import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import {
  BsPerson,
  BsFileEarmarkText,
  BsEnvelope,
  BsPhone
} from "react-icons/bs";

import { validarCpf } from "../../../../global/utils/formatCpfCnpj";
import InputTexto from "../../../../componentes/inputText";

const Campos = ({ register, errors, valores, values }) => {
  return (
    <>
      <InputTexto
        id="nome"
        name="nome"
        type="text"
        placeholder="Nome"
        icon={<BsPerson />}
        ref={register({
          required: "Campo Nome é obrigatório!"
        })}
        errors={errors}
        defaultValue={valores?.nome ?? ""}
        value={values?.nome || ""}
      />
      <Box
        component="span"
        fontSize="0.75em"
        fontWeight="400"
        margin="3px 14px 0px"
        color="rgb(211, 47, 47)"
      >
        {errors?.nome?.message ?? ""}
      </Box>

      <InputTexto
        id="cnpjCpf"
        name="cnpjCpf"
        type="text"
        placeholder="CPF"
        icon={<BsFileEarmarkText />}
        ref={register({
          required: "Campo CPF é obrigatório!",
          validate: (value) =>
            value ? validarCpf(value) || "CPF inválido!" : true
        })}
        errors={errors}
        defaultValue={valores?.cnpjCpf || ""}
        value={values?.cnpjCpf || ""}
      />
      <Box
        component="span"
        fontSize="0.75em"
        fontWeight="400"
        margin="3px 14px 0px"
        color="rgb(211, 47, 47)"
      >
        {errors?.cnpjCpf?.message ?? ""}
      </Box>

      <InputTexto
        id="email"
        name="email"
        type="email"
        placeholder="E-mail"
        icon={<BsEnvelope />}
        ref={register({
          required: "O campo E-mail é obrigatório!",
          pattern: {
            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: "Campo E-mail inválido!"
          },
          maxLength: {
            value: 100,
            message: "Quantidade máxima de 100 caracteres!"
          }
        })}
        errors={errors}
        defaultValue={valores?.email ?? ""}
        value={values?.email ?? ""}
      />
      <Box
        component="span"
        fontSize="0.75em"
        fontWeight="400"
        margin="3px 14px 0px"
        color="rgb(211, 47, 47)"
      >
        {errors?.email?.message ?? ""}
      </Box>
      <InputTexto
        id="telefone"
        name="telefone"
        type="text"
        placeholder="Telefone"
        icon={<BsPhone />}
        ref={register({
          required: "O campo Telefone é obrigatório!",
          minLength: {
            value: 18,
            message: "Quantidade mínima de 18 caracteres!"
          }
        })}
        errors={errors}
        defaultValue={valores?.telefone || ""}
        value={values?.telefone || ""}
      />
      <Box
        component="span"
        fontSize="0.75em"
        fontWeight="400"
        margin="3px 14px 0px"
        color="rgb(211, 47, 47)"
      >
        {errors?.telefone?.message ?? ""}
      </Box>
    </>
  );
};

Campos.propTypes = {
  register: PropTypes.oneOfType([PropTypes.any]).isRequired,
  errors: PropTypes.oneOfType([PropTypes.any]).isRequired,
  valores: PropTypes.oneOfType([PropTypes.any]),
  values: PropTypes.oneOfType([PropTypes.any])
};

Campos.defaultProps = {
  valores: {},
  values: {}
};

export default Campos;
