import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Modal from "@mui/material/Modal";
import { Box, Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useStyles } from "./style";

const ModalLuz = ({
  item,
  onCancelar,
  mensagem,
  titulo,
  header,
  children,
  footer
}) => {
  const classes = useStyles();
  const [aberto, setAberto] = useState(false);

  useEffect(() => {
    setAberto(Boolean(item));
  }, [item]);

  const onClose = () => {
    setAberto(false);
    onCancelar();
  };

  return (
    <Modal open={aberto}>
      <Box
        component={Grid}
        container
        justifyContent="center"
        alignItems="center"
        className={classes.container}
        width={{ sm: "50%", lg: "30%", md: "35%", xl: "25%" }}
        m={0}
      >
        {header && (
          <Grid container className={classes.header}>
            <Grid item xs={11}>
              <Box color="#332053" fontSize="24px" fontWeight="700">
                {titulo}
              </Box>
            </Grid>
            <Grid item xs={1}>
              <CloseIcon
                cursor="pointer"
                onClick={onClose}
                className={classes.close}
              />
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <Box color="#332053" fontSize="16px" fontWeight="400" m={2}>
            {mensagem}
          </Box>
        </Grid>

        <Grid item xs={12}>
          {children}
        </Grid>
        {footer && (
          <Grid container className={classes.footer}>
            {footer}
          </Grid>
        )}
      </Box>
    </Modal>
  );
};

ModalLuz.propTypes = {
  item: PropTypes.oneOfType([PropTypes.any]).isRequired,
  onCancelar: PropTypes.oneOfType([PropTypes.func]),
  mensagem: PropTypes.oneOfType([PropTypes.node, PropTypes.any]),
  titulo: PropTypes.string,
  header: PropTypes.bool,
  children: PropTypes.node,
  footer: PropTypes.oneOfType([PropTypes.node, PropTypes.any])
};

ModalLuz.defaultProps = {
  onCancelar: () => {},
  mensagem: {},
  titulo: "",
  header: true,
  children: <></>,
  footer: ""
};

export default ModalLuz;
