import RetornoEndpointDto from "../../../global/dto/retornoEndpointDto";
import {
  liberaPropostaContrato,
  listarPaginado,
  listarPrecosDetalhe
} from "../../../servicos/listagemCotacoesServico";
import { contratarCotacao } from "../../../servicos/demandaAtendServico";
import { obterTodos } from "../../../servicos/tipoPlanoCotacaoServico";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";
import { store } from "../../../global/redux";

export default class ListagemCotacaoHelper {
  static async listarCotacoes(filtro) {
    try {
      const resultado = await listarPaginado(filtro);
      return RetornoEndpointDto.Sucesso(
        "Listagem de cotações obtidas com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        error.response.data.Message ||
          "Ocorreu um erro ao obter a lista de cotações"
      );
    }
  }

  static async contratarCotacao(id) {
    try {
      const resultado = await contratarCotacao(id);

      return RetornoEndpointDto.Sucesso(
        "Cotação contratada com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Ocorreu um erro ao contratar a cotação");
    }
  }

  static async listarTodosTipos() {
    try {
      const resultado = await obterTodos();

      return RetornoEndpointDto.Sucesso(
        "Listagem de tipo de cotações obtidas com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu um erro ao obter a lista de tipos de cotações"
      );
    }
  }

  static async listarPrecosDetalhe(id) {
    try {
      const resultado = await listarPrecosDetalhe(id);
      return RetornoEndpointDto.Sucesso(
        "Listagem de precos obtidas com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        error.response.data.Message ||
          "Ocorreu um erro ao obter a lista de precos"
      );
    }
  }

  static async liberaPropostaContrato(id, body) {
    try {
      const resultado = await liberaPropostaContrato(id, body);
      return RetornoEndpointDto.Sucesso(
        "Proposta liberada com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        error.response.data.Message || "Ocorreu um erro ao liberar proposta!"
      );
    }
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }

  static exibirSucesso(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem
      })
    );
  }
}
