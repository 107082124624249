/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";

// Componentes
import DownloadIcon from "@mui/icons-material/Download";
import { Box, Divider, Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import { useSelector } from "react-redux";
import FaturasGDHelper from "./helper";
import { Button, useStyles } from "./style";

import { TabelaPaginada, TabelaPaginadaMobile } from "../../../componentes";
// import InputDataWhite from "../../../componentes/inputTextoDataBranco";
import enumeradorStatus from "./enumeradores/enumeradorStatus";

// DTO
import ResultadoPaginadoDto from "../../../componentes/tabelaPaginada/resultadoPaginadoDto";
import FiltroDto from "./dto/filtroDto";
import ListagemFaturasDto from "./dto/listagemFaturasGDDto";

// Redux
import { store } from "../../../global/redux";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";

// Styles
import Coluna from "../../../componentes/tabelaPaginada/colunas/coluna";
import ColunaComponentePersonalizado from "../../../componentes/tabelaPaginada/colunas/colunaComponentePersonalizado";
import UnidadeNaoSelecionada from "../../../componentes/unidadeNaoSelecionada";
import {
  unidadeConsumidoraGD,
  unidadeConsumidoraGDColaborador
} from "../../../servicos/unidadeConsumidoraGDServico";
import ColunaRelatorio from "./colunasPersonalizadas/ColunaRelatorio";
import ColunaStatus from "./colunasPersonalizadas/ColunaStatus";

const ListagemGDFaturas = () => {
  moment.locale("pt");

  const classes = useStyles();
  const refTabela = useRef(null);

  const global = useSelector((state) => state.usuario);
  const { unidadeSelecionada, usuario, unidades } = global;
  const nomeUnidade = unidades
    ? unidades
        ?.find((x) => x.id === unidadeSelecionada)
        ?.nomeUnidade?.replace(/\s/g, "-")
    : "";
  const { tipo } = usuario;
  const [filtroAdicionalTabela, setFiltroAdicionalTabela] = useState(
    new FiltroDto()
  );

  const themes = useTheme();

  const mobile = useMediaQuery(themes.breakpoints.down("lg"));
  // eslint-disable-next-line consistent-return
  const onClickGerarPDF = async (parametros) => {
    const { sucesso, mensagem } = await FaturasGDHelper.downloadFatura(
      parametros,
      nomeUnidade
    );
    if (!sucesso) return FaturasGDHelper.exibirErro(mensagem);
  };

  const colunas = [];

  const handleStatus = (status) => {
    switch (status) {
      case enumeradorStatus.VENCIDA:
        return classes.fundoVencido;
      case enumeradorStatus.PAGO:
        return classes.fundoPago;
      case enumeradorStatus.EMABERTO:
        return "";
      default:
        return status;
    }
  };

  // eslint-disable-next-line react/prop-types
  const ColunaMobile = ({ parametros }) => {
    return (
      <Box className={classes.boxArea}>
        <h5>{`Fatura ${parametros?.mesReferencia}`}</h5>
        <p className={classes.valorFatura}>{parametros?.valorTotalFaturaLUZ}</p>
        <p className={classes.vencimentoFatura}>
          {`Vencimento ${parametros?.dataVencimentoLUZ}`}
        </p>

        <Divider className={`my-2 ${classes.divider}`} />

        <Box className="d-flex justify-content-center">
          <Box
            className={`${classes.fundoCircular} ${handleStatus(
              parametros?.status
            )}`}
          />
          <Box className="d-inline">{parametros?.status.toUpperCase()}</Box>
        </Box>
        <Box className="d-inline my-2">
          {parametros?.status !== enumeradorStatus.PAGO
            ? "--"
            : `em: ${parametros?.dataPagamento}`}
        </Box>

        <Divider className={`my-2 ${classes.divider}`} />

        <Button
          onClick={() => onClickGerarPDF(parametros)}
          className={`text-white mt-3 ${classes.botao}`}
        >
          <DownloadIcon fontSize="small" /> Baixar fatura
        </Button>
      </Box>
    );
  };

  if (!mobile) {
    colunas.push(new Coluna("mesReferencia", "Período referente", true));
    colunas.push(new Coluna("dataVencimentoLUZ", "Vencimento", true));
    colunas.push(
      new ColunaComponentePersonalizado(
        "opcoes",
        "Status",
        ColunaStatus,
        null,
        false,
        true,
        "12%",
        "12%",
        "pl-2"
      )
    );
    colunas.push(new Coluna("dataPagamento", "Pagamento", true));
    colunas.push(new Coluna("valorTotalFaturaLUZ", "Valor", true));
    colunas.push(
      new ColunaComponentePersonalizado(
        "opcoes",
        "Fat.",
        ColunaRelatorio,
        null,
        false,
        true,
        "6%",
        "6%",
        "pl-2"
      )
    );
  } else {
    colunas.push(
      new ColunaComponentePersonalizado(
        "nome",
        "",
        ColunaMobile,
        null,
        false,
        false,
        null,
        null
      )
    );
  }

  // const [date, setDateSelecionada] = useState(new Date());
  const handleCapitalize = (string) => {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  };

  // eslint-disable-next-line consistent-return
  const handleDadosFaturas = async (unidadeId) => {
    try {
      const resultado = await FaturasGDHelper.getDadosFaturas(
        unidadeId,
        "2020-01-01",
        moment().format("YYYY-MM-DD")
      );
      if (!resultado.sucesso) {
        store.dispatch(
          alertaExibir({
            tipo: "danger",
            mensagem: resultado.mensagem
          })
        );
        return new ResultadoPaginadoDto([], 1, 0, 0);
      }

      if (!resultado?.data || resultado?.data?.length === 0)
        return new ResultadoPaginadoDto([], 1, 0, 0);

      const resultadoFiltroCancelados = resultado?.data?.filter(
        (item) => item.status !== enumeradorStatus.CANCELADA
      );

      if (resultadoFiltroCancelados.length === 0 ?? true)
        return new ResultadoPaginadoDto([], 1, 0, 0);

      const resLinhas = resultadoFiltroCancelados?.map((res) => {
        const dataVencimentoLUZ = moment(res.dataVencimentoLUZ).format(
          "DD-MM-YYYY"
        );
        const dataPagamento =
          res.status !== enumeradorStatus.PAGO
            ? "--"
            : moment(res.dataPagamento).format("DD-MM-YYYY");
        const mesReferencia = handleCapitalize(
          `${moment(`${res.mesReferencia}`).format("MMMM")}-${
            res.anoReferencia
          }`
        );
        const valorTotalFaturaLUZ = `R$ ${res?.valorTotalFaturaLUZ}`;

        return new ListagemFaturasDto(
          res.id,
          mesReferencia,
          dataVencimentoLUZ,
          res.status,
          dataPagamento,
          valorTotalFaturaLUZ,
          res.idArquivoFaturaLUZ,
          res.anoReferencia
        );
      });

      return new ResultadoPaginadoDto(resLinhas, 0, 0, 0);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: error?.response?.data?.message
        })
      );
    }
  };

  const handleUnidadeConsumidora = async (unidadeConsumidoraId) => {
    try {
      const { data, status } =
        tipo === "Cliente"
          ? await unidadeConsumidoraGD()
          : await unidadeConsumidoraGDColaborador(unidadeConsumidoraId);
      if (data && status === 200) {
        const unidade =
          data?.unidadeConsumidoraGDList?.filter(
            (item) => item.unidadeConsumidoraId === unidadeConsumidoraId
          ) || [];
        handleDadosFaturas(unidade[0]?.unidadeConsumidoraId);
        setFiltroAdicionalTabela(new FiltroDto(unidadeSelecionada));
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: error?.response?.data?.message
        })
      );
    }
  };

  const onChangeFiltrosTabela = async () => {
    return handleDadosFaturas(unidadeSelecionada);
  };

  useEffect(() => {
    if (unidadeSelecionada) {
      handleUnidadeConsumidora(unidadeSelecionada);
    }
  }, [unidadeSelecionada]);

  // const handleDateChange = (data) => {
  //   setDateSelecionada(data);
  // }

  return (
    <>
      {unidadeSelecionada ? (
        <Grid container xs={12}>
          {/* //   <Grid container item xs={12} className="ml-3 mr-2 mb-3">
            //     <Grid
            //       container
            //       item
            //       alignItems="center"
            //       justify="space-between"
            //       spacing={2}
            //       className="mt-4"
            //     >
            //       <Grid item md={2} xs={6}>
            //         <InputDataWhite
            //           autoOk
            //           styleForm={false}
            //           type="text"
            //           id="date"
            //           label="Selecione uma data"
            //           format="dd/MM/yyyy"
            //           customValue={date}
            //           onChange={(value) => handleDateChange(value)}
            //         />
            //       </Grid>

            //     </Grid>
            //   </Grid> */}
          <Grid item xs={12} className="mt-4">
            {mobile ? (
              <TabelaPaginadaMobile
                className={classes.fundoCelulaTransparente}
                ref={refTabela}
                onChangeFiltrosTabela={onChangeFiltrosTabela}
                filtrosAdicionais={filtroAdicionalTabela}
                colunas={colunas}
                paginaAtual={1}
                noHeader
                noFooter={false}
              />
            ) : (
              <TabelaPaginada
                className={classes.tabela}
                ref={refTabela}
                onChangeFiltrosTabela={onChangeFiltrosTabela}
                filtrosAdicionais={filtroAdicionalTabela}
                colunas={colunas}
                paginaAtual={1}
                paginationPerPage={20}
                noHeader={false}
                noFooter={false}
              />
            )}
          </Grid>
        </Grid>
      ) : (
        <UnidadeNaoSelecionada />
      )}
    </>
  );
};

export default ListagemGDFaturas;
