import { makeStyles } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import { Base } from "../cores";

const theme = createTheme();

export const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: Base.OffWhite,
    border: "0 none",
    borderRadius: "4px",
    boxShadow: theme.shadows[5],
    left: "50%",
    outline: "none",
    position: "absolute",
    padding: theme.spacing(4),
    transform: "translate(-50%, -50%)",
    top: "50%",
    width: "640px !important",
    [theme.breakpoints.down("xs")]: {
      width: "350px !important"
    }
  },
  containerTelaCheia: {
    backgroundColor: Base.OffWhite,
    border: "0 none",
    borderRadius: "4px",
    boxShadow: theme.shadows[5],
    left: "50%",
    outline: "none",
    position: "absolute",
    padding: theme.spacing(4),
    transform: "translate(-50%, -50%)",
    top: "50%",
    width: "80% !important",
    height: "80%"
  },
  buttonLink: {
    "&:hover": {
      textDecoration: "none !important"
    },
    "&:focus": {
      boxShadow: "none !important",
      outline: 0
    },
    display: "flex !important",
    flexDirection: "row-reverse !important",
    backgroundColor: `${Base.Transparent} !important`,
    border: "none",
    fontSize: "16px !important",
    color: `${Base.BlueCerulean} !important`,
    paddingTop: "30%"
  },
  button: {
    background: "transparent",
    color: Base.BlueLochmara,
    fontSize: "14px",
    padding: "0px"
  },
  titulo: {
    marginBottom: "0px",
    color: Base.BlackCurrant
  },
  item: {
    display: "flex",
    justifyContent: "flex-end"
  },
  itemMensagem: {
    height: "100%",
    overflowY: "scroll",
    textAlign: "justify",
    paddingRight: "10px",
    whiteSpace: "pre-line",
    color: Base.BlackCurrant
  },
  divMensagem: {
    height: "80%"
  },
  icon: {
    cursor: "pointer"
  }
}));
