import React, { useEffect, useRef, useState } from "react";
import { useForm, useWatch } from "react-hook-form";

// Componentes
import TuneIcon from "@mui/icons-material/Tune";
import { Grid } from "@mui/material";
import BotaoFiltroOrdenacao from "componentes/botaoFiltroOrdenar";
import InputCalendario from "componentes/inputTextoDataBranco";
import TabelaPaginada from "componentes/tabelaPaginada";
import Coluna from "componentes/tabelaPaginada/colunas/coluna";
import ResultadoPaginadoDto from "componentes/tabelaPaginada/resultadoPaginadoDto";
import theme from "themes";

// DTO
import MaterialInputBusca from "componentes/inputBusca";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { useSelector } from "react-redux";
import { listarOrganizarPor } from "servicos/medicaoControleServico";
import AlteracoesMedicoesDto from "./dto/alteracaoMedicoesDto";
import FiltroAdicionalTabela from "./dto/filtroAdicionalTabela";
import FiltroDto from "./dto/filtroDto";
import AgenteHelper from "./helper";

// Redux

// Styles
import { useStyles } from "./style";

// Serviços

const AlteracaoMedicoes = () => {
  const [ordemSelecionada, setOrdemSelecionada] = useState("");
  const [filtroAdicionalTabela, setFiltroAdicionalTabela] = useState(
    new FiltroAdicionalTabela()
  );
  const [ordemColunaSelecionada, setOrdemColunaSelecionada] = useState();
  const [mes, setMes] = useState(window.moment().subtract(1, "month"));
  const classes = useStyles();
  const refTabela = useRef(null);
  const global = useSelector((state) => state.usuario);
  const { empresaSelecionada, clienteSelecionado, unidadeSelecionada } = global;

  const { register, control } = useForm({
    reValidateMode: "onSubmit"
  });

  const textoBusca = useWatch({
    control,
    name: "textoBusca",
    defaultValue: ""
  });

  const colunas = [
    new Coluna("cliente", "Cliente", false, "16%", "16%"),
    new Coluna("unidade", "Unidade", false, "11%", "11%"),
    new Coluna("medidor", "Medidor", false, "11%", "11%"),
    new Coluna("dataAlteracao", "Data de alteração", false, "11%", "11%"),
    new Coluna("dia", "Dia", false, "6%", "6%"),
    new Coluna("hora", "Hora", false, "6%", "6%"),
    new Coluna("valorPrevio", "Valor prévio kWh", false, "12%", "12%"),
    new Coluna("valorAjustado", "Valor ajustado kWh", false, "13.5%", "13.5%"),
    new Coluna("diferenca", "Diferença kWh", false, "10%", "10%")
  ];

  const obterAlteracaoMedicao = async (parametros) => {
    const filtro = new FiltroDto(
      parametros?.tamanhoPagina,
      parametros?.pagina,
      parametros?.pesquisar,
      parametros?.filtroAdicional?.ordenacao ?? "",
      parametros?.filtroAdicional?.data,
      parametros?.filtroAdicional?.unidadeSelecionada ?? "",
      parametros?.filtroAdicional?.clienteSelecionado ?? "",
      parametros?.filtroAdicional?.empresaSelecionada ?? ""
    );

    const resultado = await AgenteHelper.listarAlteracaoMedicoes(filtro);

    if (!resultado.sucesso) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: resultado.mensagem
        })
      );
      return new ResultadoPaginadoDto([], 1, 0, 0);
    }

    if (resultado?.data?.agentesCCEEClientesPerfis?.length === 0 ?? true)
      return new ResultadoPaginadoDto([], 1, 0, 0);

    const resLinhas = resultado?.data?.data?.medicaoControle.map((res) => {
      return new AlteracoesMedicoesDto(
        res.clienteNome,
        res.unidadeNome,
        res.medidorNome,
        res.dataAlteracao,
        res.diaMedicao,
        `${res.horaMedicao}h`,
        res.valorPrevio,
        res.valorAjustado,
        res.diferenca,
        res.situacao === "AT"
      );
    });

    return new ResultadoPaginadoDto(
      resLinhas,
      resultado?.data?.data?.paginaAtual,
      resultado?.data?.data?.totalItens,
      resultado?.data?.data?.totalPaginas
    );
  };

  const onChangeFiltrosTabela = async (parametros) => {
    return obterAlteracaoMedicao({
      tamanhoPagina: parametros.totalPagina,
      pagina: parametros.pagina,
      pesquisar: parametros.pesquisar,
      filtroAdicional: new FiltroAdicionalTabela(
        parametros?.filtrosAdicionais?.ordenacao ?? "",
        window.moment(mes).format("yyyy-MM"),
        parametros?.filtrosAdicionais?.empresaSelecionada,
        parametros?.filtrosAdicionais?.clienteSelecionado,
        parametros?.filtrosAdicionais?.unidadeSelecionada
      )
    });
  };

  const handleClickAtivarFiltro = () => {
    setFiltroAdicionalTabela(
      new FiltroAdicionalTabela(
        ordemSelecionada,
        mes,
        empresaSelecionada,
        clienteSelecionado,
        unidadeSelecionada
      )
    );
  };

  const handleClickOrdemSelecionada = (event) => {
    const val = ordemSelecionada === event ? "" : event;
    setOrdemSelecionada(val);
  };

  const handleAlterarFiltroAdicional = () => {
    setFiltroAdicionalTabela(
      new FiltroAdicionalTabela(
        ordemSelecionada,
        mes,
        empresaSelecionada,
        clienteSelecionado,
        unidadeSelecionada
      )
    );
  };

  useEffect(() => {
    handleAlterarFiltroAdicional();
  }, [mes, empresaSelecionada, clienteSelecionado, unidadeSelecionada]);

  const handleClickSortOrderSelecionado = (valor, idCampoSelecionado) => {
    const val =
      ordemColunaSelecionada === idCampoSelecionado && valor === ""
        ? ""
        : idCampoSelecionado;
    setOrdemColunaSelecionada(val);
    setOrdemSelecionada(valor);
  };

  return (
    <Grid container xs={12}>
      <Grid container item xs={12} className="ml-3 mr-2 mb-3">
        <Grid container item alignItems="center" justifyContent="space-between">
          <Grid item md={5}>
            <MaterialInputBusca
              type="text"
              id="textoBusca"
              name="textoBusca"
              label="Buscar"
              renderIconShowHide
              searchAdornment
              permiteValorBranco
              defaultValue={textoBusca ?? ""}
              ref={register}
            />
          </Grid>
          <Grid item md={2}>
            <InputCalendario
              styleForm={false}
              type="text"
              id="mes"
              name="mes"
              format="MMMM/yyyy"
              maxDate={window.moment().subtract(1, "month")}
              customValue={mes}
              onChange={(valor) => setMes(valor)}
              renderIconShowHide={false}
              views={["month", "year"]}
            />
          </Grid>
          <Grid item md={2}>
            <BotaoFiltroOrdenacao
              type="button"
              color={theme.color.secondaryBorderColor}
              background="transparent"
              label="Filtrar / Ordernar"
              icon={<TuneIcon />}
              className={classes.buttonFiltro}
              ordenacaoColuna={listarOrganizarPor()}
              ordenadorUm="Ordenador"
              ordenadorDois="Filtrar"
              ordemSelecionada={ordemSelecionada}
              ordemColunaSelecionada={ordemColunaSelecionada}
              onClickOrdenacao={handleClickOrdemSelecionada}
              onClickAtivarAgora={handleClickAtivarFiltro}
              onClickSortOrder={handleClickSortOrderSelecionado}
              larguraBotao="3"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className="mt-4">
        <TabelaPaginada
          ref={refTabela}
          onChangeFiltrosTabela={onChangeFiltrosTabela}
          colunas={colunas}
          pesquisar={textoBusca}
          filtrosAdicionais={filtroAdicionalTabela}
          paginaAtual={1}
          paginationPerPage={20}
          noHeader
        />
      </Grid>
    </Grid>
  );
};

export default AlteracaoMedicoes;
