import { makeStyles } from '@mui/styles';
import { Base } from 'componentes/cores';

export const useStyles = makeStyles(() => ({
    titulo: {
        fontWeight: "bold"
    },
    container: {
        "& .MuiInputBase-root": {
            color: `${Base.OffWhite} !important`
        },
        "& .MuiInputBase-input": {
            maxWidth: "90px"
        },
        "& .MuiButtonBase-root": {
            color: `${Base.OffWhite} !important`
        },
    },
    itemGrafico: {
        "& .apexcharts-tooltip.apexcharts-theme-light": {
            border: "0 none",
            background: "none",
            width: "fit-content",
            height: "fit-content",
            textAlign: "center",
            fontSize: "13px"
        }
    },
    noData: {
        textAlign: "center",
        width: "100%",
        minHeight: "200px",
        "& div": {
            margin: "125px auto 0",
            maxWidth: "80%"
        }
    },
    arrowBox: {
        position: "relative",
        background: "#647480",
        padding: "8px",
        borderColor: "#647480"
    },
    arrowBoxTitle: {
        color: "#a6b9c8",
        fontSize: "10px"
    },
    formControl: {
        width: "100%",
        "& .MuiInputBase-root": {
            height: "56px",
            color: `${Base.White} !important`,
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#FFF !important"
            }
        },
        "& .MuiSelect-icon": {
            color: `${Base.White} !important`
        },
        "& option": {
            color: Base.BlackBastille
        },
        "& .MuiSelect-select.MuiSelect-select": {
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            color: "#eef0f9"
        },
        "& .MuiSelect-outlined.MuiSelect-outlined": {
            padding: "0 40px 0 20px "
        },
        "& .MuiFormLabel-root": {
            color: `${Base.OffWhite} !important`,
        }
    },
}));

export const customStyles = {
    table: {
        style: {
            background: "transparent",
        }
    },
    headRow: {
        style: {
            background: "transparent",
        }
    },
    headCells: {
        style: {
            background: "#2a2b32",
            marginLeft: "3px",
            fontSize: "20px",
            justifyContent: "center",
            color: `${Base.OffWhite} !important`,
            "& div": {
                "&:hover": {
                    color: `${Base.OffWhite} !important`
                }
            }
        }
    },
    container: {
        margin: "0 0 8px",
        width: "100%",
        paddingLeft: "30px",
        "& .MuiFormControl-marginNormal": {
            border: `5px solid ${Base.BlueViolet}`,
            borderRadius: "4px",
            margin: "0"
        },
    },
    rows: {
        style: {
            background: "transparent",
            marginBottom: "1px",
            marginTop: "1px",
            color: `${Base.White} !important`
        }
    },
    Pagination: {
        styles: {
            color: "#eef0f9"
        }
    },
    cells: {
        style: {
            marginLeft: "3px",
            background: "#32333b",
            fontSize: "20px",
            justifyContent: "center",
            color: `${Base.White} !important`,
            "& div": {
                "&:hover": {
                    color: `${Base.OffWhite} !important`
                }
            }
        }
    }
};