import React from "react";
import PropTypes from "prop-types";

// Componentes
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import IOSStyledSwitch from "./IOSStyledSwitch";

const IOSStyleSwitch = IOSStyledSwitch;

const MaterialSwitch = React.forwardRef((props, ref) => {
  const {
    label,
    labelPlacement,
    id,
    name,
    checked,
    disabled,
    onChange,
    className
  } = props;

  const handleChange = (event) => {
    onChange(event.target.checked);
  };

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <IOSStyleSwitch
            checked={checked}
            onChange={handleChange}
            disabled={disabled}
            name={name}
            id={id}
            inputRef={ref}
          />
        }
        label={label}
        labelPlacement={labelPlacement}
        className={className}
      />
    </FormGroup>
  );
});

MaterialSwitch.propTypes = {
  label: PropTypes.string,
  labelPlacement: PropTypes.oneOfType([PropTypes.any]),
  id: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.oneOfType([PropTypes.any]),
  onChange: PropTypes.oneOfType([PropTypes.func]),
  disabled: PropTypes.bool,
  className: PropTypes.string
};

MaterialSwitch.defaultProps = {
  label: "",
  labelPlacement: "top",
  id: "",
  name: "",
  checked: false,
  onChange: () => {},
  disabled: false,
  className: ""
};

export default MaterialSwitch;
