import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Modal from "@mui/material/Modal";
import { Grid } from "@mui/material";
import Botao from "../botao";

import { useStyles } from "./style";

const ModalOperacoes = (props) => {
  const { item, onCancelar, mensagem, titulo } = props;

  const classes = useStyles();
  const [aberto, setAberto] = useState(false);

  useEffect(() => {
    setAberto(Boolean(item));
  }, [item]);

  const onClose = () => {
    setAberto(false);
    onCancelar();
  };

  return (
    <Modal open={aberto} onClose={onClose}>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        className={classes.container}
      >
        <Grid xs={12} className={classes.header} py={3} px={2}>
          {titulo}
        </Grid>
        <Grid xs={12}>{mensagem}</Grid>
        <Grid
          lg={2}
          md={4}
          xs={6}
          direction="row"
          justifyContent="flex-end"
          p={2}
        >
          <Botao
            onClick={onClose}
            label="Fechar"
            className={`${classes.button} `}
          />
        </Grid>
      </Grid>
    </Modal>
  );
};

ModalOperacoes.propTypes = {
  item: PropTypes.oneOfType([PropTypes.any]).isRequired,
  onCancelar: PropTypes.oneOfType([PropTypes.func]),
  mensagem: PropTypes.oneOfType([PropTypes.node, PropTypes.any]),
  titulo: PropTypes.string
};

ModalOperacoes.defaultProps = {
  onCancelar: () => {},
  mensagem: {},
  titulo: ""
};

export default ModalOperacoes;
