import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import {
  BotaoCadastro,
  Loader,
  SelectAutocomplete
} from "../../../../componentes";
// import InputBlock from "../InputBlock";
import { useStyles } from "./style";
import theme from "../../../../themes";
import InputBlock from "../InputBlock";

const FiltrosMemberGetMember = ({
  adicionarLinha,
  ultimaAtualizacao,
  inativarAdd,
  setDistribuidoraId,
  distribuidoraId,
  listaDistribuidora,
  filtros,
  carregandoDistribuidora
}) => {
  const classes = useStyles();
  return (
    <Grid container spacing={2}>
      <Grid item lg={6} md={3} sm={12} xs={12}>
        <BotaoCadastro
          label="Adicionar Nova Vigência"
          type="button"
          onClick={() => adicionarLinha()}
          disabled={inativarAdd}
          color={theme.color.primary}
          className={classes.adicionar}
        />
      </Grid>
      {filtros === "DistribuidoraMemberGetMember" && (
        <Grid item lg={2} md={5} sm={12} xs={12}>
          <Loader loading={carregandoDistribuidora}>
            <SelectAutocomplete
              disableClearable
              disableOpenOnFocus
              id="distribuidoraId"
              name="distribuidoraId"
              options={listaDistribuidora}
              label="Filtrar por distribuidora"
              getOptionLabel={(option) =>
                option?.nomeFantasia ?? distribuidoraId
              }
              onChange={(e, item) => {
                setDistribuidoraId(item?.id);
              }}
              value={distribuidoraId}
              allowClear
              valueName="nomeFantasia"
            />
          </Loader>
        </Grid>
      )}
      <Grid item lg={2} md={3} sm={12} xs={12}>
        <InputBlock
          type="text"
          id="ultimaAtualizacao"
          name="ultimaAtualizacao"
          label="Última atualização"
          value={ultimaAtualizacao}
          className="w-100 pr-2"
          readOnly
          disabled
        />
      </Grid>
    </Grid>
  );
};

FiltrosMemberGetMember.propTypes = {
  adicionarLinha: PropTypes.oneOfType([PropTypes.func]),
  ultimaAtualizacao: PropTypes.string,
  inativarAdd: PropTypes.bool,
  setDistribuidoraId: PropTypes.oneOfType([PropTypes.func]),
  distribuidoraId: PropTypes.number,
  listaDistribuidora: PropTypes.oneOfType([PropTypes.array]),
  filtros: PropTypes.string,
  carregandoDistribuidora: PropTypes.bool
};

FiltrosMemberGetMember.defaultProps = {
  adicionarLinha: () => {},
  ultimaAtualizacao: "",
  inativarAdd: false,
  setDistribuidoraId: () => {},
  distribuidoraId: "",
  listaDistribuidora: [],
  filtros: "",
  carregandoDistribuidora: false
};

export default FiltrosMemberGetMember;
