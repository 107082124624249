import React, { useEffect, useState, useMemo } from "react";
import { useHistory } from "react-router-dom";

// Icones
import TuneIcon from "@mui/icons-material/Tune";

// Form Hooks
import { useForm, useWatch } from "react-hook-form";

// Componentes
import { Grid } from "@mui/material";
import { Botao } from "../../../../componentes";
import BotaoCadastro from "../../../../componentes/botaoCadastro";
import BotaoFiltroOrdenacao from "../../../../componentes/botaoFiltroOrdenar";
import { Base } from "../../../../componentes/cores";
import ItemListaExpansivel from "../../../../componentes/itemListaExpansivel";
import Loader from "../../../../componentes/loader";
import ModalConfirmacao from "../../../../componentes/modalConfirmacao";
import Paginacao from "../../../../componentes/paginacao";

// Serviços
import {
  deletarDistribuidora,
  listarFiltrarPor,
  listarOrganizarPor
} from "../../../../servicos/distribuidorasServico";

// Rotas
import { RotasDTO } from "../../../../global/rotas/rotasUrlDto";

// Redux
import { store } from "../../../../global/redux";
import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";

// Styles
import theme from "../../../../themes";
import { useStyles } from "./style";

// Útils
import MaterialInputBusca from "../../../../componentes/inputBusca";
import TabelaSemDados from "../../../../componentes/tabelaPaginada/tabelaSemDados";
import { formataCnpj } from "../../../../servicos/utils";
import FiltroDto from "./Dto/filtroDto";
import DistribuidoraHelper from "./helper";
import { cpfCnpjValido } from "../../../../global/utils/formatCpfCnpj";

const DistribuidoraListar = () => {
  const classes = useStyles();
  const history = useHistory();

  const [paginaAtual, setPaginaAtual] = useState();
  const [totalPaginas, setTotalPaginas] = useState();
  const [carregamentoInicial, setCarregamentoInicial] = useState(false);

  const [carregandoDistribuidora, setCarregandoDistribuidoras] = useState(
    false
  );
  const [listaDistribuidoras, setListaDistribuidoras] = useState([]);

  const handleListarDistribuidoras = async ({ pagina }) => {
    const filtro = new FiltroDto(
      pagina || paginaAtual,
      20,
      cpfCnpjValido(textoPesquisa),
      ordemSelecionada,
      filtroSelecionado
    );

    const {
      data,
      sucesso,
      mensagem
    } = await DistribuidoraHelper.obterDistribuidoras(
      filtro,
      setCarregandoDistribuidoras
    );

    if (!sucesso) {
      DistribuidoraHelper.exibirErro(mensagem);
      setCarregandoDistribuidoras(false);
      return;
    }

    setListaDistribuidoras(data.distribuidoras);
    setPaginaAtual(data?.paginaAtual);
    setTotalPaginas(data?.totalPaginas);
  };

  useEffect(() => {
    setCarregamentoInicial(true);
    handleListarDistribuidoras({ pagina: 1 });
  }, []);

  const onClickNovaDistribuidora = () => {
    history.push(`${RotasDTO.Distribuidoras}/cadastro`);
  };

  const onClickEditar = (id) => {
    history.push(`${RotasDTO.Distribuidoras}/cadastro/${id}`);
  };

  const [modalExclusao, setModalExclusao] = useState(false);

  const deletar = async (item) => {
    try {
      setModalExclusao(false);
      setCarregandoDistribuidoras(true);
      const deletou = await deletarDistribuidora(item?.id, !item?.ativo);
      if (deletou) {
        handleListarDistribuidoras({ pagina: 1 });
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: `A distribuidora foi ${
              !item?.ativo ? "ativada" : "inativada"
            } com sucesso!`
          })
        );
      }
      setCarregandoDistribuidoras(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoDistribuidoras(false);
    }
  };

  const onClickExcluir = (item) => {
    setModalExclusao(item);
  };

  const onConfirmarExclusao = (item) => {
    try {
      deletar(item);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  const { register, control } = useForm({
    reValidateMode: "onSubmit"
  });

  const textoBusca = useWatch({
    control,
    name: "textoBusca",
    defaultValue: ""
  });

  const textoPesquisa = useMemo(() => {
    return textoBusca?.length >= 3 ? textoBusca?.trim() : "";
  }, [textoBusca]);

  useEffect(() => {
    if (textoPesquisa === "" && carregamentoInicial) {
      const debounce = setTimeout(() => {
        handleListarDistribuidoras({ pagina: 1 });
      }, 1000);

      return () => clearTimeout(debounce);
    }
  }, [textoPesquisa]);

  const [ordemSelecionada, setOrdemSelecionada] = useState("");
  const [filtroSelecionado, setFiltroSelecionado] = useState("");

  const handleClickOrdemSelecionada = (event) => {
    const val = ordemSelecionada === event ? "" : event;
    setOrdemSelecionada(val);
  };

  const handleClickFiltroSelecionado = (event) => {
    const val = filtroSelecionado === event ? "" : event;
    setFiltroSelecionado(val);
  };

  const handleClickAtivarAgora = async () => {
    handleListarDistribuidoras({ pagina: 1 });
  };

  const onChangePaginacao = (item, pagina) => {
    setCarregamentoInicial(true);
    setPaginaAtual(pagina);
    if (carregamentoInicial) {
      handleListarDistribuidoras({ pagina });
    }
  };

  const onClickSearch = () => {
    handleListarDistribuidoras({ pagina: 1 });
  };

  return (
    <Loader loading={carregandoDistribuidora} className="w-auto">
      <ModalConfirmacao
        item={modalExclusao}
        onConfirmar={(item) => onConfirmarExclusao(item)}
        mensagem={`Tem certeza que deseja ${
          modalExclusao?.ativo ? "inativar" : "ativar"
        } essa Distribuidora?`}
        onCancelar={() => setModalExclusao(false)}
      />
      <Grid
        container
        spacing={3}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={8} sm={5}>
          <MaterialInputBusca
            type="text"
            id="textoBusca"
            name="textoBusca"
            label="Buscar"
            renderIconShowHide
            searchAdornment
            defaultValue={textoBusca ?? ""}
            ref={register}
            permiteValorBranco
          />
        </Grid>

        <Grid item xs={4} sm={2}>
          <Botao
            type="submit"
            onClick={onClickSearch}
            label="Buscar"
            className={classes.button}
            disabled={!textoPesquisa}
          />
        </Grid>
        <Grid sm={2} />

        <Grid
          item
          xs={12}
          sm={3}
          display="flex"
          justifyContent={{ sm: "flex-end" }}
        >
          <BotaoFiltroOrdenacao
            type="button"
            color={theme.color.secondaryBorderColor}
            background="transparent"
            label="Filtrar / Ordernar"
            icon={<TuneIcon />}
            className={classes.buttonFiltro}
            ordenacao={listarOrganizarPor()}
            ordenadorUm="Ordenador"
            ordenadorDois="Filtrar"
            ordemSelecionada={ordemSelecionada}
            filtros={listarFiltrarPor()}
            filtrosSelecionados={filtroSelecionado}
            onClickOrdenacao={handleClickOrdemSelecionada}
            onClickFiltro={handleClickFiltroSelecionado}
            onClickAtivarAgora={handleClickAtivarAgora}
          />
        </Grid>
        <Grid item xs={12}>
          <BotaoCadastro
            label="Nova distribuidora"
            color={theme.color.buttonColor}
            onClick={onClickNovaDistribuidora}
          />
        </Grid>
        {listaDistribuidoras && listaDistribuidoras?.length > 0 ? (
          <>
            {listaDistribuidoras.map((distribuidora) => {
              return (
                <ItemListaExpansivel
                  key={distribuidora?.id}
                  label={distribuidora?.nomeFantasia ?? "Distribuidora"}
                  color={Base.White}
                  edicao={!!distribuidora?.id}
                  exclusao={!!distribuidora?.id}
                  onClickEditar={() => onClickEditar(distribuidora?.id)}
                  onClickExcluir={() => onClickExcluir(distribuidora ?? false)}
                  ativo={distribuidora?.ativo}
                >
                  <div>
                    <strong>CNPJ: </strong>
                    {formataCnpj(distribuidora?.cnpj) ?? ""}
                  </div>
                </ItemListaExpansivel>
              );
            })}
            <Grid
              container
              spacing={3}
              direction="row"
              justifyContent="center"
              alignItems="center"
              mt={2}
            >
              <Grid item>
                <Paginacao
                  paginaAtual={paginaAtual}
                  totalPaginas={totalPaginas}
                  onChangePagina={onChangePaginacao}
                />
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid
            container
            spacing={3}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <TabelaSemDados className={classes.container} />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Loader>
  );
};

export default DistribuidoraListar;
