import { saveAs } from "file-saver";
import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const api = () => obterApi(MICROSERVICO.MEASUREMENT);

// Fazer upload
export const uploadMedicao = async (arquivo) => {
  const formData = new FormData();
  formData.append("file", arquivo);

  return api().post("v1/Medicao/UploadWitProgress", formData);
};

export const uploadProinfa = async (arquivo) => {
  const formData = new FormData();
  formData.append("file", arquivo);

  return api().post("v1/proinfa/uploadwithprogress", formData);
};

export const uploadPatamares = async (arquivo) => {
  const formData = new FormData();
  formData.append("file", arquivo.file);

  return api().post("v1/patamar/uploadwithprogress", formData);
};

export const uploadMatrizDesconto = async (arquivo) => {
  const formData = new FormData();
  formData.append("file", arquivo.file);
  return api().post("v1/matrizdesconto/uploadwitprogress", formData);
};

export const uploadMatrizDescontoConsumo = async (arquivo) => {
  const formData = new FormData();
  formData.append("file", arquivo.file);
  return api().post("v1/matrizdescontoconsumo/uploadwitprogress", formData);
};

export const uploadMatrizDescontoConsumoPonta = async (arquivo) => {
  const formData = new FormData();
  formData.append("file", arquivo.file);
  return api().post(
    "v1/matrizdescontoconsumoponta/uploadwitprogress",
    formData
  );
};

// Progresso
export const uploadTarifas = async (arquivo) => {
  const formData = new FormData();
  formData.append("file", arquivo.file);
  return api().post("v1/distribuidoratarifas/uploadwithprogress", formData);
};

// Progresso
export const uploadMunicipio = async (arquivo) => {
  const formData = new FormData();
  formData.append("file", arquivo.file);
  return api().post("v1/distribuidoramunicipio", formData);
};

// Progresso
export const getUploadProgress = async (nomeArquivo) => {
  const responseProgress = await api().get(
    `v1/uploadprogress/progressbar?nomeArquivo=${nomeArquivo}`
  );

  return responseProgress.data.progressValue;
};

export const uploadMatrizDescontoConsumoBaixo = async (arquivo) => {
  const formData = new FormData();
  formData.append("file", arquivo.file);
  return api().post("v1/matrizdescontogdbaixa/uploadwitprogress", formData);
};

// Fazer upload
export const uploadIndice = async (arquivo) => {
  const formData = new FormData();
  formData.append("file", arquivo);

  return api().post("v1/preco/importar-indice-reajuste", formData);
};

// Progresso
export const getPlanilhaGDBaixa = async () => {
  let resposta;
  await api()
    .get("v1/matrizdescontogdbaixa/geraplanilhagdbaixa", {
      headers: {
        "Content-Disposition": "attachment;",
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      },
      responseType: "arraybuffer"
    })
    .then((response) => {
      if (response.status === 200) {
        const blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        saveAs(blob, "matrizDescontoConsumoBaixa");
        resposta = "Sucesso";
      } else if (response.status === 202) {
        resposta = new TextDecoder().decode(response.data);
      }
    });

  return resposta;
};

export const uploadUsinasUnidade = async (arquivo) => {
  const formData = new FormData();
  formData.append("file", arquivo.file);
  return api().post(
    "v1/unidadeconsumidora/uploadunidadeconsumidorausinavinculadagestaoenergeticawithprogress",
    formData
  );
};

export const exportarExcelUsinasUnidade = () => {
  return api().get(
    "v1/unidadeconsumidora/unidadeconsumidorausinavinculadagestaoenergetica-gerarexcel",
    {
      responseType: "blob"
    }
  );
};
