import React from "react";
import { Box } from "@mui/material";
import getDomain from "../../../../global/getDomain";

import { ButtonLuz, LogoLuz } from "../../style";

const NavBarLuz = () => {
  const siteLuzSimulador = () => {
    window.open(
      `${process.env.REACT_APP_SITE_LUZ_API_URL}/#simulador`,
      "_target"
    );
  };

  return (
    <Box
      minHeight="10vh"
      pl={{ xs: 1, md: 6 }}
      pr={{ xs: 2, md: 6 }}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
      }}
    >
      <Box>
        <LogoLuz src={`../themes/${getDomain()}/assets/logo.png`} />
      </Box>
      <Box>
        <ButtonLuz onClick={siteLuzSimulador}>Mude para a LUZ</ButtonLuz>
      </Box>
    </Box>
  );
};

export default NavBarLuz;
