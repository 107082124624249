import RetornoEndpointDto from "../../../global/dto/retornoEndpointDto";
import { store } from "../../../global/redux";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";
import { getDadosFaturas, downloadFatura } from "../../../servicos/dgIntegracaoServico";


export default class FaturasHelperGd {

  static async getDadosFaturas(unidadeId, dataInicio, dataFim) {
    try {

      const resultado = await getDadosFaturas(unidadeId, dataInicio, dataFim);

      return RetornoEndpointDto.Sucesso(
        "Faturas obtidas com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu um erro ao obter a lista de faturas"
      );
    }
  }

  static async downloadFatura(params, nomeUnidade) {
    try {
      await downloadFatura(params, nomeUnidade);

      return RetornoEndpointDto.Sucesso(
        "arquivo obtido com sucesso",
        null
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu um erro ao obter arquivo"
      );
    }
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }
}
