/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from "react";

// Redux
import { Grid } from "@mui/material";
import CustomLoader from "componentes/customLoader";
import { store } from "../../../global/redux";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";

// Componentes
import UploadDropArea from "../../../componentes/upload/index";

// Serviços
import { desabilitarFiltroLateral } from "../../../global/redux/modulos/usuario/actions";
import { uploadIndice } from "../../../servicos/uploadServico";

const IndicesUpload = () => {
  const [carregandoArquivo, setCarregandoArquivo] = useState(false);

  const fazerUpload = async (arquivos) => {
    try {
      setCarregandoArquivo(true);

      const arquivo = arquivos[0];
      const blob = arquivo.file.slice(0, arquivo.file.size, arquivo.file.type);
      const novoArquivo = new File([blob], arquivo.file.name, {
        type: arquivo.file.type
      });

      const upload = await uploadIndice(novoArquivo);

      if (upload?.status === 200 || upload?.status === 204) {
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: `O arquivo ${arquivo.file.name} foi processado com sucesso!`
          })
        );
      }
      setCarregandoArquivo(false);
    } catch (error) {
      setCarregandoArquivo(false);
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  const onAddUpload = (arquivos) => {
    fazerUpload(arquivos);
  };

  const onDropRejected = (valor) => {
    const arquivo = valor[0];
    store.dispatch(
      alertaExibir({
        tipo: "warning",
        mensagem: `Tipo do arquivo ${arquivo.name} não é permitido!`
      })
    );
  };

  useEffect(() => {
    store.dispatch(desabilitarFiltroLateral(true));

    return () => {
      store.dispatch(desabilitarFiltroLateral(false));
    };
  }, [desabilitarFiltroLateral]);

  return (
    <Grid container spacing={3} alignItems="center">
      <CustomLoader>
        <Grid item xs={12}>
          <UploadDropArea
            onAdd={onAddUpload}
            limit={1}
            acceptedFiles={[
              ".xlsm,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            ]}
            dropAreaText={
              <>
                Solte os arquivos para anexá-los ou procurar <br />
                (planilha em excel deverá conter nome "Fator")
              </>
            }
            loader={carregandoArquivo}
            showLoader={false}
            onDropRejected={onDropRejected}
          />
        </Grid>
      </CustomLoader>
    </Grid>
  );
};

export default IndicesUpload;
