export function menuExibir() {
  return {
    type: "@menu/menuExibir"
  };
}

export function menuFechar() {
  return {
    type: "@menu/menuFechar"
  };
}

export function menuFecharTotalmente() {
  return {
    type: "@menu/menuFecharTotalmente"
  };
}

export function menuExibirTotalmente() {
  return {
    type: "@menu/menuExibirTotalmente"
  };
}

export function menuExibirMetade() {
  return {
    type: "@menu/menuExibirMetade"
  };
}

export function menuNaoExibirMetade() {
  return {
    type: "@menu/menuNaoExibirMetade"
  };
}

export function cabecalho(status) {
  return {
    type: "@menu/cabecalho",
    payload: status
  };
}

export function usina(status) {
  return {
    type: "@menu/usina",
    payload: status
  };
}
