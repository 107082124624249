import PropTypes from "prop-types";
import React, { useState } from "react";

import {
  EditOutlined,
  CheckBox,
  CheckBoxOutlineBlank
} from "@mui/icons-material/";
import { Box, Collapse, Grid, IconButton } from "@mui/material";
import theme from "themes";
import ToogleIcon from "./componentes/toggleIcon";

import { useStyles } from "./style";
import enumeradorBotao from "../../enumeradores/enumeradorBotao";

const ItemListaExpansivelResponsiva = ({
  nomeFantasia,
  color,
  cnpj,
  aberto,
  status,
  validade,
  plano,
  onClickEditar,
  numeroInstalacao,
  onClickParceiro,
  usuarioCargoId,
  liberaPropostaContrato,
  cargoId
}) => {
  const classes = useStyles();
  const [itemAberto, setItemAberto] = useState(aberto);

  return (
    <Grid className={classes.boxItem} container>
      <Grid className={classes.gridItem} item xs={2}>
        <Box display="flex" justifyContent="center">
          <ToogleIcon
            aberto={itemAberto}
            color={color}
            onClick={() => setItemAberto(!itemAberto)}
          />
        </Box>
      </Grid>
      <Grid item xs={10}>
        <Grid xs={12} className={classes.label}>
          {nomeFantasia}
        </Grid>
        <Grid xs={12}>{cnpj}</Grid>
        {numeroInstalacao && (
          <Grid xs={12}>Número de Instalação: {numeroInstalacao}</Grid>
        )}
        <Grid xs={6}>
          Status:
          {status}
        </Grid>
        <Grid xs={6}>
          Validade:
          {validade}
        </Grid>
        <Grid xs={12}>
          Plano:
          {plano}
        </Grid>
      </Grid>

      <Collapse className="w-100" in={itemAberto}>
        <Grid container spacing={0}>
          <Grid
            item
            className={`${classes.gridItem} ${classes.treeItem} ${classes.expandItem} fade show`}
          >
            <Grid container>
              {/* <Grid item xs={4}>
                {onClickPDF}
              </Grid> */}

              <Grid item xs={4}>
                <IconButton onClick={onClickEditar} size="large">
                  <Box display="flex" flexDirection="column">
                    <EditOutlined cursor="pointer" htmlColor="#FA3EB1" />
                    <Box
                      component="span"
                      fontSize="10px"
                      mt="7px"
                      fontWeight="600"
                      color="#FA3EB1"
                    >
                      Editar
                    </Box>
                  </Box>
                </IconButton>
              </Grid>
              {usuarioCargoId !== enumeradorBotao.Parceiro &&
                cargoId === enumeradorBotao.Parceiro && (
                  <Grid item xs={4} className={classes.fundoBotoes}>
                    <IconButton onClick={onClickParceiro} size="large">
                      <Box display="flex" flexDirection="column">
                        {liberaPropostaContrato === "S" ? (
                          <CheckBox cursor="pointer" htmlColor="#FA3EB1" />
                        ) : (
                          <CheckBoxOutlineBlank
                            cursor="pointer"
                            htmlColor="#FA3EB1"
                          />
                        )}
                        <Box
                          component="span"
                          fontSize="10px"
                          mt="7px"
                          fontWeight="600"
                          color="#FA3EB1"
                        >
                          Proposta
                        </Box>
                      </Box>
                    </IconButton>
                  </Grid>
                )}
            </Grid>
          </Grid>
        </Grid>
      </Collapse>
    </Grid>
  );
};

ItemListaExpansivelResponsiva.propTypes = {
  nomeFantasia: PropTypes.string,
  cnpj: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  color: PropTypes.string,
  status: PropTypes.string,
  validade: PropTypes.string,
  plano: PropTypes.string,
  aberto: PropTypes.bool,
  onClickEditar: PropTypes.oneOfType([PropTypes.func]),
  numeroInstalacao: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onClickParceiro: PropTypes.oneOfType([PropTypes.func]),
  cargoId: PropTypes.number,
  liberaPropostaContrato: PropTypes.string,
  usuarioCargoId: PropTypes.number
};

ItemListaExpansivelResponsiva.defaultProps = {
  nomeFantasia: "",
  color: theme.color.primary,
  aberto: false,
  status: "",
  validade: "",
  plano: "",
  onClickEditar: () => {},
  numeroInstalacao: "",
  onClickParceiro: () => {},
  cargoId: 0,
  liberaPropostaContrato: "",
  usuarioCargoId: 0
};

export default ItemListaExpansivelResponsiva;
