export default class FiltroDto {
  constructor(
    tamanhoPaginacao,
    pagina,
    searchString,
    ordernar,
    situacao,
    produto,
    tipoEnergia,
    submercado,
    status,
    unidadeSelecionada,
    clienteSelecionado,
    empresaSelecionada
  ) {
    this.tamanhoPaginacao = tamanhoPaginacao;
    this.pagina = pagina;
    this.idOuClienteNome = searchString;
    this.ordernar = ordernar;
    this.situacao = situacao;
    this.produto = produto;
    this.tipoEnergia = tipoEnergia;
    this.submercado = submercado;
    this.status = status;
    this.unidadeId = unidadeSelecionada;
    this.clienteId = clienteSelecionado;
    this.empresaId = empresaSelecionada;
  }
}
