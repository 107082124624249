import TuneIcon from "@mui/icons-material/Tune";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

// Componentes
import { Grid } from "@mui/material";
import { Botao, Loader, SelectArredondado, TabelaPaginada } from "componentes";
import { RotasDTO } from "global/rotas/rotasUrlDto";
import { useSelector } from "react-redux";
import Coluna from "../../../../componentes/tabelaPaginada/colunas/coluna";
import ColunaComponentePersonalizado from "../../../../componentes/tabelaPaginada/colunas/colunaComponentePersonalizado";
import ColunaMultiplosBotoes from "./colunaMultiplosBotoes";
import TermosHelper from "./helper";

// DTO
import ResultadoPaginadoDto from "../../../../componentes/tabelaPaginada/resultadoPaginadoDto";
import FiltroAdicionalTabela from "./dto/filtroAdicionalTabela";
import FiltroDto from "./dto/filtroDto";
import ListagemTermosDto from "./dto/listagemTermosDto";
import enumeradorBotao from "./enumeradores/enumeradorBotao";

// Redux
import { store } from "../../../../global/redux";
import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";

// Styles
import { useStyles } from "./style";

import BotaoFiltroOrdenacao from "../../../../componentes/botaoFiltroOrdenar";
import { listarProdutos } from "../../../../servicos/produtosServico";
import {
  filtroEmpresaPor,
  listarFiltrarPor,
  listarOrganizarPor
} from "../../../../servicos/termoDeUsoServico";
import theme from "../../../../themes";

const ListagemTermos = () => {
  const history = useHistory();
  const global = useSelector((state) => state.usuario);
  const { empresaSelecionada } = global;
  const [produtoLista, setProdutoLista] = useState([]);
  const [carregandoProduto, setCarregandoProduto] = useState(false);
  const [produtoIdSelecionado, setProdutoSelecionado] = useState("0");
  const [filtroAdicionalTabela, setFiltroAdicionalTabela] = useState(
    new FiltroAdicionalTabela()
  );
  const [ordemSelecionada, setOrdemSelecionada] = useState("");
  const [ordemColunaSelecionada, setOrdemColunaSelecionada] = useState();
  const [valorOrdenacao, setValorOrdenacao] = useState();
  const [filtroSelecionado, setFiltroSelecionado] = useState("");
  const [filtroSelecionadoEmpresa, setFiltroSelecionadoEmpresa] = useState("");
  const classes = useStyles();
  const refTabela = useRef(null);

  useEffect(() => {
    obterProduto();
  }, []);

  const obterProduto = async () => {
    try {
      setCarregandoProduto(true);
      const lista = await listarProdutos();

      if (lista?.status === 200 && lista?.data?.produtos) {
        setProdutoLista([
          {
            id: "0",
            descricao: "Todos"
          },
          ...lista?.data.produtos
        ]);
      }

      setCarregandoProduto(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoProduto(false);
    }
  };

  const onClickEditar = (id) => {
    history.push(`${RotasDTO.Termos}/cadastro/${id}`);
  };

  const onClickBotao = (tipo, parametros) => {
    switch (tipo) {
      case enumeradorBotao.Edicao:
        onClickEditar(parametros.id);
        break;
      default:
        break;
    }
  };

  const colunas = [
    new Coluna("id", "Versão"),
    new Coluna("empresa", "Empresa"),
    new Coluna("produto", "Produto"),
    new Coluna("dataInicioVigencia", "Inicio vigência"),
    new Coluna("dataFimVigencia", "Fim vigência"),
    new Coluna("nomeUsuarioResponsavel", "Responsável"),
    new ColunaComponentePersonalizado(
      "opcoes",
      "Editar",
      ColunaMultiplosBotoes,
      onClickBotao,
      false,
      true,
      "11.8%",
      "11.8%",
      "pl-2"
    )
  ];

  const obterTermos = async (parametros) => {
    const filtro = new FiltroDto(
      20,
      parametros?.pagina,
      parametros?.filtroAdicional?.ordenacao ?? "",
      parametros?.filtroAdicional?.filtro ?? "",
      parametros?.filtroAdicional?.tipoEmpresa ?? "",
      parametros?.filtroAdicional?.empresaSelecionada === ""
        ? 0
        : parametros?.filtroAdicional?.empresaSelecionada,
      parametros?.filtroAdicional?.produto === ""
        ? 0
        : parametros?.filtroAdicional?.produto
    );

    const resultado = await TermosHelper.listaTermoDeUso(filtro);

    if (!resultado.sucesso) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: resultado.mensagem
        })
      );
      return new ResultadoPaginadoDto([], 1, 0, 0);
    }

    if (resultado?.data.length === 0 ?? true)
      return new ResultadoPaginadoDto([], 1, 0, 0);

    const resLinhas = resultado?.data?.listaTermos?.map((res) => {
      return new ListagemTermosDto(
        res.id,
        res.dataInicioVigencia,
        res.dataFimVigencia,
        res.dataCadastro,
        res.empresa,
        res.produto,
        res.nomeUsuarioResponsavel
      );
    });

    return new ResultadoPaginadoDto(
      resLinhas,
      resultado?.data?.paginaAtual,
      resultado?.data?.totalItens,
      resultado?.data?.totalPaginas
    );
  };

  const onChangeFiltrosTabela = async (parametros) => {
    return obterTermos({
      tamanhoPagina: parametros?.totalPagina,
      pagina: parametros?.pagina,
      filtroAdicional: new FiltroAdicionalTabela(
        parametros?.filtrosAdicionais?.ordenacao,
        parametros?.filtrosAdicionais?.filtro,
        parametros?.filtrosAdicionais?.tipoEmpresa,
        parametros?.filtrosAdicionais?.empresaSelecionada,
        parametros?.filtrosAdicionais?.produto
      )
    });
  };

  const handleClickAtivarFiltro = () => {
    setFiltroAdicionalTabela(
      new FiltroAdicionalTabela(
        valorOrdenacao,
        filtroSelecionado,
        filtroSelecionadoEmpresa,
        empresaSelecionada,
        produtoIdSelecionado
      )
    );
  };

  const handleAlterarFiltroLateral = () => {
    setFiltroAdicionalTabela(
      new FiltroAdicionalTabela(
        valorOrdenacao,
        filtroSelecionado,
        filtroSelecionadoEmpresa,
        empresaSelecionada,
        produtoIdSelecionado
      )
    );
  };

  useEffect(() => {
    handleAlterarFiltroLateral();
  }, [empresaSelecionada, produtoIdSelecionado]);

  const handleClickSortOrderSelecionado = (valor, idCampoSelecionado) => {
    const val =
      ordemColunaSelecionada === idCampoSelecionado && valor === ""
        ? ""
        : idCampoSelecionado;
    setOrdemColunaSelecionada(val);
    setValorOrdenacao(valor);
  };

  const handleClickOrdemSelecionada = (event) => {
    const val = ordemSelecionada === event ? "" : event;
    setOrdemSelecionada(val);
  };

  const handleClickFiltroSelecionado = (event) => {
    const val = filtroSelecionado === event ? "" : event;
    setFiltroSelecionado(val);
  };

  const handleClickFiltroSelecionadoEmpresa = (event) => {
    const val = filtroSelecionadoEmpresa === event ? "" : event;
    setFiltroSelecionadoEmpresa(val);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={5} md={5} sm={5}>
          <Loader loading={carregandoProduto}>
            <SelectArredondado
              id="produtoId"
              name="produtoId"
              valueKey="id"
              valueName="descricao"
              dataSource={produtoLista}
              label="Produto"
              value={produtoIdSelecionado}
              onChange={(e) => {
                setProdutoSelecionado(e.target.value);
              }}
              placeholder="Produto"
              allowClear
              permiteValorBranco
            />
          </Loader>
        </Grid>
        <Grid item sm={1} xs={1} md={4} lg={4} />
        <Grid item sm={3} xs={4} md={3} lg={3}>
          <BotaoFiltroOrdenacao
            type="button"
            color={theme.color.secondaryBorderColor}
            background="transparent"
            label="Filtrar / Ordernar"
            icon={<TuneIcon />}
            className={classes.buttonFiltro}
            ordenadorUm="Ordenador"
            ordenacaoColuna={listarOrganizarPor()}
            ordemSelecionada={ordemSelecionada}
            ordemColunaSelecionada={ordemColunaSelecionada}
            onClickAtivarAgora={handleClickAtivarFiltro}
            onClickSortOrder={handleClickSortOrderSelecionado}
            onClickOrdenacao={handleClickOrdemSelecionada}
            filtros={listarFiltrarPor()}
            filtrosSelecionados={filtroSelecionado}
            onClickFiltro={handleClickFiltroSelecionado}
            ordenadorDois="Filtrar"
            ordenadorOito="Empresas"
            filtroEmpresas={filtroEmpresaPor()}
            filtroSelecionadosEmpresa={filtroSelecionadoEmpresa}
            onClickFiltroEmpresa={handleClickFiltroSelecionadoEmpresa}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} className="mt-4">
        <TabelaPaginada
          ref={refTabela}
          onChangeFiltrosTabela={onChangeFiltrosTabela}
          colunas={colunas}
          filtrosAdicionais={filtroAdicionalTabela}
          paginaAtual={1}
          itemsPorPagina={20}
          noHeader
        />
      </Grid>
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={3}>
          <Botao
            type="button"
            label="Novo Termo"
            className={classes.button}
            onClick={() => {
              history.push(`${RotasDTO.Termos}/cadastro`);
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ListagemTermos;
