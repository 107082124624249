import * as React from "react";
import PropTypes from "prop-types";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useStyles } from "./style";

export default function Notificacao({ fechar, mensagem }) {
  const classes = useStyles({ tipo: mensagem?.tipo });

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={fechar}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <div>
      <Snackbar
        open={!!mensagem?.aberto}
        autoHideDuration={10000}
        onClose={fechar}
        action={action}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        className={classes.snackbar}
        message={mensagem?.texto}
      />
    </div>
  );
}

Notificacao.propTypes = {
  fechar: PropTypes.oneOfType([PropTypes.func]),
  mensagem: PropTypes.shape({
    aberto: PropTypes.bool,
    tipo: PropTypes.string,
    texto: PropTypes.string
  })
};

Notificacao.defaultProps = {
  fechar: () => {},
  mensagem: { aberto: false, tipo: "success", texto: "Sucesso" }
};
