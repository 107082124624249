export default class PossuiVarejistaDto {
  constructor(id, descricao) {
    this.id = id;
    this.descricao = descricao;
  }

  static obtemDataSource() {
    return [
      new PossuiVarejistaDto("true", "Sim"),
      new PossuiVarejistaDto("false", "Não")
    ];
  }
}
