import { makeStyles } from "@mui/styles";
import { Base } from "componentes/cores";
import theme from "themes";

export const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: "#F2F2F2 !important",
    position: "relative",
    width: "1020px",
    height: "746px",
    display: "flex"
  },
  boxFundoRoxo: {
    position: "relative",
    background:
      "linear-gradient(90deg, rgba(142,53,125,1) 0%, rgba(84,43,121,1) 19%, rgba(57,35,96,1) 56%)",
    borderRadius: "0 50px 50px 0",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "30px",
    width: "480px"
  },
  boxTexto: {
    position: "relative",
    padding: "0 40px",

    "& h1": {
      color: Base.White,
      fontSize: "60px",
      fontFamily: `${theme.font.family}  !important`,
      fontWeight: 700,
      marginBottom: 0
    }
  },
  boxTextoFlex: {
    display: "flex",
    "& h2": {
      color: theme.color.secondary,
      fontSize: "64px",
      fontFamily: `${theme.font.family}  !important`,
      fontWeight: 700,
      marginBottom: 0
    }
  },
  iconeLista: {
    left: "-10px",
    top: "10px",
    width: "25px",
    position: "absolute"
  },
  conteudo: {
    float: "left",
    padding: "30px",
    height: "100%",
    display: "flex",
    alignItems: "center"
  },
  iconeSeta: {
    display: "flex",
    alignSelf: "center",
    width: "80px",
    marginLeft: "20px"
  },
  iconeSol: {
    position: "absolute",
    right: "-70px",
    bottom: "-50px",
    width: "150px"
  },
  iconeLuz: {
    position: "absolute",
    right: 20,
    bottom: 20,
    width: "60px"
  }
}));
