import { RotasDTO } from "../../../../global/rotas/rotasUrlDto";
import RetornoEndpointDto from "../../../../global/dto/retornoEndpointDto";
import { listarMedidores } from "../../../../servicos/medidoresServico";

export default class MedidoresHelper {
  static async ListarMedidores(filtro, history) {
    try {
      const resultado = await listarMedidores(filtro);

      return RetornoEndpointDto.Sucesso(
        "Medidores listados com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Não foi possivel obter a lista de medidores"
      );
    } finally {
      history.push({
        pathname: RotasDTO.Medidores,
        state: { dadosMedidor: null }
      });
    }
  }
}
