/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
// eslint-disable-next-line import/no-extraneous-dependencies
import clsx from "clsx";
import React from "react";
import { useColorlibStepIconStyles } from "../style";

export default function StepIcone(props) {
  const { active, completed, icons, vertical } = props;
  const classes = useColorlibStepIconStyles({ vertical });

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed
      })}
    >
      {icons[String(props.icon - 1)]}
    </div>
  );
}
