import { makeStyles } from "@mui/styles";
import theme from "themes";
import { Base } from "../../../../../../componentes/cores";

export const useStyles = makeStyles(({
  containerColorido: {
    backgroundColor: (props) => props.planoCor,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    color: (props) => props.corTexto,
    padding: "24px"
  },
  containerBranco: {
    padding: "24px"
  },
  containerPadding: {
    padding: "0px 24px",
    "&:first-child": {
      paddingLeft: 0
    },
    "&.ultimoItem": {
      paddingRight: 0
    }
  },
  labelPlano: {
    fontFamily: theme.font.family,
    fontSize: "18px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.4px"
  },
  labelInformacoes: {
    fontFamily: theme.font.family,
    fontSize: "18px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.4px"
  },
  valorInformacoes: {
    fontFamily: theme.font.family,
    fontSize: "24px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.53px"
  },
  valorReducaoCustos: {
    fontFamily: theme.font.family,
    fontSize: "30px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.67px"
  },
  planoNome: {
    fontFamily: theme.font.family,
    fontSize: "30px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.67px"
  },
  containerInformacoes: {
    paddingBottom: "14px"
  },
  botaoEscolher: {
    borderRadius: "21px",
    backgroundColor: Base.Mango,
    width: "100%",
    padding: "10px 22px"
  }
}));
