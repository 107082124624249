/* eslint-disable camelcase */
export const b64toBlob = (b64Data, contentType, sliceSize) => {
  contentType = contentType || "";
  sliceSize = sliceSize || 512;

  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};


export function isPdfFile(base64) {
  const pdfSignatures = [
    [...new Uint8Array([0x25, 0x50, 0x44, 0x46, 0x2D])],    // {"PDF"
    [...new Uint8Array([0xDF, 0xBF, 0x34, 0xEB, 0xCE]) ]    // {"PDF2":
  ]
  
  const binary_string = window.atob(base64);

  const len = binary_string.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
  }
  return pdfSignatures.some(x => arrayEquals(x, [...bytes.slice(0, x.length)]));
}

function arrayEquals(a, b) {
  return Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index]);
}