import { makeStyles } from "@mui/styles";
import DataTable, { createTheme } from "react-data-table-component";
import styled from "styled-components";

import theme from "themes";
import { Base } from "../cores";

export const DataTables = styled(DataTable)`
  .rdt_TableHead {
    display: none !important;
  }
  .rdt_TableCell {
    padding: 0 !important;
  }
  .rdt_TableRow {
    border: none !important;
    background: "red !important";
  }
`;

export const useStyles = makeStyles({
  Container: {
    background: theme.color.red,
    height: "300px",
    marginTop: "2em",
    marginBottom: "2em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  checkBoxBase: {
    "&:checked": {
      color: "black"
    }
  },
  iconeSelecionado: {
    fontWeight: "bold",
    color: "black",
    backgroundColor: `${theme.color.checkButonColor}`
  },
  iconeSemSelecao: {
    color: theme.color.bordaCheckbox,
    backgroundColor: theme.color.fundoCheckbox,
    borderRadius: "2px"
  }
});

export const conditionalRowStyles = [
  {
    when: (row) => !row.ativo,
    style: {
      "& div": {}
    }
  }
];

export const customStyles = {
  table: {
    style: {
      background: "transparent"
    }
  },
  header: {
    style: {
      color: `${Base.OffWhite} !important`,
      background: "transparent"
    }
  },
  subHeader: {
    style: {
      background: "transparent"
    }
  },
  headRow: {
    style: {
      background: "transparent"
    }
  },
  headCells: {
    style: {
      background: theme.color.bgTabela,
      border: "1px solid #D2D2D7",
      fontWeight: "700",
      fontSize: "16px",
      "& div": {
        color: `${theme.color.secondaryBorderColor} !important`,
        "&:hover": {
          color: `${theme.color.secondaryBorderColor} !important`
        }
      },
      "&:first-of-type": {
        borderTopLeftRadius: "8px !important"
      },
      "&:last-of-type": {
        borderTopRightRadius: "8px !important"
      }
    }
  },
  rows: {
    style: {
      fontWeight: "400",
      fontSize: "16px",
      ":last-of-type": {
        "& .rdt_TableCell:first-child": {
          borderBottomLeftRadius: "8px !important"
        },
        "& .rdt_TableCell:last-child": {
          borderBottomRightRadius: "8px !important",
          padding: "5px"
        }
      }
    }
  },
  Pagination: {
    styles: {
      color: `${theme.color.secondaryBorderColor} !important`
    }
  },
  cells: {
    style: {
      color: `${theme.color.secondaryBorderColor} !important`,
      background: theme.color.bgTabela,
      border: "1px solid #D2D2D7",
      fontSize: "14px"
    }
  }
};

export const customStylesMobile = {
  tableWrapper: {
    background: "#2a2b32",
    style: {
      display: "inherit"
    }
  },
  headCells: {
    style: {
      background: "#2a2b32",
      marginLeft: "3px",
      fontSize: "16px",
      "& div": {
        "&:hover": {
          color: `${Base.OffWhite} !important`
        }
      }
    }
  },
  rows: {
    style: {
      marginBottom: "1px",
      marginTop: "1px"
    }
  },
  Pagination: {
    styles: {
      color: "#eef0f9"
    }
  },
  cells: {
    style: {
      marginLeft: 0,
      background: "transparent",
      fontSize: "14px"
    }
  }
};

createTheme({
  background: {
    default: "transparent"
  },
  text: {
    primary: theme.color.secondaryBorderColor,
    secondary: theme.color.secondaryBorderColor
  },
  action: {
    hover: theme.color.secondaryBorderColor
  }
});
