import { makeStyles } from "@mui/styles";
import styled from "styled-components";

export const useStyles = makeStyles(() => ({
  semDados: {
    minHeight: "300px",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    "& h4": {
      color: "#4F4F4F",
      fontSize: "18px",
      fontWeight: 700,
      maxWidth: "330px"
    }
  }
}));

export const IconeSemDados = styled.img`
  height: 50px;
  margin-right: 10px;
  margin-bottom: 15px;
`;
