import { makeStyles } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import theme from "../../../../themes";
import { Base } from "../../../../componentes/cores";

const themes = createTheme();

export const useStyles = makeStyles(() => ({
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    backgroundColor: Base.White,
    outline: "none",
    color: Base.BlackOpacity80,
    borderRadius: "16px",
    padding: "20px 20px 0 20px",
    [themes.breakpoints.down("sm")]: {
      width: "100%",
      padding: "20px"
    }
  },
  cardCabecalho: {
    borderBottom: `1px solid ${Base.TextLuzOpacity}`,
    marginBottom: "5px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "start",
    width: "100%"
  },
  titulo: {
    color: theme.color.primary,
    fontFamily: theme.font.family,
    fontSize: "20px",
    fontWeight: 700,
    marginBottom: "20px"
  },
  closeButton: {
    textDecoration: "none",
    border: "none",
    background: "transparent"
  },
  closeIcone: {
    color: theme.color.secondary
  },
  cardConteudo: {
    display: "flex",
    alignItems: "center"
  },
  bordaDireita: {
    borderRight: `1px solid ${Base.TextLuzOpacity}`,
    [themes.breakpoints.down("sm")]: {
      borderRight: "none"
    }
  },
  bordaTopo: {
    borderTop: `1px solid ${Base.TextLuzOpacity}`,
    [themes.breakpoints.down("sm")]: {
      borderTop: "none"
    }
  },
  altura: {
    minHeight: "110px"
  },
  cardIcone: {
    margin: "10px 10px 10px 0",
    height: "20px"
  }
}));
