export default class FaturaDistribuidoraDto {
  constructor(
    unidadeId,
    nomeUnidade,
    pessoaFisicaJuridica,
    cnpjCpfFormatado,
    dataUploadFormatada,
    guidFatura,
    protocoloDemanda,
    cep,
    nomeDistribuidora,
    origem,
    telefone
  ) {
    this.unidadeId = unidadeId;
    this.nomeUnidade = nomeUnidade;
    this.pessoaFisicaJuridica = pessoaFisicaJuridica;
    this.cnpjCpfFormatado = cnpjCpfFormatado;
    this.dataUploadFormatada = dataUploadFormatada;
    this.guidFatura = guidFatura;
    this.protocoloDemanda = protocoloDemanda;
    this.cep = cep;
    this.nomeDistribuidora = nomeDistribuidora;
    this.origem = origem;
    this.telefone = telefone;
  }
}