import MICROSERVICO from "../global/enumeradores/microServico";
import obterApiLuz from "./apiLuz";

const apiLuz = (log) => obterApiLuz(MICROSERVICO.SITE_LUZ_LEAD, log);

export const criarLeadSalesForceServico = async (body, id) => {
  const metodo = id ? "put" : "post";
  if (id) {
    body.id = id ?? "";
  }
  const log = {
    tipo : id ? "atualizaLog" : "enviaLog",
    nestedField : id ? "put_SFIntegracaoEnergia_PreLead" : "post_SFIntegracaoEnergia_PreLead",
    index : `cotacao-gd-${process.env.REACT_APP_ENVIRONMENT}`,
    mainFields : "data.dados.idDoPreLeadNoSalesforce",
    messege : "Cotação GD Area Logada",
    nameMainFields : "idDoPreLeadNoSalesforce"
  }
  return apiLuz(log)[metodo]("/v1/SFIntegracaoEnergia/PreLead", body);
};
