/* eslint-disable react/jsx-one-expression-per-line */
import React from "react";
import { Box, Grid } from "@mui/material";
import PropTypes from "prop-types";
import EsqueletoEtapa from "../../Componentes/EsqueletoEtapa";
import { Botao } from "../../../../../componentes";

const ProcessoMigracao = ({ prazo, showButton, agendar }) => {
  const onClickAgendar = () => {
    agendar();
  };
  const handleTexto = () => {
    return (
      <Box>
        <Box py={1}>
          Início do processo de migração da sua distribuidora atual para LUZ!
          Esse processo pode levar até 15 dias úteis.
        </Box>
        <Box py={1}>
          Durante esse período, pode ser que você receba faturas da sua
          distribuidora
          <b> em seu nome</b>. Caso isso aconteça, fique tranquilo e realize o
          pagamento para garantir o fornecimento de sua energia.
        </Box>
        <Box py={1}>
          Em caso de dúvidas, nós te ajudamos! Basta entrar em contato por meio
          dos canais de atendimento.
        </Box>
      </Box>
    );
  };
  return (
    <>
      <EsqueletoEtapa
        prazo={prazo}
        titulo="Processo de migração"
        subtitulo={handleTexto()}
        esconderBotaoProximo={false}
        showButton={showButton}
      />
      <Box component={Grid} container spacing={2} pt={2}>
        <Grid item xs={12} lg={4} />
        <Grid item xs={12} lg={4} />
        <Grid item xs={12} lg={4}>
          <Botao
            type="button"
            label="Fale com o atendimento"
            onClick={() => onClickAgendar()}
          />
        </Grid>
      </Box>
    </>
  );
};

ProcessoMigracao.propTypes = {
  prazo: PropTypes.node,
  showButton: PropTypes.bool,
  agendar: PropTypes.oneOfType([PropTypes.func])
};

ProcessoMigracao.defaultProps = {
  prazo: "",
  showButton: false,
  agendar: () => {}
};

export default ProcessoMigracao;
