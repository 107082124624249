import { makeStyles } from "@mui/styles";
import theme from "themes";
import { Base } from "../../../../componentes/cores";

export const useStyles = makeStyles({
  containerColorido: {
    padding: "24px",
    backgroundColor: (props) => props.planoCor,
    color: (props) => props.corTexto,
    borderBottomLeftRadius: "0px",
    borderBottomRightRadius: "0px"
  },
  containerBranco: {
    padding: "24px"
  },
  containerPadding: {
    padding: 0
  },
  labelPlano: {
    fontFamily: theme.font.family,
    fontSize: "18px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.4px"
  },
  labelInformacoes: {
    fontFamily: theme.font.family,
    fontSize: "18px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.4px"
  },
  valorInformacoes: {
    fontFamily: theme.font.family,
    fontSize: "24px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.53px"
  },
  valorReducaoCustos: {
    fontFamily: theme.font.family,
    fontSize: "30px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.67px"
  },
  planoNome: {
    fontFamily: theme.font.family,
    fontSize: "30px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.67px"
  },
  containerInformacoes: {
    paddingBottom: "24px"
  },
  divCabecalho: {
    color: `${Base.White} !important`,
    fontSize: "24px"
  },
  botaoEscolherDepois: {
    borderRadius: "22px",
    borderWidth: 2,
    color: `${Base.White} !important`,
    fontSize: "18px"
  },
  botaoEscolher: {
    borderRadius: "22px",
    backgroundColor: Base.Mango,
    padding: "10px 22px",
    width: "100%"
  },
  informacoesAsterisco: {
    color: `${Base.White} !important`,
    fontSize: "14px",
    marginTop: 24
  },
  dataValidade: {
    color: `${Base.White} !important`,
    fontSize: "14px",
    marginTop: 24
  }
});
