import { Grid } from "@mui/material";
import React from "react";

// import { Container } from './styles';

// eslint-disable-next-line react/prop-types
function TabelaSemDados({ className }) {
  return (
    <>
      <Grid container>
        <Grid item xs={12} className={className}>
          <h4>Nenhum registro encontrado</h4>
        </Grid>
      </Grid>
    </>
  );
}

export default TabelaSemDados;
