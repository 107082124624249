export default class UnidadeConsumidoraDto {
    constructor(id, descricao) {
      this.id = id.toString();
      this.descricao = descricao;    

    }
  
    static mapearParaDto(dados) {        
      return dados.map(
        (unidade) =>
          new UnidadeConsumidoraDto(          
            unidade.id,
            unidade.descricao
          )
      );
    }
  
    static obterMock() {
      return [
        new UnidadeConsumidoraDto("1","Unidade1"),
        new UnidadeConsumidoraDto("2","Unidade2"),
        new UnidadeConsumidoraDto("3","Unidade3"),
        new UnidadeConsumidoraDto("4","Unidade4")
      ];
    }
  }