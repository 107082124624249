import React from "react";

import IconeLuz from "../../../../../assets/icones/workflow/luz.svg";
import IconeCamera from "../../../../../assets/icones/workflow/camera.svg";
import IconeSucesso from "../../../../../assets/icones/workflow/sucesso.svg";
import { useStyles } from "./style";

const PassoFoto = () => {
  const classes = useStyles();

  return (
    <div className={classes.card}>
      <h3 className={classes.tituloCard}>Como enviar seu documento:</h3>
      <div className={classes.subTituloCard}>
        <img src={IconeLuz} className="mr-2" alt="ícone luz" />
        <p>Ao tirar uma foto, verifique se está em um lugar bem iluminado.</p>
      </div>
      <div className={classes.subTituloCard}>
        <img src={IconeCamera} className="mr-2" alt="ícone câmera" />
        <p>
          Enquadre na câmera do celular.Ao tirar uma foto, verifique se está em
          um lugar bem iluminado.
        </p>
      </div>
      <div className={classes.subTituloCard}>
        <img src={IconeSucesso} className="mr-2" alt="ícone sucesso" />
        <p>
          Ao enviar a foto ou arquivo, confirme se consegue ler todas as
          informações.
        </p>
      </div>
    </div>
  );
};
export default PassoFoto;
