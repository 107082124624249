import React from "react";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import { createTheme } from "@mui/material/styles";

import BgCardLogin from "../../assets/BgCardLogin.png";

const theme = createTheme();

const useStyles = makeStyles(() => ({
  paper: {
    backgroundImage: `url(${BgCardLogin})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.up("sm")]: {
      width: 468
    }
  }
}));

// eslint-disable-next-line react/prop-types
const CardLogin = ({ children }) => {
  const classes = useStyles();

  return (
    <Box className={`${classes.paper} mx-auto card shadow-lg border-0`}>
      <div className="card-body">{children}</div>
    </Box>
  );
};

export default CardLogin;
