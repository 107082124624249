import getDomain from "../global/getDomain";

import thymos from "./thymos/paginas/home";
import delta from "./delta/paginas/home";
import luz from "./luz/paginas/home";

const pagina = {
  delta,
  thymos,
  luz
};

export default pagina[getDomain()];
