export default class FaturaMLListaDto {
  constructor(
    unidadeId,
    unidadeConsumidora,
    mes,
    ano,
    mesReferencia,
    faturaProcessada
  ) {
    this.unidadeId = unidadeId;
    this.unidadeConsumidora = unidadeConsumidora;
    this.mes = mes;
    this.ano = ano;
    this.mesReferencia = mesReferencia;    
    this.faturaProcessada = faturaProcessada;
  }
}
