import React from "react";
import PropTypes from "prop-types";

import AddIcon from "@mui/icons-material/Add";

import { Base } from "../cores";

import { useStyles, Botao, Borda } from "./style";

// eslint-disable-next-line react/prop-types
const BotaoCadastro = ({ label, color, onClick, className, disabled }) => {
  const classes = useStyles({ color, disabled });

  return (
    <Botao
      role="button"
      onClick={(e) => onClick(e)}
      onKeyDown={(e) => onClick(e)}
      tabIndex="0"
      className={`${classes.botao} ${className}`}
      disabled={disabled}
    >
      <Borda className={classes.borda} />
      <AddIcon className={classes.icone} />
      {label}
    </Botao>
  );
};

BotaoCadastro.propTypes = {
  label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  color: PropTypes.string,
  onClick: PropTypes.oneOfType([PropTypes.func]),
  className: PropTypes.string
};

BotaoCadastro.defaultProps = {
  color: Base.CharcoalGrey,
  onClick: () => {},
  className: ""
};

export default BotaoCadastro;
