import RetornoEndpointDto from "../../../global/dto/retornoEndpointDto";
import { store } from "../../../global/redux";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";
import {
  gerarPlanosSimulados,
  obterPlanosSimulados,
  selecionarPlanoSimulado
} from "../../../servicos/planosServico";
import PlanoDataDto from "./Dtos/planoDataDto";
import PlanoSimuladoDto from "./Dtos/planoSimuladoDto";

export default class PlanosSimuladosHelper {


  static async simularPlanos(idCotacao) {
    try {
      const resultado = await gerarPlanosSimulados(idCotacao);

      const { data } = resultado;

      if (!data || !data.planosSimulados)
        return RetornoEndpointDto.Erro(
          "Ocorreu uma falha ao obter os planos simulados"
        );

      return RetornoEndpointDto.Sucesso(
        "Planos obtidos com sucesso",
        new PlanoDataDto(
          PlanoSimuladoDto.mapearParaDto(data.planosSimulados),
          data.dataCotacao,
          data.dataValidade,
          data.fechada
        )
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu uma falha ao obter os planos simulados"
      );
    }
  }


  static async ObterPlanosSimulados(idCotacao) {
    try {
      const resultado = await obterPlanosSimulados(idCotacao);

      const { data } = resultado;

      if (!data || !data.planosSimulados)
        return RetornoEndpointDto.Erro(
          "Ocorreu uma falha ao obter os planos simulados"
        );

      return RetornoEndpointDto.Sucesso(
        "Planos obtidos com sucesso",
        new PlanoDataDto(
          PlanoSimuladoDto.mapearParaDto(data.planosSimulados),
          data.dataCotacao,
          data.dataValidade,
          data.fechada
        )
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu uma falha ao obter os planos simulados"
      );
    }
  }

  static async selecionarPlano(idPlano, cotacaoId,dadosFatura) {
    try {
      await selecionarPlanoSimulado(idPlano, cotacaoId,dadosFatura);

      return RetornoEndpointDto.Sucesso("Plano escolhido com sucesso");
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu uma falha ao escolher o plano simulado"
      );
    }
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }

  static exibirSucesso(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem
      })
    );
  }
}
