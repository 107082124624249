import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const api = () => obterApi(MICROSERVICO.MEASUREMENT);

// Buscar parcela de carga
export const buscarParcelaDeCarga = async (cnpj) => {
  return api().get(
    `v1/parcelaativoccee?EmpresaId=1&CodigoPerfil=115&Cnpj=${cnpj}`
  );
};
