import RetornoEndpointDto from "../../../global/dto/retornoEndpointDto";
import { listarPaginadoSemFatura } from "../../../servicos/listagemCotacoesGdServico";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";
import { store } from "../../../global/redux";

export default class ListagemCotacaoHelper {
  static async listarCotacoesSemFatura(filtro) {
    try {
      const resultado = await listarPaginadoSemFatura(filtro);

      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Lista de cotações gd não encontrada!");

      return RetornoEndpointDto.Sucesso(
        "Listagem de cotações gd obtidas com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu um erro ao obter a lista de cotações gd!"
      );
    }
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }

  static exibirSucesso(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem
      })
    );
  }
}
