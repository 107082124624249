import RetornoEndpointDto from "../../../../global/dto/retornoEndpointDto";
import { ListarDistribuidoraPisCofins } from "../../../../servicos/distribuidoraPisCofinsServico";

export default class DistribuidoraPisCofinsHelper {
  static async ListarDistribuidoraPisCofins(filtro) {
    try {
      const resultado = await ListarDistribuidoraPisCofins(filtro);

      return RetornoEndpointDto.Sucesso(
        "Distribuidora Pis/Cofins obtidas com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu um erro ao obter a lista de distribuidora Pis/Cofins"
      );
    }
  }
}
