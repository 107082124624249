import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Grid } from "@mui/material";
import { EditOutlined } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import DataTable from "react-data-table-component";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Coluna from "componentes/tabelaPaginada/colunas/coluna";
import ColunaBotao from "componentes/tabelaPaginada/colunas/colunaBotao";
import ModalConfirmacao from "componentes/modalConfirmacao";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { useStyles, customStyles, conditionalRowStyles } from "./style";
import RotasDTO from "../../../rotas/rotasUrlDto";
import { buscarVigenciasBandeiras, deletarBandeira } from "../../../servicos/bandeirasTarifarias";
import { Base } from "componentes/cores";

const BandeiraTarifariaAtual = () => {
  const history = useHistory();
  const classes = useStyles();

  const [data] = useState(window.moment().format("YYYY-MM-DD"));
  const [atual, setAtual] = useState();;
  const [futura, setFutura] = useState();

  const [modalExclusao, setModalExclusao] = useState(false);

  const obterBandeiras = async (abortController) => {
    try {
      const lista = await buscarVigenciasBandeiras(data, {
        signal: abortController.signal,
      });

      if (lista?.status === 200 && lista?.data) {

        if (lista?.data?.vigenciaAtual !== null) {
          const response = [lista?.data?.vigenciaAtual]
          const organizado = [];
          response.map((item) => organizado.push({
            ...item,
            dataInicial: window.moment(item.dtVigencia).format("DD-MM-YYYY"),
            verde: item.valorVerde.toLocaleString("pt-BR", { style: "currency", currency: "BRL", minimumFractionDigits: 3 }),
            amarelo: item.valorAmarelo.toLocaleString("pt-BR", { style: "currency", currency: "BRL", minimumFractionDigits: 3 }),
            vermelho1: item.valorVermelho1.toLocaleString("pt-BR", { style: "currency", currency: "BRL", minimumFractionDigits: 3 }),
            vermelho2: item.valorVermelho2.toLocaleString("pt-BR", { style: "currency", currency: "BRL", minimumFractionDigits: 3 })
          }));
          setAtual(organizado);
          setFutura(lista?.data?.vigenciaFutura)
        } else {
          setFutura(lista?.data?.vigenciaFutura)
          setAtual([])
        }
      }
    } catch (error) {
      if (error.name === "AbortError") {
        // Handling error thrown by aborting request
      }

      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  const onClickNova = () => {
    if (futura.length) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Não é possivel inserir uma nova vigência, pois já existe uma agendada."
        })
      );
    } else {
      history.push(`${RotasDTO.BandeirasTarifariasCadastrar}/atual`);
    }
  }

  const onClickEditar = (id) => {
    history.push(`${RotasDTO.BandeirasTarifariasCadastrar}/atual/${id}`);
  };

  const excluir = async (id) => {
    try {
      if (futura.length > 0) {
        const deletarFutura = await deletarBandeira(futura[0].id);
        if (deletarFutura.status === 200 && deletarFutura.data) {
          store.dispatch(
            alertaExibir({
              tipo: "success",
              mensagem: `Vigência Futura: ${deletarFutura.data}`
            })
          );

          const deletarAtual = await deletarBandeira(id);
          if (deletarAtual.status === 200 && deletarAtual.data) {
            store.dispatch(
              alertaExibir({
                tipo: "success",
                mensagem: `Vigência Atual: ${deletarAtual.data}`
              })
            );

            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        }
      } else {
        const deletarAtual = await deletarBandeira(id);
        if (deletarAtual.status === 200 && deletarAtual.data) {
          store.dispatch(
            alertaExibir({
              tipo: "success",
              mensagem: deletarAtual.data
            })
          );

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  }

  const onConfirmarExclusao = (item) => {
    try {
      excluir(item);
      setModalExclusao(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  }

  const onClickExcluir = (item) => {
    setModalExclusao(item);
  };

  const colunas = [
    new Coluna("dataInicial", "Data"),
    new Coluna("verde", "Verde"),
    new Coluna("amarelo", "Amarela"),
    new Coluna("vermelho1", "Vermelha 1"),
    new Coluna("vermelho2", "Vermelha 2"),
  ];

  const colunasComEdição = [
    ...colunas,
    new ColunaBotao(
      "editar",
      "",
      onClickEditar,
      <EditOutlined style={{ color: Base.White }} />,
      false,
      "70px",
      "70px",
      true
    )];

  const colunasComExclusão = [
    ...colunasComEdição,
    new ColunaBotao(
      "excluir",
      "",
      onClickExcluir,
      <DeleteIcon style={{ color: Base.White }} />,
      false,
      "70px",
      "70px",
      true
    )
  ]

  useEffect(() => {
    const abortController = new AbortController();
    obterBandeiras(abortController);
    return () => {
      abortController.abort();
    }
  }, []);

  return (
    <>
      <Grid container>
        <Grid item sm={12} className="mt-5">
          <ModalConfirmacao
            item={modalExclusao}
            onConfirmar={(item) => onConfirmarExclusao(item)}
            mensagem="Tem certeza que deseja excluir essa vigência?"
            onCancelar={() => setModalExclusao(false)}
          />

          <div style={{ display: "flex", height: "100%", width: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              {atual?.length > 0 ?
                <DataTable
                  noHeader
                  subHeader
                  subHeaderAlign='right'
                  subHeaderComponent={<AddCircleIcon className={classes.icon} onClick={() => onClickNova()} />}
                  columns={colunasComExclusão}
                  data={atual}
                  theme="DeltaEnergia"
                  customStyles={customStyles}
                  conditionalRowStyles={conditionalRowStyles}
                  noDataComponent="Nenhum registro encontrado"
                />
                :
                <Grid container p={2} spacing={2}>
                  <Grid item>
                    <AddCircleIcon className={classes.icon} onClick={() => onClickNova()} />
                  </Grid>
                  <Grid item sm={12} className={classes.customTextoNoData}>
                    Nenhum registro encontrado
                  </Grid>
                </Grid>
              }
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container p={2} spacing={4} className={classes.container}>
        <Grid item sm={12} style={{ color: "white" }}>
          * Observação: Valores em R$ para cada 100 kWh
        </Grid>
      </Grid>
    </>
  )
}

export default BandeiraTarifariaAtual;