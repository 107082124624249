import PropTypes from "prop-types";
import React, { useCallback, useMemo, useState } from "react";
import shortid from "shortid";

import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { TreeView } from "@mui/x-tree-view/TreeView";
import CampoTexto from "../../paginas/Cadastros/ArvoreGerencial/componentes/campoTexto";
import BotaoCadastro from "../botaoCadastro";
import TreeViewIcon from "./treeViewIcon";
import TreeViewItem from "./treeViewItem";
import TreeViewParentIcon from "./treeViewParentIcon";

// Styles
// eslint-disable-next-line import/order
import theme from "themes";
import { useStyles } from "./style";

const TreeViewArvoreGerencial = ({
  dataSource,
  editable,
  onEditar,
  nodeEdicao,
  onSalvar,
  deletable,
  onDeletar,
  selectable,
  onSelecionar,
  selecionados,
  nodesExpanded,
  lblBotaoCadastroRoot,
  lblBotaoCadastroNivelFilho,
  lblBotaoCadastroNivelNeto,
  lblBotaoCadastroNivel3,
  lblBotaoCadastroNivel4,
  empresasSelecionadas
}) => {
  const classes = useStyles();
  const [nodesAtivos, setNodesAtivos] = useState();

  const verificarSelecao = useCallback(
    (idArvore, idEmpresa) => {
      return !!selecionados.find(
        (selecionado) =>
          selecionado.idArvore === idArvore &&
          selecionado.idEmpresa === idEmpresa
      );
    },
    [selecionados]
  );

  const verificarSelecaoEmpresa = useCallback(
    (idEmpresa) => {
      return empresasSelecionadas.includes(idEmpresa);
    },
    [empresasSelecionadas]
  );

  const onClickSelecionar = (node, idEmpresa) => {
    onSelecionar(node, idEmpresa);
  };

  const expanded = useMemo(() => {
    if (nodesExpanded?.length) return nodesExpanded;
    if (nodesAtivos?.length) return nodesAtivos;
    return [];
  }, [nodesExpanded, nodesAtivos]);

  const [novoCadastroRoot, setNovoCadastroRoot] = useState(false);
  const [novoCadastroNivel, setNovoCadastroNivel] = useState({});

  return (
    <>
      {dataSource?.length ? (
        <TreeView
          classes={{ root: classes.treeView }}
          disableSelection
          defaultEndIcon={<TreeViewParentIcon />}
          onNodeToggle={(_event, itens) => setNodesAtivos(itens)}
          expanded={[...expanded]}
        >
          {editable &&
            (novoCadastroRoot ? (
              <CampoTexto
                full
                nivel={0}
                onEditar={() => setNovoCadastroRoot(false)}
                onSalvar={(dados) => {
                  if (onSalvar(dados)) setNovoCadastroRoot(false);
                }}
              />
            ) : (
              <BotaoCadastro
                label={lblBotaoCadastroRoot}
                color={theme.color.buttonColor}
                className={classes.button}
                onClick={() => setNovoCadastroRoot(true)}
              />
            ))}
          {dataSource.map((empresa) => {
            return (
              <TreeItem
                key={empresa.id}
                nodeId={String(empresa.id)}
                classes={{
                  content: classes.content,
                  label: classes.label,
                  iconContainer: classes.iconContainer,
                  group: classes.group
                }}
                label={
                  nodeEdicao && nodeEdicao?.id === empresa.id ? (
                    <CampoTexto
                      level={0}
                      nivel={0}
                      node={empresa}
                      onEditar={() => onEditar()}
                      onSalvar={(dados) => onSalvar(dados)}
                    />
                  ) : (
                    <TreeViewItem
                      level={0}
                      editable={editable}
                      onEditar={(node) => onEditar(node)}
                      deletable={deletable}
                      onDeletar={(node) => onDeletar(node)}
                      node={empresa}
                      childs
                      label={`${empresa.nomeFantasia ?? empresa.descricao} • ${
                        empresa?.id
                      }`}
                      selectable={selectable}
                      onSelecionar={(node) =>
                        onClickSelecionar(node, empresa.id)
                      }
                      selecionados={selecionados}
                      selected={verificarSelecaoEmpresa(empresa.id)}
                    />
                  )
                }
                expandIcon={
                  <TreeViewIcon
                    ativo={empresa.situacao.toUpperCase() === "AT"}
                    nodeId={String(empresa.id)}
                    level={!editable ? 0 : 1}
                    nodesAtivos={nodesAtivos}
                  />
                }
                collapseIcon={
                  <TreeViewIcon
                    ativo={empresa.situacao.toUpperCase() === "AT"}
                    nodeId={String(empresa.id)}
                    level={!editable ? 0 : 1}
                    nodesAtivos={nodesAtivos}
                  />
                }
              >
                {editable ? (
                  <TreeItem
                    key={shortid.generate()}
                    nodeId={shortid.generate()}
                    classes={{
                      content: classes.content,
                      label: classes.label,
                      iconContainer: classes.iconContainer,
                      group: classes.group
                    }}
                    label={
                      novoCadastroNivel === empresa.id ? (
                        <CampoTexto
                          level={1}
                          nivel={1}
                          nodePai={empresa}
                          onEditar={() => setNovoCadastroNivel()}
                          onSalvar={(dados) => onSalvar(dados)}
                        />
                      ) : (
                        <BotaoCadastro
                          label={lblBotaoCadastroNivelFilho}
                          color={theme.color.fourthBackgroud}
                          className={classes.iconCrud}
                          onEditar={(node) => onEditar(node)}
                          onClick={() => setNovoCadastroNivel(empresa.id)}
                        />
                      )
                    }
                  />
                ) : null}
                {empresa?.arvoreGerencialColecao?.length
                  ? empresa.arvoreGerencialColecao.map((filho) => {
                      return (
                        <TreeItem
                          key={String(filho.id)}
                          nodeId={String(filho.id)}
                          classes={{
                            content: classes.content,
                            label: classes.label,
                            iconContainer: classes.iconContainer,
                            group: classes.group
                          }}
                          className="withChildren"
                          label={
                            nodeEdicao && nodeEdicao?.id === filho.id ? (
                              <CampoTexto
                                level={1}
                                nivel={1}
                                node={filho}
                                nodePai={empresa}
                                onEditar={() => onEditar()}
                                onSalvar={(dados) => onSalvar(dados)}
                              />
                            ) : (
                              <TreeViewItem
                                level={1}
                                editable={editable}
                                onEditar={(node) => onEditar(node)}
                                deletable={deletable}
                                onDeletar={(node) => onDeletar(node)}
                                node={filho}
                                childs
                                label={`${filho.descricao} • ${filho?.id}`}
                                selectable={selectable}
                                onSelecionar={(node) =>
                                  onClickSelecionar(node, empresa.id)
                                }
                                selecionados={selecionados}
                                selected={verificarSelecao(
                                  filho.id,
                                  empresa.id
                                )}
                              />
                            )
                          }
                          expandIcon={
                            <TreeViewIcon
                              ativo={filho.situacao.toUpperCase() === "AT"}
                              nodeId={`${filho.id}_${empresa.id}`}
                              level={!editable ? 1 : 2}
                              nodesAtivos={nodesAtivos}
                            />
                          }
                          collapseIcon={
                            <TreeViewIcon
                              ativo={filho.situacao.toUpperCase() === "AT"}
                              nodeId={`${filho.id}_${empresa.id}`}
                              level={!editable ? 1 : 2}
                              nodesAtivos={nodesAtivos}
                            />
                          }
                        >
                          {editable ? (
                            <TreeItem
                              key={shortid.generate()}
                              nodeId={shortid.generate()}
                              classes={{
                                content: classes.content,
                                label: classes.label,
                                iconContainer: classes.iconContainer,
                                group: classes.group
                              }}
                              label={
                                novoCadastroNivel === filho.id ? (
                                  <CampoTexto
                                    level={1}
                                    nivel={2}
                                    nodePai={filho}
                                    onEditar={() => setNovoCadastroNivel()}
                                    onSalvar={(dados) => onSalvar(dados)}
                                  />
                                ) : (
                                  <BotaoCadastro
                                    label={lblBotaoCadastroNivelNeto}
                                    color={theme.color.fourthBackgroud}
                                    className={classes.iconCrud}
                                    onEditar={(node) => onEditar(node)}
                                    onClick={() =>
                                      setNovoCadastroNivel(filho.id)
                                    }
                                  />
                                )
                              }
                            />
                          ) : null}
                          {filho?.arvoreGerencialColecao?.length
                            ? filho.arvoreGerencialColecao.map((neto) => {
                                return (
                                  <TreeItem
                                    key={neto.id}
                                    nodeId={String(neto.id)}
                                    classes={{
                                      content: classes.content,
                                      label: classes.label,
                                      iconContainer: classes.iconContainer,
                                      group: classes.group
                                    }}
                                    className="withChildren"
                                    label={
                                      nodeEdicao &&
                                      nodeEdicao?.id === neto.id ? (
                                        <CampoTexto
                                          level={2}
                                          nivel={2}
                                          node={neto}
                                          nodePai={filho}
                                          onEditar={() => onEditar()}
                                          onSalvar={(dados) => onSalvar(dados)}
                                        />
                                      ) : (
                                        <TreeViewItem
                                          level={2}
                                          editable={editable}
                                          onEditar={(node) => onEditar(node)}
                                          deletable={deletable}
                                          onDeletar={(node) => onDeletar(node)}
                                          node={neto}
                                          childs={Boolean(
                                            neto.arvoreGerencialColecao.length
                                          )}
                                          label={`${neto.descricao} • ${neto?.id}`}
                                          selectable={selectable}
                                          onSelecionar={(node) =>
                                            onClickSelecionar(node, empresa.id)
                                          }
                                          selecionados={selecionados}
                                          selected={verificarSelecao(
                                            neto.id,
                                            empresa.id
                                          )}
                                        />
                                      )
                                    }
                                    expandIcon={
                                      <TreeViewIcon
                                        ativo={
                                          neto.situacao.toUpperCase() === "AT"
                                        }
                                        nodeId={`${neto.id}_${empresa.id}`}
                                        level={!editable ? 2 : 3}
                                        nodesAtivos={nodesAtivos}
                                      />
                                    }
                                    collapseIcon={
                                      <TreeViewIcon
                                        ativo={
                                          neto.situacao.toUpperCase() === "AT"
                                        }
                                        nodeId={`${neto.id}_${empresa.id}`}
                                        level={!editable ? 2 : 3}
                                        nodesAtivos={nodesAtivos}
                                      />
                                    }
                                  >
                                    {editable ? (
                                      <TreeItem
                                        key={shortid.generate()}
                                        nodeId={shortid.generate()}
                                        classes={{
                                          content: classes.content,
                                          label: classes.label,
                                          iconContainer: classes.iconContainer,
                                          group: classes.group
                                        }}
                                        label={
                                          novoCadastroNivel === neto.id ? (
                                            <CampoTexto
                                              level={3}
                                              nivel={3}
                                              nodePai={neto}
                                              onEditar={() =>
                                                setNovoCadastroNivel()
                                              }
                                              onSalvar={(dados) =>
                                                onSalvar(dados)
                                              }
                                            />
                                          ) : (
                                            <BotaoCadastro
                                              label={lblBotaoCadastroNivel3}
                                              color={
                                                theme.color.fourthBackgroud
                                              }
                                              className={classes.iconCrud}
                                              onEditar={(node) =>
                                                onEditar(node)
                                              }
                                              onClick={() =>
                                                setNovoCadastroNivel(neto.id)
                                              }
                                            />
                                          )
                                        }
                                      />
                                    ) : null}

                                    {neto?.arvoreGerencialColecao?.length
                                      ? neto.arvoreGerencialColecao.map(
                                          (bisneto) => {
                                            return (
                                              <TreeItem
                                                key={bisneto.id}
                                                nodeId={String(bisneto.id)}
                                                classes={{
                                                  content: classes.content,
                                                  label: classes.label,
                                                  iconContainer:
                                                    classes.iconContainer,
                                                  group: classes.group
                                                }}
                                                className="withChildren"
                                                label={
                                                  nodeEdicao &&
                                                  nodeEdicao?.id ===
                                                    bisneto.id ? (
                                                    <CampoTexto
                                                      level={4}
                                                      nivel={4}
                                                      node={bisneto}
                                                      nodePai={neto}
                                                      onEditar={() =>
                                                        onEditar()
                                                      }
                                                      onSalvar={(dados) =>
                                                        onSalvar(dados)
                                                      }
                                                    />
                                                  ) : (
                                                    <TreeViewItem
                                                      level={4}
                                                      editable={editable}
                                                      onEditar={(node) =>
                                                        onEditar(node)
                                                      }
                                                      deletable={deletable}
                                                      onDeletar={(node) =>
                                                        onDeletar(node)
                                                      }
                                                      node={bisneto}
                                                      childs={Boolean(
                                                        bisneto
                                                          .arvoreGerencialColecao
                                                          .length
                                                      )}
                                                      label={`${bisneto.descricao} • ${bisneto?.id}`}
                                                      selectable={selectable}
                                                      onSelecionar={(node) =>
                                                        onClickSelecionar(
                                                          node,
                                                          empresa.id
                                                        )
                                                      }
                                                      selecionados={
                                                        selecionados
                                                      }
                                                      selected={verificarSelecao(
                                                        bisneto.id,
                                                        empresa.id
                                                      )}
                                                    />
                                                  )
                                                }
                                                expandIcon={
                                                  <TreeViewIcon
                                                    ativo={
                                                      bisneto.situacao.toUpperCase() ===
                                                      "AT"
                                                    }
                                                    nodeId={`${bisneto.id}_${empresa.id}`}
                                                    level={!editable ? 3 : 4}
                                                    nodesAtivos={nodesAtivos}
                                                  />
                                                }
                                                collapseIcon={
                                                  <TreeViewIcon
                                                    ativo={
                                                      bisneto.situacao.toUpperCase() ===
                                                      "AT"
                                                    }
                                                    nodeId={`${bisneto.id}_${empresa.id}`}
                                                    level={!editable ? 3 : 4}
                                                    nodesAtivos={nodesAtivos}
                                                  />
                                                }
                                              >
                                                {editable ? (
                                                  <TreeItem
                                                    key={shortid.generate()}
                                                    nodeId={shortid.generate()}
                                                    classes={{
                                                      content: classes.content,
                                                      label: classes.label,
                                                      iconContainer:
                                                        classes.iconContainer,
                                                      group: classes.group
                                                    }}
                                                    label={
                                                      novoCadastroNivel ===
                                                      bisneto.id ? (
                                                        <CampoTexto
                                                          level={4}
                                                          nivel={4}
                                                          nodePai={bisneto}
                                                          onEditar={() =>
                                                            setNovoCadastroNivel()
                                                          }
                                                          onSalvar={(dados) =>
                                                            onSalvar(dados)
                                                          }
                                                        />
                                                      ) : (
                                                        <BotaoCadastro
                                                          label={
                                                            lblBotaoCadastroNivel4
                                                          }
                                                          color={
                                                            theme.color
                                                              .fourthBackgroud
                                                          }
                                                          className={
                                                            classes.iconCrud
                                                          }
                                                          onEditar={(node) =>
                                                            onEditar(node)
                                                          }
                                                          onClick={() =>
                                                            setNovoCadastroNivel(
                                                              bisneto.id
                                                            )
                                                          }
                                                        />
                                                      )
                                                    }
                                                  />
                                                ) : null}

                                                {bisneto?.arvoreGerencialColecao
                                                  ?.length
                                                  ? bisneto.arvoreGerencialColecao.map(
                                                      (tataraneto) => {
                                                        return (
                                                          <TreeItem
                                                            key={tataraneto.id}
                                                            nodeId={String(
                                                              tataraneto.id
                                                            )}
                                                            classes={{
                                                              content:
                                                                classes.content,
                                                              label:
                                                                classes.label,
                                                              iconContainer:
                                                                classes.iconContainer,
                                                              group:
                                                                classes.group
                                                            }}
                                                            className={
                                                              !editable
                                                                ? "withChildren"
                                                                : ""
                                                            }
                                                            label={
                                                              nodeEdicao &&
                                                              nodeEdicao?.id ===
                                                                tataraneto.id ? (
                                                                <CampoTexto
                                                                  level={5}
                                                                  nivel={5}
                                                                  node={
                                                                    tataraneto
                                                                  }
                                                                  nodePai={
                                                                    bisneto
                                                                  }
                                                                  onEditar={() =>
                                                                    onEditar()
                                                                  }
                                                                  onSalvar={(
                                                                    dados
                                                                  ) =>
                                                                    onSalvar(
                                                                      dados
                                                                    )
                                                                  }
                                                                />
                                                              ) : (
                                                                <TreeViewItem
                                                                  level={5}
                                                                  editable={
                                                                    editable
                                                                  }
                                                                  onEditar={(
                                                                    node
                                                                  ) =>
                                                                    onEditar(
                                                                      node
                                                                    )
                                                                  }
                                                                  deletable={
                                                                    deletable
                                                                  }
                                                                  onDeletar={(
                                                                    node
                                                                  ) =>
                                                                    onDeletar(
                                                                      node
                                                                    )
                                                                  }
                                                                  node={
                                                                    tataraneto
                                                                  }
                                                                  childs={Boolean(
                                                                    tataraneto
                                                                      .arvoreGerencialColecao
                                                                      .length
                                                                  )}
                                                                  label={`${tataraneto.descricao} • ${tataraneto?.id}`}
                                                                  selectable={
                                                                    selectable
                                                                  }
                                                                  onSelecionar={(
                                                                    node
                                                                  ) =>
                                                                    onClickSelecionar(
                                                                      node,
                                                                      empresa.id
                                                                    )
                                                                  }
                                                                  selecionados={
                                                                    selecionados
                                                                  }
                                                                  selected={verificarSelecao(
                                                                    tataraneto.id,
                                                                    empresa.id
                                                                  )}
                                                                />
                                                              )
                                                            }
                                                            expandIcon={
                                                              <TreeViewIcon
                                                                ativo={
                                                                  tataraneto.situacao.toUpperCase() ===
                                                                  "AT"
                                                                }
                                                                nodeId={`${tataraneto.id}_${empresa.id}`}
                                                                level={
                                                                  !editable
                                                                    ? 4
                                                                    : 5
                                                                }
                                                                nodesAtivos={
                                                                  nodesAtivos
                                                                }
                                                              />
                                                            }
                                                            collapseIcon={
                                                              <TreeViewIcon
                                                                ativo={
                                                                  tataraneto.situacao.toUpperCase() ===
                                                                  "AT"
                                                                }
                                                                nodeId={`${tataraneto.id}_${empresa.id}`}
                                                                level={
                                                                  !editable
                                                                    ? 4
                                                                    : 5
                                                                }
                                                                nodesAtivos={
                                                                  nodesAtivos
                                                                }
                                                              />
                                                            }
                                                          >
                                                            {tataraneto
                                                              ?.arvoreGerencialColecao
                                                              ?.length
                                                              ? tataraneto.arvoreGerencialColecao.map(
                                                                  (final) => {
                                                                    return (
                                                                      <TreeItem
                                                                        key={
                                                                          final.id
                                                                        }
                                                                        nodeId={String(
                                                                          final.id
                                                                        )}
                                                                        classes={{
                                                                          content:
                                                                            classes.content,
                                                                          label:
                                                                            classes.label,
                                                                          iconContainer:
                                                                            classes.iconContainer,
                                                                          group:
                                                                            classes.group
                                                                        }}
                                                                        label={
                                                                          nodeEdicao &&
                                                                          nodeEdicao?.id ===
                                                                            final.id ? (
                                                                            <CampoTexto
                                                                              level={
                                                                                6
                                                                              }
                                                                              nivel={
                                                                                6
                                                                              }
                                                                              node={
                                                                                final
                                                                              }
                                                                              nodePai={
                                                                                tataraneto
                                                                              }
                                                                              onEditar={() =>
                                                                                onEditar()
                                                                              }
                                                                              onSalvar={(
                                                                                dados
                                                                              ) =>
                                                                                onSalvar(
                                                                                  dados
                                                                                )
                                                                              }
                                                                            />
                                                                          ) : (
                                                                            <TreeViewItem
                                                                              level={
                                                                                5
                                                                              }
                                                                              editable={
                                                                                editable
                                                                              }
                                                                              onEditar={(
                                                                                node
                                                                              ) =>
                                                                                onEditar(
                                                                                  node
                                                                                )
                                                                              }
                                                                              deletable={
                                                                                deletable
                                                                              }
                                                                              onDeletar={(
                                                                                node
                                                                              ) =>
                                                                                onDeletar(
                                                                                  node
                                                                                )
                                                                              }
                                                                              node={
                                                                                final
                                                                              }
                                                                              childs={Boolean(
                                                                                final
                                                                                  .arvoreGerencialColecao
                                                                                  .length
                                                                              )}
                                                                              label={`${final.descricao} • ${final?.id}`}
                                                                              selectable={
                                                                                selectable
                                                                              }
                                                                              onSelecionar={(
                                                                                node
                                                                              ) =>
                                                                                onClickSelecionar(
                                                                                  node,
                                                                                  empresa.id
                                                                                )
                                                                              }
                                                                              selecionados={
                                                                                selecionados
                                                                              }
                                                                              selected={verificarSelecao(
                                                                                final.id,
                                                                                empresa.id
                                                                              )}
                                                                            />
                                                                          )
                                                                        }
                                                                        expandIcon={
                                                                          <TreeViewIcon
                                                                            ativo={
                                                                              bisneto.situacao.toUpperCase() ===
                                                                              "AT"
                                                                            }
                                                                            nodeId={`${final.id}_${empresa.id}`}
                                                                            level={
                                                                              6
                                                                            }
                                                                            nodesAtivos={
                                                                              nodesAtivos
                                                                            }
                                                                          />
                                                                        }
                                                                        collapseIcon={
                                                                          <TreeViewIcon
                                                                            ativo={
                                                                              final.situacao.toUpperCase() ===
                                                                              "AT"
                                                                            }
                                                                            nodeId={`${final.id}_${empresa.id}`}
                                                                            level={
                                                                              6
                                                                            }
                                                                            nodesAtivos={
                                                                              nodesAtivos
                                                                            }
                                                                          />
                                                                        }
                                                                      />
                                                                    );
                                                                  }
                                                                )
                                                              : null}
                                                          </TreeItem>
                                                          // eslint-disable-next-line max-len
                                                          // se precisar colocar mais um nivel e só adicionar como child
                                                        );
                                                      }
                                                    )
                                                  : null}
                                              </TreeItem>
                                            );
                                          }
                                        )
                                      : null}
                                  </TreeItem>
                                );
                              })
                            : null}
                        </TreeItem>
                      );
                    })
                  : null}
              </TreeItem>
            );
          })}
        </TreeView>
      ) : null}
    </>
  );
};

TreeViewArvoreGerencial.propTypes = {
  dataSource: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  editable: PropTypes.bool,
  onEditar: PropTypes.oneOfType([PropTypes.func]),
  nodeEdicao: PropTypes.oneOfType([PropTypes.object]),
  onSalvar: PropTypes.oneOfType([PropTypes.func]),
  deletable: PropTypes.bool,
  onDeletar: PropTypes.oneOfType([PropTypes.func]),
  selectable: PropTypes.bool,
  onSelecionar: PropTypes.oneOfType([PropTypes.func]),
  selecionados: PropTypes.oneOfType([PropTypes.array]),
  nodesExpanded: PropTypes.oneOfType([PropTypes.any]),
  lblBotaoCadastroRoot: PropTypes.string,
  lblBotaoCadastroNivelFilho: PropTypes.string,
  lblBotaoCadastroNivelNeto: PropTypes.string,
  lblBotaoCadastroNivel3: PropTypes.string,
  lblBotaoCadastroNivel4: PropTypes.string,

  empresasSelecionadas: PropTypes.oneOfType([PropTypes.array])
};

TreeViewArvoreGerencial.defaultProps = {
  dataSource: [],
  editable: false,
  onEditar: () => {},
  nodeEdicao: {},
  onSalvar: () => {},
  deletable: false,
  onDeletar: () => {},
  selectable: false,
  onSelecionar: () => {},
  selecionados: [],
  nodesExpanded: [],
  lblBotaoCadastroRoot: "Novo pai",
  lblBotaoCadastroNivelFilho: "Novo filho",
  lblBotaoCadastroNivelNeto: "Novo neto",
  lblBotaoCadastroNivel3: "Novo nivel 3",
  lblBotaoCadastroNivel4: "Novo nivel 4",
  empresasSelecionadas: []
};

export default TreeViewArvoreGerencial;
