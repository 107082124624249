import { Box, Card, Grid } from "@mui/material";
import moment from "moment";
import mapeamentoEtapaFluxo from "paginas/CotacaoGD/constantes/constanteMapeamentoFluxo";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
// import { GetApp } from "@mui/icons-material";
import { GetApp } from "@mui/icons-material";
import { Search } from "@material-ui/icons";
import enumeradorBotao from "../../../constantes/constanteParceiro";
import {
  cardDescricao,
  tipoProduto,
  tipoProdutoDescricao,
  tipoProdutoId
} from "../../../../../global/utils/tipoProdutoUtil";
import {
  formatarCampo,
  validarCpfCnpj
} from "../../../../../global/utils/formatCpfCnpj";
import MaterialInputTexto from "../../../../../componentes/inputTexto/materialInput";
import Cofre from "../../../../../assets/cotacoesGd/cofre.png";
import Home from "../../../../../assets/cotacoesGd/home.png";
import Informacao from "../../../../../assets/cotacoesGd/informacao.png";
import CustomLoader from "../../../../../componentes/customLoader";
import ModalLuz from "../../../../../componentes/modalLuz";
import ModalMensagemErro from "../../../../../componentes/ModalMensagemErro";
import { InterfaceDTO } from "../../../../../global/dto/interfacesDto";
import { handleLoading } from "../../../../../global/redux/modulos/loader/actions";
import { RotasDTO } from "../../../../../global/rotas/rotasUrlDto";
import { usuarioPossuiFuncionalidade } from "../../../../../servicos/funcionalidadesServico";
import {
  formataMoedaReais,
  formataPercentual,
  handlePdf,
  removeMaskCep,
  removeMaskCpfCnpj,
  removeMaskRG,
  removeMaskTelefone,
  validacaoTrimObject
} from "../../../../../servicos/utils";
import CardsPlanos from "../../componentes/CardsPlanos";
import FooterCards from "../../componentes/FooterCards";
import TituloPagina from "../../componentes/TituloPagina";
import UnidadeConsumidora from "../../componentes/UnidadeConsumidora";
import BotaoForm from "../componentes/botaoForm";
import InformacaoEmpresa from "./componente/InformacaoUsuario";
import SiteLuzLeadHelper from "./helper";
import { useStyles } from "./style";
import CotacaoGDHelper from "../../helper";

const ContratacaoPJ = ({
  setPassoAtual,
  dadosCepCobertura,
  obterCepCobertura,
  nomeContratante,
  emailContratante,
  telefoneContratante,
  dadosFatura,
  dadosMatrizGD,
  dadosLeadSalesForce,
  dadosFormSimulador,
  externoIdSemFatura,
  setEmails,
  dadosOfficer,
  dadosTiposPlanos
}) => {
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue
  } = useForm({
    reValidateMode: "onSubmit"
  });

  const {
    register: registerModal,
    handleSubmit: handleSubmitModal,
    errors: errorsModal
  } = useForm({
    reValidateMode: "onSubmit"
  });

  const [ufId, setUFId] = useState("");
  const [semFidelidade, setSemFidelidade] = useState(false);
  const [desconto1AnoSel, setDesconto1Ano] = useState(false);
  const [desconto2AnosSel, setDesconto2Anos] = useState(false);

  const [officer, setOfficer] = useState([]);
  const [matrizGD, setMatrizGD] = useState([]);
  const [salesForcesId, setSalesForcesId] = useState("");
  const [fatura, setFatura] = useState([]);
  const [dadosForm, setDadosFormSimulador] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [coberturaCep, setCoberturaCep] = useState([]);
  const [desconto, setDesconto] = useState(null);
  const [periodo, setPeriodo] = useState(null);
  const [error, setError] = useState(false);
  const [economia, setEconomia] = useState(null);
  const [dadosPlanos, setDadosPLanos] = useState([]);
  const [enviarForm, setEnviarForm] = useState(true);
  const history = useHistory();
  const [dadosCotacaoId, setDadosCotacaoId] = useState(null);
  const [dataCotacao, setDataCotacao] = useState(null);
  const [dataValidade, setDataValidade] = useState(null);
  const [envelopeId, setEnvelopeId] = useState(null);
  const [exibirModalMensagem, setExibirModalMensagem] = useState(false);
  const [dadosPlanosGerados, setDadosPlanosGerados] = useState([]);
  const [representantes, setRepresentantes] = useState([]);
  const { id } = useParams();
  const [dadosCotacaoGd, setDadosCotacao] = useState([]);
  const [exibirModalValidacao, setExibirModalValidacao] = useState(false);
  const [cotacaoId, setCotacaoId] = useState(null);
  const [faturaProcessada, setFaturaProcessada] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [valorCustoTotal, setValorCustoTotal] = useState(null);
  const usuario = useSelector((state) => state.usuario);
  const rotas = useSelector((state) => state.rotas);
  const { menu } = usuario;
  const [statusVigenciaFechado, setStatusVigenciaFechado] = useState(false);
  const [mensagemModal, setMensagemModal] = useState("");
  const [modalAcao, setModalAcao] = useState(false);
  const dispatch = useDispatch();
  const [statusLoading, setStatusLoading] = useState(
    "Aguarde! Processando Cliente."
  );

  const [gerarProposta, setGerarProposta] = useState(null);
  const [produtoEscolhido, setProdutoEscolhido] = useState("");
  const [etapaFluxo, setEtapaFluxo] = useState(false);
  const [cpfCnpjAntigo, setCpfCnpjAntigo] = useState(null);
  const [numeroInstalacaoAntigo, setNumeroInstalacaoAntigo] = useState(null);
  const [exibirModalInput, setExibirModalInput] = useState(false);
  const [modalMensagemInput, setModalMensagemInput] = useState("");
  const [cpfCnpjParceiro, setCpfCnpjParceiro] = useState(null);
  const [validaParceiro, setValidaParceiro] = useState(true);
  const [dadosFormParceiro, setDadosFormParceiro] = useState({});
  const [parceiroEncontrado, setParceiroEncontrado] = useState(null);
  const [validaEmail, setValidaEmail] = useState(true);

  const editarInputs = usuarioPossuiFuncionalidade(
    menu,
    rotas.rotaAtual.caminho,
    RotasDTO.CotacoesGD,
    InterfaceDTO.PermissaoEditarCamposCotacoes
  );

  useEffect(() => {
    setFatura(dadosFatura?.[0]);
    setSalesForcesId(dadosLeadSalesForce?.data?.dados?.idDoPreLeadNoSalesforce);
    setNumeroInstalacaoAntigo(dadosFatura?.[0]?.numeroInstalacao);
  }, [dadosFatura, dadosLeadSalesForce]);

  useEffect(() => {
    setDadosFormSimulador(dadosFormSimulador);
    setCpfCnpjAntigo(dadosFormSimulador?.cnpjCliente);
  }, [dadosFormSimulador]);

  useEffect(() => {
    setCoberturaCep(dadosCepCobertura);
  }, [dadosCepCobertura]);

  useEffect(() => {
    setOfficer(dadosOfficer);
  }, [dadosOfficer]);

  const {
    planoDesconto1,
    planoDesconto2,
    planoDesconto3,
    planoEconomia1,
    planoEconomia2,
    planoEconomia3,
    periodo1,
    periodo2,
    periodo3,
    label1,
    label2,
    label3,
    rodape1,
    rodape2,
    rodape3,
    showPlano1,
    showPlano2,
    showPlano3,
    matrizId,
    planos1,
    planos2,
    planos3,
    edicaoDesconto
  } = matrizGD;

  const {
    consumo,
    faseId,
    nivelTensaoId,
    fileNameLead,
    decricaoDistribuidora,
    cnpjDistribuidora,
    distribuidoraId,
    nomeEmpresa,
    nomeCompleto,
    email,
    telefone,
    cupom,
    cnpjCliente,
    tipoPlanoSelecionado,
    taxaIluminacao,
    valorSemGd,
    cep,
    icms,
    cupomMGM,
    parceiro
  } = dadosForm || [];

  const {
    numeroInstalacao,
    dataLeitura,
    status,
    codigoCliente,
    leituraSubGrupo,
    leituraFase,
    leituraICMS,
    inadimplencia
  } = fatura || [];

  useEffect(() => {
    if (!semFidelidade && !desconto1AnoSel && !desconto2AnosSel) {
      setPeriodo(null);
      setDesconto(null);
      setEconomia(null);
    }
  }, [semFidelidade, desconto1AnoSel, desconto2AnosSel]);

  const handleName = (nameCard, matriz) => {
    if (nameCard === "DescontoSemFidelidade") {
      setSemFidelidade(true);
      setDesconto1Ano(false);
      setDesconto2Anos(false);
      setDesconto(matriz?.planoDesconto1);
      setPeriodo(matriz?.periodo1);
      setEconomia(matriz?.planoEconomia1);
      setProdutoEscolhido(matriz?.planos1);
    } else if (nameCard === "Desconto1Ano" || nameCard === "Desconto3Anos") {
      setSemFidelidade(false);
      setDesconto1Ano(true);
      setDesconto2Anos(false);
      setDesconto(matriz?.planoDesconto2);
      setPeriodo(matriz?.periodo2);
      setEconomia(matriz?.planoEconomia2);
      setProdutoEscolhido(matriz?.planos2);
    } else if (nameCard === "Desconto2Anos" || nameCard === "Desconto5Anos") {
      setSemFidelidade(false);
      setDesconto1Ano(false);
      setDesconto2Anos(true);
      setDesconto(matriz?.planoDesconto3);
      setPeriodo(matriz?.periodo3);
      setEconomia(matriz?.planoEconomia3);
      setProdutoEscolhido(matriz?.planos3);
    }
  };

  const handlePLanosDescricao = (tipoPlanoCotacaoId, planos) => {
    return planos?.find((item) => item.tipoPlano === tipoPlanoCotacaoId)
      ?.descricao;
  };

  const obterCotacoesGdId = async (idGD, planos, matriz) => {
    const {
      data,
      sucesso,
      mensagem
    } = await SiteLuzLeadHelper.obterCotacaoGdId(idGD);
    if (!sucesso) {
      SiteLuzLeadHelper.exibirErro(mensagem);
      setEtapaFluxo(true);
      return;
    }

    setDadosCotacaoId(data?.id);
    setDataCotacao(data?.dataCotacao);
    setDataValidade(data?.dataValidade);
    setSalesForcesId(
      data?.preLead || dadosLeadSalesForce?.data?.dados?.idDoPreLeadNoSalesforce
    );
    setEnvelopeId(data?.envelopeId);
    setDadosPlanosGerados(data?.planosGerados);
    setFaturaProcessada(data?.faturaProcessada);
    setValorCustoTotal(data?.valorDaConta);
    setStatusVigenciaFechado(data?.status === "FE");

    const tipoPlanoCotacaoId = data?.planosGerados?.find(
      (item) => item?.cotacaoSelecionada === "S" && item.ativo === "S"
    )?.tipoPlanoCotacaoId;

    handleName(handlePLanosDescricao(tipoPlanoCotacaoId, planos), matriz);

    setDadosCotacao(data);
    setNumeroInstalacaoAntigo(data?.numeroInstalacao);
    setCpfCnpjParceiro(formatarCampo(data?.cpfCnpjIndicador));
  };

  const obterCotacoesIdRepresentantes = async (idGD) => {
    const {
      data,
      sucesso,
      mensagem
    } = await SiteLuzLeadHelper.obterCotacoesIdRepresentantes(idGD);
    if (!sucesso) {
      SiteLuzLeadHelper.exibirErro(mensagem);
      setEtapaFluxo(true);
      return;
    }

    setRepresentantes(data?.representantes);
  };

  useEffect(() => {
    setDadosPLanos(dadosTiposPlanos?.tipoPlanoCotacoes);
    setMatrizGD(dadosMatrizGD);

    if (id) {
      obterCotacoesGdId(id, dadosTiposPlanos?.tipoPlanoCotacoes, dadosMatrizGD);
      obterCotacoesIdRepresentantes(id);
    }
  }, [dadosTiposPlanos, dadosMatrizGD]);

  const handlePLanosId = (descricao) => {
    return dadosPlanos?.find((item) => item.descricao === descricao)?.id;
  };

  const atualizarSalesForce = async (modelForm, gerouContrato, etapa) => {
    const model = {
      id: salesForcesId ?? "",
      reducaoDeCusto: economia ?? 0,
      pessoaFisicaJuridica: "PJ",
      contratoGerado: !!gerouContrato,
      cpfCnpj: removeMaskCpfCnpj(modelForm?.cnpj) ?? "",
      nome: nomeCompleto,
      email,
      telefone: removeMaskTelefone(telefone),
      erroGenericoSimulacao: error,
      observacoes: "",
      numeroInstalacao: modelForm?.numeroInstalacao ?? null,
      produtoEscolhidoDoCliente: produtoEscolhido ?? "",
      descontoEscolhidoPeloCliente: desconto ? `${desconto}` : "",
      uf: modelForm?.uf ?? "",
      cidade: modelForm?.cidade ?? "",
      numero: modelForm?.numero ?? null,
      nomeFantasia: modelForm?.nomeEmpresaContratante || "",
      cpfRepresentanteLegal:
        removeMaskCpfCnpj(modelForm?.cpfRepresentante) ?? "",
      cpfCnpjMgmParceiro: parceiroEncontrado
        ? removeMaskCpfCnpj(cpfCnpjParceiro)
        : "",
      mgmParceiro: !!parceiroEncontrado,
      edicaoDesconto: edicaoDesconto === "S",
      produto: tipoProdutoDescricao(distribuidoraId)
    };

    if (fileNameLead) {
      model.guidArquivoDaDigitalizacao = salesForcesId || externoIdSemFatura;
      model.nomeDoAnexoComExtensao = fileNameLead ?? "";
    }

    if (gerouContrato && !etapaFluxo && etapa === "form") {
      model.etapa = mapeamentoEtapaFluxo.enviarContrato;
    } else if (gerouContrato === null && !etapaFluxo && etapa === "form") {
      model.etapa = mapeamentoEtapaFluxo.salvarCotacao;
    } else if (gerouContrato === null && !etapaFluxo && etapa === "unidade") {
      model.etapa = mapeamentoEtapaFluxo.unidadeConsumidora;
    } else if (etapaFluxo) {
      model.etapa = mapeamentoEtapaFluxo.erroApi3;
    }

    if (
      cpfCnpjAntigo &&
      removeMaskCpfCnpj(modelForm?.cnpj) !== removeMaskCpfCnpj(cpfCnpjAntigo)
    ) {
      model.edicaoCPFCNPJ = true;
      model.dataAlteracaoCPFCNPJ = window.moment().format();
    }

    if (
      numeroInstalacaoAntigo &&
      modelForm?.numeroInstalacao !== numeroInstalacaoAntigo
    ) {
      model.edicaoInstalacao = true;
      model.dataAlteracaoInstalacao = window.moment().format();
    }

    const { sucesso, mensagem } = await SiteLuzLeadHelper.atualizarSalesForce(
      model,
      handleLoading,
      setError,
      dispatch,
      setStatusLoading,
      enviarForm
    );
    if (!sucesso) {
      SiteLuzLeadHelper.exibirErro(mensagem);
      dispatch(handleLoading(false));
      setEtapaFluxo(true);
    } else {
      if (etapa === "unidade") return;
      if (!enviarForm) {
        history.push(`${RotasDTO.CotacoesGDListagem}`);
        return;
      }
      setPassoAtual("3contratado");
    }
  };

  const atualizarCotacaoGD = async (termo, cotacaoGd) => {
    const model = {
      envelopeId: termo?.envelopeId,
      naoExcluiRepresentante: true,
      preLead: salesForcesId || ""
    };

    const { sucesso, mensagem } = await SiteLuzLeadHelper.atualizarCotacaoGD(
      cotacaoGd?.id,
      model,
      setError,
      handleLoading,
      dispatch
    );

    if (!sucesso) {
      SiteLuzLeadHelper.exibirErro(mensagem);
      setEtapaFluxo(true);
    }
  };

  const criarTermoAdesao = async (dados, modelForm, modelPlano, cotacaoGd) => {
    let gerouContrato = null;

    if (enviarForm) {
      const model = {
        idLeadPlanoSelecionado: dados?.id ?? null,
        cnpj: removeMaskCpfCnpj(modelForm?.cnpj) ?? null,
        cep: removeMaskCep(modelForm?.cep) ?? null,
        endereco: modelForm?.endereco ?? null,
        numero: modelForm?.numero ?? null,
        uf: modelForm?.uf ?? null,
        bairro: modelForm?.bairro ?? null,
        cidade: modelForm?.cidade ?? null,
        telefone: removeMaskTelefone(modelForm?.telefoneRepresentante) ?? null,
        email: modelForm?.emailRepresentante ?? null,
        complemento: modelForm?.complemento ?? null,
        consumo: dados?.consumoMedioConta ?? null,
        demanda: null,
        nomeCompleto: modelForm?.nomeRepresentante ?? null,
        percentualDesconto: dados?.percentualDesconto ?? null,
        razaoSocialDistribuidora: decricaoDistribuidora ?? null,
        cnpjDistribuidora: removeMaskCpfCnpj(cnpjDistribuidora) ?? null,
        periodoPlano: dados?.periodoMeses ?? null,
        nomeFantasia: modelForm?.nomeEmpresaContratante ?? null,
        dataPrevistaMigracao: moment().add(1, "months").format(),
        numeroInstalacao: modelForm?.numeroInstalacao ?? null,
        leadClienteId: modelPlano?.leadClienteId ?? null,
        distribuidoraId,
        leadUnidadeId: modelPlano?.leadUnidadeId ?? null,
        preleadId: salesForcesId || ""
      };

      const {
        data,
        sucesso,
        mensagem
      } = await SiteLuzLeadHelper.criarTermoAdesao(
        model,
        setError,
        handleLoading,
        dispatch,
        setStatusLoading
      );

      gerouContrato = data?.envelopeId;

      if (!sucesso) {
        SiteLuzLeadHelper.exibirErro(mensagem);
        dispatch(handleLoading(false));
        setEtapaFluxo(true);
        return;
      }
      atualizarCotacaoGD(data, cotacaoGd);
    }

    atualizarSalesForce(modelForm, gerouContrato, "form");
  };

  const criarLeadPlanoSelecionado = async (
    modelPlano,
    modelForm,
    cotacaoGd
  ) => {
    let response = null;

    if (desconto && periodo) {
      const model = {
        leadUnidadeId: modelPlano?.leadUnidadeId,
        leadClienteId: modelPlano?.leadClienteId,
        valorMedioConta: valorSemGd ?? 0,
        consumoMedioConta: consumo ?? null,
        percentualDesconto: desconto,
        assinado: "N",
        demandaContratada: 0,
        periodoMeses: periodo
      };

      const {
        data,
        sucesso,
        mensagem
      } = await SiteLuzLeadHelper.criarLeadPlanoSelecionado(
        model,
        setError,
        handleLoading,
        dadosCotacaoId,
        dispatch
      );

      response = data;

      if (!sucesso) {
        SiteLuzLeadHelper.exibirErro(mensagem);
        dispatch(handleLoading(false));
        setEtapaFluxo(true);
        return;
      }
      criarTermoAdesao(data, modelForm, modelPlano, cotacaoGd);
    } else {
      criarTermoAdesao(response, modelForm, modelPlano, cotacaoGd);
    }
  };

  const criarLeadUnidade = async (dados, modelForm, cotacaoGd) => {
    const model = {
      cnpjCpf: removeMaskCpfCnpj(modelForm.cnpj) ?? "",
      nomeUnidade: modelForm?.nomeEmpresaContratante ?? "",
      nomeRazaoSocial: modelForm?.nomeEmpresaContratante ?? "",
      cep: removeMaskCep(modelForm?.cep) ?? "",
      endereco: modelForm?.endereco ?? "",
      numero: modelForm?.numero ?? "",
      uf: modelForm?.uf ?? "",
      bairro: modelForm?.bairro ?? "",
      cidade: modelForm?.cidade ?? "",
      complemento: modelForm?.complemento ?? null,
      telefone: removeMaskTelefone(modelForm?.telefoneRepresentante) ?? "",
      distribuidoraId: distribuidoraId ?? null,
      numeroInstalacao: modelForm?.numeroInstalacao ?? null,
      icms: icms ?? 0,
      nivelTensaoId: nivelTensaoId ?? null,
      classeTarifariaId: null,
      demandaContratadaPonta: null,
      demandaContratadaForaPonta: null,
      dataLeitura: moment(dataLeitura).date() ?? null,
      diaFaturamentoDistribuidora: moment(dataLeitura).date() ?? null,
      numerofasesId: faseId ?? null,
      pessoaFisicaJuridica: "PJ",
      arvoreGerencialId: officer?.arvore?.id,
      colaboradorId: officer?.officers?.id,
      empresaGrupoDeltaId: officer?.arvore?.empresaId,
      tipoRepresentacaoId: 4,
      origem: "AreaLogada",
      cupom: cupom ?? null,
      cosip: taxaIluminacao ?? null,
      produtoId: tipoProdutoId(distribuidoraId),
      guidFatura: salesForcesId,
      clienteId: dados?.id,
      status: "NI",
      contaSemGD: valorSemGd ?? 0,
      consumoKwh: consumo ?? 0,
      codigoCliente: modelForm?.codigoCliente ?? ""
    };

    const {
      data,
      sucesso,
      mensagem
    } = await SiteLuzLeadHelper.criarLeadUnidade(
      model,
      setError,
      handleLoading,
      dadosCotacaoId,
      dispatch
    );
    if (!sucesso) {
      SiteLuzLeadHelper.exibirErro(mensagem);
      dispatch(handleLoading(false));
      setEtapaFluxo(true);
      return;
    }
    const modelPlano = {
      leadUnidadeId: data?.id,
      leadClienteId: dados?.id
    };

    criarLeadPlanoSelecionado(modelPlano, modelForm, cotacaoGd);
  };

  const criarLeadClienteLuzPJ = async (dados, cotacaoGd) => {
    const representante = [];

    if (dados?.nomeRepresentante) {
      representante.push({
        nomeCompleto: dados?.nomeRepresentante,
        cpf: removeMaskCpfCnpj(dados?.cpfRepresentante),
        email: dados?.emailRepresentante,
        telefone: removeMaskTelefone(dados?.telefoneRepresentante),
        clienteCnpjCpf: removeMaskCpfCnpj(dados?.cnpj),
        contato: true
      });
    }

    if (dados?.nomeRepresentante2) {
      representante.push({
        nomeCompleto: dados?.nomeRepresentante2,
        cpf: removeMaskCpfCnpj(dados?.cpfRepresentante2),
        email: dados?.emailRepresentante2,
        telefone: removeMaskTelefone(dados?.telefoneRepresentante2),
        clienteCnpjCpf: removeMaskCpfCnpj(dados?.cnpj),
        contato: false
      });
    }

    if (representantes?.[0]?.id) {
      representante[0].id = representantes[0]?.id;
      representante[0].leadClienteId = representantes[0]?.leadClienteId;
    }

    if (dados?.nomeRepresentante2 && representantes?.[1]?.id) {
      representante[1].id = representantes[1]?.id;
      representante[1].leadClienteId = representantes[1]?.leadClienteId;
    }

    const model = {
      id: 0,
      cnpjCpf: removeMaskCpfCnpj(dados?.cnpj),
      razaoSocial: dados?.nomeEmpresaContratante,
      nomeFantasia: dados?.nomeEmpresaContratante,
      representantes: representante,
      cep: removeMaskCep(dados?.cep),
      endereco: dados?.endereco,
      numero: dados?.numero,
      uf: dados?.uf,
      bairro: dados?.bairro,
      cidade: dados?.cidade,
      complemento: dados?.complemento ?? null,
      telefone: removeMaskTelefone(dados?.telefoneRepresentante),
      pessoaFisicaJuridica: "PJ",
      arvoreGerencialId: officer?.arvore?.id,
      colaboradorId: officer?.officers?.id,
      empresaGrupoDeltaId: officer?.arvore?.empresaId,
      origem: "AreaLogada",
      nomeContatoInicial: dados?.nomeRepresentante,
      emailContatoInicial: dados?.emailRepresentante,
      cpfContatoInicial: removeMaskCpfCnpj(dados?.cpfRepresentante),
      rgContatoInicial: removeMaskRG(dados?.rgRepresentante),
      cotacaoGdId: cotacaoGd?.id
    };

    const {
      data,
      sucesso,
      mensagem
    } = await SiteLuzLeadHelper.criarLeadClienteLuzPJ(
      model,
      setError,
      handleLoading,
      dadosCotacaoId,
      dispatch
    );

    if (!sucesso) {
      SiteLuzLeadHelper.exibirErro(mensagem);
      dispatch(handleLoading(false));
      setEtapaFluxo(true);
      return;
    }
    criarLeadUnidade(data, dados, cotacaoGd);
  };

  const criarCotacaoGDPlanosGerado = async (dados, modelForm) => {
    const modelPlanos = [];
    if (showPlano1) {
      modelPlanos.push({
        cotacaoGdId: dados?.id,
        tipoPlanoCotacaoId: handlePLanosId(planos1),
        produtoId: tipoProdutoId(distribuidoraId),
        economiaAnual: planoEconomia1,
        percentualDesconto: planoDesconto1,
        cotacaoSelecionada: periodo1 === periodo ? "S" : "N",
        matrizGdId: matrizId,
        ativo: "S",
        edicaoDesconto
      });
    }
    if (showPlano2) {
      modelPlanos.push({
        cotacaoGdId: dados?.id,
        tipoPlanoCotacaoId: handlePLanosId(planos2),
        produtoId: tipoProdutoId(distribuidoraId),
        economiaAnual: planoEconomia2,
        percentualDesconto: planoDesconto2,
        cotacaoSelecionada: periodo2 === periodo ? "S" : "N",
        matrizGdId: matrizId,
        ativo: "S",
        edicaoDesconto
      });
    }

    if (showPlano3) {
      modelPlanos.push({
        cotacaoGdId: dados?.id,
        tipoPlanoCotacaoId: handlePLanosId(planos3),
        produtoId: tipoProdutoId(distribuidoraId),
        economiaAnual: planoEconomia3,
        percentualDesconto: planoDesconto3,
        cotacaoSelecionada: periodo3 === periodo ? "S" : "N",
        matrizGdId: matrizId,
        ativo: "S",
        edicaoDesconto
      });
    }

    const {
      sucesso,
      mensagem
    } = await SiteLuzLeadHelper.criarCotacaoGDPlanosGerado(
      modelPlanos,
      setError,
      handleLoading,
      dispatch
    );
    if (!sucesso) {
      SiteLuzLeadHelper.exibirErro(mensagem);
      dispatch(handleLoading(false));
      setEtapaFluxo(true);
      return;
    }

    criarLeadClienteLuzPJ(modelForm, dados);
  };

  const handleTiposPlanos = (planos) => {
    switch (planos) {
      case "PD":
        return "Padrao";
      case "PS":
        return "Personalizado";
      default:
        return planos;
    }
  };

  const criarCotacaoGD = async (modelForm) => {
    const model = {
      colaboradorId: officer?.officers?.id,
      empresaId: officer?.arvore?.empresaId,
      arvoreGerencialId: officer?.arvore?.id,
      cpfCnpj: removeMaskCpfCnpj(modelForm.cnpj) ?? "",
      pessoaFisicaJuridica: "PJ",
      preLead: salesForcesId ?? "",
      nome: modelForm?.nomeEmpresaContratante ?? modelForm?.nomeRepresentante,
      email: modelForm?.emailRepresentante,
      telefone: removeMaskTelefone(modelForm?.telefoneRepresentante) ?? "",
      cep: removeMaskCep(modelForm?.cep) ?? "",
      distribuidoraId: distribuidoraId ?? null,
      nFases: faseId ?? null,
      nivelTensao: nivelTensaoId ?? null,
      plano: handleTiposPlanos(tipoPlanoSelecionado),
      faturaProcessada:
        faturaProcessada === "S" || status?.nome === "Processado com Sucesso"
          ? "S"
          : "N",
      numeroInstalacao: modelForm?.numeroInstalacao ?? null,
      endereco: modelForm?.endereco ?? "",
      numero: modelForm?.numero ?? "",
      complemento: modelForm?.complemento ?? null,
      bairro: modelForm?.bairro ?? null,
      cidade: modelForm?.cidade ?? null,
      uf: modelForm?.uf ?? null,
      valorDaConta: valorSemGd ?? 0,
      consumoKwh: consumo ?? 0,
      cosip: taxaIluminacao ?? 0,
      icms: icms ?? 0,
      codigoCliente: modelForm?.codigoCliente ?? "",
      cupomMGM,
      cpfCnpjIndicador: parceiroEncontrado
        ? removeMaskCpfCnpj(cpfCnpjParceiro)
        : null,
      edicaoDesconto: edicaoDesconto === "S",
      leituraFase: leituraFase ?? dadosCotacaoGd?.leituraFase ?? false,
      leituraSubGrupo:
        leituraSubGrupo ?? dadosCotacaoGd?.leituraSubGrupo ?? false,
      leituraICMS: leituraICMS ?? dadosCotacaoGd?.leituraICMS ?? false,
      inadimplencia: inadimplencia ?? dadosCotacaoGd?.inadimplencia ?? false,
      aprovaContrato: dadosCotacaoGd?.aprovaContrato
        ? dadosCotacaoGd?.aprovaContrato
        : "N",
      aprovaContratoMaster: dadosCotacaoGd?.aprovaContratoMaster
        ? dadosCotacaoGd?.aprovaContratoMaster
        : "N",
      emailInconsistencia: "S"
    };

    if (dadosCotacaoId) {
      model.dataCotacao = dataCotacao;
      model.dataValidade = dataValidade;
    } else {
      model.dataCotacao = moment()?.format();
      model.dataValidade = moment().add(15, "days").format();
    }

    const { data, sucesso, mensagem } = await SiteLuzLeadHelper.criarCotacaoGD(
      model,
      setError,
      handleLoading,
      dadosCotacaoId,
      dispatch,
      setStatusLoading
    );
    if (!sucesso) {
      SiteLuzLeadHelper.exibirErro(mensagem);
      dispatch(handleLoading(false));
      setStatusLoading("");
      setEtapaFluxo(true);
      return;
    }

    criarCotacaoGDPlanosGerado(data, modelForm);
  };

  const obterUnidadeConsumidoraSimulador = async (dados) => {
    const model = {
      cpfCnpj: removeMaskCpfCnpj(dados?.cnpj),
      numeroInstalacao: dados?.numeroInstalacao,
      distribuidoraId
    };
    const {
      sucesso,
      mensagem
    } = await SiteLuzLeadHelper.obterUnidadeConsumidoraSimulador(
      model,
      setError,
      handleLoading,
      dispatch
    );

    if (sucesso) {
      criarCotacaoGD(dados);
    } else if (mensagem === "unidadeExists") {
      setModalAcao(true);
      setMensagemModal(
        "Analisamos as informações da sua unidade consumidora e ela já se encontra na nossa base, caso tenha outra unidade consumidora realizar a simulação novamente."
      );
      await atualizarSalesForce(dados, null, "unidade");
      dispatch(handleLoading(false));
    }
  };

  const atualizarCotacaoGDPlanosGerado = async (cotacaoGdId) => {
    const model = {
      cotacaoGdId,
      ativo: "N"
    };

    const {
      sucesso,
      mensagem
    } = await SiteLuzLeadHelper.atualizarCotacaoGDPlanosGerado(
      model,
      setError,
      handleLoading,
      dispatch
    );
    if (!sucesso) {
      SiteLuzLeadHelper.exibirErro(mensagem);
      setEtapaFluxo(true);
    }
  };

  const handleEditarCotacaoGd = () => {
    if (cotacaoId) history.push(`${RotasDTO.CotacoesGD}/${cotacaoId}`);
    setPassoAtual("1simulacao");
    obterCotacoesGdId(cotacaoId);
    setExibirModalMensagem(false);
  };

  const obterCotacaoGd = async (dados) => {
    if (!dados?.numeroInstalacao || !dados.cnpj || !distribuidoraId) {
      obterUnidadeConsumidoraSimulador(dados);
      if (dadosPlanosGerados?.length > 0) {
        atualizarCotacaoGDPlanosGerado(dadosCotacaoId);
      }
      return;
    }

    const model = {
      numeroInstalacao: dados?.numeroInstalacao,
      cpfCnpj: removeMaskCpfCnpj(dados?.cnpj),
      distribuidoraId
    };

    const { data, sucesso } = await SiteLuzLeadHelper.obterCotacaoGd(model);

    if (!sucesso) {
      obterUnidadeConsumidoraSimulador(dados);
      if (dadosPlanosGerados?.length > 0) {
        atualizarCotacaoGDPlanosGerado(dadosCotacaoId);
      }
      setEtapaFluxo(true);
    } else {
      setExibirModalValidacao(true);
      setCotacaoId(data);
    }
  };

  const handleCardLabel = (planoId) => {
    switch (planoId) {
      case 14:
        return "LIBERDADE TOTAL";
      case 10:
        return "DESCONTO 1 ANO";
      case 11:
        return "DESCONTO 2 ANOS";
      case 12:
        return "DESCONTO 3 ANOS";
      case 13:
        return "DESCONTO 5 ANOS";
      default:
        return "SEM PLANOS";
    }
  };

  const handleCardRodape = (planoId) => {
    switch (planoId) {
      case 14:
        return "Sem fidelidade";
      case 10:
        return "Fidelidade de 1 ano";
      case 11:
        return "Fidelidade de 2 anos";
      case 12:
        return "Fidelidade de 3 anos";
      case 13:
        return "Fidelidade de 5 anos";
      default:
        return "Sem Planos";
    }
  };

  const cotacaoProposta = async (dados) => {
    const planos = [];

    if (showPlano1) {
      planos.push({
        percentualDesconto: planoDesconto1 ?? 0,
        descricao: label1 ?? "",
        economiaAnual: planoEconomia1 ?? 0,
        cotacaoSelecionada: periodo1 === periodo ? "S" : "N",
        periodoMeses: periodo1 ?? 0
      });
    }

    if (showPlano2) {
      planos.push({
        percentualDesconto: planoDesconto2,
        descricao: label2 ?? "",
        economiaAnual: planoEconomia2 ?? 0,
        cotacaoSelecionada: periodo2 === periodo ? "S" : "N",
        periodoMeses: periodo2 ?? 0
      });
    }

    if (showPlano3) {
      planos.push({
        percentualDesconto: planoDesconto3,
        descricao: label3 ?? "",
        economiaAnual: planoEconomia3 ?? 0,
        cotacaoSelecionada: periodo3 === periodo ? "S" : "N",
        periodoMeses: periodo3
      });
    }

    const planosGerados = dadosPlanosGerados?.map((item) => ({
      percentualDesconto: item?.percentualDesconto,
      descricao: handleCardLabel(item?.tipoPlanoCotacaoId),
      economiaAnual: item?.economiaAnual,
      cotacaoSelecionada: item?.cotacaoSelecionada,
      periodoMeses: dadosPlanos?.find(
        (itemPlanos) => itemPlanos?.tipoPlano === item?.tipoPlanoCotacaoId
      )?.periodoMeses
    }));

    const model = {
      dataCotacao: window.moment().format(),
      nomeRazaoSocial:
        dados?.nomeEmpresaContratante ?? dados?.nomeRepresentante,
      cpfCnpj: removeMaskCpfCnpj(dados?.cnpj) ?? 0,
      valorDaConta: valorSemGd ?? 0,
      colaboradorId: officer?.officers?.id,
      telefone: dados?.telefoneRepresentante ?? "",
      email: dados?.emailRepresentante ?? "",
      planos: statusVigenciaFechado ? planosGerados : planos,
      produtoId: tipoProdutoId(distribuidoraId)
    };

    const { data, sucesso, mensagem } = await SiteLuzLeadHelper.cotacaoProposta(
      model,
      handleLoading,
      dispatch,
      setStatusLoading
    );

    if (!sucesso) {
      SiteLuzLeadHelper.exibirErro(mensagem);
      dispatch(handleLoading(false));
      setStatusLoading("");
      return;
    }

    const nomeArquivo = `proposta-${model?.dataCotacao}`;
    handlePdf(data?.html, nomeArquivo);
  };

  const aoEnviarFormulario = async (dadosForm, enviarParceiro = true) => {
    const dados = validacaoTrimObject(dadosForm);

    if (gerarProposta === "hook-form-proposta") {
      cotacaoProposta(dados);
      return;
    }
    if (enviarForm && !semFidelidade && !desconto1AnoSel && !desconto2AnosSel) {
      setModalAcao(true);
      setMensagemModal("Por favor, selecione um plano!");
      return;
    }

    if (envelopeId && !enviarForm) {
      setExibirModalMensagem(true);
      return;
    }

    if (dados?.emailRepresentante === dados?.emailRepresentante2) {
      setModalAcao(true);
      setMensagemModal(`Dados duplicados email ${dados?.emailRepresentante}`);
      return;
    }

    if (dados?.emailRepresentante && validaEmail) {
      const { sucesso, mensagem } = await CotacaoGDHelper.buscaPerfilPorEmail(
        dados?.emailRepresentante
      );

      if (!sucesso) {
        if (mensagem === "204") setValidaEmail(false);
        if (mensagem === "existsColaborador") {
          setModalAcao(true);
          setMensagemModal("Email já está cadastrado como colaborador!");
          return;
        }

        if (mensagem === "error") {
          SiteLuzLeadHelper.exibirErro("Erro ao encontrar perfil usuário!");
          return;
        }
      }
      setValidaEmail(false);
    }

    if (parceiro && enviarParceiro) {
      setModalMensagemInput("Informe o CPF ou CNPJ do Indicador");
      setDadosFormParceiro(dados);
      setExibirModalInput(true);
      return;
    }

    const model = {
      ...dados,
      uf: ufId
    };
    const emails = dados?.emailRepresentante2
      ? `${dados?.emailRepresentante} e ${dados?.emailRepresentante2}`
      : `${dados?.emailRepresentante}`;
    setEmails(emails);
    if (dadosCotacaoId) {
      obterUnidadeConsumidoraSimulador(model);
      if (dadosPlanosGerados?.length > 0) {
        atualizarCotacaoGDPlanosGerado(dadosCotacaoId);
      }
    } else {
      obterCotacaoGd(model);
    }
  };

  const handleMensagemModal = () => {
    return (
      <Box fontSize="24px">
        Não é possível salvar os dados alterados. Gere um novo contrato em
        <Box component="span" fontWeight="700" mx={1}>
          Enviar Contato
        </Box>
      </Box>
    );
  };

  const handleModalValidacao = () => {
    return (
      <Box fontSize="24px" fontWeight="700">
        Cotação já cadastrada. Deseja editar?
      </Box>
    );
  };

  const handleMensagem = (mensagem) => {
    return (
      <Box fontSize="24px" fontWeight="700">
        {mensagem}
      </Box>
    );
  };

  const handleCpfCnpj = (value) => {
    const mask = formatarCampo(value);
    setCpfCnpjParceiro(mask);
  };

  const obterCpfCnpj = async (enviar = "search") => {
    if (
      !cpfCnpjParceiro ||
      validarCpfCnpj(cpfCnpjParceiro) === "CPF inválido!" ||
      validarCpfCnpj(cpfCnpjParceiro) === "CNPJ inválido!"
    ) {
      return;
    }

    const { data, sucesso, mensagem } = await CotacaoGDHelper.obterCpfCnpj(
      removeMaskCpfCnpj(cpfCnpjParceiro),
      setParceiroEncontrado
    );

    if (!sucesso) {
      if (mensagem === "204" && enviar === "continuar") {
        setCpfCnpjParceiro(null);
        setParceiroEncontrado(null);
        setModalAcao(true);
        setMensagemModal("Insira um CPF ou CNPJ indicador válido.");
        return;
      }
      SiteLuzLeadHelper.exibirErro(mensagem);
      return;
    }

    if (enviar === "continuar") {
      aoEnviarFormulario(dadosFormParceiro, false);
    }

    setParceiroEncontrado(data);
  };

  const verificaIncosistencia = (inadim, Leifase, LeiIcms, LeiSub) => {
    switch (true) {
      case inadim === false &&
        Leifase === true &&
        LeiIcms === true &&
        LeiSub === true:
        return "semInconsistencia";
      case inadim === true &&
        Leifase === false &&
        LeiIcms === false &&
        LeiSub === false:
        return "comInconsistencia";
      case inadim === true ||
        Leifase === false ||
        LeiIcms === false ||
        LeiSub === false:
        return "comInconsistencia";
      default:
        return "default";
    }
  };

  const mostrarEnviarContrato = useMemo(() => {
    const inadim =
      dadosFatura?.[0]?.inadimplencia ?? dadosCotacaoGd?.inadimplencia;
    const Leifase =
      dadosFatura?.[0]?.leituraFase ?? dadosCotacaoGd?.leituraFase;
    const LeiIcms =
      dadosFatura?.[0]?.leituraICMS ?? dadosCotacaoGd?.leituraIcms;
    const LeiSub =
      dadosFatura?.[0]?.leituraSubGrupo ?? dadosCotacaoGd?.leituraSubgrupo;

    const valida = verificaIncosistencia(inadim, Leifase, LeiIcms, LeiSub);

    const semInconsistencia =
      valida === "semInconsistencia" &&
      (dadosCotacaoGd?.aprovaContrato === "N" ||
        dadosCotacaoGd?.aprovaContrato === null ||
        dadosCotacaoGd?.aprovaContrato === undefined) &&
      (dadosCotacaoGd?.aprovaContratoMaster === "N" ||
        dadosCotacaoGd?.aprovaContratoMaster === null ||
        dadosCotacaoGd?.aprovaContratoMaster === undefined) &&
      dadosOfficer?.officers?.cargoId === enumeradorBotao.Parceiro;

    const existsInconsistencia =
      valida === "comInconsistencia" &&
      (dadosCotacaoGd?.aprovaContrato === "N" ||
        dadosCotacaoGd?.aprovaContrato === null ||
        dadosCotacaoGd?.aprovaContrato === undefined) &&
      (dadosCotacaoGd?.aprovaContratoMaster === "N" ||
        dadosCotacaoGd?.aprovaContratoMaster === null ||
        dadosCotacaoGd?.aprovaContratoMaster === undefined) &&
      dadosOfficer?.officers?.cargoId === enumeradorBotao.Parceiro;

    const existsInconsistenciaAprova =
      valida === "comInconsistencia" &&
      dadosCotacaoGd?.aprovaContrato === "S" &&
      dadosCotacaoGd?.aprovaContratoMaster === "S" &&
      dadosOfficer?.officers?.cargoId === enumeradorBotao.Parceiro;

    switch (true) {
      case usuario?.usuario?.cargoId !== enumeradorBotao.Parceiro:
        return "exibirContrato";
      case semInconsistencia:
        return "exibirContrato";
      case existsInconsistenciaAprova:
        return "exibirContrato";
      case existsInconsistencia:
        return "NaoExibirContrato";
      default:
        return "NaoExibirContrato";
    }
  }, [dadosOfficer, dadosFatura, dadosCotacaoGd]);

  return (
    <>
      <ModalMensagemErro
        item={modalAcao}
        titulo="Algo deu errado"
        mensagem={mensagemModal}
        onCancelar={() => {
          setModalAcao(false);
          setMensagemModal("");
        }}
        onConfirmar={() => {}}
        texto="Tentar novamente"
      />
      <ModalLuz
        item={exibirModalValidacao}
        mensagem={handleModalValidacao()}
        onCancelar={() => setExibirModalValidacao(false)}
        onConfirmar={handleEditarCotacaoGd}
      />
      <ModalLuz
        item={exibirModalMensagem}
        mensagem={handleMensagemModal()}
        onCancelar={() => setExibirModalMensagem(false)}
        footer={false}
      />

      <ModalLuz
        item={exibirModalInput}
        mensagem={handleMensagem(modalMensagemInput)}
        onCancelar={() => {
          setExibirModalInput(false);
          setModalMensagemInput("");
          setValidaParceiro(false);
          setParceiroEncontrado(null);
          setCpfCnpjParceiro(null);
          aoEnviarFormulario(dadosFormParceiro, false);
        }}
        header={false}
        footer
        onConfirmar={() => {
          setExibirModalInput(false);
          setValidaParceiro(false);
          obterCpfCnpj("continuar");
        }}
        textbtn1="Prosseguir sem indicação"
        textbtn2="Continuar"
        disabled={!parceiroEncontrado}
        bgColorBtn2={!parceiroEncontrado ? "#9F9F9F" : "#FA3EB1"}
        colorBtn2={!parceiroEncontrado ? "#4F4F4F" : "#fff"}
      >
        <form
          className="needs-validation"
          onSubmit={handleSubmitModal(() => {})}
        >
          <Box mb={4} className={classes.container}>
            <Grid container alignItems="center">
              <Grid item xs={10} sm={11} xl={10.8}>
                <MaterialInputTexto
                  type="text"
                  id="cpfCnpjIndicador"
                  name="cpfCnpjIndicador"
                  label="CPF ou CNPJ"
                  renderIconShowHide={false}
                  defaultValue={cpfCnpjParceiro || ""}
                  onBlur={(e) => handleCpfCnpj(e.target.value)}
                  maxLength={18}
                  ref={registerModal({
                    required: validaParceiro
                      ? "Campo cpf/cnpj é obrigatório!"
                      : false,
                    maxLength: {
                      value: 18,
                      message: "Quantidade máxima de 14 caracteres!"
                    },
                    minLength: {
                      value: 11,
                      message: "Quantidade mínima de 11 caracteres!"
                    },
                    validate: (value) => validarCpfCnpj(value)
                  })}
                  errors={errorsModal}
                  allowClear
                />
                {parceiroEncontrado === false && (
                  <Box color="#FF0000" fontSize="13px" fontWeight="400">
                    {removeMaskCpfCnpj(cpfCnpjParceiro)?.length === 11
                      ? "CPF não encontrado"
                      : "CNPJ não encontrado"}
                  </Box>
                )}
              </Grid>
              <Grid item xs={2} sm={1} xl={1.2}>
                <Box
                  component="button"
                  ml={2}
                  borderRadius="10px"
                  p={1}
                  textAlign="center"
                  bgcolor="rgb(250, 62, 177)"
                  type="submit"
                  border="none"
                  onClick={() => {
                    setGerarProposta("");
                  }}
                >
                  <Search
                    onClick={obterCpfCnpj}
                    cursor="pointer"
                    htmlColor="#fff"
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </form>
      </ModalLuz>
      <CustomLoader msgLoading={statusLoading}>
        <Card className={classes.cardCadastro}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <TituloPagina
              icon={Cofre}
              titulo="Planos disponíveis"
              alt="planosDisponiveis"
            />
            <Box
              component="button"
              bgcolor="#FA3EB1"
              color="#fff"
              textAlign="center"
              fontSize={{ xs: "15px", sm: "16px" }}
              fontWeight="700"
              borderRadius="24px"
              py="5px"
              px={{ xs: "15px", sm: "20px" }}
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setGerarProposta("hook-form-proposta");
                setEnviarForm(false);
              }}
              id="propostaId"
              form="hook-form-proposta"
              type="submit"
              border="none"
            >
              <Box component="span" mr="5px">
                Baixar proposta
              </Box>
              <GetApp sx={{ width: "20px", height: "20px" }} htmlColor="#fff" />
            </Box>
          </Box>
          <Grid container spacing={2}>
            {statusVigenciaFechado &&
              dadosPlanosGerados &&
              dadosPlanosGerados?.map((item) => (
                <Grid item xs={12} lg={4} xl={4}>
                  <CardsPlanos
                    titulo={handleCardLabel(item.tipoPlanoCotacaoId)}
                    porcentagem={formataPercentual(item?.percentualDesconto)}
                    anual={formataMoedaReais(item.economiaAnual)}
                    rodape={handleCardRodape(item.tipoPlanoCotacaoId)}
                    nomeCard="DescontoSemFidelidade"
                    checked={item?.cotacaoSelecionada === "S"}
                    descricao={cardDescricao(distribuidoraId)}
                  />
                </Grid>
              ))}
            {showPlano1 && (
              <Grid item xs={12} lg={4} xl={4}>
                <CardsPlanos
                  titulo={label1}
                  porcentagem={formataPercentual(planoDesconto1)}
                  anual={formataMoedaReais(planoEconomia1)}
                  rodape={rodape1}
                  nomeCard="DescontoSemFidelidade"
                  checked={semFidelidade}
                  onChecked={setSemFidelidade}
                  onName={(value) => handleName(value, matrizGD)}
                  disabled={semFidelidade}
                  descricao={cardDescricao(distribuidoraId)}
                />
              </Grid>
            )}

            {showPlano2 && (
              <Grid item xs={12} lg={4} xl={4}>
                <CardsPlanos
                  titulo={label2}
                  porcentagem={formataPercentual(planoDesconto2)}
                  anual={formataMoedaReais(planoEconomia2)}
                  rodape={rodape2}
                  nomeCard="Desconto1Ano"
                  checked={desconto1AnoSel}
                  onChecked={setDesconto1Ano}
                  onName={(value) => handleName(value, matrizGD)}
                  disabled={planoDesconto2}
                  descricao={cardDescricao(distribuidoraId)}
                />
              </Grid>
            )}

            {showPlano3 && (
              <Grid item xs={12} lg={4} xl={4}>
                <CardsPlanos
                  titulo={label3}
                  porcentagem={formataPercentual(planoDesconto3)}
                  anual={formataMoedaReais(planoEconomia3)}
                  rodape={rodape3}
                  nomeCard="Desconto2Anos"
                  checked={desconto2AnosSel}
                  onChecked={setDesconto2Anos}
                  onName={(value) => handleName(value, matrizGD)}
                  disabled={planoDesconto3}
                  descricao={cardDescricao(distribuidoraId)}
                />
              </Grid>
            )}
          </Grid>
          <FooterCards tipoProduto={tipoProduto(distribuidoraId)} />
        </Card>
        <form
          id={`${gerarProposta ? "hook-form-proposta" : "hook-form"}`}
          className="needs-validation"
          onSubmit={handleSubmit(aoEnviarFormulario)}
        >
          <Box pt={3}>
            <Card className={classes.cardCadastro}>
              <TituloPagina
                icon={Informacao}
                titulo="Informações do contratante"
                alt="informacaoContrante"
              />
              <InformacaoEmpresa
                nomeContratante={nomeContratante}
                emailContratante={emailContratante}
                telefoneContratante={telefoneContratante}
                nomeEmpresa={nomeEmpresa}
                register={register}
                errors={errors}
                clearErrors={clearErrors}
                setValue={setValue}
                cpfCliente={cnpjCliente}
                enviarForm={enviarForm}
                representantes={representantes}
                editarInputs={editarInputs}
                statusVigenciaFechado={statusVigenciaFechado}
                enviarFormProposta={gerarProposta === "hook-form-proposta"}
              />
            </Card>
          </Box>

          <Box pt={3} mb={3}>
            <Card className={classes.cardCadastro}>
              <TituloPagina
                icon={Home}
                titulo="Unidade consumidora"
                alt="unidadeConsumidora"
              />
              <Box color="#4F4F4F" fontSize="14px" fontWeight="400" py={3}>
                Informe os dados do local onde será feita a instalação do
                medidor
              </Box>
              <UnidadeConsumidora
                setUFId={setUFId}
                ufId={ufId}
                dadosCepCobertura={dadosCepCobertura}
                obterCepCobertura={obterCepCobertura}
                register={register}
                errors={errors}
                numeroInstalacao={
                  numeroInstalacao ?? dadosCotacaoGd?.numeroInstalacao
                }
                dadosCotacaoGd={dadosCotacaoGd}
                statusVigenciaFechado={statusVigenciaFechado}
                cepForm={cep}
                enviarFormProposta={gerarProposta !== "hook-form-proposta"}
                codigoCliente={codigoCliente ?? dadosCotacaoGd?.codigoCliente}
              />
            </Card>
          </Box>
        </form>
        <Box component={Grid} container spacing={2}>
          {!statusVigenciaFechado && (
            <>
              {mostrarEnviarContrato === "exibirContrato" && (
                <Grid item xs={12} lg={3} mt={2}>
                  <BotaoForm
                    onClick={() => {
                      setGerarProposta("");
                      setEnviarForm(true);
                    }}
                    id="contratacaoPJId"
                    form="hook-form"
                    label="Enviar contrato"
                    type="submit"
                    style={{ background: "#9F9F9F" }}
                  />
                </Grid>
              )}
              <Grid item xs={12} lg={3} mt={2}>
                <BotaoForm
                  onClick={() => {
                    setGerarProposta("");
                    setEnviarForm(false);
                  }}
                  id="salvarPJId"
                  form="hook-form"
                  label="Salvar"
                  type="submit"
                  style={{ color: "#FA3EB1", border: "1px solid #FA3EB1" }}
                />
              </Grid>
            </>
          )}
        </Box>
      </CustomLoader>
    </>
  );
};

ContratacaoPJ.propTypes = {
  setPassoAtual: PropTypes.oneOfType([PropTypes.func]),
  dadosCepCobertura: PropTypes.oneOfType([PropTypes.array]),
  obterCepCobertura: PropTypes.oneOfType([PropTypes.func]),
  nomeContratante: PropTypes.string,
  emailContratante: PropTypes.string,
  telefoneContratante: PropTypes.string,
  dadosMatrizGD: PropTypes.oneOfType([PropTypes.array]),
  dadosFormSimulador: PropTypes.oneOfType([PropTypes.array]),
  dadosFatura: PropTypes.oneOfType([PropTypes.array]),
  dadosLeadSalesForce: PropTypes.oneOfType([PropTypes.array]),
  externoIdSemFatura: PropTypes.string,
  setEmails: PropTypes.oneOfType([PropTypes.func]),
  dadosOfficer: PropTypes.oneOfType([PropTypes.array]),
  dadosTiposPlanos: PropTypes.oneOfType([PropTypes.array])
};

ContratacaoPJ.defaultProps = {
  setPassoAtual: () => {},
  dadosCepCobertura: [],
  obterCepCobertura: () => {},
  nomeContratante: "",
  emailContratante: "",
  telefoneContratante: "",
  dadosMatrizGD: [],
  dadosFormSimulador: [],
  dadosFatura: [],
  dadosLeadSalesForce: [],
  externoIdSemFatura: "",
  setEmails: () => {},
  dadosOfficer: [],
  dadosTiposPlanos: []
};

export default ContratacaoPJ;
