import { makeStyles } from "@mui/styles";
import theme from "themes";

export const useStyles = makeStyles({
  fundoCelula: {
    backgroundColor: "transparent",
    width: "100%",
    height: "100%"
  },
  fundoBotoes: {
    backgroundColor: theme.color.bgTabela
  },
  botaoCentral: {
    borderLeft: `3px ${theme.color.secondaryBackgroud} solid`
  },
  inativo: {
    opacity: 0.5
  },
  ativo: {
    opacity: 1
  },
  botao: {
    color: `${theme.color.botaoDownloadFatura} !important`,
    fontSize: "32px"
  }
});
