import { makeStyles } from "@mui/styles";
import styled from "styled-components";
import { createTheme } from "@mui/material/styles";
import theme from "../../..";
import { Base } from "../../../../componentes/cores";

const th = createTheme();

export const useStyles = makeStyles(() => ({
  titulo: {
    color: theme.color.primary,
    fontFamily: theme.font.family,
    fontSize: "24px",
    fontWeight: 700,
    [th.breakpoints.down("sm")]: {
      fontSize: "20px"
    }
  },
  subtitulo: {
    fontSize: "20px",
    color: theme.color.primary,
    marginBottom: 0
  },
  legenda: {
    fontSize: "20px",
    color: "#86868B",
    marginBottom: 5
  },
  sombra: {
    boxShadow: "0 3px 10px rgb(0 0 0 / 0.2) !important",
    height: "100%"
  },
  boxFim: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    "& p": {
      marginBottom: 0,
      color: "#9F9F9F",
      fontSize: "16px"
    }
  },
  preco: {
    color: theme.color.primary,
    fontSize: "16px",
    fontWeight: 700
  },
  kwh: {
    color: "#FA5A61",
    fontSize: "16px",
    fontWeight: 700
  }
}));

export const Botao = styled.button`
  color: ${Base.White};
  background-color: ${theme.color.secondary};
  border-color: transparent;
  border-radius: 24px;
  font-weight: 600;
  font-size: 14px;
  padding: 4px 16px;
`;
