/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable no-shadow */
/* eslint-disable radix */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useForm, useWatch } from "react-hook-form";
import shortid from "shortid";
import { Grid, Checkbox } from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import { Base } from "componentes/cores";
import Dialog from "@mui/material/Dialog";
import ModalConfirmacao from "componentes/modalConfirmacao";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Card from "@mui/material/Card";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Loader from "componentes/loader";
import MaterialInputTexto from "componentes/inputTexto/materialInput";
import BotaoFiltroOrdenacao from "componentes/botaoFiltroOrdenar";
import ItemListaExpansivel from "componentes/itemListaExpansivel";
import { buscarDistribuidoras, listarDistribuidorasSemPaginacao } from "servicos/distribuidorasServico";
import { Botao } from "componentes";
import RotasDTO from "../../../rotas/rotasUrlDto";
import { useStyles } from "./style";
import { obterApelidos, obterParametrizacaoDistribuidoras, listarOrganizarPor, listarFiltrarPor, ativarInativarParametroDistribuidora, sincronizarDistribuidoras, updateDistribuidoraDivergente } from "../../../servicos/parametrizacaoDistribuidoras";

const ListaDistribuidoras = () => {
  const classes = useStyles();
  const history = useHistory();

  const { register, control } = useForm({
    reValidateMode: "onSubmit"
  });

  const onClickEditar = (id) => {
    history.push(`${RotasDTO.DistribuidorasLista}/parametrizacao/${id}`);
  }

  const [distribuidoras, setDistribuidoras] = useState([]);
  const [listaDistribuidora, setListaDistribuidora] = useState([]);
  const [carregandoDados, setCarregandoDados] = useState(false);
  const [filtroSelecionado, setFiltroSelecionado] = useState("");
  const [ordemSelecionada, setOrdemSelecionada] = useState("");
  const usuario = useSelector((state) => state.usuario);
  const { empresaSelecionada } = usuario;
  const [dialogDivergenciaOpen, setDialogDivergenciaOpen] = useState(false);
  const [mensagensDivergencias, setMensagensDivergencias] = useState([]);
  const [divergenciasDistribuidoras, setDivergenciasDistribuidoras] = useState([]);
  // const [ordemSelecionada, setOrdemSelecionada] = useState(null);
  const [modalAtivaInativa, setModalAtivaInativa] = useState(false);

  const textoBusca = useWatch({
    control,
    name: "textoBusca",
    defaultValue: ""
  });

  const stringBusca = useMemo(() => {
    return textoBusca;
  }, [textoBusca]);

  const handleObterListaApelidosDist = (apelidosDistribuidora, iddist) => {
    const apelidosFiltro = apelidosDistribuidora?.filter(ap => ap.idDistribuidora === iddist);
    if (apelidosFiltro?.length > 0) {
      const apelidos = { apelidos: apelidosFiltro[0].apelidos };
      return apelidos;

    }
  }

  const handleObterParametrosDist = (parametros, iddist) => {
    const parametrosFiltro = parametros?.filter(p => p.idDeltaDigital === iddist);
    if (parametrosFiltro?.length > 0) {
      return { parametro: parametrosFiltro[0] };
    }
  }

  const obterDistruidoras = useCallback(async (filtro) => {
    try {

      setCarregandoDados(true);

      const lista = await listarDistribuidorasSemPaginacao();
      let dist = [];

      if (lista.status === 200 && lista.data) {
        dist = lista?.data?.distribuidoras ?? [];
        const apelidos = await obterApelidos();
        const parametros = await obterParametrizacaoDistribuidoras();


        if (apelidos.status === 200 && apelidos.data) {
          dist = dist?.map((item) => ({ ...item, ...handleObterListaApelidosDist(apelidos.data, item.id) }));

        }

        if (parametros.status === 200 && parametros.data) {
          dist = dist?.map((item) => ({ ...item, ...handleObterParametrosDist(parametros.data, item.id) }));

        }
      }
      setDistribuidoras(dist);
      setListaDistribuidora(dist);
      setCarregandoDados(false);
    } catch (error) {
      console.info(error);
      setCarregandoDados(false);
    }
  }, []);

  function filterByNomeFantasia(obj) {
    return obj.nomeFantasia != null && obj.nomeFantasia.toUpperCase().includes(textoBusca.toUpperCase());
  }

  function filterByActive(obj) {
    if (obj.parametro && obj.parametro.statusProjecaoTarifaria == filtroSelecionado) {
      return true;
    } 
      return false;
  }

  const handleFiltrarDistribuidoras =  () => {

    let dadosFiltrados = [];

    if (listaDistribuidora != null && listaDistribuidora.length > 0) {

      dadosFiltrados = listaDistribuidora;

      if (stringBusca != undefined && stringBusca.length > 0) {
        if ((filtroSelecionado == null || filtroSelecionado == undefined || filtroSelecionado == "")) {
          dadosFiltrados = dadosFiltrados.filter(filterByNomeFantasia);
        }
      }
      if (filtroSelecionado == "0" || filtroSelecionado == "1") {
        const distAtivas = dadosFiltrados?.filter(filterByActive);
        dadosFiltrados = distAtivas;
      }
      setDistribuidoras(dadosFiltrados);
    }
  }

  const handleReajusteTarifario = (dia, mes) => {
    const data = `${mes}-${dia}`;
    return window.moment(data).format("DD/MMM");
  }


  // const handleClickOrdemSelecionada = (event) => {
  //   const val = ordemSelecionada === event ? null : event;
  //   setOrdemSelecionada(val);
  // };

  const handleClickFiltroSelecionado = (event) => {
    const val = filtroSelecionado === event ? null : event;
    setFiltroSelecionado(val);
  };

  const handleClickAtivarAgora = async () => {
    handleFiltrarDistribuidoras();
  };

  useEffect(() => {
    if (!distribuidoras.length) obterDistruidoras(null);
  }, [!distribuidoras.length, obterDistruidoras]);

  useEffect(() => {
    setTimeout(() => {
      handleFiltrarDistribuidoras();
    }, 100);
  }, [stringBusca]);

  const onClickInativar = (item) => {
    setModalAtivaInativa(item);
  };

  const ativarInativar = async (item) => {
    try {
      setModalAtivaInativa(false);
      setCarregandoDados(true);

      const param = item?.parametro;
      param.statusProjecaoTarifaria = !param.statusProjecaoTarifaria;
      if (param) {

        const resposta = await ativarInativarParametroDistribuidora(param);
        if (resposta) {
          obterDistruidoras();
          store.dispatch(
            alertaExibir({
              tipo: "success",
              mensagem: `Parâmetro distribuidora ${param?.statusProjecaoTarifaria ? "ativado" : "inativado"
                } com sucesso!`
            })
          );
        }
      }
      else {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "Parâmetro distribuidora não encontrado para Inativar / Ativar"
          })
        );
      }

      setCarregandoDados(false);
    } catch (error) {
      setCarregandoDados(false);
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  const onConfirmarAtivaInativa = (item) => {
    try {
      ativarInativar(item);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  const mapDistribuidorasDivergencia = (distribuidorasD) => {
    const divergencias = [];
    if (distribuidorasD.length > 0)
      distribuidorasD.forEach(element => {
        const distDelta = element.distribuidoraDelta;
        const distParam = element.parametroDistribuidora;

        const divergenciasPorDistribuidora = [];
        if (distDelta.cnpj !== distParam.cnpj)
          divergenciasPorDistribuidora.push(`Divergência entre o CNPJ ${distDelta.cnpj} (Novo) - ${distParam.cnpj} (Atual)`)

        if (distDelta.idDeltaDigital !== distParam.idDeltaDigital)
          divergenciasPorDistribuidora.push(`Divergência entre os IDs Delta Digital ${distDelta.idDeltaDigital} (Novo) - ${distParam.idDeltaDigital} (Atual)`)

        if (distDelta.nomeFantasia !== distParam.nomeFantasia)
          divergenciasPorDistribuidora.push(`Divergência entre nome ${distDelta.nomeFantasia} (Novo) - ${distParam.nomeFantasia} (Atual)`)

        distDelta.checked = false;

        if (divergenciasPorDistribuidora.length > 0)
          divergencias.push({ distribuidora: distDelta, divergencias: divergenciasPorDistribuidora, idDistribuidora: element.idParametroDistribuidora });
        else
          divergencias.push({ distribuidora: distDelta, divergencias: ["Divergencia não identificada"], idDistribuidora: element.idParametroDistribuidora });
      })

    setDivergenciasDistribuidoras(divergencias);
  }

  const handleSincronizarDistribuidoras = async () => {
    setCarregandoDados(true);
    sincronizarDistribuidoras().then((response) => {
      if (response.status === 200 && response.data) {
        setMensagensDivergencias(response.data.mensagens);
        if (response.data.distribuidorasDivergentes)
          mapDistribuidorasDivergencia(response.data.distribuidorasDivergentes)
        else
          setDivergenciasDistribuidoras([]);
        setDialogDivergenciaOpen(true);
      }
    })
  };

  const handleCloseDialog = () => {
    setDialogDivergenciaOpen(false);
    setCarregandoDados(false);
  }

  const handleChangeCheckbox = (event) => {
    divergenciasDistribuidoras.forEach(element => {
      if (parseInt(element.distribuidora.idANEEL) === parseInt(event.id)) {
        element.distribuidora.checked = event.checked;
      }
    })
  }

  const handleCheckAll = (event) => {
    divergenciasDistribuidoras.forEach(element => {
      element.distribuidora.checked = event.checked;
    });
  }

  const handleUpdateDistribuidoras = async () => {
    setDialogDivergenciaOpen(false);
    setCarregandoDados(true);
    try {
      divergenciasDistribuidoras.forEach(async element => {
        if (element.distribuidora.checked === true) {
          const data = {
            IdDeltaDigital: element.distribuidora.idDeltaDigital,
            NomeFantasia: element.distribuidora.nomeFantasia,
            CNPJ: element.distribuidora.cnpj,
            IdANEEL: element.distribuidora.idANEEL,
            StatusProjecaoTarifaria: element.distribuidora.statusProjecaoTarifaria,
            StatusDistribuidora: element.distribuidora.statusDistribuidora
          };

          await updateDistribuidoraDivergente(element.idDistribuidora, data);
        }
      });

      await obterDistruidoras(null);

      store.dispatch(
        alertaExibir({
          tipo: "success",
          mensagem: "Distribuidoras atualizadas com sucesso!"
        })
      );

    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Erro ao atualizar distribuidoras!"
        })
      );
    }
  }

  // {
  //   "IdDeltaDigital": 0,
  //   "NomeFantasia": "string",
  //   "CNPJ": "string",
  //   "IdANEEL": 0,
  //   "StatusProjecaoTarifaria": true,
  //   "StatusDistribuidora": true
  // }
  return (
    <Loader loading={carregandoDados} className="w-auto">
      <Dialog open={dialogDivergenciaOpen} style={{ maxWidth: "1200px", marginLeft: "15vw" }} className={classes.dialog}>
        <DialogContent style={{ maxWidth: "100vw", width: "59vw", height: "100%", maxHeight: "720px", textAlign: "center" }}>
          <Grid xs={12} style={{ marginBottom: "7px", width: "100%" }}>
            <Typography style={{ fontSize: "1.3rem", float: "left", cursor: "pointer" }} onClick={() => { setDialogDivergenciaOpen(false) }}>X</Typography>
            <Typography style={{ fontSize: "1.3rem", fontWeight: "bold" }}>Sincronização de distribuidoras bem sucedida</Typography>
          </Grid>
          <Grid xs={12} style={{ marginBottom: "15px" }}>
            {mensagensDivergencias.map(element => {
              return (<Grid>
                <span>{element}</span>
                      </Grid>)
            })}
          </Grid>
          <Grid xs={12} style={{ marginBottom: "40px", fontSize: "1.2rem", fontWeight: "bold" }}>
            <span>Selecione as distribuidoras com divergência de dados que deseja atualizar</span>
          </Grid>
          <Grid container xs={12} style={{ marginBottom: "12px", paddingLeft: "16px" }}>
            <Grid item xs={1} class={classes.footerCheckbox}>
              <Checkbox
                defaultChecked={false}
                color="default"
                id="checkAll"
                onChange={e => handleCheckAll(e.target)}
              />
            </Grid>
            <Grid item xs={2} style={{ alignSelf: "center" }}>
              <Typography>
                Id ANEEL
              </Typography>
            </Grid>
            <Grid item xs={9} style={{ alignSelf: "center", textAlign: "left" }}>
              <Typography>
                Nome Fantasia
              </Typography>
            </Grid>
          </Grid>
          {divergenciasDistribuidoras.map(element => {
            return (
              <Grid xs={12} style={{ marginBottom: "10px" }}>
                <Card variant="outlined" style={{ height: element.divergencias.length === 1 ? "86px" : (element.divergencias.length === 2 ? "106px" : "126px") }}>
                  <CardContent>
                    <Grid container xs={12}>
                      <Grid item xs={1} class={classes.footerCheckbox} style={{ marginTop: "10px" }}>
                        <Checkbox
                          defaultChecked={element.distribuidora.checked}
                          color="default"
                          id={element.distribuidora.idANEEL}
                          onChange={e => handleChangeCheckbox(e.target)}
                        />
                      </Grid>
                      <Grid item xs={2} style={{ alignSelf: "center" }}>
                        <Typography>
                          {element.distribuidora.idANEEL}
                        </Typography>

                      </Grid>
                      <Grid item xs={9} style={{ alignSelf: "center", textAlign: "left" }}>
                        <Typography>
                          {element.distribuidora.nomeFantasia}
                        </Typography>

                        {element.divergencias.map(item => {
                          return (<Typography style={{ textAlign: "left", fontSize: "12px", paddingLeft: "20px" }}>
                            {item}
                                  </Typography>);
                        })}
                      </Grid>

                    </Grid>
                  </CardContent>
                </Card>
              </Grid>)
          })}
          <Grid container xs={12} style={{ paddingTop: "50px" }}>
            <Grid item xs={6}>
              <DialogActions>
                <Botao
                  type="button"
                  label="Não atualizar distribuidoras"
                  className={classes.button}
                  color={Base.GreyDim}
                  onClick={handleCloseDialog}
                />
              </DialogActions>
            </Grid>
            <Grid item xs={6}>
              <DialogActions>
                <Botao
                  type="submit"
                  label="Atualizar distribuidoras"
                  className={classes.button}
                  onClick={handleUpdateDistribuidoras}
                />
              </DialogActions>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12}>
          <ModalConfirmacao
            item={modalAtivaInativa}
            onConfirmar={(item) => onConfirmarAtivaInativa(item)}
            mensagem={`Tem certeza que deseja ${modalAtivaInativa?.parametro?.statusProjecaoTarifaria ? "inativar" : "ativar"
              } essa Parametrização de Distribuidora?`}
            onCancelar={() => setModalAtivaInativa(false)}
          />
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={5} xl={7}>
              <MaterialInputTexto
                type="text"
                id="textoBusca"
                name="textoBusca"
                label="Buscar"
                renderIconShowHide
                searchAdornment
                defaultValue={textoBusca ?? ""}
                ref={register}
                className={classes.search}
                permiteValorBranco
              />
            </Grid>
            <Grid item xs={3} xl={2} className={classes.itemFiltro}>
              <BotaoFiltroOrdenacao
                type="button"
                color={Base.OffWhite}
                background="transparent"
                label="Filtrar / Ordenar"
                icon={<TuneIcon />}
                className={classes.buttonFiltro}
                // ordenacao={listarOrganizarPor()}
                ordenadorUm="Ordenador"
                ordenadorDois="Filtrar"
                ordemSelecionada=""
                filtros={listarFiltrarPor()}
                filtrosSelecionados={filtroSelecionado}
                // onClickOrdenacao={handleClickOrdemSelecionada}
                onClickFiltro={handleClickFiltroSelecionado}
                onClickAtivarAgora={handleClickAtivarAgora}
              />
            </Grid>
            <Grid item xs={4} xl={3}>
              <Botao
                type="submit"
                label="Sincronizar distribuidoras"
                className={classes.button}
                // onClick={() => history.push(`${RotasDTO.DistribuidorasLista}/configuracao/${distribuidora.id}`)}
                onClick={handleSincronizarDistribuidoras}
              />
            </Grid>

            {distribuidoras.length > 0 && distribuidoras.map((distribuidora) => {
              return (
                <ItemListaExpansivel
                  key={shortid.generate()}
                  label={distribuidora?.parametro?.titulo}
                  color={distribuidora.parametro ? Base.GreenFrag : Base.White}
                  edicao={!!distribuidora?.id}
                  exclusao={distribuidora?.parametro?.idDistribuidora}
                  onClickEditar={() => onClickEditar(distribuidora?.parametro?.idDistribuidora)}
                  onClickExcluir={() => onClickInativar(distribuidora ?? false)}
                  ativo={distribuidora?.parametro?.statusProjecaoTarifaria}
                >
                  <div>

                    <Grid spacing={1}>
                      <Grid container spacing={2}>
                        <Grid item sm={4}>
                          <strong>Aniversário Tarifário: </strong>
                        </Grid>
                        <Grid item sm={8}>
                          {handleReajusteTarifario(distribuidora?.diaReajusteTarifario, distribuidora?.mesReajusteTarifario)}
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item sm={4}>
                          <strong>Apelidos: </strong>
                        </Grid>
                        <Grid item sm={8}>
                          {distribuidora?.apelidos?.map(ap => (<span>
                            {ap}
                            <br />
                                                               </span>))}
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </ItemListaExpansivel>
              )
            })
            }
          </Grid>
        </Grid>
      </Grid>
    </Loader>
  )
}

export default ListaDistribuidoras;