/* eslint-disable no-restricted-globals */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable func-names */
/* eslint-disable no-plusplus */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-return-assign */
/* eslint-disable radix */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useCallback } from "react";
import { Base } from "componentes/cores";
import Botao from "componentes/botao";
import SwipeableViews from "react-swipeable-views";
import { TabPanel, ModalConfirmacao } from "componentes";
import { useTheme } from "@mui/material/styles";
import { Grid, Tab, Tabs, AppBar, InputLabel } from "@mui/material";
import MaterialInputMascara from "componentes/inputTextoMascara";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import Loader from "componentes/loader";
import { store } from "global/redux";
import MaterialInputTexto from "componentes/inputTexto/materialInput";
import { getParametrosGlobais, updateParametrosGlobais } from "../../servicos/parametrosGlobais";
import { useStyles } from "./style";


function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,
    };
}

const ParametrosGlobaisConfiguracoes = () => {
    const classes = useStyles();
    const theme = useTheme();
    const usuarioGlobal = useSelector((state) => state.usuario);

    const [value, setValue] = useState(0);
    const [nextIndex, setNextIndex] = useState(null);

    const [parametros, setParametros] = useState([]);
    
    const [componentes, setComponentes] = useState([]);
    const [copiaComponentes, setCopiaComponentes] = useState([]);

    const [loadingParametros, setLoadingParametros] = useState(false);
    const [showModalConfirmacao, setShowModalConfirmacao] = useState(false);

    let hasError = false;

    const { handleSubmit } = useForm({
        reValidateMode: "onSubmit",
    });

    function getValorCorreto(element) {
        switch (element.tipoDado) {
            case "INT":
                return element.valorInt;
            case "DEC":
                return element.natureza !== "%" ? element.valorFloat : +(element.valorFloat * 100).toFixed(14);
            case "DAT":
                return element.valorData;
            case "TXT":
                return element.valorTexto
            default:
        }
        return "";
    }

    function camelCase(str) {
        return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
            if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
            return index === 0 ? match.toLowerCase() : match.toUpperCase();
        });
    }

    const getParametros = useCallback(async () => {
        try {
            setLoadingParametros(true);
            
            await getParametrosGlobais().then((response) => {
                if (response.status === 200 && response?.data) {
                    setParametros(response.data);

                    const componenteMontagem = [];
                    let copiaComponenteMontagem = [];

                    const abasIncluir = [];
                    let index = 0;
                    
                    response.data.forEach(element => {
                        if (!abasIncluir.includes(element.contexto)) {
                            abasIncluir.push(element.contexto);
                            componenteMontagem.push({ "aba": element.contexto, "index": index, "conteudo": [] });
                            index++;
                        }
                    });
                    response.data.forEach(elementResponse => {
                        componenteMontagem.forEach(element => {
                            if (elementResponse.contexto === element.aba)
                                element.conteudo.push({
                                    "idParametro": elementResponse.idParametro,
                                    "contexto": elementResponse.contexto,
                                    "formato": elementResponse.formato,
                                    "id": camelCase(elementResponse.parametro.normalize("NFD").replace(/[^a-zA-Zs]/g, "")),
                                    "parametro": elementResponse.parametro,
                                    "natureza": elementResponse.natureza,
                                    "tipoDado": elementResponse.tipoDado,
                                    "valor": getValorCorreto(elementResponse),
                                    "descricao": elementResponse.descricao,
                                    "atualizadoEm": elementResponse.atualizadoEm,
                                    "nomeUsuario": elementResponse.nomeUsuario,
                                    "idUsuario": elementResponse.idUsuario
                                })
                        });
                    });
                    copiaComponenteMontagem = JSON.parse(JSON.stringify(componenteMontagem))

                    setComponentes(componenteMontagem);
                    setCopiaComponentes(copiaComponenteMontagem);
                    setLoadingParametros(false);
                }
            });        
        } catch (error) {
            console.info(error);
            setLoadingParametros(false);
        }
    });

    useEffect(() => {
        if (!parametros.length) getParametros();
    }, []);

    const checkDiff = () => {
        let sameValue = [];

        componentes[value].conteudo.forEach(item => {
            sameValue.push(
                item.valor ===
                copiaComponentes[value]
                    .conteudo
                        .find(parametroAntigo => parametroAntigo.idParametro === item.idParametro)
                            .valor
            );
        });

        return sameValue.some(value => !value);;
    }

    const handleChange = (event, newValue) => {
        const hasDiff = checkDiff();

        if (!hasDiff) {
            setValue(newValue);
            return;
        }

        setShowModalConfirmacao(true);
        setNextIndex(newValue);
    };

    const enviarFormulario = async () => {
        setLoadingParametros(true);
        hasError = false;
        setShowModalConfirmacao(false);
        const JSONRetorno = [];
        componentes[value].conteudo.forEach(item => {
            const sameValue = (
                item.valor ===
                copiaComponentes[value]
                    .conteudo
                        .find(parametroAntigo => parametroAntigo.idParametro === item.idParametro)
                            .valor
            )

            let copiaItem = JSON.parse(JSON.stringify(item));

            if (!sameValue) {
                copiaItem.nomeUsuario = usuarioGlobal.usuario.usuarioNome;
                copiaItem.idUsuario = parseInt(usuarioGlobal.usuario.id);                
            }

            copiaItem.valor = copiaItem.natureza !== "%" ? copiaItem.valor : +(copiaItem.valor / 100).toFixed(8);
            copiaItem.valorInt = copiaItem.tipoDado === "INT" ? copiaItem.valor : 0;
            copiaItem.valorFloat = copiaItem.tipoDado === "DEC" ? copiaItem.valor : 0;
            copiaItem.valorData = copiaItem.tipoDado === "DAT" ? copiaItem.valor : null;
            copiaItem.valorTexto = copiaItem.tipoDado === "TXT" ? copiaItem.valor : "";

            JSONRetorno.push(copiaItem);
        });
       
        const abaProjecaoBilateral = componentes[value].aba === "Projeção Bilaterais"; 

        if (abaProjecaoBilateral) {
            let soma = 0.0;
            JSONRetorno
                .filter(item => item.contexto == "Projeção Bilaterais")
                .forEach(o=>soma += o.valorFloat);

            const possuiValorZero = JSONRetorno
                .filter(item => item.contexto == "Projeção Bilaterais")
                .some(item=>item.valor == 0)

            if(soma !== 1.0) {
                store.dispatch(
                    alertaExibir({
                        tipo: "warning",
                        mensagem: "A soma dos parâmetros de Projeção Bilaterais deve totalizar 100%."
                    })
                );
                hasError = true;
                setLoadingParametros(false);
                return;
            } else if (possuiValorZero) {
                store.dispatch(
                    alertaExibir({
                        tipo: "warning",
                        mensagem: "Um ou mais parâmetros de Projeção Bilaterais não foi preenchido."
                    })
                );
                hasError = true;
                setLoadingParametros(false);
                return;
            }

        }
        
        await updateParametrosGlobais(JSONRetorno).then((data) => {
            if (data.status === 200) {
                store.dispatch(
                    alertaExibir({
                        tipo: "success",
                        mensagem: "Configurações de parâmetros globais salvas com sucesso!"
                    })
                );
            } else {
                store.dispatch(
                    alertaExibir({
                        tipo: "warn",
                        mensagem: "Falha na atualizacao de parametros globais."
                    })
                ); 
            }
        });

        getParametros();            
        setLoadingParametros(false);
    }

    const aoEnviarFormulario = (dados) => {
        enviarFormulario(dados);
    };

    function formatarValorSalvar(dado) {
        const formatado = dado
            ? +(parseFloat(
                String(dado)
                    ?.replace(" MWh", "")
                    ?.replace(" kW", "")
                    ?.replace("R$ ", "")
                    ?.replace("%", "")
                    ?.replace(" MWmed", "")
            ).toFixed(8))
            : dado;

        return formatado;
    }

    const comps = (element) => {
        switch (element.natureza) {
            case "Qtd. Anos":
                return ( 
                    <MaterialInputMascara
                        label={element.parametro}
                        type="text"
                        id={element.id}
                        name={element.id}
                        sufixo=" anos"
                        defaultValue={element.valor}
                        onBlur={(e) => element.valor = parseInt(e.target.value?.replace(" anos", ""))}
                    />
                )
            case "Mês": 
                return (
                    <MaterialInputMascara
                        label={element.parametro}
                        type="text"
                        id={element.id}
                        name={element.id}
                        defaultValue={element.valor}
                        onBlur={(e) => element.valor = parseInt(e.target.value)}
                    />
                )
            case "Qtd. Meses":
                return (
                    <MaterialInputMascara
                        label={element.parametro}
                        type="text"
                        id={element.id}
                        name={element.id}
                        sufixo=" meses"
                        defaultValue={element.valor}
                        onBlur={(e) => element.valor = parseInt(e.target.value?.replace(" meses", ""))}
                    />
                )
            case "Qtd.Meses":
                return (
                    <MaterialInputMascara
                        label={element.parametro}
                        type="text"
                        id={element.id}
                        name={element.id}
                        sufixo=" meses"
                        defaultValue={element.valor}
                        onBlur={(e) => element.valor = parseInt(e.target.value?.replace(" meses", ""))}
                    />
                )
            case "%":
                return (
                    <MaterialInputMascara
                        label={element.parametro}
                        type="text"
                        sufixo="%"
                        id={element.id}
                        name={element.id}
                        defaultValue={element.valor}
                        onBlur={(e) => element.valor = formatarValorSalvar(e.target.value.replaceAll(".", "").replace(",", "."))}
                    />
                )
            case "Fator":
                return (
                    <MaterialInputMascara
                        label={element.parametro}
                        type="text"
                        id={element.id}
                        name={element.id}
                        defaultValue={element.valor}
                        onBlur={(e) => element.valor = formatarValorSalvar(e.target.value.replaceAll(".", "").replace(",", "."))}
                    />)
            case "R$":
                return (
                    <MaterialInputMascara
                        label={element.parametro}
                        type="text"
                        prefixo="R$ "
                        id={element.id}
                        name={element.id}
                        defaultValue={element.valor}
                        onBlur={(e) => element.valor = formatarValorSalvar(e.target.value.replaceAll(".", "").replace(",", "."))}
                    />
                )
            case "MWmed":
                return (
                    <MaterialInputMascara
                        label={element.parametro}
                        type="text"
                        sufixo=" MWmed"
                        id={element.id}
                        name={element.id}
                        defaultValue={element.valor}
                        onBlur={(e) => element.valor = formatarValorSalvar(e.target.value.replaceAll(".", "").replace(",", "."))}
                    />
                )
            case "MWmédio":
                return (
                    <MaterialInputMascara
                        label={element.parametro}
                        type="text"
                        sufixo=" MWmed"
                        id={element.id}
                        name={element.id}
                        defaultValue={element.valor}
                        onBlur={(e) => element.valor = formatarValorSalvar(e.target.value.replaceAll(".", "").replace(",", "."))}
                    />
                )
            case "R$ MM":
                return (
                    <MaterialInputMascara
                        label={element.parametro}
                        type="text"
                        prefixo="R$ "
                        id={element.id}
                        name={element.id}
                        defaultValue={element.valor}
                        onBlur={(e) => element.valor = formatarValorSalvar(e.target.value.replaceAll(".", "").replace(",", "."))}
                    />
                )
            case "R$/MWh":
                return (
                    <MaterialInputMascara
                        label={element.parametro}
                        type="text"
                        prefixo="R$ "
                        id={element.id}
                        name={element.id}
                        defaultValue={element.valor}
                        onBlur={(e) => element.valor = formatarValorSalvar(e.target.value.replaceAll(".", "").replace(",", "."))}
                    />
                )
            case "Filtro":
                return (
                    <MaterialInputTexto
                        fullWidth
                        type="text"
                        id={element.id}
                        name={element.id}
                        label={element.parametro}
                        renderIconShowHide={false}
                        defaultValue={element.valor}
                        onBlur={(e) => element.valor = e.target.value}
                    />
                )
            default:
                return (<p>{`Natureza ${element.natureza} do parâmetro ${element.parametro} não mapeada.`}</p>)
        }
    }

    return <>
        <ModalConfirmacao
            item={showModalConfirmacao}
            onCancelar={async () => {
                setShowModalConfirmacao(!showModalConfirmacao)
                await getParametros();
                setValue(nextIndex);
            }}
            onConfirmar={async () => {
                await enviarFormulario();
                if (!hasError) {
                    setValue(nextIndex);
                }; 
            }}
            mensagem={
                <div>         
                    <h5>Alterações Pendentes</h5>
                    <p style={{
                        marginBottom: 10
                    }}>
                        Deseja salvar as alterações realizadas?
                    </p>
                </div>
            }
        />
        <form className="needs-validation" onSubmit={handleSubmit(aoEnviarFormulario)}>
            <div className={classes.root}>
                <Loader loading={loadingParametros}>
                    <AppBar position="static" color="default">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            scrollButtons
                            variant="scrollable"
                            allowScrollButtonsMobile>
                            {componentes.map((item) => (
                                <Tab label={item.aba} {...a11yProps(parseInt(item.index))} />
                            ))}
                        </Tabs>
                    </AppBar>
                    <SwipeableViews
                        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                        index={value}
                    >
                        {componentes.map((item) => (
                            <TabPanel value={value} index={parseInt(item.index)} dir={theme.direction}>
                                <InputLabel id="perid" style={{ fontWeight: "bold", paddingBottom: "15px" }}>Parâmetros</InputLabel>
                                <Grid container>
                                    {item.conteudo.map((element) => (
                                        <Grid item sm={3} style={{ paddingRight: "20px", paddingBottom: "30px" }}>
                                            {comps(element)}
                                        </Grid>
                                    ))}
                                </Grid>
                            </TabPanel>
                        ))}

                    </SwipeableViews>
                    <Grid container className={classes.container} justifyContent="center" sm={12}>
                        <Grid item sm={6} class={classes.footerButtons}>
                            <Grid container spacing={2} className={classes.container} justifyContent="flex-end">
                                <Grid item sm={3}>
                                    <Botao type="submit" color={Base.Mango} label="Salvar" className={classes.button} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Loader>

            </div>
        </form>
    </>;
}

export default ParametrosGlobaisConfiguracoes;