import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Avatar, Menu, MenuItem, Grid } from "@mui/material";
import ModalInformacao from "componentes/modalVerificacao";

import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";

import { ToggleBadge } from "../../style";
import { useStyles, Wrapper } from "./style";

import { deslogarUsuario } from "../../../../global/redux/modulos/usuario/actions";
import { store } from "../../../../global/redux";

import clientData from "../../../../global/clientData";

const AreaUsuario = () => {
  const classes = useStyles();

  const [menuAberto, setMenuAberto] = useState(null);
  const [exibirModalTermo, setExibirModalTermo] = useState(false);

  const onClickAbrirMenu = (event) => {
    setMenuAberto(event.currentTarget);
  };

  const onClickFecharMenu = () => {
    setMenuAberto(null);
  };

  const onClickSair = () => {
    store.dispatch(deslogarUsuario());
    window.location.reload(true);
  };

  return (
    <>
      <ModalInformacao
        telaInteira
        exibir={exibirModalTermo}
        titulo="Termo de uso e privacidade"
        mensagem=""
        labelBotao="OK"
        onClose={() => {
          setExibirModalTermo(false);
        }}
        showBotao={false}
      />

      <Wrapper className={classes.wrapper}>
        <ToggleBadge
          badgeContent={
            !menuAberto ? (
              <ExpandMoreIcon className={classes.badgeSize} />
            ) : (
              <ExpandLessIcon className={classes.badgeSize} />
            )
          }
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          color="default"
          onClick={onClickAbrirMenu}
          aria-controls="usuarioMenu"
          aria-haspopup="true"
        >
          <Avatar variant="rounded" className={classes.avatar}>
            <PersonOutlineOutlinedIcon fontSize="medium" />
          </Avatar>
        </ToggleBadge>
        <Menu
          id="usuarioMenu"
          anchorEl={menuAberto}
          keepMounted
          elevation={0}
          open={Boolean(menuAberto)}
          onClose={onClickFecharMenu}
          MenuListProps={{ className: classes.menu }}
        >
          <MenuItem className={classes.menuItem} onClick={onClickFecharMenu}>
            <SettingsOutlinedIcon className={classes.menuIcon} />
            <Link to="/conta/alterar-senha" className={classes.menuLink}>
              Alterar Senha
            </Link>
          </MenuItem>
          <MenuItem className={classes.menuItem} onClick={onClickSair}>
            <PowerSettingsNewOutlinedIcon className={classes.menuIcon} />
            Sair
          </MenuItem>
          <MenuItem className={classes.menuFooter}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid
                item
                lg={12}
                sm={12}
                className="d-flex justify-content-center"
                fontSize="16px"
              >
                {/* <Link className={classes.footerLink} to="/">
                Privacidade
              </Link>
              &nbsp;•&nbsp;
              <Link className={classes.footerLink} onClick={handleClick} to="/">
                Termos
              </Link>
              &nbsp;&nbsp;&nbsp; */}
                {clientData.footer.copyright}
              </Grid>
            </Grid>
          </MenuItem>
        </Menu>
      </Wrapper>
    </>
  );
};

export default AreaUsuario;
