import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { DateRange } from "react-date-range";
import { ptBR } from "date-fns/locale";
import {
  Grid,
  Popover,
  InputAdornment,
  TextField,
  IconButton
} from "@mui/material";
import DateRangeIcon from "@mui/icons-material/DateRange";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Botao from "../../../../componentes/botao";
import { useStyles } from "./style";
import theme from "../../../../themes";

const InputRangeData = ({ dataInicial, dataFinal, onChangeData }) => {
  const classes = useStyles();
  const calendarioRef = useRef(0);
  const [datas, setDatas] = useState({
    startDate: moment(dataInicial).isValid()
      ? moment(dataInicial).toDate()
      : new Date(),
    endDate: moment(dataFinal).isValid()
      ? moment(dataFinal).toDate()
      : new Date(),
    key: "selection"
  });
  const [motrarCalendario, setMotrarCalendario] = useState(false);
  const [valor, setValor] = useState(null);
  const [ancora, setAncora] = useState(null);

  const fecharCalendario = () => {
    setMotrarCalendario(false);
    setAncora(null);
  };

  const formatarValor = ({ startDate, endDate }) => {
    let inputValor = "";
    if (startDate) inputValor += moment(startDate).format("MMM");
    if (endDate)
      inputValor += ` a ${moment(endDate).format("MMM")}, ${moment(
        endDate
      ).format("YYYY")}`;

    setValor(inputValor);
  };

  const onSelectDateRanges = (item) => {
    const { startDate, endDate } = item.selection;

    formatarValor(item.selection);
    setDatas(item.selection);
    if (endDate !== startDate) {
      fecharCalendario();
      onChangeData(item.selection);
    }
  };

  useEffect(() => {
    formatarValor(datas);
  }, []);

  return (
    <Grid container>
      <Grid xs={7} sm={8}>
        <TextField
          label=""
          fullWidth
          value={valor}
          ref={calendarioRef}
          disabled
          className={classes.container}
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="end"
                onClick={() => {
                  setAncora(calendarioRef.current);
                  setMotrarCalendario(true);
                }}
              >
                <IconButton>
                  <DateRangeIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <Popover
          open={motrarCalendario}
          anchorEl={ancora}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
          onClose={fecharCalendario}
        >
          <DateRange
            ranges={[datas]}
            onChange={(date) => onSelectDateRanges(date)}
            locale={ptBR}
            staticRanges={undefined}
            inputRanges={undefined}
            moveRangeOnFirstSelection={false}
            showDateDisplay={false}
            scroll={{ enabled: true }}
            rangeColors={[
              theme.color.primary,
              theme.color.secondary,
              theme.color.primary
            ]}
            months={2}
            direction="horizontal"
          />
        </Popover>
      </Grid>
      <Grid xs={5} sm={4}>
        <Botao
          type="button"
          label="Alterar período"
          className={classes.button}
          onClick={() => {
            setAncora(calendarioRef.current);
            setMotrarCalendario(true);
          }}
        />
      </Grid>
    </Grid>
  );
};

InputRangeData.propTypes = {
  onChangeData: PropTypes.func,
  dataInicial: PropTypes.string,
  dataFinal: PropTypes.string
};

InputRangeData.defaultProps = {
  onChangeData: () => {},
  dataInicial: "",
  dataFinal: ""
};

export default InputRangeData;
