import { store } from "../../../global/redux";
import RetornoEndpointDto from "../../../global/dto/retornoEndpointDto";
import {
  exportarExcelUsinasUnidade,
  uploadUsinasUnidade
} from "../../../servicos/uploadServico";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";

export default class UploadUsinasUnidadeHelper {
  static async uploadUsinasUnidade(arquivo, setShowLoader) {
    try {
      setShowLoader(true);
      const resultado = await uploadUsinasUnidade(arquivo);

      return RetornoEndpointDto.Sucesso(
        "Arquivo enviado com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Ocorreu um erro ao enviar o arquivo");
    } finally {
      setShowLoader(false);
    }
  }

  static async exportarExcelUsinasUnidade(setShowLoader) {
    try {
      setShowLoader(true);

      const resultado = await exportarExcelUsinasUnidade();

      if (resultado.status === "204") {
        return RetornoEndpointDto.Sucesso("Nenhum Excel encontrado!", []);
      }

      return RetornoEndpointDto.Sucesso(
        "Arquivo baixado com sucesso!",
        resultado?.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Ocorreu um erro ao baixar o arquivo");
    } finally {
      setShowLoader(false);
    }
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }

  static exibirSucesso(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem
      })
    );
  }
}
