import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { Card, Grid } from '@mui/material';
import { useStyles, Tabela, TabelaScroll } from '../../../style';
import Loader from 'componentes/loader';
import SelectArredondado from 'componentes/selectArredondado';
import InputDataBranco from 'componentes/inputTextoDataBranco';
import { listarDistribuidoras } from '../../../../../servicos/parametrizacaoDistribuidoras';
import { valoresContratosLeilaoSparta } from '../../../../../servicos/premissasBrasil';
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";

const LeilaoSpartaVisualizar = () => {
  const classes = useStyles();

  const [carregandoDistribuidoras, setCarregandoDistribuidoras] = useState(false);
  const [listaDistribuidoras, setListaDistribuidoras] = useState([]);

  const [distribuidoraID, setDistribuidoraID] = useState("");
  const onChangeDistribuidora = (event) => setDistribuidoraID(event.target.value);

  const [inicioSuprimento, setInicioSuprimento] = useState(null);
  const handleChangeInicioSuprimento = (ano) => setInicioSuprimento(ano);

  const obterDistribuidoras = useCallback(async () => {
    try {
      setCarregandoDistribuidoras(true);
      const lista = await listarDistribuidoras(true);
      if (lista.status === 200 && lista.data) {
        setCarregandoDistribuidoras(false);    
        setListaDistribuidoras(lista?.data);
      }
    } catch (error) {
      setCarregandoDistribuidoras(false);
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );;
    }
  }, []);

  useEffect(() => {
    if (!listaDistribuidoras.length) obterDistribuidoras();
  }, [!listaDistribuidoras.length, obterDistribuidoras]);

  const [valores, setValores] = useState([]);
  const [showValores, setShowValores] = useState(false);

  const obterValores = useCallback(async (id) => {
    try {
      if(moment(inicioSuprimento).format("YYYY").length === 4){
        const response = await valoresContratosLeilaoSparta(id, moment(inicioSuprimento).format("YYYY"));
        if (response.status === 200 && response.data) {
          setValores(response?.data[0]?.ContratosDeLeilao);
          setShowValores(true);
        } else {
          setValores([]);
          setShowValores(false);
          store.dispatch(
            alertaExibir({
              tipo: "warning",
              mensagem: "Não há dados para impressão com os parâmetros informados."
            })
          );
        }
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  }, [inicioSuprimento]);

  useEffect(() => {
    if (distribuidoraID && inicioSuprimento) obterValores(distribuidoraID);
  }, [distribuidoraID, inicioSuprimento, obterValores]);

  return <>
    <Grid container spacing={4} p={2} className={classes.container} justifyContent="flex-end" alignItems="center">
      <Grid item sm={3}>
        <Loader loading={carregandoDistribuidoras}>
          <SelectArredondado
            id="distribuidoraId"
            name="distribuidoraId"
            valueKey="id"
            valueName="Titulo"
            dataSource={listaDistribuidoras}
            label="Agente Comprador"
            value={distribuidoraID}
            onChange={(event) => onChangeDistribuidora(event)}
            placeholder="Agente Comprador"
            allowClear
            className={classes.selectOfficer}
            labelClassName={classes.selectOfficer}
          />
        </Loader>
      </Grid>
      <Grid item sm={2} className={classes.customSelect}>
        <InputDataBranco
          type="text"
          id="inicioSuprimento"
          name="inicioSuprimento"
          customValue={inicioSuprimento}
          onChange={(data) => handleChangeInicioSuprimento(data)}
          maxDate={moment("2090-12-31").toDate()}
          format="yyyy"
          views={["year"]}
          renderIconShowHide={false}
        />
      </Grid>
    </Grid>
    {showValores === true ?
      <Card>
        <Grid container spacing={4} p={2} className={classes.container}>
          <Grid item sm={12}>
            <TabelaScroll>
              <div id="scroll">
                <Tabela>
                  <Tabela.Thead>
                    <Tabela.Tr>
                      <Tabela.Th>Leilão</Tabela.Th>
                      <Tabela.Th>Produto</Tabela.Th>
                      <Tabela.Th>Modalidade</Tabela.Th>
                      <Tabela.Th>Fonte</Tabela.Th>
                      <Tabela.Th>Montante</Tabela.Th>
                      <Tabela.Th>Data Base</Tabela.Th>
                      <Tabela.Th>Custo Base</Tabela.Th>
                      <Tabela.Th>Tarifa Atualizada</Tabela.Th>
                      <Tabela.Th>MWh contratado</Tabela.Th>
                      <Tabela.Th>MWh considerado</Tabela.Th>
                      <Tabela.Th>Despesa final por produto</Tabela.Th>
                    </Tabela.Tr>
                  </Tabela.Thead>
                  <Tabela.Tbody>
                    {valores?.map((item) => (
                      <Tabela.Tr>
                        <Tabela.Td>{item.Leilao}</Tabela.Td>
                        <Tabela.Td>{item.Produto}</Tabela.Td>
                        <Tabela.Td>{item.Modalidade}</Tabela.Td>
                        <Tabela.Td>{item.Fonte}</Tabela.Td>
                        <Tabela.Td>{item.Montante.toLocaleString("pt-BR", { minimumFractionDigits: 3 })}</Tabela.Td>
                        <Tabela.Td>{moment(item.DatBase).format("DD/MM/YYYY")}</Tabela.Td>
                        <Tabela.Td>{item.CustoBase.toLocaleString("pt-BR", { minimumFractionDigits: 2 })}</Tabela.Td>
                        <Tabela.Td>{item.TarifaAtualizada.toLocaleString("pt-BR", { minimumFractionDigits: 2 })}</Tabela.Td>
                        <Tabela.Td>{item.MwhContratado.toLocaleString("pt-BR", { minimumFractionDigits: 2 })}</Tabela.Td>
                        <Tabela.Td>{item.MwhConsiderado.toLocaleString("pt-BR", { minimumFractionDigits: 2 })}</Tabela.Td>
                        <Tabela.Td>{item.DespesaFinalPorProduto.toLocaleString("pt-BR", { minimumFractionDigits: 2 })}</Tabela.Td>
                      </Tabela.Tr>
                    ))}
                  </Tabela.Tbody>
                </Tabela>
              </div>
            </TabelaScroll>
          </Grid>
        </Grid>
      </Card>
      : ""
    }
  </>;
}

export default LeilaoSpartaVisualizar;