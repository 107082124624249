import { createTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import styled from "styled-components";
import theme from "themes";

const th = createTheme();

export const useStyles = makeStyles(() => ({
  wrapper: {
    alignItems: "center",
    borderRight: `1px solid ${theme.color.secundary}`,
    display: "flex",
    fontSize: "18px",
    height: th.spacing(6.5),
    marginRight: "10px",
    paddingRight: "12px",
    color: `${theme.color.secondaryText} !important`
  },
  icon: {
    marginRight: "10px"
  }
}));

export const Wrapper = styled.div``;

export const Mes = styled.span`
  text-transform: uppercase;
`;

export const Ola = styled.div`
  margin-right: 15px;
`;
