/* eslint-disable import/no-unresolved */
import MICROSERVICO from "global/enumeradores/microServico";
import obterApi from "servicos/api";

const api = () => obterApi(MICROSERVICO.PROJECAOTARIFARIA);

export const importVariaveisCDE = async (validarDominio, anoTarifario, usuarioGlobal, keyFileS3) => {
  const metodo = "post"
  const url = `v1/ImportVariaveisProjecaoCDE/importar?idUsuario=${usuarioGlobal?.usuario?.id}&nomeUsuario=${usuarioGlobal?.usuario?.usuarioNome}&validarDominio=${validarDominio}&anoReferencia=${anoTarifario}&keyFileS3=${keyFileS3}`;

  return api()[metodo](url);
};

export const getVariaveisCDE = async (anoInicio, anoFim) => {
  return api().get(`v1/ImportVariaveisProjecaoCDE/visualizar?anoinicial=${anoInicio}&anofinal=${anoFim}`)
}