import React from "react";
import PropTypes from "prop-types";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Porcentagem } from "./style";

export const Percentual = ({ valor }) => {
  const validarCor = () => {
    return valor > 0
      ? "#BF2A2A"
      : !valor || valor === 0
      ? "#F4A330"
      : "#2ABF6F";
  };

  if (valor > 0)
    return (
      <Porcentagem cor={validarCor()}>
        <ArrowDropUpIcon />
        {` +${valor}%`}
      </Porcentagem>
    );
  if (valor === 0)
    return <Porcentagem cor={validarCor()}>{`-- ${valor}%`}</Porcentagem>;
  if (valor < 0)
    return (
      <Porcentagem cor={validarCor()}>
        <ArrowDropDownIcon />
        {` ${valor}%`}
      </Porcentagem>
    );
  return <Porcentagem cor={validarCor()}> -- </Porcentagem>;
};

Percentual.propTypes = {
  valor: PropTypes.number
};

Percentual.defaultProps = {
  valor: 0
};

export default Percentual;
