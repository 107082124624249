import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";



const PassosCotacoesGd = ({ passoAtual, totalPassos }) => {

    const handleTitulo = (passo) => {
        switch (passo) {
            case 1:
                return "Simulador";
            case 2:
                return "Contratação";
            case 3:
                return "Contrato gerado"
            default:
                return ""
        }
    }
    
    return (
        <>
            <Box
                component="div"
                color="#9F9F9F"
                fontWeight="400"
                fontSize="14px"
            >
                {`Passo ${passoAtual} de ${totalPassos}`}
            </Box>
            <Box color="#332053" fontWeight="700" fontSize="24px">{handleTitulo(passoAtual)}</Box>
            <Box
                component="div"
                sx={{ background: "linear-gradient(90deg, #F722B5 0%, #FD9507 100%)" }}
                borderRadius="64px"
                height="8px"
                mb={4}
            />
        </>
    )
}

PassosCotacoesGd.propTypes = {
    passoAtual: PropTypes.number,
    totalPassos: PropTypes.number

}

PassosCotacoesGd.defaultProps = {
    passoAtual: 0,
    totalPassos: 0
}





export default PassosCotacoesGd;