import { store } from "../../../global/redux";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";
import RetornoEndpointDto from "../../../global/dto/retornoEndpointDto";
import {
  obterBeneficioFatura,
  criarElasticidadeBeneficio,
  salvarBeneficioFatura,
  atualizaStatusBeneficioFatura
} from "../../../servicos/memberGetMemberServico";

export default class MemberGetMemberFatura {
  static async obterBeneficioFatura(filtro, setCarregandoFatura) {
    try {
      setCarregandoFatura(true);
      const resultado = await obterBeneficioFatura(filtro);

      return RetornoEndpointDto.Sucesso(
        "Beneficios fatura encontrada com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Erro ao encontrar beneficios fatura!");
    } finally {
      setCarregandoFatura(false);
    }
  }

  static async criarElasticidadeBeneficio(body, setCarregandoFatura) {
    try {
      setCarregandoFatura(true);
      const resultado = await criarElasticidadeBeneficio(body);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro(
          "Elasticidade beneficio não encontrado!"
        );

      return RetornoEndpointDto.Sucesso(
        "Elasticidade beneficio criada com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Erro ao criar elasticidade beneficio!");
    } finally {
      setCarregandoFatura(false);
    }
  }

  static async salvarBeneficioFatura(id, body, setCarregandoFatura) {
    try {
      setCarregandoFatura(true);
      const resultado = await salvarBeneficioFatura(id, body);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Beneficio fatura não encontrado!");

      return RetornoEndpointDto.Sucesso(
        "Beneficios fatura salva com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Erro ao salvar beneficios fatura!");
    } finally {
      setCarregandoFatura(false);
    }
  }

  static async atualizaStatusBeneficioFatura(params, setCarregandoFatura) {
    try {
      setCarregandoFatura(true);
      const resultado = await atualizaStatusBeneficioFatura(params);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro(
          "Status beneficios fatura não encontrado!"
        );

      return RetornoEndpointDto.Sucesso(
        "Status beneficios fatura atualizado com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Erro ao atualizar status beneficios fatura!"
      );
    } finally {
      setCarregandoFatura(false);
    }
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }

  static exibirSucesso(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem
      })
    );
  }
}
