import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const api = () => obterApi(MICROSERVICO.MAQUINACALCULO);

export const executaCalculeira = (body) => {
  return api().post("/maquinadecalculo/executa-calculeira", body);
};

export const inserirLimiteProbalidadeInadimplencias = (body) => {
  return api().post(
    "/maquinadecalculo/inserir-limite-probabilidade-inadimplencia",
    body
  );
};
