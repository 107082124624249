import { createTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import theme from "themes";
import { Base } from "../../../../../componentes/cores";

export const th = createTheme();

export const chipStyles = makeStyles({
  previewChip: {
    minWidth: 160,
    maxWidth: 210,
    [th.breakpoints.down("md")]: {
      minWidth: 150,
      maxWidth: 150
    },
    [th.breakpoints.down("lg")]: {
      minWidth: 100,
      maxWidth: 100
    },
    [th.breakpoints.down("xl")]: {
      minWidth: 150,
      maxWidth: 150
    },
    [th.breakpoints.down("xl")]: {
      minWidth: 150,
      maxWidth: 150
    },
    "& .MuiChip-deleteIcon": {
      display: (props) => (props.iconeDesabled ? "none !important" : "block")
    }
  }
});

export const useStyles = makeStyles(() => ({
  containerDropZone: {
    "& .MuiTypography-h5": {
      fontSize: "18px",
      color: `${theme.color.dropZoneLabel}`
    },
    "& .MuiDropzoneArea-root": {
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      flexDirection: "row-reverse",
      backgroundColor: "transparent",
      borderRadius: theme.borda.input,
      borderColor: Base.GreyDim,
      borderWidth: "2px",
      borderStyle: "dashed",
      cursor: "default",
      color: Base.Preto,
      height: "54px",
      // paddingLeft: "10px",
      // paddingRigth: "10px",
      width: "100%",
      textAlign: "center",
      minHeight: "117px",
      marginBottom: "20px"
    },
    "& .MuiDropzoneArea-textContainer": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row-reverse",
      color: Base.Black
    },
    "& .MuiDropzoneArea-icon": {
      color: Base.GreyDim,
      width: 31,
      heigh: 31,
      marginRight: "20px"
    },
    "& .MuiDropzoneArea-text": {
      color: `${theme.color.dropZoneLabel} !important`,
      "&:hover": {
        color: Base.BlueBerry,
        cursor: "pointer"
      }
    },
    "& .MuiChip-clickable": {
      backgroundColor: "rgba(0, 0, 0, 0.7) !important",
      color: `${theme.color.secondaryText} !important`,
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.7)"
      }
    }
  },
  containerListagemImagens: {
    display: "flex",
    flexWrap: "wrap",
    color: "rgba(0, 0, 0, 0.7)",
    "& .MuiButtonBase-root:hover, .MuiButtonBase-root:active, .MuiButtonBase-root:focus": {
      color: "rgba(0, 0, 0, 0.7)",
      "& .MuiChip-deleteIcon": {
        color: "rgba(0, 0, 0, 0.7)",
        display: "none !important"
      }
    },
    "& .MuiChip-root": {
      margin: "4px",
      color: `${theme.color.secondaryBorderColor} !important`,
      fontSize: "14px",
      borderRadius: "5px",
      backgroundColor: "rgba(0, 0, 0, 0.7)"
    },
    "& .MuiChip-deleteIcon": {
      color: `${theme.color.secondaryBorderColor} !important`,
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      display: "none !important"
    }
  }
}));
