import { makeStyles } from "@mui/styles";
import styled from "styled-components";
import theme from "themes";
import { Base } from "../../../../../componentes/cores";

export const useStyles = makeStyles(() => ({
  container: {
    color: `${Base.Black} !important`,
    fill: `${Base.Black} !important`,
    "& .apexcharts-legend.apexcharts-align-center.position-bottom": {
      bottom: "0",
      display: "block",
      fontFamily: theme.font.family,
      fontSize: "14px",
      inset: "auto",
      overflowY: "hidden",
      textAlign: "justify",
      width: "100%"
    },
    "& .apexcharts-legend-text": {
      textAlign: "justify",
      fontSize: "18px !important",
      color: `${Base.Black} !important`,
      fill: `${Base.Black} !important`,
      width: "100%",
    },
    "& .apexcharts-datalabels-group": {
      transform: "translateX(32%)",
      "& .apexcharts-text.apexcharts-datalabel-label": {
        color: `${Base.Black}`,
        fill: `${Base.Black} !important`,
        textAnchor: "start",
        textTransform: "capitalize",
        transform: "translateX(0)"
      },
      "& .apexcharts-text.apexcharts-datalabel-value": {
        color: `${Base.Black}`,
        fill: `${Base.Black} !important`,
        textAnchor: "start",
        transform: "translateX(0)"
      }
    }
  }
}));

export const Legenda = styled.div`
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  text-transform: capitalize;
`;

export const Label = styled.div``;
export const Valor = styled.div``;
export const Total = styled.div``;
