import { store } from "../../../global/redux";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";
import RetornoEndpointDto from "../../../global/dto/retornoEndpointDto";
import {
  atualizarCupom,
  listarCupons
} from "../../../servicos/colaboradorCupomServico";

export default class EditarCupomHelper {
  static async listarCupons(filtro) {
    try {
      const resultado = await listarCupons(filtro);

      return RetornoEndpointDto.Sucesso(
        "Listagem de cupons obtidas com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu um erro ao obter a lista de cupons"
      );
    }
  }

  static async atualizarCupom(id, model) {
    try {
      const atualizando = await atualizarCupom(id, model);

      return RetornoEndpointDto.Sucesso(
        "Cupom atualizado com sucesso",
        atualizando.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        `Ocorreu um erro ao atualizar o cupom ${id}`
      );
    }
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }

  static exibirSucesso(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem
      })
    );
  }
}
