/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable consistent-return */
/* eslint-disable no-async-promise-executor */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-console */
/* eslint-disable radix */
/* eslint-disable no-unused-vars */
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import shortid from "shortid";

// Form Hooks
import { useForm } from "react-hook-form";

// Componentes
import { Box, Card, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { InputRadioGroup } from "componentes";
import { cnpj } from "cpf-cnpj-validator";
import { cotacaoTipoCalculo } from "global/constantes";
import { InterfaceDTO } from "global/dto/interfacesDto";
import { useSelector } from "react-redux";
import { usuarioPossuiFuncionalidade } from "servicos/funcionalidadesServico";
import Botao from "../../../componentes/botao";
import BotaoRetornarListagem from "../../../componentes/botaoRetornarListagem";
import MaterialInputTexto from "../../../componentes/inputTexto/materialInput";
import InputData from "../../../componentes/inputTextoData";
import MaterialInputMascara from "../../../componentes/inputTextoMascara";
import Loader from "../../../componentes/loader";
import ModalConfirmacao from "../../../componentes/modalConfirmacao";
import SelectArredondado from "../../../componentes/selectArredondado";
import SelectAutoComplete from "../../../componentes/selectAutocomplete";
import MaterialSwitch from "../../../componentes/switch";
import UploadCustomizado from "../../../componentes/uploadCustomizado/index";

// Serviços
import {
  listarColaboradoresPorArvoreGerencial,
  listarOfficersPorArvoreGerencial
} from "../../../servicos/colaboradoresServico";
import {
  buscarDistribuidoraNomeOuCnpj,
  buscarDistribuidoras
} from "../../../servicos/distribuidorasServico";

import { buscarDadosCadastraisPorCnpj } from "../../../servicos/dadosCadastraisCnpjServico";
import { buscarPorId } from "../../../servicos/usuariosServico";

// Styles
import { useStyles } from "./style";

// Redux
import { store } from "../../../global/redux";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";
import { responseFatura } from "../../../global/redux/modulos/log/actions";
import {
  desabilitarCliente,
  desabilitarEmpresa,
  desabilitarUnidade,
  selecionarEmpresa
} from "../../../global/redux/modulos/usuario/actions";
import statusCotacao from "../constantes/constanteStatusCotacao";
import CotacaoInicialHelper from "./helper";

// DTO
import { RotasDTO } from "../../../global/rotas/rotasUrlDto";

import { ReactComponent as Upload } from "../../../assets/cotacoes/luz/upload.svg";
import getDomain from "../../../global/getDomain";
import {
  digitalizacaoFatura,
  obterDadosFaturaDigitalizada
} from "../../../servicos/digitalizacaoServico";

const CotacaoInicialCadastro = () => {
  const domain = getDomain();
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("lg"));
  const { id } = useParams();

  const { register, errors, handleSubmit } = useForm({
    reValidateMode: "onSubmit"
  });

  const [dadosCotacaoEdicao, setDadosCotacaoEdicao] = useState(null);
  const [carregando, setCarregando] = useState(false);
  const [status, setStatus] = useState("AT");
  const [
    modalCotacaoAbertaMesmoCNPJ,
    setModalCotacaoAbertaMesmoCNPJ
  ] = useState(false);
  const [limparCNPJ, setLimparCNPJ] = useState(false);
  const [cotacaoIdMesmoCNPJ, setCotacaoIdMesmoCNPJ] = useState(0);
  const [faturaExternoId, setFaturaExternoId] = useState("");

  // Officer
  const [colaboradorId, setColaboradorId] = useState();
  const [listaOfficer, setListaOfficer] = useState([]);
  const [carregandoOfficer, setCarregandoOfficer] = useState(false);

  // Consumidor Livre
  const [consumidorLivre, setConsumidorLivre] = useState(false);
  const [tradicionalVarejista, setTradicionalVarejista] = useState("TR");
  const [opcoesConsumidorLivre, setOpcoesConsumidorLivre] = useState(null);
  const listaOpcoesConsumidorLivre = useMemo(() => {
    if (
      !opcoesConsumidorLivre ||
      opcoesConsumidorLivre == null ||
      opcoesConsumidorLivre.length === 0
    )
      return null;

    return opcoesConsumidorLivre.map((x) => {
      x.checked = String(x.valor) === String(consumidorLivre);

      return x;
    });
  }, [opcoesConsumidorLivre, consumidorLivre]);

  const onChangeConsumidorLivre = (valor) => {
    setConsumidorLivre(valor);
  };

  const onChangeConsumidorLivreRadio = (valor) => {
    setConsumidorLivre(valor);
  };

  const onChangeTradicionalVarejista = (valor) => {
    setTradicionalVarejista(valor);
  };

  const onChangeDataMigracao = (data) => {
    setDataMigracao(data);
  };

  // Unidade Cnpj
  const [unidadeCnpj, setUnidadeCnpj] = useState(false);
  const [opcoesUnidadeCnpj, setOpcoesUnidadeCnpj] = useState(null);
  const onChangeUnidadeCnpj = (valor) => {
    setUnidadeCnpj(valor);
  };
  const listaOpcoesUnidadeCnpj = useMemo(() => {
    if (
      !opcoesUnidadeCnpj ||
      opcoesUnidadeCnpj == null ||
      opcoesUnidadeCnpj.length === 0
    )
      return null;

    return opcoesUnidadeCnpj.map((x) => {
      x.checked = String(x.valor) === String(unidadeCnpj);

      return x;
    });
  }, [opcoesUnidadeCnpj, unidadeCnpj]);

  // Desconto baseado
  const [descontoBaseado, setDescontoBaseado] = useState(0);
  const [listaDescontoBaseado, setListaDescontoBaseado] = useState([]);
  const itensDescontoBaseado = useMemo(() => {
    if (
      !listaDescontoBaseado ||
      !listaDescontoBaseado.length ||
      listaDescontoBaseado.length === 0
    )
      return;

    if (!descontoBaseado || String(descontoBaseado) === "0")
      return listaDescontoBaseado.map((x, i) => {
        x.checked = x.valor === "1";

        return x;
      });

    return listaDescontoBaseado.map((x) => {
      x.checked = String(x.valor) === String(descontoBaseado);

      return x;
    });
  }, [listaDescontoBaseado, descontoBaseado]);

  const listaTradicionalVarejista = [
    { label: "Varejista", valor: "VT" },
    { label: "Tradicional", valor: "TR" }
  ];

  const descontoSelecionado = useMemo(() => {
    if (!itensDescontoBaseado || itensDescontoBaseado.length === 0) return null;

    return itensDescontoBaseado.find((x) => x.checked);
  }, [itensDescontoBaseado]);

  const custoFinanceiroSelecionado = useMemo(() => {
    if (!descontoSelecionado || descontoSelecionado == null) return false;

    return (
      String(descontoSelecionado.valor) ===
      String(cotacaoTipoCalculo.valorMedio)
    );
  }, [descontoSelecionado]);

  const rotas = useSelector((state) => state.rotas);
  const usuario = useSelector((state) => state.usuario);
  const { menu } = usuario;
  const permissaoAlterarFiltrarOfficer = usuarioPossuiFuncionalidade(
    menu,
    rotas.rotaAtual.caminho,
    RotasDTO.CotacaoIncial,
    InterfaceDTO.PodeAlterarFiltrarOfficer
  );

  useEffect(() => {
    setColaboradorId(usuario?.usuario?.colaboradorId);
    store.dispatch(desabilitarCliente(true));
    store.dispatch(desabilitarUnidade(true));

    return () => {
      store.dispatch(desabilitarEmpresa(false));
      store.dispatch(desabilitarCliente(false));
      store.dispatch(desabilitarUnidade(false));
    };
  }, []);

  useEffect(() => {
    if (mobile) {
      history.push("/cotacao/mobile");
    }
  }, [mobile]);

  const obterOpcoesSimNao = useCallback(() => {
    setOpcoesConsumidorLivre(CotacaoInicialHelper.obterOpcoesSimNao());
    setOpcoesUnidadeCnpj(CotacaoInicialHelper.obterOpcoesSimNao());
  }, [setOpcoesConsumidorLivre]);

  const obterListaDescontoBaseado = useCallback(() => {
    setListaDescontoBaseado(CotacaoInicialHelper.obterDescontoBaseado());
  }, [setListaDescontoBaseado]);

  const onChangeDescontoBaseado = (e) => {
    setDescontoBaseado(parseInt(e));
  };

  const onChangeDescontoBaseadoRadio = (e) => {
    setDescontoBaseado(parseInt(e?.target?.value));
  };

  const { empresaSelecionada } = usuario;

  useEffect(() => {
    obterOfficer(empresaSelecionada);
  }, [permissaoAlterarFiltrarOfficer]);

  const obterOfficer = async (empresa) => {
    try {
      setCarregandoOfficer(true);
      const lista = await listarOfficersPorArvoreGerencial(empresa ?? 0);
      let data = lista?.data;
      if (lista.status !== 200 || !lista.data) {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "Officers não encontrados!"
          })
        );
        return;
      }

      if (!permissaoAlterarFiltrarOfficer)
        data = data.filter((d) => d.id === usuario?.usuario?.colaboradorId);

      setListaOfficer(data);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    } finally {
      setCarregandoOfficer(false);
    }
  };

  const onChangeOfficer = (officer) => {
    setColaboradorId(officer.target.value);
  };

  const [demandaContratada, setDemandaContratada] = useState();
  const [demandaPonta, setDemandaPonta] = useState();
  const [dataMigracao, setDataMigracao] = useState(null);
  const [modalidade, setModalidade] = useState("VE");
  const [aumentarDemanda, setAumentarDemanda] = useState(false);

  useEffect(() => {
    if (demandaPonta > 0 && demandaContratada > 0) {
      setModalidade("AZ");
    } else if (demandaContratada > 0) {
      setModalidade("VE");
    } else {
      setModalidade("VE");
    }

    if (demandaPonta < 500 && demandaPonta > 0) {
      if (demandaContratada >= 500) {
        setAumentarDemanda(false);
      } else {
        setAumentarDemanda(true);
      }
    } else if (demandaContratada < 500 && demandaContratada > 0) {
      if (demandaPonta >= 500) {
        setAumentarDemanda(false);
      } else {
        setAumentarDemanda(true);
      }
    }

    if (dataMigracao && (demandaContratada < 500 || demandaPonta < 500)) {
      const date = moment(dataMigracao).isAfter("2023/12/31");
      if (date) {
        setAumentarDemanda(false);
      } else {
        setAumentarDemanda(true);
      }
    }

    if (
      dataMigracao === "" &&
      demandaContratada === "" &&
      demandaPonta === ""
    ) {
      setAumentarDemanda(false);
    } else if (demandaContratada >= 500 || demandaPonta >= 500) {
      setAumentarDemanda(false);
    } else if (!dataMigracao && !demandaContratada && !demandaPonta) {
      setAumentarDemanda(false);
    } else if (
      dataMigracao &&
      demandaContratada === "" &&
      demandaPonta === ""
    ) {
      setAumentarDemanda(false);
    } else if (demandaContratada === undefined && demandaPonta === "") {
      setAumentarDemanda(false);
    } else if (demandaPonta === undefined && demandaContratada === "") {
      setAumentarDemanda(false);
    }
  }, [dataMigracao, demandaContratada, demandaPonta]);

  const formataValor = (valor) => {
    if (typeof valor === "number") return valor;
    if (!valor) return "";

    return valor
      .replace("R$ ", "")
      .replace(" kW", "")
      .replace(" MWh", "")
      .replace(" MWm", "")
      .replace(".", "")
      .replace(",", ".")
      .replace(" ", "");
  };

  // Nome Fantasia
  const [nomeFantasia, setNomeFantasia] = useState();

  const [consumoForaPonta, setConsumoForaPonta] = useState();

  const [consumoPonta, setConsumoPonta] = useState();

  const [demandaForaPonta, setdemandaForaPonta] = useState();

  const [demandataPonta, setDemandaPontaFatura] = useState();

  const [carregandoNomeFantasia, setCarregandoNomeFantasia] = useState(false);

  // Distribuidora
  const [distribuidoraId, setDistribuidoraId] = useState(null);

  // Distribuidora
  const [listaDistribuidora, setListaDistribuidora] = useState([]);

  const [distribuidoraIdApi, setdistribuidoraIdApi] = useState([]);

  const [carregandoDistribuidora, setCarregandoDistribuidora] = useState(false);

  const isEdicao = useMemo(() => {
    return id && status !== statusCotacao.Expirada;
  }, [id, status]);

  const edicaoAberta = useMemo(() => {
    return id && status === statusCotacao.Aberta;
  });

  const obterDistribuidoraNomeOuCnpj = async (nomeOuCnpj) => {
    try {
      const distribuidora = await buscarDistribuidoraNomeOuCnpj(nomeOuCnpj);
      if (distribuidora?.status === 200) {
        setdistribuidoraIdApi(distribuidora?.data);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  useEffect(() => {
    obterDistribuidora();
  }, []);

  const obterDistribuidora = async () => {
    try {
      setCarregandoDistribuidora(true);
      const lista = await buscarDistribuidoras();
      if (lista?.status === 200 && lista?.data) {
        setListaDistribuidora(lista?.data);
      }
      setCarregandoDistribuidora(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoDistribuidora(false);
    }
  };

  useEffect(() => {
    obterDistribuidora();
  }, []);

  const [dadosCnpj, setDadosCnpj] = useState();

  const verificarCnpj = useCallback(async (valor) => {
    const cnpjFormatado =
      valor?.target?.value?.replace(/[^a-zA-Z0-9]/g, "") ?? valor;

    setDadosCnpj(cnpjFormatado);
    if (cnpjFormatado.length === 14) {
      try {
        setCarregandoNomeFantasia(true);
        const dadosCadastrais = await buscarDadosCadastraisPorCnpj(
          cnpjFormatado
        );

        if (dadosCadastrais?.status === 200 && dadosCadastrais?.data) {
          setDadosCnpj(dadosCadastrais.data.data.cnpj);
          setNomeFantasia(
            dadosCadastrais.data.data.nomeFantasia ||
              dadosCadastrais.data.data.razaoSocial
          );
          if (
            dadosCadastrais?.data?.data?.situacaoCadastral !== "2" &&
            dadosCadastrais?.data?.data?.descricaoDaSituacaoCadastral !==
              "Ativa"
          ) {
            // store.dispatch(
            // alertaExibir({
            //   tipo: "warning",
            //   mensagem:
            //     "CNPJ inserido encontra-se irregular na Receita Federal"
            // })
            // );
          }
        }
        setCarregandoNomeFantasia(false);
      } catch (error) {
        // store.dispatch(
        // alertaExibir({
        //   tipo: "warning",
        //   mensagem:
        //     error?.response?.data?.message ??
        //     "CNPJ não encontrado na Receita Federal"
        // })
        // );
        setCarregandoNomeFantasia(false);
      }
    }
  }, []);

  const obterCotacaoPorId = useCallback(async () => {
    if (!id) return;

    setCarregando(true);

    const resultado = await CotacaoInicialHelper.obterCotacaoPorId(id);

    if (!resultado.sucesso) {
      CotacaoInicialHelper.exibirErro(resultado.mensagem);
      setCarregando(false);
      history.push(RotasDTO.ListagemCotacoes);
      return;
    }

    const { data: cotacaoBanco } = resultado;

    setDadosCotacaoEdicao(cotacaoBanco);
    setDataMigracao(cotacaoBanco.dataMigracao);
    onChangeDataMigracao(cotacaoBanco.dataMigracao);

    setDistribuidoraId(cotacaoBanco.distribuidoraId);
    setConsumidorLivre(cotacaoBanco.consumidorLivre);
    setTradicionalVarejista(cotacaoBanco.tradicionalVarejista);
    setColaboradorId(cotacaoBanco.colaboradorId);
    setStatus(cotacaoBanco.status);
    setDemandaContratada(cotacaoBanco.demandaContratada);
    setDemandaPonta(cotacaoBanco.demandaPonta);
    setDescontoBaseado(cotacaoBanco.tipoCalculo);
    setUnidadeCnpj(cotacaoBanco.unidadeCnpj);
    setDadosCnpj(cotacaoBanco.unidadeCnpj);
    store.dispatch(selecionarEmpresa(cotacaoBanco.empresaId));

    setCarregando(false);
  }, [id]);

  useEffect(() => {
    obterCotacaoPorId();
  }, [obterCotacaoPorId]);

  useEffect(() => {
    obterListaDescontoBaseado();
    obterOpcoesSimNao();
  }, [obterListaDescontoBaseado, obterOpcoesSimNao]);

  const formatarDadosCotacao = (dados) => {
    try {
      const cnpjFormatado =
        dados?.cnpj?.replace(/[^a-zA-Z0-9]/g, "") ||
        dadosCotacaoEdicao?.cnpj?.replace(/[^a-zA-Z0-9]/g, "");

      return {
        DistribuidoraId: distribuidoraId,
        ConsumidorLivre: consumidorLivre || dadosCotacaoEdicao?.consumidorLivre,
        tradicionalVarejista:
          tradicionalVarejista || dadosCotacaoEdicao?.tradicionalVarejista,
        UnidadeCnpj: unidadeCnpj || "false",
        Cnpj: cnpjFormatado,
        NomeFantasia: dados?.nomeFantasia || dadosCotacaoEdicao?.nomeFantasia,
        ValorMedioConta:
          ((dados?.valorMedio ?? 0.0) && formataValor(dados?.valorMedio)) ||
          ((dadosCotacaoEdicao?.valorMedioConta ?? 0.0) &&
            formataValor(dadosCotacaoEdicao?.valorMedioConta)),
        ConsumoForaPonta:
          ((dados?.consumoForaPonta ?? 0.0) &&
            formataValor(dados?.consumoForaPonta)) ||
          ((dadosCotacaoEdicao?.consumoForaPonta ?? 0.0) &&
            formataValor(dadosCotacaoEdicao?.consumoForaPonta)),
        ConsumoPonta:
          ((dados?.consumoPonta ?? 0.0) && formataValor(dados?.consumoPonta)) ||
          ((dadosCotacaoEdicao?.consumoPonta ?? 0.0) &&
            formataValor(dadosCotacaoEdicao?.consumoPonta)),
        DemandaContratada:
          (dados?.demandaContratadaKW &&
            formataValor(dados?.demandaContratadaKW)) ||
          (dadosCotacaoEdicao?.demandaContratada &&
            formataValor(dadosCotacaoEdicao?.demandaContratada)),

        DemandaPonta: demandaPonta || dados?.demandaPonta,

        DataMigracao: dataMigracao || dados?.dataMigracao,
        Modalidade: modalidade || dados?.modalidade,

        ColaboradorId: colaboradorId || dadosCotacaoEdicao?.colaboradorId,
        EmpresaId: usuario?.empresaSelecionada || dadosCotacaoEdicao?.empresaId,
        Status: !isEdicao ? "AT" : status,
        TipoCalculo:
          descontoSelecionado.valor || dadosCotacaoEdicao?.tipoCalculo,
        ProdutoId: dadosCotacaoEdicao?.produtoId
      };
    } catch (error) {
      console.log(error);
    }
  };

  // const gerarPlanosSimulados = async (cotacaoId) => {
  //   const resultado = await CotacaoInicialHelper.simularPlanos(cotacaoId);

  //   if (!resultado.sucesso) CotacaoInicialHelper.exibirErro(resultado.mensagem);
  // };
  const enviarFormulario = async (dados) => {
    try {
      if (isEdicao && !edicaoAberta) return;
      if (usuario.empresaSelecionada === "") {
        CotacaoInicialHelper.exibirErro("É necessário selecionar uma Empresa");
        return;
      }

      if (!distribuidoraId) {
        CotacaoInicialHelper.exibirErro(
          "É necessário selecionar uma Distribuidora"
        );
        return;
      }

      if (!descontoSelecionado || descontoSelecionado == null) {
        CotacaoInicialHelper.exibirErro(
          "É necessário selecionar o tipo de calculo do desconto!"
        );
        return;
      }

      const dadosCotacao = formatarDadosCotacao(dados);

      if (!dadosCotacao) return;

      const resultado = await CotacaoInicialHelper.salvarCotacao(
        dadosCotacao,
        isEdicao,
        id
      );

      if (
        resultado.data != null &&
        !resultado.data.validationResult.isValid &&
        resultado.data.validationResult.errors[0].errorMessage ===
          "Já existe uma cotação aberta para este CNPJ"
      ) {
        setCotacaoIdMesmoCNPJ(resultado.data.id);
        setModalCotacaoAbertaMesmoCNPJ(true);
        return;
      }

      if (
        resultado.data != null &&
        !resultado.data.validationResult.isValid &&
        resultado.data.validationResult.errors.length > 0
      ) {
        CotacaoInicialHelper.exibirErro(
          resultado.data.validationResult.errors[0].errorMessage
        );
        return;
      }

      if (!resultado.sucesso) {
        CotacaoInicialHelper.exibirErro(resultado.mensagem);
        return;
      }

      sessionStorage.setItem("cotacao_id", resultado?.data?.id);

      history.push(`/cotacao/planos/${resultado.data.id}`, {
        dadosCotacaoInicial: resultado.data,
        dadosCnpj
      });
    } catch (error) {
      CotacaoInicialHelper.exibirErro(
        "Erro interno, entre em contato com o suporte!"
      );
    }
  };

  const aoEnviarFormulario = (dados) => {
    enviarFormulario(dados);
  };

  const onSalvarClick = (event) => {
    if (!isEdicao || edicaoAberta) return;

    event.preventDefault();

    const dadosProximaTela = {
      id,
      cnpj: dadosCotacaoEdicao?.cnpj.replace(/[^a-zA-Z0-9]/g, ""),
      isEdicao
    };

    history.push(`/cotacao/planos/${id}`, {
      dadosCotacaoInicial: dadosProximaTela,
      dadosCnpj: {
        cnpj: dadosCotacaoEdicao?.cnpj.replace(/[^a-zA-Z0-9]/g, "")
      }
    });
  };

  const onConfirmarTelaEdicao = () => {
    mobile
      ? history.push(`${RotasDTO.CotacaoIncial}//mobile/${cotacaoIdMesmoCNPJ}`)
      : history.push(`${RotasDTO.CotacaoIncial}/${cotacaoIdMesmoCNPJ}`);

    setModalCotacaoAbertaMesmoCNPJ(false);
  };

  const [progress, setProgress] = useState(0);
  const [carregandoArquivo, setCarregandoArquivo] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [msgLoading, setMsgLoading] = useState("");
  const [loading, setLoading] = useState(false);
  const [dataUri, setDataUri] = useState("");

  const onAddUpload = async (valor) => {
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem: "O arquivo está sendo digitalizado Aguarde !"
      })
    );
    setMsgLoading("Efetuando upload da fatura...");
    setProgress(5);
    getProgress("arquivo", 0);
    try {
      setLoading(true);

      const fileBytes = valor[0].data.split(",")[1];
      const extensao = valor[0].data.split(";")[0];
      const fileName = valor[0]?.file?.name;
      let endpoint = "";

      if (extensao === "data:application/pdf") endpoint = "importar-fourvision";
      else if (extensao === "data:image/png" || extensao === "data:image/jpeg")
        endpoint = "importar-earq";

      const externoId = shortid.generate();
      setFaturaExternoId(externoId);

      const retornoDigitalizacao = await digitalizacaoFatura(
        fileBytes,
        fileName,
        externoId,
        colaboradorId,
        empresaSelecionada || "32",
        distribuidoraId,
        endpoint
      );

      const faturaDigitalizada = retornoDigitalizacao;
      delete faturaDigitalizada?.resultObject[0]?.faturaHistoricos;
      delete faturaDigitalizada?.resultObject[0]?.faturaItens;
      delete faturaDigitalizada?.resultObject[0]?.faturaLote;
      store.dispatch(responseFatura(faturaDigitalizada));

      if (retornoDigitalizacao?.statusCodes === 200) {
        retornoFatura(externoId);
        setMsgLoading("Lendo dados da fatura...");
      } else {
        setMsgLoading("Erro ao digitalizar fatura");
        setTimeout(function () {
          setLoading(false);
        }, 3000);
      }
    } catch (error) {
      setMsgLoading("Erro ao efetuar upload!");
      setTimeout(function () {
        setLoading(false);
      }, 3000);
    }
  };

  const onDropRejected = (valor) => {
    const arquivo = valor[0];
    store.dispatch(
      alertaExibir({
        tipo: "warning",
        mensagem: `Tipo do arquivo ${arquivo.name} não é permitido!`
      })
    );
  };

  const retornoFatura = async (externoId) => {
    try {
      const retornoFatura = await obterDadosFaturaDigitalizada(externoId);
      setProgress(50);
      getProgress("arquivo", 50);

      if (JSON.stringify(retornoFatura.fornecedor) === "null") {
        setMsgLoading("Erro ao processar fatura.");
        setTimeout(function () {
          setLoading(false);
        }, 3000);
      } else {
        setMsgLoading("Sucesso!");

        verificarCnpj(retornoFatura?.cliente.cnpj);
        setDadosCnpj(retornoFatura?.cliente.cnpj);
        setNomeFantasia(retornoFatura?.cliente?.nome);
        setConsumoForaPonta(
          (retornoFatura?.consumoForaPonta / 1000).toFixed(3)
        );
        setConsumoPonta((retornoFatura?.consumoPonta / 1000).toFixed(3));
        setdemandaForaPonta(
          Math.max(
            retornoFatura?.demandaForaPonta,
            retornoFatura?.demanda
          ).toFixed(0)
        );
        setDemandaPontaFatura((retornoFatura?.demandaPonta).toFixed(0));

        const distribuidora = await buscarDistribuidoraNomeOuCnpj(
          retornoFatura?.fornecedorCnpj.replace(/[^a-zA-Z0-9]/g, "")
        );
        if (distribuidora?.status === 200) {
          setDistribuidoraId(distribuidora?.data?.id);
        }
      }
    } catch (error) {
      setMsgLoading("Erro ao processar fatura.");
      setTimeout(function () {
        setLoading(false);
      }, 3000);
    }
    setProgress(100);
    getProgress("arquivo", 100);
  };

  const resizedataURL = async (datas, wantedWidth, wantedHeight) => {
    return new Promise(async function (resolve, reject) {
      const img = document.createElement("img");
      img.onload = function () {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = wantedWidth;
        canvas.height = wantedHeight;
        ctx.drawImage(this, 0, 0, wantedWidth, wantedHeight);
        const dataURI = canvas.toDataURL();
        resolve(dataURI);
      };
      img.src = datas;
    });
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileRead = async (event) => {
    const file = event.target.files[0];
    const base64 = await convertBase64(file);
    const newDataUri = await resizedataURL(base64, 1080, 1350);
    setDataUri(newDataUri);
    // setTela(4)
  };

  const fazerUpload = async (arquivo, nomeArquivo) => {
    // try {
    //   const upload = await uploadMedicao(arquivo);

    //   if (upload?.status === 200 || upload?.status === 204) {
    //     store.dispatch(
    //       alertaExibir({
    //         tipo: "success",
    //         mensagem: `O arquivo ${nomeArquivo} foi processado com sucesso!`
    //       })
    //     );
    //   }
    // } catch (error) {
    //   setCarregandoArquivo(false);
    //   store.dispatch(
    //     alertaExibir({
    //       tipo: "warning",
    //       mensagem:
    //         error?.response?.data?.message ??
    //         "Erro interno, entre em contato com o suporte!"
    //     })
    //   );
    // }

    setProgress(100);

    const ocultarBarra = setInterval(() => {
      setShowLoader(false);
      setCarregandoArquivo(false);
      clearInterval(ocultarBarra);
    }, 3000);
  };

  const getProgress = (nomeArquivo, auxProgress) => {
    setCarregandoArquivo(true);
    setShowLoader(true);

    if (auxProgress >= 100) {
      setShowLoader(false);
      setCarregandoArquivo(false);
    }

    const timer = setInterval(() => {
      // const response = getUploadProgress(nomeArquivo);
      // response.then((res) => {
      //   setProgress((prevProgress) => (res === 0 ? prevProgress : res));
      //   auxProgress = res;
      // });
      // if (auxProgress >= 100) {
      //   clearInterval(timer);
      //   setShowLoader(false);
      //   setCarregandoArquivo(false);
      // }
    }, 5000);
  };

  return (
    <Loader loading={carregando}>
      <>
        <ModalConfirmacao
          item={modalCotacaoAbertaMesmoCNPJ}
          onConfirmar={() => onConfirmarTelaEdicao()}
          mensagem="Já existe uma cotação aberta para este CNPJ. Deseja editar?"
          onCancelar={() => {
            setModalCotacaoAbertaMesmoCNPJ(false);
            setCotacaoIdMesmoCNPJ(0);
            setLimparCNPJ(true);
          }}
        />
        <form
          className="needs-validation"
          onSubmit={handleSubmit(aoEnviarFormulario)}
        >
          {domain !== "luz" && (
            <Grid container columnSpacing={2} pb={3}>
              <Grid
                item
                lg={3}
                md={3}
                sm={5}
                xs={6}
                className={classes.container}
              >
                <Loader loading={carregandoOfficer}>
                  <SelectArredondado
                    id="colaboradorId"
                    name="colaboradorId"
                    valueKey="id"
                    valueName="nomeColaborador"
                    dataSource={listaOfficer}
                    label="Officer"
                    value={colaboradorId}
                    onChange={(colaborador) => onChangeOfficer(colaborador)}
                    placeholder="Officer"
                    styleForm={false}
                    allowClear
                    ref={register(
                      { name: "colaboradorId" },
                      {
                        required: !colaboradorId
                          ? "Campo Officer é obrigatório!"
                          : false
                      }
                    )}
                    errors={errors}
                  />
                </Loader>
              </Grid>
              <Grid item lg={3} md={3} sm={5} xs={6}>
                <UploadCustomizado
                  dropAreaText="Clique aqui para anexar a fatura"
                  loader={carregandoArquivo}
                  onAdd={onAddUpload}
                  progressValue={progress}
                  showLoader={showLoader}
                  onDropRejected={onDropRejected}
                  acceptedFiles={["image/*", "application/pdf"]}
                />
              </Grid>
            </Grid>
          )}
          <Card className={`${classes.container} ${classes.imagemFundo}`}>
            <Grid
              container
              p={2}
              columnSpacing={2}
              justifyContent="flex-end"
              className={classes.container}
            >
              <Grid item xs={11} className="font-weight-bold">
                Informe os dados de energia
              </Grid>
              <Grid item xs={1}>
                <BotaoRetornarListagem
                  justify="flex-end"
                  urlListagem={RotasDTO.ListagemCotacoes}
                />
              </Grid>
            </Grid>

            {domain === "luz" && (
              <Grid
                container
                p={2}
                columnSpacing={2}
                justifyContent="flex-start"
                className={classes.container}
              >
                <Grid item lg={3} md={3} sm={5} xs={6}>
                  <Loader loading={carregandoOfficer}>
                    <SelectArredondado
                      id="colaboradorId"
                      name="colaboradorId"
                      valueKey="id"
                      valueName="nomeColaborador"
                      dataSource={listaOfficer}
                      label="Officer"
                      value={colaboradorId}
                      onChange={(colaborador) => onChangeOfficer(colaborador)}
                      placeholder="Officer"
                      styleForm={false}
                      allowClear
                      ref={register(
                        { name: "colaboradorId" },
                        {
                          required: !colaboradorId
                            ? "Campo Officer é obrigatório!"
                            : false
                        }
                      )}
                      errors={errors}
                    />
                  </Loader>
                </Grid>
                <Grid item lg={3} md={3} sm={5} xs={6}>
                  <Box
                    bgcolor="rgba(250, 62, 177, 0.08)"
                    border="2px dashed #FA3EB1"
                    borderRadius="8px"
                  >
                    <UploadCustomizado
                      dropAreaText="Anexar fatura"
                      loader={carregandoArquivo}
                      onAdd={onAddUpload}
                      progressValue={progress}
                      showLoader={showLoader}
                      onDropRejected={onDropRejected}
                      acceptedFiles={["image/*", "application/pdf"]}
                      icon={Upload}
                    />
                  </Box>
                </Grid>
              </Grid>
            )}

            <Grid
              container
              p={2}
              columnSpacing={2}
              className={classes.container}
            >
              <Grid item lg={6} md={9} sm={12} xs={12}>
                <MaterialInputMascara
                  type="text"
                  id="cnpj"
                  name="cnpj"
                  label="CNPJ"
                  mask="##.###.###/####-##"
                  renderIconShowHide={false}
                  disabled={isEdicao}
                  onBlur={(valor) => verificarCnpj(valor)}
                  defaultValue={dadosCotacaoEdicao?.cnpj || dadosCnpj}
                  limparValor={limparCNPJ}
                  redefineLimpar={(estado) => setLimparCNPJ(estado)}
                  ref={register({
                    required: "Campo CNPJ é obrigatório!",
                    maxLength: {
                      value: 18,
                      message: "Quantidade máxima de 18 caracteres!"
                    },
                    minLength: {
                      value: 11,
                      message: "Quantidade mínima de 11 caracteres!"
                    },
                    validate: (value) => cnpj.isValid(value) || "CNPJ inválido!"
                  })}
                  errors={errors}
                />
              </Grid>
            </Grid>
            <Grid
              container
              p={2}
              columnSpacing={2}
              className={classes.container}
            >
              <Grid item lg={6} md={9} sm={12} xs={12}>
                <Loader loading={carregandoNomeFantasia}>
                  <MaterialInputTexto
                    type="text"
                    id="nomeFantasia"
                    name="nomeFantasia"
                    disabled={isEdicao}
                    label="Nome fantasia"
                    renderIconShowHide={false}
                    defaultValue={
                      nomeFantasia || dadosCotacaoEdicao?.nomeFantasia
                    }
                    ref={register({
                      required: "Campo Nome Fantasia é obrigatório!",
                      maxLength: {
                        value: 100,
                        message: "Quantidade máxima de 100 caracteres!"
                      }
                    })}
                    errors={errors}
                  />
                </Loader>
              </Grid>
            </Grid>
            <Grid
              container
              p={2}
              columnSpacing={2}
              className={classes.container}
            >
              <Grid item lg={6} md={9} sm={12} xs={12}>
                <Loader loading={carregandoDistribuidora}>
                  <SelectAutoComplete
                    disableClearable
                    disableOpenOnFocus
                    id="distribuidoraId"
                    name="distribuidoraId"
                    // disabled={isEdicao && !edicaoAberta}
                    options={listaDistribuidora}
                    label="Distribuidora"
                    getOptionLabel={(option) =>
                      option?.nomeFantasia ?? distribuidoraId
                    }
                    onChange={(e, item) => {
                      setDistribuidoraId(item?.id ?? distribuidoraId);
                    }}
                    value={distribuidoraId}
                    ref={register(
                      { name: "distribuidoraId" },
                      {
                        required: !distribuidoraId
                      }
                    )}
                    error={errors}
                    allowClear
                    valueName="nomeFantasia"
                  />
                </Loader>
              </Grid>
            </Grid>
            <Grid
              container
              p={2}
              columnSpacing={2}
              className={classes.container}
            >
              <Grid item lg={6} md={9} sm={12} xs={12}>
                <Box className={classes.showMobile}>
                  <Box mb={3} className="font-weight-bold">
                    Calcular desconto baseado em
                  </Box>
                  {itensDescontoBaseado?.map((x) => {
                    return (
                      <MaterialSwitch
                        labelPlacement="start"
                        checked={x.checked}
                        onChange={() => onChangeDescontoBaseado(x.valor)}
                        ref={register({
                          required: "Campo Desconto Baseado é obrigatório!"
                        })}
                        label={
                          <Box
                            className={
                              x.checked ? classes.checked : classes.unchecked
                            }
                          >
                            {x.label}
                          </Box>
                        }
                        value={x.valor}
                      />
                    );
                  })}
                </Box>

                <Box className={classes.hideMobile}>
                  <InputRadioGroup
                    type="text"
                    id="descontoBaseado"
                    name="descontoBaseado"
                    label="Calcular desconto baseado em:"
                    renderIconShowHide={false}
                    disabled={isEdicao && !edicaoAberta}
                    customValue={descontoBaseado}
                    onChange={(valor) => onChangeDescontoBaseadoRadio(valor)}
                    defaultValue={descontoBaseado}
                    ref={register({
                      required: "Campo Desconto Baseado é obrigatório!"
                    })}
                    errors={errors}
                    className={classes.inputRadio}
                    classNameLabel={classes.inputRadioLabel}
                    itens={itensDescontoBaseado}
                  />
                </Box>
              </Grid>
            </Grid>
            {custoFinanceiroSelecionado ? (
              <Grid container p={2} spacing={3} className={classes.container}>
                <Grid item lg={6} md={9} sm={12} xs={12}>
                  <MaterialInputMascara
                    type="text"
                    id="valorMedio"
                    name="valorMedio"
                    label="Valor médio da conta de energia (R$)"
                    prefixo="R$ "
                    sufixo=""
                    disabled={isEdicao && !edicaoAberta}
                    renderIconShowHide={false}
                    defaultValue={dadosCotacaoEdicao?.valorMedioConta}
                    ref={register({
                      required: "Campo valor médio é obrigatório!",
                      maxLength: {
                        value: 18,
                        message: "Quantidade máxima de 18 caracteres!"
                      },
                      minLength: {
                        value: 1,
                        message: "Quantidade mínima de 1 caracteres!"
                      }
                    })}
                    errors={errors}
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                p={2}
                columnSpacing={2}
                className={classes.container}
              >
                <Grid item lg={3} md={6} sm={6} xs={6}>
                  <MaterialInputMascara
                    type="text"
                    id="consumoForaPonta"
                    name="consumoForaPonta"
                    label="Consumo Fora Ponta (MWh)"
                    sufixo=" MWh"
                    prefixo=""
                    disabled={isEdicao && !edicaoAberta}
                    renderIconShowHide={false}
                    defaultValue={
                      dadosCotacaoEdicao?.consumoForaPonta || consumoForaPonta
                    }
                    ref={register({
                      required: "Campo Consumo Fora Ponta é obrigatório!",
                      maxLength: {
                        value: 18,
                        message: "Quantidade máxima de 18 caracteres!"
                      },
                      minLength: {
                        value: 1,
                        message: "Quantidade mínima de 1 caracteres!"
                      }
                    })}
                    errors={errors}
                  />
                </Grid>
                <Grid item lg={3} md={6} sm={6} xs={6}>
                  <MaterialInputMascara
                    type="text"
                    id="consumoPonta"
                    name="consumoPonta"
                    label="Consumo Ponta (MWh)"
                    sufixo=" MWh"
                    prefixo=""
                    disabled={isEdicao && !edicaoAberta}
                    renderIconShowHide={false}
                    defaultValue={
                      dadosCotacaoEdicao?.consumoPonta || consumoPonta
                    }
                    ref={register({
                      required: "Campo Consumo Ponta é obrigatório!",
                      maxLength: {
                        value: 18,
                        message: "Quantidade máxima de 18 caracteres!"
                      },
                      minLength: {
                        value: 1,
                        message: "Quantidade mínima de 1 caracteres!"
                      }
                    })}
                    errors={errors}
                  />
                </Grid>
              </Grid>
            )}
            <Grid
              container
              p={2}
              columnSpacing={2}
              className={classes.container}
            >
              <Grid item lg={3} md={6} sm={6} xs={6}>
                <MaterialInputMascara
                  type="text"
                  id="demandaContratadaKW"
                  name="demandaContratadaKW"
                  label="Demanda contratatada fora ponta (kW)"
                  sufixo=" kW"
                  defaultValue={
                    dadosCotacaoEdicao?.demandaContratada || demandaForaPonta
                  }
                  renderIconShowHide={false}
                  disabled={isEdicao && !edicaoAberta}
                  onBlur={(valor) =>
                    setDemandaContratada(formataValor(valor?.target?.value))
                  }
                  ref={register({
                    required:
                      "Campo demanda contratatada Fora Ponta (kW) é obrigatório!",
                    maxLength: {
                      value: 18,
                      message: "Quantidade máxima de 18 caracteres!"
                    },
                    minLength: {
                      value: 1,
                      message: "Quantidade mínima de 1 caracteres!"
                    }
                  })}
                  errors={errors}
                />
              </Grid>
              <Grid item lg={3} md={6} sm={6} xs={6}>
                <MaterialInputMascara
                  type="text"
                  id="demandaPonta"
                  name="demandaPonta"
                  label="Demanda contratada ponta (kW)"
                  sufixo=" kW"
                  defaultValue={
                    dadosCotacaoEdicao?.demandaPonta || demandataPonta
                  }
                  renderIconShowHide={false}
                  disabled={isEdicao && !edicaoAberta}
                  onBlur={(valor) =>
                    setDemandaPonta(formataValor(valor?.target?.value))
                  }
                  errors={errors}
                />
              </Grid>
            </Grid>

            <Grid
              container
              p={2}
              columnSpacing={2}
              className={classes.container}
            >
              <Grid item lg={3} md={3} sm={6}>
                <InputData
                  type="text"
                  id="dataMigracao"
                  name="dataMigracao"
                  format="dd/MM/yyyy"
                  label="Data prevista de migração"
                  minDate={window.moment().subtract(90, "year")}
                  customValue={dataMigracao}
                  onBlur={(e) => onChangeDataMigracao(e.target.value)}
                  renderIconShowHide
                  errors={errors}
                  className={classes}
                  InputProps={{ readOnly: false }}
                  variant="standard"
                  autoOk
                />
              </Grid>
            </Grid>

            {aumentarDemanda ? (
              <Grid
                container
                p={2}
                columnSpacing={2}
                className={classes.container}
              >
                <Grid item lg={6} md={9} sm={12} xs={12}>
                  <InputRadioGroup
                    type="text"
                    id="unidadeCnpj"
                    name="unidadeCnpj"
                    label="Deseja aumentar a demanda?"
                    renderIconShowHide={false}
                    disabled={isEdicao && !edicaoAberta}
                    customValue={unidadeCnpj}
                    onChange={(valor) =>
                      onChangeUnidadeCnpj(valor?.target?.value)
                    }
                    defaultValue={unidadeCnpj}
                    errors={errors}
                    className={classes.inputRadio}
                    classNameLabel={classes.inputRadioLabel}
                    itens={listaOpcoesUnidadeCnpj}
                  />
                </Grid>
              </Grid>
            ) : null}

            <Grid container p={2} spacing={2} className={classes.container}>
              <Grid item xs={12} className={classes.showMobile}>
                <MaterialSwitch
                  labelPlacement="start"
                  checked={consumidorLivre}
                  onChange={(e) => onChangeConsumidorLivre(e)}
                  ref={register({
                    required: "Campo Consumidor Livre é obrigatório!"
                  })}
                  label={
                    <Box className={classes.checked}>Consumidor livre</Box>
                  }
                />
              </Grid>

              <Grid item xs={12} className={classes.hideMobile}>
                <InputRadioGroup
                  type="text"
                  id="consumidorLivre"
                  name="consumidorLivre"
                  label="O consumidor é livre?"
                  renderIconShowHide={false}
                  disabled={isEdicao && !edicaoAberta}
                  customValue={consumidorLivre}
                  onChange={(valor) =>
                    onChangeConsumidorLivreRadio(valor?.target?.value)
                  }
                  defaultValue={consumidorLivre}
                  ref={register({
                    required: "Campo Consumidor Livre é obrigatório!"
                  })}
                  errors={errors}
                  className={classes.inputRadio}
                  classNameLabel={classes.inputRadioLabel}
                  itens={listaOpcoesConsumidorLivre}
                />
              </Grid>

              <Grid item xs={12} className={classes.hideMobile}>
                <InputRadioGroup
                  type="text"
                  id="tradicionalVarejista"
                  name="tradicionalVarejista"
                  label="Tipo de mercado"
                  renderIconShowHide={false}
                  disabled={isEdicao && !edicaoAberta}
                  customValue={tradicionalVarejista}
                  onChange={(valor) =>
                    onChangeTradicionalVarejista(valor?.target?.value)
                  }
                  defaultValue={tradicionalVarejista}
                  ref={register({
                    required: "Campo Consumidor Livre é obrigatório!"
                  })}
                  errors={errors}
                  className={classes.inputRadio}
                  classNameLabel={classes.inputRadioLabel}
                  itens={listaTradicionalVarejista}
                />
              </Grid>
            </Grid>
            {domain !== "luz" && (
              <Grid
                container
                p={2}
                columnSpacing={2}
                className={classes.containerAvancar}
              >
                <Grid item lg={4} md={6} sm={11} xs={11}>
                  <Botao
                    type="submit"
                    label="Ver os melhores planos"
                    className={classes.button}
                    onClick={(e) => onSalvarClick(e)}
                  />
                </Grid>
              </Grid>
            )}
          </Card>
          {domain === "luz" && (
            <Box component={Grid} container p={2} columnSpacing={2}>
              <Grid item xs={12} lg={3} mt={2}>
                <Botao
                  type="submit"
                  label="Ver os melhores planos"
                  className={classes.button}
                  onClick={(e) => onSalvarClick(e)}
                />
              </Grid>
            </Box>
          )}
        </form>
      </>
    </Loader>
  );
};

export default CotacaoInicialCadastro;
