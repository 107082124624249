import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  useMemo
} from "react";
import { useForm, useWatch } from "react-hook-form";
import { useHistory } from "react-router-dom";

// Componentes
import TuneIcon from "@mui/icons-material/Tune";
import { Grid } from "@mui/material";
import { ModalConfirmacao } from "componentes";
import { RotasDTO } from "global/rotas/rotasUrlDto";
import { useSelector } from "react-redux";
import theme from "themes";
import MaterialInputBusca from "componentes/inputBusca";
import Botao from "../../../../componentes/botao";
import TabelaPaginada from "../../../../componentes/tabelaPaginada";
import Coluna from "../../../../componentes/tabelaPaginada/colunas/coluna";
import BotaoFiltroOrdenacao from "../../../../componentes/botaoFiltroOrdenar";
import BoletasHelper from "./helper";
import ColunaComponentePersonalizado from "../../../../componentes/tabelaPaginada/colunas/colunaComponentePersonalizado";
import ColunaMultiplosBotoes from "./colunaMultiplosBotoes";

// DTO
import FiltroAdicionalTabela from "./dto/filtroAdicionalTabela";
import ListagemBoletasDto from "./dto/listagemBoletasDto";
import FiltroDto from "./dto/filtroDto";
import ResultadoPaginadoDto from "../../../../componentes/tabelaPaginada/resultadoPaginadoDto";
import enumeradorBotao from "./enumeradores/enumeradorBotao";

// Redux
import { store } from "../../../../global/redux";
import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";

// Styles
import { useStyles } from "./style";

// Serviços
import {
  listarOrganizarPor,
  filtroProdutoPor,
  filtroTipoEnergiaPor,
  filtroSubmercadoPor,
  filtroStatusPor,
  mudarStatus
} from "../../../../servicos/boletasServico";

// Icone

import {
  selecionarCliente,
  selecionarEmpresa
} from "../../../../global/redux/modulos/usuario/actions";

const ListagemBoletas = () => {
  const history = useHistory();
  const [ordemSelecionada, setOrdemSelecionada] = useState("");
  const [ordemColunaSelecionada, setOrdemColunaSelecionada] = useState();
  const [filtroSituacaoSelecionado] = useState("");
  const [filtroAdicionalTabela, setFiltroAdicionalTabela] = useState(
    new FiltroAdicionalTabela()
  );
  const [filtroProdutoSelecionado, setFiltroProdutoSelecionado] = useState("");
  const [
    filtroTipoDeEnergiaSelecionado,
    setFiltroTipoDeEnergiaSelecionado
  ] = useState("");
  const [
    filtroSubmercadoSelecionado,
    setFiltroSubmercadoSelecionado
  ] = useState("");
  const [filtroStatusSelecionado, setFiltroStatusSelecionado] = useState("");
  const [valorOrdenacao, setValorOrdenacao] = useState();

  const [carregamentoInicial, setCarregamentoInicial] = useState(false);

  const classes = useStyles();
  const refTabela = useRef(null);
  const montouComponente = useRef(false);

  const global = useSelector((state) => state.usuario);
  const { empresaSelecionada, clienteSelecionado, unidadeSelecionada } = global;

  const { register, control } = useForm({
    reValidateMode: "onSubmit"
  });

  const textoBusca = useWatch({
    control,
    name: "textoBusca",
    defaultValue: ""
  });

  useEffect(() => {
    setCarregamentoInicial(true);
  }, []);

  useEffect(() => {
    const { ClientId, EmpresaGrupoDeltaId } =
      history?.location?.state?.dadosBoleta || {};

    store.dispatch(selecionarEmpresa(EmpresaGrupoDeltaId));
    store.dispatch(selecionarCliente(ClientId));
  }, [history]);

  const onClickEditar = (id) => {
    history.push(`${RotasDTO.Boletas}/cadastro/${id}`);
  };

  const [modalExclusao, setModaoExclusao] = useState(false);

  const onClickExcluir = (item) => {
    setModaoExclusao(item);
  };

  const onClickMudarStatus = useCallback(async (id, status) => {
    const checarValorStatus = status ? 1 : 3;
    try {
      const mudouStatus = await mudarStatus(id, checarValorStatus);
      if (mudouStatus) {
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: `A Boleta foi ${
              status === false ? "inativado" : "ativado"
            } com sucesso!`
          })
        );
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    } finally {
      setModaoExclusao(false);
    }
    if (refTabela?.current) refTabela.current.obterDadosPaginados();
  }, []);

  const onClickBotao = (tipo, parametros) => {
    switch (tipo) {
      case enumeradorBotao.Edicao:
        onClickEditar(parametros.id);
        break;
      case enumeradorBotao.Status:
        onClickExcluir(parametros);
        break;
      default:
        break;
    }
  };

  const colunas = [
    new Coluna("boleta", "Boleta", true, "8%", "8%"),
    new Coluna("cliente", "Cliente", true, "16%", "16%"),
    new Coluna("qtdaUnidades", "N° de Unidades", true, "10%", "10%"),
    new Coluna("produto", "Produto", true, "15%", "15%"),
    new Coluna("tipoEnergia", "Tipo de energia", true, "13%", "13%"),
    new Coluna("submercado", "Submercado", true, "13%", "13%"),
    new Coluna("status", "Status", true, "10%", "10%"),
    new ColunaComponentePersonalizado(
      "opcoes",
      "Editar",
      ColunaMultiplosBotoes,
      onClickBotao,
      false,
      true,
      "11.8%",
      "11.8%",
      "pl-2"
    )
  ];

  const obterGrupos = async (parametros) => {
    const filtro = new FiltroDto(
      parametros?.tamanhoPagina,
      parametros?.pagina,
      parametros?.pesquisar || textoPesquisa,
      parametros?.filtroAdicional?.ordenacao ?? "",
      parametros?.filtroAdicional?.situacao ?? "",
      parametros?.filtroAdicional?.produto ?? "",
      parametros?.filtroAdicional?.tipoEnergia ?? "",
      parametros?.filtroAdicional?.submercado ?? "",
      parametros?.filtroAdicional?.status ?? "",
      parametros?.filtroAdicional?.unidadeSelecionada ?? "",
      parametros?.filtroAdicional?.clienteSelecionado ?? "",
      parametros?.filtroAdicional?.empresaSelecionada ?? ""
    );

    const resultado = await BoletasHelper.listarBoletas(filtro, history);

    if (!resultado.sucesso) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: resultado.mensagem
        })
      );
      return new ResultadoPaginadoDto([], 1, 0, 0);
    }

    if (resultado?.data.length === 0 ?? true)
      return new ResultadoPaginadoDto([], 1, 0, 0);

    const resLinhas = resultado?.data?.boletas?.map((res) => {
      return new ListagemBoletasDto(
        res.id,
        res.id,
        res.clienteNome,
        res.numeroUnidades,
        res.produto,
        res.tipoEnergia,
        res.submercado,
        res.status,
        res.status === "Lançada" || res.status === "Aprovada"
      );
    });

    return new ResultadoPaginadoDto(
      resLinhas,
      resultado?.data?.paginaAtual,
      resultado?.data?.totalItens,
      resultado?.data?.totalPaginas
    );
  };

  const onChangeFiltrosTabela = async (parametros) => {
    const { ClientId, EmpresaGrupoDeltaId } =
      history?.location?.state?.dadosBoleta || {};

    return obterGrupos({
      tamanhoPagina: parametros?.totalPagina,
      pagina: parametros?.pagina,
      pesquisar: parametros?.pesquisar,
      filtroAdicional: new FiltroAdicionalTabela(
        parametros?.filtrosAdicionais?.ordenacao,
        parametros?.filtrosAdicionais?.situacao,
        parametros?.filtrosAdicionais?.produto,
        parametros?.filtrosAdicionais?.tipoEnergia,
        parametros?.filtrosAdicionais?.submercado,
        parametros?.filtrosAdicionais?.status,
        parametros?.filtrosAdicionais?.unidadeSelecionada,
        parametros?.filtrosAdicionais?.clienteSelecionado || ClientId,
        parametros?.filtrosAdicionais?.empresaSelecionada || EmpresaGrupoDeltaId
      )
    });
  };

  const handleClickAtivarFiltro = () => {
    setFiltroAdicionalTabela(
      new FiltroAdicionalTabela(
        valorOrdenacao,
        filtroSituacaoSelecionado,
        filtroProdutoSelecionado,
        filtroTipoDeEnergiaSelecionado,
        filtroSubmercadoSelecionado,
        filtroStatusSelecionado,
        unidadeSelecionada,
        clienteSelecionado,
        empresaSelecionada
      )
    );
  };

  const handleAlterarFiltroLateral = () => {
    setFiltroAdicionalTabela(
      new FiltroAdicionalTabela(
        valorOrdenacao,
        filtroSituacaoSelecionado,
        filtroProdutoSelecionado,
        filtroTipoDeEnergiaSelecionado,
        filtroSubmercadoSelecionado,
        filtroStatusSelecionado,
        unidadeSelecionada,
        clienteSelecionado,
        empresaSelecionada
      )
    );
  };

  useEffect(() => {
    if (montouComponente.current) {
      setCarregamentoInicial(true);
      if (carregamentoInicial) {
        handleAlterarFiltroLateral();
      }
    } else montouComponente.current = true;
  }, [empresaSelecionada, clienteSelecionado, unidadeSelecionada]);

  const handleClickOrdemSelecionada = (event) => {
    const val = ordemSelecionada === event ? "" : event;
    setOrdemSelecionada(val);
  };

  const handleClickFiltroProdutoSelecionado = (event) => {
    const val = filtroProdutoSelecionado === event ? "" : event;
    setFiltroProdutoSelecionado(val);
  };

  const handleClickFiltroTipoDeEnergiaSelecionado = (event) => {
    const val = filtroTipoDeEnergiaSelecionado === event ? "" : event;
    setFiltroTipoDeEnergiaSelecionado(val);
  };

  const handleClickFiltroSubmercadoSelecionado = (event) => {
    const val = filtroSubmercadoSelecionado === event ? "" : event;
    setFiltroSubmercadoSelecionado(val);
  };

  const handleClickFiltroStatusSelecionado = (event) => {
    const val = filtroStatusSelecionado === event ? "" : event;
    setFiltroStatusSelecionado(val);
  };

  const handleClickSortOrderSelecionado = (valor, idCampoSelecionado) => {
    const val =
      ordemColunaSelecionada === idCampoSelecionado && valor === ""
        ? ""
        : idCampoSelecionado;
    setOrdemColunaSelecionada(val);
    setValorOrdenacao(valor);
  };

  const textoPesquisa = useMemo(() => {
    return textoBusca?.length >= 3 ? textoBusca?.trim() : "";
  }, [textoBusca]);

  useEffect(() => {
    if (textoPesquisa === "" && carregamentoInicial) {
      const debounce = setTimeout(() => {
        setFiltroAdicionalTabela(
          new FiltroAdicionalTabela(
            valorOrdenacao,
            filtroSituacaoSelecionado,
            filtroProdutoSelecionado,
            filtroTipoDeEnergiaSelecionado,
            filtroSubmercadoSelecionado,
            filtroStatusSelecionado,
            unidadeSelecionada,
            clienteSelecionado,
            empresaSelecionada,
            textoPesquisa
          )
        );
      }, 1000);

      return () => clearTimeout(debounce);
    }
  }, [textoPesquisa]);

  const onClickSearch = () => {
    setFiltroAdicionalTabela(
      new FiltroAdicionalTabela(
        valorOrdenacao,
        filtroSituacaoSelecionado,
        filtroProdutoSelecionado,
        filtroTipoDeEnergiaSelecionado,
        filtroSubmercadoSelecionado,
        filtroStatusSelecionado,
        unidadeSelecionada,
        clienteSelecionado,
        empresaSelecionada,
        textoPesquisa
      )
    );
  };

  return (
    <Grid container>
      <ModalConfirmacao
        item={modalExclusao}
        onConfirmar={(item) => onClickMudarStatus(item?.id, !item?.ativo)}
        mensagem={`Tem certeza que deseja ${
          modalExclusao?.ativo ? "inativar" : "ativar"
        } essa Boleta?`}
        onCancelar={() => setModaoExclusao(false)}
      />

      <Grid
        container
        item
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
      >
        <Grid item xs={8} sm={5}>
          <MaterialInputBusca
            type="text"
            id="textoBusca"
            name="textoBusca"
            label="Buscar"
            renderIconShowHide
            searchAdornment
            permiteValorBranco
            defaultValue={textoBusca ?? ""}
            ref={register}
          />
        </Grid>
        <Grid item xs={4} sm={2}>
          <Botao
            type="submit"
            onClick={onClickSearch}
            label="Buscar"
            className={classes.button}
            disabled={!textoPesquisa}
          />
        </Grid>
        <Grid sm={2} />
        <Grid
          item
          xs={12}
          sm={3}
          display="flex"
          justifyContent={{ sm: "flex-end" }}
        >
          <Grid item xs={6}>
            <BotaoFiltroOrdenacao
              type="button"
              color={theme.color.secondaryBorderColor}
              background="transparent"
              label="Filtrar / Ordernar"
              icon={<TuneIcon />}
              className={classes.buttonFiltro}
              ordenadorUm="Ordenador"
              ordenacaoColuna={listarOrganizarPor()}
              ordenadorTres="Produto"
              filtroProduto={filtroProdutoPor()}
              ordenadorQuatro="Tipo de Energia"
              filtroTipoEnergia={filtroTipoEnergiaPor()}
              ordenadorCinco="Submercado"
              filtroSubmercado={filtroSubmercadoPor()}
              ordenadorSeis="Status"
              filtroStatus={filtroStatusPor()}
              ordemSelecionada={ordemSelecionada}
              ordemColunaSelecionada={ordemColunaSelecionada}
              filtroProdutoSelecionados={filtroProdutoSelecionado}
              filtroTipoEnergiaSelecionados={filtroTipoDeEnergiaSelecionado}
              filtroSubmercadoSelecionados={filtroSubmercadoSelecionado}
              filtroStatusSelecionados={filtroStatusSelecionado}
              onClickOrdenacao={handleClickOrdemSelecionada}
              onClickFiltroProduto={handleClickFiltroProdutoSelecionado}
              onClickFiltroTipoDeEnergia={
                handleClickFiltroTipoDeEnergiaSelecionado
              }
              onClickFiltroSubmercado={handleClickFiltroSubmercadoSelecionado}
              onClickFiltroStatus={handleClickFiltroStatusSelecionado}
              onClickSortOrder={handleClickSortOrderSelecionado}
              onClickAtivarAgora={handleClickAtivarFiltro}
              larguraBotao={3}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} className="mt-4">
        <TabelaPaginada
          ref={refTabela}
          onChangeFiltrosTabela={onChangeFiltrosTabela}
          colunas={colunas}
          pesquisar=""
          filtrosAdicionais={filtroAdicionalTabela}
          paginaAtual={1}
          itemsPorPagina={20}
          noHeader
        />
      </Grid>
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={3}>
          <Botao
            type="button"
            label="Nova Boleta"
            className={classes.button}
            onClick={() => {
              history.push(`${RotasDTO.Boletas}/cadastro`);
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ListagemBoletas;
