import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { Grid, Box, Chip } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { Botao } from "componentes";
import BotaoOutLine from "componentes/botaoOutline";

import { useStyles } from "./style";

const FiltroUnidades = ({
  onFiltrar,
  textoNaoEncontrado,
  onCancelar,
  dadosFiltrados,
  inputType,
  carregando
}) => {
  const classes = useStyles();
  const [unidades, setUnidades] = useState(dadosFiltrados);
  const [texto, setTexto] = useState("");
  const inputRef = useRef(null);

  const salvar = () => {
    onFiltrar(unidades);
  };

  const alterarFiltro = (valor) => {
    const valorFormatado =
      inputType === "number"
        ? valor?.target?.value?.replace(/[^0-9^, ]/gi, "")
        : valor?.target?.value;

    setTexto(valorFormatado);
    if (
      valorFormatado?.includes(" ") ||
      valorFormatado?.includes(",") ||
      (valorFormatado && valor.key === "Enter")
    ) {
      const valorsSeparados = valorFormatado?.split(/[\s, ]+/);
      const valoresSemEspaço = valorsSeparados.filter((v) => v !== "");
      setUnidades([...unidades, ...valoresSemEspaço]);
      setTexto("");
    }
  };

  const deletar = (index) => {
    const novasUnidades = unidades.filter((u, i) => i !== index);
    setUnidades(novasUnidades);
  };

  const limpar = () => {
    setUnidades([]);
    setTexto("");
  };

  return (
    <>
      <Grid container spacing={1}>
        {textoNaoEncontrado ? (
          <Grid item xs={12} className={classes.naoEncontrado}>
            {textoNaoEncontrado}
          </Grid>
        ) : (
          <>
            <Grid item xs={12} className="font-weight-bold">
              <label htmlFor="motivos" className={classes.label}>
                Inserir UC
              </label>
              <Box
                id="filtroUnidades"
                name="filtroUnidades"
                className={classes.inputTextArea}
                maxLength={200}
                onClick={() => inputRef?.current?.focus()}
              >
                {unidades.map((unidade, index) => (
                  <Chip
                    key={index}
                    label={unidade}
                    className={classes.chip}
                    deleteIcon={<ClearIcon />}
                    onDelete={() => deletar(index)}
                  />
                ))}

                <input
                  type="text"
                  value={texto}
                  onChange={(evento) => alterarFiltro(evento)}
                  onKeyDown={(evento) => alterarFiltro(evento)}
                  autoComplete="off"
                  ref={inputRef}
                />
              </Box>
            </Grid>
            <Grid item xs={12} className={classes.contador}>
              <span>{unidades.length} UC</span>
            </Grid>
          </>
        )}
        <Grid item xs={6}>
          <BotaoOutLine
            onClick={textoNaoEncontrado ? onCancelar : limpar}
            label={textoNaoEncontrado ? "Ok" : "Limpar campo"}
            className={classes.botaoTransparente}
            disabled={Boolean(!unidades?.length) || carregando}
          />
        </Grid>
        <Grid item xs={6}>
          <Botao
            onClick={() => salvar()}
            label={textoNaoEncontrado ? "Tentar novamente" : "Filtrar"}
            className={classes.botao}
            disabled={Boolean(!unidades?.length) || carregando}
          />
        </Grid>
      </Grid>
    </>
  );
};

FiltroUnidades.propTypes = {
  onFiltrar: PropTypes.func,
  textoNaoEncontrado: PropTypes.node,
  onCancelar: PropTypes.func,
  dadosFiltrados: PropTypes.arrayOf(PropTypes.string),
  inputType: PropTypes.string,
  carregando: PropTypes.bool
};

FiltroUnidades.defaultProps = {
  onFiltrar: () => {},
  textoNaoEncontrado: null,
  onCancelar: () => {},
  dadosFiltrados: [],
  inputType: "text",
  carregando: false
};

export default FiltroUnidades;
