/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-else-return */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from "react";
import {
  Grid,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Tooltip,
  Select,
  MenuItem,
  Typography,
  Box
} from "@mui/material";
import BotaoRetornarListagem from "componentes/botaoRetornarListagem";
import { Botao, ModalConfirmacao } from "componentes";
import { Base } from "componentes/cores";
import Loader from "componentes/loader";
import DataTable from "react-data-table-component";
import Coluna from "componentes/tabelaPaginada/colunas/coluna";
import MaterialInputTexto from "componentes/inputTexto/materialInput";
import SaveIcon from "@mui/icons-material/Save";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import ColunaComponentePersonalizado from "componentes/tabelaPaginada/colunas/colunaComponentePersonalizado";
import moment from "moment";
import { useParams } from "react-router-dom";
import Switch from "componentes/switch";
import { useSelector } from "react-redux";
import { useStyles, customStyles } from "./style";
import {
  changeStatusProjecao,
  getDetalhesById,
  getExcelResultadoProjecao,
  atualizarTituloProjecao,
  reprocessaProjecao,
  listarStatus
} from "../../../servicos/projecaoTarifaria";
import ColunaStatus from "./colunasPersonalizadas/ColunaStatus";
import ColunaDetalhes from "./colunasPersonalizadas/ColunaDetalhes";
import ColunaTempo from "./colunasPersonalizadas/ColunaTempo";
import ModalInformacao from "componentes/modalVerificacao";

const ProjecaoTarifariaDetalhes = (props) => {
  const classes = useStyles();
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isReprojecting, setIsReprojecting] = useState(false);
  const [etapas, setEtapas] = useState([]);
  const [listaDetalhes, setListaDetalhes] = useState([]);
  const [idProjecaoTarifaria, setProjecaoTarifaria] = useState(0);
  const [nomeArquivo, setNomeArquivo] = useState("");
  const [titulo, setTitulo] = useState("");
  const [grupo, setGrupo] = useState("");
  const [saveTitulo, setSaveTitulo] = useState(false);
  const [showModalGerarExcel, setShowModalGerarExcel] = useState(false);
  const [showModalReprojetar, setShowModalReprojetar] = useState(false);
  const [opcaoReprocesso, setOpcaoReprocesso] = useState(1);
  const [isDefinitive, setIsDefinitive] = useState(null);
  const [status, setStatus] = useState([]);
  const [modalInfo, setModalInfo] = useState(false);
  const [msgFalha, setMsgFalha] = useState();
  const [msgAguarde, setMsgAguarde] = useState(
    <Typography
      style={{ color: Base.OffWhite, paddingTop: "100px", paddingLeft: "42%" }}
    >
      Aguarde a carga dos dados...
    </Typography>
  );

  const usuarioGlobal = useSelector((state) => state.usuario);

  const colunasHeader = [
    new Coluna("periodoProjecao", "Projeção dos Anos", false, "180px", "180px"),
    {
      ...new Coluna("criacao", "Criação", false, "180px", "180px"),
      wrap: true
    },
    {
      ...new Coluna("atualizacao", "Atualização", false, "180px", "180px"),
      wrap: true
    },
    new ColunaComponentePersonalizado(
      "TempoExecucao",
      "Tempo de Execução",
      ColunaTempo,
      null,
      false,
      false,
      "200px"
    ),
    new Coluna("status", "Status", false, "100px", "100px"),
    { ...new Coluna("cenario", "Cenário", false, "350px"), wrap: true }
  ];

  const colunasEtapas = [
    new Coluna("Titulo", "Etapa", false),
    new ColunaComponentePersonalizado(
      "Status",
      "Status",
      ColunaStatus,
      null,
      false,
      false,
      "250px",
      "250px"
    ),
    new ColunaComponentePersonalizado(
      "TempoExecucao",
      "Tempo de Execução",
      ColunaTempo,
      null,
      false,
      false,
      "250px",
      "250px"
    ),
    new ColunaComponentePersonalizado(
      "detalhes",
      "Detalhes",
      ColunaDetalhes,
      null,
      false,
      false,
      "120px",
      "120px"
    )
  ];

  const [loadingStatus, setLoadingStatus] = useState(false);

  const listarDetalhes = async (id) => {
    try {
      setProjecaoTarifaria(id);

      await getDetalhesById(id).then((response) => {
        if (response.status === 200 && response.data) {
          response.data.executadaEm = moment(response.data.executadaEm).format(
            "lll"
          );

          setIsDefinitive(response.data.statusProjecao);

          setTimeout(() => {
            if (response?.data?.statusProjecao === "PROCESSANDO") {
              setLoadingStatus(true);
              listarDetalhes(id);
            } else {
              setLoadingStatus(false);
            }
          }, 3000);

          const detalheOriginal = response.data;

          const detalheEdited = {
            ...detalheOriginal,
            criacao: `${detalheOriginal.criadaPor} - ${moment(
              new Date(detalheOriginal.criadaEm)
            ).format("DD/MM/YYYY HH:mm")}`,
            atualizacao: `${detalheOriginal.atualizadaPor} - ${moment(
              new Date(detalheOriginal.atualizadaEm)
            ).format("DD/MM/YYYY HH:mm")}`
          };

          setListaDetalhes(detalheEdited);

          setEtapas(response.data.itens);
          setNomeArquivo(
            response.data.projecao !== undefined
              ? `Projeção ${response.data.projecao}.xlsx`
              : ""
          );
          setTitulo(response.data.projecao);
          setGrupo(response.data.tituloGrupo);
        }
      });
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Erro ao buscar detalhes da projeção!"
        })
      );
      return false;
    }
  };

  const handleExportExcel = async () => {
    setIsDownloading(true);
    await getExcelResultadoProjecao(idProjecaoTarifaria, nomeArquivo);
    setIsDownloading(false);
    setShowModalGerarExcel(false);
  };

  const handleTituloOnChange = async (event) => {
    if (idProjecaoTarifaria !== 0) {
      setSaveTitulo(true);
      setTitulo(event.target.value);
    } else {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Nenhuma projeção selecionada."
        })
      );
    }
  };

  const handleSaveTitulo = async () => {
    setIsLoading(true);
    await atualizarTituloProjecao(idProjecaoTarifaria, titulo).then(
      (response) => {
        if (response.status === 200 && response.data) {
          store.dispatch(
            alertaExibir({
              tipo: "success",
              mensagem: "Título atualizado com sucesso."
            })
          );
        } else {
          store.dispatch(
            alertaExibir({
              tipo: "warning",
              mensagem: "Erro na gravação do título."
            })
          );
        }
      }
    );

    setSaveTitulo(false);
    setIsLoading(false);
    setMsgAguarde();
  };

  const handleExecutaReprojecao = async () => {
    const reprocessarapartirdafalha = opcaoReprocesso == 2;
    setIsReprojecting(true);

    await reprocessaProjecao(
      idProjecaoTarifaria,
      usuarioGlobal.usuario.id,
      usuarioGlobal.usuario.nome,
      reprocessarapartirdafalha
    ).then((response) => {
      if (response.status === 200 && response.data) {
        listarDetalhes(id);
      } else {
        if (Array.isArray(response.data)) {
          const etapacomfalha = response.data.filter((o, i) => {
            return Object.keys(o).some(
              (k) =>
                o[k] != "SUCESSO" && k != "Gravação Histórico Etapas" && i > 0
            );
          });
          const msgetapacomfalha = Object.values(etapacomfalha[0])[0];
          setMsgFalha(msgetapacomfalha);
        } else {
          setMsgFalha(response.data);
        }
        setModalInfo(true);
      }
    });
    setIsReprojecting(false);
    setShowModalReprojetar(false);
  };

  const handleChangeOpcaoReprocesso = (event) => {
    setOpcaoReprocesso(event.target.value);
  };

  const handleChangeStatusProjecao = async () => {
    const newStatus = isDefinitive === "RASCUNHO" ? "CONCLUIDA" : "RASCUNHO";
    const response = await changeStatusProjecao(
      id,
      usuarioGlobal.usuario.id,
      usuarioGlobal.usuario.nome,
      newStatus
    );

    if (response.status !== 200) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Erro interno, entre em contato com o suporte!"
        })
      );
      return;
    }

    setIsDefinitive(newStatus);
  };

  const obterStatus = async () => {
    const response = await listarStatus();

    if (response.status !== 200) {
      store.dispatch(
        alertaExibir({
          type: "error",
          message: "Não foi possível resgatar status da projeção"
        })
      );
      return;
    }

    setStatus(response.data);
  };

  useEffect(() => {
    setIsLoading(true);
    if (!listaDetalhes.length) listarDetalhes(id);
    if (!status.length) obterStatus();
    setIsLoading(false);
    setMsgAguarde();
  }, []);

  const handleStatus = (codigo) => {
    return status?.find((item) => item?.codigo === codigo)?.titulo;
  };

  return <>
    <ModalInformacao
      titulo="Reprojeção não realizada com a seguinte falha:"
      exibir={modalInfo}
      mensagem={msgFalha}
      onClose={() => {
        setModalInfo(false);
      }}
      showBotao={false}
    />
    <ModalConfirmacao
      item={showModalGerarExcel}
      onCancelar={() => setShowModalGerarExcel(false)}
      onConfirmar={handleExportExcel}
      mensagem={
        <div>
          <Loader loading={isDownloading} className="w-auto">
            <strong>
              <p>
                Gerar planilha excel com o resultado da projeção e fazer
                download?
              </p>
            </strong>
          </Loader>
          <FormControl
            variant="outlined"
            className={classes.formControlWhiteBackgrounc}
          >
            <InputLabel htmlFor="outlined-nome-arquivo-excel">
              Nome do Arquivo a Gerar
            </InputLabel>
            <Tooltip
              placement="top"
              title={
                <h6>Nome sugerido. Aceite ou edite antes de confirmar.</h6>
              }
            >
              <OutlinedInput
                id="outlined-nome-arquivo-excel"
                type="text"
                value={nomeArquivo}
                onChange={(e) => setNomeArquivo(e.target.value)}
                label="Nome do Arquivo a Gerar"
              />
            </Tooltip>
          </FormControl>
        </div>
      }
    />

    <ModalConfirmacao
      item={showModalReprojetar}
      onCancelar={() => setShowModalReprojetar(false)}
      onConfirmar={handleExecutaReprojecao}
      mensagem={
        <div>
          <Loader loading={isReprojecting} className="w-auto">
            <Box
              fontSize="16px"
              fontWeight="700"
              color="#191C22 !important"
              mb={2}
            >
              Reprocessar a projeção selecionada?
            </Box>
          </Loader>

          <FormControl
            variant="outlined"
            className={classes.formControlSimple}
          >
            <InputLabel id="vigencias">
              Abrangência do reprocessamento
            </InputLabel>
            <Select
              labelId
              id="opcaoReprocesso"
              value={opcaoReprocesso ?? ""}
              onChange={handleChangeOpcaoReprocesso}
              label="Abrangência do reprocessamento"
              fullWidth>
              <MenuItem value="1">Projetar do início</MenuItem>
              <MenuItem value="2">
                Projetar a partir da última etapa com falha
              </MenuItem>
            </Select>
          </FormControl>
        </div>
      }
    />

    <Grid
      container
      spacing={3}
      style={{
        paddingLeft: "1.5em",
        paddingRight: "1.5em",
        paddingBottom: "0.9em"
      }}
    >
      <Grid item xs={6} className={classes.inputCustomContainer}>
        {grupo !== null ? (
          <FormControl variant="outlined" className={classes.formControl}>
            <MaterialInputTexto
              fullWidth
              type="text"
              id="grupo"
              name="grupo"
              label="Grupo"
              style={{ opacity: "0.6" }}
              renderIconShowHide={false}
              defaultValue={grupo}
              disabled
            />
          </FormControl>
        ) : (
          ""
        )}
      </Grid>

      <Grid item xs={6} className={classes.backButton}>
        <BotaoRetornarListagem voltarPagina />
      </Grid>
    </Grid>
    <Grid container style={{ paddingLeft: "1.5em", paddingRight: "1.5em" }}>
      <Grid container py={2} spacing={3} alignItems="center">
        <Grid item xs={3}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="outlined-adornment-password">
              Título
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type="text"
              value={titulo}
              onChange={handleTituloOnChange}
              endAdornment={
                <InputAdornment position="end">
                  <div
                    className={
                      saveTitulo ? classes.saveEnabled : classes.saveDisabled
                    }
                    style={{
                      maxWidth: "54px",
                      maxHeight: "54px"
                    }}
                    onClick={handleSaveTitulo}
                    role="button"
                  >
                    <Tooltip
                      placement="top"
                      title={<h5>Salvar edição do título</h5>}
                    >
                      <SaveIcon />
                    </Tooltip>
                  </div>
                </InputAdornment>
              }
              label="Título"
            />
          </FormControl>
        </Grid>
        <Grid item xs={5} className={classes.inputCustomContainer}>
          <FormControl variant="outlined" className={classes.formControl}>
            <MaterialInputTexto
              fullWidth
              type="text"
              id="idDistribuidora"
              name="guia"
              label="Distribuidora"
              style={{ opacity: "0.6" }}
              renderIconShowHide={false}
              defaultValue={listaDetalhes.distribuidora}
              disabled
            />
          </FormControl>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center"
            }}
          >
            {status && (
              <p
                style={{
                  color: "white",
                  marginBottom: 0,
                  marginRight: 5
                }}
              >
                {handleStatus("RASCUNHO")}
              </p>
            )}
            <Switch
              label=""
              checked={isDefinitive === "CONCLUIDA"}
              onChange={() => {
                handleChangeStatusProjecao();
              }}
            />
            {status && (
              <p
                style={{
                  color: "white",
                  marginBottom: 0,
                  marginLeft: 5
                }}
              >
                {handleStatus("CONCLUIDA")}
              </p>
            )}
          </div>
        </Grid>
        <Grid xs={2} className={classes.buttonContainer}>
          <Botao
            type="submit"
            label="Gerar Excel"
            color={Base.Mango}
            className={classes.button}
            onClick={() => {
              setShowModalGerarExcel(true);
            }}
          />
        </Grid>
        <Grid xs={2} className={classes.buttonContainer}>
          <Botao
            type="submit"
            label="Reexecutar Projeção"
            color={Base.Mango}
            className={classes.button}
            onClick={() => {
              setShowModalReprojetar(true);
              setLoadingStatus(true);
            }}
            disabled={isDefinitive === "CONCLUIDA"}
          />
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        spacing={3}
        justifyContent="center"
        className="w-100"
      >
        <Loader loading={isLoading} className="w-100">
          {msgAguarde}
        </Loader>
      </Grid>
      {listaDetalhes != [] ? (
        <Grid container py={4} spacing={4} alignItems="center" className="w-100">
          <Grid item xs={12}>
            <DataTable
              subHeaderAlign="right"
              columns={colunasHeader}
              data={[listaDetalhes]}
              theme="DeltaEnergia"
              customStyles={customStyles}
              noHeader
              noDataComponent="Nenhum registro encontrado"
            />
          </Grid>
        </Grid>
      ) : (
        ""
      )}

      {etapas.length > 0 ? (
        <Loader loading={loadingStatus} className="text-white">
          <Grid container py={2} spacing={4} alignItems="center">
            <Grid item xs={12}>
              <DataTable
                subHeaderAlign="right"
                columns={colunasEtapas}
                data={etapas}
                theme="DeltaEnergia"
                customStyles={customStyles}
                noHeader
                noDataComponent="Nenhum registro de etapa encontrado para esta projeção"
                progress={loadingStatus}
              />
            </Grid>
          </Grid>
        </Loader>
      ) : (
        ""
      )}
    </Grid>
  </>;
};

export default ProjecaoTarifariaDetalhes;
