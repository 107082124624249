export default class ListagemBoletasDto {
  constructor(
    id,
    boleta,
    cliente,
    qtdaUnidades,
    produto,
    tipoEnergia,
    submercado,
    status,
    ativo
  ) {
    this.id = id;
    this.boleta = boleta;
    this.cliente = cliente;
    this.qtdaUnidades = qtdaUnidades;
    this.produto = produto;
    this.tipoEnergia = tipoEnergia;
    this.submercado = submercado;
    this.status = status;
    this.ativo = ativo;
  }
}
