import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const api = () => {
  return obterApi(MICROSERVICO.MANAGEMENT);
};

// Listar Colaboradores
export const listarColaboradores = async (colaboradorId) => {
  const params = colaboradorId ? `?retornarColaborador=${colaboradorId}` : "";
  return api().get(`v1/Colaborador/sem-paginacao${params}`);
};

export const listarColaboradoresSemPaginacao = async (empresaId) => {
  const urlParams = empresaId !== 0 ? `?&empresaId=${empresaId}` : "";
  return api().get(`v1/Colaborador/sem-paginacao${urlParams}`);
};

// Listar Area Funcional
export const listarAreaFuncional = async () => {
  return api().get("v1/AreaFuncional");
};

// Listar Cargo
export const listarCargo = async () => {
  return api().get("v1/CargoColaborador");
};

// Listar Colaborador
export const listarColaborador = async (
  ordemSelecionada,
  filtroSelecionado,
  empresaSelecionada,
  paginaAtual = 0,
  textoPesquisa,
  tamanhoPaginacao = 20
) => {
  const urlParams = `?&pagina=${paginaAtual}&tamanhoPaginacao=${tamanhoPaginacao}${
    ordemSelecionada?.length ? `&sortOrder=${ordemSelecionada}` : ""
  }${filtroSelecionado?.length ? `&filter=${filtroSelecionado}` : ""}${
    empresaSelecionada !== undefined ? `&empresaId=${empresaSelecionada}` : ""
  }${textoPesquisa?.length ? `&searchString=${textoPesquisa}` : ""}`;
  return api().get(`v1/colaborador${urlParams}`);
};

// Busca Por Id
export const buscarPorId = async (id) => {
  return api().get(`v1/colaborador/${id}`);
};

// Deletar Colaborador
export const deletarColaborador = async (id, situacao) => {
  return api().patch(`v1/colaborador/${id}/situacao/${situacao}`);
};

// Salvar Colaborador
export const salvarColaborador = async (id, dados) => {
  let metodo = "post";
  let url = "v1/colaborador/";
  if (id > 0) {
    metodo = "put";
    url = `${url}${id}`;
  }
  return api()[metodo](url, dados);
};

// Busca Árvore Gerencial do Colaborador
export const buscarArvorePorColaboradorId = async (colaboradorId) => {
  return api().get(`v1/ArvoreGerencialColaboradorEmpresa/${colaboradorId}`);
};

// Listar Colaboradores Por Árvore Gerencial
export const listarColaboradoresPorArvoreGerencial = async (empresa) => {
  return api().get(
    `v1/Colaborador/Arvore/Gerencial/Listar${
      empresa ? `?empresaId=${empresa}` : ""
    }`
  );
};

// Listar Officers por Árvore Gerencial
export const listarOfficersPorArvoreGerencial = async (empresa) => {
  const log = {
    nestedField: "get_colaborador_officers",
    tipo: "atualizaLog"
  };
  return api(log).get(
    `v1/Colaborador/officers${empresa ? `?empresaId=${empresa}` : ""}`
  );
};

export const listarOrganizarPor = () => {
  const ordernacoes = [
    {
      key: "0",
      label: "Alfabética"
    },
    {
      key: "3",
      label: "Recentes"
    }
  ];

  return ordernacoes;
};

export const listarFiltrarPor = () => {
  const filtros = [
    {
      key: "AT",
      label: "Ativo"
    },
    {
      key: "IN",
      label: "Inativo"
    }
  ];

  return filtros;
};

export const listarColaboradorPaginado = async (
  ordemSelecionada,
  filtroSelecionado,
  empresaSelecionada,
  paginaAtual = 0,
  textoPesquisa,
  campoPesquisa,
  tamanhoPaginacao = 20
) => {
  const urlParams = {
    pagina: paginaAtual,
    tamanhoPaginacao,
    sortOrder: ordemSelecionada,
    filter: filtroSelecionado,
    empresaId: empresaSelecionada,
    searchString: textoPesquisa,
    campoTextoSelecionado: campoPesquisa
  };
  return api().get(
    `v1/colaborador?${new URLSearchParams(urlParams).toString()}`
  );
};
