import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { Card, Grid } from '@mui/material';
import { useStyles, Tabela, TabelaScroll } from './style';
import Loader from 'componentes/loader';
import SelectArredondado from 'componentes/selectArredondado';
import InputDataBranco from 'componentes/inputTextoDataBranco';
import { listarDistribuidoras } from '../../../../../servicos/parametrizacaoDistribuidoras';
import { valoresContratosLeilaoCCEE } from '../../../../../servicos/premissasBrasil';
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import CloseIcon from "@mui/icons-material/Close";
import RotasDTO  from "../../../../../rotas/rotasUrlDto";  
import { useHistory } from "react-router-dom";

const LeilaoCCEEVisualizar = () => {
  const classes = useStyles();
  const history = useHistory();

  const onFechar = (id) => {
     history.push(`${RotasDTO.ProjecaoInsumoListar}`);
   }
  const [listaDistribuidoras, setListaDistribuidoras] = useState([]);
  const [distribuidoraID, setDistribuidoraID] = useState("");
  const onChangeDistribuidora = (event) => setDistribuidoraID(event.target.value);
  const [carregandoDistribuidoras, setCarregandoDistribuidoras] = useState(false);

  const [inicioSuprimento, setInicioSuprimento] = useState(null);
  const handleChangeInicioSuprimento = (ano) => setInicioSuprimento(ano);

  const obterDistribuidoras = useCallback(async () => {
    try {
      setCarregandoDistribuidoras(true);
      const lista = await listarDistribuidoras(true);
      if (lista.status === 200 && lista.data) {
        setCarregandoDistribuidoras(false);
        setListaDistribuidoras(lista?.data);
      }
    } catch (error) {
      setCarregandoDistribuidoras(false);
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );;
    }
  }, []);

  useEffect(() => {
    if (!listaDistribuidoras.length) obterDistribuidoras();
  }, [!listaDistribuidoras.length, obterDistribuidoras]);

  const [valores, setValores] = useState([]);
  const [showValores, setShowValores] = useState(false);
  const obterValores = useCallback(async () => {
    try {
      const response = await valoresContratosLeilaoCCEE(distribuidoraID,moment(inicioSuprimento).format("YYYY"));
      if (response.status === 200 && response.data) {
        setValores(response.data);
        setShowValores(true);
      } else {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "Não há dados para visualização com os parâmetros informados."
          })
        );
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  }, [distribuidoraID, inicioSuprimento]);

  useEffect(() => {
    if (distribuidoraID && inicioSuprimento) obterValores();
  }, [distribuidoraID, inicioSuprimento, obterValores]);

  return <>
    <Grid container className={classes.containerTitulo}>
          <Grid item xs={11} className="font-weight-bold">
              Planilha de Leilão CCEE
          </Grid>
          <Grid item xs={1} >             
            <CloseIcon onClick={onFechar} className={classes.icon}/>
          </Grid>   
    </Grid>
    <Grid container spacing={4} p={2} className={classes.container} justifyContent="flex-end" alignItems="center">
      <Grid item sm={3}>
        <Loader loading={carregandoDistribuidoras}>
          <SelectArredondado
            id="idDistribuidora"
            name="idDistribuidora"
            valueKey="idDistribuidora"
            valueName="titulo"
            dataSource={listaDistribuidoras}
            label="Agente Comprador"
            value={distribuidoraID}
            onChange={(event) => onChangeDistribuidora(event)}
            placeholder="Agente Comprador"
            allowClear
            className={classes.selectOfficer}
            labelClassName={classes.selectOfficer}
          />
        </Loader>
      </Grid>
      <Grid item sm={2} className={classes.customSelect}>
        <InputDataBranco
          type="text"
          id="inicioSuprimento"
          name="inicioSuprimento"
          customValue={inicioSuprimento}
          onChange={(data) => handleChangeInicioSuprimento(data)}
          maxDate={moment("2090-12-31").toDate()}
          format="yyyy"
          views={["year"]}
          renderIconShowHide={false}
        />
      </Grid>
    </Grid>
    {showValores === true ?
      <Card>
        <Grid container className={classes.container}>
          <Grid item sm={12}>
            <TabelaScroll>
              <div id="scroll">
                <Tabela>
                  <Tabela.Thead>
                    <Tabela.Tr>
                      <Tabela.Th>Leilão</Tabela.Th>
                      <Tabela.Th>Produto</Tabela.Th>
                      <Tabela.Th>Tipo de Contrato</Tabela.Th>
                      <Tabela.Th>Fonte</Tabela.Th>
                      <Tabela.Th>Custo Base</Tabela.Th>
                      <Tabela.Th>Data Leilão</Tabela.Th>
                      <Tabela.Th>Início de Suprimento</Tabela.Th>
                      <Tabela.Th>Fim de Suprimento</Tabela.Th>
                      <Tabela.Th>Agente Vendedor</Tabela.Th>
                      <Tabela.Th>Agente Comprador</Tabela.Th>
                      <Tabela.Th>Ano</Tabela.Th>
                      <Tabela.Th>Mês</Tabela.Th>
                      <Tabela.Th>Montante MWh</Tabela.Th>
                      <Tabela.Th>Status</Tabela.Th>
                    </Tabela.Tr>
                  </Tabela.Thead>
                  <Tabela.Tbody>
                    {valores?.map((item) => (
                      <Tabela.Tr>
                        <Tabela.Td>{item.leilao}</Tabela.Td>
                        <Tabela.Td>{item.produto}</Tabela.Td>
                        <Tabela.Td>{item.tipoContrato}</Tabela.Td>
                        <Tabela.Td>{item.fonte}</Tabela.Td>
                        <Tabela.Td>{item.custoBase.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</Tabela.Td>
                        <Tabela.Td>{moment(item.dataLeilao).format('L')}</Tabela.Td>
                        <Tabela.Td>{moment(item.dataInicioSuprimento).format('L')}</Tabela.Td>
                        <Tabela.Td>{moment(item.dataFimSuprimento).format('L')}</Tabela.Td>
                        <Tabela.Td>{item.agenteVendedor}</Tabela.Td>
                        <Tabela.Td>{item.agenteComprador}</Tabela.Td>
                        <Tabela.Td>{item.ano}</Tabela.Td>
                        <Tabela.Td>{moment(item.mes).format("MM")}</Tabela.Td>
                        <Tabela.Td>{item.montante.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</Tabela.Td>
                        <Tabela.Td>{item.status}</Tabela.Td>
                      </Tabela.Tr>
                    ))}
                  </Tabela.Tbody>
                </Tabela>
              </div>
            </TabelaScroll>

          </Grid>
        </Grid>
      </Card>
      : ""
    }
  </>;
}

export default LeilaoCCEEVisualizar;