import React, { useState, useCallback, useEffect } from "react";
import moment from "moment";
import { Card, Grid, Divider } from "@mui/material";
import { useForm } from "react-hook-form";
import { useStyles, BoxTabela, Tabela } from "./style";
import UploadDropArea from "componentes/upload";
import InputData from "componentes/inputTextoData";
import Botao from "componentes/botao";
import ResultadoOperacao from "componentes/resultadoOperacao";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { uploadRedeBasica, valoresRedeBasica } from "../../servicos/redeBasica";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import RotasDTO from "../../rotas/rotasUrlDto";
import { useHistory } from "react-router-dom";

const RAP = () => {
  const classes = useStyles();
  const history = useHistory();
  const msgPadraoUpload = "Solte o arquivo para anexá-lo ou procurar";
  const usuarioGlobal = useSelector((state) => state.usuario);

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(msgPadraoUpload);
  const [carregandoArquivo, setCarregandoArquivo] = useState(false);
  const [progressMessage, setProgressMessage] = useState("");
  const [resultadoOperacao, setResultadoOperacao] = useState(false);
  const [resultadoOperacaoCabecalho, setResultadoOperacaoCabecalho] = useState(
    ""
  );
  const [resultadoOperacaoObj, setResultadoOperacaoObj] = useState({
    mensagens: []
  });

  const [valoresDefinitivos, setValoresDefinitivos] = useState([]);
  const [valoresEstimados, setValoresValoresEstimados] = useState([]);
  const [anoInicial, setAnoInicial] = useState(null);
  const [anoFinal, setAnoFinal] = useState(null);
  const [temResultado, setTemResultado] = useState(false);

  const onFechar = (id) => {
    history.push(`${RotasDTO.ProjecaoInsumoListar}`);
  };

  const onAddUpload = (arquivo) => {
    if (arquivo != null && arquivo[0] != null) {
      setFile(arquivo[0].file);
      setFileName(arquivo[0].file.name);
    }
  };

  const EnviarForm = async () => {
    try {
      if (!file) {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "Por favor, selecione o arquivo desejado."
          })
        );

        return;
      }

      setCarregandoArquivo(true);
      setResultadoOperacao(false);
      setProgressMessage("Importando o arquivo... Por favor, aguarde.");

      const response = await uploadRedeBasica(
        file,
        usuarioGlobal?.usuario?.id,
        usuarioGlobal?.usuario?.usuarioNome
      );

      if (response.status === 200 && response?.data) {
        setFile(false);
        setFileName(msgPadraoUpload);
        setCarregandoArquivo(false);
        setResultadoOperacao(true);

        let dadosRetorno = response?.data;
        let mensagemRetorno = ["Processo de Importação Finalizado"];

        setResultadoOperacaoCabecalho(
          `Rede Basica - Importação do arquivo ${file?.file?.name}`
        );
        setResultadoOperacaoObj({ mensagens: mensagemRetorno });
      } else if (response.status === 202 && response?.data) {
        setFile(false);
        setFileName(msgPadraoUpload);
        setCarregandoArquivo(false);
        setResultadoOperacao(true);

        let mensagemRetorno = ["Processo de Importação Finalizado"];

        for (var i = 0; i < response.data.length; i++) {
          mensagemRetorno.push(response.data[i]);
        }

        setResultadoOperacaoCabecalho(
          `Rede Basica - Importação do arquivo ${file?.file?.name}`
        );
        setResultadoOperacaoObj({ mensagens: mensagemRetorno });
      }
    } catch (error) {
      setFile(false);
      setFileName(msgPadraoUpload);
      setCarregandoArquivo(false);
      setResultadoOperacao(true);
      setResultadoOperacaoCabecalho(
        `Rede Basica - Importação do arquivo ${file?.file?.name}`
      );
      setResultadoOperacaoObj({
        mensagens: [error.message, error.response.data.message]
      });
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  const aoEnviarForm = () => {
    EnviarForm();
  };

  const onCancelar = () => {
    setFile(false);
    setFileName(msgPadraoUpload);
    setCarregandoArquivo(false);
    setResultadoOperacao(false);
    setResultadoOperacaoCabecalho("");
    setResultadoOperacaoObj({
      mensagens: []
    });
  };

  const handleAnoInicial = (data) => setAnoInicial(data);
  const handleAnoFinal = (data) => setAnoFinal(data);

  const obterValores = useCallback(async () => {
    try {
      const lista = await valoresRedeBasica(
        moment(anoInicial).format("YYYY"),
        moment(anoFinal).format("YYYY")
      );
      if (lista?.status === 200 && lista?.data) {
        const { data } = lista;
        const def = [];
        const est = [];
        data.map((item) =>
          item.valorDF !== 0 ? def.push(item) : est.push(item)
        );
        setValoresDefinitivos(def);
        setValoresValoresEstimados(est);
        setTemResultado(true);
      }
    } catch (error) {
      console.info(error);
    }
  }, [anoInicial, anoFinal]);

  const { handleSubmit } = useForm({
    reValidateMode: "onSubmit"
  });

  useEffect(() => {
    if (anoInicial && anoFinal) obterValores();
  }, [anoInicial, anoFinal, obterValores]);

  return (
    <form className="needs-validation" onSubmit={handleSubmit(aoEnviarForm)}>
      <ResultadoOperacao
        item={resultadoOperacaoObj}
        cabecalhoResultado={resultadoOperacaoCabecalho}
        onClose={() => onFechar()}
      />
      {resultadoOperacao === false ? (
        <Card className={classes.container}>
          <Grid container className={classes.containerTitulo}>
            <Grid item xs={11} className="font-weight-bold">
              Importação de Rede Básica
            </Grid>
            <Grid item xs={1}>
              <CloseIcon onClick={onFechar} className={classes.icon} />
            </Grid>
          </Grid>
          <Grid container spacing={4} p={2} className={classes.container}>
            <Grid item sm={2} className={classes.containerData}>
              <div className={classes.divLabel}>
                <strong>De:</strong>
              </div>
              <InputData
                type="text"
                customValue={anoInicial}
                onChange={(data) => handleAnoInicial(data)}
                format="yyyy"
                views={["year"]}
                renderIconShowHide={false}
                minDate={moment().add(-100, "years").toDate()}
              />
            </Grid>
            <Grid item sm={2} className={classes.containerData}>
              <div className={classes.divLabel}>
                <strong>Até:</strong>
              </div>
              <InputData
                type="text"
                customValue={anoFinal}
                onChange={(data) => handleAnoFinal(data)}
                format="yyyy"
                views={["year"]}
                renderIconShowHide={false}
                maxDate={moment().add(100, "years").toDate()}
              />
            </Grid>
          </Grid>
          <Grid container spacing={4} p={2} className={classes.container}>
            <Grid item sm={12}>
              {temResultado === true ? (
                <BoxTabela>
                    
                    <div id="scroll">
                    <p>
                      {valoresDefinitivos.length > 0 ? "Definitivo" : ""}
                    </p>
                      <Tabela>
                        <thead>
                          <tr>
                            {valoresDefinitivos.map((item) => (
                              <th>{item.ano}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {valoresDefinitivos.map((item) => (
                              <td>
                                {item.valorDF.toLocaleString("pt-BR", {
                                  minimumFractionDigits: 2
                                })}
                              </td>
                            ))}
                          </tr>
                        </tbody>
                      </Tabela>
                    <br />
                    <Divider orientation="vertical" flexItem />
                    <br />
                    <div>
                      <p>
                        {valoresEstimados.length > 0
                          ? "Estimado Real / Nominal"
                          : ""}
                      </p>
                      <Tabela className={classes.table}>
                        <thead>
                          <tr>
                            {valoresEstimados.map((item) => (
                              <th>{item.ano}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {valoresEstimados.map((item) => (
                              <>
                                <td>
                                  {item.valorER.toLocaleString("pt-BR", {
                                    minimumFractionDigits: 2
                                  })}
                                  <div className={classes.customBr} />
                                  {item.valorEN.toLocaleString("pt-BR", {
                                    minimumFractionDigits: 2
                                  })}
                                </td>
                              </>
                            ))}
                          </tr>
                        </tbody>
                      </Tabela>
                    </div>
                  </div>
                </BoxTabela>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
          <Grid container spacing={4} p={2} className={classes.container}>
            <Grid item sm={12}>
              <UploadDropArea
                dropAreaText={fileName}
                acceptedFiles={[
                  ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                ]}
                showLoader={false}
                onAdd={onAddUpload}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={4}
            p={2}
            className={classes.container}
            justifyContent="space-between"
          >
            <Grid item sm={3}>
              <Botao
                type="button"
                label="Cancelar"
                className={classes.customButton}
              />
            </Grid>
            <Grid item sm={3}>
              <Botao
                type="submit"
                label="Importar"
                className={classes.button}
              />
            </Grid>
          </Grid>
        </Card>
      ) : (
        ""
      )}
    </form>
  );
};

export default RAP;
