import React from "react";
import { Grid } from "@mui/material";
import { useStyles } from "./style";

const TermoPactuacao = () => {
  const classes = useStyles();

  return (
    <Grid className={classes.containerContent}>
      <h5>
        <p>
          Processando seu termo de pactuação, enviaremos em breve para a
          distribuidora
        </p>
      </h5>
    </Grid>
  );
};

export default TermoPactuacao;
