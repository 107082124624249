import React from "react";

import IconeSucesso from "../../../../../assets/icones/workflow/sucesso.svg";
import IconeProibido from "../../../../../assets/icones/workflow/proibido.svg";
import { useStyles } from "./style";

const PassoFoto = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.card}>
        <h3 className={classes.tituloCard}>Como enviar seu documento:</h3>
        <div className={classes.subTituloCard}>
          <img src={IconeSucesso} className="mr-2" alt="ícone sucesso" />
          <p>Acesse a câmera frontal do seu celular.</p>
        </div>
        <div className={classes.subTituloCard}>
          <img src={IconeProibido} className="mr-2" alt="ícone proíbido" />
          <p>
            Não use chapéu, óculos, nem máscara que possam cobrir seu rosto.
          </p>
        </div>
        <div className={classes.subTituloCard}>
          <img src={IconeSucesso} className="mr-2" alt="ícone sucesso" />
          <p>
            Segure o documento escolhido (virado para a frente), alinhado e
            próximo ao seu rosto.
          </p>
        </div>
        <div className={classes.subTituloCard}>
          <img src={IconeSucesso} className="mr-2" alt="ícone sucesso" />
          <p>Ao tirar uma foto, verifique se está em um lugar bem iluminado.</p>
        </div>
        <div className={classes.subTituloCard}>
          <img src={IconeSucesso} className="mr-2" alt="ícone sucesso" />
          <p>
            Verifique se seu rosto aparece de forma clara e se o documento está
            legível.
          </p>
        </div>
      </div>
    </div>
  );
};
export default PassoFoto;
