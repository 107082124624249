import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Componentes
import Modal from "@mui/material/Modal";
import { Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useStyles } from "./style";

const ModalMaior = (props) => {
  const { item, onConfirmar, onCancelar, mensagem } = props;

  const classes = useStyles();
  const [aberto, setAberto] = useState(false);

  // eslint-disable-next-line no-unused-vars, no-shadow
  const aoEnviar = (item) => {
    onConfirmar(item);
  };

  useEffect(() => {
    setAberto(Boolean(item));
  }, [item]);

  const onClose = () => {
    setAberto(false);
    onCancelar();
  };

  return (
    <Modal open={aberto} onClose={onClose}>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        className={classes.container}
        p={1}
        spacing={1}
      >
        <Grid item xs={1}>
          <CloseIcon
            className={classes.icon}
            onClick={() => {
              onClose();
            }}
          />
        </Grid>
        <Grid item xs={12}>
          {mensagem}
        </Grid>
      </Grid>
    </Modal>
  );
};

ModalMaior.propTypes = {
  item: PropTypes.oneOfType([PropTypes.any]).isRequired,
  onConfirmar: PropTypes.oneOfType([PropTypes.func]),
  onCancelar: PropTypes.oneOfType([PropTypes.func]),
  mensagem: PropTypes.oneOfType([PropTypes.node, PropTypes.any])
};

ModalMaior.defaultProps = {
  onConfirmar: () => {},
  onCancelar: () => {},
  mensagem: {}
};

export default ModalMaior;
