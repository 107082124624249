/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

// Styles
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Loader from "componentes/loader";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { Box } from "@mui/material";
import ReactSpeedometer from "react-d3-speedometer";
import { BG, useStyles } from "./style";

import Carousel from "../Carousel";

import historico from "../historico-completo.png";

const RotaUsinas = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [carregandoUsina, setCarregandoUsina] = useState(true);
  const [dadosUsinas, setDadosUsina] = useState([]);
  const [evolucao1, setEvolucao1] = useState(11.3);
  const [evolucao2, setEvolucao2] = useState();
  const stateUsina = useSelector((state) => state?.menu?.usina);

  useEffect(() => {
    setTimeout(() => {
      setLoading(true);
    }, 500);
  }, []);

  const updateEvolucao1 = (newValue) => {
    setEvolucao1(newValue);
  };

  const updateEvolucao2 = (newValue) => {
    setEvolucao2(newValue);
  };

  useEffect(() => {
    if (stateUsina?.id === null) {
      window.location.href = "../";
    }
    fetchUsina(stateUsina);
  }, [stateUsina]);

  useEffect(() => {
    // Atualiza os dados do gráfico manualmente
    const chart = document.querySelector("#chart1"); // Seleciona o elemento do gráfico
    if (chart && chart.ApexCharts) {
      chart.ApexCharts.updateOptions({
        series: [evolucao1?.replace(",", ".")] // Atualiza a série do gráfico
      });
    }
  }, [evolucao1]);

  const fetchUsina = async () => {
    try {
      setCarregandoUsina(true);
      fetch(
        `https://apiapp.sualuz.com.br/wpusinas/index.php/wp-json/wp/v2/pages/${stateUsina?.id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setDadosUsina(data);
          updateEvolucao1(
            data?.acf?.cronograma_de_evolucao
              ?.replace("%", "")
              ?.replace(",", ".")
          );
          updateEvolucao2(
            data?.acf?.cronograma_de_evolucao_financeira
              ?.replace("%", "")
              ?.replace(",", ".")
          );
          setCarregandoUsina(false);
        });
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  const formatarVariavel = (valor) => {
    if (valor != null && valor !== "" && valor !== undefined) {
      valor = valor.toString().replace(/[^\d,.]/g, "");
      valor = valor.replace(",", ".");
      valor = parseFloat(valor);
      return valor;
    }
    return 0;
  };

  const carrossel = useRef(null);
  const exibirHistorico = () => {
    if (carrossel.current) {
      carrossel.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="p-5">
      {carregandoUsina ? (
        <>
          <Loader loading className="w-auto" />
          <div className={classes.titulosCarregando}>
            <h4>Carregando dados</h4>
          </div>
        </>
      ) : (
        <>
          <div className="row">
            <div className="col-lg-6 col-12">
              <div>
                {dadosUsinas?.acf?.foto_principal ? (
                  <div className="d-inline-block">
                    <img
                      src={dadosUsinas?.acf?.foto_principal}
                      alt="Foto"
                      className="img-fluid"
                    />
                    <Box
                      className={classes.areaHistorico}
                      onClick={exibirHistorico}
                    >
                      <img
                        src={historico}
                        alt="Histórico Completo"
                        className="img-fluid"
                      />
                    </Box>
                  </div>
                ) : (
                  <div className="text-center">
                    <h3 className={classes.tituloOperacao}>
                      Nenhuma foto cadastrada
                    </h3>
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-6">
              <div className={classes.boxMaxWidth}>
                <div className={classes.tituloOperacao}>
                  {dadosUsinas?.acf?.entrada_em_operacao}
                </div>
                {dadosUsinas?.acf?.avancos && (
                  <div className="row">
                    <div className="col-md-6 p-3 d-flex align-items-center justify-content-center">
                      <div className="text-center">
                        <h3 className={classes.h3Avanco}>Avanço Físico</h3>
                        <h2 className={classes.h2Porcentagem}>{evolucao1}%</h2>
                        <div className={classes.reactSpeedWidth}>
                          <ReactSpeedometer
                            labelFontSize="0px"
                            valueTextFontSize="0px"
                            valueTextFontWeight="0"
                            maxSegmentLabels={0}
                            segmentColors={["#FFCC00", "#AAA"]}
                            customSegmentStops={[
                              0,
                              formatarVariavel(evolucao1),
                              100
                            ]}
                            width={235}
                            textColor="#eee"
                            minValue={0}
                            maxValue={100}
                            value={formatarVariavel(evolucao1)}
                            paddingHorizontal={34}
                            paddingVertical={34}
                            ringWidth={30}
                            needleColor="white"
                          />
                        </div>
                        <div className={classes.zeroacem}>
                          <div className={classes.zero}>0%</div>
                          <div className={classes.cem}>100%</div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 p-3 d-flex align-items-center justify-content-center">
                      <div className="text-center">
                        <h3 className={classes.h3Avanco}>Avanço Financeiro</h3>
                        <h2 className={classes.h2PorcentagemFinanceiro}>
                          {evolucao2}%
                        </h2>
                        <div className={classes.reactSpeedWidth}>
                          <ReactSpeedometer
                            labelFontSize="0px"
                            valueTextFontSize="0px"
                            valueTextFontWeight="0"
                            valueText={() => "sadsadsa"}
                            maxSegmentLabels={0}
                            segmentColors={["#83929b", "#AAA"]}
                            customSegmentStops={[
                              0,
                              formatarVariavel(evolucao2),
                              100
                            ]}
                            width={235}
                            textColor="#eee"
                            minValue={0}
                            maxValue={100}
                            value={formatarVariavel(evolucao2)}
                            paddingHorizontal={34}
                            paddingVertical={34}
                            ringWidth={30}
                            needleColor="white"
                          />
                        </div>
                        <div className={classes.zeroacem}>
                          <div className={classes.zero}>0%</div>
                          <div className={classes.cem}>100%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className={`col-md-6 ${classes.areaNumeros}`}>
              <h3 className={classes.subTitlo}>Projetos</h3>
              <div className="row">
                <div className="col-4 my-1">Potência</div>
                <div className="col-8 my-1">{dadosUsinas?.acf?.potencia}</div>

                {dadosUsinas?.acf?.potencia_parcialmente_conectada && (
                  <>
                    <div className="col-4 my-1">
                      Potência parcialmente conectada
                    </div>
                    <div className="col-8 my-1">
                      {dadosUsinas?.acf?.potencia_parcialmente_conectada}
                    </div>
                  </>
                )}

                <div className="col-4 my-1">Endereço</div>
                <div className="col-8 my-1">{dadosUsinas?.acf?.endereco}</div>

                <div className="col-4 my-1">Cronograma de evolução física</div>
                <div className="col-8 my-1">
                  {dadosUsinas?.acf?.cronograma_de_evolucao}
                </div>

                <div className="col-4 my-1">
                  Cronograma de evolução financeira
                </div>
                <div className="col-8 my-1">
                  {dadosUsinas?.acf?.cronograma_de_evolucao_financeira}
                </div>
                <div className="col-4 my-1">Data de início</div>
                <div className="col-8 my-1">
                  {dadosUsinas?.acf?.data_de_inicio}
                </div>

                <div className="col-4 my-1">Data do término</div>
                <div className="col-8 my-1">
                  {dadosUsinas?.acf?.data_do_termino}
                </div>

                <div className="col-4 my-1">Volume de energia</div>
                <div className="col-8 my-1">
                  {dadosUsinas?.acf?.volume_de_energia}
                </div>

                <div className="col-4 my-1">Concessionária</div>
                <div className="col-8 my-1">
                  {dadosUsinas?.acf?.concessionaria}
                </div>

                <div className="col-4 my-1">CAPEX</div>
                <div className="col-8 my-1">{dadosUsinas?.acf?.capex}</div>

                <div className="col-4 my-1">Estruturas</div>
                <div className="col-8 my-1">{dadosUsinas?.acf?.estruturas}</div>
              </div>
            </div>

            <div className="col-md-6 mt-5">
              <div className={classes.boxMaxWidth}>
                {dadosUsinas?.acf?.vetor_mapa && (
                  <img src={dadosUsinas?.acf?.vetor_mapa} alt="Mapa" />
                )}
              </div>
            </div>
          </div>

          <div className="row d-flex align-items-baseline">
            <div className={`col-md-6 ${classes.areaNumeros2}`}>
              <h3 className={classes.subTitlo}>Ambiental</h3>
              <div className="row">
                <div className="col-4 my-1">Licenciamento Ambiental</div>
                <div className="col-8 my-1">
                  {dadosUsinas?.acf?.licenciamento_ambiental}
                </div>

                <div className="col-4 my-1">Documento do Órgão Ambiental</div>
                <div className="col-8 my-1">
                  {dadosUsinas?.acf?.documento_do_orgao_ambiental}
                </div>

                <div className="col-4 my-1">Documento Ambiental Municipal</div>
                <div className="col-8 my-1">
                  {dadosUsinas?.acf?.documento_ambiental_municipal}
                </div>

                <div className="col-4 my-1">Supressão Vegetal</div>
                <div className="col-8 my-1">
                  {dadosUsinas?.acf?.supressao_vegetal}
                </div>

                <div className="col-4 my-1">Status Licenciamento Ambiental</div>
                <div className="col-8 my-1">
                  {dadosUsinas?.acf?.status_licenciamento_ambiental}
                  {dadosUsinas?.acf?.status_licenciamento_ambiental ===
                    "Concluído" && (
                    <CheckCircleIcon className={classes.iconeConcluido} />
                  )}
                </div>
              </div>
            </div>

            <div className={`col-md-6 ${classes.areaNumeros2}`}>
              <h3 className={classes.subTitlo}>Fundiário</h3>
              <div className="row">
                <div className="col-4 my-1">Tipo de Contrato</div>
                <div className="col-8 my-1">
                  {dadosUsinas?.acf?.tipo_de_contrato}
                </div>
                <div className="col-4 my-1">Status do Contrato</div>
                <div className="col-8 my-1">
                  {dadosUsinas?.acf?.status_do_contrato}
                </div>

                <div className="col-4 my-1">Área</div>
                <div className="col-8 my-1"> {dadosUsinas?.acf?.area}</div>

                <div className="col-4 my-1">Preço</div>
                <div className="col-8 my-1"> {dadosUsinas?.acf?.preco}</div>

                <div className="col-4 my-1">Matricula</div>
                <div className="col-8 my-1"> {dadosUsinas?.acf?.matricula}</div>

                <div className="col-4 my-1">Status do Fundiário</div>
                <div className="col-8 my-1">
                  {dadosUsinas?.acf?.status_do_fundiario}
                  {dadosUsinas?.acf?.status_do_fundiario === "Concluído" && (
                    <CheckCircleIcon className={classes.iconeConcluido} />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row d-flex align-items-baseline">
            <div className={`col-md-6 ${classes.areaNumeros}`}>
              <h3 className={classes.subTitlo}>Detalhamento do Projeto</h3>
              <div className="row">
                <div className="col-4 my-2">Status da Obra</div>
                <div className="col-8 my-2">
                  {dadosUsinas?.acf?.status_da_obra}
                </div>

                <div className="col-4 my-2">Contratos com a Distribuidora</div>
                <div className="col-8 my-2">
                  {dadosUsinas?.acf?.contratos_com_a_distribuidora}
                </div>

                <div className="col-4 my-2">
                  Desenvolvedor do Projeto e Parecer de Acesso
                </div>
                <div className="col-8 my-2">
                  {
                    dadosUsinas?.acf
                      ?.desenvolvedor_do_projeto_e_parecer_de_acesso
                  }
                </div>

                <div className="col-4 my-2">Equipamentos</div>
                <div className="col-8 my-2">
                  {" "}
                  {dadosUsinas?.acf?.equipamentos}
                </div>

                <div className="col-4 my-2">Fornecedores</div>
                <div className="col-8 my-2">
                  {" "}
                  {dadosUsinas?.acf?.fornecedores}
                </div>

                <div className="col-4 my-2">Estrutura Societária</div>
                <div className="col-8 my-2">
                  {dadosUsinas?.acf?.estrutura_societaria}
                </div>
              </div>
            </div>

            <div className="col-md-6 mt-5" ref={carrossel}>
              <div className={classes.boxMaxWidth}>
                {loading && (
                  <Carousel>
                    {dadosUsinas?.acf?.foto_1 && (
                      <div className="item">
                        <BG foto={dadosUsinas?.acf?.foto_1} />
                      </div>
                    )}
                    {dadosUsinas?.acf?.foto_2 && (
                      <div className="item">
                        <BG foto={dadosUsinas?.acf?.foto_2} />
                      </div>
                    )}
                    {dadosUsinas?.acf?.foto_3 && (
                      <div className="item">
                        <BG foto={dadosUsinas?.acf?.foto_3} />
                      </div>
                    )}
                    {dadosUsinas?.acf?.foto_4 && (
                      <div className="item">
                        <BG foto={dadosUsinas?.acf?.foto_4} />
                      </div>
                    )}
                    {dadosUsinas?.acf?.foto_5 && (
                      <div className="item">
                        <BG foto={dadosUsinas?.acf?.foto_5} />
                      </div>
                    )}
                    {dadosUsinas?.acf?.foto_6 && (
                      <div className="item">
                        <BG foto={dadosUsinas?.acf?.foto_6} />
                      </div>
                    )}
                  </Carousel>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default RotaUsinas;
