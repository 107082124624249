import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const api = () => obterApi(MICROSERVICO.MEASUREMENT);

export const listarUsinasLuz = async (filtro) => {
  const filtroQueryString = new URLSearchParams(filtro).toString();
  return api().get(`v1/usinasluz?${filtroQueryString}`);
};

export const salvarUsinasLuz = async (id, body) => {
  let metodo = "post";
  let url = "v1/usinasluz";
  if (id > 0) {
    metodo = "put";
    url = `${url}/${id}`;
  }
  return api()[metodo](url, body);
};

export const atualizarUsinasLuz = async (params) => {
  const { id, situacao } = params;

  return api().patch(`v1/usinasluz/${id}/${situacao}`);
};

export const listarOrganizarPor = () => {
  const ordenar = [
    {
      key: "1",
      asc: "1",
      desc: "2",
      label: "Alfabética"
    },
    {
      key: "4",
      asc: "4",
      desc: "0",
      label: "Recentes"
    }
  ];

  return ordenar;
};

export const listarFiltrarPor = () => {
  const filtros = [
    {
      key: "AT",
      label: "Ativo"
    },
    {
      key: "IN",
      label: "Inativo"
    }
  ];

  return filtros;
};

export const obterUsinasLuzPorId = (params) => {
  const filtroQueryString = new URLSearchParams(params).toString();
  return api().get(`v1/usinasluz/obterusinasluzporid?${filtroQueryString}`);
};

export const obterUsinasLuzCpfCnpj = (cpfCnpj) => {
  return api().get(`v1/usinasluz/${cpfCnpj}`);
};

export const obterUsinaAssociacaoPorAssociacaoId = (params) => {
  const filtroQueryString = new URLSearchParams(params).toString();
  return api().get(
    `v1/usinasluz/obterusinaassociacaoporassociacaoid?${filtroQueryString}`
  );
};
