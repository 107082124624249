import { Card, Divider, Grid } from "@mui/material";
import { Botao, BotaoCadastro, Loader } from "componentes";

import BotaoOutLine from "componentes/botaoOutline";
import { Base } from "componentes/cores";
import UploadDropAreaMultiplo from "componentes/uploadMultiplo";
import { RotasDTO } from "global/rotas/rotasUrlDto";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ContatoDemo from "./Componente/contatoDemo";
import ClienteDemoDto from "./Dto/clienteDemoDto";
import ContatoCotacaoHelper from "./helper";
import { useStyles } from "./style";

const ContatoCotacao = () => {
  const classes = useStyles();
  const refContato = useRef(null);
  const refRepresentante = useRef(null);
  const history = useHistory();
  const { state } = useLocation();

  const [dadosEmpresa, setDadosEmpresa] = useState(null);
  const [representanteVisiviel, setRepresentanteVisivel] = useState(false);
  const [carregandoUpload, setCarregandoUpload] = useState(0);
  const [arquivos, setArquivos] = useState([]);
  const [carregando, setCarregando] = useState(false);

  const cnpjParametro = useMemo(() => {
    return state?.cnpj ?? null;
  }, [state]);

  const onClickRemoverResponsavel = () => {
    setRepresentanteVisivel(false);
  };

  const onClickNovoRepresentante = async () => {
    if (!(await refContato.current.validarCampos())) return;

    setRepresentanteVisivel((oldState) => !oldState);
  };

  const obterCnpjReceita = async () => {
    if (!cnpjParametro) return;

    const cliente = await ContatoCotacaoHelper.obterClientesPorCnpj(
      cnpjParametro
    );

    if (cliente.sucesso) {
      setDadosEmpresa({
        ...cliente.data,
        contatoInicial: {
          nomeCompleto: cliente.data.nomeContatoInicial,
          email: cliente.data.emailContatoInicial,
          representante: cliente.data.contatoRepresentante,
          cpf: cliente.data.cpfContatoInicial,
          rg: cliente.data.rgContatoInicial,
          telefone: cliente.data.telefone
        }
      });
      return;
    }

    const resultado = await ContatoCotacaoHelper.obterDadosEmpresaReceitaCNPJ(
      cnpjParametro
    );

    if (!resultado.sucesso) {
      ContatoCotacaoHelper.exibirErro(resultado.mensagem);
      return;
    }

    setDadosEmpresa(
      ClienteDemoDto.receitaFederalParaDto(resultado.data, state?.nomeFantasia)
    );
  };

  const validarCampos = async () => {
    const validarcontato =
      (await refContato?.current?.validarCampos()) ?? false;
    const validarRepresentante =
      (await refRepresentante?.current?.validarCampos()) ?? true;

    return validarcontato && validarRepresentante;
  };

  // eslint-disable-next-line consistent-return
  const removerCaracteresEspeciais = (valor) => {
    if (valor) return valor.replace(/[^a-zA-Z0-9]/g, "");
  };

  const onSalvar = async () => {
    if (!(await validarCampos())) return false;

    setCarregando(true);

    const dadosContato = refContato?.current?.obterValores();
    const dadosRepresentante = refRepresentante?.current?.obterValores();

    const paraSalvar = {
      ...dadosEmpresa,
      representantes: dadosRepresentante
        ? [
            {
              ...dadosRepresentante,
              telefone: removerCaracteresEspeciais(dadosRepresentante?.telefone)
            }
          ]
        : [],
      contatoInicial: dadosContato,
      telefone: removerCaracteresEspeciais(dadosContato?.telefone)
    };

    const result = await ContatoCotacaoHelper.salvarClientes(paraSalvar);

    if (!result.sucesso) {
      ContatoCotacaoHelper.exibirErro(result.mensagem);
      setCarregando(false);
      return false;
    }

    if (!arquivos || arquivos.length === 0) {
      setCarregando(false);
      return true;
    }

    // eslint-disable-next-line no-use-before-define
    const resultado = await onSalvarArquivos(
      result?.data?.id || paraSalvar?.id
    );

    setCarregando(false);
    return resultado;
  };

  const onSalvarArquivos = async (clienteId) => {
    const result = await ContatoCotacaoHelper.salvarArquivos(
      arquivos,
      clienteId
    );

    if (!result.sucesso) {
      ContatoCotacaoHelper.exibirErro(result.mensagem);
      return false;
    }

    ContatoCotacaoHelper.exibirSucesso(result.mensagem);
    return true;
  };

  const onContratar = async () => {
    const salvou = await onSalvar();

    if (!salvou) return;

    setCarregando(true);

    const dadosContato = refContato?.current?.obterValores();
    const dadosRepresentante = refRepresentante?.current?.obterValores();
    const dadosDocumento = dadosRepresentante || dadosContato;

    const criarDocumento = {
      cotacaoId: state.id,
      nome: dadosDocumento.nomeCompleto,
      email: dadosDocumento.email,
      telefone: removerCaracteresEspeciais(dadosDocumento.telefone)
    };

    const resultado = await ContatoCotacaoHelper.criarDocumento(criarDocumento);

    if (!resultado.sucesso) {
      ContatoCotacaoHelper.exibirErro(resultado.mensagem);
      setCarregando(false);

      return;
    }

    setCarregando(false);
    history.push(`${RotasDTO.Demo}/cotacao/contrato`, resultado.data);
  };

  const apenasSalvar = async () => {
    const salvou = await onSalvar();

    if (!salvou) return;

    history.push(`${RotasDTO.Demo}`);
  };

  // eslint-disable-next-line no-shadow
  const onAddArquivoUpload = (arquivos) => {
    if (!arquivos || arquivos.length === 0) return;

    setCarregandoUpload(0);

    let contador = 0;

    const intervalo = setInterval(() => {
      if (contador === 4) {
        clearInterval(intervalo);
        return;
      }

      setCarregandoUpload((old) => old + 25);
      // eslint-disable-next-line no-plusplus
      contador++;
    }, 250);
  };

  // eslint-disable-next-line no-shadow
  const onDeleteArquivoUpload = (arquivos) => {
    setArquivos(arquivos);
  };

  useEffect(() => {
    obterCnpjReceita();
  }, [cnpjParametro]);

  useEffect(() => {
    if (dadosEmpresa?.representantes && dadosEmpresa?.representantes.length > 0)
      setRepresentanteVisivel(true);
  }, [dadosEmpresa]);

  return (
    <Loader loading={carregando}>
      <Card className={classes.container}>
        <Grid
          container
          spacing={5}
          justifyContent="flex-start"
          className={classes.container}
        >
          <Grid item className={`font-weight-bold ${classes.tituloSecao}`}>
            Dados contato
          </Grid>
        </Grid>
        <ContatoDemo
          ref={refContato}
          dadosPadrao={dadosEmpresa?.contatoInicial}
        />
        {representanteVisiviel && (
          <>
            <Grid
              container
              spacing={2}
              justifyContent="flex-start"
              className={classes.containerCampos}
            >
              <Grid item className={`${classes.tituloSecao}`}>
                Representante
              </Grid>
            </Grid>
            <ContatoDemo
              ref={refRepresentante}
              ehRepresentante
              dadosPadrao={
                dadosEmpresa?.representantes && dadosEmpresa?.representantes[0]
              }
              onClickRemoverResponsavel={onClickRemoverResponsavel}
            />
          </>
        )}
        {!representanteVisiviel && (
          <Grid
            container
            className={(classes.container, classes.containerCampos)}
          >
            <Grid item xs={12} spacing={2} className={classes.addRepresentante}>
              <BotaoCadastro
                label="Adicionar representante"
                color={Base.Black}
                onClick={onClickNovoRepresentante}
              />
              <Grid item lg={12} md={12} className="pt-4">
                <Divider />
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid
          container
          spacing={2}
          justifyContent="flex-start"
          className={classes.containerCampos}
        >
          <Grid item className={`font-weight-bold ${classes.tituloSecao}`}>
            Anexar documentos
          </Grid>
        </Grid>
        <Grid container spacing={5} className={`${classes.container}`}>
          <Grid item>
            Clique no botão para tirar uma foto da fatura e autopreencher ou
            preencha abaixo:
          </Grid>
        </Grid>
        <Grid container spacing={5} className={`${classes.container}`}>
          <Grid item lg={12} md={12} sm={12}>
            <UploadDropAreaMultiplo
              onAdd={onAddArquivoUpload}
              onDropRejected={onDeleteArquivoUpload}
              progressValue={carregandoUpload}
              acceptedFiles={["image/*", "application/pdf"]}
              dropAreaText="Solte a(s) fatura(s) de energia elétrica para anexá-la(s) ou procurar"
              filesLimit={10}
              renderizaComponente
              showPreviews
            />
          </Grid>
        </Grid>
        <Grid container spacing={5} className={classes.containerAvancar}>
          <Grid item lg={2} md={2} sm={12} className="pr-0">
            <Botao
              type="submit"
              label="Contratar"
              bgColor={Base.Mango}
              onClick={() => onContratar()}
            />
          </Grid>
          <Grid item lg={2} md={2} sm={12} className="pr-0">
            <BotaoOutLine
              type="submit"
              label="Salvar"
              onClick={() => apenasSalvar()}
            />
          </Grid>
        </Grid>
      </Card>
    </Loader>
  );
};

export default ContatoCotacao;
