export const StatusPreFaturamentoDto = {
  ImportacaoIncompleta: 1,
  FioCalculado: 2,
  AnaliseFioRealizada: 3,
  AguardandoConfirmacaoFio: 4,
  RefazerCalculo: 5,
  AFaturar: 6,
  EmProcessamento: 7,
  NaoProcessado: 8,
  Faturado: 9
};
