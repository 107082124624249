import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";
import {
  getByUnidadeId,
  reativarBoletaGd
} from "../../../../servicos/boletasGDServico";
import { store } from "../../../../global/redux";
import RetornoEndpointDto from "../../../../global/dto/retornoEndpointDto";

export default class CadastradoBoletaGdHelper {
  static async getByUnidadeId(unidadeConsumidoraId, setCarregandoSalvar) {
    try {
      setCarregandoSalvar(true);
      const resultado = await getByUnidadeId(unidadeConsumidoraId);
      if (resultado.status === 204)
        return RetornoEndpointDto.Sucesso("204", []);

      return RetornoEndpointDto.Sucesso(
        "Obter unidade consumidora  com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Erro ao buscar unidade consumidora!");
    } finally {
      setCarregandoSalvar(false);
    }
  }

  static async reativarBoletaGd(body, setCarregandoSalvar) {
    try {
      setCarregandoSalvar(true);
      const resultado = await reativarBoletaGd(body);
      if (resultado.status === 204)
        return RetornoEndpointDto.Sucesso("204", []);

      return RetornoEndpointDto.Sucesso(
        "Boleta Gd reativada com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Erro ao atualizar boleta Gd!");
    } finally {
      setCarregandoSalvar(false);
    }
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }

  static exibirSucesso(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem
      })
    );
  }
}
