import React, { useEffect, useRef, useState, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useHistory } from "react-router-dom";

// Componentes
import { Grid } from "@mui/material";
import MaterialInputBusca from "componentes/inputBusca";
import { InterfaceDTO } from "global/dto/interfacesDto";
import { useSelector } from "react-redux";
import { TabelaPaginada } from "../../../../componentes";
import Botao from "../../../../componentes/botao";
import InputData from "../../../../componentes/inputTextoData";
import Loader from "../../../../componentes/loader";
import Coluna from "../../../../componentes/tabelaPaginada/colunas/coluna";
import ColunaComponentePersonalizado from "../../../../componentes/tabelaPaginada/colunas/colunaComponentePersonalizado";
import ResultadoPaginadoDto from "../../../../componentes/tabelaPaginada/resultadoPaginadoDto";

// Redux
import { store } from "../../../../global/redux";
import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";

// DTO
import { RotasDTO } from "../../../../global/rotas/rotasUrlDto";

// Styles
import { Container, useStyles } from "./style";

import ColunaMultiplosBotoes from "../colunaCustomizada";
import enumeradorBotao from "../enumeradores/enumeradorBotao";
import FiltroAdicionalTabela from "./filtroAdicionalTabela";
import FiltroDto from "./filtroDto";
import PreFaturamentoHelper from "./helper";
import PreFaturamentoListaDto from "./preFaturamentoListaDto";

import { usuarioPossuiFuncionalidade } from "../../../../servicos/funcionalidadesServico";

const PreFaturamento = () => {
  const history = useHistory();
  const classes = useStyles();
  const refTabela = useRef(null);

  const usuario = useSelector((state) => state.usuario);
  const {
    empresaSelecionada,
    clienteSelecionado,
    unidadeSelecionada
  } = usuario;

  const { register, control } = useForm({
    reValidateMode: "onSubmit"
  });

  const usuarioGlobal = useSelector((state) => state.usuario);
  const { menu } = usuarioGlobal;
  const rotas = useSelector((state) => state.rotas);

  const [mesAno, setMesAno] = useState();
  const [dataReferencia, setDataReferencia] = useState();
  const [exibirTabela, setExibirTabela] = useState(false);
  const [carregandoBtnCalculoCusto, setCarregandoBtnCalculoCusto] = useState(
    false
  );

  const [carregamentoInicial, setCarregamentoInicial] = useState(false);

  const possuiPermissaoCalcularCustos = usuarioPossuiFuncionalidade(
    menu,
    rotas.rotaAtual.caminho,
    RotasDTO.PreFaturamento,
    InterfaceDTO.PermissaoCalcularCustos
  );

  useEffect(() => {
    setCarregamentoInicial(false);
    const dataFiltro = window.moment().toDate();
    setMesAno(window.moment(dataFiltro).subtract(1, "month"));

    // Este é m bug do window.moment, ao obter o mês, ele pega o anterior
    let mesRef = window.moment(mesAno).add(1, "month").month();
    if (mesRef === 0) mesRef = 12;

    const dataRef = `${window.moment(dataFiltro).year()}-${mesRef}-1`;
    setDataReferencia(dataRef);
  }, []);

  const textoBusca = useWatch({
    control,
    name: "textoBusca",
    defaultValue: ""
  });

  const LabelColunaStatus = (params) => {
    const { className, parametros } = params;

    return (
      <Container className={className} status={parametros.statusId}>
        {parametros.statusNome}
      </Container>
    );
  };

  const onClickEditar = (id) => {
    history.push(`${RotasDTO.PreFaturamento}/cadastro/${id}`);
  };

  const onClickRastrear = (id) => {
    history.push(`${RotasDTO.PreFaturamento}/rastreamento/${id}`);
  };

  const onClickBotao = (tipo, id) => {
    switch (tipo) {
      case enumeradorBotao.Edicao:
        onClickEditar(id);
        break;
      case enumeradorBotao.Rastreamento:
        onClickRastrear(id);
        break;
      default:
        break;
    }
  };

  const colunas = [
    new Coluna("unidadeConsumidoraId", "Unidade consumidora"),
    new Coluna("mesReferencia", "Mês de referência"),
    new Coluna("dataRealizado", "Realizado em:"),
    new ColunaComponentePersonalizado(
      "status",
      "Status",
      LabelColunaStatus,
      null,
      false,
      false,
      null,
      null,
      "pl-2"
    ),
    new ColunaComponentePersonalizado(
      "opcoes",
      "Ações",
      ColunaMultiplosBotoes,
      onClickBotao,
      false,
      false,
      "10%"
    )
  ];

  const executarCalculoCusto = async () => {
    setCarregandoBtnCalculoCusto(true);

    const resultado = await PreFaturamentoHelper.executarCalculoCusto(
      dataReferencia
    );

    if (!resultado.sucesso) {
      setCarregandoBtnCalculoCusto(false);
      PreFaturamentoHelper.exibirErro(resultado.mensagem);
      return;
    }

    PreFaturamentoHelper.exibirSucesso(resultado.data.listaInformacao[0]);
    setCarregandoBtnCalculoCusto(false);
  };

  const obterPreFaturamento = async (parametros) => {
    const filtro = new FiltroDto(
      parametros.totalPagina,
      parametros.pagina,
      parametros.busca,
      parametros.empresaId,
      parametros.clienteId,
      parametros.unidadeId,
      parametros.ano,
      parametros.mes
    );

    const resultado = await PreFaturamentoHelper.listarPreFaturamento(filtro);

    if (!resultado.sucesso) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: resultado.mensagem
        })
      );
      return new ResultadoPaginadoDto([], 1, 0, 0);
    }

    if (resultado?.data?.length === 0 ?? true)
      return new ResultadoPaginadoDto([], 1, 0, 0);

    const resLinhas = resultado?.data?.preFaturamento?.map((res) => {
      return new PreFaturamentoListaDto(
        res.id,
        res.unidadeConsumidora,
        res.mesReferencia,
        res.realizadoEm,
        res.statusId,
        res.statusNome,
        true
      );
    });

    return new ResultadoPaginadoDto(
      resLinhas,
      resultado.data.paginaAtual,
      resultado.data.totalItens,
      resultado.data.totalPaginas
    );
  };

  const [ordemSelecionada] = useState();

  const [filtroAdicionalTabela, setFiltroAdicionalTabela] = useState(
    new FiltroAdicionalTabela()
  );

  useEffect(() => {
    setFiltroAdicionalTabela(
      new FiltroAdicionalTabela(
        ordemSelecionada,
        mesAno,
        empresaSelecionada,
        clienteSelecionado,
        unidadeSelecionada
      )
    );
  }, [mesAno, empresaSelecionada, clienteSelecionado, unidadeSelecionada]);

  useEffect(() => {
    setTimeout(() => {
      setExibirTabela(true);
    }, 100);
  }, []);

  const onChangeFiltrosTabela = async (parametros) => {
    let mesRef = window.moment(mesAno).add(1, "month").month();
    if (mesRef === 0) mesRef = 12;

    const dataRef = `${window.moment(mesAno).year()}-${mesRef}-1`;
    setDataReferencia(dataRef);

    setCarregamentoInicial(true);
    if (carregamentoInicial) {
      return obterPreFaturamento({
        tamanhoPagina: parametros.totalPagina,
        pagina: parametros.pagina,
        busca: parametros.pesquisar || textoPesquisa,
        empresaId: empresaSelecionada,
        clienteId: clienteSelecionado,
        unidadeId: unidadeSelecionada,
        ano: window.moment(mesAno).year(),
        mes: mesRef
      });
    }
  };

  const textoPesquisa = useMemo(() => {
    return textoBusca?.length >= 3 ? textoBusca?.trim() : "";
  }, [textoBusca]);

  useEffect(() => {
    if (textoPesquisa === "" && carregamentoInicial) {
      const debounce = setTimeout(() => {
        setFiltroAdicionalTabela(
          new FiltroAdicionalTabela(
            ordemSelecionada,
            mesAno,
            empresaSelecionada,
            clienteSelecionado,
            unidadeSelecionada
          )
        );
      }, 1000);

      return () => clearTimeout(debounce);
    }
  }, [textoPesquisa]);

  const onClickSearch = () => {
    setFiltroAdicionalTabela(
      new FiltroAdicionalTabela(
        ordemSelecionada,
        mesAno,
        empresaSelecionada,
        clienteSelecionado,
        unidadeSelecionada,
        textoPesquisa
      )
    );
  };

  return (
    <Grid container>
      <Grid
        container
        spacing={3}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={8} sm={5}>
          <MaterialInputBusca
            type="text"
            id="textoBusca"
            name="textoBusca"
            label="Buscar"
            renderIconShowHide
            searchAdornment
            permiteValorBranco
            defaultValue={textoBusca ?? ""}
            ref={register}
          />
        </Grid>
        <Grid item xs={4} sm={2}>
          <Botao
            type="submit"
            onClick={onClickSearch}
            label="Buscar"
            className={classes.button}
            disabled={!textoPesquisa}
          />
        </Grid>

        {!possuiPermissaoCalcularCustos && <Grid sm={2} />}

        {possuiPermissaoCalcularCustos && (
          <Grid item sm={3} xs={6}>
            <Loader loading={carregandoBtnCalculoCusto}>
              <Botao
                type="button"
                label="Executar calculo custo"
                className={classes.button}
                onClick={() => executarCalculoCusto()}
              />
            </Loader>
          </Grid>
        )}
        <Grid item sm={possuiPermissaoCalcularCustos ? 2 : 3} xs={6}>
          <InputData
            type="text"
            id="mesAnoBusca"
            name="mesAnoBusca"
            format="MMM/yyyy"
            customValue={mesAno}
            onChange={(data) => setMesAno(data)}
            views={["month", "year"]}
            className={classes}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} className="mt-4">
        {exibirTabela && (
          <TabelaPaginada
            ref={refTabela}
            onChangeFiltrosTabela={onChangeFiltrosTabela}
            colunas={colunas}
            pesquisar={null}
            filtrosAdicionais={filtroAdicionalTabela}
            paginaAtual={1}
            paginationPerPage={20}
            noHeader
          />
        )}
      </Grid>
    </Grid>
  );
};

export default PreFaturamento;
