import styled from "styled-components";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  titulo: {
    color: "rgba(0, 0, 0, 0.87)"
  },
  subtitulo: {
    "& h5": { fontWeight: "700 !important" }
  },
  label: {
    display: "flex",
    alignItems: "center",
    marginBottom: "5px",
    "& p": {
      marginBottom: 0,
      marginRight: 20,
      fontSize: 18
    }
  },
  salvar: {
    display: "flex",
    justifyContent: "flex-end"
  },
  botao: {
    width: "auto !important",
    padding: "5px 50px"
  },
  semDados: {
    display: "flex",
    justifyContent: "center",
    height: "300px",
    alignItems: "center"
  }
});

export const Icone = styled.img`
  width: ${(props) => (props.tamanho ? props.tamanho : "30px")};
  margin: ${(props) => (props.margem ? props.margem : "0")};
`;
