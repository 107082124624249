import { Box } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { useStyles } from "./style";

const MensagemDesconto = ({ desconto }) => {
  const classes = useStyles();
  return (
    <Box
      bgcolor="rgb(245, 245, 247)"
      p={2}
      mb={2}
      borderRadius="8px"
      textAlign="center"
    >
      <Box
        fontSize={{ xs: "22px", lg: "40px" }}
        fontWeight="700"
        color="#332053"
      >
        Com base no gasto mensal informado,
      </Box>
      <Box
        fontSize={{ xs: "22px", lg: "40px" }}
        fontWeight="700"
        color="#332053"
      >
        na LUZ você têm
      </Box>
      <Box
        fontSize={{ xs: "22px", lg: "40px" }}
        fontWeight="700"
        className={classes.text}
      >
        {`até ${desconto} % de desconto`}
      </Box>
      <Box
        fontSize={{ xs: "12px", lg: "16px" }}
        fontWeight="700"
        color="#332053"
      >
        na fatura de energia todo mês
      </Box>
      <Box
        fontSize={{ xs: "22px", lg: "40px" }}
        fontWeight="700"
        className={classes.text}
      >
        Zero custos ou taxas extras
      </Box>
      <Box
        fontSize={{ xs: "12px", lg: "16px" }}
        fontWeight="700"
        color="#332053"
      >
        Sem taxa de adesão ou mensalidade
      </Box>
    </Box>
  );
};

MensagemDesconto.propTypes = {
  desconto: PropTypes.string
};
MensagemDesconto.defaultProps = {
  desconto: ""
};

export default MensagemDesconto;
