import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const api = () => obterApi(MICROSERVICO.MANAGEMENT);

export const listarOrganizarPor = () => {
  const ordernacoes = [
    {
      key: "AB",
      label: "Aberto"
    },
    {
      key: "VA",
      label: "Validado"
    },
    {
      key: "AJ",
      label: "Ajustado"
    },
    {
      key: "RE",
      label: "Rejeitada"
    },
    {
      key: "CA",
      label: "Cancelada"
    },
    {
      key: "AT",
      label: "Atrasado"
    }
  ];

  return ordernacoes;
};

export const ordenacaoPorColuna = () => {
  const sortOrder = [
    {
      key: "0",
      asc: "0",
      desc: "1",
      label: "Nome"
    },
    {
      key: "6",
      asc: "6",
      desc: "7",
      label: "Prazo"
    }
  ];

  return sortOrder;
};

// Listar Cotações
export const listarCotacoes = async () => {
  return api().get("v1/CotacaoInicial");
};

// Listar Cotações Paginadas
export const listarPaginado = async (filtro) => {
  const filtroQueryString = new URLSearchParams(filtro).toString();

  return api().get(`v1/ValidarCotacoes?${filtroQueryString}`);
};
