import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const apiCorreios = () => obterApi(MICROSERVICO.MEASUREMENT);

const api = () => obterApi(MICROSERVICO.INTEGRATION);

// Buscar CEP
export const buscaCep = async (cep) => {
  return api().post("v1/correios", { CEP: cep });
};

export const uploadBaseDeDados = async (arquivos) => {

  const formdata = new FormData();

  arquivos.forEach((arquivo) => {
    formdata.append("files", arquivo.file);
  });
  
  return apiCorreios().post("v1/correios", formdata);
};

export const obterCepCobertura = async (cep) => {
  return apiCorreios().get(`v1/correios?cep=${cep}`);
};

