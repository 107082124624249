import { makeStyles } from '@mui/styles';
import { createTheme } from "@mui/material/styles";

const theme = createTheme();

export const useStyles = makeStyles(() => ({
  container: {
    margin: "0",
    width: "100%",
    "& .MuiInputBase-root": {
      height: "46px"
    },
    "& .MuiFormLabel-root": {
      lineHeight: "1"
    },
  },
  button: {
    fontSize: '18px',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100% !important",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
