import {
  excluirLeadClienteTestemunha,
  salvarLeadClienteTestemunha
} from "servicos/leadClienteTestemunhaServico";
import {
  salvarLeadUnidadeConsumidora,
  salvarLeadPlanoUnidade
} from "../../../servicos/leadUnidadesServico";
import RetornoEndpointDto from "../../../global/dto/retornoEndpointDto";
import { store } from "../../../global/redux";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";
import { obterCepCobertura } from "../../../servicos/correiosServico";
import { buscarDadosCadastraisPorCnpj } from "../../../servicos/dadosCadastraisCnpjServico";
import { salvarLeadCliente } from "../../../servicos/leadClientesServico";
import { obterArvoreGerencialAltoNivel } from "../../../servicos/arvoresGerenciaisServico";
import { buscarPorId } from "../../../servicos/colaboradoresServico";
import { criarTermoAdesaoAcl } from "../../../servicos/termoAdesaoServico";
import { buscarDistribuidoraNomeOuCnpj } from "../../../servicos/distribuidorasServico";
import {
  salvarCotacaoInicialAcl,
  obterLeadClientePorId,
  atualizarCotacao,
  obterCotacao,
  salvarPrecosDetalhe
} from "../../../servicos/cotacaoInicialServico";
import { criarPLanosCotacaoInicial } from "../../../servicos/planosServico";
import { criarLeadSalesForceServico } from "../../../servicos/preLeadSalesforceServico";
import RadioDto from "../../../componentes/inputRadioGroup/radioDto";

export default class DadosEmpresaACLHelper {
  static async obterCepCobertura(cepCobertura, setCarregandoCep) {
    try {
      setCarregandoCep(true);
      const resultado = await obterCepCobertura(cepCobertura);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro(
          "Obter cobertura única não encontrados!"
        );

      return RetornoEndpointDto.Sucesso(
        "Obter cobertura única com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu uma falha ao obter cobertura única não encontrados!"
      );
    } finally {
      setCarregandoCep(false);
    }
  }

  static async criarLeadCliente(body, handleLoading, dispatch, cotacaoId) {
    try {
      dispatch(handleLoading(true));
      const resultado = await salvarLeadCliente(body, cotacaoId);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Lead cliente não encontrado!");

      return RetornoEndpointDto.Sucesso(
        "Lead cliente criado com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Erro ao criar lead cliente");
    } finally {
      dispatch(handleLoading(false));
    }
  }

  static async criarLeadUnidadeConsumidora(
    body,
    handleLoading,
    dispatch,
    cotacaoId
  ) {
    try {
      dispatch(handleLoading(true));
      const resultado = await salvarLeadUnidadeConsumidora(body, cotacaoId);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro(
          "Lead unidade consumidora não encontrados!"
        );

      return RetornoEndpointDto.Sucesso(
        "Lead uniddade consumidora criado com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Erro ao criar lead unidade consumidora");
    } finally {
      dispatch(handleLoading(false));
    }
  }

  static async criarLeadPlanoselecionado(
    body,
    handleLoading,
    dispatch,
    cotacaoId
  ) {
    try {
      dispatch(handleLoading(true));
      const resultado = await salvarLeadPlanoUnidade(body, cotacaoId);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro(
          "Lead plano selecionado não encontrados!"
        );

      return RetornoEndpointDto.Sucesso(
        "Lead plano selecionado criado com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Erro ao criar lead plano selecionado");
    } finally {
      dispatch(handleLoading(false));
    }
  }

  static async obterArvoreGerencialAltoNivel(colaboradorId, empresaId) {
    try {
      const resultado = await obterArvoreGerencialAltoNivel(
        colaboradorId,
        empresaId
      );
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Arvore gerencial não encontrados!");

      return RetornoEndpointDto.Sucesso(
        "Arvore gerencial encontrada com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Erro ao encontrar árvore gerencial!");
    }
  }

  static async buscarDadosCadastraisPorCnpj(setCarregandoNomeFantasia, cnpj) {
    try {
      setCarregandoNomeFantasia(true);
      const resultado = await buscarDadosCadastraisPorCnpj(cnpj);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Cnpj não encontrados!");

      return RetornoEndpointDto.Sucesso(
        "Cnpj obtido com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Cnpj não encontrados!");
    } finally {
      setCarregandoNomeFantasia(false);
    }
  }

  static async obterColaboradorId(colaboradorId) {
    try {
      const resultado = await buscarPorId(colaboradorId ?? 0);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Colaborador por id não encontrados!");

      return RetornoEndpointDto.Sucesso(
        "Colaborador por id obtido com sucesso",
        resultado.data
      );
    } catch (error) {
      if (error?.response?.status === 403) {
        return RetornoEndpointDto.Erro("403");
      }
      return RetornoEndpointDto.Erro(
        `Não foi  encontrado colaborador id ${colaboradorId}`
      );
    }
  }

  static async criarTermoAdesaoAcl(body, handleLoading, dispatch) {
    try {
      dispatch(handleLoading(true));
      const resultado = await criarTermoAdesaoAcl(body);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Termo de adesão não encontrados!");

      return RetornoEndpointDto.Sucesso(
        "Termo de adesão criado com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Erro ao criar termo de adesão");
    } finally {
      dispatch(handleLoading(false));
    }
  }

  static async obterDistribuidoraCnpjOuNome(cnpj) {
    try {
      const resultado = await buscarDistribuidoraNomeOuCnpj(cnpj);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro(
          "Obter distribuídora única não encontrados!"
        );

      return RetornoEndpointDto.Sucesso(
        "Obter distribuídora única com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Obter distribuídora única não encontrados!"
      );
    }
  }

  static async criarCotacaoInicial(body, handleLoading, dispatch, cotacaoId) {
    try {
      dispatch(handleLoading(true));
      const resultado = await salvarCotacaoInicialAcl(body, cotacaoId);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Cotação Inicial salva não encontrada!");

      return RetornoEndpointDto.Sucesso(
        "Cotação Inicial salva com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Não foi possivel salvar a cotação");
    } finally {
      dispatch(handleLoading(false));
    }
  }

  static async criarPLanosCotacaoInicial(body, handleLoading, dispatch) {
    try {
      dispatch(handleLoading(true));
      const resultado = await criarPLanosCotacaoInicial(
        body,
        handleLoading,
        dispatch
      );

      if (resultado.status === 204)
        return RetornoEndpointDto.Erro(
          "Planos Cotação Inicial não encontrada!"
        );

      return RetornoEndpointDto.Sucesso(
        "Planos Cotação Inicial salva com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Não foi possivel salvar planos cotação");
    } finally {
      dispatch(handleLoading(false));
    }
  }

  static async criarLeadSalesForceServico(body, id, handleLoading, dispatch) {
    try {
      dispatch(handleLoading(true));
      const resultado = await criarLeadSalesForceServico(body, id);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Lead sales force não encontrados!");

      return RetornoEndpointDto.Sucesso(
        "Lead sales force criado com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Lead sales force não foi criado!");
    } finally {
      dispatch(handleLoading(false));
    }
  }

  static async obterLeadClientePorId(cotacaoId) {
    try {
      const resultado = await obterLeadClientePorId(cotacaoId);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Lead cliente não encontrados!");

      return RetornoEndpointDto.Sucesso(
        "Lead cliente obtido com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Erro ao obter lead cliente!");
    }
  }

  static async atualizarCotacao(id, body, handleLoading, dispatch) {
    try {
      dispatch(handleLoading(true));
      const resultado = await atualizarCotacao(id, body);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Cotação atualizada não encontrados!");

      return RetornoEndpointDto.Sucesso(
        "Cotação atualizada com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Erro ao atualizar cotação");
    } finally {
      dispatch(handleLoading(false));
    }
  }

  static async obterCotacao(model, handleLoading, dispatch) {
    try {
      dispatch(handleLoading(true));
      const resultado = await obterCotacao(model);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("CotacaoEncontrada");
      return RetornoEndpointDto.Sucesso(
        "Cotação obtido com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Erro ao obter cotação!");
    } finally {
      dispatch(handleLoading(false));
    }
  }

  static async criarLeadClienteTestemunha(body, handleLoading, dispatch, id) {
    try {
      dispatch(handleLoading(true));
      const resultado = await salvarLeadClienteTestemunha(id, body);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Lead testemunha não encontrado!");

      return RetornoEndpointDto.Sucesso(
        "Lead testemunha criado com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Erro ao criar lead testemunha");
    } finally {
      dispatch(handleLoading(false));
    }
  }

  static obterGarantia() {
    return [
      new RadioDto("Não se aplica", "Não se aplica"),
      new RadioDto("Garantia exigida", "Garantia exigida")
    ];
  }

  static async excluirLeadClienteTestemunha(id, handleLoading, dispatch) {
    try {
      dispatch(handleLoading(true));
      const resultado = await excluirLeadClienteTestemunha(id);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Lead testemunha não encontrado!");

      return RetornoEndpointDto.Sucesso(
        "Lead testemunha excluido com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Erro ao excluir lead testemunha");
    } finally {
      dispatch(handleLoading(false));
    }
  }

  static async criarPrecosDetalhe(body, cotacaoId) {
    try {
      const resultado = await salvarPrecosDetalhe(body, cotacaoId);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Preços detalhe salva não encontrado!");

      return RetornoEndpointDto.Sucesso(
        "Preços detalhe salvao com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Não foi possivel salvar os preços");
    }
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }

  static exibirSucesso(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem
      })
    );
  }
}
