/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import shortid from "shortid";

// Componentes
import TuneIcon from "@mui/icons-material/Tune";
import { Box, Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CustomLoader from "componentes/customLoader";
import { InterfaceDTO } from "global/dto/interfacesDto";
import { handleLoading } from "global/redux/modulos/loader/actions";
import { usuarioPossuiFuncionalidade } from "servicos/funcionalidadesServico";
import { mesesParaAnos } from "servicos/utils";
import theme from "themes";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import formatarValor from "global/utils/formatarValor";
import { TabelaPaginadaMobile } from "../../../componentes";
import BotaoFiltroOrdenacao from "../../../componentes/botaoFiltroOrdenar";
import MaterialInputBusca from "../../../componentes/inputBusca";
import Loader from "../../../componentes/loader";
import SelectArredondado from "../../../componentes/selectArredondado";
import TabelaPaginada from "../../../componentes/tabelaPaginada";
import Coluna from "../../../componentes/tabelaPaginada/colunas/coluna";
import ColunaCampoDropDown from "../../../componentes/tabelaPaginada/colunas/colunaCampoDropDown";
import ColunaComponentePersonalizado from "../../../componentes/tabelaPaginada/colunas/colunaComponentePersonalizado";
import ResultadoPaginadoDto from "../../../componentes/tabelaPaginada/resultadoPaginadoDto";
import ColunaMultiplosBotoes from "./componentes/colunaMultiplosBotoes";
import ColunaMultiplosBotoesMobile from "./componentes/colunaMultiplosBotoes/mobile";
import ItemListaExpansivelResponsivo from "./componentes/itemListaExpansivelResponsiva";
import ListagemCotacaoHelper from "./helper";
import ModalLuz from "../../../componentes/modalLuz";

// Redux
import { store } from "../../../global/redux";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";

// DTO
import { RotasDTO } from "../../../global/rotas/rotasUrlDto";
import FiltroAdicionalTabela from "./dto/filtroAdicionalTabela";
import FiltroDto from "./dto/filtroDto";
import ListagemCotacaoDto from "./dto/listagemCotacaoDto";
import enumeradorBotao from "./enumeradores/enumeradorBotao";

// Constantes
import statusCotacao from "../constantes/constanteStatusCotacao";

// Styles
import { useStyles } from "./style";

// Serviços
import { listarOfficersPorArvoreGerencial } from "../../../servicos/colaboradoresServico";
import {
  listarFiltrarPor,
  listarOrganizarPor,
  ordenacaoPorColuna,
  updatePlano
} from "../../../servicos/listagemCotacoesServico";

// Útils
import ModalMensagemErro from "../../../componentes/ModalMensagemErro";
import {
  desabilitarCliente,
  desabilitarEmpresa,
  desabilitarUnidade
} from "../../../global/redux/modulos/usuario/actions";
import ComponenteMultiBotoesHelper from "./componentes/colunaMultiplosBotoes/helper";

const ListagemCotacoes = () => {
  const [ordemSelecionada, setOrdemSelecionada] = useState();
  const [filtroSelecionado, setFiltroSelecionado] = useState();
  const [ordemColunaSelecionada, setOrdemColunaSelecionada] = useState();
  const [filtroAdicionalTabela, setFiltroAdicionalTabela] = useState({});
  const [valorOrdenacao, setValorOrdenacao] = useState();
  const [mensagemModal, setMensagemModal] = useState("");
  const [modalAcao, setModalAcao] = useState(false);
  const [exibirModalPrecos, setExibirModalPrecos] = useState(false);
  const [modalPrecosDados, setModalPrecosDados] = useState([]);
  const [campoTextoSelecionado, setCampoTextoSelecionado] = useState(null);
  const [dadosListagem, setDadosListagem] = useState({});
  const [modalParceiro, setModalParceiro] = useState(false);

  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const themes = useTheme();
  const mobile = useMediaQuery(themes.breakpoints.down("lg"));
  const refTabela = useRef(null);

  const { register, control } = useForm({
    reValidateMode: "onSubmit"
  });

  const textoBusca = useWatch({
    control,
    name: "textoBusca",
    defaultValue: ""
  });

  useEffect(() => {
    store.dispatch(desabilitarCliente(true));
    store.dispatch(desabilitarUnidade(true));

    return () => {
      store.dispatch(desabilitarEmpresa(false));
      store.dispatch(desabilitarCliente(false));
      store.dispatch(desabilitarUnidade(false));
    };
  }, []);

  const [listaOfficer, setListaOfficer] = useState([]);
  const [carregandoOfficer, setCarregandoOfficer] = useState(false);

  const obterOfficer = async (empresa) => {
    try {
      setCarregandoOfficer(true);
      const lista = await listarOfficersPorArvoreGerencial(empresa ?? 0);
      let data = lista?.data;
      if (lista.status !== 200 || !lista.data) {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "Officers não encontrados!"
          })
        );
        return;
      }

      // eslint-disable-next-line no-use-before-define
      if (!permissaoAlterarFiltrarOfficer)
        // eslint-disable-next-line no-use-before-define
        data = data.filter((d) => d.id === colaboradorId);
      setListaOfficer(data);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    } finally {
      setCarregandoOfficer(false);
    }
  };

  const onChangeSelecionarOfficer = (event) => {
    // eslint-disable-next-line no-use-before-define
    setColaboradorId(event);
  };

  const rotas = useSelector((state) => state.rotas);
  const usuario = useSelector((state) => state.usuario);
  const { menu } = usuario;
  const permissaoAlterarFiltrarOfficer = usuarioPossuiFuncionalidade(
    menu,
    rotas.rotaAtual?.caminho,
    RotasDTO.Cotacoes,
    InterfaceDTO.PodeAlterarFiltrarOfficer
  );
  const [colaboradorId, setColaboradorId] = useState(
    usuario?.usuario?.colaboradorId
  );
  const [filtroPersonalizado, setFiltroPersonalizado] = useState({
    ...(colaboradorId > -1 && { colaboradorId })
  });

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!colaboradorId) return null;

    setFiltroPersonalizado({
      ...(colaboradorId > -1 && { colaboradorId })
    });
  }, [colaboradorId]);

  const { empresaSelecionada } = usuario;

  useEffect(() => {
    obterOfficer(empresaSelecionada);
  }, [permissaoAlterarFiltrarOfficer, empresaSelecionada]);

  const onChangeSelecionarPlano = async (event, params) => {
    const idPlano = Number(event);

    const idPlanoSelecionado =
      params?.plano?.find((p) => Number(p.id) === Number(idPlano))
        ?.planoGeradoId ?? 0;

    if (params.statusCotacao !== statusCotacao.Aberta) return;

    await updatePlano(idPlanoSelecionado, params);

    if (refTabela?.current) refTabela.current.obterDadosPaginados();
  };

  const onClickEditar = (id) => {
    history.push(`${RotasDTO.CotacaoACL}/${id}`);
  };

  const onClickAbrirModal = async (id) => {
    try {
      dispatch(handleLoading(true));
      const {
        data,
        sucesso,
        mensagem
      } = await ListagemCotacaoHelper.listarPrecosDetalhe(id);

      if (!sucesso) {
        dispatch(handleLoading(false));
        ListagemCotacaoHelper.exibirErro(
          mensagem || "Erro interno, entre em contato com o suporte!"
        );
        return;
      }

      if (!data || data?.length === 0) {
        dispatch(handleLoading(false));
        ListagemCotacaoHelper.exibirErro("Preços não encontrados!");
        return;
      }

      const dadosFormatados = Object.groupBy(data, ({ ganhoMin }) => ganhoMin);
      const dadosParaArray = Object.keys(dadosFormatados)?.map((key) => [
        key,
        dadosFormatados[key]
      ]);
      setModalPrecosDados(dadosParaArray || []);
      setExibirModalPrecos(true);
    } catch (error) {
      dispatch(handleLoading(false));
      ListagemCotacaoHelper.exibirErro(
        "Erro interno, entre em contato com o suporte!"
      );
    } finally {
      dispatch(handleLoading(false));
    }
  };

  // eslint-disable-next-line consistent-return
  const onClickPDF = async (id) => {
    // dispatch(handleLoading(true));
    const {
      sucesso,
      mensagem
    } = await ComponenteMultiBotoesHelper.obterDadosRelatorio(id);

    if (!sucesso) {
      // dispatch(handleLoading(false));
      return ComponenteMultiBotoesHelper.exibirErro(mensagem);
    }

    // setDadosPdf(data);
    // setPrinting(true);

    // baixarPdf(data);
  };

  const onClickContratar = async (id) => {
    dispatch(handleLoading(true));
    try {
      const resultado = await ListagemCotacaoHelper.contratarCotacao(id);
      dispatch(handleLoading(false));
      if (!resultado.sucesso) {
        ListagemCotacaoHelper.exibirErro(resultado.mensagem);
        return;
      }

      if (resultado.sucesso) {
        ListagemCotacaoHelper.exibirSucesso("Cotação fechada com sucesso");
      }
    } catch (error) {
      dispatch(handleLoading(false));
      ListagemCotacaoHelper.exibirErro(
        "Erro interno, entre em contato com o suporte!"
      );
    }
  };

  const liberaPropostaContrato = async () => {
    setModalParceiro(false);
    const model = {
      liberaPropostaContrato:
        dadosListagem?.liberaPropostaContrato === null ||
        dadosListagem?.liberaPropostaContrato === "N"
          ? "S"
          : "N"
    };

    const {
      sucesso,
      mensagem
    } = await ListagemCotacaoHelper.liberaPropostaContrato(
      dadosListagem?.id,
      model
    );

    if (!sucesso) {
      ListagemCotacaoHelper.exibirErro(mensagem);
      return;
    }

    if (refTabela?.current) refTabela.current.obterDadosPaginados();
  };

  const onClickParceiro = async (params) => {
    setModalParceiro(true);
    setDadosListagem({ ...params });
  };

  const onClickBotao = (tipo, parametros) => {
    switch (tipo) {
      case enumeradorBotao.Edicao:
        if (parametros.statusCotacao !== "AN") {
          onClickEditar(parametros.id);
        }
        break;
      case enumeradorBotao.PDF:
        if (parametros.statusCotacao !== "AN") {
          onClickPDF(parametros.id);
        }
        break;
      case enumeradorBotao.Contrato:
        if (parametros.statusCotacao === "AT") {
          onClickContratar(parametros.id);
        }
        break;
      case enumeradorBotao.Modal:
        onClickAbrirModal(parametros.id);
        break;
      case enumeradorBotao.Parceiro:
        onClickParceiro(parametros);
        break;
      default:
        break;
    }
  };

  const permissaoContratarCotacao = usuarioPossuiFuncionalidade(
    menu,
    rotas.rotaAtual.caminho,
    RotasDTO.Cotacoes,
    InterfaceDTO.PermissaoContratarCotacao
  );

  const ColunaMobile = ({ parametros }) => {
    const botaoDesabilitado =
      parametros?.status === "Expirada" ||
      parametros?.status === "Fechada" ||
      parametros?.status === "Cancelada" ||
      parametros?.status === "Rejeitada" ||
      !parametros?.cotacaoSelecionada;
    return (
      <ItemListaExpansivelResponsivo
        botaoDesabilitado={botaoDesabilitado}
        key={shortid.generate()}
        nomeFantasia={parametros?.nomeFantasia}
        cnpj={parametros?.cnpj}
        status={parametros?.status}
        validade={parametros?.dataValidade}
        plano={parametros?.plano[parametros?.cotacaoSelecionada - 1]?.descricao}
        color={theme.color.buttonColor}
        onClickEditar={() => onClickEditar(parametros?.id)}
        onClickPDF={ColunaMultiplosBotoesMobile(parametros)}
        onClickContratar={() =>
          !permissaoContratarCotacao ? null : onClickContratar(parametros?.id)
        }
        numeroInstalacao={parametros?.numeroInstalacao}
        onClickParceiro={() => onClickParceiro(parametros)}
        usuarioCargoId={parametros?.usuarioCargoId}
        liberaPropostaContrato={parametros?.liberaPropostaContrato}
        cargoId={parametros?.cargoId}
      />
    );
  };

  const colunas = !mobile
    ? [
        new Coluna("nomeFantasia", "Nome Fantasia", false, "26.3%", "26.3%"),
        new Coluna("cnpj", "CNPJ", false, "15.7%", "15.7%"),
        new Coluna(
          "numeroInstalacao",
          "Número de Instalação",
          false,
          "15.7%",
          "15.7%"
        ),
        new ColunaCampoDropDown(
          "plano",
          "Plano",
          false,
          onChangeSelecionarPlano,
          "20%",
          "20%"
        ),
        new Coluna("dataCotacao", "Data cotação", false, "12%"),
        new Coluna("dataValidade", "Validade cotação", false, "12%"),
        new Coluna("status", "Status", false, "10%"),
        new ColunaComponentePersonalizado(
          "opcoes",
          "Opções",
          ColunaMultiplosBotoes,
          onClickBotao,
          false,
          true,
          "12%",
          "12%",
          "pl-2"
        )
      ]
    : [
        new ColunaComponentePersonalizado(
          "nome",
          "",
          ColunaMobile,
          null,
          false,
          false,
          null,
          null
        )
      ];

  const obterStatusDescricao = (status) => {
    switch (status) {
      case statusCotacao.Aberta:
        return "Aberta";
      case statusCotacao.Fechada:
        return "Fechada";
      case statusCotacao.Expirada:
        return "Expirada";
      case statusCotacao.Cancelada:
        return "Cancelada";
      case statusCotacao.Rejeitada:
        return "Rejeitada";
      case statusCotacao.Anulada:
        return "Anulada";
      default:
        return "";
    }
  };

  const obterListagem = async (parametros) => {
    try {
      if (
        !parametros?.filtroPersonalizado?.colaboradorId ||
        !parametros?.filtroAdicional?.empresaId
      )
        return null;

      const filtro = new FiltroDto(
        parametros.tamanhoPagina,
        parametros.pagina,
        parametros?.campoTextoSelecionado === "CnpjCpf"
          ? parametros?.pesquisar?.replace(/[^0-9]+/g, "")
          : parametros?.pesquisar?.trim(),
        parametros.filtroAdicional.ordenacao ?? "",
        [parametros.filtroAdicional.filtro] ?? "",
        {
          nome: "colaboradorId",
          valor: parametros?.filtroPersonalizado?.colaboradorId ?? ""
        },
        parametros.filtroAdicional.sortOrder ?? "",
        parametros?.filtroAdicional?.empresaId ?? "",
        true,
        parametros?.campoTextoSelecionado
      );

      const resultado = await ListagemCotacaoHelper.listarCotacoes(filtro);
      const planos = (await ListagemCotacaoHelper.listarTodosTipos()).data
        ?.tipoPlanoCotacoes;

      if (!resultado.sucesso) {
        setModalAcao(true);
        setMensagemModal(resultado?.mensagem);

        return new ResultadoPaginadoDto([], 1, 0, 0);
      }

      if (resultado.data.cotacoesIniciais?.length === 0 ?? true)
        return new ResultadoPaginadoDto([], 1, 0, 0);

      if (resultado.data === "") return new ResultadoPaginadoDto([], 1, 0, 0);

      const resLinhas = resultado?.data?.cotacoesInicias?.map((res) => {
        const planoSelecionadoId = res?.planosGerados?.find(
          (plano) => plano?.cotacaoSelecionada
        );

        const dataCotacao = window
          .moment(res?.dataCotacao)
          .format("DD-MM-YYYY");
        const dataValidade = window
          .moment(res?.dataValidade)
          .format("DD-MM-YYYY");
        const cotacaoStatus = obterStatusDescricao(res.status);

        const planosFiltrados = planos
          .filter(
            (item) =>
              res?.planosGerados?.findIndex(
                (plano) => plano.tipoPlano === item.id
              ) > -1
          )
          .map((plano) => {
            const planoGerado = res?.planosGerados?.find(
              (itemPlano) => Number(itemPlano.tipoPlano) === Number(plano.id)
            );
            const plan = {
              descricao: `Desconto Garantido ${mesesParaAnos(
                plano?.periodoMeses
              )} ANOS`,
              id: plano?.id,
              periodoMeses: plano?.periodoMeses,
              tipoPlano: plano?.tipoPlano
            };
            return {
              ...plan,
              planoGeradoId: planoGerado?.id
            };
          });

        const cnpjFormatado = res?.cnpj.replace(
          /(\d{2})?(\d{3})?(\d{3})?(\d{4})?(\d{2})/,
          "$1.$2.$3/$4-$5"
        );

        return new ListagemCotacaoDto(
          res?.id,
          res?.nomeFantasia,
          cnpjFormatado,
          res?.numeroInstalacao,
          planosFiltrados,
          dataCotacao,
          dataValidade,
          cotacaoStatus,
          true,
          planoSelecionadoId?.tipoPlano,
          res?.status,
          usuario?.usuario?.cargoId,
          res?.liberaPropostaContrato,
          res?.cargoId
        );
      });

      if (!resLinhas) return false;

      return new ResultadoPaginadoDto(
        resLinhas,
        resultado.data.paginaAtual,
        resultado.data.totalItens,
        resultado.data.totalPaginas
      );
    } catch (error) {
      console.info(error);
    }
  };

  const onChangeFiltrosTabela = useCallback(
    async (parametros) => {
      try {
        if (
          !permissaoAlterarFiltrarOfficer &&
          (!colaboradorId || colaboradorId === -1)
        )
          return null;

        return obterListagem({
          tamanhoPagina: parametros.totalPagina,
          pagina: parametros.pagina,
          pesquisar: parametros.pesquisar,
          filtroAdicional: new FiltroAdicionalTabela(
            parametros.filtrosAdicionais?.ordenacao,
            parametros.filtrosAdicionais?.filtro,
            parametros.filtrosAdicionais?.sortOrder,
            parametros.filtrosAdicionais?.empresaId
          ),
          filtroPersonalizado: parametros?.filtroPersonalizado,
          campoTextoSelecionado: campoTextoSelecionado ?? ""
        });
      } catch (error) {
        return null;
      }
    },
    [permissaoAlterarFiltrarOfficer, colaboradorId, campoTextoSelecionado]
  );

  const handleAlterarFiltroLateral = () => {
    setFiltroAdicionalTabela(
      new FiltroAdicionalTabela(
        ordemSelecionada,
        filtroSelecionado,
        valorOrdenacao,
        empresaSelecionada
      )
    );
  };

  useEffect(() => {
    if (empresaSelecionada) {
      handleAlterarFiltroLateral();
    }
  }, [empresaSelecionada]);

  const handleClickAtivarFiltro = () => {
    setFiltroAdicionalTabela(
      new FiltroAdicionalTabela(
        ordemSelecionada,
        filtroSelecionado,
        valorOrdenacao,
        empresaSelecionada
      )
    );
  };

  const handleClickOrdemSelecionada = (event) => {
    const val = ordemSelecionada === event ? "" : event;
    setOrdemSelecionada(val);
  };

  const handleClickFiltroSelecionado = (event) => {
    const val = filtroSelecionado === event ? "" : event;
    setFiltroSelecionado(val);
  };

  const handleClickSortOrderSelecionado = (valor, idCampoSelecionado) => {
    const val =
      ordemColunaSelecionada === idCampoSelecionado && valor === ""
        ? ""
        : idCampoSelecionado;
    setOrdemColunaSelecionada(val);
    setValorOrdenacao(valor);
  };

  const handleMensagemModal = (mensagem) => {
    return (
      <Box component="div" fontWeight="700" textAlign="center">
        {mensagem}
      </Box>
    );
  };

  const verificarDesconto = (meses) => {
    if (meses === "36") return "DESCONTOS 3 ANOS";
    if (meses === "48") return "DESCONTOS 4 ANOS";
    if (meses === "60") return "DESCONTOS 5 ANOS";

    return meses;
  };

  const handleMensagemModalPrecos = () => {
    return (
      <Box component="div">
        {modalPrecosDados?.map((preco) => (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography className={classes.tituloAccordion}>
                {verificarDesconto(preco[0])}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {preco[1] &&
                preco[1].map((precoDetalhe) => (
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography className={classes.subtituloAccordion}>
                        {precoDetalhe.ano}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <b>adicionalCalculado: </b>
                        {precoDetalhe?.adicionalCalculado
                          ? formatarValor(precoDetalhe?.adicionalCalculado)
                          : 0}
                      </Typography>
                      <Typography>
                        <b>comissao: </b>
                        {precoDetalhe?.comissao
                          ? formatarValor(precoDetalhe?.comissao)
                          : 0}
                      </Typography>
                      <Typography>
                        <b>indiceDataBase: </b>
                        {precoDetalhe?.indiceDataBase
                          ? String(precoDetalhe?.indiceDataBase)
                          : 0}
                      </Typography>
                      <Typography>
                        <b>indiceMigracao: </b>
                        {precoDetalhe?.indiceMigracao
                          ? String(precoDetalhe?.indiceMigracao)
                          : 0}
                      </Typography>
                      <Typography>
                        <b>margemUtilizada: </b>
                        {precoDetalhe?.margemUtilizada
                          ? formatarValor(precoDetalhe?.margemUtilizada)
                          : 0}
                      </Typography>
                      <Typography>
                        <b>mesalidadeCalculado: </b>
                        {precoDetalhe?.mesalidadeCalculado
                          ? formatarValor(precoDetalhe?.mesalidadeCalculado)
                          : 0}
                      </Typography>
                      <Typography>
                        <b>precoFinal: </b>
                        {precoDetalhe?.precoFinal
                          ? formatarValor(precoDetalhe?.precoFinal)
                          : 0}
                      </Typography>
                      <Typography>
                        <b>precoMedio: </b>
                        {precoDetalhe?.precoMedio
                          ? formatarValor(precoDetalhe?.precoMedio)
                          : 0}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    );
  };

  return (
    <Grid container>
      <ModalMensagemErro
        item={modalAcao}
        titulo="Algo deu errado"
        mensagem={handleMensagemModal(mensagemModal)}
        onCancelar={() => {
          setModalAcao(false);
          setMensagemModal("");
        }}
        onConfirmar={() => {}}
        texto="Tentar novamente"
      />
      <ModalLuz
        item={exibirModalPrecos}
        mensagem={handleMensagemModalPrecos()}
        onCancelar={() => {
          setExibirModalPrecos(false);
          setModalPrecosDados([]);
        }}
        footer={false}
      />

      <ModalLuz
        item={modalParceiro}
        mensagem={handleMensagemModal(
          `Deseja ${
            dadosListagem?.liberaPropostaContrato === "N" ||
            dadosListagem?.liberaPropostaContrato === null
              ? "liberar"
              : "bloquear"
          } proposta ou contrato?`
        )}
        onCancelar={() => {
          setModalParceiro(false);
        }}
        onConfirmar={liberaPropostaContrato}
      />
      <Grid item xs={12}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
        >
          <Grid item xs={12} lg={3}>
            <SelectArredondado
              id="CampoTextoSelecionado"
              name="CampoTextoSelecionado"
              label="Filtrar por campo"
              placeholder="Filtrar por campo"
              valueKey="valor"
              valueName="nome"
              dataSource={[
                { nome: "Todos", valor: "" },
                { nome: "Nome", valor: "Nome" },
                { nome: "CNPJ|CPF", valor: "CnpjCpf" },
                { nome: "Número Instalação", valor: "NumeroInstalacao" }
              ]}
              value={campoTextoSelecionado}
              onChange={(e) => {
                setCampoTextoSelecionado(e.target.value);
              }}
              permiteValorBranco
              styleForm={false}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            {campoTextoSelecionado && (
              <MaterialInputBusca
                type="text"
                id="textoBusca"
                name="textoBusca"
                label="Buscar"
                renderIconShowHide
                searchAdornment
                permiteValorBranco
                defaultValue={textoBusca ?? ""}
                ref={register}
              />
            )}
          </Grid>
          <Grid item xs={12} lg={3}>
            <Loader loading={carregandoOfficer}>
              <SelectArredondado
                select
                id="officer"
                name="officer"
                label="Officer"
                placeholder="Officer"
                valueKey="id"
                valueName="nomeColaborador"
                dataSource={listaOfficer}
                value={colaboradorId}
                onChange={(event) => {
                  onChangeSelecionarOfficer(event.target.value);
                }}
                permiteValorBranco
                ref={register}
                marginBottom="0"
                styleForm={false}
              />
            </Loader>
          </Grid>
          <Grid
            item
            component={Grid}
            xs={12}
            lg={3}
            className={classes.itemFiltro}
          >
            <BotaoFiltroOrdenacao
              type="button"
              color={theme.color.secondaryBorderColor}
              background="transparent"
              label="Filtrar / Ordernar"
              icon={<TuneIcon />}
              className={classes.buttonFiltro}
              ordenacao={listarOrganizarPor()}
              ordenacaoColuna={ordenacaoPorColuna()}
              ordenador="Ordenação"
              ordenadorUm="Status"
              ordenadorDois="Planos"
              ordemSelecionada={ordemSelecionada}
              filtros={listarFiltrarPor()}
              filtrosSelecionados={filtroSelecionado}
              ordemColunaSelecionada={ordemColunaSelecionada}
              onClickOrdenacao={handleClickOrdemSelecionada}
              onClickFiltro={handleClickFiltroSelecionado}
              onClickSortOrder={handleClickSortOrderSelecionado}
              onClickAtivarAgora={handleClickAtivarFiltro}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={`${classes.tabela} mt-4`}>
        {!mobile ? (
          <CustomLoader>
            <TabelaPaginada
              ref={refTabela}
              onChangeFiltrosTabela={onChangeFiltrosTabela}
              colunas={colunas}
              pesquisar={textoBusca}
              filtrosAdicionais={filtroAdicionalTabela}
              filtroPersonalizado={filtroPersonalizado}
              paginaAtual={1}
              paginationPerPage={20}
              noHeader
            />
          </CustomLoader>
        ) : (
          <CustomLoader>
            <TabelaPaginadaMobile
              className={classes.fundoCelulaTransparente}
              ref={refTabela}
              pesquisar={textoBusca}
              onChangeFiltrosTabela={onChangeFiltrosTabela}
              filtrosAdicionais={filtroAdicionalTabela}
              filtroPersonalizado={filtroPersonalizado}
              linhasTabela={8}
              colunas={colunas}
              paginaAtual={1}
              noHeader
            />
          </CustomLoader>
        )}
      </Grid>
    </Grid>
  );
};

export default ListagemCotacoes;
