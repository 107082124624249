import React, { useState, useCallback, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";

// Utils
import { cnpj } from "cpf-cnpj-validator";

// Form Hooks
import { useForm } from "react-hook-form";

// Componentes
import { Card, Divider, Grid } from "@mui/material";
import Loader from "../../../../componentes/loader";
import Botao from "../../../../componentes/botao";
import MaterialInputTexto from "../../../../componentes/inputTexto/materialInput";
import MaterialSwitch from "../../../../componentes/switch";
import InputHora from "../../../../componentes/inputTextoHora";
import InputData from "../../../../componentes/inputTextoData";
import BotaoRetornarListagem from "../../../../componentes/botaoRetornarListagem";
import MaterialInputMascara from "../../../../componentes/inputTextoMascara";
import ModalConfirmacao from "../../../../componentes/modalConfirmacao";
import SelectArredondado from "../../../../componentes/selectArredondado";

// Serviços
import {
  salvarDistribuidora,
  buscarPorId,
  BuscarPorCnpjOuNomeFantasia
} from "../../../../servicos/distribuidorasServico";
import { buscarDadosCadastraisPorCnpj } from "../../../../servicos/dadosCadastraisCnpjServico";
import { listarSubmercados } from "../../../../servicos/submercadoServico";

// Rotas
import { RotasDTO } from "../../../../global/rotas/rotasUrlDto";

// Styles
import { useStyles } from "./style";

// Redux
import { store } from "../../../../global/redux";
import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";
import {
  selecionarCliente,
  selecionarEmpresa,
  selecionarUnidade
} from "../../../../global/redux/modulos/usuario/actions";

const DistribuidoraCadastro = () => {
  const { id } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const { register, errors, handleSubmit } = useForm({
    reValidateMode: "onSubmit"
  });

  // Periodicidade
  const [periodicidade, setPeriodicidade] = useState();

  // Situacao
  const [situacao, setSituacao] = useState(true);

  const onChangeSituacao = (valor) => {
    setSituacao(valor);
  };

  // ICMS demanda registrada
  const [icmsDemandaRegistrada, setIcmsDemandaRegistrada] = useState(true);

  const onChangeIcmsDemandaRegistrada = (valor) => {
    setIcmsDemandaRegistrada(valor);
  };

  // ICMS sobre desconto
  const [icmsDesconto, setIcmsDesconto] = useState(true);

  const onChangeIcmsDesconto = (valor) => {
    setIcmsDesconto(valor);
  };

  // PIS/COFINS sobre ICMS
  const [pisCofinsIcms, setPisCofinsIcms] = useState(true);

  const onChangePisCofinsIcms = (valor) => {
    setPisCofinsIcms(valor);
  };

  // PIS/COFINS sobre ICMS
  const [pisCofinsSobreDesconto, setPisCofinsSobreDesconto] = useState(true);

  const onChangePisCofinsSobreDesconto = (valor) => {
    setPisCofinsSobreDesconto(valor);
  };

  // ICMS Sobre Não Utilizada
  const [naoUtilizadaIcms, setNaoUtilizadaIcms] = useState(true);

  const onChangeNaoUtilizadaIcms = (valor) => {
    setNaoUtilizadaIcms(valor);
  };

  const [carregandoDistribuidora, setCarregandoDistribuidora] = useState(false);
  const [dadosDistribuidora, setDadosDistribuidora] = useState();

  // Data reajuste
  const [dataReajuste, setDataReajuste] = useState(null);

  const onChangeDataReajuste = (data) => {
    setDataReajuste(data);
  };

  // Converte a hora para o formato americano
  const toHourFormat = (date) => {
    const minutes = String(date?.getMinutes()).padStart(2, "0");
    const hour = String(date?.getHours()).padStart(2, "0");

    return `${hour}:${minutes}:00`;
  };

  // Hora inicio e fim ponta
  const [inicioHorarioPonta, setInicioHorarioPonta] = useState(null);
  const [terminoHorarioPonta, setTerminoHorarioPonta] = useState(null);
  const terminoHorarioPontaDisabled = true;

  const onChangeInicioHorarioPonta = (horario) => {
    if (horario && !Number.isNaN(horario.getTime())) {
      let hora = horario?.getHours() + 3;
      if (hora > 24) {
        hora -= 24;
      }
      setInicioHorarioPonta(horario);
      setTerminoHorarioPonta(
        new Date(`Thu Oct 01 2020 ${hora}:${horario?.getMinutes()}:00 GMT-0300`)
      );
    } else {
      setTerminoHorarioPonta(null);
    }
  };

  // Submercado
  const [listaSubmercado, setListaSubmercado] = useState([]);
  const [carregandoSubmercado, setCarregandoSubmercado] = useState(false);
  const [submercadoId, setSubmercadoId] = useState();

  const onChangeSubmercado = (submercado) => {
    setSubmercadoId(submercado.target.value);
  };

  const obterSubmercado = async () => {
    try {
      setCarregandoSubmercado(true);
      const lista = await listarSubmercados();
      if (lista?.status === 200 && lista?.data) {
        setListaSubmercado(lista?.data);
      }
      setCarregandoSubmercado(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoSubmercado(false);
    }
  };

  useEffect(() => {
    obterSubmercado();
  }, []);

  // Converte a data para o formato americano
  const toDataFormat = (date) => {
    const dd = String(date?.getDate()).padStart(2, "0");
    const mm = String(date?.getMonth() + 1).padStart(2, "0");
    const yyyy = date?.getFullYear();

    return `${yyyy}-${mm}-${dd}`;
  };

  // Data Periodo Inicial e Final
  const [dataPeriodoInicial, setDataPeriodoInicial] = useState(null);
  const [dataPeriodoFinal, setDataPeriodoFinal] = useState(null);

  const onChangeDataPeriodoFinal = (data) => {
    let datePrimary = dataPeriodoInicial;
    if (typeof datePrimary !== "string")
      datePrimary = toDataFormat(datePrimary);
    if (datePrimary <= toDataFormat(data)) setDataPeriodoFinal(data);
  };

  const onChangeDataPeriodoInicial = (data) => {
    let dateSecond = dataPeriodoFinal;
    if (typeof dateSecond !== "string") dateSecond = toDataFormat(dateSecond);
    if (dateSecond >= toDataFormat(data)) setDataPeriodoInicial(data);
  };

  const [idDistribuidoraExistente, setIdDistribuidoraExistente] = useState(0);

  const [razaoSocial, setRazaoSocial] = useState();

  const enviarFormulario = async (dados) => {
    const cnpjFormatado = dados.cnpj?.replace(/[^a-zA-Z0-9]/g, "");
    try {
      const salvou = await salvarDistribuidora(id ?? 0, {
        ...dados,
        MesReajusteTarifario:
          dataReajuste != null ? dataReajuste.getMonth() + 1 : "",
        DiaReajusteTarifario: dataReajuste?.getDate() ?? "",
        dataPeriodoInicial:
          typeof dataPeriodoInicial === "string"
            ? new Date(dataPeriodoInicial)
            : toDataFormat(dataPeriodoInicial),
        dataPeriodoFinal:
          typeof dataPeriodoFinal === "string"
            ? new Date(dataPeriodoFinal)
            : toDataFormat(dataPeriodoFinal),
        inicioHorarioPonta: toHourFormat(inicioHorarioPonta),
        terminoHorarioPonta: toHourFormat(terminoHorarioPonta),
        Ativo: situacao,
        Cnpj: cnpjFormatado,
        RazaoSocial: razaoSocial,
        IcmsDemandaRegistrada: icmsDemandaRegistrada ? "AT" : "IN",
        IcmsDesconto: icmsDesconto ? "AT" : "IN",
        PisCofinsIcms: pisCofinsIcms ? "AT" : "IN",
        naoUtilizadaIcms: naoUtilizadaIcms ? "AT" : "IN",
        pisCofinsSobreDesconto: pisCofinsSobreDesconto ? "AT" : "IN",
        Periodicidade: periodicidade,
        SubmercadoId: submercadoId
      });

      if (salvou?.status === 200 || salvou?.status === 204) {
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: "Distribuidora salva com sucesso!"
          })
        );
        setTimeout(() => {
          history.push(RotasDTO.Distribuidoras);
          store.dispatch(selecionarEmpresa(null));
          store.dispatch(selecionarCliente(null));
          store.dispatch(selecionarUnidade(null));
        }, 2000);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
    setCarregandoDistribuidora(false);
  };

  const [erroRazaoSocial, setErroRazaoSocial] = useState(false);

  const [erroRazaoSocialCaracteres, setErroRazaoSocialCaracteres] = useState(
    false
  );

  const validarRazaoSocial = () => {
    if (razaoSocial?.length > 100) {
      setErroRazaoSocial(true);
      setErroRazaoSocialCaracteres(true);
      return;
    }
    setErroRazaoSocialCaracteres(false);
    setErroRazaoSocial(!razaoSocial);
  };

  const aoEnviarFormulario = (dados) => {
    if (!erroRazaoSocial) {
      setCarregandoDistribuidora(true);
      enviarFormulario(dados);
      setErroRazaoSocial(false);
    }
  };

  const [carregandoCnpj, setCarregandoCnpj] = useState(false);
  const [nomeFantasia, setNomeFantasia] = useState();

  const onBlurNomeFantasia = (nome) => {
    const nomeFantasiaFormatado = nome.replaceAll("'", "").replaceAll("\"", "");
    setNomeFantasia(nomeFantasiaFormatado);
  };

  const obterDistribuidora = useCallback(async (idDistribuidora) => {
    try {
      const distribuidora = await buscarPorId(idDistribuidora);
      if (distribuidora?.status === 200 && distribuidora?.data) {
        setDadosDistribuidora(distribuidora?.data);
        setNomeFantasia(distribuidora?.data?.nomeFantasia);
        setSituacao(distribuidora?.data?.ativo);
        setIcmsDemandaRegistrada(
          distribuidora?.data?.icmsDemandaRegistrada === "AT"
        );
        setIcmsDesconto(distribuidora?.data?.icmsDesconto === "AT");
        setPisCofinsIcms(distribuidora?.data?.pisCofinsIcms === "AT");
        setNaoUtilizadaIcms(distribuidora?.data?.naoUtilizadaIcms === "AT");
        setPisCofinsSobreDesconto(distribuidora?.data?.pisCofinsSobreDesconto === "AT");
        setIdDistribuidoraExistente(1);
        setInicioHorarioPonta(
          new Date(
            `Thu Oct 01 2020 ${distribuidora?.data?.inicioHorarioPonta} GMT-0300`
          )
        );
        setTerminoHorarioPonta(
          new Date(
            `Thu Oct 01 2020 ${distribuidora?.data?.terminoHorarioPonta} GMT-0300`
          )
        );
        setDataPeriodoInicial(distribuidora?.data?.dataPeriodoInicial);
        setDataPeriodoFinal(distribuidora?.data?.dataPeriodoFinal);
        setDataReajuste(
          new Date(
            `2020-${distribuidora?.data?.mesReajusteTarifario}-${distribuidora?.data?.diaReajusteTarifario}`
          )
        );
        setRazaoSocial(distribuidora?.data?.razaoSocial);
        setSubmercadoId(distribuidora?.data?.submercadoId);
        setPeriodicidade(distribuidora?.data?.periodicidade);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  }, []);

  useEffect(() => {
    if (id) obterDistribuidora(id);
  }, [id, obterDistribuidora]);

  const [modalCnpjExistente, setModalCnpjExistente] = useState(false);
  const [modalRazaoSocialExistente, setModalRazaoSocialExistente] = useState(
    false
  );

  const onConfirmarTelaEdicaoCNPJ = () => {
    history.push(
      `${RotasDTO.Distribuidoras}/cadastro/${idDistribuidoraExistente}`
    );
    setModalCnpjExistente(false);
  };

  const onCancelarTelaEdicaoCNPJ = () => {
    setDadosDistribuidora({
      ...(dadosDistribuidora && { dadosDistribuidora }),
      cnpj: " "
    });
    setModalCnpjExistente(false);
  };

  const onConfirmarTelaEdicaoRazaoSocial = () => {
    history.push(
      `${RotasDTO.Distribuidoras}/cadastro/${idDistribuidoraExistente}`
    );
    setModalRazaoSocialExistente(false);
  };

  const onCancelarTelaEdicaoRazaoSocial = () => {
    setDadosDistribuidora({
      ...(dadosDistribuidora && { dadosDistribuidora }),
      RazaoSocial: " "
    });
    setModalRazaoSocialExistente(false);
  };

  const verificarCnpj = useCallback(async (valor) => {
    try {
      setCarregandoCnpj(true);
      const cnpjFormatado = valor?.target.value.replace(/[^a-zA-Z0-9]/g, "");
      if (idDistribuidoraExistente === 0 && cnpjFormatado.length === 14) {
        const dadosCadastrais = await buscarDadosCadastraisPorCnpj(
          cnpjFormatado
        );
        const distribuidora = await BuscarPorCnpjOuNomeFantasia(cnpjFormatado);
        if (
          distribuidora?.status === 200 &&
          distribuidora?.data?.distribuidoras &&
          distribuidora?.data?.distribuidoras[0].id
        ) {
          setModalCnpjExistente(true);
          setIdDistribuidoraExistente(
            distribuidora?.data?.distribuidoras[0].id
          );
        }
        if (dadosCadastrais?.data?.success) {
          setRazaoSocial(dadosCadastrais?.data?.data?.razaoSocial);
          const nomeFantasiaFormatado = dadosCadastrais?.data?.data?.nomeFantasia
            .replaceAll("'", "")
            .replaceAll("\"", "");
          setNomeFantasia(nomeFantasiaFormatado);
        } else {
          store.dispatch(
            alertaExibir({
              tipo: "warning",
              mensagem: "CNPJ inválido!"
            })
          );
          setRazaoSocial("");
          setNomeFantasia("");
        }
      }
      setCarregandoCnpj(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoCnpj(false);
    }
  }, []);

  const verificarRazaoSocial = async (valor) => {
    if (valor) {
      const distribuidora = await BuscarPorCnpjOuNomeFantasia(valor);
      if (
        idDistribuidoraExistente === 0 &&
        distribuidora?.status === 200 &&
        distribuidora?.data?.distribuidoras &&
        distribuidora?.data?.distribuidoras[0].id
      ) {
        setModalRazaoSocialExistente(true);
        setIdDistribuidoraExistente(distribuidora?.data?.distribuidoras[0].id);
      }
    }
  };

  function formatarValorSalvar(valor) {
    return valor ? String(valor)?.replace(" ano(s)", "")?.trim() : valor;
  }

  useEffect(() => {
    verificarRazaoSocial(razaoSocial);
  }, [razaoSocial]);

  return (
    <>
      <ModalConfirmacao
        item={modalCnpjExistente}
        onConfirmar={() => onConfirmarTelaEdicaoCNPJ()}
        mensagem="CNPJ já cadastrado. Deseja ir para a tela de edição?"
        onCancelar={() => onCancelarTelaEdicaoCNPJ()}
      />
      <ModalConfirmacao
        item={modalRazaoSocialExistente}
        onConfirmar={() => onConfirmarTelaEdicaoRazaoSocial()}
        mensagem="Razão Social já cadastrada. Deseja ir para a tela de edição?"
        onCancelar={() => onCancelarTelaEdicaoRazaoSocial(false)}
      />
      <form
        className="needs-validation"
        onSubmit={handleSubmit(aoEnviarFormulario)}
      >
        <Card className={classes.container}>
          <Grid container p={2} spacing={4} className={classes.container}>
            <Grid item xs={6} className="font-weight-bold">
              {dadosDistribuidora?.id
                ? "Editar distribuidora"
                : "Nova distribuidora"}
            </Grid>
            <BotaoRetornarListagem
              urlListagem={RotasDTO.Distribuidoras}
              zerarCombo
            />
            <Grid item lg={3} md={3} sm={5}>
              <Loader loading={carregandoCnpj}>
                <MaterialInputMascara
                  type="text"
                  id="cnpj"
                  name="cnpj"
                  label="CNPJ"
                  mask="##.###.###/####-##"
                  renderIconShowHide={false}
                  onBlur={(valor) => verificarCnpj(valor)}
                  defaultValue={dadosDistribuidora?.cnpj}
                  ref={register({
                    required: "Campo CNPJ é obrigatório!",
                    maxLength: {
                      value: 18,
                      message: "Quantidade máxima de 18 caracteres!"
                    },
                    minLength: {
                      value: 11,
                      message: "Quantidade mínima de 11 caracteres!"
                    },
                    validate: (value) => cnpj.isValid(value) || "CNPJ inválido!"
                  })}
                  errors={errors}
                />
              </Loader>
            </Grid>
            <Grid item lg={6} md={6} sm={8}>
              <Loader loading={carregandoCnpj}>
                <MaterialInputTexto
                  type="text"
                  id="RazaoSocial"
                  name="RazaoSocial"
                  label="Razão Social"
                  permiteValorBranco
                  renderIconShowHide={false}
                  onBlur={(valor) => setRazaoSocial(valor.target.value)}
                  defaultValue={razaoSocial}
                  ref={register({
                    required: "Campo Razão Social é obrigatório!"
                  })}
                  className={`${erroRazaoSocial ? "Input-error" : ""}`}
                  value={razaoSocial}
                  errors={errors}
                />
              </Loader>
              {erroRazaoSocial ? (
                <p
                  className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error"
                  id="RazaoSocial-helper-text"
                >
                  {erroRazaoSocialCaracteres
                    ? "Quantidade máxima de 100 caracteres!"
                    : "Campo Razão Social é obrigatório!"}
                </p>
              ) : null}
            </Grid>
            <Grid item lg={3} md={3} sm={5}>
              <Loader loading={carregandoCnpj}>
                <MaterialInputTexto
                  type="text"
                  id="NomeFantasia"
                  name="NomeFantasia"
                  label="Nome Fantasia"
                  permiteValorBranco
                  renderIconShowHide={false}
                  defaultValue={nomeFantasia}
                  onBlur={(event) => onBlurNomeFantasia(event.target.value)}
                  onInput={(event) => onBlurNomeFantasia(event.target.value)}
                  ref={register({
                    required: "Campo Nome Fantasia é obrigatório!",
                    maxLength: {
                      value: 50,
                      message: "Quantidade máxima de 50 caracteres!"
                    },
                    validate: (value) =>
                      (!value.includes("'") && !value.includes("\"")) ||
                      "Não é permitido adicionar aspas no Nome Fantasia"
                  })}
                  errors={errors}
                />
              </Loader>
            </Grid>
          </Grid>
          <Grid container p={2} spacing={4} className={classes.container}>
            <Grid item lg={3} md={3} sm={5}>
              <MaterialInputTexto
                type="number"
                id="idAneel"
                name="idAneel"
                label="Id Aneel"
                renderIconShowHide={false}
                defaultValue={dadosDistribuidora?.idAneel}
                ref={register({
                  required: "Campo Id Aneel é obrigatório!",
                  maxLength: {
                    value: 50,
                    message: "Quantidade máxima de 50 caracteres!"
                  },
                  min: {
                    value: 1,
                    message: "Campo Id Aneel não pode ser negativo!"
                  }
                })}
                errors={errors}
              />
            </Grid>
            <Grid item lg={3} md={3} sm={5}>
              <InputData
                type="text"
                id="dataReajuste"
                name="dataReajuste"
                label="Data de Reajuste"
                format="dd/MM"
                customValue={dataReajuste}
                onChange={(data) => onChangeDataReajuste(data)}
                renderIconShowHide={false}
                defaultValue={dadosDistribuidora?.dataReajuste}
                ref={register({
                  required: "Campo Data de Reajuste é obrigatório!",
                  validate: (value) =>
                    moment(value, "DD/MM").isValid() ||
                    "Data de Reajuste inválida!"
                })}
                errors={errors}
                className={classes}
              />
            </Grid>
            <Grid item lg={3} md={3} sm={5}>
              <Loader loading={carregandoSubmercado}>
                <SelectArredondado
                  id="Submercado"
                  name="Submercado"
                  valueKey="id"
                  valueName="descricao"
                  marginBottom="0"
                  dataSource={listaSubmercado}
                  label="Submercado"
                  value={submercadoId}
                  onChange={(submercado) => onChangeSubmercado(submercado)}
                  placeholder="Submercado"
                  ref={register(
                    { name: "Submercado" },
                    {
                      required: !submercadoId
                        ? "Campo Submercado é obrigatório!"
                        : false
                    }
                  )}
                  allowClear
                  errors={errors}
                />
              </Loader>
            </Grid>
            <Grid item lg={3} md={3} sm={5}>
              <MaterialInputMascara
                type="text"
                id="periodicidadeAnos"
                name="periodicidadeAnos"
                sufixo=" ano(s)"
                label="Periodicidade rev. tarifária"
                defaultValue={periodicidade}
                renderIconShowHide={false}
                onBlur={(e) =>
                  setPeriodicidade(formatarValorSalvar(e.target.value))
                }
                ref={register({
                  required: "Campo Periodicidade é obrigatório!",
                  validate: (value) =>
                    formatarValorSalvar(value) > 0 || "Periodicidade inválida!"
                })}
                errors={errors}
              />
            </Grid>
          </Grid>
          {dadosDistribuidora?.id ? (
            <Grid container p={2} spacing={4} className={classes.container}>
              <Grid item lg={3} md={3} sm={5}>
                <MaterialSwitch
                  label="Ativo"
                  labelPlacement="top"
                  id="Ativo"
                  name="Ativo"
                  checked={situacao}
                  onChange={(valor) => onChangeSituacao(valor)}
                />
              </Grid>
            </Grid>
          ) : null}
          <Divider className={classes.divider} />
          <Grid container p={2} spacing={4} className={classes.container}>
            <Grid item lg={12} className="font-weight-bold">
              Horário de ponta
            </Grid>
            <Grid item lg={3} md={3} sm={5} className="">
              <InputHora
                type="text"
                id="inicioHorarioPonta"
                name="inicioHorarioPonta"
                label="Início de ponta"
                renderIconShowHide={false}
                customValue={inicioHorarioPonta}
                onChange={(hora) => onChangeInicioHorarioPonta(hora)}
                defaultValue={dadosDistribuidora?.inicioHorarioPonta}
                ref={register({
                  required: "Campo Início de Ponta é obrigatório!",
                  validate: (value) =>
                    value.replace(":", "").replace("_", "").length === 4 ||
                    "Hora inválida!"
                })}
                errors={errors}
                className={classes}
              />
            </Grid>
            <Grid item lg={3} md={3} sm={5} className="">
              <InputHora
                type="text"
                id="terminoHorarioPonta"
                name="terminoHorarioPonta"
                label="Fim de ponta"
                customValue={terminoHorarioPonta}
                renderIconShowHide={false}
                defaultValue={dadosDistribuidora?.terminoHorarioPonta}
                ref={register({
                  required: "Verifique os campos Início e Término de Ponta!",
                  validate: (value) =>
                    value.replace(":", "").replace("_", "").length === 4 ||
                    "Hora inválida!"
                })}
                errors={errors}
                className={classes}
                disabled={terminoHorarioPontaDisabled}
              />
            </Grid>
            <Grid item lg={3} md={3} sm={5} className="">
              <InputData
                type="text"
                id="dataPeriodoInicial"
                name="dataPeriodoInicial"
                label="Início da vigência"
                customValue={dataPeriodoInicial}
                onChange={(data) => onChangeDataPeriodoInicial(data)}
                renderIconShowHide={false}
                ref={register({
                  required: "Campo Início da vigência é obrigatório!",
                  validate: (value) =>
                    (moment(value, "DD/MM/YYYY").isValid() &&
                      moment(value, "DD/MM/YYYY") >
                        moment("01/01/1900", "DD/MM/YYYY")) ||
                    "Data de Início da vigência inválida!"
                })}
                errors={errors}
                className={classes}
              />
            </Grid>
            <Grid item lg={3} md={3} sm={5} className="">
              <InputData
                type="text"
                id="dataPeriodoFinal"
                name="dataPeriodoFinal"
                label="Fim da vigência"
                customValue={dataPeriodoFinal}
                minDate={dataPeriodoInicial}
                onChange={(data) => onChangeDataPeriodoFinal(data)}
                defaultValue={dadosDistribuidora?.dataPeriodoFinal}
                ref={register({
                  required: "Campo Fim da vigência é obrigatório!",
                  validate: (value) =>
                    (moment(value, "DD/MM/YYYY").isValid() &&
                      moment(value, "DD/MM/YYYY") >
                        moment("01/01/1900", "DD/MM/YYYY") &&
                      moment(dataPeriodoInicial) <
                        moment(value, "DD/MM/YYYY")) ||
                    "Data de Fim da vigência inválida!"
                })}
                errors={errors}
                className={classes}
              />
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Grid container p={2} spacing={4} className={classes.container}>
            <Grid item lg={12} className="font-weight-bold">
              Impostos sobre Distribuidora
            </Grid>
            <Grid container item lg={7} xs={12}>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <MaterialSwitch
                  label="ICMS demanda registrada"
                  labelPlacement="top"
                  id="IcmsDemandaRegistrada"
                  name="IcmsDemandaRegistrada"
                  checked={icmsDemandaRegistrada}
                  onChange={(valor) => onChangeIcmsDemandaRegistrada(valor)}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <MaterialSwitch
                  label="ICMS sobre desconto"
                  labelPlacement="top"
                  id="IcmsDesconto"
                  name="IcmsDesconto"
                  checked={icmsDesconto}
                  onChange={(valor) => onChangeIcmsDesconto(valor)}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <MaterialSwitch
                  label="PIS/COFINS sobre ICMS"
                  labelPlacement="top"
                  id="PisCofinsIcms"
                  name="PisCofinsIcms"
                  checked={pisCofinsIcms}
                  onChange={(valor) => onChangePisCofinsIcms(valor)}
                />
              </Grid>
            </Grid>
            <Grid container item lg={5} sm={8} xs={12}>
              <Grid item lg={6} md={6} sm={4} xs={12}>
                <MaterialSwitch
                  label="ICMS sobre Não Utilizada"
                  labelPlacement="top"
                  id="naoUtilizadaIcms"
                  name="naoUtilizadaIcms"
                  checked={naoUtilizadaIcms}
                  onChange={(valor) => onChangeNaoUtilizadaIcms(valor)}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={4} xs={12}>
                <MaterialSwitch
                  label="PIS/COFINS sobre desconto"
                  labelPlacement="top"
                  id="pisCofinsSobreDesconto"
                  name="pisCofinsSobreDesconto"
                  checked={pisCofinsSobreDesconto}
                  onChange={(valor) => onChangePisCofinsSobreDesconto(valor)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Grid container p={2} spacing={4} className={classes.container}>
            <Grid item lg={3} md={4} sm={6} className={classes.containerSalvar}>
              <Loader loading={carregandoDistribuidora}>
                <Botao
                  type="submit"
                  label="Salvar"
                  className={classes.button}
                  onClick={() => validarRazaoSocial()}
                />
              </Loader>
            </Grid>
          </Grid>
        </Card>
      </form>
    </>
  );
};

export default DistribuidoraCadastro;
