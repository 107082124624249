import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const api = () => obterApi(MICROSERVICO.MEASUREMENT);

// Busca Por Id
export const buscarPorId = async (id) => {
  return api().get(`v1/leadunidadeconsumidora/${id}`);
};

// Listar Unidades
export const listarUnidadesConsumidoras = async (
  ordemSelecionada,
  filtroSelecionado,
  pagina,
  searchString,
  clientes
) => {
  const parametros = {
    sortOrder: ordemSelecionada,
    filter: filtroSelecionado,
    pagina,
    tamanhoPaginacao: 10,
    searchString: searchString === undefined ? "" : searchString
  };
  if (clientes.length > 0) parametros.clientesId = clientes;

  return api().get(
    `v1/leadunidadeconsumidora?${new URLSearchParams(parametros).toString()}`
  );
};

// Deletar Cliente
export const deletarUnidade = async (id) => {
  return api().delete(`v1/leadunidadeconsumidora/DeleteById?id=${id}`);
};

export const listarOrganizarPor = () => {
  const ordernacoes = [
    {
      key: "0",
      label: "Alfabética"
    },
    {
      key: "3",
      label: "Recentes"
    },
    {
      key: "4",
      label: "CNPJ"
    }
  ];

  return ordernacoes;
};

// Salvar Cliente
export const salvarUnidade = async (id, dados) => {
  let metodo = "post";
  let url = "v1/leadunidadeconsumidora";
  if (id > 0) {
    metodo = "put";
    url = `${url}/${id}`;
  }

  return api()[metodo](url, dados);
};

// Efetivar UnidadeConsumidora
export const efetivarUnidadeConsumidora = async (ids) => {
  const metodo = "post";
  const url = "v1/leadunidadeconsumidora/EfetivarCadastro";

  return api()[metodo](url, ids);
};

export const listarFiltrarPor = () => {
  const filtros = [
    {
      key: "PP",
      label: "Pendente"
    },
    {
      key: "NI",
      label: "Não importado"
    }
  ];

  return filtros;
};

// Listar Unidades
export const listarUnidadesConsumidorasCombo = async (clienteId, token) => {
  const IdCliente = parseInt(clienteId, 10);
  return api().get(
    `v1/leadunidadeconsumidora/ListarUnidadeConsumidoraPorCliente/${IdCliente}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
};

// Buscar Unidade Consumidora por CNPJ
export const buscarPorCnpj = async (cnpj) => {
  const urlParams = `?searchString=${cnpj}`;
  return api().get(`v1/leadUnidadeConsumidora${urlParams}`);
};

// Busca Status
export const buscarStatus = async () => {
  return api().get("v1/leadUnidadeConsumidora/Status");
};

// Busca Submercado
export const buscarSubmercado = async () => {
  return api().get("v1/Submercado");
};

// Busca Nível de Tensão
export const buscarNivelTensao = async () => {
  return api().get("v1/NivelTensao");
};

// Todas Unidades
export const listarUnidadesSemPaginacao = async (empresa, cliente) => {
  const params = {
    empresaId: empresa,
    clienteId: cliente
  };

  return api().get(
    `v1/leadunidadeconsumidora/sem-paginacao?${new URLSearchParams(
      params
    ).toString()}`
  );
};

// Busca Tipo Representação
export const buscaTipoRepresentacao = async () => {
  return api().get("v1/tiporepresentacao");
};

export const salvarLeadPlanoSelecionado = async (id, dados) => {
  let metodo = "post";
  let url = "v1/leadunidadeconsumidora/leadplanoselecionado";
  if (id > 0) {
    metodo = "put";
    url = `${url}/${id}`;
  }

  return api()[metodo](url, dados);
};

export const salvarLeadUnidadeConsumidora = async (dados, cotacaoId) => {
  let metodo = "post";
  let url = "v1/leadunidadeconsumidora";
  if (cotacaoId > 0) {
    metodo = "put";
    url = `${url}/updateclientid`;
  }

  return api()[metodo](url, dados);
};

export const salvarLeadPlanoUnidade = async (dados, cotacaoId) => {
  let metodo = "post";
  let url = "v1/leadunidadeconsumidora/leadplanoselecionado";
  if (cotacaoId > 0) {
    metodo = "put";
    url = "v1/leadunidadeconsumidora/updateleadplanoleadunidadeid";
  }

  return api()[metodo](url, dados);
};
