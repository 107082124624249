import React from "react";
import PropTypes from "prop-types";

import { Tooltip } from "@mui/material";
import { Button } from "./style";

const BotaoForm = React.forwardRef((props, ref) => {
  const {
    disabled,
    id,
    icon,
    label,
    type,
    onClick,
    style,
    className,
    value,
    loginLuz,
    stateless,
    selected,
    form,
    titleTooltip,
    labelTootip,
    styleTooltip
  } = props;

  const classNameDesc = `btn btn-lg rounded-pill w-100 position-relative d-flex justify-content-center align-items-center ${className}`;

  return (
    <Tooltip
      title={titleTooltip}
      aria-label={labelTootip}
      slotProps={{ tooltip: { ...styleTooltip } }}
    >
      <Button
        form={form}
        type={type}
        className={classNameDesc}
        id={id}
        disabled={disabled}
        ref={ref}
        loginLuz={loginLuz}
        stateless={stateless}
        selected={selected}
        onClick={onClick}
        style={{ ...style }}
        value={value}
      >
        {icon && icon}
        {label}
      </Button>
    </Tooltip>
  );
});

BotaoForm.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.any]),
  label: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.oneOfType([PropTypes.func]),
  style: PropTypes.oneOfType([PropTypes.any]),
  className: PropTypes.string,
  value: PropTypes.string,
  stateless: PropTypes.bool,
  loginLuz: PropTypes.bool,
  selected: PropTypes.bool,
  form: PropTypes.string,
  titleTooltip: PropTypes.string,
  labelTootip: PropTypes.string,
  styleTooltip: PropTypes.oneOfType([PropTypes.object])
};

BotaoForm.defaultProps = {
  disabled: false,
  id: "",
  icon: false,
  label: "",
  type: "button",
  onClick: () => {},
  style: {},
  className: "",
  value: "",
  stateless: true,
  loginLuz: false,
  selected: false,
  form: "",
  titleTooltip: "",
  labelTootip: "",
  styleTooltip: {}
};

export default BotaoForm;
