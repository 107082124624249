import { makeStyles } from "@mui/styles";
import theme from "themes";
import { Base } from "../../../../componentes/cores";

export const useStyles = makeStyles({
  container: {
    margin: "0",
    width: "100%",
    "& .MuiInputBase-root": {
      height: "46px"
    },
    "& .MuiFormLabel-root": {
      lineHeight: "1"
    }
  },
  button: {
    fontSize: "18px"
  },
  trace: {
    width: "112px",
    height: "47px",
    borderBottom: "1px solid black",
    position: "absolute"
  },
  filter: {
    color: `${Base.OffWhite} !important`,
    display: "inline",
    fontSize: "18px",
    margin: 0,
    padding: 0,
    "& li": {
      display: "inline-block",
      marginLeft: "45px",
      position: "relative",
      "&:nth-child(1) .icon": {
        backgroundColor: Base.White
      },
      "&:nth-child(2) .icon": {
        backgroundColor: Base.OrangeBlaze
      },
      "&:nth-child(3) .icon": {
        backgroundColor: Base.BlueViolet
      },
      "&:nth-child(4) .icon": {
        backgroundColor: Base.BlueDodger
      },
      "&:nth-child(5) .icon": {
        backgroundColor: Base.GreenFern
      },
      "&:nth-child(6) .icon": {
        backgroundColor: Base.Aurora
      }
    },
    "& .icon": {
      display: "inline-block",
      height: "20px",
      left: "-30px",
      position: "absolute",
      width: "20px"
    },
    "& .texto": { marginTop: "-2px", color: theme.color.textoBarra }
  },
  containerAtivo: {
    margin: "-16px 0 0 0",
    width: "100%"
  },
  containerSalvar: {
    margin: "0 0 32px 0"
  },
  containerLegendas: {
    margin: "24px 0"
  },
  cardCadastro: {
    backgroundColor: theme.color.bgColorCardCadastro
  },
  containerIcone: {
    borderRadius: "50%",
    border: "none",
    height: "40px",
    width: "40px",
    backgroundColor: theme.color.btOrdBGColor,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& svg": {
      color: Base.White
    }
  },
  containerModal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  modalItem: {
    backgroundColor: Base.White,
    width: "100%",
    maxWidth: "350px",
    margin: "0 auto",
    padding: "30px",
    position: "relative"
  },
  closeIcon: {
    position: "absolute",
    top: 2,
    right: 2,
    cursor: "pointer"
  },
  switch: {
    marginTop: "10px",
    marginLeft: "0 !important",
    "& .MuiFormControlLabel-label": {
      marginLeft: "4px"
    },
    "& .css-j204z7-MuiFormControlLabel-root": {
      marginLeft: "0px !important"
    }
  }
});
