import { makeStyles } from '@mui/styles';
import styled from "styled-components";
import { Base } from 'componentes/cores';
import { createTheme } from "@mui/material/styles";

const theme = createTheme();

export const useStyles = makeStyles(() => ({
  container: {
    margin: 0,
    width: '100%',
    "& .MuiGrid-root": {
      color: `${Base.BlackBastille} !important`
    },
    "& .MuiDropzoneArea-text": {
      color: `${Base.BlackBastille} !important`
    },
    "& .MuiInputBase-root": {
      height: "46px"
    },
    "& .MuiFormLabel-root": {
      lineHeight: "1"
    },

    '& hr': {
      height: "80px", 
      margin: theme.spacing(0, 0.5),

      '&::before': {
        content: "attr(data-df)",
        color: Base.BlackBastille,
        float: "right",
        margin: "0 10px",
      },

      '&::after': {
        content: "attr(data-et)",
        color: Base.BlackBastille,
        position: "absolute",
        margin: "0 10px",
      },
    }
  },
  containerTitulo: {
    padding: '18px !important'
  },
  titulo: {
    fontSize: '18px',
    fontWeight: '700 !important',
    color: '#000 !important'
  },
  containerData: {
    paddingLeft: '32px !important'
  },
  icon: {
    cursor: "pointer",
    float:"right",
    margin: "5px",
    color: '#000 !important',
    fontWeight: '700 !important'
  },
  containerAnoReferencia: {
    paddingLeft: '33px !important'
  },
  containerDataReferencia: {
    paddingLeft: '33px !important'
  },
  containerCusto: {
    paddingTop: '0px !important'
  },
  button: {
    fontSize: '18px',
  },
  customButton: {
    fontSize: '18px',
    backgroundColor: "transparent !important"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
  },
  fileUpload: {
    "& .MuiDropzoneArea-root": {
      minHeight: "150px",
      borderWidth: "2px"
    },
    "& .MuiDropzoneArea-text": {
      fontSize: "16px"
    }
  },
  customBr: {
    borderBottom: "4px solid white;",
  }
}));