import { Buffer } from "buffer";
import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const api = () => obterApi(MICROSERVICO.DELTAPROCESSAMENTO);

export const digitalizacaoFatura = async (
  fileBytes,
  fileName,
  externoId,
  colaboradorId,
  empresaCodigo,
  distribuidoraId,
  endpoint
) => {
  const keyOnS3 = `${externoId}-${fileName}`;
  const urlPreSigned = await gerarUrlUpload(keyOnS3);
  const binary = Buffer.from(fileBytes, "base64");

  await fetch(decodeURI(urlPreSigned.data), {
    method: "PUT",
    body: binary.buffer,
    headers: {
      "Content-type": "application/octet-stream"
    }
  });

  const paramPost = {
    fileName: keyOnS3,
    externoId: externoId.toString(),
    colaboradorId,
    empresaCodigo,
    endpoint,
    origemFront: "AreaLogada"
  };

  if (distribuidoraId) paramPost.distribuidoraId = distribuidoraId;

  const resultado = await api().post("/fatura/importar-s3-async", paramPost);

  const respostaFatura = await new Promise((resolve) => {
    const intervalId = setInterval(async () => {
      try {
        const res = await obterFaturaDeltaProcessamento(
          resultado.data.resultObject.item2
        );

        if (res.status !== 202) {
          clearInterval(intervalId);
          resolve(res);
        }
      } catch (error) {
        clearInterval(intervalId);
        resolve(error);
      }
    }, 5000);
  });

  return respostaFatura?.data;
};

export const obterDadosFaturaDigitalizada = async (externoId) => {
  return (
    api()
      // eslint-disable-next-line prefer-template
      .get("fatura/buscar-id/" + externoId.toString() + "")
      .then((data) => {
        return data?.data;
      })
  );
};

export const obterDadosFaturaArquivo = async (externoId) => {
  return (
    api()
      // eslint-disable-next-line prefer-template
      .get("fatura/buscar-arquivo-id/" + externoId.toString() + "")
      .then((data) => {
        return data?.data;
      })
  );
};

export const ImportarFaturaFourvision = async (arquivo) => {
  const paramPost = {
    fileBytes: arquivo.data.split(",")[1],
    fileName: arquivo.file.name,
    origemFront: "AreaLogada"
  };

  return api()
    .post("fatura/importar-fourvision", paramPost)
    .then((data) => {
      return data;
    });
};

export const gerarUrlUpload = async (fileNameWithExtension) => {
  return api().get(
    `/awsutil/GeraURLUpload/${encodeURIComponent(fileNameWithExtension)}`
  );
};

export const obterFaturaDeltaProcessamento = (id) => {
  return api().get(`/fatura/consultar-importacao-async/${id}`);
};

export const digitalizacaoFaturaAntigo = async (
  fileBytes,
  externoId,
  endpoint,
  colaboradorId
) => {
  const paramPost = {
    fileBytes,
    externoId: externoId.toString(),
    colaboradorId,
    origemFront: "AreaLogada"
  };
  return api().post(`/fatura/${endpoint}`, paramPost);
};
