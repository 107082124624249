export default class listagemFaturasDto {
  constructor(
    id,
    unidadeConsumidoraId,
    ultimoIdentificadorBoleto,
    ultimoNomeBoleto,
    ultimoIdentificadorNotaFiscal,
    ultimoNomeNotaFiscal,
    mesAnoReferenciaFormatado,
    mesReferencia,
    anoReferencia,
    statusNome,
    statusId,
    dataPagamentoFormatada,
    valorFormatado,
    valorPagoFormatado,
    dataVencimentoFormatada,
    empresaId,
    cliente,
    unidadeConsumidora,
    atraso
  ) {
    this.id = id;
    this.unidadeConsumidoraId = unidadeConsumidoraId;
    this.ultimoIdentificadorBoleto = ultimoIdentificadorBoleto;
    this.ultimoNomeBoleto = ultimoNomeBoleto;
    this.ultimoIdentificadorNotaFiscal = ultimoIdentificadorNotaFiscal;
    this.ultimoNomeNotaFiscal = ultimoNomeNotaFiscal;
    this.mesAnoReferenciaFormatado = mesAnoReferenciaFormatado;
    this.mesReferencia = mesReferencia;
    this.anoReferencia = anoReferencia;
    this.statusNome = statusNome;
    this.statusId = statusId;
    this.dataPagamentoFormatada = dataPagamentoFormatada;
    this.valorFormatado = valorFormatado;
    this.valorPagoFormatado = valorPagoFormatado;
    this.dataVencimentoFormatada = dataVencimentoFormatada;
    this.empresaId = empresaId;
    this.cliente = cliente;
    this.unidadeConsumidora = unidadeConsumidora;
    this.atraso = atraso;
  }
}
