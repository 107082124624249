import MICROSERVICO from "global/enumeradores/microServico";
import obterApi from "servicos/api";

const api = () => obterApi(MICROSERVICO.PROJECAOTARIFARIA);

export const getAll = async () => {
    const metodo = "get";
    const url = "v1/AnotacaoUsuario/getall"
    return api()[metodo](url);
  }

  export const adicionarAnotacao = async (data) => {
    const metodo = "post";
    const url = "v1/AnotacaoUsuario/add"
    return api()[metodo](url,data);
  }

  export const getById = async (id) => {
    const metodo = "get";
    const url = `v1/AnotacaoUsuario/getbyid?IdAnotacaoUsuario=${id}`
    return api()[metodo](url);
  }

  export const editarAnotacao = async (data) => {
    const metodo = "put";
    const url = `v1/AnotacaoUsuario/update`
    return api()[metodo](url,data); 
  }

  export const excluirAnotacao = async (id) => {
    const metodo = "delete";
    const url = `v1/AnotacaoUsuario/delete?IdAnotacao=${id}`
    return api()[metodo](url);
  }