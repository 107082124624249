import { makeStyles } from '@mui/styles';
import { Base } from 'componentes/cores';

export const useStyles = makeStyles(() => ({
  container: {
    margin: 0,
    width: '100%',
    "& .MuiGrid-root": {
      color: `${Base.BlackBastille}`
    },
  },
  customSelect: {
    "& .MuiFormControl-root": {
      borderRadius: "4px",
      color: "gray",
      border: "1px solid gray"
    },

    "& .MuiOutlinedInput-inputAdornedEnd": {
      color: "gray",
    }
  },
}));