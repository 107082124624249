import React, { useRef } from "react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import PropTypes from "prop-types";

// Componentes
import { GetApp } from "@mui/icons-material";
import { Box, Grid } from "@mui/material";
import GraficoMercado from "./GraficoMercado";
import GraficoConsumo from "./GraficoConsumo";
import CardLinha from "./CardLinha";
import Passos from "./Passos";
import GrupoDelta from "./GrupoDelta";
import { dados1, dados3, quemSomos, medidor, grupoDelta } from "./dados";
import Card from "./Card";
import Pagina1 from "./Pagina1";
import Pagina2 from "./Pagina2";
import Pagina3 from "./Pagina3";
import Pagina4 from "./Pagina4";
import Pagina5 from "./Pagina5";
import Pagina6 from "./Pagina6";
import ImagemClientes from "../../../assets/jornadaCliente/clientes.png";

// Styles
import { useStyles } from "./style";

const PropostaPdf = ({ dadosPlanos, dadosCliente, setCarregando }) => {
  const classes = useStyles();
  const pdfRef = useRef([]);
  const pdfRefGrafico1 = useRef(null);
  const pdfRefGrafico2 = useRef(null);
  const pdfRefGrafico3 = useRef(null);

  const gerarPdf = async () => {
    try {
      setCarregando(true);

      let elementos = pdfRef?.current?.filter((dado) => dado).slice(0, 9);

      if (pdfRefGrafico3?.current)
        elementos.splice(8, 0, pdfRefGrafico3.current);
      if (pdfRefGrafico2?.current)
        elementos.splice(8, 0, pdfRefGrafico2.current);
      if (pdfRefGrafico1?.current)
        elementos.splice(8, 0, pdfRefGrafico1.current);
      const doc = new jsPDF("l", "px", [1020, 746]);

      // eslint-disable-next-line no-restricted-syntax
      for (const [i, value] of elementos.entries()) {
        let imgData = "";

        // eslint-disable-next-line no-await-in-loop
        await html2canvas(value, {
          scale: 1.1,
          foreignObjectRendering: false,
          logging: false,
          proxy: "/proxy"
        }).then(async (canvas) => {
          imgData = canvas.toDataURL("image/jpeg", 1.0);
        });
        doc.addImage({
          imageData: imgData,
          format: "JPEG",
          x: 0,
          y: 0,
          width: doc.internal.pageSize.width,
          height: doc.internal.pageSize.height,
          alias: undefined,
          compression: "FAST",
          rotation: 0
        });
        if (i + 1 === elementos.length) {
          doc.save(`proposta-${window?.moment()?.format()}.pdf`);
        } else {
          doc.addPage();
        }

        imgData = "";
      }

      elementos = [];
    } finally {
      setCarregando(false);
    }
  };

  return (
    <>
      <Grid container display="flex" justifyContent="flex-end">
        <Box
          bgcolor="#FA3EB1"
          color="#fff"
          textAlign="center"
          fontSize={{ xs: "15px", sm: "16px" }}
          fontWeight="700"
          borderRadius="24px"
          py="5px"
          px={{ xs: "15px", sm: "20px" }}
          sx={{ cursor: "pointer" }}
          onClick={() => {
            gerarPdf();
          }}
        >
          <Box component="span" mr="5px">
            Baixar proposta
          </Box>
          <GetApp sx={{ width: "20px", height: "20px" }} htmlColor="#fff" />
        </Box>
      </Grid>
      <Box zIndex="-1000" position="absolute" top="-100000px" left="-100000px">
        <Box py={2} display="flex" justifyContent="center">
          <div
            ref={(el) => {
              pdfRef.current = [...pdfRef.current, el];
            }}
          >
            <Pagina1
              titulo={<h1>MERCADO LIVRE DE ENERGIA</h1>}
              subtitulo={<h2>Portabilidade com a LUZ</h2>}
            />
          </div>
        </Box>
        <Box py={2} display="flex" justifyContent="center">
          <div
            ref={(el) => {
              pdfRef.current = [...pdfRef.current, el];
            }}
          >
            <Pagina4
              titulo={quemSomos.titulo}
              conteudo={<>{quemSomos.conteudo}</>}
              bordaConteudo={false}
            />
          </div>
        </Box>

        <Box py={2} display="flex" justifyContent="center">
          <div
            ref={(el) => {
              pdfRef.current = [...pdfRef.current, el];
            }}
          >
            <Pagina6
              titulo={
                <h1>
                  CONHEÇA
                  <br /> O GRUPO DELTA
                </h1>
              }
              conteudoEsquerda={
                <Box>
                  <Box pb={6}>{grupoDelta.esquerda.texto}</Box>
                  <Box className={classes.card}>
                    {grupoDelta.esquerda.cards.map((card, index) => (
                      <Box p={2} className={classes.cardConteudo} key={index}>
                        {card.titulo}
                        {card.texto}
                      </Box>
                    ))}
                  </Box>
                </Box>
              }
              conteudoDireita={<GrupoDelta />}
            />
          </div>
        </Box>
        <Box py={2} display="flex" justifyContent="center">
          <div
            ref={(el) => {
              pdfRef.current = [...pdfRef.current, el];
            }}
          >
            <Pagina3
              titulo={
                <Box pt={2}>
                  <h1>
                    ALGUNS DE <span>NOSSOS CLIENTES</span>
                  </h1>
                </Box>
              }
              conteudo={
                <Box px={2} pt={6}>
                  <img
                    src={ImagemClientes}
                    className="img-fluid"
                    alt="Imagem clientes"
                  />
                </Box>
              }
              bordaConteudo={false}
            />
          </div>
        </Box>
        <Box py={2} display="flex" justifyContent="center">
          <div
            ref={(el) => {
              pdfRef.current = [...pdfRef.current, el];
            }}
          >
            <Pagina2
              titulo={<h1>VANTAGENS DE ESCOLHER A LUZ</h1>}
              conteudo={
                <Grid container spacing={3} px={4}>
                  {dados1.map((card, index) => (
                    <Card dados={card} key={index} />
                  ))}
                </Grid>
              }
              bordaConteudo={false}
            />
          </div>
        </Box>
        <Box py={2} display="flex" justifyContent="center">
          <div
            ref={(el) => {
              pdfRef.current = [...pdfRef.current, el];
            }}
          >
            <Pagina5
              titulo={<h1>MEDIDOR INTELIGENTE E PLATAFORMA</h1>}
              subtitulo={<h2>LUZ</h2>}
              conteudo={
                <Box>
                  {medidor.map((card, index) => (
                    <CardLinha dados={card} key={index} />
                  ))}
                </Box>
              }
            />
          </div>
        </Box>
        <Box py={2} display="flex" justifyContent="center">
          <div
            ref={(el) => {
              pdfRef.current = [...pdfRef.current, el];
            }}
          >
            <Pagina3
              titulo={
                <>
                  <h2>
                    MERCADO LIVRE DE ENERGIA <br />
                    <span>PRODUTO INDICADO</span>
                  </h2>
                  <h4>*Depende do perfil de consumo e estrutura da empresa.</h4>
                </>
              }
              conteudo={
                <Grid
                  container
                  spacing={3}
                  px={4}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  height="80%"
                >
                  {dados3.map((card, index) => (
                    <Card
                      dados={card}
                      key={index}
                      color="#FFFFFF"
                      fontSize="18px"
                      iconeSize="50px"
                      colunas={6}
                      maxHeight="430px"
                    />
                  ))}
                </Grid>
              }
              bordaConteudo={false}
            />
          </div>
        </Box>
        <Box py={2} display="flex" justifyContent="center">
          <div
            ref={(el) => {
              pdfRef.current = [...pdfRef.current, el];
            }}
          >
            <Pagina2
              titulo={<h1>INFORMAÇÕES SOBRE SUA UNIDADE</h1>}
              conteudo={<GraficoConsumo dadosProposta={dadosCliente} />}
              bordaConteudo={false}
            />
          </div>
        </Box>
        {dadosPlanos[0] && (
          <Box py={2} display="flex" justifyContent="center">
            <div ref={pdfRefGrafico1}>
              <Pagina2
                titulo={<h2>PROJEÇÕES DE ECONOMIA - DESCONTO GARANTIDO</h2>}
                conteudo={<GraficoMercado dadosPlano={dadosPlanos[0]} />}
                bordaConteudo={false}
              />
            </div>
          </Box>
        )}
        {dadosPlanos[1] && (
          <Box py={2} display="flex" justifyContent="center">
            <div ref={pdfRefGrafico2}>
              <Pagina2
                titulo={<h2>PROJEÇÕES DE ECONOMIA - DESCONTO GARANTIDO</h2>}
                conteudo={<GraficoMercado dadosPlano={dadosPlanos[1]} />}
                bordaConteudo={false}
              />
            </div>
          </Box>
        )}
        {dadosPlanos[2] && (
          <Box py={2} display="flex" justifyContent="center">
            <div ref={pdfRefGrafico3}>
              <Pagina2
                titulo={<h2>PROJEÇÕES DE ECONOMIA - DESCONTO GARANTIDO</h2>}
                conteudo={<GraficoMercado dadosPlano={dadosPlanos[2]} />}
                bordaConteudo={false}
              />
            </div>
          </Box>
        )}
        <Box py={2} display="flex" justifyContent="center">
          <div
            ref={(el) => {
              pdfRef.current = [...pdfRef.current, el];
            }}
          >
            <Pagina2
              titulo={
                <h1>
                  COMO SERÁ <span>NOSSA JORNADA</span>
                </h1>
              }
              conteudo={<Passos passo={2} />}
              bordaConteudo={false}
            />
          </div>
        </Box>
      </Box>
    </>
  );
};

PropostaPdf.propTypes = {
  dadosPlanos: PropTypes.arrayOf(
    PropTypes.shape({
      anos: PropTypes.number,
      economiaAnual: PropTypes.number,
      economiaMensal: PropTypes.number,
      economiaTotal: PropTypes.number,
      percentualDesconto: PropTypes.number,
      nomePlano: PropTypes.string,
      periodoEconomia: PropTypes.shape({
        inicio: PropTypes.string,
        fim: PropTypes.string
      })
    })
  ),
  dadosCliente: PropTypes.shape(),
  setCarregando: PropTypes.func
};

PropostaPdf.defaultProps = {
  dadosPlanos: [],
  dadosCliente: {},
  setCarregando: () => {}
};

export default PropostaPdf;
