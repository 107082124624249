import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Loader } from "../../../../../componentes";
import ModalMensagemErro from "../../../../../componentes/ModalMensagemErro";

import { tipoClienteDocumento } from "../../../../../global/constantes";
import EsqueletoEtapa from "../../Componentes/EsqueletoEtapa";
import PassoDocumento from "../../Componentes/Passos/Documento";
import PassoFoto from "../../Componentes/Passos/Foto";
import UploadCard from "../../Componentes/UploadCard";
import EnvioDocumentosHelper from "./helper";

import IconeFoto from "../../../../../assets/icones/workflow/documento-foto.svg";
import IconeVerso from "../../../../../assets/icones/workflow/documento-verso.svg";

function EnvioDocumentos({
  onProximaEtapa,
  pf,
  onVoltarEtapa,
  clienteId,
  showButton,
  unidadeId,
  prazo
}) {
  const [loadingGeral, setLoadingGeral] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showLoaderFoto, setShowLoaderFoto] = useState(false);
  const [showLoader3, setShowLoader3] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const [listaArquivoTipo1, setListaArquivosTipo1] = useState([]);
  const [listaArquivoTipo2, setListaArquivosTipo2] = useState([]);
  const [listaArquivoTipo3, setListaArquivosTipo3] = useState([]);
  const [modalAcao, setModalAcao] = useState(false);
  const [mensagemModal, setMensagemModal] = useState("");
  const [tentativa, setTentativa] = useState(1);

  const handleMensagemModal = (mensagem) => {
    setTimeout(() => {
      setModalAcao(true);
      setMensagemModal(mensagem);
    }, 2000);
  };

  function calcularTamanhoArquivos(listaTipo1, listaTipo2) {
    const listaTodosArquivos = listaTipo1?.concat(listaTipo2);

    const valorInicial = 0;
    const soma = listaTodosArquivos?.reduce(function (acumulador, valorAtual) {
      return acumulador + valorAtual.size;
    }, valorInicial);

    return soma;
  }

  const validarUrlDocumento = async (arquivos) => {
    const arquivoValidacao =
      arquivos.map((item) => ({
        tipoDocumento: item?.url?.tipoDocumento,
        nomeGuid: item?.url?.nomeGuid,
        nomeArquivo: item?.url?.nomeArquivo,
        extensao: item?.url?.extensao
      })) || [];

    const model = {
      arquivos: arquivoValidacao,
      clienteId,
      operacaoControleId: arquivos?.[0].url?.operacaoControleId,
      tentativa,
      unidadeId,
      dataValidade: window.moment(new Date(2090, 12, 31)).format("YYYY-MM-DD")
    };

    const {
      data,
      sucesso,
      mensagem
    } = await EnvioDocumentosHelper.validarUrlDocumento(model, setLoadingGeral);

    if (!sucesso) {
      handleMensagemModal(mensagem);
      setModalAcao(true);
      return;
    }

    if (data?.tentativa === 1 && data?.aprovado?.toLowerCase() === "n") {
      handleMensagemModal(data?.mensagem);
      setTentativa((value) => value + 1);
      return;
    }

    EnvioDocumentosHelper.exibirSucesso("Arquivos foram salvos com sucesso!");
    setTimeout(() => {
      onProximaEtapa();
    }, 1000);
  };

  const getBinaryFrom = async (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result));

      reader.readAsArrayBuffer(file);
    });
  };

  const uploadFileToS3 = async (arquivos) => {
    try {
      const promises = arquivos.map(async (item) => {
        const currentFileBuffer = await getBinaryFrom(item?.arquivo?.[0]);
        if (!item?.url?.urlPreAssinadaArquivo) return;
        await fetch(decodeURI(item?.url?.urlPreAssinadaArquivo), {
          method: "PUT",
          body: currentFileBuffer,
          headers: {
            "Content-type": "multipart/form-data"
          }
        });
      });

      await Promise.all(promises);

      validarUrlDocumento(arquivos);
    } catch (error) {
      EnvioDocumentosHelper.exibirErro("Erro ao fazer upload AWS!");
    }
  };

  const normalizaArquivos = (doc, tipo, tipoDoc) => {
    const newDoc = doc.filter((item) => item.tipoDocumento === tipo);

    const newArray = tipoDoc?.map((doc, index) => ({
      arquivo: [doc],
      url: newDoc[index]
    }));

    return newArray;
  };

  const arquivosNomes = (doc) => {
    return doc?.map((item) => item.name) || [];
  };

  const onClickProximo = async () => {
    const documentosIdentificacao = listaArquivoTipo1;
    const documentosEscolhidos = listaArquivoTipo2;
    const documentosContrato = listaArquivoTipo3;
    const tamanhoArquivos = listaArquivoTipo2?.concat(listaArquivoTipo3);

    if (
      calcularTamanhoArquivos(documentosIdentificacao, tamanhoArquivos) >
      94371840
    ) {
      handleMensagemModal("Não é permitido enviar mais que 90MB de arquivos!");
    } else {
      const model = {
        clienteId,
        unidadeId,
        dataValidade: window
          .moment(new Date(2090, 12, 31))
          .format("YYYY-MM-DD"),
        arquivos: [
          {
            tipoDocumento: 1,
            arquivos: arquivosNomes(documentosIdentificacao)
          },
          {
            tipoDocumento: 2,
            arquivos: arquivosNomes(documentosEscolhidos)
          },
          {
            tipoDocumento: 3,
            arquivos: arquivosNomes(documentosContrato)
          }
        ]
      };

      const response = await EnvioDocumentosHelper.salvarArquivo(
        model,
        setLoadingGeral
      );
      if (response && response.sucesso) {
        const documents = response?.data?.clienteDocumentoUrl || [];
        const arrayS3 = [
          ...normalizaArquivos(documents, 1, documentosIdentificacao),
          ...normalizaArquivos(documents, 2, documentosEscolhidos),
          ...normalizaArquivos(documents, 3, documentosContrato)
        ];

        uploadFileToS3(arrayS3);
      }
    }
  };

  const addArquivos = async (arquivos, tipo) => {
    if (tipo === 1) {
      setShowLoader(true);
      setListaArquivosTipo1(arquivos);
      setTimeout(() => {
        setShowLoader(false);
      }, 2000);

      if (listaArquivoTipo2.length > 0) {
        if (pf) {
          setDisabledButton(false);
        }
        if (!pf && listaArquivoTipo3.length > 0) {
          setDisabledButton(false);
        }
      }
    } else if (tipo === 2) {
      setShowLoaderFoto(true);
      setListaArquivosTipo2(arquivos);
      setTimeout(() => {
        setShowLoaderFoto(false);
      }, 2000);

      if (listaArquivoTipo1.length > 0) {
        if (pf) {
          setDisabledButton(false);
        }
        if (!pf && listaArquivoTipo3.length > 0) {
          setDisabledButton(false);
        }
      }
    } else {
      setShowLoader3(true);
      setListaArquivosTipo3(arquivos);
      setTimeout(() => {
        setShowLoader3(false);
      }, 2000);

      if (listaArquivoTipo2.length > 0 && listaArquivoTipo1.length > 0) {
        setDisabledButton(false);
      }
    }
  };

  const ondDeleteFile = async (arquivos, tipo) => {
    if (tipo === 1) {
      if (listaArquivoTipo1.length === 1) {
        setDisabledButton(true);
        setListaArquivosTipo1([]);
      }
      setListaArquivosTipo1((oldState) =>
        oldState?.filter((item) => item.name !== arquivos.name)
      );
    } else if (tipo === 2) {
      if (listaArquivoTipo2.length === 1) {
        setDisabledButton(true);
      }
      setListaArquivosTipo2((oldState) =>
        oldState?.filter((item) => item.name !== arquivos.name)
      );
    } else {
      if (listaArquivoTipo3.length === 1) {
        setDisabledButton(true);
      }
      setListaArquivosTipo3((oldState) =>
        oldState?.filter((item) => item.name !== arquivos.name)
      );
    }
  };

  const subtituloPf =
    "Anexe uma foto sua, segurando seu documento próximo ao seu rosto. Por favor, tenha cuidado com a iluminação do ambiente.";
  const subtituloPj =
    "Anexe a documentação societária. Lembre-se de incluir os documentos que dão poderes aos representantes informados acima.";

  const passoPj = `<b>Documentos aceitos</b><br/><ul>
  <li>Constituição da sociedade;</li>
  <li>Procuração dos representantes legais, caso não estejam na constituição da sociedade.</li>`;

  return (
    <>
      <ModalMensagemErro
        item={modalAcao}
        titulo="Algo deu errado"
        mensagem={mensagemModal}
        onCancelar={() => setModalAcao(false)}
        onConfirmar={() => {}}
        texto="Tentar novamente"
      />
      <Loader loading={loadingGeral}>
        <EsqueletoEtapa
          prazo={prazo}
          titulo="Envio de documentos"
          subtitulo="Envie os documentos solicitados abaixo para realizarmos a análise de segurança e continuar com a sua migração para a LUZ."
          onClickProximo={() => onClickProximo()}
          onClickVoltar={onVoltarEtapa}
          disabledButton={disabledButton}
          showButton={showButton}
        >
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <UploadCard
                  showLoader={showLoader}
                  limite={10}
                  titulo="Documento de identificação"
                  tituloUpload="Envie a foto ou arquivo .PDF do documento"
                  subtitulo="Anexe uma foto ou arquivo, contendo a frente e o verso do seu documento. É importante que as informações do documento estejam legíveis na foto."
                  htmlPasso="<b>Documentos aceitos:</b><br/><ul>
                <li>Carteira de identidade nacional ou local (RG).</li>
                <li>Carteira Nacional de Habilitação (CNH).</li></ul>"
                  passoCustomizado={<PassoDocumento />}
                  onAddArquivoUpload={(arquivos, tipo) => {
                    addArquivos(arquivos, tipo);
                  }}
                  renderizaComponente
                  onDeleteArquivoUpload={(arquivos, tipo) => {
                    ondDeleteFile(arquivos, tipo);
                  }}
                  tipo={tipoClienteDocumento.Identificacao}
                  onClickDownload={() => null}
                  disabledCard={false}
                  handleMensagemModal={(mensagem) =>
                    handleMensagemModal(mensagem)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <UploadCard
                  showLoader={showLoaderFoto}
                  limite={10}
                  titulo="Foto com o documento escolhido"
                  tituloUpload="Envie a sua foto segurando o documento"
                  iconeUpload={IconeFoto}
                  subtitulo={subtituloPf}
                  passoCustomizado={<PassoFoto />}
                  onAddArquivoUpload={(arquivos, tipo) => {
                    addArquivos(arquivos, tipo);
                  }}
                  onDeleteArquivoUpload={(arquivos, tipo) => {
                    ondDeleteFile(arquivos, tipo);
                  }}
                  renderizaComponente
                  tipo={tipoClienteDocumento.FotoIdentificação}
                  onClickDownload={() => null}
                  handleMensagemModal={(mensagem) =>
                    handleMensagemModal(mensagem)
                  }
                />
              </Grid>
              {!pf && (
                <Grid item xs={12}>
                  <UploadCard
                    showLoader={showLoader3}
                    limite={10}
                    titulo="Contrato Social/Estatuto Social"
                    tituloUpload="Envie a foto ou arquivo .PDF do documento"
                    iconeUpload={IconeVerso}
                    subtitulo={subtituloPj}
                    htmlPasso={passoPj}
                    passoCustomizado={<PassoDocumento />}
                    onAddArquivoUpload={(arquivos, tipo) => {
                      addArquivos(arquivos, tipo);
                    }}
                    onDeleteArquivoUpload={(arquivos, tipo) => {
                      ondDeleteFile(arquivos, tipo);
                    }}
                    renderizaComponente
                    tipo={tipoClienteDocumento.Contrato}
                    onClickDownload={() => null}
                    handleMensagemModal={(mensagem) =>
                      handleMensagemModal(mensagem)
                    }
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </EsqueletoEtapa>
      </Loader>
    </>
  );
}

EnvioDocumentos.propTypes = {
  onProximaEtapa: PropTypes.func.isRequired,
  onVoltarEtapa: PropTypes.func.isRequired,
  clienteId: PropTypes.number.isRequired,
  showButton: PropTypes.bool.isRequired,
  unidadeId: PropTypes.number.isRequired,
  pf: PropTypes.bool.isRequired,
  prazo: PropTypes.node
};

EnvioDocumentos.defaultProps = {
  prazo: <></>
};

export default EnvioDocumentos;
