import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { validacoesInputs } from "../global/constantes/index";

export function removerCaracteres(valor) {
  return valor
    ? String(valor)
        .replace(/[^\w\s]/gi, "")
        .trim()
    : valor;
}

export function formataMoedaReais(valor, casasDecimais = 2) {
  if (!valor) return "";
  return valor.toLocaleString("pt-br", {
    style: "currency",
    currency: "BRL",
    maximumFractionDigits: casasDecimais
  });
}

export function formataPercentual(valor, casasDecimais = 2) {
  return `${valor?.toLocaleString("pt-br", {
    currency: "BRL",
    maximumFractionDigits: casasDecimais
  })}%`;
}

export function formataTelefone(telefone) {
  if (!telefone) return "";
  telefone = telefone.replace(/\D/g, "");
  telefone = telefone.replace(/^(\d{2})(\d{2})(\d)/g, "+$1 ($2) $3");
  telefone = telefone.replace(/(\d)(\d{4})$/, "$1-$2");
  return telefone;
}

export function formataCnpj(cnpj) {
  if (!cnpj) return "";
  cnpj = cnpj.replace(/[^\d]/g, "");
  return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
}

export function formataCpf(cpf) {
  if (!cpf) return "";
  return cpf
    .replace(/\D/g, "")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");
}

export function formataValorSanitizado(valor) {
  return valor ? String(valor).replace(",", ".") : valor;
}

export function downloadFile(content, fileName, contentType) {
  const a = document.createElement("a");
  const blob = new Blob([content], { type: contentType });

  a.href = window.URL.createObjectURL(blob);
  a.download = fileName;
  a.click();
}

export function formatarValor(num) {
  const value = Math.abs(num);

  switch (true) {
    case value > 999 && value < 999999:
      /* eslint-disable prefer-template */
      return Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k";
    case value > 999999 && value < 999999999:
      return Math.sign(num) * (Math.abs(num) / 1000000).toFixed(1) + "M";
    case value > 999999999:
      return Math.sign(num) * (Math.abs(num) / 1000000000).toFixed(1) + "Bi";
    default:
      return Math.sign(num) * Math.abs(num);
  }
}

export function mesesParaAnos(meses) {
  if (!meses || meses < 12) return "";
  // eslint-disable-next-line radix
  return `${parseInt(meses / 12)}`;
}

const extenso = require("numero-por-extenso");

export function numeroPorExtenso(numero) {
  if (typeof numero !== "number") return "";
  return extenso.porExtenso(numero);
}

export function numeroMonetarioPorExtenso(numero) {
  if (typeof numero !== "number") return "";
  return extenso.porExtenso(numero, extenso.estilo.monetario);
}

export function numeroPercentualPorExtenso(numero) {
  if (typeof numero !== "number") return "";
  return extenso.porExtenso(numero, extenso.estilo.porcentagem);
}

export function convertBase64(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
}

export function removerAcentos(valor) {
  if (!valor) return "";
  return valor?.normalize("NFD").replaceAll(/[\u0300-\u036f]/g, "");
}

export function removeMaskTelefone(telefone) {
  if (!telefone) return "";
  return telefone?.replace(/[^a-zA-Z0-9]/g, "");
}

export function removeMaskCpfCnpj(cpjCnpj) {
  if (!cpjCnpj) return "";
  return cpjCnpj?.replace(/[^a-zA-Z0-9]/g, "");
}

export function removeMaskCep(cep) {
  if (!cep) return "";
  return cep?.replace(/[^a-zA-Z0-9]/g, "");
}

export function removeMaskRG(rg) {
  if (!rg) return "";
  return rg?.replace(/[^a-zA-Z0-9]/g, "");
}

export function existeAcento(value) {
  if (!value) return "";
  value.toLowerCase();
  if (
    value.match(new RegExp("[ÁÀÂÃ]", "gi"), "a") ||
    value.match(new RegExp("[ÉÈÊ]", "gi"), "e") ||
    value.match(new RegExp("[ÍÌÎ]", "gi"), "i") ||
    value.match(new RegExp("[ÓÒÔÕ]", "gi"), "o") ||
    value.match(new RegExp("[ÚÙÛ]", "gi"), "u") ||
    value.match(new RegExp("[Ç]", "gi"), "c")
  ) {
    return true;
  }
  return false;
}

function trimObject(str) {
  if (typeof str !== "string") return str;
  return str?.trim();
}

export function validacaoTrimObject(obj) {
  const data = Object.entries(obj).reduce((newObject, [key, value]) => {
    newObject[key] = trimObject(value);
    return newObject;
  }, {});

  return data;
}

// usar com MaterialInputTexto
export function formatarBrasileira(value) {
  if (!value) return "";
  if (Number.isNaN(value)) return value;

  let valor = value.replace(/\D/g, "");
  valor = (valor / 100).toFixed(2);
  valor = valor.replace(".", ",");
  valor = valor.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  return `R$ ${valor}`;
}

export const handlePdf = async (html, nomeArquivo) => {
  const iframe = document.createElement("iframe");
  iframe.style.visibility = "hidden";
  document.body.appendChild(iframe);
  const iframedoc = iframe.contentDocument || iframe.contentWindow.document;
  iframedoc.body.innerHTML = html;

  let imgData = "";

  await html2canvas(iframedoc.body, {
    scale: 2,
    foreignObjectRendering: false,
    logging: false,
    proxy: "/proxy"
  }).then((canvas) => {
    imgData = canvas.toDataURL("image/jpeg", 0.3);
  });

  // eslint-disable-next-line new-cap
  const doc = new jsPDF({
    orientation: "portrait",
    unit: "mm",
    format: "A4",
    compress: true
  });

  doc.addImage({
    imageData: imgData,
    format: "JPEG",
    x: 0,
    y: 0,
    width: 210,
    height: 297,
    alias: undefined,
    compression: "FAST",
    rotation: 0
  });

  doc.save(nomeArquivo);
  document.body.removeChild(iframe);
};

// 15.000,00 -> 150000.00
// 0,750 -> 0.75
export function formatarValorDecimalSalvar(valor) {
  return valor
    ? parseFloat(
        String(valor)
          ?.replace(/[^0-9,.]/g, "")
          ?.replace(".", "")
          ?.replace(",", ".")
          ?.trim()
      )
    : valor;
}

export function formatarValorSalvarFromForm(dado) {
  const formatado = dado
    ? dado
        .replace(" MWh", "")
        ?.replace(" kW", "")
        ?.replace("R$ ", "")
        ?.replace("%", "")
        ?.replaceAll(".", "")
        ?.trim()
    : dado;

  return formatado;
}

export function validarErrors(errors) {
  if (Object?.keys(errors)?.length <= 0) return [];

  const data =
    Object?.entries(errors?.errors)?.map(([key, value]) => ({
      nameError: key,
      valueError: value
    })) || [];

  return data;
}

export const downloadFileAWS = (url, nomeArquivo) => {
  const element = document.createElement("a");
  element.setAttribute("href", url);
  element.setAttribute("download", nomeArquivo);
  element.style.display = "none";
  element.setAttribute("target", "_blank");
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export const periodoData = (dtFim, dtInicio) => {
  const periodo = window
    .moment([dtFim])
    .diff(window.moment([dtInicio]), "months", true);

  return periodo || "";
};

export const fieldEmpty = (valor) => {
  return String(valor?.trim())?.length > 0;
};

export const validarDDDs = (telefone) => {
  if (!telefone) return;
  const dddsValidos = [
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "21",
    "22",
    "24",
    "27",
    "28",
    "31",
    "32",
    "33",
    "34",
    "35",
    "37",
    "38",
    "41",
    "42",
    "43",
    "44",
    "45",
    "46",
    "47",
    "48",
    "49",
    "51",
    "53",
    "54",
    "55",
    "61",
    "62",
    "63",
    "64",
    "65",
    "66",
    "67",
    "68",
    "69",
    "71",
    "73",
    "74",
    "75",
    "77",
    "79",
    "81",
    "82",
    "83",
    "84",
    "85",
    "86",
    "87",
    "88",
    "89",
    "91",
    "92",
    "93",
    "94",
    "95",
    "96",
    "97",
    "98",
    "99"
  ];

  const ddd = removeMaskTelefone(telefone)?.replace("55", "")?.substring(0, 2);
  const celular = removeMaskTelefone(telefone)?.substring(4);
  const codigo = removeMaskTelefone(telefone)?.substring(0, 2);

  let validacao = null;

  if (celular?.length === 9 && celular?.[0] !== "9") {
    validacao = validacoesInputs.celular;
  } else if (celular?.length < 8) {
    validacao = validacoesInputs.telefone;
  } else if (codigo !== "55") {
    validacao = validacoesInputs.ddi;
  } else if (!dddsValidos?.includes(ddd)) {
    validacao = validacoesInputs.ddd;
  }

  return validacao;
};

export const validarNomeCompleto = (value) => {
  if (!value) return;

  let validacao = null;

  if (!fieldEmpty(value)) {
    validacao = validacoesInputs.obrigatorio;
  }

  const array = value
    ?.toLowerCase()
    ?.trim()
    ?.split(" ")
    ?.filter((item) => item !== "");

  // if (new Set(array)?.size !== array?.length) {
  //   validacao = validacoesInputs.nomeSobrenome;
  // } else if (array.length === 0) {
  //   validacao = validacoesInputs.obrigatorio;
  // } else if (array[0]?.length === 1) {
  //   validacao = validacoesInputs.nomeSobrenomeValido;
  // } else if (array.at(-1) && array.at(-1).length === 1) {
  //   validacao = validacoesInputs.nomeSobrenomeValido;
  // }
  if (!array?.[1]) {
    validacao = validacoesInputs.nomeSobrenomeCompleto;
  }

  return validacao;
};

export const validarEmail = (email) => {
  if (!email) return;
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const emailInvalido = new RegExp("teste*", "g");

  let validacao = null;

  if (regex.test(email) && emailInvalido.test(email?.toLowerCase())) {
    validacao = validacoesInputs.email;
  } else if (!fieldEmpty(email)) {
    validacao = validacoesInputs.obrigatorio;
  }

  return validacao;
};
