/* eslint-disable import/no-unresolved */
/* eslint-disable eqeqeq */
import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import { Botao } from "componentes";
import { Base } from "componentes/cores";
import BotaoRetornarListagem from "componentes/botaoRetornarListagem";
import { useForm, useWatch } from "react-hook-form";
import DataTable from "react-data-table-component";
import Coluna from "componentes/tabelaPaginada/colunas/coluna";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import Loader from "componentes/loader";
import MaterialInputTexto from "componentes/inputTexto/materialInput";
import { useStyles, customStyles, conditionalRowStyles } from "./style";
import {
  buscaExecutarProjecaoView,
  buscaParametrosProjecoes,
  buscaStatusDoGrupo
} from "../../../servicos/grupoProjecoes";
import {
  orquestrargrupo,
  reprocessaProjecaoGrupo
} from "../../../servicos/projecaoTarifaria";
import ModalInformacao from "componentes/modalVerificacao";
import Switch from "componentes/switch";

const ExecucaoProjecaoListar = () => {
  const { idgrupo, nomegrupo, idcenario } = useParams();
  const usuarioGlobal = useSelector((state) => state.usuario);
  const history = useHistory();
  const classes = useStyles();

  const [atual, setAtual] = useState();
  const [atualFiltrado, setAtualFiltrado] = useState([]);
  const [carregandoDados, setCarregandoDados] = useState(false);
  const [
    deveLimparLinhasSelecionadas,
    setDeveLimparLinhasSelecionadas
  ] = useState(false);
  const [distribuidorasSelecionadas, setDistribuidorasSelecionadas] = useState(
    []
  );
  const [resultadosProjecao, setResultadosProjecao] = useState([]);
  const [modalInfo, setModalInfo] = useState(false);
  const [tituloModal, setTituloModal] = useState("");
  const [mensagemAguarde, setMensagemAguarde] = useState("");
  const [situacaoGrupo, setSituacaoGrupo] = useState("");
  const [sincrono, setSincrono] = useState(true);

  const { register, control } = useForm({
    reValidateMode: "onSubmit"
  });

  const textoBusca = useWatch({
    control,
    name: "textoBusca",
    defaultValue: ""
  });

  const stringBusca = useMemo(() => {
    return textoBusca;
  }, [textoBusca]);

  const colunas = [
    new Coluna("distribuidora", "Distribuidora", false, "210px", "210px"),
    new Coluna("pcatRealizado", "Ano Realizado", false),
    new Coluna("incluida", "Incluída", false),
    new Coluna("execucao", "Execução", false, "190px", "190px"),
    {
      ...new Coluna("analista", "Analista", false, "190px", "190px"),
      wrap: true
    },
    new Coluna("resultado", "Resultado", false),
    new Coluna("status", "Status", false)
  ];

  const onSelectedRowsChange = (row) => {
    setDistribuidorasSelecionadas(row);
  };

  const handleSelectRow = (row) => {
    let retorno = false;
    if (
      row.idProjecaoTarifaria !== null &&
      distribuidorasSelecionadas?.selectedRows?.length > 0
    ) {
      const encontrou = distribuidorasSelecionadas.selectedRows.find(
        (rs) => rs.idDistribuidora === row.idDistribuidora
      );
      retorno = encontrou !== undefined;
    }
    return retorno;
  };

  const limparDistribuidorasSelecionadas = () => {
    setDeveLimparLinhasSelecionadas(true);
    setDistribuidorasSelecionadas([]);
    setDeveLimparLinhasSelecionadas(false);
  };

  const handleMensagem = (status) => {
    const nomeEmpresa = status?.map((item) => item.distribuidora)?.join(", ");
    return (
      <>
        {status?.length > 0 && (
          <Box fontSize="30px" fontWeight="400">
            <Box>{`Processando empresa(s): ${nomeEmpresa}`}</Box>
          </Box>
        )}
      </>
    );
  };

  const handleStatusTabela = (lista) => {
    setResultadosProjecao("Processo iniciado...");
    const status = lista.data.filter(
      (item) => item.status === "Processando" && item.status !== undefined
    );

    const mensagem = handleMensagem(status);
    setResultadosProjecao(mensagem);

    if (status?.length > 0) {
      return true;
    }

    return false;
  };

  const onClickSincrono = (event) => {
    setSincrono(!!event);
  };

  const handleStatusTabelaItem = (lista, distribuidora) => {
    if (lista?.data !== null) {
      setResultadosProjecao("Processo iniciado...");
      const status = lista.data.filter(
        (item) =>
          item.status === "Processando" &&
          item.status !== undefined &&
          item.distribuidora === distribuidora
      );

      const mensagem = handleMensagem(status);
      setResultadosProjecao(mensagem);

      if (status?.length > 0) {
        return true;
      }
    }

    return false;
  };

  const atualizarDistribuidoras = async (distribuidora) => {
    setCarregandoDados(true);
    try {
      return new Promise((resolve) => {
        const timer = setInterval(async () => {
          const lista = await buscaExecutarProjecaoView(idgrupo);

          const response = lista?.data;
          const organizado = [];

          if (!handleStatusTabelaItem(lista, distribuidora)) {
            if (response) {
              response.map((item) =>
                organizado.push({
                  ...item,
                  execucao:
                    item.execucao !== null
                      ? window
                          .moment(item.execucao)
                          .format("DD/MM/YYYY à[s] HH:MM")
                      : ""
                })
              );
            }
            setAtual(organizado);
            setAtualFiltrado(organizado);
            clearTimeout(timer);
            resolve();
          }
        }, 7000);
      });
    } catch (error) {
      setCarregandoDados(false);
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            error?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  const atualizarListaDeDistribuidoras = async () => {
    "aqui";
    setCarregandoDados(true);
    try {
      const lista = await buscaExecutarProjecaoView(idgrupo);
      if (lista?.data !== null) {
        const response = lista?.data;
        const organizado = [];

        if (response) {
          response.map((item) =>
            organizado.push({
              ...item,
              execucao:
                item.execucao !== null
                  ? window.moment(item.execucao).format("DD/MM/YYYY à[s] HH:MM")
                  : ""
            })
          );
        }

        setTimeout(async () => {
          if (handleStatusTabela(lista)) {
            await atualizarListaDeDistribuidoras();
          }
        }, 7000);

        setAtual(organizado);
        setAtualFiltrado(organizado);

        limparDistribuidorasSelecionadas();
      }
      setCarregandoDados(false);
    } catch (error) {
      setCarregandoDados(false);
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            error?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  const obterStatusGrupo = async () => {
    try {
      const retorno = await buscaStatusDoGrupo(idgrupo);

      if (retorno.status === 200 && retorno.data) {
        setSituacaoGrupo(retorno.data.codigo);
      }
    } catch (error) {
      console.info(error);
    }
  };

  function filterByTituloDistribuidora(obj) {
    return (
      obj.distribuidora != null &&
      obj.distribuidora.toUpperCase().includes(textoBusca.toUpperCase())
    );
  }

  const handleFiltrarListaExecucaoProjecao = () => {
    let dadosFiltrados = [];

    if (atual != null && atual.length > 0) {
      dadosFiltrados = atual;

      if (stringBusca != undefined && stringBusca.length > 0) {
        dadosFiltrados = dadosFiltrados.filter(filterByTituloDistribuidora);
      }
    }
    setAtualFiltrado(dadosFiltrados);
  };

  const onClickExecutarProjecoes = async () => {
    setMensagemAguarde("Aguarde...");
    setTituloModal("Executando as projeções e reprojeções selecionadas...");
    setModalInfo(true);
    setCarregandoDados(true);
    /*
      Filtra a seleção para as distribuidoras ainda sem projeção para o grupo corrente
    */
    const novasProjecoes = distribuidorasSelecionadas.selectedRows.filter(
      (item) => item.idProjecaoTarifaria === null
    );
    let promisesNovas = [];
    let promisesRepros = [];
    let resposta = [];

    /*
      Gera lista de IdDistribuidoras e obtém do back os respectivos objetos parâmetros formatados para a projeção
    */
    if (novasProjecoes.length > 0) {
      const idsNovasProjecoes = novasProjecoes
        .map((item) => item.idDistribuidora)
        .toString();
      let parametrosNovasProjecoes = [];
      await buscaParametrosProjecoes(idsNovasProjecoes, idcenario)
        .then((res) => {
          parametrosNovasProjecoes = res.data;
        })
        .catch((rej) => {
          console.dir(rej.data);
        });

      /*
        Gera lista de 'promises' de execução para cada nova projeção selecionada, definindo
        também uma lista para as respostas de cada execução
      */
      promisesNovas = parametrosNovasProjecoes.map(async (objProjetar) => {
        const novaResposta = await orquestrargrupo(
          objProjetar,
          usuarioGlobal.usuario?.id,
          usuarioGlobal.usuario?.nome,
          idgrupo
        );
        resposta.push({
          data: novaResposta.data,
          status: novaResposta.status,
          tipo: "Projeção"
        });
      });
    }

    /*
      Filtra a seleção para as distribuidoras com projeção para o grupo corrente
      e gera lista de IdProjecaoTarifaria
    */
    const reprojecoes = distribuidorasSelecionadas.selectedRows.filter(
      (item) => item.idProjecaoTarifaria !== null
    );

    if (reprojecoes.length > 0) {
      const idsReprojecoes = reprojecoes.map(
        (item) => item.idProjecaoTarifaria
      );

      promisesRepros = idsReprojecoes.map(async (id) => {
        const respostaReproj = await reprocessaProjecaoGrupo(
          id,
          usuarioGlobal.usuario.id,
          usuarioGlobal.usuario.nome,
          false,
          idgrupo
        );
        resposta.push({
          data: respostaReproj.data,
          status: respostaReproj.status,
          tipo: "Reprojeção"
        });
      });
    }

    const allpromises = [...promisesNovas, ...promisesRepros];

    /*
      Aguarda a execução de todas as 'promises'
    */
    await Promise.allSettled(allpromises);

    atualizarListaDeDistribuidoras();

    setMensagemAguarde("");
    setTituloModal("Resultado das Projeções e Reprojeções");
    setResultadosProjecao("Processamento iniciado, aguarde..");
    setCarregandoDados(false);
  };

  const onClickExecutarProjecoesSincrono = async () => {
    setMensagemAguarde("Aguarde...");
    setTituloModal("Executando as projeções e reprojeções selecionadas...");
    setModalInfo(true);
    setCarregandoDados(true);
    /*
      Filtra a seleção para as distribuidoras ainda sem projeção para o grupo corrente
    */
    const novasProjecoes = distribuidorasSelecionadas.selectedRows.filter(
      (item) => item.idProjecaoTarifaria === null
    );

    /*
      Gera lista de IdDistribuidoras e obtém do back os respectivos objetos parâmetros formatados para a projeção
    */
    if (novasProjecoes.length > 0) {
      /*
        Gera lista de 'promises' de execução para cada nova projeção selecionada, definindo
        também uma lista para as respostas de cada execução
      */
      for (let projecao of novasProjecoes) {
        let parametrosNovasProjecoes = [];
        await buscaParametrosProjecoes(projecao.idDistribuidora, idcenario)
          .then((res) => {
            parametrosNovasProjecoes = res.data;
          })
          .catch((rej) => {
            console.dir(rej.data);
          });

        await orquestrargrupo(
          parametrosNovasProjecoes[0],
          usuarioGlobal.usuario?.id,
          usuarioGlobal.usuario?.nome,
          idgrupo
        );

        await atualizarDistribuidoras(projecao.distribuidora);
      }
    }

    /*
      Filtra a seleção para as distribuidoras com projeção para o grupo corrente
      e gera lista de IdProjecaoTarifaria
    */
    const reprojecoes = distribuidorasSelecionadas.selectedRows.filter(
      (item) => item.idProjecaoTarifaria !== null
    );

    if (reprojecoes.length > 0) {
      for (let reprojecao of reprojecoes) {
        await reprocessaProjecaoGrupo(
          reprojecao.idProjecaoTarifaria,
          usuarioGlobal.usuario.id,
          usuarioGlobal.usuario.nome,
          false,
          idgrupo
        );

        await atualizarDistribuidoras(reprojecao.distribuidora);
      }
    }

    limparDistribuidorasSelecionadas();
    setMensagemAguarde("");
    setTituloModal("Resultado das Projeções e Reprojeções");
    setResultadosProjecao("Processamento finalizado.");
    setCarregandoDados(false);
  };

  const onCloseModalInformacao = () => {
    setModalInfo(false);
    setResultadosProjecao([]);
  };

  useEffect(() => {
    obterStatusGrupo();
  }, [nomegrupo]);

  useEffect(() => {
    setTimeout(() => {
      handleFiltrarListaExecucaoProjecao();
    }, 100);
  }, [stringBusca]);

  useEffect(() => {
    const abortController = new AbortController();

    atualizarListaDeDistribuidoras(abortController);

    return () => {
      abortController.abort();
    };
  }, [idgrupo]);

  return (
    <>
      <ModalInformacao
        titulo={tituloModal}
        exibir={modalInfo}
        telaInteira={true}
        mensagem={
          <Box>
            <Loader loading={carregandoDados} className="w-auto">
              <Box fontSize="30px" fontWeight="400" color="#0D0010">
                {mensagemAguarde}
              </Box>
            </Loader>
            <Box fontSize="30px" fontWeight="400">
              {resultadosProjecao}
            </Box>
          </Box>
        }
        onClose={onCloseModalInformacao}
        showBotao={false}
      />
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={11} className={classes.inputCustomContainer}>
          <MaterialInputTexto
            fullWidth
            type="text"
            id="tituloGrupo"
            name="tituloGrupo"
            label="Grupo de Projeções"
            className={classes.search}
            renderIconShowHide={false}
            defaultValue={nomegrupo}
            disabled
          />
        </Grid>
        <Grid item xs={1} className={classes.backButton}>
          <BotaoRetornarListagem voltarPagina />
        </Grid>
      </Grid>
      <Grid container mt={1} spacing={3}>
        <Grid item xs={6} xl={7} alignItems="center">
          <MaterialInputTexto
            type="text"
            id="textoBusca"
            name="textoBusca"
            label="Buscar"
            renderIconShowHide
            searchAdornment
            defaultValue={textoBusca ?? ""}
            ref={register}
            className={classes.search}
            permiteValorBranco
          />
        </Grid>
        <Grid item xs={3}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center"
            }}
          >
            <Switch
              label=""
              checked={sincrono}
              onChange={(evento) => onClickSincrono(evento)}
            />
            <p
              style={{
                color: "white",
                marginBottom: 0,
                marginLeft: 15,
                marginTop: 5
              }}
            >
              {sincrono ? "Sequêncial" : "Paralelo"}
            </p>
          </div>
        </Grid>
        <Grid
          item
          xs={3}
          className={classes.buttonContainer}
          alignItems="center"
        >
          <Botao
            type="submit"
            label="Executar Projeções"
            color={Base.Mango}
            className={classes.button}
            onClick={() =>
              sincrono
                ? onClickExecutarProjecoesSincrono()
                : onClickExecutarProjecoes()
            }
            disabled={
              distribuidorasSelecionadas.length === 0 ||
              distribuidorasSelecionadas.selectedRows.length === 0
            }
          />
        </Grid>
      </Grid>
      <Grid container>
        {atualFiltrado?.length > 0 ? (
          <DataTable
            subHeader
            subHeaderAlign="right"
            columns={colunas}
            data={atualFiltrado}
            keyField={atualFiltrado.idProjecaoTarifaria}
            theme="DeltaEnergia"
            customStyles={customStyles}
            conditionalRowStyles={conditionalRowStyles}
            noDataComponent="Nenhum registro encontrado"
            noHeader
            selectableRows={true}
            clearSelectedRows={deveLimparLinhasSelecionadas}
            selectableRowDisabled={(row) =>
              row.codigoStatus == "CONCLUIDA" ||
              row.pcatRealizado == null ||
              row.spartaRealizado == null ||
              situacaoGrupo == "CONCLUIDO"
                ? true
                : false
            }
            onSelectedRowsChange={onSelectedRowsChange}
            selectableRowSelected={handleSelectRow}
          />
        ) : (
          ""
        )}
      </Grid>
    </>
  );
};

export default ExecucaoProjecaoListar;
