import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import { Box, Button, CircularProgress, Grid, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";

// Redux
import { useSelector } from "react-redux";

// Componentes
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import moment from "moment";
import theme from "themes";
import ConsumoAtual from "../ConsumoAtual";
import ConsumoDiario from "../ConsumoDiario";
import ConsumoHora from "../ConsumoHora";
import ConsumoMensal from "../ConsumoMensal";
import ProjecaoConsumo from "../ProjecaoConsumo";

// Redux
import { store } from "../../../global/redux";
import {
  desabilitarCliente,
  desabilitarEmpresa,
  desabilitarFiltroLateral
} from "../../../global/redux/modulos/usuario/actions";
import { CarregandoPDF, UnidadeNaoSelecionada, useStyles } from "./style";

import { getTipoPerfil } from "../../../global/getTipoPerfil";

const Dashboard = () => {
  const usuario = useSelector((state) => state.usuario);
  const { tipo } = usuario?.usuario;

  const classes = useStyles();
  const [showBotaoPDF, setShowBotaoPDF] = useState(false);
  const [pdf, setPDF] = React.useState({
    status: false
  });
  const {
    empresas,
    empresaSelecionada,
    clientes,
    clienteSelecionado,
    unidades,
    unidadeSelecionada,
    contas
  } = usuario;

  useEffect(() => {
    setShowBotaoPDF(false);
    const timeout = setTimeout(() => {
      setShowBotaoPDF(true);
      clearTimeout(timeout);
    }, 3000);
  }, []);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (getTipoPerfil(tipo)) {
      if (contas?.length === 0) {
        store.dispatch(
          desabilitarEmpresa(empresas?.length === 1 && empresaSelecionada)
        );
        store.dispatch(
          desabilitarCliente(clientes?.length === 1 && clienteSelecionado)
        );

        return () => {
          store.dispatch(desabilitarFiltroLateral(false));
        };
      }
    }
  }, [
    empresas,
    empresaSelecionada,
    clientes,
    clienteSelecionado,
    unidades,
    unidadeSelecionada
  ]);

  const print = () => {
    // const consumoDiarioDiv = document.getElementById("consumoDiario");
    // var getConsumoDiario = function() {
    //     html2canvas(consumoDiarioDiv, { scale: 0.95 }).then((canvas) => {
    //       return canvas.toDataURL('image/png')
    //     })
    //   };

    setPDF({
      status: true
    });

    // eslint-disable-next-line consistent-return
    const getNomeCliente = () => {
      // eslint-disable-next-line no-unused-vars
      const localizado = -1;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < usuario.clientes.length; i++) {
        if (usuario.clientes[i].id === clienteSelecionado) {
          return usuario.clientes[i].nomeFantasia;
        }
      }
    };
    // eslint-disable-next-line consistent-return
    const getNomeUnidade = () => {
      // eslint-disable-next-line no-unused-vars
      const localizado = -1;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < usuario.unidades.length; i++) {
        if (usuario.unidades[i].id === unidadeSelecionada) {
          return usuario.unidades[i].nomeUnidade;
        }
      }
    };

    setTimeout(function () {
      const forPDF = document.querySelectorAll(".printDiv");

      const len = forPDF.length;
      // eslint-disable-next-line new-cap
      const thisPDF = new jsPDF("portrait");

      function header() {
        thisPDF.setDrawColor(
          theme.pdf.drawColorR,
          theme.pdf.drawColorG,
          theme.pdf.drawColorB
        );
        thisPDF.setLineWidth(5.0);
        thisPDF.line(0, 0, 300, 0);
        thisPDF.text(75, 40, "DASHBOARD MEDIÇÃO");
        thisPDF.setFontSize(11);
        thisPDF.text(8, 47, "Cliente:");
        thisPDF.setFont(undefined, "bold");
        thisPDF.text(27, 47, getNomeCliente());
        thisPDF.setFont(undefined, "normal");
        thisPDF.text(8, 55, "Unidade:");
        thisPDF.setFont(undefined, "bold");
        thisPDF.text(27, 55, getNomeUnidade());
        thisPDF.addImage(theme.pdf.logo, "PNG", 8, 10, 35, 25);
      }

      function footer(n) {
        thisPDF.setPage(n);
        thisPDF.setTextColor(theme.pdf.fontFooterColor);
        thisPDF.setFontSize(9);
        thisPDF.setFont(undefined, "normal");
        thisPDF.text(13, 288, moment().format("DD/MM/YYYY"));
        thisPDF.text(theme.texto.empresa, 120, 273);
        thisPDF.text(
          `${theme.texto.enderecoRua},${theme.texto.enderecoNumero}-${theme.texto.enderecoComplemento}`,
          120,
          278
        );
        thisPDF.text(
          `${theme.texto.enderecoBairro} - CEP ${theme.texto.enderecoCep} - ${theme.texto.enderecoCidade} - ${theme.texto.enderecoEstado}`,
          120,
          283
        );
        thisPDF.text(theme.texto.site, 120, 288);
      }

      function paginacao(n) {
        thisPDF.setPage(n);
        thisPDF.setFontSize(7);
        thisPDF.setFont(undefined, "normal");
        thisPDF.text(95, 255, n);
      }

      let z = 0;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < forPDF.length; i++) {
        // eslint-disable-next-line no-loop-func
        html2canvas(forPDF[i]).then((canvas) => {
          // eslint-disable-next-line no-plusplus
          z++;
          const imgData = canvas.toDataURL("image/png");
          if (z === 1) {
            // Grafico 1 - Consumo Atual
            thisPDF.addImage(
              imgData,
              "JPEG",
              1,
              55,
              203.0015555555555,
              85.1560952025851
            );
            header();
            thisPDF.addImage(theme.pdf.footer, "JPEG", 0, 246, 213, 51);
            footer(1);
            paginacao("1/2");
          }
          if (z === 2) {
            // Grafico 2 - Consumo Hora
            thisPDF.addPage();
            thisPDF.addImage(
              imgData,
              "JPEG",
              1,
              135,
              203.0015555555555,
              101.9416119811086
            );
          }
          if (z === 4) {
            // Grafico 3 - Consumo Mensal
            thisPDF.setPage(1);
            thisPDF.addImage(
              imgData,
              "JPEG",
              1,
              145,
              200.0015555555555,
              101.09842729306484
            );
          }
          if (z === 3) {
            // Grafico 4 - Diário
            thisPDF.setPage(2);
            thisPDF.addImage(theme.pdf.footer, "JPEG", 0, 247, 213, 51);

            thisPDF.addImage(
              imgData,
              "JPEG",
              1,
              5,
              203.0015555555555,
              127.52886994465729
            );
            footer(2);
            paginacao("2/2");
          }

          if (z === len) {
            // Save
            thisPDF.save(`Relatório de Consumo - ${moment().format("MMM_Y")}`);
            setPDF({
              status: false
            });
          }
        });
      }
    }, 1000);
  };

  return (
    <>
      {unidadeSelecionada ? (
        <>
          {showBotaoPDF && (
            <Box className={classes.boxPDF}>
              <Button
                style={{ width: "100%", textAlign: "right" }}
                onClick={print}
              >
                {pdf.status ? (
                  ""
                ) : (
                  <IconButton
                    aria-label="Gerar PDF"
                    className={classes.bordaPDF}
                    size="large"
                  >
                    <PictureAsPdfOutlinedIcon
                      className={classes.estiloBotaoPDF}
                    />
                  </IconButton>
                )}
              </Button>
              {pdf.status ? (
                <CircularProgress size={24} className={classes.loaderBranco} />
              ) : (
                <></>
              )}
            </Box>
          )}

          {pdf.status ? (
            <CarregandoPDF>Gerando PDF, por favor aguarde...</CarregandoPDF>
          ) : (
            <></>
          )}

          <Box
            style={
              pdf.status ? { position: "absolute", marginTop: "-3500px" } : {}
            }
          >
            <Grid
              style={{
                width: pdf.status ? 1780 : "auto",
                height: pdf.status ? 190 : "auto"
              }}
              container
              spacing={3}
              p={1}
              className="h-auto"
            >
              <Grid item md={6} sm={12} className="printDiv">
                <ConsumoAtual
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...pdf}
                />
              </Grid>
              <Grid item md={6} sm={12}>
                <ProjecaoConsumo />
              </Grid>
            </Grid>
            <Grid
              style={{
                width: pdf.status ? 1780 : "auto",
                height: pdf.status ? 190 : "auto"
              }}
              container
              spacing={3}
              p={1}
            >
              <Grid item md={pdf.status ? 6 : 5} sm={12} className="printDiv">
                <ConsumoHora
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...pdf}
                />
              </Grid>
              <Grid item md={7} sm={12} className="printDiv">
                <ConsumoDiario
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...pdf}
                />
              </Grid>
            </Grid>
            <Grid
              style={{
                width: pdf.status ? 1780 : "auto",
                height: pdf.status ? 190 : "auto"
              }}
              container
              spacing={3}
              p={1}
              className="mb-3"
            >
              <Grid item md={pdf.status ? 6 : 12} sm={12} className="printDiv">
                <ConsumoMensal
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...pdf}
                />
              </Grid>
            </Grid>
          </Box>
        </>
      ) : (
        <UnidadeNaoSelecionada>
          É necessário escolher uma unidade consumidora para continuar!
        </UnidadeNaoSelecionada>
      )}
    </>
  );
};

export default Dashboard;
