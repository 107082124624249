import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Grid } from '@mui/material';
import { EditOutlined } from "@mui/icons-material";
import DeleteIcon from '@mui/icons-material/Delete';
import DataTable from "react-data-table-component";
import RotasDTO from '../../../rotas/rotasUrlDto';
import { useStyles, customStyles, conditionalRowStyles } from "../vigenciaAtual/style";
import Coluna from 'componentes/tabelaPaginada/colunas/coluna';
import ColunaBotao from "componentes/tabelaPaginada/colunas/colunaBotao";
import ModalConfirmacao from 'componentes/modalConfirmacao';
import { store } from 'global/redux';
import { alertaExibir } from 'global/redux/modulos/alertas/actions';
import { buscarVigenciasBandeiras, deletarBandeira } from '../../../servicos/bandeirasTarifarias';

const BandeiraTarifariaFutura = () => {
  const history = useHistory();
  const classes = useStyles();

  const [data] = useState(moment().format("YYYY-MM-DD"));
  const [futuras, setFuturas] = useState([]);
  const [modalExclusao, setModalExclusao] = useState(false);

  const obterBandeiras = async (abortController) => {
    try {
      const response = await buscarVigenciasBandeiras(data, {
        signal: abortController.signal
      });

      if (response?.status === 200 && response?.data && response?.data?.vigenciaFutura.length > 0) {
        const { vigenciaFutura } = response?.data;
        const resp = [];
        vigenciaFutura.map((item) => (
          resp.push({
            ...item,
            dataInicial: moment(item.dtVigencia).format("L"),
            verde: item.valorVerde.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 3 }),
            amarelo: item.valorAmarelo.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 3 }),
            vermelho1: item.valorVermelho1.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 3 }),
            vermelho2: item.valorVermelho2.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 3 })
          })
        ));
        return setFuturas(resp);
      }
    } catch (error) {
      console.info(error);
    }
  };

  const onClickEditar = (id) => {
    history.push(`${RotasDTO.BandeirasTarifariasCadastrar}/agendada/${id}`);
  };

  const excluir = async (id) => {
    try {
      const response = await deletarBandeira(id);
      if (response.status === 200 && response.data) {
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: response.data
          })
        );

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: error.response.data
        })
      );
    }
  }

  const onConfirmarExclusao = (id) => {
    excluir(id);
    setModalExclusao(false);
  }

  const onClickExcluir = (id) => {
    setModalExclusao(id);
  };

  const colunas = [
    new Coluna("dataInicial", "Data"),
    new Coluna("verde", "Verde"),
    new Coluna("amarelo", "Amarela"),
    new Coluna("vermelho1", "Vermelha 1"),
    new Coluna("vermelho2", "Vermelha 2"),
  ];

  const colunasComEdição = [
    ...colunas,
    new ColunaBotao(
      "editar",
      "",
      onClickEditar,
      <EditOutlined />,
      false,
      "70px",
      "70px",
      true
    )];

  const colunasComExclusão = [
    ...colunasComEdição,
    new ColunaBotao(
      "excluir",
      "",
      onClickExcluir,
      <DeleteIcon />,
      false,
      "70px",
      "70px",
      true
    )
  ]

  useEffect(() => {
    const abortController = new AbortController();
    obterBandeiras(abortController);
    return () => {
      abortController.abort();
    }
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={12} className="mt-5">
          <ModalConfirmacao
            item={modalExclusao}
            onConfirmar={(item) => onConfirmarExclusao(item)}
            mensagem="Tem certeza que deseja excluir essa vigência?"
            onCancelar={() => setModalExclusao(false)}
          />

          <div style={{ display: "flex", height: "100%", width: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              <DataTable
                noHeader
                title="Vigência Futura"
                noDataComponent="Nenhum registro encontrado"
                columns={colunasComExclusão}
                data={futuras}
                theme="DeltaEnergia"
                customStyles={customStyles}
                conditionalRowStyles={conditionalRowStyles}
              />
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container p={2} spacing={4} className={classes.container}>
        <Grid item sm={12} style={{ color: 'white' }}>
          * Observação: Valores em R$ para cada 100 kWh
        </Grid>
      </Grid>
    </>
  )
}

export default BandeiraTarifariaFutura;