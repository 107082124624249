import PropTypes from "prop-types";
import React from "react";

import { Receipt } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";

import FaturasHelper from "../helper";
import { useStyles } from "./style";

import { HtmlTooltip } from "../../../../Graficos/ConsumoAtual/style";

const ColunaNotaFiscal = ({ parametros }) => {
  const classes = useStyles();

  // eslint-disable-next-line consistent-return
  const onClickDownloadNotaFiscal = async (params) => {
    const { sucesso, mensagem } = await FaturasHelper.BaixarNotaFiscal(params);

    if (!sucesso) return FaturasHelper.exibirErro(mensagem);
  };

  return (
    <Grid container spacing={0} className={classes.fundoCelula}>
      <Grid item xs={12}>
        <Button
          onClick={() => onClickDownloadNotaFiscal(parametros)}
          className={`text-white ${classes.botao}`}
        >
          <HtmlTooltip title="Baixar">
            <Receipt />
          </HtmlTooltip>
        </Button>
      </Grid>
    </Grid>
  );
};

ColunaNotaFiscal.propTypes = {
  parametros: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired
};

export default ColunaNotaFiscal;
