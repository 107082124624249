import { makeStyles } from "@mui/styles";
import theme from "themes";

export const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: "transparent",
    color: theme.color.primaryBackgroud,
    marginTop: "3px",
    padding: "8px",
    textAlign: "left"
  }
}));
