
import React, { useState, useEffect} from 'react';
import { useSelector } from "react-redux";
import { Card, Grid } from '@mui/material';
import { useParams, useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { useStyles } from './style';
import BotaoRetornarListagem from 'componentes/botaoRetornarListagem';
import MaterialInputTexto from 'componentes/inputTexto/materialInput';
import Botao from 'componentes/botao';
import { adicionarAnotacao, getById, editarAnotacao } from '../../../servicos/anotacaoUsuario';
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import moment from "moment";


const IncluirEditarAnotacoes = () => {
  const { id } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const usuarioGlobal = useSelector((state) => state.usuario);
  const [descricao, setDescricao] = useState('');

  const { register, errors, handleSubmit } = useForm({
    reValidateMode: 'onSubmit',
  });

  const enviarFormulario = async (dados) => {
    try {

      if(descricao === undefined || descricao === null || descricao.toString().trim() === '')
      {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "Descrição deve ser preenchida. Operação cancelada."
          })
        );
        return;
      }      

      const dadosgravacao = {
        idAnotacaoUsuario : id,
        idUsuario : usuarioGlobal.usuario.id,
        nomeUsuario: usuarioGlobal.usuario.nome,
        descricao: dados.descricao,
        data: moment().format()
      };

      let salvou;

      if(id > 0)
      {
        salvou = await editarAnotacao(dadosgravacao);
      }
      else
      {
        salvou = await adicionarAnotacao(dadosgravacao);
      }

      if (salvou?.status === 200) {
        
        history.goBack();

        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: "Anotação gravada com sucesso"
          })
        );
      }
      else if(salvou?.status === 202)
      {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: `${salvou.data}`
          })
        );
      }

    } catch (error) {
      console.dir(error);
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem:
            `${error?.message}: ${error?.response?.statusText}` ??
            "Erro não previsto, entre em contato com o suporte!"
        })
      );
    }
  };

  const handleChangeDescricao = (event) => {
    setDescricao(event.target.value);
  }

  const aoEnviarFormulario = (dados) => {
    enviarFormulario(dados);
  }

  const obterAnotacao = async () => {
    try
    {
      if(id > 0)
      {
        const anotacao = await getById(id);
        console.dir(anotacao);
        
        if (anotacao?.data !== null) {
          setDescricao(anotacao.data.descricao);
        }
    }

    }
    catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem:
            error?.response?.data?.message ?? error?.message ?? "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  }

  useEffect(() => {
    obterAnotacao();
  }, [id]);

  return <>
    <form className="needs-validation" onSubmit={handleSubmit(aoEnviarFormulario)}>
      <Card className={classes.container}>
        <Grid container p={2} spacing={2} className={classes.container} justifyContent="flex-end">
          <Grid item sm={11}>
            {id > 0 ? "Editar Anotação de Usuário" : "Nova Anotação de Usuário"}
          </Grid>
          <Grid item sm={1}>
            <BotaoRetornarListagem voltarPagina />
          </Grid>
        </Grid>
        <Grid container p={2} spacing={2} className={classes.container}>
          <Grid item xs={12} sm={12}>
            <MaterialInputTexto
              type="text"
              id="descricao"
              name="descricao"
              label="Descrição"
              renderIconShowHide={false}
              defaultValue={descricao}
              ref={register({
                required: 'Campo Descrição é obrigatório!',
              })}
              onBlur = {handleChangeDescricao}
              errors={errors}
            />
          </Grid>       

        </Grid>
        
        <Grid container p={2} spacing={2} className={classes.container}>
          <Grid item lg={4} md={6} sm={12}>
            <Botao type="submit" label="Salvar" className={classes.button} />
          </Grid>
        </Grid>
      </Card>
    </form>
  </>;
};

export default IncluirEditarAnotacoes;
