import RetornoEndpointDto from "global/dto/retornoEndpointDto";
import { obterClientes } from "../../../../servicos/clientesServico";
import { Listar } from "../../servicos/permissaoClienteTipoRelatorioServico";
import * as TipoRelatorioServico from "../../servicos/tipoRelatorioServico";

export default class CategoriaRelatorioHelper {
  static async Listar(filtro) {
    try {
      const resultado = await Listar(filtro);

      return RetornoEndpointDto.Sucesso(
        "Categorias de Relatórios obtidos com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu um erro ao obter a lista de Categorias de Relatórios"
      );
    }
  }

  static async ListarTipoRelatorio(filtro) {
    try {
      const resultado = await TipoRelatorioServico.Listar(filtro);

      return RetornoEndpointDto.Sucesso(
        "Categorias de Relatórios obtidos com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu um erro ao obter a lista de Categorias de Relatórios"
      );
    }
  }

  static async obterClientes() {
    try {
      const resultado = await obterClientes();

      return RetornoEndpointDto.Sucesso(
        "Clientes obtidos com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu um erro ao obter a lista de Clientes"
      );
    }
  }
}
