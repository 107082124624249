import React, { useState, useEffect } from "react";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  OutlinedInput,
  Typography
} from "@mui/material";
import { Base } from "componentes/cores";
import { Botao, ModalConfirmacao } from "componentes";
import { useForm } from "react-hook-form";
import Loader from "componentes/loader";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useStyles } from "./style";
import { getExcelComposicaoCCCeOrcamentoCDE } from "../../../servicos/exportacoes";
import { obterCenarios } from "../../../servicos/cenariosSeriesHistoricas";
import BotaoRetornarListagem from "componentes/botaoRetornarListagem";
import InputDataBranco from "componentes/inputTextoDataBranco";
import moment from "moment";
import ModalInformacao from "componentes/modalVerificacao";

const ExportarCDEBrasil = () => {
  const usuarioGlobal = useSelector((state) => state.usuario);
  const classes = useStyles();
  const history = useHistory();
  const [isDownloading, setIsDownloading] = useState(false);
  const [nomeArquivo, setNomeArquivo] = useState("CDEBrasil.xlsx");
  const [showModalGerarExcel, setShowModalGerarExcel] = useState(false);
  const [cenarios, setCenarios] = useState([]);
  const [cenario, setCenario] = useState("");
  const [cenarioDescricao, setCenarioDescricao] = useState("");
  const [anoInicial, setAnoInicial] = useState(new Date());
  const [anoFinal, setAnoFinal] = useState(new Date());
  const [modalInfo, setModalInfo] = useState(false);
  const [msgFalha, setMsgFalha] = useState();

  const { handleSubmit } = useForm({
    reValidateMode: "onSubmit"
  });

  const handleCenarios = async () => {
    try {
      const lista = await obterCenarios();
      if (lista?.status === 200 && lista?.data) {
        setCenarios(lista?.data);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  const handleExportExcel = async () => {
    setIsDownloading(true);
    if (!cenario) {
      setShowModalGerarExcel(false);
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: `Não foi selecionado um Cenário`
        })
      );
    } else if (showModalGerarExcel) {
      try {
        const response = await getExcelComposicaoCCCeOrcamentoCDE(
          cenario,
          anoInicial.getFullYear(),
          anoFinal.getFullYear(),
          nomeArquivo
        );
        setShowModalGerarExcel(false);

        if (response !== "Sucesso") {
          setMsgFalha(`${response}`);
          setModalInfo(true);
        } else {
          store.dispatch(
            alertaExibir({
              tipo: "success",
              mensagem: "Exportação concluída com sucesso."
            })
          );
        }
      } catch (error) {
        setShowModalGerarExcel(false);
        setMsgFalha(`Erro não previsto: ${error}`);
        setModalInfo(true);
        setIsDownloading(false);
      }
    }
    setIsDownloading(false);
  };

  const handleChangeCenario = (event) => {
    cenarios.forEach((item) => {
      if (item.id === event) {
        setCenario(item.id);
        setCenarioDescricao(item.descricao);
      }
    });
  };

  useEffect(() => {
    handleCenarios();
  }, []);

  return (
    <>
      <ModalInformacao
        titulo={"Exportação não realizada com a seguinte falha:"}
        exibir={modalInfo}
        mensagem={msgFalha}
        onClose={() => {
          setModalInfo(false);
        }}
        showBotao={false}
      />
      <ModalConfirmacao
        item={showModalGerarExcel}
        onCancelar={() => setShowModalGerarExcel(false)}
        onConfirmar={handleExportExcel}
        className={classes.container}
        mensagem={
          <pre>
            <Loader loading={isDownloading} className="w-auto">
              <strong>
                <p>Gerar planilha excel CDE Brasil e fazer download?</p>
              </strong>
            </Loader>
            <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-nome-arquivo-excel">
                Nome do Arquivo
              </InputLabel>
              <Tooltip
                placement="top"
                title={
                  <h6>Nome sugerido. Aceite ou edite antes de confirmar.</h6>
                }
              >
                <OutlinedInput
                  id="outlined-nome-arquivo-excel"
                  type="text"
                  value={nomeArquivo}
                  onChange={(e) => setNomeArquivo(e.target.value)}
                  label="Nome do Arquivo a Gerar"
                />
              </Tooltip>
            </FormControl>
          </pre>
        }
      />
      <Grid container item alignItems="center">
        <Grid item xs={11}>
          <Typography
            variant="h5"
            style={{
              color: Base.OffWhite,
              paddingBottom: "50px"
            }}
          >
            Exportação de CDE Brasil
          </Typography>
        </Grid>
        <Grid item xs={1} className={classes.backButton}>
          <BotaoRetornarListagem voltarPagina />
        </Grid>
      </Grid>

      <Grid
        container
        p={2}
        spacing={2}
        rowsap
        alignItems="center"
        className={classes.container}
        justifyContent="space-around"
        direction="column"
        alignItens="center"
      >
        <Grid container style={{ paddingBottom: "20px" }}>
          <Grid item xs={3}></Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={3}>
            <Typography
              variant="body1"
              style={{ color: Base.OffWhite }}
            >
              Ano Inicial
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant="body1"
              style={{ color: Base.OffWhite }}
            >
              Ano Final
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          style={{ paddingBottom: "60px" }}
          direction="row"
          alignItens="flex-end"
          justifyContent="space-around"
          className={classes.container}
        >
          <Grid item xs={3}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="cenario-label">Cenário</InputLabel>
              <Select
                variant="outlined"
                labelId="cenario"
                id="cenario"
                label="Cenário"
                onChange={(e) => handleChangeCenario(e.target.value)}
                fullWidth
              >
                {cenarios.map((item) => (
                  <MenuItem value={item?.id}>{item.nome}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={3} className={classes.customSelect}>
            <InputDataBranco
              type="text"
              id="anoinicial"
              name="anoinicial"
              customValue={anoInicial}
              onChange={(data) => setAnoInicial(data)}
              maxDate={moment("2090-12-31").toDate()}
              minDate={moment("1950-12-31").toDate()}
              format="yyyy"
              views={["year"]}
              renderIconShowHide={false}
            />
          </Grid>
          <Grid item xs={3} className={classes.customSelect}>
            <InputDataBranco
              type="text"
              id="anofinal"
              name="anofinal"
              customValue={anoFinal}
              onChange={(data) => setAnoFinal(data)}
              maxDate={moment("2090-12-31").toDate()}
              minDate={moment("1950-12-31").toDate()}
              format="yyyy"
              views={["year"]}
              renderIconShowHide={false}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid xs={3} className={classes.buttonContainer}>
            <Botao
              type="submit"
              label="Gerar Excel"
              color={Base.Mango}
              className={classes.button}
              onClick={() => {
                setShowModalGerarExcel(true);
              }}
            />
          </Grid>
          <Grid item xs={5}></Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={2}></Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ExportarCDEBrasil;
