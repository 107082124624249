import produce from "immer";

const inicial = {
  usuario: "",
  contas: [],
  contaSelecionada: "",
  token: "",
  menu: [],
  permissoes: [],
  empresas: [],
  empresaSelecionada: "",
  empresaDesabilitada: false,
  clientes: [],
  clienteSelecionado: "",
  clienteDesabilitado: false,
  unidades: [],
  unidadeSelecionada: "",
  unidadeDesabilitada: false,
  nomeArquivoMedicao: "",
  nomeArquivoProinfa: "",
  empresaDefault: "",
  clienteDefault: "",
  unidadeDefault: ""
};

export default function usuario(state = inicial, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "@usuario/salvarUsuario":
        draft.usuario = action?.payload?.usuarioLogado ?? "";
        draft.token = action?.payload?.token ?? "";
        break;
      case "@usuario/salvarContasUsuario":
        draft.contas = action?.payload ?? "";
        break;
      case "@usuario/selecionarConta":
        draft.contaSelecionada = action?.payload ?? "";
        break;
      case "@usuario/salvarEmpresas":
        draft.empresas = action?.payload ?? "";
        break;
      case "@usuario/selecionarEmpresa":
        draft.empresaSelecionada = action?.payload ?? "";
        break;
      case "@usuario/salvarClientes":
        draft.clientes = action?.payload ?? "";
        break;
      case "@usuario/selecionarCliente":
        draft.clienteSelecionado = action?.payload ?? "";
        break;
      case "@usuario/salvarUnidades":
        draft.unidades = action?.payload ?? "";
        break;
      case "@usuario/selecionarUnidade":
        draft.unidadeSelecionada = action?.payload ?? "";
        break;
      case "@usuario/deslogarUsuario":
        draft = inicial;
        localStorage.clear();
        window.location.href = "/logout";
        break;
      case "@usuario/salvarMenu":
        draft.menu = action?.payload ?? "";
        break;
      case "@usuario/desabilitarFiltroLateral":
        draft.empresaDesabilitada = action?.payload;
        draft.clienteDesabilitado = action?.payload;
        draft.unidadeDesabilitada = action?.payload;
        break;
      case "@usuario/desabilitarEmpresa":
        draft.empresaDesabilitada = action?.payload;
        break;
      case "@usuario/desabilitarCliente":
        draft.clienteDesabilitado = action?.payload;
        break;
      case "@usuario/desabilitarUnidade":
        draft.unidadeDesabilitada = action?.payload;
        break;
      case "@mediaco/nomeArquivoMedicao":
        draft.nomeArquivoMedicao = action?.payload ?? "";
        break;
      case "@mediaco/nomeArquivoProinfa":
        draft.nomeArquivoProinfa = action?.payload ?? "";
        break;
      case "@usuario/salvarEmpresaDefault":
        draft.empresaDefault = action?.payload ?? "";
        break;
      case "@usuario/salvarClienteDefault":
        draft.clienteDefault = action?.payload ?? "";
        break;
      case "@usuario/salvarUnidadeDefault":
        draft.unidadeDefault = action?.payload ?? "";
        break;
      default:
        draft = inicial;
        break;
    }
  });
}
