import React from "react";
import PropTypes from "prop-types";

// Componentes Material
import { Grid } from "@mui/material";

// Componentes
import Botao from "../../../../../../componentes/botao";
import { Base } from "../../../../../../componentes/cores";

// Hook de estilo
import { useStyles } from "./style";
import BotaoBranco from "../../../../../../componentes/botaoBranco";

const SemPlanos = ({ onClickVoltar, onClickProsseguir }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.container} spacing={1}>
      <Grid item xs={12}>
        <span className={classes.label}>
          Não tem matriz disponível para esse consumo/demanda, entre em contato com o suporte
        </span>
      </Grid>
      <Grid
        item
        lg={2}
        md={3}
        sm={4}
        xs={12}
        className={classes.containerBotao}
      >
        <BotaoBranco
          className={classes.botaoVoltar}
          label="Voltar"
          onClick={onClickVoltar}
        />
      </Grid>
      <Grid
        item
        lg={2}
        md={3}
        sm={4}
        xs={12}
        className={classes.containerBotao}
      >
        <Botao
          color={Base.Mango}
          label="Continuar"
          onClick={onClickProsseguir}
          className={classes.botao}
        />
      </Grid>
    </Grid>
  );
};

SemPlanos.propTypes = {
  onClickVoltar: PropTypes.oneOfType([PropTypes.func]).isRequired,
  onClickProsseguir: PropTypes.oneOfType([PropTypes.func]).isRequired
};

export default SemPlanos;
