/* eslint-disable import/no-unresolved */
import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import { Grid } from "@mui/material";
import InputDataBranco from "componentes/inputTextoDataBranco";
import { store } from "global/redux";
import { useHistory } from "react-router-dom";
import RotasDTO from "../../../rotas/rotasUrlDto";
import { Base } from "componentes/cores";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { Botao } from "componentes";
import { buscarBandeirasPorAno } from "../../../servicos/bandeirasTarifarias";
import Bandeira from "./componente";
import { useStyles } from "./style";

const Bandeiras = () => {
  const classes = useStyles();
  const history = useHistory();

  const [anoBase, setAnoBase] = useState(window.moment().toDate());
  const handleChangeAnoBase = (ano) => setAnoBase(ano);

  const [dadosDefinitivos, setDadosDefinitivos] = useState([]);
  const [dadosEstimados, setDadosEstimados] = useState([]);
  const [dados, setDados] = useState(false);

  const obterBandeirasPorAno = useCallback(async (ano) => {
    try {
      const response = await buscarBandeirasPorAno(moment(ano).format("YYYY"));
      if (response?.status === 200 && response?.data) {
        const { data } = response;

        const definitivos = [];
        const estimados = [];

        data.map((item) => (
          item.definitivoEstimado === "DF" ? definitivos.push(item) : estimados.push(item)
        ));

        setDadosDefinitivos(definitivos);
        setDadosEstimados(estimados);
        setDados(true);
      }
    } catch (error) {
      setDadosDefinitivos([]);
      setDadosEstimados([]);
      setDados(false);

      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: `Não há dados de Bandeiras para o ano ${ano.getFullYear()}.`
        })
      );
    }
  }, []);

  useEffect(() => {
    if (anoBase) obterBandeirasPorAno(anoBase);
  }, [anoBase, obterBandeirasPorAno]);


  return <>
    <Grid container spacing={4} className={classes.container} justifyContent="flex-end">
      <Grid item sm={2} className={classes.customSelect}>
        <InputDataBranco
          type="text"
          id="anoBase"
          name="anoBase"
          customValue={anoBase}
          onChange={(data) => handleChangeAnoBase(data)}
          minDate={moment().subtract(21, "years").toDate()}
          maxDate={moment("2090-12-31").toDate()}
          format="yyyy"
          views={["year"]}
          renderIconShowHide={false}
          variant="outlined"
        />
      </Grid>
    </Grid>
    <Bandeira
      valorEstimados={dadosEstimados}
      valoresDefinitivos={dadosDefinitivos}
      response={dados}
    />
    <Grid container p={2} spacing={4} className={classes.container}>
      <Grid item sm={12} style={{ color: "white" }}>
        * Observação: Valores em R$ para cada 100 kWh
      </Grid>
    </Grid>
    <Grid container p={2} spacing={3} className={classes.container} justifyContent="flex-start" style={{ marginTop: "30px" }}>
      <Grid xs={2} style={{ paddingTop: "25px" }}>
        <Botao
          type="submit"
          label="Importar"
          color={Base.Mango}
          className={classes.button}
          onClick={() => {
            history.push(RotasDTO.BandeirasTarifariasImportar)
          }}
        />
      </Grid>
    </Grid>
  </>;
}

export default Bandeiras;
