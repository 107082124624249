import React from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

const FooterCards = ({ tipoProduto }) => {
  return (
    <Box textAlign="center" py={3}>
      {tipoProduto && (
        <Box fontSize="12px" fontWeight="700" color="#4F4F4F">
          *Não aplicado sobre os custos da distribuidora.
        </Box>
      )}
      <Box fontSize="14px" fontWeight="400">
        Taxa de adesão GRÁTIS. É necessário um aviso prévio de 30 dias para o
        cancelamento dos planos.
      </Box>
    </Box>
  );
};

FooterCards.propTypes = {
  tipoProduto: PropTypes.bool
};
FooterCards.defaultProps = {
  tipoProduto: false
};

export default FooterCards;
