import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "@mui/styles";

import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";

import { Grid, Chip } from "@mui/material";
import { DropzoneAreaBase } from "material-ui-dropzone";
import Alerta from "componentes/alerta";
import ProgressbarWithLabelValue from "../../../../../componentes/progressBar/index";

import { th, chipStyles, useStyles } from "./style";

export function arrayEquals(a, b) {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
}

export function isPdfOrImageFile(base64) {
  const pdfOrImageSignatures = [
    [...new Uint8Array([0xff, 0xd8, 0xff, 0xe0])], // "JPG"
    [...new Uint8Array([0xff, 0xd8, 0xff, 0xe0])], // "JPEG"
    [...new Uint8Array([0x89, 0x50, 0x4e, 0x47])], // "PNG"
    [...new Uint8Array([0x49, 0x49, 0x2a, 0x00])], // "TIF"
    [...new Uint8Array([0x49, 0x49, 0x2a, 0x00])], // "TIFF"
    [...new Uint8Array([0x47, 0x49, 0x46, 0x38])], // "GIF"
    [...new Uint8Array([0x42, 0x4d])], // "BMP"
    [...new Uint8Array([0x00, 0x00, 0x01, 0x00])], // "ICO"
    [...new Uint8Array([0x25, 0x50, 0x44, 0x46, 0x2d])], // {"PDF"
    [...new Uint8Array([0xdf, 0xbf, 0x34, 0xeb, 0xce])] // {"PDF2":
  ];

  // eslint-disable-next-line camelcase
  const binary_string = window.atob(base64);
  // eslint-disable-next-line camelcase
  const len = binary_string.length;
  const bytes = new Uint8Array(len);
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < len; i++) {
    // eslint-disable-next-line camelcase
    bytes[i] = binary_string.charCodeAt(i);
  }
  return pdfOrImageSignatures.some((x) =>
    arrayEquals(x, [...bytes.slice(0, x.length)])
  );
}

const UploadDropAreaMultiploUnico = React.forwardRef((props, ref) => {
  const {
    acceptedFiles,
    dropAreaText,
    icon,
    id,
    name,
    onAdd,
    onDropRejected,
    progressValue,
    showLoader,
    filesLimit,
    initialFiles,
    renderizaComponente,
    disabled,
    onPreviewChipClick,
    disabledCard,
    handleMensagemModal
  } = props;

  const classes = useStyles();
  const classesChip = chipStyles();

  const [fileObjects, setFileObjects] = useState([]);
  const [alertasUpload, setAlertasUpload] = useState([]);

  useEffect(() => {
    const files = initialFiles?.map((file) => ({ file }));
    setFileObjects(files);
  }, [initialFiles]);

  const [files, setFiles] = useState([]);

  const addFiles = (itens) => {
    if (itens?.length > 0) {
      const newFiles = fileObjects.concat(itens);
      const listaArquivos = newFiles.map((item) => item?.file);
      onAdd(listaArquivos);
      setFileObjects(newFiles);
    }

    // eslint-disable-next-line no-useless-return
    return;
  };

  const handleOnAdd = async (lista) => {
    lista =
      lista.filter(
        (file) => !files.find((f) => f?.file?.name === file?.file?.name)
      ) || [];

    if (lista?.length === 0) {
      handleMensagemModal("O arquivo inserido já existe!");
    }

    setFiles([...files, ...lista]);
    addFiles(lista);
  };

  const handleOnDelete = (valor, index) => {
    onDropRejected(valor?.file);
    const newFiles = fileObjects.slice();
    newFiles.splice(index, 1);
    setFileObjects(newFiles);
    setFiles([]);
  };

  const handleOnDropRejected = (valor) => {
    if (valor[0].size > 31457280) {
      handleMensagemModal("Não são permitidos arquivos maiores que 30MB!");
    } else {
      const arquivo = valor[0];
      handleMensagemModal(`Arquivo ${arquivo.name} inválido!`);
    }
  };

  const handleUpload = () => {};

  return renderizaComponente && initialFiles ? (
    <ThemeProvider theme={th} disabled={disabled}>
      <Grid container spacing={3} alignItems="center" disabled={disabled}>
        <Grid item xs={12} className={classes.containerDropZone}>
          {alertasUpload && alertasUpload?.length
            ? alertasUpload.map((alerta, index) => {
                return (
                  <Alerta
                    key={index}
                    onClose={() => {
                      setAlertasUpload(alertasUpload.slice(0, index));
                    }}
                    {...alerta}
                  />
                );
              })
            : null}
          {!disabled ? (
            <DropzoneAreaBase
              maxFileSize={31457280}
              onDrop={handleUpload}
              acceptedFiles={acceptedFiles}
              filesLimit={filesLimit}
              fileObjects={fileObjects}
              id={id}
              name={name}
              Icon={icon}
              dropzoneText={dropAreaText}
              onAdd={(newFiles) => handleOnAdd(newFiles)}
              showAlerts={false}
              onDelete={(deletedFileObject, index) =>
                handleOnDelete(deletedFileObject, index)
              }
              onDropRejected={(valor) => handleOnDropRejected(valor)}
              ref={ref}
              disabled={disabled}
              dropzoneProps={{ disabled: disabledCard }}
              showPreviews
              showPreviewsInDropzone={false}
              previewGridProps={{
                container: { spacing: 1, direction: "row" }
              }}
              previewChipProps={{
                classes: { root: classesChip.previewChip }
              }}
              previewText=""
              useChipsForPreview
              onPreviewChipClick={(arquivo) =>
                onPreviewChipClick(arquivo?.file?.name)
              }
            />
          ) : (
            <div className={classes.containerListagemImagens}>
              {initialFiles &&
                initialFiles.map((item, idx) => (
                  <div key={idx}>
                    <Chip
                      classes={{ root: classesChip.previewChip }}
                      label={item.name}
                      onClick={() => onPreviewChipClick(item.name)}
                      onDelete={!disabled && handleOnDelete(item)}
                      variant="outlined"
                    />
                  </div>
                ))}
            </div>
          )}
        </Grid>
      </Grid>
      {showLoader && !disabled ? (
        <ProgressbarWithLabelValue progressValue={progressValue} />
      ) : null}
    </ThemeProvider>
  ) : null;
});

UploadDropAreaMultiploUnico.propTypes = {
  acceptedFiles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  dropAreaText: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.any]),
  id: PropTypes.string,
  name: PropTypes.string,
  onAdd: PropTypes.oneOfType([PropTypes.func]),
  onDropRejected: PropTypes.oneOfType([PropTypes.any]),
  progressValue: PropTypes.oneOfType([PropTypes.any]),
  showLoader: PropTypes.oneOfType([PropTypes.any]),
  showPreviews: PropTypes.oneOfType([PropTypes.bool]),
  filesLimit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  initialFiles: PropTypes.oneOfType([PropTypes.any]),
  renderizaComponente: PropTypes.bool,
  imageOrPdfValidate: PropTypes.bool,
  onPreviewChipClick: PropTypes.oneOfType([PropTypes.func]),
  disabledCard: PropTypes.bool,
  handleMensagemModal: PropTypes.oneOfType([PropTypes.func]),
  disabled: PropTypes.bool
};

UploadDropAreaMultiploUnico.defaultProps = {
  acceptedFiles: [
    ".csv, .xls, .xlsx, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values"
  ],
  dropAreaText: "Solte os arquivos para anexá-los ou procurar",
  icon: CloudUploadOutlinedIcon,
  id: "UploadDropArea",
  name: "UploadDropArea",
  onAdd: () => {},
  onDropRejected: () => {},
  progressValue: () => {},
  showLoader: () => {},
  showPreviews: false,
  filesLimit: 1,
  initialFiles: [],
  renderizaComponente: false,
  imageOrPdfValidate: false,
  onPreviewChipClick: () => {},
  disabledCard: false,
  handleMensagemModal: () => {},
  disabled: false
};

export default UploadDropAreaMultiploUnico;
