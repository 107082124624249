import MICROSERVICO from "global/enumeradores/microServico";
import obterApi from "servicos/api";

const api = () => obterApi(MICROSERVICO.PROJECAOTARIFARIA);

export const uploadReceitaAdicionalBandeira = async (idUsuario, nomeUsuario, keyFileS3) => {
    const metodo = "post";
    const url = `v1/ImportReceitaAdicionalBandeira/import?idUsuario=${idUsuario}&nomeUsuario=${nomeUsuario}&keyFileS3=${keyFileS3}`;
    return api()[metodo](url);
};

export const obterValoresReceitaAdicionalBandeira = () => {
    return api().get(`v1/ImportReceitaAdicionalBandeira/getall`);
}

export const obterValoresReceitaAdicionalBandeiraById = (id) => {
    return api().get(`v1/ImportReceitaAdicionalBandeira/GetByIdDistribuidora?idDistribuidora=${id}`);
}
