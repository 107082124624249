import RetornoEndpointDto from "global/dto/retornoEndpointDto";
import { Listar, MudarStatus } from "../../servicos/tipoRelatorioServico";

export default class Helper {
  static async Listar(filtro) {
    try {
      const resultado = await Listar(filtro);

      return RetornoEndpointDto.Sucesso(
        "Lista obtidas com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu um erro ao obter a lista"
      );
    }
  }

  static async MudarStatus(params) {
    try {
      if (!params)
        return RetornoEndpointDto.Erro(
          "Erro ao tentar atualizar"
        );

      const { data } = await MudarStatus(params);

      // executarIntegracaoPerfilCCEE();

      return RetornoEndpointDto.Sucesso(
        "Atualizado com sucesso",
        data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        error?.response?.data?.message ?? "Ocorreu uma falha ao salvar"
      );
    }
  }
}
