import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

// DTO
import { RotasDTO } from "../global/rotas/rotasUrlDto";
import { InterfaceDTO } from "../global/dto/interfacesDto";

const api = () => obterApi(MICROSERVICO.PROFILE);

export const listarFuncionalidades = async () => {
  return api().get("v1/Funcionalidades");
};

export const buscarPorId = async (id) => {
  return api().get(`v1/Funcionalidades?id=${id}`);
};

export const salvarFuncionalidade = async (id, dados) => {
  let metodo = "post";
  let url = "v1/Funcionalidades/";
  if (id > 0) {
    metodo = "put";
    url = `${url}${id}`;
  }
  return api()[metodo](url, dados);
};

export const deletarFuncionalidade = async (item) => {
  return api().patch(`v1/Funcionalidades/${item.id}/ativo/${!item.ativo}`);
};

// Ordenar Funcionalidades
export const ordernaFuncionalidades = async (lista, checked) => {
  return api().put("v1/Funcionalidades/ordenacao", {
    FuncionalidadeOrdenacaoColecao: lista,
    alteraOrdenacao: checked
  });
};

export const usuarioPossuiFuncionalidade = (
  _menu,
  _rotaAtual,
  _rotaValidacao,
  _funcionalidadeInterface
) => {
  const rota = Object.keys(RotasDTO).find(
    // eslint-disable-next-line no-underscore-dangle
    (key) => RotasDTO[key] === RotasDTO[_rotaAtual]
  );

  if (_menu?.length && rota) {
    const funcionalidade = _menu.find((item) =>
      item.functionality.funcionalidadeColecao.find(
        (func) => func.caminho === rota
      )
    );

    if (!funcionalidade) return false;

    if (funcionalidade?.functionality?.funcionalidadeColecao?.length) {
      const segmento = funcionalidade?.functionality?.funcionalidadeColecao.find(
        (item) => RotasDTO[item.caminho] === _rotaValidacao
      );

      if (!segmento) return false;

      if (segmento?.funcionalidadeColecao?.length) {
        const filtro = segmento?.funcionalidadeColecao.find(
          (item) => InterfaceDTO[item.caminho] === _funcionalidadeInterface
        );

        return Boolean(filtro);
      }

      return false;
    }
  }

  return true;
};
