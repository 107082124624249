import { Box, Grid } from "@mui/material";
import React, { useCallback, useEffect, useState, useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import moment from "moment";
import Cofre from "../../../assets/consumoEnergia/cofre.png";
import Velocimento from "../../../assets/consumoEnergia/velocimetro.png";

import theme from "../../../themes";
import { useStyles } from "./style";
import CardConsumoEconomia from "../../../componentes/cardConsumoEconomia";
import InputDataLuz from "../../../componentes/inputDataLuz";
import { store } from "../../../global/redux";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";
import {
  getTelemetriaAtual,
  getTelemetriaDiario,
  getTelemetriaResumo
} from "../../../servicos/temetriaGDServico";
import { Loader } from "../../../componentes";
import SemDados from "../../../componentes/semDados";
import UnidadeNaoSelecionada from "../../../componentes/unidadeNaoSelecionada";
import {
  unidadeConsumidoraGD,
  unidadeConsumidoraGDColaborador
} from "../../../servicos/unidadeConsumidoraGDServico";
import { getConsumoProjecao } from "../../../servicos/dgIntegracaoServico";
import Button from "../componentes/botaoLuz";

const Atual = () => {
  const classes = useStyles();
  const [grafico, setGrafico] = useState(<div />);
  const [carregandoDados, setCarregandoDados] = useState(false);
  const usuarioLogado = useSelector((state) => state.usuario);
  const [dataCategories, setCategories] = useState([]);
  const [dataSeries, setSeries] = useState([]);
  const [selecioneUnidade, setSelecioneUnidade] = useState(false);
  const [dataDescricao, setDataDescricao] = useState(null);
  const [validacao, setValidacao] = useState(true);
  const [consumoDiario, setDadosConsumoDiario] = useState(null);
  const [dadosUnidades, setDadosUnidade] = useState([]);
  const [resumoPrincipal, setResumoPrincipal] = useState([]);
  const [consumoProjecao, setDadosConsumoProjecao] = useState(null);
  const [dataPeriodo, setDataPerido] = useState(null);
  const {
    empresaSelecionada,
    clienteSelecionado,
    unidadeSelecionada,
    usuario
  } = usuarioLogado;

  const { tipo } = usuario;

  useEffect(() => {
    moment.locale("pt");
    const descricao = `${moment().format("DD MMMM")} ${moment().format(
      "YYYY, dddd"
    )}`;
    setDataDescricao(descricao);
    setSelecioneUnidade(true);
  }, []);

  const handleResumoPrincipal = async (
    codigoMedidor,
    tarifa,
    diaFaturamento
  ) => {
    try {
      if (codigoMedidor) {
        const { data, status } = await getTelemetriaResumo(
          codigoMedidor,
          tarifa,
          diaFaturamento
        );
        if (data && status === 200) {
          setResumoPrincipal(data);
        } else if (status === 204) {
          setResumoPrincipal(null);
        }
      }
    } catch (error) {
      if (error?.response?.status !== 200 && error?.response?.status !== 204) {
        setResumoPrincipal(null);
      }
    }
  };

  const handleDataGrafico = async (codigoDescricao, dataHoje, tarifa) => {
    try {
      setCarregandoDados(true);
      if (codigoDescricao && dataHoje) {
        const { data, status } = await getTelemetriaAtual(
          codigoDescricao,
          dataHoje,
          tarifa
        );
        if (data && status === 200) {
          setValidacao(true);
          setCategories(data.map((item) => item.minuto));
          setSeries(data.map((item) => item.pt));
        } else if (status === 204) {
          setValidacao(false);
          setCategories([]);
          setSeries([]);
        }
      }
    } catch (error) {
      if (error?.response?.status !== 200 && error?.response?.status !== 204) {
        setValidacao(false);
      }
    } finally {
      setCarregandoDados(false);
    }
  };

  const handleDataConsumoDia = async (codigoDescricao, dataHoje, tarifa) => {
    try {
      if (codigoDescricao && dataHoje) {
        const { data, status } = await getTelemetriaDiario(
          codigoDescricao,
          dataHoje,
          dataHoje,
          tarifa
        );
        if (data && status === 200) {
          setDadosConsumoDiario(data);
        } else if (status === 204) {
          setDadosConsumoDiario(null);
        }
      }
    } catch (error) {
      if (error?.response?.status !== 200 && error?.response?.status !== 204) {
        setDadosConsumoDiario(null);
      }
    }
  };

  const handleUnidadeConsumidora = async (unidadeId, dataHoje) => {
    try {
      const { data, status } =
        tipo === "Cliente"
          ? await unidadeConsumidoraGD()
          : await unidadeConsumidoraGDColaborador(unidadeId);
      if (data && status === 200) {
        const unidade =
          data?.unidadeConsumidoraGDList?.filter(
            (item) => item.unidadeConsumidoraId === unidadeId
          ) || [];
        setDadosUnidade(unidade);
        if (unidade?.[0]?.medidores?.length > 0) {
          handleDataGrafico(
            unidade?.[0]?.medidores[0].descricaoMedidor,
            dataHoje,
            unidade?.[0]?.somaTarifaCheiaBandeira
          );
          handleDataConsumoDia(
            unidade?.[0]?.medidores[0].descricaoMedidor,
            dataHoje,
            unidade?.[0]?.somaTarifaCheiaBandeira
          );
          handleResumoPrincipal(
            unidade?.[0]?.medidores[0].descricaoMedidor,
            unidade?.[0]?.somaTarifaCheiaBandeira,
            unidade?.[0]?.diaFaturamentoDistribuidora
          );
          setSelecioneUnidade(true);
        } else if (data && status === 204) {
          store.dispatch(
            alertaExibir({
              tipo: "warning",
              mensagem:
                "Não existe medidor cadastrada para esse unidade, selecione outra!"
            })
          );
        }
      }
    } catch (error) {
      if (error?.response?.status !== 200 && error?.response?.status !== 204) {
        setValidacao(false);
      }
    }
  };

  const changeData = (data) => {
    moment.locale("pt");
    const descricao = `${moment(data).format("DD MMMM")} ${moment(data).format(
      "YYYY, dddd"
    )}`;
    setDataDescricao(descricao);
    setDataPerido(data);
  };

  useEffect(() => {
    if (unidadeSelecionada) {
      moment.locale("pt");
      const descricao = `${moment().format("DD MMMM")} ${moment().format(
        "YYYY, dddd"
      )}`;
      const dataDia = moment().format("YYYY-MM-DD");
      setDataDescricao(descricao);
      setDataPerido(dataDia);
      handleUnidadeConsumidora(unidadeSelecionada, dataDia);
    } else {
      setSelecioneUnidade(false);
    }
  }, [empresaSelecionada, clienteSelecionado, unidadeSelecionada]);

  const handleConsumoProjecao = async (unidadeId, cip, bandeira, tarifa) => {
    const { eptTotal, eptProjetado } = resumoPrincipal;
    try {
      if (eptTotal && eptProjetado) {
        const { data, status } = await getConsumoProjecao(
          unidadeId,
          eptTotal,
          eptProjetado,
          cip,
          bandeira,
          tarifa
        );
        if (data && status === 200) {
          setDadosConsumoProjecao(data);
        } else if (status === 204) {
          setDadosConsumoProjecao(null);
        }
      }
    } catch (error) {
      if (error?.response?.status !== 200 && error?.response?.status !== 204) {
        setDadosConsumoProjecao(null);
      }
    }
  };

  useEffect(() => {
    if (dadosUnidades && resumoPrincipal) {
      const unidadeId = dadosUnidades[0]?.unidadeConsumidoraId;
      const cip = dadosUnidades[0]?.cosip;
      const bandeiraTarifaria = dadosUnidades[0]?.bandeiraTarifaria;
      const tarifa = dadosUnidades[0]?.somaTarifaCheiaBandeira;

      handleConsumoProjecao(unidadeId, cip, bandeiraTarifaria, tarifa);
    }
  }, [dadosUnidades, resumoPrincipal]);

  const dataGrafico = useMemo(
    () => ({
      series: [
        {
          data: dataSeries,
          name: "Consumo real",
          type: "area"
        }
      ],
      options: {
        colors: ["rgba(253, 149, 7, 0.64)", "rgba(253, 149, 7, 0.24)"],
        chart: {
          fontFamily: theme.font.family,
          offsetX: 0,
          offsetY: 0,
          width: "100%",
          maxWidth: "100%",
          type: "area",
          animations: {
            enabled: true
          },
          toolbar: {
            show: false
          },
          fontSize: "10px"
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: true,
          fontSize: "14px",
          fontWeight: 700,
          labels: {
            colors: ["#332053"],
            useSeriesColors: false
          },
          markers: {
            fillColors: [
              "linear-gradient(357.04deg, rgba(247, 34, 181, 0.64) 2.11%, rgba(253, 149, 7, 0.64) 75.57%)",
              "linear-gradient(357.04deg, rgba(247, 34, 181, 0.45) 2.11%, rgba(253, 149, 7, 0.45) 75.57%)"
            ]
          }
        },
        stroke: {
          colors: ["rgba(253, 149, 7, 0.64)", "rgba(253, 149, 7, 0.24)"],
          curve: "smooth"
        },
        fill: {
          type: ["gradient", "gradient"],
          gradient: {
            gradientToColors: [
              "rgba(253, 34, 181, 0.64)",
              "rgba(253, 34, 181, 0.24)"
            ],
            type: "vertical",
            shadeIntensity: 0,
            inverseColors: false,
            opacityFrom: 0.8,
            opacityTo: 0.8,
            stops: [20, 100, 100, 100]
          }
        },
        markers: {
          size: 0,
          colors: ["#332053", "#332053"]
        },
        xaxis: {
          categories: dataCategories,
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            show: true
          },
          labels: {
            show: true,
            style: {
              colors: "#4F4F4F",
              fontWeight: "700",
              fontSize: "12px"
            },
            datetimeUTC: true,
            rotate: 0,
            formatter(value) {
              return `
               ${value}
              `;
            }
          },
          position: "bottom",
          tooltip: {
            enabled: false
          },
          tickAmount: 5
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          forceNiceScale: true,
          seriesName: undefined,
          labels: {
            show: true,
            minWidth: 0,
            maxWidth: 160,
            offsetX: 0,
            offsetY: 0,
            rotate: 0,
            style: {
              colors: "#4F4F4F",
              fontWeight: "700",
              fontSize: "12px"
            },
            formatter(value) {
              return `
               ${value} W
              `;
            }
          }
        },
        tooltip: {
          enabled: true,
          theme: false,
          fillSeriesColor: false,
          custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            const horas = w?.globals?.categoryLabels[dataPointIndex];
            const valor = series[seriesIndex][dataPointIndex];
            const nameSeries = w.globals.initialSeries[seriesIndex];

            return `
             <div class=${classes.toolTip}> 
              <div class=${classes.dot}></div>
              <div class=${classes.toolTipText}>
                ${horas} - ${valor} W
                <div>${nameSeries?.name}</div>
              </div>
             </div>
          `;
          }
        }
      }
    }),
    [dataCategories, dataSeries]
  );

  const RenderizarGrafico = useCallback(() => {
    const graficoRender = (
      <div className="mixed-chart">
        <ReactApexChart
          options={dataGrafico?.options}
          series={dataGrafico?.series}
          type="area"
          height="350"
          width="100%"
        />
      </div>
    );

    setGrafico(graficoRender);
  }, [setGrafico, dataCategories, dataSeries]);

  useEffect(() => RenderizarGrafico(), [RenderizarGrafico]);

  return (
    <Loader loading={carregandoDados}>
      {selecioneUnidade ? (
        <Box component="div" px={2} py={4} boxShadow={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={10} display="flex">
              <Box ml={{ lg: 2 }} component="div">
                <InputDataLuz
                  descricao={dataDescricao}
                  onChange={(value) => changeData(value)}
                />
              </Box>
            </Grid>
            <Grid xs={6} md={2}>
              <Box mr={{ lg: 3 }}>
                <Button
                  id="alterarPeriodo"
                  form="hook-form"
                  label="Alterar período"
                  onClick={() =>
                    handleUnidadeConsumidora(unidadeSelecionada, dataPeriodo)
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              {validacao ? grafico : <SemDados />}
            </Grid>
            <CardConsumoEconomia
              xs={12}
              md={6}
              dados={consumoDiario}
              icon={Velocimento}
              title="Consumo do dia"
              content={`R$ ${consumoDiario?.eptFPeriodo || ""} (${
                consumoDiario?.eptPeriodo || ""
              } KWh)`}
            />
            <CardConsumoEconomia
              xs={12}
              md={6}
              dados={consumoProjecao}
              icon={Cofre}
              title="Sua próxima conta deve diminuir em"
              content={`R$ ${consumoProjecao?.economiaProjetadaLUZ || ""} (${
                consumoProjecao?.desconto || ""
              }%)`}
            />
          </Grid>
        </Box>
      ) : (
        <UnidadeNaoSelecionada />
      )}
    </Loader>
  );
};

export default Atual;
