import { makeStyles } from "@mui/styles";
import theme from "../../../../themes";

export const useStyles = makeStyles({
  container: {
    margin: "0 0 24px",
    width: "100%",
    "& .MuiGrid-grid-md-12": {
      padding: "12px"
    }
  },
  itemFiltro: {
    display: "flex",
    justifyContent: "flex-end"
  },
  buttonFiltro: {
    fontSize: "18px",
    maxWidth: "220px"
  },
  containerSemDados: {
    background: theme.color.red,
    color: `${theme.color.secondaryBorderColor} !important`,
    height: "300px",
    marginTop: "2em",
    marginBottom: "2em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
});
