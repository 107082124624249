import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { useStyles } from "./style";
import enumeradorStatus from "../enumeradores/enumeradorStatus";

const ColunaStatus = ({ parametros }) => {
  const classes = useStyles();
  const handleStatus = (status) => {
    switch (status) {
      case enumeradorStatus.VENCIDA:
        return classes.fundoVencido;
      case enumeradorStatus.PAGO:
        return classes.fundoPago;
      case enumeradorStatus.EMABERTO:
        return "";
      default:
        return status;
    }
  }
  return (    
    <Grid container spacing={0} className={classes.fundoBotoes}>
      <Grid item xs={1} className={handleStatus(parametros.status)} />
      <Grid item xs={11} className={classes.textoStatus}>
        {parametros.status}
      </Grid>
    </Grid>
  );
};

ColunaStatus.propTypes = {
  parametros: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired
};

export default ColunaStatus;
