import styled from "styled-components";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import theme from "../../../../themes";
import { Base } from "../../../cores";

export const useStyles = makeStyles(() => ({
  container: {
    bottom: "5px"
  },
  gridItem: {
    margin: "0 50px 0 0"
  },
  backgroundBlack: {
    backgroundColor: theme.color.bgTheme
  },
  menu: {
    backgroundColor: Base.White,
    borderRadius: "8px",
    padding: "0 !important",
    boxShadow:
      "0px 8px 16px 0px rgba(0, 0, 0, 0.08), 0px 2px 8px 0px rgba(0, 0, 0, 0.12)",
    "&:hover": {
      color: "#4F4F4F !important",
      textDecoration: "none !important"
    },
    "& li": {
      padding: "16px !important",
      "&:hover": {
        backgroundColor: "#F6F7FB !important",
        color: "#4F4F4F !important"
      }
    }
  }
}));

export const Item = styled.div`
  background: ${(props) =>
    props.ativa && props.mobile ? theme.appBar.menuitemcolor : "transparent"};
  border-radius: 8px;
  height: ${(props) =>
    !props.mobile ? "" : props.borda ? props.borda : "8px"};
  margin-top: ${(props) => (!props.mobile ? "" : "6px")};
`;

export const ItemText = styled(Box)`
  font-family: "Plau";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: ${(props) =>
    props.ativa
      ? theme.color.menuItemtextEnable
      : theme.color.menuItemTextOpacity};
  cursor: pointer;
  margin-bottom: 2px;
  &:hover {
    text-decoration: none;
    color: ${(props) =>
      props.ativa
        ? theme.color.menuItemtextEnable
        : theme.color.menuItemTextOpacity};
  }
`;
