
import React, { useState, useEffect, useCallback } from 'react';
import { Card, Grid } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useParams, useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { useStyles } from './style';
import BotaoRetornarListagem from 'componentes/botaoRetornarListagem';
import MaterialInputTexto from 'componentes/inputTexto/materialInput';
import Loader from 'componentes/loader';
import Botao from 'componentes/botao';
import RotasDTO from '../../../rotas/rotasUrlDto';
import { buscarUnidades, buscarCategorias, buscarPorId, salvarSerieHistorica } from '../../../servicos/seriesHistoricas';
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";

const SerieHistorica = () => {
  const { id } = useParams();
  const classes = useStyles();
  const history = useHistory();

  const { register, errors, handleSubmit } = useForm({
    reValidateMode: 'onSubmit',
  });

  const [periodicidades] = useState([{ id: 1, name: 'Anual', value: 'A' }, { id: 2, name: 'Mensal', value: 'M' }]);
  const [periodicidade, setPeriodicidade] = useState('');

  const [unidadeId, setUnidadeId] = useState('');

  const [categoriaId, setCategoriaId] = useState('');

  const [carregandoUnidades, setCarregandoUnidades] = useState(false);
  const [unidades, setUnidades] = useState([]);
  const buscaUnidades = async () => {
    try {
      setCarregandoUnidades(true);
      const response = await buscarUnidades();
      if (response?.status === 200 && response?.data) {
        setUnidades(response?.data);
        setCarregandoUnidades(false);
      }
    } catch (error) {
      console.info(error);
      setCarregandoUnidades(false);
    }
  };

  const [carregandoCategorias, setCarregandoCategorias] = useState(false);
  const [categorias, setCategorias] = useState([]);
  const buscaCategorias = async () => {
    try {
      setCarregandoCategorias(true);
      const response = await buscarCategorias();
      if (response?.status === 200 && response?.data) {
        setCategorias(response?.data);
        setCarregandoCategorias(false);
      }
    } catch (error) {
      console.info(error);
      setCarregandoCategorias(false);
    }
  };

  useEffect(() => {
    buscaUnidades();
    buscaCategorias();
  }, []);

  const [codigo, setCodigo] = useState('');
  const [apelido, setApelido] = useState('');
  const [fonte, setFonte] = useState('');
  const [descricao, setDescricao] = useState('');

  const obterSerie = useCallback(async (codigoSerie) => {
    try {
      const serie = await buscarPorId(codigoSerie);
      if (serie?.status === 200 && serie?.data) {
        setCodigo(serie?.data.codigo);
        setApelido(serie?.data.apelido);
        setFonte(serie?.data.fonte);
        setDescricao(serie?.data.descricao);
        setPeriodicidade(serie?.data.periodicidade);
        setUnidadeId(serie?.data.idUnidade);
        setCategoriaId(serie?.data.idCategoria);
      }
    } catch (error) {
      console.info(error);
    }
  }, []);

  const enviarFormulario = async (dados) => {
    try {
      const salvou = await salvarSerieHistorica(id ?? 0, {
        ...dados,
        codigo: codigo,
        apelido: apelido,
        fonte: fonte,
        descricao: descricao,
        idCategoria: categoriaId,
        idUnidade: unidadeId,
        periodicidade: periodicidade,
        ativo: 1
      });

      if (salvou?.status === 200 || salvou?.status === 204) {
  
        setTimeout(() => {
          history.push(RotasDTO.SeriesHistoricas);
        }, 2000);

        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: "Série histórica gravada com sucesso"
          })
        );


      }
    } catch (error) {
      setCodigo('');
      setApelido('');
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro não previsto, entre em contato com o suporte!"
        })
      );
    }
  };

  useEffect(() => {
    if (id) obterSerie(id);
  }, [id, obterSerie]);

  const handleChangeApelido = (event) => {
    setApelido(event.target.value);
  }

  const handleChangeCodigo = (event) => {
    setCodigo(event.target.value);
  }

  const handleChangeUnidade = (event) => {
    setUnidadeId(event.target.value);
  };

  const handleChangeCategoria = (event) => {
    setCategoriaId(event.target.value);
  };
  const handleChangePeriodicidade = (event) => {
    setPeriodicidade(event.target.value);
  };
  const handleChangeFonte = (event) => {
    setFonte(event.target.value);
  };
  const handleChangeDescricao = (event) => {
    setDescricao(event.target.value);
  };
  const aoEnviarFormulario = (dados) => {
    enviarFormulario(dados);
  }

  return <>
    <form className="needs-validation" onSubmit={handleSubmit(aoEnviarFormulario)}>
      <Card className={classes.container}>
        <Grid container p={2} className={classes.container} justifyContent="flex-end">
          <Grid item sm={11}>
            {codigo ? "Editar Série Histórica" : "Nova Série Histórica"}
          </Grid>
          <Grid item sm={1}>
            <BotaoRetornarListagem
              justify="flex-end"
              urlListagem={RotasDTO.SeriesHistoricas}
            />
          </Grid>
        </Grid>
        <Grid container p={2} spacing={4} className={classes.container}>
          <Grid item xs={12} sm={6}>
            <MaterialInputTexto
              type="text"
              id="nomeSerieHistorica"
              name="codigo"
              label="Código"
              renderIconShowHide={false}
              defaultValue={codigo}
              disabled = {codigo ? true : false}
              ref={register({
                required: 'Campo Código é obrigatório!',
                maxLength: {
                  value: 20,
                  message: 'Quantidade máxima de 20 caracteres!',
                },
              })}
              onInput = {(e) =>{
                e.target.value = e.target.value.toString().slice(0,20)
              }}
              onBlur = {handleChangeCodigo}
              errors={errors}
            />
          </Grid>       
          <Grid item xs={12} sm={6}>
            <MaterialInputTexto
              type="text"
              id="apelidoSerieHistorica"
              name="apelido"
              label="Apelido"
              renderIconShowHide={false}
              defaultValue={apelido}
              disabled = {apelido ? true : false}
              ref={register({
                required: 'Campo Apelido é obrigatório!',
                maxLength: {
                  value: 50,
                  message: 'Quantidade máxima de 50 caracteres!',
                },
              })}
              onInput = {(e) =>{
                e.target.value = e.target.value.toString().slice(0,50)
              }}
              onBlur = {handleChangeApelido}
              errors={errors}
            />
          </Grid>       

          <Grid item xs={12} sm={6}>
            <MaterialInputTexto
              type="text"
              id="descricaoSerieHistorica"
              name="descricao"
              label="Descrição"
              renderIconShowHide={false}
              defaultValue={descricao}
              value={descricao}
              onInput={handleChangeDescricao}
              ref={register}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MaterialInputTexto
              type="text"
              id="fonteSerieHistorica"
              name="fonte"
              label="Fonte"
              renderIconShowHide={false}
              defaultValue={fonte}
              value={fonte}
              onInput = {handleChangeFonte}
              ref={register}
              errors={errors}
            />
          </Grid>
        </Grid>
        
        <Grid container p={2} spacing={4} className={classes.container}>
          <Grid item xs={12} sm={4}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="perid">Periodicidade</InputLabel>
              <Select
                variant="outlined"
                labelId="perid"
                id="perid1"
                value={periodicidade ?? ""}
                onChange={handleChangePeriodicidade}
                label="Periodicidade"
                fullWidth>
                {periodicidades.map((prdcdd) => (
                  <MenuItem value={prdcdd.value}>{prdcdd.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Loader loading={carregandoUnidades}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="unidade-label">Unidade</InputLabel>
                <Select
                  variant="outlined"
                  labelId="unidade-label"
                  id="unidadeSerie"
                  value={unidadeId ?? ""}
                  onChange={handleChangeUnidade}
                  label="Unidades"
                  fullWidth>
                  {unidades.map((unidade) => (
                    <MenuItem value={unidade.id}>{unidade.descricao}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Loader>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Loader loading={carregandoCategorias}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="categoria-label">Categoria</InputLabel>
                <Select
                  variant="outlined"
                  labelId="categoria-label"
                  id="categoriaSerie"
                  value={categoriaId ?? ""}
                  onChange={handleChangeCategoria}
                  label="Categorias"
                  fullWidth>
                  {categorias.map((categoria) => (
                    <MenuItem value={categoria.id}>{categoria.descricao}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Loader>
          </Grid>
        </Grid>
        <Grid container p={2} spacing={4} className={classes.container}>
          <Grid item lg={4} md={6} sm={12}>
            <Botao type="submit" label="Salvar" className={classes.button} />
          </Grid>
        </Grid>
      </Card>
    </form>
  </>;
};

export default SerieHistorica;
