import styled from "styled-components";
import { makeStyles } from '@mui/styles';
import { Base } from "componentes/cores";
import theme from 'themes';

export const useStyles = makeStyles({
  label: {
    alignItems: "center !important",
    backgroundColor: Base.BlackBastille,
    color: `${Base.OffWhite} !important`,
    display: "flex !important",
    fontSize: "18px !important",
    height: "54px !important",
    marginRight: "2px  !important",
    opacity: (props) => (props.ativo ? 1 : 0.5),
    padding: "0 15px !important"
  },
  squareWidth: {
    height: "54px !important",
    maxWidth: "54px !important",
    width: "100% !important",
    "&:not(:last-child)": {
      marginRight: "2px  !important"
    }
  },
  gridItem: {
    flexBasis: "100% !important",
    fontSize: "2.1875rem  !important",
    margin: "0  !important",
    maxWidth: "54px  !important",
    width: "100%  !important"
  },
  treeItem: {
    backgroundColor: Base.BlackBastille,
    color: `${Base.OffWhite} !important`,
    height: "54px"
  },
  expandItem: {
    backgroundColor: theme.color.thirdBackgroud,
    fontSize: "18px !important",
    height: "auto !important",
    marginLeft: "56px !important",
    marginTop: "2px !important",
    minWidth: "calc(100% - 56px) !important",
    padding: "15px 20px !important"
  },
  wrapper: {
    alignItems: "center !important",
    display: "flex !important",
    height: "100% !important",
    minHeight: "100% !important"
  },
  center: {
    justifyContent: "center !important"
  }
});

export const Label = styled.div``;
