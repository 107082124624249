import moment from "moment";

export default class ListaDto {
  constructor(
    id,
    nome,
    ativo,
    dataUpload,
    clienteId,
    identificador,
    nomeTipoRelatorio,
    nomeUnidade,
    categoria,
    dataReferencia,
    videos
  ) {
    this.id = id;
    this.nome = nome;
    this.ativo = ativo;
    this.cliente = clienteId;
    this.dataUpload = moment(dataUpload).format("L");
    this.identificador = identificador;
    this.nomeTipoRelatorio = nomeTipoRelatorio;
    this.nomeUnidade = nomeUnidade;
    this.categoria = categoria;
    this.dataReferencia = moment(dataReferencia).format("L");
    this.videos = videos;
  }
}
