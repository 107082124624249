import { makeStyles } from "@mui/styles";
import { Base } from "componentes/cores";
import theme from "themes";

export const useStyles = makeStyles(() => ({
  card: {
    minHeight: (props) => props.minHeight,
    height: "100%",
    width: "100%",
    border: `1px solid  ${Base.OrangeBlaze} !important`,
    borderRadius: "18px !important",
    padding: "18px !important",
    "& h2": {
      color: (props) => (props.color ? props.color : theme.color.primary),
      fontWeight: 700,
      fontSize: "26px",
      marginBottom: 0,
      "& span": {
        color: theme.color.secondary
      }
    },
    "& h3": {
      color: (props) => (props.color ? props.color : theme.color.primary),
      fontWeight: 700,
      fontSize: (props) => (props.fontSize ? props.fontSize : "24px"),
      marginBottom: 0,
      "& span": {
        color: theme.color.secondary
      }
    },
    "& li": {
      color: (props) => (props.color ? props.color : theme.color.primary),
      fontSize: "18px",
      fontFamily: `${theme.font.family}  !important`,
      marginBottom: "15px"
    }
  },
  icone: {
    maxWidth: "100%",
    height: (props) => (props.iconeSize ? props.iconeSize : "50px"),
    marginRight: "10px"
  }
}));
