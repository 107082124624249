import { makeStyles } from "@mui/styles";
import { Base } from "componentes/cores";
import theme from "themes";

export const useStyles = makeStyles(() => ({
  container: {
    "& h2": {
      fontSize: "24px",
      margin: 0,
      fontWeight: 700,
      color: theme.color.primary,
      "& span": {
        color: theme.color.secondary
      }
    }
  },
  card: {
    border: `2px solid  ${Base.OrangeBlaze} !important`,
    borderRadius: "24px !important",
    padding: (props) =>
      props.exibeGrafico ? "18px !important" : "24px !important",
    marginBottom: "15px",
    width: (props) => (props.exibeGrafico ? "255px" : "100%"),
    "& p": {
      fontSize: (props) => (props.exibeGrafico ? "16px" : "22px"),
      margin: 0,
      fontWeight: 700,
      color: theme.color.secondary
    }
  },
  graficoTitulo: {
    border: `2px solid  ${theme.color.secondary} !important`,
    borderRadius: "8px !important",
    textAlign: "center",
    width: "fit-content",
    color: theme.color.primary,
    padding: "5px 20px",
    alignSelf: "center",
    "& h3": {
      fontSize: "18px",
      margin: 0,
      fontWeight: 700
    }
  }
}));
