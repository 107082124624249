import { makeStyles } from '@mui/styles';
import { Base } from "componentes/cores";
import { createTheme } from "@mui/material/styles";

const theme = createTheme();

export const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: theme.palette.background.paper
  },
  titleValidation: {
    padding: "20px",
    "text-align": "center"
  },
  labelAno:{
    paddingBottom: "15px",
    paddingLeft: "5px"
  },
  inputAno:{
    paddingLeft: "15px"
  },
  icon: {
    paddingRight: "15px"
  },
  container: {
    margin: "10px",
    width: "100%",
    "& .MuiGrid-root": {
      color: `${Base.BlackBastille} !important`
    },
    "& .MuiDropzoneArea-text": {
      color: `${Base.BlackBastille} !important`
    },
    "& .MuiInputBase-root": {
      height: "46px"
    },
    "& .MuiFormLabel-root": {
      lineHeight: "1"
    }
  },
  lineHeight: {
    "line-height": "1 !important",
    "&.Mui-focused": {
      color: Base.Mango
    }
  },
  listValues: {
    "max-height": "150px",
    overflow: "auto",
    display: "inline-grid"
  },
  button: {
    fontSize: "18px"
  },
  buttonCustom: {
    fontSize: "18px",
    color: "#FEBB2F !important",
    backgroundColor: "transparent !important",
    "&:hover": {
      color: "#212529"
    }
  },
  botao: {
    background: "none",
    color: "inherit",
    border: "none",
    padding: "0",
    font: "inherit",
    cursor: "pointer",
    outline: "inherit"
  },
  //  formControl: {
  //   "& .MuiInputBase-root": {
  //     backgroundColor: "#303642",
  //     borderRadius: 4,
  //     height: "36px"
  //   },
  //   "& .MuiSelect-icon": {
  //     color: Base.White
  //   },
  //   "& option": {
  //     color: Base.BlackBastille
  //   },
  //   "& .MuiSelect-select.MuiSelect-select": {
  //     overflow: "hidden",
  //     whiteSpace: "nowrap",
  //     textOverflow: "ellipsis",
  //     color: "#eef0f9"
  //   },
  //   "& .MuiSelect-outlined.MuiSelect-outlined": {
  //     padding: "0 40px 0 20px "
  //   },
  //   "& .MuiFormLabel-root": {
  //     color: `${Base.OffWhite} !important`,
  //   }
  // }
}));
