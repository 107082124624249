import React from "react";
import { Box, Link, Card } from "@mui/material";
import PropTypes from "prop-types";
import { KeyboardBackspace } from "@mui/icons-material"
import { useStyles } from "./style"


const Contratado = ({ email, setPassoAtual }) => {
    const preventDefault = (event) => {
        event.preventDefault();
        setPassoAtual("1simulacao");

    }
    const classes = useStyles();
    return (
        <Card className={classes.cardCadastro}>
            <Box pl={4}>
                <Box
                    fontSize="24px"
                    fontWeight="700"
                    color="#FA3EB1"
                    mt={5}
                >
                    Contrato enviado!
                </Box>
                <Box color="#4F4F4F" my={1}>{`Enviamos um e-mail com o termo de adesão para contratação no endereço: ${email}`}</Box>
                <Box color="#4F4F4F" my={1}>Por favor, verifique sua caixa de spam, caso não receba o e-mail em poucos minutos.</Box>
                <Box
                    display="flex"
                    alignContent="center"
                    mt={4}
                    mb={12}
                >
                    <Box mr={1}>
                        <KeyboardBackspace
                            cursor="pointer"
                            htmlColor="#FA3EB1"
                        />
                    </Box>
                    <Box
                        style={{ color:"#FA3EB1", cursor: "pointer" }}
                        component={Link}
                        fontSize="16px"
                        fontWeight="700"
                        underline="always"
                        onClick={preventDefault}
                        
                    >
                        Voltar para o inicio
                    </Box>
                </Box>
            </Box>
        </Card>
    )
}

Contratado.propTypes = {
    email: PropTypes.string,
    setPassoAtual: PropTypes.oneOfType([PropTypes.func]),

}
Contratado.defaultProps = {
    email: "",
    setPassoAtual: () => { },

}

export default Contratado;