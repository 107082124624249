import React, { useState } from "react";
import PropTypes from "prop-types";

// Icones
import EditIcon from "@mui/icons-material/Edit";
import TableChartIcon from "@mui/icons-material/TableChart";
import PageviewIcon from '@mui/icons-material/Pageview';
import DeleteIcon from "@mui/icons-material/Delete";
// Componentes
import { Collapse, Grid } from "@mui/material";
import ToogleIcon from "./componentes/toggleIcon";
// Styles
import { Label, useStyles } from "./style";
import theme from "themes";
import { Base } from "componentes/cores";
const ItemListaExpansivel = ({
  label,
  color,
  labelColor,
  edicao,
  detalhe,
  relatorios,
  exclusao,
  onClickEditar,
  onClickExcluir,
  onClickVisualizar,
  onClickRelatorios,
  ativo,
  aberto,
  children,
  lg,
  md
}) => {
  const classes = useStyles({ ativo });
  const [itemAberto, setItemAberto] = useState(aberto);

  return (
    <Grid item lg={lg} md={md}>
      <Grid container spacing={0}>
        <Grid item className={`${classes.squareWidth} ${classes.gridItem}`}>
          <ToogleIcon
            aberto={itemAberto}
            ativo={ativo}
            color={color}
            onClick={() => setItemAberto(!itemAberto)}
          />
        </Grid>
        <Grid item xs>
          <Label className={classes.label} style={{color:`${labelColor}`}}>{label}</Label>
        </Grid>
        {edicao && (
          <Grid item className={`${classes.squareWidth} ${classes.gridItem}`}>
            <div
              className={`${classes.treeItem} ${classes.wrapper} ${classes.center}`}
              onKeyDown={onClickEditar}
              onClick={onClickEditar}
              role="button"
              tabIndex={0}
            >
              <EditIcon />
            </div>
          </Grid>
        )}
        {detalhe && (
          <Grid item className={`${classes.squareWidth} ${classes.gridItem}`}>
            <div
              className={`${classes.treeItem} ${classes.wrapper} ${classes.center}`}
              onKeyDown={onClickVisualizar}
              onClick={onClickVisualizar}
              role="button"
              tabIndex={0}
            >
              <PageviewIcon />
            </div>
          </Grid>
        )}        
        {relatorios && (
          <Grid item className={`${classes.squareWidth} ${classes.gridItem}`}>
            <div
              className={`${classes.treeItem} ${classes.wrapper} ${classes.center}`}
              onKeyDown={onClickRelatorios}
              onClick={onClickRelatorios}
              role="button"
              tabIndex={0}
            >
              <TableChartIcon />
            </div>
          </Grid>
        )}        

        {exclusao && (
          <Grid item className={`${classes.squareWidth} ${classes.gridItem}`}>
            <div
              className={`${classes.treeItem} ${classes.wrapper} ${classes.center}`}
              onKeyDown={onClickExcluir}
              onClick={onClickExcluir}
              role="button"
              tabIndex={0}
            >
              <DeleteIcon />
            </div>
          </Grid>
        )}
      </Grid>
      <Collapse in={itemAberto}>
        <Grid container spacing={0}>
          <Grid
            item
            className={`${classes.gridItem} ${classes.treeItem} ${classes.expandItem} fade show`}
          >
            {children}
          </Grid>
        </Grid>
      </Collapse>
    </Grid>
  );
};

ItemListaExpansivel.propTypes = {
  label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  color: PropTypes.string,
  labelColor: PropTypes.string,
  edicao: PropTypes.bool,
  detalhe: PropTypes.bool,
  relatorios: PropTypes.bool,
  exclusao: PropTypes.bool,
  onClickEditar: PropTypes.oneOfType([PropTypes.func]),
  onClickExcluir: PropTypes.oneOfType([PropTypes.func]),
  onClickVisualizar: PropTypes.oneOfType([PropTypes.func]),
  onClickRelatorios: PropTypes.oneOfType([PropTypes.func]),
  ativo: PropTypes.bool,
  aberto: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node])
};

ItemListaExpansivel.defaultProps = {
  color: theme.color.primary,
  labelColor: Base.White,
  edicao: false,
  detalhe: false,
  relatorios: false,
  exclusao: false,
  onClickEditar: () => {},
  onClickExcluir: () => {},
  onClickVisualizar: () => {},
  onClickRelatorios: () => {},
  ativo: true,
  aberto: false,
  lg: 6,
  md: 12,
  children: <></>
};

export default ItemListaExpansivel;
