import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const api = () => obterApi(MICROSERVICO.MANAGEMENT);

export const obterTodos = async (clienteLivre) => {
  let urlParam = "";

  if (clienteLivre !== undefined)
    urlParam = new URLSearchParams({
      clienteLivre
    }).toString();

  return api().get(`v1/TipoPlanoCotacao/${urlParam}`);
};
