import { makeStyles } from "@mui/styles";
// import { createTheme } from "@mui/material/styles";
import { Base } from "../cores";
import themes from "../../themes";

// const theme = createTheme();

export const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: `${Base.WhiteLuz} !important`,
    border: `1px solid  ${Base.TextLuzOpacity} !important`,
    borderRadius: "16px !important",
    boxShadow: "0px 2px 4px 1px rgba(0, 0, 0, 0.16)",
    outline: "none",
    position: "absolute",
    padding: "32px 16px 32px 16px !important",
    transform: "translate(-50%, -50%) !important",
    top: "50% !important",
    left: "50% !important",
    color: themes.color.thirdText
  }
}));
