import { Slider, TextField } from "@mui/material";

import withStyles from "@mui/styles/withStyles";

export const SliderCotacoes = withStyles({
    root: {
      color: "#FA3EB1 !important",
      height: "2 !important",
      padding: "15px 0 !important",
    },
    thumb: {
      height: "28px  !important",
      width: "28px  !important",
      backgroundColor: "#FA3EB1 !important",
      marginTop: "-14 !important",
      marginLeft: "-14 !important",
      "&:focus, &:hover, &$active": {
        boxShadow: "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)  !important",
      },
    },
})(Slider);

export const InputCotacoes = withStyles({
    root: {
        "& .MuiInputBase-root": {
            color: "#FA3EB1 !important",
            borderBottom: "1px solid #FA3EB1 !important",
            fontSize: "15px !important",
            fontWeight: "700 !important"
        }

    },
    focused: {}
       
})(TextField)

