import React from "react";
import ReactApexChart from "react-apexcharts";
import PropTypes from "prop-types";

// Componentes
import { Box, Grid } from "@mui/material";
import theme from "themes";
import { options } from "./config";
import { useStyles } from "./style";

const GraficoConsumo = ({ dadosProposta }) => {
  const categoriasConsumo = () => {
    const dadosFiltrados = dadosProposta?.faturaHistoricos?.filter(
      (item, index, array) =>
        index === array.findIndex((t) => t.mes === item.mes)
    );
    const mesesFormatados = dadosFiltrados?.map((item) =>
      window?.moment(item?.mes)?.format("MMM/YY")
    );

    return mesesFormatados?.reverse() || [];
  };

  const classes = useStyles({
    exibeGrafico:
      dadosProposta?.faturaHistoricos && categoriasConsumo().length > 0
  });

  const dadosConsumo = () => {
    const dadosFiltrados = dadosProposta?.faturaHistoricos?.filter(
      (item, index, array) =>
        index === array.findIndex((t) => t.mes === item.mes)
    );
    const dadosPontaFormatados = dadosFiltrados?.map(
      (item) => item?.horaPonta || 0
    );

    const dadosForaPontaFormatados = dadosFiltrados?.map(
      (item) => item?.horaForaPonta || 0
    );

    return [
      {
        name: "Consumo Ponta [KWh]",
        data: dadosPontaFormatados?.reverse() || []
      },
      {
        name: "Consumo Fora Ponta [KWh]",
        data: dadosForaPontaFormatados?.reverse() || []
      }
    ];
  };

  const arredondar = (consumoForaPonta, consumoPonta) => {
    const soma = (consumoForaPonta || 0) + (consumoPonta || 0);

    return Math.round(soma * 100) / 100;
  };

  return (
    <Grid container className={classes.container} pb={2} px={4}>
      <Grid item xs={12} height="100%" pb={6}>
        <h4>
          Cliente: <span>{dadosProposta?.nomeCliente || ""}</span>
        </h4>
      </Grid>
      <Grid
        item
        xs={12}
        sm={
          dadosProposta?.faturaHistoricos && categoriasConsumo().length > 0
            ? 4
            : 12
        }
        height="100%"
        display={
          dadosProposta?.faturaHistoricos && categoriasConsumo().length > 0
            ? "block"
            : "flex"
        }
        pr={6}
      >
        <Box className={classes.card} mr={2}>
          <p style={{ color: theme.color.primary }}>Endereço da UC:</p>
          {dadosProposta?.cidade && dadosProposta?.uf && (
            <p>
              {dadosProposta?.cidade || ""} - {dadosProposta?.uf || ""}
            </p>
          )}
          <p style={{ color: theme.color.primary }}>Distribuidora:</p>
          <p>{dadosProposta?.nomeDistribuidora || ""}</p>
        </Box>
        <Box className={classes.card} mr={2}>
          <p style={{ color: theme.color.primary }}>Unidade Consumidora:</p>
          <p>{dadosProposta?.numeroInstalacao || ""}</p>
          <p style={{ color: theme.color.primary }}>Modalidade:</p>
          <p>
            {dadosProposta?.nivelTensao || ""}{" "}
            {dadosProposta?.modalidade === "VE" ? "Verde" : "Azul"}
          </p>
        </Box>
        <Box className={classes.card} mr={2}>
          {!!dadosProposta?.demandaContratadaForaPonta && (
            <>
              <p style={{ color: theme.color.primary }}>
                Demanda Contratada Fora Ponta:
              </p>
              <p>{dadosProposta?.demandaContratadaForaPonta} kW</p>
            </>
          )}
          {!!dadosProposta?.demandaContratadaPonta && (
            <>
              <p style={{ color: theme.color.primary }}>
                Demanda Contratada Ponta:
              </p>
              <p>{dadosProposta?.demandaContratadaPonta} KW</p>
            </>
          )}
          {!!dadosProposta?.consumoForaPonta && !!dadosProposta.consumoPonta && (
            <>
              <p style={{ color: theme.color.primary }}>
                Consumo Mensal Médio:
              </p>
              <p>
                {arredondar(
                  dadosProposta?.consumoForaPonta,
                  dadosProposta?.consumoPonta
                )}{" "}
                MWh
              </p>
            </>
          )}
        </Box>
      </Grid>
      {dadosProposta?.faturaHistoricos && categoriasConsumo().length > 0 && (
        <Grid
          item
          xs={12}
          sm={8}
          display="flex"
          flexDirection="column"
          justifyContent="end"
          pl={2}
        >
          <Box className={classes.graficoTitulo}>
            <h3>SEU HISTÓRICO DE CONSUMO (kWh)</h3>
          </Box>
          <ReactApexChart
            options={options(categoriasConsumo())}
            series={dadosConsumo()}
            type="bar"
            height="350px"
          />
        </Grid>
      )}
    </Grid>
  );
};

GraficoConsumo.propTypes = {
  dadosProposta: PropTypes.shape({
    nomeCliente: PropTypes.string,
    nivelTensao: PropTypes.string,
    nomeDistribuidora: PropTypes.string,
    faturaHistoricos: PropTypes.arrayOf(),
    cidade: PropTypes.string,
    demandaContratadaForaPonta: PropTypes.number,
    demandaContratadaPonta: PropTypes.number,
    modalidade: PropTypes.string,
    uf: PropTypes.string,
    consumoForaPonta: PropTypes.number,
    consumoPonta: PropTypes.number,
    numeroInstalacao: PropTypes.number
  })
};

GraficoConsumo.defaultProps = {
  dadosProposta: {}
};

export default GraficoConsumo;
