import React, { useState } from "react";
import PropTypes from "prop-types";

// Componentes
import { Grid, Box } from "@mui/material";
import InputTexto from "../../../componentes/inputTexto";
import Medidor from "./medidor";

// Styles
import { useStyles } from "./style";

const Campos = ({
  register,
  onChange,
  forcaSenhaAntiga,
  forcaSenhaNova,
  statusMedidor
}) => {
  const classes = useStyles();
  const [typeOld, setTypeOld] = useState("password");
  const [typeNew, setTypeNew] = useState("password");

  const onShowOldPassword = () => {
    setTypeOld(typeOld === "text" ? "password" : "text");
  };

  const onShowNewPassword = () => {
    setTypeNew(typeNew === "text" ? "password" : "text");
  };

  const enabledButton = (value) => {
    onChange(value);
  };

  return (
    <>
      <Grid item lg={5} md={5} sm={6} xs={12}>
        <InputTexto
          type={typeOld}
          id="OldPassword"
          name="OldPassword"
          placeholder="Senha anterior"
          labelWidth="110"
          renderIconShowHide
          ref={register({
            required: "Campo senha anterior é obrigatório!"
          })}
          onShow={() => onShowOldPassword()}
          bordaQuadrada
        />
        <Grid component={Box} item xs={12} className={classes.medidor}>
          <Medidor forca={forcaSenhaAntiga} />
          {statusMedidor.senhaAntiga}
        </Grid>
      </Grid>
      <Grid item lg={5} md={5} sm={6} xs={12}>
        <InputTexto
          type={typeNew}
          id="NewPassword"
          name="NewPassword"
          placeholder="Nova senha"
          labelWidth="110"
          renderIconShowHide
          ref={register({
            required: "Campo confirmação da senha nova é obrigatório!"
          })}
          onShow={() => onShowNewPassword()}
          bordaQuadrada
          onChange={(e) => enabledButton(e.target.value)}
        />
        <Grid component={Box} item xs={12} className={classes.medidor}>
          <Medidor forca={forcaSenhaNova} />
          {statusMedidor.senhaNova}
        </Grid>
      </Grid>
    </>
  );
};

Campos.propTypes = {
  register: PropTypes.oneOfType([PropTypes.any]).isRequired,
  onChange: PropTypes.oneOfType([PropTypes.func]),
  forcaSenhaAntiga: PropTypes.string,
  forcaSenhaNova: PropTypes.string,
  statusMedidor: PropTypes.func
};

Campos.defaultProps = {
  onChange: () => {},
  forcaSenhaAntiga: "",
  forcaSenhaNova: "",
  statusMedidor: () => {}
};

export default Campos;
