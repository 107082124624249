import RetornoEndpointDto from "global/dto/retornoEndpointDto";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { BuscarComparacaoProjecaoBoletaOuConsumoProjecao } from "servicos/comparacaoProjecaoBoletaService";
import { obterProjecaoDinamica } from "servicos/projecaoConsumoService";
import ProjecaoDto from "./dto/ProjecaoDto";

export default class ProjecaoConsumoHelper {
  
  static async ObterProjecao(projecaoId, unidadeSelecionada, dataReferencia) {
    try {
      const retorno = await BuscarComparacaoProjecaoBoletaOuConsumoProjecao(
        projecaoId,
        unidadeSelecionada,
        window.moment(dataReferencia).format("MM"),
        window.moment(dataReferencia).format("YYYY")
      );

      return RetornoEndpointDto.SucessoSomenteDados(retorno.data);
    } catch (error) {
      return RetornoEndpointDto.Erro(
        error?.response?.data?.message ??
          "Ocorreu uma falha ao obter a projeção de consumo"
      );
    }
  }

  static async ObterProjecaoSemComparacao(
    projecaoId,
    unidadeSelecionada,
    dataReferencia
  ) {
    try {
      const retorno = await obterProjecaoDinamica(
        projecaoId,
        unidadeSelecionada,
        dataReferencia
      );

      if (!retorno || !retorno.data)
        return RetornoEndpointDto.Erro(
          "Não foi encontrado dados para a projeção solicitada"
        );

      return RetornoEndpointDto.SucessoSomenteDados(
        ProjecaoDto.obterDeConversaoDinamica(
          retorno.data.projetadoMes,
          retorno.data.projetadoDia,
          unidadeSelecionada,
          window.moment(dataReferencia).format("MM"),
          window.moment(dataReferencia).format("YYYY"),
          projecaoId
        )
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        error?.response?.data?.message ??
          "Ocorreu uma falha ao obter a projeção de consumo"
      );
    }
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }

  static exibirSucesso(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem
      })
    );
  }
}
