import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const api = () => obterApi(MICROSERVICO.MANAGEMENT);

// Dados da Validação da Cotação
export const obterPorId = (id) => {
  return api().get(`v1/ValidarCotacoes/dadosValidacao/${id}`);
};

// Lista de Arquivos de Fatura
export const listaArquivosCotacao = async (cotacaoId) => {
  return api().get(`v1/ClienteArquivoFatura/${cotacaoId}`);
};

// Titulo Validar Cotações
export const obterDadosFatura = (id) => {
  return api().get(`v1/ValidarCotacoes/Fatura/buscar-id/${id}`);
};

// Titulo Validar Cotações
export const obterTituloRF = (cnpj) => {
  return api().get(`v1/ValidarCotacoes/${cnpj}`);
};

// Histórico de consumo
export const obterHistorico = (id) => {
  return api().get(`v1/ValidarCotacoes/Historico/${id}`);
};

// Atualiza o status da validação
export const atualizaSituacao = (cotacaoId, situacao) => {
  const dados = {
    CotacaoId: cotacaoId,
    Situacao: situacao
  };

  return api().post("v1/ValidarCotacoes/AtualizaSituacao", dados);
};

// Gera uma nova cotação cancelando a atual
export const gerarNovaCotacao = (dados) => {
  return api().post("v1/ValidarCotacoes/GerarNovaCotacao", dados);
};
