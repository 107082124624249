import MICROSERVICO from "global/enumeradores/microServico";
import obterApi from "servicos/api";

const api = () => obterApi(MICROSERVICO.PROJECAOTARIFARIA);

export const uploadBaseGarantiaFisica = async (arquivo, idUsuario, nomeUsuario) => {
    const data = new FormData();
    data.append("file", arquivo.file);
  
    const metodo = "post";
    const url = `v1/ImportBaseGarantiaFisica/importa?idUsuario=${idUsuario}&nomeUsuario=${nomeUsuario}`;
    return api()[metodo](url, data);
}

export const obterCotasPorIdDistribuidora = async (ano, idDistribuidora) => {
  return api().get(`v1/ImportBaseGarantiaFisica/visualizagarantiafisicacota?ano=${ano}&idDistribuidora=${idDistribuidora}`);
}

export const obterUsinas = async (ano) => {
  return api().get(`v1/ImportBaseGarantiaFisica/visualizagarantiafisicausina?ano=${ano}`);
};
