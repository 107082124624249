import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { RotasDTO } from "global/rotas/rotasUrlDto";
import { InterfaceDTO } from "global/dto/interfacesDto";
import { usuarioPossuiFuncionalidade } from "servicos/funcionalidadesServico";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { fieldEmpty } from "../../../../../servicos/utils";
import { SelectArredondado } from "../../../../../componentes";
import MaterialInputTexto from "../../../../../componentes/inputTexto/materialInput";
import {
  unidadesFederativas,
  validacoesInputs
} from "../../../../../global/constantes";
import { useStyles } from "./style";
import InputTextoCEP from "../inputTextoCEP";
import CheckInputs from "../CheckInputs";

const UnidadeConsumidora = ({
  dadosCepCobertura,
  obterCepCobertura,
  register,
  errors,
  setUFId,
  ufId,
  numeroInstalacao,
  dadosCotacaoGd,
  statusVigenciaFechado,
  cepForm,
  enviarFormProposta,
  codigoCliente
}) => {
  const classes = useStyles();
  const [cep, setCep] = useState("");
  const [logradouro, setLogradouro] = useState("");
  const [complemento, setComplemento] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidade, setCidade] = useState("");
  const [numero, setNumero] = useState("");
  const [carregandoCep, setCarregandoCep] = useState(false);
  const [checked, setChecked] = useState(false);
  const usuario = useSelector((state) => state.usuario);
  const rotas = useSelector((state) => state.rotas);
  const { menu } = usuario;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const podeEditarNumeroInstalacao = usuarioPossuiFuncionalidade(
    menu,
    rotas.rotaAtual.caminho,
    RotasDTO.CotacoesGD,
    InterfaceDTO.PodeEditarNumeroInstalacao
  );

  const habiltarEditar = useMemo(() => {
    return podeEditarNumeroInstalacao && checked;
  }, [podeEditarNumeroInstalacao, checked]);

  useEffect(() => {
    if (dadosCepCobertura?.sucesso) {
      setCarregandoCep(dadosCepCobertura.sucesso);
      setTimeout(() => {
        setCarregandoCep(false);
        setCep(dadosCepCobertura?.cepCobertura);
        setLogradouro(dadosCepCobertura?.data?.logradouroCompleto);
        setComplemento(dadosCepCobertura?.data?.complemento);
        setBairro(dadosCepCobertura?.data?.bairro);
        setCidade(dadosCepCobertura?.data?.cidade);
        setUFId(dadosCepCobertura?.data?.uf);
      }, 2000);
    }
  }, [dadosCepCobertura]);

  const onBlurCepEdit = async (valorCep, dadosCotacao) => {
    if (valorCep.length > 7) {
      const result = await obterCepCobertura(valorCep);
      if (result.sucesso) {
        setCarregandoCep(result.sucesso);
        setTimeout(() => {
          setCarregandoCep(false);
          setCep(valorCep ?? "");
          setLogradouro(dadosCotacao?.endereco);
          setComplemento(dadosCotacao?.complemento);
          setBairro(dadosCotacao?.bairro);
          setCidade(dadosCotacao?.cidade);
          setUFId(dadosCotacao?.uf);
          setNumero(dadosCotacao?.numero);
        }, 1000);
      }
    }
  };

  const onBlurCep = async (valorCep) => {
    if (valorCep.length > 7) {
      const result = await obterCepCobertura(valorCep);
      if (result.sucesso) {
        setCarregandoCep(result.sucesso);
        setTimeout(() => {
          setCarregandoCep(false);
          setCep(valorCep ?? "");
          setLogradouro(
            result?.data?.logradouroCompleto ?? dadosCotacaoGd?.endereco
          );
          setComplemento(
            result?.data?.complemento ?? dadosCotacaoGd?.complemento
          );
          setBairro(result?.data?.bairro ?? dadosCotacaoGd?.bairro);
          setCidade(result?.data?.cidade ?? dadosCotacaoGd?.cidade);
          setNumero("");
        }, 1000);
      }
    }
  };

  useEffect(() => {
    if (dadosCotacaoGd?.id) {
      onBlurCepEdit(cepForm ?? dadosCotacaoGd.cep, dadosCotacaoGd);
    }
  }, [dadosCotacaoGd]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={4} md={4} sm={4} xs={12}>
          <InputTextoCEP
            id="cep"
            name="cep"
            label="CEP"
            carregandoEndereco={carregandoCep}
            onBlurCep={onBlurCep}
            defaultValue={cep ?? ""}
            register={register}
            errors={errors}
            disabled={statusVigenciaFechado}
            enviarFormProposta={enviarFormProposta}
          />
        </Grid>
        <Grid item lg={8} md={8} sm={8} xs={12}>
          <MaterialInputTexto
            type="text"
            id="endereco"
            name="endereco"
            label="Endereço"
            permiteValorBranco
            renderIconShowHide={false}
            defaultValue={logradouro ?? ""}
            ref={register({
              required: enviarFormProposta
                ? validacoesInputs.obrigatorio
                : false,

              maxLength: {
                value: 200,
                message: "Quantidade máxima de 200 caracteres."
              },
              validate: (value) =>
                fieldEmpty(value) || validacoesInputs.obrigatorio
            })}
            errors={errors}
            disabled={statusVigenciaFechado}
          />
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={12}>
          <MaterialInputTexto
            type="text"
            id="numero"
            name="numero"
            label="Número"
            permiteValorBranco
            renderIconShowHide={false}
            defaultValue={numero ?? ""}
            ref={register({
              required: enviarFormProposta
                ? validacoesInputs.obrigatorio
                : false,
              maxLength: {
                value: 10,
                message: "Quantidade máxima de 10 caracteres."
              },
              validate: (value) =>
                fieldEmpty(value) || validacoesInputs.obrigatorio
            })}
            errors={errors}
            disabled={statusVigenciaFechado}
          />
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={12}>
          <MaterialInputTexto
            type="text"
            id="complemento"
            name="complemento"
            label="Complemento"
            permiteValorBranco
            renderIconShowHide={false}
            defaultValue={complemento ?? ""}
            ref={register({
              maxLength: {
                value: 100,
                message: "Quantidade máxima de 100 caracteres."
              }
            })}
            errors={errors}
            disabled={statusVigenciaFechado}
          />
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={12}>
          <MaterialInputTexto
            type="text"
            id="bairro"
            name="bairro"
            label="Bairro"
            permiteValorBranco
            renderIconShowHide={false}
            defaultValue={bairro ?? ""}
            ref={register({
              required: enviarFormProposta
                ? validacoesInputs.obrigatorio
                : false,
              maxLength: {
                value: 100,
                message: "Quantidade máxima de 100 caracteres."
              },
              validate: (value) =>
                fieldEmpty(value) || validacoesInputs.obrigatorio
            })}
            errors={errors}
            disabled={statusVigenciaFechado}
          />
        </Grid>
        {podeEditarNumeroInstalacao && !statusVigenciaFechado && !mobile && (
          <>
            <Grid item xs={8} />
            <Grid item xs={4}>
              <CheckInputs setChecked={setChecked} checked={checked} />
            </Grid>
          </>
        )}

        <Grid item lg={4} md={4} sm={4} xs={12}>
          <MaterialInputTexto
            type="text"
            id="cidade"
            name="cidade"
            label="Cidade"
            permiteValorBranco
            renderIconShowHide={false}
            defaultValue={cidade ?? ""}
            ref={register({
              required: enviarFormProposta
                ? validacoesInputs.obrigatorio
                : false,
              maxLength: {
                value: 100,
                message: "Quantidade máxima de 100 caracteres."
              },
              validate: (value) =>
                fieldEmpty(value) || validacoesInputs.obrigatorio
            })}
            errors={errors}
            disabled={statusVigenciaFechado}
          />
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={12}>
          <SelectArredondado
            id="ufId"
            name="ufId"
            valueKey="UF"
            valueName="UF"
            dataSource={unidadesFederativas}
            label="UF"
            value={ufId ?? ""}
            onChange={(e) => setUFId(e.target.value)}
            placeholder="UF"
            allowClear={!statusVigenciaFechado}
            ref={register(
              { name: "ufId" },
              {
                required:
                  enviarFormProposta && !ufId
                    ? validacoesInputs.obrigatorio
                    : false
              }
            )}
            errors={errors}
            disabled={statusVigenciaFechado}
          />
        </Grid>
        {podeEditarNumeroInstalacao && !statusVigenciaFechado && mobile && (
          <>
            <Grid item xs={12}>
              <CheckInputs setChecked={setChecked} checked={checked} />
            </Grid>
          </>
        )}
        <Grid item lg={2} md={2} sm={2} xs={12}>
          <MaterialInputTexto
            type="text"
            id="numeroInstalacao"
            name="numeroInstalacao"
            label="Número Instalação"
            permiteValorBranco
            renderIconShowHide={false}
            defaultValue={numeroInstalacao ?? ""}
            ref={register({
              required: enviarFormProposta
                ? validacoesInputs.obrigatorio
                : false,
              maxLength: {
                value: 50,
                message: "Quantidade máxima de 50 caracteres."
              },
              validate: (value) =>
                fieldEmpty(value) || validacoesInputs.obrigatorio
            })}
            errors={errors}
            className={
              !habiltarEditar || statusVigenciaFechado
                ? classes.desabledInput
                : ""
            }
            readOnly={!habiltarEditar || statusVigenciaFechado}
          />
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={12}>
          <MaterialInputTexto
            type="text"
            id="codigoCliente"
            name="codigoCliente"
            label="Código Cliente (opcional)"
            defaultValue={codigoCliente ?? ""}
            permiteValorBranco
            renderIconShowHide={false}
            ref={register({
              maxLength: {
                value: 200,
                message: "Quantidade máxima de 200 caracteres."
              }
            })}
            errors={errors}
            allowClear
            disabled={statusVigenciaFechado}
          />
        </Grid>
      </Grid>
    </>
  );
};

UnidadeConsumidora.propTypes = {
  setUFId: PropTypes.oneOfType([PropTypes.func]),
  ufId: PropTypes.string,
  dadosCepCobertura: PropTypes.oneOfType([PropTypes.array]),
  obterCepCobertura: PropTypes.oneOfType([PropTypes.func]),
  register: PropTypes.oneOfType([PropTypes.func]),
  errors: PropTypes.string,
  numeroInstalacao: PropTypes.string,
  dadosCotacaoGd: PropTypes.oneOfType([PropTypes.array]),
  statusVigenciaFechado: PropTypes.bool,
  cepForm: PropTypes.string,
  enviarFormProposta: PropTypes.bool,
  codigoCliente: PropTypes.string
};

UnidadeConsumidora.defaultProps = {
  setUFId: () => {},
  ufId: "",
  dadosCepCobertura: [],
  obterCepCobertura: () => {},
  register: () => {},
  errors: "",
  numeroInstalacao: "",
  dadosCotacaoGd: [],
  statusVigenciaFechado: false,
  cepForm: "",
  enviarFormProposta: false,
  codigoCliente: ""
};

export default UnidadeConsumidora;
