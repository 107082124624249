export default class OperacaoDto {
  constructor(
    id,
    unidadeNome,
    unidadeId,
    dataEnvioMigracao,
    dataPrevisaoConclusao,
    diasDesdeInicioMigracao,
    unidadesSelecionadas,
    operacaoControleId,
    disabled,
    status
  ) {
    this.id = id;
    this.unidadeNome = unidadeNome;
    this.unidadeId = unidadeId;
    this.dataEnvioMigracao = dataEnvioMigracao;
    this.dataPrevisaoConclusao = dataPrevisaoConclusao;
    this.diasDesdeInicioMigracao = diasDesdeInicioMigracao;
    this.unidadesSelecionadas = unidadesSelecionadas;
    this.operacaoControleId = operacaoControleId;
    this.disabled = disabled;
    this.status = status;
  }
}
