import React from "react";
import PropTypes from "prop-types";

import BgCardLogin from "../../assets/BgCardLogin.png";

const CardConteudo = ({ children, height, width, className }) => {
  return (
    <div
      className={`card shadow-lg border-0 ${className}`}
      style={{
        background: `url(${BgCardLogin}) center center no-repeat`,
        height,
        width
      }}
    >
      <div className="card-body">{children}</div>
    </div>
  );
};

CardConteudo.propTypes = {
  children: PropTypes.node,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
};

CardConteudo.defaultProps = {
  children: () => {},
  height: "100%",
  width: "auto",
  className: ""
};

export default CardConteudo;
