import RetornoEndpointDto from "global/dto/retornoEndpointDto";

import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { listarClientes } from "servicos/clientesServico";
import { listarUnidadesConsumidorasCombo } from "servicos/unidadesServico";
import {
  Atualiza,
  AtualizarOrdemsVideos,
  AtualizarVideo,
  CancelarEnvioVideo,
  CompletarEnvioVideo,
  DeletarVideo,
  DeletarVideoIdentificador,
  GerarLinkVideo,
  IniciaMultpartUpload,
  ObterPorId,
  ObterVideosPorRelatorioId,
  PostarVideo,
  PostarVideoS3,
  Upload
} from "../../../servicos/relatorioServico";
import { Listar as ListarTipos } from "../../../servicos/tipoRelatorioServico";

class Helper {
  static async Atualiza(params) {
    try {
      if (!params)
        return RetornoEndpointDto.Erro(
          "É necessário informar uma categoria para a persistencia"
        );

      const { data } = await Atualiza(params);

      return RetornoEndpointDto.Sucesso(
        "Relatório atualizado com Sucesso",
        data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        (error?.response?.data?.errors && error?.response?.data?.errors[0]) ??
          "Ocorreu uma falha ao salvar o Relatório"
      );
    }
  }

  static async ObterPorId(params) {
    try {
      if (!params)
        return RetornoEndpointDto.Erro(
          "É necessário informar uma categoria para a persistencia"
        );

      const { data } = await ObterPorId(params);

      return RetornoEndpointDto.Sucesso(
        "Categoria cadastrada com Sucesso",
        data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        error?.response?.data?.message ??
          "Ocorreu uma falha ao salvar o Categoria"
      );
    }
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }

  static exibirSucesso(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem
      })
    );
  }

  static async ListarClientes({
    ordemSelecionada,
    filtroSelecionado,
    paginaAtual,
    empresaSelecionada,
    searchString,
    tamanhoPaginacao
  }) {
    try {
      const { data } = await listarClientes(
        ordemSelecionada ?? "",
        filtroSelecionado ?? "",
        paginaAtual ?? 1,
        empresaSelecionada ?? "",
        searchString ?? "",
        tamanhoPaginacao ?? 10000000
      );

      return RetornoEndpointDto.Sucesso("Clientes listados com sucesso", data);
    } catch (error) {
      return RetornoEndpointDto.Erro(
        error?.response?.data?.message ??
          "Ocorreu uma falha ao listar os clientes"
      );
    }
  }

  static async ListarUnidadesConsumidorasCombo({ clienteId }) {
    try {
      const { data } = await listarUnidadesConsumidorasCombo(clienteId);

      return RetornoEndpointDto.Sucesso("Unidades listadas com sucesso", data);
    } catch (error) {
      return RetornoEndpointDto.Erro(
        error?.response?.data?.message ??
          "Ocorreu uma falha ao listar as Unidades"
      );
    }
  }

  static async ListarTipos(params) {
    try {
      const { data } = await ListarTipos(params);

      return RetornoEndpointDto.Sucesso(
        "Categoria cadastrada com Sucesso",
        data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        error?.response?.data?.message ??
          "Ocorreu uma falha ao salvar o Categoria"
      );
    }
  }

  static async Upload(params) {
    try {
      const { data } = await Upload(params);

      return RetornoEndpointDto.Sucesso(
        "Relatório cadastrado com Sucesso",
        data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        (error?.response?.data?.errors && error?.response?.data?.errors[0]) ??
          "Ocorreu uma falha ao salvar o Relatório"
      );
    }
  }

  static async ObterVideos(params) {
    try {
      const { data } = await ObterVideosPorRelatorioId(params);

      return RetornoEndpointDto.Sucesso(data);
    } catch (error) {
      return RetornoEndpointDto.Erro(
        (error?.response?.data?.errors && error?.response?.data?.errors[0]) ??
          "Ocorreu uma falha ao obter os vídeos"
      );
    }
  }

  static async IniciaMultpartUpload(params) {
    try {
      const { data } = await IniciaMultpartUpload(params);

      return RetornoEndpointDto.Sucesso("link gerado com Sucesso", data);
    } catch (error) {
      return RetornoEndpointDto.Erro(
        (error?.response?.data?.errors && error?.response?.data?.errors[0]) ??
          "Ocorreu uma falha ao gerar o link vídeo no relatório"
      );
    }
  }

  static async GerarLinkVideo(params) {
    try {
      const { data } = await GerarLinkVideo(params);

      return RetornoEndpointDto.Sucesso("link gerado com Sucesso", data);
    } catch (error) {
      return RetornoEndpointDto.Erro(
        (error?.response?.data?.errors && error?.response?.data?.errors[0]) ??
          "Ocorreu uma falha ao gerar o link vídeo no relatório"
      );
    }
  }

  static async PostarVideoS3(params) {
    try {
      const { data } = await PostarVideoS3(params);

      return RetornoEndpointDto.Sucesso("Vídeo cadastrado com Sucesso", data);
    } catch (error) {
      return RetornoEndpointDto.Erro(
        (error?.response?.data?.errors && error?.response?.data?.errors[0]) ??
          "Ocorreu uma falha ao salvar o vídeo no relatório"
      );
    }
  }

  static async CompletarEnvioVideo(params) {
    try {
      const { data } = await CompletarEnvioVideo(params);

      return RetornoEndpointDto.Sucesso("Vídeo cadastrado com Sucesso", data);
    } catch (error) {
      return RetornoEndpointDto.Erro(
        (error?.response?.data?.errors && error?.response?.data?.errors[0]) ??
          "Ocorreu uma falha ao salvar o vídeo"
      );
    }
  }

  static async CancelarEnvioVideo(params) {
    try {
      const { data } = await CancelarEnvioVideo(params);

      return RetornoEndpointDto.Sucesso("Vídeo cancelado com Sucesso", data);
    } catch (error) {
      return RetornoEndpointDto.Erro(
        (error?.response?.data?.errors && error?.response?.data?.errors[0]) ??
          "Ocorreu uma falha ao cancelar o vídeo"
      );
    }
  }

  static async PostarVideo(params) {
    try {
      const { data } = await PostarVideo(params);

      return RetornoEndpointDto.Sucesso("Vídeo cadastrado com Sucesso", data);
    } catch (error) {
      return RetornoEndpointDto.Erro(
        (error?.response?.data?.errors && error?.response?.data?.errors[0]) ??
          "Ocorreu uma falha ao salvar o vídeo no relatório"
      );
    }
  }

  static async AtualizarVideo(params) {
    try {
      const { data } = await AtualizarVideo(params);

      return RetornoEndpointDto.Sucesso("Vídeo atualizado com Sucesso", data);
    } catch (error) {
      return RetornoEndpointDto.Erro(
        (error?.response?.data?.errors && error?.response?.data?.errors[0]) ??
          "Ocorreu uma falha ao atualizar o vídeo no relatório"
      );
    }
  }

  static async AtualizarOrdemsVideos(params) {
    try {
      const { data } = await AtualizarOrdemsVideos(params);

      return RetornoEndpointDto.Sucesso("Vídeos ordenados com Sucesso", data);
    } catch (error) {
      return RetornoEndpointDto.Erro(
        (error?.response?.data?.errors && error?.response?.data?.errors[0]) ??
          "Ocorreu uma falha ao ordernar o vídeo no relatório"
      );
    }
  }

  static async DeletarVideo(id) {
    try {
      await DeletarVideo(id);

      return RetornoEndpointDto.Sucesso("Vídeo deletado com Sucesso", {
        sucesso: true
      });
    } catch (error) {
      return RetornoEndpointDto.Erro(
        (error?.response?.data?.errors && error?.response?.data?.errors[0]) ??
          "Ocorreu uma falha ao deletar o vídeo"
      );
    }
  }

  static async DeletarVideoIdentificador(params) {
    try {
      await DeletarVideoIdentificador(params);

      return RetornoEndpointDto.Sucesso("Vídeo deletado com Sucesso", {
        sucesso: true
      });
    } catch (error) {
      return RetornoEndpointDto.Erro(
        (error?.response?.data?.errors && error?.response?.data?.errors[0]) ??
          "Ocorreu uma falha ao deletar o vídeo"
      );
    }
  }
}

export default Helper;
