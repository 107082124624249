import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Grid,
  Card,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from "@mui/material";
import { useStyles, Tabela } from "./style";
import { obterValoresReceitaAdicionalBandeiraById } from "../../../servicos/receitaAdicionalBandeira";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import CloseIcon from "@mui/icons-material/Close";
import RotasDTO from "../../../rotas/rotasUrlDto";
import Loader from "componentes/loader";
import { useHistory } from "react-router-dom";
import { listarDistribuidoras } from "submodules/DeltaTarifa/servicos/parametrizacaoDistribuidoras";

const ReceitaAdicionalBandeiraVisualizar = () => {
  const classes = useStyles();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const [distribuidora, setDistribuidora] = useState(null);
  const [valores, setValores] = useState([]);

  const [distribuidoras, setDistribuidoras] = useState([]);

  const onFechar = () => {
    history.push(`${RotasDTO.ProjecaoInsumoListar}`);
  };

  const obterValores = async () => {
    try {
      setIsLoading(true);
      const { idDistribuidora } = distribuidoras.find(
        (element) => element.titulo === distribuidora
      );
      const response = await obterValoresReceitaAdicionalBandeiraById(
        idDistribuidora
      );

      if (response.status === 200 && response.data.length) {
        setValores(response?.data);
      } else {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "Não há dados para exibição."
          })
        );
      }

      setIsLoading(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  const obterDistribuidoras = async () => {
    setIsLoading(true);
    const response = await listarDistribuidoras(true);

    if (response.status !== 200) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            "Nao foi possivel buscar distribuidoras elegiveis, entre em contato com o suporte!"
        })
      );
    }

    setDistribuidoras(response.data);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!distribuidoras.length) {
      obterDistribuidoras();
    }
  }, []);

  useEffect(() => {
    if (distribuidora) {
      setValores([]);
      obterValores();
    }
  }, [distribuidora]);

  return (
    <Loader loading={isLoading}>
      <Grid container spacing={2} p={2} className={classes.container}>
        <Grid container className={classes.containerTitulo}>
          <Grid item xs={11} className={classes.titulo}>
            Receita Adicional Bandeira
          </Grid>
          <Grid item xs={1}>
            <CloseIcon onClick={onFechar} className={classes.icon} />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          p={2}
          className={classes.container}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item sm={3}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="perid">Distribuidora</InputLabel>
              <Select
                labelId="distribuidora"
                id="distribuidora"
                value={distribuidora}
                onChange={(event) => {
                  setDistribuidora(event.target.value);
                }}
                label="Distribuidora"
                fullWidth
              >
                {distribuidoras.map((dist) => (
                  <MenuItem value={dist.titulo}>{dist.titulo}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {valores.length > 0 && (
          <Grid item sm={12}>
            <Card className={classes.container}>
              <Grid container spacing={2} p={2} className={classes.container}>
                <Grid item sm={12}>
                  <div className={classes.customPosition}>
                    <Tabela>
                      <Tabela.Thead>
                        <Tabela.Tr>
                          <Tabela.Th colSpan="3">
                            Informações Importadas
                          </Tabela.Th>
                        </Tabela.Tr>
                        <Tabela.Tr>
                          <Tabela.Th>Mes Competencia</Tabela.Th>
                          <Tabela.Th>Valor</Tabela.Th>
                        </Tabela.Tr>
                      </Tabela.Thead>
                      <Tabela.Tbody>
                        {valores?.map((item) => (
                          <Tabela.Tr>
                            <Tabela.Td>
                              {moment(item.mesCompetencia)?.format("L")}
                            </Tabela.Td>
                            <Tabela.Td>
                              {item.valor?.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                                minimumFractionDigits: 2
                              })}
                            </Tabela.Td>
                          </Tabela.Tr>
                        ))}
                      </Tabela.Tbody>
                    </Tabela>
                  </div>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        )}
      </Grid>
    </Loader>
  );
};

export default ReceitaAdicionalBandeiraVisualizar;
