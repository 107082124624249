import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import shortid from "shortid";

// Ícones
import TuneIcon from "@mui/icons-material/Tune";

// Form Hooks
import { useForm, useWatch } from "react-hook-form";

// Componentes
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { Botao } from "../../../../componentes";
import BotaoCadastro from "../../../../componentes/botaoCadastro";
import BotaoFiltroOrdenacao from "../../../../componentes/botaoFiltroOrdenar";
import { Base } from "../../../../componentes/cores";
import ItemListaExpansivel from "../../../../componentes/itemListaExpansivel";
import Loader from "../../../../componentes/loader";
import ModalConfirmacao from "../../../../componentes/modalConfirmacao";
import Paginacao from "../../../../componentes/paginacao";

// Serviços
import {
  deletarMedidor,
  listarFiltrarPor,
  listarOrganizarPor
} from "../../../../servicos/medidoresServico";

// Rotas
import { RotasDTO } from "../../../../global/rotas/rotasUrlDto";

// Redux
import { store } from "../../../../global/redux";
import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";

// Styles
import MaterialInputBusca from "../../../../componentes/inputBusca";
import TabelaSemDados from "../../../../componentes/tabelaPaginada/tabelaSemDados";
import theme from "../../../../themes";
import FiltroListagemMedidoresDto from "../dto/filtroListagemMedidoresDto";
import MedidoresHelper from "./helper";
import { useStyles } from "./style";

const MedidoresListar = () => {
  const classes = useStyles();
  const history = useHistory();
  const montouComponente = useRef(false);

  const {
    unidadeSelecionada,
    clienteSelecionado,
    empresaSelecionada
  } = useSelector((state) => state.usuario);

  const { register, control } = useForm({
    reValidateMode: "onSubmit"
  });

  // eslint-disable-next-line no-console
  console.log(history?.location?.state?.dadosMedidor?.id?.toString());

  const textoBusca = useWatch({
    control,
    name: "textoBusca",
    defaultValue: history?.location?.state?.dadosMedidor?.id?.toString() || ""
  });

  const [carregandoMedidores, setCarregandoMedidores] = useState(false);
  const [listaMedidores, setListaMedidores] = useState([]);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(0);

  const [ordemSelecionada, setOrdemSelecionada] = useState("");
  const [filtroSelecionado, setFiltroSelecionado] = useState("");
  const [carregamentoInicial, setCarregamentoInicial] = useState(false);

  const textoPesquisa = useMemo(() => {
    return textoBusca?.trim() || "";
  }, [textoBusca]);

  useEffect(() => {
    setCarregamentoInicial(true);
    if (empresaSelecionada) return;
    listarMedidores({ pagina: 1 });
  }, []);

  const listarMedidores = async ({ pagina }) => {
    setCarregandoMedidores(true);

    const filtro = new FiltroListagemMedidoresDto(
      ordemSelecionada,
      filtroSelecionado,
      pagina || paginaAtual,
      unidadeSelecionada ?? null,
      textoPesquisa,
      clienteSelecionado ?? null,
      empresaSelecionada ?? null
    );

    const resultado = await MedidoresHelper.ListarMedidores(filtro, history);

    if (!resultado.sucesso || !resultado.data) {
      setListaMedidores([]);
      setTotalPaginas(0);
      setCarregandoMedidores(false);
      return;
    }

    const { data: dados } = resultado;

    setTotalPaginas(dados.totalPaginas);
    setListaMedidores(dados.medidores);
    setPaginaAtual(dados.paginaAtual);

    setCarregandoMedidores(false);
  };

  const onChangePaginacao = (item, pagina) => {
    setPaginaAtual(pagina);
    listarMedidores({ pagina });
  };

  const onClickOrdemSelecionada = (event) => {
    const val = ordemSelecionada === event ? "" : event;
    setOrdemSelecionada(val);
  };

  const onClickFiltroSelecionado = (event) => {
    const val = filtroSelecionado === event ? "" : event;
    setFiltroSelecionado(val);
  };

  const onClickAtivarAgora = async () => {
    listarMedidores({ pagina: 1 });
  };

  useEffect(() => {
    if (montouComponente.current) {
      setCarregamentoInicial(true);
      if (carregamentoInicial) {
        listarMedidores({ pagina: paginaAtual });
      }
    } else montouComponente.current = true;
  }, [empresaSelecionada, clienteSelecionado, unidadeSelecionada]);

  useEffect(() => {
    if (textoPesquisa === "" && carregamentoInicial) {
      const debounce = setTimeout(() => {
        listarMedidores({ pagina: 1 });
      }, 1000);

      return () => clearTimeout(debounce);
    }
  }, [textoPesquisa]);

  const onClickNovoMedidor = () => {
    history.push(`${RotasDTO.Medidores}/cadastro`);
  };

  const onClickEditar = (id) => {
    history.push(`${RotasDTO.Medidores}/cadastro/${id}`);
  };

  const [modalExclusao, setModalExclusao] = useState(false);

  const deletar = async (item) => {
    try {
      setModalExclusao(false);
      setCarregandoMedidores(true);
      const deletou = await deletarMedidor(
        item?.id,
        !(modalExclusao?.situacao?.toUpperCase() === "AT")
      );
      if (deletou?.status === 204) {
        listarMedidores({ pagina: paginaAtual });
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: `O medidor foi ${
              !(modalExclusao?.situacao?.toUpperCase() === "AT")
                ? "ativado"
                : "inativado"
            } com sucesso!`
          })
        );
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoMedidores(false);
    }
  };

  const onClickExcluir = (item) => {
    setModalExclusao(item);
  };

  const onConfirmarExclusao = (item) => {
    try {
      deletar(item);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  const onClickSearch = () => {
    listarMedidores({ pagina: 1 });
  };

  return (
    <Loader loading={carregandoMedidores} className="w-auto">
      <ModalConfirmacao
        item={modalExclusao}
        onConfirmar={(item) => onConfirmarExclusao(item)}
        mensagem={`Tem certeza que deseja ${
          modalExclusao?.situacao?.toUpperCase() === "AT"
            ? "inativar"
            : "ativar"
        } esse Medidor?`}
        onCancelar={() => setModalExclusao(false)}
      />
      <Grid
        container
        spacing={3}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={8} sm={5}>
          <MaterialInputBusca
            type="text"
            id="textoBusca"
            name="textoBusca"
            label="Buscar"
            renderIconShowHide
            searchAdornment
            defaultValue={textoBusca ?? ""}
            ref={register}
            permiteValorBranco
          />
        </Grid>
        <Grid item xs={4} sm={2}>
          <Botao
            type="submit"
            onClick={onClickSearch}
            label="Buscar"
            className={classes.button}
            disabled={!textoPesquisa}
          />
        </Grid>
        <Grid xs={2} />
        <Grid
          item
          xs={12}
          sm={3}
          display="flex"
          justifyContent={{ sm: "flex-end" }}
        >
          <BotaoFiltroOrdenacao
            type="button"
            color={theme.color.secondaryBorderColor}
            background="transparent"
            label="Filtrar / Ordernar"
            icon={<TuneIcon />}
            className={classes.buttonFiltro}
            ordenacao={listarOrganizarPor()}
            ordenadorUm="Ordenador"
            ordenadorDois="Filtrar"
            ordemSelecionada={ordemSelecionada}
            filtros={listarFiltrarPor()}
            filtrosSelecionados={filtroSelecionado}
            onClickOrdenacao={onClickOrdemSelecionada}
            onClickFiltro={onClickFiltroSelecionado}
            onClickAtivarAgora={onClickAtivarAgora}
          />
        </Grid>
        <Grid item xs={12}>
          <BotaoCadastro
            label="Novo medidor"
            color={theme.color.buttonColor}
            onClick={onClickNovoMedidor}
          />
        </Grid>
        {listaMedidores && listaMedidores?.length > 0 ? (
          <>
            {listaMedidores.map((medidor) => {
              return (
                <ItemListaExpansivel
                  key={shortid.generate()}
                  label={
                    medidor?.codigoMedidorCCEE ?? medidor?.descricaoMedidorCCEE
                  }
                  color={Base.White}
                  edicao={!!medidor?.id}
                  exclusao={!!medidor?.id}
                  onClickEditar={() => onClickEditar(medidor?.id)}
                  onClickExcluir={() => onClickExcluir(medidor)}
                  ativo={medidor?.situacao === "AT"}
                >
                  <div>
                    <strong>Descrição: </strong>
                    {medidor?.descricaoMedidorCCEE ?? ""}
                  </div>
                </ItemListaExpansivel>
              );
            })}
            <Grid
              container
              spacing={3}
              direction="row"
              justifyContent="center"
              alignItems="center"
              mt={2}
            >
              <Grid item>
                <Paginacao
                  totalPaginas={totalPaginas}
                  onChangePagina={onChangePaginacao}
                />
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid
            container
            spacing={3}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <TabelaSemDados className={classes.container} />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Loader>
  );
};

export default MedidoresListar;
