export default class PlanoConfiguracaoDto {
  constructor(tipoPlano, corCard, nomePlano, icone, corTexto, opacity, fundoColor) {
    this.tipoPlano = tipoPlano;
    this.corCard = corCard;
    this.nomePlano = nomePlano;
    this.icone = icone;
    this.corTexto = corTexto;
    this.opacity = opacity;
    this.fundoColor = fundoColor;
  }
}
