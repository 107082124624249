import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const api = (log) => obterApi(MICROSERVICO.MANAGEMENT, log);

// Contratar cotação
export const contratarCotacao = (cotacaoId) => {
  const log = {
    nestedField : "dados_empresa",
    tipo : "atualizaLog"
  };
  return api(log).post(`v1/DemandaAtend/contratar/${cotacaoId}`);
};

// Retornar data migração
export const obterDataMigracao = (protocoloDemanda) => {
  return api().get(
    `v1/DemandaAtend/data-migracao?protocoloDemanda=${
      protocoloDemanda?.protocoloDemanda ?? ""
    }`
  );
};

// Retornar dados para termos de Adesão, Procuração e Confirmação
export const obterDadosParaTermoAdesao = (protocoloDemanda) => {
  return api().get(
    `v1/DemandaAtend/TermoDeAdesao?protocoloDemanda=${
      protocoloDemanda?.protocoloDemanda ?? ""
    }`
  );
};

export const tramitarDemandaAtend = (id, proximaEtapa) => {
  const model = {
    operacaoPassoOnboardingId: proximaEtapa
  };
  return api().patch(
    `v1/OperacaoControle/AtualizaPassoOnboarding/${id}`,
    model
  );
};

export const obterProtocoloDemandaPorFiltroLateral = (
  empresa,
  clienteId,
  unidadeId
) => {
  return api().get(
    `v1/DemandaAtend/empresa/${empresa}/clienteId/${clienteId}/unidade/${unidadeId}`
  );
};

export const obterProtocoloDemandaGDPorFiltroLateral = (unidadeId) => {
  return api().get(`v1/OperacaoControle/ObterPorUnidadeId/${unidadeId}`);
};

export const reenviarContrato = async (body) => {
  return api().post("v1/ContratoAtend/docusign/reenviar", body);
};
