import { Link } from "react-router-dom";
import styled from "styled-components";
import { makeStyles } from "@mui/styles";
// eslint-disable-next-line import/no-unresolved
import theme from "themes";

import { Base } from "../../../../componentes/cores";

// Imagens
import imgEconomia from "../../../../assets/home/economia.png";
import bgEconomia from "../../../../assets/home/bgEconomia.png";

import imgConsumo from "../../../../assets/home/consumo.png";
import bgConsumo from "../../../../assets/home/bgConsumo.png";

import bgMedicao from "../../../../assets/home/bgMedicao.png";
import imgMedicao from "../../../../assets/home/medicao.png";

import bgInfoCadastro from "../../../../assets/home/bgInfoCadastro.png";

import iconLinkedin from "../../../../assets/home/iconLinkedin.png";
import iconDelta from "../../../../assets/home/iconDelta.png";

// Imagens mobile
import bgEconomiaMobile from "../../../../assets/home/bg-economia.png";
import bgFinanceiroMobile from "../../../../assets/home/bg-financeiro.png";
import bgConsumoMobile from "../../../../assets/home/bg-consumo.png";
import bgRelatoriosMobile from "../../../../assets/home/bg-relatorios.png";

export const useStyles = makeStyles(() => ({
  iconesDashboard: {
    float: "right",
    marginRight: "20%"
  },
  iconeDashboardLuz: {
    marginLeft: 20,
    marginRight: 25
  },
  card100DashboardLuz: {
    padding: 20,
    backgroundColor: Base.White,
    clear: "both",
    flexGrow: 1,
    height: "auto",
    minHeight: "75vh",
    color: `${theme.color.primary} !important`,
    fontStyle: "italic",
    fontSize: 42,
    borderRadius: 20,
    paddingTop: 50
  },
  card50DashboardLuz: {
    padding: 20,
    backgroundColor: Base.White,
    clear: "both",
    flexGrow: 1,
    height: "auto",
    minHeight: "36.5vh",
    color: `${theme.color.primary} !important`,
    fontStyle: "italic",
    fontSize: 35,
    borderRadius: 25,
    paddingTop: 50,
    "&:nth-child(2)": {
      marginTop: 20
    }
  },
  paddingCardDashboardLuz: {
    padding: 20
  },
  linkDashboard: {
    cursor: "pointer",
    color: Base.PrimaryLuzColor,
    textDecoration: "none",
    "&:hover": {
      color: Base.PrimaryLuzColor,
      textDecoration: "none"
    }
  }
}));

export const Economia = styled(Link)`
  align-items: center;
  background: url(${bgEconomia}) center center no-repeat;
  background-size: cover;
  cursor: pointer;
  display: flex;
  height: 445px;
  justify-items: center;
  margin-bottom: 30px;
  text-decoration: none;
  width: 97%;
  &:hover {
    text-decoration: none;
  }
  @media (max-width: 1366px) {
    height: 290px;
    width: 97%;
  }
  @media (max-width: 1024px) {
    height: 290px;
    width: 97%;
  }
  @media (max-width: 600px) {
    height: 120px;
    width: 97%;
    margin-bottom: 10px;
  }
`;

export const TextoEconomia = styled.div`
  background: url(${imgEconomia}) left center no-repeat;
  color: ${Base.GreyTextLuz};
  font-size: 34px;
  height: 80px;
  margin: 0 auto;
  padding-left: 96px;
  padding-top: 10px;
  width: 288px;
`;

export const Consumo = styled(Link)`
  align-items: center;
  background: url(${bgConsumo}) center center no-repeat;
  background-size: cover;
  cursor: pointer;
  display: flex;
  height: 445px;
  justify-items: center;
  text-decoration: none;
  max-width: 97%;
  &:hover {
    text-decoration: none;
  }
  @media (max-width: 1366px) {
    height: 290px;
    width: 97%;
  }
  @media (max-width: 1024px) {
    height: 290px;
    width: 97%;
  }
  @media (max-width: 600px) {
    height: 120px;
    width: 97%;
    margin-bottom: 10px;
  }
`;

export const TextoConsumo = styled.div`
  background: url(${imgConsumo}) left center no-repeat;
  color: ${Base.GreyTextLuz};
  font-size: 34px;
  height: 76px;
  margin: 0 auto;
  padding-left: 96px;
  padding-top: 10px;
  width: 258px;
`;

export const MedicaoConsolidada = styled(Link)`
  align-items: center;
  background: url(${bgMedicao}) center center no-repeat;
  background-size: cover;
  cursor: pointer;
  display: flex;
  height: 637px;
  justify-items: center;
  margin-bottom: 30px;
  text-decoration: none;
  width: 95%;
  &:hover {
    text-decoration: none;
  }
  @media (max-width: 1366px) {
    height: 410px;
    width: 97%;
  }
  @media (max-width: 1024px) {
    height: 385px;
    width: 97%;
  }
  @media (max-width: 600px) {
    height: 120px;
    width: 97%;
    margin-bottom: 15px;
  }
`;

export const TextoMedicao = styled.div`
  background: url(${imgMedicao}) left center no-repeat;
  color: ${Base.GreyTextLuz};
  font-size: 28px;
  height: 96px;
  margin: 0 auto;
  padding-left: 80px;
  padding-top: 10px;
  width: 228px;
  @media (max-width: 1366px) {
    font-size: 26px;
  }
  @media (max-width: 1024px) {
    font-size: 24px;
  }
`;

export const InformacoesCadastro = styled(Link)`
  align-items: center;
  background: url(${bgInfoCadastro}) center center no-repeat;
  background-size: cover;
  display: flex;
  height: 252px;
  justify-items: center;
  text-decoration: none;
  width: 98%;
  &:hover {
    text-decoration: none;
  }
  @media (max-width: 1366px) {
    height: 172px;
    width: 99%;
  }
  @media (max-width: 1024px) {
    width: 95%;
    height: 195px;
  }
  @media (max-width: 600px) {
    width: 100%;
    height: 145px;
  }
`;

export const TextoInformacoes = styled.div`
  color: ${Base.GreyTextLuz};
  font-size: 24px;
  height: 96px;
  margin: 0 auto;
  width: 150px;
`;

export const LinkedIn = styled.a`
  align-items: center;
  background: ${Base.OffWhite} url(${iconLinkedin}) center center no-repeat;
  background-size: cover;
  display: flex;
  height: 111px;
  justify-items: center;
  margin-bottom: 30px;
  width: 91px;
  @media (max-width: 1366px) {
    height: 75px !important;
    width: 61px !important;
    margin-bottom: 22px;
    margin-right: 12% !important;
  }
  @media (max-width: 1024px) {
    height: 81px !important;
    width: 51px !important;
    margin-bottom: 33px;
    margin-right: 12% !important;
  }
  @media (max-width: 600px) {
    height: 61px !important;
    width: 51px !important;
    margin-bottom: 22px;
    margin-right: 12% !important;
  }
`;

export const Site = styled.a`
  align-items: center;
  background: ${Base.OffWhite} url(${iconDelta}) center center no-repeat;
  background-size: cover;
  display: flex;
  height: 111px;
  justify-items: center;
  width: 91px;
  @media (max-width: 1366px) {
    height: 75px !important;
    width: 61px !important;
    margin-right: 12% !important;
  }
  @media (max-width: 1024px) {
    height: 81px !important;
    width: 51px !important;
    margin-right: 12% !important;
  }
  @media (max-width: 600px) {
    height: 61px !important;
    width: 51px !important;
  }
`;

export const EconomiaMobile = styled(Link)`
  align-items: center;
  background: url(${bgEconomiaMobile}) center center no-repeat;
  background-size: cover;
  cursor: pointer;
  display: flex;
  height: 445px;
  justify-items: center;
  margin-bottom: 30px;
  text-decoration: none;
  width: 100%;
  &:hover {
    text-decoration: none;
  }
  @media (max-width: 1366px) {
    height: 290px;
    width: 100%;
  }
  @media (max-width: 1024px) {
    height: 290px;
    width: 100%;
  }
  @media (max-width: 600px) {
    height: 140px;
    width: 97%;
    width: 100%;
  }
`;

export const FinanceiroMobile = styled(Link)`
  align-items: center;
  background: url(${bgFinanceiroMobile}) center center no-repeat;
  background-size: cover;
  cursor: pointer;
  display: flex;
  height: 445px;
  justify-items: center;
  margin-bottom: 30px;
  text-decoration: none;
  width: 100%;
  &:hover {
    text-decoration: none;
  }
  @media (max-width: 1366px) {
    height: 290px;
    width: 100%;
  }
  @media (max-width: 1024px) {
    height: 290px;
    width: 100%;
  }
  @media (max-width: 600px) {
    height: 140px;
    width: 97%;
    width: 100%;
  }
`;

export const ConsumoMobile = styled(Link)`
  align-items: center;
  background: url(${bgConsumoMobile}) center center no-repeat;
  background-size: cover;
  cursor: pointer;
  display: flex;
  height: 445px;
  justify-items: center;
  margin-bottom: 30px;
  text-decoration: none;
  width: 100%;
  &:hover {
    text-decoration: none;
  }
  @media (max-width: 1366px) {
    height: 290px;
    width: 100%;
  }
  @media (max-width: 1024px) {
    height: 290px;
    width: 100%;
  }
  @media (max-width: 600px) {
    height: 140px;
    width: 97%;
    width: 100%;
  }
`;

export const RelatoriosMobile = styled(Link)`
  align-items: center;
  background: url(${bgRelatoriosMobile}) center center no-repeat;
  background-size: cover;
  cursor: pointer;
  display: flex;
  height: 445px;
  justify-items: center;
  margin-bottom: 30px;
  text-decoration: none;
  width: 100%;
  &:hover {
    text-decoration: none;
  }
  @media (max-width: 1366px) {
    height: 290px;
    width: 100%;
  }
  @media (max-width: 1024px) {
    height: 290px;
    width: 100%;
  }
  @media (max-width: 600px) {
    height: 140px;
    width: 97%;
    width: 100%;
  }
`;

export const TextoMobile = styled.div`
  text-align: center;
  color: ${theme.color.textoBarra};
  font-size: 28px;
  height: 80px;
  margin: 0 auto;
  padding-top: 15px;
  width: 288px;
`;
