/* eslint-disable import/no-unresolved */
import moment from "moment";
import React, { useState, useEffect } from "react";
import { Grid, Card, FormControl, InputLabel } from "@mui/material";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { InputTextoData } from "componentes";
import { useStyles, Tabela } from "./style";
import { getBaseEER } from "../../../servicos/baseEER";
import CloseIcon from "@mui/icons-material/Close";
import RotasDTO  from "../../../rotas/rotasUrlDto";  
import { useHistory } from "react-router-dom";

const ProjecaoCustosVisualizar = () => {
    const classes = useStyles();
    const history = useHistory();
    const [valores, setValores] = useState([]);
    const [, setError] = useState(false);

    const [anoInicio, setAnoInicio] = useState(moment());
    const [anoFim, setAnoFim] = useState(moment());

    const [parameterChanged, setParameterChanged] = useState(true);

    const obterValores = async () => {
        try {
            const response = await getBaseEER(anoInicio.format("yyyy"), anoFim.format("yyyy"));
            if (response.status === 200 && response.data) {
                setParameterChanged(false);
                setValores(response?.data);

            } else {
                setValores([]);
                store.dispatch(
                    alertaExibir({
                        tipo: "warning",
                        mensagem: "Não há dados para exibição."
                    })
                );
            }
        } catch (exception) {
            setError(true);
            store.dispatch(
                alertaExibir({
                    tipo: "warning",
                    mensagem:
                        exception?.response?.data?.message ??
                        "Erro interno, entre em contato com o suporte!"
                })
            );
        }
    };

    const handleChangeAnoInicio = async (data) => {
        setAnoInicio(moment(data));
        setParameterChanged(true);
    }

    const handleChangeAnoFim = async (data) => {
        setAnoFim(moment(data));
        setParameterChanged(true);
    }

    useEffect(() => {
        console.info(parameterChanged);
        if(anoFim < anoInicio && parameterChanged)
        {
            setParameterChanged(false);
            setValores([]);
            store.dispatch(
                alertaExibir({
                    tipo: "warning",
                    mensagem: "Ano Fim menor que Ano Início. Pesquisa cancelada."
                })
            );

        }
        else if (parameterChanged) obterValores();
    }, [parameterChanged]);

    const onFechar = (id) => {
        history.push(`${RotasDTO.ProjecaoInsumoListar}`);
    }

    return <>
        <Grid container sm={12}>
            <Grid container className={classes.containerTitulo}>
                <Grid item xs={11} className={classes.titulo}>
                    Histórico EER e ESS
                </Grid>
                <Grid item xs={1} >             
                    <CloseIcon onClick={onFechar} className={classes.icon}/>
                </Grid>   
            </Grid>
            <Grid container spacing={4} className={classes.container} justifyContent="flex-end" alignItems="center">
                <Grid item sm={2}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputTextoData
                            type="text"
                            id="anoInicio"
                            name="anoInicio"
                            label="Ano Início"
                            customValue={anoInicio}
                            format="yyyy"
                            onChange={(data) => handleChangeAnoInicio(data)}
                            minDate={moment().subtract(10, "years").format("YYYY")}
                            views={["year"]}
                            renderIconShowHide={false}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={2} style={{marginLeft: "15px"}}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputTextoData
                            type="text"
                            id="anoFinal"
                            name="anoFinal"
                            label="Ano Fim"
                            customValue={anoFim}
                            format="yyyy"
                            onChange={(data) => handleChangeAnoFim(data)}
                            minDate={moment().subtract(10, "years").format("YYYY")}
                            views={["year"]}
                            renderIconShowHide={false}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </Grid>
        <Grid container spacing={4} p={2} className={classes.container}>
            <Grid item sm={12}>
                <Card className={classes.container}>
                    <Grid container spacing={4} className={classes.container}>
                        <Grid item sm={12} className={classes.customPosition} style={{ paddingTop: "10px", paddingBottom: "0px" }}>
                            <InputLabel>• Valores em R$ (Reais)</InputLabel>
                        </Grid>
                        <Grid item sm={12} style={{ paddingTop: "0px" }}>
                            <div className={classes.customPosition}>
                                <Tabela>
                                    <Tabela.Thead>
                                        <Tabela.Tr>
                                            <Tabela.Th colSpan="12">Histórico EER e ESS</Tabela.Th>
                                        </Tabela.Tr>
                                        <Tabela.Tr>
                                            <Tabela.Th>DATA</Tabela.Th>
                                            <Tabela.Th>SCONER</Tabela.Th>
                                            <Tabela.Th>TOTAL A LIQUIDAR</Tabela.Th>
                                            <Tabela.Th>PAGAMENTOS E OUTROS</Tabela.Th>
                                            <Tabela.Th>CUSTO EER</Tabela.Th>
                                            <Tabela.Th>CUSTO ESS</Tabela.Th>
                                            <Tabela.Th>ORIGEM</Tabela.Th>
                                        </Tabela.Tr>
                                    </Tabela.Thead>
                                    <Tabela.Tbody>
                                        {valores?.map((item) => (
                                            <Tabela.Tr>
                                                <Tabela.Td>{moment(item.dataMes).format("DD/MM/YYYY")}</Tabela.Td>
                                                <Tabela.Td>{item.sconer ? `${item.sconer?.toLocaleString("pt-BR", { currency: "BRL", minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : "-"}</Tabela.Td>
                                                <Tabela.Td>{item.totalLiquidar ? `${item.totalLiquidar?.toLocaleString("pt-BR", { currency: "BRL", minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : "-"}</Tabela.Td>
                                                <Tabela.Td>{item.pagtoOutros ? `${item.pagtoOutros?.toLocaleString("pt-BR", { currency: "BRL", minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : "-"}</Tabela.Td>
                                                <Tabela.Td>{item.eer ? `${item.eer?.toLocaleString("pt-BR", { currency: "BRL", minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : "-"}</Tabela.Td>
                                                <Tabela.Td>{item.ess ? `${item.ess?.toLocaleString("pt-BR", { currency: "BRL", minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : "-"}</Tabela.Td>
                                                <Tabela.Td>{item.origem}</Tabela.Td>
                                            </Tabela.Tr>
                                        ))}
                                    </Tabela.Tbody>
                                </Tabela>
                            </div>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        </Grid>
    </>;
}

export default ProjecaoCustosVisualizar;
