import React from "react";
import PropTypes from "prop-types";

function FormValidation({ children, handleSubmit, onSubmit }) {
  return (
    <form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
      {children}
    </form>
  );
}

FormValidation.propTypes = {
  children: PropTypes.oneOfType([PropTypes.any]).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default FormValidation;
