/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import moment from "moment";
import SelectArredondado from "componentes/selectArredondado";
import InputData from "componentes/inputTextoData";
// Form Hooks
import { useForm } from "react-hook-form";
// Ícones
import CloseIcon from "@mui/icons-material/Close";
// Componentes
import Botao from "componentes/botao";
import Loader from "componentes/loader";

// Styles
import { Base } from "componentes/cores";
import { useStyles } from "./style";
import { getInfoAtualizar } from '../../../servicos/premissasBrasil';
import { obterCenarios } from "../../../servicos/cenariosSeriesHistoricas";
import { ExecutarProjecao } from "../../../servicos/projecaoBrasil";
import { Typography } from "@mui/material";
import { HubConnectionBuilder, HttpTransportType } from '@microsoft/signalr';

const ParametrosProjecaoBrasil = ({ aberto, itemProjecao, onCancelar,
  ativaCenario, ativaAno, ativaTextoProinfa }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { register } = useForm({
    reValidateMode: "onSubmit"
  });
  const [cenarios, setCenarios] = useState([]);
  const usuarioGlobal = useSelector((state) => state.usuario);
  const [resultadoProjecao, setResultadoProjecao] = useState(false);
  const [resultadoProjecaoMensagens, setResultadoProjecaoMensagens] = useState([]);
  const [idCenario, setIdCenario] = useState();
  const [ano, setAno] = useState();
  const [minAno, setMinAno] = useState();
  const [projetando, setProjetando] = useState(false);
  const [maxAnoReferencia, setMaxAnoReferencia] = useState();
  const [infoAtualizar, setInfoAtualizar] = useState([]);
  const [connection, setConnection] = useState(null);

  useEffect(() => {

    const newConnection = new HubConnectionBuilder()
      //.withUrl('https://localhost:5001/projecaotarifaria/valoreseer', { https://hub.sualuz.com.br/projecaotarifaria/valoresee
      .withUrl(`${process.env.REACT_APP_ATUALIZA_VALORES_ERR_HUB}/projecaotarifaria/valoreseer`, { 
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets

      }).withAutomaticReconnect()
      .build();

    setConnection(newConnection);

  }, []);

  useEffect(() => {

    if (connection) {

      connection.start()
        .then(result => {

          console.log('Connected!');
          connection.on('AtualizaEEROK', message => {
            debugger
            console.log(JSON.stringify(message))
            setProjetando(false);
            setResultadoProjecao(true);

            setResultadoProjecaoMensagens([message]);

          });
          connection.on('AtualizaEERFail', message => {
            debugger
            console.log(JSON.stringify(message))
            setResultadoProjecaoMensagens([message]);
            setResultadoProjecao(true);
            setProjetando(false);
          });


        })
        .catch(e => console.log('Connection failed: ', e));

    }

  }, [connection]);

  const handleCenarios = async () => {
    try {
      const lista = await obterCenarios();
      if (lista?.status === 200 && lista?.data) {
        setCenarios(lista?.data);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  }

  const handleChangeAno = (event) => {
    setAno(event)
  }

  const executarItemProjecao = async (idCenario, ano) => {
    setProjetando(true);
    const mensagemRetorno = [];
    try {

      const idUsuario = usuarioGlobal?.usuario?.id;
      const nomeUsuario = usuarioGlobal?.usuario?.usuarioNome;
      const anoRef = moment(ano).format("YYYY");
      const resultadoProjecao = await ExecutarProjecao(itemProjecao?.codigo, idCenario, anoRef, idUsuario, nomeUsuario);

      if (resultadoProjecao?.status === 200) {
        if (resultadoProjecao.data) {
          mensagemRetorno.push(resultadoProjecao.data);

        }
      }
      else if (resultadoProjecao?.status === 202) {
        mensagemRetorno.push("Projeção não executada com as seguintes falhas:");

        if (resultadoProjecao.data) {
          for (const itval in resultadoProjecao.data) {
            mensagemRetorno.push(resultadoProjecao.data[itval].Falha);
          }
        }
      }
    }
    catch (erro) {

      console.info('erro');
      mensagemRetorno.push("Erro não previsto:");
      if (erro?.message) {
        mensagemRetorno.push(erro?.message)
      }
      if (erro?.response?.data?.message) {
        mensagemRetorno.push(erro?.response?.data?.message)
      }
    }
    if (itemProjecao?.codigo != "EER_GERAL") {
      setProjetando(false);
      setResultadoProjecao(true);
      setResultadoProjecaoMensagens(mensagemRetorno);
    }
  }

  const buscaInfoAtualizar = async () => {

    try {
      const response = await getInfoAtualizar();
      if (response.status == 200) {
        setMaxAnoReferencia(response.data[0].maxAnoReferencia);
        setInfoAtualizar(response.data);
      }
    }
    catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: `Erro não previsto ao obter informações para atualização: ${error}`
        })
      );
    }

  }

  useEffect(() => {
    buscaInfoAtualizar();
  }, [infoAtualizar.length])


  useEffect(() => {
    if (!ativaCenario && !ativaAno) {
      executarItemProjecao();
    }
    else {
      setProjetando(false);
      setResultadoProjecao(false);
      if (ativaCenario) {
        handleCenarios();
      }
      if (ativaAno) {
        setMinAno(window.moment().subtract(10, "year"));
        if (ativaTextoProinfa) {
          setAno(moment([maxAnoReferencia]));
        }
        else {
          setAno(window.moment());
        }
      }
    }

  }, [aberto]);


  const onProjetar = () => {
    let parametrosValidos = false;
    switch (itemProjecao?.id) {
      case "CDE_GERAL":
        if (idCenario !== undefined) {
          parametrosValidos = true;
        }
        break;
      case "EER_GERAL":
        if (idCenario !== undefined) {
          parametrosValidos = true;
        }
        break;
      case "ESS_GERAL":
        if (idCenario > 0 && ano > 1950) {
          parametrosValidos = true;
        }
        break;
      case "MERCADO_BRASIL":
        parametrosValidos = true;
        break;
      case "PROINFA":
        if (ano > 0 && idCenario !== undefined) {
          parametrosValidos = true;
        }
        break;
      case "TARIFA_ANGRA":
        parametrosValidos = true;
        break;
      case "TARIFA_CGF":
        parametrosValidos = true;
        break;
      default:
        parametrosValidos = false;
        break;
    }
    if (parametrosValidos) {
      executarItemProjecao(idCenario, ano)
    } else {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Parâmetro(s) inválido(s)"
        })
      )
    }
  }


  return (
    <div className={classes.root} style={!aberto ? { display: "none" } : {}}>
      <Grid container p={1} spacing={1} className={classes.icon} justifyContent="flex-end">
        <CloseIcon
          onClick={onCancelar}
        />
      </Grid>
      <div className={classes.titleValidation}>
        <h6>
          <b>{itemProjecao?.titulo}</b>
        </h6>
      </div>
      <Loader loading={projetando} className="w-auto">
        <div className={classes.titleValidation}>

          {projetando ?
            <h6>
              {" "}
              <b>EXECUTANDO A PROJEÇÃO, POR FAVOR, AGUARDE...</b>
              {" "}
            </h6>
            :
            <>
              {resultadoProjecao ?
                <div>
                  <AppBar position="static" color="default">
                    <Tabs value={itemProjecao?.codigo} indicatorColor="primary" textColor="primary" variant="fullWidth">
                      <Grid container p={2} spacing={2} className={classes.container}>
                        {resultadoProjecaoMensagens !== null &&
                          resultadoProjecaoMensagens?.length > 0 &&
                          resultadoProjecaoMensagens.map((mensagem) => {
                            return (
                              <Grid item sm={12}>
                                <span style={{ textAlign: "left" }}><pre>{mensagem}</pre></span>
                                <br />
                              </Grid>
                            );
                          })}
                      </Grid>
                    </Tabs>
                  </AppBar>
                  <Grid item sm={12}>
                    <Grid
                      container
                      p={4}
                      spacing={4}
                      className={classes.container}
                      justifyContent="center"
                    >
                      <Grid item sm={3}>
                        <Botao
                          type="button"
                          color={Base.Mango}
                          label="Ok"
                          onClick={onCancelar}
                          className={classes.buttonCustom}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
                :
                <>
                  <AppBar position="static" color="default">
                    <Tabs value={itemProjecao?.codigo} indicatorColor="primary" textColor="primary" variant="fullWidth">
                      <Grid container p={2} spacing={4} className={classes.container} justifyContent="left">
                        {ativaAno ?
                          <Grid item sm={12}>
                            <Grid item sm={1} className={classes.labelAno}><Grid item sm={1}>Ano</Grid></Grid>

                            <Grid item sm={2} sx={2} className={classes.inputAno}>
                              <InputData
                                label=""
                                type="text"
                                id="ano"
                                name="ano"
                                minDate={minAno}
                                customValue={ano}
                                onChange={(valor) => handleChangeAno(valor)}
                                format="yyyy"
                                views={["year"]}
                                renderIconShowHide={false}
                              />
                            </Grid>

                          </Grid>
                          :
                          ""
                        }
                        {ativaCenario ?
                          <Grid item sm={12}>
                            <Grid item sm={1}><Grid item sm={1}>Cenário</Grid></Grid>
                            <Grid item sm={3} sx={3}>
                              <SelectArredondado
                                id="cenarioSelecionado"
                                name="cenarioSelecionado"
                                valueKey="id"
                                valueName="nome"
                                value={idCenario}
                                dataSource={cenarios}
                                onChange={(event) => {
                                  setIdCenario(event.target.value);
                                }}
                              />
                            </Grid>
                          </Grid>
                          :
                          ""
                        }
                        {ativaTextoProinfa ?
                          <Grid item sm={12} >
                            <Typography style={{ textAlign: "left", paddingTop: "-30px", marginTop: "-30px" }}>Observações:</Typography>
                            <Typography style={{ textAlign: "left" }}>Índices usados na atualização:</Typography>
                            {infoAtualizar.map((item) => {
                              return <Typography style={{ textAlign: "left" }}>
                                {`${item.nomeFonteEnergia} - ${item.tituloSerieHistorica}`}
                              </Typography>
                            })}
                            <Typography style={{ textAlign: "left" }}>Último ano de referência do contrato
                              PROINFA importado: {maxAnoReferencia}</Typography>
                            <Typography style={{ textAlign: "left" }}>Ano: refere-se ao ano de referência do
                              contrato PROINFA que será usado como base da projeção</Typography>
                          </Grid>
                          : ""}
                      </Grid>
                    </Tabs>
                  </AppBar>

                  <Grid item sm={12}>
                    <Grid
                      container
                      p={4}
                      spacing={4}
                      className={classes.container}
                      justifyContent="center"
                    >
                      <Grid item sm={3}>
                        <Botao
                          type="button"
                          color={Base.Mango}
                          label="Projetar"
                          onClick={(() => onProjetar())}
                          className={classes.buttonCustom}
                        />
                      </Grid>
                      <Grid item sm={3} />
                      <Grid item sm={3}>
                        <Botao
                          type="button"
                          color={Base.Mango}
                          label="Cancelar"
                          onClick={onCancelar}
                          className={classes.buttonCustom}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>}
            </>}
        </div>

      </Loader>
    </div>
  );
};

export default ParametrosProjecaoBrasil;
