import React from "react";
import CampoDropDownTabela from "../../campoDropDownTabela";

export default class ColunaCampoDropDown {
  constructor(
    field,
    headerName,
    sortable = false,
    onChangeSelecionarPlano,
    maxWidth = null,
    minWidth = null
  ) {
    this.selector = field;
    this.name = headerName;
    this.sortable = sortable;
    this.maxWidth = maxWidth;
    this.minWidth = minWidth;
    this.cell = (params) => {
      return (
        <CampoDropDownTabela
          id={params.id}
          name={params.nome}
          valueKey="id"
          valueName="descricao"
          dataSource={params.plano}
          value={params.cotacaoSelecionada}
          onChange={(event) =>
            onChangeSelecionarPlano(event.target.value, params)
          }
          parametros={params}
        />
      );
    };
  }
}
