import PropTypes from "prop-types";
import React from "react";
import { generate } from "shortid";

import { Edit } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";

import MaterialSwitch from "componentes/switch";
import { HtmlTooltip } from "paginas/Graficos/ConsumoAtual/style";
import { useStyles } from "./style";

import enumeradorBotao from "../enumeradores/enumeradorBotao";

const ColunaMultiplosBotoes = ({ onClick, parametros }) => {
  const classes = useStyles();

  const onClickEdicao = () => onClick(enumeradorBotao.Edicao, parametros);

  const onClickStatus = () => onClick(enumeradorBotao.Status, parametros);

  return (
    <Grid container spacing={0} className={classes.fundoCelula}>
      <Grid item xs={6}>
        <Button
          key={generate()}
          onClick={onClickEdicao}
          className={`text-white ${classes.botao}`}
        >
          <HtmlTooltip title="Editar">
            <Edit />
          </HtmlTooltip>
        </Button>
      </Grid>

      <Grid item xs={6} className={classes.botaoCentral}>
        <Button key={generate()} onClick={onClickStatus}>
          <HtmlTooltip title="Status">
            <div>
              <MaterialSwitch checked={parametros.ativo} />
            </div>
          </HtmlTooltip>
        </Button>
      </Grid>
    </Grid>
  );
};

ColunaMultiplosBotoes.propTypes = {
  onClick: PropTypes.oneOfType([PropTypes.func]).isRequired,
  parametros: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired
};

export default ColunaMultiplosBotoes;
