import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const api = () => obterApi(MICROSERVICO.MANAGEMENT);

export const ordenacaoPorColuna = () => {
  const sortOrder = [
    {
      key: "4",
      asc: "1",
      desc: "2",
      label: "Data"
    }
  ];

  return sortOrder;
};

export const listarFiltrarClientePor = () => {
  const filtros = [
    {
      key: "PF",
      label: "PF"
    },
    {
      key: "PJ",
      label: "PJ"
    }
  ];

  return filtros;
};

export const listarFiltrarPorCriacaoDeSenha = () => {
  const filtros = [
    {
      key: "true",
      label: "SIM"
    },
    {
      key: "false",
      label: "NÃO"
    }
  ];

  return filtros;
};

export const ordenacaoPorStatus = () => {
  const sortOrder = [
    {
      key: "S",
      label: "Aprovado"
    },
    {
      key: "N",
      label: "Reprovado"
    },
    {
      key: "pendente",
      label: "Pendente"
    }
  ];

  return sortOrder;
};

export const listarCancelado = () => {
  const filtros = [
    {
      key: "true",
      label: "Sim"
    },
    {
      key: "false",
      label: "Não"
    }
  ];

  return filtros;
};

export const listarOperacaoControlePaginado = async (
  filtro,
  paramsSecundarios
) => {
  const filtroQueryString = new URLSearchParams(filtro).toString();
  if (paramsSecundarios) {
    return api().get(
      `v1/OperacaoControle/GetAllPaginadoV2?${filtroQueryString}${paramsSecundarios}`
    );
  }
  return api().get(`v1/OperacaoControle/GetAllPaginadoV2?${filtroQueryString}`);
};

export const exportarExcel = async () => {
  return api().get("v1/OperacaoControle/ExportarExcelPorFila");
};

export const listarOperacaoControlePJPaginado = async (filtro) => {
  const filtroQueryString = new URLSearchParams(filtro).toString();

  return api().get(
    `v1/OperacaoControle/GetAllPaginadoPJ?${filtroQueryString}&statusboletanaoexibida=3&statusboletanaoexibida=4`
  );
};

export const listarRejeitado = () => {
  const filtros = [
    {
      key: "true",
      label: "Sim"
    },
    {
      key: "false",
      label: "Não"
    }
  ];

  return filtros;
};
