import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { CloudUploadOutlined } from "@mui/icons-material";
import { useStyles } from "./style";

const InputFile = ({ id, name, accept, onUpload, label, file }) => {
  const classes = useStyles();

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label htmlFor={id} className={classes.customFile}>
      <input
        type="file"
        id={id}
        name={name}
        accept={accept}
        onChange={(e) => onUpload(e)}
        className={classes.inputFile}
        value={file}
      />

      <Box className={classes.textContainer}>
        <Box className={classes.icon}>
          <CloudUploadOutlined />
        </Box>
        <p className={classes.text}>{label}</p>
      </Box>
    </label>
  );
};

InputFile.propTypes = {
  onUpload: PropTypes.func,
  id: PropTypes.string,
  name: PropTypes.string,
  accept: PropTypes.string,
  label: PropTypes.string,
  file: PropTypes.string
};

InputFile.defaultProps = {
  onUpload: () => {},
  id: "uploadFile",
  name: "uploadFile",
  accept: "video/*",
  label: "",
  file: ""
};

export default InputFile;
