export default class FiltroDto {
  constructor(
    ano,
    mes,
    unidadeSelecionada,
    clienteSelecionado,
    empresaSelecionada,
    ordenacao,
    textoPesquisa,
    pagina,
    tamanhoPagina
  ) {
    this.Ano = ano;
    this.Mes = mes;
    this.UnidadeId = unidadeSelecionada;
    this.ClienteId = clienteSelecionado;
    this.EmpresaId = empresaSelecionada;
    this.SortOrder = ordenacao;
    this.SearchString = textoPesquisa;
    this.Page = pagina;
    this.PageSize = tamanhoPagina;
  }
}
