import React from "react";
import { Box, Card, Grid } from "@mui/material";
import { Botao } from "../../componentes";

import Arrow from "../../assets/ajuda/arrow.png";
import Email from "../../assets/ajuda/email.png";
import WhatsApp from "../../assets/ajuda/Vector.png";

const Ajuda = () => {
  const handleArtigos = () => {
    window.open("https://sualuz.com.br/blog", "_blank");
  }
  const handleWhatapps = () => {
    window.open("https://api.whatsapp.com/send?phone=551154677000", "_blank");
  }

  const handleEmail = () => {
    window.location.href = "mailto: atendimento@sualuz.com.br";
  }

  return (
    <Card>
      <Grid component={Box} container spacing={2} px={2} py={4}>
        <Grid item xs={12} md={12}>
          <Box component="div" fontWeight="800" fontSize="24px" color="#332053">Tire suas dúvidas rapidamente com nossos artigos:</Box>
        </Grid>  
        <Grid item xs={12} md={12}>
          <Box component="div" fontWeight="400" fontSize="16px" color="#332053">Conte com explicações sobre o processo de contratação e uso. Encontre conteúdo com o passo-a-passo sobre as principais dúvidas, para você resolver rapidamente.</Box>
        </Grid> 
        <Grid item xs={12} sm={5} md={4} xl={3}>
          <Botao
            type="submit"
            label="Buscar artigos"
            onClick={handleArtigos}
          />
        </Grid> 
        <Grid item xs={12} md={12}>
          <Box component="div" fontWeight="800" fontSize="24px" color="#332053">Ou escolha como entrar em contato com a gente:</Box>
        </Grid>  
        <Grid item xs={12} sm={6} md={6} xl={4}>
          <Box 
            component="div" 
            display="flex" 
            bgcolor="#FBFBFD" 
            border="1px solid #D2D2D7" 
            boxShadow="0px 4px 8px 2px rgba(0, 0, 0, 0.08)" 
            borderRadius="8px"
            sx={{ cursor: "pointer"}} 
            px={2} 
            py={2} 
            onClick={handleWhatapps}
          >
            <Box component="div" width="23.14" height="23.25" mr={2} mt="5px">
              <Box component="img" src={WhatsApp} />
            </Box>
            <Box component="div" mr={4}>
              <Box component="div" fontWeight="400" fontSize="20px">WhatsApp</Box>
              <Box component="div" color="#4F4F4F" fontSize="16px" fontWeight="400">Mande mensagem a qualquer hora. Te respondemos de segunda a sexta das 8h às 22h, aos sábados e feriados das 8h às 18h.</Box>
            </Box>
            <Box component="div" display="flex" alignItems="center" justifyContent="end" width="5.86" height="10.23">
              <Box component="img" src={Arrow} />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6} xl={4}>
          <Box 
            component="div" 
            display="flex" 
            bgcolor="#FBFBFD" 
            border="1px solid #D2D2D7" 
            boxShadow="0px 4px 8px 2px rgba(0, 0, 0, 0.08)" 
            borderRadius="8px"
            sx={{ cursor: "pointer"}} 
            px={2} 
            py={2} 
            onClick={handleEmail}
          >
            <Box component="div" width="23.25" height="18.6" mr={2} mt="5px">
              <Box component="img" src={Email} />
            </Box>
            <Box component="div" mr={4}>
              <Box component="div" fontWeight="400" fontSize="20px">E-mail</Box>
              <Box component="div" color="#4F4F4F" fontSize="16px" fontWeight="400">Mande mensagem a qualquer hora. Te respondemos de segunda a sexta das 8h às 22h, aos sábados e feriados das 8h às 18h.</Box>
            </Box>
            <Box component="div" display="flex" alignItems="center" justifyContent="end" width="5.86" height="10.23">
              <Box component="img" src={Arrow} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Card>
  )
}

export default Ajuda;