import styled from "styled-components";
import { makeStyles } from '@mui/styles';
import { Base } from "componentes/cores";

export const useStyles = makeStyles({
  label: {
    alignItems: "center",
    backgroundColor: Base.BlackBastille,
    color: `${Base.OffWhite} !important`,
    display: "flex",
    fontSize: "18px",
    height: "54px",
    marginRight: "2px",
    opacity: (props) => (props.ativo ? 1 : 0.5),
    padding: "0 15px"
  },
  squareWidth: {
    height: "54px",
    maxWidth: "54px",
    width: "100%",
    "&:not(:last-child)": {
      marginRight: "2px"
    }
  },
  gridItem: {
    flexBasis: "100%",
    fontSize: "2.1875rem",
    margin: 0,
    maxWidth: "54px",
    width: "100%"
  },
  treeItem: {
    backgroundColor: Base.BlackBastille,
    color: `${Base.OffWhite} !important`,
    height: "54px"
  },
  expandItem: {
    backgroundColor: Base.BlackRock,
    fontSize: "18px",
    height: "auto",
    marginLeft: "56px !important",
    marginTop: 2,
    minWidth: "calc(100% - 56px)",
    padding: "15px 20px"
  },
  wrapper: {
    alignItems: "center",
    display: "flex",
    height: "100%",
    minHeight: "100%"
  },
  center: {
    justifyContent: "center"
  }
});

export const Label = styled.div``;
