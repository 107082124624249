import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const api = () => obterApi(MICROSERVICO.MEASUREMENT);

export const listarOrganizarPor = () => {
  const ordenar = [
    {
      key: "1",
      asc: "1",
      desc: "2",
      label: "Alfabética"
    },
    {
      key: "4",
      asc: "4",
      desc: "0",
      label: "Recentes"
    }
  ];

  return ordenar;
};

export const filtroProdutoPor = () => {
  const filtroProduto = [
    {
      key: "1",
      label: "DG"
    },
    {
      key: "2",
      label: "Consultoria"
    }
  ];

  return filtroProduto;
};

export const filtroTipoEnergiaPor = () => {
  const filtroTipoEnergia = [
    {
      key: "1",
      label: "Conv"
    },
    {
      key: "2",
      label: "I5"
    },
    {
      key: "3",
      label: "I1"
    },
    {
      key: "4",
      label: "I0"
    },
    {
      key: "5",
      label: "I8"
    },
    {
      key: "6",
      label: "CQ5"
    }
  ];

  return filtroTipoEnergia;
};

export const filtroSubmercadoPor = () => {
  const filtroSubmercado = [
    {
      key: "1",
      label: "SE/CO"
    },
    {
      key: "2",
      label: "S"
    },
    {
      key: "3",
      label: "NE"
    },
    {
      key: "4",
      label: "N"
    }
  ];

  return filtroSubmercado;
};

export const filtroStatusPor = () => {
  const filtroStatus = [
    {
      key: "1",
      label: "Lançada"
    },
    {
      key: "2",
      label: "Aprovada"
    },
    {
      key: "3",
      label: "Cancelada"
    },
    {
      key: "4",
      label: "Rejeitada"
    }
  ];

  return filtroStatus;
};

// Mudar status
export const mudarStatus = async (id, status) => {
  return api().put(`v1/boletas/${id}/status/${status}`);
};

// Salvar Boleta
export const salvarBoleta = async (id, dados) => {
  let metodo = "post";
  let url = "v1/boletas/";
  if (id > 0) {
    metodo = "put";
    url = `${url}${id}`;
  }
  return api()[metodo](url, dados);
};

// Busca Por Id
export const buscarPorId = async (id) => {
  return api().get(`v1/boletas/${id}`);
};

// Listar
export const listarBoletas = async (filtro) => {
  const filtroQueryString = new URLSearchParams(filtro).toString();
  return api().get(`v1/boletas/paginado?${filtroQueryString}`);
};

// Flexibilidade Superior
export const listarFlexibilidadeSuperior = async () => {
  return api().get("v1/flexibilidadesuperior");
};

// Flexibilidade Inferior
export const listarFlexibilidadeInferior = async () => {
  return api().get("v1/flexibilidadeinferior");
};
