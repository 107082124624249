/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from "react";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { store } from "global/redux";
import { useTheme } from "@mui/material/styles";
import { Card, Grid, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import InputDataBranco from "componentes/inputTextoDataBranco";
import { useStyles, Tabela } from "./style";
import { obterOrcamentoCDEBrasil } from "../../../../servicos/projecaoBrasil";
import { obterCenarios } from "../../../../servicos/cenariosSeriesHistoricas";
import CloseIcon from "@mui/icons-material/Close";
import RotasDTO from "../../../../rotas/rotasUrlDto";
import { useHistory } from "react-router-dom";

const OrcamentoCDEBrasilVisualizar = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const [orcamentoCDE, setOrcamentoCDE] = useState({});
  const [cenarios, setCenarios] = useState([]);
  const [cenario, setCenario] = useState();
  const [ano, setAno] = useState();

  const onChangeAno = (data) => {
    setAno(data.getFullYear());
  };

  const obterValores = async () => {
    try {
      const response = await obterOrcamentoCDEBrasil(
        ano,
        cenario
      );
      if (response?.status === 200 && response?.data) {
        setOrcamentoCDE(response.data);
        console.log(response.data);
      } else {
        setOrcamentoCDE();
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: `Falha ao obter dados: ${response.data}`
          })
        );
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: `Erro não previsto: ${error}`
        })
      );
    }
  };

  const handleCenarios = async () => {
    try {
      const lista = await obterCenarios();
      if (lista?.status === 200 && lista?.data) {
        setCenarios(lista?.data);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  const handleChangeCenario = (event) => {
    cenarios.forEach((item) => {
      if (item.id === event) {
        setCenario(item.id);
      }
    });
  };

  useEffect(() => {
    console.log("no useeffects: " + ano);
    if (cenario !== undefined && ano !== undefined) obterValores();
  }, [ano, cenario]);

  useEffect(() => {
    handleCenarios();
  }, [cenario]);

  const onFechar = (id) => {
    history.push(`${RotasDTO.ProjecaoBrasilListar}`);
  };

  return (
    <>
      <Grid container className={classes.containerTitulo}>
        <Grid item xs={11} className={classes.titulo}>
          Orçamento CDE Brasil
        </Grid>
        <Grid item xs={1}>
          <CloseIcon onClick={onFechar} className={classes.icon} />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={4}
        className={classes.container}
        justifyContent="flex-end"
        alignItems="center"
      >
        <Grid item sm={3}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="cenario-label">Cenário</InputLabel>
            <Select
              labelId="cenario-label"
              id="cenario"
              label="Cenário"
              onChange={(e) => handleChangeCenario(e.target.value)}
              fullWidth
            >
              {cenarios.map((item) => (
                <MenuItem value={item?.id}>{item.nome}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item sm={3}>
          <InputDataBranco
            label="Ano"
            type="text"
            id="teste"
            name=""
            format="yyyy"
            customValue={ano ? new Date(ano, 0) : null}
            onChange={(data) => onChangeAno(data)}
            views={["year"]}
            minDate= {new Date(`Thu Oct 01 ${new Date().getFullYear() - 10} GMT-0300`)}
            maxDate= {new Date(`Thu Oct 01 ${new Date().getFullYear() + 20} GMT-0300`)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.container}>
        <Grid item sm={12}>
          {orcamentoCDE && orcamentoCDE.tarifasCDE &&
            <Card className={classes.container}>
              <Grid container p={2} spacing={2} className={classes.container}>
                <Grid item sm={12}>                
                  <Tabela className={classes.container}>
                    <Tabela.Thead>
                      <Tabela.Tr>
                        <Tabela.Th>Ano</Tabela.Th>
                        <Tabela.Th>Região</Tabela.Th>
                        <Tabela.Th>Nível de Tensão</Tabela.Th>
                        <Tabela.Th>CDE Uso (R$/MWh)</Tabela.Th>
                        <Tabela.Th>CDE GD (R$/MWh)</Tabela.Th>        
                      </Tabela.Tr>
                    </Tabela.Thead>
                    <Tabela.Tbody>
                      {orcamentoCDE.tarifasCDE?.map((tarifa, index) => (
                        <Tabela.Tr key={index}>
                          <Tabela.Td>{tarifa.ano}</Tabela.Td>
                          <Tabela.Td>{tarifa.regiao}</Tabela.Td>
                          <Tabela.Td>{tarifa.tensao}</Tabela.Td>
                          <Tabela.Td>{parseFloat(tarifa.cdeUso).toFixed(2)}</Tabela.Td>
                          <Tabela.Td>{parseFloat(tarifa.cdegd).toFixed(2)}</Tabela.Td>                       
                        </Tabela.Tr>
                      ))}
                      <Tabela.Tr>
                        <Tabela.Td>{ano}</Tabela.Td>
                        <Tabela.Td>CDE Brasil</Tabela.Td>
                        <Tabela.Td></Tabela.Td>
                        <Tabela.Td>{parseFloat(orcamentoCDE.cdeUsoBrasil).toFixed(2)}</Tabela.Td>
                        <Tabela.Td>{parseFloat(orcamentoCDE.cdegdBrasil).toFixed(2)}</Tabela.Td>
                      </Tabela.Tr>
                    </Tabela.Tbody>
                  </Tabela>                  
                </Grid>
              </Grid>
            </Card>
          }
        </Grid>
      </Grid>
    </>
  );
};

export default OrcamentoCDEBrasilVisualizar;
