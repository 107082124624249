const statusCotacao = {
  Aberta: "AT",
  Fechada: "FE",
  Expirada: "IN",
  Cancelada: "CA",
  Rejeitada: "RE",
  Anulada: "AN"
};

export default statusCotacao;
