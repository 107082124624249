export default class PreFaturamentoListaDto {
  constructor(
    id,
    unidadeConsumidoraId,
    mesReferencia,
    dataRealizado,
    statusId,
    statusNome,
    ativo
  ) {
    this.id = id;
    this.unidadeConsumidoraId = unidadeConsumidoraId;
    this.mesReferencia = mesReferencia;
    this.dataRealizado = dataRealizado;
    this.statusId = statusId;
    this.statusNome = statusNome;
    this.ativo = ativo;
  }
}
