import { makeStyles } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import theme from "../../../../../themes";
import { Base } from "../../../../../componentes/cores";

const themes = createTheme();

export const useStyles = makeStyles(() => ({
  header: {
    padding: "24px 40px 0px 40px",
    boxShadow: (props) => (props.shadowHeader ? `0 3px ${Base.OffWhite}` : "")
  },
  footer: {
    justifyContent: "end",
    padding: "24px 40px",
    boxShadow: (props) => (props.shadowFooter ? `0 -3px ${Base.OffWhite}` : "")
  },
  content: {
    padding: "0px 40px",
    marginTop: 20,
    paddingBottom: 20
  },
  containerBotaoAssinar: {
    justifyContent: "flex-end",
    justifySelf: "flex-end",
    justifyItems: "flex-end"
  },
  containerContent: {
    padding: "30px 10px 30px 0px",
    maxHeight: "300px",
    overflowX: "auto",
    fontFamily: theme.font.family,
    fontSize: "18px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.4px",
    textAlign: "justify",
    color: Base.Preto
  },
  containerTitulo: {
    alignItems: "center",
    alignContent: "center",
    alignSelf: "center",
    marginBottom: 20
  },
  labelMenor: {
    fontFamily: theme.font.family,
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.27px"
  },
  labelMaior: {
    fontSize: "16px",
    fontFamily: theme.font.family,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.27px"
  },
  icone: {
    marginRight: 10
  },
  containerIcone: {
    alignItems: "center",
    alignContent: "center",
    alignSelf: "center",
    justifyContent: "center",
    justifySelf: "center",
    justifyItems: "center"
  },
  titulo: {
    fontFamily: theme.font.family,
    fontSize: "24px",
    fontWeight: 700,
    color: theme.color.tituloColorCardRepresetante
  },
  subtitulo: {
    fontFamily: theme.fontTahoma.family,
    fontSize: "16px",
    marginTop: 15,
    marginBottom: 15,
    color: Base.BoldLuzText
  },
  prazoTexto: {
    color: Base.BoldLuzText
  },
  linkDownload: {
    fontFamily: theme.font.family,
    fontSize: "18px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.4px",
    textAlign: "justify",
    color: Base.Preto,
    textDecoration: "underline",
    "&:hover": {
      textDecoration: "underline",
      color: Base.Preto
    }
  },
  imagemFundo: {
    backgroundColor: `${Base.White}`,
    boxShadow: themes.shadows[1],
    borderRadius: 10
  },
  botaoOffWhite: {
    background: "transparent"
  }
}));
