import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { cnpj } from "cpf-cnpj-validator";
import moment from "moment";

// Form Hooks
import { useForm } from "react-hook-form";

// Componentes
import { Divider, Grid } from "@mui/material";
import Botao from "componentes/botao";
import Loader from "componentes/loader";
import ModalConfirmacao from "componentes/modalConfirmacao";
import MaterialInputMascara from "componentes/inputTextoMascara";
import MaterialSwitch from "componentes/switch";
import InputData from "componentes/inputTextoData";
import MaterialInputTexto from "componentes/inputTexto/materialInput";
import SelectArredondado from "componentes/selectArredondado";
import MaterialInputTelefone from "componentes/inputTexto/materialInputTelefone";

// Rotas
import { RotasDTO } from "global/rotas/rotasUrlDto";

// Redux
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import {
  desabilitarUnidade,
  salvarUnidades,
  selecionarCliente,
  selecionarEmpresa,
  selecionarUnidade
} from "global/redux/modulos/usuario/actions";

// Serviços
import {
  buscarPorCnpj,
  buscarStatus,
  buscarSubmercado,
  buscarNivelTensao,
  salvarUnidade,
  listarUnidadesConsumidorasCombo,
  buscaTipoRepresentacao,
  buscarTiposUnidadeConsumidora
} from "servicos/unidadesServico";
import { buscarDistribuidoras } from "servicos/distribuidorasServico";
import { obterCepCobertura } from "servicos/correiosServico";
import { buscarParcelaDeCarga } from "servicos/parcelaAtivoCCEEServico";
import { buscarPorCnpj as buscarClientePorCnpj } from "servicos/clientesServico";
import { buscarDadosCadastraisPorCnpj } from "servicos/dadosCadastraisCnpjServico";
import { obterOperacaoControlePorUnidadeId } from "servicos/operacaoControleServico";

// DTO
import { InterfaceDTO } from "global/dto/interfacesDto";
import { removerCaracteres, fieldEmpty } from "servicos/utils";
import { tipoUnidadeConsumidoraList } from "global/constantes";

// Styles
import { useStyles } from "../../style";

const MercadoLivreForm = (props) => {
  const {
    id,
    dadosUnidade,
    produtoSelecionado,
    empresaUnidade,
    usuario,
    rotas,
    menu
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const { register, errors, handleSubmit } = useForm({
    reValidateMode: "onSubmit"
  });

  useEffect(() => {
    store.dispatch(desabilitarUnidade(true));

    return () => {
      store.dispatch(desabilitarUnidade(false));
    };
    // eslint-disable-next-line
  }, [desabilitarUnidade]);

  const [unidadeExistente, setUnidadeExistente] = useState(false);
  const [idUnidadeExistente, setIdUnidadeExistente] = useState();
  const [unidadeExistenteDados, setUnidadeExistenteDados] = useState();
  const [
    listaTipoUnidadeConsumidora,
    setListaTipoUnidadeConsumidora
  ] = useState(tipoUnidadeConsumidoraList);
  const [numero, setNumero] = useState();
  const [complemento, setComplemento] = useState(null);
  const [carregandoCnpj, setCarregandoCnpj] = useState(false);
  const [razaoSocial, setRazaoSocial] = useState("");
  const [nomeFantasia, setNomeFantasia] = useState("");
  const [inscricaoEstadual, setInscricaoEstadual] = useState();
  const [inscricaoMunicipal, setInscricaoMunicipal] = useState();
  const [valorTelefone, setValorTelefone] = useState("");
  const [valorCnpj, setValorCnpj] = useState();
  const [valorCep, setValorCep] = useState();
  const [limparCep, setLimparCep] = useState(false);
  const [numeroInstalacao, setNumeroInstalacao] = useState();
  const [cnpjFormatado, setCnpjFormatado] = useState();

  // Status
  const [listaStatus, setListaStatus] = useState([]);
  const [carregandoStatus, setCarregandoStatus] = useState(false);
  const [statusId, setStatusId] = useState();
  const [limparCnpj, setLimparCnpj] = useState(false);
  const [disabledCpf, setDisabledCpf] = useState(false);
  const [tipoUc, setTipoUc] = useState();
  const [numerofases, setNumerofases] = useState();
  const [produtoId, setProdutoId] = useState(parseInt(produtoSelecionado, 10));
  const [icms, setIcms] = useState(0);

  // Submercado
  const [listaSubmercado, setListaSubmercado] = useState([]);
  const [carregandoSubmercado, setCarregandoSubmercado] = useState(false);
  const [submercadoId, setSubmercadoId] = useState();

  const onBlurNomeFantasia = (nome) => {
    // eslint-disable-next-line quotes
    const nomeFantasiaFormatado = nome.replaceAll("'", "").replaceAll('"', "");
    setNomeFantasia(nomeFantasiaFormatado);
  };

  const onConfirmarTelaEdicao = () => {
    setUnidadeExistente(false);
    history.push(
      `${RotasDTO.UnidadesConsumidoras}/cadastro/${idUnidadeExistente}`
    );
  };

  // Situação
  const [situacao, setSituacao] = useState(false);
  const onChangeSituacao = (valor) => {
    setSituacao(valor);
  };

  // Data migração
  const [dataMigracao, setDataMigracao] = useState(null);
  const onChangeDataMigracao = (data) => {
    setDataMigracao(data);
  };

  // Distribuidoras
  const [distribuidoraId, setDistribuidoraId] = useState();
  const onChangeDistribuidora = (distribuidora) => {
    setDistribuidoraId(distribuidora.target.value);
  };

  const [carregandoUnidade, setCarregandoUnidade] = useState(false);
  // const [dadosUnidade, setDadosUnidade] = useState();

  // Distribuidoras
  const [listaDistribuidora, setListaDistribuidora] = useState([]);
  const [carregandoDistribuidora, setCarregandoDistribuidora] = useState(false);

  const obterDistribuidora = async () => {
    try {
      setCarregandoDistribuidora(true);
      const lista = await buscarDistribuidoras();
      if (lista?.status === 200 && lista?.data) {
        setListaDistribuidora(lista?.data);
      }
      setCarregandoDistribuidora(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoDistribuidora(false);
    }
  };

  useEffect(() => {
    obterDistribuidora();
  }, []);

  const [ufId, setUFId] = useState();
  const onChangeUF = (uf) => {
    setUFId(uf.target.value);
  };

  // CEP
  const [carregandoEndereco, setCarregandoEndereco] = useState(false);
  const [dadosEndereco, setDadosEndereco] = useState();

  const carregarCep = useCallback(async (cep) => {
    try {
      setCarregandoEndereco(true);
      const endereco = await obterCepCobertura(cep);

      if (endereco?.status === 200) {
        setUFId(endereco.data.uf);
        setDadosEndereco({
          endereco: endereco.data.logradouro,
          bairro: endereco.data.bairro,
          cidade: endereco.data.cidade
        });
        setNumero(" ");
        setComplemento(endereco.data.complemento);
      }

      setCarregandoEndereco(false);
    } catch (error) {
      setUFId(null);
      setDadosEndereco({
        endereco: "",
        bairro: "",
        cidade: ""
      });
      setNumero("");
      setComplemento("");
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Não foi possível localizar o CEP!"
        })
      );
      setCarregandoEndereco(false);
    }
  }, []);

  const onBlurCep = (cepValor) => {
    if (cepValor.replace(/\s/g, "").length > 7) {
      carregarCep(cepValor);
    }
  };

  const [tipoFormulario, setTipoFormulario] = useState(1);

  const listaModalidade = [
    { nome: "AZUL", id: 1 },
    { nome: "VERDE", id: 2 }
  ];

  const [modalidadeId, setModalidadeId] = useState();
  const onChangeModalidade = (modalidade) => {
    setTipoFormulario(modalidade.target.value === "2" ? 2 : 1);
    setModalidadeId(modalidade.target.value);
  };

  const listaUF = [
    { nome: "Acre", UF: "AC" },
    { nome: "Alagoas", UF: "AL" },
    { nome: "Amapá", UF: "AP" },
    { nome: "Amazonas", UF: "AM" },
    { nome: "Bahia", UF: "BA" },
    { nome: "Ceará", UF: "CE" },
    { nome: "Distrito Federal", UF: "DF" },
    { nome: "Espírito Santo", UF: "ES" },
    { nome: "Goiás", UF: "GO" },
    { nome: "Maranhão", UF: "MA" },
    { nome: "Mato Grosso", UF: "MT" },
    { nome: "Mato Grosso do Sul", UF: "MS" },
    { nome: "Minas Gerais", UF: "MG" },
    { nome: "Pará", UF: "PA" },
    { nome: "Paraíba", UF: "PB" },
    { nome: "Paraná", UF: "PR" },
    { nome: "Pernambuco", UF: "PE" },
    { nome: "Piauí", UF: "PI" },
    { nome: "Rio de Janeiro", UF: "RJ" },
    { nome: "Rio Grande do Norte", UF: "RN" },
    { nome: "Rio Grande do Sul", UF: "RS" },
    { nome: "Rondônia", UF: "RO" },
    { nome: "Roraima", UF: "RR" },
    { nome: "Santa Catarina", UF: "SC" },
    { nome: "São Paulo", UF: "SP" },
    { nome: "Sergipe", UF: "SE" },
    { nome: "Tocantins", UF: "TO" }
  ];

  const listaIcmsInclusoNF = [
    { nome: "Não", id: "0" },
    { nome: "Sim", id: "1" }
  ];

  const [icmsNaNotaFiscal, setIcmsNaNotaFiscal] = useState();
  const onChangeIcmsNF = (valor) => {
    setIcmsNaNotaFiscal(valor.target.value);
  };

  const [parcelaDeCargaExistente, setParcelaDeCargaExistente] = useState(false);
  const [parcelaDeCarga, setParcelaDeCarga] = useState("");

  const zerarCamposEmpresa = () => {
    setRazaoSocial("");
    setNomeFantasia("");
    setValorTelefone(" ");
    setInscricaoMunicipal("");
    setInscricaoEstadual("");
    setStatusId(null);
  };

  const zerarCamposParcela = () => {
    setParcelaDeCargaExistente(false);
    setSubmercadoId("");
    setParcelaDeCarga(" ");
  };

  const zerarCamposEndereco = () => {
    setLimparCep(true);
    setDadosEndereco(null);
    setNumero("");
    setComplemento("");
    setUFId(null);
  };

  const consultarRF = useCallback(async (valor) => {
    try {
      setCarregandoCnpj(true);
      const dadosCadastrais = await buscarDadosCadastraisPorCnpj(valor);
      const telefone1 =
        dadosCadastrais?.data?.data?.telefone1 &&
        `55${dadosCadastrais?.data?.data?.telefone1}`;
      const telefone2 =
        dadosCadastrais?.data?.data?.telefone2 &&
        `55${dadosCadastrais?.data?.data?.telefone2}`;

      if (dadosCadastrais?.data?.success) {
        setRazaoSocial(dadosCadastrais?.data?.data?.razaoSocial);
        const nomeFantasiaFormatado = dadosCadastrais?.data?.data?.nomeFantasia
          .replaceAll("'", "")
          // eslint-disable-next-line quotes
          .replaceAll('"', "");
        setNomeFantasia(nomeFantasiaFormatado);
        setValorTelefone(telefone1 || telefone2);
        setInscricaoMunicipal("");
        setInscricaoEstadual("");
        setStatusId(null);
        zerarCamposParcela();
        zerarCamposEndereco();
      } else {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "CNPJ inválido!"
          })
        );
        zerarCamposEmpresa();
        zerarCamposParcela();
        zerarCamposEndereco();
      }
      setCarregandoCnpj(false);
    } catch (error) {
      console.info(error);
      setCarregandoCnpj(false);
    }
  }, []);

  const verificarCnpjExistente = useCallback(
    async (valor) => {
      try {
        setCarregandoCnpj(true);
        if (!dadosUnidade) {
          if (valor.length === 14) {
            const unidade = await buscarPorCnpj(valor);
            if (unidade?.data && unidade?.data?.unidadesConsumidoras) {
              setUnidadeExistenteDados(unidade?.data);
              setIdUnidadeExistente(unidade?.data?.unidadesConsumidoras[0].id);
            }

            const dadosCliente = await buscarClientePorCnpj(valor);
            if (dadosCliente?.data) {
              setRazaoSocial(dadosCliente?.data?.razaoSocial);
              setNomeFantasia(dadosCliente?.data?.nomeFantasia);
              setValorTelefone(dadosCliente?.data?.telefone);
              setInscricaoEstadual(dadosCliente?.data?.inscricaoEstadual);
              setInscricaoMunicipal(dadosCliente?.data?.inscricaoMunicipal);
              setStatusId(dadosCliente?.data?.status);
              setDadosEndereco({
                endereco: dadosCliente?.data?.endereco,
                bairro: dadosCliente?.data?.bairro,
                cidade: dadosCliente?.data?.cidade
              });
              setValorCep(dadosCliente?.data?.cep);
              setNumero(dadosCliente?.data?.numero);
              setComplemento(dadosCliente?.data?.complemento);
              setUFId(dadosCliente?.data?.uf);
            } else {
              consultarRF(valor);
            }

            const verificarParcelaCarga = await buscarParcelaDeCarga(valor);
            if (
              verificarParcelaCarga?.status === 200 &&
              verificarParcelaCarga?.data?.parcelaDeCarga
            ) {
              setParcelaDeCargaExistente(true);
              setSubmercadoId(verificarParcelaCarga?.data?.submercado);
              setParcelaDeCarga(verificarParcelaCarga?.data.parcelaDeCarga);
            } else {
              zerarCamposParcela();
            }
          }
        }
        setCarregandoCnpj(false);
      } catch (error) {
        console.info(error);
      } finally {
        setCarregandoCnpj(false);
      }
    },
    [dadosUnidade]
  );

  const verificaCnpj = (valor) => {
    const cnpjVerificado = valor?.target.value.replace(/[^a-zA-Z0-9]/g, "");
    setCnpjFormatado(cnpjVerificado);
    verificarCnpjExistente(cnpjVerificado);
    consultarRF(cnpjVerificado);
  };

  useEffect(() => {
    if (numero === " ") setNumero("");
    if (parcelaDeCarga === " ") setParcelaDeCarga("");
  }, [numero, parcelaDeCarga]);

  const onChangeStatus = (status) => {
    setStatusId(status.target.value);
  };

  const obterStatus = async () => {
    try {
      setCarregandoStatus(true);
      const lista = await buscarStatus();
      if (lista?.status === 200 && lista?.data) {
        setListaStatus(lista?.data);
      }
      setCarregandoStatus(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoStatus(false);
    }
  };

  useEffect(() => {
    obterStatus();
  }, []);

  const onChangeSubmercado = (submercado) => {
    setSubmercadoId(submercado.target.value);
  };

  const obterSubmercado = async () => {
    try {
      setCarregandoSubmercado(true);
      const lista = await buscarSubmercado();
      if (lista?.status === 200 && lista?.data) {
        setListaSubmercado(lista?.data);
      }
      setCarregandoSubmercado(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoSubmercado(false);
    }
  };

  useEffect(() => {
    obterSubmercado();
  }, []);

  // Nível Tensão
  const [listaNivelTensao, setListaNivelTensao] = useState([]);
  const [carregandoNivelTensao, setCarregandoNivelTensao] = useState(false);
  const [nivelTensaoId, setNivelTensaoId] = useState();

  const onChangeNivelTensao = (nivelTensao) => {
    setNivelTensaoId(nivelTensao.target.value);
  };

  const obterNivelTensao = async () => {
    try {
      setCarregandoNivelTensao(true);
      const lista = await buscarNivelTensao();
      if (lista?.status === 200 && lista?.data) {
        setListaNivelTensao(lista?.data);
      }
      setCarregandoNivelTensao(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoNivelTensao(false);
    }
  };

  const onChangeTipoUc = (selected) => {
    setTipoUc(selected.target.value);
  };

  const obterListaTipoUnidade = async () => {
    try {
      setCarregandoStatus(true);
      const lista = await buscarTiposUnidadeConsumidora();
      if (lista?.status === 200 && lista?.data) {
        setListaTipoUnidadeConsumidora(lista?.data.tipos);
      }
      setCarregandoStatus(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoStatus(false);
    }
  };

  useEffect(() => {
    obterNivelTensao();
    obterListaTipoUnidade();
  }, []);

  const [tipoRepresentacaoId, setTipoRepresentacaoId] = useState();

  const setUnidade = () => {
    setRazaoSocial(dadosUnidade?.nomeRazaoSocial);
    setNomeFantasia(dadosUnidade?.nomeUnidade);
    setInscricaoEstadual(dadosUnidade?.inscricaoEstadual);
    setInscricaoMunicipal(dadosUnidade?.inscricaoMunicipal);
    setDadosEndereco({
      endereco: dadosUnidade?.endereco,
      bairro: dadosUnidade?.bairro,
      cidade: dadosUnidade?.cidade
    });
    setParcelaDeCarga(dadosUnidade?.parcelaCarga);
    setNumero(dadosUnidade?.numero);
    setComplemento(dadosUnidade?.complemento);
    setStatusId(dadosUnidade?.status);
    setDistribuidoraId(dadosUnidade?.distribuidoraId);
    setSubmercadoId(dadosUnidade?.submercadoId);
    setNivelTensaoId(dadosUnidade?.nivelTensaoId);
    setDataMigracao(dadosUnidade?.dataPrevistaMigracao);
    setSituacao(String(dadosUnidade?.situacao).toLocaleUpperCase() === "AT");
    setTipoRepresentacaoId(dadosUnidade?.tipoRepresentacaoId);
    setModalidadeId(dadosUnidade?.classeTarifariaId);
    setTipoFormulario(dadosUnidade?.classeTarifariaId === 2 ? 2 : 1);
    setValorCep(dadosUnidade?.cep);
    setValorTelefone(dadosUnidade?.telefone);
    setValorCnpj(dadosUnidade?.cnpjCpf);
    setUFId(dadosUnidade.uf);
    setTipoUc(dadosUnidade.tipoUnidadeConsumidoraId);
    setNumerofases(dadosUnidade.numerofasesId);
    setProdutoId(parseInt(produtoSelecionado, 10));
    setIcms(dadosUnidade.icms);

    if (dadosUnidade?.icmsNaNotaFiscal != null)
      setIcmsNaNotaFiscal(dadosUnidade?.icmsNaNotaFiscal ? "1" : "0");
  };

  useEffect(() => {
    setProdutoId(parseInt(produtoSelecionado, 10));
  }, [produtoSelecionado]);

  const [habilitarCampos, setHabilitarCampos] = useState(true);

  const checarPermissaoEditar = useMemo(() => {
    const rota = Object.keys(RotasDTO).find(
      (key) => RotasDTO[key] === RotasDTO[rotas.rotaAtual.caminho]
    );

    if (menu?.length && rota) {
      const funcionalidade = menu.find((item) =>
        item.functionality.funcionalidadeColecao.find((f) => f.caminho === rota)
      );

      if (!funcionalidade) return false;

      if (funcionalidade?.functionality?.funcionalidadeColecao?.length) {
        const segmento = funcionalidade?.functionality?.funcionalidadeColecao.find(
          (item) => RotasDTO[item.caminho] === RotasDTO.UnidadesConsumidoras
        );

        if (!segmento) return false;

        if (segmento?.funcionalidadeColecao?.length) {
          const filtro = segmento?.funcionalidadeColecao.find(
            (item) => InterfaceDTO[item.caminho] === InterfaceDTO.PodeEditar
          );

          return Boolean(filtro);
        }
      }
    }

    return false;
  }, [menu, rotas]);

  useEffect(() => {
    if (dadosUnidade) {
      setHabilitarCampos(checarPermissaoEditar);
      setUnidade();
    }
  }, [dadosUnidade, checarPermissaoEditar]);

  const recarregarUnidadesFiltro = useCallback(async () => {
    if (usuario?.clienteSelecionado) {
      const lista = await listarUnidadesConsumidorasCombo(
        usuario?.clienteSelecionado,
        usuario?.token
      );
      if (lista?.status === 200 && lista?.data.unidadeConsumidoraResponseList) {
        store.dispatch(
          salvarUnidades(lista?.data.unidadeConsumidoraResponseList ?? [])
        );
      }
    }
  }, []);

  const enviarFormulario = async (dados) => {
    let hoje = new Date();
    const dd = String(hoje.getDate()).padStart(2, "0");
    const mm = String(hoje.getMonth() + 1).padStart(2, "0");
    const yyyy = hoje.getFullYear();
    hoje = `${yyyy}-${mm}-${dd}`;

    const cnpjFormatadoEnviar = dados?.cnpj
      ? dados?.cnpj?.replace(/[^a-zA-Z0-9]/g, "")
      : valorCnpj?.replace(/[^a-zA-Z0-9]/g, "");
    const telefoneFormatado = dados?.telefone.replace(/[^a-zA-Z0-9]/g, "");

    try {
      if (!usuario.clienteSelecionado) {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "É necessário selecionar um cliente"
          })
        );
      } else {
        const salvou = await salvarUnidade(id ?? 0, {
          ...dados,
          UF: ufId,
          Status: statusId,
          SubmercadoId: submercadoId,
          NivelTensaoId: nivelTensaoId ?? "",
          DistribuidoraId: distribuidoraId,
          ClasseTarifariaId: modalidadeId ?? "",
          ClienteId: usuario.clienteSelecionado,
          PessoaFisicaJuridica: "PJ",
          Situacao: id ? (situacao === true ? "AT" : "IN") : "AT",
          DataReferencia: hoje,
          cnpjCpf: cnpjFormatadoEnviar,
          telefone: telefoneFormatado,
          demandaContratadaForaPonta: dados?.demandaContratadaForaPonta
            .trim()
            .replace(",", "")
            .replace(".", ""),
          demandaContratadaPonta: dados?.demandaContratadaPonta
            ? dados?.demandaContratadaPonta
                .trim()
                .replace(",", "")
                .replace(".", "")
            : 0,
          icms,
          DataPrevistaMigracao: moment(dataMigracao).isValid()
            ? moment(dataMigracao).format("YYYY-MM-DD")
            : "",
          tipoRepresentacaoId,
          IcmsNaNotaFiscal:
            icmsNaNotaFiscal === "1"
              ? true
              : icmsNaNotaFiscal === "0"
              ? false
              : null,
          tipoUnidadeConsumidoraId: tipoUc,
          NumerofasesId: numerofases,
          produtoId
        });

        if (salvou?.status === 200 || salvou?.status === 204) {
          await obterOperacaoControlePorUnidadeId(salvou?.data?.id ?? id);

          store.dispatch(
            alertaExibir({
              tipo: "success",
              mensagem: "Unidade Consumidora salva com sucesso!"
            })
          );
          setTimeout(() => {
            recarregarUnidadesFiltro();
            history.push({
              pathname: RotasDTO.UnidadesConsumidoras,
              state: { dadosUnidade: salvou?.data }
            });
            store.dispatch(selecionarEmpresa(null));
            store.dispatch(selecionarCliente(null));
            store.dispatch(selecionarUnidade(null));
          }, 2000);
        }
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
    setCarregandoUnidade(false);
  };

  const aoEnviarFormulario = (dados) => {
    setCarregandoUnidade(true);
    enviarFormulario(dados);
  };

  const verificarPermissaoAlterarCpf = useCallback(async () => {
    setDisabledCpf(true);
    usuario.menu.forEach((permissao) => {
      if (permissao.functionality.nome === "Pode editar CPF") {
        setDisabledCpf(false);
      }
      if (permissao?.functionality?.funcionalidadeColecao) {
        permissao.functionality.funcionalidadeColecao.forEach(
          (funcionalidade) => {
            if (funcionalidade.nome === "Pode editar CPF") {
              setDisabledCpf(false);
            }
            if (funcionalidade?.funcionalidadeColecao) {
              funcionalidade.funcionalidadeColecao.forEach(
                (funcionalidadeNeta) => {
                  if (funcionalidadeNeta.nome === "Pode editar CPF") {
                    setDisabledCpf(false);
                  }
                }
              );
            }
          }
        );
      }
    });
  }, []);

  useEffect(() => {
    if (id) verificarPermissaoAlterarCpf(id);
  }, [id, verificarPermissaoAlterarCpf]);

  useEffect(() => {
    if (empresaUnidade) {
      store.dispatch(selecionarEmpresa(empresaUnidade?.id));
    }
  }, [empresaUnidade]);

  const { empresaSelecionada } = usuario;

  useEffect(() => {
    if (empresaSelecionada && dadosUnidade) {
      store.dispatch(selecionarCliente(dadosUnidade?.clienteId));
    }
  }, [empresaSelecionada, dadosUnidade]);

  useEffect(() => {
    if (
      unidadeExistenteDados &&
      !id &&
      cnpjFormatado === unidadeExistenteDados.unidadesConsumidoras[0].cnpjCpf &&
      numeroInstalacao ===
        unidadeExistenteDados.unidadesConsumidoras[0].numeroInstalacao &&
      Number(distribuidoraId) ===
        unidadeExistenteDados.unidadesConsumidoras[0].distribuidoraId
    ) {
      setUnidadeExistente(true);
    }
  }, [
    cnpjFormatado,
    numeroInstalacao,
    distribuidoraId,
    unidadeExistenteDados,
    id
  ]);

  const [tipoRepresentacaoLista, setTipoRepresentacaoLista] = useState([]);

  const obterTipoRepresentacao = useCallback(async () => {
    try {
      const lista = await buscaTipoRepresentacao();
      if (lista?.status === 200 && lista?.data)
        setTipoRepresentacaoLista(lista?.data);
    } catch (error) {
      console.info(error);
    }
  }, []);

  useEffect(() => {
    obterTipoRepresentacao();
  }, [obterTipoRepresentacao]);

  const onChangeTipoRepresentacao = (valor) => {
    setTipoRepresentacaoId(valor.target.value);
  };

  return (
    <>
      <ModalConfirmacao
        item={unidadeExistente}
        onConfirmar={() => onConfirmarTelaEdicao()}
        mensagem="Unidade já cadastrada. Deseja ir para a tela de edição?"
        onCancelar={() => {
          setUnidadeExistente(false);
          zerarCamposEmpresa();
          zerarCamposParcela();
          zerarCamposEndereco();
        }}
      />
      <form
        className="needs-validation"
        onSubmit={handleSubmit(aoEnviarFormulario)}
      >
        <Grid container p={2} spacing={3} className={classes.container}>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <MaterialInputMascara
              type="text"
              id="cnpj"
              name="cnpj"
              label="CNPJ"
              mask="##.###.###/####-##"
              limparValor={limparCnpj}
              disabled={disabledCpf || !habilitarCampos}
              redefineLimpar={(estado) => setLimparCnpj(estado)}
              onBlur={(valor) => verificaCnpj(valor)}
              renderIconShowHide={false}
              defaultValue={dadosUnidade?.cnpjCpf}
              ref={register({
                required: "Campo CNPJ é obrigatório!",
                maxLength: {
                  value: 18,
                  message: "Quantidade máxima de 18 caracteres!"
                },
                minLength: {
                  value: 11,
                  message: "Quantidade mínima de 11 caracteres!"
                },
                validate: (value) => cnpj.isValid(value) || "CNPJ inválido!"
              })}
              errors={errors}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Loader loading={carregandoCnpj}>
              <MaterialInputTexto
                type="text"
                id="nomeRazaoSocial"
                name="nomeRazaoSocial"
                label="Razão Social"
                disabled={!habilitarCampos}
                renderIconShowHide={false}
                permiteValorBranco
                defaultValue={razaoSocial}
                ref={register({
                  required: "Campo Razão Social é obrigatório!",
                  maxLength: {
                    value: 200,
                    message: "Quantidade máxima de 200 caracteres!"
                  },
                  validate: (value) =>
                    fieldEmpty(value) || "Campo Razão Social é obrigatório!"
                })}
                errors={errors}
              />
            </Loader>
          </Grid>
          <Grid
            item
            lg={3}
            md={3}
            sm={6}
            xs={12}
            className={classes.containerTelefone}
          >
            <Loader loading={carregandoCnpj}>
              <MaterialInputTelefone
                type="text"
                id="telefone"
                name="telefone"
                label="Telefone"
                disabled={!habilitarCampos}
                renderIconShowHide={false}
                defaultValue={valorTelefone}
                value={valorTelefone}
                ref={register({
                  required: "O campo Telefone é obrigatório!",
                  minLength: {
                    value: 18,
                    message: "Quantidade mínima de 12 caracteres!"
                  }
                })}
                errors={errors}
              />
            </Loader>
          </Grid>
        </Grid>
        <Grid container p={2} spacing={3} className={classes.container}>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Loader loading={carregandoCnpj}>
              <MaterialInputTexto
                type="text"
                id="nomeUnidade"
                name="nomeUnidade"
                label="Nome Fantasia"
                disabled={!habilitarCampos}
                permiteValorBranco
                renderIconShowHide={false}
                defaultValue={nomeFantasia}
                onBlur={(event) => onBlurNomeFantasia(event.target.value)}
                onInput={(event) => onBlurNomeFantasia(event.target.value)}
                ref={register({
                  required: "Campo Nome Fantasia é obrigatório!",
                  maxLength: {
                    value: 50,
                    message: "Quantidade máxima de 50 caracteres!"
                  },
                  validate: (value) =>
                    !fieldEmpty(value)
                      ? "Campo Nome Fantasia é obrigatório!"
                      : // eslint-disable-next-line quotes
                        (!value.includes("'") && !value.includes('"')) ||
                        "Não é permitido adicionar aspas no Nome Fantasia"
                })}
                errors={errors}
              />
            </Loader>
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Loader loading={carregandoCnpj}>
              <MaterialInputTexto
                type="text"
                id="inscricaoEstadual"
                name="inscricaoEstadual"
                label="Inscrição Estadual"
                disabled={!habilitarCampos}
                renderIconShowHide={false}
                permiteValorBranco
                defaultValue={inscricaoEstadual}
                ref={register({
                  maxLength: {
                    value: 30,
                    message: "Quantidade máxima de 30 caracteres!"
                  }
                })}
                maxLength={30}
                errors={errors}
              />
            </Loader>
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Loader loading={carregandoCnpj}>
              <MaterialInputTexto
                type="text"
                id="inscricaoMunicipal"
                name="inscricaoMunicipal"
                label="Inscrição Municipal"
                disabled={!habilitarCampos}
                permiteValorBranco
                renderIconShowHide={false}
                defaultValue={inscricaoMunicipal}
                ref={register({
                  maxLength: {
                    value: 30,
                    message: "Quantidade máxima de 30 caracteres!"
                  }
                })}
                maxLength={30}
                errors={errors}
              />
            </Loader>
          </Grid>
        </Grid>
        <Grid container p={2} spacing={3} className={classes.container}>
          <Grid item lg={3} md={3} sm={5} xs={12}>
            <Loader loading={carregandoStatus || carregandoCnpj}>
              <SelectArredondado
                id="Status"
                name="Status"
                valueKey="codigo"
                valueName="descricao"
                dataSource={listaStatus}
                label="Status"
                disabled={!habilitarCampos}
                value={statusId}
                onChange={(status) => onChangeStatus(status)}
                placeholder="Status"
                marginBottom="0"
                allowClear
                ref={register(
                  { name: "Status" },
                  {
                    required: !statusId ? "Campo Status é obrigatório!" : false
                  }
                )}
                errors={errors}
              />
            </Loader>
          </Grid>
          <Grid item lg={3} md={3} sm={5} xs={12}>
            <Loader loading={carregandoDistribuidora}>
              <SelectArredondado
                id="Distribuidora"
                name="Distribuidora"
                valueKey="id"
                marginBottom="0"
                valueName="nomeFantasia"
                dataSource={listaDistribuidora}
                label="Distribuidora"
                disabled={!habilitarCampos}
                value={distribuidoraId}
                onChange={(distribuidora) =>
                  onChangeDistribuidora(distribuidora)
                }
                placeholder="Distribuidora"
                allowClear
                ref={register(
                  { name: "Distribuidora" },
                  {
                    required: !distribuidoraId
                      ? "Campo Distribuidora é obrigatório!"
                      : false
                  }
                )}
                errors={errors}
              />
            </Loader>
          </Grid>
          <Grid item lg={3} md={3} sm={5} xs={12}>
            <Loader loading={carregandoStatus || carregandoCnpj}>
              <SelectArredondado
                id="tipoRepresentacaoId"
                name="tipoRepresentacaoId"
                valueKey="id"
                valueName="descricao"
                dataSource={tipoRepresentacaoLista}
                label="Tipo representação"
                disabled={!habilitarCampos}
                value={tipoRepresentacaoId}
                onChange={(valor) => onChangeTipoRepresentacao(valor)}
                placeholder="Tipo representação"
                marginBottom="0"
                allowClear
                ref={register(
                  { name: "tipoRepresentacaoId" },
                  {
                    required: !tipoRepresentacaoId
                      ? "Campo Tipo representação é obrigatório!"
                      : false
                  }
                )}
                errors={errors}
              />
            </Loader>
          </Grid>
        </Grid>
        <Grid container p={2} spacing={3} className={classes.containerAtivo}>
          {dadosUnidade?.id ? (
            <Grid item lg={3} md={3} sm={5} xs={12}>
              <MaterialSwitch
                label="Ativo"
                disabled={!habilitarCampos}
                labelPlacement="top"
                id="Situacao"
                name="Situacao"
                checked={situacao}
                onChange={(valor) => onChangeSituacao(valor)}
              />
            </Grid>
          ) : null}
        </Grid>
        <Divider className={classes.divider} />
        <Grid container p={2} spacing={3} className={classes.container}>
          <Grid item xs={12} className="font-weight-bold">
            Endereço
          </Grid>
          <Grid item lg={3} md={3} sm={5} xs={12}>
            <Loader loading={carregandoEndereco || carregandoCnpj}>
              <MaterialInputMascara
                type="text"
                id="cep"
                name="cep"
                label="CEP"
                mask="#####-###"
                disabled={!habilitarCampos}
                limparValor={limparCep}
                redefineLimpar={(estado) => setLimparCep(estado)}
                renderIconShowHide={false}
                defaultValue={valorCep?.replace("-", "")}
                maxLength={9}
                onBlur={(event) => onBlurCep(event.target.value)}
                ref={register({
                  required: "O campo CEP é obrigatório!",
                  minLength: {
                    value: 9,
                    message: "Quantidade minima de 9 caracteres!"
                  },
                  maxLength: {
                    value: 9,
                    message: "Quantidade máxima de 9 caracteres!"
                  },
                  validate: (value) =>
                    value.replace(/\s/g, "").length === 9 ||
                    "Deve ser informado 8 digitos"
                })}
                errors={errors}
              />
            </Loader>
          </Grid>
          <Grid item lg={7} md={7} sm={9} xs={12}>
            <Loader loading={carregandoEndereco || carregandoCnpj}>
              <MaterialInputTexto
                type="text"
                id="endereco"
                name="endereco"
                label="Endereço"
                disabled={!habilitarCampos}
                renderIconShowHide={false}
                permiteValorBranco
                defaultValue={dadosEndereco?.endereco}
                ref={register({
                  required: "Campo Endereço é obrigatório!",
                  maxLength: {
                    value: 200,
                    message: "Quantidade máxima de 200 caracteres!"
                  },
                  validate: (value) =>
                    fieldEmpty(value) || "Campo Endereço é obrigatório!"
                })}
                errors={errors}
              />
            </Loader>
          </Grid>
          <Grid item lg={2} md={4} sm={4} xs={12}>
            <Loader loading={carregandoEndereco || carregandoCnpj}>
              <MaterialInputTexto
                type="text"
                id="numero"
                name="numero"
                label="Número"
                disabled={!habilitarCampos}
                permiteValorBranco
                renderIconShowHide={false}
                defaultValue={numero}
                ref={register({
                  required: "Campo Número é obrigatório!",
                  maxLength: {
                    value: 10,
                    message: "Quantidade máxima de 10 caracteres!"
                  },
                  validate: (value) =>
                    fieldEmpty(value) || "Campo Número é obrigatório!"
                })}
                errors={errors}
              />
            </Loader>
          </Grid>
        </Grid>
        <Grid container p={2} spacing={3} className={classes.container}>
          <Grid item lg={3} md={3} sm={5} xs={12}>
            <Loader loading={carregandoEndereco || carregandoCnpj}>
              <MaterialInputTexto
                type="text"
                id="complemento"
                name="complemento"
                label="Complemento"
                disabled={!habilitarCampos}
                permiteValorBranco
                renderIconShowHide={false}
                defaultValue={complemento ?? ""}
                errors={errors}
                ref={register}
              />
            </Loader>
          </Grid>
          <Grid item lg={3} md={3} sm={5} xs={12}>
            <Loader loading={carregandoEndereco || carregandoCnpj}>
              <MaterialInputTexto
                type="text"
                id="bairro"
                name="bairro"
                label="Bairro"
                disabled={!habilitarCampos}
                permiteValorBranco
                renderIconShowHide={false}
                defaultValue={dadosEndereco?.bairro}
                ref={register({
                  required: "Campo Bairro é obrigatório!",
                  maxLength: {
                    value: 100,
                    message: "Quantidade máxima de 100 caracteres!"
                  },
                  validate: (value) =>
                    fieldEmpty(value) || "Campo Bairro é obrigatório!"
                })}
                errors={errors}
              />
            </Loader>
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <Loader loading={carregandoEndereco || carregandoCnpj}>
              <MaterialInputTexto
                type="text"
                id="cidade"
                name="cidade"
                label="Cidade"
                disabled={!habilitarCampos}
                permiteValorBranco
                renderIconShowHide={false}
                defaultValue={dadosEndereco?.cidade}
                ref={register({
                  required: "Campo Cidade é obrigatório!",
                  maxLength: {
                    value: 100,
                    message: "Quantidade máxima de 100 caracteres!"
                  },
                  validate: (value) =>
                    fieldEmpty(value) || "Campo Cidade é obrigatório!"
                })}
                errors={errors}
              />
            </Loader>
          </Grid>
          <Grid item lg={2} md={2} sm={4} xs={12}>
            <Loader loading={carregandoEndereco || carregandoCnpj}>
              <SelectArredondado
                id="uf"
                name="uf"
                valueKey="UF"
                marginBottom="0"
                valueName="UF"
                disabled={!habilitarCampos}
                dataSource={listaUF}
                label="UF"
                value={ufId}
                onChange={(estado) => onChangeUF(estado)}
                placeholder="UF"
                allowClear
                ref={register(
                  { name: "uf" },
                  {
                    required: !ufId ? "Campo UF é obrigatório!" : false
                  }
                )}
                errors={errors}
              />
            </Loader>
          </Grid>
        </Grid>
        <Grid container p={2} spacing={3} className={classes.container}>
          <Grid item xs={12} className="font-weight-bold">
            Cadastro complementar
          </Grid>
          <Grid item lg={3} md={3} sm={5} xs={12}>
            <InputData
              type="text"
              id="dataMigracao"
              name="dataMigracao"
              label="Data de Migração"
              disabled={!habilitarCampos}
              customValue={dataMigracao}
              onChange={(data) => onChangeDataMigracao(data)}
              renderIconShowHide={false}
              ref={register({
                validate: (value) =>
                  (moment(value, "DD/MM/YYYY").isValid() &&
                    moment(value, "DD/MM/YYYY") >
                      moment("01/01/1900", "DD/MM/YYYY")) ||
                  value === "" ||
                  "Data de Migração inválida!"
              })}
              errors={errors}
              className={classes}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={5} xs={12}>
            <MaterialInputMascara
              type="text"
              id="numeroInstalacao"
              name="numeroInstalacao"
              label="N° da instalação"
              disabled={!habilitarCampos}
              mask={null}
              renderIconShowHide={false}
              onBlur={(event) => setNumeroInstalacao(event.target.value ?? 0)}
              defaultValue={dadosUnidade?.numeroInstalacao}
              ref={register({
                maxLength: {
                  value: 50,
                  message: "Quantidade máxima de 50 caracteres!"
                }
              })}
              maxLength={30}
              errors={errors}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={5} xs={12}>
            <Loader loading={carregandoSubmercado || carregandoCnpj}>
              <SelectArredondado
                id="Submercado"
                name="Submercado"
                valueKey="id"
                valueName="descricao"
                marginBottom="0"
                disabled={!habilitarCampos}
                dataSource={listaSubmercado}
                label="Submercado"
                value={submercadoId}
                onChange={(submercado) => onChangeSubmercado(submercado)}
                placeholder="Submercado"
                allowClear
                errors={errors}
              />
            </Loader>
          </Grid>
          <Grid item lg={3} md={3} sm={5} xs={12}>
            <Loader loading={carregandoCnpj}>
              <MaterialInputTexto
                type="text"
                id="parcelaCarga"
                name="parcelaCarga"
                label="Parcela de Carga"
                permiteValorBranco
                renderIconShowHide={false}
                defaultValue={parcelaDeCarga}
                ref={register({
                  maxLength: {
                    value: 50,
                    message: "Quantidade máxima de 50 caracteres!"
                  }
                })}
                maxLength={50}
                errors={errors}
                disabled={parcelaDeCargaExistente || !habilitarCampos}
              />
            </Loader>
          </Grid>
          <Grid item lg={3} md={3} sm={5} xs={12}>
            <MaterialInputMascara
              type="text"
              id="icms"
              name="icms"
              label="ICMS"
              mask="##%"
              disabled={!habilitarCampos}
              renderIconShowHide={false}
              defaultValue={dadosUnidade?.icms}
              onBlur={(valor) => {
                setIcms(removerCaracteres(valor?.target?.value));
              }}
              maxLength={30}
              errors={errors}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={5} xs={12}>
            <SelectArredondado
              id="icmsNaNotaFiscal"
              name="icmsNaNotaFiscal"
              valueKey="id"
              marginBottom="0"
              valueName="nome"
              dataSource={listaIcmsInclusoNF}
              label="ICMS na NF de energia?"
              disabled={!habilitarCampos}
              value={icmsNaNotaFiscal}
              onChange={(valor) => onChangeIcmsNF(valor)}
              placeholder="ICMS na NF de energia?"
              allowClear
              errors={errors}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={5} xs={12}>
            <Loader loading={carregandoNivelTensao}>
              <SelectArredondado
                id="NivelTensao"
                name="NivelTensao"
                valueKey="id"
                marginBottom="0"
                valueName="descricao"
                dataSource={listaNivelTensao}
                label="Nível de tensão"
                disabled={!habilitarCampos}
                value={nivelTensaoId}
                onChange={(nivelTensao) => onChangeNivelTensao(nivelTensao)}
                placeholder="Nível de tensão"
                allowClear
                errors={errors}
              />
            </Loader>
          </Grid>
          <Grid item lg={3} md={3} sm={5} xs={12}>
            <SelectArredondado
              id="Modalidade"
              name="Modalidade"
              valueKey="id"
              valueName="nome"
              marginBottom="0"
              dataSource={listaModalidade}
              label="Modalidade"
              disabled={!habilitarCampos}
              value={modalidadeId}
              onChange={(modalidade) => onChangeModalidade(modalidade)}
              placeholder="Modalidade"
              allowClear
              errors={errors}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={5} xs={12}>
            <MaterialInputMascara
              type="text"
              id="demandaContratadaForaPonta"
              name="demandaContratadaForaPonta"
              label="Demanda C. fora ponta"
              disabled={!habilitarCampos}
              renderIconShowHide={false}
              defaultValue={dadosUnidade?.demandaContratadaForaPonta}
              ref={register({
                maxLength: {
                  value: 32,
                  message: "Quantidade máxima de 32 caracteres!"
                }
              })}
              maxLength={32}
              errors={errors}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={5} xs={12}>
            {tipoFormulario === 1 ? (
              <MaterialInputMascara
                type="text"
                id="demandaContratadaPonta"
                name="demandaContratadaPonta"
                label="Demanda C. ponta"
                disabled={!habilitarCampos}
                renderIconShowHide={false}
                defaultValue={dadosUnidade?.demandaContratadaPonta}
                ref={register({
                  maxLength: {
                    value: 32,
                    message: "Quantidade máxima de 32 caracteres!"
                  }
                })}
                maxLength={32}
                errors={errors}
              />
            ) : null}
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <MaterialInputTexto
              type="text"
              id="cnae"
              name="cnae"
              label="CNAE"
              disabled={!habilitarCampos}
              renderIconShowHide={false}
              defaultValue={dadosUnidade?.cnae ?? ""}
              ref={register({
                maxLength: {
                  value: 7,
                  message: "Quantidade máxima de 7 caracteres!"
                }
              })}
              errors={errors}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <SelectArredondado
              id="tipoUnidadeConsumidoraId"
              name="tipoUnidadeConsumidoraId"
              valueKey="id"
              valueName="descricao"
              dataSource={listaTipoUnidadeConsumidora}
              label="Tipo da UC"
              disabled={!habilitarCampos}
              value={tipoUc}
              onChange={(value) => onChangeTipoUc(value)}
              placeholder="Tipo da UC"
              allowClear
              errors={errors}
            />
          </Grid>
        </Grid>
        <Grid container p={2} spacing={3} className={classes.container}>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <Loader loading={carregandoUnidade}>
              <Botao
                type="submit"
                label="Salvar"
                disabled={!habilitarCampos}
                className={classes.button}
              />
            </Loader>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

MercadoLivreForm.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dadosUnidade: PropTypes.oneOfType([PropTypes.any]),
  produtoSelecionado: PropTypes.oneOfType([PropTypes.any]),
  empresaUnidade: PropTypes.oneOfType([PropTypes.any]),
  usuario: PropTypes.oneOfType([PropTypes.any]),
  rotas: PropTypes.oneOfType([PropTypes.any]),
  menu: PropTypes.oneOfType([PropTypes.any])
};

MercadoLivreForm.defaultProps = {
  id: "",
  dadosUnidade: {},
  produtoSelecionado: {},
  empresaUnidade: {},
  usuario: {},
  rotas: {},
  menu: {}
};

export default MercadoLivreForm;
