/* eslint-disable no-continue */
/* eslint-disable consistent-return */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-shadow */
/* eslint-disable prefer-template */
/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect, useCallback } from "react";
import { Grid, FormControl, InputLabel, Select, MenuItem, Tooltip, OutlinedInput,Typography } from "@mui/material";
import { Base } from "componentes/cores";
import { Botao, ModalConfirmacao } from "componentes";
import BotaoOutLine from "componentes/botaoOutline";
import { useForm } from "react-hook-form";
import Loader from "componentes/loader";
import MaterialInputTexto from "componentes/inputTexto/materialInput";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { useSelector } from "react-redux";
import SelectAutocompleteCheckbox from "componentes/selectAutocompleteCheckbox";
import { useHistory, useParams } from "react-router-dom";
import { ModalVerificacao } from "componentes";
import { useStyles } from "./style";
import { buscaDistribuidoraPorGrupo, buscaListaSubgrupos, buscaAnosPorGrupo } from "../../../servicos/grupoProjecoes";
import { getExcelMercadoGrupo } from "../../../servicos/exportacoes";
import RotasDTO from "../../../rotas/rotasUrlDto";
import CloseIcon from "@mui/icons-material/Close";
import BotaoRetornarListagem from "componentes/botaoRetornarListagem";
import moment from "moment";
import ModalInformacao from "componentes/modalVerificacao";


const MercadoGrupo = () => {
    const usuarioGlobal = useSelector((state) => state.usuario);
    const classes = useStyles();
    const {idgrupo, titulogrupo} = useParams();

    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);

    const [distribuidoras, setDistribuidoras] = useState([]);
    const [listaDistribuidorasEditada, setListaDistribuidorasEditada] = useState([]);
    const [distribuidorasSelecionadas, setDistribuidorasSelecionadas] = useState([]);
    const [anos, setAnos] = useState([]);
    const [anoSelecionado, setAnoSelecionado] = useState(2010);

    const [nomeArquivo, setNomeArquivo] = useState("MercadoGrupo.xlsx");
    const [showModalGerarExcel, setShowModalGerarExcel] = useState(false);
    const [modalInfo, setModalInfo] = useState(false);
    const [msgFalha, setMsgFalha] = useState();


    const handleAnos = useCallback( async () =>{

        const menorAno = 2010;
        const maiorAno = moment().year();
        let years = [];

        for(var i = menorAno; i <= maiorAno; i++)
        {
            years.push(i);
        }
        setAnos(years);
        setAnoSelecionado(menorAno);
    });
 
    const handleDistribuidoras = useCallback( async () => {
        try {
            setIsLoading(true);
            const lista = await buscaDistribuidoraPorGrupo(idgrupo);
            if (lista?.status === 200 && lista?.data) {
                setIsLoading(false);
                setDistribuidoras(lista?.data);
                setListaDistribuidorasEditada(lista?.data.map(listItem => { 
                    return {
                        id: listItem.idDistribuidora,
                        nome: listItem.titulo
                    }
                }));
            } else {
                setDistribuidoras([]);
                setIsLoading(false);
            }
        } catch (error) {
            console.info(error);
            setIsLoading(false);
        }
    })


     const onChangeDistribuidora = async (item) => {
        setDistribuidorasSelecionadas(item);
      }; 

      const OnChangeAnos = (event) => {
        setAnoSelecionado(event.target.value);
      } 


    useEffect(() => {
        if (!distribuidoras?.length) handleDistribuidoras();
    }, [distribuidoras?.length]);

    useEffect(() => {
        if(!anos.length) handleAnos();
    }, [anos.length])


    const handleExportExcel =  useCallback (async() => {

        setIsDownloading(true);

        const distribuidorasSemTodos = listaDistribuidorasEditada.filter( ( el ) => {
            return el.nome !== 'Todos';
          } );

        let paramdistribuidoras = [];
        let paramanos = [];


        // Prepara parâmetro Distribuidora
        if(distribuidorasSelecionadas.some(d => d.nome === "Todos"))
        {
            paramdistribuidoras = distribuidorasSemTodos.map((item) => {
                return item.id
            }).join();
        }
        else if(distribuidorasSelecionadas.length > 0)
        {
            paramdistribuidoras = distribuidorasSelecionadas.map((item) => {
                return item.id
            }).join();
        }
        else{
            setShowModalGerarExcel(false);
            store.dispatch(
                alertaExibir({
                  tipo: "warning",  
                  mensagem: "Escolha ao menos uma distribuidora para exportação."
                })
              );
              return;
        }

        // Prepara parâmetro Ano
        if(anoSelecionado !== undefined && anoSelecionado !== null && anoSelecionado !== "")
        {
            paramanos = anoSelecionado
        }
        else
        {
            setShowModalGerarExcel(false);
            store.dispatch(
                alertaExibir({
                  tipo: "warning",  
                  mensagem: "Escolha um ano inicial de histórico para exportação."
                })
              );
              return;

        }


        /*
            Se parâmetro distribuidoras foi carregado com sucesso
        */
 
        if(distribuidoras.length > 0)
        {
            try
            {
                const response = await getExcelMercadoGrupo(idgrupo,paramdistribuidoras,
                    paramanos,nomeArquivo);

                setDistribuidorasSelecionadas([]);
                handleAnos();
                setShowModalGerarExcel(false);

                if(response !== "Sucesso")
                {
                    setMsgFalha(`${response}`);
                    setModalInfo(true);
                }
                else
                {
                    store.dispatch(
                        alertaExibir({
                            tipo: "success",  
                            mensagem: "Exportação concluída com sucesso."
                        })
                    );
                }


            }
            catch(error)
            {
                setDistribuidorasSelecionadas([]);
                handleAnos();
                setIsDownloading(false);
                setShowModalGerarExcel(false);
                setMsgFalha(`Erro não previsto: ${error}`);
                setModalInfo(true);
            }
        }
        setIsDownloading(false);
    });

    
    return <>
        <ModalInformacao
            titulo={"Exportação não realizada com a seguinte falha:"}
            exibir={modalInfo}
            mensagem={msgFalha}
            onClose={() => {
            setModalInfo(false);
            }}
            showBotao={false}
        />        
        <Loader loading={isLoading} className="w-auto">
            <ModalConfirmacao
            item={showModalGerarExcel}
            onCancelar={() => setShowModalGerarExcel(false)}
            onConfirmar={() => handleExportExcel()}
            className = {classes.container}
            mensagem={
                <pre>
                    <Loader loading={isDownloading} className="w-auto">
                    <strong><p>Gerar planilha excel com Mercado e fazer download?</p></strong>
                    </Loader>            
                    <FormControl variant="outlined" >
                        <InputLabel htmlFor="outlined-nome-arquivo-excel">Nome do Arquivo</InputLabel>
                        <Tooltip 
                            placement="top"
                            title={
                                <h6>Nome sugerido. Aceite ou edite antes de confirmar.</h6>
                            }
                        >
                            <OutlinedInput
                                id="outlined-nome-arquivo-excel"
                                type="text"
                                value={nomeArquivo}
                                onChange={(e) => setNomeArquivo(e.target.value)}
                                label="Nome do Arquivo a Gerar"
                            />               
                        </Tooltip>                 
                    </FormControl>
                </pre>
            }
            />          
            <Grid container item spacing={0} alignItems="center">
                <Typography variant = "h4" style={{color: Base.OffWhite, fontSize: 20}}>
                    Exportação de Mercado do Grupo de Projeções</Typography>
                <Grid item className={classes.backButton}>
                    <BotaoRetornarListagem voltarPagina />
                </Grid>
            </Grid>
            <Grid container spacing={7} alignItems="center">
                <Grid item>
                    <Typography variant = "h6" style={{color: Base.OffWhite, fontSize: 20}}>
                        Grupo: {titulogrupo}</Typography>
                </Grid>
            </Grid>

            <Grid container style={{ paddingLeft: "1.5em", paddingRight: "1.5em", paddingTop: "25px" }}>
                <Grid
                    container
                    spacing={3}
                    alignItems="center"
                    className={classes.container}
                    justifyContent="space-between"
                >   
                    <Grid item xs={5}>
                        <SelectAutocompleteCheckbox 
                            id="selectDistribuidoraId"
                            name="distribuidora"
                            options={listaDistribuidorasEditada}
                            label="Distribuidora"
                            style={classes}
                            getOptionLabel={(option) => option.nome || ""}
                            onChange={(e, item) => {
                                onChangeDistribuidora(item);
                            }}
                            value={distribuidorasSelecionadas}
                            allowClear
                        />
                    </Grid>
                    <Grid item xs={4}>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="anoinicialdohistorico">Ano Inicial do Histórico</InputLabel>
                            <Select
                                variant="outlined"
                                labelId="anoinicialdohistorico"
                                id="anoinicialdohistorico"
                                value={anoSelecionado}
                                onChange={OnChangeAnos}
                                label="Ano Inicial do Histórico"
                                fullWidth>
                                {anos.map((ano) => (
                                    <MenuItem key={ano} value={ano}>{ano}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container alignItems="left" style={{ paddingTop: "25px" }}>
                    <Grid xs={2}>
                        <Botao
                            type="button"
                            label="Gerar Excel"
                            color={Base.Mango}
                            className={classes.button}
                            onClick={()=>{setShowModalGerarExcel(true)}}
                            disabled = {distribuidorasSelecionadas.length === 0}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Loader>
    </>;
}


export default MercadoGrupo;
