import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const api = () => obterApi(MICROSERVICO.NOTIFICACAO);

// Lista tipo de relatório permitido pra o cliente parametrizar notificações
export const ListaTipoRelatorioPorCliente = async (params) => {
  return api().get(`v1/relatorio/ListaTipoRelatorioPorCliente?${new URLSearchParams(params).toString()}`);
};

// Parametriza qual tipo de relatório quer receber notificação
export const AlterarParametrizacaoNotificacao = async (dados) => {
  return api().patch("v1/relatorio/ParametrizaTipoRelatorio", dados);
};

export const ObterQuantidadeNotificacoes = async () => {
  return api().get("v1/notificacao/ObterQuantidadeNotificacoes");
};

export const ListaNotificacoes = async () => {
  return api().get("v1/notificacao/ListaNotificacoes");
};

export const AtualizaNotificacoesVisualizadas = async () => {
  return api().post("v1/notificacao/AtualizaNotificacoesVisualizadas");
};
