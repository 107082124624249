export default class ListagemCotacaoDto {
  constructor(
    id,
    nome,
    cpfCnpj,
    numeroInstalacao,
    dataCotacao,
    status,
    dataValidade,
    planoSelecionado,
    ativo,
    statusCotacao,
    envelopeId,
    desabledBtnContrato,
    desabledBtnDownload,
    distribuidoraId,
    usuarioCargoId,
    liberaPropostaContrato,
    cargoId,
    aprovaContratoMaster,
    aprovaContratoComercial,
    liberaPropostaContratoMaster
  ) {
    this.id = id;
    this.nome = nome;
    this.cpfCnpj = cpfCnpj;
    this.numeroInstalacao = numeroInstalacao;
    this.dataCotacao = dataCotacao;
    this.status = status;
    this.dataValidade = dataValidade;
    this.planoSelecionado = planoSelecionado;
    this.ativo = ativo;
    this.statusCotacao = statusCotacao;
    this.envelopeId = envelopeId;
    this.desabledBtnContrato = desabledBtnContrato;
    this.desabledBtnDownload = desabledBtnDownload;
    this.distribuidoraId = distribuidoraId;
    this.usuarioCargoId = usuarioCargoId;
    this.liberaPropostaContrato = liberaPropostaContrato;
    this.cargoId = cargoId;
    this.aprovaContratoMaster = aprovaContratoMaster;
    this.aprovaContratoComercial = aprovaContratoComercial;
    this.liberaPropostaContratoMaster = liberaPropostaContratoMaster;
  }
}
