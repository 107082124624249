/* eslint-disable import/no-unresolved */
import MICROSERVICO from "global/enumeradores/microServico";
import obterApi from "servicos/api";

const api = () => obterApi(MICROSERVICO.PROJECAOTARIFARIA);

export const uploadBasesItaipu = async (arquivo, anoReferencia, idUsuario, nomeUsuario) => {
  const data = new FormData();
  data.append("file", arquivo.file);

  const metodo = "post";
  const url = `v1/ImportBasesItaipu/importa?AnoReferencia=${anoReferencia}&idUsuario=${idUsuario}&nomeUsuario=${nomeUsuario}`;
  return api()[metodo](url, data);
}

export const getItaipuEnergia = async (idDistribuidora) => {
  return api().get(`v1/ImportBasesItaipu/visualizaritaipuenergia?IdDistribuidora=${idDistribuidora}`)
}

export const getItaipuPotencia = async (idDistribuidora, anoInicio, anoFim) => {
  return api().get(`v1/ImportBasesItaipu/visualizaritaipupotencia?IdDistribuidora=${idDistribuidora}&AnoInicial=${anoInicio}&AnoFinal=${anoFim}`)
}

export const getDistribuidoras = async () => {
  const metodo = "get";
  const url = "v1/ParametroDistribuidora/getalljoin?StatusProjecaoTarifaria=true"
  return api()[metodo](url);
}
