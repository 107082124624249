import { saveAs } from "file-saver";
import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";


const api = () => obterApi(MICROSERVICO.MANAGEMENT);


export const obterClienteContratoPorUnidadeId = async (unidadeId) => {
    return api().get(`v1/ClienteContrato?unidadeId=${unidadeId}`);
};


export const downloadClienteContrato = async (unidadeId, nomeGuid) => {
   const { data } = await api().get(`v1/ClienteContrato/download/${unidadeId}?nomeGuid=${nomeGuid}`,
        {
            responseType: "blob",
        }
    );

    const blob = new Blob([data]);

    saveAs(blob, `${nomeGuid}.pdf`);
};
  