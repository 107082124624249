const MICROSERVICO = {
  MEASUREMENT: "MEASUREMENT",
  PROFILE: "PROFILE",
  MANAGEMENT: "MANAGEMENT",
  INTEGRATION: "INTEGRATION",

  AREALOGADA: "AREALOGADA",
  PROJECAOTARIFARIA: "PROJECAOTARIFARIA",
  FATURAMENTO: "FATURAMENTO",
  CALCULOCUSTOS: "CALCULOCUSTOS",
  PROCESSAMENTO: "PROCESSAMENTO",
  NOTIFICACAO: "NOTIFICACAO",
  SERASA: "SERASA",
  TELEMETRIA: "TELEMETRIA",
  DGINTEGRACAO: "DGINTEGRACAO",
  DELTAPROCESSAMENTO: "DELTAPROCESSAMENTO",
  SITE_LUZ_LEAD: "SITELUZ",
  MAQUINACALCULO: "MAQUINACALCULO",
  DELTASDK: "DELTASDK",
  MEMBERGETMEMBER: "MEMBERGETMEMBER"
};

export default MICROSERVICO;
