export default class AgenteItemDto {
  constructor(id, descricao, codigoAgente) {
    this.id = id;
    this.descricao = descricao;
    this.codigoAgente = codigoAgente;
  }

  static mapearParaDto(dados) {
    return dados.map(
      (agente) =>
        new AgenteItemDto(
          agente.siglaAgenteCCEE,
          agente.siglaAgenteCCEE,
          agente.id
        )
    );
  }

  static obterMock() {
    return [
      new AgenteItemDto("SIGLA1", "AGENTE1", "1"),
      new AgenteItemDto("SIGLA2", "AGENTE2", "2"),
      new AgenteItemDto("SIGLA3", "AGENTE3", "3"),
      new AgenteItemDto("SIGLA4", "AGENTE4", "4")
    ];
  }
}
