import { makeStyles } from "@mui/styles";
import styled from "styled-components";
import theme from "themes";
import { Base } from "../../../componentes/cores";

export const useStyles = makeStyles(() => ({
  select: {
    paddingRight: "10px !important",
    paddingBottom: "5px !important",
    "& .MuiSelect-root": {
      paddingRight: "0px",
      paddingLeft: "8px",
      minWidth: "38px"
    },
    "& .MuiSelect-iconOutlined": {
      right: "2px"
    },
    "& fieldset": {
      color: `${theme.color.selectArredondadoColor} !important`,
      borderColor: `${theme.color.selectArredondadoColor} !important`
    },
    "& .MuiOutlinedInput-input": {
      paddingRight: "5px !important"
    }
  },
  label: {
    color: `${theme.color.selectArredondadoColor} !important`,
    transform: "translate(8px, 20px) scale(1)"
  },
  input: {
    "& .MuiInputLabel-outlined": {
      color: `${theme.color.selectArredondadoColor} !important`,
      transform: "translate(8px, 20px) scale(1)",
      fontSize: "1rem"
    },
    "& .MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75) !important"
    },
    "& .MuiOutlinedInput-input": {
      color: `${theme.color.selectArredondadoColor} !important`,
      fontSize: "1rem"
    },
    paddingRight: "10px !important",
    paddingBottom: "5px !important",
    "& label": { color: `${theme.color.selectArredondadoColor} !important` },
    "& fieldset": {
      borderColor: `${theme.color.selectArredondadoColor} !important`
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      color: theme.color.selectArredondadoColor,
      borderColor: `${theme.color.selectArredondadoColor}`
    },
    "& .Mui-error": {
      color: "#F44336",
      fontSize: "1rem"
    }
  },
  container: {
    width: "100%",
    margin: 0,
    paddingBottom: "15px",
    paddingRight: "10px",
    "& fieldset": {
      borderColor: `${theme.color.selectArredondadoColor} !important`
    },
    "& label, & button, & input": {
      color: `${theme.color.selectArredondadoColor} !important`
    }
  },
  botaoGrid: {
    paddingRight: "10px",
    paddingBottom: "20px"
  },
  info: {
    marginRight: "5px",
    color: theme.color.selectArredondadoColor
  }
}));

export const BotaoFiltro = styled.button`
  background-color: ${theme.color.primary};
  border-color: ${theme.color.primary};
  color: ${Base.White} !important;
  outline: none;
  width: 100%;
  min-height: 30px;
`;
