import { makeStyles } from "@mui/styles";
import { Base } from "componentes/cores";

export const useStyles = makeStyles(() => ({
  titulo: {
    fontWeight: "bold"
  },
  container: {
    "& .MuiInputBase-root": {
      color: `${Base.OffWhite} !important`
    },
    "& .MuiInputBase-input": {
      maxWidth: "90px"
    },
    "& .MuiButtonBase-root": {
      color: `${Base.OffWhite} !important`
    }
  },
  itemGrafico: {
    "& .apexcharts-tooltip.apexcharts-theme-light": {
      border: "0 none",
      background: "none",
      width: "fit-content",
      height: "fit-content",
      textAlign: "center",
      fontSize: "13px"
    }
  },
  noData: {
    textAlign: "center",
    width: "100%",
    minHeight: "200px",
    "& div": {
      margin: "125px auto 0",
      maxWidth: "80%"
    }
  },
  arrowBox: {
    position: "relative",
    background: "#647480",
    padding: "8px",
    borderColor: "#647480"
  },
  arrowBoxTitle: {
    color: "#a6b9c8",
    fontSize: "10px"
  },
  formControl: {
    width: "100%",
    "& .MuiInputBase-root": {
      height: "56px",
      color: `${Base.White} !important`,
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#FFF !important"
      }
    },
    "& .MuiSelect-icon": {
      color: `${Base.White} !important`
    },

    "& option": {
      color: Base.BlackBastille
    },
    "& .MuiSelect-select.MuiSelect-select": {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      color: "#eef0f9"
    },
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      padding: "0 40px 0 20px "
    },
    "& .MuiFormLabel-root": {
      color: `${Base.OffWhite} !important`
    },
    "& .Mui-disabled": {
      "-webkit-text-fill-color": `${Base.White} !important`,
    }
  },
  formControlWhiteBackgrounc: {
    width: "100%",
    "& .MuiInputBase-root": {
      height: "56px",
      color: Base.BlackBastille,
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#000"
      }
    },
    "& .MuiSelect-icon": {
      color: Base.BlackBastille
    },

    "& option": {
      color: Base.White
    },
    "& .MuiSelect-select.MuiSelect-select": {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      color: "#eef0f9"
    },
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      padding: "0 40px 0 20px "
    },
    "& .MuiFormLabel-root": {
      color: Base.BlackBastille
    }
  },
  formControlSimple: {
    width: "100%",
    minWidth: "100%",
    maxWidth: "100%"
  },
  backButton: {
    "& .MuiGrid-root .MuiSvgIcon-root": {
      color: `${Base.OffWhite} !important`,
      width: 50,
      height: 50
    }
  },
  button: {
    fontSize: "18px"
  },

  paddingLeft: {
    paddingLeft: 20
  },

  saveDisabled: {
    color: "white",
    opacity: "0.6",
    pointerEvents: "none"
  },

  saveEnabled: {
    color: "white",
    opacity: "1",
    pointerEvents: "auto"
  }
}));

export const customStyles = {
  table: {
    style: {
      background: "transparent"
    }
  },
  header: {
    style: {
      color: `${Base.OffWhite} !important`,
      background: "transparent"
    }
  },
  subHeader: {
    style: {
      background: "transparent"
    }
  },
  headRow: {
    style: {
      background: "transparent"
    }
  },
  headCells: {
    style: {
      background: "#2a2b32",
      marginLeft: "3px",
      fontSize: "16px",
      "& div": {
        color: `${Base.OffWhite} !important`,
        "&:hover": {
          color: `${Base.OffWhite} !important`
        }
      }
    }
  },
  rows: {
    style: {
      background: "transparent",
      marginBottom: "1px",
      marginTop: "1px",
      color: Base.White
    }
  },
  Pagination: {
    styles: {
      color: "#eef0f9"
    }
  },
  cells: {
    style: {
      marginLeft: "3px",
      background: "#32333b",
      fontSize: "16px",
      color: Base.default
    }
  }
};
