import { createTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import theme from "themes";
import { Base } from "../cores";

export const useStyles = makeStyles(() => ({
  root: {
    "& label.Mui-focused": {
      color: `${Base.GreyDim}`
    }
  },
  container: {
    "& .MuiFormControl-marginNormal": {
      margin: "0"
    }
  }
}));

export const materialTheme = createTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: theme.color.primary
      }
    },
    datePicker: {
      selectColor: theme.color.primary
    }
  }
});
