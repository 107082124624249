import { makeStyles } from '@mui/styles';
import { Base } from "componentes/cores";
import { createTheme } from "@mui/material/styles";

const theme = createTheme();

export const useStyles = makeStyles(() => ({
  container: {
    margin: 0,
    width: "100%",
    "& .MuiGrid-root": {
      color: `${Base.BlackBastille} !important`
    },
    "& .MuiDropzoneArea-text": {
      color: `${Base.BlackBastille} !important`
    },
    "& .MuiInputBase-root": {
      height: "46px"
    },
    "& .MuiFormLabel-root": {
      lineHeight: "1"
    }
  },
  containerTitulo: {
    fontSize: '18px',
    padding: '18px !important'
  },
  containerData: {
    padding: '32px !important'
  },  
  iconcustom: {
    cursor: "pointer",
    float:"right",
    margin: "5px"
  },  
  button: {
    fontSize: "18px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100% !important",
    maxWidth: "100% !important",
  },
  customMargin: {
    margin: theme.spacing(1),
  },
  spartainfo: {
    margin: 0,
    padding:"0px 0px 15px 25px"
  },
}));
