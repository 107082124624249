/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { store } from "global/redux";
import { useTheme } from "@mui/material/styles";
import { AppBar, Tabs, Tab, Card, Grid } from "@mui/material";
import SwipeableViews from "react-swipeable-views";
import TabPanel from "componentes/tabPanel";
import InputDataBranco from "componentes/inputTextoDataBranco";
import { useStyles, Tabela } from "./style";
import { obterValoresOrcamento } from "../../../../servicos/cdeServico";
import CloseIcon from "@mui/icons-material/Close";
import RotasDTO  from "../../../../rotas/rotasUrlDto";  
import { useHistory } from "react-router-dom";

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const CDEOrcamentoVisualizar = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();


  const [value, setValue] = React.useState(0);
  const [listaDespesas, setListaDespesas] = useState([]);
  const [listaReceitas, setListaReceitas] = useState([]);
  const [totalDespesas, setTotalDespesas] = useState([]);
  const [totalReceitas, setTotalReceitas] = useState([]);
  const [anoInicial, setAnoInicial] = useState();
  const [cabecalhoDespesas, setCabecalhoDespesas] = useState([]);
  const [cabecalhoReceitas, setCabecalhoReceitas] = useState([]);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
 
  const onChangeAnoInicial = (data) => {
    setAnoInicial(data);
  };


  const obterValores = useCallback(async () => {
    try {

      if(anoInicial === undefined || anoInicial == null) return;

      const response = await obterValoresOrcamento(moment(anoInicial).format("YYYY"), moment(anoInicial).format("YYYY"));
      if (response?.status === 200 && response?.data) {
        const { data } = response;
        const despesas = [];
        const receitas = [];
        let totalDespesasSum = 0;
        let totalReceitasSum = 0;

        data.map((item) => item.Movimento === "DESPESA" ?
          despesas.push(item) :
          receitas.push(item));

        despesas.forEach(item => {
          item.Valores.forEach(element => {
            totalDespesasSum += element.Valor
          })
        });

        receitas.forEach(item => {
          item.Valores.forEach(element => {
            totalReceitasSum += element.Valor
          })
        });

        setTotalDespesas(totalDespesasSum);
        setTotalReceitas(totalReceitasSum);

        setListaDespesas(despesas);
        setListaReceitas(receitas);

      }
      else
      {

        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem:
              `Não há valores para o ano ${moment(anoInicial).format('yyyy')}.`
          })
        );

        setTotalDespesas([]);
        setTotalReceitas([]);

        setListaDespesas([]);
        setListaReceitas([]);
 
        setCabecalhoDespesas([]);
        setCabecalhoReceitas([]);
        setAnoInicial(null);
          
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem:
            `Erro não previsto: ${error.message}`
        })
      );

    }
  }, [anoInicial]);


  const obterCabecalhoTabela = useCallback(() => {
    let despesas = [];
    let receitas = [];

    listaDespesas.map((item) => {
      const data = item?.Valores;
      despesas = data?.filter((este, i) => data.indexOf(este) === i);
      return despesas;
    });

    listaReceitas.map((item) => {
      const data = item?.Valores;
      receitas = data?.filter((este, i) => data.indexOf(este) === i);
      return receitas;
    });

    setCabecalhoDespesas(despesas);
    setCabecalhoReceitas(receitas);

  }, [listaDespesas, listaReceitas]);

  useEffect(() => { obterValores() }, [obterValores]);

  useEffect(() => { if (listaDespesas && listaReceitas) obterCabecalhoTabela() },
    [listaDespesas, listaReceitas])

    const onFechar = (id) => {
      history.push(`${RotasDTO.ProjecaoInsumoListar}`);
    }

  return <>
   <Grid container className={classes.containerTitulo}>
        <Grid item xs={11} className={classes.titulo}>
          Orçamento CDE
        </Grid>
        <Grid item xs={1} >             
          <CloseIcon onClick={onFechar} className={classes.icon}/>
        </Grid>   
      </Grid>
    <Grid container spacing={4} className={classes.container} justifyContent="flex-end" alignItems="center">
      <Grid item sm={3} className={classes.customSelect}>
        <InputDataBranco
          type="text"
          id="teste"
          name=""
          format="yyyy"
          customValue={anoInicial}
          onChange={(data) => onChangeAnoInicial(data)}
          views={["year"]}
          minDate= {new Date(`Thu Oct 01 ${new Date().getFullYear() - 10} GMT-0300`)}
          maxDate= {new Date(`Thu Oct 01 ${new Date().getFullYear() + 20} GMT-0300`)}
          />
      </Grid>
    </Grid>
    {listaDespesas.length ?
    <Card className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{ style: { background: "#DCDCDC" } }}
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Despesas" {...a11yProps(0)} />
          <Tab label="Receitas" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <Grid container spacing={4} className={classes.container}>
            <Grid item sm={12}>
              <Tabela>
                <Tabela.Thead>
                  <Tabela.Tr>
                    <Tabela.Th>Despesas (Em R$)</Tabela.Th>
                    {cabecalhoDespesas.map((item) => (
                      <Tabela.Th key={Math.random()}>{item.Ano}</Tabela.Th>
                    ))}
                  </Tabela.Tr>
                </Tabela.Thead>
                <Tabela.Tbody> 

                  {listaDespesas.map((item) => (
                    <Tabela.Tr key={Math.random()}>
                      <Tabela.Td key={Math.random()}>{item.Rubrica}</Tabela.Td>
                      {item?.Valores ?
                        item?.Valores?.map((index) => (
                          <Tabela.Td key={Math.random()}>{index.Valor.toLocaleString("pt-BR", { currency: "BRL", minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Tabela.Td>
                        ))
                        :
                        <Tabela.Td>0</Tabela.Td>
                      }
                    </Tabela.Tr>
                  ))}

                </Tabela.Tbody>
                <Tabela.Tfoot>
                  <Tabela.Tr>
                    <Tabela.Td>Total</Tabela.Td>
                    <Tabela.Td>{totalDespesas.toLocaleString("pt-BR", { currency: "BRL", minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Tabela.Td>
                  </Tabela.Tr>
                </Tabela.Tfoot>
              </Tabela>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <Grid container spacing={4} className={classes.container}>
            <Grid item sm={12}>
              <Tabela>
                <Tabela.Thead>
                  <Tabela.Tr>
                    <Tabela.Th>Receitas (Em R$)</Tabela.Th>
                    {cabecalhoReceitas.map((item) => (
                      <Tabela.Th key={Math.random()}>{item.Ano}</Tabela.Th>
                    ))}
                  </Tabela.Tr>
                </Tabela.Thead>
                <Tabela.Tbody>
                  {listaReceitas.map((item) => (
                    <Tabela.Tr key={Math.random()}>
                      <Tabela.Td>{item.Rubrica}</Tabela.Td>
                      {item?.Valores ?
                        item?.Valores?.map((index) => (
                          <Tabela.Td key={Math.random()}>{index.Valor.toLocaleString("pt-BR", { currency: "BRL", minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Tabela.Td>
                        ))
                        :
                        <Tabela.Td>0</Tabela.Td>
                      }
                    </Tabela.Tr>
                  ))}
                </Tabela.Tbody>
                <Tabela.Tfoot>
                  <Tabela.Tr>
                    <Tabela.Td>Total</Tabela.Td>
                    <Tabela.Td>{totalReceitas.toLocaleString("pt-BR", { currency: "BRL", minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Tabela.Td>
                  </Tabela.Tr>
                </Tabela.Tfoot>
              </Tabela>
            </Grid>
          </Grid>
        </TabPanel>
      </SwipeableViews>
    </Card>
    : "" }
  </>;
}

export default CDEOrcamentoVisualizar;