import RetornoEndpointDto from "../../../global/dto/retornoEndpointDto";
import { store } from "../../../global/redux";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";
import { ListarAgentes,ListarUnidades } from "../../../servicos/agenteServico";
import { listarSubmercados } from "../../../servicos/submercadoServico";
import { listarClassesPerfil } from "../../../servicos/classePerfilServico";
import AgenteItemDto from "./dto/agenteItemDto";
import UnidadeConsumidoraDto from "./dto/UnidadeConsumidoraDto";
import ClassePerfilDto from "./dto/classePerfilDto";
import PossuiVarejistaDto from "./dto/possuiVarejistaDto";
import SubmercadoDto from "./dto/submercadoDto";
import {
  obterPorSigla,
  salvarPerfilAgente,
  obterPorId
} from "../../../servicos/perfilAgenteService";

export default class PerfilAgenteHelper {
  static async listarSubmercadosHelper() {
    try {
      const retorno = await listarSubmercados();

      if (retorno.status === "204")
        return RetornoEndpointDto.Erro(
          "Ocorreu um erro na busca dos Submercados"
        );

      const listaDto = SubmercadoDto.converterParaDto(retorno.data);

      return RetornoEndpointDto.Sucesso(
        "Submercados encontrados com sucesso",
        listaDto
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu um erro na busca dos Submercados"
      );
    }
  }

  static async salvarPerfilAgenteCCEE(perfilAgenteDto) {
    try {
      await salvarPerfilAgente(perfilAgenteDto);

      return RetornoEndpointDto.Sucesso("Perfil de agente salvo com sucesso");
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu um erro ao salvar o perfil de agente CCEE"
      );
    }
  }

  static async listarAgentes(searchString) {
    try {
      const agentes = await ListarAgentes(searchString);

      if (agentes.status === 204)
        return RetornoEndpointDto.Erro("Ocorreu um erro na busca dos Agentes");

      return RetornoEndpointDto.Sucesso(
        "Agentes obtidos com sucesso",
        AgenteItemDto.mapearParaDto(agentes.data)
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Ocorreu um erro na busca dos Agentes");
    }
  }

  static async listarUnidades(searchString) {
    try {
      const unidades = await ListarUnidades(searchString);

      if (unidades.status === 204)
        return RetornoEndpointDto.Erro("Ocorreu um erro na busca dos Unidades");

      return RetornoEndpointDto.Sucesso(
        "Unidades obtidos com sucesso",
        UnidadeConsumidoraDto.mapearParaDto(unidades.data)
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Ocorreu um erro na busca dos Unidades");
    }
  }

  static async listarClassesPerfil() {
    try {
      const resultado = await listarClassesPerfil();

      if (resultado.status === 204)
        return RetornoEndpointDto.Erro(
          "Ocorreu um erro na busca das Classes de perfil"
        );

      const { data } = resultado;

      return RetornoEndpointDto.Sucesso(
        "Classes perfil obtidas com sucesso",
        ClassePerfilDto.mapearParaDto(data)
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu um erro na busca das Classes de perfil"
      );
    }
  }

  static listarPossuiComercioVarejista() {
    return PossuiVarejistaDto.obtemDataSource();
  }

  static async verificarSeSiglaCadastrada(sigla) {
    try {
      const perfilAgente = await obterPorSigla(sigla);

      if (perfilAgente.status === "204") return false;

      return !!perfilAgente.data;
    } catch (error) {
      return false;
    }
  }

  static async obterPorId(id) {
    try {

      if (!id)
        return RetornoEndpointDto.Erro(
          "É necessário informar uma perfil para a persistencia"
        );

        const { data } = await obterPorId(id);

      return RetornoEndpointDto.Sucesso(
        "Dados obtido com sucesso",
        data
      );

    } catch (error) {
      return RetornoEndpointDto.Erro(
        error?.response?.data?.message ?? "Ocorreu uma falha ao obter perfil de agente"
      );
    }
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }

  static exibirSucesso(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem
      })
    );
  }
}
