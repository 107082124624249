import React from "react";
import PropTypes from "prop-types";
import { generate } from "shortid";
import { Grid, Button } from "@mui/material";
import { useStyles } from "./style";
import enumeradorBotao from "../enumeradores/enumeradorBotao";
import { HtmlTooltip } from "paginas/Graficos/ConsumoAtual/style";
import ShuffleIcon from '@mui/icons-material/Shuffle';
import PageviewIcon from '@mui/icons-material/Pageview';
import TimelineIcon from '@mui/icons-material/Timeline';
import TableChartIcon from "@mui/icons-material/TableChart";
import { Base } from "componentes/cores";

const ColunaMultiplosBotoes = ({ onClick, parametros }) => {
  const classes = useStyles();

  const onClickProjetar = () => onClick(enumeradorBotao.Projetar, parametros);
  const onClickHistorico = () => onClick(enumeradorBotao.Historico, parametros);
  const onClickVisualizar = () => onClick(enumeradorBotao.Visualizar, parametros);
  const onClickExportar = () =>{console.dir(parametros);onClick(enumeradorBotao.Exportar, parametros)};

  return (
    <Grid container spacing={0} className={classes.fundoCelula}>

      <Grid item xs={3} >
        <Button
          key={generate()}
          onClick={onClickProjetar}
          className={`text-white ${classes.botao}`}
        >
          <HtmlTooltip title="Projetar">
            <ShuffleIcon />
          </HtmlTooltip>
        </Button>
      </Grid>
      <Grid item xs={3} className={classes.botaoCentral}>
        <Button key={generate()} onClick={onClickHistorico} className={`text-white ${classes.botao}`}>
          <HtmlTooltip title="Histórico">
            <div>
              <TimelineIcon />
            </div>
          </HtmlTooltip>
        </Button>
      </Grid>
      <Grid item xs={3} className={classes.botaoCentral}>
        <Button key={generate()} onClick={onClickVisualizar} className={`text-white ${classes.botao}`}>
          <HtmlTooltip title="Visualizar">
            <div>
              <PageviewIcon />
            </div>
          </HtmlTooltip>
        </Button>
      </Grid>
      <Grid item xs={3} className={classes.botaoCentral}>
        <Button key={generate()} onClick={onClickExportar} className={`text-white ${classes.botao}`} 
        disabled={parametros.comandoExportar == null}>
          <HtmlTooltip title="Exportar">
            {parametros.comandoExportar == null ?
            <div style = {{color: Base.Grey}}>
              <TableChartIcon />
            </div>
            :
            <div style = {{color: `${Base.OffWhite} !important`}}>
            <TableChartIcon />
          </div>
            }
          </HtmlTooltip>
        </Button>
      </Grid>

    </Grid>
  );
};

ColunaMultiplosBotoes.propTypes = {
  onClick: PropTypes.oneOfType([PropTypes.func]).isRequired,
  parametros: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired
};

export default ColunaMultiplosBotoes;