import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// import { Link } from "react-router-dom";
import moment from "moment";

// Componentes
import { Grid } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import formatarValor from "../../../../global/utils/formatarValor";
import SemDados from "../SemDados";

import { store } from "../../../../global/redux";
import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";
import { buscarTodasCategorias } from "../../../../servicos/categoriasServico";

import Loader from "../../../../componentes/loader";

// Styles
import { useStyles } from "./style";

const Categorias = ({ unidadeConsumidora }) => {
  const classes = useStyles();

  const [dadosCategorias, setDadosCategorias] = useState({});
  const [carregando, setCarregando] = useState(false);

  const seriesDados =
    dadosCategorias?.grupos?.map((item) => item.consumo) || [];
  const labelsDados =
    dadosCategorias?.grupos?.map((item) => item.descricao) || [];
  const cores = [
    {
      id: 1,
      cor: "#2ABF6F"
    },
    { id: 2, cor: "#5BA9D0" },
    { id: 3, cor: "#FA5A61" },
    { id: 4, cor: "#FA3EB1" },
    { id: 5, cor: "#F4A330" },
    { id: 9, cor: "#A72474" }
  ];

  const dadosFiltrados = cores.filter((cor) =>
    dadosCategorias?.grupos?.find((item) => item.id === cor.id)
  );
  const coresGrafico = dadosFiltrados?.map((dado) => dado.cor) || [];

  const dadosGrafico = {
    series: seriesDados,
    options: {
      chart: {
        type: "donut"
      },
      legend: {
        show: false
      },
      colors: coresGrafico,
      stroke: {
        width: 0
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          offsetX: 0,
          donut: {
            labels: {
              show: false
            },
            size: "60%"
          },
          customScale: 1
        }
      },
      labels: labelsDados
    }
  };

  const obterDadosCategorias = async (unidade, dataInicio = null) => {
    setCarregando(true);
    try {
      const dados = await buscarTodasCategorias({
        UnidadeConsumidoradId: unidade?.unidadeConsumidoraId,
        Mac: unidade?.medidores[0]?.descricaoMedidor,
        DiaFaturamento: unidade?.diaFaturamentoDistribuidora,
        Tarifa: unidade?.tarifaCheia,
        DataInicio: unidade?.diaFaturamentoDistribuidora
          ? `${moment(dataInicio).format("YYYY-MM")}-${
              unidade?.diaFaturamentoDistribuidora
            }`
          : moment(dataInicio).format("YYYY-MM-DD")
      });

      setDadosCategorias(dados?.data ? dados?.data : {});
    } catch (erro) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: erro?.response?.data?.message
        })
      );
    } finally {
      setCarregando(false);
    }
  };

  useEffect(() => {
    if (unidadeConsumidora?.medidores?.length) {
      const data = new Date();
      const dataFaturamento = unidadeConsumidora?.diaFaturamentoDistribuidora
        ? moment([
            data.getFullYear(),
            data.getMonth() - 1,
            unidadeConsumidora?.diaFaturamentoDistribuidora
          ]).toDate()
        : new Date();

      obterDadosCategorias(unidadeConsumidora, dataFaturamento);
    }
  }, [unidadeConsumidora]);

  return (
    <Loader loading={carregando}>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: { xs: "space-between" },
            alignItems: { xs: "center" },
            background: "#FBFBFD"
          }}
          p={2}
        >
          <h3 className={classes.titulo}>Categorias</h3>
          {/* <Link to="/categorias">
            <Botao>Ver detalhes</Botao>
          </Link> */}
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "center"
          }}
          p={2}
        >
          {seriesDados.length > 0 ? (
            <>
              <ReactApexChart
                options={dadosGrafico?.options}
                series={dadosGrafico?.series}
                type="donut"
                height="320"
                width="320"
              />
              <div className={classes.textoCentral}>
                <p>Consumo de</p>
                <p>energia no período:</p>
                <h2>{formatarValor(135)}</h2>
                <h3>125 kwh</h3>
              </div>
            </>
          ) : (
            <SemDados texto="Ainda não conseguimos mostrar suas categorias! Em breve, elas estarão disponíveis para você." />
          )}
        </Grid>
      </Grid>
    </Loader>
  );
};

Categorias.propTypes = {
  unidadeConsumidora: PropTypes.shape({
    unidadeConsumidoraId: PropTypes.number,
    diaFaturamentoDistribuidora: PropTypes.number,
    medidores: PropTypes.arrayOf(
      PropTypes.shape({ descricaoMedidor: PropTypes.string })
    )
  })
};

Categorias.defaultProps = {
  unidadeConsumidora: null
};

export default Categorias;
