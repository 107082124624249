import * as React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Select,
  OutlinedInput,
  FormControl,
  MenuItem,
  Stack,
  Chip
} from "@mui/material";
import Contador from "../Contador";

export default function SelectContador({ label, opcoes, onChange }) {
  const filtrarPorQuantidade = (items) => {
    return items?.length ? items.filter((opcao) => opcao.quantidade > 0) : [];
  };

  return (
    <Box>
      <FormControl fullWidth>
        <Select
          labelId="select-label"
          id="select"
          label=""
          onChange={() => {}}
          displayEmpty
          renderValue={() => {
            const opcoesFiltradas = filtrarPorQuantidade(opcoes);
            return (
              <>
                {opcoesFiltradas?.length ? (
                  <Stack direction="row" spacing={1}>
                    {opcoesFiltradas.map((opcao) => (
                      <Chip label={`${opcao.descricao} +${opcao.quantidade}`} />
                    ))}
                  </Stack>
                ) : (
                  label
                )}
              </>
            );
          }}
          input={<OutlinedInput />}
        >
          {opcoes?.length &&
            opcoes.map((opcao, indexOpcao) => (
              <MenuItem
                key={opcao.tipoApplianceId}
                value={opcao.tipoApplianceId}
                onClick={() => {}}
              >
                {opcao.item}
                <Contador
                  contador={opcao.quantidade}
                  label={opcao.descricao}
                  onChange={(contador) => onChange(contador, indexOpcao)}
                />
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  );
}

SelectContador.propTypes = {
  label: PropTypes.string,
  opcoes: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ).isRequired,
  onChange: PropTypes.func
};

SelectContador.defaultProps = {
  label: "",
  onChange: () => {}
};
