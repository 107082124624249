import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import moment from "moment";
import CardConsumoEconomia from "../../../componentes/cardConsumoEconomia";
import GraficoConsumoEconomiaBarra from "../componentes/graficosBarrasConsumoEconomia";
import InputDataLuz from "../../../componentes/inputDataLuz";

import Cofre from "../../../assets/consumoEnergia/cofre.png";
import Velocimento from "../../../assets/consumoEnergia/velocimetro.png";
import { getTelemetriaAnual } from "../../../servicos/temetriaGDServico";
import { store } from "../../../global/redux";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";
import { Loader } from "../../../componentes";
import SemDados from "../../../componentes/semDados";
import {
  unidadeConsumidoraGD,
  unidadeConsumidoraGDColaborador
} from "../../../servicos/unidadeConsumidoraGDServico";
import UnidadeNaoSelecionada from "../../../componentes/unidadeNaoSelecionada";
import { getConsumoProjecao } from "../../../servicos/dgIntegracaoServico";
import Button from "../componentes/botaoLuz";

const Geral = () => {
  const [dados, setDados] = useState(null);
  const usuarioLogado = useSelector((state) => state.usuario);
  const [carregandoDados, setCarregandoDados] = useState(false);
  const [dataCategories, setCategories] = useState([]);
  const [dataSeriesReal, setSeriesReal] = useState([]);
  const [dataSeriesProjetada, setSeriesProjetada] = useState([]);
  const [selecioneUnidade, setSelecioneUnidade] = useState(false);
  const [dataDescricaoFim, setDataDescricaoFim] = useState(null);
  const [dataDescricaoInicio, setDataDescricaoInicio] = useState(null);
  const [dataInicio, setDataInicio] = useState(null);
  const [validacao, setValidacao] = useState(true);
  const [dadosUnidades, setDadosUnidade] = useState([]);
  const [consumoProjecao, setDadosConsumoProjecao] = useState(null);
  const [dataFim, setDataFim] = useState(null);
  const [dataSeriesConsumo, setDataSeriesConsumo] = useState([]);

  const {
    empresaSelecionada,
    clienteSelecionado,
    unidadeSelecionada,
    usuario
  } = usuarioLogado;

  const { tipo } = usuario;

  useEffect(() => {
    moment.locale("pt");
    const descricaoFim = `${moment().format("DD MMMM")} ${moment().format(
      "YYYY, dddd"
    )}`;
    setDataDescricaoFim(descricaoFim);

    const descricaoInicio = `${moment().format("DD MMMM")} ${moment()
      .subtract(1, "year")
      .format("YYYY, dddd")}`;
    setDataDescricaoInicio(descricaoInicio);
    setSelecioneUnidade(true);
  }, []);

  const handleGrafico = async (codigoDescricao, tarifa, dtIni, dtFim) => {
    try {
      setCarregandoDados(true);
      if (codigoDescricao) {
        const { data, status } = await getTelemetriaAnual(
          codigoDescricao,
          tarifa,
          dtIni,
          dtFim
        );
        if (data && status === 200) {
          setValidacao(true);
          setCategories(data?.anualList?.map((item) => item?.ano));
          setSeriesReal(data?.anualList?.map((item) => item?.eptFTotal));
          setSeriesProjetada(
            data?.anualList?.map((item) => item?.eptFProjetado)
          );
          setDataSeriesConsumo(data?.anualList?.map((item) => item?.eptTotal));
          setDados(data);
        } else if (status === 204) {
          setValidacao(false);
          setCategories([]);
          setSeriesReal([]);
          setSeriesProjetada([]);
          setDataSeriesConsumo([]);
          setDados(null);
        }
      }
    } catch (error) {
      if (error?.response?.status !== 200 && error?.response?.status !== 204) {
        setValidacao(false);
      }
    } finally {
      setCarregandoDados(false);
    }
  };

  const handleUnidadeConsumidora = async (unidadeId, dtInit, dtFim) => {
    try {
      const { data, status } =
        tipo === "Cliente"
          ? await unidadeConsumidoraGD()
          : await unidadeConsumidoraGDColaborador(unidadeId);
      if (data && status === 200) {
        const unidade =
          data?.unidadeConsumidoraGDList?.filter(
            (item) => item.unidadeConsumidoraId === unidadeId
          ) || [];
        setDadosUnidade(unidade);
        if (unidade?.[0]?.medidores?.length > 0) {
          handleGrafico(
            unidade?.[0]?.medidores[0].descricaoMedidor,
            unidade?.[0]?.somaTarifaCheiaBandeira,
            dtInit,
            dtFim
          );
          setSelecioneUnidade(true);
        } else {
          store.dispatch(
            alertaExibir({
              tipo: "warning",
              mensagem:
                "Não existe medidor cadastrada para esse unidade, selecione outra!"
            })
          );
        }
      }
    } catch (error) {
      if (error?.response?.status !== 200 && error?.response?.status !== 204) {
        setValidacao(false);
      }
    }
  };

  const changeDataFim = (data) => {
    moment.locale("pt");
    const descricao = `${moment(data).format("DD MMMM")} ${moment(data).format(
      "YYYY, dddd"
    )}`;
    setDataDescricaoFim(descricao);
    setDataFim(data);
  };

  const changeDataInicio = (data) => {
    moment.locale("pt");
    const descricao = `${moment(data).format("DD MMMM")} ${moment(data).format(
      "YYYY, dddd"
    )}`;
    setDataDescricaoInicio(descricao);
    setDataInicio(data);
  };

  useEffect(() => {
    if (unidadeSelecionada) {
      moment.locale("pt");
      const descricaoFim = `${moment().format("DD MMMM")} ${moment().format(
        "YYYY, dddd"
      )}`;
      const dataAtual = moment().format("YYYY-MM-DD");
      setDataDescricaoFim(descricaoFim);
      setDataFim(dataAtual);

      const descricaoInicio = `${moment().format(
        "DD MMMM"
      )} ${moment().subtract(1, "year").format("YYYY, dddd")}`;
      const dataInicial = moment().subtract(1, "year").format("YYYY-MM-DD");
      setDataDescricaoInicio(descricaoInicio);
      setDataInicio(dataInicial);

      handleUnidadeConsumidora(unidadeSelecionada, dataInicial, dataAtual);
    } else {
      setSelecioneUnidade(false);
    }
  }, [empresaSelecionada, clienteSelecionado, unidadeSelecionada]);

  const handleConsumoProjecao = async (unidadeId, cip, bandeira, tarifa) => {
    const { eptPeriodo, eptProjetado } = dados;
    try {
      if (eptPeriodo && eptProjetado) {
        const { data, status } = await getConsumoProjecao(
          unidadeId,
          eptPeriodo,
          eptProjetado,
          cip,
          bandeira,
          tarifa
        );
        if (data && status === 200) {
          setDadosConsumoProjecao(data);
        } else if (status === 204) {
          setDadosConsumoProjecao(null);
        }
      }
    } catch (error) {
      if (error?.response?.status !== 200 && error?.response?.status !== 204) {
        setDadosConsumoProjecao(null);
      }
    }
  };

  useEffect(() => {
    if (dadosUnidades && dados) {
      const unidadeId = dadosUnidades[0]?.unidadeConsumidoraId;
      const cip = dadosUnidades[0]?.cosip;
      const bandeiraTarifaria = dadosUnidades[0]?.bandeiraTarifaria;
      const tarifa = dadosUnidades[0]?.somaTarifaCheiaBandeira;

      handleConsumoProjecao(unidadeId, cip, bandeiraTarifaria, tarifa);
    }
  }, [dadosUnidades, dados]);

  return (
    <Loader loading={carregandoDados}>
      {selecioneUnidade ? (
        <Box component="div" px={2} py={4} boxShadow={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={10}>
              <Box component="div" display={{ md: "flex" }}>
                <Box component="span" ml={{ lg: 2 }}>
                  <InputDataLuz
                    descricao={dataDescricaoInicio}
                    onChange={(value) => changeDataInicio(value)}
                  />
                </Box>
                <Box component="span">
                  <InputDataLuz
                    descricao={dataDescricaoFim}
                    onChange={(value) => changeDataFim(value)}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid xs={6} md={2}>
              <Box ml={{ lg: 2 }} mr={{ lg: 2 }}>
                <Button
                  id="alterarPeriodo"
                  form="hook-form"
                  label="Alterar período"
                  onClick={() =>
                    handleUnidadeConsumidora(
                      unidadeSelecionada,
                      dataInicio,
                      dataFim
                    )
                  }
                />
              </Box>
            </Grid>

            {validacao ? (
              <GraficoConsumoEconomiaBarra
                dados={dados}
                serie1={dataSeriesReal}
                serie2={dataSeriesProjetada}
                serie3={dataSeriesConsumo}
                categories={dataCategories}
                typeCategories={undefined}
                type="geral"
              />
            ) : (
              <SemDados />
            )}

            <CardConsumoEconomia
              xs={12}
              md={6}
              dados={dados}
              icon={Velocimento}
              title="Consumo geral"
              content={`R$ ${dados?.eptFPeriodo} (${dados?.eptPeriodo} KWh)`}
            />
            <CardConsumoEconomia
              xs={12}
              md={6}
              dados={consumoProjecao}
              icon={Cofre}
              title="Sua conta anual deve diminuir em"
              content={`R$ ${consumoProjecao?.EconomiaProjetadaLUZ || ""} (${
                consumoProjecao?.desconto || ""
              }%)`}
            />
          </Grid>
        </Box>
      ) : (
        <UnidadeNaoSelecionada />
      )}
    </Loader>
  );
};

export default Geral;
