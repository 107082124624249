/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";

// Redux
import { Button, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import UnidadeNaoSelecionada from "componentes/unidadeNaoSelecionada";
import getDomain from "global/getDomain";
import { HtmlTooltip } from "paginas/Graficos/ConsumoAtual/style";
import { useSelector } from "react-redux";
import { store } from "../../../global/redux";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";

// Componentes
import InputDataWhite from "../../../componentes/inputTextoDataBranco";
import Loader from "../../../componentes/loader";
import MedidorConsumo from "./MedidorConsumo";
import MedidoresCompletos from "./MedidoresCompletos";
import MedidorHora from "./MedidorHora";

// Style

import Excel46x46Luz from "../../../assets/icones/downloads/excel46x46-luz.png";
import Excel46x46 from "../../../assets/icones/downloads/excel46x46.png";

// Helper
import MedicaoHelper from "./helper";

const Consolidacao = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("lg"));

  const usuario = useSelector((state) => state.usuario);
  const { unidadeSelecionada } = usuario;
  const [carregandoDownload, setCarregandoDownload] = useState(false);

  const [dataMedicaoConsolidada, setDataMedicaoConsolidada] = useState(
    new Date()
  );

  const definirMesInicial = () => {
    const now = window.moment();
    const day = now.date();
    const newDate = window.moment().subtract(1, "month").toDate();
    return day === 1 || day === 2 ? new Date(newDate) : new Date(now);
  };

  useEffect(() => {
    setDataMedicaoConsolidada(definirMesInicial());
  }, [unidadeSelecionada]);

  const onChangeDataMedicaoConsolidada = (data) => {
    setDataMedicaoConsolidada(data);
  };

  const [consolidacaoSelecionada, setConsolidacaoSelecionada] = useState();
  const medidorSelecionado = (data) => {
    setConsolidacaoSelecionada(data);
  };

  const onClickDownload = async () => {
    setCarregandoDownload(true);

    if (!consolidacaoSelecionada) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Selecione um medidor!"
        })
      );
    } else {
      const mes = dataMedicaoConsolidada.getMonth() + 1;

      await MedicaoHelper.DownloadExcelMedicao(
        unidadeSelecionada,
        `${dataMedicaoConsolidada.getFullYear()}-${mes}-${1}`,
        consolidacaoSelecionada?.codigoMedidorCCEE
      );
    }

    setCarregandoDownload(false);
  };

  return (
    <>
      {unidadeSelecionada ? (
        <>
          <Grid container>
            <Grid
              container
              justifyContent="space-between"
              className="m-3 w-100"
            >
              <Grid item className="mb-3">
                <InputDataWhite
                  type="text"
                  id="dataMedicaoConsolidada"
                  name="dataMedicaoConsolidada"
                  format="MMM/yyyy"
                  customValue={dataMedicaoConsolidada}
                  onChange={(data) => onChangeDataMedicaoConsolidada(data)}
                  views={["month", "year"]}
                  autoOk
                />
              </Grid>
              {!mobile ? (
                <Grid item className="mb-3">
                  <Loader loading={carregandoDownload}>
                    <Button
                      style={{ marginTop: "-15px" }}
                      onClick={() => onClickDownload(1)}
                    >
                      <HtmlTooltip title="Baixar">
                        <img
                          src={
                            getDomain() === "luz" ? Excel46x46Luz : Excel46x46
                          }
                        />
                      </HtmlTooltip>
                    </Button>
                  </Loader>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
          {!mobile ? (
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <MedidoresCompletos
                  medidorSelecionado={medidorSelecionado}
                  dataSelecionada={dataMedicaoConsolidada}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <MedidorHora medidorSelecionado={consolidacaoSelecionada} />
              </Grid>
              <Grid item xs={12} md={4}>
                <MedidorConsumo medidorSelecionado={consolidacaoSelecionada} />
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <MedidorHora medidorSelecionado={consolidacaoSelecionada} />
              </Grid>
              <Grid item xs={12} md={4}>
                <MedidorConsumo medidorSelecionado={consolidacaoSelecionada} />
              </Grid>
              <Grid item xs={12} md={4}>
                <MedidoresCompletos
                  medidorSelecionado={medidorSelecionado}
                  dataSelecionada={dataMedicaoConsolidada}
                />
              </Grid>
            </Grid>
          )}
        </>
      ) : (
        <UnidadeNaoSelecionada />
      )}
    </>
  );
};

export default Consolidacao;
