import React from "react";
import InputData from "../inputTextoData";

export default class ColunaCampoDataFim {
  constructor(
    field,
    headerName,
    sortable = false,
    onChangeSelecionarDataFim,
    maxWidth = null,
    minWidth = null,
    dataFim = null,
    idCampoSelecionadoFim = null,
    selecionadoId = null,
    enabled = false
  ) {
    this.selector = field;
    this.name = headerName;
    this.sortable = sortable;
    this.maxWidth = maxWidth;
    this.minWidth = minWidth;
    this.dataFim = dataFim;
    this.idCampoSelecionadoFim = idCampoSelecionadoFim;
    this.selecionadoId = selecionadoId;
    this.enabled = enabled;
    this.cell = (params) => {
      const yearsFromNow = new Date();
      yearsFromNow.setFullYear(yearsFromNow.getFullYear() + 200);
      return (
        <InputData
          type="date"
          id={`dataFim${params.id}`}
          name={`dataFim${params.id}`}
          customValue={
            params.id === idCampoSelecionadoFim
              ? dataFim
              : params.dataFimVigencia
          }
          onAccept={(value) => {
            onChangeSelecionarDataFim(value, params);
          }}
          onBlur={(event) => {
            if (event.target.value) {
              const data = event.target.value;
              const dia = data.split("/")[0];
              const mes = data.split("/")[1];
              const ano = data.split("/")[2];
              const novaData = new Date(
                `${ano}-${`0${mes}`.slice(-2)}-${`1${dia}`.slice(-2)}T00:00`
              );

              onChangeSelecionarDataFim(novaData, params);
            }
          }}
          disabled={!(params.id === selecionadoId && enabled)}
          InputProps={{
            disableUnderline: true
          }}
          minDate={new Date()}
          maxDate={yearsFromNow}
        />
      );
    };
  }
}
