import React, { useMemo } from "react";
import PropTypes from "prop-types";

// Icones
import { BsFillEyeSlashFill, BsFillEyeFill } from "react-icons/bs";

// Cores
import { Base } from "../cores";

const InputTexto = React.forwardRef((props, ref) => {
  const {
    placeholder,
    onChange,
    disabled,
    onKeyDown,
    label,
    id,
    name,
    legenda,
    icon,
    type,
    errors,
    defaultValue,
    onShow
  } = props;

  const isInvalid = useMemo(() => {
    return errors[name]?.message ?? false;
  }, [errors, name]);

  const styleInput = useMemo(
    () =>
      icon
        ? {
            borderLeft: 0,
            borderRight: onShow && 0,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderTopRightRadius: onShow ? 0 : "50rem",
            borderBottomRightRadius: onShow ? 0 : "50rem"
          }
        : { borderRadius: "50rem" },
    [icon, onShow]
  );

  const styleIcon = useMemo(() => {
    return {
      backgroundColor: disabled ? Base.GreyDisabled : "transparent",
      borderTopLeftRadius: "50rem",
      borderBottomLeftRadius: "50rem",
      borderColor: isInvalid ? Base.RedAmaranth : Base.GreyWater
    };
  }, [disabled, isInvalid]);

  const styleShowIcon = useMemo(() => {
    return {
      backgroundColor: "transparent",
      borderTopRightRadius: "50rem",
      borderBottomRightRadius: "50rem",
      borderColor: isInvalid ? Base.RedAmaranth : Base.GreyWater,
      cursor: "pointer"
    };
  }, [isInvalid]);

  const classNameCampo = useMemo(() => {
    let classe = "";

    if (!isInvalid) {
      if (onShow) classe = "mb-3";
      else classe = "w-auto mb-3";
    } else if (onShow) classe = "is-invalid";
    else classe = "w-auto is-invalid";

    return classe;
  }, [isInvalid, onShow]);

  return (
    <div className={icon ? "input-group" : "form-group"}>
      {label && <label htmlFor={id}>{label}</label>}
      {icon && (
        <div className={`input-group-prepend ${isInvalid ? "mb-0" : "mb-3"}`}>
          <span className="input-group-text" style={{ ...styleIcon }}>
            {icon}
          </span>
        </div>
      )}
      <input
        type={type}
        id={id}
        name={name}
        style={{ ...styleInput }}
        className={`form-control form-control-lg text-dark ${
          icon && "pl-0"
        } ${classNameCampo}`}
        aria-describedby={id}
        ref={ref}
        placeholder={placeholder}
        onChange={(e) => onChange(e)}
        disabled={disabled}
        onKeyDown={onKeyDown}
        defaultValue={defaultValue}
      />
      {onShow && (
        <div
          role="button"
          tabIndex={0}
          onClick={onShow}
          onKeyPress={onShow}
          className={`input-group-prepend ${isInvalid ? "mb-0" : "mb-3"}`}
        >
          <span
            className="input-group-text border-left-0"
            style={{ ...styleShowIcon }}
          >
            {type === "password" ? <BsFillEyeFill /> : <BsFillEyeSlashFill />}
          </span>
        </div>
      )}
      {isInvalid && <div className="invalid-feedback mb-3">{isInvalid}</div>}
      {legenda && (
        <small id={id`Legenda`} className="form-text text-muted">
          {legenda}
        </small>
      )}
    </div>
  );
});

InputTexto.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.oneOfType([PropTypes.func]),
  disabled: PropTypes.bool,
  onKeyDown: PropTypes.oneOfType([PropTypes.func]),
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  legenda: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.any]),
  type: PropTypes.string,
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  defaultValue: PropTypes.string,
  onShow: PropTypes.oneOfType([PropTypes.func, PropTypes.bool])
};

InputTexto.defaultProps = {
  placeholder: "",
  onChange: () => {},
  disabled: false,
  onKeyDown: () => {},
  label: "",
  legenda: "",
  icon: false,
  type: "text",
  errors: {},
  defaultValue: "",
  onShow: false
};

export default InputTexto;
