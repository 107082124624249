// import { Document } from "@react-pdf/renderer";
// import PropTypes from "prop-types";
import React from "react";
// import ModeloPlanosEnergia from "./ModeloPlanosEnergia";

const PdfCotacoes = () => {
  // const { idCotacao } = props;

  return (
    // <Document title={`Cotação ${idCotacao}`}>
    //   <ModeloPlanosEnergia {...props} />
    // </Document>
    <></>
  );
};

PdfCotacoes.propTypes = {
  // idCotacao: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  //   .isRequired
};

export default PdfCotacoes;
