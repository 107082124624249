import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { Loader } from "componentes";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import { saveAs } from "file-saver";
import { store } from "../../../global/redux";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";
import UploadDropArea from "../../../componentes/upload/index";

import { desabilitarFiltroLateral } from "../../../global/redux/modulos/usuario/actions";
import UploadUsinasUnidadeHelper from "./helper";

import { useStyles } from "./style";

const UsinasUnidade = () => {
  const classes = useStyles();
  const [progress, setProgress] = useState(0);
  const [showLoader, setShowLoader] = useState(false);

  const processarArquivo = async (valor) => {
    setProgress(0);

    const arquivo = valor[0];
    if (!arquivo) return;

    const {
      sucesso,
      mensagem
    } = await UploadUsinasUnidadeHelper.uploadUsinasUnidade(
      arquivo,
      setShowLoader
    );

    if (!sucesso) {
      UploadUsinasUnidadeHelper.exibirErro(mensagem);
      setShowLoader(false);
      return;
    }

    setProgress(100);
  };

  const onAddUpload = (valor) => {
    processarArquivo(valor);
  };

  const onDropRejected = (valor) => {
    const arquivo = valor[0];
    store.dispatch(
      alertaExibir({
        tipo: "warning",
        mensagem: `Tipo do arquivo ${arquivo.name} não é permitido!`
      })
    );
  };

  useEffect(() => {
    store.dispatch(desabilitarFiltroLateral(true));

    return () => {
      store.dispatch(desabilitarFiltroLateral(false));
    };
  }, [desabilitarFiltroLateral]);

  const onDownload = async () => {
    const {
      data,
      sucesso,
      mensagem
    } = await UploadUsinasUnidadeHelper.exportarExcelUsinasUnidade(
      setShowLoader
    );

    if (!sucesso) {
      UploadUsinasUnidadeHelper.exibirErro(mensagem);
      setShowLoader(false);
    }

    const blob = new Blob([data]);
    saveAs(blob, "UsinasPorUnidadeConsumidora.xlsx");
  };

  return (
    <Loader loading={showLoader}>
      <Grid container spacing={1} alignItems="center">
        <Grid item sm={6} xs={12} className={classes.containerDropZone}>
          <Box className={classes.container} mb={1}>
            Usinas por Unidade Consumidora.
          </Box>
          <UploadDropArea
            onAdd={onAddUpload}
            progressValue={progress}
            showLoader={showLoader}
            onDropRejected={onDropRejected}
            acceptedFiles={[
              ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            ]}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Box className={classes.container} mb={1}>
            Download por Unidade Consumidora.
          </Box>
          <button type="button" className={classes.button} onClick={onDownload}>
            <CloudDownloadOutlinedIcon className={classes.icone} />
            <span>Clique aqui para realizar o download</span>
          </button>
        </Grid>
      </Grid>
    </Loader>
  );
};

export default UsinasUnidade;
