import { makeStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import styled from "styled-components";
import Tooltip from "@mui/material/Tooltip";

export const useStyles = makeStyles(() => ({
  container: {
    margin: 0,
    width: "100%"
  },
  button: {
    fontSize: "18px"
  },
  medidor: {
    marginTop: "-18px !important",
    fontSize: "14px"
  }
}));

const cores = ["darkred", "orangered", "orange", "yellowgreen", "green"];

export const Medidor = styled.div`
  
  .strength-meter {
    background: #ddd;
    height: 10px;
    position: relative;
    margin: 5px 0;
    overflow: hidden;
    &:after {
      content: "";
      height: inherit;
      background: transparent;
      display: block;
      border-color: #fff;
      border-style: solid;
      border-width: 0 6px 0;
      position: absolute;
      width: calc(33.3%);
      z-index: 2;
    }
    &:after {
      right: calc(33.3%);
    }
  }
  .strength-meter-fill {
    background: transparent;
    height: inherit;
    position: absolute;
    width: 0;
    transition: width 0.5s ease-in-out, background 0.25s;
    &[data-strength="${(props) => props.forca}"] {
      background: ${(props) => cores[props.forca]};
      width: calc(20% * ${(props) => props.forca + 1});
    }
  }
`;

export const Mensagem = styled.p`
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  letter-spacing: -0.31px;
  margin-bottom: 40px;
  text-align: center;
`;

export const TooltipInfo = withStyles(() => ({
  tooltip: {
    fontSize: "12px",
    width: "100%",
    padding: "8px",
    borderImageSlice: 1,
    backgroundImage:
      "linear-gradient(to bottom, #647480, #647480), linear-gradient(to bottom, #374148, #647480 87%)",
    borderImageSource: "linear-gradient(to bottom, #374148, #647480 87%)",
    borderRadius: "5px"
  }
}))(Tooltip);
