/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Grid, Button } from "@mui/material";

import { useStyles } from "./style";

const ColunaTempo = ({ parametros }) => {

    const classes = useStyles();
    const [tempo, setTempo] = useState("");

    const handleTempo = () => {
        
        let millis = parametros.tempoExecucao;
        if(millis == undefined) {millis = parametros.TempoExecucao;}

        if (millis && millis != undefined) 
        {
            //millis = parametros.tempoExecucao
            const minutes = Math.floor(millis / 60000).toFixed(0);
            const seconds = ((millis % 60000) / 1000).toFixed(0);
            setTempo(`${minutes < 10 ? "0" : ""}${minutes}:${seconds < 10 ? "0" : ""}${seconds}`)
        }
    }

    useEffect(() => {handleTempo()}, [])

    return (
        <Grid container spacing={0} className={classes.fundoCelula}>
            <Grid item xs={12} className={classes.textoStatus}>
                {tempo}
            </Grid>
        </Grid>
    );
};


export default ColunaTempo;
