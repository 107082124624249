export default class FiltroListagemDto {
  constructor(
    ativo,
    searchString,
    sortOrder,
    pagina,
    tamanhoPaginacao,
    unidadeId,
    clienteId,
    empresaId,
    tipoPerfil,
    campoPesquisa
  ) {
    this.ativo = ativo;
    this.searchString = searchString;
    this.sortOrder = sortOrder;
    this.pagina = pagina;
    this.tamanhoPaginacao = tamanhoPaginacao;
    this.unidadeId = unidadeId;
    this.clienteId = clienteId;
    this.empresaId = empresaId;
    this.tipoPerfil = tipoPerfil;
    this.campoTextoSelecionado = campoPesquisa;
  }
}
