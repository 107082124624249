import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import ReactApexChart from "react-apexcharts";

// Componentes
import { Box, Card, Grid } from "@mui/material";
import IconeConsumo from "../../../../assets/icones/categorias/consumo.svg";
import IconeInformacao from "../../../../assets/icones/categorias/informacao.svg";
import Relogio from "../../../../assets/icones/categorias/relogio.svg";
import formatarValor from "../../../../global/utils/formatarValor";
// eslint-disable-next-line import/no-named-as-default
import Percentual from "../Percentual";
import { Circulo, Kwh, useStyles } from "./style";

const ConsumoGrupo = ({ dados, informacoes, setModalAberto }) => {
  const classes = useStyles();
  const [dadosConsumo, setDadosConsumo] = useState();

  const dadosGrafico = useMemo(
    () => ({
      series: [
        {
          name: "Consumo",
          data:
            dadosConsumo?.historico?.map(
              (consumo) => consumo.valorFinanceiro
            ) || []
        }
      ],
      options: {
        chart: {
          height: 300,
          type: "bar",
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            columnWidth: "60px",
            borderRadius: 30,
            borderRadiusApplication: "end"
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 0
        },
        grid: {
          row: {
            colors: ["#fff", "#fff"]
          }
        },
        xaxis: {
          labels: {
            rotate: -45
          },
          categories:
            dadosConsumo?.historico?.map((consumo) => {
              return moment(consumo.dataHistorico).format("MMM");
            }) || []
        },
        yaxis: {
          labels: {
            formatter(value) {
              return formatarValor(value);
            }
          }
        },
        fill: {
          colors: [informacoes?.cor, informacoes?.cor]
        },
        responsive: [
          {
            breakpoint: 1200,
            options: {
              plotOptions: {
                bar: {
                  columnWidth: "35px",
                  borderRadius: 17
                }
              }
            }
          },
          {
            breakpoint: 600,
            options: {
              plotOptions: {
                bar: {
                  columnWidth: "25px",
                  borderRadius: 12
                }
              }
            }
          }
        ]
      }
    }),
    [dadosConsumo]
  );

  useEffect(() => {
    if (dados) {
      setDadosConsumo(dados);
    }
  }, [dados]);

  return (
    <>
      <Grid container py={3} px={2}>
        <Grid
          xs={12}
          sx={{
            display: "flex",
            justifyContent: { xs: "space-between", sm: "flex-start" }
          }}
        >
          <Box display="flex">
            <img
              src={informacoes?.icone}
              className={classes.cardIcone}
              alt="ícone consumo"
            />
            <h1 className={classes.titulo}>{dados?.descricao}</h1>
          </Box>
          <button
            type="button"
            className={classes.botao}
            onClick={() => setModalAberto(true)}
          >
            <img
              src={IconeInformacao}
              className={classes.cardIcone}
              alt="ícone consumo"
            />
          </button>
        </Grid>
        <Grid xs={12}>
          <ReactApexChart
            options={dadosGrafico?.options}
            series={dadosGrafico?.series}
            type="bar"
            height={300}
          />
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            pb={2}
          >
            <Circulo cor={informacoes?.cor} />
            <b>Consumo Real</b>
          </Box>
        </Grid>
        <Grid container display="flex" alignItems="stretch">
          <Grid xs={6}>
            <Card className={`px-2 py-3 mx-2 h-100 ${classes.sombra}`}>
              <Box marginBottom={1} className={classes.centro}>
                <img
                  src={Relogio}
                  className={classes.cardIcone}
                  alt="ícone relógio"
                />
              </Box>
              <Box marginBottom={1} className={classes.centro}>
                Consumo de energia no período:
              </Box>
              <Box className={classes.cardConteudo}>
                <h1 className={classes.cardPreco}>
                  {formatarValor(dadosConsumo?.valorFinanceiro)}
                </h1>
                <Kwh corTexto={informacoes?.cor}>
                  {`${dadosConsumo?.consumo || "--"} kWh`}
                </Kwh>
              </Box>
            </Card>
          </Grid>
          <Grid xs={6}>
            <Card className={`px-2 py-3 mx-2 h-100 ${classes.sombra}`}>
              <Box marginBottom={1} className={classes.centro}>
                <img
                  src={IconeConsumo}
                  className={classes.cardIcone}
                  alt="ícone máquina de lavar"
                />
              </Box>
              <Box marginBottom={1} className={classes.centro}>
                Alteração de consumo em relação ao período anterior:
              </Box>
              <Percentual valor={dados?.percentualComparativoMesAnterior} />
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

ConsumoGrupo.propTypes = {
  dados: PropTypes.shape().isRequired,
  informacoes: PropTypes.shape({
    cor: PropTypes.string.isRequired,
    icone: PropTypes.string.isRequired
  }).isRequired,
  setModalAberto: PropTypes.func
};

ConsumoGrupo.defaultProps = {
  setModalAberto: () => {}
};

export default ConsumoGrupo;
