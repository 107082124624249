const Colunas = [
  {
    name: "Score De",
    selector: (row) => row.scoreDe,
    cell: (row) => row.scoreDe,
    width: ""
  },
  {
    name: "Score Para",
    selector: (row) => row.scorePara,
    cell: (row) => row.scorePara,
    width: ""
  },
  {
    name: "Prinad",
    selector: (row) => row.limitePrinadGD,
    cell: (row) => row.limitePrinadGD,
    width: ""
  },
  {
    name: "Status",
    selector: (row) => row.status,
    cell: (row) => row.status,
    width: ""
  },
  {
    name: "Ações",
    selector: (row) => row.acoes,
    cell: (row) => row.acoes,
    width: "",
    style: {
      textAlign: "center"
    }
  }
];

export default Colunas;
