/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useRef, useState } from "react";

import { Grid } from "@mui/material";
import Coluna from "componentes/tabelaPaginada/colunas/coluna";
import ColunaComponentePersonalizado from "componentes/tabelaPaginada/colunas/colunaComponentePersonalizado";
import { useForm, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";

import MaterialInputTexto from "componentes/inputTexto/materialInput";
import ResultadoPaginadoDto from "componentes/tabelaPaginada/resultadoPaginadoDto";

import {
  Loader,
  SelectAutocomplete,
  Switch,
  TabelaPaginada
} from "componentes";

import Helper from "./helper";
import { useStyles } from "./style";

const ColunaAssociado = ({ onClick, parametros }) => {
  const [ativo, setAtivo] = useState(parametros.ativo);

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Switch
        checked={ativo}
        onChange={(value) => {
          setAtivo(value);
          parametros.ativo = value;
          onClick(parametros);
        }}
      />
    </Grid>
  );
};

const ColunaVingencia = ({ onClick, parametros }) => {
  const [possuiVigencia, setPossuiVigencia] = useState(
    parametros.possuiVigencia
  );

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Switch
        disabled={!parametros.ativo}
        checked={possuiVigencia}
        onChange={(value) => {
          setPossuiVigencia(value);
          parametros.possuiVigencia = value;
          onClick(parametros);
        }}
      />
    </Grid>
  );
};

const RelatoriosAssociar = () => {
  const classes = useStyles();
  const refTabela = useRef(null);

  const { id: idUrl } = useParams();

  const [listaClientes, setListaClientes] = useState();
  const [clienteId, setClienteId] = useState("0");
  const [carregando, setCarregando] = useState();

  const [filtroAdicionalTabela, setFiltroAdicionalTabela] = useState();

  const { register, control } = useForm({
    reValidateMode: "onSubmit"
  });

  const NomeTipoRelatorio = useWatch({
    control,
    name: "NomeTipoRelatorio",
    defaultValue: ""
  });

  const onChangeFiltrosTabela = async (parametros) => {
    if (Number(clienteId) === 0) return;

    const params = {};
    params.clienteId = clienteId;
    params.NomeTipoRelatorio = NomeTipoRelatorio;
    params.totalPagina = parametros.totalPagina;
    params.pagina = parametros.pagina;

    const retorno = await Helper.ListaTipoRelatorioParaAssociacao(params);

    // eslint-disable-next-line consistent-return
    return new ResultadoPaginadoDto(
      retorno.data.lista,
      1,
      retorno.data.totalItens,
      retorno.data.totalPaginas
    );
  };

  const ObterListaClientes = async () => {
    setCarregando(true);

    const { data } = await Helper.ListarClientes();

    setListaClientes([
      {
        id: "0",
        nomeFantasia: " -- Selecione -- "
      },
      ...data.clientes
    ]);

    setCarregando(false);
  };

  const onChangeAtivo = async (params) => {
    let response;

    setCarregando(true);

    if (params.ativo) {
      response = await Helper.Associa(params);
    } else {
      response = await Helper.Desassocia(params);
    }

    setCarregando(false);
  };

  useEffect(() => {
    ObterListaClientes();
  }, []);

  const obterPorId = useCallback(async () => {
    if (!idUrl || idUrl <= 0) return;

    setClienteId(idUrl);
    setFiltroAdicionalTabela({
      clienteId: idUrl
    });
  }, [idUrl]);

  useEffect(() => {
    obterPorId();
  }, [obterPorId]);

  const colunas = [
    new ColunaComponentePersonalizado(
      "ativo",
      "Associado",
      ColunaAssociado,
      onChangeAtivo,
      false,
      false,
      "110px",
      "110px",
      ""
    ),
    new Coluna("nomeCategoriaRelatorio", "Categoria Relatório"),
    new Coluna("nomeTipoRelatorio", "Tipo de Relatório"),
    new ColunaComponentePersonalizado(
      "possuiVigencia",
      "Possui Vigencia?",
      ColunaVingencia,
      onChangeAtivo,
      false,
      false,
      "160px",
      "160px",
      ""
    )
  ];

  return (
    <>
      <Loader loading={carregando}>
        <Grid container>
          <Grid item xs={12}>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
            >
              <Grid item lg={6} md={6} sm={12}>
                <SelectAutocomplete
                  disableOpenOnFocus
                  id="clienteId"
                  name="clienteId"
                  label="Cliente"
                  value={clienteId}
                  options={listaClientes}
                  placeholder="--Selecione--"
                  getOptionLabel={(option) => option?.nomeFantasia ?? ""}
                  onChange={(e, item) => {
                    setClienteId(item?.id ?? 0);
                    setFiltroAdicionalTabela({
                      ClienteId: item?.id ?? 0
                    });
                  }}
                  ref={register({
                    name: "ClienteId",
                    required: true
                  })}
                  allowClear
                  valueName="nomeFantasia"
                />
              </Grid>

              <Grid item lg={6} md={6} sm={12}>
                <MaterialInputTexto
                  type="text"
                  id="NomeTipoRelatorio"
                  name="NomeTipoRelatorio"
                  label="Buscar Tipo de Relatório"
                  renderIconShowHide
                  searchAdornment
                  permiteValorBranco
                  defaultValue={NomeTipoRelatorio ?? ""}
                  ref={register}
                  className={classes.search}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} mt={6}>
            <TabelaPaginada
              ref={refTabela}
              className={classes.table}
              onChangeFiltrosTabela={onChangeFiltrosTabela}
              colunas={colunas}
              paginaAtual={1}
              pesquisar={NomeTipoRelatorio}
              filtrosAdicionais={filtroAdicionalTabela}
              noHeader
            />
          </Grid>
        </Grid>
      </Loader>
    </>
  );
};

export default RelatoriosAssociar;
