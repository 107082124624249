import RetornoEndpointDto from "../../../global/dto/retornoEndpointDto";
import { listarPaginado } from "../../../servicos/listagemValidarCotacoesServico";
import { obterTodos } from "../../../servicos/tipoPlanoCotacaoServico";

export default class ListagemCotacaoHelper {
  static async listarCotacoes(filtro) {
    try {
      const resultado = await listarPaginado(filtro);

      return RetornoEndpointDto.Sucesso(
        "Listagem de cotações obtidas com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu um erro ao obter a lista de cotações"
      );
    }
  }

  static async listarTodosTipos() {
    try {
      const resultado = await obterTodos();

      return RetornoEndpointDto.Sucesso(
        "Listagem de tipo de cotações obtidas com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu um erro ao obter a lista de tipos de cotações"
      );
    }
  }
}
