import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const api = () => obterApi(MICROSERVICO.MEASUREMENT);

export const listarAssociacaoLuz = async (filtro) => {
  const filtroQueryString = new URLSearchParams(filtro).toString();
  return api().get(`v1/associacaoluz?${filtroQueryString}`);
};

export const salvarAssociacaoLuz = async (id, body) => {
  let metodo = "post";
  let url = "v1/associacaoluz";
  if (id > 0) {
    metodo = "put";
    url = `${url}/${id}`;
  }
  return api()[metodo](url, body);
};

export const atualizarAssociacaoLuz = async (params) => {
  const { id, situacao } = params;

  return api().patch(`v1/associacaoluz/${id}/${situacao}`);
};

export const listarOrganizarPor = () => {
  const ordenar = [
    {
      key: "1",
      asc: "1",
      desc: "2",
      label: "Alfabética"
    },
    {
      key: "4",
      asc: "4",
      desc: "0",
      label: "Recentes"
    }
  ];

  return ordenar;
};

export const listarFiltrarPor = () => {
  const filtros = [
    {
      key: "AT",
      label: "Ativo"
    },
    {
      key: "IN",
      label: "Inativo"
    }
  ];

  return filtros;
};

export const obterAssociacaoLuzPorId = (params) => {
  const filtroQueryString = new URLSearchParams(params).toString();
  return api().get(
    `v1/associacaoluz/obterassociacaoluzporid?${filtroQueryString}`
  );
};

export const obterAssociacaoLuzCpfCnpj = (cpfCnpj) => {
  return api().get(`v1/associacaoluz/${cpfCnpj}`);
};

export const obterAssociacao = () => {
  return api().get("v1/associacaoluz/sem-paginacao");
};

export const obterTipoDocumentoAssociacao = () => {
  return api().get("v1/associacaoluz/tipodocumentoassociacaoluz");
};

export const gerarUploadDocumentosAssociacao = (body) => {
  return api().post("v1/associacaoluz/uploaddocumentosassociacao", body);
};

export const gerarUrlDownload = (params) => {
  const filtroQueryString = new URLSearchParams(params).toString();
  return api().get(`v1/associacaoluz/geraurldownload?${filtroQueryString}`);
};

export const obterAssociacaoPorDistribuidora = (distribuidoraId) => {
  return api().get(
    `v1/associacaoluz/obterlistapordistribuidoraid/${distribuidoraId}`
  );
};
