import React from "react";
import PropTypes from "prop-types";

import { Medidor as Wrapper } from "../style";

const Medidor = ({ forca }) => {
  return (
    <Wrapper forca={forca}>
      <div className="strength-meter mt-2 visible">
        <div className="strength-meter-fill" data-strength={forca} />
      </div>
    </Wrapper>
  );
};

Medidor.propTypes = {
  forca: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export default Medidor;
