/* eslint-disable import/no-named-as-default */
import RotasDTO from "./rotasUrlDto";
import * as Pagina from "../paginas";

export default [
  {
    path: RotasDTO.SeriesHistoricas,
    caminho: "SeriesHistoricas",
    component: Pagina.SeriesHistoricas,
    exact: true,
    menuCabecalho: "Séries Históricas",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.SeriesHistoricas}/cadastro`,
    caminho: "SeriesHistoricas",
    component: Pagina.CadastrarSerieHistorica,
    exact: true,
    menuCabecalho: "Cadastro de Séries Históricas",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.SeriesHistoricas}/cadastro/:id?`,
    caminho: "SeriesHistoricas",
    component: Pagina.CadastrarSerieHistorica,
    exact: true,
    menuCabecalho: "Alteração de Séries Históricas",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.SeriesHistoricas}/importar`,
    caminho: "SeriesHistoricas",
    component: Pagina.ImportarSerieHistorica,
    exact: true,
    menuCabecalho: "Importação de Séries Históricas",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.SeriesHistoricas}/importar`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.ImportarSerieHistorica,
    exact: true,
    menuCabecalho: "Importação de Séries Históricas",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.SeriesHistoricas}/valores`,
    caminho: "SeriesHistoricas",
    component: Pagina.ConsultarSerieHistorica,
    exact: true,
    menuCabecalho: "Consultar Série Histórica",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.SeriesHistoricas}/valores`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.ConsultarSerieHistorica,
    exact: true,
    menuCabecalho: "Consultar Série Histórica",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.SeriesHistoricas}/valores/:id?`,
    caminho: "SeriesHistoricas",
    component: Pagina.ConsultarSerieHistorica,
    exact: true,
    menuCabecalho: "Valores da Série Histórica",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.DistribuidorasLista,
    caminho: "DistribuidorasLista",
    component: Pagina.ListaDistribuidoras,
    exact: true,
    menuCabecalho: "Lista",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.DistribuidorasLista}/parametrizacao/:id?`,
    caminho: "DistribuidorasLista",
    component: Pagina.ParametrizacaoDistribuidoras,
    exact: true,
    menuCabecalho: "DistribuidorasLista",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.DistribuidorasLista}/configuracao/:id?`,
    caminho: "DistribuidorasLista",
    component: Pagina.ConfiguracaoDistribuidoras,
    exact: true,
    menuCabecalho: "DistribuidorasLista",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.MercadoFaturado,
    caminho: "MercadoFaturado",
    component: Pagina.MercadoFaturado,
    exact: true,
    menuCabecalho: "Parametrização",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.MercadoFaturado}/configuracaoDistribuidora/:id?`,
    caminho: "MercadoFaturado",
    component: Pagina.MercadoFaturado,
    exact: true,
    menuCabecalho: "ConfiguracaoDistribuidora",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.DistribuidoraMercadoFaturado,
    caminho: "MercadoFaturado",
    component: Pagina.DistribuidoraMercadoFaturado,
    exact: true,
    menuCabecalho: "ListagemDistribuidoras",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.DistribuidoraMercadoFaturadoConfiguracao}/:id?`,
    caminho: "MercadoFaturado",
    component: Pagina.DistribuidoraMercadoFaturadoConfiguracao,
    exact: true,
    menuCabecalho: "DistribuidoraConfiguracao",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.BandeirasTarifariasAcionamento,
    caminho: "BandeirasTarifariasAcionamento",
    component: Pagina.Bandeiras,
    exact: true,
    menuCabecalho: "Acionamento",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.BandeirasTarifariasAcionamento}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.Bandeiras,
    exact: false,
    menuCabecalho: "Acionamento",
    autenticada: true,
    estruturada: true
  },
  {
    path:`${RotasDTO.ProjecaoInsumoListar}${RotasDTO.BandeirasTarifariasImportar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.ImportarPrevisaoBandeiraTarifaria,
    exact: true,
    menuCabecalho: "Importar",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.BandeirasTarifariasImportar,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.ImportarPrevisaoBandeiraTarifaria,
    exact: true,
    menuCabecalho: "Importar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.BandeirasTarifariasCadastrar}/:type?/:id?`,
    caminho: "ValoresVigencia",
    component: Pagina.CadastroBandeiraTarifaria,
    exact: true,
    menuCabecalho: "Cadastrar",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.BandeirasTarifariasAtual,
    caminho: "BandeirasTarifariasAtual",
    component: Pagina.BandeiraTarifariaAtual,
    exact: true,
    menuCabecalho: "Atual",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.BandeirasTarifariasHistoricas,
    caminho: "BandeirasTarifariasHistoricas",
    component: Pagina.BandeiraTarifariaHistorica,
    exact: true,
    menuCabecalho: "Históricas",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.BandeirasTarifariasFutura,
    caminho: "BandeirasTarifariasFutura",
    component: Pagina.BandeiraTarifariaFutura,
    exact: true,
    menuCabecalho: "Futura",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.RedeBasica,
    caminho: "RedeBasica",
    component: Pagina.RAP,
    exact: true,
    menuCabecalho: "Consulta",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.RedeBasica}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.RAP,
    exact: true,
    menuCabecalho: " ",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.Cenarios,
    caminho: "Cenarios",
    component: Pagina.Cenarios,
    exact: true,
    menuCabecalho: "Lista",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.Cenarios}${RotasDTO.CenariosCadastro}/:id?`,
    caminho: "Cenarios",
    component: Pagina.CadastroCenarios,
    exact: true,
    menuCabecalho: "Cadastro",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.CDEEnergiaVisualizar,
    caminho: "CDEEnergiaVisualizar",
    component: Pagina.CDEEnergiaVisualizacao,
    exact: true,
    menuCabecalho: "Consulta",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.CDEEnergiaVisualizar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.CDEEnergiaVisualizacao,
    exact: true,
    menuCabecalho: " ",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.CDEEnergiaImportar,
    caminho: "CDEEnergiaImportar",
    component: Pagina.CDEEnergiaImportacao,
    exact: true,
    menuCabecalho: "Importar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.CDEEnergiaImportar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.CDEEnergiaImportacao,
    exact: true,
    menuCabecalho: " ",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.CDEOrcamentoVisualizar,
    caminho: "CDEOrcamentoVisualizar",
    component: Pagina.CDEOrcamentoVisualizar,
    exact: true,
    menuCabecalho: "Consulta",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.CDEOrcamentoVisualizar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.CDEOrcamentoVisualizar,
    exact: true,
    menuCabecalho: " ",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.CDEOrcamentoImportar,
    caminho: "CDEOrcamentoImportar",
    component: Pagina.CDEOrcamentoImportar,
    exact: true,
    menuCabecalho: "Importar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.CDEOrcamentoImportar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.CDEOrcamentoImportar,
    exact: true,
    menuCabecalho: " ",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.PcatImportar,
    caminho: "PcatImportar",
    component: Pagina.PCAT,
    exact: true,
    menuCabecalho: "Importar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.PcatImportar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.PCAT,
    exact: true,
    menuCabecalho: "Importar",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.BaseRateioProinfaImportar,
    caminho: "BaseRateioProinfaImportar",
    component: Pagina.BaseRateioProinfaImportar,
    exact: true,
    menuCabecalho: "Importar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.BaseRateioProinfaImportar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.BaseRateioProinfaImportar,
    exact: true,
    menuCabecalho: " ",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.BaseRateioProinfaVisualizar,
    caminho: "BaseRateioProinfaVisualizar",
    component: Pagina.BaseRateioProinfaVisualizar,
    exact: true,
    menuCabecalho: "Consulta",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.BaseRateioProinfaVisualizar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.BaseRateioProinfaVisualizar,
    exact: true,
    menuCabecalho: " ",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.BaseRateioEERImportar,
    caminho: "BaseRateioEERImportar",
    component: Pagina.BaseRateioEERImportar,
    exact: true,
    menuCabecalho: "Importar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.BaseRateioEERImportar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.BaseRateioEERImportar,
    exact: true,
    menuCabecalho: " ",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.BaseRateioEERVisualizar,
    caminho: "BaseRateioEERVisualizar",
    component: Pagina.BaseRateioEERVisualizar,
    exact: true,
    menuCabecalho: "Consulta",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.BaseRateioEERVisualizar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.BaseRateioEERVisualizar,
    exact: true,
    menuCabecalho: " ",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.BaseRateioESSImportar,
    caminho: "BaseRateioESSImportar",
    component: Pagina.BaseRateioESSImportar,
    exact: true,
    menuCabecalho: "Importar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.BaseRateioESSImportar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.BaseRateioESSImportar,
    exact: true,
    menuCabecalho: " ",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.BaseRateioESSVisualizar,
    caminho: "BaseRateioESSVisualizar",
    component: Pagina.BaseRateioESSVisualizar,
    exact: true,
    menuCabecalho: "Consulta",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.BaseRateioESSVisualizar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.BaseRateioESSVisualizar,
    exact: true,
    menuCabecalho: " ",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.ProretImportar,
    caminho: "ProretImportar",
    component: Pagina.ProretImportar,
    exact: true,
    menuCabecalho: "Importar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.ProretImportar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.ProretImportar,
    exact: true,
    menuCabecalho: " ",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.ProretConsultar,
    caminho: "ProretConsultar",
    component: Pagina.ProretConsultar,
    exact: true,
    menuCabecalho: "Consulta",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.ProretConsultar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.ProretConsultar,
    exact: true,
    menuCabecalho: " ",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.LeilaoCCEEVisualizar,
    caminho: "LeilaoCCEEVisualizar",
    component: Pagina.LeilaoCCEEVisualizar,
    exact: true,
    menuCabecalho: "Consulta",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.LeilaoCCEEVisualizar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.LeilaoCCEEVisualizar,
    exact: true,
    menuCabecalho: "Visualizar Leilão CCEE",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.LeilaoCCEEImportar,
    caminho: "LeilaoCCEEImportar",
    component: Pagina.LeilaoCCEEImportar,
    exact: true,
    menuCabecalho: "Importar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.LeilaoCCEEImportar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.LeilaoCCEEImportar,
    exact: true,
    menuCabecalho: "Importar Leilão CCEE",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.LeilaoSpartaVisualizar,
    caminho: "LeilaoSpartaVisualizar",
    component: Pagina.LeilaoSpartaVisualizar,
    exact: true,
    menuCabecalho: "Consultar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.LeilaoSpartaVisualizar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.LeilaoSpartaVisualizar,
    exact: true,
    menuCabecalho: " ",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.MercadoSpartaVisualizar,
    caminho: "MercadoSpartaVisualizar",
    component: Pagina.MercadoSpartaVisualizar,
    exact: true,
    menuCabecalho: "Consultar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.MercadoSpartaVisualizar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.MercadoSpartaVisualizar,
    exact: true,
    menuCabecalho: " ",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.SpartaImportar,
    caminho: "SpartaImportar",
    component: Pagina.SpartaImportar,
    exact: true,
    menuCabecalho: "Importar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.SpartaImportar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.SpartaImportar,
    exact: true,
    menuCabecalho: " ",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.ReceitaAdicionalBandeiraVisualizar,
    caminho: "ReceitaAdicionalBandeiraVisualizar",
    component: Pagina.ReceitaAdicionalBandeiraVisualizar,
    exact: true,
    menuCabecalho: "Visualizar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.ReceitaAdicionalBandeiraVisualizar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.ReceitaAdicionalBandeiraVisualizar,
    exact: true,
    menuCabecalho: " ",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.ReceitaAdicionalBandeiraImportar,
    caminho: "ReceitaAdicionalBandeiraImportar",
    component: Pagina.ReceitaAdicionalBandeiraImportar,
    exact: true,
    menuCabecalho: "Importar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.ReceitaAdicionalBandeiraImportar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.ReceitaAdicionalBandeiraImportar,
    exact: true,
    menuCabecalho: " ",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.ComponenteQFatorXImportar,
    caminho: "ComponenteQFatorXImportar",
    component: Pagina.ComponenteQFatorXImportar,
    exact: true,
    menuCabecalho: "Importar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.ComponenteQFatorXImportar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.ComponenteQFatorXImportar,
    exact: true,
    menuCabecalho: " ",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.ComponenteQFatorXVisualizar,
    caminho: "ComponenteQFatorXVisualizar",
    component: Pagina.ComponenteQFatorXVisualizar,
    exact: true,
    menuCabecalho: "Visualizar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.ComponenteQFatorXVisualizar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.ComponenteQFatorXVisualizar,
    exact: true,
    menuCabecalho: " ",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.BaseItaipuImportar,
    caminho: "BaseItaipuImportar",
    component: Pagina.BaseItaipuImportar,
    exact: true,
    menuCabecalho: "Importar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.BaseItaipuImportar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.BaseItaipuImportar,
    exact: true,
    menuCabecalho: "Importar Base Itaipu",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.BaseItaipuVisualizar,
    caminho: "BaseItaipuVisualizar",
    component: Pagina.BaseItaipuVisualizar,
    exact: true,
    menuCabecalho: "Visualizar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.BaseItaipuVisualizar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.BaseItaipuVisualizar,
    exact: true,
    menuCabecalho: "Visualizar Importações Base Itaipu",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.BaseEERImportar,
    caminho: "BaseEERImportar",
    component: Pagina.BaseEERImportar,
    exact: true,
    menuCabecalho: "Importar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.BaseEERImportar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.BaseEERImportar,
    exact: true,
    menuCabecalho: "Importar Bases EER",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.BaseEERVisualizar,
    caminho: "BaseEERVisualizar",
    component: Pagina.BaseEERVisualizar,
    exact: true,
    menuCabecalho: "Visualizar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.BaseEERVisualizar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.BaseEERVisualizar,
    exact: true,
    menuCabecalho: "Visualizar Importações Base EER",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.ContratosProinfaImportar,
    caminho: "ContratosProinfaImportar",
    component: Pagina.ContratosProinfaImportar,
    exact: true,
    menuCabecalho: "Importar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.ContratosProinfaImportar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.ContratosProinfaImportar,
    exact: true,
    menuCabecalho: "Importar Contratos Proinfa",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.ContratosProinfaVisualizar,
    caminho: "ContratosProinfaVisualizar",
    component: Pagina.ContratosProinfaVisualizar,
    exact: true,
    menuCabecalho: "Consulta",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.ContratosProinfaVisualizar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.ContratosProinfaVisualizar,
    exact: true,
    menuCabecalho: "Visualizar Contratos PROINFA",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.ContratosBilateraisImportar,
    caminho: "ContratosBilateraisImportar",
    component: Pagina.ContratosBilateraisImportar,
    exact: true,
    menuCabecalho: "Importar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.ContratosBilateraisImportar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.ContratosBilateraisImportar,
    exact: true,
    menuCabecalho: "Visualizar Contratos Bilaterais",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.ContratosBilateraisVisualizar,
    caminho: "ContratosBilateraisVisualizar",
    component: Pagina.ContratosBilateraisVisualizar,
    exact: true,
    menuCabecalho: "Importar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.ContratosBilateraisVisualizar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.ContratosBilateraisVisualizar,
    exact: true,
    menuCabecalho: "Visualizar Contratos Bilaterais",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.ContratosEerImportar,
    caminho: "ContratosEerImportar",
    component: Pagina.ContratosEerImportar,
    exact: true,
    menuCabecalho: "Importar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.ContratosEerImportar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.ContratosEerImportar,
    exact: true,
    menuCabecalho: "Importar Contratos EER",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.ContratosEerVisualizar,
    caminho: "ContratosEerVisualizar",
    component: Pagina.ContratosEerVisualizar,
    exact: true,
    menuCabecalho: "Consulta",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.ContratosEerVisualizar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.ContratosEerVisualizar,
    exact: true,
    menuCabecalho: "Visualizar Contratos EER",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.CotaAngraImportar,
    caminho: "CotaAngraImportar",
    component: Pagina.CotaAngraImportar,
    exact: true,
    menuCabecalho: "Importar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.CotaAngraImportar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.CotaAngraImportar,
    exact: true,
    menuCabecalho: "Importar Cotas Angra",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.CotaAngraVisualizar,
    caminho: "CotaAngraVisualizar",
    component: Pagina.CotaAngraVisualizar,
    exact: true,
    menuCabecalho: "Consulta",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.CotaAngraVisualizar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.CotaAngraVisualizar,
    exact: true,
    menuCabecalho: "Visualizar Cotas Angra",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.TermicasVisualizar,
    caminho: "TermicasVisualizar",
    component: Pagina.TermicasVisualizar,
    exact: true,
    menuCabecalho: "Consulta",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.TermicasVisualizar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.TermicasVisualizar,
    exact: true,
    menuCabecalho: "Visualizar Cotas Angra",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.TermicasImportar,
    caminho: "TermicasImportar",
    component: Pagina.TermicasImportar,
    exact: true,
    menuCabecalho: "Importar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.TermicasImportar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.TermicasImportar,
    exact: true,
    menuCabecalho: "Visualizar Cotas Angra",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.MigracaoeOutrosImportar,
    caminho: "MigracaoeOutrosImportar",
    component: Pagina.MigracaoeOutrosImportar,
    exact: true,
    menuCabecalho: "Importar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.MigracaoeOutrosImportar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.MigracaoeOutrosImportar,
    exact: true,
    menuCabecalho: "Visualizar Cotas Angra",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.MigracaoeOutrosVisualizar,
    caminho: "MigracaoeOutrosVisualizar",
    component: Pagina.MigracaoeOutrosVisualizar,
    exact: true,
    menuCabecalho: "Visualizar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.MigracaoeOutrosVisualizar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.MigracaoeOutrosVisualizar,
    exact: true,
    menuCabecalho: "Visualizar Cotas Angra",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.ESSANEELImportar,
    caminho: "ESSANEELImportar",
    component: Pagina.ESSANEELImportar,
    exact: true,
    menuCabecalho: "Importar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.ESSANEELImportar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.ESSANEELImportar,
    exact: true,
    menuCabecalho: "Visualizar Cotas Angra",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.ESSANEELVisualizar,
    caminho: "ESSANEELVisualizar",
    component: Pagina.ESSANEELVisualizar,
    exact: true,
    menuCabecalho: "Visualizar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.ESSANEELVisualizar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.ESSANEELVisualizar,
    exact: true,
    menuCabecalho: "Visualizar Cotas Angra",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.ESSMinimoImportar,
    caminho: "ESSMinimoImportar",
    component: Pagina.ESSMinimoImportar,
    exact: true,
    menuCabecalho: "Importar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.ESSMinimoImportar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.ESSMinimoImportar,
    exact: true,
    menuCabecalho: "Visualizar Cotas Angra",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.ESSMinimoVisualizar,
    caminho: "ESSMinimoVisualizar",
    component: Pagina.ESSMinimoVisualizar,
    exact: true,
    menuCabecalho: "Consulta",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.ESSMinimoVisualizar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.ESSMinimoVisualizar,
    exact: true,
    menuCabecalho: " ",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.ParametrosGlobaisConfiguracoes,
    caminho: "ParametrosGlobaisConfiguracoes",
    component: Pagina.ParametrosGlobaisConfiguracoes,
    exact: true,
    menuCabecalho: "Configurações",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.ProjecaoInsumoListar,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.ProjecaoInsumoListar,
    exact: true,
    menuCabecalho: "ProjecaoInsumoListar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}/historico/:id?`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.ProjecaoInsumoHistoricoImportacoes,
    exact: true,
    menuCabecalho: "Histórico de Importações",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.ProjecaoBrasilListar,
    caminho: "ProjecaoBrasilListar",
    component: Pagina.ProjecaoBrasilListar,
    exact: true,
    menuCabecalho: "ProjecaoBrasilListar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoBrasilListar}/historico-projecoes/:id?/:titulo`,
    caminho: "ProjecaoBrasilListar",
    component: Pagina.ProjecaoBrasilHistoricoProjecoes,
    exact: true,
    menuCabecalho: "Histórico de Projeções",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.BaseGarantiaFisicaImportar}`,
    caminho: "BaseGarantiaFisica",
    component: Pagina.BaseGarantiaFisicaImportar,
    exact: true,
    menuCabecalho: "Importação de Base - Garantia Física",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.BaseGarantiaFisicaImportar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.BaseGarantiaFisicaImportar,
    exact: true,
    menuCabecalho: "Importação de Base - Garantia Física",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.BaseGarantiaFisicaVisualizar}`,
    caminho: "BaseGarantiaFisica",
    component: Pagina.BaseGarantiaFisicaListar,
    exact: true,
    menuCabecalho: "Visualização de Base - Garantia Física",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.BaseGarantiaFisicaVisualizar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.BaseGarantiaFisicaListar,
    exact: true,
    menuCabecalho: "Visualização de Base - Garantia Física",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoTarifaria}`,
    caminho: "ProjecaoTarifaria",
    component: Pagina.ProjecaoTarifaria,
    exact: true,
    menuCabecalho: "Projeção Tarifária - Distribuidora",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoTarifaria}/visualizar`,
    caminho: "ProjecaoTarifaria",
    component: Pagina.ListarProjecaoTarifaria,
    exact: true,
    menuCabecalho: "Visualizar Projeção Tarifária - Distribuidora",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoTarifaria}/visualizar/detalhes/:id`,
    caminho: "ProjecaoTarifaria",
    component: Pagina.ProjecaoTarifariaDetalhes,
    exact: true,
    menuCabecalho: "Projeção Tarifária - Distribuidora",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.VariaveisCDEImportar}`,
    caminho: "VariaveisCDEImportar",
    component: Pagina.VariaveisCDEImportar,
    exact: true,
    menuCabecalho: "Variáveis CDE - Importar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.VariaveisCDEImportar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.VariaveisCDEImportar,
    exact: true,
    menuCabecalho: "Importar Variáveis CDE",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.VariaveisCDEVisualizar}`,
    caminho: "VariaveisCDEVisualizar",
    component: Pagina.VariaveisCDEVisualizar,
    exact: true,
    menuCabecalho: "Variáveis CDE - Visualizar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.VariaveisCDEVisualizar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.VariaveisCDEVisualizar,
    exact: true,
    menuCabecalho: "Visualizar Variáveis CDE",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoCustosImportar}`,
    caminho: "ProjecaoCustosImportar",
    component: Pagina.ProjecaoCustosImportar,
    exact: true,
    menuCabecalho: "Projecao Custos Exceção - Importar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.ProjecaoCustosImportar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.ProjecaoCustosImportar,
    exact: true,
    menuCabecalho: "Visualizar Variáveis CDE",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoCustosVisualizar}`,
    caminho: "ProjecaoCustosVisualizar",
    component: Pagina.ProjecaoCustosVisualizar,
    exact: true,
    menuCabecalho: "Projecao Custos Exceção - Visualizar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.ProjecaoCustosVisualizar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.ProjecaoCustosVisualizar,
    exact: true,
    menuCabecalho: "Projecao Custos Exceção - Visualizar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.RateioCDEImportar}`,
    caminho: "RateioCDEImportar",
    component: Pagina.RateioCDEImportar,
    exact: true,
    menuCabecalho: "Rateio CDE - Importar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.RateioCDEImportar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.RateioCDEImportar,
    exact: true,
    menuCabecalho: "Rateio CDE - Importar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.RateioCDEVisualizar}`,
    caminho: "RateioCDEVisualizar",
    component: Pagina.RateioCDEVisualizar,
    exact: true,
    menuCabecalho: "Rateio CDE - Visualizar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.RateioCDEVisualizar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.RateioCDEVisualizar,
    exact: true,
    menuCabecalho: "Rateio CDE - Visualizar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.SerieHistoricaCenarioListagem}`,
    caminho: "SerieHistoricaCenarioListagem",
    component: Pagina.SerieHistoricaCenarioListagem,
    exact: true,
    menuCabecalho: "Listagem de Séries Históricas por Cenário",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoFatorComposto}`,
    caminho: "ProjecaoFatorComposto",
    component: Pagina.ProjecaoFatorComposto,
    exact: true,
    menuCabecalho: "Listagem de Projeção Fator Composto",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoFatorCompostoEditar}/:id?`,
    caminho: "ProjecaoFatorComposto",
    component: Pagina.ProjecaoFatorCompostoEditar,
    exact: true,
    menuCabecalho: "Edição de Projeção Fator Composto",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ValoresVigencia}`,
    caminho: "ValoresVigencia",
    component: Pagina.ValoresVigencia,
    exact: true,
    menuCabecalho: "Valores de Bandeira Tarifária",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ValoresWACC}`,
    caminho: "ValoresWACC",
    component: Pagina.ValoresWACC,
    exact: true,
    menuCabecalho: "Valores de WACC",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ValoresWACCCadastro}/:type?/:id?`,
    caminho: "ValoresWACC",
    component: Pagina.ValoresWACCCadastro,
    exact: true,
    menuCabecalho: "Valores de WACC Cadastro",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.ProjecaoInsumosRecortesVisualizar,
    caminho: "ProjecaoInsumosRecortesVisualizar",
    component: Pagina.ProjecaoInsumosRecortesVisualizar,
    exact: true,
    menuCabecalho: "Visualizar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumosRecortesAlterar}/:codigoInsumoRecorte?`,
    caminho: "ProjecaoInsumosRecortesVisualizar",
    component: Pagina.ProjecaoInsumosRecortesAlterar,
    exact: true,
    menuCabecalho: "Alterar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumosRecortesAlterarItem}/:codigoInsumoRecorte?/:codigoRecorteItem?`,
    caminho: "ProjecaoInsumosRecortesVisualizar",
    component: Pagina.ProjecaoInsumosRecortesAlterarItem,
    exact: true,
    menuCabecalho: "Visualizar",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.PcatVisualizar,
    caminho: "PcatVisualizar",
    component: Pagina.PcatVisualizar,
    exact: true,
    menuCabecalho: "Visualizar PCAT",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.PcatVisualizar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.PcatVisualizar,
    exact: true,
    menuCabecalho: "Visualizar",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.ImportarTarifaReferencia,
    caminho: "ImportarTarifaReferencia",
    component: Pagina.ImportarTarifaReferencia,
    exact: true,
    menuCabecalho: "Importar Tarifa Referencia",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.ImportarTarifaReferencia}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.ImportarTarifaReferencia,
    exact: true,
    menuCabecalho: "Importar",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.VisualizarTarifaReferencia,
    caminho: "ImportarTarifaReferencia",
    component: Pagina.VisualizarTarifaReferencia,
    exact: true,
    menuCabecalho: "Visualizar Tarifa Referencia",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.VisualizarTarifaReferencia}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.VisualizarTarifaReferencia,
    exact: true,
    menuCabecalho: "Visualizar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoTarifaria}/visualizar/detalhes/:id${RotasDTO.DetalheSucesso}/:etapa/:titulo`,
    caminho: "ProjecaoTarifaria",
    component: Pagina.DetalheSucesso,
    exact: true,
    menuCabecalho: "ProjecaoDetalheSucesso",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoTarifaria}/visualizar/detalhes/:id${RotasDTO.DetalheFalha}/:etapa/:titulo`,
    caminho: "ProjecaoTarifaria",
    component: Pagina.DetalheFalha,
    exact: true,
    menuCabecalho: "Projeção Tarifária - Distribuidora",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoTarifaria}/visualizar/detalhes/:id${RotasDTO.DetalheMercadoFaturado}/:etapa`,
    caminho: "ProjecaoTarifaria",
    component: Pagina.DetalheMercadoFaturado,
    exact: true,
    menuCabecalho: "Projeção Tarifária - Distribuidora",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoTarifaria}/visualizar/detalhes/:id${RotasDTO.DetalheTarifa}/:etapa`,
    caminho: "ProjecaoTarifaria",
    component: Pagina.DetalheTarifa,
    exact: true,
    menuCabecalho: "Projeção Tarifária - Distribuidora",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.AdicionaisCustoImportar,
    caminho: "AdicionaisCustoImportar",
    component: Pagina.AdicionaisCustoImportar,
    exact: true,
    menuCabecalho: "Importar Adicionais Custo",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.AdicionaisCustoImportar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.AdicionaisCustoImportar,
    exact: true,
    menuCabecalho: "Importar",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.AdicionaisCustoVisualizar,
    caminho: "AdicionaisCustoVisualizar",
    component: Pagina.AdicionaisCustoVisualizar,
    exact: true,
    menuCabecalho: "Visualizar Adicionais Custo",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.AdicionaisCustoVisualizar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.AdicionaisCustoVisualizar,
    exact: true,
    menuCabecalho: "Visualizar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoTarifaria}/visualizar/detalhes/:id${RotasDTO.DetalheSucesso}/:etapa/:titulo${RotasDTO.MemoriaCalculo}`,
    caminho: "ProjecaoTarifariaVisualizar",
    component: Pagina.MemoriaCalculo,
    exact: true,
    menuCabecalho: "MemoriaCalculo",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoTarifaria}/visualizar/detalhes/:id${RotasDTO.MercadoFaturado}/:etapa/:titulo${RotasDTO.MemoriaCalculo}`,
    caminho: "ProjecaoTarifariaVisualizar",
    component: Pagina.MemoriaCalculo,
    exact: true,
    menuCabecalho: "MemoriaCalculo",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoTarifaria}/visualizar/detalhes/:id${RotasDTO.DetalheTarifa}/:etapa/:titulo${RotasDTO.MemoriaCalculo}`,
    caminho: "ProjecaoTarifariaVisualizar",
    component: Pagina.MemoriaCalculo,
    exact: true,
    menuCabecalho: "MemoriaCalculo",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.GrupoProjecoes}`,
    caminho: "GruposProjecoes",
    component: Pagina.GrupoProjecoes,
    exact: true,
    menuCabecalho: "Grupos Projeções",
    autenticada: true,
    estruturada: true
  },    
  {
    path: `${RotasDTO.GrupoProjecoes}/:id`,
    caminho: "GruposProjecoes",
    component: Pagina.GrupoProjecoes,
    exact: true,
    menuCabecalho: "Grupos Projeções",
    autenticada: true,
    estruturada: true
  },  
  {
    path: `${RotasDTO.GruposProjecoes}`,
    caminho: "GruposProjecoes",
    component: Pagina.GruposProjecoes,
    exact: true,
    menuCabecalho: "Grupos Projeções",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ListaProjecoesDeGrupo}/:idgrupo/:nomegrupo/:titulocenario/:idcenario`,
    caminho: "GruposProjecoes",
    component: Pagina.ListaProjecoesDeGrupo,
    exact: true,
    menuCabecalho: "Grupos Projeções",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ExecucaoProjecaoListar}/:idgrupo/:nomegrupo/:idcenario`,
    caminho: "GruposProjecoes",
    component: Pagina.ExecucaoProjecaoListar,
    exact: true,
    menuCabecalho: "Grupos Projeções",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.GrupoProjecaoRelatorioListar}/:idgrupo/:nomegrupo/:titulocenario/:idcenario`,
    caminho: "GruposProjecoes",
    component: Pagina.GrupoProjecaoRelatorioListar,
    exact: true,
    menuCabecalho: "Grupos Projeções",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.CvaImportar,
    caminho: "CvaImportar",
    component: Pagina.CVA,
    exact: true,
    menuCabecalho: "Importar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.CvaImportar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.CVA,
    exact: true,
    menuCabecalho: "Importar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.TarifaMediaPorGrupo}`,
    caminho: "TarifaMediaPorGrupo",
    component: Pagina.TarifaMediaPorGrupo,
    exact: true,
    menuCabecalho: "TarifaMediaPorGrupo",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoBrasilListar}${RotasDTO.CDEBrasilExportar}`,
    caminho: "ProjecaoBrasilListar",
    component: Pagina.ExportarCDEBrasil,
    exact: true,
    menuCabecalho: "Projeções Brasil",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.EfeitoConsumidorGrupo}`,
    caminho: "EfeitoConsumidorGrupo",
    component: Pagina.EfeitoConsumidorGrupo,
    exact: true,
    menuCabecalho: "EfeitoConsumidorGrupo",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.GrupoProjecaoRelatorioListar}${RotasDTO.TarifaConsolidadaGrupo}/:idgrupo/:titulogrupo`,
    caminho: "GruposProjecoes",
    component: Pagina.TarifaConsolidadaGrupo,
    exact: true,
    menuCabecalho: "Grupos Projeções",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.GrupoProjecaoRelatorioListar}${RotasDTO.RevisaoParcelaB}/:idgrupo/:titulogrupo`,
    caminho: "GruposProjecoes",
    component: Pagina.RevisaoParcelaB,
    exact: true,
    menuCabecalho: "Grupos Projeções",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.GrupoProjecaoRelatorioListar}${RotasDTO.CustosProjetados}/:idgrupo/:titulogrupo`,
    caminho: "GruposProjecoes",
    component: Pagina.CustosProjetados,
    exact: true,
    menuCabecalho: "Grupos Projeções",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.GrupoProjecaoRelatorioListar}${RotasDTO.RelatorioFinanceiro}/:idgrupo/:titulogrupo`,
    caminho: "GruposProjecoes",
    component: Pagina.RelatorioFinanceiro,
    exact: true,
    menuCabecalho: "Grupos Projeções",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.GrupoProjecaoRelatorioListar}${RotasDTO.RelatorioRiscoHidrologico}/:idgrupo/:titulogrupo`,
    caminho: "GruposProjecoes",
    component: Pagina.RelatorioRiscoHidrologico,
    exact: true,
    menuCabecalho: "Grupos Projeções",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.GrupoProjecaoRelatorioListar}${RotasDTO.ResumoEnergia}/:idgrupo/:titulogrupo`,
    caminho: "GruposProjecoes",
    component: Pagina.ResumoEnergia,
    exact: true,
    menuCabecalho: "Grupos Projeções",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.GrupoProjecaoRelatorioListar}${RotasDTO.MercadoGrupo}/:idgrupo/:titulogrupo`,
    caminho: "GruposProjecoes",
    component: Pagina.MercadoGrupo,
    exact: true,
    menuCabecalho: "Grupos Projeções",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.GrupoProjecaoRelatorioListar}${RotasDTO.CustoTarifaGrupo}/:idgrupo/:titulogrupo`,
    caminho: "GruposProjecoes",
    component: Pagina.CustoTarifaGrupo,
    exact: true,
    menuCabecalho: "Grupos Projeções",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoBrasilListar}${RotasDTO.ExportarComposicaoEER}`,
    caminho: "ProjecaoBrasilListar",
    component: Pagina.ExportarComposicaoEER,
    exact: true,
    menuCabecalho: "Projeções Brasil",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.GrupoProjecaoRelatorioListar}${RotasDTO.EfetividadePorGrupo}/:idgrupo/:titulogrupo`,
    caminho: "GruposProjecoes",
    component: Pagina.EfetividadePorGrupo,
    exact: true,
    menuCabecalho: "Grupos Projeções",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.SeriesHistoricas}/exportar`,
    caminho: "SeriesHistoricas",
    component: Pagina.ExportarSerieHistorica,
    exact: true,
    menuCabecalho: "Exportação de Séries Históricas",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.AnotacoesUsuariosListar}`,
    caminho: "ProjecaoTarifaria",
    component: Pagina.AnotacoesUsuariosListar,
    exact: true,
    menuCabecalho: "Anotações de Usuários",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.AnotacoesUsuariosListar}/cadastrar-anotacao/:id`,
    caminho: "ProjecaoTarifaria",
    component: Pagina.IncluirEditarAnotacoes,
    exact: true,
    menuCabecalho: "Anotações de Usuários",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.GrupoProjecaoRelatorioListar}${RotasDTO.CustoTarifaConsolidado}/:idgrupo/:titulogrupo`,
    caminho: "GruposProjecoes",
    component: Pagina.CustoTarifaConsolidado,
    exact: true,
    menuCabecalho: "Grupos Projeções",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.GrupoProjecaoRelatorioListar}${RotasDTO.PMIX}/:idgrupo/:titulogrupo`,
    caminho: "GruposProjecoes",
    component: Pagina.PMIX,
    exact: true,
    menuCabecalho: "Grupos Projeções",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoBrasilListar}${RotasDTO.ExportarProinfaGeral}`,
    caminho: "ProjecaoBrasilListar",
    component: Pagina.ExportarProinfaGeral,
    exact: true,
    menuCabecalho: "Projeções Brasil",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoBrasilListar}${RotasDTO.CDEOrcamentoBrasilVisualizar}`,
    caminho: "ProjecaoBrasilListar",
    component: Pagina.OrcamentoCDEBrasilVisualizar,
    exact: true,
    menuCabecalho: "Projeções Brasil",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoBrasilListar}${RotasDTO.EERContratosBrasilVisualizar}`,
    caminho: "ProjecaoBrasilListar",
    component: Pagina.ContratosEERBrasilVisualizar,
    exact: true,
    menuCabecalho: "Projeções Brasil",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoBrasilListar}${RotasDTO.ProinfaContratosBrasilVisualizar}`,
    caminho: "ProjecaoBrasilListar",
    component: Pagina.ContratosProinfaBrasilVisualizar,
    exact: true,
    menuCabecalho: "Projeções Brasil",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProinfaVigencias}`,
    caminho: "ProjecaoBrasilListar",
    component: Pagina.VigenciasProinfa,
    exact: true,
    menuCabecalho: "Proinfa",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProinfaVigencias}/cadastro/:operacao/:tipovigencia`,
    caminho: "ProjecaoBrasilListar",
    component: Pagina.CadastroVigenciaProinfa,
    exact: true,
    menuCabecalho: "Proinfa",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.PagamentoMensalCDEImportar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.CDEPagamentoMensalImportar,
    exact: true,
    menuCabecalho: " ",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.PagamentoMensalCDEVisualizar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.CDEPagamentoMensalVisualizar,
    exact: true,
    menuCabecalho: " ",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.GrupoProjecaoRelatorioListar}${RotasDTO.TarifaMediaEfeitoConsumidor}/:idgrupo/:titulogrupo`,
    caminho: "GruposProjecoes",
    component: Pagina.EfeitoConsumidorTarifaMedia,
    exact: true,
    menuCabecalho: "Grupos Projeções",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}/cva/visualizar`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.CVAVisualizar,
    exact: true,
    menuCabecalho: "Importações de Insumos",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.AjusteItaipuImportar,
    caminho: "AjusteItaipuImportar",
    component: Pagina.ImportarAjusteItaipu,
    exact: true,
    menuCabecalho: "Importar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.AjusteItaipuImportar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.ImportarAjusteItaipu,
    exact: true,
    menuCabecalho: "Ajuste Custo Itaipu",
    autenticada: true,
    estruturada: true
  },
  {
    path: RotasDTO.AjusteItaipuVisualizar,
    caminho: "AjusteItaipuVisualizar",
    component: Pagina.VisualizarAjusteItaipu,
    exact: true,
    menuCabecalho: "Visualizar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.AjusteItaipuVisualizar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.VisualizarAjusteItaipu,
    exact: true,
    menuCabecalho: "Visualizar Ajuste Custo Itaipu",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.GrupoProjecaoRelatorioListar}${RotasDTO.ReceitaBandeiraExportar}/:idgrupo/:titulogrupo`,
    caminho: "GruposProjecoes",
    component: Pagina.ExportarReceitaBandeira,
    exact: true,
    menuCabecalho: "Grupos Projeções",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.GrupoProjecaoRelatorioListar}${RotasDTO.TarifaAbertaExportar}/:idgrupo/:titulogrupo`,
    caminho: "GruposProjecoes",
    component: Pagina.ExportarTarifaAberta,
    exact: true,
    menuCabecalho: "Grupos Projeções",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ListarExportacoes}`,
    caminho: "ListarExportacoes",
    component: Pagina.ExportacoesListar,
    exact: true,
    menuCabecalho: "Exportações",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ListarExportacoes}${RotasDTO.ImpactoCustoDistribuidoraExportar}`,
    caminho: "ListarExportacoes",
    component: Pagina.ExportarImpactoCustoDistribuidora,
    exact: true,
    menuCabecalho: "Exportações",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ListarExportacoes}${RotasDTO.NivelContratacaoExportar}`,
    caminho: "ListarExportacoes",
    component: Pagina.ExportarNivelContratacao,
    exact: true,
    menuCabecalho: "Exportações",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.SimulacaoEnergiaContratadaImportar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.SimulacaoEnergiaContratadaImportar,
    exact: true,
    menuCabecalho: "Importar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.EmprestimoImportar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.EmprestimoImportar,
    exact: true,
    menuCabecalho: "Importar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.EncargoMigracaoImportar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.EncargoMigracaoImportar,
    exact: true,
    menuCabecalho: "Importar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.FatorMercadoNivelImportar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.FatorMercadoNivelImportar,
    exact: true,
    menuCabecalho: "Importar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.FatorMercadoNivelVisualizar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.FatorMercadoNivelVisualizar,
    exact: true,
    menuCabecalho: "Visualizar Parâmetros de Mercado",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.MercadoReferenciaImportar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.MercadoReferenciaImportar,
    exact: true,
    menuCabecalho: "Importar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.MercadoReferenciaVisualizar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.MercadoReferenciaVisualizar,
    exact: true,
    menuCabecalho: "Visualizar Mercado Referência CDE",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.RateioCDEDivulgadoImportar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.RateioCDEDivulgadoImportar,
    exact: true,
    menuCabecalho: "Importar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.ProjecaoInsumoListar}${RotasDTO.RateioCDEDivulgadoVisualizar}`,
    caminho: "ProjecaoInsumoListar",
    component: Pagina.RateioCDEDivulgadoVisualizar,
    exact: true,
    menuCabecalho: "Visualizar Rateio CDE Divulgado",
    autenticada: true,
    estruturada: true
  },
]
