/* eslint-disable import/no-unresolved */
/* eslint-disable consistent-return */
import MICROSERVICO from "global/enumeradores/microServico";
import obterApi from "servicos/api";

const api = () => obterApi(MICROSERVICO.PROJECAOTARIFARIA);

export const obterProjecaoBrasilLista = () => {
  return api().get("v1/ProjecaoItemGeral/getall");
}

export const obterHistoricoProjecoesPorIdProjecaoBrasil = async (id) => {
  return api().get(`v1/ProjecaoItemGeral/${id}`);
}

export const obterOrcamentoCDEBrasil = async (ano, idCenario) => {
  return api().get(`v1/ProjecoesBrasil/visualizar/cdebrasil?ano=${ano}&idcenario=${idCenario}`);
}

export const obterContratosEERAtualizados = async (idCenario, anoIni) => {
  return api().get(`v1/ProjecaoItemGeral/contratos-eer-atualizados?idcenario=${idCenario}&anoini=${anoIni}`);
}

export const obterContratosProinfaAtualizados = async (idCenario, anoIni) => {
  return api().get(`v1/ProjecaoItemGeral/contratos-proinfa-atualizados?idcenario=${idCenario}&anoini=${anoIni}`);
}

export const listarFiltrarPor = () => {
  const filtros = [
    {
      key: 1,
      label: "Sucesso"
    },
    {
      key: 0,
      label: "Falha"
    }
  ];

  return filtros;
};


// métodos para as Projeções

// Projetar Custo CDE Brasil
export const projetarCustoCDEBrasil = async (idCenario, idUsuario, nomeUsuario) => {
  return api().post(`v1/ProjecoesBrasil/projetar/cdebrasil?idUsuario=${idUsuario}&NomeUsuario=${nomeUsuario}&idCenario=${idCenario}`);
}

// Atualizar Contratos EER
export const projetarAtualizarContratosEER = async (idcenario, idUsuario, nomeUsuario) => {
  return api().post(`v1/ImportarContratoEER/atualizar/valores?idcenario=${idcenario}&idUsuario=${idUsuario}&NomeUsuario=${nomeUsuario}`);
}

// Cálcular ESS Geral
export const projetarCalcularESSGeral = async (anoinicial, cenario, idUsuario, nomeUsuario) => {
  return api().get(`v1/ImportGeracaoTermica/projetar/ess/brasil?anoinicial=${anoinicial}&cenario=${cenario}&idUsuario=${idUsuario}&NomeUsuario=${nomeUsuario}`);
}
// Projetar Mercado Faturado
export const projetarMercadoFaturado = async (idUsuario, nomeUsuario,idCenario) => {
  return api().post(`v1/ProjecoesBrasil/projetar/mercadofaturadobrasil?idUsuario=${idUsuario}&NomeUsuario=${nomeUsuario}&IdCenario=${idCenario}`);
}
// Atualizar Contratos PROINFA
export const projetarAtualizarContratosPROINFA = async (anoreferencia, idCenario, idUsuario, nomeUsuario) => {
  return api().post(`v1/ImportarContratosProInfa/atualizarvalores?anoreferencia=${anoreferencia}&idcenario=${idCenario}&idUsuario=${idUsuario}&NomeUsuario=${nomeUsuario}`);
}

export const projetarTarifaAngra = async (idUsuario, nomeUsuario,idCenario) => {
  return api().post(`v1/ProjecoesBrasil/projetar/tarifaangra?idUsuario=${idUsuario}&NomeUsuario=${nomeUsuario}&IdCenario=${idCenario}`);
}

// Projetar Tarifa para Cotas de Garantia Física
export const projetarTarifaParaCotasGarantiaFisica = async (idUsuario, nomeUsuario,idCenario) => {
  return api().get(`v1/ProjecoesBrasil/projetar/tarifa/cgf?idUsuario=${idUsuario}&NomeUsuario=${nomeUsuario}&IdCenario=${idCenario}`);
}

export const ExecutarProjecao = (codigo, idCenario, ano, idUsuario, nomeUsuario) => {
  switch (codigo) {
    case "CDE_GERAL":
      return projetarCustoCDEBrasil(idCenario, idUsuario, nomeUsuario);
    case "EER_GERAL":
      return projetarAtualizarContratosEER(idCenario, idUsuario, nomeUsuario);
    case "ESS_GERAL":
      return projetarCalcularESSGeral(ano, idCenario, idUsuario, nomeUsuario);
    case "MERCADO_BRASIL":
      return projetarMercadoFaturado(idUsuario, nomeUsuario,idCenario);
    case "PROINFA":
      return projetarAtualizarContratosPROINFA(ano, idCenario, idUsuario, nomeUsuario);
    case "TARIFA_ANGRA":
      return projetarTarifaAngra(idUsuario, nomeUsuario,idCenario);
    case "TARIFA_CGF":
      return projetarTarifaParaCotasGarantiaFisica(idUsuario, nomeUsuario,idCenario);
    default:

  }
}