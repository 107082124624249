import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";

// Componentes
import { Card, Divider, Grid } from "@mui/material";
import Loader from "componentes/loader";
import Botao from "componentes/botao";
import BotaoRetornarListagem from "componentes/botaoRetornarListagem";
import MaterialInputMascara from "componentes/inputTextoMascara";

// Rotas
import { RotasDTO } from "../../../../global/rotas/rotasUrlDto";

// Styles
import { useStyles } from "./style";

// Helper
import processamentoFaturaMLHelper from "../listagem/helper";

const ProcessamentoFaturaDetalhes = () => {
    const { unidadeid, mes, ano } = useParams();
    const history = useHistory();
    const classes = useStyles();

    const [dados, setDados] = useState();
    const [nomeUnidade, setNomeUnidade] = useState();
    const [consumoP, setConsumoP] = useState();
    const [consumoFP, setConsumoFP] = useState();
    const [demandaContrP, setDemandaContrP] = useState();
    const [demandaContrFP, setDemandaContrFP] = useState();
    const [demandaRegP, setDemandaRegP] = useState();
    const [demandaRegFP, setDemandaRegFP] = useState();
    const [pisCofins, setPisCofins] = useState();
    const [icms, setIcms] = useState();
    const [cosip, setCosip] = useState();
    const [reativos, setReativos] = useState();
    const [ajustes, setAjustes] = useState();
    const [ajustesProximoMes, setAjustesProximoMes] = useState();

    const [carregandoEnviarAjuste, setCarregandoEnviarAjuste] = useState(false);
    const [carregandoCalcularDiferenca, setCarregandoCalcularDiferenca] = useState();
    const [desabilitarBotoes, setDesabilitarBotoes] = useState(false);

    const setBotoesCarregando = (status) => {
        setCarregandoCalcularDiferenca(status);
        setCarregandoEnviarAjuste(status);
    };

    const parametros = { 
        consumoPonta: consumoP !== "" ? consumoP : 0,
        consumoForaPonta: consumoFP !== "" ? consumoFP : 0,
        demandaContratadaPonta: demandaContrP !== "" ? demandaContrP : 0,
        demandaContratadaForaPonta: demandaContrFP !== "" ? demandaContrFP : 0,
        demandaRegistradaPonta: demandaRegP !== "" ? demandaRegP : 0,
        demandaRegistradaForaPonta: demandaRegFP !== "" ? demandaRegFP : 0,
        pisCofins: pisCofins !== "" ? pisCofins : 0,
        icms: icms !== "" ? icms : 0,
        cosip: cosip !== "" ? cosip : 0,
        reativos: reativos !== "" ? reativos : 0,
        ajustes: ajustes !== "" ? ajustes : 0
    }

    const enviarAjusteParaProximoMes = async () => {
        setCarregandoEnviarAjuste(true);
        parametros.ajustesProximoMes = ajustesProximoMes !== "" ? ajustesProximoMes : 0;
        await processamentoFaturaMLHelper.AtualizarProcessamentoFaturaML(unidadeid, mes, ano, parametros);
        setCarregandoEnviarAjuste(false);
    };

    const CalcularDiferenca = async () => {
        setCarregandoCalcularDiferenca(true);

        const resultado = await processamentoFaturaMLHelper.CalcularDiferencaFaturadoEProcessado(unidadeid, mes, ano, parametros);

        if (resultado?.status === 200)
            setAjustesProximoMes(resultado?.data?.diferenca)

        setCarregandoCalcularDiferenca(false);
    };

    function formatarValorSalvar(dado) {

        const formatado = dado
            ? dado.replace(" MWh", "")
                ?.replace(" kW", "")
                ?.replace("R$ ", "")
                ?.replace("%", "")
                ?.replaceAll(".", "")
                ?.replace(",", ".")
                ?.trim()
            : dado;

        return formatado;
    }

    const obterCalculoRealizadoEProcessado = useCallback(async () => {
        setBotoesCarregando(true);
        const resultado = await processamentoFaturaMLHelper.ObterCalculoRealizadoEProcessado(unidadeid, mes, ano);

        if (resultado?.data?.statusCodes === 204)
            history.push(RotasDTO.ProcessamentoFaturaML);

        setDados(resultado?.data)
        setNomeUnidade(resultado?.data?.nomeUnidadeConsumidora);
        setConsumoP(resultado?.data?.resumoCalculoProcessado.consumoPonta);
        setConsumoFP(resultado?.data?.resumoCalculoProcessado.consumoForaPonta);
        setDemandaContrP(resultado?.data?.resumoCalculoProcessado.demandaContratadaPonta);
        setDemandaContrFP(resultado?.data?.resumoCalculoProcessado.demandaContratadaForaPonta);
        setDemandaRegP(resultado?.data?.resumoCalculoProcessado.demandaRegistradaPonta);
        setDemandaRegFP(resultado?.data?.resumoCalculoProcessado.demandaRegistradaForaPonta);
        setPisCofins(resultado?.data?.resumoCalculoProcessado.pisCofins);
        setIcms(resultado?.data?.resumoCalculoProcessado.icms);
        setCosip(resultado?.data?.resumoCalculoProcessado.cosip);
        setReativos(resultado?.data?.resumoCalculoProcessado.reativos);
        setAjustes(resultado?.data?.resumoCalculoProcessado.ajustes);
        setAjustesProximoMes(resultado?.data?.resumoCalculoProcessado.ajustesProximoMes);
        setDesabilitarBotoes(resultado?.data?.existePreFaturamentoMesSeguinte);
        setBotoesCarregando(false);
    }, []);

    useEffect(() => {
        if (unidadeid && mes && ano) obterCalculoRealizadoEProcessado();
    }, [unidadeid, mes, ano, obterCalculoRealizadoEProcessado]);

    return (
        <>
            <Card className={classes.cardCadastro}>
                <Grid container spacing={4} className={classes.container}>
                    <Grid item xs={6} className="font-weight-bold">
                        {nomeUnidade}
{" "}
-
{mes}
/
{ano}
                    </Grid>
                    <BotaoRetornarListagem urlListagem={RotasDTO.ProcessamentoFaturaML} />
                    <Grid item xs={6} className="font-weight-bold">
                        <Grid item lg={7} md={12} sm={12}>
                            Dados Faturados
                        </Grid>
                        <Grid item lg={7} md={12} sm={12}>
                            <MaterialInputMascara
                                type="text"
                                id="consumoPontaRealizado"
                                name="consumoPontaRealizado"
                                sufixo=" MWh"
                                label="Consumo P (MWh)"
                                renderIconShowHide={false}
                                defaultValue={dados?.resumoCalculoRealizado?.consumoPonta}
                                disabled
                            />
                        </Grid>
                        <Grid item lg={7} md={3} sm={6}>
                            <MaterialInputMascara
                                type="text"
                                id="consumoForaPontaRealizado"
                                name="consumoForaPontaRealizado"
                                defaultValue={dados?.resumoCalculoRealizado?.consumoForaPonta}
                                sufixo=" MWh"
                                label="Consumo FP (MWh)"
                                renderIconShowHide={false}
                                disabled
                            />
                        </Grid>
                        <Grid item lg={7} md={3} sm={6}>
                            <MaterialInputMascara
                                type="text"
                                id="demandaContratadaPontaRealizado"
                                name="demandaContratadaPontaRealizado"
                                defaultValue={dados?.resumoCalculoRealizado?.demandaContratadaPonta}
                                sufixo=" kW"
                                label="Demanda contr. P (kW)"
                                renderIconShowHide={false}
                                disabled
                            />
                        </Grid>
                        <Grid item lg={7} md={3} sm={6}>
                            <MaterialInputMascara
                                type="text"
                                id="demandaContratadaForaPontaRealizado"
                                name="demandaContratadaForaPontaRealizado"
                                defaultValue={dados?.resumoCalculoRealizado?.demandaContratadaForaPonta}
                                sufixo=" kW"
                                label="Demanda contr. FP (kW)"
                                renderIconShowHide={false}
                                disabled
                            />
                        </Grid>
                        <Grid item lg={7} md={3} sm={6}>
                            <MaterialInputMascara
                                type="text"
                                id="demandaRegistradaPontaRealizado"
                                name="demandaRegistradaPontaRealizado"
                                defaultValue={dados?.resumoCalculoRealizado?.demandaRegistradaPonta}
                                sufixo=" kW"
                                label="Demanda reg. P (kW)"
                                renderIconShowHide={false}
                                disabled
                            />
                        </Grid>
                        <Grid item lg={7} md={3} sm={6}>
                            <MaterialInputMascara
                                type="text"
                                id="demandaRegistradaForaPontaRealizado"
                                name="demandaRegistradaForaPontaRealizado"
                                defaultValue={dados?.resumoCalculoRealizado?.demandaRegistradaForaPonta}
                                sufixo=" kW"
                                label="Demanda reg. FP (kW)"
                                renderIconShowHide={false}
                                disabled
                            />
                        </Grid>
                        <Grid item lg={7} md={3} sm={6}>
                            <MaterialInputMascara
                                type="text"
                                id="pisCofinsRealizado"
                                name="pisCofinsRealizado"
                                defaultValue={dados?.resumoCalculoRealizado?.pisCofins}
                                sufixo=" %"
                                label="PisCofins"
                                renderIconShowHide={false}
                                disabled
                            />
                        </Grid>
                        <Grid item lg={7} md={3} sm={6}>
                            <MaterialInputMascara
                                type="text"
                                id="icmsRealizado"
                                name="icmsRealizado"
                                defaultValue={dados?.resumoCalculoRealizado?.icms}
                                sufixo=" %"
                                label="ICMS"
                                renderIconShowHide={false}
                                disabled
                            />
                        </Grid>
                        <Grid item lg={7} md={3} sm={6}>
                            <MaterialInputMascara
                                type="text"
                                id="cosipRealizado"
                                name="cosipRealizado"
                                defaultValue={dados?.resumoCalculoRealizado?.cosip}
                                prefixo="R$ "
                                label="Cosip"
                                renderIconShowHide={false}
                                disabled
                            />
                        </Grid>
                        <Grid item lg={7} md={3} sm={6}>
                            <MaterialInputMascara
                                type="text"
                                id="reativosRealizado"
                                name="reativosRealizado"
                                defaultValue={dados?.resumoCalculoRealizado?.reativos}
                                prefixo="R$ "
                                label="Reativos"
                                renderIconShowHide={false}
                                disabled
                            />
                        </Grid>
                        <Grid item lg={7} md={3} sm={6}>
                            <MaterialInputMascara
                                type="text"
                                id="ajustesRealizado"
                                name="ajustesRealizado"
                                defaultValue={dados?.resumoCalculoRealizado?.ajustes}
                                prefixo="R$ "
                                label="Ajustes"
                                renderIconShowHide={false}
                                disabled
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={6} className="font-weight-bold">
                        <Grid item lg={7} md={12} sm={12}>
                            Dados da Fatura Processada
                        </Grid>
                        <Grid item lg={7} md={12} sm={12}>
                            <MaterialInputMascara
                                type="text"
                                id="consumoPontaProcessado"
                                name="consumoPontaProcessado"
                                sufixo=" MWh"
                                label="Consumo P (MWh)"
                                renderIconShowHide={false}
                                defaultValue={dados?.resumoCalculoProcessado?.consumoPonta}
                                onBlur={(e) =>
                                    setConsumoP(formatarValorSalvar(e.target.value))
                                }                                
                            />
                        </Grid>
                        <Grid item lg={7} md={3} sm={6}>
                            <MaterialInputMascara
                                type="text"
                                id="consumoForaPontaProcessado"
                                name="consumoForaPontaProcessado"
                                defaultValue={dados?.resumoCalculoProcessado?.consumoForaPonta}
                                sufixo=" MWh"
                                label="Consumo FP (MWh)"
                                renderIconShowHide={false}
                                onBlur={(e) =>
                                    setConsumoFP(formatarValorSalvar(e.target.value))
                                }                                
                            />
                        </Grid>
                        <Grid item lg={7} md={3} sm={6}>
                            <MaterialInputMascara
                                type="text"
                                id="demandaContratadaPontaProcessado"
                                name="demandaContratadaPontaProcessado"
                                defaultValue={dados?.resumoCalculoProcessado?.demandaContratadaPonta}
                                sufixo=" kW"
                                label="Demanda contr. P (kW)"
                                renderIconShowHide={false}
                                onBlur={(e) =>
                                    setDemandaContrP(formatarValorSalvar(e.target.value))
                                }
                            />
                        </Grid>
                        <Grid item lg={7} md={3} sm={6}>
                            <MaterialInputMascara
                                type="text"
                                id="demandaContratadaForaPontaProcessado"
                                name="demandaContratadaForaPontaProcessado"
                                defaultValue={dados?.resumoCalculoProcessado?.demandaContratadaForaPonta}
                                sufixo=" kW"
                                label="Demanda contr. FP (kW)"
                                renderIconShowHide={false}
                                onBlur={(e) =>
                                    setDemandaContrFP(formatarValorSalvar(e.target.value))
                                }                                
                            />
                        </Grid>
                        <Grid item lg={7} md={3} sm={6}>
                            <MaterialInputMascara
                                type="text"
                                id="demandaRegistradaPontaProcessado"
                                name="demandaRegistradaPontaProcessado"
                                defaultValue={dados?.resumoCalculoProcessado?.demandaRegistradaPonta}
                                sufixo=" kW"
                                label="Demanda reg. P (kW)"
                                renderIconShowHide={false}
                                onBlur={(e) =>
                                    setDemandaRegP(formatarValorSalvar(e.target.value))
                                }                                
                            />
                        </Grid>
                        <Grid item lg={7} md={3} sm={6}>
                            <MaterialInputMascara
                                type="text"
                                id="demandaRegistradaForaPontaProcessado"
                                name="demandaRegistradaForaPontaProcessado"
                                defaultValue={dados?.resumoCalculoProcessado?.demandaRegistradaForaPonta}
                                sufixo=" kW"
                                label="Demanda reg. FP (kW)"
                                renderIconShowHide={false}
                                onBlur={(e) =>
                                    setDemandaRegFP(formatarValorSalvar(e.target.value))
                                }
                            />
                        </Grid>
                        <Grid item lg={7} md={3} sm={6}>
                            <MaterialInputMascara
                                type="text"
                                id="pisCofinsProcessado"
                                name="pisCofinsProcessado"
                                defaultValue={dados?.resumoCalculoProcessado?.pisCofins}
                                sufixo=" %"
                                label="PisCofins"
                                renderIconShowHide={false}
                                onBlur={(e) =>
                                    setPisCofins(formatarValorSalvar(e.target.value))
                                }
                            />
                        </Grid>
                        <Grid item lg={7} md={3} sm={6}>
                            <MaterialInputMascara
                                type="text"
                                id="icmsProcessado"
                                name="icmsProcessado"
                                defaultValue={dados?.resumoCalculoProcessado?.icms}
                                sufixo=" %"
                                label="ICMS"
                                renderIconShowHide={false}
                                onBlur={(e) =>
                                    setIcms(formatarValorSalvar(e.target.value))
                                }
                            />
                        </Grid>
                        <Grid item lg={7} md={3} sm={6}>
                            <MaterialInputMascara
                                type="text"
                                id="cosipProcessado"
                                name="cosipProcessado"
                                defaultValue={dados?.resumoCalculoProcessado?.cosip}
                                prefixo="R$ "
                                label="Cosip"
                                renderIconShowHide={false}
                                onBlur={(e) =>
                                    setCosip(formatarValorSalvar(e.target.value))
                                }
                            />
                        </Grid>
                        <Grid item lg={7} md={3} sm={6}>
                            <MaterialInputMascara
                                type="text"
                                id="reativosProcessado"
                                name="reativosProcessado"
                                defaultValue={dados?.resumoCalculoProcessado?.reativos}
                                prefixo="R$ "
                                label="Reativos"
                                renderIconShowHide={false}
                                onBlur={(e) =>
                                    setReativos(formatarValorSalvar(e.target.value))
                                }
                            />
                        </Grid>
                        <Grid item lg={7} md={3} sm={6}>
                            <MaterialInputMascara
                                type="text"
                                id="ajustesProcessado"
                                name="ajustesProcessado"
                                defaultValue={dados?.resumoCalculoProcessado?.ajustes}
                                prefixo="R$ "
                                label="Ajustes"
                                renderIconShowHide={false}
                                onBlur={(e) =>
                                    setAjustes(formatarValorSalvar(e.target.value))
                                }
                            />
                        </Grid>
                        <Grid item lg={7} md={3} sm={6}>
                            <Loader loading={carregandoCalcularDiferenca}>
                                <Botao
                                    label="Calcular diferença faturamento"
                                    className={classes.button}
                                    onClick={() => CalcularDiferenca()}
                                    disabled={desabilitarBotoes}
                                />
                            </Loader>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider className={classes.divider} />
                <Grid container spacing={4} className={classes.container}>
                    <Grid item lg={3} md={3} sm={6}>
                        <MaterialInputMascara
                            type="text"
                            id="ajustesProximoMes"
                            name="ajustesProximoMes"
                            defaultValue={ajustesProximoMes}
                            prefixo="R$ "
                            label="Ajustes financeiros para o próximo mês"
                            renderIconShowHide={false}
                            onBlur={(e) =>
                                setAjustesProximoMes(formatarValorSalvar(e.target.value))
                            }
                        />
                    </Grid>
                    <Grid item lg={3} md={4} sm={6} className={classes.containerSalvar}>
                        <Loader loading={carregandoEnviarAjuste}>
                            <Botao
                                label="Enviar ajuste para o próximo mês"
                                className={classes.button}
                                onClick={() => enviarAjusteParaProximoMes()}
                                disabled={desabilitarBotoes}
                            />
                        </Loader>
                    </Grid>
                </Grid>
            </Card>
        </>
    );
};

export default ProcessamentoFaturaDetalhes;
