import { makeStyles } from "@mui/styles";
import theme from "themes";
import { Base } from "../../../../componentes/cores";

export const useStyles = makeStyles({
  container: {
    "& .MuiInputBase-root": {
      height: "46px"
    },
    "& .MuiFormLabel-root": {
      lineHeight: "1"
    }
  },
  containerTelefone: {
    "& .MuiInputBase-input": {
      height: "7px"
    }
  },
  search: {
    "& label.Mui-focused": {
      color: `${theme.color.secondaryBorderColor} !important`
    },
    "& .MuiInput-underline:after": {
      borderColor: `${theme.color.secondaryBorderColor} !important`
    },
    "& .MuiFormLabel-root": {
      color: `${theme.color.secondaryBorderColor} !important`
    },
    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-input": {
        color: `${theme.color.secondaryBorderColor} !important`
      },
      "& .MuiInputAdornment-root": {
        color: `${theme.color.secondaryBorderColor} !important`
      },
      "& fieldset": {
        borderColor: `${theme.color.secondaryBorderColor} !important`
      },
      "&:hover fieldset": {
        borderColor: `${theme.color.secondaryBorderColor} !important`
      },
      "&.Mui-focused fieldset": {
        borderColor: `${theme.color.secondaryBorderColor} !important`
      }
    }
  },
  itemFiltro: {
    display: "flex",
    justifyContent: "flex-end"
  },
  buttonFiltro: {
    fontSize: "18px",
    maxWidth: "220px"
  },
  inputTelefone: {
    maxWidth: "80%",
    width: "100%",
    "&.Mui-error": {
      borderColor: Base.RedError,
      color: Base.RedError
    }
  },
  containerAtivo: {
    margin: "-16px 0 -16px 16px"
  },
  divider: {
    margin: "32px 18px 16px"
  },
  containerSalvar: {
    margin: "0 0 24px"
  }
});
