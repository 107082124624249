import produce from "immer";

const inicial = {
  dadosEnergia: {},
  dadosHistorico: [],
  mostrarHistorico: false
};

export default function demo(state = inicial, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "@demo/salvarDadosEnergia":
        draft.dadosEnergia = action?.payload ?? {};
        break;
      case "@demo/salvarHistorico":
        draft.dadosHistorico = action?.payload ?? [];
        break;
      case "@demo/mostrarHistorico":
        draft.mostrarHistorico = action?.payload;
        break;
      default:
        draft = inicial;
        break;
    }
  });
}
