import { Box, Card, Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import SelectAutoComplete from "../../../componentes/selectAutocomplete";
import Loader from "../../../componentes/loader";
import ModalConfirmacao from "../../../componentes/modalConfirmacao";
import { buscarDistribuidoras } from "../../../servicos/distribuidorasServico";
import { obterProcuracao } from "../../../servicos/clienteDocumento";
import { store } from "../../../global/redux";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";

const DownloadProcuracao = () => {
  const [carregandoDistribuidora, setCarregandoDistribuidora] = useState(false);
  const [distribuidoraId, setDistribuidoraId] = useState(null);
  const [listaDistribuidora, setListaDistribuidora] = useState([]);
  const [modalConfirmacao, setModalConfirmacao] = useState(false);
  const [mensagemModal, setMensagemModal] = useState("");

  useEffect(() => {
    obterDistribuidora();
  }, []);

  const obterDistribuidora = async () => {
    try {
      setCarregandoDistribuidora(true);
      const lista = await buscarDistribuidoras();
      if (lista?.status === 200 && lista?.data) {
        setListaDistribuidora(lista?.data);
      }
      setCarregandoDistribuidora(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoDistribuidora(false);
    }
  };

  const obterProcuracaoPorDistribuidora = async (
    idDistribuidora,
    nomeFantasia
  ) => {
    try {
      setCarregandoDistribuidora(true);
      const procuracao = await obterProcuracao(idDistribuidora);
      if (procuracao?.data?.validationResult?.errors[0]?.errorCode !== "204") {
        setMensagemModal(
          `Procurações para distribuidora ${nomeFantasia} enviadas por e-mail!`
        );
      } else {
        setMensagemModal(
          `Não foram encontradas procurações para distribuidora ${nomeFantasia}!`
        );
      }

      setModalConfirmacao(true);
      setCarregandoDistribuidora(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoDistribuidora(false);
    }
  };

  return (
    <>
      <ModalConfirmacao
        item={modalConfirmacao}
        mensagem={<h5>{mensagemModal}</h5>}
        esconderBotaoCancelar
        labelBotaoConfirmar="Fechar"
        onConfirmar={() => {
          setModalConfirmacao(false);
        }}
      />
      <Card>
        <Grid container space={2} p={2}>
          <Grid item xs={12}>
            <Box fontSize="24px" fontWeight="700" color="#332053" mb={4}>
              Download Procurações
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Loader loading={carregandoDistribuidora}>
              <SelectAutoComplete
                disableClearable
                disableOpenOnFocus
                id="distribuidoraId"
                name="distribuidoraId"
                options={listaDistribuidora}
                label="Selecione a Distribuidora"
                getOptionLabel={(option) => option?.nomeFantasia}
                onChange={(e, item) => {
                  setDistribuidoraId(item?.id);
                  if (!item?.id) return;
                  obterProcuracaoPorDistribuidora(item?.id, item?.nomeFantasia);
                }}
                value={distribuidoraId}
                valueName="nomeFantasia"
              />
            </Loader>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default DownloadProcuracao;
