import RetornoEndpointDto from "../../../../global/dto/retornoEndpointDto";
import {
  listarAssociacaoLuz,
  atualizarAssociacaoLuz
} from "../../../../servicos/associacaoLuzServico";

export default class AssociacaoLuzHelper {
  static async listarAssociacaoLuz(filtro, setCarregandoAssociacao) {
    try {
      setCarregandoAssociacao(true);
      const resultado = await listarAssociacaoLuz(filtro);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Associação não encontrados!");

      return RetornoEndpointDto.Sucesso(
        "Associação obtida com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Erro ao buscar associação");
    } finally {
      setCarregandoAssociacao(false);
    }
  }

  static async atualizarAssociacaoLuz(params, setCarregandoAssociacao) {
    try {
      setCarregandoAssociacao(true);
      const resultado = await atualizarAssociacaoLuz(params);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Associação não encontrados!");

      return RetornoEndpointDto.Sucesso(
        "Associação atualizado com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Erro ao atualizado associação");
    } finally {
      setCarregandoAssociacao(false);
    }
  }
}
