export default [
    {
      nome: "Todos",
      valor: "0"
    },
    {
      nome: "Janeiro",
      valor: 1
    },
    {
      nome: "Fevereiro",
      valor: 2
    },
    {
      nome: "Março",
      valor: 3
    },
    {
      nome: "Abril",
      valor: 4
    },
    {
      nome: "Maio",
      valor: 5
    },
    {
      nome: "Junho",
      valor: 6
    },
    {
      nome: "Julho",
      valor: 7
    },
    {
      nome: "Agosto",
      valor: 8
    },
    {
      nome: "Setembro",
      valor: 9
    },
    {
      nome: "Outubro",
      valor: 10
    },
    {
      nome: "Novembro",
      valor: 11
    },
    {
      nome: "Dezembro",
      valor: 12
    }
  ]