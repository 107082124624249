export default class FaturaDistribuidoraDto {
  constructor(
    unidadeId,
    unidadeNomeRazaoSocial,
    unidadeCnpjCpf,
    unidadeNumeroInstalacao,
    dataRegistro,
    solicitacaoCancelamentoId
  ) {
    this.unidadeId = unidadeId;
    this.unidadeNomeRazaoSocial = unidadeNomeRazaoSocial;
    this.unidadeCnpjCpf = unidadeCnpjCpf;
    this.unidadeNumeroInstalacao = unidadeNumeroInstalacao;
    this.dataRegistro = dataRegistro;
    this.solicitacaoCancelamentoId = solicitacaoCancelamentoId;
  }
}
