import { makeStyles } from "@mui/styles";
import theme from "themes";

export const useStyles = makeStyles({
  squareWidth: {
    height: "54px !important",
    width: "54px !important"
  },
  treeViewItemExpand: {
    color: `${theme.color.treeItemArrowColor} !important`,
    justifyContent: "end !important",
    padding: 0,
    position: "relative !important"
  },
  treeViewBorder: {
    borderLeftColor: `${theme.color.treeItemArrowColor} !important`,
    borderLeftWidth: "6px",
    borderLeftStyle: "solid"
  },
  gridItem: {
    flexBasis: "100% !important",
    fontSize: "2.1875rem",
    margin: "0 !important",
    maxWidth: "100% !important",
    "pointer-events": "all",
    width: "54px !important"
  },
  treeItem: {
    backgroundColor: `${theme.color.bgItemExpansivel} !important`,
    color: `${theme.color.treeItemArrowColor} !important`,
    height: "54px !important",
    opacity: (props) => (props.ativo ? 1 : 0.5)
  },
  wrapper: {
    alignItems: "center !important",
    display: "flex !important",
    height: "100% !important",
    minHeight: "100% !important"
  },
  start: {
    alignItems: "flex-start !important"
  },
  center: {
    justifyContent: "center !important"
  },
  left: {
    justifyContent: "start !important",
    paddingLeft: "20px !important"
  },
  right: {
    justifyContent: "flex-end !important"
  }
});
