export default class ListaDto {
  constructor(
    TipoRelatorioId, 
    TipoRelatorioNome, 
    NomeCategoriaRelatorio,
    NotificacaoPorEmail, 
    NotificacaoPelaPlataforma
  ) 
  {
    this.id = TipoRelatorioId;
    this.nome = TipoRelatorioNome;
    this.categoriaRelatorio = NomeCategoriaRelatorio;
    this.notificacaoPorEmail = NotificacaoPorEmail;
    this.notificacaoPelaPlataforma = NotificacaoPelaPlataforma;
  }
}
