import produce from "immer";

const inicial = {
  rotaAtual: {},
  menuSelecionadoColecao: []
};

export default function rotas(state = inicial, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "@rotas/salvarRotaAtual": {
        draft.rotaAtual = action.payload;
        break;
      }
      case "@rotas/salvarMenuSelecionadoColecao": {
        draft.menuSelecionadoColecao = action.payload;
        break;
      }
      default:
        break;
    }
  });
}
