import { makeStyles } from "@mui/styles";
import styled from "styled-components";
import theme from "themes";
import { Button, Box } from "@mui/material";
import { Base } from "../../../../componentes/cores";

export const useStyles = makeStyles({
  fundoCelula: {
    width: "80%",
    height: "100%",
    borderRadius: "4px",
    textAlign: "center"
  },
  fundoBotoes: {
    backgroundColor: theme.color.bgTabela
  },
  botaoCentral: {
    borderLeft: `3px ${theme.color.secondaryBackgroud} solid`
  },
  botao: {
    color: `${theme.color.secondaryText} !important`,
    width: "100%",
    height: "100%",
    justifyContent: "center",
    justifyItems: "center",
    alignContent: "center",
    alignItems: "center"
  },
  inativo: {
    opacity: 0.5
  },
  ativo: {
    opacity: 1
  },
  inputData: {
    "& .MuiInputBase-root": {
      fontSize: "14px",
      padding: "0 !important",
      width: "auto !important"
    },
    "& input": {
      padding: "8px 0 8px 8px"
    },
    "& .MuiInputAdornment-root": {
      width: "auto !important",
      "& button": {
        padding: "0 !important",
        marginRight: "4px !important",
        "& svg": {
          width: "20px !important",
          height: "20px !important",
          fontSize: "20px !important"
        }
      }
    },
    "& .MuiFormLabel-root": {
      fontSize: "14px"
    }
  },
  botaoBuscar: {
    background: "transparent !important",
    color: `${theme.color.secondary} !important`,
    fontSize: "16px",
    maxWidth: "100%",
    fontWeight: "700",
    marginTop: "5px",
    lineHeight: "19.2px",
    padding: "8px 32px",
    margin: "0 !important",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    width: "100% !important",
    "&:disabled": {
      color: "#9F9F9F !important",
      borderColor: "#9F9F9F !important",
      opacity: "1 !important"
    }
  },
  textoBotao: {
    textAlign: "left",
    width: "100%",
    lineHeight: "16px"
  }
});

export const Celula = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.color.primary} !important;
`;

export const CelulaStatus = styled.div`
  background-color: ${(props) =>
    props.corFundo ? props.corFundo : theme.color.bgTabela};
  color: ${(props) => (props.corTexto ? props.corTexto : theme.color.bgTabela)};
  border: 1px solid
    ${(props) => (props.corTexto ? props.corTexto : theme.color.bgTabela)};
  text-align: center;
  height: auto;
  padding: 0px 4px;
  border-radius: 4px;
`;

export const CelulaCheckbox = styled.div`
  background-color: ${(props) =>
    props.cor ? props.cor : theme.color.bgTabela};
  width: 100%;
  .MuiButtonBase-root {
    color: ${theme.color.secondary} !important;
    padding: 0 8px 0 0 !important;
  }
  label,
  .MuiFormControlLabel-label {
    font-size: 14px !important;
    margin-bottom: 0 !important;
  }
  .Mui-disabled {
    color: #a6a6a6 !important;
  }
`;

export const BotaoMenu = styled(Button)`
  color: ${theme.color.primary} !important;
  text-transform: none !important;
  font-weight: 700 !important;
  padding: 0 !important;
  width: 100% !important;
`;

export const Menu = styled(Box)`
  background: ${Base.White};
  z-index: 1;
  position: absolute;
  right: 10px;
  width: 400px !important;
  padding: 16px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px 2px 8px 0px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  div {
    width: auto !important;
  }
`;
