import React from "react";
import PropTypes from "prop-types";
import { generate } from "shortid";

import { Grid, Button } from "@mui/material";
import { EditOutlined, Delete } from "@mui/icons-material";

import { HtmlTooltip } from "paginas/Graficos/ConsumoAtual/style";
import { useStyles } from "./style";
import enumerador from "../enumerador";

const ColunaMultiplosBotoes = ({ onClick, parametros }) => {
  const classes = useStyles();

  const onClickDownload = () => onClick(enumerador.Edicao, parametros);
  const onClickDetalhes = () => onClick(enumerador.Deletar, parametros);

  return (
    <Grid container spacing={0} className={classes.fundoCelula}>

      <Grid item xs={6}>
        <Button
          key={generate()}
          onClick={onClickDownload}
          className={`text-white ${classes.botao}`}
        >
          <HtmlTooltip title="Editar">
            <EditOutlined />
          </HtmlTooltip>
        </Button>
      </Grid>

      <Grid item xs={6} className={classes.botaoCentral}>
        <Button 
        key={generate()} 
        onClick={onClickDetalhes}
        className={`text-white ${classes.botao}`}
        >
          <HtmlTooltip title="Deletar">
          <Delete />
          </HtmlTooltip>
        </Button>
      </Grid>

    </Grid>
  );
};

ColunaMultiplosBotoes.propTypes = {
  onClick: PropTypes.oneOfType([PropTypes.func]).isRequired,
  parametros: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired
};

export default ColunaMultiplosBotoes;
