import { saveAs } from "file-saver";
import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";
import RetornoEndpointDto from "../global/dto/retornoEndpointDto";

const api = (log) => obterApi(MICROSERVICO.DELTAPROCESSAMENTO, log);

export const digitalizacaoFaturaDeltaProcessamentoOcr = (
  fileName,
  externoId,
  empresaCodigo,
  colaboradorId,
  distribuidoraIdModal
) => {
  const params = {
    fileName,
    externoId,
    empresaCodigo,
    colaboradorId
  };

  if (distribuidoraIdModal) {
    params.distribuidoraId = distribuidoraIdModal;
  }
  params.origemFront = "AreaLogada";
  return api().post("/fatura/importar-bestdeal-s3-async", params);
};

export const obterFaturaDeltaProcessamentoOcr = (id) => {
  return api().get(`/fatura/consultar-bestdeal-async/${id}`);
};

export const atualizarExternoIdDeltaProcessamento = (params) => {
  const log = {
    nestedField: "put_fatura_atualizaExternoId",
    tipo: "atualizaLog",
    index: `cotacao-gd-${process.env.REACT_APP_ENVIRONMENT}`
  };

  const { externoIdOrigem, externoIdDestino } = params || {};

  delete params.externoIdOrigem;
  delete params.externoIdDestino;

  const queryString = new URLSearchParams(params).toString();

  return api(log).put(
    `/fatura/atualizar-externoId/${externoIdOrigem}/${externoIdDestino}?${queryString}`
  );
};

// eslint-disable-next-line consistent-return
export const baixarArquivoFatura = async (faturaExternoId) => {
  const url = `/fatura/buscar-arquivo-id/${faturaExternoId}`;

  try {
    const response = await api().get(url);

    if (response.status === 204)
      return RetornoEndpointDto.Erro("Ocorreu uma falha ao obter o arquivo");

    const blob = new Blob([response.data.arquivoOriginal]);

    saveAs(blob, response.data.arquivoNome);
  } catch (error) {
    return RetornoEndpointDto.Erro("Ocorreu uma falha ao obter o arquivo");
  }
};

export const obterbaixaRendaDeltaProcessamento = (params) => {
  const queryString = new URLSearchParams(params).toString();
  return api().get(`/fatura/obter-baixa-renda?${queryString}`);
};

export const gerarUrlUpload = async (fileNameWithExtension) => {
  return api().get(
    `/awsutil/GeraURLUpload/${encodeURIComponent(fileNameWithExtension)}`
  );
};
