import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { DownloadExcelMedicao } from "servicos/medicaoServico";
import RetornoEndpointDto from "../../../global/dto/retornoEndpointDto";

export default class MedicaoHelper {
  
  static async DownloadExcelMedicao(unidadeId, dataReferencia, codigoMedicor) {
    try {
      await DownloadExcelMedicao(unidadeId, dataReferencia, codigoMedicor);

      return RetornoEndpointDto.Sucesso(
        "arquivo obtido com sucesso",
        null
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu um erro ao obter arquivo"
      );
    }
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem: mensagem ?? "Erro interno, entre em contato com o suporte!"
      })
    );
  }
}
