const Colunas = [
  {
    name: "Nome distribuidora",
    selector: (row) => row.nomeDistribuidora,
    cell: (row) => row.nomeDistribuidora,
    width: ""
  },
  {
    name: "Data Início",
    selector: (row) => row.dataInicioVigencia,
    cell: (row) => row.dataInicioVigencia,
    width: ""
  },
  {
    name: "Data Fim",
    selector: (row) => row.dataFimVigencia,
    cell: (row) => row.dataFimVigencia,
    width: ""
  },
  {
    name: "Valor do indicado",
    selector: (row) => row.valorAmigoIndicado,
    cell: (row) => row.valorAmigoIndicado,
    width: ""
  },
  {
    name: "Valor do recebido",
    selector: (row) => row.valorIndicador,
    cell: (row) => row.valorIndicador,
    width: ""
  },
  {
    name: "Prioridade",
    selector: (row) => row.ordemPrioritaria,
    cell: (row) => row.ordemPrioritaria,
    width: ""
  },
  {
    name: "Ações",
    selector: (row) => row.acoes,
    cell: (row) => row.acoes,
    width: "",
    style: {
      textAlign: "center"
    }
  }
];

export default Colunas;
