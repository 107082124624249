/* eslint-disable consistent-return */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import { useTheme } from "@mui/material/styles";
import { AppBar, Tabs, Tab, Card, Grid, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import SwipeableViews from "react-swipeable-views";
import TabPanel from "componentes/tabPanel";
import Loader from "componentes/loader";
import InputDataBranco from "componentes/inputTextoDataBranco";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { useStyles, Tabela, TabelaScroll } from "./style";
import { listarDistribuidoras } from "../../../servicos/parametrizacaoDistribuidoras";
import { getValoresMercadoFaturado } from "../../../servicos/mercadoFaturado";
import { obterValoresPesosTarifarios, obterValoresCustos, obterValoresTarifas } from "../../../servicos/pcat";

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const PcatVisualizar = () => {
  const classes = useStyles();
    const theme = useTheme();

    // Auxiliares
    const [isLoadingDistribuidoras, setIsLoadingDistribuidoras] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showData, setShowData] = useState(false);

    // Geral
    const [listaDistribuidoras, setListaDistribuidoras] = useState([]);
    const [listaTabelaConsulta, _setListaTabelaConsulta] = useState(["Mercado", "Custos", "Tarifas", "Pesos Tarifários"]);

    // Selected Values
    const [distribuidoraID, setDistribuidoraID] = useState(null);
    const [tabelaConsulta, setTabelaConsulta] = useState(null);
    const [anoTarifario, setAnoTarifario] = useState(null);

    // Custos
    const [custos, setCustos] = useState([])

    // Tarifas 
    const [cabecalho, setCabecalho] = useState([]);
    const [subCabecalho, setSubCabecalho] = useState([]);
    const [dados, setDados] = useState(null)
    
    // PesosTarifarios
    const [pesosTarifariosDados, setPesosTarifariosDados] = useState(null);

    const onChangeAnoTarifario = (data) => {
        setAnoTarifario(data);
    };

    const [valoresTUSD, setValoresTUSD] = useState([]);
    const [valoresTE, setValoresTE] = useState([]);

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const obterValoresTabelaCustos = async () => {
        try {
            const response = await obterValoresCustos(distribuidoraID, moment(anoTarifario).format("YYYY"));

            if (!response.data || response.status !== 200) {
              setCustos([]);
              store.dispatch(
                alertaExibir({
                    tipo: "warning",
                    mensagem: "Não há dados para impressão com os parâmetros informados."
                })
              );
              return;
            }
            setCustos(response?.data);
            setShowData(true);
        } catch (error) {
            store.dispatch(
              alertaExibir({
                  tipo: "warning",
                  mensagem: error?.response?.data?.message
              })
            );
        }
    }

    const renderizarTabelaCustos = () => {
      return (
        <>
            <Grid item sm={24}>
                <TabelaScroll>
                <div id="scroll">
                    <Tabela>
                    <Tabela.Tbody>
                        <Tabela.Tr className={classes.customHead}>
                        <Tabela.Td className={classes.noBorder}><b>TIPO TARIFA</b></Tabela.Td>
                        <Tabela.Td className={classes.noBorder}>
                            <Tabela.Tr>
                            <Tabela.Td className={classes.noBorder}>
                                <Tabela.Tr>
                                <Tabela.Td className={classes.noBorder}><b>GRUPO DE CUSTO</b></Tabela.Td>
                                <Tabela.Td className={classes.noBorder}>
                                    <Tabela.Tr>
                                    <Tabela.Td className={classes.noBorder}><b>CUSTO</b></Tabela.Td>
                                    </Tabela.Tr>
                                </Tabela.Td>
                                <Tabela.Td className={classes.noBorder}>
                                    <Tabela.Tr>
                                    <Tabela.Td className={classes.noBorder}><b>BASE ECONÔMICA</b></Tabela.Td>
                                    </Tabela.Tr>
                                </Tabela.Td>
                                <Tabela.Td className={classes.noBorder}>
                                    <Tabela.Tr>
                                    <Tabela.Td className={classes.noBorder}><b>BASE FINANCEIRA</b></Tabela.Td>
                                    </Tabela.Tr>
                                </Tabela.Td>
                                </Tabela.Tr>
                            </Tabela.Td>
                            </Tabela.Tr>
                        </Tabela.Td>
                        </Tabela.Tr>
                        {custos.length > 0 && custos?.map((item) => (
                        <Tabela.Tr>
                            <Tabela.Td>{item?.tipoTarifa}</Tabela.Td>
                            <Tabela.Td>
                            {item?.grupoCusto.map((grupo) => (
                                <Tabela.Tr className={classes.noBorder}>
                                <Tabela.Td className={classes.noBorder}>
                                    <Tabela.Tr>
                                    <Tabela.Td>{grupo?.nome}</Tabela.Td>
                                    <Tabela.Td>
                                        {grupo?.custo.map((el) => (
                                        <Tabela.Tr>
                                            <Tabela.Td>{el?.nome}</Tabela.Td>
                                        </Tabela.Tr>
                                        ))}
                                    </Tabela.Td>
                                    <Tabela.Td>
                                        {grupo?.custo.map((el) => (
                                        <Tabela.Tr>
                                            <Tabela.Td>{parseFloat(el?.baseEconomica).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Tabela.Td>
                                        </Tabela.Tr>
                                        ))}
                                    </Tabela.Td>
                                    <Tabela.Td>
                                        {grupo?.custo.map((el) => (
                                        <Tabela.Tr>
                                            <Tabela.Td>{parseFloat(el?.baseFinanceira).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Tabela.Td>
                                        </Tabela.Tr>
                                        ))}
                                    </Tabela.Td>
                                    </Tabela.Tr>
                                </Tabela.Td>
                                </Tabela.Tr>
                            ))}
                            </Tabela.Td>
                        </Tabela.Tr>
                        ))}
                    </Tabela.Tbody>
                    </Tabela>
                </div>
                </TabelaScroll>
            </Grid>
        </>
      )
    }

    const obterValoresTabelaTarifas = async () => {
      try {
        const response = await obterValoresTarifas(distribuidoraID, moment(anoTarifario).format("YYYY"));

        if (response.status !== 200 || !response.data.length) {
          store.dispatch(
            alertaExibir({
            tipo: "warning",
            mensagem: "Não há dados para impressão com os parâmetros informados."
            })
          );
          return;
        }

        if (response.data.length > 0) {
          let cabecalhos = [];

          response.data.forEach(aba => {
            cabecalhos = [...cabecalhos, aba];
          });

          setCabecalho(cabecalhos);
        }

        setDados(response?.data);
        setShowData(true);
      } catch (error) {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem:
              error?.response?.data?.message ??
              "Erro interno, entre em contato com o suporte!"
          })
        );
      }
    }

    const renderizarTabelaTarifaria = () => {
      return (
        <>
          <Card className={classes.root}>
            <AppBar position="static" color="default">
              <Tabs
                value={value}
                onChange={handleChange}
                TabIndicatorProps={{ style: { background: "#DCDCDC" } }}
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                {cabecalho?.map((aba, index) => (
                  <Tab label={aba.grupo} {...a11yProps(index)} />
                ))}
              </Tabs>
            </AppBar>
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
            {cabecalho?.map((item, index) => (
              <TabPanel value={value} index={index} dir={theme.direction}>
                <Grid container spacing={4} className={classes.container}>
                  <Grid item sm={12}>
                    <TabelaScroll>
                      <div id="scroll">
                        <Tabela>
                          <Tabela.Thead>
                            <Tabela.Tr>
                              <Tabela.Th>SUBGRUPO</Tabela.Th>
                              <Tabela.Th>MODALIDADE</Tabela.Th>
                              <Tabela.Th>CLASSE</Tabela.Th>
                              <Tabela.Th>SUBCLASSE</Tabela.Th>
                              <Tabela.Th>DETALHE</Tabela.Th>
                              <Tabela.Th>NOME UC</Tabela.Th>
                              <Tabela.Th>POSTO</Tabela.Th>
                              <Tabela.Th>UNIDADE</Tabela.Th>
                              <Tabela.Th>GRUPO CUSTO</Tabela.Th>
                              <Tabela.Th>TIPO CUSTO</Tabela.Th>
                              <Tabela.Th>BE</Tabela.Th>
                              <Tabela.Th>BF</Tabela.Th>
                              <Tabela.Th>CVA</Tabela.Th>
                            </Tabela.Tr>
                          </Tabela.Thead>
                          <Tabela.Tbody>
                            {item?.linhas?.map((linha) => (
                              <Tabela.Tr>
                                <Tabela.Td>{linha?.subGrupo}</Tabela.Td>
                                <Tabela.Td>{linha?.modalidade}</Tabela.Td>
                                <Tabela.Td>{linha?.classe}</Tabela.Td>
                                <Tabela.Td>{linha?.subClasse}</Tabela.Td>
                                <Tabela.Td>{linha?.detalhe}</Tabela.Td>
                                <Tabela.Td>{linha?.nomeUc}</Tabela.Td>
                                <Tabela.Td>{linha?.posto}</Tabela.Td>
                                <Tabela.Td>{linha?.unidade}</Tabela.Td>
                                <Tabela.Td>{linha?.grupoCusto}</Tabela.Td>
                                <Tabela.Td>{linha?.tipoCusto}</Tabela.Td>
                                <Tabela.Td>{linha?.valorBaseEconomica.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Tabela.Td>
                                <Tabela.Td>{linha?.valorBaseFinanceira.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Tabela.Td>
                                <Tabela.Td>{linha?.valorCVA.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Tabela.Td>
                              </Tabela.Tr>
                            ))}
                          </Tabela.Tbody>
                        </Tabela>
                      </div>
                    </TabelaScroll>
                  </Grid>
                </Grid>
              </TabPanel>
            ))}
            </SwipeableViews>
          </Card>
        </>
      )
    }

    const obterValoresTabelaPesosTarifarios = async () => {
      try {
        const response = await obterValoresPesosTarifarios(distribuidoraID, moment(anoTarifario).format("YYYY"));

        if (response.status !== 200 || !response.data.length) {
          store.dispatch(
            alertaExibir({
              tipo: "warning",
              mensagem: "Não há dados para impressão com os parâmetros informados."
            })
          );
          return
        }

        setPesosTarifariosDados(response?.data);
        setShowData(true);
      } catch (error) {
          store.dispatch(
            alertaExibir({
              tipo: "warning",
              mensagem: error?.response?.data?.message
            })
          );
      }
    }

    const renderizarTabelaPesosTarifarios = () => {    
      return (
        <>
          <Card className={classes.root}>
              <AppBar position="static" color="default">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    TabIndicatorProps={{ style: { background: "#DCDCDC" } }}
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                  >
                  {pesosTarifariosDados?.map((item, index) => (
                    <Tab label={item.grupo} {...a11yProps(index)} />
                  ))}
                  </Tabs>
              </AppBar>
              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                onChangeIndex={handleChangeIndex}
              >
                  {pesosTarifariosDados?.map((item, index) => (
                  <TabPanel value={value} index={index} dir={theme.direction}>
                      <Grid container spacing={4} className={classes.container}>
                      <Grid item sm={12}>
                          <TabelaScroll>
                          <div id="scroll">
                              <Tabela>
                              <Tabela.Thead>
                                <Tabela.Tr>
                                  <Tabela.Th>SUBGRUPO</Tabela.Th>
                                  <Tabela.Th>MODALIDADE</Tabela.Th>
                                  <Tabela.Th>CLASSE</Tabela.Th>
                                  <Tabela.Th>SUBCLASSE</Tabela.Th>
                                  <Tabela.Th>DETALHE</Tabela.Th>
                                  <Tabela.Th>NOME UC</Tabela.Th>
                                  <Tabela.Th>POSTO</Tabela.Th>
                                  <Tabela.Th>UNIDADE</Tabela.Th>
                                  <Tabela.Th>GRUPO CUSTO</Tabela.Th>
                                  <Tabela.Th>TIPO CUSTO</Tabela.Th>
                                  <Tabela.Th>VALOR</Tabela.Th>
                                </Tabela.Tr>
                              </Tabela.Thead>
                              <Tabela.Tbody>
                                {item?.linhas?.map((linha) => (
                                  <Tabela.Tr>
                                      <Tabela.Td>{linha?.subGrupo}</Tabela.Td>
                                      <Tabela.Td>{linha?.modalidade}</Tabela.Td>
                                      <Tabela.Td>{linha?.classe}</Tabela.Td>
                                      <Tabela.Td>{linha?.subClasse}</Tabela.Td>
                                      <Tabela.Td>{linha?.detalhe}</Tabela.Td>
                                      <Tabela.Td>{linha?.nomeUc}</Tabela.Td>
                                      <Tabela.Td>{linha?.posto}</Tabela.Td>
                                      <Tabela.Td>{linha?.unidade}</Tabela.Td>
                                      <Tabela.Td>{linha?.grupoCusto}</Tabela.Td>
                                      <Tabela.Td>{linha?.tipoCusto}</Tabela.Td>
                                      <Tabela.Td>{linha?.valor.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Tabela.Td>
                                  </Tabela.Tr>
                                ))}
                              </Tabela.Tbody>
                              </Tabela>
                          </div>
                          </TabelaScroll>
                      </Grid>
                      </Grid>
                  </TabPanel>
                  ))}
              </SwipeableViews>
          </Card>
        </>
      )
    }

    const obterValoresTabelaMercado = async () => {
      try {
          const lista = await getValoresMercadoFaturado(distribuidoraID, moment(anoTarifario).format("YYYY"));
      
          if (lista.status !== 200 || !lista?.data) {
              setValoresTUSD([]);
              setValoresTE([]);
              store.dispatch(
                  alertaExibir({
                      tipo: "warning",
                      mensagem: `${lista.data ? lista.data : 'Não há dados para impressão com os parâmetros informados.'}`
                  })
              );
              return;
          }

          const { data } = lista;
          const tusd = [];
          const te = [];
          
          data.map((item => item.Grupo === "TUSD" ? tusd.push(item) : te.push(item)))
          setValoresTUSD(tusd);
          setValoresTE(te);
          setShowData(true);
      } catch (error) {
          store.dispatch(
              alertaExibir({
                  tipo: "warning",
                  mensagem:
                      error?.response?.data?.message
              })
          );
      }
    };

    const renderizarTabelaMercado = () => {
      return (
        <>
          <Card className={classes.root}>
              <AppBar position="static" color="default">
              <Tabs
                  value={value}
                  onChange={handleChange}
                  TabIndicatorProps={{ style: { background: "#DCDCDC" } }}
                  textColor="primary"
                  variant="fullWidth"
                  aria-label="full width tabs example"
              >
                  <Tab label="TUSD" {...a11yProps(0)} />
                  <Tab label="TE" {...a11yProps(1)} />
              </Tabs>
              </AppBar>
              <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
              >
              <TabPanel value={value} index={0} dir={theme.direction}>
                  <Grid container spacing={4} className={classes.container}>
                  <Grid item sm={12}>
                      <Tabela>
                      <thead>
                          <tr>
                          <th>SUBGRUPO</th>
                          <th>MODALIDADE</th>
                          <th>CLASSE</th>
                          <th>SUBCLASSE</th>
                          <th>DETALHE</th>
                          <th>NOME UC</th>
                          <th>POSTO</th>
                          <th>UNIDADE</th>
                          <th>VOLUME</th>
                          </tr>
                      </thead>
                      <tbody>
                          {valoresTUSD.map((item) => item.GruposDeConsumo.map((grupo) => (
                          <tr>
                              <td>{grupo.Subgrupo}</td>
                              <td>{grupo.Modalidade}</td>
                              <td>{grupo.Classe}</td>
                              <td>{grupo.Subclasse}</td>
                              <td>{grupo.Detalhe}</td>
                              <td>{grupo.NomeUc}</td>
                              <td>{grupo.Posto}</td>
                              <td>{grupo.Unidade}</td>
                              <td>{grupo.Volume}</td>
                          </tr>
                          )))}
                      </tbody>
                      </Tabela>
                  </Grid>
                  </Grid>
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                  <Grid container spacing={4} className={classes.container}>
                  <Grid item sm={12}>
                      <Tabela>
                      <thead>
                          <tr>
                          <th>SUBGRUPO</th>
                          <th>MODALIDADE</th>
                          <th>CLASSE</th>
                          <th>SUBCLASSE</th>
                          <th>DETALHE</th>
                          <th>NOME UC</th>
                          <th>POSTO</th>
                          <th>UNIDADE</th>
                          <th>VOLUME</th>
                          </tr>
                      </thead>
                      <tbody>
                          {valoresTE.map((item) => item.GruposDeConsumo.map((grupo) => (
                          <tr>
                              <td>{grupo.Subgrupo}</td>
                              <td>{grupo.Modalidade}</td>
                              <td>{grupo.Classe}</td>
                              <td>{grupo.Subclasse}</td>
                              <td>{grupo.Detalhe}</td>
                              <td>{grupo.NomeUc}</td>
                              <td>{grupo.Posto}</td>
                              <td>{grupo.Unidade}</td>
                              <td>{grupo.Volume}</td>
                          </tr>
                          )))}
                      </tbody>
                      </Tabela>
                  </Grid>
                  </Grid>
              </TabPanel>
              </SwipeableViews>
          </Card>
        </>
        )
    }

    const gerarResultado = () => {
      if (tabelaConsulta === "Mercado") return renderizarTabelaMercado();
      if (tabelaConsulta === "Custos") return renderizarTabelaCustos();
      if (tabelaConsulta === "Tarifas") return renderizarTabelaTarifaria();
      if (tabelaConsulta === "Pesos Tarifários") return renderizarTabelaPesosTarifarios();      
    }

    const onChangeDistribuidora = (event) => setDistribuidoraID(event.target.value);

    const obterDistribuidoras = useCallback(async () => {
        try {
        setIsLoadingDistribuidoras(true);
        const lista = await listarDistribuidoras(true);
        if (lista.status === 200 && lista.data) {
            setIsLoadingDistribuidoras(false);
            setListaDistribuidoras(lista?.data);
        }
        } catch (error) {
          setIsLoadingDistribuidoras(false);
          console.info(error);
        }
    }, []);

    useEffect(() => {
        if (!listaDistribuidoras.length) obterDistribuidoras();
    }, []);

    useEffect(() => {
      setDados(null);
      setShowData(false);

      if (distribuidoraID && anoTarifario && tabelaConsulta) {
        const getTabelaDados = async () => {
          setIsLoading(true);

          if (tabelaConsulta === "Mercado") {
            await obterValoresTabelaMercado();
          } else if (tabelaConsulta === "Custos") {
            await obterValoresTabelaCustos();
          } else if (tabelaConsulta === "Tarifas") {
            await obterValoresTabelaTarifas();
          } else {
            await obterValoresTabelaPesosTarifarios();
          }

          setIsLoading(false);
        }
      
        getTabelaDados();
      };
    }, [distribuidoraID, anoTarifario, tabelaConsulta]);

    return <>
      <Grid container spacing={4} p={2} className={classes.container} justifyContent="flex-end" alignItems="center">
        <Grid item sm={2}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="perid">Tabela</InputLabel>
            <Select
              labelId="table"
              id="table"
              value={tabelaConsulta}
              onChange={(event) => setTabelaConsulta(event.target.value)}
              label="Tabela"
              fullWidth>
            {listaTabelaConsulta.map((item) => (
              <MenuItem value={item}>
                {item}
              </MenuItem>
            ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={3}>
          <Loader loading={isLoadingDistribuidoras}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="perid">Distribuidora</InputLabel>
              <Select
                labelId="distr"
                id="distr"
                value={distribuidoraID}
                onChange={onChangeDistribuidora}
                label="Distribuidora"
                fullWidth>
                {listaDistribuidoras.map((dist) => (
                  <MenuItem value={dist.idDistribuidora}>{dist.titulo}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Loader>
        </Grid>
        <Grid item sm={2} className={classes.customSelect}>
          <InputDataBranco
            type="text"
            id="anoTarifario"
            name="anoTarifario"
            customValue={anoTarifario}
            onChange={(data) => onChangeAnoTarifario(data)}
            maxDate={moment("2090-12-31").toDate()}
            minDate={moment("1950-01-01").toDate()}
            format="yyyy"
            views={["year"]}
            renderIconShowHide={false}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.container} justifyContent="flex-end" alignItems="center">
        <Loader loading={isLoading}>
          {showData &&
            gerarResultado()
          }
        </Loader>
      </Grid>
    </>;
}

export default PcatVisualizar;