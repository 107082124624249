import { makeStyles } from "@mui/styles";
import styled from "styled-components";
import theme from "themes";

export const useStyles = makeStyles(() => ({
  boxCaptcha: {
    textAlign: "center"
  },

  logoCaptcha: {
    width: 110
  },

  check: {
    color: "green"
  },

  iconeLogoff: {
    display: "block",
    float: "left",
    height: 37,
    marginRight: 20,
    marginTop: 10,
    width: 37,
    fontSize: 37
  },
  labelAceito: {
    color: `${theme.color.InLoginText} !important`,
    "& a": {
      textDecoration: "underline !important",
      color: `${theme.color.InLoginText} !important`
    }
  },
  gradientTextLux: theme.color.GradientTextLuz
}));

// console.log(theme.size)
export const Version = styled.div`
  text-align: center;
  margin-top: 20px;
  color: ${theme.color.InLoginText};
  font-size: 10px;
`;

export const Fundo = styled.div`
  /* background: url(${(props) => (props.url ? props.url : "")}) left top; */
  /* background-size: auto 100%; */
  background-repeat: no-repeat;
  background: url(${(props) => (props.url ? props.url : "")}) center center;
  background-size: cover;
  background-color: ${theme.color.primary}
`;

export const Logo = styled.img`
  margin: 16px auto;
  height: 8vh;
  max-height: 132px;
  max-width: 217px;

  @media (min-width: ${theme.size.lg}) {
    height: auto;
    position: absolute;
    top: 64px;
    left: 48px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
`;

export const Avatar = styled.div`
  max-height: 100px;
  max-width: 100px;
  * {
    background: #eee;
    font-size: 6rem;
    text-align: center;
  }
`;

export const Titulo = styled.h2`
  color: ${theme.color.InLoginText};
  font-style: ${(props) => props.italic ?? "normal"};
  font-weight: bold;
  letter-spacing: -0.8px;
  text-align: center;
  text-transform: uppercase;
  margin: 24px auto;
  font-size: 18px;

  @media (min-width: ${theme.size.sm}) {
    margin-top: 47px;
    margin-bottom: 50px;
    font-size: ${(props) => props.fonte ?? "33px"};
  }
`;

export const SubTitulo = styled.h2`
  color: ${theme.color.InLoginText};
  letter-spacing: 0.4px;
  line-height: 1.6;
  text-align: center;
  font-size: 20px;

  @media (min-width: ${theme.size.sm}) {
    margin-top: 50px;
    margin-bottom: 50px;
    font-size: 20px;
  }
`;
export const SubTituloLuz = styled.span`
  letter-spacing: 0.4px;
  line-height: 1.6;
  font-size: 32px;
  background: ${theme.color.gradientTextLuz};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 700;
  @media (min-width: ${theme.size.sm}) {
    background: ${theme.color.gradientTextLuz};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-size: 48px;
    font-weight: 700;
  }
`;

export const MensagemLogout = styled.p`
  color: ${theme.color.InLoginText};
  font-size: 18px;
  letter-spacing: -0.31px;
  margin: 30px auto;
  max-width: 280px;
  position: relative;
  text-align: left;
`;

export const Mensagem = styled.p`
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  letter-spacing: -0.31px;
  margin-bottom: 40px;
  text-align: center;
`;

export const ButtonLink = styled.button`
  background: transparent;
  border: 0 none;
  color: '${theme.color.InLoginText};
  &:hover {
    co'lor: ${theme.color.hoverEsqueciSenha};
  }
`;

export const ButtonLinkLuz = styled.button`
  font-family: "Plau";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  margin-left: auto;
  text-decoration-line: underline;
  color: #fa3eb1;
  border: none;
  background: rgb(255, 255, 255);
`;

export const ButtonLinkMudeLuz = styled.button`
  font-family: "Plau";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  margin-left: auto;
  text-decoration-line: underline;
  color: #fa3eb1;
  border: none;
  background: #332053;
`;
export const Erros = styled.div``;

const cores = ["darkred", "orangered", "orange", "yellowgreen", "green"];

export const Medidor = styled.div`
  .strength-meter {
    background: #ddd;
    height: 10px;
    position: relative;
    margin: 5px 0;
    overflow: hidden;
    &:after {
      content: "";
      height: inherit;
      background: transparent;
      display: block;
      border-color: #fff;
      border-style: solid;
      border-width: 0 6px 0;
      position: absolute;
      width: calc(33.3%);
      z-index: 2;
    }
    &:after {
      right: calc(33.3%);
    }
  }
  .strength-meter-fill {
    background: transparent;
    height: inherit;
    position: absolute;
    width: 0;
    transition: width 0.5s ease-in-out, background 0.25s;
    &[data-strength="${(props) => props.forca}"] {
      background: ${(props) => cores[props.forca]};
      width: calc(20% * ${(props) => props.forca + 1});
    }
  }
`;

export const BoxLoginLuz = styled.div`
  margin-left: auto;
  background: #ffffff;
  border: 0 none;
  @media (max-width: ${theme.size.sm}) {
    margin: 0 auto;
  }
  @media (min-width: ${theme.size.sm}) {
    margin-right: 8%;
  }
`;

export const LogoLuz = styled.img`
  width: 150px;
  height: 101px;
`;

export const ButtonLuz = styled.button`
  width: 175px;
  height: 35px;
  background: #fa3eb1;
  border-radius: 24px;
  border: none;
  color: #ffffff;
  font-family: "Plau";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
`;

export const Rodape = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  margin-top: 60px;
`;
