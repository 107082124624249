import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { Card, Grid } from "@mui/material";
import InputDataBranco from "componentes/inputTextoDataBranco";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import CloseIcon from "@mui/icons-material/Close";
import { useHistory } from "react-router-dom";
import { useStyles, Tabela } from "./style";
import RotasDTO  from "../../../../rotas/rotasUrlDto";  
import { visualizarRateioCDEDivulgado } from "../../../../servicos/cdeServico";

const RateioCDEDivulgadoVisualizar = () => {
    const classes = useStyles();
    const history = useHistory();
  
    const onFechar = () => {
       history.push(`${RotasDTO.ProjecaoInsumoListar}`);
     }
    const [anoBase, setAnoBase] = useState(null);
    const handleChangeAnoBase = (ano) => setAnoBase(ano);
  
    const [dados, setDados] = useState([]);
  
    const obterDados = useCallback(async (ano) => {
      try {
        const anoRef = moment(ano).format("YYYY");
        const response = await visualizarRateioCDEDivulgado(anoRef);

        if (response.status !== 200) {
          setDados([]);
          setAnoBase(null);
          store.dispatch(
            alertaExibir({
              tipo: "warning",
              mensagem:
                "Sem dados para a data especificada!" 
            })
          );
        }

        setDados(response?.data);
      } catch (error) {
        setDados([]);
        setAnoBase(null);
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem:
              error?.response?.data?.message ??
              "Erro interno, entre em contato com o suporte!" 
          })
        );
      }
    }, []);
  
    useEffect(() => {
      if (anoBase && moment(anoBase).format("YYYY") > 1900) obterDados(anoBase);
    }, [anoBase, obterDados]);
  
    return <>
      <Grid container className={classes.containerTitulo}>
          <Grid item xs={11} className={classes.titulo}>
            Rateio CDE Divulgado
          </Grid>
          <Grid item xs={1}>             
            <CloseIcon onClick={onFechar} className={classes.icon}/>
          </Grid>   
      </Grid>
      <Grid container p={2} spacing={4} className={classes.container} justifyContent="flex-end" alignItems="center">
        <Grid item sm={2} className={classes.customSelect}>
          <InputDataBranco
            type="text"
            id="anoBase"
            name="anoBase"
            customValue={anoBase}
            onChange={(data) => handleChangeAnoBase(data)}
            minDate={moment().subtract(21, "years").toDate()}
            maxDate={moment("2090-12-31").toDate()}
            format="yyyy"
            views={["year"]}
            openTo="year"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.container}>
        <Grid item sm={12}>
          {dados.length > 0 &&
            <Card className={classes.container}>
              <Grid container p={2} spacing={2} className={classes.container}>
                <Grid item sm={12}>                
                  <Tabela className={classes.container}>
                    <Tabela.Thead>
                      <Tabela.Tr>
                        <Tabela.Th>TÍTULO</Tabela.Th>
                        <Tabela.Th>CDE Uso</Tabela.Th>
                        <Tabela.Th>CDE GD</Tabela.Th>        
                        <Tabela.Th>Ano</Tabela.Th>
                      </Tabela.Tr>
                    </Tabela.Thead>
                    <Tabela.Tbody>
                      {dados.map((item) => (
                        <Tabela.Tr>
                          <Tabela.Td>{item.titulo}</Tabela.Td>
                          <Tabela.Td>{item.cdeUso}</Tabela.Td>
                          <Tabela.Td>{item.cdegd}</Tabela.Td>                       
                          <Tabela.Td>{item.ano}</Tabela.Td>
                        </Tabela.Tr>
                      ))}
                    </Tabela.Tbody>
                  </Tabela>                  
                </Grid>
              </Grid>
            </Card>
          }
        </Grid>
      </Grid>
    </>;
}

export default RateioCDEDivulgadoVisualizar;