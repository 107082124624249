import { makeStyles } from '@mui/styles';
import { Base } from 'componentes/cores';

export const useStyles = makeStyles(() => ({
  titulo: {
    fontWeight: "bold"
  },

  container: {
    width: "100%",
    "& .MuiInputBase-root": {
      color: `${Base.OffWhite} !important`,
      height: "55px",
      "& input": {
        pointerEvents: "none"
      }
    },
    "& .MuiButtonBase-root": {
      color: `${Base.OffWhite} !important`
    },
    "& .MuiFormLabel-root": {
      lineHeight: "1",
      color: Base.White
    },
    "& .MuiSvgIcon-root": {
      color: Base.White
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${Base.White}`
    }
  },
  filter: {
    color: `${Base.OffWhite} !important`,
    display: "inline",
    fontSize: "16px",
    margin: 0,
    "& li": {
      display: "inline-block",
      marginLeft: "45px",
      position: "relative",
      "&:nth-child(1) .icon": {
        backgroundColor: Base.GreenFrag
      },
      "&:nth-child(2) .icon": {
        backgroundColor: Base.RedGeranium
      },
      "&:nth-child(3) .icon": {
        backgroundColor: Base.GreyPaynes
      }
    },
    "& .icon": {
      display: "inline-block",
      height: "20px",
      left: "-30px",
      position: "absolute",
      width: "20px"
    },
    "& .texto": { marginTop: "-2px" }
  },
  containerArrowLeft: {
    borderRight: "2px solid #191C22",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  containerArrowRight: {
    borderLeft: "2px solid #191C22",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  arrow: {
    color: `${Base.OffWhite} !important`,
    cursor: "pointer",
  },

  itemGrafico: {
    "& .apexcharts-tooltip.apexcharts-theme-light": {
      border: "0 none",
      background: "none",
      width: "fit-content",
      height: "fit-content",
      textAlign: "center",
      fontSize: "13px"
    }
  },
  noData: {
    textAlign: "center",
    width: "100%",
    minHeight: "200px",
    "& div": {
      margin: "125px auto 0",
      maxWidth: "80%"
    }
  },
  arrowBox: {
    position: "relative",
    background: "#647480",
    padding: "8px",
    borderColor: "#647480"
  },
  arrowBoxTitle: {
    color: "#a6b9c8",
    fontSize: "10px"
  },
  formControl: {
    width: "100%",
    "& .MuiInputBase-root": {
      height: "56px",
      color: `${Base.White} !important`,
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#FFF"
      }
    },
    "& .MuiSelect-icon": {
      color: Base.White
    },
    "& option": {
      color: Base.BlackBastille
    },
    "& .MuiSelect-select.MuiSelect-select": {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      color: "#eef0f9"
    },
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      padding: "0 40px 0 20px "
    },
    "& .MuiFormLabel-root": {
      color: `${Base.OffWhite} !important`,
    }
  },

  select: {
    ".MuiSvgIcon-root": {
      color: `${Base.OffWhite} !important`,
    }
  },
  inputRoot: {
    color: `${Base.White} !important`,
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: Base.White
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: Base.White
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: Base.White
    }
  },
  backButton: {
    "& .MuiGrid-root .MuiSvgIcon-root": {
      color: `${Base.OffWhite} !important`,
      width: 50,
      height: 50,
    },
    "&": {
      position: "absolute",
      right: 0,
      top: 2
    }
  }
}));
