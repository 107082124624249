import React, { useState, useEffect } from "react";
import { Box, Card, Grid } from "@mui/material";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { Botao, Loader } from "../../../componentes";
import CardsPlanos from "../componentes/CardsPlanos";
import { formataMoedaReais, formataPercentual } from "../../../servicos/utils";
import { useStyles } from "./style";
import TituloPagina from "../componentes/TituloPagina";
import Cofre from "../../../assets/cotacoesGd/cofre.png";
import ModalMensagemErro from "../../../componentes/ModalMensagemErro";
import { RotasDTO } from "../../../global/rotas/rotasUrlDto";
import PropostaPdf from "../PropostaPdf";
import SelectAutocompleteCheckbox from "../../../componentes/selectAutocompleteCheckbox";
import PlanosAClHelper from "./helper";

const PLanosACL = () => {
  const history = useHistory();
  const classes = useStyles();
  const usuario = useSelector((state) => state.usuario);
  const [dadosPlanosGerados, setDadosPlanosGerados] = useState([]);
  const [nomePlanoSelecionado, setNomePlanoSelecionado] = useState("");
  const [dadosProposta, setDadosProposta] = useState({});
  const [mensagemModal, setMensagemModal] = useState("");
  const [modalAcao, setModalAcao] = useState(false);
  const [dadosSelecionado, setDadosSelecionado] = useState({});
  const [checked, setChecked] = useState(false);
  const { id } = useParams();
  const [cotacaoId, setCotacaoId] = useState(null);
  const [dadosHistory, setDadosHistory] = useState(null);
  const { unidadeSelecionada } = usuario;
  const [statusVigenciaFechado, setStatusVigenciaFechado] = useState(false);
  const [carregando, setCarregando] = useState(false);
  const [propostaId, setPropostaId] = useState([]);
  const [propostaLista, setPropostaLista] = useState([]);

  const normalizaEconomiaPeriodo = (meses, periodo) => {
    const periodoMeses = periodo?.output?.find(
      (item) => item?.quantidadeMeses === meses
    )?.outputCalculeira;

    return {
      inicio: periodoMeses?.economia?.[0].mesTimeline?.mes,
      fim: periodoMeses?.economia?.at(-1)?.mesTimeline?.mes
    };
  };

  const normalizaEconomiaDados = (meses, periodo) => {
    const periodoMeses = periodo?.output?.find(
      (item) => item?.quantidadeMeses === meses
    )?.outputCalculeira;

    return periodoMeses?.economiaAnual;
  };

  const normalizaGarantiaDados = (meses, periodo) => {
    const garantia = periodo?.garantia?.find((item) => item?.meses === meses);
    return {
      meses: garantia?.meses,
      mediaCustoEnergia: garantia?.mediaCustoEnergia?.toFixed(2)
    };
  };

  const normalizaPlanos = (dados, dadosCalculeira) => {
    const arrayPLanos = [];

    arrayPLanos.push(
      {
        nomePlano: "DESCONTOS 3 ANOS",
        anos: 3,
        periodoMeses: 36,
        economiaAnual: dados?.economia3Anos_reais,
        economiaMensal: dados?.economiaMensal3Anos_reais,
        economiaTotal: dados?.economia3Anos_reais ?? "",
        flexibilidade: 0,
        sustentabilidade: 0,
        percentualDesconto: dados?.economia3Anos_percent * 100 ?? "",
        cotacaoSelecionada: false,
        tipoPlanoCotacaoId: 12,
        cotacaoId: 0,
        matrizDescontoId: 0,
        ativo: true,
        periodoEconomia: normalizaEconomiaPeriodo(36, dadosCalculeira),
        dadosEconomiaAnual: normalizaEconomiaDados(36, dadosCalculeira),
        dadosGarantia: normalizaGarantiaDados(36, dadosCalculeira)
      },
      {
        nomePlano: "DESCONTOS 4 ANOS",
        anos: 4,
        periodoMeses: 48,
        economiaAnual: dados?.economia4Anos_reais,
        economiaMensal: dados?.economiaMensal4Anos_reais,
        economiaTotal: dados?.economia4Anos_reais ?? "",
        flexibilidade: 0,
        sustentabilidade: 0,
        percentualDesconto: dados?.economia4Anos_percent * 100 ?? "",
        cotacaoSelecionada: false,
        tipoPlanoCotacaoId: 15,
        cotacaoId: 0,
        matrizDescontoId: 0,
        ativo: true,
        periodoEconomia: normalizaEconomiaPeriodo(48, dadosCalculeira),
        dadosEconomiaAnual: normalizaEconomiaDados(48, dadosCalculeira),
        dadosGarantia: normalizaGarantiaDados(48, dadosCalculeira)
      },
      {
        nomePlano: "DESCONTOS 5 ANOS",
        anos: 5,
        periodoMeses: 60,
        economiaAnual: dados?.economia5Anos_reais,
        economiaMensal: dados?.economiaMensal5Anos_reais,
        economiaTotal: dados?.economia5Anos_reais ?? "",
        flexibilidade: 0,
        sustentabilidade: 0,
        percentualDesconto: dados?.economia5Anos_percent * 100 ?? "",
        cotacaoSelecionada: false,
        tipoPlanoCotacaoId: 13,
        cotacaoId: 0,
        matrizDescontoId: 0,
        ativo: true,
        periodoEconomia: normalizaEconomiaPeriodo(60, dadosCalculeira),
        dadosEconomiaAnual: normalizaEconomiaDados(60, dadosCalculeira),
        dadosGarantia: normalizaGarantiaDados(60, dadosCalculeira)
      }
    );

    return arrayPLanos || [];
  };

  const handlePLanoSelecionado = (item) => {
    if (checked) {
      setNomePlanoSelecionado("");
      return;
    }
    setNomePlanoSelecionado(item?.nomePlano);
    setDadosSelecionado({
      ...item,
      cotacaoSelecionada: true
    });
  };

  const onChangeSelecionarProposta = (item) => {
    setPropostaId(item);
  };

  useEffect(() => {
    const dados = normalizaPlanos(
      history?.location?.state?.economiaConsolidada,
      history?.location?.state?.dadosCalculeira
    );
    setDadosPlanosGerados(dados);
    setPropostaLista(dados?.map((item) => ({ ...item, nome: item.nomePlano })));
    setDadosHistory(history?.location?.state);
    setDadosProposta(history?.location?.state);
    setStatusVigenciaFechado(history?.location?.state?.status === "FE");
  }, [history]);

  const handleDadosEmpresa = () => {
    if (!nomePlanoSelecionado) {
      setModalAcao(true);
      setMensagemModal("Por favor, selecione um plano!");
      return;
    }

    const todosPlanos = dadosPlanosGerados.map(
      (planos) =>
        [dadosSelecionado].find(
          (newItem) => newItem.tipoPlanoCotacaoId === planos.tipoPlanoCotacaoId
        ) || planos
    );

    const dadosEmpresa = {
      ...dadosProposta,
      ...dadosSelecionado,
      planos: todosPlanos,
      dadosPlanoSelecionado: dadosSelecionado
    };

    const url = cotacaoId ? `//dados-empresa/${cotacaoId}` : "//dados-empresa";

    history.push({
      pathname: `${RotasDTO.CotacaoACL}${url}`,
      state: dadosEmpresa
    });
  };

  const handlePlanosDescricao = (periodo) => {
    switch (periodo) {
      case 36:
        return "DESCONTOS 3 ANOS";
      case 48:
        return "DESCONTOS 4 ANOS";
      case 60:
        return "DESCONTOS 5 ANOS";

      default:
        return "";
    }
  };

  const normalizaPlanosFechada = (dadosPlanosGerados) => {
    return dadosPlanosGerados?.map((item) => ({
      nomePlano: handlePlanosDescricao(item?.periodoMeses),
      anos: 3,
      periodoMeses: item?.periodoMeses,
      economiaAnual: item?.economiaAnual,
      economiaMensal: item?.economiaMensal,
      economiaTotal: item?.economiaTotal ?? "",
      flexibilidade: 0,
      sustentabilidade: 0,
      percentualDesconto: item?.percentualDesconto,
      cotacaoSelecionada: item?.cotacaoSelecionada,
      tipoPlanoCotacaoId: item?.tipoPlanoCotacaoId,
      cotacaoId: item?.cotacaoId,
      matrizDescontoId: item?.matrizDescontoId,
      ativo: true,
      periodoEconomia: {
        inicio: item?.dataInicioSuprimento,
        fim: item?.dataFimSuprimento
      }
    }));
  };

  const obterCotacaoInicialPlanosGerados = async (id) => {
    const {
      data,
      sucesso,
      mensagem
    } = await PlanosAClHelper.obterCotacaoInicialPlanosGerados(
      id,
      setCarregando
    );
    if (!sucesso) {
      PlanosAClHelper.exibirErro(mensagem);
      setCarregando(false);
    }

    const dadosNormalizado = normalizaPlanosFechada(
      data?.cotacaoInicialPlanosGerados
    );

    setDadosPlanosGerados(dadosNormalizado);
    setPropostaLista(
      dadosNormalizado?.map((item) => ({ ...item, nome: item.nomePlano }))
    );
  };

  useEffect(() => {
    if (!id) return;
    setCotacaoId(id);
    if (statusVigenciaFechado) obterCotacaoInicialPlanosGerados(id);
  }, [id, statusVigenciaFechado]);

  const renderPassoEmpresa = () => {
    const url = `//dados-empresa/${cotacaoId}`;

    history.push({
      pathname: `${RotasDTO.CotacaoACL}${url}`,
      state: dadosProposta
    });
  };

  return (
    <>
      <ModalMensagemErro
        item={modalAcao}
        titulo="Algo deu errado"
        mensagem={mensagemModal}
        onCancelar={() => {
          setModalAcao(false);
          setMensagemModal("");
        }}
        onConfirmar={() => {}}
        texto="Tentar novamente"
      />
      <Loader loading={carregando}>
        <Card className={classes.cardCadastro}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            px={2}
          >
            <Box>
              <TituloPagina
                icon={Cofre}
                titulo="Planos disponíveis"
                alt="planosDisponiveis"
              />
            </Box>
            <Box>
              {!statusVigenciaFechado && (
                <PropostaPdf
                  dadosPlanos={
                    propostaId?.length ? propostaId : [dadosPlanosGerados[2]]
                  }
                  dadosCliente={dadosHistory}
                  unidade={unidadeSelecionada}
                  setCarregando={setCarregando}
                />
              )}
            </Box>
          </Box>
          {!statusVigenciaFechado && (
            <Grid
              container
              spacing={2}
              pb={2}
              px={2}
              display="flex"
              justifyContent="flex-end"
            >
              <Grid item xs={12} sm={5} md={4}>
                <SelectAutocompleteCheckbox
                  disableClearable
                  disableOpenOnFocus
                  id="propostasId"
                  name="pospostas"
                  options={propostaLista}
                  label="Planos Prosposta"
                  getOptionLabel={(option) => option.nomePlano}
                  onChange={(e, item) => {
                    onChangeSelecionarProposta(item);
                  }}
                  value={propostaId}
                  defaultValue={propostaId}
                  allowClear
                  selecionarTodos={false}
                />
              </Grid>
            </Grid>
          )}
          <Grid container spacing={2} p={2}>
            {dadosPlanosGerados &&
              dadosPlanosGerados.map((item, index) => (
                <Grid container item xs={12} lg={4} xl={4} key={index}>
                  <CardsPlanos
                    onChecked={() => {
                      handlePLanoSelecionado(item);
                      setChecked((prev) => !prev);
                    }}
                    titulo={item.nomePlano}
                    porcentagem={formataPercentual(item?.percentualDesconto)}
                    anual={formataMoedaReais(item.economiaAnual)}
                    mensal={item?.economialAnual / item?.anos}
                    rodape={formataMoedaReais(item.economiaTotal)}
                    nomeCard={item.nomePlano}
                    checked={
                      (checked && item.nomePlano === nomePlanoSelecionado) ||
                      item?.cotacaoSelecionada
                    }
                    economiaMensal={formataMoedaReais(item?.economiaMensal)}
                    economiaAnual={formataMoedaReais(
                      item?.economiaAnual / item?.anos
                    )}
                    periodoEconomia={`Inicio: ${window
                      .moment(item?.periodoEconomia?.inicio)
                      .format("DD/MM/YYYY")} - Fim: ${window
                      .moment(item?.periodoEconomia?.fim)
                      .format("DD/MM/YYYY")}`}
                    disabled={statusVigenciaFechado}
                  />
                </Grid>
              ))}
          </Grid>
        </Card>
        <Box component={Grid} container spacing={4} pl={1} pt={3}>
          <Grid item xs={12} lg={3} mt={2}>
            {statusVigenciaFechado ? (
              <Botao
                onClick={renderPassoEmpresa}
                label="Próximos"
                className={classes.button}
              />
            ) : (
              <Botao
                label="Dados cadastrais"
                className={classes.button}
                onClick={() => {
                  handleDadosEmpresa();
                }}
              />
            )}
          </Grid>
        </Box>
      </Loader>
    </>
  );
};

export default PLanosACL;
