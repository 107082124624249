export default class FaturaDistribuidoraPreLeadDto {
  constructor(
    id,
    nome,
    pessoaFisicaJuridica,
    descontoInicial,
    valorDaConta,
    reducaoDeCusto,
    faturaProcessada,
    dataDaCriacaoNoSalesforce,
    produto,
    origemDaInformacao,
    email,
    telefone,
    cep,
    nomeDoAnexoComExtensao,
    guidArquivoDaDigitalizacao,
    multaDistribuidora,
    preLeadContratado,
    distribuidora,
    distribuidoraNaoAtendida,
    erroLeituraFatura,
    consumoAbaixoRange,
    contratoGerado
  ) {
    this.id = id;
    this.nome = nome;
    this.pessoaFisicaJuridica = pessoaFisicaJuridica;
    this.descontoInicial = descontoInicial;
    this.valorDaConta = valorDaConta;
    this.reducaoDeCusto = reducaoDeCusto;
    this.faturaProcessada = faturaProcessada;
    this.dataDaCriacaoNoSalesforce = dataDaCriacaoNoSalesforce;
    this.produto = produto;
    this.origemDaInformacao = origemDaInformacao;    
    this.email = email;
    this.telefone = telefone;
    this.cep = cep;
    this.nomeDoAnexoComExtensao = nomeDoAnexoComExtensao;
    this.guidArquivoDaDigitalizacao = guidArquivoDaDigitalizacao;
    this.multaDistribuidora = multaDistribuidora;
    this.preLeadContratado = preLeadContratado;
    this.distribuidora = distribuidora;
    this.distribuidoraNaoAtendida = distribuidoraNaoAtendida;
    this.erroLeituraFatura = erroLeituraFatura;
    this.consumoAbaixoRange = consumoAbaixoRange;
    this.contratoGerado = contratoGerado;
  }
}
