/* eslint-disable import/no-unresolved */
import MICROSERVICO from "global/enumeradores/microServico";
import obterApi from "servicos/api";

const api = () => obterApi(MICROSERVICO.PROJECAOTARIFARIA);

export const uploadContratosBilaterais = async (arquivo, idUsuario, nomeUsuario) => {
    const data = new FormData();
    data.append("file", arquivo.file);

    const metodo = "post";
    const url = `v1/ImpBasesContratosBilaterais/importa?idUsuario=${idUsuario}&nomeUsuario=${nomeUsuario}`;
    return api()[metodo](url, data);
};

export const getDistribuidoras = async () => {
    const metodo = "get";
    const url = "v1/ParametroDistribuidora/getalljoin?StatusProjecaoTarifaria=true";
   
    return api()[metodo](url);
   
  }
  
  export const getContratosBilaterais = async (idDistribuidora) => {
    const metodo = "get";
    const url = `v1/ImpBasesContratosBilaterais/visualizarcontratos${idDistribuidora ? `?IdDistribuidora=${idDistribuidora}` : ""}`; 
    return api()[metodo](url);
  }