import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";

// Redux
import { useSelector } from "react-redux";

// Icones
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

// Componentes
import { Grid } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import { Base } from "../../../componentes/cores";
import InputData from "../../../componentes/inputTextoData";
import Loader from "../../../componentes/loader";

// Redux
import { store } from "../../../global/redux";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";

// Servicos
import { buscarPorUnidade } from "../../../servicos/consumoAtualServico";

// Styles
import { Container } from "../Dashboard/style";
import { HtmlTooltip, useStyles } from "./style";

// DTO
import themes from "../../../themes";
import ConsumoDto from "./consumoDto";

const definirMesInicial = () => {
  const now = window.moment();
  const day = now.date();
  const newDate = window.moment().subtract(1, "month").toDate();
  return day === 1 || day === 2 ? newDate : now;
};

const ConsumoAtual = (props) => {
  const classes = useStyles();
  const usuario = useSelector((state) => state.usuario);

  const [pdf, setPDF] = React.useState(props);

  useEffect(() => {
    setPDF(props);
  }, [props]);
  // console.log(usuario)

  // Unidade selecionada
  const { unidadeSelecionada } = usuario;

  // Cliente selecionado
  const { clienteSelecionado } = usuario;
  const [nomeCliente, setNomeCliente] = useState();
  const [nomeUnidade, setNomeUnidade] = useState();

  // console.log(unidadeSelecionada)

  const getNomeCliente = () => {
    // eslint-disable-next-line no-unused-vars
    let localizado = -1;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < usuario.clientes.length; i++) {
      if (usuario.clientes[i].id === clienteSelecionado) {
        setNomeCliente(usuario.clientes[i].nomeFantasia);
        // eslint-disable-next-line no-unused-vars
        localizado = i;
        break;
      }
    }
  };
  const getNomeUnidade = () => {
    // eslint-disable-next-line no-unused-vars
    let localizado = -1;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < usuario.unidades.length; i++) {
      if (usuario.unidades[i].id === unidadeSelecionada) {
        setNomeUnidade(usuario.unidades[i].nomeUnidade);
        // eslint-disable-next-line no-unused-vars
        localizado = i;
        break;
      }
    }
  };

  // Estado de controle do Mes selecionado
  const [mes, setMes] = useState(definirMesInicial());

  // Estado de controle do loader
  const [carregandoConsumoAtual, setCarregandoConsumoAtual] = useState(false);

  // Estados que contem as informações necessarias de cada consumo
  const [consumoMesAtual, setConsumoMesAtual] = useState(null);
  const [consumoMesAnterior, setConsumoMesAnterior] = useState(null);

  // State para gerar PDF
  // const [pdf, setPdf] = useState();

  // const baixarPdf = ({ blob, loading, error }) => {
  //   if (loading || error) return <></>;
  //   FileSaver.saveAs(blob, `Economia`);
  //   setPdf(null);
  //   return <></>;
  // };

  // UseMemo que calcula se deve ou não exibir cada consumo
  const exibirConsumo = useMemo(() => !!consumoMesAtual, [consumoMesAtual]);
  const exibirConsumoMesAnterior = useMemo(() => !!consumoMesAnterior, [
    consumoMesAnterior
  ]);

  // UseMemo que calcula se deve exibir erro da CCEE
  const exibirErroCCEEPrincipal = useMemo(
    () => consumoMesAtual?.meuConsumo === 0,
    [consumoMesAtual]
  );
  const exibirErroCCEESecundario = useMemo(
    () => consumoMesAnterior?.meuConsumo === 0,
    [consumoMesAnterior]
  );

  // UseMemo para calcular e cachear os dados dos graficos
  const dadosMesAtual = useMemo(() => {
    const mesAt = [
      {
        name: "Meu Consumo",
        aux: "MeuConsumo",
        data: [consumoMesAtual?.meuConsumo ?? 0]
      }
    ];

    if (consumoMesAtual?.contratoMax) {
      mesAt.push({
        name: "Contrato Maximo",
        aux: "ContratoMax",
        data: [consumoMesAtual?.contratoMax ?? 0]
      });
    }

    return mesAt;
  }, [consumoMesAtual]);

  const dadosMesAnterior = useMemo(() => {
    const mesAnt = [
      {
        name: "Meu Consumo",
        aux: "MeuConsumo",
        data: [consumoMesAnterior?.meuConsumo ?? 0]
      }
    ];

    if (consumoMesAnterior?.contratoMax) {
      mesAnt.push({
        name: "Contrato Maximo",
        aux: "ContratoMax",
        data: [consumoMesAnterior?.contratoMax ?? 0]
      });
    }

    return mesAnt;
  }, [consumoMesAnterior]);

  // Hooks de configuração do grafico
  const optionMesAtual = useMemo(() => {
    return {
      chart: {
        type: "bar",
        height: "auto",
        zoom: {
          enabled: true
        },
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: true
        }
      },
      colors: [themes.color.primary, Base.BlackOpacity40],
      dataLabels: {
        enabled: false
      },
      legend: {
        show: false
      },
      xaxis: {
        position: pdf.status ? "top" : "bottom",
        show: false,
        categories: ["Meu consumo", "Contrato Maximo"],
        labels: {
          style: {
            colors: [pdf.status ? "#000000" : themes.color.labelColorGrafico]
          }
        }
      },
      yaxis: {
        show: false
      },
      tooltip: {
        enabled: true,
        custom: ({ series, seriesIndex, dataPointIndex }) => {
          const valorConvertido = series[seriesIndex][dataPointIndex]
            .toString()
            .replace(".", ",");
          return `<div class=${classes.tooltip}>
              <span>
              ${seriesIndex === 0 ? "Consumo" : "Contrato máx."} 
              <br /> 
              ${
                seriesIndex === 0
                  ? `${consumoMesAtual.porcentagemUtilizada
                      .toFixed(2)
                      .toString()
                      .replace(".", ",")}%`
                  : `${valorConvertido}MWh`
              }          
              </span>
              </div>`;
        },
        theme: "dark"
      }
    };
  }, [consumoMesAtual, classes.tooltip, pdf]);

  const optionMesAnterior = useMemo(() => {
    return {
      chart: {
        type: "bar",
        height: "auto",
        zoom: {
          enabled: true
        },
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: true
        }
      },
      colors: [themes.color.serieInferiorAtual, Base.BlackOpacity40],
      dataLabels: {
        enabled: false
      },
      legend: {
        show: false
      },
      xaxis: {
        position: pdf.status ? "top" : "bottom",
        show: true,
        categories: ["Meu consumo"],
        labels: {
          style: {
            colors: [pdf.status ? "#000000" : themes.color.labelColorGrafico]
          }
        }
      },
      yaxis: {
        show: false
      },
      tooltip: {
        enabled: true,
        custom: ({ series, seriesIndex, dataPointIndex }) => {
          const valorConvertido = series[seriesIndex][dataPointIndex]
            .toString()
            .replace(".", ",");
          return `<div class=${classes.tooltip}>
              <span>
              ${seriesIndex === 0 ? "Consumo" : "Contrato máx."} 
              <br /> 
              ${
                seriesIndex === 0
                  ? `${consumoMesAnterior.porcentagemUtilizada
                      .toFixed(2)
                      .toString()
                      .replace(".", ",")}%`
                  : `${valorConvertido}MWh`
              }          
              </span>
              </div>`;
        },
        theme: "dark"
      }
    };
  }, [consumoMesAnterior, classes.tooltip, pdf]);

  // Metodos pra Mapear os dados retornados do Endpoint
  const mapearConsumoAtual = (resultados, nomesMeses) => {
    if (resultados.porcentagemUtilizada[1] <= 0) {
      setConsumoMesAtual(null);
      return;
    }

    const mesAtual = new ConsumoDto(
      nomesMeses[1],
      resultados.contratoMax[1],
      resultados.contrato,
      resultados.contratoMin,
      resultados.meuConsumo[1],
      resultados.porcentagemUtilizada[1]
    );

    setConsumoMesAtual(mesAtual);
  };

  const mapearConsumoAnterior = (resultados, nomesMeses) => {
    if (resultados.porcentagemUtilizada[0] <= 0) {
      setConsumoMesAnterior(null);
      return;
    }

    const mesAnterior = new ConsumoDto(
      nomesMeses[0],
      resultados.contratoMax[0],
      resultados.contrato,
      resultados.contratoMin,
      resultados.meuConsumo[0],
      resultados.porcentagemUtilizada[0]
    );

    setConsumoMesAnterior(mesAnterior);
  };

  // Metodo de busca no endpoint quando o mes for Alterado
  const handleBucarConsumoAtual = useCallback(
    async (dataSelecionada) => {
      try {
        setCarregandoConsumoAtual(true);
        setConsumoMesAnterior(null);
        setConsumoMesAtual(null);

        const dataInicio = moment(dataSelecionada)
          .startOf("month")
          .add(-1, "month")
          .format("YYYY-MM-DD");
        const dataFim = moment(dataSelecionada)
          .endOf("month")
          .format("YYYY-MM-DD");

        const consumoAtual = await buscarPorUnidade(
          unidadeSelecionada,
          dataInicio,
          dataFim
        );

        if (
          (consumoAtual?.status !== 200 ?? true) ||
          (!consumoAtual?.data ?? true)
        ) {
          setConsumoMesAnterior(null);
          setConsumoMesAtual(null);
        }

        const { resultados, labelsMes: nomesMeses } = consumoAtual.data;

        mapearConsumoAtual(resultados, nomesMeses);

        mapearConsumoAnterior(resultados, nomesMeses);

        setCarregandoConsumoAtual(false);
      } catch (error) {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem:
              error?.response?.data?.message ??
              "Erro interno, entre em contato com o suporte!"
          })
        );
        setCarregandoConsumoAtual(false);
      }
    },
    [unidadeSelecionada]
  );

  const obterMedidores = useCallback(
    async (dataSelecionada) => {
      handleBucarConsumoAtual(dataSelecionada);
    },
    [handleBucarConsumoAtual]
  );

  useEffect(() => {
    obterMedidores(mes);
    getNomeCliente();
    getNomeUnidade();
  }, [obterMedidores, mes]);

  const informacaoNaoDisponivel = () => {
    return (
      <Grid
        container
        item
        justifyContent="center"
        alignContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          Não existe registro para essa data!
        </Grid>
      </Grid>
    );
  };

  // eslint-disable-next-line no-unused-vars
  const onClickGerarPDF = async () => {
    // eslint-disable-next-line no-unused-vars
    const data = {
      nomeCliente,
      nomeUnidade,
      consumoAtual: consumoMesAtual?.meuConsumo ?? 0,
      contratoMaxAtual: consumoMesAtual?.contratoMax ?? 0,
      contratoMesAtual: consumoMesAtual?.labelMes ?? 0,
      contratoPorcentagemAtual: consumoMesAtual?.porcentagemUtilizada ?? 0,

      consumoAnterior: consumoMesAnterior?.meuConsumo ?? 0,
      contratoMaxAnterior: consumoMesAnterior?.contratoMax ?? 0,
      contratoMesAnterior: consumoMesAnterior?.labelMes ?? 0,
      contratoPorcentagemAnterior: consumoMesAnterior?.porcentagemUtilizada ?? 0
    };

    // setPdf(<PDFConsumo {...data} />);
    // return null;
  };

  const erroCCEEGrafico = () => {
    return (
      <Grid
        container
        item
        justifyContent="center"
        alignContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          Ocorreu um erro com a conexão da medição, aguarde alguns dias para
          normalização do serviço da CCEE.
        </Grid>
      </Grid>
    );
  };

  const erroCCEEGraficoPeriodoAnterior = () => {
    return (
      <Grid
        container
        item
        justifyContent="center"
        alignContent="center"
        alignItems="center"
      >
        <Grid
          container
          item
          justifyContent="center"
          alignContent="center"
          alignItems="center"
        >
          <h1 className={classes.consumoLabel}>
            {moment(mes).subtract(1, "months").format("MMM/yyyy")}
          </h1>
        </Grid>

        <Grid item xs={12}>
          Ocorreu um erro com a conexão da medição, aguarde alguns dias para
          normalização do serviço da CCEE.
        </Grid>
      </Grid>
    );
  };

  return (
    <Container
      style={{ backgroundColor: pdf.status === true ? "#fbfbfb" : "" }}
    >
      <Grid
        container
        justifyContent="space-between"
        className="m-0 w-100"
        spacing={2}
      >
        <Grid
          item
          className={classes.titulo}
          style={{ color: pdf.status === true ? "black" : "" }}
        >
          Consumo atual
          {!pdf.status ? (
            <HtmlTooltip title="Consumo + Perdas - Proinfa">
              <InfoOutlinedIcon className={classes.iconInfo} />
            </HtmlTooltip>
          ) : (
            <></>
          )}
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item>
              {!pdf.status ? (
                <InputData
                  type="text"
                  id="mes"
                  name="mes"
                  format="MMM/yyyy"
                  customValue={mes}
                  maxDate={moment().endOf("month").toDate()}
                  onChange={(valor) => setMes(valor)}
                  renderIconShowHide={false}
                  className={classes}
                  views={["month", "year"]}
                  variant="inline"
                  autoOk
                />
              ) : (
                <div style={{ color: "black" }}>
                  {moment(mes).format("MMM/yyyy")}
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {exibirConsumo || exibirConsumoMesAnterior ? (
        <Loader loading={carregandoConsumoAtual} className="w-auto">
          <div id="teste">
            {pdf.status && consumoMesAtual?.contratoMax !== 0 ? (
              <div className={classes.contratoMaximo}>Contrato Máx.</div>
            ) : (
              <></>
            )}
            <Grid
              container
              className={classes.containerSuperior}
              alignItems="center"
              style={{ paddingLeft: 50 }}
            >
              {exibirConsumo && !exibirErroCCEEPrincipal ? (
                <>
                  <Grid item md={2}>
                    <Grid
                      container
                      item
                      alignItems="center"
                      justifyContent="flex-end"
                      className="pr-2"
                    >
                      <Grid
                        item
                        xs={12}
                        className={classes.containerTituloSuperior}
                      >
                        <Grid container justifyContent="flex-end">
                          <Grid item>
                            <h1
                              className={classes.consumoLabel}
                              style={{
                                color: pdf.status === true ? "black" : ""
                              }}
                            >
                              Meu Consumo
                            </h1>
                          </Grid>
                        </Grid>
                        <Grid container justifyContent="flex-end">
                          <Grid item>
                            <h3
                              className={classes.consumoValor}
                              style={{
                                color: pdf.status === true ? "black" : ""
                              }}
                            >
                              {consumoMesAtual.meuConsumo !== undefined
                                ? consumoMesAtual.meuConsumo.toLocaleString(
                                    "pt-BR"
                                  )
                                : 0}
                              MWh
                            </h3>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    md={10}
                    className={classes.containerGraficoSuperior}
                  >
                    <ReactApexChart
                      options={optionMesAtual}
                      series={dadosMesAtual}
                      type="bar"
                      height="150px"
                    />
                  </Grid>
                </>
              ) : (
                <Grid container alignItems="center" justifyContent="center">
                  {erroCCEEGrafico()}
                </Grid>
              )}
            </Grid>

            <Grid
              container
              className={classes.containerInferior}
              alignItems="center"
              style={{
                backgroundColor: pdf.status === true ? "#f0f0f0" : "",
                paddingLeft: 50
              }}
            >
              {exibirConsumoMesAnterior && !exibirErroCCEESecundario ? (
                <>
                  <Grid item md={2}>
                    <Grid
                      container
                      item
                      alignItems="center"
                      justifyContent="flex-end"
                      className="pr-2"
                    >
                      <Grid
                        item
                        xs={12}
                        className={classes.containerTituloSuperior}
                      >
                        <Grid container justifyContent="flex-end">
                          <Grid item xs={12}>
                            <h1
                              className={classes.consumoLabel}
                              style={{
                                color: pdf.status === true ? "black" : ""
                              }}
                            >
                              {consumoMesAnterior.labelMes}
                            </h1>
                          </Grid>
                        </Grid>
                        <Grid container justifyContent="flex-end">
                          <Grid item>
                            <h3
                              className={classes.consumoValor}
                              style={{
                                color: pdf.status === true ? "black" : ""
                              }}
                            >
                              {consumoMesAnterior.meuConsumo
                                ? consumoMesAnterior.meuConsumo.toLocaleString(
                                    "pt-BR"
                                  )
                                : 0}
                              MWh
                            </h3>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    md={10}
                    className={classes.containerGraficoSuperior}
                  >
                    <ReactApexChart
                      options={optionMesAnterior}
                      series={dadosMesAnterior}
                      type="bar"
                      height="100px"
                    />
                  </Grid>
                </>
              ) : (
                <Grid container alignItems="center" justifyContent="center">
                  {erroCCEEGraficoPeriodoAnterior()}
                </Grid>
              )}
            </Grid>
          </div>
        </Loader>
      ) : (
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          className={classes.noData}
        >
          {informacaoNaoDisponivel()}
        </Grid>
      )}
    </Container>
  );
};

export default ConsumoAtual;
