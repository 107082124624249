export default class OperacaoDto {
  constructor(operacaoControleId, clienteId, unidadeNome, cnpj, status, data) {
    this.operacaoControleId = operacaoControleId;
    this.clienteId = clienteId;
    this.unidadeNome = unidadeNome;
    this.cnpj = cnpj;
    this.status = status;
    this.data = data;
  }
}
