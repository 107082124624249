import MICROSERVICO from "../global/enumeradores/microServico";
import obterApiLuz from "./apiLuz";

const api = () => obterApiLuz(MICROSERVICO.SITE_LUZ_LEAD);

export const obterAnaliseCredito = async (params) => {
  const filtroQueryString = new URLSearchParams(params).toString();
  return api().get(
    `v1/AnaliseCredito/obter-analise-credito?${filtroQueryString}`
  );
};
