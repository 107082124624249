/* eslint-disable no-continue */
/* eslint-disable consistent-return */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-shadow */
/* eslint-disable prefer-template */
/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect, useCallback } from "react";
import { Grid, FormControl, InputLabel, Select, MenuItem, Tooltip, OutlinedInput,Typography } from "@mui/material";
import { Base } from "componentes/cores";
import { Botao, ModalConfirmacao } from "componentes";
import Loader from "componentes/loader";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { useSelector } from "react-redux";
import SelectAutocompleteCheckbox from "componentes/selectAutocompleteCheckbox";
import { useHistory, useParams } from "react-router-dom";
import { useStyles } from "./style";
import { listarDistribuidoras} from "../../../servicos/parametrizacaoDistribuidoras";
import { getExcelImpactoCustos } from "../../../servicos/exportacoes";
import BotaoRetornarListagem from "componentes/botaoRetornarListagem";
import moment from "moment";
import ModalInformacao from "componentes/modalVerificacao";


const ExportarImpactoCustoDistribuidora = () => {
    const usuarioGlobal = useSelector((state) => state.usuario);
    const classes = useStyles();

    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);

    const [distribuidoras, setDistribuidoras] = useState([]);
    const [listaDistribuidorasEditada, setListaDistribuidorasEditada] = useState([]);
    const [distribuidorasSelecionadas, setDistribuidorasSelecionadas] = useState([]);

    const [nomeArquivo, setNomeArquivo] = useState("ImpactoCustos.xlsx");
    const [showModalGerarExcel, setShowModalGerarExcel] = useState(false);
    const [modalInfo, setModalInfo] = useState(false);
    const [msgFalha, setMsgFalha] = useState();


    const handleDistribuidoras = useCallback( async () => {
        try {
            setIsLoading(true);
            const lista = await listarDistribuidoras(true);
            if (lista?.status === 200 && lista?.data) {
                setIsLoading(false);
                setDistribuidoras(lista?.data);
                setListaDistribuidorasEditada(lista?.data.map(listItem => { 
                    return {
                        id: listItem.idDistribuidora,
                        nome: listItem.titulo
                    }
                }));
            } else {
                setDistribuidoras([]);
                setIsLoading(false);
            }
        } catch (error) {
            console.info(error);
            setIsLoading(false);
        }
    })


     const onChangeDistribuidora = async (item) => {
        setDistribuidorasSelecionadas(item);
      }; 


    useEffect(() => {
        if (!distribuidoras?.length) handleDistribuidoras();
    }, [distribuidoras?.length]);


    const handleExportExcel =  useCallback (async() => {

        setIsDownloading(true);

        const distribuidorasSemTodos = listaDistribuidorasEditada.filter( ( el ) => {
            return el.nome !== 'Todos';
          } );

        let paramdistribuidoras = [];

        // Prepara parâmetro Distribuidora
        if(distribuidorasSelecionadas.some(d => d.nome === "Todos"))
        {
            paramdistribuidoras = distribuidorasSemTodos.map((item) => {
                return item.id
            }).join();
        }
        else if(distribuidorasSelecionadas.length > 0)
        {
            paramdistribuidoras = distribuidorasSelecionadas.map((item) => {
                return item.id
            }).join();
        }
        else{
            setShowModalGerarExcel(false);
            store.dispatch(
                alertaExibir({
                  tipo: "warning",  
                  mensagem: "Escolha ao menos uma distribuidora para exportação."
                })
              );
              return;
        }

        /*
            Se parâmetro distribuidoras foi carregado com sucesso
        */
 
        if(distribuidoras.length > 0)
        {
            try
            {

                const response = await getExcelImpactoCustos(paramdistribuidoras,nomeArquivo);

                setDistribuidorasSelecionadas([]);
                
                setShowModalGerarExcel(false);

                if(response !== "Sucesso")
                {
                    setMsgFalha(`${response}`);
                    setModalInfo(true);
                }
                else
                {
                    store.dispatch(
                        alertaExibir({
                            tipo: "success",  
                            mensagem: "Exportação concluída com sucesso."
                        })
                    );
                }


            }
            catch(error)
            {
                setDistribuidorasSelecionadas([]);
                setIsDownloading(false);
                setShowModalGerarExcel(false);
                setMsgFalha(`Erro não previsto: ${error}`);
                setModalInfo(true);
            }
        }
        setIsDownloading(false);
    });

    
    return <>
        <ModalInformacao
            titulo={"Exportação não realizada com a seguinte falha:"}
            exibir={modalInfo}
            mensagem={msgFalha}
            onClose={() => {
            setModalInfo(false);
            }}
            showBotao={false}
        />        
        <Loader loading={isLoading} className="w-auto">
            <ModalConfirmacao
            item={showModalGerarExcel}
            onCancelar={() => setShowModalGerarExcel(false)}
            onConfirmar={() => handleExportExcel()}
            className = {classes.container}
            mensagem={
                <pre>
                    <Loader loading={isDownloading} className="w-auto">
                    <strong><p>Gerar planilha excel com Impacto dos Custos e fazer download?</p></strong>
                    </Loader>            
                    <FormControl variant="outlined" >
                        <InputLabel htmlFor="outlined-nome-arquivo-excel">Nome do Arquivo</InputLabel>
                        <Tooltip 
                            placement="top"
                            title={
                                <h6>Nome sugerido. Aceite ou edite antes de confirmar.</h6>
                            }
                        >
                            <OutlinedInput
                                id="outlined-nome-arquivo-excel"
                                type="text"
                                value={nomeArquivo}
                                onChange={(e) => setNomeArquivo(e.target.value)}
                                label="Nome do Arquivo a Gerar"
                            />               
                        </Tooltip>                 
                    </FormControl>
                </pre>
            }
            />          
            <Grid container item alignItems="center">
            <Typography variant = "h4" style={{color: Base.OffWhite, fontSize: 20}}>
                    Exportação de Impacto dos Custos</Typography>0
            </Grid>
            <Grid container spacing={3} alignItems="center">

                <Grid item xs={12} className={classes.backButton} style={{paddingBottom: "60px"}}>
                        <BotaoRetornarListagem voltarPagina />
                </Grid>
                <Grid></Grid>
            </Grid>

            <Grid container style={{ paddingLeft: "1.5em", paddingRight: "1.5em" }}>
                <Grid
                    container
                    spacing={3}
                    alignItems="center"
                    className={classes.container}
                    justifyContent="space-between"
                >   
                    <Grid item xs={5}>
                        <SelectAutocompleteCheckbox 
                            id="selectDistribuidoraId"
                            name="distribuidora"
                            options={listaDistribuidorasEditada}
                            label="Distribuidora"
                            style={classes}
                            getOptionLabel={(option) => option.nome || ""}
                            onChange={(e, item) => {
                                onChangeDistribuidora(item);
                            }}
                            value={distribuidorasSelecionadas}
                            allowClear
                        />
                    </Grid>
                    <Grid item xs={4}>
                    </Grid>
                </Grid>
                <Grid container alignItems="left" style={{ paddingTop: "25px" }}>
                    <Grid xs={2} style={{ paddingTop: "25px" }}>
                        <Botao
                            type="button"
                            label="Gerar Excel"
                            color={Base.Mango}
                            className={classes.button}
                            onClick={()=>{setShowModalGerarExcel(true)}}
                            disabled = {distribuidorasSelecionadas.length === 0}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Loader>
    </>;
}

export default ExportarImpactoCustoDistribuidora;
