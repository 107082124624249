export function salvarDadosEnergia(dados) {
  return {
    type: "@demo/salvarDadosEnergia",
    payload: dados
  };
}

export function salvarHistorico(dados) {
  return {
    type: "@demo/salvarHistorico",
    payload: dados
  };
}

export function mostrarHistorico(mostrar) {
  return {
    type: "@demo/mostrarHistorico",
    payload: mostrar
  };
}
