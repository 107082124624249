import React, { useState, useEffect } from "react";
import moment from "moment";

// Componentes
import { Grid } from "@mui/material";
import { SelectArredondado, Loader } from "../../../componentes";
import TabelaHistorico from "../Componentes/TabelaHistorico";
import { meses } from "./meses";
import InputData from "../../../componentes/inputTextoData";
import InputBlock from "../Componentes/InputBlock";

// Redux
import { store } from "../../../global/redux";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";

// Serviços
import {
  listarPrecos,
  listarHistoricoPaginado
} from "../../../servicos/precoServico";

// Styles
import { useStyles } from "./style";

const Historico = () => {
  const classes = useStyles();
  const [filtro, setFiltro] = useState({
    submercadoId: 1,
    pagina: 1,
    tamanhoPaginacao: 7680
  });
  const [totalPagina, setTotalPagina] = useState(1);
  const [ultimaAtualizacao, setUltimaAtualizacao] = useState("");
  const [carregando, setCarregando] = useState(0);
  const [historico, setHistorico] = useState([]);
  const [precosAtuais, setPrecosAtuais] = useState([]);

  const salvarUltimaAtualizacao = (precos) => {
    if (precos?.length) {
      const max = new Date(
        Math.max(...precos.map((e) => new Date(e.dataFimVigencia)))
      );

      if (max) {
        setUltimaAtualizacao(moment(max).format("DD/MM/yyyy HH:mm"));
      }
    } else {
      setUltimaAtualizacao("");
    }
  };

  const buscarPrecosAtuais = async (filtroPaginacao) => {
    try {
      setCarregando(true);
      const resposta = await listarPrecos({
        submercadoId: filtroPaginacao.submercadoId,
        tamanhoPaginacao: 800
      });
      const { precos } = resposta.data;

      setPrecosAtuais(precos || []);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    } finally {
      setCarregando(false);
    }
  };

  const buscarHistorico = async (filtroPaginacao) => {
    try {
      setCarregando(true);
      if (Number(filtroPaginacao?.mes) === 0) delete filtroPaginacao.mes;
      if (filtroPaginacao?.dataAtual === null) delete filtroPaginacao.dataAtual;
      if (filtroPaginacao?.dataAtual)
        filtroPaginacao.dataAtual = moment(filtroPaginacao.dataAtual).format(
          "yyyy-MM-DD"
        );
      const resposta = await listarHistoricoPaginado({
        ...filtroPaginacao
      });
      const { precos, totalPaginas } = resposta.data;

      if (precos) {
        setHistorico(precos);
        setTotalPagina(totalPaginas);
        salvarUltimaAtualizacao(precos);
      } else {
        setHistorico([]);
        setTotalPagina(1);
        salvarUltimaAtualizacao([]);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    } finally {
      setCarregando(false);
    }
  };

  const onChangeData = (data) => {
    setFiltro((prevState) => ({
      ...prevState,
      dataAtual: data ? moment(data).toDate() : data
    }));

    if (
      data &&
      (Number.isNaN(data.getFullYear()) || data.getFullYear() < 1500)
    ) {
      return;
    }

    buscarHistorico({
      ...filtro,
      dataAtual: data
    });
  };

  const onChangePagina = (pagina) => {
    setFiltro((prevState) => ({
      ...prevState,
      pagina
    }));

    buscarHistorico({ ...filtro, pagina });
  };

  useEffect(() => {
    buscarHistorico(filtro);
    buscarPrecosAtuais(filtro);
  }, []);

  return (
    <>
      <Grid container>
        <Grid item lg={3} md={3} sm={4} xs={12}>
          <SelectArredondado
            select
            id="mes"
            name="mes"
            label="Mês"
            placeholder="Mês"
            valueKey="valor"
            valueName="nome"
            className={classes.select}
            labelClassName={classes.label}
            dataSource={meses}
            value={filtro.mes}
            onChange={(e) => {
              setFiltro((prevState) => ({
                ...prevState,
                mes: e.target.value
              }));
              buscarHistorico({
                ...filtro,
                mes: e.target.value
              });
            }}
            styleForm={false}
            allowClear
          />
        </Grid>
        <Grid item lg={3} md={3} sm={4} xs={12}>
          <InputData
            type="text"
            id="dataAtual"
            name="dataAtual"
            label="Data"
            placeholder="Data"
            customValue={filtro.dataAtual}
            onChange={(valor) => onChangeData(valor)}
            renderIconShowHide={false}
            className={classes}
          />
        </Grid>
        <Grid item lg={3} md={3} sm={4} xs={12}>
          <SelectArredondado
            select
            id="submercado"
            name="submercado"
            label="submercado"
            placeholder="submercado"
            valueKey="valor"
            valueName="nome"
            className={classes.select}
            dataSource={[
              { nome: "Sudeste", valor: 1 },
              { nome: "Sul", valor: 2 },
              { nome: "Nordeste", valor: 3 },
              { nome: "Norte", valor: 4 }
            ]}
            value={filtro.submercadoId}
            onChange={(e) => {
              setFiltro((prevState) => ({
                ...prevState,
                submercadoId: e.target.value
              }));
              buscarHistorico({ ...filtro, submercadoId: e.target.value });
              buscarPrecosAtuais({ ...filtro, submercadoId: e.target.value });
            }}
            styleForm={false}
            allowClear
          />
        </Grid>
        <Grid item lg={3} md={3} sm={4} xs={12}>
          <InputBlock
            type="text"
            id="ultimaAtualizacao"
            name="ultimaAtualizacao"
            label="Última atualização"
            value={ultimaAtualizacao}
            className="w-100"
            readOnly
            disabled
          />
        </Grid>
      </Grid>
      <Grid className="my-3">
        <Loader loading={carregando}>
          <TabelaHistorico
            linhasHistorico={historico}
            linhasAtuais={precosAtuais}
            onChangePagina={(totalPaginas, pagina) => onChangePagina(pagina)}
            totalPaginas={totalPagina}
            mes={filtro.mes}
            submercado={filtro.submercadoId}
          />
        </Loader>
      </Grid>
    </>
  );
};

export default Historico;
