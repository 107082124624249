import styled from "styled-components";
import theme from "themes";

export const Button = styled.button`
  color: ${theme.color.labelColor};
  border-color: ${theme.color.borderColor};
  width: 100%;
  &:hover {
    color: ${theme.color.hoverLabelColor};
    background-color: ${theme.color.hoverBgColor};
    border-color: ${theme.color.hoverBorderColor};
  },
  border-radius:"50rem"
`;

export const Icone = styled.img`
  max-height: 27px;
  object-fit: cover;
`;
