import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const api = () => obterApi(MICROSERVICO.MANAGEMENT);

export const salvarLeadClienteTestemunha = async (id, dados) => {
  const metodo = id ? "patch" : "post";

  let url = "v1/LeadClienteTestemunha";
  if (id > 0) {
    url = `v1/LeadClienteTestemunha/Update/${dados?.id}`;
  }

  return api()[metodo](url, dados);
};

export const excluirLeadClienteTestemunha = async (id) => {
  const metodo = "delete";
  return api()[metodo](`v1/LeadClienteTestemunha/${id}`);
};
