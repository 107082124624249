import React, { useCallback, useEffect, useState } from "react";
import { Box, Grid, Tooltip } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import { useSelector } from "react-redux";
import { InfoOutlined } from "@mui/icons-material";
import { TituloEconomia } from "./style";
import Data from "./data";

import Cofre from "../../../assets/consumoEnergia/cofre.png";
import Consumo from "../../../assets/consumoEnergia/consumo.png";
import DataImg from "../../../assets/consumoEnergia/data.png";
import Dinheiro from "../../../assets/consumoEnergia/dinheiro.png";
import Velocimento from "../../../assets/consumoEnergia/velocimetro.png";

import { Base } from "../../../componentes/cores";
import CardConsumoEconomia from "../../../componentes/cardConsumoEconomia";
import {
  getTelemetriaDiario,
  getTelemetriaResumo
} from "../../../servicos/temetriaGDServico";
import { store } from "../../../global/redux";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";
import { Loader } from "../../../componentes";
import UnidadeNaoSelecionada from "../../../componentes/unidadeNaoSelecionada";
import {
  unidadeConsumidoraGD,
  unidadeConsumidoraGDColaborador
} from "../../../servicos/unidadeConsumidoraGDServico";
import SemDados from "../../../componentes/semDados";
import {
  getConsumoProjecao,
  getDadosFaturas
} from "../../../servicos/dgIntegracaoServico";
import ConsumoNegativo from "../componentes/ConsumoNegativo";

const ResumoPrincipal = () => {
  const [carregandoDados, setCarregandoDados] = useState(false);
  const [resumo, setResumo] = useState(null);
  const usuarioLogado = useSelector((state) => state.usuario);
  const [selecioneUnidade, setSelecioneUnidade] = useState(false);
  const [validacao, setValidacao] = useState(true);
  const [dadosFatura, setDadosFatura] = useState([]);
  const [consumoDiario, setDadosConsumoDiario] = useState(null);
  const [dadosUnidades, setDadosUnidade] = useState([]);
  const [consumoProjecao, setDadosConsumoProjecao] = useState(null);

  const {
    empresaSelecionada,
    clienteSelecionado,
    unidadeSelecionada,
    usuario
  } = usuarioLogado;

  const { tipo } = usuario;

  useEffect(() => {
    setSelecioneUnidade(true);
  }, []);

  const handleResumoPrincipal = useCallback(
    async (codigoMedidor, tarifa, diaFaturamento) => {
      try {
        setCarregandoDados(true);
        if (codigoMedidor) {
          const { data, status } = await getTelemetriaResumo(
            codigoMedidor,
            tarifa,
            diaFaturamento
          );
          if (data && status === 200) {
            setResumo(data);
            setValidacao(true);
          } else if (status === 204) {
            setResumo(null);
            setValidacao(false);
          }
        }
      } catch (error) {
        if (
          error?.response?.status !== 200 &&
          error?.response?.status !== 204
        ) {
          setValidacao(false);
        }
      } finally {
        setCarregandoDados(false);
      }
    },
    []
  );

  const handleDataConsumoDia = async (codigoDescricao, dataHoje, tarifa) => {
    try {
      if (codigoDescricao && dataHoje) {
        const { data, status } = await getTelemetriaDiario(
          codigoDescricao,
          dataHoje,
          dataHoje,
          tarifa
        );
        if (data && status === 200) {
          setDadosConsumoDiario(data);
        } else if (status === 204) {
          setDadosConsumoDiario(null);
        }
      }
    } catch (error) {
      if (error?.response?.status !== 200 && error?.response?.status !== 204) {
        setDadosConsumoDiario(null);
      }
    }
  };

  const handleDadosFaturas = async (unidadeId, dataInicial, dataHoje) => {
    try {
      const { data, status } = await getDadosFaturas(
        unidadeId,
        dataInicial,
        dataHoje
      );
      if (data.length > 0 && status === 200) {
        setDadosFatura(data);
      }
    } catch (error) {
      if (error?.response?.status !== 200 && error?.response?.status !== 204) {
        setDadosFatura(null);
      }
    }
  };

  const handleUnidadeConsumidora = async (unidadeId, dataHoje) => {
    try {
      const { data, status } =
        tipo === "Cliente"
          ? await unidadeConsumidoraGD()
          : await unidadeConsumidoraGDColaborador(unidadeId);
      if (data && status === 200) {
        const unidade =
          data?.unidadeConsumidoraGDList?.filter(
            (item) => item.unidadeConsumidoraId === unidadeId
          ) || [];
        setDadosUnidade(unidade);
        if (unidade?.[0]?.medidores?.length > 0) {
          const date = `${moment()?.format("YYYY-MM")}-${
            unidade?.[0]?.diaFaturamentoDistribuidora
          }`;
          const dataInicial = moment(date)
            ?.subtract(1, "month")
            ?.format("YYYY-MM-DD");

          handleResumoPrincipal(
            unidade?.[0]?.medidores[0].descricaoMedidor,
            unidade?.[0]?.somaTarifaCheiaBandeira,
            unidade?.[0]?.diaFaturamentoDistribuidora
          );
          handleDataConsumoDia(
            unidade?.[0]?.medidores[0].descricaoMedidor,
            dataHoje,
            unidade?.[0]?.somaTarifaCheiaBandeira
          );
          handleDadosFaturas(
            unidade[0]?.unidadeConsumidoraId,
            dataInicial,
            dataHoje
          );
          setSelecioneUnidade(true);
        } else {
          store.dispatch(
            alertaExibir({
              tipo: "warning",
              mensagem:
                "Não existe medidor cadastrada para esse unidade, selecione outra!"
            })
          );
        }
      }
    } catch (error) {
      if (error?.response?.status !== 200 && error?.response?.status !== 204) {
        setValidacao(false);
      }
    }
  };

  useEffect(() => {
    if (unidadeSelecionada) {
      moment.locale("pt");
      const dataHoje = moment().format("YYYY-MM-DD");
      handleUnidadeConsumidora(unidadeSelecionada, dataHoje);
    } else {
      setSelecioneUnidade(false);
    }
  }, [empresaSelecionada, clienteSelecionado, unidadeSelecionada]);

  const handleConsumoProjecao = async (unidadeId, cip, bandeira, tarifa) => {
    const { eptTotal, eptProjetado } = resumo;
    try {
      if (eptTotal && eptProjetado) {
        const { data, status } = await getConsumoProjecao(
          unidadeId,
          eptTotal,
          eptProjetado,
          cip,
          bandeira,
          tarifa
        );
        if (data && status === 200) {
          setDadosConsumoProjecao(data);
        } else if (status === 204) {
          setDadosConsumoProjecao(null);
        }
      }
    } catch (error) {
      if (error?.response?.status !== 200 && error?.response?.status !== 204) {
        setDadosConsumoProjecao(null);
      }
    }
  };

  useEffect(() => {
    if (dadosUnidades && resumo) {
      const unidadeId = dadosUnidades[0]?.unidadeConsumidoraId;
      const cip = dadosUnidades[0]?.cosip;
      const bandeiraTarifaria = dadosUnidades[0]?.bandeiraTarifaria;
      const tarifa = dadosUnidades[0]?.somaTarifaCheiaBandeira;

      handleConsumoProjecao(unidadeId, cip, bandeiraTarifaria, tarifa);
    }
  }, [dadosUnidades, resumo]);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const porcentagem = Math.round(
    (resumo?.eptTotal * 100) / resumo?.eptProjetado
  );
  const porcentagemf = Math.round(
    (resumo?.eptFTotal * 100) / resumo?.eptFProjetado
  );
  const porcentagemfMinimo =
    porcentagemf.length <= 15 && matches ? "15" : porcentagemf;
  const porcentagemMinimo =
    porcentagem.length <= 15 && matches ? "15" : porcentagem;

  return (
    <Loader loading={carregandoDados}>
      {selecioneUnidade ? (
        <Box component="div" px={2} py={4} boxShadow={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Data />
            </Grid>

            {consumoDiario === null || consumoDiario?.eptFPeriodo > 0 ? (
              <>
                {validacao ? (
                  <>
                    <Grid item xs={12} md={12}>
                      <Box display="flex">
                        <TituloEconomia>Consumo acumulado</TituloEconomia>
                        <Tooltip title="acumulado">
                          <InfoOutlined fontSize="small" />
                        </Tooltip>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={11}>
                      <Box
                        width="100"
                        sx={{ background: Base.GradientTextLuzOpacity }}
                        borderRadius="0px 24px 24px 0px"
                      >
                        <Box
                          sx={{ background: Base.GradientTextLuz }}
                          width={`${porcentagemMinimo}%`}
                          py="3px"
                          borderRadius="0px 24px 24px 0px"
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center"
                            }}
                          >
                            <Box
                              component="span"
                              fontStyle="normal"
                              fontWeight="400"
                              fontSize={{
                                xs: "10px",
                                sm: "15px",
                                md: "24px",
                                lg: "24px",
                                xl: "24px"
                              }}
                              color="#FFFFFF"
                              ml={{ xs: "2px", md: "10px", lg: "10px" }}
                            >
                              {`${resumo?.eptTotal || ""} kWh`}
                            </Box>
                            <Box
                              component="img"
                              src={Consumo}
                              alt="img-acumulado"
                              mr="2px"
                              height={{ xs: "21px", md: "100%" }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item md={1} />
                    <Grid item xs={12} md={11}>
                      <Box
                        component="div"
                        display="flex"
                        justifyContent="end"
                        color={Base.PrimaryLuzColor}
                      >
                        {`${resumo?.eptProjetado || ""} kWh`}
                      </Box>
                    </Grid>
                    <Grid item md={1} />
                    <Grid item xs={12} md={12}>
                      <Box display="flex">
                        <TituloEconomia>Valor da conta</TituloEconomia>
                        <Tooltip title="conta">
                          <InfoOutlined fontSize="small" ml={6} />
                        </Tooltip>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={11}>
                      <Box
                        width="100"
                        bgcolor={Base.RgbaCosmoLuz}
                        borderRadius="0px 24px 24px 0px"
                      >
                        <Box
                          bgcolor={Base.Cosmo}
                          width={`${porcentagemfMinimo}%`}
                          py="3px"
                          borderRadius="0px 24px 24px 0px"
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center"
                            }}
                          >
                            <Box
                              component="span"
                              fontStyle="normal"
                              fontWeight="400"
                              fontSize={{
                                xs: "10px",
                                sm: "15px",
                                md: "24px",
                                lg: "24px",
                                xl: "24px"
                              }}
                              color="#FFFFFF"
                              ml={{ xs: "2px", md: "10px", lg: "10px" }}
                            >
                              {`R$ ${resumo?.eptFTotal || ""}`}
                            </Box>
                            <Box
                              component="img"
                              src={Dinheiro}
                              alt="img-valor"
                              mr="2px"
                              height={{ xs: "21px", md: "100%" }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item md={1} />
                    <Grid item xs={12} md={11} mb={6}>
                      <Box
                        component="div"
                        display="flex"
                        justifyContent="end"
                        color={Base.PrimaryLuzColor}
                        mb={6}
                      >
                        {`R$ ${resumo?.eptFProjetado || ""}`}
                      </Box>
                    </Grid>
                    <Grid item md={1} />
                  </>
                ) : (
                  <SemDados />
                )}
                <CardConsumoEconomia
                  xs={12}
                  md={4}
                  dados={consumoDiario}
                  icon={Velocimento}
                  title="Consumo de hoje"
                  content={`R$ ${consumoDiario?.eptFPeriodo || ""} (${
                    consumoDiario?.eptPeriodo || ""
                  } KWh)`}
                />
                <CardConsumoEconomia
                  xs={12}
                  md={4}
                  dados={consumoProjecao}
                  icon={Cofre}
                  title="Sua próxima conta deve diminuir em"
                  content={`R$ ${
                    consumoProjecao?.economiaProjetadaLUZ || ""
                  } (${consumoProjecao?.desconto || ""}%)`}
                />
                <CardConsumoEconomia
                  xs={12}
                  md={4}
                  dados={dadosFatura?.length > 0}
                  icon={DataImg}
                  title="Consumo anterior"
                  content={`R$ ${
                    (dadosFatura?.length > 0 &&
                      dadosFatura[0]?.valorTotalFaturaLUZ) ||
                    ""
                  } (${
                    (dadosFatura?.length > 0 &&
                      dadosFatura[0]?.consumoFaturadokWh) ||
                    ""
                  } KWh)`}
                />
              </>
            ) : (
              <ConsumoNegativo />
            )}
          </Grid>
        </Box>
      ) : (
        <UnidadeNaoSelecionada />
      )}
    </Loader>
  );
};

export default ResumoPrincipal;
