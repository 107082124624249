import { store } from "../../../global/redux";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";
import RetornoEndpointDto from "../../../global/dto/retornoEndpointDto";
import {
  obterBeneficioDistribuidora,
  salvarDistribuidoraFatura,
  atualizaStatusDistribuidoraFatura
} from "../../../servicos/memberGetMemberServico";
import { buscarDistribuidoras } from "../../../servicos/distribuidorasServico";

export default class MemberGetMemberDistribuidora {
  static async obterBeneficioDistribuidora(
    filtro,
    setCarregandoBenificioDistribuidora
  ) {
    try {
      setCarregandoBenificioDistribuidora(true);
      const resultado = await obterBeneficioDistribuidora(filtro);

      return RetornoEndpointDto.Sucesso(
        "Distribuidora encontrada com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Erro ao encontrar beneficios distribuidora!"
      );
    } finally {
      setCarregandoBenificioDistribuidora(false);
    }
  }

  static async obterDistribuidora(setCarregandoDistribuidora) {
    try {
      setCarregandoDistribuidora(true);
      const resultado = await buscarDistribuidoras();
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Distribuidora não encontrados!");

      return RetornoEndpointDto.Sucesso(
        "Distribuidora obtido com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Distribuidora não encontrados!");
    } finally {
      setCarregandoDistribuidora(false);
    }
  }

  static async salvarDistribuidoraFatura(
    id,
    body,
    setCarregandoBenificioDistribuidora
  ) {
    try {
      setCarregandoBenificioDistribuidora(true);
      const resultado = await salvarDistribuidoraFatura(id, body);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Distribuidora fatura não encontrado!");

      return RetornoEndpointDto.Sucesso(
        "Distribuidora fatura salva com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Erro ao salvar distribuidora fatura!");
    } finally {
      setCarregandoBenificioDistribuidora(false);
    }
  }

  static async atualizaStatusDistribuidoraFatura(
    params,
    setCarregandoBenificioDistribuidora
  ) {
    try {
      setCarregandoBenificioDistribuidora(true);
      const resultado = await atualizaStatusDistribuidoraFatura(params);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro(
          "Status distribuidora fatura não encontrado!"
        );

      return RetornoEndpointDto.Sucesso(
        "Status distribuidora fatura atualizado com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Erro ao atualizar status distribuidora fatura!"
      );
    } finally {
      setCarregandoBenificioDistribuidora(false);
    }
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }

  static exibirSucesso(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem
      })
    );
  }
}
