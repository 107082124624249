/* eslint-disable radix */
/* eslint-disable no-use-before-define */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  Grid,
  Card,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  Checkbox
} from "@mui/material";
import BotaoRetornarListagem from "componentes/botaoRetornarListagem";
import MaterialInputTexto from "componentes/inputTexto/materialInput";
import Loader from "componentes/loader";
import Botao from "componentes/botao";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";

import {
  salvarRecorteItem,
  obterRecortePorCodigo,
  obterRecorteItemPorCodigo,
  obterRecorteItemsRefPorCodigoRecorteItem
} from "../../../../servicos/projecaoInsumos";

import { useStyles } from "./style";
import ConsolidacaoMedicaoHelper from "paginas/AlteracaoMedicoes/Consolidadas/helper";

const AlteraRecorteItem = () => {
  const { codigoInsumoRecorte, codigoRecorteItem } = useParams();
  const classes = useStyles();

  const { errors, register, handleSubmit } = useForm({
    reValidateMode: "onSubmit"
  });

  const [selectBool, setSelectBool] = useState([
    { value: true, text: "Sim" },
    { value: false, text: "Não" }
  ]);

  const [nome, setNome] = useState();
  const [guia, setGuia] = useState();

  const [item, setItem] = useState();
  const [obrigatorio, setObrigatorio] = useState();
  const [deslocaColunas, setDeslocaColunas] = useState(0);
  const [deslocaLinhas, setDeslocaLinhas] = useState(0);
  const [usaExpReg, setUsaExpReg] = useState(false);
  const [expReg, setExpReg] = useState();

  const [itemsRef, setItemsRef] = useState([]);

  const [carregandoDados, setCarregandoDados] = useState(false);
  const [carregandoRecorte, setCarregandoRecorte] = useState(false);
  const [carregandoRecorteItem, setCarregandoRecorteItem] = useState(false);
  const [carregandoRecorteItemRefs, setCarregandoRecorteItemRefs] = useState(
    false
  );

  const handleObterRecorte = useCallback(async () => {
    try {
      setCarregandoDados(true);
      setCarregandoRecorte(true);
      const response = await obterRecortePorCodigo(codigoInsumoRecorte);
      if (response.status === 200 && response.data) {
        setNome(response.data?.nome);
        setGuia(response.data?.guia);
      }
      setCarregandoRecorte(false);
    } catch (error) {
      setCarregandoRecorte(false);
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  }, []);

  useEffect(() => {
    if (codigoInsumoRecorte) handleObterRecorte();
  }, [codigoInsumoRecorte]);

  const handleObterItemRecorte = useCallback(async () => {
    console.info("handleObterItemRecorte");
    try {
      setCarregandoDados(true);
      setCarregandoRecorteItem(true);
      const response = await obterRecorteItemPorCodigo(codigoRecorteItem);
      if (response.status === 200 && response.data) {
        setItem(response.data.item);
        setObrigatorio(response.data.obrigatorio);
        setDeslocaColunas(response.data.deslocaColunas);
        setDeslocaLinhas(response.data.deslocaLinhas);
        setUsaExpReg(response.data.usaExpReg);
        setExpReg(response.data.expReg);
      }
      setCarregandoRecorteItem(false);
    } catch (error) {
      setCarregandoRecorteItem(false);
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  }, []);

  useEffect(() => {
    if (codigoRecorteItem) handleObterItemRecorte();
  }, [codigoRecorteItem]);

  const handleObterItemsRecorte = useCallback(async () => {
    try {
      setCarregandoDados(true);
      setCarregandoRecorteItemRefs(true);
      const response = await obterRecorteItemsRefPorCodigoRecorteItem(
        codigoRecorteItem
      );
      if (response.status === 200 && response.data) {
        const lista = response?.data;
        const listaOrganizada = [];
        if (lista) {
          lista.map((item) =>
            listaOrganizada.push({
              codigoRecorteItem: codigoRecorteItem,
              referencia: item.referencia
            })
          );
        }
        setItemsRef(listaOrganizada);
      }
      setCarregandoRecorteItemRefs(false);
    } catch (error) {
      setCarregandoRecorteItemRefs(false);
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  }, []);

  useEffect(() => {
    if (codigoRecorteItem) handleObterItemsRecorte();
  }, [codigoRecorteItem]);

  const handleCarregandoDados = useCallback(() => {
    setCarregandoDados(false);
  }, []);

  useEffect(() => {
    if (
      carregandoDados &&
      !carregandoRecorte &&
      !carregandoRecorteItem &&
      !carregandoRecorteItemRefs
    )
      handleCarregandoDados();
  }, [
    carregandoDados &&
      !carregandoRecorte &&
      !carregandoRecorteItem &&
      !carregandoRecorteItemRefs,
    handleCarregandoDados
  ]);

  const onChangeUsaExpReg = (event) => {
    setUsaExpReg(event.target.checked);
  };

  const handleChangeReferencia = (event) => {
    const novaReferencia = event.target.value.trim();
    let newListReferencia = itemsRef;
    newListReferencia[event.target.id] = {
      codigoRecorteItem: codigoRecorteItem,
      referencia: novaReferencia
    };
    newListReferencia = [...new Set(newListReferencia)];
    setItemsRef(
      newListReferencia.filter((item) => item.referencia?.trim() !== "")
    );
  };

  const addReferencia = () => {
    if (itemsRef === []) {
      setItemsRef([
        {
          codigoRecorteItem: codigoRecorteItem,
          referencia: ""
        }
      ]);
    } else {
      setItemsRef((state) => [
        ...state,
        {
          codigoRecorteItem: codigoRecorteItem,
          referencia: ""
        }
      ]);
    }
  };

  const removeReferencia = (valueRemove) => {
    const newListReferencia = itemsRef?.filter(
      (item) => item.referencia !== valueRemove
    );
    setItemsRef(newListReferencia);
  };

  const editarRecorteItem = async () => {
    try {
      const listaReferencias = [];
      if (itemsRef) {
        itemsRef.map((item) =>
          listaReferencias.push({
            codigoRecorteItem: codigoRecorteItem,
            referencia: item.referencia
          })
        );
      }

      const parametroSalvar = {
        Codigo: codigoRecorteItem,
        CodigoInsumoRecorte: codigoInsumoRecorte,
        Item: item,
        Obrigatorio: obrigatorio,
        DeslocaColunas: deslocaColunas,
        DeslocaLinhas: deslocaLinhas,
        UsaExpReg: usaExpReg,
        ExpReg: expReg,
        Referencias: listaReferencias
      };
      console.log("parametroSalvar", parametroSalvar);

      const salvou = await salvarRecorteItem(parametroSalvar);
      if (salvou?.status === 200 || salvou?.status === 204) {
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: "Item Recorte atualizado com sucesso"
          })
        );
      } else {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "Não foi possível atualizar o Item Recorte"
          })
        );
      }
    } catch (error) {
      console.log(error.message);
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: error.message
        })
      );
    }
  };

  const aoEnviarFormulario = (dados) => {
    if (itemsRef !== undefined) {
      const newListReferencias = itemsRef?.filter(
        (item) => item.referencia?.trim() !== ""
      );
      setItemsRef(newListReferencias);
    }
    if (item === null || item === undefined || item === "") {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Informe o Campo Item"
        })
      );
    } else if (obrigatorio === null || obrigatorio === undefined) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Informe o Item Recorte é Obrigatório"
        })
      );
    } else if (
      deslocaColunas === null ||
      deslocaColunas === undefined ||
      deslocaColunas === ""
    ) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Informe o Campo Desloca Colunas"
        })
      );
    } else if (
      deslocaLinhas === null ||
      deslocaLinhas === undefined ||
      deslocaLinhas === ""
    ) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Informe o Campo Desloca Linhas"
        })
      );
    } else if (usaExpReg === null || usaExpReg === undefined) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Informe o Campo Usar Expressão Regular"
        })
      );
    } else if (
      usaExpReg === true &&
      (expReg === null || expReg === undefined || expReg === "")
    ) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Informe o Campo Expressão Regular"
        })
      );
    } else {
      editarRecorteItem(dados);
    }
  };

  return (
    <>
      <Loader loading={carregandoDados} className="w-auto">
        <form
          className="needs-validation"
          onSubmit={handleSubmit(aoEnviarFormulario)}
        >
          <Card className={classes.container}>
            <Grid container p={2} spacing={2} className={classes.container}>
              <Grid item xs={9}>
                {" "}
              </Grid>
            </Grid>
            <Grid
              container
              px={2}
              spacing={4}
              className={classes.container}
              justifyContent="flex-end"
            >
              <Grid item sm={11}>
                {nome !== "" && guia !== "" ? nome + " - " + guia : ""}
              </Grid>
              <Grid item sm={1}>
                <BotaoRetornarListagem justify="flex-end" voltarPagina={true} />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={8}>
                <Grid container p={2} spacing={2} className={classes.container}>
                  <Grid item sm={6}>
                    <Loader loading="">
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <MaterialInputTexto
                          type="text"
                          id="item"
                          name="item"
                          label="Item"
                          renderIconShowHide={false}
                          defaultValue={item !== "" ? item : ""}
                          onBlur={(e) => setItem(e.target.value)}
                        />
                      </FormControl>
                    </Loader>
                  </Grid>
                  <Grid item sm={6}>
                    <Loader loading="">
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <InputLabel id="obrigatorio-label">
                          Obrigatório
                        </InputLabel>
                        <Select
                          variant="outlined"
                          labelId="obrigatorio-label"
                          id="obrigatorio"
                          value={obrigatorio ?? ""}
                          onChange={(e) => setObrigatorio(e.target.value)}
                          label="Obrigatório"
                          fullWidth
                        >
                          {selectBool.map((item) => (
                            <MenuItem value={item.value}>{item.text}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Loader>
                  </Grid>
                </Grid>
                <Grid container p={2} spacing={2} className={classes.container}>
                  <Grid item sm={6}>
                    <Loader loading="">
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <MaterialInputTexto
                          type="text"
                          id="deslocaColunasId"
                          name="deslocaColunasName"
                          label="Desloca Colunas"
                          renderIconShowHide={false}
                          defaultValue={
                            deslocaColunas !== "" ? deslocaColunas : ""
                          }
                          value={deslocaColunas !== "" ? deslocaColunas : ""}
                          onBlur={(e) => setDeslocaColunas(e.target.value)}
                        />
                      </FormControl>
                    </Loader>
                  </Grid>
                  <Grid item sm={6}>
                    <Loader loading="">
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <MaterialInputTexto
                          type="text"
                          id="deslocaLinhas"
                          name="deslocaLinhas"
                          label="Desloca Linhas"
                          renderIconShowHide={false}
                          defaultValue={
                            deslocaLinhas !== "" ? deslocaLinhas : ""
                          }
                          value={deslocaLinhas !== "" ? deslocaLinhas : ""}
                          onBlur={(e) => setDeslocaLinhas(e.target.value)}
                        />
                      </FormControl>
                    </Loader>
                  </Grid>
                </Grid>
                <Grid container p={2} spacing={2} className={classes.container}>
                  <Grid item sm={6}>
                    <Loader loading="">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={usaExpReg}
                            onChange={onChangeUsaExpReg}
                            classes={{ colorPrimary: classes.checkbox }}
                          />
                        }
                        label="Usar Expressão Regular?"
                        style={{ marginBottom: 0 }}
                      />
                    </Loader>
                  </Grid>
                  <Grid item sm={6}>
                    <Loader loading="">
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <MaterialInputTexto
                          type="text"
                          id="expReg"
                          name="expReg"
                          label="Expressão Regular"
                          renderIconShowHide={false}
                          defaultValue={expReg !== "" ? expReg : ""}
                          onBlur={(e) => setExpReg(e.target.value)}
                        />
                      </FormControl>
                    </Loader>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4} spacing={1}>
                <Grid container spacing={2} className={classes.container}>
                  <Grid item sm={6} justifyContent="flex-start">
                    <b>Referências</b>
                  </Grid>
                  <Grid item sm={6} justifyContent="flex-start">
                    <Grid
                      container
                      p={2}
                      spacing={2}
                      className={classes.container}
                    >
                      <Grid item sm={12}>
                        <button
                          type="button"
                          className={classes.botao}
                          onClick={() => {
                            addReferencia();
                          }}
                        >
                          + Referência
                        </button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  p={1}
                  spacing={2}
                  className={classes.container}
                  justifyContent="right"
                >
                  {itemsRef?.map((item, i) => (
                    <Grid
                      container
                      p={1}
                      spacing={2}
                      className={classes.container}
                    >
                      <Grid item sm={10}>
                        <MaterialInputTexto
                          type="text"
                          id={i}
                          name="item.referencia"
                          value="item.referencia"
                          renderIconShowHide={false}
                          defaultValue={item.referencia ?? ""}
                          onBlur={(event) => handleChangeReferencia(event)}
                          ref={register({
                            required: ""
                          })}
                          errors={errors}
                        />
                      </Grid>
                      <Grid item sm={2}>
                        <button
                          type="button"
                          className={classes.botao}
                          onClick={() => {
                            removeReferencia(item.referencia);
                          }}
                        >
                          X
                        </button>
                      </Grid>
                    </Grid>
                  ))}
                  <br />
                </Grid>
              </Grid>
            </Grid>
            <Grid container p={2} spacing={3} className={classes.container}>
              <Grid item sm={4}>
                <Botao
                  type="submit"
                  label="Salvar"
                  className={classes.button}
                />
              </Grid>
            </Grid>
          </Card>
        </form>
      </Loader>
    </>
  );
};

export default AlteraRecorteItem;
