import React, { useState, useEffect, useCallback, useMemo } from "react";
import shortid from "shortid";
import TuneIcon from "@mui/icons-material/Tune";
import { Grid } from "@mui/material";
import { useForm, useWatch } from "react-hook-form";
import { useHistory } from "react-router-dom";
import {
  listarSerieHistorica,
  atualizarSerieHistorica,
  listarOrganizarPor,
  listarFiltrarPor,
  listarTipoPor
} from "../../../servicos/seriesHistoricas";
import { useStyles } from "./style";
import { Base } from "componentes/cores";
import Loader from "componentes/loader";
import ModalConfirmacao from "componentes/modalConfirmacao";
import MaterialInputTexto from "componentes/inputTexto/materialInput";
import BotaoFiltroOrdenacao from "componentes/botaoFiltroOrdenar";
import BotaoCadastro from "componentes/botaoCadastro";
import Botao from "componentes/botao";
import ItemListaExpansivel from "componentes/itemListaExpansivel";
import RotasDTO from "../../../rotas/rotasUrlDto";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";

const SeriesHistoricas = () => {
  const classes = useStyles();
  const history = useHistory();

  const [carregandoSeries, setCarregandoSeries] = useState(false);
  const [seriesHistoricas, setSeriesHistoricas] = useState([]);
  const [listaSeriesHistoricas, setListaSeriesHistoricas] = useState([]);
  const [modalExclusao, setModalExclusao] = useState(false);
  const [ordemSelecionada, setOrdemSelecionada] = useState(null);
  const [filtroSelecionado, setFiltroSelecionado] = useState(null);
  const [tipoSelecionado, setTipoSelecionado] = useState(null);
  const [naoExibirFiltrosTipo, setNaoExibirFiltrosTipo] = useState(false);

  const { register, control } = useForm({
    reValidateMode: "onSubmit"
  });

  const textoBusca = useWatch({
    control,
    name: "textoBusca",
    defaultValue: ""
  });

  const stringBusca = useMemo(() => {
    return textoBusca;
  }, [textoBusca]);

  useEffect(() => {
    setTimeout(() => {
      handleFiltrarSeriesHistoricas();
    }, 100);
  }, [stringBusca]);

  const handleFiltrarSeriesHistoricas = () => {
    let dadosFiltrados = [];

    if (listaSeriesHistoricas != null && listaSeriesHistoricas.length > 0) {
      dadosFiltrados = listaSeriesHistoricas;

      if (stringBusca != undefined && stringBusca.length > 0) {
        if (filtroSelecionado == null || filtroSelecionado == undefined) {
          dadosFiltrados = dadosFiltrados.filter(
            filterSeriesHistoricasByCodigo
          );
        } else {
          let seriesFiltradasPorCampo = [];

          if (tipoSelecionado == 1) {
            seriesFiltradasPorCampo = dadosFiltrados.filter(
              filterSeriesHistoricasByCategoria
            );
          } else if (tipoSelecionado == 2) {
            seriesFiltradasPorCampo = dadosFiltrados.filter(
              filterSeriesHistoricasByPeriodicicade
            );
          } else if (tipoSelecionado == 3) {
            seriesFiltradasPorCampo = dadosFiltrados.filter(
              filterSeriesHistoricasByUnidade
            );
          }
          if (
            seriesFiltradasPorCampo != null &&
            seriesFiltradasPorCampo != undefined
          ) {
            dadosFiltrados = seriesFiltradasPorCampo;
          }
        }
      }
      if (filtroSelecionado == 0 || filtroSelecionado == 1) {
        let seriesAtivas = dadosFiltrados?.filter(
          filterSeriesHistoricasByAtivo
        );
        dadosFiltrados = seriesAtivas;
      }
      let dadosOrdenados = [];

      if (ordemSelecionada == 2) {
        dadosOrdenados = dadosFiltrados
          .sort(function (a, b) {
            return a.idSerieHistorica > b.idSerieHistorica
              ? 1
              : b.idSerieHistorica > a.idSerieHistorica
              ? -1
              : 0;
          })
          .reverse();
      } else {
        dadosOrdenados = dadosFiltrados.sort(function (a, b) {
          return a.codigo > b.codigo ? 1 : b.codigo > a.codigo ? -1 : 0;
        });
      }
      setSeriesHistoricas(dadosOrdenados);
    }
  };

  function filterSeriesHistoricasByCodigo(obj) {
    if (
      obj.codigo != null &&
      obj.codigo.toUpperCase().includes(textoBusca.toUpperCase())
    ) {
      return true;
    } else {
      return false;
    }
  }

  function filterSeriesHistoricasByUnidade(obj) {
    if (
      obj.descricaoUnidade != undefined &&
      obj.descricaoUnidade.toUpperCase().includes(textoBusca.toUpperCase())
    ) {
      return true;
    } else {
      return false;
    }
  }

  function filterSeriesHistoricasByCategoria(obj) {
    if (
      obj.descricaoCategoria != undefined &&
      obj.descricaoCategoria.toUpperCase().includes(textoBusca.toUpperCase())
    ) {
      return true;
    } else {
      return false;
    }
  }

  function filterSeriesHistoricasByPeriodicicade(obj) {
    if (
      obj.descricaoPeriodicidade != undefined &&
      obj.descricaoPeriodicidade
        .toUpperCase()
        .includes(textoBusca?.toUpperCase())
    ) {
      return true;
    } else {
      return false;
    }
  }

  function filterSeriesHistoricasByAtivo(obj) {
    if (obj.ativo == filtroSelecionado) {
      return true;
    } else {
      return false;
    }
  }

  const handleSeriesHistoricas = async () => {
    try {
      setCarregandoSeries(true);
      const lista = await listarSerieHistorica();
      if (lista?.status === 200 && lista?.data) {
        setSeriesHistoricas(lista?.data);
        setListaSeriesHistoricas(lista?.data);
      } else {
        setSeriesHistoricas([]);
      }
      setCarregandoSeries(false);
    } catch (error) {
      console.info(error);
      setCarregandoSeries(false);
    }
  };

  const handleClickOrdemSelecionada = (event) => {
    const val = ordemSelecionada === event ? null : event;
    setOrdemSelecionada(val);
  };

  const handleClickFiltroSelecionado = (event) => {
    const val = filtroSelecionado === event ? null : event;
    setFiltroSelecionado(val);
  };

  const handleClickTipoSelecionado = (event) => {
    const val = tipoSelecionado === event ? null : event;
    setTipoSelecionado(val);
  };

  const handleClickAtivarAgora = async () => {
    handleFiltrarSeriesHistoricas();
  };

  const obterSeriesHistoricas = useCallback(async () => {
    handleSeriesHistoricas();
  }, []);

  useEffect(() => {
    if (!seriesHistoricas?.length) handleSeriesHistoricas();
  }, [seriesHistoricas.length, handleSeriesHistoricas]);

  const onClickNovaSerieHistorica = () => {
    history.push(`${RotasDTO.SeriesHistoricas}/cadastro`);
  };

  const onClickImportarSeriesHistoricas = () => {
    history.push(`${RotasDTO.SeriesHistoricas}/importar`);
  };

  const onClickValoresSeriesHistoricas = () => {
    history.push(`${RotasDTO.SeriesHistoricas}/valores`);
  };

  const onClickExportarSeriesHistoricas = () => {
    history.push(`${RotasDTO.SeriesHistoricas}/exportar`);
  };

  const onClickEditar = async (id) => {
    history.push(`${RotasDTO.SeriesHistoricas}/cadastro/${id}`);
  };

  const onClickVisualizacao = async (id) => {
    history.push(`${RotasDTO.SeriesHistoricas}/valores/${id}`);
  };

  const deletar = async (item) => {
    try {
      setModalExclusao(false);
      setCarregandoSeries(true);
      const deletou = await atualizarSerieHistorica({
        codigo: item?.codigo,
        apelido: item?.apelido,
        descricao: item?.descricao,
        ativo: item?.ativo == 0 ? 1 : 0,
        fonte: item?.fonte,
        idCategoria: item?.idCategoria,
        idUnidade: item?.idUnidade,
        periodicidade: item?.periodicidade
      });

      if (deletou) {
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem:
              item?.ativo == 1
                ? "Série Histórica desativada com sucesso!"
                : "Série Histórica ativada com sucesso!"
          })
        );

        obterSeriesHistoricas();
      }
      setCarregandoSeries(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );

      setCarregandoSeries(false);
    }
  };

  const onClickExcluir = (item) => {
    setModalExclusao(item);
  };

  const onConfirmarExclusao = (item) => {
    try {
      deletar(item);
    } catch (error) {
      console.info(error);
    }
  };

  return (
    <Loader loading={carregandoSeries} className="w-auto">
      <ModalConfirmacao
        item={modalExclusao}
        onConfirmar={(item) => onConfirmarExclusao(item)}
        mensagem={
          modalExclusao.ativo == 1
            ? "Tem certeza que deseja desabilitar essa Série Histórica?"
            : "Tem certeza que deseja habilitar essa Série Histórica?"
        }
        onCancelar={() => setModalExclusao(false)}
      />
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={9}>
          <MaterialInputTexto
            type="text"
            id="textoBusca"
            name="textoBusca"
            label="Buscar"
            renderIconShowHide
            searchAdornment
            defaultValue={textoBusca ?? ""}
            ref={register}
            className={classes.search}
            permiteValorBranco
          />
        </Grid>
        <Grid item xs={3} className={classes.itemFiltro}>
          <BotaoFiltroOrdenacao
            type="button"
            color={Base.GreyWater}
            background="transparent"
            label="Filtrar / Ordenar"
            icon={<TuneIcon />}
            className={classes.buttonFiltro}
            ordenacao={listarOrganizarPor()}
            ordenadorUm="Ordenador"
            ordenadorDois="Filtrar"
            ordemSelecionada={ordemSelecionada}
            filtros={listarFiltrarPor()}
            filtrosSelecionados={filtroSelecionado}
            tipos={naoExibirFiltrosTipo ? null : listarTipoPor()}
            tiposSelecionados={tipoSelecionado}
            onClickOrdenacao={handleClickOrdemSelecionada}
            onClickFiltro={handleClickFiltroSelecionado}
            onClickTipo={handleClickTipoSelecionado}
            onClickAtivarAgora={handleClickAtivarAgora}
          />
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}></Grid>
          <Grid item xs={2} mt={2}>
            <Botao
              label="Importar"
              color={Base.RedChestnut}
              onClick={onClickImportarSeriesHistoricas}
            />
          </Grid>
          <Grid item xs={2} mt={2}>
            <Botao
              label="Exportar"
              color={Base.RedChestnut}
              onClick={onClickExportarSeriesHistoricas}
            />
          </Grid>
          <Grid item xs={2} mt={2}>
            <Botao
              label="Consultar"
              color={Base.RedChestnut}
              onClick={onClickValoresSeriesHistoricas}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <BotaoCadastro
            label="Nova Série Histórica"
            color={Base.RedChestnut}
            onClick={onClickNovaSerieHistorica}
          />
        </Grid>
        {seriesHistoricas.length > 0 &&
          seriesHistoricas.map((serie) => {
            return (
              <ItemListaExpansivel
                key={shortid.generate()}
                label={serie?.codigo ?? serie?.apelido}
                color={Base.RedChestnut}
                visualizacao={!!serie.codigo}
                edicao={!!serie?.codigo}
                exclusao={!!serie.codigo}
                onClickVisualizacao={() => onClickVisualizacao(serie?.codigo)}
                onClickEditar={() => onClickEditar(serie?.codigo)}
                onClickExcluir={() => onClickExcluir(serie ?? false)}
                ativo={Boolean(serie?.ativo)}
              >
                <div>
                  <Grid spacing={1}>
                    <Grid container spacing={12}>
                      <Grid item sm={4}>
                        <strong>Descrição: </strong>
                      </Grid>
                      <Grid item sm={8}>
                        {serie?.descricao}
                      </Grid>
                    </Grid>

                    <Grid container spacing={12}>
                      <Grid item sm={4}>
                        <strong>Apelido: </strong>
                      </Grid>
                      <Grid item sm={8}>
                        {serie?.apelido}
                      </Grid>
                    </Grid>

                    <Grid container spacing={12}>
                      <Grid item sm={4}>
                        <strong>Categoria: </strong>
                      </Grid>
                      <Grid item sm={8}>
                        {serie?.descricaoCategoria}
                      </Grid>
                    </Grid>

                    <Grid container spacing={12}>
                      <Grid item sm={4}>
                        <strong>Unidade: </strong>
                      </Grid>
                      <Grid item sm={8}>
                        {serie?.descricaoUnidade}
                      </Grid>
                    </Grid>

                    <Grid container spacing={12}>
                      <Grid item sm={4}>
                        <strong>Periodicidade: </strong>
                      </Grid>
                      <Grid item sm={8}>
                        {serie?.descricaoPeriodicidade}
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </ItemListaExpansivel>
            );
          })}
      </Grid>
    </Loader>
  );
};

export default SeriesHistoricas;
