import { makeStyles } from '@mui/styles';
import { Base } from "componentes/cores";

export const useStyles = makeStyles({
  squareWidth: {
    height: "54px !important",
    width: "54px !important"
  },
  treeViewItemExpand: {
    color: Base.GreyDim,
    justifyContent: "end !important",
    padding: 0,
    position: "relative !important"
  },
  treeViewBorder: {
    borderLeftColor: (props) => props.color,
    borderLeftWidth: "6px !important",
    borderLeftStyle: "solid !important"
  },
  gridItem: {
    flexBasis: "100% !important",
    fontSize: "2.1875rem !important",
    margin: "0  !important",
    maxWidth: "100% !important",
    "pointer-events": "all",
    width: "54px !important"
  },
  treeItem: {
    backgroundColor: Base.BlackBastille,
    color: `${Base.OffWhite} !important`,
    height: "54px !important",
    opacity: (props) => (props.ativo ? 1 : 0.5)
  },
  wrapper: {
    alignItems: "center !important",
    display: "flex !important",
    height: "100% !important",
    minHeight: "100% !important"
  },
  start: {
    alignItems: "flex-start !important"
  },
  center: {
    justifyContent: "center !important"
  },
  left: {
    justifyContent: "start !important",
    paddingLeft: "20px !important"
  },
  right: {
    justifyContent: "flex-end !important"
  }
});
