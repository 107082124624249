import { makeStyles } from "@mui/styles";
import { Base } from "componentes/cores";

export const useStyles = makeStyles({
  container: {
    margin: "0 0 0",
    width: "100%",
    "& .MuiGrid-root": {
      color: Base.BlackBastille
    },
    "& .MuiInputBase-root": {
      height: "46px"
    },
    "& .MuiFormLabel-root": {
      lineHeight: "1"
    },
    "& .MuiDropzoneArea-text": {
      color: Base.BlackBastille
    },
    "& .MuiDropzoneArea-icon": {
      color: Base.BlackBastille
    }
  },
  contentCliente: {
    "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
      paddingTop: "5px"
    }
  },
  button: {
    fontSize: "18px"
  }
});
