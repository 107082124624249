import React from "react";

import { useSelector } from "react-redux";

import RotaNaoAutenticada from "../../global/rotas/rotaNaoAutenticada";
import RotaAutenticadaNaoEstruturada from "../../global/rotas/rotaAutenticadaNaoEstruturada";
import RotaAutenticada from "../../global/rotas/rotaAutenticada";

const Conteudo = (rota) => {
  // eslint-disable-next-line react/destructuring-assignment
  localStorage.setItem("menuUrl", rota.location.pathname);
  const usuario = useSelector((state) => state.usuario);
  const { autenticada, estruturada } = rota;

  if (usuario?.token && autenticada && estruturada)
    return <RotaAutenticada rota={rota} />;

  if (usuario?.token && autenticada && !estruturada)
    return <RotaAutenticadaNaoEstruturada rota={rota} />;

  return <RotaNaoAutenticada rota={rota} />;
};

export default Conteudo;
