import { makeStyles } from '@mui/styles';
import { Base } from 'componentes/cores';

export const useStyles = makeStyles(() => ({
  titulo: {
    fontWeight: "bold"
  },
  container: {
    "& .MuiInputBase-root": {
      color: `${Base.OffWhite} !important`
    },
    "& .MuiInputBase-input": {
      maxWidth: "90px"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0 none"
    },
    "& .MuiButtonBase-root": {
      color: `${Base.OffWhite} !important`
    },
  },
  itemGrafico: {
    "& .apexcharts-tooltip.apexcharts-theme-light": {
      border: "0 none",
      background: "none",
      width: "fit-content",
      height: "fit-content",
      textAlign: "center",
      fontSize: "13px"
    }
  },
  noData: {
    textAlign: "center",
    width: "100%",
    minHeight: "200px",
    "& div": {
      margin: "125px auto 0",
      maxWidth: "80%"
    }
  },
  arrowBox: {
    position: "relative",
    background: "#647480",
    padding: "8px",
    borderColor: "#647480"
  },
  arrowBoxTitle: {
    color: "#a6b9c8",
    fontSize: "10px"
  },
  formControl: {
    "& .MuiInputBase-root": {
      backgroundColor: "#303642",
      borderRadius: 4,
      height: "36px"
    },
    "& .MuiSelect-icon": {
      color: Base.White
    },
    "& option": {
      color: Base.BlackBastille
    },
    "& .MuiSelect-select.MuiSelect-select": {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      color: "#eef0f9"
    },
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      padding: "0 40px 0 20px "
    },
    "& .MuiFormLabel-root": {
      color: `${Base.OffWhite} !important`,
    }
  },
}));


