import { useEffect, useRef } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
// eslint-disable-next-line import/no-extraneous-dependencies
import postscribe from "postscribe";

function Dynamsoft() {
  const containerRef = useRef(null);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const init = async () => {
    if (!containerRef?.current) return;

    postscribe(
      containerRef.current,
      "<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/dynamsoft-document-viewer@1.1.0/dist/ddv.css' />"
    );
    postscribe(
      containerRef.current,
      "<script src='https://cdn.jsdelivr.net/npm/dynamsoft-core@3.0.30/dist/core.js'></script>"
    );
    postscribe(
      containerRef.current,
      "<script src='https://cdn.jsdelivr.net/npm/dynamsoft-license@3.0.20/dist/license.js'></script>"
    );
    postscribe(
      containerRef.current,
      "<script src='https://cdn.jsdelivr.net/npm/dynamsoft-document-normalizer@2.0.20/dist/ddn.js'></script>"
    );
    postscribe(
      containerRef.current,
      "<script src='https://cdn.jsdelivr.net/npm/dynamsoft-capture-vision-router@2.0.30/dist/cvr.js'></script>"
    );
    postscribe(
      containerRef.current,
      "<script src='https://cdn.jsdelivr.net/npm/dynamsoft-document-viewer@1.1.0/dist/ddv.js'></script>"
    );
  };

  useEffect(() => {
    if (window?.location) {
      const protocolo = window?.location?.protocol;
      if (!matches && protocolo === "https:") {
        init();
      }
    }
  }, []);

  return <div ref={containerRef} />;
}

export default Dynamsoft;
