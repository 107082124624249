import { makeStyles } from '@mui/styles';
import { Base } from "componentes/cores";

export const useStyles = makeStyles({
  fundoCelula: {
    backgroundColor: "transparent",
    width: "100%",
    height: "100%"
  },
  fundoBotoes: {
    backgroundColor: Base.BlackRock
  },
  botaoCentral: {
    border: `2px ${Base.BlackRussian} solid`,
  },
  botao: {
    width: "60px",
    height: "100%",
    justifyContent: "center",
    justifyItems: "center",
    alignContent: "center",
    alignItems: "center"
  },
  inativo: {
    opacity: 0.5
  },
  ativo: {
    opacity: 1
  },
  div: {
    '& .rdt_TableHeader': {
     minHeight: "0px"
    }
  }
});
