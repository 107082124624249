export default class RetornoEndpointDto {
  constructor(sucesso, mensagem, data = null) {
    this.sucesso = sucesso;
    this.mensagem = mensagem;
    this.data = data;
  }

  static Erro(mensagem, data = null) {
    return new RetornoEndpointDto(false, mensagem, data);
  }

  static Sucesso(mensagem, data = null) {
    return new RetornoEndpointDto(true, mensagem, data);
  }

  static SucessoSomenteDados(data) {
    return new RetornoEndpointDto(true, null, data);
  }
}
