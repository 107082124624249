import React from "react";

// Componentes
import { Grid, Box } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";

// Styles
import { useStyles, Stepper } from "./style";

const Passos = () => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: { xs: "space-between" },
          alignItems: { xs: "center" }
        }}
        p={2}
      >
        <h3 className={classes.titulo}>Próximos passos:</h3>
      </Grid>

      <Grid item xs={12}>
        <Box sx={{ p: { xs: 1, lg: 2 } }}>
          <Stepper step={5}>
            <div className="stepper-item active completed completed1">
              <div className="stepper-icon">
                <DoneIcon />
              </div>
              <div className="step-counter">01</div>
              <div className="step-label">
                <h3>Estudo de Viabilidade com a LUZ</h3>
                <p>Etapa concluída!</p>
              </div>
            </div>
            <div className="stepper-item completed completed2">
              <div className="stepper-icon" />
              <div className="step-counter">02</div>
              <div className="step-label">
                <h3>Analisar proposta comercial e Termo de Adesão</h3>
                <p>
                  Caso você tenha qualquer dúvida, nosso time de especialistas
                  está a sua disposição para esclarecermos ponto a ponto!
                </p>
              </div>
            </div>
            <div className="stepper-item completed completed3">
              <div className="stepper-icon" />
              <div className="step-counter">03</div>
              <div className="step-label">
                <h3>Assinatura do Contrato e Início do processo de migração</h3>
                <p>
                  Envio do contrato social e informações do responsável legal +
                  Assinatura Digital.
                </p>
              </div>
            </div>
          </Stepper>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Passos;
