import React from "react";
import PropTypes from "prop-types";

import { Grid } from "@mui/material";

import { Icon, useStyles } from "./style";

const TreeViewParentIcon = ({ force }) => {
  const classes = useStyles();

  return (
    <Grid
      item
      xs={1}
      className={`${force && classes.forcePosition} ${classes.squareWidth} ${
        classes.gridItem
      }`}
    >
      <div
        className={`${classes.treeViewItemExpand} ${classes.wrapper} ${classes.start} ${classes.right}`}
      >
        <Icon />
      </div>
    </Grid>
  );
};

TreeViewParentIcon.propTypes = {
  force: PropTypes.bool
};

TreeViewParentIcon.defaultProps = {
  force: false
};

export default TreeViewParentIcon;
