import React, { useState } from 'react';
import { Card, Grid } from "@mui/material";
import { useForm } from 'react-hook-form';
import { useStyles } from './style';
import UploadDropArea from 'componentes/upload';
import Botao from 'componentes/botao';
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { uploadBasesItaipu } from "../../../servicos/baseItaipu";
import ResultadoOperacao from 'componentes/resultadoOperacao';
import moment from 'moment';
import InputData from 'componentes/inputTextoData';
import { useSelector } from "react-redux";
import RotasDTO from "../../../rotas/rotasUrlDto";
import CloseIcon from "@mui/icons-material/Close";
import { useHistory } from "react-router-dom";

const BaseItaipu = () => {
  const classes = useStyles();
  const history = useHistory();
  const usuarioGlobal = useSelector((state) => state.usuario);
  const msgPadraoUpload = "Solte o arquivo para anexá-lo ou procurar";

  const { handleSubmit } = useForm({
    reValidateMode: 'onSubmit',
  });

  const [file, setFile] = useState(null);
  const [anoReferencia, setAnoReferencia] = useState(null);
  const [fileName, setFileName] = useState(msgPadraoUpload);
  const [carregandoArquivo, setCarregandoArquivo] = useState(false);
  const [progressMesage, setProgressMessage] = useState("");
  const [resultadoOperacao, setResultadoOperacao] = useState(false);
  const [resultadoOperacaoCabecalho, setResultadoOperacaoCabecalho] = useState("");
  const [resultadoOperacaoObj, setResultadoOperacaoObj] = useState({
    "mensagens": []
  });
  const handleChangeAnoReferencia = (ano) => setAnoReferencia(ano);
  const processarArquivo = (arquivo) => {
    if (arquivo != null && arquivo[0] != null) {
      setFile(arquivo[0]);
      setFileName(arquivo[0].file.name);
    }
  };

  const onAddUpload = (arquivo) => {
    processarArquivo(arquivo)
  };

  const onCancelar = () => {
    setFile(false);
    setAnoReferencia(null);
    setFileName(msgPadraoUpload);
    setCarregandoArquivo(false);
    setResultadoOperacao(false);
    setResultadoOperacaoCabecalho("");
    setResultadoOperacaoObj({
      "mensagens": []
    });
  }

  const EnviarForm = async () => {
    try {
      const anoBase = moment(anoReferencia).format("YYYY");

      setCarregandoArquivo(true);
      setResultadoOperacao(false);
      setProgressMessage("Importando o arquivo... Por favor, aguarde.");
      const upload = await uploadBasesItaipu(file, anoBase, usuarioGlobal?.usuario?.id, usuarioGlobal?.usuario?.usuarioNome);
      if (upload.status === 200  && upload?.data) {
        setFile(false);
        setFileName(msgPadraoUpload);
        setCarregandoArquivo(false);
        setResultadoOperacao(true);
        let dadosRetorno = upload?.data;

        let mensagemRetorno = ["Processo de Importação finalizado com sucesso"];

        if (dadosRetorno.length !== null && dadosRetorno.length !== undefined && dadosRetorno.length > 1) {
          
          let valores = dadosRetorno?.[1];

          if (valores.length !== null && valores.length !== undefined && valores.length > 0) {
            for (var i = 0; i < valores?.length; i++) {
              mensagemRetorno.push(valores[i].Mensagem);
            }
          }
        }

        setResultadoOperacaoCabecalho(`Bases Itaipu - Importação do arquivo ${file?.file?.name}`)
        setResultadoOperacaoObj({ "mensagens": mensagemRetorno });

      }
      else if(upload.status === 202)
      {
        const match = upload.data[0].find(element => {
          /*
           * A mensagem com status 202 e com a substring 'Registros inseridos' indica que a importação 
           * foi realizada mas que uma ou mais
           * distribuidoras não foram importadas e foram retornadas pelo endpoint
           */
          if (Object.keys(element)[0].includes("Registros inseridos")) {
            return true;
          }
        });

        setCarregandoArquivo(false);
        setResultadoOperacao(true); 
        let dadosRetorno = upload?.data;

        let mensagemRetorno = [];

        if (dadosRetorno.length !== null && dadosRetorno.length !== undefined && dadosRetorno.length > 1) {
          
          let valores = dadosRetorno?.[1];

          if (valores.length !== null && valores.length !== undefined && valores.length > 0) {
            for (var i = 0; i < valores?.length; i++) {
              mensagemRetorno.push(valores[i].Mensagem);
            }
          }
        }
        setResultadoOperacaoObj({"mensagens": mensagemRetorno });  

        /*
         * Caso a importação tenha sido realizada e uma ou mais distribuidoras não tenham sido importadas
         * notifica sucesso na importação e exibe a lista de distribuidoras não importadas
         */
        if(match != undefined)
        {
          setResultadoOperacaoCabecalho(`Bases Itaipu - Processo de Importação finalizado com sucesso.`)
         }
        else
        {
          setResultadoOperacaoCabecalho(`Bases Itaipu - Processo de Importação finalizado com falhas.`)
        }
      }


    } catch (erro) {
      setFile(false);
      setFileName(msgPadraoUpload);
      setCarregandoArquivo(false);
      setResultadoOperacao(true);
      setResultadoOperacaoCabecalho(`Bases Itaipu - Importação do arquivo ${file?.file?.name}`)
      setResultadoOperacaoObj({ "mensagens": [erro.message ?? "Ocorreu um erro desconhecido"] });
      console.info(erro);
    }
  }

  const aoEnviarForm = () => {

    if (anoReferencia === null || anoReferencia === undefined || anoReferencia === "") {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Por favor, Informe o Ano de Referência"
        })
      )
    }
    else if (moment(anoReferencia).format("YYYY").length > 4 || moment(anoReferencia).format("YYYY").length < 4) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Ano de Referência inválido"
        })
      )
    }
    else if (!file) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Por favor, selecione o arquivo desejado."
        })
      )
    } else {
      EnviarForm();
    }

  }

  const onFechar = (id) => {
    history.push(`${RotasDTO.ProjecaoInsumoListar}`);
  }

  return (
    <form className="needs-validation" onSubmit={handleSubmit(aoEnviarForm)}>
      <ResultadoOperacao
        item={resultadoOperacaoObj}
        cabecalhoResultado={resultadoOperacaoCabecalho}
        onClose={() => onFechar()}
      />
      {resultadoOperacao === false ?
        <Card className={classes.container}>
          <Grid container className={classes.containerTitulo}>
            <Grid item xs={11} className="font-weight-bold">
              Importação de Bases Itaipu
            </Grid>

            <Grid item xs={1} >
              <CloseIcon onClick={onFechar} className={classes.icon} />
            </Grid>

          </Grid>
          {carregandoArquivo === false ?
            <Card className={classes.container}>
              <Grid container spacing={4} p={2} className={classes.container}>
                <Grid item sm={3} className={classes.containerData}>
                  <InputData
                    type="text"
                    label="Ano de Referência"
                    customValue={anoReferencia}
                    onChange={(data) => handleChangeAnoReferencia(data)}
                    renderIconShowHide={false}
                    format="yyyy"
                    views={["year"]}
                    minDate= {moment().add(-100, "years").toDate()}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4} p={2} className={classes.container}>
                <Grid item sm={12} className={classes.fileUpload}>
                  <UploadDropArea
                    onAdd={onAddUpload}
                    dropAreaText={fileName}
                    showLoader={false}
                    acceptedFiles={[
                      '.xlsm,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
                    ]}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4} p={2} className={classes.container} justifyContent="space-between">
                <Grid item sm={3}>
                  <Botao
                    type="button"
                    label="Cancelar"
                    onClick={onCancelar}
                    className={classes.customButton}
                  />
                </Grid>
                <Grid item sm={3}>
                  <Botao
                    type="submit"
                    label="Importar"
                    className={classes.button}
                  />
                </Grid>
              </Grid>
            </Card>
            :
            <Card className={classes.container}>
              <Grid container spacing={4} p={2} className={classes.container}>
                <Grid item sm={12} className={classes.fileUpload}>
                  <Grid item sm={12} style={{ textAlign: "center" }}>
                    {progressMesage}
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          }
        </Card>
        : ""
      }
    </form>
  );
}

export default BaseItaipu;