import moment from "moment";
import React, { useState } from "react";
import InputData from "../inputTextoData";

export default class ColunaVigenciaDe {
  constructor(
    field,
    headerName,
    sortable = false,
    onChangeDataInicioDesconto,
    maxWidth = null,
    minWidth = null,
    dataInicioDescontoDiferenciado = null,
    idCampoSelecionadoInicioDesconto = null,
    selecionadoId = null,
    enabled = false
  ) {
    this.selector = field;
    this.name = headerName;
    this.sortable = sortable;
    this.maxWidth = maxWidth;
    this.minWidth = minWidth;
    this.dataInicioDescontoDiferenciado = dataInicioDescontoDiferenciado;
    this.idCampoSelecionadoInicioDesconto = idCampoSelecionadoInicioDesconto;
    this.selecionadoId = selecionadoId;
    this.enabled = enabled;
    const [dataInformada, setDataInformada] = useState();
    this.cell = (params) => {
      moment.updateLocale({
        invalidDate: "Date invalide!!!"
      });
      moment().format("DD/MM/YYYY");
      return (
        <InputData
          type="date"
          id={`dataVigenciaDe${params.id}`}
          name={`dataVigenciaDe${params.id}`}
          customValue={
            params.id === idCampoSelecionadoInicioDesconto
              ? dataInicioDescontoDiferenciado
              : dataInformada === "" ||
                params.dataInicioDescontoDiferenciado === "0001-01-01T00:00:00"
              ? "-"
              : params.dataInicioDescontoDiferenciado
          }
          onAccept={(value) => {
            setDataInformada(value);
            onChangeDataInicioDesconto(value, params);
          }}
          onBlur={(event) => {
            if (event.target.value) {
              const data = event.target.value;
              const dia = data.split("/")[0];
              const mes = data.split("/")[1];
              const ano = data.split("/")[2];
              const novaData = new Date(
                `${ano 
                  }-${ 
                  (`0${  mes}`).slice(-2) 
                  }-${ 
                  (`1${  dia}`).slice(-2) 
                  }T00:00`
              );

              setDataInformada(novaData);
              onChangeDataInicioDesconto(novaData, params);
            }
          }}
          params={params}
          disabled={!(params.id === selecionadoId && enabled)}
          InputProps={{
            disableUnderline: true
          }}
          minDate={window.moment().toDate()}
        />
      );
    };
  }
}
