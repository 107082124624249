export default class ListagemCupomDto {
  constructor(
    id,
    nomeColaborador,
    cupom,
    descontoDiferenciado,
    dataInicioVigencia,
    dataFimVigencia,
    colaboradorId,
    adicionalDescontoSemFidelidade,
    adicionalDesconto3Anos,
    adicionalDesconto5Anos,
    dataInicioDescontoDiferenciado,
    dataFinalDescontoDiferenciado,
    descontosSelecionadosList,
  ) {
    this.id = id;
    this.nomeColaborador = nomeColaborador;
    this.cupom = cupom;
    this.descontoDiferenciado = descontoDiferenciado;
    this.dataInicioVigencia = dataInicioVigencia;
    this.dataFimVigencia = dataFimVigencia;
    this.colaboradorId = colaboradorId;
    this.adicionalDescontoSemFidelidade = adicionalDescontoSemFidelidade;
    this.adicionalDesconto3Anos = adicionalDesconto3Anos;
    this.adicionalDesconto5Anos = adicionalDesconto5Anos;
    this.dataInicioDescontoDiferenciado = dataInicioDescontoDiferenciado;
    this.dataFinalDescontoDiferenciado = dataFinalDescontoDiferenciado;
    this.descontosSelecionadosList = descontosSelecionadosList;
  }
}