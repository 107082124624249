import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { Grid, Card } from '@mui/material';
import { useStyles } from './style';
import BotaoRetornarListagem from 'componentes/botaoRetornarListagem';
import MaterialInputTexto from 'componentes/inputTexto/materialInput';
import MaterialSwitch from 'componentes/switch';
import Botao from 'componentes/botao';
import { obterCenarioPorId, salvarCenario } from '../../../servicos/cenariosSeriesHistoricas'
import RotasDTO from '../../../rotas/rotasUrlDto';
import { Loader } from 'componentes';

const CadastroCenarios = () => {
  const { id } = useParams();
  const history = useHistory();
  const classes = useStyles();

  const { errors, handleSubmit } = useForm({
    reValidateMode: 'onSubmit',
  });

  const [cenario, setCenario] = useState([]);
  const [nome, setNome] = useState('');
  const [descricao, setDescricao] = useState('');
  const [isStandard, setIsStandard] = useState(false);
  const [salvandoCenario, setSalvandoCenario] = useState(false);

  const handleCenario = useCallback(async (cenarioID) => {
    try {
      const lista = await obterCenarioPorId(cenarioID);
      if (lista?.status === 200 && lista?.data) {
        setCenario(lista?.data);
        setNome(lista?.data?.nome);
        setDescricao(lista?.data?.descricao);
        setIsStandard(lista?.data?.isStandard)
      } else {
        setCenario([]);
      }
    } catch (error) {
      console.info(error);
    }
  }, []);

  const handleNome = (nomeCenario) => {
    setNome(nomeCenario.target.value);
  }

  const handleDescricao = (descricaoCenario) => {
    setDescricao(descricaoCenario.target.value);
  }

  const onChangeSwitch = () => {
    setIsStandard(!isStandard)
  }

  const enviarFormulario = async () => {
    try {
      setSalvandoCenario(true);
      const salvou = await salvarCenario(id ?? 0, {
        id,
        nome,
        descricao,
        ativo: 1,
        isStandard: isStandard === true ? 1 : 0
      });

      if (salvou?.status === 200 || salvou?.status === 204) {
        setTimeout(() => {
          history.push(RotasDTO.Cenarios);
        }, 2000);
      }
    } catch (error) {
      console.info(error);
      setSalvandoCenario(false);
    }
  };

  const aoEnviarFormulario = (dados) => {
    enviarFormulario(dados);
  }

  useEffect(() => {
    if (id) handleCenario(id);
  }, [id, handleCenario]);

  return <>
    <form className="needs-validation" onSubmit={handleSubmit(aoEnviarFormulario)}>
      <Loader loading={salvandoCenario} className='w-auto'>
        <Card className={classes.container}>
          <Grid container p={2} spacing={4} className={classes.container} justifyContent="flex-end">
            <Grid item sm={11}>
              {cenario?.id ? "Editar Cenário" : "Novo Cenário"}
            </Grid>
            <Grid item sm={1}>
              <BotaoRetornarListagem
                justify="flex-end"
                urlListagem={RotasDTO.Cenarios}
              />
            </Grid>
          </Grid>
          <Grid container p={2} spacing={4} className={classes.container}>
            <Grid item sm={4}>
              <MaterialInputTexto
                type="text"
                id="nomeCenario"
                name="nomeCenario"
                label="Cenário"
                renderIconShowHide={false}
                defaultValue={cenario?.nome}
                onBlur={(valorNome) => handleNome(valorNome)}
                errors={errors}
              />
            </Grid>
            <Grid item sm={6}>
              <MaterialInputTexto
                type="text"
                id="descricaoCenario"
                name="descricaoCenario"
                label="Descrição"
                renderIconShowHide={false}
                defaultValue={cenario?.descricao}
                onBlur={(valorDescricao) => handleDescricao(valorDescricao)}
                errors={errors}
              />
            </Grid>
            <Grid item sm={4}>
              <MaterialSwitch
                label="Standard"
                labelPlacement="top"
                id="Standard"
                name="Standard"
                checked={Boolean(isStandard)}
                onChange={(valor) => onChangeSwitch(valor)}
                exibirLabels
              />
            </Grid>
          </Grid>
          <Grid container p={2} spacing={4} className={classes.container}>
            <Grid item sm={4}>
              <Botao type="submit" label="Salvar" className={classes.button} />
            </Grid>
          </Grid>
        </Card>
      </Loader>
    </form>
  </>;
};

export default CadastroCenarios;