import { downloadClienteContrato, obterClienteContratoPorUnidadeId } from "servicos/clienteContratoServico";
import RetornoEndpointDto from "../../../global/dto/retornoEndpointDto";
import { store } from "../../../global/redux";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";

export default class ClienteContratoHelper {

  static async obterClienteContrato(unidadeId) {
    try {
      const response = await obterClienteContratoPorUnidadeId(unidadeId);

      return RetornoEndpointDto.Sucesso(
        "Contrato obtido com sucesso",
        response.data
      );
    } catch (ex) {
      return RetornoEndpointDto.Erro(
        ex?.response?.data?.message ?? "Não foi possivel obter contrato!"
      );
    }
  }

  static async downloadContrato(unidadeId, nomeGuid) {
    try {
      const response = await downloadClienteContrato(unidadeId, nomeGuid)

      return response?.data;
    } catch (ex) {
      return RetornoEndpointDto.Erro(
        ex?.response?.data?.message ??
          "Não foi possivel baixar arquivo"
      );
    }
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }

  static exibirAlerta(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "warning",
        mensagem
      })
    );
  }

  static exibirSucesso(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem
      })
    );
  }
}
