import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  snackbar: {
    backgroundColor: "transparent",
    "& .MuiSnackbarContent-root": {
      backgroundColor: (props) =>
        props?.tipo === "success"
          ? "#2ABF6F !important"
          : props?.tipo === "warning"
          ? "#F4A330 !important"
          : "#BF2A2A !important"
    }
  }
}));
