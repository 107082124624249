
import RadioDto from "componentes/inputRadioGroup/radioDto";

export default class ClienteHelper {

    static obterTipoCliente() {
        return [
            new RadioDto("Pessoa Jurídica", "PJ"),
            new RadioDto("Pessoa Física", "PF")
        ];
    }

}
