import { makeStyles } from "@mui/styles";
import theme from "themes";
import { Base } from "../../../../../componentes/cores";

export const useStyles = makeStyles({
  containerColorido: {
    backgroundColor: (props) => props.planoCor,
    color: (props) => props.corTexto,
    borderBottomLeftRadius: "0px",
    borderBottomRightRadius: "0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  containerBranco: {
    padding: "24px"
  },
  containerPadding: {
    padding: "0px 24px",
    opacity: (props) => (props.cotacaoFechada ? 0.5 : 1)
  },
  labelPlano: {
    fontFamily: theme.font.family,
    fontSize: "16px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.4px"
  },
  labelInformacoes: {
    fontFamily: theme.font.family,
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.4px"
  },
  valorInformacoes: {
    fontFamily: theme.font.family,
    fontSize: "20px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.53px"
  },
  valorReducaoCustos: {
    fontFamily: theme.font.family,
    fontSize: "24px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.67px"
  },
  planoNome: {
    fontFamily: theme.font.family,
    fontSize: "24px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.67px"
  },
  containerInformacoes: {
    paddingBottom: "15px"
  },
  botaoEscolher: {
    borderRadius: "21px",
    backgroundColor: Base.Mango,
    width: "100%",
    padding: "10px 22px"
  },
  porcentagemReducao:{
    color: Base.BlackRussian,
    fontSize: 24
  },
  containerPaddingInterno:{
    padding: 10
  },
  labelInformacoesEconomia:{
    fontWeight: 500
  },
  itemPlano:{
    marginBottom: 40,
    cursor: "pointer"
  },
  btnLabel:{
    color: Base.BlueLochmara
  }
});
