/* eslint-disable import/no-unresolved */
import MICROSERVICO from "global/enumeradores/microServico";
import obterApi from "servicos/api";

const api = () => obterApi(MICROSERVICO.PROJECAOTARIFARIA);

export const uploadCotasAngra = async (arquivo, anoReferencia, idUsuario, nomeUsuario) => {
  const data = new FormData();
  data.append("file", arquivo.file);

  const metodo = "post";
  const url = `v1/ImportCotasAngra/importa?AnoReferencia=${anoReferencia}&idUsuario=${idUsuario}&nomeUsuario=${nomeUsuario}`;
  return api()[metodo](url, data);
}

export const getDistribuidoras = async () => {
  const metodo = "get";
  const url = "v1/ParametroDistribuidora/getalljoin?StatusProjecaoTarifaria=true"
  return api()[metodo](url);
}

export const getCotasAngra = async (idDistribuidora) => {
  const metodo = "get";
  const url = `v1/ImportCotasAngra/visualizarcotasangra${idDistribuidora ? `?IdDistribuidora=${idDistribuidora}` : ""}`; 
  return api()[metodo](url);
}
