/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { store } from "global/redux";
import { useTheme } from "@mui/material/styles";
import { Grid, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import InputDataBranco from "componentes/inputTextoDataBranco";
import { useStyles, customStyles } from "./style";
import { obterContratosProinfaAtualizados } from "../../../../../servicos/projecaoBrasil";
import { obterCenarios } from "../../../../../servicos/cenariosSeriesHistoricas";
import CloseIcon from "@mui/icons-material/Close";
import RotasDTO from "../../../../../rotas/rotasUrlDto";
import { useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import Coluna from "componentes/tabelaPaginada/colunas/coluna";

const ContratosProinfaBrasilVisualizar = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const [contratoProinfa, setContratoProinfa] = useState([]);
  const [anoInicial, setAnoInicial] = useState(moment().toDate());
  const [cenarios, setCenarios] = useState([]);
  const [cenario, setCenario] = useState();

  const colunas = [
    {
      ...new Coluna("origem", "Origem", false, "120px", "120px"),
      center: true
    },
    { ...new Coluna("empreendimento", "Empreendimento", false), center: true },
    {
      ...new Coluna("energia", "Energia (MWh)", false, "200px", "200px"),
      right: true,
      format: (row) =>
        row.energia.toLocaleString("pt-BR", { minimumFractionDigits: 2 })
    },
    {
      ...new Coluna("custoFonte", "Custo Fonte (R$)", false, "180px", "180px"),
      right: true,
      format: (row) =>
        row.custoFonte.toLocaleString("pt-BR", { minimumFractionDigits: 2 })
    },
    {
      ...new Coluna("custoTotal", "Custo Total (R$)", false, "180px", "180px"),
      right: true,
      format: (row) =>
        row.custoTotal.toLocaleString("pt-BR", { minimumFractionDigits: 2 })
    },
    {
      ...new Coluna("custo", "Custo (R$)", false, "200px", "200px"),
      right: true,
      format: (row) =>
        row.custo.toLocaleString("pt-BR", { minimumFractionDigits: 2 })
    }
  ];

  const onChangeAnoInicial = (data) => {
    setAnoInicial(data);
  };

  const obterValores = async () => {
    try {
      const response = await obterContratosProinfaAtualizados(
        cenario,
        moment(anoInicial).format("YYYY")
      );
      if (response?.status === 200 && response?.data) {
        setContratoProinfa(response.data);
      } else {
        setContratoProinfa();
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: `Falha ao obter dados: ${response.data}`
          })
        );
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: `Erro não previsto: ${error}`
        })
      );
    }
  };

  const handleCenarios = async () => {
    try {
      const lista = await obterCenarios();
      if (lista?.status === 200 && lista?.data) {
        setCenarios(lista?.data);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  const handleChangeCenario = (event) => {
    cenarios.forEach((item) => {
      if (item.id === event) {
        setCenario(item.id);
      }
    });
  };

  useEffect(() => {
    if (cenario !== undefined) obterValores();
  }, [anoInicial, cenario]);

  useEffect(() => {
    handleCenarios();
  }, [cenario]);

  const onFechar = (id) => {
    history.push(`${RotasDTO.ProjecaoBrasilListar}`);
  };

  return (
    <>
      <Grid container className={classes.containerTitulo}>
        <Grid item xs={11} className={classes.titulo}>
          Contratos PROINFA
        </Grid>
        <Grid item xs={1}>
          <CloseIcon onClick={onFechar} className={classes.icon} />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={4}
        className={classes.container}
        justifyContent="flex-end"
        alignItems="center"
      >
        <Grid item sm={3}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="cenario-label">Cenário</InputLabel>
            <Select
              labelId="cenario-label"
              id="cenario"
              label="Cenário"
              onChange={(e) => handleChangeCenario(e.target.value)}
              fullWidth
            >
              {cenarios.map((item) => (
                <MenuItem value={item?.id}>{item.nome}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item sm={3}>
          <InputDataBranco
            label="Ano"
            type="text"
            id="teste"
            name=""
            format="yyyy"
            customValue={anoInicial}
            onChange={(data) => onChangeAnoInicial(data)}
            views={["year"]}
            minDate={
              new Date(`Thu Oct 01 ${new Date().getFullYear() - 10} GMT-0300`)
            }
            maxDate={
              new Date(`Thu Oct 01 ${new Date().getFullYear() + 20} GMT-0300`)
            }
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12}>
          <DataTable
            subHeader
            subHeaderAlign="right"
            columns={colunas}
            data={contratoProinfa}
            theme="DeltaEnergia"
            customStyles={customStyles}
            noDataComponent=""
            noHeader
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ContratosProinfaBrasilVisualizar;
