import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { Card, Grid } from '@mui/material';
import { useStyles, Tabela } from './style';
import InputDataBranco from 'componentes/inputTextoDataBranco';
import { valoresContratroProinfa, valoresContratroProinfaAtualizados } from '../../../../../servicos/premissasBrasil';
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import CloseIcon from "@mui/icons-material/Close";
import RotasDTO  from "../../../../../rotas/rotasUrlDto";  
import { useHistory } from "react-router-dom";

const ContratosProinfaVisualizar = () => {
  const classes = useStyles();
  const history = useHistory();

  const onFechar = (id) => {
     history.push(`${RotasDTO.ProjecaoInsumoListar}`);
   }
  const [anoBase, setAnoBase] = useState(null);
  const [tipoRetorno, setTipoRetorno] = useState("");
  const handleChangeAnoBase = (ano) => setAnoBase(ano);

  const [dados, setDados] = useState(null);

  const obterDados = useCallback(async (ano) => {
    try {
      const anoRef = moment(ano).format("YYYY");
      const response = await valoresContratroProinfa(anoRef);
      if (response.status === 200 && response.data) {
        setDados(response?.data);
        setTipoRetorno("Valores Importados")
      } 
      else {
        const responseAtualizado = await valoresContratroProinfaAtualizados(anoRef);
        if (responseAtualizado.status === 200 && responseAtualizado.data) {
          setDados(responseAtualizado?.data);
          setTipoRetorno("Valores Projetados")
        }
        else
        {  
          setDados(null);
          setAnoBase(null);
          setTipoRetorno(null);
          store.dispatch(
            alertaExibir({
              tipo: "warning",
              mensagem: "Não foram encontrados dados para o Ano informado."
            })
          );
        }
      }
    } catch (error) {
      setDados(null);
      setAnoBase(null);
      setTipoRetorno("");
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!" 
        })
      );
    }
  }, []);

  useEffect(() => {
    if (anoBase && moment(anoBase).format("YYYY") > 1900) obterDados(anoBase);
  }, [anoBase, obterDados]);

  return <>
   <Grid container className={classes.containerTitulo}>
        <Grid item xs={11} className={classes.titulo}>
          Planilha de Contratos PROINFA
        </Grid>
        <Grid item xs={1} >             
          <CloseIcon onClick={onFechar} className={classes.icon}/>
        </Grid>   
      </Grid>
    <Grid container spacing={4} p={2} className={classes.container} justifyContent="flex-end" alignItems="center">
      <Grid item sm={2} className={classes.customSelect}>
        <InputDataBranco
          type="text"
          id="anoBase"
          name="anoBase"
          customValue={anoBase}
          onChange={(data) => handleChangeAnoBase(data)}
          minDate={moment().subtract(21, 'years').toDate()}
          maxDate={moment("2090-12-31").toDate()}
          format="yyyy"
          views={["year"]}
          renderIconShowHide={false}
        />
      </Grid>
    </Grid>
    <Grid container spacing={4} p={2} className={classes.container}>
      <Grid item sm={12}>
        {dados &&
          <Card className={classes.container}>
            <Grid container spacing={4} p={2} className={classes.container}>
              <Grid item sm={12}>
                <div className={classes.customText}>
                <b>{tipoRetorno}</b>
                   <br />
                  {dados &&
                    dados?.dataPrecos ?
                    <b>{`Preço Em: ${moment(dados?.dataPrecos).format('L')}`}</b>
                    : ""
                  }
                  <br />
                  {dados &&
                    dados?.custoAdicional ?
                    <b>
                      {`Custo Adicional (R$/MWh): ${dados?.custoAdicional.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
                    `}
                    </b>
                    : ""
                  }
                </div>
              </Grid>
              <Grid item sm={12}>
                <Tabela>
                  <Tabela.Thead>
                    <Tabela.Tr>
                      <Tabela.Th>CONTRATO</Tabela.Th>
                      <Tabela.Th>EMPREENDIMENTO</Tabela.Th>
                      <Tabela.Th>FONTE</Tabela.Th>        
                      <Tabela.Th>VIGÊNCIA</Tabela.Th>
                      <Tabela.Th>MONTANTE (MWh)</Tabela.Th>
                      <Tabela.Th>PREÇO (R$/MWh)</Tabela.Th>
                      <Tabela.Th>CUSTO (R$)</Tabela.Th>
                    </Tabela.Tr>
                  </Tabela.Thead>
                  <Tabela.Tbody>
                    {dados?.contratos?.map((item) => (
                      <Tabela.Tr>
                        <Tabela.Td>{item.numeroContrato}</Tabela.Td>
                        <Tabela.Td>{item.empreendimento}</Tabela.Td>
                        <Tabela.Td>{item.fonte}</Tabela.Td>                       
                        <Tabela.Td>{moment(item?.vigencia)?.format('L')}</Tabela.Td>
                        <Tabela.Td>{item?.montante?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2})}</Tabela.Td>
                        <Tabela.Td>
                          {item?.preco?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                        </Tabela.Td>
                        <Tabela.Td>
                          {item?.custo?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                        </Tabela.Td>
                      </Tabela.Tr>
                    ))}
                  </Tabela.Tbody>
                </Tabela>
              </Grid>
            </Grid>
          </Card>
        }
      </Grid>
    </Grid>
  </>;
}

export default ContratosProinfaVisualizar;