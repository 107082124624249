export default class FiltroDto {
  constructor(
    tamanhoPaginacao,
    pagina,
    filtroPersonalizado,
    empresaSelecionada,
    tipoCliente,
    campoPesquisa,
    searchString,
    sortOrder
  ) {
    this.tamanhoPaginacao = tamanhoPaginacao;
    this.pagina = pagina;
    this[filtroPersonalizado.nome] = filtroPersonalizado.valor;
    this.empresaId = empresaSelecionada;
    this.pessoaFisicaJuridica = tipoCliente;
    this.sortOrder = sortOrder;
    this[campoPesquisa] = searchString;
  }
}
