import React from "react";
import PropTypes from "prop-types";

// Componentes
import { Box } from "@mui/material";
import { useStyles } from "./style";

const CardLinha = ({ dados }) => {
  const classes = useStyles();

  return (
    <Box className={classes.card}>
      <Box className={classes.imagem}>
        {dados?.imagem && (
          <img src={dados.imagem} alt="Icone" className={classes.icone} />
        )}
        {dados?.legendaImagem && dados.legendaImagem}
      </Box>
      <Box mb={2}>
        {dados.titulo && dados.titulo}
        {dados.conteudo && dados.conteudo}
      </Box>
    </Box>
  );
};

CardLinha.propTypes = {
  dados: PropTypes.shape({
    imagem: PropTypes.string,
    legendaImagem: PropTypes.node,
    titulo: PropTypes.node,
    conteudo: PropTypes.node
  })
};

CardLinha.defaultProps = {
  dados: {}
};

export default CardLinha;
