import { makeStyles } from "@mui/styles";
import { Base } from "../../../../componentes/cores";
import theme from "../../../../themes";

export const useStyles = makeStyles({
  container: {
    margin: "0",
    width: "100%",
    "& .MuiInputBase-root": {
      height: "46px"
    },
    "& .MuiFormLabel-root": {
      lineHeight: "1"
    }    
  },
  cardCadastro: {
    backgroundColor: theme.color.bgColorCardCadastro
  },
  containerEdicaoUsuario: {
    margin: "0 0 -8px 0",
    width: "100%"
  },
  contentEmpresa: {
    "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
      padding: "0px 4px"
    },
    "& .MuiChip-root": {
      height: "26px"
    }
  },
  containerTelefone: {
    "& .MuiInputBase-input": {
      height: "7px",
    }
  },
  button: {
    fontSize: "18px",
    paddingLeft: "10px",
    paddingRight: "10px"
  },
  trace: {
    width: "112px",
    height: "47px",
    borderBottom: "1px solid black",
    position: "absolute"
  },
  inputTelefone: {
    maxWidth: "68%",
    width: "100%",
    "&.Mui-error": {
      borderColor: Base.RedError,
      color: Base.RedError
    }
  },
  divider: {
    margin: "10px 18px !important"
  },
  containerSalvar: {
    margin: "0 0 12px 0",
    width: "100%"
  },
  inputRadio: {
    marginBottom: "-16px"
  },
  inputRadioLabel: {
    color: `${theme.color.radioButton} !important`
  },
  tituloStrong: {
    fontWeight: "bold"
  }
});
