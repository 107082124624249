import {
  obterPorId,
  listaArquivosCotacao,
  obterTituloRF,
  obterDadosFatura,
  atualizaSituacao,
  obterHistorico,
  gerarNovaCotacao
} from "../../../servicos/ValidarCotacaoServico";
import RetornoEndpointDto from "../../../global/dto/retornoEndpointDto";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";
import { store } from "../../../global/redux";

export default class CotacaoInicialHelper {
  static async obterCotacaoPorId(id) {
    try {
      const resultado = await obterPorId(id);

      if (resultado.status === 204)
        return RetornoEndpointDto.Erro(`Não encontrada cotação com Id ${id}`);

      return RetornoEndpointDto.Sucesso(
        "Cotação obtida com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(`Não encontrada cotação com Id ${id}`);
    }
  }

  static async obterHistorico(id) {
    try {
      const resultado = await obterHistorico(id);

      if (resultado.status === 204)
        return RetornoEndpointDto.Erro(
          `Não foi possível encontrar o histórico de consumo para cotação com Id ${id}`
        );

      return RetornoEndpointDto.Sucesso(
        "Histórico de consumo obtido com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        `Não foi possível encontrar o histórico de consumo para cotação com Id ${id}`
      );
    }
  }

  static async obterDadosFatura(id) {
    try {
      const resultado = await obterDadosFatura(id);

      if (resultado.status === 204)
        return RetornoEndpointDto.Erro(
          `Não foi possível encontrar os dados da fatura para cotação com Id ${id}`
        );

      return RetornoEndpointDto.Sucesso(
        "Fatura(s) obtida(s) com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        `Não foi possível encontrar os dados da fatura para cotação com Id ${id}`
      );
    }
  }

  static async AtualizaSituacao(id, situacao) {
    try {
      const resultado = await atualizaSituacao(id, situacao);
      if (resultado.status !== 204)
        return RetornoEndpointDto.Erro(
          `Não foi possível atualizar o status da validação CotacaoId: ${id} Situacao: ${situacao}`
        );

      return RetornoEndpointDto.Sucesso(
        "Validação atualizada com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        `Não foi possível atualizar o status da validação CotacaoId: ${id} Situacao: ${situacao}`
      );
    }
  }

  static async GerarNovaCotacao(dados) {
    try {
      const resultado = await gerarNovaCotacao(dados);

      if (resultado.status !== 200)
        return RetornoEndpointDto.Erro(
          "Não foi possível gerar uma nova cotação"
        );

      return RetornoEndpointDto.Sucesso(
        "Nova cotação gerada com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Não foi possível gerar uma nova cotação");
    }
  }

  static async obterFaturasPorId(id) {
    try {
      const resultado = await listaArquivosCotacao(id);

      if (resultado.status === 204)
        return RetornoEndpointDto.Erro(
          `Não foi possível encontrar nenhum arquivo de fatura para cotação com Id ${id}`
        );

      return RetornoEndpointDto.Sucesso(
        "Fatura(s) obtida(s) com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        `Não foi possível encontrar nenhum arquivo de fatura para cotação com Id ${id}`
      );
    }
  }

  static async obterTituloRF(cnpj) {
    try {
      const resultado = await obterTituloRF(cnpj);

      if (resultado.status === 204)
        return RetornoEndpointDto.Erro(
          `Não foi possível encontrar dados para o cnpj ${cnpj}`
        );

      return RetornoEndpointDto.Sucesso(
        "Dados da RF obtidos com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        `Não foi possível encontrar dados para o cnpj ${cnpj}`
      );
    }
  }

  static formatarMesagemErro(error, erroPadrao) {
    try {
      if (!(error?.response?.data?.message ?? false)) return erroPadrao;

      const str = error.response.data.message;

      return str.split(" :: ")[1].replace("\r", "").replace("\n", "");
    } catch (err) {
      return erroPadrao;
    }
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }

  static exibirSucesso(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem
      })
    );
  }
}
