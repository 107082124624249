import RetornoEndpointDto from "global/dto/retornoEndpointDto";
import { Listar } from "../../servicos/categoriaRelatorioServico";

export default class CategoriaRelatorioHelper {
  static async Listar(filtro) {
    try {
      const resultado = await Listar(filtro);

      return RetornoEndpointDto.Sucesso(
        "Categorias de Relatórios obtidos com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu um erro ao obter a lista de Categorias de Relatórios"
      );
    }
  }
}
