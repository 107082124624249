import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const api = () => obterApi(MICROSERVICO.PROFILE);

// Listar Usuarios
export const listarUsuarios = async (filtro) => {
  const urlParams = new URLSearchParams(filtro).toString();

  return api().get(`v1/usuarios?${urlParams}`);
};

// Listar Usuarios Cliente Prospect Inteface Ativa
export const listarUsuariosClienteProspect = async (filtro) => {
  const urlParams = new URLSearchParams(filtro).toString();

  return api().get(`v1/usuarios/cliente-prospect-interface?${urlParams}`);
};

// Busca Por Id
export const buscarPorId = async (id) => {
  return api().get(`v1/usuarios/${id}`);
};

// Busca Por Id cliente/prospect interface ativa
export const buscarPorIdClienteProspectLiberado = async (id) => {
  return api().get(`v1/usuarios/${id}/cliente-prospect-interface`);
};

// Busca Por Cpf
export const buscarPorCpf = async (cpf, tipoSelecionado) => {
  return api().get(
    `v1/usuarios/buscaCnpj/${cpf}?tipoSelecionado=${tipoSelecionado}`
  );
};

// Busca Por Email
export const buscarPorEmail = async (email) => {
  return api().get(`v1/usuarios/buscaEmail/${email}`);
};

// Busca Por Email
export const buscarPorUserName = async (userName) => {
  return api().get(`v1/usuarios/buscaUserName/${userName}`);
};

// Obtém usuário colaborador responsável pelo usuário logado
export const obterGestor = async () => {
  return api().get("v1/Usuarios/obterGestorDoUsuarioLogado");
};

// Deletar Usuario
export const deletarUsuario = async (id, ativo) => {
  return api().patch(`v1/usuarios/${id}/ativo/${ativo}`);
};

export const listarUsuarioOperacaoPorUnidadeId = async (id) => {
  return api().get(`v1/Usuarios/ListaUsuarioOperacaoPorUnidadeId/${id}`);
};

// Salvar Usuario
export const salvarUsuario = async (id, dados) => {
  let metodo = "post";
  let url =
    id > 0 ? "v1/usuarios/atualiza-usuario/" : "v1/usuarios/adiciona-usuario";
  if (id > 0) {
    metodo = "put";
    url = `${url}${id}`;
  }
  return api()[metodo](url, dados);
};

// Enviar e-mail
export const enviarEmailAcesso = async (id, empresaId, email) => {
  const empresa = empresaId ? `?empresaId=${empresaId}` : "";

  return api().post(`v1/usuarios/enviarEmail/${id}/email/${email}${empresa}`);
};

export const listarOrganizarPor = () => {
  const ordernacoes = [
    {
      key: "0",
      label: "Alfabética"
    },
    {
      key: "3",
      label: "Recentes"
    }
  ];

  return ordernacoes;
};

export const listarFiltrarPor = () => {
  const filtros = [
    {
      key: true,
      label: "Ativo"
    },
    {
      key: false,
      label: "Inativo"
    }
  ];

  return filtros;
};

export const listarTipoPor = () => {
  const filtros = [
    {
      key: "1",
      label: "Colaborador"
    },
    {
      key: "2",
      label: "Cliente"
    },
    {
      key: "3",
      label: "Prospect"
    }
  ];

  return filtros;
};

export const obterUltimoLoginUsuario = async (usuarioId) => {
  return api().get(
    `v1/UsuarioAcessos/obter-ultimo-login?usuarioId=${usuarioId}`
  );
};

export const verificarSenhaUsuario = async (usuarioId) => {
  return api().get(`v1/Usuarios/verificarExisteSenha/${usuarioId}`);
};

export const verificarAcessoBiometria = async (usuarioId) => {
  return api().get(
    `v1/UsuarioAcessos/obter-usuario-acesso-biometria?usuarioId=${usuarioId}`
  );
};

export const buscaPerfilPorEmail = async (email) => {
  return api().get(`v1/Usuarios/buscaPerfilPorEmail/${email}`);
};
