import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const api = () => obterApi(MICROSERVICO.MANAGEMENT);

const montarRequestModel = (dados) => {
  const arvoreGerencialDto = {
    Descricao: dados?.descricao,
    ArvoreGerencialVinculadaId:
      dados?.arvoreGerencialVinculadaId === undefined
        ? 0
        : dados?.arvoreGerencialVinculadaId,
    NomeRelacao: "",
    Situacao: "AT",
    DataReferencial: null,
    Nivel: dados?.nivel
  };

  return arvoreGerencialDto;
};

// Listar Arvores Gerenciais
export const listarArvoresGerenciais = async (filtroSelecionado, empresa) => {
  let urlParams = "";

  if (
    filtroSelecionado &&
    filtroSelecionado.length > 0 &&
    filtroSelecionado[0] !== ""
  ) {
    urlParams = `?&filtro=${filtroSelecionado}`;
  }

  return api().get(
    `v1/ArvoreGerencial/GetAllById${urlParams}${
      empresa ? `&empresaId=${empresa}` : ""
    }`
  );
};

// Busca Por Id
export const buscarPorId = async (id) => {
  return api().get(`v1/ArvoreGerencial/${id}`);
};

// Listar Arvores Gerenciais Empresas
export const listarArvoresGerenciaisEmpresas = async () => {
  return api().get("v1/ArvoreGerencialEmpresa");
};

export const salvarArvoresGerenciais = async (id, dados) => {
  const arvoreGerencialDto = montarRequestModel(dados);

  let metodo = "post";
  let url = "";

  if (id > 0) {
    metodo = "put";
    url = "v1/ArvoreGerencial";
    arvoreGerencialDto.Id = id;
  } else {
    url =
      "v1/ArvoreGerencialColaboradorEmpresa/ArvoreGerencialAndColaboradorEmpresa";
  }

  return api()[metodo](url, arvoreGerencialDto);
};

export const deletarArvoresGerenciais = async (id, situacao) => {
  const data = new Date();
  const dataSituacao = `${data.getFullYear()}-${
    data.getMonth() + 1
  }-${data.getDate()}`;
  return api().patch(
    `v1/ArvoreGerencial/${id}/situacao/${situacao}/data-situacao/${dataSituacao}`
  );
};

export const obterArvoreGerencialAltoNivel = async (
  colaboradorId,
  empresaId
) => {
  return api().get(
    `v1/ArvoreGerencial/Alto/Nivel/Colaborador/${colaboradorId}/Empresa/${empresaId}`
  );
};

export const ordernaArvoresGerenciais = async (lista) => {
  return api().put("v1/ArvoreGerencial/ordenacao", {
    FuncionalidadeOrdenacaoColecao: lista
  });
};

export const listarFiltrarPor = () => {
  const filtros = [
    {
      key: "AT",
      label: "Ativo"
    },
    {
      key: "IN",
      label: "Inativo"
    }
  ];

  return filtros;
};
