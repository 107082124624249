/* eslint-disable no-else-return */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import MICROSERVICO from "global/enumeradores/microServico";
import obterApi from "servicos/api";

const api = () => obterApi(MICROSERVICO.PROJECAOTARIFARIA);

export const obterRegioes = async () => {
  return api().get("v1/ParametroDistribuidora/regioes");
}

export const obterCapital = async () => {
  return api().get("v1/ParametroDistribuidora/tipocapital");
}

export const obterContrato = async () => {
  return api().get("v1/ParametroDistribuidora/tiposcontrato");
}

export const obterParametrizacaoDistribuidoraPorId = async (id) => {
  return api().get(`v1/ParametroDistribuidora/getbyid?id=${id}`);
}

export const obterParametrizacaoDistribuidoras = async () => {
  return api().get("v1/ParametroDistribuidora/getall");
}

export const listarDistribuidoras = async (statusProjecaoTarifaria) => {
  if (statusProjecaoTarifaria === true) {
    return api().get("v1/ParametroDistribuidora/getalljoin?StatusProjecaoTarifaria=true");
  } else if (statusProjecaoTarifaria === false) {
    return api().get("v1/ParametroDistribuidora/getalljoin?StatusProjecaoTarifaria=false");
  } else {
    return api().get("v1/ParametroDistribuidora/getalljoin");
  }
};

export const buscarDistribuidoras = async () => {
  return api().get("v1/ParametroDistribuidora/getall");
};

export const salvarParametrizacaoDistribuidora = async (id, data) => {
  let metodo = "post";
  let url = "v1/ParametroDistribuidora/add";

  if (id && id > 0) {
    metodo = "put";
    url = "v1/ParametroDistribuidora/update";
  }
  return api()[metodo](url, data);
};

export const ativarInativarParametroDistribuidora = async (data) => {
  return api().put("v1/ParametroDistribuidora/update", data);
}

// Salvar Apelidos
export const salvarApelidoDistribuidora = async (dados) => {
  const metodo = "post";
  const url = "v1/apelidodistribuidora/add";
  return api()[metodo](url, dados);
};

// Busca Por Apelido
export const buscarPorApelido = async (apelido) => {
  return api().get(`v1/apelidodistribuidora/getbyApelido/${apelido}`);
};

// Busca Por Id Distribuidora
export const buscarPorIdDistribuidora = async (idDistribuidora) => {
  return api().get(`v1/apelidodistribuidora/getbyidDistribuidora?idDistribuidora=${idDistribuidora}`);
};

// Obter Apelidos
export const obterApelidos = async () => {
  return api().get("v1/apelidodistribuidora/getall");
};


export const listarOrganizarPor = () => {
  const ordernacoes = [
    {
      key: "1",
      label: "Alfabética"
    },
    {
      key: "2",
      label: "Recentes"
    }
  ];

  return ordernacoes;
};


export const listarFiltrarPor = () => {
  const filtros = [
    {
      key: "1",
      label: "Ativo para Projeção"
    },
    {
      key: "0",
      label: "Inativo para Projeção"
    }
  ];

  return filtros;
};

export const sincronizarDistribuidoras = async () => {
  return api().post("v1/ParametroDistribuidora/sincronizardistribuidoras");
};

export const updateDistribuidoraDivergente = async (idDistribuidora, data) => {
  return api().put(`v1/ParametroDistribuidora/UpdateDistribuidoraDivergente?id=${idDistribuidora}`, data);
}
