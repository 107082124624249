import { makeStyles } from "@mui/styles";
import theme from "themes";
import { Base } from "../../../../../componentes/cores";

export const useStyles = makeStyles({
  form: {
    backgroundColor: "transparent",
    "pointer-events": "all"
  },
  container: {},
  gridItem: {
    backgroundColor: Base.White,
    height: 54
  },
  itemSelect: {},
  select: {
    border: "none",
    borderRadius: "0",
    boxShadow: "none",
    color: theme.color.primaryBackgroud,
    marginBottom: "0",
    "&:hover": {
      border: "none"
    },
    "& .MuiOutlinedInput-root": {
      border: "none",
      borderRadius: "0",
      "&:hover": {
        border: "none",
        borderRadius: "0"
      }
    },
    "& .MuiOutlinedInput-notchedOutline": {
      display: "none",
      position: "relative"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none",
      borderRadius: "0"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      border: "none",
      borderRadius: "0"
    },
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      border: "none",
      borderRadius: "0"
    }
  },
  errorSelect: {
    borderColor: Base.RedAmaranth,
    borderWidth: "1px",
    borderStyle: "solid"
  },
  errorFlag: {
    borderColor: Base.RedAmaranth,
    borderWidth: "1px",
    borderStyle: "solid"
  },
  itemClose: {
    alignItems: "center",
    backgroundColor: Base.White,
    display: "flex",
    height: "100%",
    justifyContent: "center",
    textAlign: "center"
  },
  buttonClose: {
    cursor: "pointer",
    height: "24px",
    "pointer-events": "all"
  },
  itemCadastrar: {
    backgroundColor: Base.White,
    height: "100%"
  },
  buttonCadastrar: {
    border: "0 none",
    height: "100%",
    outline: "none !important"
  },
  itemFull: {
    flexGrow: 1
  },
  itemLabel: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    paddingLeft: "5px"
  },
  label: {
    color: Base.GreyDim,
    fontSize: "18px",
    height: "auto",
    margin: "0",
    padding: "0"
  },
  input: {
    border: "0",
    borderRadius: "0",
    fontSize: "18px",
    outline: "none",
    padding: "0 0 0 6px",
    height: "100%",
    width: "100%"
  },
  descricao: {
    fontSize: "18px"
  },
  divider: {
    borderRight: `2px solid ${theme.color.primaryBackgroud}`
  },
  margin: {
    marginBottom: "2px"
  },
  checkbox: {
    "&.Mui-checked": {
      color: theme.color.checkButonColor
    }
  },
  errorLabel: {
    borderColor: Base.RedAmaranth,
    borderRightWidth: "0",
    borderStyle: "solid",
    borderWidth: "1px"
  },
  errorInput: {
    borderColor: Base.RedAmaranth,
    borderLeftWidth: "0",
    borderRightWidth: "2px",
    borderStyle: "solid",
    borderWidth: "1px"
  },
  anexarArquivo:{
    width: "100%"
  },
  iconeInput:{
    marginRight: 15
  }
});
