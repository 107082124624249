import { makeStyles } from "@mui/styles";
import { Base } from "componentes/cores";
import getDomain from "global/getDomain";
import theme from "themes";

export const useStyles = makeStyles(() => ({
  header: {
    padding: "24px 40px 0px 40px",
    boxShadow: (props) => (props.shadowHeader ? `0 3px ${Base.OffWhite}` : "")
  },
  footer: {
    padding: "24px 40px",
    boxShadow: (props) => (props.shadowFooter ? `0 -3px ${Base.OffWhite}` : "")
  },
  content: {
    padding: "0px 40px",
    minHeight: "400px"
  },
  containerBotaoAssinar: {
    justifyContent: "flex-end",
    justifySelf: "flex-end",
    justifyItems: "flex-end"
  },
  containerContent: {
    padding: "30px 10px 30px 0px",
    maxHeight: "300px",
    overflowX: "auto",
    fontFamily: theme.font.family,
    fontSize: "18px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.4px",
    textAlign: "justify",
    color: Base.Preto
  },
  containerTitulo: {
    alignItems: "center",
    alignContent: "center",
    alignSelf: "center"
  },
  labelMenor: {
    fontFamily: theme.font.family,
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.27px"
  },
  labelMaior: {
    fontSize: "16px",
    fontFamily: theme.font.family,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.27px"
  },
  icone: {
    width: "24px",
    height: "24px",
    backgroundColor: "transparent"
  },
  containerIcone: {
    alignItems: "center",
    alignContent: "center",
    alignSelf: "center",
    justifyContent: "center",
    justifySelf: "center",
    justifyItems: "center"
  },
  titulo: {
    fontFamily: theme.font.family,
    fontSize: "18px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.4px",
    color: theme.color.tituloColorCardRepresetante
  },
  linkDownload: {
    fontFamily: theme.font.family,
    fontSize: "18px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.4px",
    textAlign: "justify",
    color: Base.Preto,
    textDecoration: "underline",
    "&:hover": {
      textDecoration: "underline",
      color: Base.Preto
    }
  },
  imagemFundo: {
    background:
      getDomain() !== "luz" &&
      `url(../../themes/${getDomain()}/assets/cotacao_dados_energia.png)`,
    backgroundSize: "cover"
  },
  botaoOffWhite: {
    background: "transparent"
  }
}));
