import PropTypes from "prop-types";
import React from "react";
import { generate } from "shortid";

import { Edit } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";

import { HtmlTooltip } from "paginas/Graficos/ConsumoAtual/style";
import { useHistory } from "react-router-dom";
import { RotasDTO } from "../../../../global/rotas/rotasUrlDto";
import { useStyles } from "./style";

const ColunaBaixar = ({ parametros }) => {
  const classes = useStyles();
  const history = useHistory();

  const onClickEditar = () => {
    history.push(
      `${RotasDTO.AnaliseDocumento}/${parametros.operacaoControleId}`
    );
  };

  return (
    <Grid container spacing={0} className={classes.fundoCelula}>
      <Grid item xs={12}>
        <Button
          key={generate()}
          onClick={onClickEditar}
          className={`text-white ${classes.botao}`}
        >
          <HtmlTooltip title="Baixar">
            <Edit />
          </HtmlTooltip>
        </Button>
      </Grid>
    </Grid>
  );
};

ColunaBaixar.propTypes = {
  parametros: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired
};

export default ColunaBaixar;
