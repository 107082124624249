import { makeStyles } from "@mui/styles";
import styled from "styled-components";
import theme from "themes";

export const useStyles = makeStyles({
  container: {
    "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
      height: "1.01em",
      padding: "0 0 0 20px"
    },
    margin: "0",
    width: "100%",
    "& .MuiInputBase-root": {
      height: "46px"
    },
    "& .MuiFormLabel-root": {
      lineHeight: "1"
    }
  },
  button: {
    color: `${theme.color.primary} !important`,
    backgroundColor: "transparent",
    border: "none",
    paddingRight: "10px",
    "&:disabled": {
      opacity: "0.5"
    }
  },
  card: {
    marginTop: "10px",
    background: "#FFF !important"
  }
});

export const ButtonLinkLuz = styled.button`
  font-family: "Plau";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  text-decoration-line: underline;
  color: #fa3eb1;
  border: none;
  background: rgb(255, 255, 255);
`;

export const TabelaModal = styled.table`
  width: 100%;
`;
TabelaModal.Tr = styled.tr`
  ${(props) => props?.borderBottom && "border-bottom : 2px solid #D9D9D9;"}
`;
TabelaModal.Th = styled.th`
  border-top : 2px solid #D9D9D9;
  border-left: 2px solid #D9D9D9;
  padding: 10px; 
  font-weight-bold;
  color: #332053;
  margin-left: 10px;
  max-width: ${(props) => (props?.width ? props.width : "auto")};
`;
TabelaModal.ThLeft = styled.th`
  border-top : 2px solid #D9D9D9;
  padding: 10px; 
  font-weight-bold;
  color: #332053;
  margin-left: 10px;
  max-width: ${(props) => (props?.width ? props.width : "auto")};
`;

TabelaModal.Td = styled.td`
  border-left: 2px solid #d9d9d9;
  padding: 10px;
  color: #000000;
  max-width: ${(props) => (props?.width ? props.width : "auto")};
  word-break: break-all;
`;
TabelaModal.TdLeft = styled.td`
  color: #000000;
  padding: 10px;
  max-width: ${(props) => (props?.width ? props.width : "auto")};
`;

export const Tabela = styled.table`
  width: 100%;
  margin-left: 20px;
  margin-right: 20px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  border-collapse: unset !important;
`;

export const Icone = styled.img`
  max-width: 20px;
`;

export const Scroll = styled.div`
  max-height: 300px;
  overflow-y: scroll;
`;
