import React, { useState,useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Card, Grid } from '@mui/material';
import { useStyles } from './style';
import { Base } from 'componentes/cores';
import BotaoRetornarListagem from 'componentes/botaoRetornarListagem';
import Loader from 'componentes/loader';
import UploadDropArea from 'componentes/upload';
import Botao from 'componentes/botao';
import ValidacaoDominios from 'componentes/validacaoDominios';
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { importar } from '../../../../servicos/cdeServico';
import RotasDTO from '../../../../rotas/rotasUrlDto';
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { useHistory } from "react-router-dom";
import { importPing } from "../../../../utils/import-ping"
import { geraURLUpload, uploadFile } from "../../../../servicos/awsUtils";
import { obterCenarios } from "../../../../servicos/cenariosSeriesHistoricas";
import SelectArredondado from "componentes/selectArredondado";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";

const CDEOrcamentoImportar = () => {
  const classes = useStyles();

  const history = useHistory();

  const onFechar = (id) => {
    history.push(`${RotasDTO.ProjecaoInsumoListar}`);
  }

  const usuarioGlobal = useSelector((state) => state.usuario);

  const msgPadraoUpload = "Solte o arquivo para anexá-lo ou procurar";

  const { handleSubmit } = useForm({
    reValidateMode: 'onSubmit',
  });

  const [file, setFile] = useState(false);
  const [fileName, setFileName] = useState(msgPadraoUpload);
  const [carregandoArquivo, setCarregandoArquivo] = useState(false);
  const [progressMesage, setProgressMessage] = useState("");

  const [validacaoDominio, setValidacaoDominio] = useState(false);
  const [validacaoDominioCabecalho, setValidacaoDominioCabecalho] = useState("");
  const [validacaoDominiosObj, setValidacaoDominiosObj] = useState({
    "contexto": "",
    "entidades": []
  });
  const [cenarios, setCenarios] = useState([]);
  const [idCenario, setIdCenario] = useState();
  useEffect(() => { handleCenarios()})
  const handleCenarios = async () => {
    try {
      const lista = await obterCenarios();
      if (lista?.status === 200 && lista?.data) {
        setCenarios(lista?.data);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  }
  const processarArquivo = (arquivo) => {
    if (arquivo != null && arquivo[0] != null) {
      setFile(arquivo[0]);
      setFileName(arquivo[0].file.name);
    }
  };

  const onAddUpload = (arquivo) => {
    processarArquivo(arquivo)
  };

  const importarCDEComValidacao = async () => {
    if (file) {
      setCarregandoArquivo(true);
      setProgressMessage("Importando o arquivo... Por favor, aguarde.");
      try {
        const keyOnS3 = file.file.name;
        
        const urlPreSigned = await geraURLUpload(keyOnS3)

        console.log('urlPreSigned', urlPreSigned);

        const uploadS3Response = await uploadFile(file.file, file.file.type, urlPreSigned.data);
        
        console.log('uploadS3Response', uploadS3Response);

        const response = await importar(usuarioGlobal?.usuario?.id, usuarioGlobal?.usuario?.usuarioNome, idCenario, keyOnS3);

        if (response?.status === 200 && response?.data) {
          const datetimeImportStarted = response.data.DataHoraRequisicao
          const urlParams = new URLSearchParams(window.location.search);
          let idInsumo = urlParams.get('id')
          let codigoInsumo = urlParams.get('codigo')
          await importPing(idInsumo, datetimeImportStarted, usuarioGlobal?.usuario.id, codigoInsumo, (status, message) => {
            let mensagemRetorno = "Processo de Importação Finalizado.";
            
            setFile(false);
            
            setFileName(msgPadraoUpload);
            
            setCarregandoArquivo(false);
            
            setValidacaoDominio(false);
            
            store.dispatch(
              alertaExibir({
                tipo: "success",
                mensagem: mensagemRetorno
              })
            );
          })
        } else if (response?.status === 202 && response?.data) {
          if (response?.data.typeError === "ERRO-VALIDACAO-ESTRUTURA") {
            setFile(false);
            setFileName(msgPadraoUpload);
            setCarregandoArquivo(false);
            setValidacaoDominio(false);
            store.dispatch(
              alertaExibir({
                tipo: "danger",
                mensagem: response.data.value[0].ItemValidacao.Mensagem
              })
            );
          } else if (response?.data.typeError === "ERRO-VALIDACAO-DOMINIO") {
            setValidacaoDominio(true);
            setValidacaoDominioCabecalho(`Importação do arquivo ${file.file.name}`)
            setValidacaoDominiosObj(response?.data.value);
          }
        }
      } catch (error) {
        setFile(false);
        setFileName(msgPadraoUpload);
        setCarregandoArquivo(false);
        setValidacaoDominio(false);
        store.dispatch(
          alertaExibir({
            tipo: "danger",
            mensagem: "Erro ao importar arquivo!"
          })
        );
      }
    } else {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: "É necessário informar o arquivo!"
        })
      );
    }
  }

  const onCancelar = () => {
    setFile(false);
    setFileName(msgPadraoUpload);
    setCarregandoArquivo(false);
    setValidacaoDominio(false);
  }

  const onCancelarValidacaoDominios = () => {
    setFile(false);
    setFileName(msgPadraoUpload);
    setCarregandoArquivo(false);
    setValidacaoDominio(false);
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem: "Importação cancelada!"
      })
    );
  }

  return (
    <form className="needs-validation" onSubmit={handleSubmit(importarCDEComValidacao)}>
      <ValidacaoDominios
        item={validacaoDominiosObj}
        cabecalhoValidacao={validacaoDominioCabecalho}
        onConfirmar={(data) => importarCDE(data)}
        onCancelar={() => onCancelarValidacaoDominios()}
      />
      <Card className={classes.container} style={validacaoDominio === true ? { display: 'none' } : {}}>
        <Grid container className={classes.containerTitulo}>
          <Grid item xs={11} className="font-weight-bold">
            Importação de planilha de Orçamento CDE
          </Grid>
          <Grid item xs={1} >
            <CloseIcon onClick={onFechar} className={classes.icon} />
          </Grid>
        </Grid>
        <AppBar position="static" color="default">
          <Tabs indicatorColor="primary" textColor="primary" variant="fullWidth">
            <Grid container p={2} spacing={4} className={classes.container} justifyContent="left">
             

              <Grid item sm={12}>
                <Grid item sm={1}><Grid item sm={1}>Cenário</Grid></Grid>
                <Grid item sm={3} sx={3}>
                  <SelectArredondado
                    id="cenarioSelecionado"
                    name="cenarioSelecionado"
                    valueKey="id"
                    valueName="nome"
                    value={idCenario}
                    dataSource={cenarios}
                    onChange={(event) => {
                      setIdCenario(event.target.value);
                    }}
                  />
                </Grid>
              </Grid>              

            </Grid>
          </Tabs>
        </AppBar>
        {validacaoDominio === false ?
          <div>
            <Grid container spacing={4} p={2} className={classes.container}>
              {carregandoArquivo === false ?
                <Grid item sm={12}>
                  <UploadDropArea
                    onAdd={onAddUpload}
                    dropAreaText={fileName}
                    showLoader={false}
                    acceptedFiles={[
                      '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
                    ]}
                  />
                </Grid>
                :
                <Grid container spacing={4} p={2} className={classes.container} style={{ marginBottom: "20px" }}>
                  <Grid item sm={12} style={{ textAlign: "center" }}>
                    {progressMesage}
                  </Grid>
                  <Grid item sm={12}>
                    <Loader loading={carregandoArquivo} />
                  </Grid>
                </Grid>
              }
            </Grid>
            {
              carregandoArquivo === false ?
                <Grid container spacing={4} p={2} className={classes.container} justifyContent="center">
                  <Grid item sm={3}>
                    <Botao
                      type="button"
                      label="Cancelar"
                      onClick={onCancelar}
                      color={Base.GreyDim}
                      className={classes.button}
                    />
                  </Grid>
                  <Grid item sm={3}>
                    <Botao
                      type="submit"
                      label="Salvar"
                      color={Base.Mango}
                      className={classes.button}
                    />
                  </Grid>
                </Grid>
                : ""
            }
          </div>
          : ""}

      </Card>
    </form>
  );
}

export default CDEOrcamentoImportar;
