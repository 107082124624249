import React from "react";

// Componentes
import { Box } from "@mui/material";
import PropTypes from "prop-types";

// Styles
import { useStyles } from "./style";
import IconeLista from "../../../../assets/jornadaCliente/icone-lista.png";

const Pagina3 = ({ titulo, conteudo }) => {
  const classes = useStyles();

  return (
    <Box className={classes.containerImage} p={3}>
      <img className={classes.iconeLista} src={IconeLista} alt="imagem lista" />
      <Box className={classes.boxTexto} pl={6}>
        {titulo}
      </Box>
      {conteudo}
    </Box>
  );
};

Pagina3.propTypes = {
  titulo: PropTypes.node,
  conteudo: PropTypes.node
};

Pagina3.defaultProps = {
  titulo: <></>,
  conteudo: <></>
};

export default Pagina3;
