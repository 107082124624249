/* eslint-disable jsx-a11y/control-has-associated-label */
import CloseIcon from "@mui/icons-material/Close";
import { Box, Grid, Modal } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import ArCondicionado from "../../../../assets/icones/categorias/ar-condicionado.svg";
import Chuveiro from "../../../../assets/icones/categorias/chuveiro.svg";
import Fogao from "../../../../assets/icones/categorias/fogao.svg";
import Lampada from "../../../../assets/icones/categorias/lampada.svg";
import MaquinaLavar from "../../../../assets/icones/categorias/maquina-lavar.svg";
import Refrigerador from "../../../../assets/icones/categorias/refrigerador.svg";
import { useStyles } from "./style";

const ModalCategoria = ({ aberto, setAberto }) => {
  const classes = useStyles();

  return (
    <>
      <Modal
        open={aberto}
        onClose={() => setAberto(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={classes.modal}>
          <Box className={classes.cardCabecalho}>
            <h3 className={classes.titulo}>Tipos de aparelhos</h3>
            <button
              type="button"
              className={classes.closeButton}
              onClick={() => setAberto(false)}
            >
              <CloseIcon className={classes.closeIcone} />
            </button>
          </Box>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Box p={1} className={classes.bordaDireita}>
                <div className={classes.cardConteudo}>
                  <div>
                    <img
                      src={Refrigerador}
                      className={classes.cardIcone}
                      alt="ícone refrigerador"
                    />
                    Geladeira e freezer
                  </div>
                </div>
                <div className={classes.cardConteudo}>
                  <span>Geladeira, freezer, adega e cervejeira</span>
                </div>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box p={1}>
                <div className={classes.cardConteudo}>
                  <img
                    src={ArCondicionado}
                    className={classes.cardIcone}
                    alt="ícone ar condicionado"
                  />
                  Ar condicionado e ventilador
                </div>
                <span>Ar condicionado e ventilador</span>
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Box
                p={1}
                className={`${classes.bordaDireita} ${classes.bordaTopo}`}
              >
                <div className={classes.cardConteudo}>
                  <img
                    src={Chuveiro}
                    className={classes.cardIcone}
                    alt="ícone chuveiro"
                  />
                  Chuveiro e aquecedor
                </div>
                <span>
                  Chuveiro, aquecedor, aquecedor de piscina, ferro de passar
                  roupa, secador de cabelo
                </span>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box p={1} className={classes.bordaTopo}>
                <div className={classes.cardConteudo}>
                  <img
                    src={Fogao}
                    className={classes.cardIcone}
                    alt="ícone fogão"
                  />
                  cozinha
                </div>
                <span>Air fryer</span>
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Box
                p={1}
                className={`${classes.bordaDireita} ${classes.bordaTopo} ${classes.altura}`}
              >
                <div className={classes.cardConteudo}>
                  <img
                    src={MaquinaLavar}
                    className={classes.cardIcone}
                    alt="ícone máquina de lavar"
                  />
                  Lavanderia
                </div>
                <span>Máquina de lavar e secadora de roupas</span>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box p={1} className={classes.bordaTopo}>
                <div className={classes.cardConteudo}>
                  <img
                    src={Lampada}
                    className={classes.cardIcone}
                    alt="ícone lampada"
                  />
                  Outros
                </div>
                <span>Iluminação, aparelhos eletrônicos e carregadores</span>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </>
  );
};

ModalCategoria.propTypes = {
  aberto: PropTypes.bool,
  setAberto: PropTypes.func
};

ModalCategoria.defaultProps = {
  aberto: false,
  setAberto: () => {}
};

export default ModalCategoria;
