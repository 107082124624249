import React, { useState, useEffect } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { Card, Grid, Box, Divider } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import TuneIcon from "@mui/icons-material/Tune";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import theme from "../../../themes";
import Loader from "../../../componentes/loader";
import BotaoFiltroOrdenacao from "../../../componentes/botaoFiltroOrdenar";
import SelectAutocompleteCheckbox from "../../../componentes/selectAutocompleteCheckbox";
import InputData from "../../../componentes/inputTextoData";
import { store } from "../../../global/redux";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";
import {
  getFaturasVisaoGeral,
  downloadFatura
} from "../../../servicos/dgIntegracaoServico";
import formatarValor from "../../../global/utils/formatarValor";
import { useStyles, tema, StatusIcone } from "./style";

const VisaoGeral = () => {
  const classes = useStyles();
  const global = useSelector((state) => state.usuario);
  const { unidades, unidadeSelecionada } = global;
  const [listaTipoFatura] = useState([
    { id: "Pago", nome: "Pagas" },
    { id: "Vencida", nome: "Vencidas" },
    { id: "Em aberto", nome: "Em aberto" }
  ]);
  const [tipoFatura, setTipoFatura] = useState([]);
  const [dataBusca, setDataBusca] = useState();
  const [ordemSelecionada, setOrdemSelecionada] = useState("DataVencimento");
  const [valorOrdenacao, setValorOrdenacao] = useState(0);
  const [carregando, setCarregando] = useState(false);
  const [dados, setDados] = useState([]);

  const onChangeTipoFatura = (item) => {
    const todosCampo = item?.findIndex((x) => x.id === -1) >= 0;
    const todosLista = tipoFatura?.findIndex((x) => x.id === -1) >= 0;

    const quemEntrou = item.find(
      (i) => tipoFatura.findIndex((u) => u.id === i.id) === -1
    );
    const quemSaiu = tipoFatura.find(
      (u) => item.findIndex((i) => i.id === u.id) === -1
    );

    const selecionarTodasOpcoes = quemEntrou?.id === -1;
    const removerTodasOpcoes = quemSaiu?.id === -1;
    const selecionarOpcaoETodos =
      !todosCampo &&
      !todosLista &&
      item.length === listaTipoFatura.filter((x) => x.id !== -1).length;
    const removerOpcaoTodos = todosCampo && quemSaiu !== -1;

    if (selecionarTodasOpcoes || selecionarOpcaoETodos)
      setTipoFatura(listaTipoFatura);
    else if (removerOpcaoTodos)
      setTipoFatura((oldState) =>
        oldState.filter((x) => x.id !== -1 && x.id !== quemSaiu.id)
      );
    else if (removerTodasOpcoes) setTipoFatura([]);
    else setTipoFatura(item);
  };

  const ordernacoes = [
    {
      key: "ValorFinanceiro",
      asc: "0",
      desc: "1",
      label: "Por valor ($)"
    },
    {
      key: "DataVencimento",
      asc: "0",
      desc: "1",
      label: "Data de vencimento"
    }
  ];

  const handleClickSortOrderSelecionado = (valor, idCampoSelecionado) => {
    const val =
      ordemSelecionada === idCampoSelecionado && valor === ""
        ? ""
        : idCampoSelecionado;
    setOrdemSelecionada(val);
    setValorOrdenacao(valor);
  };

  const meses = [
    { id: 1, mes: "Janeiro" },
    { id: 2, mes: "Fevereiro" },
    { id: 3, mes: "Março" },
    { id: 4, mes: "Abril" },
    { id: 5, mes: "Maio" },
    { id: 6, mes: "Junho" },
    { id: 7, mes: "Julho" },
    { id: 8, mes: "Agosto" },
    { id: 9, mes: "Setembro" },
    { id: 10, mes: "Outubro" },
    { id: 11, mes: "Novembro" },
    { id: 12, mes: "Dezembro" }
  ];

  const retornarCorStatus = (status) => {
    if (status === "Vencida") return "#DC3545";

    if (status === "Pago") return "#2ABF6F";

    if (status === "Em aberto") return "#F4A330";

    return "";
  };

  const retornarTextoStatus = (status) => {
    if (status === "Vencida") return "VENCIDA";

    if (status === "Pago") return "PAGAMENTO REALIZADO";

    if (status === "Em aberto") return "PAGAMENTO EM ABERTO";

    return "";
  };

  const retornarDataFatura = (dadosDataFatura) => {
    if (dadosDataFatura.status === "Vencida")
      return moment(dadosDataFatura?.dataVencimentoLUZ).format("DD/MM/YYYY");

    if (dadosDataFatura.status === "Pago")
      return moment(dadosDataFatura?.dataPagamento).format("DD/MM/YYYY");

    if (dadosDataFatura.status === "Em aberto")
      return moment(dadosDataFatura?.dataRegistro).format("DD/MM/YYYY");

    return "";
  };

  const obterVisaoGeral = async () => {
    if ((unidades && unidades.length) || unidadeSelecionada) {
      setCarregando(true);
      let unidadesString = "";
      let statusString = "";

      if (unidadeSelecionada) {
        unidadesString = `unidadeIds=${unidadeSelecionada}`;
      } else {
        const unidadesIds = [...new Set(unidades.map((item) => item.id))];

        unidadesIds.map((id, index) => {
          unidadesString =
            index === unidadesIds.length - 1
              ? `${unidadesString}unidadeIds=${id}`
              : `${unidadesString}unidadeIds=${id}&`;
          return id;
        });
      }

      if (tipoFatura.length) {
        const statusFiltrados = tipoFatura.filter((tipo) => tipo.id !== -1);

        statusFiltrados.map((status, index) => {
          statusString =
            index === statusFiltrados.length - 1
              ? `${statusString}StatusFatura=${status.id}`
              : `${statusString}StatusFatura=${status.id}&`;
          return status;
        });
      }

      const params = {};

      if (dataBusca)
        params.DataRefencia = moment(dataBusca).format("YYYY-MM-DD");

      if (valorOrdenacao >= 0 && ordemSelecionada)
        params[ordemSelecionada] = valorOrdenacao;

      try {
        const resposta = await getFaturasVisaoGeral(
          unidadesString,
          params,
          statusString
        );
        if (resposta.data && resposta.status === 200) {
          const faturasFiltradas = resposta?.data?.length
            ? resposta.data.filter((fatura) => fatura.status !== "Cancelada")
            : [];
          setDados(faturasFiltradas);
        }
      } catch (error) {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem:
              error?.response?.data?.message ||
              "Erro interno, entre em contato com o suporte!"
          })
        );
      } finally {
        setCarregando(false);
      }
    } else {
      setDados([]);
    }
  };

  const handleClickAtivarFiltro = () => {
    if (valorOrdenacao >= 0) obterVisaoGeral();
  };

  const onClickDownload = async (dadosFatura) => {
    if (dadosFatura?.idArquivoFaturaLUZ) {
      try {
        await downloadFatura(
          dadosFatura,
          dadosFatura?.nomeUnidade || dadosFatura?.nome
        );
      } catch (error) {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem:
              error?.response?.data?.message ||
              "Erro interno, entre em contato com o suporte!"
          })
        );
      } finally {
        setCarregando(false);
      }
    }
  };

  useEffect(() => {
    obterVisaoGeral();
  }, [unidades, unidadeSelecionada, dataBusca, tipoFatura]);

  return (
    <Loader loading={carregando}>
      <Card>
        <ThemeProvider theme={tema}>
          <Grid container p={4} spacing={2}>
            <Grid item xs={12} sm={4}>
              <SelectAutocompleteCheckbox
                id="selectDistribuidoraId"
                name="tipoFatura"
                options={listaTipoFatura}
                label="Tipo de fatura:"
                style={classes}
                getOptionLabel={(option) => option.nome}
                onChange={(e, item) => {
                  onChangeTipoFatura(item);
                }}
                value={tipoFatura}
                allowClear
              />
            </Grid>
            <Grid
              item
              xs={0}
              sm={0}
              md={0}
              lg={2}
              sx={{ display: { xs: "none", lg: "block" } }}
            />

            <Grid item xs={6} sm={4} lg={3}>
              <InputData
                type="text"
                id="dataBusca"
                name="dataBusca"
                label="Data"
                customValue={dataBusca}
                className={classes}
                format="MM/yyyy"
                views={["month", "year"]}
                openTo="month"
                onAccept={(data) => setDataBusca(data)}
                autoOk
              />
            </Grid>
            <Grid item xs={6} sm={4} lg={3}>
              <BotaoFiltroOrdenacao
                type="button"
                color={theme.color.secondaryBorderColor}
                background="transparent"
                label="Filtrar / Ordernar"
                icon={<TuneIcon />}
                className={classes.buttonFiltro}
                ordenadorUm="Ordenador"
                ordenacaoColuna={ordernacoes}
                ordemColunaSelecionada={ordemSelecionada}
                onClickSortOrder={handleClickSortOrderSelecionado}
                onClickAtivarAgora={handleClickAtivarFiltro}
                larguraBotao="4"
              />
            </Grid>
          </Grid>
          <Divider />
          <Grid container p={2}>
            {dados.length > 0 &&
              dados.map((dado) => (
                <Grid item xs={12} sm={6} md={4} p={2} key={dado.id}>
                  <Card className={classes.card}>
                    <Box className={classes.endereco}>
                      <HomeOutlinedIcon />
                      <Box>
                        <h5>{dado?.nomeUnidade || dado?.nome}</h5>
                        <p>
                          {dado?.endereco ? dado?.endereco : ""}
                          {dado?.numero ? `, ${dado?.numero}` : ""}
                          {dado?.cidade ? ` - ${dado?.cidade}` : ""}
                        </p>
                      </Box>
                    </Box>
                    <Box className={classes.borda}>
                      <p>
                        {`N° da UC: ${
                          dado?.numeroInstalacao ? dado?.numeroInstalacao : ""
                        }`}
                      </p>
                    </Box>
                    <Box className={classes.preco}>
                      <h4>
                        {`Fatura 
                        ${
                          meses.find((mes) => mes.id === dado.mesReferencia)
                            ?.mes
                        }/${dado.anoReferencia}`}
                      </h4>
                      <h3>{formatarValor(dado?.valorTotalFaturaLUZ)}</h3>
                      <p>
                        {`Vencimento: ${
                          dado?.dataVencimentoLUZ
                            ? moment(dado?.dataVencimentoLUZ).format(
                                "DD/MM/YYYY"
                              )
                            : "-"
                        }`}
                      </p>
                    </Box>
                    <Box className={classes.borda}>
                      <Box className={classes.status}>
                        <StatusIcone color={retornarCorStatus(dado?.status)} />
                        <h5>{retornarTextoStatus(dado?.status)}</h5>
                      </Box>
                      <span>{`em: ${retornarDataFatura(dado)}`}</span>
                    </Box>
                    <button
                      type="button"
                      onClick={() => onClickDownload(dado)}
                      className={classes.botaoBaixar}
                    >
                      <DownloadIcon color="#FFF" />
                      Baixar fatura
                    </button>
                  </Card>
                </Grid>
              ))}

            {dados.length === 0 && (
              <div className={classes.naoSelecionado}>
                {unidades.length > 0
                  ? "Dados não encontrados para cliente selecionado!"
                  : "É necessário escolher um cliente para continuar!"}
              </div>
            )}
          </Grid>
        </ThemeProvider>
      </Card>
    </Loader>
  );
};

export default VisaoGeral;
