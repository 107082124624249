import MICROSERVICO from "global/enumeradores/microServico";
import obterApi from "servicos/api";

const api = () => obterApi(MICROSERVICO.PROJECAOTARIFARIA);

export const buscarVigenciasWACC = async (data) => {
  return api().get(`v1/WACC/dataref?dataref=${data}`);
}

export const salvarWacc = async (data) => {
  return api().put("v1/WACC/update", data);
}

export const addWacc = async (data) => {
  return api().post("v1/WACC/add", data);
}

export const deleteWacc = async (inicioVigencia) => {
  return api().delete(`v1/WACC/delete?iniciovigencia=${inicioVigencia}`);
}