import React from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import { useStyles, Icone } from "./style";

const CardDownload = (props) => {
  const { icon, text } = props;

  const classes = useStyles();

  return (
    <Card className={classes.root} border={3}>
      <CardContent>
        <Typography className={classes.content} variant="h5" component="h2">
          <Icone className="ml-4" src={icon} />
          <span className="ml-2">{text}</span>
        </Typography>
      </CardContent>
    </Card>
  );
};

CardDownload.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.any]),
  text: PropTypes.string
};

CardDownload.defaultProps = {
  icon: false,
  text: ""
};

export default CardDownload;
