import RetornoEndpointDto from "../../../../global/dto/retornoEndpointDto";
import { store } from "../../../../global/redux";
import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";
import { listarFaturas, downloadBoleto, downloadNotaFiscal } from "../../../../servicos/faturasServico";
import { buscarCalculoCustosPdf } from "../../../../servicos/calculoCustosServico";

export default class FaturasHelper {

  static async listarFaturas(filtro) {
    try {

      const resultado = await listarFaturas(filtro);

      return RetornoEndpointDto.Sucesso(
        "Faturas obtidas com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu um erro ao obter a lista de faturas"
      );
    }
  }

  static async BaixarBoleto(params) {
    try {
      await downloadBoleto(params);

      return RetornoEndpointDto.Sucesso(
        "arquivo obtido com sucesso",
        null
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu um erro ao obter arquivo"
      );
    }
  }

  static async BaixarNotaFiscal(parametros) {
    try {
      await downloadNotaFiscal(parametros);

      return RetornoEndpointDto.Sucesso(
        "arquivo obtido com sucesso",
        null
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu um erro ao obter arquivo"
      );
    }
  }
  
  static async BuscarCalculoCustosPdf(unidadeConsumidoraId, mes, ano) {
    try {

      const resultado = await buscarCalculoCustosPdf(unidadeConsumidoraId, mes, ano);
      
      return RetornoEndpointDto.Sucesso(
        "Cálculo custos obtido com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu um erro ao obter o Cálculo custos"
      );
    }
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }
}
