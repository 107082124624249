export default class FiltroDto {
  constructor(
    tamanhoPaginacao,
    pagina,
    searchString,
    ordernar,
    filtroPersonalizado,
    sortOrder,
    empresaSelecionada,
    Expiradas,
    tipoCliente,
    campoPesquisa
  ) {
    this.tamanhoPaginacao = tamanhoPaginacao;
    this.pagina = pagina;
    this.searchString = searchString;
    this.sortOrder = sortOrder;
    this.status = ordernar;
    this[filtroPersonalizado.nome] = filtroPersonalizado.valor;
    this.empresaId = empresaSelecionada;
    this.Expiradas = Expiradas;
    this.pessoaFisicaJuridica = tipoCliente;
    this.campoTextoSelecionado = campoPesquisa;
  }
}
