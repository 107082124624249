export default class FiltroListagemMedidoresDto {
  constructor(
    ordemSelecionada,
    filtroSelecionado,
    paginaAtual,
    tamanhoPaginacao,
    unidadeConsumidoraId,
    searchString,
    clienteId,
    empresaId,
    tipoCliente,
    tipoTc,
    tipoMedidores
  ) {
    this.sortOrder = ordemSelecionada;
    this.filtroSelecionado = filtroSelecionado;
    this.Page = paginaAtual;
    this.PageSize = tamanhoPaginacao;
    this.unidadeConsumidoraId = unidadeConsumidoraId;
    this.searchString = searchString;
    this.clienteId = clienteId;
    this.empresaId = empresaId;
    this.tipoCliente = tipoCliente;
    this.tipoTc = tipoTc;
    this.tipoMedidores = tipoMedidores;
  }
}
