import React from "react";
import { useStyles } from "./style";

const UnidadeSemDemanda = () => {

  const classes = useStyles();

  return (
    <div className={classes.root}>
      Não há demanda para a unidade selecionada
    </div>
  )
}
export default UnidadeSemDemanda;
