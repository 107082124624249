import { makeStyles } from "@mui/styles";
import theme from "../../../../../themes";

export const useStyles = makeStyles({
  inputData: {
    width: "100%",
    margin: 0,
    "& .MuiFormControl-root": {
      width: "100%"
    }
  },
  search: {
    "& label.Mui-focused": {
      color: `${theme.color.secondaryBorderColor} !important`
    },
    "& .MuiInput-underline:after": {
      borderColor: `${theme.color.secondaryBorderColor} !important`
    },
    "& .MuiFormLabel-root": {
      color: `${theme.color.secondaryBorderColor} !important`
    },
    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-input": {
        color: `${theme.color.secondaryBorderColor} !important`
      },
      "& .MuiInputAdornment-root": {
        color: `${theme.color.secondaryBorderColor} !important`
      },
      "& fieldset": {
        borderColor: `${theme.color.secondaryBorderColor} !important`
      },
      "&:hover fieldset": {
        borderColor: `${theme.color.secondaryBorderColor} !important`
      },
      "&.Mui-focused fieldset": {
        borderColor: `${theme.color.secondaryBorderColor} !important`
      },
      "&.makeStyles-buttonFiltro-72": {
        height: "53px !important"
      }
    }
  },
  buttonFiltro: {
    fontSize: "18px",
    maxWidth: "222px",
    height: "53px"
  }
});
