/* eslint-disable import/no-unresolved */
import { makeStyles } from '@mui/styles';
import { Base } from "componentes/cores";
import { createTheme } from "@mui/material/styles";

const theme = createTheme();

export const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: theme.palette.background.paper
  },
  container: {
    margin: 0,
    width: "100%",
    "& .MuiGrid-root": {
      color: `${Base.BlackBastille} !important`
    },
    "& .MuiDropzoneArea-text": {
      color: `${Base.BlackBastille} !important`
    },
    "& .MuiInputBase-root": {
      height: "46px"
    },
    "& .MuiFormLabel-root": {
      lineHeight: "1"
    },
  },
  formControl: {
    minWidth: "100% !important",
  },
  formSpaceBetweenTop: {
    marginBottom: "20%"
  },
  formSpaceBetweenBottom: {
    marginTop: "20%"
  },
  marginLabel: {
    marginBottom: "15px"
  },
  alignItems: {
      alignSelf: "center"
  },
  button: {
    fontSize: "18px",
  },
  buttonCustom: {
    fontSize: "18px",
    color: "#FEBB2F !important",
    backgroundColor: "transparent !important",

    "&:hover": {
      color: "#212529",
    }

  },
  botao: {
    background: "none",
    color: "inherit",
    border: "none",
    padding: "0",
    font: "inherit",
    cursor: "pointer",
    outline: "inherit",
  },
  total: {
    textAlign: "end",
  },
  inputText: {
    textAlign: "center",
    border: "none",
    background: "rgba(46, 49, 49, 0.2)",

    "& .MuiInputBase-root.Mui-disabled": {
      color: Base.BlackBastille,

      "& input": {
        textAlign: "center"
      }
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none"
    }
  },
  footerButtons: {
    paddingBottom: "1rem",
    width: "50vw"
  },
  footerCheckbox:{
    paddingBottom: "1rem"
  }
}));

