import { makeStyles } from "@mui/styles";
import theme from "../../../../themes";
import { Base } from "../../../../componentes/cores";

export const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    margin: 0,
    paddingBottom: 0,
    paddingRight: 0,
    "& fieldset": {
      borderColor: "rgba(0, 0, 0, 0.26) !important"
    },
    "& input": {
      padding: "7px 5px",
      fontSize: "14px"
    },
    "& button": {
      padding: "0"
    },
    "& .MuiInputAdornment-positionEnd": {
      marginLeft: 0
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: 0
    }
  }
}));

export const customStyles = {
  table: {
    style: {
      background: "transparent"
    }
  },
  headRow: {
    style: {
      background: "transparent",
      marginBottom: "3px",
      borderBottom: "none"
    }
  },
  headCells: {
    style: {
      background: Base.GreyDisabled,
      marginLeft: "6px",
      fontSize: "16px",
      textAlign: "center",
      justifyContent: "center",
      color: theme.color.primary
    }
  },
  rows: {
    style: {
      background: "transparent",
      marginBottom: "3px",
      marginTop: "3px",
      minHeight: "30px !important",
      ":not(:last-of-type)": {
        borderBottom: "none"
      }
    }
  },
  cells: {
    style: {
      marginLeft: "6px",
      background: Base.White,
      fontSize: "14px",
      padding: "4px",
      textAlign: "center",
      width: "100%",
      "&.rdt_TableCell div:first-child": {
        width: "100%"
      },
    }
  }
};
