import { Card, Grid } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";

import Botao from "componentes/botao";
import BotaoRetornarListagem from "componentes/botaoRetornarListagem";
import { Base } from "componentes/cores";
import FormValidation from "componentes/formValidation";
import MaterialInputTexto from "componentes/inputTexto/materialInput";
import Loader from "componentes/loader";
import SelectArredondado from "componentes/selectArredondado";
import MaterialSwitch from "componentes/switch";

import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { desabilitarFiltroLateral } from "global/redux/modulos/usuario/actions";
import { RotasDTO } from "global/rotas/rotasUrlDto";

import CategoriaHelper from "../../Categorias/helper";
import TipoHelper from "./helper";
import { useStyles } from "./style";

function Cadastro() {
  const history = useHistory();
  const { id: idUrl } = useParams();

  const [carregando, setCarregando] = useState();

  const [carregandoCategoria, setCarregandoCategoria] = useState();
  const [listaCategorias, setListaCategorias] = useState();
  const [categoriaId, setCategoriaId] = useState();

  const [id, setId] = useState();

  const [nomeTipo, setNomeTipo] = useState();

  const [ativo, setAtivo] = useState(true);

  const setarCategoria = (idCliente) => {
    // console.log('setarCategoria', idCliente);
    setCategoriaId(idCliente);
  };

  const obterClientes = useCallback(async () => {
    setCarregandoCategoria(true);
    const resultado = await CategoriaHelper.Listar();
    setCarregandoCategoria(false);

    if (resultado.sucesso) {
      setListaCategorias(resultado.data.lista);
      return;
    }

    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem: resultado.mensagem
      })
    );
  }, []);

  const { register, errors, handleSubmit } = useForm({
    reValidateMode: "onSubmit"
  });

  const classes = useStyles();

  const onSubmit = async (params) => {
    if (categoriaId) params.CategoriaRelatorioId = categoriaId;

    if (id) params.Id = id;

    setCarregando(true);

    const retorno = await TipoHelper.Salvar(params);

    setCarregando(false);

    if (!retorno.sucesso) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: retorno.mensagem
        })
      );
      return;
    }

    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem: retorno.mensagem
      })
    );

    history.push(RotasDTO.RelatoriosTipos);
  };

  const obterPorId = useCallback(async () => {
    if (!idUrl || idUrl <= 0) return;

    setCarregando(true);

    const retorno = await TipoHelper.ObterPorId({ id: idUrl });

    if (!retorno.sucesso) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: retorno.mensagem
        })
      );
      return;
    }

    const Tipo = retorno.data;
    // console.log('Tipo', Tipo);

    setId(Tipo.id);
    setNomeTipo(Tipo.nome);
    setAtivo(Tipo.ativo);
    setCategoriaId(Tipo.categoriaRelatorio.id);

    setCarregando(false);
  }, [idUrl]);

  const categoriaRequired = useMemo(
    () => !categoriaId && "Campo Categoria é obrigatório!",
    [categoriaId]
  );

  useEffect(() => {
    obterPorId();
  }, [obterPorId]);

  useEffect(() => {
    store.dispatch(desabilitarFiltroLateral(true));

    return () => store.dispatch(desabilitarFiltroLateral(false));
  }, [desabilitarFiltroLateral]);

  useEffect(() => {
    obterClientes();
  }, [obterClientes]);

  return (
    <>
      <Loader loading={carregando}>
        <FormValidation onSubmit={onSubmit} handleSubmit={handleSubmit}>
          <Card>
            <Grid container p={2} spacing={4} className={classes.container}>
              <Grid item xs={6} className="font-weight-bold">
                {`${id ? "Editar" : "Novo"} Tipo de Relatório`}
              </Grid>

              <BotaoRetornarListagem urlListagem={RotasDTO.RelatoriosTipos} />

              <Grid item lg={3} md={3} sm={6}>
                <Loader loading={carregandoCategoria}>
                  <SelectArredondado
                    id="CategoriaRelatorioId"
                    name="CategoriaRelatorioId"
                    valueKey="id"
                    marginBottom="0"
                    valueName="nome"
                    dataSource={listaCategorias}
                    label="Categoria do Relatório"
                    defaultValue={categoriaId}
                    value={categoriaId}
                    onChange={(event) => {
                      setarCategoria(Number(event.target.value));
                    }}
                    placeholder="Categoria Relatorio"
                    allowClear
                    ref={register({
                      name: "CategoriaRelatorioId",
                      required: categoriaRequired
                    })}
                    errors={errors}
                  />
                </Loader>
              </Grid>

              <Grid item lg={6} md={6} sm={12}>
                <MaterialInputTexto
                  type="text"
                  id="Nome"
                  name="Nome"
                  permiteValorBranco
                  defaultValue={nomeTipo}
                  label="Nome do tipo de relatório"
                  renderIconShowHide={false}
                  ref={register({
                    required: "Campo Nome é obrigatório!",
                    maxLength: {
                      value: 100,
                      message: "Quantidade máxima de 100 caracteres!"
                    }
                  })}
                  errors={errors}
                />
              </Grid>

              {id && (
                <>
                  <Grid item lg={3} md={4} sm={6} justifyContent="flex-start">
                    <MaterialSwitch
                      ref={register()}
                      label="Ativo"
                      labelPlacement="top"
                      id="Ativo"
                      justify="flex-start"
                      name="Ativo"
                      checked={ativo}
                      onChange={(param) => setAtivo(param)}
                    />
                  </Grid>
                </>
              )}
            </Grid>
            <div className={classes.savewrapper}>
              <Botao
                type="submit"
                label="Salvar"
                color={Base.Mango}
                className={classes.button}
              />
            </div>
          </Card>
        </FormValidation>
      </Loader>
    </>
  );
}

export default Cadastro;
