/* eslint-disable import/no-unresolved */
import MICROSERVICO from "global/enumeradores/microServico";
import obterApi from "servicos/api";

const api = () => obterApi(MICROSERVICO.PROJECAOTARIFARIA);

export const obterFatorComposto = async () => {
    return api().get("v1/SerieHistoricaValor/fatorcomposto/visualiza");
}

export const listarSerieHistorica = async () => {
    return api().get("v1/SerieHistorica?Ativo=true");
}

export const salvarFatorComposto = async (data) => {
    return api().put("v1/SerieHistoricaValor/fatorcomposto/atualiza", data);
}