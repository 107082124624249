export const InterfaceDTO = {
  PermissaoAlterarCpfCnpj: "Permissão de alterar CPF e/ou CNPJ",
  PodeAlterarFiltrarOfficer: "Pode alterar ou filtrar o Officer",
  PodeEditar: "Editar campos",
  EnviarEmailAcesso: "Enviar e-mail de acesso",
  PermissaoContratarCotacao: "Permissão para contratar cotação",
  PermissaoImportarLeads:
    "Permissão de importação de Leads (Cliente e Unidade)",
  PermissaoCalcularCustos: "Permissão para calcular custos",
  PermissaoUsuariosClienteProspect:
    "Permissão para usuários tipo cliente/prospect visualizarem todos os usuários do mesmo cliente.",
  PermissaoCadastrarCliente: "Permissão de cadastrar cliente",
  PermissaoVerPerfil: "Ver perfil",
  PermissaoVerRadioGroup: "Ver radio group",
  PermissaoVerStatus: "Ver status",
  PermissaoVerAprovarSerasa: "Ver botão aprovar serasa",
  PermissaoEditarCamposCotacoes: "Pode editar campos cotações GD",
  InserirPlanoPersonalizado: "Inserir plano personalizado",
  PermissaoEditarPrecoMensal: "Permissão Editar Preço Mensal",
  PodeAlterarFiltrarOfficerDelta: "Pode alterar ou filtrar o Officer - Delta",
  PermissaoEditarCotacaoACL: "Pode editar campos cotações ACL",
  PermissaoEditarMedidorGD: "Pode editar medidor GD",
  PodeEditarCPFCNPJ: "Pode Editar CPF e CNPJ",
  PodeEditarNumeroInstalacao: "Pode editar Numero instalação",
  PodeEditarNumeroInstalacaoACL: "Pode editar Numero instalação - ACL",
  PodeEditarDesconto: "Pode editar descontos",
  PodeEditarUnidade: "Pode Editar Unidade",
  PermissaoContratacao: "Permissão para contratação",
  PermissaoReativarBoletaGD: "Reativar BoletaGD Rejeitada ou Cancelada",
  PodeEditarBoletaGd: "Pode editar Boleta GD",
  SuspensaoCancelamentoOperacoes: "Suspensão Cancelamento Operações",
  AprovaContratoComercial: "Aprova Contrato Comercial Cotaçãogd",
  AprovaContratoMaster: "Aprova Contrato Master Cotaçãogd"
};
