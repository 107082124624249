/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import shortid from "shortid";

import TuneIcon from "@mui/icons-material/Tune";
import { Box, Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { tipoProdutoId } from "../../../global/utils/tipoProdutoUtil";
import { TabelaPaginadaMobile } from "../../../componentes";
import BotaoFiltroOrdenacao from "../../../componentes/botaoFiltroOrdenarLuz";
import MaterialInputBusca from "../../../componentes/inputBusca";
import Loader from "../../../componentes/loader";
import SelectArredondado from "../../../componentes/selectArredondado";
import Coluna from "../../../componentes/tabelaPaginada/colunas/coluna";
import ColunaComponentePersonalizado from "../../../componentes/tabelaPaginada/colunas/colunaComponentePersonalizado";
import ResultadoPaginadoDto from "../../../componentes/tabelaPaginada/resultadoPaginadoDto";
import TabelaPaginada from "../../../componentes/tabelaPaginadaLuz";
import { handleLoading } from "../../../global/redux/modulos/loader/actions";
import ColunaMultiplosBotoes from "./componentes/colunaMultiplosBotoes";
import ItemListaExpansivelResponsivo from "./componentes/itemListaExpansivelResponsiva";
import ListagemCotacaoHelper from "./helper";

import { store } from "../../../global/redux";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";

import { InterfaceDTO } from "../../../global/dto/interfacesDto";
import { RotasDTO } from "../../../global/rotas/rotasUrlDto";
import FiltroAdicionalTabela from "./dto/filtroAdicionalTabela";
import FiltroDto from "./dto/filtroDto";
import ListagemCotacaoDto from "./dto/listagemCotacaoDto";
import enumeradorBotao from "./enumeradores/enumeradorBotao";

import theme from "../../../themes";
import { useStyles } from "./style";

import { listarOfficersPorArvoreGerencial } from "../../../servicos/colaboradoresServico";
import {
  listarFiltrarClientePor,
  listarOrganizarPor,
  ordenacaoPorColuna
} from "../../../servicos/listagemCotacoesGdServico";

import {
  desabilitarCliente,
  desabilitarEmpresa,
  desabilitarUnidade,
  selecionarEmpresa
} from "../../../global/redux/modulos/usuario/actions";

import CustomLoader from "../../../componentes/customLoader";
import ModalLuz from "../../../componentes/modalLuz";
import ModalMensagemErro from "../../../componentes/ModalMensagemErro";
import { formatarCampo } from "../../../global/utils/formatCpfCnpj";
import { usuarioPossuiFuncionalidade } from "../../../servicos/funcionalidadesServico";
import { handlePdf } from "../../../servicos/utils";
import statusCotacao from "../constantes/constanteStatusCotacao";
import EmpresaNaoSelecionada from "./componentes/EmpresaNaoSelecionada";

const ListagemCotacoesGD = () => {
  const [ordemSelecionada, setOrdemSelecionada] = useState();
  const [ordemColunaSelecionada, setOrdemColunaSelecionada] = useState();
  const [filtroAdicionalTabela, setFiltroAdicionalTabela] = useState({});
  const [valorOrdenacao, setValorOrdenacao] = useState();
  const rotas = useSelector((state) => state.rotas);
  const usuario = useSelector((state) => state.usuario);
  const { menu } = usuario;

  const permissaoAlterarFiltrarOfficer = usuarioPossuiFuncionalidade(
    menu,
    rotas.rotaAtual?.caminho,
    RotasDTO.CotacoesGDListagem,
    InterfaceDTO.PodeAlterarFiltrarOfficer
  );

  const permissaoAlterarFiltrarOfficerDelta = usuarioPossuiFuncionalidade(
    menu,
    rotas.rotaAtual?.caminho,
    RotasDTO.CotacoesGDListagem,
    InterfaceDTO.PodeAlterarFiltrarOfficerDelta
  );

  const aprovaContratoComercial = usuarioPossuiFuncionalidade(
    menu,
    rotas.rotaAtual?.caminho,
    RotasDTO.CotacoesGDListagem,
    InterfaceDTO.AprovaContratoComercial
  );

  const aprovaContratoMaster = usuarioPossuiFuncionalidade(
    menu,
    rotas.rotaAtual?.caminho,
    RotasDTO.CotacoesGDListagem,
    InterfaceDTO.AprovaContratoMaster
  );

  const [colaboradorId, setColaboradorId] = useState(
    usuario?.usuario?.colaboradorId
  );
  const [filtroPersonalizado, setFiltroPersonalizado] = useState({
    ...(colaboradorId > -1 && { colaboradorId })
  });

  const [listaOfficer, setListaOfficer] = useState([]);
  const [carregandoOfficer, setCarregandoOfficer] = useState(false);
  const [campoPesquisa, setCampoPesquisa] = useState(null);
  const [modalAcao, setModalAcao] = useState(false);
  const [nomeCliente, setNomeCliente] = useState("");
  const [envelopeId, setEnvelopeId] = useState(null);
  const [modalErro, setModalErro] = useState(false);
  const [modalMensagemErro, setModalMensagemErro] = useState("");
  const [dadosListagem, setDadosListagem] = useState({});
  const [modalParceiro, setModalParceiro] = useState(false);
  const [mensagemParceiro, setMensagemParceiro] = useState("");
  const [
    filtroTipoClienteSelecionado,
    setFiltroTipoClienteSelecionado
  ] = useState(null);

  useEffect(() => {
    if (!colaboradorId) return;

    setFiltroPersonalizado({
      ...(colaboradorId > -1 && { colaboradorId })
    });
  }, [colaboradorId]);

  const { empresaSelecionada } = usuario;

  const obterOfficer = async (empresa) => {
    try {
      setCarregandoOfficer(true);
      const lista = await listarOfficersPorArvoreGerencial(empresa ?? 0);

      if (lista?.status !== 200 || !lista?.data) {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "Officers não encontrados!"
          })
        );
        return;
      }

      if (
        permissaoAlterarFiltrarOfficer &&
        permissaoAlterarFiltrarOfficerDelta
      ) {
        setListaOfficer([
          {
            id: 0,
            nomeColaborador: "Todos",
            areaFuncionalId: 0,
            cargoId: 0,
            colaboradorSituacao: [],
            colaboradorCupom: [],
            arvoreGerencialColaboradorEmpresa: [],
            areaFuncionalDescricao: "",
            cargoDescricao: "",
            situacao: "",
            nivel: 0,
            arvoreGerencialEmpresa: []
          },
          ...lista?.data
        ]);
        return;
      }

      if (
        !permissaoAlterarFiltrarOfficerDelta &&
        permissaoAlterarFiltrarOfficer
      ) {
        setListaOfficer([
          {
            id: 0,
            nomeColaborador: "Todos",
            areaFuncionalId: 0,
            cargoId: 0,
            colaboradorSituacao: [],
            colaboradorCupom: [],
            arvoreGerencialColaboradorEmpresa: [],
            areaFuncionalDescricao: "",
            cargoDescricao: "",
            situacao: "",
            nivel: 0,
            arvoreGerencialEmpresa: []
          },
          ...lista?.data
        ]);
        return;
      }

      const dados = lista?.data?.filter((d) => d.id === colaboradorId);
      setListaOfficer(dados);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    } finally {
      setCarregandoOfficer(false);
    }
  };

  useEffect(() => {
    obterOfficer(empresaSelecionada);
  }, [permissaoAlterarFiltrarOfficer, empresaSelecionada]);

  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const themes = useTheme();
  const mobile = useMediaQuery(themes.breakpoints.down("md"));
  const refTabela = useRef(null);

  const { register, control } = useForm({
    reValidateMode: "onSubmit"
  });

  const textoBusca = useWatch({
    control,
    name: "textoBusca",
    defaultValue: ""
  });

  useEffect(() => {
    store.dispatch(selecionarEmpresa(32));
    store.dispatch(desabilitarCliente(true));
    store.dispatch(desabilitarUnidade(true));

    return () => {
      store.dispatch(desabilitarEmpresa(false));
      store.dispatch(desabilitarCliente(false));
      store.dispatch(desabilitarUnidade(false));
    };
  }, []);

  const onChangeSelecionarOfficer = (event) => {
    setColaboradorId(event);
  };

  const onClickEditar = (id) => {
    history.push(`${RotasDTO.CotacoesGD}/${id}`);
  };

  const cotacaoProposta = async (idCotacao, nomeArquivo, produtoId) => {
    const {
      data,
      sucesso,
      mensagem
    } = await ListagemCotacaoHelper.cotacaoProposta(
      idCotacao,
      produtoId,
      handleLoading,
      dispatch
    );

    if (!sucesso) {
      setModalMensagemErro(mensagem);
      dispatch(handleLoading(false));
      setModalErro(true);
      return;
    }

    handlePdf(data?.html, nomeArquivo);
  };

  const onClickPDF = async (parametros) => {
    const nomeArquivo = `proposta-${parametros?.dataCotacao}-${parametros?.numeroInstalacao}`;
    cotacaoProposta(
      parametros?.id,
      nomeArquivo,
      tipoProdutoId(parametros?.distribuidoraId)
    );
  };

  const reenviarContrato = async () => {
    const model = {
      envelopeId
    };

    const { sucesso, mensagem } = await ListagemCotacaoHelper.reenviarContrato(
      model,
      setModalAcao,
      handleLoading,
      dispatch
    );

    if (!sucesso) {
      ListagemCotacaoHelper.exibirErro(mensagem);
      dispatch(handleLoading(false));
    } else {
      ListagemCotacaoHelper.exibirSucesso(mensagem);
    }
  };

  const onClickContratar = async (parametros) => {
    setModalAcao(true);
    setNomeCliente(parametros?.nome);
    setEnvelopeId(parametros?.envelopeId);
  };

  const liberaPropostaContrato = async () => {
    setModalParceiro(false);
    const model = {};

    if (dadosListagem?.tipoAprovacao === "master") {
      model.aprovaContratoMaster =
        dadosListagem?.liberaPropostaContratoMaster === null ||
        dadosListagem?.liberaPropostaContratoMaster === "N"
          ? "S"
          : "N";
      model.aprovaContratoMasterColaboradorId = Number(
        usuario?.usuario?.colaboradorId
      );
    } else if (dadosListagem?.tipoAprovacao === "comercial") {
      model.aprovaContrato =
        dadosListagem?.liberaPropostaContrato === null ||
        dadosListagem?.liberaPropostaContrato === "N"
          ? "S"
          : "N";
      model.aprovaContratoColaboradorId = Number(
        usuario?.usuario?.colaboradorId
      );
    }

    const {
      sucesso,
      mensagem
    } = await ListagemCotacaoHelper.liberaPropostaContrato(
      dadosListagem?.id,
      model
    );

    if (!sucesso) {
      ListagemCotacaoHelper.exibirErro(mensagem);
      return;
    }

    if (refTabela?.current) refTabela.current.obterDadosPaginados();
  };

  const onClickParceiro = async (params) => {
    setModalParceiro(true);
    setDadosListagem({ ...params });
    setMensagemParceiro(
      `Deseja ${
        params?.liberaPropostaContrato === "N" ||
        params?.liberaPropostaContrato === null
          ? "liberar"
          : "bloquear"
      } o envio do Contrato para a unidade ${params?.nome}?`
    );
  };

  const onClickBotao = (tipo, parametros, tipoAprovacao) => {
    switch (tipo) {
      case enumeradorBotao.Edicao:
        if (parametros.statusCotacao !== "AN") {
          onClickEditar(parametros.id);
        }
        break;
      case enumeradorBotao.PDF:
        if (parametros.statusCotacao !== "AN") {
          onClickPDF(parametros);
        }
        break;
      case enumeradorBotao.Contrato:
        if (parametros.statusCotacao === "AT") {
          onClickContratar(parametros);
        }
        break;
      case enumeradorBotao.Parceiro:
        parametros.tipoAprovacao = tipoAprovacao;
        onClickParceiro(parametros);
        break;
      default:
        break;
    }
  };

  const ColunaMobile = ({ parametros }) => {
    return (
      <ItemListaExpansivelResponsivo
        key={shortid.generate()}
        color={theme.color.buttonColor}
        onClick={onClickBotao}
        parametros={parametros}
      />
    );
  };

  const colunas = !mobile
    ? [
        new Coluna(
          "nome",
          "Nome do Cliente/Nome Fantasia",
          false,
          "22%",
          "22%"
        ),
        new Coluna("cpfCnpj", "CPF/CNPJ", false, "16%", "16%"),
        new Coluna("numeroInstalacao", "UC", false, "16%"),
        new Coluna("dataCotacao", "Data da Cotação", false, "16%"),
        new Coluna("status", "Status", false, "10%"),
        new ColunaComponentePersonalizado(
          "opcoes",
          "Opções",
          ColunaMultiplosBotoes,
          onClickBotao,
          false,
          true,
          "20%",
          "20%",
          "pl-2"
        )
      ]
    : [
        new ColunaComponentePersonalizado(
          "nome",
          "",
          ColunaMobile,
          null,
          false,
          false,
          null,
          null
        )
      ];

  const obterStatusDescricao = (status) => {
    switch (status) {
      case statusCotacao.Aberta:
        return "Aberta";
      case statusCotacao.Fechada:
        return "Fechada";
      case statusCotacao.Expirada:
        return "Expirada";
      case statusCotacao.Cancelada:
        return "Cancelada";
      case statusCotacao.Rejeitada:
        return "Rejeitada";
      case statusCotacao.Anulada:
        return "Anulada";
      default:
        return "";
    }
  };

  const obterListagem = useCallback(async (parametros) => {
    if (
      !parametros?.filtroPersonalizado?.colaboradorId ||
      !parametros?.filtroAdicional?.empresaId
    )
      return null;

    const filtro = new FiltroDto(
      parametros?.tamanhoPagina,
      parametros?.pagina,
      parametros?.campoPesquisa === "CnpjCpf"
        ? parametros?.pesquisar?.replace(/[^0-9]+/g, "")
        : parametros?.pesquisar,
      parametros?.filtroAdicional?.ordenacao ?? "",
      {
        nome: "colaboradorId",
        valor: parametros?.filtroPersonalizado?.colaboradorId ?? ""
      },
      parametros?.filtroAdicional?.sortOrder,
      parametros?.filtroAdicional?.empresaId ?? "",
      true,
      parametros?.filtroAdicional?.tipoCliente ?? "",
      parametros?.campoPesquisa
    );

    const {
      data,
      mensagem,
      sucesso
    } = await ListagemCotacaoHelper.listarCotacoes(
      filtro,
      permissaoAlterarFiltrarOfficerDelta
    );

    if (!sucesso) {
      ListagemCotacaoHelper.exibirErro(mensagem);
      return new ResultadoPaginadoDto([], 1, 0, 0);
    }

    if (data.cotacaoGD?.length === 0 ?? true)
      return new ResultadoPaginadoDto([], 1, 0, 0);

    if (data === "") return new ResultadoPaginadoDto([], 1, 0, 0);

    const {
      data: dadosTiposPLanos
    } = await ListagemCotacaoHelper.obterTodosPlanos(false);

    const resLinhas = data?.cotacaoGD?.map((res) => {
      const dataCotacao = window.moment(res?.dataCotacao).format("DD-MM-YYYY");
      const dataValidade = window
        .moment(res?.dataValidade)
        .format("DD-MM-YYYY");
      const status = obterStatusDescricao(res.status);
      const cpfCnpj = formatarCampo(res?.cpfCnpj);

      const desabledBtnContrato =
        res.status === statusCotacao.Expirada ||
        res.status === statusCotacao.Fechada ||
        res.status === statusCotacao.Cancelada ||
        res.envelopeId === null;

      const desabledBtnDownload =
        res.status === statusCotacao.Expirada ||
        res.status === statusCotacao.Fechada ||
        res.status === statusCotacao.Cancelada;

      const planoSelecionadoId = res?.planosGerados?.find(
        (plano) => plano?.cotacaoSelecionada === "S" && plano.ativo === "S"
      )?.tipoPlanoCotacaoId;

      const planoSelecionadoDescricao = dadosTiposPLanos?.tipoPlanoCotacoes?.find(
        (item) => item?.tipoPlano === planoSelecionadoId
      )?.descricao;

      return new ListagemCotacaoDto(
        res?.id,
        res?.nome,
        cpfCnpj,
        res?.numeroInstalacao,
        dataCotacao,
        status,
        dataValidade,
        planoSelecionadoDescricao || "",
        true,
        res.status,
        res.envelopeId,
        desabledBtnContrato,
        desabledBtnDownload,
        res.distribuidoraId,
        usuario?.usuario?.cargoId,
        res?.aprovaContrato,
        res?.cargoId,
        aprovaContratoMaster,
        aprovaContratoComercial,
        res?.aprovaContratoMaster
      );
    });

    if (!resLinhas) return false;

    return new ResultadoPaginadoDto(
      resLinhas,
      data?.paginaAtual,
      data?.totalItens,
      data?.totalPaginas
    );
  }, []);

  const onChangeFiltrosTabela = useCallback(
    async (parametros) => {
      try {
        if (
          !permissaoAlterarFiltrarOfficer &&
          (!colaboradorId || colaboradorId === -1)
        )
          return null;

        return obterListagem({
          tamanhoPagina: parametros.totalPagina ?? "",
          pagina: parametros.pagina ?? "",
          pesquisar: parametros.pesquisar ?? "",
          filtroAdicional: new FiltroAdicionalTabela(
            parametros.filtrosAdicionais?.ordenacao ?? "",
            parametros.filtrosAdicionais?.sortOrder ?? "",
            parametros.filtrosAdicionais?.empresaId ?? "",
            parametros?.filtrosAdicionais?.tipoCliente ?? ""
          ),
          filtroPersonalizado: parametros?.filtroPersonalizado ?? "",
          campoPesquisa: campoPesquisa ?? ""
        });
      } catch (error) {
        return null;
      }
    },
    [permissaoAlterarFiltrarOfficer, colaboradorId, campoPesquisa]
  );

  const handleAlterarFiltroLateral = () => {
    setFiltroAdicionalTabela(
      new FiltroAdicionalTabela(
        ordemSelecionada,
        valorOrdenacao,
        empresaSelecionada,
        filtroTipoClienteSelecionado
      )
    );
  };

  useEffect(() => {
    handleAlterarFiltroLateral();
  }, [empresaSelecionada]);

  const handleClickAtivarFiltro = () => {
    setFiltroAdicionalTabela(
      new FiltroAdicionalTabela(
        ordemSelecionada,
        valorOrdenacao,
        empresaSelecionada,
        filtroTipoClienteSelecionado
      )
    );
  };

  const handleClickOrdemSelecionada = (event) => {
    const val = ordemSelecionada === event ? "" : event;
    setOrdemSelecionada(val);
  };

  const handleClickSortOrderSelecionado = (valor, idCampoSelecionado) => {
    const val =
      ordemColunaSelecionada === idCampoSelecionado && valor === ""
        ? ""
        : idCampoSelecionado;
    setOrdemColunaSelecionada(val);
    setValorOrdenacao(valor);
  };

  const handleClickFiltroTipoClienteSelecionado = (event) => {
    const val = filtroTipoClienteSelecionado === event ? "" : event;
    setFiltroTipoClienteSelecionado(val);
  };

  const handleMensagemModal = () => {
    return (
      <Box>
        A proposta será enviada para o cliente:
        <Box component="span" fontWeight="700" mx="5px">
          {nomeCliente}
        </Box>
        no e-mail salvo na cotação
      </Box>
    );
  };

  const handleMensagemErro = (mensagem) => {
    return (
      <Box component="div" fontWeight="700" textAlign="center">
        {mensagem}
      </Box>
    );
  };

  return (
    <>
      <ModalLuz
        item={modalAcao}
        titulo="Você têm certeza que deseja enviar o contrato?"
        mensagem={handleMensagemModal()}
        onCancelar={() => setModalAcao(false)}
        onConfirmar={reenviarContrato}
      />

      <ModalMensagemErro
        item={modalErro}
        titulo=""
        mensagem={handleMensagemErro(modalMensagemErro)}
        onCancelar={() => {
          setModalErro(false);
          setModalMensagemErro("");
        }}
        onConfirmar={() => {}}
        texto="Tentar novamente"
      />
      <ModalLuz
        item={modalParceiro}
        mensagem={handleMensagemErro(mensagemParceiro)}
        onCancelar={() => {
          setModalParceiro(false);
        }}
        onConfirmar={liberaPropostaContrato}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} lg={3}>
          <SelectArredondado
            id="CampoPesquisa"
            name="CampoPesquisa"
            label="Filtrar por campo"
            placeholder="Filtrar por campo"
            valueKey="valor"
            valueName="nome"
            dataSource={[
              { nome: "Todos", valor: "" },
              { nome: "Nome", valor: "Nome" },
              { nome: "CNPJ|CPF", valor: "CnpjCpf" },
              { nome: "Número Instalação", valor: "NumeroInstalacao" }
            ]}
            value={campoPesquisa}
            onChange={(e) => {
              setCampoPesquisa(e.target.value);
            }}
            permiteValorBranco
            styleForm={false}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          {campoPesquisa && (
            <MaterialInputBusca
              type="text"
              id="textoBusca"
              name="textoBusca"
              label="Buscar"
              renderIconShowHide
              searchAdornment
              permiteValorBranco
              defaultValue={textoBusca ?? ""}
              ref={register}
            />
          )}
        </Grid>
        <Grid item xs={6} lg={4}>
          <Loader loading={carregandoOfficer}>
            <SelectArredondado
              select
              id="officer"
              name="officer"
              label="Officer"
              placeholder="Officer"
              valueKey="id"
              valueName="nomeColaborador"
              dataSource={listaOfficer}
              value={colaboradorId}
              onChange={(event) => {
                onChangeSelecionarOfficer(event.target.value);
              }}
              permiteValorBranco
              ref={register}
              marginBottom="0"
              styleForm={false}
            />
          </Loader>
        </Grid>
        <Grid component={Grid} item xs={6} lg={2}>
          <BotaoFiltroOrdenacao
            type="button"
            color={theme.color.secondaryBorderColor}
            background="transparent"
            label="Filtrar / Ordernar"
            icon={<TuneIcon htmlColor="#FA3EB1" />}
            className={classes.buttonFiltro}
            ordenacao={listarOrganizarPor()}
            ordenacaoColuna={ordenacaoPorColuna()}
            ordenador="Ordenação"
            ordenadorUm="Status"
            ordenadorTres="Tipo Cliente"
            ordemSelecionada={ordemSelecionada}
            ordemColunaSelecionada={ordemColunaSelecionada}
            onClickOrdenacao={handleClickOrdemSelecionada}
            onClickSortOrder={handleClickSortOrderSelecionado}
            onClickAtivarAgora={handleClickAtivarFiltro}
            filtroTipoCliente={listarFiltrarClientePor()}
            filtrosTipoClienteSelecionado={filtroTipoClienteSelecionado}
            onClickFitroTipoCliente={handleClickFiltroTipoClienteSelecionado}
          />
        </Grid>
        {empresaSelecionada === "" ? (
          <EmpresaNaoSelecionada />
        ) : (
          <Grid item xs={12} className={`${classes.tabela} mt-4`}>
            {!mobile ? (
              <CustomLoader>
                <TabelaPaginada
                  ref={refTabela}
                  onChangeFiltrosTabela={onChangeFiltrosTabela}
                  colunas={colunas}
                  pesquisar={textoBusca}
                  filtrosAdicionais={filtroAdicionalTabela}
                  filtroPersonalizado={filtroPersonalizado}
                  paginaAtual={1}
                  paginationPerPage={5}
                />
              </CustomLoader>
            ) : (
              <CustomLoader>
                <TabelaPaginadaMobile
                  className={classes.fundoCelulaTransparente}
                  ref={refTabela}
                  pesquisar={textoBusca}
                  onChangeFiltrosTabela={onChangeFiltrosTabela}
                  filtrosAdicionais={filtroAdicionalTabela}
                  filtroPersonalizado={filtroPersonalizado}
                  linhasTabela={8}
                  colunas={colunas}
                  paginaAtual={1}
                />
              </CustomLoader>
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ListagemCotacoesGD;
