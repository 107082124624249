import { makeStyles } from "@mui/styles";
import styled from "styled-components";
import theme from "themes";

export const useStyles = makeStyles(() => ({
  container: {
    "& .apexcharts-legend.apexcharts-align-center.apx-legend-position-bottom": {
      bottom: "0 !important",
      position: "absolute !important",
      display: "block !important",
      fontFamily: theme.font.family,
      fontSize: "14px",
      inset: "auto",
      overflowY: "hidden",
      textAlign: "justify",
      width: "100%"
    },
    "& .apexcharts-legend.apexcharts-align-center.position-bottom": {
      bottom: "0 !important",
      position: "absolute !important",
      display: "block !important",
      fontFamily: theme.font.family,
      fontSize: "14px",
      inset: "auto",
      overflowY: "hidden",
      textAlign: "justify",
      width: "100%"
    },
    "& .apexcharts-legend-text": {
      textAlign: "justify",
      width: "100%"
    },
    "& .apexcharts-datalabels-group": {
      transform: "translateX(32%)",
      "& .apexcharts-text.apexcharts-datalabel-label": {
        color: `${theme.color.textoBarra}`,
        fill: `${theme.color.textoBarra} !important`,
        textAnchor: "start",
        textTransform: "capitalize",
        transform: "translateX(0)"
      },
      "& .apexcharts-text.apexcharts-datalabel-value": {
        color: `${theme.color.textoBarra}`,
        fill: `${theme.color.textoBarra} !important`,
        textAnchor: "start",
        transform: "translateX(0)"
      }
    }
  }
}));

export const Legenda = styled.div`
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  text-transform: capitalize;
`;

export const Label = styled.div``;
export const Valor = styled.div``;
export const Total = styled.div``;
