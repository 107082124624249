/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect, useWatch, useMemo } from "react";
import { Grid, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import moment from "moment";
import { useForm } from "react-hook-form";
import Loader from "componentes/loader";
import ModalConfirmacao from "componentes/modalConfirmacao";
import MaterialInputTexto from "componentes/inputTexto/materialInput";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import DataTable from "react-data-table-component";
import Coluna from "../../../../../componentes/tabelaPaginada/colunas/coluna";
import { useStyles, customStyles } from "./style";
import {
  obterCenarios,
  obterSeriesHistoricasCenario
} from "../../../servicos/seriesHistoricas";

const SerieHistoricaCenarioListagem = () => {
  const classes = useStyles();

  const [carregandoCenarios, setCarregandoCenarios] = useState(false);
  const [modalExclusao, setModalExclusao] = useState(false);
  const [PCAT, setPCAT] = useState("");
  const [SPARTA, setSPARTA] = useState("");
  const [cenarios, setCenarios] = useState([]);
  const [cenario, setCenario] = useState("");
  const [cenarioDescricao, setCenarioDescricao] = useState("");
  const [seriesHistoricas, setSeriesHistoricas] = useState([]);

  const { handleSubmit, register, control } = useForm({
    reValidateMode: "onSubmit"
  });

  // const textoBusca = useWatch({
  //     control,
  //     name: "textoBusca",
  //     defaultValue: ""
  // });

  // const stringBusca = useMemo(() => {
  //     return textoBusca;
  // }, [textoBusca]);

  const handleCenarios = async () => {
    try {
      setCarregandoCenarios(true);
      const lista = await obterCenarios();
      if (lista?.status === 200 && lista?.data) {
        setCarregandoCenarios(false);
        setCenarios(lista?.data);
      } else {
        setCarregandoCenarios(false);
        setCenarios([]);
      }
    } catch (error) {
      console.info(error);
    }
  };

  useEffect(() => {
    if (!cenarios?.length) handleCenarios();
  }, [cenarios.length, handleCenarios]);

  const obterSeriesHistoricas = async (idCenario) => {
    try {
      const lista = await obterSeriesHistoricasCenario(idCenario);
      if (lista?.status === 200 && lista?.data) {
        const newData = lista?.data;

        newData.forEach((element) => {
          element.PrimeiroRegistro = moment(element.PrimeiroRegistro)?.format(
            "L"
          );
          element.UltimoRegistro = moment(element.UltimoRegistro)?.format("L");
        });

        setSeriesHistoricas(lista?.data);
      } else {
        setSeriesHistoricas([]);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Não há dados para exibição."
        })
      );
    }
  };

  const handleChangeCenario = async (event) => {
    cenarios.forEach((item) => {
      if (item.id === event) {
        setCenario(item);
        setCenarioDescricao(item.descricao);
      }
    });
    await obterSeriesHistoricas(event);
  };

  return (
    <form>
      <Loader loading={carregandoCenarios} className="w-auto">
        <ModalConfirmacao
          item={modalExclusao}
          // onConfirmar={(item) => onConfirmarExclusao(item)}
          mensagem="Tem certeza que deseja ativar/inativar esta configuração?"
          onCancelar={() => setModalExclusao(false)}
        />
        <Grid container style={{ paddingLeft: "1.5em", paddingRight: "1.5em" }}>
          <Grid container pb={6} spacing={3} alignItems="center">
            <Grid item xs={2}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="energia-suprimento-label">Cenário</InputLabel>
                <Select
                  variant="outlined"
                  labelId="energia-suprimento"
                  id="energiaSuprimento"
                  // value={energiaSuprimento ?? ""}
                  // onChange={e => setEnergiaSuprimento(e.target.value)}
                  label="Cenário"
                  onChange={(e) => handleChangeCenario(e.target.value)}
                  fullWidth
                >
                  {cenarios.map((item) => (
                    <MenuItem value={item?.id}>{item.nome}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={5}>
              <FormControl variant="outlined" className={classes.formControl}>
                <MaterialInputTexto
                  type="text"
                  id="nomeSerieHistorica"
                  name="codigo"
                  label="Descrição"
                  renderIconShowHide={false}
                  defaultValue={cenarioDescricao}
                  readOnly
                />
              </FormControl>
            </Grid>
          </Grid>
          {/* {cenario ?
                        <Grid container xs={12}>
                            <Grid item xs={6}>
                                <MaterialInputTexto
                                    type="text"
                                    id="textoBusca"
                                    name="textoBusca"
                                    label="Buscar"
                                    renderIconShowHide
                                    searchAdornment
                                    defaultValue={textoBusca ?? ""}
                                    //ref={register}
                                    className={classes.search}
                                    permiteValorBranco
                                />
                            </Grid>
                        </Grid> : <></>} */}
          {cenario ? (
            <Grid item xs={12} className={`${classes.tabela}`}>
              <DataTable
                columns={[
                  new Coluna("Codigo", "Código", false, "20%"),
                  new Coluna("Periodicidade", "Periodicidade", false, "16%"),
                  new Coluna("Unidade", "Unidade", false, "12%"),
                  new Coluna("Categoria", "Status", false, "16%"),
                  new Coluna(
                    "PrimeiroRegistro",
                    "Primeiro registro",
                    false,
                    "18%"
                  ),
                  new Coluna("UltimoRegistro", "Último registro", false, "18%")
                ]}
                title="Séries Históricas por Cenário"
                subHeaderAlign="right"
                data={seriesHistoricas}
                theme="DeltaEnergia"
                customStyles={customStyles}
                // conditionalRowStyles={conditionalRowStyles}
                noDataComponent="Nenhum registro encontrado"
                noHeader
              />
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </Loader>
    </form>
  );
};

export default SerieHistoricaCenarioListagem;
