import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const api = () => obterApi(MICROSERVICO.TELEMETRIA);

export const getTelemetriaHora = (mac, date, tarifa) => {
  return api().get(
    `v1/Telemetria/hora?Mac=${mac}&DataInicio=${date}&Tarifa=${tarifa}`
  );
};

export const getTelemetriaAtual = (mac, date, tarifa) => {
  return api().get(
    `v1/Telemetria/atual?Mac=${mac}&DataInicio=${date}&Tarifa=${tarifa}`
  );
};

export const getTelemetriaDiario = (mac, dataInicio, dataFim, tarifa) => {
  return api().get(
    `v1/Telemetria/diario?Mac=${mac}&DataInicio=${dataInicio}&DataFim=${dataFim}&Tarifa=${tarifa}`
  );
};

export const getTelemetriaResumo = (mac, tarifa, diaFaturamento) => {
  return api().get(
    `v1/Telemetria/mes-atual?Mac=${mac}&Tarifa=${tarifa}&DiaFaturamento=${diaFaturamento}`
  );
};

export const getTelemetriaMensal = (
  unidadeConsumidoraId,
  mac,
  tarifa,
  diaFaturamento,
  dataInicio,
  dataFim
) => {
  return api().get(
    `v1/Telemetria/mensal?UnidadeConsumidoraId=${unidadeConsumidoraId}&Tarifa=${tarifa}&DiaFaturamento=${diaFaturamento}&Mac=${mac}&DataInicio=${dataInicio}&DataFim=${dataFim}`
  );
};

export const getTelemetriaAnual = (mac, tarifa, dataInicio, dataFim) => {
  return api().get(
    `v1/Telemetria/anual?Mac=${mac}&Tarifa=${tarifa}&DataInicio=${dataInicio}&DataFim=${dataFim}`
  );
};

export const getStatusMedidor = (mac) => {
  return api().get(`v1/TestaMedidor/status-medidor?mac=${mac}`);
};

export const verificarMac = async (mac) => {
  return api().get(`v1/DesagregacaoDeCarga/existeMac/${mac}`);
};
