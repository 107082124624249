import { makeStyles } from "@mui/styles";
import themes from "themes";

export const useStyles = makeStyles(() => ({
  label: {
    color: "#4F4F4F",
    marginBottom: "8px"
  },
  checkboxLabel: {
    color: "#4F4F4F",
    fontWeight: "normal",
    margin: 0
  },
  botao: {
    color: `${themes.color.secondaryText} !important`,
    width: "100%",
    justifyContent: "center",
    justifyItems: "center",

    "&:disabled": {
      backgroundColor: "#9F9F9F",
      opacity: 1,
      color: "#4F4F4F !important",
      borderColor: "#9F9F9F"
    }
  },
  botaoTransparente: {
    color: `${themes.color.secondary} !important`,
    width: "100%",
    justifyContent: "center",
    justifyItems: "center",
    borderColor: `${themes.color.secondary} !important`,
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent !important"
    }
  },
  inputTextArea: {
    borderColor: "rgba(0, 0, 0, 0.23)",
    borderRadius: "3px",
    height: "80px",
    maxWidth: "100%",
    padding: "16.5px 14px",
    width: "100%"
  }
}));
