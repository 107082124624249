import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const api = () => obterApi(MICROSERVICO.MEASUREMENT);

export const obterEnqueteAparelhos = async (unidadeConsumidoraId) => {
  return api().get(
    `v1/unidadeconsumidoraenqueteaparelhos/unidadeconsumidoraid?unidadeConsumidoraId=${unidadeConsumidoraId}`
  );
};

export const postarEnqueteAparelhos = (body) => {
  return api().post("v1/unidadeconsumidoraenqueteaparelhos", body);
};
