export default class ListagemBoletasDto {
  constructor(
    id,
    boleta,
    clienteNome,
    unidadeConsumidora,
    desconto,
    dataCriacaoBoleta,
    dataInicioFornecimento,
    formaPagamento,
    status,
  ) {
    this.id = id;
    this.boleta = boleta;
    this.clienteNome = clienteNome;
    this.unidadeConsumidora = unidadeConsumidora;
    this.desconto = desconto;
    this.dataCriacaoBoleta = dataCriacaoBoleta;
    this.dataInicioFornecimento = dataInicioFornecimento;
    this.formaPagamento = formaPagamento;
    this.status = status;
  }
}


