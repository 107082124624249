import { getHistImpByChaveNatural, getProjecaoInsumo } from "../servicos/spartaServico";

export const importPing = async (idInsumo, datetimeImportStarted, userId, codigoInsumo, callback) => {
	try {
            const projecaoInsumoResponse = await getProjecaoInsumo(codigoInsumo)
            console.log('projecaoInsumoResponse.data', projecaoInsumoResponse.data[0])
            let projecaoInsumoResponseData = projecaoInsumoResponse.data[0]

            const intervalAttemptMax = projecaoInsumoResponseData.tempoFimPing / projecaoInsumoResponseData.tempoIntervaloPing
            let intervalAttemptCount = 0

            if(isNaN(intervalAttemptMax)){
                callback('error', 'intervalAttemptMax invalid')
                return
            }
    
            setTimeout(async () => {
                console.log('start ping')
                let intervalId = setInterval(async () => {
                intervalAttemptCount++
                console.log('intervalAttemptCount/intervalAttemptMax', intervalAttemptCount, intervalAttemptMax)
                if(intervalAttemptCount > intervalAttemptMax){
                    clearInterval(intervalId)
                    callback('error', 'timeout')
                    return
                }
                
                const histImpByChaveNatural = await getHistImpByChaveNatural(idInsumo, userId, datetimeImportStarted)
                console.log('histImpByChaveNatural.data', histImpByChaveNatural.data)
    
                if(histImpByChaveNatural.status === 200 && histImpByChaveNatural?.data.length > 0){
                    clearInterval(intervalId)
                    var messages = []

                    // histImpByChaveNatural?.data.forEach(element => {
                    //     messages.push(`titulo: ${element.CodigoErro} | valor:${element.Mensagem}`)
                    // });

                    callback(histImpByChaveNatural?.data[0].status, histImpByChaveNatural.data[0].observacao)
                }
    
                }, projecaoInsumoResponseData.tempoIntervaloPing);
            }, projecaoInsumoResponseData.tempoInicioPing);    
        
    } catch (error) {
        callback('error', error)
    }
}