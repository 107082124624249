export function salvarRotaAtual(rota) {
  return {
    type: "@rotas/salvarRotaAtual",
    payload: rota
  };
}

export function salvarMenuSelecionadoColecao(colecao) {
  return {
    type: "@rotas/salvarMenuSelecionadoColecao",
    payload: colecao
  };
}
