import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import shortid from "shortid";

// Componentes
import { Card, Grid } from "@mui/material";
import DataTable from "react-data-table-component";

// Componentes
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";
import Botao from "../../../../componentes/botao";
import BotaoRetornarListagem from "../../../../componentes/botaoRetornarListagem";
import FormValidation from "../../../../componentes/formValidation";
import MaterialInputTexto from "../../../../componentes/inputTexto/materialInput";
import Loader from "../../../../componentes/loader";
import SelectAutocomplete from "../../../../componentes/selectAutocomplete";
import Coluna from "../../../../componentes/tabelaPaginada/colunas/coluna";
import ColunaBotao from "../../../../componentes/tabelaPaginada/colunas/colunaBotao";

// Ícones

// DTO
import { RotasDTO } from "../../../../global/rotas/rotasUrlDto";

// Styles
import { customStyles, TabelaSemDados, useStyles } from "./style";

// Serviços
import { listarClientesCombo } from "../../../../servicos/clientesServico";
import {
  buscarPorId,
  salvarGrupoAssociativo
} from "../../../../servicos/grupoAssociativoServico";

// Redux
import { store } from "../../../../global/redux";
import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";

const GrupoAssociativoCadastro = () => {
  const { id } = useParams();
  const history = useHistory();
  const classes = useStyles();

  const { register, errors, handleSubmit } = useForm({
    reValidateMode: "onSubmit"
  });

  const [dadosGrupo, setDadosGrupo] = useState();
  const [carregandoDadosGrupo, setCarregandoDadosGrupo] = useState(false);

  const [clientesDoGrupoLista, setClientesDoGrupoLista] = useState([]);

  const obterGrupoAssociativo = useCallback(async (idGrupo) => {
    try {
      setCarregandoDadosGrupo(true);
      const grupo = await buscarPorId(idGrupo);
      if (grupo?.status === 200 && grupo?.data) {
        setDadosGrupo(grupo?.data);
        if (grupo?.data?.clientes) {
          const clientes = grupo?.data?.clientes.map((cliente) => {
            return {
              id: cliente.id,
              nome: cliente.nomeFantasia ?? cliente.razaoSocial,
              ativo: cliente?.situacao === "AT"
            };
          });
          setClientesDoGrupoLista(clientes);
        }
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    } finally {
      setCarregandoDadosGrupo(false);
    }
  }, []);

  useEffect(() => {
    if (id) obterGrupoAssociativo(id);
  }, [id, obterGrupoAssociativo]);

  const usuario = useSelector((state) => state.usuario);

  const [carregandoCliente, setCarregandoCliente] = useState(false);
  const [listaCliente, setListaCliente] = useState([]);
  const [clienteBuscaSelecionado, setClienteBuscaSelecionado] = useState(false);

  const obterCliente = useCallback(async () => {
    try {
      setCarregandoCliente(true);
      const lista = await listarClientesCombo(0, usuario?.token);
      if (lista?.status === 200 && lista?.data) {
        setListaCliente(lista?.data?.clientes);
      }
      setCarregandoCliente(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoCliente(false);
    }
  }, []);

  useEffect(() => {
    obterCliente();
  }, [obterCliente]);

  const onChangeClienteSelecionado = (cliente) => {
    setClienteBuscaSelecionado(cliente?.id);
  };

  const onClickAdicionarCliente = () => {
    if (clienteBuscaSelecionado) {
      const cliente = listaCliente.find(
        (item) => item.id === clienteBuscaSelecionado
      );
      if (cliente) {
        if (
          !clientesDoGrupoLista.find(
            (item) => item.id === clienteBuscaSelecionado
          )
        ) {
          setClientesDoGrupoLista(
            clientesDoGrupoLista.concat({
              id: cliente?.id,
              nome: cliente?.nomeFantasia,
              ativo: cliente?.situacao === "AT"
            })
          );
          setClienteBuscaSelecionado();
        } else {
          store.dispatch(
            alertaExibir({
              tipo: "warning",
              mensagem:
                "O cliente selecionado já está vinculado ao grupo associativo!"
            })
          );
        }
      }
    }
  };

  const onClickRemoverCliente = (clienteId) => {
    const listaAtualizada = clientesDoGrupoLista.filter(
      (cliente) => cliente.id !== clienteId
    );
    setClientesDoGrupoLista(listaAtualizada);
  };

  const colunas = [
    new Coluna("nome", "Cliente"),
    new ColunaBotao(
      "status",
      "",
      onClickRemoverCliente,
      <DeleteIcon className={classes.removerItem} />,
      false,
      "70px"
    )
  ];

  const onEnviarFormulario = async (dados) => {
    if (dados) {
      const listaClientes = [];
      if (clientesDoGrupoLista?.length >= 2) {
        const lista = clientesDoGrupoLista.map((cliente) => cliente.id);
        listaClientes.clientesIds = lista ?? [];
      } else {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "É necessário associar ao menos dois clientes ao grupo!"
          })
        );
        return;
      }
      try {
        const salvou = await salvarGrupoAssociativo(id ?? 0, {
          ...dados,
          ...listaClientes,
          ativo: true
        });
        if (salvou) {
          store.dispatch(
            alertaExibir({
              tipo: "success",
              mensagem: "O grupo associativo foi salvo com sucesso!"
            })
          );
          history.push(RotasDTO.GrupoAssociativo);
        }
      } catch (error) {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem:
              error?.response?.data?.message ??
              "Erro interno, entre em contato com o suporte!"
          })
        );
        console.info(error);
      }
    }
  };

  return (
    <>
      <Loader loading={carregandoDadosGrupo}>
        <FormValidation
          onSubmit={onEnviarFormulario}
          handleSubmit={handleSubmit}
        >
          <Card className={classes.cardCadastro}>
            <Grid container spacing={4} className={classes.container}>
              <Grid item xs={6} className="font-weight-bold">
                {dadosGrupo?.id ? "Editar" : "Novo"} grupo associativo
              </Grid>
              <BotaoRetornarListagem urlListagem={RotasDTO.GrupoAssociativo} />
              <Grid container spacing={4} className={classes.container}>
                <Grid item lg={6} md={6} sm={6}>
                  <MaterialInputTexto
                    type="text"
                    id="descricao"
                    name="descricao"
                    label="Nome do grupo"
                    placeholder="Nome do grupo"
                    defaultValue={dadosGrupo?.descricao}
                    renderIconShowHide={false}
                    ref={register({
                      required: "Campo Nome do grupo é obrigatório!",
                      maxLength: {
                        value: 50,
                        message: "Quantidade máxima de 50 caracteres!"
                      }
                    })}
                    errors={errors}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={4}
                className={`${classes.container} pb-3`}
              >
                <Grid item lg={6} md={6} sm={6}>
                  <Loader loading={carregandoCliente}>
                    <SelectAutocomplete
                      disableClearable
                      disableOpenOnFocus
                      id="cliente"
                      name="cliente"
                      options={listaCliente}
                      label="Cliente"
                      getOptionLabel={(option) =>
                        option?.nomeFantasia ?? option.razaoSocial
                      }
                      onChange={(e, valor) => {
                        onChangeClienteSelecionado(valor);
                      }}
                      value={clienteBuscaSelecionado}
                      allowClear
                      valueName="nomeFantasia"
                    />
                  </Loader>
                </Grid>
                <Grid item lg={3} md={4} sm={6}>
                  <Botao
                    id={shortid.generate()}
                    type="button"
                    label="Adicionar"
                    className={classes.button}
                    onClick={onClickAdicionarCliente}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Card>
          <Grid container spacing={0} className={classes.container}>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              className="text-white font-weight-bold my-4"
            >
              Clientes adicionados
            </Grid>
            <Grid item lg={12} md={12} sm={12}>
              <DataTable
                columns={colunas}
                data={clientesDoGrupoLista}
                noHeader
                theme="DeltaEnergia"
                customStyles={customStyles}
                noDataComponent={
                  <TabelaSemDados>Nenhum cliente associado</TabelaSemDados>
                }
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6} className="mt-4">
              <Botao
                id={shortid.generate()}
                type="submit"
                label="Salvar"
                className={classes.button}
              />
            </Grid>
          </Grid>
        </FormValidation>
      </Loader>
    </>
  );
};

export default GrupoAssociativoCadastro;
