import React from "react";
import PropTypes from "prop-types";

// Icones
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import { Grid } from "@mui/material";

import { useStyles } from "./style";

const ToggleIcon = ({ color, aberto, ativo, onClick }) => {
  const classes = useStyles({ color, ativo });

  return (
    <Grid item xs={1} className={`${classes.squareWidth} ${classes.gridItem}`}>
      <div
        className={`${classes.treeViewBorder} ${classes.treeItem} ${classes.wrapper} ${classes.center}`}
        role="button"
        tabIndex={0}
        onClick={onClick}
        onKeyDown={onClick}
      >
        {aberto ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </div>
    </Grid>
  );
};

ToggleIcon.propTypes = {
  color: PropTypes.string,
  aberto: PropTypes.bool,
  ativo: PropTypes.bool,
  onClick: PropTypes.oneOfType([PropTypes.func])
};

ToggleIcon.defaultProps = {
  color: "",
  aberto: 0,
  ativo: true,
  onClick: () => {}
};

export default ToggleIcon;
