import RetornoEndpointDto from "../../../../global/dto/retornoEndpointDto";

import {
  listarUsinasLuz,
  atualizarUsinasLuz
} from "../../../../servicos/usinasLuzServico";

export default class UsinasLuzHelper {
  static async listarUsinasLuz(filtro, setCarregandoUsinas) {
    try {
      setCarregandoUsinas(true);
      const resultado = await listarUsinasLuz(filtro);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Usinas não encontrados!");

      return RetornoEndpointDto.Sucesso(
        "Usinas obtida com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Erro ao buscar usinas");
    } finally {
      setCarregandoUsinas(false);
    }
  }

  static async atualizarUsinasLuz(params, setCarregandoUsinas) {
    try {
      setCarregandoUsinas(true);
      const resultado = await atualizarUsinasLuz(params);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Usinas não encontrados!");

      return RetornoEndpointDto.Sucesso(
        "Usinas atualizado com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Erro ao atualizado usinas");
    } finally {
      setCarregandoUsinas(false);
    }
  }
}
