import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

// Icones
import TuneIcon from "@mui/icons-material/Tune";

// Form Hooks
import { useForm, useWatch } from "react-hook-form";

// Redux
import { useSelector } from "react-redux";

// Componentes
import { Grid } from "@mui/material";
import MaterialInputBusca from "componentes/inputBusca";
import TabelaSemDados from "componentes/tabelaPaginada/tabelaSemDados";
import theme from "themes";
import { cpfCnpjValido } from "../../../../global/utils/formatCpfCnpj";
import { Botao, SelectArredondado } from "../../../../componentes";
import BotaoCadastro from "../../../../componentes/botaoCadastro";
import BotaoFiltroOrdenacao from "../../../../componentes/botaoFiltroOrdenar";
import { Base } from "../../../../componentes/cores";
import ItemListaExpansivel from "../../../../componentes/itemListaExpansivel";
import Loader from "../../../../componentes/loader";
import ModalConfirmacao from "../../../../componentes/modalConfirmacao";
import Paginacao from "../../../../componentes/paginacao";

// Redux
import { store } from "../../../../global/redux";
import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";
import {
  desabilitarCliente,
  desabilitarEmpresa,
  desabilitarUnidade
} from "../../../../global/redux/modulos/usuario/actions";

// Serviços
import {
  deletarColaborador,
  listarColaboradorPaginado,
  listarFiltrarPor,
  listarOrganizarPor
} from "../../../../servicos/colaboradoresServico";

// Rotas
import { RotasDTO } from "../../../../global/rotas/rotasUrlDto";

// Styles
import { useStyles } from "./style";

const ColaboradorListar = () => {
  const classes = useStyles();
  const history = useHistory();

  const [paginaAtual, setPaginaAtual] = useState();
  const [totalPaginas, setTotalPaginas] = useState();
  const [carregandoColaborador, setCarregandoColaborador] = useState(false);
  const [listaColaborador, setListaColaborador] = useState([]);
  const [carregamentoInicial, setCarregamentoInicial] = useState(false);
  const montouComponente = useRef(false);
  const [campoPesquisa, setCampoPesquisa] = useState("");

  const empresaSelecionada = useSelector(
    (state) => state.usuario.empresaSelecionada
  );

  const { register, control, setValue } = useForm({
    reValidateMode: "onSubmit"
  });

  const textoBusca = useWatch({
    control,
    name: "textoBusca",
    defaultValue: history?.location?.state?.colaborador?.id?.toString() || ""
  });

  const textoPesquisa = useMemo(() => {
    return textoBusca?.trim() || "";
  }, [textoBusca]);

  const handleListarColaborador = async (
    ordernacao,
    filtro,
    atual,
    empresa
  ) => {
    try {
      setCarregandoColaborador(true);

      const buscaPorId = history?.location?.state?.colaborador?.id?.toString()
        ? "BuscaPorId"
        : "";

      const lista = await listarColaboradorPaginado(
        ordernacao,
        filtro,
        empresa,
        atual,
        cpfCnpjValido(textoPesquisa),
        campoPesquisa || buscaPorId || ""
      );

      if (lista?.status === 200 && lista?.data?.colaboradores) {
        setListaColaborador(lista?.data.colaboradores);
        setPaginaAtual(lista?.data.paginaAtual);
        setTotalPaginas(lista?.data.totalPaginas);
      } else {
        setListaColaborador([]);
        setPaginaAtual();
        setTotalPaginas(0);
      }
      setCarregandoColaborador(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoColaborador(false);
    } finally {
      history.push({
        pathname: RotasDTO.Colaboradores,
        state: { colaborador: null }
      });
    }
  };

  useEffect(() => {
    setCarregamentoInicial(true);
    handleListarColaborador(
      ordemSelecionada,
      filtroSelecionado,
      1,
      empresaSelecionada
    );
  }, []);

  useEffect(() => {
    if (montouComponente.current) {
      setCarregamentoInicial(true);
      if (carregamentoInicial) {
        handleListarColaborador(
          ordemSelecionada,
          filtroSelecionado,
          1,
          empresaSelecionada
        );
      }
    } else montouComponente.current = true;
  }, [empresaSelecionada]);

  useEffect(() => {
    if (textoPesquisa === "" && carregamentoInicial) {
      const debounce = setTimeout(() => {
        handleListarColaborador(
          ordemSelecionada,
          filtroSelecionado,
          1,
          empresaSelecionada
        );
      }, 1000);
      return () => clearTimeout(debounce);
    }
  }, [textoPesquisa]);

  useEffect(() => {
    if (campoPesquisa === "Todos" && carregamentoInicial) {
      setValue("textoBusca", null);
      const debounce = setTimeout(() => {
        handleListarColaborador(
          ordemSelecionada,
          filtroSelecionado,
          1,
          empresaSelecionada
        );
      }, 1000);
      return () => clearTimeout(debounce);
    }
  }, [campoPesquisa]);

  useEffect(() => {
    store.dispatch(desabilitarEmpresa(false));
    store.dispatch(desabilitarCliente(true));
    store.dispatch(desabilitarUnidade(true));

    return () => {
      store.dispatch(desabilitarCliente(false));
      store.dispatch(desabilitarUnidade(false));
    };
  }, [desabilitarEmpresa, desabilitarCliente, desabilitarUnidade]);

  const onClickNovoColaborador = () => {
    history.push(`${RotasDTO.Colaboradores}/cadastro`);
  };

  const onClickEditar = (id) => {
    history.push(`${RotasDTO.Colaboradores}/cadastro/${id}`);
  };

  const [modalExclusao, setModalExclusao] = useState(false);

  const deletar = async (item) => {
    try {
      setModalExclusao(false);
      setCarregandoColaborador(true);
      const deletou = await deletarColaborador(
        item?.id,
        item?.situacao === "AT" ? "IN" : "AT"
      );
      if (deletou) {
        handleListarColaborador(
          ordemSelecionada,
          filtroSelecionado,
          1,
          empresaSelecionada
        );
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: `O colaborador foi ${
              item?.situacao === "IN" ? "ativado" : "inativado"
            } com sucesso!`
          })
        );
      }
      setCarregandoColaborador(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoColaborador(false);
    }
  };

  const onClickExcluir = (item) => {
    setModalExclusao(item);
  };

  const onConfirmarExclusao = (item) => {
    try {
      deletar(item);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  const [ordemSelecionada, setOrdemSelecionada] = useState("");
  const [filtroSelecionado, setFiltroSelecionado] = useState("");

  const handleClickOrdemSelecionada = (event) => {
    const val = ordemSelecionada === event ? "" : event;
    setOrdemSelecionada(val);
  };

  const handleClickFiltroSelecionado = (event) => {
    const val = filtroSelecionado === event ? "" : event;
    setFiltroSelecionado(val);
  };

  const handleClickAtivarAgora = async () => {
    const filtro = [];
    if (filtroSelecionado) filtro.push(filtroSelecionado);
    handleListarColaborador(ordemSelecionada, filtro, 1, empresaSelecionada);
  };

  const proximaPagina = "Go to next page";
  const paginaAnterior = "Go to previous page";

  const onChangePaginacao = (item) => {
    if (item.innerText !== "") {
      const pagina = parseInt(item.innerText, 10);
      handleListarColaborador(
        ordemSelecionada,
        filtroSelecionado,
        pagina,
        empresaSelecionada
      );
    }

    if (item.innerText === "") {
      if (item.ariaLabel === proximaPagina) {
        setPaginaAtual(paginaAtual + 1);
        handleListarColaborador(
          ordemSelecionada,
          filtroSelecionado,
          paginaAtual + 1,
          empresaSelecionada
        );
      }

      if (item.ariaLabel === paginaAnterior) {
        setPaginaAtual(paginaAtual - 1);
        handleListarColaborador(
          ordemSelecionada,
          filtroSelecionado,
          paginaAtual - 1,
          empresaSelecionada
        );
      }
    }
  };

  const onClickSearch = () => {
    handleListarColaborador(
      ordemSelecionada,
      filtroSelecionado,
      1,
      empresaSelecionada
    );
  };

  return (
    <Loader loading={carregandoColaborador} className="w-auto">
      <ModalConfirmacao
        item={modalExclusao}
        onConfirmar={(item) => onConfirmarExclusao(item)}
        mensagem={`Tem certeza que deseja ${
          modalExclusao?.situacao === "AT" ? "inativar" : "ativar"
        } esse Colaborador?`}
        onCancelar={() => setModalExclusao(false)}
      />
      <Grid
        container
        p={2}
        spacing={3}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <SelectArredondado
            select
            id="CampoPesquisa"
            name="CampoPesquisa"
            label="Filtrar por campo"
            placeholder="Filtrar por campo"
            valueKey="valor"
            valueName="nome"
            className={classes.select}
            dataSource={[
              { nome: "Todos", valor: "Todos" },
              { nome: "Nome Colaborador", valor: "Nome" }
            ]}
            value={campoPesquisa}
            onChange={(e) => {
              setCampoPesquisa(e.target.value);
            }}
            permiteValorBranco
            styleForm={false}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <MaterialInputBusca
            type="text"
            id="textoBusca"
            name="textoBusca"
            label="Buscar"
            renderIconShowHide
            searchAdornment
            permiteValorBranco
            defaultValue={textoBusca ?? ""}
            ref={register}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={2}>
          <Botao
            type="submit"
            onClick={onClickSearch}
            label="Buscar"
            className={classes.button}
            disabled={!textoPesquisa}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={3}
          display="flex"
          justifyContent={{ sm: "flex-end" }}
        >
          <BotaoFiltroOrdenacao
            type="button"
            color={theme.color.secondaryBorderColor}
            background="transparent"
            label="Filtrar / Ordernar"
            icon={<TuneIcon />}
            className={classes.buttonFiltro}
            ordenacao={listarOrganizarPor()}
            ordenadorUm="Ordenador"
            ordenadorDois="Filtrar"
            ordemSelecionada={ordemSelecionada}
            filtros={listarFiltrarPor()}
            filtrosSelecionados={filtroSelecionado}
            onClickOrdenacao={handleClickOrdemSelecionada}
            onClickFiltro={handleClickFiltroSelecionado}
            onClickAtivarAgora={handleClickAtivarAgora}
          />
        </Grid>
        <Grid item xs={12}>
          <BotaoCadastro
            label="Novo Colaborador"
            color={theme.color.buttonColor}
            onClick={onClickNovoColaborador}
          />
        </Grid>
        {listaColaborador && listaColaborador?.length ? (
          <>
            {listaColaborador.map((colaborador) => {
              return (
                <ItemListaExpansivel
                  key={colaborador?.id}
                  label={colaborador?.nomeColaborador ?? "Colaborador"}
                  color={Base.White}
                  edicao={!!colaborador?.id}
                  exclusao={!!colaborador?.id}
                  onClickEditar={() => onClickEditar(colaborador?.id)}
                  onClickExcluir={() => onClickExcluir(colaborador ?? false)}
                  ativo={Boolean(colaborador?.situacao.toUpperCase() === "AT")}
                >
                  <div>
                    <strong>Area: </strong>
                    {colaborador?.areaFuncionalDescricao ?? ""}
                  </div>
                  <div>
                    <strong>Cargo: </strong>
                    {colaborador?.cargoDescricao ?? ""}
                  </div>
                </ItemListaExpansivel>
              );
            })}
            <Grid
              container
              spacing={3}
              direction="row"
              justifyContent="center"
              alignItems="center"
              mt={2}
            >
              <Grid item>
                <Paginacao
                  paginaAtual={paginaAtual}
                  totalPaginas={totalPaginas}
                  onChangePagina={onChangePaginacao}
                />
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid
            container
            spacing={3}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <TabelaSemDados className={classes.container} />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Loader>
  );
};

export default ColaboradorListar;
