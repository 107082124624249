import moment from "moment";
import React from "react";

import { Box } from "@mui/material";
import IconData from "../../../../assets/consumoEnergia/icons-data.png";
import { Mes, useStyles, Wrapper } from "./style";

const Data = () => {
  const classes = useStyles();

  return (
    <Wrapper className={classes.wrapper}>
      <Mes>
        {`${moment().format("D MMM")}`}
        &nbsp;
      </Mes>
      {moment().format("YYYY, dddd")}
      <Box component="img" src={IconData} alt="icons-data" ml={2} />
    </Wrapper>
  );
};

export default Data;
