import React from "react";
import PropTypes from "prop-types";
import { Grid, Tooltip } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import HelpIcon from "@mui/icons-material/Help";
import { useStyles } from "./style";

const InputRadio = React.forwardRef((props, ref) => {
  const {
    label,
    id,
    name,
    defaultValue,
    errors,
    onChange,
    value,
    valueSecondary,
    customValue,
    helperLabel,
    ariaLabel,
    text,
    className,
    disabled,
    textSecondary
  } = props;

  const classes = useStyles();

  return (
    <Grid container spacing={0} alignItems="flex-end">
      <Grid item lg={helperLabel ? 11 : 12} xs={helperLabel ? 11 : 12}>
        <FormControl variant="standard" component="fieldset">
          <FormLabel component="legend">{label}</FormLabel>
          <RadioGroup
            aria-label={ariaLabel}
            name={name}
            value={customValue}
            onChange={(valor) => onChange(valor)}
            id={id}
            inputRef={ref}
            error={Boolean(errors[name]?.message)}
            helperText={errors[name]?.message ?? ""}
            defaultValue={defaultValue}
            classes={{ root: className ?? classes.root }}
          >
            <Grid container spacing={0} alignItems="center">
              <Grid item>
                <FormControlLabel
                  value={value}
                  checked={String(customValue) === String(value)}
                  control={<Radio />}
                  label={text}
                  style={{ display: "inline-block" }}
                  disabled={disabled}
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  value={valueSecondary}
                  checked={String(customValue) === String(valueSecondary)}
                  control={<Radio />}
                  label={textSecondary}
                  style={{ display: "inline-block" }}
                />
              </Grid>
            </Grid>
          </RadioGroup>
        </FormControl>
      </Grid>
      {helperLabel ? (
        <Grid item lg={1}>
          <Tooltip title={helperLabel} aria-label={helperLabel}>
            <HelpIcon />
          </Tooltip>
        </Grid>
      ) : null}
    </Grid>
  );
});

InputRadio.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  errors: PropTypes.oneOfType([PropTypes.object]),
  onChange: PropTypes.oneOfType([PropTypes.func]),
  helperLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  value: PropTypes.string,
  valueSecondary: PropTypes.string,
  ariaLabel: PropTypes.string,
  customValue: PropTypes.Boolean,
  text: PropTypes.string,
  className: PropTypes.oneOfType([PropTypes.any]),
  textSecondary: PropTypes.string,
  disabled: PropTypes.Boolean
};

InputRadio.defaultProps = {
  label: "",
  id: "",
  defaultValue: "",
  errors: {},
  onChange: () => {},
  helperLabel: "",
  value: "",
  valueSecondary: "",
  ariaLabel: "",
  customValue: false,
  text: "",
  className: "",
  textSecondary: "",
  disabled: false
};

export default InputRadio;
