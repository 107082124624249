export default class FiltroDto {
  constructor(
    tamanhoPaginacao,
    pagina,
    ordernar,
    fitro,
    tipoEmpresa,
    empresaSelecionada = 0,
    produto = 0
  ) {
    this.tamanhoPaginacao = tamanhoPaginacao;
    this.pagina = pagina;
    this.SortOrder = ordernar;
    this.Filtro = fitro;
    this.TipoEmpresa = tipoEmpresa;
    this.idEmpresa = empresaSelecionada;
    this.idProduto = produto;    
  }
}
