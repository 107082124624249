import MICROSERVICO from "global/enumeradores/microServico";
import obterApi from "servicos/api";

const api = () => obterApi(MICROSERVICO.PROJECAOTARIFARIA);

export const importarComValidacao = async (idUsuario, nomeUsuario, keyFileS3) => {
  const metodo = "post";
  const url = `v1/ImportOrcamentoCDE/importarComValidacao?idUsuario=${idUsuario}&nomeUsuario=${nomeUsuario}&keyFileS3=${keyFileS3}`;
  return api()[metodo](url);
};

export const importar = async (idUsuario, nomeUsuario,idCenario, keyFileS3) => {
  const metodo = "post";
  const url = `v1/ImportOrcamentoCDE/importar?idUsuario=${idUsuario}&nomeUsuario=${nomeUsuario}&idCenario=${idCenario}&keyFileS3=${keyFileS3}`;
  return api()[metodo](url);
};

export const obterValoresOrcamento = async (inicio, fim) => {
  return api().get(`v1/ImportOrcamentoCDE/getbyperiod?anoinicial=${inicio}&anofinal=${inicio}`);
}

export const importarCDEEnergia = async (arquivo, anoBase, dispositivo, qtdParcelas, idUsuario, nomeUsuario) => {
  const formData = new FormData();
  formData.append("file", arquivo.file);

  const metodo = "post";
  const url = `v1/ImportResCDEEnergia/importa?ano=${anoBase}&dispositivo=${dispositivo}&parcelas=${qtdParcelas}&idUsuario=${idUsuario}&nomeUsuario=${nomeUsuario}`;
  return api()[metodo](url, formData);
};

export const valoresCDEEnergia = async (anoBase) => {
  return api().get(`v1/ImportResCDEEnergia/visualiza?anobase=${anoBase}`);
}

export const valoresDispositivos = async () => {
  return api().get(`v1/ImportResCDEEnergia/visualizardispositivos`);
}

export const importarCDEPagamentoMensal = async (idUsuario, nomeUsuario, keyFileS3) => {
  const metodo = "post";
  const url = `v1/CDEPagamentoMensal/importa?idUsuario=${idUsuario}&NomeUsuario=${nomeUsuario}&keyFileS3=${keyFileS3}`;
  return api()[metodo](url);
}

export const getCDEPagamentoMensal = async (idDistribuidora, dataInicial, dataFinal) => {   
  const result = await api().get(`v1/CDEPagamentoMensal/visualizar?idDistribuidora=${idDistribuidora}&DataInicial=${dataInicial}&DataFinal=${dataFinal}`);
  return result;
}

export const importarFatorMercadoNivel = async (idUsuario, nomeUsuario, idCenario, arquivo) => {

  console.log(`idCenario: ${idCenario}`);

  const formData = new FormData();
  formData.append("file", arquivo.file);

  const metodo = "post";
  const url = `v1/FatorMercadoNivel/importar?idUsuario=${idUsuario}&nomeUsuario=${nomeUsuario}&idCenario=${idCenario}`;
  return api()[metodo](url, formData);
};

export const visualizarParametrosMercado = async (ano) => {
  const url = `v1/FatorMercadoNivel/visualizar?ano=${ano}`;

  return api().get(url)
};

export const importarMercadoReferencia = async (idUsuario, nomeUsuario, idCenario, arquivo) => {

  console.log(`idCenario: ${idCenario}`);

  const formData = new FormData();
  formData.append("file", arquivo.file);

  const metodo = "post";
  const url = `v1/MercadoReferenciaCDE/importar?idUsuario=${idUsuario}&nomeUsuario=${nomeUsuario}&idCenario=${idCenario}`;
  return api()[metodo](url, formData);
};

export const visualizarMercadoReferenciaCDE = async (ano) => {
  const url = `v1/MercadoReferenciaCDE/visualizar?ano=${ano}`;

  return api().get(url)
}

export const importarRateioCDEDivulgado = async (idUsuario, nomeUsuario, ano, arquivo) => {

  const formData = new FormData();

  formData.append("file", arquivo.file);

  const metodo = "post";
  
  const url = `v1/RateioCDEDivulgado/importar?idUsuario=${idUsuario}&nomeUsuario=${nomeUsuario}&ano=${ano}`;
  
  return api()[metodo](url, formData);
};

export const visualizarRateioCDEDivulgado = async (ano) => {
  const url = `v1/RateioCDEDivulgado/visualizar?ano=${ano}`;

  return api().get(url)
}