import FileSaver from "file-saver";
import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const api = () => obterApi(MICROSERVICO.MAQUINACALCULO);

export const obtersimulacoes = (filtro) => {
  const filtroQueryString = new URLSearchParams(filtro).toString();
  return api().get(`/maquinadecalculo/lista-simulacoes?${filtroQueryString}`);
};

export const downloadSimulacao = async (id) => {
  const response = await api().get(
    `/maquinadecalculo/consulta-calculeira-por-id?id=${id}`,
    {
      headers: {
        "Content-Disposition": "attachment;",
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      },
      responseType: "arraybuffer"
    }
  );

  const blob = new Blob([response.data], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  });

  FileSaver.saveAs(blob, `Simulacao-${id}`);
};
