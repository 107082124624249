/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-console */
/* eslint-disable eqeqeq */
/* eslint-disable radix */
/* eslint-disable import/no-unresolved */

import React, { useState, useEffect, useCallback } from "react";
import { Card, Grid, Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import moment from "moment";
import Select from "@mui/material/Select";
import { useParams, useHistory } from "react-router-dom";
import Loader from "componentes/loader";
import { useForm } from "react-hook-form";
import BotaoRetornarListagem from "componentes/botaoRetornarListagem";
import MaterialInputTexto from "componentes/inputTexto/materialInput";
import Botao from "componentes/botao";
import { store } from "global/redux";
import { useSelector } from "react-redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import MaterialInputMascara from "componentes/inputTextoMascara";
import RotasDTO from "../../../rotas/rotasUrlDto";
import { listarSerieHistorica, obterFatorComposto, salvarFatorComposto } from "../../../servicos/fatorComposto";
import { useStyles } from "./style";

const ProjecaoFatorCompostoEditar = () => {
  const { id } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const usuarioGlobal = useSelector((state) => state.usuario);

  const { register, errors, handleSubmit } = useForm({
    reValidateMode: "onSubmit",
  });

  const [descricao, setDescricao] = useState("");
  const [operacao, setOperacao] = useState("");
  const [seriesHistoricas, setSeriesHistoricas] = useState([]);
  const [series, setSeries] = useState([]);
  const [fatorComposto, setFatorComposto] = useState({});
  const [buscouFator, setBuscouFator] = useState(false);
  const [carregando, setCarregando] = useState(false);

  const obterFator = async () => {
    try {
      setBuscouFator(true);
      await obterFatorComposto().then(data => {
        data.data.forEach(element => {
          if (element.Codigo == id) {
            
            setFatorComposto(element);
            setSeries(element.SerieHistorica);
            setOperacao(element.Operacao);
            setDescricao(element.Descricao);
          }
        })
      });
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (!buscouFator) obterFator();
  }, [!buscouFator, obterFator]);


  const enviarFormulario = async () => {
    const dados = {
      Codigo: id,
      Operacao: operacao,
      Descricao: descricao,
      IdUsuario: usuarioGlobal?.usuario?.id,
      NomeUsuario: usuarioGlobal?.usuario?.usuarioNome,
      AtualizadoEm: moment().format(),
      SerieHistorica: series.map(element => (
        {
          CodigoFator: id,
          CodigoSerieHistorica: element.CodigoSerieHistorica,
          Peso: parseFloat(recuperarPontoDecimal(element.Peso))
        }
      ))
    }

    try {
      const salvou = await salvarFatorComposto(dados);

      if (salvou?.status === 200 || salvou?.status === 204) {
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: "Fator composto gravado com sucesso."
          })
        );
  
        setTimeout(() => {
          history.push(RotasDTO.ProjecaoFatorComposto);
        }, 2000);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  const obterSeriesHistoricas = useCallback(async () => {
    try {
      const response = await listarSerieHistorica();
      if (response?.status === 200 && response?.data) {
        setSeriesHistoricas(response?.data);
      }
    } catch (error) {
      console.info(error);
    }
  }, []);

  useEffect(() => {
    if (!seriesHistoricas.length) obterSeriesHistoricas();
  }, [!seriesHistoricas.length, obterSeriesHistoricas]);

  const addSerie = (newValue) => setSeries((state) => [...state, newValue]);

  const changeSelect = (event) => {
    setSeries(
      series.map((item) => {
        if (parseInt(item.id_serie) === parseInt(event.currentTarget.dataset.id)) {
          return { ...item, codigo: event.target.value, CodigoSerieHistorica: event.target.value, apelido: event.currentTarget.dataset.apelido, fonte: event.currentTarget.dataset.fonte, periodicidade: event.currentTarget.dataset.periodicidade, categoria: event.currentTarget.dataset.categoria };
        }
        return item;
      })
    );
  };

  const changeInput = (event) => {
    setSeries(
      series.map((item) => {
        if (item.CodigoSerieHistorica === event.target.id) {
          return { ...item, Peso: recuperaValorFloat(event.target.value)};
        }
        return item;

      })
    );
  };

  const recuperaValorFloat = ((sequencia) => {
    return (parseFloat(String(sequencia).replace("%", "").replace(",",".")) / 100).toFixed(4);
  });

  const recuperarPontoDecimal = ((sequencia) => {
    return String(sequencia).replace(",",".");
  });  

  const removeSeries = (valueRemove) => {
    const newListSeries = series?.filter(item => item !== valueRemove)
    setSeries(newListSeries);
  }

  const handleChangeOperacao = (event) => {
    setOperacao(event.target.value);
  };

  const aoEnviarFormulario = () => {
    let sum = 0.0;
    series.map((item) => sum += parseFloat(recuperarPontoDecimal(item.Peso)));
    if(parseFloat(sum.toFixed(2)) != 1.0)
    {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "A soma dos pesos das séries não está totalizando 100.00"
        })
      );
      return;
    }
    let periodicidade = "";
    let valid = true;
    
    setCarregando(true);
    series.forEach(element => {
      seriesHistoricas.forEach(item => {
        if (element.CodigoSerieHistorica === item.codigo)
          if (periodicidade === "") {
            periodicidade = item.periodicidade;
          }
          else if (item.periodicidade !== periodicidade)
            valid = false;
      })
    })

    if (valid) {
      enviarFormulario();
      setCarregando(false);
    }
    else {
      setCarregando(false);
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "A periodicidade das séries históricas devem ser todas semelhantes."
        })
      );
    }
  }


  return <>
    <form className="needs-validation" onSubmit={handleSubmit(aoEnviarFormulario)}>
      <Loader loading={carregando} className='w-auto'>
        <Card className={classes.container}>
          <Grid container sm={12} spacing={4} className={classes.container} px={10} py={2}>
            <Grid item sm={11}>
              <Typography style={{ fontWeight: "bold" }}>
                Editar {id}
              </Typography>
            </Grid>
            <Grid item sm={1} justifyContent="flex-end">
              <BotaoRetornarListagem
                urlListagem={RotasDTO.ProjecaoFatorComposto}
              />
            </Grid>
          </Grid>
          <Grid container sm={12} spacing={4} className={classes.container} px={10} py={2}>
            <Grid item sm={7}>
              <MaterialInputTexto
                type="text"
                id="descricaoSerieHistorica"
                name="descricao"
                label="Descrição"
                renderIconShowHide={false}
                defaultValue={descricao}
                ref={register}
                errors={errors}
                onInput={(e) => {
                  setDescricao(e.target.value);
                }}
              />
            </Grid>
            <Grid item sm={5}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="perid">Operação</InputLabel>
                <Select
                  variant="outlined"
                  labelId="perid"
                  id="perid1"
                  value={operacao ?? ""}
                  onChange={handleChangeOperacao}
                  label="Operação"
                  fullWidth>
                  {["Somatório", "Produtório"].map((prdcdd) => (
                    <MenuItem value={prdcdd}>{prdcdd}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={4} className={classes.container} px={8}>
            <Grid item sm={12}>
              <Grid container p={2} spacing={2} className={classes.container}>
                <Grid container p={2} spacing={2} className={classes.container}>
                  <Grid item sm={7} justifyContent="flex-start">
                    <b>Série Histórica </b>
                  </Grid>
                  <Grid item sm={2} justifyContent="flex-start">
                    <b>Peso</b>
                  </Grid>
                  <Grid item sm={3} justifyContent="flex-start">
                    <button
                      type="button"
                      className={classes.botao}
                      onClick={() => {
                        addSerie({
                          id_serie: Math.floor(Math.random() * 6) + 1,
                          id: Math.floor(Math.random() * 6) + 1,
                          codigo: "",
                          apelido: "",
                          fonte: "",
                          periodicidade: "",
                          categoria: "",
                          Peso: 0
                        });
                      }}
                    >
                      + Série Histórica
                    </button>
                  </Grid>
                </Grid>
                {series?.map(serie => 
                (
                  <Grid container p={2} spacing={2} className={classes.container}>
                    <Grid item sm={7}>
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="seriesHistoricas-label">Nome da Série Histórica</InputLabel>
                        <Select
                          variant="outlined"
                          labelId="seriesHistoricas-label"
                          label="Série Histórica "
                          onChange={changeSelect}
                          value={serie.CodigoSerieHistorica}
                          style={{ textAlign: "center" }}
                          fullWidth>
                          {seriesHistoricas.map((item) => (
                            <MenuItem
                              key={item.codigo}
                              data-id={serie.id_serie}
                              data-apelido={item.apelido}
                              data-fonte={item.fonte}
                              data-periodicidade={item.periodicidade === "M" ? "Mensal" : "Anual"}
                              data-categoria={item.idCategoria}
                              value={item.codigo}
                            >
                              {item.codigo}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item sm={2}>
                      <MaterialInputMascara
                        type="text"
                        sufixo="%"
                        id={serie.CodigoSerieHistorica}
                        name="Peso"
                        defaultValue={serie.Peso ? (serie.Peso * 100).toFixed(2) : 0}
                        onBlur={changeInput}
                        renderIconShowHide={false}
                        style={{ textAlignLast: "center" }}
                        ref={register}
                      />
                    </Grid>
                    <Grid item sm={2}>
                      <button
                        type="button"
                        className={classes.botao}
                        onClick={() => {
                          removeSeries(serie);
                        }}
                      >
                        X
                      </button>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
          <Grid container sm={11} spacing={4} className={classes.container} px={10} py={2}>
            <Grid item lg={8} md={7} sm={0} />
            <Grid item xs={4}>
              <Botao type="submit" label="Salvar" className={classes.button} />
            </Grid>
          </Grid>
        </Card>
      </Loader>
    </form>
  </>;
};

export default ProjecaoFatorCompostoEditar;
