import { makeStyles } from "@mui/styles";
import { Base } from "componentes/cores";
import theme from "themes";

export const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: Base.White,
    position: "relative",
    width: "1020px",
    height: "746px",
    display: "flex"
  },
  boxEsquerda: {
    position: "relative",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "30px 30px 30px 0",
    width: "640px"
  },
  boxTexto: {
    position: "relative",
    paddingLeft: "40px",

    "& h1": {
      color: theme.color.primary,
      fontSize: "60px",
      fontFamily: `${theme.font.family}  !important`,
      fontWeight: 700,
      marginBottom: "32px"
    },
    "& h3": {
      color: theme.color.primary,
      fontSize: "21px",
      fontFamily: `${theme.font.family}  !important`,
      marginBottom: 0,

      "& span": {
        color: theme.color.secondary
      }
    }
  },
  boxDireita: {
    position: "relative",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "30px 30px 30px 0",
    width: "480px"
  },
  iconeLista: {
    left: "0",
    top: "10px",
    width: "25px",
    position: "absolute"
  },
  iconeLuz: {
    position: "absolute",
    right: 20,
    bottom: 20,
    width: "60px"
  }
}));
