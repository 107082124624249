import styled from "styled-components";
import theme from "../../../../themes";

export const Button = styled.button`
  background-color: ${(props) =>
    props.loginLuz ? theme.color.primaryOpacity :
      !props.stateless
        ? props.selected
          ? theme.colorbtOrdBGColor
          : theme.color.btSelBGColor
        : theme.color.btOrdBGColor};
  border-color: ${(props) =>
    !props.stateless
      ? props.selected
        ? theme.color.btOrdBGColor
        : theme.color.btOrdBorderColor
      : theme.color.btOrdBGColor};
  color: ${(props) =>
    props.selected ? theme.color.btTextColor : theme.color.btSelTextColor} !important;
  outline: none;
  width: 100%;
`;
