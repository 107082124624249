import RetornoEndpointDto from "../../../global/dto/retornoEndpointDto";
import { store } from "../../../global/redux";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";
import { salvarUsuarioCri } from "../../../servicos/criServico";

export default class UsuarioCriHelper {
  static async salvarUsuarioCri(body, setCarregando) {
    try {
      setCarregando(true);
      const resultado = await salvarUsuarioCri(body);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Usuário cri criado não encontrado!");

      return RetornoEndpointDto.Sucesso(
        "Usuário cri criado com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Erro ao cadastrar usuário!");
    } finally {
      setCarregando(false);
    }
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }

  static exibirSucesso(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem
      })
    );
  }
}
