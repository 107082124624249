/* eslint-disable import/no-unresolved */
import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Grid } from "@mui/material";

import DataTable from "react-data-table-component";
import Coluna from "componentes/tabelaPaginada/colunas/coluna";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import Loader from "componentes/loader";
import ColunaComponentePersonalizado from "componentes/tabelaPaginada/colunas/colunaComponentePersonalizado";
import { obterProjecaoBrasilLista } from "../../servicos/projecaoBrasil";
import enumeradorBotao from "./enumeradores/enumeradorBotao";
import { useStyles, customStyles, conditionalRowStyles } from "./style";
import RotasDTO from "../../rotas/rotasUrlDto";
import ColunaMultiplosBotoes from "./colunaCustomizada";
import ParametrosProjecaoBrasil from "./parametros"

const ProjecaoBrasilListar = () => {
  const history = useHistory();
  const classes = useStyles();

  const [atual, setAtual] = useState();
  const [carregandoDados, setCarregandoDados] = useState(false);
  const [itemProjecaoBrasil, setItemProjecaoBrasil] = useState();
  const [projetar, setProjetar] = useState(false);
  const [ativaAno, setAtivaAno] = useState(false);
  const [ativaCenario, setAtivaCenario] = useState(false);
  const [ativaTextoProinfa, setAtivaTextoProinfa] = useState(false);


  const obterInsumos = async () => {
    setCarregandoDados(true);
    try {
      const lista = await obterProjecaoBrasilLista();
      if (lista?.data !== null) {
        const response = lista?.data;
        const organizado = [];
        if (response) {
          response.map((item) => organizado.push({
            ...item,
            id: item.codigo,
            dataUltimaProjecao: item.dataHora != null ? window.moment(item.dataHora).format("DD-MM-YYYY HH:mm:ss") : ""
          }));
        }
        setAtual(organizado);
      }
      setCarregandoDados(false);
    } catch (error) {
      setCarregandoDados(false);
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem:
            error?.response?.data?.message ?? error?.message ?? "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  const onClickProjetar = (codigo) => {
    debugger
    const itemProjecao = atual.filter(it => it.codigo === codigo)[0];
    if (itemProjecao?.usaParametro) {
      /*  if (itemProjecao?.id === "EER_GERAL" || itemProjecao?.id == "MERCADO_BRASIL" || itemProjecao?.id === "ESS_GERAL" || itemProjecao?.id === "CDE_GERAL"
         || itemProjecao?.id === "PROINFA") { */
      setAtivaCenario(true);
      //}
      if (itemProjecao?.id === "ESS_GERAL" || itemProjecao?.id === "PROINFA") {
        setAtivaAno(true);
      }
      if (itemProjecao?.id === "PROINFA") setAtivaTextoProinfa(true);


    }
    setItemProjecaoBrasil(itemProjecao);
    setProjetar(true);
  };

  const onClickHistorico = (idProjecaoBrasil, titulo) => {
    history.push(`${RotasDTO.ProjecaoBrasilListar}/historico-projecoes/${idProjecaoBrasil}/${titulo}`);
  };

  const onClickVisualizar = (idProjecaoBrasil, titulo, comandoVisualizar) => {
    if (comandoVisualizar !== null) {
      if (idProjecaoBrasil === "MERCADO_BRASIL" || idProjecaoBrasil === "TARIFA_ANGRA" || idProjecaoBrasil === "TARIFA_CGF") {
        history.push(`${RotasDTO.SeriesHistoricas}${comandoVisualizar}`);
      } else {
        history.push(`${RotasDTO.ProjecaoBrasilListar}${comandoVisualizar}`);
      }
    }
    else {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: `Função ainda não desenvolvida para a projeção ${titulo} - ${idProjecaoBrasil}`
        })
      );
    }
  }

  const onClickExportar = (idProjecaoBrasil, titulo, comandoExportar) => {
    if (comandoExportar !== null) {
      history.push(`${RotasDTO.ProjecaoBrasilListar}${comandoExportar}`);
    }
    else {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: `Função ainda não desenvolvida para a projeção ${titulo} - ${idProjecaoBrasil}`
        })
      );
    }
  }

  const onClickBotao = (tipo, parametros) => {
    switch (tipo) {
      case enumeradorBotao.Projetar:
        onClickProjetar(parametros.id);
        break;
      case enumeradorBotao.Historico:
        onClickHistorico(parametros.id, parametros.titulo);
        break;
      case enumeradorBotao.Visualizar:
        onClickVisualizar(parametros.id, parametros.titulo, parametros.comandoVisualizar);
        break;
      case enumeradorBotao.Exportar:
        onClickExportar(parametros.id, parametros.titulo, parametros.comandoExportar);
        break;

      default:
        break;
    }
  };

  const colunas = [
    new Coluna("titulo", "Título", true, "400px", "400px"),
    new Coluna("dataUltimaProjecao", "Última Projeção", true, "250px", "250px"),
    new Coluna("nomeUsuario", "Usuário", true),
    new ColunaComponentePersonalizado(
      "opcoes",
      "Ações",
      ColunaMultiplosBotoes,
      onClickBotao
    )
  ];

  useEffect(() => {
    obterInsumos();
  }, []);

  const onCancelar = () => {
    setItemProjecaoBrasil(null);
    setProjetar(false);
    setAtivaAno(false);
    setAtivaCenario(false);
  }

  return (
    <>
      <Loader loading={carregandoDados} className="w-auto">
        <ParametrosProjecaoBrasil
          aberto={projetar}
          itemProjecao={itemProjecaoBrasil}
          onCancelar={() => onCancelar()}
          ativaCenario={ativaCenario}
          ativaAno={ativaAno}
          ativaTextoProinfa={ativaTextoProinfa}
        />
        <div className={classes.root} style={projetar ? { display: "none" } : {}}>
          <Grid container mt={6}>
            {atual?.length > 0 ?
              <DataTable
                columns={colunas}
                data={atual}
                theme="DeltaEnergia"
                customStyles={customStyles}
                conditionalRowStyles={conditionalRowStyles}
                noDataComponent="Nenhum registro encontrado"
                noHeader
              />
              :
              ""
            }
          </Grid>
        </div>
      </Loader>
    </>
  )
}

export default ProjecaoBrasilListar;