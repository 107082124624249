/* eslint-disable import/no-unresolved */
import MICROSERVICO from "global/enumeradores/microServico";
import obterApi from "servicos/api";

const api = () => obterApi(MICROSERVICO.PROJECAOTARIFARIA);

export const add = async (data) => {
    const metodo = "post";
    const url = "v1/CompositeConfigProjecaoMerc/add";
    return api()[metodo](url, data);
};

export const edit = async (data) => {
    const metodo = "put";
    const url = "v1/CompositeConfigProjecaoMerc/update";
    return api()[metodo](url, data);
};

export const excluir = async (id) => {
    const metodo = "delete";
    const url = `v1/CompositeConfigProjecaoMerc/delete?id=${id}`;
    return api()[metodo](url);
}

export const getPadrao = async () => {
    const metodo = "get";
    const url = "v1/CompositeConfigProjecaoMerc/getpadrao";
    return api()[metodo](url);
}

export const getDistribuidora = async () => {
    const metodo = "get";
    const url = "v1/CompositeConfigProjecaoMerc/getdistribuidorasconfigtusdte";
    return api()[metodo](url);
}

export const getAnosAtivos = async (id) => {
    const metodo = "get";
    const url = `v1/CompositeConfigProjecaoMerc/getanosativos?iddistribuidora=${id}`;
    return api()[metodo](url);
}

export const getValoresMercadoFaturado = async (id, ano) => {
    const metodo = "get";
    const url = `v1/ImportPCAT/getmercadofaturado?IdDistribuidora=${id}&AnoTarifario=${ano}`
    return api()[metodo](url);
}

export const getAllDistribuidoras = async () => {
    const metodo = "get";
    const url = "v1/ParametroDistribuidora/getalljoin?StatusProjecaoTarifaria=true"
    return api()[metodo](url);
}

export const getAnosDistribuidora = async (id) => {
    const metodo = "get";
    const url = `v1/CompositeConfigProjecaoMerc/getanosativos?iddistribuidora=${id}`;
    return api()[metodo](url);
}

export const getConfigDistribuidora = async (id) => {
    const metodo = "get";
    const url = `v1/CompositeConfigProjecaoMerc/getconfigdistribuidora?id=${id}`;
    return api()[metodo](url);
}

export const updateConfigAtivo = async (id, ativo) => {
    const metodo = "put";
    const url = `v1/CompositeConfigProjecaoMerc/updateconfigativo?idDistribuidora=${id}&Ativo=${ativo}`;
    return api()[metodo](url);
}