import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Card, Grid } from '@mui/material';
import { useStyles } from './style';
import { Base } from 'componentes/cores';
import BotaoRetornarListagem from 'componentes/botaoRetornarListagem';
import Loader from 'componentes/loader';
import UploadDropArea from 'componentes/upload';
import Botao from 'componentes/botao';
import ValidacaoDominios from 'componentes/validacaoDominios';
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { importarTermicasComValidacao, importarTermicasSemValidacao } from '../../../servicos/termicas';
import RotasDTO from '../../../rotas/rotasUrlDto';
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { useHistory } from "react-router-dom";

const TermicasImportar = () => {
  const classes = useStyles();
  const usuarioGlobal = useSelector((state) => state.usuario);
  const history = useHistory();

  const onFechar = (id) => {
    history.push(`${RotasDTO.ProjecaoInsumoListar}`);
  }
  const msgPadraoUpload = "Solte o arquivo para anexá-lo ou procurar";

  const { handleSubmit } = useForm({
    reValidateMode: 'onSubmit',
  });

  const [file, setFile] = useState(false);
  const [fileName, setFileName] = useState(msgPadraoUpload);
  const [carregandoArquivo, setCarregandoArquivo] = useState(false);
  const [progressMessage, setProgressMessage] = useState("");

  const [validacaoDominio, setValidacaoDominio] = useState(false);
  const [validacaoDominioCabecalho, setValidacaoDominioCabecalho] = useState("");
  const [validacaoDominiosObj, setValidacaoDominiosObj] = useState({
    "contexto": "",
    "entidades": []
  });

  const processarArquivo = (arquivo) => {
    if (arquivo != null && arquivo[0] != null) {
      setFile(arquivo[0]);
      setFileName(arquivo[0].file.name);
    }
  };

  const onAddUpload = (arquivo) => {
    processarArquivo(arquivo)
  };

  const importarSemValidacao = async (data) => {
    if(data && data.totalDesmarcado === 0 && data.responseInsert.totalInserido === data.responseInsert.totalValores){
      if(file && data){
        setCarregandoArquivo(true);
        setProgressMessage("Importando o arquivo... Por favor, aguarde.");
        try {
          const response = await importarTermicasSemValidacao(file, usuarioGlobal?.usuario?.id, usuarioGlobal?.usuario?.usuarioNome);
          if (response?.status === 200 && response?.data) {
            setFile(false);
            setFileName(msgPadraoUpload);
            setCarregandoArquivo(false);
            setValidacaoDominio(false);
            store.dispatch(
              alertaExibir({
                tipo: "success",
                mensagem: `Arquivo ${file.file.name} importado com sucesso!`
              })
            );
          } else if (response?.status === 202 && response?.data) {
            if (response?.data.typeError === "ERRO-VALIDACAO-ESTRUTURA") {
              setFile(false);
              setFileName(msgPadraoUpload);
              setCarregandoArquivo(false);
              setValidacaoDominio(false);
              store.dispatch(
                alertaExibir({
                  tipo: "danger",
                  mensagem: response.data.value[0].ItemValidacao.Mensagem
                })
              );
            }
          }
        } catch (error) {
          setFile(false);
          setFileName(msgPadraoUpload);
          setCarregandoArquivo(false);
          setValidacaoDominio(false);
          store.dispatch(
            alertaExibir({
              tipo: "danger",
              mensagem: "Erro ao importar arquivo!"
            })
          );
        }
      }else{
        store.dispatch(
          alertaExibir({
            tipo: "danger",
            mensagem: "É necessário informar o arquivo!"
          })
        );
      }
    }else {
      if(data && data.responseInsert && data.responseInsert.totalInserido > 0){
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: data.responseInsert.totalInserido === 1 ? `1 valor registrado com sucesso!` :  `${data.responseInsert.totalInserido} valores registrados com sucesso!`
          })
        );
      }
      setCarregandoArquivo(false);
      setValidacaoDominio(false);

    }
  }

  const importarComValidacao = async () => {
    if(file){
      setCarregandoArquivo(true);
      setProgressMessage("Importando o arquivo... Por favor, aguarde.");
      try {
        const response = await importarTermicasComValidacao(file, usuarioGlobal?.usuario?.id, usuarioGlobal?.usuario?.usuarioNome);
        if (response?.status === 200 && response?.data) {
          setFile(false);
          setFileName(msgPadraoUpload);
          setCarregandoArquivo(false);
          setValidacaoDominio(false);
          store.dispatch(
            alertaExibir({
              tipo: "success",
              mensagem: `Arquivo ${file.file.name} importado com sucesso!`
            })
          );
        } else if (response?.status === 202 && response?.data) {
          if (response?.data.typeError === "ERRO-VALIDACAO-ESTRUTURA") {
            setFile(false);
            setFileName(msgPadraoUpload);
            setCarregandoArquivo(false);
            setValidacaoDominio(false);
            store.dispatch(
              alertaExibir({
                tipo: "danger",
                mensagem: response.data.value[0].ItemValidacao.Mensagem
              })
            );
          }else if (response?.data.typeError === "ERRO-VALIDACAO-DOMINIO") {
            setValidacaoDominio(true);
            setValidacaoDominioCabecalho(`Importação do arquivo ${file.file.name}`)
            setValidacaoDominiosObj(response?.data.value);
          }
        }
      } catch (error) {
        setFile(false);
        setFileName(msgPadraoUpload);
        setCarregandoArquivo(false);
        setValidacaoDominio(false);
        store.dispatch(
          alertaExibir({
            tipo: "danger",
            mensagem: "Erro ao importar arquivo!"
          })
        );
      }
    }else{
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: "É necessário informar o arquivo!"
        })
      );
    }
  }

  const onCancelar = () => {
    setFile(false);
    setFileName(msgPadraoUpload);
    setCarregandoArquivo(false);
    setValidacaoDominio(false);
  }

  const onCancelarValidacaoDominios = () => {
    setFile(false);
    setFileName(msgPadraoUpload);
    setCarregandoArquivo(false);
    setValidacaoDominio(false);
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem: "Importação cancelada!"
      })
    );
  }

  return (
    <form className="needs-validation" onSubmit={handleSubmit(importarComValidacao)}>
      <ValidacaoDominios
        item={validacaoDominiosObj}
        cabecalhoValidacao={validacaoDominioCabecalho}
        onConfirmar={(data) => importarSemValidacao(data)}
        onCancelar={() => onCancelarValidacaoDominios()}
      />
      <Card className={classes.container} style={validacaoDominio === true ? { display: 'none' } : {}}>
        <Grid container className={classes.containerTitulo}>
          <Grid item xs={11} className="font-weight-bold">
            Importação de Planilha de Usinas Térmicas
          </Grid>
          <Grid item xs={1} >             
            <CloseIcon onClick={onFechar} className={classes.icon}/>
          </Grid>   
        </Grid>
        {validacaoDominio === false ?
          <div>
            <Grid container spacing={4} className={classes.container}>
              {carregandoArquivo === false ?
                <Grid item sm={12}>
                  <UploadDropArea
                    onAdd={onAddUpload}
                    dropAreaText={fileName}
                    showLoader={false}
                    acceptedFiles={[
                      '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
                    ]}
                  />
                </Grid>
                :
                <Grid container spacing={4} className={classes.container} style={{ marginBottom: "20px" }}>
                  <Grid item sm={12} style={{ textAlign: "center" }}>
                    {progressMessage}
                  </Grid>
                  <Grid item sm={12}>
                    <Loader loading={carregandoArquivo} />
                  </Grid>
                </Grid>
              }
            </Grid>
            {
              carregandoArquivo === false ?
                <Grid container spacing={4} className={classes.container} justifyContent="center">
                  <Grid item sm={3}>
                    <Botao
                      type="button"
                      label="Cancelar"
                      onClick={onCancelar}
                      color={Base.GreyDim}
                      className={classes.button}
                    />
                  </Grid>
                  <Grid item sm={3}>
                    <Botao
                      type="submit"
                      label="Salvar"
                      color={Base.Mango}
                      className={classes.button}
                    />
                  </Grid>
                </Grid>
                : ""
            }
          </div>
          : ""}

      </Card>
    </form>
  );
}

export default TermicasImportar;
