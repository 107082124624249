import React, { useEffect, useState } from "react";

// Componentes
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box, Grid, IconButton } from "@mui/material";
// import { pdf } from "@react-pdf/renderer";
import { TabelaPaginada } from "componentes";
import UnidadeNaoSelecionada from "componentes/unidadeNaoSelecionada";
// import { saveAs } from "file-saver";
import moment from "moment";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import FaturasHelper from "./helper";

import IconeBoleto from "../../../../assets/icones/financeiro-mobile/icone-boleto.png";
import IconeNF from "../../../../assets/icones/financeiro-mobile/icone-nf.png";
import IconeFinanceiro from "../../../../assets/icones/financeiro-mobile/icone-relatorio.png";

// import PdfDemostrativoFaturamento from "../../../Relatorios/CalculoCustos/PDF";

// DTO
import ResultadoPaginadoDto from "../../../../componentes/tabelaPaginada/resultadoPaginadoDto";
import FiltroDto from "./dto/filtroDto";
import listagemFaturasDto from "./dto/listagemFaturasDto";

// Redux
import { store } from "../../../../global/redux";
import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";

// Styles
import { useStyles } from "./style";

// Serviços

const FaturasMobile = () => {
  // eslint-disable-next-line no-unused-vars
  const [ordemSelecionada, setOrdemSelecionada] = useState("");
  const [filtroAdicionalTabela, setFiltroAdicionalTabela] = useState(
    new FiltroDto()
  );

  const history = useHistory();

  const [mesWidget, setMesWidget] = useState([]);
  const [mes, setMes] = useState(moment().subtract(1, "month").format("M"));
  const [ano, setAno] = useState(moment().subtract(1, "month").format("YYYY"));
  const [dadosFinanceiros, setDadosFinanceiros] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [filtroStatusSelecionado, setFiltroStatusSelecionado] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [valorOrdenacao, setValorOrdenacao] = useState(0);

  const classes = useStyles();

  const global = useSelector((state) => state.usuario);
  const { unidadeSelecionada } = global;

  const obterGrupos = async (parametros) => {
    const filtro = new FiltroDto();
    filtro.linhasPorPagina = parametros?.tamanhoPagina;
    filtro.pagina = parametros?.pagina;
    filtro.ordenacao = Number(valorOrdenacao) !== 0 ? valorOrdenacao : 0;
    filtro.unidadeConsumidoraId =
      Number(unidadeSelecionada) !== 0 ? unidadeSelecionada : 0;
    filtro.anoReferencia = Number(ano) !== 0 ? ano : 0;
    filtro.mesReferencia = Number(mes) !== 0 ? mes : 0;
    filtro.statusId =
      Number(filtroStatusSelecionado) !== 0 ? filtroStatusSelecionado : 0;

    const resultado = await FaturasHelper.listarFaturas(filtro);

    if (!resultado.sucesso) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: resultado.mensagem
        })
      );
      return new ResultadoPaginadoDto([], 1, 0, 0);
    }

    if (resultado?.data?.totalItens === 0) {
      setDadosFinanceiros([]);
    }

    if (resultado?.data.length === 0 ?? true)
      return new ResultadoPaginadoDto([], 1, 0, 0);

    const resLinhas = resultado?.data?.lista?.map((res) => {
      setDadosFinanceiros(res);
      // eslint-disable-next-line new-cap
      return new listagemFaturasDto(
        res.id,
        res.unidadeConsumidoraId,
        res.ultimoIdentificadorBoleto,
        res.ultimoNomeBoleto,
        res.ultimoIdentificadorNotaFiscal,
        res.ultimoNomeNotaFiscal,
        res.mesAnoReferenciaFormatado,
        res.mesReferencia,
        res.anoReferencia,
        res.statusNome,
        res.statusId,
        res.dataPagamentoFormatada,
        res.valorFormatado,
        res.dataVencimentoFormatada
      );
    });

    return new ResultadoPaginadoDto(
      resLinhas,
      resultado?.data?.paginaAtual,
      resultado?.data?.totalItens,
      resultado?.data?.totalPaginas
    );
  };

  // eslint-disable-next-line consistent-return
  const onChangeFiltrosTabela = async (parametros) => {
    if (Number(unidadeSelecionada) !== 0) {
      return obterGrupos({
        tamanhoPagina: parametros?.totalPagina,
        pagina: parametros?.pagina,
        filtroAdicional: new FiltroDto(
          parametros?.filtrosAdicionais?.ordenacao,
          parametros?.filtrosAdicionais?.statusId,
          parametros?.filtroAdicional?.anoReferencia,
          parametros?.filtroAdicional?.mesReferencia,
          parametros?.filtroAdicional?.unidadeConsumidoraId
        )
      });
    }
  };

  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    whileMonth();
    setFiltroAdicionalTabela(
      new FiltroDto(
        ordemSelecionada,
        filtroStatusSelecionado,
        ano,
        mes,
        unidadeSelecionada
      )
    );
  }, [unidadeSelecionada, ano, mes]);

  const whileMonth = () => {
    const subtrairMeses = moment().subtract(6, "month").format("M/YYYY");
    const meses = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 6; i++) {
      meses.push({
        nomeMes: moment(subtrairMeses, "M/YYYY").add(i, "month").format("MMM"),
        mes: moment(subtrairMeses, "M/YYYY").add(i, "month").format("M"),
        ano: moment(subtrairMeses, "M/YYYY").add(i, "month").format("YYYY")
      });
    }
    setMesWidget(meses);
  };

  const alterarMes = (parametro) => {
    setMes(parametro[0]);
    setAno(parametro[1]);
  };

  // eslint-disable-next-line consistent-return
  const onClickDownloadBoleto = async () => {
    const { sucesso, mensagem } = await FaturasHelper.BaixarBoleto(
      dadosFinanceiros
    );
    if (!sucesso) return FaturasHelper.exibirErro(mensagem);
  };

  // eslint-disable-next-line consistent-return
  const onClickDownloadNotaFiscal = async () => {
    const { sucesso, mensagem } = await FaturasHelper.BaixarNotaFiscal(
      dadosFinanceiros
    );

    if (!sucesso) return FaturasHelper.exibirErro(mensagem);
  };

  // eslint-disable-next-line consistent-return
  const onClickGerarPDF = async () => {
    const { sucesso, mensagem } = await FaturasHelper.BuscarCalculoCustosPdf(
      dadosFinanceiros.unidadeConsumidoraId,
      mes,
      ano
    );
    if (!sucesso) return FaturasHelper.exibirErro(mensagem);

    // const doc = (
    //   <PdfDemostrativoFaturamento
    //     // eslint-disable-next-line react/jsx-props-no-spreading
    //     {...data}
    //   />
    // );
    // const asPdf = pdf([]);
    // asPdf.updateContainer(doc);
    // const blob = await asPdf.toBlob();
    // saveAs(blob, `Demonstrativo de Faturamento ${mes}-${ano}`);
  };

  return (
    <>
      {unidadeSelecionada ? (
        <Box>
          <Box className={classes.barraSuperior}>
            <IconButton
              className={classes.arrowBack}
              onClick={() => history.push("/dashboard/")}
              size="small"
            >
              <ArrowBackIosIcon fontSize="small" />
            </IconButton>
            Financeiro
          </Box>
          <Box className={classes.backgroundCinza}>
            <Box className={classes.tituloSuperior}>
              Total da conta de
              {moment(mes).format("MMMM")}
            </Box>
            <Box className={classes.tituloSuperiorValor}>
              {dadosFinanceiros?.valorFormatado}
            </Box>

            <Box style={{ display: "none" }}>
              <TabelaPaginada
                onChangeFiltrosTabela={onChangeFiltrosTabela}
                filtrosAdicionais={filtroAdicionalTabela}
                paginaAtual={1}
                paginationPerPage={20}
              />
            </Box>

            <Grid container>
              {mesWidget?.map((t) => {
                return (
                  <Grid
                    className={`${classes.textoBranco} ${
                      mes === t.mes ? classes.textoSelecionado : ""
                    } `}
                    onClick={() => alterarMes([t.mes, t.ano])}
                    item
                    xs={2}
                  >
                    <Box
                      className={`${classes.pin} ${
                        mes === t.mes ? classes.pinSelecionado : ""
                      } `}
                    />
                    {t.nomeMes}
                  </Grid>
                );
              })}
            </Grid>
          </Box>

          <Box className={classes.boxStatus}>
            {dadosFinanceiros?.statusNome === "PAGO" ? (
              <Box className={classes.borderPago}>
                {dadosFinanceiros?.statusNome}
              </Box>
            ) : dadosFinanceiros?.statusNome === "PENDENTE" ? (
              <Box className={classes.borderPendente}>
                {dadosFinanceiros?.statusNome}
              </Box>
            ) : dadosFinanceiros?.statusNome === "ATRASADO" ? (
              <Box className={classes.borderAtrasado}>
                {dadosFinanceiros?.statusNome}
              </Box>
            ) : (
              <></>
            )}
          </Box>

          <Box className={classes.boxDatas}>
            <Box>
              Data de Vencimento:
              {dadosFinanceiros?.dataVencimentoFormatada}
            </Box>
            <Box mt={1}>
              Data de Pagamento:
              {dadosFinanceiros?.dataPagamentoFormatada
                ? dadosFinanceiros?.dataPagamentoFormatada
                : " ---"}
            </Box>
          </Box>

          <Box className={classes.boxBotoes}>
            <Grid container>
              <Grid item xs={4}>
                <Box
                  onClick={() => onClickDownloadNotaFiscal()}
                  className={classes.botaoAcao}
                >
                  <img
                    src={IconeNF}
                    className={classes.iconeBotao}
                    alt="notaFiscal"
                  />
                  NF
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box
                  onClick={() =>
                    onClickGerarPDF(
                      dadosFinanceiros.unidadeConsumidoraId,
                      dadosFinanceiros.mesReferencia,
                      dadosFinanceiros.anoReferencia
                    )
                  }
                  className={`${classes.botaoAcao} ${classes.botaoCentro}`}
                >
                  <img
                    src={IconeFinanceiro}
                    className={classes.iconeBotao}
                    alt="financeiro"
                  />
                  Fat.
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box
                  onClick={() => onClickDownloadBoleto()}
                  className={`${classes.botaoAcao} ${classes.botaoRight}`}
                >
                  <img
                    src={IconeBoleto}
                    className={classes.iconeBotao}
                    alt="boleto"
                  />
                  Bol.
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      ) : (
        <UnidadeNaoSelecionada />
      )}
    </>
  );
};

export default FaturasMobile;
