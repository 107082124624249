import { makeStyles } from "@mui/styles";
import theme from "../../../../../../../themes";

export const useStyles = makeStyles({
  container: {
    margin: "0",
    width: "100%",
    "& .MuiFormControl-root": {
      width: "100%"
    }
  },
  cardCadastro: {
    backgroundColor: theme.color.bgColorCardCadastro,
    padding: "30px"
  },
  desabledInput: {
    pointerEvents: "none",
    opacity: 0.5
  }
});
