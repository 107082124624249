import { makeStyles } from "@mui/styles";
import theme from "themes";

export const useStyles = makeStyles({
  treeView: {
    flexGrow: 1,
    minWidth: "275px !important",
    "pointer-events": "none !important",
    "& *": {
      fontFamily: theme.font.family
    }
  },
  container: {
    height: "100% !important",
    maxHeight: "56px !important",
    margin: "0 !important",
    width: "100% !important"
  },
  content: {
    padding: "0 !important"
  },
  label: {
    margin: "0 0 2px !important",
    padding: "0 !important"
  },
  iconContainer: {
    margin: "0 2px 2px 0 !important",
    maxWidth: "54px !important",
    width: "100% !important",
    "& svg": {
      fontSize: "2.1875rem !important"
    }
  },
  iconHide: {
    display: "none !important"
  },
  iconCrud: {
    "pointer-events": "all !important"
  },
  group: {
    marginLeft: "0 !important",
    "& .withChildren": {
      marginLeft: "56px !important"
    }
  },
  button: {
    marginBottom: "2px !important",
    "pointer-events": "all !important"
  },
  form: {
    height: "54px !important"
  },
  card: {
    height: "100% !important",
    maxHeight: "56px !important",
    "pointer-events": "auto !important",
    width: "100% !important"
  }
});
