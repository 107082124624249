import React, { useEffect, useState } from "react";

// Redux
import { Grid } from "@mui/material";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import { store } from "../../../global/redux";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";

// Componentes
import UploadDropArea from "../../../componentes/upload/index";
import Loader from "../../../componentes/loader";

// Serviços
import { getUploadProgress } from "../../../servicos/uploadServico";
import { desabilitarFiltroLateral } from "../../../global/redux/modulos/usuario/actions";
import UploadHelper from "./helper";

// Styles
import { useStyles } from "./style";

const MedicaoUpload = () => {
  const classes = useStyles();
  const [progressConsumoBaixo, setProgressConsumoBaixo] = useState(0);
  const [showLoaderConsumoBaixo, setShowLoaderConsumoBaixo] = useState(false);
  const [carregando, setCarregando] = useState(false);

  const getProgressConsumoBaixo = (nomeArquivo) => {
    let auxProgress = 0;
    setShowLoaderConsumoBaixo(true);
    const timer = setInterval(() => {
      const response = getUploadProgress(nomeArquivo);
      response.then((res) => {
        setProgressConsumoBaixo((prevProgress) =>
          res === 0 ? prevProgress : res
        );
        auxProgress = res;
      });

      if (auxProgress >= 100) {
        clearInterval(timer);
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: `O arquivo ${nomeArquivo} foi processado com sucesso!`
          })
        );
        setShowLoaderConsumoBaixo(false);
      }
    }, 10000);
  };

  const processarArquivoConsumoBaixa = async (valor) => {
    const arquivo = valor[0];
    if (arquivo) {
      store.dispatch(
        alertaExibir({
          tipo: "success",
          mensagem: `O arquivo ${arquivo.file.name} foi enviado para processamento!`
        })
      );

      const resultado = await UploadHelper.enviarArquivoConsumoBaixa(arquivo);

      if (!resultado.sucesso) {
        store.dispatch(
          alertaExibir({
            tipo: "danger",
            mensagem: resultado.mensagem
          })
        );
        return;
      }

      setProgressConsumoBaixo(0);
      getProgressConsumoBaixo(arquivo.file.name);
    }
  };

  const onAddUploadConsumoBaixa = (valor) => {
    processarArquivoConsumoBaixa(valor);
  };

  const onDropRejectedConsumoBaixo = (valor) => {
    const arquivo = valor[0];
    store.dispatch(
      alertaExibir({
        tipo: "warning",
        mensagem: `Tipo do arquivo ${arquivo.name} não é permitido!`
      })
    );
  };

  const onDownloadConsumoBaixa = async () => {
    setCarregando(true);
    const resultado = await UploadHelper.obterArquivoConsumoBaixa();

    if (!resultado.sucesso) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: resultado.mensagem
        })
      );
    }

    setCarregando(false);
  };

  useEffect(() => {
    store.dispatch(desabilitarFiltroLateral(true));

    return () => {
      store.dispatch(desabilitarFiltroLateral(false));
    };
  }, [desabilitarFiltroLateral]);

  return (
    <Loader loading={carregando}>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={6} className={classes.container}>
          Matriz Desconto Consumo Baixa (R$)
        </Grid>
        <Grid item xs={6} className={classes.container}>
          Download Matriz Desconto Consumo Baixa
        </Grid>
        <Grid item xs={6}>
          <UploadDropArea
            onAdd={onAddUploadConsumoBaixa}
            progressValue={progressConsumoBaixo}
            showLoader={showLoaderConsumoBaixo}
            onDropRejected={onDropRejectedConsumoBaixo}
          />
        </Grid>
        <Grid item xs={6}>
          <button
            type="button"
            className={classes.button}
            onClick={onDownloadConsumoBaixa}
          >
            <CloudDownloadOutlinedIcon className={classes.icone} />
            <span>Clique aqui para realizar o download</span>
          </button>
        </Grid>
      </Grid>
    </Loader>
  );
};

export default MedicaoUpload;
