import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const api = () => obterApi(MICROSERVICO.PROFILE);

// Listar Perfis
export const listarPerfis = async () => {
  return api().get("v1/Perfil");
};

// Busca Por Id
export const buscarPorId = async (id) => {
  return api().get(`v1/Perfil/${id}`);
};

// Busca Perfil Por Tipo
export const buscarPorTipo = async (tipo) => {
  return api().get(`v1/Perfil/tipoperfil/${tipo}`);
};

// Buscar perfil por TipoPerfilEnum
export const buscarPerfilPorTipo = async (tipo) => {
  return api().get(`v1/Perfil/Tipo/${tipo}`);
};

// Deletar Perfil
export const deletarPerfil = async (id, ativo) => {
  return api().patch(`v1/Perfil/${id}/ativo/${ativo}`);
};

// Salvar Perfil
export const salvarPerfil = async (id, dados) => {
  let metodo = "post";
  let url = "v1/Perfil/";
  if (id > 0) {
    metodo = "put";
    url = `${url}${id}`;
  }
  return api()[metodo](url, dados);
};

// Listar Tipos de Perfil
export const listarTiposPerfil = async () => {
  return api().get("v1/PerfilTipo");
};
