/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
/* eslint-disable import/no-named-as-default */
import React, { useEffect, useState } from "react";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box, Button, Grid, IconButton } from "@mui/material";
import getDomain from "global/getDomain";
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import { useHistory } from "react-router-dom";
import {
  BoxInstrucoes,
  ComFatura,
  Cotacoes,
  Instrucao,
  ListaCotacoes,
  SemFatura,
  TextoMobile,
  TituloInstrucao,
  useStyles
} from "./style";

import IconeComFaturaPreto from "../../../assets/cotacoes/img-com-fatura-preto.png";
import IconeComFatura from "../../../assets/cotacoes/img-com-fatura.png";
import IconeSemFaturaPreto from "../../../assets/cotacoes/img-sem-fatura-preto.png";
import IconeSemFatura from "../../../assets/cotacoes/img-sem-fatura.png";

import IconeEnquadramento from "../../../assets/cotacoes/icone-enquadramento.png";
import IconeIluminacao from "../../../assets/cotacoes/icone-iluminacao.png";
import IconeQualidade from "../../../assets/cotacoes/icone-qualidade.png";

import Botao from "../../../componentes/botao";

import "./estilo-hack.css";

import {
  digitalizacaoFaturaAntigo,
  obterDadosFaturaDigitalizada
} from "../../../servicos/digitalizacaoServico";

import Loader from "./loader";

import "react-html5-camera-photo/build/css/index.css";
import ImagePreview from "./ImagePreview";

const CotacaoMobile = () => {
  const history = useHistory();
  const classes = useStyles();
  const [tela, setTela] = useState(1);
  const [loading, setLoading] = useState(false);
  const [msgLoading, setMsgLoading] = useState("");
  // Tela 1: Inicial
  // Tela 2: Com / Sem fatura
  // Tela 3: Instruções

  const [dataUri, setDataUri] = useState("");

  function handleTakePhotoAnimationDone(dataUri) {
    setDataUri(dataUri);
  }

  const isFullscreen = false;

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [tela]);

  const importarFatura = async () => {
    setMsgLoading("Efetuando upload da fatura...");
    try {
      setLoading(true);

      const base64 = dataUri.split(",")[1];

      const externoId = Math.floor(1000 + Math.random() * 9000);
      const retornoDigitalizacao = await digitalizacaoFaturaAntigo(
        base64,
        externoId,
        "importar-earq"
      );
      if (retornoDigitalizacao?.statusCodes === 200) {
        retornoFatura(externoId);
        setMsgLoading("Lendo dados da fatura...");
      } else {
        setMsgLoading("Erro ao digitalizar fatura");
        setTimeout(function () {
          setLoading(false);
        }, 3000);
      }
    } catch (error) {
      setMsgLoading("Erro ao efetuar upload!");
      setTimeout(function () {
        setLoading(false);
      }, 3000);
    }
  };

  const retornoFatura = async (externoId) => {
    try {
      const retornoFatura = await obterDadosFaturaDigitalizada(externoId);
      if (JSON.stringify(retornoFatura.fornecedor) === "null") {
        setMsgLoading("Erro ao processar fatura.");
        setTimeout(function () {
          setLoading(false);
        }, 3000);
      } else {
        setMsgLoading("Sucesso!");

        setTimeout(function () {
          history.push({
            pathname: "/cotacao/inicial//mobile",
            state: retornoFatura
          });
          setLoading(false);
        }, 2000);
      }
    } catch (error) {
      setMsgLoading("Erro ao processar fatura.");
      setTimeout(function () {
        setLoading(false);
      }, 3000);
    }
  };

  const nav = () => {
    // eslint-disable-next-line no-unused-expressions
    tela === 1 ? history.push("/dashboard/") : setTela(tela - 1);
  };

  const handleFileRead = async (event) => {
    const file = event.target.files[0];
    const base64 = await convertBase64(file);
    const newDataUri = await resizedataURL(base64, 1080, 1350);
    setDataUri(newDataUri);
    setTela(4);
  };

  const resizedataURL = async (datas, wantedWidth, wantedHeight) => {
    // eslint-disable-next-line no-async-promise-executor, no-unused-vars
    return new Promise(async function (resolve, reject) {
      const img = document.createElement("img");
      img.onload = function () {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = wantedWidth;
        canvas.height = wantedHeight;
        ctx.drawImage(this, 0, 0, wantedWidth, wantedHeight);
        const dataURI = canvas.toDataURL();
        resolve(dataURI);
      };
      img.src = datas;
    });
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const menuRender = () => {
    switch (tela) {
      case 1:
        return (
          <>
            <Cotacoes onClick={() => setTela(2)}>
              <TextoMobile>Cotações</TextoMobile>
            </Cotacoes>

            <ListaCotacoes to="/cotacao">
              <TextoMobile>Lista de Cotações</TextoMobile>
            </ListaCotacoes>
          </>
        );
      case 2:
        return (
          <>
            <ComFatura onClick={() => setTela(3)}>
              <TextoMobile>
                <img
                  src={
                    getDomain() === "luz" ? IconeComFaturaPreto : IconeComFatura
                  }
                  className={classes.iconeBotao}
                  alt="icones"
                />
                Com Fatura
              </TextoMobile>
            </ComFatura>

            <SemFatura to="/cotacaoacl/inicial">
              <TextoMobile>
                <img
                  src={
                    getDomain() === "luz" ? IconeSemFaturaPreto : IconeSemFatura
                  }
                  className={classes.iconeBotao}
                  alt="mobile"
                />
                Sem Fatura
              </TextoMobile>
            </SemFatura>
          </>
        );
      case 3:
        return (
          <>
            <BoxInstrucoes>
              <Grid container>
                <Grid item xs={2} className={classes.colunaIconeInstrucoes}>
                  <img
                    src={IconeIluminacao}
                    className={classes.iconeInstrucoes}
                    alt="instrucoes"
                  />
                </Grid>
                <Grid item xs={10}>
                  <TituloInstrucao>Iluminação do ambiente</TituloInstrucao>
                  <Instrucao>
                    Posicione a fatura em um ambiente iluminado.
                  </Instrucao>
                </Grid>
              </Grid>
            </BoxInstrucoes>

            <BoxInstrucoes>
              <Grid container>
                <Grid item xs={2} className={classes.colunaIconeInstrucoes}>
                  <img
                    src={IconeEnquadramento}
                    className={classes.iconeInstrucoes}
                    alt="instrucoes"
                  />
                </Grid>
                <Grid item xs={10}>
                  <TituloInstrucao>Enquadramento da fatura</TituloInstrucao>
                  <Instrucao>
                    Enquadre o documento, alinhando as bordas.
                  </Instrucao>
                </Grid>
              </Grid>
            </BoxInstrucoes>

            <BoxInstrucoes>
              <Grid container>
                <Grid item xs={2} className={classes.colunaIconeInstrucoes}>
                  <img
                    src={IconeQualidade}
                    className={classes.iconeInstrucoes}
                    alt="instrucoes"
                  />
                </Grid>
                <Grid item xs={10}>
                  <TituloInstrucao>Qualidade da foto</TituloInstrucao>
                  <Instrucao>
                    As imagens precisam estar nítidas, com os dados legíveis.
                  </Instrucao>
                </Grid>
              </Grid>
            </BoxInstrucoes>

            <Grid container>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  component="label"
                  className={classes.anexarFoto}
                >
                  Próximo
                  <input
                    onChange={(e) => {
                      handleFileRead(e);
                    }}
                    accept="image/*"
                    type="file"
                    hidden
                  />
                </Button>
              </Grid>
            </Grid>
          </>
        );
      case 4:
        return (
          <>
            {dataUri ? (
              <>
                <Grid container>
                  <Grid item xs={6} className={classes.itemBotao}>
                    <Botao
                      type="button"
                      label="Voltar"
                      className={classes.botaoNovaFoto}
                      onClick={() => setTela(3)}
                    />
                  </Grid>

                  <Grid item xs={6} className={classes.itemBotao}>
                    <Botao
                      type="button"
                      label="Processar"
                      className={classes.botaoProcessarFatura}
                      onClick={() => importarFatura()}
                    />
                  </Grid>
                </Grid>

                <ImagePreview dataUri={dataUri} isFullscreen={isFullscreen} />
              </>
            ) : (
              <>
                <Camera
                  imageType={IMAGE_TYPES.JPG}
                  imageCompression={0}
                  isMaxResolution={false}
                  // eslint-disable-next-line react/jsx-no-bind
                  onTakePhotoAnimationDone={handleTakePhotoAnimationDone}
                  idealFacingMode={FACING_MODES.ENVIRONMENT}
                  isImageMirror={false}
                  isFullscreen={isFullscreen}
                  idealResolution={{ width: 1920, height: 1080 }}
                  sizeFactor={1}
                />
              </>
            )}
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      {!loading ? (
        <Box>
          <Box className={classes.barraSuperior}>
            <IconButton
              className={classes.arrowBack}
              onClick={() => nav()}
              size="small"
            >
              <ArrowBackIosIcon fontSize="small" />
            </IconButton>
            Cotações
          </Box>

          {menuRender()}
        </Box>
      ) : (
        <Loader msgLoading={msgLoading} />
      )}
    </>
  );
};

export default CotacaoMobile;
