import React, { useCallback, useEffect, useMemo, useState } from "react";

// ApexCharts
import ReactApexChart from "react-apexcharts";

// Componentes
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import moment from "moment";
import { useSelector } from "react-redux";
import theme from "themes";
import InputData from "../../../componentes/inputTextoData";
import Loader from "../../../componentes/loader";

// Styles
import { Base } from "../../../componentes/cores";
import { Container } from "../Dashboard/style";
import { useStyles } from "./style";

// Serviços
import { consumoHora } from "../../../servicos/graficosServico";

const ConsumoHora = (props) => {
  const classes = useStyles();
  const usuario = useSelector((state) => state.usuario);
  const { unidadeSelecionada } = usuario;

  const [diaMes, setDiaMes] = useState();
  const [carregandoDados, setCarregandoDados] = useState(false);

  const [dadosDasHoras, setDadosDasHoras] = useState([]);
  const [labelsHoras, setLabelsHoras] = useState([]);
  const [grafico, setGrafico] = useState(<div />);
  const [maiorValor, setMaiorValor] = useState();

  const [pdf, setPDF] = React.useState(props);

  useEffect(() => {
    if (dadosDasHoras.length) {
      const dadosOrdenados = dadosDasHoras.filter((item) => item);
      const maxValor = dadosOrdenados.length > 0 && Math.max(...dadosOrdenados);
      if (dadosOrdenados) setMaiorValor(maxValor);
    }
  }, [dadosDasHoras]);

  useEffect(() => {
    setDiaMes(window.moment().subtract(1, "days"));
  }, []);

  useEffect(() => {
    setPDF(props);
  }, [props]);

  const dados = useMemo(
    () => ({
      series: [
        {
          color: pdf.status ? "#f76c1d" : theme.color.gradientGraficoHoraFinal,
          data: dadosDasHoras,
          name: "Horas",
          type: "area"
        }
      ],
      options: {
        chart: {
          fontFamily: theme.font.family,
          offsetX: 0,
          offsetY: 0,
          width: "100%",
          maxWidth: "100%",
          type: "area",
          toolbar: {
            show: false
          },
          fontSize: "10px"
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        stroke: {
          colors: pdf.status
            ? ["#F76C1D", "#F76C1D"]
            : [
                theme.color.gradientGraficoHoraFinal,
                theme.color.gradientGraficoHoraInicial
              ],
          curve: "smooth",
          width: pdf.status ? 5 : 2
        },
        fill: {
          type: ["gradient", "solid"],
          gradient: {
            gradientToColors: [
              theme.color.gradientGraficoHoraInicial,
              theme.color.gradientGraficoHoraFinal
            ],
            type: "vertical",
            shadeIntensity: 0,
            inverseColors: false,
            opacityFrom: 0.8,
            opacityTo: 0.8,
            stops: [20, 100, 100, 100]
          }
        },
        labels: labelsHoras,
        markers: {
          size: 0,
          strokeColor: "#fff"
        },
        grid: {
          borderColor: theme.color.gridColorGrafico,
          xaxis: {
            lines: {
              show: true
            },
            labels: {
              show: false
            }
          },
          yaxis: {
            lines: {
              show: false
            },
            labels: {
              show: false
            }
          },
          padding: {
            right: 20
          }
        },
        xaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            show: false
          },
          labels: {
            formatter(x) {
              return `${x}`;
            },
            style: {
              colors: pdf.status
                ? theme.color.thirdBackgroud
                : theme.color.labelColorGrafico,
              fontSize: pdf.status ? "14px" : "11px"
            },
            rotate: 0
          },
          position: "top",
          tooltip: {
            enabled: false
          }
        },
        yaxis: {
          labels: {
            show: false
          },
          min: 0,
          max: maiorValor * 1.025
        },
        tooltip: {
          custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            const valorConvertido = series[seriesIndex][dataPointIndex];
            const valor =
              valorConvertido &&
              valorConvertido.toFixed(3).toString().replace(".", ",");
            return `
            <div class=${classes.arrowBox}> 
              <center class=${classes.arrowBoxTitle}>${
              w.globals.labels[dataPointIndex] - 1
            }h </center>
              <span><b>${valor}KWh</b></span>
            </div>
          `;
          }
        },
        noData: {
          text: "",
          style: {
            color: `${Base.OffWhite} !important`,
            fontSize: "18px",
            maxWidth: "80%"
          }
        }
      }
    }),
    [dadosDasHoras, labelsHoras, maiorValor, pdf]
  );

  const RenderizarGrafico = useCallback(() => {
    const graficoRender = (
      <ReactApexChart
        options={dados?.options}
        series={dados?.series}
        height={pdf.status ? 240 : 350}
        type="area"
      />
    );

    setGrafico(graficoRender);
  }, [setGrafico, dadosDasHoras, labelsHoras, pdf]);

  useEffect(() => RenderizarGrafico(), [RenderizarGrafico]);

  const obterDados = useCallback(async (diaMesValue) => {
    try {
      setCarregandoDados(true);
      if (diaMesValue && unidadeSelecionada) {
        const dia = window.moment(diaMesValue).format("YYYY-MM-DD");
        const unidSelec = unidadeSelecionada;

        const retorno = await consumoHora(dia, unidSelec);

        if (retorno?.data) {
          setDadosDasHoras(retorno?.data?.resultados ?? []);
          setLabelsHoras(retorno?.data?.labels ?? []);
          setDiaMes(diaMesValue);
        }
      }
      setCarregandoDados(false);
    } catch (error) {
      setCarregandoDados(false);
    }
  });

  const obterUltimaMedicao = useCallback(async () => {
    return false;
  }, []);

  useEffect(() => {
    obterUltimaMedicao();
  }, [unidadeSelecionada]);

  return (
    <Container
      height="100%"
      width="100%"
      style={{ backgroundColor: pdf.status ? "#fbfbfb" : "" }}
    >
      <Grid
        container
        justifyContent="space-between"
        className="m-0 w-100"
        spacing={2}
      >
        <Grid
          item
          className={classes.titulo}
          style={{ color: pdf.status === true ? "black" : "" }}
        >
          <div style={{ fontSize: pdf.status === true ? "18px" : "" }}>
            Consumo por hora
          </div>
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item>
              {!pdf.status ? (
                <InputData
                  type="text"
                  id="diaMes"
                  name="diaMes"
                  format="dd/MMM"
                  maxDate={window.moment().subtract(1, "day").toDate()}
                  customValue={diaMes}
                  onChange={(valor) => obterDados(valor)}
                  renderIconShowHide={false}
                  className={classes}
                />
              ) : (
                <div style={{ color: "black" }}>
                  {moment(diaMes).format("DD/MM/YYYY")}
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={`${classes.itemGrafico} w-100`}>
        <Grid item xs={12} className={`${classes.itemGrafico} h-100 w-100`}>
          <Loader loading={carregandoDados}>
            {dados?.options && dados?.series && dadosDasHoras?.length ? (
              grafico
            ) : (
              <div className={classes.noData}>
                <div>
                  Não foi possível carregar as informações de consumo, tente
                  novamente em algumas horas!
                </div>
              </div>
            )}
          </Loader>
        </Grid>
      </Grid>

      {pdf.status ? (
        <Grid container spacing={0} className={classes.itemGraficoInferior}>
          <Grid item xs>
            <TableContainer>
              <Table
                className={classes.table}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableColumn}>Hora</TableCell>
                    <TableCell align="left" className={classes.tableColumnCons}>
                      Cons. (MWh)
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dadosDasHoras.map((row, index) =>
                    row !== 0 && index <= 4 && typeof row !== "object" ? (
                      <TableRow key={row}>
                        <TableCell
                          className={classes.tableColumn}
                          component="th"
                          scope="row"
                        >
                          {`0${labelsHoras[index]}`}
                        </TableCell>
                        <TableCell align="left">
                          {row.toLocaleString("pt-BR")}
                        </TableCell>
                      </TableRow>
                    ) : (
                      ""
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs>
            <TableContainer>
              <Table
                className={classes.table}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableColumn}>Hora</TableCell>
                    <TableCell align="left" className={classes.tableColumnCons}>
                      Cons. (MWh)
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dadosDasHoras.map((row, index) =>
                    index > 4 &&
                    index <= 9 &&
                    row !== 0 &&
                    typeof row !== "object" ? (
                      <TableRow key={row}>
                        <TableCell
                          className={classes.tableColumn}
                          component="th"
                          scope="row"
                        >
                          {`0${labelsHoras[index]}`}
                        </TableCell>
                        <TableCell align="left">
                          {row.toLocaleString("pt-BR")}
                        </TableCell>
                      </TableRow>
                    ) : (
                      ""
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs>
            <TableContainer>
              <Table
                className={classes.table}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableColumn}>Hora</TableCell>
                    <TableCell align="left" className={classes.tableColumnCons}>
                      Cons. (MWh)
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dadosDasHoras.map((row, index) =>
                    index >= 10 &&
                    index <= 14 &&
                    row !== 0 &&
                    typeof row !== "object" ? (
                      <TableRow key={row}>
                        <TableCell
                          className={classes.tableColumn}
                          component="th"
                          scope="row"
                        >
                          {labelsHoras[index]}
                        </TableCell>
                        <TableCell align="left">
                          {row.toLocaleString("pt-BR")}
                        </TableCell>
                      </TableRow>
                    ) : (
                      ""
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs>
            <TableContainer>
              <Table
                className={classes.table}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableColumn}>Hora</TableCell>
                    <TableCell align="left" className={classes.tableColumnCons}>
                      Cons. (MWh)
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dadosDasHoras.map((row, index) =>
                    index >= 15 &&
                    index <= 19 &&
                    row !== 0 &&
                    typeof row !== "object" ? (
                      <TableRow key={row}>
                        <TableCell
                          className={classes.tableColumn}
                          component="th"
                          scope="row"
                        >
                          {labelsHoras[index]}
                        </TableCell>
                        <TableCell align="left">
                          {row.toLocaleString("pt-BR")}
                        </TableCell>
                      </TableRow>
                    ) : (
                      ""
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs>
            <TableContainer>
              <Table
                className={classes.table}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableColumn}>Hora</TableCell>
                    <TableCell align="left" className={classes.tableColumnCons}>
                      Cons. (MWh)
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dadosDasHoras.map((row, index) =>
                    index >= 20 &&
                    index <= 24 &&
                    row !== 0 &&
                    typeof row !== "object" ? (
                      <TableRow key={row}>
                        <TableCell
                          className={classes.tableColumn}
                          component="th"
                          scope="row"
                        >
                          {labelsHoras[index]}
                        </TableCell>
                        <TableCell align="left">
                          {row.toLocaleString("pt-BR")}
                        </TableCell>
                      </TableRow>
                    ) : (
                      ""
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
    </Container>
  );
};

export default ConsumoHora;
