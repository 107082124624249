import React, { useState, useEffect, useCallback, useMemo } from 'react';
import shortid from "shortid";
import TuneIcon from "@mui/icons-material/Tune";
import { Grid } from '@mui/material';
import { useForm, useWatch } from 'react-hook-form';
import { useHistory } from "react-router-dom";
import { listarGruposProjecoes,listarFiltrarPor, excluirGrupo } from '../../../servicos/grupoProjecoes';
import { useStyles } from "./style";
import { Base } from 'componentes/cores';
import Loader from 'componentes/loader';
import ModalConfirmacao from 'componentes/modalConfirmacao';
import MaterialInputTexto from 'componentes/inputTexto/materialInput';
import BotaoFiltroOrdenacao from 'componentes/botaoFiltroOrdenar';
import BotaoCadastro from 'componentes/botaoCadastro';
import ItemListaExpansivel from './itemListaExpansivel';
import RotasDTO from '../../../rotas/rotasUrlDto';
import { store } from "global/redux";
import { useSelector } from "react-redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import ModalInformacao from "componentes/modalVerificacao";

const GruposProjecoes = () => {
  const classes = useStyles();
  const history = useHistory();

  const [carregandoGrupos, setcarregandoGrupos] = useState(false);
  const [gruposDeProjecoes, setGruposDeProjecoes] = useState([]);
  const [listaGruposDeProjecoes, setlistaGruposDeProjecoes] = useState([]);
  const [modalExclusao, setModalExclusao] = useState(false);
  const [msgExclusao, setMsgExclusao] = useState("");
  const [filtroSelecionado, setFiltroSelecionado] = useState(null);
  const [valoresfiltro, setValoresFiltro] = useState(null)
  const [modalInfo, setModalInfo] = useState(false);
  const [idgrupo, setIdGrupo] = useState(null);
    const usuarioGlobal = useSelector((state) => state.usuario);
  const { register, control, setValue } = useForm({
    reValidateMode: "onSubmit"
  });

  const textoBusca = useWatch({
    control,
    name: "textoBusca",
    defaultValue: ""
  });

  const stringBusca = useMemo(() => {
    return textoBusca;
  }, [textoBusca]);

  function filterByActive(obj) {
    if (obj.codigoStatusGrupo && obj.codigoStatusGrupo === filtroSelecionado) {
      return true;
    } 
      return false;
  }
 
  const filterGruposDeProjecoesByTitulo = (obj) => {
    if (obj.titulo != null && obj.titulo.toUpperCase().includes(textoBusca.toUpperCase())) {   
      return true;
    } else {
      return false;
    }
  }
 
  const handleFiltrarGruposDeProjecoes = () =>{

    let dadosFiltrados = [];
    
    if(listaGruposDeProjecoes != null && listaGruposDeProjecoes.length > 0){  
      
      dadosFiltrados = listaGruposDeProjecoes;

      if(stringBusca != undefined && stringBusca.length > 0){
        setFiltroSelecionado(null);
        dadosFiltrados = listaGruposDeProjecoes.filter(filterGruposDeProjecoesByTitulo);
        
      }
      if (filtroSelecionado != null) {

        const gruposAtivados = listaGruposDeProjecoes?.filter(filterByActive);
        if (gruposAtivados.length === 0)
        {
          store.dispatch(
            alertaExibir({
              tipo: "warning",
              mensagem: "Nenhum grupo filtrado."
            })
          );
        }
        else{
          dadosFiltrados = gruposAtivados;
        }
      }
      setGruposDeProjecoes(dadosFiltrados);
      
    } 
  }

  const handleClickFiltroSelecionado = (event) => {
    const val = filtroSelecionado === event ? null : event;
    setFiltroSelecionado(val);
  };

  const handleClickAtivarAgora = async () => {
    handleFiltrarGruposDeProjecoes();
  };

  const obterGruposDeProjecoes = async () => {
    try {
      setcarregandoGrupos(true);
      const lista = await listarGruposProjecoes();
      if (lista?.status === 200 && lista?.data) {
        const gruposnaoexcluidos = lista?.data.filter(w=> {return w.codigoStatusGrupo != 'EXCLUIDO'});
        setGruposDeProjecoes(gruposnaoexcluidos)
        setlistaGruposDeProjecoes(gruposnaoexcluidos)
      } else {
        setGruposDeProjecoes([]);
      }
      setcarregandoGrupos(false);
    } catch (error) {
      console.info(error);
      setcarregandoGrupos(false);
    }
  }

  const obterValoresFiltro = async () => {
    const retorno = await listarFiltrarPor();
    setValoresFiltro (retorno);
  }

  const onClickNovoGrupoProjecoes = () => {
    history.push(`${RotasDTO.GrupoProjecoes}`);
  };
  
  const onClickEditar = async (id) => {
    console.info(`${RotasDTO.GrupoProjecoes}/${id}`);
    history.push(`${RotasDTO.GrupoProjecoes}/${id}`);
  }

  const onClickVisualizar = async (id,nome,cenario,idcenario) => {
  history.push(`${RotasDTO.ListaProjecoesDeGrupo}/${id}/${nome}/${cenario}/${idcenario}`);
  }

  const onClickRelatorios = async (id,nome,cenario,idcenario) => {
    history.push(`${RotasDTO.GrupoProjecaoRelatorioListar}/${id}/${nome}/${cenario}/${idcenario}`);
    }  

  const onClickExcluir = async (grp) => {

    if(!!grp)
    {
      if(grp.codigoStatusGrupo === "CONCLUIDO") {
        setMsgExclusao("Não é possível excluir um grupo concluído");
        setModalInfo(true);
      }
      else
      {
        setIdGrupo(grp.idGrupoProjecoes);
        setModalExclusao(true);
      }
    }

  }

  const handleExclusao = async () => {
    const response = await excluirGrupo(idgrupo, usuarioGlobal.usuario.id, usuarioGlobal.usuario.nome);

    if (response.status === 200)
    {
      store.dispatch(
        alertaExibir({
          tipo: "success",
          mensagem: "Grupo excluído com sucesso."
        })
      );
      obterGruposDeProjecoes();
    }
    else
    {
      setMsgExclusao(response.data);
      setModalInfo(true);
    }
    setModalExclusao(false);    
  }

  useEffect(() => {
    if (!gruposDeProjecoes?.length) obterGruposDeProjecoes();
  }, [gruposDeProjecoes.length]);

  useEffect(() => {
    setTimeout(() => {
      handleFiltrarGruposDeProjecoes();
    }, 100);
  }, [stringBusca]);

  useEffect(() => {
    if(valoresfiltro == null) obterValoresFiltro();
  }, [valoresfiltro])

  return (
    <Loader loading={carregandoGrupos} className="w-auto">

      <ModalInformacao
        titulo={"Validação da exclusão"}
        exibir={modalInfo}
        mensagem={msgExclusao}
        onClose={() => {
          setModalInfo(false);
        }}
        showBotao={false}
      />

      <ModalConfirmacao
        item={modalExclusao}
        onConfirmar={handleExclusao}
        mensagem={"Tem certeza que deseja excluir esse Grupo de Projeções?"}
        onCancelar={() => setModalExclusao(false)}
      />
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={9}>
           <MaterialInputTexto
            type="text"
            id="textoBusca"
            name="textoBusca"
            label="Buscar"
            renderIconShowHide
            searchAdornment
            defaultValue={textoBusca ?? ""}
            ref={register}
            className={classes.search}
            permiteValorBranco
          />
        </Grid>
        <Grid item xs={3} className={classes.itemFiltro}>

        <BotaoFiltroOrdenacao
            type="button"
            color={Base.GreyWater}
            background="transparent"
            label="Filtrar"
            icon={<TuneIcon />}
            className={classes.buttonFiltro}
            ordenadorUm="Filtrar"
            ordemSelecionada=""
            filtros={valoresfiltro}
            filtrosSelecionados={filtroSelecionado}
            onClickFiltro={handleClickFiltroSelecionado}
            onClickAtivarAgora={handleClickAtivarAgora}
          />
        </Grid>
        <Grid container>
        <Grid item xs={7}>
        </Grid> 
        </Grid>
        <Grid item xs={12}>
          <BotaoCadastro
            label="Novo Grupo de Projeções"
            color={Base.White}
            onClick={onClickNovoGrupoProjecoes}
          />

          

        </Grid>
        {gruposDeProjecoes.length > 0 && gruposDeProjecoes.map((grupo) => {
          return (
            <ItemListaExpansivel
              key={shortid.generate()}
              label={`${grupo?.titulo}  ${Boolean(grupo?.isOfficialNinja) ? "(Oficial)" : ""}`}
              labelColor = {grupo.codigoStatusGrupo === "CONCLUIDO" ? Base.Grey : Base.White}
              color={grupo.falhas == 0 ? Base.GreenFern : grupo.falhas > 0 ? Base.RedError : Base.BlackBastille}
              visualizacao={!!grupo.idGrupoProjecoes}
              edicao={!!grupo.idGrupoProjecoes}
              detalhe={!!grupo.idGrupoProjecoes}
              relatorios={!!grupo.idGrupoProjecoes}
              exclusao={!!grupo.idGrupoProjecoes}
              onClickVisualizar={() => onClickVisualizar(grupo.idGrupoProjecoes, grupo.titulo,grupo.nomeCenario,grupo.idCenario)}
              onClickEditar={() => onClickEditar(grupo.idGrupoProjecoes)}
              onClickExcluir={() => onClickExcluir(grupo)}
              onClickRelatorios={() => onClickRelatorios(grupo.idGrupoProjecoes, grupo.titulo,grupo.nomeCenario,grupo.idCenario)}
              ativo={true}
            >
              <div>

              <Grid spacing={1}>
                  
              <Grid container spacing={2}>  
                    <Grid item sm={4}>
                      <strong>Cenário: </strong>  
                    </Grid>          
                    <Grid item sm={8}>
                    {grupo?.nomeCenario} 
                    </Grid>                    
                  </Grid>                   

                  <Grid container spacing={2}>  
                    <Grid item sm={4}>
                      <strong>Descrição: </strong>  
                    </Grid>          
                    <Grid item sm={8}>
                    {grupo?.descricao}
                    </Grid>                    
                  </Grid> 

                </Grid>     
              </div>
            </ItemListaExpansivel>
          )
        })}
      </Grid>
    </Loader>
  );
}

export default GruposProjecoes;
