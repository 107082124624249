import PropTypes from "prop-types";
import React, { useState } from "react";

// Componentes
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse, Grid } from "@mui/material";
import DataTable from "react-data-table-component";
import { meses } from "../../meses";
import InputEditavel from "../InputEditavel";
import PrecosCabecalho from "../PrecosCabecalho";
import TabelaSemDados from "../TabelaSemDados";

// Styles
import { customStylesLinhas, customStylesMedia, useStyles } from "./style";

const TabelaPrecoAtual = ({
  linhas,
  salvarPrecos,
  onEditar,
  erro,
  index,
  editarInputs,
  itemAberto,
  setItemAberto
}) => {
  const classes = useStyles();
  const [name, setName] = useState("");

  const colunas = [
    {
      name: <div className={classes.tabelaPrecosCelula}>Mês/Ano</div>,
      selector: (row) => row.mes
    },
    {
      name: <PrecosCabecalho swapI1 />,
      selector: (row) => row.preco
    },
    {
      name: <div className={classes.tabelaPrecosCelula}>swap I5</div>,
      selector: (row) => row.swapI5
    },
    {
      name: <div className={classes.tabelaPrecosCelula}>swap I1</div>,
      selector: (row) => row.swapI1
    }
  ];

  const linhasHeader = [
    {
      mes: <div className={classes.tabelaPrecosCelula}>Mês/Ano</div>,
      preco: <PrecosCabecalho />,
      swapI5: <div className={classes.tabelaPrecosCelula}>swap I5</div>,
      swapI1: <div className={classes.tabelaPrecosCelula}>swap I1</div>
    }
  ];

  const retornarMes = (mesValor) => {
    const mesNome = meses.find((mes) => mes.valor === mesValor);

    return mesNome?.nome ?? "";
  };

  // eslint-disable-next-line no-shadow
  const salvarPreco = (valor, index, campo) => {
    const linhasCopia = linhas;
    linhasCopia[index][campo] =
      valor || valor === 0
        ? parseFloat(String(valor)?.replace(",", "."))
        : null;

    if (campo === "precoConv" && valor) {
      linhasCopia[index].precoI1 =
        parseFloat(String(valor)?.replace(",", ".")) +
        linhasCopia[index].swapI1;
      linhasCopia[index].precoI5 =
        parseFloat(String(valor)?.replace(",", ".")) +
        linhasCopia[index].swapI5;
    }
    if (campo === "swapI1" && valor) {
      linhasCopia[index].precoI1 =
        parseFloat(String(valor)?.replace(",", ".")) +
        linhasCopia[index].precoConv;
    }
    if (campo === "swapI5" && valor) {
      linhasCopia[index].precoI5 =
        parseFloat(String(valor)?.replace(",", ".")) +
        linhasCopia[index].precoConv;
    }
    salvarPrecos(linhasCopia);

    setName("");
    onEditar(false);
  };

  const gerarLinhas = (dados) => {
    let dadosFormatados = [];

    if (dados?.length > 0) {
      // eslint-disable-next-line no-shadow
      dadosFormatados = dados.map((item, index) => {
        return {
          mes: `${retornarMes(item.mes)}/${item.ano}`,
          preco: (
            <div className={classes.inputGrupo}>
              <div className={classes.inputPadding}>
                <InputEditavel
                  id={`precoConv-${item.ano}${index}`}
                  name="precoConv"
                  valorInput={item.precoConv}
                  onClick={(valor) => {
                    salvarPreco(valor, index, "precoConv");
                  }}
                  editar={() => {
                    setName(`precoConv-${item.ano}-${index}`);
                    onEditar(true);
                  }}
                  bloqueado={item.bloqueado}
                  disabled={!(name === `precoConv-${item.ano}-${index}`)}
                  erro={
                    (!item.bloqueado && erro && item.precoConv === null) ||
                    (erro && name === `precoConv-${item.ano}-${index}`)
                  }
                  editarInputs={!editarInputs}
                />
              </div>
              <div className={classes.inputPadding}>
                <InputEditavel
                  id={`precoI5-${item.ano}${index}`}
                  name="precoI5"
                  valorInput={item.precoI5}
                  onClick={(valor) => {
                    salvarPreco(valor, index, "precoI5");
                  }}
                  bloqueado
                  editar={() => {
                    setName(`precoI5-${item.ano}-${index}`);
                    onEditar(true);
                  }}
                  disabled={!(name === `precoI5-${item.ano}-${index}`)}
                  erro={
                    (!item.bloqueado && erro && item.precoI5 === null) ||
                    (erro && name === `precoI5-${item.ano}-${index}`)
                  }
                  editarInputs={!editarInputs}
                />
              </div>
              <div className={classes.inputPadding}>
                <InputEditavel
                  id={`precoI1-${item.ano}${index}`}
                  name="precoI1"
                  valorInput={item.precoI1}
                  onClick={(valor) => {
                    salvarPreco(valor, index, "precoI1");
                  }}
                  bloqueado
                  editar={() => {
                    setName(`precoI1-${item.ano}-${index}`);
                    onEditar(true);
                  }}
                  disabled={!(name === `precoI1-${item.ano}-${index}`)}
                  erro={
                    (!item.bloqueado && erro && item.precoI1 === null) ||
                    (erro && name === `precoI1-${item.ano}-${index}`)
                  }
                  editarInputs={!editarInputs}
                />
              </div>
            </div>
          ),
          swapI5: (
            <div className={classes.inputPadding}>
              <InputEditavel
                id={`swapI5-${item.ano}${index}`}
                name="swapI5"
                valorInput={item.swapI5}
                onClick={(valor) => {
                  salvarPreco(valor, index, "swapI5");
                }}
                bloqueado={item.bloqueado}
                editar={() => {
                  setName(`swapI5-${item.ano}-${index}`);
                  onEditar(true);
                }}
                disabled={!(name === `swapI5-${item.ano}-${index}`)}
                erro={
                  (!item.bloqueado && erro && item.swapI5 === null) ||
                  (erro && name === `swapI5-${item.ano}-${index}`)
                }
                editarInputs={!editarInputs}
              />
            </div>
          ),
          swapI1: (
            <div className={classes.inputPadding}>
              <InputEditavel
                id={`swapI1-${item.ano}${index}`}
                name="swapI1"
                valorInput={item.swapI1}
                className={classes.inputPadding}
                onClick={(valor) => {
                  salvarPreco(valor, index, "swapI1");
                }}
                bloqueado={item.bloqueado}
                editar={() => {
                  setName(`swapI1-${item.ano}-${index}`);
                  onEditar(true);
                }}
                disabled={!(name === `swapI1-${item.ano}-${index}`)}
                erro={
                  (!item.bloqueado && erro && item.swapI1 === null) ||
                  (erro && name === `swapI1-${item.ano}-${index}`)
                }
                editarInputs={!editarInputs}
              />
            </div>
          )
        };
      });
    }

    return dadosFormatados;
  };

  const formatarPreco = (media) => {
    if (typeof media === "number") {
      return `R$ ${String(media.toFixed(2)).replace(".", ",")}`;
    }

    return media;
  };

  // eslint-disable-next-line consistent-return
  const gerarMedias = (dados) => {
    if (dados?.length) {
      let precoConv = 0;
      let precoI5 = 0;
      let precoI1 = 0;
      let swapI5 = 0;
      let swapI1 = 0;

      dados.forEach((item) => {
        precoConv += item.precoConv === null ? 0 : item.precoConv;
        precoI5 += item.precoI5 === null ? 0 : item.precoI5;
        precoI1 += item.precoI1 === null ? 0 : item.precoI1;
        swapI5 += item.swapI5 === null ? 0 : item.swapI5;
        swapI1 += item.swapI1 === null ? 0 : item.swapI1;
      });

      const medias = [
        {
          mes: `Média ${dados[0].ano}`,
          preco: (
            <div className={classes.mediaPrecos}>
              <span>
                {formatarPreco(
                  precoConv /
                    dados.filter(
                      (item) => item.precoConv !== null && item.precoConv !== 0
                    ).length || 0
                )}
              </span>
              <span>
                {formatarPreco(
                  precoI5 /
                    dados.filter(
                      (item) => item.precoI5 !== null && item.precoI5 !== 0
                    ).length || 0
                )}
              </span>
              <span>
                {formatarPreco(
                  precoI1 /
                    dados.filter(
                      (item) => item.precoI1 !== null && item.precoI1 !== 0
                    ).length || 0
                )}
              </span>
            </div>
          ),
          swapI5: formatarPreco(
            swapI5 /
              dados.filter((item) => item.swapI5 !== null && item.swapI5 !== 0)
                .length || 0
          ),
          swapI1: (
            <div className={classes.mediaSwapI1}>
              <span>
                {formatarPreco(
                  swapI1 /
                    dados.filter(
                      (item) => item.swapI1 !== null && item.swapI1 !== 0
                    ).length || 0
                )}
              </span>
              {itemAberto ? <ExpandLess /> : <ExpandMore />}
            </div>
          )
        }
      ];

      return medias;
    }
  };

  return (
    <div className={classes.tabelaPaginada}>
      <div style={{ flexGrow: 1, overflowX: "scroll" }} className="mb-1">
        <Grid container>
          {index === 0 && (
            <DataTable
              columns={colunas}
              data={linhasHeader}
              customStyles={customStylesLinhas}
              noHeader
              noTableHead
              responsive={false}
            />
          )}

          {itemAberto === index && (
            <Collapse
              in={itemAberto === index}
              className="w-100"
              disableStrictModeCompat
            >
              <DataTable
                columns={colunas}
                data={gerarLinhas(linhas)}
                customStyles={customStylesLinhas}
                noHeader
                noTableHead={index === 0}
                responsive={false}
              />
            </Collapse>
          )}

          <Grid
            container
            onClick={() =>
              itemAberto === index ? setItemAberto(-1) : setItemAberto(index)
            }
          >
            <DataTable
              columns={colunas}
              data={gerarMedias(linhas)}
              customStyles={customStylesMedia}
              noDataComponent={<TabelaSemDados />}
              noHeader
              noTableHead
              responsive={false}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

TabelaPrecoAtual.propTypes = {
  linhas: PropTypes.oneOfType([PropTypes.array]).isRequired,
  salvarPrecos: PropTypes.func,
  index: PropTypes.number,
  erro: PropTypes.bool,
  editarInputs: PropTypes.bool,
  onEditar: PropTypes.func,
  itemAberto: PropTypes.number,
  setItemAberto: PropTypes.func
};

TabelaPrecoAtual.defaultProps = {
  salvarPrecos: () => {},
  onEditar: () => {},
  erro: false,
  index: 0,
  editarInputs: false,
  itemAberto: -1,
  setItemAberto: () => {}
};

export default TabelaPrecoAtual;
