import RetornoEndpointDto from "../../../../global/dto/retornoEndpointDto";
import { store } from "../../../../global/redux";
import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";
import {
  obterPorIdUnidade,
  salvarMedidorGD,
  uploadMultiplo
} from "../../../../servicos/medidorGDServico";

export default class CadastroMedidorGdHelper {
  static async salvarMedidorGD(id, body, setCarregandoSalvar) {
    try {
      setCarregandoSalvar(true);
      const resultado = await salvarMedidorGD(id, body);

      return RetornoEndpointDto.Sucesso(
        `Medidor Gd ${id ? "atualizado" : "criado"} criado com sucesso`,
        resultado.data
      );
    } catch (error) {
      if (error.response?.status === 409) {
        return RetornoEndpointDto.Erro("medidorExists");
      }
      return RetornoEndpointDto.Erro(
        `Erro ao ${id ? "atualizar" : "criar"} medidor gd!`
      );
    } finally {
      setCarregandoSalvar(false);
    }
  }

  static async obterMedidorPorUnidade(id, setCarregandoSalvar) {
    try {
      setCarregandoSalvar(true);
      const resultado = await obterPorIdUnidade(id);

      return RetornoEndpointDto.Sucesso(
        "Medidores listados por unidade com sucesso.",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Não foi possivel obter a lista de medidores por unidade."
      );
    } finally {
      setCarregandoSalvar(false);
    }
  }

  static async uploadMultiplo(codigo, listaArquivos, setCarregandoUpload) {
    try {
      setCarregandoUpload(0);
      const resultado = await uploadMultiplo(codigo, listaArquivos);

      return RetornoEndpointDto.Sucesso(
        "Upload realizado com sucesso.",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Não foi possivel realizar upload dos arquivos."
      );
    } finally {
      setCarregandoUpload(0);
    }
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }

  static exibirSucesso(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem
      })
    );
  }
}
