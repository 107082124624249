import React, { useState } from 'react';
import { Card, Grid } from "@mui/material";
import { useForm } from 'react-hook-form';
import { useStyles } from './style';
import UploadDropArea from 'componentes/upload';
import Botao from 'componentes/botao';
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import ResultadoOperacao from 'componentes/resultadoOperacao';
import { Item } from 'componentes/treeView/treeViewItem/style';
import { values } from 'lodash-es';
import CloseIcon from "@mui/icons-material/Close";
import RotasDTO  from "../../../../rotas/rotasUrlDto";  
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { uploadPlanilhaProret } from '../../../../servicos/percentuaisRegulatorios';

const ProretImportar = () => {
  const classes = useStyles();
  const usuarioGlobal = useSelector((state) => state.usuario);
  const history = useHistory();

  const msgPadraoUpload = "Solte o arquivo para anexá-lo ou procurar";
  
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(msgPadraoUpload);
  const [carregandoArquivo, setCarregandoArquivo] = useState(false);
  const [progressMesage, setProgressMessage] = useState("");
  const [resultadoOperacao, setResultadoOperacao] = useState(false);
  const [resultadoOperacaoCabecalho, setResultadoOperacaoCabecalho] = useState("");
  const [resultadoOperacaoObj, setResultadoOperacaoObj] = useState({
    "mensagens": []
  });

  const onFechar = () => {
    history.push(`${RotasDTO.ProjecaoInsumoListar}`);
  }

  const onCancelar = () => {
    setFile(false);
    setFileName(msgPadraoUpload);
    setCarregandoArquivo(false);
    setResultadoOperacao(false);
    setResultadoOperacaoCabecalho("");
    setResultadoOperacaoObj({
      "mensagens": []
    });
  }

  const { handleSubmit } = useForm({
    reValidateMode: 'onSubmit',
  });

  const EnviarForm = async () => {
    try {
      if (!file) {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "Por favor, selecione o arquivo desejado."
          })
        )
      } else {
        setCarregandoArquivo(true);
        setResultadoOperacao(false);
        setProgressMessage("Importando o arquivo... Por favor, aguarde.");

        const upload = await uploadPlanilhaProret(file, usuarioGlobal?.usuario?.id, usuarioGlobal?.usuario?.usuarioNome);

        let mensagemRetorno = [""];

        if (upload.status === 200 && upload?.data) {
          const dadosRetorno = upload?.data;
          mensagemRetorno = ["O processo de Importação do arquivo foi finalizado."];

          if(dadosRetorno?.DistribuidorasNaoImportadas.length > 0){
            for (let i = 0; i < dadosRetorno?.DistribuidorasNaoImportadas.length; i++) {
              mensagemRetorno.push(dadosRetorno?.DistribuidorasNaoImportadas[i]); 
            } 
          }
        }
        else if(upload.status === 202 && upload?.data)
        {
          console.dir(upload.data);
          mensagemRetorno = [`A importação não foi executada. Ocorreu a seguinte falha: ${upload?.data[0].Mensagem}`];
        }

        setFile(false);
        setFileName(msgPadraoUpload);
        setCarregandoArquivo(false);
        setResultadoOperacao(true);
        setResultadoOperacaoCabecalho(`Percentuais Regulatórios - Importação do arquivo ${file?.name}`)
        setResultadoOperacaoObj({"mensagens": mensagemRetorno });      
        
      }
    } catch (error) {
      setFileName(msgPadraoUpload);
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  }

  const aoEnviarForm = async () => {
    if (file) {
      EnviarForm();
    }
  };

  const processarArquivo = (arquivo) => {
    if (arquivo != null && arquivo[0] != null) {
      setFile(arquivo[0].file);
      setFileName(arquivo[0].file.name);
    }
  };

  const onAddUpload = (arquivo) => {
    processarArquivo(arquivo);
  };

  return (
    <form className="needs-validation" onSubmit={handleSubmit(aoEnviarForm)}>
      <ResultadoOperacao
        item={resultadoOperacaoObj}
        cabecalhoResultado={resultadoOperacaoCabecalho}
        onClose={() => onFechar()}
      />
      {resultadoOperacao === false ?
        <Card className={classes.container}>
          <Grid container className={classes.containerTitulo}>
            <Grid item xs={11} className="font-weight-bold">
              Percentuais RI
            </Grid>
            <Grid item xs={1} >             
              <CloseIcon onClick={onFechar} className={classes.icon}/>
            </Grid>   
          </Grid>
          {carregandoArquivo === false ?
            <Card className={classes.container}>
              <Grid container spacing={4} p={2} className={classes.container}>
                <Grid item sm={12}>
                  <UploadDropArea
                    onAdd={onAddUpload}
                    dropAreaText={fileName}
                    showLoader={false}
                    acceptedFiles={[
                      '.xlsm,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
                    ]}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4} p={2} className={classes.container} justifyContent="space-between">
                <Grid item sm={3}>
                  <Botao
                    type="button"
                    label="Cancelar"
                    onClick={onCancelar}
                    className={classes.customButton}
                  />
                </Grid>
                <Grid item sm={3}>
                  <Botao
                    type="submit"
                    label="Importar"
                    className={classes.button}
                  />
                </Grid>
              </Grid>
            </Card>
            :
            <Card className={classes.container}>
              <Grid container spacing={4} p={2} className={classes.container}>
                <Grid item sm={12} className={classes.fileUpload}>
                  <Grid item sm={12} style={{ textAlign: "center" }}>
                    {progressMesage}
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          }
        </Card>
      : ""
      }
    </form>
  );
}

export default ProretImportar;