import { makeStyles } from '@mui/styles';
import styled from "styled-components";
import { Base } from 'componentes/cores';
import theme from 'themes';

export const Tabela = styled.table`
  width: 100%;
`;

Tabela.Thead = styled.thead` background: #DCDCDC; `;

Tabela.Tr = styled.tr``;

Tabela.Th = styled.th`
  padding: 4px;
  border: 4px solid white;
  text-align: center;
`;

Tabela.Tbody = styled.tbody` background: #F0F0F0; `;

Tabela.Td = styled.td`
  padding: 2px;
  border: 2px solid white;
  text-align: center;
`;

Tabela.Tfoot = styled.tfoot``;

export const TabelaScroll = styled.div`
  display: flex;
  border: 1px solid #DCDCDC;
  padding: 10px;
  width: 100%;

  div {
    display: flex;
    overflow-y: auto;
  }

  #scroll {
    padding-bottom: 10px;
    width: 100%;

    ::-webkit-scrollbar {
      height: 6px;
      background-color: #F5F5F5;
    }

    ::-webkit-scrollbar-track {
      background-color: #F5F5F5;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #DCDCDC;
      border-radius: 10px;
    }
  }
`;

export const useStyles = makeStyles(() => ({
  container: {
    margin: 0,
    width: '100%',
    "& .MuiGrid-root": {
      color: `${Base.BlackBastille} !important`
    },
    "& .MuiDropzoneArea-text": {
      color: `${Base.BlackBastille} !important`
    },
    "& .MuiInputBase-root": {
      height: "46px"
    },
    "& .MuiFormLabel-root": {
      lineHeight: "1"
    }
  },
  button: {
    fontSize: '18px',
  },
  containerTitulo: {
    padding: '18px !important',   
  },
  titulo: {
    fontSize: '18px',
    color: '#FFF !important',
    fontFamily: theme.font.family,
    fontWeight:'700'
  },
  containerData: {
    padding: '32px !important'
  },
  icon: {
    cursor: "pointer",
    float:"right",
    margin: "5px",
    color: '#FFF !important'
  },
  containerData: {
    padding: '32px !important'
  },
}));