import RetornoEndpointDto from "../../../global/dto/retornoEndpointDto";
import { ListarPaginado } from "../../../servicos/agenteServico";

export default class AgenteHelper {
  static async listarAgentes(filtro) {
    try {
      const resultado = await ListarPaginado(filtro);

      return RetornoEndpointDto.Sucesso(
        "Agentes obtidos com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu um erro ao obter a lista de agentes"
      );
    }
  }
}
