import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Card, Grid } from "@mui/material";
import { cnpj, cpf } from "cpf-cnpj-validator";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import MaterialInputTextoMoeda from "componentes/inputTexto/materialInputMoeda";
import ModalMensagemErro from "../../../componentes/ModalMensagemErro";
import CheckInputs from "../../../componentes/checkInputs";
import {
  Alerta,
  BotaoCadastro,
  BotaoRetornarListagem,
  InputRadioGroup,
  Loader,
  SelectArredondado
} from "../../../componentes";
import MaterialInputTexto from "../../../componentes/inputTexto/materialInput";
import MaterialInputTelefone from "../../../componentes/inputTexto/materialInputTelefone";
import MaterialInputMascara from "../../../componentes/inputTextoMascara";
import ModalInformacao from "../../../componentes/modalVerificacao";
import {
  estadoCivilList,
  unidadesFederativas
} from "../../../global/constantes";
import { handleLoading } from "../../../global/redux/modulos/loader/actions";
import { useStyles } from "./style";
import BotaoForm from "../../../componentes/botaoForm";
import { Base } from "../../../componentes/cores";
import CustomLoader from "../../../componentes/customLoader";
import ModalLuz from "../../../componentes/modalLuz";
import { InterfaceDTO } from "../../../global/dto/interfacesDto";
import { RotasDTO } from "../../../global/rotas/rotasUrlDto";
import { existeItemDuplicados } from "../../../global/utils/itensDuplicadosArray";
import { usuarioPossuiFuncionalidade } from "../../../servicos/funcionalidadesServico";
import {
  formatarBrasileira,
  removeMaskCep,
  removeMaskCpfCnpj,
  removeMaskRG,
  removeMaskTelefone,
  validacaoTrimObject
} from "../../../servicos/utils";
import DadosEmpresaACLHelper from "./helper";
import enumeradorBotao from "../ListagemCotacoes/enumeradores/enumeradorBotao";

const DadosEmpresaACL = () => {
  const classes = useStyles();
  const { register, errors, handleSubmit, clearErrors, setValue } = useForm({
    reValidateMode: "onSubmit"
  });
  const dispatch = useDispatch();
  const [modalInfo, setModalInfo] = useState(false);
  const [uf, setUF] = useState(null);
  const [cep, setCep] = useState(null);
  const [carregandoCep, setCarregandoCep] = useState(false);
  const [endereco, setEndereco] = useState(null);
  const [numero, setNumero] = useState(null);
  const [complemento, setComplemento] = useState(null);
  const [bairro, setBairro] = useState(null);
  const [cidade, setCidade] = useState(null);
  const [nomeContatoInicial, setNomeContatoInicial] = useState(null);
  const [rgContatoInicial, setRgContatoInicial] = useState(null);
  const [cpfContatoInicial, setCpfContatoInciial] = useState(null);
  const [emailContatoInicial, setEmailContatoInicial] = useState(null);
  const [telefoneContatoInicial, setTelefoneContatoInicial] = useState(null);
  const [limparCpf, setLimparCpf] = useState(false);
  const [habilitaRepresentante, setHabilitaRepresentante] = useState(false);
  const [
    habilitaSegundoRepresentante,
    setHabilitaSegundoRepresentante
  ] = useState(false);

  const [dadosRepresentante, setRepresentante] = useState({});
  const [dadosRepresentante2, setRepresentante2] = useState({});
  const [representanteDuplicado, setRepresentanteDuplicado] = useState(false);
  const [bloquearRepresentantesContato] = useState(false);
  const usuario = useSelector((state) => state.usuario);
  const [cnpjCliente, setCnpjCliente] = useState(null);
  const [nomeEmpresa, setNomeEmpresa] = useState(null);
  const history = useHistory();
  const [dadosInicial, setDadosInicial] = useState({});
  const [dadosDistribuidora, setDadosDistribuidora] = useState({});
  const [carregandoNomeFantasia, setCarregandoNomeFantasia] = useState(false);
  const [cupom, setCupom] = useState(null);
  const [nacionalidade, setNacionalidade] = useState(null);
  const [estadoCivilContatoInicial, setEstadoCivilContatoInicial] = useState(
    null
  );
  const [estadoCivilRepresentante, setEstadoCivilRepresentante] = useState(
    null
  );
  const [estadoCivilRepresentante2, setEstadoCivilRepresentante2] = useState(
    null
  );

  const [camposObrigatorios, setCamposObrigatorios] = useState(false);
  const [error, setError] = useState(false);
  const { id } = useParams();
  const [cotacaoId, setCotacaoId] = useState(null);
  const [enviarForm, setEnviarForm] = useState(true);
  const [dadosCotacao, setDadosCotacao] = useState({});
  const [representanteId, setRepresentanteId] = useState(0);
  const [representante2Id, setRepresentante2Id] = useState(0);
  const [exibirModalMensagem, setExibirModalMensagem] = useState(false);
  const [envelopeId, setEnvelopeId] = useState(null);
  const [numeroInstalacao, setNumeroInstalacao] = useState(null);
  const [exibirModalMensagemEditar, setExibirModalMensagemEditar] = useState(
    false
  );
  const [checked, setChecked] = useState(false);
  const [habilitaTestemunha, setHabilitaTestemunha] = useState(false);

  const [dadosTestemunha, setTestemunha] = useState({});
  const [estadoCivilTestemunha, setEstadoCivilTestemunha] = useState(null);
  const [mensagemDuplicidade, setMensagemDuplicidade] = useState("");
  const [garantia, setGarantia] = useState("Não se aplica");
  const [valorGarantia, setValorGarantia] = useState(null);
  const [testemunhaId, setTestemunhaId] = useState(0);
  const [mesGarantia, setMesGarantia] = useState(null);
  const [profissaoContatoInicial, setProfissaoContatoInicial] = useState(null);
  const [exibirModalExcluir, setExibirModalExcluir] = useState(false);
  const [tipoContratacao, setTipoContratacao] = useState("");
  const [statusVigenciaFechado, setStatusVigenciaFechado] = useState(false);
  const [modalParceiro, setModalParceiro] = useState(false);
  const [mensagemModalParceiro, setMensagemModalParceiro] = useState("");
  const [dadosOfficer, setDadosOfficer] = useState({});

  const rotas = useSelector((state) => state.rotas);
  const { menu } = usuario;

  const podeEditarNumeroInstalacao = usuarioPossuiFuncionalidade(
    menu,
    rotas.rotaAtual.caminho,
    RotasDTO.CotacaoACL,
    InterfaceDTO.PodeEditarNumeroInstalacaoACL
  );

  const habiltarEditar = useMemo(() => {
    return podeEditarNumeroInstalacao && checked;
  }, [podeEditarNumeroInstalacao, checked]);

  const formataValor = (valor) => {
    if (typeof valor === "number") return valor;
    if (!valor) return "";
    return valor
      ? parseFloat(
          String(valor)
            .trim()
            .replace(",00", "")
            .replace("R$", "")
            .replace(" ", "")
            .replaceAll(".", "")
            .replace(",", ".")
        )
      : valor;
  };

  const multiplicacaoValorGarantia = useMemo(() => {
    const multiplicacao =
      valorGarantia && mesGarantia
        ? formataValor(valorGarantia) * Number(mesGarantia)
        : 0;

    return formatarBrasileira(multiplicacao.toFixed(2)) || "";
  }, [valorGarantia, mesGarantia]);

  const obterCepCobertura = async (cepCobertura) => {
    const {
      data,
      sucesso,
      mensagem
    } = await DadosEmpresaACLHelper.obterCepCobertura(
      cepCobertura,
      setCarregandoCep
    );
    if (!sucesso) {
      DadosEmpresaACLHelper.exibirErro(mensagem);
      setCarregandoCep(false);
      setError(error);
      return;
    }

    setEndereco(data?.logradouro ?? dadosCotacao?.endereco);
    setComplemento(data?.complemento ?? dadosCotacao?.complemento);
    setBairro(data?.bairro ?? dadosCotacao?.bairro);
    setCidade(data?.cidade ?? dadosCotacao?.cidade);
    setUF(data?.uf ?? dadosCotacao?.uf);
    setNumero(dadosCotacao?.numero);
  };

  useEffect(() => {
    const {
      nomeRazaoSocial,
      clienteCnpj,
      // eslint-disable-next-line no-shadow
      cep,
      clienteEmail,
      clienteTelefone,
      envelopeId: envelopeContratoId,
      garantia,
      dadosPlanoSelecionado,
      mesGarantia
    } = history?.location?.state || {};
    setNomeEmpresa(nomeRazaoSocial);
    setCnpjCliente(clienteCnpj);
    setEmailContatoInicial(clienteEmail);
    setTelefoneContatoInicial(clienteTelefone);
    setEnvelopeId(envelopeContratoId);
    setCep(cep);
    setDadosInicial(history?.location?.state);
    setGarantia(garantia === "Não se aplica" ? garantia : "Garantia exigida");
    setValorGarantia(
      formatarBrasileira(
        dadosPlanoSelecionado?.dadosGarantia?.mediaCustoEnergia
      )
    );
    setMesGarantia(mesGarantia);
    setStatusVigenciaFechado(history?.location?.state?.status === "FE");
  }, [history]);

  useEffect(() => {
    if (!cotacaoId && cep) {
      obterCepCobertura(cep);
    }
  }, [cotacaoId, cep]);

  const onBlurCep = (valorCep) => {
    if (valorCep.length > 7) {
      obterCepCobertura(removeMaskCep(valorCep));
    }
  };

  const onClickNovoRepresentante = (event) => {
    if (statusVigenciaFechado) return;
    event.preventDefault();
    setCamposObrigatorios(true);

    if (!habilitaRepresentante) {
      setHabilitaRepresentante(true);
      setValue("estadoCivilRepresentante", null);
      setCamposObrigatorios(true);
    }

    if (habilitaRepresentante) {
      setHabilitaSegundoRepresentante(true);
      setValue("estadoCivilRepresentante2", null);
      setCamposObrigatorios(true);
    }
  };

  const excluirRepresentante = () => {
    if (statusVigenciaFechado) return;
    setHabilitaRepresentante(false);
    setCamposObrigatorios(false);
  };

  const excluirSegundoRepresentante = () => {
    if (statusVigenciaFechado) return;
    setHabilitaSegundoRepresentante(false);
    setCamposObrigatorios(false);
  };

  const validarRepresentantesDuplicados = (representantes) => {
    const uniqueEmail = representantes.map((r) => r.Email);
    const uniqueRG = representantes.map((r) => r.RG);
    const uniqueCPF = representantes.map((r) => r.CPF);

    let unique = false;
    if (
      existeItemDuplicados(uniqueEmail) ||
      existeItemDuplicados(uniqueRG) ||
      existeItemDuplicados(uniqueCPF)
    ) {
      unique = true;
    }
    return unique;
  };

  const criarLeadSalesForceServico = async (dados) => {
    const model = {
      id: dadosInicial?.salesForcesId,
      pessoaFisicaJuridica: "PJ",
      nome: dados?.razaoSocial ?? "",
      email: dados?.emailContatoInicial ?? "",
      telefone: removeMaskTelefone(dados?.telefoneContatoInicial) ?? "",
      cep: removeMaskCep(cep) ?? "",
      descontoInicial:
        parseFloat(
          (
            dadosInicial?.economiaConsolidada?.economia5Anos_percent * 100
          )?.toFixed(2)
        ) ?? 0,
      valorDaConta: dadosInicial?.valorMedioConta,
      reducaoDeCusto:
        dadosInicial?.economiaConsolidada?.economiaMensal4Anos_reais ?? 0,
      multaDistribuidora: false,
      preLeadContratado: false,
      erroLeituraFatura: dadosInicial?.isErro,
      consumoAbaixoRange: false,
      distribuidoraNaoAtendida: !dadosDistribuidora?.mlApp,
      contratoGerado: !!envelopeId,
      distribuidora: dadosInicial?.nomeDistribuidora ?? "",
      origemDaInformacao: "AreaLogada",
      dataDaCriacaoNoSalesforce: window.moment().format(),
      cupom: cupom ?? "",
      produto: "ACL",
      erroGenericoSimulacao: error,
      observacoes: "",
      cpfCnpj: cnpjCliente ?? "",
      numeroInstalacao: dados?.numeroInstalacao,
      nomeFantasia: dados?.razaoSocial ?? "",
      dataSimulacao: window.moment().format(),
      consumoUtilizado: dadosInicial?.consumoMedioAcl,
      consumoMensal: dadosInicial?.consumoMensal,
      proprietario: dadosInicial?.colaboradorId,
      empresaDoGrupo: process.env.REACT_APP_EMPRESA_GRUPO_API_URL,
      faturaProcessada: dadosInicial?.faturaProcessada,
      scoreSerasa: dadosInicial?.score ?? 0,
      descontoOfertado3Anos:
        parseFloat(
          (
            dadosInicial?.economiaConsolidada?.economia3Anos_percent * 100
          )?.toFixed(2)
        ) ?? 0,
      descontoOfertado4Anos:
        parseFloat(
          (
            dadosInicial?.economiaConsolidada?.economia4Anos_percent * 100
          )?.toFixed(2)
        ) ?? 0,
      descontoOfertado5Anos:
        parseFloat(
          (
            dadosInicial?.economiaConsolidada?.economia5Anos_percent * 100
          )?.toFixed(2)
        ) ?? 0,
      reducaoCusto5Anos:
        dadosInicial?.economiaConsolidada?.economiaMensal5Anos_reais ?? 0,
      reducaoCusto3Anos:
        dadosInicial?.economiaConsolidada?.economiaMensal3Anos_reais ?? 0
    };

    if (dadosInicial?.fileNameLead) {
      model.nomeDoAnexoComExtensao = dadosInicial?.fileNameLead;
      model.guidArquivoDaDigitalizacao = dadosInicial?.externoIdFatura;
    }

    if (!cotacaoId) {
      model.consumoHistorico = dadosInicial?.consumoHistorico;
    }

    const {
      sucesso,
      mensagem
    } = await DadosEmpresaACLHelper.criarLeadSalesForceServico(
      validacaoTrimObject(model),
      dadosInicial?.salesForcesId,
      handleLoading,
      dispatch
    );

    if (!sucesso) {
      DadosEmpresaACLHelper.exibirErro(mensagem);
      dispatch(handleLoading(false));
      setError(true);
      return;
    }

    history.push({
      pathname: `${RotasDTO.Cotacoes}`
    });
  };

  const atualizarCotacao = async (termo, dadosCotacaoInicial, dados) => {
    const model = {
      envelopeId: termo?.envelopeId
    };

    const { sucesso, mensagem } = await DadosEmpresaACLHelper.atualizarCotacao(
      dadosCotacaoInicial?.id,
      model,
      handleLoading,
      dispatch
    );

    if (!sucesso) {
      DadosEmpresaACLHelper.exibirErro(mensagem);
      dispatch(handleLoading(false));
      setError(true);
      return;
    }

    criarLeadSalesForceServico(dados);
  };

  const handleModalidade = (cor) => {
    switch (cor) {
      case "AZ":
        return "Azul";
      case "VE":
        return "Verde";
      default:
        return "";
    }
  };

  const handleTextoMes = (mes) => {
    switch (mes) {
      case 1:
        return `${mesGarantia} mês`;
      default:
        return `${mesGarantia} meses`;
    }
  };

  const criarTermoAdesaoAcl = async (
    dados,
    dadosCliente,
    dadosUnidade,
    dadosPlanos,
    dadosCotacaoInicial
  ) => {
    if (enviarForm) {
      const model = {
        idLeadPlanoSelecionado: dadosPlanos?.id ?? 0,
        leadClienteId: dadosCliente?.id ?? 0,
        cnpj: removeMaskCpfCnpj(cnpjCliente) ?? "",
        nomeFantasia: dados?.razaoSocial,
        razaoSocial: dados?.razaoSocial,
        cep: removeMaskCep(cep) ?? "",
        endereco: dados?.endereco ?? "",
        numero: dados?.numero ?? "",
        complemento: dados?.complemento ?? null,
        bairro: dados?.bairro ?? "",
        cidade: dados?.cidade ?? "",
        uf: dados?.uf ?? "",
        consumo: dadosInicial?.consumoMedio,
        demanda: dadosInicial?.demandaContratadaForaPonta,
        nomeCompleto: dados?.nomeContatoInicial ?? "",
        cpf: removeMaskCpfCnpj(dados?.cpfContatoInicial) ?? "",
        rg: removeMaskRG(dados?.rgContatoInicial) ?? "",
        email: dados?.emailContatoInicial ?? "",
        telefone: removeMaskTelefone(dados?.telefoneContatoInicial) ?? "",
        distribuidoraId:
          dadosDistribuidora?.id ?? dadosInicial?.distribuidoraId ?? 0,
        razaoSocialDistribuidora: dadosDistribuidora?.nomeFantasia ?? "",
        cnpjDistribuidora: dadosDistribuidora?.cnpj ?? "",
        reducaoDeCusto: 0,
        percentualDesconto: dadosInicial?.percentualDesconto,
        periodoPlano: dadosInicial?.periodoMeses,
        assinado: "N",
        inscricaoEstadual: "",
        inscricaoMunicipal: "",
        pessoaFisicaJuridica: "PJ",
        produtoId: 1,
        numeroInstalacao: dados?.numeroInstalacao,
        nacionalidade: dados?.nacionalidadeContatoInicial,
        estadoCivil: dados?.estadoCivilContatoInicial,
        profissao: dados?.profissaoContatoInicial,
        submercado: dadosInicial?.submercadoDescricao,
        demandaPonta: dadosInicial?.demandaContratadaPonta,
        classeTensao: dadosInicial?.nivelTensao,
        modalidadeTarifaria: handleModalidade(dadosInicial?.modalidade),
        icms: dadosInicial?.icms,
        garantia:
          garantia === "Não se aplica"
            ? "Não se aplica"
            : `O CLIENTE deverá prestar caução no montante correspondente a ${handleTextoMes(
                mesGarantia
              )} de faturamento da energia média contratada, devendo ser apresentado em até 15 (quinze) dias antes do início do fornecimento de energia.`,
        dataPrevistaMigracao:
          dadosInicial?.dadosPlanoSelecionado?.periodoEconomia?.inicio,
        dataFornecimentoAte:
          dadosInicial?.dadosPlanoSelecionado?.periodoEconomia?.fim,
        validadeProposta: dadosCotacaoInicial?.dataValidade,
        tipoContratacao: tipoContratacao || "",
        cotacaoInicialId: dadosCotacaoInicial?.id,
        preleadId: dadosInicial?.salesForcesId || ""
      };

      const {
        data,
        sucesso,
        mensagem
      } = await DadosEmpresaACLHelper.criarTermoAdesaoAcl(
        validacaoTrimObject(model),
        handleLoading,
        dispatch
      );
      if (!sucesso) {
        DadosEmpresaACLHelper.exibirErro(mensagem);
        dispatch(handleLoading(false));
        setError(true);
        return;
      }

      atualizarCotacao(data, dadosCotacaoInicial, dados);
      setTipoContratacao("");
    } else {
      criarLeadSalesForceServico(dados);
    }
  };

  const criarLeadPlanoselecionado = async (
    dados,
    dadosCliente,
    dadosUnidade,
    dadosCotacaoInicial
  ) => {
    const model = {
      leadUnidadeId: dadosUnidade?.id ?? 0,
      leadClienteId: dadosCliente?.id ?? 0,
      percentualDesconto: dadosInicial?.percentualDesconto,
      periodoMeses: dadosInicial?.periodoMeses,
      assinado: "N",
      demandaContratada: dadosInicial?.demandaContratadaForaPonta,
      consumoMedioConta: dadosInicial?.consumoMedio,
      valorMedioConta: dadosInicial?.valorMedioConta,
      demandaPonta: dadosInicial?.demandaContratadaPonta
    };

    const {
      data,
      sucesso,
      mensagem
    } = await DadosEmpresaACLHelper.criarLeadPlanoselecionado(
      model,
      handleLoading,
      dispatch,
      cotacaoId
    );
    if (!sucesso) {
      DadosEmpresaACLHelper.exibirErro(mensagem);
      dispatch(handleLoading(false));
      return;
    }

    criarTermoAdesaoAcl(
      dados,
      dadosCliente,
      dadosUnidade,
      data,
      dadosCotacaoInicial
    );
  };

  const criarLeadUnidadeConsumidora = async (
    dados,
    dadosCliente,
    dadosCotacaoInicial
  ) => {
    const model = {
      nomeUnidade: dados?.razaoSocial,
      nomeRazaoSocial: dados?.razaoSocial,
      classeTarifariaId: dadosInicial?.classeTarifariaId,
      distribuidoraId:
        dadosDistribuidora?.id ?? dadosInicial?.distribuidoraId ?? 0,
      nivelTensaoId: dadosInicial?.nivelTensaoId,
      clienteId: dadosCliente?.id ?? 0,
      cep: removeMaskCep(cep) ?? "",
      endereco: dados?.endereco ?? "",
      numero: dados?.numero ?? "",
      complemento: dados?.complemento ?? null,
      uf: dados?.uf ?? "",
      bairro: dados?.bairro ?? "",
      cidade: dados?.cidade ?? "",
      telefone: removeMaskTelefone(dados?.telefoneContatoInicial),
      status: "NI",
      cnpjCpf: removeMaskCpfCnpj(cnpjCliente) ?? "",
      inscricaoEstadual: "",
      inscricaoMunicipal: "",
      pessoaFisicaJuridica: "PJ",
      numeroInstalacao: dados?.numeroInstalacao,
      parcelaCarga: null,
      submercadoId: dadosInicial?.subMercadoId,
      dataPrevistaMigracao: dadosInicial?.dataMigracaoPrevisao,
      situacao: "AT",
      dataReferencia: window.moment().format(),
      demandaContratadaPonta: dadosInicial?.demandaContratadaPonta,
      demandaContratadaForaPonta: dadosInicial?.demandaContratadaForaPonta,
      icms: dadosInicial?.icms,
      tipoRepresentacaoId: 4,
      cnpjDoLead: removeMaskCpfCnpj(cnpjCliente) ?? "",
      idDaUnidadeNoSFParaIntegracao: "",
      origem: "AreaLogada",
      diaFaturamentoDistribuidora: null,
      cupom: dadosInicial?.usuarioCupom ?? cupom ?? "",
      cosip: dadosInicial?.cosip,
      numerofasesId: 3,
      consumoKwh: dadosInicial?.consumoMedio,
      produtoId: 1,
      cnae: null
    };

    if (!cotacaoId) {
      model.consumoHistorico = dadosInicial?.consumoHistorico;
      model.guidFatura = dadosInicial?.salesForcesId;
    }
    const {
      data,
      sucesso,
      mensagem
    } = await DadosEmpresaACLHelper.criarLeadUnidadeConsumidora(
      validacaoTrimObject(model),
      handleLoading,
      dispatch,
      cotacaoId
    );
    if (!sucesso) {
      DadosEmpresaACLHelper.exibirErro(mensagem);
      dispatch(handleLoading(false));
      setError(true);
      return;
    }

    criarLeadPlanoselecionado(dados, dadosCliente, data, dadosCotacaoInicial);
  };

  const criarLeadClienteTestemunha = async (
    dados,
    dadosCliente,
    dadosCotacaoInicial
  ) => {
    const model = {};

    if (dados?.nomeTestemunha) {
      model.id = testemunhaId;
      model.nomeCompleto = dados?.nomeTestemunha || "";
      model.cpf = removeMaskCpfCnpj(dados?.cpfTestemunha) || "";
      model.rg = removeMaskRG(dados?.rgTestemunha) || "";
      model.email = dados?.emailTestemunha || "";
      model.telefone = removeMaskTelefone(dados?.telefoneTestemunha) || "";
      model.leadClienteId = dadosCliente?.id;
      model.contato = false;
      model.nacionalidade = dados?.nacionalidadeTestemunha || "";
      model.estadoCivil = dados?.estadoCivilTestemunha || "";
      model.profissao = dados?.profissaoTestemunha || "";
      model.clienteCnpjCpf = removeMaskCpfCnpj(dadosCliente?.cnpjCpf) || "";
    }

    if (Object.keys(model).length > 0) {
      const {
        sucesso,
        mensagem
      } = await DadosEmpresaACLHelper.criarLeadClienteTestemunha(
        validacaoTrimObject(model),
        handleLoading,
        dispatch,
        testemunhaId
      );

      if (!sucesso) {
        DadosEmpresaACLHelper.exibirErro(mensagem);
        dispatch(handleLoading(false));
        setError(true);
        return;
      }
    }

    criarLeadUnidadeConsumidora(dados, dadosCliente, dadosCotacaoInicial);
  };

  const criarLeadCliente = async (dados, dadosCotacaoInicial, dadosArvore) => {
    const representantes = [];

    if (dados?.nomeContatoRepresentante)
      representantes.push({
        id: representanteId,
        NomeCompleto: dados?.nomeContatoRepresentante,
        Email: dados?.emailContatoRepresentante,
        CPF: removeMaskCpfCnpj(dados?.cpfRepresentante),
        RG: removeMaskRG(dados?.rgRepresentante),
        Telefone: removeMaskTelefone(dados?.telefoneRepresentante),
        nacionalidade: dados?.nacionalidadeRepresentante,
        estadoCivil: dados?.estadoCivilRepresentante,
        profissao: dados?.profissaoRepresentante,
        clienteCnpjCpf: removeMaskCpfCnpj(cnpjCliente) || ""
      });

    if (dados?.nomeContatoRepresentante2)
      representantes.push({
        id: representante2Id,
        NomeCompleto: dados?.nomeContatoRepresentante2,
        Email: dados?.emailContatoRepresentante2,
        CPF: removeMaskCpfCnpj(dados?.cpfRepresentante2),
        RG: removeMaskRG(dados?.rgRepresentante2),
        Telefone: removeMaskTelefone(dados?.telefoneRepresentante2),
        nacionalidade: dados?.nacionalidadeRepresentante2,
        estadoCivil: dados?.estadoCivilRepresentante2,
        profissao: dados?.profissaoRepresentante2,
        clienteCnpjCpf: removeMaskCpfCnpj(cnpjCliente) || ""
      });

    const model = {
      CnpjCpf: removeMaskCpfCnpj(cnpjCliente) ?? "",
      RazaoSocial: dados?.razaoSocial,
      NomeFantasia: dados?.razaoSocial,
      ArvoreGerencialId: dadosArvore?.id,
      InscricaoEstadual: "",
      InscricaoMunicipal: "",
      Status: "NI",
      Cep: removeMaskCep(cep) ?? "",
      Endereco: dados?.endereco ?? "",
      Numero: dados?.numero ?? "",
      Complemento: dados?.complemento ?? null,
      Uf: dados?.uf ?? "",
      Bairro: dados?.bairro ?? "",
      Cidade: dados?.cidade ?? "",
      Telefone: removeMaskTelefone(dados?.telefoneContatoInicial),
      PessoaFisicaJuridica: "PJ",
      Situacao: "AT",
      DataSituacao: window.moment().format(),
      ColaboradorId:
        dadosInicial?.colaboradorId ?? usuario?.usuario?.colaboradorId,
      ConsumidorLivre: dadosInicial?.consumidorLivre === "S",
      AgenteCCEEId: 0,
      EmpresaGrupoDeltaId: usuario.empresaSelecionada,
      ContatoInicial: {
        NomeCompleto: dados?.nomeContatoInicial ?? "",
        Email: dados?.emailContatoInicial ?? "",
        CPF: removeMaskCpfCnpj(dados?.cpfContatoInicial) ?? "",
        RG: removeMaskRG(dados?.rgContatoInicial) ?? "",
        Telefone: removeMaskTelefone(dados?.telefoneContatoInicial) ?? "",
        Testemunha: false
      },
      Representantes: representantes,
      NomeContatoInicial: dados?.nomeContatoInicial ?? "",
      EmailContatoInicial: dados?.emailContatoInicial ?? "",
      CpfContatoInicial: removeMaskCpfCnpj(dados?.cpfContatoInicial) ?? "",
      RgContatoInicial: removeMaskRG(dados?.rgContatoInicial) ?? "",
      Origem: "AreaLogada",
      nacionalidade: dados?.nacionalidadeContatoInicial,
      estadoCivil: dados?.estadoCivilContatoInicial,
      cotacaoMLId: dadosCotacaoInicial?.id,
      profissao: dados?.profissaoContatoInicial
    };

    const {
      data,
      sucesso,
      mensagem
    } = await DadosEmpresaACLHelper.criarLeadCliente(
      validacaoTrimObject(model),
      handleLoading,
      dispatch,
      cotacaoId
    );
    if (!sucesso) {
      DadosEmpresaACLHelper.exibirErro(mensagem);
      dispatch(handleLoading(false));
      setError(true);
      return;
    }

    criarLeadClienteTestemunha(dados, data, dadosCotacaoInicial);
  };

  const criarPlanoCotacaoInicial = async (
    dados,
    dadosCotacaoInicial,
    dadosArvore
  ) => {
    const planos = dadosInicial?.planos?.map((item) => ({
      periodoMeses: item?.periodoMeses,
      economiaMensal: item?.economiaMensal,
      economiaAnual: item?.economiaAnual,
      economiaTotal: (item?.economiaTotal * item?.periodoMeses) / 12,
      flexibilidade: item?.flexibilidade,
      sustentabilidade: item?.sustentabilidade,
      percentualDesconto: item?.percentualDesconto,
      cotacaoSelecionada: item?.cotacaoSelecionada,
      tipoPlanoCotacaoId: item?.tipoPlanoCotacaoId,
      cotacaoId: dadosCotacaoInicial?.id,
      matrizDescontoId: 0,
      ativo: true,
      dataInicioSuprimento: item?.periodoEconomia?.inicio,
      dataFimSuprimento: item?.periodoEconomia?.fim
    }));

    const model = { cotacaoInicialPlanosGerados: planos };

    const {
      sucesso,
      mensagem
    } = await DadosEmpresaACLHelper.criarPLanosCotacaoInicial(
      model,
      handleLoading,
      dispatch
    );
    if (!sucesso) {
      DadosEmpresaACLHelper.exibirErro(mensagem);
      dispatch(handleLoading(false));
      setError(true);
      return;
    }

    criarLeadCliente(dados, dadosCotacaoInicial, dadosArvore);
  };

  const salvarPrecosDetalhes = async (cotacao) => {
    if (dadosInicial?.dadosCalculeira) {
      const precosDetalhe = dadosInicial?.dadosCalculeira?.precoDetalhe
        .filter((detalhe) => detalhe.quantidadeMeses <= 60)
        ?.map((dado) => {
          return {
            quantidadeMeses: dado.quantidadeMeses,
            precos: dado?.precos?.map((preco) => ({
              adicionalCalculado: preco.adicionalCalculado,
              ano: preco.ano,
              comissao: preco.comissao,
              indiceDataBase: preco.indiceDataBase,
              indiceMigracao: preco.indiceMigracao,
              margemUtilizada: preco.margemUtilizada,
              mesalidadeCalculado: preco.mensalidadeCalculado,
              precoFinal: preco.precoFinal,
              precoMedio: preco.precoMedio,
              ganhoMin: dado.quantidadeMeses
            }))
          };
        });
      const model = { listPrecos: precosDetalhe };
      const {
        sucesso,
        mensagem
      } = await DadosEmpresaACLHelper.criarPrecosDetalhe(model, cotacao.id);

      if (!sucesso) {
        DadosEmpresaACLHelper.exibirErro(mensagem);
        dispatch(handleLoading(false));
        setError(true);
      }
    }
  };

  const criarCotacaoInicial = async (dados, dadosArvore) => {
    const model = {
      cnpj: removeMaskCpfCnpj(cnpjCliente),
      nomeFantasia: dados?.razaoSocial,
      demandaContratada: dadosInicial?.demandaContratadaForaPonta,
      consumidorLivre: dadosInicial?.consumidorLivre === "S",
      distribuidoraId:
        dadosDistribuidora?.id ?? dadosInicial?.distribuidoraId ?? 0,
      empresaId: usuario.empresaSelecionada,
      colaboradorId:
        dadosInicial?.colaboradorId ?? usuario?.usuario?.colaboradorId,
      status: "AT",
      unidadeCnpj: true,
      produtoId: 1,
      consumoMedioConta: dadosInicial?.consumoMedio,
      consumoForaPonta: dadosInicial?.consumoForaPonta,
      consumoPonta: dadosInicial?.consumoPonta,
      valorMedioConta: dadosInicial?.valorMedioConta,
      tipoCalculo: "1",
      dataMigracao: dadosInicial?.dataMigracaoPrevisao,
      tradicionalVarejista: dadosInicial?.tradicionalVarejista,
      demandaPonta: dadosInicial?.demandaContratadaPonta,
      modalidade: dadosInicial?.modalidade,
      nivelTensao: dadosInicial?.nivelTensao,
      submercadoId: dadosInicial?.submercado,
      tipoEnergia: dadosInicial?.tipoEnergia,
      cep: removeMaskCep(cep) ?? "",
      cosip: dadosInicial?.cosip,
      icms: dadosInicial?.icms,
      pis: dadosInicial?.pis,
      cofins: dadosInicial?.cofins,
      comissao: dadosInicial?.temComissao,
      temGerador: dadosInicial?.temGerador,
      energiaGerada: dadosInicial?.energiaGerada,
      precoGerador: dadosInicial?.precoGerador,
      quandoMigrou: dadosInicial?.quandoMigrou,
      indiceVarejista: dadosInicial?.indiceVarejista,
      indiceCovid: dadosInicial?.indiceCovid,
      indiceNaoUtilizadaIcms: dadosInicial?.indiceNaoUtilizadaIcms,
      indicePisCofinsSobreIcms: dadosInicial?.indicePisCofinsSobreIcms,
      indiceDistribuidoraDesconto: dadosInicial?.indiceDistribuidoraDesconto,
      indiceRegistrada: dadosInicial?.indiceRegistrada,
      indiceSubvencaoIcms: dadosInicial?.indiceSubvencaoIcms,
      indiceSubvencaoPisCofins: dadosInicial?.indiceSubvencaoPisCofins,
      indiceReajuste: dadosInicial?.indiceReajuste,
      indiceEnergiaGerada: dadosInicial?.indiceEnergiaGerada,
      numeroInstalacao: dados?.numeroInstalacao,
      email: dados?.emailContatoInicial,
      telefone: removeMaskTelefone(dados?.telefoneContatoInicial),
      envelopeID: dadosInicial?.envelopeId ?? "",
      preLead: dadosInicial?.salesForcesId,
      faturaProcessada: dadosInicial?.faturaProcessada === true ? "S" : "N",
      origem: "AreaLogada",
      garantia:
        garantia === "Não se aplica"
          ? "Não se aplica"
          : `O CLIENTE deverá prestar caução no montante correspondente a ${handleTextoMes(
              mesGarantia
            )} de faturamento da energia média contratada, devendo ser apresentado em até 15 (quinze) dias antes do início do fornecimento de energia.`,
      valorGarantia:
        garantia === "Não se aplica"
          ? 0
          : formataValor(multiplicacaoValorGarantia),
      mesesGarantia: mesGarantia || 0,
      cupom: cupom ?? "",
      liberaPropostaContrato: dadosInicial?.dadosCotacao?.liberaPropostaContrato
        ? dadosInicial?.dadosCotacao?.liberaPropostaContrato
        : "N"
    };

    const {
      data,
      sucesso,
      mensagem
    } = await DadosEmpresaACLHelper.criarCotacaoInicial(
      model,
      handleLoading,
      dispatch,
      cotacaoId
    );
    if (!sucesso) {
      DadosEmpresaACLHelper.exibirErro(mensagem);
      dispatch(handleLoading(false));
      setError(true);
      return;
    }

    salvarPrecosDetalhes(data);
    criarPlanoCotacaoInicial(dados, data, dadosArvore);
  };

  const obterArvoreGerencialAltoNivel = async (dados) => {
    const {
      data,
      sucesso,
      mensagem
    } = await DadosEmpresaACLHelper.obterArvoreGerencialAltoNivel(
      dadosInicial?.colaboradorId,
      usuario.empresaSelecionada
    );

    if (!sucesso) {
      DadosEmpresaACLHelper.exibirErro(mensagem);
      setError(true);
      return;
    }

    criarCotacaoInicial(dados, data);
  };

  const buscarDadosCadastraisPorCnpj = async (cnpjPesquisar) => {
    if (!cnpjPesquisar) return;

    const {
      data,
      sucesso,
      mensagem
    } = await DadosEmpresaACLHelper.buscarDadosCadastraisPorCnpj(
      setCarregandoNomeFantasia,
      removeMaskCpfCnpj(cnpjPesquisar)
    );

    if (!sucesso) {
      DadosEmpresaACLHelper.exibirErro(mensagem);
      setCarregandoNomeFantasia(false);
      setNomeEmpresa(null);
      return;
    }
    setNomeEmpresa(data.nomeFantasia || data.razaoSocial);
  };

  const obterColaboradorId = async (colaboradorId) => {
    if (!colaboradorId) return;
    const {
      data,
      sucesso,
      mensagem
    } = await DadosEmpresaACLHelper.obterColaboradorId(colaboradorId);
    if (!sucesso) {
      if (mensagem === "403") {
        return;
      }
      DadosEmpresaACLHelper.exibirErro(mensagem);
      return;
    }

    setDadosOfficer(data);
    setCupom(data?.cupom);
  };

  const obterDistribuidoraCnpjOuNome = async (cnpjPesquisar) => {
    if (!cnpjPesquisar) return;
    const {
      data,
      sucesso,
      mensagem
    } = await DadosEmpresaACLHelper.obterDistribuidoraCnpjOuNome(
      removeMaskCpfCnpj(cnpjPesquisar)
    );

    if (!sucesso) {
      DadosEmpresaACLHelper.exibirErro(mensagem);
      return;
    }
    setDadosDistribuidora(data);
  };

  useEffect(() => {
    if (dadosInicial) {
      obterColaboradorId(dadosInicial?.colaboradorId);
      obterDistribuidoraCnpjOuNome(
        removeMaskCpfCnpj(dadosInicial?.fornecedorCnpj)
      );
      setNumeroInstalacao(dadosInicial?.numeroInstalacao);
    }
  }, [dadosInicial]);

  const obterCotacao = async (dados) => {
    if (
      !dados?.numeroInstalacao ||
      !cnpjCliente ||
      !dadosInicial?.distribuidoraId
    ) {
      obterArvoreGerencialAltoNivel({
        ...dados,
        estadoCivilContatoInicial,
        estadoCivilRepresentante,
        estadoCivilRepresentante2,
        uf
      });
      return;
    }

    const model = {
      numeroInstalacao: dados?.numeroInstalacao,
      cpfCnpj: removeMaskCpfCnpj(cnpjCliente),
      distribuidoraId: dadosInicial?.distribuidoraId
    };

    if (cotacaoId) {
      obterArvoreGerencialAltoNivel({
        ...dados,
        estadoCivilContatoInicial,
        estadoCivilRepresentante,
        estadoCivilRepresentante2,
        uf
      });

      return;
    }

    const {
      data,
      sucesso,
      mensagem
    } = await DadosEmpresaACLHelper.obterCotacao(
      model,
      handleLoading,
      dispatch
    );

    if (!sucesso) {
      if (mensagem === "CotacaoEncontrada") {
        obterArvoreGerencialAltoNivel({
          ...dados,
          estadoCivilContatoInicial,
          estadoCivilRepresentante,
          estadoCivilRepresentante2,
          uf
        });
      }
    } else {
      setExibirModalMensagemEditar(true);
      setCotacaoId(data);
    }
  };

  const validaRepresentante = (dados) => {
    const representantes = [];

    if (dados?.nomeContatoRepresentante) {
      representantes.push({
        Email: dados?.emailContatoRepresentante,
        CPF: removeMaskCpfCnpj(dados?.cpfRepresentante),
        RG: removeMaskRG(dados?.rgRepresentante)
      });
    }

    if (dados?.nomeContatoRepresentante2) {
      setMensagemDuplicidade(
        "Representante Legal em duplicidade, Email, Rg, Cpf!"
      );
      representantes.push({
        Email: dados?.emailContatoRepresentante2,
        CPF: removeMaskCpfCnpj(dados?.cpfRepresentante2),
        RG: removeMaskRG(dados?.rgRepresentante2)
      });
    }

    if (dados?.nomeTestemunha) {
      setMensagemDuplicidade(
        "Dados de testemunha em representante legal, Email, Rg, Cpf!"
      );
      representantes.push({
        Email: dados?.emailTestemunha,
        CPF: removeMaskCpfCnpj(dados?.cpfTestemunha),
        RG: removeMaskRG(dados?.rgTestemunha)
      });
    }

    if (representantes.length > 0) {
      const representantesList = representantes.slice();
      if (validarRepresentantesDuplicados(representantesList)) {
        return true;
      }
    }

    return false;
  };

  const enviarFormulario = async (dados) => {
    if (!usuario.empresaSelecionada) {
      DadosEmpresaACLHelper.exibirErro("É Obrigatório selecionar uma empresa!");
      return;
    }

    if (!habilitaRepresentante && enviarForm) {
      DadosEmpresaACLHelper.exibirErro(
        "Necessário adicionar um representante legal!"
      );
      return;
    }

    if (!habilitaTestemunha && enviarForm && tipoContratacao !== "Contrato") {
      DadosEmpresaACLHelper.exibirErro("Necessário adicionar uma testemunha!");
      return;
    }

    if (envelopeId && !enviarForm) {
      setExibirModalMensagem(true);
      return;
    }

    if (validaRepresentante(dados)) {
      setRepresentanteDuplicado(true);
      setTimeout(() => {
        setRepresentanteDuplicado(false);
      }, 5000);
      return;
    }

    const model = {
      ...dados,
      estadoCivilTestemunha
    };

    obterCotacao(model);
  };

  const parceiro = useMemo(() => {
    const validaParceiro =
      (dadosInicial?.dadosCotacao?.liberaPropostaContrato?.toLowerCase() ===
        "n" ||
        dadosInicial?.dadosCotacao?.liberaPropostaContrato === null ||
        dadosInicial?.dadosCotacao?.liberaPropostaContrato === undefined) &&
      dadosOfficer?.cargoId === enumeradorBotao.Parceiro;

    const naoParceiro =
      dadosInicial?.dadosCotacao?.liberaPropostaContrato?.toLowerCase() ===
        "S" && dadosOfficer?.cargoId === enumeradorBotao.Parceiro;

    switch (true) {
      case usuario?.usuario?.cargoId !== enumeradorBotao.Parceiro:
        return "naoParceiro";
      case validaParceiro:
        return "parceiro";
      case naoParceiro:
        return "naoParceiro";
      default:
        return "naoParceiro";
    }
  }, [dadosInicial, dadosOfficer]);

  const aoEnviarFormulario = (dados) => {
    if (parceiro === "parceiro" && tipoContratacao) {
      setMensagemModalParceiro(
        "Para prosseguir, a proposta e contrato deverá ser validada pelo time comercial!"
      );
      setModalParceiro(true);
      return;
    }
    enviarFormulario(dados);
  };

  const obterLeadClientePorId = async (cotacaoLeadId) => {
    if (!cotacaoLeadId) return;

    const {
      data,
      sucesso,
      mensagem
    } = await DadosEmpresaACLHelper.obterLeadClientePorId(cotacaoLeadId ?? "");

    if (!sucesso) {
      DadosEmpresaACLHelper.exibirErro(mensagem);
      return;
    }

    setDadosCotacao(data);
    setCep(data?.cep);
    setNumero(data?.numero);
    setEndereco(data?.endereco);
    setComplemento(data?.complemento);
    setBairro(data?.bairro);
    setCidade(data?.cidade);
    setUF(data?.uf);
    setNomeContatoInicial(data?.nomeContatoInicial);
    setRgContatoInicial(data?.rgContatoInicial);
    setCpfContatoInciial(data?.cpfContatoInicial);
    setEstadoCivilContatoInicial(data?.estadoCivil);
    setNacionalidade(data?.nacionalidade);
    setProfissaoContatoInicial(data?.profissao);

    const {
      id: id1,
      nomeCompleto,
      cpf: cpf1,
      rg,
      email,
      telefone,
      nacionalidade: nacionalidade1,
      estadoCivil,
      profissao
    } = data?.representantes?.[0] || [];

    setRepresentante({
      nomeCompleto,
      cpf: cpf1,
      rg,
      email,
      telefone,
      nacionalidade: nacionalidade1,
      profissao
    });

    setEstadoCivilRepresentante(estadoCivil);
    setRepresentanteId(id1);

    if (nomeCompleto) setHabilitaRepresentante(true);

    const {
      id: id2,
      nomeCompleto: nomeCompleto2,
      cpf: cpf2,
      rg: rg2,
      email: email2,
      telefone: telefone2,
      nacionalidade: nacionalidade2,
      estadoCivil: estadoCivil2,
      profissao: profissao2
    } = data?.representantes?.[1] || [];

    setRepresentante2({
      nomeCompleto: nomeCompleto2,
      cpf: cpf2,
      rg: rg2,
      email: email2,
      telefone: telefone2,
      nacionalidade: nacionalidade2,
      profissao: profissao2
    });

    setEstadoCivilRepresentante2(estadoCivil2);
    setRepresentante2Id(id2);
    if (nomeCompleto2) setHabilitaSegundoRepresentante(true);

    const {
      id: idT,
      nomeCompleto: nomeCompletoT,
      cpf: cpfT,
      rg: rgT,
      email: emailT,
      telefone: telefoneT,
      nacionalidade: nacionalidadeT,
      estadoCivil: estadoCivilT,
      profissao: profissaoT
    } = data?.testemunhas?.[0] || [];

    setTestemunha({
      nomeCompleto: nomeCompletoT,
      cpf: cpfT,
      rg: rgT,
      email: emailT,
      telefone: telefoneT,
      nacionalidade: nacionalidadeT,
      profissao: profissaoT
    });

    setEstadoCivilTestemunha(estadoCivilT);
    setTestemunhaId(idT);
    if (nomeCompletoT) setHabilitaTestemunha(true);
  };

  useEffect(() => {
    if (!id) return;
    setCotacaoId(id);
    obterLeadClientePorId(id);
  }, [id]);

  const handleMensagemModal = () => {
    return (
      <Box fontSize="24px">
        Não é possível salvar os dados alterados. Gere uma nova
        <Box component="span" fontWeight="700" mx={1}>
          proposta
        </Box>
        e
        <Box component="span" fontWeight="700" mx={1}>
          contrato
        </Box>
      </Box>
    );
  };

  const handleEditarCotacao = () => {
    if (cotacaoId) history.push(`${RotasDTO.CotacaoACL}/${cotacaoId}`);
    setExibirModalMensagem(false);
  };

  const onClickNovoTestemunha = (event) => {
    if (statusVigenciaFechado) return;
    event.preventDefault();

    setHabilitaTestemunha(true);
  };

  const excluirTestemunha = (id) => {
    if (statusVigenciaFechado) return;
    if (id <= 0) {
      setHabilitaTestemunha(false);
    }
    setExibirModalExcluir(true);
  };

  const handleModalValidacao = () => {
    return (
      <Box fontSize="24px" fontWeight="700">
        Cotação já cadastrada. Deseja editar?
      </Box>
    );
  };

  const excluirLeadClienteTestemunha = async (idTestemunha) => {
    if (idTestemunha <= 0) return;
    const {
      sucesso,
      mensagem
    } = await DadosEmpresaACLHelper.excluirLeadClienteTestemunha(
      testemunhaId,
      handleLoading,
      dispatch
    );

    if (!sucesso) {
      DadosEmpresaACLHelper.exibirErro(mensagem);
      dispatch(handleLoading(false));
    }

    setHabilitaTestemunha(false);
    setExibirModalExcluir(false);
    obterLeadClientePorId(id);
  };

  const handleModal = (msg) => {
    return (
      <Box fontSize="24px" fontWeight="700">
        {msg}
      </Box>
    );
  };

  return (
    <CustomLoader
      msgLoading={enviarForm ? "Enviando contrato..." : "Salvando dados..."}
    >
      <ModalInformacao
        exibir={modalInfo}
        mensagem="E-mail de acesso enviado para o contato cadastrado, verifique a caixa de entrada e lixo eletrônico."
        onClose={() => {
          setModalInfo(false);
        }}
        showBotaoLink
        showBotao={false}
      />
      <ModalLuz
        item={exibirModalMensagem}
        mensagem={handleMensagemModal()}
        onCancelar={() => setExibirModalMensagem(false)}
        footer={false}
      />
      <ModalLuz
        item={exibirModalMensagemEditar}
        mensagem={handleModalValidacao()}
        onCancelar={() => {
          setExibirModalMensagemEditar(false);
          setCotacaoId(null);
        }}
        onConfirmar={handleEditarCotacao}
      />
      <ModalLuz
        item={exibirModalExcluir}
        mensagem={handleModal(
          "Têm certeza que deseja excluir essa Testemunha?"
        )}
        onCancelar={() => {
          setExibirModalExcluir(false);
        }}
        onConfirmar={() => excluirLeadClienteTestemunha(testemunhaId)}
      />

      <ModalMensagemErro
        item={modalParceiro}
        titulo=""
        mensagem={handleModal(mensagemModalParceiro)}
        onCancelar={() => {
          setModalParceiro(false);
          setMensagemModalParceiro("");
        }}
        onConfirmar={() => {}}
        footer={false}
      />

      <form
        id="hook-form"
        className="needs-validation"
        onSubmit={handleSubmit(aoEnviarFormulario)}
      >
        <Card className={classes.container}>
          <Grid container spacing={2} className="py-6 px-4 mt-3 mb-3">
            <Grid item xs={12}>
              <Grid container justifyContent="flex-end">
                <Grid item xs={11} className={classes.textoSpan}>
                  Dados empresa
                </Grid>
                <Grid item xs={1}>
                  <BotaoRetornarListagem
                    justify="flex-end"
                    urlListagem={
                      id ? `${RotasDTO.CotacaoACL}/${id}` : RotasDTO.CotacaoACL
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4} lg={4}>
              <MaterialInputMascara
                type="text"
                id="cnpj"
                name="cnpj"
                label="CNPJ"
                mask="##.###.###/####-##"
                onBlur={(e) => buscarDadosCadastraisPorCnpj(e.target.value)}
                renderIconShowHide={false}
                defaultValue={cnpjCliente ?? ""}
                ref={register({
                  required: "Campo CNPJ é obrigatório!",
                  maxLength: {
                    value: 18,
                    message: "Quantidade máxima de 18 caracteres!"
                  },
                  minLength: {
                    value: 18,
                    message: "Quantidade mínima de 18 caracteres!"
                  },
                  validate: (value) => cnpj.isValid(value) || "CNPJ inválido!"
                })}
                errors={errors}
                className={classes.desabledInput}
                readOnly
                disabled={statusVigenciaFechado}
              />
            </Grid>
            <Grid item xs={12} sm={4} lg={4}>
              <Loader loading={carregandoNomeFantasia}>
                <MaterialInputTexto
                  type="text"
                  id="razaoSocial"
                  name="razaoSocial"
                  label="Razão social"
                  renderIconShowHide={false}
                  defaultValue={nomeEmpresa ?? ""}
                  disabled={statusVigenciaFechado}
                  ref={register({
                    required: "Campo Razão Social é obrigatório!",
                    maxLength: {
                      value: 100,
                      message: "Quantidade máxima de 100 caracteres!"
                    }
                  })}
                  errors={errors}
                />
              </Loader>
            </Grid>

            <Grid item xs={12} sm={4} lg={4}>
              <Loader loading={carregandoCep}>
                <MaterialInputMascara
                  type="text"
                  id="cep"
                  name="cep"
                  label="CEP"
                  mask="#####-###"
                  renderIconShowHide={false}
                  defaultValue={cep ?? ""}
                  maxLength={9}
                  onBlur={(event) => onBlurCep(event.target.value)}
                  ref={register({
                    required: "O campo CEP é obrigatório!",
                    minLength: {
                      value: 9,
                      message: "Quantidade minima de 9 caracteres!"
                    },
                    maxLength: {
                      value: 9,
                      message: "Quantidade máxima de 9 caracteres!"
                    },
                    validate: (value) =>
                      value.replace(/\s/g, "").length === 9 ||
                      "Deve ser informado 8 digitos"
                  })}
                  errors={errors}
                  disabled={statusVigenciaFechado}
                />
              </Loader>
            </Grid>
            <Grid item xs={12} sm={4} lg={4}>
              <MaterialInputTexto
                type="text"
                id="endereco"
                name="endereco"
                label="Endereço"
                permiteValorBranco
                renderIconShowHide={false}
                defaultValue={endereco ?? ""}
                ref={register({
                  required: enviarForm
                    ? "Campo Endereço é obrigatório!"
                    : false,
                  maxLength: {
                    value: 200,
                    message: "Quantidade máxima de 200 caracteres!"
                  }
                })}
                errors={errors}
                disabled={statusVigenciaFechado}
              />
            </Grid>
            <Grid item xs={12} sm={4} lg={4}>
              <MaterialInputTexto
                type="text"
                id="numero"
                name="numero"
                label="Número"
                permiteValorBranco
                renderIconShowHide={false}
                defaultValue={numero ?? ""}
                ref={register({
                  required: enviarForm ? "Campo Número é obrigatório!" : false,
                  maxLength: {
                    value: 10,
                    message: "Quantidade máxima de 10 caracteres!"
                  }
                })}
                errors={errors}
                disabled={statusVigenciaFechado}
              />
            </Grid>
            <Grid item xs={12} sm={4} lg={4}>
              <MaterialInputTexto
                type="text"
                id="complemento"
                name="complemento"
                label="Complemento"
                permiteValorBranco
                renderIconShowHide={false}
                defaultValue={complemento ?? ""}
                ref={register({
                  maxLength: {
                    value: 100,
                    message: "Quantidade máxima de 100 caracteres!"
                  }
                })}
                errors={errors}
                disabled={statusVigenciaFechado}
              />
            </Grid>

            <Grid item xs={12} sm={4} lg={4}>
              <MaterialInputTexto
                type="text"
                id="bairro"
                name="bairro"
                label="Bairro"
                permiteValorBranco
                renderIconShowHide={false}
                defaultValue={bairro ?? ""}
                ref={register({
                  required: enviarForm ? "Campo Bairro é obrigatório!" : false,
                  maxLength: {
                    value: 100,
                    message: "Quantidade máxima de 100 caracteres!"
                  }
                })}
                errors={errors}
                disabled={statusVigenciaFechado}
              />
            </Grid>
            <Grid item xs={12} sm={4} lg={4}>
              <MaterialInputTexto
                type="text"
                id="cidade"
                name="cidade"
                label="Cidade"
                permiteValorBranco
                renderIconShowHide={false}
                defaultValue={cidade ?? ""}
                ref={register({
                  required: "Campo Cidade é obrigatório!",
                  maxLength: {
                    value: 100,
                    message: "Quantidade máxima de 100 caracteres!"
                  }
                })}
                errors={errors}
                disabled={statusVigenciaFechado}
              />
            </Grid>
            <Grid item xs={12} sm={4} lg={4}>
              <SelectArredondado
                id="uf"
                name="uf"
                valueKey="UF"
                valueName="UF"
                dataSource={unidadesFederativas}
                label="UF"
                value={uf ?? ""}
                onChange={(e) => setUF(e.target.value)}
                placeholder="UF"
                allowClear={!statusVigenciaFechado}
                ref={register(
                  { name: "uf" },
                  {
                    required: !uf ? "Campo UF é obrigatório" : false
                  }
                )}
                errors={errors}
                disabled={statusVigenciaFechado}
              />
            </Grid>

            <Grid item xs={12} sm={4} lg={4}>
              {podeEditarNumeroInstalacao && (
                <Box color="rgba(0, 0, 0, 0.6)">
                  <CheckInputs
                    label="Habilitar a edição"
                    setChecked={setChecked}
                    checked={checked}
                    width={15}
                    height={15}
                    disabled={statusVigenciaFechado}
                  />
                </Box>
              )}
              <MaterialInputTexto
                type="text"
                id="numeroInstalacao"
                name="numeroInstalacao"
                label="Número Instalação"
                permiteValorBranco
                renderIconShowHide={false}
                defaultValue={numeroInstalacao ?? ""}
                ref={register({
                  required: "Campo Número Instalação é obrigatório!",

                  maxLength: {
                    value: 50,
                    message: "Quantidade máxima de 50 caracteres!"
                  }
                })}
                errors={errors}
                className={!habiltarEditar ? classes.desabledInput : ""}
                readOnly={!habiltarEditar}
                disabled={statusVigenciaFechado}
              />
            </Grid>
            <Grid item xs={12} sm={8} lg={8} />

            <Grid item xs={12} sm={12} lg={12}>
              <InputRadioGroup
                type="text"
                id="garantia"
                name="garantia"
                label="Garantia"
                renderIconShowHide={false}
                customValue={garantia}
                onChange={(e) => setGarantia(e?.target?.value)}
                defaultValue={garantia}
                ref={register({
                  required: "Campo garantia é obrigatório!"
                })}
                errors={errors}
                className={classes.inputRadio}
                classNameLabel={classes.inputRadioLabel}
                itens={DadosEmpresaACLHelper.obterGarantia()}
                disabled={statusVigenciaFechado}
              />
            </Grid>

            {garantia === "Garantia exigida" && (
              <>
                <Grid item xs={6} sm={3} md={4} lg={2}>
                  <MaterialInputTextoMoeda
                    type="text"
                    id="valorGarantia"
                    name="valorGarantia"
                    label="Valor Médio Garantia"
                    defaultValue={valorGarantia ?? ""}
                    renderIconShowHide={false}
                    onInput={(e) => {
                      setValorGarantia(formatarBrasileira(e.target.value));
                    }}
                    ref={register({
                      required:
                        garantia === "Garantia exigida"
                          ? "Valor médio garantia é obrigatório!"
                          : false
                    })}
                    errors={errors}
                    disabled={statusVigenciaFechado}
                  />
                </Grid>
                <Grid item xs={6} sm={3} md={4} lg={2}>
                  <MaterialInputTexto
                    type="number"
                    id="mesGarantia"
                    name="mesGarantia"
                    label="Meses Garantia"
                    defaultValue={mesGarantia || ""}
                    onInput={(e) => {
                      setMesGarantia(e.target.value);
                    }}
                    renderIconShowHide={false}
                    ref={register({
                      required:
                        garantia === "Garantia exigida"
                          ? "Campo mês garantia obrigatório"
                          : false
                    })}
                    errors={errors}
                    disabled={statusVigenciaFechado}
                  />
                </Grid>
                <Grid item xs={6} sm={3} md={4} lg={2}>
                  <MaterialInputTexto
                    type="text"
                    id="mesValorGarantia"
                    name="mesValorGarantia"
                    label="Valor Total Garantia"
                    defaultValue={multiplicacaoValorGarantia || ""}
                    renderIconShowHide={false}
                    ref={register}
                    disabled
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={9} lg={8} />
              </>
            )}

            <Grid item xs={12} className="pt-4">
              <Grid item xs={12} className={classes.textoSpan}>
                Dados contato
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4} lg={4}>
              <MaterialInputTexto
                type="text"
                id="nomeContatoInicial"
                name="nomeContatoInicial"
                label="Nome completo"
                defaultValue={nomeContatoInicial ?? ""}
                permiteValorBranco
                renderIconShowHide={false}
                ref={register({
                  required: enviarForm
                    ? "Campo Nome Completo é obrigatório!"
                    : false,
                  maxLength: {
                    value: 100,
                    message: "Quantidade máxima de 100 caracteres!"
                  }
                })}
                errors={errors}
                disabled={statusVigenciaFechado}
              />
            </Grid>
            <Grid item xs={12} sm={4} lg={4}>
              <MaterialInputTexto
                type="text"
                id="rgContatoInicial"
                name="rgContatoInicial"
                label="RG"
                renderIconShowHide={false}
                defaultValue={rgContatoInicial ?? ""}
                ref={register({
                  required: enviarForm ? "O campo RG é obrigatório!" : false,
                  maxLength: {
                    value: 20,
                    message: "Quantidade máxima de 20 caracteres!"
                  },
                  minLength: {
                    value: 7,
                    message: "Quantidade mínima de 7 caracteres!"
                  }
                })}
                errors={errors}
                disabled={statusVigenciaFechado}
              />
            </Grid>
            <Grid item xs={12} sm={4} lg={4}>
              <MaterialInputMascara
                type="text"
                id="cpfContatoInicial"
                name="cpfContatoInicial"
                label="CPF"
                mask="###.###.###-##"
                limparValor={limparCpf}
                redefineLimpar={(estado) => setLimparCpf(estado)}
                renderIconShowHide={false}
                defaultValue={cpfContatoInicial ?? ""}
                ref={register({
                  required: enviarForm ? "O campo CPF é obrigatório!" : false,
                  maxLength: {
                    value: 14,
                    message: "Quantidade máxima de 14 caracteres!"
                  },
                  validate: (value) =>
                    value ? cpf.isValid(value) || "CPF inválido!" : true
                })}
                errors={errors}
                disabled={statusVigenciaFechado}
              />
            </Grid>
            <Grid item xs={12} sm={4} lg={4}>
              <MaterialInputTexto
                type="text"
                id="emailContatoInicial"
                name="emailContatoInicial"
                label="E-mail"
                renderIconShowHide={false}
                defaultValue={emailContatoInicial ?? ""}
                permiteValorBranco
                ref={register({
                  required: enviarForm
                    ? "O campo E-mail é obrigatório!"
                    : false,
                  pattern: {
                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Campo E-mail inválido!"
                  },
                  maxLength: {
                    value: 100,
                    message: "Quantidade máxima de 100 caracteres!"
                  }
                })}
                errors={errors}
                disabled={statusVigenciaFechado}
              />
            </Grid>
            <Grid item xs={12} sm={4} lg={4}>
              <MaterialInputTelefone
                type="text"
                id="telefoneContatoInicial"
                name="telefoneContatoInicial"
                label="Telefone"
                renderIconShowHide={false}
                defaultValue={telefoneContatoInicial ?? ""}
                ref={register({
                  required: enviarForm
                    ? "O campo Telefone é obrigatório!"
                    : false,
                  minLength: {
                    value: 18,
                    message: "Quantidade mínima de 12 caracteres!"
                  }
                })}
                errors={errors}
                disabled={statusVigenciaFechado}
              />
            </Grid>

            <Grid item xs={12} sm={4} lg={4}>
              <SelectArredondado
                id="estadoCivilContatoInicial"
                name="estadoCivilContatoInicial"
                valueKey="value"
                valueName="label"
                dataSource={estadoCivilList}
                label="Estado Civil"
                value={estadoCivilContatoInicial ?? ""}
                onChange={(e) => setEstadoCivilContatoInicial(e.target.value)}
                placeholder="Estado civil"
                allowClear={!statusVigenciaFechado}
                ref={register(
                  { name: "estadoCivilContatoInicial" },
                  {
                    required:
                      !estadoCivilContatoInicial && enviarForm
                        ? "Campo estado civil obrigatório"
                        : false
                  }
                )}
                errors={errors}
                disabled={statusVigenciaFechado}
              />
            </Grid>
            <Grid item xs={12} sm={4} lg={4}>
              <MaterialInputTexto
                type="text"
                id="nacionalidadeContatoInicial"
                name="nacionalidadeContatoInicial"
                label="Nacionalidade"
                renderIconShowHide={false}
                defaultValue={nacionalidade ?? ""}
                ref={register({
                  required: enviarForm
                    ? "Campo Nacionalidade é obrigatório!"
                    : false,
                  pattern: {
                    value: /([^\d$&+,:;=?@#|'<>.^*()%!-]*$)/g,
                    message:
                      "Deve ser informado apenas letras e espaços no nome!"
                  }
                })}
                errors={errors}
                disabled={statusVigenciaFechado}
              />
            </Grid>
            <Grid item xs={12} sm={4} lg={4}>
              <MaterialInputTexto
                type="text"
                id="profissaoContatoInicial"
                name="profissaoContatoInicial"
                label="Profissão"
                renderIconShowHide={false}
                permiteValorBranco
                defaultValue={profissaoContatoInicial || ""}
                ref={register({
                  required: enviarForm
                    ? "Campo Profissão é obrigatório!"
                    : false,
                  maxLength: {
                    value: 50,
                    message: "Quantidade máxima de 50 caracteres!"
                  },
                  validate: (value) =>
                    // eslint-disable-next-line quotes
                    (!value.includes("'") && !value.includes('"')) ||
                    "Não é permitido adicionar aspas no Nome Fantasia"
                })}
                errors={errors}
                disabled={statusVigenciaFechado}
              />
            </Grid>

            <Grid item xs={12} sm={8} lg={8} className={classes.textoSpan} />

            {habilitaRepresentante && (
              <>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  lg={12}
                  className={classes.textoSpan}
                >
                  Representante
                  <DeleteIcon
                    className={classes.excluirRepresentante}
                    onClick={() => {
                      clearErrors([
                        "estadoCivilRepresentante",
                        "estadoCivilRepresentante2"
                      ]);
                      setValue("estadoCivilRepresentante", "0");
                      setValue("estadoCivilRepresentante2", "0");
                      excluirRepresentante();
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4} lg={4}>
                  <MaterialInputTexto
                    type="text"
                    id="nomeContatoRepresentante"
                    name="nomeContatoRepresentante"
                    label="Nome completo"
                    renderIconShowHide={false}
                    defaultValue={dadosRepresentante?.nomeCompleto ?? ""}
                    permiteValorBranco
                    ref={register({
                      required: enviarForm
                        ? "Campo Nome Completo é obrigatório!"
                        : false,
                      maxLength: {
                        value: 100,
                        message: "Quantidade máxima de 100 caracteres!"
                      }
                    })}
                    errors={errors}
                    disabled={statusVigenciaFechado}
                  />
                </Grid>
                <Grid item xs={12} sm={4} lg={4}>
                  <MaterialInputTexto
                    type="text"
                    id="rgRepresentante"
                    name="rgRepresentante"
                    label="RG"
                    mask="##.###.###-#"
                    renderIconShowHide={false}
                    defaultValue={dadosRepresentante?.rg ?? ""}
                    ref={register({
                      required: enviarForm
                        ? "O campo RG é obrigatório!"
                        : false,
                      maxLength: {
                        value: 20,
                        message: "Quantidade máxima de 20 caracteres!"
                      },
                      minLength: {
                        value: 7,
                        message: "Quantidade mínima de 7 caracteres!"
                      }
                    })}
                    errors={errors}
                    disabled={statusVigenciaFechado}
                  />
                </Grid>
                <Grid item xs={12} sm={4} lg={4}>
                  <MaterialInputMascara
                    type="text"
                    id="cpfRepresentante"
                    name="cpfRepresentante"
                    label="CPF"
                    mask="###.###.###-##"
                    renderIconShowHide={false}
                    defaultValue={dadosRepresentante?.cpf ?? ""}
                    ref={register({
                      required: enviarForm
                        ? "O campo CPF é obrigatório!"
                        : false,
                      maxLength: {
                        value: 14,
                        message: "Quantidade máxima de 14 caracteres!"
                      },
                      validate: (value) =>
                        value ? cpf.isValid(value) || "CPF inválido!" : true
                    })}
                    errors={errors}
                    disabled={statusVigenciaFechado}
                  />
                </Grid>
                <Grid item xs={12} sm={4} lg={4}>
                  <MaterialInputTexto
                    type="text"
                    id="emailContatoRepresentante"
                    name="emailContatoRepresentante"
                    label="E-mail"
                    renderIconShowHide={false}
                    defaultValue={dadosRepresentante?.email ?? ""}
                    permiteValorBranco
                    ref={register({
                      required: enviarForm
                        ? "O campo E-mail é obrigatório!"
                        : false,
                      pattern: {
                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "Campo E-mail inválido!"
                      },
                      maxLength: {
                        value: 100,
                        message: "Quantidade máxima de 100 caracteres!"
                      }
                    })}
                    errors={errors}
                    disabled={statusVigenciaFechado}
                  />
                </Grid>
                <Grid item xs={12} sm={4} lg={4}>
                  <MaterialInputTelefone
                    type="text"
                    id="telefoneRepresentante"
                    name="telefoneRepresentante"
                    label="Telefone"
                    renderIconShowHide={false}
                    defaultValue={dadosRepresentante?.telefone ?? " "}
                    ref={register({
                      required: enviarForm
                        ? "O campo Telefone é obrigatório!"
                        : false,
                      minLength: {
                        value: 18,
                        message: "Quantidade mínima de 12 caracteres!"
                      }
                    })}
                    errors={errors}
                    disabled={statusVigenciaFechado}
                  />
                </Grid>
                <Grid item xs={12} sm={4} lg={4}>
                  <SelectArredondado
                    id="estadoCivilRepresentante"
                    name="estadoCivilRepresentante"
                    valueKey="value"
                    valueName="label"
                    dataSource={estadoCivilList}
                    label="Estado Civil"
                    value={estadoCivilRepresentante ?? ""}
                    onChange={(e) =>
                      setEstadoCivilRepresentante(e.target.value)
                    }
                    placeholder="Estado civil"
                    allowClear={!statusVigenciaFechado}
                    ref={register(
                      { name: "estadoCivilRepresentante" },
                      {
                        required:
                          camposObrigatorios &&
                          !estadoCivilRepresentante &&
                          enviarForm
                            ? "Campo Estado civil é obrigatório!"
                            : false
                      }
                    )}
                    errors={errors}
                    disabled={statusVigenciaFechado}
                  />
                </Grid>
                <Grid item xs={12} sm={4} lg={4}>
                  <MaterialInputTexto
                    type="text"
                    id="nacionalidadeRepresentante"
                    name="nacionalidadeRepresentante"
                    label="Nacionalidade"
                    renderIconShowHide={false}
                    defaultValue={dadosRepresentante?.nacionalidade ?? ""}
                    ref={register({
                      required: enviarForm
                        ? "Campo Nacionalidade é obrigatório!"
                        : false,
                      pattern: {
                        value: /([^\d$&+,:;=?@#|'<>.^*()%!-]*$)/g,
                        message:
                          "Deve ser informado apenas letras e espaços no nome!"
                      }
                    })}
                    errors={errors}
                    disabled={statusVigenciaFechado}
                  />
                </Grid>
                <Grid item xs={12} sm={4} lg={4}>
                  <MaterialInputTexto
                    type="text"
                    id="profissaoRepresentante"
                    name="profissaoRepresentante"
                    label="Profissão"
                    renderIconShowHide={false}
                    permiteValorBranco
                    defaultValue={dadosRepresentante?.profissao || ""}
                    ref={register({
                      required: enviarForm
                        ? "Campo Profissão é obrigatório!"
                        : false,
                      maxLength: {
                        value: 50,
                        message: "Quantidade máxima de 50 caracteres!"
                      },
                      validate: (value) =>
                        // eslint-disable-next-line quotes
                        (!value.includes("'") && !value.includes('"')) ||
                        "Não é permitido adicionar aspas no Nome Fantasia"
                    })}
                    errors={errors}
                    disabled={statusVigenciaFechado}
                  />
                </Grid>
                <Grid item xs={12} sm={4} lg={4} />
              </>
            )}

            {habilitaSegundoRepresentante && (
              <>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  className={classes.textoSpan}
                >
                  Representante (2)
                  <DeleteIcon
                    className={classes.excluirRepresentante}
                    onClick={() => {
                      clearErrors(["estadoCivilRepresentante2"]);
                      setValue("estadoCivilRepresentante2", "0");
                      excluirSegundoRepresentante();
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4} lg={4}>
                  <MaterialInputTexto
                    type="text"
                    id="nomeContatoRepresentante2"
                    name="nomeContatoRepresentante2"
                    label="Nome completo"
                    renderIconShowHide={false}
                    defaultValue={dadosRepresentante2?.nomeCompleto ?? ""}
                    permiteValorBranco
                    ref={register({
                      required: enviarForm
                        ? "Campo Nome Completo é obrigatório!"
                        : false,
                      maxLength: {
                        value: 100,
                        message: "Quantidade máxima de 100 caracteres!"
                      }
                    })}
                    errors={errors}
                    disabled={statusVigenciaFechado}
                  />
                </Grid>
                <Grid item xs={12} sm={4} lg={4}>
                  <MaterialInputTexto
                    type="text"
                    id="rgRepresentante2"
                    name="rgRepresentante2"
                    label="RG"
                    renderIconShowHide={false}
                    defaultValue={dadosRepresentante2?.rg ?? ""}
                    ref={register({
                      required: enviarForm
                        ? "O campo RG é obrigatório!"
                        : false,
                      maxLength: {
                        value: 20,
                        message: "Quantidade máxima de 20 caracteres!"
                      },
                      minLength: {
                        value: 7,
                        message: "Quantidade mínima de 7 caracteres!"
                      }
                    })}
                    errors={errors}
                    disabled={statusVigenciaFechado}
                  />
                </Grid>
                <Grid item xs={12} sm={4} lg={4}>
                  <MaterialInputMascara
                    type="text"
                    id="cpfRepresentante2"
                    name="cpfRepresentante2"
                    label="CPF"
                    mask="###.###.###-##"
                    renderIconShowHide={false}
                    defaultValue={dadosRepresentante2?.cpf ?? ""}
                    ref={register({
                      required: enviarForm
                        ? "O campo CPF é obrigatório!"
                        : false,
                      maxLength: {
                        value: 14,
                        message: "Quantidade máxima de 14 caracteres!"
                      },
                      validate: (value) =>
                        value ? cpf.isValid(value) || "CPF inválido!" : true
                    })}
                    errors={errors}
                    disabled={statusVigenciaFechado}
                  />
                </Grid>
                <Grid item xs={12} sm={4} lg={4}>
                  <MaterialInputTexto
                    type="text"
                    id="emailContatoRepresentante2"
                    name="emailContatoRepresentante2"
                    label="E-mail"
                    renderIconShowHide={false}
                    defaultValue={dadosRepresentante2?.email ?? ""}
                    permiteValorBranco
                    ref={register({
                      required: enviarForm
                        ? "O campo E-mail é obrigatório!"
                        : false,
                      pattern: {
                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "Campo E-mail inválido!"
                      },
                      maxLength: {
                        value: 100,
                        message: "Quantidade máxima de 100 caracteres!"
                      }
                    })}
                    errors={errors}
                    disabled={statusVigenciaFechado}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  lg={4}
                  className={classes.containerTelefone}
                >
                  <MaterialInputTelefone
                    type="text"
                    id="telefoneRepresentante2"
                    name="telefoneRepresentante2"
                    label="Telefone"
                    renderIconShowHide={false}
                    defaultValue={dadosRepresentante2?.telefone ?? ""}
                    ref={register({
                      required: enviarForm
                        ? "O campo Telefone é obrigatório!"
                        : false,
                      minLength: {
                        value: 18,
                        message: "Quantidade mínima de 12 caracteres!"
                      }
                    })}
                    errors={errors}
                    disabled={statusVigenciaFechado}
                  />
                </Grid>
                <Grid item xs={12} sm={4} lg={4}>
                  <SelectArredondado
                    id="estadoCivilRepresentante2"
                    name="estadoCivilRepresentante2"
                    valueKey="value"
                    valueName="label"
                    dataSource={estadoCivilList}
                    label="Estado Civil"
                    value={estadoCivilRepresentante2 ?? ""}
                    onChange={(e) =>
                      setEstadoCivilRepresentante2(e.target.value)
                    }
                    placeholder="Estado civil"
                    allowClear={!statusVigenciaFechado}
                    ref={register(
                      { name: "estadoCivilRepresentante2" },
                      {
                        required:
                          camposObrigatorios &&
                          !estadoCivilRepresentante2 &&
                          enviarForm
                            ? "Campo Estado civil é obrigatório!"
                            : false
                      }
                    )}
                    errors={errors}
                    disabled={statusVigenciaFechado}
                  />
                </Grid>
                <Grid item xs={12} sm={4} lg={4}>
                  <MaterialInputTexto
                    type="text"
                    id="nacionalidadeRepresentante2"
                    name="nacionalidadeRepresentante2"
                    label="Nacionalidade"
                    renderIconShowHide={false}
                    defaultValue={dadosRepresentante2?.nacionalidade ?? ""}
                    ref={register({
                      required: enviarForm
                        ? "Campo Nacionalidade é obrigatório!"
                        : false,
                      pattern: {
                        value: /([^\d$&+,:;=?@#|'<>.^*()%!-]*$)/g,
                        message:
                          "Deve ser informado apenas letras e espaços no nome!"
                      }
                    })}
                    errors={errors}
                    disabled={statusVigenciaFechado}
                  />
                </Grid>
                <Grid item xs={12} sm={4} lg={4}>
                  <MaterialInputTexto
                    type="text"
                    id="profissaoRepresentante2"
                    name="profissaoRepresentante2"
                    label="Profissão"
                    renderIconShowHide={false}
                    permiteValorBranco
                    defaultValue={dadosRepresentante2?.profissao || ""}
                    ref={register({
                      required: enviarForm
                        ? "Campo Profissão é obrigatório!"
                        : false,
                      maxLength: {
                        value: 50,
                        message: "Quantidade máxima de 50 caracteres!"
                      },
                      validate: (value) =>
                        // eslint-disable-next-line quotes
                        (!value.includes("'") && !value.includes('"')) ||
                        "Não é permitido adicionar aspas no Nome Fantasia"
                    })}
                    errors={errors}
                    disabled={statusVigenciaFechado}
                  />
                </Grid>
                <Grid item xs={12} sm={4} lg={4} />
              </>
            )}

            {representanteDuplicado && (
              <Grid item xs={12}>
                <Alerta
                  key={0}
                  onClose={() => {
                    setRepresentanteDuplicado(false);
                  }}
                  {...{
                    tipo: "danger",
                    mensagem: mensagemDuplicidade
                  }}
                />
              </Grid>
            )}

            {!habilitaSegundoRepresentante && !bloquearRepresentantesContato && (
              <Grid item xs={12} className={classes.addRepresentante}>
                <BotaoCadastro
                  label="Adicionar representante"
                  color={Base.Black}
                  disabled={
                    bloquearRepresentantesContato || statusVigenciaFechado
                  }
                  onClick={onClickNovoRepresentante}
                />
              </Grid>
            )}

            {!habilitaTestemunha && (
              <Grid item xs={12} className={classes.addRepresentante}>
                <BotaoCadastro
                  label="Adicionar testemunha"
                  color={Base.Black}
                  onClick={onClickNovoTestemunha}
                  disabled={statusVigenciaFechado}
                />
              </Grid>
            )}

            {habilitaTestemunha && (
              <>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  className={classes.textoSpan}
                >
                  Testemunha
                  <DeleteIcon
                    className={classes.excluirRepresentante}
                    onClick={() => {
                      clearErrors(["estadoCivilTestemunha"]);
                      setValue("estadoCivilTestemunha", "0");
                      excluirTestemunha(testemunhaId);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4} lg={4}>
                  <MaterialInputTexto
                    type="text"
                    id="nomeTestemunha"
                    name="nomeTestemunha"
                    label="Nome completo"
                    renderIconShowHide={false}
                    defaultValue={dadosTestemunha?.nomeCompleto ?? ""}
                    permiteValorBranco
                    ref={register({
                      required: enviarForm
                        ? "Campo Nome Completo é obrigatório!"
                        : false,
                      maxLength: {
                        value: 100,
                        message: "Quantidade máxima de 100 caracteres!"
                      }
                    })}
                    errors={errors}
                    disabled={statusVigenciaFechado}
                  />
                </Grid>
                <Grid item xs={12} sm={4} lg={4}>
                  <MaterialInputTexto
                    type="text"
                    id="rgTestemunha"
                    name="rgTestemunha"
                    label="RG"
                    mask="##.###.###-#"
                    renderIconShowHide={false}
                    defaultValue={dadosTestemunha?.rg ?? ""}
                    ref={register({
                      required: enviarForm
                        ? "O campo RG é obrigatório!"
                        : false,
                      maxLength: {
                        value: 20,
                        message: "Quantidade máxima de 20 caracteres!"
                      },
                      minLength: {
                        value: 7,
                        message: "Quantidade mínima de 7 caracteres!"
                      }
                    })}
                    errors={errors}
                    disabled={statusVigenciaFechado}
                  />
                </Grid>
                <Grid item xs={12} sm={4} lg={4}>
                  <MaterialInputMascara
                    type="text"
                    id="cpfTestemunha"
                    name="cpfTestemunha"
                    label="CPF"
                    mask="###.###.###-##"
                    renderIconShowHide={false}
                    defaultValue={dadosTestemunha?.cpf ?? ""}
                    ref={register({
                      required: enviarForm
                        ? "O campo CPF é obrigatório!"
                        : false,
                      maxLength: {
                        value: 14,
                        message: "Quantidade máxima de 14 caracteres!"
                      },
                      validate: (value) =>
                        value ? cpf.isValid(value) || "CPF inválido!" : true
                    })}
                    errors={errors}
                    disabled={statusVigenciaFechado}
                  />
                </Grid>
                <Grid item xs={12} sm={4} lg={4}>
                  <MaterialInputTexto
                    type="text"
                    id="emailTestemunha"
                    name="emailTestemunha"
                    label="E-mail"
                    renderIconShowHide={false}
                    defaultValue={dadosTestemunha?.email ?? ""}
                    permiteValorBranco
                    ref={register({
                      required: enviarForm
                        ? "O campo E-mail é obrigatório!"
                        : false,
                      pattern: {
                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "Campo E-mail inválido!"
                      },
                      maxLength: {
                        value: 100,
                        message: "Quantidade máxima de 100 caracteres!"
                      }
                    })}
                    errors={errors}
                    disabled={statusVigenciaFechado}
                  />
                </Grid>
                <Grid item xs={12} sm={4} lg={4}>
                  <MaterialInputTelefone
                    type="text"
                    id="telefoneTestemunha"
                    name="telefoneTestemunha"
                    label="Telefone"
                    renderIconShowHide={false}
                    defaultValue={dadosTestemunha?.telefone ?? " "}
                    ref={register({
                      required: enviarForm
                        ? "O campo Telefone é obrigatório!"
                        : false,
                      minLength: {
                        value: 18,
                        message: "Quantidade mínima de 12 caracteres!"
                      }
                    })}
                    errors={errors}
                    disabled={statusVigenciaFechado}
                  />
                </Grid>
                <Grid item xs={12} sm={4} lg={4}>
                  <SelectArredondado
                    id="estadoCivilTestemunha"
                    name="estadoCivilTestemunha"
                    valueKey="value"
                    valueName="label"
                    dataSource={estadoCivilList}
                    label="Estado Civil"
                    value={estadoCivilTestemunha ?? ""}
                    onChange={(e) => setEstadoCivilTestemunha(e.target.value)}
                    placeholder="Estado civil"
                    allowClear={!statusVigenciaFechado}
                    ref={register(
                      { name: "estadoCivilTestemunha" },
                      {
                        required:
                          enviarForm && !estadoCivilTestemunha
                            ? "Campo Estado civil é obrigatório!"
                            : false
                      }
                    )}
                    errors={errors}
                    disabled={statusVigenciaFechado}
                  />
                </Grid>
                <Grid item xs={12} sm={4} lg={4}>
                  <MaterialInputTexto
                    type="text"
                    id="nacionalidadeTestemunha"
                    name="nacionalidadeTestemunha"
                    label="Nacionalidade"
                    renderIconShowHide={false}
                    defaultValue={dadosTestemunha?.nacionalidade ?? ""}
                    ref={register({
                      required: enviarForm
                        ? "Campo Nacionalidade é obrigatório!"
                        : false,
                      pattern: {
                        value: /([^\d$&+,:;=?@#|'<>.^*()%!-]*$)/g,
                        message:
                          "Deve ser informado apenas letras e espaços no nome!"
                      }
                    })}
                    errors={errors}
                    disabled={statusVigenciaFechado}
                  />
                </Grid>
                <Grid item xs={12} sm={4} lg={4}>
                  <MaterialInputTexto
                    type="text"
                    id="profissaoTestemunha"
                    name="profissaoTestemunha"
                    label="Profissão"
                    renderIconShowHide={false}
                    permiteValorBranco
                    defaultValue={dadosTestemunha?.profissao || ""}
                    ref={register({
                      required: enviarForm
                        ? "Campo Profissão é obrigatório!"
                        : false,
                      maxLength: {
                        value: 50,
                        message: "Quantidade máxima de 50 caracteres!"
                      },
                      validate: (value) =>
                        // eslint-disable-next-line quotes
                        (!value.includes("'") && !value.includes('"')) ||
                        "Não é permitido adicionar aspas no Nome Fantasia"
                    })}
                    errors={errors}
                    disabled={statusVigenciaFechado}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Card>
      </form>

      {!statusVigenciaFechado && (
        <Grid container spacing={2} pt={3}>
          <Grid item lg={3} md={6} sm={6} xs={12}>
            <BotaoForm
              onClick={() => {
                setEnviarForm(false);
                setTipoContratacao("");
              }}
              id="salvarId"
              form="hook-form"
              label="Salvar"
              type="submit"
              style={{ color: "#FA3EB1", border: "1px solid #FA3EB1" }}
              titleTooltip="Salva a cotação."
              labelTootip="salvar"
              styleTooltip={{ sx: { fontSize: "15px" } }}
            />
          </Grid>

          <Grid item lg={3} md={6} sm={6} xs={12}>
            <BotaoForm
              onClick={() => {
                setEnviarForm(parceiro === "naoParceiro");
                setTipoContratacao("PropostaContrato");
              }}
              id="propostaContratoId"
              form="hook-form"
              label="Proposta + Contrato"
              type="submit"
              style={{ background: "#9F9F9F", border: "1px solid #9F9F9F" }}
              titleTooltip="Envio da proposta e contrato de forma conjunta."
              labelTootip="propostaContrato"
              styleTooltip={{ sx: { fontSize: "15px" } }}
            />
          </Grid>

          <Grid item lg={3} md={6} sm={6} xs={12}>
            <BotaoForm
              onClick={() => {
                setEnviarForm(parceiro === "naoParceiro");
                setTipoContratacao("Contrato");
              }}
              id="contratacaoId"
              form="hook-form"
              label="Contrato"
              type="submit"
              style={{ background: "#9F9F9F", border: "1px solid #9F9F9F" }}
              titleTooltip="Envio somente do contrato."
              labelTootip="contrato"
              styleTooltip={{ sx: { fontSize: "15px" } }}
            />
          </Grid>

          <Grid item lg={3} md={6} sm={6} xs={12}>
            <BotaoForm
              onClick={() => {
                setEnviarForm(parceiro === "naoParceiro");
                setTipoContratacao("PropostaDepoisContrato");
              }}
              id="propostaDepoisContratoId"
              form="hook-form"
              label="Proposta > Contrato"
              type="submit"
              style={{ background: "#9F9F9F", border: "1px solid #9F9F9F" }}
              titleTooltip="Envio da proposta e após assinatura da mesma o contrato é enviado."
              labelTootip="propostaDepoisContrato"
              styleTooltip={{ sx: { fontSize: "15px" } }}
            />
          </Grid>
        </Grid>
      )}
    </CustomLoader>
  );
};

export default DadosEmpresaACL;
