import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { generate } from "shortid";

import { Box, Button } from "@mui/material";
import {
  EditOutlined,
  Send,
  GetApp,
  CheckBox,
  CheckBoxOutlineBlank
} from "@mui/icons-material";

import { HtmlTooltip } from "../../../../Graficos/ConsumoAtual/style";

import enumeradorBotao from "../../enumeradores/enumeradorBotao";
import { useStyles } from "./style";

const ColunaMultiplosBotoes = ({ onClick, parametros }) => {
  const classes = useStyles();

  const onClickEdicao = () => onClick(enumeradorBotao.Edicao, parametros);
  const onClickContrato = () => onClick(enumeradorBotao.Contrato, parametros);
  const onClickPDF = () => onClick(enumeradorBotao.PDF, parametros);
  const onClickParceiro = (tipoAprovacao) =>
    onClick(enumeradorBotao.Parceiro, parametros, tipoAprovacao);

  const exibirAprovaContratoMaster = useMemo(() => {
    return (
      parametros?.usuarioCargoId !== enumeradorBotao.Parceiro &&
      parametros?.cargoId === enumeradorBotao.Parceiro &&
      !parametros?.envelopeId &&
      parametros?.aprovaContratoMaster
    );
  }, [parametros?.aprovaContratoMaster]);

  const exibirAprovaContratoComercial = useMemo(() => {
    return (
      parametros?.usuarioCargoId !== enumeradorBotao.Parceiro &&
      parametros?.cargoId === enumeradorBotao.Parceiro &&
      !parametros?.envelopeId &&
      parametros?.aprovaContratoComercial
    );
  }, [parametros?.aprovaContratoComercial]);

  return (
    <>
      <Box
        className={`${classes.fundoCelula}`}
        display="flex"
        justifyContent="space-around"
        alignItems="center"
        flexWrap="wrap"
      >
        <Box className={classes.fundoBotoes}>
          <Button
            key={generate()}
            onClick={onClickContrato}
            className={`${classes.botao} ${
              parametros?.desabledBtnContrato ? classes.inativo : classes.ativo
            }`}
            disabled={parametros?.desabledBtnContrato}
          >
            <HtmlTooltip
              title={
                parametros?.desabledBtnContrato
                  ? "Sem contrato"
                  : "Reenviar contrato"
              }
            >
              <Send htmlColor="#FA3EB1" />
            </HtmlTooltip>
          </Button>
        </Box>

        <Box className={`${classes.fundoBotoes}`}>
          <Button
            key={generate()}
            onClick={onClickEdicao}
            className={classes.botao}
          >
            <HtmlTooltip title="Editar">
              <EditOutlined htmlColor="#FA3EB1" />
            </HtmlTooltip>
          </Button>
        </Box>
        <Box className={classes.fundoBotoes}>
          <Button
            key={generate()}
            onClick={() => onClickPDF()}
            className={`${classes.botao} ${
              parametros?.desabledBtnDownload ? classes.inativo : classes.ativo
            }`}
            disabled={parametros?.desabledBtnDownload}
          >
            <HtmlTooltip title="Download">
              <GetApp htmlColor="#FA3EB1" />
            </HtmlTooltip>
          </Button>
        </Box>
        {exibirAprovaContratoComercial && (
          <Box className={classes.fundoBotoes}>
            <Button
              key={generate()}
              onClick={() => onClickParceiro("comercial")}
              className={classes.botao}
            >
              <HtmlTooltip
                title={`${
                  parametros?.liberaPropostaContrato === "S"
                    ? "Bloquear"
                    : "Liberar"
                } Contrato Comercial`}
              >
                {parametros?.liberaPropostaContrato === "S" ? (
                  <CheckBox htmlColor="#FA3EB1" />
                ) : (
                  <CheckBoxOutlineBlank htmlColor="#FA3EB1" />
                )}
              </HtmlTooltip>
            </Button>
          </Box>
        )}

        {exibirAprovaContratoMaster && (
          <Box className={classes.fundoBotoes}>
            <Button
              key={generate()}
              onClick={() => onClickParceiro("master")}
              className={classes.botao}
            >
              <HtmlTooltip
                title={`${
                  parametros?.liberaPropostaContratoMaster === "S"
                    ? "Bloquear"
                    : "Liberar"
                } Contrato Master`}
              >
                {parametros?.liberaPropostaContratoMaster === "S" ? (
                  <CheckBox htmlColor="#FA3EB1" />
                ) : (
                  <CheckBoxOutlineBlank htmlColor="#FA3EB1" />
                )}
              </HtmlTooltip>
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
};

ColunaMultiplosBotoes.propTypes = {
  onClick: PropTypes.oneOfType([PropTypes.func]).isRequired,
  parametros: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired
};

export default ColunaMultiplosBotoes;
