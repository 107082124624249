/* eslint-disable react/prop-types */
import React, { useEffect, useMemo } from "react";
import { Grid } from "@mui/material";
import { store } from "global/redux";
import { SetModoEnvioAssinatura } from "global/redux/modulos/workflow/actions";
import { useSelector } from "react-redux";
import { ProtocolosEtapas, tipoEnvioContrato } from "global/constantes";
import { useStyles } from "./style";
import EsqueletoEtapa from "../../Componentes/EsqueletoEtapa";

const AguardandoAssinaturaConfirmacaoContrato = ({
  prazo,
  responsavel,
  onProximaEtapa,
  esconderBotaoProximo,
  onVoltarEtapa
}) => {
  const classes = useStyles();
  const workflowStore = useSelector((str) => str.workflow);

  const onClickProximo = () => {
    onProximaEtapa(ProtocolosEtapas.Fim);
  };

  const onClickVoltar = () => {
    onVoltarEtapa(ProtocolosEtapas.AssinarContratoProcuracao);
  };

  const textoEnvio = useMemo(() => {
    if (String(workflowStore.modoEnvio) === String(tipoEnvioContrato.SMS))
      return "SMS, em caso de não ter recebido aguarde alguns minutos";

    return "Email, verifique em sua caixa de entrada ou spam";
  });

  useEffect(() => {
    return () => {
      store.dispatch(SetModoEnvioAssinatura(null));
    };
  }, []);

  return (
    <EsqueletoEtapa
      prazo={prazo}
      responsavel={responsavel}
      titulo="Aguardando assinatura dos documentos"
      onClickProximo={() => onClickProximo()}
      onClickVoltar={() => onClickVoltar()}
      esconderBotaoProximo={esconderBotaoProximo}
    >
      <Grid className={classes.containerContent}>
        <h5>
          <p>Os documentos foram enviados para assinatura via: </p>
          <p>{textoEnvio}</p>
        </h5>
      </Grid>
    </EsqueletoEtapa>
  );
};

export default AguardandoAssinaturaConfirmacaoContrato;
