import React from "react";

import PropTypes from "prop-types";

// Componentes
import { Box } from "@mui/material";

// Styles
import { useStyles } from "./style";
import IconeLista from "../../../../assets/jornadaCliente/icone-lista.png";
import IconeLuz from "../../../../assets/jornadaCliente/icone-luz.png";
import IconeSeta from "../../../../assets/jornadaCliente/seta-luz.png";
import IconeSol from "../../../../assets/jornadaCliente/icone-sol.png";

const Pagina1 = ({ titulo, subtitulo, conteudo }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.boxFundoRoxo}>
        <Box className={classes.boxTexto}>
          <img
            className={classes.iconeLista}
            src={IconeLista}
            alt="imagem lista"
          />
          {titulo}
          <Box className={classes.boxTextoFlex}>
            {subtitulo}
            <img
              className={classes.iconeSeta}
              src={IconeSeta}
              alt="imagem seta"
            />
          </Box>
        </Box>

        <img className={classes.iconeSol} src={IconeSol} alt="imagem sol" />
      </Box>
      <Box className={classes.conteudo}>{conteudo}</Box>
      <img className={classes.iconeLuz} src={IconeLuz} alt="imagem luz" />
    </Box>
  );
};

Pagina1.propTypes = {
  titulo: PropTypes.node,
  subtitulo: PropTypes.node,
  conteudo: PropTypes.node
};

Pagina1.defaultProps = {
  titulo: <></>,
  subtitulo: <></>,
  conteudo: <></>
};

export default Pagina1;
