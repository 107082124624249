import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Redux
import { Card, Grid, Table, TableCell, TableRow } from "@mui/material";
import { store } from "../../../../global/redux";
import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";

// Componentes
import BotaoRetornarListagem from "../../../../componentes/botaoRetornarListagem";

// Rotas
import { RotasDTO } from "../../../../global/rotas/rotasUrlDto";

// Styles
import { useStyles } from "./style";

// Helper
import preFaturamentoHelper from "./helper";

const PreFaturamentoRastreamento = () => {
  const { id } = useParams();
  const classes = useStyles();
  const [listaRastreamento, setListaRastreamento] = useState();

  useEffect(() => {
    obterListaRastreamento();
  }, []);

  const obterListaRastreamento = async () => {
    try {
      const resultado = await preFaturamentoHelper.listarPreFaturamentoRastreamento(
        id
      );
      setListaRastreamento(resultado?.data);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  return (
    <Card className={classes.container}>
      <Grid container p={2} spacing={2} className={classes.container}>
        <Grid item xs={6} className="font-weight-bold">
          Pré Faturamento Rastreamento #{" "}
          {listaRastreamento?.nomeUnidadeConsumidora} -{listaRastreamento?.mes}/
          {listaRastreamento?.ano} #
        </Grid>
        <BotaoRetornarListagem
          urlListagem={RotasDTO.PreFaturamento}
          zerarCombo
        />
        {listaRastreamento?.listPreFaturamentoControle?.map((item) => {
          return (
            <Table>
              <TableRow>
                <TableCell width="20%">{item.dataAtualizacao}</TableCell>
                <TableCell width="20%">{item?.statusNome}</TableCell>
                <TableCell width="60%">
                  {item?.listaAviso.map((aviso) => {
                    return <Grid>{aviso}</Grid>;
                  })}
                </TableCell>
              </TableRow>
            </Table>
          );
        })}
      </Grid>
    </Card>
  );
};

export default PreFaturamentoRastreamento;
