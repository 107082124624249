import { makeStyles } from '@mui/styles';
import styled from "styled-components";
import { Base } from "componentes/cores";

export const useStyles = makeStyles(() => ({
  container: {
    margin: 0,
    width: "100%",
    "& .MuiGrid-root": {
      color: `${Base.BlackBastille} !important`
    },
    "& .MuiDropzoneArea-text": {
      color: `${Base.BlackBastille} !important`
    },
    "& .MuiInputBase-root": {
      height: "46px"
    },
    "& .MuiFormLabel-root": {
      lineHeight: "1"
    },
  },
  containerTitulo: {
    padding: "18px !important"
  },
  titulo: {
    fontSize: "18px",
    color: "#000 !important",
    fontWeight: "700"
  },
  containerData: {
    paddingTop: "32px !important",
    paddingLeft: '32px !important',
    paddingBottom: "32px !important",
  },

  icon: {
    cursor: "pointer",
    float: "right",
    margin: "5px",
    color: "#000 !important",
    fontWeight: "700"
  },
  button: {
    fontSize: "18px",
  },
  buttonCustom: {
    fontSize: "18px",
    color: "#FEBB2F !important",
    backgroundColor: "transparent !important",
    "&:hover": {
      color: "#212529",
    }
  },
  customSelect: {
    "& .MuiFormControl-root": {
      borderRadius: "4px",
      color: "gray",
      border: "1px solid gray"
    },

    "& .MuiOutlinedInput-inputAdornedEnd": {
      color: "gray",
    }
  },
  customPosition: {
    display: "flex",
    justifyContent: "center"
  },
  customText: {
    display: "flex",
    justifyContent: "space-around"
  },
  selectOfficer: {
    filter: "invert(1)",
    marginBottom: "0px"

  },
}));

export const Tabela = styled.table``;

Tabela.Thead = styled.thead`
  background: #DCDCDC;
`;

Tabela.Tr = styled.tr``;

Tabela.Th = styled.th`
  padding: 4px;
  border: 4px solid white;
  text-align: center;
`;

Tabela.Tbody = styled.tbody`
  background: #F0F0F0;
`;

Tabela.Td = styled.td`
  padding: 2px;
  border: 2px solid white;
  text-align: center;
  min-width: 160px;
`;

Tabela.Tfoot = styled.tfoot``;

export const TabelaScroll = styled.div`
  display: flex;
  border: 1px solid #DCDCDC;
  padding: 10px;
  max-width: 970px;

  div {
    display: flex;
    overflow: auto;
  }

  #scroll {
    padding-bottom: 10px;

    ::-webkit-scrollbar {
      height: 6px;
      background-color: #F5F5F5;
    }

    ::-webkit-scrollbar-track {
      background-color: #F5F5F5;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #DCDCDC;
      border-radius: 10px;
    }
  }
`