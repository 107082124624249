export default class ConsumoDto {
  constructor(
    labelMes,
    contratoMax,
    contrato,
    contratoMin,
    meuConsumo,
    porcentagemUtilizada
  ) {
    this.labelMes = labelMes;
    this.contratoMax = contratoMax;
    this.contrato = contrato;
    this.contratoMin = contratoMin;
    this.meuConsumo = meuConsumo;
    this.porcentagemUtilizada = porcentagemUtilizada;
  }
}
