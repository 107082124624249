import { makeStyles } from '@mui/styles';
import { Base } from 'componentes/cores';
import theme from 'themes';

export const useStyles = makeStyles(() => ({
  container: {
    margin: 0,
    width: '100%',
    "& .MuiGrid-root": {
      color: `${Base.BlackBastille} !important`,
    },
    "& .MuiDropzoneArea-text": {
      color: `${Base.BlackBastille} !important`,
    },
    "& .MuiInputBase-root": {
      height: "46px"
    },
    "& .MuiFormLabel-root": {
      lineHeight: "1"
    },
  },
  containerTitulo: {
    padding: '18px !important'
  },
  titulo: {
    fontSize: '18px'
  },
  containerData: {
    padding: '32px !important'
  },
  icon: {
    cursor: "pointer",
    float:"right",
    margin: "5px"
  },
  button: {
    fontSize: '18px',
  },
  customButton: {
    fontSize: '18px',
    backgroundColor: "transparent !important",

    "&:hover": {
      color: `${Base.BlackBastille} !important`,
    }
  },
  fileUpload: {
    "& .MuiDropzoneArea-root": {
      minHeight: "150px",
      borderWidth: "2px",
      width: "100%"
    },
    "& .MuiDropzoneArea-text": {
      fontSize: "16px"
    }
  },
  lista: {
    listStyle: "none",
    padding: 0,

    "& li": {
      background: "#DCDCDC",
      margin: "4px",
      padding: "4px",
      cursor: "pointer",
    }
  },
  customColorText: {
    color: Base.BlackBastille
  }
}));