import { makeStyles } from "@mui/styles";
import theme from "../../../../../themes";

export const useStyles = makeStyles(() => ({
  cardUpload: {
    border: "none",
    borderRadius: 10,
    borderWidth: 1,
    padding: "25px",
    marginBottom: "13px",
    display: "flex",
    "& .MuiDropzoneArea-root": {
      border: `4px dashed ${theme.color.bordaUpload}`,
      background: "rgba(250, 62, 177, 0.08)"
    },
    "& .MuiDropzoneArea-icon": {
      color: theme.color.bordaUpload
    },
    "& .MuiDropzoneArea-textContainer": {
      display: "flex",
      alignItems: "center",
      "& .MuiDropzoneArea-icon": {
        marginTop: "10px",
        marginBottom: 0,
        width: "40px",
        marginRight: 0,
        alignSelf: "center",
        height: "auto"
      },
      "& .MuiDropzoneArea-text": {
        fontFamily: theme.fontTahoma.family,
        margin: 0,
        fontSize: "18px",
        fontWeight: "400"
      }
    },
    "& .MuiChip-label": {
      color: "#fff"
    }
  }
}));
