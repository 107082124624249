import React, { useState } from "react";
import PropTypes from "prop-types";
import PhoneInput from "react-phone-input-2";
import { useStyles } from "./style";

function InputTextoPhone({
  id,
  name,
  setValorTelefone,
  label,
  placeholder,
  setValue,
  value,
  exibirErroTelefone,
  erroTelefoneObrigatorio
}) {
  const classes = useStyles();

  const [telefoneLabel, setTelefoneLabel] = useState("");

  return (
    <>
      <PhoneInput
        country="br"
        localization="pt"
        placeholder={placeholder}
        masks={{ br: "(..) .....-...." }}
        specialLabel={telefoneLabel}
        onFocus={() => setTelefoneLabel(label)}
        onBlur={() => setTelefoneLabel("")}
        onChange={(valor) => {
          setValorTelefone(valor);
          setValue(name, valor);
        }}
        value={value}
        inputClass={`MuiInputBase-input ${classes.inputTelefone}`}
        containerClass={exibirErroTelefone ? classes.containerError : ""}
        inputProps={{
          id,
          name
        }}
      />
      {exibirErroTelefone ? (
        <p
          className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error"
          id="UserName-helper-text"
        >
          {erroTelefoneObrigatorio
            ? "O Campo Telefone é Obrigátorio"
            : "Telefone Inválido"}
        </p>
      ) : null}
    </>
  );
}

InputTextoPhone.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  setValorTelefone: PropTypes.oneOfType([PropTypes.func]).isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  setValue: PropTypes.oneOfType([PropTypes.func]).isRequired,
  value: PropTypes.oneOfType([PropTypes.any]),
  exibirErroTelefone: PropTypes.oneOfType([PropTypes.bool]).isRequired,
  erroTelefoneObrigatorio: PropTypes.oneOfType([PropTypes.bool]).isRequired
};

InputTextoPhone.defaultProps = {
  value: ""
};

export default InputTextoPhone;
