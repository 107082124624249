import { makeStyles } from "@mui/styles";
import styled from "styled-components";
import { createTheme } from "@mui/material/styles";
import theme from "../../..";
import { Base } from "../../../../componentes/cores";

const th = createTheme();

export const useStyles = makeStyles(() => ({
  card: {
    boxShadow: "0px 2px 4px 1px #00000029 !important"
  },
  titulo: {
    color: theme.color.primary,
    fontFamily: theme.font.family,
    fontSize: "24px",
    fontWeight: 700,
    [th.breakpoints.down("sm")]: {
      fontSize: "20px"
    }
  },
  sombra: { boxShadow: "0 3px 10px rgb(0 0 0 / 0.2) !important" },
  textoCapitalize: {
    textTransform: "capitalize",
    marginRight: "2px"
  },
  texto: {
    color: theme.color.primary,
    display: "flex",
    marginBottom: 0
  },
  preco: { color: "#4F4F4F", fontSize: "24px", fontWeight: 700 },
  textoCinza: {
    color: "#9F9F9F",
    marginBottom: 0,
    fontSize: "16px"
  }
}));

export const Botao = styled.button`
  color: ${Base.White};
  background-color: ${theme.color.secondary};
  border-color: transparent;
  border-radius: 24px;
  font-weight: 600;
  font-size: 14px;
  padding: 4px 16px;
`;

export const BotaoTransparent = styled.button`
  color: #000000;
  background-color: transparent;
  border-color: transparent;
  color: ${theme.color.primary};
`;

export const Chip = styled.div`
  width: auto;
  color: ${Base.White};
  background-color: ${(props) => props.background};
  border-radius: 24px;
  font-size: 14px;
  padding: 4px 16px;
  margin-right: 10px;
`;

export const Icone = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 10px;
`;
