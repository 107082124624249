export default class FiltroPesquisaPaginada {
  constructor(
    pagina,
    totalPagina,
    colunaOrdenacao,
    ordenacao,
    pesquisar,
    filtrosAdicionais,
    filtroPersonalizado
  ) {
    this.pagina = pagina;
    this.totalPagina = totalPagina;
    this.colunaOrdenacao = colunaOrdenacao;
    this.ordenacao = ordenacao;
    this.pesquisar = pesquisar;
    this.filtrosAdicionais = filtrosAdicionais;
    this.filtroPersonalizado = filtroPersonalizado;
  }
}
