const mapeamentoEtapaFluxo = {
  comDesconto: "Etapa 1: Criação de Lead com desconto inicial",
  semDesconto: "Etapa 1:  Criação de Lead",
  erroApi1: "Etapa 1: Erro genérico - API",
  planosGerado: "Etapa 3: Plano gerado com sucesso",
  semMatriz: "Etapa 3: Não foi possível gerar a matriz desconto",
  serasa: "Etapa 3: Reprovado SERASA",
  distribuidora: "Etapa 1: Criação de Lead - Distribuidora não atendida",
  distribuidoraSimulador:
    "Etapa 3: Criação de Lead - Distribuidora não atendida",
  injetada: "Etapa 3: Cliente não elegível - Injetada",
  tarifaBranca: "Etapa 3: Cliente não elegível - Tarifa Branca",
  bOptante: "Etapa 3: Cliente não elegível - Boptante",
  debitos: "Etapa 3: Cliente não elegível - Inadimplencia",
  baixaRenda: "Etapa 3: Cliente não elegível - Baixa renda",
  erroApi2: "Etapa 3: Erro genérico - API",
  salvarCotacao: "Etapa 4: Formulário de dados gerais e contrato não gerado",
  enviarContrato: "Etapa 4: Formulário de dados gerais e contrato gerado",
  unidadeConsumidora:
    "Etapa 4: Unidade consumidora já contratada - Contrato não gerado",
  erroApi3: "Etapa 4: Erro genérico - API"
};

export default mapeamentoEtapaFluxo;
