import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Card, Grid } from '@mui/material';
import { useStyles } from './style';
import BotaoRetornarListagem from 'componentes/botaoRetornarListagem';
import UploadDropArea from 'componentes/upload';
import Botao from 'componentes/botao';
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { importarBaseRateioESS } from '../../../../../servicos/premissasBrasil';
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import RotasDTO from "../../../../../rotas/rotasUrlDto";
import { useHistory } from "react-router-dom";
import { Loader } from 'componentes';

const BaseRateioESSImportar = () => {
  const classes = useStyles();
  const usuarioGlobal = useSelector((state) => state.usuario);
  const msgPadraoUpload = "Solte o arquivo para anexá-lo ou procurar";
  const history = useHistory();

  const onFechar = (id) => {
    history.push(`${RotasDTO.ProjecaoInsumoListar}`);
  }

  const { handleSubmit } = useForm({
    reValidateMode: 'onSubmit',
  });

  const [file, setFile] = useState(false);
  const [fileName, setFileName] = useState(msgPadraoUpload);
  const [importando, setImportando] = useState(false);

  const processarArquivo = (arquivo) => {
    if (arquivo != null && arquivo[0] != null) {
      setFile(arquivo[0].file);
      setFileName(arquivo[0].file.name);
    }
  };

  const onAddUpload = (arquivo) => {
    processarArquivo(arquivo);
  };

  const EnviarForm = async () => {
    try {
      setImportando(true);
      const response = await importarBaseRateioESS(file, usuarioGlobal?.usuario?.id, usuarioGlobal?.usuario?.usuarioNome);
      if (response.status === 200 && response.data) {
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: "Arquivo importado com sucesso!"
          })
        );
        setFile(false);
        setFileName(msgPadraoUpload);
        setImportando(false);
      } else {
        const { ItensValidacao, DistribuidorasNaoEncontradas } = response.data;
        setImportando(false);
        if (ItensValidacao.length > 0) {

          store.dispatch(
            alertaExibir({
              tipo: "warning",
              mensagem: `${ItensValidacao}`
            })
          );
        }

        if (DistribuidorasNaoEncontradas.length > 0) {
          DistribuidorasNaoEncontradas.map((item) => (
            store.dispatch(
              alertaExibir({
                tipo: "warning",
                mensagem: `${item}`
              })
            )
          ))
        }
      }
    } catch (error) {
      setImportando(false);
      setFileName(msgPadraoUpload);
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  }

  const aoEnviarForm = async () => {
    if (file) {
      EnviarForm();
    }
  };

  return (
    <form className="needs-validation" onSubmit={handleSubmit(aoEnviarForm)}>
      <Loader loading={importando} className="w-auto">
        <Card>
          <Grid container className={classes.containerTitulo}>
            <Grid item xs={11} className={classes.titulo}>
              Importação de Planilha de Base de Rateio ESS
            </Grid>
            <Grid item xs={1}>
              <CloseIcon onClick={onFechar} className={classes.icon} />
            </Grid>
          </Grid>
          <Grid container p={2} spacing={4} className={classes.container}>
            <Grid item sm={12}>
              <UploadDropArea
                onAdd={onAddUpload}
                showLoader={false}
                dropAreaText={fileName}
                acceptedFiles={[
                  '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
                ]}
              />
            </Grid>
            <Grid item sm={3}>
              <Botao
                type="submit"
                label="Salvar"
                className={classes.button}
              />
            </Grid>
          </Grid>
        </Card>
      </Loader>
    </form>
  )
}

export default BaseRateioESSImportar;