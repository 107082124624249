/* eslint-disable react/prop-types */
import { GetApp } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Grid,
  useMediaQuery,
  useTheme
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ColunaComponentePersonalizado from "../../../componentes/tabelaPaginada/colunas/colunaComponentePersonalizado";
import ResultadoPaginadoDto from "../../../componentes/tabelaPaginada/resultadoPaginadoDto";
import TabelaPaginada from "./componentes/tabelaPaginada";
import TabelaPaginadaMobile from "./componentes/tabelaPaginada/mobile";
import HelperDocumentos from "./helpers";
import ListaDto from "./ListaDto";
import { HtmlTooltip, useStyles } from "./style";

import ClienteNaoSelecionada from "./componentes/clienteNaoSelecionada";

const ContaDocumentos = () => {
  const classes = useStyles();
  const usuario = useSelector((state) => state.usuario);
  const { clienteSelecionado } = usuario;

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("lg"));
  const refTabela = useRef(null);
  const [filtroAdicionalTabela, setFiltroAdicionalTabela] = useState({
    clienteSelecionado
  });

  // eslint-disable-next-line no-unused-vars
  const listarDocumentosCliente = async (params) => {
    if (!clienteSelecionado) return true;

    const {
      data,
      sucesso,
      mensagem
    } = await HelperDocumentos.obterDocumentosClientePorClienteId(
      clienteSelecionado
    );

    if (!sucesso) {
      HelperDocumentos.exibirErro(mensagem);
      return new ResultadoPaginadoDto([], 1, 0, 0);
    }

    const resLinhas = data?.map((item) => {
      return new ListaDto(
        item?.id,
        item?.clienteId,
        item?.tipoDocumentoId,
        item?.nomeArquivo,
        item?.dataUpload,
        item?.pessoaFisicaJuridica
      );
    });

    return new ResultadoPaginadoDto(
      resLinhas,
      0, // pagina atual
      0, // total itens
      0 // total paginas
    );
  };

  useEffect(() => {
    if (!clienteSelecionado) return;
    setFiltroAdicionalTabela({ clienteSelecionado });
  }, [clienteSelecionado]);

  // eslint-disable-next-line react/prop-types
  const ColunaDownload = ({ parametros }) => {
    const onClickBotao = async ({ id, nomeArquivo }) => {
      await HelperDocumentos.obterUrlDocumentoCliente(id, nomeArquivo);
    };

    return (
      <Box
        component={Grid}
        container
        spacing={0}
        className={classes.fundoCelula}
        display="flex"
        justifyContent="center"
      >
        <Grid item>
          <Button
            onClick={() => onClickBotao(parametros)}
            className={`text-white ${classes.botao}`}
          >
            <HtmlTooltip title="Baixar">
              <GetApp />
            </HtmlTooltip>
          </Button>
        </Grid>
      </Box>
    );
  };

  const ColunaMobile = ({ parametros }) => {
    const onClickBotao = async ({ id, nomeArquivo }) => {
      await HelperDocumentos.obterUrlDocumentoCliente(id, nomeArquivo);
    };

    return (
      <Grid container className={classes.fundoCelulaMobile}>
        <Grid item xs={10}>
          <Grid item>
            <HtmlTooltip title={parametros.nomeArquivo}>
              <div
                className={`${classes.linhaTooltip} ${classes.nomeRelatorio}`}
              >
                {parametros.nomeArquivo}
              </div>
            </HtmlTooltip>
          </Grid>
          <Grid item xs={12} className={`${classes.dataUpload}`}>
            {parametros.dataUpload}
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <Button
            onClick={() => onClickBotao(parametros)}
            className="text-white"
          >
            <HtmlTooltip title="Baixar">
              <GetApp className={`${classes.botao}`} />
            </HtmlTooltip>
          </Button>
        </Grid>
      </Grid>
    );
  };

  const ColunaNomesArquivo = ({ parametros }) => {
    return (
      <HtmlTooltip title={`${parametros?.nomeArquivo}`}>
        <Box className={`${classes.linhaTooltip}`}>
          {parametros.nomeArquivo}
        </Box>
      </HtmlTooltip>
    );
  };

  const handleTipoDocumento = (tipoId, fisicaJuridica) => {
    const value = `${tipoId}-${fisicaJuridica}`;
    switch (value) {
      case "1-PF":
        return "Documento de identificação.";
      case "2-PF":
        return "Foto com o documento escolhido.";
      case "1-PJ":
        return "Documento de identificação dos representantes legais.";
      case "2-PJ":
        return "Contrato Social/Estatuto Social.";
      default:
        return "tipoDocumentoId ou pessoaFisicaJuridica não encontrado!";
    }
  };

  const ColunaTiposDocumento = ({ parametros }) => {
    const { tipoDocumentoId, pessoaFisicaJuridica } = parametros;
    return (
      <HtmlTooltip
        title={`${handleTipoDocumento(tipoDocumentoId, pessoaFisicaJuridica)}`}
      >
        <Box className={`${classes.linhaTooltip}`}>
          {handleTipoDocumento(tipoDocumentoId, pessoaFisicaJuridica)}
        </Box>
      </HtmlTooltip>
    );
  };

  const ColunaDataUpload = ({ parametros }) => {
    const { dataUpload } = parametros;
    return (
      <HtmlTooltip title={`${dataUpload}`}>
        <Box className={`${classes.linhaTooltip}`}>{dataUpload}</Box>
      </HtmlTooltip>
    );
  };

  const colunas = [];

  if (!mobile) {
    colunas.push(
      new ColunaComponentePersonalizado(
        "nomeArquivo",
        "Documentos",
        ColunaNomesArquivo,
        null,
        false,
        false,
        null,
        "30%"
      )
    );
    colunas.push(
      new ColunaComponentePersonalizado(
        "tipoDocumentoId",
        "Tipo de documento",
        ColunaTiposDocumento,
        null,
        false,
        false,
        null,
        "30%"
      )
    );
    colunas.push(
      new ColunaComponentePersonalizado(
        "dataUpload",
        "Data de upload",
        ColunaDataUpload,
        null,
        false,
        false,
        null,
        "30%"
      )
    );
    colunas.push(
      new ColunaComponentePersonalizado(
        "opcoes",
        "",
        ColunaDownload,
        null,
        false,
        true,
        null,
        null,
        "pl-2"
      )
    );
  } else {
    colunas.push(
      new ColunaComponentePersonalizado(
        "nome",
        "",
        ColunaMobile,
        null,
        false,
        false,
        null,
        null
      )
    );
  }

  const onChangeFiltrosTabela = async (params) => {
    return listarDocumentosCliente(params);
  };

  return (
    <>
      {clienteSelecionado === "" ? (
        <ClienteNaoSelecionada />
      ) : (
        <Card>
          <Box component={Grid} container py={2} px={3}>
            <Box fontSize="24px" fontWeight="700" color="#332053" mb={4}>
              Baixar documentos
            </Box>
            <Grid item xs={12}>
              {!mobile ? (
                <TabelaPaginada
                  className=""
                  ref={refTabela}
                  onChangeFiltrosTabela={onChangeFiltrosTabela}
                  filtrosAdicionais={filtroAdicionalTabela}
                  colunas={colunas}
                  paginaAtual={1}
                  noHeader
                  noFooter={false}
                />
              ) : (
                <Grid>
                  <TabelaPaginadaMobile
                    className={classes.fundoCelulaTransparente}
                    ref={refTabela}
                    onChangeFiltrosTabela={onChangeFiltrosTabela}
                    filtrosAdicionais={filtroAdicionalTabela}
                    colunas={colunas}
                    paginaAtual={1}
                    noFooter={false}
                  />
                </Grid>
              )}
            </Grid>
          </Box>
        </Card>
      )}
    </>
  );
};

export default ContaDocumentos;
