export default class AgenteDto {
  constructor(
    cnpj,
    razaoSocial,
    codigoAgente,
    siglaAgente,
    ativo,
    clienteId,
    descricaoDoAgente,
    id
  ) {
    this.Cnpj = cnpj.replace(/\D+/g, "");
    this.RazaoSocial = razaoSocial;
    this.CodigoAgenteCCEE = codigoAgente;
    this.SiglaAgenteCCEE = siglaAgente;
    this.Ativo = ativo;
    this.Id = id;
    this.ClienteId = clienteId;
    this.DescricaoDoAgente = descricaoDoAgente;
  }
}
