import { store } from "../../../global/redux";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";
import RetornoEndpointDto from "../../../global/dto/retornoEndpointDto";
import { exportarExcelCuponsAtivos } from "../../../servicos/memberGetMemberServico";

export default class MemberGetMemberCupom {
  static async exportarExcelCuponsAtivos(setLoading) {
    try {
      setLoading(true);
      const resultado = await exportarExcelCuponsAtivos();

      if (resultado?.status === 204)
        RetornoEndpointDto.Erro("Cupons não encontrados!", []);

      return RetornoEndpointDto.Sucesso(
        "Cupons encontrado com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Erro ao encontrar cupons!");
    } finally {
      setLoading(false);
    }
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }

  static exibirSucesso(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem
      })
    );
  }
}
