import { createTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { Base } from "componentes/cores";
import getDomain from "global/getDomain";
import styled from "styled-components";
import theme from "themes";

const th = createTheme();

const corFundoPlano = (tipoPlano) => {
  switch (tipoPlano) {
    case 1:
      return "#B18577";
    case 2:
      return "#B4B4B4";
    case 3:
      return "#D5B178";
    case 4:
      return "#B18577";
    case 5:
      return "#B4B4B4";
    case 6:
      return "#D5B178";
    default:
      return "";
  }
};

export const ImagemPlano = styled.img`
  display: block;
  float: left;
  height: 42px;
  margin-left: 15px;
  margin-right: 10px;
  margin-top: 15px;
  position: absolute;
  width: 39px;
`;

export const useStyles = makeStyles(() => ({
  container: {
    margin: "0",
    width: "100%",
    color: theme.color.textoBarra,
    "& .MuiGrid-root": {
      color: theme.color.textoBarra
    },
    "& .MuiDropzoneArea-text": {
      color: theme.color.textoBarra
    },
    "& .MuiInputBase-root": {
      height: "46px"
    },
    "& .MuiFormLabel-root": {
      lineHeight: "1"
    },
    "& .MuiTypography-root": {
      fontSize: "14px",
      [th.breakpoints.up("xl")]: {
        fontSize: "18px"
      }
    }
  },
  containerTelefone: {
    "& .MuiInputBase-input": {
      height: "7px"
    }
  },
  button: {
    fontSize: "18px",
    "&:disabled": {
      color: Base.Black,
      backgroundColor: Base.Mango,
      borderColor: Base.Mango,
      "&:hover": {
        color: Base.White
      }
    }
  },
  inputTelefone: {
    "&.Mui-error": {
      borderColor: Base.RedError,
      color: Base.RedError
    }
  },
  addRepresentante: {
    "& .MuiSvgIcon-root, button": {
      color: Base.Black
    }
  },
  subTitulo: {
    marginTop: "10px",
    marginBottom: "-20px"
  },
  imagemFundo: {
    background:
      getDomain() !== "luz" &&
      `url(../../themes/${getDomain()}/assets/cotacao_dados_energia.png)`,
    backgroundSize: "cover"
  },
  faturaEnergia: {
    textAlign: "center",
    border: "1px solid #191c224D",
    padding: "24px 16px 16px",
    fontSize: "14px",

    "& span": {
      fontSize: "18px",
      letterSpacing: "-0.4px"
    },
    "& .MuiDropzoneArea-root": {
      borderWidth: "2px",
      borderColor: "#00000080"
    },
    "& .MuiDropzoneArea-textContainer": {
      flexDirection: "column-reverse",

      "& .MuiDropzoneArea-icon": {
        marginTop: "-15px",
        marginBottom: "-10px",
        width: "24px",
        marginRight: 0
      },
      "& .MuiDropzoneArea-text": {
        margin: 0,
        fontSize: "12px",
        padding: "0 50px"
      }
    }
  },
  cnpjCep: {
    "& label": {
      fontWeight: "bold",
      fontSize: "large",
      color: `${theme.color.textoBarra}`
    },
    color: theme.color.textoBarra
  },
  inputBranco: {
    "& .MuiFormLabel-root": {
      color: `${theme.color.textoBarra} !important`
    },
    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-input": {
        color: `${theme.color.textoBarra} !important`
      },
      "& .MuiInputAdornment-root": {
        color: `${theme.color.textoBarra} !important`
      },
      "& fieldset": {
        border: `1px solid ${theme.color.textoBarra}`,
        color: `${theme.color.textoBarra} !important`
      },
      "&:hover fieldset": {
        border: `1px solid ${theme.color.textoBarra}`,
        color: `${theme.color.textoBarra} !important`
      },
      "&.Mui-focused fieldset": {
        color: `${theme.color.textoBarra} !important`
      },
      "& .MuiSelect-outlined.MuiSelect-outlined": {
        color: `${theme.color.textoBarra} !important`
      },
      "& .MuiInputBase-root": {
        color: `${theme.color.textoBarra} !important`
      },
      "& .MuiInputBase-root.Mui-disabled": {
        color: `${theme.color.textoBarra} !important`
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme.color.textoBarra}`,
        color: `${theme.color.textoBarra} !important`
      }
    }
  },
  fundoPreto: {
    backgroundColor: theme.color.bgCardCotacoes
  },
  barraSuperior: {
    fontSize: "18px",
    fontStyle: "italic",
    height: "42px",
    textAlign: "center",
    color: `${theme.color.textoBarra}`,
    width: "100%"
  },
  arrowBack: {
    color: `${theme.color.textoBarra}`,
    position: "absolute",
    left: 0,
    paddingLeft: 15
  },
  displayBlock: {
    display: "block"
  },
  displayNone: {
    display: "none"
  },
  displayDefault: {
    display: "flex"
  },
  modalInfo: {
    backgroundColor: Base.OffWhite,
    border: "0 none",
    borderRadius: "4px",
    boxShadow: th.shadows[5],
    left: "50%",
    outline: "none",
    position: "absolute",
    padding: th.spacing(4),
    transform: "translate(-50%, -50%)",
    top: "50%",
    width: "100%"
  },
  titulo: {
    marginBottom: "0px",
    fontSize: 21
  },
  botaoFechar: {
    color: Base.BlueLochmara,
    marginLeft: "-30px"
  },
  botaoSalvar: {
    width: "100%",
    backgroundColor: `${theme.color.bgCardInstrucoes} !important`,
    color: `${Base.BlackRussian} !important`,
    borderColor: `${Base.BlackRussian} !important`
  },

  containerColorido: {
    backgroundColor: (props) => corFundoPlano(props.planoCor),
    borderBottomLeftRadius: "0px",
    borderBottomRightRadius: "0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  containerBranco: {
    padding: "24px"
  },
  containerPadding: {
    padding: "0px 24px",
    opacity: (props) => (props.cotacaoFechada ? 0.5 : 1)
  },
  labelPlano: {
    fontFamily: theme.font.family,
    fontSize: "16px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.4px"
  },
  labelInformacoes: {
    fontFamily: theme.font.family,
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.4px"
  },
  valorInformacoes: {
    fontFamily: theme.font.family,
    fontSize: "20px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.53px"
  },
  valorReducaoCustos: {
    fontFamily: theme.font.family,
    fontSize: "24px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.67px"
  },
  percentualDesconto: {
    color: Base.BlackRussian,
    fontSize: "18px",
    fontWeight: "bold"
  },
  containerInformacoes: {
    paddingBottom: "15px"
  },
  porcentagemReducao: {
    fontWeight: "bold",
    color: Base.BlackRussian,
    fontSize: 36
  },
  containerPaddingInterno: {
    backgroundColor: Base.OffWhite,
    padding: 10
  },
  periodoMeses: {
    color: "#455065",
    fontWeight: 500
  },
  itemPlano: {
    marginBottom: 40,
    cursor: "pointer"
  },
  btnLabel: {
    color: Base.BlueLochmara
  },
  marginPlanos: {
    marginTop: 15,
    marginBottom: 25
  },
  tituloResumo: {
    color: `${Base.OffWhite} !important`,
    fontSize: 16,
    marginTop: 25
  },
  margemResumoCotacao: {
    width: "100%",
    textAlign: "center",
    marginTop: 10,
    marginBottom: 10
  }
}));
