import { RotasDTO } from "../../../../global/rotas/rotasUrlDto";
import RetornoEndpointDto from "../../../../global/dto/retornoEndpointDto";
import { listarBoletas } from "../../../../servicos/boletasGDServico";

export default class BoletasHelper {
  static async listarBoletas(filtro, history) {
    try {
      const resultado = await listarBoletas(filtro);

      return RetornoEndpointDto.Sucesso(
        "Boletas obtidas com sucesso",
        resultado?.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu um erro ao obter a lista de boletas"
      );
    } finally {
      history.replace({
        pathname: RotasDTO.BoletasGD,
        state: { dadosBoletaGd: null }
      });
    }
  }
}
