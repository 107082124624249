import produce from "immer";

const inicial = {
  visivel: false,
  visivelTotalmente: true,
  visivelMetade: false,
  cabecalho: true,
  usina: {
    id: null,
    rotulo: ""
  }
};

export default function menu(state = inicial, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "@menu/menuExibir": {
        draft.visivel = true;
        break;
      }

      case "@menu/menuFechar": {
        draft.visivel = false;
        break;
      }

      case "@menu/menuFecharTotalmente": {
        draft.visivelTotalmente = false;
        break;
      }

      case "@menu/menuExibirTotalmente": {
        draft.visivelTotalmente = true;
        break;
      }

      case "@menu/menuExibirMetade": {
        draft.visivelMetade = true;
        break;
      }

      case "@menu/menuNaoExibirMetade": {
        draft.visivelMetade = false;
        break;
      }

      case "@menu/cabecalho": {
        draft.cabecalho = action.payload;
        break;
      }

      case "@menu/usina": {
        draft.usina = {
          id: action.payload.id,
          rotulo: action.payload.rotulo
        };
        break;
      }

      default:
        break;
    }
  });
}
