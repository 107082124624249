import { makeStyles } from '@mui/styles';
import styled from "styled-components";
import { Base } from 'componentes/cores';
import { createTheme } from "@mui/material/styles";

const theme = createTheme();

export const BoxTabela = styled.div`
  display: flex;
  border: 1px solid #DCDCDC;
  padding: 10px;
  max-width: 1003px;

  
  div {
    display: flex;
    overflow-y: auto;
  }

  #scroll {
    padding-bottom: 10px;

    ::-webkit-scrollbar {
      height: 6px;
      background-color: #F5F5F5;
    }

    ::-webkit-scrollbar-track {
      background-color: #F5F5F5;
      border-radius: 10px;
    }
    
    ::-webkit-scrollbar-thumb {
      background-color: #DCDCDC;
      border-radius: 10px;
    }
`;

export const Tabela = styled.table`
  margin-top: 20px;
  
  th, td {
    padding: 4px;
    border: 4px solid white;
    text-align: center;
  }

  thead {
    background: #DCDCDC;
  }

  tbody {
    background: #F0F0F0;
  }
`;

export const useStyles = makeStyles(() => ({
  container: {
    margin: 0,
    width: '100%',
    "& .MuiGrid-root": {
      color: `${Base.BlackBastille} !important`
    },
    "& .MuiDropzoneArea-text": {
      color: `${Base.BlackBastille} !important`
    },
    "& .MuiInputBase-root": {
      height: "46px"
    },
    "& .MuiFormLabel-root": {
      lineHeight: "1"
    },

    '& hr': {
      height: "80px", 
      margin: theme.spacing(0, 0.5),

      '&::before': {
        content: "attr(data-df)",
        color: Base.BlackBastille,
        float: "right",
        margin: "0 10px",
      },

      '&::after': {
        content: "attr(data-et)",
        color: Base.BlackBastille,
        position: "absolute",
        margin: "0 10px",
      },
    }
  },
  containerTitulo: {
    padding: '18px !important'
  },
  titulo: {
    fontSize: '18px'
  },
  containerData: {
    padding: '32px !important'
  },
  icon: {
    cursor: "pointer",
    float:"right",
    margin: "5px"
  },
  button: {
    fontSize: '18px',
  },
  customButton: {
    fontSize: '18px',
    backgroundColor: "transparent !important"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
  },
  fileUpload: {
    "& .MuiDropzoneArea-root": {
      minHeight: "150px",
      borderWidth: "2px"
    },
    "& .MuiDropzoneArea-text": {
      fontSize: "16px"
    },
  },
  customBr: {
    borderBottom: "4px solid white;",
  }
}));