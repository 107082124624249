import { store } from "global/redux";
import RetornoEndpointDto from "../../../../global/dto/retornoEndpointDto";

import { obterDistribuidoras } from "../../../../servicos/distribuidorasServico";
import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";

export default class DistribuidoraHelper {
  static async obterDistribuidoras(filtro, setCarregandoDistribuidoras) {
    try {
      setCarregandoDistribuidoras(true);
      const resultado = await obterDistribuidoras(filtro);

      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Usinas não encontrados!");

      return RetornoEndpointDto.Sucesso(
        "Distribuidoras obtida com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Erro ao buscar distribuidoras");
    } finally {
      setCarregandoDistribuidoras(false);
    }
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }

  static exibirSucesso(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem
      })
    );
  }
}
