/* eslint-disable import/no-unresolved */
import MICROSERVICO from "global/enumeradores/microServico";
import obterApi from "servicos/api";

const api = () => obterApi(MICROSERVICO.PROJECAOTARIFARIA);

export const uploadProjecaoCustos = async (arquivo, ano, usuarioGlobal) => {
  const formData = new FormData();
  formData.append("file", arquivo.file);
  const metodo = "post"
  const url = `v1/ProjExcCusto/importar/${ano}?idUsuario=${usuarioGlobal?.usuario?.id}&nomeUsuario=${usuarioGlobal?.usuario?.usuarioNome}`;

  return api()[metodo](url, formData);
};

export const getProjecaoCustos = async (ano, distribuidora) => {
  return api().get(`v1/ProjExcCusto/visualiza?ano=${ano}&distribuidora=${distribuidora}`)
}

export const getDistribuidoras = async () => {
  const metodo = "get";
  const url = "v1/ParametroDistribuidora/getalljoin?StatusProjecaoTarifaria=true"
  return api()[metodo](url);
}
