import MICROSERVICO from "global/enumeradores/microServico";
import obterApi from "servicos/api";

const api = () => obterApi(MICROSERVICO.PROJECAOTARIFARIA);

export const ImportCVA = async (arquivo, idDistribuidora, validarDominio, anoTarifario, novaVigencia, idUsuario, nomeUsuario) => {
  const formData = new FormData();
  formData.append("file", arquivo.file);
  let url = "";
  const metodo = "post";

  if (novaVigencia != null) {
    url = `v1/ImportCVA/import?idDistribuidora=${idDistribuidora}&validarDominio=${validarDominio}&anoTarifario=${anoTarifario}&novaVigencia=${novaVigencia}&idUsuario=${idUsuario}&nomeUsuario=${nomeUsuario}`;
  } else {
    url = `v1/ImportCVA/import?idDistribuidora=${idDistribuidora}&validarDominio=${validarDominio}&anoTarifario=${anoTarifario}&idUsuario=${idUsuario}&nomeUsuario=${nomeUsuario}`;
  }

  return api()[metodo](url, formData);
};

export const GetUltimaImportacao = async (IdDistribuidora) => {
  return api().get(`v1/ImportCVA/getultimaimportacao?IdDistribuidora=${IdDistribuidora}`);
}

export const GetCVA = async (IdDistribuidora) => {
  return api().get(`v1/ImportCVA/visualizar?IdDistribuidora=${IdDistribuidora}`);
}