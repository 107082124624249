import produce from "immer";

const inicial = {
  listaNotificacoes: [],
  quantidadeNotificacoes: 0
};

export default function notificacoes(state = inicial, action) {
  return produce(state, (draft) => {

    switch (action.type) {

      case "@notificacoes/salvarNotificacoes":
        draft.listaNotificacoes = action?.payload.lista;
        draft.quantidadeNotificacoes = action?.payload.totalItens;
        break;

      case "@notificacoes/zerarQuantidadeNotificacoes":
        draft.quantidadeNotificacoes = 0
        break;

      default:
        break;
    }
  });
}
