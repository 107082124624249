import React, { useCallback, useEffect, useMemo, useState } from "react";

// ApexChart
import ReactApexChart from "react-apexcharts";

// Redux
import { useSelector } from "react-redux";

// Componentes
import { Grid } from "@mui/material";
import theme from "themes";
import { formataPercentual } from "../../../../servicos/utils";
import { Base } from "../../../../componentes/cores";
import InputData from "../../../../componentes/inputTextoData";
import Loader from "../../../../componentes/loader";

// Styles
import { Container } from "../../Dashboard/style";
import CustoCativo from "./CustoCativo";
import CustoLivre from "./CustoLivre";
import {
  Economia,
  EconomiaPDF,
  Label,
  LabelPDF,
  useStyles,
  Valor
} from "./style";

import CustoCativoPDF from "./CustoCativoPDF";
import CustoLivrePDF from "./CustoLivrePDF";

// Serviços
import { calculoConsolidado } from "../../../../servicos/economiaServico";

// eslint-disable-next-line react/prop-types
const EconomiaMensal = ({ status, pdfStatus }) => {
  const classes = useStyles();
  const usuario = useSelector((state) => state.usuario);
  // eslint-disable-next-line no-unused-vars
  const [pdf, setPDF] = useState(pdfStatus);

  const { unidadeSelecionada } = usuario;

  const [mesAno, setMesAno] = useState();

  const [custoCativo, setCustoCativo] = useState(null);
  const [dadosCativo, setDadosCativo] = useState([]);

  const [custoLivre, setCustoLivre] = useState(null);
  const [dadosLivre, setDadosLivre] = useState([]);

  const [economia, setEconomia] = useState();

  const [grafico, setGrafico] = useState(<div />);
  const [statusPdf, setStatusPdf] = useState();

  const [descontoPercentual, setDescontoPercentual] = useState(null);

  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    setStatusPdf(status?.status);
  }, [status]);

  useEffect(() => {
    setMesAno(window.moment().add(-1, "M"));
    // eslint-disable-next-line no-use-before-define
    definirMesInicial();
  }, []);

  const definirMesInicial = () => {
    const now = window.moment();
    const day = now.date();
    if (day === 1 || day === 2) {
      const date = window.moment().subtract(2, "month").toDate();
      setMesAno(date);
    } else {
      setMesAno(window.moment().add(-1, "M"));
    }
  };

  const [carregandoDados, setCarregandoDados] = useState(false);

  const obterCalculoConsolidado = useCallback(async () => {
    try {
      setCarregandoDados(true);

      if (unidadeSelecionada && mesAno && window.moment(mesAno)?.isValid()) {
        const consolidado = await calculoConsolidado(
          unidadeSelecionada,
          window.moment(mesAno)?.format("MM"),
          window.moment(mesAno)?.format("yyyy")
        );
        if (consolidado?.status === 200 && consolidado?.data) {
          setCustoCativo(consolidado?.data?.custoCativo);
          setDadosCativo(consolidado?.data?.cativo);
          setCustoLivre(consolidado?.data?.custoLivre);
          setDadosLivre(consolidado?.data?.livre);
          setEconomia(consolidado?.data?.economia);
          setDescontoPercentual(
            consolidado?.data?.descontoPercentual
              ? consolidado?.data?.descontoPercentual * 100
              : 0
          );
        }
      }
    } catch (error) {
      if (error?.response?.data?.status === 404) {
        setCustoCativo(null);
        setDadosCativo(null);
        setCustoLivre(null);
        setDadosLivre(null);
        setEconomia(null);
        setDescontoPercentual(null);
      }
      console.info(error);
    } finally {
      setCarregandoDados(false);
    }
  }, [unidadeSelecionada, mesAno]);

  useEffect(() => {
    obterCalculoConsolidado();
  }, [obterCalculoConsolidado]);

  const dados = useMemo(
    () => ({
      series: [
        {
          color: theme.color.custoCativo,
          data: [custoCativo],
          name: "Custo cativo",
          type: "column"
        },
        {
          color: theme.color.custoLivre,
          data: [custoLivre],
          name: "Custo livre",
          type: "column"
        }
      ],
      options: {
        chart: {
          background: "transparent",
          fontFamily: theme.font.family,
          // offsetY: pdf.status ? undefined : 35,
          type: "bar",
          toolbar: {
            show: false
          },
          width: "100%",
          zoom: {
            enabled: false
          }
        },
        plotOptions: {
          bar: {
            columnWidth: pdf.status ? "70%" : "50%"
          }
        },
        dataLabels: {
          enabled: false,
          background: {
            enabled: false
          },
          dropShadow: {
            enabled: true,
            blur: 0,
            opacity: 0.2
          },
          offsetY: 0,
          style: {
            color: Base.OrangeEcstasy,
            fontSize: "16px"
          },
          textAnchor: "start",
          width: "100%"
        },
        legend: {
          show: false
        },
        fill: {
          type: "solid"
        },
        labels: [""],
        markers: {
          size: 0
        },
        grid: {
          borderColor: Base.GreyShuttle,
          width: "100%",
          xaxis: {
            lines: {
              show: false
            },
            labels: {
              show: false
            }
          },
          yaxis: {
            lines: {
              show: true
            },
            labels: {
              show: false
            }
          }
        },
        xaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            show: false
          },
          labels: {
            enabled: false,
            show: false,
            style: {
              colors: pdf.status
                ? theme.color.thirdText
                : theme.color.textoBarra
            }
          },
          position: "top",
          tooltip: {
            enabled: false
          },
          width: "100%"
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          forceNiceScale: true,
          labels: {
            show: true,
            style: {
              colors: pdf.status
                ? theme.color.thirdText
                : theme.color.textoBarra,
              fontSize: statusPdf ? 22 : 12,
              fontWeight: "bold"
            },
            formatter(value) {
              return value?.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
                maximumFractionDigits: 0,
                minimumFractionDigits: 0
              });
            }
          },
          min: 0,
          tickAmount: 8,
          width: "100%"
        },
        noData: {
          text: "",
          style: {
            color: theme.color.textoBarra,
            fontSize: "18px",
            maxWidth: "80%"
          }
        },
        tooltip: {
          shared: false
        }
      }
    }),
    [custoCativo, custoLivre, pdf]
  );

  const RenderizarGrafico = useCallback(() => {
    const graficoRender = (
      <ReactApexChart
        options={dados?.options || []}
        series={dados?.series || []}
        type="bar"
        height={pdf.status ? 300 : 350}
        width={pdf.status ? 200 : "100%"}
      />
    );

    setGrafico(graficoRender);
  }, [setGrafico, custoCativo, custoLivre, pdf]);

  useEffect(() => RenderizarGrafico(), [RenderizarGrafico, pdf]);

  return (
    <>
      <Container
        className={
          pdf.status ? classes.containerGraficoPDF : classes.containerGrafico
        }
      >
        <Grid
          container
          justifyContent="space-between"
          className="m-0 w-100"
          spacing={4}
        >
          {pdf.status ? (
            <Grid item className={classes.tituloPDF} />
          ) : (
            <Grid item className={classes.titulo}>
              Economia mensal
            </Grid>
          )}

          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                {!pdf.status ? (
                  <InputData
                    type="text"
                    id="mesInicio"
                    name="mesInicio"
                    minDate={window.moment().subtract(10, "year").toDate()}
                    maxDate={window.moment().endOf("month").toDate()}
                    customValue={mesAno}
                    onChange={(valor) => setMesAno(valor)}
                    renderIconShowHide={false}
                    className={classes}
                    views={["month", "year"]}
                    autoOk
                    format="MMM/yyyy"
                  />
                ) : (
                  <div style={{ color: "black", fontSize: 13 }}>
                    {window.moment(mesAno).format("MMM/yyyy")}
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Loader loading={carregandoDados}>
          {custoCativo && custoLivre && dados?.options && dados?.series ? (
            pdf.status ? (
              <Grid container className="m-0 w-100">
                <Grid item lg={4} xs={4}>
                  <CustoCativoPDF dadosCativo={dadosCativo} />
                </Grid>
                <Grid
                  item
                  lg={4}
                  xs={4}
                  className={pdf.status ? classes.ajusteAltura : ""}
                >
                  {grafico}
                  {pdf.status ? (
                    <EconomiaPDF>
                      <LabelPDF>
                        Economia: &nbsp;
                        {economia?.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                          maximumFractionDigits: 0,
                          minimumFractionDigits: 0
                        })}
                      </LabelPDF>
                    </EconomiaPDF>
                  ) : (
                    <Economia>
                      <Label>Economia</Label>
                      <Valor>
                        {economia?.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                          maximumFractionDigits: 0,
                          minimumFractionDigits: 0
                        })}
                      </Valor>
                    </Economia>
                  )}
                </Grid>

                <Grid item lg={4} xs={4}>
                  <CustoLivrePDF dadosLivre={dadosLivre || []} />
                </Grid>
              </Grid>
            ) : (
              <Grid container className="m-0 w-100" spacing={4}>
                <Grid item lg={4} className="pb-0">
                  <CustoCativo dadosCativo={dadosCativo || []} />
                </Grid>
                <Grid item lg={4}>
                  {grafico}
                  <Economia>
                    <Label>Economia</Label>
                    <Valor>
                      {economia?.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                        maximumFractionDigits: 0,
                        minimumFractionDigits: 0
                      })}
                      {descontoPercentual &&
                        descontoPercentual !== "NAN" &&
                        ` (${formataPercentual(descontoPercentual || "")})`}
                    </Valor>
                  </Economia>
                </Grid>
                <Grid item lg={4} className="pb-0">
                  <CustoLivre dadosLivre={dadosLivre} />
                </Grid>
              </Grid>
            )
          ) : (
            <Grid item className={classes.noData}>
              <div>
                Não foi possível carregar as informações de economia, tente
                novamente em algumas horas!
              </div>
            </Grid>
          )}
        </Loader>
      </Container>
    </>
  );
};

export default EconomiaMensal;
