import { RotasDTO } from "../../../../global/rotas/rotasUrlDto";
import RetornoEndpointDto from "../../../../global/dto/retornoEndpointDto";
import { listarMedidorGD } from "../../../../servicos/medidorGDServico";

export default class MedidorGDHelper {
  static async ListarMedidorGD(filtro, history) {
    try {
      const resultado = await listarMedidorGD(filtro);

      return RetornoEndpointDto.Sucesso(
        "Medidores listados com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Não foi possivel obter a lista de medidores"
      );
    } finally {
      history.replace({
        pathname: RotasDTO.MedidorGD,
        state: { dadosMedidorGd: null }
      });
    }
  }
}
