import MICROSERVICO from "global/enumeradores/microServico";
import obterApi from "servicos/api";

const api = () => obterApi(MICROSERVICO.PROJECAOTARIFARIA);

export const buscarVigenciasBandeiras = async (data) => {
  return api().get(`v1/BandeiraTarifa/dataref?dataref=${data}`);
}

export const buscarBandeirasPorId = async (id) => {
  return api().get(`v1/BandeiraTarifa/${id}`);
}

export const deletarBandeira = async (id) => {
  return api().delete(`v1/BandeiraTarifa/${id}`);
}

export const buscarBandeirasPorAno = async (ano) => {
  return api().get(`v1/BandeiraReferencia/ano?ano=${ano}`);
}

export const uploadBandeirasTarifarias = async (arquivo, idUsuario, nomeUsuario) => {
  const data = new FormData();
  data.append("file", arquivo.file);

  const metodo = "post";
  const url = `v1/BandeiraReferencia/upload?idUsuario=${idUsuario}&NomeUsuario=${nomeUsuario}`;
  return api()[metodo](url, data);
};

export const salvarBandeira = async (id, data) => {
  let metodo = "post";
  const url = 'v1/BandeiraTarifa/';

  if (id > 0) {
    metodo = "put";
  }

  return api()[metodo](url, data);
};