import styled from "styled-components";

export const Wrapper = styled.div`
  text-align: center;
  width: 100%;
`;

export const Divisor = styled.span`
  display: block;
  flex-shrink: 0;
  height: 1px;
  width: 100%;
`;

export const Logo = styled.img`
  height: auto;
  margin-bottom: 15px;
  max-width: 115px;
  width: 100%;
  margin-top: 10px;
  padding: 10px;
`;

export const LogoLuz = styled.img`
  height: auto;
  margin-bottom: 15px;
  width: 100%;
  margin-top: 10px;
`;

export const Version = styled.div`
  bottom: 0;
  left: 0;
  right: 0;
  text-align: right;
  padding: 20px;
  color: #00000080;
  font-size: 10px;
`;
