import React from "react";
import PropTypes from "prop-types";

import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";

import { Grid } from "@mui/material";
import { DropzoneAreaBase } from "material-ui-dropzone";
import ProgressbarWithLabelValue from "../progressBar/index";
import Loader from "../loader";

import { useStyles } from "./style";

const UploadCustomizado = React.forwardRef((props, ref) => {
  const {
    acceptedFiles,
    dropAreaText,
    icon,
    id,
    name,
    onAdd,
    onDropRejected,
    progressValue,
    showLoader,
    // eslint-disable-next-line react/prop-types
    limit,
    loader,
    disabled
  } = props;

  const classes = useStyles();

  const handleOnAdd = (valor) => {
    onAdd(valor);
  };

  const handleOnDropRejected = (valor) => {
    onDropRejected(valor);
  };

  return (
    <>
      <Loader loading={loader} className="w-auto">
        <Grid container alignItems="center">
          <Grid item xs={12} className={classes.containerDropZone}>
            <DropzoneAreaBase
              maxFileSize={200000000}
              acceptedFiles={acceptedFiles}
              filesLimit={limit ?? 1}
              id={id}
              name={name}
              Icon={icon}
              dropzoneText={dropAreaText}
              onAdd={(valor) => handleOnAdd(valor)}
              showAlerts={false}
              onDropRejected={(valor) => handleOnDropRejected(valor)}
              ref={ref}
              disable={disabled}
            />
          </Grid>
        </Grid>
      </Loader>
      {showLoader ? (
        <ProgressbarWithLabelValue progressValue={progressValue} />
      ) : null}
    </>
  );
});

UploadCustomizado.propTypes = {
  acceptedFiles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  dropAreaText: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.any]),
  id: PropTypes.string,
  name: PropTypes.string,
  onAdd: PropTypes.oneOfType([PropTypes.func]),
  onDropRejected: PropTypes.oneOfType([PropTypes.any]),
  progressValue: PropTypes.oneOfType([PropTypes.any]),
  showLoader: PropTypes.oneOfType([PropTypes.any]),
  loader: PropTypes.bool,
  disabled: PropTypes.bool
};

UploadCustomizado.defaultProps = {
  acceptedFiles: [
    ".pdf, .csv, .xls, .xlsx, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values"
  ],
  dropAreaText: "Solte os arquivos para anexá-los ou procurar",
  icon: CloudUploadOutlinedIcon,
  id: "UploadCustomizado",
  name: "UploadCustomizado",
  onAdd: () => {},
  onDropRejected: () => {},
  progressValue: () => {},
  showLoader: () => {},
  loader: false,
  disabled: false
};

export default UploadCustomizado;
