export default class ListagemCotacaoDto {
  constructor(
    id,
    nomeFantasia,
    cnpj,
    desconto,
    plano,
    dataCotacao,
    dataValidade,
    status,
    ativo,
    cotacaoSelecionada,
    statusCotacao,
    consumidorLivre,
    valorMedioConta,
    dataCotacaoJson,
    dataMigracao
  ) {
    this.id = id;
    this.nomeFantasia = nomeFantasia;
    this.cnpj = cnpj;
    this.desconto = desconto;
    this.plano = plano;
    this.dataCotacao = dataCotacao;
    this.dataValidade = dataValidade;
    this.status = status;
    this.statusCotacao = statusCotacao;
    this.ativo = ativo;
    this.consumidorLivre = consumidorLivre;
    this.cotacaoSelecionada = cotacaoSelecionada;
    this.valorMedioConta = valorMedioConta;
    this.dataCotacaoJson = dataCotacaoJson;
    this.dataMigracao = dataMigracao;
  }
}
