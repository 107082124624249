import { Checkbox, FormControlLabel } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useStyles } from "./styles";

const CheckInputs = ({
  setChecked,
  checked,
  label,
  name,
  id,
  value,
  width,
  height,
  disabled
}) => {
  const classes = useStyles({ width, height });

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <FormControlLabel
      label={label}
      control={
        <Checkbox
          id={id}
          value={value}
          name={name}
          checked={checked}
          onChange={handleChange}
          className={classes.root}
          disableRipple
          color="default"
          checkedIcon={<span className={(classes.icon, classes.checkedIcon)} />}
          icon={<span className={classes.icon} />}
          inputProps={{ "aria-label": "decorative checkbox" }}
          size="medium"
          label={label}
          disabled={disabled}
        />
      }
    />
  );
};

CheckInputs.propTypes = {
  setChecked: PropTypes.oneOfType([PropTypes.func]),
  checked: PropTypes.bool,
  label: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.string,
  id: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  disabled: PropTypes.bool
};

CheckInputs.defaultProps = {
  setChecked: () => {},
  checked: false,
  label: false,
  name: "",
  value: "",
  id: "",
  width: 20,
  height: 20,
  disabled: false
};

export default CheckInputs;
