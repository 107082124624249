import { Tooltip } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import { Base } from "../../../componentes/cores";
import theme from "../../../themes";

// eslint-disable-next-line no-underscore-dangle
const theme_ = createTheme();

export const useStyles = makeStyles(() => ({
  button: {
    fontSize: "18px"
  },
  medidor: {
    marginTop: "-48px",
    fontSize: "14px"
  },
  linhaTooltip: {
    cursor: "default",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  dataUpload: {
    fontSize: 16
  },
  botao: {
    color: `${theme.color.botaoDownloadFatura} !important`,
    fontSize: "32px"
  },
  fundoCelulaMobile: {
    backgroundColor: `${theme.color.novoListItemBackgroundHover}`,
    border: "none",
    borderRadius: 10,
    borderWidth: 1,
    padding: "25px",
    marginBottom: "13px",
    boxShadow: theme_.shadows[1]
  },
  nomeRelatorio: {
    color: `${theme.color.primary}`,
    fontWeight: "bold",
    fontSize: "16px",
    marginBottom: 10
  },
  fundoCelulaTransparente: {
    backgroundColor: Base.BlackRussian,
    display: "flex",
    justifyContent: "center"
  }
}));

export const HtmlTooltip = withStyles(() => ({
  tooltip: {
    fontSize: "14px",
    width: "100%",
    textAlign: "center",
    alignItems: "center",
    padding: "8px",
    borderImageSlice: 1,
    backgroundImage:
      "linear-gradient(to bottom, #647480, #647480), linear-gradient(to bottom, #374148, #647480 87%)",
    borderImageSource: "linear-gradient(to bottom, #374148, #647480 87%)",
    borderRadius: "5px"
  }
}))(Tooltip);
