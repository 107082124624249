import PropTypes from "prop-types";
import React, { useEffect, useMemo, useRef, useState } from "react";

// Icones
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";

// Componentes
import { Card, Checkbox, FormControlLabel, Grid, Hidden } from "@mui/material";
import theme from "themes";
import SelectArredondado from "../../../../../componentes/selectArredondado";

// Styles
import { useStyles } from "./style";

// Rotas
import { InterfaceDTO } from "../../../../../global/dto/interfacesDto";
import { RotasDTO } from "../../../../../global/rotas/rotasUrlDto";

const CampoTexto = ({ node, nodePai, onEditar, onSalvar, full, tipo }) => {
  const className = useStyles();

  const [tiposFuncionalidade] = useState([
    {
      id: 4,
      nome: "Segmento"
    },
    {
      id: 5,
      nome: "Interface"
    }
  ]);

  const [tipoSelecionado, setTipoSelecionado] = useState(
    node?.idTipoFuncionalidade ?? undefined
  );

  const listaComponentes = useMemo(() => {
    const lista = [];

    if (String(tipoSelecionado) === "5") {
      Object.keys(InterfaceDTO)
        .sort()
        .forEach((item) => {
          lista.push({ key: item, nome: InterfaceDTO[item] });
        });
    } else {
      Object.keys(RotasDTO)
        .sort()
        .forEach((rota) => {
          lista.push({ key: rota, nome: rota });
        });
    }

    return lista;
  }, [tipoSelecionado]);

  const inputEl = useRef(null);

  const [nome, setNome] = useState(node?.nome ?? "");
  const [flag, setFlag] = useState({
    Mobile: node?.flagMobile ?? false,
    Web: node?.flagWeb ?? false,
    Intranet: node?.flagIntranet ?? false
  });
  const [componente, setComponente] = useState(node?.caminho ?? undefined);
  const [descricao, setDescricao] = useState(node?.descricao ?? "");

  useEffect(() => {
    if (node?.idTipoFuncionalidade)
      setTipoSelecionado(String(node?.idTipoFuncionalidade));
  }, [node]);

  const onChangeFlag = (event) => {
    setFlag({ ...flag, [event.target.name]: !flag[event.target.name] });
  };

  const [errors, setErrors] = useState();

  const [iconeBase64, setIconeBase64] = useState("");

  const existemErrosValidacao = () => {
    let validacoes = false;

    if (tipo) {
      if (!tipoSelecionado) {
        validacoes = {
          ...validacoes,
          tipoSelecionado: { message: "Tipo é obrigatório!" }
        };
      }
      if (!Object.values(flag).find((item) => item === true)) {
        validacoes = {
          ...validacoes,
          flag: { message: "Um plataforma é obrigatória!" }
        };
      }
      if (!componente) {
        validacoes = {
          ...validacoes,
          componente: { message: "Componente é obrigatório!" }
        };
      }
    }

    if (!nome) {
      validacoes = {
        ...validacoes,
        nome: { message: "Nome é obrigatório!" }
      };
    }

    return validacoes;
  };

  const onClickCadastrar = () => {
    const errosValidacao = existemErrosValidacao();

    if (!errosValidacao) {
      onSalvar({
        ...node,
        ...(Object.keys(nodePai).length && { idFuncionalidadePai: nodePai.id }),
        idTipoFuncionalidade:
          tipoSelecionado ?? node?.idTipoFuncionalidade ?? 3,
        nome,
        iconeBase64,
        flagWeb: flag?.Web,
        flagMobile: flag?.Mobile,
        flagIntranet: flag?.Intranet,
        caminho: componente,
        descricao
      });
    } else {
      setErrors(errosValidacao);
    }
  };

  const handleFileRead = async (event) => {
    const file = event.target.files[0];
    const base64 = await convertBase64(file);
    const newDataUri = await resizedataURL(base64, 64, 64);
    const base64result = newDataUri.split(",")[1];
    setIconeBase64(base64result);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const resizedataURL = async (datas, wantedWidth, wantedHeight) => {
    // eslint-disable-next-line no-async-promise-executor, func-names
    return new Promise(async function (resolve) {
      const img = document.createElement("img");
      // eslint-disable-next-line func-names
      img.onload = function () {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = wantedWidth;
        canvas.height = wantedHeight;
        ctx.drawImage(this, 0, 0, wantedWidth, wantedHeight);
        const dataURI = canvas.toDataURL();
        resolve(dataURI);
      };
      img.src = datas;
    });
  };

  return (
    <Card
      className={`${className.form} ${full ? className.margin : ""} rounded-0`}
      style={{ maxHeight: tipo ? "234px" : "54px" }}
    >
      <Grid
        container
        spacing={0}
        alignItems="center"
        className={className.container}
        style={{ maxHeight: tipo ? "234px" : "54px" }}
      >
        <Grid
          item
          style={{
            maxWidth: tipo ? "calc(100% - 166px)" : "calc(100% - 109px)",
            width: "100%"
          }}
        >
          <Grid
            container
            spacing={0}
            className={className.margin}
            style={{ height: "54px" }}
          >
            {tipo ? (
              <>
                {tipoSelecionado && (
                  <Grid
                    item
                    className={className.gridItem}
                    style={{
                      alignItems: "center",
                      display: "flex",
                      maxWidth: "81px",
                      paddingLeft: "16px",
                      paddingRight: "8px",
                      width: "100%"
                    }}
                  >
                    <label
                      htmlFor="tipoSelecionado"
                      className={className.label}
                    >
                      Tipo:
                    </label>
                  </Grid>
                )}
                <Grid
                  item
                  className={`${className.gridItem} ${className.divider}`}
                  style={{
                    height: "54px",
                    maxWidth: tipoSelecionado ? "205px" : "286px",
                    width: "100%"
                  }}
                >
                  <SelectArredondado
                    id="tipoSelecionado"
                    name="tipoSelecionado"
                    valueKey="id"
                    valueName="nome"
                    dataSource={tiposFuncionalidade}
                    placeholder="Tipo"
                    value={tipoSelecionado}
                    onChange={(valor) => setTipoSelecionado(valor.target.value)}
                    className={`${className.select} ${
                      errors?.tipoSelecionado ? className.errorSelect : ""
                    }`}
                    style={{ height: "54px" }}
                    customSelect
                    isFuncionality
                  />
                </Grid>
              </>
            ) : null}

            {tipo === 0 || tipo === undefined ? (
              <Grid container>
                <Grid item xs={6}>
                  <Grid container className={`${className.gridItemHeight}`}>
                    <Grid
                      item
                      className={`${className.gridItem}  ${
                        errors?.nome ? className.errorLabel : ""
                      }`}
                      style={{
                        alignItems: "center",
                        display: "flex",
                        maxWidth: "81px",
                        paddingLeft: "16px",
                        paddingRight: "8px",
                        width: "100%"
                      }}
                    >
                      <label htmlFor="nome" className={className.label}>
                        Nome:
                      </label>
                    </Grid>
                    <Grid
                      item
                      className={`${className.gridItem} ${className.divider} ${
                        errors?.nome ? className.errorInput : ""
                      }`}
                      style={{
                        maxWidth: tipo
                          ? "calc(100% - 367px)"
                          : "calc(100% - 81px)",
                        width: "100%"
                      }}
                    >
                      <input
                        id="nome"
                        name="nome"
                        defaultValue={nome}
                        className={`${className.input}`}
                        onChange={(valor) => setNome(valor.target.value)}
                        required
                        autoComplete="off"
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={6}>
                  <Grid
                    container
                    className={`${className.gridItemHeight} ${className.gridItem}`}
                  >
                    <input
                      ref={inputEl}
                      type="file"
                      onChange={(e) => {
                        handleFileRead(e);
                        e.stopPropagation();
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      accept="image/*"
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <>
                <Grid
                  item
                  className={`${className.gridItem}  ${
                    errors?.nome ? className.errorLabel : ""
                  }`}
                  style={{
                    alignItems: "center",
                    display: "flex",
                    maxWidth: "81px",
                    paddingLeft: "16px",
                    paddingRight: "8px",
                    width: "100%"
                  }}
                >
                  <label htmlFor="nome" className={className.label}>
                    Nome:
                  </label>
                </Grid>
                <Grid
                  item
                  className={`${className.gridItem} ${className.divider} ${
                    errors?.nome ? className.errorInput : ""
                  }`}
                  style={{
                    maxWidth: tipo ? "calc(100% - 367px)" : "calc(100% - 81px)",
                    width: "100%"
                  }}
                >
                  <input
                    id="nome"
                    name="nome"
                    defaultValue={nome}
                    className={`${className.input}`}
                    onChange={(valor) => setNome(valor.target.value)}
                    required
                    autoComplete="off"
                  />
                </Grid>
              </>
            )}
          </Grid>

          {tipo ? (
            <>
              <Grid
                container
                spacing={0}
                className={className.margin}
                style={{ height: "54px" }}
              >
                <Grid
                  item
                  className={`${className.gridItem} ${className.divider} ${
                    errors?.flag ? className.errorFlag : ""
                  }`}
                  style={{
                    alignContent: "center",
                    alignItems: "center",
                    display: "flex",
                    height: "54px",
                    maxWidth: "50%",
                    width: "100%"
                  }}
                >
                  <label
                    htmlFor="flag"
                    className={className.label}
                    style={{ paddingLeft: "16px", paddingRight: "8px" }}
                  >
                    <Hidden xlUp>Plat.:</Hidden>
                    <Hidden xlDown>Plataforma:</Hidden>
                  </label>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={flag?.Mobile}
                        onClick={onChangeFlag}
                        name="Mobile"
                        color="primary"
                        classes={{ colorPrimary: className.checkbox }}
                      />
                    }
                    label="Mobile"
                    style={{ marginBottom: 0 }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={flag?.Web}
                        onClick={onChangeFlag}
                        name="Web"
                        color="primary"
                        classes={{ colorPrimary: className.checkbox }}
                      />
                    }
                    label="Web"
                    style={{ marginBottom: 0 }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={flag?.Intranet}
                        onClick={onChangeFlag}
                        name="Intranet"
                        color="primary"
                        classes={{ colorPrimary: className.checkbox }}
                      />
                    }
                    label="Intranet"
                    style={{ marginBottom: 0 }}
                  />
                </Grid>
                <Grid
                  item
                  className={`h-100 ${className.gridItem} ${className.divider}`}
                  style={{ maxWidth: "50%", width: "100%" }}
                >
                  <SelectArredondado
                    id="componente"
                    name="componente"
                    valueKey="key"
                    valueName="nome"
                    dataSource={listaComponentes}
                    placeholder={
                      String(tipoSelecionado) === "5"
                        ? "Interface do sistema:"
                        : "Componente do sistema:"
                    }
                    value={componente}
                    onChange={(valor) => setComponente(valor.target.value)}
                    className={`${className.select} ${
                      errors?.componente ? className.errorSelect : ""
                    }`}
                    style={{ height: "54px" }}
                    customSelect
                    isFuncionality
                  />
                </Grid>
              </Grid>
              <Grid container spacing={0} style={{ height: "122px" }}>
                <Grid
                  item
                  className={`h-100 w-100 ${className.gridItem} ${className.divider}`}
                >
                  <label
                    htmlFor="descricaoFuncionalidade"
                    className={`w-100 ${className.label}`}
                    style={{
                      paddingLeft: "16px",
                      paddingRight: "8px",
                      paddingTop: "8px"
                    }}
                  >
                    Descrição:
                  </label>
                  <textarea
                    id="descricao"
                    name="descricao"
                    defaultValue={descricao}
                    className={`${className.input} ${className.descricao}`}
                    onChange={(valor) => setDescricao(valor.target.value)}
                    maxLength={100}
                    style={{
                      border: `1px solid ${theme.color.primaryBackgroud}`,
                      borderRadius: "3px",
                      height: "auto",
                      margin: "8px 16px 0",
                      maxWidth: "100%",
                      padding: "8px 16px",
                      width: "calc(100% - 32px)"
                    }}
                    autoComplete="off"
                  />
                </Grid>
              </Grid>
            </>
          ) : null}
        </Grid>
        <Grid
          item
          className={`${className.itemCadastrar} ${className.divider}`}
          style={{
            alignItems: "center",
            display: "flex",
            height: tipo ? "234px" : "56px",
            justifyContent: "center",
            maxWidth: tipo ? "112px" : "54px",
            width: "100%"
          }}
        >
          <DoneIcon
            className={className.buttonClose}
            style={{ width: "100%" }}
            onClick={() => onClickCadastrar()}
          />
        </Grid>
        <Grid
          item
          className={`${className.gridItem} ${className.itemClose}`}
          style={{
            height: tipo ? "234px" : "56px",
            maxWidth: "54px",
            width: "100%"
          }}
        >
          <CloseIcon
            className={className.buttonClose}
            onClick={() => onEditar()}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

CampoTexto.propTypes = {
  node: PropTypes.oneOfType([PropTypes.object]),
  nodePai: PropTypes.oneOfType([PropTypes.object]),
  onEditar: PropTypes.oneOfType([PropTypes.func]),
  onSalvar: PropTypes.oneOfType([PropTypes.func]),
  full: PropTypes.bool,
  tipo: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

CampoTexto.defaultProps = {
  node: {},
  nodePai: {},
  onEditar: () => {},
  onSalvar: () => {},
  full: false,
  tipo: 0
};

export default CampoTexto;
