import React from "react";
import PropTypes from "prop-types";
import { generate } from "shortid";

import { Grid, Button } from "@mui/material";
import { GetApp, Search } from "@mui/icons-material";

import { HtmlTooltip } from "paginas/Graficos/ConsumoAtual/style";
import { useStyles } from "./style";
import enumerador from "./enumerador";

import origensPermissaoDownload from "../origensPermissaoDownload";

const ColunaMultiplosBotoes = ({ onClick, parametros }) => {
  const classes = useStyles();

  const onClickDownload = () => onClick(enumerador.Download, parametros);
  const onClickDetalhar = () => onClick(enumerador.Detalhar, parametros);

  const podeDownload = origensPermissaoDownload.includes(parametros.origemDaInformacao) && parametros.guidArquivoDaDigitalizacao;

  return (
    <Grid container spacing={0} className={classes.fundoCelula}>
      <Button
        key={generate()}
        onClick={onClickDownload}
        className={`text-white ${classes.botao}`}
        disabled={!podeDownload}
      >
        <HtmlTooltip title="Download">
          <GetApp />
        </HtmlTooltip>
      </Button>
      <Button
        key={generate()}
        onClick={onClickDetalhar}
        className={`text-white ${classes.botao}`}
      >
        <HtmlTooltip title="Detalhar">
          <Search />
        </HtmlTooltip>
      </Button>
    </Grid>
  );
};

ColunaMultiplosBotoes.propTypes = {
  onClick: PropTypes.oneOfType([PropTypes.func]).isRequired,
  parametros: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired
};

export default ColunaMultiplosBotoes;
