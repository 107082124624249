/* eslint-disable react/prop-types */
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Grid } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import { Base } from 'componentes/cores';
import theme from "themes";

export default function Grafico(props) {
  const dadosGrafico = props.data;
  const cotacoes = props.data.cotacoes;
  const unidade = props.unidade;
  const [serieDefinitivo, setSerieDefinitivo] = useState([]);
  const [serieEstimado, setSerieEstimado] = useState([]);
  const [labelsGrafico, setLabelsGrafico] = useState([]);

  const dados = useMemo(
    () => ({
      series: [{ data: serieDefinitivo, name: "  ", color: "transparent" }, { data: [], name: "Definitivo", color: Base.GreenFern }, { data: [], name: "Estimado", color: Base.RedAmaranth }],
      options: {
        chart: {
          fontFamily: theme.font.family,
          toolbar: {
            show: false
          },
          type: "bar",
          zoom: {
            enabled: false
          },
          background: "#303642",
          width: "100%"
        },
        dataLabels: {
          background: {
            enabled: false
          },
          dropShadow: {
            enabled: true,
            blur: 0,
            opacity: 0.2
          },
          offsetY: -5,
          style: {
            color: "#F76C1D",
            fontSize: "16px"
          },
          textAnchor: "middle",
          width: "100%",
          formatter(value) {
            if(value !== null && value !== undefined)
            {
              return unidade == "%" ? (value*100).toLocaleString('pt-BR', { maximumFractionDigits: 2 }) : value.toLocaleString('pt-BR', { maximumFractionDigits: 3 });
            }
            else
            {
              return `${value}x`;
            }
          }
        },
        legend: {
          show: true,
          labels: {
            colors: "#fff",
          },
          onItemClick: {
            toggleDataSeries: false
          },
        },
        stroke: {
          curve: "straight",
          width: 3
        },
        fill: {
          type: ["solid"],
          gradient: {
            type: "horizontal",
            shadeIntensity: 0,
            inverseColors: false,
            opacityFrom: 0.8,
            opacityTo: 0.8,
            stops: [20, 100, 100, 100]
          }
        },
        labels: labelsGrafico,
        markers: {
          size: 0
        },
        grid: {
          borderColor: "#59606F",
          width: "100%",
          xaxis: {
            lines: {
              show: false
            },
            labels: {
              show: false
            }
          },
          yaxis: {
            lines: {
              show: true
            },
            labels: {
              show: false
            }
          }
        },
        xaxis: {
          width: "100%",
          axisBorder: {
            show: true
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            show: false
          },
          labels: {
            style: {
              colors: Base.OffWhite
            }
         
          },
          position: "top",
          tooltip: {
            enabled: true
          }
        },
        yaxis: {
          width: "100%",
          labels: {
            show: false,
            style: {
              colors: Base.OffWhite
            }
          }
        },
        tooltip: {
          theme: "dark",
          enabled: true,
          x: [
            {
              title: {
                formatter(val) {
                  return val;
                }
              }
            },
            {
              title: {
                formatter(val) {
                  return val;
                }
              }
            },
            {
              title: {
                formatter(val) {
                  return val;
                }
              }
            }
          ],
        },
        noData: {
          text: "",
          style: {
            color: `${Base.OffWhite} !important`,
            fontSize: "18px",
            maxWidth: "80%"
          }
        }
      }
    }),
    [serieDefinitivo, serieEstimado]
  );

  const obterDados = useCallback((grafico, unidadeValores) => {

    const df = [];
    const dfLabels = [];
    const et = [];
    const etLabels = [];

    grafico.cotacoes.map(item => item?.efetivoSimulado === 'DF' ? df.push({ x: item.efetivoSimulado, y: [item.valor], fillColor: Base.GreenFern }) : et.push({ x: item.efetivoSimulado, y: [ item.valor], fillColor: Base.RedAmaranth }));
    grafico.cotacoes.map(item => item?.efetivoSimulado === 'DF' ? dfLabels.push(item.dtSerieHistorica) : etLabels.push(item.dtSerieHistorica));

    const dadosCompat = df.concat(et);
    const labelsConcat = dfLabels.concat(etLabels);
    
    const labels = grafico.periodicidade == "Mensal" ? labelsConcat.map(label => window.moment(label).format("MMM/YYYY")) : labelsConcat.map(label => window.moment(label).format("YYYY"));
    setSerieDefinitivo(dadosCompat);
    setSerieEstimado(et);
    setLabelsGrafico(labels);
  }, []);

  useEffect(() => {  
     if (Object.keys(dadosGrafico).length) {
       obterDados(dadosGrafico, unidade)
      }
  }, [dadosGrafico, unidade]);

  return (
    <Grid container className="m-0 w-100" spacing={3}>
      {Object.keys(dadosGrafico).length > 0 ?  
        <>
          <Grid item sm={12}>
            Valores em: 
            {unidade}
          </Grid>
          <Grid item sm={12}>
            <ReactApexChart
              options={dados?.options}
              series={dados?.series}
              type="bar"
              height={350}
            />
          </Grid>
        </>
        : ""
      }
    </Grid>
  );
};