import React from "react";
import { Grid } from "@mui/material";

import { useStyles } from "./style";

const CadastroMedidor = () => {
  const classes = useStyles();

  return (
    <Grid className={classes.containerContent}>
      <h5>
        <p>
          Estamos realizando o cadastro do ponto de medição, em breve lançaremos
          novidades
        </p>
      </h5>
    </Grid>
  );
};

export default CadastroMedidor;
