/* eslint-disable no-plusplus */
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import moment from "moment";
import { Card, Grid } from "@mui/material";
import BotaoRetornarListagem from "componentes/botaoRetornarListagem";
import UploadDropArea from "componentes/upload";
import Botao from "componentes/botao";
import InputData from "componentes/inputTextoData";
import ResultadoOperacao from "componentes/resultadoOperacao";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { useHistory } from "react-router-dom";
import { Loader } from "componentes";
import { useStyles } from "./style";
import { importarBaseRateioProinfa } from "../../../../../servicos/premissasBrasil";
import RotasDTO from "../../../../../rotas/rotasUrlDto";

const BaseRateioProinfaImportar = () => {

  const usuarioGlobal = useSelector((state) => state.usuario);
  const classes = useStyles();
  const history = useHistory();

  const msgPadraoUpload = "Solte o arquivo para anexá-lo ou procurar";
  
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(msgPadraoUpload);
  const [anoBase, setAnoBase] = useState(null);
  const [importando, setImportando] = useState(false)
  const [resultadoOperacao, setResultadoOperacao] = useState(false);
  const [resultadoOperacaoCabecalho, setResultadoOperacaoCabecalho] = useState("");
  const [resultadoOperacaoObj, setResultadoOperacaoObj] = useState({
    "mensagens": []
  });

  const processarArquivo = (arquivo) => {
    if (arquivo != null && arquivo[0] != null) {
      setFile(arquivo[0].file);
      setFileName(arquivo[0].file.name);
    }
  };

  const onFechar = () => {
    history.push(`${RotasDTO.ProjecaoInsumoListar}`);
  }

  const onAddUpload = (arquivo) => {
    processarArquivo(arquivo);
  };

  const { handleSubmit } = useForm({
    reValidateMode: "onSubmit",
  });

  const handleChangeAnoBase = (ano) => setAnoBase(ano);

  const EnviarForm = async () => {
    try {
      if (!file) {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "Por favor, selecione o arquivo desejado."
          })
        );

        return;
      }

      setImportando(true);
      setResultadoOperacao(false);

      const response = await importarBaseRateioProinfa(file, moment(anoBase).format("YYYY"), usuarioGlobal?.usuario?.id, usuarioGlobal?.usuario?.usuarioNome);

      if (response.status === 200 || response.status === 202 && response.data) {
        setFile(false);
        setFileName(msgPadraoUpload);
        setImportando(false);
        setAnoBase(null);
        setResultadoOperacao(true);

        const dadosRetorno = response?.data;
        const mensagemRetorno = ["Processo de Importação Finalizado"];

        if(Array.isArray(dadosRetorno))
        {
          mensagemRetorno.push(...dadosRetorno); 
        }
        else{
          mensagemRetorno.push(dadosRetorno); 
        }
        setResultadoOperacaoCabecalho(`Rateio PROINFA - Importação do arquivo ${fileName}`)
        setResultadoOperacaoObj({"mensagens": mensagemRetorno });
        
      }
    } catch (error) {
      setFile(false);
      setFileName(msgPadraoUpload);
      setImportando(false);
      setResultadoOperacao(true);
      setResultadoOperacaoCabecalho(`Rateio PROINFA - Importação do arquivo ${fileName}`)
      setResultadoOperacaoObj({"mensagens": [error.message?? "Ocorreu um erro desconhecido"]});
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  }

  const aoEnviarForm = async () => {
    if (file) {
      await EnviarForm();
    }
  };

  return (
    <form className="needs-validation" onSubmit={handleSubmit(aoEnviarForm)}>
      <ResultadoOperacao
        item={resultadoOperacaoObj}
        cabecalhoResultado={resultadoOperacaoCabecalho}
        onClose={() => onFechar()}
      />
      {resultadoOperacao === false ?
        <Loader loading={importando} className="w-auto">
          <Card>
            <Grid container className={classes.containerTitulo}>
              <Grid item xs={11} className={classes.titulo}>
                Importação de Planilha de Base de Rateio PROINFA
              </Grid>
              <Grid item xs={1}>
                <CloseIcon onClick={onFechar} className={classes.icon} />
              </Grid>
            </Grid>
            <Grid container p={2} spacing={4} className={classes.container}>
              <Grid item sm={2} className={classes.containerData}>
                <InputData
                  type="text"
                  label="Ano Base"
                  customValue={anoBase}
                  onChange={(data) => handleChangeAnoBase(data)}
                  renderIconShowHide={false}
                  format="yyyy"
                  views={["year"]}
                  minDate= {moment().add(-100, "years").toDate()}
                />
              </Grid>
              <Grid item sm={12}>
                <UploadDropArea
                  onAdd={onAddUpload}
                  showLoader={false}
                  dropAreaText={fileName}
                  acceptedFiles={[
                    ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                  ]}
                />
              </Grid>
              <Grid item sm={3}>
                <Botao
                  type="submit"
                  label="Salvar"
                  className={classes.button}
                />
              </Grid>
            </Grid>
          </Card>
        </Loader>
        : ""
      }
    </form>
  )
}

export default BaseRateioProinfaImportar;