/* eslint-disable import/no-unresolved */
import moment from "moment";
import React, { useState, useEffect, useCallback } from "react";
import { Grid, Card, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { InputTextoData } from "componentes";
import { useStyles, Tabela } from "./style";
import { getDistribuidoras, getProjecaoCustos } from "../../../servicos/projecaoCustos";
import CloseIcon from "@mui/icons-material/Close";
import RotasDTO from "../../../rotas/rotasUrlDto";
import { useHistory } from "react-router-dom";

const BaseEERVisualizar = () => {
    const classes = useStyles();
    const history = useHistory();

    const onFechar = (id) => {
        history.push(`${RotasDTO.ProjecaoInsumoListar}`);
    }
    const [valores, setValores] = useState([]);
    const [, setError] = useState(false);

    const [ano, setAnoInicio] = useState(moment());

    const [distribuidoras, setDistribuidoras] = useState([]);
    const [distribuidora, setDistribuidora] = useState("");

    const obterValores = async (anoValor, idDistribuidora) => {
        try {
            const response = await getProjecaoCustos(anoValor.format("yyyy"), idDistribuidora);
            if (response.status === 200 && response.data) {
                setValores(response?.data);

            } else {
                setValores([]);
                store.dispatch(
                    alertaExibir({
                        tipo: "warning",
                        mensagem: "Não há dados para exibição."
                    })
                );
            }
        } catch (exception) {
            setError(true);
            store.dispatch(
                alertaExibir({
                    tipo: "warning",
                    mensagem:
                        exception?.response?.data?.message ??
                        "Erro interno, entre em contato com o suporte!"
                })
            );
        }
    };

    const obterDistribuidoras = useCallback(async () => {
        try {
            const response = await getDistribuidoras();
            if (response?.status === 200 && response?.data) {
                setDistribuidoras(response?.data);
            }
        } catch (error) {
            console.info(error);
        }
    });

    useEffect(() => {
        if (!distribuidoras.length) obterDistribuidoras();
    }, []);

    const handleChangeAnoInicio = async (data) => {
        setAnoInicio(moment(data));
        if (distribuidora)
            obterValores(moment(data), distribuidora)
    }

    const handleChangeDistribuidora = async (event) => {
        setDistribuidora(event.target.value);
        obterValores(ano, event.target.value)
    };

    return <>
        <Grid container className={classes.containerTitulo}>
            <Grid item xs={11} className={classes.titulo}>
                Projeção de Custos de Exceção
            </Grid>
            <Grid item xs={1} >
                <CloseIcon onClick={onFechar} className={classes.icon} />
            </Grid>
        </Grid>
        <Grid container sm={12}>
            <Grid container spacing={4} p={2} className={classes.container} justifyContent="flex-end" alignItems="center">
                <Grid item sm={2}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputTextoData
                            type="text"
                            id="anoInicio"
                            name="anoInicio"
                            label="Ano"
                            customValue={ano}
                            format="yyyy"
                            onChange={(data) => handleChangeAnoInicio(data)}
                            minDate={moment().subtract(10, "years").toDate()}
                            views={["year"]}
                            renderIconShowHide={false}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={3} className={classes.customSelect}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="perid">Distribuidora</InputLabel>
                        <Select
                            labelId="distr"
                            id="distr"
                            value={distribuidora ?? ""}
                            onChange={handleChangeDistribuidora}
                            label="Distribuidora"
                            fullWidth>
                            {distribuidoras.map((dist) => (
                                <MenuItem value={dist.idDistribuidora}>{dist.titulo}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </Grid>
        {valores.length && 
        <Grid container spacing={4} p={2} className={classes.container}>
            <Grid item sm={12}>
                <Card className={classes.container}>
                    <Grid container spacing={4} className={classes.container}>
                        <Grid item sm={12} style={{ paddingTop: "0px" }}>
                            <Grid item sm={12} className={classes.customPosition} style={{ paddingTop: "10px", paddingBottom: "0px" }}>
                                <InputLabel style={{ color: "#000" }}>• Valores em R$ (Reais)</InputLabel>
                            </Grid>
                            <div className={classes.customPosition}>
                                <Tabela>
                                    <Tabela.Thead>
                                        <Tabela.Tr>
                                            <Tabela.Th colSpan="12">PROJEÇÃO CUSTO EXCEÇÃO</Tabela.Th>
                                        </Tabela.Tr>
                                        <Tabela.Tr>
                                            <Tabela.Th>TIPO CUSTO</Tabela.Th>
                                            <Tabela.Th>GRUPO CONSUMO</Tabela.Th>
                                            <Tabela.Th>VALOR</Tabela.Th>
                                        </Tabela.Tr>
                                    </Tabela.Thead>
                                    <Tabela.Tbody>
                                        {valores?.map((item) => (
                                            <Tabela.Tr>
                                                <Tabela.Td>{item.TipoCustoPCAT}</Tabela.Td>
                                                <Tabela.Td>{item.GrupoConsumo}</Tabela.Td>
                                                <Tabela.Td>{item.Custo ? `${item.Custo?.toLocaleString("pt-BR", { currency: "BRL", minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : "-"}</Tabela.Td>
                                            </Tabela.Tr>
                                        ))}
                                    </Tabela.Tbody>
                                </Tabela>
                            </div>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        </Grid>
        }
    </>;
}

export default BaseEERVisualizar;
