import { makeStyles } from "@mui/styles";
import styled from "styled-components";
import { Base } from "componentes/cores";
import theme from "themes";

export const useStyles = makeStyles({
  container: {
    paddingBottom: 0,
    margin: "0 !important",
    width: "100%",
    "& .MuiGrid-item": {
      paddingBottom: 0
    },
    "& .MuiInputBase-root": {
      height: "46px",
      paddingBottom: 0
    },
    "& .MuiFormLabel-root": {
      lineHeight: "1"
    },
    "& .MuiGrid-spacing-xs-4 > .MuiGrid-item": {
      padding: "12px"
    }
  },
  titulo: {
    boxShadow: `0px 10px 15px -15px ${theme.color.primaryBackgroud}`,
    fontSize: "18px"
  },
  buttonAssinar: {
    fontSize: "18px",
    "& svg": {
      marginRight: "10px",
      "& path": {
        backgroundColor: Base.White
      }
    }
  },
  containerAvancar: {
    margin: "0 0 16px !important"
  },
  button: {
    fontSize: "18px"
  }
});

export const Conteudo = styled.div`
  font-size: 18px;
  height: 100%;
  max-height: 492px;
  overflow: scroll;
  width: 100%;
`;
