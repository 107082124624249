/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import EsqueletoEtapa from "../../Componentes/EsqueletoEtapa";
import { useStyles } from "./style";
import AprovadoHelper from "./helper";

const Aprovado = ({
  prazo,
  responsavel,
  protocoloDemanda,
  esconderBotaoProximo,
  onVoltarEtapa
}) => {
  const classes = useStyles();
  const [dataMigracao, setDataMigracao] = useState();

  const getDataMigracao = async () => {
    // eslint-disable-next-line no-shadow
    const dataMigracao = await AprovadoHelper.obterData(protocoloDemanda);

    setDataMigracao(dataMigracao?.data?.dataMigracao);
  };

  useEffect(() => {
    getDataMigracao();
  }, []);

  return (
    <EsqueletoEtapa
      prazo={prazo}
      responsavel={responsavel}
      onClickVoltar={onVoltarEtapa}
      titulo="Fim"
      esconderBotaoProximo={esconderBotaoProximo}
    >
      <Grid item xs={12} className={classes.containerContent}>
        <h4>Parabéns, contratação aprovada!</h4>
        <br />
        <h4>O fornecimento de energia pela Delta será a partir de:</h4>
        <br />
        <h4 className="font-weight-bold">{dataMigracao}</h4>
      </Grid>
    </EsqueletoEtapa>
  );
};

export default Aprovado;
