/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { initDocDetectModule } from "global/utils/initDocDetectModule";
import { store } from "../../global/redux";

import {
  ModalContent,
  Container,
  Button,
  Texto,
  BoxButton,
  Previa,
  Area
} from "./styles";

const ModalCamera = ({ fechar, onCapturar, onVoltar, carregouScript }) => {
  const [imgSrc, setImgSrc] = useState(null);

  const carregaDynamsoft = async () => {
    if (!carregouScript) {
      Dynamsoft.Core.CoreModule.loadWasm(["DDN"]);
      Dynamsoft.DDV.Core.loadWasm();
      await Dynamsoft.License.LicenseManager.initLicense(
        "DLS2eyJoYW5kc2hha2VDb2RlIjoiMTAyNzQ2MzI3LVRYbFhaV0pRY205cSIsIm1haW5TZXJ2ZXJVUkwiOiJodHRwczovL21kbHMuZHluYW1zb2Z0b25saW5lLmNvbSIsIm9yZ2FuaXphdGlvbklEIjoiMTAyNzQ2MzI3Iiwic3RhbmRieVNlcnZlclVSTCI6Imh0dHBzOi8vc2Rscy5keW5hbXNvZnRvbmxpbmUuY29tIiwiY2hlY2tDb2RlIjoyNTgwNTk0Njh9",
        true
      );
      await Dynamsoft.DDV.Core.init();
      // Configure document boundaries function
      await initDocDetectModule(Dynamsoft.DDV, Dynamsoft.CVR);
    }

    const captureViewer = new Dynamsoft.DDV.CaptureViewer({
      container: "container",
      uiConfig: {
        type: Dynamsoft.DDV.Elements.Layout,
        flexDirection: "column",
        children: [
          Dynamsoft.DDV.Elements.MainView,
          {
            type: Dynamsoft.DDV.Elements.Layout,
            className: "ddv-capture-viewer-footer-mobile",
            style: {
              background: "#332053",
              fontFamily: "iconfont !important"
            },
            children: [
              {
                type: Dynamsoft.DDV.Elements.AutoDetect,
                className: "ddv-capture-viewer-autoDetectButton",
                style: {
                  backgroundColor: "transparent",
                  fontFamily: "iconfont !important"
                }
              },
              {
                type: Dynamsoft.DDV.Elements.AutoCapture,
                className: "ddv-capture-viewer-autoCaptureButton",
                style: {
                  background: "transparent",
                  fontFamily: "iconfont !important"
                }
              },
              {
                type: Dynamsoft.DDV.Elements.Capture,
                className: "ddv-capture-viewer-captureButton",
                style: {
                  background: "#FD9507",
                  fontFamily: "iconfont !important"
                }
              },
              {
                type: Dynamsoft.DDV.Elements.CameraConvert,
                className: "ddv-capture-viewer-cameraConvertButton",
                style: {
                  fontFamily: "iconfont !important"
                }
              },

              {
                type: Dynamsoft.DDV.Elements.Button,
                className: "ddv-button-close",
                tooltip: "close viewer",
                events: {
                  click: () => voltar()
                },
                style: {
                  color: "#fa3eb1",
                  fontWeight: "bold",
                  fontFamily: "iconfont !important"
                }
              }
            ]
          }
        ]
      },
      viewerConfig: {
        acceptedPolygonConfidence: 65,
        enableAutoCapture: true,
        enableAutoDetect: true,
        maxFrameNumber: 10,
        quadSelectionStyle: {
          background: "rgba(255,255,255,0)",
          border: "2px solid #fa3eb1"
        }
      }
    });

    let temErro = false;

    await captureViewer
      .play({
        resolution: [3840, 2160]
      })
      .catch(() => {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem:
              "Para usar a câmera habilite-a nas configurações do seu navegador!"
          })
        );
        fechar();
        temErro = true;
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      });

    if (temErro) return;

    captureViewer.on("captured", async (e) => {
      captureViewer.stop();
      document.getElementById("container").style.display = "none";

      const pageData = await captureViewer.currentDocument.getPageData(
        e.pageUid
      );
      const blob = URL.createObjectURL(pageData.display.data);
      setImgSrc(blob);
    });

    // Restore Button function
    document.getElementById("restore").onclick = () => {
      setImgSrc(null);
      captureViewer.currentDocument.deleteAllPages();
      captureViewer.play({
        resolution: [3840, 2160]
      });
      document.getElementById("container").style.display = "";
    };
  };

  const iniciarVideo = async () => {
    try {
      if (window?.location) {
        const protocolo = window?.location?.protocol;

        if (protocolo === "https:") {
          carregaDynamsoft();
        } else {
          store.dispatch(
            alertaExibir({
              tipo: "warning",
              mensagem: "Para usar a câmera é necessário protocolo https!"
            })
          );
          fechar();
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }
      }
    } catch (e) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            "Para usar a câmera habilite-a nas configurações do seu navegador!"
        })
      );
      fechar();
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  };

  const prosseguir = () => {
    const fileName = "fatura.jpg"; // Mudança para extensão .jpg

    // Função para carregar o Blob associado à URL
    const blobUrl = imgSrc;
    function fetchBlobFromUrl(blobUrl) {
      return fetch(blobUrl).then((response) => response.blob());
    }

    // Obtendo o Blob e criando o objeto File
    fetchBlobFromUrl(blobUrl).then((blob) => {
      const file = new File([blob], fileName, {
        type: "image/jpeg" // Tipo especificado como 'image/jpeg'
      });

      fechar();
      onCapturar(file);
    });
  };

  const voltar = () => {
    setImgSrc(null);
    fechar();
    onVoltar();
  };

  useEffect(() => {
    iniciarVideo();
  }, []);

  return (
    <ModalContent>
      <Container>
        <div id="container" style={{ height: "100%" }} />
        {imgSrc && (
          <Area>
            <Previa alt="fatura" src={imgSrc && imgSrc} />
          </Area>
        )}
        <BoxButton className={`${imgSrc ? "block " : "d-none"}`}>
          <Button onClick={voltar} className="mb-2 transparent">
            Voltar
          </Button>
          <div>
            <Texto className="mb-2">As informações estão legíveis?</Texto>
            <Button id="restore" className="mb-2 transparent">
              Tirar outra foto
            </Button>
            <Button onClick={prosseguir}>Sim, enviar</Button>
          </div>
        </BoxButton>
      </Container>
    </ModalContent>
  );
};

ModalCamera.propTypes = {
  fechar: PropTypes.func,
  onCapturar: PropTypes.func,
  onVoltar: PropTypes.func,
  carregouScript: PropTypes.bool
};

ModalCamera.defaultProps = {
  fechar: () => {},
  onCapturar: () => {},
  onVoltar: () => {},
  carregouScript: false
};

export default ModalCamera;
