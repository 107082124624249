import { makeStyles } from "@mui/styles";
import styled from "styled-components";
import theme from "themes";
import { Base } from "../../../../componentes/cores";

export const useStyles = makeStyles({
  container: {
    margin: "0 0 0px",
    width: "100%",
    "& .MuiOutlinedInput-input": {
      color: Base.White
    },
    "& .MuiFormLabel-root": {
      color: `${Base.White} !important`,
      backgroundColor: "#191C22"
    },
    "& .MuiFormControl-marginNormal": {
      borderRadius: "4px"
    } 
  },  
  select: {
    "& label.Mui-focused": {
      color: `${Base.OffWhite} !important`
    },
    "& .MuiInput-underline:after": {
      borderColor: Base.OffWhite
    },
    "& .MuiFormLabel-root": {
      color: `${Base.OffWhite} !important`,
    },
    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-input": {
        color: `${Base.OffWhite} !important`
      },
      "& .MuiInputAdornment-root": {
        color: `${Base.OffWhite} !important`
      },
      "& fieldset": {
        borderColor: Base.OffWhite
      },
      "&:hover fieldset": {
        borderColor: Base.OffWhite
      },
      "&.Mui-focused fieldset": {
        borderColor: Base.OffWhite
      }
    },
    "& .MuiSelect-icon": {
      color: Base.White
    }
  },
  itemFiltro: {
    display: "flex",
    justifyContent: "flex-end"
  },
  buttonExcel: {
    fontSize: "18px",
    padding: "9.5px 5px",
    "& img": {
      width: "18px",
      marginLeft: "16px"
    }
  },
  buttonFiltro: {
    padding: "9.5px 5px"
  },
  tabela: {    
    "& div": { opacity: "1 !important"}    
  },
  selectData: {
    color: Base.White
  },
  barraSuperior: {
    fontSize: "18px",
    fontStyle: "italic",
    height: "42px",
    textAlign: "center",
    color: `${theme.color.textoBarra}`,
    width: "100%"
  },
  arrowBack:{
    color: `${theme.color.textoBarra}`,
    position:"absolute",
    left:0,
    paddingLeft:15
  },
  textoBranco:{
    color: "#686d77",
    textAlign: "center",
  },
  textoSelecionado:{
    color: "#FEBB2F"
  },
  pin:{
    margin: "0 auto",
    marginBottom: 15,
    width:10,
    height:40,
    backgroundColor: "#686d77",
    border: "1px",
    borderRadius: 25
  },
  pinSelecionado:{
    backgroundColor: "#FEBB2F"
  },
  tituloSuperior:{
    color: `${Base.WhiteSmoke}`,
    marginTop: 25,
  },
  tituloSuperiorValor:{
    color: `${Base.WhiteSmoke}`,
    fontSize:22,
    marginTop: 10,
    marginBottom: 35
  },
  backgroundCinza:{
    backgroundColor: "#282E3D",
    padding: 10
  },
  boxStatus:{
    color: `${Base.WhiteSmoke}`,
    marginTop: 25,
    padding: 10
  },
  borderAtrasado:{
    borderLeftColor: `${theme.color.bgAtrasado}`,
    borderLeftWidth: 10,
    borderLeftStyle: "solid",
    paddingLeft: 10
  },
  borderPago:{
    borderLeftColor: `${theme.color.bgPago}`,
    borderLeftWidth: 10,
    borderLeftStyle: "solid",
    paddingLeft: 10
  },
  borderPendente:{
    borderLeftColor: `${theme.color.bgPendente}`,
    borderLeftWidth: 10,
    borderLeftStyle: "solid",
    paddingLeft: 10
  },
  boxDatas:{
    color: `${Base.WhiteSmoke}`,
    marginTop: 25,
    padding: 10
  },
  boxBotoes:{
    cursor: "pointer",
    marginTop: 80    
  },
  botaoAcao:{
    paddingTop: 10,
    paddingLeft: 10,
    width:100,
    height: 110,
    color: `${Base.WhiteSmoke}`,
    backgroundColor: "#282E3D" 
  },
  botaoCentro:{
    margin: "0 auto"
  },
  botaoRight:{
    float: "right"
  },
  iconeBotao:{
    display: "block",
    marginBottom: 7
  },
  boxArea:{
    textAlign: "center",
    padding: "20px 0",
    backgroundColor: Base.NovoBGMenuHuver,
    borderColor: "#f1f1f1",
    borderWidth: 2,
    borderStyle: "solid",
    borderRadius: 10,
    width: "100%"
  },
  valorFatura:{
    margin: "15px 0",
    fontSize: 22,
    fontWeight: "bold",
    color: theme.color.valorCorFatura
  },
  vencimentoFatura:{
    fontSize: 14,
    marginTop: 10
  },
  divider: {
    margin: "8px 18px 16px"
  },
  fundoCircular: {
    marginRight: 10,
    width: 20,
    height: 20,
    borderRadius: 5,
    display: "inline-table"
  },
  fundoPendente: {
    backgroundColor: `${theme.color.bgPendente}`,
  },
  fundoAtrasado: {
    backgroundColor: `${theme.color.bgAtrasado}`,
  },
  fundoPago: {
    backgroundColor: `${theme.color.bgPago}`,
  },
  fundoPagoParcial: {
    backgroundColor: `${theme.color.bgPagoParcial}`,
  },
  botaoDownloadFatura:{
    backgroundColor: `${theme.color.botaoDownloadFatura}`
  }
});

export const Icone = styled.img`
  margin-left: 2px;
  max-width: 18px;
  object-fit: cover;
`;

export const Button = styled.button`
  background-color: ${theme.color.botaoDownloadFatura};
  color: ${theme.color.secondaryBackgroud};
  padding: 10px;
  width: 250px;
  border: 0;
  border-radius: 30px;
`;
