const microServicosUrl = {
  MEASUREMENT: process.env.REACT_APP_MEASUREMENT_API_URL,
  PROFILE: process.env.REACT_APP_PROFILE_API_URL,
  MANAGEMENT: process.env.REACT_APP_MANAGEMENT_API_URL,
  INTEGRATION: process.env.REACT_APP_INTEGRATION_API_URL,
  AREALOGADA: process.env.REACT_APP_AREALOGADA_API_URL,
  PROJECAOTARIFARIA: process.env.REACT_APP_PROJECAOTARIFARIA_API_URL,
  FATURAMENTO: process.env.REACT_APP_FATURAMENTO_API_URL,
  CALCULOCUSTOS: process.env.REACT_APP_CALCULOCUSTOS_API_URL,
  PROCESSAMENTO: process.env.REACT_APP_PROCESSAMENTO_API_URL,
  NOTIFICACAO: process.env.REACT_APP_NOTIFICACAO_API_URL,
  SERASA: process.env.REACT_APP_SERASA_API_URL,
  LUZ: process.env.REACT_APP_SITE_LUZ_API_URL,
  TELEMETRIA: process.env.REACT_APP_TELEMETRIA_API_URL,
  DGINTEGRACAO: process.env.REACT_APP_DGINTEGRACAO_API_URL,
  DELTAPROCESSAMENTO: process.env.REACT_APP_DELTA_PROCESSAMENTO_OCR_API_URL,
  SITELUZ: process.env.REACT_APP_SITE_LUZ_LEAD_API_URL,
  MAQUINACALCULO: process.env.REACT_APP_MAQUINA_CALCULO_API_URL,
  DELTASDK: process.env.REACT_APP_DELTA_SDK_API_URL,
  MEMBERGETMEMBER: process.env.REACT_APP_MEMBER_GET_MEMBER_API_URL
};

export default function retornaApiUrl(microServico) {
  return microServicosUrl[microServico];
}
