/* eslint-disable react/prop-types */
import React, { useEffect, useLayoutEffect, useState } from "react";
import { Grid } from "@mui/material";
import CardDownload from "componentes/cardDownload";
import ModalContrato from "componentes/modalContrato";
// import { BlobProvider } from "@react-pdf/renderer";
// import FileSaver from "file-saver";
import { ProtocolosEtapas, tipoEnvioContrato } from "global/constantes";
import { Clicksign } from "bibliotecas/embedded";
import ModalInformacao from "componentes/modalVerificacao";
import { Loader } from "componentes";
// Imagens
import { store } from "global/redux";
import { SetModoEnvioAssinatura } from "global/redux/modulos/workflow/actions";
import getDomain from "global/getDomain";
import EsqueletoEtapa from "../../Componentes/EsqueletoEtapa";
import { DocumentoClicksign } from "../AssinarTermo/style";
import ConfirmacaoHelper from "./helper";
import { useStyles, ModeloDocumento } from "./style";
import IconAssinatura from "../../../../../assets/icones/workflow/assinatura.svg";
import enumeradorPlataforma from "../../Enumeradores/enumeradorPlataforma";
// import TermoConfirmacao from "../../../../Relatorios/OnBoarding/TermoConfirmacao";
import OnBoardingHelper from "../../helper";

const Confirmacao = ({
  prazo,
  responsavel,
  onProximaEtapa,
  labelProximo,
  protocoloDemanda,
  onVoltarEtapa,
  somenteLeitura
}) => {
  const classes = useStyles();

  const [assinar, setAssinar] = useState(false);
  // const [pdfConfirmacao, setPdfConfirmacao] = useState();
  const [exibirModalAssinar, setExibirModalAssinar] = useState(false);
  const [requestSignatureKeyList, setRequestSignatureKeyList] = useState([]);
  const [documentosCarregados, setDocumentosCarregados] = useState([]);
  const [carregandoDocumento, setCarregandoDocumento] = useState(false);
  const [loadingGeral, setLoadingGeral] = useState(false);
  const [plataforma, setPlataforma] = useState();
  const [carregandoTermoConfirmacao, setCarregandoTermoConfirmacao] = useState(
    false
  );

  const onClickProximo = () => {
    if (somenteLeitura) {
      onProximaEtapa(
        ProtocolosEtapas.ValidacaoAssinaturaConfirmacaoContrato,
        true
      );
      return;
    }

    setAssinar(true);
  };

  const onClickVoltar = () => {
    onVoltarEtapa(ProtocolosEtapas.PrevioAnaliseRisco);
  };

  const avancarEtapa = async () => {
    setAssinar(false);
    setLoadingGeral(true);

    const result = await ConfirmacaoHelper.criarDocumento(
      plataforma,
      protocoloDemanda
    );

    if (!result.sucesso) {
      ConfirmacaoHelper.exibirErro(result.mensagem);
      setLoadingGeral(false);
      return;
    }

    if (String(plataforma) !== String(tipoEnvioContrato.AssinarPlataforma)) {
      onProximaEtapa(ProtocolosEtapas.AguardandoAssinaturaConfirmacao);
      store.dispatch(SetModoEnvioAssinatura(plataforma));
      setLoadingGeral(false);
      return;
    }

    setDocumentosCarregados(result.data.chaveAssinaturas);
    setLoadingGeral(false);
    setExibirModalAssinar(true);
  };

  const onChangePlataforma = (event) => {
    setPlataforma(event?.target?.value);
  };

  const onClickGerarTermoConfirmacaoPDF = async () => {
    setCarregandoTermoConfirmacao(true);
    // setPdfConfirmacao();

    const {
      sucesso,
      mensagem
    } = await OnBoardingHelper.ObterDadosParaTermoAdesao(protocoloDemanda);

    if (!sucesso) {
      setCarregandoTermoConfirmacao(false);
      OnBoardingHelper.exibirErro(mensagem);
      return;
    }

    setCarregandoTermoConfirmacao(false);
    // eslint-disable-next-line react/jsx-props-no-spreading
    // setPdfConfirmacao(<TermoConfirmacao {...data} />);
    // eslint-disable-next-line no-useless-return
    return;
  };

  // const baixarPdf = ({ blob, loading, error }) => {
  //   if (loading || error || !blob) return <></>;
  //   FileSaver.saveAs(blob, "Termo de Adesão");
  //   // setPdfConfirmacao(null);
  //   return <></>;
  // };

  useEffect(() => {
    setRequestSignatureKeyList(documentosCarregados);
  }, [documentosCarregados]);

  let widget;
  // Widget
  useLayoutEffect(() => {
    if (exibirModalAssinar && requestSignatureKeyList?.length) {
      setCarregandoDocumento(true);

      if (widget) {
        widget.unmount();
      } else {
        widget = window.document.getElementById("refDocumento");
        if (widget) widget.innerHTML = "";
      }

      widget = new Clicksign(requestSignatureKeyList[0]);

      widget.endpoint = "https://sandbox.clicksign.com";
      widget.origin = "http://luz.localhost:3000/";

      setTimeout(() => {
        widget.mount("refDocumento");
      }, 1000);

      widget.on("loaded", function () {
        setCarregandoDocumento(false);
      });

      widget.on("signed", function () {
        setTimeout(() => {
          const documentosRestantes = [...requestSignatureKeyList];
          documentosRestantes.splice(0, 1);

          if (documentosRestantes?.length) {
            setRequestSignatureKeyList(documentosRestantes);
            setExibirModalAssinar(true);
          } else onProximaEtapa();
        }, 1000);
      });
    }
  }, [exibirModalAssinar, requestSignatureKeyList]);

  const modeloDoc = `themes/${getDomain()}/assets/contrato_workflow.png`;

  return (
    <>
      <Loader loading={loadingGeral}>
        <ModalContrato
          item={assinar}
          onConfirmar={() => avancarEtapa()}
          mensagem={
            <Loader loading={carregandoDocumento}>
              <ModeloDocumento src={modeloDoc} />
            </Loader>
          }
          dataSourcePlataforma={enumeradorPlataforma}
          valuePlataforma={plataforma}
          onChange={(event) => onChangePlataforma(event)}
          onCancelar={() => {
            setAssinar(false);
          }}
        />
        <ModalInformacao
          telaInteira
          exibir={exibirModalAssinar}
          titulo="Assinar documento"
          className="h-100 w-100"
          mensagem={
            <DocumentoClicksign className="h-100 w-100" id="refDocumento" />
          }
          labelBotao="OK"
          onClose={() => {
            setExibirModalAssinar(false);
          }}
          showBotao={false}
        />
        <EsqueletoEtapa
          prazo={prazo}
          responsavel={responsavel}
          titulo="Confirmação"
          onClickProximo={() => onClickProximo()}
          onClickVoltar={() => onClickVoltar()}
          labelProximo={labelProximo}
        >
          <Grid>
            <p>Clique na caixa abaixo para realizar o download da minuta.</p>
            <p>
              Clique em “Assinar” para enviar o documento aos Representantes
              cadastrados.
            </p>
          </Grid>
          <Grid xs={12}>
            {/* {pdfConfirmacao && (
              <BlobProvider document={pdfConfirmacao}>{baixarPdf}</BlobProvider>
            )} */}
            <Loader loading={carregandoTermoConfirmacao}>
              <CardDownload
                text="Validação do contrato"
                icon={IconAssinatura}
                onClickDownload={() => onClickGerarTermoConfirmacaoPDF()}
              />
            </Loader>
          </Grid>
          <Grid item xs={12} className={classes.containerContent}>
            <Grid container>
              <Grid item xs={12} />
            </Grid>
          </Grid>
        </EsqueletoEtapa>
      </Loader>
    </>
  );
};

export default Confirmacao;
