import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { Card, Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useStyles } from './style';
import InputData from 'componentes/inputTextoData';
import { Base } from 'componentes/cores';
import Loader from 'componentes/loader';
import UploadDropArea from 'componentes/upload';
import Botao from 'componentes/botao';
import ValidacaoDominios from 'componentes/validacaoDominios';
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { ImportPCAT, GetLatestImportsByIdDistribuidora, possuiImportsDeUmAnoTarifario } from '../../servicos/pcat';
import { listarDistribuidoras } from '../../servicos/parametrizacaoDistribuidoras';
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { useHistory } from "react-router-dom";
import RotasDTO from "../../rotas/rotasUrlDto";
import ModalInformacao from "componentes/modalVerificacao";
import { importPing } from "../../utils/import-ping"
import { geraURLUpload } from "../../servicos/awsUtils";
import AWSS3UploadAsh from 'aws-s3-upload-ash'

const PCAT = () => {
  const classes = useStyles();
  const history = useHistory();
  const usuarioGlobal = useSelector((state) => state.usuario);
  const msgPadraoUpload = "Solte o arquivo para anexá-lo ou procurar";

  const { handleSubmit } = useForm({
    reValidateMode: 'onSubmit',
  });

  const [file, setFile] = useState(false);
  const [fileName, setFileName] = useState(msgPadraoUpload);
  const [carregandoArquivo, setCarregandoArquivo] = useState(false);
  const [progressMesage, setProgressMessage] = useState("");

  const [distribuidora, setDistribuidora] = useState({});
  const [distribuidoraPCATS, setDistribuidoraPCATS] = useState([]);
  const [ultimaPCAT, setUltimaPCAT] = useState({});
  const [atualPCAT, setAtualPCAT] = useState({});
  const [existPCAT, setExistPCAT] = useState(false);
  const [modalInfo, setModalInfo] = useState(false);
  const [tituloModal, setTituloModal] = useState("");
  const [resultadosImportacao, setResultadosImportacao] = useState([]);


  const [validacaoDominio, setValidacaoDominio] = useState(false);
  const [validacaoDominioCabecalho, setValidacaoDominioCabecalho] = useState("");
  const [validacaoDominiosObj, setValidacaoDominiosObj] = useState({
    "contexto": "",
    "entidades": []
  });

  const [distribuidoras, setDistribuidoras] = useState([]);
  const [carregandoDistribuidoras, setCarregandoDistribuidoras] = useState(false);
  const S3CustomClient = new AWSS3UploadAsh();

  const obterDistruidoras = useCallback(async () => {
    try {
      setCarregandoDistribuidoras(true);
      const lista = await listarDistribuidoras(true);
      if (lista.status === 200 && lista.data) {
        setDistribuidoras(lista?.data);
      }
      setCarregandoDistribuidoras(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoDistribuidoras(false);
    }
  }, []);

  useEffect(() => {
    if (!distribuidoras.length) obterDistruidoras();
  }, [!distribuidoras.length, obterDistruidoras]);

  const getListImportsByIdDistribuidora = async (id) => {
    const retorno = await GetLatestImportsByIdDistribuidora(id);

    if (retorno.data && retorno.data.length > 0) {
      setDistribuidoraPCATS(retorno.data);
      setUltimaPCAT(retorno.data[0]);
    } else {
      setDistribuidoraPCATS([]);
      setUltimaPCAT({});
    }

  }

  const handleChangeDistribuidora = async (event) => {
    setDistribuidora(
      {
        id: event.target.value,
        titulo: event?.currentTarget?.dataset.titulo,
        nomefantasia: event?.currentTarget?.dataset?.nomefantasia,
        razaosocial: event?.currentTarget?.dataset?.razaosocial,
        diareajustetarifario: event?.currentTarget?.dataset?.diareajustetarifario,
        mesreajustetarifario: event?.currentTarget?.dataset?.mesreajustetarifario,
      }
    );
    await getListImportsByIdDistribuidora(event.target.value);
  }

  const [anoTarifario, setAnoTarifario] = useState(null);
  const handleChangeAnoTarifario = (data) => {
    setAnoTarifario(data);
  }

  const [vigenciaCorrecao, setVigenciaCorrecao] = useState(null);
  const handleChangeVigenciaCorrecao = (data) => {
    setVigenciaCorrecao(data);
  }

  const [acaoID, setAcaoID] = useState(null);
  const handleChangeAcao = (event) => {
    setAcaoID(event.target.value);
  }

  useEffect(() => {
    setExistPCAT(false);
  }, [ultimaPCAT, anoTarifario]);

  const processarArquivo = (arquivo) => {
    if (arquivo != null && arquivo[0] != null) {
      setFile(arquivo[0]);
      setFileName(arquivo[0].file.name);
    }
  };

  const onAddUpload = (arquivo) => {
    processarArquivo(arquivo)
  };

  const onCancelar = () => {
    setFile(false);
    setFileName(msgPadraoUpload);
    setCarregandoArquivo(false);
    setValidacaoDominio(false);
    setExistPCAT(false);
    setUltimaPCAT({});
    setAtualPCAT({});
    setDistribuidoraPCATS([]);
    setDistribuidora({})
    setAnoTarifario(null);
    setVigenciaCorrecao(null);
    setAcaoID(null);
  }

  const onCancelarValidacaoDominios = () => {
    setFile(false);
    setFileName(msgPadraoUpload);
    setCarregandoArquivo(false);
    setValidacaoDominio(false);
    setExistPCAT(false);
    setUltimaPCAT({});
    setAtualPCAT({});
    setDistribuidoraPCATS([]);
    setDistribuidora({})
    setAnoTarifario(null);
    setVigenciaCorrecao(null);
    setAcaoID(null);
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem: "Importação cancelada!"
      })
    );
  }

  const importarPCAT = async (data) => {
    if (data && data.totalDesmarcado === 0 && data.responseInsert.totalInserido === data.responseInsert.totalValores) {
      if (file && data) {
        setCarregandoArquivo(true);
        setValidacaoDominio(false);
        setProgressMessage("Importando o arquivo... Por favor, aguarde.");
        setExistPCAT(false);
        try {
          let novaVigencia = null;
          if (vigenciaCorrecao != null) {
            novaVigencia = vigenciaCorrecao.toISOString();
          }
          const response = await ImportPCAT(file, distribuidora.id, false, anoTarifario.getFullYear(), novaVigencia, usuarioGlobal?.usuario?.id, usuarioGlobal?.usuario?.usuarioNome);
          if (response?.status === 200 && response?.data) {
            onCancelar();
            let mensagemRetorno = [];
            mensagemRetorno.push(`Arquivo '${file.file.name}' importado com sucesso!`);
            mensagemRetorno.push(response.data[0].Mensagem);
            setResultadosImportacao(mensagemRetorno);
            setTituloModal("Resultado da Importação");
            setModalInfo(true);
          }
          else if (response?.status === 202 && response?.data) {
            if (response?.data.typeError === "ERRO-VALIDACAO-ESTRUTURA" ||
              response?.data.typeError === "ERRO-VALIDACAO-TARIFA" ||
              response?.data.typeError === "ERRO-VALIDACAO-MERCADO-FATURADO" ||
              response?.data.typeError === "ERRO-VALIDACAO-CUSTOS" ||
              response?.data.typeError === "ERRO-VALIDACAO-PESO-TARIFARIO" ||
              response?.data.typeError === "ERRO-VALIDACAO-EFEITO" ||
              response?.data.typeError === "ERRO-VALIDACAO-TARFIN" ||
              response?.data.typeError === "ERRO-VALIDACAO-TARIFA-CONSOLIDADA") {
              setFile(false);
              setFileName(msgPadraoUpload);
              setCarregandoArquivo(false);
              setValidacaoDominio(false);
              store.dispatch(
                alertaExibir({
                  tipo: "warning",
                  mensagem: response.data.value[0].mensagem
                })
              );
            }
          }
        } catch (error) {
          setFile(false);
          setFileName(msgPadraoUpload);
          setCarregandoArquivo(false);
          setValidacaoDominio(false);
          store.dispatch(
            alertaExibir({
              tipo: "danger",
              mensagem: "Erro ao importar arquivo!"
            })
          );
        }
      } else {
        store.dispatch(
          alertaExibir({
            tipo: "danger",
            mensagem: "É necessário informar o arquivo!"
          })
        );
      }
    } else {
      if (data && data.responseInsert && data.responseInsert.totalInserido > 0) {
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: data.responseInsert.totalInserido === 1 ? `1 valor registrado com sucesso!` : `${data.responseInsert.totalInserido} valores registrados com sucesso!`
          })
        );
      }
      setCarregandoArquivo(false);
      setValidacaoDominio(false);
    }
  }

  const importarPCATComValidacao = async () => {
    console.log('importarPCATComValidacao')
    if (file) {
      setCarregandoArquivo(true);
      setProgressMessage("Importando o arquivo... Por favor, aguarde.");
      setExistPCAT(false);
      try {
        let novaVigencia = null;
        if (vigenciaCorrecao != null) {
          novaVigencia = vigenciaCorrecao.toISOString();
        }

        console.log('file.file', file.file)
        const keyOnS3 = file.file.name;
        const urlPreSigned = await geraURLUpload(keyOnS3)
        const uploadS3Response = await S3CustomClient.uploadFile(file.file, file.file.type, urlPreSigned.data);
        
        console.log('urlPreSigned', urlPreSigned.data)
        console.log('uploadS3Response', uploadS3Response)

        const response = await ImportPCAT(
          distribuidora.id, 
          true, 
          anoTarifario.getFullYear(), 
          novaVigencia, 
          usuarioGlobal?.usuario?.id, 
          usuarioGlobal?.usuario?.usuarioNome, 
          keyOnS3);

        if (response?.status === 200 && response?.data) {
          // onCancelar();
          // let mensagemRetorno = [];
          // mensagemRetorno.push(`Arquivo '${file.file.name}' importado com sucesso!`);
          // mensagemRetorno.push(response.data[0].Mensagem);
          // setResultadosImportacao(mensagemRetorno);
          // setTituloModal("Resultado da Importação");
          // setModalInfo(true);
          setProgressMessage("Processando a importaçäo... Por favor, aguarde.");
          const datetimeImportStarted = response.data.DataHoraRequisicao
          const urlParams = new URLSearchParams(window.location.search);
          let idInsumo = urlParams.get('id')
          let codigoInsumo = urlParams.get('codigo')
          await importPing(idInsumo, datetimeImportStarted, usuarioGlobal?.usuario.id, codigoInsumo, (status, message) => {
            setCarregandoArquivo(false);
            setTituloModal(`Resultado da Importação`)
            setModalInfo(true);
            // let mensagemRetorno = ["Processo de Importação Finalizado.", status, ...message];
            let mensagemRetorno = ["Processo de Importação Finalizado.", status, message];
            setResultadosImportacao(mensagemRetorno);
          })
        } else if (response?.status === 202 && response?.data) {

          if (response?.data.typeError === "ERRO-VALIDACAO-ESTRUTURA" ||
            response?.data.typeError === "ERRO-VALIDACAO-TARIFA" ||
            response?.data.typeError === "ERRO-VALIDACAO-MERCADO-FATURADO" ||
            response?.data.typeError === "ERRO-VALIDACAO-CUSTOS" ||
            response?.data.typeError === "ERRO-VALIDACAO-PESO-TARIFARIO" ||
            response?.data.typeError === "ERRO-VALIDACAO-EFEITO" ||
            response?.data.typeError === "ERRO-VALIDACAO-TARFIN" ||
            response?.data.typeError === "ERRO-VALIDACAO-TARIFA-CONSOLIDADA") {
            setFile(false);
            setFileName(msgPadraoUpload);
            setCarregandoArquivo(false);
            setValidacaoDominio(false);
            let mensagemRetorno = [];
            mensagemRetorno = response.data.value.map((value) => { return value.mensagem });
            setResultadosImportacao(mensagemRetorno);
            setTituloModal("Importação não realizada com as seguintes falhas:");
            setModalInfo(true);
          } else if (response?.data.typeError === "ERRO-VALIDACAO-DOMINIO") {
            setValidacaoDominio(true);
            setValidacaoDominioCabecalho(`Importação do arquivo ${file.file.name}`)
            setValidacaoDominiosObj(response?.data.value);
          }
        }
      } catch (error) {
        setFile(false);
        setFileName(msgPadraoUpload);
        setCarregandoArquivo(false);
        setValidacaoDominio(false);
        store.dispatch(
          alertaExibir({
            tipo: "danger",
            mensagem: "Erro ao importar arquivo!"
          })
        );
      }
    } else {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: "É necessário informar o arquivo!"
        })
      );
    }
  }

  const realizarEnvioPCAT = () => {
    if (distribuidoraPCATS?.length === 0) {
      importarPCATComValidacao();
      return;
    }

    let findPCAT = false;

    distribuidoraPCATS.forEach(pcat => {
      if (pcat.anoTarifario === anoTarifario.getFullYear()) {
        findPCAT = true;

        setAtualPCAT(pcat);

        if (acaoID == null || acaoID == undefined) {
          store.dispatch(
            alertaExibir({
              tipo: "danger",
              mensagem: "É necessário informar o que quer fazer!"
            })
          );

          return;
        }

        if (acaoID !== "2") {
          importarPCATComValidacao();
          return;
        }

        if (vigenciaCorrecao != null) {
          importarPCATComValidacao();
          return;
        }

        store.dispatch(
          alertaExibir({
            tipo: "danger",
            mensagem: "É necessário informar a nova vigência!"
          })
        );
      }
    });

    if (!findPCAT) {
      importarPCATComValidacao();
    }
  }

  const validarPCATsDoAnoTarifario = async () => {
    if (existPCAT) {
      realizarEnvioPCAT();
      return;
    }

    if (ultimaPCAT.anoTarifario === anoTarifario.getFullYear()) {
      setExistPCAT(true);
      return;
    }

    if (!existPCAT) {
      const { data } = await possuiImportsDeUmAnoTarifario(distribuidora.id, anoTarifario.getFullYear());

      if (data === true) {
        setExistPCAT(true);
        return;
      }
    }

    realizarEnvioPCAT();
  }

  const validarEnvioPCAT = async () => {
    if (!file) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: "É necessário informar o arquivo!"
        })
      );

      return;
    }

    if (!(distribuidora && distribuidora.id)) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: "É necessário informar a Distribuidora!"
        })
      );

      return;
    }

    if (!anoTarifario) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: "É necessário informar o Ano Tarifário!"
        })
      );

      return;
    }

    validarPCATsDoAnoTarifario();
  }

  const onFechar = (id) => {
    history.push(`${RotasDTO.ProjecaoInsumoListar}`);
  }


  return (
    <form className="needs-validation" onSubmit={handleSubmit(validarEnvioPCAT)}>
      <ModalInformacao
        titulo={<div>{tituloModal}</div>}
        exibir={modalInfo}
        mensagem={<div style={{ color: Base.BlackBastille }}>
          {resultadosImportacao.map((result) => { return <div style={{ marginBottom: "10px" }}>{result}</div> })
          }
        </div>}
        onClose={() => {
          setModalInfo(false);
        }}
        showBotao={true}
        labelBotao="OK"
      />
      <ValidacaoDominios
        item={validacaoDominiosObj}
        cabecalhoValidacao={validacaoDominioCabecalho}
        onConfirmar={(data) => importarPCAT(data)}
        onCancelar={() => onCancelarValidacaoDominios()}
      />
      {validacaoDominio === false ?
        <Card className={classes.container}>
          <Grid container className={classes.containerTitulo}>
            <Grid item xs={11} className="font-weight-bold">
              Importação de PCAT
            </Grid>
            <Grid item xs={1} >
              <CloseIcon onClick={onFechar} className={classes.iconcustom} />
            </Grid>
          </Grid>
          {existPCAT === true ?
            <div>
              <Grid container spacing={4} p={2} className={classes.container}>
                <Grid item sm={12}>
                  <p>
                    {`Já existe um arquivo PCAT / ${distribuidora?.titulo} para o ano de ${anoTarifario.getFullYear()}.`}
                    <br />
                    Você pode substituir os dados para a vigência atual ou criar uma nova vigência.
                  </p>
                </Grid>
              </Grid>

              <Grid container spacing={4} p={2} className={classes.container} alignItems="center">
                <Grid item sm={4}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="vigencias">O que quer fazer?</InputLabel>
                    <Select
                      labelId
                      id="vigencias"
                      value={acaoID ?? ""}
                      onChange={handleChangeAcao}
                      label="O que quer fazer?"
                      fullWidth>
                      <MenuItem value="1">Substituir vigência</MenuItem>
                      <MenuItem value="2">Nova vigência</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {acaoID === "2" ?
                  <Grid item sm={5}>
                    <InputData
                      type="text"
                      label="Vigência da Correção"
                      customValue={vigenciaCorrecao}
                      onChange={(data) => handleChangeVigenciaCorrecao(data)}
                      minDate={moment(atualPCAT.inicioVigencia).add(1, "days").toDate()}
                      maxDate={moment(atualPCAT.fimVigencia).toDate()}
                      renderIconShowHide={false}
                    />
                  </Grid>
                  : ""}
                {carregandoArquivo === false ?
                  <Grid container spacing={4} p={2} className={classes.container} justifyContent="center">
                    <Grid item sm={3}>
                      <Botao
                        type="button"
                        label="Cancelar"
                        onClick={onCancelar}
                        color={Base.GreyDim}
                        className={classes.button}
                      />
                    </Grid>
                    <Grid item sm={3}>
                      <Botao
                        type="submit"
                        label="Importar"
                        color={Base.Mango}
                        className={classes.button}
                      />
                    </Grid>
                  </Grid>
                  : ""
                }
              </Grid>
            </div>
            : <div>
              <Grid container spacing={4} p={2} className={classes.container}>
                {carregandoArquivo === false ?
                  <Grid item sm={12}>
                    <Grid item sm={12} container spacing={4} p={2} className={classes.container} alignItems="center">
                      <Grid item sm={5}>
                        <Loader loading={carregandoDistribuidoras}>
                          <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="distribuidoras">Distribuidoras</InputLabel>
                            <Select
                              labelId="distribuidoraId"
                              id="distribuidoras"
                              value={distribuidora.id}
                              onChange={handleChangeDistribuidora}
                              label="Distribuidoras"
                              fullWidth>
                              {distribuidoras?.map((item) => (
                                <MenuItem
                                  key={item.idDistribuidora}
                                  value={item.idDistribuidora}
                                  data-titulo={item.titulo}
                                  data-diareajustetarifario={item.diaReajusteTarifario}
                                  data-mesreajustetarifario={item.mesReajusteTarifario}
                                  data-nomefantasia={item.nomeFantasia}
                                  data-razaosocial={item.razaoSocial}
                                >
                                  {item.titulo}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Loader>
                      </Grid>
                      <Grid item sm={3}>
                        <InputData
                          type="text"
                          label="Ano Tarifário"
                          customValue={anoTarifario}
                          format="yyyy"
                          onChange={(data) => handleChangeAnoTarifario(data)}
                          minDate={moment().subtract(8, 'years').toDate()}
                          views={["year"]}
                          renderIconShowHide={false}
                        />
                      </Grid>
                    </Grid>
                    <Grid item sm={12} className={classes.pcatinfo}>
                      {distribuidora && distribuidora?.diareajustetarifario ?
                        <div>
                          {`Aniversário Tarifário: ${distribuidora?.diareajustetarifario}/${distribuidora?.mesreajustetarifario}`}
                        </div>
                        : ""}
                      {ultimaPCAT && ultimaPCAT?.anoTarifario ?
                        <div>
                          {`Última PCAT importada: ${ultimaPCAT.anoTarifario}`}
                        </div>
                        : ""}
                    </Grid>
                    <Grid item sm={12} className={classes.pcatDropFile}>
                      <UploadDropArea
                        onAdd={onAddUpload}
                        dropAreaText={fileName}
                        showLoader={false}
                        acceptedFiles={[
                          '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
                        ]}
                      />
                    </Grid>
                  </Grid>
                  :
                  <Grid container spacing={4} p={2} className={classes.container} style={{ marginBottom: "20px" }}>
                    <Grid item sm={12} style={{ textAlign: "center" }}>
                      {progressMesage}
                    </Grid>
                    <Grid item sm={12}>
                      <Loader loading={carregandoArquivo} />
                    </Grid>
                  </Grid>
                }
              </Grid>
              {carregandoArquivo === false ?
                <Grid container spacing={4} p={2} className={classes.container} justifyContent="center">
                  <Grid item sm={3}>
                    <Botao
                      type="button"
                      label="Cancelar"
                      onClick={onCancelar}
                      color={Base.GreyDim}
                      className={classes.button}
                    />
                  </Grid>
                  <Grid item sm={3}>
                    <Botao
                      type="submit"
                      label="Importar"
                      color={Base.Mango}
                      className={classes.button}
                    />
                  </Grid>
                </Grid>
                : ""
              }
            </div>
          }
        </Card>
        : ""
      }

    </form>
  );
}

export default PCAT;
