import { makeStyles } from "@mui/styles";
import themes from "themes";

export const useStyles = makeStyles(() => ({
  botao: {
    color: `${themes.color.secondaryText} !important`,
    width: "100%",
    justifyContent: "center",
    justifyItems: "center",

    "&:disabled": {
      backgroundColor: "#9F9F9F",
      opacity: 1,
      color: "#4F4F4F !important",
      borderColor: "#9F9F9F"
    }
  },
  botaoTransparente: {
    color: `${themes.color.secondary} !important`,
    width: "100%",
    justifyContent: "center",
    justifyItems: "center",
    borderColor: `${themes.color.secondary} !important`,
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent !important"
    }
  }
}));
