import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";
// import api from "./api";

const api = () => obterApi(MICROSERVICO.MEASUREMENT);

// Busca Por Unidade
export const buscarPorUnidade = async (unidadeId, dataInicio, dataFim) => {
  return api().get(
    `v1/ConsolidacaoAtual/unidadeconsumidora/${unidadeId}/dataInicio/${dataInicio}/dataFim/${dataFim}`
  );
};
