import { store } from "../../../../global/redux";
import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";
import RetornoEndpointDto from "../../../../global/dto/retornoEndpointDto";
import {
  listarOfficersPorArvoreGerencial,
  buscarPorId as obterColaboradorId
} from "../../../../servicos/colaboradoresServico";
import {
  buscarDistribuidoras,
  buscarDistribuidoraNomeOuCnpj
} from "../../../../servicos/distribuidorasServico";
import { listarSubmercados } from "../../../../servicos/submercadoServico";
import { listarTipoEnergia } from "../../../../servicos/tiposEnergiaServico";
import RadioDto from "../../../../componentes/inputRadioGroup/radioDto";
import { buscarNivelTensao } from "../../../../servicos/unidadesServico";
import { buscarDadosCadastraisPorCnpj } from "../../../../servicos/dadosCadastraisCnpjServico";
import { obterCepCobertura } from "../../../../servicos/correiosServico";
import { digitalizacaoFatura } from "../../../../servicos/digitalizacaoServico";
import { executaCalculeira } from "../../../../servicos/maquinaCalculoServico";
import { criarLeadSalesForceServico } from "../../../../servicos/preLeadSalesforceServico";
import { atualizarExternoIdDeltaProcessamento } from "../../../../servicos/deltaProcessamentoOcrServico";
import {
  obterPorId as obterCotacaoPorId,
  obterCotacao,
  atualizarCotacaoSituacaoVigencia
} from "../../../../servicos/cotacaoInicialServico";
import { criarPLanosCotacaoInicial } from "../../../../servicos/planosServico";
import { obterPorCupom } from "../../../../servicos/leadClienteLuzServico";

export default class CotacaoACLHelper {
  static async digitalizacaoFatura(
    fileBytes,
    fileName,
    externoId,
    colaboradorId,
    empresaSelecionada,
    distribuidoraId,
    endpoint,
    setCarregandoArquivo,
    setError
  ) {
    try {
      setCarregandoArquivo(true);
      const resultado = await digitalizacaoFatura(
        fileBytes,
        fileName,
        externoId,
        colaboradorId,
        empresaSelecionada || "32",
        distribuidoraId,
        endpoint
      );
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Erro ao efetuar upload!");

      return RetornoEndpointDto.Sucesso(
        "Sucesso ao efetuar upload!",
        resultado
      );
    } catch (error) {
      console.info(error);
      setError(true);
      return RetornoEndpointDto.Erro(
        `Erro ao fazer upload do arquivo ${fileName}!`
      );
    } finally {
      setCarregandoArquivo(false);
    }
  }

  static async obterOfficerArvore(empresa, setCarregandoOfficer, setError) {
    try {
      setCarregandoOfficer(true);
      const resultado = await listarOfficersPorArvoreGerencial(empresa ?? 0);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Officers não encontrados!");

      return RetornoEndpointDto.Sucesso(
        "Officers obtido com sucesso!",
        resultado.data
      );
    } catch (error) {
      setError(true);
      return RetornoEndpointDto.Erro(
        `Officers não encontrados empresa ${empresa}!`
      );
    } finally {
      setCarregandoOfficer(false);
    }
  }

  static async obterColaboradorId(colaboradorId) {
    try {
      const resultado = await obterColaboradorId(colaboradorId ?? 0);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Colaborador por id não encontrados!");

      return RetornoEndpointDto.Sucesso(
        "Colaborador por id obtido com sucesso!",
        resultado.data
      );
    } catch (error) {
      if (error?.response?.status === 403) {
        return RetornoEndpointDto.Erro("403");
      }

      return RetornoEndpointDto.Erro(
        `Não foi  encontrado colaborador id ${colaboradorId}`
      );
    }
  }

  static async obterDistribuidora(setCarregandoDistribuidora, setError) {
    try {
      setCarregandoDistribuidora(true);
      const resultado = await buscarDistribuidoras();
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Distribuidora não encontrados!");

      return RetornoEndpointDto.Sucesso(
        "Distribuidora obtido com sucesso!",
        resultado.data
      );
    } catch (error) {
      setError(true);
      return RetornoEndpointDto.Erro("Distribuidora não encontrados!");
    } finally {
      setCarregandoDistribuidora(false);
    }
  }

  static async obterSubmercados(setCarregandoSubMercado, setError) {
    try {
      setCarregandoSubMercado(true);
      const resultado = await listarSubmercados();
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("SubMercado não encontrados!");

      return RetornoEndpointDto.Sucesso(
        "SubMercado obtido com sucesso!",
        resultado.data
      );
    } catch (error) {
      setError(true);
      return RetornoEndpointDto.Erro("SubMercado não encontrados!");
    } finally {
      setCarregandoSubMercado(false);
    }
  }

  static async obterTipoEnergia(setCarregandoTipoEnergia, setError) {
    try {
      setCarregandoTipoEnergia(true);
      const resultado = await listarTipoEnergia();
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Tipo de energia não encontrados!");

      return RetornoEndpointDto.Sucesso(
        "Tipo de energia obtido com sucesso!",
        resultado.data
      );
    } catch (error) {
      setError(true);
      return RetornoEndpointDto.Erro("Tipo de energia não encontrados!");
    } finally {
      setCarregandoTipoEnergia(false);
    }
  }

  static obterTradicionalVarejista() {
    return [new RadioDto("Varejista", "VR"), new RadioDto("Tradicional", "TR")];
  }

  static obterOpcoesSimNao() {
    return [new RadioDto("Sim", "S"), new RadioDto("Não", "N")];
  }

  static async obterNivelTensao(setCarregandoNivelTensao, setError) {
    try {
      setCarregandoNivelTensao(true);
      const resultado = await buscarNivelTensao();
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Níveis de tensão não encontrados!");

      return RetornoEndpointDto.Sucesso(
        "Níveis de tensão obtido com sucesso!",
        resultado.data
      );
    } catch (error) {
      setError(true);
      return RetornoEndpointDto.Erro("Níveis de tensão não encontrados!");
    } finally {
      setCarregandoNivelTensao(false);
    }
  }

  static async buscarDadosCadastraisPorCnpj(setCarregandoNomeFantasia, cnpj) {
    try {
      setCarregandoNomeFantasia(true);
      const resultado = await buscarDadosCadastraisPorCnpj(cnpj);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Cnpj não encontrados!");

      return RetornoEndpointDto.Sucesso(
        "Cnpj obtido com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Cnpj não encontrados!");
    } finally {
      setCarregandoNomeFantasia(false);
    }
  }

  static async obterCepCobertura(
    cepCobertura,
    setCarregandoDistribuidora,
    setError
  ) {
    try {
      setCarregandoDistribuidora(true);
      const resultado = await obterCepCobertura(cepCobertura);
      if (resultado.status === 204) return RetornoEndpointDto.Erro("204");

      return RetornoEndpointDto.Sucesso(
        "Obter cobertura única com sucesso!",
        resultado.data
      );
    } catch (error) {
      setError(true);
      return RetornoEndpointDto.Erro("Obter cobertura única não encontrados!");
    } finally {
      setCarregandoDistribuidora(false);
    }
  }

  static async obterDistribuidoraCnpjOuNome(cnpj, setError) {
    try {
      const resultado = await buscarDistribuidoraNomeOuCnpj(cnpj);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro(
          "Obter distribuídora única não encontrados!"
        );

      return RetornoEndpointDto.Sucesso(
        "Obter distribuídora única com sucesso!",
        resultado.data
      );
    } catch (error) {
      setError(true);
      return RetornoEndpointDto.Erro(
        "Obter distribuídora única não encontrados!"
      );
    }
  }

  static async executaCalculeira(dadosCotacao, isEdicao, id, setLoading) {
    try {
      setLoading(true);
      const resultado = await executaCalculeira(dadosCotacao, isEdicao, id);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Cálculos não encontrados!");

      return RetornoEndpointDto.Sucesso(
        "Cálculos realizao com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Cálculos não encontrados!");
    } finally {
      setLoading(false);
    }
  }

  static async criarLeadSalesForceServico(params, setLoading, id) {
    try {
      setLoading(true);
      const resultado = await criarLeadSalesForceServico(params, id);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Lead sales force não encontrados!");

      return RetornoEndpointDto.Sucesso(
        "Lead sales force criado com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Lead sales force não foi criado!");
    } finally {
      setLoading(false);
    }
  }

  static async atualizarExternoIdDeltaProcessamento(params, setLoading) {
    try {
      setLoading(true);
      const resultado = await atualizarExternoIdDeltaProcessamento(params);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro(
          "Lead sales forces id atualizado não encontrados!"
        );

      return RetornoEndpointDto.Sucesso(
        "Lead sales forces id atualizado com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Erro ao atualizar lead sales forces id luz"
      );
    } finally {
      setLoading(false);
    }
  }

  static async obterCotacaoPorId(id, setLoading, setError) {
    try {
      setLoading(true);
      const resultado = await obterCotacaoPorId(id);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Cotação não encontrados!");

      return RetornoEndpointDto.Sucesso(
        "Cotação obtida com sucesso!",
        resultado.data
      );
    } catch (error) {
      setError(true);
      return RetornoEndpointDto.Erro("Erro ao obter cotação!");
    } finally {
      setLoading(false);
    }
  }

  static async obterCotacao(model, setLoading) {
    try {
      setLoading(true);
      const resultado = await obterCotacao(model);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("CotacaoEncontrada");
      return RetornoEndpointDto.Sucesso(
        "Cotação obtido com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Erro ao obter cotação!");
    } finally {
      setLoading(false);
    }
  }

  static async criarPlanosCotacaoInicial(body, setLoading) {
    try {
      setLoading(true);
      const resultado = await criarPLanosCotacaoInicial(body);

      if (resultado.status === 204)
        return RetornoEndpointDto.Erro(
          "Planos Cotação Inicial não encontrada!"
        );

      return RetornoEndpointDto.Sucesso(
        "Planos Cotação Inicial salva com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Não foi possivel salvar planos cotação");
    } finally {
      setLoading(false);
    }
  }

  static async atualizarCotacaoSituacaoVigencia(id, setLoading) {
    try {
      setLoading(true);
      const resultado = await atualizarCotacaoSituacaoVigencia(id);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Cotação atualizada não encontrados!");

      return RetornoEndpointDto.Sucesso(
        "Cotação atualizada com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Erro ao atualizar cotação");
    } finally {
      setLoading(false);
    }
  }

  static async obterPorCupom(cupom, setCarregandoCupom) {
    try {
      setCarregandoCupom(true);
      const resultado = await obterPorCupom(cupom);
      if (resultado.status === 204) return RetornoEndpointDto.Erro("204");
      return RetornoEndpointDto.Sucesso("Cupom válido", resultado.data);
    } catch (error) {
      return RetornoEndpointDto.Erro("Cupom não válido!");
    } finally {
      setCarregandoCupom(false);
    }
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }

  static exibirSucesso(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem
      })
    );
  }

  static exibirNaoEncontrado(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "warning",
        mensagem
      })
    );
  }
}
