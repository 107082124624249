import { makeStyles } from "@mui/styles";
import styled from "styled-components";

import theme from "themes";

// Backgrounds
import bgConsolidacao from "../../../../assets/home/bg-consolidacao.png";
import bgConsumoAtual from "../../../../assets/home/bg-consumo-atual.png";
import bgConsumoDiario from "../../../../assets/home/bg-consumo-diario.png";
import bgConsumoHorario from "../../../../assets/home/bg-consumo-horario.png";
import bgConsumoMensal from "../../../../assets/home/bg-consumo-mensal.png";

export const ConsumoAtual = styled.div`
  align-items: center;
  background: url(${bgConsumoAtual}) center center no-repeat;
  background-size: cover;
  cursor: pointer;
  display: flex;
  height: 445px;
  justify-items: center;
  margin-bottom: 30px;
  text-decoration: none;
  width: 100%;
  &:hover {
    text-decoration: none;
  }
  @media (max-width: 1366px) {
    height: 290px;
    width: 100%;
  }
  @media (max-width: 1024px) {
    height: 290px;
    width: 100%;
  }
  @media (max-width: 600px) {
    height: 120px;
    width: 97%;
    width: 100%;
  }
`;

export const ConsumoHorario = styled.div`
  align-items: center;
  background: url(${bgConsumoHorario}) center center no-repeat;
  background-size: cover;
  cursor: pointer;
  display: flex;
  height: 445px;
  justify-items: center;
  margin-bottom: 30px;
  text-decoration: none;
  width: 100%;
  &:hover {
    text-decoration: none;
  }
  @media (max-width: 1366px) {
    height: 290px;
    width: 100%;
  }
  @media (max-width: 1024px) {
    height: 290px;
    width: 100%;
  }
  @media (max-width: 600px) {
    height: 120px;
    width: 97%;
    width: 100%;
  }
`;

export const ConsumoDiario = styled.div`
  align-items: center;
  background: url(${bgConsumoDiario}) center center no-repeat;
  background-size: cover;
  cursor: pointer;
  display: flex;
  height: 445px;
  justify-items: center;
  margin-bottom: 30px;
  text-decoration: none;
  width: 100%;
  &:hover {
    text-decoration: none;
  }
  @media (max-width: 1366px) {
    height: 290px;
    width: 100%;
  }
  @media (max-width: 1024px) {
    height: 290px;
    width: 100%;
  }
  @media (max-width: 600px) {
    height: 120px;
    width: 97%;
    width: 100%;
  }
`;

export const ConsumoMensal = styled.div`
  align-items: center;
  background: url(${bgConsumoMensal}) center center no-repeat;
  background-size: cover;
  cursor: pointer;
  display: flex;
  height: 445px;
  justify-items: center;
  margin-bottom: 30px;
  text-decoration: none;
  width: 100%;
  &:hover {
    text-decoration: none;
  }
  @media (max-width: 1366px) {
    height: 290px;
    width: 100%;
  }
  @media (max-width: 1024px) {
    height: 290px;
    width: 100%;
  }
  @media (max-width: 600px) {
    height: 120px;
    width: 97%;
    width: 100%;
  }
`;

export const Consolidacao = styled.div`
  align-items: center;
  background: url(${bgConsolidacao}) center center no-repeat;
  background-size: cover;
  cursor: pointer;
  display: flex;
  height: 445px;
  justify-items: center;
  margin-bottom: 30px;
  text-decoration: none;
  width: 100%;
  &:hover {
    text-decoration: none;
  }
  @media (max-width: 1366px) {
    height: 290px;
    width: 100%;
  }
  @media (max-width: 1024px) {
    height: 290px;
    width: 100%;
  }
  @media (max-width: 600px) {
    height: 120px;
    width: 97%;
    width: 100%;
  }
`;

export const Texto = styled.div`
  text-align: center;
  color: ${theme.color.textoBarra};
  font-size: 28px;
  height: 80px;
  margin: 0 auto;
  padding-top: 15px;
  width: 288px;
`;
export const useStyles = makeStyles(() => ({
  barraSuperior: {
    fontSize: "18px",
    fontStyle: "italic",
    height: "42px",
    textAlign: "center",
    color: `${theme.color.textoBarra}`,
    width: "100%"
  },
  arrowBack: {
    color: `${theme.color.textoBarra}`,
    position: "absolute",
    left: 0,
    paddingLeft: 15
  },
  marginTopContainer: {
    width: "100%",
    height: "auto",
    marginTop: "25%"
  }
}));
