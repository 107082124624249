/* eslint-disable react/prop-types */
import { Card } from "@mui/material";
import UploadDropAreaMultiplo from "componentes/uploadMultiplo";
import React, { useState } from "react";
import { useStyles } from "./style";

function UploadCard({
  titulo,
  subtitulo,
  tituloUpload,
  onAddArquivoUpload,
  onDeleteArquivoUpload,
  onClickDownload,
  arquivos,
  tipo,
  limite,
  renderizaComponente,
  somenteLeitura
}) {
  const classes = useStyles();
  const [carregandoUpload, setCarregandoUpload] = useState(0);

  const controlarPorcentagem = () => {
    setCarregandoUpload(0);
    let porcentagem = 0;

    const intervalo = setInterval(() => {
      if (porcentagem >= 100) {
        clearInterval(intervalo);
        return;
      }

      porcentagem += 25;
      setCarregandoUpload(porcentagem);
    }, 150);
  };

  // eslint-disable-next-line no-shadow
  const onAdd = (arquivos) => {
    // eslint-disable-next-line react/prop-types
    if (!arquivos || arquivos.length === 0) return;

    controlarPorcentagem();

    onAddArquivoUpload(arquivos, tipo);
  };

  return (
    <Card className={classes.cardUpload}>
      <h3 className={classes.tituloCard}>{titulo}</h3>
      <h4 className={classes.subTituloCard}>{subtitulo}</h4>
      <UploadDropAreaMultiplo
        // eslint-disable-next-line react/jsx-boolean-value
        imageOrPdfValidate={true}
        // eslint-disable-next-line no-shadow
        onAdd={(arquivos) => onAdd(arquivos)}
        onDropRejected={onDeleteArquivoUpload}
        progressValue={carregandoUpload}
        acceptedFiles={["image/*", "application/pdf"]}
        dropAreaText={tituloUpload}
        filesLimit={limite ?? 1}
        disabled={somenteLeitura}
        initialFiles={arquivos}
        renderizaComponente={renderizaComponente}
        onPreviewChipClick={onClickDownload}
        showPreviews
      />
    </Card>
  );
}

export default UploadCard;
