import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const api = () => obterApi(MICROSERVICO.MEASUREMENT);

export const listarDistribuidoras = async (
  ordemSelecionada,
  filtroSelecionado,
  paginaAtual = 0
) => {
  const urlParams = `?&pagina=${paginaAtual}${
    ordemSelecionada?.length ? `&sortOrder=${ordemSelecionada}` : ""
  }${filtroSelecionado?.length ? `&filter=${filtroSelecionado}` : ""}`;
  return api().get(`v1/distribuidoras${urlParams}&tamanhoPaginacao=20`);
};

export const listarDistribuidorasSemPaginacao = async (
  ordemSelecionada,
  filtroSelecionado,
  paginaAtual = 0
) => {
  const urlParams = `?&pagina=${paginaAtual}${
    ordemSelecionada?.length ? `&sortOrder=${ordemSelecionada}` : ""
  }${filtroSelecionado?.length ? `&filter=${filtroSelecionado}` : ""}`;
  return api().get(`v1/distribuidoras${urlParams}`);
};

export const BuscarPorCnpjOuNomeFantasia = async (dado) => {
  const urlParams = `?searchString=${dado}`;
  return api().get(`v1/distribuidoras${urlParams}`);
};

export const buscarPorId = async (id) => {
  return api().get(`v1/distribuidoras/${id}`);
};

export const buscarDistribuidoraNomeOuCnpj = async (nomeOuCnpj) => {
  return api().get(`v1/distribuidoras/getbynomeoucnpj/${nomeOuCnpj}`);
};

export const buscarDistribuidoras = async () => {
  return api().get("v1/distribuidoras/sem-paginacao");
};

export const obterListaReduzidaDistribuidoraGD = async () => {
  return api().get("v1/distribuidoras/lista-reduzida?gdApp=true");
};

export const deletarDistribuidora = async (id, ativo) => {
  return api().patch(`v1/distribuidoras/${id}/habilita/${ativo}`);
};

export const salvarDistribuidora = async (id, dados) => {
  let metodo = "post";
  let url = "v1/distribuidoras/";
  if (id > 0) {
    metodo = "put";
    url = `${url}${id}`;
  }
  return api()[metodo](url, dados);
};

export const listarOrganizarPor = () => {
  const ordernacoes = [
    {
      key: "0",
      label: "Alfabética"
    },
    {
      key: "3",
      label: "Recentes"
    }
  ];

  return ordernacoes;
};

export const listarFiltrarPor = () => {
  const filtros = [
    {
      key: true,
      label: "Ativo"
    },
    {
      key: false,
      label: "Inativo"
    }
  ];

  return filtros;
};

export const obterDistribuidoras = async (filtro) => {
  const filtroQueryString = new URLSearchParams(filtro).toString();
  return api().get(`v1/distribuidoras?${filtroQueryString}`);
};
