import React, { useState, useEffect, useCallback } from 'react';
import { Grid } from '@mui/material';
import DataTable from "react-data-table-component";
import { useStyles, customStyles } from './style';
import Coluna from 'componentes/tabelaPaginada/colunas/coluna';
import { store } from 'global/redux';
import { alertaExibir } from 'global/redux/modulos/alertas/actions';
import { buscarVigenciasBandeiras } from '../../../servicos/bandeirasTarifarias';

const BandeiraTarifariaHistorica = () => {
  const [data] = useState(window.moment().format("YYYY-MM-DD"));
  const [historica, setHistorica] = useState([]);
  const classes = useStyles();

  const obterBandeiras = async (abortController) => {
    try {
      const lista = await buscarVigenciasBandeiras(data, {
        signal: abortController.signal
      });

      if (lista?.status === 200 && lista?.data) {
        const response = lista?.data.vigenciaHistorica;
        const organizado = [];
        response.map((item) => organizado.push({
          ...item,
          dataInicial: window.moment(item.dtVigencia).format("DD-MM-YYYY"),
          verde: item.valorVerde.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 3 }),
          amarelo: item.valorAmarelo.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 3 }),
          vermelho1: item.valorVermelho1.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 3 }),
          vermelho2: item.valorVermelho2.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 3 })
        }));
        setHistorica(organizado);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  const colunas = [
    new Coluna("dataInicial", "Data"),
    new Coluna("verde", "Verde"),
    new Coluna("amarelo", "Amarela"),
    new Coluna("vermelho1", "Vermelha 1"),
    new Coluna("vermelho2", "Vermelha 2"),
  ];

  useEffect(() => {
    const abortController = new AbortController();
    obterBandeiras(abortController);
    return () => {
      abortController.abort();
    }
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={12} className="mt-5">
          <div style={{ display: "flex", height: "100%", width: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              <DataTable
                noHeader
                title="Valores Históricos"
                columns={colunas}
                data={historica}
                theme="DeltaEnergia"
                customStyles={customStyles}
                noDataComponent="Nenhum registro encontrado"
              />
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container p={2} spacing={4} className={classes.container}>
        <Grid item sm={12} style={{ color: 'white' }}>
          * Observação: Valores em R$ para cada 100 kWh
        </Grid>
      </Grid>
    </>
  )
}

export default BandeiraTarifariaHistorica;