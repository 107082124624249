import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const api = () => obterApi(MICROSERVICO.MANAGEMENT);

export const listarOrganizarPor = () => {
  const ordernacoes = [
    {
      key: "AT",
      label: "Aberta"
    },
    {
      key: "FE",
      label: "Fechada"
    },
    {
      key: "IN",
      label: "Expirada"
    },
    {
      key: "CA",
      label: "Cancelada"
    }
  ];

  return ordernacoes;
};

export const ordenacaoPorColuna = () => {
  const sortOrder = [
    {
      key: "0",
      asc: "0",
      desc: "1",
      label: "Nome"
    },
    {
      key: "4",
      asc: "4",
      desc: "5",
      label: "Data"
    },
    {
      key: "6",
      asc: "6",
      desc: "7",
      label: "Validade"
    }
  ];

  return sortOrder;
};

export const listarFiltrarClientePor = () => {
  const filtros = [
    {
      key: "PF",
      label: "PF"
    },
    {
      key: "PJ",
      label: "PJ"
    }
  ];

  return filtros;
};

export const listarPlanos = async () => {
  return api().get("v1/tipoplanocotacao");
};

export const listarPaginado = async (
  filtro,
  permissaoAlterarFiltrarOfficerDelta
) => {
  const filtroQueryString = new URLSearchParams(filtro).toString();

  const interfaceExists = permissaoAlterarFiltrarOfficerDelta
    ? "GetAllPaginadoDelta"
    : "GetAllPaginado";

  return api().get(`v1/CotacaoGD/${interfaceExists}?${filtroQueryString}`);
};

export const cotacaoProposta = async (id, produtoId) => {
  return api().get(`/v1/CotacaoGD/${id}/proposta/${produtoId}`);
};

export const listarPaginadoSemFatura = async (filtro) => {
  const filtroQueryString = new URLSearchParams(filtro).toString();
  return api().get(`v1/CotacaoGDSemFatura/GetAllPaginado?${filtroQueryString}`);
};

export const liberaPropostaContrato = async (id, body) => {
  return api().patch(`v1/CotacaoGD/AprovaContrato/${id}`, body);
};
