const statusValidarCotacao = {
  Aberto: "AB",
  Validado: "VA",
  Ajustado: "AJ",
  Rejeitada: "RE",
  Cancelada: "CA",
  Atrasado: "AT"
};

export default statusValidarCotacao;
