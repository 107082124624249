/* eslint-disable no-else-return */
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useForm, useWatch } from "react-hook-form";
import shortid from "shortid";
import { Grid } from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import { Base } from "componentes/cores";
import Loader from "componentes/loader";
import ModalConfirmacao from "componentes/modalConfirmacao";
import MaterialInputTexto from "componentes/inputTexto/materialInput";
import BotaoFiltroOrdenacao from "componentes/botaoFiltroOrdenar";
import { useSelector } from "react-redux"
import RotasDTO from "../../../rotas/rotasUrlDto";
import { useStyles } from "./style";
import ItemListaExpansivel from "./itemListaExpansivel";
import { getProjecoesTarifarias, listarFiltrarPor, excluirProjecao } from "../../../servicos/projecaoTarifaria";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import ModalInformacao from "componentes/modalVerificacao";

const ListaDistribuidoras = () => {
  const classes = useStyles();
  const history = useHistory();
  const usuarioGlobal = useSelector((state) => state.usuario);

  const { register, control } = useForm({
    reValidateMode: "onSubmit"
  });

  const onClickEditar = (id) => {
    history.push(`${RotasDTO.ProjecaoTarifariaVisualizarDetalhes}/${id}`);
  }

  const [projecoes, setProjecoes] = useState([]);
  const [listaProjecoes, setListaProjecoes] = useState([]);
  const [carregandoDados, setCarregandoDados] = useState(false);
  const [filtroSelecionado, setFiltroSelecionado] = useState(null);

  const [showModalExclusao, setShowModalExclusao] = useState(false);
  const [projecaoExclusao, setProjecaoExclusao] = useState({});
  const [modalInfo, setModalInfo] = useState(false);
  
  const textoBusca = useWatch({
    control,
    name: "textoBusca",
    defaultValue: ""
  });

  const stringBusca = useMemo(() => {
    return textoBusca;
  }, [textoBusca]);

  const obterProjecoes = useCallback(async () => {
    try {
      setCarregandoDados(true);
      const lista = await getProjecoesTarifarias();

      if (lista.status === 200 && lista.data) {
        setProjecoes(lista.data); 
        setListaProjecoes(lista.data);            
      }
      setCarregandoDados(false);  
    } catch (error) {
      console.info(error);
      setCarregandoDados(false);
    }
  }, []);

  function filterByTitulo(obj) {
    if (obj.titulo != null && obj.titulo.toUpperCase().includes(textoBusca.toUpperCase())) {   
      return true;
    } else {
      return false;
    }
  }
  
  function filterByDistribuidora(obj) {
    if (obj.distribuidora != null && obj.distribuidora.toUpperCase().includes(textoBusca.toUpperCase())) {   
      return true;
    } else {
      return false;
    }
  }

  function filterByStatus(obj) {
    if (obj.status != null && obj.status.toUpperCase().includes(textoBusca.toUpperCase())) {   
      return true;
    } else {
      return false;
    }
  }

  const handleFiltrarProjecoes = () =>{

    let dadosFiltrados = [];
    
    if(listaProjecoes != null && listaProjecoes.length > 0){  
      
      dadosFiltrados = listaProjecoes;
     
      if(stringBusca !== undefined && stringBusca.length > 0){           
        if((filtroSelecionado === null || filtroSelecionado === undefined)){
          dadosFiltrados = dadosFiltrados.filter(filterByTitulo);
        }      
      }
      if (filtroSelecionado === 0){
        let projecoesPorDistribuidora = dadosFiltrados?.filter(filterByDistribuidora);
        dadosFiltrados = projecoesPorDistribuidora;
      } 
      else if (filtroSelecionado === 1){
        let projecoesPorStatus = dadosFiltrados?.filter(filterByStatus);
        dadosFiltrados = projecoesPorStatus;
      } 
      
      setProjecoes(dadosFiltrados);

    } 
  }

  const handlePeriodo = (de, ate) => {
    const data = `de ${de} até ${ate}`;
    return data;
  }
 
  const handleClickFiltroSelecionado = (event) => {
    const val = filtroSelecionado === event ? null : event;
    setFiltroSelecionado(val);
  };

  const handleClickAtivarAgora = async () => {
    handleFiltrarProjecoes();
  };

  const onClickExcluir = async (projecao) => {
    if(projecao.statusProjecao === "CONCLUIDA") {
      setModalInfo(true);
      return;
    }

    setProjecaoExclusao(projecao);
    setShowModalExclusao(true);
  }

  const handleExclusao = async (idProjecaoExclusao) => {
    const response = await excluirProjecao(idProjecaoExclusao, usuarioGlobal.usuario.id, usuarioGlobal.usuario.nome);

    if (response.status === 200)
    {
      store.dispatch(
        alertaExibir({
          tipo: "success",
          mensagem: "Projeção excluída com sucesso."
        })
      );
    }
    else
    {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: response.data
        })
      );

    }
    setShowModalExclusao(false);
    setProjecaoExclusao({});
    obterProjecoes(null);
  }

  useEffect(() => {
    if (!projecoes.length) obterProjecoes(null);
  }, [projecoes.length,obterProjecoes]);

  useEffect(() => {
    setTimeout(() => {
      handleFiltrarProjecoes();
    }, 100);
  }, [stringBusca,handleFiltrarProjecoes]);

  return (
    <>
      <ModalInformacao
        titulo={"Validação da exclusão"}
        exibir={modalInfo}
        mensagem={"Não é possível excluir uma projeção concluída"}
        onClose={() => {
          setModalInfo(false);
        }}
        showBotao={false}
      />

      <ModalConfirmacao
        item={showModalExclusao}
        onCancelar={() => {
          setShowModalExclusao(false);
          setProjecaoExclusao({});
        }}
        onConfirmar={() => handleExclusao(projecaoExclusao.idProjecaoTarifaria)}
        mensagem={
          <div>    
            <h5>Deseja concluir a exclusão?</h5>
            <p>Projecao a ser excluida "{projecaoExclusao.titulo}".</p>
          </div>
        }
      />
      <Loader loading={carregandoDados} className="w-auto">
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={9}>
            <MaterialInputTexto
              type="text"
              id="textoBusca"
              name="textoBusca"
              label="Buscar"
              renderIconShowHide
              searchAdornment
              defaultValue={textoBusca ?? ""}
              ref={register}
              className={classes.search}
              permiteValorBranco
            />
          </Grid>
          <Grid item xs={3} className={classes.itemFiltro}>
            <BotaoFiltroOrdenacao
              type="button"
              color={Base.OffWhite}
              background="transparent"
              label="Filtrar / Ordenar"
              icon={<TuneIcon />}
              className={classes.buttonFiltro}
              ordenadorUm="Ordenador"
              ordenadorDois="Filtrar"
              ordemSelecionada=""
              filtros={listarFiltrarPor()}
              filtrosSelecionados={filtroSelecionado}
              onClickFiltro={handleClickFiltroSelecionado}
              onClickAtivarAgora={handleClickAtivarAgora}
            />
          </Grid>

          {projecoes.length > 0 && projecoes.map((projecao) => {
            return (
              <>
                {projecao.statusProjecao !== "EXCLUIDA" &&
                  <ItemListaExpansivel
                    key={shortid.generate()}
                    label={projecao?.titulo}
                    color={projecao.statusProjecao === "CONCLUIDA" ? Base.YellowMustard : Base.White}
                    edicao={!!projecao?.idProjecaoTarifaria}
                    onClickEditar={() => onClickEditar(projecao?.idProjecaoTarifaria)}
                    exclusao={!!projecao?.idProjecaoTarifaria}
                    onClickExcluir={() => onClickExcluir(projecao)}
                  >
                    <div>    
                      <Grid spacing={1}>
                        <Grid container spacing={1}>  
                          <Grid item sm={4}>
                            <strong>Status: </strong>  
                          </Grid>          
                          <Grid item sm={8}>
                            {projecao?.tituloProjecao} 
                          </Grid>                    
                        </Grid>
                        <Grid container spacing={1}>  
                          <Grid item sm={4}>
                            <strong>Distribuidora: </strong>  
                          </Grid>          
                          <Grid item sm={8}>
                            {projecao?.distribuidora} 
                          </Grid>                    
                        </Grid> 
                        <Grid container spacing={1}>  
                          <Grid item sm={4}>
                            <strong>Projeção de: </strong>  
                          </Grid>          
                          <Grid item sm={8}>
                            {handlePeriodo(projecao?.anoInicial, projecao?.anoFinal)}
                          </Grid>                    
                        </Grid> 
                        <Grid container spacing={1}>  
                          <Grid item sm={4}>
                            <strong>Execução: </strong>  
                          </Grid>          
                          <Grid item sm={8}>
                            {projecao?.status} 
                          </Grid>                    
                        </Grid>  
                        <Grid container spacing={1}>  
                          <Grid item sm={4}>
                            <strong>Cenario: </strong>  
                          </Grid>          
                          <Grid item sm={8}>
                            {projecao?.nomeCenario} 
                          </Grid>                    
                        </Grid>  
                        <Grid container spacing={1}>  
                          <Grid item sm={4}>
                            <strong>Usuário: </strong>  
                          </Grid>          
                          <Grid item sm={8}>
                            {projecao?.nomeUsuario} 
                          </Grid>                    
                        </Grid>   
                      </Grid>                        
                    </div>
                  </ItemListaExpansivel>
                }
              </>
            )
          })}
        </Grid>
      </Loader>
    </>
  )
}

export default ListaDistribuidoras;