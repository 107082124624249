/* eslint-disable import/no-unresolved */
import MICROSERVICO from "global/enumeradores/microServico";
import obterApi from "servicos/api";
import { saveAs } from "file-saver";

const api = () => obterApi(MICROSERVICO.PROJECAOTARIFARIA);

export const getTempoEstimado = async () => {
  return api().get("v1/ProjecaoTarifaria/getmediamilissegundo");
}

export const getAllDistribuidoras = async () => {
    const metodo = "get";
    const url = "v1/ParametroDistribuidora/getalljoin?StatusProjecaoTarifaria=true"
    return api()[metodo](url);
}

export const getImportsPCATDistribuidora = async (IdDistribuidora) => {
    const result = await api().get(`v1/ImportPCAT/getLatestImportsByIdDistribuidora?IdDistribuidora=${IdDistribuidora}`);
    return result;
}


export const getImportsSPARTADistribuidora = async (IdDistribuidora) => {
    return api().get(`v1/ImportSPARTA/getLatestImportsByIdDistribuidora?IdDistribuidora=${IdDistribuidora}`);
};

export const obterCenarios = async () => {
    return api().get("v1/Cenario");
}

export const orquestrar = async (data, idUsuario, nomeUsuario) => {
    return api().post(`v1/ProjecaoTarifaria/orquestrar?IdUsuario=${idUsuario}&NomeUsuario=${nomeUsuario}`, data);
}

export const orquestrargrupo = async (data, idUsuario, nomeUsuario, idgrupo) => {
  return api().post(`v1/ProjecaoTarifaria/orquestrar?IdUsuario=${idUsuario}&NomeUsuario=${nomeUsuario}&executarapartirdafalha=false&idprojecaotarifaria=&idgrupoprojecoes=${idgrupo}`, data);
}

export const getProjecoesTarifarias = async () => {
    const response = await api().get("v1/ProjecaoTarifaria/getall");
    return response;
}
 
export const listarFiltrarPor = () => {
  const filtros = [
    {
      key: "1",
      label: "Status"
    },
  ];

  return filtros;
};

export const getDetalhesById = async (id) => {
  return api().get(`v1/ProjecaoTarifaria/detailsbyid?idProjecaoTarifaria=${id}`);
}

export const getDetalhesSucesso = async (id, etapa) => {
  return api().get(`v1/ProjecaoTarifaria/detailsresult?idProjecaoTarifaria=${id}&codigoProjecaoEtapa=${encodeURIComponent(etapa)}`);
}

export const getDetalhesFalha = async (id, etapa) => {
  return api().get(`v1/ProjecaoTarifaria/detailsfault?idProjecaoTarifaria=${id}&codigoProjecaoEtapa=${encodeURIComponent(etapa)}`);
}

export const getDetalhesMercadoFaturado = async (id) => {
  return api().get(`v1/ProjecaoTarifaria/detailsmercadofaturado?idProjecaoTarifaria=${id}`)
}

export const getDetalhesTarifa = async (id) => {
  return api().get(`v1/ProjecaoTarifaria/detailstarifa?idProjecaoTarifaria=${id}`)  
}

export const getExcelResultadoProjecao = async (IdProjecaoTarifaria, nomeArquivo) => {
 await api().get(`v1/ExportarExcel/exportarplanilha/projecaotarifaria?idprojecaotarifaria=${IdProjecaoTarifaria}`,
  {
    headers:
            {
                'Content-Disposition': "attachment;",
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer'
  }).then((response) => {
    const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, nomeArquivo);
  });
};

export const atualizarTituloProjecao = async(IdProjecaoTarifaria,novoTitulo) => {
  return api().put(`v1/ProjecaoTarifaria/atualizatitulo?idProjecaoTarifaria=${IdProjecaoTarifaria}&novotitulo=${novoTitulo}`);
};

export const reprocessaProjecao = async(idprojecaotarifaria, idUsuario, nomeUsuario, executarapartirdafalha) => {
  return api().post(`v1/ProjecaoTarifaria/reprocessaprojecao?idprojecaotarifaria=${idprojecaotarifaria}&idUsuario=${idUsuario}&nomeUsuario=${nomeUsuario}&executarapartirdafalha=${executarapartirdafalha}`);
};

export const reprocessaProjecaoGrupo = async(idprojecaotarifaria, idUsuario, nomeUsuario, executarapartirdafalha, idgrupo) => {
  return api().post(`v1/ProjecaoTarifaria/reprocessaprojecao?idprojecaotarifaria=${idprojecaotarifaria}&idUsuario=${idUsuario}&nomeUsuario=${nomeUsuario}&executarapartirdafalha=${executarapartirdafalha}&idgrupoprojecoes=${idgrupo}`);
};

export const changeStatusProjecao = async (idProjecao, idUsuario, nomeUsuario, statusProjecao) => {
  return api().put(`v1/ProjecaoTarifaria/atualizarstatus?idProjecaoTarifaria=${idProjecao}&idUsuario=${idUsuario}&nomeUsuario=${nomeUsuario}&statusProjecao=${statusProjecao}`)
}

export const excluirProjecao = async (idProjecao, idUsuario, nomeUsuario) => {
  const response = await api().delete(`v1/ProjecaoTarifaria/delete?idProjecaoTarifaria=${idProjecao}&idUsuario=${idUsuario}&nomeUsuario=${nomeUsuario}`);
  return response;
}

export const listarStatus = async () => {
  return api().get(`v1/ProjecaoTarifaria/getstatusprojecao`);
}
