export default class ListagemValidarCotacaoDto {
  constructor(
    id,
    nomeCliente,
    cnpj,
    distribuidora,
    nomeColaborador,
    dataSimulacao
  ) {
    this.id = id;
    this.nomeCliente = nomeCliente;
    this.cnpj = cnpj;
    this.distribuidora = distribuidora;
    this.nomeColaborador = nomeColaborador;
    this.dataSimulacao = dataSimulacao;
  }
}
