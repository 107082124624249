/* eslint-disable radix */
/* eslint-disable no-use-before-define */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useCallback, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { Grid, Card, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { EditOutlined } from "@mui/icons-material";
import DataTable from "react-data-table-component";
import Coluna from "componentes/tabelaPaginada/colunas/coluna";
import ColunaBotao from "componentes/tabelaPaginada/colunas/colunaBotao";
import RotasDTO from '../../../../rotas/rotasUrlDto';
import MaterialInputTexto from "componentes/inputTexto/materialInput";

import { customStyles, conditionalRowStyles } from "../../style";
import Loader from "componentes/loader";
import Botao from "componentes/botao";

import BotaoRetornarListagem from 'componentes/botaoRetornarListagem';

import { obterRecortePorCodigo, salvarRecorte, obterRecorteItemsPorCodigoInsumoRecorte } from "../../../../servicos/projecaoInsumos";
import { useStyles } from "./style";
import { Base}  from "componentes/cores";

const AlteraRecorte = () => {
  const { codigoInsumoRecorte } = useParams();
  const history = useHistory();
  const classes = useStyles();

  const { handleSubmit } = useForm({
    reValidateMode: "onSubmit",
  });

  const onClickEditar = (codigoRecorteItem) => {
    history.push(`${RotasDTO.ProjecaoInsumosRecortesAlterarItem}/${encodeURIComponent(codigoInsumoRecorte)}/${encodeURIComponent(codigoRecorteItem)}`);
  }

  const [recorte, setRecorte] = useState();
  const [nome, setNome] = useState();
  const [guia, setGuia] = useState();
  const [refSupEsquerda, setRefSupEsquerda] = useState();
  const [refInfDireita, setRefInfDireita] = useState();
  const [itensRecortes, setItensRecortes] = useState();
  const [carregandoDados, setCarregandoDados] = useState(false);

  const handleObterRecorte = useCallback(async () => {
    try {
      setCarregandoDados(true);
      const response = await obterRecortePorCodigo(codigoInsumoRecorte);
      if (response.status === 200 && response.data) {
        setRecorte(response.data);
        setNome(response.data?.nome);
        setGuia(response.data?.guia);
        setRefSupEsquerda(response.data?.refSupEsquerda);
        setRefInfDireita(response.data?.refInfDireita);
      }
      setCarregandoDados(false);
    } catch (error) {
      setCarregandoDados(false);
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  }, []);

  useEffect(() => {
    if (codigoInsumoRecorte) handleObterRecorte();
  }, [codigoInsumoRecorte, handleObterRecorte]);

  const obterListaRecorteItens = useCallback(async () => {
    try {
      const response = await obterRecorteItemsPorCodigoInsumoRecorte(codigoInsumoRecorte);
      if (response.status === 200 && response.data) {
        const lista = response?.data;
        const listaOrganizada = [];
        if (lista) {
          lista.map((item) => listaOrganizada.push({
            ...item,
            id: item.codigo,
            obrigatorioText: item.obrigatorio ? "Sim " : "Não"
          }));
        }
        setItensRecortes(listaOrganizada);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  }, []);

  useEffect(() => {
    if (codigoInsumoRecorte) obterListaRecorteItens();
  }, [codigoInsumoRecorte, obterListaRecorteItens]);

  const enviarFormulario = async (rec) => {
    try {
      const salvou = await salvarRecorte(rec);
      if (salvou?.status === 200 || salvou?.status === 204) {
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: "Recorte Atualizado com sucesso"
          })
        );

      }
      else {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "Não foi possível atualizar o Recorte"
          })
        );
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: error
        })
      );
      console.info(error);
    }
  }

  const aoEnviarFormulario = (dados) => {
    if (nome == null || nome == undefined || nome == "") {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Informe o Nome do Recorte"
        })
      );
    }
    else if (guia == null || guia == undefined || guia == "") {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Informe a Guia"
        })
      );
    }
    else if (refSupEsquerda == null || refSupEsquerda == undefined || refSupEsquerda == "") {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Informe o Canto Superior Esquerdo"
        })
      );
    }
    else if (refInfDireita == null || refInfDireita == undefined || refInfDireita == "") {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Informe o Canto Superior Direito"
        })
      );
    }
    else {
      const recortenew = {
        codigo: codigoInsumoRecorte,
        nome: nome,
        guia: guia,
        refSupEsquerda: refSupEsquerda,
        refInfDireita: refInfDireita
      }
      enviarFormulario(recortenew);
    }
  }

  const colunas = [
    new Coluna("item", "Item", true, "400px", "400px"),
    new Coluna("obrigatorioText", "Obrigatório", false),
    new Coluna("deslocaColunas", "Deslocar Colunas", false),
    new Coluna("deslocaLinhas", "Deslocar Linhas", false),
    new ColunaBotao(
      "editar",
      "Editar",
      onClickEditar,
      <EditOutlined style={{ color: Base.White }} />,
      false,
      "70px",
      "70px",
      true
    )
  ];

  return <>
    <Loader loading={carregandoDados} className="w-auto">
      <form className="needs-validation" onSubmit={handleSubmit(aoEnviarFormulario)}>
        <Card className={classes.container}>
          <Grid container p={2} spacing={4} className={classes.container} justifyContent="flex-end">
            <Grid item sm={11}>
              Editar Recorte
            </Grid>
            <Grid item sm={1}>
              <BotaoRetornarListagem
                justify="flex-end"
                voltarPagina={true}
              />
            </Grid>
          </Grid>
          <Grid container p={2} spacing={3} className={classes.container}>
            <Grid item xs={12} sm={12} md={3}>
              <MaterialInputTexto
                fullWidth
                type="text"
                id="nome"
                name="nome"
                label="Recorte"
                renderIconShowHide={false}
                onBlur={e => setNome(e.target.value)}
                defaultValue={recorte?.nome !== "" ? recorte?.nome : ""}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <MaterialInputTexto
                fullWidth
                type="text"
                id="guia"
                name="guia"
                label="Guia"
                renderIconShowHide={false}
                onBlur={e => setGuia(e.target.value)}
                defaultValue={recorte?.guia !== "" ? recorte?.guia : ""}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <MaterialInputTexto
                fullWidth
                type="text"
                id="cantoSuperiorEsquerdo"
                name="cantoSuperiorEsquerdo"
                label="Canto Superior Esquerdo"
                renderIconShowHide={false}
                onBlur={e => setRefSupEsquerda(e.target.value)}
                defaultValue={recorte?.refSupEsquerda !== "" ? recorte?.refSupEsquerda : ""}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <MaterialInputTexto
                fullWidth
                type="text"
                id="cantoInferiorDireito"
                name="cantoSInferiorDireito"
                label="Canto Inferior Direito"
                renderIconShowHide={false}
                onBlur={e => setRefInfDireita(e.target.value)}
                defaultValue={recorte?.refInfDireita !== "" ? recorte?.refInfDireita : ""}
              />
            </Grid>
          </Grid>
          <Grid container p={2} spacing={3} className={classes.container}>
            <Grid item sm={3}>
              <Botao type="submit" label="Atualizar" className={classes.button} />
            </Grid>
          </Grid>
        </Card>
        <Grid container mt={6} className={classes.container}>
          <Grid container>
            {itensRecortes?.length > 0 ?
              <DataTable
                columns={colunas}
                data={itensRecortes}
                theme="DeltaEnergia"
                keyField="codigo"
                customStyles={customStyles}
                conditionalRowStyles={conditionalRowStyles}
                noDataComponent="Nenhum registro encontrado"
                noHeader
              />
              :
              null
            }
          </Grid>
        </Grid>
      </form>
    </Loader>
  </>;
}

export default AlteraRecorte;