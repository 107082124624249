import MICROSERVICO from "global/enumeradores/microServico";
import obterApi from "servicos/api";

const api = () => obterApi(MICROSERVICO.PROJECAOTARIFARIA);

export const importarTermicasComValidacao = async (arquivo, idUsuario, nomeUsuario) => {
  const formData = new FormData();
  formData.append("file", arquivo.file);

  const metodo = "post";
  const url = `v1/ImportGeracaoTermica/importarComValidacao?idUsuario=${idUsuario}&nomeUsuario=${nomeUsuario}`;
  return api()[metodo](url, formData);
};

export const importarTermicasSemValidacao = async (arquivo, idUsuario, nomeUsuario) => {
  const formData = new FormData();
  formData.append("file", arquivo.file);

  const metodo = "post";
  const url = `v1/ImportGeracaoTermica/importar?idUsuario=${idUsuario}&nomeUsuario=${nomeUsuario}`;
  return api()[metodo](url, formData);
};

export const obterPorTermica = async (termica) => {
  return api().get(`v1/ImportGeracaoTermica/obterportermica?termica=${termica}`);
}

export const obterTermicas = async () => {
  return api().get('v1/ImportGeracaoTermica/visualizar');
};

