import React from "react";
import moment from "moment";
import PropTypes from "prop-types";

// Styles
import { TabelaModal } from "../style";

const ModalHistoricoContato = ({ listaControleComunicacao }) => {
  const formatarData = (data) => {
    return moment(data).isValid() ? moment(data).format("DD/MM/yyyy") : "-";
  };

  return (
    <TabelaModal>
      <thead>
        <TabelaModal.Tr container borderBottom>
          <TabelaModal.ThLeft xs={6}>
            <div>
              <b>Data</b>
            </div>
          </TabelaModal.ThLeft>
          <TabelaModal.Th xs={6}>
            <div>
              <b>Tipo de Comunicação</b>
            </div>
          </TabelaModal.Th>
          <TabelaModal.Th xs={6}>
            <div>
              <b>Meio de contato</b>
            </div>
          </TabelaModal.Th>
          <TabelaModal.Th xs={6}>
            <div>
              <b>Link para salesforce</b>
            </div>
          </TabelaModal.Th>
        </TabelaModal.Tr>
      </thead>
      <tbody>
        {listaControleComunicacao?.map((item, a) => (
          <TabelaModal.Tr container key={a}>
            <TabelaModal.TdLeft xs={6}>
              <div>{formatarData(item?.modificadoPelaUltimaVez)}</div>
            </TabelaModal.TdLeft>
            <TabelaModal.Td xs={6}>
              <div>{`${item?.assunto} - ${item?.submotivo}`}</div>
            </TabelaModal.Td>
            <TabelaModal.Td xs={6}>
              <div>{item?.origin}</div>
            </TabelaModal.Td>
            <TabelaModal.Td xs={6}>
              <div>{item?.link}</div>
            </TabelaModal.Td>
          </TabelaModal.Tr>
        ))}
      </tbody>
    </TabelaModal>
  );
};

ModalHistoricoContato.propTypes = {
  listaControleComunicacao: PropTypes.oneOfType([PropTypes.any])
};

ModalHistoricoContato.defaultProps = {
  listaControleComunicacao: []
};

export default ModalHistoricoContato;
