import { makeStyles } from "@mui/styles";
import styled from "styled-components";
import { createTheme } from "@mui/material/styles";
import { Base } from "../../../componentes/cores";
import theme from "../../../themes";

const th = createTheme();

export const useStyles = makeStyles(() => ({
  container: {
    margin: "0 0 24px",
    width: "100%"
  },
  search: {
    "& label.Mui-focused": {
      color: Base.OffWhite
    },
    "& .MuiInput-underline:after": {
      borderColor: Base.OffWhite
    },
    "& .MuiFormLabel-root": {
      color: Base.OffWhite
    },
    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-input": {
        color: Base.OffWhite
      },
      "& .MuiInputAdornment-root": {
        color: Base.BlackBastille
      },
      "& fieldset": {
        borderColor: Base.OffWhite
      },
      "&:hover fieldset": {
        borderColor: Base.OffWhite
      },
      "&.Mui-focused fieldset": {
        borderColor: Base.OffWhite
      }
    },
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px"
    }
  },
  select: {
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px"
    },
    "& label.Mui-focused": {
      color: Base.OffWhite
    },
    "& .MuiInput-underline:after": {
      borderColor: Base.OffWhite
    },
    "& .MuiFormLabel-root": {
      color: Base.OffWhite
    },
    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-input": {
        color: Base.OffWhite
      },
      "& .MuiInputAdornment-root": {
        color: Base.OffWhite
      },
      "& fieldset": {
        borderColor: Base.OffWhite
      },
      "&:hover fieldset": {
        borderColor: Base.OffWhite
      },
      "&.Mui-focused fieldset": {
        borderColor: Base.OffWhite
      }
    },
    "& .MuiSelect-icon": {
      color: Base.White
    }
  },
  itemFiltro: {
    display: "flex",
    justifyContent: "flex-end",
    [th.breakpoints.down("md")]: {
      justifyCcontent: "normal",
      width: "100% !important",
      display: "inline"
    }
  },
  buttonFiltro: {
    fontSize: "16px",
    maxWidth: "100%",
    fontWeight: "700",
    marginTop: "5px"
  },
  tabela: {
    "& .MuiButton-root": {
      minWidth: "0px",
      "& .MuiSvgIcon-root": {
        fontSize: "20px"
      }
    }
  },
  linhaTooltip: {
    cursor: "default",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  fundoCelulaMobile: {
    backgroundColor: "#282E3D",
    marginBottom: "23px"
  },
  botao: {
    color: `${theme.color.secondaryText} !important`,
    fontSize: "42px"
  }
}));

export const Icone = styled.img`
  margin-left: 2px;
  max-width: 18px;
  object-fit: cover;
`;
