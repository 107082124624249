import { makeStyles } from "@mui/styles";
import styled from "styled-components";

export const useStyles = makeStyles(() => ({
  zeroacem: {
    width: "100%"
  },
  zero: {
    width: "50%",
    color: "white",
    display: "inline",
    float: "left",
    marginTop: 10
  },
  cem: {
    width: "50%",
    color: "white",
    display: "inline",
    float: "left",
    marginTop: 10
  },
  reactSpeedWidth: {
    width: "90%",
    marginTop: "-50px",
    height: 150
  },
  h3Avanco: {
    color: "white",
    fontSize: 22,
    marginBottom: 15
  },
  h2Porcentagem: {
    color: "#FFCC00",
    fontSize: 20,
    marginBottom: 10,
    fontWeight: "bold"
  },
  h2PorcentagemFinanceiro: {
    color: "#83929b",
    fontSize: 20,
    marginBottom: 10,
    fontWeight: "bold"
  },
  titulosCarregando: {
    borderWidth: 1,
    borderColor: "white",
    padding: 8,
    borderRadius: 10,
    fontSize: 16,
    color: "#FFCC00",
    borderStyle: "solid",
    textAlign: "center",
    marginTop: 60,
    fontWeight: "bold"
  },
  areaHistorico: {
    width: "100%",
    textAlign: "right",
    marginTop: "-30px",
    marginLeft: "-10px",
    cursor: "pointer"
  },
  legenda1: {
    color: "#2D2D2C",
    backgroundColor: "#FFCC00",
    position: "absolute",
    bottom: 0,
    fontSize: 12,
    padding: 6,
    borderTopRightRadius: 10,
    fontWeight: 500
  },
  legenda2: {
    color: "white",
    backgroundColor: "#4A494A",
    top: 0,
    fontSize: 12,
    padding: 6,
    borderTopRightRadius: 10,
    fontWeight: 500,
    display: "inline"
  },
  boxMaxWidth: {
    maxWidth: 474
  },
  tituloOperacao: {
    borderWidth: 1,
    borderColor: "white",
    padding: 8,
    borderRadius: 10,
    fontSize: 19,
    color: "#FFCC00",
    borderStyle: "solid",
    textAlign: "center",
    marginBottom: 29,
    fontWeight: "bold"
  },
  subTitlo: {
    color: "#FFCC00",
    fontWeight: "bold",
    fontSize: 16
  },
  areaNumeros: {
    marginTop: 80,
    color: "white",
    fontSize: 13
  },
  areaNumeros2: {
    marginTop: 60,
    color: "white !important",
    fontSize: 12
  },
  textoBranco: {
    color: "white !important",
    fontSize: 13
  },
  bordaBranca: {
    borderWidth: 1,
    borderColor: "white",
    padding: 8,
    borderRadius: 10,
    borderStyle: "solid"
  },
  bgSlide: {
    width: "100%",
    height: 250,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover"
  },
  iconeConcluido: {
    color: "#239966",
    marginLeft: 10
  }
}));

export const BG = styled.div`
  width: 100%;
  height: 255px;
  background: url(${(props) => props.foto}) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`;
