import { makeStyles } from "@mui/styles";
import themes from "themes";
import { Base } from "componentes/cores";

export const useStyles = makeStyles(() => ({
  containerDropZone: {
    marginLeft: 15,
    "& .MuiTypography-h5": {
      fontSize: "14px",
      margin: 0
    },
    "& .MuiDropzoneArea-root": {
      cursor: "default",
      alignItems: "center",
      backgroundColor: "transparent",
      borderRadius: themes.borda.input,
      borderColor: Base.White,
      borderWidth: "1px",
      borderStyle: "solid",
      height: "auto",
      padding: "0 10px",
      width: "100%",
      textAlign: "center",
      minHeight: "auto",
      marginBottom: "0px"
    },
    "& .MuiDropzoneArea-textContainer": {
      height: 44,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      flexDirection: "row-reverse"
    },
    "& .MuiDropzoneArea-icon": {
      color: `${themes.color.buttonColor} !important`,
      width: 31,
      heigh: 31,
      marginRight: "5px"
    },
    "& .MuiDropzoneArea-text": {
      color: `${themes.color.buttonColor} !important`,
      "&:hover": {
        color: Base.buttonColor,
        cursor: "pointer"
      }
    }
  }
}));
