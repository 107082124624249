import { makeStyles } from "@mui/styles";
import theme from "themes";

export const useStyles = makeStyles(() => ({
  container: {
    margin: "0 0 16px",
    width: "100%",
    "& .MuiInputBase-root": {
      height: "46px"
    },
    "& .MuiFormLabel-root": {
      lineHeight: "1"
    }
  },
  cardCadastro: {
    backgroundColor: theme.color.bgColorCardCadastro
  }
}));
