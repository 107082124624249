import React from "react";
import PropTypes from "prop-types";

// Componentes
import { Box } from "@mui/material";

// Styles
import { useStyles } from "./style";
import IconeLista from "../../../../assets/jornadaCliente/icone-lista.png";
import IconeLuz from "../../../../assets/jornadaCliente/icone-luz.png";

const Pagina1 = ({ titulo, conteudoEsquerda, conteudoDireita }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.boxEsquerda}>
        <Box className={classes.boxTexto}>
          <img
            className={classes.iconeLista}
            src={IconeLista}
            alt="imagem lista"
          />
          {titulo}
          <Box>{conteudoEsquerda}</Box>
        </Box>
      </Box>
      <Box className={classes.boxDireita}>{conteudoDireita}</Box>
      <img className={classes.iconeLuz} src={IconeLuz} alt="imagem luz" />
    </Box>
  );
};

Pagina1.propTypes = {
  titulo: PropTypes.node,
  conteudoEsquerda: PropTypes.node,
  conteudoDireita: PropTypes.node
};

Pagina1.defaultProps = {
  titulo: <></>,
  conteudoEsquerda: <></>,
  conteudoDireita: PropTypes.node
};

export default Pagina1;
