import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { MdPerson } from "react-icons/md";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Grid } from "@mui/material";

import { store } from "../../../global/redux";
import {
  deslogarUsuario,
  selecionarCliente,
  selecionarEmpresa,
  selecionarUnidade,
  salvarEmpresaDefault,
  salvarClienteDefault,
  salvarUnidadeDefault
} from "../../../global/redux/modulos/usuario/actions";

import CardLogin from "../../../componentes/cardLogin";
import Loader from "../../../componentes/loader";
import Botao from "../../../componentes/botao";
import Alerta from "../../../componentes/alerta";

import { Wrapper, Titulo, Mensagem, ButtonLink, Avatar } from "../style";
import Contas from "./contas";
import { buscarPorId } from "../../../servicos/clientesServico";

import NavBarLuz from "../componente/NavBarLuz";

const SelecaoConta = () => {
  const history = useHistory();
  const { register, handleSubmit } = useForm();

  const [carregandoConta, setCarregandoConta] = useState(false);
  const [mensagemAlerta, setMensagemAlerta] = useState("");

  const usuario = useSelector((state) => state.usuario);
  const { contas, contaSelecionada } = usuario;

  useEffect(() => {
    if (!usuario?.token) history.push("/login");
  }, [usuario, history]);

  useEffect(() => {
    if (contas?.length && contaSelecionada) history.push("/");
  }, [contas, contaSelecionada, history]);

  const enviarFormulario = async () => {
    setCarregandoConta(false);
    history.push("/");
  };

  const buscarClientePorId = async (id) => {
    try {
      const retorno = await buscarPorId(id);

      if (!retorno.status === 200) return null;

      return retorno.data;
    } catch (error) {
      return null;
    }
  };

  const setarEmpresaClienteUnidade = (
    empresaGrupoDeltaId,
    clienteId,
    unidadeId
  ) => {
    store.dispatch(selecionarEmpresa(empresaGrupoDeltaId));
    store.dispatch(selecionarCliente(clienteId));
    store.dispatch(selecionarUnidade(unidadeId));

    store.dispatch(salvarEmpresaDefault(empresaGrupoDeltaId));
    store.dispatch(salvarClienteDefault(clienteId));
    store.dispatch(salvarUnidadeDefault(unidadeId));
  };

  const obterSetarFiltro = async (unidadeConsumidora) => {
    const cliente = await buscarClientePorId(unidadeConsumidora.clienteId);

    if (cliente)
      setarEmpresaClienteUnidade(
        cliente.empresaGrupoDeltaId,
        cliente.id,
        unidadeConsumidora.id
      );
  };

  const aoEnviarFormulario = async (dados) => {
    if (!dados?.conta) {
      setMensagemAlerta({
        tipo: "warning",
        mensagem: "É necessário selecionar uma conta para continuar!"
      });
      return;
    }

    const unidadeConsumidora =
      contas?.find((conta) => conta?.clienteId === Number(dados?.conta)) || {};

    setCarregandoConta(true);
    store.dispatch(selecionarUnidade(dados?.conta?.id));

    if (unidadeConsumidora) obterSetarFiltro(unidadeConsumidora);

    enviarFormulario(dados);
  };

  const retornarAoLogin = () => {
    store.dispatch(deslogarUsuario());
    window.location.reload(true);
  };

  return (
    <>
      <NavBarLuz />
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "90vh", backgroundColor: "#332053" }}
      >
        <Grid cols={12} className="d-flex align-content-center flex-wrap">
          <CardLogin height={533} width={468} className="mx-auto">
            <Wrapper className="mx-auto">
              <Avatar className="rounded mx-auto mt-5 mb-n4">
                <MdPerson />
              </Avatar>
              <Titulo fonte="20px">{usuario?.usuario?.nome ?? "Olá"}</Titulo>
              <Mensagem>
                Você possui mais de uma conta relacionada a esse login, qual
                deseja conectar?
              </Mensagem>
              <form
                className="needs-validation"
                onSubmit={handleSubmit(aoEnviarFormulario)}
              >
                <Loader loading={carregandoConta}>
                  <Contas dados={usuario.contas ?? {}} register={register} />
                  <Botao
                    type="submit"
                    label="Entrar"
                    style={{ marginTop: 24 }}
                  />
                </Loader>
              </form>
              <Mensagem className="mt-2">
                <ButtonLink href="/login" onClick={() => retornarAoLogin()}>
                  Não é você?
                </ButtonLink>
              </Mensagem>
              {mensagemAlerta && (
                <Alerta
                  {...mensagemAlerta}
                  onClose={() => setMensagemAlerta("")}
                />
              )}
            </Wrapper>
          </CardLogin>
        </Grid>
      </Grid>
    </>
  );
};

export default SelecaoConta;
