/* eslint-disable react/prop-types */
import React, { useCallback, useRef } from "react";

import { Grid } from "@mui/material";

import { useHistory } from "react-router-dom";

import Botao from "componentes/botao";
import TabelaPaginada from "componentes/tabelaPaginada";
import Coluna from "componentes/tabelaPaginada/colunas/coluna";
import ColunaComponentePersonalizado from "componentes/tabelaPaginada/colunas/colunaComponentePersonalizado";
import ResultadoPaginadoDto from "componentes/tabelaPaginada/resultadoPaginadoDto";

import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { RotasDTO } from "global/rotas/rotasUrlDto";
import { useStyles } from "./style";

import CategoriaRelatorioHelper from "./helper";

import ColunaMultiplosBotoes from "./colunaMultiplosBotoes";

import enumeradorBotao from "./enumeradores/enumeradorBotao";

import { MudarStatus } from "../../servicos/categoriaRelatorioServico";

const ColunaMarca = ({ parametros }) => {
  return <span>{parametros.possuiMarcaDagua ? "Sim" : "Não"}</span>;
};

const ColunaCliente = ({ parametros }) => {
  return <span>{parametros.serveParaCliente ? "Sim" : "Não"}</span>;
};

const RelatoriosCategorias = () => {
  const history = useHistory();
  const classes = useStyles();
  const refTabela = useRef(null);

  const onClickEditar = (id) => {
    history.push(`${RotasDTO.RelatoriosCategorias}/cadastro/${id}`);
  };

  const onClickMudarStatus = useCallback(async (id, status) => {
    try {
      const mudarStatus = await MudarStatus(id, status);

      // console.log('mudarStatus', mudarStatus);

      if (mudarStatus) {
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: `A categoria foi ${
              status === false ? "inativado" : "ativado"
            } com sucesso!`
          })
        );
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }

    if (refTabela?.current) refTabela.current.obterDadosPaginados();
  }, []);

  const onClickBotao = (tipo, parametros) => {
    switch (tipo) {
      case enumeradorBotao.Edicao:
        onClickEditar(parametros.id);
        break;
      case enumeradorBotao.Status:
        onClickMudarStatus(parametros.id, !parametros.ativo);
        break;
      default:
        break;
    }
  };

  const colunas = [
    new Coluna("nome", "Nome"),
    new ColunaComponentePersonalizado(
      "possuiMarcaDagua",
      "Possui Marca D'agua",
      ColunaMarca
    ),
    new ColunaComponentePersonalizado(
      "serveParaCliente",
      "Serve Para Cliente",
      ColunaCliente
    ),
    new ColunaComponentePersonalizado(
      "opcoes",
      "Editar",
      ColunaMultiplosBotoes,
      onClickBotao,
      false,
      true,
      "15%",
      "15%",
      "pl-2"
    )
  ];

  const obterCategoriasRelatorios = async (parametros) => {
    const resultado = await CategoriaRelatorioHelper.Listar(parametros);

    if (!resultado.sucesso) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: resultado.mensagem
        })
      );
      return new ResultadoPaginadoDto([], 1, 0, 0);
    }

    if (resultado.data.lista?.length === 0 ?? true)
      return new ResultadoPaginadoDto([], 1, 0, 0);

    return new ResultadoPaginadoDto(
      resultado.data.lista,
      resultado.data.paginaAtual,
      resultado.data.totalItens,
      resultado.data.totalPaginas
    );
  };

  const onChangeFiltrosTabela = async (parametros) => {
    return obterCategoriasRelatorios(parametros);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <TabelaPaginada
          className={classes.table}
          ref={refTabela}
          onChangeFiltrosTabela={onChangeFiltrosTabela}
          colunas={colunas}
          paginaAtual={1}
        />
      </Grid>
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={6}>
          <Botao
            type="submit"
            label="Nova Categoria de Relatório"
            className={classes.button}
            onClick={() =>
              history.push(`${RotasDTO.RelatoriosCategorias}/cadastro`)
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RelatoriosCategorias;
