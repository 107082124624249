import { makeStyles } from "@mui/styles";
import styled from "styled-components";
import { createTheme } from "@mui/material/styles";
import theme from "../../..";
import { Base } from "../../../../componentes/cores";

const th = createTheme();

export const useStyles = makeStyles(() => ({
  card: {
    boxShadow: "0px 2px 4px 1px #00000029 !important"
  },
  titulo: {
    color: theme.color.primary,
    fontFamily: theme.font.family,
    fontSize: "24px",
    fontWeight: 700,
    [th.breakpoints.down("sm")]: {
      fontSize: "20px"
    }
  },
  subtitulo: {
    fontSize: "20px",
    color: theme.color.primary
  },
  texto: {
    color: theme.color.primary,
    display: "flex",
    marginBottom: 0
  },
  textoCinza: {
    color: "#9F9F9F",
    display: "flex",
    marginBottom: 5
  }
}));

export const Chip = styled.div`
  width: auto;
  color: ${Base.White};
  background-color: ${(props) => (props?.cor ? props.cor : "#2abf6f")};
  border-radius: 24px;
  font-size: 14px;
  padding: 4px 16px;
  margin-right: 10px;
`;

export const ImagemUnidade = styled.img`
  width: 170px;
  @media (max-width: 1315px) {
    width: 145px;
  }
`;

export const StatusUnidade = styled.button`
  position: absolute;
  width: 64px;
  color: ${Base.White};
  background-color: ${(props) => (props?.cor ? props.cor : "#2abf6f")};
  border-color: transparent;
  border-radius: 24px;
  font-size: 12px;
  padding: 4px 10px;
  top: 82px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 1315px) {
    top: 67px;
    font-size: 12px;
    width: 72px;
  }
`;
