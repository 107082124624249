import React, { useCallback, useEffect, useMemo, useState } from "react";

// Form Hooks
import { useForm, useWatch } from "react-hook-form";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { Grid } from "@mui/material";
import { useHistory } from "react-router-dom";

import MaterialInputTexto from "componentes/inputTexto/materialInput";
import ResultadoPaginadoDto from "componentes/tabelaPaginada/resultadoPaginadoDto";

import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { RotasDTO } from "global/rotas/rotasUrlDto";
import themes from "../../../../themes";
import {
  BotaoCadastro,
  ItemListaExpansivel,
  Loader,
  Paginacao,
  SelectAutocomplete
} from "../../../../componentes";

import Helper from "./helper";
import { useStyles } from "./style";

import {
  RelatoriosList,
  RelatoriosListItem,
  RelatoriosListItemText,
  RelatoriosTitleGroup
} from "./relatorios.styled";

// Principal
const RelatoriosAssociar = () => {
  const history = useHistory();
  const classes = useStyles();

  const [listaAssociacoes, setListaAssociacoes] = useState();

  const [paginaAtual, setPaginaAtual] = useState("1");
  const [totalPaginas, setTotalPaginas] = useState();
  const [clienteIdSelecionado, setClienteSelecionado] = useState("");
  const [clienteLista, setClienteLista] = useState([]);
  const [carregandoClientes, setCarregandoClientes] = useState(false);

  /* #region Eventos */

  const onClickNovoCliente = () => {
    history.push(`${RotasDTO.RelatoriosAssociar}/cadastro`);
  };

  const onClickEditar = (params) => {
    history.push(`${RotasDTO.RelatoriosAssociar}/cadastro/${params.clienteId}`);
  };

  /* #endregion */

  /* #region Métodos */

  const obterClientes = useCallback(async () => {
    try {
      setCarregandoClientes(true);
      const lista = await Helper.obterClientes();

      if (lista?.data?.clientes) {
        setClienteLista(lista?.data?.clientes);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    } finally {
      setCarregandoClientes(false);
    }
  }, []);

  const obterLista = async (parametros) => {
    parametros.pagina = paginaAtual;
    parametros.totalPagina = 20;

    const resultado = await Helper.Listar(parametros);

    setTotalPaginas(resultado.data?.totalPaginas);

    if (!resultado.sucesso) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: resultado.mensagem
        })
      );
      return new ResultadoPaginadoDto([], 1, 0, 0);
    }

    if (resultado.data.lista?.length === 0 ?? true)
      return new ResultadoPaginadoDto([], 1, 0, 0);

    return new ResultadoPaginadoDto(
      resultado.data.lista,
      resultado.data.paginaAtual,
      resultado.data.totalItens,
      resultado.data.totalPaginas
    );
  };

  const { register, control } = useForm({
    reValidateMode: "onSubmit"
  });

  const clienteSelecionado = useMemo(() => {
    return clienteLista.find(
      (item) => Number(item.id) === Number(clienteIdSelecionado)
    )?.nomeFantasia;
  }, [clienteIdSelecionado]);

  // Busca por tipo de relatório
  const txtBuscaTipoRelatorio = useWatch({
    control,
    name: "BuscaTipoRelatorio",
    defaultValue: ""
  });

  const txtParaBuscaTipoRelatorio = useMemo(() => {
    return txtBuscaTipoRelatorio?.length >= 3 ? txtBuscaTipoRelatorio : "";
  }, [txtBuscaTipoRelatorio]);

  const obterListaAssociacoes = async () => {
    const params = {};

    if (clienteSelecionado) params.NomeCliente = clienteSelecionado;
    if (
      txtBuscaTipoRelatorio !== null &&
      txtBuscaTipoRelatorio !== "" &&
      txtBuscaTipoRelatorio?.length >= 3
    )
      params.NomeTipoRelatorio = txtBuscaTipoRelatorio;

    const result = await obterLista(params);

    setListaAssociacoes(result.linhas);
  };

  const onChangePaginacao = (item, pagina) => {
    setPaginaAtual(pagina);
  };

  /* #endregion */

  /* #region Ouvintes */

  useEffect(() => {
    obterListaAssociacoes();
    obterClientes();
  }, []);

  useEffect(() => {
    obterListaAssociacoes();
  }, [clienteIdSelecionado, txtParaBuscaTipoRelatorio, paginaAtual]);

  /* #endregion */

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
        >
          <Grid item lg={6} md={6} sm={12}>
            <Loader loading={carregandoClientes}>
              <SelectAutocomplete
                disableOpenOnFocus
                id="clienteId"
                name="clienteId"
                label="Cliente"
                value={clienteIdSelecionado}
                options={clienteLista}
                placeholder="--Selecione--"
                getOptionLabel={(option) => option?.nomeFantasia ?? ""}
                onChange={(e, item) => setClienteSelecionado(item?.id ?? 0)}
                allowClear
                valueName="nomeFantasia"
                className={classes.search}
              />
            </Loader>
          </Grid>
          <Grid item lg={6} md={6} sm={12}>
            <MaterialInputTexto
              type="text"
              id="BuscaTipoRelatorio"
              name="BuscaTipoRelatorio"
              permiteValorBranco
              defaultValue={txtBuscaTipoRelatorio}
              label="Busca por Tipo de Relatório"
              renderIconShowHide
              searchAdornment
              ref={register}
              className={classes.search}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} className="mt-5 mb-3">
        <BotaoCadastro
          color={themes.color.buttonColor}
          label="Nova Associação"
          onClick={onClickNovoCliente}
        />
      </Grid>
      <Grid item xs={12} marginBottom="10">
        {listaAssociacoes?.map((itemAssociacao, idx) => {
          return (
            <ItemListaExpansivel
              md={12}
              lg={12}
              key={idx}
              edicao
              onClickEditar={() => onClickEditar(itemAssociacao)}
              label={itemAssociacao.clienteNome}
            >
              <RelatoriosTitleGroup>Relatórios associados</RelatoriosTitleGroup>
              <RelatoriosList>
                {itemAssociacao.listaTipoRelatorio.map(
                  // eslint-disable-next-line camelcase
                  (itemTipoRelatorio, idx_t) => {
                    return (
                      // eslint-disable-next-line camelcase
                      <RelatoriosListItem key={idx_t}>
                        <DescriptionOutlinedIcon />
                        <RelatoriosListItemText>
                          {itemTipoRelatorio.nome}
                        </RelatoriosListItemText>
                      </RelatoriosListItem>
                    );
                  }
                )}
              </RelatoriosList>
            </ItemListaExpansivel>
          );
        })}
      </Grid>

      <Grid
        container
        spacing={3}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <div style={{ marginTop: 20 }}>
            <Paginacao
              paginaAtual={paginaAtual}
              totalPaginas={totalPaginas}
              onChangePagina={onChangePaginacao}
            />
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RelatoriosAssociar;
