import { combineReducers } from "redux";

import alertas from "./alertas/reducers";
import usuario from "./usuario/reducers";
import rotas from "./rotas/reducers";
import demo from "./demo/reducers";
import menu from "./menu/reducers";
import workflow from "./workflow/reducers";
import loader from "./loader/reducers";
import notificacoes from "./notificacoes/reducers";
import log from "./log/reducers";

const reducers = combineReducers({
  alertas,
  usuario,
  rotas,
  demo,
  menu,
  workflow,
  loader,
  notificacoes,
  log
});

const rootReducer = (state, action) => reducers(state, action);

export default rootReducer;
