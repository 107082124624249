/* eslint-disable import/no-unresolved */
import moment from "moment";
import React, { useState, useEffect } from "react";
import { Grid, Card, FormControl } from "@mui/material";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { InputTextoData } from "componentes";
import { useStyles, Tabela } from "./style";
import { getVariaveisCDE } from "../../../../servicos/variaveisCDE";
import CloseIcon from "@mui/icons-material/Close";
import RotasDTO  from "../../../../rotas/rotasUrlDto";  
import { useHistory } from "react-router-dom";

const VariaveisCDEVisualizar = () => {
  const classes = useStyles();
  const history = useHistory();

  const onFechar = (id) => {
     history.push(`${RotasDTO.ProjecaoInsumoListar}`);
   }
  const [valores, setValores] = useState([]);
  const [anoInicio, setAnoInicio] = useState(moment());
  const [anoFim, setAnoFim] = useState(moment());

  const obterValores = async (anoInicial, anoFinal) => {
    try {
      const response = await getVariaveisCDE(anoInicial.format("yyyy"), anoFinal.format("yyyy"));
      if (response.status === 200 && response.data) {
        setValores(response?.data);
      } else {
        setValores([]);
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "Não há dados para exibição."
          })
        );
      }
    } catch (exception) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            exception?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  const handleChangeAnoInicio = async (data) => {
    setAnoInicio(moment(data));
    obterValores(moment(data), anoFim);
  }

  const handleChangeAnoFim = async (data) => {
    setAnoFim(moment(data));
    obterValores(anoInicio, moment(data));
  }

  useEffect(() => {
    if (!valores.length) obterValores(anoInicio, anoFim);
  }, []);

  function getValueByUnidade(valor, unidade) {
    switch (unidade) {
      case "%":
        return `${(valor * 100).toFixed(2)}%`
      case "R$":
        return `R$ ${valor.toLocaleString("pt-BR", { currency: "BRL", minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
      case "MWh":
        return `${valor.toFixed(2)} MWh`
      default:
        return "";
    }
  }

  return <>
  <Grid container className={classes.containerTitulo}>
        <Grid item xs={11} className={classes.titulo}>
          Variáveis CDE
        </Grid>
        <Grid item xs={1} >             
          <CloseIcon onClick={onFechar} className={classes.icon}/>
        </Grid>   
      </Grid>
    <Grid container sm={12}>
      <Grid container spacing={4} className={classes.container} justifyContent="flex-end" alignItems="center">
        <Grid item sm={2}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputTextoData
              type="text"
              id="anoInicio"
              name="anoInicio"
              label="Ano Início"
              customValue={anoInicio}
              format="yyyy"
              onChange={(data) => handleChangeAnoInicio(data)}
              minDate={moment().subtract(10, "years").toDate()}
              views={["year"]}
              renderIconShowHide={false}
            />
          </FormControl>
        </Grid>
        <Grid item sm={2} style={{ marginLeft: "15px" }}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputTextoData
              type="text"
              id="anoFinal"
              name="anoFinal"
              label="Ano Fim"
              customValue={anoFim}
              format="yyyy"
              onChange={(data) => handleChangeAnoFim(data)}
              minDate={moment().subtract(10, "years").toDate()}
              views={["year"]}
              renderIconShowHide={false}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
    <Grid container spacing={4} p={2} className={classes.container}>
      <Grid item sm={12}>
        <Card className={classes.container}>

          <Grid container className={classes.container}>
            <Grid item sm={12} style={{ paddingTop: "0px" }}>
              <div className={classes.customPosition}>
                <Tabela>
                  <Tabela.Thead>
                    <Tabela.Tr>
                      <Tabela.Th colSpan="12">VARIÁVEIS CDE</Tabela.Th>
                    </Tabela.Tr>
                    <Tabela.Tr>
                      <Tabela.Th>ANO</Tabela.Th>
                      <Tabela.Th>ITEM</Tabela.Th>
                      <Tabela.Th>VALOR</Tabela.Th>
                    </Tabela.Tr>
                  </Tabela.Thead>
                  <Tabela.Tbody>
                    {valores?.map((item) => (
                      <Tabela.Tr>
                        <Tabela.Td>{item.Ano}</Tabela.Td>
                        <Tabela.Td>{item.Item}</Tabela.Td>
                        <Tabela.Td>{getValueByUnidade(item.Valor, item.Unidade)}</Tabela.Td>
                      </Tabela.Tr>
                    ))}
                  </Tabela.Tbody>
                </Tabela>
              </div>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  </>;
}

export default VariaveisCDEVisualizar;
