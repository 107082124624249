/* eslint-disable import/no-unresolved */
import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useForm, useWatch } from "react-hook-form";
import shortid from "shortid";
import { Grid, Typography } from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import { Base } from "componentes/cores";
import Loader from "componentes/loader";
import moment from "moment";
import ModalConfirmacao from "componentes/modalConfirmacao";
import MaterialInputTexto from "componentes/inputTexto/materialInput";
import BotaoFiltroOrdenacao from "componentes/botaoFiltroOrdenar";
import ItemListaExpansivel from "componentes/itemListaExpansivel";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { obterCenarios, salvarCenario } from "../../../servicos/cenariosSeriesHistoricas";
import { obterFatorComposto } from "../../../servicos/fatorComposto";
import { useStyles } from "./style";
import RotasDTO from "../../../rotas/rotasUrlDto";

const ProjecaoFatorComposto = () => {
  const classes = useStyles();
  const history = useHistory();

  const { register, control } = useForm({
    reValidateMode: "onSubmit"
  });

  const textoBusca = useWatch({
    control,
    name: "textoBusca",
    defaultValue: ""
  });


  const [carregandoCenario, setCarregandoFatorComposto] = useState(false);
  const [fatorComposto, setFatorComposto] = useState([]);

  const handleFatorComposto = async () => {
    try {
      setCarregandoFatorComposto(true);
      const lista = await obterFatorComposto();
      if (lista?.status === 200 && lista?.data) {
        setFatorComposto(lista?.data);
        setCarregandoFatorComposto(false);
      } else {
        setFatorComposto([]);
      }
      setCarregandoFatorComposto(false);
    } catch (error) {
      console.info(error);
    }
  }

  const onClickEditar = (id) => {
    history.push(`${RotasDTO.ProjecaoFatorCompostoEditar}/${id}`);
  }

  const [modalExclusao, setModalExclusao] = useState();

  const onClickExcluir = (item) => {
    setModalExclusao(item);
  };

  const buscarCenarios = useCallback(async () => {
    handleFatorComposto();
  }, []);

  useEffect(() => {
    if (!fatorComposto?.length) handleFatorComposto();
  }, [fatorComposto.length, handleFatorComposto]);

  return (
    <Loader loading={carregandoCenario} className='w-auto'>
      <Grid container spacing={3} alignItems='center'>
        <Grid item xs={9}>
          <MaterialInputTexto
            type='text'
            id='textoBusca'
            name='textoBusca'
            label='Buscar'
            renderIconShowHide
            searchAdornment
            defaultValue={textoBusca ?? ""}
            ref={register}
            className={classes.search}
            permiteValorBranco
          />
        </Grid>
        <Grid item xs={3} className={classes.itemFiltro}>
          <BotaoFiltroOrdenacao
            type='button'
            label='Filtrar / Ordernar'
            color={Base.OffWhite}
            icon={<TuneIcon />}
            className={classes.buttonFiltro}
          />
        </Grid>

        {fatorComposto.length > 0 && fatorComposto.map((fator) => {
          return (
            <ItemListaExpansivel
              key={shortid.generate()}
              label={fator?.Codigo}
              color={Base.RedChestnut}
              edicao
              exclusao={false}
              onClickEditar={() => onClickEditar(fator?.Codigo)}
            >
              <div>
                <Typography>
                  <Grid xs={12}>
                    {`Descrição: ${fator.Descricao}`}
                  </Grid>
                  <Grid xs={12}>
                    {`Última atualização: ${fator.NomeUsuario}, ${moment(fator.AtualizadoEm).format("DD-MM-YYYY")}`}
                  </Grid>
                </Typography>
                {/* <strong>Descrição:</strong>
              {fator.descricao} */}
              </div>
            </ItemListaExpansivel>
          )
        })
        }
      </Grid>
    </Loader>
  )
}

export default ProjecaoFatorComposto;