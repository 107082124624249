export default class FiltroDto {
    constructor(
      linhasPorPagina,
      pagina,
      ordenacao,
      statusId,
      anoReferencia,
      mesReferencia,    
      unidadeConsumidoraId
    ) {
      this.linhasPorPagina = linhasPorPagina;
      this.pagina = pagina;
      this.ordenacao = ordenacao;
      this.statusId = statusId;
      this.anoReferencia = anoReferencia;
      this.mesReferencia = mesReferencia;    
      this.unidadeConsumidoraId = unidadeConsumidoraId;
    }
  }
  