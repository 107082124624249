/* eslint-disable no-continue */
/* eslint-disable consistent-return */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-shadow */
/* eslint-disable prefer-template */
/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect, useCallback } from "react";
import { Grid, FormControl, InputLabel, Select, MenuItem, Tooltip, OutlinedInput,Typography } from "@mui/material";
import { Base } from "componentes/cores";
import { Botao, ModalConfirmacao } from "componentes";
import BotaoOutLine from "componentes/botaoOutline";
import { useForm } from "react-hook-form";
import Loader from "componentes/loader";
import MaterialInputTexto from "componentes/inputTexto/materialInput";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { useSelector } from "react-redux";
import SelectAutocompleteCheckbox from "componentes/selectAutocompleteCheckbox";
import { useHistory, useParams } from "react-router-dom";
import { ModalVerificacao } from "componentes";
import { useStyles } from "./style";
import { buscaDistribuidoraPorGrupo, buscaListaSubgrupos, buscaAnosPorGrupo } from "../../../servicos/grupoProjecoes";
import { getExcelEfeitoConsumidorGrupo } from "../../../servicos/exportacoes";
import RotasDTO from "../../../rotas/rotasUrlDto";
import CloseIcon from "@mui/icons-material/Close";
import BotaoRetornarListagem from "componentes/botaoRetornarListagem";
import ModalInformacao from "componentes/modalVerificacao";


const EfeitoConsumidorGrupo = () => {
    const usuarioGlobal = useSelector((state) => state.usuario);
    const classes = useStyles();
    const {idgrupo, titulogrupo} = useParams();

    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);

    const [distribuidoras, setDistribuidoras] = useState([]);
    const [listaDistribuidorasEditada, setListaDistribuidorasEditada] = useState([]);
    const [distribuidorasSelecionadas, setDistribuidorasSelecionadas] = useState([]);
    const [subgrupos, setSubgrupos] = useState([]);
    const [subgruposSelecionados, setSubgruposSelecionados] = useState([]);
    const [anos, setAnos] = useState([]);
    const [anosSelecionados, setAnosSelecionados] = useState([]);
    const [modalInfo, setModalInfo] = useState(false);
    const [msgFalha, setMsgFalha] = useState();

    const [nomeArquivo, setNomeArquivo] = useState("EfeitoConsumidorGrupo.xlsx");
    const [showModalGerarExcel, setShowModalGerarExcel] = useState(false);

    const handleAnos = useCallback( async () =>{
        try{
            setIsLoading(true);
            const respostaAnos = await buscaAnosPorGrupo(idgrupo);

            if(respostaAnos.status === 200 && respostaAnos.data){
                setIsLoading(false);
                let years = [];
                for(var i = respostaAnos.data.menorAnoInicial; i <= respostaAnos.data.maiorAnoFinal; i++)
                {
                    years.push(i);
                }
                setAnos(years);
              
            }
            else{
                setIsLoading(false);
            }
        }
        catch (error) {
            console.info(error);
            setIsLoading(false);
        }
    });
    
    const handleSubgrupos = useCallback( async () =>{
        try{
            setIsLoading(true);
            const respostaSubgrupos = await buscaListaSubgrupos();

            if(respostaSubgrupos.status === 200 && respostaSubgrupos.data){
                setIsLoading(false);
               let subgruposoriginal = respostaSubgrupos.data.map(listItem => { 
                    return {
                        id: listItem.subgrupo,
                        nome: listItem.subgrupo
                    }
                });            
                let subgruposadded = [{id:'A',nome:'A'},{id:'B',nome:'B'},{id:'A %2B B',nome:'A + B'}];
                subgruposoriginal.unshift(...subgruposadded);
                setSubgrupos(subgruposoriginal);

            }
            else{
                setIsLoading(false);
            }
        }
        catch (error) {
            console.info(error);
            setIsLoading(false);
        }
    });

    const handleDistribuidoras = useCallback( async () => {
        try {
            setIsLoading(true);
            const lista = await buscaDistribuidoraPorGrupo(idgrupo);
            if (lista?.status === 200 && lista?.data) {
                setIsLoading(false);
                setDistribuidoras(lista?.data);
                setListaDistribuidorasEditada(lista?.data.map(listItem => { 
                    return {
                        id: listItem.idDistribuidora,
                        nome: listItem.titulo
                    }
                }));
            } else {
                setDistribuidoras([]);
                setIsLoading(false);
            }
        } catch (error) {
            console.info(error);
            setIsLoading(false);
        }
    })

     const onChangeDistribuidora = async (item) => {
        setDistribuidorasSelecionadas(item);
      }; 

      const onChangeSubgrupo = async (item) => {
          setSubgruposSelecionados(item);
      };

      const OnChangeAnos = (event) => {
        setAnosSelecionados(event.target.value);
      } 

    useEffect(() => {
        if(!subgrupos.length) handleSubgrupos();
    }, [subgrupos?.length]);

    useEffect(() => {
        if (!distribuidoras?.length) handleDistribuidoras();
    }, [distribuidoras?.length]);

    useEffect(() => {
        if(!anos.length) handleAnos();
    }, [anos.length])


    const handleExportExcel =  useCallback (async() => {

        setIsDownloading(true);

        const distribuidorasSemTodos = listaDistribuidorasEditada.filter( ( el ) => {
            return el.nome !== 'Todos';
          } );

        let paramdistribuidoras = [];
        let paramsubgrupos = [];
        let paramanos = [];


        // Prepara parâmetro Distribuidora
        if(distribuidorasSelecionadas.some(d => d.nome === "Todos"))
        {
            paramdistribuidoras = distribuidorasSemTodos.map((item) => {
                return item.id
            }).join();
        }
        else if(distribuidorasSelecionadas.length > 0)
        {
            paramdistribuidoras = distribuidorasSelecionadas.map((item) => {
                return item.id
            }).join();
        }
        else{
            setShowModalGerarExcel(false);
            store.dispatch(
                alertaExibir({
                  tipo: "warning",  
                  mensagem: "Escolha ao menos uma distribuidora para exportação."
                })
              );
        }

        // Prepara parâmetro Subgrupo
        if(subgruposSelecionados.length > 0 && !subgruposSelecionados.some(d=>d.nome === "Todos"))
        {
            paramsubgrupos = subgruposSelecionados.map((item) => {
                return item.id
            }).join();
        }

        // Prepara parâmetro Ano
        if(anosSelecionados.length > 0)
        {
            paramanos = anosSelecionados.map((item)=>{
                return item
            }).join();
        }


        /*
            Se parâmetro distribuidoras foi carregado com sucesso
        */
 
        if(distribuidoras.length > 0)
        {
            try
            {
                const response = await getExcelEfeitoConsumidorGrupo(idgrupo,paramdistribuidoras,
                    paramsubgrupos, paramanos,nomeArquivo);

                setDistribuidorasSelecionadas([]);
                setSubgruposSelecionados([]);
                setAnosSelecionados([]);
                setShowModalGerarExcel(false);

                if(response !== "Sucesso")
                {
                    setMsgFalha(`${response}`);
                    setModalInfo(true);
                }
                else
                {
                    store.dispatch(
                        alertaExibir({
                            tipo: "success",  
                            mensagem: "Exportação concluída com sucesso."
                        })
                    );
                }

            }
            catch(error)
            {
                setShowModalGerarExcel(false);
                setMsgFalha(`Erro não previsto: ${error}`);
                setModalInfo(true);
                setIsDownloading(false);
            }
        }
        setIsDownloading(false);
    });

    return <>
        <ModalInformacao
            titulo={"Exportação não realizada com a seguinte falha:"}
            exibir={modalInfo}
            mensagem={msgFalha}
            onClose={() => {
            setModalInfo(false);
            }}
            showBotao={false}
        />        
        <Loader loading={isLoading} className="w-auto">
            <ModalConfirmacao
            item={showModalGerarExcel}
            onCancelar={() => setShowModalGerarExcel(false)}
            onConfirmar={() => handleExportExcel()}
            className = {classes.container}
            mensagem={
                <pre>
                    <Loader loading={isDownloading} className="w-auto">
                    <strong><p>Gerar planilha excel com o Histórico do Efeito Consumidor e fazer download?</p></strong>
                    </Loader>            
                    <FormControl variant="outlined" >
                        <InputLabel htmlFor="outlined-nome-arquivo-excel">Nome do Arquivo</InputLabel>
                        <Tooltip 
                            placement="top"
                            title={
                                <h6>Nome sugerido. Aceite ou edite antes de confirmar.</h6>
                            }
                        >
                            <OutlinedInput
                                id="outlined-nome-arquivo-excel"
                                type="text"
                                value={nomeArquivo}
                                onChange={(e) => setNomeArquivo(e.target.value)}
                                label="Nome do Arquivo a Gerar"
                            />               
                        </Tooltip>                 
                    </FormControl>
                </pre>
            }
            />          
            <Grid container item spacing={3} alignItems="center">
            <Typography variant = "h5" style={{color: Base.OffWhite, fontSize: 20}}>
                    Exportação de Efeito Consumidor do Grupo de Projeções</Typography>
            </Grid>
            <Grid container spacing={3} alignItems="center">
                <Grid item xs={4}>
                <Typography variant = "h6" style={{color: Base.OffWhite, fontSize: 20}}>
                    Grupo: {titulogrupo}</Typography>
                </Grid>
                <Grid item xs={7}></Grid>
                <Grid item xs={1} className={classes.backButton}>
                        <BotaoRetornarListagem voltarPagina />
                </Grid>

            </Grid>

            <Grid container style={{ paddingLeft: "1.5em", paddingRight: "1.5em" }}>
                <Grid
                    container
                    spacing={3}
                    alignItems="center"
                    className={classes.container}
                    justifyContent="space-between"
                >   
                    <Grid item xs={5}>
                        <SelectAutocompleteCheckbox 
                            id="selectDistribuidoraId"
                            name="distribuidora"
                            options={listaDistribuidorasEditada}
                            label="Distribuidora"
                            style={classes}
                            getOptionLabel={(option) => option.nome || ""}
                            onChange={(e, item) => {
                                onChangeDistribuidora(item);
                            }}
                            value={distribuidorasSelecionadas}
                            allowClear
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <SelectAutocompleteCheckbox 
                            id="selectSubgrupos"
                            name="selectSubgrupos"
                            options={subgrupos}
                            label="Subgrupo"
                            style={classes}
                            getOptionLabel={(option) => option.nome || ""}
                            onChange={(e, item) => {
                                onChangeSubgrupo(item);
                            }}
                            value={subgruposSelecionados}
                            allowClear
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="perid">Período</InputLabel>
                            <Select
                                variant="standard"
                                labelId="perid"
                                id="perid1"
                                value={anosSelecionados}
                                onChange={OnChangeAnos}
                                label="Anos"
                                fullWidth
                                multiple>
                                {anos.map((ano) => (
                                    <MenuItem key={ano} value={ano}>{ano}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container alignItems="left" style={{ paddingTop: "25px" }}>
                    <Grid xs={2} style={{ paddingTop: "25px" }}>
                        <Botao
                            type="button"
                            label="Gerar Excel"
                            color={Base.Mango}
                            className={classes.button}
                            onClick={()=>{setShowModalGerarExcel(true)}}
                            disabled = {distribuidorasSelecionadas.length === 0}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Loader>
    </>;
}


export default EfeitoConsumidorGrupo;
