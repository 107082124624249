import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Icones
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

// Componentes
import { Card, Grid } from "@mui/material";

// Styles
import { useStyles } from "./style";

const CampoTexto = ({
  node,
  nodePai,
  level,
  onEditar,
  onSalvar,
  full,
  nivel
}) => {
  const className = useStyles();

  const idCampoNome = "nomeNomeArvoreGerencial";
  const idCampoDescricao = "descricaoArvoreGerencial";

  const [tipoFuncionalidade, setTipoFuncionalidade] = useState(
    node?.nome ?? undefined
  );
  const [nomeArvoreGerencial, setNomeArvoreGerencial] = useState(
    node?.descricao ?? ""
  );
  const [descricaoFuncionalidade, setDescricaoFuncionalidade] = useState(
    node?.descricao ?? ""
  );

  useEffect(() => {
    if (node?.idTipoFuncionalidade)
      setTipoFuncionalidade(String(node?.idTipoFuncionalidade));
  }, [node]);

  const [errors, setErrors] = useState();

  const existemErrosValidacao = () => {
    let validacoes = false;

    if (!nomeArvoreGerencial) {
      validacoes = {
        ...validacoes,
        nomeArvoreGerencial: { message: "Descrição é obrigatório!" }
      };
    }

    return validacoes;
  };

  const onClickCadastrar = () => {
    const errosValidacao = existemErrosValidacao();

    if (!errosValidacao) {
      onSalvar({
        ...node,
        ...(nodePai && { arvoreGerencialVinculadaId: nodePai.id }),
        descricao: nomeArvoreGerencial,
        nivel
      });
    } else {
      setErrors(errosValidacao);
    }
  };

  const [cssError, setCssError] = useState();

  useEffect(() => {
    if (errors?.nomeArvoreGerencial?.message) {
      setCssError(true);
    }
  }, [errors]);

  return (
    <Card
      className={`${className.form} ${full ? className.margin : ""} rounded-0`}
    >
      <Grid
        container
        spacing={0}
        alignItems="center"
        className={`${className.container}`}
      >
        <Grid
          item
          xl={level ? (tipoFuncionalidade === "5" ? 5 : 8) : 10}
          lg={level ? (tipoFuncionalidade === "5" ? 5 : 8) : 10}
          xs
          className={`h-100 w-100 ${className.gridItem} ${className.itemFull} ${className.divider}`}
        >
          <Grid
            container
            spacing={0}
            alignItems="center"
            className="h-100 position-relative"
          >
            <Grid
              item
              xl={2}
              lg={2}
              xs={2}
              className={`h-100 w-100 ${className.itemLabel} ${
                cssError ? className.errorLabel : ""
              }`}
            >
              <label htmlFor={idCampoNome} className={className.label}>
                Descrição:
              </label>
            </Grid>
            <Grid
              item
              xl={10}
              lg={10}
              xs={10}
              className={`h-100 ${cssError ? className.errorInput : ""}`}
            >
              <input
                id={idCampoNome}
                name="nomeArvoreGerencial"
                defaultValue={nomeArvoreGerencial}
                className={`${className.input}`}
                onChange={(valor) => setNomeArvoreGerencial(valor.target.value)}
                required
                autoComplete="off"
              />
            </Grid>
            {tipoFuncionalidade === "5" ? (
              <Grid
                container
                spacing={0}
                alignItems="center"
                className={`h-100 ${
                  tipoFuncionalidade === "5" ? className.customDescricao : ""
                }`}
              >
                <Grid
                  item
                  xl={1}
                  lg={1}
                  xs={2}
                  className={`h-100 w-100 ${className.itemLabel}`}
                >
                  <label htmlFor={idCampoDescricao} className={className.label}>
                    Ação:
                  </label>
                </Grid>
                <Grid item xl={11} lg={11} xs={10} className="h-100">
                  <input
                    id={idCampoDescricao}
                    name="descricaoFuncionalidade"
                    defaultValue={descricaoFuncionalidade}
                    className={`${className.input} ${className.descricao}`}
                    onChange={(valor) =>
                      setDescricaoFuncionalidade(valor.target.value)
                    }
                    autoComplete="off"
                  />
                </Grid>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
        <Grid
          item
          xl={1}
          lg={1}
          className={`${className.itemClose} ${className.divider}`}
        >
          <CloseIcon
            className={className.buttonClose}
            onClick={() => onEditar()}
          />
        </Grid>
        <Grid item xl={1} lg={1} className={className.itemCadastrar}>
          <Grid item xl={1} lg={1} className={className.itemCadastrar}>
            <CheckIcon
              className={className.buttonCadastrar}
              onClick={() => onClickCadastrar()}
            />
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

CampoTexto.propTypes = {
  node: PropTypes.oneOfType([PropTypes.object]),
  nodePai: PropTypes.oneOfType([PropTypes.object]),
  level: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  onEditar: PropTypes.oneOfType([PropTypes.func]),
  onSalvar: PropTypes.oneOfType([PropTypes.func]),
  full: PropTypes.bool,
  nivel: PropTypes.oneOfType([PropTypes.bool, PropTypes.number])
};

CampoTexto.defaultProps = {
  node: {},
  nodePai: {},
  level: false,
  onEditar: () => {},
  onSalvar: () => {},
  full: false,
  nivel: 0
};

export default CampoTexto;
