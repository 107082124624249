import { makeStyles } from "@mui/styles";
import theme from "../../../../themes";

export const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "transparent",
    width: "100%"
  },
  label: {
    display: "flex",
    alignItems: "center",
    "& p": {
      marginBottom: 0,
      marginRight: 20,
      fontSize: 18
    }
  },
  contador: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    backgroundColor: "transparent",
    "& span": {
      padding: "5px",
      fontSize: "20px"
    }
  },
  botao: {
    padding: "5px",
    background: "transparent",
    color: theme.color.secondary,
    border: "none"
  },
  visivel: {
    visibility: "visible"
  },
  invisivel: {
    visibility: "hidden"
  },
  semSombra: {
    boxShadow: "none !important"
  }
});
