import { makeStyles } from "@mui/styles";
import { Base } from "componentes/cores";
import theme from "../../../../themes";

export const useStyles = makeStyles(() => ({
  containerImage: {
    background:
      "linear-gradient(90deg, rgba(142,53,125,1) 0%, rgba(84,43,121,1) 19%, rgba(57,35,96,1) 56%)",
    width: "1020px",
    height: "746px",
    position: "relative"
  },
  boxTexto: {
    color: Base.White,
    fontSize: "42px",
    fontFamily: `${theme.font.family}  !important`,
    fontWeight: 700,
    marginBottom: 0,
    "& h1": {
      fontSize: "42px",
      marginBottom: 0,
      fontWeight: 700,
      "& span": {
        color: theme.color.secondary
      }
    },
    "& h2": {
      fontSize: "37px",
      marginBottom: 0,
      fontWeight: 700,
      "& span": {
        color: theme.color.secondary
      }
    },
    "& h4": {
      fontSize: "21px",
      fontWeight: 700,
      marginBottom: 0
    }
  },
  iconeLista: {
    position: "absolute",
    left: 0,
    top: 40,
    width: "30px"
  }
}));
