import { Search } from "@mui/icons-material";
import { Box, Grid } from "@mui/material";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { Loader } from "../../../../../componentes";
import { store } from "../../../../../global/redux";
import { alertaExibir } from "../../../../../global/redux/modulos/alertas/actions";
import {
  removeMaskCep,
  removeMaskTelefone
} from "../../../../../servicos/utils";
import CotacaoGDHelper from "../../Etapas/Simular/helper";
import MensagemDefault from "./componentes/mensagemDefault";
import MensagemDesconto from "./componentes/mensagemDesconto";
import MensagemSemDesconto from "./componentes/mensagemSemDesconto";
import SimuladorMatrizDescontoHelper from "./helper";
import { InputCotacoes, SliderCotacoes } from "./style";
import mapeamentoFluxo from "../../../constantes/constanteMapeamentoFluxo";

const SimulacaoSlider = ({
  distribuidoraId,
  tipoClienteSelecionado,
  nome,
  email,
  telefone,
  cep,
  valorDaConta,
  gdApp,
  cupom,
  colaboradorId,
  municipioId
}) => {
  const [value, setValue] = useState("");
  const [valueSlider, setValorSlider] = useState(0);
  const [mensagemSimulacao, setMensagemSimulacao] = useState("");
  const [carregandoMatrizDesconto, setCarregandoMatrizDesconto] = useState(
    false
  );
  const [desconto, setDesconto] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(false);
  const [etapaFluxo, setEtapaFluxo] = useState(false);

  const handleSliderChange = (event, newValue) => {
    setValorSlider(newValue);
    setValue("");
  };

  const handleInputChange = (event) => {
    if (Number.isNaN(Number(event?.target?.value))) return;

    const valor = Number(event?.target?.value);

    if (valor > 15000) {
      setValue(15000);
      setValorSlider(15000);
    } else {
      setValorSlider(valor);
      setValue(event?.target?.value === "" ? "" : Number(event?.target?.value));
    }
  };

  const handleBlur = () => {
    setValue("");
  };

  const mensagemSlider = useMemo(() => {
    switch (mensagemSimulacao) {
      case "comDesconto":
        return <MensagemDesconto desconto={desconto} />;
      case "semDesconto":
        return <MensagemSemDesconto />;
      default:
        return <MensagemDefault />;
    }
  }, [mensagemSimulacao]);

  const criarCotacaoGDSemFatura = async (
    idDoPreLeadNoSalesforce,
    descontoSimulador
  ) => {
    const model = {
      nomeContato: nome,
      email,
      telefone: removeMaskTelefone(telefone),
      cep: removeMaskCep(cep),
      distribuidoraId,
      pessoaFisicaJuridica: tipoClienteSelecionado,
      idDoPreLeadNoSalesforce,
      colaboradorId,
      descontoInicial: descontoSimulador?.desconto5Anos ?? 0
    };

    const {
      mensagem,
      sucesso
    } = await SimuladorMatrizDescontoHelper.criarCotacaoGDSemFatura(
      model,
      setCarregandoMatrizDesconto
    );

    if (!sucesso) {
      SimuladorMatrizDescontoHelper.exibirErro(mensagem);
      setCarregandoMatrizDesconto(false);
      setEtapaFluxo(true);
    }
  };

  const criarLeadSalesForceServicoSimulacao = async (
    descontoSimulador,
    etapa
  ) => {
    const valorSemGD = valorDaConta !== 0 ? valorDaConta : null;
    const model = {
      nome,
      email,
      telefone: removeMaskTelefone(telefone),
      cep: removeMaskCep(cep),
      pessoaFisicaJuridica: tipoClienteSelecionado,
      valorDaConta: valorSemGD ?? valueSlider,
      descontoInicial: descontoSimulador?.desconto5Anos ?? 0,
      multaDistribuidora: false,
      preLeadContratado: false,
      erroLeituraFatura: false,
      consumoAbaixoRange: descontoSimulador?.desconto5Anos === undefined,
      distribuidoraNaoAtendida: !gdApp,
      contratoGerado: false,
      origemDaInformacao: "AreaLogada",
      cupom: cupom ?? null
    };

    if (model.distribuidoraNaoAtendida) {
      etapa = mapeamentoFluxo.distribuidora;
    } else if (etapaFluxo) {
      etapa = mapeamentoFluxo.erroApi1;
    }

    model.etapa = etapa ?? "";

    const {
      data,
      sucesso,
      mensagem
    } = await CotacaoGDHelper.criarLeadSalesForceServico(
      model,
      setCarregandoMatrizDesconto,
      setError
    );
    if (!sucesso) {
      CotacaoGDHelper.exibirErro(mensagem);
      setCarregandoMatrizDesconto(false);
      return;
    }

    criarCotacaoGDSemFatura(
      data?.data?.dados?.idDoPreLeadNoSalesforce,
      descontoSimulador
    );
  };

  const handleMatrizDesconto = async () => {
    if (
      distribuidoraId === "" ||
      distribuidoraId === 0 ||
      valueSlider === 0 ||
      cep === "" ||
      telefone === "" ||
      email === "" ||
      nome === "" ||
      colaboradorId === "" ||
      colaboradorId === 0
    ) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            "Selecione os campos Pessoa Física ou Jurídica, Cep, Distribuidora, Colaborador, Telefone, Email e Nome Completo!"
        })
      );
      return;
    }

    const model = {
      idDistribuidora: distribuidoraId,
      valorSemGD: valueSlider,
      pessoaFisicaJuridica: tipoClienteSelecionado
    };

    const modelOpcional = {
      cosip: null,
      desconto3e5Anos: "N",
      idMunicipio: municipioId ?? ""
    };

    const {
      data,
      sucesso,
      mensagem
    } = await SimuladorMatrizDescontoHelper.obterMatrizDescontoSimulacao(
      model,
      modelOpcional,
      setCarregandoMatrizDesconto
    );

    if (!sucesso) {
      if (mensagem === "semDesconto") {
        setMensagemSimulacao("semDesconto");
        SimuladorMatrizDescontoHelper.exibirErro(
          "Desconto não encontrado para o valor digitado!"
        );
        await criarLeadSalesForceServicoSimulacao(
          data,
          mapeamentoFluxo.semDesconto
        );
        setCarregandoMatrizDesconto(false);
        return;
      }

      setEtapaFluxo(true);
      SimuladorMatrizDescontoHelper.exibirErro(mensagem);
      return;
    }

    if (data?.desconto5Anos > 0) {
      setDesconto(data?.desconto5Anos);
      setMensagemSimulacao("comDesconto");
      await criarLeadSalesForceServicoSimulacao(
        data,
        mapeamentoFluxo.comDesconto
      );
    }
  };

  return (
    <Box px={{ lg: 4 }}>
      <Grid container spacing={2}>
        <Box component={Grid} xs={12}>
          <Loader loading={carregandoMatrizDesconto}>{mensagemSlider}</Loader>
        </Box>
        <Box mb={2} fontSize="20px" fontWeight="700">
          Valor médio da sua fatura por mês
        </Box>
        <Box component={Grid} item xs={9}>
          <Box
            component="span"
            display="flex"
            justifyContent="center"
            fontSize="20px"
            fontWeight="700"
            color="#E851B1"
          >
            {`R$ ${valueSlider}`}
          </Box>
          <Box>
            <SliderCotacoes
              value={typeof valueSlider === "number" ? valueSlider : 0}
              onChange={handleSliderChange}
              aria-labelledby="input-slider"
              max={15000}
              min={0}
            />
          </Box>
        </Box>
        <Box component={Grid} item xs={2} mt="-8px !important">
          <InputCotacoes
            value={value}
            margin="dense"
            variant="standard"
            onChange={handleInputChange}
            onBlur={handleBlur}
            inputProps={{
              step: 1,
              min: 0,
              max: 15000,
              type: "text",
              "aria-labelledby": "input-slider",
              disableUnderline: true
            }}
            placeholder="Digitar Valor"
          />
        </Box>
        <Box
          component={Grid}
          item
          xs={1}
          mt="21px !important"
          onClick={() => handleMatrizDesconto()}
        >
          <Search cursor="pointer" htmlColor="#FA3EB1" />
        </Box>
      </Grid>
    </Box>
  );
};

SimulacaoSlider.propTypes = {
  distribuidoraId: PropTypes.string,
  tipoClienteSelecionado: PropTypes.string,
  nome: PropTypes.string,
  email: PropTypes.string,
  telefone: PropTypes.string,
  cep: PropTypes.string,
  valorDaConta: PropTypes.string,
  gdApp: PropTypes.string,
  cupom: PropTypes.string,
  colaboradorId: PropTypes.string,
  municipioId: PropTypes.string
};
SimulacaoSlider.defaultProps = {
  distribuidoraId: "",
  tipoClienteSelecionado: "",
  nome: "",
  email: "",
  telefone: "",
  cep: "",
  valorDaConta: "",
  gdApp: "",
  cupom: "",
  colaboradorId: "",
  municipioId: ""
};

export default SimulacaoSlider;
