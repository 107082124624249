import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { Botao } from "componentes";
import BotaoOutLine from "componentes/botaoOutline";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useSelector } from "react-redux";
import UploadCustomizado from "../Upload";

// Helper
import operacaoControleHelper from "../../helper";

import { useStyles } from "./style";

const SuspenderCancelamento = (props) => {
  const {
    dados,
    onCancelar,
    setNotificacao,
    setCarregandoOperacao,
    obterCancelamento,
    nomeUnidade
  } = props;
  const classes = useStyles();
  const usuario = useSelector((state) => state.usuario);
  const [carregandoArquivo, setCarregandoArquivo] = useState(false);
  const [arquivo, setArquivo] = useState();

  const onAddUpload = async (valor) => {
    setCarregandoArquivo(true);
    setArquivo(valor[0]);
    setCarregandoArquivo(false);
  };

  const onDropRejected = (valor) => {
    const arquivo = valor[0];

    setNotificacao({
      aberto: true,
      tipo: "warning",
      texto: `Tipo do arquivo ${arquivo.name} não é permitido!`
    });
  };

  const onSuspenderCancelamento = async () => {
    setCarregandoOperacao(true);

    const resultadoSuspender = await operacaoControleHelper.suspenderCancelamento(
      {
        solicitacaoCancelamentoId: dados?.id,
        colaboradorId: dados?.colaboradorId
      },
      setNotificacao
    );

    if (resultadoSuspender?.data || resultadoSuspender?.data === 0) {
      const resultadoValidacaoSuspender = await operacaoControleHelper.validarSuspenderCancelamento(
        { id: dados?.id, arquivo: arquivo?.file },
        setNotificacao
      );

      if (resultadoValidacaoSuspender) {
        const dadosChamado = {
          id: dados?.numeroChamado,
          assunto: `CANCELAMENTO CONTRATO LUZ - ${nomeUnidade}`,
          tipoRegistroId: process.env.REACT_APP_TIPO_REGISTRO_ID,
          unidadeId: dados?.unidadeId,
          ...(dados?.dataPrevistaDevolucao && {
            dataPrevistaDevolucao: dados?.dataPrevistaDevolucao
          }),
          ...(dados?.protocoloDistribuidora && {
            numeroProtocoloDevolucao: dados?.protocoloDistribuidora
          }),
          emailColaborador: usuario?.usuario?.email,
          status: "Suspensao Cancelamento"
        };

        await operacaoControleHelper.atualizarSFIntegracaoEnergiaChamado(
          dadosChamado,
          setNotificacao
        );

        setNotificacao({
          aberto: true,
          tipo: "success",
          texto: "Cancelamento suspenso com sucesso!"
        });

        onCancelar();
        obterCancelamento();
      }
    }

    setCarregandoOperacao(false);
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} mb={2}>
          <div className={classes.dados}>
            <p>
              Anexe um arquivo para documentar a suspensão deste cancelamento.
            </p>
          </div>
        </Grid>
        <Grid item xs={12}>
          <UploadCustomizado
            dropAreaText={arquivo?.file?.name || "Anexar arquivo"}
            loader={carregandoArquivo}
            onAdd={onAddUpload}
            progressValue={0}
            showLoader={false}
            onDropRejected={onDropRejected}
            acceptedFiles={["image/*", "application/pdf"]}
            icon={AttachFileIcon}
          />
        </Grid>
        <Grid item xs={6}>
          <BotaoOutLine
            onClick={() => onCancelar()}
            label="Cancelar"
            className={classes.botaoTransparente}
          />
        </Grid>
        <Grid item xs={6}>
          <Botao
            onClick={() => onSuspenderCancelamento()}
            label="Suspender"
            className={classes.botao}
            disabled={!arquivo}
          />
        </Grid>
      </Grid>
    </>
  );
};

SuspenderCancelamento.propTypes = {
  dados: PropTypes.oneOfType([PropTypes.any]),
  onCancelar: PropTypes.func,
  setNotificacao: PropTypes.func,
  setCarregandoOperacao: PropTypes.func,
  obterCancelamento: PropTypes.func,
  nomeUnidade: PropTypes.string
};

SuspenderCancelamento.defaultProps = {
  dados: null,
  onCancelar: () => {},
  setNotificacao: () => {},
  setCarregandoOperacao: () => {},
  obterCancelamento: () => {},
  nomeUnidade: ""
};

export default SuspenderCancelamento;
