/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
import React, { useEffect, useRef, useState } from "react";

import { Button, Grid } from "@mui/material";

import Coluna from "componentes/tabelaPaginada/colunas/coluna";
import ColunaComponentePersonalizado from "componentes/tabelaPaginada/colunas/colunaComponentePersonalizado";
import ResultadoPaginadoDto from "componentes/tabelaPaginada/resultadoPaginadoDto";

import {
  Loader,
  SelectArredondado,
  TabelaPaginada,
  TabelaPaginadaMobile
} from "componentes";

import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { useSelector } from "react-redux";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { GetApp, OndemandVideo } from "@mui/icons-material";
import usuario from "global/redux/modulos/usuario/reducers";
import { HtmlTooltip } from "paginas/Graficos/ConsumoAtual/style";
// eslint-disable-next-line import/no-extraneous-dependencies
import { useLocation } from "react-router";
import HelperTipos from "../Tipos/helper";
import anos from "./anos";
import enumeradorCategoriaRelatorio from "./enumeradores/enumeradorCategoriaRelatorio";
import Helper from "./helper";
import ListaDto from "./ListaDto";
import meses from "./meses";
import { useStyles } from "./style";

import Modal from "./componentes/modalVideo";
import DownloadMetricas from "./relatoriosMetricas";

const ColunaDownload = ({ parametros }) => {
  const classes = useStyles();
  const [modalAberto, setModalAberto] = useState(false);
  const [carregandoDownload, setcarregandoDownload] = useState(false);

  const global = useSelector((state) => state.usuario);
  // eslint-disable-next-line no-shadow
  const { clienteSelecionado, usuario } = global;

  const onClickBotao = async (parametros) => {
    setcarregandoDownload(true);
    await Helper.BaixarPorUUID(parametros);
    setcarregandoDownload(false);

    if (usuario.tipo !== "Colaborador") {
      const salvarMetricas = {
        RelatorioId: parametros.id,
        DataDownloadRelatorio: new Date()
      };
      if (clienteSelecionado !== "")
        salvarMetricas.clienteId = clienteSelecionado;
      await Helper.Salvar(salvarMetricas);
    }
  };

  const onClickVideo = async () => {
    if (parametros?.videos?.length) {
      setModalAberto(true);
    }
  };

  return (
    <Grid container spacing={0} className={classes.fundoCelula}>
      <Modal
        item={modalAberto}
        relatorio={parametros}
        listaVideos={parametros?.videos || []}
        onCancelar={() => setModalAberto(false)}
      />

      <Loader loading={carregandoDownload}>
        <Grid
          item
          xs={parametros.categoria === "Setoriais" ? 6 : 12}
          className={classes.celulaBaixar}
        >
          <Button
            onClick={() => onClickBotao(parametros)}
            className={`text-white ${classes.botao}`}
          >
            <HtmlTooltip title="Baixar">
              <GetApp />
            </HtmlTooltip>
          </Button>
        </Grid>
      </Loader>
      {parametros.categoria === "Setoriais" && (
        <Grid item xs={6} className={classes.celulaVideo}>
          <Button
            onClick={() => onClickVideo(parametros)}
            className={`text-white ${classes.botao}`}
            disabled={parametros?.videos?.length === 0}
          >
            <HtmlTooltip title="Vídeo">
              <OndemandVideo />
            </HtmlTooltip>
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

const ColunaMobile = ({ parametros }) => {
  const classes = useStyles();
  const [modalAberto, setModalAberto] = useState(false);
  const onClickBotao = async (parametros) => {
    await Helper.BaixarPorUUID(parametros);
    if (usuario.tipo !== "Colaborador") {
      const salvarMetricas = {
        RelatorioId: parametros.id,
        DataDownloadRelatorio: new Date()
      };

      // eslint-disable-next-line no-undef
      if (clienteSelecionado !== "")
        // eslint-disable-next-line no-undef
        salvarMetricas.clienteId = clienteSelecionado;
      await Helper.Salvar(salvarMetricas);
    }
  };

  const onClickVideo = async () => {
    if (parametros?.videos?.length) {
      setModalAberto(true);
    }
  };

  return (
    <Grid container className={classes.fundoCelulaMobile}>
      <Modal
        item={modalAberto}
        relatorio={parametros}
        listaVideos={parametros?.videos || []}
        onCancelar={() => setModalAberto(false)}
      />

      <Grid item xs={parametros.categoria === "Setoriais" ? 8 : 10}>
        <Grid item>
          <HtmlTooltip title={parametros.nome}>
            <div className={`${classes.linhaTooltip} ${classes.nomeRelatorio}`}>
              {parametros.nome}
            </div>
          </HtmlTooltip>
        </Grid>
        <Grid item xs={12} className={`${classes.dataUpload}`}>
          {`Upload: ${parametros.dataUpload}`}
        </Grid>
      </Grid>
      <Grid item xs={2} className={classes.celulaVideoMobile}>
        <Button
          onClick={() => onClickBotao(parametros)}
          className="text-white p-0"
        >
          <HtmlTooltip title="Baixar">
            <GetApp className={`${classes.botao}`} />
          </HtmlTooltip>
        </Button>
      </Grid>
      {parametros.categoria === "Setoriais" && (
        <Grid item xs={2} className={classes.celulaVideoMobile}>
          <Button
            onClick={() => onClickVideo()}
            className={`text-white p-0 ${classes.botaoMobile}`}
            disabled={parametros?.videos?.length === 0}
          >
            <HtmlTooltip title="Vídeo">
              <OndemandVideo className={`${classes.botao}`} />
            </HtmlTooltip>
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

const ColunaNomes = ({ parametros }) => {
  const classes = useStyles();
  return (
    <HtmlTooltip title={parametros.nome}>
      <div className={`${classes.linhaTooltip}`}>{parametros.nome}</div>
    </HtmlTooltip>
  );
};

const ColunaTipoRelatorio = ({ parametros }) => {
  const classes = useStyles();
  return (
    <HtmlTooltip title={parametros.nomeTipoRelatorio}>
      <div className={`${classes.linhaTooltip}`}>
        {parametros.nomeTipoRelatorio}
      </div>
    </HtmlTooltip>
  );
};

const ColunaUnidade = ({ parametros }) => {
  const classes = useStyles();
  return (
    <HtmlTooltip title={parametros.nomeUnidade}>
      <div className={`${classes.linhaTooltip}`}>{parametros.nomeUnidade}</div>
    </HtmlTooltip>
  );
};

const RelatoriosBaixar = (props) => {
  const location = useLocation();

  // QueryString utilizada na notificação de relatório
  const urlParams = new URLSearchParams(location.search);
  const tipoRelatorioParam =
    urlParams.get("tipoRelatorio") == null
      ? "0"
      : urlParams.get("tipoRelatorio");
  const anoParam = urlParams.get("ano") == null ? "0" : urlParams.get("ano");
  const mesParam = urlParams.get("mes") == null ? "0" : urlParams.get("mes");

  const { state } = location;

  const global = useSelector((state) => state.usuario);
  const { unidadeSelecionada } = global;

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  const classes = useStyles();
  const refTabela = useRef(null);
  const [mes, setMes] = useState(mesParam);
  const [ano, setAno] = useState(anoParam);
  const [tipoRelatorioId, setTipoRelatorioId] = useState(tipoRelatorioParam);
  const [tiposRelatorios, setTiposRelatorios] = useState();
  // eslint-disable-next-line no-unused-vars
  const [dataInicialPesquisa, setDataInicialPesquisa] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [dataFinalPesquisa, setDataFinalPesquisa] = useState(null);
  const [filtroAdicionalTabela, setFiltroAdicionalTabela] = useState({
    tipoRelatorioId,
    ano,
    mes,
    unidadeSelecionada
  });
  // eslint-disable-next-line no-unused-vars
  const [videos, setVideos] = useState([]);

  const colunas = [];

  if (!mobile) {
    colunas.push(
      new ColunaComponentePersonalizado(
        "nome",
        "Relatórios",
        ColunaNomes,
        null,
        false,
        false,
        null,
        null
      )
    );
    colunas.push(
      new ColunaComponentePersonalizado(
        "nomeTipoRelatorio",
        "Tipo de relatório",
        ColunaTipoRelatorio,
        null,
        false,
        false,
        null,
        null
      )
    );

    if (props.categoria === "Customizados") {
      colunas.push(
        new ColunaComponentePersonalizado(
          "nomeUnidade",
          "Unidade",
          ColunaUnidade,
          null,
          false,
          false,
          null,
          null
        )
      );
    }
    colunas.push(new Coluna("dataUpload", "Data de upload", true));
    colunas.push(
      new ColunaComponentePersonalizado(
        "opcoes",
        "Ações",
        ColunaDownload,
        null,
        false,
        true,
        null,
        null,
        "pl-2",
        videos
      )
    );
  } else {
    colunas.push(
      new ColunaComponentePersonalizado(
        "nome",
        "",
        ColunaMobile,
        null,
        false,
        false,
        null,
        null
      )
    );
  }

  // eslint-disable-next-line no-unused-vars
  const onClickDownloadRelatorio = async () => {
    return Helper.ExportarPlanilha({
      dataInicial: dataInicialPesquisa
        ? window.moment(dataInicialPesquisa).format("YYYY-MM-DD")
        : "",
      dataFinal: dataFinalPesquisa
        ? window.moment(dataFinalPesquisa).format("YYYY-MM-DD")
        : ""
    });
  };

  const obterEnviados = async (params) => {
    if (Number(tipoRelatorioId) !== 0) params.TipoRelatorioId = tipoRelatorioId;
    if (Number(ano) !== 0) params.Ano = ano;
    if (Number(mes) !== 0) params.Mes = mes;
    if (Number(unidadeSelecionada) !== 0)
      params.UnidadeConsumidoraId = unidadeSelecionada;

    let resultado;
    const videos = [];

    if (props.categoria === "Setoriais") {
      resultado = await Helper.ListarSetoriais(params);

      if (resultado?.data?.lista?.length) {
        // eslint-disable-next-line no-restricted-syntax
        for (const item of resultado.data.lista) {
          // eslint-disable-next-line no-await-in-loop
          const { mensagem } = await Helper.ObterVideos(item);

          videos.push(mensagem.lista);
        }
      }
    } else if (props.categoria === "Customizados") {
      resultado = await Helper.ListarCustomizados(params);
    } else if (props.categoria === "Metricas") {
      // eslint-disable-next-line no-unused-expressions
      DownloadMetricas;
    }

    if (!resultado.sucesso) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: resultado.mensagem
        })
      );
      return new ResultadoPaginadoDto([], 1, 0, 0);
    }

    const resLinhas = resultado.data.lista?.map((item, index) => {
      return new ListaDto(
        item.id,
        item.nome,
        item.ativo,
        item.dataUpload,
        item.clienteId,
        item.identificador,
        item.tipoRelatorio.nome,
        item.nomeUnidade,
        props.categoria,
        item.dataReferencia,
        videos[index]
      );
    });

    return new ResultadoPaginadoDto(
      resLinhas,
      resultado.data.paginaAtual,
      resultado.data.totalItens,
      resultado.data.totalPaginas
    );
  };

  const onChangeFiltrosTabela = async (params) => {
    return obterEnviados(params);
  };

  useEffect(() => {
    setFiltroAdicionalTabela({
      tipoRelatorioId,
      ano,
      mes,
      unidadeSelecionada
    });
  }, [tipoRelatorioId, ano, mes, unidadeSelecionada]);

  const obterTiposRelatorios = async () => {
    const parametrosTipo = {};

    if (props.categoria === "Setoriais") {
      parametrosTipo.CategoriaRelatorioId =
        enumeradorCategoriaRelatorio.Setoriais;
    } else if (props.categoria === "Customizados") {
      parametrosTipo.CategoriaRelatorioId =
        enumeradorCategoriaRelatorio.Customizados;
    } else if (props.categoria === "Metricas") {
      parametrosTipo.CategoriaRelatorioId =
        enumeradorCategoriaRelatorio.Metricas;
    }
    const resultado = await HelperTipos.Listar(parametrosTipo);

    if (!resultado.sucesso) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: resultado.mensagem
        })
      );
      return;
    }

    setTiposRelatorios([
      {
        id: "0",
        nome: "Todos"
      },
      ...resultado.data.lista
    ]);

    if (state?.filtroCategoriaId) {
      setTipoRelatorioId(state?.filtroCategoriaId);
    }
  };

  useEffect(() => {
    obterTiposRelatorios();
  }, []);

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      spacing={!mobile ? 2 : 0}
    >
      <Grid item md={4} xs={12}>
        <Loader loading={false}>
          <SelectArredondado
            select
            id="TipoRelatorioId"
            name="TipoRelatorioId"
            valueKey="id"
            valueName="nome"
            dataSource={tiposRelatorios}
            label="Tipo de relatório"
            value={tipoRelatorioId}
            styleForm={false}
            onChange={(e) => {
              setTipoRelatorioId(e.target.value);
            }}
            placeholder="Tipo de relatório"
            allowClear
            permiteValorBranco
          />
        </Loader>
      </Grid>
      <Grid item md={4} xs={6}>
        <SelectArredondado
          select
          id="Ano"
          name="Ano"
          label="Ano"
          className={classes.selectLeft}
          placeholder="Ano"
          valueKey="valor"
          valueName="nome"
          dataSource={anos}
          value={ano}
          onChange={(e) => {
            setAno(e.target.value);
          }}
          permiteValorBranco
          styleForm={false}
        />
      </Grid>
      <Grid item md={4} xs={6}>
        <SelectArredondado
          select
          id="Mes"
          name="Mes"
          label="Mês"
          className={classes.selectRight}
          placeholder="Mês"
          valueKey="valor"
          valueName="nome"
          dataSource={meses}
          value={mes}
          onChange={(event) => {
            setMes(event.target.value);
          }}
          permiteValorBranco
          styleForm={false}
        />
      </Grid>

      <Grid item xs={12}>
        {!mobile ? (
          <TabelaPaginada
            className=""
            ref={refTabela}
            onChangeFiltrosTabela={onChangeFiltrosTabela}
            filtrosAdicionais={filtroAdicionalTabela}
            colunas={colunas}
            paginaAtual={1}
            noHeader
          />
        ) : (
          <Grid>
            <TabelaPaginadaMobile
              className={classes.fundoCelulaTransparente}
              ref={refTabela}
              onChangeFiltrosTabela={onChangeFiltrosTabela}
              filtrosAdicionais={filtroAdicionalTabela}
              colunas={colunas}
              paginaAtual={1}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default RelatoriosBaixar;
