import { Card, Chip, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { GetApp } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import { HtmlTooltip } from "paginas/Graficos/ConsumoAtual/style";
import { Loader, Botao, ModalConfirmacao, InputRadioGroup } from "componentes";
import MaterialInputTexto from "componentes/inputTexto/materialInput";
// Redux
import { store } from "../../../global/redux";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";

import analiseDocumentoHelper from "./helper";
import { useStyles, Tabela, chipStyles } from "./style";
import UploadCard from "./Componentes/UploadCard";
import { RotasDTO } from "../../../global/rotas/rotasUrlDto";
import { downloadFileAWS, validacaoTrimObject } from "../../../servicos/utils";

function AnaliseDocumento() {
  const { operacaocontroleid } = useParams();

  const classes = useStyles();
  const [nomeCliente, setNomeCliente] = useState();
  const [cnpjCnfCliente, setCnpjCnfCliente] = useState();
  const [operacaoControlePJId, setOperacaoControlePJId] = useState();
  const [listaDocumento, setListaDocumento] = useState();
  const [justificativaJuridico, setJustificativaJuridico] = useState("");
  const [itemIncorreto, setItemIncorreto] = useState();
  const [carregando, setCarregando] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [mostraModal, setMostraModal] = useState(false);
  const [iniciarReprovacao, setIniciarReprovacao] = useState(false);
  const [contratos, setContratos] = useState([]);
  const [iniciarAprovacao, setIniciarAprovacao] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [arquivo, setArquivo] = useState({});
  const history = useHistory();

  const [nomeArquivo, setNomeArquivo] = useState(null);
  const [statusEdit, setStatusEdit] = useState(false);
  const [documentoGuid, setDocumentoGuid] = useState(null);
  const classesChip = chipStyles({ iconeDesabled: false });

  const listarDocumento = async () => {
    if (!operacaocontroleid || operacaocontroleid <= 0) return;

    const {
      data,
      sucesso,
      mensagem
    } = await analiseDocumentoHelper.listarDocumentoParaAprovacao(
      operacaocontroleid,
      setCarregando
    );

    if (!sucesso) {
      analiseDocumentoHelper.exibirErro(mensagem);
      return;
    }

    if (data?.validacao) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: data?.validacao
        })
      );
    }

    await listarContratos(data?.listaClienteDocumento[0]?.unidadeConsumidoraId);

    setNomeCliente(data?.nomeFantasia);
    setCnpjCnfCliente(data?.cnpjCpfFormatado);
    setOperacaoControlePJId(data?.operacaoControlePJId);
    setListaDocumento(data?.listaClienteDocumento);
    setJustificativaJuridico(
      data?.justificativaAprovado || data?.justificativaReprovado
    );
    setNomeArquivo(data?.nomeArquivo);
    setStatusEdit(data?.aprovado === "S" || data?.aprovado === "N");
    setIniciarAprovacao(data?.aprovado === "S");
    setIniciarReprovacao(data?.aprovado === "N");
    setItemIncorreto(data?.itemIncorreto);
    setDocumentoGuid(data?.documentoGuid);
  };

  const listarContratos = async (unidade) => {
    if (!unidade) return;

    const retornoContratos = await analiseDocumentoHelper.obterClienteContrato(
      unidade
    );

    setContratos(retornoContratos?.data || []);
  };

  const onClickDownloadContrato = async (unidade, nomeGuid) => {
    await analiseDocumentoHelper.downloadContrato(unidade, nomeGuid);
  };

  const verificarTipoContrato = (tipo) => {
    const tipos = ["Contrato LUZ", "Procuração", "Certificado", "Summary"];
    if (tipo?.toLowerCase()?.includes("contrato pj")) return tipos[0];
    if (tipo?.toLowerCase()?.includes("contrato pf")) return tipos[0];
    if (tipo?.toLowerCase()?.includes("contrato")) return tipos[0];
    if (tipo?.toLowerCase()?.includes("summary")) return tipos[3];
    if (tipo?.includes("certificate")) return tipos[2];
    return tipos[1];
  };

  const onClickDownloadDocumentoCliente = async (id, nomeArquivo) => {
    setCarregando(true);
    await analiseDocumentoHelper.downloadDocumentoCliente(id, nomeArquivo);
    setCarregando(false);
  };

  const aprovarDocumentos = async () => {
    const {
      sucesso,
      mensagem
    } = await analiseDocumentoHelper.atualizarOperacaoControlePJ(
      operacaoControlePJId,
      {
        aprovado: "S"
      },
      setCarregando
    );

    if (!sucesso) {
      analiseDocumentoHelper.exibirErro(mensagem);
      return;
    }

    setBtnDisabled(true);
    history.push(`${RotasDTO.ValidacaoJuridico}`);
  };

  const adicionaRegistroDocumentoPJAprovado = async (data) => {
    const model = {
      operacaoControlePJId,
      justificativaAprovado: data?.justificativaAprovado || "",
      nomeArquivo: arquivo.nomeArquivo || "",
      nomeArquivoComExtensao: data?.documentoGuideS3 || ""
    };

    const {
      mensagem,
      sucesso
    } = await analiseDocumentoHelper.adicionaRegistroDocumentoPJAprovado(
      validacaoTrimObject(model),
      setCarregando
    );

    if (!sucesso) {
      analiseDocumentoHelper.exibirErro(mensagem);
      return;
    }

    aprovarDocumentos();
  };

  const getBinaryFrom = async (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result));

      reader.readAsArrayBuffer(file);
    });
  };

  const uploadFileToS3 = async (data, arquivo) => {
    try {
      const currentFileBuffer = await getBinaryFrom(arquivo?.arquivo?.[0]);
      if (!data?.urlPreAssinada) return;

      await fetch(decodeURI(data?.urlPreAssinada), {
        method: "PUT",
        body: currentFileBuffer,
        headers: {
          "Content-type": "multipart/form-data"
        }
      });

      adicionaRegistroDocumentoPJAprovado(data);
    } catch (error) {
      analiseDocumentoHelper.exibirErro("Erro ao fazer upload AWS!");
    }
  };

  const uploadControlePJDocumentoAprovado = async () => {
    if (
      !justificativaJuridico ||
      (!Object.values(arquivo).length > 0 && !arquivo.nomeArquivo)
    ) {
      analiseDocumentoHelper.exibirErro(
        "Arquivo e justificativa, são obrigatórios"
      );
      return;
    }

    const model = {
      operacaoControlePJId,
      justificativaAprovado: justificativaJuridico || "",
      nomeArquivoComExtensao: arquivo.nomeArquivo || ""
    };

    const {
      data,
      mensagem,
      sucesso
    } = await analiseDocumentoHelper.uploadControlePJDocumentoAprovado(
      validacaoTrimObject(model),
      setCarregando
    );

    if (!sucesso) {
      analiseDocumentoHelper.exibirErro(mensagem);
      return;
    }

    uploadFileToS3(data, arquivo);
  };

  const reprovarDocumentos = async () => {
    if (!itemIncorreto) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: "Motivo da reprovação é obrigatório!"
        })
      );
      return;
    }

    if (!justificativaJuridico) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: "Justificativa é obrigatória!"
        })
      );
      return;
    }

    await analiseDocumentoHelper.atualizarOperacaoControlePJ(
      operacaoControlePJId,
      {
        aprovado: "N",
        justificativaJuridico,
        itemIncorreto
      },
      setCarregando
    );

    setBtnDisabled(true);
    history.push(`${RotasDTO.ValidacaoJuridico}`);
  };

  useEffect(() => {
    listarDocumento();
  }, [operacaocontroleid]);

  const addArquivos = async (arquivo) => {
    setShowLoader(true);
    const fileName = arquivo[0]?.name;

    const arquivoAdd = {
      nomeArquivo: fileName,
      arquivo
    };

    setArquivo(arquivoAdd);

    setTimeout(() => {
      setShowLoader(false);
    }, 2000);
  };

  const onDelete = async () => {
    setArquivo({});
  };

  const downloadFileUrl = async (nomeArquivo, guidArquivo) => {
    if (!guidArquivo) return;
    const model = {
      nomeArquivo: guidArquivo
    };

    const {
      data,
      sucesso,
      mensagem
    } = await analiseDocumentoHelper.geraUrlDownload(model, setCarregando);

    if (!sucesso) {
      analiseDocumentoHelper.exibirErro(mensagem);
      setCarregando(false);
      return;
    }

    downloadFileAWS(data, nomeArquivo);
  };

  return (
    <>
      <Loader loading={carregando} className="w-auto">
        <ModalConfirmacao
          item={mostraModal}
          onConfirmar={() => {
            uploadControlePJDocumentoAprovado();
            setMostraModal(false);
          }}
          mensagem="Tem certeza que deseja aprovar a documentação do cliente?"
          onCancelar={() => setMostraModal(false)}
        />
        {operacaoControlePJId > 0 ? (
          <Card>
            <Grid container p={2} spacing={4}>
              <Grid item lg={12} md={12} sm={12} className="font-weight-bold">
                {`Cliente em Aprovação de Documentos:  ${nomeCliente} (${cnpjCnfCliente})`}
              </Grid>
              <Grid item lg={12} md={12} sm={12}>
                <Tabela>
                  <Tabela.Tr>
                    <Tabela.Th>Tipo de Documento</Tabela.Th>
                    <Tabela.Th>Nome</Tabela.Th>
                    <Tabela.Th />
                  </Tabela.Tr>
                  {listaDocumento?.map((item) => (
                    <Tabela.Tr key={item.id}>
                      <Tabela.Td>{item.tipoDocumento.documento}</Tabela.Td>
                      <Tabela.Td>{item.nomeArquivo}</Tabela.Td>
                      <Tabela.Td>
                        <Button
                          onClick={() =>
                            onClickDownloadDocumentoCliente(
                              item.id,
                              item.nomeArquivo + item.extensao
                            )
                          }
                        >
                          <HtmlTooltip title="Baixar">
                            <GetApp className={`${classes.button}`} />
                          </HtmlTooltip>
                        </Button>
                      </Tabela.Td>
                    </Tabela.Tr>
                  ))}
                </Tabela>
              </Grid>
              <hr className={classes.hr} />
              <Grid item lg={12} md={12} sm={12}>
                <Tabela>
                  <Tabela.Tr>
                    <Tabela.Th>Contratos</Tabela.Th>
                    <Tabela.Th>Nome</Tabela.Th>
                    <Tabela.Th />
                  </Tabela.Tr>
                  {contratos?.map((item) => (
                    <Tabela.Tr key={item.id}>
                      <Tabela.Td>
                        {verificarTipoContrato(item.nomeGuid)}
                      </Tabela.Td>
                      <Tabela.Td>{item.nomeGuid}</Tabela.Td>
                      <Tabela.Td>
                        <Button
                          onClick={() =>
                            onClickDownloadContrato(
                              item.unidadeId,
                              item.nomeGuid
                            )
                          }
                        >
                          <HtmlTooltip title="Baixar">
                            <GetApp className={`${classes.button}`} />
                          </HtmlTooltip>
                        </Button>
                      </Tabela.Td>
                    </Tabela.Tr>
                  ))}
                </Tabela>
              </Grid>
              <Grid item lg={3} md={6} sm={6} xs={12}>
                <Botao
                  disabled={btnDisabled || statusEdit}
                  onClick={() => {
                    setIniciarAprovacao(true);
                    setIniciarReprovacao(false);
                  }}
                  label="Aprovar"
                />
              </Grid>
              <Grid item lg={3} md={6} sm={6} xs={12}>
                <Botao
                  disabled={btnDisabled || statusEdit}
                  onClick={() => {
                    setIniciarAprovacao(false);
                    setIniciarReprovacao(true);
                  }}
                  label="Reprovar"
                />
              </Grid>
            </Grid>
          </Card>
        ) : null}

        {iniciarReprovacao > 0 ? (
          <Card className={classes.card}>
            <Grid container p={2} spacing={4}>
              <Grid item lg={12} md={12} sm={12} className="font-weight-bold">
                Selecione o porque foi reprovado
              </Grid>
              <Grid item lg={12} md={12} sm={12}>
                <InputRadioGroup
                  type="text"
                  id="itemIncorreto"
                  name="itemIncorreto"
                  label="Motivo reprovação"
                  renderIconShowHide={false}
                  disabled={btnDisabled || statusEdit}
                  customValue={itemIncorreto}
                  onChange={(valor) => setItemIncorreto(valor.target.value)}
                  defaultValue={itemIncorreto}
                  classNameLabel={classes.inputRadioLabel}
                  itens={analiseDocumentoHelper.listarItemIncorreto()}
                />
              </Grid>
              <Grid item lg={8} md={8} sm={8} xs={12}>
                <MaterialInputTexto
                  type="text"
                  id="justificativa"
                  name="justificativa"
                  label="Justifique sua resposta"
                  disabled={btnDisabled || statusEdit}
                  renderIconShowHide={false}
                  onBlur={(valor) =>
                    setJustificativaJuridico(valor.target.value)
                  }
                  defaultValue={justificativaJuridico || ""}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Botao
                  disabled={btnDisabled || statusEdit}
                  onClick={() => reprovarDocumentos()}
                  label="Salvar"
                />
              </Grid>
            </Grid>
          </Card>
        ) : null}

        {iniciarAprovacao && (
          <Card className={classes.card}>
            <Grid container p={2} spacing={2}>
              <Grid item lg={12} md={12} sm={12} className="font-weight-bold">
                Descreva o porque foi aprovado
              </Grid>

              <Grid item lg={4} md={4} sm={12}>
                {statusEdit ? (
                  nomeArquivo && (
                    <Chip
                      classes={{ root: classesChip.previewChip }}
                      label={nomeArquivo}
                      onClick={() =>
                        downloadFileUrl(nomeArquivo, documentoGuid)
                      }
                      variant="outlined"
                    />
                  )
                ) : (
                  <UploadCard
                    showLoader={showLoader}
                    limite={1}
                    titulo="Faça upload do documento"
                    tituloUpload="Carregar documento"
                    onAddArquivoUpload={(arquivos, tipo) => {
                      addArquivos(arquivos, tipo);
                    }}
                    onDeleteArquivoUpload={(arquivos, tipo) => {
                      onDelete(arquivos, tipo);
                    }}
                    onClickDownload={(nome) =>
                      downloadFileUrl(nome, documentoGuid)
                    }
                    initialFiles={
                      nomeArquivo
                        ? [
                            new File([""], nomeArquivo, {
                              type: `application/${
                                nomeArquivo?.split(".")?.reverse()?.[0]
                              }`,
                              lastModified: new Date()
                            })
                          ]
                        : undefined
                    }
                    renderizaComponente
                    disabledCard={statusEdit}
                  />
                )}
              </Grid>
              <Grid item sm={8} />
              <Grid item lg={8} md={8} sm={8} xs={12}>
                <MaterialInputTexto
                  type="text"
                  id="justificativa"
                  name="justificativa"
                  label="Justifique sua resposta"
                  disabled={btnDisabled || statusEdit}
                  renderIconShowHide={false}
                  onBlur={(valor) =>
                    setJustificativaJuridico(valor.target.value)
                  }
                  defaultValue={justificativaJuridico || ""}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Botao
                  disabled={btnDisabled || statusEdit}
                  onClick={() => setMostraModal(true)}
                  label="Salvar"
                />
              </Grid>
            </Grid>
          </Card>
        )}
      </Loader>
    </>
  );
}

export default AnaliseDocumento;
