import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const api = () => obterApi(MICROSERVICO.MEASUREMENT);

export const unidadeConsumidoraGD = async () => {
  return api().get("v1/unidadeconsumidora/gd");
};

export const unidadeConsumidoraGDColaborador = async (unidadeConsumidoraId) => {
  return api().get(`v1/unidadeconsumidora/gd/colaborador/${unidadeConsumidoraId}`);
}
