import { makeStyles } from "@mui/styles";
import { Base } from "componentes/cores";
import _theme from "themes";
import { createTheme } from "@mui/material/styles";

const theme = createTheme();

export const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: theme.palette.background.paper
  },
  container: {
    margin: 0,
    width: "100%",
    "& .MuiGrid-root": {
      color: `${Base.BlackBastille} !important`
    },
    "& .MuiDropzoneArea-text": {
      color: `${Base.BlackBastille} !important`
    },
    "& .MuiInputBase-root": {
      height: "46px"
    },
    "& .MuiFormLabel-root": {
      lineHeight: "1"
    }
  },
  formControl: {
    minWidth: "100%"
  },
  containerTitulo: {
    padding: "18px !important"
  },
  containerTitulo: {
    padding: "18px !important"
  },
  titulo: {
    fontSize: "18px",
    color: "#FFF !important",
    fontFamily: _theme.font.family,
    fontWeight: "700"
  },
  containerData: {
    padding: "32px !important"
  },
  icon: {
    cursor: "pointer",
    float: "right",
    margin: "5px",
    color: "#FFF !important"
  },
  containerData: {
    padding: "32px !important"
  },
  button: {
    fontSize: "18px"
  },
  buttonCustom: {
    fontSize: "18px",
    color: "#FEBB2F !important",
    backgroundColor: "transparent !important",

    "&:hover": {
      color: "#212529"
    }
  },
  botao: {
    background: "none",
    color: "inherit",
    border: "none",
    padding: "0",
    font: "inherit",
    cursor: "pointer",
    outline: "inherit"
  },
  total: {
    textAlign: "end"
  },
  inputText: {
    textAlign: "center",
    border: "none",
    background: "rgba(46, 49, 49, 0.2)",

    "& .MuiInputBase-root.Mui-disabled": {
      color: Base.BlackBastille,

      "& input": {
        textAlign: "center"
      }
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none"
    }
  },
  footerButtons: {
    paddingBottom: "1rem",
    width: "50vw"
  },
  footerCheckbox: {
    paddingBottom: "1rem"
  },
  itemfiltroData: {
    padding: "30px",
    "& input": {
      color: Base.White
    },
    "& div": {
      border: "none"
    }
  },
  panelGarantiaFisica: {
    padding: "10px",
    "& input": {
      color: Base.White
    },
    "& div": {
      border: "none"
    }
  }
}));

export const conditionalRowStyles = [
  {
    when: (row) => !row.ativo,
    style: {
      "& div": {}
    }
  }
];

export const customStyles = {
  table: {
    style: {
      background: "transparent"
    }
  },
  header: {
    style: {
      background: "transparent"
    }
  },
  subHeader: {
    style: {
      background: "transparent"
    }
  },
  headRow: {
    style: {
      background: "transparent"
    }
  },
  headCells: {
    style: {
      background: "#2a2b32",
      marginLeft: "3px",
      fontSize: "16px",
      "& div": {
        color: `${Base.OffWhite} !important`,
        "&:hover": {
          color: `${Base.OffWhite} !important`
        }
      }
    }
  },
  container: {
    margin: "0 0 24px",
    width: "100%",
    paddingLeft: "30px",
    "& .MuiFormControl-marginNormal": {
      border: `5px solid ${Base.BlueViolet}`,
      borderRadius: "4px",
      margin: "0"
    }
  },
  rows: {
    style: {
      marginBottom: "1px",
      marginTop: "1px",
      color: Base.White
    }
  },
  Pagination: {
    styles: {
      color: "#eef0f9"
    }
  },
  cells: {
    style: {
      marginLeft: "3px",
      background: "#32333b",
      fontSize: "14px"
    }
  }
};
