import CheckIcon from "@mui/icons-material/Check";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Checkbox } from "@mui/material";
import React from "react";
import { generate } from "shortid";
import { useStyles } from "../style";

export default class ColunaCampoCheckBox {
  constructor(
    field,
    headerName,
    onChange,
    maxWidth = null,
    minWidth = null,
    label
  ) {
    const classes = useStyles();
    this.selector = field;
    this.name = headerName;
    this.maxWidth = maxWidth;
    this.minWidth = minWidth;
    this.cell = (params) => {
      return (
        <Checkbox
          key={generate()}
          color="default"
          className={classes.checkbox}
          checkedIcon={<CheckIcon className={classes.iconeSelecionado} />}
          icon={
            <CheckBoxOutlineBlankIcon className={classes.iconeSemSelecao} />
          }
          checked={params.checked}
          inputProps={{ "aria-label": label }}
          disabled={params.disabled}
          onChange={(event) => {
            onChange(event.target.checked, params?.id);
          }}
        />
      );
    };
  }
}
