import CheckIcon from "@mui/icons-material/Check";
import ReportIcon from "@mui/icons-material/Report";
import { Box, Grid } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";

// FormHooks
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";

// Redux
import { useSelector } from "react-redux";
import { store } from "../../../global/redux";
import { salvarNotificacoes } from "../../../global/redux/modulos/notificacoes/actions";
import {
  salvarContasUsuario,
  salvarUsuario
} from "../../../global/redux/modulos/usuario/actions";

import Alerta from "../../../componentes/alerta";
import Botao from "../../../componentes/botao";
import CardLogin from "../../../componentes/cardLogin";
import Loader from "../../../componentes/loader";

import Campos from "../campos";
import {
  ButtonLink,
  ButtonLinkLuz,
  ButtonLinkMudeLuz,
  Fundo,
  Logo,
  Mensagem,
  MensagemLogout,
  Rodape,
  SubTitulo,
  SubTituloLuz,
  Titulo,
  useStyles,
  Version,
  Wrapper
} from "../style";

import LogoDeltaCaptcha from "../../../assets/captch/logo-delta-captcha.png";
import LogoLuzCaptcha from "../../../assets/captch/logo-luz-captcha.png";
import LogoThymosCaptcha from "../../../assets/captch/logo-thymos-captcha.png";

import {
  efetuarLogin,
  regerarChave,
  validarCaptcha
} from "../../../servicos/loginServico";
import { ListaNotificacoes } from "../../../servicos/notificacaoServico";

import getDomain from "../../../global/getDomain";
import version from "../../../global/getVersion";
import theme from "../../../themes";

import Captcha from "../componente";
import NavBarLuz from "../componente/NavBarLuz";
import AcessoHelper from "../helper";

const Login = () => {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    errors,
    reset
  } = useForm({ mode: "onSubmit" });

  const login = useSelector((state) => state.usuario);

  const [urlCallback, setUrlCallback] = useState();
  const [enviarEmail, setEnviarEmail] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const [posicao, setPosicao] = useState(0);
  const [chaveCaptcha, setChave] = useState("");
  const [resetCaptcha, setResetCaptcha] = useState(false);
  const [captchaValido, setCaptchaValido] = useState(false);
  const [carregandoLogin, setCarregandoLogin] = useState(false);
  const [mensagemAlerta, setMensagemAlerta] = useState("");
  const [mensagemAlertaAcesso, setMensagemAlertaAcesso] = useState("");

  const redirecionar = (id) => {
    if (urlCallback) {
      window.location.href = urlCallback;
    } else if (id === 43) {
      window.location.href = "/fluxo-migracao/migracao-gd";
    } else if (id === 46) {
      window.location.href = "/dashboard/economia";
    } else {
      window.location.href = "/";
    }
  };

  const obterNotificacoes = () => {
    ListaNotificacoes()
      .then((retornoNotificacoes) => {
        if (
          retornoNotificacoes?.status === 200 ||
          retornoNotificacoes?.status === 204
        ) {
          if (retornoNotificacoes?.data)
            store.dispatch(salvarNotificacoes(retornoNotificacoes?.data));

          // redirecionar(login?.usuario?.perfilId ?? "");
        }
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.error(err));
  };

  const obterContas = async (logado) => {
    if (
      logado?.data?.usuarioLogado?.tipo?.toLocaleLowerCase() === "colaborador"
    ) {
      redirecionar(logado?.data?.usuarioLogado?.perfilId ?? "");
      return;
    }

    const {
      data,
      sucesso,
      mensagem
    } = await AcessoHelper.obterUsuariosClientes(
      logado.data.usuarioLogado.id,
      setCarregandoLogin
    );

    if (!sucesso) {
      AcessoHelper.exibirErro(mensagem);
      return;
    }

    store.dispatch(salvarContasUsuario(data?.usuario?.clienteUnidades ?? []));

    if (data?.usuario?.clienteUnidades?.length > 1) {
      history.push("/login/selecao-conta");
    } else {
      redirecionar(logado?.data?.usuarioLogado?.perfilId ?? "");
    }
  };

  const reloadCaptcha = () => {
    setResetCaptcha(true);
    setCaptchaValido(false);
    setPosicao(0);
    const timer = setTimeout(() => {
      setResetCaptcha(false);
    }, 100);
    return () => clearTimeout(timer);
  };

  const enviarFormulario = async (dados) => {
    try {
      const validacaoCaptcha = { chave: chaveCaptcha, posicao };

      if (dados?.acesso && dados?.senha && !enabled) {
        const logado = await efetuarLogin(dados, validacaoCaptcha);
        if (logado?.status === 200 && logado?.data && logado?.data?.token) {
          store.dispatch(salvarUsuario(logado.data));

          obterContas(logado);
        }
      }
    } catch (error) {
      if (error?.response?.data?.status === 400) {
        reloadCaptcha();
      }
      if (error?.response?.data?.status === 403) {
        setMensagemAlertaAcesso({
          tipo: "danger",
          mensagem:
            "E-mail ou senha incorretos. Por favor, verifique seus dados e tente novamente."
        });

        return;
      }

      setMensagemAlerta({
        tipo: "warning",
        mensagem: error?.response?.data?.message
          ? error?.response?.data?.message
          : "Erro interno, entre em contato com o suporte!"
      });
    } finally {
      setCarregandoLogin(false);
      setEnabled(false);
    }
  };

  const aoEnviarFormulario = (dados) => {
    setEnabled(true);
    setMensagemAlerta("");
    setCarregandoLogin(true);
    enviarFormulario(dados);
  };

  const [erros, setErros] = useState({});

  useEffect(() => {
    setErros(errors);
  }, [errors]);

  // eslint-disable-next-line consistent-return
  const onClickEsqueciSenha = async () => {
    if (!captchaValido) {
      setMensagemAlerta({
        tipo: "warning",
        mensagem: "Posicione a figura no local indicado."
      });
      return false;
    }
    setMensagemAlerta("");
    const acesso = getValues("acesso");

    if (!acesso) {
      setErros({
        acesso: {
          message: "Campo acesso/e-mail é obrigatório!",
          type: "required"
        }
      });
    } else {
      setErros({});
      setValue("senha", "");
      setCarregandoLogin(true);
      try {
        if (!enabled) {
          setEnabled(true);
          const gerou = await regerarChave(acesso);
          if (gerou?.data?.validationResult?.isValid) {
            setMensagemAlerta({
              tipo: "success",
              mensagem: "Você receberá um e-mail com novas instruções!"
            });
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          }
        }
      } catch (error) {
        setMensagemAlerta({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        });
      }
      setCarregandoLogin(false);
    }
  };

  const mensagemLogout = useMemo(() => {
    if (window?.location?.pathname === "/logout")
      return "Sua sessão encerrou! Faça o login novamente.";
    return false;
  }, [window.location.pathname]);

  const siteLuzSimulador = () => {
    window.open(
      `${process.env.REACT_APP_SITE_LUZ_API_URL}/#simulador`,
      "_target"
    );
  };

  const handleEsqueciSenha = () => {
    setEnviarEmail(true);
    reset();
  };

  const handleLogin = () => {
    setEnviarEmail(false);
  };

  const captchaPosicao = (value) => value > 0 && setPosicao(value);

  const validando = async () => {
    if (posicao > 0) {
      try {
        const validacaoCaptcha = { chave: chaveCaptcha, posicao };
        const valido = await validarCaptcha(validacaoCaptcha);
        if (valido.status === 200) {
          setCaptchaValido(true);
          setMensagemAlerta("");
          setEnabled(false);
        }
      } catch (error) {
        if (error?.response?.data?.status === 400) {
          reloadCaptcha();
        }
        setMensagemAlerta({
          tipo: "warning",
          mensagem: "Posicione a figura no local indicado"
        });
      }
    }
  };

  const desabilitarCaptcha = () => {
    if (location?.pathname?.includes("sem-captcha")) {
      setEnabled(false);
    }
  };

  useEffect(() => {
    if (login?.token && history) {
      obterNotificacoes();
    }
  }, [login, history]);

  useEffect(() => {
    if (posicao > 0) {
      validando();
    }
  }, [chaveCaptcha, posicao]);

  useEffect(() => {
    desabilitarCaptcha();
    const urlRetorno = localStorage.getItem("url_retorno");
    if (urlRetorno) setUrlCallback(urlRetorno);
  }, []);

  const chave = (value) => {
    setChave(value);
  };

  const handleImageLogo = (domain) => {
    switch (domain) {
      case "luz":
        return LogoLuzCaptcha;
      case "delta":
        return LogoDeltaCaptcha;
      case "thymos":
        return LogoThymosCaptcha;
      default:
        return LogoLuzCaptcha;
    }
  };

  const primeiroCadastroCri = () => {
    history.push("/login/cri/cadastro");
  };

  return (
    <>
      {getDomain() === "luz" ? (
        <>
          <NavBarLuz />
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "90vh", backgroundColor: "#332053" }}
          >
            <Box
              mt={2}
              justifyContent="center"
              alignItems="center"
              width={{ xs: "100%", sm: 468 }}
            >
              {mensagemAlertaAcesso && (
                <Alerta
                  {...mensagemAlertaAcesso}
                  onClose={() => setMensagemAlertaAcesso("")}
                />
              )}
            </Box>
            <Box
              borderRadius="4px"
              justifyContent="center"
              alignItems="center"
              bgcolor="#FFFFFF"
              p={{ xs: 0.7, sm: 4 }}
              width={{ xs: "100%", sm: 468 }}
              mt={!mensagemAlertaAcesso ? 6 : 0}
            >
              <Box mb={{ xs: 6 }} display="flex" justifyContent="center">
                {enviarEmail ? (
                  <SubTituloLuz style={{ fontSize: "40px" }}>
                    Esqueci minha senha
                  </SubTituloLuz>
                ) : (
                  <SubTituloLuz>{theme.texto.loginBemVindo}</SubTituloLuz>
                )}
              </Box>
              {mensagemLogout ? (
                <MensagemLogout>
                  <ReportIcon className={classes.iconeLogoff} />
                  {mensagemLogout}
                </MensagemLogout>
              ) : null}
              <form
                id="hook-form"
                autoComplete="off"
                className="needs-validation"
                onSubmit={handleSubmit(aoEnviarFormulario)}
                style={{ marginTop: "30px" }}
              >
                <Loader loading={carregandoLogin}>
                  {enviarEmail ? (
                    <Campos
                      register={register}
                      errors={erros}
                      enviarEmail={enviarEmail}
                    />
                  ) : (
                    <Campos
                      register={register}
                      errors={erros}
                      enviarEmail={enviarEmail}
                    />
                  )}
                </Loader>
              </form>
              <Box
                display="flex"
                justifyContent="start"
                mt={{ xs: 2 }}
                mb={{ xs: 2 }}
              >
                {!enviarEmail ? (
                  <ButtonLinkLuz onClick={handleEsqueciSenha}>
                    Esqueci minha senha
                  </ButtonLinkLuz>
                ) : null}
              </Box>
              <Box className={classes.boxCaptcha}>
                {!resetCaptcha && !captchaValido ? (
                  <Captcha
                    sistema={getDomain()}
                    chave={setChave}
                    captchaPosicao={setPosicao}
                  />
                ) : (
                  captchaValido && (
                    <>
                      <Box
                        component="img"
                        className={classes.logoCaptcha}
                        src={handleImageLogo(getDomain())}
                      />
                      <div className="text-center my-3">
                        <CheckIcon className={classes.check} />
                        {" Validado!"}
                      </div>
                    </>
                  )
                )}
              </Box>
              {enviarEmail ? (
                <Box
                  color="#fff"
                  mt="24"
                  border="none"
                  bgcolor={`rgba(250, 62, 177, ${enabled ? 0.4 : 1})`}
                  component="button"
                  form="hook-form"
                  type="submit"
                  width="100%"
                  borderRadius="24px"
                  height="54px"
                  fontWeight="700"
                  fontSize="18px"
                  disabled={enabled}
                  opacity={0.2}
                  onClick={onClickEsqueciSenha}
                  sx={{ cursor: "pointer" }}
                >
                  Redefinir senha
                </Box>
              ) : (
                <Box
                  color="#fff"
                  mt="24"
                  border="none"
                  bgcolor={`rgba(250, 62, 177, ${enabled ? 0.4 : 1})`}
                  component="button"
                  form="hook-form"
                  type="submit"
                  width="100%"
                  borderRadius="24px"
                  height="54px"
                  fontWeight="700"
                  fontSize="18px"
                  disabled={enabled}
                  sx={{ cursor: "pointer" }}
                >
                  Entrar
                </Box>
              )}
              <Box mt={1}>
                {mensagemAlerta && (
                  <Alerta
                    {...mensagemAlerta}
                    onClose={() => setMensagemAlerta("")}
                  />
                )}
              </Box>
            </Box>
            {enviarEmail ? (
              <Rodape>
                Já sou cliente!
                <ButtonLinkMudeLuz onClick={handleLogin}>
                  Voltar ao Login
                </ButtonLinkMudeLuz>
              </Rodape>
            ) : (
              <Rodape>
                Ainda não é cliente?
                <ButtonLinkMudeLuz onClick={siteLuzSimulador}>
                  Mude para a LUZ
                </ButtonLinkMudeLuz>
              </Rodape>
            )}
          </Grid>
        </>
      ) : (
        <>
          <Fundo
            url={`../themes/${getDomain()}/assets/bg-login.jpg`}
            className="w-100 h-100 position-absolute overflow-hidden"
          />
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "100vh", backgroundColor: "#332053" }}
          >
            <Logo src={`../themes/${getDomain()}/assets/logo-217x132.png`} />
            <Box
              mt={2}
              justifyContent="center"
              alignItems="center"
              width={{ xs: "100%", sm: 468 }}
            >
              {mensagemAlertaAcesso && (
                <Alerta
                  {...mensagemAlertaAcesso}
                  onClose={() => setMensagemAlertaAcesso("")}
                />
              )}
            </Box>
            <CardLogin height={533} width={468} className="mx-auto">
              <Wrapper className="mx-auto">
                <Titulo italic="italic">Login</Titulo>
                <SubTitulo>{theme.texto.loginBemVindo}</SubTitulo>
                {mensagemLogout ? (
                  <MensagemLogout>
                    <ReportIcon className={classes.iconeLogoff} />
                    {mensagemLogout}
                  </MensagemLogout>
                ) : null}
                <form
                  className="needs-validation"
                  onSubmit={handleSubmit(aoEnviarFormulario)}
                >
                  <Loader loading={carregandoLogin}>
                    <Campos register={register} errors={erros} />

                    <Box className={classes.boxCaptcha}>
                      {!resetCaptcha && !captchaValido ? (
                        <Captcha
                          sistema={getDomain()}
                          chave={(value) => chave(value)}
                          captchaPosicao={(value) => captchaPosicao(value)}
                        />
                      ) : (
                        captchaValido && (
                          <>
                            <Box
                              component="img"
                              className={classes.logoCaptcha}
                              src={handleImageLogo(getDomain())}
                            />
                            <div className="text-center my-3">
                              <CheckIcon className={classes.check} />
                              {" Validado!"}
                            </div>
                          </>
                        )
                      )}
                    </Box>

                    <Botao
                      type="submit"
                      label="Entrar"
                      style={{ marginTop: 24 }}
                      disabled={enabled}
                    />
                  </Loader>
                </form>
                <Mensagem className="mt-2">
                  <ButtonLink
                    onClick={() => {
                      onClickEsqueciSenha();
                    }}
                    onKeyDown={onClickEsqueciSenha}
                  >
                    Esqueci minha senha
                  </ButtonLink>
                </Mensagem>
                {location?.pathname?.includes("login/cri") &&
                  getDomain() === "delta" && (
                    <Box fontWeight="700" fontSize="15px" textAlign="center">
                      Ainda não tem cadastro?
                      <Box
                        component="span"
                        ml={0.5}
                        borderBottom="1px solid  rgb(218, 163, 50)"
                        color="rgb(218, 163, 50)"
                        sx={{ cursor: "pointer" }}
                        onClick={() => primeiroCadastroCri()}
                      >
                        Cadastre-se
                      </Box>
                    </Box>
                  )}
                <Box className="mt-2">
                  {mensagemAlerta && (
                    <Alerta
                      {...mensagemAlerta}
                      onClose={() => setMensagemAlerta("")}
                    />
                  )}
                </Box>
                <Version>{`v. ${version}`}</Version>
              </Wrapper>
            </CardLogin>
          </Grid>
        </>
      )}
    </>
  );
};

export default Login;
