export default class ProjecaoDto {
  constructor(
    id,
    boletaId,
    unidadeConsumidoraId,
    mes,
    ano,
    consumoMaisPerdasMenosProinfa,
    montanteInferior,
    montanteSuperior,
    tipoProjecaoId,
    valorMaximo,
    projetadoDia
  ) {
    this.id = id;
    this.boletaId = boletaId;
    this.unidadeConsumidoraId = unidadeConsumidoraId;
    this.mes = mes;
    this.ano = ano;
    this.consumoMaisPerdasMenosProinfa = consumoMaisPerdasMenosProinfa;
    this.montanteInferior = montanteInferior;
    this.montanteSuperior = montanteSuperior;
    this.tipoProjecaoId = tipoProjecaoId;
    this.valorMaximo = valorMaximo;
    this.projetadoDia = projetadoDia;
  }

  static obterDeConversaoDinamica(
    projetadoMes,
    projetadoDia,
    unidadeConsumidoraId,
    mes,
    ano,
    tipoProjecaoId
  ) {
    return new ProjecaoDto(
      0,
      0,
      unidadeConsumidoraId,
      mes,
      ano,
      projetadoMes,
      0,
      0,
      tipoProjecaoId,
      0,
      projetadoDia
    );
  }
}
