import { Radio } from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";

export const RadioLuz = withStyles({
  root: {
    "&$checked": {
      color: "#E851B1 !important"
    }
  },
  checked: {}
  // eslint-disable-next-line react/jsx-props-no-spreading
})((props) => <Radio color="primary" {...props} />);
