import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { ListarMedicoes } from "servicos/medicaoServico";
import { buscarPorUnidade } from "servicos/medidoresServico";
import RetornoEndpointDto from "../../../global/dto/retornoEndpointDto";
import APIUtil from "../../../global/utils/apiUtil";

export default class ListagemMedicaoHelper {
  static async obterCodigosMedidorCCEE(unidadeId) {
    try {
      const lista = await buscarPorUnidade(unidadeId);

      return RetornoEndpointDto.SucessoSomenteDados(lista.data);
    } catch (error) {
      return APIUtil.tratarFalhaApi(
        error,
        "Ocorreu uma falha ao buscar os codigos medidor CCEE"
      );
    }
  }

  static async obterMedicoes(filtro, unidadeId, data) {
    try {
      const lista = await ListarMedicoes(filtro, unidadeId, data);

      return RetornoEndpointDto.SucessoSomenteDados(lista.data);
    } catch (error) {
      return APIUtil.tratarFalhaApi(
        error,
        "Ocorreu uma falha ao buscar as medições"
      );
    }
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem: mensagem ?? "Erro interno, entre em contato com o suporte!"
      })
    );
  }
}
