import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import { AppBar, Tabs, Tab, Card, Grid } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import SwipeableViews from 'react-swipeable-views';
import { useStyles, HtmlTooltip, Tabela } from './style';
import TabPanel from 'componentes/tabPanel';
import InputDataBranco from "componentes/inputTextoDataBranco";
import Loader from 'componentes/loader';
import { valoresCDEEnergia } from '../../../../servicos/cdeServico';
import CloseIcon from "@mui/icons-material/Close";
import RotasDTO from "../../../../rotas/rotasUrlDto";
import { useHistory } from "react-router-dom";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";

const CDEEnergiaVisualizacao = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const onFechar = (id) => {
    history.push(`${RotasDTO.ProjecaoInsumoListar}`);
  }
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => setValue(newValue);
  const handleChangeIndex = (index) => setValue(index);

  const [anoBase, setAnoBase] = useState(window.moment().toDate());
  const handleChangeAnoBase = (data) => setAnoBase(data);

  const [dispositivos, setDispositivos] = useState([]);
  const [isDispositivos, setIsDispositivos] = useState(false);
  const [sumario, setSumario] = useState([]);
  const [carregandoDados, setCarregandoDados] = useState(false);

  const obterDados = useCallback(async (data) => {
    try {
      const ano = moment(data).format("YYYY")
      const response = await valoresCDEEnergia(ano);
      setCarregandoDados(true);

      if (response.status === 200 && response?.data) {
          setIsDispositivos(true);
          setDispositivos(response?.data);
          setCarregandoDados(false);
      }
      else
      {
        setIsDispositivos(false);
        setDispositivos([]);
        setCarregandoDados(false);
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: `Não foram encontrados valores para o ano ${ano}`
          })
        );        
      }
    } catch (error) {
      setCarregandoDados(false);
    }
  }, []);

  useEffect(() => {
    if (anoBase) obterDados(anoBase)
  }, [anoBase, obterDados]);

  const [tableHeadSumario, setTableHeadSumario] = useState();
  const obterDadosCabecalho = useCallback(() => {
    sumario.map((item) => (
      item?.Sumario?.map((el) => {
        const lista = el?.Valores;
        const anos = lista?.filter((elemento, i) => lista?.indexOf(elemento) === i);
        return setTableHeadSumario(anos);
      })
    ))
  }, [sumario]);

  useEffect(() => {
    if (sumario) obterDadosCabecalho()
  }, [sumario, obterDadosCabecalho]);

  return <>
    <Grid container className={classes.containerTitulo}>
      <Grid item xs={11} className={classes.titulo}>
        Resoluções CDE Energia
      </Grid>
      <Grid item xs={1}>
        <CloseIcon onClick={onFechar} className={classes.icon} />
      </Grid>
    </Grid>
    <Grid container spacing={4} className={classes.container} justifyContent="flex-end" alignItems="center">
      <Grid item sm={2} className={classes.customSelect}>
        <InputDataBranco
          type="text"
          id="anoBase"
          name="anoBase"
          customValue={anoBase}
          onChange={(data) => handleChangeAnoBase(data)}
          format="yyyy"
          views={["year"]}
          renderIconShowHide={false}
        />
      </Grid>
    </Grid>
          <Grid container spacing={12} className={classes.container}>
            <Grid item sm={12}>
              <Loader loading={carregandoDados}>
              {isDispositivos === true ?
                <Tabela>
                  <Tabela.Thead>
                    <Tabela.Tr>
                          <Tabela.Th>Distribuidora</Tabela.Th>
                          <Tabela.Th>Dispositivo</Tabela.Th>
                          <Tabela.Th>Parcela</Tabela.Th>
                          <Tabela.Th>Valor Parcela</Tabela.Th>
                   </Tabela.Tr>
                  </Tabela.Thead>
                  <Tabela.Tbody>
                    {dispositivos?.map((item) => (
                        <Tabela.Tr>
                          <Tabela.Td>{item?.nomeFantasia}</Tabela.Td>
                          <Tabela.Td>{item?.dispositivo}</Tabela.Td>
                          <Tabela.Td>{item?.parcela}</Tabela.Td>
                          <Tabela.Td>{item?.valorParcela?.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</Tabela.Td>
                        </Tabela.Tr>
                    ))}
                  </Tabela.Tbody>
                </Tabela>
                  : ""}
              </Loader>
            </Grid>
          </Grid>
  </>;
}

export default CDEEnergiaVisualizacao;