/* eslint-disable react/no-unused-prop-types */
import React from "react";
import PropTypes from "prop-types";

import theme from "themes";
import { Box, StatusColor, Legenda } from "./style";

const BoxLegenda = React.forwardRef((props, ref) => {
  // eslint-disable-next-line react/prop-types
  const { legendas, statusColors, fontSize } = props;

  return (
    <Box ref={ref}>
      <StatusColor bgColor={statusColors} />
      <Legenda size={fontSize}>{legendas}</Legenda>
    </Box>
  );
});

BoxLegenda.propTypes = {
  id: PropTypes.string,
  color: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.any]),
  className: PropTypes.string,
  value: PropTypes.string
};

BoxLegenda.defaultProps = {
  id: "",
  color: theme.color.primary,
  style: {},
  className: "",
  value: ""
};

export default BoxLegenda;
