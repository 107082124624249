
const colunas = [
    {
        name: "Data Início Vigência",
        selector: (row) => row.dataInicioVigencia
    },
    {
        name: "Data Fim Vigência",
        selector: (row) => row.dataFimVigencia
    },
    {
        name: "Contribuição Associativa",
        selector: (row) => row.contribuicaoAssociativa
    },
    {
        name: "Cosip",
        selector: (row) => row.cosip
    },
    {
        name: "Custo de Conexão",
        selector: (row) => row.custoConexao
    },
    {
        name: "Encargos",
        selector: (row) => row.encargos
    },
    {
        name: "Custos Adicionais",
        selector: (row) => row.custosAdicionais
    },
    {
        name: "Margem Luz",
        selector: (row) => row.margemLuz
    }
];



export default colunas;
