import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";
import obterApiLuz from "./apiLuz";

const api = () => obterApi(MICROSERVICO.MEASUREMENT);
const apiLuz = (log) => obterApiLuz(MICROSERVICO.SITE_LUZ_LEAD, log);

export const getCancelamentoPaginado = async (unidadeId) => {
  return api().get(
    `v1/cancelamento/simulacoes-paginado?unidadeId=${unidadeId}&ultimoRegistro=true&pageIndex=1&pageSize=10`
  );
};

export const getCancelamentoSuspensao = async (id) => {
  return api().get(`v1/cancelamento/suspensao/${id}`);
};

export const getCancelamentoMotivos = async () => {
  return api().get("v1/motivoscancelamento/motivos?status=AT");
};

export const getCancelamento = async (unidadeId) => {
  return api().get(
    `v1/cancelamento/solicitacoes-paginado?unidadeId=${unidadeId}&ultimoRegistro=true&pageIndex=1&pageSize=10`
  );
};

export const postarCancelamentoSimulacao = async (dados) => {
  return api().post("v1/cancelamento/simulacao", dados);
};

export const postarCancelamento = async (dados) => {
  return api().post("v1/cancelamento/solicitacao", dados);
};

export const getCancelamentoCargoOfficer = async (unidadeId) => {
  return api().get(`v1/cancelamento/getcargoofficer?unidadeId=${unidadeId}`);
};

export const suspenderCancelamento = async (dados) => {
  return api().post("v1/cancelamento/suspensao", dados);
};

export const validarSuspenderCancelamento = async (params) => {
  const formData = new FormData();

  formData.append("arquivo", params.arquivo);

  return api().post(
    `v1/cancelamento/suspensao-upload-documento/${params.id}`,
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" }
    }
  );
};

export const getSupensaoCancelamento = async (id) => {
  return api().get(`v1/cancelamento/suspensao/${id}`);
};

export const postarDevolverCancelamento = async (dados) => {
  return api().post("v1/cancelamento/devolucao", dados);
};

export const getDevolucao = async (id) => {
  return api().get(
    `v1/cancelamento/devolucao-paginado?solicitacaoCancelamentoId=${id}&ultimoRegistro=true&pageIndex=1&pageSize=10`
  );
};

export const atualizarDevolverCancelamento = async (dados) => {
  return api().put("v1/cancelamento/devolucao", dados);
};

export const getStatusCancelamento = async (unidadeId) => {
  return api().get(`v1/cancelamento/rastreio-cancelamento/${unidadeId}`);
};

export const atualizarSFIntegracaoEnergiaChamado = async (dados) => {
  return apiLuz().put("v1/SFIntegracaoEnergia/Chamado", dados);
};

export const postCancelamentoMotivo = async (dados) => {
  return api().post("v1/motivoscancelamento/motivo", dados);
};

export const listarHistoricoCancelamento = async (filtro) => {
  const filtroQueryString = new URLSearchParams(filtro).toString();
  return api().get(`v1/cancelamento/historico?${filtroQueryString}`);
};

export const obterSuspensaoDocumento = async (id) => {
  return api()
    .post(`v1/cancelamento/suspensao-download-documento/${id}`)
    .then((resposta) => {
      return resposta?.data;
    });
};
