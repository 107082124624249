import { makeStyles } from '@mui/styles';
import styled from "styled-components";
import { Base } from 'componentes/cores';
import { createTheme } from "@mui/material/styles";

const theme = createTheme();

export const useStyles = makeStyles(() => ({
  container: {
    '& hr': {
      backgroundColor: "#fff",
      margin: theme.spacing(0, 0.5),
      width: "3px",
      height: "80px",

      '&::before': {
        content: "attr(data-df)",
        color: `${Base.White} !important`,
        float: "right",
        margin: "0 10px",
      },

      '&::after': {
        content: "attr(data-et)",
        color: `${Base.White} !important`,
        position: "absolute",
        margin: "0 10px",
      },
    },
  },
  mes: {
    minWidth: "80px",
    minHeight: "30px",
    paddingLeft: "10px",
    paddingRight: "10px",
    marginLeft: "3px",
    backgroundColor: "#32333b",
    color: "#fff",
    textAlign: "center",
    fontSize: "16px",
  },
  bandeira: {
    minHeight: "4px",
    paddingLeft: "10px",
    paddingRight: "10px",
    marginLeft: "3px",
    border: "none",
  },
  valor: {
    textAlign: "center",
    color: "#fff",
  },
  bandeiraCustom: {
    minHeight: "8px",
    paddingLeft: "10px",
    paddingRight: "10px",
    marginLeft: "3px",
    border: "none",
  },
  mesCustom: {
    minWidth: "80px",
    minHeight: "26px",
    paddingLeft: "10px",
    paddingRight: "10px",
    marginLeft: "3px",
    backgroundColor: "#32333b",
    color: "#fff",
    textAlign: "center",
    fontSize: "16px",
  },
}));

export const Alinhamento = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const BandeiraWrapper = styled.div``;

export const BandeiraMes = styled.div``;

export const BandeiraCor = styled.div``;

export const BandeiraValor = styled.div``;