import { makeStyles } from "@mui/styles";
import styled from "styled-components";
import { createTheme } from "@mui/material/styles";
import theme from "../../..";
import { Base } from "../../../../componentes/cores";

const th = createTheme();

export const useStyles = makeStyles(() => ({
  card: {
    backgroundColor: "#FFFFFF !important",
    boxShadow: "0px 2px 4px 1px #00000029 !important"
  },
  titulo: {
    color: theme.color.primary,
    fontFamily: theme.font.family,
    fontSize: "24px",
    fontWeight: 700,
    [th.breakpoints.down("sm")]: {
      fontSize: "20px"
    }
  },
  sombra: { boxShadow: "0 3px 10px rgb(0 0 0 / 0.2) !important" },
  texto: {
    color: "#4F4F4F",
    marginBottom: 0,
    fontSize: "16px"
  },
  textoRoxo: { color: theme.color.primary, fontSize: "24px", fontWeight: 700 },
  preco: { color: "#2ABF6F", fontSize: "24px", fontWeight: 700 },
  textoBox: {
    color: "#4F4F4F",
    marginBottom: 0,
    fontSize: "14px",
    fontWeight: 700
  },
  textoCompartilhar: {
    color: theme.color.primary,
    fontSize: "18px",
    fontWeight: 700,
    margin: 0,
    lineHeight: "27px"
  },
  iconeInfo: {
    color: theme.color.secondary
  },
  boxCompartilhar: {
    display: "flex",
    justifyContent: "center"
  },
  botaoCompartilhar: {
    paddingRight: "15px !important",
    maxWidth: "62px"
  },
  compartilharFooter: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "0 16px"
  },
  comoFunciona: {
    color: "#4F4F4F",
    fontSize: "20px",
    lineHeight: "24px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column"
  }
}));

export const Botao = styled.button`
  color: ${Base.White};
  background-color: ${theme.color.secondary};
  border-color: transparent;
  border-radius: 24px;
  font-weight: 600;
  font-size: 14px;
  padding: 4px 16px;
`;

export const BotaoTransparent = styled.button`
  color: #000000;
  background-color: transparent;
  border-color: transparent;
  color: ${theme.color.primary};
  padding: 0;
  width: 100%;
  text-align: end;
`;

export const IconeCompartilhar = styled.img`
  max-width: 100%;
  height: auto;
`;

export const BotaoCopiar = styled.button`
  box-shadow: 0px 2px 4px 1px #00000029;
  border-radius: 8px;
  color: ${theme.color.primary};
  background-color: transparent;
  border-color: transparent;
  color: ${theme.color.primary};
  font-size: 18px;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 400px;
  padding: 10px;
`;
