import { Box } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

const TituloPagina = ({ icon, titulo, alt }) => {
  return (
    <Box py={3}>
      <Box
        color="#332053"
        fontSize={{ xs: "15px", sm: "20px" }}
        fontWeight="700"
        display="flex"
        alignContent="center"
      >
        <Box component="img" src={icon} alt={alt} pr={1} />
        {titulo}
      </Box>
    </Box>
  );
};

TituloPagina.propTypes = {
  icon: PropTypes.string,
  titulo: PropTypes.string,
  alt: PropTypes.string
};

TituloPagina.defaultProps = {
  icon: "",
  titulo: "",
  alt: ""
};

export default TituloPagina;
