import { AnotacoesUsuariosListar, EfetividadePorGrupo, GrupoProjecoes, TarifaConsolidadaGrupo } from "../paginas";

export default {
    SeriesHistoricas: "/series-historicas",
    DistribuidorasLista: "/distribuidoras",
    BandeirasTarifariasAcionamento: "/bandeiras-tarifarias/acionamento",
    BandeirasTarifariasImportar: "/bandeiras-tarifarias/importar",
    BandeirasTarifariasCadastrar: "/bandeiras-tarifarias/cadastro",
    BandeirasTarifariasAtual: "/bandeiras-tarifarias/atual",
    BandeirasTarifariasHistoricas: "/bandeiras-tarifarias/historicas",
    BandeirasTarifariasFutura: "/bandeiras-tarifarias/futura",
    MercadoFaturado: "/mercado-faturado",
    DistribuidoraMercadoFaturado: "/mercado-faturado/distribuidora",
    DistribuidoraMercadoFaturadoConfiguracao: "/mercado-faturado/distribuidora/configuracao",
    RedeBasica: "/rede-basica",
    Cenarios: "/cenarios",
    CenariosCadastro: "/cadastro",
    CDEEnergiaVisualizar: "/cde-energia/visualizar",
    CDEEnergiaImportar: "/cde-energia/importar",
    CDEOrcamentoVisualizar: "/cde-orcamento/visualizar",
    CDEOrcamentoImportar: "/cde-orcamento/importar",
    PcatImportar: "/pcat/importar",
    PcatVisualizar: "/pcat/visualizar",
    BaseRateioProinfaVisualizar: "/rateio-proinfa/visualizar",
    BaseRateioProinfaImportar: "/rateio-proinfa/importar",
    BaseRateioEERVisualizar: "/rateio-eer/visualizar",
    BaseRateioEERImportar: "/rateio-eer/importar",
    BaseRateioESSVisualizar: "/rateio-ess/visualizar",
    BaseRateioESSImportar: "/rateio-ess/importar",
    ContratosEerImportar: "/contratos-eer/importar",
    ContratosEerVisualizar: "/contratos-eer/visualizar",
    ContratosProinfaImportar: "/contratos-proinfa/importar",
    ContratosProinfaVisualizar: "/contratos-proinfa/visualizar",
    ProretImportar: "/percentuais-regulatorios-proret/importar",
    ProretConsultar: "/percentuais-regulatorios-proret/visualizar",
    LeilaoCCEEVisualizar: "/leilao-ccee/visualizar",
    LeilaoCCEEImportar: "/leilao-ccee/importar",
    LeilaoSpartaVisualizar: "/leilao-sparta/visualizar",
    MercadoSpartaVisualizar: "/mercado-sparta/visualizar",SpartaImportar: "/sparta/importar",
    ReceitaAdicionalBandeiraVisualizar: "/receita-adicional-bandeira/visualizar",
    ReceitaAdicionalBandeiraImportar: "/receita-adicional-bandeira/importar",
    TermicasVisualizar: "/termicas/visualizar",
    TermicasImportar: "/termicas/importar",
    ContratosBilateraisImportar:"/contratos-bilaterais/importar",
    ContratosBilateraisVisualizar:"/contratos-bilaterais/visualizar",
    BaseItaipuImportar: "/base-itaipu/importar",
    BaseItaipuVisualizar:"/base-itaipu/visualizar",
    CotaAngraImportar:"/cotas-angra/importar",
    CotaAngraVisualizar:"/cotas-angra/visualizar",
    MigracaoeOutrosImportar: "/migracao-e-outros/importar",
    MigracaoeOutrosVisualizar: "/migracao-e-outros/visualizar",
    ESSANEELImportar: "/ess-aneel/importar",
    ESSANEELVisualizar: "/ess-aneel/visualizar",
    ESSMinimoImportar:"/ess-minimo/importar",
    ESSMinimoVisualizar: "/ess-minimo/visualizar",
    BaseEERImportar: "/base-eer/importar",
    BaseEERVisualizar: "/base-eer/visualizar",
    ProjecaoInsumoListar: "/importacoes",
    ProjecaoBrasilListar: "/projecao-brasil",
    ParametrosGlobaisConfiguracoes: "/parametrosGlobais/configuracoes",
    BaseGarantiaFisicaImportar: "/base-garantia-fisica/Importar",
    BaseGarantiaFisicaVisualizar: "/base-garantia-fisica/visualizar",
    VariaveisCDEImportar: "/variaveis-cde/importar",
    VariaveisCDEVisualizar: "/variaveis-cde/visualizar",
    ProjecaoTarifaria: "/projecao-tarifaria/distribuidora",
    ProjecaoTarifariaVisualizar: "/projecao-tarifaria/distribuidora/visualizar",
    ProjecaoTarifariaVisualizarDetalhes: "/projecao-tarifaria/distribuidora/visualizar/detalhes",
    DetalheSucesso: "/sucesso",
    DetalheFalha: "/falha",
    DetalheTarifa: "/tarifa",
    DetalheMercadoFaturado: "/mercado-faturado",
    ProjecaoCustosVisualizar: "/projecao-custos/visualizar",
    ProjecaoCustosImportar: "/projecao-custos/importar",
    RateioCDEImportar: "/rateio-cde/importar",
    RateioCDEVisualizar: "/rateio-cde/visualizar",
    SerieHistoricaCenarioListagem: "/series-historicas/listagem-cenario",
    ProjecaoFatorComposto: "/series-historicas/fator-composto/listar",
    ProjecaoFatorCompostoEditar: "/series-historicas/fator-composto/listar/editar",
    ComponenteQFatorXImportar:"/componente-q-fator-x/importar",
    ComponenteQFatorXVisualizar:"/componente-q-fator-x/visualizar",
    ValoresVigencia: "/bandeiras-tarifarias/valores",
    ValoresWACC: "/wacc/valores",
    ValoresWACCCadastro: "/wacc/valores/cadastro",
    ProjecaoInsumosRecortesVisualizar: "/recortes/visualizar",
    ProjecaoInsumosRecortesAlterar: "/recortes/alterar",
    ProjecaoInsumosRecortesAlterarItem: "/recortes/alterar/item",
    ImportarTarifaReferencia: "/tarifa-referencia/importar",
    VisualizarTarifaReferencia: "/tarifa-referencia/visualizar",
    AdicionaisCustoImportar: "/adicionais-custo/importar",
    AdicionaisCustoVisualizar: "/adicionais-custo/visualizar",
    MemoriaCalculo: "/memoria-calculo",
    GruposProjecoes: "/grupo-projecoes/listagem",
    GrupoProjecoes: "/grupo-projecoes/cadastro",
    ListaProjecoesDeGrupo: "/grupo-projecoes/projecoes",
    ExecucaoProjecaoListar: "/grupo-projecoes/execucao",
    GrupoProjecaoRelatorioListar: "/grupo-projecoes/relatorio",
    CvaImportar: "/cva/importar",
    CDEBrasilExportar: "/cde-brasil",
    TarifaConsolidadaGrupo: "/tarifa-consolidada-grupo",
    RevisaoParcelaB: "/revisao-parcela-b",
    CustosProjetados: "/custos-projetados",
    RelatorioFinanceiro: "/relatorio-financeiro",
    RelatorioRiscoHidrologico: "/relatorio-risco-hidrologico",
    ResumoEnergia: "/resumo-energia",
    MercadoGrupo: "/mercado-grupo",
    CustoTarifaGrupo: "/custo-tarifa-grupo",
    ExportarComposicaoEER: "/eer-brasil",
    EfetividadePorGrupo: "/efetividade-grupo",
    AnotacoesUsuariosListar: "/anotacoes-usuarios",
    AnotacoesUsuariosCadastrar: "/cadastrar-anotacao",
    CustoTarifaConsolidado: "/custos-consolidados",
    PMIX: "/pmix",
    ExportarProinfaGeral: "/proinfa-brasil",
    CDEOrcamentoBrasilVisualizar: "/cde-orcamento-brasil/visualizar",
    EERContratosBrasilVisualizar: "/contratos-eer-brasil/visualizar",
    ProinfaContratosBrasilVisualizar: "/contratos-proinfa-brasil/visualizar",
    ProinfaVigencias: "/vigencias-proinfa",
    PagamentoMensalCDEImportar: "/cde-pagamento/importar",
    PagamentoMensalCDEVisualizar: "/cde-pagamento/visualizar",
    TarifaMediaEfeitoConsumidor: "/efeito-consumidor-tarifa-media",
    VisualizarCVA: "/cva/visualizar",
    AjusteItaipuImportar: "/ajuste-itaipu/importar",
    AjusteItaipuVisualizar: "/ajuste-itaipu/visualizar",
    ReceitaBandeiraExportar: "/receita-bandeira",
    TarifaAbertaExportar: "/tarifa-aberta",
    ImpactoCustoDistribuidoraExportar: "/impacto-custo-distribuidora",
    ListarExportacoes: "/exportacoes",
    NivelContratacaoExportar: "/nivel-contratacao",
    SimulacaoEnergiaContratadaImportar: "/simulacao-energia-contratada/importar",
    EmprestimoImportar: "/emprestimo/importar",
    EncargoMigracaoImportar: "/encargo-migracao/importar",
    FatorMercadoNivelImportar: "/FatorMercadoNivel/importar",
    FatorMercadoNivelVisualizar: "/FatorMercadoNivel/visualizar",
    MercadoReferenciaImportar: "/MercadoReferencia/importar",
    MercadoReferenciaVisualizar: "/MercadoReferencia/visualizar",
    RateioCDEDivulgadoImportar: "/rateio-cde-divulgado/importar",
    RateioCDEDivulgadoVisualizar: "/rateio-cde-divulgado/visualizar"
}