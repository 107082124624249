import { Checkbox } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useStyles } from "./styles"

const CheckBoxLuz = ({ 
    nomeCard, 
    onChecked, 
    onName,
    checked,
}) => {
    const classes = useStyles();

    const handleChange = (event) => {
        onName(event.target.value);
        onChecked(event.target.checked);
	}
    

    return (
        <Checkbox
            id={nomeCard}
            value={nomeCard}
            checked={checked}
            onChange={handleChange}
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            inputProps={{ "aria-label": "decorative checkbox" }}
            
        />
    );

}

CheckBoxLuz.propTypes = {
    nomeCard: PropTypes.string,
    checked: PropTypes.bool,
    onChecked: PropTypes.oneOfType([PropTypes.func]),
    onName: PropTypes.oneOfType([PropTypes.func]),
}

CheckBoxLuz.defaultProps = {
    nomeCard: "",
    checked: false,
    onChecked: () => {},
    onName: () => {},
}

export default CheckBoxLuz;