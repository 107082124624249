import React from "react";
import { Box, Grid } from "@mui/material";

import Alert from "../../../../assets/consumoEnergia/alert.png";

const ConsumoNegativo = () => {
  return (
    <Grid item xs={12} md={12}>
      <Box
        bgcolor="rgba(244, 163, 48, 0.16)"
        border="4px dashed #F4A330"
        borderRadius="8px"
        pt={4}
        pb={6}
        p={{ sm: 2, xs: 2 }}
      >
        <Box component="div" display="flex" justifyContent="center">
          <Box component="img" src={Alert} />
        </Box>
        <Box
          component="div"
          textAlign="center"
          fontSize="20px"
          fontWeight="700"
          mb={6}
        >
          <Box component="span" color="#4F4F4F">
            <Box>Identificamos uma instabilidade nos seus dados. Em breve,</Box>
            <Box>atualizaremos seu consumo para consulta.</Box>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default ConsumoNegativo;
