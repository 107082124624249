import React from "react";
import { generate } from "shortid";

export default class ColunaComponentePersonalizado {
  constructor(
    field,
    headerName,
    Componente,
    onClick,
    sortable = false,
    compact = false,
    maxWidth = null,
    minWidth = null,
    className = "",
    selecionadoId = null,
    enabled = false
  ) {
    this.selector = field;
    this.name = headerName;
    this.sortable = sortable;
    this.maxWidth = maxWidth;
    this.minWidth = minWidth;
    this.compact = compact;
    this.selecionadoId = selecionadoId;
    this.enabled = enabled;
    this.cell = (params) => {
      return (
        <Componente
          key={generate()}
          className={className}
          parametros={params}
          onClick={onClick}
          enabled={params.id === selecionadoId && enabled}
        />
      );
    };
  }
}
