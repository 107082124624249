import { makeStyles } from "@mui/styles";
import { Base } from "componentes/cores";
import theme from "themes";

import ImagemPrincipal from "../../../../assets/jornadaCliente/imagem-principal.jpg";

export const useStyles = makeStyles(() => ({
  containerImage: {
    background: `url(${ImagemPrincipal}) center center no-repeat`,
    backgroundSize: "cover",
    width: "1020px",
    height: "746px",
    position: "relative"
  },
  boxTexto: {
    position: "absolute",
    left: 80,
    bottom: 60,
    width: "480px",
    "& h1": {
      color: Base.White,
      fontSize: "61px",
      fontFamily: `${theme.font.family}  !important`,
      fontWeight: 700,
      marginBottom: 0
    },
    "& h2": {
      color: Base.White,
      fontSize: "37px",
      fontFamily: `${theme.font.family}  !important`,
      fontWeight: 700
    }
  },
  iconeLista: {
    position: "absolute",
    left: 0,
    bottom: 140,
    width: "20px"
  },
  iconeLuz: {
    position: "absolute",
    left: 80,
    top: 100,
    width: "150px"
  }
}));
