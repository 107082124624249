import React from "react";
import PropTypes from "prop-types";
import { generate } from "shortid";

import { Grid, Button } from "@mui/material";
import { EditOutlined, TrackChanges } from "@mui/icons-material";

import { HtmlTooltip } from "paginas/Graficos/ConsumoAtual/style";
import { useStyles } from "./style";
import enumeradorBotao from "../enumeradores/enumeradorBotao";

const ColunaMultiplosBotoes = ({ onClick, parametros }) => {
  const classes = useStyles();

  const onClickEditar = () => onClick(enumeradorBotao.Edicao, parametros.id);
  const onClickRastrear = () => onClick(enumeradorBotao.Rastreamento, parametros.id);

  return (
    <Grid container spacing={0} className={classes.fundoCelula}>

      <Grid item xs={6}>
        <Button
          key={generate()}
          onClick={onClickEditar}
          className={`text-white ${classes.botao}`}
        >
          <HtmlTooltip title="Editar">
            <EditOutlined />
          </HtmlTooltip>
        </Button>
      </Grid>

      <Grid item xs={6}>
        <Button
          key={generate()}
          onClick={onClickRastrear}
          className={`text-white ${classes.botao}`}
        >
          <HtmlTooltip title="Rastrear">
            <TrackChanges />
          </HtmlTooltip>
        </Button>
      </Grid>

    </Grid>
  );
};

ColunaMultiplosBotoes.propTypes = {
  onClick: PropTypes.oneOfType([PropTypes.func]).isRequired,
  parametros: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired
};

export default ColunaMultiplosBotoes;