import { makeStyles } from "@mui/styles";
import { Base } from "componentes/cores";
import themes from "../..";

export const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: `${Base.WhiteLuz} !important`,
    border: `1px solid  ${Base.TextLuzOpacity} !important`,
    borderRadius: "16px !important",
    boxShadow: "0px 2px 4px 1px rgba(0, 0, 0, 0.16)",
    outline: "none",
    position: "absolute",
    padding: "0 !important",
    transform: "translate(-50%, -50%) !important",
    top: "50% !important",
    left: "50% !important",
    color: themes.color.thirdText
  },
  header: {
    borderBottom: "1px solid #D2D2D7",
    padding: "16px 16px 16px 16px"
  },
  footer: {
    borderTop: "1px solid #D2D2D7",
    padding: "16px 16px 32px 16px"
  },
  close: {
    color: themes.color.secondary
  }
}));
