import React, { useEffect, useState } from "react";

// Form Hooks
import { useForm, useWatch } from "react-hook-form";

// Componentes
import { Grid } from "@mui/material";
import MaterialInputBusca from "componentes/inputBusca";
import Funcionalidades from "./componentes/funcionalidades";

// Styles
import { useStyles } from "./style";

// Redux
import { store } from "../../../global/redux";
import { desabilitarFiltroLateral } from "../../../global/redux/modulos/usuario/actions";
import CheckInputs from "../../../componentes/checkInputs";

const FuncionalidadeCadastro = () => {
  const classes = useStyles();
  const { register, control } = useForm({
    reValidateMode: "onSubmit"
  });

  const textoBusca = useWatch({
    control,
    name: "textoBusca",
    defaultValue: ""
  });

  const [nodeEdicao, setNodeEdicao] = useState({});
  const [checked, setChecked] = useState(false);

  const onEditar = (node) => {
    setNodeEdicao(node);
  };

  useEffect(() => {
    store.dispatch(desabilitarFiltroLateral(true));

    return () => {
      store.dispatch(desabilitarFiltroLateral(false));
    };
  }, [desabilitarFiltroLateral]);

  return (
    <Grid
      container
      spacing={0}
      className={classes.container}
      alignItems="center"
      justifyContent="space-between"
    >
      <Grid item lg={5} className="mb-4">
        <MaterialInputBusca
          type="text"
          id="textoBusca"
          name="textoBusca"
          label="Buscar"
          renderIconShowHide
          searchAdornment
          defaultValue={textoBusca ?? ""}
          ref={register}
          permiteValorBranco
        />
      </Grid>
      <Grid item lg={5} className="d-flex justify-content-end">
        <ul className={classes.filter}>
          <li>
            <CheckInputs
              setChecked={setChecked}
              checked={checked}
              label="Ordenar Lista"
              name="ordenarLista"
              id="ordenarLista"
            />
          </li>
          <li>
            <div className="icon" />
            <div className="texto">Setor</div>
          </li>
          <li>
            <div className="icon" />
            <div className="texto">Segmento</div>
          </li>
          <li>
            <div className="icon" />
            <div className="texto">Interface</div>
          </li>
        </ul>
      </Grid>
      <Funcionalidades
        onBuscar={textoBusca ?? ""}
        onEditar={(node) => onEditar(node)}
        nodeEdicao={nodeEdicao}
        register={register}
        checked={checked}
      />
    </Grid>
  );
};

export default FuncionalidadeCadastro;
