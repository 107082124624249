import MICROSERVICO from "global/enumeradores/microServico";
import obterApi from "servicos/api";

const api = () => obterApi(MICROSERVICO.PROJECAOTARIFARIA);

export const uploadArquivoSerieHistorica = async (id, arquivo, idUsuario, nomeUsuario) => {
  const data = new FormData();
  data.append("file", arquivo);

  const metodo = "post";
  let url = `v1/SerieHistoricaValor?idcenario=${id}&idUsuario=${idUsuario}&NomeUsuario=${nomeUsuario}`;
  url = `${url}${id}`;
  return api()[metodo](url, data);
};