import { makeStyles } from "@mui/styles";
import styled from "styled-components";
import { createTheme } from "@mui/material/styles";
import theme from "../../..";
import { Base } from "../../../../componentes/cores";

const th = createTheme();

export const useStyles = makeStyles(() => ({
  titulo: {
    color: theme.color.primary,
    fontFamily: theme.font.family,
    fontSize: "24px",
    fontWeight: 700,
    [th.breakpoints.down("sm")]: {
      fontSize: "20px"
    }
  },
  textoCentral: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    "& p": {
      margin: 0,
      fontFamily: theme.font.family,
      fontSize: "14px"
    },
    "& h2": {
      color: theme.color.primary,
      fontFamily: theme.font.family,
      fontWeight: 700,
      fontSize: "24px",
      margin: 0
    },
    "& h3": {
      color: theme.color.primary,
      fontFamily: theme.font.family,
      fontWeight: 700,
      fontSize: "16px",
      margin: 0
    }
  },
  semDados: {
    minHeight: "310px",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    "& h4": {
      color: "#4F4F4F",
      fontSize: "18px",
      fontWeight: 700
    }
  }
}));

export const Botao = styled.button`
  color: ${Base.White};
  background-color: ${theme.color.secondary};
  border-color: transparent;
  border-radius: 24px;
  font-weight: 600;
  font-size: 14px;
  padding: 4px 16px;
`;

export const IconeSemDados = styled.img`
  height: 50px;
  margin-right: 10px;
  margin-bottom: 15px;
`;
