/* eslint-disable no-bitwise */
import React from "react";
import PropTypes from "prop-types";

import { Grid, Button } from "@mui/material";
import { Timeline } from "@mui/icons-material";

import RotasDTO  from "../../../../rotas/rotasUrlDto";  

import { useHistory, useParams } from "react-router-dom";
import { useStyles } from "./style";


const ColunaDetalhes = ({ parametros }) => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();


  const parametroMercadoFaturadoComSucesso = parametros.TipoComponente === "Mercado" && parametros.Status === "SUCESSO";
  const parametroTarifaComSucesso = parametros.TipoComponente === "Tarifa" && parametros.Status === "SUCESSO";
  const parametroCustoComSucesso = parametros.TipoComponente === "Custo" && parametros.Status === "SUCESSO";
  const parametroReceitaComSucesso = parametros.TipoComponente === "Receita" && parametros.Status === "SUCESSO";

  const parametroPendente = parametros.Status === "Pendente"

  const onClickGoToDetail = () => {
    if (parametroCustoComSucesso || parametroReceitaComSucesso) {
      history.push(`${RotasDTO.ProjecaoTarifaria}/visualizar/detalhes/${id}${RotasDTO.DetalheSucesso}/${parametros.CodigoEtapa}/${parametros.Titulo}`)
    } 
    else if (parametroMercadoFaturadoComSucesso){
      history.push(`${RotasDTO.ProjecaoTarifaria}/visualizar/detalhes/${id}${RotasDTO.DetalheMercadoFaturado}/${parametros.CodigoEtapa}`)
    } 
    else if (parametroTarifaComSucesso){
      history.push(`${RotasDTO.ProjecaoTarifaria}/visualizar/detalhes/${id}${RotasDTO.DetalheTarifa}/${parametros.CodigoEtapa}`)
    } 
    else {
      history.push(`${RotasDTO.ProjecaoTarifaria}/visualizar/detalhes/${id}${RotasDTO.DetalheFalha}/${parametros.CodigoEtapa}/${parametros.Titulo}`)
    }
  };

  return (

    <Grid container spacing={0} className={classes.fundoCelula}>
      <Grid item xs={12}>
        {!parametroPendente && (
          <Button
            onClick={() => {
              onClickGoToDetail()
            }}
            className={`text-white ${classes.botao}`}
          >
            <Timeline />
          </Button>
        )}        
      </Grid>
    </Grid>
  );
};

ColunaDetalhes.propTypes = {
  parametros: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired
};

export default ColunaDetalhes;
