import { makeStyles } from "@mui/styles";
import theme from "themes";

export const useStyles = makeStyles(() => ({
  conteudo: {
    padding: "10px 0px"
  },
  representanteTitulo: {
    fontFamily: theme.font.family,
    fontSize: "18px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.4px"
  },
  icone: {
    width: "24px",
    height: "24px",
    backgroundColor: "transparent",
    cursor: "pointer",
    marginLeft: "10px"
  }
}));
