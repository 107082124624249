import { extendMoment } from "moment-range";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { DefaultEditor } from "react-simple-wysiwyg";

// Form Hooks
import { useForm } from "react-hook-form";

// Componentes
import { Card, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import BotaoRetornarListagem from "../../../../componentes/botaoRetornarListagem";
import InputData from "../../../../componentes/inputTextoData";
import Loader from "../../../../componentes/loader";
import SelectArredondado from "../../../../componentes/selectArredondado";

// Serviços
import { listarEmpresasGrupoDeltaCombo } from "../../../../servicos/empresaServico";
import { listarProdutos } from "../../../../servicos/produtosServico";
import {
  obterTermoPorId,
  salvarTermo
} from "../../../../servicos/termoDeUsoServico";

// Styles
import { useStyles } from "./style";

// Redux
import { store } from "../../../../global/redux";
import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";

// DTOs
import BotaoForm from "../../../../componentes/botaoForm";
import { RotasDTO } from "../../../../global/rotas/rotasUrlDto";

const CadastroTermo = () => {
  const moment = extendMoment(window.moment);
  const usuario = useSelector((state) => state.usuario);
  const { id } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm({
    mode: "onChange"
  });

  const [dataInicioVigencia, setDataInicioVigencia] = useState(null);
  const [dataFimVigencia, setDataFimVigencia] = useState(null);
  const [empresaId, setEmpresaId] = useState();
  const [produtoId, setProdutoId] = useState();

  const [carregandoProduto, setCarregandoProduto] = useState(false);
  const [carregandoEmpresa, setCarregandoEmpresa] = useState(false);

  const [produtoLista, setProdutoLista] = useState([]);
  const [listaEmpresa, setListaEmpresa] = useState([]);

  const [htmlFormatado, setHtmlFormato] = useState("");

  useEffect(() => {
    obterEmpresa();
    obterProduto();
  }, []);

  const obterProduto = async () => {
    try {
      setCarregandoProduto(true);
      const lista = await listarProdutos();

      if (lista?.status === 200 && lista?.data?.produtos) {
        setProdutoLista(lista?.data.produtos ?? []);
      }
      setCarregandoProduto(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoProduto(false);
    }
  };

  const onChangeProdutoId = (valor) => {
    setProdutoId(valor.target.value);
  };

  const obterEmpresa = async () => {
    try {
      setCarregandoEmpresa(true);
      const lista = await listarEmpresasGrupoDeltaCombo(usuario?.token);

      if (lista?.status === 200 && lista?.data.empresasGrupoDelta) {
        setListaEmpresa(lista?.data.empresasGrupoDelta ?? []);
      }
      setCarregandoEmpresa(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoEmpresa(false);
    }
  };

  const onChangeEmpresaId = (valor) => {
    setEmpresaId(valor.target.value);
  };

  const [carregandoSalvar, setCarregandoSalvar] = useState(false);

  const enviarFormulario = async () => {
    try {
      setCarregandoSalvar(true);

      const model = {
        IdEmpresa: Number(empresaId),
        IdProduto: Number(produtoId),
        DataInicioVigencia: moment(dataInicioVigencia).format("yyyy-MM-DD"),
        DataFimVigencia: moment(dataFimVigencia).format("yyyy-MM-DD"),
        Descricao: htmlFormatado
      };

      const salvou = await salvarTermo(id ?? 0, model);

      if (salvou?.status === 200 || salvou?.status === 204) {
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: "Termo salvo com sucesso!"
          })
        );
        setTimeout(() => {
          history.push(RotasDTO.Termos);
        }, 1000);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: error?.response?.data?.message
        })
      );
    } finally {
      setCarregandoSalvar(false);
    }
  };

  const aoEnviarFormulario = (dados) => {
    enviarFormulario(dados);
  };

  // Obter Boleta Por Id
  const obterTermo = useCallback(async (termoId) => {
    try {
      const termo = await obterTermoPorId(termoId);
      if (termo?.status === 200 && termo?.data) {
        if (!termo.data) return;

        setEmpresaId(termo?.data?.idEmpresa);
        setProdutoId(termo?.data?.idProduto);
        setHtmlFormato(termo?.data?.descricao);
        setDataInicioVigencia(termo?.data?.dataInicioVigencia);
        setDataFimVigencia(termo?.data?.dataFimVigencia);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  }, []);

  useEffect(() => {
    if (id) obterTermo(id);
  }, [id]);

  const handleHtmlFormatado = (valor) => {
    setHtmlFormato(valor?.target?.value);
  };

  return (
    <Loader loading={carregandoSalvar}>
      <Card className={classes.cardCadastro}>
        <form
          id="hook-form"
          className="needs-validation"
          onSubmit={handleSubmit(aoEnviarFormulario)}
        >
          <Grid container p={2} spacing={4} className={classes.container}>
            <Grid item lg={6} xs={6} className="font-weight-bold">
              {id ? "Editar" : "Novo"} termo
            </Grid>
            <BotaoRetornarListagem urlListagem={RotasDTO.Termos} zerarCombo />
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <Loader loading={carregandoEmpresa}>
                <SelectArredondado
                  id="empresaId"
                  name="empresaId"
                  valueKey="id"
                  valueName="nomeFantasia"
                  dataSource={listaEmpresa}
                  label="Empresa"
                  value={empresaId}
                  placeholder="Empresa"
                  allowClear
                  onChange={(valor) => {
                    onChangeEmpresaId(valor);
                  }}
                  ref={register(
                    { name: "empresaId" },
                    {
                      required: !empresaId
                        ? "Campo Empresa é obrigatório!"
                        : false
                    }
                  )}
                  errors={errors}
                />
              </Loader>
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <Loader loading={carregandoProduto}>
                <SelectArredondado
                  id="produtoId"
                  name="produtoId"
                  valueKey="id"
                  valueName="descricao"
                  dataSource={produtoLista}
                  label="Produto"
                  value={produtoId}
                  placeholder="Produto"
                  allowClear
                  onChange={(valor) => {
                    onChangeProdutoId(valor);
                  }}
                  ref={register(
                    { name: "produtoId" },
                    {
                      required: !produtoId
                        ? "Campo Produto é obrigatório!"
                        : false
                    }
                  )}
                  errors={errors}
                />
              </Loader>
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <InputData
                type="text"
                id="dataInicioVigencia"
                name="dataInicioVigencia"
                label="Data início vigência"
                customValue={dataInicioVigencia}
                minDate={moment().startOf("day").toDate()}
                onChange={(data) => setDataInicioVigencia(data)}
                renderIconShowHide={false}
                ref={register({
                  required: "Campo Data início vigência é obrigatório!",
                  validate: (data) =>
                    (moment(data, "DD/MM/YYYY").isValid() &&
                      moment(data, "DD/MM/YYYY") <=
                        moment(moment().startOf("day"), "DD/MM/YYYY")) ||
                    "Data início vigência inválida!"
                })}
                errors={errors}
                className={classes}
                InputProps={{ readOnly: false }}
                variant="inline"
                autoOk
              />
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <InputData
                type="text"
                id="dataFimVigencia"
                name="dataFimVigencia"
                label="Data fim vigência"
                customValue={dataFimVigencia}
                minDate={moment().startOf("day").toDate()}
                onChange={(data) => setDataFimVigencia(data)}
                renderIconShowHide={false}
                ref={register({
                  required: "Campo Data fim vigência é obrigatório!",
                  validate: (data) =>
                    (moment(data, "DD/MM/YYYY").isValid() &&
                      moment(data, "DD/MM/YYYY") >=
                        moment(moment().startOf("day"), "DD/MM/YYYY")) ||
                    "Data fim vigência inválida!"
                })}
                errors={errors}
                className={classes}
                InputProps={{ readOnly: false }}
                variant="inline"
                autoOk
              />
            </Grid>
          </Grid>
        </form>
        <Grid container p={2} spacing={4} className={classes.container}>
          <Grid item lg={12} md={2} sm={12} xs={12}>
            <DefaultEditor
              value={htmlFormatado}
              onChange={(valor) => handleHtmlFormatado(valor)}
            />
          </Grid>
        </Grid>
      </Card>

      <Grid container p={2} spacing={4} className={classes.container}>
        <Grid
          item
          lg={3}
          md={3}
          sm={6}
          xs={12}
          className={`mt-4 ml-0 ${classes.containerSalvar}`}
        >
          <BotaoForm
            form="hook-form"
            type="submit"
            label="Salvar"
            className={classes.button}
          />
        </Grid>
      </Grid>
    </Loader>
  );
};

export default CadastroTermo;
