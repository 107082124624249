import styled from "styled-components";
import { makeStyles } from "@mui/styles";
import { createTheme } from "react-data-table-component";
import theme from "themes";
import { Base } from "../../../../componentes/cores";

export const useStyles = makeStyles({
  container: {
    margin: "0",
    width: "100%",
    "& .MuiInputBase-root": {
      height: "46px"
    },
    "& .MuiFormLabel-root": {
      lineHeight: "1"
    }
  },
  button: {
    fontSize: "18px"
  },
  trace: {
    width: "112px",
    height: "47px",
    borderBottom: "1px solid black",
    position: "absolute"
  },
  inputTelefone: {
    "&.Mui-error": {
      borderColor: Base.RedError,
      color: Base.RedError
    }
  },
  containerTelefone: {
    "& .MuiInputBase-input": {
      height: "7px"
    }
  },
  containerSalvar: {
    margin: "16px 16px 32px"
  },
  divider: {
    margin: "8px 18px 16px"
  },
  item: {
    display: "flex",
    justifyContent: "flex-end"
  },
  icon: {
    cursor: "pointer"
  },
  cardCadastro: {
    backgroundColor: theme.color.bgColorCardCadastro
  }
});

export const customStyles = {
  Container: {
    alignItems: "center",
    background: theme.color.thirdBackgroud,
    color: "#fff",
    display: "flex",
    height: "300px",
    justifyContent: "center",
    marginBottom: "2em",
    marginTop: "0",
    maxWidth: "100%",
    opacity: "1"
  },
  table: {
    style: {
      color: "#fff",
      opacity: "1"
    }
  },
  headCells: {
    style: {
      background: theme.color.thirdBackgroud,
      fontSize: "16px",
      marginLeft: "3px",
      "&:first-child": {
        marginLeft: "0"
      },
      "& div": {
        "&:hover": {
          color: `${theme.color.secondaryBorderColor} !important`,
          opacity: "1"
        }
      }
    }
  },
  rows: {
    style: {
      color: "#fff",
      marginBottom: "1px",
      marginTop: "2px",
      opacity: "1"
    }
  },
  Pagination: {
    styles: {
      color: `${theme.color.secondaryBorderColor} !important`
    }
  },
  cells: {
    style: {
      background: theme.color.thirdBackgroud,
      color: theme.color.textoBarra,
      fontSize: "14px",
      marginLeft: "3px",
      opacity: "1",
      "&:first-child": {
        marginLeft: "0"
      }
    }
  }
};

export const TabelaSemDados = styled.span`
  background-color: ${theme.color.thirdBackgroud};
  margin-top: 3px;
  padding: 8px;
  text-align: left;
  width: 100%;
`;

createTheme({
  background: {
    default: "transparent"
  },
  text: {
    primary: theme.color.secondaryText,
    secondary: theme.color.secondaryText
  },
  action: {
    hover: theme.color.secondaryBorderColor
  }
});
