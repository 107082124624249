import styled from "styled-components";

export const ModalContent = styled.div`
  background-color: white;
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  color: black;
  text-align: center;
  z-index: 1100;
`;

export const Tutorial = styled.div`
  position: relative;
  width: 100%;
  max-height: 100vh;
  overflow: auto;
`;

export const Img = styled.img`
  width: 100%;
  height: auto;
`;

export const Icone = styled.img`
  max-width: 50px;
  height: auto;
`;

export const BoxPasso = styled.div`
  background-color: #332053;
  width: 100%;
  height: 100%;
  margin-top: -120px;
  z-index: 1;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  padding: 20px;
  position: fixed;

  h1 {
    font-family: Tahoma;
    font-size: 20px;
    font-weight: 700;
    color: #ffffff;
    line-height: 24px;
    margin-bottom: 20px;
  }

  button {
    text-decoration: none;
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    padding: 10px 36px;
    border-radius: 24px;
    color: white;
    background: #fa3eb1;
    border: none;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;

    &.transparent {
      background-color: transparent;
      color: #fa3eb1;
      text-decoration: underline;
    }
  }
`;

export const Passo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  &.ml {
    margin-left: 3px;
  }

  img {
    &.mr-1 {
      padding-right: 5px;
    }

    &.mr-2 {
      padding-right: 10px;
    }
  }

  p {
    font-family: Tahoma;
    font-style: normal;
    color: #ffffff;
    font-size: 16px;
    line-height: 20px;
    text-align: left;
  }
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  .ddv-capture-viewer-autoDetectButton,
  .ddv-capture-viewer-autoCaptureButton {
    background: transparent !important;
  }
`;

export const Overlay = styled.div`
  position: absolute;
  top: 15px;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0 auto;
  --b: 3px; /* thickness of the border */
  --c: red; /* color of the border */
  --w: calc(50% - 10px); /* width of border */

  &:before {
    content: "";
    position: absolute;
    inset: 0;
    background: var(--c, red);
    --_g: #0000 90deg, #000 0;
    --_p: var(--w) var(--w) no-repeat;
    --mask: conic-gradient(from 90deg at top var(--b) left var(--b), var(--_g))
        0 0 / var(--_p),
      conic-gradient(from 180deg at top var(--b) right var(--b), var(--_g)) 100%
        0 / var(--_p),
      conic-gradient(from 0deg at bottom var(--b) left var(--b), var(--_g)) 0
        100% / var(--_p),
      conic-gradient(from -90deg at bottom var(--b) right var(--b), var(--_g))
        100% 100% / var(--_p);
    -webkit-mask: var(--mask);
    mask: var(--mask);
  }
`;

export const BoxButton = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(51, 32, 83, 0.2);
  padding: 5%;
  border-radius: 20px;
`;

export const Button = styled.button`
  text-decoration: none;
  font-size: 16px;
  line-height: 14px;
  font-weight: 700;
  padding: 5px 26px;
  border-radius: 24px;
  color: white;
  background: #fa3eb1;
  border: none;
  margin-left: 10px;
  margin-right: 10px;

  &.transparent {
    background-color: transparent;
    color: #fa3eb1;
    text-decoration: underline;
  }
`;

export const Texto = styled.p`
  text-decoration: none;
  font-size: 18px;
  line-height: 14px;
  font-weight: 700;
  padding: 2px 10px;
  margin-bottom: 2px;
  border: none;
  display: block;
`;

export const Previa = styled.img`
  width: 100%;
  height: auto;
  display: block;
`;

export const Area = styled.div`
  width: 100%;
  height: 80%;
  display: block;
`;
