/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from "react";
import { Grid, FormControl, Typography } from "@mui/material";
import Loader from "componentes/loader";
import DataTable from "react-data-table-component";
import Coluna from "componentes/tabelaPaginada/colunas/coluna";
import MaterialInputTexto from "componentes/inputTexto/materialInput";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { useHistory, useParams } from "react-router-dom";
import { getDetalhesSucesso } from "submodules/DeltaTarifa/servicos/projecaoTarifaria";
import BotaoRetornarListagem from "componentes/botaoRetornarListagem";
import { Base } from "componentes/cores";
import { Botao } from "componentes";
import { useStyles, customStyles } from "./style";
import RotasDTO  from "../../../../rotas/rotasUrlDto";
import moment from "moment";

const DetalheSucesso = () => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);

    const history = useHistory();
    const {id, etapa, titulo} = useParams();
    
    const [tituloProjecao, setTituloProjecao] = useState("")
    const [cenario, setCenario] = useState("")
    const [dataProjecao, setDataProjecao] = useState("")
    const [distribuidora, setDistribuidora] = useState("")

    const [detalhesTabela, setDetalhesTabela] = useState([]);

    const [msgAguarde, setMsgAguarde] = useState(<Typography 
        style={{color: Base.OffWhite, paddingTop:"100px", paddingLeft:"42%"}}>Aguarde a carga dos dados...</Typography>)


    const colunasDetalhes = [
        new Coluna("titulo", "Titulo", false),
        new Coluna("ano", "Ano", false, "60px"),
        new Coluna("energia", "Energia MWh", false, "220px"),
        new Coluna("custo", "Custo R$", false, "230px"),
        new Coluna("tarifa", "Custo Médio R$/MWh", false, "170px", "170px"),
    ];

    const listarDetalhes = async () => {
        try {
            await getDetalhesSucesso(id, etapa).then((response) => {
                if (response.status === 200 && response.data) {
                    setTituloProjecao(response.data.projecao);
                    setCenario(response.data.cenario);
                    setDataProjecao(moment(response.data.dataProjecao).format("LLL"));
                    setDistribuidora(response.data.distribuidora)
                    setDetalhesTabela(response.data.etapas.map(etapa => {
                        let returnvalue = {...etapa};

                        if(!isNaN(returnvalue.energia))
                        {
                            returnvalue.energia = parseFloat(etapa.energia).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                        }

                        returnvalue.custo = etapa.custo.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                        
                        if(!isNaN(returnvalue.tarifa))
                        {

                            returnvalue.tarifa = parseFloat(etapa.tarifa).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                        }

                        return returnvalue;
                    
                    }));

                } else {
                    store.dispatch(
                        alertaExibir({
                            tipo: "warning",
                            mensagem:
                                "Erro ao buscar detalhes da etapa!"
                        })
                    );
                }
            });
        } catch (error) {
            store.dispatch(
                alertaExibir({
                    tipo: "warning",
                    mensagem:
                        "Erro ao buscar detalhes da etapa!"
                })
            );
        }
    }

    useEffect(() => {
        setIsLoading(true);
        listarDetalhes();
        setIsLoading(false);
        setMsgAguarde();
    }, [])

    return (
        <Grid container style={{ paddingLeft: "1.5em", paddingRight: "1.5em" }}>
            <Grid item xs={8} style={{ marginBottom: 20 }}>
                <h4 style={{color: "white"}}>{tituloProjecao}</h4>
                <h5 style={{color: "white"}}>{titulo}</h5>
            </Grid>
            <Grid item xs={3}>
                <Botao
                    type="submit"
                    label="Memória de Cálculo"
                    color={Base.Mango}
                    className={classes.button}
                    onClick={() => {
                        history.push(`${RotasDTO.ProjecaoTarifaria}/visualizar/detalhes/${id}${RotasDTO.DetalheSucesso}/${etapa}/${titulo}${RotasDTO.MemoriaCalculo}`)
                    }}
                />
            </Grid>
            <Grid item xs={1} className={classes.backButton}>
                <BotaoRetornarListagem voltarPagina />
            </Grid>
            <Grid container spacing={3} alignItems="center">
                <Grid item xs={4}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <MaterialInputTexto
                            fullWidth
                            type="text"
                            id="distribuidora"
                            name="distribuidora"
                            label="Distribuidora"
                            renderIconShowHide={false}
                            defaultValue={distribuidora}
                            disabled
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={4}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <MaterialInputTexto
                            fullWidth
                            type="text"
                            id="cenario"
                            name="cenario"
                            label="Cenario"
                            disabled
                            renderIconShowHide={false}
                            defaultValue={cenario}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={4}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <MaterialInputTexto
                            fullWidth
                            type="text"
                            id="data"
                            name="data"
                            label="Realizada em"
                            renderIconShowHide={false}
                            defaultValue={dataProjecao}
                        />
                    </FormControl>
                </Grid>
            </Grid>

            <Grid container item xs={12} spacing={3} justifyContent="center" className="w-100">
                <Loader loading={isLoading} className="w-100">
                    {msgAguarde}
                </Loader>
            </Grid>   

            {detalhesTabela.length > 0 ?
            <Grid container spacing={3} alignItems="center">
                <Grid item xs={12}>
                        <DataTable
                            subHeader
                            subHeaderAlign='right'
                            columns={colunasDetalhes}
                            data={detalhesTabela}
                            theme="DeltaEnergia"
                            customStyles={customStyles}
                            noDataComponent="Nenhum registro encontrado"
                            noHeader
                        />
                </Grid>
            </Grid>
            :
            ""
            }
        </Grid>
    );
}

export default DetalheSucesso;
