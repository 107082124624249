import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";

// Redux
import { useSelector } from "react-redux";

// Componentes
import Componente from "../../componentes/conteudo/componente";

const RotaAutenticadaNaoEstruturada = ({ rota }) => {
  const usuario = useSelector((state) => state.usuario);

  if (usuario?.token && rota.autenticada && !rota.estruturada) {
    return <Componente rota={rota} />;
  }
  return <Redirect to="/" />;
};

RotaAutenticadaNaoEstruturada.propTypes = {
  rota: PropTypes.oneOfType([PropTypes.any]).isRequired
};

export default RotaAutenticadaNaoEstruturada;
