import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// Componentes
import { Grid, Box, Card } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { getTelemetriaResumo } from "servicos/temetriaGDServico";
import Loader from "componentes/loader";
import Consumo from "assets/consumoEnergia/consumo.png";
import Dinheiro from "assets/consumoEnergia/dinheiro.png";
import { Base } from "componentes/cores";
import { RotasDTO } from "global/rotas/rotasUrlDto";
import formatarValor from "global/utils/formatarValor";
import SemDados from "../SemDados";

// Styles
import { useStyles, Botao } from "./style";

const ConsumoEconomia = ({ dadosFatura, unidadeConsumidora }) => {
  const classes = useStyles();
  const [carregandoDados, setCarregandoDados] = useState(false);
  const [resumo, setResumo] = useState(null);

  const handleResumoPrincipal = useCallback(
    async (codigoMedidor, tarifa, diaFaturamento) => {
      try {
        setCarregandoDados(true);
        if (codigoMedidor) {
          const { data, status } = await getTelemetriaResumo(
            codigoMedidor,
            tarifa,
            diaFaturamento
          );
          if (data && status === 200) {
            setResumo(data);
          } else if (status === 204) {
            setResumo(null);
          }
        }
      } catch (error) {
        setResumo(null);
      } finally {
        setCarregandoDados(false);
      }
    },
    []
  );

  useEffect(() => {
    if (unidadeConsumidora) {
      handleResumoPrincipal(
        unidadeConsumidora?.medidores[0]?.descricaoMedidor,
        unidadeConsumidora?.tarifaCheia,
        unidadeConsumidora?.diaFaturamentoDistribuidora
      );
    }
  }, [unidadeConsumidora]);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const porcentagem = Math.round(
    (resumo?.eptTotal * 100) / resumo?.eptProjetado
  );
  const porcentagemf = Math.round(
    (resumo?.eptFTotal * 100) / resumo?.eptFProjetado
  );
  const porcentagemfMinimo =
    porcentagemf.length <= 15 && matches ? "15" : porcentagemf;
  const porcentagemMinimo =
    porcentagem.length <= 15 && matches ? "15" : porcentagem;

  return (
    <Loader loading={carregandoDados}>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: { xs: "space-between" },
            alignItems: { xs: "center" },
            background: "#FBFBFD"
          }}
          p={2}
        >
          <h2 className={classes.titulo}>Consumo e econômia</h2>
          <Link to={RotasDTO.Atual}>
            <Botao>Ver detalhes</Botao>
          </Link>
        </Grid>
        {resumo ? (
          <Grid item xs={12} p={2}>
            <Card className={classes.sombra}>
              <Grid item xs={12} px={2} py={2}>
                <h3 className={classes.subtitulo}>Resumo do mês</h3>
              </Grid>
              <Grid item xs={12} px={2} py={1}>
                <Box
                  width="100"
                  sx={{ background: Base.GradientTextLuzOpacity }}
                  borderRadius="0px 24px 24px 0px"
                >
                  <Box
                    sx={{ background: Base.GradientTextLuz }}
                    width={`${porcentagemMinimo}%`}
                    py="3px"
                    borderRadius="0px 24px 24px 0px"
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                      }}
                    >
                      <Box
                        component="span"
                        fontStyle="normal"
                        fontWeight="400"
                        fontSize={{
                          xs: "10px",
                          sm: "15px",
                          md: "24px",
                          lg: "24px",
                          xl: "24px"
                        }}
                        color="#FFFFFF"
                        ml={{ xs: "2px", md: "10px", lg: "10px" }}
                      />
                      <Box
                        component="img"
                        src={Consumo}
                        alt="img-acumulado"
                        mr="2px"
                        height={{ xs: "21px", md: "100%" }}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end"
                  }}
                  pr={1}
                />
              </Grid>
              <Grid item xs={12} px={2} py={1}>
                <Box
                  width="100"
                  bgcolor={Base.RgbaCosmoLuz}
                  borderRadius="0px 24px 24px 0px"
                >
                  <Box
                    bgcolor={Base.Cosmo}
                    width={`${porcentagemfMinimo}%`}
                    py="3px"
                    borderRadius="0px 24px 24px 0px"
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                      }}
                    >
                      <Box
                        component="span"
                        fontStyle="normal"
                        fontWeight="400"
                        fontSize={{
                          xs: "10px",
                          sm: "15px",
                          md: "24px",
                          lg: "24px",
                          xl: "24px"
                        }}
                        color="#FFFFFF"
                        ml={{ xs: "2px", md: "10px", lg: "10px" }}
                      />
                      <Box
                        component="img"
                        src={Dinheiro}
                        alt="img-valor"
                        mr="2px"
                        height={{ xs: "21px", md: "100%" }}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end"
                  }}
                  pr={1}
                />
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={12} md={6} px={2} pt={2} pb={1}>
                  <Box
                    sx={{
                      background: "#F5F5F7",
                      textAlign: "center",
                      borderRadius: "8px"
                    }}
                    py={2}
                  >
                    <Box px={1}>
                      <h3 className={classes.kwh}>
                        {`${resumo?.eptTotal || ""} kWh`}
                      </h3>
                    </Box>
                    <Box px={1}>
                      <h3 className={classes.preco}>
                        {formatarValor(resumo?.eptFTotal) || ""}
                      </h3>
                    </Box>
                    <Box px={1}>
                      <strong className={classes.textoCinza}>
                        Conta até agora
                      </strong>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} px={2} pt={2} pb={1}>
                  <Box
                    sx={{
                      background: "#F5F5F7",
                      textAlign: "center",
                      borderRadius: "8px"
                    }}
                    py={2}
                  >
                    <Box px={1}>
                      <h3 className={classes.kwh}>
                        {`${resumo?.eptProjetado || ""} kWh`}
                      </h3>
                    </Box>
                    <Box px={1}>
                      <h3 className={classes.preco}>
                        {formatarValor(resumo?.eptFProjetado) || ""}
                      </h3>
                    </Box>
                    <Box px={1}>
                      <strong className={classes.textoCinza}>
                        Conta projetada
                      </strong>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12} p={2} className={classes.boxFim}>
                {dadosFatura?.length > 0 && (
                  <p>
                    {`Conta do mês anterior: ${
                      formatarValor(dadosFatura[0]?.valorTotalFaturaLUZ) || ""
                    } (${dadosFatura[0]?.consumoFaturadokWh}KWh)`}
                  </p>
                )}
              </Grid>
            </Card>
          </Grid>
        ) : (
          <SemDados texto="Você ainda não tem informação do seu consumo! Em breve, ela estará disponível para você." />
        )}
      </Grid>
    </Loader>
  );
};

ConsumoEconomia.propTypes = {
  unidadeConsumidora: PropTypes.shape({
    unidadeConsumidoraId: PropTypes.number,
    diaFaturamentoDistribuidora: PropTypes.number,
    medidores: PropTypes.arrayOf(
      PropTypes.shape({ descricaoMedidor: PropTypes.string })
    ),
    tarifaCheia: PropTypes.number
  }),
  dadosFatura: PropTypes.arrayOf(
    PropTypes.shape({
      valorTotalFaturaLUZ: PropTypes.number,
      consumoFaturadokWh: PropTypes.number
    })
  )
};
ConsumoEconomia.defaultProps = {
  unidadeConsumidora: null,
  dadosFatura: []
};

export default ConsumoEconomia;
