import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import shortid from "shortid";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

// Componentes
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { TreeView } from "@mui/x-tree-view/TreeView";
import theme from "themes";
import CampoTexto from "../../paginas/Cadastros/Funcionalidade/componentes/campoTexto";
import BotaoCadastro from "../botaoCadastro";
import TreeViewIcon from "./treeViewIcon";
import TreeViewItem from "./treeViewItem";
import TreeViewParentIcon from "./treeViewParentIcon";

import { useStyles } from "./style";

import { ordernaFuncionalidades } from "../../servicos/funcionalidadesServico";

import { store } from "../../global/redux";
import { alertaExibir } from "../../global/redux/modulos/alertas/actions";

import { cmainhoFuncionalidadeDefaultEnum } from "../../global/constantes";

const TreeViewFuncionalidades = ({
  dataSource,
  editable,
  onEditar,
  nodeEdicao,
  onSalvar,
  deletable,
  onDeletar,
  draggable,
  selectable,
  onSelecionar,
  selecionados,
  nodesExpanded,
  onRecarregar,
  checked
}) => {
  const classes = useStyles();
  const [nodesAtivos, setNodesAtivos] = useState();

  const verificarSelecao = useCallback(
    (item) => {
      if (
        (item.caminho === "AlterarSenha" ||
          item.id === cmainhoFuncionalidadeDefaultEnum.unidadesConsumidoras) &&
        !selecionados.includes(item.id)
      )
        selecionados.push(item.id);
      return selecionados.includes(item.id);
    },
    [selecionados]
  );

  const onClickSelecionar = (node) => {
    onSelecionar(node);
  };

  const expanded = useMemo(() => {
    if (nodesExpanded?.length) return nodesExpanded;
    if (nodesAtivos?.length) return nodesAtivos;
    return [];
  }, [nodesExpanded, nodesAtivos]);

  const getListStyle = (isDraggingOver, itens) =>
    isDraggingOver
      ? {
          background: theme.color.primaryBackgroud,
          height: itens ? `calc(${itens} * 56px)` : "auto"
        }
      : null;

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    opacity: isDragging ? 0.5 : 1,
    ...draggableStyle
  });

  const onDragEnd = async (item, lista, checked = false) => {
    const novaLista = [...lista];

    const index = lista.findIndex(
      (l) => String(l?.id) === String(item?.draggableId)
    );
    const droppable = lista.find(
      (l) => String(l?.id) === String(item?.draggableId)
    );

    // Remove item da lista
    novaLista.splice(index, 1);

    // Adiciona na nova posição
    novaLista.splice(item?.destination?.index, 0, droppable);

    let listaOrdenacao = [];

    // eslint-disable-next-line no-shadow
    novaLista.forEach((funcionalidade, index) => {
      listaOrdenacao = [
        ...listaOrdenacao,
        {
          id: funcionalidade?.id || 0,
          ordenacao: index + 1
        }
      ];
    });

    if (listaOrdenacao?.length) {
      try {
        const ordenou = await ordernaFuncionalidades(listaOrdenacao, checked);

        if (ordenou) {
          onRecarregar(true);
          store.dispatch(
            alertaExibir({
              tipo: "success",
              mensagem: "Funcionalidades ordenadas com sucesso!"
            })
          );
        }
      } catch (error) {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem:
              error?.response?.data?.message ??
              "Erro interno, entre em contato com o suporte!"
          })
        );
      }
    }
  };

  const [novoSetor, setNovoSetor] = useState(false);
  const [novoSegmento, setNovoSegmento] = useState({});

  useEffect(() => {
    if (!checked) return;
    onDragEnd({ draggableId: "" }, dataSource, checked);
  }, [checked]);

  return (
    <TreeView
      classes={{ root: classes.treeView }}
      disableSelection
      defaultEndIcon={<TreeViewParentIcon />}
      onNodeToggle={(_event, itens) => setNodesAtivos(itens)}
      expanded={[...expanded]}
    >
      {editable &&
        (novoSetor ? (
          <CampoTexto
            full
            onEditar={() => setNovoSetor(false)}
            onSalvar={(dados) => {
              if (onSalvar(dados)) setNovoSetor(false);
            }}
          />
        ) : (
          <BotaoCadastro
            label="Novo setor"
            color={theme.color.secondaryText}
            className={classes.button}
            onClick={() => setNovoSetor(true)}
          />
        ))}
      <DragDropContext onDragEnd={(item) => onDragEnd(item, dataSource)}>
        <Droppable
          droppableId="droppableFuncionalidades"
          isDropDisabled={checked}
        >
          {(providedDroppable, snapshotDroppable) => (
            <div
              {...providedDroppable.droppableProps}
              ref={providedDroppable.innerRef}
              style={getListStyle(snapshotDroppable.isDraggingOver)}
              className="mt-1"
            >
              {dataSource.map((funcionalidade, funcionalidadeIndex) => {
                return (
                  <Draggable
                    key={String(funcionalidade.id)}
                    draggableId={String(funcionalidade.id)}
                    index={funcionalidadeIndex}
                    isDragDisabled={
                      (!draggable && funcionalidade?.ativo) || checked
                    }
                  >
                    {(providedDraggable, snapshotDraggable) => (
                      <div
                        ref={providedDraggable.innerRef}
                        {...providedDraggable.draggableProps}
                        style={getItemStyle(
                          snapshotDraggable.isDragging,
                          providedDraggable.draggableProps.style
                        )}
                      >
                        <TreeItem
                          key={funcionalidade.id}
                          nodeId={String(funcionalidade.id)}
                          classes={{
                            content: classes.content,
                            label: classes.label,
                            iconContainer: classes.iconContainer,
                            group: classes.group
                          }}
                          label={
                            nodeEdicao &&
                            nodeEdicao?.id === funcionalidade.id ? (
                              <CampoTexto
                                tipo={0}
                                node={funcionalidade}
                                onEditar={() => onEditar()}
                                onSalvar={(dados) => {
                                  if (onSalvar(dados)) {
                                    onEditar();
                                  }
                                }}
                              />
                            ) : (
                              <TreeViewItem
                                level={0}
                                editable={editable}
                                onEditar={(node) => onEditar(node)}
                                deletable={deletable}
                                onDeletar={(node) => onDeletar(node)}
                                draggable={draggable}
                                dragHandleProps={
                                  providedDraggable.dragHandleProps
                                }
                                node={funcionalidade}
                                childs
                                label={funcionalidade.nome}
                                icone={funcionalidade?.icone}
                                selectable={selectable}
                                onSelecionar={(node) => onClickSelecionar(node)}
                                selecionados={selecionados}
                                selected={verificarSelecao(funcionalidade)}
                                checked={checked}
                              />
                            )
                          }
                          expandIcon={
                            <TreeViewIcon
                              ativo={funcionalidade.ativo}
                              nodeId={String(funcionalidade.id)}
                              level={funcionalidade?.idTipoFuncionalidade}
                              nodesAtivos={nodesAtivos}
                            />
                          }
                          collapseIcon={
                            <TreeViewIcon
                              ativo={funcionalidade.ativo}
                              nodeId={String(funcionalidade.id)}
                              level={funcionalidade?.idTipoFuncionalidade}
                              nodesAtivos={nodesAtivos}
                            />
                          }
                          onLabelClick={(event) => event.preventDefault()}
                        >
                          {editable && funcionalidade.ativo ? (
                            <TreeItem
                              key={shortid.generate()}
                              nodeId={shortid.generate()}
                              classes={{
                                content: classes.content,
                                label: classes.label,
                                iconContainer: classes.iconContainer,
                                group: classes.group
                              }}
                              label={
                                novoSegmento === funcionalidade.id ? (
                                  <CampoTexto
                                    tipo={1}
                                    nodePai={funcionalidade}
                                    onEditar={() => setNovoSegmento()}
                                    onSalvar={(dados) => {
                                      if (onSalvar(dados)) {
                                        setNovoSegmento();
                                      }
                                    }}
                                  />
                                ) : (
                                  <BotaoCadastro
                                    label="Novo segmento"
                                    color={theme.color.fourthBackgroud}
                                    className={classes.iconCrud}
                                    onEditar={(node) => onEditar(node)}
                                    onClick={() =>
                                      setNovoSegmento(funcionalidade.id)
                                    }
                                  />
                                )
                              }
                              onLabelClick={(event) => event.preventDefault()}
                            />
                          ) : null}

                          {funcionalidade?.funcionalidadeColecao?.length ? (
                            <DragDropContext
                              onDragEnd={(item) =>
                                onDragEnd(
                                  item,
                                  funcionalidade.funcionalidadeColecao
                                )
                              }
                            >
                              <Droppable droppableId="droppableFilhos">
                                {(
                                  providedDroppableFilhos,
                                  snapshotDroppableFilhos
                                ) => (
                                  <div
                                    {...providedDroppableFilhos.droppableProps}
                                    ref={providedDroppableFilhos.innerRef}
                                    style={getListStyle(
                                      snapshotDroppableFilhos.isDraggingOver,
                                      funcionalidade.funcionalidadeColecao
                                        .length
                                    )}
                                  >
                                    {funcionalidade.funcionalidadeColecao.map(
                                      (filho, filhoIndex) => {
                                        return (
                                          <Draggable
                                            key={String(filho.id)}
                                            draggableId={String(filho.id)}
                                            index={filhoIndex}
                                            isDragDisabled={
                                              (!draggable && filho?.ativo) ||
                                              checked
                                            }
                                          >
                                            {(
                                              providedDraggableFilho,
                                              snapshotDraggableFilho
                                            ) => (
                                              <div
                                                ref={
                                                  providedDraggableFilho.innerRef
                                                }
                                                {...providedDraggableFilho.draggableProps}
                                                style={getItemStyle(
                                                  snapshotDraggableFilho.isDragging,
                                                  providedDraggableFilho
                                                    .draggableProps.style
                                                )}
                                                className="mt-1"
                                              >
                                                <TreeItem
                                                  key={filho.id}
                                                  nodeId={String(filho.id)}
                                                  classes={{
                                                    content: classes.content,
                                                    label: classes.label,
                                                    iconContainer:
                                                      classes.iconContainer,
                                                    group: classes.group
                                                  }}
                                                  className="withChildren"
                                                  label={
                                                    nodeEdicao &&
                                                    nodeEdicao?.id ===
                                                      filho.id ? (
                                                      <CampoTexto
                                                        tipo={1}
                                                        node={filho}
                                                        nodePai={funcionalidade}
                                                        onEditar={() =>
                                                          onEditar()
                                                        }
                                                        onSalvar={(dados) => {
                                                          if (onSalvar(dados)) {
                                                            onEditar();
                                                          }
                                                        }}
                                                      />
                                                    ) : (
                                                      <TreeViewItem
                                                        level={1}
                                                        editable={editable}
                                                        onEditar={(node) =>
                                                          onEditar(node)
                                                        }
                                                        deletable={deletable}
                                                        onDeletar={(node) =>
                                                          onDeletar(node)
                                                        }
                                                        draggable={draggable}
                                                        dragHandleProps={
                                                          providedDraggableFilho.dragHandleProps
                                                        }
                                                        node={filho}
                                                        childs
                                                        label={filho.nome}
                                                        selectable={selectable}
                                                        onSelecionar={(node) =>
                                                          onClickSelecionar(
                                                            node
                                                          )
                                                        }
                                                        selecionados={
                                                          selecionados
                                                        }
                                                        selected={verificarSelecao(
                                                          filho
                                                        )}
                                                        checked={checked}
                                                      />
                                                    )
                                                  }
                                                  expandIcon={
                                                    <TreeViewIcon
                                                      ativo={filho.ativo}
                                                      nodeId={String(filho.id)}
                                                      level={
                                                        filho?.idTipoFuncionalidade
                                                      }
                                                      nodesAtivos={nodesAtivos}
                                                    />
                                                  }
                                                  collapseIcon={
                                                    <TreeViewIcon
                                                      ativo={filho.ativo}
                                                      nodeId={String(filho.id)}
                                                      level={
                                                        filho?.idTipoFuncionalidade
                                                      }
                                                      nodesAtivos={nodesAtivos}
                                                    />
                                                  }
                                                  onLabelClick={(event) =>
                                                    event.preventDefault()
                                                  }
                                                >
                                                  {editable && filho.ativo ? (
                                                    <TreeItem
                                                      key={shortid.generate()}
                                                      nodeId={shortid.generate()}
                                                      classes={{
                                                        content:
                                                          classes.content,
                                                        label: classes.label,
                                                        iconContainer:
                                                          classes.iconContainer,
                                                        group: classes.group
                                                      }}
                                                      label={
                                                        novoSegmento ===
                                                        filho.id ? (
                                                          <CampoTexto
                                                            tipo={1}
                                                            nodePai={filho}
                                                            onEditar={() =>
                                                              setNovoSegmento()
                                                            }
                                                            onSalvar={(
                                                              dados
                                                            ) => {
                                                              if (
                                                                onSalvar(dados)
                                                              ) {
                                                                setNovoSegmento();
                                                              }
                                                            }}
                                                          />
                                                        ) : (
                                                          <BotaoCadastro
                                                            label="Novo segmento ou interface"
                                                            color={
                                                              theme.color
                                                                .fourthBackgroud
                                                            }
                                                            className={
                                                              classes.iconCrud
                                                            }
                                                            onEditar={(node) =>
                                                              onEditar(node)
                                                            }
                                                            onClick={() =>
                                                              setNovoSegmento(
                                                                filho.id
                                                              )
                                                            }
                                                          />
                                                        )
                                                      }
                                                      onLabelClick={(event) =>
                                                        event.preventDefault()
                                                      }
                                                    />
                                                  ) : null}
                                                  {filho?.funcionalidadeColecao
                                                    ?.length ? (
                                                    <DragDropContext
                                                      onDragEnd={(item) =>
                                                        onDragEnd(
                                                          item,
                                                          filho.funcionalidadeColecao
                                                        )
                                                      }
                                                    >
                                                      <Droppable droppableId="droppableNetos">
                                                        {(
                                                          providedDroppableNeto,
                                                          snapshotDroppableNeto
                                                        ) => (
                                                          <div
                                                            {...providedDroppableNeto.droppableProps}
                                                            ref={
                                                              providedDroppableNeto.innerRef
                                                            }
                                                            style={getListStyle(
                                                              snapshotDroppableNeto.isDraggingOver,
                                                              filho
                                                                .funcionalidadeColecao
                                                                .length
                                                            )}
                                                          >
                                                            {filho.funcionalidadeColecao.map(
                                                              (
                                                                neto,
                                                                netoIndex
                                                              ) => {
                                                                return (
                                                                  <Draggable
                                                                    key={String(
                                                                      neto.id
                                                                    )}
                                                                    draggableId={String(
                                                                      neto.id
                                                                    )}
                                                                    index={
                                                                      netoIndex
                                                                    }
                                                                    isDragDisabled={
                                                                      (!draggable &&
                                                                        neto?.ativo) ||
                                                                      checked
                                                                    }
                                                                  >
                                                                    {(
                                                                      providedDraggableNeto,
                                                                      snapshotDraggableNeto
                                                                    ) => (
                                                                      <div
                                                                        ref={
                                                                          providedDraggableNeto.innerRef
                                                                        }
                                                                        {...providedDraggableNeto.draggableProps}
                                                                        style={getItemStyle(
                                                                          snapshotDraggableNeto.isDragging,
                                                                          providedDraggableNeto
                                                                            .draggableProps
                                                                            .style
                                                                        )}
                                                                      >
                                                                        <TreeItem
                                                                          key={
                                                                            neto.id
                                                                          }
                                                                          nodeId={String(
                                                                            neto.id
                                                                          )}
                                                                          classes={{
                                                                            label:
                                                                              classes.label,
                                                                            iconContainer:
                                                                              classes.iconContainer,
                                                                            group:
                                                                              classes.group
                                                                          }}
                                                                          label={
                                                                            nodeEdicao &&
                                                                            nodeEdicao?.id ===
                                                                              neto.id ? (
                                                                              <CampoTexto
                                                                                tipo={
                                                                                  2
                                                                                }
                                                                                node={
                                                                                  neto
                                                                                }
                                                                                nodePai={
                                                                                  filho
                                                                                }
                                                                                onEditar={() =>
                                                                                  onEditar()
                                                                                }
                                                                                onSalvar={(
                                                                                  dados
                                                                                ) => {
                                                                                  if (
                                                                                    onSalvar(
                                                                                      dados
                                                                                    )
                                                                                  ) {
                                                                                    onEditar();
                                                                                  }
                                                                                }}
                                                                              />
                                                                            ) : (
                                                                              <TreeViewItem
                                                                                level={
                                                                                  2
                                                                                }
                                                                                editable={
                                                                                  editable
                                                                                }
                                                                                onEditar={(
                                                                                  node
                                                                                ) =>
                                                                                  onEditar(
                                                                                    node
                                                                                  )
                                                                                }
                                                                                deletable={
                                                                                  deletable
                                                                                }
                                                                                onDeletar={(
                                                                                  node
                                                                                ) =>
                                                                                  onDeletar(
                                                                                    node
                                                                                  )
                                                                                }
                                                                                draggable={
                                                                                  draggable
                                                                                }
                                                                                dragHandleProps={
                                                                                  providedDraggableNeto.dragHandleProps
                                                                                }
                                                                                node={
                                                                                  neto
                                                                                }
                                                                                childs={
                                                                                  neto
                                                                                    ?.funcionalidadeColecao
                                                                                    ?.length
                                                                                }
                                                                                label={
                                                                                  neto.nome
                                                                                }
                                                                                selectable={
                                                                                  selectable
                                                                                }
                                                                                onSelecionar={(
                                                                                  node
                                                                                ) =>
                                                                                  onClickSelecionar(
                                                                                    node
                                                                                  )
                                                                                }
                                                                                selecionados={
                                                                                  selecionados
                                                                                }
                                                                                selected={verificarSelecao(
                                                                                  neto
                                                                                )}
                                                                                checked={
                                                                                  checked
                                                                                }
                                                                              />
                                                                            )
                                                                          }
                                                                          expandIcon={
                                                                            <TreeViewIcon
                                                                              ativo={
                                                                                neto.ativo
                                                                              }
                                                                              nodeId={String(
                                                                                neto.id
                                                                              )}
                                                                              level={
                                                                                neto?.idTipoFuncionalidade
                                                                              }
                                                                              nodesAtivos={
                                                                                nodesAtivos
                                                                              }
                                                                            />
                                                                          }
                                                                          collapseIcon={
                                                                            <TreeViewIcon
                                                                              ativo={
                                                                                neto.ativo
                                                                              }
                                                                              nodeId={String(
                                                                                neto.id
                                                                              )}
                                                                              level={
                                                                                neto?.idTipoFuncionalidade
                                                                              }
                                                                              nodesAtivos={
                                                                                nodesAtivos
                                                                              }
                                                                            />
                                                                          }
                                                                          onLabelClick={(
                                                                            event
                                                                          ) =>
                                                                            event.preventDefault()
                                                                          }
                                                                        />
                                                                      </div>
                                                                    )}
                                                                  </Draggable>
                                                                );
                                                              }
                                                            )}
                                                          </div>
                                                        )}
                                                      </Droppable>
                                                    </DragDropContext>
                                                  ) : null}
                                                </TreeItem>
                                              </div>
                                            )}
                                          </Draggable>
                                        );
                                      }
                                    )}
                                  </div>
                                )}
                              </Droppable>
                            </DragDropContext>
                          ) : null}
                        </TreeItem>
                      </div>
                    )}
                  </Draggable>
                );
              })}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </TreeView>
  );
};

TreeViewFuncionalidades.propTypes = {
  dataSource: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  editable: PropTypes.bool,
  onEditar: PropTypes.oneOfType([PropTypes.func]),
  nodeEdicao: PropTypes.oneOfType([PropTypes.object]),
  onSalvar: PropTypes.oneOfType([PropTypes.func]),
  deletable: PropTypes.bool,
  onDeletar: PropTypes.oneOfType([PropTypes.func]),
  draggable: PropTypes.bool,
  selectable: PropTypes.bool,
  onSelecionar: PropTypes.oneOfType([PropTypes.func]),
  selecionados: PropTypes.oneOfType([PropTypes.array]),
  nodesExpanded: PropTypes.oneOfType([PropTypes.any]),
  onRecarregar: PropTypes.oneOfType([PropTypes.func]),
  checked: PropTypes.bool
};

TreeViewFuncionalidades.defaultProps = {
  dataSource: [],
  editable: false,
  onEditar: () => {},
  nodeEdicao: {},
  onSalvar: () => {},
  deletable: false,
  onDeletar: () => {},
  draggable: false,
  selectable: false,
  onSelecionar: () => {},
  selecionados: [],
  nodesExpanded: [],
  onRecarregar: () => {},
  checked: false
};
window["__react-beautiful-dnd-disable-dev-warnings"] = true;
export default TreeViewFuncionalidades;
