/* eslint-disable react/prop-types */
import React from "react";
import { Grid } from "@mui/material";
import { ProtocolosEtapas } from "global/constantes";
import EsqueletoEtapa from "../../Componentes/EsqueletoEtapa";
import { useStyles } from "./style";

const Reprovado = ({ prazo, responsavel, onVoltarEtapa }) => {
  const classes = useStyles();

  const onVoltar = () => {
    onVoltarEtapa(ProtocolosEtapas.IndicarRepresentanteLegal);
  };

  return (
    <EsqueletoEtapa
      prazo={prazo}
      responsavel={responsavel}
      onClickVoltar={() => onVoltar()}
      titulo="Fim"
      esconderBotaoProximo
    >
      <Grid item xs={12} className={classes.containerContent}>
        <h4>
          No momento não foi possível dar continuidade à contratação. Entraremos
          em contato para esclarecimentos.
        </h4>
      </Grid>
    </EsqueletoEtapa>
  );
};

export default Reprovado;
