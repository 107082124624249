import React, { useState, useEffect } from 'react';
import { Card, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useStyles } from './style';
import { Base } from 'componentes/cores';
import Loader from 'componentes/loader';
import UploadDropArea from 'componentes/upload';
import SelectAutoComplete from 'componentes/selectAutocomplete';
import MaterialInputTexto from 'componentes/inputTexto/materialInput';
import Botao from 'componentes/botao';
import { obterCenarios } from '../../../servicos/cenariosSeriesHistoricas';
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { uploadArquivoSerieHistorica } from '../../../servicos/uploadArquivo';
import RotasDTO from '../../../rotas/rotasUrlDto';
import ResultadoOperacao from 'componentes/resultadoOperacao';
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";

const ImportarSerieHistorica = () => {
  const classes = useStyles();
  const history = useHistory();
  const usuarioGlobal = useSelector((state) => state.usuario);
  const msgPadraoUpload = "Solte o arquivo para anexá-lo ou procurar";

  const { handleSubmit, register, errors } = useForm({
    reValidateMode: 'onSubmit',
  });

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(msgPadraoUpload);
  const [carregandoArquivo, setCarregandoArquivo] = useState(false);
  const [progressMessage, setProgressMessage] = useState("");
  const [resultadoOperacao, setResultadoOperacao] = useState(false);
  const [resultadoOperacaoCabecalho, setResultadoOperacaoCabecalho] = useState("");
  const [resultadoOperacaoObj, setResultadoOperacaoObj] = useState({
    "mensagens": []
  });
  const [carregandoCenario, setCarregandoCenario] = useState(false);
  const [cenarios, setCenarios] = useState([]);
  const [cenarioID, setCenarioID] = useState(null);
  const [cenarioDescricao, setCenarioDescricao] = useState("Selecione o Cenário");

  const handleCenarios = async () => {
    try {
      setCarregandoCenario(true);
      const lista = await obterCenarios();
      if (lista?.status === 200 && lista?.data) {
        setCenarios(lista?.data);
        setCarregandoCenario(false);
      } else {
        setCenarios([]);
      }
      setCarregandoCenario(false);
    } catch (error) {
      console.info(error);
    }
  }

  const handleChangeCenario = (idCenario, descCenario) => {
    setCenarioID(idCenario);
    setCenarioDescricao(descCenario)
  }

  useEffect(() => {
    if (!cenarios?.length) handleCenarios();
  }, [cenarios.length, handleCenarios]);

  

  const enviarFormulario = async () => {
    try {
      setCarregandoArquivo(true);
      setProgressMessage("Importando o arquivo... Por favor, aguarde.");
      const upload = await uploadArquivoSerieHistorica(cenarioID, file, usuarioGlobal?.usuario?.id, usuarioGlobal?.usuario?.usuarioNome);

      if (upload?.status === 200 && upload?.data) {
        setCarregandoArquivo(false);
        setResultadoOperacao(true); 
        let dadosRetorno = upload?.data;
        
        let mensagemRetorno = ["Processo de Importação Finalizado com sucesso"];

        if (dadosRetorno.length !==null && dadosRetorno.length !== undefined && dadosRetorno.length > 1){
          
          let valores = dadosRetorno?.[1];
          
          if(valores.length !==null && valores.length !== undefined && valores.length > 0){
            for (var i = 0; i < valores?.length; i++) {
              mensagemRetorno.push(valores[i].Mensagem); 
            } 
          }
        }
        setResultadoOperacaoCabecalho(`Série Histórica - Importação do arquivo ${fileName}`)
        setResultadoOperacaoObj({"mensagens": mensagemRetorno });      


      }
      else{
        setCarregandoArquivo(false);
        setResultadoOperacao(true); 
        let dadosRetorno = upload?.data;
        
        let mensagemRetorno = ["Processo de Importação Finalizado com falhas"];
        console.dir(dadosRetorno);
        if (dadosRetorno.length !==null && dadosRetorno.length !== undefined && dadosRetorno.length > 0){
            for (var i = 0; i < dadosRetorno?.length; i++) {
              mensagemRetorno.push(dadosRetorno[i]); 
            } 
        }
        setResultadoOperacaoCabecalho(`Série Histórica - Importação do arquivo ${fileName}`)
        setResultadoOperacaoObj({"mensagens": mensagemRetorno });      

      }
    } catch (error) {
      setCarregandoArquivo(false);
      setResultadoOperacao(true); 
      
      let mensagemRetorno = [`Erro não previsto: ${error.message}`];

      let dadosRetorno = error?.response.data;
        
      if (dadosRetorno.length !==null && dadosRetorno.length !== undefined && dadosRetorno.length > 1){
          for (var i = 0; i < dadosRetorno?.length; i++) {
            mensagemRetorno.push(dadosRetorno[i]); 
          } 
      }

      setResultadoOperacaoCabecalho(`Série Histórica - Importação do arquivo ${fileName}`)
      setResultadoOperacaoObj({"mensagens": mensagemRetorno });      

    };
  }

  const aoEnviarForm = () => {
    if (!file) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Por favor, selecione o arquivo desejado."
        })
      )
    } else {
      enviarFormulario();
    }    
    
  }

  const onAddUpload = (arquivo) => {
    setFile(arquivo[0].file);
    setFileName(arquivo[0].file.name);
  };

  const onFecharResultadoOperacao = (id) => {
    setCarregandoArquivo(false);
    setFile(false);
    setFileName(msgPadraoUpload);
    setCenarioDescricao("Selecione o Cenário");
    setCenarioID(null);
    setResultadoOperacao(false); 
    setResultadoOperacaoCabecalho("");
    setResultadoOperacaoObj({
      "mensagens": []
    });
  }

  const onFechar = (id) => {
    history.push(`${RotasDTO.SeriesHistoricas}`);
  }  

  return (
    <form className="needs-validation" onSubmit={handleSubmit(aoEnviarForm)}>
      <ResultadoOperacao
        item={resultadoOperacaoObj}
        cabecalhoResultado={resultadoOperacaoCabecalho}
        onClose={() => onFecharResultadoOperacao()}
      />
      {resultadoOperacao === false ?      
      <Card className={classes.container}>
        <Grid container className={classes.containerTitulo}>
          <Grid item xs={11}className="font-weight-bold">
            Importar Valores de Séries Históricas
          </Grid>
          <Grid item xs={1} >             
                <CloseIcon onClick={onFechar} className={classes.icon}/>
            </Grid>   
        </Grid>
        <Grid container spacing={4} p={2} className={classes.container}>
          <Grid item sm={4}>
            <Loader loading={carregandoCenario}>
              <SelectAutoComplete
                disableClearable={false}
                disableOpenOnFocus
                id="cenarioSerieHistorica"
                name="cenarioSerieHistorica"
                label="Cenário"
                value={cenarioID ?? ""}
                options={cenarios}
                getOptionLabel={(option) => option.nome}
                onChange={(e, item) => handleChangeCenario(item?.id, item?.descricao)}
                ref={register(
                  { name: 'cenarioSerieHistorica' },
                  {
                    required: !cenarioID ? 'Campo Cenário é obrigatório!' : false,
                  }
                )}
                error={errors}
                valueName="nome"
              />
            </Loader>
          </Grid>
          <Grid item sm={6}>
            <MaterialInputTexto
              type="text"
              name="cenarioDescricao"
              label="Descrição do cenário"
              defaultValue={cenarioDescricao}
              value={cenarioDescricao ?? ""}
              readOnly
              errors={errors}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4} p={2} className={classes.container}>
          {carregandoArquivo === false ?
            <Grid item sm={12}>
              <UploadDropArea
                onAdd={onAddUpload}
                dropAreaText={fileName}
                showLoader={false}
                acceptedFiles={[
                  '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
                ]}
              />
            </Grid>
            :
            <Grid container spacing={4} p={2} className={classes.container} style={{ marginBottom: "20px" }}>
              <Grid item sm={12} style={{ textAlign: "center" }}>
                {progressMessage}
              </Grid>
              <Grid item sm={12}>
                <Loader loading={carregandoArquivo} />
              </Grid>
            </Grid>
          }
        </Grid>
        <Grid container spacing={4} p={2} className={classes.container}>
          <Grid item sm={4}>
            <Botao
              type="submit"
              label="Salvar"
              color={Base.Mango}
              className={classes.button}
            />
          </Grid>
        </Grid>
      </Card>
      : ""
    }      
    </form>
  );
};

export default ImportarSerieHistorica;
