import MICROSERVICO from "global/enumeradores/microServico";
import obterApi from "servicos/api";

const api = () => obterApi(MICROSERVICO.PROJECAOTARIFARIA);

export const ValidarDominio = async (data) => {
    const metodo = "post";
    const url = `v1/ValidacaoDominio/ValidarDominio`;
    return api()[metodo](url, data);
};

export const InserirValorDominio = async (data) => {
    const metodo = "post";
    const url = `v1/ValidacaoDominio/InserirValorDominio`;
    return api()[metodo](url, data);
};