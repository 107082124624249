import { makeStyles } from "@mui/styles";
import { Base } from "componentes/cores";
import getDomain from "global/getDomain";
import theme from "themes";
import { createTheme } from "@mui/material/styles";

const th = createTheme();

export const useStyles = makeStyles(() => ({
  container: {
    margin: "0",
    width: "100%",
    "& .MuiInputBase-root": {
      height: "46px"
    },
    "& .MuiFormLabel-root": {
      lineHeight: "1"
    },
    "& .MuiGrid-spacing-xs-4 > .MuiGrid-item": {
      padding: "12px"
    },
    "& .MuiTypography-body1": {
      width: "100%"
    }
  },
  button: {
    fontSize: "18px"
  },
  selectOfficer: {
    filter: theme.color.invert
  },
  trace: {
    width: "112px",
    height: "47px",
    borderBottom: "1px solid black",
    position: "absolute"
  },
  fundoDadosEnergia: {
    // background: `url(themes/${getDomain()}/assets/bg-login.png) left top`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },
  containerAvancar: {
    margin: "0 0 16px"
  },
  inputRadio: {
    marginBottom: "-16px",
    color: theme.color.primaryBackgroud
  },
  inputRadioLabel: {
    color: `${theme.color.primaryBackgroud} !important`
  },
  imagemFundo: {
    background: getDomain() !== "luz" && `url(../../themes/${getDomain()}/assets/cotacao_dados_energia.png)`,
    backgroundSize: "cover"
  },
  fundoPreto: {
    backgroundColor: theme.color.bgCardCotacoes,
  },
  checked:{
    filter: theme.color.invert,
    marginTop: 9
  },
  unchecked:{
    color: Base.GreyHit,
    marginTop: 9
  },
  hideMobile:{
    [th.breakpoints.down("lg")]: {
      display: "none !important",
    },
  },
  showMobile:{
    [th.breakpoints.up("md")]: {
      display: "none !important",
    },
  },
  barraSuperior: {
    fontSize: "18px",
    fontStyle: "italic",
    height: "42px",
    textAlign: "center",
    color: `${theme.color.textoBarra}`,
    width: "100%"
  },
  arrowBack:{
    color: `${theme.color.textoBarra}`,
    position:"absolute",
    left:0,
    paddingLeft:15
  },
  iconeBotao:{
    display: "block",
    margin: "0 auto",
    marginTop: -30,
    marginBottom: 10
  },

}));
