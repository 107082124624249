import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const api = () => obterApi(MICROSERVICO.MEASUREMENT);

export const listarOrganizarPor = () => {
  const ordenar = [
    {
      key: "1",
      asc: "1",
      desc: "2",
      label: "Alfabética"
    },
    {
      key: "2",
      asc: "5",
      desc: "6",
      label: "Recente"
    }
  ];

  return ordenar;
};

export const filtroSituacaoPor = () => {
  const filtroSituacao = [
    {
      key: "1",
      label: "Válido"
    },
    {
      key: "2",
      label: "Inválido"
    }
  ];

  return filtroSituacao;
};

export const ListarDistribuidoraPisCofins = async (filtro) => {
  const filtroQueryString = new URLSearchParams(filtro).toString();
  return api().get(`v1/distribuidorapiscofins/paginado?${filtroQueryString}`);
};

export const salvarDistribuidoraPisCofins = async (id, dados) => {
  let metodo = "post";
  let url = "v1/distribuidorapiscofins/";
  if (id > 0) {
    metodo = "put";
    url = `${url}${id}`;
  }
  return api()[metodo](url, dados);
};

export const buscarPorId = async (id) => {
  return api().get(`v1/distribuidorapiscofins/${id}`);
};
