import RetornoEndpointDto from "../../../global/dto/retornoEndpointDto";
import {
  obterAssociacaoPorDistribuidora,
  obterAssociacao
} from "../../../servicos/associacaoLuzServico";
import RadioDto from "../../../componentes/inputRadioGroup/radioDto";
import { store } from "../../../global/redux";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";
import { obterUsinaAssociacaoPorAssociacaoId } from "../../../servicos/usinasLuzServico";
import {
  obterUnidadeUsinaPorUnidadeId,
  salvarUnidadeUsina
} from "../../../servicos/unidadesServico";

export default class UnidadeHelper {
  static obterProdutos() {
    return [
      new RadioDto("Mercado Livre", "1"),
      new RadioDto("Geração Distribuída", "4"),
      new RadioDto("Geração Distribuidora - Energia Consumida", "5")
    ];
  }

  static async obterAssociacao(setCarregandoAssociacao) {
    try {
      setCarregandoAssociacao(true);
      const resultado = await obterAssociacao();

      return RetornoEndpointDto.Sucesso(
        "Associação obtido com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Associação não encontrados!");
    } finally {
      setCarregandoAssociacao(false);
    }
  }

  static async obterUsinaAssociacaoPorAssociacaoId(
    params,
    setCarregandoUsinas
  ) {
    try {
      setCarregandoUsinas(true);
      const resultado = await obterUsinaAssociacaoPorAssociacaoId(params);

      return RetornoEndpointDto.Sucesso(
        "Usinas obtido com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Usinas não encontrados!");
    } finally {
      setCarregandoUsinas(false);
    }
  }

  static async salvarUnidadeUsina(body, setCarregandoUnidade) {
    try {
      setCarregandoUnidade(true);
      const resultado = await salvarUnidadeUsina(body);

      return RetornoEndpointDto.Sucesso(
        "Usinas salva com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Erro ao salvar usinas!");
    } finally {
      setCarregandoUnidade(false);
    }
  }

  static async obterUnidadeUsinaPorUnidadeId(params, setCarregandoAssociacao) {
    try {
      setCarregandoAssociacao(true);
      const resultado = await obterUnidadeUsinaPorUnidadeId(params);

      return RetornoEndpointDto.Sucesso(
        "Unidades obtido com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Erro ao buscar unidades!");
    } finally {
      setCarregandoAssociacao(false);
    }
  }

  static async obterAssociacaoPorDistribuidora(
    distribuidoraId,
    setCarregandoAssociacao
  ) {
    try {
      setCarregandoAssociacao(true);
      const resultado = await obterAssociacaoPorDistribuidora(distribuidoraId);

      if (resultado.status === 204)
        return RetornoEndpointDto.Sucesso(
          "Associação por distribuidora não encontrados!",
          []
        );

      return RetornoEndpointDto.Sucesso(
        "Associação por distribuidora não encontrados!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Erro ao obter associação por distribuidora!"
      );
    } finally {
      setCarregandoAssociacao(false);
    }
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }

  static exibirSucesso(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem
      })
    );
  }
}
