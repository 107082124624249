import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Card, Grid } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loader from "../../componentes/loader";
import UnidadeNaoSelecionada from "../../componentes/unidadeNaoSelecionada";
import { store } from "../../global/redux";
import { alertaExibir } from "../../global/redux/modulos/alertas/actions";
import {
  buscarGrupo,
  buscarTodasCategorias
} from "../../servicos/categoriasServico";
import { unidadeConsumidoraGD } from "../../servicos/unidadeConsumidoraGDServico";
import ConsumoCategorias from "./componentes/ConsumoCategorias";
import ConsumoGrupo from "./componentes/ConsumoGrupo";
import InputData from "./componentes/InputData";
import InputRangeData from "./componentes/InputRangeData";
import ModalCategoria from "./componentes/ModalCategoria";
import { BotaoVoltar } from "./style";

const Categorias = () => {
  const usuario = useSelector((state) => state.usuario);
  const { unidadeSelecionada } = usuario;
  const [modalAberto, setModalAberto] = useState(false);
  const [dadosGrupo, setDadosGrupo] = useState();
  const [informacoesGrupo, setInformacoesGrupo] = useState();
  const [data, setData] = useState(new Date());
  const [rangeDatas, setRangeDatas] = useState({});
  const [dadosCategorias, setDadosCategorias] = useState({});
  const [carregando, setCarregando] = useState(false);
  const [unidadeMedidores, setUnidadeMedidores] = useState();

  const obterDadosCategorias = async (unidade, dataInicio = null) => {
    setCarregando(true);
    try {
      const dados = await buscarTodasCategorias({
        UnidadeConsumidoradId: unidade?.unidadeConsumidoraId,
        Mac: unidade?.medidores[0]?.descricaoMedidor,
        DiaFaturamento: unidade?.diaFaturamentoDistribuidora,
        Tarifa: unidade?.tarifaCheia,
        DataInicio: unidade?.diaFaturamentoDistribuidora
          ? `${moment(dataInicio).format("YYYY-MM")}-${
              unidade?.diaFaturamentoDistribuidora
            }`
          : moment(dataInicio).format("YYYY-MM-DD")
      });

      setDadosCategorias(dados?.data ? dados?.data : dados);
      setRangeDatas({
        dataInicial:
          dataInicio ||
          unidade?.medidores[0]?.dataDesagregacaoInicio ||
          new Date(),
        dataFinal: new Date()
      });
    } catch (erro) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: erro?.response?.data?.message
        })
      );
    } finally {
      setCarregando(false);
    }
  };

  const obterUnidadeConsumidoraGD = async (id) => {
    setCarregando(true);
    try {
      const unidades = await unidadeConsumidoraGD();
      const unidadeFiltrada = unidades.data.unidadeConsumidoraGDList.find(
        (unidade) => unidade.unidadeConsumidoraId === id
      );

      if (unidadeFiltrada?.medidores?.length) {
        setUnidadeMedidores(unidadeFiltrada);
        // eslint-disable-next-line no-shadow
        const data = new Date();
        const dataFaturamento = unidadeFiltrada?.diaFaturamentoDistribuidora
          ? moment([
              data.getFullYear(),
              data.getMonth(),
              unidadeFiltrada?.diaFaturamentoDistribuidora
            ]).toDate()
          : new Date();

        setData(dataFaturamento);
        obterDadosCategorias(unidadeFiltrada, dataFaturamento);
      }
    } catch (erro) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: erro?.response?.data?.message
        })
      );

      setCarregando(false);
    }
  };

  const obterDadosGrupo = async (grupo, datas = false) => {
    setCarregando(true);
    try {
      if (unidadeMedidores?.medidores?.length) {
        const dadosGrupoApi = await buscarGrupo({
          GrupoId: grupo.id,
          UnidadeConsumidoradId: unidadeMedidores?.unidadeConsumidoraId,
          Mac: unidadeMedidores?.medidores[0]?.descricaoMedidor,
          DiaFaturamento: unidadeMedidores?.diaFaturamentoDistribuidora,
          Tarifa: unidadeMedidores?.tarifaCheia,
          DataInicio: datas
            ? moment(datas?.startDate).format("YYYY-MM-DD")
            : moment(rangeDatas?.dataInicial).format("YYYY-MM-DD"),
          DataFim: datas
            ? moment(datas?.endDate).format("YYYY-MM-DD")
            : moment(rangeDatas?.dataFinal).format("YYYY-MM-DD")
        });
        setDadosGrupo(
          dadosGrupoApi?.data ? dadosGrupoApi?.data : dadosGrupoApi
        );

        setInformacoesGrupo(grupo);
      }
    } catch (erro) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: erro?.response?.data?.message
        })
      );
    } finally {
      setCarregando(false);
    }
  };

  const onChangeData = async (dataInicial) => {
    if (unidadeMedidores) {
      await obterDadosCategorias(unidadeMedidores, dataInicial);
    } else if (unidadeSelecionada) {
      obterUnidadeConsumidoraGD(unidadeSelecionada);
    }
  };

  const onChangeDataRange = async (datas) => {
    await obterDadosGrupo(informacoesGrupo, datas);
  };

  useEffect(() => {
    if (unidadeSelecionada) {
      obterUnidadeConsumidoraGD(unidadeSelecionada);
    }
  }, [unidadeSelecionada]);

  return !unidadeSelecionada ? (
    <UnidadeNaoSelecionada />
  ) : (
    <Loader loading={carregando}>
      <ModalCategoria
        aberto={modalAberto}
        setAberto={() => setModalAberto(false)}
      />
      <Grid container pb={2}>
        {dadosGrupo && (
          <BotaoVoltar onClick={() => setDadosGrupo()}>
            <ArrowBackIosIcon />
            Voltar
          </BotaoVoltar>
        )}
      </Grid>
      <Grid container pb={2}>
        <Grid item xs={12}>
          {dadosGrupo ? (
            <InputRangeData
              onChangeData={(datas) => onChangeDataRange(datas)}
              dataInicial={rangeDatas?.dataInicial}
              dataFinal={rangeDatas?.dataFinal}
            />
          ) : (
            <InputData
              onChangeData={(dataSelecionada) => onChangeData(dataSelecionada)}
              defaultValue={data}
            />
          )}
        </Grid>
      </Grid>
      <Card>
        {dadosGrupo ? (
          <ConsumoGrupo
            dados={dadosGrupo}
            informacoes={informacoesGrupo}
            setModalAberto={setModalAberto}
          />
        ) : (
          <ConsumoCategorias
            setModalAberto={setModalAberto}
            selecionarGrupo={obterDadosGrupo}
            dados={dadosCategorias}
            carregando={carregando}
          />
        )}
      </Card>
    </Loader>
  );
};

export default Categorias;
