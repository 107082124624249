import styled from "styled-components";
import { makeStyles } from "@mui/styles";
import theme from "themes";
import { Base } from "../../../componentes/cores";

export const IconeImagem = styled.img`
  max-height: 58px;
  max-width: 58px;
`;

export const TotalCativo = styled.div`
  align-items: center;
  background-color: ${theme.color.bgCardEconomia};
  border-radius: 4px;
  color: ${Base.White};
  display: flex;
  height: 119px;
  justify-items: center;
  padding: 25px;
  text-decoration: none;
  width: 100%;
`;

export const TotalLivre = styled.div`
  align-items: center;
  background-color: ${theme.color.bgCardEconomia};
  border-radius: 4px;
  color: ${Base.White};
  display: flex;
  height: 119px;
  justify-items: center;
  padding: 25px;
  text-decoration: none;
  width: 100%;
`;

export const CarregandoPDF = styled.div`
  color: ${theme.color.textoBarra};
  font-size: 22px;
  margin: 200px auto 0;
  max-width: 500px;
  text-align: center;
`;

export const TotalEconomia = styled.div`
  align-items: center;
  background-color: ${theme.color.bgCardEconomia};
  border-radius: 4px;
  color: ${Base.White};
  display: flex;
  height: 119px;
  justify-items: center;
  padding: 25px;
  text-decoration: none;
  width: 100%;
`;

export const Icone = styled.div`
  align-content: center;
  align-items: center;
  display: inline-flex;
  height: 58px;
  margin-right: 10px;
  width: auto;
  & > svg {
    font-size: 58px;
  }
`;

export const Texto = styled.div`
  // display: inline-block;
  // height: 58px;
  // max-width: 205px;
`;

export const Legenda = styled.div`
  // display: inline-block;
  font-size: 16px;
  font-weight: normal;
`;

export const Valor = styled.div`
  // display: inline-block;
  font-size: 30px;
  font-weight: bold;
`;

export const useStyles = makeStyles(() => ({
  boxSuperiorEconomia: {
    backgroundColor: "#fbfbfb !important",
    color: "black !important"
  },
  estiloBotaoPDF: {
    color: theme.color.textoBarra,
    fontSize: 32
  },
  bordaPDF: {
    borderColor: "transparent",
    padding: 5,
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: 5,
    backgroundColor: "transparent"
  },
  boxPDF: {
    position: "fixed",
    right: 0,
    marginTop: "-58px",
    zIndex: 9999,
    marginRight: 10
  },
  loaderBranco: {
    color: theme.color.textoBarra,
    position: "absolute",
    marginLeft: "-38px",
    marginTop: 6,
    backgroundColor: "transparent"
  },
  barraSuperior: {
    fontSize: "18px",
    fontStyle: "italic",
    height: "42px",
    textAlign: "center",
    color: `${theme.color.textoBarra}`,
    width: "100%"
  },
  arrowBack: {
    color: `${theme.color.textoBarra}`,
    position: "absolute",
    left: 0,
    paddingLeft: 15,
    zIndex: 50
  }
}));
