import { createTheme } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import withStyles from "@mui/styles/withStyles";
import React from "react";
import ClientTheme from "themes";

const theme = createTheme();

const IOSStyleSwitch = withStyles(() => ({
  root: {
    width: "42px !important",
    height: "26px !important",
    padding: "0 !important",
    margin: "0 !important"
  },
  switchBase: {
    padding: "1px !important",
    "&$checked": {
      transform: "translateX(16px) !important",
      color: `${theme.palette.common.white} !important`,
      "& + $track": {
        backgroundColor: ClientTheme.color.switchBgColor,
        opacity: "1 !important"
        // border: "none"
      }
    },
    "&$disabled": {
      "&$checked": {
        "& + $track": {
          backgroundColor: `${ClientTheme.color.switchBgColorDisabled} !important`,
          opacity: "0.4 !important"
        }
      }
    },
    "&$focusVisible $thumb": {
      color: `${ClientTheme.color.primary} !important`,
      border: "6px solid #fff !important"
    }
  },
  thumb: {
    width: "24px !important",
    height: "24px !important"
  },
  disabled: {},
  track: {
    borderRadius: `${26 / 2}px !important`,
    border: `1px solid ${theme.palette.grey[400]} !important`,
    backgroundColor: `${theme.palette.grey[50]} !important`,
    opacity: "1 !important",
    transition: theme.transitions.create(["background-color", "border"])
  },
  checked: {},
  focusVisible: {}
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
        disabled: classes.disabled
      }}
      inputRef={props.reference}
      {...props}
    />
  );
});

export default IOSStyleSwitch;
