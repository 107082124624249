import React,{useState} from "react";
import PropTypes from "prop-types";
import { generate } from "shortid";
import { Grid, Button } from "@mui/material";
import { useStyles } from "./style";
import enumeradorBotaoAnotacoes from "../enumeradores/enumeradorBotao";
import { HtmlTooltip } from "paginas/Graficos/ConsumoAtual/style";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from "react-redux";
import { Base } from "componentes/cores";


const ColunaMultiplosBotoes = ({ onClick, parametros }) => {
  const classes = useStyles();
  const usuarioGlobal = useSelector((state) => state.usuario);
  const [desabilita2, setDesabilita] = useState(true);

  const onClickEditar = () => onClick(enumeradorBotaoAnotacoes.Editar, parametros);
  const onClickExcluir = () => onClick(enumeradorBotaoAnotacoes.Excluir, parametros);

  const desabilita = ()=>{
    return !(parametros.idUsuario == usuarioGlobal.usuario.id);
  };

  return (
    <Grid container spacing={0} className={classes.fundoCelula}>

      <Grid item xs={6} >
        <Button
          key={generate()}
          onClick={onClickEditar}
          className={`text-white ${classes.botao}`}
          disabled={desabilita()}
        >
          <HtmlTooltip title="Editar">
            <div style={{color: desabilita() ? Base.GreyDim : Base.White}}>
            <EditIcon />
            </div>
          </HtmlTooltip>
        </Button>
      </Grid>
      <Grid item xs={6} className={classes.botaoCentral}>
        <Button key={generate()} onClick={onClickExcluir} className={`text-white ${classes.botao}`} disabled={desabilita()}>
          <HtmlTooltip title="Excluir">
            <div  style={{color: desabilita() ? Base.GreyDim : Base.White}}>
              <DeleteIcon />
            </div>
          </HtmlTooltip>
        </Button>
      </Grid>

    </Grid>
  );
};

ColunaMultiplosBotoes.propTypes = {
  onClick: PropTypes.oneOfType([PropTypes.func]).isRequired,
  parametros: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired
};

export default ColunaMultiplosBotoes;