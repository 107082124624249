import { Checkbox, FormControlLabel } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useStyles } from "./styles";

const CheckInputs = ({ setChecked, checked }) => {
  const classes = useStyles();

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <FormControlLabel
      label="Habilitar a edição"
      control={
        <Checkbox
          id="checkInput"
          value="checkInput"
          checked={checked}
          onChange={handleChange}
          className={classes.root}
          disableRipple
          color="default"
          checkedIcon={<span className={(classes.icon, classes.checkedIcon)} />}
          icon={<span className={classes.icon} />}
          inputProps={{ "aria-label": "decorative checkbox" }}
          size="small"
          label="Habilitar a edição"
        />
      }
    />
  );
};

CheckInputs.propTypes = {
  setChecked: PropTypes.oneOfType([PropTypes.func]),
  checked: PropTypes.bool
};

CheckInputs.defaultProps = {
  setChecked: () => {},
  checked: false
};

export default CheckInputs;
