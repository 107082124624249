import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

// Icones
import TuneIcon from "@mui/icons-material/Tune";

// Form Hooks
import { useForm, useWatch } from "react-hook-form";

// Redux
import { useSelector } from "react-redux";

// Componentes
import { Grid } from "@mui/material";
import MaterialInputBusca from "componentes/inputBusca";
import TabelaSemDados from "componentes/tabelaPaginada/tabelaSemDados";
import { usuarioTipoEnum } from "global/constantes";
import { InterfaceDTO } from "global/dto/interfacesDto";
import { usuarioPossuiFuncionalidade } from "servicos/funcionalidadesServico";
import theme from "themes";
import { Botao, SelectArredondado } from "../../../../componentes";
import BotaoCadastro from "../../../../componentes/botaoCadastro";
import BotaoFiltroOrdenacao from "../../../../componentes/botaoFiltroOrdenar";
import { Base } from "../../../../componentes/cores";
import ItemListaExpansivel from "../../../../componentes/itemListaExpansivel";
import Loader from "../../../../componentes/loader";
import ModalConfirmacao from "../../../../componentes/modalConfirmacao";
import Paginacao from "../../../../componentes/paginacao";

// Serviços
import {
  deletarUsuario,
  listarFiltrarPor,
  listarOrganizarPor,
  listarTipoPor
} from "../../../../servicos/usuariosServico";

// Rotas
import { RotasDTO } from "../../../../global/rotas/rotasUrlDto";

// Redux
import { store } from "../../../../global/redux";
import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";

// Styles
import { useStyles } from "./style";

// Útils
import { getTipoPerfil } from "../../../../global/getTipoPerfil";
import {
  desabilitarCliente,
  desabilitarEmpresa,
  desabilitarUnidade
} from "../../../../global/redux/modulos/usuario/actions";
import { formataCpf } from "../../../../servicos/utils";
import FiltroListagemDto from "../dto/filtroListagemDto";
import ListagemUsuarioHelper from "./helper";
import { cpfCnpjValido } from "../../../../global/utils/formatCpfCnpj";

const UsuarioAcessoListar = () => {
  const classes = useStyles();
  const history = useHistory();
  const usuarioGlobal = useSelector((state) => state.usuario);
  const { tipo } = usuarioGlobal?.usuario;

  const [paginaAtual, setPaginaAtual] = useState();
  const [totalPaginas, setTotalPaginas] = useState();

  const [carregandoUsuario, setCarregandoUsuarios] = useState(false);
  const [listaUsuarios, setListaUsuarios] = useState([]);

  const [carregamentoInicial, setCarregamentoInicial] = useState(false);
  const [campoPesquisa, setCampoPesquisa] = useState("");

  const tamanhoPaginacao = 20;

  const { register, control } = useForm({
    reValidateMode: "onSubmit"
  });

  const textoBusca = useWatch({
    control,
    name: "textoBusca",
    defaultValue: history?.location?.state?.dadosUsuario?.id?.toString() || ""
  });

  const textoPesquisa = useMemo(() => {
    return textoBusca?.trim() || "";
  }, [textoBusca]);

  useEffect(() => {
    if (getTipoPerfil(tipo)) {
      store.dispatch(desabilitarEmpresa(false));
      store.dispatch(desabilitarCliente(false));
      store.dispatch(desabilitarUnidade(false));
    }
  }, [desabilitarEmpresa, desabilitarCliente, desabilitarUnidade]);

  useEffect(() => {
    if (textoPesquisa === "" && carregamentoInicial) {
      const debounce = setTimeout(() => {
        handleListarUsuarios(
          ordemSelecionada,
          filtroSelecionado,
          tipoSelecionado,
          1
        );
      }, 1000);

      return () => clearTimeout(debounce);
    }
  }, [textoPesquisa]);

  const usuario = useSelector((state) => state.usuario);
  const {
    empresaSelecionada,
    clienteSelecionado,
    unidadeSelecionada
  } = usuario;

  const rotas = useSelector((state) => state.rotas);
  const { menu } = usuario;
  const permissaoVisualizarUsuarios =
    usuarioPossuiFuncionalidade(
      menu,
      rotas.rotaAtual.caminho,
      RotasDTO.Usuarios,
      InterfaceDTO.PermissaoUsuariosClienteProspect
    ) && usuario?.usuario?.tipoPerfil !== usuarioTipoEnum.colaborador;

  const handleListarUsuarios = async (
    ordenacao,
    filtroSelecionado,
    tipoSelecionadoParametro,
    atual
  ) => {
    try {
      setCarregandoUsuarios(true);

      const buscaPorId = history?.location?.state?.dadosUsuario?.id?.toString()
        ? "BuscaPorId"
        : "";

      const filtro = new FiltroListagemDto(
        filtroSelecionado ?? "",
        cpfCnpjValido(textoPesquisa) ?? "",
        ordenacao ?? "",
        atual ?? 1,
        tamanhoPaginacao,
        unidadeSelecionada ?? "",
        clienteSelecionado ?? "",
        empresaSelecionada ?? "",
        tipoSelecionadoParametro ?? "",
        campoPesquisa || buscaPorId || ""
      );

      const retorno = await (permissaoVisualizarUsuarios
        ? ListagemUsuarioHelper.listarTodosUsuariosClienteProspect(filtro)
        : ListagemUsuarioHelper.listarTodosUsuarios(filtro));

      if (retorno.sucesso) {
        setListaUsuarios(retorno.data?.usuarios);
        setPaginaAtual(retorno.data?.paginaAtual);
        setTotalPaginas(retorno.data?.totalPaginas);
        return;
      }

      ListagemUsuarioHelper.exibirMensagemErro(retorno.mensagem);
      setCarregandoUsuarios(false);
      setListaUsuarios([]);
      setPaginaAtual(1);
      setTotalPaginas(1);
    } catch (error) {
      ListagemUsuarioHelper.exibirMensagemErro(null);
      setCarregandoUsuarios(false);
    } finally {
      setCarregandoUsuarios(false);
      history.push({
        pathname: RotasDTO.Usuarios,
        state: { dadosUsuario: null }
      });
    }
  };

  useEffect(() => {
    setCarregamentoInicial(true);
    handleListarUsuarios(
      ordemSelecionada,
      filtroSelecionado,
      tipoSelecionado,
      1
    );
  }, []);

  const onClickNovaUsuario = () => {
    history.push(`${RotasDTO.Usuarios}/cadastro`);
  };

  const onClickEditar = (id) => {
    history.push(`${RotasDTO.Usuarios}/cadastro/${id}`);
  };

  const [modalExclusao, setModaoExclusao] = useState(false);

  const deletar = async (item) => {
    try {
      setModaoExclusao(false);
      setCarregandoUsuarios(true);
      const deletou = await deletarUsuario(item?.id, !item?.ativo);
      if (deletou) {
        handleListarUsuarios(
          ordemSelecionada,
          filtroSelecionado,
          tipoSelecionado,
          1
        );
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: `O usuario foi ${
              item?.ativo ? "inativado" : "ativado"
            } com sucesso!`
          })
        );
      }
      setCarregandoUsuarios(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoUsuarios(false);
    }
  };

  const onClickExcluir = (id) => {
    setModaoExclusao(id);
  };

  const onConfirmarExclusao = (id) => {
    try {
      deletar(id);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  const [ordemSelecionada, setOrdemSelecionada] = useState(null);
  const [filtroSelecionado, setFiltroSelecionado] = useState(null);
  const [tipoSelecionado, setTipoSelecionado] = useState(null);

  const montouComponente = useRef(false);

  useEffect(() => {
    if (montouComponente.current) {
      setCarregamentoInicial(true);
      if (carregamentoInicial) {
        handleListarUsuarios(
          ordemSelecionada,
          filtroSelecionado,
          tipoSelecionado,
          1
        );
      }
    } else montouComponente.current = true;
  }, [empresaSelecionada, clienteSelecionado, unidadeSelecionada]);

  const handleClickOrdemSelecionada = (event) => {
    const val = ordemSelecionada === event ? null : event;
    setOrdemSelecionada(val);
  };

  const handleClickFiltroSelecionado = (event) => {
    const val = filtroSelecionado === event ? null : event;
    setFiltroSelecionado(val);
  };

  const handleClickTipoSelecionado = (event) => {
    const val = tipoSelecionado === event ? null : event;
    setTipoSelecionado(val);
  };

  const handleClickAtivarAgora = async () => {
    const filtro = [];
    if (filtroSelecionado) filtro.push(filtroSelecionado);
    handleListarUsuarios(ordemSelecionada, filtro, tipoSelecionado);
  };

  const [naoExibirFiltrosTipo] = useState(false);

  const onChangePaginacao = (item, pagina) => {
    handleListarUsuarios(
      ordemSelecionada,
      filtroSelecionado,
      tipoSelecionado,
      pagina
    );
  };

  const onClickSearch = () => {
    handleListarUsuarios(
      ordemSelecionada,
      filtroSelecionado,
      tipoSelecionado,
      1
    );
  };

  return (
    <Loader loading={carregandoUsuario} className="w-auto">
      <ModalConfirmacao
        item={modalExclusao}
        onConfirmar={(item) => onConfirmarExclusao(item)}
        mensagem={`Tem certeza que deseja ${
          modalExclusao?.ativo ? "inativar" : "ativar"
        } esse Usuário?`}
        onCancelar={() => setModaoExclusao(false)}
      />
      <Grid
        container
        p={2}
        spacing={3}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <SelectArredondado
            select
            id="CampoPesquisa"
            name="CampoPesquisa"
            label="Filtrar por campo"
            placeholder="Filtrar por campo"
            valueKey="valor"
            valueName="nome"
            className={classes.select}
            dataSource={[
              { nome: "Todos", valor: "" },
              { nome: "Nome do usuário", valor: "NomeUsuario" },
              { nome: "CPF/CNPJ", valor: "CnpjCpf" },
              { nome: "Email", valor: "Email" }
            ]}
            value={campoPesquisa}
            onChange={(e) => {
              setCampoPesquisa(e.target.value);
            }}
            permiteValorBranco
            styleForm={false}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={4}>
          <MaterialInputBusca
            type="text"
            id="textoBusca"
            name="textoBusca"
            label="Buscar"
            renderIconShowHide
            searchAdornment
            defaultValue={textoBusca ?? ""}
            ref={register}
            permiteValorBranco
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={2}>
          <Botao
            type="submit"
            onClick={onClickSearch}
            label="Buscar"
            className={classes.button}
            disabled={!textoPesquisa}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={3}
          display="flex"
          justifyContent={{ sm: "flex-end" }}
        >
          <BotaoFiltroOrdenacao
            type="button"
            color={theme.color.secondaryBorderColor}
            background="transparent"
            label="Filtrar / Ordenar"
            icon={<TuneIcon />}
            className={classes.buttonFiltro}
            ordenacao={listarOrganizarPor()}
            ordenadorUm="Ordenador"
            ordenadorDois="Filtrar"
            ordemSelecionada={ordemSelecionada}
            filtros={listarFiltrarPor()}
            filtrosSelecionados={filtroSelecionado}
            tipos={naoExibirFiltrosTipo ? null : listarTipoPor()}
            tiposSelecionados={tipoSelecionado}
            onClickOrdenacao={handleClickOrdemSelecionada}
            onClickFiltro={handleClickFiltroSelecionado}
            onClickTipo={handleClickTipoSelecionado}
            onClickAtivarAgora={handleClickAtivarAgora}
          />
        </Grid>
        <Grid item xs={12}>
          <BotaoCadastro
            label="Novo usuario"
            color={theme.color.buttonColor}
            onClick={onClickNovaUsuario}
          />
        </Grid>

        {listaUsuarios && listaUsuarios.length > 0 ? (
          <>
            {listaUsuarios?.map((item, i) => {
              return (
                <ItemListaExpansivel
                  key={i}
                  label={item?.nome ?? "Usuario"}
                  color={Base.White}
                  edicao={!!item?.id}
                  exclusao={!!item?.id}
                  onClickEditar={() => onClickEditar(item?.id)}
                  onClickExcluir={() => onClickExcluir(item ?? false)}
                  ativo={item?.ativo}
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                >
                  <div>
                    <strong>CPF: </strong>
                    {formataCpf(item?.cnpjCpf) ?? ""}
                  </div>
                </ItemListaExpansivel>
              );
            })}
            <Grid
              container
              spacing={3}
              direction="row"
              justifyContent="center"
              alignItems="center"
              mt={2}
            >
              <Grid item>
                <Paginacao
                  paginaAtual={paginaAtual}
                  totalPaginas={totalPaginas}
                  onChangePagina={onChangePaginacao}
                />
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid
            container
            spacing={3}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <TabelaSemDados className={classes.container} />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Loader>
  );
};

export default UsuarioAcessoListar;
