import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  tabelaPrecos: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  tabelaPrecosCabecalho: {
    borderBottom: "4px solid #F4F4F4",
    paddingTop: "5px",
    paddingBottom: "5px",
    width: "100%",
    minHeight: "36px"
  },
  tabelaPrecosColuna: {
    display: "flex",
    padding: "0 10px",
    width: "100%",
    "& div": {
      padding: "5px 0"
    },
  },
  border: {
    borderRight: "4px solid #F4F4F4"
  }
}));