import React, { useMemo } from "react";
import PropTypes from "prop-types";

// Icones
import { BsFillEyeSlashFill, BsFillEyeFill } from "react-icons/bs";

// Cores
import getDomain from "global/getDomain";
import { Base } from "../cores";
import theme from "../../themes";

const InputTexto = React.forwardRef((props, ref) => {
  const {
    placeholder,
    onChange,
    disabled,
    onKeyDown,
    label,
    id,
    name,
    icon,
    type,
    errors,
    defaultValue,
    onShow,
    bordaQuadrada
  } = props;

  const isInvalid = useMemo(() => {
    return errors[name]?.message ?? false;
  }, [errors, name]);

  const styleInput = useMemo(
    () =>
      icon && !bordaQuadrada
        ? {
            borderLeft: 0,
            borderRight: onShow && 0,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderTopRightRadius: onShow ? 0 : "50rem",
            borderBottomRightRadius: onShow ? 0 : "50rem",
            backgroundColor: getDomain() === "luz" && Base.White
          }
        : { borderRadius: "0", borderRight: "0", borderLeft: "0" },
    [icon, onShow, bordaQuadrada]
  );

  const styleIcon = useMemo(() => {
    return {
      backgroundColor: disabled
        ? Base.GreyDisabled
        : getDomain() === "luz"
        ? Base.White
        : "transparent",
      borderTopLeftRadius: !bordaQuadrada && "50rem",
      borderBottomLeftRadius: !bordaQuadrada && "50rem",
      borderColor: isInvalid ? Base.RedAmaranth : theme.color.InBorderColor
    };
  }, [disabled, isInvalid]);

  const styleShowIcon = useMemo(() => {
    return {
      backgroundColor: getDomain() === "luz" ? Base.White : "transparent",
      borderTopRightRadius: !bordaQuadrada ? "50rem" : ".25rem",
      borderBottomRightRadius: !bordaQuadrada ? "50rem" : ".25rem",
      borderColor: isInvalid ? Base.RedAmaranth : theme.color.InBorderColor,
      cursor: "pointer"
    };
  }, [isInvalid]);

  const classNameCampo = useMemo(() => {
    let classe = "";

    if (!isInvalid) {
      if (onShow) classe = "mb-3";
      else classe = "mb-3";
    } else if (onShow) classe = "is-invalid";
    else classe = "is-invalid";

    return classe;
  }, [isInvalid, onShow]);

  return (
    <div className={icon ? "input-group input-group-lg" : "form-group"}>
      {label && <label htmlFor={id}>{label}</label>}
      {icon && (
        <div className={`input-group-prepend ${isInvalid ? "mb-0" : "mb-3"}`}>
          <span className="input-group-text" style={{ ...styleIcon }}>
            {icon}
          </span>
        </div>
      )}
      <input
        type={type}
        id={id}
        name={name}
        style={{ ...styleInput }}
        className={`form-control text-dark ${icon && "pl-0"} ${classNameCampo}`}
        aria-describedby={id}
        ref={ref}
        placeholder={placeholder}
        onChange={(e) => onChange(e)}
        disabled={disabled}
        onKeyDown={onKeyDown}
        defaultValue={defaultValue}
      />
      {onShow && (
        <div
          role="button"
          tabIndex={0}
          onClick={onShow}
          onKeyPress={onShow}
          className={`input-group-append ${isInvalid ? "mb-0" : "mb-3"}`}
        >
          <span
            className="input-group-text border-left-0"
            style={{ ...styleShowIcon }}
          >
            {type === "password" ? (
              <BsFillEyeFill color={theme.color.IconSenha} />
            ) : (
              <BsFillEyeSlashFill color={theme.color.IconSenha} />
            )}
          </span>
        </div>
      )}
      {/* {isInvalid && <div className="invalid-feedback mb-3">{isInvalid}</div>} */}
      {/* {legenda && (
        <small id={id`Legenda`} className="form-text text-muted">
          {legenda}
        </small>
      )} */}
    </div>
  );
});

InputTexto.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.oneOfType([PropTypes.func]),
  disabled: PropTypes.bool,
  onKeyDown: PropTypes.oneOfType([PropTypes.func]),
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.any]),
  type: PropTypes.string,
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  defaultValue: PropTypes.string,
  onShow: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  bordaQuadrada: PropTypes.oneOfType([PropTypes.func, PropTypes.bool])
};

InputTexto.defaultProps = {
  placeholder: "",
  onChange: () => {},
  disabled: false,
  onKeyDown: () => {},
  label: "",
  icon: true,
  type: "text",
  errors: {},
  defaultValue: "",
  onShow: false,
  bordaQuadrada: false
};

export default InputTexto;
