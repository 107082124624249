/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import EsqueletoEtapa from "../../Componentes/EsqueletoEtapa";
import AppStore from "../../../../../assets/icones/workflow/app-store-mobile-tag.png";
import PlayStore from "../../../../../assets/icones/workflow/playstore-mobile-tag.png";

const BoasVindas = ({ showButton }) => {
  const handleTexto = () => {
    return (
      <Box>
        <Box py={1}>
          Estamos muito felizes em lhe dar as boas-vindas e em ter você como
          cliente LUZ!
        </Box>
        <Box py={1}>
          Agradecemos a confiança em nossa empresa e esperamos te atender da
          melhor maneira possível por todo o tempo que permanecer conosco!
        </Box>
        <Box py={1}>Temos alguns pontos importantes para passar a você:</Box>
        <Box py={1}>
          Baixe o App "SUA LUZ" no App Store ou "LUZ" no Play store, para ter o
          controle de consumo e acesso a todas as funcionalidades abaixo:
        </Box>
      </Box>
    );
  };

  const handleAppleStore = () => {
    window.open(
      `${process.env.REACT_APP_APPLE_STORE_API_URL}/id1644930247`,
      "_target"
    );
  };

  const handlePlayStore = () => {
    window.open(
      `${process.env.REACT_APP_PLAY_STORE_API_URL}?id=br.com.sualuz.app`,
      "_target"
    );
  };

  return (
    <>
      <EsqueletoEtapa
        titulo="Bem-vindo ao futuro da energia!"
        subtitulo={handleTexto()}
        esconderBotaoProximo={false}
        showButton={showButton}
      />

      <Box
        display="flex"
        justifyContent={{ xs: "space-around", lg: "end" }}
        pt={3}
      >
        <Box onClick={handleAppleStore}>
          <Box
            component="img"
            src={AppStore}
            alt="app-store"
            sx={{ cursor: "pointer" }}
          />
        </Box>
        <Box ml={{ lg: 3 }} onClick={handlePlayStore}>
          <Box
            component="img"
            src={PlayStore}
            alt="play-store"
            sx={{ cursor: "pointer" }}
          />
        </Box>
      </Box>
    </>
  );
};

BoasVindas.propTypes = {
  showButton: PropTypes.bool
};

BoasVindas.defaultProps = {
  showButton: false
};

export default BoasVindas;
