import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";

import {
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField
} from "@mui/material";

import ClearIcon from "@mui/icons-material/Clear";

import { useStyles } from "./style";

const SelectCustomizado = React.forwardRef((props, ref) => {
  const {
    id,
    name,
    value,
    onChange,
    dataSource,
    valueKey,
    valueName,
    label,
    errors,
    disabled
  } = props;

  const classes = useStyles();

  const onClickClear = useCallback(() => {
    onChange({ target: { value: "" } });
  }, [onChange]);

  const clearButton = useMemo(
    () =>
      value && !disabled ? (
        <InputAdornment position="start" className={classes.clearButton}>
          <IconButton
            onClick={onClickClear}
            onMouseDown={onClickClear}
            size="small"
          >
            <ClearIcon fontSize="small" />
          </IconButton>
        </InputAdornment>
      ) : null,
    [value, classes.clearButton, onClickClear]
  );

  return (
    <FormControl
      variant="outlined"
      fullWidth
      className={`${classes.formControl}`}
    >
      <TextField
        id={id}
        name={name}
        select
        variant="outlined"
        fullWidth
        margin="dense"
        value={value}
        onChange={onChange}
        classes={{ root: classes.select }}
        inputRef={ref}
        disabled={disabled}
        {...(!value && { label })}
        InputProps={{ endAdornment: clearButton }}
        error={Boolean(errors[name]?.message)}
        helperText={errors[name]?.message ?? ""}
      >
        {dataSource?.length
          ? dataSource.map((item, i) => {
              return (
                <MenuItem key={i} value={String(item[valueKey])}>
                  {item[valueName]}
                </MenuItem>
              );
            })
          : []}
      </TextField>
    </FormControl>
  );
});

SelectCustomizado.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.any]),
  onChange: PropTypes.oneOfType([PropTypes.func]),
  dataSource: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  valueKey: PropTypes.string,
  valueName: PropTypes.string,
  label: PropTypes.string,
  errors: PropTypes.oneOfType([PropTypes.object]),
  disabled: PropTypes.bool
};

SelectCustomizado.defaultProps = {
  value: "",
  onChange: () => {},
  dataSource: [],
  valueKey: "",
  valueName: "",
  label: "",
  errors: {},
  disabled: false
};

export default SelectCustomizado;
