import IconeClick from "../../../assets/jornadaCliente/icone-click.png";
import IconeRecibo from "../../../assets/jornadaCliente/icone-recibo.png";
import IconeBateria from "../../../assets/jornadaCliente/icone-bateria.png";
import IconePorquinho from "../../../assets/jornadaCliente/icone-porquinho-luz.png";
import IconeCelular from "../../../assets/jornadaCliente/icone-celular.png";
import IconeMaos from "../../../assets/jornadaCliente/icone-maos.png";
import IconeClickBranco from "../../../assets/jornadaCliente/icone-click-branco.png";
import IconeUser from "../../../assets/jornadaCliente/icone-user-branco.png";
import Notebook from "../../../assets/jornadaCliente/notebook.png";
import Medidor from "../../../assets/jornadaCliente/medidor.png";

export const dados1 = [
  {
    icone: IconeClick,
    texto: (
      <h3>
        <span>Flexibilidade total</span> do seu consumo
      </h3>
    )
  },
  {
    icone: IconeRecibo,
    texto: (
      <h3>
        A <span>LUZ</span> é responsável por executar todas as{" "}
        <span>atividades e obrigações necessárias</span>.
      </h3>
    )
  },
  {
    icone: IconeBateria,
    texto: (
      <h3>
        Energia <span>renovável</span>
      </h3>
    )
  },
  {
    icone: IconePorquinho,
    texto: (
      <h3>
        <span>Sem obras</span> ou investimentos iniciais
      </h3>
    )
  },
  {
    icone: IconeCelular,
    texto: (
      <h3>
        <span>Atendimento especializado</span> para sua empresa
      </h3>
    )
  },
  {
    icone: IconeClick,
    texto: (
      <h3>
        Controle e previsibilidade do consumo na palma da mão, através de uma
        <span>plataforma completa de gestão</span> de energia
      </h3>
    )
  }
];

export const dados2 = [
  {
    icone: IconeMaos,
    texto: (
      <h3>
        <span>Credibilidade, confiança e solidez:</span> avalie o tempo de
        mercado e a expertise da empresa no setor de energia.
      </h3>
    )
  },
  {
    icone: IconeClickBranco,
    texto: (
      <h3>
        <span>Produto e Condições comerciais:</span>
        <br /> o portfólio de soluções da empresa deve ser completo e as
        condições comerciais personalizadas de acordo com a demanda do seu
        negócio.
      </h3>
    )
  },
  {
    icone: IconeUser,
    texto: (
      <h3>
        <span>Pós-venda e atendimento:</span>
        <br /> analise se a qualidade dos serviços se mantém no pós-venda e se o
        que foi prometido é cumprido. Considere também o atendimento, que deve
        ser eficaz e ágil.
      </h3>
    )
  }
];

export const dados3 = [
  {
    icone: IconeClickBranco,
    titulo: (
      <h2>
        Desconto <span>Garantido</span>
      </h2>
    ),
    texto: (
      <ul>
        <li>
          Produto criado pelo Grupo Delta em 2015, com o objetivo de trazer
          maior previsibilidade aos nossos clientes.
        </li>
        <li>
          Percentual de Desconto Garantido em contrato sobre o custo total que a
          unidade consumidora teria no mercado cativo, incluindo todos os
          impostos.
        </li>
        <li>Otimize seu consumo de energia com total flexibilidade</li>
        <li>Possibilidade de certificação sustentável | I-REC</li>
      </ul>
    )
  }
];

export const listaJornada = [
  {
    titulo: "Upload fatura",
    items: ["Simulação de planos", "Estudo", "Proposta", "Minuta"]
  },
  {
    titulo: "SITE",
    items: [
      "Upload fatura",
      "Apresentação de plano",
      "Estudo resumido",
      "Minuta"
    ]
  }
];

export const lista = [
  {
    titulo: "AJUSTE DE PLANOS",
    items: [
      "A+3 - min 36 meses",
      "A+4 - min 48 meses",
      "A+5 - min 60 meses",
      "Inicio de simulação: 6 meses a frente",
      "Fim de contrato: 31/12/aaaa",
      "Apresentar período de fornecimento (início e fim de contrato)"
    ]
  },
  {
    titulo: "IMPLEMENTAÇÕES",
    items: [
      "Atualização de minuta",
      "Atualização de proposta",
      "Criação de relatório",
      "Criação de relatório resumido"
    ]
  }
];

export const quemSomos = {
  titulo: <h1>QUEM SOMOS?</h1>,
  conteudo: (
    <>
      <h3>
        A LUZ é uma fornecedora digital de energia e nosso objetivo é trazer{" "}
        <strong>economia e opção de escolha</strong> aos consumidores
        facilitando o acesso ao mercado livre de energia.
      </h3>
      <br />
      <h3>
        Além disso, oferecemos uma{" "}
        <strong>plataforma exclusiva de gestão digital</strong> para
        acompanhamento das informações de consumo, entregando ainda mais{" "}
        <strong>controle e transparência.</strong>
      </h3>
    </>
  )
};

export const medidor = [
  {
    imagem: Medidor,
    legendaImagem: (
      <p>
        *Imagem meramente
        <br />
        ilustrativa
      </p>
    ),
    titulo: <h2>MEDIDOR INTELIGENTE</h2>,
    conteudo: (
      <h4>
        Instalamos um medidor inteligente para coletar todos os dados do seu
        consumo.
      </h4>
    )
  },
  {
    imagem: Notebook,
    titulo: <h2>PLATAFORMA LUZ</h2>,
    conteudo: (
      <h4>
        Nossa plataforma de gestão de energia disponibiliza informações do seu
        consumo de maneira simples e intuitiva.
      </h4>
    )
  }
];

export const grupoDelta = {
  esquerda: {
    texto: (
      <h3>
        Fazemos parte do <strong>Grupo Delta Energia</strong>, empresa com{" "}
        <strong>
          <span>mais de 20 anos</span> de experiência
        </strong>{" "}
        no setor elétrico e responsável por transacionar{" "}
        <strong>
          <span>8% de todo o consumo</span> de energia do Brasil.
        </strong>
      </h3>
    ),
    cards: [
      {
        titulo: (
          <h2>
            +1000
            <br />
            Clientes
          </h2>
        ),
        texto: <p>No mercado livre</p>
      },
      {
        titulo: <h2>+de 1 Bilhão</h2>,
        texto: (
          <p>
            Sob gestão em Fundos de Investimento com foco em comercialização de
            energia
          </p>
        )
      },
      {
        titulo: <h2>+de 8 %</h2>,
        texto: <p>do consumo de energia do Brasil Negociados por mês</p>
      }
    ]
  }
};
