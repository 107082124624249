/* eslint-disable no-sequences */
/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";

import Tooltip from "@mui/material/Tooltip";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import { useSelector } from "react-redux";
import { store } from "global/redux";
import {
  salvarMenu,
  deslogarUsuario
} from "global/redux/modulos/usuario/actions";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import {
  menuExibir,
  menuFechar,
  menuFecharTotalmente,
  menuExibirTotalmente,
  cabecalho,
  menuNaoExibirMetade,
  usina
} from "global/redux/modulos/menu/actions";

import getDomain from "global/getDomain";

import { createTheme } from "@mui/material/styles";
import withStyles from "@mui/styles/withStyles";
import useMediaQuery from "@mui/material/useMediaQuery";

// eslint-disable-next-line import/no-extraneous-dependencies
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import ListItemIcon from "@mui/material/ListItemIcon";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import themes from "themes";
import shortid from "shortid";
import MenuImg from "../../assets/menu.png";
import { salvarMenuSelecionadoColecao } from "../../global/redux/modulos/rotas/actions";
import { RotasDTO } from "../../global/rotas/rotasUrlDto";
import { buscarPorId } from "../../servicos/usuariosServico";
import { listarPermissoes } from "../../servicos/loginServico";
import { Logo, LogoLuz, Wrapper } from "./style";
import SelecaoUnidade from "./componentes/selecaoClienteUnidade";

const theme = createTheme();

// eslint-disable-next-line react/prop-types
const Menu = ({ role }) => {
  const drawerWidth = 240;
  const useStyles = makeStyles(() => ({
    root: {
      display: "flex"
    },
    menuButton: {
      marginRight: 36
    },
    iconeCentro: {
      display: "inline",
      textAlign: "center",
      color: themes.color.novoBtMenuTextColor
    },
    hide: {
      display: "none"
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap"
    },
    drawerOpen: {
      // borderTopRightRadius: getDomain() === 'luz' ? 35 : 0,
      // borderBottomRightRadius: getDomain() === 'luz' ? 35 : 0,
      backgroundColor: `${
        role === "Perfil Gráficos Delta" ||
        role === "Perfil Gráficos Delta Cliente"
          ? "#4A494A"
          : themes.color.primary
      } !important`,
      width: drawerWidth,
      transition: `${theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })} !important`
    },
    drawerClose: {
      backgroundColor: `${
        role === "Perfil Gráficos Delta" ||
        role === "Perfil Gráficos Delta Cliente"
          ? "#4A494A"
          : themes.color.primary
      } !important`,
      transition: `${theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })} !important`,
      overflowX: "hidden !important",
      width: `calc(${theme.spacing(9)} + 25px) !important`,
      [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(9)} + 25px) !important`
      }
    },
    toolbar: {
      display: "flex !important",
      alignItems: "center !important",
      justifyContent: "flex-end !important",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar
    },
    centerItem: {
      padding: 10
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3)
    },
    listIcon: {
      minWidth: "30px !important",
      marginRight: 10,
      color: `${themes.color.novoBtMenuTextColor} !important`
    },
    displayContents: {
      display: "contents"
    },
    "@global": {
      "*::-webkit-scrollbar": {
        width: "0.4em !important"
      },
      "*::-webkit-scrollbar-track": {
        "-webkit-box-shadow": "inset 0 0 4px rgba(0,0,0,0.00) !important"
      },
      "*::-webkit-scrollbar-thumb": {
        background: `${themes.appBar.menuitemcolor} !important`,
        borderRadius: "8px !important",
        height: "4px !important"
      }
    },
    list: {
      color: themes.color.btMenuTextColor
    },
    listItem: {
      backgroundColor: themes.color.novoItemBaseColor,
      marginBottom: 10,
      fontSize: 15,
      color: `${
        role === "Perfil Gráficos Delta" ||
        role === "Perfil Gráficos Delta Cliente"
          ? "white !important"
          : themes.color.novoItemTextColor
      }`,
      paddingTop: 8,
      paddingBottom: 8,
      "&:hover": {
        color: `${
          role === "Perfil Gráficos Delta" ||
          role === "Perfil Gráficos Delta Cliente"
            ? "white"
            : themes.color.novoItemTextColor
        }`,
        backgroundColor: `${
          role === "Perfil Gráficos Delta" ||
          role === "Perfil Gráficos Delta Cliente"
            ? "transparent"
            : themes.color.novoListItemBackgroundHover
        }`
      }
    },
    iconeMenu: {
      color: `${themes.color.btMenuTextColor} !important`
    },
    selected: {
      color: themes.color.novoItemTextColor,
      backgroundColor: `${
        role === "Perfil Gráficos Delta" ||
        role === "Perfil Gráficos Delta Cliente"
          ? "transparent"
          : themes.color.novoListItemBackgroundHover
      }`
    },
    iconeMenuEsquerda: {
      transform: "scaleX(-1)",
      position: "absolute !important",
      left: 0,
      color: `${themes.color.btMenuTextColor} !important`
    },
    iconeAjuste: {
      width: 18,
      webkitFilter:
        getDomain() === "luz"
          ? "invert(46%) sepia(76%) saturate(4726%) hue-rotate(300deg) brightness(102%) contrast(96%)"
          : role === "Perfil Gráficos Delta" ||
            role === "Perfil Gráficos Delta Cliente"
          ? "invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%)"
          : "none",
      filter:
        getDomain() === "luz"
          ? "invert(46%) sepia(76%) saturate(4726%) hue-rotate(300deg) brightness(102%) contrast(96%)"
          : role === "Perfil Gráficos Delta" ||
            role === "Perfil Gráficos Delta Cliente"
          ? "invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%)"
          : "none"
    },
    sair: {
      width: "100%",
      textAlign: "center",
      marginTop: 30,
      marginBottom: 30,
      "& a": {
        position: "relative",
        fontSize: 16,
        color: `${
          role === "Perfil Gráficos Delta" ||
          role === "Perfil Gráficos Delta Cliente"
            ? "white"
            : themes.color.novoItemTextColor
        }`,
        paddingBottom: 2,
        borderBottom: `1px solid ${themes.color.novoItemTextColor}`
      },
      "& a:hover": {
        textDecoration: "none"
      }
    },
    sairIcon: {
      fontSize: 18,
      marginLeft: 8,
      color: `${
        role === "Perfil Gráficos Delta" ||
        role === "Perfil Gráficos Delta Cliente"
          ? "white"
          : themes.color.novoItemTextColor
      }`
    },
    obras: {
      width: "100%",
      textAlign: "center",
      fontSize: 28,
      fontWeight: "bold",
      color: "white"
    }
  }));

  const TooltipCustomizado = withStyles({
    tooltip: {
      fontSize: 22,
      color: themes.color.textoTooltip,
      backgroundColor: themes.color.bgToolTip
    }
  })(Tooltip);

  const usuario = useSelector((state) => state.usuario);
  const stateMenu = useSelector((state) => state.menu);
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const url = "";

  const { menu } = usuario;
  const obterPermissoesMenu = useCallback(async () => {
    try {
      const usuarioPerfil = await buscarPorId(usuario?.usuario?.id);
      if (usuarioPerfil?.data) {
        const resultado = await listarPermissoes(usuario?.token);
        if (resultado?.data) {
          if (
            resultado?.data?.profileList?.functionalityList &&
            resultado?.data?.profileList?.functionalityList.length > 0
          )
            store.dispatch(
              salvarMenu([...resultado?.data?.profileList?.functionalityList])
            );
        }
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  }, [usuario.usuario.id]);

  useEffect(() => {
    if (!menu?.length) obterPermissoesMenu();
  }, [menu, obterPermissoesMenu]);

  const retornaUrl = (item) => {
    // eslint-disable-next-line no-shadow
    let url = "/";

    const menuAtivo = item?.functionality?.funcionalidadeColecao.filter(
      (subMenu) => subMenu.ativo
    );

    if (item?.functionality?.caminho)
      url = RotasDTO[item?.functionality?.caminho];
    if (item?.functionality?.funcionalidadeColecao?.length)
      url = RotasDTO[menuAtivo[0]?.caminho];
    return url;
  };

  const salvarMenuColecao = (colecao) => {
    store.dispatch(salvarMenuSelecionadoColecao([...colecao]));
  };

  function ordenarMenu(x, y) {
    if (x?.functionality?.ordenacao && y?.functionality?.ordenacao)
      return x?.functionality?.ordenacao - y?.functionality?.ordenacao;
    return true;
  }
  const closeMenu = () => {
    store.dispatch(menuFechar());
  };

  const classes = useStyles();
  const [open, setOpen] = React.useState(!matches);
  const [listaUsinas, setListaUsinas] = React.useState([]);

  const handleDrawerOpen = () => {
    setOpen(true);
    // eslint-disable-next-line no-unused-expressions
    matches && store.dispatch(menuFechar());
  };

  const handleDrawerClose = () => {
    setOpen(false);
    // eslint-disable-next-line no-unused-expressions
    matches && store.dispatch(menuExibir());
  };

  const onToggleMenu = () => {
    if (stateMenu.visivel) {
      store.dispatch(menuFechar());
      // eslint-disable-next-line no-unused-expressions
      matches && setOpen(false);
    } else {
      store.dispatch(menuExibir());
    }
  };

  const toggleRecolher = () => {
    setOpen(true);
    if (stateMenu.visivelTotalmente) {
      store.dispatch(menuFecharTotalmente());
      store.dispatch(menuNaoExibirMetade());
    } else {
      store.dispatch(menuExibirTotalmente());
      store.dispatch(menuNaoExibirMetade());
    }
  };

  useEffect(() => {
    if (stateMenu.visivelMetade) {
      setOpen(false);
      store.dispatch(menuExibirTotalmente());
    } else {
      setOpen(true);
    }
  }, [stateMenu.visivelMetade]);

  useEffect(() => {
    store.dispatch(cabecalho(open));
  }, [open]);

  const onClickSair = () => {
    store.dispatch(deslogarUsuario());
    window.location.reload(true);
  };

  const handleIcone = (hash) => {
    return `${process.env.REACT_APP_ICONE_PROFILE_API_URL}/${hash}`;
  };

  useEffect(() => {
    getUsinas();
  }, []);

  const getUsinas = async () => {
    fetch(
      "https://apiapp.sualuz.com.br/wpusinas/index.php/wp-json/wp/v2/pages?per_page=99&orderby=id&order=asc",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        }
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setListaUsinas(data);
      });
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      {stateMenu.visivelTotalmente && (
        <Drawer
          ModalProps={{ onBackdropClick: () => onToggleMenu() }}
          open={stateMenu.visivel}
          variant={matches ? "permanent" : "persistent"}
          className={`shadow-lg ${clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })}`}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open
            })
          }}
        >
          <div className={classes.toolbar}>
            {open ? (
              <>
                {matches && (
                  <IconButton
                    onClick={toggleRecolher}
                    className={classes.iconeMenuEsquerda}
                  >
                    <DoubleArrowIcon />
                  </IconButton>
                )}
                <IconButton
                  onClick={matches ? handleDrawerClose : onToggleMenu}
                  className={classes.iconeMenu}
                >
                  <ChevronLeftIcon />
                </IconButton>
              </>
            ) : (
              <>
                {matches ? (
                  <>
                    <IconButton
                      onClick={toggleRecolher}
                      className={classes.iconeMenuEsquerda}
                    >
                      <DoubleArrowIcon />
                    </IconButton>
                    <IconButton
                      onClick={handleDrawerOpen}
                      className={classes.iconeMenu}
                    >
                      <ChevronRightIcon />
                    </IconButton>
                  </>
                ) : (
                  <IconButton
                    onClick={onToggleMenu}
                    className={classes.iconeMenu}
                  >
                    <ChevronLeftIcon />
                  </IconButton>
                )}
              </>
            )}
          </div>
          <Wrapper>
            <Link to="/" onClick={closeMenu}>
              {getDomain() === "luz" ? (
                <LogoLuz
                  src={`${
                    process.env.PUBLIC_URL
                  }/themes/${getDomain()}/assets/logo-424x256.png`}
                />
              ) : role !== "Perfil Gráficos Delta" ||
                role !== "Perfil Gráficos Delta Cliente" ? (
                <Logo
                  src={`${
                    process.env.PUBLIC_URL
                  }/themes/${getDomain()}/assets/logo-424x256.png`}
                />
              ) : (
                <Logo
                  src={`${
                    process.env.PUBLIC_URL
                  }/themes/${getDomain()}/assets/logo-217x132.png`}
                />
              )}
            </Link>
            {open && (
              <div className={classes.centerItem}>
                {role !== "Perfil Gráficos Delta" &&
                  role !== "Perfil Gráficos Delta Cliente" && (
                    <SelecaoUnidade />
                  )}
              </div>
            )}
          </Wrapper>
          <>
            {role === "Perfil Gráficos Delta" ||
              (role === "Perfil Gráficos Delta Cliente" && open === true && (
                <div className={classes.obras}>
                  Obras em
                  <br />
                  Andamento
                </div>
              ))}
          </>
          <List className={classes.list}>
            {role === "Perfil Gráficos Delta" ||
            role === "Perfil Gráficos Delta Cliente"
              ? listaUsinas?.map((item) => {
                  return (
                    item?.acf?.ativar_usina && (
                      <React.Fragment key={shortid.generate()}>
                        <ListItem
                          component={NavLink}
                          to="/investimentos/rota-usinas"
                          key={shortid.generate()}
                          className={`${classes.listItem} ${classes.selected}`}
                          onClick={() => {
                            // eslint-disable-next-line no-unused-expressions
                            store.dispatch(
                              usina({
                                id: item?.id,
                                rotulo: item?.title?.rendered
                              })
                            );
                          }}
                        >
                          <ListItemIcon
                            key={shortid.generate()}
                            className={classes.listIcon}
                          >
                            <img
                              alt="ajuste"
                              src={MenuImg}
                              className={classes.iconeAjuste}
                            />
                          </ListItemIcon>
                          <ListItemText
                            key={shortid.generate()}
                            primaryTypographyProps={{
                              style: {
                                whiteSpace: "normal",
                                fontSize: 13,
                                fontWeight: "bold"
                              }
                            }}
                            primary={item?.title?.rendered}
                          />
                        </ListItem>
                      </React.Fragment>
                    )
                  );
                })
              : menu &&
                menu
                  .filter(
                    (item) =>
                      item.functionality.idTipoFuncionalidade < 5 &&
                      item?.functionality?.ativo
                  )
                  .sort(ordenarMenu)
                  .map((item) => {
                    // eslint-disable-next-line no-shadow
                    const url = retornaUrl(item);
                    const menuUrl = localStorage.getItem("menuUrl").split("/");
                    const pathName = typeof url === "string" && url?.split("/");
                    return (
                      <React.Fragment key={shortid.generate()}>
                        {url && (
                          <ListItem
                            component={NavLink}
                            to={url ?? "./"}
                            key={shortid.generate()}
                            className={`${classes.listItem} ${
                              menuUrl[1] === pathName[1] && classes.selected
                            }`}
                            onClick={() => {
                              // eslint-disable-next-line no-unused-expressions
                              !matches && closeMenu();
                              salvarMenuColecao(
                                item?.functionality?.funcionalidadeColecao,
                                item
                              );
                            }}
                          >
                            {open === false ? (
                              <>
                                <TooltipCustomizado
                                  key={shortid.generate()}
                                  placement="right"
                                  title={item?.functionality?.nome}
                                  followCursor
                                >
                                  <ListItemIcon
                                    key={shortid.generate()}
                                    onClick={() => {
                                      // eslint-disable-next-line no-unused-expressions
                                      !matches && closeMenu();
                                      salvarMenuColecao(
                                        item?.functionality
                                          ?.funcionalidadeColecao,
                                        item
                                      );
                                    }}
                                    className={classes.iconeCentro}
                                  >
                                    {item?.functionality?.icone !== null ? (
                                      <img
                                        alt="menu"
                                        // eslint-disable-next-line no-return-assign
                                        onError={(e) => (
                                          // eslint-disable-next-line no-sequences
                                          (e.target.onerror = null),
                                          (e.target.src = MenuImg)
                                        )}
                                        src={handleIcone(
                                          item?.functionality?.icone
                                        )}
                                        className={classes.iconeAjuste}
                                      />
                                    ) : (
                                      <img
                                        alt="ajuste"
                                        src={MenuImg}
                                        className={classes.iconeAjuste}
                                      />
                                    )}
                                  </ListItemIcon>
                                </TooltipCustomizado>
                              </>
                            ) : (
                              // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                              <div
                                onClick={() => {
                                  // eslint-disable-next-line no-unused-expressions
                                  !matches && closeMenu();
                                  salvarMenuColecao(
                                    item?.functionality?.funcionalidadeColecao,
                                    item
                                  );
                                }}
                                className={classes.displayContents}
                              >
                                <ListItemIcon
                                  key={shortid.generate()}
                                  className={classes.listIcon}
                                >
                                  {item?.functionality?.icone !== null ? (
                                    <img
                                      alt="ajuste"
                                      onError={(e) => (
                                        (e.target.onerror = null),
                                        (e.target.src = MenuImg)
                                      )}
                                      src={handleIcone(
                                        item?.functionality?.icone
                                      )}
                                      width="18"
                                      className={classes.iconeAjuste}
                                    />
                                  ) : (
                                    <img
                                      alt="ajuste"
                                      src={MenuImg}
                                      className={classes.iconeAjuste}
                                    />
                                  )}
                                </ListItemIcon>
                                <ListItemText
                                  key={shortid.generate()}
                                  primaryTypographyProps={{
                                    style: {
                                      whiteSpace: "normal",
                                      fontSize: 15,
                                      fontWeight: "bold"
                                    }
                                  }}
                                  primary={item?.functionality?.nome ?? "Item"}
                                />
                              </div>
                            )}
                          </ListItem>
                        )}
                      </React.Fragment>
                    );
                  })}

            <div className={classes.sair}>
              <a href={url} onClick={onClickSair}>
                Sair da conta
              </a>
              <ExitToAppIcon className={classes.sairIcon} />
            </div>
          </List>
        </Drawer>
      )}
    </div>
  );
};

export default Menu;
