import { Tune } from "@mui/icons-material";
import { Box, Grid } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useHistory } from "react-router-dom";
import shortid from "shortid";
import {
  Botao,
  BotaoCadastro,
  Loader,
  Paginacao
} from "../../../../componentes";
import { Base } from "../../../../componentes/cores";
import TabelaSemDados from "../../../../componentes/tabelaPaginada/tabelaSemDados";
import { useStyles } from "./style";

import BotaoFiltroOrdenacao from "../../../../componentes/botaoFiltroOrdenar";
import MaterialInputBusca from "../../../../componentes/inputBusca";
import ItemListaExpansivel from "../../../../componentes/itemListaExpansivel";
import ModalLuz from "../../../../componentes/modalLuz";
import { RotasDTO } from "../../../../global/rotas/rotasUrlDto";
import {
  listarFiltrarPor,
  listarOrganizarPor
} from "../../../../servicos/usinasLuzServico";
import { formataCnpj } from "../../../../servicos/utils";
import themes from "../../../../themes";
import FiltroDto from "./Dto/filtroDto";
import UsinasLuzHelper from "./helper";
import { cpfCnpjValido } from "../../../../global/utils/formatCpfCnpj";

const UsinasLuz = () => {
  const { register, control } = useForm({
    reValidateMode: "onSubmit"
  });
  const classes = useStyles();
  const history = useHistory();
  const [carregandoUsinas, setCarregandoUsinas] = useState(false);
  const [listagemUsinas, setListagemUsinas] = useState([]);
  const [totalPaginas, setTotalPaginas] = useState();
  const [paginaAtual, setPaginaAtual] = useState();
  const [carregamentoInicial, setCarregamentoInicial] = useState(false);
  const [modalAcao, setModalAcao] = useState(false);
  const [itemSelecionado, setItemSelecionado] = useState(null);
  const [ordemSelecionada, setOrdemSelecionada] = useState("");
  const [filtroSelecionado, setFiltroSelecionado] = useState("");
  const [ordemColunaSelecionada, setOrdemColunaSelecionada] = useState("");
  const [valorOrdenacao, setValorOrdenacao] = useState("");
  const [modalMensagem, setModalMensagem] = useState("");

  const handleClickOrdemSelecionada = (event) => {
    const val = ordemSelecionada === event ? "" : event;
    setOrdemSelecionada(val);
  };

  const handleClickFiltroSelecionado = (event) => {
    const val = filtroSelecionado === event ? "" : event;
    setFiltroSelecionado(val);
  };

  const handleClickSortOrderSelecionado = (valor, idCampoSelecionado) => {
    const val =
      ordemColunaSelecionada === idCampoSelecionado && valor === ""
        ? ""
        : idCampoSelecionado;
    setOrdemColunaSelecionada(val);
    setValorOrdenacao(valor);
  };

  const textoBusca = useWatch({
    control,
    name: "textoBusca",
    defaultValue: ""
  });

  const textoPesquisa = useMemo(() => {
    return textoBusca?.length >= 3 ? textoBusca?.trim() : "";
  }, [textoBusca]);

  const onClickNova = () => {
    history.push(`${RotasDTO.UsinasLuz}/cadastro`);
  };

  const onClickEditar = (id) => {
    history.push(`${RotasDTO.UsinasLuz}/cadastro/${id}`);
  };

  const onClickExcluir = (item) => {
    setModalAcao(true);
    setModalMensagem(
      `Deseja ${item.situacao === "AT" ? "inativar" : "ativar"}  a "${
        item?.nomeFantasia
      }"`
    );
    setItemSelecionado(item);
  };

  const obterListagem = async ({ pagina }) => {
    const tamanhoPaginacao = 20;

    const filtro = new FiltroDto(
      pagina || paginaAtual,
      tamanhoPaginacao,
      cpfCnpjValido(textoPesquisa),
      valorOrdenacao ?? "",
      filtroSelecionado
    );

    const { data, sucesso, mensagem } = await UsinasLuzHelper.listarUsinasLuz(
      filtro,
      setCarregandoUsinas
    );
    if (!sucesso) {
      UsinasLuzHelper.exibirErro(mensagem);
      setCarregandoUsinas(false);
      setPaginaAtual(null);
      setTotalPaginas(null);
      setListagemUsinas([]);
    }
    setTotalPaginas(data?.totalPaginas);
    setPaginaAtual(data?.paginaAtual);
    setListagemUsinas(data?.usinasLuz);
  };

  useEffect(() => {
    if (textoPesquisa === "" && carregamentoInicial) {
      const debounce = setTimeout(() => {
        obterListagem({ pagina: 1 });
      }, 1000);

      return () => clearTimeout(debounce);
    }
  }, [textoPesquisa]);

  useEffect(() => {
    obterListagem({ pagina: 1 });
    setCarregamentoInicial(true);
  }, []);

  const onChangePaginacao = (item, pagina) => {
    setCarregamentoInicial(true);
    setPaginaAtual(pagina);
    if (carregamentoInicial) {
      obterListagem({ pagina });
    }
  };

  const atualizarUsinasLuz = async () => {
    const model = {
      id: itemSelecionado?.id,
      situacao: itemSelecionado?.situacao === "AT" ? "IN" : "AT"
    };

    const { sucesso, mensagem } = await UsinasLuzHelper.atualizarUsinasLuz(
      model,
      setCarregandoUsinas
    );
    if (!sucesso) {
      UsinasLuzHelper.exibirErro(mensagem);
      setCarregandoUsinas(false);
    }

    setModalAcao(false);
    obterListagem({ pagina: 1 });
  };

  const onClickAtivarAgora = async () => {
    obterListagem({ pagina: 1 });
  };

  const handleModalMensagem = (mensagem) => {
    return (
      <Box component="div" fontWeight="700" textAlign="center">
        {mensagem}
      </Box>
    );
  };

  const onClickSearch = () => {
    obterListagem({ pagina: 1 });
  };

  return (
    <Loader loading={carregandoUsinas}>
      <ModalLuz
        item={modalAcao}
        mensagem={handleModalMensagem(modalMensagem)}
        onCancelar={() => {
          setModalAcao(false);
          setModalMensagem("");
        }}
        onConfirmar={() => atualizarUsinasLuz()}
      />

      <Grid
        container
        spacing={3}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={8} sm={5}>
          <MaterialInputBusca
            type="text"
            id="textoBusca"
            name="textoBusca"
            label="Buscar"
            renderIconShowHide
            searchAdornment
            permiteValorBranco
            defaultValue={textoBusca ?? ""}
            ref={register}
          />
        </Grid>
        <Grid item xs={4} sm={2}>
          <Botao
            type="submit"
            onClick={onClickSearch}
            label="Buscar"
            className={classes.button}
            disabled={!textoPesquisa}
          />
        </Grid>
        <Grid sm={2} />
        <Grid
          item
          xs={12}
          sm={3}
          display="flex"
          justifyContent={{ sm: "flex-end" }}
        >
          <BotaoFiltroOrdenacao
            type="button"
            color={themes.color.secondaryBorderColor}
            background="transparent"
            label="Filtrar / Ordernar"
            icon={<Tune />}
            className={classes.buttonFiltro}
            ordenacaoColuna={listarOrganizarPor()}
            ordenadorUm="Ordenador"
            ordenadorDois="Filtrar"
            ordemColunaSelecionada={ordemColunaSelecionada}
            filtros={listarFiltrarPor()}
            filtrosSelecionados={filtroSelecionado}
            onClickOrdenacao={handleClickOrdemSelecionada}
            onClickFiltro={handleClickFiltroSelecionado}
            onClickAtivarAgora={onClickAtivarAgora}
            onClickSortOrder={handleClickSortOrderSelecionado}
          />
        </Grid>
        <Grid item xs={12}>
          <BotaoCadastro
            label="Nova Usina"
            color={themes.color.buttonColor}
            onClick={onClickNova}
          />
        </Grid>

        {listagemUsinas && listagemUsinas?.length > 0 ? (
          <>
            {listagemUsinas.map((item) => {
              return (
                <ItemListaExpansivel
                  key={shortid.generate()}
                  label={item?.nomeFantasia || item?.razaoSocial}
                  color={Base.White}
                  edicao={!!item?.id}
                  exclusao={!!item?.id}
                  onClickEditar={() => onClickEditar(item?.id)}
                  onClickExcluir={() => onClickExcluir(item)}
                  ativo={item?.situacao === "AT"}
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                >
                  <div>
                    <strong>Descrição: </strong>
                    {formataCnpj(item?.cpfCnpj) ?? ""}
                  </div>
                </ItemListaExpansivel>
              );
            })}
            <Grid
              container
              spacing={3}
              direction="row"
              justifyContent="center"
              alignItems="center"
              marginTop={2}
            >
              <Grid item>
                <Paginacao
                  paginaAtual={paginaAtual}
                  totalPaginas={totalPaginas}
                  onChangePagina={onChangePaginacao}
                />
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid
            container
            spacing={3}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <TabelaSemDados className={classes.container} />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Loader>
  );
};

export default UsinasLuz;
