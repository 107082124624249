import React, { useRef, useState, useCallback } from "react";
import moment from "moment";

// Componentes
import DownloadIcon from "@mui/icons-material/Download";
import { Grid } from "@mui/material";
import MaterialInputBusca from "componentes/inputBusca";
import ColunaBotao from "componentes/tabelaPaginada/colunas/colunaBotao";
import InputData from "componentes/inputTextoData";
import { Loader, SelectArredondado, Botao } from "componentes";
import TabelaPaginada from "componentes/tabelaPaginada";
import Coluna from "componentes/tabelaPaginada/colunas/coluna";
import ResultadoPaginadoDto from "componentes/tabelaPaginada/resultadoPaginadoDto";

// Redux
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";

// DTO
import FiltroDto from "./dto/filtroDto";
import ListagemValidarCotacaoDto from "./dto/listagemValidarCotacaoDto";
import SimulacaoHelper from "./helper";

const Simulacoes = () => {
  const [carregando, setCarregando] = useState(false);
  const [dataSimulacao, setDataSimulacao] = useState();
  const [cnpj, setCnpj] = useState("");
  const [filtroPersonalizado, setFiltroPersonalizado] = useState({});
  const [textoParaBusca, setTextoParaBusca] = useState("");
  const [campoPesquisa, setCampoPesquisa] = useState("cliente");
  const refTabela = useRef(null);

  const onClickEditar = async (id) => {
    setCarregando(true);
    try {
      await SimulacaoHelper.downloadSimulacao(id);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: error.response.data.mensagem
        })
      );
    } finally {
      setCarregando(false);
    }
  };

  const colunas = [
    new Coluna("nomeCliente", "Nome", false),
    new Coluna("cnpj", "CNPJ", false),
    new Coluna("distribuidora", "Distribuidora", false),
    new Coluna("nomeColaborador", "Officer", false),
    new Coluna("dataSimulacao", "Data Simulação", false),
    new ColunaBotao(
      "download",
      "Download",
      onClickEditar,
      <DownloadIcon />,
      false,
      "90px",
      "90px",
      false,
      false
    )
  ];

  const obterListagem = useCallback(
    async (params) => {
      try {
        const filtro = new FiltroDto(
          params.tamanhoPagina,
          params?.pagina && params.pagina - 1,
          params?.filtroPersonalizado?.nomeCliente &&
            params?.filtroPersonalizado?.nomeCliente,
          params?.filtroPersonalizado?.cnpj &&
            params?.filtroPersonalizado?.cnpj?.replace(/[^0-9]+/g, ""),
          params?.filtroPersonalizado?.dataSimulacao &&
            moment(params.filtroPersonalizado.dataSimulacao).isValid() &&
            moment(params.filtroPersonalizado.dataSimulacao)?.format(
              "YYYY-MM-DD"
            )
        );

        if (!filtro?.nomeCliente) delete filtro.nomeCliente;
        if (!filtro?.cnpj) delete filtro.cnpj;
        if (!filtro?.dataSimulacao) delete filtro.dataSimulacao;

        const resultado = await SimulacaoHelper.listarSimulacoes(filtro);

        if (!resultado.sucesso) {
          store.dispatch(
            alertaExibir({
              tipo: "danger",
              mensagem: resultado.mensagem
            })
          );
          return new ResultadoPaginadoDto([], 1, 0, 0);
        }

        if (resultado?.data?.outputCalculeira?.length === 0)
          return new ResultadoPaginadoDto([], 1, 0, 0);

        if (resultado?.data?.outputCalculeira === "")
          return new ResultadoPaginadoDto([], 1, 0, 0);

        const resLinhas = resultado?.data?.outputCalculeira?.map((res) => {
          const dataSimulacao = moment(res?.dataSimulacao).format(
            "DD/MM/YYYY HH:mm:ss"
          );

          const cnpjFormatado = res?.cnpj.replace(
            /(\d{2})?(\d{3})?(\d{3})?(\d{4})?(\d{2})/,
            "$1.$2.$3/$4-$5"
          );

          return new ListagemValidarCotacaoDto(
            res?.id,
            res?.nomeCliente,
            cnpjFormatado,
            res?.nomeDistribuidora,
            res?.nomeColaborador,
            dataSimulacao,
            true,
            null
          );
        });

        if (!resLinhas) return false;

        return new ResultadoPaginadoDto(
          resLinhas,
          resultado.data.paginaAtual,
          resultado.data.totalItens,
          resultado.data.totalPaginas
        );
      } catch (error) {
        store.dispatch(
          alertaExibir({
            tipo: "danger",
            mensagem: error.response.data.mensagem
          })
        );
      }
    },
    [dataSimulacao]
  );

  const onChangeFiltrosTabela = useCallback(
    async (parametros) => {
      try {
        return obterListagem({
          tamanhoPagina: parametros.totalPagina,
          pagina: parametros.pagina,
          filtroPersonalizado: parametros?.filtroPersonalizado
        });
      } catch (error) {
        return null;
      }
    },
    [dataSimulacao, cnpj]
  );

  const onPesquisar = () => {
    setFiltroPersonalizado({
      ...(textoParaBusca && { nomeCliente: textoParaBusca }),
      ...(dataSimulacao && { dataSimulacao }),
      ...(cnpj && { cnpj })
    });
  };

  return (
    <Loader loading={carregando}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item md={4} sm={4} xs={12}>
              <SelectArredondado
                select
                id="CampoPesquisa"
                name="CampoPesquisa"
                label="Filtrar por campo"
                placeholder="Filtrar por campo"
                valueKey="valor"
                valueName="nome"
                dataSource={[
                  { nome: "Nome do Cliente", valor: "cliente" },
                  { nome: "CNPJ", valor: "cnpj" },
                  { nome: "Data Inicial", valor: "data" }
                ]}
                value={campoPesquisa}
                onChange={(e) => {
                  setCnpj("");
                  setDataSimulacao();
                  setTextoParaBusca("");
                  setCampoPesquisa(e.target.value);
                  setFiltroPersonalizado({});
                }}
                permiteValorBranco
                styleForm={false}
              />
            </Grid>
            {campoPesquisa === "cliente" && (
              <Grid item lg={4} sm={5} xs={12}>
                <MaterialInputBusca
                  type="text"
                  id="textoBusca"
                  name="textoBusca"
                  label="Nome"
                  renderIconShowHide
                  searchAdornment
                  permiteValorBranco
                  defaultValue={textoParaBusca ?? ""}
                  onInput={(valor) => {
                    setTextoParaBusca(valor?.target?.value);
                  }}
                />
              </Grid>
            )}
            {campoPesquisa === "cnpj" && (
              <Grid item lg={4} sm={5} xs={12}>
                <MaterialInputBusca
                  type="text"
                  id="cnpj"
                  name="cnpj"
                  label="CNPJ"
                  renderIconShowHide
                  searchAdornment
                  permiteValorBranco
                  defaultValue={cnpj ?? ""}
                  onInput={(valor) => {
                    setCnpj(valor?.target?.value);
                  }}
                />
              </Grid>
            )}
            {campoPesquisa === "data" && (
              <Grid item lg={4} sm={5} xs={12}>
                <InputData
                  type="text"
                  id="DataSimulacao"
                  name="DataSimulacao"
                  label="Data Inicial"
                  renderIconShowHide={false}
                  customValue={dataSimulacao}
                  onChange={(data) => {
                    setDataSimulacao(data);
                  }}
                  maxDate={new Date()}
                />
              </Grid>
            )}
            <Grid item md={3} sm={3} xs={12}>
              <Botao onClick={() => onPesquisar()} label="Pesquisar" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} mt={4}>
          <TabelaPaginada
            ref={refTabela}
            onChangeFiltrosTabela={onChangeFiltrosTabela}
            colunas={colunas}
            filtroPersonalizado={filtroPersonalizado}
            paginaAtual={1}
            paginationPerPage={20}
            noHeader
          />
        </Grid>
      </Grid>
    </Loader>
  );
};

export default Simulacoes;
