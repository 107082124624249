/* eslint-disable react/prop-types */
import React, { useEffect, useLayoutEffect, useState } from "react";

// Componentes
import { Box, Grid } from "@mui/material";
import CardDownload from "componentes/cardDownload";
import ModalContrato from "componentes/modalContrato";
import ModalInformacao from "componentes/modalVerificacao";

// import { BlobProvider } from "@react-pdf/renderer";
import FileSaver from "file-saver";

import { Clicksign } from "bibliotecas/embedded";
import { Loader } from "componentes";
import { ProtocolosEtapas, tipoEnvioContrato } from "global/constantes";
import { store } from "global/redux";
import { SetModoEnvioAssinatura } from "global/redux/modulos/workflow/actions";
import { PDFDocument } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import getDomain from "global/getDomain";
import { formataPercentual, numeroPorExtenso } from "servicos/utils";
import AssinarTermoHelper from "./helper";
import enumeradorPlataforma from "../../Enumeradores/enumeradorPlataforma";
// import TermoProcuracao from "../../../../Relatorios/OnBoarding/TermoProcuracao";
import OnBoardingHelper from "../../helper";
import { DocumentoClicksign } from "./style";
import EsqueletoEtapa from "../../Componentes/EsqueletoEtapa";
import IconAssinatura from "../../../../../assets/icones/workflow/assinatura.svg";

const AssinarTermo = ({
  prazo,
  responsavel,
  onProximaEtapa,
  labelProximo,
  protocoloDemanda,
  onVoltarEtapa,
  somenteLeitura
}) => {
  const [modalEnviar, setModalEnviar] = useState(false);
  // const [pdfAdesao, setPdfTermoAdesao] = useState();
  // const [pdfProcuracao, setPdfTermoProcuracao] = useState();
  const [plataforma, setPlataforma] = useState();
  const [exibirModalAssinar, setExibirModalAssinar] = useState(false);
  const [carregandoDocumento, setCarregandoDocumento] = useState(false);
  const [requestSignatureKeyList, setRequestSignatureKeyList] = useState([]);
  const [documentosCarregados, setDocumentosCarregados] = useState([]);
  const [loadingGeral, setLoadingGeral] = useState(false);
  const [carregandoTermoAdesao, setCarregandoTermoAdesao] = useState(false);
  const [carregandoTermoProcuracao, setCarregandoTermoProcuracao] = useState(
    false
  );

  const onClickProximo = () => {
    if (somenteLeitura) {
      onProximaEtapa(ProtocolosEtapas.CreditoRejeitadoManual);
      return;
    }

    setModalEnviar(true);
  };

  const onClickVoltar = () => {
    onVoltarEtapa(ProtocolosEtapas.IndicarRepresentanteLegal);
  };

  const onChangePlataforma = (event) => {
    setPlataforma(event?.target?.value);
  };
  const modeloDoc = `../../../../../../themes/${getDomain()}/assets/contrato_workflow.png`;

  const definirConfiguracoesFormulario = (form) => {
    form.getFields().forEach((field) => {
      const nameField = field.getName();
      if (!nameField.includes("Text")) {
        const textField = form.getTextField(nameField);
        textField.setMaxLength(undefined);
      }
    });
  };

  const obterFonts = async (pdf) => {
    pdf.registerFontkit(fontkit);

    const urlPlau = "themes/default/assets/fonts/plau.otf";
    const plauFontBytes = await fetch(urlPlau).then((res) => res.arrayBuffer());
    const plauFont = await pdf.embedFont(plauFontBytes);

    const urlPlauBold = "themes/default/assets/fonts/plau_bold.otf";
    const plauBoldFontBytes = await fetch(urlPlauBold).then((res) =>
      res.arrayBuffer()
    );
    const plauBoldFont = await pdf.embedFont(plauBoldFontBytes);

    const urlCenturyGothic = "themes/default/assets/fonts/centuryGothic.ttf";
    const centuryGothicFontBytes = await fetch(urlCenturyGothic).then((res) =>
      res.arrayBuffer()
    );
    const centuryGothicFont = await pdf.embedFont(centuryGothicFontBytes);

    const urlCenturyGothicBold =
      "themes/default/assets/fonts/centuryGothic_bold.ttf";
    const centuryGothicBoldFontBytes = await fetch(
      urlCenturyGothicBold
    ).then((res) => res.arrayBuffer());
    const centuryGothicBoldFont = await pdf.embedFont(
      centuryGothicBoldFontBytes
    );

    const font = getDomain() !== "luz" ? centuryGothicFont : plauFont;
    const fontBold =
      getDomain() !== "luz" ? centuryGothicBoldFont : plauBoldFont;

    return { font, fontBold };
  };

  const obterRepresentantes = (representantes, index) => {
    return representantes[index]
      ? [
          `Nome: ${representantes[index].nomeCompleto}`,
          `Email: ${representantes[index].email}`,
          `Rg: ${representantes[index].rg}`,
          `CPF: ${representantes[index].cpf}`,
          `Telefone: ${representantes[index].telefone}`
        ].join("\n")
      : "";
  };

  const gerarPdfTermoAdesao = async (dados) => {
    const formBytes = await fetch(
      `themes/${getDomain()}/assets/workflow/termoAdesao.pdf`
    ).then((res) => res.arrayBuffer());

    const pdfPages = await PDFDocument.load(formBytes);

    const formPages = pdfPages.getForm();

    definirConfiguracoesFormulario(formPages);

    const fonts = await obterFonts(pdfPages);
    const { font, fontBold } = fonts;

    const fieldNumeroContrato = formPages.getTextField("numeroContrato");
    const fieldNomeUnidade = formPages.getTextField("nomeUnidade");
    const fieldCnpjUnidade = formPages.getTextField("cnpjUnidade");
    const fieldEnderecoUnidade = formPages.getTextField("enderecoUnidade");
    const fieldRepresentante01 = formPages.getTextField("representante01");
    const fieldRepresentante02 = formPages.getTextField("representante02");
    const fieldUnidadeCnpj = formPages.getTextField("unidadeCnpj");
    const fieldDistribuidora = formPages.getTextField("distribuidora");
    const fieldDemandaContratada = formPages.getTextField("demandaContratada");
    const fieldConsumoOuValorMedioMensal = formPages.getTextField(
      "consumoOuValorMedioMensal"
    );
    const fieldPeriodo = formPages.getTextField("periodo");
    const fieldPlano = formPages.getTextField("plano");
    const fieldDesconto = formPages.getTextField("desconto");
    const fieldNumeroContrato02 = formPages.getTextField("numeroContrato02");
    const fieldDataRodape = formPages.getTextField("dataRodape");
    const fieldPisCofins = formPages.getTextField("pisCofins");

    const fieldIcms = formPages.getTextField("icms");
    const fieldNumeroContrato03 = formPages.getTextField("numeroContrato03");
    const fieldDataRodape02 = formPages.getTextField("dataRodape02");
    const fieldDataAssinatura = formPages.getTextField("dataAssinatura");
    const fieldRepresentanteAssinatura01 = formPages.getTextField(
      "representanteAssinatura01"
    );
    const fieldRepresentanteAssinatura02 = formPages.getTextField(
      "representanteAssinatura02"
    );
    const fieldNumeroContrato04 = formPages.getTextField("numeroContrato04");
    const fieldDataRodape03 = formPages.getTextField("dataRodape03");

    fieldNumeroContrato.setText(dados?.numeroContrato);
    fieldNumeroContrato.updateAppearances(font);

    fieldNumeroContrato02.setText(`${dados?.numeroContrato}, celebrado`);
    fieldNumeroContrato02.updateAppearances(font);

    fieldNumeroContrato03.setText(`${dados?.numeroContrato}, celebrado`);
    fieldNumeroContrato03.updateAppearances(font);

    fieldNumeroContrato04.setText(`${dados?.numeroContrato}, celebrado`);
    fieldNumeroContrato04.updateAppearances(font);

    fieldNomeUnidade.setText(dados?.nomeUnidadeConsumidora);
    fieldNomeUnidade.updateAppearances(fontBold);

    fieldCnpjUnidade.setText(dados?.cnpjUnidadeConsumidora);
    fieldCnpjUnidade.updateAppearances(font);

    fieldEnderecoUnidade.setText(dados?.enderecoUnidadeConsumidora);
    fieldEnderecoUnidade.updateAppearances(font);

    fieldRepresentante01.setText(
      obterRepresentantes(dados?.listaRepresentante, 0)
    );
    fieldRepresentante01.updateAppearances(font);

    fieldRepresentante02.setText(
      obterRepresentantes(dados?.listaRepresentante, 1)
    );
    fieldRepresentante02.updateAppearances(font);

    fieldRepresentanteAssinatura01.setText(
      obterRepresentantes(dados?.listaRepresentante, 0)
    );
    fieldRepresentanteAssinatura01.updateAppearances(font);

    fieldRepresentanteAssinatura02.setText(
      obterRepresentantes(dados?.listaRepresentante, 1)
    );
    fieldRepresentanteAssinatura02.updateAppearances(font);

    fieldUnidadeCnpj.setText(
      `${dados?.nomeUnidadeConsumidora} - ${dados?.cnpjUnidadeConsumidora}`
    );
    fieldUnidadeCnpj.updateAppearances(font);

    fieldDistribuidora.setText(dados?.nomeDistribuidora);
    fieldDistribuidora.updateAppearances(font);

    fieldDemandaContratada.setText(dados?.demandaContratada);
    fieldDemandaContratada.updateAppearances(font);

    fieldConsumoOuValorMedioMensal.setText(dados?.consumoOuValorMedioMensal);
    fieldConsumoOuValorMedioMensal.updateAppearances(font);

    fieldPeriodo.setText(
      `${dados?.periodoFornecimento} (${numeroPorExtenso(
        dados?.periodoFornecimento
      )} anos)`
    );
    fieldPeriodo.updateAppearances(font);

    fieldPlano.setText(`Desconto garantido ${dados?.periodoFornecimento} anos`);
    fieldPlano.updateAppearances(font);

    fieldDesconto.setText(`${formataPercentual(dados?.preco)}`);
    fieldDesconto.updateAppearances(font);

    fieldDataRodape.setText(`${dados?.dataBoletaPorExtenso}`);
    fieldDataRodape.updateAppearances(font);

    fieldDataRodape02.setText(`${dados?.dataBoletaPorExtenso}`);
    fieldDataRodape02.updateAppearances(font);

    fieldDataRodape03.setText(`${dados?.dataBoletaPorExtenso}`);
    fieldDataRodape03.updateAppearances(font);

    fieldDataAssinatura.setText(`${dados?.dataBoletaPorExtenso}`);
    fieldDataAssinatura.updateAppearances(font);

    fieldPisCofins.setText(`${formataPercentual(dados?.pisCofins)}`);
    fieldPisCofins.updateAppearances(font);

    // fieldPisCofinsExtenso.setText(`(${numeroPercentualPorExtenso(dados?.pisCofins)})`)
    // fieldPisCofinsExtenso.updateAppearances(font)

    fieldIcms.setText(`${formataPercentual(dados?.icms)}`);
    fieldIcms.updateAppearances(font);

    const pdfPrimeiroBytes = await pdfPages.save();

    return pdfPrimeiroBytes;
  };

  const avancarEtapa = async () => {
    setModalEnviar(false);
    setLoadingGeral(true);

    const result = await AssinarTermoHelper.criarDocumento(
      plataforma,
      protocoloDemanda
    );

    if (!result.sucesso) {
      AssinarTermoHelper.exibirErro(result.mensagem);
      setLoadingGeral(false);
      return;
    }

    if (String(plataforma) !== String(tipoEnvioContrato.AssinarPlataforma)) {
      onProximaEtapa(ProtocolosEtapas.AguardandoAssinaturaContrato);
      setLoadingGeral(false);
      store.dispatch(SetModoEnvioAssinatura(plataforma));
      return;
    }

    setDocumentosCarregados(result.data.chaveAssinaturas);
    setLoadingGeral(false);
    setExibirModalAssinar(true);
  };

  const mergePdfs = async (pdfsToMerge) => {
    const mergedPdf = await PDFDocument.create();

    // eslint-disable-next-line no-restricted-syntax
    for (const pdfCopyDoc of pdfsToMerge) {
      // eslint-disable-next-line no-await-in-loop
      const pdfDoc = await PDFDocument.load(pdfCopyDoc);

      // eslint-disable-next-line no-await-in-loop
      const copiedPages = await mergedPdf.copyPages(
        pdfDoc,
        pdfDoc.getPageIndices()
      );

      copiedPages.forEach((page) => {
        mergedPdf.addPage(page);
      });
    }

    const pdfBytes = await mergedPdf.save();

    const blob = new Blob([pdfBytes], { type: "application/pdf" });

    FileSaver.saveAs(blob, "Termo de Adesao");
  };

  // eslint-disable-next-line no-unused-vars
  const onClickGerarTermoAdesaoPDF = async () => {
    setCarregandoTermoAdesao(true);
    // setPdfTermoProcuracao();
    // setPdfTermoAdesao();

    const {
      sucesso,
      mensagem,
      data
    } = await OnBoardingHelper.ObterDadosParaTermoAdesao(protocoloDemanda);

    if (!sucesso) {
      OnBoardingHelper.exibirErro(mensagem);
      setCarregandoTermoAdesao(false);
      return;
    }

    setCarregandoTermoAdesao(false);
    const pdfs = [];

    const pdfTermoADesao = await gerarPdfTermoAdesao(data);
    pdfs.push(pdfTermoADesao);

    mergePdfs(pdfs);
    // setPdfTermoAdesao(<TermoAdesaoPdf {...data} />);
    // eslint-disable-next-line no-useless-return
    return;
  };

  // eslint-disable-next-line no-unused-vars
  const onClickGerarTermoProcuracaoPDF = async () => {
    setCarregandoTermoProcuracao(true);
    // setPdfTermoAdesao();
    // setPdfTermoProcuracao();

    const {
      sucesso,
      mensagem
    } = await OnBoardingHelper.ObterDadosParaTermoAdesao(protocoloDemanda);

    if (!sucesso) {
      setCarregandoTermoProcuracao(false);
      OnBoardingHelper.exibirErro(mensagem);
      return;
    }

    // const pdfs = [];

    // const pdfTermoProcuracao = await gerarPdfTermoProcuracao(data);
    // pdfs.push(pdfTermoProcuracao);

    // mergePdfs(pdfs);

    setCarregandoTermoProcuracao(false);
    // eslint-disable-next-line react/jsx-props-no-spreading
    // setPdfTermoProcuracao(<TermoProcuracao {...data} />);
    // eslint-disable-next-line no-useless-return
    return;
  };

  // const baixarPdfAdesao = ({ blob, loading, error }) => {
  //   if (loading || error || !blob) return <></>;
  //   FileSaver.saveAs(blob, "Termo de Adesão");
  //   setPdfTermoAdesao(null);
  //   return <></>;
  // };

  // const baixarPdfProcuracao = ({ blob, loading, error }) => {
  //   if (loading || error || !blob) return <></>;
  //   FileSaver.saveAs(blob, "Termo de Procuração");
  //   setPdfTermoProcuracao(null);
  //   return <></>;
  // };

  useEffect(() => {
    setRequestSignatureKeyList(documentosCarregados);
  }, [documentosCarregados]);

  let widget;

  // Widget
  useLayoutEffect(() => {
    if (exibirModalAssinar && requestSignatureKeyList?.length) {
      setCarregandoDocumento(true);

      if (widget) {
        widget.unmount();
      } else {
        widget = window.document.getElementById("refDocumento");
        if (widget) widget.innerHTML = "";
      }

      widget = new Clicksign(requestSignatureKeyList[0]);

      widget.endpoint = "https://sandbox.clicksign.com";
      widget.origin = "http://delta.localhost:3000/";
      widget.on("loaded", function () {
        setCarregandoDocumento(false);
      });

      widget.on("signed", function () {
        const documentosRestantes = [...requestSignatureKeyList];
        documentosRestantes.splice(0, 1);

        if (documentosRestantes?.length) {
          setRequestSignatureKeyList(documentosRestantes);
          setExibirModalAssinar(true);
        } else onProximaEtapa();
      });

      setTimeout(() => {
        widget.mount("refDocumento");
      }, 1000);
    }
  }, [exibirModalAssinar, requestSignatureKeyList]);

  // const modeloDoc = `themes/${getDomain()}/assets/contrato_workflow.png`

  // eslint-disable-next-line no-unused-vars
  const gerarPdfTermoProcuracao = async (dados) => {
    const formBytes = await fetch(
      `themes/${getDomain()}/assets/workflow/procuracao.pdf`
    ).then((res) => res.arrayBuffer());

    const pdfPages = await PDFDocument.load(formBytes);

    const formPages = pdfPages.getForm();

    definirConfiguracoesFormulario(formPages);

    const fonts = await obterFonts(pdfPages);
    const { font, fontBold } = fonts;

    const fieldNumeroContrato = formPages.getTextField("numeroContrato");
    const fieldDadosUnidade = formPages.getTextField("dadosUnidade");
    const fieldPrimeiro = formPages.getTextField("primeiro");
    const fieldSegundo = formPages.getTextField("segundo");
    const fieldDataAssinatura = formPages.getTextField("dataAssinatura");
    const fieldNomeUnidade = formPages.getTextField("nomeUnidade");
    const fieldRepresentanteAssinatura01 = formPages.getTextField(
      "representante01"
    );
    const fieldRepresentanteAssinatura02 = formPages.getTextField(
      "representante02"
    );
    const fieldNumeroContratoRodape = formPages.getTextField(
      "numeroContratoRodape"
    );
    const fieldDataRodape = formPages.getTextField("dataRodape");

    fieldNumeroContrato.setText(dados?.numeroContrato);
    fieldNumeroContrato.updateAppearances(font);

    fieldNumeroContratoRodape.setText(dados?.numeroContrato);
    fieldNumeroContratoRodape.updateAppearances(font);

    fieldDadosUnidade.setText(`${dados?.nomeUnidadeConsumidora}, pessoa jurídica de direito privado, com sede na ${dados.enderecoUnidadeConsumidora}, inscrita no CNPJ/MF sob o nº ${dados.cnpjUnidadeConsumidora}, 
    neste ato representada por seus representantes legais na forma prevista em seus atos constitutivos, doravante designada “Outorgante”, nomeia e constitui, em caráter irrevogável, como sua bastante procuradora:`);
    fieldDadosUnidade.updateAppearances(font);

    fieldPrimeiro.setText(
      `(i) 	representá-la junto a ${dados?.nomeDistribuidora}, inscrita no CNPJ nº ${dados?.cnpjDistribuidora}, para a finalidade de solicitar informações e documentos em seu nome;`
    );
    fieldPrimeiro.updateAppearances(font);

    fieldSegundo.setText(
      "(ii) 	Praticar qualquer ato ou firmar qualquer documento que venha a ser necessário, obrigatório, útil ou conveniente para o fiel e pleno cumprimento do presente mandato."
    );
    fieldSegundo.updateAppearances(font);

    fieldNomeUnidade.setText(dados?.nomeUnidadeConsumidora);
    fieldNomeUnidade.updateAppearances(fontBold);

    fieldRepresentanteAssinatura01.setText(
      obterRepresentantes(dados?.listaRepresentante, 0)
    );
    fieldRepresentanteAssinatura01.updateAppearances(font);

    fieldRepresentanteAssinatura02.setText(
      obterRepresentantes(dados?.listaRepresentante, 1)
    );
    fieldRepresentanteAssinatura02.updateAppearances(font);

    fieldDataRodape.setText(`${dados?.dataBoletaPorExtenso}`);
    fieldDataRodape.updateAppearances(font);

    fieldDataAssinatura.setText(`${dados?.dataBoletaPorExtenso}`);
    fieldDataAssinatura.updateAppearances(font);

    const pdfPrimeiroBytes = await pdfPages.save();

    return pdfPrimeiroBytes;
  };

  return (
    <>
      <Loader loading={loadingGeral}>
        <ModalContrato
          item={modalEnviar}
          onConfirmar={() => avancarEtapa()}
          mensagem={
            <Loader loading={carregandoDocumento}>
              <Box component="img" src={modeloDoc} />
              {/* <ModeloDocumento src={modeloDoc} /> */}
            </Loader>
          }
          dataSourcePlataforma={enumeradorPlataforma}
          valuePlataforma={plataforma}
          onChange={(event) => onChangePlataforma(event)}
          onCancelar={() => {
            setModalEnviar(false);
          }}
        />
        <ModalInformacao
          telaInteira
          exibir={exibirModalAssinar}
          titulo="Assinar documento"
          className="h-100 w-100"
          mensagem={
            <DocumentoClicksign className="h-100 w-100" id="refDocumento" />
          }
          labelBotao="OK"
          onClose={() => {
            setExibirModalAssinar(false);
          }}
          showBotao={false}
        />
        <EsqueletoEtapa
          prazo={prazo}
          responsavel={responsavel}
          titulo="Assinatura de documentos"
          onClickProximo={() => onClickProximo()}
          onClickVoltar={() => onClickVoltar()}
          labelProximo={labelProximo}
        >
          <Grid>
            <Grid>
              <p>
                Clique nas caixas abaixo para realizar o download das minutas.
              </p>
              <p>
                Clique em “Assinar” para enviar os documentos aos Representantes
                cadastrados.
              </p>
            </Grid>
            <Grid xs={12}>
              {/* {pdfAdesao && (
                <BlobProvider document={pdfAdesao}>
                  {baixarPdfAdesao}
                </BlobProvider>
              )} */}
              <Loader loading={carregandoTermoAdesao}>
                <CardDownload
                  text="Contrato de energia"
                  icon={IconAssinatura}
                  onClickDownload={() => {}}
                />
              </Loader>
            </Grid>
            <Grid xs={12}>
              {/* {pdfProcuracao && (
                <BlobProvider document={pdfProcuracao}>
                  {baixarPdfProcuracao}
                </BlobProvider>
              )} */}
              <Loader loading={carregandoTermoProcuracao}>
                <CardDownload
                  text="Procuração"
                  icon={IconAssinatura}
                  onClickDownload={() => {}}
                />
              </Loader>
            </Grid>
          </Grid>
        </EsqueletoEtapa>
      </Loader>
    </>
  );
};

export default AssinarTermo;
