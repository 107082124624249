import { store } from "../../../global/redux";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";
import RetornoEndpointDto from "../../../global/dto/retornoEndpointDto";
import {
  obterDocumentosClientePorClienteId,
  downloadDocumentoCliente,
  obterUrlDocumentoCliente
} from "../../../servicos/clienteDocumento";

export default class HelperDocumentos {
  static async obterDocumentosClientePorClienteId(clienteId) {
    try {
      const resultado = await obterDocumentosClientePorClienteId(clienteId);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Arquivo não encontrados!");

      return RetornoEndpointDto.Sucesso(
        "Arquivo obtido com sucesso",
        resultado?.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Ocorreu um erro ao obter arquivo");
    }
  }

  static async downloadDocumentoCliente(id, nomeArquivo) {
    try {
      const resultado = await downloadDocumentoCliente(id, nomeArquivo);

      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Arquivo não encontrados!");

      return RetornoEndpointDto.Sucesso(
        "arquivo obtido com sucesso",
        resultado?.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Ocorreu um erro ao obter arquivo");
    }
  }

  static async obterUrlDocumentoCliente(id, nomeArquivo) {
    try {
      const resultado = await obterUrlDocumentoCliente(id, nomeArquivo);

      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Arquivo não encontrados!");

      return RetornoEndpointDto.Sucesso(
        "arquivo obtido com sucesso",
        resultado?.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Ocorreu um erro ao obter arquivo");
    }
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }

  static exibirSucesso(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem
      })
    );
  }
}
