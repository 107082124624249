import React, { useState } from "react";
import { useHistory } from "react-router-dom";

// Componentes
import { Card, Grid } from "@mui/material";
import Botao from "componentes/botao";
import { Base } from "componentes/cores";
import Loader from "componentes/loader";

// Styles

// Ícones
import CropFreeOutlinedIcon from "@mui/icons-material/CropFreeOutlined";
import FlashOffOutlinedIcon from "@mui/icons-material/FlashOffOutlined";
import WbIncandescentOutlinedIcon from "@mui/icons-material/WbIncandescentOutlined";

// Redux
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import {
  mostrarHistorico,
  salvarDadosEnergia,
  salvarHistorico
} from "global/redux/modulos/demo/actions";

// DTO
import { RotasDTO } from "global/rotas/rotasUrlDto";
import {
  BotaoFoto,
  Conteudo,
  ExemploCaptura,
  Instrucao,
  LabelBotaoFoto,
  Subtitulo,
  Texto,
  useStyles
} from "./style";

// Serviços
import { obterHistoricoFatura } from "../../../../servicos/demoServico";

const CapturaFatura = () => {
  const classes = useStyles();
  const history = useHistory();

  const [carregando, setCarregando] = useState(false);

  const onClickCancelar = (event) => {
    event.preventDefault();
    history.goBack();
  };

  const onChangeBotaoFoto = async (event) => {
    try {
      setCarregando(true);
      if (event?.target?.files) {
        const historico = await obterHistoricoFatura(event?.target?.files);

        if (historico) {
          store.dispatch(
            salvarDadosEnergia({
              cnpj: "27.537.389/0001-65",
              nomeFantasia: "CDM MISS SILVIA MORIZONO",
              distribuidoraId: 1068,
              valorMedio: "63.136,48",
              demandaContratadaKW: "1100 kW"
            })
          );
          store.dispatch(mostrarHistorico(true));
          store.dispatch(salvarHistorico(historico));
          setTimeout(() => {
            setCarregando(false);
            history.push(`${RotasDTO.Demo}/cotacao/inicial`);
          }, 2000);
        }
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Não foi possível processar a imagem!"
        })
      );
      setCarregando(false);
    }
  };

  return (
    <Loader loading={carregando}>
      <form className="needs-validation">
        <Card className={classes.container}>
          <Grid
            container
            spacing={3}
            pr={6}
            justifyContent="space-around"
            className={classes.container}
          >
            <Grid item xs={12} className="font-weight-bold">
              Foto fatura de energia
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            pr={6}
            className={classes.container}
            style={{ paddingTop: 0, paddingBottom: 40 }}
          >
            <Grid item>
              Para tirar uma foto de qualidade siga as dicas abaixo, as imagens
              precisam estar nítidas, com os dados legíveis.
            </Grid>
          </Grid>
          <Grid container spacing={3} pr={6} className={classes.container}>
            <Grid item className="py-0 pr-0">
              <Grid item lg={12} style={{ marginBottom: 30 }}>
                <Instrucao>
                  <Conteudo>
                    <WbIncandescentOutlinedIcon className={classes.iconBox} />
                    <Subtitulo>Iluminação do ambiente</Subtitulo>
                    <Texto>
                      Posicione o documento sobre uma mesa em um ambiente
                      iluminado.
                    </Texto>
                  </Conteudo>
                </Instrucao>
              </Grid>
              <Grid item lg={12} style={{ marginBottom: 30 }}>
                <Instrucao>
                  <Conteudo>
                    <CropFreeOutlinedIcon className={classes.iconBox} />
                    <Subtitulo>Enquadramento do documento</Subtitulo>
                    <Texto>Enquadre o documento, alinhando as bordas.</Texto>
                  </Conteudo>
                </Instrucao>
              </Grid>
              <Grid item lg={12}>
                <Instrucao>
                  <Conteudo>
                    <FlashOffOutlinedIcon className={classes.iconBox} />
                    <Subtitulo>Não utilizar o flash</Subtitulo>
                    <Texto>
                      Desligue o flash para evitar destaques de luz e sombras.
                    </Texto>
                  </Conteudo>
                </Instrucao>
              </Grid>
            </Grid>
            <Grid item className="py-0">
              <ExemploCaptura />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            pr={6}
            className={classes.containerAvancar}
          >
            <Grid item lg={2} md={2} sm={12} className="pr-0">
              <Botao
                type="submit"
                label="Cancelar"
                bgColor={Base.White}
                className={classes.buttonCancelar}
                onClick={onClickCancelar}
              />
            </Grid>
            <Grid item lg={2} md={3} sm={12}>
              <LabelBotaoFoto
                className={`btn btn-lg rounded-pill w-100 position-relative d-flex justify-content-center align-items-center ${classes.button}`}
              >
                Tirar foto
                <BotaoFoto
                  type="file"
                  accept="image/*"
                  height={1080}
                  width={1920}
                  capture
                  onChange={onChangeBotaoFoto}
                />
              </LabelBotaoFoto>
            </Grid>
          </Grid>
        </Card>
      </form>
    </Loader>
  );
};

export default CapturaFatura;
