import React from "react";
import InputTexto from "./inputTexto";

export default class colunaCampoTexto3Anos {
  constructor(
    field,
    headerName,
    sortable = false,
    onChangeSelecionarDesconto3Anos,
    maxWidth = null,
    minWidth = null,
    adicionalDesconto3Anos = null,
    idCampoDesconto3Anos = null,
    selecionadoId = null,
    enabled = false
  ) {
    this.selector = field;
    this.name = headerName;
    this.sortable = sortable;
    this.maxWidth = maxWidth;
    this.minWidth = minWidth;
    this.adicionalDesconto3Anos = adicionalDesconto3Anos;
    this.idCampoDesconto3Anos = idCampoDesconto3Anos;
    this.selecionadoId = selecionadoId;
    this.enabled = enabled;
    this.cell = (params) => {
      return (
        <InputTexto
          type="text"
          id={params.id}
          name='adicionalDesconto3Anos'
          permiteValorBranco
          onBlur={(event) => onChangeSelecionarDesconto3Anos(event.target.value, params)}
          defaultValue={
            params.id === idCampoDesconto3Anos
              ? adicionalDesconto3Anos
              : params?.adicionalDesconto3Anos
          }
          renderIconShowHide={false}
          disabled={!(params.id === selecionadoId && enabled)}
        />
      );
    };
  }
}
