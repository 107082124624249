import React, { useState, useCallback, useEffect } from 'react';
import moment from 'moment';
import { Grid, Card } from '@mui/material';
import { useStyles, Tabela } from './style';
import InputDataBranco from 'componentes/inputTextoDataBranco';
import { obterValoresBaseRateioProinfa } from '../../../../../servicos/premissasBrasil';
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import CloseIcon from "@mui/icons-material/Close";
import RotasDTO  from "../../../../../rotas/rotasUrlDto";  
import { useHistory } from "react-router-dom";

const BaseRateioProinfaVisualizar = () => {
  const classes = useStyles();
  const history = useHistory();

  const onFechar = (id) => {
     history.push(`${RotasDTO.ProjecaoInsumoListar}`);
   }
  const [anoBase, setAnoBase] = useState(null);
  const onChangeAnoBase = (data) => setAnoBase(data);

  const [valores, setValores] = useState([]);

  const obterValores = useCallback(async (ano) => {
    try {
      const response = await obterValoresBaseRateioProinfa(moment(ano).format("YYYY"));
      if (response.status === 200 && response.data) {
        setValores(response?.data);
      } else {
        setValores([]);
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "Não há dados para impressão com os parâmetros informados."
          })
        );
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  }, []);

  useEffect(() => { if (anoBase) obterValores(anoBase) }, [anoBase, obterValores]);

  return <>
   <Grid container className={classes.containerTitulo}>
        <Grid item xs={11} className={classes.titulo}>
          Planilha de Base de Rateio PROINFA
        </Grid>
        <Grid item xs={1} >             
          <CloseIcon onClick={onFechar} className={classes.icon}/>
        </Grid>   
      </Grid>
    <Grid container spacing={4} className={classes.container} justifyContent="flex-end" alignItems="center">
      <Grid item sm={2} className={classes.customSelect}>
        <InputDataBranco
          type="text"
          id="anoBase"
          name="anoBase"
          customValue={anoBase}
          onChange={(data) => onChangeAnoBase(data)}
          maxDate={moment("2090-12-31").toDate()}
          format="yyyy"
          views={["year"]}
          renderIconShowHide={false}
        />
      </Grid>
    </Grid>
    <Grid container p={2} spacing={4} className={classes.container}>
      <Grid item sm={12}>
        <Card className={classes.container}>
          <Grid container p={2} spacing={4} className={classes.container}>
            {valores.length > 0 ?
              <Grid item sm={12}>
                <div className={classes.customPosition}>
                  <Tabela>
                    <Tabela.Thead>
                      <Tabela.Tr>
                        <Tabela.Th colSpan="5">BASE DE RATEIO PROINFA</Tabela.Th>
                      </Tabela.Tr>
                      <Tabela.Tr>
                        <Tabela.Th>DISTRIBUIDORA</Tabela.Th>
                        <Tabela.Th>QUOTA REAIS</Tabela.Th>
                        <Tabela.Th>QUOTA MWH</Tabela.Th>
                        <Tabela.Th>PERCENTUAL REAIS</Tabela.Th>
                        <Tabela.Th>PERCENTUAL MHW</Tabela.Th>
                      </Tabela.Tr>
                    </Tabela.Thead>
                    <Tabela.Tbody>
                      {valores?.map((item) => (
                        item.BaseRateio.map((base) => (
                          <Tabela.Tr>
                            <Tabela.Td>{base.Distribuidora}</Tabela.Td>
                            <Tabela.Td>{base.QuotaReais.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Tabela.Td>
                            <Tabela.Td>{base.QuotaMwh.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Tabela.Td>
                            <Tabela.Td>{(base.PercentualReais * 100).toLocaleString("pt-BR", { minimumFractionDigits: 4, maximumFractionDigits: 4 })}</Tabela.Td>
                            <Tabela.Td>{(base.PercentualMwh * 100).toLocaleString("pt-BR", { minimumFractionDigits: 4, maximumFractionDigits: 4 })}</Tabela.Td>
                          </Tabela.Tr>
                        ))
                      ))}
                    </Tabela.Tbody>
                  </Tabela>
                </div>
              </Grid>
              : ""}
          </Grid>
        </Card>
      </Grid>
    </Grid>
  </>;
}

export default BaseRateioProinfaVisualizar;
