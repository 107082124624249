import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import shortid from 'shortid';
import { Grid } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import { useStyles } from './style';
import { Base } from "componentes/cores";
import Loader from 'componentes/loader';
import ModalConfirmacao from 'componentes/modalConfirmacao';
import MaterialInputTexto from 'componentes/inputTexto/materialInput';
import BotaoFiltroOrdenacao from 'componentes/botaoFiltroOrdenar';
import BotaoCadastro from 'componentes/botaoCadastro';
import ItemListaExpansivel from 'componentes/itemListaExpansivel';
import { obterCenarios, salvarCenario } from '../../../servicos/cenariosSeriesHistoricas';
import { store } from 'global/redux';
import { alertaExibir } from 'global/redux/modulos/alertas/actions';
import RotasDTO from '../../../rotas/rotasUrlDto';

const Cenarios = () => {
  const classes = useStyles();
  const history = useHistory();

  const { register, control } = useForm({
    reValidateMode: 'onSubmit'
  });

  const textoBusca = useWatch({
    control,
    name: 'textoBusca',
    defaultValue: ''
  });


  const [carregandoCenario, setCarregandoCenario] = useState(false);
  const [cenarios, setCenarios] = useState([]);

  const handleCenarios = async () => {
    try {
      setCarregandoCenario(true);
      const lista = await obterCenarios();
      if (lista?.status === 200 && lista?.data) {
        setCenarios(lista?.data);
        setCarregandoCenario(false);
      } else {
        setCenarios([]);
      }
      setCarregandoCenario(false);
    } catch (error) {
      console.info(error);
    }
  }

  const onClickNovoCenario = () => {
    history.push(`${RotasDTO.Cenarios}${RotasDTO.CenariosCadastro}`);
  }

  const onClickEditar = (id) => {
    history.push(`${RotasDTO.Cenarios}${RotasDTO.CenariosCadastro}/${id}`);
  }

  const [modalExclusao, setModalExclusao] = useState();

  const onClickExcluir = (item) => {
    setModalExclusao(item);
  };

  const buscarCenarios = useCallback(async () => {
    handleCenarios();
  }, []);

  useEffect(() => {
    if (!cenarios?.length) handleCenarios();
  }, [cenarios.length, handleCenarios]);

  const deletar = async (item) => {
    try {
      setModalExclusao(false);
      setCarregandoCenario(true);
      const deletou = await salvarCenario(item?.id, {
        id: item?.id,
        nome: item?.nome,
        descricao: item?.descricao,
        ativo: item?.ativo === 0 ? 1 : 0,
        isStandard: item?.isStandard,
      });

      if (deletou) {
        store.dispatch(
          alertaExibir({
            tipo: 'success',
            mensagem:
              'Cenário desativado com sucesso!'
          })
        );
        buscarCenarios();
      }
      setCarregandoCenario(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: 'warning',
          mensagem:
            error?.response?.data?.message ??
            'Erro interno, entre em contato com o suporte!'
        })
      );

      setCarregandoCenario(false)
    }
  }

  const onConfirmarExclusao = (item) => {
    try {
      deletar(item);
    } catch (error) {
      console.info(error);
    }
  }

  return (
    <Loader loading={carregandoCenario} className='w-auto'>
      <ModalConfirmacao
        item={modalExclusao}
        onConfirmar={(item) => onConfirmarExclusao(item)}
        mensagem='Tem certeza que deseja desabilitar esse Cenário?'
        onCancelar={() => setModalExclusao(false)}
      />
      <Grid container spacing={3} alignItems='center'>
        <Grid item xs={9}>
          <MaterialInputTexto
            type='text'
            id='textoBusca'
            name='textoBusca'
            label='Buscar'
            renderIconShowHide
            searchAdornment
            defaultValue={textoBusca ?? ''}
            ref={register}
            className={classes.search}
            permiteValorBranco
          />
        </Grid>
        <Grid item xs={3} className={classes.itemFiltro}>
          <BotaoFiltroOrdenacao
            type='button'
            label='Filtrar / Ordernar'
            color={Base.OffWhite}
            icon={<TuneIcon />}
            className={classes.buttonFiltro}
          />
        </Grid>
        <Grid item xs={12}>
          <BotaoCadastro
            label='Novo Cenário'
            color={Base.RedChestnut}
            onClick={onClickNovoCenario}
          />
        </Grid>

        {cenarios.length > 0 && cenarios.map((cenario) => {
          return (
            <ItemListaExpansivel
              key={shortid.generate()}
              label={cenario?.nome}
              color={Base.RedChestnut}
              edicao={!!cenario?.id}
              exclusao={!!cenario.id}
              onClickEditar={() => onClickEditar(cenario?.id)}
              onClickExcluir={() => onClickExcluir(cenario ?? false)}
              ativo={Boolean(cenario?.ativo)}
            >
              <div>
                <strong>Descrição:</strong>
                {cenario.descricao}
              </div>
            </ItemListaExpansivel>
          )
        })
        }
      </Grid>
    </Loader>
  )
}

export default Cenarios;