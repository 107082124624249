import RetornoEndpointDto from "../../../../global/dto/retornoEndpointDto";
import { listarPreFaturamentos } from "../../../../servicos/preFaturamentoServico";
import { executarCalculoCusto } from "../../../../servicos/calculoCustosServico";
import { store } from "../../../../global/redux";
import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";

export default class PreFaturamentoHelper {

  static async listarPreFaturamento(filtro) {
    try {
      const resultado = await listarPreFaturamentos(filtro);

      return RetornoEndpointDto.Sucesso(
        "Pré-faturamento obtido com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu um erro ao obter a lista de pré-faturamento"
      );
    }
  }

  static async executarCalculoCusto(dataReferencia) {
    try {
      const resultado = await executarCalculoCusto(dataReferencia);

      return RetornoEndpointDto.Sucesso(
        "Calculo custos executado com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu um erro ao executar calculo custos"
      );
    }
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }

  static exibirSucesso(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem
      })
    );
  }

}
