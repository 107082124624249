import React from "react";

// Componentes
import { Box } from "@mui/material";

// Styles
import { useStyles } from "./style";
import LogoLuz from "../../../../assets/jornadaCliente/icone-luz.png";
import ImagemGrupo from "../../../../assets/jornadaCliente/grupo-delta.png";
import LogoNottus from "../../../../assets/jornadaCliente/nottus.png";
import LogoZeta from "../../../../assets/jornadaCliente/zeta.png";
import LogoWisebyte from "../../../../assets/jornadaCliente/wisebyte.png";
import LogoDelta from "../../../../themes/delta/assets/Delta_Preto.png";

const GrupoDelta = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <img className={classes.logoDelta} src={LogoDelta} alt="imagem luz" />
      <img
        className={classes.imagemGrupo}
        src={ImagemGrupo}
        alt="imagem lista"
      />

      <Box className={classes.logos}>
        <Box className={classes.logoBox} py={2} pr={2}>
          <img className={classes.luzLogo} src={LogoZeta} alt="imagem luz" />
          <p>Gestão de recursos do fundo Delta Energia Strategy</p>
        </Box>
        <Box className={classes.logoBox} p={2}>
          <img className={classes.luzLogo} src={LogoLuz} alt="imagem luz" />
          <p>Geração Distribuída e varejo para o mercado livre de energia.</p>
        </Box>
        <Box className={classes.logoBox} p={2}>
          <img className={classes.luzLogo} src={LogoNottus} alt="imagem luz" />
          <p>Consultoria e soluções meteorológicas.</p>
        </Box>
        <Box className={classes.logoBox} pl={2} pt={2}>
          <img
            className={classes.luzLogo}
            src={LogoWisebyte}
            alt="imagem luz"
          />
          <p>Soluções tecnológicas para empresas de energia</p>
        </Box>
      </Box>
    </Box>
  );
};

export default GrupoDelta;
