/* eslint-disable import/no-unresolved */
import MICROSERVICO from "global/enumeradores/microServico";
import obterApi from "servicos/api";

const api = () => obterApi(MICROSERVICO.PROJECAOTARIFARIA);

export const uploadBaseEER = async (arquivo, idUsuario, nomeUsuario) => {
    const data = new FormData();
    data.append("file", arquivo.file);
  
    const metodo = "post";
    const url = `v1/ImportarBaseEER/importa?idUsuario=${idUsuario}&nomeUsuario=${nomeUsuario}`;
    return api()[metodo](url, data);
  }

export const getBaseEER = async (anoinicio, anofim) => {
  return api().get(`v1/ImportarBaseEER/visualizarbaseeer/${anoinicio}/${anofim}`);
}
