import { makeStyles } from "@mui/styles";
import theme from "themes";

export const useStyles = makeStyles(() => ({
  select: {
    paddingRight: "10px !important",
    paddingBottom: "5px !important",
    "& .MuiSelect-root": {
      paddingRight: "0px",
      paddingLeft: "8px",
      minWidth: "38px"
    },
    "& .MuiSelect-iconOutlined": {
      right: "2px"
    },
    "& fieldset": {
      borderColor: `${theme.color.selectArredondadoColor} !important`
    }
  },
  label: {
    transform: "translate(8px, 20px) scale(1)",
    color: theme.color.selectArredondadoColor
  },
  input: {
    "& .MuiInputLabel-outlined": {
      color: theme.color.selectArredondadoColor,
      transform: "translate(8px, 20px) scale(1)",
      fontSize: "1rem"
    },
    "& .MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75) !important"
    },
    "& .MuiOutlinedInput-input": {
      padding: "18.5px 8px",
      fontSize: "1rem"
    },
    paddingRight: "10px !important",
    paddingBottom: "5px !important",
    "& label, & input": { color: theme.color.selectArredondadoColor },
    "& fieldset": {
      borderColor: `${theme.color.selectArredondadoColor}`
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      color: theme.color.selectArredondadoColor,
      borderColor: `${theme.color.selectArredondadoColor}`
    },
    "& .Mui-error": {
      color: "#F44336",
      fontSize: "1rem"
  }
  },
  container: {
    width: "100%",
    margin: 0,
    paddingBottom: "15px",
    paddingRight: "10px",
    "& fieldset": {
      borderColor: `${theme.color.selectArredondadoColor} !important`
    },
    "& label, & button, & input": {
      color: theme.color.selectArredondadoColor
    }
  },
  botaoGrid: {
    paddingRight: "10px",
    paddingBottom: "20px"
  },
  info: {
    marginRight: "5px",
    color: theme.color.selectArredondadoColor
  }
}));
