import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const api = () => obterApi(MICROSERVICO.MANAGEMENT);

export const criarDocumentoClickSign = (dadosDocumento) => {
  return api().post("v1/Demo/ClickSign/Documento/Criar", dadosDocumento);
};

export const obterDadosFoto = (foto) => {
  return api().post("v1/Demo/ClickSign/Foto/Upload", { file: foto });
};

export const assinarDocumento = (dto) => {
  return api().post("v1/Demo/ClickSign/Documento/Assinar", {
    chaveDocumentoAssinatura: dto
  });
};
