import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import MaterialInputTexto from "componentes/inputTexto/materialInput";
import { Botao } from "componentes";
import BotaoOutLine from "componentes/botaoOutline";
import InputData from "componentes/inputTextoData";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useSelector } from "react-redux";
import operacaoControleHelper from "../../helper";

import { useStyles } from "./style";

const Devolver = (props) => {
  const {
    dados,
    onCancelar,
    setCarregandoOperacao,
    setNotificacao,
    id,
    obterCancelamento,
    nomeUnidade,
    dadosCancelamento
  } = props;
  const classes = useStyles();
  const usuario = useSelector((state) => state.usuario);
  const [protocoloDistribuidora, setProtocoloDistribuidora] = useState();
  const [dataPrevistaDevolucao, setDataPrevistaDevolucao] = useState();
  const [desabilitarSalvar, setDesabilitarSalvar] = useState();
  const [status, setStatus] = useState();
  const [motivoAtendimento, setMotivoAtendimento] = useState();

  const validarBotao = () => {
    if (status === 3 && motivoAtendimento) {
      setDesabilitarSalvar(false);
      return;
    }
    if (status === 1 && dataPrevistaDevolucao) {
      setDesabilitarSalvar(false);
      return;
    }
    if (status === 2 && dataPrevistaDevolucao && protocoloDistribuidora) {
      setDesabilitarSalvar(false);
      return;
    }
    if (dataPrevistaDevolucao && protocoloDistribuidora) {
      setDesabilitarSalvar(false);
      return;
    }
    setDesabilitarSalvar(true);
  };

  const retornarStatusTexto = () => {
    if (status === 1) {
      return "Status alterado para resolvido";
    }
    if (status === 2) {
      return "Status alterado para reiniciar processo";
    }
    if (status === 3) {
      return "Status alterado para encaminhar para atendimento";
    }
    return "Devolução iniciada com sucesso";
  };

  const validarStatus = () => {
    if (status === 1) return "Cliente Cancelado";
    if (status === 2) return "Devolucao em Andamento";
    if (status === 3) return "Aguardando Atendimento";

    return "Devolucao em andamento";
  };

  const onDevolver = async () => {
    setCarregandoOperacao(true);
    setDesabilitarSalvar(true);

    const resultadoDevolver =
      dados?.solicitacaoCancelamentoId && status !== 2
        ? await operacaoControleHelper.atualizarDevolverCancelamento(
            {
              solicitacaoCancelamentoId: id,
              devolucaoCancelamentoStatusResolucaoId: Number(status),
              ...(protocoloDistribuidora && { protocoloDistribuidora }),
              ...(dataPrevistaDevolucao && {
                dataConclusao: dataPrevistaDevolucao
              }),
              ...(motivoAtendimento && { motivoAtendimento })
            },
            setNotificacao
          )
        : await operacaoControleHelper.postarDevolverCancelamento(
            {
              solicitacaoCancelamentoId: id,
              ...(status === 2 && {
                devolucaoCancelamentoStatusResolucaoId: Number(status)
              }),
              protocoloDistribuidora,
              dataPrevistaDevolucao
            },
            setNotificacao
          );

    setCarregandoOperacao(false);
    setDesabilitarSalvar();

    if (resultadoDevolver?.data) {
      const dadosChamado = {
        id: dadosCancelamento?.numeroChamado,
        assunto: `CANCELAMENTO CONTRATO LUZ - ${nomeUnidade}`,
        tipoRegistroId: process.env.REACT_APP_TIPO_REGISTRO_ID,
        unidadeId: dados?.unidadeId,
        ...(motivoAtendimento && status === 3 && { motivoAtendimento }),
        ...(dados?.dataPrevistaDevolucao && {
          dataPrevistaDevolucao: dados?.dataPrevistaDevolucao
        }),
        ...(dados?.protocoloDistribuidora && {
          numeroProtocoloDevolucao: dados?.protocoloDistribuidora
        }),
        emailColaborador: usuario?.usuario?.email,
        status: validarStatus()
      };

      await operacaoControleHelper.atualizarSFIntegracaoEnergiaChamado(
        dadosChamado,
        setNotificacao
      );

      setNotificacao({
        aberto: true,
        tipo: "success",
        texto: retornarStatusTexto()
      });

      onCancelar();
      obterCancelamento();
    }
  };

  const onChangeStatus = async (valor) => {
    if (Number(valor?.target?.value) === 3) {
      setDataPrevistaDevolucao();
      setProtocoloDistribuidora();
    }

    setStatus(Number(valor?.target?.value));
  };

  useEffect(() => {
    validarBotao();
  }, [
    protocoloDistribuidora,
    dataPrevistaDevolucao,
    status,
    motivoAtendimento
  ]);

  return (
    <>
      <Grid container spacing={1}>
        {dados?.solicitacaoCancelamentoId && (
          <Grid item xs={12} className={classes.inputRadio}>
            <FormControl>
              <RadioGroup
                name="radio-buttons-group"
                value={status}
                onChange={onChangeStatus}
              >
                <FormControlLabel
                  value={1}
                  control={<Radio />}
                  label="Resolvido"
                />
                <FormControlLabel
                  value={2}
                  control={<Radio />}
                  label="Reiniciar processo"
                />
                <FormControlLabel
                  value={3}
                  control={<Radio />}
                  label="Encaminhar para atendimento"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        )}
        {(status === 2 || !dados?.solicitacaoCancelamentoId) && (
          <Grid item xs={12} className="font-weight-bold">
            <label htmlFor="motivos" className={classes.label}>
              Protocolo da distribuidora
            </label>
            <MaterialInputTexto
              type="text"
              id="ticket"
              name="ticket"
              label="Digite o protocolo da distribuidora"
              renderIconShowHide={false}
              defaultValue={protocoloDistribuidora}
              onInput={(e) => setProtocoloDistribuidora(e.target.value)}
            />
          </Grid>
        )}
        {(status === 1 ||
          status === 2 ||
          !dados?.solicitacaoCancelamentoId) && (
          <Grid item xs={12} className="font-weight-bold">
            <label htmlFor="motivos" className={classes.label}>
              {status === 1
                ? "Data da devolução"
                : "Data prevista de devolução"}
            </label>
            <InputData
              type="text"
              id="dataReajuste"
              name="dataReajuste"
              label="DD/MM/YYYY"
              customValue={dataPrevistaDevolucao}
              onChange={(data) => setDataPrevistaDevolucao(data)}
              renderIconShowHide={false}
              className={classes}
            />
          </Grid>
        )}

        {status === 3 && (
          <Grid item xs={12} className="font-weight-bold">
            <label htmlFor="motivos" className={classes.label}>
              Descrição do motivo do problema
            </label>
            <textarea
              id="motivoAtendimento"
              name="motivoAtendimento"
              defaultValue={motivoAtendimento}
              className={classes.inputTextArea}
              onChange={(valor) => setMotivoAtendimento(valor.target.value)}
              maxLength={200}
              placeholder="Digite o motivo do problema"
              autoComplete="off"
            />
          </Grid>
        )}

        <Grid item xs={6} mt={2}>
          <BotaoOutLine
            onClick={() => onCancelar()}
            label="Cancelar"
            className={classes.botaoTransparente}
          />
        </Grid>
        <Grid item xs={6} mt={2}>
          <Botao
            onClick={() => onDevolver()}
            label="Salvar"
            className={classes.botao}
            disabled={desabilitarSalvar}
          />
        </Grid>
      </Grid>
    </>
  );
};

Devolver.propTypes = {
  dados: PropTypes.oneOfType([PropTypes.any]),
  onCancelar: PropTypes.func,
  setCarregandoOperacao: PropTypes.func,
  setNotificacao: PropTypes.func,
  id: PropTypes.number,
  nomeUnidade: PropTypes.string,
  obterCancelamento: PropTypes.func,
  dadosCancelamento: PropTypes.oneOfType([PropTypes.any])
};

Devolver.defaultProps = {
  dados: null,
  onCancelar: () => {},
  setCarregandoOperacao: () => {},
  setNotificacao: () => {},
  id: 0,
  obterCancelamento: () => {},
  nomeUnidade: "",
  dadosCancelamento: null
};

export default Devolver;
