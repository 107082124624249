import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";
import { baixarArquivo } from "./arquivoServico";

const api = () => obterApi(MICROSERVICO.MANAGEMENT);

export const salvarDocumentosDemandaFaturasCCER = async (
  arquivos,
  protocoloDemanda
) => {
  const formdata = new FormData();

  arquivos.forEach((arquivo) => {
    formdata.append("arquivos", arquivo, arquivo.path);
  });

  return api().post(
    `v1/DemandaArquivos/FaturasCCER?protocoloDemanda=${
      protocoloDemanda?.protocoloDemanda ?? ""
    }`,
    formdata
  );
};

export const salvarDocumentosDemandaDemonstrativoDRE = async (
  arquivos,
  protocoloDemanda
) => {
  const formdata = new FormData();

  arquivos.forEach((arquivo) => {
    formdata.append("arquivos", arquivo, arquivo.path);
  });

  return api().post(
    `v1/DemandaArquivos/BalancoDre?protocoloDemanda=${
      protocoloDemanda?.protocoloDemanda ?? ""
    }`,
    formdata
  );
};

export const salvarFaturasEnergia = async (arquivos, protocoloDemanda) => {
  const formdata = new FormData();

  arquivos.forEach((arquivo) => {
    formdata.append("arquivos", arquivo, arquivo.path);
  });

  return api().post(
    `v1/DemandaArquivos/FaturasEnergia?protocoloDemanda=${
      protocoloDemanda?.protocoloDemanda ?? ""
    }`,
    formdata
  );
};

export const baixarDocumentosDemandaAttend = async (
  nomeArquivo,
  protocoloDemanda
) => {
  const url = `v1/DemandaArquivos/Download?protocoloDemanda=${
    protocoloDemanda?.protocoloDemanda ?? ""
  }&nomeArquivo=${nomeArquivo}`;

  return baixarArquivo(api, url, nomeArquivo);
};

export const deleteArquivo = async (
  nomeArquivo,
  protocoloDemanda,
  tipoArquivoDemanda
) => {
  const formdata = new FormData();
  const url = `v1/DemandaArquivos/DeleteArquivo?protocoloDemanda=${
    protocoloDemanda?.protocoloDemanda ?? ""
  } 
   &nomeArquivo=${nomeArquivo ?? ""}
   &tipoArquivo=${tipoArquivoDemanda}`;

  return api().post(url, formdata);
};

export const salvarDocumentosDemandaContratoSocialProcuracao = async (
  arquivos,
  protocoloDemanda
) => {
  const formdata = new FormData();

  arquivos.forEach((arquivo) => {
    formdata.append("arquivos", arquivo, arquivo.path);
  });

  return api().post(
    `v1/DemandaArquivos/ContratoSocialProcuracao?protocoloDemanda=${
      protocoloDemanda?.protocoloDemanda ?? ""
    }`,
    formdata
  );
};

export const listarDocumentosDemanda = (protocoloDemanda) => {
  return api().get(
    `v1/DemandaArquivos?protocoloDemanda=${
      protocoloDemanda?.protocoloDemanda ?? ""
    }`
  );
};

export const indicarRepresentanteLegal = (representantes, protocoloDemanda) => {
  return api().post(
    `v1/DemandaAtend/representantes?protocoloDemanda=${
      protocoloDemanda?.protocoloDemanda ?? ""
    }`,
    representantes
  );
};

export const consultarRepresentanteLegal = (protocoloDemanda) => {
  return api().get(
    `v1/DemandaAtend/representantes?protocoloDemanda=${protocoloDemanda}`
  );
};

export const gerarContrato = (tipoEnvio, protocoloDemanda) => {
  return api().post(
    `v1/ContratoAtend/Adesao/TipoEnvio/${tipoEnvio}?protocoloDemanda=${
      protocoloDemanda?.protocoloDemanda ?? ""
    }`
  );
};

export const obterDemanda = (protocoloDemanda) => {
  return api().get(
    `v1/DemandaAtend/obter-demanda?protocoloDemanda=${
      protocoloDemanda?.protocoloDemanda ?? ""
    }`
  );
};

export const gerarContratoConfirmacao = (tipoEnvio, protocoloDemanda) => {
  return api().post(
    `v1/ContratoAtend/Confirmacao/TipoEnvio/${tipoEnvio}?protocoloDemanda=${
      protocoloDemanda?.protocoloDemanda ?? ""
    }`
  );
};

export const gerarContratoConfirmacaoGD = (tipoEnvio, protocoloDemanda) => {
  return api().post("v1/ContratoAtend/gd/Confirmacao", {
    tipoEnvio,
    protocoloDemanda
  });
};

export const importarFaturasEnergiaTramitacao = (protocoloDemanda) => {
  return api().post(
    `v1/DemandaAtend/faturasEnergia?protocoloDemanda=${
      protocoloDemanda?.protocoloDemanda ?? ""
    }`,
    null
  );
};
