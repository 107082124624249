/* eslint-disable jsx-a11y/media-has-caption */
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

// Componentes
import { Close, PlayArrow } from "@mui/icons-material";
import { Grid } from "@mui/material";
import Modal from "@mui/material/Modal";
import { useSelector } from "react-redux";
import Video from "../video";

// Styles
import { useStyles } from "./style";

import Helper from "../../helper";

const ModalMaior = ({ item, relatorio, listaVideos, onCancelar }) => {
  const classes = useStyles();
  const [aberto, setAberto] = useState(false);
  const [modalVideoAberto, setModalVideoAberto] = useState(false);
  const [videos, setVideos] = useState([]);
  const [videoSelecionado, setVideoSelecionado] = useState();

  const onClose = () => {
    setAberto(false);
    setVideoSelecionado("");
    setModalVideoAberto(false);
    onCancelar();
  };

  const global = useSelector((state) => state.usuario);
  const { clienteSelecionado, usuario } = global;

  const abrirVideo = (index) => {
    setVideoSelecionado({
      index,
      src: videos[index].link
    });
    if (usuario.tipo !== "Colaborador") {
      const salvarMetricas = {
        RelatorioId: relatorio?.id,
        DataVisualizacaoVideo: new Date()
      };
      if (clienteSelecionado !== "")
        salvarMetricas.ClienteId = clienteSelecionado;
      Helper.Salvar(salvarMetricas);
    }

    setModalVideoAberto(true);
  };

  const finalizarVideo = (video) => {
    if (videoSelecionado.index + 1 < videos.length) {
      setVideoSelecionado({
        index: videoSelecionado.index + 1,
        src: videos[videoSelecionado.index + 1].link
      });
      video.play();
    }
  };

  useEffect(() => {
    setAberto(Boolean(item));
    setVideos(listaVideos);
  }, [item, listaVideos]);

  return (
    <Modal open={aberto} onClose={onClose}>
      {modalVideoAberto ? (
        <Grid container className={classes.videoContainer}>
          <Video
            cancelar={() => setModalVideoAberto(false)}
            finalizarVideo={(video) => finalizarVideo(video)}
            src={videoSelecionado.src}
          />
        </Grid>
      ) : (
        <Grid container className={classes.container}>
          <Grid item xs={11} className="pb-2">
            <h3 className={classes.titulo}>
              {relatorio.nomeTipoRelatorio && relatorio?.nomeTipoRelatorio}
            </h3>
            {relatorio?.dataReferencia ? (
              <h3 className={classes.titulo}>
                {`${relatorio.dataReferencia.slice(
                  3,
                  5
                )} / ${relatorio.dataReferencia.slice(6, 10)}`}
              </h3>
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={1} className={classes.close}>
            <Close
              onClick={() => {
                onClose();
              }}
            />
          </Grid>

          {videos?.length > 0 &&
            videos.map((video, index) => (
              <Grid container className={classes.listaVideos} key={index}>
                <Grid
                  item
                  xs={6}
                  sm={5}
                  lg={4}
                  onClick={() => abrirVideo(index)}
                >
                  <div className={classes.videoPlay}>
                    {video?.linkImagem ? (
                      <img
                        src={video.linkImagem}
                        className={classes.videoPrevia}
                        alt="prévia do vídeo"
                      />
                    ) : (
                      <video
                        className={classes.videoPrevia}
                        ref={(el) => {
                          if (el) {
                            el.currentTime = 3;
                            el.load();
                          }
                        }}
                      >
                        <source src={video.link} />
                        Seu browser não suporta HTML video.
                      </video>
                    )}
                    <div className={classes.videoIcon}>
                      <PlayArrow />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6} sm={7} lg={8}>
                  <h4 className={classes.tituloVideo}>{video.nome}</h4>
                  <p className={classes.descricao}>{video.descricao}</p>
                </Grid>
                {index + 1 < videos.length && (
                  <hr className={classes.videoHr} />
                )}
              </Grid>
            ))}
        </Grid>
      )}
    </Modal>
  );
};
ModalMaior.propTypes = {
  item: PropTypes.oneOfType([PropTypes.any]).isRequired,
  relatorio: PropTypes.oneOfType([PropTypes.any]).isRequired,
  listaVideos: PropTypes.oneOfType([PropTypes.any]).isRequired,
  onCancelar: PropTypes.func
};

ModalMaior.defaultProps = {
  onCancelar: () => {}
};

export default ModalMaior;
