import { store } from "../../../global/redux";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";
import RetornoEndpointDto from "../../../global/dto/retornoEndpointDto";

import {
  obterBeneficioPrecoFixo,
  salvarPrecoFixoFatura,
  atualizaStatusPrecoFixoFatura
} from "../../../servicos/memberGetMemberServico";

export default class MemberGetMemberPrecoFixo {
  static async obterBeneficioPrecoFixo(
    filtro,
    setCarregandoBenificioPrecoFixo
  ) {
    try {
      setCarregandoBenificioPrecoFixo(true);
      const resultado = await obterBeneficioPrecoFixo(filtro);

      return RetornoEndpointDto.Sucesso(
        "Preço fixo encontrada com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Erro ao encontrar preço fixo!");
    } finally {
      setCarregandoBenificioPrecoFixo(false);
    }
  }

  static async salvarPrecoFixoFatura(
    id,
    body,
    setCarregandoBenificioPrecoFixo
  ) {
    try {
      setCarregandoBenificioPrecoFixo(true);
      const resultado = await salvarPrecoFixoFatura(id, body);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Preço fixo não encontrado!");

      return RetornoEndpointDto.Sucesso(
        "Preço fixo fatura salva com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Erro ao salvar preço fixo!");
    } finally {
      setCarregandoBenificioPrecoFixo(false);
    }
  }

  static async atualizaStatusPrecoFixoFatura(
    params,
    setCarregandoBenificioPrecoFixo
  ) {
    try {
      setCarregandoBenificioPrecoFixo(true);
      const resultado = await atualizaStatusPrecoFixoFatura(params);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro(
          "Status preço fixo fatura não encontrado!"
        );

      return RetornoEndpointDto.Sucesso(
        "Status preço fixo fatura atualizado com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Erro ao atualizar status preço fixo fatura!"
      );
    } finally {
      setCarregandoBenificioPrecoFixo(false);
    }
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }

  static exibirSucesso(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem
      })
    );
  }
}
