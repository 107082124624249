import { makeStyles } from "@mui/styles";
import theme from "themes";

export const useStyles = makeStyles(() => ({
  input: {
    borderColor: "transparent !important",
    "& .MuiInputBase-input": {
      padding: 0,
      borderColor: "transparent !important",
      textOverflow: "initial !important",
      whiteSpace: "inherit !important",
      overflow: "initial !important",
      lineHeight: "16px !important"
    },
    "& fieldset": {
      borderColor: "transparent !important",
      "& :focus-visible": {
        borderColor: "transparent"
      }
    },
    "& .MuiSelect-icon": {
      color: theme.color.primary
    }
  }
}));
