import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const apiConsolidacao = () => obterApi(MICROSERVICO.MEASUREMENT);

// Gráfico de consumo diário
export const consumoDiario = async (
  diaMesInicio,
  diaMesFim,
  unidadeSelecionada
) => {
  return apiConsolidacao().get(
    `v1/ConsolidacaoDiaria/${unidadeSelecionada}/${diaMesInicio}/${diaMesFim}`
  );
};

export const consumoMensal = async (mesInicio, mesFim, unidadeSelecionada) => {
  return apiConsolidacao().get(
    `v1/consolidacaomensal/unidadeconsumidora/${unidadeSelecionada}/datainicio/${mesInicio}/datafim/${mesFim}`
  );
};

// Gráfico de consumo hora
export const consumoHora = async (diaMes, unidadeSelecionada) => {
  return apiConsolidacao().get(
    `v1/ConsolidacaoHoraria/unidadeconsumidora/${unidadeSelecionada}/datamedicao/${diaMes}`
  );
};

export const consumoHoraUltimaMedicao = async (unidadeSelecionada) => {
  return apiConsolidacao().get(
    `v1/ConsolidacaoHoraria/unidadeconsumidora/${unidadeSelecionada}/ultimaMedicao`
  );
};
