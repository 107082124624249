import { makeStyles } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import { Base } from "../cores";
import themes from "../../themes";

const theme = createTheme();

export const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: `${Base.WhiteLuz} !important`,
    border: `1px solid  ${Base.TextLuzOpacity}`,
    borderRadius: "16px !important",
    boxShadow: "0px 2px 4px 1px rgba(0, 0, 0, 0.16) !important",
    outline: "none !important",
    position: "absolute !important",
    padding: theme.spacing(4),
    transform: "translate(-50%, -50%) !important",
    color: themes.color.thirdText,
    [theme.breakpoints.up("xs")]: {
      top: "37% !important",
      left: "50% !important",
      width: "97% !important"
    },
    [theme.breakpoints.up("sm")]: {
      top: "37% !important",
      left: "50% !important",
      width: "83%"
    },
    [theme.breakpoints.up("md")]: {
      top: "46% !important",
      left: "53% !important",
      width: "83% !important"
    },
    [theme.breakpoints.up("lg")]: {
      top: "50% !important",
      left: "60% !important",
      width: "30% !important"
    },
    [theme.breakpoints.up("xl")]: {
      top: "48% !important",
      left: "56% !important",
      width: "20% !important"
    }
  }
}));
