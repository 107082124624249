import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import { GetApp } from "@mui/icons-material";
import { Loader } from "../../../../../componentes";
import { HtmlTooltip } from "../../../../Graficos/ConsumoAtual/style";
import operacaoControleHelper from "../../helper";

// Styles
import { useStyles, TabelaModal } from "../style";

const ModalTrocaTitularidadeFatura = ({
  listaTrocaTitularidadeFaturaVigente
}) => {
  const classes = useStyles();
  const [carregandoModalFaturas, setCarregandoModalFaturas] = useState(false);

  const onClickDownloadFatura = async (guid, nomeArquivo) => {
    setCarregandoModalFaturas(true);
    await operacaoControleHelper.downloadFatura(guid, nomeArquivo);
    setCarregandoModalFaturas(false);
  };

  return (
    <Loader loading={carregandoModalFaturas} className="w-auto">
      <TabelaModal>
        <thead>
          <TabelaModal.Tr container borderBottom>
            <TabelaModal.ThLeft xs={6}>
              <div>
                <b>Arquivo</b>
              </div>
            </TabelaModal.ThLeft>
            <TabelaModal.Th xs={6} />
          </TabelaModal.Tr>
        </thead>
        <tbody>
          {listaTrocaTitularidadeFaturaVigente?.map((item, a) => (
            <TabelaModal.Tr container key={a}>
              <TabelaModal.TdLeft xs={6}>
                <div>{item?.nomeArquivo}</div>
              </TabelaModal.TdLeft>
              <TabelaModal.Td xs={6}>
                <Button
                  onClick={() =>
                    onClickDownloadFatura(item?.faturaGuide, item?.nomeArquivo)
                  }
                >
                  <HtmlTooltip title="Baixar">
                    <GetApp className={`${classes.button}`} />
                  </HtmlTooltip>
                </Button>
              </TabelaModal.Td>
            </TabelaModal.Tr>
          ))}
        </tbody>
      </TabelaModal>
    </Loader>
  );
};

ModalTrocaTitularidadeFatura.propTypes = {
  listaTrocaTitularidadeFaturaVigente: PropTypes.oneOfType([PropTypes.any])
};

ModalTrocaTitularidadeFatura.defaultProps = {
  listaTrocaTitularidadeFaturaVigente: []
};

export default ModalTrocaTitularidadeFatura;
