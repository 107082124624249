import React from "react";
import PropTypes from "prop-types";

// Componentes
import { Box, Grid } from "@mui/material";
import { useStyles } from "./style";

const Card = ({
  dados,
  color,
  iconeSize,
  fontSize,
  colunas,
  maxHeight,
  minHeight
}) => {
  const classes = useStyles({
    iconeSize,
    fontSize,
    color,
    minHeight
  });

  return (
    <Grid
      item
      xs={12}
      sm={colunas}
      height="100%"
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box className={classes.card} maxHeight={maxHeight}>
        <Box
          height={iconeSize || "auto"}
          mb={2}
          display="flex"
          alignItems="center"
        >
          {dados?.icone && (
            <img src={dados.icone} alt="Icone" className={classes.icone} />
          )}
          {dados.titulo && dados.titulo}
        </Box>
        <Box mb={1}>{dados.texto}</Box>
      </Box>
    </Grid>
  );
};

Card.propTypes = {
  dados: PropTypes.shape({
    icone: PropTypes.string,
    titulo: PropTypes.node,
    texto: PropTypes.node
  }),
  color: PropTypes.string,
  iconeSize: PropTypes.string,
  fontSize: PropTypes.string,
  colunas: PropTypes.number,
  maxHeight: PropTypes.string,
  minHeight: PropTypes.string
};

Card.defaultProps = {
  dados: {},
  color: "",
  iconeSize: "",
  fontSize: "",
  colunas: 4,
  maxHeight: "350px",
  minHeight: "280px"
};

export default Card;
