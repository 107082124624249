export default class ListagemCotacaoDto {
  constructor(
    id,
    nomeFantasia,
    cnpj,
    numeroInstalacao,
    plano,
    dataCotacao,
    dataValidade,
    status,
    ativo,
    cotacaoSelecionada,
    statusCotacao,
    usuarioCargoId,
    liberaPropostaContrato,
    cargoId
  ) {
    this.id = id;
    this.nomeFantasia = nomeFantasia;
    this.cnpj = cnpj;
    this.numeroInstalacao = numeroInstalacao;
    this.plano = plano;
    this.dataCotacao = dataCotacao;
    this.dataValidade = dataValidade;
    this.status = status;
    this.statusCotacao = statusCotacao;
    this.ativo = ativo;
    this.cotacaoSelecionada = cotacaoSelecionada;
    this.usuarioCargoId = usuarioCargoId;
    this.liberaPropostaContrato = liberaPropostaContrato;
    this.cargoId = cargoId;
  }
}
