import moment from "moment";
import React from "react";
import InputData from "../inputTextoData";

export default class ColunaCampoDataInicio {
  constructor(
    field,
    headerName,
    sortable = false,
    onChangeSelecionarDataInicio,
    maxWidth = null,
    minWidth = null,
    dataInicio = null,
    idCampoSelecionadoInicio = null,
    selecionadoId = null,
    enabled = false
  ) {
    this.selector = field;
    this.name = headerName;
    this.sortable = sortable;
    this.maxWidth = maxWidth;
    this.minWidth = minWidth;
    this.dataInicio = dataInicio;
    this.idCampoSelecionadoInicio = idCampoSelecionadoInicio;
    this.selecionadoId = selecionadoId;
    this.enabled = enabled;
    this.cell = (params) => {
      moment().format("DD/MM/YYYY");
      return (
        <InputData
          type="date"
          id={`dataInicio${params.id}`}
          name={`dataInicio${params.id}`}
          customValue={
            params.id === idCampoSelecionadoInicio
              ? dataInicio
              : params.dataInicioVigencia
          }
          onAccept={(value) => {
            onChangeSelecionarDataInicio(value, params);
          }}
          onBlur={(event) => {
            if (event.target.value) {
              const data = event.target.value;
              const dia = data.split("/")[0];
              const mes = data.split("/")[1];
              const ano = data.split("/")[2];
              const novaData = new Date(
                `${ano 
                  }-${ 
                  (`0${  mes}`).slice(-2) 
                  }-${ 
                  (`1${  dia}`).slice(-2) 
                  }T00:00`
              );

              onChangeSelecionarDataInicio(novaData, params);
            }
          }}
          params={params}
          disabled={!(params.id === selecionadoId && enabled)}
          InputProps={{
            disableUnderline: true
          }}
          minDate={new Date()}
        />
      );
    };
  }
}
