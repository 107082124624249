import { makeStyles } from "@mui/styles";
import styled from "styled-components";
import theme from "themes";

export const useStyles = makeStyles({
  label: {
    alignItems: "center !important",
    backgroundColor: theme.color.bgItemExpansivel,
    color: `${theme.color.secondaryText} !important`,
    display: "flex",
    fontSize: (props) => (props.mobile ? "11px !important" : "18px !important"),
    fontWeight: (props) =>
      props.mobile ? "700 !important" : "18px !important",
    height: "54px !important",
    marginRight: "2px !important",
    opacity: (props) => (props.ativo ? 1 : 0.5),
    padding: "0 15px !important"
  },
  squareWidth: {
    height: "54px !important",
    maxWidth: "54px !important",
    width: "100% !important",
    "&:not(:last-child)": {
      marginRight: "2px !important"
    }
  },
  gridItem: {
    flexBasis: "100% !important",
    fontSize: "2.1875rem",
    margin: "0 !important",
    maxWidth: "54px !important",
    width: "100% !important"
  },
  treeItem: {
    backgroundColor: theme.color.bgItemExpansivel,
    color: `${theme.color.secondaryText} !important`,
    height: "54px"
  },
  expandItem: {
    backgroundColor: `${theme.color.bgItemExpansivel} !important`,
    fontSize: "18px",
    height: "auto",
    marginLeft: "56px !important",
    marginTop: "2px !important",
    minWidth: "calc(100% - 56px)",
    padding: "15px 20px"
  },
  wrapper: {
    alignItems: "center",
    display: "flex",
    height: "100%",
    minHeight: "100%"
  },
  center: {
    justifyContent: "center"
  }
});

export const Label = styled.div``;
