import { makeStyles } from '@mui/styles';
import styled from "styled-components";
import { Base } from 'componentes/cores';
import _theme from 'themes';
import { createTheme } from "@mui/material/styles";

const theme = createTheme();

export const useStyles = makeStyles(() => ({
  container: {
    margin: "0 0 16px 0",
    width: '100%',
    "& .MuiGrid-root": {
      color: `${Base.BlackBastille} !important`
    },
    "& .MuiDropzoneArea-text": {
      color: `${Base.BlackBastille} !important`
    },
    "& .MuiInputBase-root": {
      height: "46px"
    },
    "& .MuiFormLabel-root": {
      lineHeight: "1",
      color: "white",
      fontSize: '18px'
    }
  },
  containerTitulo: {
    padding: '8px !important',   
  },
  titulo: {
    fontSize: '26px',
    color: '#FFF !important',
    fontFamily: _theme.font.family,
    fontWeight:'400'
  },
  containerData: {
    padding: '32px !important'
  },
  icon: {
    cursor: "pointer",
    float:"right",
    margin: "5px",
    color: '#FFF !important'
  },
  containerData: {
    padding: '32px !important'
  },
  button: {
    fontSize: '18px',
  },
  customButton: {
    fontSize: '18px',
    backgroundColor: "transparent !important"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
  },
  fileUpload: {
    "& .MuiDropzoneArea-root": {
      minHeight: "150px",
      borderWidth: "2px"
    },
    "& .MuiDropzoneArea-text": {
      fontSize: "16px"
    }
  },
  selectOfficer: {
    filter: "invert(1)",
    marginBottom: "0px",
  },
  customSelect: {
    "& .MuiFormControl-root": {
      borderRadius: "4px",
      color: "gray",
      border: "1px solid gray",
      marginRight: "30px"
    },

    "& .MuiOutlinedInput-inputAdornedEnd": {
      color: "white",
    }
  },
  customTabs: {
    "& .MuiTab-textColorPrimary.Mui-selected": {
      color: "gray"
    }
  },
  formControl: {
    width: "100%",
    "& .MuiInputBase-root": {
        height: "45px",
        color: `${Base.White} !important`,
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#FFF"
        }
    },
    "& .MuiSelect-icon": {
        color: Base.White
    },
    "& option": {
        color: Base.BlackBastille
    },
    "& .MuiSelect-select.MuiSelect-select": {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        color: "#eef0f9"
    },
    "& .MuiSelect-outlined.MuiSelect-outlined": {
        padding: "0 40px 0 20px "
    },
    "& .MuiFormLabel-root": {
        color: `${Base.OffWhite} !important`,
    }
}  
}));

export const customStyles = {
  table: {
    style: {
      background: "transparent"
    }
  },
  header: {
    style: {
      background: "transparent"
    }
  },
  subHeader: {
    style: {
      background: "transparent"
    }
  },
  headRow: {
    style: {
      background: "transparent"
    }
  },
  headCells: {
    style: {
      background: "#2a2b32",
      marginLeft: "3px",
      fontSize: "16px",
      "& div": {
        color: `${Base.OffWhite} !important`,
        "&:hover": {
          color: `${Base.OffWhite} !important`
        }
      }
    }
  },
  rows: {
    style: {
      background: "transparent",
      marginBottom: "1px",
      marginTop: "1px",
      color: Base.White
    }
  },
  Pagination: {
    styles: {
      color: "#eef0f9"
    }
  },
  cells: {
    style: {
      marginLeft: "3px",
      background: "#32333b",
      fontSize: "16px",
      color:Base.default
    }
  } 
};