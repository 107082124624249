/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-empty */
/* eslint-disable no-unused-expressions */
/* eslint-disable import/named */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";

// React Hook Form
import { useForm } from "react-hook-form";

// Validator
import { cnpj, cpf } from "cpf-cnpj-validator";

// Material
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box, Card, Grid, IconButton } from "@mui/material";

// Redux
import { useSelector } from "react-redux";

// Componentes
import Botao from "componentes/botao";
import BotaoCadastro from "componentes/botaoCadastro";
import BotaoRetornarListagem from "componentes/botaoRetornarListagem";
import { Base } from "componentes/cores";
import FormValidation from "componentes/formValidation";
import MaterialInputTexto from "componentes/inputTexto/materialInput";
import MaterialInputTelefone from "componentes/inputTexto/materialInputTelefone";
import MaterialInputMascara from "componentes/inputTextoMascara";
import Loader from "componentes/loader";
import SelectArredondado from "componentes/selectArredondado";
import MaterialSwitch from "componentes/switch";
import UploadDropAreaMultiplo from "componentes/uploadMultiplo";

// Styles

// Serviços
import RetornoEndpointDto from "global/dto//retornoEndpointDto";
import { InterfaceDTO } from "global/dto/interfacesDto";
import { obterArvoreGerencialAltoNivel } from "servicos/arvoresGerenciaisServico";
import { baixarArquivoFatura } from "servicos/clienteArquivoFaturaService";
import { buscaCep } from "servicos/correiosServico";
import {
  buscarPorCnpjCotacao,
  dadosPlanoEscolhido,
  listaArquivosCotacao,
  salvarClienteCotacao,
  uploadFatura
} from "servicos/cotacaoInicialServico";
import { usuarioPossuiFuncionalidade } from "servicos/funcionalidadesServico";
import { removerCaracteres } from "servicos/utils";

// Global
import BotaoOutLine from "componentes/botaoOutline";
import ModalInformacao from "componentes/modalVerificacao";
import { RotasDTO } from "global/rotas/rotasUrlDto";
import { unidadesFederativas } from "../../../global/constantes";

// DTO
import { store } from "../../../global/redux";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";
import {
  desabilitarCliente,
  desabilitarEmpresa,
  desabilitarUnidade
} from "../../../global/redux/modulos/usuario/actions";
import CotacaoInicialHelper from "../CotacaoInicial/helper";
import PlanosSimuladosHelper from "../Planos/helper";
import DadosEmpresaHelper from "./helper";
import {
  CardHeader,
  CardPlano,
  ImagemPlano,
  Label,
  Plano,
  useStyles
} from "./style";

import getDomain from "../../../global/getDomain";

import IconeBronzeDelta from "../../../assets/cotacoes/delta/bronze.png";
import IconeOuroDelta from "../../../assets/cotacoes/delta/ouro.png";
import IconePrataDelta from "../../../assets/cotacoes/delta/prata.png";

import IconeBronzeLuz from "../../../assets/cotacoes/luz/bronze.png";
import IconeOuroLuz from "../../../assets/cotacoes/luz/ouro.png";
import IconePrataLuz from "../../../assets/cotacoes/luz/prata.png";

import IconeBronzeThymos from "../../../assets/cotacoes/thymos/bronze.png";
import IconeOuroThymos from "../../../assets/cotacoes/thymos/ouro.png";
import IconePrataThymos from "../../../assets/cotacoes/thymos/prata.png";
import PlanosConstante from "../Planos/constantes/planosConstante";

const DadosEmpresaMobile = () => {
  const {
    register,
    errors,
    handleSubmit,
    control,
    setValue,
    getValues
  } = useForm({
    reValidateMode: "onSubmit"
  });
  const location = useLocation();
  const history = useHistory();
  const usuario = useSelector((state) => state.usuario);
  const { state } = location;
  const { dadosCnpj: dadosDoState, dadosCotacaoInicial } = state || {};
  const [carregandoDados, setCarregandoDados] = useState(false);
  const [dadosEmpresa, setDadosEmpresa] = useState();
  const [limparCnpj, setLimparCnpj] = useState(false);
  const [ufId, setUFId] = useState();
  const [carregandoEndereco, setCarregandoEndereco] = useState(false);
  const [dadosEndereco, setDadosEndereco] = useState();
  const [carregandoUF, setCarregandoUF] = useState(false);
  const [listaUF, setListaUF] = useState([]);
  const [numero, setNumero] = useState();
  const [complemento, setComplemento] = useState();
  const [dadosClienteEdicao, setDadosClienteEdicao] = useState(null);
  const [contatoInicial, setContatoInicial] = useState(null);
  const [limparCpf, setLimparCpf] = useState(false);
  const [limparRgRepresentante, setLimparRgRepresentante] = useState(false);
  const [limparCpfRepresentante, setLimparCpfRepresentante] = useState(false);
  const [limparRgRepresentante2, setLimparRgRepresentante2] = useState(false);
  const [limparCpfRepresentante2, setLimparCpfRepresentante2] = useState(false);
  const [renderComponenteEnergia, setRenderComponenteEnergia] = useState(true);
  const [arquivosCotacaoEnergia, setArquivosCotacaoEnergia] = useState();
  const [cotacaoFechada, setCotacaoFechada] = useState();
  const [cadastro, setCadastro] = useState(false);
  const [modalInfo, setModalInfo] = useState(false);
  const [listaPlanos, setListaPlanos] = useState("");
  const [dadosPlanos, setDadosPlanos] = useState([]);
  const [
    bloquearRepresentantesContato,
    setBloquearRepresentatesContato
  ] = useState(false);

  const [infoPlanoSelecionado, setInfoPlanoSelecionado] = useState([]);

  const classes = useStyles({
    planoCor: infoPlanoSelecionado?.tipoPlano,
    corTexto: infoPlanoSelecionado?.tipoPlano
  });

  const dominio = `${getDomain()}`;

  const [
    renderComponenteContratoSocial,
    setRenderComponenteContratoSocial
  ] = useState(true);
  const [
    arquivosCotacaoContratoSocial,
    setArquivosCotacaoContratoSocial
  ] = useState();
  const [renderComponenteProcuracao, setRenderComponenteProcuracao] = useState(
    true
  );
  const [arquivosCotacaoProcuracao, setArquivosCotacaoProcuracao] = useState();
  const [renderComponenteCCER, setRenderComponenteCCER] = useState(true);
  const [arquivosCotacaoCCER, setArquivosCotacaoCCER] = useState();
  const [carregandoUploadEnergia, setCarregandoUploadEnergia] = useState(0);
  const [
    carregandoUploadContratoSocial,
    setCarregandoUploadContratoSocial
  ] = useState(0);
  const [carregandoUploadProcuracao, setCarregandoUploadProcuracao] = useState(
    0
  );
  const [carregandoUploadCCER, setCarregandoUploadCCER] = useState(0);
  const [arquivoFaturaEnergia, setArquivoFaturaEnergia] = useState();
  const [
    arquivoFaturaContratoSocial,
    setArquivoFaturaContratoSocial
  ] = useState();
  const [arquivoFaturaProcuracao, setArquivoFaturaProcuracao] = useState();
  const [arquivoFaturaCCER, setArquivoFaturaCCER] = useState();
  const [habilitaRepresentante, setHabilitaRepresentante] = useState(false);
  const [
    habilitaSegundoRepresentante,
    setHabilitaSegundoRepresentante
  ] = useState(false);
  const [ehRepresentante, setEhRepresentante] = useState(false);
  const [redireciona, setRedireciona] = useState(false);
  const nomeContatoDefault = useMemo(() => {
    return dadosEmpresa?.nomeContatoInicial || contatoInicial?.nomeCompleto;
  }, [dadosEmpresa, contatoInicial]);
  const emailContatoDefault = useMemo(() => {
    return dadosEmpresa?.emailContatoInicial || contatoInicial?.email;
  }, [dadosEmpresa, contatoInicial]);
  const telefoneContatoDefault = useMemo(() => {
    return contatoInicial?.telefone;
  }, [contatoInicial]);
  const nomeContatoRepresentanteDefault = useMemo(() => {
    return (
      dadosEmpresa?.nomeContatoRepresentante ||
      dadosClienteEdicao?.nomeContatoRepresentante
    );
  }, [dadosEmpresa, dadosClienteEdicao]);
  const emailContatoRepresentanteDefault = useMemo(() => {
    return (
      dadosEmpresa?.emailContatoRepresentante ||
      dadosClienteEdicao?.emailContatoRepresentante
    );
  }, [dadosEmpresa, dadosClienteEdicao]);
  const telefoneContatoRepresentanteDefault = useMemo(() => {
    return dadosClienteEdicao?.telefoneRepresentante;
  }, [dadosClienteEdicao]);
  const nomeContatoRepresentanteDefault2 = useMemo(() => {
    return (
      dadosEmpresa?.nomeContatoRepresentante2 ||
      dadosClienteEdicao?.nomeContatoRepresentante2
    );
  }, [dadosEmpresa, dadosClienteEdicao]);
  const emailContatoRepresentanteDefault2 = useMemo(() => {
    return (
      dadosEmpresa?.emailContatoRepresentante2 ||
      dadosClienteEdicao?.emailContatoRepresentante2
    );
  }, [dadosEmpresa, dadosClienteEdicao]);
  const telefoneContatoRepresentanteDefault2 = useMemo(() => {
    return dadosClienteEdicao?.telefoneRepresentante2;
  }, [dadosClienteEdicao]);
  const camposAssistidos = getValues([
    "nomeContatoInicial",
    "emailContatoInicial",
    "telefone"
  ]);
  const camposAssistidosRepresentante = getValues([
    "nomeContatoRepresentante",
    "telefoneRepresentante",
    "emailContatoRepresentante"
  ]);
  const camposAssistidosRepresentante2 = getValues([
    "nomeContatoRepresentante2",
    "telefoneRepresentante2",
    "emailContatoRepresentante2"
  ]);
  const [carregandoUpload, setCarregandoUpload] = useState(0);
  const [arquivoFatura, setArquivoFatura] = useState();
  const [renderizaComponente, setRenderizaComponente] = useState(false);
  const [arquivosCotacao, setArquivosCotacao] = useState(false);
  const [listaRepresentantes, setListaRepresentantes] = useState([]);
  const [dadosRepresentante, setDadosRepresentante] = useState();
  const [dadosRepresentante2, setDadosRepresentante2] = useState();
  const [desabilitarContratar, setDesabilitarContratar] = useState(true);
  const [tela, setTela] = useState(1);

  const verificarSemPlanoSelecionado = useCallback(async () => {
    const cotacaoId =
      dadosEmpresa?.id ||
      dadosClienteEdicao?.id ||
      state?.dadosCotacaoInicial?.id;

    const verificar = await DadosEmpresaHelper.verificarSemPlanoSelecionado(
      cotacaoId
    );
    setDesabilitarContratar(verificar?.data);
  }, []);

  // Desabilitar botao contratar
  useEffect(() => {
    verificarSemPlanoSelecionado();
  }, [verificarSemPlanoSelecionado]);

  // Representante legal
  const onChangeSituacao = (valor) => {
    setEhRepresentante(valor);
  };

  const onClickNovoRepresentante = (event) => {
    event.preventDefault();

    if (
      !habilitaRepresentante &&
      camposAssistidos.nomeContatoInicial &&
      camposAssistidos.emailContatoInicial &&
      camposAssistidos.telefone &&
      camposAssistidos.telefone.length > 17
    ) {
      setHabilitaRepresentante(true);
    }

    if (
      habilitaRepresentante &&
      camposAssistidosRepresentante.nomeContatoRepresentante &&
      camposAssistidosRepresentante.emailContatoRepresentante &&
      camposAssistidosRepresentante.telefoneRepresentante &&
      camposAssistidosRepresentante.telefoneRepresentante.length > 17
    ) {
      setHabilitaSegundoRepresentante(true);
    }

    const _representanteTrigger = control?.trigger();
  };

  useEffect(() => {
    // Contato inicial
    if (nomeContatoDefault) setValue("nomeContatoInicial", nomeContatoDefault);
    if (emailContatoDefault)
      setValue("emailContatoInicial", emailContatoDefault);
    if (telefoneContatoDefault) setValue("telefone", telefoneContatoDefault);

    // Contato Representante
    if (nomeContatoRepresentanteDefault)
      setValue("nomeContatoRepresentante", nomeContatoRepresentanteDefault);
    if (emailContatoRepresentanteDefault)
      setValue("emailContatoRepresentante", emailContatoRepresentanteDefault);
    if (telefoneContatoRepresentanteDefault)
      setValue("telefoneRepresentante", telefoneContatoRepresentanteDefault);

    // Contato Representante 2
    if (nomeContatoRepresentanteDefault2)
      setValue("nomeContatoRepresentante2", nomeContatoRepresentanteDefault2);
    if (emailContatoRepresentanteDefault2)
      setValue("emailContatoRepresentante2", emailContatoRepresentanteDefault2);
    if (telefoneContatoRepresentanteDefault2)
      setValue("telefoneRepresentante2", telefoneContatoRepresentanteDefault2);
  }, [
    nomeContatoDefault,
    emailContatoDefault,
    telefoneContatoDefault,
    nomeContatoRepresentanteDefault,
    emailContatoRepresentanteDefault,
    telefoneContatoRepresentanteDefault,
    nomeContatoRepresentanteDefault2,
    emailContatoRepresentanteDefault2,
    telefoneContatoRepresentanteDefault2
  ]);

  useEffect(() => {
    dadosRepresentante && setHabilitaRepresentante(true);
    dadosRepresentante2 && setHabilitaSegundoRepresentante(true);
  }, [dadosRepresentante, dadosRepresentante2]);

  // UF
  const obterUF = async () => {
    try {
      setCarregandoUF(true);
      const lista = {
        data: unidadesFederativas,
        status: 200
      };
      if (lista?.status === 200 && lista?.data) {
        setListaUF(lista?.data);
      }
      setCarregandoUF(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoUF(false);
    }
  };

  const rotas = useSelector((state) => state.rotas);
  const { menu } = usuario;
  const permissaoContratarCotacao = usuarioPossuiFuncionalidade(
    menu,
    rotas.rotaAtual.caminho,
    RotasDTO.Cotacoes,
    InterfaceDTO.PermissaoContratarCotacao
  );

  const onChangeUF = (uf) => {
    setUFId(uf.target.value);
  };

  useEffect(() => {
    setUFId(state?.dadosCnpj?.uf ?? undefined);
    setDadosEmpresa(state?.dadosCnpj ?? { cnpj: dadosDoState?.cnpj } ?? {});
  }, [dadosDoState]);

  useEffect(() => {
    obterUF();
  }, []);

  // CEP
  const carregarCep = useCallback(async (cep) => {
    try {
      setCarregandoEndereco(true);
      const endereco = await buscaCep(cep);

      if (endereco?.status === 200) {
        setUFId(endereco.data.uf);
        setDadosEndereco({
          endereco: endereco.data.logradouro,
          bairro: endereco.data.bairro,
          cidade: endereco.data.cidade,
          cep: endereco.data.cep
        });
        setNumero(" ");
        setComplemento(" ");
      }

      setCarregandoEndereco(false);
    } catch (error) {
      setUFId(null);
      setDadosEndereco({
        endereco: "",
        bairro: "",
        cidade: ""
      });
      setNumero("");
      setComplemento("");
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Não foi possível localizar o CEP!"
        })
      );
      setCarregandoEndereco(false);
    }
  }, []);

  const onBlurCep = (valor) => {
    if (valor.length > 7 && valor !== dadosEmpresa?.cep) {
      carregarCep(valor);
    }
  };

  useEffect(() => {
    if (numero === " ") setNumero("");
    if (complemento === " ") setComplemento("");
  }, [numero, complemento]);

  // Arquivos Upload
  const onAddArquivoUpload = (lista) => {
    setArquivoFatura(lista);
  };

  const onDeleteArquivoUpload = (lista) => {
    setArquivoFatura((oldState) =>
      oldState.filter((item) => item.name !== lista.name)
    );
  };

  const onAddArquivoUploadEnergia = (lista) => {
    setArquivoFaturaEnergia(lista);
  };

  const onAddArquivoUploadContratoSocial = (lista) => {
    setArquivoFaturaContratoSocial(lista);
  };

  const onAddArquivoUploadProcuracao = (lista) => {
    setArquivoFaturaProcuracao(lista);
  };

  const onAddArquivoUploadCCER = (lista) => {
    setArquivoFaturaCCER(lista);
  };

  const onDeleteArquivoUploadEnergia = (lista) => {
    setArquivoFaturaEnergia(
      arquivoFaturaEnergia.filter((item) => item.name !== lista.name)
    );
  };

  const onDeleteArquivoUploadContratoSocial = (lista) => {
    setArquivoFaturaContratoSocial(
      arquivoFaturaContratoSocial.filter((item) => item.name !== lista.name)
    );
  };

  const onDeleteArquivoUploadProcuracao = (lista) => {
    setArquivoFaturaProcuracao(
      arquivoFaturaProcuracao.filter((item) => item.name !== lista.name)
    );
  };

  const onDeleteArquivoUploadCCER = (lista) => {
    setArquivoFaturaCCER(
      arquivoFaturaCCER.filter((item) => item.name !== lista.name)
    );
  };

  // RF
  const obterDadosEmpresaCNPJReceita = async (Cnpj) => {
    const resultado = await DadosEmpresaHelper.obterDadosEmpresaReceitaCNPJ(
      Cnpj
    );

    if (!resultado.sucesso) return;

    setDadosEmpresa(resultado.data);
    setUFId(resultado.data.uf);
  };

  // Recuperar dados
  const recuperarEnderecoCliente = useCallback(async () => {
    try {
      const CNPJ =
        dadosEmpresa?.cnpj ||
        dadosClienteEdicao?.cnpj ||
        state?.dadosCotacaoInicial?.cnpj;

      const cotacaoId =
        dadosEmpresa?.id ||
        dadosClienteEdicao?.id ||
        state?.dadosCotacaoInicial?.id;

      setCarregandoDados(true);

      if (!CNPJ || !cotacaoId) {
        return;
      }

      const buscaEnderecoPorCnpj = await DadosEmpresaHelper.obterDadosEmpresaCadastro(
        CNPJ,
        cotacaoId
      );

      if (buscaEnderecoPorCnpj.sucesso) {
        setDadosRepresentante(
          buscaEnderecoPorCnpj?.data?.representanteLegais &&
            buscaEnderecoPorCnpj?.data?.representanteLegais[0]
        );
        setDadosRepresentante2(
          buscaEnderecoPorCnpj?.data?.representanteLegais &&
            buscaEnderecoPorCnpj?.data?.representanteLegais[1]
        );
        setContatoInicial(buscaEnderecoPorCnpj?.data?.contatoInicial);
        setEhRepresentante(
          buscaEnderecoPorCnpj.data.contatoInicial?.representante
        );
        setListaRepresentantes(buscaEnderecoPorCnpj?.data?.representanteLegais);

        setBloquearRepresentatesContato(!buscaEnderecoPorCnpj.data.ehMatriz);

        if (buscaEnderecoPorCnpj.data.cnpj !== CNPJ) {
          obterDadosEmpresaCNPJReceita(CNPJ);
          setRenderizaComponente(true);
          setArquivosCotacao([]);
          setCadastro(true);
          return;
        }

        setDadosClienteEdicao(buscaEnderecoPorCnpj.data);
        setDadosEndereco({
          endereco: buscaEnderecoPorCnpj.data.endereco,
          bairro: buscaEnderecoPorCnpj.data.bairro,
          cidade: buscaEnderecoPorCnpj.data.cidade
        });
        setNumero(buscaEnderecoPorCnpj.data.numero);
        setComplemento(buscaEnderecoPorCnpj.data.complemento);
        setUFId(buscaEnderecoPorCnpj.data.uf);
        setDadosEmpresa([]);
      } else {
        obterDadosEmpresaCNPJReceita(CNPJ);
        setRenderizaComponente(true);
        setArquivosCotacao([]);
        setCadastro(true);
      }
    } catch (error) {
      setRenderizaComponente(true);
      setArquivosCotacao([]);
    } finally {
      setCarregandoDados(false);
    }
  }, []);

  useEffect(() => {
    recuperarEnderecoCliente();
    obterPlanosSimulados();
  }, [recuperarEnderecoCliente]);

  // Recuperar arquivos upload
  function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  const obterArquivosCotacao = useCallback(async () => {
    try {
      if (dadosCotacaoInicial?.id) {
        const lista = await listaArquivosCotacao(dadosCotacaoInicial?.id);
        if (lista?.data?.listaClienteArquivoFatura) {
          const listaArquivos = [];

          lista.data.listaClienteArquivoFatura.forEach((item) => {
            if (item?.arquivoBase64) {
              listaArquivos.push(
                new File(
                  [b64toBlob(item?.arquivoBase64), item?.mimeType],
                  item?.nomeArquivo,
                  { type: item?.mimeType }
                )
              );
            }
          });
          setArquivosCotacao(listaArquivos);
        } else {
          setArquivosCotacao([]);
        }
      }
    } catch (error) {
      setArquivosCotacao([]);
    } finally {
      setRenderizaComponente(true);
    }
  }, [dadosClienteEdicao]);

  const obterArquivosCotacaoEnergia = useCallback(async () => {
    try {
      if (dadosCotacaoInicial?.id) {
        const listaEnergia = await listaArquivosCotacao(
          dadosCotacaoInicial?.id
        );
        if (listaEnergia?.data?.listaClienteArquivoFatura) {
          const listaArquivosEnergia = [];

          listaEnergia.data.listaClienteArquivoFatura.forEach((item) => {
            if (item?.arquivoBase64) {
              listaArquivosEnergia.push(
                new File(
                  [b64toBlob(item?.arquivoBase64), item?.mimeType],
                  item?.nomeArquivo,
                  { type: item?.mimeType }
                )
              );
            }
          });

          setArquivosCotacaoEnergia(listaArquivosEnergia);
        } else {
          setArquivosCotacaoEnergia([]);
        }
      }
    } catch (error) {
      setArquivosCotacaoEnergia([]);
    } finally {
      setRenderComponenteEnergia(true);
    }
  }, [dadosClienteEdicao]);

  const obterArquivosCotacaoSocial = useCallback(async () => {
    try {
      if (dadosClienteEdicao?.id) {
        const listar = await listaArquivosCotacao(dadosCotacaoInicial?.id);
        if (listar?.data?.listaClienteArquivoFatura) {
          const listaArquivos = [];

          listar.data.listaClienteArquivoFatura.forEach((item) => {
            if (item?.arquivoBase64) {
              listaArquivos.push(
                new File(
                  [b64toBlob(item?.arquivoBase64), item?.mimeType],
                  item?.nomeArquivo,
                  { type: item?.mimeType }
                )
              );
            }
          });

          setArquivosCotacaoEnergia(listaArquivos);
        } else {
          setArquivosCotacaoEnergia([]);
        }
      }
    } catch (error) {
      setArquivosCotacaoContratoSocial([]);
    } finally {
      setRenderComponenteContratoSocial(true);
    }
  }, [dadosClienteEdicao]);

  const obterArquivosCotacaoProcuracao = useCallback(async () => {
    try {
      if (dadosCotacaoInicial?.id) {
        const listar = await listaArquivosCotacao(dadosCotacaoInicial?.id);
        if (listar?.data?.listaClienteArquivoFatura) {
          const listaArquivos = [];

          listar.data.listaClienteArquivoFatura.forEach((item) => {
            if (item?.arquivoBase64) {
              listaArquivos.push(
                new File(
                  [b64toBlob(item?.arquivoBase64), item?.mimeType],
                  item?.nomeArquivo,
                  { type: item?.mimeType }
                )
              );
            }
          });

          setArquivosCotacaoEnergia(listaArquivos);
        } else {
          setArquivosCotacaoEnergia([]);
        }
      }
    } catch (error) {
      setArquivosCotacaoProcuracao([]);
    } finally {
      setRenderComponenteProcuracao(true);
    }
  }, [dadosClienteEdicao]);

  const obterArquivosCotacaoCCER = useCallback(async () => {
    try {
      if (dadosCotacaoInicial?.id) {
        const listar = await listaArquivosCotacao(dadosCotacaoInicial?.id);
        if (listar?.data?.listaClienteArquivoFatura) {
          const listaArquivos = [];

          listar.data.listaClienteArquivoFatura.forEach((item) => {
            if (item?.arquivoBase64) {
              listaArquivos.push(
                new File(
                  [b64toBlob(item?.arquivoBase64), item?.mimeType],
                  item?.nomeArquivo,
                  { type: item?.mimeType }
                )
              );
            }
          });

          setArquivosCotacaoEnergia(listaArquivos);
        } else {
          setArquivosCotacaoEnergia([]);
        }
      }
    } catch (error) {
      setArquivosCotacaoCCER([]);
    } finally {
      setRenderComponenteCCER(true);
    }
  }, [dadosClienteEdicao]);

  useEffect(() => {
    obterArquivosCotacao();
    obterArquivosCotacaoEnergia();
    obterArquivosCotacaoSocial();
    obterArquivosCotacaoProcuracao();
    obterArquivosCotacaoCCER();
  }, [
    obterArquivosCotacao,
    obterArquivosCotacaoEnergia,
    obterArquivosCotacaoSocial,
    obterArquivosCotacaoProcuracao,
    obterArquivosCotacaoCCER
  ]);

  // Filtro lateral
  useEffect(() => {
    store.dispatch(desabilitarCliente(true));
    store.dispatch(desabilitarUnidade(true));

    return () => {
      store.dispatch(desabilitarEmpresa(false));
      store.dispatch(desabilitarCliente(false));
      store.dispatch(desabilitarUnidade(false));
    };
  }, []);

  const removerCaracteresEspeciais = (valor) => {
    if (valor) return valor.replace(/[^a-zA-Z0-9]/g, "");
  };

  const [validacao, setValidacao] = useState(false);
  const [enviarDados, setEnviarDados] = useState(false);
  // Enviar dados
  const enviarFormulario = async (dados, ehContratar) => {
    try {
      if (!usuario.empresaSelecionada) {
        DadosEmpresaHelper.exibirErro("É Obrigatório selecionar uma empresa");
        return;
      }

      const telefonetratado = removerCaracteresEspeciais(dados?.telefone);
      const telefoneRepresentante = removerCaracteresEspeciais(
        dados?.telefoneRepresentante
      );
      const telefoneRepresentante2 = removerCaracteresEspeciais(
        dados?.telefoneRepresentante2
      );
      const cpfTratado = removerCaracteresEspeciais(dados?.cpf);
      const cpfRepresentante = removerCaracteresEspeciais(
        dados?.cpfRepresentante
      );
      const cpfRepresentante2 = removerCaracteresEspeciais(
        dados?.cpfRepresentante2
      );
      const rgTratado = removerCaracteresEspeciais(dados?.rg);
      const rgRepresentante = removerCaracteresEspeciais(
        dados?.rgRepresentante
      );
      const rgRepresentante2 = removerCaracteresEspeciais(
        dados?.rgRepresentante2
      );

      const representantes = [];

      if (dados?.nomeContatoRepresentante)
        representantes.push({
          Id: dadosRepresentante?.id,
          NomeCompleto: dados?.nomeContatoRepresentante,
          Email: dados?.emailContatoRepresentante,
          CPF: cpfRepresentante,
          RG: rgRepresentante,
          Telefone: telefoneRepresentante
        });

      if (dados?.nomeContatoRepresentante2)
        representantes.push({
          Id: dadosRepresentante2?.id,
          NomeCompleto: dados?.nomeContatoRepresentante2,
          Email: dados?.emailContatoRepresentante2,
          CPF: cpfRepresentante2,
          RG: rgRepresentante2,
          Telefone: telefoneRepresentante2
        });

      setCarregandoDados(true);

      let ArvoreGerencialId = dadosClienteEdicao?.arvoreGerencialId;

      if (
        !dadosClienteEdicao ||
        dadosClienteEdicao.arvoreGerencialId === 0 ||
        dadosClienteEdicao.empresaGrupoDeltaId !== usuario.empresaSelecionada
      ) {
        const colaborador = await obterArvoreGerencialAltoNivel(
          state?.dadosCotacaoInicial?.colaboradorId ??
            dadosClienteEdicao?.colaboradorId ??
            usuario?.usuario?.colaboradorId,
          usuario.empresaSelecionada
        );

        if (colaborador?.data) ArvoreGerencialId = colaborador?.data?.id;
      }

      const salvarDadosDto = {
        CnpjCpf:
          removerCaracteres(dadosClienteEdicao?.cnpjCpf) ??
          removerCaracteres(dadosEmpresa?.cnpj ?? dados.cnpj),
        RazaoSocial:
          dadosClienteEdicao?.razaoSocial ??
          dadosEmpresa?.razaoSocial ??
          dados.razaoSocial,
        NomeFantasia:
          state?.dadosCotacaoInicial?.nomeFantasia ??
          dadosClienteEdicao?.nomeFantasia ??
          dadosEmpresa?.nomeFantasia ??
          dadosClienteEdicao?.razaoSocial ??
          dadosEmpresa?.razaoSocial ??
          dados.razaoSocial,
        ArvoreGerencialId,
        InscricaoEstadual: "",
        InscricaoMunicipal: "",
        Status: "PP",
        CotacaoId: state?.dadosCotacaoInicial?.id,
        Cep: dados?.cep,
        Endereco: dados?.endereco,
        Numero: dados?.numero,
        Complemento: dados?.complemento,
        UF: ufId ?? dadosClienteEdicao?.uf ?? dados?.uf,
        Bairro: dados?.bairro,
        Cidade: dados?.cidade,
        PessoaFisicaJuridica: "PJ",
        Situacao: "AT",
        DataSituacao: window.moment().format("YYYY-MM-DD"),
        Telefone: telefonetratado || contatoInicial?.telefone,
        ContatoInicial: {
          NomeCompleto:
            dados?.nomeContatoInicial || contatoInicial?.nomeCompleto,
          Email: dados?.emailContatoInicial || contatoInicial?.email,
          CPF: cpfTratado || contatoInicial?.cpf,
          RG: rgTratado || contatoInicial?.rg,
          Representante: ehRepresentante
        },
        Representantes: representantes ?? representantes,
        ColaboradorId:
          state?.dadosCotacaoInicial?.colaboradorId ??
          dadosClienteEdicao?.colaboradorId ??
          usuario?.usuario?.colaboradorId,
        ConsumidorLivre:
          dadosClienteEdicao?.consumidorLivre ||
          state?.dadosCotacaoInicial?.consumidorLivre ||
          false,
        AgenteCCEEId: 0,
        EmpresaGrupoDeltaId: usuario.empresaSelecionada,
        CriarUsuario: ehContratar
      };

      const { nomeContatoInicial, emailContatoInicial, telefone } = dados;
      if (
        nomeContatoInicial !== "" &&
        telefone !== "" &&
        emailContatoInicial !== "" &&
        enviarDados
      ) {
        setValidacao(false);
        const resultado = await DadosEmpresaHelper.salvarDadosEmpresaCadastro(
          salvarDadosDto
        );
        if (resultado.sucesso) {
          store.dispatch(
            alertaExibir({
              tipo: "success",
              mensagem: "Dados da empresa salvos com sucesso!"
            })
          );

          if (ehContratar) {
            const resultadoContratar = await DadosEmpresaHelper.contratarCotacao(
              resultado.data.cotacaoId
            );

            if (!resultadoContratar.sucesso) {
              DadosEmpresaHelper.exibirErro(resultadoContratar.mensagem);
              return;
            }
          }

          if (arquivoFatura) {
            setCarregandoUpload(0);

            try {
              const uploadComSucesso = await uploadFatura(
                dadosClienteEdicao?.id || resultado?.data?.clienteId,
                dadosCotacaoInicial?.id,
                arquivoFatura
              );

              if (uploadComSucesso) setCarregandoUpload(100);
            } catch (error) {
              store.dispatch(
                alertaExibir({
                  tipo: "warning",
                  mensagem:
                    error?.response?.data?.message ??
                    "Não foi possível enviar a fatura!"
                })
              );
              setCarregandoUpload(0);
            }
          }

          if (cadastro && ehContratar) {
            setModalInfo(true);
          } else {
            setRedireciona(true);
          }

          setCarregandoDados(false);
          setCadastro(false);
        }
      } else {
        setValidacao(false);
        setTela(2);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    } finally {
      setCarregandoDados(false);
    }
  };

  const aoEnviarFormulario = (dados, event) => {
    const ehContratar =
      event?.nativeEvent?.submitter?.id === "1" && permissaoContratarCotacao;
    enviarFormulario(dados, ehContratar);
  };

  const onClickDownload = async (nomeArquivo) => {
    const retorno = await baixarArquivoFatura(
      dadosCotacaoInicial?.id,
      nomeArquivo
    );
    if (!retorno) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            "Download indisponível. Salve a cotação para realizar o download do arquivo!"
        })
      );
    }
  };

  const onPreviewChipClick = (nomeArquivo) => {
    onClickDownload(nomeArquivo);
  };

  const obterPlanosSimulados = useCallback(async () => {
    setCarregandoDados(true);

    const resultado = await PlanosSimuladosHelper.ObterPlanosSimulados(
      dadosCotacaoInicial?.id
    );

    if (resultado.sucesso) setCotacaoFechada(resultado.data.fechada);
    setListaPlanos(resultado.data.planos);
    setDadosPlanos(resultado.data.planos);
    setCarregandoDados(false);
  }, [cotacaoFechada]);

  const nav = () => {
    tela === 1 ? history.goBack() : setTela(tela - 1);
  };

  const getCotacaoDetalhes = async () => {
    try {
      const { data, status } = await dadosPlanoEscolhido(
        state?.dadosCotacaoInicial?.id
      );
      if (status === 200) {
        setInfoPlanoSelecionado(data);
      }
    } catch (error) {}
  };

  const icones = {
    "delta/bronze": IconeBronzeDelta,
    "delta/prata": IconePrataDelta,
    "delta/ouro": IconeOuroDelta,

    "luz/bronze": IconeBronzeLuz,
    "luz/prata": IconePrataLuz,
    "luz/ouro": IconeOuroLuz,

    "thymos/bronze": IconeBronzeThymos,
    "thymos/prata": IconePrataThymos,
    "thymos/ouro": IconeOuroThymos
  };

  const iconeNome = `${dominio}/${infoPlanoSelecionado?.descricao?.toLowerCase()}`;

  const Icone = () => {
    const height = 52;
    const width = 54;

    return (
      <img src={icones[iconeNome]} height={height} width={width} alt="icones" />
    );
  };

  const planoConfiguracao = useMemo(() => {
    if (!infoPlanoSelecionado || infoPlanoSelecionado.length === 0) return [];
    return PlanosConstante.filter((plano) => {
      return [infoPlanoSelecionado]?.some(
        (dado) => dado.tipoPlano === plano.tipoPlano
      );
    });
  }, [infoPlanoSelecionado, PlanosConstante]);

  return (
    <>
      <Box className={classes.barraSuperior}>
        <IconButton
          className={classes.arrowBack}
          onClick={() => {
            nav();
            setEnviarDados(false);
          }}
          size="small"
        >
          <ArrowBackIosIcon fontSize="small" />
        </IconButton>
        {tela === 1 ? "Dados Empresa" : "Dados do Contato"}
      </Box>

      <Loader loading={carregandoDados}>
        <ModalInformacao
          exibir={modalInfo}
          mensagem="E-mail de acesso enviado para o contato cadastrado, verifique a caixa de entrada e lixo eletrônico."
          onClose={() => {
            setModalInfo(false);
            setRedireciona(true);
          }}
          showBotaoLink
          showBotao={false}
        />

        <FormValidation
          onSubmit={aoEnviarFormulario}
          handleSubmit={handleSubmit}
        >
          <Card className={classes.fundoPreto}>
            <Grid container spacing={2} className={classes.container}>
              <>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  className={
                    tela === 1 ? classes.displayBlock : classes.displayNone
                  }
                >
                  <MaterialInputMascara
                    className={classes.inputBranco}
                    type="text"
                    id="cnpj"
                    name="cnpj"
                    label="CNPJ"
                    mask="##.###.###/####-##"
                    limparValor={limparCnpj}
                    redefineLimpar={(estado) => setLimparCnpj(estado)}
                    renderIconShowHide={false}
                    defaultValue={
                      dadosClienteEdicao?.cnpjCpf ??
                      dadosEmpresa?.cnpj ??
                      state?.dadosCotacaoInicial?.cnpj
                    }
                    disabled={Boolean(
                      dadosClienteEdicao?.cnpjCpf || dadosEmpresa?.cnpj
                    )}
                    ref={register({
                      required: "Campo CNPJ é obrigatório!",
                      maxLength: {
                        value: 18,
                        message: "Quantidade máxima de 18 caracteres!"
                      },
                      minLength: {
                        value: 18,
                        message: "Quantidade mínima de 18 caracteres!"
                      },
                      validate: (value) =>
                        cnpj.isValid(value) || "CNPJ inválido!"
                    })}
                    errors={errors}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={9}
                  className={
                    tela === 1 ? classes.displayBlock : classes.displayNone
                  }
                >
                  <Loader loading={carregandoEndereco}>
                    <MaterialInputMascara
                      className={classes.inputBranco}
                      type="text"
                      id="cep"
                      name="cep"
                      label="CEP"
                      mask="#####-###"
                      renderIconShowHide={false}
                      defaultValue={
                        removerCaracteres(dadosClienteEdicao?.cep) ||
                        (removerCaracteres(dadosEndereco?.cep) ??
                          removerCaracteres(dadosEmpresa?.cep))
                      }
                      maxLength={9}
                      onBlur={(event) => onBlurCep(event.target.value)}
                      ref={register({
                        required: "O campo CEP é obrigatório!",
                        minLength: {
                          value: 9,
                          message: "Quantidade minima de 9 caracteres!"
                        },
                        maxLength: {
                          value: 9,
                          message: "Quantidade máxima de 9 caracteres!"
                        },
                        validate: (value) =>
                          value.replace(/\s/g, "").length === 9 ||
                          "Deve ser informado 8 digitos"
                      })}
                      errors={errors}
                    />
                  </Loader>
                </Grid>

                <Grid
                  item
                  xs={12}
                  className={
                    tela === 1 ? classes.displayBlock : classes.displayNone
                  }
                >
                  <MaterialInputTexto
                    type="text"
                    id="razaoSocial"
                    name="razaoSocial"
                    label="Razão social"
                    className={classes.inputBranco}
                    renderIconShowHide={false}
                    defaultValue={
                      dadosClienteEdicao?.nomeFantasia ??
                      dadosEmpresa?.razaoSocial ??
                      state?.dadosCotacaoInicial?.nomeFantasia
                    }
                    disabled={
                      dadosClienteEdicao?.nomeFantasia ??
                      dadosEmpresa?.razaoSocial
                    }
                    ref={register({
                      required: "Campo Razão Social é obrigatório!",
                      maxLength: {
                        value: 100,
                        message: "Quantidade máxima de 100 caracteres!"
                      }
                    })}
                    errors={errors}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  className={
                    tela === 1 ? classes.displayBlock : classes.displayNone
                  }
                >
                  <Loader loading={carregandoEndereco}>
                    <MaterialInputTexto
                      type="text"
                      id="endereco"
                      name="endereco"
                      label="Endereço"
                      permiteValorBranco
                      renderIconShowHide={false}
                      className={classes.inputBranco}
                      defaultValue={
                        dadosEmpresa?.descricaoDoLogradouro &&
                        dadosEmpresa?.logradouro
                          ? `${dadosEmpresa?.descricaoDoLogradouro} ${dadosEmpresa?.logradouro}`
                          : dadosEndereco?.endereco
                      }
                      ref={register({
                        required: "Campo Endereço é obrigatório!",
                        maxLength: {
                          value: 200,
                          message: "Quantidade máxima de 200 caracteres!"
                        }
                      })}
                      errors={errors}
                    />
                  </Loader>
                </Grid>

                <Grid
                  item
                  xs={6}
                  className={
                    tela === 1 ? classes.displayBlock : classes.displayNone
                  }
                >
                  <Loader loading={carregandoEndereco}>
                    <MaterialInputTexto
                      className={classes.inputBranco}
                      type="text"
                      id="numero"
                      name="numero"
                      label="Número"
                      permiteValorBranco
                      renderIconShowHide={false}
                      defaultValue={numero ?? dadosEmpresa?.numero}
                      ref={register({
                        required: "Campo Número é obrigatório!",
                        maxLength: {
                          value: 10,
                          message: "Quantidade máxima de 10 caracteres!"
                        }
                      })}
                      errors={errors}
                    />
                  </Loader>
                </Grid>

                <Grid
                  item
                  xs={6}
                  className={
                    tela === 1 ? classes.displayBlock : classes.displayNone
                  }
                >
                  <Loader loading={carregandoEndereco}>
                    <MaterialInputTexto
                      className={classes.inputBranco}
                      type="text"
                      id="bairro"
                      name="bairro"
                      label="Bairro"
                      permiteValorBranco
                      renderIconShowHide={false}
                      defaultValue={
                        dadosEndereco?.bairro ?? dadosEmpresa?.bairro
                      }
                      ref={register({
                        required: "Campo Bairro é obrigatório!",
                        maxLength: {
                          value: 100,
                          message: "Quantidade máxima de 100 caracteres!"
                        }
                      })}
                      errors={errors}
                    />
                  </Loader>
                </Grid>

                <Grid
                  item
                  xs={6}
                  className={
                    tela === 1 ? classes.displayBlock : classes.displayNone
                  }
                >
                  <Loader loading={carregandoEndereco}>
                    <MaterialInputTexto
                      className={classes.inputBranco}
                      type="text"
                      id="cidade"
                      name="cidade"
                      label="Cidade"
                      permiteValorBranco
                      renderIconShowHide={false}
                      defaultValue={
                        dadosEndereco?.cidade ?? dadosEmpresa?.municipio
                      }
                      ref={register({
                        required: "Campo Cidade é obrigatório!",
                        maxLength: {
                          value: 100,
                          message: "Quantidade máxima de 100 caracteres!"
                        }
                      })}
                      errors={errors}
                    />
                  </Loader>
                </Grid>

                <Grid
                  item
                  xs={6}
                  className={
                    tela === 1 ? classes.displayBlock : classes.displayNone
                  }
                >
                  <Loader loading={carregandoUF || carregandoEndereco}>
                    <SelectArredondado
                      className={classes.inputBranco}
                      id="uf"
                      name="uf"
                      valueKey="UF"
                      valueName="UF"
                      dataSource={listaUF}
                      label="UF"
                      value={ufId ?? dadosEmpresa?.uf}
                      onChange={(estado) => onChangeUF(estado)}
                      placeholder="UF"
                      allowClear
                      ref={register(
                        { name: "uf" },
                        {
                          required:
                            !ufId && !dadosClienteEdicao?.uf
                              ? "Campo UF é obrigatório!"
                              : false
                        }
                      )}
                      errors={errors}
                    />
                  </Loader>
                </Grid>

                <Grid
                  item
                  xs={12}
                  className={
                    tela === 2 ? classes.displayBlock : classes.displayNone
                  }
                >
                  <MaterialInputTexto
                    className={classes.inputBranco}
                    type="text"
                    id="nomeContatoInicial"
                    name="nomeContatoInicial"
                    label="Nome completo"
                    defaultValue={camposAssistidos?.nomeContatoInicial ?? ""}
                    permiteValorBranco
                    renderIconShowHide={false}
                    disabled={bloquearRepresentantesContato}
                    ref={register({
                      required: validacao
                        ? false
                        : "Campo Nome Completo é obrigatório!",
                      maxLength: {
                        value: 100,
                        message: "Quantidade máxima de 100 caracteres!"
                      }
                    })}
                    errors={errors}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  className={
                    tela === 2 ? classes.displayBlock : classes.displayNone
                  }
                >
                  <MaterialInputTexto
                    className={classes.inputBranco}
                    type="text"
                    id="emailContatoInicial"
                    name="emailContatoInicial"
                    label="E-mail"
                    renderIconShowHide={false}
                    defaultValue={camposAssistidos?.emailContatoInicial ?? ""}
                    disabled={bloquearRepresentantesContato}
                    permiteValorBranco
                    ref={register({
                      required: validacao
                        ? false
                        : "O campo E-mail é obrigatório!",
                      pattern: {
                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "Campo E-mail inválido!"
                      },
                      maxLength: {
                        value: 100,
                        message: "Quantidade máxima de 100 caracteres!"
                      }
                    })}
                    errors={errors}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  className={
                    tela === 2 ? classes.displayBlock : classes.displayNone
                  }
                >
                  <MaterialInputTelefone
                    className={classes.inputBranco}
                    type="text"
                    id="telefone"
                    name="telefone"
                    label="Telefone"
                    renderIconShowHide={false}
                    defaultValue={camposAssistidos.telefone ?? ""}
                    disabled={bloquearRepresentantesContato}
                    ref={register({
                      required: validacao
                        ? false
                        : "O campo Telefone é obrigatório!",
                      minLength: {
                        value: 18,
                        message: "Quantidade mínima de 12 caracteres!"
                      }
                    })}
                    errors={errors}
                  />

                  <Card className={classes.marginPlanos}>
                    {infoPlanoSelecionado?.percentualDesconto > 0 ? (
                      <Grid container>
                        <Box
                          component={Grid}
                          item
                          sx={{
                            background: planoConfiguracao[0]?.corCard,
                            opacity: planoConfiguracao[0]?.opacity
                          }}
                          xl={2}
                          lg={4}
                          md={4}
                          sm={3}
                          xs={3}
                          className={classes.containerColorido}
                        >
                          {iconeNome ? <Icone /> : null}
                        </Box>
                        <Grid item xl={10} lg={8} md={8} sm={9} xs={9}>
                          <Grid
                            container
                            className={classes.containerPaddingInterno}
                          >
                            <Grid item xs={12}>
                              <span className={classes.porcentagemReducao}>
                                {infoPlanoSelecionado?.percentualDesconto} %
                              </span>
                            </Grid>
                            <Grid item xs={12}>
                              <span className={classes.percentualDesconto}>
                                Redução de custos
                              </span>
                            </Grid>
                            <Grid item xs={12}>
                              <span className={classes.periodoMeses}>
                                Período: {infoPlanoSelecionado?.periodoMeses}{" "}
                                meses
                              </span>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : null}
                  </Card>

                  <Grid
                    container
                    className={
                      tela === 2 ? classes.displayDefault : classes.displayNone
                    }
                  >
                    {permissaoContratarCotacao ? (
                      <Grid item lg={6} md={6} sm={6} xs={6}>
                        <Botao
                          type="submit"
                          label="Contratar"
                          id={1}
                          color={Base.Mango}
                          className={classes.button}
                          disabled={desabilitarContratar || cotacaoFechada}
                          onClick={() => setEnviarDados(true)}
                        />
                      </Grid>
                    ) : null}
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <BotaoOutLine
                        type="submit"
                        id={2}
                        label="Salvar"
                        className={classes.botaoSalvar}
                        onClick={() => setEnviarDados(true)}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  className={
                    tela === 1 ? classes.displayBlock : classes.displayNone
                  }
                >
                  <Botao
                    type="submit"
                    label="Dados do contato"
                    color={Base.Mango}
                    className={classes.button}
                    onClick={() => {
                      setValidacao(true);
                      getCotacaoDetalhes();
                      setEnviarDados(false);
                    }}
                  />
                </Grid>
              </>
            </Grid>
          </Card>

          {redireciona ? (
            <Redirect
              to={
                usuario?.usuario?.role === "Vendedor"
                  ? RotasDTO.Home
                  : RotasDTO.Cotacoes
              }
            />
          ) : null}
        </FormValidation>
      </Loader>
    </>
  );
};

export default DadosEmpresaMobile;
