import React, { useEffect } from "react";
import PropTypes from "prop-types";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import { withStyles } from "@mui/styles";
import { AutoCompleteCustom, useStyles } from "./style";

const SelectAutocompleteCheckbox = React.forwardRef((props, ref) => {
  const {
    id,
    label,
    options,
    getOptionLabel,
    onChange,
    onInputChange,
    onChangeSelect,
    value,
    disabled,
    defaultValue,
    error,
    selecionarTodos
  } = props;

  const classes = useStyles();

  const opcaoTodos = {
    id: -1,
    nome: "Todos"
  };

  // Icones
  const icon = (
    <CheckBoxOutlineBlankIcon
      fontSize="small"
      classes={{ root: classes.box }}
    />
  );
  const checkedIcon = (
    <CheckBoxIcon fontSize="small" classes={{ root: classes.checked }} />
  );

  useEffect(() => {
    if (options.length > 1 && selecionarTodos) {
      options.unshift(opcaoTodos);
    }
  }, [options]);

  return (
    <>
      <Autocomplete
        multiple
        disableCloseOnSelect
        limitTags={1}
        id={id}
        options={options}
        getOptionLabel={getOptionLabel}
        onInputChange={onInputChange}
        disabled={disabled}
        onChange={onChange}
        value={value}
        defaultValue={defaultValue}
        classes={classes.select}
        renderOption={(params, option, { selected }) => (
          <div {...params} key={option.id}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.nome}
          </div>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={onChangeSelect}
            label={label}
            variant="outlined"
            error={Boolean(error[id])}
            inputRef={ref}
          />
        )}
      />
      {error[id] ? (
        <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">
          {`Campo ${label} é obrigatório!`}
        </p>
      ) : null}
    </>
  );
});

SelectAutocompleteCheckbox.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  getOptionLabel: PropTypes.oneOfType([PropTypes.any]),
  value: PropTypes.oneOfType([PropTypes.any]),
  onInputChange: PropTypes.oneOfType([PropTypes.func]),
  onChange: PropTypes.oneOfType([PropTypes.func]),
  onChangeSelect: PropTypes.oneOfType([PropTypes.func]),
  label: PropTypes.string,
  maxHeight: PropTypes.string,
  maxWidth: PropTypes.string,
  marginBottom: PropTypes.string,
  inputValue: PropTypes.string,
  options: PropTypes.oneOfType([PropTypes.any]),
  error: PropTypes.oneOfType([PropTypes.any]),
  defaultValue: PropTypes.oneOfType([PropTypes.any]),
  disabled: PropTypes.bool,
  selecionarTodos: PropTypes.bool
};

SelectAutocompleteCheckbox.defaultProps = {
  id: "",
  getOptionLabel: "",
  value: "",
  onChange: () => {},
  onInputChange: () => {},
  onChangeSelect: () => {},
  label: "",
  maxHeight: "auto",
  maxWidth: "auto",
  marginBottom: "10px",
  inputValue: "",
  options: "",
  error: false,
  defaultValue: null,
  disabled: false,
  selecionarTodos: true
};

export default withStyles(AutoCompleteCustom)(SelectAutocompleteCheckbox);
