export default class FiltroDto {
  constructor(
    tamanhoPaginacao,
    pagina,
    searchString,
    ordernar,
    situacao,
    pagamento,
    status,
    unidadeSelecionada,
    clienteSelecionado,
    empresaSelecionada
  ) {
    this.PageSize = tamanhoPaginacao;
    this.Page = pagina;
    this.BoletaGdId = searchString;
    this.SortOrder = ordernar;
    this.Situacao = situacao;
    this.Pagamento = pagamento;
    this.Status = status;
    this.unidadeId = unidadeSelecionada;
    this.ClienteId = clienteSelecionado;
    this.EmpresaId = empresaSelecionada;
  }
}
