import React from "react";
import PropTypes from "prop-types";
import { Box, Grid } from "@mui/material";
import { Base } from "../cores";

const CardConsumoEconomia = ({ xs, md, icon, title, content, dados }) => {
  return (
    <Grid item xs={xs} md={md}>
      <Box bgcolor={Base.CardBgColor} borderRadius="4px" px={2} py={3}>
        <Box
          component="img"
          src={icon}
          alt="img-consumo-hora"
          pb={3}
          sx={{ filter: dados ? "" : "opacity(0.5)" }}
        />
        <Box
          component="div"
          fontSize="16px"
          fontWeight="700"
          color={dados ? Base.PrimaryLuzColor : Base.CardBgColorOpacity}
        >
          {title}
        </Box>
        <Box
          component="div"
          fontSize="24px"
          fontWeight="700"
          color={dados ? Base.PrimaryLuzColor : Base.CardBgColorOpacity}
        >
          {dados ? content : "--"}
        </Box>
      </Box>
    </Grid>
  );
};

CardConsumoEconomia.propTypes = {
  xs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  md: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  dados: PropTypes.bool
};

CardConsumoEconomia.defaultProps = {
  xs: 0,
  md: 0,
  icon: "",
  title: "",
  content: "",
  dados: false
};

export default CardConsumoEconomia;
