export const options = (categorias) => ({
  chart: {
    type: "bar",
    height: "auto",
    zoom: {
      enabled: true
    },
    toolbar: {
      show: false
    }
  },
  grid: {
    show: false
  },
  colors: ["#332053", "rgba(253, 149, 7, 1)"],
  plotOptions: {
    bar: {
      dataLabels: {
        position: "start"
      }
    }
  },
  dataLabels: {
    enabled: true,
    offsetY: 50,
    style: {
      fontSize: "14px",
      fontWeight: "bold",
      colors: ["#000000"]
    },
    formatter(value) {
      return ` ${value} k`;
    }
  },
  legend: {
    show: true,
    fontSize: "14px",
    fontWeight: 700,
    labels: {
      colors: ["#332053"],
      useSeriesColors: false
    },
    markers: {
      radius: 12,
      fillColors: [
        "#332053",
        "linear-gradient(357.04deg, rgba(247, 34, 181, 1) 2.11%, rgba(253, 149, 7, 1) 75.57%)"
      ]
    }
  },
  fill: {
    type: ["gradient", "gradient"],
    gradient: {
      gradientToColors: ["#332053", "#F722B5"],
      type: "vertical",
      shadeIntensity: 0,
      inverseColors: false,
      opacityFrom: 1,
      opacityTo: 1,
      stops: [20, 80, 100, 100]
    }
  },
  xaxis: {
    show: false,
    categories: categorias
  },
  yaxis: {
    show: false,
    axisTicks: {
      show: false
    },
    labels: {
      show: true,
      minWidth: 0,
      maxWidth: 160,
      offsetX: 0,
      offsetY: 0,
      rotate: 0,
      style: {
        colors: "#4F4F4F",
        fontWeight: "700",
        fontSize: "12px"
      }
    }
  }
});
