export default class AlteracoesMedicoesDto {
  constructor(
    id,
    mes,
    ano,
    medidor,
    consumoTotal,
    consumoTotalApto,
    diferencaConsumoTotal,
    dataAlteracao,
    dataApto,
    unidadeNome
  ) {
    this.id = id;
    this.mes = mes;
    this.ano = ano;
    this.medidor = medidor;
    this.consumoTotal = consumoTotal;
    this.consumoTotalApto = consumoTotalApto;
    this.diferencaConsumoTotal = diferencaConsumoTotal;
    this.dataAlteracao = dataAlteracao;
    this.dataApto = dataApto;
    this.unidadeNome = unidadeNome;
  }
}
