import { makeStyles } from "@mui/styles";
import { Base } from "componentes/cores";
import theme from "themes";

export const useStyles = makeStyles(() => ({
  container: {
    "& hr": {
      borderTop: `1px solid ${theme.color.secondary}`,
      padding: "5px 20px"
    }
  },
  card: {
    height: "100%",
    border: `1px solid  ${Base.OrangeBlaze} !important`,
    borderRadius: "34px !important",
    padding: "16px !important",
    textAlign: "center",
    "& h1": {
      color: theme.color.primary,
      fontWeight: 700,
      fontSize: "54px !important",
      margin: 0
    },
    "& h2": {
      color: theme.color.primary,
      fontWeight: 700,
      fontSize: "42px",
      margin: 0,
      "& span": {
        fontSize: "20px"
      }
    },
    "& h3": {
      color: theme.color.primary,
      fontWeight: 700,
      fontSize: "36px",
      margin: 0
    },
    "& h4": {
      color: `${theme.color.secondary} !important`,
      fontWeight: 700,
      fontSize: "32px",
      margin: 0
    },
    "& p": {
      color: theme.color.primary,
      fontSize: "16px",
      margin: 0,
      fontWeight: 700
    }
  },
  th: {
    padding: "5px 10px",
    color: "rgba(253, 149, 7, 1)",
    textAlign: "center",
    fontWeight: 700,
    fontSize: "14px"
  },
  td: {
    padding: "5px 10px",
    color: theme.color.primary,
    textAlign: "center",
    fontWeight: 700,
    fontSize: "14px"
  },
  trFooter: {
    backgroundColor: theme.color.secondary,
    color: Base.White,
    "& td": {
      padding: "5px 10px",
      fontWeight: 700,
      textAlign: "center",
      fontSize: "14px"
    }
  }
}));
