import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import CloseIcon from "@mui/icons-material/Close";

import { Grid } from "@mui/material";
import { store } from "../../global/redux";
import ModalConfirmacao from "../modalConfirmacao";

import { useStyles } from "./style";

import {
  selecionarCliente,
  selecionarEmpresa,
  selecionarUnidade
} from "../../global/redux/modulos/usuario/actions";

// eslint-disable-next-line react/prop-types
const BotaoRetornarListagem = ({ urlListagem, voltarPagina, zerarCombo }) => {
  const history = useHistory();
  const classes = useStyles();

  const [exibirModal, setExibirModal] = useState(false);

  const retonarParaListagem = () => {
    if (voltarPagina) {
      history.goBack();
    } else {
      history.push(urlListagem);
      if (zerarCombo) {
        store.dispatch(selecionarEmpresa());
        store.dispatch(selecionarCliente());
        store.dispatch(selecionarUnidade());
      }
    }
  };

  return (
    <Grid item xs={6} className={classes.item}>
      <ModalConfirmacao
        item={exibirModal}
        onConfirmar={() => retonarParaListagem()}
        mensagem="Deseja voltar para a lista de cadastros?"
        onCancelar={() => setExibirModal(false)}
      />
      <CloseIcon
        className={classes.icon}
        onClick={() => retonarParaListagem()}
      />
    </Grid>
  );
};

BotaoRetornarListagem.propTypes = {
  urlListagem: PropTypes.oneOfType([PropTypes.any])
};

BotaoRetornarListagem.defaultProps = {
  urlListagem: "/"
};

export default BotaoRetornarListagem;
