import { makeStyles } from "@mui/styles";
import { Base } from "../cores";

export const useStyles = makeStyles({
  inputTelefone: {
    maxWidth: "68%",
    width: "100%",
    "&.Mui-error": {
      borderColor: Base.RedError,
      color: Base.RedError
    }
  },
  containerError: {
    "&.react-tel-input .form-control": {
      border: "1px solid rgb(244, 67, 54)"
    },
    "&.react-tel-input .form-control:focus": {
      borderColor: "rgb(244, 67, 54)",
      boxShadow: "0 0 0 1px rgb(244, 67, 54)"
    },
    "&.special-label": {
      color: "rgb(244, 67, 54)"
    }
  }
});
