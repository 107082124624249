import { obterDemanda } from "servicos/workflowServico";
import RetornoEndpointDto from "../../../global/dto/retornoEndpointDto";
import { store } from "../../../global/redux";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";
import {
  obterDadosParaTermoAdesao,
  obterProtocoloDemandaPorFiltroLateral
} from "../../../servicos/demandaAtendServico";

export default class OnBoardingHelper {
  static async ObterDadosDemanda(protocoloDemanda) {
    try {
      const response = await obterDemanda(protocoloDemanda);

      return RetornoEndpointDto.Sucesso(
        "Dados da demanda obtidos com sucesso",
        response.data
      );
    } catch (ex) {
      return RetornoEndpointDto.Erro(
        ex?.response?.data?.message ?? "Não foi possivel consultar a demanda!"
      );
    }
  }

  static async ObterDadosParaTermoAdesao(protocoloDemanda) {
    try {
      const resultado = await obterDadosParaTermoAdesao(protocoloDemanda);

      if (!resultado?.data?.validationResult?.isValid) {
        return RetornoEndpointDto.Erro("Dados incompletos para gerar o termo");
      }

      return RetornoEndpointDto.Sucesso(
        "Dados para termo obtido com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu um erro ao obter os dados do termo"
      );
    }
  }

  static async obterProtocoloDemanda(empresa, cliente, unidade, setSemDemanda) {
    try {
      const resposta = await obterProtocoloDemandaPorFiltroLateral(
        empresa,
        cliente,
        unidade
      );

      if (String(resposta.status) === String(204)) {
        setSemDemanda(true);
        return RetornoEndpointDto.Sucesso(
          "Nenhuma demanda para a unidade selecionada"
        );
      }

      setSemDemanda(false);
      return RetornoEndpointDto.Sucesso(
        "Protocolo demanda obtido com sucesso",
        resposta.data
      );
    } catch (error) {
      setSemDemanda(true);
      return RetornoEndpointDto.Erro(
        "Ocorreu um erro ao obter o protocolo demanda"
      );
    }
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }

  static exibirAlerta(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "warning",
        mensagem
      })
    );
  }

  static exibirSucesso(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem
      })
    );
  }
}
