/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from "react";
import { Grid, FormControl, Typography, recomposeColor } from "@mui/material";
import Loader from "componentes/loader";
import DataTable from "react-data-table-component";
import Coluna from "componentes/tabelaPaginada/colunas/coluna";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { useStyles, customStyles } from "./style";
import { useHistory, useParams } from "react-router-dom";
import { getDetalhesTarifa } from "submodules/DeltaTarifa/servicos/projecaoTarifaria";
import MaterialInputTexto from "componentes/inputTexto/materialInput";
import BotaoRetornarListagem from "componentes/botaoRetornarListagem";
import RotasDTO  from "../../../../rotas/rotasUrlDto";
import { Botao } from "componentes";
import moment from "moment";
import { Base } from "componentes/cores";
import "./style.css"
import { red } from "@mui/material/colors";

const DetalheTarifa = () => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);

    const { id, etapa } = useParams();
    const history = useHistory();

    const [tituloProjecao, setTituloProjecao] = useState("")
    const [cenario, setCenario] = useState("")
    const [dataProjecao, setDataProjecao] = useState("")
    const [distribuidora, setDistribuidora] = useState("")
    const [msgAguarde, setMsgAguarde] = useState(<Typography 
    style={{color: Base.OffWhite, paddingTop:"100px", paddingLeft:"42%"}}>Aguarde a carga dos dados...</Typography>)

    const [detalhesTabela, setDetalhesTabela] = useState([]);

    const colunasDetalhes = [
        new Coluna("ano", "Ano", false),
        new Coluna("subgrupo", "SubGrupo", false),
        {...new Coluna("tM_TUSD_kW", "TUSD Média (R$/kW)", false), wrap: true},
        {...new Coluna("tM_TUSD_MWh", "TUSD Média (R$/MWh)", false), wrap: true},
        {...new Coluna("tM_TE_MWh", "TE Média (R$/MWh)", false), wrap: true},
        {...new Coluna("tM_MWh", "Tarifa Média (R$/MWh)", false), wrap: true},
        {...new Coluna("eC_TUSD", "Efeito Consumidor TUSD", false), wrap: true},
        {...new Coluna("eC_TE", "Efeito Consumidor TE", false), wrap: true},
        {...new Coluna("eC_Total", "Efeito Consumidor Total", false), wrap: true}
    ];

    const listarDetalhes = async () => {
        try {
            setIsLoading(true);
            await getDetalhesTarifa(id).then((response) => {

                if (response.status === 200 && Object.keys(response.data).length > 0) {
                    setTituloProjecao(response.data.projecao);
                    setCenario(response.data.cenario);
                    setDataProjecao(moment(response.data.dataProjecao).format("L"));
                    setDistribuidora(response.data.distribuidora)

                    setDetalhesTabela(response.data.tarifas.map((item) => {
                        return {
                            ...item,
                            tM_TUSD_kW: item.tM_TUSD_kW.toLocaleString("pt-BR", { minimumFractionDigits: 6, maximumFractionDigits: 6 }),
                            tM_TUSD_MWh: item.tM_TUSD_MWh.toLocaleString("pt-BR", { minimumFractionDigits: 6, maximumFractionDigits: 6 }),
                            tM_TE_MWh: item.tM_TE_MWh.toLocaleString("pt-BR", { minimumFractionDigits: 6, maximumFractionDigits: 6 }),
                            tM_MWh: item.tM_MWh.toLocaleString("pt-BR", { minimumFractionDigits: 6, maximumFractionDigits: 6 }),
                            eC_TUSD: `${(item.eC_TUSD * 100).toLocaleString("pt-BR", { minimumFractionDigits: 4, maximumFractionDigits: 4 })} %`,
                            eC_TE: `${(item.eC_TE * 100).toLocaleString("pt-BR", { minimumFractionDigits: 4, maximumFractionDigits: 4 })} %`,
                            eC_Total: `${(item.eC_Total * 100).toLocaleString("pt-BR", { minimumFractionDigits: 4, maximumFractionDigits: 4 })} %`
                        }
                    }));
                    
                } else {
                    store.dispatch(
                        alertaExibir({
                            tipo: "warning",
                            mensagem:
                                "Erro ao buscar detalhes da tarifa"
                        })
                    );
                }
                setIsLoading(false);
                setMsgAguarde();
            });
        } catch (error) {
            store.dispatch(
                alertaExibir({
                    tipo: "danger",
                    mensagem:
                        `Erro não previsto ao buscar detalhes da tarifa: ${error}`
                })
            );
        }
    }

    useEffect(() => {
        listarDetalhes();
    }, [])


    return (
        <Grid container style={{ paddingLeft: "1.5em", paddingRight: "1.5em" }}>
            <Grid item xs={8} style={{ marginBottom: 20 }}>
                <h4 style={{color: "white"}}>{tituloProjecao}</h4>
                <h5 style={{color: "white"}}>Projeção Tarifa</h5>
            </Grid>
            <Grid item xs={3}>
                <Botao
                    type="submit"
                    label="Memória de Cálculo"
                    color={Base.Mango}
                    className={classes.button}
                    onClick={() => {
                        history.push(`${RotasDTO.ProjecaoTarifaria}/visualizar/detalhes/${id}${RotasDTO.DetalheTarifa}/TARIFA/Projeção Tarifa${RotasDTO.MemoriaCalculo}`);
                    }}
                />
            </Grid>
            <Grid item xs={1} className={classes.backButton}>
                <BotaoRetornarListagem voltarPagina />
            </Grid>
            
            <Grid container spacing={3} alignItems="center">
                <Grid item xs={4}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <MaterialInputTexto
                            fullWidth
                            type="text"
                            id="distribuidora"
                            name="distribuidora"
                            label="Distribuidora"
                            renderIconShowHide={false}
                            defaultValue={distribuidora}
                            disabled
                        />

                    </FormControl>
                </Grid>

                <Grid item xs={4}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <MaterialInputTexto
                            fullWidth
                            type="text"
                            id="cenario"
                            name="cenario"
                            label="Cenario"
                            disabled
                            renderIconShowHide={false}
                            defaultValue={cenario}
                        />

                    </FormControl>
                </Grid>

                <Grid item xs={4}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <MaterialInputTexto
                            fullWidth
                            type="text"
                            id="data"
                            name="data"
                            label="Realizada em"
                            renderIconShowHide={false}
                            defaultValue={dataProjecao}
                        />

                    </FormControl>
                </Grid>
            </Grid>

            <Grid container item xs={12} spacing={3} justifyContent="center" className="w-100">
                <Loader loading={isLoading} className="w-100">
                    {msgAguarde}
                </Loader>
            </Grid>   

            {detalhesTabela.length > 0 ?
                <Grid container spacing={3} justifyContent="left">
                    <Grid item xs={12} align="left">
                    <Typography style={{color: Base.OffWhite, paddingTop:"50px", paddingBottom:"-50px"}}>
                        "As tarifas projetadas não consideram os descontos tarifários"</Typography>
                        <DataTable
                            subHeader
                            subHeaderAlign='right'
                            columns={colunasDetalhes}
                            data={detalhesTabela}
                            theme="DeltaEnergia"
                            customStyles={customStyles}
                            noDataComponent="Nenhum registro encontrado"
                            noHeader
                            
                        />
                
                    </Grid>
                </Grid>
            : ""}
        </Grid>
    );
}

export default DetalheTarifa;
