/* eslint-disable jsx-a11y/control-has-associated-label */
import PropTypes from "prop-types";
import React from "react";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import { Grid } from "@mui/material";
import theme from "themes";
import MaterialSwitch from "../switch";

import { Label, useStyles } from "./style";

const ItemLista = ({
  label,
  color,
  edicao,
  exclusao,
  onClickEditar,
  onClickExcluir,
  ativo
}) => {
  const classes = useStyles({ color, ativo });

  return (
    <Grid item xs={4}>
      <Grid container spacing={0}>
        <Grid item xs>
          <Label className={classes.label}>{label}</Label>
        </Grid>
        {edicao && (
          <Grid item className={`${classes.squareWidth} ${classes.gridItem}`}>
            <div
              className={`${classes.treeItem} ${classes.wrapper} ${classes.center}`}
              onKeyDown={onClickEditar}
              onClick={onClickEditar}
              role="button"
              tabIndex={0}
            >
              <EditOutlinedIcon />
            </div>
          </Grid>
        )}
        {exclusao && (
          <Grid item className={`${classes.squareWidth} ${classes.gridItem}`}>
            <div
              className={`${classes.treeItem} ${classes.wrapper} ${classes.center}`}
              onKeyDown={onClickExcluir}
              onClick={onClickExcluir}
              role="button"
              tabIndex={0}
            >
              <MaterialSwitch checked={ativo} />
            </div>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

ItemLista.propTypes = {
  label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  color: PropTypes.string,
  edicao: PropTypes.bool,
  exclusao: PropTypes.bool,
  onClickEditar: PropTypes.oneOfType([PropTypes.func]),
  onClickExcluir: PropTypes.oneOfType([PropTypes.func]),
  ativo: PropTypes.bool
};

ItemLista.defaultProps = {
  color: theme.color.primary,
  edicao: false,
  exclusao: false,
  onClickEditar: () => {},
  onClickExcluir: () => {},
  ativo: true
};

export default ItemLista;
