import RetornoEndpointDto from "global/dto/retornoEndpointDto";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { gerarContrato } from "servicos/workflowServico";

export default class AssinarTermoHelper {
  static async criarDocumento(tipoEnvio, protocoloDemanda) {
    try {
      const resultado = await gerarContrato(tipoEnvio, protocoloDemanda);

      return RetornoEndpointDto.Sucesso(
        "Documentos gerados com sucesso!",
        resultado.data
      );
    } catch (ex) {
      return RetornoEndpointDto.Erro(
        ex?.response?.data?.message ??
          "Não foi possivel criar os documentos para assinatura!"
      );
    }
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }

  static exibirSucesso(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem
      })
    );
  }
}
