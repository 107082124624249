import moment from "moment";

export default class ListaDto {
  constructor(id, clienteId, tipoDocumentoId, nomeArquivo, dataUpload, pessoaFisicaJuridica) {
    this.id = id;
    this.clienteId = clienteId;
    this.tipoDocumentoId = tipoDocumentoId;
    this.nomeArquivo = nomeArquivo;
    this.dataUpload = moment(dataUpload).format("L");
    this.pessoaFisicaJuridica = pessoaFisicaJuridica;
  }
}

