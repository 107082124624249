/* eslint-disable no-else-return */
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useParams,useHistory } from "react-router-dom";
import { useForm, useWatch } from "react-hook-form";
import shortid from "shortid";
import { Grid, FormControl } from "@mui/material";
import { Botao} from "componentes";
import { Base } from "componentes/cores";
import Loader from "componentes/loader";
import ModalConfirmacao from "componentes/modalConfirmacao";
import MaterialInputTexto from "componentes/inputTexto/materialInput";
import Switch from "componentes/switch";
import BotaoRetornarListagem from "componentes/botaoRetornarListagem";
import { useSelector } from "react-redux"
import RotasDTO from "../../../rotas/rotasUrlDto";
import { useStyles } from "./style";
import ItemListaExpansivel from "../listagem/itemListaExpansivel";
import { listarProjecoesPorGrupo, updateStatusGrupo, buscaStatusDoGrupo } from "../../../servicos/grupoProjecoes";
import { excluirProjecao } from "../../../servicos/projecaoTarifaria";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import ModalInformacao from "componentes/modalVerificacao";

const ListaProjecoesDeGrupo = () => {
  const { idgrupo, nomegrupo, titulocenario, idcenario } = useParams();
  
  const classes = useStyles();
  const history = useHistory();
  const usuarioGlobal = useSelector((state) => state.usuario);

  const [projecoes, setProjecoes] = useState([]);
  const [listaProjecoes, setListaProjecoes] = useState([]);
  const [carregandoDados, setCarregandoDados] = useState(false);
  const [filtroSelecionado, setFiltroSelecionado] = useState(null);

  const [showModalExclusao, setShowModalExclusao] = useState(false);
  const [showModalUpdateStatus, setShowModalUpdateStatus] = useState(false);
  const [projecaoExclusao, setProjecaoExclusao] = useState({});
  const [modalInfo, setModalInfo] = useState(false);
  const [situacaoGrupo, setSituacaoGrupo] = useState("");
  const [situacaoGrupoProvisoria, setSituacaoGrupoProvisoria] = useState("");

  const { register, control } = useForm({
    reValidateMode: "onSubmit"
  });
  
  const textoBusca = useWatch({
    control,
    name: "textoBusca",
    defaultValue: ""
  });

  const stringBusca = useMemo(() => {
    return textoBusca;
  }, [textoBusca]);

  const obterProjecoes = useCallback(async () => {
    try {
      setCarregandoDados(true);
      const lista = await listarProjecoesPorGrupo(idgrupo);

      if (lista.status === 200 && lista.data) {
        setProjecoes(lista.data); 
        setListaProjecoes(lista.data);            
      }
      setCarregandoDados(false);  
    } catch (error) {
      console.info(error);
      setCarregandoDados(false);
    }
  }, []);

  const obterStatusGrupo = async () =>{
    try {
      const retorno = await buscaStatusDoGrupo(idgrupo);

      if (retorno.status === 200 && retorno.data) {
        setSituacaoGrupo(retorno.data.codigo); 
        setSituacaoGrupoProvisoria(retorno.data.codigo);            
      }
    } catch (error) {
      console.info(error);
    }
  }

  function filterByTitulo(obj) {
    if (obj.tituloProjecao != null && obj.tituloProjecao.toUpperCase().includes(textoBusca.toUpperCase())) {   
      return true;
    } else {
      return false;
    }
  }
  
  const handleFiltrarProjecoes = () =>{

    let dadosFiltrados = [];
    
    if(listaProjecoes != null && listaProjecoes.length > 0){  
      
      dadosFiltrados = listaProjecoes;
     
      if(stringBusca !== undefined && stringBusca.length > 0){           
        if((filtroSelecionado === null || filtroSelecionado === undefined)){
          dadosFiltrados = dadosFiltrados.filter(filterByTitulo);
        }      
      }
      setProjecoes(dadosFiltrados);
    } 
  }

  const handlePeriodo = (de, ate) => {
    const data = `de ${de} até ${ate}`;
    return data;
  }
  
  const handleExclusao = async (idProjecaoExclusao) => {
    
    const response = await excluirProjecao(idProjecaoExclusao, usuarioGlobal.usuario.id, usuarioGlobal.usuario.nome);
    if (response.status === 200)
    {
      store.dispatch(
        alertaExibir({
          tipo: "success",
          mensagem: "Projeção excluída com sucesso."
        })
      );
    }
    else
    {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: response.data
        })
      );

    }
    
    setShowModalExclusao(false);
    setProjecaoExclusao({});
    obterProjecoes();
  }

  const handleChangeStatusProjecao = async () => {
    
    setSituacaoGrupo(situacaoGrupoProvisoria);

    const response = await updateStatusGrupo(idgrupo, situacaoGrupoProvisoria);

    if (response.status === 200) {
      store.dispatch(
          alertaExibir({
              tipo: "success",
              mensagem: `Gravação do status do grupo realizada com sucesso`
          })
      )
    }
    else {
        store.dispatch(
            alertaExibir({
                tipo: "warning",
                mensagem: `Falha na gravação do Status do Grupo: ${response.data}`
            })
        )
    } 
    obterProjecoes();
    setShowModalUpdateStatus(false);
    obterStatusGrupo();
  }

  const handleMinDate = (date) => {
    /*
      Caso data enviada pelo back seja a data mínima com ano = 1
      significa o mesmo que data sem valor
    */
    return new Date(Date.parse(date)).getUTCFullYear() !== 1 
    ?
    new Date(Date.parse(date)).toLocaleString("pt-Br",{
      dateStyle: "short",
      timeStyle: "short",
      timeZone: "America/Sao_Paulo"
    }) : "" ;
  }
 
  const onClickExcluir = async (projecao) => {
    
    if(projecao.codigoStatusProjecao === "CONCLUIDA") {
      setModalInfo(true);
    }
    else
    {
        setProjecaoExclusao(projecao);
        setShowModalExclusao(true);
    }
  }

  const onClickVisualizar = (id) => {
    history.push(`${RotasDTO.ProjecaoTarifariaVisualizarDetalhes}/${id}`);
  }

  const onClickExecutarProjecoes = () => {
    history.push(`${RotasDTO.ExecucaoProjecaoListar}/${idgrupo}/${nomegrupo}/${idcenario}`);
  }

  const onClickChangeStatusGrupo = async(event) => {
    const novaSituacao = event ? "CONCLUIDO" : "RASCUNHO";
    setSituacaoGrupoProvisoria(novaSituacao);
    setShowModalUpdateStatus(true);
  }

  useEffect(() => {
    if (!projecoes.length) 
    {
      obterProjecoes();
     }
  }, [projecoes.length]);


  useEffect(() => {
    obterStatusGrupo();
  },[projecoes.length]);


  useEffect(() => {
    setTimeout(() => {
      handleFiltrarProjecoes();
    }, 100);
  }, [stringBusca]);

 
  return (
    <>
      <ModalInformacao
        titulo={"Validação da exclusão"}
        exibir={modalInfo}
        mensagem={"Não é possível excluir uma projeção concluída"}
        onClose={() => {
          setModalInfo(false);
        }}
        showBotao={false}
      />

      <ModalConfirmacao
        item={showModalExclusao}
        onCancelar={() => {
          setShowModalExclusao(false);
          setProjecaoExclusao({});
        }}
        onConfirmar={() => handleExclusao(projecaoExclusao.idProjecaoTarifaria)}
        mensagem={
          <div>    
            <h5>Deseja concluir a exclusão?</h5>
            <p>Projecao a ser excluida "{projecaoExclusao.tituloProjecao}".</p>
          </div>
        }
      />

      <ModalConfirmacao
        item={showModalUpdateStatus}
        onCancelar={() => {
          setShowModalUpdateStatus(false);
        }}
        onConfirmar={handleChangeStatusProjecao}
        mensagem={
          <div>    
            <h5>Confirma a alteração do status do grupo?</h5>
            <p>Grupo a ser alterado: "{nomegrupo}".</p>
          </div>
        }
      />

      <Loader loading={carregandoDados} className="w-auto">
      <Grid container p={2} spacing={2} alignItems="center">
            <Grid item xs={3} className={classes.inputCustomContainer}>
              <MaterialInputTexto
                  fullWidth
                  type="text"
                  id="tituloGrupo"
                  name="tituloGrupo"
                  label="Grupo"
                  className={classes.search}
                  //style={{opacity: "0.6"}}
                  renderIconShowHide={false}
                  defaultValue={nomegrupo}
                  disabled
              />
            </Grid>
            <Grid item xs={2} className={classes.inputCustomContainer}>
              <MaterialInputTexto
                  fullWidth
                  type="text"
                  id="tituloCenario"
                  name="tituloCenario"
                  label="Cenário"
                  className={classes.search}
                  //style={{opacity: "0.6"}}
                  renderIconShowHide={false}
                  defaultValue={titulocenario}
                  disabled
              />
            </Grid>

             <Grid item xs={3}>
                  <div style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                      }}>
                      {//status &&
                          <p style={{
                              color: "white",
                              marginBottom: 0, 
                              marginRight: 5,
                          }}>
                              {"Rascunho"}
                          </p>
                      }
                      <Switch
                          label=""
                          checked={situacaoGrupo === "CONCLUIDO"}
                          onChange={(evento) => onClickChangeStatusGrupo(evento)}
                      />
                      {//status && 
                          <p style={{
                              color: "white", 
                              marginBottom: 0,
                              marginLeft: 5,
                          }}>
                              {"Concluído"}
                          </p>   
                      }
                  </div>
            </Grid>
            <Grid xs={3} className={classes.buttonContainer}>
                <Botao
                    type="submit"
                    label="Executar Projeções"
                    color={Base.Mango}
                    className={classes.button}
                    onClick={()=>{onClickExecutarProjecoes()}}
                />
          </Grid>
          <Grid item xs={1} className={classes.backButton}>
                    <BotaoRetornarListagem voltarPagina />
          </Grid>

      </Grid>
        <Grid container p={2} spacing={2} alignItems="center">
          <Grid item xs={9}>
            <MaterialInputTexto
              type="text"
              id="textoBusca"
              name="textoBusca"
              label="Buscar"
              renderIconShowHide
              searchAdornment
              defaultValue={textoBusca ?? ""}
              ref={register}
              className={classes.search}
              permiteValorBranco
            />

          </Grid>

          {projecoes.length > 0 && projecoes.map((projecao) => {
            return (
              <>
                {projecao.codigoStatusProjecao !== "EXCLUIDA" &&
                  <ItemListaExpansivel
                    key={shortid.generate()}
                    label={projecao?.tituloProjecao}
                    labelColor = {projecao.codigoStatusProjecao === "CONCLUIDA" ? Base.Grey : Base.White}
                    color={projecao.resultadoProjecao === "Falha" ? Base.RedError : Base.GreenFern}
                    detalhe={!!projecao?.idProjecaoTarifaria}
                    onClickVisualizar={() => onClickVisualizar(projecao?.idProjecaoTarifaria)}
                    exclusao={!!projecao?.idProjecaoTarifaria}
                    onClickExcluir={() => onClickExcluir(projecao)}
                  >
                    <div>    
                      <Grid spacing={1}>

                        <Grid container spacing={2}>    
                          <Grid item sm={4}>
                            <strong>Distribuidora: </strong>  
                          </Grid>          
                          <Grid item sm={8}>
                            {projecao?.distribuidora} 
                          </Grid>                    
                        </Grid> 

                        <Grid container spacing={2}>  
                          <Grid item sm={4}>
                            <strong>Período projetado: </strong>  
                          </Grid>          
                          <Grid item sm={8}>
                            {handlePeriodo(projecao?.anoInicial, projecao?.anoFinal)}
                          </Grid>                    
                        </Grid> 

                        <Grid container spacing={2}>  
                          <Grid item sm={4}>
                            <strong>Status: </strong>  
                          </Grid>          
                          <Grid item sm={8}>
                            {projecao?.statusProjecao}
                          </Grid>                    
                        </Grid> 

                        <Grid container spacing={2}>  
                          <Grid item sm={4}>
                            <strong>Última execução: </strong>  
                          </Grid>          
                          <Grid item sm={8}>{handleMinDate(projecao?.dataUltimaExecucao)} 
                          </Grid>                    
                        </Grid>  

                        <Grid container spacing={2}>  
                          <Grid item sm={4}>
                            <strong>Analista: </strong>  
                          </Grid>          
                          <Grid item sm={8}>
                            {projecao?.usuario} 
                          </Grid>                    
                        </Grid>  

                        <Grid container spacing={2}>  
                          <Grid item sm={4}>
                            <strong>Resultado: </strong>  
                          </Grid>          
                          <Grid item sm={8}>
                            {projecao?.resultadoProjecao} 
                          </Grid>                    
                        </Grid>   

                      </Grid>                        
                    </div>
                  </ItemListaExpansivel>
                }
              </>
            )
          })}
        </Grid>
      </Loader>
    </>
  )
}

export default ListaProjecoesDeGrupo;