import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import shortid from "shortid";

// Icones
import TuneIcon from "@mui/icons-material/Tune";

// Form Hooks
import { useForm, useWatch } from "react-hook-form";

// Redux
import { useSelector } from "react-redux";

// Componentes
import { Grid } from "@mui/material";
import { SelectArredondado, Botao } from "componentes";
import MaterialInputBusca from "componentes/inputBusca";
import { InterfaceDTO } from "global/dto/interfacesDto";
import TabelaSemDados from "componentes/tabelaPaginada/tabelaSemDados";
import { cpfCnpjValido } from "../../../../global/utils/formatCpfCnpj";
import BotaoCadastro from "../../../../componentes/botaoCadastro";
import Loader from "../../../../componentes/loader";
import ModalConfirmacao from "../../../../componentes/modalConfirmacao";
import ItemListaExpansivel from "../../../../componentes/itemListaExpansivel";
import BotaoFiltroOrdenacao from "../../../../componentes/botaoFiltroOrdenar";
import Paginacao from "../../../../componentes/paginacao";
import theme from "../../../../themes";

// Serviços
import {
  listarClientes,
  deletarCliente,
  listarOrganizarPor,
  listarFiltrarPor,
  listarFiltrarClientePor
} from "../../../../servicos/clientesServico";
import { usuarioPossuiFuncionalidade } from "../../../../servicos/funcionalidadesServico";

// Rotas
import { RotasDTO } from "../../../../global/rotas/rotasUrlDto";

// Redux
import { store } from "../../../../global/redux";
import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";

// Styles
import { useStyles } from "./style";

import {
  desabilitarCliente,
  desabilitarEmpresa,
  desabilitarUnidade
} from "../../../../global/redux/modulos/usuario/actions";

const ClientesListar = () => {
  const classes = useStyles();
  const history = useHistory();

  const [paginaAtual, setPaginaAtual] = useState();
  const [totalPaginas, setTotalPaginas] = useState();
  const [carregandoClientes, setCarregandoClientes] = useState(false);
  const [listaClientes, setListaClientes] = useState([]);
  const [mostrarBtnCadastroCliente, setMostrarBtnCadastroCliente] = useState(
    true
  );
  const [carregamentoInicial, setCarregamentoInicial] = useState(false);
  const [campoPesquisa, setCampoPesquisa] = useState("");

  const usuario = useSelector((state) => state.usuario);
  const { empresaSelecionada, menu } = usuario;
  const rotas = useSelector((state) => state.rotas);

  useEffect(() => {
    setCarregamentoInicial(true);
    handleListarClientes(
      ordemSelecionada,
      filtroSelecionado,
      1,
      empresaSelecionada,
      textoParaBusca,
      20,
      filtroTipoClienteSelecionado
    );
  }, []);

  const handleListarClientes = async (
    ordernacao,
    filtro,
    atual,
    empresa,
    texto,
    tamanhoPaginacao,
    filtroTipoClienteSelecionado
  ) => {
    try {
      setCarregandoClientes(true);
      const buscaPorId = history?.location?.state?.dadosCliente?.id?.toString()
        ? "BuscaPorId"
        : "";
      const lista = await listarClientes(
        ordernacao,
        filtro,
        atual,
        empresa,
        cpfCnpjValido(texto),
        tamanhoPaginacao,
        filtroTipoClienteSelecionado,
        campoPesquisa || buscaPorId || ""
      );
      if (lista?.status === 200 && lista?.data?.clientes?.length) {
        setListaClientes(lista?.data?.clientes);
        setPaginaAtual(lista?.data?.paginaAtual);
        setTotalPaginas(lista?.data?.totalPaginas);
      } else {
        setListaClientes([]);
        setPaginaAtual();
        setTotalPaginas();
      }

      setCarregandoClientes(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoClientes(false);
    } finally {
      history.replace({
        pathname: RotasDTO.Clientes,
        state: { dadosCliente: null }
      });
    }
  };

  const onClickNovoCliente = () => {
    history.push(`${RotasDTO.Clientes}/cadastro`);
  };

  const onClickEditar = (id) => {
    history.push(`${RotasDTO.Clientes}/cadastro/${id}`);
  };

  const [modalExclusao, setModalExclusao] = useState(false);

  const deletar = async (item) => {
    try {
      setModalExclusao(false);
      setCarregandoClientes(true);
      const deletou = await deletarCliente(
        item?.id,
        !(modalExclusao?.situacao?.toUpperCase() === "AT")
      );
      if (deletou) {
        handleListarClientes("", "", 1, "", "", "", "");
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: `O cliente foi ${
              !(modalExclusao?.situacao?.toUpperCase() === "AT")
                ? "ativado"
                : "inativado"
            } com sucesso!`
          })
        );
      }
      setCarregandoClientes(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoClientes(false);
    }
  };

  const onClickExcluir = (item) => {
    setModalExclusao(item);
  };

  const onConfirmarExclusao = (item) => {
    try {
      deletar(item);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  const { register, control, setValue } = useForm({
    reValidateMode: "onSubmit"
  });

  const textoBusca = useWatch({
    control,
    name: "textoBusca",
    defaultValue: history?.location?.state?.dadosCliente?.id?.toString() || ""
  });

  const textoParaBusca = useMemo(() => {
    return textoBusca?.trim() || "";
  }, [textoBusca]);

  const [ordemSelecionada, setOrdemSelecionada] = useState("");
  const [filtroSelecionado, setFiltroSelecionado] = useState("");
  const [
    filtroTipoClienteSelecionado,
    setFiltroTipoClienteSelecionado
  ] = useState("");

  useEffect(() => {
    if (textoParaBusca === "" && carregamentoInicial) {
      const debounce = setTimeout(() => {
        handleListarClientes(
          ordemSelecionada,
          filtroSelecionado,
          1,
          empresaSelecionada,
          textoParaBusca,
          20,
          filtroTipoClienteSelecionado
        );
      }, 1000);

      return () => clearTimeout(debounce);
    }
  }, [textoParaBusca]);

  useEffect(() => {
    if (campoPesquisa === "Todos" && carregamentoInicial) {
      setValue("textoBusca", null);
      const debounce = setTimeout(() => {
        handleListarClientes(
          ordemSelecionada,
          filtroSelecionado,
          1,
          empresaSelecionada,
          textoParaBusca,
          20,
          filtroTipoClienteSelecionado
        );
      }, 1000);

      return () => clearTimeout(debounce);
    }
  }, [campoPesquisa]);

  useEffect(() => {
    const possuiFuncionalidadeCadastrarCliente = usuarioPossuiFuncionalidade(
      menu,
      rotas.rotaAtual.caminho,
      RotasDTO.Clientes,
      InterfaceDTO.PermissaoCadastrarCliente
    );
    setMostrarBtnCadastroCliente(possuiFuncionalidadeCadastrarCliente);
    if (carregamentoInicial) {
      handleListarClientes(
        ordemSelecionada,
        filtroSelecionado,
        1,
        empresaSelecionada,
        textoParaBusca,
        60,
        filtroTipoClienteSelecionado
      );
    }
  }, [empresaSelecionada]);

  useEffect(() => {
    store.dispatch(desabilitarCliente(true));
    store.dispatch(desabilitarUnidade(true));

    return () => {
      store.dispatch(desabilitarEmpresa(false));
      store.dispatch(desabilitarCliente(false));
      store.dispatch(desabilitarUnidade(false));
    };
  }, [desabilitarCliente, desabilitarUnidade]);

  const handleClickOrdemSelecionada = (event) => {
    const val = ordemSelecionada === event ? "" : event;
    setOrdemSelecionada(val);
  };

  const handleClickFiltroSelecionado = (event) => {
    const val = filtroSelecionado === event ? "" : event;
    setFiltroSelecionado(val);
  };

  const handleClickFiltroTipoClienteSelecionado = (event) => {
    const val = filtroTipoClienteSelecionado === event ? "" : event;
    setFiltroTipoClienteSelecionado(val);
  };

  const handleClickAtivarAgora = async () => {
    const filtro = [];
    if (filtroSelecionado) filtro.push(filtroSelecionado);
    handleListarClientes(
      ordemSelecionada,
      filtro,
      1,
      empresaSelecionada,
      textoParaBusca,
      60,
      filtroTipoClienteSelecionado
    );
  };

  const onChangePaginacao = (item, pagina) => {
    handleListarClientes(
      ordemSelecionada,
      filtroSelecionado,
      pagina,
      empresaSelecionada,
      textoParaBusca,
      60,
      filtroTipoClienteSelecionado
    );
  };

  const onClickSearch = () => {
    handleListarClientes(
      ordemSelecionada,
      filtroSelecionado,
      1,
      empresaSelecionada,
      textoParaBusca,
      60,
      filtroTipoClienteSelecionado
    );
  };

  return (
    <Loader loading={carregandoClientes} className="w-auto">
      <ModalConfirmacao
        item={modalExclusao}
        onConfirmar={(item) => onConfirmarExclusao(item)}
        mensagem={`Tem certeza que deseja ${
          modalExclusao?.situacao?.toUpperCase() === "AT"
            ? "inativar"
            : "ativar"
        } esse Cliente?`}
        onCancelar={() => setModalExclusao(false)}
      />
      <Grid
        container
        spacing={3}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={12} sm={4} lg={3}>
          <SelectArredondado
            select
            id="CampoPesquisa"
            name="CampoPesquisa"
            label="Filtrar por campo"
            placeholder="Filtrar por campo"
            valueKey="valor"
            valueName="nome"
            className={classes.select}
            dataSource={[
              { nome: "Todos", valor: "Todos" },
              { nome: "Nome", valor: "NomeFantasia" },
              { nome: "CPF/CNPJ", valor: "CnpjCpf" },
              { nome: "E-mail", valor: "Email" },
              { nome: "Telefone", valor: "Telefone" }
            ]}
            value={campoPesquisa}
            onChange={(e) => {
              setCampoPesquisa(e.target.value);
            }}
            permiteValorBranco
            styleForm={false}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={4}>
          <MaterialInputBusca
            type="text"
            id="textoBusca"
            name="textoBusca"
            label="Buscar"
            renderIconShowHide
            searchAdornment
            defaultValue={textoBusca ?? ""}
            ref={register}
            permiteValorBranco
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={2}>
          <Botao
            type="submit"
            onClick={onClickSearch}
            label="Buscar"
            className={classes.button}
            disabled={!textoParaBusca}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          lg={3}
          display="flex"
          justifyContent={{ sm: "flex-end" }}
        >
          <BotaoFiltroOrdenacao
            type="button"
            color={theme.color.secondaryBorderColor}
            background="transparent"
            label="Filtrar / Ordernar"
            icon={<TuneIcon />}
            className={classes.buttonFiltro}
            ordenacao={listarOrganizarPor()}
            ordenadorUm="Ordenador"
            ordenadorDois="Filtrar"
            ordenadorTres="Tipo Cliente"
            ordemSelecionada={ordemSelecionada}
            filtros={listarFiltrarPor()}
            filtrosSelecionados={filtroSelecionado}
            onClickOrdenacao={handleClickOrdemSelecionada}
            onClickFiltro={handleClickFiltroSelecionado}
            onClickAtivarAgora={handleClickAtivarAgora}
            filtroTipoCliente={listarFiltrarClientePor()}
            filtrosTipoClienteSelecionado={filtroTipoClienteSelecionado}
            onClickFitroTipoCliente={handleClickFiltroTipoClienteSelecionado}
          />
        </Grid>

        <Grid
          item
          xs={12}
          className={`${
            mostrarBtnCadastroCliente ? `${classes.containerSalvar}` : "d-none"
          }`}
        >
          <BotaoCadastro
            label="Novo cliente"
            color={theme.color.buttonColor}
            onClick={onClickNovoCliente}
          />
        </Grid>
        {listaClientes && listaClientes.length ? (
          <>
            {listaClientes.map((cliente) => {
              return (
                <ItemListaExpansivel
                  key={shortid.generate()}
                  label={
                    cliente?.nomeFantasia ?? cliente.razaoSocial ?? "Cliente"
                  }
                  color={theme.color.buttonColor}
                  edicao={!!cliente?.id}
                  exclusao={!!cliente?.id}
                  onClickEditar={() => onClickEditar(cliente?.id)}
                  onClickExcluir={() => onClickExcluir(cliente ?? false)}
                  ativo={cliente?.situacao.toUpperCase() === "AT"}
                >
                  <div>
                    <strong>CNPJ/CPF: </strong>
                    {cliente?.cnpjCpfFormatado ?? ""}
                  </div>
                </ItemListaExpansivel>
              );
            })}
            <Grid
              container
              spacing={3}
              direction="row"
              justifyContent="center"
              alignItems="center"
              mt={2}
            >
              <Grid item>
                <Paginacao
                  paginaAtual={paginaAtual}
                  totalPaginas={totalPaginas}
                  onChangePagina={onChangePaginacao}
                />
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid
            container
            spacing={3}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <TabelaSemDados className={classes.container} />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Loader>
  );
};

export default ClientesListar;
