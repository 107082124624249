import { EditOutlined } from "@mui/icons-material";
import TuneIcon from "@mui/icons-material/Tune";
import { Button, Grid } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useHistory } from "react-router-dom";
import theme from "themes";
import Botao from "../../../componentes/botao";
import BotaoFiltroOrdenacao from "../../../componentes/botaoFiltroOrdenar";
import MaterialInputBusca from "../../../componentes/inputBusca";
import TabelaPaginada from "../../../componentes/tabelaPaginada";
import Coluna from "../../../componentes/tabelaPaginada/colunas/coluna";
import ColunaComponentePersonalizado from "../../../componentes/tabelaPaginada/colunas/colunaComponentePersonalizado";
import ResultadoPaginadoDto from "../../../componentes/tabelaPaginada/resultadoPaginadoDto";
import { store } from "../../../global/redux";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";
import { desabilitarFiltroLateral } from "../../../global/redux/modulos/usuario/actions";
import { RotasDTO } from "../../../global/rotas/rotasUrlDto";
import {
  listarFiltrarPor,
  listarOrganizarPor
} from "../../../servicos/agenteServico";
import AgenteListaDto from "./agenteListaDto";
import FiltroAdicionalTabela from "./filtroAdicionalTabela";
import FiltroDto from "./filtroDto";
import AgenteHelper from "./helper";
import { useStyles } from "./style";

const Agente = () => {
  const [ordemSelecionada, setOrdemSelecionada] = useState("");
  const [filtroSelecionado, setFiltroSelecionado] = useState("");
  const [filtroAdicionalTabela, setFiltroAdicionalTabela] = useState(
    new FiltroAdicionalTabela()
  );

  const history = useHistory();
  const classes = useStyles();

  const [carregamentoInicial, setCarregamentoInicial] = useState(false);

  const { register, control } = useForm({
    reValidateMode: "onSubmit"
  });

  const textoBusca = useWatch({
    control,
    name: "textoBusca",
    defaultValue: ""
  });

  const ColunaEditarAgente = ({ parametros }) => {
    const onClickEditarAgente = () => {
      history.push(`${RotasDTO.Agentes}/cadastro/${parametros.id}`);
    };

    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Button
            onClick={onClickEditarAgente}
            className={`text-white ${classes.botao}`}
          >
            <EditOutlined />
          </Button>
        </Grid>
      </Grid>
    );
  };

  ColunaEditarAgente.propTypes = {
    parametros: PropTypes.shape({ id: PropTypes.string }).isRequired
  };

  const ColunaEditarPerfil = ({ parametros }) => {
    const onClickEditarPerfil = () => {
      history.push(
        `${RotasDTO.PerfilAgenteCadastro}/${parametros.perfilAgenteId}`
      );
    };

    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Button
            onClick={onClickEditarPerfil}
            className={`text-white ${classes.botao}`}
          >
            <EditOutlined />
          </Button>
        </Grid>
      </Grid>
    );
  };

  ColunaEditarPerfil.propTypes = {
    parametros: PropTypes.shape({ perfilAgenteId: PropTypes.string }).isRequired
  };

  const colunas = [
    new Coluna("cliente", "Cliente"),
    new Coluna("sigla", "Sigla agente CCEE"),
    new Coluna("perfil", "Perfil Agente"),
    new ColunaComponentePersonalizado(
      "editarAgente",
      "Editar agente",
      ColunaEditarAgente,
      null,
      false,
      false,
      "90px"
    ),
    new ColunaComponentePersonalizado(
      "editarPerfil",
      "Editar perfil",
      ColunaEditarPerfil,
      null,
      false,
      false,
      "90px"
    )
  ];

  const obterAgentes = async (parametros) => {
    const filtro = new FiltroDto(
      parametros.tamanhoPagina,
      parametros.pagina,
      parametros.pesquisar || textoPesquisa,
      parametros.filtroAdicional.ordenacao,
      [parametros.filtroAdicional.filtro]
    );

    const resultado = await AgenteHelper.listarAgentes(filtro);

    if (!resultado.sucesso) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: resultado.mensagem
        })
      );
      return new ResultadoPaginadoDto([], 1, 0, 0);
    }

    if (resultado.data.agentesCCEEClientesPerfis?.length === 0 ?? true)
      return new ResultadoPaginadoDto([], 1, 0, 0);

    const resLinhas = resultado.data.agentesCCEEClientesPerfis?.map((res) => {
      return new AgenteListaDto(
        res.agenteCCEEId,
        res.razaoSocialCliente,
        res.siglaAgenteCCEE,
        res.descricaoPerfilAgente,
        res.ativo,
        res.perfilAgenteId
      );
    });

    return new ResultadoPaginadoDto(
      resLinhas,
      resultado.data.paginaAtual,
      resultado.data.totalItens,
      resultado.data.totalPaginas
    );
  };

  const onChangeFiltrosTabela = async (parametros) => {
    return obterAgentes({
      tamanhoPagina: parametros.totalPagina,
      pagina: parametros.pagina,
      pesquisar: parametros.pesquisar,
      filtroAdicional: new FiltroAdicionalTabela(
        parametros.filtrosAdicionais?.ordenacao,
        parametros.filtrosAdicionais?.filtro
      )
    });
  };

  const handleClickAtivarFiltro = () => {
    setFiltroAdicionalTabela(
      new FiltroAdicionalTabela(ordemSelecionada, filtroSelecionado)
    );
  };

  const handleClickOrdemSelecionada = (event) => {
    const val = ordemSelecionada === event ? "" : event;
    setOrdemSelecionada(val);
  };

  const handleClickFiltroSelecionado = (event) => {
    const val = filtroSelecionado === event ? "" : event;
    setFiltroSelecionado(val);
  };

  useEffect(() => {
    setCarregamentoInicial(true);
    store.dispatch(desabilitarFiltroLateral(true));
    return () => store.dispatch(desabilitarFiltroLateral(false));
  }, []);

  const textoPesquisa = useMemo(() => {
    return textoBusca?.length >= 3 ? textoBusca?.trim() : "";
  }, [textoBusca]);

  useEffect(() => {
    if (textoPesquisa === "" && carregamentoInicial) {
      const debounce = setTimeout(() => {
        setFiltroAdicionalTabela(
          new FiltroAdicionalTabela(
            ordemSelecionada,
            filtroSelecionado,
            textoPesquisa
          )
        );
      }, 1000);

      return () => clearTimeout(debounce);
    }
  }, [textoPesquisa]);

  const onClickSearch = () => {
    setFiltroAdicionalTabela(
      new FiltroAdicionalTabela(
        ordemSelecionada,
        filtroSelecionado,
        textoPesquisa
      )
    );
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
        >
          <Grid item md={5} sm={9}>
            <MaterialInputBusca
              type="text"
              id="textoBusca"
              name="textoBusca"
              label="Buscar"
              renderIconShowHide
              searchAdornment
              permiteValorBranco
              defaultValue={textoBusca ?? ""}
              ref={register}
            />
          </Grid>
          <Grid item xs={4} sm={2}>
            <Botao
              type="submit"
              onClick={onClickSearch}
              label="Buscar"
              className={classes.button}
              disabled={!textoPesquisa}
            />
          </Grid>
          <Grid sm={2} />
          <Grid
            item
            xs={12}
            sm={3}
            display="flex"
            justifyContent={{ sm: "flex-end" }}
          >
            <BotaoFiltroOrdenacao
              type="button"
              color={theme.color.secondaryBorderColor}
              background="transparent"
              label="Filtrar / Ordernar"
              icon={<TuneIcon />}
              className={classes.buttonFiltro}
              ordenacao={listarOrganizarPor()}
              ordenadorUm="Ordenador"
              ordenadorDois="Filtrar"
              ordemSelecionada={ordemSelecionada}
              filtros={listarFiltrarPor()}
              filtrosSelecionados={filtroSelecionado}
              onClickOrdenacao={handleClickOrdemSelecionada}
              onClickFiltro={handleClickFiltroSelecionado}
              onClickAtivarAgora={handleClickAtivarFiltro}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className="mt-4">
        <TabelaPaginada
          onChangeFiltrosTabela={onChangeFiltrosTabela}
          colunas={colunas}
          pesquisar=""
          filtrosAdicionais={filtroAdicionalTabela}
          paginaAtual={1}
          itemsPorPagina={20}
          noHeader
        />
      </Grid>
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={3}>
          <Botao
            type="submit"
            label="Novo Agente"
            className={classes.button}
            onClick={() => history.push(`${RotasDTO.Agentes}/cadastro`)}
          />
        </Grid>
        <Grid item xs={3}>
          <Botao
            type="submit"
            label="Novo Perfil"
            className={classes.button}
            onClick={() => history.push(`${RotasDTO.PerfilAgenteCadastro}`)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Agente;
