import React, { useState, useEffect, useCallback } from "react";
import { Grid } from "@mui/material";
import DataTable from "react-data-table-component";
import Coluna from "componentes/tabelaPaginada/colunas/coluna";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { useStyles, customStyles } from "./style";
import { buscarVigenciasWACC } from "../../../servicos/wacc";

const ValorWACCHistorica = () => {
  const [data] = useState(window.moment().format("YYYY-MM-DD"));
  const [historica, setHistorica] = useState([]);
  const classes = useStyles();

  const obterBandeiras = async (abortController) => {
    try {
      const lista = await buscarVigenciasWACC(data, {
        signal: abortController.signal
      });

      if (lista?.status === 200 && lista?.data) {
        const response = lista?.data.vigenciaHistorica;
        const organizado = [];
        response.map((item) => organizado.push({
          ...item,
          dataInicial: window.moment(item.inicioVigencia).format("DD-MM-YYYY"),
          dataFinal: window.moment(item.finalVigencia).format("DD-MM-YYYY"),
          valor: item.valorWACC.toLocaleString("pt-BR", { style: "percent",  minimumFractionDigits: 2 }),
        }));
        setHistorica(organizado);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",  
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  const colunas = [
    new Coluna("dataInicial", "Data"),
    new Coluna("valor", "Valor"),
  ];

  useEffect(() => {
    const abortController = new AbortController();
    obterBandeiras(abortController);
    return () => {
      abortController.abort();
    }
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={12} className="mt-5">
          <div style={{ display: "flex", height: "100%", width: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              <DataTable
                noHeader
                title="Valores Históricos"
                columns={colunas}
                data={historica}
                theme="DeltaEnergia"
                customStyles={customStyles}
                noDataComponent="Nenhum registro encontrado"
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default ValorWACCHistorica;