import { buscaPerfilPorEmail } from "../../../servicos/usuariosServico";
import {
  buscarPorId,
  listarOfficersPorArvoreGerencial
} from "../../../servicos/colaboradoresServico";
import RetornoEndpointDto from "../../../global/dto/retornoEndpointDto";
import { store } from "../../../global/redux";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";
import RadioDto from "../../../componentes/inputRadioGroup/radioDto";
import {
  digitalizacaoFaturaDeltaProcessamentoOcr,
  obterFaturaDeltaProcessamentoOcr
} from "../../../servicos/deltaProcessamentoOcrServico";
import {
  buscarDistribuidoras,
  buscarDistribuidoraNomeOuCnpj
} from "../../../servicos/distribuidorasServico";
import {
  buscarNivelTensao,
  buscarNumeroFases
} from "../../../servicos/unidadesServico";
import { obterCepCobertura } from "../../../servicos/correiosServico";
import { obterMatrizDescontoGdBaixa } from "../../../servicos/matrizDescontoGdBaixaServico";
import { criarLeadSalesForceServico } from "../../../servicos/preLeadSalesforceServico";
import { obterArvoreGerencialAltoNivel } from "../../../servicos/arvoresGerenciaisServico";
import { obterTodos } from "../../../servicos/tipoPlanoCotacaoServico";
import { getByCpfCnpj } from "../../../servicos/boletasGDServico";

export default class CotacaoGDHelper {
  static async obterOfficerArvore(empresa, setCarregandoOfficer, setError) {
    try {
      setCarregandoOfficer(true);
      const resultado = await listarOfficersPorArvoreGerencial(empresa ?? 0);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Officers não encontrados!");

      return RetornoEndpointDto.Sucesso(
        "Officers obtido com sucesso",
        resultado.data
      );
    } catch (error) {
      setError(true);
      return RetornoEndpointDto.Erro(
        `Officers não encontrados empresa ${empresa}`
      );
    } finally {
      setCarregandoOfficer(false);
    }
  }

  static async obterColaboradorId(colaboradorId, setError) {
    try {
      const resultado = await buscarPorId(colaboradorId ?? 0);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Colaborador por id não encontrados!");

      return RetornoEndpointDto.Sucesso(
        "Colaborador por id obtido com sucesso",
        resultado.data
      );
    } catch (error) {
      setError(true);
      return RetornoEndpointDto.Erro(
        `Não foi  encontrado colaborador id ${colaboradorId}`
      );
    }
  }

  static async digitalizacaoFaturaDeltaProcessamentoOcr(
    fileBytes,
    fileName,
    externoId,
    empresaCodigo,
    setCarregandoArquivo,
    setError
  ) {
    try {
      setCarregandoArquivo(true);
      const resultado = await digitalizacaoFaturaDeltaProcessamentoOcr(
        fileBytes,
        fileName,
        externoId,
        empresaCodigo
      );
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Erro ao efetuar upload!");

      const respostaFatura = await new Promise((resolve) => {
        const intervalId = setInterval(async () => {
          try {
            const res = await obterFaturaDeltaProcessamentoOcr(
              resultado.data.resultObject.item2
            );

            if (res.status !== 202) {
              clearInterval(intervalId);
              resolve(res);
            }
          } catch (error) {
            clearInterval(intervalId);
            resolve(error);
          }
        }, 5000);
      });

      if (
        respostaFatura?.data?.statusCodes === 200 &&
        respostaFatura?.data?.success
      ) {
        return RetornoEndpointDto.Sucesso(
          "Sucesso ao efetuar upload!",
          respostaFatura?.data
        );
      }
      setError(true);
      return RetornoEndpointDto.Erro(
        `Erro ao fazer upload do arquivo ${fileName}`
      );
    } catch (error) {
      setError(true);
      return RetornoEndpointDto.Erro(
        `Erro ao fazer upload do arquivo ${fileName}`
      );
    } finally {
      setCarregandoArquivo(false);
    }
  }

  static obterTipoCliente() {
    return [
      new RadioDto("Pessoa Física", "PF"),
      new RadioDto("Pessoa Jurídica", "PJ")
    ];
  }

  static obterTipoPlano() {
    return [new RadioDto("Padrão", "PD"), new RadioDto("Personalizado", "PS")];
  }

  static async obterDistribuidora(setCarregandoDistribuidora, setError) {
    try {
      setCarregandoDistribuidora(true);
      const resultado = await buscarDistribuidoras();
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Distribuidora não encontrados!");

      return RetornoEndpointDto.Sucesso(
        "Distribuidora obtido com sucesso",
        resultado.data
      );
    } catch (error) {
      setError(true);
      return RetornoEndpointDto.Erro("Distribuidora não encontrados!");
    } finally {
      setCarregandoDistribuidora(false);
    }
  }

  static async obterNumerosFases(setCarregandoFases, setError) {
    try {
      setCarregandoFases(true);
      const resultado = await buscarNumeroFases();
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Números de fases não encontrados!");

      return RetornoEndpointDto.Sucesso(
        "Números de fases obtido com sucesso",
        resultado.data
      );
    } catch (error) {
      setError(true);
      return RetornoEndpointDto.Erro("Números de fases não encontrados!");
    } finally {
      setCarregandoFases(false);
    }
  }

  static async obterNivelTensao(setCarregandoNivelTensao, setError) {
    try {
      setCarregandoNivelTensao(true);
      const resultado = await buscarNivelTensao();
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Níveis de tensão não encontrados!");

      return RetornoEndpointDto.Sucesso(
        "Níveis de tensão obtido com sucesso",
        resultado.data
      );
    } catch (error) {
      setError(true);
      return RetornoEndpointDto.Erro("Níveis de tensão não encontrados!");
    } finally {
      setCarregandoNivelTensao(false);
    }
  }

  static async obterDistribuidoraCnpjOuNome(cnpj, setError) {
    try {
      const resultado = await buscarDistribuidoraNomeOuCnpj(cnpj);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro(
          "Obter distribuídora única não encontrados!"
        );

      return RetornoEndpointDto.Sucesso(
        "Obter distribuídora única com sucesso",
        resultado.data
      );
    } catch (error) {
      setError(true);
      return RetornoEndpointDto.Erro(
        "Obter distribuídora única não encontrados!"
      );
    }
  }

  static async obterCepCobertura(cepCobertura, setCarregandoCep, setError) {
    try {
      setCarregandoCep(true);
      const resultado = await obterCepCobertura(cepCobertura);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro(
          "Obter cobertura única não encontrados!"
        );

      return RetornoEndpointDto.Sucesso(
        "Obter cobertura única com sucesso",
        resultado.data
      );
    } catch (error) {
      setError(true);
      return RetornoEndpointDto.Erro("Obter cobertura única não encontrados!");
    } finally {
      setCarregandoCep(false);
    }
  }

  static async obterMatrizDescontoGdBaixa(
    params,
    setCarregandoMatriz,
    setError
  ) {
    try {
      setCarregandoMatriz(true);
      const resultado = await obterMatrizDescontoGdBaixa(params);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro(
          "Obter cobertura única não encontrados!"
        );

      return RetornoEndpointDto.Sucesso(
        "Obter cobertura única com sucesso",
        resultado.data
      );
    } catch (error) {
      setError(true);
      return RetornoEndpointDto.Erro("Obter cobertura única não encontrados!");
    } finally {
      setCarregandoMatriz(false);
    }
  }

  static async criarLeadSalesForceServico(
    params,
    setCarregandoMatriz,
    setError
  ) {
    try {
      setCarregandoMatriz(true);
      const resultado = await criarLeadSalesForceServico(params);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Lead sales force não encontrados!");

      return RetornoEndpointDto.Sucesso(
        "Lead sales force criado com sucesso",
        resultado.data
      );
    } catch (error) {
      setError(true);
      return RetornoEndpointDto.Erro("Lead sales force não encontrados!");
    } finally {
      setCarregandoMatriz(false);
    }
  }

  static async obterArvoreGerencialAltoNivel(
    colaboradorId,
    empresaId,
    setCarregandoDistribuidora,
    setError
  ) {
    try {
      setCarregandoDistribuidora(true);
      const resultado = await obterArvoreGerencialAltoNivel(
        colaboradorId,
        empresaId
      );
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Árvore gerencial não encontrados!");

      return RetornoEndpointDto.Sucesso(
        "Árvore gerencial obtido com sucesso",
        resultado.data
      );
    } catch (error) {
      setError(true);
      return RetornoEndpointDto.Erro("Árvore gerencial não encontrados!");
    } finally {
      setCarregandoDistribuidora(false);
    }
  }

  static async obterTodosPlanos() {
    try {
      const resultado = await obterTodos();
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("PLanos não encontrados!");

      return RetornoEndpointDto.Sucesso(
        "PLanos obtido com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Planos não encontrados!");
    }
  }

  static async obterCpfCnpj(id, setParceiroEncontrado) {
    try {
      const resultado = await getByCpfCnpj(id);
      if (resultado.status === 204) {
        setParceiroEncontrado(false);
        return RetornoEndpointDto.Erro("204");
      }

      return RetornoEndpointDto.Sucesso(
        "Boleta GD obtido com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Erro ao encontrar Boleta GD!");
    }
  }

  static async buscaPerfilPorEmail(email) {
    try {
      const resultado = await buscaPerfilPorEmail(email);
      if (resultado.status === 204) return RetornoEndpointDto.Erro("204");
      return RetornoEndpointDto.Sucesso(
        "Perfil do usuário encontrado, com sucesso",
        resultado.data
      );
    } catch (error) {
      if (error?.response?.status === 409) {
        return RetornoEndpointDto.Erro("existsColaborador");
      }

      return RetornoEndpointDto.Erro("error");
    }
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }

  static exibirSucesso(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem
      })
    );
  }
}
