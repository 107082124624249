import MICROSERVICO from "global/enumeradores/microServico";
import obterApi from "servicos/api";

const api = () => obterApi(MICROSERVICO.PROJECAOTARIFARIA);

export const obterProjecaoInsumosImportacoes = () => {
  return api().get(`v1/ProjecaoInsumo/getall`);
}

export const obterHistoricoImportacoesPorIdInsumo = async (idProjecaoInsumo) => {
  return api().get(`v1/ProjecaoInsumo/${idProjecaoInsumo}`);
}

export const obterInsumosComRecorte = () => {
  return api().get(`v1/ProjecaoInsumo/getProjecaoInsumoComRecorte`);
}

export const obterRecortesPorInsumo = (idProjecaoInsumo) => {
  return api().get(`v1/ProjInsumoRecorte/getByID?idProjecaoInsumo=${idProjecaoInsumo}`);
}

export const obterRecortePorCodigo = (pCodigoInsumoRecorte) => {
  return api().get(`v1/ProjInsumoRecorte/getByCode?codigo=${pCodigoInsumoRecorte}`);
}

export const salvarRecorte = (data) => {
  return api().put("v1/ProjInsumoRecorte", data);
}

export const salvarRecorteItem = (data) => {
  return api().put("v1/ProjInsRecorteItem", data);
}

export const obterRecorteItemPorCodigo = (codigo) => {
  return api().get(`v1/ProjInsRecorteItem/getByCode?codigo=${codigo}`);
}

export const obterRecorteItemsPorCodigoInsumoRecorte = (codigoInsumoRecorte) => {
  return api().get(`v1/ProjInsRecorteItem/getByCodigoInsumoRecorte?codigoInsumoRecorte=${codigoInsumoRecorte}`);
}

export const obterRecorteItemsRefPorCodigoRecorteItem = (codigoRecorteItem) => {
  return api().get(`v1/ProjInsRecorteItemRef/getByCode?codigoRecorteItem=${codigoRecorteItem}`);
}

export const listarFiltrarPor = () => {
  const filtros = [
    {
      key: 1,
      label: "Sucesso"
    },
    {
      key: 0,
      label: "Falha"
    }
  ];

  return filtros;
};

export const listarFiltrarRecortesPor = () => {
  const filtros = [
    {
      key: "0",
      label: "Recorte"
    }
  ];

  return filtros;
};