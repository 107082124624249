/* eslint-disable import/no-unresolved */
/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-syntax */
import React, { useState } from "react";
import { Card, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import UploadDropArea from "componentes/upload";
import Botao from "componentes/botao";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import ResultadoOperacao from "componentes/resultadoOperacao";
import { useSelector } from "react-redux";
import { uploadRateioCDE } from "../../../../servicos/rateiocde";
import { useStyles } from "./style";
import CloseIcon from "@mui/icons-material/Close";
import RotasDTO  from "../../../../rotas/rotasUrlDto";  
import { useHistory } from "react-router-dom";

import { importPing } from "../../../../utils/import-ping"
import { geraURLUpload } from "../../../../servicos/awsUtils";
import AWSS3UploadAsh from 'aws-s3-upload-ash';

const S3CustomClient = new AWSS3UploadAsh();

const RateioCDEImportar = () => {
  const history = useHistory();
  const classes = useStyles();
  const usuarioGlobal = useSelector((state) => state.usuario);
  const msgPadraoUpload = "Solte o arquivo para anexá-lo ou procurar";

  const { handleSubmit } = useForm({
    reValidateMode: "onSubmit",
  });
  const tituloCabecalhoRetorno = "Cotas Rateio CDE - Importação do arquivo";
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(msgPadraoUpload);
  const [carregandoArquivo, setCarregandoArquivo] = useState(false);
  const [progressMesage, setProgressMessage] = useState("");
  const [resultadoOperacao, setResultadoOperacao] = useState(false);
  const [resultadoOperacaoCabecalho, setResultadoOperacaoCabecalho] = useState("");
  const [resultadoOperacaoObj, setResultadoOperacaoObj] = useState({
    "mensagens": []
  });

  const processarArquivo = (arquivo) => {
    if (arquivo != null && arquivo[0] != null) {
      setFile(arquivo[0]);
      setFileName(arquivo[0].file.name);
    }
  };

  const onAddUpload = (arquivo) => {
    processarArquivo(arquivo)
  };

  const onCancelar = () => {
    setFile(false);
    setFileName(msgPadraoUpload);
    setCarregandoArquivo(false);
    setResultadoOperacao(false);
    setResultadoOperacaoCabecalho("");
    setResultadoOperacaoObj({
      "mensagens": []
    });
  }

  const EnviarForm = async () => {
    try {

      setCarregandoArquivo(true);
      setResultadoOperacao(false);
      setProgressMessage("Importando o arquivo... Por favor, aguarde.");

      console.log('file.file', file.file)
      const keyOnS3 = file.file.name;
      const urlPreSigned = await geraURLUpload(keyOnS3)
      const uploadS3Response = await S3CustomClient.uploadFile(file.file, file.file.type, urlPreSigned.data);
        
      console.log('urlPreSigned', urlPreSigned.data)
      console.log('uploadS3Response', uploadS3Response)

      const upload = await uploadRateioCDE(usuarioGlobal?.usuario?.id, usuarioGlobal?.usuario?.usuarioNome, keyOnS3);
      if ((upload.status === 200 || upload.status === 202) && upload?.data) {
        const datetimeImportStarted = upload.data.DataHoraRequisicao
        const urlParams = new URLSearchParams(window.location.search);
        let idInsumo = urlParams.get('id')
        let codigoInsumo = urlParams.get('codigo')
        await importPing(idInsumo, datetimeImportStarted, usuarioGlobal?.usuario.id, codigoInsumo, (status, message) => {
          let mensagemRetorno = ["Processo de Importação Finalizado.", status, message];
          setFile(false);
          setFileName(msgPadraoUpload);
          setCarregandoArquivo(false);
          setResultadoOperacao(true);
          setResultadoOperacaoCabecalho(`${tituloCabecalhoRetorno} ${keyOnS3}`)
          setResultadoOperacaoObj({ "mensagens": [mensagemRetorno] });
        })
      }
    } catch (erro) {
      setFile(false);
      setFileName(msgPadraoUpload);
      setCarregandoArquivo(false);
      setResultadoOperacao(true);
      setResultadoOperacaoCabecalho(`${tituloCabecalhoRetorno} ${file?.file?.name}`)
      setResultadoOperacaoObj({ "mensagens": [erro.message ?? "Ocorreu um erro desconhecido"] });
      console.info(erro);
    }
  }

  const aoEnviarForm = () => {

    if (!file) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Por favor, selecione o arquivo desejado."
        })
      )
    } else {
      EnviarForm();
    }
  }
  const onFechar = (id) => {
    history.push(`${RotasDTO.ProjecaoInsumoListar}`);
  }
  return (
    <form className="needs-validation" onSubmit={handleSubmit(aoEnviarForm)}>
      <ResultadoOperacao
        item={resultadoOperacaoObj}
        cabecalhoResultado={resultadoOperacaoCabecalho}
        onClose={() => onCancelar()}
      />
      {resultadoOperacao === false ?
        <Card className={classes.container}>
          <Grid container className={classes.containerTitulo}>
            <Grid item xs={11} className="font-weight-bold">
              Importação de Cotas de Rateio CDE
            </Grid>
            <Grid item xs={1} >             
              <CloseIcon onClick={onFechar} className={classes.icon}/>
            </Grid>   
          </Grid>
          {carregandoArquivo === false ?
            <Card className={classes.container}>
              <Grid container spacing={4} p={2} className={classes.container}>
                <Grid item sm={12} className={classes.fileUpload}>
                  <UploadDropArea
                    onAdd={onAddUpload}
                    dropAreaText={fileName}
                    showLoader={false}
                    acceptedFiles={[
                      ".xlsm,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                    ]}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4} p={2} className={classes.container} justifyContent="space-between">
                <Grid item sm={3}>
                  <Botao
                    type="button"
                    label="Cancelar"
                    onClick={onCancelar}
                    className={classes.customButton}
                  />
                </Grid>
                <Grid item sm={3}>
                  <Botao
                    type="submit"
                    label="Importar"
                    className={classes.button}
                  />
                </Grid>
              </Grid>
            </Card>
            :
            <Card className={classes.container}>
              <Grid container spacing={4} p={2} className={classes.container}>
                <Grid item sm={12} className={classes.fileUpload}>
                  <Grid item sm={12} style={{ textAlign: "center" }}>
                    {progressMesage}
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          }
        </Card>
        : ""
      }
    </form>
  );
}

export default RateioCDEImportar;