import styled from "styled-components";

export const Wrapper = styled.div`
  width: inherit;

  .spinner-border {
    left: 50%;
    margin-left: -15px;
    margin-top: -15px;
    top: 50%;
    z-index: 1;
  }

  ${(props) =>
    props.loading === "true" &&
    `
    .children {
        clear: both;
        opacity: 0.5;
        overflow: hidden;
        pointer-events: none;
        position: relative;
        user-select: none;
    }
  `}
`;
