import { makeStyles } from "@mui/styles";
import { Base } from "../../../../../componentes/cores";
import theme from "../../../../../themes";

export const useStyles = makeStyles(() => ({
  card: {
    padding: "10px",
    backgroundColor: Base.NovoBGMenuHuver,
    height: "auto"
  },
  tituloCard: {
    fontWeight: 700,
    fontFamily: theme.font.family,
    fontSize: "20px",
    color: theme.color.secondary
  },
  subTituloCard: {
    display: "flex",
    fontFamily: theme.fontTahoma.family,
    fontSize: "16px",
    alignItems: "center",
    marginBottom: "10px",
    "& p": {
      margin: 0,
      fontFamily: theme.fontTahoma.family
    }
  }
}));
