import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

// Form Hooks
import { useForm } from "react-hook-form";

// Redux
import { useSelector } from "react-redux";

// Componentes
import { Card, Grid } from "@mui/material";
import { InterfaceDTO } from "global/dto/interfacesDto";
import { usuarioPossuiFuncionalidade } from "servicos/funcionalidadesServico";
import Campos from "../componentes/campos";
import Loader from "../../../../componentes/loader";
import Botao from "../../../../componentes/botao";
import BotaoRetornarListagem from "../../../../componentes/botaoRetornarListagem";

// Redux
import { store } from "../../../../global/redux";
import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";

// Styles
import { useStyles } from "./style";

// Rotas
import { RotasDTO } from "../../../../global/rotas/rotasUrlDto";

// Serviços
import {
  salvarMedidor,
  buscarPorId
} from "../../../../servicos/medidoresServico";
import { buscarPorId as buscarClientePorId } from "../../../../servicos/clientesServico";
import { buscarPorId as buscarEmpresaPorId } from "../../../../servicos/empresaServico";
import {
  selecionarCliente,
  selecionarEmpresa,
  selecionarUnidade
} from "../../../../global/redux/modulos/usuario/actions";
import { validacaoTrimObject } from "../../../../servicos/utils";

const MedidoresCadastro = () => {
  const { id } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const [dadosMedidor, setDadosMedidor] = useState();
  const [
    carregandoCadastroMedidores,
    setcarregandoCadastroMedidores
  ] = useState(false);

  const { register, handleSubmit, errors } = useForm();

  const usuario = useSelector((state) => state.usuario);

  const obterMedidor = useCallback(
    async (idMedidor) => {
      try {
        const medidor = await buscarPorId(idMedidor);
        if (medidor?.status === 200 && medidor?.data) {
          setDadosMedidor(medidor?.data);
          store.dispatch(selecionarEmpresa(medidor?.data?.empresaGrupoDeltaId));
          if (usuario.empresaSelecionada)
            store.dispatch(selecionarCliente(medidor?.data?.clienteId));
          if (usuario.clienteSelecionado)
            store.dispatch(
              selecionarUnidade(medidor?.data?.unidadeConsumidoraId)
            );
        }
      } catch (error) {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem:
              error?.response?.data?.message ??
              "Erro interno, entre em contato com o suporte!"
          })
        );
      }
    },
    [usuario]
  );

  useEffect(() => {
    if (id) obterMedidor(id);
  }, [id, obterMedidor]);

  const enviarFormulario = async (dados) => {
    try {
      if (!usuario?.unidadeSelecionada) {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "É necessário selecionar uma Unidade Consumidora"
          })
        );
      } else {
        dados.id = id;
        if (!("swMedidorAtivo" in dados)) dados.swMedidorAtivo = true;
        const salvou = await salvarMedidor(validacaoTrimObject(dados), usuario);

        if (salvou) {
          store.dispatch(
            alertaExibir({
              tipo: "success",
              mensagem: "O medidor foi salvo com sucesso!"
            })
          );
          setTimeout(() => {
            history.push({
              pathname: RotasDTO.Medidores,
              state: { dadosMedidor: salvou?.data }
            });
            store.dispatch(selecionarEmpresa(null));
            store.dispatch(selecionarCliente(null));
            store.dispatch(selecionarUnidade(null));
          }, 2000);
        }
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
    setcarregandoCadastroMedidores(false);
  };

  const aoEnviarFormulario = (dados) => {
    setcarregandoCadastroMedidores(true);
    enviarFormulario(dados);
  };

  const [empresaUnidade, setEmpresaUnidade] = useState();
  const [clienteUnidade, setClienteUnidade] = useState();

  // Nome da empresa selecionada
  const obterEmpresa = useCallback(async () => {
    if (dadosMedidor?.empresaGrupoDeltaId) {
      try {
        const empresa = await buscarEmpresaPorId(
          dadosMedidor?.empresaGrupoDeltaId
        );

        if (empresa?.data) {
          setEmpresaUnidade(empresa?.data);
        }
      } catch (error) {
        console.info(error);
      }
    }
  }, [dadosMedidor]);

  useEffect(() => {
    obterEmpresa();
  }, [obterEmpresa]);

  const nomeEmpresa = useMemo(() => {
    if (empresaUnidade?.nomeFantasia) return empresaUnidade?.nomeFantasia;

    if (usuario?.empresas) {
      const empresa = usuario.empresas.find(
        (item) => String(item?.id) === String(usuario?.empresaSelecionada)
      );

      return empresa?.nomeFantasia ?? "";
    }
  }, [usuario?.empresaSelecionada, dadosMedidor, empresaUnidade]);

  // Nome da cliente selecionado
  const obterCliente = useCallback(async () => {
    if (dadosMedidor?.clienteId) {
      try {
        const cliente = await buscarClientePorId(dadosMedidor?.clienteId);

        if (cliente?.data) {
          setClienteUnidade(cliente?.data);
        }
      } catch (error) {
        console.info(error);
      }
    }
  }, [dadosMedidor]);

  useEffect(() => {
    obterCliente();
  }, [obterCliente]);

  const nomeCliente = useMemo(() => {
    let cliente;

    if (dadosMedidor?.clienteId && usuario?.clientes) {
      cliente = usuario?.clientes.find(
        (item) => String(item?.id) === String(dadosMedidor?.clienteId)
      );

      if (!cliente && clienteUnidade) return clienteUnidade?.nomeFantasia ?? "";
    } else {
      cliente = usuario?.clientes.find(
        (item) => String(item?.id) === String(usuario?.clienteSelecionado)
      );
    }

    return cliente?.nomeFantasia ?? "";
  }, [usuario?.clienteSelecionado, dadosMedidor, clienteUnidade]);

  // Nome da Unidade selecionada
  const nomeUnidade = useMemo(() => {
    let unidade;

    if (dadosMedidor?.unidadeConsumidoraId && usuario?.unidades) {
      unidade = usuario.unidades.find(
        (item) =>
          String(item?.id) === String(dadosMedidor?.unidadeConsumidoraId)
      );
    } else if (usuario?.unidadeSelecionada && usuario?.unidades) {
      unidade = usuario.unidades.find(
        (item) => String(item?.id) === String(usuario?.unidadeSelecionada)
      );
    }

    return unidade?.nomeUnidade ?? "";
  }, [
    dadosMedidor?.unidadeConsumidoraId,
    usuario?.unidades,
    usuario?.unidadeSelecionada
  ]);

  const { menu } = usuario;
  const rotas = useSelector((state) => state.rotas);
  const podeEditarCampos = usuarioPossuiFuncionalidade(
    menu,
    rotas.rotaAtual.caminho,
    RotasDTO.Medidores,
    InterfaceDTO.PodeEditar
  );

  return (
    <form
      className="needs-validation"
      onSubmit={handleSubmit(aoEnviarFormulario)}
    >
      <Card className={classes.cardCadastro}>
        <Grid container p={2} spacing={4} className={classes.container}>
          <Grid item xs={6} className="font-weight-bold">
            {dadosMedidor?.id && nomeEmpresa
              ? "Editar medidor"
              : "Novo medidor"}
            {nomeEmpresa !== "" ? ` na empresa ${nomeEmpresa}` : ""}
            {nomeCliente !== "" ? ` e cliente ${nomeCliente}` : ""}
            {nomeUnidade !== "" ? ` e unidade ${nomeUnidade}` : ""}
          </Grid>
          <BotaoRetornarListagem
            urlListagem={{
              pathname: RotasDTO.Medidores,
              state: { dadosMedidor }
            }}
            zerarCombo
          />
        </Grid>

        <Campos
          register={register}
          errors={errors}
          medidor={dadosMedidor}
          podeEditarCampos={podeEditarCampos}
        />
        <Grid container p={2} spacing={4} className={classes.container}>
          <Grid item lg={12}>
            <Grid item lg={3} md={4} sm={12}>
              <Loader loading={carregandoCadastroMedidores}>
                <Botao
                  type="submit"
                  label="Salvar"
                  disabled={!podeEditarCampos}
                  className={classes.button}
                />
              </Loader>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </form>
  );
};

export default MedidoresCadastro;
