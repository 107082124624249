import React, { useState, useEffect } from 'react';
import { Grid, Card } from '@mui/material';
import { useStyles, Tabela } from './style';
import { obterValoresBaseRateioEER } from '../../../../../servicos/premissasBrasil';
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import CloseIcon from "@mui/icons-material/Close";
import RotasDTO  from "../../../../../rotas/rotasUrlDto";
import { useHistory } from "react-router-dom";

const BaseRateioEERVisualizar = () => {
  const classes = useStyles();
  const history = useHistory();

  const onFechar = (id) => {
     history.push(`${RotasDTO.ProjecaoInsumoListar}`);
   }
   
  const [valores, setValores] = useState([]);

  const obterValores = async () => {
    try {
      const response = await obterValoresBaseRateioEER();
      if (response.status === 200 && response.data) {
        setValores(response?.data);
      } else {
        setValores([]);
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "Não há dados para impressão com os parâmetros informados."
          })
        );
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  useEffect(() => {
    if (!valores.length) obterValores();
  }, [!valores.length, obterValores]);

  return (
    <Grid container p={2} spacing={4} className={classes.container}>
       <Grid container className={classes.containerTitulo}>
          <Grid item xs={11} className={classes.titulo}>
            Base de Rateio EER
          </Grid>
          <Grid item xs={1} >             
            <CloseIcon onClick={onFechar} className={classes.icon}/>
          </Grid>   
        </Grid>
      <Grid item sm={12}>
        <Card className={classes.container}>
          <Grid container p={2} spacing={4} className={classes.container}>
            {valores.length > 0 ?
              <Grid item sm={12}>
                <div className={classes.customPosition}>
                  <Tabela>
                    <Tabela.Thead>
                      <Tabela.Tr>
                        <Tabela.Th colSpan="4">BASE DE RATEIO EER</Tabela.Th>
                      </Tabela.Tr>
                      <Tabela.Tr>
                        <Tabela.Th>DISTRIBUIDORA</Tabela.Th>
                        <Tabela.Th>ANO</Tabela.Th>
                        <Tabela.Th>CUSTO</Tabela.Th>
                        <Tabela.Th>RATEIO</Tabela.Th>
                      </Tabela.Tr>
                    </Tabela.Thead>
                    <Tabela.Tbody>
                      {valores?.map((item) => (
                        <Tabela.Tr>
                          <Tabela.Td>{item.NomeFantasiaDistr}</Tabela.Td>
                          <Tabela.Td>{item.Ano}</Tabela.Td>
                          <Tabela.Td>{item.Custo.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 })}</Tabela.Td>
                          <Tabela.Td>{item.Rateio.toLocaleString("pt-BR", { style: "percent", minimumFractionDigits: 2 })}</Tabela.Td>
                        </Tabela.Tr>
                      ))}
                    </Tabela.Tbody>
                  </Tabela>
                </div>
              </Grid>
              : ""}
          </Grid>
        </Card>
      </Grid>
    </Grid>
  )
}

export default BaseRateioEERVisualizar;
