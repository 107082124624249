import { makeStyles } from "@mui/styles";
import styled from "styled-components";
import { createTheme } from "@mui/material/styles";

const theme = createTheme();

export const useStyles = makeStyles({
  container: {
    backgroundColor: "#FFFFFF",
    border: "0 none",
    borderRadius: "10px",
    left: "50%",
    outline: "none",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    top: "50%",
    width: "50% !important",
    color: "#FFFFFF",
    textAlign: "center",
    height: "80%",
    [theme.breakpoints.down("sm")]: {
      width: "98% !important"
    }
  },
  conteudo: {
    position: "relative",
    width: "100%",
    paddingTop: "5px",
    height: "100%"
  },
  button: {
    backgroundColor: "#FFFFFF",
    position: "absolute",
    right: 0,
    border: "none",
    zIndex: 1,
    margin: "5px"
  }
});

export const Iframe = styled.embed`
  width: 100%;
  padding: 5px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  img {
    height: auto
  }
`;

export const Icone = styled.img`
  max-width: 30px;
  height: 30px;
`;
