export const obterHistoricoFatura = async () => {
  return [
    {
      data: "Jan/2021",
      consumo: "254.588,00",
      data2: "Out/2020",
      consumo2: "265.656,30",
      ativo: true
    },
    {
      data: "Dez/2020",
      consumo: "277.215,50",
      data2: "Set/2020",
      consumo2: "265.980,50",
      ativo: true
    },
    {
      data: "Nov/2020",
      consumo: "254.080,70",
      data2: "Ago/2020",
      consumo2: "246.090,20",
      ativo: true
    }
  ];
};
