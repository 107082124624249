import produce from "immer";

const inicial = {
  modoEnvio: null
};

export default function workflow(state = inicial, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "@workflow/SetarEnvioAssinatura":
        draft.modoEnvio = action.payload;
        break;
      default:
        draft = inicial;
        break;
    }
  });
}
