import { makeStyles } from "@mui/styles";
import styled from "styled-components";
import _theme from "themes";
import { createTheme } from "@mui/material/styles";

const theme = createTheme();

export const useStyles = makeStyles(() => ({
  topo: {
    padding: theme.spacing(2),
    "& > div + div": {
      paddingLeft: theme.spacing(2),
    },
    "& img": {
      maxWidth: "100%",
      textAlign: "center"
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(4, 1, 4, 2),
    }
  },
  info: {
    fontSize: "18px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.1px",
    // whiteSpace: "nowrap",
    [theme.breakpoints.up("md")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "18px",
    },
  },
  dados: {
    fontFamily: _theme.font.family,
    fontSize: "50px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    "& span": {
      fontSize: "20px"
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "24px"
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "24px"
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "38px"
    }
  },
  icone: {
    margin: "4px 35px 4px 0"
  },
  card: {
    height: "auto!important",
    // marginBottom: theme.spacing(2),
    // padding: "30px 40px"
  },
  trace: {
    borderBottomColor: _theme.color.traceMedidorHoras,
    borderBottomStyle: "groove",
    borderBottomWidth: "6px"
  },
  iconeWrapper: {
    textAlign: "center"
  }
}));

export const Icone = styled.img`
  max-height: 65px;
  object-fit: cover;
`;
