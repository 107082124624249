import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  WhatsappShareButton,
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
  FacebookMessengerShareButton
} from "react-share";

// Componentes
import { Grid, Card, Box } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Loader from "componentes/loader";
import {
  obterClienteCupomIndicadorPorCliente,
  obterCreditoIndicadorPorCliente
} from "servicos/memberGetMemberServico";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { RotasDTO } from "global/rotas/rotasUrlDto";
import formatarValor from "global/utils/formatarValor";
import WhatsApp from "assets/home/whatsapp.png";
import Facebook from "assets/home/facebook.png";
import Twitter from "assets/home/twitter.png";
import Email from "assets/home/email.png";
import Mesenger from "assets/home/mesenger.png";
import Comofunciona from "assets/home/como-funciona.png";
import Modal from "../Modal";
import SemDados from "../SemDados";

// Styles
import {
  useStyles,
  Botao,
  BotaoTransparent,
  IconeCompartilhar,
  BotaoCopiar
} from "./style";

const IndiqueGanhe = ({ cliente }) => {
  const classes = useStyles();
  const [dadosCupom, setDadosCupom] = useState([]);
  const [carregando, setCarregando] = useState(false);
  const [modalCompartilhar, setModalCompartilhar] = useState(false);
  const [modalComoFunciona, setModalComoFunciona] = useState(false);
  const [indicados, setIndicados] = useState(0);

  const copiarCupom = (texto, isUrl = false) => {
    if (!texto) return;
    const textField = document.createElement("textarea");
    textField.innerText = texto;
    document.body.appendChild(textField);
    textField.select();
    if (navigator?.clipboard) {
      navigator.clipboard.writeText(texto);
    } else {
      document.execCommand("copy");
    }
    if (isUrl) {
      store.dispatch(
        alertaExibir({
          tipo: "success",
          mensagem: "Link copiado com sucesso!"
        })
      );
    }
    if (!isUrl) {
      store.dispatch(
        alertaExibir({
          tipo: "success",
          mensagem: "Cupom copiado com sucesso!"
        })
      );
    }

    textField.remove();
  };

  const obterClienteCupomIndicador = async () => {
    try {
      setCarregando(true);
      const { data } = await obterClienteCupomIndicadorPorCliente(cliente);
      const response = await obterCreditoIndicadorPorCliente(cliente);

      if (response?.data[0]?.quantidade)
        setIndicados(response.data[0].quantidade);

      if (data) setDadosCupom(data);
      else setDadosCupom([]);
    } catch (error) {
      setDadosCupom([]);
    } finally {
      setCarregando(false);
    }
  };

  useEffect(() => {
    if (cliente) obterClienteCupomIndicador();
  }, [cliente]);

  const conteudoComoFunciona = () => (
    <Box className={classes.comoFunciona}>
      <Box pb={2}>
        Para garantir o benefício, compartilhe o link ou envie o{" "}
        <strong>código</strong> que deverá ser inserido no simulador do nosso
        site. Veja o exemplo:
      </Box>
      <IconeCompartilhar src={Comofunciona} alt="Imagem como funciona" />
    </Box>
  );

  const conteudoCompartilhar = () => (
    <Box>
      <Box className={classes.boxCompartilhar}>
        <WhatsappShareButton
          className={classes.botaoCompartilhar}
          url={dadosCupom[0]?.url}
        >
          <IconeCompartilhar src={WhatsApp} alt="Ícone whatsapp" />
        </WhatsappShareButton>
        <FacebookShareButton
          className={classes.botaoCompartilhar}
          url={dadosCupom[0]?.url}
        >
          <IconeCompartilhar src={Facebook} alt="Ícone Facebook" />
        </FacebookShareButton>
        <TwitterShareButton
          className={classes.botaoCompartilhar}
          url={dadosCupom[0]?.url}
        >
          <IconeCompartilhar src={Twitter} alt="Ícone Twitter" />
        </TwitterShareButton>
        <EmailShareButton
          className={classes.botaoCompartilhar}
          url={dadosCupom[0]?.url}
        >
          <IconeCompartilhar src={Email} alt="Ícone E-mail" />
        </EmailShareButton>
        <FacebookMessengerShareButton
          className={classes.botaoCompartilhar}
          url={dadosCupom[0]?.url}
        >
          <IconeCompartilhar src={Mesenger} alt="Ícone Mesenger" />
        </FacebookMessengerShareButton>
      </Box>
    </Box>
  );

  const conteudoCompartilharFooter = () => (
    <Box className={classes.compartilharFooter}>
      <BotaoCopiar onClick={() => copiarCupom(dadosCupom[0]?.url, true)}>
        <LinkIcon
          sx={{
            marginRight: "10px",
            height: "1.2em",
            width: "1.2em"
          }}
        />
        Copiar link
        <ContentCopyIcon
          sx={{
            height: "1.2em",
            width: "1.2em"
          }}
        />
      </BotaoCopiar>
    </Box>
  );

  return (
    <Loader loading={carregando}>
      <Modal
        item={modalCompartilhar}
        titulo="Compartilhe seu link"
        mensagem={conteudoCompartilhar()}
        footer={conteudoCompartilharFooter()}
        onCancelar={() => setModalCompartilhar(false)}
      />
      <Modal
        item={modalComoFunciona}
        titulo="Como funciona:"
        mensagem={conteudoComoFunciona()}
        footer={false}
        onCancelar={() => setModalComoFunciona(false)}
      />
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: { xs: "space-between" },
            alignItems: { xs: "center" },
            background: "#FBFBFD"
          }}
          p={2}
        >
          <h3 className={classes.titulo}>Indique e ganhe</h3>
          <Link to={RotasDTO.ConvideGanhe}>
            <Botao>Ver detalhes</Botao>
          </Link>
        </Grid>
        {dadosCupom.length > 0 ? (
          <>
            <Grid item xs={12} md={6} px={2} pt={2} pb={1}>
              <Card
                sx={{ background: "#FBFBFD", textAlign: "center" }}
                className={classes.card}
              >
                <Box px={1} pt={1}>
                  <p className={classes.textoBox}>
                    Amigos que já mudaram para LUZ:
                  </p>
                </Box>
                <Box px={1} pb={1}>
                  <h3 className={classes.textoRoxo}>{indicados}</h3>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} pr={2} pt={2} pb={1}>
              <Card
                sx={{ background: "#FBFBFD", textAlign: "center" }}
                className={classes.card}
              >
                <Box px={1} pt={1}>
                  <p className={classes.textoBox}>
                    Total de desconto já recebido:
                  </p>
                </Box>
                <Box px={1} pb={1}>
                  <h3 className={classes.preco}>
                    {formatarValor(dadosCupom[0]?.valorAmigoIndicado)}
                  </h3>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} p={1}>
              <Box xs={12} p={1}>
                <p className={classes.texto}>
                  Compartilhe o código de desconto abaixo com seus amigos e
                  economizem juntos na conta de energia.
                </p>
              </Box>
            </Grid>
            <Grid item xs={12} px={2} py={1}>
              <Card className={classes.card}>
                <Box
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "space-between" },
                    alignItems: { xs: "center" }
                  }}
                  p={1}
                >
                  <Box>
                    <p className={classes.texto}>Seu código de indicação:</p>
                    <h2 className={classes.textoRoxo}>
                      {dadosCupom[0]?.codigo}
                    </h2>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      flexDirection: "column",
                      alignItems: "flex-end"
                    }}
                  >
                    <BotaoTransparent
                      onClick={() => {
                        setModalComoFunciona(true);
                      }}
                    >
                      <InfoOutlinedIcon className={classes.iconeInfo} />
                    </BotaoTransparent>
                    <Botao onClick={() => copiarCupom(dadosCupom[0]?.codigo)}>
                      Copiar
                    </Botao>
                  </Box>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} px={2} pt={1} pb={2}>
              <Card className={classes.card}>
                <BotaoTransparent onClick={() => setModalCompartilhar(true)}>
                  <Box
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: { xs: "space-between" },
                      alignItems: { xs: "center" }
                    }}
                    p={1}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between"
                      }}
                    >
                      <LinkIcon
                        sx={{
                          marginRight: "10px",
                          height: "1.2em",
                          width: "1.2em"
                        }}
                      />
                      <h3 className={classes.textoCompartilhar}>
                        Compartilhe o link
                      </h3>
                    </Box>
                    <ArrowForwardIosIcon />
                  </Box>
                </BotaoTransparent>
              </Card>
            </Grid>
          </>
        ) : (
          <SemDados texto="Ainda não conseguimos mostrar seu cupom! Em breve, eles estarão disponíveis para você." />
        )}
      </Grid>
    </Loader>
  );
};

IndiqueGanhe.propTypes = {
  cliente: PropTypes.number.isRequired
};

export default IndiqueGanhe;
