import RotasDTO from "./rotasUrlDto";

import * as Pagina from "../paginas";

export default [{
    path: `${RotasDTO.RelatoriosDashboard}`,
    caminho: "RelatoriosDashboard",
    component: Pagina.RelatoriosDashboard,
    exact: true,
    menuCabecalho: "RelatoriosDashboard",
    autenticada: true,
    estruturada: true
  },{
    path: `${RotasDTO.RelatoriosCustomizadosBaixar}`,
    caminho: "RelatoriosCustomizadosBaixar",
    component: Pagina.RelatoriosCustomizadosBaixar,
    exact: true,
    menuCabecalho: "RelatoriosCustomizadosBaixar",
    autenticada: true,
    estruturada: true
  },{
    path: `${RotasDTO.RelatoriosSetoriaisBaixar}`,
    caminho: "RelatoriosSetoriaisBaixar",
    component: Pagina.RelatoriosSetoriaisBaixar,
    exact: true,
    menuCabecalho: "RelatoriosSetoriaisBaixar",
    autenticada: true,
    estruturada: true
  },{
    path: `${RotasDTO.RelatoriosEnvios}`,
    caminho: "RelatoriosEnvios",
    component: Pagina.RelatoriosEnvios,
    exact: true,
    menuCabecalho: "Envios",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.RelatoriosEnvios}/cadastro/:id?`,
    caminho: "RelatoriosEnvios",
    component: Pagina.RelatoriosEnviosCadastro,
    exact: false,
    menuCabecalho: "RelatoriosEnvios",
    autenticada: true,
    estruturada: true
  },{
    path: `${RotasDTO.RelatoriosCategorias}`,
    caminho: "RelatoriosCategorias",
    component: Pagina.RelatoriosCategorias,
    exact: true,
    menuCabecalho: "Categorias",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.RelatoriosCategorias}/cadastro/:id?`,
    caminho: "RelatoriosCategorias",
    component: Pagina.RelatoriosCategoriasCadastro,
    exact: false,
    menuCabecalho: "Categorias",
    autenticada: true,
    estruturada: true
  },{
    path: `${RotasDTO.RelatoriosTipos}`,
    caminho: "RelatoriosTipos",
    component: Pagina.RelatoriosTipos,
    exact: true,
    menuCabecalho: "Tipos",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.RelatoriosTipos}/cadastro/:id?`,
    caminho: "RelatoriosTipos",
    component: Pagina.RelatoriosTiposCadastro,
    exact: false,
    menuCabecalho: "Tipos",
    autenticada: true,
    estruturada: true
  },{
    path: `${RotasDTO.RelatoriosAssociar}`,
    caminho: "RelatoriosAssociar",
    component: Pagina.RelatoriosAssociar,
    exact: true,
    menuCabecalho: "RelatoriosAssociar",
    autenticada: true,
    estruturada: true
  },
  {
    path: `${RotasDTO.RelatoriosAssociar}/cadastro/:id?`,
    caminho: "RelatoriosAssociar",
    component: Pagina.RelatoriosAssociarCadastro,
    exact: false,
    menuCabecalho: "RelatoriosAssociarCadastro",
    autenticada: true,
    estruturada: true
  },{
    path: `${RotasDTO.RelatoriosMetricasBaixar}`,
    caminho: "RelatoriosMetricasBaixar",
    component: Pagina.RelatoriosMetricasBaixar,
    exact: true,
    menuCabecalho: "RelatoriosMetricasBaixar",
    autenticada: true,
    estruturada: true
  }]