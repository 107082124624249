export default class ListagemValidarCotacaoDto {
  constructor(
    id,
    nomeFantasia,
    cnpj,
    plano,
    fechamento,
    prazo,
    status,
    ativo,
    cotacaoSelecionada,
    statusCotacao
  ) {
    this.id = id;
    this.nomeFantasia = nomeFantasia;
    this.cnpj = cnpj;
    this.plano = plano;
    this.fechamento = fechamento;
    this.prazo = prazo;
    this.status = status;
    this.statusCotacao = statusCotacao;
    this.ativo = ativo;
    this.cotacaoSelecionada = cotacaoSelecionada;
  }
}
