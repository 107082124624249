import PropTypes from "prop-types";
import React, { useState } from "react";

// Componentes
import { Grid } from "@mui/material";
import DataTable from "react-data-table-component";
import TabelaSemDados from "../TabelaSemDados";

// Styles
import { customStylesMedia, useStyles } from "./style";

const TabelaPrecoAnual = ({ linhas }) => {
  const classes = useStyles();
  const [itemAberto, setItemAberto] = useState(false);

  const colunas = [
    {
      name: <div className={classes.tabelaPrecosCelula}>Ano</div>,
      selector: (row) => row.ano
    },
    {
      name: <div className={classes.tabelaPrecosCelula}>Convencional</div>,
      selector: (row) => row.convencional
    },
    {
      name: <div className={classes.tabelaPrecosCelula}>Incentivado 50%</div>,
      selector: (row) => row.incentivado50
    },
    {
      name: <div className={classes.tabelaPrecosCelula}>Incentivado 100%</div>,
      selector: (row) => row.incentivado100
    },
    {
      name: <div className={classes.tabelaPrecosCelula}>Inflação</div>,
      selector: (row) => row.inflacao
    }
  ];

  const formatarPreco = (media) => {
    if (media) {
      const with2Decimals = media.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];

      return with2Decimals.replace(".", ",");
    }

    return "0,00";
  };

  const gerarLinhas = (dados) => {
    let dadosFormatados = [];

    if (dados?.length) {
      dadosFormatados = dados.map((dado) => ({
        ano: `${dado.ano}`,
        convencional: `R$ ${formatarPreco(dado?.anuaConv || 0)}`,
        incentivado50: `R$ ${formatarPreco(dado?.anualI5 || 0)}`,
        incentivado100: `R$ ${formatarPreco(dado?.anualI1 || 0)}`,
        inflacao: formatarPreco(dado?.fatoreajuste)
      }));
    }

    return dadosFormatados;
  };

  return (
    <div className={classes.tabelaPaginada}>
      <div style={{ flexGrow: 1, overflowX: "scroll" }} className="mb-1">
        <Grid container>
          <Grid container onClick={() => setItemAberto(!itemAberto)}>
            <DataTable
              columns={colunas}
              data={gerarLinhas(linhas)}
              customStyles={customStylesMedia}
              noDataComponent={<TabelaSemDados />}
              responsive={false}
              noHeader
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

TabelaPrecoAnual.propTypes = {
  linhas: PropTypes.oneOfType([PropTypes.array]).isRequired
};

export default TabelaPrecoAnual;
