import { makeStyles } from "@mui/styles";
import { Base } from "../../../../componentes/cores";
import theme from "../../../../themes"

export const useStyles = makeStyles(() => ({
  containerGrafico: {
    height: "100%",
    minHeight: "300px",
    width: "100%",
    paddingBottom: "15px",
    "& .apexcharts-tooltip": {
      border: "0 none",
      background: "#647480",
      textAlign: "center",
      fontSize: "15px",
      marginTop: "-3px"
    },
    "& .apexcharts-tooltip-title": {
      background: "#647480",
      borderBottom: "0",
      color: Base.GreyCasper,
      fontSize: "10px",
      display: "none"
    },
    "& .apexcharts-tooltip-series-group": {
      marginBottom: "-5px"
    }
  },
  titulo: {
    fontWeight: "bold"
  },
  tituloPDF:{
    fontWeight: "bold",
    fontSize: 32,
    color: Base.Black
  },
  container: {
    "& .MuiInputBase-root": {
      color: theme.color.textoBarra
    },
    "& .MuiInputBase-input": {
      maxWidth: "75px"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0 none"
    },
    "& .MuiButtonBase-root": {
      color: theme.color.textoBarra, 
      paddingLeft: 0
    }
  },
  noData: {
    textAlign: "center",
    width: "100%",
    "& div": {
      margin: "50px auto 0",
      maxWidth: "80%"
    }
  }
}));
