import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Grid, Card } from "@mui/material";
import UploadDropArea from "componentes/upload";
import BotaoRetornarListagem from "componentes/botaoRetornarListagem";
import Botao from "componentes/botao";
import Loader from "componentes/loader";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import rotasUrlDto from "submodules/DeltaTarifa/rotas/rotasUrlDto";
import { useSelector } from "react-redux";
import { uploadBandeirasTarifarias } from "../../../servicos/bandeirasTarifarias";
import { useStyles } from "./style";

const ImportarPrevisaoBandeiraTarifaria = () => {
  const classes = useStyles();
  const usuarioGlobal = useSelector((state) => state.usuario);

  const { handleSubmit } = useForm({
    reValidateMode: "onSubmit",
  });

  const msgPadraoUpload = "Solte o arquivo para anexá-lo ou procurar";
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(msgPadraoUpload);
  const [carregandoArquivo, setCarregandoArquivo] = useState(false);
  const [progressMesage, setProgressMessage] = useState("");

  const onCancelar = () => {
    setFile(null);
    setFileName(msgPadraoUpload);
    setCarregandoArquivo(false);
    setProgressMessage("");
  }

  const enviarFormulario = async () => {

    try {
      setCarregandoArquivo(true);
      setProgressMessage("Importando o arquivo... Por favor, aguarde.");

      const response = await uploadBandeirasTarifarias(file, usuarioGlobal?.usuario?.id, usuarioGlobal?.usuario?.usuarioNome);
      if (response?.status === 200 && response?.data) {
        onCancelar();
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: `Arquivo ${fileName} importado com sucesso!`
          })
        );
      }
    } catch (error) {
      error.response.data.map((item) => (
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: item
          })
        )
      ))
      onCancelar();
    };
  }

  const aoEnviarForm = () => {
    if (!file) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Por favor, selecione o arquivo desejado."
        })
      )
    } else {
      enviarFormulario();
    }    
  }

  const processarArquivo = (arquivo) => {
    if (arquivo != null && arquivo[0] != null) {
      setFile(arquivo[0]);
      setFileName(arquivo[0].file.name);
    }
  };

  const onAddUpload = (arquivo) => {
    processarArquivo(arquivo)
  };

  return (
    <form className="needs-validation" onSubmit={handleSubmit(aoEnviarForm)}>
      <Card className={classes.container}>
        <Grid container p={2} spacing={4} className={classes.container} justifyContent="flex-end">
          <Grid item sm={11}>
            Importar Previsão de Bandeiras Tarifárias
          </Grid>
          <Grid item sm={1}>
            <BotaoRetornarListagem
              justify="flex-end"
              urlListagem={rotasUrlDto.ProjecaoInsumoListar}
            />
          </Grid>
        </Grid>
        {carregandoArquivo === false ?
          <>
            <Grid container p={2} spacing={4} className={classes.container}>
              <Grid item sm={12}>
                <UploadDropArea
                  onAdd={onAddUpload}
                  dropAreaText={fileName}
                  showLoader={false}
                  acceptedFiles={[
                    ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                  ]}
                />
              </Grid>
            </Grid>
            <Grid container p={2} spacing={4} className={classes.container}>
              <Grid item sm={4}>
                <Botao
                  type="submit"
                  label="Salvar"
                  className={classes.button}
                />
              </Grid>
            </Grid>
          </>
          :
          <Grid container spacing={4} className={classes.container} style={{ marginBottom: "20px" }}>
            <Grid item sm={12} style={{ textAlign: "center" }}>
              {progressMesage}
            </Grid>
            <Grid item sm={12}>
              <Loader loading={carregandoArquivo} />
            </Grid>
          </Grid>
        }
      </Card>
    </form>
  );
}

export default ImportarPrevisaoBandeiraTarifaria;