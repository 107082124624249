import { makeStyles } from "@mui/styles";
import { Base } from "componentes/cores";
import { Link } from "react-router-dom";
import styled from "styled-components";
import theme from "themes";
import bgComFatura from "../../../assets/cotacoes/bg-com-fatura.png";
import bgSemFatura from "../../../assets/cotacoes/bg-sem-fatura.png";
import bgCotacoes from "../../../assets/relatorios/bg-exclusivos.png";
import bgListaCotacoes from "../../../assets/relatorios/bg-setoriais.png";

export const Cotacoes = styled.div`
  align-items: center;
  background: url(${bgCotacoes}) center center no-repeat;
  background-size: cover;
  cursor: pointer;
  display: flex;
  height: 445px;
  justify-items: center;
  margin-bottom: 30px;
  text-decoration: none;
  width: 100%;
  &:hover {
    text-decoration: none;
  }
  @media (max-width: 1366px) {
    height: 490px;
    width: 100%;
  }
  @media (max-width: 1024px) {
    height: 490px;
    width: 100%;
  }
  @media (max-width: 600px) {
    height: 320px;
    width: 100%;
  }
`;

export const ListaCotacoes = styled(Link)`
  align-items: center;
  background: url(${bgListaCotacoes}) center center no-repeat;
  background-size: cover;
  cursor: pointer;
  display: flex;
  height: 445px;
  justify-items: center;
  margin-bottom: 30px;
  text-decoration: none;
  width: 100%;
  &:hover {
    text-decoration: none;
  }
  @media (max-width: 1366px) {
    height: 290px;
    width: 100%;
  }
  @media (max-width: 1024px) {
    height: 290px;
    width: 100%;
  }
  @media (max-width: 600px) {
    height: 320px;
    width: 100%;
  }
`;

export const ComFatura = styled.div`
  align-items: center;
  background: url(${bgComFatura}) center center no-repeat;
  background-size: cover;
  cursor: pointer;
  display: flex;
  height: 445px;
  justify-items: center;
  margin-bottom: 30px;
  text-decoration: none;
  width: 100%;
  &:hover {
    text-decoration: none;
  }
  @media (max-width: 1366px) {
    height: 290px;
    width: 100%;
  }
  @media (max-width: 1024px) {
    height: 290px;
    width: 100%;
  }
  @media (max-width: 600px) {
    height: 320px;
    width: 100%;
  }
`;

export const SemFatura = styled(Link)`
  align-items: center;
  background: url(${bgSemFatura}) center center no-repeat;
  background-size: cover;
  cursor: pointer;
  display: flex;
  height: 445px;
  justify-items: center;
  margin-bottom: 30px;
  text-decoration: none;
  width: 100%;
  &:hover {
    text-decoration: none;
  }
  @media (max-width: 1366px) {
    height: 290px;
    width: 100%;
  }
  @media (max-width: 1024px) {
    height: 290px;
    width: 100%;
  }
  @media (max-width: 600px) {
    height: 320px;
    width: 100%;
  }
`;

export const TextoMobile = styled.div`
  text-align: center;
  color: ${theme.color.textoBarra};
  font-size: 28px;
  height: 80px;
  margin: 0 auto;
  padding-top: 15px;
  width: 288px;
`;

export const TituloInstrucao = styled.div`
  text-align: left;
  color: ${Base.Black};
  font-style: italic;
  font-size: 26px;
`;

export const Instrucao = styled.div`
  text-align: left;
  color: ${Base.BlackOpacity80};
  font-size: 16px;
  font-style: italic;
`;

export const BoxInstrucoes = styled.div`
  align-items: center;
  background-color: ${theme.color.bgCardInstrucoes};
  border: 1px solid rgba(25, 28, 34, 0.5);
  box-sizing: border-box;
  cursor: default;
  display: flex;
  justify-items: center;
  margin-bottom: 30px;
  text-decoration: none;
  color: #000000;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  min-height: 140px;
  &:hover {
    text-decoration: none;
  }
`;

export const useStyles = makeStyles(() => ({
  anexarFoto: {
    backgroundColor: `${theme.color.primary} !important`,
    color: `${Base.White} !important`,
    borderColor: `${theme.color.primary} !important`,
    width: "98%",
    borderRadius: 25,
    fontSize: "1.25rem",
    textTransform: "none !important"
  },

  barraSuperior: {
    fontSize: "18px",
    fontStyle: "italic",
    height: "42px",
    textAlign: "center",
    color: `${theme.color.textoBarra}`,
    width: "100%"
  },
  arrowBack: {
    color: `${theme.color.textoBarra}`,
    position: "absolute",
    left: 0,
    paddingLeft: 15
  },
  iconeBotao: {
    display: "block",
    margin: "0 auto",
    marginTop: -30,
    marginBottom: 10
  },
  colunaIconeInstrucoes: {
    alignItems: "center",
    display: "flex",
    justifyItems: "center"
  },
  iconeInstrucoes: {
    margin: "0 auto"
  },
  itemBotao: {
    padding: 10
  },
  botaoNovaFoto: {
    backgroundColor: `${theme.color.primaryBackgroud} !important`,
    color: `${Base.WhiteSmoke} !important`,
    borderColor: `${Base.WhiteSmoke} !important`
  },
  botaoProcessarFatura: {
    marginBottom: 15
  }
}));
