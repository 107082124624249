import MICROSERVICO from "global/enumeradores/microServico";
import obterApi from "servicos/api";

const api = () => obterApi(MICROSERVICO.PROJECAOTARIFARIA);

export const ImportPCAT = async (idDistribuidora, validarDominio, anoTarifario, novaVigencia, idUsuario, nomeUsuario, keyFileS3) => {
  let url = "";
  const metodo = "post";

  if (novaVigencia != null) {
    url = `v1/ImportPCAT/import?idDistribuidora=${idDistribuidora}&validarDominio=${validarDominio}&anoTarifario=${anoTarifario}&novaVigencia=${novaVigencia}&idUsuario=${idUsuario}&nomeUsuario=${nomeUsuario}&keyFileS3=${keyFileS3}`;
  } else {
    url = `v1/ImportPCAT/import?idDistribuidora=${idDistribuidora}&validarDominio=${validarDominio}&anoTarifario=${anoTarifario}&idUsuario=${idUsuario}&nomeUsuario=${nomeUsuario}&keyFileS3=${keyFileS3}`;
  }

  return api()[metodo](url);
};

export const GetLatestImportsByIdDistribuidora = async (IdDistribuidora) => {
  return api().get(`v1/ImportPCAT/getLatestImportsByIdDistribuidora?IdDistribuidora=${IdDistribuidora}`);
}

export const possuiImportsDeUmAnoTarifario = async (IdDistribuidora, anoTarifario) => {
	const queryParams = `idDistribuidora=${IdDistribuidora}&anoTarifario=${anoTarifario}`
	return api().get(`v1/ImportPCAT/possuiImportsDeUmAnoTarifario?${queryParams}`);
}

export const obterValoresCustos = async (id, ano) => {
  return api().get(`v1/PcatCusto/getviewcusto?id=${id}&ano=${ano}`);
}

export const obterValoresTarifas = async (id, ano) => {
  return api().get(`v1/PcatTarifa/getviewtarifa?id=${id}&ano=${ano}`);
}

export const obterValoresPesosTarifarios = async (id, ano) => {
  return api().get(`v1/PcatPesoTarifario/getviewpesostarifarios?id=${id}&ano=${ano}`);
}