export default class FiltroAdicionalTabela {
  constructor(
    ordenacao,
    ordemColunaSelecionada,
    empresaSelecionada,
    filtroTipoClienteSelecionado
  ) {
    this.ordenacao = ordenacao;
    this.sortOrder = ordemColunaSelecionada;
    this.empresaId = empresaSelecionada;
    this.tipoCliente = filtroTipoClienteSelecionado;
  }
}
