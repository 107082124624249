import { makeStyles } from "@mui/styles";
import styled from "styled-components";
import { createTheme } from "@mui/material/styles";
import theme from "../../../../themes";

const th = createTheme();

export const useStyles = makeStyles(() => ({
  titulo: {
    color: theme.color.primary,
    fontFamily: theme.font.family,
    fontSize: "28px",
    [th.breakpoints.down("sm")]: {
      fontSize: "20px",
    }
  },
  botao: {
    background: "transparent",
    border: "none",
    padding: "0 10px",
    display: "flex",
    alignItems: "center"
  },
  sombra: { boxShadow: "0 3px 10px rgb(0 0 0 / 0.2) !important" },
  cardConteudo: {
    display: "flex",
    alignItems: "end",
    [th.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center"
    }
  },
  cardIcone: {
    marginLeft: "5px",
    marginRight: "10px"
  },
  cardPreco: {
    color: theme.color.primary,
    fontFamily: theme.font.family,
    fontSize: "24px",
    fontWeight: 700,
    margin: 0,
    marginRight: 10
  },
  centro: {
    [th.breakpoints.down("sm")]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center"
    }
  }
}));

export const Circulo = styled.div`
  background-color: ${(props) => (props.cor ? props.cor : theme.color.primary)};
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin-right: 8px;
`;

export const Kwh = styled.span`
  color: ${(props) => (props.corTexto ? props.corTexto : theme.color.primary)};
  font-weight: 700;
  font-size: 20px;
  margin-right: 10px;
`;
