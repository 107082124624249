import RetornoEndpointDto from "global/dto/retornoEndpointDto";

import { ObterPorId, Salvar } from "../../../servicos/tipoRelatorioServico";

class CadastroHelper {
  static async Salvar(params) {
    try {
      if (!params)
        return RetornoEndpointDto.Erro(
          "É necessário informar uma categoria para a persistencia"
        );

      const { data } = await Salvar(params);

      return RetornoEndpointDto.Sucesso(
        "Categoria cadastrada com Sucesso",
        data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        error?.response?.data?.message ??
          "Ocorreu uma falha ao salvar o Categoria"
      );
    }
  }

  static async ObterPorId(params) {
    // console.log('ObterPorId', params);
    try {
      if (!params)
        return RetornoEndpointDto.Erro(
          "É necessário informar uma categoria para a persistencia"
        );

      const { data } = await ObterPorId(params);

      return RetornoEndpointDto.Sucesso(
        "Categoria cadastrada com Sucesso",
        data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        error?.response?.data?.message ??
          "Ocorreu uma falha ao salvar o Categoria"
      );
    }
  }
}

export default CadastroHelper;
