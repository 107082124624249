import { Box, CircularProgress } from "@mui/material";
import $ from "jquery";
import "jquery-ui-bundle";
import "jquery-ui-touch-punch";
import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import LuzAcesa from "../../../assets/captch/images/luz-acesa.png";
import DeltaAcesa from "../../../assets/captch/images/delta-acesa.png";
import ThymosAcesa from "../../../assets/captch/images/thymos-acesa.png";
import Reset from "../../../assets/captch/reset.png";
import { retornoCaptcha } from "../../../servicos/loginServico";
import { StyledSection } from "./style";

const Captcha = ({ handleCaptcha, captchaPosicao, sistema, chave }) => {
  const [captcha, setCaptcha] = useState(false);
  const [imagemCaptcha, setImagemCaptcha] = useState("");
  const [loading, setLoading] = useState("");

  const getCaptcha = async () => {
    const respostaCaptcha = await retornoCaptcha();
    try {
      if (respostaCaptcha?.status === 200) {
        chave(respostaCaptcha.data.chave);
        setImagemCaptcha(respostaCaptcha.data.base64);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const resetCaptcha = () => {
    setLoading(false);
    setCaptcha(false);
    handleCaptcha(true);
    captchaPosicao(0);
    chave("");
    $("#slider").slider({ disabled: false });
    $(".ui-slider-handle").show();
    $(".img-apagada").show();
    $("#slider").slider("value", 0);
    $(".ui-slider-handle").removeClass("sem-content");
    getCaptcha();
  };

  useEffect(() => {
    $(function () {
      $("#slider").slider({
        orientation: "horizontal",
        range: "min",
        min: 0,
        max: 100,
        value: 0,
        step: 1,
        change(event, ui) {
          if (ui.value > 0) {
            setLoading(true);
            captchaPosicao(ui.value);
            handleCaptcha(false);
            $("#slider").slider({ disabled: true });
          }
        }
      });
    });

    if (captcha === true) {
      $("#slider").slider({ disabled: true });
    }
  }, []);

  const handleImage = (domain) => {
    switch (domain) {
      case "luz":
        return `url(${LuzAcesa})`;
      case "delta":
        return `url(${DeltaAcesa})`;
      case "thymos":
        return `url(${ThymosAcesa})`;
      default:
        return `url(${LuzAcesa})`;
    }
  };

  useEffect(() => {
    getCaptcha();
  }, []);

  return (
    <StyledSection url={handleImage(sistema)}>
      <link rel="stylesheet" href="/css/jquery-ui.css" />
      <link rel="stylesheet" href="/css/captcha.css" />

      <div className="col-xs-12 col-md-12 posicione-figura">
        <Box textAlign="center">Posicione a figura no local indicado</Box>
      </div>
      <div className="col-xs-12">
        <div className="area mx-auto">
          <Box className="img-apagada">
            <img
              className="img-captcha"
              src={`data:image/jpeg;base64,${imagemCaptcha}`}
              alt="imagem captcha"
            />
          </Box>
          <div id="slider" />
        </div>
      </div>
      <div className="col-xs-12 col-md-12 text-center mb-4 ">
        {loading ? (
          <Box component="div" className="text-center mt-0">
            <CircularProgress size={24} className="loader" />
            <div className="text-center">Validando verificador ...</div>
          </Box>
        ) : (
          <Box component="div" className="text-center mt-0 resetCaptcha ">
            <Box
              component="img"
              src={Reset}
              alt="Resetar verificador"
              onClick={() => resetCaptcha()}
              mt={4}
            />
            <div className="text-center mt-3">Resetar verificador</div>
          </Box>
        )}
      </div>
    </StyledSection>
  );
};

Captcha.propTypes = {
  captchaPosicao: PropTypes.oneOfType([PropTypes.func]),
  handleCaptcha: PropTypes.oneOfType([PropTypes.func]),
  chave: PropTypes.oneOfType([PropTypes.func]),
  sistema: PropTypes.string
};

Captcha.defaultProps = {
  handleCaptcha: () => {},
  captchaPosicao: () => {},
  chave: () => {},
  sistema: ""
};

export default Captcha;
