import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";

// Redux
import { useSelector } from "react-redux";

// Gráfico
import ReactSpeedometer from "react-d3-speedometer";

// Componentes
import { Grid } from "@mui/material";
import themes from "themes";
import InputData from "../../../componentes/inputTextoData";
import Loader from "../../../componentes/loader";
import BoxLegenda from "../../../componentes/boxLegenda";
import SelectArredondado from "../../../componentes/selectArredondado";
import { Base } from "../../../componentes/cores";

// Styles
import { Container } from "../Dashboard/style";
import { useStyles } from "./style";

// Serviços
import ProjecaoConsumoHelper from "./helper";

const ProjecaoConsumo = () => {
  const classes = useStyles();
  const usuario = useSelector((state) => state.usuario);
  const { unidadeSelecionada } = usuario;

  const [data, setData] = useState();
  const [idProjecao, setIdProjecao] = useState();
  const [projecaoSelecionada, setProjecaoSelecionada] = useState();
  const [boletaConsumo, setBoletaConsumo] = useState(0);
  const [projetadoDia, setProjetadoDia] = useState();
  const [montanteInferior, setMontanteInferior] = useState(0);
  const [montanteSuperior, setMontanteSuperior] = useState(0);
  const [valorMaximo, setValorMaximo] = useState(0);
  const [dados, setdados] = useState();

  const [listaProjecao] = useState([
    {
      id: 1,
      titulo: "Média simples"
    },
    {
      id: 5,
      titulo: "Fora Ponta e Ponta"
    },
    {
      id: 3,
      titulo: "Dia útil e não útil"
    },
    {
      id: 4,
      titulo: "Meses anteriores"
    }
  ]);

  const [legendas] = useState([
    { id: 1, legenda: "Consumo Mín", bgCor: themes.color.montanteSuperior },
    { id: 2, legenda: "Volume contratado", bgCor: themes.color.montante },
    { id: 3, legenda: "Acima contrato", bgCor: themes.color.montanteInferior }
  ]);

  const [carregandoDados, setCarregandoDados] = useState(false);
  const [grafico, setGrafico] = useState(<div />);
  const [noData, setNoData] = useState(true);
  const [quantidadeSegmentos, setQtdaSegments] = useState();

  const obterDados = useCallback(async () => {
    if (window.moment() <= window.moment().startOf("month")) return;

    if (!data || !unidadeSelecionada || !projecaoSelecionada) return;
    try {
      setCarregandoDados(true);

      const retorno = await ProjecaoConsumoHelper.ObterProjecao(
        projecaoSelecionada.id,
        unidadeSelecionada,
        data
      );

      if (!retorno.sucesso) {
        ProjecaoConsumoHelper.exibirErro(retorno.mensagem);
        return;
      }

      const maximo =
        retorno?.data?.valorMaximo === 0
          ? parseFloat(
              (retorno?.data?.montanteSuperior)
                .toFixed(2)
                .toString()
                .replace(".", ",")
            )
          : parseFloat(
              (retorno?.data?.valorMaximo)
                .toFixed(2)
                .toString()
                .replace(".", ",")
            );

      const inferior = parseFloat(
        (retorno?.data?.montanteInferior)
          .toFixed(2)
          .toString()
          .replace(".", ",")
      );
      const superior = parseFloat(
        (retorno?.data?.montanteSuperior)
          .toFixed(2)
          .toString()
          .replace(".", ",")
      );
      const consumo = parseFloat(
        (retorno?.data?.consumoMaisPerdasMenosProinfa).toFixed(2).toString()
      );

      const projDia =
        retorno?.data?.projetadoDia === 0 ||
        retorno?.data?.projetadoDia === null ||
        retorno?.data?.projetadoDia === undefined
          ? consumo / moment().daysInMonth()
          : retorno?.data?.projetadoDia;

      const valoresSegmentos = [0];

      if (inferior > 0) valoresSegmentos.push(inferior);
      if (superior > 0) valoresSegmentos.push(superior);

      let numSegmentos = 1;
      valoresSegmentos.forEach((valor) => {
        numSegmentos += valor > 0 ? 1 : 0;
      });

      setdados(retorno?.data);
      setQtdaSegments(numSegmentos);
      setMontanteInferior(inferior);
      setMontanteSuperior(superior);
      setValorMaximo(
        maximo ? Math.trunc(maximo) : Math.trunc(consumo + consumo * 0.1)
      );
      setBoletaConsumo(consumo);
      setProjetadoDia(projDia);
      setNoData(
        !retorno?.data?.consumoMaisPerdasMenosProinfa &&
          !retorno?.data?.montanteInferior &&
          !retorno?.data?.montanteSuperior &&
          !retorno?.data?.valorMaximo
      );
    } catch (error) {
      console.info(error);
      setCarregandoDados(false);
    } finally {
      setCarregandoDados(false);
    }
  }, [data, unidadeSelecionada, projecaoSelecionada]);

  useEffect(() => {
    obterDados();
  }, [obterDados]);

  useEffect(() => {
    setData(window.moment().startOf("month"));
    setProjecaoSelecionada(listaProjecao[0]);
    setIdProjecao(1);
  }, [listaProjecao]);

  useEffect(() => {
    try {
      setCarregandoDados(true);
      const novaProjecaoSelecionada = listaProjecao.find(
        (item) => String(item.id) === String(idProjecao)
      );
      setProjecaoSelecionada(novaProjecaoSelecionada);
      setCarregandoDados(false);
    } catch (error) {
      setCarregandoDados(false);
    }
  }, [listaProjecao, idProjecao]);

  const definirMesInicial = () => {
    const now = moment();
    const day = now.date();
    if (day === 1 || day === 2) {
      // const date = moment().subtract(1, 'month').toDate();
      setIdProjecao(4);
    }
  };

  useEffect(() => {
    definirMesInicial();
  }, []);

  const RenderizarGrafico = useCallback(() => {
    const valoresSegmentos = [0];
    const coresSegmentos = [];

    if (montanteInferior > 0) {
      valoresSegmentos.push(montanteInferior);
      coresSegmentos.push(themes.color.montanteSuperior);
    }

    coresSegmentos.push(themes.color.montante);

    if (montanteSuperior > 0) {
      valoresSegmentos.push(montanteSuperior);
      coresSegmentos.push(themes.color.montanteInferior);
    }

    if (valorMaximo > 0) valoresSegmentos.push(valorMaximo);

    const graficoRender = (
      <ReactSpeedometer
        width={220}
        height={160}
        value={boletaConsumo === 0 ? valorMaximo / 2 : boletaConsumo}
        minValue={0}
        maxValue={valorMaximo}
        currentValueText="MWh"
        needleTransitionDuration={1000}
        needleTransition="easeExpOut"
        needleColor={Base.BlueTuna}
        needleHeightRatio={0.7}
        maxSegmentLabels={quantidadeSegmentos + 1}
        segments={quantidadeSegmentos}
        customSegmentStops={valoresSegmentos}
        segmentColors={coresSegmentos}
        valueTextFontWeight="400"
        valueTextFontSize="12px"
        textColor={themes.color.labelColorGrafico}
        ringWidth={50}
        forceRender
      />
    );

    setGrafico(graficoRender);
  }, [
    setGrafico,
    boletaConsumo,
    montanteInferior,
    montanteSuperior,
    valorMaximo,
    quantidadeSegmentos
  ]);

  useEffect(() => RenderizarGrafico(), [RenderizarGrafico]);

  function onChangeSelect(valor) {
    setIdProjecao(valor);
  }

  const [legendasAtivas, setLegendasAtivas] = useState(null);

  // Legendas dinâmicas
  useEffect(() => {
    const coresSegmentos = [];

    if (dados?.montanteInferior > 0) {
      coresSegmentos.push({ id: 1, legenda: themes.color.montanteSuperior });
    }

    coresSegmentos.push({ id: 2, legenda: themes.color.montante });

    if (dados?.valorMaximo > 0) {
      coresSegmentos.push({ id: 3, legenda: themes.color.montanteInferior });
    }

    const legendasLista = [];

    for (let i = 0; i < coresSegmentos.length; i += 1) {
      const corId = coresSegmentos[i].id;
      const corFiltrada = legendas.filter((cor) => cor.id === corId);
      legendasLista.push(corFiltrada);
    }
    setLegendasAtivas(legendasLista);
  }, [dados, legendas]);

  return (
    <Container height="100%" width="100%">
      <Grid
        container
        justifyContent="space-between"
        className="m-0 w-100"
        spacing={2}
      >
        <Grid item className={classes.titulo}>
          Projeção de consumo
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item>
              <InputData
                type="text"
                id="mes"
                name="mes"
                format="MMM/yyyy"
                views={["month", "year"]}
                customValue={data}
                minDate={window.moment().startOf("month").toDate()}
                maxDate={window.moment().startOf("month").toDate()}
                onChange={(valor) => setData(valor)}
                renderIconShowHide={false}
                className={classes}
                disabled
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="space-around"
        className="m-0 w-100"
        spacing={2}
      >
        <Grid item md={6} sm={12}>
          <Grid item className={classes.dados}>
            <Grid item lg={9} sm={12}>
              <SelectArredondado
                styleForm={false}
                id="projecaoSelecionada"
                name="projecaoSelecionada"
                valueKey="id"
                valueName="titulo"
                dataSource={listaProjecao}
                value={idProjecao}
                onChange={(event) => {
                  onChangeSelect(event.target.value);
                }}
              />
            </Grid>
            <Grid item>
              {!noData ? (
                <Loader loading={carregandoDados}>
                  <br />
                  <Grid item>
                    Consumo projetado/dia
                    <br />
                    <span>
                      {projetadoDia.toFixed(2).toString().replace(".", ",")}
                      <p>MWh</p>
                    </span>
                  </Grid>
                  <Grid item>
                    Consumo projetado/mês
                    <br />
                    <span>
                      {boletaConsumo.toFixed(2).toString().replace(".", ",")}
                      <p>MWh</p>
                    </span>
                  </Grid>
                </Loader>
              ) : null}
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={6} sm={12}>
          {!noData ? (
            <Grid item xs={11} className={classes.containerGrafico}>
              <Loader loading={carregandoDados}>{grafico}</Loader>
            </Grid>
          ) : (
            <Grid className={classes.noData}>
              <div>
                {window.moment() > window.moment().startOf("month")
                  ? "Não foi possível carregar as informações de consumo, tente novamente em algumas horas!"
                  : "Não possuímos dados de consumo para realizar a projeção do mês atual"}
              </div>
            </Grid>
          )}
          <Grid item xs={4} className={classes.boxLegenda}>
            {!noData
              ? legendasAtivas.map((leg, idx) =>
                  leg.map((item) => (
                    <BoxLegenda
                      key={idx}
                      statusColors={item.bgCor}
                      legendas={item.legenda}
                      fontSize="10px"
                    />
                  ))
                )
              : null}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ProjecaoConsumo;
