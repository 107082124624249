import PropTypes from "prop-types";
import React from "react";

import getDomain from "global/getDomain";
import DeltaGif from "../../../../assets/cotacoes/loading-delta.gif";
import LuzGif from "../../../../assets/cotacoes/loading-luz.gif";
import { useStyles, Wrapper } from "./style";

const Loader = ({ className, msgLoading }) => {
  const classes = useStyles();
  return (
    <Wrapper className={`position-relative ${className}`}>
      <div className={classes.containerLoader}>
        <img
          src={getDomain() === "luz" ? LuzGif : DeltaGif}
          className={classes.imagemLoader}
          alt="loader"
        />
        <h4 className={classes.msgLoading}>{msgLoading}</h4>
      </div>
    </Wrapper>
  );
};

Loader.propTypes = {
  className: PropTypes.string,
  msgLoading: PropTypes.string
};

Loader.defaultProps = {
  className: "",
  msgLoading: ""
};

export default Loader;
