import MICROSERVICO from "global/enumeradores/microServico";
import obterApi from "servicos/api";

const api = () => obterApi(MICROSERVICO.PROJECAOTARIFARIA);

export const importarArquivo = async (arquivo, idUsuario, nomeUsuario) => {
    const data = new FormData();
    data.append("file", arquivo.file);

    const metodo = "post";
    const url = `v1/EncargoMigracao/importar?idUsuario=${idUsuario}&nomeUsuario=${nomeUsuario}`;
    return api()[metodo](url, data);
}

export const getAdicionaisCusto = async (idDistribuidora, anoInicial, anoFinal) => {   
    const result = await api().get(`v1/EncargoMigracao/visualizar?idDistribuidora=${idDistribuidora}&AnoInicial=${anoInicial}&AnoFinal=${anoFinal}`);
    return result;
}

export const getAllDistribuidoras = async () => {
    const metodo = "get";
    const url = "v1/ParametroDistribuidora/getalljoin?StatusProjecaoTarifaria=true"
    return api()[metodo](url);
}