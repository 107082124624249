import React from "react";
import PropTypes from "prop-types";

// Componentes
import { Box } from "@mui/material";

// Styles
import { useStyles } from "./style";

import IconeLista from "../../../../assets/jornadaCliente/icone-lista.png";
import IconeSol from "../../../../assets/jornadaCliente/icone-sol.png";
import IconeLuz from "../../../../assets/jornadaCliente/icone-luz.png";

const Pagina2 = ({
  titulo,
  subtitulo,
  tituloConteudo,
  conteudo,
  bordaConteudo
}) => {
  const classes = useStyles({ bordaConteudo });

  return (
    <div className={classes.container}>
      <img className={classes.iconeSol} src={IconeSol} alt="imagem sol" />
      <div>
        <img
          className={classes.iconeLista}
          src={IconeLista}
          alt="imagem lista"
        />
        <Box pl={bordaConteudo ? 0 : 6} className={classes.box}>
          {titulo}
        </Box>
        <Box pl={bordaConteudo ? 0 : 6} className={classes.box}>
          {subtitulo}
        </Box>
      </div>

      {conteudo && (
        <Box className={classes.conteudo}>
          {tituloConteudo && (
            <h2 className={classes.tituloConteudo}>{tituloConteudo}</h2>
          )}
          <Box p={2} height="100%">
            {conteudo}
          </Box>
        </Box>
      )}
      <img className={classes.iconeLuz} src={IconeLuz} alt="imagem luz" />
    </div>
  );
};

Pagina2.propTypes = {
  titulo: PropTypes.node,
  tituloConteudo: PropTypes.string,
  conteudo: PropTypes.node,
  bordaConteudo: PropTypes.bool,
  subtitulo: PropTypes.node
};

Pagina2.defaultProps = {
  titulo: <></>,
  subtitulo: <></>,
  tituloConteudo: "",
  conteudo: null,
  bordaConteudo: true
};

export default Pagina2;
