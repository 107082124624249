import { makeStyles } from "@mui/styles";
import theme from "themes";

export const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: "#F2F2F2 !important",
    width: "1020px",
    height: "746px",
    position: "relative",
    padding: "48px !important"
  },
  box: {
    display: "flex",
    width: "100%",
    height: "100%"
  },
  imagemFundo: {
    position: "absolute",
    left: 0,
    top: "50%",
    transform: "translateY(-50%)",
    width: "350px"
  },
  iconeLista: {
    position: "absolute",
    left: 330,
    top: "50%",
    transform: "translateY(-9%)",
    width: "50px"
  },
  iconeSol: {
    position: "absolute",
    left: "-30px",
    top: "50px",
    width: "160px",
    zIndex: 1
  },
  iconeLuz: {
    position: "absolute",
    right: 20,
    bottom: 20,
    width: "60px"
  },
  conteudo: {
    height: "80%",
    width: "55%",
    padding: "16px 0 !important",
    marginTop: "100px",
    float: "right",
    color: theme.color.primary,
    "& h3": {
      fontSize: "26px !important"
    }
  },
  titulo: {
    color: theme.color.primary,
    fontSize: "44px !important",
    fontWeight: 700
  },
  borda: {
    background:
      "linear-gradient(90deg, rgba(234,130,52,1) 0%, rgba(223,105,102,1) 40%, rgba(232,81,177,1) 78%)",
    width: "70px",
    height: "5px",
    borderRadius: "5px",
    margin: "10px 0"
  }
}));
