import produce from "immer";

const inicial = {
  fatura: {}
};

export default function log(state = inicial, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "@log/responseFatura": {
        draft.fatura = action.payload;
        break;
      }
      default: 
        return state;
    }
  });
}
