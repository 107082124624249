import React from "react";

import PropTypes from "prop-types";

// Componentes
import { Box } from "@mui/material";

// Styles
import { useStyles } from "./style";
import IconeLista from "../../../../assets/jornadaCliente/icone-lista.png";
import IconeLuz from "../../../../assets/jornadaCliente/icone-luz.png";

const Pagina1 = ({ titulo, subtitulo }) => {
  const classes = useStyles();

  return (
    <Box className={classes.containerImage}>
      <img className={classes.iconeLuz} src={IconeLuz} alt="imagem luz" />
      <img className={classes.iconeLista} src={IconeLista} alt="imagem lista" />
      <Box className={classes.boxTexto}>
        {titulo}
        {subtitulo}
      </Box>
    </Box>
  );
};

Pagina1.propTypes = {
  titulo: PropTypes.node,
  subtitulo: PropTypes.node
};

Pagina1.defaultProps = {
  titulo: <></>,
  subtitulo: <></>
};

export default Pagina1;
