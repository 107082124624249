import { makeStyles } from "@mui/styles";
import theme from "themes";

export const useStyles = makeStyles(() => ({
  select: {
    paddingRight: "10px !important",
    paddingBottom: "5px !important",
    "& fieldset": {
      borderColor: `${theme.color.selectArredondadoColor} !important`
    }
  },
  label: {
    color: `${theme.color.selectArredondadoColor} !important`
  },
  container: {
    width: "100%",
    margin: 0,
    "& fieldset": {
      borderColor: `${theme.color.selectArredondadoColor} !important`
    },
    "& label, & button, & input": {
      color: `${theme.color.selectArredondadoColor} !important`
    },
    paddingRight: "10px !important",
    paddingBottom: "5px !important"
  }
}));
