/* eslint-disable no-continue */
/* eslint-disable consistent-return */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-shadow */
/* eslint-disable prefer-template */
/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  OutlinedInput,
  Typography
} from "@mui/material";
import { Base } from "componentes/cores";
import { Botao, ModalConfirmacao } from "componentes";
import Loader from "componentes/loader";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { useSelector } from "react-redux";
import SelectAutocompleteCheckbox from "componentes/selectAutocompleteCheckbox";
import { useHistory, useParams } from "react-router-dom";
import { useStyles } from "./style";
import { buscaDistribuidoraPorGrupo } from "../../../servicos/grupoProjecoes";
import { getExcelEfeitoConsTarifMed } from "../../../servicos/exportacoes";
import BotaoRetornarListagem from "componentes/botaoRetornarListagem";
import moment from "moment";
import ModalInformacao from "componentes/modalVerificacao";

const EfeitoConsumidorTarifaMedia = () => {
  const usuarioGlobal = useSelector((state) => state.usuario);
  const classes = useStyles();
  const { idgrupo, titulogrupo } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const [distribuidoras, setDistribuidoras] = useState([]);
  const [listaDistribuidorasEditada, setListaDistribuidorasEditada] = useState(
    []
  );
  const [distribuidorasSelecionadas, setDistribuidorasSelecionadas] = useState(
    []
  );
  const [consolidado, setConsolidado] = useState("Não");
  const [tipoTarifa, setTipoTarifa] = useState("Aplicação");
  const [anos, setAnos] = useState([]);
  const [anoSelecionado, setAnoSelecionado] = useState(2010);

  const [nomeArquivo, setNomeArquivo] = useState("TarifaMed.xlsx");
  const [showModalGerarExcel, setShowModalGerarExcel] = useState(false);
  const [modalInfo, setModalInfo] = useState(false);
  const [msgFalha, setMsgFalha] = useState();

  const handleAnos = useCallback(async () => {
    const menorAno = 2010;
    const maiorAno = moment().year();
    let years = [];

    for (var i = menorAno; i <= maiorAno; i++) {
      years.push(i);
    }
    setAnos(years);
    setAnoSelecionado(menorAno);
  });

  const handleDistribuidoras = useCallback(async () => {
    try {
      setIsLoading(true);
      const lista = await buscaDistribuidoraPorGrupo(idgrupo);
      if (lista?.status === 200 && lista?.data) {
        setIsLoading(false);
        setDistribuidoras(lista?.data);
        setListaDistribuidorasEditada(
          lista?.data.map((listItem) => {
            return {
              id: listItem.idDistribuidora,
              nome: listItem.titulo
            };
          })
        );
      } else {
        setDistribuidoras([]);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  });

  const onChangeDistribuidora = async (item) => {
    setDistribuidorasSelecionadas(item);
  };

  const onChangeAnos = (event) => {
    setAnoSelecionado(event.target.value);
  };

  const onChangeConsolidado = (event) => {
    setConsolidado(event.target.value);
    consolidado === "Não"
      ? onChangeDistribuidora([{ id: "-1", nome: "Todos" }])
      : onChangeDistribuidora([]);
  };

  const onChangeTipoTarifa = (event) => {
    setTipoTarifa(event.target.value);
  };

  useEffect(() => {
    if (!distribuidoras?.length) handleDistribuidoras();
  }, [distribuidoras?.length]);

  useEffect(() => {
    if (!anos.length) handleAnos();
  }, [anos.length]);

  const downloadFile = (blob) => {
    const url = URL.createObjectURL(blob)
    const element = document.createElement("a");
    element.setAttribute("href", url);
    element.setAttribute('download', nomeArquivo);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  let contador = 0;
  const handleStatusArquivo = async (url) => {
    let fileResponse;
    const headers = { Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", };

    await axios
      .get(url, { responseType: 'blob', headers })
      .then((response)=> (fileResponse = response))
      .catch((error) => (fileResponse = error));

    setTimeout(async () => {
      if (contador > 160000) {
        setIsDownloading(false);
        setShowModalGerarExcel(false);
        setMsgFalha(`Erro não previsto. Tente novamente!`);
        setModalInfo(true);
        return;
      }
      if (fileResponse?.status === 200) {
        downloadFile(fileResponse.data);
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: "Exportação concluída com sucesso."
          })
        );
        setIsDownloading(false);
        setShowModalGerarExcel(false);
      } else {
        handleStatusArquivo(url);
        contador = contador + 5000;
      }
    }, 5000);
  };

  const handleExportExcel = async () => {
    setIsDownloading(true);

    const distribuidorasSemTodos = listaDistribuidorasEditada.filter((el) => {
      return el.nome !== "Todos";
    });

    let paramdistribuidoras = [];
    let paramanos = [];

    // Prepara parâmetro Distribuidora
    if (distribuidorasSelecionadas.some((d) => d.nome === "Todos")) {
      paramdistribuidoras = distribuidorasSemTodos
        .map((item) => {
          return item.id;
        })
        .join();
    } else if (distribuidorasSelecionadas.length > 0) {
      paramdistribuidoras = distribuidorasSelecionadas
        .map((item) => {
          return item.id;
        })
        .join();
    } else {
      setShowModalGerarExcel(false);
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Escolha ao menos uma distribuidora para exportação."
        })
      );
      return;
    }

    // Prepara parâmetro Ano
    if (
      anoSelecionado !== undefined &&
      anoSelecionado !== null &&
      anoSelecionado !== ""
    ) {
      paramanos = anoSelecionado;
    } else {
      setShowModalGerarExcel(false);
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Escolha um ano inicial de histórico para exportação."
        })
      );
      return;
    }

    /*
            Se parâmetro distribuidoras foi carregado com sucesso
        */
    if (distribuidoras.length > 0) {
      try {
        const response = await getExcelEfeitoConsTarifMed(
          idgrupo,
          paramdistribuidoras,
          paramanos,
          nomeArquivo,
          usuarioGlobal.usuario.nome,
          consolidado,
          tipoTarifa
        );

        setDistribuidorasSelecionadas([]);
        handleAnos();

        const urlDownload = response.data.Resposta;

        handleStatusArquivo(urlDownload);
      } catch (error) {
        setDistribuidorasSelecionadas([]);
        handleAnos();
        setIsDownloading(false);
        setShowModalGerarExcel(false);
        setMsgFalha(`Erro não previsto: ${error}`);
        setModalInfo(true);
      }
    }
  };

  return <>
    <ModalInformacao
      titulo={"Exportação não realizada com a seguinte falha:"}
      exibir={modalInfo}
      mensagem={msgFalha}
      onClose={() => {
        setModalInfo(false);
      }}
      showBotao={false}
    />
    <Loader loading={isLoading} className="w-auto">
      <ModalConfirmacao
        item={showModalGerarExcel}
        onCancelar={() => setShowModalGerarExcel(false)}
        onConfirmar={() => isDownloading ? () => { } : handleExportExcel()}
        className={classes.container}
        mensagem={
          <pre>
            <Loader loading={isDownloading} className="w-auto">
              <strong>
                <p>
                  Gerar planilha excel com Efeito Consumidor e Tarifa Média e
                  fazer download?
                </p>
              </strong>
            </Loader>
            <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-nome-arquivo-excel">
                Nome do Arquivo
              </InputLabel>
              <Tooltip
                placement="top"
                title={
                  <h6>Nome sugerido. Aceite ou edite antes de confirmar.</h6>
                }
              >
                <OutlinedInput
                  id="outlined-nome-arquivo-excel"
                  type="text"
                  value={nomeArquivo}
                  onChange={(e) => setNomeArquivo(e.target.value)}
                  label="Nome do Arquivo a Gerar"
                />
              </Tooltip>
            </FormControl>
          </pre>
        }
      />
      <Grid container item spacing={0} alignItems="center">
        <Typography
          variant="h4"
          style={{ color: Base.OffWhite, fontSize: 20 }}
        >
          Exportação de Efeito Consumidor e Tarifa Média
        </Typography>
        <Grid item xs={1} className={classes.backButton}>
          <BotaoRetornarListagem voltarPagina />
        </Grid>
      </Grid>
      <Grid container spacing={3} alignItems="center">
        <Grid item>
          <Typography
            variant="h6"
            style={{ color: Base.OffWhite, fontSize: 20 }}
          >
            Grupo: {titulogrupo}
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        style={{
          paddingLeft: "1.5em",
          paddingRight: "1.5em",
          marginTop: "1.5em"
        }}
      >
        <Grid
          container
          spacing={3}
          alignItems="center"
          className={classes.container}
          justifyContent="space-between"
        >
          <Grid item xs={2}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="consolidado">Consolidado</InputLabel>
              <Select
                variant="outlined"
                labelId="consolidado"
                id="consolidado"
                value={consolidado}
                onChange={onChangeConsolidado}
                label="Consolidado"
                fullWidth>
                <MenuItem key="1-s" value={"Sim"}>
                  Sim
                </MenuItem>
                <MenuItem key="1-n" value={"Não"}>
                  Não
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="tipoTarifa">Tipo Tarifa</InputLabel>
              <Select
                variant="outlined"
                labelId="tipoTarifa"
                id="tipoTarifa"
                value={tipoTarifa}
                onChange={onChangeTipoTarifa}
                label="Tipo Tarifa"
                fullWidth>
                <MenuItem value="Aplicação">Aplicação</MenuItem>
                <MenuItem value="Econômica">Econômica</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <SelectAutocompleteCheckbox
              id="selectDistribuidoraId"
              name="distribuidora"
              options={listaDistribuidorasEditada}
              label="Distribuidora"
              style={classes}
              getOptionLabel={(option) => option.nome || ""}
              onChange={(e, item) => {
                onChangeDistribuidora(item);
              }}
              value={distribuidorasSelecionadas}
              disabled={consolidado === "Sim"}
              allowClear
            />
          </Grid>

          <Grid item xs={3}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="anoinicialdohistorico">
                Ano Inicial do Histórico
              </InputLabel>
              <Select
                variant="outlined"
                labelId="anoinicialdohistorico"
                id="anoinicialdohistorico"
                value={anoSelecionado}
                onChange={onChangeAnos}
                label="Ano Inicial do Histórico"
                fullWidth>
                {anos.map((ano) => (
                  <MenuItem key={ano} value={ano}>
                    {ano}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container alignItems="left" style={{ paddingTop: "15px" }}>
          <Grid xs={2} style={{ paddingTop: "15px" }}>
            <Botao
              type="button"
              label="Gerar Excel"
              color={Base.Mango}
              className={classes.button}
              onClick={() => {
                setShowModalGerarExcel(true);
              }}
              disabled={distribuidorasSelecionadas.length === 0}
            />
          </Grid>
        </Grid>
      </Grid>
    </Loader>
  </>;
};

export default EfeitoConsumidorTarifaMedia;
