
export function salvarNotificacoes(notificacoes) {
  return {
    type: "@notificacoes/salvarNotificacoes",
    payload: notificacoes
  };
}

export function zerarQuantidadeNotificacoes() {
  return {
    type: "@notificacoes/zerarQuantidadeNotificacoes"
  };
}