/* eslint-disable import/no-unresolved */
/* eslint-disable eqeqeq */
import React, { useState, useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import { useForm, useWatch } from "react-hook-form";
import DataTable from "react-data-table-component";
import Coluna from "componentes/tabelaPaginada/colunas/coluna";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import ColunaComponentePersonalizado from "componentes/tabelaPaginada/colunas/colunaComponentePersonalizado";
import Loader from "componentes/loader";
import MaterialInputTexto from "componentes/inputTexto/materialInput";
import BotaoRetornarListagem from "componentes/botaoRetornarListagem";
import RotasDTO from "../../../rotas/rotasUrlDto";
import { useStyles, customStyles, conditionalRowStyles } from "./style";
import { buscaListaConsultas } from "../../../servicos/grupoProjecoes";
import enumeradorBotao from "./enumeradores/enumeradorBotao";
import ColunaMultiplosBotoes from "./colunaCustomizada";

const GrupoProjecaoRelatorioListar = () => {
  const { idgrupo, nomegrupo, titulocenario, idcenario } = useParams();
  const history = useHistory();
  const classes = useStyles();

  const [atual, setAtual] = useState();
  const [atualFiltrado, setAtualFiltrado] = useState([]);
  const [carregandoDados, setCarregandoDados] = useState(false);

  const { register, control } = useForm({
    reValidateMode: "onSubmit"
  });

  const textoBusca = useWatch({
    control,
    name: "textoBusca",
    defaultValue: ""
  });

  const stringBusca = useMemo(() => {
    return textoBusca;
  }, [textoBusca]);

  const obterInsumos = async () => {
    setCarregandoDados(true);
    try {
      const lista = await buscaListaConsultas();
      if (lista?.data !== null) {
        const response = lista?.data;
        const organizado = [];
        if (response) {
          response.map((item) => organizado.push({
            ...item
          }));
        }
        setAtual(organizado);
        setAtualFiltrado(organizado);
      }
      setCarregandoDados(false);
    } catch (error) {
      setCarregandoDados(false);
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ?? error?.message ?? "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };


  function filterByNome(obj) {
    if (obj.titulo != null && obj.titulo.toUpperCase().includes(textoBusca.toUpperCase())) {
      return true;
    }
    return false;

  };

  const handleFiltrarInsumos = () => {
    let dadosFiltrados = [];

    if (atual != null && atual.length > 0) {
      dadosFiltrados = atual;

      if (stringBusca != undefined && stringBusca.length > 0) {
        dadosFiltrados = dadosFiltrados.filter(filterByNome);
      }
    }
    setAtualFiltrado(dadosFiltrados);
  };

  useEffect(() => {
    setTimeout(() => {
      handleFiltrarInsumos();
    }, 100);
  }, [stringBusca]);

  const onClickImportar = (id) => {

    const rota = atual.filter((i) => i.id == id)[0]?.comando
    /*
    if (rota != null && rota != undefined && rota != "") {
      history.push(`${RotasDTO.ProjecaoInsumoListar}${rota}/${idgrupo}/${nomegrupo}`);
    } else {*/
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Tela para importação não configurada"
        })
      );
  };


  const onClickVisualizar = (codigo) => {
    const rota = atual.filter((i) => i.codigo == codigo)[0]?.comando

    if (rota != null && rota != undefined && rota != "") {
      history.push(`${RotasDTO.GrupoProjecaoRelatorioListar}${rota}/${idgrupo}/${nomegrupo}`);
    } else {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Tela para visualização não configurada"
        })
      );
    }
  };

  const onClickVisualizarHistorico = (idProjecaoInsumo) => {
    if (idProjecaoInsumo != null && idProjecaoInsumo != undefined && idProjecaoInsumo != 0) {
      const dataUltimaImportacao = atual.filter((i) => i.id == idProjecaoInsumo)[0]?.dataUltimaImportacao

      if (dataUltimaImportacao != null && dataUltimaImportacao != undefined && dataUltimaImportacao != "") {
        history.push(`${RotasDTO.ProjecaoInsumoListar}/historico/${idProjecaoInsumo}`);
      } else {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "Histórico não encontrado"
          })
        );
      }
    }
  };

  const onClickBotao = (tipo, parametros) => {

    switch (tipo) {
      case enumeradorBotao.Importar:
        onClickImportar(parametros.codigo);
        break;
      case enumeradorBotao.Visualizar:
        onClickVisualizar(parametros.codigo);
        break;
      case enumeradorBotao.Historico:
        onClickVisualizarHistorico(parametros.codigo);
        break;
      default:
        break;
    }
  };

  const colunas = [
    new Coluna("titulo", "Título", false, "700px", "700px"),
    new ColunaComponentePersonalizado(
      "opcoes",
      "Ações",
      ColunaMultiplosBotoes,
      onClickBotao,
      false,
      false,
      "400px"
    )
  ];

  useEffect(() => {
    const abortController = new AbortController();
    obterInsumos(abortController);
    return () => {
      abortController.abort();
    }
  }, []);

  return (
    <>
      <Loader loading={carregandoDados} className="w-auto">
      <Grid container spacing={2} alignItems="center">
            <Grid item xs={4} className={classes.inputCustomContainer}>
              <MaterialInputTexto
                  fullWidth
                  type="text"
                  id="tituloGrupo"
                  name="tituloGrupo"
                  label="Relatórios para o Grupo"
                  className = {classes.formControl}
                  style={{opacity: "0.6"}}
                  renderIconShowHide={false}
                  defaultValue={nomegrupo}
                  disabled
              />
            </Grid>
            <Grid item xs={2} className={classes.inputCustomContainer}>
              <MaterialInputTexto
                  fullWidth
                  type="text"
                  id="tituloCenario"
                  name="tituloCenario"
                  label="Cenário"
                  className={classes.formControl}
                  style={{opacity: "0.6"}}
                  renderIconShowHide={false}
                  defaultValue={titulocenario}
                  disabled
              />
            </Grid>
            <Grid item xs={5}></Grid>
          <Grid item xs={1} className={classes.backButton}>
                    <BotaoRetornarListagem voltarPagina />
          </Grid>

        </Grid>

        <Grid container mt={1} spacing={2}>
          <Grid item xs={5} xl={7}>
            <MaterialInputTexto
              type="text"
              id="textoBusca"
              name="textoBusca"
              label="Buscar"
              renderIconShowHide
              searchAdornment
              defaultValue={textoBusca ?? ""}
              ref={register}
              className={classes.search}
              permiteValorBranco
            />
          </Grid>
        </Grid>
        <Grid container  alignItems="stretch" justifyContent="space-around">
          <Grid item>
          {atualFiltrado?.length > 0 ?
            <DataTable
              subHeader
              subHeaderAlign='rigth'
              columns={colunas}
              data={atualFiltrado}
              theme="DeltaEnergia"
              customStyles={customStyles}
              conditionalRowStyles={conditionalRowStyles}
              noDataComponent="Nenhum registro encontrado"
              noHeader
            />
            :
            ""
          }
          </Grid>
        </Grid>
      </Loader>
    </>
  )
}

export default GrupoProjecaoRelatorioListar;