export default class FiltroDto {
  constructor(
    tamanhoPaginacao,
    pagina,
    searchString,
    dataInicial,
    dataFinal,
    campoPesquisa,
    ordernar,
    situacao,
    situacaoCupom
  ) {
    this.tamanhoPaginacao = tamanhoPaginacao;
    this.pagina = pagina;
    this.searchString = searchString;
    this.dataInicioVigencia = dataInicial;
    this.dataFimVigencia = dataFinal;
    this.campoTextoSelecionado = campoPesquisa;
    this.SortOrder = ordernar;
    this.situacao = situacao;
    this.situacaoCupom = situacaoCupom;
  }
}
