import { makeStyles } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import styled from "styled-components";
import theme from "themes";
import { Base } from "../../../componentes/cores";

export const useStyles = makeStyles({
  container: {
    margin: "0 0 24px",
    width: "100%"
  },
  pagination: {
    textAlign: "center"
  },
  validacaoCredito: {
    fontSize: 14,
    marginBottom: 15
  },
  gridPadding: {
    "& div": {
      marginBottom: 5,
      color: Base.GrayMedidores
    },
    "& b": {
      marginRight: 7,
      color: Base.BlackRussian
    }
  },
  gridPaddingTopo: {
    marginBottom: 20,
    padding: 10,
    backgroundColor: Base.GrayMedidores,
    "& div": {
      marginBottom: 5,
      color: Base.BlackRussian
    },
    "& b": {
      marginRight: 15,
      color: Base.BlackRussian
    }
  },
  dataConsultaSerasa: {
    marginBottom: 5,
    color: Base.GrayMedidores,
    "& b": {
      fontWeight: "bold",
      marginRight: 10,
      color: Base.BlackRussian
    }
  },
  btnAcoes: {
    textAlign: "left",
    marginTop: 30
  },
  btnDownload: {
    backgroundColor: Base.BlueTuna,
    color: Base.WhiteGhost,
    fontSize: 16,
    "&:hover": {
      backgroundColor: Base.BlackRussian
    }
  },
  btnRejeitado: {
    backgroundColor: Base.GrayMedidores,
    color: Base.WhiteGhost,
    fontSize: 16,
    "&:hover": {
      backgroundColor: Base.BlackRussian
    }
  },
  btnValidado: {
    backgroundColor: Base.GreenFern,
    float: "right",
    color: Base.WhiteGhost,
    fontSize: 16,
    "&:hover": {
      backgroundColor: Base.BlackRussian
    }
  },
  search: {
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px"
    },
    "& label.Mui-focused": {
      color: `${theme.color.secondaryBorderColor} !important`
    },
    "& .MuiInput-underline:after": {
      borderColor: `${theme.color.secondaryBorderColor} !important`
    },
    "& .MuiFormLabel-root": {
      color: `${theme.color.secondaryBorderColor} !important`
    },
    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-input": {
        color: `${theme.color.secondaryBorderColor} !important`
      },
      "& .MuiInputAdornment-root": {
        color: `${theme.color.secondaryBorderColor} !important`
      },
      "& fieldset": {
        borderColor: `${theme.color.secondaryBorderColor} !important`
      },
      "&:hover fieldset": {
        borderColor: `${theme.color.secondaryBorderColor} !important`
      },
      "&.Mui-focused fieldset": {
        borderColor: `${theme.color.secondaryBorderColor} !important`
      }
    }
  },
  itemFiltro: {
    display: "flex",
    justifyContent: "flex-end"
  },
  buttonFiltro: {
    fontSize: "18px",
    maxWidth: "220px"
  },
  aprovarTitulo: {
    color: theme.color.secondary,
    textAlign: "center"
  }
});

export const th = createTheme();

export const chipStyles = makeStyles(() =>
  createStyles({
    previewChip: {
      minWidth: 160,
      maxWidth: 210,
      [th.breakpoints.down("md")]: {
        minWidth: 150,
        maxWidth: 150
      },
      [th.breakpoints.down("lg")]: {
        minWidth: 100,
        maxWidth: 100
      },
      [th.breakpoints.down("xl")]: {
        minWidth: 150,
        maxWidth: 150
      },
      [th.breakpoints.down("xl")]: {
        minWidth: 150,
        maxWidth: 150
      }
    }
  })
);

export const BotaoDownload = styled.button`
  background-color: transparent;
  border: none;
  margin-bottom: 5px;
`;
