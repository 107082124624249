import React from "react";

// Styles
import { useStyles } from "./style";

const PrecosCabecalho = () => {
  const classes = useStyles();

  return (
    <div className={classes.tabelaPrecos}>
      <div className={classes.tabelaPrecosCabecalho}>Preços</div>
      <div className={classes.tabelaPrecosColuna}>
        <div className={classes.border}>Conv</div>
        <div className={classes.border}>I5</div>
        <div>I1</div>
      </div>
    </div>
  );
};

export default PrecosCabecalho;
