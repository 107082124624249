import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const api = () => {
  return obterApi(MICROSERVICO.MANAGEMENT);
};

export const listarOrganizarPor = () => {
  const ordenar = [
    {
      key: "1",
      asc: "0",
      desc: "1",
      label: "Colaborador"
    },
    {
      key: "4",
      asc: "2",
      desc: "3",
      label: "Cupom"
    }
  ];

  return ordenar;
};

export const listarFiltrarPor = () => {
  const filtros = [
    {
      key: "AT",
      label: "Ativo"
    },
    {
      key: "IN",
      label: "Inativo"
    }
  ];

  return filtros;
};

export const listarCupons = (filtro) => {
  const filtroQueryString = new URLSearchParams(filtro).toString();
  return api().get(`/v1/ColaboradorCupom?${filtroQueryString}`);
};

export const atualizarCupom = (id, model) => {
  return api().put(`/v1/ColaboradorCupom/${id}`, model);
};
