import React from "react";
import SelectMultiplo from "../componentes/SelectMultiplo";
import { Celula } from "./style";

export default class ColunaDocumentos {
  constructor(
    label,
    nome,
    opcoes,
    opcao,
    onSelecionar,
    minWidth = null,
    maxWidth = null
  ) {
    this.name = (
      <SelectMultiplo
        id={nome}
        name={nome}
        label={label}
        opcao={opcao}
        opcoes={opcoes}
        setOpcao={(item) => {
          onSelecionar(item);
        }}
      />
    );

    const verificarCor = (status) => {
      if (status === "Finalizado") return "#8FD14F";

      if (status === "Em Andamento" || status === "Requer Validação")
        return "#FEF445";

      if (status === "Atrasado") return "#F099C1";

      if (status === "Com Problema") return "#F24726";

      return "#FFFFFF";
    };

    this.selector = label;
    this.cell = (row) => (
      <Celula cor={verificarCor(row[nome])}>{row[nome]}</Celula>
    );
    this.maxWidth = maxWidth;
    this.minWidth = minWidth;
  }
}
