import { saveAs } from "file-saver";
import RetornoEndpointDto from "../global/dto/retornoEndpointDto";

// eslint-disable-next-line consistent-return
export const baixarArquivo = async (api, url, nomeArquivo) => {
  try {
    const response = await api().get(url, { responseType: "blob" });

    if (response.status === 204)
      return RetornoEndpointDto.Erro("Ocorreu uma falha ao obter o arquivo");

    const blob = new Blob([response.data]);

    saveAs(blob, nomeArquivo);
  } catch (error) {
    return RetornoEndpointDto.Erro("Ocorreu uma falha ao obter o arquivo");
  }
};
