import {
  obterPorId,
  salvarCotacaoInicial
} from "../../../servicos/cotacaoInicialServico";
import RetornoEndpointDto from "../../../global/dto/retornoEndpointDto";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";
import { store } from "../../../global/redux";
import { gerarPlanosSimulados } from "../../../servicos/planosServico";
import RadioDto from "../../../componentes/inputRadioGroup/radioDto";

export default class CotacaoInicialHelper {
  static async obterCotacaoPorId(id) {
    try {
      const resultado = await obterPorId(id);

      if (resultado.status === 204)
        return RetornoEndpointDto.Erro(`Não encontrada cotação com Id ${id}`);

      return RetornoEndpointDto.Sucesso(
        "Cotação obtida com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(`Não encontrada cotação com Id ${id}`);
    }
  }

  static async salvarCotacao(dadosCotacao, isEdicao, id) {
    try {
      const resultado = await salvarCotacaoInicial(dadosCotacao, isEdicao, id);

      return RetornoEndpointDto.Sucesso(
        "Cotação Inicial salva com sucesso!",
        resultado.data
      );
    } catch (error) {
      if (!error || !error?.response)
        return RetornoEndpointDto.Erro("Não foi possivel salvar a cotação");

      return RetornoEndpointDto.Erro(
        this.formatarMesagemErro(error, "Não foi possivel salvar a cotação")
      );
    }
  }

  static async simularPlanos(cotacaoId) {
    try {
      await gerarPlanosSimulados(cotacaoId);

      return RetornoEndpointDto.Sucesso("Planos gerados com sucesso!");
    } catch (error) {
      return RetornoEndpointDto.Erro("Não foi possivel gerar os planos");
    }
  }

  static obterDescontoBaseado() {
    return [
      new RadioDto("Consumo de energia", "1", false, false),
      new RadioDto("Custo financeiro", "2", false, false)
    ];
  }

  static obterOpcoesSimNao() {
    return [new RadioDto("Sim", true), new RadioDto("Não", false)];
  }

  static formatarMesagemErro(error, erroPadrao) {
    try {
      if (!(error?.response?.data?.message ?? false)) return erroPadrao;

      const str = error.response.data.message;

      return str.split(" :: ")[1].replace("\r", "").replace("\n", "");
    } catch (err) {
      return erroPadrao;
    }
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }

  static exibirSucesso(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem
      })
    );
  }
}
