import styled from 'styled-components';
import { makeStyles } from '@mui/styles';
import { Base } from 'componentes/cores';

export const useStyles = makeStyles(() => ({
  titulo: {
    fontWeight: "bold"
  },

  container: {
    width: "100%",
    "& .MuiInputBase-root": {
        color: `${Base.OffWhite} !important`,
        height: "55px",
        "& input": {
          pointerEvents: "none"
        }
    },
    "& .MuiInputBase-input": {
      maxWidth: "90px"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0 none"
    },
    "& .MuiFormLabel-root": {
      lineHeight: "1",
      color: Base.White
    },
    "& .MuiSvgIcon-root": {
      color: Base.White
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${Base.White} !important`
    }            
  },

  itemGrafico: {
    "& .apexcharts-tooltip.apexcharts-theme-light": {
      border: "0 none",
      background: "none",
      width: "fit-content",
      height: "fit-content",
      textAlign: "center",
      fontSize: "13px"
    }
  },

  noData: {
    textAlign: "center",
    width: "100%",
    minHeight: "200px",
    "& div": {
      margin: "125px auto 0",
      maxWidth: "80%"
    }
  },

  arrowBox: {
    position: "relative",
    background: "#647480",
    padding: "8px",
    borderColor: "#647480"
  },

  arrowBoxTitle: {
    color: "#a6b9c8",
    fontSize: "10px"
  },

  formControl: {
    "& .MuiInputBase-root": {
      backgroundColor: "#303642",
      borderRadius: 4,
      height: "36px",
      color: Base.White
    },
    "& .MuiSelect-icon": {
      color: Base.White
    },
    "& option": {
      color: Base.BlackBastille
    },
    "& .MuiSelect-select.MuiSelect-select": {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      color: "#eef0f9"
    },
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      padding: "0 40px 0 20px "
    },
    "& .MuiFormLabel-root": {
      color: `${Base.OffWhite} !important`,
    }
  },

  select: {
    ".MuiSvgIcon-root": {
        color: `${Base.OffWhite} !important`,
    }   
},

inputRoot: {
    color: `${Base.White} !important`,
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: `${Base.White} !important`
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: `${Base.White} !important`
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: `${Base.White} !important`
    }
  }


}));

export const Container = styled.div`
  background-color: ${Base.BlueZodiac};
  border-radius: 4px;
  color: ${Base.OffWhite};
  height: 100%;
  min-height: ${(props) => props.height};
`;

