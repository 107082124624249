import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { BotaoCadastro } from "../../../../componentes";
// import InputBlock from "../InputBlock";
import { useStyles } from "./style";
import theme from "../../../../themes";
import InputBlock from "../InputBlock";

const FiltrosSerasa = ({
  adicionarLinha,
  ultimaAtualizacao,
  inativarAdd,
  label
}) => {
  const classes = useStyles();
  return (
    <Grid container spacing={2}>
      <Grid item lg={6} md={3} sm={12} xs={12}>
        <BotaoCadastro
          label={label}
          type="button"
          onClick={() => adicionarLinha()}
          disabled={inativarAdd}
          color={theme.color.primary}
          className={classes.adicionar}
        />
      </Grid>
      <Grid item lg={2} md={3} sm={12} xs={12}>
        <InputBlock
          type="text"
          id="ultimaAtualizacao"
          name="ultimaAtualizacao"
          label="Última atualização"
          value={ultimaAtualizacao}
          className="w-100 pr-2"
          readOnly
          disabled
        />
      </Grid>
    </Grid>
  );
};

FiltrosSerasa.propTypes = {
  adicionarLinha: PropTypes.oneOfType([PropTypes.func]),
  ultimaAtualizacao: PropTypes.string,
  inativarAdd: PropTypes.bool,
  label: PropTypes.string
};

FiltrosSerasa.defaultProps = {
  adicionarLinha: () => {},
  ultimaAtualizacao: "",
  inativarAdd: false,
  label: ""
};

export default FiltrosSerasa;
