import RetornoEndpointDto from "../../../global/dto/retornoEndpointDto";
import {
  obtersimulacoes,
  downloadSimulacao
} from "../../../servicos/simulacoesServico";

export default class SimulacaoHelper {
  static async listarSimulacoes(filtro) {
    try {
      const resultado = await obtersimulacoes(filtro);

      return RetornoEndpointDto.Sucesso(
        "Listagem de simulações obtidas com sucesso",
        resultado.data
      );
    } catch (error) {
      if (error.response.status === 404) {
        return RetornoEndpointDto.Erro("Nenhuma simulação encontrada!");
      }
      return RetornoEndpointDto.Erro(
        "Ocorreu um erro ao obter a lista de simulações"
      );
    }
  }

  static async downloadSimulacao(filtro) {
    try {
      await downloadSimulacao(filtro);

      return RetornoEndpointDto.Sucesso(
        "Listagem de simulações obtidas com sucesso"
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu um erro ao obter a lista de simulações"
      );
    }
  }
}
