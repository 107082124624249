import RetornoEndpointDto from "../../../global/dto/retornoEndpointDto";
import { ListarAlteracaoMedicoesConsolidadas } from "../../../servicos/consolidacaoMedicaoServico";

export default class ConsolidacaoMedicaoHelper {

  static async ListarAlteracaoMedicoesConsolidadas(filtro) {
    try {
      const resultado = await ListarAlteracaoMedicoesConsolidadas(filtro);

      return RetornoEndpointDto.Sucesso(
        "Alteração de medições obtidas com sucesso",
        resultado
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu um erro ao obter a alteração de medições"
      );
    }
  }

}
