import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import moment from "moment";

// Componentes
import { Grid, Card, Box } from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import IconeCodigoBarras from "assets/home/icone-codigo-barras.png";
import Loader from "componentes/loader";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { RotasDTO } from "global/rotas/rotasUrlDto";
import formatarValor from "global/utils/formatarValor";
import { enviarFaturasPorEmail } from "servicos/dgIntegracaoServico";
import SemDados from "../SemDados";

// Styles
import { useStyles, Botao, Chip, Icone } from "./style";

const SuaFatura = ({ carregandoFatura, dadosFatura }) => {
  const classes = useStyles();

  const faturaStatus = [
    { status: "Em aberto", texto: "FATURA ABERTA", cor: "#2ABF6F" },
    { status: "Pago", texto: "FATURA PAGA", cor: "#2ABF6F" },
    { status: "Vencida", texto: "FATURA VENCIDA", cor: "#BF2A2A" },
    { status: "Pendente", texto: "FATURA PENDENTE", cor: "#FF9600" }
  ];

  const copiarCodigo = () => {
    if (!dadosFatura[0]?.codigoPagamento) return;
    const textField = document.createElement("textarea");
    textField.innerText = dadosFatura[0]?.codigoPagamento;
    document.body.appendChild(textField);
    textField.select();
    if (navigator?.clipboard) {
      navigator.clipboard.writeText(dadosFatura[0]?.codigoPagamento);
    } else {
      document.execCommand("copy");
    }
    textField.remove();

    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem: "Fatura copiada com sucesso!"
      })
    );
  };

  const enviarFatura = async (id) => {
    try {
      const { status } = await enviarFaturasPorEmail({
        faturasIds: [id]
      });

      if (status === 200) {
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: "Fatura enviada com sucesso!"
          })
        );
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem:
            error?.response?.data?.message ||
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  return (
    <Loader loading={carregandoFatura}>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: { xs: "space-between" },
            alignItems: { xs: "center" },
            background: "#FBFBFD"
          }}
          p={2}
        >
          <h3 className={classes.titulo}>Sua fatura</h3>
          <Link to={RotasDTO.FaturasGD}>
            <Botao>Ver detalhes</Botao>
          </Link>
        </Grid>
        {dadosFatura?.length > 0 ? (
          <>
            <Grid item xs={12} px={2} pt={2} pb={1}>
              <Card sx={{ background: "#FBFBFD" }} className={classes.card}>
                <Box px={1} py={2}>
                  <Box className={classes.texto}>
                    <span className={classes.textoCapitalize}>
                      {moment(
                        dadosFatura[0]?.dataDeFechamentodaFaturaLUZ
                      ).format("MMM")}
                    </span>
                    {"de "}
                    {moment(dadosFatura[0]?.dataDeFechamentodaFaturaLUZ).format(
                      "YYYY"
                    )}
                  </Box>
                </Box>

                <Box p={1} className={classes.texto}>
                  <HomeOutlinedIcon sx={{ marginRight: "5px" }} />
                  <strong>{dadosFatura[0]?.nome}</strong>
                </Box>
                <Box p={1}>
                  <h3 className={classes.preco}>
                    {formatarValor(dadosFatura[0]?.valorTotalFaturaLUZ)}
                  </h3>
                </Box>
                <Box
                  sx={{
                    display: "flex"
                  }}
                  px={1}
                  py={2}
                >
                  <Chip
                    background={
                      faturaStatus.find(
                        (status) => status.status === dadosFatura[0]?.status
                      )?.cor
                    }
                  >
                    {
                      faturaStatus.find(
                        (status) => status.status === dadosFatura[0]?.status
                      )?.texto
                    }
                  </Chip>
                  <strong className={classes.textoCinza}>
                    Fecha em{" "}
                    {moment(dadosFatura[0]?.dataVencimentoLUZ).format("DD/MM")}
                  </strong>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} px={2} py={1}>
              <Card className={classes.card}>
                <Box
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "space-between" },
                    alignItems: { xs: "center" }
                  }}
                  p={1}
                >
                  <Box className={classes.texto}>
                    <Icone
                      src={IconeCodigoBarras}
                      alt="ícone código do boleto"
                    />
                    <strong>Código do boleto</strong>
                  </Box>
                  <Botao onClick={() => copiarCodigo()}>Copiar</Botao>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} px={2} pt={1} pb={2}>
              <Card className={classes.card}>
                <Box
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "space-between" },
                    alignItems: { xs: "center" }
                  }}
                  p={1}
                >
                  <Box className={classes.texto}>
                    <ArticleOutlinedIcon
                      sx={{
                        marginRight: "10px",
                        height: "1.2em",
                        width: "1.2em"
                      }}
                    />
                    <strong>Enviar por e-mail</strong>
                  </Box>

                  <Botao onClick={() => enviarFatura(dadosFatura[0]?.id)}>
                    Enviar
                  </Botao>
                </Box>
              </Card>
            </Grid>
          </>
        ) : (
          <SemDados texto="Ainda não conseguimos mostrar suas faturas! Em breve, elas estarão disponíveis para você." />
        )}
      </Grid>
    </Loader>
  );
};

SuaFatura.propTypes = {
  carregandoFatura: PropTypes.bool,
  dadosFatura: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      dataVencimentoLUZ: PropTypes.string,
      codigoPagamento: PropTypes.string,
      dataDeFechamentodaFaturaLUZ: PropTypes.string,
      nome: PropTypes.string,
      status: PropTypes.string,
      valorTotalFaturaLUZ: PropTypes.number
    })
  )
};

SuaFatura.defaultProps = {
  carregandoFatura: false,
  dadosFatura: []
};

export default SuaFatura;
