/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import shortid from "shortid";

import TuneIcon from "@mui/icons-material/Tune";
import { Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { TabelaPaginadaMobile } from "../../../componentes";
import BotaoFiltroOrdenacao from "../../../componentes/botaoFiltroOrdenarLuz";
import MaterialInputBusca from "../../../componentes/inputBusca";
import Loader from "../../../componentes/loader";
import SelectArredondado from "../../../componentes/selectArredondado";
import Coluna from "../../../componentes/tabelaPaginada/colunas/coluna";
import ColunaComponentePersonalizado from "../../../componentes/tabelaPaginada/colunas/colunaComponentePersonalizado";
import ResultadoPaginadoDto from "../../../componentes/tabelaPaginada/resultadoPaginadoDto";
import TabelaPaginada from "../../../componentes/tabelaPaginadaLuz";
import ColunaMultiplosBotoes from "./componentes/colunaBotoes";
import ItemListaExpansivelResponsivo from "./componentes/itemListaExpansivelResponsiva";
import ListagemCotacaoHelper from "./helper";

import { store } from "../../../global/redux";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";

import { InterfaceDTO } from "../../../global/dto/interfacesDto";
import { RotasDTO } from "../../../global/rotas/rotasUrlDto";
import FiltroAdicionalTabela from "../ListagemCotacoesGD/dto/filtroAdicionalTabela";
import FiltroDto from "./dto/filtroDto";
import listagemCotacaoSemFaturaDto from "./dto/listagemCotacaoSemFaturaDto";

import theme from "../../../themes";
import { useStyles } from "../ListagemCotacoesGD/style";

import { listarOfficersPorArvoreGerencial } from "../../../servicos/colaboradoresServico";
import { listarFiltrarClientePor } from "../../../servicos/listagemCotacoesGdServico";

import {
  desabilitarCliente,
  desabilitarEmpresa,
  desabilitarUnidade,
  selecionarEmpresa
} from "../../../global/redux/modulos/usuario/actions";

import CustomLoader from "../../../componentes/customLoader";
import { usuarioPossuiFuncionalidade } from "../../../servicos/funcionalidadesServico";
import EmpresaNaoSelecionada from "../ListagemCotacoesGD/componentes/EmpresaNaoSelecionada";

const ListagemCotacoesGD = () => {
  const [ordemSelecionada, setOrdemSelecionada] = useState();
  const [ordemColunaSelecionada, setOrdemColunaSelecionada] = useState();
  const [filtroAdicionalTabela, setFiltroAdicionalTabela] = useState({});
  const [valorOrdenacao, setValorOrdenacao] = useState();
  const rotas = useSelector((state) => state.rotas);
  const usuario = useSelector((state) => state.usuario);
  const { menu } = usuario;
  const permissaoAlterarFiltrarOfficer = usuarioPossuiFuncionalidade(
    menu,
    rotas.rotaAtual?.caminho,
    RotasDTO.CotacoesGDListagemSemFatura,
    InterfaceDTO.PodeAlterarFiltrarOfficer
  );
  const [colaboradorId, setColaboradorId] = useState(
    usuario?.usuario?.colaboradorId
  );
  const [filtroPersonalizado, setFiltroPersonalizado] = useState({
    ...(colaboradorId > -1 && { colaboradorId })
  });

  const [listaOfficer, setListaOfficer] = useState([]);
  const [carregandoOfficer, setCarregandoOfficer] = useState(false);
  const [campoPesquisa, setCampoPesquisa] = useState(null);

  const [
    filtroTipoClienteSelecionado,
    setFiltroTipoClienteSelecionado
  ] = useState(null);

  useEffect(() => {
    if (!colaboradorId) return;

    setFiltroPersonalizado({
      ...(colaboradorId > -1 && { colaboradorId })
    });
  }, [colaboradorId]);

  const { empresaSelecionada } = usuario;

  const obterOfficer = async (empresa) => {
    try {
      setCarregandoOfficer(true);
      const lista = await listarOfficersPorArvoreGerencial(empresa ?? 0);
      let data = lista?.data;
      if (lista.status !== 200 || !lista.data) {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "Officers não encontrados!"
          })
        );
        return;
      }

      if (!permissaoAlterarFiltrarOfficer)
        data = data.filter((d) => d.id === colaboradorId);

      setListaOfficer(data);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    } finally {
      setCarregandoOfficer(false);
    }
  };

  useEffect(() => {
    obterOfficer(empresaSelecionada);
  }, [permissaoAlterarFiltrarOfficer, empresaSelecionada]);

  const history = useHistory();
  const classes = useStyles();
  const themes = useTheme();
  const mobile = useMediaQuery(themes.breakpoints.down("md"));
  const refTabela = useRef(null);

  const { register, control } = useForm({
    reValidateMode: "onSubmit"
  });

  const textoBusca = useWatch({
    control,
    name: "textoBusca",
    defaultValue: ""
  });

  useEffect(() => {
    store.dispatch(selecionarEmpresa(32));
    store.dispatch(desabilitarCliente(true));
    store.dispatch(desabilitarUnidade(true));

    return () => {
      store.dispatch(desabilitarEmpresa(false));
      store.dispatch(desabilitarCliente(false));
      store.dispatch(desabilitarUnidade(false));
    };
  }, []);

  const onChangeSelecionarOfficer = (event) => {
    setColaboradorId(event);
  };

  const onClickBotao = (tipo, parametros) => {
    history.push(`${RotasDTO.CotacoesGD}/${parametros.id}?semFatura=true`);
  };

  const ColunaMobile = ({ parametros }) => {
    return (
      <ItemListaExpansivelResponsivo
        key={shortid.generate()}
        nomeContato={parametros?.nome}
        email={parametros?.email}
        telefone={parametros?.telefone}
        cep={parametros?.cep}
        color={theme.color.buttonColor}
        onClickEditar={() => onClickBotao(null, parametros)}
      />
    );
  };

  const colunas = !mobile
    ? [
        new Coluna(
          "nome",
          "Nome do Cliente/Nome Fantasia",
          false,
          "26%",
          "26%"
        ),
        new Coluna("email", "E-mail", false, "20%", "20%"),
        new Coluna("telefone", "Telefone", false, "20%"),
        new Coluna("cep", "CEP", false, "20%"),
        new ColunaComponentePersonalizado(
          "opcoes",
          "Opções",
          ColunaMultiplosBotoes,
          onClickBotao,
          false,
          true,
          "14%",
          "14%",
          "pl-2"
        )
      ]
    : [
        new ColunaComponentePersonalizado(
          "nome",
          "",
          ColunaMobile,
          null,
          false,
          false,
          null,
          null
        )
      ];

  const obterListagem = useCallback(async (parametros) => {
    if (
      !parametros?.filtroPersonalizado?.colaboradorId ||
      !parametros?.filtroAdicional?.empresaId
    )
      return null;

    const filtro = new FiltroDto(
      parametros?.tamanhoPagina,
      parametros?.pagina,
      {
        nome: "colaboradorId",
        valor: parametros?.filtroPersonalizado?.colaboradorId ?? ""
      },
      parametros?.filtroAdicional?.empresaId ?? "",
      parametros?.filtroAdicional?.tipoCliente ?? "",
      parametros?.campoPesquisa ?? "",
      parametros?.pesquisar,
      parametros?.filtroAdicional?.sortOrder,
      parametros?.filtroAdicional?.ordenacao ?? ""
    );

    const {
      data,
      mensagem,
      sucesso
    } = await ListagemCotacaoHelper.listarCotacoesSemFatura(filtro);

    if (!sucesso) {
      ListagemCotacaoHelper.exibirErro(mensagem);
      return new ResultadoPaginadoDto([], 1, 0, 0);
    }

    if (data.cotacaoGDSemFatura?.length === 0 ?? true)
      return new ResultadoPaginadoDto([], 1, 0, 0);

    if (data === "") return new ResultadoPaginadoDto([], 1, 0, 0);

    const resLinhas = data?.cotacaoGDSemFatura?.map((res) => {
      const telefone =
        res?.telefone?.length >= 11
          ? res?.telefone
              ?.replace(/\D/g, "")
              .replace(/(\d{2})(\d)/, "($1) $2")
              .replace(/(\d{5})(\d)/, "$1-$2")
              .replace(/(-\d{4})\d+?$/, "$1")
          : res?.telefone
              ?.replace(/\D/g, "")
              .replace(/(\d{2})(\d)/, "($1) $2")
              .replace(/(\d{4})(\d)/, "$1-$2")
              .replace(/(-\d{4})\d+?$/, "$1");
      const cep = res?.cep
        ?.replace(/\D/g, "")
        .replace(/(\d{5})(\d)/, "$1-$2")
        .replace(/(-\d{3})\d+?$/, "$1");

      // eslint-disable-next-line new-cap
      return new listagemCotacaoSemFaturaDto(
        res?.id,
        res?.nomeContato,
        res?.email,
        telefone,
        cep
      );
    });

    if (!resLinhas) return false;

    return new ResultadoPaginadoDto(
      resLinhas,
      data?.paginaAtual,
      data?.totalItens,
      data?.totalPaginas
    );
  }, []);

  const onChangeFiltrosTabela = useCallback(
    async (parametros) => {
      try {
        if (
          !permissaoAlterarFiltrarOfficer &&
          (!colaboradorId || colaboradorId === -1)
        )
          return null;

        return obterListagem({
          tamanhoPagina: parametros.totalPagina ?? "",
          pagina: parametros.pagina ?? "",
          pesquisar: parametros.pesquisar ?? "",
          filtroAdicional: new FiltroAdicionalTabela(
            parametros.filtrosAdicionais?.ordenacao ?? "",
            parametros.filtrosAdicionais?.sortOrder ?? "",
            parametros.filtrosAdicionais?.empresaId ?? "",
            parametros?.filtrosAdicionais?.tipoCliente ?? ""
          ),
          filtroPersonalizado: parametros?.filtroPersonalizado ?? "",
          campoPesquisa: campoPesquisa ?? ""
        });
      } catch (error) {
        return null;
      }
    },
    [permissaoAlterarFiltrarOfficer, colaboradorId, campoPesquisa]
  );

  const handleAlterarFiltroLateral = () => {
    setFiltroAdicionalTabela(
      new FiltroAdicionalTabela(
        ordemSelecionada,
        valorOrdenacao,
        empresaSelecionada,
        filtroTipoClienteSelecionado
      )
    );
  };

  useEffect(() => {
    handleAlterarFiltroLateral();
  }, [empresaSelecionada]);

  const handleClickAtivarFiltro = () => {
    setFiltroAdicionalTabela(
      new FiltroAdicionalTabela(
        ordemSelecionada,
        valorOrdenacao,
        empresaSelecionada,
        filtroTipoClienteSelecionado
      )
    );
  };

  const handleClickOrdemSelecionada = (event) => {
    const val = ordemSelecionada === event ? "" : event;
    setOrdemSelecionada(val);
  };

  const handleClickSortOrderSelecionado = (valor, idCampoSelecionado) => {
    const val =
      ordemColunaSelecionada === idCampoSelecionado && valor === ""
        ? ""
        : idCampoSelecionado;
    setOrdemColunaSelecionada(val);
    setValorOrdenacao(valor);
  };

  const handleClickFiltroTipoClienteSelecionado = (event) => {
    const val = filtroTipoClienteSelecionado === event ? "" : event;
    setFiltroTipoClienteSelecionado(val);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={3}>
          <SelectArredondado
            id="CampoPesquisa"
            name="CampoPesquisa"
            label="Filtrar por campo"
            placeholder="Filtrar por campo"
            valueKey="valor"
            valueName="nome"
            dataSource={[
              { nome: "Todos", valor: "" },
              { nome: "E-mail", valor: "Email" },
              { nome: "Nome", valor: "NomeContato" },
              { nome: "Telefone", valor: "Telefone" },
              { nome: "Cep", valor: "Cep" }
            ]}
            value={campoPesquisa}
            onChange={(e) => {
              setCampoPesquisa(e.target.value);
            }}
            permiteValorBranco
            styleForm={false}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          {campoPesquisa && (
            <MaterialInputBusca
              type="text"
              id="textoBusca"
              name="textoBusca"
              label="Buscar"
              renderIconShowHide
              searchAdornment
              permiteValorBranco
              defaultValue={textoBusca ?? ""}
              ref={register}
            />
          )}
        </Grid>
        <Grid item xs={6} lg={3}>
          <Loader loading={carregandoOfficer}>
            <SelectArredondado
              select
              id="officer"
              name="officer"
              label="Officer"
              placeholder="Officer"
              valueKey="id"
              valueName="nomeColaborador"
              dataSource={listaOfficer}
              value={colaboradorId}
              onChange={(event) => {
                onChangeSelecionarOfficer(event.target.value);
              }}
              permiteValorBranco
              ref={register}
              marginBottom="0"
              styleForm={false}
            />
          </Loader>
        </Grid>
        <Grid component={Grid} item xs={6} lg={2}>
          <BotaoFiltroOrdenacao
            type="button"
            color={theme.color.secondaryBorderColor}
            background="transparent"
            label="Filtrar / Ordernar"
            icon={<TuneIcon htmlColor="#FA3EB1" />}
            className={classes.buttonFiltro}
            ordenacaoColuna={[
              {
                key: "0",
                asc: "0",
                desc: "1",
                label: "Nome"
              }
            ]}
            ordenador="Ordenação"
            ordenadorUm="Status"
            ordenadorTres="Tipo Cliente"
            ordemSelecionada={ordemSelecionada}
            ordemColunaSelecionada={ordemColunaSelecionada}
            onClickOrdenacao={handleClickOrdemSelecionada}
            onClickSortOrder={handleClickSortOrderSelecionado}
            onClickAtivarAgora={handleClickAtivarFiltro}
            filtroTipoCliente={listarFiltrarClientePor()}
            filtrosTipoClienteSelecionado={filtroTipoClienteSelecionado}
            onClickFitroTipoCliente={handleClickFiltroTipoClienteSelecionado}
          />
        </Grid>
        {empresaSelecionada === "" ? (
          <EmpresaNaoSelecionada />
        ) : (
          <Grid item xs={12} className={`${classes.tabela} mt-4`}>
            {!mobile ? (
              <CustomLoader>
                <TabelaPaginada
                  ref={refTabela}
                  onChangeFiltrosTabela={onChangeFiltrosTabela}
                  colunas={colunas}
                  pesquisar={textoBusca}
                  filtrosAdicionais={filtroAdicionalTabela}
                  filtroPersonalizado={filtroPersonalizado}
                  paginaAtual={1}
                  paginationPerPage={5}
                />
              </CustomLoader>
            ) : (
              <CustomLoader>
                <TabelaPaginadaMobile
                  className={classes.fundoCelulaTransparente}
                  ref={refTabela}
                  pesquisar={textoBusca}
                  onChangeFiltrosTabela={onChangeFiltrosTabela}
                  filtrosAdicionais={filtroAdicionalTabela}
                  filtroPersonalizado={filtroPersonalizado}
                  linhasTabela={8}
                  colunas={colunas}
                  paginaAtual={1}
                />
              </CustomLoader>
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ListagemCotacoesGD;
