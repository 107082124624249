import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Modal from "@mui/material/Modal";
import { Box, Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useStyles } from "./style";

const ModalLuz = ({
  item,
  onConfirmar,
  onCancelar,
  mensagem,
  titulo,
  footer,
  header,
  textbtn1,
  textbtn2,
  children,
  disabled,
  colorBtn1,
  colorBtn2,
  bgColorBtn1,
  bgColorBtn2
}) => {
  const classes = useStyles();
  const [aberto, setAberto] = useState(false);

  useEffect(() => {
    setAberto(Boolean(item));
  }, [item]);

  const onClose = () => {
    setAberto(false);
    onCancelar();
  };

  return (
    <Modal open={aberto}>
      <Box
        component={Grid}
        container
        justifyContent="center"
        alignItems="center"
        className={classes.container}
        spacing={1}
        width={{ sm: "83%", lg: "50%", xl: "30%" }}
        m={0}
      >
        {header && (
          <Grid container>
            <Grid item xs={11}>
              <Box color="#332053" fontSize="24px" fontWeight="700" mb={2}>
                {titulo}
              </Box>
            </Grid>
            <Grid item xs={1}>
              <CloseIcon cursor="pointer" onClick={onClose} />
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <Box color="#332053" fontSize="16px" fontWeight="400" mb={2}>
            {mensagem}
          </Box>
        </Grid>

        <Grid item xs={12}>
          {children}
        </Grid>

        {footer && (
          <>
            <Box component={Grid} item xs={6}>
              <Box
                component="button"
                id="buttonNao"
                name="buttonNao"
                value="buttonNao"
                width="100%"
                bgcolor={bgColorBtn1}
                fontSize="16px"
                fontWeight="700"
                borderRadius="24px"
                color={colorBtn1}
                border={`1px solid ${colorBtn1}`}
                onClick={onClose}
                py={1}
              >
                {textbtn1}
              </Box>
            </Box>
            <Box component={Grid} item xs={6} textAlign="end">
              <Box
                component="button"
                id="buttonSim"
                name="buttonSim"
                value="buttonSim"
                width="100%"
                fontSize="16px"
                fontWeight="700"
                borderRadius="24px"
                border="none"
                color={colorBtn2}
                bgcolor={bgColorBtn2}
                onClick={onConfirmar}
                disabled={disabled}
                py={1}
              >
                {textbtn2}
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};

ModalLuz.propTypes = {
  item: PropTypes.oneOfType([PropTypes.any]).isRequired,
  onConfirmar: PropTypes.oneOfType([PropTypes.func]),
  onCancelar: PropTypes.oneOfType([PropTypes.func]),
  mensagem: PropTypes.oneOfType([PropTypes.node, PropTypes.any]),
  titulo: PropTypes.string,
  footer: PropTypes.bool,
  header: PropTypes.bool,
  textbtn1: PropTypes.string,
  textbtn2: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  colorBtn1: PropTypes.string,
  colorBtn2: PropTypes.string,
  bgColorBtn1: PropTypes.string,
  bgColorBtn2: PropTypes.string
};

ModalLuz.defaultProps = {
  onConfirmar: () => {},
  onCancelar: () => {},
  mensagem: {},
  titulo: "",
  footer: true,
  header: true,
  textbtn1: "Não",
  textbtn2: "Sim",
  children: () => {},
  disabled: false,
  colorBtn1: "#FA3EB1",
  colorBtn2: "#fff",
  bgColorBtn1: "#fff",
  bgColorBtn2: "#FA3EB1"
};

export default ModalLuz;
