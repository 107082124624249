/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
import moment from "moment";
import React, { useState, useEffect, useCallback } from "react";
import AppBar from "@mui/material/AppBar";
import { useTheme } from "@mui/material/styles";
import SwipeableViews from "react-swipeable-views";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Grid, Card, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { InputTextoData, TabPanel } from "componentes";
import { useStyles, Tabela } from "./style";
import { getItaipuEnergia, getItaipuPotencia, getDistribuidoras } from "../../../servicos/baseItaipu";
import CloseIcon from "@mui/icons-material/Close";
import RotasDTO  from "../../../rotas/rotasUrlDto";  
import { useHistory } from "react-router-dom";

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const BaseItaipuVisualizar = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  
  const [value, setValue] = React.useState(0);

  const [valoresEnergia, setValoresEnergia] = useState([]);
  const [valoresPotencia, setValoresPotencia] = useState([]); 
  const [distribuidoras, setDistribuidoras] = useState([]);
  const [distribuidora, setDistribuidora] = useState("");
  const [anoInicio, setAnoInicio] = useState(moment());
  const [anoFim, setAnoFim] = useState(moment());
  const [parameterChanged, setParameterChanged] = useState(true);

  const obterDistribuidoras = useCallback(async () => {
    try {
      const response = await getDistribuidoras();
      if (response?.status === 200 && response?.data) {
        setDistribuidoras(response?.data);
      }
    } catch (e) {
      console.info(e);
    }
  });

  useEffect(() => {
    if (!distribuidoras.length) obterDistribuidoras();
  }, []);

  const obterValoresEnergia = async () => {
    try {
      const response = await getItaipuEnergia(distribuidora);
      if (response.status === 200 && response.data) {
        const filteredResponse = response?.data.filter(item => {
          return item.ano >= anoInicio.format("yyyy") && item.ano <= anoFim.format("yyyy");
        })

        setParameterChanged(false);
        setValoresEnergia(filteredResponse);
      } else {
        setParameterChanged(false);
        setValoresEnergia([]);
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "Não há dados para exibição. - Energia"
          })
        );
      }
    } catch (exception) {
      setParameterChanged(false);
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            exception?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  const obterValoresPotencia = async () => {
    try {
      const response = await getItaipuPotencia(distribuidora, anoInicio.format("yyyy"), anoFim.format("yyyy"));
      if (response.status === 200 && response.data) {
        setParameterChanged(false);
        setValoresPotencia(response?.data);
      } else {
        setParameterChanged(false);
        setValoresPotencia([]);
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "Não há dados para exibição. - Potência"
          })
        );
      }
    } catch (exception) {
      setParameterChanged(false);
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            exception?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  const obterValores = () => {
    obterValoresEnergia();
    obterValoresPotencia();
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleChangeDistribuidora = async (event) => {
    setDistribuidora(event.target.value);
    setParameterChanged(true);
  };

  const handleChangeAnoInicio = async (data) => {
    setAnoInicio(moment(data));
    setParameterChanged(true);
  }

  const handleChangeAnoFim = async (data) => {
    setAnoFim(moment(data));
    setParameterChanged(true);
  }

  useEffect(() => {
    if (parameterChanged && distribuidora) obterValores();
  }, [parameterChanged && distribuidora, obterValores]);

  const onFechar = (id) => {
    history.push(`${RotasDTO.ProjecaoInsumoListar}`);
  }

  return <>
    <Grid container spacing={4} p={2} className={classes.container} justifyContent="flex-end" alignItems="center" style={{marginRight: "15px"}}>
      <Grid container className={classes.containerTitulo}>
         <Grid item xs={11} className={classes.titulo}>
           Bases Itaipu
          </Grid>
          
          <Grid item xs={1} >             
              <CloseIcon onClick={onFechar} className={classes.icon}/>
          </Grid>
          
        </Grid>
      <Grid item sm={3} className={classes.customSelect}>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="perid">Distribuidora</InputLabel>
          <Select
            labelId="distr"
            id="distr"
            value={distribuidora ?? ""}
            onChange={handleChangeDistribuidora}
            label="Distribuidora"
            fullWidth>
            {distribuidoras.map((dist) => (
              <MenuItem key={dist.idDistribuidora.toString()} value={dist.idDistribuidora}>{dist.titulo}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item sm={2}>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputTextoData
            type="text"
            id="anoInicio"
            name="anoInicio"
            label="Ano Início"
            customValue={anoInicio}
            format="yyyy"
            onChange={(data) => handleChangeAnoInicio(data)}
            minDate={moment().subtract(50, "years").toDate()}
            views={["year"]}
            renderIconShowHide={false}
          />
        </FormControl>
      </Grid>
      <Grid item sm={2} style={{ marginLeft: "15px" }}>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputTextoData
            type="text"
            id="anoFinal"
            name="anoFinal"
            label="Ano Fim"
            customValue={anoFim}
            format="yyyy"
            onChange={(data) => handleChangeAnoFim(data)}
            minDate={moment().subtract(10, "years").toDate()}
            views={["year"]}
            renderIconShowHide={false}
          />
        </FormControl>
      </Grid>
    </Grid>
    <Grid container spacing={4} p={2} className={classes.container}>
      <Grid item sm={12}>
        <Card className={classes.container}>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab label="ENERGIA" {...a11yProps(0)} />
              <Tab label="POTÊNCIA" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              <Grid container spacing={4} p={2} className={classes.container}>
                <Grid item sm={12} style={{ paddingTop: "0px" }}>
                  <div className={classes.customPosition}>
                    <Tabela>
                      <Tabela.Thead>
                        <Tabela.Tr>
                          <Tabela.Th colSpan="12">MIGRAÇÃO E OUTROS</Tabela.Th>
                        </Tabela.Tr>
                        <Tabela.Tr>
                          <Tabela.Th>ANO</Tabela.Th>
                          <Tabela.Th>COTA-PARTE</Tabela.Th>
                          <Tabela.Th>MONTANTE</Tabela.Th>
                        </Tabela.Tr>
                      </Tabela.Thead>
                      <Tabela.Tbody>
                        {valoresEnergia?.map((item) => (
                          <Tabela.Tr>
                            <Tabela.Td>{item.ano}</Tabela.Td>
                            <Tabela.Td>{(item.cotaParte).toFixed(3)}</Tabela.Td>
                            <Tabela.Td>{(item.montante).toFixed(3)}</Tabela.Td>
                          </Tabela.Tr>
                        ))}
                      </Tabela.Tbody>
                    </Tabela>
                  </div>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <Grid container spacing={4} p={2} className={classes.container}>
                <Grid item sm={12} style={{ paddingTop: "0px" }}>
                  <div className={classes.customPosition}>
                    <Tabela>
                      <Tabela.Thead>
                        <Tabela.Tr>
                          <Tabela.Th colSpan="12">MIGRAÇÃO E OUTROS</Tabela.Th>
                        </Tabela.Tr>
                        <Tabela.Tr>
                          <Tabela.Th>DATA</Tabela.Th>
                          <Tabela.Th>POTÊNCIA</Tabela.Th>
                        </Tabela.Tr>
                      </Tabela.Thead>
                      <Tabela.Tbody>
                        {valoresPotencia?.map((item) => (
                          <Tabela.Tr>
                            <Tabela.Td>{moment(item.dataMes).format("DD/MM/YYYY")}</Tabela.Td>
                            <Tabela.Td>{(item.potencia).toFixed(3)}</Tabela.Td>
                          </Tabela.Tr>
                        ))}
                      </Tabela.Tbody>
                    </Tabela>
                  </div>
                </Grid>
              </Grid>
            </TabPanel>
          </SwipeableViews>


        </Card>
      </Grid>
    </Grid>
  </>;
}

export default BaseItaipuVisualizar;
