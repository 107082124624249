import { buscarDadosCadastraisPorCnpj } from "../../../../servicos/dadosCadastraisCnpjServico";
import RetornoEndpointDto from "../../../../global/dto/retornoEndpointDto";
import { store } from "../../../../global/redux";
import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";
import {
  obterAssociacaoLuzCpfCnpj,
  obterAssociacaoLuzPorId,
  salvarAssociacaoLuz,
  obterTipoDocumentoAssociacao,
  gerarUploadDocumentosAssociacao,
  gerarUrlDownload
} from "../../../../servicos/associacaoLuzServico";
import { obterCepCobertura } from "../../../../servicos/correiosServico";

export default class CadastroAssociacaoHelper {
  static async salvarAssociacaoLuz(id, body, carregandoAssociacao) {
    try {
      carregandoAssociacao(true);
      const resultado = await salvarAssociacaoLuz(id, body);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Associação não encontrados!");

      return RetornoEndpointDto.Sucesso(
        `Associação ${id ? "atualizado" : "criado"} criado com sucesso`,
        resultado.data
      );
    } catch (error) {
      if (error.response?.status === 409) {
        return RetornoEndpointDto.Erro("associacaoExists");
      }
      return RetornoEndpointDto.Erro(
        `Erro ao ${id ? "atualizar" : "criar"} associação!`
      );
    } finally {
      carregandoAssociacao(false);
    }
  }

  static async obterCepCobertura(cepCobertura, setCarregandoCep) {
    try {
      setCarregandoCep(true);
      const resultado = await obterCepCobertura(cepCobertura);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro(
          "Obter cobertura única não encontrados!"
        );

      return RetornoEndpointDto.Sucesso(
        "Obter cobertura única com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Obter cobertura única não encontrados!");
    } finally {
      setCarregandoCep(false);
    }
  }

  static async buscarDadosCadastraisPorCnpj(cnpj, setCarregandoNomeFantasia) {
    try {
      setCarregandoNomeFantasia(true);
      const resultado = await buscarDadosCadastraisPorCnpj(cnpj);

      return RetornoEndpointDto.Sucesso(
        "Cnpj obtido com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Erro ao buscar Cnpj!");
    } finally {
      setCarregandoNomeFantasia(false);
    }
  }

  static async obterAssociacaoLuzPorId(params, carregandoAssociacao) {
    try {
      carregandoAssociacao(true);
      const resultado = await obterAssociacaoLuzPorId(params);

      return RetornoEndpointDto.Sucesso(
        "Associação luz obtida com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Erro ao obter associação luz!");
    } finally {
      carregandoAssociacao(false);
    }
  }

  static async obterAssociacaoLuzCpfCnpj(cpfCnpj, carregandoAssociacao) {
    try {
      carregandoAssociacao(true);
      const resultado = await obterAssociacaoLuzCpfCnpj(cpfCnpj);

      return RetornoEndpointDto.Sucesso(
        "Obter cobertura única com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Obter cobertura única não encontrados!");
    } finally {
      carregandoAssociacao(false);
    }
  }

  static async obterTipoDocumentoAssociacao(carregandoAssociacao) {
    try {
      carregandoAssociacao(true);
      const resultado = await obterTipoDocumentoAssociacao();

      return RetornoEndpointDto.Sucesso(
        "Obter cobertura única com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Obter cobertura única não encontrados!");
    } finally {
      carregandoAssociacao(false);
    }
  }

  static async gerarUploadDocumentosAssociacao(body, carregandoAssociacao) {
    try {
      carregandoAssociacao(true);
      const resultado = await gerarUploadDocumentosAssociacao(body);

      return RetornoEndpointDto.Sucesso(
        "Upload documento realizado com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Erro ao gerar upload de documentos!");
    } finally {
      carregandoAssociacao(false);
    }
  }

  static async geraUrlDownload(params, carregandoAssociacao) {
    try {
      carregandoAssociacao(true);
      const resultado = await gerarUrlDownload(params);

      return RetornoEndpointDto.Sucesso(
        "Url download gerada com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Erro ao gerar url documentos!");
    } finally {
      carregandoAssociacao(false);
    }
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }

  static exibirSucesso(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem
      })
    );
  }
}
