import theme from "themes";

import { makeStyles } from "@mui/styles";
import { Base } from "../../../componentes/cores";

export const useStyles = makeStyles({
  containerColorido: {
    padding: "24px",
    backgroundColor: (props) => props.planoCor,
    color: (props) => props.corTexto,
    borderBottomLeftRadius: "0px",
    borderBottomRightRadius: "0px"
  },
  containerBranco: {
    padding: "24px"
  },
  containerPadding: {
    padding: 0
  },
  labelPlano: {
    fontFamily: theme.font.family,
    fontSize: "18px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.4px"
  },
  labelInformacoes: {
    fontFamily: theme.font.family,
    fontSize: "18px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.4px"
  },
  valorInformacoes: {
    fontFamily: theme.font.family,
    fontSize: "20px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.53px"
  },
  valorReducaoCustos: {
    fontFamily: theme.font.family,
    fontSize: "24px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.67px"
  },
  planoNome: {
    fontFamily: theme.font.family,
    fontSize: "24px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.67px"
  },
  containerInformacoes: {
    paddingBottom: "20px"
  },
  divCabecalho: {
    color: `${theme.color.textoBarra}`,
    fontSize: "20px",
    paddingLeft: "24px",
    paddingRight: "24px"
  },
  botaoEscolherDepois: {
    borderRadius: "22px",
    borderWidth: 2,
    color: `${theme.color.textoBarra} !important`,
    borderColor: `${theme.color.textoBarra} !important`,   
    fontSize: "18px"
  },
  botaoEscolher: {
    borderRadius: "22px",
    backgroundColor: Base.Mango,
    padding: "10px 22px",
    width: "100%"
  },
  informacoesAsterisco: {
    color: `${theme.color.textoBarra}`,
    fontSize: "14px",
    marginTop: 24
  },
  dataValidade: {
    color: `${theme.color.textoBarra}`,
    fontSize: "14px",
    marginTop: 24
  },
  barraSuperior: {
    fontSize: "18px",
    fontStyle: "italic",
    height: "42px",
    textAlign: "center",
    color: `${theme.color.textoBarra}`,
    width: "100%"
  },
  arrowBack:{
    color: `${theme.color.textoBarra}`,
    position:"absolute",
    left:0,
    paddingLeft:15
  },
  marginBarraSuperior:{
    marginTop: 15
  },
  divCabecalhoMobile: {
    color: `${Base.White} !important`,
    fontSize: "20px"
  },
  informacoesAsteriscoMobile: {
    color: `${theme.color.textoBarra}`,
    fontSize: "12px",
    marginTop: 14
  },
  areaEscolherDepois:{
    marginTop: 20
  },
  dataValidadeMobile:{
    fontSize: 11,
    padding: "0 20px",
    color: `${theme.color.textoBarra}`,
  }
});
