/* eslint-disable no-continue */
/* eslint-disable consistent-return */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-shadow */
/* eslint-disable prefer-template */
/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect, useCallback } from "react";
import { Grid, FormControl, InputLabel, Select, MenuItem, Tooltip, OutlinedInput, Typography } from "@mui/material";
import { Base } from "componentes/cores";
import { Botao, ModalConfirmacao } from "componentes";
import Loader from "componentes/loader";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { useSelector } from "react-redux";
import SelectAutocompleteCheckbox from "componentes/selectAutocompleteCheckbox";
import { useHistory, useParams } from "react-router-dom";
import { useStyles } from "./style";
import { buscaDistribuidoraPorGrupo } from "../../../servicos/grupoProjecoes";
import { getExcelCustosConsolidados } from "../../../servicos/exportacoes";
import BotaoRetornarListagem from "componentes/botaoRetornarListagem";
import moment from "moment";
import ModalInformacao from "componentes/modalVerificacao";


const CustoTarifaConsolidado = () => {
    const usuarioGlobal = useSelector((state) => state.usuario);
    const classes = useStyles();
    const { idgrupo, titulogrupo } = useParams();

    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);

    const [anos, setAnos] = useState([]);
    const [anoSelecionado, setAnoSelecionado] = useState(2010);

    const [nomeArquivo, setNomeArquivo] = useState("CustosConsolidados.xlsx");
    const [showModalGerarExcel, setShowModalGerarExcel] = useState(false);
    const [modalInfo, setModalInfo] = useState(false);
    const [msgFalha, setMsgFalha] = useState();


    const handleAnos = useCallback(async () => {

        const menorAno = 2010;
        const maiorAno = moment().year();
        let years = [];

        for (var i = menorAno; i <= maiorAno; i++) {
            years.push(i);
        }
        setAnos(years);
        setAnoSelecionado(menorAno);
    });

    const OnChangeAnos = (event) => {
        setAnoSelecionado(event.target.value);
    }

    useEffect(() => {
        if (!anos.length) handleAnos();
    }, [anos.length])


    const handleExportExcel = useCallback(async () => {

        setIsDownloading(true);

        let paramanos = [];

        // Prepara parâmetro Ano
        if (anoSelecionado !== undefined && anoSelecionado !== null && anoSelecionado !== "") {
            paramanos = anoSelecionado
        }
        else {
            setShowModalGerarExcel(false);
            store.dispatch(
                alertaExibir({
                    tipo: "warning",
                    mensagem: "Escolha um ano inicial de histórico para exportação."
                })
            );
            return;
        }
        try {
            const response = await getExcelCustosConsolidados(idgrupo, paramanos, nomeArquivo);

            handleAnos();

            setShowModalGerarExcel(false);

            if (response !== "Sucesso") {
                setMsgFalha(`${response}`);
                setModalInfo(true);
            }
            else {
                store.dispatch(
                    alertaExibir({
                        tipo: "success",
                        mensagem: "Exportação concluída com sucesso."
                    })
                );
            }
        }
        catch (error) {
            handleAnos();
            setIsDownloading(false);
            setShowModalGerarExcel(false);
            setMsgFalha(`Erro não previsto: ${error}`);
            setModalInfo(true);
        }

        setIsDownloading(false);
    });


    return <>
        <ModalInformacao
            titulo={"Exportação não realizada com a seguinte falha:"}
            exibir={modalInfo}
            mensagem={msgFalha}
            onClose={() => {
                setModalInfo(false);
            }}
            showBotao={false}
        />
        <Loader loading={isLoading} className="w-auto">
            <ModalConfirmacao
                item={showModalGerarExcel}
                onCancelar={() => setShowModalGerarExcel(false)}
                onConfirmar={() => handleExportExcel()}
                className={classes.container}
                mensagem={
                    <pre>
                        <Loader loading={isDownloading} className="w-auto">
                            <strong><p>Gerar planilha excel com Custos Consolidados e fazer download?</p></strong>
                        </Loader>
                        <FormControl variant="outlined" >
                            <InputLabel htmlFor="outlined-nome-arquivo-excel">Nome do Arquivo</InputLabel>
                            <Tooltip
                                placement="top"
                                title={
                                    <h6>Nome sugerido. Aceite ou edite antes de confirmar.</h6>
                                }
                            >
                                <OutlinedInput
                                    id="outlined-nome-arquivo-excel"
                                    type="text"
                                    value={nomeArquivo}
                                    onChange={(e) => setNomeArquivo(e.target.value)}
                                    label="Nome do Arquivo a Gerar"
                                />
                            </Tooltip>
                        </FormControl>
                    </pre>
                }
            />
            <Grid container item spacing={0} alignItems="center">
                <Typography variant="h4" style={{ color: Base.OffWhite, fontSize: 20 }}>
                    Exportação de Custos Consolidados do Grupo de Projeções</Typography>
                    <Grid className={classes.backButton}>
                    <BotaoRetornarListagem voltarPagina />
                </Grid>
            </Grid>
            <Grid container spacing={3} alignItems="center">
                <Grid item>
                    <Typography variant="h6" style={{ color: Base.OffWhite, fontSize: 20 }}>
                        Grupo: {titulogrupo}</Typography>
                </Grid>
            </Grid>

            <Grid container style={{ paddingLeft: "1.5em", paddingRight: "1.5em", paddingTop: "25px" }}>
                <Grid
                    container
                    spacing={3}
                    alignItems="center"
                    className={classes.container}
                    justifyContent="space-between"
                >
                    <Grid item xs={3}>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="anoinicialdohistorico">Ano Inicial do Histórico</InputLabel>
                            <Select
                                variant="outlined"
                                labelId="anoinicialdohistorico"
                                id="anoinicialdohistorico"
                                value={anoSelecionado}
                                onChange={OnChangeAnos}
                                label="Ano Inicial do Histórico"
                                fullWidth>
                                {anos.map((ano) => (
                                    <MenuItem key={ano} value={ano}>{ano}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <Botao
                            type="button"
                            label="Gerar Excel"
                            color={Base.Mango}
                            className={classes.button}
                            onClick={() => { setShowModalGerarExcel(true) }}
                        />

                    </Grid>
                    <Grid item xs={7}>
                    </Grid>
                </Grid>
            </Grid>
        </Loader>
    </>;
}

export default CustoTarifaConsolidado;
