import RetornoEndpointDto from "../../../global/dto/retornoEndpointDto";
import {
  uploadMatrizDesconto,
  uploadMatrizDescontoConsumo,
  uploadMatrizDescontoConsumoBaixo,
  uploadMatrizDescontoConsumoPonta,
  getPlanilhaGDBaixa
} from "../../../servicos/uploadServico";

export default class UploadHelper {
  static async enviarArquivo(arquivo) {
    try {
      const resultado = await uploadMatrizDesconto(arquivo);

      return RetornoEndpointDto.Sucesso(
        "Arquivo enviado com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Ocorreu um erro ao enviar o arquivo");
    }
  }

  static async enviarArquivoConsumo(arquivo) {
    try {
      const resultado = await uploadMatrizDescontoConsumo(arquivo);

      return RetornoEndpointDto.Sucesso(
        "Arquivo enviado com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Ocorreu um erro ao enviar o arquivo");
    }
  }

  static async enviarArquivoConsumoPonta(arquivo) {
    try {
      const resultado = await uploadMatrizDescontoConsumoPonta(arquivo);

      return RetornoEndpointDto.Sucesso(
        "Arquivo enviado com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Ocorreu um erro ao enviar o arquivo");
    }
  }

  static async enviarArquivoConsumoBaixa(arquivo) {
    try {
      const resultado = await uploadMatrizDescontoConsumoBaixo(arquivo);

      return RetornoEndpointDto.Sucesso(
        "Arquivo enviado com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Ocorreu um erro ao enviar o arquivo");
    }
  }

  static async obterArquivoConsumoBaixa() {
    try {
      const resultado = await getPlanilhaGDBaixa();

      if (resultado.status === "204") {
        return RetornoEndpointDto.Erro("Nenhum Excel encontrado!");
      }

      return RetornoEndpointDto.Sucesso("Arquivo baixado com sucesso!");
    } catch (error) {
      return RetornoEndpointDto.Erro("Ocorreu um erro ao baixar o arquivo");
    }
  }
}
