import React from "react";
import InputTexto from "./inputTexto";

export default class colunaCampoTexto5Anos {
  constructor(
    field,
    headerName,
    sortable = false,
    onChangeSelecionarDesconto5Anos,
    maxWidth = null,
    minWidth = null,
    adicionalDesconto5Anos = null,
    idCampoDesconto5Anos = null,
    selecionadoId = null,
    enabled = false
  ) {
    this.selector = field;
    this.name = headerName;
    this.sortable = sortable;
    this.maxWidth = maxWidth;
    this.minWidth = minWidth;
    this.adicionalDesconto5Anos = adicionalDesconto5Anos;
    this.idCampoDesconto5Anos = idCampoDesconto5Anos;
    this.selecionadoId = selecionadoId;
    this.enabled = enabled;
    this.cell = (params) => {
      return (
        <InputTexto
          type="text"
          id={params.id}
          name="adicionalDesconto5Anos"
          permiteValorBranco
          onBlur={(event) =>
            onChangeSelecionarDesconto5Anos(event.target.value, params)
          }
          defaultValue={
            params.id === idCampoDesconto5Anos
              ? adicionalDesconto5Anos
              : params?.adicionalDesconto5Anos
          }
          renderIconShowHide={false}
          disabled={!(params.id === selecionadoId && enabled)}
        />
      );
    };
  }
}
