import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  input: {
    borderColor: "transparent !important",

    "& .MuiInputBase-input": {
      padding: 0,
      borderColor: "transparent !important"
    },
    "& fieldset": {
      borderColor: "transparent !important",
      "& :focus-visible": {
        borderColor: "transparent"
      }
    }
  }
}));
