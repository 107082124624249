export default class FiltroAdicionalTabela {
  constructor(
    ordenacao,
    situacao,
    filtroSelecionado,
    dataInicialPesquisa,
    dataFinalPesquisa
  ) {
    this.ordenacao = ordenacao;
    this.situacao = situacao;
    this.situacaoCupom = filtroSelecionado;
    this.dataInicialPesquisa = dataInicialPesquisa;
    this.dataFinalPesquisa = dataFinalPesquisa;
  }
}
