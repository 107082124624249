import React, { useMemo } from "react";
import PropTypes from "prop-types";

// Icones
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import { Grid } from "@mui/material";

import { useStyles } from "./style";

const TreeViewIcon = ({ ativo, nodeId, level, nodesAtivos }) => {
  const classes = useStyles({ color: level, ativo });

  const active = useMemo(() => nodesAtivos.includes(nodeId) ?? false, [
    nodesAtivos,
    nodeId
  ]);

  return (
    <Grid item xs={1} className={`${classes.squareWidth} ${classes.gridItem}`}>
      <div
        className={`${classes.treeViewBorder} ${classes.treeItem} ${classes.wrapper} ${classes.center}`}
        role="button"
        tabIndex={0}
      >
        {active ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </div>
    </Grid>
  );
};

TreeViewIcon.propTypes = {
  ativo: PropTypes.bool,
  nodeId: PropTypes.string,
  level: PropTypes.number,
  nodesAtivos: PropTypes.oneOfType([PropTypes.array])
};

TreeViewIcon.defaultProps = {
  ativo: false,
  nodeId: "",
  level: 0,
  nodesAtivos: []
};

export default TreeViewIcon;
