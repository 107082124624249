import { makeStyles } from "@mui/styles";
import theme from "themes";

export const useStyles = makeStyles({
  inputTextArea: {
    border: "1px solid",
    borderColor: "#D2D2D7",
    borderRadius: "8px",
    minHeight: "152px",
    maxWidth: "100%",
    padding: "12px",
    width: "100%",
    resize: "both",
    overflowY: "auto",
    "& input": {
      border: "none",
      width: "100%",
      outline: "unset",
      "&:focus": {
        boxShadow: "none",
        border: "none"
      }
    },
    "& input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    },
    "& input::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    },
    /* Firefox */
    "& input[type=number]": {
      "-moz-appearance": "textfield"
    }
  },
  contador: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "16px !important",
    color: "#4F4F4F"
  },
  label: {
    color: "#4F4F4F",
    marginTop: "16px",
    marginBottom: "8px"
  },
  chip: {
    background: `${theme.color.primary} !important`,
    color: "#FFFFFF !important",
    margin: "4px !important",
    padding: "8px !important",
    "& .MuiChip-label": {
      padding: "0 8px",
      color: "#FFFFFF"
    },
    "& .MuiChip-deleteIcon": {
      background: `${theme.color.primary} !important`,
      color: "#FFFFFF !important",
      fontSize: "16px !important",
      "& :hover": {
        background: `${theme.color.primary} !important`,
        color: "#FFFFFF !important"
      }
    }
  },
  botao: {
    color: `${theme.color.secondaryText} !important`,
    width: "100%",
    justifyContent: "center",
    justifyItems: "center",
    fontSize: "18px",
    lineHeight: "21.6px",
    fontWeight: 700,
    "&:disabled": {
      backgroundColor: "#9F9F9F",
      opacity: 1,
      color: "#4F4F4F !important",
      borderColor: "#9F9F9F"
    }
  },
  botaoTransparente: {
    color: `${theme.color.secondary} !important`,
    width: "100%",
    justifyContent: "center",
    justifyItems: "center",
    borderColor: `${theme.color.secondary} !important`,
    backgroundColor: "transparent",
    fontSize: "18px",
    lineHeight: "21.6px",
    fontWeight: 700,
    "&:hover": {
      backgroundColor: "transparent !important"
    },
    "&:disabled": {
      opacity: 1,
      color: "#9F9F9F !important",
      borderColor: "#9F9F9F !important"
    }
  },
  naoEncontrado: {
    color: theme.color.primary,
    fontFamily: theme.fontTahoma.family,
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "16px",
    marginBottom: "24px !important"
  }
});
