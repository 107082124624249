import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import moment from "moment";
import { Grid, Card, TextField } from "@mui/material";
import BotaoRetornarListagem from "componentes/botaoRetornarListagem";
import InputData from "componentes/inputTextoData";
import Botao from "componentes/botao";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { useStyles } from "./style";
import { buscarVigenciasWACC, salvarWacc, addWacc } from "../../../servicos/wacc";
import RotasDTO from "../../../rotas/rotasUrlDto";

const ValoresWACCCadastro = () => {
  const { id, type } = useParams();
  const history = useHistory();
  const classes = useStyles();

  const { errors, handleSubmit } = useForm({
    reValidateMode: "onSubmit",
  });

  const [data] = useState(moment().format())

  const [listaWacc, setListaWacc] = useState([]);

  const [dataMinima, setDataMinima] = useState();
  const [dataMaxima] = useState();
  const [inicioVigencia, setInicioVigencia] = useState(null);
  const [finalVigencia, setFinalVigencia] = useState(null);

  const [valorWacc, setValorWacc] = useState("");

  const [futura, setFutura] = useState();
  const [atual, setAtual] = useState();
  const [historico, setHistorico] = useState();

  const onChangeDataInicioVigencia = (dt) => setInicioVigencia(dt);
  const handleValorWacc = (event) => setValorWacc(event.target.value);

  const obterWaccAtual = useCallback(async (lista) => {
    try {
      console.dir(lista);
      setInicioVigencia(lista.inicioVigencia)
      setFinalVigencia(lista.finalVigencia)
      setValorWacc((lista.valorWACC * 100).toLocaleString("pt-BR"))

    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "ObterWaccAtual: Erro interno, entre em contato com o suporte!"
        })
      );
    }
  }, [listaWacc.length]);

  const obterBandeiras = useCallback(async () => {
    try {
      await buscarVigenciasWACC(data).then((response) => {
        
        if (response?.status === 200 && response?.data) {
          setListaWacc(response?.data);

          if (type === "atual") {
            obterWaccAtual(response?.data.vigenciaAtual);
          } 
          else if (type === "agendada") {
            
            if(response?.data.vigenciaFutura.length)
              {
                obterWaccAtual(response?.data.vigenciaFutura[0]);
              }
              else{
                setFinalVigencia(response?.data.vigenciaAtual.finalVigencia)
              }

          }
          
          setVigencia(response?.data)

        }
      })
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "ObterVigenciasWACC: Erro interno, entre em contato com o suporte!"
        })
      );
    }
  }, [listaWacc.length]);

  const setVigencia = (wacc) => {

    let inicioVigenciaAtual;
    let inicioVigenciaHistorica;
    let inicioVigenciaFutura;

    if (wacc?.vigenciaAtual) {
      setAtual(wacc.vigenciaAtual);
      inicioVigenciaAtual = wacc.vigenciaAtual.inicioVigencia;
    }

    if (wacc?.vigenciaFutura.length) {
      setFutura(wacc.vigenciaFutura[0]);
      inicioVigenciaFutura = wacc.vigenciaFutura[0].inicioVigencia;
    }

    
    if (wacc?.vigenciaHistorica.length) {
      setHistorico(wacc?.vigenciaHistorica[0])
      inicioVigenciaHistorica = wacc?.vigenciaHistorica[0].inicioVigencia;
    }

    if (type === "atual") {
      if (inicioVigenciaAtual) {
        setDataMinima(window.moment(inicioVigenciaAtual).add(1, "day"));
      }
      else{
        setDataMinima(window.moment(inicioVigenciaHistorica).add(1, "day"));
      }
    }

    if (type === "agendada") {
      if (inicioVigenciaFutura) {
        setDataMinima(window.moment(inicioVigenciaFutura).add(1, "day"));
      } else {
        setDataMinima(window.moment(inicioVigenciaAtual).add(1, "day"));
      }
    }    
    

  }


  useEffect(() => {
    if (!listaWacc.length) obterBandeiras();
  }, [listaWacc.length]);
  


  const enviarFormulario = async () => {
    try {
      let response;
      const valorWaccTratado = +(valorWacc.replace(",", ".")) / 100;

      if (id === "nova") {
        response = await addWacc({
          inicioVigencia: moment(inicioVigencia),
          finalVigencia: moment(finalVigencia),
          valorWacc: valorWaccTratado
        });
      } else {
        response = await salvarWacc({
          inicioVigencia: moment(inicioVigencia),
          valorWacc: valorWaccTratado
        });
      }

      if (response?.status === 200 && response.data) {
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: response.data
          })
        );

        setTimeout(() => {
          history.push(RotasDTO.ValoresWACC);
        }, 2000);
      }
    } catch (error) {
      if (error.response.status === 400) {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "Erro ao salvar vigência, verifique as datas e o valor."
          })
        );
      } else {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem:
              error?.response?.data ??
              "Erro interno, entre em contato com o suporte!"
          })
        );
      }
    }
  }

  const aoEnviarFormulario = (dados) => {
    enviarFormulario(dados);
  }

  return (
    <form className="needs-validation" onSubmit={handleSubmit(aoEnviarFormulario)}>
      <Card className={classes.container}>
        <Grid container p={2} spacing={4} justifyContent="flex-end" className={classes.container}>
          <Grid item sm={11}>
            Vigencia de WACC
          </Grid>
          <Grid item sm={1}>
            <BotaoRetornarListagem
              justify="flex-end"
              urlListagem={RotasDTO.ValoresWACC}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4} alignItems="center" className={classes.container}>
          <Grid item sm={4} style={{ marginLeft: "16px", marginRight: "16px" }}>
            <InputData
              type="text"
              id="dataVigencia"
              name="dataVigencia"
              label="Início da vigência"
              disabled = {id !== 'nova'}
              minDate={dataMinima}
              maxDate={dataMaxima}
              customValue={inicioVigencia}
              onChange={(dt) => onChangeDataInicioVigencia(dt)}
              errors={errors}
            />
          </Grid>
          <Grid item sm={3}>
            <TextField
              id="valorWacc"
              label="Valor"
              variant="outlined"
              value={valorWacc}
              onChange={(valor) => handleValorWacc(valor)}
            />
          </Grid>
        </Grid>
        <Grid container p={2} spacing={4} className={classes.container}>
          <Grid item sm={4}>
            <Botao type="submit" label="Salvar" className={classes.button} />
          </Grid>
        </Grid>
      </Card>
    </form>
  );
};

export default ValoresWACCCadastro;