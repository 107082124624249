import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { Pagination, Box } from "@mui/material";

import { useStyles } from "./style";

const PaginacaoTabela = ({
  totalRegistros,
  registrosPorPagina,
  pagina,
  onChangePagina,
  totalItens
}) => {
  const classes = useStyles();

  const totalPaginas = useMemo(() => {
    return Math.ceil(totalRegistros / registrosPorPagina);
  }, [totalRegistros, registrosPorPagina]);

  const handleChangePagina = (page) => {
    onChangePagina(page, totalRegistros);
  };

  return (
    <div className={`${classes.paginacaoContainer}`}>
      {totalItens && (
        <Box pb={2}>
          <strong>{`Total de itens: ${totalRegistros}`}</strong>
        </Box>
      )}
      <Pagination
        count={totalPaginas}
        boundaryCount={1}
        siblingCount={0}
        shape="rounded"
        page={pagina}
        variant="outlined"
        color="primary"
        onChange={(evento, page) => handleChangePagina(page)}
      />
    </div>
  );
};

PaginacaoTabela.propTypes = {
  totalRegistros: PropTypes.oneOfType([PropTypes.any]),
  registrosPorPagina: PropTypes.oneOfType([PropTypes.any]),
  pagina: PropTypes.oneOfType([PropTypes.any]),
  onChangePagina: PropTypes.oneOfType([PropTypes.any]),
  totalItens: PropTypes.bool
};

PaginacaoTabela.defaultProps = {
  totalRegistros: 0,
  registrosPorPagina: 0,
  pagina: 1,
  onChangePagina: () => {},
  totalItens: false
};

export default PaginacaoTabela;
