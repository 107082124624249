import produce from "immer";

const inicial = {
  loading: false,
};

export default function loader(state = inicial, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "@loader/handle": {
        draft.loading = action.payload?? false;
        break;
      }
      default: 
        return state;
    }
  });
}
