import React, { useRef, useState } from "react";
import { Box, Grid } from "@mui/material";
import { Tune, Search } from "@mui/icons-material";
import { useForm, useWatch } from "react-hook-form";
import moment from "moment";
import MaterialInputBusca from "../../../componentes/inputBusca";
import BotaoFiltroOrdenacao from "../../../componentes/botaoFiltroOrdenar";
import { SelectArredondado, TabelaPaginada } from "../../../componentes";
import EditarCupomHelper from "./helper";
import Coluna from "../../../componentes/tabelaPaginada/colunas/coluna";
import ColunaCampoDropDown from "./componentes/colunas/colunaCampoDropDown";
import ColunaComponentePersonalizado from "./componentes/colunas/colunaComponentePersonalizado";
import ColunaMultiplosBotoes from "./componentes/colunaMultiplosBotoes";
import FiltroDto from "./dto/filtroDto";
import ResultadoPaginadoDto from "../../../componentes/tabelaPaginada/resultadoPaginadoDto";
import FiltroAdicionalTabela from "./dto/filtroAdicionalTabela";
import ListagemCupomDto from "./dto/listagemCupomDto";
import ColunaCampoDataInicio from "./componentes/colunas/colunaCampoDataInicio";
import ColunaCampoDataFim from "./componentes/colunas/colunaCampoDataFim";
import ColunaCampoTextoSemFidelidade from "./componentes/colunas/colunaCampoTextoSemFidelidade";
import ColunaCampoTexto3Anos from "./componentes/colunas/colunaCampoTexto3Anos";
import ColunaCampoTexto5Anos from "./componentes/colunas/colunaCampoTexto5Anos";

import ColunaVigenciaDe from "./componentes/colunas/colunaVigenciaDe";
import ColunaVigenciaAte from "./componentes/colunas/colunaVigenciaAte";

import InputDataWhite from "../../../componentes/inputTextoDataBranco";
import { useStyles } from "./style";
import {
  listarFiltrarPor,
  listarOrganizarPor
} from "../../../servicos/colaboradorCupomServico";
import themes from "../../../themes";

const EditarCupom = () => {
  const classes = useStyles();
  const refTabela = useRef(null);

  const [filtroAdicionalTabela, setFiltroAdicionalTabela] = useState(
    new FiltroAdicionalTabela()
  );
  const [ordenacao, setValorOrdenacao] = useState(null);
  const [ordemColunaSelecionada, setOrdemColunaSelecionada] = useState(null);
  const [ordemSelecionada, setOrdemSelecionada] = useState(null);
  const [filtroSelecionado, setFiltroSelecionado] = useState(null);
  const [dataInicio, setDataIncicio] = useState(null);
  const [dataInicioDescontoDiferenciado, setDataIncicioDesconto] = useState(
    null
  );
  const [dataFinalDescontoDiferenciado, setDataFimDesconto] = useState(null);
  const [idCampoSelecionadoInicio, setCampoSelecionadoInicio] = useState(null);
  const [
    idCampoSelecionadoInicioDesconto,
    setCampoSelecionadoInicioDesconto
  ] = useState(null);
  const [
    idCampoSelecionadoFimDesconto,
    setCampoSelecionadoFimDesconto
  ] = useState(null);
  const [dataFim, setDataFim] = useState(null);
  const [adicionalDescontoSemFidelidade, setDescontoSemFidelidade] = useState(
    null
  );
  const [idCampoDescontoSemFidelidade, setCampoSemFidelidade] = useState(null);
  const [adicionalDesconto3Anos, setDesconto3Anos] = useState(null);
  const [idCampoDesconto3Anos, setCampoDesconto3Anos] = useState(null);
  const [adicionalDesconto5Anos, setDesconto5Anos] = useState(null);
  const [idCampoDesconto5Anos, setCampoDesconto5Anos] = useState(null);
  const [idCampoSelecionadoFim, setCampoSelecionadoFim] = useState(null);
  const [descontoDiferenciado, setDescontoDiferenciado] = useState("");
  const [idDescontoDiferenciado, setIdDescontoDiferenciado] = useState("");
  const [enabled, setEnabled] = useState(false);
  const [selecionadoId, setSelecionadoId] = useState(null);
  const [campoPesquisa, setCampoPesquisa] = useState("");
  const [dataInicialPesquisa, setDataInicialPesquisa] = useState(null);
  const [dataFinalPesquisa, setDataFinalPesquisa] = useState(null);
  const [situacao] = useState("AT");

  const { register, control } = useForm({
    reValidateMode: "onSubmit"
  });

  const textoBusca = useWatch({
    control,
    name: "textoBusca",
    defaultValue: ""
  });

  const handleClickSortOrderSelecionado = (valor, idCampoSelecionado) => {
    const val =
      ordemColunaSelecionada === idCampoSelecionado && valor === ""
        ? ""
        : idCampoSelecionado;
    setOrdemColunaSelecionada(val);
    setValorOrdenacao(valor);
  };

  const handleClickOrdemSelecionada = (event) => {
    const val = ordemSelecionada === event ? "" : event;
    setOrdemSelecionada(val);
  };

  const handleClickFiltroSelecionado = (event) => {
    const val = filtroSelecionado === event ? "" : event;
    setFiltroSelecionado(val);
  };

  const atualizarCupom = async (id, model) => {
    const { mensagem, sucesso } = await EditarCupomHelper.atualizarCupom(
      id,
      model
    );

    if (!sucesso) {
      EditarCupomHelper.exibirErro(mensagem);
      return;
    }

    if (refTabela?.current) refTabela.current.obterDadosPaginados();
    setIdDescontoDiferenciado(null);
    setCampoSelecionadoInicio(null);
    setCampoSelecionadoFim(null);
    setCampoSemFidelidade(null);
    setCampoDesconto3Anos(null);
    setCampoDesconto5Anos(null);
    setDescontoSemFidelidade(null);
    setDesconto3Anos(null);
    setDesconto5Anos(null);
  };

  const obterListagemCupons = async (parametros) => {
    const filtro = new FiltroDto(
      parametros?.tamanhoPagina,
      parametros?.pagina,
      parametros?.pesquisar,
      parametros?.dataInicial,
      parametros?.dataFinal,
      parametros?.campoPesquisa,
      parametros?.filtroAdicional?.ordenacao ?? "",
      parametros?.filtroAdicional?.situacao ?? "AT",
      parametros?.filtroAdicional?.situacaoCupom ?? "",
      parametros?.adicionalDesconto3Anos,
      parametros?.adicionalDesconto5Anos
    );

    const { data, mensagem, sucesso } = await EditarCupomHelper.listarCupons(
      filtro
    );

    if (!sucesso) {
      EditarCupomHelper.exibirErro(mensagem);
      return new ResultadoPaginadoDto([], 1, 0, 0);
    }

    if (data?.colaboradorCupom?.length === 0 ?? true)
      return new ResultadoPaginadoDto([], 1, 0, 0);

    const descontosSelecionadosList = [
      { id: "N", descricao: "Não" },
      { id: "S", descricao: "Sim" }
    ];

    const resLinhas = data?.colaboradorCupom?.map((res) => {
      return new ListagemCupomDto(
        res?.id,
        res?.nomeColaborador,
        res?.cupom,
        res?.descontoDiferenciado,
        res?.dataInicioVigencia,
        res?.dataFimVigencia,
        res?.colaboradorId,
        res?.adicionalDescontoSemFidelidade,
        res?.adicionalDesconto3Anos,
        res?.adicionalDesconto5Anos,
        res?.dataInicioDescontoDiferenciado,
        res?.dataFinalDescontoDiferenciado,
        descontosSelecionadosList
      );
    });

    return new ResultadoPaginadoDto(
      resLinhas,
      data?.paginaAtual,
      data?.totalItens,
      data?.totalPaginas
    );
  };

  const onChangeFiltrosTabela = async (parametros) => {
    return obterListagemCupons({
      tamanhoPagina: parametros?.totalPagina,
      pagina: parametros?.pagina,
      pesquisar: parametros?.pesquisar,
      dataInicial: dataInicialPesquisa
        ? window.moment(dataInicialPesquisa).format("YYYY-MM-DD")
        : "",
      filtroAdicional: new FiltroAdicionalTabela(
        parametros?.filtrosAdicionais?.ordenacao,
        parametros?.filtrosAdicionais?.situacao,
        parametros?.filtrosAdicionais?.situacaoCupom
      ),
      dataFinal: dataFinalPesquisa
        ? window.moment(dataFinalPesquisa).format("YYYY-MM-DD")
        : "",
      campoPesquisa: campoPesquisa ?? ""
    });
  };

  const buscarPorDatas = () => {
    setFiltroAdicionalTabela(
      new FiltroAdicionalTabela(
        ordenacao,
        situacao,
        filtroSelecionado,
        dataInicialPesquisa,
        dataFinalPesquisa
      )
    );
  };

  const handleClickAtivarFiltro = () => {
    setFiltroAdicionalTabela(
      new FiltroAdicionalTabela(
        ordenacao,
        situacao,
        filtroSelecionado,
        dataInicialPesquisa,
        dataFinalPesquisa
      )
    );
  };

  const onChangeSelecionarDescontoDiferenciado = async (
    diferenciado,
    params
  ) => {
    setDescontoDiferenciado(diferenciado);
    setIdDescontoDiferenciado(params?.id);
  };

  const onClickBotao = (enable, params) => {
    setEnabled(enable);
    setSelecionadoId(params?.id);
    const descontoDiferenciadoDefault = params?.descontoDiferenciado ?? "N";

    const desconto = descontoDiferenciado ?? descontoDiferenciadoDefault;

    const semFidelidade =
      desconto === "N"
        ? null
        : adicionalDescontoSemFidelidade ??
          params?.adicionalDescontoSemFidelidade;

    const desconto3Anos =
      desconto === "N"
        ? null
        : adicionalDesconto3Anos ?? params?.adicionalDesconto3Anos;

    const desconto5Anos =
      desconto === "N"
        ? null
        : adicionalDesconto5Anos ?? params?.adicionalDesconto5Anos;

    const dataInicioDesconto =
      desconto === "N"
        ? ""
        : dataInicioDescontoDiferenciado ??
          params?.dataInicioDescontoDiferenciado;
    const dataFimDesconto =
      desconto === "N"
        ? ""
        : dataFinalDescontoDiferenciado ??
          params?.dataFinalDescontoDiferenciado;

    if (!enable) {
      const model = {
        colaboradorId: params?.colaboradorId,
        cupom: params?.cupom,
        dataInicioVigencia: dataInicio
          ? moment(dataInicio).format()
          : params?.dataInicioVigencia,
        dataFimVigencia: dataFim
          ? moment(dataFim).format()
          : params?.dataFimVigencia,
        descontoDiferenciado:
          descontoDiferenciado ?? descontoDiferenciadoDefault,
        adicionalDescontoSemFidelidade:
          semFidelidade === 0 ? null : semFidelidade,
        adicionalDesconto3Anos: desconto3Anos === 0 ? null : desconto3Anos,
        adicionalDesconto5Anos: desconto5Anos === 0 ? null : desconto5Anos,
        dataInicioDescontoDiferenciado: dataInicioDesconto,
        dataFinalDescontoDiferenciado: dataFimDesconto
      };

      atualizarCupom(params?.id, model);
    }
  };

  const onChangeSelecionarDataInicio = (date, params) => {
    setDataIncicio(date);
    setCampoSelecionadoInicio(params.id);
  };

  const onChangeDataInicioDesconto = (date, params) => {
    setDataIncicioDesconto(date);
    setCampoSelecionadoInicioDesconto(params.id);
  };

  const onChangeDataFimDesconto = (date, params) => {
    setDataFimDesconto(date);
    setCampoSelecionadoFimDesconto(params.id);
  };

  const onChangeSelecionarDataFim = (date, params) => {
    setDataFim(date);
    setCampoSelecionadoFim(params.id);
  };

  const onChangeSelecionarDescontoSemFidelidade = (desconto, params) => {
    setDescontoSemFidelidade(desconto ? Number(desconto) : 0);
    setCampoSemFidelidade(params.id);
  };

  const onChangeSelecionarDesconto3Anos = (desconto, params) => {
    setDesconto3Anos(desconto ? Number(desconto) : 0);
    setCampoDesconto3Anos(params.id);
  };

  const onChangeSelecionarDesconto5Anos = (desconto, params) => {
    setDesconto5Anos(desconto ? Number(desconto) : 0);
    setCampoDesconto5Anos(params.id);
  };

  const colunas = [
    new Coluna("nomeColaborador", "Colaborador", false, "16%", "16%"),
    new Coluna("cupom", "Cupom", false, "12%", "12%"),
    new ColunaCampoDataInicio(
      "dataInicioVigencia",
      "Cupom válido de:",
      false,
      onChangeSelecionarDataInicio,
      "9%",
      "9%",
      dataInicio,
      idCampoSelecionadoInicio,
      selecionadoId,
      enabled
    ),
    new ColunaCampoDataFim(
      "dataFimVigencia",
      "Cupom válido até:",
      false,
      onChangeSelecionarDataFim,
      "9%",
      "9%",
      dataFim,
      idCampoSelecionadoFim,
      selecionadoId,
      enabled
    ),
    new ColunaCampoDropDown(
      "descontoDiferenciado",
      "Desconto diferenciado?",
      false,
      onChangeSelecionarDescontoDiferenciado,
      "7%",
      "7%",
      descontoDiferenciado,
      idDescontoDiferenciado,
      selecionadoId,
      enabled
    ),
    new ColunaCampoTextoSemFidelidade(
      "adicionalDescontoSemFidelidade",
      "Desconto sem fidelidade:",
      false,
      onChangeSelecionarDescontoSemFidelidade,
      "8%",
      "8%",
      adicionalDescontoSemFidelidade === 0
        ? ""
        : adicionalDescontoSemFidelidade,
      idCampoDescontoSemFidelidade,
      selecionadoId,
      enabled
    ),

    new ColunaCampoTexto3Anos(
      "adicionalDesconto3Anos",
      "Desconto 3 anos:",
      false,
      onChangeSelecionarDesconto3Anos,
      "7%",
      "7%",
      adicionalDesconto3Anos === 0 ? "" : adicionalDesconto3Anos,
      idCampoDesconto3Anos,
      selecionadoId,
      enabled
    ),

    new ColunaCampoTexto5Anos(
      "adicionalDesconto5Anos",
      "Desconto 5 anos:",
      false,
      onChangeSelecionarDesconto5Anos,
      "7%",
      "7%",
      adicionalDesconto5Anos === 0 ? "" : adicionalDesconto5Anos,
      idCampoDesconto5Anos,
      selecionadoId,
      enabled
    ),

    new ColunaVigenciaDe(
      "vigenciaDescontoDe",
      "Vigência desconto de:",
      false,
      onChangeDataInicioDesconto,
      "9%",
      "9%",
      dataInicioDescontoDiferenciado,
      idCampoSelecionadoInicioDesconto,
      selecionadoId,
      enabled
    ),

    new ColunaVigenciaAte(
      "vigenciaDescontoAte",
      "Vigência desconto até:",
      false,
      onChangeDataFimDesconto,
      "9%",
      "9%",
      dataFinalDescontoDiferenciado,
      idCampoSelecionadoFimDesconto,
      selecionadoId,
      enabled
    ),

    new ColunaComponentePersonalizado(
      "opcoes",
      "",
      ColunaMultiplosBotoes,
      onClickBotao,
      false,
      true,
      "4%",
      "4%",
      "pl-2",
      selecionadoId,
      enabled
    )
  ];

  return (
    <Grid container p={2} spacing={2}>
      <Grid item xs={12} lg={2}>
        <SelectArredondado
          id="CampoPesquisa"
          name="CampoPesquisa"
          label="Filtrar por campo"
          placeholder="Filtrar por campo"
          valueKey="valor"
          valueName="nome"
          dataSource={[
            { nome: "Todos", valor: "" },
            { nome: "Nome Colaborador", valor: "nomeColaborador" },
            { nome: "Nome Cupom", valor: "nomeCupom" }
          ]}
          value={campoPesquisa}
          onChange={(e) => {
            setCampoPesquisa(e.target.value);
          }}
          permiteValorBranco
          styleForm={false}
        />
      </Grid>
      <Grid item xs={12} lg={3}>
        {campoPesquisa && (
          <MaterialInputBusca
            type="text"
            id="textoBusca"
            name="textoBusca"
            label="Buscar"
            className={classes.search}
            renderIconShowHide
            searchAdornment
            permiteValorBranco
            defaultValue={textoBusca ?? ""}
            ref={register}
          />
        )}
      </Grid>
      <Grid item xs={12} lg={2}>
        <InputDataWhite
          styleForm={false}
          type="text"
          id="DataInicial"
          name="DataInicial"
          label="Cupom Valido de"
          format="dd-MM-yyyy"
          customValue={dataInicialPesquisa}
          onChange={(data) => setDataInicialPesquisa(data)}
          minDate={window.moment().subtract(200, "years").toDate()}
          className={{ container: classes.inputData }}
        />
      </Grid>
      <Grid item xs={12} lg={2}>
        <InputDataWhite
          styleForm={false}
          type="text"
          id="DataFinal"
          name="DataFinal"
          label="Cupom Valido Até"
          format="dd-MM-yyyy"
          customValue={dataFinalPesquisa}
          onChange={(data) => setDataFinalPesquisa(data)}
          maxDate={window.moment().add(200, "years").toDate()}
          minDate={window.moment(dataInicialPesquisa).toDate()}
          className={{ container: classes.inputData }}
        />
      </Grid>
      <Grid item xs={4} lg={1}>
        <Box
          border={`1px solid ${themes.color.secondaryBorderColor}`}
          borderRadius="5px"
          p={1.7}
          textAlign="center"
        >
          <Search
            onClick={buscarPorDatas}
            cursor="pointer"
            htmlColor={`${themes.color.secondaryBorderColor}`}
          />
        </Box>
      </Grid>
      <Grid item xs={8} lg={2}>
        <BotaoFiltroOrdenacao
          type="button"
          background="transparent"
          label="Filtrar / Ordernar"
          icon={<Tune />}
          className={classes.buttonFiltro}
          ordenacaoColuna={listarOrganizarPor()}
          filtros={listarFiltrarPor()}
          ordemSelecionada={ordemSelecionada}
          filtrosSelecionados={filtroSelecionado}
          ordenadorUm="Ordenador"
          ordenadorDois="Filtrar"
          ordemColunaSelecionada={ordemColunaSelecionada}
          onClickOrdenacao={handleClickOrdemSelecionada}
          onClickFiltro={handleClickFiltroSelecionado}
          onClickSortOrder={handleClickSortOrderSelecionado}
          onClickAtivarAgora={handleClickAtivarFiltro}
        />
      </Grid>
      <Grid item xs={12} mt={4}>
        <TabelaPaginada
          ref={refTabela}
          onChangeFiltrosTabela={onChangeFiltrosTabela}
          colunas={colunas}
          pesquisar={textoBusca}
          filtrosAdicionais={filtroAdicionalTabela}
          paginaAtual={1}
          itemsPorPagina={20}
          noHeader
        />
      </Grid>
    </Grid>
  );
};

export default EditarCupom;
