import MICROSERVICO from "global/enumeradores/microServico";
import obterApi from "servicos/api";

const api = () => obterApi(MICROSERVICO.PROJECAOTARIFARIA);

export const obterCenarios = async () => {
  return api().get('v1/Cenario');
}

export const obterCenarioPorId = async (id) => {
  return api().get(`v1/Cenario/${id}`);
}

export const atualizarCenario = async (id, data) => {
  return api().put(`v1/Cenario/${id}`, data);
}

export const salvarCenario = async (id, data) => {
  let metodo = "post";
  let url = 'v1/Cenario/';
  if (id > 0) {
    metodo = "put";
    url = `${url}${id}`;
  }
  return api()[metodo](url, data);
};