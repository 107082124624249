import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box, Grid, IconButton } from "@mui/material";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import ConsolidacaoWidget from "../../Consolidacao";
import ConsumoAtualWidget from "../../ConsumoAtual";
import ConsumoDiarioWidget from "../../ConsumoDiario";
import ConsumoHoraWidget from "../../ConsumoHora";
import ConsumoMensalWidget from "../../ConsumoMensal";

import {
  Consolidacao,
  ConsumoAtual,
  ConsumoDiario,
  ConsumoHorario,
  ConsumoMensal,
  Texto,
  useStyles
} from "./style";

const DashboardMobile = () => {
  const [tela, setTela] = useState("");
  const [titulo, setTitulo] = useState("Consumo");

  const history = useHistory();
  const classes = useStyles();
  return (
    <Grid container>
      <Box className={classes.barraSuperior}>
        <IconButton
          className={classes.arrowBack}
          onClick={() =>
            tela === ""
              ? history.push("/dashboard/")
              : [setTela(""), setTitulo("Consumo")]
          }
          size="small"
        >
          <ArrowBackIosIcon fontSize="small" />
        </IconButton>

        {titulo}
      </Box>
      {tela === "ConsumoAtual" ? (
        <Box className={classes.marginTopContainer}>
          <ConsumoAtualWidget />
        </Box>
      ) : tela === "ConsumoHora" ? (
        <Box className={classes.marginTopContainer}>
          <ConsumoHoraWidget />
        </Box>
      ) : tela === "ConsumoDiario" ? (
        <Box className={classes.marginTopContainer}>
          <ConsumoDiarioWidget />
        </Box>
      ) : tela === "ConsumoMensal" ? (
        <Box pt={5}>
          <ConsumoMensalWidget />
        </Box>
      ) : tela === "Consolidacao" ? (
        <ConsolidacaoWidget />
      ) : (
        <Grid xs={12} item>
          <ConsumoAtual
            onClick={() => [
              setTela("ConsumoAtual"),
              setTitulo("Consumo atual")
            ]}
          >
            <Texto>Consumo Atual</Texto>
          </ConsumoAtual>

          <ConsumoHorario
            onClick={() => [
              setTela("ConsumoHora"),
              setTitulo("Consumo horário")
            ]}
          >
            <Texto>Consumo Horário</Texto>
          </ConsumoHorario>

          <ConsumoDiario
            onClick={() => [
              setTela("ConsumoDiario"),
              setTitulo("Consumo diário")
            ]}
          >
            <Texto>Consumo Diário</Texto>
          </ConsumoDiario>

          <ConsumoMensal
            onClick={() => [
              setTela("ConsumoMensal"),
              setTitulo("Consumo Mensal")
            ]}
          >
            <Texto>Consumo Mensal</Texto>
          </ConsumoMensal>

          <Consolidacao
            onClick={() => [setTela("Consolidacao"), setTitulo("Consolidação")]}
          >
            <Texto>Consolidação</Texto>
          </Consolidacao>
        </Grid>
      )}
    </Grid>
  );
};

export default DashboardMobile;
