import { makeStyles } from "@mui/styles";
import themes from "themes";

export const useStyles = makeStyles(() => ({
  dados: {
    marginBottom: "15px",
    "& p": {
      color: "#4F4F4F",
      margin: 0
    }
  },
  listaItem: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "5px",
    "& strong": {
      color: `${themes.color.primary} !important`
    },
    "& span": {
      color: "#4F4F4F"
    }
  },
  botao: {
    color: `${themes.color.secondaryText} !important`,
    width: "100%",
    justifyContent: "center",
    justifyItems: "center",

    "&:disabled": {
      backgroundColor: "#9F9F9F",
      opacity: 1,
      color: "#4F4F4F !important",
      borderColor: "#9F9F9F"
    }
  }
}));
