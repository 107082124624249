import { makeStyles } from "@mui/styles";
import { Base } from "componentes/cores";
import getDomain from "global/getDomain";
import theme from "themes";
import { createTheme } from "@mui/material/styles";

const th = createTheme();

export const useStyles = makeStyles(() => ({
  container: {
    margin: "0",
    width: "100%",
    "& .MuiInputBase-root": {
      height: "46px"
    },
    "& .MuiFormLabel-root": {
      lineHeight: "1"
    },
    "& .MuiGrid-spacing-xs-4 > .MuiGrid-item": {
      padding: "12px"
    },
    "& .MuiTypography-body1": {
      width: "100%"
    },
    "& .MuiFormGroup-root label .Mui-checked span div svg:nth-child(1)": {
      fill: `${theme.color.radioButtonDefault} !important`
    },
    "& .MuiFormGroup-root label .Mui-checked span div svg:nth-child(2)": {
      fill: `${theme.color.radioButtonDefault} !important`
    }
  },
  button: {
    fontSize: "18px"
  },
  selectOfficer: {
    filter: "invert(1)"
  },
  trace: {
    width: "112px",
    height: "47px",
    borderBottom: "1px solid black",
    position: "absolute"
  },
  fundoDadosEnergia: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },
  containerAvancar: {
    margin: "0 0 16px"
  },
  inputRadio: {
    marginBottom: "-16px",
    color: theme.color.primaryText
  },
  inputRadioLabel: {
    color: `${theme.color.primaryText} !important`
  },
  imagemFundo: {
    background:
      getDomain() !== "luz" &&
      `url(../../themes/${getDomain()}/assets/cotacao_dados_energia.png)`,
    backgroundSize: "cover"
  },
  checked: {
    color: theme.color.primaryBackgroud,
    marginTop: 9
  },
  unchecked: {
    color: Base.GreyHit,
    marginTop: 9
  },
  hideMobile: {
    [th.breakpoints.down("lg")]: {
      display: "none !important"
    }
  },
  showMobile: {
    [th.breakpoints.up("md")]: {
      display: "none !important"
    }
  },
  desabledInput: {
    pointerEvents: "none",
    opacity: 0.7
  },
  containerListagemImagens: {
    display: "flex",
    flexWrap: "wrap",
    color: `${theme.color.secondaryText} !important`,
    "& .MuiButtonBase-root:hover, .MuiButtonBase-root:active, .MuiButtonBase-root:focus": {
      color: `${theme.color.secondaryText} !important`,
      "& .MuiChip-deleteIcon": {
        color: `${theme.color.secondaryText} !important`
      }
    },
    "& .MuiChip-root": {
      margin: "4px",
      color: `${theme.color.secondaryBorderColor} !important`,
      fontSize: "14px",
      borderRadius: "5px",
      backgroundColor: "#FFF !important"
    },
    "& .MuiChip-deleteIcon": {
      color: `${theme.color.secondaryBorderColor} !important`,
      backgroundColor: "#FFF !important"
    }
  },
  botaoCamera: {
    display: "flex",
    textAlign: "center",
    width: "100%",
    height: "44px",
    textTransform: "inherit !important",
    color: `${theme.color.buttonColor} !important`,
    "& svg": {
      marginRight: "20px",
      color: `${theme.color.secondary} !important`
    }
  }
}));
