import RetornoEndpointDto from "../../../global/dto/retornoEndpointDto";
import {
  listarPaginado,
  cotacaoProposta,
  liberaPropostaContrato
} from "../../../servicos/listagemCotacoesGdServico";
import { reenviarContrato } from "../../../servicos/demandaAtendServico";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";
import { store } from "../../../global/redux";
import { obterTodos } from "../../../servicos/tipoPlanoCotacaoServico";

export default class ListagemCotacaoHelper {
  static async listarCotacoes(filtro, permissaoAlterarFiltrarOfficerDelta) {
    try {
      const resultado = await listarPaginado(
        filtro,
        permissaoAlterarFiltrarOfficerDelta
      );

      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Lista de cotações gd não encontrada!");

      return RetornoEndpointDto.Sucesso(
        "Listagem de cotações gd obtidas com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu um erro ao obter a lista de cotações gd!"
      );
    }
  }

  static async reenviarContrato(body, setModalAcao, handleLoading, dispatch) {
    try {
      dispatch(handleLoading(true));
      setModalAcao(false);
      const resultado = await reenviarContrato(body);

      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Reeenvio de contrato não encontrado!");

      return RetornoEndpointDto.Sucesso(
        "Contrato reenviado com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Ocorreu um erro ao reenviar o contrato!");
    } finally {
      dispatch(handleLoading(false));
    }
  }

  static async cotacaoProposta(idCotacao, produtoId, handleLoading, dispatch) {
    try {
      dispatch(handleLoading(true));
      const { data, status } = await cotacaoProposta(idCotacao, produtoId);

      if (status === 204)
        return RetornoEndpointDto.Erro("Arquivo não encontrada!");

      return RetornoEndpointDto.Sucesso("Proposta gerada com sucesso!", data);
    } catch (error) {
      return RetornoEndpointDto.Erro("Erro no Download!");
    } finally {
      dispatch(handleLoading(false));
    }
  }

  static async obterTodosPlanos() {
    try {
      const resultado = await obterTodos();
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("PLanos não encontrados!");

      return RetornoEndpointDto.Sucesso(
        "PLanos obtido com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Planos não encontrados!");
    }
  }

  static async liberaPropostaContrato(id, body) {
    try {
      const resultado = await liberaPropostaContrato(id, body);
      return RetornoEndpointDto.Sucesso(
        "Proposta liberada com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        error.response.data.Message || "Ocorreu um erro ao liberar proposta!"
      );
    }
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }

  static exibirSucesso(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem
      })
    );
  }
}
