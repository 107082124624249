import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { Card, Grid } from '@mui/material';
import { useStyles } from './style';
import UploadDropArea from 'componentes/upload';
import Botao from 'componentes/botao';
import InputData from 'componentes/inputTextoData';
import ValidacaoDominios from 'componentes/validacaoDominios';
import Loader from 'componentes/loader';
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { uploadContratroProinfa } from '../../../../../servicos/premissasBrasil';
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import RotasDTO  from "../../../../../rotas/rotasUrlDto";  
import { useHistory } from "react-router-dom";

const ContratosProinfaImportar = () => {
  const history = useHistory();
  const classes = useStyles();
  const usuarioGlobal = useSelector((state) => state.usuario);
  const { handleSubmit } = useForm({
    reValidateMode: 'onSubmit',
  });

  const [anoReferencia, setAnoReferencia] = useState(null);
  const handleChangeAnoReferencia = (ano) => setAnoReferencia(ano);

  const [dataReferenciaPreco, setDataReferenciaPreco] = useState(null);
  const handleChangeDataReferenciaPreco = (data) => setDataReferenciaPreco(data);

  const [custosAdicionais, setCustosAdicionais] = useState(0);
  const handleChangeCustosAdicionais = (event) => setCustosAdicionais(event.target.value.replace("R$", "").replaceAll(".", "").replace(",", "."));

  const msgPadraoUpload = "Solte o arquivo para anexá-lo ou procurar";
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(msgPadraoUpload);

  const processarArquivo = (arquivo) => {
    if (arquivo != null && arquivo[0] != null) {
      setFile(arquivo[0].file);
      setFileName(arquivo[0].file.name);
    }
  };

  const onAddUpload = (arquivo) => {
    processarArquivo(arquivo);
  };

  const [carregandoArquivo, setCarregandoArquivo] = useState(false);
  const [progressMesage, setProgressMessage] = useState("");

  const [validacaoDominio, setValidacaoDominio] = useState(false);
  const [validacaoDominioCabecalho, setValidacaoDominioCabecalho] = useState("");
  const [validacaoDominiosObj, setValidacaoDominiosObj] = useState({
    "contexto": "",
    "entidades": []
  });

  const onCancelar = () => {
    setFile(false);
    setFileName(msgPadraoUpload)
    setCarregandoArquivo(false);
    setValidacaoDominio(false);
    setAnoReferencia(null);
    setDataReferenciaPreco(null);
    setCustosAdicionais(0);
  }

  const validarPreenchimento = () => {

    let valid = true;
    let msg = "";

    if ((file == null || file === false) && anoReferencia == null && dataReferenciaPreco == null && custosAdicionais === "") {
      valid = false;
      msg = "É necessario informar os campos obrigatório.";
    } else if (anoReferencia == null) {
      valid = false;
      msg = "É necessario informar o campo ano de referência.";
    } else if (dataReferenciaPreco == null) {
      valid = false;
      msg = "É necessario informar o campo referência dos preços.";
    } else if (custosAdicionais === "") {
      valid = false;
      msg = "É necessario informar o campo custos adicionais.";
    } else if (custosAdicionais !== "" && (custosAdicionais < -10000 || custosAdicionais > 10000)) {
      valid = false;
      msg = "O campo custos adicionais somente aceita valores entre -10.000,00 e 10.000,00.";
    }  else if (file == null || file === false) {
      valid = false;
      msg = "É necessario informar o arquivo para o upload.";
    }

    return {
      valid,
      msg
    }

  }

  const EnviarForm = async () => {

    const validacao = validarPreenchimento();

    if (validacao.valid) {

      setCarregandoArquivo(true);
      setProgressMessage("Importando o arquivo... Por favor, aguarde.");

      try {
        const anoBase = moment(anoReferencia).format("YYYY");
        const dataBase = moment(dataReferenciaPreco).format("MM-DD-YYYY");
        const custos = custosAdicionais.toString().replace("R$", "")
        const response = await uploadContratroProinfa(file, anoBase, dataBase, custos, true, usuarioGlobal?.usuario?.id, usuarioGlobal?.usuario?.usuarioNome);

        if (response?.status === 200 && response?.data) {
          onCancelar();
          store.dispatch(
            alertaExibir({
              tipo: "success",
              mensagem: `Arquivo ${file?.name} importado com sucesso!`
            })
          );
        } else if (response?.status === 202 && response?.data) {
          if (response?.data.typeError === "ERRO-VALIDACAO-ESTRUTURA") {
            onCancelar();
            store.dispatch(
              alertaExibir({
                tipo: "danger",
                mensagem: response.data.value[0].ItemValidacao.Mensagem
              })
            );
          } else if (response?.data.typeError === "ERRO-VALIDACAO-DOMINIO") {
            setValidacaoDominio(true);
            setValidacaoDominioCabecalho(`Importação do arquivo ${file?.name}`)
            setValidacaoDominiosObj(response?.data?.value);
          }
        }
      } catch (error) {
        onCancelar();
        store.dispatch(
          alertaExibir({
            tipo: "danger",
            mensagem: "Erro ao importar arquivo!"
          })
        );
      }
    } else {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: validacao.msg
        })
      );
    }

  }

  const importarContrato = async (data) => {
    if (data && data.totalDesmarcado === 0 && data.responseInsert.totalInserido === data.responseInsert.totalValores) {
      setValidacaoDominio(false);
      const validacao = validarPreenchimento();

      if (validacao.valid) {
        setCarregandoArquivo(true);
        setProgressMessage("Importando o arquivo... Por favor, aguarde.");

        try {
          const anoBase = moment(anoReferencia).format("YYYY");
          const dataBase = moment(dataReferenciaPreco).format("MM-DD-YYYY");
          const custos = custosAdicionais.toString().replace("R$", "")
          const response = await uploadContratroProinfa(file, anoBase, dataBase, custos, false, usuarioGlobal?.usuario?.id, usuarioGlobal?.usuario?.usuarioNome);

          if (response?.status === 200 && response?.data) {
            onCancelar();
            store.dispatch(
              alertaExibir({
                tipo: "success",
                mensagem: `Arquivo ${file?.name} importado com sucesso!`
              })
            );
          } else if (response?.status === 202 && response?.data) {
            if (response?.data.typeError === "ERRO-VALIDACAO-ESTRUTURA") {
              onCancelar();
              store.dispatch(
                alertaExibir({
                  tipo: "danger",
                  mensagem: response.data.value[0].ItemValidacao.Mensagem
                })
              );
            } else if (response?.data.typeError === "ERRO-VALIDACAO-DOMINIO") {
              setValidacaoDominio(true);
              setValidacaoDominioCabecalho(`Importação do arquivo ${file?.name}`)
              setValidacaoDominiosObj(response?.data?.value);
            }
          }
        } catch (error) {
          onCancelar();
          store.dispatch(
            alertaExibir({
              tipo: "danger",
              mensagem: `Erro não previsto ao importar arquivo: ${error}`
            })
          );
        }
      } else {
        store.dispatch(
          alertaExibir({
            tipo: "danger",
            mensagem: validacao.msg
          })
        );
      }

    } else {
      if (data && data.responseInsert && data.responseInsert.totalInserido > 0) {
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: data.responseInsert.totalInserido === 1 ? `1 valor registrado com sucesso!` : `${data.responseInsert.totalInserido} valores registrados com sucesso!`
          })
        );
      }
      setCarregandoArquivo(false);
      setValidacaoDominio(false);
    }

  }
 
  const onCancelarValidacaoDominios = () => {
    setFile(false);
    setFileName(msgPadraoUpload)
    setCarregandoArquivo(false);
    setValidacaoDominio(false);
    setAnoReferencia(null);
    setDataReferenciaPreco(null);
    setCustosAdicionais(0);
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem: "Importação cancelada!"
      })
    );
  }

  const onFechar = (id) => {
    history.push(`${RotasDTO.ProjecaoInsumoListar}`);
  }

  return (
    <form className="needs-validation" onSubmit={handleSubmit(EnviarForm)}>
      <ValidacaoDominios
        item={validacaoDominiosObj}
        cabecalhoValidacao={validacaoDominioCabecalho}
        onConfirmar={(data) => importarContrato(data)}
        onCancelar={() => onCancelarValidacaoDominios()}
      />
      <Card className={classes.container} style={validacaoDominio === true ? { display: 'none' } : {}}>
        <Grid container className={classes.containerTitulo}>
          <Grid item xs={11} className="font-weight-bold">
            Importação de Planilha de Contratos PROINFA
          </Grid>
          <Grid item xs={1} >             
            <CloseIcon onClick={onFechar} className={classes.icon}/>
          </Grid>   
        </Grid>
        {validacaoDominio === false ?
          <>
            <Grid container className={classes.container}>
              {carregandoArquivo === false ?
                <Grid container>
                  <Grid container className={classes.container}>
                    <Grid item sm={4} className={classes.containerAnoReferencia}>
                      <InputData
                        type="text"
                        label="Ano de Referência"
                        id="anoReferencia"
                        name="anoReferencia"
                        customValue={anoReferencia}
                        onChange={(data) => handleChangeAnoReferencia(data)}
                        renderIconShowHide={false}
                        format="yyyy"
                        views={["year"]}
                        minDate= {moment().add(-100, "years").toDate()}
                      />
                    </Grid>
                    <Grid item sm={4} className={classes.containerDataReferencia}>
                      <InputData
                        type="text"
                        label="Referência dos Preços"
                        id="dataReferenciaPreco"
                        name="dataReferenciaPreco"
                        customValue={dataReferenciaPreco}
                        onChange={(data) => handleChangeDataReferenciaPreco(data)}
                        renderIconShowHide={false}
                        minDate= {moment().add(-100, "years").toDate()}
                      />
                    </Grid>
                  </Grid>
                  <Grid item sm={12} style={{ margin: "20px" }}>
                    <UploadDropArea
                      onAdd={onAddUpload}
                      dropAreaText={fileName}
                      showLoader={false}
                      acceptedFiles={[
                        '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
                      ]}
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <Grid container spacing={5} p={2} className={classes.container} justifyContent="center">
                      <Grid item sm={3}>
                        <Botao type="button" label="Cancelar" onClick={onCancelar} className={classes.buttonCustom} />
                      </Grid>
                      <Grid item sm={3}>
                        <Botao type="submit"label="Salvar" className={classes.button} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                :
                <Grid container className={classes.container} style={{ marginBottom: "20px" }}>
                  <Grid item sm={12} style={{ textAlign: "center" }}>
                    {progressMesage}
                  </Grid>
                  <Grid item sm={12}>
                    <Loader loading={carregandoArquivo} />
                  </Grid>
                </Grid>
              }
            </Grid>
          </>
          : ""
        }
      </Card>
    </form>
  );
}

export default ContratosProinfaImportar;