import styled from "styled-components";
import { makeStyles } from "@mui/styles";
import { Base } from "../cores";

export const useStyles = makeStyles({
  root: {
    background: Base.Transparent,
    marginTop: "3%",
    width: "513px",
    height: "112px",
    borderRadius: 5,
    border: `Solid 1px ${Base.GreyWater}`,
    boxShadow: "none"
  },
  content: {
    marginTop: "3%"
  },
  noHover: {
    "&:hover": {
      textDecoration: "none",
      color: "inherit"
    }
  }
});

export const Icone = styled.img`
  width: 51px;
  height: 44px;
  object-fit: cover;
`;
