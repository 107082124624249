export default class RelatorioCotacoesDto {
  constructor(
    dadosCotacao,
    dadosCliente,
    distribuidoraDescricao,
    dadosPlanos,
    dadosColaborador,
    dataCotacao,
    dataValidade,
    cativoVsLivre
  ) {
    this.dadosCotacao = dadosCotacao;
    this.dadosCliente = dadosCliente;
    this.distribuidoraDescricao = distribuidoraDescricao;
    this.dadosPlanos = dadosPlanos;
    this.dadosColaborador = dadosColaborador;
    this.dataCotacao = dataCotacao;
    this.dataValidade = dataValidade;
    this.cativoVsLivre = cativoVsLivre;
  }
}
