export default class FiltroDto {
  constructor(
    pagina,
    tamanhoPaginacao,
    searchString,
    ordemSelecionada,
    filtroSelecionado
  ) {
    this.pagina = pagina;
    this.tamanhoPaginacao = tamanhoPaginacao;
    this.searchString = searchString;
    this.sortOrder = ordemSelecionada;
    this.filter = filtroSelecionado;
  }
}
