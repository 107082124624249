import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Grid } from "@mui/material";
import { EditOutlined } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import DataTable from "react-data-table-component";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Coluna from "componentes/tabelaPaginada/colunas/coluna";
import ColunaBotao from "componentes/tabelaPaginada/colunas/colunaBotao";
import ModalConfirmacao from "componentes/modalConfirmacao";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { useStyles, customStyles, conditionalRowStyles } from "./style";
import RotasDTO from "../../../rotas/rotasUrlDto";
import { buscarVigenciasWACC, deleteWacc } from "../../../servicos/wacc";
import { Base } from "componentes/cores";

const ValorWACCAtual = () => {
  const history = useHistory();
  const classes = useStyles();

  const [data] = useState(window.moment().format("YYYY-MM-DD"));
  const [atual, setAtual] = useState([]);
  const [futura, setFutura] = useState([]);

  const [modalExclusao, setModalExclusao] = useState(false);

  const obterBandeiras = async () => {
    try {
      const lista = await buscarVigenciasWACC(data);

      if (lista?.status === 200 && lista?.data) {

        if (lista?.data?.vigenciaAtual !== null) {
          const response = [lista?.data?.vigenciaAtual]
          const organizado = [];

          response.map((item) => organizado.push({
            ...item,
            dataInicial: window.moment(item.inicioVigencia).format("DD-MM-YYYY"),
            dataFinal: window.moment(item.finalVigencia).format("DD-MM-YYYY"),
            valor: item.valorWACC.toLocaleString("pt-BR", { style: "percent",  minimumFractionDigits: 2 }),
          }));
          setAtual(organizado);
          setFutura(lista?.data?.vigenciaFutura)
        } else {
          setFutura(lista?.data?.vigenciaFutura)
          setAtual([])
        }
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "ObterBandeira: Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  const onClickNova = () => {

    if (futura.length) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Não é possivel inserir uma nova vigência, pois já existe uma agendada."
        })
      );
    } else {
      history.push(`${RotasDTO.ValoresWACCCadastro}/agendada/nova`);
    }
  }

  const onClickEditar = () => {
    history.push(`${RotasDTO.ValoresWACCCadastro}/atual`);
  };

  const onConfirmarExclusao = async () => {
    try {
      const response = await deleteWacc(atual[0].inicioVigencia);
      setModalExclusao(false);

      if(response.status === 200 && response.data.includes("sucesso"))
      {
        setAtual([]);
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: `${response.data}`
          })
        );
      }
      else if(response.status === 202)
      {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: `Falha: ${response.data}`
          })
        );

      }

    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem:`onConfirmarExclusao: Erro não previsto: ${error}`
        })
      );
    }
  }

  const onClickExcluir = () => {
    setModalExclusao(true);
  };

  const colunas = [
    new Coluna("dataInicial", "Data"),
    new Coluna("valor", "Valor"),
  ];

  const colunasComEdição = [
    ...colunas,
    new ColunaBotao(
      "editar",
      "",
      onClickEditar,
      <EditOutlined style={{color: Base.OffWhite}}/>,
      false,
      "70px",
      "70px",
      true
    )];

  const colunasComExclusão = [
    ...colunasComEdição,
    new ColunaBotao(
      "excluir",
      "",
      onClickExcluir,
      <DeleteIcon  style={{color: Base.OffWhite}}/>,
      false,
      "70px",
      "70px",
      true
    )
  ]

  useEffect(() => {
    obterBandeiras();
  }, [atual.length]);

  return (
    <>
      <Grid container>
        <Grid item sm={12} className="mt-5">
          <ModalConfirmacao
            item={modalExclusao}
            onConfirmar={() => onConfirmarExclusao()}
            mensagem="Tem certeza que deseja excluir essa vigência?"
            onCancelar={() => setModalExclusao(false)}
          />

          <div style={{ display: "flex", height: "100%", width: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              {atual?.length > 0 ?
                <DataTable
                  noHeader
                  subHeader
                  subHeaderAlign='right'
                  subHeaderComponent={<AddCircleIcon className={classes.icon} onClick={() => onClickNova()} />}
                  columns={colunasComExclusão}
                  data={atual}
                  theme="DeltaEnergia"
                  customStyles={customStyles}
                  conditionalRowStyles={conditionalRowStyles}
                  noDataComponent="Nenhum registro encontrado"
                />
                :
                <Grid container spacing={2}>
                  <Grid item>
                    <AddCircleIcon className={classes.icon} onClick={() => onClickNova()} />
                  </Grid>
                  <Grid item sm={12} className={classes.customTextoNoData}>
                    Nenhum registro encontrado
                  </Grid>
                </Grid>
              }
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default ValorWACCAtual;