import React, { useCallback, useEffect, useMemo, useState } from "react";

// ApexChart
import ReactApexChart from "react-apexcharts";

// Componentes
import { Grid } from "@mui/material";

// Redux
import { useSelector } from "react-redux";

// Componentes
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import _theme from "themes";
import { Base } from "../../../../componentes/cores";
import InputData from "../../../../componentes/inputTextoData";
import Loader from "../../../../componentes/loader";

// Styles
import { Container } from "../../Dashboard/style";
import { useStyles } from "./style";

// Serviços
import { historicoEconomia } from "../../../../servicos/economiaServico";

const Historico = (props) => {
  const [pdf, setPDF] = useState(props);
  const classes = useStyles();
  const usuario = useSelector((state) => state.usuario);
  const { unidadeSelecionada } = usuario;

  const [mesInicio, setMesInicio] = useState();
  const [mesFim, setMesFim] = useState();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("lg"));

  useEffect(() => {
    setPDF(props);
  }, [props]);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    !mobile
      ? setMesInicio(window.moment().startOf("month").subtract(1, "year"))
      : setMesInicio(window.moment().startOf("month").subtract(3, "months"));
  }, [mobile]);

  useEffect(() => {
    if (mesInicio)
      // eslint-disable-next-line no-unused-expressions
      !mobile
        ? setMesFim(
            window.moment(mesInicio).add(1, "year").subtract(1, "month")
          )
        : setMesFim(
            window.moment(mesInicio).add(1, "year").subtract(9, "months")
          );
  }, [mesInicio]);

  const [custoCativo, setCustoCativo] = useState([]);
  const [custoLivre, setCustoLivre] = useState([]);
  const [economia, setEconomia] = useState([]);
  const [labelsPeriodos, setLabelsPeriodos] = useState([]);
  const [grafico, setGrafico] = useState(<div />);

  const [carregandoDados, setCarregandoDados] = useState(false);

  const tratarDadosHistorico = (dados) => {
    const listaPeriodos = [];
    const listaCativo = [];
    const listaLivre = [];
    const listaEconomia = [];

    if (dados) {
      dados.forEach((dado) => {
        if (!listaPeriodos.includes(dado?.periodo))
          listaPeriodos.push(dado?.periodo);

        listaCativo.push(dado?.custoCativo > 0 ? dado?.custoCativo : null);
        listaLivre.push(dado?.custoLivre > 0 ? dado?.custoLivre : null);
        listaEconomia.push(dado?.economia ?? 0);
      });
      setLabelsPeriodos(listaPeriodos);
    }

    setCustoCativo(listaCativo);
    setCustoLivre(listaLivre);
    setEconomia(listaEconomia);
  };

  const obterHistorico = useCallback(async () => {
    try {
      setCarregandoDados(true);

      if (unidadeSelecionada && mesInicio && mesFim) {
        const historico = await historicoEconomia(
          unidadeSelecionada,
          window.moment(mesInicio)?.format("yyyy-MM-DD"),
          window.moment(mesFim)?.format("yyyy-MM-DD")
        );
        if (historico?.status === 200 && historico?.data?.listaHistorico)
          tratarDadosHistorico(historico?.data?.listaHistorico);
      }
    } finally {
      setCarregandoDados(false);
    }
  }, [unidadeSelecionada, mesFim]);

  useEffect(() => {
    obterHistorico();
  }, [obterHistorico]);

  const dados = useMemo(
    () => ({
      series: [
        {
          color: _theme.color.custoCativo,
          data: custoCativo,
          name: "Custo cativo",
          type: "column"
        },
        {
          color: _theme.color.custoLivre,
          data: custoLivre,
          name: "Custo livre",
          type: "column"
        }
      ],
      options: {
        chart: {
          fontFamily: _theme.font.family,
          toolbar: {
            show: false
          },
          type: "line",
          zoom: {
            enabled: false
          },
          background: "transparent",
          width: "100%"
        },
        dataLabels: {
          background: {
            enabled: false
          },
          dropShadow: {
            enabled: true,
            blur: 0,
            opacity: 0.2
          },
          offsetY: -5,
          textAnchor: "start",
          width: "100%"
        },
        legend: {
          show: false
        },
        stroke: {
          show: !pdf.status,
          curve: "straight",
          width: 3
        },
        fill: {
          opacity: 0.9,
          type: "solid"
        },
        labels: labelsPeriodos,
        markers: {
          size: pdf.status ? 0 : 4
        },
        grid: {
          borderColor: Base.GreyShuttle,
          width: "100%",
          xaxis: {
            lines: {
              show: false
            },
            labels: {
              show: false
            }
          },
          yaxis: {
            lines: {
              show: true
            },
            labels: {
              show: false
            }
          }
        },
        xaxis: {
          width: "100%",
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            show: false
          },
          labels: {
            style: {
              colors: pdf.status
                ? _theme.color.thirdText
                : _theme.color.textoBarra,
              fontSize: pdf.status ? 22 : 12,
              fontWeight: "bold"
            },
            rotate: 0
          },
          position: "top",
          tooltip: {
            enabled: false
          }
        },
        yaxis: {
          forceNiceScale: true,
          labels: {
            show: true,
            style: {
              colors: pdf.status
                ? _theme.color.thirdText
                : _theme.color.textoBarra,
              fontSize: pdf.status ? 22 : 12,
              fontWeight: "bold"
            },
            formatter(value, index) {
              if (
                index?.series?.length &&
                index?.seriesIndex &&
                index?.seriesIndex === 2
              ) {
                const valorEconomia = index?.series[2][index?.dataPointIndex];
                if (!valorEconomia) return "0%";

                return `${valorEconomia}%`;
              }

              return value?.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
                maximumFractionDigits: 2,
                minimumFractionDigits: 0
              });
            }
          },
          min: 0,
          showAlways: false,
          showForNullSeries: false,
          width: "100%"
        },
        noData: {
          text: "",
          style: {
            color: _theme.color.textoBarra,
            fontSize: "18px",
            maxWidth: "80%"
          }
        },
        tooltip: {
          shared: false
        }
      }
    }),
    [custoCativo, custoLivre, economia, labelsPeriodos, pdf]
  );

  const RenderizarGrafico = useCallback(() => {
    const graficoRender = (
      <ReactApexChart
        options={dados?.options}
        series={dados?.series}
        type="line"
        height={350}
        width="100%"
      />
    );

    setGrafico(graficoRender);
  }, [setGrafico, custoCativo, custoLivre, economia, labelsPeriodos, pdf]);

  useEffect(() => RenderizarGrafico(), [RenderizarGrafico]);

  return (
    <>
      <Container
        className={classes.containerGrafico}
        style={{ backgroundColor: pdf.status ? "white" : "" }}
      >
        <Grid
          container
          justifyContent="space-between"
          className="m-0 w-100"
          spacing={4}
        >
          <Grid item className={classes.titulo}>
            {pdf.status ? "" : "Histórico"}
          </Grid>
          <Grid item>
            <Grid container spacing={3}>
              <Grid item>
                {!pdf.status ? (
                  <InputData
                    type="text"
                    id="mesInicio"
                    name="mesInicio"
                    minDate={window.moment().subtract(10, "year").toDate()}
                    maxDate={window.moment().endOf("month").toDate()}
                    customValue={mesInicio}
                    onChange={(valor) => setMesInicio(valor)}
                    renderIconShowHide={false}
                    className={classes}
                    views={["month", "year"]}
                    autoOk
                    format="MMM/yyyy"
                  />
                ) : (
                  <div style={{ color: "black", fontSize: 22 }}>
                    {window.moment(mesInicio).format("MMM/yyyy")}
                  </div>
                )}
              </Grid>
              <Grid item>
                {!pdf.status ? (
                  <InputData
                    type="text"
                    id="mesFim"
                    name="mesFim"
                    minDate={new Date(mesInicio)}
                    maxDate={
                      !mobile
                        ? window.moment(mesInicio).add(11, "month").toDate()
                        : window.moment(mesInicio).add(4, "month").toDate()
                    }
                    customValue={mesFim}
                    onChange={(valor) => setMesFim(valor)}
                    renderIconShowHide={false}
                    className={classes}
                    views={["month", "year"]}
                    autoOk
                    format="MMM/yyyy"
                  />
                ) : (
                  <div
                    style={{ color: "black", fontSize: 22, marginLeft: -20 }}
                  >
                    {`-${window.moment(mesFim).format("MMM/yyyy")}`}
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className="m-0 w-100">
          <Grid item lg={12} xs={12}>
            <Loader loading={carregandoDados}>
              {custoCativo &&
              custoLivre &&
              economia &&
              dados?.options &&
              dados?.series ? (
                grafico
              ) : (
                <div className={classes.noData}>
                  Não foi possível carregar as informações de histórico, tente
                  novamente em algumas horas!
                </div>
              )}
            </Loader>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Historico;
