export default class FiltroDto {
  constructor(
    tamanhoPaginacao,
    pagina,
    searchString,
    empresaId,
    clienteId,
    unidadeId,
    ano,
    mes
  ) {
    this.tamanhoPaginacao = tamanhoPaginacao ?? 10;
    this.pagina = pagina ?? 1;
    this.empresaId = empresaId ?? "";
    this.clienteId = clienteId ?? "";
    this.unidadeId = unidadeId ?? "";
    this.ano = ano ?? "";
    this.mes = mes ?? "";
    this.busca = searchString ?? "";
  }
}
