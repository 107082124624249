import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef
} from "react";
import { Grid } from "@mui/material";
import { useHistory } from "react-router-dom";
import TuneIcon from "@mui/icons-material/Tune";

import shortid from "shortid";
import { useSelector } from "react-redux";
import { useForm, useWatch } from "react-hook-form";
import { RotasDTO } from "../../../../global/rotas/rotasUrlDto";
import {
  Botao,
  BotaoCadastro,
  Loader,
  ModalConfirmacao,
  Paginacao
} from "../../../../componentes";
import ItemListaExpansivel from "../../../../componentes/itemListaExpansivel";
import { Base } from "../../../../componentes/cores";

import MedidorGDHelper from "./helper";
import theme from "../../../../themes";
import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";
import { store } from "../../../../global/redux";
import {
  deletarMedidorGD,
  getTiposMedidores,
  getTiposTC,
  listarFiltrarPor,
  listarOrganizarPor,
  listarFiltrarClientePor
} from "../../../../servicos/medidorGDServico";
import MaterialInputBusca from "../../../../componentes/inputBusca";
import BotaoFiltroOrdenacao from "../../../../componentes/botaoFiltroOrdenar";

import { useStyles } from "./style";
import FiltroListagemMedidoresDto from "./dto/filtroListagemMedidoresDto";
import TabelaSemDados from "../../../../componentes/tabelaPaginada/tabelaSemDados";
import { InterfaceDTO } from "../../../../global/dto/interfacesDto";
import { usuarioPossuiFuncionalidade } from "../../../../servicos/funcionalidadesServico";

const ListagemMedidorGD = () => {
  const history = useHistory();
  const classes = useStyles();
  const [carregandoMedidores, setCarregandoMedidores] = useState(false);
  const [listaMedidores, setListaMedidores] = useState([]);
  const [modalExclusao, setModalExclusao] = useState(false);
  const [ordemSelecionada, setOrdemSelecionada] = useState("");
  const [filtroSelecionado, setFiltroSelecionado] = useState("");
  const [totalPaginas, setTotalPaginas] = useState();
  const [paginaAtual, setPaginaAtual] = useState();
  const [listaTipoTC, setListaTipoTC] = useState([]);
  const [listaTipoMedidor, setListaTipoMedidor] = useState([]);
  const [
    filtroTipoClienteSelecionado,
    setFiltroTipoClienteSelecionado
  ] = useState("");
  const [filtroTipoTCSelecionado, setFiltroTipoTCSelecionado] = useState("");
  const [
    filtroTipoMedidoresSelecionado,
    setFiltroTipoMedidoresSelecionado
  ] = useState("");
  const montouComponente = useRef(false);

  const { register, control } = useForm({
    reValidateMode: "onSubmit"
  });

  const rotas = useSelector((state) => state.rotas);
  const usuario = useSelector((state) => state.usuario);
  const {
    menu,
    unidadeSelecionada,
    clienteSelecionado,
    empresaSelecionada
  } = usuario;

  const permissaoEditarMedidorGD = usuarioPossuiFuncionalidade(
    menu,
    rotas.rotaAtual.caminho,
    RotasDTO.MedidorGD,
    InterfaceDTO.PermissaoEditarMedidorGD
  );

  const textoBusca = useWatch({
    control,
    name: "textoBusca",
    defaultValue: history?.location?.state?.dadosMedidorGd?.id?.toString() || ""
  });

  const textoPesquisa = useMemo(() => {
    return textoBusca?.trim() || "";
  }, [textoBusca]);

  const onClickFiltroSelecionado = (event) => {
    const val = filtroSelecionado === event ? "" : event;
    setFiltroSelecionado(val);
  };

  const handleClickFiltroTipoClienteSelecionado = (event) => {
    const val = filtroTipoClienteSelecionado === event ? "" : event;
    setFiltroTipoClienteSelecionado(val);
  };

  const handleClickFiltroTipoTCSelecionado = (event) => {
    const val = filtroTipoTCSelecionado === event ? "" : event;
    setFiltroTipoTCSelecionado(val);
  };

  const handleClickFiltroTipoMedidoresSelecionado = (event) => {
    const val = filtroTipoMedidoresSelecionado === event ? "" : event;
    setFiltroTipoMedidoresSelecionado(val);
  };

  const obterTiposMedidores = useCallback(async () => {
    try {
      const lista = await getTiposMedidores();
      if (lista?.data?.tiposMedidor)
        setListaTipoMedidor(lista?.data?.tiposMedidor);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  }, []);

  const filtroTiposMedidoresPor = () => {
    return listaTipoMedidor.map((status) => ({
      key: status.id,
      label: status.descricao
    }));
  };

  const obterTiposTC = useCallback(async () => {
    try {
      const lista = await getTiposTC();
      if (lista?.data?.medidorGDTipoTCLista)
        setListaTipoTC(lista?.data?.medidorGDTipoTCLista);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  }, []);

  const filtroTiposTCPor = () => {
    return listaTipoTC.map((status) => ({
      key: status.id,
      label: status.descricao
    }));
  };

  const [carregamentoInicial, setCarregamentoInicial] = useState(false);

  useEffect(() => {
    obterTiposMedidores();
    obterTiposTC();
    setCarregamentoInicial(true);
    listarMedidoresGD({ pagina: 1 });
  }, []);

  const listarMedidoresGD = async ({ pagina }) => {
    try {
      setCarregandoMedidores(true);
      const tamanhoPaginacao = 20;

      const filtro = new FiltroListagemMedidoresDto(
        ordemSelecionada,
        filtroSelecionado,
        pagina || paginaAtual,
        tamanhoPaginacao,
        unidadeSelecionada ?? null,
        textoPesquisa,
        clienteSelecionado ?? null,
        empresaSelecionada ?? null,
        filtroTipoClienteSelecionado,
        filtroTipoTCSelecionado,
        filtroTipoMedidoresSelecionado
      );

      const resultado = await MedidorGDHelper.ListarMedidorGD(filtro, history);
      if (resultado?.data) {
        setTotalPaginas(resultado?.data?.totalPaginas);
        setListaMedidores(resultado?.data?.medidoresGd);
        setPaginaAtual(resultado?.data?.paginaAtual);
      } else {
        setListaMedidores([]);
        setPaginaAtual();
        setTotalPaginas();
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    } finally {
      setCarregandoMedidores(false);
    }
  };

  const onChangePaginacao = (item, pagina) => {
    setCarregamentoInicial(true);
    setPaginaAtual(pagina);
    if (carregamentoInicial) {
      listarMedidoresGD({ pagina });
    }
  };

  useEffect(() => {
    if (montouComponente.current) {
      setCarregamentoInicial(true);
      if (carregamentoInicial) {
        listarMedidoresGD({ pagina: 1 });
      }
    } else montouComponente.current = true;
  }, [empresaSelecionada, clienteSelecionado, unidadeSelecionada]);

  useEffect(() => {
    if (textoPesquisa === "" && carregamentoInicial) {
      const debounce = setTimeout(() => {
        listarMedidoresGD({ pagina: 1 });
      }, 1000);
      return () => clearTimeout(debounce);
    }
  }, [textoPesquisa]);

  const onClickNovoMedidor = () => {
    history.push(`${RotasDTO.MedidorGD}/cadastro`);
  };

  const onClickEditar = (id) => {
    history.push(`${RotasDTO.MedidorGD}/cadastro/${id}`);
  };

  const deletar = async (item) => {
    try {
      setModalExclusao(false);
      setCarregandoMedidores(true);
      const deletou = await deletarMedidorGD(
        item?.id,
        !(modalExclusao?.situacao?.toUpperCase() === "AT")
      );
      if (deletou?.status === 204) {
        listarMedidoresGD({ pagina: paginaAtual });
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: `O medidor foi ${
              !(modalExclusao?.situacao?.toUpperCase() === "AT")
                ? "ativado"
                : "inativado"
            } com sucesso!`
          })
        );
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    } finally {
      setCarregandoMedidores(false);
    }
  };

  const onConfirmarExclusao = (item) => {
    try {
      deletar(item);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  const onClickExcluir = (item) => {
    setModalExclusao(item);
  };

  const onClickAtivarAgora = async () => {
    listarMedidoresGD({ pagina: 1 });
  };

  const onClickOrdemSelecionada = (event) => {
    const val = ordemSelecionada === event ? "" : event;
    setOrdemSelecionada(val);
  };

  const onClickSearch = () => {
    listarMedidoresGD({ pagina: 1 });
  };

  return (
    <Loader loading={carregandoMedidores}>
      <ModalConfirmacao
        item={modalExclusao}
        onConfirmar={(item) => onConfirmarExclusao(item)}
        mensagem={`Tem certeza que deseja ${
          modalExclusao?.situacao?.toUpperCase() === "AT"
            ? "inativar"
            : "ativar"
        } esse Medidor?`}
        onCancelar={() => setModalExclusao(false)}
      />
      <Grid
        container
        spacing={3}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={8} sm={5}>
          <MaterialInputBusca
            type="text"
            id="textoBusca"
            name="textoBusca"
            label="Buscar"
            renderIconShowHide
            searchAdornment
            permiteValorBranco
            defaultValue={textoBusca ?? ""}
            ref={register}
          />
        </Grid>

        <Grid item xs={4} sm={2}>
          <Botao
            type="submit"
            onClick={onClickSearch}
            label="Buscar"
            className={classes.button}
            disabled={!textoBusca}
          />
        </Grid>
        <Grid xs={2} />

        <Grid
          xs={12}
          sm={3}
          item
          display="flex"
          justifyContent={{ sm: "flex-end" }}
        >
          <BotaoFiltroOrdenacao
            type="button"
            color={theme.color.secondaryBorderColor}
            background="transparent"
            label="Filtrar / Ordernar"
            icon={<TuneIcon />}
            className={classes.buttonFiltro}
            ordenacao={listarOrganizarPor()}
            ordenadorUm="Ordenador"
            ordenadorDois="Filtrar"
            ordenadorTres="Tipo Cliente"
            ordemSelecionada={ordemSelecionada}
            filtros={listarFiltrarPor()}
            filtrosSelecionados={filtroSelecionado}
            onClickOrdenacao={onClickOrdemSelecionada}
            onClickFiltro={onClickFiltroSelecionado}
            onClickAtivarAgora={onClickAtivarAgora}
            filtroTipoCliente={listarFiltrarClientePor()}
            filtrosTipoClienteSelecionado={filtroTipoClienteSelecionado}
            onClickFitroTipoCliente={handleClickFiltroTipoClienteSelecionado}
            ordenadorNove="Tipos TC"
            filtroTipoTC={filtroTiposTCPor()}
            filtrosTipoTCSelecionado={filtroTipoTCSelecionado}
            onClickFitroTipoTC={handleClickFiltroTipoTCSelecionado}
            ordenadorDez="Tipos Medidores"
            filtroTipoMedidores={filtroTiposMedidoresPor()}
            filtrosTipoMedidoresSelecionado={filtroTipoMedidoresSelecionado}
            onClickFitroTipoMedidores={
              handleClickFiltroTipoMedidoresSelecionado
            }
            larguraBotao={3}
          />
        </Grid>

        <Grid item xs={12}>
          <BotaoCadastro
            label="Novo medidor"
            color={theme.color.buttonColor}
            onClick={onClickNovoMedidor}
          />
        </Grid>
        {listaMedidores && listaMedidores?.length > 0 ? (
          <>
            {listaMedidores.map((medidor) => {
              return (
                <ItemListaExpansivel
                  key={shortid.generate()}
                  label={medidor?.codigoMedidor}
                  color={Base.White}
                  edicao={!!medidor?.id}
                  exclusao={!!medidor?.id}
                  onClickEditar={() => onClickEditar(medidor?.id)}
                  onClickExcluir={
                    !permissaoEditarMedidorGD
                      ? () => {}
                      : () => onClickExcluir(medidor)
                  }
                  ativo={medidor?.situacao === "AT"}
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                  disabled={!permissaoEditarMedidorGD}
                >
                  <div>
                    <strong>Descrição: </strong>
                    {medidor?.tipoMedidor ?? ""}
                  </div>
                </ItemListaExpansivel>
              );
            })}
            <Grid
              container
              spacing={3}
              direction="row"
              justifyContent="center"
              alignItems="center"
              marginTop={2}
            >
              <Grid item>
                <Paginacao
                  paginaAtual={paginaAtual}
                  totalPaginas={totalPaginas}
                  onChangePagina={onChangePaginacao}
                />
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid
            container
            spacing={3}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <TabelaSemDados className={classes.container} />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Loader>
  );
};

export default ListagemMedidorGD;
