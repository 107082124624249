/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import moment from "moment";
import { Card, Grid, TextField } from "@mui/material";
import BotaoRetornarListagem from "componentes/botaoRetornarListagem";
import InputData from "componentes/inputTextoData";
import UploadDropArea from "componentes/upload";
import ModalConfirmacao from "componentes/modalConfirmacao";
import Botao from "componentes/botao";
import Loader from "componentes/loader";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { useHistory } from "react-router-dom";
import RotasDTO from "../../../../rotas/rotasUrlDto";
import { valoresDispositivos, importarCDEEnergia } from "../../../../servicos/cdeServico";
import { useStyles } from "./style";

const CDEEnergiaImportacao = () => {
  const classes = useStyles();
  const history = useHistory();

  const usuarioGlobal = useSelector((state) => state.usuario);

  const [dispositivosAtuais, setDispositivosAtuais] = useState([]);
  const [dispositivo, setDispositivo] = useState("");

  const [file, setFile] = useState(null);
  const msgPadraoUpload = "Solte o arquivo para anexá-lo ou procurar";
  const [fileName, setFileName] = useState(msgPadraoUpload);
  const [carregandoArquivo, setCarregandoArquivo] = useState(false);
  const [progressMesage, setProgressMessage] = useState("");
  const [modal, setModal] = useState(false);
  const [modalMessage, setModalMessage] = useState(false);
  const [importando, setImportando] = useState(false);
  const [isReimportacao, setIsReimportacao] = useState(false);
  const [anoBase, setAnoBase] = useState(null);
  const [qtdParcelas, setQtdParcelas] = useState("");
  const [anoAtual] = useState(moment().format("YYYY"));

  const onFechar = (id) => {
    history.push(`${RotasDTO.ProjecaoInsumoListar}`);
  }

  const { handleSubmit } = useForm({
    reValidateMode: "onSubmit",
  });

  const handleChangeAnoBase = (data) => {
    setIsReimportacao(false);
    setAnoBase(data);
  }

  const handleChangeQtdParcelas = (event) => {
    setIsReimportacao(false);
    setQtdParcelas(event.target.value);
  }

  const handleChangeDispositivo = (event) => {
    setIsReimportacao(false);
    setDispositivo(event.target.value);
  }

  const aoCancelar = () => {
    setFile(null);
    setFileName(msgPadraoUpload);
    setAnoBase(null);
    setQtdParcelas("");
    setDispositivo("");
    setCarregandoArquivo(false);
    setProgressMessage("");
  };

  const obterDados = useCallback(async () => {
    try {
      setImportando(true);
      const response = await valoresDispositivos();

      if (response.status !== 200 || !response.data) {
        console.error(response);
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem:
              "Sem dispositivos encontrados!"
          })
        );
      }

      setDispositivosAtuais(response.data);
      setImportando(false);
    } catch (error) {
      setImportando(false);
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  }, [])

  useEffect(() => {
    if (!dispositivosAtuais.length) obterDados();
  }, [!dispositivosAtuais.length, obterDados]);

  const handleClickItem = (item) => {
    setAnoBase(moment(item.ano, "YYYY"));
    setQtdParcelas(item.qtdParcelas);
    setDispositivo(item.dispositivo);
    setIsReimportacao(true);
  }

  const EnviarForm = async () => {
    try {
      setCarregandoArquivo(true);
      setProgressMessage("Importando o arquivo... Por favor, aguarde.");

      const ano = moment(anoBase).format("YYYY");
      const response = await importarCDEEnergia(file, ano, dispositivo, qtdParcelas, usuarioGlobal?.usuario?.id, usuarioGlobal?.usuario?.usuarioNome);
      if (response.status === 200 && response?.data) {
        aoCancelar();
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: `Arquivo ${fileName} importado com sucesso!`
          })
        );
      } else if (response.status === 202 && response?.data) {
        aoCancelar();
        store.dispatch(
          alertaExibir({
            tipo: "danger",
            mensagem: response.data[0].ItemValidacao.Mensagem
          })
        );
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  }

  const onConfirmar = () => {
    setModal(false);
    EnviarForm();
  };

  const onCancelar = () => {
    setModal(false);
    aoCancelar();
  };

  const aoEnviarForm = async () => {
    if (file && anoBase && qtdParcelas && dispositivo) {
      setModal(true);
      if (isReimportacao) {
        setModalMessage(
          <p className={classes.customColorText}>
            {`Deseja incluir o dispositivo ${dispositivo} com o ano base ${moment(anoBase).format("YYYY")}? Caso contrário, volte e altere o nome do dispositivo.`}
          </p>
        );
      } else {
        setModalMessage(
          <p className={classes.customColorText}>
            {`Deseja incluir o dispositivo ${dispositivo} com o ano base ${moment(anoBase).format("YYYY")}?`}
          </p>
        );
      }
    } else {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: "É necessário informar o arquivo e/ou ano base, quantidades de parcela e dispositivo!"
        })
      );
    }
  }

  const processarArquivo = (arquivo) => {
    if (arquivo != null && arquivo[0] != null) {
      setFile(arquivo[0]);
      setFileName(arquivo[0].file.name);
    }
  };

  const onAddUpload = (arquivo) => {
    processarArquivo(arquivo)
  };

  return (
    <form className="needs-validation" onSubmit={handleSubmit(aoEnviarForm)}>
      <Loader loading={importando} className="w-auto">
        <ModalConfirmacao
          item={modal}
          onConfirmar={(item) => onConfirmar(item)}
          mensagem={modalMessage}
          onCancelar={onCancelar}
        />

        <Card className={classes.container}>
          <Grid container className={classes.containerTitulo}>
            <Grid item xs={11} className="font-weight-bold">
              Importação de Planilha - Resoluções CDE Energia
            </Grid>
            <Grid item xs={1}>
              <CloseIcon onClick={onFechar} className={classes.icon} />
            </Grid>
          </Grid>
          {carregandoArquivo === false ?
            <Grid container p={2} spacing={4} className={classes.container}>
              {dispositivosAtuais.length > 0 ?
                <Grid item sm={3}>
                  <strong>Dispositivos Atuais</strong>
                  <ul className={classes.lista}>
                    {dispositivosAtuais?.map((item) => (
                      <li aria-hidden="true" onClick={() => handleClickItem(item)}>
                        {item.ano}
                        -
                        {item.dispositivo}
                      </li>
                    ))}
                  </ul>
                </Grid>
              : ""}
              <Grid item sm={dispositivosAtuais.length > 0 ? 8 : 12}>
                <Grid container p={2} spacing={4} className={classes.container} justifyContent="center" alignItems="center">
                  <Grid item sm={3}>
                    <InputData
                      type="text"
                      label="Ano Base"
                      name="anoBase"
                      customValue={anoBase}
                      minDate={new Date("1970")}
                      onChange={(data) => handleChangeAnoBase(data)}
                      maxDate={new Date("2091")}
                      format="yyyy"
                      views={["year"]}
                      openTo="year"
                      renderIconShowHide={false}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <TextField
                      id="qtdParcelas"
                      label="Qtd. de parcelas"
                      variant="outlined"
                      value={qtdParcelas}
                      onChange={(event) => handleChangeQtdParcelas(event)}
                      style={{
                        width: "100%"
                      }}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <TextField
                      id="txtDispositivo"
                      label="Dispositivo"
                      variant="outlined"
                      value={dispositivo}
                      onChange={(event) => handleChangeDispositivo(event)}
                      style={{
                        width: "100%"
                      }}
                    />
                  </Grid>
                  <Grid item sm={12} className={classes.fileUpload}>
                    <UploadDropArea
                      onAdd={onAddUpload}
                      dropAreaText={fileName}
                      showLoader={false}
                      acceptedFiles={[
                        ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                      ]}
                    />
                  </Grid>
                  <Grid item sm={3}>
                    <Botao
                      type="button"
                      label="Cancelar"
                      onClick={aoCancelar}
                      className={classes.button}
                    />
                  </Grid>
                  <Grid item sm={3}>
                    <Botao
                      type="submit"
                      label="Salvar"
                      className={classes.button}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            :
            <Grid container spacing={4} className={classes.container} style={{ marginBottom: "20px" }}>
              <Grid item sm={12} style={{ textAlign: "center" }}>
                {progressMesage}
              </Grid>
              <Grid item sm={12}>
                <Loader loading={carregandoArquivo} />
              </Grid>
            </Grid>
          }
        </Card>
      </Loader>
    </form>
  );
}

export default CDEEnergiaImportacao;