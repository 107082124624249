import moment from "moment";
import React, { useEffect, useState } from "react";

// Componentes
import { Grid } from "@mui/material";
import { Botao, Loader } from "componentes";
import { useForm } from "react-hook-form";
import theme from "themes";
import BotaoCadastro from "../../../componentes/botaoCadastro";
import InputData from "../../../componentes/inputTextoData";
import {
  listarConstantes,
  postarConstantes
} from "../../../servicos/precoServico";
import InputBlock from "../Componentes/InputBlock";
import TabelaConstantesAdicionar from "../Componentes/TabelaConstantesAdicionar";
import TabelaPrecoConstantes from "../Componentes/TabelaPrecoConstantes";

// Redux
import { store } from "../../../global/redux";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";

// Form Hooks

// Styles
import { useStyles } from "./style";

const Constantes = () => {
  const classes = useStyles();
  const { register } = useForm({
    reValidateMode: "onSubmit"
  });
  const [temErro, setTemErro] = useState(false);
  const [filtro, setFiltro] = useState({});
  const [ultimaAtualizacao, setUltimaAtualizacao] = useState("");
  const [carregando, setCarregando] = useState(false);
  const [dadosSalvos, setDadosSalvos] = useState([]);
  const [constantesSalvar, setConstantesSalvar] = useState([]);
  const [botaoDesabilitado, setBotaoDesabilitado] = useState(true);

  const salvarDados = (valor, index, campo) => {
    const novasLinhasAdicionar = constantesSalvar;
    novasLinhasAdicionar[index][campo] = valor;
    setConstantesSalvar([...novasLinhasAdicionar]);
  };

  const salvarUltimaAtualizacao = (precos) => {
    if (precos?.length) {
      const max = new Date(
        Math.max(...precos.map((e) => new Date(e.dataInicioVigencia)))
      );

      if (max) {
        setUltimaAtualizacao(moment(max).format("DD/MM/yyyy HH:mm"));
      }
    } else {
      setUltimaAtualizacao(null);
    }
  };

  const adicionarLinha = () => {
    const linha = {
      dataInicioVigencia: "",
      dataFimVigencia: "",
      contribuicaoAssociativa: null,
      cosip: null,
      custoConexao: null,
      encargos: null,
      custosAdicionais: null,
      margemLuz: null
    };
    setConstantesSalvar((prevState) => [...prevState, linha]);
  };

  const verificarData = (dados) => {
    if (Object.keys(dados)?.length === 0) return true;

    const dataInicio = new Date(dados.dataInicial);
    const dataFim = new Date(dados.dataFinal);

    if (dados?.dataInicial && Number.isNaN(dataInicio.getFullYear())) {
      return false;
    }

    if (dados?.dataFinal && Number.isNaN(dataFim.getFullYear())) {
      return false;
    }

    if (
      dados?.dataInicial &&
      dados?.dataFinal &&
      moment(dataFim).format("yyyy-MM-DD") <
        moment(dataInicio).format("yyyy-MM-DD")
    ) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Data inicial deve ser menor ou igual a data final!"
        })
      );
      return false;
    }

    return true;
  };

  const buscarConstantes = async (dadosFiltro = {}) => {
    try {
      const filterFormatted = {};

      if (!verificarData(dadosFiltro)) {
        setCarregando(false);
        return;
      }

      if (dadosFiltro?.dataInicial) {
        filterFormatted.dataInicial = moment(dadosFiltro.dataInicial).format(
          "yyyy-MM-DD"
        );
      }
      if (dadosFiltro?.dataFinal) {
        filterFormatted.dataFinal = moment(dadosFiltro.dataFinal).format(
          "yyyy-MM-DD"
        );
      }

      setCarregando(true);
      const item = await listarConstantes(filterFormatted);
      const { precos } = item.data;
      if (precos) {
        salvarUltimaAtualizacao(precos);

        setDadosSalvos(precos);
      } else {
        setDadosSalvos([]);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    } finally {
      setCarregando(false);
    }
  };

  const habilitarBotaoSalvar = () => {
    if (constantesSalvar?.length) {
      setBotaoDesabilitado(false);
    }
  };

  const verificarCamposTabelaData = () => {
    let temDataInvalida = false;

    constantesSalvar.forEach((item) => {
      const dataInicio = new Date(item.dataInicioVigencia);
      const dataFim = new Date(item.dataFimVigencia);

      if (dataInicio && Number?.isNaN(dataInicio.getFullYear())) {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "Data inicío vigência inválida!"
          })
        );
        temDataInvalida = true;
        return;
      }

      if (dataFim && Number?.isNaN(dataFim.getFullYear())) {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "Data fim vigência inválida!"
          })
        );
        temDataInvalida = true;
        return;
      }

      if (
        moment(dataFim).format("yyyy-MM-DD") <
        moment(dataInicio).format("yyyy-MM-DD")
      ) {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "Data inicial deve ser menor ou igual a data final!"
          })
        );
        temDataInvalida = true;
      }
    });

    return temDataInvalida;
  };

  const verificarCamposTabela = () => {
    let temCampoVazio = false;
    constantesSalvar.forEach((item) => {
      if (
        !item.dataInicioVigencia ||
        !item.dataFimVigencia ||
        !item.contribuicaoAssociativa ||
        !item.cosip ||
        !item.custoConexao ||
        !item.encargos ||
        !item.custosAdicionais ||
        !item.margemLuz
      ) {
        temCampoVazio = true;
      }
    });

    setTemErro(true);
    return temCampoVazio;
  };

  const alterarDataInicial = (dataInicial) => {
    setFiltro((prevState) => ({
      ...prevState,
      dataInicial
    }));
    buscarConstantes({
      ...filtro,
      dataInicial
    });
  };

  const alterarDataFinal = (dataFinal) => {
    buscarConstantes({
      ...filtro,
      dataFinal
    });
    setFiltro((prevState) => ({
      ...prevState,
      dataFinal
    }));
  };

  const salvarConstantes = async () => {
    try {
      setCarregando(true);

      if (verificarCamposTabela()) {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "preencha e salve todos os campos."
          })
        );
        return;
      }

      if (verificarCamposTabelaData()) {
        return;
      }

      const dadosFormatados = [];

      constantesSalvar.map((item) =>
        dadosFormatados.push({
          contribuicaoAssociativa: parseFloat(
            item.contribuicaoAssociativa?.replace(",", ".")
          ),
          cosip: parseFloat(item.cosip?.replace(",", ".")),
          custoConexao: parseFloat(item.custoConexao?.replace(",", ".")),
          encargos: parseFloat(item.encargos?.replace(",", ".")),
          custosAdicionais: parseFloat(
            item.custosAdicionais?.replace(",", ".")
          ),
          margemLuz: parseFloat(item.margemLuz?.replace(",", ".")),
          dataInicioVigencia: moment(item.dataInicioVigencia).format(
            "yyyy-MM-DDTHH:mm"
          ),
          dataFimVigencia: moment(item.dataFimVigencia).format(
            "yyyy-MM-DDTHH:mm"
          )
        })
      );

      const retorno = await postarConstantes(dadosFormatados);

      if (retorno?.status === 200 || retorno?.status === 204) {
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: "Dados salvos com sucesso!"
          })
        );

        buscarConstantes(filtro);
        setConstantesSalvar([]);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    } finally {
      setCarregando(false);
    }
  };

  useEffect(() => {
    buscarConstantes();
  }, []);

  useEffect(() => {
    habilitarBotaoSalvar();
  }, [constantesSalvar]);

  return (
    <>
      <Grid container spacing={1} className="mb-3">
        <Grid item lg={5} md={5} sm={12} xs={12} className="mb-3">
          <BotaoCadastro
            label="Adicionar Nova Vigência"
            type="button"
            onClick={adicionarLinha}
            color={theme.color.primary}
            className={classes.adicionar}
          />
        </Grid>
        <Grid item lg={2} md={3} sm={4} xs={12}>
          <InputData
            type="text"
            id="dataInicial"
            name="dataInicial"
            label="Data Inicial"
            customValue={filtro.dataInicial || null}
            onChange={(data) => alterarDataInicial(data)}
            onBlur={(event) => alterarDataInicial(event.target.value)}
            className={classes}
          />
        </Grid>
        <Grid item lg={2} md={3} sm={4} xs={12}>
          <InputData
            type="text"
            id="dataFinal"
            name="dataFinal"
            label="Data Final"
            customValue={filtro.dataFinal || null}
            onChange={(data) => alterarDataFinal(data)}
            onBlur={(event) => alterarDataFinal(event.target.value)}
            className={classes}
          />
        </Grid>
        <Grid item lg={2} md={3} sm={4} xs={12}>
          <InputBlock
            type="text"
            id="ultimaAtualizacao"
            name="ultimaAtualizacao"
            label="Última atualização"
            value={ultimaAtualizacao}
            className="w-100 pr-2"
            readOnly
            disabled
          />
        </Grid>
      </Grid>
      <Loader loading={carregando}>
        <div style={{ display: "flex", height: "100%", width: "100%" }}>
          <div style={{ flexGrow: 1, overflowX: "scroll" }} className="mb-1">
            <Grid container>
              <TabelaConstantesAdicionar
                salvar={(valor, index, campo) =>
                  salvarDados(valor, index, campo)
                }
                erros={temErro}
                register={register}
                linhas={constantesSalvar}
              />
            </Grid>
            <Grid container>
              <TabelaPrecoConstantes linhas={dadosSalvos} />
            </Grid>
          </div>
        </div>
      </Loader>
      <Grid container className="m-3 w-100">
        <Grid item md={3}>
          <Botao
            label="Salvar"
            onClick={() => salvarConstantes()}
            disabled={botaoDesabilitado}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Constantes;
