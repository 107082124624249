import PropTypes from "prop-types";
import React, { useState } from "react";
import { generate } from "shortid";

import { GetApp } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";

import { Loader } from "componentes";
import { HtmlTooltip } from "paginas/Graficos/ConsumoAtual/style";
import Helper from "../helper";
import { useStyles } from "./style";

// eslint-disable-next-line no-unused-vars
const ColunaBaixar = ({ onClick, parametros }) => {
  const classes = useStyles();
  const [carregandoDownload, setcarregandoDownload] = useState(false);

  const onClickDownload = async () => {
    try {
      setcarregandoDownload(true);
      await Helper.BaixarPorUUID(parametros);
      setcarregandoDownload(false);
    } catch (error) {
      setcarregandoDownload(false);
      Helper.exibirErro(
        error?.response?.data?.message ?? "Ocorreu um erro ao obter arquivo!"
      );
    }
  };

  return (
    <Loader loading={carregandoDownload}>
      <Grid container spacing={0} className={classes.fundoCelula}>
        <Grid item xs={12}>
          <Button
            key={generate()}
            onClick={onClickDownload}
            className={`text-white ${classes.botao}`}
          >
            <HtmlTooltip title="Baixar">
              <GetApp />
            </HtmlTooltip>
          </Button>
        </Grid>
      </Grid>
    </Loader>
  );
};

ColunaBaixar.propTypes = {
  onClick: PropTypes.oneOfType([PropTypes.func]).isRequired,
  parametros: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired
};

export default ColunaBaixar;
