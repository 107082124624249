// Node mudules
import { Grid } from "@mui/material";
import moment from "moment";

// Hooks
import { RotasDTO } from "global/rotas/rotasUrlDto";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useStyles } from "./style";

// Componentes
import BotaoBranco from "../../../../componentes/botaoBranco";
import Loader from "../../../../componentes/loader";
import PlanosConstante from "../../../Cotacao/Planos/constantes/planosConstante";
import PlanosSimuladosHelper from "../../../Cotacao/Planos/helper";
import CardPlano from "./componentes/cardPlano";
import SemPlanos from "./componentes/semPlanos";

// Redux
import { store } from "../../../../global/redux";
import {
  desabilitarCliente,
  desabilitarEmpresa,
  desabilitarUnidade
} from "../../../../global/redux/modulos/usuario/actions";

// DTO

const MelhoresPlanos = () => {
  const classes = useStyles();
  const history = useHistory();
  const { state } = useLocation();

  // Estado para controle dados do calculo do plano simulado
  const [dadosPlanos, setDadosPlanos] = useState([]);

  // Estado para controle dos dados do endpoint de consulta (será utilizado apenas no post)
  // eslint-disable-next-line no-unused-vars
  const [dadosBanco, setDadosBanco] = useState(null);

  // Estado para contole das datas de cotação e validade
  const [dataCotacao, setDataCotacao] = useState(null);
  const [dataValidade, setDataValidade] = useState(null);

  // Estado para controle do loading da tela
  const [carregando, setCarregando] = useState(false);

  const planosDisponiveis = useMemo(() => {
    if (!dadosPlanos || dadosPlanos.length === 0) return [];

    return PlanosConstante.filter((plano) => {
      return dadosPlanos.some(
        (dado) => dado.tipoPlanoSimulado === plano.tipoPlano
      );
    });
  }, [dadosPlanos, PlanosConstante]);

  const dadosCotacaoInicial = useMemo(() => {
    return state?.dadosCotacaoInicial;
  }, [state]);

  const atribuirDadosState = (resultado) => {
    setDadosBanco(resultado.data);
    setDataCotacao(resultado.data.dataCotacao);
    setDataValidade(resultado.data.dataValidade);
    setDadosPlanos(resultado.data.planos);
  };

  const onEscolherPlano = async (dadosCardSelecionado) => {
    if (!dadosCardSelecionado) return;

    const retorno = await PlanosSimuladosHelper.selecionarPlano(
      dadosCardSelecionado.id,
      dadosCotacaoInicial?.id
    );

    if (!retorno.sucesso) {
      PlanosSimuladosHelper.exibirErro(retorno.mensagem);
      return;
    }

    history.push(
      `${RotasDTO.Demo}/cotacao/contato/${state.dadosCotacaoInicial.id}`,
      state.dadosCotacaoInicial
    );
  };

  const prosseguirSemEscolha = () => {
    history.push(
      `${RotasDTO.Demo}/cotacao/contato/${state.dadosCotacaoInicial.id}`,
      state.dadosCotacaoInicial
    );
  };

  const onClickVoltar = () => {
    history.goBack();
  };

  const obterPlanosSimulados = useCallback(async () => {
    setCarregando(true);

    const resultado = await PlanosSimuladosHelper.ObterPlanosSimulados(
      dadosCotacaoInicial?.id
    );

    if (resultado.sucesso) atribuirDadosState(resultado);

    setCarregando(false);
  }, [setDadosPlanos]);

  useEffect(() => {
    obterPlanosSimulados();
  }, [obterPlanosSimulados]);

  useEffect(() => {
    store.dispatch(desabilitarCliente(true));
    store.dispatch(desabilitarUnidade(true));

    return () => {
      store.dispatch(desabilitarEmpresa(false));
      store.dispatch(desabilitarCliente(false));
      store.dispatch(desabilitarUnidade(false));
    };
  }, []);

  return (
    <Loader loading={carregando}>
      <Grid container>
        {dataCotacao && planosDisponiveis?.length > 0 && (
          <Grid item lg={12} md={12} className={classes.divCabecalho}>
            <Grid
              container
              spacing={1}
              justifyContent="space-between"
              alignItems="center"
              className="mb-3"
            >
              <Grid item>O que nós recomendamos para você:</Grid>
              <Grid item className={classes.divEscolherDepois}>
                <BotaoBranco
                  className={classes.botaoEscolherDepois}
                  label="Escolher depois"
                  onClick={prosseguirSemEscolha}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        {planosDisponiveis?.length > 0 ? (
          <Grid container justifyContent="space-between">
            {planosDisponiveis.map((planoConfiguracao, index) => (
              <Grid
                item
                lg={4}
                md={4}
                sm={12}
                xs={12}
                className={classes.cards}
              >
                <CardPlano
                  planoConfiguracao={planoConfiguracao}
                  dadosCards={dadosPlanos.find(
                    (dado) =>
                      dado.tipoPlanoSimulado === planoConfiguracao.tipoPlano
                  )}
                  onEscolherPlano={onEscolherPlano}
                  ultimoItem={
                    String(planosDisponiveis?.length - 1) === String(index)
                  }
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <SemPlanos
            onClickVoltar={() => onClickVoltar()}
            onClickProsseguir={() => prosseguirSemEscolha()}
          />
        )}
        <Grid container justifyContent="space-between">
          <Grid item className={classes.informacoesAsterisco}>
            *Os valores são projeções que podem variar (Menos o percentual de
            redução de custos)
          </Grid>
          <Grid item className={classes.dataValidade}>
            {`Validade da cotação: ${moment(dataValidade).format(
              "DD/MM/YYYY"
            )}`}
          </Grid>
        </Grid>
      </Grid>
    </Loader>
  );
};

export default MelhoresPlanos;
