import React from "react";
import PropTypes from "prop-types";

// Componentes
import { Box } from "@mui/material";

// Styles
import { useStyles } from "./style";

import ImagemFundo from "../../../../assets/jornadaCliente/imagem-quem-somos.png";
import IconeLista from "../../../../assets/jornadaCliente/icone-lista.png";
import IconeSol from "../../../../assets/jornadaCliente/icone-sol.png";
import IconeLuz from "../../../../assets/jornadaCliente/icone-luz.png";

const Pagina4 = ({ titulo, conteudo }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div>
        <img className={classes.iconeSol} src={IconeSol} alt="imagem sol" />
        <img
          className={classes.imagemFundo}
          src={ImagemFundo}
          alt="imagem lista"
        />
        <img
          className={classes.iconeLista}
          src={IconeLista}
          alt="imagem lista"
        />
      </div>

      <Box className={classes.conteudo}>
        <Box className={classes.titulo}>
          {titulo}
          <div className={classes.borda} />
        </Box>
        <Box pt={3} height="100%">
          {conteudo}
        </Box>
      </Box>
      <img className={classes.iconeLuz} src={IconeLuz} alt="imagem luz" />
    </div>
  );
};

Pagina4.propTypes = {
  titulo: PropTypes.node,
  conteudo: PropTypes.node
};

Pagina4.defaultProps = {
  titulo: <></>,
  conteudo: <></>
};

export default Pagina4;
