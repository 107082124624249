import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// Form Hooks
import { useForm } from "react-hook-form";

// Componentes
import { Card, Divider, Grid } from "@mui/material";
import Botao from "componentes/botao";
import { Base } from "componentes/cores";
import MaterialInputTexto from "componentes/inputTexto/materialInput";
import MaterialInputMascara from "componentes/inputTextoMascara";
import Loader from "componentes/loader";
import SelectAutoComplete from "componentes/selectAutocomplete";
import Coluna from "componentes/tabelaPaginada/colunas/coluna";
import { cnpj } from "cpf-cnpj-validator";
import DataTable from "react-data-table-component";

// Serviços
import PhotoCameraOutlinedIcon from "@mui/icons-material/PhotoCameraOutlined";
import { salvarDadosEnergia } from "global/redux/modulos/demo/actions";
import { RotasDTO } from "global/rotas/rotasUrlDto";
import { useSelector } from "react-redux";
import { buscarDadosCadastraisPorCnpj } from "../../../../servicos/dadosCadastraisCnpjServico";
import { buscarDistribuidoras } from "../../../../servicos/distribuidorasServico";
import CotacaoInicialHelper from "../../../Cotacao/CotacaoInicial/helper";

// Styles
import { customStyles, TabelaSemDados, useStyles } from "./style";

// Redux
import { store } from "../../../../global/redux";
import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";
import {
  desabilitarCliente,
  desabilitarEmpresa,
  desabilitarUnidade
} from "../../../../global/redux/modulos/usuario/actions";

// DTO

// Ícones

const DadosEnergia = () => {
  const classes = useStyles();
  const history = useHistory();

  const usuario = useSelector((state) => state.usuario);
  const demo = useSelector((state) => state.demo);
  const { dadosEnergia, mostrarHistorico, dadosHistorico } = demo;

  const { register, errors, handleSubmit, getValues } = useForm({
    reValidateMode: "onSubmit"
  });

  const [carregando, setCarregando] = useState(false);
  const [colaboradorId, setColaboradorId] = useState();

  useEffect(() => {
    setColaboradorId(usuario?.usuario?.colaboradorId);
  }, [usuario]);

  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    obterDistribuidora();
    store.dispatch(desabilitarCliente(true));
    store.dispatch(desabilitarUnidade(true));

    return () => {
      store.dispatch(desabilitarEmpresa(false));
      store.dispatch(desabilitarCliente(false));
      store.dispatch(desabilitarUnidade(false));
    };
  }, []);

  const formataValor = (valor) => {
    return valor
      ? valor
          .replace("R$", "")
          .replace("kW", "")
          .replace(".", "")
          .replace(",", ".")
      : valor;
  };

  const [nome, setNome] = useState();
  const [carregandoNome, setCarregandoNome] = useState(false);

  const [distribuidoraId, setDistribuidoraId] = useState();
  const [listaDistribuidora, setListaDistribuidora] = useState([]);
  const [carregandoDistribuidora, setCarregandoDistribuidora] = useState(false);

  const obterDistribuidora = async () => {
    try {
      setCarregandoDistribuidora(true);
      const lista = await buscarDistribuidoras();
      if (lista?.status === 200 && lista?.data) {
        setListaDistribuidora(lista?.data);
      }
      setCarregandoDistribuidora(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoDistribuidora(false);
    }
  };

  const [dadosCnpj, setDadosCnpj] = useState();

  const formatarDadosCotacao = (dados) => {
    const cnpjFormatado = dados?.cnpj?.replace(/[^a-zA-Z0-9]/g, "");

    return {
      DistribuidoraId: distribuidoraId,
      ConsumidorLivre: false,
      UnidadeCnpj: false,
      Cnpj: cnpjFormatado,
      NomeFantasia: dados?.nomeFantasia,
      PrevisaoInicioContrato: window.moment().format("YYYY-MM-DD"),
      ValorMedioConta: formataValor(dados?.valorMedio),
      DemandaContratada: formataValor(dados?.demandaContratadaKW),
      ColaboradorId: colaboradorId,
      EmpresaId: usuario.empresaSelecionada,
      Status: "AT"
    };
  };

  const gerarPlanosSimulados = async (cotacaoId) => {
    const resultado = await CotacaoInicialHelper.simularPlanos(cotacaoId);

    if (!resultado.sucesso) CotacaoInicialHelper.exibirErro(resultado.mensagem);
  };

  const enviarFormulario = async (dados) => {
    try {
      setCarregando(true);

      if (usuario.empresaSelecionada === "") {
        CotacaoInicialHelper.exibirErro("É necessário selecionar uma Empresa");
        return;
      }

      if (!distribuidoraId) {
        CotacaoInicialHelper.exibirErro(
          "É necessário selecionar uma Distribuidora"
        );
        return;
      }

      const dadosCotacao = formatarDadosCotacao(dados);
      const resultado = await CotacaoInicialHelper.salvarCotacao(dadosCotacao);

      if (!resultado.sucesso) {
        CotacaoInicialHelper.exibirErro(resultado.mensagem);
        return;
      }

      await gerarPlanosSimulados(resultado.data.id);

      history.push(`${RotasDTO.Demo}/cotacao/planos/${resultado.data.id}`, {
        dadosCotacaoInicial: resultado.data,
        dadosCnpj
      });
    } catch (error) {
      CotacaoInicialHelper.exibirErro(
        "Erro interno, entre em contato com o suporte!"
      );
    } finally {
      setCarregando(false);
    }
  };

  const aoEnviarFormulario = (dados) => {
    enviarFormulario(dados);
  };

  const onClickTirarFoto = () => {
    const valores = getValues([
      "cnpj",
      "nomeFantasia",
      "valorMedio",
      "demandaContratadaKW"
    ]);

    store.dispatch(
      salvarDadosEnergia({
        distribuidoraId,
        ...valores
      })
    );

    history.push(`${RotasDTO.Demo}/cotacao/captura`);
  };

  const verificarCnpj = useCallback(async (valor) => {
    const cnpjFormatado = valor?.target.value.replace(/[^a-zA-Z0-9]/g, "");
    if (cnpjFormatado.length === 14) {
      try {
        setDadosCnpj({ cnpj: cnpjFormatado });
        setCarregandoNome(true);
        const dadosCadastrais = await buscarDadosCadastraisPorCnpj(
          cnpjFormatado
        );

        if (dadosCadastrais?.status === 200 && dadosCadastrais?.data) {
          setDadosCnpj(dadosCadastrais.data.data);
          setNome(
            dadosCadastrais.data.data.nomeFantasia ||
              dadosCadastrais.data.data.razaoSocial
          );
          if (
            dadosCadastrais?.data?.data?.situacaoCadastral !== "2" &&
            dadosCadastrais?.data?.data?.descricaoDaSituacaoCadastral !==
              "Ativa"
          ) {
            store.dispatch(
              alertaExibir({
                tipo: "warning",
                mensagem:
                  "CNPJ inserido encontra-se irregular na Receita Federal"
              })
            );
          }
        }
        setCarregandoNome(false);
      } catch (error) {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem:
              error?.response?.data?.message ??
              "CNPJ não encontrado na Receita Federal"
          })
        );
        setCarregandoNome(false);
      }
    }
  }, []);

  const colunas = [
    new Coluna("data", "Data", true),
    new Coluna("consumo", "Consumo (kWh)", true),
    new Coluna("data2", "Data", true),
    new Coluna("consumo2", "Consumo (kWh)", true)
  ];

  const [objetoDadosHistorico, setObjetoDadosHistorico] = useState([]);

  useEffect(() => {
    if (mostrarHistorico && dadosHistorico?.length)
      setObjetoDadosHistorico(dadosHistorico);
  }, [mostrarHistorico]);

  useEffect(() => {
    if (dadosEnergia && Object.entries(dadosEnergia)?.length)
      setCarregando(true);

    if (listaDistribuidora?.length && dadosEnergia?.distribuidoraId)
      setDistribuidoraId(dadosEnergia?.distribuidoraId);

    if (dadosEnergia?.nomeFantasia) setNome(dadosEnergia?.nomeFantasia);

    setTimeout(() => {
      setCarregando(false);
    }, 2000);
  }, [dadosEnergia, listaDistribuidora]);

  return (
    <Loader loading={carregando}>
      <form
        className="needs-validation"
        onSubmit={handleSubmit(aoEnviarFormulario)}
      >
        <Card className={classes.container}>
          <Grid
            container
            spacing={3}
            pr={6}
            justifyContent="space-around"
            className={classes.container}
          >
            <Grid item className={`font-weight-bold ${classes.tituloSecao}`}>
              Dados de energia
            </Grid>
            <Grid item className="ml-auto pl-0">
              <Botao
                type="submit"
                label="Tirar foto"
                bgColor={Base.White}
                icon={<PhotoCameraOutlinedIcon />}
                className={classes.buttonCamera}
                onClick={() => onClickTirarFoto()}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            pr={6}
            className={`${classes.container} pt-0`}
          >
            <Grid item>
              Clique no botão para tirar uma foto da fatura e autopreencher ou
              preencha abaixo:
            </Grid>
          </Grid>
          <Grid container spacing={3} pr={6} className={classes.container}>
            <Grid item lg={6} md={6} sm={8}>
              <MaterialInputMascara
                type="text"
                id="cnpj"
                name="cnpj"
                label="CNPJ"
                mask="##.###.###/####-##"
                defaultValue={dadosEnergia?.cnpj?.replace(/[^a-zA-Z0-9]/g, "")}
                renderIconShowHide={false}
                onBlur={(valor) => verificarCnpj(valor)}
                ref={register({
                  required: "Campo CNPJ é obrigatório!",
                  maxLength: {
                    value: 18,
                    message: "Quantidade máxima de 18 caracteres!"
                  },
                  minLength: {
                    value: 11,
                    message: "Quantidade mínima de 11 caracteres!"
                  },
                  validate: (value) => cnpj.isValid(value) || "CNPJ inválido!"
                })}
                errors={errors}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={8}>
              <Loader loading={carregandoNome}>
                <MaterialInputTexto
                  type="text"
                  id="nomeFantasia"
                  name="nomeFantasia"
                  label="Nome"
                  renderIconShowHide={false}
                  defaultValue={nome}
                  ref={register({
                    required: "Campo Nome é obrigatório!",
                    maxLength: {
                      value: 100,
                      message: "Quantidade máxima de 100 caracteres!"
                    }
                  })}
                  errors={errors}
                />
              </Loader>
            </Grid>
          </Grid>
          <Grid container spacing={3} pr={6} className={classes.container}>
            <Grid item lg={4} md={4} sm={8}>
              <Loader loading={carregandoDistribuidora}>
                <SelectAutoComplete
                  disableClearable
                  disableOpenOnFocus
                  id="distribuidoraId"
                  name="distribuidoraId"
                  options={listaDistribuidora}
                  label="Distribuidora"
                  getOptionLabel={(option) => option?.nomeFantasia ?? ""}
                  onChange={(e, item) => {
                    setDistribuidoraId(item?.id ?? 0);
                  }}
                  value={distribuidoraId}
                  defaultValue={dadosEnergia?.distribuidoraId}
                  ref={register(
                    { name: "distribuidoraId" },
                    {
                      required: !distribuidoraId
                    }
                  )}
                  error={errors}
                  allowClear
                  valueName="nomeFantasia"
                />
              </Loader>
            </Grid>
            <Grid item lg={4} md={4} sm={8}>
              <MaterialInputMascara
                type="text"
                id="demandaContratadaKW"
                name="demandaContratadaKW"
                label="Demanda contratada (kW)"
                sufixo="kW"
                defaultValue={formataValor(dadosEnergia?.demandaContratadaKW)}
                renderIconShowHide={false}
                ref={register({
                  required: "Campo Demanda contratada é obrigatório!",
                  maxLength: {
                    value: 18,
                    message: "Quantidade máxima de 18 caracteres!"
                  },
                  minLength: {
                    value: 1,
                    message: "Quantidade mínima de 1 caracteres!"
                  }
                })}
                errors={errors}
              />
            </Grid>
            <Grid item lg={4} md={4} sm={8}>
              <MaterialInputMascara
                type="text"
                id="valorMedio"
                name="valorMedio"
                label="Fatura (R$)"
                prefixo="R$"
                defaultValue={formataValor(dadosEnergia?.valorMedio)}
                renderIconShowHide={false}
                ref={register({
                  required: "Campo Consumo médio é obrigatório!",
                  maxLength: {
                    value: 18,
                    message: "Quantidade máxima de 18 caracteres!"
                  },
                  minLength: {
                    value: 1,
                    message: "Quantidade mínima de 1 caracteres!"
                  }
                })}
                errors={errors}
              />
            </Grid>
          </Grid>
          {mostrarHistorico && objetoDadosHistorico?.length ? (
            <Grid container spacing={3} pr={6} className={classes.container}>
              <Grid item lg={12} md={12} className="pt-4">
                <Divider />
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                className={`font-weight-bold ${classes.tituloSecao}`}
              >
                Histórico de consumo
              </Grid>
              <Grid item lg={12} md={12}>
                <DataTable
                  columns={colunas}
                  noHeader
                  persistTableHead
                  keyField="id"
                  data={objetoDadosHistorico}
                  theme="DeltaEnergiaDemo"
                  customStyles={customStyles}
                  noDataComponent={
                    <TabelaSemDados>Sem dados de histórico</TabelaSemDados>
                  }
                />
              </Grid>
            </Grid>
          ) : null}
          <Grid
            container
            spacing={3}
            pr={6}
            className={classes.containerAvancar}
          >
            <Grid item lg={3} md={3} sm={12}>
              <Botao
                type="submit"
                label="Ver planos"
                className={classes.button}
              />
            </Grid>
          </Grid>
        </Card>
      </form>
    </Loader>
  );
};

export default DadosEnergia;
