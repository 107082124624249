import { makeStyles } from "@mui/styles";
import styled from "styled-components";
import theme from "themes";

export const useStyles = makeStyles({
  fundoCelula: {
    width: "80%",
    height: "100%",
    borderRadius: "4px",
    textAlign: "center"
  },
  fundoBotoes: {
    backgroundColor: theme.color.bgTabela
  },
  botaoCentral: {
    borderLeft: `3px ${theme.color.secondaryBackgroud} solid`
  },
  botao: {
    color: `${theme.color.secondaryText} !important`,
    width: "100%",
    height: "100%",
    justifyContent: "center",
    justifyItems: "center",
    alignContent: "center",
    alignItems: "center"
  },
  inativo: {
    opacity: 0.5
  },
  ativo: {
    opacity: 1
  }
});

export const Celula = styled.div`
  padding: 10px;
  background-color: ${(props) =>
    props.cor ? props.cor : theme.color.bgTabela};
  width: 100%;
  text-align: center;
  color: ${(props) =>
    props.cor === "#FFFFFF"
      ? `${theme.color.secondaryText} !important`
      : "#FFFFFF"};
`;
