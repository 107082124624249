/* eslint-disable no-underscore-dangle */
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { generate } from "shortid";

import FileSaver from "file-saver";

import {
  EditOutlined,
  Search,
  CheckBox,
  CheckBoxOutlineBlank
} from "@mui/icons-material";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import { Box, Button, Grid } from "@mui/material";

import { InterfaceDTO } from "global/dto/interfacesDto";
import { RotasDTO } from "global/rotas/rotasUrlDto";
import { useDispatch, useSelector } from "react-redux";
import { usuarioPossuiFuncionalidade } from "servicos/funcionalidadesServico";

import fontkit from "@pdf-lib/fontkit";
import getDomain from "global/getDomain";
import { handleLoading } from "global/redux/modulos/loader/actions";
import html2canvas from "html2canvas";
import CardPlano from "paginas/Cotacao/Planos/componentes/cardPlano";
import PlanosConstante from "paginas/Cotacao/Planos/constantes/planosConstante";
import { PDFDocument } from "pdf-lib";
import ReactApexChart from "react-apexcharts";
import {
  formataMoedaReais,
  formataPercentual,
  formatarValor,
  formataTelefone
} from "servicos/utils";
import theme from "themes";
import { HtmlTooltip } from "../../../../Graficos/ConsumoAtual/style";

import enumeradorBotao from "../../enumeradores/enumeradorBotao";
import ComponenteMultiBotoesHelper from "./helper";

import { useStyles } from "./style";

const ColunaMultiplosBotoes = ({ onClick, parametros }) => {
  const usuario = useSelector((state) => state.usuario);
  const rotas = useSelector((state) => state.rotas);
  const dispatch = useDispatch();
  const { menu } = usuario;
  const permissaoContratarCotacao = usuarioPossuiFuncionalidade(
    menu,
    rotas.rotaAtual.caminho,
    RotasDTO.Cotacoes,
    InterfaceDTO.PermissaoContratarCotacao
  );
  const [dadosPdf, setDadosPdf] = useState();
  const [economiaTotal, setEconomiaTotal] = useState(0);
  const [printing, setPrinting] = useState(false);

  // eslint-disable-next-line no-undef
  const classes = useStyles();
  const onClickEdicao = () => onClick(enumeradorBotao.Edicao, parametros);

  const onClickAbrirModal = () => onClick(enumeradorBotao.Modal, parametros);
  const onClickParceiro = () => onClick(enumeradorBotao.Parceiro, parametros);

  const botaoEditarDesabilitado = parametros?.status === "Anulada";

  // eslint-disable-next-line no-unused-vars, consistent-return
  const gerarPdf = async () => {
    dispatch(handleLoading(true));

    const {
      sucesso,
      mensagem,
      data
    } = await ComponenteMultiBotoesHelper.obterDadosRelatorio(parametros?.id);

    if (!sucesso) {
      dispatch(handleLoading(false));
      return ComponenteMultiBotoesHelper.exibirErro(mensagem);
    }

    setDadosPdf(data);
    setPrinting(true);

    // eslint-disable-next-line no-use-before-define
    baixarPdf(data);
  };

  const baixarPdf = async (dados) => {
    setTimeout(async () => {
      const pdfs = [];

      // eslint-disable-next-line no-use-before-define
      const pdfPrimeiraPagina = await gerarPdfPrimeiraPagina(dados);
      pdfs.push(pdfPrimeiraPagina);

      const planoSelecionado = dados?.dadosPlanos.find(
        (d) => d?.cotacaoInicialPlanosGerados?.cotacaoSelecionada
      );

      if (planoSelecionado) {
        setEconomiaTotal(
          planoSelecionado?.cotacaoInicialPlanosGerados?.economiaTotal
        );
        // eslint-disable-next-line no-use-before-define
        const pdfSegundaPagina = await gerarPdfSegundaPagina(
          dados,
          planoSelecionado
        );
        pdfs.push(pdfSegundaPagina);
      }

      // eslint-disable-next-line no-use-before-define
      mergePdfs(pdfs);
    }, 500);
  };

  const dadosGraficoCativoVsLivre = useMemo(
    () => ({
      options: {
        chart: {
          id: "basic-bar",
          animations: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        fill: {
          opacity: theme.color.opacityGrafico
        },
        grid: {
          show: true
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [0],
          formatter(val, { dataPointIndex }) {
            const dif =
              val - dadosPdf?.cativoVsLivre?.economiaLivre[dataPointIndex];
            return `R$ ${formatarValor(dif)}`;
          },
          style: {
            fontSize: "30px",
            fontFamily: theme.font.family,
            fontWeight: "bold",
            colors: ["#29b136"],
            marging: 20
          },
          // background: {
          //   enabled: true,
          //   foreColor: '#fff',
          //   borderWidth: 10
          // },
          offsetY: -50,
          offsetX: 30
        },
        xaxis: {
          categories: dadosPdf?.cativoVsLivre?.listaAnos,
          labels: {
            show: true,
            style: {
              fontSize: "32px",
              fontFamily: theme.font.family,
              fontWeight: "bold",
              colors: ["#000"]
            }
          }
        },
        yaxis: {
          labels: {
            show: true,
            style: {
              colors: ["#000"],
              fontSize: "20px",
              fontFamily: theme.font.family,
              fontWeight: "bold"
            },
            formatter(value) {
              return `R$ ${formatarValor(value)}`;
            }
          },
          forceNiceScale: true
        },
        tooltip: {
          enabled: false
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: "top" // top, center, bottom
              // orientation: 'vertical'
            }
          }
        },
        // title: {
        //   text: 'Projeção de Economia',
        //   align: 'center',
        //   margin: 0,
        //   offsetX: 0,
        //   offsetY: 0,
        //   floating: false,
        //   style: {
        //     fontSize:  '46px',
        //     fontWeight:  'bold',
        //     fontFamily:  'Helvetica',
        //     color:  '#000'
        //   },
        // },
        legend: {
          show: false,
          showForSingleSeries: false,
          showForNullSeries: true,
          showForZeroSeries: true,
          position: "bottom",
          horizontalAlign: "center",
          floating: true,
          fontSize: "14px",
          fontFamily: theme.font.family,
          fontWeight: 400,
          formatter: undefined,
          inverseOrder: false,
          width: undefined,
          height: undefined,
          tooltipHoverFormatter: undefined,
          customLegendItems: [],
          offsetX: 0,
          offsetY: 0,
          labels: {
            // colors: ['#fff'],
            useSeriesColors: true
          },
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: "#fff",
            fillColors: undefined,
            radius: 12,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0
          },
          itemMargin: {
            horizontal: 5,
            vertical: 0
          },
          onItemClick: {
            toggleDataSeries: true
          },
          onItemHover: {
            highlightDataSeries: true
          }
        }
      },
      optionsCativoVsLivreTotal: {
        chart: {
          id: "basic-bar",
          animations: {
            enabled: false
          },
          // background: '#f0f0f0',
          toolbar: {
            show: false
          }
        },
        fill: {
          opacity: theme.color.opacityGrafico
        },
        grid: {
          show: false
        },
        dataLabels: {
          enabled: true,
          // enabledOnSeries: [0],
          formatter(val) {
            // const dif = val - dadosPdf?.cativoVsLivre?.economiaTotalLivre[dataPointIndex];
            // return formatarValor(val);
            return `R$ ${formatarValor(val)}`;
          },
          style: {
            fontSize: "30px",
            fontFamily: theme.font.family,
            fontWeight: "bold",
            // colors: ['#29b136'],
            colors: ["#000"],
            marging: 20
          }
          // background: {
          //   enabled: true,
          //   foreColor: '#fff',
          //   borderWidth: 10
          // },
          // offsetY: -50,
          // offsetX: 80
        },
        xaxis: {
          categories: ["Total"],
          labels: {
            show: true,
            style: {
              fontSize: "32px",
              fontFamily: theme.font.family,
              fontWeight: "bold",
              colors: ["#000"]
            }
          }
        },
        yaxis: {
          opposite: true,
          labels: {
            show: false,
            style: {
              colors: ["#000"],
              fontSize: "20px",
              fontFamily: theme.font.family,
              fontWeight: "bold"
            },
            formatter(value) {
              return `R$ ${formatarValor(value)}`;
            }
          },
          forceNiceScale: true
        },
        tooltip: {
          enabled: false
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: "top" // top, center, bottom
            }
          }
        },
        legend: {
          show: false,
          showForSingleSeries: false,
          showForNullSeries: true,
          showForZeroSeries: true,
          position: "bottom",
          horizontalAlign: "center",
          floating: true,
          fontSize: "24px",
          fontFamily: theme.font.family,
          fontWeight: 400,
          inverseOrder: false,
          width: undefined,
          height: undefined,
          tooltipHoverFormatter: undefined,
          customLegendItems: [],
          offsetX: 0,
          offsetY: 0,
          labels: {
            // colors: ['#000'],
            useSeriesColors: true
          },
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: undefined,
            fillColors: undefined,
            radius: 12,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0
          },
          itemMargin: {
            horizontal: 5,
            vertical: 0
          },
          onItemClick: {
            toggleDataSeries: false
          },
          onItemHover: {
            highlightDataSeries: false
          }
        },
        annotations: {
          position: "front",
          yaxis: [
            {
              // x: 'Mercado Livre',
              // yAxisIndex: ,
              y: dadosPdf?.cativoVsLivre?.economiaTotalCativo[0],
              // offsetX: -500,
              offsetY: 10000,
              width: "100%",
              strokeDashArray: 0,
              textAnchor: "end",
              label: {
                borderWidth: 0,
                borderRadius: 0,
                offsetX: -150,
                offsetY: -10,
                style: {
                  color: "#2ec408",
                  fontSize: "32px",
                  fontWeight: "bold"
                },
                text: `R$ ${formatarValor(
                  dadosPdf?.cativoVsLivre?.economiaTotal
                )}`
              }
            }
          ]
        }
      },
      series: [
        {
          name: "Cativo",
          color: theme.color.Cativo,
          data: dadosPdf?.cativoVsLivre?.economiaCativo
        },
        {
          name: "Mercado Livre",
          color: theme.color.Livre,
          data: dadosPdf?.cativoVsLivre?.economiaLivre
        }
      ],
      seriesCativoVsLivreTotal: [
        {
          name: "Cativo",
          color: theme.color.Cativo,
          data: dadosPdf?.cativoVsLivre?.economiaTotalCativo
        },
        {
          name: "Mercado Livre",
          color: theme.color.Livre,
          data: dadosPdf?.cativoVsLivre?.economiaTotalLivre
        }
      ]
    }),
    [economiaTotal]
  );

  const GraficoRenderCativoVsLivre = () => {
    return (
      <ReactApexChart
        options={dadosGraficoCativoVsLivre?.options}
        series={dadosGraficoCativoVsLivre?.series}
        width={1100}
        height={800}
        type="bar"
      />
    );
  };

  const GraficoRenderCativoVsLivreTotal = () => {
    return (
      <ReactApexChart
        options={dadosGraficoCativoVsLivre?.optionsCativoVsLivreTotal}
        series={dadosGraficoCativoVsLivre?.seriesCativoVsLivreTotal}
        width={500}
        height={800}
        type="bar"
      />
    );
  };

  const Grafico = () => {
    return (
      <Box
        style={{ position: "fixed", marginTop: "-3200px", right: "50%" }}
        id="chart"
      >
        <Grid container className="h-auto" style={{ width: 1600 }}>
          <Grid item md={8} sm={8}>
            <GraficoRenderCativoVsLivre />
          </Grid>
          <Grid item md={2} sm={2}>
            <GraficoRenderCativoVsLivreTotal />
          </Grid>
        </Grid>
      </Box>
    );
  };

  const planos = useMemo(() => {
    if (!dadosPdf?.dadosPlanos || dadosPdf?.dadosPlanos.length === 0) return [];
    return PlanosConstante.filter((plano) => {
      return dadosPdf?.dadosPlanos.some(
        (dado) => dado?.tipoPlanoCotacao?.tipoPlano === plano.tipoPlano
      );
    });
  }, [dadosPdf]);

  const dominio = `${getDomain()}`;

  const Planos = () => {
    return (
      <Grid
        container
        style={{
          position: "fixed",
          marginTop: "-3200px",
          right: "10%",
          width: 1300,
          zIndex: 10000
        }}
        lg={12}
        md={12}
        justifyContent="space-around"
        id="print"
      >
        {dadosPdf?.dadosPlanos.length > 0 ? (
          dadosPdf?.dadosPlanos?.map((plano) => (
            <Grid item lg={4} md={4} sm={12} xs={12} className="h-auto">
              <CardPlano
                planoConfiguracao={planos?.find(
                  (p) => p.tipoPlano === plano?.tipoPlanoCotacao?.tipoPlano
                )}
                dadosCards={plano?.cotacaoInicialPlanosGerados}
                periodoMeses={plano?.tipoPlanoCotacao?.periodoMeses}
                planoSelecionado={
                  plano?.cotacaoInicialPlanosGerados?.cotacaoSelecionada
                }
                print
                dominio={`${dominio}`}
              />
            </Grid>
          ))
        ) : (
          <Grid item xs={12} className={classes.semPlanos}>
            SEM PLANOS DISPONÍVEIS
          </Grid>
        )}
      </Grid>
    );
  };

  const obterFonts = async (pdf) => {
    pdf.registerFontkit(fontkit);

    const urlPlau = "themes/default/assets/fonts/plau.otf";
    const plauFontBytes = await fetch(urlPlau).then((res) => res.arrayBuffer());
    const plauFont = await pdf.embedFont(plauFontBytes);

    const urlPlauBold = "themes/default/assets/fonts/plau_bold.otf";
    const plauBoldFontBytes = await fetch(urlPlauBold).then((res) =>
      res.arrayBuffer()
    );
    const plauBoldFont = await pdf.embedFont(plauBoldFontBytes);

    const urlCenturyGothic = "themes/default/assets/fonts/centuryGothic.ttf";
    const centuryGothicFontBytes = await fetch(urlCenturyGothic).then((res) =>
      res.arrayBuffer()
    );
    const centuryGothicFont = await pdf.embedFont(centuryGothicFontBytes);

    const urlCenturyGothicBold =
      "themes/default/assets/fonts/centuryGothic_bold.ttf";
    const centuryGothicBoldFontBytes = await fetch(
      urlCenturyGothicBold
    ).then((res) => res.arrayBuffer());
    const centuryGothicBoldFont = await pdf.embedFont(
      centuryGothicBoldFontBytes
    );

    const font = getDomain() !== "luz" ? centuryGothicFont : plauFont;
    const fontBold =
      getDomain() !== "luz" ? centuryGothicBoldFont : plauBoldFont;

    return { font, fontBold };
  };

  const definirConfiguracoesFormulario = (form) => {
    form.getFields().forEach((field) => {
      const nameField = field.getName();
      const textField = form.getTextField(nameField);
      textField.setMaxLength(undefined);
    });
  };

  const gerarPdfPrimeiraPagina = async (dados) => {
    const planosPrint = document.querySelector("#print");

    const canvasPlanos = await html2canvas(planosPrint, {
      height: 800,
      scale: 2
    });

    const imgPlanosData = canvasPlanos.toDataURL("image/png");

    const imgPlanosBase64 = imgPlanosData
      .toString()
      .replace("data:image/png;base64,", "");

    // eslint-disable-next-line no-use-before-define
    const imagePlanosBytes = _base64ToArrayBuffer(imgPlanosBase64);

    const formPrimeiraPaginaPdfBytes = await fetch(
      `themes/${getDomain()}/assets/cotacoes/${
        dados?.dadosCotacao?.tipoCalculo === 2
          ? "pdf_planos_financeiro.pdf"
          : "pdf_planos.pdf"
      }`
    ).then((res) => res.arrayBuffer());

    const pdfPrimeiraPagina = await PDFDocument.load(
      formPrimeiraPaginaPdfBytes
    );

    const formPrimeiraPagina = pdfPrimeiraPagina.getForm();

    definirConfiguracoesFormulario(formPrimeiraPagina);

    const planosImage = await pdfPrimeiraPagina.embedPng(imagePlanosBytes);

    const fonts = await obterFonts(pdfPrimeiraPagina);
    const { font, fontBold } = fonts;

    const clienteField = formPrimeiraPagina.getTextField("cliente");
    const nomeField = formPrimeiraPagina.getTextField("nome");
    const distribuidoraField = formPrimeiraPagina.getTextField("distribuidora");
    const cnpjField = formPrimeiraPagina.getTextField("cnpj");
    const emailField = formPrimeiraPagina.getTextField("email");
    const demandaField = formPrimeiraPagina.getTextField("demanda");
    const telefoneField = formPrimeiraPagina.getTextField("telefone");
    const consumoField = formPrimeiraPagina.getTextField("consumo");
    const dataField = formPrimeiraPagina.getTextField("data");
    const planosField = formPrimeiraPagina.getTextField("planos");
    const validadeField = formPrimeiraPagina.getTextField("validade");
    const officerField = formPrimeiraPagina.getTextField("officer");

    planosField.setImage(planosImage);

    clienteField.setText(dados?.dadosCliente?.nomeFantasia);
    clienteField.updateAppearances(fontBold);

    nomeField.setText(dados?.dadosCliente?.nomeContatoInicial);
    nomeField.updateAppearances(fontBold);

    distribuidoraField.setText(dados?.distribuidoraDescricao);
    distribuidoraField.updateAppearances(fontBold);

    cnpjField.setText(dados?.dadosCliente?.cnpjCpf);
    cnpjField.updateAppearances(fontBold);

    emailField.setText(dados?.dadosCliente?.emailContatoInicial);
    emailField.updateAppearances(fontBold);

    demandaField.setText(`${dados?.dadosCotacao?.demandaContratada}kW`);
    demandaField.updateAppearances(fontBold);

    const telefoneFormatado = formataTelefone(dados?.dadosCliente?.telefone);
    telefoneField.setText(telefoneFormatado ?? dados?.dadosCliente?.telefone);
    telefoneField.updateAppearances(fontBold);

    const consumo =
      dados?.dadosCotacao?.tipoCalculo === 2
        ? formataMoedaReais(dados?.dadosCotacao?.valorMedioConta)
        : `${dados?.dadosCotacao?.consumoMedioConta}MWh`;

    consumoField.setText(consumo.replace(/\s/g, ""));
    consumoField.updateAppearances(fontBold);

    dataField.setText(dados?.dataCotacao);
    dataField.updateAppearances(fontBold);

    validadeField.setText(`18h do dia ${dados?.dataValidade}`);
    validadeField.updateAppearances(fontBold);

    officerField.setText(
      [
        `${dados?.dadosColaborador?.nome}`,
        `${dados?.dadosColaborador?.email}`,
        dados?.dadosColaborador?.telefone
          ? `Cel.: ${formataTelefone(dados?.dadosColaborador?.telefone)}`
          : ""
      ].join("\n")
    );
    officerField.updateAppearances(font);

    const pdfPrimeiroBytes = await pdfPrimeiraPagina.save();

    return pdfPrimeiroBytes;
  };

  const gerarPdfSegundaPagina = async (dados, planoSelecionado) => {
    const chartPrint = document.querySelector("#chart");

    const canvas = await html2canvas(chartPrint);

    const imgData = canvas.toDataURL("image/png");

    const imageBase64 = imgData
      .toString()
      .replace("data:image/png;base64,", "");

    // eslint-disable-next-line no-use-before-define
    const imageBytes = _base64ToArrayBuffer(imageBase64);

    const formSegundaPaginaPdfBytes = await fetch(
      `themes/${getDomain()}/assets/cotacoes/pdf_economia.pdf`
    ).then((res) => res.arrayBuffer());

    const pdfSegundaPagina = await PDFDocument.load(formSegundaPaginaPdfBytes);

    const formSegundaPagina = pdfSegundaPagina.getForm();

    definirConfiguracoesFormulario(formSegundaPagina);

    const fonts = await obterFonts(pdfSegundaPagina);
    const { font, fontBold } = fonts;

    const graficoImage = await pdfSegundaPagina.embedPng(imageBytes);

    const graficoField = formSegundaPagina.getTextField("grafico");
    const descontoField = formSegundaPagina.getTextField("desconto");
    const officerField = formSegundaPagina.getTextField("officerEconomia");

    graficoField.setImage(graficoImage);

    const percentualDesconto = `${formataPercentual(
      planoSelecionado?.cotacaoInicialPlanosGerados?.percentualDesconto
    )}`;
    descontoField.setText(percentualDesconto);
    descontoField.updateAppearances(fontBold);

    officerField.setText(
      [
        `${dados?.dadosColaborador?.nome}`,
        `${dados?.dadosColaborador?.email}`,
        dados?.dadosColaborador?.telefone
          ? `Cel.: ${formataTelefone(dados?.dadosColaborador?.telefone)}`
          : ""
      ].join("\n")
    );
    officerField.updateAppearances(font);

    const pdfSegundoBytes = await pdfSegundaPagina.save();

    return pdfSegundoBytes;
  };

  const mergePdfs = async (pdfsToMerge) => {
    const mergedPdf = await PDFDocument.create();

    // eslint-disable-next-line no-restricted-syntax
    for (const pdfCopyDoc of pdfsToMerge) {
      // eslint-disable-next-line no-await-in-loop
      const pdfDoc = await PDFDocument.load(pdfCopyDoc);

      // eslint-disable-next-line no-await-in-loop
      const copiedPages = await mergedPdf.copyPages(
        pdfDoc,
        pdfDoc.getPageIndices()
      );

      copiedPages.forEach((page) => {
        mergedPdf.addPage(page);
      });
    }

    const pdfBytes = await mergedPdf.save();

    const blob = new Blob([pdfBytes], { type: "application/pdf" });

    dispatch(handleLoading(false));
    setPrinting(false);

    FileSaver.saveAs(blob, `Relatorio de Cotação ${parametros.id}`);
  };

  const _base64ToArrayBuffer = (base64) => {
    // eslint-disable-next-line camelcase
    const binary_string = window.atob(base64);
    // eslint-disable-next-line camelcase
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < len; i++) {
      // eslint-disable-next-line camelcase
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  };

  return (
    <>
      <Grid container spacing={0} className={`${classes.fundoCelula}`}>
        <Grid
          item
          xs={3}
          className={`${classes.fundoBotoes} ${
            permissaoContratarCotacao ? classes.botaoCentral : null
          }`}
        >
          <Button
            key={generate()}
            onClick={onClickEdicao}
            className={`${classes.botao} ${
              botaoEditarDesabilitado ? classes.inativo : classes.ativo
            }`}
          >
            <HtmlTooltip
              title={parametros.status === "Fechada" ? "Pesquisar" : "Editar"}
            >
              {parametros.status === "Fechada" ? <Search /> : <EditOutlined />}
            </HtmlTooltip>
          </Button>
        </Grid>
        <Grid item xs={3} className={`${classes.fundoBotoes} `}>
          <Button
            key={generate()}
            onClick={onClickAbrirModal}
            className={classes.botao}
          >
            <HtmlTooltip title="Preços">
              <RequestQuoteIcon />
            </HtmlTooltip>
          </Button>
        </Grid>

        {parametros?.usuarioCargoId !== enumeradorBotao.Parceiro &&
          parametros?.cargoId === enumeradorBotao.Parceiro && (
            <Grid item xs={3} className={classes.fundoBotoes}>
              <Button
                key={generate()}
                onClick={onClickParceiro}
                className={classes.botao}
              >
                <HtmlTooltip
                  title={`${
                    parametros?.liberaPropostaContrato === "S"
                      ? "Bloquear"
                      : "Liberar"
                  } Proposta e Contrato`}
                >
                  {parametros?.liberaPropostaContrato === "S" ? (
                    <CheckBox />
                  ) : (
                    <CheckBoxOutlineBlank />
                  )}
                </HtmlTooltip>
              </Button>
            </Grid>
          )}

        {dadosPdf && printing ? (
          <>
            <Planos />
            {economiaTotal && printing ? <Grafico /> : null}
          </>
        ) : null}
      </Grid>
    </>
  );
};

ColunaMultiplosBotoes.propTypes = {
  onClick: PropTypes.oneOfType([PropTypes.func]).isRequired,
  parametros: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired
};

export default ColunaMultiplosBotoes;
