import React from "react";
import { Grid } from "@mui/material";
import { useStyles } from "./style";

const CartaDenuncia = () => {
  const classes = useStyles();

  return (
    <Grid className={classes.containerContent}>
      <h5>
        <p>
          Enviamos suas carta denúncia para a distribuidora, estamos aguardando
          o retorno do documento.
        </p>
      </h5>
    </Grid>
  );
};

export default CartaDenuncia;
