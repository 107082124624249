/* eslint-disable import/no-unresolved */
import MICROSERVICO from "global/enumeradores/microServico";
import obterApi from "servicos/api";

const api = () => obterApi(MICROSERVICO.PROJECAOTARIFARIA);

export const getParametrosGlobais = async() => {
    return api().get("v1/ConfigGlobal/visualizar");
}

export const updateParametrosGlobais = async (data) => {
    return api().put("v1/ConfigGlobal/update", data);
}