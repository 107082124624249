import MICROSERVICO from "global/enumeradores/microServico";
import obterApi from "servicos/api";

const api = () => obterApi(MICROSERVICO.PROJECAOTARIFARIA);

export const uploadRedeBasica = async (arquivo, idUsuario, nomeUsuario) => {
    const data = new FormData();
    data.append("file", arquivo);
  
    const metodo = "post";
    const url = `v1/ImportRedeBasica/importa?idUsuario=${idUsuario}&nomeUsuario=${nomeUsuario}`;
    return api()[metodo](url, data);
};

export const valoresRedeBasica = async(inicio, fim) => {
    return api().get(`v1/ImportRedeBasica/visualiza?anoinicial=${inicio}&anofinal=${fim}`);
}