/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React from "react";
import { Grid } from "@mui/material";
import { AccountBoxOutlined } from "@mui/icons-material";
import { mdiCircleSlice6 } from "@mdi/js";
import Icon from "@mdi/react";
import { Botao } from "componentes";
import BotaoOutLine from "componentes/botaoOutline";
import { useStyles } from "./style";

const EsqueletoEtapa = ({
  children,
  prazo,
  responsavel,
  titulo,
  proximoDisabled,
  onClickProximo,
  shadowHeader,
  shadowFooter,
  esconderBotaoProximo,
  labelProximo,
  labelVoltar,
  onClickVoltar,
  esconderBotaoVoltar
}) => {
  const classes = useStyles({ shadowHeader, shadowFooter });

  return (
    <div className={classes.imagemFundo}>
      <Grid container className={classes.header}>
        <Grid item xs={12}>
          <Grid container>
            <Grid
              item
              xl={10}
              lg={8}
              md={8}
              sm={8}
              xs={12}
              className={classes.containerTitulo}
            >
              <h2 className={classes.titulo}>{titulo}</h2>
            </Grid>
            <Grid xl={1} lg={2} md={2} sm={2} xs={6}>
              <Grid container>
                <Grid
                  item
                  lg={2}
                  md={3}
                  sm={4}
                  xs={2}
                  className={classes.containerIcone}
                >
                  <AccountBoxOutlined className={classes.icone} />
                </Grid>
                <Grid item lg={10} md={9} sm={8} xs={8}>
                  <Grid container>
                    <Grid item xs={12}>
                      <label className={`${classes.labelMenor}`}>
                        Responsável
                      </label>
                    </Grid>
                    <Grid item xs={12}>
                      <label className={classes.labelMaior}>
                        {responsavel}
                      </label>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xl={1} lg={2} md={2} sm={2} xs={2}>
              <Grid container>
                <Grid
                  item
                  lg={2}
                  md={3}
                  sm={4}
                  xs={5}
                  className={classes.containerIcone}
                >
                  <Icon path={mdiCircleSlice6} className={classes.icone} />
                </Grid>
                <Grid lg={10} md={9} sm={8} xs={7}>
                  <Grid container>
                    <Grid item xs={12}>
                      <label className={classes.labelMenor}>Prazo</label>
                    </Grid>
                    <Grid item xs={12}>
                      <label className={classes.labelMaior}>{prazo}</label>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={classes.content}>
        {children}
      </Grid>

      <Grid container spacing={1} className={classes.footer}>
        {!esconderBotaoVoltar && (
          <Grid item xs={12} lg={4}>
            <BotaoOutLine
              label={labelVoltar ?? "Voltar"}
              className={classes.botaoOffWhite}
              disabled={proximoDisabled}
              onClick={() => onClickVoltar()}
            />
          </Grid>
        )}
        {!esconderBotaoProximo && (
          <Grid item xs={12} lg={4}>
            <Botao
              type="submit"
              label={labelProximo ?? "Próximo"}
              disabled={proximoDisabled}
              onClick={() => onClickProximo()}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default EsqueletoEtapa;
