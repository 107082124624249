import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const api = () => obterApi(MICROSERVICO.MANAGEMENT);

// Buscar Distribuidora por CNPJ ou nome fantasi
export const BuscarPorCnpjOuNomeFantasia = async (dado) => {
  return api().get(`v1/EmpresaGrupoDelta/exist/${dado}`);
};

export const listarEmpresasGrupoDelta = async (
  ordemSelecionada,
  filtroSelecionado,
  paginaAtual = 0
) => {
  const urlParams = `?${
    ordemSelecionada ? `&sortOrder=${ordemSelecionada}` : ""
  }${
    filtroSelecionado?.length !== 0 ? `&filter=${filtroSelecionado}` : ""
  }&pagina=${paginaAtual}&tamanhoPaginacao=${20}`;

  return api().get(`v1/EmpresaGrupoDelta${urlParams}`);
};

export const buscarPorId = async (id) => {
  return api().get(`v1/EmpresaGrupoDelta/${id}`);
};

export const deletarEmpresaGrupoDelta = async (id, ativo) => {
  const situacao = ativo ? "AT" : "IN";
  return api().patch(`v1/EmpresaGrupoDelta/${id}/situacao/${situacao}`);
};

export const listarOrganizarPor = () => {
  const ordernacoes = [
    {
      key: "0",
      label: "Alfabética"
    },
    {
      key: "3",
      label: "Recentes"
    }
  ];

  return ordernacoes;
};

export const listarFiltrarPor = () => {
  const filtros = [
    {
      key: "AT",
      label: "Ativo"
    },
    {
      key: "IN",
      label: "Inativo"
    }
  ];

  return filtros;
};

export const salvarEmpresaGrupoDelta = async (id, dados) => {
  let metodo = "post";
  let url = "v1/EmpresaGrupoDelta/";
  if (id > 0) {
    metodo = "put";
    dados.id = id;
    url = `${url}`;
  }
  return api()[metodo](url, dados);
};

export const listarEmpresasGrupoDeltaCombo = async (token) => {
  return api().get(
    "v1/EmpresaGrupoDelta/ListarEmpresasGrupoDeltaPorIdUsuario",
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
};
