import React, { useMemo } from "react";
import PropTypes from "prop-types";
import ModalInformacao from "componentes/modalVerificacao";

const InputCheckBoxTermo = (props) => {
  const { name, errors, termoDescricao } = props;
  const isInvalid = useMemo(() => {
    return errors[name]?.message ?? false;
  }, [errors, name]);

  return (
    <>
      <ModalInformacao
        telaInteira
        exibir={false}
        titulo="Termo de uso e privacidade"
        mensagem={termoDescricao}
        labelBotao="OK"
        onClose={() => {}}
        showBotao={false}
        possuiHtml
      />
      <div className="form-group" style={{ marginTop: 15 }}>
        {isInvalid && <div className="invalid-feedback mb-3">{isInvalid}</div>}
      </div>
    </>
  );
};

InputCheckBoxTermo.propTypes = {
  name: PropTypes.string.isRequired,
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  termoDescricao: PropTypes.string,
};

InputCheckBoxTermo.defaultProps = {
  errors: {},
  termoDescricao: "",
};

export default InputCheckBoxTermo;
