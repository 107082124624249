import React from "react";
import PropTypes from "prop-types";
import { Wrapper } from "./style";

const Loader = ({ children, className, loading, dark }) => {
  return (
    <Wrapper
      loading={loading.toString() ?? false}
      className={`position-relative ${className}`}
    >
      <div
        className={`${
          loading
            ? `position-absolute spinner-border text-${
                dark ? "dark" : "warning"
              }`
            : ""
        }`}
        role="status"
      />
      <div className="children">{children}</div>
    </Wrapper>
  );
};

Loader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.any]),
  className: PropTypes.string,
  loading: PropTypes.bool,
  dark: PropTypes.bool
};

Loader.defaultProps = {
  children: () => {},
  className: "",
  loading: false,
  dark: true
};

export default Loader;
