import { StepConnector } from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";

import themes from "themes";
import { createTheme } from "@mui/material/styles";

const theme = createTheme();

export const useColorlibStepIconStyles = makeStyles({
  root: {
    background: (props) =>
      props?.vertical
        ? themes.color.rootVerticalBgColor
        : themes.color.rootBgColor,
    zIndex: 1,
    color: themes.color.rootColor,
    width: 54,
    height: 54,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: themes.font.family,
    fontSize: "24px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.53px"
  },
  active: {
    background: (props) =>
      props?.vertical
        ? themes.color.breadcrumbVerticalActiveBgColor
        : themes.color.breadcrumbActiveBgColor,
    color: themes.color.activeLabelColor
  },
  completed: {
    background: (props) =>
      props?.vertical
        ? themes.color.breadcrumbVerticalActiveBgColor
        : themes.color.breadcrumbActiveBgColor,
    color: themes.color.completeLabelColor
  }
});

export const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: "22px !important"
  },
  active: {
    "& $lineVertical": {
      borderImageSlice: 1,
      borderImageSource: themes.color.breadcrumbVerticalActiveBgColor,
      borderLeftColor: themes.color.breadcrumbVerticalActiveBgColor,
      color: themes.color.activeLabelColor
    },
    "& $lineHorizontal": {
      backgroundColor: themes.color.breadcrumbActiveBgColor,
      color: themes.color.activeLabelColor
    }
  },
  completed: {
    "& $lineVertical": {
      borderImageSlice: 1,
      borderImageSource: themes.color.breadcrumbVerticalCompleteBgColor,
      borderLeftColor: themes.color.breadcrumbVerticalCompleteBgColor,
      color: themes.color.completeLabelColor
    },
    "& $lineHorizontal": {
      backgroundColor: themes.color.breadcrumbCompleteBgColor,
      color: themes.color.completeLabelColor
    }
  },
  lineHorizontal: {
    height: 8,
    border: "0 !important",
    background: themes.color.breadcrumbBgColor,
    borderRadius: 4
  },
  lineVertical: {
    minHeight: 34,
    borderLeftStyle: "solid",
    borderLeftWidth: 5,
    borderLeftColor: themes.color.breadcrumbVerticalBgColor,
    marginLeft: 12
  },
  vertical: {
    padding: 0
  }
})(StepConnector);

export const useStyles = makeStyles(() => ({
  labelStep: {
    fontFamily: themes.font.family,
    fontSize: "16px",
    [theme.breakpoints.down("lg")]: {
      fontSize: "11px"
    },
    fontWeight: (props) => (props?.vertical ? "bold" : "normal"),
    fontStretch: "normal",
    fontStyle: " normal",
    lineHeight: "normal",
    letterSpacing: "-0.36px",
    textAlign: "center",
    color: (props) =>
      props?.vertical ? themes.color.labelVertical : themes.color.label
  },
  stepper: {
    backgroundColor: "transparent",
    background: "transparent",
    padding: 0,
    marginBottom: 20
  },
  lineVertical: {
    background: (props) =>
      props?.vertical
        ? themes.color.breadcrumbVerticalBgColor
        : themes.color.breadcrumbBgColor
  }
}));
