const anosDefault = [
  { nome: "2023", valor: 2023 },
  { nome: "2024", valor: 2024 },
  { nome: "2025", valor: 2025 },
  { nome: "2026", valor: 2026 },
  { nome: "2027", valor: 2027 },
  { nome: "2028", valor: 2028 },
  { nome: "2029", valor: 2029 },
  { nome: "2030", valor: 2030 },
  { nome: "2031", valor: 2031 },
  { nome: "2032", valor: 2032 },
  { nome: "2033", valor: 2033 },
  { nome: "2034", valor: 2034 },
  { nome: "2035", valor: 2035 },
  { nome: "2036", valor: 2036 },
  { nome: "2037", valor: 2037 },
  { nome: "2038", valor: 2038 }
];

export const anos = anosDefault.map((item, index) => {
  return {
    nome: new Date().getFullYear() + index,
    valor: new Date().getFullYear() + index
  };
});
