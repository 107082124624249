import React from "react";

import Rotas from "./global/rotas/rotas";

function App() {
  // Verifica se é url de retorno mais abaixo
  // Deve conter um parametro "email=true" para ser url de retorno
  const url = new URL(window.location.href);
  const parametros = new URLSearchParams(window.location.search);

  if (parametros.has("email") && parametros.get("email") === "true") {
    // Verifica se já existe url armazenada p/ evitar duplicidade
    const urlArmazenada = localStorage.getItem("url_retorno");
    if (urlArmazenada) {
      const parametrosUrlArmazenada = new URLSearchParams(urlArmazenada.search);
      if (!parametrosUrlArmazenada.has("email"))
        localStorage.removeItem("url_retorno");
    }

    parametros.delete("email");
    url.search = parametros.toString();
    localStorage.setItem("url_retorno", url.toString());
  }
  return <Rotas />;
}

export default App;
