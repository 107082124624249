import React, { useEffect, useRef, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useHistory } from "react-router-dom";

// Redux
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";

// Componentes
import { TabelaPaginada } from "componentes";
import MaterialInputBusca from "componentes/inputBusca";
import InputData from "componentes/inputTextoData";
import Coluna from "componentes/tabelaPaginada/colunas/coluna";
import ColunaComponentePersonalizado from "componentes/tabelaPaginada/colunas/colunaComponentePersonalizado";
import ResultadoPaginadoDto from "componentes/tabelaPaginada/resultadoPaginadoDto";
import UploadDropArea from "componentes/upload/index";
import { store } from "../../../../global/redux";
import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";

// Serviços
import { desabilitarFiltroLateral } from "../../../../global/redux/modulos/usuario/actions";
import ProcessamentoFaturaMLHelper from "./helper";

import ColunaMultiplosBotoes from "./colunaCustomizada";
import FiltroAdicionalTabela from "./filtroAdicionalTabela";

import { RotasDTO } from "../../../../global/rotas/rotasUrlDto";
import FaturaMLListaDto from "./faturaMLListaDto";
import FiltroDto from "./filtroDto";

import { useStyles } from "./style";

const ProcessamentoFaturaMLLista = () => {
  const classes = useStyles();
  const history = useHistory();
  const refTabela = useRef(null);
  const [progress, setProgress] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const [carregandoArquivo, setCarregandoArquivo] = useState(false);
  const [mesAno, setMesAno] = useState();
  const [carregamentoInicial, setCarregamentoInicial] = useState(false);
  const usuario = useSelector((state) => state.usuario);
  const {
    empresaSelecionada,
    clienteSelecionado,
    unidadeSelecionada
  } = usuario;
  const [ordemSelecionada] = useState();
  const [filtroAdicionalTabela, setFiltroAdicionalTabela] = useState(
    new FiltroAdicionalTabela()
  );
  const { register, control } = useForm({ reValidateMode: "onSubmit" });

  const salvarProcessamentoFaturaML = async (arquivo) => {
    setProgress(30);
    const upload = await ProcessamentoFaturaMLHelper.ImportarFaturaFourvision(
      arquivo
    );

    if (upload?.data.statusCodes === 200) {
      setProgress(60);

      const parametros = {};
      parametros.faturaId = upload?.data?.resultObject[0].externoId;
      parametros.mesReferencia = upload?.data?.resultObject[0].mesFormat.split(
        "/"
      )[0];
      parametros.anoReferencia = upload?.data?.resultObject[0].mesFormat.split(
        "/"
      )[1];
      parametros.numeroInstalacao =
        upload?.data?.resultObject[0].numeroInstalacao;
      parametros.consumoPonta =
        upload?.data?.resultObject[0].consumoPonta / 1000;
      parametros.consumoForaPonta =
        upload?.data?.resultObject[0].consumoForaPonta / 1000;
      parametros.demandaContratadaPonta =
        upload?.data?.resultObject[0].demandaPonta;
      parametros.demandaContratadaForaPonta =
        upload?.data?.resultObject[0].demandaForaPonta;
      parametros.pisCofins =
        upload?.data?.resultObject[0].pisPorcentagem +
        upload?.data?.resultObject[0].cofinsPorcentagem;
      parametros.cosip = upload?.data?.resultObject[0].cosip;

      parametros.demandaRegistradaForaPonta =
        upload?.data?.resultObject[0].demanda;
      parametros.icms = upload?.data?.resultObject[0].tributosICMSAliquota;

      // Campos pendentes no processamento
      // parametros.demandaRegistradaPonta = upload?.data?.resultObject[0].demandaPonta;
      // parametros.reativos = upload?.data?.resultObject[0].reativos;
      // parametros.ajustes = upload?.data?.resultObject[0].ajustes;

      await ProcessamentoFaturaMLHelper.AddProcessamentoFaturaML(parametros);
    }

    setProgress(100);

    const ocultarBarra = setInterval(() => {
      setShowLoader(false);
      setCarregandoArquivo(false);
      clearInterval(ocultarBarra);
    }, 3000);
  };

  const onAddUpload = async (valor) => {
    const arquivo = valor[0];

    if (arquivo) {
      store.dispatch(
        alertaExibir({
          tipo: "info",
          mensagem: `O arquivo ${arquivo.file.name} está processando...`
        })
      );

      setProgress(0);
      setCarregandoArquivo(true);
      setShowLoader(true);
      salvarProcessamentoFaturaML(arquivo);
    }
  };

  const onDropRejected = (valor) => {
    const arquivo = valor[0];
    store.dispatch(
      alertaExibir({
        tipo: "warning",
        mensagem: `Tipo do arquivo ${arquivo.name} não é permitido!`
      })
    );
  };

  const obterPreFaturamento = async (parametros) => {
    const filtro = new FiltroDto(
      parametros.totalPagina,
      parametros.pagina,
      parametros.busca,
      parametros.empresaId,
      parametros.clienteId,
      parametros.unidadeId,
      parametros.ano,
      parametros.mes
    );

    const resultado = await ProcessamentoFaturaMLHelper.ListarFaturados(filtro);

    if (resultado?.data?.statusCodes === 204)
      return new ResultadoPaginadoDto([], 1, 0, 0);

    const resLinhas = resultado?.data?.preFaturamento?.map((res) => {
      return new FaturaMLListaDto(
        res.unidadeId,
        res.unidadeConsumidora,
        res.mes,
        res.ano,
        res.mesReferencia,
        res.faturaProcessada
      );
    });

    return new ResultadoPaginadoDto(
      resLinhas,
      resultado.data.paginaAtual,
      resultado.data.totalItens,
      resultado.data.totalPaginas
    );
  };

  // eslint-disable-next-line consistent-return
  const onChangeFiltrosTabela = async (parametros) => {
    let mesRef = window.moment(mesAno).add(1, "month").month();
    if (mesRef === 0) mesRef = 12;

    setCarregamentoInicial(true);
    if (carregamentoInicial) {
      return obterPreFaturamento({
        tamanhoPagina: parametros.totalPagina,
        pagina: parametros.pagina,
        busca: parametros.pesquisar,
        empresaId: empresaSelecionada,
        clienteId: clienteSelecionado,
        unidadeId: unidadeSelecionada,
        ano: window.moment(mesAno).year(),
        mes: mesRef
      });
    }
  };

  const onClickBotao = (unidadeId, mes, ano) => {
    history.push(
      `${RotasDTO.ProcessamentoFaturaML}/${unidadeId}/${mes}/${ano}`
    );
  };

  useEffect(() => {
    setFiltroAdicionalTabela(
      new FiltroAdicionalTabela(
        ordemSelecionada,
        mesAno,
        empresaSelecionada,
        clienteSelecionado,
        unidadeSelecionada
      )
    );
  }, [mesAno, empresaSelecionada, clienteSelecionado, unidadeSelecionada]);

  useEffect(() => {
    setCarregamentoInicial(false);
    const dataFiltro = window.moment().add(-1, "month").toDate();
    setMesAno(dataFiltro);

    // Este é m bug do window.moment, ao obter o mês, ele pega o anterior
    let mesRef = window.moment(mesAno).add(1, "month").month();
    if (mesRef === 0) mesRef = 12;
  }, []);

  useEffect(() => {
    store.dispatch(desabilitarFiltroLateral(true));

    return () => {
      store.dispatch(desabilitarFiltroLateral(false));
    };
  }, [desabilitarFiltroLateral]);

  const textoBusca = useWatch({
    control,
    name: "textoBusca",
    defaultValue: ""
  });

  const colunas = [
    new Coluna("unidadeConsumidora", "Unidade consumidora"),
    new Coluna("mesReferencia", "Mês de referência"),
    new Coluna("faturaProcessada", "Fatura processada"),
    new ColunaComponentePersonalizado(
      "opcoes",
      "Ações",
      ColunaMultiplosBotoes,
      onClickBotao,
      false,
      false,
      "10%"
    )
  ];

  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item xs={12}>
        <UploadDropArea
          onAdd={onAddUpload}
          progressValue={progress}
          showLoader={showLoader}
          loader={carregandoArquivo}
          onDropRejected={onDropRejected}
          dropAreaText="Solte o arquivo para anexá-lo ou clique para procurar!"
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item md={5} sm={6}>
            <MaterialInputBusca
              type="text"
              id="textoBusca"
              name="textoBusca"
              label="Buscar"
              renderIconShowHide
              searchAdornment
              permiteValorBranco
              defaultValue={textoBusca ?? ""}
              ref={register}
            />
          </Grid>

          <Grid item lg={2} md={2} sm={6} className={classes.itemFiltro}>
            <InputData
              type="text"
              id="mesAnoBusca"
              name="mesAnoBusca"
              format="MMM/yyyy"
              customValue={mesAno}
              onChange={(data) => setMesAno(data)}
              views={["month", "year"]}
              className={classes}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className="mt-4">
        <TabelaPaginada
          ref={refTabela}
          onChangeFiltrosTabela={onChangeFiltrosTabela}
          colunas={colunas}
          pesquisar={textoBusca}
          filtrosAdicionais={filtroAdicionalTabela}
          paginaAtual={1}
          paginationPerPage={20}
          noHeader
        />
      </Grid>
    </Grid>
  );
};

export default ProcessamentoFaturaMLLista;
