import React from "react";
import { useStyles } from "./style";

const EmpresaNaoSelecionada = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      É necessário escolher uma empresa para continuar!
    </div>
  );
};
export default EmpresaNaoSelecionada;
