/* eslint-disable import/no-unresolved */
/* eslint-disable no-return-assign */
/* eslint-disable radix */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useCallback } from "react";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "componentes/tabPanel";
import { Base } from "componentes/cores";
import Botao from "componentes/botao";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { store } from "global/redux";
import { useForm } from "react-hook-form";
import MaterialSwitch from "componentes/switch/index";
import { Grid, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import MaterialInputTexto from "componentes/inputTexto/materialInput";
import MaterialInputMascara from "componentes/inputTextoMascara";
import { add, edit, getPadrao } from "../../servicos/mercadoFaturado";
import { useStyles } from "./style";
import { listarSerieHistoricaMercadoFaturado } from "../../servicos/seriesHistoricas";

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const MercadoFaturado = () => {
  const classes = useStyles();
  const theme = useTheme();

  const [seriesHistoricas, setSeriesHistoricas] = useState([]);
  const [igualar, setIgualar] = useState(false);
  const [isEdit, setIsEdit] = useState(true);
  const [isChange, setIsChange] = useState(false);

  const [value, setValue] = React.useState(0);

  const [pesoMigracaoTUSD, setPesoMigracaoTUSD] = useState("0");
  const [pesoHistoricoTUSD, setPesoHistoricoTUSD] = useState("0");
  const [pesoOutrosTUSD, setPesoOutrosTUSD] = useState("0");
  const [totalTUSD, setTotalTUSD] = useState();
  const [seriesTUSD, setSeriesTUSD] = useState([]);

  const [pesoMigracaoTE, setPesoMigracaoTE] = useState("0");
  const [pesoHistoricoTE, setPesoHistoricoTE] = useState("0");
  const [pesoOutrosTE, setPesoOutrosTE] = useState("0");
  const [totalTE, setTotalTE] = useState();
  const [seriesTE, setSeriesTE] = useState([]);

  const { register, handleSubmit } = useForm({
    reValidateMode: "onSubmit",
  });

  const obterSeriesHistoricas = useCallback(async () => {
    try {
      const data = {
        unidade: 1, // porcentagem
        periodicidade: "A" // anual
      }

      const response = await listarSerieHistoricaMercadoFaturado(data);
      if (response?.status === 200 && response?.data) {
        setSeriesHistoricas(response?.data);
      }
    } catch (error) {
      console.info(error);
    }
  }, []);

  useEffect(() => {
    if (!seriesHistoricas.length) obterSeriesHistoricas();
  }, [!seriesHistoricas.length, obterSeriesHistoricas]);

  const addSerieTUSD = (newValue) => setSeriesTUSD((state) => [...state, newValue]);
  const addSerieTE = (newValue) => setSeriesTE((state) => [...state, newValue]);

  const obterConfiguracao = useCallback(async () => {
    try {
      const config = await getPadrao();
      if (config?.status === 200 && config?.data) {
        setIsEdit(true);
        const configuracaoTUSD = config.data[0].configuracao;
        const configuracaoTE = config.data[1].configuracao;
        const pesosTUSD = config.data[0].pesossh;
        const pesosTE = config.data[1].pesossh;
        setPesoMigracaoTUSD((configuracaoTUSD.pesomigracao * 100).toFixed(2));
        setPesoHistoricoTUSD((configuracaoTUSD.pesohistorico * 100).toFixed(2));
        setPesoOutrosTUSD((configuracaoTUSD.pesooutros * 100).toFixed(2));
        setPesoMigracaoTE((configuracaoTE.pesomigracao * 100).toFixed(2));
        setPesoHistoricoTE((configuracaoTE.pesohistorico * 100).toFixed(2));
        setPesoOutrosTE((configuracaoTE.pesooutros * 100).toFixed(2));

        if (pesosTUSD.length > 0) {
          pesosTUSD.forEach((data) => {
            addSerieTUSD({
              id_serie: data.idserieidhistorica,
              id: Math.floor(Math.random() * 6) + 1,
              codigo: data.seriehistorica.codigo,
              apelido: data.seriehistorica.nome,
              fonte: data.seriehistorica.fonte,
              periodicidade: data.seriehistorica.periodicidade.tipo,
              categoria: "",
              peso: (data.peso * 100).toFixed(2)
            });
          });
        }

        if (pesosTE.length > 0) {
          pesosTE.forEach((data) => {
            addSerieTE({
              id_serie: data.idserieidhistorica,
              id: Math.floor(Math.random() * 6) + 1,
              codigo: data.seriehistorica.codigo,
              apelido: data.seriehistorica.nome,
              fonte: data.seriehistorica.fonte,
              periodicidade: data.seriehistorica.periodicidade.tipo,
              categoria: "",
              peso: (data.peso * 100).toFixed(2)
            });
          });
        }
      }
    } catch (error) {
      console.info(error);
    }
  }, []);

  useEffect(() => {
    obterConfiguracao();
  }, [obterConfiguracao]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const onChangeIgualar = (valor) => {
    if (valor) {
      handleChangeIndex(0);
    }
    setIgualar(valor);
  };



  const handlePesoMigracaoTUSD = (event) => {
    setPesoMigracaoTUSD(event.target.value.replace("%", ""));
    setIsChange(true);
  }

  const handlePesoHistoricoTUSD = (event) => {
    setPesoHistoricoTUSD(event.target.value.replace("%", ""));
    setIsChange(true);
  }

  const handlePesoOutrosTUSD = (event) => {
    setPesoOutrosTUSD(event.target.value.replace("%", ""));
    setIsChange(true);
  }

  const changeSelectTUSD = (event) => {
    setSeriesTUSD(
      seriesTUSD.map((item) => {
        if (parseInt(item.id_serie) === parseInt(event.currentTarget.dataset.id)) {
          return { ...item, codigo: event.target.value, apelido: event.currentTarget.dataset.apelido, fonte: event.currentTarget.dataset.fonte, periodicidade: event.currentTarget.dataset.periodicidade, categoria: event.currentTarget.dataset.categoria };
        }
        return item;
      })
    );
    setIsChange(true);
  };

  const changeInputTUSD = (event) => {
    setSeriesTUSD(
      seriesTUSD.map((item) => {
        if (parseInt(item.id) === parseInt(event.target.id)) {
          return { ...item, peso: event.target.value.replace("%", "") };
        }
        return item;

      })
    );
    setIsChange(true);
  };

  const handleTotalTUSD = useCallback(() => {
    let sum = 0;
    seriesTUSD.map((item) => sum += parseFloat(recuperarPontoDecimal(item.peso)));
    const values = [recuperarPontoDecimal(pesoHistoricoTUSD), recuperarPontoDecimal(pesoMigracaoTUSD), 
        recuperarPontoDecimal(pesoOutrosTUSD)];
    let sum2 = 0;
    values.map((item) => sum2 += parseFloat(item));
    setTotalTUSD((sum + sum2).toFixed(2));

  }, [seriesTUSD, pesoHistoricoTUSD, pesoMigracaoTUSD, pesoOutrosTUSD]);

  useEffect(() => { handleTotalTUSD() }, [handleTotalTUSD]);



  const handlePesoMigracaoTE = (event) => {
    setPesoMigracaoTE(event.target.value.replace("%", ""));
    setIsChange(true);
  }

  const handlePesoHistoricoTE = (event) => {
    setPesoHistoricoTE(event.target.value.replace("%", ""));
    setIsChange(true);
  }

  const handlePesoOutrosTE = (event) => {
    setPesoOutrosTE(event.target.value.replace("%", ""));
    setIsChange(true);
  }

  const changeSelectTE = (event) => {
    setSeriesTE(
      seriesTE.map((item) => {
        if (parseInt(item.id_serie) === parseInt(event.currentTarget.dataset.id)) {
          return { ...item, codigo: event.target.value, apelido: event.currentTarget.dataset.apelido, fonte: event.currentTarget.dataset.fonte, periodicidade: event.currentTarget.dataset.periodicidade, categoria: event.currentTarget.dataset.categoria };
        }
        return item;
      })
    );
    setIsChange(true);
  };

  const changeInputTE = (event) => {
    setSeriesTE(
      seriesTE.map((item) => {
        if (parseInt(item.id) === parseInt(event.target.id)) {
          return { ...item, peso: event.target.value.replace("%", "") };
        }
        return item;

      })
    );
    setIsChange(true);
  };

  const handleTotalTE = useCallback(() => {
    let sum = 0.0;
    seriesTE.map((item) => sum += parseFloat(recuperarPontoDecimal(item.peso)));
    const values = [recuperarPontoDecimal(pesoHistoricoTE), recuperarPontoDecimal(pesoMigracaoTE), 
        recuperarPontoDecimal(pesoOutrosTE)];
    let sum2 = 0.0;
    values.map((item) => sum2 += parseFloat(item));
    setTotalTE((sum + sum2).toFixed(2));


  }, [seriesTE, pesoHistoricoTE, pesoMigracaoTE, pesoOutrosTE]);

  useEffect(() => { handleTotalTE() }, [handleTotalTE]);

  const removeSeriesTUSD = (valueRemove) => {
    const newListSeriesTUSD = seriesTUSD?.filter(item => item !== valueRemove)
    setSeriesTUSD(newListSeriesTUSD);
  }

  const removeSeriesTE = (valueRemove) => {
    const newListSeriesTE = seriesTE?.filter(item => item !== valueRemove)
    setSeriesTE(newListSeriesTE);
  }

  const enviarFormulario = async () => {
    try {
      const obj = {
        "TUSD": {
          "configuracao": {
            "id": 0,
            "grupomercado": "TUSD",
            "pesomigracao": recuperaValorFloat(pesoMigracaoTUSD),
            "pesohistorico": recuperaValorFloat(pesoHistoricoTUSD),
            "pesooutros": recuperaValorFloat(pesoOutrosTUSD)
},
          "anosativos": [
            {
              "Ano": 0
            }
          ],
          "pesossh": seriesTUSD.map(item => (
            {
              "seriehistorica": {
                "idserieidhistorica": item.id,
                "codigo": item.codigo,
                "nome": item.apelido,
                "fonte": item.fonte,
                "periodicidade": {
                  "tipo": item.periodicidade
                },
                "categoria": {
                  "tipo": item.categoria
                }
              },
              "peso": recuperaValorFloat(item.peso)
            }
          ))
        },
        "TE": {
          "configuracao": {
            "id": 0,
            "grupomercado": "TE",
            "pesomigracao": !igualar ? recuperaValorFloat(pesoMigracaoTE) : recuperaValorFloat(pesoMigracaoTUSD),
            "pesohistorico": !igualar ? recuperaValorFloat(pesoHistoricoTE) : recuperaValorFloat(pesoHistoricoTUSD),
            "pesooutros": !igualar ? recuperaValorFloat(pesoOutrosTE) : recuperaValorFloat(pesoOutrosTUSD)
          },
          "anosativos": [
            {
              "Ano": 0
            }
          ],
          "pesossh": !igualar ? seriesTE.map(item => (
            {
              "seriehistorica": {
                "idserieidhistorica": item.id,
                "codigo": item.codigo,
                "nome": item.apelido,
                "fonte": item.fonte,
                "periodicidade": {
                  "tipo": item.periodicidade
                },
                "categoria": {
                  "tipo": item.categoria
                }
              },
              "peso": recuperaValorFloat(item.peso)
            }
          )) : seriesTUSD.map(item => (
            {
              "seriehistorica": {
                "idserieidhistorica": item.id,
                "codigo": item.codigo,
                "nome": item.apelido,
                "fonte": item.fonte,
                "periodicidade": {
                  "tipo": item.periodicidade
                },
                "categoria": {
                  "tipo": item.categoria
                }
              },
              "peso": recuperaValorFloat(item.peso)
            }
          ))
        }
      }
      let salvou = false;
      if (!isEdit) {
        salvou = await add(obj);
      } else {
        salvou = await edit(obj);
      }

      if (salvou) {
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: "Configuração de Série Histórica realizada com sucesso!"
          })
        );
      }

    } catch (error) {
      console.info(error)
    }
  }

  const recuperarPontoDecimal = ((sequencia) => {
    return String(sequencia).replace(",",".");
});

const recuperaValorFloat = ((sequencia) => {
    return (parseFloat(String(sequencia).replace(",",".")) / 100).toFixed(4);
});


  const aoEnviarFormulario = (dados) => {
    if (totalTUSD != 100.00 || (totalTE != 100.00 && !igualar)) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "O total dos pesos está ultrapassando 100% nas guias TUSD e/ou TE."
        })
      );
    } else {
      alertaExibir({
        tipo: "success",
        mensagem: "Sucesso"
      })
      enviarFormulario(dados);
    }
  }

  const cancelar = () => {

    setIsChange(false);

    setSeriesTUSD([]);
    setPesoMigracaoTUSD("0");
    setPesoHistoricoTUSD("0");
    setPesoOutrosTUSD("0");

    setSeriesTE([]);
    setPesoMigracaoTE("0");
    setPesoHistoricoTE("0");
    setPesoOutrosTE("0");
  }

  return (
    <form className="needs-validation" onSubmit={handleSubmit(aoEnviarFormulario)}>
      <div className={classes.root}>

        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="TUSD" {...a11yProps(0)} />
            <Tab label="TE" {...a11yProps(1)} disabled={igualar} />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <Grid container p={1} spacing={2} className={classes.container} justifyContent="center">
              <Grid item sm={1} />
              <Grid item sm={9}>
                <Grid container p={1} spacing={2} className={classes.container}>
                  <Grid item sm={8} justifyContent="flex-start">
                    <b>Série Histórica TUSD</b>
                  </Grid>
                  <Grid item sm={3} justifyContent="flex-start">
                    <b>Peso</b>
                  </Grid>
                </Grid>
                {seriesTUSD?.map(serie => (
                  <Grid container p={1} spacing={2} className={classes.container}>
                    <Grid item sm={8}>
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="seriesHistoricas-label">Nome da Série Histórica</InputLabel>
                        <Select
                          variant="outlined"
                          labelId="seriesHistoricas-label"
                          label="Série Histórica TUSD"
                          onChange={changeSelectTUSD}
                          value={serie.codigo}
                          fullWidth>
                          {seriesHistoricas.map((item) => (
                            <MenuItem
                              key={item.codigo}
                              data-id={serie.id_serie}
                              data-apelido={item.apelido}
                              data-fonte={item.fonte}
                              data-periodicidade={item.periodicidade === "M" ? "Mensal" : "Anual"}
                              data-categoria={item.idCategoria}
                              value={item.codigo}
                            >
                              {item.codigo}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item sm={3}>
                      <MaterialInputMascara
                        type="text"
                        sufixo="%"
                        id={serie.id}
                        name="teste"
                        defaultValue={serie.peso}
                        onBlur={changeInputTUSD}
                        renderIconShowHide={false}
                        ref={register}
                      />
                    </Grid>
                    <Grid item sm={1}>
                      <button
                        type="button"
                        className={classes.botao}
                        onClick={() => {
                          removeSeriesTUSD(serie);
                        }}
                      >
                        X
                      </button>
                    </Grid>
                  </Grid>
                ))}
                <br />
                <Grid container p={1} spacing={2} className={classes.container}>
                  <Grid item sm={8}>
                    <MaterialInputTexto
                      type="text"
                      name="migracaoTUSD"
                      defaultValue="Migração"
                      renderIconShowHide={false}
                      disabled
                      className={classes.inputText}
                    />
                  </Grid>
                  <Grid item sm={3}>
                    <MaterialInputMascara
                      type="text"
                      sufixo="%"
                      id="pesoMigracaoTUSD"
                      name="pesoMigracaoTUSD"
                      defaultValue={pesoMigracaoTUSD}
                      onBlur={handlePesoMigracaoTUSD}
                      renderIconShowHide={false}
                      ref={register}
                    />
                  </Grid>
                </Grid>
                <Grid container p={1} spacing={2} className={classes.container}>
                  <Grid item sm={8}>
                    <MaterialInputTexto
                      type="text"
                      name="historicoTUSD"
                      defaultValue="Histórico"
                      renderIconShowHide={false}
                      disabled
                      className={classes.inputText}
                    />
                  </Grid>
                  <Grid item sm={3}>
                    <MaterialInputMascara
                      type="text"
                      sufixo="%"
                      id="pesoHistoricoTUSD"
                      name="pesoHistoricoTUSD"
                      defaultValue={pesoHistoricoTUSD}
                      onBlur={handlePesoHistoricoTUSD}
                      renderIconShowHide={false}
                      ref={register}
                    />
                  </Grid>
                </Grid>
                <Grid container p={1} spacing={2} className={classes.container}>
                  <Grid item sm={8}>
                    <MaterialInputTexto
                      type="text"
                      name="outrosTUSD"
                      defaultValue="Outros"
                      renderIconShowHide={false}
                      disabled
                      className={classes.inputText}
                    />
                  </Grid>
                  <Grid item sm={3}>
                    <MaterialInputMascara
                      type="text"
                      sufixo="%"
                      id="pesoOutrosTUSD"
                      name="pesoOutrosTUSD"
                      defaultValue={pesoOutrosTUSD}
                      onBlur={handlePesoOutrosTUSD}
                      renderIconShowHide={false}
                      ref={register}
                    />
                  </Grid>
                </Grid>
                <Grid container p={1} spacing={2} className={classes.container}>
                  <Grid item sm={8} className={classes.total}>
                    Total:
                  </Grid>
                  <Grid item sm={3} className="text-center">
                    <b>{totalTUSD ? `${totalTUSD}%` : "%"}</b>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={2}>
                <Grid container p={1} spacing={2} className={classes.container}>
                  <Grid item sm={12}>
                    <button
                      type="button"
                      className={classes.botao}
                      onClick={() => {
                        addSerieTUSD({
                          id_serie: Math.floor(Math.random() * 6) + 1,
                          id: Math.floor(Math.random() * 6) + 1,
                          codigo: "",
                          apelido: "",
                          fonte: "",
                          periodicidade: "",
                          categoria: "",
                          peso: "0"
                        });
                      }}
                    >
                      + Série Histórica
                    </button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <Grid container p={1} spacing={2} className={classes.container} justifyContent="center">
              <Grid item sm={1} />
              <Grid item sm={9}>
                <Grid container p={1} spacing={2} className={classes.container}>
                  <Grid item sm={8} justifyContent="flex-start">
                    <b>Série Histórica TE</b>
                  </Grid>
                  <Grid item sm={3} justifyContent="flex-start">
                    <b>Peso</b>
                  </Grid>
                </Grid>
                {seriesTE?.map(serie => (
                  <Grid container p={1} spacing={2} className={classes.container}>
                    <Grid item sm={8}>
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="seriesHistoricas-label">Nome da Série Histórica</InputLabel>
                        <Select
                          variant="outlined"
                          labelId="seriesHistoricas-label"
                          label="Série Histórica"
                          onChange={changeSelectTE}
                          value={serie.codigo}
                          fullWidth>
                          {seriesHistoricas.map((item) => (
                            <MenuItem
                              key={item.codigo}
                              data-id={serie.id_serie}
                              data-apelido={item.apelido}
                              data-fonte={item.fonte}
                              data-periodicidade={item.periodicidade === "M" ? "Mensal" : "Anual"}
                              data-categoria={item.idCategoria}
                              value={item.codigo}
                            >
                              {item.codigo}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item sm={3}>
                      <MaterialInputMascara
                        type="text"
                        sufixo="%"
                        id={serie.id}
                        name="teste"
                        defaultValue={serie.peso}
                        onBlur={changeInputTE}
                        renderIconShowHide={false}
                        ref={register}
                      />
                    </Grid>
                    <Grid item sm={1}>
                      <button
                        type="button"
                        className={classes.botao}
                        onClick={() => {
                          removeSeriesTE(serie);
                        }}
                      >
                        X
                      </button>
                    </Grid>
                  </Grid>
                ))}
                <br />
                <Grid container p={1} spacing={2} className={classes.container}>
                  <Grid item sm={8}>
                    <MaterialInputTexto
                      type="text"
                      name="migracaoTE"
                      defaultValue="Migração"
                      renderIconShowHide={false}
                      disabled
                      className={classes.inputText}
                    />
                  </Grid>
                  <Grid item sm={3}>
                    <MaterialInputMascara
                      type="text"
                      sufixo="%"
                      id="pesoMigracaoTE"
                      name="pesoMigracaoTE"
                      defaultValue={pesoMigracaoTE}
                      onBlur={handlePesoMigracaoTE}
                      renderIconShowHide={false}
                      ref={register}
                    />
                  </Grid>
                </Grid>
                <Grid container p={1} spacing={2} className={classes.container}>
                  <Grid item sm={8}>
                    <MaterialInputTexto
                      type="text"
                      name="historicoTE"
                      defaultValue="Histórico"
                      renderIconShowHide={false}
                      disabled
                      className={classes.inputText}
                    />
                  </Grid>
                  <Grid item sm={3}>
                    <MaterialInputMascara
                      type="text"
                      sufixo="%"
                      id="pesoHistoricoTE"
                      name="pesoHistoricoTE"
                      defaultValue={pesoHistoricoTE}
                      onBlur={handlePesoHistoricoTE}
                      renderIconShowHide={false}
                      ref={register}
                    />
                  </Grid>
                </Grid>
                <Grid container p={1} spacing={2} className={classes.container}>
                  <Grid item sm={8}>
                    <MaterialInputTexto
                      type="text"
                      name="outrosTE"
                      defaultValue="Outros"
                      renderIconShowHide={false}
                      disabled
                      className={classes.inputText}
                    />
                  </Grid>
                  <Grid item sm={3}>
                    <MaterialInputMascara
                      type="text"
                      sufixo="%"
                      id="pesoOutrosTE"
                      name="pesoOutrosTE"
                      defaultValue={pesoOutrosTE}
                      onBlur={handlePesoOutrosTE}
                      renderIconShowHide={false}
                      ref={register}
                    />
                  </Grid>
                </Grid>
                <Grid container p={1} spacing={2} className={classes.container}>
                  <Grid item sm={8} className={classes.total}>
                    Total:
                  </Grid>
                  <Grid item sm={3} className="text-center">
                    <b>{totalTE ? `${totalTE}%` : "%"}</b>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={2}>
                <Grid container p={1} spacing={2} className={classes.container}>
                  <Grid item sm={12}>
                    <button
                      type="button"
                      className={classes.botao}
                      onClick={() => {
                        addSerieTE({
                          id_serie: Math.floor(Math.random() * 6) + 1,
                          id: Math.floor(Math.random() * 6) + 1,
                          codigo: "",
                          apelido: "",
                          fonte: "",
                          periodicidade: "",
                          categoria: "",
                          peso: "0"
                        });
                      }}
                    >
                      + Série Histórica
                    </button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </TabPanel>
        </SwipeableViews>
        <Grid container className={classes.container} justifyContent="center" sm={12}>
          <Grid item sm={6} class={classes.footerCheckbox}>
            <MaterialSwitch
              label="Igualar TUSD e TE?"
              labelPlacement="top"
              id="igualar"
              name="igualar"
              ref={register()}
              checked={igualar}
              onChange={(valor) => onChangeIgualar(valor)}
            />
          </Grid>
          <Grid item sm={6} class={classes.footerButtons}>
            <Grid container p={1} spacing={2} className={classes.container} justifyContent="flex-end">
              <Grid item sm={3} style={!isChange ? { display: "none" } : {}}>
                <Botao type="button" color={Base.Mango} label="Cancelar" onClick={cancelar} className={classes.buttonCustom} />
              </Grid>
              <Grid item sm={3}>
                <Botao type="submit" color={Base.Mango} label="Salvar" className={classes.button} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </form>
  );
}

export default MercadoFaturado;