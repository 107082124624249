import { buscarDadosCadastraisPorCnpj } from "../../../../servicos/dadosCadastraisCnpjServico";
import RetornoEndpointDto from "../../../../global/dto/retornoEndpointDto";
import { store } from "../../../../global/redux";
import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";
import {
  obterUsinasLuzCpfCnpj,
  obterUsinasLuzPorId,
  salvarUsinasLuz
} from "../../../../servicos/usinasLuzServico";
import { obterCepCobertura } from "../../../../servicos/correiosServico";
import { buscarDistribuidoras } from "../../../../servicos/distribuidorasServico";
import { obterAssociacao } from "../../../../servicos/associacaoLuzServico";

export default class CadastroUsinasHelper {
  static async salvarUsinasLuz(id, body, carregandoUsinas) {
    try {
      carregandoUsinas(true);
      const resultado = await salvarUsinasLuz(id, body);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Usinas não encontrados!");

      return RetornoEndpointDto.Sucesso(
        `Usinas ${id ? "atualizado" : "criado"} criado com sucesso`,
        resultado.data
      );
    } catch (error) {
      if (error.response?.status === 409) {
        return RetornoEndpointDto.Erro("usinasExists");
      }
      return RetornoEndpointDto.Erro(
        `Erro ao ${id ? "atualizar" : "criar"} associação!`
      );
    } finally {
      carregandoUsinas(false);
    }
  }

  static async obterCepCobertura(cepCobertura, setCarregandoCep) {
    try {
      setCarregandoCep(true);
      const resultado = await obterCepCobertura(cepCobertura);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro(
          "Obter cobertura única não encontrados!"
        );

      return RetornoEndpointDto.Sucesso(
        "Obter cobertura única com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Obter cobertura única não encontrados!");
    } finally {
      setCarregandoCep(false);
    }
  }

  static async buscarDadosCadastraisPorCnpj(cnpj, setCarregandoNomeFantasia) {
    try {
      setCarregandoNomeFantasia(true);
      const resultado = await buscarDadosCadastraisPorCnpj(cnpj);

      return RetornoEndpointDto.Sucesso(
        "Cnpj obtido com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Erro ao buscar Cnpj!");
    } finally {
      setCarregandoNomeFantasia(false);
    }
  }

  static async obterUsinasLuzPorId(params, carregandoUsinas) {
    try {
      carregandoUsinas(true);
      const resultado = await obterUsinasLuzPorId(params);

      return RetornoEndpointDto.Sucesso(
        "Usinas luz obtida com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Erro ao obter usinas luz!");
    } finally {
      carregandoUsinas(false);
    }
  }

  static async obterUsinasLuzCpfCnpj(cpfCnpj, carregandoUsinas) {
    try {
      carregandoUsinas(true);
      const resultado = await obterUsinasLuzCpfCnpj(cpfCnpj);

      return RetornoEndpointDto.Sucesso(
        "Obter usinas única com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Erro ao  encontrar usinas!");
    } finally {
      carregandoUsinas(false);
    }
  }

  static async obterDistribuidora(setCarregandoDistribuidora) {
    try {
      setCarregandoDistribuidora(true);
      const resultado = await buscarDistribuidoras();
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Distribuidora não encontrados!");

      return RetornoEndpointDto.Sucesso(
        "Distribuidora obtido com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Distribuidora não encontrados!");
    } finally {
      setCarregandoDistribuidora(false);
    }
  }

  static async obterAssociacao(setCarregandoAssociacao) {
    try {
      setCarregandoAssociacao(true);
      const resultado = await obterAssociacao();

      return RetornoEndpointDto.Sucesso(
        "Associação obtido com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Associação não encontrados!");
    } finally {
      setCarregandoAssociacao(false);
    }
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }

  static exibirSucesso(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem
      })
    );
  }
}
