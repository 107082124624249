import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import CheckBoxLuz from "../CheckBoxLuz";
import { useStyles } from "./style";

const CardsPlanos = ({
  titulo,
  porcentagem,
  anual,
  rodape,
  nomeCard,
  onChecked,
  onName,
  checked,
  disabled,
  descricao
}) => {
  const classes = useStyles();

  return (
    <>
      <Box
        bgcolor="#FFFFFF"
        border={`1px solid ${checked ? "#F722B5" : "#D2D2D7"}`}
        boxShadow="0px 2px 4px 1px rgba(0, 0, 0, 0.16)"
        borderRadius="8px"
      >
        <Box
          component="span"
          padding={2}
          bgcolor="#f5f5f7"
          className={classes.headerCard}
          display="flex"
          alignContent="center"
        >
          <CheckBoxLuz
            onChecked={onChecked}
            nomeCard={nomeCard}
            onName={onName}
            checked={checked}
            disabled={disabled}
          />

          <Box
            component="span"
            textAlign="center"
            color="#332053"
            fontSize="20px"
            fontWeight="700"
            width="100%"
          >
            {titulo}
          </Box>
        </Box>
        <Box padding={2}>
          <Box
            sx={{
              background: "linear-gradient(90deg, #F722B5 0%, #FD9507 100%)"
            }}
            color="#FFFFFF"
            borderRadius="8px"
            py={2}
          >
            <Box textAlign="center">
              <Box fontSize="24px" fontWeight="700">
                {`${porcentagem} de desconto`}
              </Box>
              <Box fontSize="16px" fontWeight="400">
                {descricao}
              </Box>
            </Box>
          </Box>
          <Box component="div" color="#332053" fontSize="16px" fontWeight="700">
            <Box textAlign="center">
              <Box pt={2}>
                Economia anual estimada de
                <Box>{anual}</Box>
              </Box>
              <Box pt={2}>{rodape}</Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

CardsPlanos.propTypes = {
  titulo: PropTypes.string,
  porcentagem: PropTypes.string,
  anual: PropTypes.string,
  rodape: PropTypes.string,
  nomeCard: PropTypes.string,
  onChecked: PropTypes.oneOfType([PropTypes.func]),
  onName: PropTypes.oneOfType([PropTypes.func]),
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  descricao: PropTypes.string
};

CardsPlanos.defaultProps = {
  titulo: "",
  porcentagem: "",
  anual: "",
  rodape: "",
  nomeCard: "",
  onChecked: () => {},
  onName: () => {},
  checked: false,
  disabled: false,
  descricao: ""
};

export default CardsPlanos;
