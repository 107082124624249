import React from "react";
import PropTypes from "prop-types";
import { generate } from "shortid";

import { Grid, Button } from "@mui/material";
import ImportExportIcon from '@mui/icons-material/ImportExport';
import PageviewIcon from '@mui/icons-material/Pageview';
import TimelineIcon from '@mui/icons-material/Timeline';
import { useStyles } from "./style";
import enumeradorBotao from "../enumeradores/enumeradorBotao";
import { HtmlTooltip } from "paginas/Graficos/ConsumoAtual/style";
import MaterialSwitch from "componentes/switch";

const ColunaMultiplosBotoes = ({ onClick, parametros }) => {
  const classes = useStyles();

  const onClickImportar = () => onClick(enumeradorBotao.Importar, parametros);
  const onClickVisualizar = () => onClick(enumeradorBotao.Visualizar, parametros);
  const onClickHistorico = () => onClick(enumeradorBotao.Historico, parametros);

  return (
    <Grid container spacing={2} className={classes.fundoCelula}>
      <Grid item xs={4} >
        <Button
          key={generate()} onClick={onClickImportar} className={`text-white ${classes.botao}`}
        >
          <HtmlTooltip title="Importar">
            <ImportExportIcon />
          </HtmlTooltip>
        </Button>
      </Grid>

      <Grid item xs={4}>
        <Button key={generate()} onClick={onClickVisualizar} className={`text-white ${classes.botao}`}>
          <HtmlTooltip title="Visualizar">
            <div>
              <PageviewIcon />
            </div>
          </HtmlTooltip>
        </Button>
      </Grid>

      <Grid item xs={4}>
        <Button key={generate()} onClick={onClickHistorico} className={`text-white ${classes.botao}`}>
          <HtmlTooltip title="Histórico">
            <div>
              <TimelineIcon />
            </div>
          </HtmlTooltip>
        </Button>
      </Grid>

    </Grid>
  );
};

ColunaMultiplosBotoes.propTypes = {
  onClick: PropTypes.oneOfType([PropTypes.func]).isRequired,
  parametros: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired
};

export default ColunaMultiplosBotoes;