import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Grid, Checkbox } from "@mui/material";
import MaterialInputTexto from "componentes/inputTexto/materialInput";
import { Botao, SelectArredondado } from "componentes";
import BotaoOutLine from "componentes/botaoOutline";
import FormControlLabel from "@mui/material/FormControlLabel";
import operacaoControleHelper from "../../helper";
import ModalCancelamento from "../ModalCancelamento";
import FormularioMotivo from "../FormularioMotivo";

import { useStyles } from "./style";

const FormularioCancelamento = (props) => {
  const { onCancelar, onSalvar, setNotificacao, setCarregandoOperacao } = props;
  const classes = useStyles();
  const usuario = useSelector((state) => state.usuario);
  const [listaMotivos, setListaMotivos] = useState();
  const [ticket, setTicket] = useState();
  const [motivoCancelamentoId, setMotivoCancelamentoId] = useState();
  const [descricaoCancelamento, setDescricaoCancelamento] = useState();
  const [discursoCliente, setDiscursoCliente] = useState();
  const [isencaoMulta, setIsencaoMulta] = useState(false);
  const [isencaoMedidor, setIsencaoMedidor] = useState(false);
  const [desabilitarSalvar, setDesabilitarSalvar] = useState(true);
  const [justificativaMulta, setJustificativaMulta] = useState();
  const [justificativaMedidor, setJustificativaMedidor] = useState();
  const [modalMotivo, setModalMotivo] = useState(false);

  const obterMotivos = async () => {
    const resultado = await operacaoControleHelper.obterCancelamentoMotivos();

    if (resultado?.data) {
      const motivos = resultado?.data || [];

      motivos.push({ id: -1, motivo: "+ New" });

      setListaMotivos(motivos);
    }
  };

  const validarBotao = () => {
    if (isencaoMedidor && !justificativaMedidor) {
      setDesabilitarSalvar(true);
      return;
    }
    if (isencaoMulta && !justificativaMulta) {
      setDesabilitarSalvar(true);
      return;
    }
    if (
      ticket &&
      motivoCancelamentoId &&
      descricaoCancelamento &&
      discursoCliente
    ) {
      setDesabilitarSalvar(false);
      return;
    }
    setDesabilitarSalvar(true);
  };

  const onChangeMotivo = (valor) => {
    if (valor === "-1") {
      setModalMotivo(true);
      return;
    }
    setMotivoCancelamentoId(valor);
  };

  const salvarMotivo = async (dados) => {
    if (listaMotivos?.find((dado) => dado.motivo === dados?.motivo)) {
      setNotificacao({
        aberto: true,
        tipo: "error",
        texto: "Motivo já existe na lista!"
      });
      return;
    }
    setCarregandoOperacao(true);
    const resultado = await operacaoControleHelper.salvarCancelamentoMotivo(
      dados,
      setNotificacao
    );

    if (resultado?.data) {
      setModalMotivo(false);
      await obterMotivos();
      setMotivoCancelamentoId(resultado?.data);
    }

    setCarregandoOperacao(false);
  };

  useEffect(() => {
    obterMotivos();
  }, []);

  useEffect(() => {
    validarBotao();
  }, [
    ticket,
    motivoCancelamentoId,
    descricaoCancelamento,
    discursoCliente,
    isencaoMedidor,
    justificativaMedidor,
    isencaoMulta,
    justificativaMulta
  ]);

  return (
    <>
      <ModalCancelamento
        titulo="Motivo de cancelamento"
        item={modalMotivo}
        conteudo={
          <FormularioMotivo
            onSalvar={(dados) => salvarMotivo(dados)}
            onCancelar={() => setModalMotivo(false)}
          />
        }
        onCancelar={() => setModalMotivo(false)}
      />
      <Grid container spacing={1}>
        <Grid item xs={12} className="font-weight-bold">
          <label htmlFor="motivos" className={classes.label}>
            Ticket de solicitação de cancelamento
          </label>
          <MaterialInputTexto
            type="text"
            id="ticket"
            name="ticket"
            label="Digite o ticket de solicitação"
            renderIconShowHide={false}
            defaultValue={ticket}
            onInput={(e) => setTicket(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} className="font-weight-bold">
          <label htmlFor="motivos" className={classes.label}>
            Opções de cancelamento
          </label>
          <SelectArredondado
            select
            id="motivos"
            name="motivos"
            label="Solicitacao de cancelamento"
            placeholder="Solicitacao de cancelamento"
            valueKey="id"
            valueName="motivo"
            dataSource={listaMotivos}
            value={motivoCancelamentoId}
            onChange={(e) => {
              onChangeMotivo(e.target.value);
            }}
            permiteValorBranco
          />
        </Grid>
        <Grid item xs={12} className="font-weight-bold">
          <label htmlFor="motivos" className={classes.label}>
            Descrição do motivo de cancelamento
          </label>
          <textarea
            id="descricaoCancelamento"
            name="descricaoCancelamento"
            defaultValue={descricaoCancelamento}
            className={classes.inputTextArea}
            onChange={(valor) => setDescricaoCancelamento(valor.target.value)}
            maxLength={200}
            placeholder="Digite o motivo de cancelamento"
            autoComplete="off"
          />
        </Grid>

        <Grid item xs={12} className="font-weight-bold">
          <label htmlFor="motivos" className={classes.label}>
            Discurso de retenção do cliente
          </label>
          <textarea
            id="discursoCliente"
            name="discursoCliente"
            defaultValue={discursoCliente}
            className={classes.inputTextArea}
            onChange={(valor) => setDiscursoCliente(valor.target.value)}
            maxLength={200}
            placeholder="Digite o motivo de retenção"
            autoComplete="off"
          />
        </Grid>

        <Grid item xs={12} className="font-weight-bold">
          <FormControlLabel
            control={
              <Checkbox
                checked={isencaoMulta}
                onChange={(e) => setIsencaoMulta(e.target.checked)}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="Solicitar isenção da multa da quebra de fidelidade"
            className={classes.checkboxLabel}
          />
        </Grid>

        {isencaoMulta && (
          <Grid item xs={12} className="font-weight-bold">
            <label htmlFor="motivos" className={classes.label}>
              Justificativa
            </label>
            <textarea
              id="justificativaMulta"
              name="justificativaMulta"
              defaultValue={justificativaMulta}
              className={classes.inputTextArea}
              onChange={(valor) => setJustificativaMulta(valor.target.value)}
              maxLength={200}
              placeholder="Digite o motivo de cancelamento"
              autoComplete="off"
            />
          </Grid>
        )}

        <Grid item xs={12} className="font-weight-bold">
          <FormControlLabel
            control={
              <Checkbox
                checked={isencaoMedidor}
                onChange={(e) => setIsencaoMedidor(e.target.checked)}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="Solicitar isenção da instalação do medidor"
            className={classes.checkboxLabel}
          />
        </Grid>
        {isencaoMedidor && (
          <Grid item xs={12} className="font-weight-bold">
            <label htmlFor="motivos" className={classes.label}>
              Justificativa
            </label>
            <textarea
              id="justificativaMedidor"
              name="justificativaMedidor"
              defaultValue={justificativaMedidor}
              className={classes.inputTextArea}
              onChange={(valor) => setJustificativaMedidor(valor.target.value)}
              maxLength={200}
              placeholder="Digite o motivo de cancelamento"
              autoComplete="off"
            />
          </Grid>
        )}

        <Grid item xs={6}>
          <BotaoOutLine
            onClick={() => onCancelar()}
            label="Cancelar"
            className={classes.botaoTransparente}
          />
        </Grid>
        <Grid item xs={6}>
          <Botao
            onClick={() =>
              onSalvar(
                {
                  ticket,
                  motivoCancelamentoId: Number(motivoCancelamentoId),
                  descricaoCancelamento,
                  discursoCliente,
                  isencaoMedidor,
                  isencaoMulta,
                  ...(isencaoMedidor && { justificativaMedidor }),
                  ...(isencaoMulta && { justificativaMulta }),
                  criarChamado: true,
                  emailSolicitante: usuario?.usuario?.email
                },
                setDesabilitarSalvar
              )
            }
            label="Salvar"
            className={classes.botao}
            disabled={desabilitarSalvar}
          />
        </Grid>
      </Grid>
    </>
  );
};

FormularioCancelamento.propTypes = {
  onCancelar: PropTypes.func,
  onSalvar: PropTypes.func,
  setNotificacao: PropTypes.func,
  setCarregandoOperacao: PropTypes.func
};

FormularioCancelamento.defaultProps = {
  onCancelar: () => {},
  onSalvar: () => {},
  setNotificacao: () => {},
  setCarregandoOperacao: () => {}
};

export default FormularioCancelamento;
