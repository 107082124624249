import MICROSERVICO from "../global/enumeradores/microServico";
import obterApiLuz from "./apiLuz";

const api = () => obterApiLuz(MICROSERVICO.SITE_LUZ_LEAD);

export const obterMatrizDescontoGdBaixa = async (params) => {
  const paramsMatriz = new URLSearchParams(params).toString();

  return api().get(`v1/MatrizDescontoGDBaixa?${paramsMatriz}`);
};
