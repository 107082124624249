import React, { useState } from "react";
import PropTypes from "prop-types";

import { FormControl, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

import { customStyles, useStyles } from "./style";

const SelectCustomizadoAutoComplete = React.forwardRef((props, ref) => {
  const {
    id,
    label,
    options,
    getOptionLabel,
    onChange,
    onInputChange,
    onChangeSelect,
    value,
    disabled,
    defaultValue,
    error,
    maxHeight,
    maxWidth,
    marginBottom,
    isFuncionality,
    customSelect,
    valueName
  } = props;

  const mixedStyle = customSelect
    ? customStyles({ maxHeight, maxWidth, marginBottom, isFuncionality, value })
    : useStyles({ maxHeight, maxWidth, marginBottom, value });

  const [aberto, setAberto] = useState(false);

  const onClickAbrirOpcoes = () => {
    setAberto(true);
  };

  const onClickFecharOpcoes = () => {
    setAberto(false);
  };

  return (
    <FormControl
      variant="outlined"
      fullWidth
      className={mixedStyle.formControl}
    >
      <Autocomplete
        id={id}
        disableClearable={false}
        options={options?.length ? options : []}
        getOptionLabel={getOptionLabel}
        onInputChange={onInputChange}
        value={
          value && options?.length > 0
            ? options?.find((item) => item?.id === value)
            : null
        }
        onChange={onChange}
        disabled={disabled}
        defaultValue={
          defaultValue && options?.length > 0
            ? options?.find((item) => item?.id === defaultValue)
            : null
        }
        noOptionsText="Nenhum registro encontrado"
        classes={{ root: mixedStyle.select }}
        ref={ref}
        onOpen={() => onClickAbrirOpcoes()}
        onClose={() => onClickFecharOpcoes()}
        renderInput={(params) => (
          <>
            <TextField
              {...params}
              onChange={onChangeSelect}
              placeholder={label}
              variant="outlined"
              error={Boolean(error[id])}
            />
            {aberto ? (
              <ArrowDropUpIcon
                className={mixedStyle.selectIcon}
                fontSize="small"
              />
            ) : (
              <ArrowDropDownIcon
                className={mixedStyle.selectIcon}
                fontSize="small"
              />
            )}
          </>
        )}
        renderOption={(propsOptions, option) => {
          return (
            <li {...propsOptions} key={option.id}>
              {option[valueName]}
            </li>
          );
        }}
      />
    </FormControl>
  );
});

SelectCustomizadoAutoComplete.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  getOptionLabel: PropTypes.oneOfType([PropTypes.any]),
  value: PropTypes.oneOfType([PropTypes.any]),
  onInputChange: PropTypes.oneOfType([PropTypes.func]),
  onChange: PropTypes.oneOfType([PropTypes.func]),
  onChangeSelect: PropTypes.oneOfType([PropTypes.func]),
  label: PropTypes.string,
  maxHeight: PropTypes.string,
  maxWidth: PropTypes.string,
  marginBottom: PropTypes.string,
  inputValue: PropTypes.string,
  options: PropTypes.oneOfType([PropTypes.any]),
  error: PropTypes.oneOfType([PropTypes.any]),
  defaultValue: PropTypes.oneOfType([PropTypes.any]),
  disabled: PropTypes.bool,
  customSelect: PropTypes.bool,
  isFuncionality: PropTypes.bool,
  valueName: PropTypes.string
};

SelectCustomizadoAutoComplete.defaultProps = {
  id: "",
  getOptionLabel: "",
  value: "",
  onChange: () => {},
  onInputChange: () => {},
  onChangeSelect: () => {},
  label: "",
  maxHeight: "auto",
  maxWidth: "auto",
  marginBottom: "10px",
  inputValue: "",
  options: "",
  error: false,
  defaultValue: null,
  disabled: false,
  customSelect: false,
  isFuncionality: false,
  valueName: "label"
};

export default SelectCustomizadoAutoComplete;
