import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const api = () => obterApi(MICROSERVICO.MEASUREMENT);

export const listarFaturaDistribuicaoCliente = async (
  paginaAtual = 1,
  campoPesquisa,
  textoPesquisa,
  ano,
  mes,
  empresaId,
  clienteId,
  unidadeId
) => {
  const params = {
    pagina: paginaAtual,
    tamanhoPaginacao: 10,
    campoPesquisa,
    textoPesquisa,
    ano,
    mes,
    empresaId,
    clienteId,
    unidadeId
  };

  return api().get(
    `v1/FaturaDistribuidora/obtertodos?${new URLSearchParams(
      params
    ).toString()}`
  );
};

export const listarFaturaDistribuidoraPreLead = async (
  paginaAtual = 1,
  textoPesquisa,
  dataInicial,
  dataFinal,
  campoPesquisa,
  origem,
  siglaProduto
) => {
  const params = {};
  params.pagina = paginaAtual;
  params.tamanhoPaginacao = 10;

  if (dataInicial) params.dataInicialDeCriacao = dataInicial;
  if (dataFinal) params.dataFinalDeCriacao = dataFinal;
  if (origem) params.origem = origem;
  if (siglaProduto) params.siglaProduto = siglaProduto;

  if (campoPesquisa) {
    params.campoPesquisa = campoPesquisa;
    params.textoPesquisa = textoPesquisa;
  }

  return api().get(
    `v1/FaturaDistribuidora/ObterPreLeads?${new URLSearchParams(
      params
    ).toString()}`
  );
};

export const obterFaturaDistribuidoraPreLead = async (id) => {
  return api().get(`v1/FaturaDistribuidora/ObterPreLeadPorId/${id}`);
};
