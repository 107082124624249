import { makeStyles } from "@mui/styles";
import { Base } from "componentes/cores";

export const useStyles = makeStyles({
  container: {
    paddingBottom: 0,
    margin: "0",
    width: "100%",
    "& .MuiGrid-item": {
      paddingBottom: 0
    },
    "& .MuiInputBase-root": {
      height: "46px",
      paddingBottom: 0
    },
    "& .MuiFormLabel-root": {
      lineHeight: "1"
    },
    "& .MuiGrid-spacing-xs-4 > .MuiGrid-item": {
      padding: "12px"
    }
  },
  button: {
    fontSize: "18px"
  },
  tituloSecao: {
    fontSize: "18px"
  },
  containerAvancar: {
    margin: "0 0 16px"
  },
  addRepresentante: ({ disabled }) => {
    return {
      "& .MuiSvgIcon-root, button": {
        color: disabled ? Base.GreyDisabled : Base.Black
      }
    };
  },
  containerCampos: {
    padding: "30px 20px 0px 20px"
  },
  iconeCentralizar: {
    justifyContent: "center",
    justifyitems: "center",
    justifySelf: "center",
    alignItems: "center",
    aligncontent: "center",
    alignself: "center"
  },
  icon: {
    cursor: "pointer"
  },
  item: {
    display: "flex",
    justifyContent: "center",
    marginLeft: "auto",
    paddingRight: 0,
    textAlign: "center",
    alignItems: "center",
    aligncontent: "center",
    alignself: "center"
  }
});
