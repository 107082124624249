import { saveAs } from "file-saver";
import MICROSERVICO from "global/enumeradores/microServico";
import obterApi from "servicos/api";

const api = () => obterApi(MICROSERVICO.AREALOGADA);

export const Atualiza = async (params) => {
  return api().patch("v1/relatorio/atualiza", params);
};

export const Listar = async (params) => {
  return api().get(
    `v1/relatorio/lista?${new URLSearchParams(params).toString()}`
  );
};

export const ListarCustomizados = async (params) => {
  // console.log('ListarCustomizados', params);

  return api().get(
    `v1/relatorio/listacustomizadosporcliente?${new URLSearchParams(
      params
    ).toString()}`
  );
};

export const ListarSetoriais = async (params) => {
  // console.log('ListarSetoriais', params);

  return api().get(
    `v1/relatorio/listasetoriaisporcliente?${new URLSearchParams(
      params
    ).toString()}`
  );
};

export const ObterPorId = async (params) => {
  // console.log('ObterPorId', params);

  return api().get(`v1/relatorio/obterporid/${params.id}`);
};

export const MudarStatus = async (params) => {
  // let params = {Id: id, Ativo: status};

  return api().patch("v1/relatorio/atualizastatus", params);
};

export const BaixarPorUUID = async (params) => {
  const { data } = await api().get(
    `v1/relatorio/download/${params.identificador}`,
    {
      responseType: "blob"
    }
  );

  const blob = new Blob([data]);

  saveAs(blob, params.nome);
};

export const Upload = async (params) => {
  const formData = new FormData();

  params.arquivo.forEach((element) => {
    formData.append("arquivo", element);
  });

  formData.append("tipoRelatorioId", params.tipoRelatorioId);
  formData.append("clienteId", params.clienteId ?? "");

  return api().post("v1/relatorio/uploademlote", formData, {
    headers: { "Content-Type": "multipart/form-data" }
  });
};

export const ObterVideosPorRelatorioId = async (params) => {
  return api().get(
    `v1/videorelatorio/listaporrelatorioid/?RelatorioId=${params.id}`
  );
};

export const IniciaMultpartUpload = async (params) => {
  return api().post(
    `v1/videorelatorio/create-multpart-upload?extensao=${params.extensao}`
  );
};

export const GerarLinkVideo = async (params) => {
  return api().post("v1/videorelatorio/gera-url-multpart-upload", params);
};

export const CompletarEnvioVideo = async (params) => {
  return api().post("v1/videorelatorio/completa-multpart-upload", params);
};

export const CancelarEnvioVideo = async (params) => {
  return api().post("v1/videorelatorio/abort-multpart-upload", params);
};

export const PostarVideoS3 = async (params) => {
  const { arquivos, urls } = params;
  const uploadedParts = [];

  const resultado = await arquivos.map(async (part, i) => {
    // eslint-disable-next-line no-return-await
    return await new Promise((resolve) => {
      const xhr = new XMLHttpRequest();
      xhr.open("PUT", urls[i]);

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          const ETag = xhr.getResponseHeader("ETag");

          if (ETag) {
            const uploadedPart = {
              partNumber: i + 1,
              // eslint-disable-next-line quotes
              eTag: ETag.replaceAll('"', "")
            };

            uploadedParts.push(uploadedPart);
          }
        }

        resolve(xhr.status);
      };

      xhr.send(part.file);
    });
  });

  const respostas = await Promise.all(resultado);

  if (respostas.find((resposta) => resposta !== 200)) {
    throw new Error("Erro ao fazer upload no S3!");
  }

  return { data: uploadedParts };
};

export const PostarVideo = async (params) => {
  const formData = new FormData();
  formData.append("relatorioId", params.relatorioId);
  formData.append("nome", params.nome);
  formData.append("descricao", params.descricao);
  formData.append("dataUpload", params.dataUpload);
  formData.append("extensao", params.extensao);
  formData.append("ativo", params.ativo);
  formData.append("identificador", params.identificador);
  formData.append("identificadorImagem", params.identificadorImagem);
  formData.append("extensaoImagem", params.extensaoImagem);

  return api().post("v1/videorelatorio/cria", formData, {
    headers: { "Content-Type": "multipart/form-data" }
  });
};

export const AtualizarVideo = async (params) => {
  return api().patch("v1/videorelatorio/atualiza", params);
};

export const DeletarVideo = async (id) => {
  return api().delete(`v1/videorelatorio/${id}`);
};

export const DeletarVideoIdentificador = async (params) => {
  return api().delete(
    `v1/videorelatorio/identificador/${params.identificador}/extensao/${params.extensao}`
  );
};

export const AtualizarOrdemsVideos = async (params) => {
  return api().patch("v1/videorelatorio/reordena", { lista: params });
};
