import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Card, Grid } from '@mui/material';
import { useStyles, Tabela, TabelaScroll } from './style';
import { valoresContratoEER } from '../../../../../servicos/premissasBrasil';
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import CloseIcon from "@mui/icons-material/Close";
import RotasDTO  from "../../../../../rotas/rotasUrlDto";
import { useHistory } from "react-router-dom";

const ContratosEerVisualizar = () => {
  const classes = useStyles();
  const history = useHistory();

  const [valores, setValores] = useState([]);
  const onFechar = (id) => {
    history.push(`${RotasDTO.ProjecaoInsumoListar}`);
  }
  const obterValores = async () => {
    try {
      const response = await valoresContratoEER();
      if (response.status === 200 && response.data) {
        setValores(response?.data);
      } else {
        setValores([]);
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "Não há dados para impressão com os parâmetros informados."
          })
        );
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  useEffect(() => {
    if (!valores.length) obterValores();
  }, [!valores.length, obterValores]);

  return (   
      <Grid container className={classes.container}> 
        <Grid container className={classes.containerTitulo}>
          <Grid item xs={11} className={classes.titulo}>
            Contratos EER
          </Grid>
          <Grid item xs={1} >             
              <CloseIcon onClick={onFechar} className={classes.icon}/>
          </Grid>   
        </Grid> 
        <Grid item xs={12}>
          <Card className={classes.container}>
            <Grid container className={classes.container}>
              {valores.length > 0 ?
                <Grid item xs={12}>
                  <TabelaScroll>
                    <div id="scroll">
                      <Tabela>
                        <Tabela.Thead>
                          <Tabela.Tr>
                            <Tabela.Th>Leilão</Tabela.Th>
                            <Tabela.Th>Tipo de leilão</Tabela.Th>
                            <Tabela.Th>Produto</Tabela.Th>
                            <Tabela.Th>Sigla do vendedor</Tabela.Th>
                            <Tabela.Th>Situação</Tabela.Th>
                            <Tabela.Th>Submercado do registro do contrato</Tabela.Th>
                            <Tabela.Th>Fonte energética</Tabela.Th>
                            <Tabela.Th>Energia negociada por contrato (MWh)</Tabela.Th>
                            <Tabela.Th>Energia negociada por contrato para os demais anos (MW médios)</Tabela.Th>
                            <Tabela.Th>Tipo de contrato (QTD/DIS)</Tabela.Th>
                            <Tabela.Th>Preço de Venda ou ICB na data do leilão (R$/MWh)</Tabela.Th>
                            <Tabela.Th>Data de Realização do leilão</Tabela.Th>
                            <Tabela.Th>Preço de venda atualizado (R$/MWh)</Tabela.Th>
                            <Tabela.Th>Receita fixa por contrato na data do leilão para os demais anos (R$/ano)</Tabela.Th>
                            <Tabela.Th>Data do Início de Suprimento</Tabela.Th>
                            <Tabela.Th>Data do Fim de Suprimento</Tabela.Th>
                          </Tabela.Tr>
                        </Tabela.Thead>
                        <Tabela.Tbody>
                          {valores?.map((item) => (
                            <Tabela.Tr>
                              <Tabela.Td>{item.Leilao}</Tabela.Td>
                              <Tabela.Td>{item.TipoLeilao}</Tabela.Td>
                              <Tabela.Td>{item.Produto}</Tabela.Td>
                              <Tabela.Td>{item.Vendedor}</Tabela.Td>
                              <Tabela.Td>{item.Situacao}</Tabela.Td>
                              <Tabela.Td>{item.SubRegistro}</Tabela.Td>
                              <Tabela.Td>{item.FonteEnergia}</Tabela.Td>
                              <Tabela.Td>{item.EnergiaNegociada.toLocaleString("pt-BR", { minimumFractionDigits: 2 })}</Tabela.Td>
                              <Tabela.Td>{item.EnergiaDemaisAnos.toLocaleString("pt-BR", { minimumFractionDigits: 2 })}</Tabela.Td>
                              <Tabela.Td>{item.TipoContrato}</Tabela.Td>
                              <Tabela.Td>{item.ICB.toLocaleString("pt-BR", { minimumFractionDigits: 2 })}</Tabela.Td>
                              <Tabela.Td>{moment(item.DataLeilao).format('L')}</Tabela.Td>
                              <Tabela.Td>{item.PrecoVenda.toLocaleString("pt-BR", { minimumFractionDigits: 2 })}</Tabela.Td>
                              <Tabela.Td>{item.ReceitaFixa.toLocaleString("pt-BR", { minimumFractionDigits: 2 })}</Tabela.Td>
                              <Tabela.Td>{moment(item.DataInicio).format('L')}</Tabela.Td>
                              <Tabela.Td>{moment(item.DataFim).format('L')}</Tabela.Td>
                            </Tabela.Tr>
                          ))}
                        </Tabela.Tbody>
                      </Tabela>
                    </div>
                  </TabelaScroll>
                </Grid>
                : ""
              }
            </Grid>
          </Card>
        </Grid>
      </Grid>
  )
}

export default ContratosEerVisualizar;