import { Button } from "@mui/material";
import React from "react";
import { generate } from "shortid";
import theme from "themes";

export default class ColunaBotao {
  constructor(
    field,
    headerName,
    onButtonClick,
    labelButton,
    sortable = false,
    maxWidth = null,
    minWidth = null,
    compact = false,
    ativo
  ) {
    this.selector = field;
    this.name = headerName;
    this.sortable = sortable;
    this.maxWidth = maxWidth;
    this.minWidth = minWidth;
    this.compact = compact;
    this.ativo = ativo;
    this.cell = (params) => {
      return (
        <Button
          key={generate()}
          className={theme.color.textoBarra}
          disabled={ativo}
          disableElevation={ativo}
          disableFocusRipple={ativo}
          onClick={() => onButtonClick(params.id)}
        >
          {labelButton}
        </Button>
      );
    };
  }
}
