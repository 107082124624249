import { saveAs } from "file-saver";
import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const api = () => obterApi(MICROSERVICO.MEASUREMENT);

export const listarOrganizarPor = () => {
  const ordernacoes = [
    {
      key: "0",
      label: "Alfabética"
    },
    {
      key: "3",
      label: "Recentes"
    }
  ];

  return ordernacoes;
};

export const listarFiltrarPor = () => {
  const filtros = [
    {
      key: "AT",
      label: "Ativo"
    },
    {
      key: "IN",
      label: "Inativo"
    }
  ];

  return filtros;
};

export const listarFiltrarClientePor = () => {
  const filtros = [
    {
      key: "PF",
      label: "PF"
    },
    {
      key: "PJ",
      label: "PJ"
    }
  ];

  return filtros;
};

export const salvarMedidorGD = async (id, dados) => {
  let metodo = "post";
  let url = "v1/medidorgd/";
  if (id > 0) {
    metodo = "put";
    url = `${url}${id}`;
  }
  return api()[metodo](url, dados);
};

export const buscarPorId = async (id) => {
  return api().get(`v1/medidorgd/${id}`);
};

export const listarMedidorGD = async (filtro) => {
  const filtroQueryString = new URLSearchParams(filtro).toString();
  return api().get(`v1/medidorgd/paginado?${filtroQueryString}`);
};

export const getUnidadeConsumidoraCliente = async (cpfCnpj) => {
  return api().get(`v1/unidadeconsumidora/cliente/${cpfCnpj}`);
};

export const getTiposMedidores = async () => {
  return api().get("v1/medidores/tiposmedidor");
};

export const getTiposTC = async () => {
  return api().get("v1/medidorgd/medidorgdtipotc");
};

export const deletarMedidorGD = async (id, ativo) => {
  const situacao = ativo ? "AT" : "IN";
  return api().patch(`v1/medidorgd/${id}/situacao/${situacao}`);
};

export const uploadMultiplo = async (codigo, listaArquivos) => {
  const formdata = new FormData();
  listaArquivos.forEach((arquivo) => {
    formdata.append("arquivos", arquivo, arquivo.path);
  });

  return api().post(`v1/medidorgd/arquivo?codigoMedidorGD=${codigo}`, formdata);
};

export const downloadArquivos = async (codigoMedidorGD, nomeArquivo) => {
  const { data } = await api().get(
    `v1/medidorgd/arquivo/${codigoMedidorGD}/${nomeArquivo}`,
    {
      responseType: "blob"
    }
  );
  const blob = new Blob([data]);
  saveAs(blob, nomeArquivo);
};

export const deleteArquivos = async (codigoMedidorGD, nomeArquivo) => {
  return api().delete(
    `v1/medidorgd/arquivo?codigoMedidorGD=${codigoMedidorGD}&nomeArquivo=${nomeArquivo}`
  );
};

export const obterPorIdUnidade = async (id) => {
  return api().get(`v1/medidorgd/obterporunidade/${id}`);
};
