import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

// Redux
import { useSelector } from "react-redux";

// Form Hooks
import { useForm } from "react-hook-form";

// Componentes
import { Card, Divider, Grid } from "@mui/material";
import Botao from "../../../../componentes/botao";
import BotaoRetornarListagem from "../../../../componentes/botaoRetornarListagem";
import MaterialInputTexto from "../../../../componentes/inputTexto/materialInput";
import MaterialInputTelefone from "../../../../componentes/inputTexto/materialInputTelefone";
import MaterialInputMascara from "../../../../componentes/inputTextoMascara";
import Loader from "../../../../componentes/loader";
import ModalConfirmacao from "../../../../componentes/modalConfirmacao";
import SelectArredondado from "../../../../componentes/selectArredondado";
import SelectAutoComplete from "../../../../componentes/selectAutocomplete";
import SelectAutocompleteCheckbox from "../../../../componentes/selectAutocompleteCheckbox";
import SelectAutocompleteCheckboxAsync from "../../../../componentes/selectAutocompleteCheckboxAsync";
import MaterialSwitch from "../../../../componentes/switch/index";
import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";

// Serviços
import { listarClientesComboFiltro } from "../../../../servicos/clientesServico";
import { listarEmpresasGrupoDeltaCombo } from "../../../../servicos/empresaServico";
import { usuarioPossuiFuncionalidade } from "../../../../servicos/funcionalidadesServico";
import { listarUnidadesConsumidorasCombo } from "../../../../servicos/unidadesServico";
import {
  buscarPorCpf,
  buscarPorEmail,
  buscarPorId,
  buscarPorIdClienteProspectLiberado,
  buscarPorUserName,
  obterUltimoLoginUsuario,
  salvarUsuario,
  verificarSenhaUsuario,
  verificarAcessoBiometria
} from "../../../../servicos/usuariosServico";

// Rotas
import { RotasDTO } from "../../../../global/rotas/rotasUrlDto";

// Redux
import { store } from "../../../../global/redux";
import { listarColaboradoresSemPaginacao } from "../../../../servicos/colaboradoresServico";

// Styles
import InputRadioGroup from "../../../../componentes/inputRadioGroup";
import RadioDto from "../../../../componentes/inputRadioGroup/radioDto";
import InputData from "../../../../componentes/inputTextoData";
import { usuarioTipoEnum } from "../../../../global/constantes";
import {
  desabilitarCliente,
  desabilitarEmpresa,
  desabilitarUnidade,
  selecionarCliente,
  selecionarEmpresa,
  selecionarUnidade
} from "../../../../global/redux/modulos/usuario/actions";
import AlertaUtil from "../../../../global/utils/alertaUtil";
import UsuarioHelper from "./helper";
import { useStyles } from "./style";

// DTO
import { InterfaceDTO } from "../../../../global/dto/interfacesDto";
import { getTipoPerfil } from "../../../../global/getTipoPerfil";
import { validarCpfCnpj } from "../../../../global/utils/formatCpfCnpj";
import { existeAcento } from "../../../../servicos/utils";

const UsuarioCadastro = () => {
  const { id } = useParams();
  const classes = useStyles();

  const history = useHistory();
  const { register, errors, handleSubmit } = useForm({
    reValidateMode: "onSubmit"
  });

  const usuarioGlobal = useSelector((state) => state.usuario);
  const { menu } = usuarioGlobal;
  const { tipo } = usuarioGlobal?.usuario;

  const usuarioLogado = useMemo(() => usuarioGlobal?.usuario, [usuarioGlobal]);

  const rotas = useSelector((state) => state.rotas);
  const permissaoVisualizarUsuarios =
    usuarioPossuiFuncionalidade(
      menu,
      rotas.rotaAtual.caminho,
      RotasDTO.Usuarios,
      InterfaceDTO.PermissaoUsuariosClienteProspect
    ) && usuarioGlobal?.usuario?.tipoPerfil !== usuarioTipoEnum.colaborador;

  const [carregandoUsuario, setCarregandoUsuario] = useState(false);
  const [dadosUsuario, setDadosUsuario] = useState();
  const [usuarioUnidade, setUsuarioUnidade] = useState();
  const [disabledBotaoSalvar, setDisabledBotaoSalvar] = useState(false);
  const [disabledColaborador, setDisabledColaborador] = useState(false);
  const [disabledCampoTipoUsuario, setDisabledCampoTipoUsuario] = useState(
    false
  );
  const [carregandoTipos, setCarregandoTipos] = useState(false);
  const [tiposUsuariosLista, setTiposUsuarioLista] = useState(null);
  const [mostrarEnviarEmail, setMostrarEnviarEmail] = useState(false);
  const [disabilitaCampos, setDisabilitaCampos] = useState(false);
  const [disabledCpf, setDisabledCpf] = useState(false);
  const [mostrarPerfil, setMostrarPerfil] = useState(false);
  const [mostrarRadioGroup, setMostrarRadioGroup] = useState(false);
  const [mostrarStatus, setMostrarStatus] = useState(false);
  const [ativo, setAtivo] = useState(false);
  const [perfilId, setPerfilId] = useState();
  const [listaPerfis, setListaPerfis] = useState([]);
  const [carregandoPerfis, setCarregandoPerfis] = useState(false);
  const [colaboradorId, setColaboradorId] = useState();
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState();
  const [valorCnpj, setValorCnpj] = useState();
  const [emailUnico, setEmailUnico] = useState(false);
  const [unidadeId, setUnidadeId] = useState([]);
  const [empresaId, setEmpresaId] = useState(null);
  const [clientes, setClientes] = useState([]);
  const [listaUnidade, setListaUnidade] = useState([]);
  const [listaOfficer, setListaOfficer] = useState([]);
  const [carregandoOfficer, setCarregandoOfficer] = useState(false);
  const [erroColaborador, setErroColaborador] = useState();
  const [modalCpfExistente, setModalCpfExistente] = useState(false);
  const [modalEmailExistente, setModalEmailExistente] = useState(false);
  const [modalUserNameExistente, setModalUserNameExistente] = useState(false);
  const [idUsuarioExistente, setIdUsuarioExistente] = useState(0);
  const [limparCpf, setLimparCpf] = useState(false);
  const [listaEmpresa, setListaEmpresa] = useState([]);
  const [listaCliente, setListaCliente] = useState([]);
  const [carregandoEmpresa, setCarregandoEmpresa] = useState(false);
  const [carregandoCliente, setCarregandoCliente] = useState(false);
  const [carregandoUnidade, setCarregandoUnidade] = useState(false);
  const [carregandoEnviarEmail, setCarregandoEnviarEmail] = useState();
  const [tipoSelecionado, setTipoSelecionado] = useState(0);
  const [cnpjCpf, setCnpjCpf] = useState("");
  const tipoEstaSelecionado = (tipoEstado) => {
    return !!tipoSelecionado && String(tipoSelecionado) === String(tipoEstado);
  };
  const [criouSenha, setCriouSenha] = useState("Não");
  const [ultimoAcesso, setUltimoAcesso] = useState("");
  const [selecionarTodasUnidades, setSelecionarTodasUnidades] = useState(false);
  const [ultimoAcessoBiometria, setUltimoAcessoBiometria] = useState("");
  const [clienteBusca, setClienteBusca] = useState("");
  const [temUnidades, setTemUnidades] = useState(false);

  // #region useMemo
  const tipoUsuarioLogado = useMemo(() => {
    if (!usuarioLogado) return null;

    return usuarioLogado.tipoPerfil;
  }, [usuarioGlobal]);

  const opcoesTipoUsuario = useMemo(() => {
    if (!tiposUsuariosLista) return [];

    return tiposUsuariosLista.map(
      (tipoUsuario) =>
        new RadioDto(
          tipoUsuario.nome,
          tipoUsuario.valor,
          tipoEstaSelecionado(tipoUsuario.valor),
          disabledCampoTipoUsuario ||
            (String(tipoUsuario.valor) ===
              String(usuarioTipoEnum.colaborador) &&
              disabledColaborador)
        )
    );
  }, [
    tiposUsuariosLista,
    disabledColaborador,
    disabledCampoTipoUsuario,
    tipoSelecionado
  ]);

  const clientesSelecionados = useMemo(() => {
    if (!listaCliente || !clientes) return [];

    const selecionados = listaCliente.filter(
      (x) => clientes.findIndex((y) => y.clienteId === x.clienteId) >= 0
    );

    return selecionados;
  }, [clientes, temUnidades]);

  const unidadesSelecionadas = useMemo(() => {
    if (!listaUnidade || !unidadeId) return [];

    const selecionadas = listaUnidade.filter(
      (x) => unidadeId.findIndex((y) => y.id === x.id) >= 0
    );

    return selecionadas;
  }, [unidadeId, listaUnidade]);

  const onChangeSituacao = (valor) => {
    setAtivo(valor);
  };

  const onChangePerfil = (perfil) => {
    setPerfilId(perfil.target.value);
  };

  const obterPerfis = async (tipoInformado) => {
    setCarregandoPerfis(true);

    const retorno = await UsuarioHelper.obterPerfis(tipoInformado);

    if (retorno.sucesso) setListaPerfis(retorno.data.perfilResponseModels);
    else AlertaUtil.alertaErro(retorno.mensagem);

    setCarregandoPerfis(false);
  };

  const onChangeOfficer = (colaborador) => {
    setColaboradorId(colaborador?.id ?? 0);
  };

  const onChangeFuncionarioCliente = async (event) => {
    const tipoCliente = event.target.value;
    setTipoSelecionado(parseInt(tipoCliente, 10));
    await obterPerfis(tipoCliente);
  };

  const onChangeEmail = (valor) => {
    setEmail(valor.currentTarget.value);
  };

  const selecionarUnidadesTodos = (todos) => {
    setUnidadeId(todos);
  };

  const obterOfficer = async (empresa) => {
    if (
      tipoSelecionado === 0 ||
      String(tipoSelecionado) !== String(usuarioTipoEnum.colaborador)
    )
      return;

    try {
      setCarregandoOfficer(true);
      const lista = await listarColaboradoresSemPaginacao(empresa);
      if (lista?.status === 200 && lista?.data) {
        setListaOfficer(lista?.data);
      }
      setCarregandoOfficer(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoOfficer(false);
    }
  };

  const obterCPF = (dados) => {
    return (
      dados?.CnpjCpf?.replace(/[^a-zA-Z0-9]/g, "") ||
      valorCnpj?.replace(/[^a-zA-Z0-9]/g, "")
    );
  };

  const enviarFormulario = async (dados) => {
    const cpfFormatado = obterCPF(dados);

    const telefoneFormatado = dados.telefone?.replace(/[^a-zA-Z0-9]/g, "");

    const colaboradorSelecionado = listaOfficer?.filter(
      (x) => String(x.id) === String(colaboradorId)
    );

    try {
      const clientesUnidades = clientes.map((cliente) => {
        return {
          clienteId: cliente.clienteId,
          unidadesId: unidadeId
            .filter(
              (unidade) =>
                unidade.clienteId === cliente.clienteId &&
                unidade.id !== -1 &&
                unidade.id
            )
            .map((u) => {
              return u.id;
            })
        };
      });

      const salvou = await salvarUsuario(id ?? 0, {
        ...dados,
        CnpjCpf: cpfFormatado,
        Senha: "",
        ColaboradorId:
          tipoSelecionado === usuarioTipoEnum.colaborador && colaboradorId
            ? colaboradorId
            : 0,
        Nome: dados.Nome ?? colaboradorSelecionado[0]?.nomeColaborador,
        Ativo: id ? ativo : true,
        PerfilId: Number(perfilId),
        ValidoAte: new Date(2090, 12, 31),
        Master: true,
        EmpresaId:
          tipoSelecionado === usuarioTipoEnum.colaborador &&
          colaboradorSelecionado
            ? colaboradorSelecionado[0]?.arvoreGerencialEmpresa[0]
                ?.empresaGrupoDeltaId
            : empresaId,
        clienteUnidades: clientesUnidades,
        Telefone: telefoneFormatado,
        TipoPerfil: tipoSelecionado
      });

      if (salvou?.status === 200 || salvou?.status === 204) {
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: "Usuário salvo com sucesso!"
          })
        );
        setTimeout(() => {
          if (getTipoPerfil(tipo)) {
            store.dispatch(selecionarEmpresa());
            store.dispatch(selecionarCliente());
            store.dispatch(selecionarUnidade());
          }
          history.push({
            pathname: RotasDTO.Usuarios,
            state: {
              dadosUsuario: salvou?.data
            }
          });
        }, 2000);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            error?.response?.data[Object.keys(error?.response?.data)[0]][0] ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
    setCarregandoUsuario(false);
  };

  const validarColaborador = () => {
    setErroColaborador(
      !colaboradorId && tipoSelecionado === usuarioTipoEnum.colaborador
    );
  };

  const aoEnviarFormulario = (dados) => {
    if (!erroColaborador) {
      setErroColaborador(false);
      setCarregandoUsuario(true);
      enviarFormulario(dados);
    }
  };

  const onConfirmarTelaEdicao = () => {
    history.push(`${RotasDTO.Usuarios}/cadastro/${idUsuarioExistente}`);
    setModalCpfExistente(false);
    setModalUserNameExistente(false);
    setModalEmailExistente(false);
  };

  const verificarUserName = async (valor) => {
    try {
      if (
        !valor.target.value ||
        (dadosUsuario?.usuarioNome === valor.target.value ?? false)
      ) {
        return;
      }

      if (
        (!dadosUsuario && valor?.target.value.length > 1) ||
        (dadosUsuario && dadosUsuario?.usuarioNome !== valor?.target.value)
      ) {
        const usuario = await buscarPorUserName(valor?.target.value);
        if (
          idUsuarioExistente === 0 &&
          usuario?.status === 200 &&
          usuario?.data &&
          usuario?.data?.id
        ) {
          if (
            String(tipoUsuarioLogado) === String(usuarioTipoEnum.colaborador)
          ) {
            setModalUserNameExistente(true);
            setIdUsuarioExistente(usuario?.data?.id);
          }
        }
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  const verificarCnpj = async (valor) => {
    setCnpjCpf(valor.target.value);
    const cpfFormatado = valor?.target.value.replace(/[^a-zA-Z0-9]/g, "");
    try {
      if (
        (cpfFormatado?.length === 11 && !dadosUsuario) ||
        (dadosUsuario && dadosUsuario?.cnpjCpf !== cpfFormatado)
      ) {
        const usuario = await buscarPorCpf(cpfFormatado, tipoSelecionado);
        if (usuario?.status === 200 && usuario?.data.id !== 0) {
          if (
            String(tipoUsuarioLogado) === String(usuarioTipoEnum.colaborador)
          ) {
            setModalCpfExistente(true);
            setIdUsuarioExistente(usuario?.data?.id);
          }
        }
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  const verificarEmail = async (valor) => {
    try {
      setEmail(valor.target.value);

      if (
        !valor.target.value ||
        (dadosUsuario?.email === valor.target.value ?? false)
      ) {
        setEmailUnico(true);
        return;
      }

      const usuario = await buscarPorEmail(valor.target.value);
      if (idUsuarioExistente === 0 && usuario?.data?.id) {
        setModalEmailExistente(true);
        setEmailUnico(false);
        setIdUsuarioExistente(usuario?.data?.id);
      } else {
        setEmailUnico(true);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  const obterEmpresa = async () => {
    try {
      setCarregandoEmpresa(true);
      const lista = await listarEmpresasGrupoDeltaCombo();
      if (lista?.status === 200 && lista?.data) {
        setListaEmpresa(lista?.data?.empresasGrupoDelta);
      }
      setCarregandoEmpresa(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoEmpresa(false);
    }
  };

  const selecionarClientes = (item) => {
    const quemEntrou = item.find(
      (i) => clientes.findIndex((u) => u.clienteId === i.clienteId) === -1
    );
    const quemSaiu = clientes.find(
      (u) => item.findIndex((i) => i.clienteId === u.clienteId) === -1
    );

    if (item.length === 0) {
      setClientes([]);
      setListaUnidade([]);
      selecionarUnidades([]);
      return;
    }

    if (quemSaiu) {
      setClientes((oldState) => oldState.filter((x) => x !== quemSaiu));
      const listaUnidadeAtualizada = listaUnidade.filter(
        (u) => u.clienteId !== quemSaiu.clienteId && u.id !== -1
      );
      setListaUnidade(listaUnidadeAtualizada);
    } else {
      setClientes(item);
      obterUnidade(quemEntrou.clienteId);
    }
  };

  const selecionarUnidades = (item) => {
    const todosCampo = item?.findIndex((x) => x.id === -1) >= 0;
    const todosLista = unidadeId?.findIndex((x) => x.id === -1) >= 0;

    const quemEntrou = item.find(
      (i) => unidadeId.findIndex((u) => u.id === i.id) === -1
    );
    const quemSaiu = unidadeId.find(
      (u) => item.findIndex((i) => i.id === u.id) === -1
    );

    const selecionarTodasOpcoes = quemEntrou?.id === -1;
    const removerTodasOpcoes = quemSaiu?.id === -1;
    const selecionarOpcaoETodos =
      !todosCampo &&
      !todosLista &&
      item.length === listaUnidade.filter((x) => x.id !== -1).length;
    const removerOpcaoTodos = todosCampo && quemSaiu !== -1;

    if (selecionarTodasOpcoes || selecionarOpcaoETodos)
      selecionarUnidadesTodos(listaUnidade);
    else if (removerOpcaoTodos)
      setUnidadeId((oldState) =>
        oldState.filter((x) => x.id !== -1 && x.id !== quemSaiu.id)
      );
    else if (removerTodasOpcoes) setUnidadeId([]);
    else setUnidadeId(item);
  };

  const obterCliente = async (empresa = null, validarUnidades = false) => {
    try {
      setCarregandoCliente(true);
      const lista = await listarClientesComboFiltro(
        empresa || empresaId,
        clienteBusca
      );
      if (lista?.status === 200 && lista?.data) {
        const listaEditada = lista?.data?.clientes?.map((x) => {
          return { clienteId: x.id, nome: x.nomeFantasia };
        });
        setListaCliente(listaEditada);
      }
      if (validarUnidades) setTemUnidades(true);
      setCarregandoCliente(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoCliente(false);
    }
  };

  const obterUnidade = async (idCliente) => {
    try {
      if (tipoSelecionado === usuarioTipoEnum.colaborador || !idCliente) return;

      setCarregandoUnidade(true);
      const lista = await listarUnidadesConsumidorasCombo(idCliente);

      if (lista?.status === 200 && lista?.data) {
        const listaEditada = lista?.data?.unidadeConsumidoraResponseList?.map(
          (x) => {
            return { id: x.id, nome: x.nomeUnidade, clienteId: idCliente };
          }
        );
        setListaUnidade((prevState) => [
          ...prevState.filter((u) => u.id !== -1),
          ...listaEditada
        ]);
        if (selecionarTodasUnidades)
          selecionarUnidades([
            ...unidadeId.filter((u) => u.id !== -1),
            ...listaEditada
          ]);
      }
      setCarregandoUnidade(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoUnidade(false);
    }
  };

  const verificarSenhaLogin = async (usuarioId) => {
    try {
      setCarregandoUsuario(true);
      const respostaSenhaUsuario = await verificarSenhaUsuario(usuarioId);
      const respostaLoginUsuario = await obterUltimoLoginUsuario(usuarioId);
      const respostaAcessoBiometria = await verificarAcessoBiometria(usuarioId);
      if (respostaAcessoBiometria.status === 200) {
        setUltimoAcessoBiometria(
          respostaAcessoBiometria?.data?.ultimoUsuarioAcessoBiometria
            ?.dataAcessoBiometria
        );
      }
      if (respostaSenhaUsuario?.status === 200 && respostaSenhaUsuario?.data) {
        setCriouSenha(respostaSenhaUsuario?.data?.senhaCriada);
        if (respostaSenhaUsuario?.data?.senhaCriada !== "Sim") {
          const ultimoAcessoData = moment(
            respostaSenhaUsuario?.data?.chaveAcessoData
          ).add(48, "hours");
          setUltimoAcesso(ultimoAcessoData);
        }
      }
      if (respostaLoginUsuario?.status === 200 && respostaLoginUsuario?.data) {
        if (respostaSenhaUsuario?.data?.senhaCriada === "Sim") {
          setUltimoAcesso(respostaLoginUsuario.data);
        }
      }
      setCarregandoUsuario(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoUsuario(false);
    }
  };

  const enviarEmailAcesso = async () => {
    setCarregandoEnviarEmail(true);
    const resultado = await UsuarioHelper.aoEnviarEmailAcesso(
      id,
      empresaId,
      email
    );

    if (!resultado.sucesso || resultado?.data?.message) {
      UsuarioHelper.exibirErro(resultado.mensagem);
      setCarregandoEnviarEmail(false);
      return;
    }

    UsuarioHelper.exibirSucesso(resultado.mensagem);
    setCarregandoEnviarEmail(false);
  };

  const obterTiposUsuarioLista = useCallback(async () => {
    try {
      setCarregandoTipos(true);
      setDisabledCampoTipoUsuario(true);

      const retornoTipos = await UsuarioHelper.obterTipoPerfis();

      if (!retornoTipos.sucesso) {
        UsuarioHelper.exibirErro(retornoTipos.mensagem);
        return;
      }

      setTiposUsuarioLista(retornoTipos.data);
    } catch (error) {
      UsuarioHelper.exibirErro();
    } finally {
      setCarregandoTipos(false);
      setDisabledCampoTipoUsuario(false);
    }
  }, []);

  const verificarTipoUsuario = useCallback(async () => {
    if (!id && tipoUsuarioLogado !== usuarioTipoEnum.colaborador) {
      setDisabledColaborador(true);
      setTipoSelecionado(usuarioTipoEnum.cliente);
      await obterPerfis(usuarioTipoEnum.cliente);
    } else if (
      id &&
      tipoUsuarioLogado !== usuarioTipoEnum.colaborador &&
      tipoSelecionado === usuarioTipoEnum.colaborador
    ) {
      setDisabledCampoTipoUsuario(true);
      setDisabledBotaoSalvar(true);
    } else {
      setDisabledCampoTipoUsuario(false);
      setDisabledBotaoSalvar(false);
      setDisabledColaborador(false);
    }
  }, [tipoUsuarioLogado]);

  const obterUsuario = useCallback(async (idUsuario) => {
    try {
      setCarregandoUsuario(true);
      const usuario = await (permissaoVisualizarUsuarios
        ? buscarPorIdClienteProspectLiberado(idUsuario)
        : buscarPorId(idUsuario));

      if (usuario?.status === 200 && usuario?.data?.usuario) {
        const perfilBuscar = !usuario.data
          ? usuarioTipoEnum.colaborador
          : usuario.data.usuario.tipoPerfil;

        await obterPerfis(perfilBuscar);

        if (!usuario.data) return;

        let clientesUniades = [];
        const unidades = [];

        if (usuario?.data?.usuarioUnidade?.clienteUnidades?.length) {
          clientesUniades = usuario?.data?.usuarioUnidade?.clienteUnidades.map(
            (item) => {
              obterUnidade(item.clienteId);
              item.unidadesId.forEach((unidade) =>
                unidades.push({ clienteId: item.clienteId, id: unidade })
              );
              return item;
            }
          );
        }

        setTipoSelecionado(usuario.data.usuario.tipoPerfil);
        setColaboradorId(usuario.data.usuario.colaboradorId);
        setEmpresaId(usuario?.data?.usuarioUnidade?.empresaGrupoDeltaId);
        setClientes(clientesUniades);
        setUnidadeId(unidades);
        setDadosUsuario(usuario?.data?.usuario);
        setPerfilId(usuario?.data?.usuario.profileId);
        setAtivo(usuario?.data?.usuario.ativo);
        setEmail(usuario?.data?.usuario.email);
        setUserName(usuario?.data?.usuario.usuarioNome);
        setValorCnpj(usuario?.data?.usuario.cnpjCpf);
        setEmailUnico(true);
        setUsuarioUnidade(usuario.data.usuarioUnidade);
        setSelecionarTodasUnidades(true);

        if (
          usuario?.data?.usuarioUnidade?.empresaGrupoDeltaId !== 0 &&
          usuario?.data?.usuarioUnidade?.empresaGrupoDeltaId !== null &&
          id
        )
          obterCliente(
            usuario?.data?.usuarioUnidade?.empresaGrupoDeltaId,
            true
          );
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    } finally {
      setCarregandoUsuario(false);
    }
  }, []);

  useEffect(() => {
    obterTiposUsuarioLista();
  }, [obterTiposUsuarioLista]);

  useEffect(() => {
    if (tipoSelecionado > 0) {
      obterPerfis(tipoSelecionado);
      obterOfficer(usuarioGlobal?.empresaSelecionada);
    }
  }, [tipoSelecionado]);

  useEffect(() => {
    verificarTipoUsuario();
  }, [verificarTipoUsuario]);

  useEffect(() => {
    obterOfficer(usuarioGlobal?.empresaSelecionada);
  }, [usuarioGlobal?.empresaSelecionada]);

  useEffect(() => {
    if (id) {
      obterUsuario(id);
      verificarSenhaLogin(id);

      const possuiFuncionalidadeEnviarEmail = usuarioPossuiFuncionalidade(
        menu,
        rotas.rotaAtual.caminho,
        RotasDTO.Usuarios,
        InterfaceDTO.EnviarEmailAcesso
      );
      setMostrarEnviarEmail(possuiFuncionalidadeEnviarEmail);

      const possuiFuncionalidadeAlterarCpf = usuarioPossuiFuncionalidade(
        menu,
        rotas.rotaAtual.caminho,
        RotasDTO.Usuarios,
        InterfaceDTO.PermissaoAlterarCpfCnpj
      );
      setDisabledCpf(!possuiFuncionalidadeAlterarCpf);

      const possuiFuncionalidadeAlterarCampos = usuarioPossuiFuncionalidade(
        menu,
        rotas.rotaAtual.caminho,
        RotasDTO.Usuarios,
        InterfaceDTO.PodeEditar
      );
      setDisabilitaCampos(!possuiFuncionalidadeAlterarCampos);

      const possuiFuncionalidadeVerPerfil = usuarioPossuiFuncionalidade(
        menu,
        rotas.rotaAtual.caminho,
        RotasDTO.Usuarios,
        InterfaceDTO.PermissaoVerPerfil
      );
      setMostrarPerfil(possuiFuncionalidadeVerPerfil);

      const possuiFuncionalidadeVerRadioGroup = usuarioPossuiFuncionalidade(
        menu,
        rotas.rotaAtual.caminho,
        RotasDTO.Usuarios,
        InterfaceDTO.PermissaoVerRadioGroup
      );
      setMostrarRadioGroup(possuiFuncionalidadeVerRadioGroup);

      const possuiFuncionalidadeVerStatus = usuarioPossuiFuncionalidade(
        menu,
        rotas.rotaAtual.caminho,
        RotasDTO.Usuarios,
        InterfaceDTO.PermissaoVerStatus
      );
      setMostrarStatus(possuiFuncionalidadeVerStatus);
    } else {
      const possuiFuncionalidadeVerPerfil = usuarioPossuiFuncionalidade(
        menu,
        rotas.rotaAtual.caminho,
        RotasDTO.Usuarios,
        InterfaceDTO.PermissaoVerPerfil
      );
      setMostrarPerfil(possuiFuncionalidadeVerPerfil);

      const possuiFuncionalidadeVerRadioGroup = usuarioPossuiFuncionalidade(
        menu,
        rotas.rotaAtual.caminho,
        RotasDTO.Usuarios,
        InterfaceDTO.PermissaoVerRadioGroup
      );
      setMostrarRadioGroup(possuiFuncionalidadeVerRadioGroup);
      setSelecionarTodasUnidades(true);
    }
  }, [id, obterUsuario]);

  useEffect(() => {
    if (getTipoPerfil(tipo)) {
      if (!id || colaboradorId || !usuarioUnidade?.empresaGrupoDeltaId) return;

      store.dispatch(selecionarEmpresa(usuarioUnidade.empresaGrupoDeltaId));
      store.dispatch(selecionarCliente(usuarioUnidade.clienteId));
      store.dispatch(selecionarUnidade(usuarioUnidade.unidadeId));
    }
  }, [usuarioUnidade]);

  useEffect(() => {
    if (getTipoPerfil(tipo)) {
      store.dispatch(desabilitarEmpresa(true));
      store.dispatch(desabilitarCliente(true));
      store.dispatch(desabilitarUnidade(true));

      return () => {
        store.dispatch(desabilitarEmpresa(false));
        store.dispatch(desabilitarCliente(false));
        store.dispatch(desabilitarUnidade(false));
      };
    }

    return false;
  }, [desabilitarEmpresa, desabilitarCliente, desabilitarUnidade]);

  useEffect(() => {
    if (getTipoPerfil(tipo)) {
      if (id) return;

      setUsuarioUnidade({
        empresaGrupoDeltaId: usuarioGlobal?.empresaSelecionada,
        unidadeId: usuarioGlobal?.unidadeSelecionada,
        clienteId: usuarioGlobal?.clienteSelecionado,
        variasUnidades: !usuarioGlobal?.unidadeSelecionada
      });
    }
  }, [usuarioGlobal]);

  useEffect(() => {
    obterEmpresa();
  }, []);

  useEffect(() => {
    if (empresaId !== 0 && empresaId !== null && clienteBusca?.length > 2)
      obterCliente();
  }, [clienteBusca]);

  return (
    <Loader loading={carregandoUsuario}>
      <>
        <ModalConfirmacao
          item={modalCpfExistente}
          onConfirmar={() => onConfirmarTelaEdicao()}
          mensagem="CPF já cadastrado. Deseja ir para a tela de edição?"
          onCancelar={() => {
            setModalCpfExistente(false);
            setLimparCpf(true);
            setIdUsuarioExistente(0);
          }}
        />
        <ModalConfirmacao
          item={modalEmailExistente}
          onConfirmar={() => onConfirmarTelaEdicao()}
          mensagem="Email já cadastrado. Deseja ir para a tela de edição?"
          onCancelar={() => {
            setModalEmailExistente(false);
            setEmail("");
            setIdUsuarioExistente(0);
          }}
        />
        <ModalConfirmacao
          item={modalUserNameExistente}
          onConfirmar={() => onConfirmarTelaEdicao()}
          mensagem="UserName já cadastrado. Deseja ir para a tela de edição?"
          onCancelar={() => {
            setModalUserNameExistente(false);
            setUserName("");
            setIdUsuarioExistente(0);
          }}
        />
        <form
          className="needs-validation"
          onSubmit={handleSubmit(aoEnviarFormulario)}
        >
          <Card className={classes.cardCadastro}>
            <Grid
              container
              p={2}
              spacing={4}
              className={classes.containerEdicaoUsuario}
            >
              <Grid item xs={9} className="font-weight-bold">
                {`${dadosUsuario?.id ? "Editar " : "Novo"} usuário`}
              </Grid>
              <Grid item xs={3}>
                <Grid container justifyContent="flex-end">
                  <BotaoRetornarListagem
                    urlListagem={{
                      pathname: RotasDTO.Usuarios,
                      state: { dadosUsuario }
                    }}
                    zerarCombo={getTipoPerfil(tipo)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              p={2}
              spacing={4}
              className={`${
                mostrarRadioGroup ? `${classes.container}` : "d-none"
              }`}
            >
              <Grid item xs={12} className={classes.inputRadio}>
                <Loader loading={carregandoTipos}>
                  <InputRadioGroup
                    type="text"
                    id="funcionarioCliente"
                    name="Funcionario"
                    label="Tipo de Usuário"
                    renderIconShowHide={false}
                    disabled={disabledColaborador || disabilitaCampos}
                    customValue={tipoSelecionado}
                    onChange={(valor) => onChangeFuncionarioCliente(valor)}
                    defaultValue={tipoSelecionado}
                    ref={register({
                      required: "Campo Tipo de Usuário é obrigatório!"
                    })}
                    errors={errors}
                    className={classes.inputRadio}
                    classNameLabel={classes.inputRadioLabel}
                    itens={opcoesTipoUsuario}
                  />
                </Loader>
              </Grid>
            </Grid>

            {tipoSelecionado !== 0 ? (
              <>
                {tipoSelecionado !== usuarioTipoEnum.colaborador ? (
                  <>
                    <Divider
                      className={`${
                        mostrarRadioGroup ? `${classes.divider}` : "d-none"
                      }`}
                    />
                    <Grid
                      container
                      p={2}
                      spacing={4}
                      className={`${
                        mostrarRadioGroup ? `${classes.container}` : "d-none"
                      }`}
                    >
                      <Grid item xs={9} className={classes.tituloStrong}>
                        Empresa
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      p={2}
                      spacing={4}
                      className={`${
                        mostrarRadioGroup ? `${classes.container}` : "d-none"
                      }`}
                    >
                      <Grid
                        item
                        lg={3}
                        md={4}
                        sm={6}
                        xs={12}
                        className={classes.contentEmpresa}
                      >
                        <Loader loading={carregandoEmpresa}>
                          <SelectAutoComplete
                            disableClearable
                            disableOpenOnFocus
                            id="empresaId"
                            name="empresa"
                            options={listaEmpresa.filter(
                              (empresa) => empresa.nomeFantasia
                            )}
                            label="Empresa"
                            getOptionLabel={(option) => option.nomeFantasia}
                            onChange={(e, item) => {
                              setClientes([]);
                              setUnidadeId([]);
                              setListaCliente([]);
                              setListaUnidade([]);
                              setEmpresaId(item?.id ?? 0);
                              setClienteBusca("");
                            }}
                            value={empresaId}
                            ref={register(
                              { name: "empresaId" },
                              {
                                required:
                                  String(tipoUsuarioLogado) ===
                                    String(usuarioTipoEnum.colaborador) &&
                                  !empresaId
                                    ? "Campo Empresa é obrigatório!"
                                    : false
                              }
                            )}
                            error={errors}
                            allowClear
                            disabled={disabilitaCampos}
                            valueName="nomeFantasia"
                          />
                        </Loader>
                      </Grid>
                      <Grid
                        item
                        lg={3}
                        md={4}
                        sm={6}
                        xs={12}
                        className={classes.contentEmpresa}
                      >
                        <Loader loading={carregandoCliente}>
                          <SelectAutocompleteCheckboxAsync
                            id="clienteId"
                            name="cliente"
                            options={listaCliente}
                            label="Cliente"
                            getOptionLabel={(option) => option.nome}
                            onChange={(e, item, reason) => {
                              selecionarClientes(item);
                              setClienteBusca("");
                              if (reason === "clear") setListaCliente([]);
                            }}
                            onChangeSelect={(e) => {
                              if (!e?.target?.value) setListaCliente([]);
                              setClienteBusca(e?.target?.value);
                            }}
                            value={clientesSelecionados}
                            inputValue={clienteBusca}
                            disabled={disabilitaCampos}
                            defaultValue={clientesSelecionados}
                            ref={register(
                              { name: "clienteId" },
                              {
                                required:
                                  String(tipoUsuarioLogado) ===
                                    String(usuarioTipoEnum.colaborador) &&
                                  !clientes.length
                                    ? "Campo Cliente é obrigatório!"
                                    : false
                              }
                            )}
                            error={errors}
                            selecionarTodos={false}
                          />
                        </Loader>
                      </Grid>
                      {tipoSelecionado !== usuarioTipoEnum.colaborador && (
                        <Grid
                          item
                          lg={3}
                          md={4}
                          sm={6}
                          xs={12}
                          className={classes.contentEmpresa}
                        >
                          <Loader loading={carregandoUnidade}>
                            <SelectAutocompleteCheckbox
                              disableClearable
                              disableOpenOnFocus
                              id="unidadeId"
                              name="unidade"
                              options={listaUnidade}
                              label="Unidade"
                              getOptionLabel={(option) => option.nome}
                              onChange={(e, item) => {
                                selecionarUnidades(item);
                              }}
                              value={unidadesSelecionadas}
                              disabled={
                                String(tipoSelecionado) !==
                                  String(usuarioTipoEnum.cliente) ||
                                disabilitaCampos
                              }
                              defaultValue={unidadesSelecionadas}
                              error={errors}
                              allowClear
                            />
                          </Loader>
                        </Grid>
                      )}
                    </Grid>
                  </>
                ) : null}
                <Divider className={classes.divider} />
                <Grid container p={2} spacing={4} className={classes.container}>
                  <Grid item xs={9} className={classes.tituloStrong}>
                    Dados Cadastrais
                  </Grid>
                </Grid>
                <Grid container p={2} spacing={4} className={classes.container}>
                  <Grid item lg={3} md={4} xs={12} className="">
                    <MaterialInputMascara
                      type="text"
                      id="CnpjCpf"
                      name="CnpjCpf"
                      label="CPF"
                      mask="###.###.###-##"
                      disabled={disabledCpf || disabilitaCampos}
                      renderIconShowHide={false}
                      defaultValue={dadosUsuario?.cnpjCpf}
                      limparValor={limparCpf}
                      redefineLimpar={(estado) => setLimparCpf(estado)}
                      onBlur={(valor) => verificarCnpj(valor)}
                      ref={register({
                        required:
                          tipoSelecionado === 3
                            ? false
                            : "Campo CPF é obrigatório!",
                        maxLength: {
                          value: 14,
                          message: "Quantidade máxima de 14 caracteres!"
                        },
                        validate:
                          tipoSelecionado !== 3 || cnpjCpf
                            ? (value) => validarCpfCnpj(value)
                            : () => {}
                      })}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item lg={3} md={4} xs={12} className="">
                    {tipoSelecionado === usuarioTipoEnum.colaborador ? (
                      <Loader loading={carregandoOfficer}>
                        <SelectAutoComplete
                          disableClearable
                          disableOpenOnFocus
                          id="ColaboradorId"
                          name="ColaboradorId"
                          options={listaOfficer}
                          label="Nome do colaborador"
                          getOptionLabel={(option) =>
                            option?.nomeColaborador ?? ""
                          }
                          onChange={(e, item) => onChangeOfficer(item)}
                          value={colaboradorId}
                          ref={register(
                            { name: "ColaboradorId" },
                            {
                              required:
                                erroColaborador &&
                                tipoSelecionado === usuarioTipoEnum.colaborador
                                  ? "Campo Colaborador é obrigatório!"
                                  : false
                            }
                          )}
                          disabled={disabilitaCampos}
                          error={errors}
                          allowClear
                          valueName="nomeColaborador"
                        />
                      </Loader>
                    ) : (
                      <MaterialInputTexto
                        type="text"
                        id="Nome"
                        name="Nome"
                        label="Nome do usuário"
                        renderIconShowHide={false}
                        defaultValue={dadosUsuario?.nome}
                        ref={register({
                          required:
                            tipoSelecionado !== usuarioTipoEnum.colaborador
                              ? "Campo Nome é obrigatório!"
                              : false,
                          maxLength: {
                            value: 50,
                            message: "Quantidade máxima de 50 caracteres!"
                          }
                        })}
                        errors={errors}
                        disabled={disabilitaCampos}
                      />
                    )}
                  </Grid>
                  <Grid item lg={3} md={4} xs={12} className="">
                    <MaterialInputTexto
                      type="text"
                      id="UsuarioNome"
                      name="UsuarioNome"
                      label="Username"
                      renderIconShowHide={false}
                      defaultValue={userName}
                      onBlur={(valor) => verificarUserName(valor)}
                      permiteValorBranco
                      ref={register({
                        required: "Campo Username é obrigatório!",
                        maxLength: {
                          value: 50,
                          message: "Quantidade máxima de 50 caracteres!"
                        },
                        validate: (value) =>
                          !existeAcento(value) ||
                          "Campo Username não pode ter acento!"
                      })}
                      errors={errors}
                      disabled={disabilitaCampos}
                    />
                  </Grid>
                </Grid>
                <Grid container p={2} spacing={4} className={classes.container}>
                  <Grid item lg={3} md={4} xs={12} className="">
                    <MaterialInputTexto
                      type="text"
                      id="Email"
                      name="Email"
                      label="E-mail"
                      renderIconShowHide={false}
                      onBlur={(valor) => verificarEmail(valor)}
                      onChange={(valor) => onChangeEmail(valor)}
                      defaultValue={dadosUsuario?.email}
                      value={email}
                      ref={register({
                        required: "O campo E-mail é obrigatório!",
                        pattern: {
                          value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: "E-mail é inválido!"
                        },
                        maxLength: {
                          value: 100,
                          message: "Quantidade máxima de 100 caracteres!"
                        },
                        validate: () => emailUnico || "Email já cadastrado!"
                      })}
                      errors={errors}
                      disabled={disabilitaCampos}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={3}
                    md={4}
                    xs={12}
                    className={classes.containerTelefone}
                  >
                    <MaterialInputTelefone
                      type="text"
                      id="telefone"
                      name="telefone"
                      label="Telefone"
                      renderIconShowHide={false}
                      defaultValue={dadosUsuario?.telefone}
                      value={dadosUsuario?.telefone}
                      ref={register({
                        required: "O campo Telefone é obrigatório!",
                        minLength: {
                          value: 18,
                          message: "Quantidade mínima de 12 caracteres!"
                        }
                      })}
                      errors={errors}
                      disabled={disabilitaCampos}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={3}
                    md={4}
                    xs={12}
                    className={`${
                      mostrarPerfil ? `${classes.container}` : "d-none"
                    }`}
                  >
                    <Loader loading={carregandoPerfis}>
                      <SelectArredondado
                        id="PerfilId"
                        marginBottom="0"
                        name="PerfilId"
                        valueKey="id"
                        valueName="nome"
                        dataSource={listaPerfis}
                        label="Perfil"
                        value={perfilId}
                        onChange={(perfil) => onChangePerfil(perfil)}
                        placeholder="Perfil"
                        allowClear
                        ref={register(
                          { name: "PerfilId" },
                          {
                            required:
                              mostrarPerfil && !perfilId
                                ? "Campo Perfil é obrigatório!"
                                : false
                          }
                        )}
                        errors={errors}
                        disabled={disabilitaCampos}
                      />
                    </Loader>
                  </Grid>
                </Grid>
                <Grid container p={2} spacing={4} className={classes.container}>
                  <Grid
                    item
                    lg={3}
                    md={4}
                    xs={12}
                    className={classes.contentEmpresa}
                  >
                    <MaterialInputTexto
                      type="text"
                      id="criouSenha"
                      name="criouSenha"
                      label="Usuário criou senha?"
                      renderIconShowHide={false}
                      defaultValue={criouSenha}
                      disabled
                    />
                  </Grid>
                  <Grid item lg={3} md={4} xs={12}>
                    <InputData
                      type="text"
                      id="acesso"
                      name="acesso"
                      label={
                        criouSenha === "Sim"
                          ? "Quando foi o último acesso?"
                          : "Quando a chave expira?"
                      }
                      customValue={ultimoAcesso}
                      format="dd/MM/yyyy HH:mm"
                      renderIconShowHide={false}
                      className={classes}
                      InputProps={{ readOnly: true }}
                      variant="inline"
                      disabled
                    />
                  </Grid>
                  <Grid item lg={3} md={4} xs={12}>
                    <InputData
                      type="text"
                      id="acessoBiometria"
                      name="acessoBiometria"
                      label="Quando foi o último acesso com biometria?"
                      customValue={ultimoAcessoBiometria}
                      format="dd/MM/yyyy HH:mm"
                      renderIconShowHide={false}
                      className={classes}
                      InputProps={{ readOnly: true }}
                      variant="inline"
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  p={2}
                  spacing={4}
                  className={`${
                    mostrarEnviarEmail ? `${classes.container}` : "d-none"
                  }`}
                >
                  <Grid item lg={3} md={5} sm={6} xs={12}>
                    <Loader loading={carregandoEnviarEmail}>
                      <Botao
                        onClick={() => enviarEmailAcesso()}
                        label="Enviar e-mail de acesso"
                        className={classes.button}
                      />
                    </Loader>
                  </Grid>
                </Grid>
                {dadosUsuario ? (
                  <Grid
                    container
                    p={2}
                    spacing={4}
                    className={`${
                      mostrarStatus ? `${classes.container}` : "d-none"
                    }`}
                  >
                    <Grid item lg={3} md={3} sm={3}>
                      <MaterialSwitch
                        label="Ativo"
                        labelPlacement="top"
                        id="Ativo"
                        name="Ativo"
                        ref={register()}
                        checked={!!ativo}
                        onChange={(valor) => onChangeSituacao(valor)}
                        disabled={disabilitaCampos}
                      />
                    </Grid>
                  </Grid>
                ) : null}
                <Grid
                  container
                  p={2}
                  spacing={4}
                  className={classes.containerSalvar}
                >
                  <Grid item lg={3} md={5} sm={6} xs={12}>
                    <Loader loading={carregandoUsuario}>
                      <Botao
                        type="submit"
                        label="Salvar"
                        className={classes.button}
                        disabled={disabledBotaoSalvar || disabilitaCampos}
                        onClick={() => validarColaborador()}
                      />
                    </Loader>
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid
                container
                p={2}
                spacing={4}
                className={classes.containerSalvar}
              />
            )}
          </Card>
        </form>
      </>
    </Loader>
  );
};

export default UsuarioCadastro;
