export default class PerfilAgenteDto {
  constructor(
    Id,
    IdAgenteCCEE,
    SiglaAgenteCCEE,
    SiglaPerfilParticipanteMercado,
    PercentualEnergia,
    DescricaoSituacaoPerfilParticipanteMercado,
    SubMercadoId,
    DescricaoTipoFonteEnergia,
    DescricaoSubTipoFonteEnergia,
    ClassePerfilId,
    IdentificadorComercioVarejista,
    DataInicioVigenciaPerfilParticipante,
    DataFimVigenciaPefilParticipante,
    Ativo,
    UnidadeConsumidoraId,
    CodigoPerfilParticipanteMercado
  ) {
    this.Id = Id;
    this.IdAgenteCCEE = IdAgenteCCEE;
    this.SiglaAgenteCCEE = SiglaAgenteCCEE;
    this.SiglaPerfilParticipanteMercado = SiglaPerfilParticipanteMercado;
    this.PercentualEnergia = PercentualEnergia;
    this.DescricaoSituacaoPerfilParticipanteMercado = DescricaoSituacaoPerfilParticipanteMercado;
    this.SubMercadoId = SubMercadoId;
    this.DescricaoTipoFonteEnergia = DescricaoTipoFonteEnergia;
    this.DescricaoSubTipoFonteEnergia = DescricaoSubTipoFonteEnergia;
    this.ClassePerfilId = ClassePerfilId;
    this.IdentificadorComercioVarejista = IdentificadorComercioVarejista;
    this.DataInicioVigenciaPerfilParticipante = DataInicioVigenciaPerfilParticipante;
    this.DataFimVigenciaPefilParticipante = DataFimVigenciaPefilParticipante;
    this.Ativo = Ativo;
    this.UnidadeConsumidoraId = parseInt(UnidadeConsumidoraId, 10);
    this.CodigoPerfilParticipanteMercado = parseInt(
      CodigoPerfilParticipanteMercado,
      10
    );
  }
}
