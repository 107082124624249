import EnumDto from "../dto/enumDto";

const tipoPlanoEnum = {
  PlanoBronze: new EnumDto(1, "Bronze", "BRONZE"),
  PlanoPrata: new EnumDto(2, "Prata", "PRATA"),
  PlanoOuro: new EnumDto(3, "Ouro", "OURO"),
  PlanoBronzeLivre: new EnumDto(4, "Bronze", "BRONZE"),
  PlanoPrataLivre: new EnumDto(5, "Prata", "PRATA"),
  PlanoOuroLivre: new EnumDto(6, "Ouro", "OURO")
};

export default tipoPlanoEnum;
