import axios from "axios";

// Redux
import lodash from "lodash";
import { store } from "../global/redux";
import { deslogarUsuario } from "../global/redux/modulos/usuario/actions";

import retornaApiUrl from "../global/configuracoes/env";

import { montaAtualizaLog, montaEnviaLog } from "./utilsLogs";

const obterApiLuz = (microServico, log, externo = false) => {
  const apiUrl = retornaApiUrl(microServico);
  let logRequest;
  const { usuario } = store.getState();

  const Api = axios.create({ baseURL: `${apiUrl}/api/` });

  Api.interceptors.request.use(
    async (config) => {
      config.baseURL = `${apiUrl}/api/`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  Api.interceptors.request.use(async (config) => {
    return new Promise((resolve) => setTimeout(() => resolve(config), 200));
  });

  Api.interceptors.request.use(async (config) => {
    logRequest = config?.data;

    const token = usuario?.token ?? false;
    if (externo) {
      config.headers.ApiKey = process.env.REACT_APP_API_KEY_API_URL;
    }
    if (token) {
      config.headers.ApiKey = process.env.REACT_APP_API_KEY_API_URL;
      // config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  Api.interceptors.response.use(
    async (response) => {
      // eslint-disable-next-line camelcase
      const cotacao_id = sessionStorage.getItem("cotacao_id");
      const idDopreLeadNoSalesforce = sessionStorage.getItem(
        "idDoPreLeadNoSalesforce"
      );
      let index = sessionStorage.getItem("indexLog");
      if (index !== undefined && index !== null) {
        // eslint-disable-next-line prefer-template, no-useless-concat
        index = index + "-" + `${process.env.REACT_APP_ENVIRONMENT}`;
        if (
          log?.tipo === "atualizaLog" &&
          // eslint-disable-next-line camelcase
          ((cotacao_id !== undefined && cotacao_id !== null) ||
            (idDopreLeadNoSalesforce !== undefined &&
              idDopreLeadNoSalesforce !== null))
        ) {
          // eslint-disable-next-line camelcase
          const searchField = cotacao_id
            ? "cotacao_id"
            : "idDoPreLeadNoSalesforce";
          // eslint-disable-next-line camelcase
          // eslint-disable-next-line no-unneeded-ternary, camelcase
          const searchContent = cotacao_id
            ? // eslint-disable-next-line no-unneeded-ternary, camelcase
              cotacao_id
            : idDopreLeadNoSalesforce;

          if (log?.dadosFatura?.fatura?.statusCodes === 200) {
            montaAtualizaLog(
              {},
              log?.dadosFatura,
              "upload_arquivo",
              searchField,
              searchContent,
              index
            );
          }
          montaAtualizaLog(
            logRequest,
            response?.data,
            log.nestedField,
            searchField,
            searchContent,
            index
          );
        } else if (log?.tipo === "enviaLog") {
          const mainFields = {};
          mainFields[log.nameMainFields] = lodash.get(
            response.data,
            log.mainFields
          );
          await montaEnviaLog(
            mainFields,
            logRequest,
            response.data.data,
            log.nestedField,
            log.messege,
            index,
            true
          );
        }
      }
      return response;
    },
    (error) => {
      if (error?.response && error?.response?.status === 401) {
        store.dispatch(deslogarUsuario());
        return false;
      }
      return Promise.reject(error);
    }
  );

  return Api;
};

export default obterApiLuz;
