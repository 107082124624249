import MICROSERVICO from "global/enumeradores/microServico";
import obterApi from "servicos/api";

const api = () => obterApi(MICROSERVICO.PROJECAOTARIFARIA);

export const uploadPlanilhaProret = async (arquivo, idUsuario, nomeUsuario) => {
  const data = new FormData();
  data.append("file", arquivo);

  const metodo = "post";
  const url = `v1/ImportPercentRegulat/importa?idUsuario=${idUsuario}&NomeUsuario=${nomeUsuario}`;
  return api()[metodo](url, data);
}

export const obterValoresProret = async () => {
  return api().get('v1/ImportPercentRegulat/visualiza');
}