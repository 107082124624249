const Base = {
  Aurora: "#fa3eb5",
  AuroraMenuBG: "#433261",
  Black: "#000",
  BlackBastille: "#2A2B32",
  BlackCurrant: "#0D0010",
  BlackLuz: "#262938",
  BlackOpacity20: "rgba(0, 0, 0, 0.2)",
  BlackOpacity40: "rgba(0, 0, 0, 0.4)",
  BlackOpacity60: "rgba(0, 0, 0, 0.6)",
  BlackOpacity70: "rgba(0, 0, 0, 0.7)",
  BlackOpacity80: "rgba(0, 0, 0, 0.8)",
  BlackRock: "#32333B",
  BlackRussian: "#191C22",
  BlackTabela: "rgba(0,0,0,.12)",
  BlueAqua: "#00E2FF",
  BlueCerulean: "#006FA6",
  BlueDodger: "#2F9AFE",
  BlueLimed: "#374148",
  BlueLochmara: "#0078DA",
  BlueTuna: "#303642",
  BlueViolet: "#872FFE",
  BlueZodiac: "#454D5E",
  BtLoginBGThymosColor: "#ed702a",
  CharcoalGrey: "#2A2B32",
  GreenFrag: "#3C8B3F",
  GreenMeadow: "#17A093",
  GreenWater: "#08B6C7",
  Grey: "#979797",
  GreyCasper: "#A6B9C8",
  GreyDim: "#6E6E6E",
  GreyDisabled: "#E9ECEF",
  GreyHit: "#A3B2B4",
  GreyPaleSky: "#647480",
  GreyPaynes: "#3D3E47",
  GreyShuttle: "#59606F",
  GreyWater: "#CED4DA",
  GreyTextLuz: "#646464",
  Mango: "#FEBB2F",
  MetallicGold: "#DAA332",
  MetallicGoldOpacity: "#DAA33270",
  OffWhite: "#EEF0F9",
  OffWhiteOpacity: "rgb(238, 240, 249, 0.5)",
  OrangeBlaze: "#FF6400",
  OrangeEcstasy: "#F76C1D",
  PlanoBronze: "#B18577",
  PlanoOuro: "#D5B178",
  PlanoPrata: "#B4B4B4",
  Purpleishpink: "#E851B1",
  PrimaryDeltaColor: "#daa332",
  PrimaryThymosColor: "#F47836",
  PrimaryOpacityThymosColor: "#fda677",
  PrimaryLuzColor: "#332053",
  PrimaryOpacityLuz: "#eef0f9",
  Preto: "#000",
  RedAmaranth: "#DC3545",
  RedChestnut: "#BA4141",
  RedError: "#F44336",
  RedGeranium: "#AB4E3F",
  Transparent: "#0000",
  White: "#FFF",
  WhiteGhost: "#F8F9FD",
  WhiteSmoke: "#EFEFEF",
  WhiteLuz: "#ffffff",
  YellowMustard: "#FFDD5B",
  YellowLight: "#FBDA62",
  GreenFern: "#568e3a",
  CustomGraLuzOne: "#eef0f9",
  CustomGraLuzTwo: "#bebebe",
  PinkLuz: "#fa3eb1",
  OrangeLuz: "#ff9600",
  SerieDeltaUm: "#7b62f9B3",
  SerieDeltaDois: "#60eaad",
  SerieDeltaTres: "#E14159",
  SerieDeltaQuatro: "#00b9fe",
  Gota: "#5fadd4",
  Lava: "#ff4640",
  Sole: "#ffdbff",
  DeltaMontSup: "#cacaca",
  DeltaMontInf: "#e14159",
  DeltaMont: "#04c1b0",
  Turvo: "#646464",
  Cimo: "#3c5734",
  LedVerde: "#32da66",
  LedVerdeLuz: "#3c5734",
  LedVermelho: "red",
  TraceMedidorConsumo: "#329eda",
  CativoDelta: "#c4c4c4",
  LivreDelta: "#febb2f",
  CativoLuz: "#fa3eb1",
  LivreLuz: "#febb2f",
  FundoBronze: "#FCEEEA",
  FundoPrata: "#EEF0F9",
  FundoOuro: "#FFF5E6",
  WhiteBgTheme: "#F4F4F4",
  GrayMedidores: "#838c9f",
  Cosmo: "#332053",
  Lume: "#ff9600",
  CativoEnergiaLuz: "#AE6905",
  CativoDistribuidoraLuz: "#D08F30",
  CativoDistribuidoraDelta: "#86999c",
  CativoImpostoLuz: "#6D512A",
  CativoImpostoDelta: "#687b7f",
  CativoOutrosLuz: "#BBA27D",
  CativoOutrosDelta: "#394345",
  LivreDistribuidoraDelta: "#118378",
  LivreImpostoDelta: "#0d6960",
  LivreOutrosDelta: "#0c504a",

  LivreDistribuidoraLuz: "#BF5B98",
  LivreEnergiaLuz: "#7B3560",
  LivreImpostoLuz: "#BD2B85",
  LivreOutrosLuz: "#6A174A",

  NovoBGMenuHuver: "#FBFBFD",

  GradientTextLuz: "linear-gradient(90deg, #F722B5 0%, #FD9507 100%)",
  GradientTextLuzOpacity:
    "linear-gradient(to right, rgba(247, 34, 181, 0.24), rgba(253, 149, 7, 0.24))",
  RgbaCosmoLuz: "rgba(51, 32, 83, 0.48)",
  TextLuzOpacity: "#D2D2D7",
  CardBgColor: "#E5E5E5",
  BoldLuzText: "#4F4F4F",

  MenuItemTextOpacityLuz: "rgba(51, 32, 83, 0.24)",
  MenuItemTextOpacityDelta: "rgba(255, 255, 255, 0.48)",

  CardBgColorOpacity: "#9F9F9F",

  ButtonLuz: "#FA3EB1",

  BackgroundBoxInfo: "#F5F5F7"
};

export { Base };
