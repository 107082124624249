/* eslint-disable no-continue */
/* eslint-disable consistent-return */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-shadow */
/* eslint-disable prefer-template */
/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect, useCallback } from "react";
import { Grid, FormControl, InputLabel, Select, MenuItem, Tooltip, OutlinedInput,Typography } from "@mui/material";
import { Base } from "componentes/cores";
import { Botao, ModalConfirmacao } from "componentes";
import Loader from "componentes/loader";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useStyles } from "./style";
import { buscaDistribuidoraPorGrupo} from "../../../servicos/grupoProjecoes";
import { getExcelTarifaAberta } from "../../../servicos/exportacoes";
import BotaoRetornarListagem from "componentes/botaoRetornarListagem";
import moment from "moment";
import ModalInformacao from "componentes/modalVerificacao";


const ExportarTarifaAberta = () => {
    const usuarioGlobal = useSelector((state) => state.usuario);
    const classes = useStyles();
    const {idgrupo, titulogrupo} = useParams();

    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);

    const [distribuidoras, setDistribuidoras] = useState([]);
    const [listaDistribuidorasEditada, setListaDistribuidorasEditada] = useState([]);
    const [distribuidoraSelecionada, setDistribuidoraSelecionada] = useState();
    const [anos, setAnos] = useState([]);
    const [anoSelecionado, setAnoSelecionado] = useState(2010);

    const [nomeArquivo, setNomeArquivo] = useState("TarifaAberta.xlsx");
    const [showModalGerarExcel, setShowModalGerarExcel] = useState(false);
    const [modalInfo, setModalInfo] = useState(false);
    const [msgFalha, setMsgFalha] = useState();
    const [tipoTarifa, setTipoTarifa] = useState("");

    const handleAnos = useCallback( async () =>{

        const menorAno = 2011;
        const maiorAno = moment().year();
        let years = [];

        for(var i = menorAno; i <= maiorAno; i++)
        {
            years.push(i);
        }
        setAnos(years);
        setAnoSelecionado(menorAno);
    });
 
    const handleDistribuidoras = useCallback( async () => {
        try {
            setIsLoading(true);
            const lista = await buscaDistribuidoraPorGrupo(idgrupo);
            if (lista?.status === 200 && lista?.data) {
                setIsLoading(false);
                setDistribuidoras(lista?.data);
                setListaDistribuidorasEditada(lista?.data.map(listItem => { 
                    return {
                        id: listItem.idDistribuidora,
                        nome: listItem.titulo
                    }
                }));
            } else {
                setDistribuidoras([]);
                setIsLoading(false);
            }
        } catch (error) {
            console.info(error);
            setIsLoading(false);
        }
    })

    const onChangeDistribuidora = async (event) => {
        setDistribuidoraSelecionada(event.props.value);
    };

    useEffect(() => {
        if (!distribuidoras?.length) handleDistribuidoras();
    }, [distribuidoras?.length]);


    const handleExportExcel =  useCallback (async() => {

        setIsDownloading(true);

        // Prepara parâmetro Distribuidora
        if(distribuidoraSelecionada === undefined)
        {
            setShowModalGerarExcel(false);
            store.dispatch(
                alertaExibir({
                  tipo: "warning",  
                  mensagem: "Escolha uma distribuidora para exportação."
                })
              );
              return;
        }

        /*
            Se parâmetro distribuidoras foi carregado com sucesso
        */
        if(distribuidoras.length > 0)
        {
            try
            {
                const response = await getExcelTarifaAberta(idgrupo,distribuidoraSelecionada,
                    tipoTarifa,nomeArquivo,usuarioGlobal.usuario.nome);

                setDistribuidoraSelecionada();
                handleAnos();
                
                setShowModalGerarExcel(false);

                if(response !== "Sucesso")
                {
                    setMsgFalha(`${response}`);
                    setModalInfo(true);
                }
                else
                {
                    store.dispatch(
                        alertaExibir({
                            tipo: "success",  
                            mensagem: "Exportação concluída com sucesso."
                        })
                    );
                }


            }
            catch(error)
            {
                setDistribuidoraSelecionada();
                handleAnos();
                setIsDownloading(false);
                setShowModalGerarExcel(false);
                setMsgFalha(`Erro não previsto: ${error}`);
                setModalInfo(true);
            }
        }
        setIsDownloading(false);
    });

    
    return <>
        <ModalInformacao
            titulo={"Exportação não realizada com a seguinte falha:"}
            exibir={modalInfo}
            mensagem={msgFalha}
            onClose={() => {
            setModalInfo(false);
            }}
            showBotao={false}
        />        
        <Loader loading={isLoading} className="w-auto">
            <ModalConfirmacao
            item={showModalGerarExcel}
            onCancelar={() => setShowModalGerarExcel(false)}
            onConfirmar={() => handleExportExcel()}
            className = {classes.container}
            mensagem={
                <pre>
                    <Loader loading={isDownloading} className="w-auto">
                    <strong><p>Gerar planilha excel com Tarifa Aberta e fazer download?</p></strong>
                    </Loader>            
                    <FormControl variant="outlined" >
                        <InputLabel htmlFor="outlined-nome-arquivo-excel">Nome do Arquivo</InputLabel>
                        <Tooltip 
                            placement="top"
                            title={
                                <h6>Nome sugerido. Aceite ou edite antes de confirmar.</h6>
                            }
                        >
                            <OutlinedInput
                                id="outlined-nome-arquivo-excel"
                                type="text"
                                value={nomeArquivo}
                                onChange={(e) => setNomeArquivo(e.target.value)}
                                label="Nome do Arquivo a Gerar"
                            />               
                        </Tooltip>                 
                    </FormControl>
                </pre>
            }
            />          
            <Grid container item spacing={0} alignItems="center">
                <Typography variant="h6" style={{ color: Base.OffWhite, fontSize: 20 }}>
                    Exportação de Tarifa Aberta</Typography>
                <Grid item className={classes.backButton}>
                    <BotaoRetornarListagem voltarPagina />
                </Grid>
            </Grid>
            <Grid container spacing={3} alignItems="center">
                <Grid item>
                <Typography variant = "h6" style={{color: Base.OffWhite, fontSize: 20}}>
                    Grupo: {titulogrupo}</Typography>
                </Grid>
            </Grid>

            <Grid container style={{ paddingLeft: "1.5em", paddingRight: "1.5em", paddingTop: "25px" }}>
                <Grid
                    container
                    spacing={3}
                    alignItems="center"
                    className={classes.container}
                    justifyContent="flex-start"
                >   
                    
                    <Grid item xs={3} className={classes.customSelect}>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="labeldistr">Distribuidora</InputLabel>
                            <Select
                                variant="outlined"
                                labelId="labeldistr"
                                id="distr"
                                value={distribuidoraSelecionada ?? ""}
                                onChange={(e, item) => {
                                    onChangeDistribuidora(item);
                                }}
                                label="Distribuidora"
                                fullWidth>
                                {listaDistribuidorasEditada.map((dist) => (
                                    <MenuItem value={dist.id}>{dist.nome}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item sm={3}>
                        <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="labeltarifa">Tipo da Tarifa</InputLabel>
                        <Select
                            variant="outlined"
                            labelId="labeltarifa"
                            id="tarifa"
                            value={tipoTarifa ?? ""}
                            onChange={(e) => {setTipoTarifa(e.target.value) }}
                            label="Tipo da Tarifa"
                            fullWidth>
                            {[{label:"Tarifa Econômica",valor:"TarifaEconomica"}, 
                            {label:"Tarifa Aplicação",valor:"TarifaAplicacao"}]
                            .map((item) => (
                            <MenuItem value={item.valor}>{item.label}</MenuItem>
                            ))}
                        </Select>
                        </FormControl>
                    </Grid>


                </Grid>
                <Grid container alignItems="left" style={{ paddingTop: "25px" }}>
                    <Grid xs={2}>
                        <Botao
                            type="button"
                            label="Gerar Excel"
                            color={Base.Mango}
                            className={classes.button}
                            onClick={()=>{setShowModalGerarExcel(true)}}
                            disabled = {distribuidoraSelecionada === undefined || tipoTarifa == ""}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Loader>
    </>;
}

export default ExportarTarifaAberta;
