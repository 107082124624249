import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { useStyles } from "./style";
import moment from "moment";

const ColunaData = ({ parametros }) => {
    const classes = useStyles();
    
    return (
        <Grid container spacing={0} className={classes.fundoBotoes}>
            <Grid item xs={12} className={classes.textoData}>
                {moment(parametros.data.substr(0, 10)).format(parametros.formatoData.toUpperCase().replace("/", " "))}
            </Grid>
        </Grid>
    );
};

ColunaData.propTypes = {
  parametros: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired
};

export default ColunaData;
