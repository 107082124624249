import getDomain from "../global/getDomain";
import thymos from "./thymos";
import delta from "./delta";
import luz from "./luz";

const theme = {
  default: {
    color: {
      primaryOpacity: "#ccc",
      BtLoginBG: "#666",
      BtLoginBorder: "#666",
      BtLoginText: "#fff",
      primary: "#666",
      primaryText: "#191C22",
      secondary: "theme.color.fourthBackgroud",
      secondaryText: "#FFF",
      gradientTextLuz: "linear-gradient(90deg, #F722B5 0%, #FD9507 100%)",
      gradientTextLuzOpacity:
        "linear-gradient(to right, rgba(247, 34, 181, 0.16), rgba(253, 149, 7, 0.16))"
    }
  },
  delta: { ...delta },
  thymos: { ...thymos },
  luz: { ...luz }
};

const size = {
  xs: "375px",
  sm: "600px",
  md: "960px",
  lg: "1280px",
  xl: "1920px"
};

theme.default.size = size;
theme.delta.size = size;
theme.thymos.size = size;
theme.luz.size = size;

// console.log('getDomain()', getDomain());
export default theme[getDomain()];
