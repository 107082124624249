import { makeStyles } from "@mui/styles";
import styled from "styled-components";
import { createTheme } from "@mui/material/styles";
import { Base } from "../../../componentes/cores";
import theme from "../../../themes";

const th = createTheme();

export const useStyles = makeStyles(() => ({
  container: {
    "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
      height: "1.01em",
      padding: "0 0 0 20px"
    },
    margin: "0",
    width: "100%",
    "& .MuiInputBase-root": {
      height: "46px"
    },
    "& .MuiFormLabel-root": {
      lineHeight: "1"
    }
  },
  search: {
    "& label.Mui-focused": {
      color: Base.BlackBastille
    },
    "& .MuiInput-underline:after": {
      borderColor: Base.BlackBastille
    },
    "& .MuiFormLabel-root": {
      color: Base.BlackBastille
    },
    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-input": {
        color: Base.BlackBastille
      },
      "& .MuiInputAdornment-root": {
        color: Base.BlackBastille
      },
      "& fieldset": {
        borderColor: Base.BlackBastille
      },
      "&:hover fieldset": {
        borderColor: Base.BlackBastille
      },
      "&.Mui-focused fieldset": {
        borderColor: Base.BlackBastille
      }
    },
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px"
    }
  },
  select: {
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px"
    },
    "& label.Mui-focused": {
      color: Base.OffWhite
    },
    "& .MuiInput-underline:after": {
      borderColor: Base.OffWhite
    },
    "& .MuiFormLabel-root": {
      color: Base.OffWhite
    },
    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-input": {
        color: Base.OffWhite
      },
      "& .MuiInputAdornment-root": {
        color: Base.OffWhite
      },
      "& fieldset": {
        borderColor: Base.OffWhite
      },
      "&:hover fieldset": {
        borderColor: Base.OffWhite
      },
      "&.Mui-focused fieldset": {
        borderColor: Base.OffWhite
      }
    },
    "& .MuiSelect-icon": {
      color: Base.White
    }
  },
  itemFiltro: {
    display: "flex",
    justifyContent: "flex-end",
    [th.breakpoints.down("md")]: {
      justifyCcontent: "normal",
      width: "100% !important",
      display: "inline"
    }
  },
  buttonFiltro: {
    fontSize: "16px",
    maxWidth: "100%",
    fontWeight: "700",
    marginTop: "5px"
  },
  tabela: {
    "& .MuiButton-root": {
      minWidth: "0px",
      "& .MuiSvgIcon-root": {
        fontSize: "20px",
        marginLeft: "2px"
      }
    }
  },
  linhaTooltip: {
    cursor: "default",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  fundoCelulaMobile: {
    backgroundColor: "#282E3D",
    marginBottom: "23px"
  },
  botao: {
    color: `${theme.color.secondaryText} !important`,
    fontSize: "42px"
  },
  empresaNaoSelecionada: {
    color: `${theme.color.secondaryBorderColor} !important`,
    fontSize: "28px",
    margin: "200px auto 0",
    maxWidth: "500px",
    textAlign: "center"
  }
}));

export const Icone = styled.img`
  max-width: 40px;
  object-fit: cover;
`;

export const BotaoDownload = styled.button`
  background-color: transparent;
  border: none;
  padding-top: 5px;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
`;
