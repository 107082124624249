import React, { useMemo, useState } from "react";
import MensagemBaixaRenda from "./componentes/MensagemBaixaRenda";
import MensagemSerasa from "./componentes/MensagemSerasa";
import CotacaoACL from "./Simular";

const CotacaoIncialACL = () => {
  const [passoAtual, setPassoAtual] = useState("cotacaoInicialACL");
  const [dadosCotacao, setDadosCotacao] = useState({});

  const passoAtualACL = useMemo(() => {
    switch (passoAtual) {
      case "cotacaoInicialACL":
        setPassoAtual("cotacaoInicialACL");
        return (
          <CotacaoACL
            setPassoAtual={setPassoAtual}
            setDadosCotacao={setDadosCotacao}
          />
        );
      case "mensagemSerasa":
        setPassoAtual("mensagemSerasa");
        return (
          <MensagemSerasa
            setPassoAtual={setPassoAtual}
            dadosCotacao={dadosCotacao}
          />
        );
      case "mensagemDistribuidora":
        setPassoAtual("mensagemDistribuidora");
        return <MensagemBaixaRenda setPassoAtual={setPassoAtual} />;

      default:
        return <div>Tela não encontrada</div>;
    }
  }, [passoAtual, dadosCotacao]);

  return <>{passoAtualACL}</>;
};

export default CotacaoIncialACL;
