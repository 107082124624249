import React from "react";
import CampoDropDownTabela from "../../../../../componentes/campoDropDownTabela";

export default class ColunaCampoDropDown {
  constructor(
    field,
    headerName,
    sortable = false,
    onChangeSelecionarDescontoDiferenciado,
    maxWidth = null,
    minWidth = null,
    descontoDiferenciado = null,
    idDescontoDiferenciado = null,
    selecionadoId = null,
    enabled = false
  ) {
    this.selector = field;
    this.name = headerName;
    this.sortable = sortable;
    this.maxWidth = maxWidth;
    this.minWidth = minWidth;
    this.descontoDiferenciado = descontoDiferenciado;
    this.idDescontoDiferenciado = idDescontoDiferenciado;
    this.selecionadoId = selecionadoId;
    this.enabled = enabled;
    this.cell = (params) => {
      return (
        <CampoDropDownTabela
          id={params.id}
          name={params.descontoDiferenciado}
          valueKey="id"
          valueName="descricao"
          dataSource={params.descontosSelecionadosList}
          value={
            idDescontoDiferenciado === params?.id
              ? descontoDiferenciado
              : params.descontoDiferenciado
          }
          onChange={(event) =>
            onChangeSelecionarDescontoDiferenciado(event.target.value, params)
          }
          parametros={params}
          disabled={!(params.id === selecionadoId && enabled)}
        />
      );
    };
  }
}
