/* eslint-disable react/no-danger */
import { Card, Grid } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { ReactComponent as Upload } from "../../../../../assets/cotacoesGd/upload.svg";
import IconeFrente from "../../../../../assets/icones/workflow/documento.svg";
import UploadDropAreaMultiploUnico from "../uploadMultiploUnico";
import { useStyles } from "./style";

function UploadCard({
  titulo,
  subtitulo,
  htmlPasso,
  tituloUpload,
  onAddArquivoUpload,
  onDeleteArquivoUpload,
  onClickDownload,
  tipo,
  limite,
  renderizaComponente,
  somenteLeitura,
  showLoader,
  disabledCard,
  handleMensagemModal,
  iconeUpload,
  passoCustomizado
}) {
  const classes = useStyles();
  const [carregandoUpload, setCarregandoUpload] = useState(0);

  const controlarPorcentagem = () => {
    setCarregandoUpload(0);
    let porcentagem = 0;

    const intervalo = setInterval(() => {
      if (porcentagem >= 100) {
        clearInterval(intervalo);
        return;
      }

      porcentagem += 25;
      setCarregandoUpload(porcentagem);
    }, 150);
  };

  const onAdd = (listaArquivo) => {
    if (!listaArquivo || listaArquivo.length === 0) return;

    controlarPorcentagem();

    onAddArquivoUpload(listaArquivo, tipo);
  };

  const onDelete = (listaArquivo) => {
    onDeleteArquivoUpload(listaArquivo, tipo);
  };

  return (
    <Card
      className={disabledCard ? classes.cardUploadDisabled : classes.cardUpload}
    >
      <Grid container>
        <Grid item xs={12} md={6} className="pr-3">
          <h3 className={classes.tituloCard}>{titulo}</h3>
          <h4 className={classes.subTituloCard}>{subtitulo}</h4>
          {htmlPasso && (
            <div
              className={classes.htmlPasso}
              dangerouslySetInnerHTML={{ __html: htmlPasso }}
            />
          )}
          {passoCustomizado && passoCustomizado}
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          className="pl-3 d-flex align-self-center flex-column"
        >
          <div className="text-center">
            <img
              src={iconeUpload}
              className="mx-4 mb-4"
              alt="ícone documento"
            />
            <UploadDropAreaMultiploUnico
              imageOrPdfValidate
              onAdd={(arquivo) => onAdd(arquivo, 1)}
              onDropRejected={(arquivo) => onDelete(arquivo, 1)}
              progressValue={carregandoUpload}
              acceptedFiles={[
                ".JPG",
                ".JPEG",
                ".PNG",
                ".TIF",
                ".JFIF",
                ".TIFF",
                ".GIF",
                ".BMP",
                ".ICO",
                ".PDF",
                ".PDF2"
              ]}
              dropAreaText={tituloUpload}
              filesLimit={limite ?? 1}
              disabled={somenteLeitura}
              renderizaComponente={renderizaComponente}
              onPreviewChipClick={onClickDownload}
              showLoader={showLoader}
              showPreviews
              disabledCard={disabledCard}
              handleMensagemModal={(mensagem) => handleMensagemModal(mensagem)}
              icon={Upload}
            />
          </div>
        </Grid>
      </Grid>
    </Card>
  );
}

UploadCard.propTypes = {
  titulo: PropTypes.string,
  subtitulo: PropTypes.string,
  htmlPasso: PropTypes.string,
  tituloUpload: PropTypes.string,
  iconeUpload: PropTypes.string,
  onAddArquivoUpload: PropTypes.func,
  onDeleteArquivoUpload: PropTypes.func,
  onClickDownload: PropTypes.func,
  showLoader: PropTypes.bool,
  disabledCard: PropTypes.bool,
  passoCustomizado: PropTypes.node,
  handleMensagemModal: PropTypes.func,
  tipo: PropTypes.string,
  limite: PropTypes.number,
  renderizaComponente: PropTypes.bool,
  somenteLeitura: PropTypes.bool
};

UploadCard.defaultProps = {
  titulo: "",
  subtitulo: "",
  htmlPasso: "",
  tituloUpload: "",
  iconeUpload: IconeFrente,
  onAddArquivoUpload: () => {},
  onDeleteArquivoUpload: () => {},
  onClickDownload: () => {},
  showLoader: false,
  disabledCard: false,
  passoCustomizado: null,
  handleMensagemModal: () => {},
  tipo: "",
  limite: 1,
  renderizaComponente: false,
  somenteLeitura: false
};

export default UploadCard;
