import { Base } from "componentes/cores";

export const options = (categorias) => ({
  chart: {
    type: "bar",
    stacked: true,
    zoom: {
      enabled: false
    },
    toolbar: {
      show: false
    }
  },
  plotOptions: {
    bar: {
      columnWidth: "40%"
    }
  },
  grid: {
    show: false
  },
  colors: [Base.PinkLuz, Base.OrangeEcstasy],
  dataLabels: {
    enabled: false
  },
  legend: {
    show: true,
    fontSize: "14px",
    fontWeight: 700,
    labels: {
      colors: ["#332053"],
      useSeriesColors: false
    },
    markers: {
      radius: 12,
      fillColors: [Base.PinkLuz, Base.OrangeEcstasy]
    }
  },
  xaxis: {
    show: false,
    categories: categorias || []
  },
  yaxis: {
    show: true,
    labels: {
      show: true,
      minWidth: 0,
      maxWidth: 160,
      offsetX: 0,
      offsetY: 0,
      rotate: 0,
      style: {
        colors: "#4F4F4F",
        fontSize: "12px"
      },
      formatter(value) {
        return `
            ${value}
          `;
      }
    }
  }
});
