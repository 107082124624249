/* eslint-disable import/no-unresolved */
import MICROSERVICO from "global/enumeradores/microServico";
import obterApi from "servicos/api";

const api = () => obterApi(MICROSERVICO.PROJECAOTARIFARIA);

export const uploadRateioCDE = async (idUsuario, nomeUsuario, keyFileS3) => {
    const metodo = "post";
    const url = `v1/ImportCotasRateioCDE/importar?idUsuario=${idUsuario}&nomeUsuario=${nomeUsuario}&keyFileS3=${keyFileS3}`;
    return api()[metodo](url);
}

export const getDistribuidoras = async () => {
    const metodo = "get";
    const url = "v1/ParametroDistribuidora/getalljoin"
    return api()[metodo](url);
}

export const getRateioCDE = async () => {
    const metodo = "get";
    const url = "v1/ImportCotasRateioCDE/visualizar";
    return api()[metodo](url);
}