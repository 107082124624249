import styled from "styled-components";
import theme from "../../../../themes";
import { Base } from "../../../../componentes/cores";

export const Porcentagem = styled.div`
  background-color: ${(props) => (props.cor ? props.cor : theme.primary)};
  color: ${Base.White};
  font-weight: 700;
  padding: 4px 8px 4px 8px;
  border-radius: 24px;
  font-size: 14px;
  width: fit-content;
  margin-bottom: 1px;
  @media (max-width: 599.95px) {
    margin: 0 auto;
  }
`;
