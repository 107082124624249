import { CloudUploadOutlined } from "@mui/icons-material";
import { Box, Chip } from "@mui/material";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

import { useStyles } from "./style";

const InputFile = ({
  file,
  id,
  name,
  dropAreaText,
  icon,
  onUpload,
  onDelete,
  accept,
  errors
}) => {
  const classes = useStyles();

  const onDrop = useCallback(
    (acceptedFiles) => {
      // eslint-disable-next-line no-shadow, array-callback-return
      acceptedFiles.map((file) => {
        onUpload(file);
      });
    },
    [file]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    onDrop
  });

  return (
    <>
      <button
        className={
          errors[name]?.message
            ? `${classes.error} ${classes.customFile}`
            : classes.customFile
        }
        {...getRootProps()}
        type="button"
      >
        <input
          type="file"
          id={id}
          name={name}
          className={classes.inputFile}
          accept={accept}
          {...getInputProps()}
        />

        <Box className={classes.textContainer}>
          <Box className={classes.icon}>{icon}</Box>
          <p className={classes.text}>{dropAreaText}</p>
        </Box>
      </button>

      {file && (
        <Chip
          className={classes.chip}
          label={file.name}
          onDelete={() => onDelete(file)}
        />
      )}

      {Boolean(errors[name]?.message) && (
        <p className="MuiFormHelperText-contained Mui-error">
          {errors[name]?.message ?? ""}
        </p>
      )}
    </>
  );
};

InputFile.propTypes = {
  file: PropTypes.oneOfType([PropTypes.any]),
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  dropAreaText: PropTypes.string,
  icon: PropTypes.node,
  onUpload: PropTypes.func,
  onDelete: PropTypes.func,
  accept: PropTypes.string,
  errors: PropTypes.oneOfType([PropTypes.object])
};

InputFile.defaultProps = {
  file: "",
  dropAreaText: "Solte os arquivos para anexá-los ou procurar",
  icon: <CloudUploadOutlined />,
  onUpload: () => {},
  onDelete: () => {},
  accept: "video/*",
  errors: {}
};

export default InputFile;
