/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";

// Componentes
import { Box, Button, Grid, Tooltip } from "@mui/material";
import { Loader, SelectArredondado, TabelaPaginada } from "componentes";
import MaterialInputBusca from "componentes/inputBusca";
import ColunaComponentePersonalizado from "componentes/tabelaPaginada/colunas/colunaComponentePersonalizado";
import { saveAs } from "file-saver";
import { b64toBlob, isPdfFile } from "global/utils/blobUtil";
import InputDataWhite from "../../../../componentes/inputTextoDataBranco";
import Modal from "../../../../componentes/modal";
import Coluna from "../../../../componentes/tabelaPaginada/colunas/coluna";
import ResultadoPaginadoDto from "../../../../componentes/tabelaPaginada/resultadoPaginadoDto";
import ColunaMultiplosBotoes from "../colunaCustomizada";

import enumerador from "../colunaCustomizada/enumerador";
import origensPermissaoDownload from "../origensPermissaoDownload";

// Serviços
import { obterDadosFaturaArquivo } from "../../../../servicos/digitalizacaoServico";
import { listarFaturaDistribuidoraPreLead } from "../../../../servicos/faturaDistribuidoraServico";
import { listarProdutos } from "../../../../servicos/produtosServico";

// dtos
import FaturaDistribuidoraPreLeadDto from "./FaturaDistribuidoraPreLeadDto";

// Redux
import { store } from "../../../../global/redux";
import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";

import { useStyles } from "./style";

const ListagemFaturaDistribuidoraPreLead = () => {
  const [carregandoPreLead, setCarregandoPreLead] = useState(false);
  const [lista, setLista] = useState([]);
  const [filtroAdicionalTabela, setFiltroAdicionalTabela] = useState();
  const [dataInicialPesquisa, setDataInicialPesquisa] = useState(null);
  const [dataFinalPesquisa, setDataFinalPesquisa] = useState(null);
  const [modalAcao, setModalAcao] = useState(false);
  const [preLeadDetalhes, setPreLeadDetalhes] = useState([]);
  const [origem, setOrigem] = useState();
  const [campoPesquisa, setCampoPesquisa] = useState("");
  const [produtoLista, setProdutoLista] = useState();
  const [siglaProduto, setSiglaProduto] = useState();
  const [carregandoProduto, setCarregandoProduto] = useState(false);

  const classes = useStyles();

  const { register, control } = useForm({
    reValidateMode: "onSubmit"
  });

  const textoBusca = useWatch({
    control,
    name: "textoBusca",
    defaultValue: ""
  });

  const textoParaBusca = useMemo(() => {
    return textoBusca?.length >= 3 ? textoBusca : "";
  }, [textoBusca]);

  const montarModal = () => {
    const podeDownload =
      origensPermissaoDownload.includes(preLeadDetalhes.origemDaInformacao) &&
      preLeadDetalhes.faturaProcessada === "Sim";

    return (
      <Loader loading={carregandoPreLead} className="w-auto">
        <Box className={classes.dataConsulta}>
          <b>Data de consulta:</b> {preLeadDetalhes.dataDaCriacaoNoSalesforce}
        </Box>
        <Grid container>
          <Grid xs={4} className={classes.gridPaddingTopo}>
            <div>
              <b>Nome: </b>
              {preLeadDetalhes.nome}
            </div>
          </Grid>
          <Grid xs={4} className={classes.gridPaddingTopo}>
            <div>
              <b>E-mail: </b> {preLeadDetalhes.email}
            </div>
          </Grid>
          <Grid xs={4} className={classes.gridPaddingTopo}>
            <div>
              <b>Telefone: </b> {preLeadDetalhes.telefone}
            </div>
          </Grid>
          <Grid xs={6} className={classes.gridPadding}>
            <div>
              <b>Externo Id:</b> {preLeadDetalhes.id}{" "}
            </div>
            <div>
              <b>Tipo Cliente:</b> {preLeadDetalhes.pessoaFisicaJuridica}{" "}
            </div>
            <div>
              <b>CEP Cliente:</b> {preLeadDetalhes.cep}
            </div>
            <div>
              <b>Distribuidora:</b> {preLeadDetalhes.distribuidora}{" "}
            </div>
            <div>
              <b>Origem:</b> {preLeadDetalhes.origemDaInformacao}{" "}
            </div>
            <div>
              <b>Nome do anexo com extensão:</b>{" "}
              {preLeadDetalhes.nomeDoAnexoComExtensao}
            </div>
            <div>
              <b>Valor da conta:</b> {preLeadDetalhes.valorDaConta}
            </div>
          </Grid>
          <Grid xs={6} className={classes.gridPadding}>
            <div>
              <b>Multa:</b> {preLeadDetalhes.multaDistribuidora}
            </div>
            <div>
              <b>Consulta fora do range:</b>{" "}
              {preLeadDetalhes.consumoAbaixoRange}
            </div>
            <div>
              <b>Erro leitura OCR:</b> {preLeadDetalhes.erroLeituraFatura}
            </div>
            <div>
              <b>Distribuidora não atendida:</b>{" "}
              {preLeadDetalhes.distribuidoraNaoAtendida}
            </div>
            <div>
              <b>Contrato assinado:</b> {preLeadDetalhes.preLeadContratado}{" "}
            </div>
            <div>
              <b>Contrato gerado:</b> {preLeadDetalhes.contratoGerado}
            </div>
            <div>
              <b>Redução de custo:</b> {preLeadDetalhes.reducaoDeCusto}
            </div>
          </Grid>
          {podeDownload ? (
            <>
              <Grid xs={12} className={classes.btnAcoes}>
                <Button
                  // eslint-disable-next-line no-use-before-define
                  onClick={() => onClickDownload(preLeadDetalhes.id)}
                  className={classes.btnDownload}
                >
                  <div>Download</div>
                </Button>
              </Grid>
            </>
          ) : null}
        </Grid>
      </Loader>
    );
  };

  const onClickDownload = async (id) => {
    setCarregandoPreLead(true);

    try {
      const retornoFaturaArquivo = await obterDadosFaturaArquivo(id);

      if (retornoFaturaArquivo.arquivoOriginal == null) {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "Arquivo não encontrado!"
          })
        );
      } else {
        isPdfFile(retornoFaturaArquivo.arquivoOriginal);
        const blob = b64toBlob(
          retornoFaturaArquivo.arquivoOriginal,
          isPdfFile(retornoFaturaArquivo.arquivoOriginal) && "application/pdf"
        );
        saveAs(blob, retornoFaturaArquivo.arquivoNome);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }

    setCarregandoPreLead(false);
  };

  const onClickDetalhar = (parametros) => {
    setPreLeadDetalhes(parametros);
    setModalAcao(true);
  };

  const onClickBotao = (tipo, parametros) => {
    switch (tipo) {
      case enumerador.Download:
        onClickDownload(parametros.id);
        break;
      case enumerador.Detalhar:
        onClickDetalhar(parametros);
        break;
      default:
        break;
    }
  };

  const colunas = [
    new Coluna("nome", "Nome"),
    new Coluna("email", "Email"),
    new Coluna("telefone", "Telefone"),
    new Coluna("pessoaFisicaJuridica", "Tipo Cliente"),
    new Coluna("descontoInicial", "Desconto"),
    new Coluna("faturaProcessada", "Fat. Processada"),
    new Coluna("dataDaCriacaoNoSalesforce", "Data Upload"),
    new Coluna("produto", "Produto"),
    new Coluna("origemDaInformacao", "Origem"),
    new ColunaComponentePersonalizado(
      "opcoes",
      "Opções",
      ColunaMultiplosBotoes,
      onClickBotao,
      false,
      true,
      "150px",
      "150px",
      "pl-2"
    )
  ];

  const onChangeFiltrosTabela = async (parametros) => {
    // eslint-disable-next-line no-use-before-define
    return obterPreLead({
      pagina: parametros.pagina,
      pesquisar: parametros.pesquisar,
      dataInicial: dataInicialPesquisa
        ? window.moment(dataInicialPesquisa).format("YYYY-MM-DD")
        : null,
      dataFinal: dataFinalPesquisa
        ? window.moment(dataFinalPesquisa).format("YYYY-MM-DD")
        : null,
      campoPesquisa,
      origem,
      siglaProduto
    });
  };

  useEffect(() => {
    setFiltroAdicionalTabela({
      dataInicialPesquisa,
      dataFinalPesquisa,
      campoPesquisa,
      origem,
      siglaProduto
    });
  }, [dataInicialPesquisa, dataFinalPesquisa, origem, siglaProduto]);

  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    obterProduto();
  }, []);

  const obterProduto = async () => {
    try {
      setCarregandoProduto(true);
      // eslint-disable-next-line no-shadow
      const lista = await listarProdutos();

      if (lista?.status === 200 && lista?.data?.produtos) {
        setProdutoLista([
          {
            id: "",
            sigla: "Todos"
          },
          ...lista?.data.produtos
        ]);
      }

      setCarregandoProduto(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoProduto(false);
    }
  };

  // eslint-disable-next-line consistent-return
  const obterPreLead = async (parametros) => {
    try {
      setCarregandoPreLead(true);

      const resultado = await listarFaturaDistribuidoraPreLead(
        parametros.pagina,
        parametros.pesquisar,
        parametros.dataInicial,
        parametros.dataFinal,
        parametros.campoPesquisa,
        parametros.origem,
        parametros.siglaProduto === "Todos" ? null : parametros.siglaProduto
      );

      if (!resultado.status === 200) {
        store.dispatch(
          alertaExibir({
            tipo: "danger",
            mensagem: resultado.mensagem
          })
        );
        return new ResultadoPaginadoDto([], 1, 0, 0);
      }

      if (resultado?.length === 0 ?? true)
        return new ResultadoPaginadoDto([], 1, 0, 0);

      const resLinhas = await resultado.data.preLeads?.map((res) => {
        return new FaturaDistribuidoraPreLeadDto(
          res.id,
          (
            <Tooltip
              title={<span style={{ fontSize: "14px" }}>{res.nome}</span>}
              aria-label={res.nome}
            >
              <span>{res.nome}</span>
            </Tooltip>
          ),
          res.pessoaFisicaJuridica,
          res.descontoInicialFormatado,
          res.valorDaContaFormatado,
          res.reducaoDeCustoFormatado,
          res.faturaProcessadaFormatada,
          res.dataDaCriacaoNoSalesforceFormatada,
          res.produto,
          res.origemDaInformacao,
          (
            <Tooltip
              title={<span style={{ fontSize: "14px" }}>{res.email}</span>}
              aria-label={res.email}
            >
              <span>{res.email}</span>
            </Tooltip>
          ),
          (
            <Tooltip
              title={<span style={{ fontSize: "14px" }}>{res.telefone}</span>}
              aria-label={res.telefone}
            >
              <span>{res.telefone}</span>
            </Tooltip>
          ),
          res.cep,
          res.nomeDoAnexoComExtensao,
          res.guidArquivoDaDigitalizacao,
          res.multaDistribuidoraFormatada,
          res.preLeadContratadoFormatado,
          res.distribuidora,
          res.distribuidoraNaoAtendidaFormatada,
          res.erroLeituraFaturaFormatado,
          res.consumoAbaixoRangeFormatado,
          res.contratoGeradoFormatado
        );
      });
      setLista(resLinhas);

      setCarregandoPreLead(false);

      return new ResultadoPaginadoDto(
        resLinhas,
        resultado.data.paginaAtual,
        resultado.data.totalItens,
        resultado.data.totalPaginas
      );
    } catch (error) {
      setCarregandoPreLead(false);
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  return (
    <Loader loading={carregandoPreLead} className="w-auto">
      <Modal
        item={modalAcao}
        mensagem={montarModal()}
        onCancelar={() => setModalAcao(false)}
      />
      <Grid
        container
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={12} sm={6} lg={2}>
          <SelectArredondado
            select
            id="CampoPesquisa"
            name="CampoPesquisa"
            label="Filtrar por campo"
            placeholder="Filtrar por campo"
            valueKey="valor"
            valueName="nome"
            className={classes.select}
            dataSource={[
              { nome: "Sem filtro", valor: "" },
              { nome: "Nome", valor: "Nome" },
              { nome: "E-mail", valor: "Email" },
              { nome: "Telefone", valor: "Telefone" }
            ]}
            value={campoPesquisa}
            onChange={(e) => {
              setCampoPesquisa(e.target.value);
            }}
            permiteValorBranco
            styleForm={false}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={2}>
          {campoPesquisa !== "" ? (
            <>
              <MaterialInputBusca
                type="text"
                id="textoBusca"
                name="textoBusca"
                label="Buscar"
                className={classes.search}
                renderIconShowHide
                searchAdornment
                defaultValue={textoBusca ?? ""}
                ref={register}
                permiteValorBranco
              />
            </>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={4} lg={2}>
          <Loader loading={carregandoProduto}>
            <SelectArredondado
              select
              id="Produto"
              name="Produto"
              label="Produto"
              placeholder="Produto"
              valueKey="sigla"
              valueName="sigla"
              className={classes.select}
              dataSource={produtoLista}
              value={siglaProduto}
              onChange={(e) => {
                setSiglaProduto(e.target.value);
              }}
              permiteValorBranco
              styleForm={false}
            />
          </Loader>
        </Grid>
        <Grid item xs={12} sm={4} lg={2}>
          <SelectArredondado
            select
            id="Origem"
            name="Origem"
            label="Origem"
            placeholder="Origem"
            valueKey="valor"
            valueName="nome"
            className={classes.select}
            dataSource={[
              { nome: "Todos", valor: "" },
              { nome: "App", valor: "App" },
              { nome: "Área Logada", valor: "AreaLogada" },
              { nome: "Chat Web", valor: "Chat Web" },
              { nome: "Site Luz", valor: "SiteLuz" },
              { nome: "WhatsApp", valor: "WhatsApp" }
            ]}
            value={origem}
            onChange={(e) => {
              setOrigem(e.target.value);
            }}
            permiteValorBranco
            styleForm={false}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={2}>
          <InputDataWhite
            styleForm={false}
            type="text"
            id="DataInicial"
            label="Data Inicial"
            format="yyyy-MM-dd"
            customValue={dataInicialPesquisa}
            onChange={(data) => setDataInicialPesquisa(data)}
            maxDate={
              dataFinalPesquisa
                ? window.moment(dataFinalPesquisa)
                : window.moment()
            }
            autoOk
            variant="inline"
            renderIconShowHide={false}
            className={{ container: classes.inputData }}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={2}>
          <InputDataWhite
            styleForm={false}
            type="text"
            id="DataFinal"
            label="Data Final"
            format="yyyy-MM-dd"
            customValue={dataFinalPesquisa}
            onChange={(data) => setDataFinalPesquisa(data)}
            maxDate={window.moment().toDate()}
            autoOk
            minDate={window.moment(dataInicialPesquisa)}
            className={{ container: classes.inputData }}
          />
        </Grid>
        <Grid item xs={12} className="mt-1">
          <TabelaPaginada
            onChangeFiltrosTabela={onChangeFiltrosTabela}
            linhas={lista}
            colunas={colunas}
            pesquisar={textoParaBusca}
            filtrosAdicionais={filtroAdicionalTabela}
            paginaAtual={1}
            paginationPerPage={2}
            className={classes.tabelaPaginada}
          />
        </Grid>
      </Grid>
    </Loader>
  );
};

export default ListagemFaturaDistribuidoraPreLead;
