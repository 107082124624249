import { makeStyles } from '@mui/styles';
import { Base } from 'componentes/cores';
import { createTheme } from "@mui/material/styles";

const theme = createTheme();

export const useStyles = makeStyles(() => ({
  container: {
    margin: 0,
    width: '100%',
    "& .MuiGrid-root": {
      color: `${Base.BlackBastille} !important`
    },
    "& .MuiDropzoneArea-text": {
      color: `${Base.BlackBastille} !important`
    },
    "& .MuiInputBase-root": {
      height: "46px"
    },
    "& .MuiFormLabel-root": {
      lineHeight: "1"
    }
  },
  containerTitulo: {
    padding: '18px !important'
  },
  titulo: {
    fontSize: '18px'
  },
  containerData: {
    padding: '32px !important'
  },
  icon: {
    cursor: "pointer",
    float:"right",
    margin: "5px"
  },
  button: {
    fontSize: '18px',
  },

  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  rootTabs: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 224,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));