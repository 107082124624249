export default class FiltroAdicionalTabela {
  constructor(
    ordenacao,
    data,
    unidadeSelecionada,
    clienteSelecionado,
    empresaSelecionada
  ) {
    this.ordenacao = ordenacao;
    this.data = data;
    this.unidadeSelecionada = unidadeSelecionada;
    this.clienteSelecionado = clienteSelecionado;
    this.empresaSelecionada = empresaSelecionada;
  }
}
