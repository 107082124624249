import RetornoEndpointDto from "../../../../global/dto/retornoEndpointDto";
import { ListarDistribuidoraICMS } from "../../../../servicos/distribuidoraICMSServico";

export default class DistribuidoraICMSHelper {
  static async ListarDistribuidoraICMS(filtro) {
    try {
      const resultado = await ListarDistribuidoraICMS(filtro);

      return RetornoEndpointDto.Sucesso(
        "Distribuidora ICMS obtidas com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu um erro ao obter a lista de distribuidora ICMS"
      );
    }
  }
}
