import RetornoEndpointDto from "global/dto/retornoEndpointDto";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { ExportarPlanilha } from "submodules/AreaLogada/servicos/metricasRelatorioServico";

export default class Helper {
  static async ExportarPlanilha(dataInicio, dataFim) {
    try {
      const resultado = await ExportarPlanilha(dataInicio, dataFim);
      return RetornoEndpointDto.Sucesso(
        "Planilha obtida com sucesso",
        resultado
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Ocorreu um erro ao obter a planilha");
    }
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }

  static exibirSucesso(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem
      })
    );
  }

  static exibirAlerta(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "warning",
        mensagem
      })
    );
  }
}
