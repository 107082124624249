import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";

// Componentes
import Loader from "../../../../../componentes/loader";
import ModalConfirmacao from "../../../../../componentes/modalConfirmacao";
import TreeViewArvoreGerencial from "../../../../../componentes/treeViewArvoreGerencial";

// Redux
import { store } from "../../../../../global/redux";
import { alertaExibir } from "../../../../../global/redux/modulos/alertas/actions";
import { desabilitarFiltroLateral } from "../../../../../global/redux/modulos/usuario/actions";

// Serviços
import {
  deletarArvoresGerenciais,
  listarArvoresGerenciais,
  salvarArvoresGerenciais
} from "../../../../../servicos/arvoresGerenciaisServico";

const TreeViewContainer = ({
  onBuscar,
  onEditar,
  nodeEdicao,
  filtrosAtivos
}) => {
  const [carregandoArvoresGerencia, setCarregandoArvoreGerencial] = useState(
    false
  );

  useEffect(() => {
    store.dispatch(desabilitarFiltroLateral(true));

    return () => {
      store.dispatch(desabilitarFiltroLateral(false));
    };
  }, [desabilitarFiltroLateral]);

  const [arvoresGerenciais, setArvoresGerenciais] = useState([]);

  const obterArvoreGerencial = useCallback(async () => {
    try {
      setCarregandoArvoreGerencial(true);
      const lista = await listarArvoresGerenciais(filtrosAtivos);
      if (lista?.status === 200 && lista?.data?.arvoreGerencialColecao) {
        setArvoresGerenciais(lista?.data?.arvoreGerencialColecao ?? []);
      }
      setCarregandoArvoreGerencial(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoArvoreGerencial(false);
    }
  }, [filtrosAtivos]);

  useEffect(() => {
    obterArvoreGerencial();
  }, [obterArvoreGerencial]);

  const filtrarDados = useCallback(() => {
    const data = arvoresGerenciais.filter((arvoreGerencial) => {
      if (onBuscar && onBuscar.length > 2) {
        const regex = RegExp(`^${onBuscar.toLowerCase()}.*$`);

        let filtro = arvoreGerencial.descricao.toLowerCase().match(regex);
        if (filtro) return true;

        filtro = arvoreGerencial.arvoreGerencialColecao.filter((filho) =>
          filho.descricao.toLowerCase().match(regex)
        ).length;
        if (filtro) return true;

        filtro = arvoreGerencial.arvoreGerencialColecao.filter((filho) => {
          filtro = filho.arvoreGerencialColecao.filter((neto) =>
            neto.descricao.toLowerCase().match(regex)
          ).length;
          if (filtro) return true;
          return false;
        }).length;
        if (filtro) return true;

        filtro = arvoreGerencial.arvoreGerencialColecao.filter((filho) => {
          filtro = filho.arvoreGerencialColecao.filter(
            (neto) =>
              neto.arvoreGerencialColecao.filter((bisneto) =>
                bisneto.descricao.toLowerCase().match(regex)
              ).length
          ).length;
          if (filtro) return true;
          return false;
        }).length;

        if (filtro) return true;

        filtro = arvoreGerencial.arvoreGerencialColecao.filter((filho) => {
          filtro = filho.arvoreGerencialColecao.filter(
            (neto) =>
              neto.arvoreGerencialColecao.filter(
                (bisneto) =>
                  bisneto.arvoreGerencialColecao.filter((tataraneto) =>
                    tataraneto.descricao.toLowerCase().match(regex)
                  ).length
              ).length
          ).length;
          if (filtro) return true;
          return false;
        }).length;

        if (filtro) return true;

        return false;
      }
      return true;
    });

    return data;
  }, [arvoresGerenciais, onBuscar]);

  const nodesExpanded = useMemo(() => {
    if (onBuscar && onBuscar?.length > 2) {
      const itens = [];
      filtrarDados().forEach((arvoreGerencial) => {
        if (!itens.includes(arvoreGerencial.id)) {
          itens.push(String(arvoreGerencial.id));
        }
        if (arvoreGerencial?.arvoreGerencialColecao?.length) {
          arvoreGerencial.arvoreGerencialColecao.forEach((filho) => {
            if (!itens.includes(filho.id)) {
              itens.push(String(filho.id));
            }
            filho.arvoreGerencialColecao.forEach((neto) => {
              if (!itens.includes(neto.id)) {
                itens.push(String(neto.id));
              }
              filho.arvoreGerencialColecao.forEach((bisneto) => {
                if (!itens.includes(bisneto.id)) {
                  itens.push(String(bisneto.id));
                }
                bisneto.arvoreGerencialColecao.forEach((tataraneto) => {
                  if (!itens.includes(tataraneto.id)) {
                    itens.push(String(tataraneto.id));
                  }
                });
              });
            });
          });
        }
      });
      return itens;
    }
    return [];
  }, [onBuscar, filtrarDados]);

  const onSalvar = async (dados) => {
    try {
      setCarregandoArvoreGerencial(true);
      const salvou = await salvarArvoresGerenciais(dados.id, {
        ...dados
      });
      if (salvou) {
        onEditar();
        obterArvoreGerencial();
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: "A árvore gerencial foi salva com sucesso!"
          })
        );
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoArvoreGerencial(false);
    }
  };

  const [modalExclusao, setModalExclusao] = useState(false);
  const [mensagemModalExclusao, setMensagemModalExclusao] = useState(false);
  const [handleArvoreAtivoInativo, setHandleArvoreAtivoInativo] = useState([
    {}
  ]);

  const deletar = async (id, situacao) => {
    try {
      setModalExclusao(false);
      setCarregandoArvoreGerencial(true);
      const deletou = await deletarArvoresGerenciais(id, situacao);
      if (deletou) {
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: `A árvore gerencial foi ${
              situacao === "IN" ? "inativada" : "ativada"
            } com sucesso!`
          })
        );
        obterArvoreGerencial();
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoArvoreGerencial(false);
    }
  };

  const onDeletar = (item) => {
    if (handleArvoreAtivoInativo.some((e) => e.id === item.id)) {
      const arvore = handleArvoreAtivoInativo.find((o) => o.id === item.id);

      if (arvore.situacao === "AT") {
        setMensagemModalExclusao(
          "Tem certeza que deseja inativar essa Árvore Gerencial?"
        );
        setHandleArvoreAtivoInativo([{ id: item.id, situacao: "IN" }]);
      }

      if (arvore.situacao === "IN") {
        setMensagemModalExclusao(
          "Tem certeza que deseja ativar essa Árvore Gerencial?"
        );
        setHandleArvoreAtivoInativo([{ id: item.id, situacao: "AT" }]);
      }
    } else {
      if (item.situacao === "AT") {
        setMensagemModalExclusao(
          "Tem certeza que deseja inativar essa Árvore Gerencial?"
        );
        setHandleArvoreAtivoInativo([{ id: item.id, situacao: "IN" }]);
      }

      if (item.situacao === "IN") {
        setMensagemModalExclusao(
          "Tem certeza que deseja ativar essa Árvore Gerencial?"
        );
        setHandleArvoreAtivoInativo([{ id: item.id, situacao: "AT" }]);
      }
    }

    setModalExclusao(item);
  };

  const onConfirmarExclusao = (item) => {
    try {
      let situacao = "";
      if (item.situacao === "AT") {
        situacao = "IN";
      } else if (item.situacao === "IN") {
        situacao = "AT";
      }

      deletar(item.id, situacao);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  return (
    <Loader loading={carregandoArvoresGerencia}>
      <ModalConfirmacao
        item={modalExclusao}
        onConfirmar={(item) => onConfirmarExclusao(item)}
        mensagem={mensagemModalExclusao}
        onCancelar={() => setModalExclusao(false)}
      />
      <TreeViewArvoreGerencial
        dataSource={filtrarDados()}
        editable
        onEditar={(node) => onEditar(node)}
        nodeEdicao={nodeEdicao}
        onSalvar={(dados) => onSalvar(dados)}
        deletable
        onDeletar={(node) => onDeletar(node)}
        draggable
        nodesExpanded={onBuscar ? nodesExpanded : []}
        onRecarregar={(recarregar) =>
          recarregar ? obterArvoreGerencial() : false
        }
        lblBotaoCadastroRoot="Nova árvore principal"
        lblBotaoCadastroNivelFilho="Nova árvore nível 1"
        lblBotaoCadastroNivelNeto="Nova árvore nível 2"
        lblBotaoCadastroNivel3="Nova árvore nível 3"
        lblBotaoCadastroNivel4="Nova árvore nível 4"
      />
    </Loader>
  );
};

TreeViewContainer.propTypes = {
  onBuscar: PropTypes.string,
  onEditar: PropTypes.oneOfType([PropTypes.func]),
  nodeEdicao: PropTypes.oneOfType([PropTypes.object]),
  filtrosAtivos: PropTypes.oneOfType([PropTypes.any])
};

TreeViewContainer.defaultProps = {
  onBuscar: "",
  onEditar: () => {},
  nodeEdicao: {},
  filtrosAtivos: {}
};

export default TreeViewContainer;
