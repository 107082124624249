import { createTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { Base } from "componentes/cores";
import theme from "themes";

// eslint-disable-next-line no-underscore-dangle
const theme_ = createTheme();

export const useStyles = makeStyles(() => ({
  selectLeft: {
    paddingRight: 15,
    [theme_.breakpoints.up("md")]: {
      padding: 0
    }
  },
  selectRight: {
    paddingLeft: 15,
    [theme_.breakpoints.up("md")]: {
      padding: 0
    }
  },
  container: {
    margin: "0 0 24px",
    width: "100%",
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme.color.secondaryBorderColor}`
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: `${theme.color.secondaryBorderColor} !important`
    },
    "& .MuiFormLabel-root": {
      color: `${theme.color.secondaryBorderColor} !important`
    },
    "& .MuiInputBase-root": {
      color: `${theme.color.secondaryBorderColor} !important`
    },
    "& .MuiSvgIcon-root": {
      color: `${theme.color.secondaryBorderColor} !important`
    },
    "& .MuiGrid-root": {
      paddingTop: 5
    }
  },
  itemFiltro: {
    display: "flex",
    justifyContent: "flex-end"
  },
  buttonFiltro: {
    fontSize: "18px",
    maxWidth: "500px"
  },
  linhaTooltip: {
    cursor: "default",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  fundoCelula: {
    height: "100%"
  },
  celulaBaixar: {
    display: "flex",
    justifyContent: "center"
  },
  celulaVideo: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center"
  },
  celulaVideoMobile: {
    display: "flex",
    justifyContent: "flex-start",
    textAlign: "center"
  },
  fundoCelulaMobile: {
    backgroundColor: `${theme.color.novoListItemBackgroundHover}`,
    border: "none",
    borderRadius: 10,
    borderWidth: 1,
    padding: "25px",
    marginBottom: "13px",
    boxShadow: theme_.shadows[1]
  },
  fundoCelulaTransparente: {
    backgroundColor: Base.BlackRussian
  },
  nomeRelatorio: {
    color: `${theme.color.primary}`,
    fontWeight: "bold",
    fontSize: "16px",
    marginBottom: 10
  },
  tipoRelatorio: {
    fontSize: "12px"
  },
  dataUpload: {
    fontSize: 16
  },
  botao: {
    color: `${theme.color.botaoDownloadFatura} !important`,
    fontSize: "32px",
    "&:disabled": {
      opacity: "0.4"
    }
  },
  botaoMobile: {
    "&:disabled": {
      opacity: "0.4"
    }
  },
  barraSuperior: {
    fontSize: "18px",
    fontStyle: "italic",
    height: "42px",
    textAlign: "center",
    color: `${theme.color.textoBarra}`,
    width: "100%"
  },
  arrowBack: {
    color: `${theme.color.textoBarra}`,
    position: "absolute",
    left: 0,
    paddingLeft: 15,
    zIndex: 9999
  }
}));
