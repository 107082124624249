import { makeStyles } from "@mui/styles";
import theme from "themes";
import { Base } from "../../../../componentes/cores";

export const useStyles = makeStyles(() => ({
  inputFile: {
    display: "none"
  },
  customFile: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: "row-reverse",
    backgroundColor: "transparent",
    borderRadius: theme.borda.input,
    borderColor: theme.color.primary,
    borderWidth: "2px",
    borderStyle: "dashed",
    color: Base.Preto,
    width: "100%",
    textAlign: "center",
    cursor: "pointer"
  },
  textContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: Base.Black,
    fontSize: "18px"
  },
  icon: {
    padding: 0,
    "& svg": {
      color: Base.Black,
      marginRight: "10px",
      width: "20px",
      height: "20px"
    },
    "& :hover": {
      backgroundColor: "transparent"
    }
  },
  text: {
    color: Base.Black,
    marginBottom: 0
  }
}));
