import RetornoEndpointDto from "global/dto/retornoEndpointDto";

import { obterGestor } from "../../../../servicos/usuariosServico";

class DashboardHelper {

  static async obterGestor() {
    try {

      const { data } = await obterGestor();

      return RetornoEndpointDto.Sucesso(
        "Gestor obtido com Sucesso",
        data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        error?.response?.data?.message ?? "Ocorreu uma falha ao buscar o gestor"
      );
    }
  }
}

export default DashboardHelper;