import { Grid } from "@mui/material";
import React from "react";

// eslint-disable-next-line react/prop-types
function TabelaSemDados({ className, texto }) {
  return (
    <>
      <Grid container>
        <Grid item xs={12} className={className}>
          <h4>{texto || "Nenhum registro encontrado"}</h4>
        </Grid>
      </Grid>
    </>
  );
}

export default TabelaSemDados;
