import React from "react";
import PropTypes from "prop-types";
import shortid from "shortid";

const Contas = ({ dados, register }) => {
  return (
    <div className="ml-5 mt-n4">
      {dados?.length &&
        dados.map((conta, c) => (
          <div key={c} className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="conta"
              id={shortid.generate()}
              value={conta?.clienteId}
              ref={register}
            />
            <label className="form-check-label" htmlFor="conta">
              {conta?.nomeCliente}
            </label>
          </div>
        ))}
    </div>
  );
};

Contas.propTypes = {
  dados: PropTypes.oneOfType([PropTypes.any]),
  register: PropTypes.oneOfType([PropTypes.any]).isRequired
};

Contas.defaultProps = {
  dados: {}
};

export default Contas;
