/* eslint-disable jsx-a11y/control-has-associated-label */
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Box, Card, FormControl, FormHelperText } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useStyles } from "./style";

const Contador = ({ id, label, onChange, contador, icone, erro }) => {
  const classes = useStyles();
  const [contadorInterno, setContadorInterno] = useState(contador);

  const adicionar = (e) => {
    e.stopPropagation();
    onChange(contador + 1);
    setContadorInterno(contadorInterno + 1);
  };

  const subtrair = (e) => {
    e.stopPropagation();
    if (contador > 0) {
      onChange(contadorInterno - 1);
      setContadorInterno(contadorInterno - 1);
    }
  };

  return (
    <FormControl fullWidth error={Boolean(erro[id])} className="mb-3">
      <Box className={`${classes.container}`}>
        <Box className={classes.label}>
          {icone && icone}
          {label && <p>{label}</p>}
        </Box>
        <Card
          className={`${classes.contador} ${
            contadorInterno === 0 && classes.semSombra
          }`}
        >
          <Box
            className={
              contadorInterno > 0 ? classes.visivel : classes.invisivel
            }
            display="flex"
          >
            <button
              type="button"
              onClick={(e) => subtrair(e)}
              className={classes.botao}
            >
              <RemoveIcon />
            </button>
            <span>{contadorInterno}</span>
          </Box>
          <button
            type="button"
            onClick={(e) => adicionar(e)}
            className={classes.botao}
          >
            <AddIcon />
          </button>
        </Card>
      </Box>
      {erro[id] && <FormHelperText>{erro[id]?.message}</FormHelperText>}
    </FormControl>
  );
};

Contador.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  onChange: PropTypes.func,
  contador: PropTypes.number,
  icone: PropTypes.oneOfType([PropTypes.any]),
  erro: PropTypes.oneOfType([PropTypes.any])
};

Contador.defaultProps = {
  id: "contador",
  label: "",
  onChange: () => {},
  contador: 0,
  icone: null,
  erro: {}
};

export default Contador;
