import React, { useEffect } from "react";

// Ícones
import QueueOutlinedIcon from "@mui/icons-material/QueueOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";

// Componentes
import { Grid } from "@mui/material";

// DTO
import { RotasDTO } from "../../../global/rotas/rotasUrlDto";

// Redux
import { store } from "../../../global/redux";
import { salvarMenuSelecionadoColecao } from "../../../global/redux/modulos/rotas/actions";

// Styles
import {
  useStyles,
  NovaCotacao,
  TextoNovaCotacao,
  MinhasCotacoes,
  TextoMinhasCotacoes,
  Chat,
  Calendario,
  Dashboard,
  LinkedIn,
  Site
} from "./style";

const Demo = () => {
  const classes = useStyles();

  useEffect(() => {
    store.dispatch(salvarMenuSelecionadoColecao([]));
  }, []);

  return (
    <Grid container spacing={0} justifyContent="flex-start">
      <Grid container spacing={0} className="mb-4">
        <Grid item className="mr-4">
          <Grid container spacing={0} className="d-block">
            <Grid item className="mb-4">
              <NovaCotacao to={`${RotasDTO.Demo}/cotacao/inicial`}>
                <TextoNovaCotacao>
                  Nova cotação
                  <QueueOutlinedIcon className={classes.icon} />
                </TextoNovaCotacao>
              </NovaCotacao>
            </Grid>
            <Grid item>
              <MinhasCotacoes to={RotasDTO.Cotacoes}>
                <TextoMinhasCotacoes>
                  Minhas cotações
                  <AssignmentOutlinedIcon className={classes.icon} />
                </TextoMinhasCotacoes>
              </MinhasCotacoes>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={0} className="d-block">
            <Grid item className="mb-4">
              <Calendario to={RotasDTO.Demo} />
            </Grid>
            <Grid item className="mb-4">
              <Dashboard to={RotasDTO.Dashboard} />
            </Grid>
            <Grid item className={classes.divChat}>
              <Chat to={RotasDTO.Demo} />
            </Grid>
            <Grid item>
              <Grid container spacing={0} justifyContent="space-between">
                <Grid item>
                  <LinkedIn
                    href="https://www.linkedin.com/company/delta-energia/"
                    alt="Delta Energia"
                    target="_blank"
                  />
                </Grid>
                <Grid item>
                  <Site
                    href="http://www.deltaenergia.com.br/"
                    alt="Delta Energia"
                    target="_blank"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Demo;
