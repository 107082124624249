const enumPassoOnboarding = {
  AssinouContrato: 1,
  PrimeiroAcesso: 2,
  UploadDocumentos: 3,
  DocumentoValidacaoJuridico: 4,
  DocumentosValidados: 5,
  TrocaTitularidadeAndamento: 6,
  TrocaTitularidadeFinalizada: 7,
  EmAgendamentoMedidor: 8,
  MedidorAgendado: 9,
  AguardandoInstalacao: 10,
  MedidorInstalacao: 11,
  MedidorInstalado: 12,
  OnboardFinalizado: 13,
  Cancelado: 14
};

export default enumPassoOnboarding;
