import RetornoEndpointDto from "../../../../global/dto/retornoEndpointDto";
import { store } from "../../../../global/redux";
import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";
import { listarUsuarios, listarUsuariosClienteProspect } from "../../../../servicos/usuariosServico";

export default class ListagemUsuarioHelper {
  static async listarTodosUsuarios(filtro) {
    try {
      const retorno = await listarUsuarios(filtro);
      return RetornoEndpointDto.Sucesso(
        "Dados obtidos com sucesso",
        retorno.data
      );
    } catch (error) {
      const mensagem =
        error?.response?.message ??
        "Ocorreu uma falha ao consultar os usuários";

      return RetornoEndpointDto.Erro(mensagem);
    }
  }

  static async listarTodosUsuariosClienteProspect(filtro) {
    try {
      const retorno = await listarUsuariosClienteProspect(filtro);
      return RetornoEndpointDto.Sucesso(
        "Dados obtidos com sucesso",
        retorno.data
      );
    } catch (error) {
      const mensagem =
        error?.response?.message ??
        "Ocorreu uma falha ao consultar os usuários";

      return RetornoEndpointDto.Erro(mensagem);
    }
  }

  static exibirMensagemErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "warning",
        mensagem: mensagem ?? "Erro interno, entre em contato com o suporte!"
      })
    );
  }
}


