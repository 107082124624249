import { obterClienteAgenteCCEE } from "../../../../servicos/clientesServico";
import RetornoEndpointDto from "../../../../global/dto/retornoEndpointDto";
import AgenteDto from "./dto/agenteDto";
import {
  ObterPorId,
  ObterPorSigla,
  SalvarAgente
} from "../../../../servicos/agenteServico";
import { executarIntegracaoPerfilCCEE } from "../../../../servicos/perfilAgenteService";

class CadastroAgenteHelper {
  static async obterClientesAgenteCCEE() {
    try {
      const response = await obterClienteAgenteCCEE();

      if (
        response.status === 204 ||
        (response.data?.clientes?.length === 0 ?? true)
      )
        return new RetornoEndpointDto(false, "Nenhum cliente encontrado");

      return new RetornoEndpointDto(
        true,
        "Clientes obtidos com sucesso",
        response.data.clientes
      );
    } catch (error) {
      return new RetornoEndpointDto(
        false,
        "Ocorreu uma falha ao obter os clientes"
      );
    }
  }

  static async ObterPorId(id) {
    try {
      const response = await ObterPorId(id);

      if (response.status === 204)
        return RetornoEndpointDto.Erro(
          `Não encontrado nenhum agente com o id ${id}`
        );

      const agente = response.data;

      return RetornoEndpointDto.Sucesso(
        "Agente obtido com sucesso",
        new AgenteDto(
          agente.cnpj,
          agente.razaoSocial,
          agente.codigoAgenteCCEE,
          agente.siglaAgenteCCEE,
          agente.ativo,
          agente.clienteId,
          agente.descricaoDoAgente,
          agente.agenteCCEEId
        )
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu uma falha ao obter o Agente por id"
      );
    }
  }

  static async ObterPorSigla(sigla) {
    try {
      const response = await ObterPorSigla(sigla);

      if (response.status === 204)
        return RetornoEndpointDto.Sucesso(
          "Não possui agente cadastrado com esta sigla"
        );

      const agente = response.data;

      return RetornoEndpointDto.Sucesso(
        "Agente obtido com sucesso",
        new AgenteDto(
          agente.cnpj,
          agente.razaoSocial,
          agente.codigoAgenteCCEE,
          agente.siglaAgenteCCEE,
          agente.ativo,
          agente.clienteId,
          agente.descricaoDoAgente,
          agente.agenteCCEEId
        )
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Ocorreu uma falha ao obter o Agente");
    }
  }

  static async SalvarAgente(agente) {
    try {
      if (!agente)
        return RetornoEndpointDto.Erro(
          "É necessário informar um agente para a persistencia"
        );

      const { data } = await SalvarAgente(agente);

      executarIntegracaoPerfilCCEE();

      return RetornoEndpointDto.Sucesso(
        "Agente cadastrado com Sucesso",
        new AgenteDto(
          data.cnpj,
          data.razaoSocial,
          data.codigoAgenteCCEE,
          data.siglaAgenteCCEE,
          data.ativo,
          data.clienteId,
          data.descricaoDoAgente,
          data.agenteCCEEId
        )
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        error?.response?.data?.message ?? "Ocorreu uma falha ao salvar o Agente"
      );
    }
  }
}

export default CadastroAgenteHelper;
