import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  container: {
    height: "75vh",
    marginBottom: "10px",
    width: "100%",
    "& iframe": {
      border: "none"
    }
  }
}));
