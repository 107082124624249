import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";

// Componentes
import { Grid, Card, Box, Typography } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import iconeMedicao from "assets/home/icone-medicao.png";
import iconeCertificado from "assets/home/icone-certificado.png";
import Loader from "componentes/loader";
import { getByUnidadeId } from "servicos/boletasGDServico";
import {
  downloadClienteContrato,
  obterClienteContratoPorUnidadeId
} from "servicos/clienteContratoServico";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import SemDados from "../SemDados";

// Styles
import { useStyles, BotaoTransparent, Chip, Icone, IconeMenor } from "./style";

const MeusPlanos = ({ unidadeConsumidora }) => {
  const classes = useStyles();
  const [carregando, setCarregando] = useState(false);
  const [dadosPlano, setDadosPlano] = useState();
  const [dadosContrato, setDadosContrato] = useState([]);

  const obterStatus = (status) => {
    switch (status) {
      case 1:
        return <Chip cor="#2ABF6F">Em ativação</Chip>;
      case 2:
        return <Chip cor="#2ABF6F">Ativo</Chip>;
      case 3:
        return <Chip cor="#BF2A2A">Finalizado</Chip>;
      case 4:
        return <Chip cor="#BF2A2A">Rejeitado</Chip>;
      default:
        return <Chip cor="#BF2A2A">Rejeitado</Chip>;
    }
  };

  const obterDataFim = (status, dataInicio) => {
    switch (status) {
      case 1:
        return "Fim: indefinido!";
      case 2:
        return `Fim: ${moment(dataInicio)
          .add(3, "years")
          .format("DD/MM/YYYY")}`;
      case 3:
        return `Fim: ${moment(dataInicio)
          .add(5, "years")
          .format("DD/MM/YYYY")}`;
      case 4:
        return `Fim: ${moment(dataInicio)
          .add(1, "years")
          .format("DD/MM/YYYY")}`;
      case 5:
        return `Fim: ${moment(dataInicio)
          .add(2, "years")
          .format("DD/MM/YYYY")}`;
      default:
        return "Fim: indefinido!";
    }
  };

  const obterClientePlano = async () => {
    try {
      setCarregando(true);
      const { data } = await getByUnidadeId(
        unidadeConsumidora.unidadeConsumidoraId
      );

      if (data) setDadosPlano(data);
      else setDadosPlano();
    } catch (error) {
      setDadosPlano();
    } finally {
      setCarregando(false);
    }
  };

  const obterClienteContrato = async () => {
    try {
      const response = await obterClienteContratoPorUnidadeId(
        unidadeConsumidora?.unidadeConsumidoraId
      );

      setDadosContrato(response.data);
    } catch (ex) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            ex?.response?.data?.message ?? "Não foi possivel obter contrato!"
        })
      );
    }
  };

  const download = async (contrato) => {
    setCarregando(true);
    const nome = contrato?.nomeGuid;
    if (!nome) {
      setCarregando(false);
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Não foi possivel obter contrato!"
        })
      );
      return;
    }

    try {
      await downloadClienteContrato(
        unidadeConsumidora?.unidadeConsumidoraId,
        nome
      );

      store.dispatch(
        alertaExibir({
          tipo: "success",
          mensagem: "Contrato obtido com sucesso!"
        })
      );
    } catch (ex) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            ex?.response?.data?.message ?? "Não foi possivel obter contrato!"
        })
      );
    } finally {
      setCarregando(false);
    }
  };
  useEffect(() => {
    if (unidadeConsumidora) {
      obterClientePlano();
      obterClienteContrato();
    } else setDadosPlano();
  }, [unidadeConsumidora]);

  return (
    <Loader loading={carregando}>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: { xs: "space-between" },
            alignItems: { xs: "center" },
            background: "#FBFBFD"
          }}
          p={2}
        >
          <h3 className={classes.titulo}>Meus planos</h3>
          {dadosPlano && obterStatus(dadosPlano?.statusId)}
        </Grid>
        {dadosPlano ? (
          <>
            <Grid item xs={12} px={2} pt={2} pb={1}>
              <Box px={1} pt={2}>
                <h3 className={classes.subtitulo}>Liberdade total</h3>
                <Typography>
                  {`Tenha ${dadosPlano?.desconto}% de desconto garantido na sua conta de energia, todo
                  mês.`}
                </Typography>
              </Box>
              <Box p={1}>
                <strong className={classes.texto}>
                  N° da instalação na distribuidora:
                </strong>
                <ul className={classes.lista}>
                  <li>{unidadeConsumidora?.numeroInstalacao || "-"}</li>
                </ul>
              </Box>
              <Box px={1} pb={2}>
                <strong className={classes.texto}>Vigência:</strong>
                <ul className={classes.lista}>
                  <li>
                    Início: {moment(dadosPlano.dataInicio).format("DD/MM/YYYY")}
                  </li>
                  <li>
                    {obterDataFim(
                      dadosPlano?.statusId || 1,
                      dadosPlano.dataInicio
                    )}
                  </li>
                </ul>
              </Box>
            </Grid>
            {dadosContrato?.length > 1 && (
              <>
                <Grid item xs={12} px={2} py={1}>
                  <Card className={classes.card}>
                    <Box
                      xs={12}
                      sx={{
                        display: "flex",
                        justifyContent: { xs: "space-between" },
                        alignItems: { xs: "center" }
                      }}
                      p={1}
                    >
                      <Box className={classes.texto}>
                        <Icone src={iconeMedicao} alt="ícone contrato" />
                        <strong>Contrato</strong>
                      </Box>
                      <BotaoTransparent
                        onClick={() => download(dadosContrato[0])}
                      >
                        <DownloadIcon />
                      </BotaoTransparent>
                    </Box>
                  </Card>
                </Grid>
                <Grid item xs={12} px={2} pt={1} pb={2}>
                  <Card className={classes.card}>
                    <Box
                      xs={12}
                      sx={{
                        display: "flex",
                        justifyContent: { xs: "space-between" },
                        alignItems: { xs: "center" }
                      }}
                      p={1}
                    >
                      <Box className={classes.texto}>
                        <IconeMenor
                          src={iconeCertificado}
                          alt="ícone certificado"
                        />
                        <strong>Certificado do contrato</strong>
                      </Box>
                      <BotaoTransparent
                        onClick={() => download(dadosContrato[1])}
                      >
                        <DownloadIcon />
                      </BotaoTransparent>
                    </Box>
                  </Card>
                </Grid>
              </>
            )}
          </>
        ) : (
          <SemDados texto="Ainda não conseguimos mostrar os dados do seu plano! Em breve, eles estarão disponíveis para você." />
        )}
      </Grid>
    </Loader>
  );
};

MeusPlanos.propTypes = {
  unidadeConsumidora: PropTypes.shape({
    unidadeConsumidoraId: PropTypes.number,
    numeroInstalacao: PropTypes.string
  })
};

MeusPlanos.defaultProps = {
  unidadeConsumidora: null
};

export default MeusPlanos;
