import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { CloudDownloadOutlined } from "@mui/icons-material";
import { useStyles } from "./style";

const DownloadButton = ({ id, onDownload, label }) => {
  const classes = useStyles();

  return (
    <button
      type="button"
      id={id}
      onClick={onDownload}
      className={classes.customFile}
    >
      <Box className={classes.textContainer}>
        <Box className={classes.icon}>
          <CloudDownloadOutlined />
        </Box>
        <p className={classes.text}>{label}</p>
      </Box>
    </button>
  );
};

DownloadButton.propTypes = {
  onDownload: PropTypes.func,
  id: PropTypes.string,
  label: PropTypes.string
};

DownloadButton.defaultProps = {
  onDownload: () => {},
  id: "download",
  label: ""
};

export default DownloadButton;
