import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const apiCalculoCustos = () => obterApi(MICROSERVICO.CALCULOCUSTOS);
const apiMeasurement = () => obterApi(MICROSERVICO.MEASUREMENT);

export const buscarCalculoCustosPdf = async (unidadeSelecionada, mes, ano) => {
  return apiCalculoCustos().get(
    `v1/CalcularCustos/GetCalculoCustosPdf/${unidadeSelecionada}/${mes}/${ano}`
  );
};

export const executarCalculoCusto = async (dataReferencia) => {
  return apiMeasurement().post(
    `v1/CalculoCustos/EnviarInsumosParaCalcularCustoPreFaturamentoJob?dataReferencia=${dataReferencia}`
  );
};

export const executarCalculoCustoPorUnidade = async (unidadeId, mes, ano) => {
  return apiCalculoCustos().post(
    `v1/CalcularCustos/RefazerCalculoCustos?unidadeId=${unidadeId}&mes=${mes}&ano=${ano}`
  );
};
