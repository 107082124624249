/* eslint-disable import/no-unresolved */
import moment from "moment";
import React, { useState, useEffect, useCallback } from "react";
import { Grid, Card, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox } from "@mui/material";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { InputTextoData } from "componentes";
import { useStyles, Tabela } from "./style";
import { getMigracaoeOutros, getDistribuidoras } from "../../../servicos/migracaoeOutros";
import CloseIcon from "@mui/icons-material/Close";
import RotasDTO  from "../../../rotas/rotasUrlDto";  
import { useHistory } from "react-router-dom";

const MigracaoeOutrosVisualizar = () => {
    const classes = useStyles();
    const history = useHistory();

    const onFechar = (id) => {
       history.push(`${RotasDTO.ProjecaoInsumoListar}`);
     }
    const [valores, setValores] = useState([]);
    const [distribuidoras, setDistribuidoras] = useState([]);
    const [distribuidora, setDistribuidora] = useState("");
    const [anoInicio, setAnoInicio] = useState(moment());
    const [anoFim, setAnoFim] = useState(moment());
    const [parameterChanged, setParameterChanged] = useState(false);
    const [checkPadrao, setCheckPadrao] = useState(false);

    const obterDistribuidoras = useCallback(async () => {
        try {
            const response = await getDistribuidoras();
            if (response?.status === 200 && response?.data) {
                setDistribuidoras(response?.data);
            }
        } catch (e) {
            console.info(e);
        }
    });

    useEffect(() => {
        if (!distribuidoras.length) obterDistribuidoras();
    }, []);

    const obterValores = async () => {
        try {
            const response = await getMigracaoeOutros(checkPadrao, distribuidora, anoInicio.format("yyyy"), anoFim.format("yyyy"));
            
            console.info(response);

            if (response.status === 200 && response.data) {
                setParameterChanged(false);
                setValores(response?.data);
            } else {
                setParameterChanged(false);
                setValores([]);
                store.dispatch(
                    alertaExibir({
                        tipo: "warning",
                        mensagem: "Não há dados para exibição."
                    })
                );
            }
        } catch (exception) {
            setParameterChanged(false);
            store.dispatch(
                alertaExibir({
                    tipo: "warning",
                    mensagem:
                        exception?.response?.data?.message ??
                        "Erro interno, entre em contato com o suporte!"
                })
            );
        }
    };

    const handleChangeDistribuidora = async (event) => {
        setDistribuidora(event.target.value);
        setParameterChanged(true);
    };

    const handleChangeAnoInicio = async (data) => {
        setAnoInicio(moment(data));
        setParameterChanged(true);
    }

    const handleChangeAnoFim = async (data) => {
        setAnoFim(moment(data));
        setParameterChanged(true);
    }

    const handleChangePadrao = (event) => {
        setCheckPadrao(event.target.checked);
        setParameterChanged(true);
    }

    useEffect(() => {
        if (parameterChanged) obterValores();
    }, [parameterChanged]);

    return <>
        <Grid container className={classes.containerTitulo}>
            <Grid item xs={11} className={classes.titulo}>
                Planilha de Parâmetros Projeção Mercado - Migração e Outros
            </Grid>
            <Grid item xs={1}>             
                <CloseIcon onClick={onFechar} className={classes.icon}/>
            </Grid>   
        </Grid>
        <Grid container sm={12}>
            <Grid container spacing={4} className={classes.container} justifyContent="flex-end" alignItems="center">
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={checkPadrao}
                            onChange={handleChangePadrao}
                            color="primary"
                            style={{paddingRight: "5px", fontSize: "2.5rem"}}
                        />
                    }
                    label="Padrão"
                    style={{color: "white", fontSize: "1.7rem"}}
                />
                <Grid item sm={3} className={classes.customSelect}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="perid">Distribuidora</InputLabel>
                        <Select
                            labelId="distr"
                            id="distr"
                            value={distribuidora ?? ""}
                            onChange={handleChangeDistribuidora}
                            fullWidth
                            readOnly={checkPadrao}>
                            {distribuidoras.map((dist) => (
                                <MenuItem value={dist.idDistribuidora}>{dist.titulo}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item sm={2}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputTextoData
                            type="text"
                            id="anoInicio"
                            name="anoInicio"
                            label="Ano Início"
                            customValue={anoInicio}
                            format="yyyy"
                            onChange={(data) => handleChangeAnoInicio(data)}
                            minDate={moment().subtract(10, "years").toDate()}
                            views={["year"]}
                            renderIconShowHide={false}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={2} style={{marginLeft: "15px"}}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputTextoData
                            type="text"
                            id="anoFinal"
                            name="anoFinal"
                            label="Ano Fim"
                            customValue={anoFim}
                            format="yyyy"
                            onChange={(data) => handleChangeAnoFim(data)}
                            minDate={moment().subtract(10, "years").toDate()}
                            views={["year"]}
                            renderIconShowHide={false}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </Grid>
        <Grid container spacing={4} p={2} className={classes.container}>
            <Grid item sm={12}>
                <Card className={classes.container}>
                {valores.length > 0 &&
                    <Grid container className={classes.container}>
                        <Grid item sm={12} style={{ paddingTop: "0px" }}>
                            <div className={classes.customPosition}>
                                <Tabela>
                                    <Tabela.Thead>
                                        <Tabela.Tr>
                                            <Tabela.Th colSpan="12">MIGRAÇÃO E OUTROS</Tabela.Th>
                                        </Tabela.Tr>
                                        <Tabela.Tr>
                                            <Tabela.Th>ANO</Tabela.Th>
                                            <Tabela.Th>MIGRAÇÃO TUSD</Tabela.Th>
                                            <Tabela.Th>MIGRAÇÃO TE</Tabela.Th>
                                            <Tabela.Th>OUTROS TUSD</Tabela.Th>
                                            <Tabela.Th>OUTROS TE</Tabela.Th>
                                        </Tabela.Tr>
                                    </Tabela.Thead>
                                    <Tabela.Tbody>
                                        {valores?.map((item) => (
                                            <Tabela.Tr>
                                                <Tabela.Td>{item.ano}</Tabela.Td>
                                                <Tabela.Td>{`${(item.migracaoTUSD * 100).toFixed(2)}%`}</Tabela.Td>
                                                <Tabela.Td>{`${(item.migracaoTE * 100).toFixed(2)}%`}</Tabela.Td>
                                                <Tabela.Td>{`${(item.outrosTUSD * 100).toFixed(2)}%`}</Tabela.Td>
                                                <Tabela.Td>{`${(item.outrosTE * 100).toFixed(2)}%`}</Tabela.Td>
                                            </Tabela.Tr>
                                        ))}
                                    </Tabela.Tbody>
                                </Tabela>
                            </div>
                        </Grid>
                    </Grid>
                }
                </Card>
            </Grid>
        </Grid>
    </>;
}

export default MigracaoeOutrosVisualizar;
