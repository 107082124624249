import React from "react";
import { Box } from "@mui/material";
import { useStyles } from "./style";


const MensagemSemDesconto = () => {
    const classes = useStyles();
    return (
        <Box
            bgcolor="rgb(245, 245, 247)"
            p={2}
            mb={2}
            borderRadius="8px"
            textAlign="center"
        >
            <Box
               fontSize={{ xs: "22px", lg: "40px"}} 
               fontWeight="700"
               color="#332053"
            >
                Com a LUZ, você têm
            </Box>
            <Box
                fontSize={{ xs: "32px", lg: "45px"}}
                fontWeight="700"
                className={classes.text}
            >
                Contratação simples, 100% digital
            </Box>
            <Box
                fontSize={{ xs: "25px", lg: "28px"}}
                fontWeight="700"
                color="#332053"
            >
                Condições Claras e simples, sem surpresas
            </Box>
            <Box
                fontSize={{ xs: "32px", lg: "45px"}}
                fontWeight="700"
                className={classes.text}
            >
                Zero custos ou taxas extras
            </Box>
            <Box
                fontSize={{ xs: "25px", lg: "28px"}}
                fontWeight="700"
                color="#332053"
            >
                Sem taxa de adesão ou mensalidade
            </Box>
        </Box>
    )
}

export default MensagemSemDesconto;