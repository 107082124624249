/* eslint-disable import/no-unresolved */
import React, { useState } from "react";
import moment from "moment";
import { useForm } from "react-hook-form";
import { Card, Grid } from "@mui/material";
import UploadDropArea from "componentes/upload";
import Botao from "componentes/botao";
import InputData from "componentes/inputTextoData";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { useHistory } from "react-router-dom";
import RotasDTO  from "../../../../../rotas/rotasUrlDto";
import { uploadContratroEER } from "../../../../../servicos/premissasBrasil";
import ResultadoOperacao from 'componentes/resultadoOperacao';
import { useStyles } from "./style";

const ContratosEerImportar = () => {
  const classes = useStyles();
  const history = useHistory();
  const usuarioGlobal = useSelector((state) => state.usuario);

  const { handleSubmit } = useForm({
    reValidateMode: "onSubmit",
  });

  const [dataReferencia, setDataReferencia] = useState(null);
  const handleChangeDataReferencia = (data) => setDataReferencia(data);

  const msgPadraoUpload = "Solte o arquivo para anexá-lo ou procurar";
  const [file, setFile] = useState(false);
  const [fileName, setFileName] = useState(msgPadraoUpload);
  const [carregandoArquivo, setCarregandoArquivo] = useState(false);
  const [progressMesage, setProgressMessage] = useState("");
  const [resultadoOperacao, setResultadoOperacao] = useState(false);
  const [resultadoOperacaoCabecalho, setResultadoOperacaoCabecalho] = useState("");
  const [resultadoOperacaoObj, setResultadoOperacaoObj] = useState({
    "mensagens": []
  });

  const processarArquivo = (arquivo) => {
    if (arquivo != null && arquivo[0] != null) {
      setFile(arquivo[0].file);
      setFileName(arquivo[0].file.name);
    }
  };

  const onAddUpload = (arquivo) => {
    processarArquivo(arquivo);
  };

  const onCancelar = () => {
    setFile(false);
    setFileName(msgPadraoUpload);
    setCarregandoArquivo(false);
    setResultadoOperacao(false);
    setResultadoOperacaoCabecalho("");
    setResultadoOperacaoObj({
      "mensagens": []
    });
  }


  const EnviarForm = async () => {
    if (file && dataReferencia) {
      try {

        setCarregandoArquivo(true);
        setResultadoOperacao(false);
        setProgressMessage("Importando o arquivo... Por favor, aguarde.");

        const response = await uploadContratroEER(file, moment(dataReferencia).format("MM-DD-YYYY"), usuarioGlobal?.usuario?.id, usuarioGlobal?.usuario?.usuarioNome);
        if (response?.status === 200 && response?.data) {
          setFile(false);
          setFileName(msgPadraoUpload);
          setDataReferencia(null);
          setCarregandoArquivo(false);
          setResultadoOperacao(true); 

          let dadosRetorno = response?.data;
          
          let mensagemRetorno = ["Processo de Importação Finalizado"];

          if (dadosRetorno.length !==null && dadosRetorno.length !== undefined && dadosRetorno.length > 1){
            
            let valores = dadosRetorno?.[1];
            
            if(valores.length !==null && valores.length !== undefined && valores.length > 0){
              for (var i = 0; i < valores?.length; i++) {
                mensagemRetorno.push(valores[i].Mensagem); 
              } 
            }
          }
          setResultadoOperacaoCabecalho(`Contratos EER - Importação do arquivo ${file?.file?.name}`)
          setResultadoOperacaoObj({"mensagens": mensagemRetorno });    

        } else if (response?.status === 202 && response?.data) {
            setFile(false);
            setFileName(msgPadraoUpload);
            setDataReferencia(null);
            setCarregandoArquivo(false);
            setResultadoOperacao(true); 
            let dadosRetorno = response?.data;
            setResultadoOperacaoCabecalho(`Importação EER - Falhas da Importação`)
            setResultadoOperacaoObj({"mensagens": dadosRetorno.map((item)=> {return item.Mensagem}) });      
        }
      } catch (error) {
        console.log(error.response);
        setFile(false);
        setFileName(msgPadraoUpload);
        setDataReferencia(null);
        setCarregandoArquivo(false);
        setResultadoOperacao(true);
        setResultadoOperacaoCabecalho(`Contratos EER - Importação do arquivo ${file?.file?.name}`)
        setResultadoOperacaoObj({"mensagens": [error.message?? "Ocorreu um erro não previsto"]});
      }
    } else {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "É necessário informar o arquivo e/ou data de referência!"
        })
      );
    }
  }

  const aoEnviarForm = async () => {
    if (file) {
      EnviarForm();
    }
  };
  
  const onFechar = () => {
    history.push(`${RotasDTO.ProjecaoInsumoListar}`);
  }

  return (
    <form className="needs-validation" onSubmit={handleSubmit(aoEnviarForm)}>
      <ResultadoOperacao
        item={resultadoOperacaoObj}
        cabecalhoResultado={resultadoOperacaoCabecalho}
        onClose={() => onFechar()}
      />
      {resultadoOperacao === false ?
      <Card>
        <Grid container className={classes.containerTitulo}>
           <Grid item xs={11} className={classes.titulo}>
             Importação de Contratos EER
           </Grid>
            <Grid item xs={1}>             
                <CloseIcon onClick={onFechar} className={classes.icon} />
            </Grid>   
        </Grid>
        {carregandoArquivo === false ?
        <Grid container p={2} spacing={4} className={classes.container}>
          <Grid item className={classes.containerData} style={{width: "300px"}}>
            <InputData
              type="text"
              label="Data de Referência"
              customValue={dataReferencia}
              onChange={(data) => handleChangeDataReferencia(data)}
              minDate={moment().subtract(21, "years").toDate()}
              maxDate={moment().toDate()}
              renderIconShowHide={false}
            />
          </Grid>
          <Grid item sm={12}>
            <UploadDropArea
              onAdd={onAddUpload}
              dropAreaText={fileName}
              showLoader={false}
              acceptedFiles={[
                ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
              ]}
            />
          </Grid>
          <Grid item sm={3}>
            <Botao
              type="submit"
              label="Salvar"
              className={classes.button}
            />
          </Grid>
        </Grid>
          :
          <Card className={classes.container}>
            <Grid container p={2} spacing={4} className={classes.container}>
              <Grid item sm={12} className={classes.fileUpload}>
                <Grid item sm={12} style={{ textAlign: "center" }}>
                  {progressMesage}
                </Grid>
              </Grid>
            </Grid>
          </Card>
        }
      </Card>
      : ""
    }      
    </form>
  )
}

export default ContratosEerImportar;