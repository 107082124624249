import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const api = () => obterApi(MICROSERVICO.MANAGEMENT);

export const listarOrganizarPor = () => {
  const ordernacoes = [
    {
      key: "0",
      label: "Alfabética"
    },
    {
      key: "3",
      label: "Recentes"
    },
    {
      key: "5",
      label: "CNPJ/CPF"
    }
  ];

  return ordernacoes;
};

export const listarFiltrarPor = () => {
  const filtros = [
    {
      key: "PP",
      label: "Pendente"
    },
    {
      key: "NI",
      label: "Não importado"
    }
  ];

  return filtros;
};

// Listar Clientes
export const listarClientes = async (
  ordemSelecionada,
  filtroSelecionado,
  paginaAtual = 0,
  empresaSelecionada,
  searchString,
  tamanhoPaginacao = 10
) => {
  const params = {
    sortOrder: ordemSelecionada === undefined ? "" : ordemSelecionada,
    empresaGrupoDeltaId: empresaSelecionada,
    filter: filtroSelecionado === undefined ? "" : filtroSelecionado,
    pagina: paginaAtual,
    tamanhoPaginacao,
    searchString: searchString === undefined ? "" : searchString
  };

  return api().get(`v1/LeadCliente?${new URLSearchParams(params).toString()}`);
};

// Buscar Cliente por CNPJ
export const buscarPorCnpj = async (cnpj) => {
  return api().get(`v1/LeadCliente/GetByCnpj/${cnpj}`);
};

// Busca Por Id
export const buscarPorId = async (id) => {
  return api().get(`v1/LeadCliente/${id}`);
};

// Busca Status
export const buscarStatus = async () => {
  return api().get("v1/LeadCliente/Status");
};

// Deletar Cliente
export const deletarCliente = async (id) => {
  return api().delete(`v1/LeadCliente/DeleteById?id=${id}`);
};

// Agente CCEE
export const obterClienteAgenteCCEE = async () => {
  return api().get("v1/LeadCliente/listar-clientes-para-agenteccee");
};

// Autocomplete
export const buscarClientePorNomeFantasia = async (nomeFantasia) => {
  return api().get(`v1/LeadCliente/busca-nome-fantasia/${nomeFantasia}`);
};

// Salvar Cliente
export const salvarCliente = async (id, dados) => {
  let metodo = "post";
  const url = "v1/LeadCliente";
  if (id > 0) {
    metodo = "put";
    dados.Id = id;
  }
  return api()[metodo](url, dados);
};

// Efetivar Clientes
export const efetivarCliente = async (ids) => {
  const metodo = "post";
  const url = "v1/LeadCliente/EfetivarCadastro";

  return api()[metodo](url, ids);
};

// Listar Clientes
export const listarClientesPorEmpresa = async (idEmpresa) => {
  return api().get(`v1/LeadCliente?empresa=${idEmpresa}`);
};

// Listar Clientes
export const listarClientesCombo = async (empresaSelecionada, token) => {
  const IdEmpresa = parseInt(empresaSelecionada, 10);
  return api().get(
    `v1/LeadCliente/ListarClientesColaboradorPorArvoreeempresa/${IdEmpresa}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
};

// Busca Por Id
export const obterCotacoesIdRepresentantes = async (id) => {
  return api().get(`v1/LeadCliente/GetByCotacaoGdId/${id}`);
};

export const salvarLeadCliente = async (dados, cotacaoId) => {
  let metodo = "post";
  let url = "v1/LeadCliente";
  if (cotacaoId > 0) {
    metodo = "put";
    url = "v1/LeadCliente/UpdateByCotacaoMLId";
  }
  return api()[metodo](url, dados);
};
