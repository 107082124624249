import { makeStyles } from "@mui/styles";
import { Base } from "componentes/cores";
import theme from "themes";

export const useStyles = makeStyles({
  inputDisabled: {
    "& input": {
      color: Base.White
    },
    "& input:disabled": {
      color: "#CCCCCC"
    }
  },
  inputEnable: {
    backgroundColor: Base.White,
    color: Base.BlackRock
  },
  inputErro: {
    "& fieldset": {
      borderColor: `${Base.Lava} !important`
    }
  },
  input: {
    "& input": {
      minWidth: "40px",
      padding: "8.5px 3px 8.5px 8px",
      fontSize: "14px"
    },
    "& .MuiInputAdornment-positionEnd": {
      marginLeft: "0",
      width: "auto"
    }
  },
  botao: {
    color: `${theme.color.secondaryText} !important`,
    width: "100%",
    height: "100%",
    justifyContent: "center !important",
    justifyItems: "center",
    alignContent: "center !important",
    alignItems: "center !important",
    minWidth: "20px !important"
  },
  botaoInput: {
    minWidth: "20px",
    padding: "0"
  },
  inativo: {
    opacity: 0.5
  },
  ativo: {
    opacity: 1
  }
});
