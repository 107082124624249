import * as React from "react";
import PropTypes from "prop-types";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { useStyles } from "./style";

function SelectMultiplo({ id, label, opcoes, opcao, setOpcao }) {
  const classes = useStyles();

  const handleChange = (event) => {
    const {
      target: { value }
    } = event;

    setOpcao(value);
  };

  return (
    <div>
      <FormControl sx={{ m: 0, width: "100%", border: "none" }}>
        <Select
          id={id}
          value={opcao}
          onChange={handleChange}
          label=""
          displayEmpty
          input={<OutlinedInput className={classes.input} />}
          multiple
          renderValue={() => {
            return <>{label}</>;
          }}
        >
          {opcoes.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              <Checkbox checked={opcao?.find((op) => op === item.id)} />
              <ListItemText primary={item.valor} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

SelectMultiplo.propTypes = {
  id: PropTypes.number.isRequired,
  label: PropTypes.string,
  opcoes: PropTypes.arrayOf({ id: PropTypes.number, valor: PropTypes.string })
    .isRequired,
  opcao: PropTypes.arrayOf(PropTypes.number).isRequired,
  setOpcao: PropTypes.func.isRequired
};

SelectMultiplo.defaultProps = {
  label: ""
};

export default SelectMultiplo;
