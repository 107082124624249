export default class LeadClienteListaDto {
    constructor(id, nomeFantasia, cnpjCpf, dataCadastro, origem, status, selecionado) {
      this.id = id;
      this.nomeFantasia = nomeFantasia;
      this.cnpjCpf = cnpjCpf;
      this.dataCadastro = dataCadastro;
      this.origem = origem;
      this.status = status;
      this.checked = selecionado;
    }
  }
  
