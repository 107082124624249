export default class PlanoSimuladoDto {
  constructor(
    tipoPlanoSimulado,
    periodo,
    flexibilidade,
    reducaoCustos,
    economiaMensal,
    economiaAnual,
    economiaTotal,
    id
  ) {
    this.tipoPlanoSimulado = tipoPlanoSimulado;
    this.periodo = periodo;
    this.flexibilidade = flexibilidade;
    this.reducaoCustos = reducaoCustos;
    this.economiaMensal = economiaMensal;
    this.economiaAnual = economiaAnual;
    this.economiaTotal = economiaTotal;
    this.id = id;
  }

  static mapearParaDto(planosSimulados) {
    return planosSimulados.map(
      (plano) =>
        new PlanoSimuladoDto(
          plano.tipoPlanoSimulado,
          plano.periodoPlano,
          "± 100%",
          plano.reducaoCustos,
          plano.economiaMensal,
          plano.economiaAnual,
          plano.economiaTotal,
          plano.cotacaoInicialPlanosGeradosId
        )
    );
  }
}
