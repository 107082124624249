import React, { useState } from "react";
import PropTypes from "prop-types";

import TuneIcon from "@mui/icons-material/Tune";

import { BotaoFiltroOrdenacaoEstilo, useStyles } from "./style";

import FiltroOrdenacao from "../filtro";

const BotaoFiltroOrdenacao = React.forwardRef((props, ref) => {
  const {
    disabled,
    id,
    icon,
    label,
    style,
    className,
    ordenacao,
    ordemSelecionada,
    filtros,
    filtroProduto,
    filtroTipoEnergia,
    filtroSubmercado,
    filtroStatus,
    filtrosSelecionados,
    filtroProdutoSelecionados,
    filtroTipoEnergiaSelecionados,
    filtroSubmercadoSelecionados,
    filtroStatusSelecionados,
    ordemColunaSelecionada,
    onClickOrdenacao,
    onClickFiltro,
    onClickFiltroProduto,
    onClickFiltroTipoDeEnergia,
    onClickFiltroSubmercado,
    onClickFiltroStatus,
    onClickSortOrder,
    onClickAtivarAgora,
    onClickTipo,
    tipos,
    tiposSelecionados,
    ordenador,
    ordenadorUm,
    ordenadorDois,
    ordenadorTres,
    ordenadorQuatro,
    ordenadorCinco,
    ordenadorSeis,
    ordenacaoColuna,
    larguraBotao,
    filtroPagamento,
    filtroPagamentoSelecionados,
    onClickFiltroPagamento,
    ordenadorSete,
    filtroTipoCliente,
    filtrosTipoClienteSelecionado,
    onClickFitroTipoCliente,
    ordenadorOito,
    filtroEmpresas,
    filtroSelecionadosEmpresa,
    onClickFiltroEmpresa,
    ordenadorNove,
    filtroTipoTC,
    filtrosTipoTCSelecionado,
    onClickFitroTipoTC,
    ordenadorDez,
    filtroTipoMedidores,
    filtrosTipoMedidoresSelecionado,
    onClickFitroTipoMedidores
  } = props;

  const classes = useStyles();

  const [showModalFiltro, setShowModalFiltro] = useState(null);

  const onHandleShowModalFiltro = (event) => {
    setShowModalFiltro(event.currentTarget);
  };

  const handleClose = () => {
    setShowModalFiltro(null);
  };

  const css =
    className === "" || className === undefined
      ? classes.buttonFiltro
      : className;

  const classNameDesc = `btn btn-lg btn-primary w-100 position-relative d-flex justify-content-center align-items-center ${css}`;

  const handleAtivarAgora = () => {
    onClickAtivarAgora();
    setTimeout(() => {
      setShowModalFiltro(null);
    }, 1000);
  };

  return (
    <div className={className}>
      <BotaoFiltroOrdenacaoEstilo
        type="button"
        className={classNameDesc}
        id={id}
        disabled={disabled}
        ref={ref}
        onClick={(valor) => onHandleShowModalFiltro(valor)}
        style={{ ...style }}
      >
        {label}
        {icon && icon}
      </BotaoFiltroOrdenacaoEstilo>
      <FiltroOrdenacao
        anchorEl={showModalFiltro}
        open={showModalFiltro}
        onClose={handleClose}
        ordenacao={ordenacao}
        ordemSelecionada={ordemSelecionada}
        filtros={filtros}
        filtroProduto={filtroProduto}
        filtroTipoEnergia={filtroTipoEnergia}
        filtroSubmercado={filtroSubmercado}
        filtroStatus={filtroStatus}
        filtrosSelecionados={filtrosSelecionados}
        filtroProdutoSelecionados={filtroProdutoSelecionados}
        filtroTipoEnergiaSelecionados={filtroTipoEnergiaSelecionados}
        filtroSubmercadoSelecionados={filtroSubmercadoSelecionados}
        filtroStatusSelecionados={filtroStatusSelecionados}
        ordemColunaSelecionada={ordemColunaSelecionada}
        tipos={tipos}
        tiposSelecionados={tiposSelecionados}
        onClickOrdenacao={onClickOrdenacao}
        onClickFiltro={onClickFiltro}
        onClickFiltroProduto={onClickFiltroProduto}
        onClickFiltroTipoDeEnergia={onClickFiltroTipoDeEnergia}
        onClickFiltroSubmercado={onClickFiltroSubmercado}
        onClickFiltroStatus={onClickFiltroStatus}
        onClickSortOrder={onClickSortOrder}
        onClickAtivarAgora={handleAtivarAgora}
        onClickTipo={onClickTipo}
        ordenador={ordenador}
        ordenadorUm={ordenadorUm}
        ordenadorDois={ordenadorDois}
        ordenadorTres={ordenadorTres}
        ordenadorQuatro={ordenadorQuatro}
        ordenadorCinco={ordenadorCinco}
        ordenadorSeis={ordenadorSeis}
        ordenadorSete={ordenadorSete}
        ordenacaoColuna={ordenacaoColuna}
        larguraBotao={larguraBotao}
        filtroPagamento={filtroPagamento}
        filtroPagamentoSelecionados={filtroPagamentoSelecionados}
        onClickFiltroPagamento={onClickFiltroPagamento}
        filtroTipoCliente={filtroTipoCliente}
        filtrosTipoClienteSelecionado={filtrosTipoClienteSelecionado}
        onClickFitroTipoCliente={onClickFitroTipoCliente}
        ordenadorOito={ordenadorOito}
        filtroEmpresas={filtroEmpresas}
        filtroSelecionadosEmpresa={filtroSelecionadosEmpresa}
        onClickFiltroEmpresa={onClickFiltroEmpresa}
        ordenadorNove={ordenadorNove}
        filtroTipoTC={filtroTipoTC}
        filtrosTipoTCSelecionado={filtrosTipoTCSelecionado}
        onClickFitroTipoTC={onClickFitroTipoTC}
        ordenadorDez={ordenadorDez}
        filtroTipoMedidores={filtroTipoMedidores}
        filtrosTipoMedidoresSelecionado={filtrosTipoMedidoresSelecionado}
        onClickFitroTipoMedidores={onClickFitroTipoMedidores}
      />
    </div>
  );
});

BotaoFiltroOrdenacao.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.any]),
  label: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.any]),
  className: PropTypes.string,
  ordenacao: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  ordenacaoColuna: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  ordemSelecionada: PropTypes.oneOfType([PropTypes.any]),
  filtros: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  filtroProduto: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  filtroTipoEnergia: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  filtroSubmercado: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  filtroStatus: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  filtrosSelecionados: PropTypes.oneOfType([PropTypes.any]),
  filtroProdutoSelecionados: PropTypes.oneOfType([PropTypes.any]),
  filtroTipoEnergiaSelecionados: PropTypes.oneOfType([PropTypes.any]),
  filtroSubmercadoSelecionados: PropTypes.oneOfType([PropTypes.any]),
  filtroStatusSelecionados: PropTypes.oneOfType([PropTypes.any]),
  ordemColunaSelecionada: PropTypes.oneOfType([PropTypes.any]),
  onClickOrdenacao: PropTypes.oneOfType([PropTypes.func]),
  onClickFiltro: PropTypes.oneOfType([PropTypes.func]),
  onClickFiltroProduto: PropTypes.oneOfType([PropTypes.func]),
  onClickFiltroTipoDeEnergia: PropTypes.oneOfType([PropTypes.func]),
  onClickFiltroSubmercado: PropTypes.oneOfType([PropTypes.func]),
  onClickFiltroStatus: PropTypes.oneOfType([PropTypes.func]),
  onClickSortOrder: PropTypes.oneOfType([PropTypes.func]),
  onClickAtivarAgora: PropTypes.oneOfType([PropTypes.func]),
  onClickTipo: PropTypes.oneOfType([PropTypes.func]),
  tipos: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  tiposSelecionados: PropTypes.oneOfType([PropTypes.any]),
  ordenador: PropTypes.oneOfType([PropTypes.any]),
  ordenadorUm: PropTypes.oneOfType([PropTypes.any]),
  ordenadorDois: PropTypes.oneOfType([PropTypes.any]),
  ordenadorTres: PropTypes.oneOfType([PropTypes.any]),
  ordenadorQuatro: PropTypes.oneOfType([PropTypes.any]),
  ordenadorCinco: PropTypes.oneOfType([PropTypes.any]),
  ordenadorSeis: PropTypes.oneOfType([PropTypes.any]),
  ordenadorSete: PropTypes.oneOfType([PropTypes.any]),

  filtroPagamento: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  filtroPagamentoSelecionados: PropTypes.oneOfType([PropTypes.any]),
  onClickFiltroPagamento: PropTypes.oneOfType([PropTypes.func]),

  filtroTipoCliente: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  filtrosTipoClienteSelecionado: PropTypes.oneOfType([PropTypes.any]),
  onClickFitroTipoCliente: PropTypes.oneOfType([PropTypes.func]),

  ordenadorOito: PropTypes.oneOfType([PropTypes.any]),
  filtroEmpresas: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  filtroSelecionadosEmpresa: PropTypes.oneOfType([PropTypes.any]),
  onClickFiltroEmpresa: PropTypes.oneOfType([PropTypes.func]),

  ordenadorNove: PropTypes.oneOfType([PropTypes.any]),
  filtroTipoTC: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  filtrosTipoTCSelecionado: PropTypes.oneOfType([PropTypes.any]),
  onClickFitroTipoTC: PropTypes.oneOfType([PropTypes.func]),

  ordenadorDez: PropTypes.oneOfType([PropTypes.any]),
  filtroTipoMedidores: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  filtrosTipoMedidoresSelecionado: PropTypes.oneOfType([PropTypes.any]),
  onClickFitroTipoMedidores: PropTypes.oneOfType([PropTypes.func]),
  larguraBotao: PropTypes.oneOfType([PropTypes.number, PropTypes.bool])
};

BotaoFiltroOrdenacao.defaultProps = {
  disabled: false,
  id: "",
  icon: <TuneIcon />,
  label: "",
  style: {},
  className: "",
  ordenacao: [],
  ordenacaoColuna: [],
  ordemSelecionada: "",
  filtros: [],
  filtroProduto: [],
  filtroTipoEnergia: [],
  filtroSubmercado: [],
  filtroStatus: [],
  filtrosSelecionados: "",
  filtroProdutoSelecionados: "",
  filtroTipoEnergiaSelecionados: "",
  filtroSubmercadoSelecionados: "",
  filtroStatusSelecionados: "",
  ordemColunaSelecionada: "",
  onClickOrdenacao: () => {},
  onClickFiltro: () => {},
  onClickFiltroProduto: () => {},
  onClickFiltroTipoDeEnergia: () => {},
  onClickFiltroSubmercado: () => {},
  onClickFiltroStatus: () => {},
  onClickSortOrder: () => {},
  onClickAtivarAgora: () => {},
  onClickTipo: () => {},
  tipos: [],
  tiposSelecionados: () => {},
  ordenador: "Ordenador",
  ordenadorUm: "",
  ordenadorDois: "",
  ordenadorTres: "",
  ordenadorQuatro: "",
  ordenadorCinco: "",
  ordenadorSeis: "",
  ordenadorSete: "",
  filtroPagamento: [],
  filtroPagamentoSelecionados: "",
  onClickFiltroPagamento: () => {},
  filtroTipoCliente: [],
  filtrosTipoClienteSelecionado: "",
  onClickFitroTipoCliente: () => {},

  ordenadorOito: "",
  filtroEmpresas: [],
  filtroSelecionadosEmpresa: "",
  onClickFiltroEmpresa: () => {},

  ordenadorNove: "",
  filtroTipoTC: [],
  filtrosTipoTCSelecionado: "",
  onClickFitroTipoTC: () => {},

  ordenadorDez: "",
  filtroTipoMedidores: [],
  filtrosTipoMedidoresSelecionado: "",
  onClickFitroTipoMedidores: () => {},
  larguraBotao: false
};

export default BotaoFiltroOrdenacao;
