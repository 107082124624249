import React, { useRef, useState, useEffect, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useHistory } from "react-router-dom";

// Componentes
import TuneIcon from "@mui/icons-material/Tune";
import { Grid } from "@mui/material";
import { RotasDTO } from "global/rotas/rotasUrlDto";
import theme from "themes";
import MaterialInputBusca from "componentes/inputBusca";
import Botao from "../../../../componentes/botao";
import TabelaPaginada from "../../../../componentes/tabelaPaginada";
import Coluna from "../../../../componentes/tabelaPaginada/colunas/coluna";
import BotaoFiltroOrdenacao from "../../../../componentes/botaoFiltroOrdenar";
import DistribuidoraPisCofinsHelper from "./helper";
import ColunaComponentePersonalizado from "../../../../componentes/tabelaPaginada/colunas/colunaComponentePersonalizado";
import ColunaMultiplosBotoes from "./colunaMultiplosBotoes";

// DTO
import FiltroAdicionalTabela from "./dto/filtroAdicionalTabela";
import ListagemBoletasDto from "./dto/listagemBoletasDto";
import FiltroDto from "./dto/filtroDto";
import ResultadoPaginadoDto from "../../../../componentes/tabelaPaginada/resultadoPaginadoDto";

// Redux
import { store } from "../../../../global/redux";
import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";
import { desabilitarFiltroLateral } from "../../../../global/redux/modulos/usuario/actions";

// Styles
import { useStyles } from "./style";

// Serviços
import {
  listarOrganizarPor,
  filtroSituacaoPor
} from "../../../../servicos/distribuidoraPisCofinsServico";

const ListagemDistribuidoraPisCofins = () => {
  const history = useHistory();
  const [ordemSelecionada, setOrdemSelecionada] = useState("");
  const [ordemColunaSelecionada, setOrdemColunaSelecionada] = useState();
  const [filtroSituacaoSelecionado, setFiltroSituacaoSelecionado] = useState(
    ""
  );
  const [filtroAdicionalTabela, setFiltroAdicionalTabela] = useState(
    new FiltroAdicionalTabela()
  );
  const [valorOrdenacao, setValorOrdenacao] = useState();

  const classes = useStyles();
  const refTabela = useRef(null);
  const [carregamentoInicial, setCarregamentoInicial] = useState(false);

  const { register, control } = useForm({
    reValidateMode: "onSubmit"
  });

  const textoBusca = useWatch({
    control,
    name: "textoBusca",
    defaultValue: ""
  });

  useEffect(() => {
    setCarregamentoInicial(true);
    store.dispatch(desabilitarFiltroLateral(true));
    return () => store.dispatch(desabilitarFiltroLateral(false));
  }, []);

  const onClickEditar = (id) => {
    history.push(`${RotasDTO.DistribuidoraPisCofins}/cadastro/${id}`);
  };

  const onClickBotao = (tipo, parametros) => {
    onClickEditar(parametros.id);
  };

  const colunas = [
    new Coluna("distribuidoraNome", "Distribuidora", true, "25%", "25%"),
    new Coluna("pisCofins", "Pis/Cofins", true, "15%", "15%"),
    new Coluna("dataIniciaVigencia", "Válido de:", true, "15%", "15%"),
    new Coluna("dataFimVigencia", "Válido até:", true, "15%", "15%"),
    new Coluna("atualizacao", "Atualização", true, "21%", "21%"),
    new ColunaComponentePersonalizado(
      "opcoes",
      "",
      ColunaMultiplosBotoes,
      onClickBotao,
      false,
      true,
      "6.4%",
      "6.4%",
      "pl-2"
    )
  ];

  const obterGrupos = async (parametros) => {
    const filtro = new FiltroDto(
      parametros?.tamanhoPagina,
      parametros?.pagina,
      parametros?.pesquisar || textoPesquisa,
      parametros?.filtroAdicional?.ordenar ?? "",
      parametros?.filtroAdicional?.situacao ?? ""
    );

    const resultado = await DistribuidoraPisCofinsHelper.ListarDistribuidoraPisCofins(
      filtro
    );

    if (!resultado.sucesso) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: resultado.mensagem
        })
      );
      return new ResultadoPaginadoDto([], 1, 0, 0);
    }

    if (resultado?.data.length === 0 ?? true)
      return new ResultadoPaginadoDto([], 1, 0, 0);

    const resLinhas = resultado?.data?.distribuidorasPisCofins?.map((res) => {
      return new ListagemBoletasDto(
        res.id,
        res.distribuidoraNome,
        `${res.pisCofins}%`,
        res.dataIniciaVigencia,
        res.dataFimVigencia,
        res.atualizacao,
        true
      );
    });

    return new ResultadoPaginadoDto(
      resLinhas,
      resultado?.data?.paginaAtual,
      resultado?.data?.totalItens,
      resultado?.data?.totalPaginas
    );
  };

  const onChangeFiltrosTabela = async (parametros) => {
    if (!carregamentoInicial) return;
    return obterGrupos({
      tamanhoPagina: parametros?.totalPagina,
      pagina: parametros?.pagina,
      pesquisar: parametros?.pesquisar,
      filtroAdicional: new FiltroAdicionalTabela(
        parametros?.filtrosAdicionais?.ordenar,
        parametros?.filtrosAdicionais?.situacao
      )
    });
  };

  const handleClickAtivarFiltro = () => {
    if (!carregamentoInicial) return;
    setFiltroAdicionalTabela(
      new FiltroAdicionalTabela(valorOrdenacao, filtroSituacaoSelecionado)
    );
  };

  const handleClickOrdemSelecionada = (event) => {
    const val = ordemSelecionada === event ? "" : event;
    setOrdemSelecionada(val);
  };

  const handleClickSortOrderSelecionado = (valor, idCampoSelecionado) => {
    const val =
      ordemColunaSelecionada === idCampoSelecionado && valor === ""
        ? ""
        : idCampoSelecionado;
    setOrdemColunaSelecionada(val);
    setValorOrdenacao(valor);
  };

  const handleClickFiltroSituacaoSelecionado = (event) => {
    const val = filtroSituacaoSelecionado === event ? "" : event;
    setFiltroSituacaoSelecionado(val);
  };

  const textoPesquisa = useMemo(() => {
    return textoBusca?.length >= 3 ? textoBusca?.trim() : "";
  }, [textoBusca]);

  useEffect(() => {
    if (textoPesquisa === "" && carregamentoInicial) {
      const debounce = setTimeout(() => {
        setFiltroAdicionalTabela(
          new FiltroAdicionalTabela(
            valorOrdenacao,
            filtroSituacaoSelecionado,
            textoPesquisa
          )
        );
      }, 1000);

      return () => clearTimeout(debounce);
    }
  }, [textoPesquisa]);

  const onClickSearch = () => {
    setFiltroAdicionalTabela(
      new FiltroAdicionalTabela(
        valorOrdenacao,
        filtroSituacaoSelecionado,
        textoPesquisa
      )
    );
  };

  return (
    <Grid container>
      <Grid container item xs={12}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item xs={8} sm={5}>
            <MaterialInputBusca
              type="text"
              id="textoBusca"
              name="textoBusca"
              label="Buscar"
              renderIconShowHide
              searchAdornment
              permiteValorBranco
              defaultValue={textoBusca ?? ""}
              ref={register}
            />
          </Grid>
          <Grid item xs={4} sm={2}>
            <Botao
              type="submit"
              onClick={onClickSearch}
              label="Buscar"
              className={classes.button}
              disabled={!textoPesquisa}
            />
          </Grid>
          <Grid xs={2} />
          <Grid
            item
            xs={12}
            sm={3}
            display="flex"
            justifyContent={{ sm: "flex-end" }}
          >
            <BotaoFiltroOrdenacao
              type="button"
              color={theme.color.secondaryBorderColor}
              background="transparent"
              label="Filtrar / Ordernar"
              icon={<TuneIcon />}
              className={classes.buttonFiltro}
              ordenadorUm="Ordenador"
              ordenacaoColuna={listarOrganizarPor()}
              ordenadorSeis="Filtrar"
              filtroStatus={filtroSituacaoPor()}
              ordemColunaSelecionada={ordemColunaSelecionada}
              ordemSelecionada={ordemSelecionada}
              filtroStatusSelecionados={filtroSituacaoSelecionado}
              onClickOrdenacao={handleClickOrdemSelecionada}
              onClickAtivarAgora={handleClickAtivarFiltro}
              onClickFiltroStatus={handleClickFiltroSituacaoSelecionado}
              onClickSortOrder={handleClickSortOrderSelecionado}
              larguraBotao={3}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className="mt-4">
        <TabelaPaginada
          ref={refTabela}
          onChangeFiltrosTabela={onChangeFiltrosTabela}
          colunas={colunas}
          pesquisar={null}
          filtrosAdicionais={filtroAdicionalTabela}
          paginaAtual={1}
          paginationPerPage={20}
          noHeader
        />
      </Grid>
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={6} sm={3}>
          <Botao
            type="button"
            label="Novo alíquota"
            className={classes.button}
            onClick={() => {
              history.push(`${RotasDTO.DistribuidoraPisCofins}/cadastro`);
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ListagemDistribuidoraPisCofins;
