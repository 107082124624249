// import { obterClienteAgenteCCEE } from "servicos/clientesServico";
import RetornoEndpointDto from "global/dto/retornoEndpointDto";

import { listarClientes } from "servicos/clientesServico";
import {
  Associa,
  Desassocia,
  ListaTipoRelatorioParaAssociacao
} from "../../../servicos/permissaoClienteTipoRelatorioServico";

class CadastroHelper {
  static async ListarClientes(
    ordemSelecionada = "",
    filtroSelecionado = "",
    paginaAtual = 1,
    empresaSelecionada = "",
    searchString = "",
    tamanhoPaginacao = 10000000
  ) {
    try {
      const { data } = await listarClientes(
        ordemSelecionada,
        filtroSelecionado,
        paginaAtual,
        empresaSelecionada,
        searchString,
        tamanhoPaginacao
      );

      return RetornoEndpointDto.Sucesso(
        "Categoria cadastrada com Sucesso",
        data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        error?.response?.data?.message ??
          "Ocorreu uma falha ao salvar o Categoria"
      );
    }
  }

  static async ListaTipoRelatorioParaAssociacao(params) {
    try {
      if (!params)
        return RetornoEndpointDto.Erro(
          "É necessário informar uma categoria para a persistencia"
        );

      const { data } = await ListaTipoRelatorioParaAssociacao(params);

      return RetornoEndpointDto.Sucesso(
        "Categoria cadastrada com Sucesso",
        data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        error?.response?.data?.message ??
          "Ocorreu uma falha ao salvar o Categoria"
      );
    }
  }

  static async Associa(params) {
    try {
      if (!params)
        return RetornoEndpointDto.Erro(
          "É necessário informar uma categoria para a persistencia"
        );

      const { data } = await Associa(params);

      return RetornoEndpointDto.Sucesso(
        "Categoria cadastrada com Sucesso",
        data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        error?.response?.data?.message ??
          "Ocorreu uma falha ao salvar o Categoria"
      );
    }
  }

  static async Desassocia(params) {
    try {
      if (!params)
        return RetornoEndpointDto.Erro(
          "É necessário informar uma categoria para a persistencia"
        );

      const { data } = await Desassocia(params);

      return RetornoEndpointDto.Sucesso(
        "Categoria cadastrada com Sucesso",
        data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        error?.response?.data?.message ??
          "Ocorreu uma falha ao salvar o Categoria"
      );
    }
  }
}

export default CadastroHelper;
