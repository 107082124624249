import { makeStyles } from "@mui/styles";
import { Base } from "componentes/cores";
import theme from "themes";

export const useStyles = makeStyles(() => ({
  box: {
    display: "flex",
    width: "100%",
    height: "100%"
  },
  coluna: {
    backgroundColor: `${Base.WhiteLuz} !important`,
    border: `1px solid  ${Base.OrangeBlaze} !important`,
    borderRadius: "16px !important",
    padding: "16px 32px !important",
    height: "80%",
    width: "100%",
    margin: "10px",
    "& p": {
      color: theme.color.primary,
      fontSize: "24px",
      fontFamily: `${theme.font.family}  !important`,
      fontWeight: 700
    },
    "& li": {
      color: theme.color.primary,
      fontSize: "24px",
      fontFamily: `${theme.font.family}  !important`
    }
  },
  card: {
    display: "flex",
    justifyContent: "space-between",
    border: "1px solid #FF9321 !important",
    borderRadius: "18px !important",
    "& h2": {
      color: "#FF9321",
      fontSize: "35px",
      fontFamily: `${theme.font.family}  !important`,
      fontWeight: 700,
      marginBottom: 0
    },
    "& p": {
      color: theme.color.primary,
      fontSize: "16px",
      fontFamily: `${theme.font.family}  !important`,
      fontWeight: 700,
      marginBottom: 0
    }
  },
  cardConteudo: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column"
  }
}));
