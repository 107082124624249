import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const api = () => obterApi(MICROSERVICO.MANAGEMENT);
const apiMeasurement = () => obterApi(MICROSERVICO.MEASUREMENT);

export const listarOrganizarPor = () => {
  const ordernacoes = [
    {
      key: "ORDENACAO_ALFABETICO_ASC",
      label: "Alfabética"
    },
    {
      key: "MAIS_RECENTES_DESC",
      label: "Recentes"
    }
  ];

  return ordernacoes;
};

export const listarFiltrarPor = () => {
  const filtros = [
    {
      key: "AT",
      label: "Ativo"
    },
    {
      key: "IN",
      label: "Inativo"
    }
  ];

  return filtros;
};

// Salvar Agente
export const SalvarAgente = async (agente) => {
  const edicao = agente.Id && agente.Id !== 0;

  const url = `v1/AgenteCCEE${edicao ? `/${agente.Id}` : ""}`;
  const method = edicao ? "put" : "post";

  return api()[method](url, agente);
};

// Obter por id
export const ObterPorId = async (id) => {
  return api().get(`v1/AgenteCCEE/${id}`);
};

export const ObterPorSigla = async (sigla) => {
  return api().get(`v1/AgenteCCEE/sigla/${sigla}`);
};

export const ListarAgentes = async (searchString) => {
  const search = searchString ? `?searchString=${searchString}` : "";
  return api().get(`v1/AgenteCCEE/AutoComplete${search}`);
};

// listar agentes paginados
export const ListarPaginado = async (filtro) => {
  const filtroQueryString = new URLSearchParams(filtro).toString();

  return api().get(`v1/AgenteCCEE/GetAllPaginado?${filtroQueryString}`);
};

export const ListarUnidades = async (searchString) => {
  const search = searchString ? `?searchString=${searchString}` : "";
  return apiMeasurement().get(`v1/UnidadeConsumidora/AutoComplete${search}`);
};