export default class SubmercadoDto {
  constructor(id, descricao) {
    this.id = id;
    this.descricao = descricao;
  }

  static converterParaDto(dados) {
    return dados.map(
      (submercado) => new SubmercadoDto(submercado.id, submercado.descricao)
    );
  }
}
