export default class FiltroAdicionalTabela {
  constructor(
    ordenacao,
    situacao,
    pagamento,
    status,
    unidadeSelecionada,
    clienteSelecionado,
    empresaSelecionada,
    searchString
  ) {
    this.ordenacao = ordenacao;
    this.situacao = situacao;
    this.pagamento = pagamento;
    this.status = status;
    this.unidadeSelecionada = unidadeSelecionada;
    this.clienteSelecionado = clienteSelecionado;
    this.empresaSelecionada = empresaSelecionada;
    this.searchString = searchString;
  }
}
