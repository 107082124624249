import { makeStyles } from "@mui/styles";
import styled from "styled-components";
import theme from "themes";

export const useStyles = makeStyles(() => ({
  card: {
    boxShadow: "0px 2px 4px 1px #00000029 !important"
  },
  titulo: {
    color: theme.color.primary,
    fontFamily: theme.font.family,
    fontSize: "36px",
    fontWeight: 700
  },
  texto: {
    color: theme.color.primary,
    display: "flex",
    marginBottom: 0
  },
  textoCinza: {
    color: "#9F9F9F",
    display: "flex",
    marginBottom: 5
  }
}));

export const Stepper = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: space-between;

  .stepper-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
  }

  .stepper-item::before {
    position: absolute;
    content: "";
    width: 100%;
    top: 37px;
    left: -50%;
    z-index: 2;
  }

  .stepper-item::after {
    position: absolute;
    content: "";
    width: 100%;
    top: 37px;
    left: 50%;
    z-index: 2;
  }

  .stepper-item .stepper-icon {
    height: 115px;
  }

  .stepper-item .stepper-icon svg {
    font-size: 130px;
    vertical-align: inherit;
    color: #4bb543;
  }

  .stepper-item .step-counter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75px;
    height: 75px;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0px 2px 4px 1px #00000029;
    margin-bottom: 6px;
    font-weight: 700;
    font-size: 42px;
  }

  .stepper-item .step-label {
    text-align: center;
    color: ${theme.color.primary};
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .stepper-item .step-label h3 {
    font-size: 24px;
    color: ${theme.color.secondary};
    max-width: 180px;
    font-weight: 700;
  }

  .stepper-item .step-label p {
    font-size: 18px;
    color: ${theme.color.primary};
    max-width: 250px;
    font-weight: 700;
  }

  .stepper-item.completed .step-counter {
    background: linear-gradient(
      90deg,
      rgba(234, 130, 52, 1) 0%,
      rgba(223, 105, 102, 1) 40%,
      rgba(232, 81, 177, 1) 78%
    );
    color: #fff;
    box-shadow: none;
    font-size: 42px;
  }

  .stepper-item.active .step-counter {
    background: linear-gradient(
      90deg,
      rgba(234, 130, 52, 1) 0%,
      rgba(223, 105, 102, 1) 40%,
      rgba(232, 81, 177, 1) 78%
    );
    width: 90px;
    height: 90px;
    color: #fff;
    box-shadow: none;
    font-size: 53px;
  }

  .stepper-item.completed::after {
    position: absolute;
    content: "";
    border-radius: 5px;
    height: 10px;
    width: 200%;
    left: 0;
    z-index: 3;
  }

  .stepper-item.completed1::after {
    background: linear-gradient(
      90deg,
      rgba(234, 130, 52, 1) 0%,
      rgba(223, 105, 102, 1) 99%
    );

    top: 158px;
  }

  .stepper-item.completed2 {
    margin-top: 10px;
  }

  .stepper-item.completed3 {
    margin-top: 10px;
  }

  .stepper-item.completed2::after {
    background: linear-gradient(
      90deg,
      rgba(223, 105, 102, 1) 0%,
      rgba(232, 81, 177, 1) 99%
    );
    top: 148px;
  }

  .stepper-item.completed3::after {
    background: rgba(232, 81, 177, 1);
    top: 148px;
  }

  .stepper-item:first-child::before {
    content: none;
  }
  .stepper-item:last-child::after {
    content: none;
  }
`;
