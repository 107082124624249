import { saveAs } from "file-saver";
import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const apiManagement = () => obterApi(MICROSERVICO.MANAGEMENT);
const apiMeasurement = () => obterApi(MICROSERVICO.MEASUREMENT);

export const obterOperacaoControlePorId = async (id) => {
  return apiManagement().get(`v1/OperacaoControle/${id}`);
};

export const obterOperacaoControlePorUnidadeId = async (unidadeId) => {
  return apiManagement().get(
    `v1/OperacaoControle/ObterPorUnidadeId/${unidadeId}`
  );
};

export const listarOperacaoTipoProblema = async () => {
  return apiManagement().get("v1/OperacaoControle/ListaTipoProblema");
};

export const atualizarOperacaoControle = async (id, dados) => {
  return apiManagement().put(`v1/OperacaoControle/${id}`, dados);
};

export const enviarEmailOperacaoControle = async (dados) => {
  return apiManagement().post("v1/OperacaoControle/EnviarEmail", dados);
};

export const salvarOperacaoTrocaTitularidade = async (dados) => {
  return apiManagement().post(
    "v1/OperacaoTrocaTitularidade/TrocaTitularidade",
    dados
  );
};

export const atualizarOperacaoTrocaTitularidade = async (
  operacaoTrocaTitularidadeId,
  dados
) => {
  return apiManagement().put(
    `v1/OperacaoTrocaTitularidade/TrocaTitularidade/${operacaoTrocaTitularidadeId}`,
    dados,
    {
      headers: { "Content-Type": "multipart/form-data" }
    }
  );
};

export const associarClienteNaUC = async (operacaoTrocaTitularidadeId) => {
  return apiManagement().post(
    `v1/OperacaoTrocaTitularidade/AssociarClienteNaUC/${operacaoTrocaTitularidadeId}`
  );
};

export const atualizarPassoOnboarding = async (
  operacaoControleId,
  operacaoPassoOnboardingId
) => {
  return apiManagement().patch(
    `v1/OperacaoControle/AtualizaPassoOnboarding/${operacaoControleId}`,
    {
      operacaoPassoOnboardingId
    }
  );
};

export const listarOperacaoStatusTroca = async () => {
  return apiManagement().get(
    "v1/OperacaoTrocaTitularidade/ObterOperacaoStatusTroca"
  );
};

export const uploadOperacaoTrocaTitularidadeFatura = async (dados) => {
  return apiManagement().post(
    "v1/OperacaoTrocaTitularidade/OperacaoTrocaTitularidadeFaturas",
    dados
  );
};

export const downloadFatura = async (guid, nomeArquivo) => {
  const { data } = await apiManagement().get(
    `v1/OperacaoTrocaTitularidade/DownloadFatura/${guid}`,
    {
      responseType: "blob"
    }
  );

  const blob = new Blob([data]);

  saveAs(blob, nomeArquivo);
};

export const atualizarOperacaoControlePJ = async (
  operacaoControlePJId,
  dados
) => {
  return apiManagement().put(
    `v1/OperacaoControle/AtualizaOperacaoControlePJ/${operacaoControlePJId}`,
    dados
  );
};

export const verificarCadastroBoleta = async (operacaoControleId) => {
  return apiMeasurement().get(
    `v1/unidadeconsumidora/verificarcadastrounidadeboletagd/${operacaoControleId}`
  );
};

export const adicionaRegistroDocumentoPJAprovado = async (dados) => {
  return apiManagement().post(
    "v1/OperacaoControle/AdicionaRegistroDocumentoPJAprovado",
    dados
  );
};

export const uploadControlePJDocumentoAprovado = async (dados) => {
  return apiManagement().post(
    "v1/OperacaoControle/UploadControlePJDocumentoAprovado",
    dados
  );
};

export const gerarUrlDownload = (params) => {
  const filtroQueryString = new URLSearchParams(params).toString();
  return apiManagement().get(
    `v1/OperacaoControle/DownloadControlePJDocumentoAprovado?${filtroQueryString}`
  );
};

export const solicitaDocumento = async (id, validarDocumentacaoUnidade) => {
  return apiManagement().patch(
    `v1/OperacaoControle/atualiza-validacao-documentacao-unidade/${id}?validacaoDocumentacaoUnidade=${validarDocumentacaoUnidade}`
  );
};
