export default class FiltroAdicionalTabela {
  constructor(
    ordenacao,
    situacao,
    produto,
    tipoEnergia,
    submercado,
    status,
    unidadeSelecionada,
    clienteSelecionado,
    empresaSelecionada,
    searchString
  ) {
    this.ordenacao = ordenacao;
    this.situacao = situacao;
    this.produto = produto;
    this.tipoEnergia = tipoEnergia;
    this.submercado = submercado;
    this.status = status;
    this.unidadeSelecionada = unidadeSelecionada;
    this.clienteSelecionado = clienteSelecionado;
    this.empresaSelecionada = empresaSelecionada;
    this.searchString = searchString;
  }
}
