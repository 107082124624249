import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { useForm } from "react-hook-form";
import { Grid, Card, Box } from "@mui/material";
import Contador from "./Componentes/Contador";
import SelectContador from "./Componentes/SelectContador";
import SelectRadio from "./Componentes/SelectRadio";
import { Botao, Loader } from "../../componentes";
import { useStyles, Icone } from "./style";
import GeladeiraIcone from "../../assets/icones/questionario/geladeira.svg";
import ChuveiroIcone from "../../assets/icones/questionario/chuveiro.svg";
import ArCondiocionadoIcone from "../../assets/icones/questionario/ar-condicionado.svg";
import AirFryericone from "../../assets/icones/questionario/airfryer.svg";
import FornoIcone from "../../assets/icones/questionario/forno.svg";
import AdegaIcone from "../../assets/icones/questionario/adega.svg";
import MicroOndasIcone from "../../assets/icones/questionario/microondas.svg";
import CervejeiraIcone from "../../assets/icones/questionario/cervejeira.svg";
import LavadoraIcone from "../../assets/icones/questionario/lavadora.svg";
import CasaIcone from "../../assets/icones/questionario/casa.svg";
import PessoaIcone from "../../assets/icones/questionario/pessoa.svg";
import UnidadeNaoSelecionada from "../../componentes/unidadeNaoSelecionada";
import {
  obterEnqueteAparelhos,
  postarEnqueteAparelhos
} from "../../servicos/questionarioServico";
import { store } from "../../global/redux";
import { alertaExibir } from "../../global/redux/modulos/alertas/actions";

const listaAparelhos = [
  {
    tipoApplianceId: 1,
    label: "Chuveiro elétrico",
    icone: ChuveiroIcone,
    iconeTamanho: "30px",
    margem: "0 15px 0 0",
    quantidade: 0
  },
  {
    tipoApplianceId: 2,
    label: "Ar condicionado",
    icone: ArCondiocionadoIcone,
    iconeTamanho: "30px",
    margem: "0 15px 0 0",
    quantidade: 0
  },
  {
    tipoApplianceId: 3,
    label: "Air fryer",
    icone: AirFryericone,
    iconeTamanho: "30px",
    margem: "0 15px 0 0",
    quantidade: 0
  },
  {
    tipoApplianceId: 4,
    label: "Forno elétrico",
    icone: FornoIcone,
    iconeTamanho: "30px",
    margem: "0 15px 0 0",
    quantidade: 0
  },
  {
    tipoApplianceId: 5,
    label: "Adega",
    icone: AdegaIcone,
    iconeTamanho: "20px",
    margem: "0 25px 0 0",
    quantidade: 0
  },
  {
    tipoApplianceId: 6,
    label: "Micro-ondas",
    icone: MicroOndasIcone,
    iconeTamanho: "23px",
    margem: "0 20px 0 0",
    quantidade: 0
  },
  {
    tipoApplianceId: 7,
    label: "Cervejeira",
    icone: CervejeiraIcone,
    iconeTamanho: "23px",
    margem: "0 20px 0 0",
    quantidade: 0
  }
];

const geladeiras = [
  {
    tipoApplianceId: 8,
    label: "Geladeira",
    icone: GeladeiraIcone,
    iconeTamanho: "30px",
    descricao: "FrostFree",
    margem: "0 12px 0 0",
    quantidade: 0
  },
  {
    tipoApplianceId: 9,
    label: "Geladeira",
    icone: GeladeiraIcone,
    descricao: "Degelo manual",
    margem: "0 10px 0 0",
    quantidade: 0
  }
];

const maquinasDeLavar = [
  {
    tipoApplianceId: 10,
    label: "Máquina de lavar",
    icone: LavadoraIcone,
    iconeTamanho: "20px",
    descricao: "Tanquinho",
    margem: "0 15px 0 0",
    quantidade: 0
  },
  {
    tipoApplianceId: 11,
    label: "Máquina de lavar",
    icone: LavadoraIcone,
    iconeTamanho: "20px",
    descricao: "Lava/seca",
    margem: "0 15px 0 0",
    quantidade: 0
  },
  {
    tipoApplianceId: 12,
    label: "Máquina de lavar",
    icone: LavadoraIcone,
    iconeTamanho: "20px",
    descricao: "Lava/centrifuga",
    margem: "0 15px 0 0",
    quantidade: 0
  }
];

const residencias = [
  {
    tipoApplianceId: 1,
    label: "Tipo de residência",
    icone: CasaIcone,
    iconeTamanho: "20px",
    margem: "0 15px 0 0",
    descricao: "Minha casa"
  },
  {
    tipoApplianceId: 2,
    label: "Tipo de residência",
    icone: CasaIcone,
    iconeTamanho: "20px",
    margem: "0 15px 0 0",
    descricao: "Casa de férias"
  }
];

const Questionario = () => {
  const classes = useStyles();
  const usuario = useSelector((state) => state.usuario);
  const { unidadeSelecionada } = usuario;
  const [carregando, setCarregando] = useState(false);
  const [tiposResidencia, setTiposResidencia] = useState([]);
  const [tipoResidenciaId, setTipoResidenciaId] = useState();
  const [aparelhos, setAparelhos] = useState([]);
  const [aparelhosGeladeiras, setAparelhosGeladeiras] = useState([]);
  const [aparelhosMaquinasDeLavar, setAparelhosMaquinasDeLavar] = useState([]);
  const [pessoas, setPessoas] = useState();

  const { setError, errors, clearErrors } = useForm({
    reValidateMode: "onSubmit"
  });

  const alterarQuantidade = (valor, index, indexItem = null) => {
    const novasOpcoes = aparelhos.map((item) => item);
    if (indexItem != null) {
      novasOpcoes[index].items[indexItem].quantidade = valor;
    } else {
      novasOpcoes[index].quantidade = valor;
    }
    setAparelhos(novasOpcoes);
  };

  const alterarQuantidadeGeladeira = (valor, index) => {
    const novasOpcoes = aparelhosGeladeiras.map((item) => item);
    novasOpcoes[index].quantidade = valor;
    setAparelhosGeladeiras(novasOpcoes);
  };

  const alterarQuantidadeMaquinaDeLavar = (valor, index) => {
    const novasOpcoes = aparelhosMaquinasDeLavar.map((item) => item);
    novasOpcoes[index].quantidade = valor;
    setAparelhosMaquinasDeLavar(novasOpcoes);
  };

  const buscarAparelhos = async () => {
    setCarregando(true);
    try {
      const aparelhosResposta = await obterEnqueteAparelhos(unidadeSelecionada);
      const pessoa = {
        tipoApplianceId: 15,
        label: "Quantas pessoas vivem com você?",
        icone: PessoaIcone,
        iconeTamanho: "30px",
        margem: "0 10px 0 0",
        quantidade: 0
      };

      if (aparelhosResposta.status === 204) {
        setPessoas(pessoa);

        setTipoResidenciaId("");
        setAparelhosGeladeiras(geladeiras);
        setAparelhosMaquinasDeLavar(maquinasDeLavar);
        setTiposResidencia(residencias);
        setAparelhos(listaAparelhos);
        return;
      }

      const aparelhosMontados = listaAparelhos.map((aparelho) => {
        const aparelhoSalvo = aparelhosResposta?.data?.quantidadeAppliance.find(
          (quantidade) =>
            quantidade.tipoApplianceId === aparelho.tipoApplianceId
        );

        if (aparelhoSalvo) {
          return {
            ...aparelho,
            quantidade: aparelhoSalvo?.quantidade || 0,
            unidadeConsumidoratipoApplianceId: unidadeSelecionada
          };
        }

        return {
          ...aparelho,
          unidadeConsumidoratipoApplianceId: unidadeSelecionada
        };
      });

      const geladeirasMontadas = geladeiras.map((geladeira) => {
        const aparelhoSalvo = aparelhosResposta?.data?.quantidadeAppliance.find(
          (quantidade) =>
            quantidade.tipoApplianceId === geladeira.tipoApplianceId
        );
        return {
          ...geladeira,
          quantidade: aparelhoSalvo?.quantidade || 0,
          unidadeConsumidoratipoApplianceId: unidadeSelecionada
        };
      });

      const maquinasDeLavarMontadas = maquinasDeLavar.map((maquina) => {
        const aparelhoSalvo = aparelhosResposta?.data?.quantidadeAppliance.find(
          (quantidade) => quantidade.tipoApplianceId === maquina.tipoApplianceId
        );
        return {
          ...maquina,
          quantidade: aparelhoSalvo?.quantidade || 0,
          unidadeConsumidoratipoApplianceId: unidadeSelecionada
        };
      });

      const residenciasMontadas = residencias.map((residencia) => {
        return {
          ...residencia,
          unidadeConsumidoratipoApplianceId: unidadeSelecionada
        };
      });

      setPessoas({
        ...pessoa,
        quantidade: aparelhosResposta?.data?.quantasPessoaVivemNaResidencia || 0
      });

      setTipoResidenciaId(aparelhosResposta?.data?.tipoResidenciaID || "");
      setAparelhosGeladeiras(geladeirasMontadas);
      setAparelhosMaquinasDeLavar(maquinasDeLavarMontadas);
      setTiposResidencia(residenciasMontadas);

      setAparelhos(aparelhosMontados);
    } catch (erro) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: erro?.response?.data?.message
        })
      );
    } finally {
      setCarregando(false);
    }
  };

  const validarAparelhos = () => {
    let valido = true;

    if (!tipoResidenciaId) {
      setError("selectResidencia", {
        type: "required",
        message: "Esse campo é obrigatório!"
      });
      valido = false;
    }

    if (pessoas.quantidade <= 0) {
      setError("pessoas", {
        type: "required",
        message: "Esse campo é obrigatório!"
      });
      valido = false;
    }

    return valido;
  };

  const salvarEnqueteAparelhos = async () => {
    try {
      if (!validarAparelhos()) return;

      clearErrors();

      const aparelhosEnviar = {
        id: 0,
        unidadeConsumidoraId: unidadeSelecionada,
        quantasPessoaVivemNaResidencia: pessoas.quantidade,
        tipoResidenciaID: tipoResidenciaId,
        dataInserçao: moment().format("YYYY-MM-DDTHH:mm:ss"),
        dataInativação: moment().format("YYYY-MM-DDTHH:mm:ss"),
        ff: "S",
        ativo: "S",
        quantidadeAppliance: []
      };

      aparelhos.map((aparelho) =>
        aparelhosEnviar.quantidadeAppliance.push({
          id: 0,
          unidadeConsumidoraId: unidadeSelecionada,
          tipoApplianceId: aparelho.tipoApplianceId,
          quantidade: aparelho.quantidade
        })
      );

      aparelhosGeladeiras.map((aparelho) =>
        aparelhosEnviar.quantidadeAppliance.push({
          id: 0,
          unidadeConsumidoraId: unidadeSelecionada,
          tipoApplianceId: aparelho.tipoApplianceId,
          quantidade: aparelho.quantidade
        })
      );

      aparelhosMaquinasDeLavar.map((aparelho) =>
        aparelhosEnviar.quantidadeAppliance.push({
          id: 0,
          unidadeConsumidoraId: unidadeSelecionada,
          tipoApplianceId: aparelho.tipoApplianceId,
          quantidade: aparelho.quantidade
        })
      );

      const resposta = await postarEnqueteAparelhos(aparelhosEnviar);
      if (resposta.status === 200) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: "Aparelhos salvos com sucesso!"
          })
        );
      }
    } catch (erro) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: erro?.response?.data?.message
        })
      );
    }
  };

  useEffect(() => {
    if (unidadeSelecionada) {
      buscarAparelhos();
    }
  }, [unidadeSelecionada]);

  return !unidadeSelecionada ? (
    <UnidadeNaoSelecionada />
  ) : (
    <Loader loading={carregando}>
      <Grid container>
        <Grid item xs={12} className={classes.titulo}>
          <h5 className="pb-2">
            Participe da nossa pesquisa, não leva mais que 2 minutos!
          </h5>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <Grid container className="p-4">
              {aparelhos && aparelhos?.length > 0 ? (
                <>
                  <Grid item xs={12} className={classes.subtitulo}>
                    <h5 className="pb-4">
                      Responda conforme a quantidade de itens em sua casa.
                    </h5>
                  </Grid>

                  <Grid item xs={12} sm={8} md={6} lg={5}>
                    {aparelhos.map((item, index) => (
                      <Contador
                        id={item.tipoApplianceId}
                        key={item.tipoApplianceId}
                        label={item.label}
                        contador={item.quantidade}
                        onChange={(contador) =>
                          alterarQuantidade(contador, index)
                        }
                        icone={
                          <Icone
                            src={item.icone}
                            tamanho={item.iconeTamanho}
                            alt="ícone categoria"
                            margem={item.margem}
                          />
                        }
                      />
                    ))}
                    <Box className="mb-3">
                      <Box className={classes.label}>
                        {aparelhosGeladeiras[0]?.icone && (
                          <Icone
                            src={aparelhosGeladeiras[0].icone}
                            tamanho={aparelhosGeladeiras[0].iconeTamanho}
                            alt="ícone categoria"
                            margem={aparelhosGeladeiras[0].margem}
                          />
                        )}
                        <p>{aparelhosGeladeiras[0].label}</p>
                      </Box>
                      <SelectContador
                        label="Selecione: "
                        opcoes={aparelhosGeladeiras}
                        onChange={(valor, index) =>
                          alterarQuantidadeGeladeira(valor, index)
                        }
                      />
                    </Box>
                    <Box className="mb-3">
                      <Box className={classes.label}>
                        {aparelhosMaquinasDeLavar[0]?.icone && (
                          <Icone
                            src={aparelhosMaquinasDeLavar[0].icone}
                            tamanho={aparelhosMaquinasDeLavar[0].iconeTamanho}
                            alt="ícone categoria"
                            margem={aparelhosMaquinasDeLavar[0].margem}
                          />
                        )}
                        <p>{aparelhosMaquinasDeLavar[0].label}</p>
                      </Box>
                      <SelectContador
                        label="Selecione: "
                        opcoes={aparelhosMaquinasDeLavar}
                        onChange={(valor, index) =>
                          alterarQuantidadeMaquinaDeLavar(valor, index)
                        }
                      />
                    </Box>
                    <Box className="mb-3">
                      <Box className={classes.label}>
                        {tiposResidencia[0]?.icone && (
                          <Icone
                            src={tiposResidencia[0].icone}
                            tamanho={tiposResidencia[0].iconeTamanho}
                            alt="ícone categoria"
                            margem={tiposResidencia[0].margem}
                          />
                        )}
                        <p>{tiposResidencia[0].label}</p>
                      </Box>
                      <SelectRadio
                        id="selectResidencia"
                        label="Selecione: "
                        opcoes={tiposResidencia}
                        valorSelecionado={tipoResidenciaId}
                        onChange={(selecao) => setTipoResidenciaId(selecao)}
                        erro={errors}
                      />
                    </Box>
                    <Contador
                      id="pessoas"
                      label={pessoas.label}
                      contador={pessoas.quantidade}
                      onChange={(contador) =>
                        setPessoas((prevState) => ({
                          ...prevState,
                          quantidade: contador
                        }))
                      }
                      icone={
                        <Icone
                          src={pessoas.icone}
                          tamanho={pessoas.iconeTamanho}
                          alt="ícone categoria"
                          margem={pessoas.margem}
                        />
                      }
                      erro={errors}
                    />
                  </Grid>
                </>
              ) : (
                <Grid item xs={12} className={classes.semDados}>
                  <h4>Nenhum registro encontrado</h4>
                </Grid>
              )}
            </Grid>
            <Grid container className="pb-4 px-4">
              <Grid item xs={0} sm={8} md={9} />
              <Grid item xs={12} sm={4} md={3} className={classes.salvar}>
                {aparelhos && aparelhos?.length > 0 && (
                  <Botao
                    label="Salvar"
                    onClick={() => salvarEnqueteAparelhos()}
                  />
                )}
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Loader>
  );
};

export default Questionario;
