import React, { useState, useEffect, useCallback } from "react";
import { Card, Grid } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useParams, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useStyles } from "./style";
import BotaoRetornarListagem from "componentes/botaoRetornarListagem";
import MaterialInputTexto from "componentes/inputTexto/materialInput";
import Loader from "componentes/loader";
import Botao from "componentes/botao";
import RotasDTO from "../../../rotas/rotasUrlDto";
import { obterCenarios } from "../../../servicos/cenariosSeriesHistoricas";
import MaterialSwitch from "componentes/switch";
import ModalConfirmacao from "componentes/modalConfirmacao";

import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import {
  salvarGrupoProjecoes,
  buscaGrupoProjecoesPorId,
  salvarisOficialNinja
} from "submodules/DeltaTarifa/servicos/grupoProjecoes";

const GrupoProjecoes = () => {
  const { id } = useParams();
  const classes = useStyles();
  const history = useHistory();

  const { register, errors, handleSubmit } = useForm({
    reValidateMode: "onSubmit"
  });

  const [cenarios, setCenarios] = useState([]);
  const [carregando, setCarregando] = useState(false);
  const [isOfficialNinja, setIsOfficialNinja] = useState(false);
  const [status, setStatus] = useState("");
  const [modalExclusao, setModalExclusao] = useState(false);
  const [msgExclusao, setMsgExclusao] = useState("");

  const onChangeSwitch = async () => {
    try {
      setCarregando(true);

      const salvou = await salvarisOficialNinja(id);
      if (salvou?.data) {
        setModalExclusao(true);
        setMsgExclusao(salvou?.data);
      }

      setCarregando(false);
    } catch (error) {
      setCarregando(false);
    }
  };

  const handleExclusao = () => {
    setIsOfficialNinja(true);
    setModalExclusao(false);
  };

  const buscaCenarios = async () => {
    try {
      setCarregando(true);
      const response = await obterCenarios();
      if (response?.status === 200 && response?.data) {
        setCenarios(response?.data);
        setCarregando(false);
      }
    } catch (error) {
      setCarregando(false);
    }
  };

  useEffect(() => {
    buscaCenarios();
  }, []);

  const [titulo, setTitulo] = useState("");
  const [descricao, setDescricao] = useState("");
  const [cenarioid, setCenarioId] = useState();

  const obterGrupoProjecoes = useCallback(async (id) => {
    setCarregando(true);
    try {
      const grupo = await buscaGrupoProjecoesPorId(id);
      if (grupo?.status === 200 && grupo?.data) {
        setTitulo(grupo?.data.titulo);
        setDescricao(grupo?.data.descricao);
        setCenarioId(grupo?.data.idCenario);
        setIsOfficialNinja(grupo?.data.isOfficialNinja);
        setStatus(grupo?.data.codigoStatusGrupo);
      }
      setCarregando(false);
    } catch (error) {
      setCarregando(false);
    }
  }, []);

  const enviarFormulario = async (dados) => {
    setCarregando(true);
    try {
      if (
        cenarioid === undefined ||
        cenarioid === null ||
        cenarioid.toString().trim() === ""
      ) {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "Cenário não selecionado. Operação cancelada."
          })
        );
        setCarregando(false);
        return;
      }
      if (
        titulo === undefined ||
        titulo === null ||
        titulo.toString().trim() === ""
      ) {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "Título deve ser preenchido. Operação cancelada."
          })
        );
        setCarregando(false);
        return;
      }

      if (
        descricao === undefined ||
        descricao === null ||
        descricao.toString().trim() === ""
      ) {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "Descrição deve ser preenchida. Operação cancelada."
          })
        );
        setCarregando(false);
        return;
      }

      const salvou = await salvarGrupoProjecoes(id ?? 0, {
        ...dados,
        idGrupoProjecoes: id,
        titulo: titulo,
        descricao: descricao,
        idCenario: cenarioid,
        isOfficialNinja: isOfficialNinja ? 1 : 0
      });
      if (salvou?.status === 200 || salvou?.status === 204) {
        history.push(RotasDTO.GruposProjecoes);

        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: "Grupo Projeções gravado com sucesso"
          })
        );
      } else if (salvou?.status === 202) {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: `${salvou.data}`
          })
        );
      }
      setCarregando(false);
    } catch (error) {
      setCarregando(false);
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem:
            `${error?.message}: ${error?.response?.statusText}` ??
            "Erro não previsto, entre em contato com o suporte!"
        })
      );
    }
  };

  useEffect(() => {
    if (id) obterGrupoProjecoes(id);
  }, [id]);

  const handleChangeTitulo = (event) => {
    setTitulo(event.target.value);
  };

  const handleChangeDescricao = (event) => {
    setDescricao(event.target.value);
  };

  const handleChangeCenario = (event) => {
    setCenarioId(event.target.value);
  };

  const aoEnviarFormulario = (dados) => {
    enviarFormulario(dados);
  };

  return (
    <Loader loading={carregando} className="w-auto">
      <ModalConfirmacao
        item={modalExclusao}
        onConfirmar={handleExclusao}
        mensagem={msgExclusao}
        onCancelar={() => setModalExclusao(false)}
      />

      <form
        className="needs-validation"
        onSubmit={handleSubmit(aoEnviarFormulario)}
      >
        <Card className={classes.container}>
          <Grid
            container
            p={2}
            spacing={4}
            className={classes.container}
            justify="flex-end"
          >
            <Grid item sm={11}>
              {id ? "Editar Grupo de Projeções" : "Novo Grupo de Projeções"}
            </Grid>
            <Grid item sm={1}>
              <BotaoRetornarListagem
                justify="flex-end"
                urlListagem={RotasDTO.GruposProjecoes}
              />
            </Grid>
          </Grid>
          <Grid container p={2} spacing={4} className={classes.container}>
            <Grid item xs={12} sm={6}>
              <MaterialInputTexto
                type="text"
                id="titulo"
                name="titulo"
                label="Título"
                renderIconShowHide={false}
                defaultValue={titulo}
                //disabled = {codigo ? true : false}
                ref={register({
                  required: "Campo Título é obrigatório!",
                  maxLength: {
                    value: 200,
                    message: "Quantidade máxima de 200 caracteres!"
                  }
                })}
                onInput={(e) => {
                  e.target.value = e.target.value.toString().slice(0, 200);
                }}
                onBlur={handleChangeTitulo}
                errors={errors}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MaterialInputTexto
                type="text"
                id="descricao"
                name="descricao"
                label="Descrição"
                renderIconShowHide={false}
                defaultValue={descricao}
                //disabled = {apelido ? true : false}
                ref={register({
                  required: "Campo Descrição é obrigatório!"
                })}
                onInput={(e) => {
                  e.target.value = e.target.value.toString().slice(0, 1000);
                }}
                onBlur={handleChangeDescricao}
                errors={errors}
              />
            </Grid>
          </Grid>

          <Grid container p={2} spacing={4} className={classes.container}>
            <Grid item xs={12} sm={4}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="IdCenario">Cenário</InputLabel>
                <Select
                  labelId="IdCenario"
                  id="IdCenario"
                  value={cenarioid ?? ""}
                  onChange={handleChangeCenario}
                  label="Cenário"
                  fullWidth
                  disabled={!!id}
                >
                  {cenarios.map((prdcdd) => (
                    <MenuItem value={prdcdd.id}>{prdcdd.nome}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {status === "CONCLUIDO" && (
              <Grid item sm={4} className="ml-2">
                <MaterialSwitch
                  label="Oficial"
                  labelPlacement="top"
                  id="OficialNinja"
                  name="OficialNinja"
                  checked={Boolean(isOfficialNinja)}
                  onChange={(valor) => onChangeSwitch(valor)}
                  exibirLabels
                  disabled={Boolean(isOfficialNinja)}
                />
              </Grid>
            )}
          </Grid>
          <Grid container p={2} spacing={4} className={classes.container}>
            <Grid item lg={4} md={6} sm={12}>
              <Botao type="submit" label="Salvar" className={classes.button} />
            </Grid>
          </Grid>
        </Card>
      </form>
    </Loader>
  );
};

export default GrupoProjecoes;
