import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// Componentes
import { Grid, Box, Typography } from "@mui/material";
import { RotasDTO } from "global/rotas/rotasUrlDto";

// Styles
import { useStyles, BotaoEnviar, Stepper } from "./style";

const FluxoMigracao = ({ passo }) => {
  const classes = useStyles();
  const [index, setIndex] = useState(0);

  const gerarTextoPasso = () => {
    switch (true) {
      case passo === 1 || passo === 2 || passo === 15:
        return (
          <>
            <h3 className={classes.subtitulo}>Documento de identificação</h3>
            <Typography>
              Agora, precisamos que você nos envie alguns documentos de
              identificação. Veja quais são esses documentos e como enviá-los no
              botão abaixo.
            </Typography>
          </>
        );
      case passo === 3 ||
        passo === 4 ||
        passo === 16 ||
        passo === 17 ||
        passo === 18:
        return (
          <>
            <h3 className={classes.subtitulo}>Análise de segurança</h3>
            <Typography>
              Aguarde enquanto realizamos a análise de segurança dos documentos
              e informações enviadas. Entraremos em contato por e-mail assim que
              a análise estiver completa.
            </Typography>
          </>
        );
      case passo === 5 || passo === 6:
        return (
          <>
            <h3 className={classes.subtitulo}>Processo de migração</h3>
            <Typography>
              Seu processo de migração para LUZ já foi iniciado! Esse
              procedimento pode levar até 15 dias úteis.
            </Typography>
          </>
        );
      case passo >= 7 && passo <= 12:
        return (
          <>
            <h3 className={classes.subtitulo}>Instalação do medidor</h3>
            <Typography>
              Você já pode agendar a instalação do seu medidor inteligente de
              energia! Clique no botão abaixo e agende a melhor data com nossos
              especialistas.
            </Typography>
          </>
        );
      case passo === 13:
        return (
          <>
            <h3 className={classes.subtitulo}>
              Bem-vindo ao futuro da energia!
            </h3>
            <Typography>
              Estamos muito felizes em ter você como cliente LUZ!
              <br />
              Temos alguns pontos importantes para passar a você, clique no
              botão abaixo para ver os próximos passos.
            </Typography>
          </>
        );
      default:
        return (
          <>
            <h3 className={classes.subtitulo}>Documento de identificação</h3>
            <Typography>
              Agora, precisamos que você nos envie alguns documentos de
              identificação. Veja quais são esses documentos e como enviá-los no
              botão abaixo.
            </Typography>
          </>
        );
    }
  };

  const verificarPasso = () => {
    switch (true) {
      case passo === 1 || passo === 2 || passo === 15:
        setIndex(0);
        return passo;
      case passo === 3 ||
        passo === 4 ||
        passo === 16 ||
        passo === 17 ||
        passo === 18:
        setIndex(1);
        return passo;
      case passo === 5 || passo === 6:
        setIndex(2);
        return passo;
      case passo >= 7 && passo <= 12:
        setIndex(3);
        return passo;
      case passo === 13:
        setIndex(4);
        return passo;
      default:
        setIndex(0);
        return passo;
    }
  };

  useEffect(() => {
    verificarPasso();
  }, [passo]);

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: { xs: "space-between" },
          alignItems: { xs: "center" },
          background: "#FBFBFD"
        }}
        p={2}
      >
        <h3 className={classes.titulo}>Fluxo de migração</h3>
      </Grid>

      <Grid item xs={12}>
        <Box sx={{ p: { xs: 1, lg: 2 } }}>
          <Stepper step={5}>
            <div
              className={`stepper-item ${index === 0 && "active"} ${
                index > 0 && "completed"
              }`}
            >
              <div className="step-counter">1</div>
              <div className="step-label">Envio de documentos</div>
            </div>
            <div
              className={`stepper-item ${index === 1 && "active"} ${
                index > 1 && "completed"
              }`}
            >
              <div className="step-counter">2</div>
              <div className="step-label">Análise de segurança</div>
            </div>
            <div
              className={`stepper-item ${index === 2 && "active"} ${
                index > 2 && "completed"
              }`}
            >
              <div className="step-counter">3</div>
              <div className="step-label">Processo de migração</div>
            </div>
            <div
              className={`stepper-item ${index === 3 && "active"} ${
                index > 3 && "completed"
              }`}
            >
              <div className="step-counter">4</div>
              <div className="step-label">Instalação do medidor</div>
            </div>
            <div
              className={`stepper-item ${index === 4 && "active completed"}`}
            >
              <div className="step-counter">5</div>
              <div className="step-label">Bem vindo</div>
            </div>
          </Stepper>
        </Box>
      </Grid>

      <Grid item xs={12} p={2}>
        <Box pb={1}>{gerarTextoPasso()}</Box>

        <Link to={RotasDTO.OnBoardingGD}>
          <Box
            py={2}
            sx={{
              display: "flex",
              justifyContent: { xs: "center" }
            }}
          >
            <BotaoEnviar>Ver detalhes</BotaoEnviar>
          </Box>
        </Link>
      </Grid>
    </Grid>
  );
};

FluxoMigracao.propTypes = {
  passo: PropTypes.number
};

FluxoMigracao.defaultProps = {
  passo: 0
};

export default FluxoMigracao;
