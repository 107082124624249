/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect, useMemo } from "react";
import shortid from "shortid";
import TuneIcon from "@mui/icons-material/Tune";
import { Grid } from "@mui/material";
import { useForm, useWatch } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Base } from "componentes/cores";
import Loader from "componentes/loader";
import ModalConfirmacao from "componentes/modalConfirmacao";
import MaterialInputTexto from "componentes/inputTexto/materialInput";
import BotaoFiltroOrdenacao from "componentes/botaoFiltroOrdenar";
import BotaoCadastro from "componentes/botaoCadastro";
import ItemListaExpansivel from "componentes/itemListaExpansivel";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import RotasDTO from "../../../rotas/rotasUrlDto";
import { useStyles } from "./style";
import { getDistribuidora, updateConfigAtivo } from "../../../servicos/mercadoFaturado";

const DistribuidoraMercadoFaturado = () => {
    const classes = useStyles();
    const history = useHistory();

    const [carregandoDistribuidoras, setCarregandoDistribuidoras] = useState(false);
    const [distribuidoras, setDistribuidoras] = useState([]);
    const [listaDistribuidoras, setListaDistribuidoras] = useState([]);
    const [modalExclusao, setModalExclusao] = useState(false);

    const { register, control } = useForm({
        reValidateMode: "onSubmit"
    });

    const textoBusca = useWatch({
        control,
        name: "textoBusca",
        defaultValue: ""
    });

    const stringBusca = useMemo(() => {
        return textoBusca;
    }, [textoBusca]);

    function filterDistribuidorasByCodigo(obj) {
        if (obj.codigo.toUpperCase().includes(textoBusca.toUpperCase())) {
            return true;
        }
        return false;
    }

    const filtrarDistribuidoras = () => {
        const dadosFiltrados = listaDistribuidoras.filter(filterDistribuidorasByCodigo);
        setDistribuidoras(dadosFiltrados);
    }

    useEffect(() => {
        setTimeout(() => {
            filtrarDistribuidoras();
        }, 100);
    }, [stringBusca]);

    const handleDistribuidoras = async () => {
        try {
            setCarregandoDistribuidoras(true);
            const lista = await getDistribuidora();
            if (lista?.status === 200 && lista?.data) {
                setDistribuidoras(lista?.data);
                setListaDistribuidoras(lista?.data);
            } else {
                setDistribuidoras([]);
            }
            setCarregandoDistribuidoras(false);
        } catch (error) {
            console.info(error);
            setCarregandoDistribuidoras(false);
        }
    }

    useEffect(() => {
        if (!distribuidoras?.length) handleDistribuidoras();
    }, []);



    const onClickNovaDistribuidora = () => {
        history.push(RotasDTO.DistribuidoraMercadoFaturadoConfiguracao);
    };

    const onClickEditar = async (id) => {
        history.push(`${RotasDTO.DistribuidoraMercadoFaturadoConfiguracao}/${id}`);
    }

    const deletar = async (item) => {
        try {
            setModalExclusao(false);
            setCarregandoDistribuidoras(true);
            await updateConfigAtivo(item?.idDistribuidora, !item?.ativo)
                .then((data) => {
                    if (data.status === 200) {
                        store.dispatch(
                            alertaExibir({
                                tipo: "success",
                                mensagem:
                                    "Configuração de distribuidora ativada/desativada com sucesso!"
                            })
                        );
                    }
                    handleDistribuidoras();
                });
            setCarregandoDistribuidoras(false);
        } catch (error) {
            store.dispatch(
                alertaExibir({
                    tipo: "warning",
                    mensagem:
                        error?.response?.data?.message ??
                        "Erro interno, entre em contato com o suporte!"
                }));

            setCarregandoDistribuidoras(false);
        }
    }

    const onClickExcluir = (item) => {
        setModalExclusao(item);
    };

    const onConfirmarExclusao = (item) => {
        try {
            deletar(item);
        } catch (error) {
            console.info(error);
        }
    };

    return (
        <Loader loading={carregandoDistribuidoras} className="w-auto">
            <ModalConfirmacao
                item={modalExclusao}
                onConfirmar={(item) => onConfirmarExclusao(item)}
                mensagem="Tem certeza que deseja ativar/inativar esta configuração?"
                onCancelar={() => setModalExclusao(false)}
            />
            <Grid container p={2} spacing={3} alignItems="center">
                <Grid item xs={9}>
                    <MaterialInputTexto
                        type="text"
                        id="textoBusca"
                        name="textoBusca"
                        label="Buscar"
                        renderIconShowHide
                        searchAdornment
                        defaultValue={textoBusca ?? ""}
                        ref={register}
                        className={classes.search}
                        permiteValorBranco
                    />
                </Grid>
                <Grid item xs={3} className={classes.itemFiltro}>
                    <BotaoFiltroOrdenacao
                        type="button"
                        color={Base.OffWhite}
                        label="Filtrar / Ordernar"
                        icon={<TuneIcon />}
                        className={classes.buttonFiltro}
                    />
                </Grid>
                <Grid item xs={12}>
                    <BotaoCadastro
                        label="Nova Configuração de Distribuidora"
                        color={Base.RedChestnut}
                        onClick={onClickNovaDistribuidora}
                    />
                </Grid>
                {distribuidoras.length > 0 && distribuidoras.map((data) => {
                    return (
                        <ItemListaExpansivel
                            key={shortid.generate()}
                            label={data.titulo}
                            color={Base.RedChestnut}
                            visualizacao={Boolean(data.ativo)}
                            edicao={Boolean(data.ativo)}
                            exclusao={Boolean(true)}
                            ativo={Boolean(data.ativo)}
                            onClickEditar={() => onClickEditar(data?.idDistribuidora)}
                            onClickExcluir={() => onClickExcluir(data ?? false)}
                        >
                            <div>
                                <strong>
                                    {data.razaoSocial}
                                </strong>
                            </div>
                        </ItemListaExpansivel>
                    );
                })}
            </Grid>
        </Loader>
    );
}

export default DistribuidoraMercadoFaturado;
