import obterApi from "./api";
import MICROSERVICO from "../global/enumeradores/microServico";

const api = () => obterApi(MICROSERVICO.MEASUREMENT);
export const validandoDesconto = async (
  id,
  desconto,
  consumidorLivre,
  valorMedioConta,
  cotacaoSelecionada,
  dataVigencia,
  dataMigracao
) => {
  const metodo = "post";
  const url = "v1/planossimulados/retornodescontofixo";
  const dados = {
    idCotacao: id,
    desconto,
    consumidorLivre,
    valorMedioConta,
    idPlanoCotacaoColl: cotacaoSelecionada,
    dataVigencia,
    dataMigracao
  };

  return api()[metodo](url, dados);
};
