import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";

import { Box, Grid } from "@mui/material";

import { useStyles } from "./style";

const ModalMensagemErro = ({
  item,
  onCancelar,
  mensagem,
  titulo,
  footer,
  header,
  texto,
  children,
  disabled,
  enviarFatura,
  textoEnviarFatura,
  onConfirmar
}) => {
  const classes = useStyles();
  const [aberto, setAberto] = useState(false);

  useEffect(() => {
    setAberto(Boolean(item));
  }, [item]);

  const onClose = () => {
    setAberto(false);
    onCancelar();
  };

  return (
    <Modal open={aberto}>
      <Box
        component={Grid}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={classes.container}
        spacing={0}
        position="relative"
      >
        {header && (
          <Grid container>
            <Box component={Grid} item xs={12} textAlign="end">
              <CloseIcon
                htmlColor="#FA3EB1"
                cursor="pointer"
                onClick={onClose}
              />
            </Box>
            <Grid item xs={12}>
              <Box color="#332053" fontSize="24px" fontWeight="700" mb={2}>
                {titulo}
              </Box>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <Box color="#332053" fontSize="16px" fontWeight="400" mb={2}>
            {mensagem}
          </Box>
        </Grid>

        <Grid item xs={12}>
          {children}
        </Grid>

        {footer && (
          <>
            <Box component={Grid} item xs={12}>
              <Box
                component="button"
                id="buttonSim"
                name="buttonSim"
                value="buttonSim"
                width="100%"
                fontSize="16px"
                fontWeight="700"
                borderRadius="24px"
                border="none"
                color="#fff"
                bgcolor="#FA3EB1"
                py={1}
                onClick={onClose}
              >
                {texto}
              </Box>
            </Box>
          </>
        )}

        {enviarFatura && (
          <>
            <Box component={Grid} item xs={12}>
              <Box
                component="button"
                id="buttonSim"
                name="buttonSim"
                value="buttonSim"
                width="100%"
                fontSize="16px"
                fontWeight="700"
                borderRadius="24px"
                border="none"
                color="#fff"
                bgcolor={disabled ? "#FA3EB1" : "rgb(232, 81, 177, 0.4)"}
                py={1}
                onClick={onConfirmar}
                disabled={!disabled}
                sx={{ cursor: "pointer" }}
              >
                {textoEnviarFatura}
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};

ModalMensagemErro.propTypes = {
  item: PropTypes.oneOfType([PropTypes.any]).isRequired,
  onCancelar: PropTypes.oneOfType([PropTypes.func]),
  mensagem: PropTypes.oneOfType([PropTypes.node, PropTypes.any]),
  titulo: PropTypes.string,
  footer: PropTypes.bool,
  header: PropTypes.bool,
  texto: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  enviarFatura: PropTypes.bool,
  textoEnviarFatura: PropTypes.string,
  onConfirmar: PropTypes.oneOfType([PropTypes.func])
};

ModalMensagemErro.defaultProps = {
  onCancelar: () => {},
  mensagem: {},
  titulo: "",
  footer: true,
  header: true,
  texto: "",
  children: () => {},
  disabled: false,
  enviarFatura: false,
  textoEnviarFatura: "",
  onConfirmar: () => {}
};

export default ModalMensagemErro;
