import { createTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { Base } from "componentes/cores";
import themes from "themes";

const theme = createTheme();

export const useStyles = makeStyles(() => ({
  videoContainer: {
    left: "50%",
    outline: "none",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    top: "50% !important",
    width: "50% !important",
    [theme.breakpoints.down("md")]: {
      width: "70% !important"
    },
    [theme.breakpoints.down("xs")]: {
      width: "100% !important"
    }
  },
  container: {
    backgroundColor: Base.OffWhite,
    border: "0 none",
    borderRadius: "4px",
    boxShadow: theme.shadows[5],
    left: "50% !important",
    outline: "none",
    position: "absolute",
    padding: "20px 24px",
    transform: "translate(-50%, -50%)",
    top: "50% !important",
    width: "50% !important",
    color: themes.color.thirdText,
    maxHeight: "70%",
    overflowY: "auto",
    [theme.breakpoints.down("md")]: {
      width: "70% !important"
    },
    [theme.breakpoints.down("xs")]: {
      width: "100% !important",
      maxHeight: "90% !important"
    }
  },
  titulo: {
    fontFamily: themes.font.family,
    fontSize: "18px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    marginBottom: "0"
  },
  close: {
    display: "flex",
    justifyContent: "flex-end",
    "& svg": {
      cursor: "pointer"
    }
  },
  videosLista: {
    margin: "10px 0 0"
  },
  videoPlay: {
    position: "relative",
    paddingRight: "5px",
    width: "100%"
  },
  videoIcon: {
    backgroundColor: Base.OffWhite,
    position: "absolute",
    borderRadius: "50%",
    transform: "translate(-50%, -50%)",
    top: "46%",
    left: "49%",
    cursor: "pointer",
    "& svg": {
      width: "2.5em",
      height: "2.5em",
      color: Base.Black
    },
    [theme.breakpoints.down("xs")]: {
      "& svg": {
        width: "1.5em",
        height: "1.5em"
      }
    }
  },
  videoPrevia: {
    cursor: "pointer",
    width: "100%"
  },
  tituloVideo: {
    fontFamily: themes.font.family,
    fontSize: "16px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    paddingLeft: "5px",
    marginBottom: "0"
  },
  descricao: {
    width: "100%",
    paddingLeft: "5px",
    marginBottom: "0"
  },
  videoHr: {
    marginLeft: "15px",
    borderBottomStyle: "solid",
    borderBottomWidth: "2px",
    borderBottomColor: themes.color.primary,
    width: "100%"
  }
}));
