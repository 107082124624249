import { makeStyles } from "@mui/styles";
import styled from "styled-components";
import { createTheme } from "@mui/material/styles";
import theme from "../../../../themes";
import { Base } from "../../../../componentes/cores";

const th = createTheme();

export const useStyles = makeStyles(() => ({
  semDados: {
    fontSize: "28px",
    margin: "200px auto 200px auto",
    maxWidth: "500px",
    textAlign: "center"
  },
  titulo: {
    color: theme.color.primary,
    fontFamily: theme.font.family,
    fontSize: "28px",
    [th.breakpoints.down("sm")]: {
      fontSize: "20px",
      fontWeight: 700
    }
  },
  botao: {
    background: "transparent",
    border: "none",
    padding: "0 10px",
    display: "flex",
    alignItems: "center"
  },
  card: {
    cursor: "pointer"
  },
  sombra: { boxShadow: "0 3px 10px rgb(0 0 0 / 0.2) !important" },
  cardConteudo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  cardIcone: {
    marginLeft: "5px",
    marginRight: "10px",
    height: "20px"
  },
  cardPreco: {
    color: theme.color.primary,
    fontFamily: theme.font.family,
    fontSize: "28px",
    margin: 0
  },
  cardSeta: {
    color: theme.color.secondary,
    fontSize: "14px !important"
  },
  textoCentral: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    "& p": {
      margin: 0,
      fontFamily: theme.font.family,
      fontSize: "14px"
    },
    "& h2": {
      color: theme.color.primary,
      fontFamily: theme.font.family,
      fontWeight: 700,
      fontSize: "24px",
      margin: 0
    },
    "& h3": {
      color: theme.color.primary,
      fontFamily: theme.font.family,
      fontWeight: 700,
      fontSize: "16px",
      margin: 0
    }
  }
}));

export const Kwh = styled.span`
  color: ${(props) => (props.corTexto ? props.corTexto : theme.primary)};
  font-weight: 700;
  margin-right: 10px;
`;

export const Porcentagem = styled.span`
  background-color: ${(props) => (props.cor ? props.cor : theme.color.primary)};
  color: ${Base.White};
  font-weight: 700;
  padding: 4px 8px 4px 8px;
  border-radius: 24px;
  font-size: 14px;
`;
