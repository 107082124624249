import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form';
import moment from "moment";
import { Grid, Card, TextField } from '@mui/material';
import { useStyles } from './style';
import BotaoRetornarListagem from 'componentes/botaoRetornarListagem';
import InputData from 'componentes/inputTextoData';
import Botao from 'componentes/botao';
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { buscarVigenciasBandeiras, buscarBandeirasPorId, salvarBandeira } from '../../../servicos/bandeirasTarifarias';
import RotasDTO from '../../../rotas/rotasUrlDto';
import NumberFormat from "react-number-format";

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      decimalSeparator = {','}
      allowedDecimalSeparators = {[',']}
      isNumericString={true} 
    />
  );
}

const CadastroBandeiraTarifaria = () => {
  const { id, type } = useParams();
  const history = useHistory();
  const classes = useStyles();

  const { errors, handleSubmit } = useForm({
    reValidateMode: 'onSubmit',
  });

  const [data] = useState(moment().format())

  const [listaBandeiras, setListaBandeiras] = useState([]);

  const obterBandeiras = useCallback(async () => {
    try {
      const response = await buscarVigenciasBandeiras(data)
      if (response?.status === 200 && response?.data) {
        setListaBandeiras(response?.data);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  }, []);

  useEffect(() => {
    if (!listaBandeiras.length) obterBandeiras();
  }, [!listaBandeiras.length, obterBandeiras]);

  const [futura, setFutura] = useState();
  const [atual, setAtual] = useState();
  const [historico, setHistorico] = useState();

  const verificarExistenciaVigencia = (bandeiras) => {
    if (bandeiras?.vigenciaAtual) {
      setAtual(bandeiras.vigenciaAtual);
    }

    if (bandeiras?.vigenciaFutura) {
      setFutura(bandeiras.vigenciaFutura[0])
    }

    if (bandeiras?.vigenciaHistorica) {
      setHistorico(bandeiras?.vigenciaHistorica[0])
    }
  }

  useEffect(() => { if (listaBandeiras) verificarExistenciaVigencia(listaBandeiras) }, [verificarExistenciaVigencia]);

  const [dataMinima, setDataMinima] = useState();
  const [dataMaxima] = useState();
  const [vigencia, setVigencia] = useState(null);

  const minData = useCallback(() => {
    if (type === "atual") {
      if (atual?.dtVigencia) {
        setDataMinima(window.moment(atual?.dtVigencia).add(1, "day"));
        setVigencia(data)
      } else {
        setDataMinima(window.moment(historico?.dtVigencia).add(1, "day"));
        setVigencia(data)
      }
    }

    if (type === "agendada") {
      if (futura?.dtVigencia) {
        if (atual?.dtVigencia) {
          setDataMinima(window.moment(atual?.dtVigencia).add(1, "day"));
        } else {
          setDataMinima(window.moment(historico?.dtVigencia).add(1, "day"));
        }
      }
    }
  }, [type, data, historico, futura, atual]);

  useEffect(() => { if (data) minData() }, [minData]);

  const [bandeiraVerde, setBandeiraVerde] = useState("");
  const [bandeiraAmarela, setBandeiraAmarela] = useState("");
  const [bandeiraVermelha1, setBandeiraVermelha1] = useState("");
  const [bandeiraVermelha2, setBandeiraVermelha2] = useState("");

  const onChangeDataVigencia = (dt) => setVigencia(dt);

  const handleValorVerde = (event) => setBandeiraVerde(event.target.value);
  const handleValorAmarela = (event) => setBandeiraAmarela(event.target.value);
  const handleValorVermelha1 = (event) => setBandeiraVermelha1(event.target.value);
  const handleValorVermelha2 = (event) => setBandeiraVermelha2(event.target.value);

  const obterBandeirasPorId = useCallback(async (idVigencia) => {
    try {
      const lista = await buscarBandeirasPorId(idVigencia);
      if (lista?.status === 200 || lista?.status === 204) {
        setVigencia(lista?.data.dtVigencia)
        setBandeiraVerde(lista?.data.valorVerde);
        setBandeiraAmarela(lista?.data.valorAmarelo);
        setBandeiraVermelha1(lista?.data.valorVermelho1);
        setBandeiraVermelha2(lista?.data.valorVermelho2);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  }, []);

  const isEdicao = useMemo(() => {
    return id && type === "atual"
  }, [id, type]);


  const enviarFormulario = async () => {
    try {
      const lastDate = moment("31/12/2099");
      const response = await salvarBandeira(id ?? 0, {
        id,
        dtVigencia: vigencia,
        valorVerde: bandeiraVerde,
        valorAmarelo: bandeiraAmarela,
        valorVermelho1: bandeiraVermelha1,
        valorVermelho2: bandeiraVermelha2,
      });

      if (response?.status === 200 && response.data) {
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: response.data
          })
        );

        setTimeout(() => {
          history.push(RotasDTO.ValoresVigencia);
        }, 2000);
      }
    } catch (error) {
      if (error.response.status == 400) {
        if (error.response.data.errors.valorVerde) {
          store.dispatch(
            alertaExibir({
              tipo: "warning",
              mensagem: "O campo Verde é obrigatório e não nulo."
            })
          );
        }

        if (error.response.data.errors.valorAmarelo) {
          store.dispatch(
            alertaExibir({
              tipo: "warning",
              mensagem: "O campo Amarela é obrigatório e não nulo."
            })
          );
        }

        if (error.response.data.errors.valorVermelho1) {
          store.dispatch(
            alertaExibir({
              tipo: "warning",
              mensagem: "O campo Vermelha 1 é obrigatório e não nulo."
            })
          );
        }

        if (error.response.data.errors.valorVermelho2) {
          store.dispatch(
            alertaExibir({
              tipo: "warning",
              mensagem: "O campo Vermelha 2 é obrigatório e não nulo."
            })
          );
        }
      } else {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem:
              error?.response?.data ??
              "Erro interno, entre em contato com o suporte!"
          })
        );
      }
    }
  }

  const aoEnviarFormulario = (dados) => {
    enviarFormulario(dados);
  }

  useEffect(() => {
    if (id) obterBandeirasPorId(id);
  }, [id, obterBandeirasPorId]);

  return (
    <form className="needs-validation" onSubmit={handleSubmit(aoEnviarFormulario)}>
      <Card className={classes.container}>
        <Grid container p={2} spacing={2} justifyContent="flex-end" className={classes.container}>
          <Grid item sm={11}>
            Nova vigência de Bandeiras Tarifárias
          </Grid>
          <Grid item sm={1}>
            <BotaoRetornarListagem
              justify="flex-end"
              urlListagem={RotasDTO.ValoresVigencia}
            />
          </Grid>
        </Grid>
        <Grid container p={2} spacing={2} alignItems="center" className={classes.container}>
          <Grid item sm={4}>
            <InputData
              type="text"
              id="dataVigencia"
              name="dataVigencia"
              label="Data de vigência"
              disabled={isEdicao}
              minDate={dataMinima}
              maxDate={dataMaxima}
              customValue={vigencia}
              onChange={(dt) => onChangeDataVigencia(dt)}
              renderIconShowHide={false}
              errors={errors}
            />
          </Grid>
          <Grid item sm={3}>
            <TextField
              id="bandeiraVerde"
              label="Verde (R$)"
              variant="outlined"
              value={bandeiraVerde}
              onChange={(valor) => handleValorVerde(valor)}
              InputProps={{
                inputComponent: NumberFormatCustom
              }}
      
            />
          </Grid>
          <Grid item sm={3}>
            <TextField
              id="bandeiraAmarela"
              label="Amarela (R$)"
              variant="outlined"
              value={bandeiraAmarela}
              onChange={(valor) => handleValorAmarela(valor)}
              InputProps={{
                inputComponent: NumberFormatCustom
              }}
            />
          </Grid>
          <Grid item sm={3}>
            <TextField
              id="bandeiraVermelha1"
              label="Vermelha 1 (R$)"
              variant="outlined"
              value={bandeiraVermelha1}
              onChange={(valor) => handleValorVermelha1(valor)}
              InputProps={{
                inputComponent: NumberFormatCustom
              }}
            />
          </Grid>
          <Grid item sm={3}>
            <TextField
              id="bandeiraVermelha2"
              label="Vermelha 2 (R$)"
              variant="outlined"
              value={bandeiraVermelha2}
              onChange={(valor) => handleValorVermelha2(valor)}
              InputProps={{
                inputComponent: NumberFormatCustom
              }}
            />
          </Grid>
        </Grid>
        <Grid container p={2} spacing={2} className={classes.container}>
          <Grid item sm={4}>
            <Botao type="submit" label="Salvar" className={classes.button} />
          </Grid>
        </Grid>
      </Card>
    </form >
  );
};

export default CadastroBandeiraTarifaria;