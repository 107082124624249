import { makeStyles } from '@mui/styles';
import { Base } from 'componentes/cores';

export const useStyles = makeStyles(() => ({
  container: {
    margin: 0,
    width: '100%',
    "& .MuiGrid-root": {
      color: `${Base.BlackBastille} !important`
    },
    "& .MuiDropzoneArea-text": {
      color: `${Base.BlackBastille} !important`
    },
    "& .MuiInputBase-root": {
      height: "46px"
    },
    "& .MuiFormLabel-root": {
      lineHeight: "1"
    }
  },
  containerTitulo: {
    padding: '18px !important'
  },
  titulo: {
    fontSize: '18px',
    color: '#000 !important',
    fontWeight: "700"
  },
  containerData: {
    padding: '32px !important'
  },
  icon: {
    cursor: "pointer",
    float:"right",
    margin: "5px",
    color: '#000 !important',
    fontWeight: "700"
  },
  button: {
    fontSize: '18px',
  },
}));