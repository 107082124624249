import MICROSERVICO from "global/enumeradores/microServico";
import obterApi from "servicos/api";

const api = () => obterApi(MICROSERVICO.AREALOGADA);

export const Salvar = async (params) => {
  const edicao = params.Id && params.Id !== 0;

  const url = `v1/categoriarelatorio${edicao ? "/atualiza" : "/cria"}`;
  const method = edicao ? "patch" : "post";

  return api()[method](url, params);
};

export const Listar = async (params) => {
  return api().get(
    `v1/categoriarelatorio/lista?${new URLSearchParams(params).toString()}`
  );
};

export const ObterPorId = async (params) => {
  // console.log('ObterPorId', params);

  return api().get(`v1/categoriarelatorio/obterporid/${params.id}`);
};

export const MudarStatus = async (id, status) => {
  const params = { Id: id, Ativo: status };

  return api().patch("v1/categoriarelatorio/atualizastatus", params);
};
