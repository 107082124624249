export default class FiltroListagemMedidoresDto {
  constructor(
    ordemSelecionada,
    filtroSelecionado,
    paginaAtual,
    unidadeConsumidoraId,
    searchString,
    clienteId,
    empresaId
  ) {
    this.ordemSelecionada = ordemSelecionada;
    this.filtroSelecionado = filtroSelecionado;
    this.paginaAtual = paginaAtual;
    this.unidadeConsumidoraId = unidadeConsumidoraId;
    this.searchString = searchString;
    this.clienteId = clienteId;
    this.empresaId = empresaId;
  }
}
