import MICROSERVICO from "global/enumeradores/microServico";
import obterApi from "servicos/api";
import { saveAs } from "file-saver";

const api = () => obterApi(MICROSERVICO.PROJECAOTARIFARIA);

  export const listarGruposProjecoes = async () => {
    return api().get("v1/GrupoProjecoes/getall");
  }

  export const buscaGrupoProjecoesPorId = async (id) => {
    return api().get(`v1/GrupoProjecoes/getbyid?idgrupoprojecoes=${id}`);
  }

  export const salvarGrupoProjecoes = async (id, data) => {
    let metodo = "post";
    let url = 'v1/GrupoProjecoes/insert';
    if (id > 0) {
      metodo = "put";
      url = 'v1/GrupoProjecoes/update'
    }
    return api()[metodo](url, data);
  };

  export const listarFiltrarPor = async () => {
    var retorno = await api().get("v1/GrupoProjecoes/getallstatusgrupoprojecoes");
    var retornofmt = retorno.data.map((item)=> {
      return {
      key: item.codigo,
      label: item.titulo
      }
    });
    return retornofmt;
  };

  export const excluirGrupo = async (idgrupo,idusuario,nomeusuario) => {
    return await api().delete(`v1/GrupoProjecoes/delete?idgrupoprojecoes=${idgrupo}&idUsuario=${idusuario}&nomeUsuario=${nomeusuario}`);
  };

  export const listarProjecoesPorGrupo = async (idgrupo) => {
    return api().get(`v1/GrupoProjecoes/getprojecoesbygrupo?idgrupoprojecoes=${idgrupo}`);
  };

  export const updateStatusGrupo = async (idgrupo, codigostatus) => {
    return api().put(`v1/GrupoProjecoes/updatestatusgrupo?idgrupoprojecoes=${idgrupo}&codigostatusgrupo=${codigostatus}`);
  };

  export const buscaStatusDoGrupo = async (idgrupo) =>
  {
    return api().get(`v1/GrupoProjecoes/getstatusbygrupo?idgrupoprojecoes=${idgrupo}`);
  };

  export const buscaExecutarProjecaoView = async (idgrupo) => {
    return api().get(`v1/GrupoProjecoes/getexecucaoprojecaoview?idgrupoprojecoes=${idgrupo}`);
  };

  export const buscaParametrosProjecoes = async (listId, idcenario) => {
    return api().get(`v1/ParametroDistribuidora/getbyidlistjoin?listId=${listId}&idcenario=${idcenario}`);
  };

  export const buscaListaConsultas = async () => {
    return api().get(`v1/GrupoProjecoes/getlistaconsultas?focogrupo=true`);
  };

  export const buscaListaConsultasGerais = async () => {
    return api().get(`v1/GrupoProjecoes/getlistaconsultas?focogrupo=false`);
  };

  export const buscaDistribuidoraPorGrupo = async (idgrupo) => {
    return api().get(`v1/GrupoProjecoes/getdistribuidoraporgrupo?idgrupoprojecoes=${idgrupo}`);
  };
  
  export const getExcelTarifaMedia = async (idgrupo,listadist, nomeArquivo) => {
    let resposta;
    await api().get(`v1/ExportarExcel/exportarplanilha/tarifamediaporgrupo?idgrupoprojecoes=${idgrupo}&listadistribuidora=${listadist}`,
     {
       headers:
               {
                   'Content-Disposition': "attachment;",
                   'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
               },
               responseType: 'arraybuffer'
     }).then((response) => {
      if(response.status === 200) 
      {
       const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
       saveAs(blob, nomeArquivo);
       resposta = "Sucesso";
      }
      else if(response.status === 202) 
      {
          resposta = new TextDecoder().decode(response.data);
      }

    });
    return resposta;
   }; 

   export const buscaListaSubgrupos = async () => {
    return api().get(`v1/GrupoProjecoes/getsubgrupo`);
  };

  export const buscaAnosPorGrupo = async (idgrupo) => {
    return api().get(`v1/GrupoProjecoes/getanosporgrupo?idgrupoprojecoes=${idgrupo}`);
  };

  export const salvarisOficialNinja = async (id) => {
    const metodo = "get";
    const url = `v1/GrupoProjecoes/validaninjaofficial?idGrupoProjecoes=${id}`;
    
    return api()[metodo](url);
  };
