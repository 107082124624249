// Proptypes
import PropTypes from "prop-types";

// Componentes
import { Button, Card, Divider, Grid } from "@mui/material";

// Hooks
import React, { useMemo } from "react";
import { useStyles } from "./style";

// DTOs
import PlanoConfiguracaoDto from "../../Dtos/planoConfiguracaoDto";
import PlanoSimuladoDto from "../../Dtos/planoSimuladoDto";

import IconeBronzeDelta from "../../assets/delta/bronze.png";
import IconeOuroDelta from "../../assets/delta/ouro.png";
import IconePrataDelta from "../../assets/delta/prata.png";

import IconeBronzeLuz from "../../assets/luz/bronze.png";
import IconeOuroLuz from "../../assets/luz/ouro.png";
import IconePrataLuz from "../../assets/luz/prata.png";

import IconeBronzeThymos from "../../assets/thymos/bronze.png";
import IconeOuroThymos from "../../assets/thymos/ouro.png";
import IconePrataThymos from "../../assets/thymos/prata.png";

const CardPlano = ({
  planoConfiguracao,
  dadosCards,
  onEscolherPlano,
  ehEdicao,
  cotacaoFechada,
  // eslint-disable-next-line react/prop-types
  planoSelecionado,
  // eslint-disable-next-line react/prop-types
  periodoMeses,
  // eslint-disable-next-line react/prop-types
  print,
  // eslint-disable-next-line react/prop-types
  dominio
}) => {
  const classes = useStyles({
    planoCor: planoConfiguracao?.corCard,
    corTexto: planoConfiguracao?.corTexto,
    opacity: planoConfiguracao?.opacity,
    cotacaoFechada,
    fundoColor: planoConfiguracao?.fundoColor,
    print
  });

  // Funcao de aplicacao de mascara
  const aplicarMascara = (valor) => {
    try {
      return valor.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
        maximumFractionDigits: 0,
        minimumFractionDigits: 0
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(
        "Quebrou a aplicação de mascara monetaria toLocaleString",
        error
      );
      return `R$ ${valor.toFixed(0)}`;
    }
  };

  const mesesParaAnos = (meses) => {
    return meses > 12 ? `${meses / 12} ANOS` : `${meses} MESES`;
  };

  // UseMemo para formatar a mascara das labels
  const periodo = useMemo(() => {
    if (!dadosCards?.periodo && !periodoMeses) return "";

    const periodoConversao = `${dadosCards?.periodo} ANOS`;
    const periodoMesesConversao = mesesParaAnos(periodoMeses);
    return print ? periodoMesesConversao : periodoConversao;
  }, dadosCards);

  const flexibilidade = useMemo(() => {
    const flex = dadosCards?.flexibilidade ?? 0;
    return flex > 0 ? flex : "±100%";
  });

  const economiaMensal = useMemo(() => {
    if (!dadosCards?.economiaMensal) return "";

    return aplicarMascara(Math.round(dadosCards.economiaMensal));
  }, dadosCards);

  const economiaAnual = useMemo(() => {
    if (!dadosCards?.economiaAnual) return "";

    return aplicarMascara(Math.round(dadosCards.economiaAnual));
  }, dadosCards);

  const economiaTotal = useMemo(() => {
    if (!dadosCards?.economiaTotal) return "";

    return aplicarMascara(Math.round(dadosCards.economiaTotal));
  }, dadosCards);

  const reducaoCustos = useMemo(() => {
    if (!dadosCards?.reducaoCustos && !dadosCards.percentualDesconto) return "";

    const reducaoValor = print
      ? dadosCards.percentualDesconto
      : dadosCards.reducaoCustos;
    const porcentagemString = reducaoValor
      .toFixed(2)
      .toString()
      .replace(".", ",")
      .toLocaleString("pt-br");

    return `${porcentagemString}%`;
  }, dadosCards);

  const onClickEscolher = () => {
    onEscolherPlano(dadosCards);
  };

  const icones = {
    "delta/bronze": IconeBronzeDelta,
    "delta/prata": IconePrataDelta,
    "delta/ouro": IconeOuroDelta,

    "luz/bronze": IconeBronzeLuz,
    "luz/prata": IconePrataLuz,
    "luz/ouro": IconeOuroLuz,

    "thymos/bronze": IconeBronzeThymos,
    "thymos/prata": IconePrataThymos,
    "thymos/ouro": IconeOuroThymos
  };

  const iconeNome = `${dominio}/${planoConfiguracao?.icone}`;

  const Icone = () => {
    const height = print ? 82 : 52;
    const width = print ? 84 : 54;

    return (
      <img src={icones[iconeNome]} height={height} width={width} alt="icone" />
    );
  };

  return (
    <Grid container className={classes.containerPadding}>
      <Grid item xs={12}>
        <Card className={classes.containerCard}>
          <Grid container>
            <Grid item xs={12}>
              <Card className={classes.containerColorido}>
                <Grid container>
                  <Grid item xl={2} lg={4} md={4} sm={3} xs={12}>
                    {iconeNome ? <Icone /> : null}
                  </Grid>
                  <Grid item xl={10} lg={8} md={8} sm={9} xs={12}>
                    <Grid container className={classes.containerPlano}>
                      <Grid item xs={12}>
                        <span className={classes.labelPlano}>
                          Desconto Garantido
                        </span>
                      </Grid>
                      <Grid item xs={12}>
                        <span className={classes.planoNome}>
                          {periodo}
                          {/* {planoConfiguracao.nomePlano} */}
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
          <Grid container className={classes.containerBranco}>
            <Grid item xs={12} className={classes.containerInformacoes}>
              <Grid container>
                <Grid item xs={12}>
                  <span className={classes.labelInformacoes}>Período</span>
                </Grid>
                <Grid item xs={12}>
                  <span className={classes.valorInformacoes}>{periodo}</span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.containerInformacoes}>
              <Grid container>
                <Grid item xs={12}>
                  <span className={classes.labelInformacoes}>
                    Flexibilidade
                  </span>
                </Grid>
                <Grid item xs={12}>
                  <span className={classes.valorInformacoes}>
                    {flexibilidade}
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.containerInformacoes}>
              <Grid container>
                <Grid item xs={12}>
                  <span className={classes.labelInformacoes}>
                    Economia mensal *
                  </span>
                </Grid>
                <Grid item xs={12}>
                  <span className={classes.valorInformacoes}>
                    {economiaMensal}
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.containerInformacoes}>
              <Grid container>
                <Grid item xs={12}>
                  <span className={classes.labelInformacoes}>
                    Economia anual *
                  </span>
                </Grid>
                <Grid item xs={12}>
                  <span className={classes.valorInformacoes}>
                    {economiaAnual}
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.containerInformacoes}>
              <Grid container>
                <Grid item xs={12}>
                  <span className={classes.labelInformacoes}>
                    Economia total *
                  </span>
                </Grid>
                <Grid item xs={12}>
                  <span className={classes.valorInformacoes}>
                    {economiaTotal}
                  </span>
                </Grid>
              </Grid>
            </Grid>
            {print ?? (
              <Grid item xs={12} className={classes.containerInformacoes}>
                <Grid container>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12} className={classes.containerInformacoes}>
              <Grid container>
                <Grid item xs={12}>
                  <span className={classes.labelInformacoes}>
                    Redução de custos
                  </span>
                </Grid>
                <Grid item xs={12}>
                  <span className={classes.valorReducaoCustos}>
                    {reducaoCustos}
                  </span>
                </Grid>
              </Grid>
            </Grid>
            {!ehEdicao && !cotacaoFechada && !print ? (
              <Grid item xs={12} className={classes.containerInformacoes}>
                <Grid container>
                  <Grid item xs={12}>
                    <Button
                      className={`${classes.botaoEscolher} ${classes.labelInformacoes}`}
                      onClick={() => onClickEscolher()}
                    >
                      Escolher
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <>
                {print && (
                  <Grid className={classes.containerSelecao}>
                    <Grid className={classes.caixaSelecao}>
                      <span>{planoSelecionado ? "X" : ""}</span>
                    </Grid>
                    <span className={classes.labelInformacoes}>
                      Selecionado
                    </span>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

CardPlano.propTypes = {
  planoConfiguracao: PropTypes.oneOfType([
    PropTypes.instanceOf(new PlanoConfiguracaoDto())
  ]).isRequired,
  dadosCards: PropTypes.oneOfType([
    PropTypes.instanceOf(new PlanoSimuladoDto())
  ]).isRequired,
  onEscolherPlano: PropTypes.oneOfType([PropTypes.func]).isRequired,
  ehEdicao: PropTypes.bool.isRequired,
  cotacaoFechada: PropTypes.bool.isRequired
};

export default CardPlano;
