/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import moment from "moment";

// Componentes
import { Grid, Tooltip } from "@mui/material";
import { Loader, SelectArredondado, TabelaPaginada } from "componentes";
import MaterialInputBusca from "componentes/inputBusca";
import ColunaComponentePersonalizado from "componentes/tabelaPaginada/colunas/colunaComponentePersonalizado";
import { saveAs } from "file-saver";
import { useSelector } from "react-redux";
import Coluna from "componentes/tabelaPaginada/colunas/coluna";
import ResultadoPaginadoDto from "componentes/tabelaPaginada/resultadoPaginadoDto";
import { formatarCampo } from "global/utils/formatCpfCnpj";
import { b64toBlob, isPdfFile } from "global/utils/blobUtil";

// Serviços
import {
  listarHistoricoCancelamento,
  obterSuspensaoDocumento
} from "servicos/cancelamentoServico";

// Redux
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import FaturaDistribuidoraDto from "./suspencaoCancelamentoDto";
import ColunaMultiplosBotoes from "./colunaCustomizada";

import enumerador from "./colunaCustomizada/enumerador";
import { useStyles } from "./style";

const ListagemSuspensaoCancelamento = () => {
  const global = useSelector((state) => state.usuario);
  const { empresaSelecionada, clienteSelecionado, unidadeSelecionada } = global;

  const [
    carregandoFaturaDistribuidora,
    setCarregandoFaturaDistribuidora
  ] = useState(false);
  const [lista, setLista] = useState([]);
  const [filtroAdicionalTabela, setFiltroAdicionalTabela] = useState();
  const [campoPesquisa, setCampoPesquisa] = useState("");
  const classes = useStyles();

  const { register, control } = useForm({
    reValidateMode: "onSubmit"
  });

  const textoBusca = useWatch({
    control,
    name: "textoBusca",
    defaultValue: ""
  });

  const textoParaBusca = useMemo(() => {
    return textoBusca?.length >= 3 ? textoBusca : "";
  }, [textoBusca]);

  const onClickDownload = async (solicitacaoCancelamentoId) => {
    setCarregandoFaturaDistribuidora(true);

    try {
      const dado = await obterSuspensaoDocumento(solicitacaoCancelamentoId);

      if (!dado) {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "Arquivo não encontrado!"
          })
        );
      } else {
        const blob = b64toBlob(
          dado?.arquivoBinario,
          isPdfFile(dado?.arquivoBinario) && "application/pdf"
        );

        saveAs(blob, dado.arquivoNome);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }

    setCarregandoFaturaDistribuidora(false);
  };

  const onClickBotao = (tipo, parametros) => {
    switch (tipo) {
      case enumerador.Download:
        onClickDownload(parametros.solicitacaoCancelamentoId);
        break;
      default:
        break;
    }
  };

  const colunas = [
    new Coluna("unidadeNomeRazaoSocial", "Unidade Consumidora"),
    new Coluna("unidadeCnpjCpf", "CPF/CNPJ"),
    new Coluna("unidadeNumeroInstalacao", "Número Instalação"),
    new Coluna(
      "dataRegistro",
      "Data Suspensão Cancelamento",
      false,
      null,
      "27%"
    ),
    new ColunaComponentePersonalizado(
      "download",
      "Download",
      ColunaMultiplosBotoes,
      onClickBotao,
      false,
      null,
      "12%"
    )
  ];

  const onChangeFiltrosTabela = async (parametros) => {
    // eslint-disable-next-line no-use-before-define
    return obterHistoricoCancelamento({
      pagina: parametros.pagina,
      pesquisar: parametros.pesquisar,
      campoPesquisa
    });
  };

  useEffect(() => {
    setFiltroAdicionalTabela({
      campoPesquisa,
      unidadeSelecionada
    });
  }, [empresaSelecionada, clienteSelecionado, unidadeSelecionada]);

  // eslint-disable-next-line consistent-return
  const obterHistoricoCancelamento = async (parametros) => {
    try {
      setCarregandoFaturaDistribuidora(true);

      const resultado = await listarHistoricoCancelamento({
        EtapaCancelamentoid: 8,
        PageIndex: parametros.pagina,
        PageSize: 10,
        ...(parametros.campoPesquisa && {
          [parametros.campoPesquisa]:
            parametros.campoPesquisa === "CPFCNPJ"
              ? parametros.pesquisar?.replace(/[^a-zA-Z0-9]/g, "")
              : parametros.pesquisar
        })
      });

      setCarregandoFaturaDistribuidora(false);

      if (!resultado.status === 200) {
        store.dispatch(
          alertaExibir({
            tipo: "danger",
            mensagem: resultado.mensagem
          })
        );
        return new ResultadoPaginadoDto([], 1, 0, 0);
      }

      if (resultado?.data?.items?.length === 0 ?? true)
        return new ResultadoPaginadoDto([], 1, 0, 0);

      const resLinhas = await resultado?.data?.items?.map((res) => {
        return new FaturaDistribuidoraDto(
          res.unidadeId,
          (
            <Tooltip
              title={
                <span style={{ fontSize: "14px" }}>
                  {res.unidadeNomeRazaoSocial}
                </span>
              }
              aria-label={res.unidadeNomeRazaoSocial}
            >
              <span>{res.unidadeNomeRazaoSocial}</span>
            </Tooltip>
          ),
          (
            <Tooltip
              title={
                <span style={{ fontSize: "14px" }}>
                  {formatarCampo(res.unidadeCnpjCpf)}
                </span>
              }
              aria-label={formatarCampo(res.unidadeCnpjCpf)}
            >
              <span>{formatarCampo(res.unidadeCnpjCpf)}</span>
            </Tooltip>
          ),
          res.unidadeNumeroInstalacao,
          moment(res.dataRegistro).format("DD/MM/YYYY HH:mm"),
          res.solicitacaoCancelamentoId
        );
      });
      setLista(resLinhas);

      return new ResultadoPaginadoDto(
        resLinhas,
        resultado.data.pageNumber,
        resultado.data.totalCount,
        resultado.data.pageSize
      );
    } catch (error) {
      setCarregandoFaturaDistribuidora(false);
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  return (
    <Loader loading={carregandoFaturaDistribuidora} className="w-auto">
      <Grid container spacing={3}>
        <Grid item md={4} xs={5}>
          <SelectArredondado
            select
            id="CampoPesquisa"
            name="CampoPesquisa"
            label="Filtrar por campo"
            placeholder="Filtrar por campo"
            valueKey="valor"
            valueName="nome"
            className={classes.select}
            dataSource={[
              { nome: "Sem filtro", valor: "" },
              { nome: "Unidade Consumidora", valor: "NomeFantasia" },
              { nome: "CPF/CNPJ", valor: "CPFCNPJ" },
              { nome: "Número Instalação", valor: "NumeroInstalacao" }
            ]}
            value={campoPesquisa}
            onChange={(e) => {
              setCampoPesquisa(e.target.value);
            }}
            permiteValorBranco
            styleForm={false}
          />
        </Grid>
        <Grid item md={4} xs={7}>
          {campoPesquisa !== "" ? (
            <>
              <MaterialInputBusca
                type="text"
                id="textoBusca"
                name="textoBusca"
                label="Buscar"
                className={classes.search}
                renderIconShowHide
                searchAdornment
                defaultValue={textoBusca ?? ""}
                ref={register}
                permiteValorBranco
              />
            </>
          ) : null}
        </Grid>
        <Grid item xs={12} className="mt-1">
          <TabelaPaginada
            onChangeFiltrosTabela={onChangeFiltrosTabela}
            linhas={lista}
            colunas={colunas}
            pesquisar={textoParaBusca}
            filtrosAdicionais={filtroAdicionalTabela}
            paginaAtual={1}
            paginationPerPage={2}
            className={classes.tabelaPaginada}
            noHeader
          />
        </Grid>
      </Grid>
    </Loader>
  );
};

export default ListagemSuspensaoCancelamento;
