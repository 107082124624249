import MICROSERVICO from "global/enumeradores/microServico";
import obterApi from "servicos/api";

const api = () => obterApi(MICROSERVICO.PROJECAOTARIFARIA);

export const uploadComponenteQFatorX = async (arquivo, idUsuario, nomeUsuario) => {
    const data = new FormData();
    data.append("file", arquivo.file);

    const metodo = "post";
    const url = `v1/ImportComponenteQdoFatorX/importa?idUsuario=${idUsuario}&nomeUsuario=${nomeUsuario}`;
    return api()[metodo](url, data);
};

export const getComponenteQFatorX = async () => {
    const metodo = "get";
    const url = "v1/ImportComponenteQdoFatorX/visualiza";
   
    return api()[metodo](url);
   
}



