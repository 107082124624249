/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

// Componentes
import { Card, Divider, Grid } from "@mui/material";
import Botao from "componentes/botao";
import Loader from "componentes/loader";

// Ícones
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import CopyrightIcon from "@mui/icons-material/Copyright";

// Styles

// Redux
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";

// DTO
import { RotasDTO } from "global/rotas/rotasUrlDto";
import ContratoHelper from "./helper";
import { Conteudo, useStyles } from "./style";

const Contrato = () => {
  const classes = useStyles();
  const history = useHistory();
  const { state } = useLocation();

  const [carregando, setCarregando] = useState(false);

  const [leuContrato, setLeuContrato] = useState(false);
  const [aceitouContrato, setAceitouContrato] = useState(false);

  useEffect(() => {
    window.jQuery("#conteudoContrato").on("scroll", function () {
      if (
        window.jQuery("#conteudoContrato").scrollTop() +
          window.jQuery("#conteudoContrato").innerHeight() >=
        window.jQuery("#conteudoContrato")[0].scrollHeight
      ) {
        setTimeout(function () {
          setLeuContrato(true);
        }, 100);
      }
    });
  }, []);

  const onClickAceito = () => {
    setAceitouContrato(!aceitouContrato);
  };

  const onClickAssinar = async () => {
    setCarregando(true);

    try {
      if (!aceitouContrato) {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "É necessário ler e aceitar o contrato antes de assinar!"
          })
        );
        return;
      }

      const result = await ContratoHelper.assinarContrato(
        state.signatario.chaveAssinaturaRequest
      );

      if (!result.sucesso) {
        ContratoHelper.exibirErro(result.mensagem);
        return;
      }

      history.push(`${RotasDTO.Demo}/cotacao/sucesso`);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Não foi possível assinar o contrato!"
        })
      );
    } finally {
      setCarregando(false);
    }
  };

  return (
    <Loader loading={carregando}>
      <Card className={classes.container}>
        <Grid
          container
          spacing={3}
          pr={6}
          justifyContent="space-around"
          className={classes.container}
        >
          <Grid
            item
            lg={12}
            md={12}
            className={`font-weight-bold py-4 ${classes.titulo}`}
          >
            Contrato Delta energia
          </Grid>
        </Grid>
        <Grid container spacing={3} pr={6} className={classes.container}>
          <Grid item lg={12}>
            <Conteudo id="conteudoContrato">
              <p>
                <strong>CDM MISS SILVIA MORIZONO</strong>, pessoa jurídica de
                direito privado, com sede na Rua Ministro Jesuino Cardoso 360,
                Vila Olímpia, CEP 04544-050, São Paulo/SP, inscrita no CNPJ/MF
                sob nº 27.537.389/0001-65, neste ato representada na forma
                prevista em seus atos constitutivos e doravante denominada{" "}
                <strong>Compradora</strong>; e
              </p>
              <p>
                <strong>DELTA COMERCIALIZADORA DE ENERGIA LTDA.</strong>, pessoa
                jurídica de direito privado, com sede na Avenida Faria Lima,
                4100, 7º andar, Vila Olímpia, CEP 04538-132, São Paulo/SP,
                inscrita no CNPJ/MF sob nº 04.802.543/0001-83 e na Inscrição
                Estadual sob o nº 117.236.866.112, neste ato representada na
                forma prevista em seus atos constitutivos e doravante denominada{" "}
                <strong>Vendedora</strong>.
              </p>
              <p>
                (i) A legislação aplicável ao setor de energia elétrica
                brasileiro, em especial o contido na Lei nº 9.074, de 7 de julho
                de 1995, Lei nº 9.427, de 26 de dezembro de 1996, Lei nº 9.648,
                de 27 de maio de 1998, Decreto nº 2.655, de 2 de julho de 1998,
                Lei nº 10.848, de 15 de março de 2004, regulamentada pelo
                Decreto nº 5.163, de 30 de julho de 2004 com as respectivas
                atualizações, bem como as resoluções da Agência Nacional de
                Energia Elétrica – ANEEL;
              </p>
              <p>
                (ii) A legislação aplicável ao setor de energia elétrica
                brasileiro, em especial o contido na Lei nº 9.074, de 7 de julho
                de 1995, Lei nº 9.427, de 26 de dezembro de 1996, Lei nº 9.648,
                de 27 de maio de 1998, Decreto nº 2.655, de 2 de julho de 1998,
                Lei nº 10.848, de 15 de março de 2004, regulamentada pelo
                Decreto nº 5.163, de 30 de julho de 2004 com as respectivas
                atualizações, bem como as resoluções da Agência Nacional de
                Energia Elétrica – ANEEL;
              </p>
              <p>
                (iii) A Compradora é pessoa jurídica, autorizada pela ANEEL na
                condição de agente da CCEE; e
              </p>
              <p>
                (iv) No caso da Compradora ainda não ser autorizada pela ANEEL,
                bem como não ser agente da CCEE,{" "}
                <u>
                  esta declara ter ciência de sua obrigação de envidar todos os
                  esforços necessários à obtenção da referida autorização e o
                  consequente cadastro na CCEE
                </u>
                .
              </p>
              <p>
                As Partes resolvem celebrar o presente Contrato de Compra e
                Venda de Energia Elétrica com Desconto Garantido, (doravante
                denominado “Contrato”), que se regerá pela legislação aplicável,
                e por estas Condições Gerais e seus respectivos Anexos, bem como
                pelos seguintes termos e condições.
              </p>
            </Conteudo>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          pr={6}
          justifyContent="flex-end"
          className={classes.container}
        >
          <Grid item lg={4} md={4} sm={12}>
            <Botao
              type="submit"
              label="Li e aceito"
              className={classes.buttonAssinar}
              icon={
                !aceitouContrato ? (
                  <CheckBoxOutlineBlankOutlinedIcon />
                ) : (
                  <CheckBoxOutlinedIcon />
                )
              }
              disabled={!leuContrato}
              onClick={() => onClickAceito()}
            />
          </Grid>
          <Grid item lg={12} md={12} className="pt-4">
            <Divider />
          </Grid>
        </Grid>
        <Grid container spacing={3} pr={6} className={classes.containerAvancar}>
          <Grid item lg={2} md={3} sm={12}>
            <Botao
              type="submit"
              label="Assinar"
              className={classes.button}
              icon={<CopyrightIcon />}
              onClick={() => onClickAssinar()}
            />
          </Grid>
        </Grid>
      </Card>
    </Loader>
  );
};

export default Contrato;
