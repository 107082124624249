import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import enumeradorStatus from "../../enumeradores/enumeradorStatus";
import { useStyles } from "./style";

const ColunaStatus = ({ parametros }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={0} className={classes.fundoBotoes}>
      <Grid
        item
        xs={1}
        className={
          parametros.statusId === enumeradorStatus.PENDENTE
            ? classes.fundoPendente
            : parametros.statusId === enumeradorStatus.ATRASADO
            ? classes.fundoAtrasado
            : parametros.statusId === enumeradorStatus.PAGO_PARCIAL
            ? classes.fundoPagoParcial
            : parametros.statusId === enumeradorStatus.PAGO
            ? classes.fundoPago
            : ""
        }
      />
      <Grid item xs={11} className={classes.textoStatus}>
        {parametros.statusNome}
      </Grid>
    </Grid>
  );
};

ColunaStatus.propTypes = {
  parametros: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired
};

export default ColunaStatus;
