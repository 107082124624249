import React from "react";
import PropTypes from "prop-types";
import { Box, Card, Link } from "@mui/material";
import { KeyboardBackspace } from "@mui/icons-material";
import { useStyles } from "./style";

const MensagemBaixaRenda = ({ setPassoAtual }) => {
  const classes = useStyles();

  const preventDefault = (event) => {
    event.preventDefault();
    setPassoAtual("1simulacao");
  };

  return (
    <Card className={classes.cardCadastro}>
      <Box pl={4}>
        <Box
          component="div"
          fontSize="45px"
          fontWeight="700"
          mb={3}
          className={classes.text}
        >
          Agradecemos seu interesse na LUZ!
        </Box>
        <Box
          component="div"
          color="#4F4F4F"
          fontSize="18px"
          fontWeight="700"
          mb={3}
        >
          Infelizmente nossos serviços ainda não estão disponíveis para a sua
          faixa de consumo ou região.
        </Box>
        <Box component="div" mb={3}>
          Estamos trabalhando para beneficiar o maior número de pessoas
          possível, então entraremos em contato assim que estivermos prontos
          para te atender!
        </Box>
        <Box display="flex" alignContent="center" mt={4} mb={12}>
          <Box mr={1}>
            <KeyboardBackspace cursor="pointer" htmlColor="#FA3EB1" />
          </Box>
          <Box
            style={{ color: "#FA3EB1", cursor: "pointer" }}
            component={Link}
            fontSize="16px"
            fontWeight="700"
            underline="always"
            onClick={preventDefault}
          >
            Voltar para o inicio
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

MensagemBaixaRenda.propTypes = {
  setPassoAtual: PropTypes.oneOfType([PropTypes.func])
};
MensagemBaixaRenda.defaultProps = {
  setPassoAtual: () => {}
};

export default MensagemBaixaRenda;
