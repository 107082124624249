/* eslint-disable no-continue */
/* eslint-disable consistent-return */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-shadow */
/* eslint-disable prefer-template */
/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from "react";
import { Grid, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { Base } from "componentes/cores";
import { Botao } from "componentes";
import BotaoOutLine from "componentes/botaoOutline";
import { useForm } from "react-hook-form";
import Loader from "componentes/loader";
import MaterialInputTexto from "componentes/inputTexto/materialInput";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { useSelector } from "react-redux";
import SelectAutocompleteCheckbox from "componentes/selectAutocompleteCheckbox";
import { useHistory } from "react-router-dom";
import { ModalVerificacao } from "componentes";
import { useStyles } from "./style";
import {
  getAllDistribuidoras,
  getImportsPCATDistribuidora,
  getImportsSPARTADistribuidora,
  getTempoEstimado,
  obterCenarios,
  orquestrar
} from "../../servicos/projecaoTarifaria";
import RotasDTO from "../../rotas/rotasUrlDto";
import ItemListaExpansivel from "./visualizar/itemListaExpansivel";

const ProjecaoTarifaria = () => {
  const usuarioGlobal = useSelector((state) => state.usuario);
  const classes = useStyles();

  const history = useHistory();

  const [showModal, setShowModal] = useState(false);

  const [hasResult, setHasResult] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [distribuidoras, setDistribuidoras] = useState([]);
  const [listaDistribuidorasEditada, setListaDistribuidorasEditada] = useState(
    []
  );
  const [distribuidora, setDistribuidora] = useState([]);

  const [projecoesComSucesso, setProjecoesComSucesso] = useState([]);
  const [erroProjecoes, setErroProjecoes] = useState([]);

  const [mostrarTempoEstimado, setMostrarTempoEstimado] = useState(false);
  const [tempoEstimado, setTempoEstimado] = useState("00:00");

  const [cenarios, setCenarios] = useState([]);
  const [cenario, setCenario] = useState("");
  const [cenarioDescricao, setCenarioDescricao] = useState("");

  const { handleSubmit } = useForm({
    reValidateMode: "onSubmit"
  });

  const getPCATDistribuidora = async (id) => {
    const retorno = await getImportsPCATDistribuidora(id);
    if (!(retorno.data && retorno.data.length > 0)) {
      return "";
    }
    return retorno.data[0].anoTarifario;
  };

  const getSPARTADistribuidora = async (id) => {
    const retorno = await getImportsSPARTADistribuidora(id);
    if (!(retorno.data && retorno.data.length > 0)) {
      return "";
    }
    return retorno.data[0].anoTarifario;
  };

  async function handleTratamentoDistribuidoras() {
    let error = [];
    let success = [];

    for (const distribuidoraSelecionada of distribuidora) {
      const distribuidoraEncontrada = distribuidoras.find(
        (distribuidoraElement) =>
          distribuidoraElement.idDistribuidora === distribuidoraSelecionada.id
      );

      if (!distribuidoraEncontrada) {
        error = [
          ...error,
          {
            ...distribuidoraSelecionada,
            message: `Distribuidora ${distribuidoraSelecionada.titulo} nao encontrada`
          }
        ];

        continue;
      }

      const PCAT = await getPCATDistribuidora(
        distribuidoraEncontrada.idDistribuidora
      );
      const SPARTA = await getSPARTADistribuidora(
        distribuidoraEncontrada.idDistribuidora
      );

      if (!PCAT && !SPARTA) {
        error = [
          ...error,
          {
            ...distribuidoraEncontrada,
            message: `Distribuidora ${distribuidoraEncontrada.titulo} sem PCAT e SPARTA`
          }
        ];

        continue;
      }

      if (!PCAT) {
        error = [
          ...error,
          {
            ...distribuidoraEncontrada,
            message: `Distribuidora ${distribuidoraEncontrada.titulo} sem PCAT`
          }
        ];

        continue;
      }

      if (!SPARTA) {
        error = [
          ...error,
          {
            ...distribuidoraEncontrada,
            message: `Distribuidora ${distribuidoraEncontrada.titulo} sem SPARTA`
          }
        ];

        continue;
        // eslint-disable-next-line no-else-return
      } else if (SPARTA !== PCAT) {
        error = [
          ...error,
          {
            ...distribuidoraEncontrada,
            message: `Distribuidora ${distribuidoraEncontrada.titulo} com SPARTA e PCAT distintos`
          }
        ];

        continue;
      }

      distribuidoraEncontrada.PCAT = PCAT;
      distribuidoraEncontrada.SPARTA = SPARTA;

      success = [...success, distribuidoraEncontrada];
    }
    return { success, error };
  }

  const handleDistribuidoras = async () => {
    try {
      setIsLoading(true);
      const lista = await getAllDistribuidoras();
      if (lista?.status === 200 && lista?.data) {
        setDistribuidoras(lista?.data);
        setListaDistribuidorasEditada(
          lista?.data.map((listItem) => {
            return {
              id: listItem.idDistribuidora,
              nome: listItem.titulo
            };
          })
        );
      } else {
        setDistribuidoras([]);
        setIsLoading(false);
      }
    } catch (error) {
      console.info(error);
      setIsLoading(false);
    }
  };

  const handleGetTempoEstimado = async () => {
    const response = await getTempoEstimado();
    const millis = response.data.milissegundos;

    const minutes = Math.floor(millis / 60000).toFixed(0);
    const seconds = ((millis % 60000) / 1000).toFixed(0);
    const tempo = minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
    setTempoEstimado(tempo);

    setMostrarTempoEstimado(true);
  };

  async function handleChamadaProjecao(distribuidorasParaProjecao) {
    let resposta = [];

    if (!distribuidorasParaProjecao) {
      throw new Error("Sem distribuidoras para projecao");
    }

    const promises = distribuidorasParaProjecao.map(
      async (distribuidoraData) => {
        const novaResposta = await orquestrar(
          distribuidoraData,
          usuarioGlobal.usuario?.id,
          usuarioGlobal.usuario?.nome
        );
        resposta = [
          ...resposta,
          { data: novaResposta.data, status: novaResposta.status }
        ];
      }
    );

    await Promise.all(promises);
    return resposta;
  }

  const handleCenarios = async () => {
    try {
      const lista = await obterCenarios();
      if (lista?.status === 200 && lista?.data) {
        setCenarios(lista?.data);
      } else {
        setCenarios([]);
      }
    } catch (error) {
      console.info(error);
    }
  };

  const handleChangeCenario = (event) => {
    cenarios.forEach((item) => {
      if (item.id === event) {
        setCenario(item);
        setCenarioDescricao(item.descricao);
      }
    });
  };

  const handleModalClose = () => {
    setShowModal(false);

    if (projecoesComSucesso.length === 1) {
      history.push(
        `${RotasDTO.ProjecaoTarifariaVisualizarDetalhes}/${projecoesComSucesso[0].data[0].IdProjecaoTarifaria}`
      );
    }
  };

  const onChangeDistribuidora = async (item) => {
    setDistribuidora(item);
  };

  const onClickEditar = (id) => {
    history.push(`${RotasDTO.ProjecaoTarifariaVisualizarDetalhes}/${id}`);
  };

  const clearLocalStorage = () => {
    localStorage.removeItem("projecoes");
    setHasResult(false);
  };

  useEffect(() => {
    if (hasResult) {
      setIsLoading(false);

      if (erroProjecoes.length === 0 && projecoesComSucesso.length === 1) {
        history.push(
          `${RotasDTO.ProjecaoTarifariaVisualizarDetalhes}/${projecoesComSucesso[0].data[0].IdProjecaoTarifaria}`
        );
      }

      if (
        projecoesComSucesso.length > 0 &&
        !localStorage.getItem("projecoes")
      ) {
        localStorage.setItem("projecoes", JSON.stringify(projecoesComSucesso));
      }
    }
  }, [hasResult]);

  useEffect(() => {
    const projecoes = JSON.parse(localStorage.getItem("projecoes"));
    const mostraProjecao = projecoes?.length > 1;

    if (mostraProjecao) {
      setProjecoesComSucesso(projecoes);
      setHasResult(true);
    }

    if (!cenarios?.length && !mostraProjecao) handleCenarios();
    if (!distribuidoras?.length && !mostraProjecao) handleDistribuidoras();

    handleGetTempoEstimado();
  }, []);

  useEffect(() => {
    setIsLoading(false);
  }, [listaDistribuidorasEditada]);

  const onSubmitForm = async () => {
    setIsLoading(true);
    setHasResult(false);

    if (cenario === "") {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            "Não foi possível executar a projeção, selecionar um cenário."
        })
      );
      setIsLoading(false);
      return;
    }

    const {
      success: distribuidorasParaProjecao,
      error: distribuidorasComErro
    } = await handleTratamentoDistribuidoras();

    try {
      const isDistribuidoraParaProjecaoNulo =
        !distribuidorasParaProjecao || distribuidorasParaProjecao.length === 0;
      setErroProjecoes(distribuidorasComErro);

      if (isDistribuidoraParaProjecaoNulo && distribuidorasComErro) {
        setShowModal(true);
        setIsLoading(false);
      }

      if (isDistribuidoraParaProjecaoNulo) {
        setIsLoading(false);
      }

      const data = distribuidorasParaProjecao.map(
        (distribuidoraSelecionada) => {
          return {
            distribuidora: {
              titulo: distribuidoraSelecionada.titulo,
              idDistribuidora: distribuidoraSelecionada.idDistribuidora,
              diaAniversarioTarifario:
                distribuidoraSelecionada.diaReajusteTarifario,
              mesAniversarioTarifario:
                distribuidoraSelecionada.mesReajusteTarifario,
              anoRealizado: distribuidoraSelecionada.PCAT,
              anoRevisaoTarifariaBase:
                distribuidoraSelecionada.anoRevisaoTarifariaBase,
              periodoRevisaoTarifaria:
                distribuidoraSelecionada.periodoRevisaoTarifaria,
              tipoContrato: distribuidoraSelecionada.tipoContrato,
              "mercadoSuprimento-": distribuidoraSelecionada.mercadoSuprimento,
              categoriaDistribuicao:
                distribuidoraSelecionada.categoriaDistribuicao,
              or_ud_er: distribuidoraSelecionada.or_ud_er,
              pesoVMAT: distribuidoraSelecionada.pesoVMAT,
              pesoVMMT: distribuidoraSelecionada.pesoVMMT,
              pesoVMBT: distribuidoraSelecionada.pesoVMBT,
              subsistema: distribuidoraSelecionada.subsistema
            },
            cenario: {
              IdCenario: cenario.id,
              NomeCenario: cenario.nome
            }
          };
        }
      );

      const response = await handleChamadaProjecao(data);

      const success = response.filter(
        (resposta) => resposta.status === 200 || 202
      );
      const error = response.filter(
        (resposta) => resposta.status !== 200 || 202
      );

      if (distribuidorasComErro.length > 0) {
        setErroProjecoes(distribuidorasComErro);
        setShowModal(true);
      }

      if (success.length > 0) {
        setProjecoesComSucesso(success);
        setHasResult(true);
      }
      setIsLoading(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Erro interno, contate o suporte!"
        })
      );

      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <Loader loading={isLoading} className="w-auto">
        <ModalVerificacao
          exibir={showModal}
          onClose={() => handleModalClose()}
          labelBotao="Ok"
          titulo="Falha"
          showBotao
          mensagem={
            <>
              <div>
                <p>
                  <strong>{erroProjecoes.length}</strong> distribuidoras com
                  erro
                </p>
              </div>
              {erroProjecoes.map((distribuidoraComErro) => (
                <>
                  <div>
                    <p>{distribuidoraComErro.message}</p>
                  </div>
                </>
              ))}
            </>
          }
        />
        <Grid container style={{ paddingLeft: "1.5em", paddingRight: "1.5em" }}>
          {!hasResult && (
            <>
              <Grid
                container
                p={2}
                spacing={3}
                alignItems="center"
                className={classes.container}
                justifyContent="space-between"
              >
                <Grid item xs={5}>
                  <SelectAutocompleteCheckbox
                    id="selectDistribuidoraId"
                    name="distribuidora"
                    options={listaDistribuidorasEditada}
                    label="Distribuidora"
                    style={classes}
                    getOptionLabel={(option) => option.nome || ""}
                    onChange={(e, item) => {
                      onChangeDistribuidora(item);
                    }}
                    value={distribuidora}
                    allowClear
                  />
                </Grid>
                {distribuidora.length > 0 && (
                  <>
                    <Grid item xs={2}>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <InputLabel id="energia-suprimento-label">
                          Cenário
                        </InputLabel>
                        <Select
                          variant="outlined"
                          labelId="energia-suprimento"
                          id="energiaSuprimento"
                          label="Cenário"
                          onChange={(e) => handleChangeCenario(e.target.value)}
                          fullWidth
                        >
                          {cenarios.map((item) => (
                            <MenuItem value={item?.id}>{item.nome}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={5}>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <MaterialInputTexto
                          type="text"
                          id="nomeSerieHistorica"
                          name="codigo"
                          label="Descrição"
                          renderIconShowHide={false}
                          defaultValue={cenarioDescricao}
                          readOnly
                        />
                      </FormControl>
                    </Grid>
                  </>
                )}
              </Grid>
              {distribuidora.length > 0 && (
                <Grid
                  container
                  alignItems="left"
                  style={{ paddingTop: "25px" }}
                >
                  <Grid xs={12}>
                    <InputLabel
                      style={{
                        color: Base.White,
                        fontSize: "1.2rem",
                        paddingTop: "5px"
                      }}
                    >
                      {`Tempo Médio Histórico por Distribuidora ${tempoEstimado}`}
                    </InputLabel>
                  </Grid>
                  <Grid xs={2} style={{ paddingTop: "25px" }}>
                    <Botao
                      type="submit"
                      label="Nova projeção"
                      color={Base.Mango}
                      className={classes.button}
                    />
                  </Grid>
                </Grid>
              )}
            </>
          )}

          {hasResult && (
            <Grid container p={2} spacing={3} className={classes.container}>
              <Grid item xs={9} direction="row">
                <h3
                  style={{
                    color: Base.White
                  }}
                >
                  Projeções Calculadas
                </h3>
                <button
                  type="button"
                  style={{
                    border: "1px solid white",
                    backgroundColor: Base.BlackRussian,
                    color: "white"
                  }}
                  onClick={() => clearLocalStorage()}
                >
                  Voltar
                </button>
              </Grid>

              {projecoesComSucesso.map((projecao) => (
                <>
                  <ItemListaExpansivel
                    key={projecao.data[0].IdProjecaoTarifaria}
                    label={projecao.data[0].Titulo}
                    color={Base.White}
                    edicao={!!projecao.data[0].IdProjecaoTarifaria}
                    onClickEditar={() =>
                      onClickEditar(projecao.data[0].IdProjecaoTarifaria)
                    }
                  >
                    <div>
                      <Grid spacing={1}>
                        <Grid container spacing={2}>
                          <Grid item sm={4}>
                            <strong>Distribuidora: </strong>
                          </Grid>
                          <Grid item sm={8}>
                            {projecao.data[0].Distribuidora}
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item sm={4}>
                            <strong>Status: </strong>
                          </Grid>
                          <Grid item sm={8}>
                            {projecao.data[0].status}
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item sm={4}>
                            <strong>Usuário: </strong>
                          </Grid>
                          <Grid item sm={8}>
                            {projecao.data[0].NomeUsuario}
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  </ItemListaExpansivel>
                </>
              ))}
            </Grid>
          )}
        </Grid>
      </Loader>
    </form>
  );
};

export default ProjecaoTarifaria;
