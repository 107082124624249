import RetornoEndpointDto from "../../../../../global/dto/retornoEndpointDto";
import { store } from "../../../../../global/redux";
import { alertaExibir } from "../../../../../global/redux/modulos/alertas/actions";
import {
  obterMatrizDescontoSimulacao,
  criarCotacaoGDSemFatura
} from "../../../../../servicos/leadClienteLuzServico";

export default class SimuladorMatrizDescontoHelper {
  static async obterMatrizDescontoSimulacao(
    params,
    paramsOpcional,
    setCarregandoMatrizDesconto
  ) {
    try {
      setCarregandoMatrizDesconto(true);
      const resultado = await obterMatrizDescontoSimulacao(
        params,
        paramsOpcional
      );
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Matriz desconto não encontrados!");

      return RetornoEndpointDto.Sucesso(
        "Matriz desconto obtida com sucesso",
        resultado.data
      );
    } catch (error) {
      if (error?.response?.status === 404) {
        return RetornoEndpointDto.Erro("semDesconto");
      }
      return RetornoEndpointDto.Erro("Matriz desconto não encontrados!");
    } finally {
      setCarregandoMatrizDesconto(false);
    }
  }

  static async criarCotacaoGDSemFatura(body, setCarregandoMatrizDesconto) {
    try {
      setCarregandoMatrizDesconto(true);
      const resultado = await criarCotacaoGDSemFatura(body);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Cotaçao gd pre lead não encontrada!");

      return RetornoEndpointDto.Sucesso(
        "Cotação gd pre lead criada com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Cotaçao gd pre lead não encontrada!");
    } finally {
      setCarregandoMatrizDesconto(false);
    }
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }

  static exibirSucesso(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem
      })
    );
  }
}
