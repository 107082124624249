import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

export default (reducers) => {
  const persistedReducer = persistReducer(
    {
      key: "delta",
      storage,
      whitelist: ["usuario", "rotas", "notificacoes", "log"],
      blacklist: []
    },
    reducers
  );

  return persistedReducer;
};
