import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const apiCalculoCustos = () => obterApi(MICROSERVICO.CALCULOCUSTOS);

export const calculoTotal = async (unidadeSelecionada) => {
  return apiCalculoCustos().get(
    `v1/Economia/calculoTotal/${unidadeSelecionada}`
  );
};

export const calculoConsolidado = async (unidadeSelecionada, mes, ano) => {
  return apiCalculoCustos().get(
    `v1/Economia/consolidado/${unidadeSelecionada}/${mes}/${ano}`
  );
};

export const historicoEconomia = async (
  unidadeSelecionada,
  dataInicial,
  dataFinal
) => {
  return apiCalculoCustos().get(
    `v1/Economia/historico/${unidadeSelecionada}/${dataInicial}/${dataFinal}`
  );
};
