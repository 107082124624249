import React, { useState, useEffect } from "react";
import { Card, Box, Grid, Button } from "@mui/material";
import { useSelector } from "react-redux";
import moment from "moment";
import { GetApp } from "@mui/icons-material";
import Loader from "../../../componentes/loader";
import { useStyles } from "./style";
import ClienteContratoHelper from "./helper";
import UnidadeNaoSelecionada from "../../../componentes/unidadeNaoSelecionada";
import { HtmlTooltip } from "../Documentos/style";

const BaixarContratos = () => {
  const classes = useStyles();
  const usuario = useSelector((state) => state.usuario);

  const {
    unidadeSelecionada,
    clienteSelecionado,
    empresaSelecionada
  } = usuario;
  const [carregandoDados, setCarregandoDados] = useState(false);
  const [contratos, setContratos] = useState([]);

  const onClickBotao = async (nomeGuid) => {
    await ClienteContratoHelper.downloadContrato(unidadeSelecionada, nomeGuid);
  };

  const buscarContratos = async () => {
    setCarregandoDados(true);

    const result = await ClienteContratoHelper.obterClienteContrato(
      unidadeSelecionada
    );

    if (!result.sucesso) {
      setContratos([]);
      setCarregandoDados(false);
      return;
    }

    if (result.data?.length > 0) {
      const contratosMap = result.data.map((r) => ({
        nome: `${r.nomeGuid}`,
        dataAssinatura: r.dataAssinatura
      }));
      setContratos(contratosMap);
    }
    setCarregandoDados(false);
  };

  useEffect(() => {
    buscarContratos();
  }, [unidadeSelecionada, clienteSelecionado, empresaSelecionada]);

  return (
    <>
      {unidadeSelecionada === "" ? (
        <UnidadeNaoSelecionada />
      ) : (
        <Loader loading={carregandoDados}>
          <Card>
            <Box component={Grid} container py={1} px={3}>
              <Box component={Grid} item xs={12} className={classes.title}>
                Baixar contratos
              </Box>
            </Box>

            <Box component={Grid} container spacing={3} p={3}>
              {contratos.length ? (
                contratos.map((contrato) => (
                  <Grid item xs={12} sm={6} md={4}>
                    <Card className={classes.contratoItem}>
                      <div className={classes.contratoTexto}>
                        <span className={classes.contratoNome}>
                          {contrato?.nome}
                        </span>
                        <span className={classes.contratoData}>
                          Data Assinatura: (
                          {moment(contrato?.dataAssinatura).format(
                            "DD/MM/YYYY"
                          )}
                          )
                        </span>
                      </div>
                      <Button
                        onClick={() => onClickBotao(contrato?.nome)}
                        className="text-white"
                      >
                        <HtmlTooltip title="Baixar">
                          <GetApp className={`${classes.contratoIcone}`} />
                        </HtmlTooltip>
                      </Button>
                    </Card>
                  </Grid>
                ))
              ) : (
                <Grid item xs={12}>
                  <div className={classes.semContratos}>
                    Não existem contratos para esse cliente!
                  </div>
                </Grid>
              )}
            </Box>
          </Card>
        </Loader>
      )}
    </>
  );
};

export default BaixarContratos;
