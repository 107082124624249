import React, { useEffect, useMemo, useRef, useState } from "react";
import { useForm, useWatch } from "react-hook-form";

// Componentes
import TuneIcon from "@mui/icons-material/Tune";
import { Grid } from "@mui/material";
import BotaoFiltroOrdenacao from "componentes/botaoFiltroOrdenar";
import { Base } from "componentes/cores";
import InputCalendario from "componentes/inputTextoDataBranco";
import TabelaPaginada from "componentes/tabelaPaginada";
import Coluna from "componentes/tabelaPaginada/colunas/coluna";
import ResultadoPaginadoDto from "componentes/tabelaPaginada/resultadoPaginadoDto";

// DTO

// Redux
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { useSelector } from "react-redux";

// Styles

// Serviços
import MaterialInputBusca from "componentes/inputBusca";
import { listarOrganizarPor } from "servicos/consolidacaoMedicaoServico";
import AlteracoesMedicoesDto from "../dto/alteracaoMedicoesDto";
import FiltroDto from "../dto/filtroDto";
import ConsolidacaoMedicaoHelper from "../helper";
import { useStyles } from "../style";

const AlteracaoMedicoes = () => {
  const global = useSelector((state) => state.usuario);
  const { empresaSelecionada, clienteSelecionado, unidadeSelecionada } = global;

  const [ordemSelecionada, setOrdemSelecionada] = useState("");

  const [data, setData] = useState(window.moment().subtract(1, "month"));
  const [ano, setAno] = useState(window.moment(data).format("yyyy"));
  const [mes, setMes] = useState(window.moment(data).format("MM"));

  const [ordemColunaSelecionada, setOrdemColunaSelecionada] = useState();
  const [pagina, setPagina] = useState(1);
  const [tamanhoPagina] = useState(10);

  const { register, control } = useForm({
    reValidateMode: "onSubmit"
  });

  const textoBusca = useWatch({
    control,
    name: "textoBusca",
    defaultValue: ""
  });

  const textoParaBusca = useMemo(() => {
    return textoBusca?.length >= 3 ? textoBusca : "";
  }, [textoBusca]);

  const [filtroDto, setFiltroDto] = useState(
    new FiltroDto(
      ano,
      mes,
      unidadeSelecionada,
      clienteSelecionado,
      empresaSelecionada,
      ordemSelecionada,
      "", // textoParaBusca,
      pagina,
      tamanhoPagina
    )
  );

  const classes = useStyles();
  const refTabela = useRef(null);

  const colunas = [
    new Coluna("unidadeNome", "Unidade"),
    new Coluna("medidor", "Medidor", false, "13%", "13%"),
    new Coluna("dataAlteracao", "Data da alteração", false, "13%", "13%"),
    new Coluna("consumoTotal", "Valor prévio kWh", false, "13%", "13%"),
    new Coluna("dataApto", "Data da consolidação", false, "13%", "13%"),
    new Coluna(
      "consumoTotalApto",
      "Valor consolidado kWh",
      false,
      "13%",
      "13%"
    ),
    new Coluna("diferencaConsumoTotal", "Diferença kWh", false, "13%", "13%")
  ];

  const obterAlteracaoMedicao = async (parametros) => {
    setAno(parametros.ano);
    setMes(parametros.mes);

    const filtro = new FiltroDto(
      parametros.ano,
      parametros.mes,
      unidadeSelecionada,
      clienteSelecionado,
      empresaSelecionada,
      ordemSelecionada,
      parametros.pesquisar,
      parametros.pagina,
      tamanhoPagina
    );

    const resultado = await ConsolidacaoMedicaoHelper.ListarAlteracaoMedicoesConsolidadas(
      filtro
    );

    if (!resultado.sucesso) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: resultado.mensagem
        })
      );
      return new ResultadoPaginadoDto([], 1, 0, 0);
    }

    const resLinhas = resultado?.data?.data?.listaConsolidacaoMedidor.map(
      (res) => {
        return new AlteracoesMedicoesDto(
          res.id,
          res.mes,
          res.ano,
          res.medidor,
          res.consumoTotal,
          res.consumoTotalApto,
          res.diferencaConsumoTotal,
          res.dataAlteracao,
          res.dataApto,
          res.unidadeNome
        );
      }
    );

    return new ResultadoPaginadoDto(
      resLinhas,
      pagina,
      resultado?.data?.data?.totalItens,
      resultado?.data?.data?.totalPaginas
    );
  };

  const onChangeFiltrosTabela = async (parametros) => {
    setPagina(parametros.pagina);

    return obterAlteracaoMedicao({
      tamanhoPagina: parametros.totalPagina,
      pagina: parametros.pagina,
      pesquisar: parametros.pesquisar,
      mes: window.moment(data).format("MM"),
      ano: window.moment(data).format("yyyy"),
      ordenacao: parametros?.filtrosAdicionais?.ordenacao ?? "",
      empresaId: parametros?.filtrosAdicionais?.empresaSelecionada,
      clienteId: parametros?.filtrosAdicionais?.clienteSelecionado,
      unidadeId: parametros?.filtrosAdicionais?.unidadeSelecionada
    });
  };

  const handleClickAtivarFiltro = () => {
    setFiltroDto(
      new FiltroDto(
        ano,
        mes,
        unidadeSelecionada,
        clienteSelecionado,
        empresaSelecionada,
        ordemSelecionada,
        textoParaBusca,
        pagina,
        tamanhoPagina
      )
    );
  };

  const handleClickOrdemSelecionada = (event) => {
    const val = ordemSelecionada === event ? "" : event;
    setOrdemSelecionada(val);
  };

  useEffect(() => {
    setFiltroDto(
      new FiltroDto(
        window.moment(data).format("yyyy"),
        window.moment(data).format("MM"),
        unidadeSelecionada,
        clienteSelecionado,
        empresaSelecionada,
        ordemSelecionada,
        textoParaBusca,
        pagina,
        tamanhoPagina
      )
    );
  }, [empresaSelecionada, clienteSelecionado, unidadeSelecionada, data]);

  const handleClickSortOrderSelecionado = (valor, idCampoSelecionado) => {
    const val =
      ordemColunaSelecionada === idCampoSelecionado && valor === ""
        ? ""
        : idCampoSelecionado;
    setOrdemColunaSelecionada(val);
    setOrdemSelecionada(valor);
  };

  return (
    <Grid container className="ml-3 mr-3 mb-3 w-auto">
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item md={5}>
          <MaterialInputBusca
            type="text"
            id="textoBusca"
            name="textoBusca"
            label="Buscar"
            renderIconShowHide
            searchAdornment
            permiteValorBranco
            defaultValue={textoBusca ?? ""}
            ref={register}
          />
        </Grid>
        <Grid item md={2}>
          <InputCalendario
            styleForm={false}
            type="text"
            id="data"
            name="data"
            format="MMMM/yyyy"
            maxDate={window.moment().subtract(1, "month")}
            customValue={data}
            onChange={(valor) => setData(valor)}
            renderIconShowHide={false}
            views={["month", "year"]}
          />
        </Grid>
        <Grid item md={2}>
          <BotaoFiltroOrdenacao
            type="button"
            color={Base.OffWhite}
            background="transparent"
            label="Filtrar / Ordernar"
            icon={<TuneIcon />}
            className={classes.buttonFiltro}
            ordenacaoColuna={listarOrganizarPor()}
            ordenadorUm="Ordenador"
            ordenadorDois="Filtrar"
            ordemSelecionada={ordemSelecionada}
            ordemColunaSelecionada={ordemColunaSelecionada}
            onClickOrdenacao={handleClickOrdemSelecionada}
            onClickAtivarAgora={handleClickAtivarFiltro}
            onClickSortOrder={handleClickSortOrderSelecionado}
            larguraBotao="3"
          />
        </Grid>
      </Grid>
      <Grid item xs={12} className="mt-4">
        <TabelaPaginada
          ref={refTabela}
          onChangeFiltrosTabela={onChangeFiltrosTabela}
          colunas={colunas}
          pesquisar={textoBusca}
          filtrosAdicionais={filtroDto}
          paginaAtual={pagina}
          paginationPerPage={tamanhoPagina}
          noHeader
        />
      </Grid>
    </Grid>
  );
};

export default AlteracaoMedicoes;
