export default class ListagemTermosDto {
  constructor(
    id,
    dataInicioVigencia,
    dataFimVigencia,
    dataCadastro,
    empresa,
    produto,
    nomeUsuarioResponsavel
  ) {
    this.id = id;
    this.dataInicioVigencia = dataInicioVigencia;
    this.dataFimVigencia = dataFimVigencia;
    this.dataCadastro = dataCadastro;
    this.produto = produto;
    this.empresa = empresa;
    this.produto = produto;
    this.nomeUsuarioResponsavel = nomeUsuarioResponsavel;
  }
}
