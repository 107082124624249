import { atualizaLog, enviaLog } from "./logDeltaSdkServico";

export async function montaAtualizaLog(
  requestLog,
  responseLog,
  nestedField,
  searchField,
  searchContent,
  index
) {
  try {
    const queryIndexId = {
      _source: searchField,
      query: {
        match: {
          [searchField]: searchContent
        }
      }
    };
    const doc = {
      doc: {
        [nestedField]: [
          {
            request: requestLog,
            response: responseLog,
            date: new Date()
          }
        ]
      }
    };
    await atualizaLog(queryIndexId, doc, index, nestedField);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

// eslint-disable-next-line consistent-return
export async function montaEnviaLog(
  mainFields,
  requestLog,
  responseLog,
  nestedField,
  message,
  index,
  dataIndex
) {
  try {
    const logElastic = {
      ...mainFields,
      [nestedField]: [
        {
          response: responseLog,
          request: requestLog
        }
      ]
    };
    const res = await enviaLog(2, message, logElastic, index, dataIndex);
    return res;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}
