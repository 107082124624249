/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from "react";
import { Grid, FormControl,Typography } from "@mui/material";
import Loader from "componentes/loader";
import DataTable from "react-data-table-component";
import Coluna from "componentes/tabelaPaginada/colunas/coluna";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { useStyles, customStyles } from "./style";
import { useHistory, useParams } from "react-router-dom";
import { getDetalhesMercadoFaturado } from "submodules/DeltaTarifa/servicos/projecaoTarifaria";
import MaterialInputTexto from "componentes/inputTexto/materialInput";
import BotaoRetornarListagem from "componentes/botaoRetornarListagem";
import RotasDTO  from "../../../../rotas/rotasUrlDto";
import { Botao } from "componentes";
import moment from "moment";
import { Base } from "componentes/cores";

const DetalheMercadoFaturado = () => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);

    const { id, etapa } = useParams();
    const history = useHistory();

    const [tituloProjecao, setTituloProjecao] = useState("")
    const [cenario, setCenario] = useState("")
    const [dataProjecao, setDataProjecao] = useState("")
    const [distribuidora, setDistribuidora] = useState("")

    const [detalhesTabela, setDetalhesTabela] = useState([]);
    const [msgAguarde, setMsgAguarde] = useState(<Typography 
        style={{color: Base.OffWhite, paddingTop:"100px", paddingLeft:"42%"}}>Aguarde a carga dos dados...</Typography>)


    const colunasDetalhes = [
        new Coluna("grupo", "Grupo", false),
        new Coluna("anoTarifario", "Ano", false),
        new Coluna("montante", "Valor", false),
        new Coluna("unidade", "Unidade", false),
    ];

    const listarDetalhes = async () => {
        try {
            await getDetalhesMercadoFaturado(id).then((response) => {
                if (response.status === 200 && response.data) {
                    setTituloProjecao(response.data.projecao);
                    setCenario(response.data.cenario);
                    setDataProjecao(moment(response.data.dataProjecao).format("L"));
                    setDistribuidora(response.data.distribuidora)
                    setDetalhesTabela(response.data.itens.map((item) => {
                        return {
                            ...item,
                            montante: item.montante.toFixed(2).toString().replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
                        };
                    }));
                } else {
                    store.dispatch(
                        alertaExibir({
                            tipo: "warning",
                            mensagem:
                                "Erro ao buscar detalhes da etapa!"
                        })
                    );
                }
            });
        } catch (error) {
            store.dispatch(
                alertaExibir({
                    tipo: "warning",
                    mensagem:
                        "Erro ao buscar detalhes da etapa!"
                })
            );
        }
    }

    useEffect(() => {
        setIsLoading(true);
        listarDetalhes();
        setIsLoading(false);
        setMsgAguarde();
    }, [])

    return (
        <Loader loading={isLoading} className="w-auto">
            <Grid container style={{ paddingLeft: "1.5em", paddingRight: "1.5em" }}>
                <Grid item xs={8} style={{ marginBottom: 20 }}>
                    <h4 style={{color: "white"}}>{tituloProjecao}</h4>
                    <h5 style={{color: "white"}}>Mercado Faturado</h5>
                </Grid>
                <Grid item xs={3}>
                    <Botao
                        type="submit"
                        label="Memória de Cálculo"
                        color={Base.Mango}
                        className={classes.button}
                        onClick={() => {
                            history.push(`${RotasDTO.ProjecaoTarifaria}/visualizar/detalhes/${id}${RotasDTO.DetalheMercadoFaturado}/${etapa}/Mercado Faturado${RotasDTO.MemoriaCalculo}`)
                        }}
                    />
                </Grid>
                <Grid item xs={1} className={classes.backButton}>
                    <BotaoRetornarListagem voltarPagina />
                </Grid>
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={4}>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <MaterialInputTexto
                                fullWidth
                                type="text"
                                id="distribuidora"
                                name="distribuidora"
                                label="Distribuidora"
                                renderIconShowHide={false}
                                defaultValue={distribuidora}
                                disabled
                            />

                        </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <MaterialInputTexto
                                fullWidth
                                type="text"
                                id="cenario"
                                name="cenario"
                                label="Cenario"
                                disabled
                                renderIconShowHide={false}
                                defaultValue={cenario}
                            />

                        </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <MaterialInputTexto
                                fullWidth
                                type="text"
                                id="data"
                                name="data"
                                label="Realizada em"
                                renderIconShowHide={false}
                                defaultValue={dataProjecao}
                            />

                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container item xs={12} spacing={3} justifyContent="center" className="w-100">
                    <Loader loading={isLoading} className="w-100">
                        {msgAguarde}
                    </Loader>
                </Grid>   

                {detalhesTabela.length > 0 &&
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={12}>
                            <DataTable
                                subHeader
                                subHeaderAlign='right'
                                columns={colunasDetalhes}
                                data={detalhesTabela}
                                theme="DeltaEnergia"
                                customStyles={customStyles}
                                noDataComponent="Nenhum registro encontrado"
                                noHeader
                            />
                    
                        </Grid>
                    </Grid>
                }
            </Grid>
        </Loader>
    );
}

export default DetalheMercadoFaturado;
