import React from "react";
import PropTypes from "prop-types";
import { Grid, Tooltip } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import HelpIcon from "@mui/icons-material/Help";
import { RadioLuz } from "./style";



const InputRadioGroupLuz = ({
  label,
  id,
  name,
  onChange,
  customValue,
  helperLabel,
  disabled,
  itens,
  classNameLabel
}) => {
 
  return (
    <Grid container spacing={0} alignItems="flex-end">
      <Grid item lg={helperLabel ? 11 : 12} xs={helperLabel ? 11 : 12}>
        <FormControl variant="standard" component="fieldset">
          <FormLabel className={classNameLabel} component="legend">
            {label}
          </FormLabel>

          <Grid container spacing={0} alignItems="center">
            {itens?.map((item) => (
              <Grid item>
                <FormControlLabel
                  onChange={(valor) => onChange(valor)}
                  value={item.valor}
                  checked={item.valor === customValue}
                  control={<RadioLuz id={id} name={name} />}
                  label={item.label}
                  style={{ display: "inline-block" }}
                  disabled={item.disabled || disabled}
                />
              </Grid>
            ))}
          </Grid>

        </FormControl>
      </Grid>
      {helperLabel ? (
        <Grid item lg={1}>
          <Tooltip title={helperLabel} aria-label={helperLabel}>
            <HelpIcon />
          </Tooltip>
        </Grid>
      ) : null}
    </Grid>
  );
};

InputRadioGroupLuz.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.oneOfType([PropTypes.func]),
  helperLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  customValue: PropTypes.bool,
  classNameLabel: PropTypes.oneOfType([PropTypes.any]),
  disabled: PropTypes.bool,
  itens: PropTypes.oneOfType([PropTypes.object]),
};

InputRadioGroupLuz.defaultProps = {
  label: "",
  id: "",
  onChange: () => {},
  helperLabel: "",
  customValue: false,
  classNameLabel: "",
  disabled: false,
  itens: []
};

export default InputRadioGroupLuz;
