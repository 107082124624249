import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import getDomain from "global/getDomain";
import {
  formataCpf,
  formataTelefone,
  removeMaskCpfCnpj,
  removeMaskTelefone
} from "../../../servicos/utils";
import { Fundo, Logo, Wrapper, Titulo } from "../style";
import Campos from "./campos";
import { Botao, CardLogin, Loader, Alerta } from "../../../componentes";
import UsuarioCriHelper from "./helper";
import Delta from "../../../themes/delta/assets/logo-217x132.png";

const CadastroCri = () => {
  const { register, handleSubmit, watch, errors } = useForm({
    mode: "onSubmit"
  });

  const [carregando, setCarregando] = useState(false);
  const history = useHistory();
  const [mensagemAlerta, setMensagemAlerta] = useState("");

  const salvarUsuarioCri = async (dados) => {
    const model = {
      nome: dados?.nome,
      cnpjCpf: removeMaskCpfCnpj(dados?.cnpjCpf),
      email: dados?.email,
      telefone: removeMaskTelefone(dados?.telefone)
    };

    const { sucesso, mensagem } = await UsuarioCriHelper.salvarUsuarioCri(
      model,
      setCarregando
    );

    if (!sucesso) {
      setMensagemAlerta({
        tipo: "danger",
        mensagem
      });
      return;
    }

    history.push("/login/cri");
  };

  const enviarFormulario = (dados) => {
    salvarUsuarioCri(dados);
  };

  const aoEnviarFormulario = (dados) => enviarFormulario(dados);

  return (
    <>
      <Fundo
        url={`../themes/${getDomain()}/assets/bg-login.jpg`}
        className="w-100 h-100 position-absolute overflow-hidden"
      />
      <Box
        component={Grid}
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh", backgroundColor: "#332053" }}
      >
        <Logo src={Delta} alt="logo" />
        <CardLogin height={533} width={468} className="mx-auto">
          <Wrapper className="mx-auto">
            <Titulo italic="italic">Cadastro</Titulo>

            <form onSubmit={handleSubmit(aoEnviarFormulario)}>
              <Loader loading={carregando}>
                <Campos
                  register={register}
                  errors={errors}
                  values={{
                    telefone: formataTelefone(watch("telefone")),
                    cnpjCpf: formataCpf(watch("cnpjCpf")),
                    email: watch("email"),
                    nome: watch("nome")
                  }}
                />

                <Botao
                  type="submit"
                  label="Cadastrar"
                  style={{ marginTop: 24, marginBottom: 36 }}
                />
              </Loader>
            </form>
          </Wrapper>
          <Box className="mt-2">
            {mensagemAlerta && (
              <Alerta
                {...mensagemAlerta}
                onClose={() => setMensagemAlerta("")}
              />
            )}
          </Box>
        </CardLogin>
      </Box>
    </>
  );
};

export default CadastroCri;
