import React from "react";
import { Box } from "@mui/material";
import { useStyles } from "./style"

const MensagemDefault = () => {
    const classes = useStyles()
    return (
        <Box
            bgcolor="rgb(245, 245, 247)"
            p={2}
            mb={2}
            borderRadius="8px"
            textAlign="center"
        >
            <Box
               fontSize={{ xs: "22px", lg: "40px"}} 
               fontWeight="700"
               color="#332053"
            >
                Desconto totalmente garantido
            </Box>
            <Box
                fontSize={{ xs: "32px", lg: "45px"}}
                fontWeight="700"
                className={classes.text}
            >
                nas suas próximas
            </Box>
            <Box
                fontSize={{ xs: "36px", lg: "47px"}}
                fontWeight="700"
                className={classes.text}
            >
                CONTAS DA LUZ
            </Box>
        </Box>
    )
}

export default MensagemDefault;
