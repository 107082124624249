/* eslint-disable react/prop-types */
import CloseIcon from "@mui/icons-material/Close";
import { Grid } from "@mui/material";
import MaterialInputTexto from "componentes/inputTexto/materialInput";
import MaterialInputTelefone from "componentes/inputTexto/materialInputTelefone";
import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useState
} from "react";
import { useForm } from "react-hook-form";
import { useStyles } from "../style";

const ContatoDemo = forwardRef(
  ({ ehRepresentante, onClickRemoverResponsavel, dadosPadrao }, ref) => {
    const classes = useStyles();

    const tamanhoColuna = useMemo(() => (ehRepresentante ? 3 : 4), [
      ehRepresentante
    ]);

    // eslint-disable-next-line no-unused-vars
    const [telefone, setTelefone] = useState();

    const { register, errors, control, setError, reset } = useForm({
      reValidateMode: "onSubmit"
    });

    const handleValidarCampos = async () => {
      return control?.trigger();
    };

    const handleObterValores = () => {
      const retorno = {
        ...dadosPadrao,
        ...control.getValues()
      };

      if (ehRepresentante) {
        retorno.cpf = 66678870115;
        retorno.rg = 59785149;
      }

      return retorno;
    };

    const handleSetarErro = (campo, mensagem) => {
      setError(campo, {
        type: "manual",
        message: mensagem
      });
    };

    const handleLimparRepresentante = () => {
      reset({});

      onClickRemoverResponsavel();
    };

    useImperativeHandle(ref, () => ({
      validarCampos() {
        return handleValidarCampos();
      },
      obterValores() {
        return handleObterValores();
      },
      setarErro(campo, mensagem) {
        handleSetarErro(campo, mensagem);
      }
    }));

    return (
      <form className="needs-validation">
        <Grid container spacing={2} className={classes.containerCampos}>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <MaterialInputTexto
              type="text"
              id="nomeCompleto"
              name="nomeCompleto"
              label="Nome"
              defaultValue={dadosPadrao?.nomeCompleto}
              renderIconShowHide={false}
              ref={register({
                required: "Campo Nome é obrigatório!",
                maxLength: {
                  value: 100,
                  message: "Quantidade máxima de 100 caracteres!"
                }
              })}
              errors={errors}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <MaterialInputTexto
              type="text"
              id="email"
              name="email"
              defaultValue={dadosPadrao?.email}
              label="Email"
              renderIconShowHide={false}
              ref={register({
                required: "O campo E-mail do Contato é obrigatório!",
                pattern: {
                  value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: "Campo E-mail do Contato inválido!"
                },
                maxLength: {
                  value: 100,
                  message: "Quantidade máxima de 100 caracteres!"
                }
              })}
              errors={errors}
            />
          </Grid>
          <Grid item lg={tamanhoColuna} md={tamanhoColuna} sm={12} xs={12}>
            <MaterialInputTelefone
              type="text"
              id="telefone"
              name="telefone"
              label="Telefone"
              renderIconShowHide={false}
              defaultValue={dadosPadrao?.telefone}
              value={telefone}
              ref={register({
                required: "O campo Telefone é obrigatório!",
                minLength: {
                  value: 18,
                  message: "Quantidade mínima de 12 caracteres!"
                }
              })}
              errors={errors}
            />
          </Grid>
          {ehRepresentante && (
            <Grid
              item
              lg={1}
              md={1}
              sm={1}
              justifyContent="flex-end"
              className={classes.item}
            >
              <Grid container justifyContent="flex-end">
                <Grid item xs={12} justifyContent="flex-end">
                  <CloseIcon
                    className={(classes.icon, classes.iconeCentralizar)}
                    onClick={() => handleLimparRepresentante()}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </form>
    );
  }
);

export default ContatoDemo;
