import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const api = () => obterApi(MICROSERVICO.PROFILE);

export const listarOrganizarPor = () => {
  const ordenar = [
    {
      key: "4",
      asc: "4",
      desc: "0",
      label: "Numéricos"
    }
  ];

  return ordenar;
};

export const filtroEmpresaPor = () => {
  const ordenar = [
    {
      key: "1",
      label: "Delta"
    },
    {
      key: "23",
      label: "Thymos"
    },
    {
      key: "32",
      label: "Luz"
    },
  ];

  return ordenar;
};

export const listarFiltrarPor = () => {
  const filtros = [
    {
      key: "AT",
      label: "Ativo"
    },
    {
      key: "IN",
      label: "Inativo"
    }
  ];

  return filtros;
};

// Verifica se o usuário tem pendencia de aceite do termo de uso
export const existeTermoDeUsoPendenteDeAceite = async (usuarioId) => {
  return api().get(
    `v1/TermoDeUso/ExisteTermoDeUsoPendenteDeAceite/${usuarioId}`
  );
};

// Obtém o termo de uso vigente
export const obterTermoDeUsoVigente = async () => {
  return api().get("v1/TermoDeUso");
};

export const obterUltimoTermoDeUsoAceito = async () => {
  return api().get("v1/TermoDeUso/UltimoTermoAceito");
};

export const listaTermoDeUso = async (filtro) => {
  const filtroQueryString = new URLSearchParams(filtro).toString();
  return api().get(`v1/TermoDeUso/Paginacao?${filtroQueryString}`);
};

export const obterTermoPorId = async (id) => {
  return api().get(`v1/TermoDeUso/${id}`);
};

export const salvarTermo = async (id, dados) => {
  let metodo = "post";
  let url = "v1/TermoDeUso/";
  if (id > 0) {
    metodo = "put";
    url = `${url}${id}`;
  }
  return api()[metodo](url, dados);
};
