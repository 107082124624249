import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

// Componentes
import TreeViewArvoreGerencial from "../../../../../../componentes/treeViewArvoreGerencial";

// Redux
import { store } from "../../../../../../global/redux";
import { alertaExibir } from "../../../../../../global/redux/modulos/alertas/actions";

// Serviços
import { listarArvoresGerenciaisEmpresas } from "../../../../../../servicos/arvoresGerenciaisServico";

const ArvoresGerenciasEmpresas = ({
  empresasSelecionadas,
  selecionados,
  onSelecionar,
  onBuscar
}) => {
  const [arvoresGerenciaisEmpresas, setArvoresGerenciasEmpresas] = useState([]);

  const obterArvoreGerencialEmpresa = async () => {
    try {
      const lista = await listarArvoresGerenciaisEmpresas();
      if (lista?.status === 200 && lista?.data?.empresaGrupoDeltaColecoes) {
        setArvoresGerenciasEmpresas(
          lista?.data?.empresaGrupoDeltaColecoes ?? []
        );
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  useEffect(() => {
    obterArvoreGerencialEmpresa();
  }, []);

  const onClickSelecionar = (node, idEmpresa) => {
    onSelecionar(
      node,
      arvoresGerenciaisEmpresas.find((empresa) => empresa.id === idEmpresa)
    );
  };

  const filtrarDados = useCallback(() => {
    return arvoresGerenciaisEmpresas.filter((x) => {
      if (onBuscar) {
        let filtro = x.nome.includes(onBuscar);
        if (filtro) return true;
        filtro = x.funcionalidadeColecao.filter((y) =>
          y.nome.includes(onBuscar)
        ).length;
        if (filtro) return true;
        return false;
      }
      return true;
    });
  }, [arvoresGerenciaisEmpresas, onBuscar]);

  const nodesExpanded = useMemo(() => {
    if (onBuscar?.length) {
      const itens = [];
      filtrarDados().forEach((x) => {
        if (!itens.includes(x.id)) {
          itens.push(String(x.id));
        }
        if (x?.funcionalidadeColecao?.length) {
          x.funcionalidadeColecao.forEach((y) => {
            if (!itens.includes(y.id)) {
              itens.push(String(y.id));
            }
          });
        }
      });
      return itens;
    }
    return [];
  }, [onBuscar.length, filtrarDados]);

  return (
    <TreeViewArvoreGerencial
      dataSource={filtrarDados()}
      selectable
      selecionados={selecionados}
      empresasSelecionadas={empresasSelecionadas}
      onSelecionar={(node, idEmpresa) => onClickSelecionar(node, idEmpresa)}
      nodesExpanded={onBuscar ? nodesExpanded : []}
    />
  );
};

ArvoresGerenciasEmpresas.propTypes = {
  empresasSelecionadas: PropTypes.oneOfType([PropTypes.array]),
  selecionados: PropTypes.oneOfType([PropTypes.array]),
  onSelecionar: PropTypes.oneOfType([PropTypes.func]),
  onBuscar: PropTypes.string
};

ArvoresGerenciasEmpresas.defaultProps = {
  empresasSelecionadas: [],
  selecionados: [],
  onSelecionar: () => {},
  onBuscar: ""
};

export default ArvoresGerenciasEmpresas;
