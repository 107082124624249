import FileSaver from "file-saver";
import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const apiSerasa = () => obterApi(MICROSERVICO.PROCESSAMENTO);
const apiManagement = () => obterApi(MICROSERVICO.MANAGEMENT);
const apiDeltaProcessamento = () => obterApi(MICROSERVICO.DELTAPROCESSAMENTO);

// Listar Registros
export const listarRegistros = async (params) => {
  return apiSerasa().get(
    `serasaexperian/consultas-realizadas?${new URLSearchParams(
      params
    ).toString()}`
  );
};

export const dadosProtocoloDemanda = async (parametro) => {
  return apiSerasa().get("serasaexperian/consulta-protocolo-demanda", {
    headers: {
      ProtocoloDemanda: parametro
    }
  });
};

export const statusProtocoloDemanda = async (protocoloDemanda, status) => {
  return apiManagement().post(
    `v1/DemandaAtend/aprova-rejeita/${protocoloDemanda}?status=${status}`
  );
};

export const downloadArquivo = async (protocoloDemanda) => {
  const { data } = await apiManagement().get(
    `v1/DemandaArquivos/download/por-tipo?protocoloDemanda=${protocoloDemanda}&tipoArquivo=4`,
    {
      responseType: "blob"
    }
  );
  const blob = new Blob([data]);
  FileSaver.saveAs(blob, `Balanço_DRE_${protocoloDemanda}.pdf`);
};

export const listarOrganizarPor = () => {
  const ordernacoes = [
    {
      key: "1",
      asc: "1",
      desc: "2",
      label: "Alfabética"
    },
    {
      key: "2",
      asc: "3",
      desc: "4",
      label: "Recentes"
    },
    {
      key: "3",
      asc: "5",
      desc: "6",
      label: "CNPJ"
    }
  ];

  return ordernacoes;
};

export const obterTabelaPrinad = async () => {
  return apiDeltaProcessamento().get("/serasaexperian/get-tabela-prinad");
};

export const obterProbilidadeInadimplencias = async () => {
  return apiDeltaProcessamento().get(
    "/serasaexperian/get-limite-probabilidade-inadimplencia"
  );
};

export const inserirProbalidadeInadimplencias = async (body) => {
  return apiDeltaProcessamento().post(
    "/serasaexperian/inserir-limite-probabilidade-inadimplencia",
    body
  );
};

export const atualizarStatusAprovado = async (params) => {
  return apiDeltaProcessamento().put(
    `/serasaexperian/liberacao-serasa?${new URLSearchParams(params).toString()}`
  );
};

export const uploadArquivo = async (params) => {
  const formData = new FormData();

  formData.append("documento", params.arquivo);
  formData.append("cnpj", params.cnpj);

  return apiDeltaProcessamento().post(
    "serasaexperian/upload-documento",
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" }
    }
  );
};

export const downloadSerasa = async (cnpj) => {
  return apiDeltaProcessamento().get(
    `serasaexperian/download-documento?cnpj=${cnpj}`
  );
};

export const obterSerasaReduzido = async (params) => {
  const filtroQueryString = new URLSearchParams(params).toString();
  return apiSerasa().get(
    `serasaexperian/consultar-cnpj-reduzida?${filtroQueryString}`
  );
};
