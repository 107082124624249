/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from "react";
import { Grid, Card } from "@mui/material";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { useStyles, Tabela } from "./style";
import { getESSMinimo } from "../../../servicos/essMinimo";
import CloseIcon from "@mui/icons-material/Close";
import RotasDTO from "../../../rotas/rotasUrlDto";
import { useHistory } from "react-router-dom";


const ESSMinimoVisualizar = () => {
  const classes = useStyles();
  const history = useHistory();

  const onFechar = (id) => {
    history.push(`${RotasDTO.ProjecaoInsumoListar}`);
  }
  const [valores, setValores] = useState([]);
  const [error, setError] = useState(false);

  const obterValores = async () => {
    try {
      const response = await getESSMinimo();
      if (response.status === 200 && response.data) {
        setValores(response?.data);
      } else {
        setValores([]);
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "Não há dados para exibição."
          })
        );
      }
    } catch (exception) {
      setError(true);
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            exception?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  useEffect(() => {
    if (!error && !valores.length) obterValores();
  }, [!error && !valores.length, obterValores]);

  return (
    <Grid container spacing={4} className={classes.container}>
      <Grid container className={classes.containerTitulo}>
        <Grid item xs={11} className={classes.titulo}>
          ESS Mínimo
        </Grid>
        <Grid item xs={1}>
          <CloseIcon onClick={onFechar} className={classes.icon} />
        </Grid>
      </Grid>
      <Grid item sm={12}>
        <Card className={classes.container}>
          <Grid container spacing={4} className={classes.container}>
            <Grid item sm={12}>
              <div className={classes.customPosition}>
                <Tabela>
                  <Tabela.Thead>
                    <Tabela.Tr>
                      <Tabela.Th colSpan="5">ESS MÍNIMO</Tabela.Th>
                    </Tabela.Tr>
                    <Tabela.Tr>
                      <Tabela.Th>ANO</Tabela.Th>
                      <Tabela.Th>CUSTO</Tabela.Th>
                    </Tabela.Tr>
                  </Tabela.Thead>
                  <Tabela.Tbody>
                    {valores?.map((item) => (
                      <Tabela.Tr>
                        <Tabela.Td>{item.ano}</Tabela.Td>
                        <Tabela.Td>{`R$ ${item.valor?.toLocaleString("pt-BR", { currency: "BRL", minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</Tabela.Td>
                      </Tabela.Tr>
                    ))}
                  </Tabela.Tbody>
                </Tabela>
              </div>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  )
}

export default ESSMinimoVisualizar;
