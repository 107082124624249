import RetornoEndpointDto from "../../global/dto/retornoEndpointDto";
import { store } from "../../global/redux";
import { alertaExibir } from "../../global/redux/modulos/alertas/actions";
import { obterUsuariosClientes } from "../../servicos/loginServico";

export default class AcessoHelper {
  static async obterUsuariosClientes(id, setCarregandoLogin) {
    try {
      setCarregandoLogin(true);
      const resultado = await obterUsuariosClientes(id);
      if (resultado.status === 204)
        return RetornoEndpointDto.Sucesso("204", []);

      return RetornoEndpointDto.Sucesso(
        "Usuários obtidos com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        error?.response?.data?.message ?? "Erro ao buscar usuários!"
      );
    } finally {
      setCarregandoLogin(false);
    }
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }

  static exibirSucesso(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem
      })
    );
  }
}
