import { Box, Grid } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { ReactComponent as Upload } from "../../../../../assets/cotacoesGd/upload.svg";
import UploadDropAreaMultiploUnico from "../uploadMultiplo";
import { useStyles } from "./style";

function UploadCard({
  titulo,
  tituloUpload,
  onAddArquivoUpload,
  onDeleteArquivoUpload,
  onClickDownload,
  tipo,
  limite,
  renderizaComponente,
  somenteLeitura,
  showLoader,
  disabledCard,
  handleMensagemModal,
  initialFiles,
  iconeDesabled
}) {
  const classes = useStyles();
  const [carregandoUpload, setCarregandoUpload] = useState(0);

  const controlarPorcentagem = () => {
    setCarregandoUpload(0);
    let porcentagem = 0;

    const intervalo = setInterval(() => {
      if (porcentagem >= 100) {
        clearInterval(intervalo);
        return;
      }

      porcentagem += 25;
      setCarregandoUpload(porcentagem);
    }, 150);
  };

  const onAdd = (listaArquivo) => {
    if (!listaArquivo || listaArquivo.length === 0) return;
    controlarPorcentagem();
    onAddArquivoUpload(listaArquivo, tipo);
  };

  const onDelete = (listaArquivo) => {
    onDeleteArquivoUpload(listaArquivo, tipo);
  };

  return (
    <Grid container className={classes.cardUpload}>
      <Box
        item
        component={Grid}
        xs={12}
        fontSize="18px"
        fontWeight="400"
        color="#332053"
      >
        {titulo}
      </Box>
      <Box item component={Grid} xs={12}>
        <UploadDropAreaMultiploUnico
          imageOrPdfValidate
          onAdd={(arquivo) => onAdd(arquivo, 1)}
          onDropRejected={(arquivo) => onDelete(arquivo, 1)}
          progressValue={carregandoUpload}
          acceptedFiles={["image/*", "application/pdf"]}
          dropAreaText={tituloUpload}
          filesLimit={limite ?? 1}
          disabled={somenteLeitura}
          renderizaComponente={renderizaComponente}
          onPreviewChipClick={onClickDownload}
          showLoader={showLoader}
          showPreviews
          disabledCard={disabledCard}
          handleMensagemModal={(mensagem) => handleMensagemModal(mensagem)}
          icon={Upload}
          initialFiles={initialFiles}
          iconeDesabled={iconeDesabled}
        />
      </Box>
    </Grid>
  );
}

UploadCard.propTypes = {
  titulo: PropTypes.string,
  tituloUpload: PropTypes.string,
  onAddArquivoUpload: PropTypes.func,
  onDeleteArquivoUpload: PropTypes.func,
  onClickDownload: PropTypes.func,
  showLoader: PropTypes.bool,
  disabledCard: PropTypes.bool,
  handleMensagemModal: PropTypes.func,
  tipo: PropTypes.number,
  limite: PropTypes.number,
  renderizaComponente: PropTypes.bool,
  somenteLeitura: PropTypes.bool,
  initialFiles: PropTypes.oneOfType([PropTypes.array]),
  iconeDesabled: PropTypes.bool
};

UploadCard.defaultProps = {
  titulo: "",
  tituloUpload: "",
  onAddArquivoUpload: () => {},
  onDeleteArquivoUpload: () => {},
  onClickDownload: () => {},
  showLoader: false,
  disabledCard: false,
  handleMensagemModal: () => {},
  tipo: 0,
  limite: 1,
  renderizaComponente: false,
  somenteLeitura: false,
  initialFiles: [],
  iconeDesabled: false
};

export default UploadCard;
