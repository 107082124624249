import { makeStyles } from '@mui/styles';
import styled from "styled-components";
import { Base } from "../../../../../componentes/cores";
import { createTheme } from "@mui/material/styles";

const theme = createTheme();

export const Tabela = styled.table`
  margin-top: 20px;
  width: 100%;
`;

Tabela.Thead = styled.thead`
  background: #DCDCDC;
`;

Tabela.Tr = styled.tr``;

Tabela.Th = styled.th`
  padding: 4px;
  border: 4px solid white;
  text-align: center;
`;

Tabela.Tbody = styled.tbody`
  background: #F0F0F0;
`;

Tabela.Td = styled.td`
  padding: 2px;
  border: 2px solid white;
  text-align: center;
  min-width: 180px;
`;

Tabela.Tfoot = styled.tfoot``;

export const TabelaScroll = styled.div`
    display: grid;
    border: 1px solid #DCDCDC;
    padding: 10px;
    max-width: 100%;

    div {
        display: flex;
        overflow: auto;
    }

    #scroll {
        padding-bottom: 10px;

        ::-webkit-scrollbar {
        height: 6px;
        background-color: #F5F5F5;
        }

        ::-webkit-scrollbar-track {
        background-color: #F5F5F5;
        border-radius: 10px;
        }

        ::-webkit-scrollbar-thumb {
        background-color: #DCDCDC;
        border-radius: 10px;
        }
    }
`

export const useStyles = makeStyles(() => ({
  container: {
    margin: 0,
    width: '100%',
    "& .MuiGrid-root": {
      color: `${Base.BlackBastille} !important`
    },
    "& .MuiDropzoneArea-text": {
      color: `${Base.BlackBastille} !important`
    },
    "& .MuiInputBase-root": {
      height: "46px"
    },
    "& .MuiFormLabel-root": {
      lineHeight: "1",
      color: `${Base.White} !important`,
    },
    "& fieldset": {
      borderColor: Base.White
    },
    "& svg": {
      color: Base.White
    },
    "& .MuiInput-underline:before": {
      borderBottom: "1px solid rgb(255 255 255 / 42%);"
    },
    "& .MuiSelect-select": {
      color: Base.White
    },
  },
  button: {
    fontSize: '18px',
  },
  customButton: {
    fontSize: '18px',
    backgroundColor: "transparent !important"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100% !important",
  },
  fileUpload: {
    "& .MuiDropzoneArea-root": {
      minHeight: "150px",
      borderWidth: "2px"
    },
    "& .MuiDropzoneArea-text": {
      fontSize: "16px"
    }
  },
  selectOfficer: {
    filter: "invert(1)",
    marginBottom: "0px"

  },
  customSelect: {
    "& .MuiFormControl-root": {
      borderRadius: "4px",
      color: "gray",
      border: "1px solid gray"
    },

    "& .MuiOutlinedInput-inputAdornedEnd": {
      color: "gray",
    }
  },
  customTabs: {
    "& .MuiTab-textColorPrimary.Mui-selected": {
      color: "gray"
    }
  },
  noBorder: {
    border: "0 !important",
  },
}));
