export default class ListagemFaturasGDDto {
  constructor(
    id,
    mesReferencia,
    dataVencimentoLUZ,
    status,
    dataPagamento,
    valorTotalFaturaLUZ,
    idArquivoFaturaLUZ,
    anoReferencia
  ) {
    this.id = id;
    this.mesReferencia = mesReferencia;
    this.dataVencimentoLUZ = dataVencimentoLUZ;
    this.status = status;
    this.dataPagamento = dataPagamento;
    this.valorTotalFaturaLUZ = valorTotalFaturaLUZ;
    this.idArquivoFaturaLUZ = idArquivoFaturaLUZ;
    this.anoReferencia = anoReferencia;
  }
}
