import styled from "styled-components";
import { Badge } from "@mui/material";
import { makeStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import theme from "themes";
import { createTheme } from "@mui/material/styles";
import { Base } from "../../../cores";

const themes = createTheme();

export const useStyles = makeStyles(() => ({
  wrapper: {
    alignItems: "center",
    display: "flex",
    height: themes.spacing(6.5),
    position: "relative",
    width: themes.spacing(6.5)
  },
  icon: {
    cursor: "pointer"
  },
  anchorMenu: {
    color: `${Base.OffWhite} !important`,
    fontSize: "26px",
    position: "absolute",
    left: "5px",
    top: "57px",
    transform: "rotate(-90deg)",
    zIndex: "999"
  },
  menu: {
    backgroundColor: Base.OffWhite,
    paddingTop: 0,
    paddingBottom: 0,
    width: "277px",
    height: "500px"
  },
  notificacaoIcon: {
    fontSize: "12px",
    color: "pink"
  },
  notificacaoMenu: {
    fontSize: "15px",
    whiteSpace: "inherit",
    wordWrap: "break-word",
    fontWeight: "bold",
    width: "277px"
  },
  tituloCardStyle: {
    color: theme.color.primary,
    fontSize: "15px"
  },
  notificacaoItem: {
    backgroundColor: Base.WhiteGhost,
    borderBottom: `1px solid ${Base.WhiteSmoke}`,
    color: theme.color.primaryBackgroud,
    fontSize: "10px",
    whiteSpace: "inherit",
    wordWrap: "break-word",
    height: "70px"
  },
  notificacaoSino: {
    color: theme.color.notificacaoSino
  },
  menuFooter: {
    backgroundColor: Base.OffWhite,
    color: theme.color.footerColor,
    fontFamily: theme.font.family,
    fontSize: "12px",
    justifyContent: "center",
    lineHeight: "10px",
    minHeight: "43px",
    textAlign: "center"
  },
  footerLink: {
    color: theme.color.notificacaoLink,
    display: "block",
    "&:hover": {
      color: theme.color.notificacaoLink,
      textDecoration: "none"
    },
    "& svg": {
      fontSize: "14px"
    }
  }
}));

export const Wrapper = styled.div``;

export const BadgeNotificacoes = withStyles(() => ({
  badge: {
    backgroundColor: theme.color.qtdNotificacao,
    borderRadius: "50rem !important",
    color: `${Base.OffWhite} !important`,
    cursor: "pointer !important",
    fontSize: "10px !important",
    height: "16px !important",
    margin: "7px 2px 3px 10px !important",
    minWidth: "16px !important",
    width: "16px !important",
    display: (props) => (props.invisible ? "none !important" : "flex")
  }
}))(Badge);

export const Quantidade = styled.span`
  font-weight: bold;
  padding: 0 2px;
`;

export const Mensagens = styled.div``;
