export default class FiltroAdicionalTabela {
  constructor(
    ordenacao,
    textoBusca,
    documentos,
    trocaDeTitularidade,
    medidor,
    finalizado,
    distribuidoraId,
    clienteComSenha,
    cancelado,
    rejeitado
  ) {
    this.ordenacao = ordenacao;
    this.textoBusca = textoBusca;
    this.documentos = documentos;
    this.trocaDeTitularidade = trocaDeTitularidade;
    this.medidor = medidor;
    this.finalizado = finalizado;
    this.distribuidoraId = distribuidoraId;
    this.clienteComSenha = clienteComSenha;
    this.cancelado = cancelado;
    this.rejeitado = rejeitado;
  }
}
