import React, { useEffect, useState, useCallback } from "react";
import { Grid } from "@mui/material";
import { useStyles, conditionalRowStyles, customStyles } from "./style";
import { useTheme } from "@mui/material/styles";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "componentes/tabPanel";
import SwipeableViews from "react-swipeable-views";
import {
  obterUsinas,
  obterCotasPorIdDistribuidora
} from "submodules/DeltaTarifa/servicos/baseGarantiaFisica";
import { listarDistribuidoras } from "../../../servicos/parametrizacaoDistribuidoras";
import Loader from "componentes/loader";
import SelectArredondado from "componentes/selectArredondado";
import InputDataBranco from "componentes/inputTextoDataBranco";
import moment from "moment";
import Coluna from "componentes/tabelaPaginada/colunas/coluna";
import DataTable from "react-data-table-component";
import CloseIcon from "@mui/icons-material/Close";
import RotasDTO from "../../../rotas/rotasUrlDto";
import { useHistory } from "react-router-dom";

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`
  };
}

const BaseGarantiaFisica = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const onFechar = (id) => {
    history.push(`${RotasDTO.ProjecaoInsumoListar}`);
  };

  const [usinas, setUsinas] = useState([]);
  const [cotas, setCotas] = useState([]);
  const [idDistribuidora, setIdDistribuidora] = useState(null);
  const [distribuidoras, setDistribuidoras] = useState([]);
  const [carregandoDistribuidoras, setCarregandoDistribuidoras] = useState(
    false
  );
  const [value, setValue] = React.useState(0);
  const [anoGarantia, setAnoGarantia] = useState(null);

  const obterDistribuidoras = useCallback(async () => {
    try {
      setCarregandoDistribuidoras(true);
      const lista = await listarDistribuidoras(true);
      if (lista.status === 200 && lista.data) {
        setCarregandoDistribuidoras(false);
        console.dir(lista?.data);
        setDistribuidoras(lista?.data);
      }
    } catch (error) {
      setCarregandoDistribuidoras(false);
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  }, []);

  const obterListaUsinas = useCallback(async (ano) => {
    try {
      let filtroAno = moment(ano).format("YYYY");
      if (filtroAno.length == 4) {
        const response = await obterUsinas(filtroAno);
        if (response?.status === 200 && response?.data) {
          if (response?.data.length > 0) {
            const organizado = [];
            response.data.map((item) =>
              organizado.push({
                ...item,
                DescPotencia:
                  item.Potencia != null ? item.Potencia.toFixed(4) : "",
                DescGFIntegral:
                  item.GFIntegral != null ? item.GFIntegral.toFixed(4) : "",
                DescGFReduzida:
                  item.GFReduzida != null ? item.GFReduzida.toFixed(4) : "",
                DescAlocacao:
                  item.Alocacao != null ? item.Alocacao.toFixed(4) : ""
              })
            );
            setUsinas(organizado);
          } else {
            setUsinas([]);
            store.dispatch(
              alertaExibir({
                tipo: "warning",
                mensagem: `Nenhum registro de Usina encontrado para o ano ${filtroAno}`
              })
            );
          }
        }
      }
    } catch (error) {
      setUsinas([]);
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Ocorreu um erro. " + error
        })
      );
    }
  }, []);

  useEffect(() => {
    if (anoGarantia) obterListaUsinas(anoGarantia);
  }, [anoGarantia, obterListaUsinas]);

  useEffect(() => {
    if (!distribuidoras.length) obterDistribuidoras();
  }, [!distribuidoras.length, obterDistribuidoras]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const obterCotas = useCallback(
    async (ano, idDist, listaUsinas, listaDistribuidoras) => {
      try {
        if (idDist > 0 && ano != null) {
          let filtroAno = moment(ano).format("YYYY");
          const response = await obterCotasPorIdDistribuidora(
            filtroAno,
            idDist
          );
          if (response?.status === 200 && response?.data) {
            if (response?.data.length > 0) {
              const organizado = [];
              response.data.map((item) =>
                organizado.push({
                  ...item,
                  descDistribuidora:
                    listaDistribuidoras != null
                      ? listaDistribuidoras.filter((d) => d.id == idDist)[0]
                          ?.titulo
                      : "",
                  descUsina:
                    listaUsinas != null
                      ? listaUsinas.filter(
                          (u) => u.CodigoUHE == item.CodigoUHE
                        )[0]?.Usinas
                      : "",
                  descCota: item.Cota != null ? item.Cota.toFixed(4) : ""
                })
              );
              setCotas(organizado);
            } else {
              setCotas([]);
              store.dispatch(
                alertaExibir({
                  tipo: "warning",
                  mensagem:
                    "Nenhum registro de Cotas encontrado para os parâmetros informados"
                })
              );
            }
          } else {
            setCotas([]);
            store.dispatch(
              alertaExibir({
                tipo: "warning",
                mensagem: `Erro ao obter dados das Cotas ${response?.status}`
              })
            );
          }
        } else {
          setCotas([]);
          store.dispatch(
            alertaExibir({
              tipo: "warning",
              mensagem:
                "Não foi possível obter dados das Cotas. Verifique o Ano e Distribuidora"
            })
          );
        }
      } catch (error) {
        setCotas([]);
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "Ocorreu um erro. " + error
          })
        );
      }
    },
    []
  );

  const colunasUsinas = [
    new Coluna("CodigoUHE", "Código", true),
    new Coluna("Usinas", "Usina", true),
    new Coluna("Concessionaria", "Concessionária", true),
    new Coluna("DescPotencia", "Potência", false, "10px"),
    new Coluna("DescGFIntegral", "GF Integral", false, "10px"),
    new Coluna("DescGFReduzida", "GF Reduzida", false, "10px"),
    new Coluna("DescAlocacao", "Alocação", false, "10px")
  ];
  const colunasCotas = [
    new Coluna("descUsina", "Usina", true),
    // new Coluna("descDistribuidora", "Distribuidora",true),
    new Coluna("descCota", "Cota", true)
  ];

  const onChangeAnoGarantia = (data) => {
    setAnoGarantia(data);
  };

  const onChangeDistribuidora = (event) => {
    setIdDistribuidora(event.target.value);
    obterCotas(anoGarantia, event.target.value, usinas, distribuidoras);
  };

  return (
    <>
      <Grid container className={classes.containerTitulo}>
        <Grid item xs={11} className={classes.titulo}>
          Base Garantia Física
        </Grid>
        <Grid item xs={1}>
          <CloseIcon onClick={onFechar} className={classes.icon} />
        </Grid>
      </Grid>
      <Grid
        item
        sm={2}
        className={classes.customSelect}
      >
        <InputDataBranco
          type="text"
          id="anoGarantia"
          name="anoGarantia"
          customValue={anoGarantia}
          onChange={(data) => onChangeAnoGarantia(data)}
          maxDate={moment("2090-12-31").toDate()}
          minDate={moment("1980-01-01").toDate()}
          format="yyyy"
          views={["year"]}
          renderIconShowHide={false}
        />
      </Grid>
      {usinas?.length > 0 ? (
        <Grid className={classes.panelGarantiaFisica}>
          <div className={classes.root}>
            <AppBar position="static" color="default">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab label="USINAS" {...a11yProps(0)} />
                <Tab label="COTAS" {...a11yProps(1)} />
              </Tabs>
            </AppBar>
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0} dir={theme.direction}>
                <Grid item xs={12}>
                  <DataTable
                    title={`Usinas - Garantia Física`}
                    columns={colunasUsinas}
                    data={usinas}
                    theme="DeltaEnergia"
                    customStyles={customStyles}
                    conditionalRowStyles={conditionalRowStyles}
                    noDataComponent="Nenhum registro encontrado"
                  />
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                <Grid container spacing={2} className={classes.container}>
                  <Grid item sm={3}>
                    <Loader loading={carregandoDistribuidoras}>
                      <SelectArredondado
                        id="distribuidoraId"
                        name="distribuidoraId"
                        valueKey="idDistribuidora"
                        valueName="titulo"
                        value={idDistribuidora}
                        dataSource={distribuidoras}
                        label="Distribuidora"
                        onChange={(event) => onChangeDistribuidora(event)}
                        placeholder="Distribuidora"
                        allowClear
                        className={classes.selectOfficer}
                        labelClassName={classes.selectOfficer}
                      />
                    </Loader>
                  </Grid>
                  <Grid item sm={9} />
                  <Grid item xs={12}>
                    {cotas?.length > 0 ? (
                      <DataTable
                        columns={colunasCotas}
                        data={cotas}
                        theme="DeltaEnergia"
                        customStyles={customStyles}
                        conditionalRowStyles={conditionalRowStyles}
                        noDataComponent="Nenhum registro encontrado"
                        noHeader
                        
                      />
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </TabPanel>
            </SwipeableViews>
          </div>
        </Grid>
      ) : (
        ""
      )}
    </>
  );
};

export default BaseGarantiaFisica;
