export function salvarUsuario(login) {
  return {
    type: "@usuario/salvarUsuario",
    payload: login
  };
}

export function salvarContasUsuario(contas) {
  return {
    type: "@usuario/salvarContasUsuario",
    payload: contas
  };
}

export function selecionarConta(conta) {
  return {
    type: "@usuario/selecionarConta",
    payload: conta
  };
}

export function salvarEmpresas(empresas) {
  return {
    type: "@usuario/salvarEmpresas",
    payload: empresas
  };
}

export function selecionarEmpresa(empresa) {
  return {
    type: "@usuario/selecionarEmpresa",
    payload: empresa
  };
}

export function salvarClientes(clientes) {
  return {
    type: "@usuario/salvarClientes",
    payload: clientes
  };
}

export function selecionarCliente(cliente) {
  return {
    type: "@usuario/selecionarCliente",
    payload: cliente
  };
}

export function salvarUnidades(unidades) {
  return {
    type: "@usuario/salvarUnidades",
    payload: unidades
  };
}

export function selecionarUnidade(unidade) {
  return {
    type: "@usuario/selecionarUnidade",
    payload: unidade
  };
}

export function deslogarUsuario() {
  return {
    type: "@usuario/deslogarUsuario"
  };
}

export function salvarMenu(menu) {
  return {
    type: "@usuario/salvarMenu",
    payload: menu
  };
}

export function desabilitarEmpresa(status) {
  return {
    type: "@usuario/desabilitarEmpresa",
    payload: status
  };
}

export function desabilitarCliente(status) {
  return {
    type: "@usuario/desabilitarCliente",
    payload: status
  };
}

export function desabilitarUnidade(status) {
  return {
    type: "@usuario/desabilitarUnidade",
    payload: status
  };
}

export function desabilitarFiltroLateral(status) {
  return {
    type: "@usuario/desabilitarFiltroLateral",
    payload: status
  };
}

export function salvarNomeArquivoMedicao(nome) {
  return {
    type: "@mediaco/nomeArquivoMedicao",
    payload: nome
  };
}

export function salvarNomeArquivoProinfa(nome) {
  return {
    type: "@proinfa/nomeArquivoProinfa",
    payload: nome
  };
}

export function salvarEmpresaDefault(empresaId) {
  return {
    type: "@usuario/salvarEmpresaDefault",
    payload: empresaId
  };
}

export function salvarClienteDefault(clienteId) {
  return {
    type: "@usuario/salvarClienteDefault",
    payload: clienteId
  };
}

export function salvarUnidadeDefault(unidadeId) {
  return {
    type: "@usuario/salvarUnidadeDefault",
    payload: unidadeId
  };
}

