import React from "react";
import { useHistory } from "react-router-dom";

// Componentes
import { Card, Grid } from "@mui/material";
import Botao from "componentes/botao";

// Styles
import { store } from "global/redux";
import {
  mostrarHistorico,
  salvarDadosEnergia,
  salvarHistorico
} from "global/redux/modulos/demo/actions";
import { Conteudo, useStyles } from "./style";

// DTO
import { RotasDTO } from "../../../../global/rotas/rotasUrlDto";

// Redux

const Sucesso = () => {
  const classes = useStyles();
  const history = useHistory();

  const onClickFinalizar = () => {
    store.dispatch(salvarDadosEnergia({}));
    store.dispatch(mostrarHistorico(false));
    store.dispatch(salvarHistorico([]));
    history.push(RotasDTO.Demo);
  };

  return (
    <Card className={`${classes.container} ${classes.card}`}>
      <Grid
        container
        spacing={5}
        justifyContent="space-around"
        className={classes.container}
      >
        <Grid
          item
          lg={12}
          md={12}
          className={`font-weight-bold ${classes.titulo}`}
        >
          Contrato assinado com sucesso
        </Grid>
      </Grid>
      <Grid container spacing={5} className={classes.container}>
        <Grid item lg={12} md={12}>
          <Conteudo className="mt-3">
            <p>
              Fique tranquilo, a Delta Energia dará prosseguimento na sua
              habilitação junto à CCEE.
            </p>
            <p>
              Uma cópia do contrato foi encaminhada para o e-mail cadastrado.
            </p>
          </Conteudo>
        </Grid>
      </Grid>
      <Grid container spacing={5} className={classes.containerAvancar}>
        <Grid item lg={2} md={3} sm={12} className="mt-5">
          <Botao
            type="submit"
            label="Finalizar"
            className={classes.button}
            onClick={() => onClickFinalizar()}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default Sucesso;
