import { Card } from "@mui/material";
import { Loader } from "componentes";
import AssistenteHorizontal from "componentes/assistente";
import { ProtocolosEtapas } from "global/constantes";
import moment from "moment";
import React, { useMemo, useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import UnidadeNaoSelecionada from "componentes/unidadeNaoSelecionada";
import AguardandoAssinaturaConfirmacaoContrato from "./Etapas/AguardandoAssinaturaConfirmacaoContrato";
import AguardandoAssinaturaContrato from "./Etapas/AguardandoAssinaturaContrato";
import Analise from "./Etapas/AnaliseDocumentos";
import Aprovado from "./Etapas/Aprovado";
import AssinarTermo from "./Etapas/AssinarTermo";
import Confirmacao from "./Etapas/Confirmacao";
import RepresentantesLegais from "./Etapas/RepresentantesLegais";
import Reprovado from "./Etapas/Reprovado";
import OnBoardingHelper from "./helper";
import Migracao from "./Etapas/Migracao";
import ValidacaoAssinaturaDocumentos from "./Etapas/ValidacaoAssinaturaDocumentos";
import UnidadeSemDemanda from "./Componentes/unidadeSemDemanda";

const OnBoarding = () => {
  const refAssistente = useRef(null);
  const [etapa, setEtapa] = useState();
  const [prazo, setPrazo] = useState();
  const [loading, setLoading] = useState(false);

  const [protocoloDemanda, setProtocoloDemanda] = useState();

  const usuario = useSelector((state) => state.usuario);
  const {
    empresaSelecionada,
    clienteSelecionado,
    unidadeSelecionada
  } = usuario;

  const steps = [
    "Representante legal",
    "Adesão",
    "Análise",
    "Confirmação",
    "Migração",
    "Fim"
  ];

  const passosId = [
    ProtocolosEtapas.IndicarRepresentanteLegal,
    ProtocolosEtapas.AssinarContratoProcuracao,
    ProtocolosEtapas.PrevioAnaliseRisco,
    ProtocolosEtapas.CreditoAprovadoAutomatico,
    ProtocolosEtapas.CreditoRejeitadoAutomatico,
    ProtocolosEtapas.ReprovadoDefault,
    ProtocolosEtapas.AnaliseCreditoManual,
    ProtocolosEtapas.CreditoAprovadoManual,
    ProtocolosEtapas.CreditoRejeitadoManual,
    ProtocolosEtapas.AssinaturaConfirmacaoContrato,
    ProtocolosEtapas.ValidacaoAssinaturaConfirmacaoContrato,
    ProtocolosEtapas.Migracao,
    ProtocolosEtapas.CartaDenuncia,
    ProtocolosEtapas.TermoPactuacao,
    ProtocolosEtapas.ModelagemCCEE,
    ProtocolosEtapas.ImportacaoFaturas,
    ProtocolosEtapas.ValidacaoImportacaoFaturas,
    ProtocolosEtapas.CadastroMedidor,
    ProtocolosEtapas.Fim,
    ProtocolosEtapas.Impossibilidade,
    ProtocolosEtapas.AguardandoAssinaturaContrato,
    ProtocolosEtapas.AguardandoAssinaturaConfirmacao
  ];

  const [indexPasso, setIndexPasso] = useState(-1);
  const [indexWizard, setIndexWizard] = useState(-1);
  const [semDemanda, setSemDamanda] = useState(false);
  const [etapaAtual, setEtapaAtual] = useState(null);

  const passoAtual = useMemo(() => {
    return passosId.find((x, i) => i === indexPasso);
  }, [indexPasso]);

  const obterPorFiltroLateral = async (empresa, cliente, unidade) => {
    setLoading(true);

    const resultado = await OnBoardingHelper.obterProtocoloDemanda(
      empresa,
      cliente,
      unidade,
      setSemDamanda
    );

    if (!resultado.sucesso) {
      OnBoardingHelper.exibirErro(resultado.mensagem);
      setLoading(false);
      return;
    }

    if (resultado.data === null || !resultado.data) {
      OnBoardingHelper.exibirAlerta(resultado.mensagem);
      setLoading(false);
      return;
    }

    setProtocoloDemanda(resultado.data);
    setLoading(false);
  };

  const obterIndex = (index) => {
    let indexPassoAtual = index;
    let indexWizardAtual;

    switch (true) {
      case typeof index === "undefined":
        indexPassoAtual = -1;
        indexWizardAtual = -1;
        break;
      case index <= 0:
        indexPassoAtual = 0;
        indexWizardAtual = 0;
        break;
      case index === 1:
        indexWizardAtual = 1;
        break;
      case index > 1 && index < 9:
        indexWizardAtual = 2;
        break;
      case index > 8 && index < 11:
        indexWizardAtual = 3;
        break;
      case index > 10 && index < 18:
        indexWizardAtual = 4;
        break;
      case index > 17 && index < 20:
        indexWizardAtual = 5;
        break;
      case index === 20:
        indexWizardAtual = 1;
        break;
      default:
        indexWizardAtual = 3;
        break;
    }

    return { indexPassoAtual, indexWizardAtual };
  };

  const definirIndexWizardPasso = (etapaASerAnalisada) => {
    let index = passosId.findIndex(
      (x) => String(x) === String(etapaASerAnalisada)
    );

    if (index < 0) index = etapaASerAnalisada;

    const { indexPassoAtual, indexWizardAtual } = obterIndex(index);

    setIndexPasso(indexPassoAtual);
    setIndexWizard(indexWizardAtual);
  };

  const obterEtapasCustomizadasASerAnalisada = () => {
    return etapa === String(ProtocolosEtapas.Impossibilidade)
      ? String(ProtocolosEtapas.Fim)
      : etapa;
  };

  useEffect(() => {
    if (!etapa) return;

    const etapaASerAnalisada = obterEtapasCustomizadasASerAnalisada();

    definirIndexWizardPasso(etapaASerAnalisada);
  }, [etapa]);

  const obterEtapaAtual = async () => {
    setLoading(true);

    const demanda = await OnBoardingHelper.ObterDadosDemanda(protocoloDemanda);

    if (!demanda.sucesso) {
      OnBoardingHelper.exibirErro(demanda.mensagem);
      setLoading(false);
      return;
    }

    if (!demanda.data.protocoloDemanda) {
      setLoading(false);
      return;
    }

    setEtapa(demanda.data.etapa);
    setEtapaAtual(demanda.data.etapa);
    setPrazo(moment(demanda.data.prazo).format("DD/MM/YYYY"));
    setLoading(false);
  };

  const proximaEtapa = async (etapaProxima, validarEtapa = false) => {
    if (validarEtapa) {
      setEtapa(etapaProxima);
      obterEtapaAtual();
      return;
    }

    definirIndexWizardPasso(etapaProxima);
  };

  const voltarEtapa = async (etapaAnterior) => {
    const etapaDesejada = indexPasso - 1;

    if (typeof etapaAnterior !== "undefined") {
      setEtapa(etapaAnterior);
      return;
    }

    definirIndexWizardPasso(etapaDesejada, false);
  };

  const analiseDocumentoEtapas = [
    String(ProtocolosEtapas.PrevioAnaliseRisco),
    String(ProtocolosEtapas.CreditoAprovadoAutomatico),
    String(ProtocolosEtapas.CreditoRejeitadoAutomatico),
    String(ProtocolosEtapas.ReprovadoDefault),
    String(ProtocolosEtapas.AnaliseCreditoManual),
    String(ProtocolosEtapas.CreditoAprovadoManual),
    String(ProtocolosEtapas.CreditoRejeitadoManual)
  ];

  const migracaoEtapas = [
    String(ProtocolosEtapas.CartaDenuncia),
    String(ProtocolosEtapas.TermoPactuacao),
    String(ProtocolosEtapas.ModelagemCCEE),
    String(ProtocolosEtapas.ImportacaoFaturas),
    String(ProtocolosEtapas.ValidacaoImportacaoFaturas),
    String(ProtocolosEtapas.CadastroMedidor),
    ProtocolosEtapas.Migracao
  ];

  const obterApenasLeitura = (passo) => {
    return passo !== String(etapaAtual);
  };

  const obterApenasLeituraAnaliseDocumentos = (passo) => {
    return (
      !analiseDocumentoEtapas.includes(passo) || passo !== String(etapaAtual)
    );
  };

  const obterApenasLeituraMigracao = (passo) => {
    return !migracaoEtapas.includes(passo) || passo !== String(etapaAtual);
  };

  const TelaPassoAtual = useMemo(() => {
    if (semDemanda) {
      return <UnidadeSemDemanda />;
    }
    switch (String(passoAtual)) {
      case String(ProtocolosEtapas.IndicarRepresentanteLegal):
        return (
          <Card>
            <RepresentantesLegais
              prazo={prazo}
              responsavel="Cliente"
              onProximaEtapa={proximaEtapa}
              onVoltarEtapa={voltarEtapa}
              somenteLeitura={obterApenasLeitura(String(passoAtual))}
              protocoloDemanda={protocoloDemanda}
              onObterEtapaAtual={obterEtapaAtual}
            />
          </Card>
        );
      case String(ProtocolosEtapas.AssinarContratoProcuracao):
        return (
          <Card>
            <AssinarTermo
              prazo={prazo}
              responsavel="Cliente"
              onProximaEtapa={proximaEtapa}
              onVoltarEtapa={voltarEtapa}
              somenteLeitura={obterApenasLeitura(String(passoAtual))}
              labelProximo={
                obterApenasLeitura(String(passoAtual)) ? "Próximo" : "Assinar"
              }
              protocoloDemanda={protocoloDemanda}
            />
          </Card>
        );
      case ProtocolosEtapas.AguardandoAssinaturaContrato:
        return (
          <Card>
            <AguardandoAssinaturaContrato
              prazo={prazo}
              responsavel="Cliente"
              onProximaEtapa={proximaEtapa}
              onVoltarEtapa={voltarEtapa}
              somenteLeitura={obterApenasLeitura(String(passoAtual))}
              // eslint-disable-next-line react/jsx-boolean-value
              esconderBotaoProximo={true}
              protocoloDemanda={protocoloDemanda}
            />
          </Card>
        );
      case String(ProtocolosEtapas.PrevioAnaliseRisco):
      case String(ProtocolosEtapas.CreditoAprovadoAutomatico):
      case String(ProtocolosEtapas.CreditoRejeitadoAutomatico):
      case String(ProtocolosEtapas.ReprovadoDefault):
      case String(ProtocolosEtapas.AnaliseCreditoManual):
      case String(ProtocolosEtapas.CreditoAprovadoManual):
      case String(ProtocolosEtapas.CreditoRejeitadoManual):
        return (
          <Card>
            <Analise
              prazo={prazo}
              responsavel="Luz"
              onProximaEtapa={proximaEtapa}
              onVoltarEtapa={voltarEtapa}
              somenteLeitura={obterApenasLeituraAnaliseDocumentos(
                String(passoAtual)
              )}
              esconderBotaoProximo={
                !obterApenasLeituraAnaliseDocumentos(String(passoAtual))
              }
              protocoloDemanda={protocoloDemanda}
            />
          </Card>
        );
      case String(ProtocolosEtapas.AssinaturaConfirmacaoContrato):
        return (
          <Card>
            <Confirmacao
              prazo={prazo}
              responsavel="Cliente"
              onProximaEtapa={proximaEtapa}
              onVoltarEtapa={voltarEtapa}
              somenteLeitura={obterApenasLeitura(String(passoAtual))}
              labelProximo={
                obterApenasLeitura(String(passoAtual)) ? "Próximo" : "Assinar"
              }
              protocoloDemanda={protocoloDemanda}
            />
          </Card>
        );
      case ProtocolosEtapas.AguardandoAssinaturaConfirmacao:
        return (
          <Card>
            <AguardandoAssinaturaConfirmacaoContrato
              prazo={prazo}
              responsavel="Cliente"
              onProximaEtapa={proximaEtapa}
              onVoltarEtapa={voltarEtapa}
              somenteLeitura={obterApenasLeitura(String(passoAtual))}
              // eslint-disable-next-line react/jsx-boolean-value
              esconderBotaoProximo={true}
              protocoloDemanda={protocoloDemanda}
            />
          </Card>
        );
      case String(ProtocolosEtapas.ValidacaoAssinaturaConfirmacaoContrato):
        return (
          <Card>
            <ValidacaoAssinaturaDocumentos
              prazo={prazo}
              responsavel="Luz"
              onProximaEtapa={proximaEtapa}
              onVoltarEtapa={voltarEtapa}
              somenteLeitura={obterApenasLeitura(String(passoAtual))}
              esconderBotaoProximo={!obterApenasLeitura(String(passoAtual))}
              protocoloDemanda={protocoloDemanda}
            />
          </Card>
        );
      case String(ProtocolosEtapas.CartaDenuncia):
      case String(ProtocolosEtapas.TermoPactuacao):
      case String(ProtocolosEtapas.ModelagemCCEE):
      case String(ProtocolosEtapas.ImportacaoFaturas):
      case String(ProtocolosEtapas.ValidacaoImportacaoFaturas):
      case String(ProtocolosEtapas.CadastroMedidor):
      case ProtocolosEtapas.Migracao:
        return (
          <Card>
            <Migracao
              prazo={prazo}
              responsavel="Luz"
              onProximaEtapa={proximaEtapa}
              onVoltarEtapa={voltarEtapa}
              somenteLeitura={obterApenasLeituraMigracao(String(passoAtual))}
              esconderBotaoProximo={
                !obterApenasLeituraMigracao(String(passoAtual))
              }
              protocoloDemanda={protocoloDemanda}
              etapa={etapa}
            />
          </Card>
        );
      case String(ProtocolosEtapas.Fim):
        return (
          <Card>
            <Aprovado
              prazo="____"
              responsavel="_____"
              onProximaEtapa={proximaEtapa}
              onVoltarEtapa={voltarEtapa}
              somenteLeitura={obterApenasLeitura(String(passoAtual))}
              esconderBotaoProximo
              protocoloDemanda={protocoloDemanda}
            />
          </Card>
        );
      case String(ProtocolosEtapas.Impossibilidade):
        return (
          <Card>
            <Reprovado
              prazo="____"
              responsavel="____"
              onProximaEtapa={proximaEtapa}
              onVoltarEtapa={voltarEtapa}
              somenteLeitura={obterApenasLeitura(String(passoAtual))}
              esconderBotaoProximo
              protocoloDemanda={protocoloDemanda}
            />
          </Card>
        );
      default:
        return <UnidadeNaoSelecionada />;
    }
  }, [passoAtual, etapa, prazo, semDemanda]);

  useEffect(() => {
    if (!empresaSelecionada || !clienteSelecionado || !unidadeSelecionada)
      return;

    obterPorFiltroLateral(
      empresaSelecionada,
      clienteSelecionado,
      unidadeSelecionada
    );
  }, [empresaSelecionada, clienteSelecionado, unidadeSelecionada]);

  useEffect(() => {
    if (!protocoloDemanda) return;

    obterEtapaAtual();
  }, [protocoloDemanda]);

  return (
    <>
      <AssistenteHorizontal
        steps={steps}
        ref={refAssistente}
        onChangeStep={(passo) => setIndexWizard(passo)}
        passoInicialFluxo={indexWizard}
      />
      <Loader loading={loading}>{TelaPassoAtual}</Loader>
    </>
  );
};

export default OnBoarding;
