import React from "react";
import ReactApexChart from "react-apexcharts";
import PropTypes from "prop-types";

// Componentes
import { Box, Grid } from "@mui/material";
import { options } from "./graficoConfig";
import { useStyles } from "./style";
import { formataPercentual } from "../../../../servicos/utils";

const GraficoMercado = ({ dadosPlano }) => {
  const classes = useStyles();

  const formataValor = (valor, casasDecimais = 0) => {
    return `${valor?.toLocaleString("pt-br", {
      currency: "BRL",
      maximumFractionDigits: casasDecimais
    })}`;
  };

  const gerarDados = () => {
    const dadosCativo = dadosPlano?.dadosEconomiaAnual?.map((dado) => {
      if (dado?.somaCustoTotalCativo) {
        const dadoFormatado = String(dado?.somaCustoTotalCativo / 1000).split(
          "."
        );

        return Number(dadoFormatado[0]);
      }
      return 0;
    });

    const dadosMercado = dadosPlano?.dadosEconomiaAnual?.map((dado) => {
      if (dado?.somaCustoTotalCativo) {
        const dadoFormatado = String(dado?.somaCustoTotalLivre / 1000).split(
          "."
        );

        return Number(dadoFormatado[0]);
      }
      return 0;
    });

    return [
      {
        name: "Custo Mercado Cativo (R$)",
        data: dadosCativo || []
      },
      {
        name: "Custo Mercado Livre (R$)",
        data: dadosMercado || []
      }
    ];
  };

  const gerarDadosTabela = () => {
    const dadosCativo = dadosPlano?.dadosEconomiaAnual?.map((dado) => {
      if (dado?.somaCustoTotalCativo) {
        const dadoFormatado = dado?.somaCustoTotalCativo / 1000;

        return dadoFormatado?.toFixed(3) || 0;
      }
      return 0;
    });

    const dadosMercado = dadosPlano?.dadosEconomiaAnual?.map((dado) => {
      if (dado?.somaCustoTotalCativo) {
        const dadoFormatado = dado?.somaCustoTotalLivre / 1000;

        return dadoFormatado?.toFixed(3) || 0;
      }
      return 0;
    });

    const dadosSoma = dadosPlano?.dadosEconomiaAnual?.map((dado) => {
      if (dado?.economiaAnual_reais) {
        const dadoFormatado = dado?.economiaAnual_reais / 1000;

        return dadoFormatado?.toFixed(3) || 0;
      }
      return 0;
    });

    return [
      {
        name: "Projeção Custo Mercado Cativo (R$)",
        data: dadosCativo || []
      },
      {
        name: "Projeção Custo Mercado Livre (R$)",
        data: dadosMercado || []
      },
      {
        name: "Economia Anual Estimada",
        data: dadosSoma || []
      }
    ];
  };

  const gerarCategorias = () => {
    const anos = dadosPlano?.dadosEconomiaAnual?.map(
      (dado) => dado?.anoTimeline
    );

    return anos || [];
  };

  return (
    <Grid container className={classes.container} spacing={3} p={2}>
      <Grid item xs={12} sm={4}>
        <Box className={classes.card}>
          <h3>CONTRATO:</h3>
          <h4>{dadosPlano?.nomePlano?.replace("DESCONTOS", "") || ""}</h4>
          <hr style={{ width: "45%" }} />
          <p>
            DESCONTO
            <br />
            GARANTIDO:
          </p>
          <h1>{formataPercentual(dadosPlano?.percentualDesconto)}</h1>
          <hr style={{ width: "45%" }} />
          <p>
            ECONOMIA ACUMULADA <br />
            ESTIMADA EM {dadosPlano?.nomePlano?.replace("DESCONTOS", "") || ""}
          </p>
          <h2>
            <span>R$</span> {formataValor(dadosPlano.economiaTotal)}
          </h2>
        </Box>
      </Grid>
      <Grid item xs={12} sm={8}>
        <ReactApexChart
          options={options(gerarCategorias())}
          series={gerarDados()}
          type="bar"
          height="250px"
        />
        <hr style={{ width: "80%" }} />
        <table style={{ width: "100%" }}>
          <thead>
            <tr>
              <th className={classes.th}>{"  "}</th>
              {gerarCategorias()?.map((categoria, index) => (
                <th key={index} className={classes.th}>
                  {categoria}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {gerarDadosTabela().map((dado, index) => (
              <tr
                key={index}
                className={
                  gerarDadosTabela().length === index + 1
                    ? classes.trFooter
                    : ""
                }
              >
                <td className={classes.td}>{dado.name}</td>
                {dado.data.map((itemData, index) => (
                  <td key={index} className={classes.td}>
                    {itemData}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </Grid>
    </Grid>
  );
};

GraficoMercado.propTypes = {
  dadosPlano: PropTypes.shape({
    economiaTotal: PropTypes.number,
    percentualDesconto: PropTypes.number,
    nomePlano: PropTypes.string,
    dadosEconomiaAnual: PropTypes.arrayOf(
      PropTypes.shape({
        anoTimeline: PropTypes.number,
        somaCustoTotalLivre: PropTypes.number,
        somaCustoTotalCativo: PropTypes.number,
        economiaAnual_reais: PropTypes.number
      })
    )
  })
};

GraficoMercado.defaultProps = {
  dadosPlano: {}
};

export default GraficoMercado;
