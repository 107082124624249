import React, { useState, useEffect } from 'react';
import { Card, Grid } from "@mui/material";
import { useForm } from 'react-hook-form';
import { useStyles } from './style';
import UploadDropArea from 'componentes/upload';
import Botao from 'componentes/botao';
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { importarRateioCDEDivulgado } from "../../../../servicos/cdeServico";
import ResultadoOperacao from 'componentes/resultadoOperacao';
import moment from 'moment';
import InputData from 'componentes/inputTextoData';
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import RotasDTO  from "../../../../rotas/rotasUrlDto";  
import { useHistory } from "react-router-dom";

const RateioCDEDivulgadoImportar = () => {
  const classes = useStyles();
  const history = useHistory();
  const usuarioGlobal = useSelector((state) => state.usuario);

  const msgPadraoUpload = "Solte o arquivo para anexá-lo ou procurar";

  const { handleSubmit } = useForm({
    reValidateMode: 'onSubmit',
  });

  const [anoReferencia, setAnoReferencia] = useState(null);
  const handleChangeAnoReferencia = (ano) => setAnoReferencia(ano);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(msgPadraoUpload);
  const [carregandoArquivo, setCarregandoArquivo] = useState(false);
  const [progressMesage, setProgressMessage] = useState("");
  const [resultadoOperacao, setResultadoOperacao] = useState(false);
  const [resultadoOperacaoCabecalho, setResultadoOperacaoCabecalho] = useState("");
  const [resultadoOperacaoObj, setResultadoOperacaoObj] = useState({
    "mensagens": []
  });
    
  const processarArquivo = (arquivo) => {
    if (arquivo != null && arquivo[0] != null) {
      setFile(arquivo[0]);
      setFileName(arquivo[0].file.name);
    }
  };

  const onAddUpload = (arquivo) => {
    processarArquivo(arquivo)
  };

  const onCancelar = () => {
    setAnoReferencia(null);
    setFile(false);
    setFileName(msgPadraoUpload);
    setCarregandoArquivo(false);
    setResultadoOperacao(false);
    setResultadoOperacaoCabecalho("");
    setResultadoOperacaoObj({
      "mensagens": []
    });
  }

  const EnviarForm = async () => {

    const anoBase = moment(anoReferencia).format("YYYY");

    try {
      if (anoReferencia === null || anoReferencia === undefined || anoReferencia === ""){
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "Por favor, Informe o Ano de Referência"
          })
        )
      }
      else if(moment(anoReferencia).format("YYYY").length > 4 || moment(anoReferencia).format("YYYY").length < 4){
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "Ano de Referência inválido"
          })
        )
      }
      else if (!file) {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "Por favor, selecione o arquivo desejado."
          })
        )
      } else {
        setCarregandoArquivo(true);
        setResultadoOperacao(false);
        setProgressMessage("Importando o arquivo... Por favor, aguarde.");
        const upload = await importarRateioCDEDivulgado(usuarioGlobal?.usuario?.id, usuarioGlobal?.usuario?.usuarioNome, anoBase, file);
        
        if ((upload.status === 200 || upload.status === 202) && upload?.data) {
          setFile(false);
          setFileName(msgPadraoUpload);
          setCarregandoArquivo(false);
          setResultadoOperacao(true);

          let dadosRetorno = upload?.data;
          let mensagemRetorno = ["Processo de Importação Finalizado"];

          if (dadosRetorno.length !==null && dadosRetorno.length !== undefined && dadosRetorno.length > 1){
            let valores = dadosRetorno?.[1];
            
            if(valores.length !==null && valores.length !== undefined && valores.length > 0){
              for (var i = 0; i < valores?.length; i++) {
                mensagemRetorno.push(valores[i].Mensagem); 
              } 
            }
          }
          setResultadoOperacaoCabecalho(`Cobertura CDE Uso e CDE GD ANEEL - Importação do arquivo ${file?.file?.name}`)
          setResultadoOperacaoObj({"mensagens": mensagemRetorno });      
        }
      }
    } catch (erro) {
      setFile(false);
      setFileName(msgPadraoUpload);
      setCarregandoArquivo(false);
      setResultadoOperacao(true);
      setResultadoOperacaoCabecalho(`Cobertura CDE Uso e CDE GD ANEEL - Importação do arquivo ${file?.file?.name}`)
      setResultadoOperacaoObj({"mensagens": [erro.message?? "Ocorreu um erro desconhecido"]});
      console.info(erro);
    }
  }

  const aoEnviarForm = () => {
    EnviarForm()
  }

  const onFechar = (id) => {
    history.push(`${RotasDTO.ProjecaoInsumoListar}`);
  }

  return (
    <form className="needs-validation" onSubmit={handleSubmit(aoEnviarForm)}>
      <Grid item sm={12} style={{ textAlign: "center" }}>
      <ResultadoOperacao
        item={resultadoOperacaoObj}
        cabecalhoResultado={resultadoOperacaoCabecalho}
        onClose={() => onFechar()}
      />
      </Grid>
      {resultadoOperacao === false ?
        <Card className={classes.container}>
          <Grid container className={classes.containerTitulo}>
            <Grid item xs={11} className="font-weight-bold">
              Cobertura CDE Uso e CDE GD ANEEL
            </Grid>
            <Grid item xs={1} >             
              <CloseIcon onClick={onFechar} className={classes.icon}/>
            </Grid>   
          </Grid>
          {carregandoArquivo === false ?
            <Grid>
            <Grid container p={2} spacing={4} className={classes.container} justifyContent="left">              
            <Grid item xs={12} sm={6} md={5} lg={4} xl={2} className={classes.containerData}>
                  <InputData
                    type="text"
                    label="Ano de Referência"
                    customValue={anoReferencia}
                    onChange={(data) => handleChangeAnoReferencia(data)}
                    renderIconShowHide={false}
                    format="yyyy"
                    views={["year"]}
                    minDate= {moment().add(-100, "years").toDate()}
                  />
                </Grid>
            </Grid>
          
          
            <Card className={classes.container}>
              <Grid container spacing={4} p={2} className={classes.container}>
                <Grid item sm={12} className={classes.fileUpload}>
                  <UploadDropArea
                    onAdd={onAddUpload}
                    dropAreaText={fileName}
                    showLoader={false}
                    acceptedFiles={[
                      '.xlsm,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
                    ]}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4} p={2} className={classes.container} justify="space-between">
                <Grid item sm={3}>
                  <Botao
                    type="button"
                    label="Cancelar"
                    onClick={onCancelar}
                    className={classes.button}
                  />
                </Grid>
                <Grid item sm={3}>
                  <Botao
                    type="submit"
                    label="Importar"
                    className={classes.button}
                  />
                </Grid>
              </Grid>
            </Card>
            </Grid>
            :
            <Card className={classes.container}>
              <Grid container spacing={4} p={2} className={classes.container}>
                <Grid item sm={12} className={classes.fileUpload}>
                  <Grid item sm={12} style={{ textAlign: "center" }}>
                    {progressMesage}
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          }
        </Card>
      : ""
      }
    </form>
  )
}

export default RateioCDEDivulgadoImportar;