import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Grid, Card } from '@mui/material';
import { useStyles, Tabela } from './style';
import { getComponenteQFatorX } from '../../../servicos/componenteQFatorX';
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import CloseIcon from "@mui/icons-material/Close";
import RotasDTO  from "../../../rotas/rotasUrlDto";  
import { useHistory } from "react-router-dom";

const ComponenteQFatorXVisualizar = () => {
  const classes = useStyles();
  const history = useHistory();

  const onFechar = (id) => {
     history.push(`${RotasDTO.ProjecaoInsumoListar}`);
   }
   
  const [valores, setValores] = useState([]);
  const [error, setError] = useState(false);

  const obterValores = async () => {
    try {
      const response = await getComponenteQFatorX();
      if (response.status === 200 && response.data) {
        setValores(response?.data);
        
      } else {
        setValores([]);
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "Não há dados para exibição."
          })
        );
      }
    } catch (error) {
      setError(true);
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  useEffect(() => {
    if (!error && !valores.length) obterValores();
  }, [!error && !valores.length, obterValores]);

  return (
    <Grid container spacing={4} p={2} className={classes.container}>
       <Grid container className={classes.containerTitulo}>
          <Grid item xs={11} className={classes.titulo}>
            Componente Q Fator X
          </Grid>
          <Grid item xs={1} >             
            <CloseIcon onClick={onFechar} className={classes.icon}/>
          </Grid>   
        </Grid>
      <Grid item sm={12}>
        <Card className={classes.container}>
          <Grid container spacing={4} p={2} className={classes.container}>
              <Grid item sm={12}>
                <div className={classes.customPosition}>
                  <Tabela>
                    <Tabela.Thead>
                      <Tabela.Tr>
                        <Tabela.Th colSpan="3">Informações Importadas</Tabela.Th>
                      </Tabela.Tr>
                      <Tabela.Tr>
                        <Tabela.Th>DISTRIBUIDORA</Tabela.Th>
                        <Tabela.Th>ANO</Tabela.Th>
                        <Tabela.Th>COMPONENTE Q</Tabela.Th>
                      </Tabela.Tr>
                    </Tabela.Thead>
                    <Tabela.Tbody>
                      {valores?.map((item) => (
                        <Tabela.Tr>
                          <Tabela.Td>{item.distribuidora}</Tabela.Td>
                          <Tabela.Td>{item.ano}</Tabela.Td>
                          <Tabela.Td>{`${item.componenteQ.toLocaleString("pt-BR", { currency: "BRL",  maximumFractionDigits: 10 })}`}</Tabela.Td>
                        </Tabela.Tr>
                      ))}
                    </Tabela.Tbody>
                  </Tabela>
                </div>
              </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  )
}

export default ComponenteQFatorXVisualizar;
