import RetornoEndpointDto from "../../../global/dto/retornoEndpointDto";
import { ListarAlteracaoMedicoes } from "../../../servicos/medicaoControleServico";

export default class BoletasHelper {
  static async listarAlteracaoMedicoes(filtro) {
    try {
      const resultado = await ListarAlteracaoMedicoes(filtro);

      return RetornoEndpointDto.Sucesso(
        "Alteração de medições obtidas com sucesso",
        resultado
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu um erro ao obter a alteração de medições"
      );
    }
  }
}
