export default class OperacaoDto {
  constructor(
    id,
    clienteId,
    clienteNome,
    unidadeId,
    unidadeNome,
    statusDocumento,
    statusTrocaDeTitularidade,
    statusMedidor,
    statusFinalizado,
    distribuidora
  ) {
    this.id = id;
    this.clienteId = clienteId;
    this.clienteNome = clienteNome;
    this.unidadeId = unidadeId;
    this.unidadeNome = unidadeNome;
    this.statusDocumento = statusDocumento;
    this.statusTrocaDeTitularidade = statusTrocaDeTitularidade;
    this.statusMedidor = statusMedidor;
    this.statusFinalizado = statusFinalizado;
    this.distribuidora = distribuidora;
  }
}
