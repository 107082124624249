export default class AlteracoesMedicoesDto {
  constructor(
    cliente,
    unidade,
    medidor,
    dataAlteracao,
    dia,
    hora,
    valorPrevio,
    valorAjustado,
    diferenca,
    ativo
  ) {
    this.cliente = cliente;
    this.unidade = unidade;
    this.medidor = medidor;
    this.dataAlteracao = dataAlteracao;
    this.dia = dia;
    this.hora = hora;
    this.valorPrevio = valorPrevio;
    this.valorAjustado = valorAjustado;
    this.diferenca = diferenca;
    this.ativo = ativo;
  }
}
