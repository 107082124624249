import * as React from "react";
import PropTypes from "prop-types";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import { useStyles } from "./style";

function SelectComponente({ id, label = <></>, opcoes, opcao = "", setOpcao }) {
  const classes = useStyles();

  const handleChange = (event) => {
    const {
      target: { value }
    } = event;

    setOpcao(value);
  };

  return (
    <FormControl sx={{ m: 0, width: "100%", border: "none" }}>
      <Select
        id={id}
        value={opcao}
        onChange={handleChange}
        label=""
        displayEmpty
        input={<OutlinedInput className={classes.input} />}
        renderValue={() => {
          return <>{label}</>;
        }}
      >
        {opcoes.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            <ListItemText primary={item.valor} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

SelectComponente.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.node,
  opcoes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      valor: PropTypes.string.isRequired
    })
  ).isRequired,
  opcao: PropTypes.oneOfType(PropTypes.string, PropTypes.number),
  setOpcao: PropTypes.func.isRequired
};

SelectComponente.defaultProps = {
  label: <></>,
  opcao: ""
};

export default SelectComponente;
