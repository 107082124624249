import { makeStyles } from "@mui/styles";
import theme from "themes";

export const useStyles = makeStyles(() => ({
  root: {
    "& label": {
      color: `${theme.color.selectArredondadoColor} !important`
    },
    "& input": {
      "-webkit-text-fill-color": `${theme.color.selectArredondadoColor} !important`,
      color: `${theme.color.selectArredondadoColor} !important`,
      bordercolor: `${theme.color.selectArredondadoColor} !important`
    },
    "& fieldset": {
      borderColor: `${theme.color.selectArredondadoColor} !important`
    }
  },
  textField: {
    width: 200
  }
}));
