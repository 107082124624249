/* eslint-disable import/no-unresolved */
import React, { useState, useEffect, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Grid } from "@mui/material";
import DataTable from "react-data-table-component";
import Coluna from "componentes/tabelaPaginada/colunas/coluna";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import Loader from "componentes/loader";
import ColunaComponentePersonalizado from "componentes/tabelaPaginada/colunas/colunaComponentePersonalizado";
import { getAll,excluirAnotacao } from "../../servicos/anotacaoUsuario";
import enumeradorBotao from "./enumeradores/enumeradorBotao";
import { useStyles, customStyles, conditionalRowStyles } from "./style";
import ColunaMultiplosBotoes from "./colunaCustomizada";
import RotasDTO from "../../rotas/rotasUrlDto";
import MaterialInputTexto from "componentes/inputTexto/materialInput";
import BotaoCadastro from 'componentes/botaoCadastro';
import { Base } from 'componentes/cores';
import BotaoFiltroOrdenacao from 'componentes/botaoFiltroOrdenar';
import TuneIcon from "@mui/icons-material/Tune";
import ModalConfirmacao from 'componentes/modalConfirmacao';


const AnotacoesUsuariosListar = () => {
  const history = useHistory();
  const classes = useStyles();
  const [anotacoes, setAnotacoes] = useState([]);
  const [anotacoesFiltro, setAnotacoesFiltro] = useState([]);
  const [carregandoDados, setCarregandoDados] = useState(false);
  const [editar, setEditar] = useState(false);
  const [ordemSelecionada, setOrdemSelecionada] = useState(null);
  const [filtroSelecionado, setFiltroSelecionado] = useState(null);
  const [tipoSelecionado, setTipoSelecionado] = useState(null);
  const [naoExibirFiltrosTipo, setNaoExibirFiltrosTipo] = useState(false);
  const [listaSeriesHistoricas, setListaSeriesHistoricas] = useState([]);
  const [seriesHistoricas, setSeriesHistoricas] = useState([]);
  const [, updateState] = React.useState();
  const [modalExclusao, setModalExclusao] = useState(false);
  const [idParaExcluir, setIdParaExcluir] = useState();

  const forceUpdate = React.useCallback(() => updateState({}), []);

  const { register, control } = useForm({
    reValidateMode: "onSubmit"
  });

  const textoBusca = useWatch({
    control,
    name: "textoBusca",
    defaultValue: ""
  });

  const stringBusca = useMemo(() => {
    return textoBusca;
  }, [textoBusca]);

  const obterAnotacoes = async () => {
    setCarregandoDados(true);
    try {
      const lista = await getAll();
      if (lista?.data !== null) {
        const response = lista?.data;
        const organizado = [];
        if (response) {
          response.map((item) => organizado.push({
            ...item,
            id: item.idAnotacaoUsuario,
            dataAnotacao: item.data != null ? window.moment(item.data).format("DD-MM-YYYY HH:mm:ss") : ""
          }));
        }
        setAnotacoes(organizado);
        setAnotacoesFiltro(organizado);
      }
      setCarregandoDados(false);
    } catch (error) {
      setCarregandoDados(false);
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ?? error?.message ?? "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  const onClickEditar = (id) => {
    history.push(`${RotasDTO.AnotacoesUsuariosListar}/cadastrar-anotacao/${id}`);
  };

  const onClickExcluir = async (id) => {
    setIdParaExcluir(id);
    setModalExclusao(true);
  }

  const handleExclusao = async () => {
    const resultdel = await excluirAnotacao(idParaExcluir);
    if (resultdel.status === 200) {
      obterAnotacoes();
      store.dispatch(
        alertaExibir({
          tipo: "success",
          mensagem: `Anotação excluída com sucesso.`
        })
      );
   };
   setModalExclusao(false)
  }

  const onClickNovaAnotacao = () => {
    history.push(`${RotasDTO.AnotacoesUsuariosListar}/cadastrar-anotacao/0`);
  }

  const onClickBotao = (tipo, parametros) => {
    switch (tipo) {
      case enumeradorBotao.Editar:
        onClickEditar(parametros.id);
        break;
      case enumeradorBotao.Excluir:
        onClickExcluir(parametros.id);
        break;
      default:
        break;
    }
  };

  const colunas = [
    {...new Coluna("criacao", "Criação", true, "150px", "150px"),wrap: true},
    {...new Coluna("alteracao", "Alteração", true, "150px", "150px"),wrap: true},
    {...new Coluna("descricao", "Descrição", true, "700px", "700px"), wrap:true},
    new ColunaComponentePersonalizado(
      "opcoes",
      "Ações",
      ColunaMultiplosBotoes,
      onClickBotao
    )
  ];

  useEffect(() => {
    obterAnotacoes();
  }, [editar]);

  useEffect(() => {
    setTimeout(() => {
      handleFiltrarAnotacoes();
    }, 100);
  }, [stringBusca,ordemSelecionada,filtroSelecionado]);


  const handleFiltrarAnotacoes = () => {
  let dadosFiltrados = [];

  if(anotacoes != null && anotacoes.length > 0){  
      
    dadosFiltrados = anotacoes

    if(stringBusca !== undefined && stringBusca.length > 0){
      dadosFiltrados = dadosFiltrados.filter(filterAnotacoesByDescricao);
    }

    if (filtroSelecionado !== undefined && filtroSelecionado !== null){
      dadosFiltrados = dadosFiltrados?.filter(filterAnotacoesByUsuario);
    } 

    let dadosOrdenados = [];

    if(ordemSelecionada == 1 ){
      dadosOrdenados = dadosFiltrados.sort(function (a, b) {
        return (a.data > b.data) ? 1 : ((b.data > a.data) ? -1 : 0);   
      });       
    }
    else if(ordemSelecionada == 2 ) {
      dadosOrdenados = dadosFiltrados.sort(function (a, b) {
        return (a.data > b.data) ? 1 : ((b.data > a.data) ? -1 : 0);   
      }).reverse();
    }    
    else {
      dadosOrdenados = dadosFiltrados;
    }

    setAnotacoesFiltro(dadosOrdenados);
    forceUpdate();


  }

}

  const handleFiltrarSeriesHistoricas = () =>{

    let dadosFiltrados = [];
    
    if(listaSeriesHistoricas != null && listaSeriesHistoricas.length > 0){  
      
      dadosFiltrados = listaSeriesHistoricas;
     
      if(stringBusca != undefined && stringBusca.length > 0){
             
        if((filtroSelecionado == null || filtroSelecionado == undefined)){
          dadosFiltrados = dadosFiltrados.filter(filterSeriesHistoricasByCodigo);
        }else {           
          let seriesFiltradasPorCampo = [];

          if (tipoSelecionado == 1){
            seriesFiltradasPorCampo = dadosFiltrados.filter(filterSeriesHistoricasByCategoria);
          }  
          else if (tipoSelecionado == 2){
            seriesFiltradasPorCampo = dadosFiltrados.filter(filterSeriesHistoricasByPeriodicicade);
          }  
          else if (tipoSelecionado == 3){
            seriesFiltradasPorCampo = dadosFiltrados.filter(filterSeriesHistoricasByUnidade);
          } 
          if(seriesFiltradasPorCampo != null && seriesFiltradasPorCampo != undefined){
            dadosFiltrados = seriesFiltradasPorCampo;
          }
        }
        
      }
      if (filtroSelecionado == 0 || filtroSelecionado == 1){
        let seriesAtivas = dadosFiltrados?.filter(filterSeriesHistoricasByAtivo);
        dadosFiltrados = seriesAtivas;
      } 
      let dadosOrdenados = [];
      
      if(ordemSelecionada == 2 ){
        dadosOrdenados = dadosFiltrados.sort(function (a, b) {
          return (a.idSerieHistorica > b.idSerieHistorica) ? 1 : ((b.idSerieHistorica > a.idSerieHistorica) ? -1 : 0);   
        }).reverse();       
      }
      else{
        dadosOrdenados = dadosFiltrados.sort(function (a, b) {
          return (a.codigo > b.codigo) ? 1 : ((b.codigo > a.codigo) ? -1 : 0);   
        });
      }    
      setSeriesHistoricas(dadosOrdenados);
    } 
  }

  const handleClickOrdemSelecionada = (event) => {
    const val = ordemSelecionada === event ? null : event;
    setOrdemSelecionada(val);
  };

  const handleClickFiltroSelecionado = (event) => {
    const val = filtroSelecionado === event ? null : event;
    setFiltroSelecionado(val);
  };

   const handleClickAtivarAgora = async () => {
    handleFiltrarAnotacoes();
  };

  function filterAnotacoesByDescricao(obj) {
    if (obj.descricao !== null && obj.descricao.toUpperCase().includes(textoBusca.toUpperCase())) {   
      return true;
    } else {
      return false;
    }
  } 

  function filterAnotacoesByUsuario(obj) {
    if (obj.idUsuario == filtroSelecionado) {   
      return true;
    } else {
      return false;
    }
  }

  function filterSeriesHistoricasByCodigo(obj) {
    if (obj.codigo != null && obj.codigo.toUpperCase().includes(textoBusca.toUpperCase())) {   
      return true;
    } else {
      return false;
    }
  }

  function filterSeriesHistoricasByUnidade(obj) {
    if (obj.descricaoUnidade != undefined && obj.descricaoUnidade.toUpperCase().includes(textoBusca.toUpperCase())) {   
      return true;
    } else {
      return false;
    }
  }

  function filterSeriesHistoricasByCategoria(obj) {
    if (obj.descricaoCategoria != undefined && obj.descricaoCategoria.toUpperCase().includes(textoBusca.toUpperCase())) {   
      return true;
    } else {
      return false;
    }
  }

  function filterSeriesHistoricasByPeriodicicade(obj) {
    if (obj.descricaoPeriodicidade != undefined && obj.descricaoPeriodicidade.toUpperCase().includes(textoBusca?.toUpperCase())) {   
      return true;
    } else {
      return false;
    }
  }

  function filterSeriesHistoricasByAtivo(obj) {
    if (obj.ativo == filtroSelecionado) {   
      return true;
    } else {
      return false;
    }
  }

  const listarOrdenarPor = () => {
    const ordernacoes = [
      {
        key: 1,
        label: "Antigas"
      },
      {
        key: 2,
        label: "Recentes"
      }
    ];
  
    return ordernacoes;
  };
  
  const listarFiltrarPor = () => {
    const filtros = anotacoes.map((item) => {
      return {
        key: item.idUsuario,
        label: item.nomeUsuario
      }
    });
    var result = filtros.reduce((unique, o) => {
      if(!unique.some(obj => obj.label === o.label && obj.key === o.key)) {
        unique.push(o);
      }
      return unique;
  },[]);

  return result;
  };

  return (
    <>
        <ModalConfirmacao
          item={modalExclusao}
          onConfirmar={handleExclusao}
          mensagem={"Tem certeza que deseja excluir essa Anotação?"}
          onCancelar={() => setModalExclusao(false)}
        />
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={6}>
            <MaterialInputTexto
              type="text"
              id="textoBusca"
              name="textoBusca"
              label="Buscar em Descrição"
              renderIconShowHide
              searchAdornment
              ref={register}
              defaultValue={textoBusca ?? ""}
              className={classes.search}
              permiteValorBranco
            />
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={3} className={classes.itemFiltro}>
        <BotaoFiltroOrdenacao
            type="button"
            color={Base.GreyWater}
            background="transparent"
            label="Filtrar / Ordenar"
            icon={<TuneIcon />}
            className={classes.buttonFiltro}
            ordenacao={listarOrdenarPor()}
            ordenadorUm="Exibir primeiro as mais"
            ordenadorDois="Filtrar pelo usuário"
            ordemSelecionada={ordemSelecionada}
            filtros={listarFiltrarPor()}
            filtrosSelecionados={filtroSelecionado}
            onClickOrdenacao={handleClickOrdemSelecionada}
            onClickFiltro={handleClickFiltroSelecionado}
          />
        </Grid>

          </Grid>
          <Grid container mt={1} spacing={3} alignItems="center">
          <Grid item xs={12}>
          <BotaoCadastro
            label="Nova Anotação"
            color={Base.White}
            onClick={onClickNovaAnotacao}
          />
        </Grid>
        </Grid>
      <Loader loading={carregandoDados} className="w-auto">
        <div className={classes.root} style={editar ? { display: "none" } : {}}>
          <Grid container>
            {anotacoesFiltro?.length > 0 ?
              <DataTable
                subHeader
                subHeaderAlign='right'
                columns={colunas}
                data={anotacoesFiltro}
                theme="DeltaEnergia"
                customStyles={customStyles}
                conditionalRowStyles={conditionalRowStyles}
                noDataComponent="Nenhum registro encontrado"
                noHeader
              />
              :
              ""
            }
          </Grid>
        </div>
      </Loader>
    </>
  )
}

export default AnotacoesUsuariosListar;