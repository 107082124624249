import { Card, Grid } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";

import Botao from "componentes/botao";
import BotaoRetornarListagem from "componentes/botaoRetornarListagem";
import { Base } from "componentes/cores";
import MaterialInputTexto from "componentes/inputTexto/materialInput";

import FormValidation from "componentes/formValidation";

import Loader from "componentes/loader";

import MaterialSwitch from "componentes/switch";

import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { desabilitarFiltroLateral } from "global/redux/modulos/usuario/actions";
import { RotasDTO } from "global/rotas/rotasUrlDto";

import { useStyles } from "./style";

import CadastroHelper from "./helper";

function AgenteCadastro() {
  const history = useHistory();
  const { id: idUrl } = useParams();

  const [carregando, setCarregando] = useState();

  const [id, setId] = useState();
  const [nomeCategoria, setNomeCategoria] = useState();
  const [ativo, setAtivo] = useState(true);
  const [serveParaCliente, setServeParaCliente] = useState(false);
  const [possuiMarcaDagua, setPossuiMarcaDagua] = useState(false);

  const { register, errors, handleSubmit } = useForm({
    reValidateMode: "onSubmit"
  });

  const classes = useStyles();

  const onSubmit = async (params) => {
    if (id) params.Id = id;

    setCarregando(true);

    const retorno = await CadastroHelper.Salvar(params);

    setCarregando(false);

    if (!retorno.sucesso) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: retorno.mensagem
        })
      );
      return;
    }

    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem: retorno.mensagem
      })
    );
    history.push(RotasDTO.RelatoriosCategorias);
  };

  const obterPorId = useCallback(async () => {
    // console.log('obterPorId', idUrl);

    if (!idUrl || idUrl <= 0) return;

    setCarregando(true);

    const retorno = await CadastroHelper.ObterPorId({ id: idUrl });

    if (!retorno.sucesso) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: retorno.mensagem
        })
      );
      return;
    }

    const categoria = retorno.data;

    setId(categoria.id);
    setNomeCategoria(categoria.nome);
    setAtivo(categoria.ativo);
    setServeParaCliente(categoria.serveParaCliente);
    setPossuiMarcaDagua(categoria.possuiMarcaDagua);

    setCarregando(false);
  }, [idUrl]);

  useEffect(() => {
    // console.log('useEffect', obterPorId);

    obterPorId();
  }, [obterPorId]);

  useEffect(() => {
    store.dispatch(desabilitarFiltroLateral(true));

    return () => store.dispatch(desabilitarFiltroLateral(false));
  }, [desabilitarFiltroLateral]);

  return (
    <>
      <Loader loading={carregando}>
        <FormValidation onSubmit={onSubmit} handleSubmit={handleSubmit}>
          <Card>
            <Grid container p={2} spacing={4} className={classes.container}>
              <Grid item xs={6} className="font-weight-bold">
                {`${id ? "Editar" : "Nova"} Categoria de Relatório`}
              </Grid>

              <BotaoRetornarListagem
                urlListagem={RotasDTO.RelatoriosCategorias}
              />

              <Grid item lg={4} md={4} sm={12}>
                <MaterialInputTexto
                  type="text"
                  id="Nome"
                  name="Nome"
                  permiteValorBranco
                  defaultValue={nomeCategoria}
                  label="Nome da categoria do relatório"
                  renderIconShowHide={false}
                  ref={register({
                    required: "Campo Nome é obrigatório!",
                    maxLength: {
                      value: 100,
                      message: "Quantidade máxima de 100 caracteres!"
                    }
                  })}
                  errors={errors}
                />
              </Grid>

              <Grid item lg={3} md={3} sm={6}>
                <MaterialSwitch
                  ref={register()}
                  label="Serve para o cliente?"
                  labelPlacement="top"
                  id="serveParaCliente"
                  name="serveParaCliente"
                  justify="flex-start"
                  checked={serveParaCliente}
                  onChange={(param) => setServeParaCliente(param)}
                />
              </Grid>

              <Grid item lg={3} md={3} sm={6}>
                <MaterialSwitch
                  ref={register()}
                  label="Possui Marca D'agua?"
                  labelPlacement="top"
                  id="possuiMarcaDagua"
                  name="possuiMarcaDagua"
                  justify="flex-start"
                  checked={possuiMarcaDagua}
                  onChange={(param) => setPossuiMarcaDagua(param)}
                />
              </Grid>

              {id && (
                <Grid item lg={2} md={2} sm={6} justifyContent="flex-start">
                  <MaterialSwitch
                    ref={register()}
                    label="Ativo"
                    labelPlacement="top"
                    id="Ativo"
                    justify="flex-start"
                    name="Ativo"
                    checked={ativo}
                    onChange={(param) => setAtivo(param)}
                  />
                </Grid>
              )}
            </Grid>

            <div className={classes.savewrapper}>
              <Botao
                type="submit"
                label="Salvar"
                color={Base.Mango}
                className={classes.button}
              />
            </div>
          </Card>
        </FormValidation>
      </Loader>
    </>
  );
}

export default AgenteCadastro;
