/* eslint-disable prefer-const */
/* eslint-disable no-shadow */
/* eslint-disable no-restricted-syntax */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import { useForm } from "react-hook-form";
import { Card, Grid, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import InputData from "componentes/inputTextoData";
import { Base } from "componentes/cores";
import Loader from "componentes/loader";
import UploadDropArea from "componentes/upload";
import Botao from "componentes/botao";
import ValidacaoDominios from "componentes/validacaoDominios";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { buscarDistribuidoras } from "servicos/distribuidorasServico";
import { useSelector } from "react-redux";
import { useStyles } from "./style";
import { importVariaveisCDE } from "../../../../servicos/variaveisCDE";
import CloseIcon from "@mui/icons-material/Close";
import RotasDTO  from "../../../../rotas/rotasUrlDto";  
import { useHistory } from "react-router-dom";
import { importPing } from "../../../../utils/import-ping"
import { geraURLUpload } from "../../../../servicos/awsUtils";
import AWSS3UploadAsh from 'aws-s3-upload-ash';

const S3CustomClient = new AWSS3UploadAsh();

const VariaveisCDEImportar = () => {
  const classes = useStyles();
  const history = useHistory();

  const onFechar = (id) => {
    history.push(`${RotasDTO.ProjecaoInsumoListar}`);
  }

  const msgPadraoUpload = "Solte o arquivo para anexá-lo ou procurar";
  const usuarioGlobal = useSelector((state) => state.usuario);

  const { handleSubmit } = useForm({
    reValidateMode: "onSubmit",
  });

  const [file, setFile] = useState(false);
  const [fileName, setFileName] = useState(msgPadraoUpload);
  const [carregandoArquivo, setCarregandoArquivo] = useState(false);
  const [progressMesage, setProgressMessage] = useState("");

  const [distribuidora, setDistribuidora] = useState({});
  const [existPCAT, setExistPCAT] = useState(false);

  const [validacaoDominio, setValidacaoDominio] = useState(false);
  const [validacaoDominioCabecalho, setValidacaoDominioCabecalho] = useState("");
  const [validacaoDominiosObj, setValidacaoDominiosObj] = useState({
    "contexto": "",
    "entidades": []
  });

  const [distribuidoras, setDistribuidoras] = useState([]);
  const obterDistruidoras = useCallback(async () => {
    try {
      const lista = await buscarDistribuidoras();
      if (lista.status === 200 && lista.data) {
        setDistribuidoras(lista?.data);
      }
    } catch (error) {
      console.info(error);
    }
  }, []);
  useEffect(() => {
    if (!distribuidoras.length) obterDistruidoras();
  }, [!distribuidoras.length, obterDistruidoras]);

  const [anoTarifario, setAnoTarifario] = useState(null);

  const handleChangeAnoTarifario = (data) => {
    setAnoTarifario(data);
  }

  const [, setVigenciaCorrecao] = useState(null);


  const [acaoID, setAcaoID] = useState(null);
  const handleChangeAcao = (event) => {
    setAcaoID(event.target.value);
  }

  const processarArquivo = (arquivo) => {
    if (arquivo != null && arquivo[0] != null) {
      setFile(arquivo[0]);
      setFileName(arquivo[0].file.name);
    }
  };

  const onAddUpload = (arquivo) => {
    processarArquivo(arquivo)
  };

  const onCancelar = () => {
    setFile(false);
    setFileName(msgPadraoUpload);
    setCarregandoArquivo(false);
    setValidacaoDominio(false);
    setDistribuidora({})
    setAnoTarifario(null);
    setVigenciaCorrecao(null);
    setAcaoID(null);
  }

  const onCancelarValidacaoDominios = () => {
    setFile(false);
    setFileName(msgPadraoUpload);
    setCarregandoArquivo(false);
    setValidacaoDominio(false);
    setDistribuidora({})
    setAnoTarifario(null);
    setVigenciaCorrecao(null);
    setAcaoID(null);
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem: "Importação cancelada!"
      })
    );
  }

  function toCamel(json) {
    let newJson; 
    let key; 
    let newKey; 
    let value
    if (json instanceof Array) {
      return json.map(function(value) {
          if (typeof value === "object") {
            value = toCamel(value)
          }
          return value
      })
    } 
      newJson = {}
      for (key in json) {
        if (json.hasOwnProperty(key)) {
          newKey = (key.charAt(0).toLowerCase() + key.slice(1) || key).toString()
          value = json[key]
          if (value instanceof Array || (value !== null && value.constructor === Object)) {
            value = toCamel(value)
          }
          newJson[newKey] = value
        }
      }
    
    return newJson
  }

  const importarVariaveisCDE = async (data) => {
    if (data && data.totalDesmarcado === 0 && data.responseInsert.totalInserido === data.responseInsert.totalValores) {
      if (file && data) {
        setCarregandoArquivo(true);
        setProgressMessage("Importando o arquivo... Por favor, aguarde.");
        try {
          const response = await importVariaveisCDE(file, false, anoTarifario.getFullYear(), usuarioGlobal);
          if (response?.status === 200 && response?.data) {
            onCancelar();
            store.dispatch(
              alertaExibir({
                tipo: "success",
                mensagem: `Arquivo ${file.file.name} importado com sucesso!`
              })
            );
          } else if (response?.status === 202 && response?.data) {
            if (response?.data.typeError === "ERRO-VALIDACAO-ESTRUTURA" || response?.data.typeError === "ERRO-VALIDACAO-TARIFA" || response?.data.typeError === "ERRO-VALIDACAO-MERCADO-FATURADO" || response?.data.typeError === "ERRO-VALIDACAO-CUSTOS" || response?.data.typeError === "ERRO-VALIDACAO-PESO-TARIFARIO") {
              setFile(false);
              setFileName(msgPadraoUpload);
              setCarregandoArquivo(false);
              setValidacaoDominio(false);
              store.dispatch(
                alertaExibir({
                  tipo: "danger",
                  mensagem: response.data.value[0].ItemValidacao.Mensagem
                })
              );
            }
          }
        } catch (error) {
          setFile(false);
          setFileName(msgPadraoUpload);
          setCarregandoArquivo(false);
          setValidacaoDominio(false);
          store.dispatch(
            alertaExibir({
              tipo: "danger",
              mensagem: "Erro ao importar arquivo!"
            })
          );
        }
      } else {
        store.dispatch(
          alertaExibir({
            tipo: "danger",
            mensagem: "É necessário informar o arquivo!"
          })
        );
      }
    } else {
      if (data && data.responseInsert && data.responseInsert.totalInserido > 0) {
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: data.responseInsert.totalInserido === 1 ? "1 valor registrado com sucesso!" : `${data.responseInsert.totalInserido} valores registrados com sucesso!`
          })
        );
      }
      setCarregandoArquivo(false);
      setValidacaoDominio(false);
    }
  }

  const importarVariaveisCDEComValidacao = async () => {
    if (file) {
      setCarregandoArquivo(true);
      setProgressMessage("Importando o arquivo... Por favor, aguarde.");
      setExistPCAT(false);
      try {
        console.log('file.file', file.file)
        const keyOnS3 = file.file.name;
        const urlPreSigned = await geraURLUpload(keyOnS3)
        const uploadS3Response = await S3CustomClient.uploadFile(file.file, file.file.type, urlPreSigned.data);
        
        console.log('urlPreSigned', urlPreSigned.data)
        console.log('uploadS3Response', uploadS3Response)

        const response = await importVariaveisCDE(true, anoTarifario.getFullYear(), usuarioGlobal, keyOnS3);
        if (response?.status === 200 && response?.data) {
          const datetimeImportStarted = response.data.DataHoraRequisicao
          const urlParams = new URLSearchParams(window.location.search);
          let idInsumo = urlParams.get('id')
          let codigoInsumo = urlParams.get('codigo')
          await importPing(idInsumo, datetimeImportStarted, usuarioGlobal?.usuario.id, codigoInsumo, (status, message) => {
            let mensagemRetorno = ["Processo de Importação Finalizado.", status, message];
            onCancelar();
            store.dispatch(
              alertaExibir({
                tipo: "success",
                mensagem: `${mensagemRetorno.toString()}`
              })
            );
          })

         
        } else if (response?.status === 202 && response?.data) {
          if (response?.data.typeError === "ERRO-VALIDACAO-ESTRUTURA" || response?.data.typeError === "ERRO-VALIDACAO-TARIFA" || response?.data.typeError === "ERRO-VALIDACAO-MERCADO-FATURADO" || response?.data.typeError === "ERRO-VALIDACAO-CUSTOS" || response?.data.typeError === "ERRO-VALIDACAO-PESO-TARIFARIO") {
            setFile(false);
            setFileName(msgPadraoUpload);
            setCarregandoArquivo(false);
            setValidacaoDominio(false);
            store.dispatch(
              alertaExibir({
                tipo: "danger",
                mensagem: response.data.value[0].ItemValidacao.Mensagem
              })
            );
          } else if (response?.data.Contexto === "PROJEÇÃO CDE") {
            setValidacaoDominio(true);
            setValidacaoDominioCabecalho(`Importação do arquivo ${file.file.name}`)
            setValidacaoDominiosObj(toCamel(response?.data));
          }
        }
      } catch (error) {
        setFile(false);
        setFileName(msgPadraoUpload);
        setCarregandoArquivo(false);
        setValidacaoDominio(false);
        store.dispatch(
          alertaExibir({
            tipo: "danger",
            mensagem: "Erro ao importar arquivo!"
          })
        );
      }
    } else {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: "É necessário informar o arquivo!"
        })
      );
    }
  }

  const validarEnvioVariaveisCDE = async () => {
    if (file) {
      if (anoTarifario) {
        importarVariaveisCDEComValidacao();
      } else {
        store.dispatch(
          alertaExibir({
            tipo: "danger",
            mensagem: "É necessário informar o Ano Tarifário!"
          })
        );
      }

    } else {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: "É necessário informar o arquivo!"
        })
      );
    }
  }

  return (
    <form className="needs-validation" onSubmit={handleSubmit(validarEnvioVariaveisCDE)}>
      <ValidacaoDominios
        item={validacaoDominiosObj}
        cabecalhoValidacao={validacaoDominioCabecalho}
        onConfirmar={(data) => importarVariaveisCDE(data)}
        onCancelar={() => onCancelarValidacaoDominios()}
      />
      <Grid container className={classes.containerTitulo}>
        <Grid item xs={11} className="font-weight-bold">
          Importação de Variáveis - Projeçao da CDE
        </Grid>
        <Grid item xs={1} >             
          <CloseIcon onClick={onFechar} className={classes.icon}/>
        </Grid>   
      </Grid>
      {validacaoDominio === false ?
        <Card className={classes.container}>
          {existPCAT === true ?
            <div>
              <Grid container spacing={4} p={2} className={classes.container}>
                <Grid item sm={12}>
                  <p>
                    {`Já existe um arquivo PCATa / ${distribuidora?.razaosocial} para o ano de ${anoTarifario.getFullYear()}.`}
                    <br />
                    Você pode substituir os dados para a vigência atual ou criar uma nova vigência.
                  </p>
                </Grid>
              </Grid>

              <Grid container spacing={4} p={2} className={classes.container} alignItems="center">
                <Grid item sm={4}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="vigencias">O que quer fazer?</InputLabel>
                    <Select
                      variant="standard"
                      labelId
                      id="vigencias"
                      value={acaoID ?? ""}
                      onChange={handleChangeAcao}
                      label="O que quer fazer?"
                      fullWidth>
                      <MenuItem value="1">Substituir vigência</MenuItem>
                      <MenuItem value="2">Nova vigência</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {carregandoArquivo === false ?
                  <Grid container spacing={4} p={2} className={classes.container} justifyContent="center">
                    <Grid item sm={3}>
                      <Botao
                        type="button"
                        label="Cancelar"
                        onClick={onCancelar}
                        color={Base.GreyDim}
                        className={classes.button}
                      />
                    </Grid>
                    <Grid item sm={3}>
                      <Botao
                        type="submit"
                        label="Salvar"
                        color={Base.Mango}
                        className={classes.button}
                      />
                    </Grid>
                  </Grid>
                  : ""
                }
              </Grid>
            </div>
            : <div>
              <Grid container spacing={4} p={2} className={classes.container}>
                {carregandoArquivo === false ?
                  <Grid item sm={12}>
                    <Grid item sm={12} container spacing={4} p={2} className={classes.container} alignItems="center" style={{ paddingBottom: "20px" }}>
                      <Grid item sm={3}>
                        <InputData
                          type="text"
                          label="Ano Tarifário"
                          customValue={anoTarifario}
                          format="yyyy"
                          onChange={(data) => handleChangeAnoTarifario(data)}
                          minDate={moment().subtract(8, "years").toDate()}
                          views={["year"]}
                          renderIconShowHide={false}
                        />
                      </Grid>
                    </Grid>
                    <Grid item sm={12} className={classes.pcatDropFile}>
                      <UploadDropArea
                        onAdd={onAddUpload}
                        dropAreaText={fileName}
                        showLoader={false}
                        acceptedFiles={[
                          ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                        ]}
                      />
                    </Grid>
                  </Grid>
                  :
                  <Grid container spacing={4} p={2} className={classes.container} style={{ marginBottom: "20px" }}>
                    <Grid item sm={12} style={{ textAlign: "center" }}>
                      {progressMesage}
                    </Grid>
                    <Grid item sm={12}>
                      <Loader loading={carregandoArquivo} />
                    </Grid>
                  </Grid>
                }
              </Grid>
              {carregandoArquivo === false ?
                <Grid container spacing={4} p={2} className={classes.container} justifyContent="center">
                  <Grid item sm={3}>
                    <Botao
                      type="button"
                      label="Cancelar"
                      onClick={onCancelar}
                      color={Base.GreyDim}
                      className={classes.button}
                    />
                  </Grid>
                  <Grid item sm={3}>
                    <Botao
                      type="submit"
                      label="Salvar"
                      color={Base.Mango}
                      className={classes.button}
                    />
                  </Grid>
                </Grid>
                : ""
              }
              </div>
          }
        </Card>
        : ""
      }

    </form>
  );
}

export default VariaveisCDEImportar;
