import RetornoEndpointDto from "../../../../../global/dto/retornoEndpointDto";
import { store } from "../../../../../global/redux";
import { alertaExibir } from "../../../../../global/redux/modulos/alertas/actions";
import { buscarPorId } from "../../../../../servicos/colaboradoresServico";
import { resumoCotacaoPdf } from "../../../../../servicos/cotacaoInicialServico";
import RelatorioCotacoesDto from "./dto/relatorioCotacoesDto";

export default class ComponenteMultiBotoesHelper {
  static async obterDadosRelatorio(idCotacao) {
    try {
      if (!idCotacao)
        return RetornoEndpointDto.Erro(
          "Não foi possivel obter os dados do relatorio!"
        );

      const { data, status } = await resumoCotacaoPdf(idCotacao);

      if (status === 204 || (!data?.cliente?.colaboradorId ?? true))
        return RetornoEndpointDto.Erro(
          "Não foi possivel obter os dados do relatorio!"
        );

      const dataCotacao = window
        .moment(data?.cotacaoInicial?.dataCotacao)
        .format("DD/MM/YYYY");

      const dataValidade = window
        .moment(data?.cotacaoInicial?.dataValidade)
        .format("DD/MM/YYYY");

      return RetornoEndpointDto.Sucesso(
        "Dados obtidos com sucesso!",
        new RelatorioCotacoesDto(
          data.cotacaoInicial,
          data.cliente,
          data.distribuidoraDescricao,
          data.listaPlanosGeradosCotacao,
          data.usuario,
          dataCotacao,
          dataValidade,
          data.cativoVsLivre
        )
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        this.formatarMesagemErro(
          error,
          "Não foi possivel obter os dados do relatorio!"
        )
      );
    }
  }

  static async obterColaborador(idColaborador) {
    try {
      if (!idColaborador)
        return RetornoEndpointDto.Erro(
          "Não foi possivel obter os dados do relatorio!"
        );

      const { data, status } = await buscarPorId(idColaborador);

      if (status === 204)
        return RetornoEndpointDto.Erro(
          "Não foi possivel obter os dados do relatorio!"
        );

      return RetornoEndpointDto.Sucesso("Dados obtidos com sucesso!", data);
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Não foi possivel obter os dados do relatorio!"
      );
    }
  }

  static formatarMesagemErro(error, erroPadrao) {
    try {
      if (!(error?.response?.data?.message ?? false)) return erroPadrao;

      const str = error.response.data.message;

      return str.split(" :: ")[1].replace("\r", "").replace("\n", "");
    } catch (err) {
      return erroPadrao;
    }
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }
}
