export default class FiltroAdicionalTabela {
  constructor(
    ordenacao,
    filtro,
    empresaSelecionada,
    clienteSelecionado,
    unidadeSelecionada
  ) {
    this.ordenacao = ordenacao;
    this.filtro = filtro;
    this.empresaSelecionada = empresaSelecionada;
    this.clienteSelecionado = clienteSelecionado;
    this.unidadeSelecionada = unidadeSelecionada;
  }
}
