/* eslint-disable consistent-return */
/* eslint-disable no-unused-expressions */
import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { Card, Grid, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Loader from "componentes/loader";
import InputDataBranco from "componentes/inputTextoDataBranco";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";

import { valoresGuiaRecortes, valoresContratosLeilaoSparta } from "../../../../../servicos/premissasBrasil";
import { listarDistribuidoras } from "../../../../../servicos/parametrizacaoDistribuidoras";
import { useStyles, Tabela, TabelaScroll } from "../../../style";

const MercadoSpartaVisualizar = () => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [carregandoDistribuidoras, setCarregandoDistribuidoras] = useState(false);
  const [listaDistribuidoras, setListaDistribuidoras] = useState([]);

  const [tabelasConsulta, _setTabelasConsulta] = useState(["Outros Dados da SPARTA", "Contratos CCEAR"]);
  const [tabelaConsulta, setTabelaConsulta] = useState("")

  const [distribuidoraId, setDistribuidoraId] = useState("");
  const [inicioSuprimento, setInicioSuprimento] = useState(null);
  
  const handleChangeInicioSuprimento = (ano) => setInicioSuprimento(ano);

  const onChangeDistribuidora = (id) => {
    setDistribuidoraId(id)
  };

  const obterDistribuidoras = useCallback(async () => {
    try {
      setCarregandoDistribuidoras(true);
      const lista = await listarDistribuidoras(true);

      if (lista.status === 200 && lista.data) {
        setCarregandoDistribuidoras(false);    
        setListaDistribuidoras(lista?.data);
      }
    } catch (error) {
      setCarregandoDistribuidoras(false);
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );;
    }
  }, []);

  useEffect(() => {
    if (!listaDistribuidoras.length) obterDistribuidoras();
  }, []);

  const [valoresMercado, setValoresMercado] = useState([]);
  const [valoresCCEE, setValoresCCEE] = useState([]);;

  const [showValores, setShowValores] = useState(false);

  const obterValoresMercado = async () => {
    try {
      setIsLoading(true);
      if (moment(inicioSuprimento).format("YYYY").length === 4){
        const response = await valoresGuiaRecortes(distribuidoraId, moment(inicioSuprimento).format("YYYY"));
        
        if (response.status === 200 && response.data) {
          setValoresMercado(response.data);
          setShowValores(true);
        } else {
          setValoresMercado([]);
          setShowValores(false);
          store.dispatch(
            alertaExibir({
              tipo: "warning",
              mensagem: "Não há dados para impressão com os parâmetros informados."
            })
          );
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  const obterValoresCCEE = async () => {
    try {
      setIsLoading(true);
      const response = await valoresContratosLeilaoSparta(distribuidoraId, moment(inicioSuprimento).format("YYYY"));

      if (response.status === 200 && response.data) {
        setValoresCCEE(response.data);
        setShowValores(true);
      } else {
        setShowValores(false);
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "Não há dados para impressão com os parâmetros informados."
          })
        );
      }
      setIsLoading(false);
    } catch (error) {
      setShowValores(false);
      setIsLoading(false);
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  const renderizarMercado = () => {
    return (
      <>
        <div>
          <Grid container className={classes.container}>
            <Grid item sm={12}>       
                <div> 
                  <Tabela>  
                    <Tabela.Thead>
                      <Tabela.Tr>
                        <Tabela.Th>Guia</Tabela.Th>
                        <Tabela.Th>Item</Tabela.Th>
                        <Tabela.Th>Valor</Tabela.Th>
                      </Tabela.Tr>
                    </Tabela.Thead>
                  <Tabela.Tbody>
                    {valoresMercado.map((it) => (
                      <Tabela.Tr>
                        <Tabela.Td>{it.guia}</Tabela.Td>
                        <Tabela.Td>{it.item}</Tabela.Td>
                        <Tabela.Td>
                          {
                            it.valor?.toLocaleString("pt-BR", {  currency: "BRL", maximumFractionDigits: 2 })
                          }
                        </Tabela.Td>
                      </Tabela.Tr>
                    ))}
                  </Tabela.Tbody>
                  </Tabela>           
                </div>
            </Grid>
          </Grid>
        </div>
      </>
    )
  }

  const renderizarCCEE = () => {
    return (
      <Grid container className={classes.container}>
        <Grid item sm={12}>
          <TabelaScroll>
            <div id="scroll">
              <Tabela>
                <Tabela.Thead>
                  <Tabela.Tr>
                    <Tabela.Th>Leilão</Tabela.Th>
                    <Tabela.Th>Produto</Tabela.Th>
                    <Tabela.Th>Modalidade</Tabela.Th>
                    <Tabela.Th>Fonte</Tabela.Th>
                    <Tabela.Th>Custo Base</Tabela.Th>
                    <Tabela.Th>Data Leilão</Tabela.Th>
                    <Tabela.Th>Ano</Tabela.Th>
                    <Tabela.Th>Montante MWh</Tabela.Th>
                    <Tabela.Th>MWh Contratado</Tabela.Th>
                    <Tabela.Th>MWh Considerado</Tabela.Th>
                    <Tabela.Th>Tarifa Atualizada</Tabela.Th>
                    <Tabela.Th>Despesa Final</Tabela.Th>
                  </Tabela.Tr>
                </Tabela.Thead>
                <Tabela.Tbody>
                  {valoresCCEE.map((item) =>
                    <>
                      {item.ContratosDeLeilao.map((linha) => 
                        <Tabela.Tr>
                          <Tabela.Td>{linha.Leilao}</Tabela.Td>
                          <Tabela.Td>{linha.Produto}</Tabela.Td>
                          <Tabela.Td>{linha.Modalidade}</Tabela.Td>
                          <Tabela.Td>{linha.Fonte}</Tabela.Td>
                          <Tabela.Td>{linha.CustoBase.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Tabela.Td>
                          <Tabela.Td>{moment(linha.DatBase).format('L')}</Tabela.Td>
                          <Tabela.Td>{item.AnoTarifario}</Tabela.Td>
                          <Tabela.Td>{linha.Montante.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Tabela.Td>
                          <Tabela.Td>{linha.MwhContratado.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Tabela.Td>
                          <Tabela.Td>{linha.MwhConsiderado.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Tabela.Td>
                          <Tabela.Td>{linha.TarifaAtualizada.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Tabela.Td>
                          <Tabela.Td>{linha.DespesaFinalPorProduto.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Tabela.Td>
                        </Tabela.Tr>
                      )}
                    </>
                  )}
                </Tabela.Tbody>
              </Tabela>
            </div>
          </TabelaScroll>
        </Grid>
      </Grid>
    )
  }

  const mostrarResultado = () => {
    if (tabelaConsulta === "Outros Dados da SPARTA") return renderizarMercado()
    if (tabelaConsulta === "Contratos CCEAR") return renderizarCCEE()
  }

  useEffect(() => {
    const allInputsFilled = (distribuidoraId && inicioSuprimento && tabelaConsulta)
    
    setShowValores(false);

    if (allInputsFilled) {
      if (tabelaConsulta === "Outros Dados da SPARTA") obterValoresMercado();
      if (tabelaConsulta === "Contratos CCEAR") obterValoresCCEE();
    }
  }, [tabelaConsulta, distribuidoraId, inicioSuprimento]);

  return <>
    <Grid container spacing={5} className={classes.container} justifyContent="flex-end" alignItems="center">
      <Grid item sm={3}>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="perid">Tabela</InputLabel>
          <Select
            variant="outlined"
            labelId="table"
            id="table"
            value={tabelaConsulta}
            onChange={(event) => setTabelaConsulta(event.target.value)}
            label="Tabela"
            fullWidth>
            {tabelasConsulta.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item sm={3}>
        <Loader loading={carregandoDistribuidoras}>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="perid">Agente Comprador</InputLabel>
          <Select
            variant="outlined"
            labelId="perid"
            id="perid"
            value={distribuidoraId}
            onChange={(event) => onChangeDistribuidora(event.target.value)}
            label="Distribuidora"
            fullWidth>
            {listaDistribuidoras.map((item) => (
                <MenuItem value={item.idDistribuidora}>{item.titulo}</MenuItem>
            ))}
          </Select>
        </FormControl>
        </Loader>
      </Grid>
      <Grid item sm={2} className={classes.customSelect}>
        <InputDataBranco
          type="text"
          id="inicioSuprimento"
          name="inicioSuprimento"
          customValue={inicioSuprimento}
          onChange={(data) => handleChangeInicioSuprimento(data)}
          maxDate={new Date("2090-12-31")}
          minDate={new Date("1950-12-31")}
          format="yyyy"
          views={["year"]}
          openTo="year"
          renderIconShowHide={false}
        />
      </Grid>
    </Grid>
    {showValores &&
      <Grid container className={classes.container} justifyContent="flex-end" alignItems="center">
        <Loader loading={isLoading}>
          <Card>
            {showValores &&
              mostrarResultado(tabelaConsulta)
            }          
          </Card>
        </Loader>
      </Grid>
    }
  </>;
}

export default MercadoSpartaVisualizar;