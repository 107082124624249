import React, { useEffect, useState } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { Grid } from "@mui/material";
import { getPowerBIEmbed } from "servicos/powerBiServico";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";

// Styles
import { useStyles } from "./style";

const PowerBI = () => {
  const classes = useStyles();
  const [dadosAcesso, setDadosAcesso] = useState();

  const getEmbed = async () => {
    try {
      const resultado = await getPowerBIEmbed();

      setDadosAcesso(resultado?.data?.embedContent);
    } catch (erro) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            erro?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  useEffect(() => {
    getEmbed();
  }, []);

  return (
    <>
      <Grid container spacing={0} justifyContent="flex-start" width="100%">
        {dadosAcesso && (
          <PowerBIEmbed
            embedConfig={{
              type: "report",
              id: dadosAcesso?.reportId,
              embedUrl: dadosAcesso?.embedUrl,
              accessToken: dadosAcesso?.embedToken,
              tokenType: models.TokenType.Embed
            }}
            cssClassName={classes.container}
          />
        )}
      </Grid>
    </>
  );
};

export default PowerBI;
