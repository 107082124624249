/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable radix */
/* eslint-disable no-unused-vars */
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

// Form Hooks
import { useForm } from "react-hook-form";

// Componentes
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box, Card, Grid, IconButton } from "@mui/material";
import { InputRadioGroup } from "componentes";
import UploadCustomizado from "componentes/uploadCustomizado";
import { cnpj } from "cpf-cnpj-validator";
import { cotacaoTipoCalculo } from "global/constantes";
import { InterfaceDTO } from "global/dto/interfacesDto";
import getDomain from "global/getDomain";
import { Fundo } from "paginas/Acesso/style";
import { useSelector } from "react-redux";
import { ConsumoMobile } from "themes/delta/paginas/home/style";
import Botao from "../../../../componentes/botao";
import BotaoRetornarListagem from "../../../../componentes/botaoRetornarListagem";
import MaterialInputTexto from "../../../../componentes/inputTexto/materialInput";
import InputData from "../../../../componentes/inputTextoData";
import MaterialInputMascara from "../../../../componentes/inputTextoMascara";
import Loader from "../../../../componentes/loader";
import SelectArredondado from "../../../../componentes/selectArredondado";
import SelectAutoComplete from "../../../../componentes/selectAutocomplete";
import MaterialSwitch from "../../../../componentes/switch";

// Serviços
import { listarColaboradoresPorArvoreGerencial } from "../../../../servicos/colaboradoresServico";
import { buscarDadosCadastraisPorCnpj } from "../../../../servicos/dadosCadastraisCnpjServico";
import {
  digitalizacaoFatura,
  obterDadosFaturaDigitalizada
} from "../../../../servicos/digitalizacaoServico";
import {
  buscarDistribuidoraNomeOuCnpj,
  buscarDistribuidoras
} from "../../../../servicos/distribuidorasServico";
import { buscarPorId } from "../../../../servicos/usuariosServico";

// Styles
import { useStyles } from "./style";

// Redux
import { store } from "../../../../global/redux";
import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";
import {
  desabilitarCliente,
  desabilitarEmpresa,
  desabilitarUnidade,
  selecionarEmpresa
} from "../../../../global/redux/modulos/usuario/actions";
import statusCotacao from "../../constantes/constanteStatusCotacao";
import CotacaoInicialHelper from "./helper";

// DTO
import { RotasDTO } from "../../../../global/rotas/rotasUrlDto";

const CotacaoIncialCadastroMobile = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const { register, errors, handleSubmit } = useForm({
    reValidateMode: "onSubmit"
  });

  const [dadosCotacaoEdicao, setDadosCotacaoEdicao] = useState(null);
  const [carregando, setCarregando] = useState(false);
  const [status, setStatus] = useState("AT");
  const [checkedRadioConsumo, setCheckedRadioConsumo] = useState(true);
  const [numeroCNPJ, setCNPJ] = useState("");
  const [consumoMedioConta, setConsumoMedioConta] = useState("");
  const [demandaContratada, setDemandaContratada] = useState();
  const [progress, setProgress] = useState(0);
  const [carregandoArquivo, setCarregandoArquivo] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [msgLoading, setMsgLoading] = useState("");
  const [loading, setLoading] = useState(false);
  const [dataUri, setDataUri] = useState("");
  const [consumoForaPonta, setConsumoForaPonta] = useState("");
  const [consumoPonta, setConsumoPonta] = useState("");
  const [demandaForaPonta, setdemandaForaPonta] = useState("");
  const [demandataPonta, setDemandaPontaFatura] = useState("");
  const [demandaPonta, setDemandaPonta] = useState();
  const [dataMigracao, setDataMigracao] = useState(null);
  const [aumentarDemanda, setAumentarDemanda] = useState(false);
  const [modalidade, setModalidade] = useState("VE");
  const [limparCNPJ, setLimparCNPJ] = useState(false);

  useEffect(() => {
    if (demandaPonta > 0 && demandaContratada > 0) {
      setModalidade("AZ");
    } else if (demandaContratada > 0) {
      setModalidade("VE");
    } else {
      setModalidade("VE");
    }

    if (demandaPonta < 500 && demandaPonta > 0) {
      if (demandaContratada >= 500) {
        setAumentarDemanda(false);
      } else {
        setAumentarDemanda(true);
      }
    } else if (demandaContratada < 500 && demandaContratada > 0) {
      if (demandaPonta >= 500) {
        setAumentarDemanda(false);
      } else {
        setAumentarDemanda(true);
      }
    }

    if (dataMigracao && (demandaContratada < 500 || demandaPonta < 500)) {
      const date = moment(dataMigracao).isAfter("2023/12/31");
      if (date) {
        setAumentarDemanda(false);
      } else {
        setAumentarDemanda(true);
      }
    }

    if (
      dataMigracao === "" &&
      demandaContratada === "" &&
      demandaPonta === ""
    ) {
      setAumentarDemanda(false);
    } else if (demandaContratada >= 500 || demandaPonta >= 500) {
      setAumentarDemanda(false);
    } else if (!dataMigracao && !demandaContratada && !demandaPonta) {
      setAumentarDemanda(false);
    } else if (
      dataMigracao &&
      demandaContratada === "" &&
      demandaPonta === ""
    ) {
      setAumentarDemanda(false);
    } else if (demandaContratada === undefined && demandaPonta === "") {
      setAumentarDemanda(false);
    } else if (demandaPonta === undefined && demandaContratada === "") {
      setAumentarDemanda(false);
    }
  }, [dataMigracao, demandaContratada, demandaPonta]);

  // Officer
  const [colaboradorId, setColaboradorId] = useState();
  const [listaOfficer, setListaOfficer] = useState([]);
  const [carregandoOfficer, setCarregandoOfficer] = useState(false);

  // Consumidor Livre
  const [consumidorLivre, setConsumidorLivre] = useState(false);
  const [tradicionalVarejista, setTradicionalVarejista] = useState(false);
  const [opcoesConsumidorLivre, setOpcoesConsumidorLivre] = useState(null);
  const onChangeConsumidorLivre = (valor) => {
    setConsumidorLivre(valor);
  };

  const onChangeTradicionalVarejista = (valor) => {
    setTradicionalVarejista(valor);
  };

  // Unidade Cnpj
  const [unidadeCnpj, setUnidadeCnpj] = useState(false);
  const [opcoesUnidadeCnpj, setOpcoesUnidadeCnpj] = useState(null);
  const onChangeUnidadeCnpj = (valor) => {
    setUnidadeCnpj(valor);
  };

  // Desconto baseado
  const [descontoBaseado, setDescontoBaseado] = useState(0);
  const [listaDescontoBaseado, setListaDescontoBaseado] = useState([]);
  const itensDescontoBaseado = useMemo(() => {
    if (
      !listaDescontoBaseado ||
      !listaDescontoBaseado.length ||
      listaDescontoBaseado.length === 0
    )
      return;

    if (!descontoBaseado || String(descontoBaseado) === "0")
      return listaDescontoBaseado.map((x, i) => {
        x.checked = x.valor === "1";

        return x;
      });

    return listaDescontoBaseado.map((x) => {
      x.checked = String(x.valor) === String(descontoBaseado);

      return x;
    });
  }, [listaDescontoBaseado, descontoBaseado]);

  const descontoSelecionado = useMemo(() => {
    if (!itensDescontoBaseado || itensDescontoBaseado.length === 0) return null;

    return itensDescontoBaseado.find((x) => x.checked);
  }, [itensDescontoBaseado]);

  const custoFinanceiroSelecionado = useMemo(() => {
    if (!descontoSelecionado || descontoSelecionado == null) return false;

    return (
      String(descontoSelecionado.valor) ===
      String(cotacaoTipoCalculo.valorMedio)
    );
  }, [descontoSelecionado]);

  const rotas = useSelector((state) => state.rotas);
  const usuario = useSelector((state) => state.usuario);
  const { menu } = usuario;

  useEffect(() => {
    store.dispatch(desabilitarCliente(true));
    store.dispatch(desabilitarUnidade(true));

    return () => {
      store.dispatch(desabilitarEmpresa(false));
      store.dispatch(desabilitarCliente(false));
      store.dispatch(desabilitarUnidade(false));
    };
  }, []);

  const filtroOfficer = useMemo(() => {
    const rota = Object.keys(RotasDTO).find(
      (key) => RotasDTO[key] === history?.location?.pathname
    );

    if (menu?.length && rota) {
      const funcionalidade = menu.find((item) =>
        item.functionality.funcionalidadeColecao.find(
          (funcionalidade) => funcionalidade.caminho === rota
        )
      );

      if (!funcionalidade) return false;

      if (funcionalidade?.functionality?.funcionalidadeColecao?.length) {
        const segmento = funcionalidade?.functionality?.funcionalidadeColecao.find(
          (item) => RotasDTO[item.caminho] === RotasDTO.CotacaoIncial
        );

        if (!segmento) return false;

        if (segmento?.funcionalidadeColecao?.length) {
          const filtro = segmento?.funcionalidadeColecao.find(
            (item) =>
              InterfaceDTO[item.caminho] ===
              InterfaceDTO.PodeAlterarFiltrarOfficer
          );

          return Boolean(filtro);
        }
      }
    }
  }, [menu, history]);

  const obterOpcoesSimNao = useCallback(() => {
    setOpcoesConsumidorLivre(CotacaoInicialHelper.obterOpcoesSimNao());
    setOpcoesUnidadeCnpj(CotacaoInicialHelper.obterOpcoesSimNao());
  }, [setOpcoesConsumidorLivre]);

  const obterListaDescontoBaseado = useCallback(() => {
    setListaDescontoBaseado(CotacaoInicialHelper.obterDescontoBaseado());
  }, [setListaDescontoBaseado]);

  const onChangeDescontoBaseado = (e) => {
    setDescontoBaseado(parseInt(e));
  };

  const onChangeDescontoBaseadoRadio = (e) => {
    setDescontoBaseado(parseInt(e?.target?.value));
  };

  const obterDadosColaborador = async (idUsuario) => {
    try {
      setCarregandoOfficer(true);
      const dados = await buscarPorId(idUsuario);
      if (dados?.data) {
        setListaOfficer([
          {
            id: dados?.data?.usuario?.colaboradorId,
            nomeColaborador: dados?.data?.usuario?.nome
          }
        ]);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    } finally {
      setCarregandoOfficer(false);
    }
  };

  const { empresaSelecionada } = usuario;

  useEffect(() => {
    if (filtroOfficer) obterOfficer(empresaSelecionada);
    else if (usuario?.usuario?.id) obterDadosColaborador(usuario?.usuario?.id);
  }, [filtroOfficer]);

  const obterOfficer = async (empresa) => {
    try {
      setCarregandoOfficer(true);
      const lista = await listarColaboradoresPorArvoreGerencial(empresa ?? 0);
      if (lista?.status === 200 && lista?.data) setListaOfficer(lista?.data);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    } finally {
      setCarregandoOfficer(false);
    }
  };

  useEffect(() => {
    if (listaOfficer?.length) setColaboradorId(usuario?.usuario?.colaboradorId);
  }, [listaOfficer]);

  const onChangeOfficer = (officer) => {
    setColaboradorId(officer.target.value);
  };

  const [unidadesCnpj, setUnidadesCnpj] = useState(false);

  const formataValor = (valor) => {
    if (typeof valor === "number") return valor;
    if (!valor) return "";

    return valor
      .replace("R$ ", "")
      .replace(" kW", "")
      .replace(" MWh", "")
      .replace(" MWm", "")
      .replace(".", "")
      .replace(",", ".")
      .replace(" ", "");
  };

  // Demanda Contratada KW
  const onChangeDemandaContratadaKW = (demandaContratadaKW) => {
    setUnidadesCnpj(
      formataValor(demandaContratadaKW.target.value) &&
        formataValor(demandaContratadaKW.target.value) < 500
    );
  };

  // Nome Fantasia
  const [nomeFantasia, setNomeFantasia] = useState();
  const [carregandoNomeFantasia, setCarregandoNomeFantasia] = useState(false);

  // Distribuidora
  const [distribuidoraId, setDistribuidoraId] = useState();

  // Distribuidora
  const [listaDistribuidora, setListaDistribuidora] = useState([]);
  const [carregandoDistribuidora, setCarregandoDistribuidora] = useState(false);

  const isEdicao = useMemo(() => {
    return id && status !== statusCotacao.Expirada;
  }, [id, status]);

  const edicaoAberta = useMemo(() => {
    return id && status === statusCotacao.Aberta;
  });

  const obterDistribuidora = async () => {
    try {
      setCarregandoDistribuidora(true);
      const lista = await buscarDistribuidoras();
      if (lista?.status === 200 && lista?.data) {
        setListaDistribuidora(lista?.data);
      }
      setCarregandoDistribuidora(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoDistribuidora(false);
    }
  };

  useEffect(() => {
    obterDistribuidora();
  }, []);

  const [dadosCnpj, setDadosCnpj] = useState();

  const verificarCnpj = useCallback(async (valor) => {
    const cnpjFormatado =
      valor?.target?.value?.replace(/[^a-zA-Z0-9]/g, "") ?? valor;
    setDadosCnpj(cnpjFormatado);

    if (cnpjFormatado.length === 14) {
      try {
        setCarregandoNomeFantasia(true);
        const dadosCadastrais = await buscarDadosCadastraisPorCnpj(
          cnpjFormatado
        );

        if (dadosCadastrais?.status === 200 && dadosCadastrais?.data) {
          setDadosCnpj(dadosCadastrais.data.data);
          setNomeFantasia(
            dadosCadastrais.data.data.nomeFantasia ||
              dadosCadastrais.data.data.razaoSocial
          );
          if (
            dadosCadastrais?.data?.data?.situacaoCadastral !== "2" &&
            dadosCadastrais?.data?.data?.descricaoDaSituacaoCadastral !==
              "Ativa"
          ) {
            store.dispatch(
              alertaExibir({
                tipo: "warning",
                mensagem:
                  "CNPJ inserido encontra-se irregular na Receita Federal"
              })
            );
          }
        }
        setCarregandoNomeFantasia(false);
      } catch (error) {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem:
              error?.response?.data?.message ??
              "CNPJ não encontrado na Receita Federal"
          })
        );
        setCarregandoNomeFantasia(false);
      }
    }
  }, []);

  const obterCotacaoPorId = useCallback(async () => {
    if (!id) return;

    setCarregando(true);

    const resultado = await CotacaoInicialHelper.obterCotacaoPorId(id);
    if (!resultado.sucesso) {
      CotacaoInicialHelper.exibirErro(resultado.mensagem);
      setCarregando(false);
      history.push(RotasDTO.ListagemCotacoes);
      return;
    }

    const { data: cotacaoBanco } = resultado;

    setDadosCotacaoEdicao(cotacaoBanco);
    setDataMigracao(cotacaoBanco.dataMigracao);

    setDistribuidoraId(cotacaoBanco.distribuidoraId);
    setConsumidorLivre(cotacaoBanco.consumidorLivre);
    setTradicionalVarejista(cotacaoBanco.tradicionalVarejista === "VT");
    setColaboradorId(cotacaoBanco.colaboradorId);
    setStatus(cotacaoBanco.status);
    setDemandaContratada(cotacaoBanco.demandaContratada);
    setDemandaPonta(cotacaoBanco.demandaPonta);
    setUnidadesCnpj(cotacaoBanco.unidadeCnpj);
    setDescontoBaseado(cotacaoBanco.tipoCalculo);
    setUnidadeCnpj(cotacaoBanco.unidadeCnpj);
    setDadosCnpj(cotacaoBanco.cnpj);
    store.dispatch(selecionarEmpresa(cotacaoBanco.empresaId));

    setCarregando(false);
  }, [id]);

  useEffect(() => {
    obterCotacaoPorId();
  }, [obterCotacaoPorId]);

  useEffect(() => {
    obterListaDescontoBaseado();
    obterOpcoesSimNao();
  }, [obterListaDescontoBaseado, obterOpcoesSimNao]);

  const formatarDadosCotacao = (dados) => {
    const cnpjFormatado =
      dados?.cnpj?.replace(/[^a-zA-Z0-9]/g, "") ||
      dadosCotacaoEdicao?.cnpj?.replace(/[^a-zA-Z0-9]/g, "");

    const tradicionalVarejistaSelecionado = tradicionalVarejista ? "VT" : "TR";

    return {
      DistribuidoraId: distribuidoraId,
      ConsumidorLivre: consumidorLivre,
      tradicionalVarejista:
        tradicionalVarejistaSelecionado ||
        dadosCotacaoEdicao?.tradicionalVarejista,

      UnidadeCnpj: unidadeCnpj || "false",
      Cnpj: cnpjFormatado,
      NomeFantasia: dados?.nomeFantasia || dadosCotacaoEdicao?.nomeFantasia,
      ValorMedioConta:
        ((dados?.valorMedio ?? 0.0) && formataValor(dados?.valorMedio)) ||
        ((dadosCotacaoEdicao?.valorMedioConta ?? 0.0) &&
          formataValor(dadosCotacaoEdicao?.valorMedioConta)),
      ConsumoForaPonta:
        ((dados?.consumoForaPonta ?? 0.0) &&
          formataValor(dados?.consumoForaPonta)) ||
        ((dadosCotacaoEdicao?.consumoForaPonta ?? 0.0) &&
          formataValor(dadosCotacaoEdicao?.consumoForaPonta)),
      ConsumoPonta:
        ((dados?.consumoPonta ?? 0.0) && formataValor(dados?.consumoPonta)) ||
        ((dadosCotacaoEdicao?.consumoPonta ?? 0.0) &&
          formataValor(dadosCotacaoEdicao?.consumoPonta)),
      DemandaContratada:
        (dados?.demandaContratadaKW &&
          formataValor(dados?.demandaContratadaKW)) ||
        (dadosCotacaoEdicao?.demandaContratada &&
          formataValor(dadosCotacaoEdicao?.demandaContratada)),

      DemandaPonta: demandaPonta || dados?.demandaPonta,

      DataMigracao: dataMigracao || dados?.dataMigracao,
      Modalidade: modalidade || dados?.modalidade,

      ColaboradorId: colaboradorId || dadosCotacaoEdicao?.colaboradorId,
      EmpresaId: usuario?.empresaSelecionada || dadosCotacaoEdicao?.empresaId,
      Status: !isEdicao ? "AT" : status,
      TipoCalculo: descontoSelecionado.valor || dadosCotacaoEdicao?.tipoCalculo
    };
  };

  const gerarPlanosSimulados = async (cotacaoId) => {
    const resultado = await CotacaoInicialHelper.simularPlanos(cotacaoId);

    if (!resultado.sucesso) CotacaoInicialHelper.exibirErro(resultado.mensagem);
  };

  const enviarFormulario = async (dados) => {
    try {
      if (isEdicao && !edicaoAberta) return;
      if (usuario.empresaSelecionada === "") {
        CotacaoInicialHelper.exibirErro("É necessário selecionar uma Empresa");
        return;
      }

      if (!distribuidoraId) {
        CotacaoInicialHelper.exibirErro(
          "É necessário selecionar uma Distribuidora"
        );
        return;
      }

      if (!descontoSelecionado || descontoSelecionado == null) {
        CotacaoInicialHelper.exibirErro(
          "É necessário selecionar o tipo de calculo do desconto!"
        );
        return;
      }

      const dadosCotacao = formatarDadosCotacao(dados);

      if (!dadosCotacao) return;

      const resultado = await CotacaoInicialHelper.salvarCotacao(
        dadosCotacao,
        isEdicao,
        id
      );

      if (!resultado.sucesso) {
        CotacaoInicialHelper.exibirErro(resultado.mensagem);
        return;
      }

      if (!isEdicao || edicaoAberta)
        await gerarPlanosSimulados(resultado.data.id);

      history.push(`/cotacao/planos/${resultado.data.id}`, {
        dadosCotacaoInicial: resultado.data,
        dadosCnpj
      });
    } catch (error) {
      CotacaoInicialHelper.exibirErro(
        "Erro interno, entre em contato com o suporte!"
      );
    }
  };

  const aoEnviarFormulario = (dados) => {
    enviarFormulario(dados);
  };

  const onSalvarClick = (event) => {
    // if (!isEdicao || edicaoAberta) return;
    // event.preventDefault();
    // const dadosProximaTela = {
    //   id,
    //   cnpj: dadosCotacaoEdicao?.cnpj.replace(/[^a-zA-Z0-9]/g, ""),
    //   isEdicao: isEdicao
    // };
    // history.push(`/cotacao/planos/${id}`, {
    //   dadosCotacaoInicial: dadosProximaTela,
    //   dadosCnpj: {
    //     cnpj: dadosCotacaoEdicao?.cnpj.replace(/[^a-zA-Z0-9]/g, "")
    //   }
    // });
  };

  const location = useLocation();

  useEffect(() => {
    if (location.state !== undefined) {
      setCNPJ(location.state?.cliente?.cnpj?.replace(/[^0-9]/g, ""));
      setNomeFantasia(location.state?.cliente?.nome);

      if (location.state?.faturaHistoricos?.length > 0) {
        // getConsumoFaturaHistoricos(location.state?.faturaHistoricos)
        setConsumoMedioConta(`${location.state?.consumo} MWh`);
      } else {
        setConsumoMedioConta(`${location.state?.consumo} MWh`);
        // getConsumoFaturaItens(location.state?.faturaItens)
      }
      // getDemanda(location.state?.faturaItens)
      setDemandaContratada(`${location.state?.demanda} kW`);
      setDistribuidoraId(1241);
    }
  }, [location]);

  const getConsumoFaturaHistoricos = (dados) => {
    let sumHoraPonta = 0;
    let sumHoraForaPonta = 0;
    dados.map((item, index) => {
      sumHoraPonta += item.horaPonta;
      sumHoraForaPonta += item.horaForaPonta;
    });
    const totalSoma = sumHoraPonta + sumHoraForaPonta;
    const divisaoSoma = totalSoma / dados.length;
    const contaFinal = divisaoSoma / 1000;
    setConsumoMedioConta(Math.round(contaFinal));
  };

  const getDemanda = (dados) => {
    let sumOffPeak1 = 0;
    let sumOffPeak2 = 0;
    dados.map((item, index) => {
      if (
        item.tipo === "demand" &&
        item.modelo === "TUSD" &&
        item.periodo === "off-peak"
      ) {
        sumOffPeak1 = item.faturada;
      }
      if (
        item.tipo === "demand" &&
        item.modelo === "TUSD" &&
        item.periodo === "off-peak-not-icms"
      ) {
        sumOffPeak2 = item.faturada;
      }
    });
    const totalSoma = sumOffPeak1 + sumOffPeak2;
    setDemandaContratada(totalSoma);
  };

  const getConsumoFaturaItens = (dados) => {
    let sumPeak1 = 0;
    let sumPeak2 = 0;
    dados.map((item, index) => {
      if (
        item.tipo === "energy" &&
        item.modelo === "TUSD" &&
        item.periodo === "peak"
      ) {
        sumPeak1 = item.faturada;
      }
      if (
        item.tipo === "energy" &&
        item.modelo === "TUSD" &&
        item.periodo === "off-peak"
      ) {
        sumPeak2 = item.faturada;
      }
    });
    const totalSoma = sumPeak1 + sumPeak2;
    const divisaoSoma = totalSoma / 1000;
    setConsumoMedioConta(Math.round(divisaoSoma));
  };

  const getProgress = (nomeArquivo, auxProgress) => {
    setCarregandoArquivo(true);
    setShowLoader(true);

    if (auxProgress >= 100) {
      setShowLoader(false);
      setCarregandoArquivo(false);
    }
  };

  const retornoFatura = async (externoId) => {
    try {
      const fatura = await obterDadosFaturaDigitalizada(externoId);
      setProgress(50);
      getProgress("arquivo", 50);

      if (JSON.stringify(fatura.fornecedor) === "null") {
        setMsgLoading("Erro ao processar fatura.");
        setTimeout(() => {
          setLoading(false);
        }, 3000);
      } else {
        setMsgLoading("Sucesso!");
        verificarCnpj(fatura?.cliente.cnpj);
        setDadosCnpj(fatura?.cliente.cnpj);
        setNomeFantasia(fatura?.cliente?.nome);
        setConsumoForaPonta((fatura?.consumoForaPonta / 1000).toFixed(3));
        setConsumoPonta((fatura?.consumoPonta / 1000).toFixed(3));
        setdemandaForaPonta(
          Math.max(fatura?.demandaForaPonta, fatura?.demanda).toFixed(0)
        );
        setDemandaPontaFatura((fatura?.demandaPonta).toFixed(0));

        const distribuidora = await buscarDistribuidoraNomeOuCnpj(
          fatura?.fornecedorCnpj.replace(/[^a-zA-Z0-9]/g, "")
        );
        if (distribuidora?.status === 200) {
          setDistribuidoraId(distribuidora?.data?.id);
        }
      }
    } catch (error) {
      setMsgLoading("Erro ao processar fatura.");
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
    setProgress(100);
    getProgress("arquivo", 100);
  };

  const onAddUpload = async (valor) => {
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem: "O arquivo está sendo digitalizado Aguarde !"
      })
    );
    setMsgLoading("Efetuando upload da fatura...");
    setProgress(5);
    getProgress("arquivo", 0);
    try {
      setLoading(true);
      const fileBytes = valor[0].data.split(",")[1];
      const externoId = Math.floor(1000 + Math.random() * 9000);
      const fileName = valor[0]?.file?.name;

      const retornoDigitalizacao = await digitalizacaoFatura(
        fileBytes,
        fileName,
        externoId,
        colaboradorId,
        empresaSelecionada || "32",
        distribuidoraId,
        "importar-earq"
      );
      if (retornoDigitalizacao?.statusCodes === 200) {
        retornoFatura(externoId);
        setMsgLoading("Lendo dados da fatura...");
      } else {
        setMsgLoading("Erro ao digitalizar fatura");
        setTimeout(() => {
          setLoading(false);
        }, 3000);
      }
    } catch (error) {
      setMsgLoading("Erro ao efetuar upload");
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  };

  const onDropRejected = (valor) => {
    const arquivo = valor[0];
    store.dispatch(
      alertaExibir({
        tipo: "warning",
        mensagem: `Tipo do arquivo ${arquivo.name} não é permitido!`
      })
    );
  };

  return (
    <Loader loading={carregando}>
      <Box className={classes.barraSuperior}>
        <IconButton
          className={classes.arrowBack}
          onClick={() => history.goBack()}
          size="small"
        >
          <ArrowBackIosIcon fontSize="small" />
        </IconButton>
        Dados Energia
      </Box>
      <form
        className="needs-validation"
        onSubmit={handleSubmit(aoEnviarFormulario)}
      >
        <Card className={`${classes.container} ${classes.fundoPreto}`}>
          <Grid container spacing={4} className={classes.container}>
            <Grid item lg={6} md={9} sm={12} xs={12}>
              <Loader loading={carregandoOfficer}>
                <SelectArredondado
                  id="colaboradorId"
                  name="colaboradorId"
                  valueKey="id"
                  valueName="nomeColaborador"
                  dataSource={listaOfficer}
                  label="Officer"
                  value={colaboradorId}
                  onChange={(colaborador) => onChangeOfficer(colaborador)}
                  placeholder="Officer"
                  allowClear
                  ref={register(
                    { name: "colaboradorId" },
                    {
                      required: !colaboradorId
                        ? "Campo Officer é obrigatório!"
                        : false
                    }
                  )}
                  errors={errors}
                  className={classes.selectOfficer}
                  labelClassName={classes.selectOfficer}
                />
              </Loader>
            </Grid>

            <Grid item lg={6} md={9} sm={12} xs={12}>
              <Box border="1px solid #D2D2D7" borderRadius="8px">
                <UploadCustomizado
                  dropAreaText="Clique aqui para anexar a fatura"
                  loader={carregandoArquivo}
                  onAdd={onAddUpload}
                  progressValue={progress}
                  showLoader={showLoader}
                  onDropRejected={onDropRejected}
                  acceptedFiles={["image/*", "application/pdf"]}
                />
              </Box>
            </Grid>

            <Grid item lg={6} md={9} sm={12} xs={12}>
              <MaterialInputMascara
                type="text"
                id="cnpj"
                name="cnpj"
                label="CNPJ"
                mask="##.###.###/####-##"
                renderIconShowHide={false}
                disabled={isEdicao}
                onBlur={(valor) => verificarCnpj(valor)}
                defaultValue={dadosCnpj}
                limparValor={limparCNPJ}
                redefineLimpar={(estado) => setLimparCNPJ(estado)}
                className={classes.selectOfficer}
                ref={register({
                  required: "Campo CNPJ é obrigatório!",
                  maxLength: {
                    value: 18,
                    message: "Quantidade máxima de 18 caracteres!"
                  },
                  minLength: {
                    value: 11,
                    message: "Quantidade mínima de 11 caracteres!"
                  },
                  validate: (value) => cnpj.isValid(value) || "CNPJ inválido!"
                })}
                errors={errors}
              />
            </Grid>

            <Grid item lg={6} md={9} sm={12} xs={12}>
              <Loader loading={carregandoNomeFantasia}>
                <MaterialInputTexto
                  type="text"
                  id="nomeFantasia"
                  name="nomeFantasia"
                  disabled={isEdicao}
                  label="Nome fantasia"
                  renderIconShowHide={false}
                  defaultValue={
                    nomeFantasia || dadosCotacaoEdicao?.nomeFantasia
                  }
                  className={classes.selectOfficer}
                  ref={register({
                    required: "Campo Nome Fantasia é obrigatório!",
                    maxLength: {
                      value: 100,
                      message: "Quantidade máxima de 100 caracteres!"
                    }
                  })}
                  errors={errors}
                />
              </Loader>
            </Grid>

            <Grid item lg={6} md={9} sm={12} xs={12}>
              <Loader loading={carregandoDistribuidora}>
                <SelectAutoComplete
                  disableClearable
                  disableOpenOnFocus
                  id="distribuidoraId"
                  name="distribuidoraId"
                  disabled={isEdicao && !edicaoAberta}
                  options={listaDistribuidora}
                  label="Distribuidora"
                  getOptionLabel={(option) => option?.nomeFantasia ?? ""}
                  onChange={(e, item) => {
                    setDistribuidoraId(item?.id ?? 0);
                  }}
                  value={distribuidoraId}
                  className={classes.selectOfficer}
                  ref={register(
                    { name: "distribuidoraId" },
                    {
                      required: !distribuidoraId
                    }
                  )}
                  error={errors}
                  allowClear
                  valueName="nomeFantasia"
                />
              </Loader>
            </Grid>

            <Grid item lg={3} md={6} sm={12} xs={12}>
              <MaterialInputMascara
                type="text"
                id="consumoForaPonta"
                name="consumoForaPonta"
                label="Consumo Fora Ponta (MWh)"
                sufixo=" MWh"
                prefixo=""
                disabled={isEdicao && !edicaoAberta}
                renderIconShowHide={false}
                defaultValue={
                  dadosCotacaoEdicao?.consumoForaPonta || consumoForaPonta
                }
                ref={register({
                  required: "Campo Consumo Fora Ponta é obrigatório!",
                  maxLength: {
                    value: 18,
                    message: "Quantidade máxima de 18 caracteres!"
                  },
                  minLength: {
                    value: 1,
                    message: "Quantidade mínima de 1 caracteres!"
                  }
                })}
                errors={errors}
              />
            </Grid>

            <Grid item lg={3} md={6} sm={12} xs={12}>
              <MaterialInputMascara
                type="text"
                id="consumoPonta"
                name="consumoPonta"
                label="Consumo Ponta (MWh)"
                sufixo=" MWh"
                prefixo=""
                disabled={isEdicao && !edicaoAberta}
                renderIconShowHide={false}
                defaultValue={
                  dadosCotacaoEdicao?.consumoPonta || consumoPonta || ""
                }
                ref={register({
                  required: "Campo Consumo Ponta é obrigatório!",
                  maxLength: {
                    value: 18,
                    message: "Quantidade máxima de 18 caracteres!"
                  },
                  minLength: {
                    value: 1,
                    message: "Quantidade mínima de 1 caracteres!"
                  }
                })}
                errors={errors}
              />
            </Grid>

            <Grid item lg={3} md={6} sm={12} xs={12}>
              <MaterialInputMascara
                type="text"
                id="demandaContratadaKW"
                name="demandaContratadaKW"
                label="Demanda contratatada fora ponta (kW)"
                sufixo=" kW"
                defaultValue={
                  dadosCotacaoEdicao?.demandaContratada ||
                  demandaForaPonta ||
                  ""
                }
                renderIconShowHide={false}
                disabled={isEdicao && !edicaoAberta}
                onBlur={(valor) =>
                  setDemandaContratada(formataValor(valor?.target?.value))
                }
                ref={register({
                  required:
                    "Campo demanda contratatada Fora Ponta (kW) é obrigatório!",
                  maxLength: {
                    value: 18,
                    message: "Quantidade máxima de 18 caracteres!"
                  },
                  minLength: {
                    value: 1,
                    message: "Quantidade mínima de 1 caracteres!"
                  }
                })}
                errors={errors}
              />
            </Grid>

            <Grid item lg={3} md={6} sm={12} xs={12}>
              <MaterialInputMascara
                type="text"
                id="demandaPonta"
                name="demandaPonta"
                label="Demanda contratada ponta (kW)"
                sufixo=" kW"
                defaultValue={
                  dadosCotacaoEdicao?.demandaPonta || demandataPonta || ""
                }
                renderIconShowHide={false}
                disabled={isEdicao && !edicaoAberta}
                onBlur={(valor) =>
                  setDemandaPonta(formataValor(valor?.target?.value))
                }
                errors={errors}
              />
            </Grid>

            <Grid item lg={3} md={3} sm={12} xs={12}>
              <InputData
                type="text"
                id="dataMigracao"
                name="dataMigracao"
                label="Data prevista de migração"
                customValue={dataMigracao}
                minDate={window.moment().subtract(90, "year")}
                onBlur={(e) => setDataMigracao(e.target.value)}
                renderIconShowHide
                errors={errors}
                className={classes}
                InputProps={{ readOnly: false }}
                variant="inline"
                autoOk
              />
            </Grid>

            {aumentarDemanda ? (
              <Grid item lg={6} md={9} sm={12} xs={12}>
                {/* <InputRadioGroup
                  type="text"
                  id="unidadeCnpj"
                  name="unidadeCnpj"
                  label="Deseja aumentar a demanda?"
                  renderIconShowHide={false}
                  disabled={isEdicao && !edicaoAberta}
                  customValue={unidadeCnpj}
                  onChange={(valor) =>
                    onChangeUnidadeCnpj(valor?.target?.value)
                  }
                  defaultValue={unidadeCnpj}
                  errors={errors}
                  className={classes.inputRadio}
                  classNameLabel={classes.inputRadioLabel}
                  itens={listaOpcoesUnidadeCnpj}
                /> */}
                <Box display="flex">
                  <Box component="label" mr={2} mt={1}>
                    Deseja aumentar a demanda?
                  </Box>
                  <Box>
                    <MaterialSwitch
                      labelPlacement="start"
                      checked={unidadeCnpj}
                      onChange={(e) => onChangeUnidadeCnpj(e)}
                      // disabled={isEdicao && !edicaoAberta}
                    />
                  </Box>
                </Box>
              </Grid>
            ) : null}

            <Grid item xs={7} className={classes.showMobile}>
              <MaterialSwitch
                labelPlacement="start"
                checked={consumidorLivre}
                onChange={(e) => onChangeConsumidorLivre(e)}
                label={
                  <Box mr={4} className={classes.checked}>
                    Consumidor livre?
                  </Box>
                }
                // disabled={isEdicao}
              />
            </Grid>

            <Grid item xs={5} className={classes.showMobile}>
              <MaterialSwitch
                labelPlacement="start"
                checked={tradicionalVarejista}
                onChange={(e) => onChangeTradicionalVarejista(e)}
                label={
                  <Box mr={2} className={classes.checked}>
                    Varejista?
                  </Box>
                }
                // disabled={isEdicao}
              />
            </Grid>

            <Grid item lg={4} md={6} sm={12} xs={12}>
              <Botao
                type="submit"
                label="Ver os melhores planos"
                className={classes.button}
              />
            </Grid>
          </Grid>
        </Card>
      </form>
    </Loader>
  );
};

export default CotacaoIncialCadastroMobile;
