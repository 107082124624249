import { makeStyles } from "@mui/styles";
import styled from "styled-components";
import theme from "../../..";
import { Base } from "../../../../componentes/cores";

export const useStyles = makeStyles(() => ({
  card: {
    boxShadow: "0px 2px 4px 1px #00000029 !important"
  },
  titulo: {
    color: theme.color.primary,
    fontFamily: theme.font.family,
    fontSize: "24px",
    fontWeight: 700
  },
  subtitulo: {
    fontSize: "20px",
    color: theme.color.primary
  },
  texto: {
    color: theme.color.primary,
    display: "flex",
    marginBottom: 0
  },
  textoCinza: {
    color: "#9F9F9F",
    display: "flex",
    marginBottom: 5
  }
}));

export const BotaoEnviar = styled.button`
  color: ${Base.White};
  background-color: ${theme.color.secondary};
  border-color: transparent;
  border-radius: 24px;
  font-weight: 700;
  font-size: 16px;
  padding: 8px 32px;
  width: 70%;
`;

export const Stepper = styled.div`
    margin-top: auto;
    display: flex;
    justify-content: space-between;
  
  .stepper-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
  }
  
  .stepper-item::before {
    position: absolute;
    content: "";
    border-bottom: 6px solid #F5F5F7;
    width: 100%;
    top: 17px;
    left: -50%;
    z-index: 2;
  }
  
  .stepper-item::after {
    position: absolute;
    content: "";
    border-bottom: 6px solid #F5F5F7;
    width: 100%;
    top: 17px;
    left: 50%;
    z-index: 2;
  }
  
  .stepper-item .step-counter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 37px;
    height: 37px;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0px 2px 4px 1px #00000029;
    margin-bottom: 6px;
    font-weight: 700;
    color: ${theme.color.primary};
  }

  .stepper-item .step-label {
    text-align: center;
    color: ${theme.color.primary};
    font-size: 14px;
    max-width: 80px;

    @media (max-width: 1100px) {
        font-size: 12px;
      }
  }
  
  .stepper-item.completed .step-counter , .stepper-item.active .step-counter {
    background-color: ${theme.color.primary};
    color: #fff;
    box-shadow: none;
  }
  
  .stepper-item.completed::after {
    position: absolute;
    content: "";
    border-bottom: 6px solid ${theme.color.primary};
    width: 100%;
    top: 17px;
    left: 50%;
    z-index: 3;
  }
  
  .stepper-item:first-child::before {
    content: none;
  }
  .stepper-item:last-child::after {
    content: none;
  }
`;
