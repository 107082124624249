import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const api = () => obterApi(MICROSERVICO.TELEMETRIA);

export const buscarTodasCategorias = async (dadosMedidor) => {
  return api().get(
    `v1/DesagregacaoDeCarga/mensal?${new URLSearchParams(
      dadosMedidor
    ).toString()}`
  );

  // const reqURL = `http://k8s-albprod-60b8b4a033-392428929.us-east-1.elb.amazonaws.com/telemetria/api/v1/DesagregacaoDeCarga/mensal?${new URLSearchParams(dadosMedidor).toString()}`;
  // const APIKey = "170ab3ed-e288-4809-ac39-d37c17ef6d5e";
  // const res = await fetch(reqURL, {
  //   method: "GET",
  //   headers: {
  //     ApiKey: APIKey,
  //     accept: "text/plain",
  //     "accept-language": "pt-BR,pt;q=0.9,en-US;q=0.8,en;q=0.7",
  //     "content-type": "application/json"
  //   }
  // });

  // return res.json();
};

export const buscarGrupo = async (dadosMedidor) => {
  return api().get(
    `v1/DesagregacaoDeCarga/periodo?${new URLSearchParams(
      dadosMedidor
    ).toString()}`
  );

  // const reqURL = `http://k8s-albprod-60b8b4a033-392428929.us-east-1.elb.amazonaws.com/telemetria/api/v1/DesagregacaoDeCarga/periodo?${new URLSearchParams(dadosMedidor).toString()}`;
  // const APIKey = "170ab3ed-e288-4809-ac39-d37c17ef6d5e";
  // const res = await fetch(reqURL, {
  //   method: "GET",
  //   headers: {
  //     ApiKey: APIKey,
  //     accept: "text/plain",
  //     "accept-language": "pt-BR,pt;q=0.9,en-US;q=0.8,en;q=0.7",
  //     "content-type": "application/json"
  //   }
  // });

  // if (res.status === 204) {
  //   return res;
  // }
  // return res.json();
};
