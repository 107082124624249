/* eslint-disable import/no-unresolved */
/* eslint-disable eqeqeq */
import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import Loader from "componentes/loader";
import SelectArredondado from "componentes/selectArredondado";
import InputData from "componentes/inputTextoData";
import Botao from "componentes/botao";
import Grafico from "./grafico";
import { obterCenarios } from "../../../servicos/cenariosSeriesHistoricas";
import {
  listarSerieHistorica,
  buscarPorId,
  buscarUnidadesPorId,
  obterValoresSerieHistorica
} from "../../../servicos/seriesHistoricas";
import { Container } from "./grafico/style";
import { useStyles } from "./style";

const ConsultarSerieHistorica = () => {
  const { id } = useParams();
  const classes = useStyles();

  const [minDataInicio, setMinDataInicio] = useState();
  const [dataInicio, setDataInicio] = useState();
  const [dataFim, setDataFim] = useState();
  const [seriesHistoricas, setSeriesHistoricas] = useState([]);
  const [cenarios, setCenarios] = useState([]);
  const [cenario, setCenario] = useState();
  const [standard, setStandard] = useState("");
  const [codigo, setCodigo] = useState();
  const [apelido, setApelido] = useState();
  const [descricao, setDescricao] = useState();
  const [fonte, setFonte] = useState();
  const [periodicidade, setPeriodicidade] = useState();
  const [categoria, setCategoria] = useState();
  const [unidadeID, setUnidadeID] = useState();
  const [carregandoDados, setCarregandoDados] = useState(false);
  const [unidadeNome, setUnidadeNome] = useState();
  const [valoresParaGrafico, setValoresParaGrafico] = useState(null);
  const [carregandoDadosGrafico, setCarregandoDadosGrafico] = useState(false);

  const buscarValores = useCallback(async () => {
    try {
      setCarregandoDadosGrafico(true);
      const inicio =
        periodicidade == "Anual"
          ? `${moment(dataInicio).format("YYYY")}-01-01`
          : moment(dataInicio).format("YYYY-MM-DD");
      const fim =
        periodicidade == "Anual"
          ? `${moment(dataFim).format("YYYY")}-01-01`
          : moment(dataFim).format("YYYY-MM-DD");
      const response = await obterValoresSerieHistorica(
        id ?? codigo,
        inicio,
        fim,
        standard ?? cenario
      );

      if (response?.status === 200 && response?.data) {
        if (
          response?.data.length == 0 ||
          (response.data.length == 1 &&
            response.data[0].idSerieHistorica == 0) ||
          response.data.cotacoes.length == 0
        ) {
          setValoresParaGrafico(null);
          store.dispatch(
            alertaExibir({
              tipo: "warning",
              mensagem:
                "Não foram encontrados dados de cotação para a série selecionada."
            })
          );
        } else {
          setValoresParaGrafico(response?.data);
        }
      } else {
        setValoresParaGrafico(null);
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "Nenhum Registro encontrado"
          })
        );
      }
      setCarregandoDadosGrafico(false);
    } catch (error) {
      setValoresParaGrafico(null);
      setCarregandoDadosGrafico(false);
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  }, [id, codigo, standard, cenario, dataInicio, dataFim]);

  const handleSeriesHistoricas = async () => {
    try {
      const series = await listarSerieHistorica();
      if (series?.status === 200 && series?.data) {
        setSeriesHistoricas(series?.data);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  const handleCenarios = async () => {
    try {
      const lista = await obterCenarios();
      if (lista?.status === 200 && lista?.data) {
        const data = lista?.data;
        data.map((item) =>
          item.isStandard === 1 ? setStandard(item.id) : setStandard("")
        );

        setCenarios(lista?.data);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  const handleDadosSerie = useCallback(async (cdg) => {
    try {
      setCarregandoDados(true);
      const serie = await buscarPorId(cdg);
      if (serie?.status === 200 && serie?.data) {
        setCodigo(serie?.data.codigo);
        setApelido(serie?.data?.apelido);
        setDescricao(serie?.data?.descricao);
        setFonte(serie?.data?.fonte);
        setPeriodicidade(
          serie?.data?.periodicidade === "A" ? "Anual" : "Mensal"
        );
        setUnidadeID(serie?.data?.idUnidade);
        setCategoria(serie?.data?.descricaoCategoria);
        setCarregandoDados(false);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  }, []);

  const handleUnidadePorId = useCallback(async (idUnidade) => {
    try {
      const unidade = await buscarUnidadesPorId(idUnidade);
      if (unidade?.status === 200 && unidade?.data) {
        setUnidadeNome(unidade?.data?.descricao);
      }
    } catch (error) {
      console.info(error);
    }
  }, []);

  const handleDataInicio = (event) => {
    setDataInicio(event);
  };
  const handleDatafim = (event) => {
    setDataFim(event);
  };

  useEffect(() => {
    handleSeriesHistoricas();
    handleCenarios();
    setMinDataInicio(window.moment().subtract(50, "years").toDate());
    setDataInicio(window.moment().subtract(6, "months").toDate());
    setDataFim(window.moment().add(6, "months").toDate());
    if (id) handleDadosSerie(id);
  }, []);

  useEffect(() => {
    if (periodicidade == "Anual") {
      setDataInicio(window.moment().startOf("year").toDate());
      setDataFim(window.moment().add(4, "years").toDate());
    } else {
      setDataInicio(window.moment().subtract(6, "months").toDate());
      setDataFim(window.moment().add(6, "months").toDate());
    }
  }, [periodicidade]);

  useEffect(() => {
    if (id) handleDadosSerie(id);
  }, [id, handleDadosSerie]);

  useEffect(() => {
    if (unidadeID) handleUnidadePorId(unidadeID);
  }, [unidadeID, handleUnidadePorId]);

  useEffect(() => {
    if (codigo) handleDadosSerie(codigo);
  }, [codigo, handleDadosSerie]);

  useEffect(() => {
    if (id && standard) buscarValores();
  }, [id, standard, cenario, dataInicio, dataFim, buscarValores]);

  return (
    <>
      <Grid container p={2} className="mb-3">
        <Grid item sm={12}>
          <Container>
            <Grid container className="m-0 w-100" p={2} spacing={2}>
              <Grid item sm={3}>
                <Grid container className="m-0 w-100">
                  <Grid item sm={12} className={classes.dados}>
                    Série Histórica
                    <Grid item className={classes.formControl}>
                      <SelectArredondado
                        id="serieSelecionada"
                        name="serieSelecionada"
                        valueKey="codigo"
                        valueName="codigo"
                        dataSource={seriesHistoricas}
                        value={codigo}
                        onChange={(event) => {
                          setCodigo(event.target.value);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={4}>
                <Loader loading={carregandoDados}>
                  <Grid container className="m-0 w-100" p={2}>
                    <Grid item={12}>
                      <Grid item sm={12}>
                        {fonte ? `Fonte: ${fonte}` : "Fonte:"}
                      </Grid>
                      <Grid item sm={12}>
                        {periodicidade
                          ? `Periodicidade: ${periodicidade}`
                          : "Periodicidade:"}
                      </Grid>
                      <Grid item sm={12}>
                        {unidadeNome ? `Unidade: ${unidadeNome}` : "Unidade:"}
                      </Grid>
                      <Grid item sm={12}>
                        {categoria ? `Categoria: ${categoria}` : "Categoria:"}
                      </Grid>
                    </Grid>
                  </Grid>
                </Loader>
              </Grid>
              <Grid item sm={4}>
                <Loader loading={carregandoDados}>
                  <Grid container className="m-0 w-100" p={2} spacing={2}>
                    <Grid item={12}>
                      <Grid item sm={12}>
                        {apelido ?? ""}
                      </Grid>
                      <Grid item sm={12}>
                        {descricao ?? ""}
                      </Grid>
                    </Grid>
                  </Grid>
                </Loader>
              </Grid>
            </Grid>
            <Grid container className="m-0 w-100" p={2}>
              <Grid item sm={12}>
                <Grid container className="m-0 w-100" pb={2} spacing={2}>
                  <Grid item sm={3}>
                    <Grid item sm={12} className={classes.dados}>
                      Cenário
                      <Grid item className={classes.formControl}>
                        <SelectArredondado
                          id="cenarioSelecionado"
                          name="cenarioSelecionado"
                          valueKey="id"
                          valueName="nome"
                          dataSource={cenarios}
                          value={standard ?? cenario}
                          onChange={(event) => {
                            setCenario(event.target.value);
                            setStandard(null);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm={3} className={classes.container}>
                    <InputData
                      type="text"
                      id="mesInicio"
                      name="mesInicio"
                      minDate={minDataInicio}
                      customValue={dataInicio}
                      onChange={(valor) => handleDataInicio(valor)}
                      format={periodicidade == "Anual" ? "yyyy" : "dd/MM/yyyy"}
                      views={periodicidade == "Anual" ? ["year"] : ["year", "month", "day"]}
                      renderIconShowHide={false}
                    />
                  </Grid>
                  <Grid item sm={3} className={classes.container}>
                    <InputData
                      type="text"
                      id="mesFim"
                      name="mesFim"
                      customValue={dataFim}
                      minDate={dataInicio}
                      onChange={(valor) => handleDatafim(valor)}
                      format={periodicidade == "Anual" ? "yyyy" : "dd/MM/yyyy"}
                      views={periodicidade == "Anual" ? ["year"] : ["year", "month", "day"]}
                      renderIconShowHide={false}
                    />
                  </Grid>
                  <Grid item sm={2} className={classes.container}>
                    <Botao
                      type="submit"
                      label="Buscar"
                      className={classes.button}
                      onClick={buscarValores}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
      <Grid container p={2} className="mb-3">
        <Grid item sm={12}>
          <Container>
            <Loader loading={carregandoDadosGrafico}>
              <Grafico unidade={unidadeNome} data={valoresParaGrafico ?? []} />
            </Loader>
          </Container>
        </Grid>
      </Grid>
    </>
  );
};

export default ConsultarSerieHistorica;
