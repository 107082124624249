import { makeStyles } from "@mui/styles";
import theme from "themes";

export const useStyles = makeStyles({
  contratosTela: {
    minHeight: "200px"
  },
  title: {
    fontSize: "24px",
    fontWeight: "700",
    color: theme.color.botaoDownloadFatura
  },
  contratoItem: {
    backgroundColor: `${theme.color.bgTheme} !important`,
    padding: "10px",
    display: "flex",
    justifyContent: "space-between",
    height: "100%"
  },
  contratoTexto: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  contratoNome: {
    color: theme.color.primary,
    fontWeight: "bold"
  },
  contratoData: {
    color: `${theme.color.secondaryText} !important`
  },
  contratoIcone: {
    color: theme.color.botaoDownloadFatura,
    display: "flex",
    alignSelf: "center"
  },
  semContratos: {
    color: `${theme.color.secondaryBorderColor} !important`,
    fontSize: "18px",
    margin: "0 auto",
    textAlign: "center"
  }
});
