import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const api = () => obterApi(MICROSERVICO.MEASUREMENT);

export const listarOrganizarPor = () => {
  const ordenar = [
    {
      key: "1",
      asc: "3",
      desc: "4",
      label: "Alfabética"
    },
    {
      key: "4",
      asc: "1",
      desc: "2",
      label: "Alteração"
    }
  ];

  return ordenar;
};

// Listar Cotações Paginadas
export const ListarAlteracaoMedicoes = async (filtro) => {
  const filtroQueryString = new URLSearchParams(filtro).toString();

  return api().get(`v1/medicaocontrole?${filtroQueryString}`);
};
