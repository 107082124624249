import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  container: {
    left: "50%",
    outline: "none",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    top: "50%",
    width: "100%"
  },
  fechar: {
    position: "absolute",
    top: 10,
    right: 20,
    cursor: "pointer",
    display: "flex",
    flexDirection: "row-reverse"
  }
}));
