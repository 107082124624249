export default class ClassePerfilDto {
  constructor(id, descricao) {
    this.id = id;
    this.descricao = descricao;
  }

  static obterMock() {
    return [
      new ClassePerfilDto(1, "Distribuidor"),
      new ClassePerfilDto(2, "Gerador"),
      new ClassePerfilDto(3, "Comercializador")
    ];
  }

  static mapearParaDto(dados) {
    return dados.map(
      (classe) => new ClassePerfilDto(classe.id, classe.descricao)
    );
  }
}
