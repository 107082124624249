import { makeStyles } from "@mui/styles";
import theme from "themes";
import { Base } from "componentes/cores";

export const useStyles = makeStyles(() => ({
  tabelaAtual: {
    display: "flex",
    height: "100%",
    width: "100%",
    "& ::-webkit-scrollbar:horizontal": {
      height: "8px"
    },
    "& ::-webkit-scrollbar-thumb": {
      background: theme.color.primryBackground
    }
  },
  tabelaHistorico: {
    display: "flex",
    height: "100%",
    width: "100%",
    "& ::-webkit-scrollbar:horizontal": {
      height: "8px"
    },
    "& ::-webkit-scrollbar-thumb": {
      background: theme.color.primary
    }
  },
  scroll: {
    flexGrow: 1,
    overflowX: "scroll",
    marginBottom: "10px"
  },
  tabelaCelula: {
    paddingLeft: "10px",
    paddingRight: "10px"
  },
  colunaAno: {
    minHeight: "62px"
  },
  precoGrupo: {
    display: "flex",
    flexFlow: "row nowrap",
    height: "100%"
  },
  precoItem: {
    borderRight: "4px solid #F4F4F4",
    width: "33.5% !important",
    paddingRight: "5px",
    paddingLeft: "5px"
  },
  precoItem2: {
    width: "33% !important",
    paddingRight: "5px",
    paddingLeft: "5px"
  },
  paginacao: {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    "& strong": {
      color: `${theme.color.primary} !important`,
    },
    "& nav ul li button": {
      color: `${Base.OffWhite} !important`
    },
    "& nav ul li button:hover": {
      color: `${Base.OffWhite} !important`,
      backgroundColor: `${theme.color.primary} !important`
    },
    "& nav ul li div": {
      color: `${Base.OffWhite} !important`
    },
    "& .MuiPaginationItem-outlinedPrimary": {
      backgroundColor: `${theme.color.bgPaginacao} !important`,
      color: `${theme.color.numeroPagina} !important`,
    },
    "& .MuiPaginationItem-outlinedPrimary.Mui-selected": {
      backgroundColor: `${theme.color.bgPaginacaoAtiva} !important`,
      border: `${theme.color.primary} !important`,
      color: `${Base.OffWhite} !important`
    },
    "& .MuiPaginationItem-outlinedPrimary.Mui-selected:hover": {
      backgroundColor: `${theme.color.bgPaginacaoHover} !important`,
      color: `${theme.color.numeroPaginaHover} !important`
    },
    "& .MuiPaginationItem-page.Mui-selected:hover": {
      backgroundColor: `${theme.color.primary} !important`
    }
  },
}));

export const customStyles = {
  table: {
    style: {
      backgroundColor: "transparent"
    }
  },
  headRow: {
    style: {
      borderBottom: "2px solid #F4F4F4",
      background: "transparent",
      minHeight: "36px !important"
    }
  },
  headCells: {
    style: {
      background: Base.GreyDisabled,
      borderLeft: "4px solid #F4F4F4",
      fontSize: "16px",
      width: "100%",
      padding: "0",
      textAlign: "center",
      minWidth: "130px !important",
      "& .rdt_TableCol_Sortable": {
        width: "100%",
        "& div": {
          width: "100%"
        }
      },
      ":nth-child(2)": {
        minWidth: "65%"
      }
    }
  },
  rows: {
    style: {
      borderTop: "2px solid #F4F4F4",
      background: "transparent",
      minHeight: "36px !important",
      ":not(:last-of-type)": {
        borderBottom: "2px solid #F4F4F4"
      }
    }
  },
  cells: {
    style: {
      borderLeft: "4px solid #F4F4F4",
      background: Base.White,
      fontSize: "14px",
      paddingLeft: "0",
      paddingRight: "0",
      textAlign: "center",
      width: "100%",
      minWidth: "130px !important",
      "& div": {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      },
      ":nth-child(2)": {
        minWidth: "65%"
      }
    }
  }
};

export const customStylesHistorico = {
  table: {
    style: {
      backgroundColor: "transparent"
    }
  },
  headRow: {
    style: {
      borderBottom: "2px solid #F4F4F4",
      background: "transparent",
      minHeight: "36px !important"
    }
  },
  headCells: {
    style: {
      background: Base.GreyDisabled,
      borderLeft: "4px solid #F4F4F4",
      fontSize: "16px",
      width: "100%",
      padding: "0",
      textAlign: "center",
      "& .rdt_TableCol_Sortable": {
        width: "100%",
        "& div": {
          width: "100%"
        }
      }
    }
  },
  rows: {
    style: {
      borderTop: "2px solid #F4F4F4",
      background: "transparent",
      minHeight: "36px !important",
      ":not(:last-of-type)": {
        borderBottom: "2px solid #F4F4F4"
      }
    }
  },
  cells: {
    style: {
      borderLeft: "4px solid #F4F4F4",
      background: Base.White,
      fontSize: "14px",
      paddingLeft: "0",
      paddingRight: "0",
      textAlign: "center",
      width: "100%",
      display: "flex",
      "& div": {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }
    }
  }
};
