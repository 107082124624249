import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import TuneIcon from "@mui/icons-material/Tune";
import DataTable from "react-data-table-component";
import RotasDTO from "../../../rotas/rotasUrlDto";
import { useStyles, conditionalRowStyles, customStyles } from "./style";
import Coluna from "componentes/tabelaPaginada/colunas/coluna";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import {
  obterHistoricoImportacoesPorIdInsumo,
  listarFiltrarPor
} from "../../../servicos/projecaoInsumos";
import MaterialInputTexto from "componentes/inputTexto/materialInput";
import { useForm, useWatch } from "react-hook-form";
import Loader from "componentes/loader";
import BotaoFiltroOrdenacao from "componentes/botaoFiltroOrdenar";
import { Base } from "componentes/cores";
import InputDataBranco from "componentes/inputTextoDataBranco";
import moment from "moment";

const ProjecaoInsumoHistoricoImportacoes = () => {
  const { id } = useParams();
  const history = useHistory();
  const classes = useStyles();

  const { register, control } = useForm({
    reValidateMode: "onSubmit"
  });

  const [atual, setAtual] = useState();
  const [listaHistorico, setListaHistorico] = useState();
  const [carregandoDados, setCarregandoDados] = useState(false);
  const [filtroSelecionado, setFiltroSelecionado] = useState(null);
  const [minDataInicio, setMinDataInicio] = useState();
  const [maxData, setMaxData] = useState();
  const [dataInicio, setDataInicio] = useState();
  const [dataFim, setDataFim] = useState();

  const obterHistorico = async () => {
    try {
      setCarregandoDados(true);
      const lista = await obterHistoricoImportacoesPorIdInsumo(id);
      if (lista?.data !== null) {
        const response = lista?.data;
        const organizado = [];
        response.map((item) =>
          organizado.push({
            ...item,
            id: item.idProjecaoInsumo,
            data:
              item.dataHora != null
                ? window.moment(item.dataHora).format("DD-MM-YYYY HH:mm:ss")
                : ""
          })
        );
        setAtual(organizado);
        setListaHistorico(organizado);
        setCarregandoDados(false);
      }
    } catch (error) {
      setCarregandoDados(false);
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            error?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  const textoBusca = useWatch({
    control,
    name: "textoBusca",
    defaultValue: ""
  });

  const stringBusca = useMemo(() => {
    return textoBusca ?? "";
  }, [textoBusca]);

  useEffect(() => {
    setTimeout(() => {
      handleFiltrarHistorico();
    }, 100);
  }, [stringBusca]);

  const handleFiltrarHistorico = () => {
    let dadosFiltrados = [];
    if (listaHistorico != null && listaHistorico.length > 0) {
      dadosFiltrados = listaHistorico;
      if (stringBusca != undefined && stringBusca.length > 0) {
        dadosFiltrados = dadosFiltrados.filter(filterSeriesHistoricasByNome);
      }
      if (filtroSelecionado == 0 || filtroSelecionado == 1) {
        let resultImportacao = dadosFiltrados?.filter(
          filterSeriesHistoricasByStatus
        );
        dadosFiltrados = resultImportacao;
      }
      setAtual(dadosFiltrados);
    }
  };

  const handleClickFiltroSelecionado = (event) => {
    const val = filtroSelecionado === event ? null : event;
    setFiltroSelecionado(val);
  };

  const handleClickAtivarAgora = async () => {
    handleFiltrarHistorico();
  };

  const handleDataInicio = (event) => {
    setDataInicio(event);
  };
  const handleDatafim = (event) => {
    setDataFim(event);
  };

  const colunas = [
    new Coluna("nomeUsuario", "Usuário", true, "200px", "200px"),
    new Coluna("data", "Data", true, "170px", "170px"),
    new Coluna("status", "Status", true, "140px", "140px"),
    { ...new Coluna("observacao", "Observação", false), wrap: true }
  ];

  useEffect(() => {
    setAtual(listaHistorico?.filter(filterSeriesHistoricasByData));
  }, [dataInicio, dataFim]);

  useEffect(() => {
    obterHistorico();
    setMinDataInicio(window.moment().subtract(5, "years").toDate());
    setMaxData(window.moment().toDate());
    setDataInicio(window.moment().subtract(1, "months").toDate());
    setDataFim(window.moment().toDate());
  }, []);

  const onClickReturn = () => {
    history.push(RotasDTO.ProjecaoInsumoListar);
  };

  function filterSeriesHistoricasByNome(obj) {
    if (
      obj.nomeUsuario != null &&
      obj.nomeUsuario.toUpperCase().includes(textoBusca.toUpperCase())
    ) {
      return true;
    } else {
      return false;
    }
  }

  function filterSeriesHistoricasByStatus(obj) {
    let statusImportacao = filtroSelecionado == 1 ? "SUCESSO" : "FALHA";
    if (obj.status != null && obj.status.toUpperCase() == statusImportacao) {
      return true;
    } else {
      return false;
    }
  }

  function filterSeriesHistoricasByData(obj) {
    if (
      obj.dataHora &&
      dataInicio &&
      dataFim &&
      moment(obj.dataHora).format("YYYY-MM-DD") >=
        moment(dataInicio).format("YYYY-MM-DD") &&
      moment(obj.dataHora).format("YYYY-MM-DD") <=
        moment(dataFim).format("YYYY-MM-DD")
    ) {
      return true;
    } else {
      return false;
    }
    return true;
  }

  return (
    <>
      <Loader loading={carregandoDados} className="w-auto">
        <Grid container item xs={12}>
          <Grid item xs={9}>
            <MaterialInputTexto
              type="text"
              id="textoBusca"
              name="textoBusca"
              label="Buscar"
              renderIconShowHide
              searchAdornment
              defaultValue={textoBusca ?? ""}
              ref={register}
              className={classes.search}
              permiteValorBranco
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={2}>
            <BotaoFiltroOrdenacao
              type="button"
              color={Base.GreyWater}
              background="transparent"
              label="Filtrar / Ordenar"
              icon={<TuneIcon />}
              className={classes.buttonFiltro}
              ordenadorUm="Ordenador"
              ordenadorDois="Filtrar"
              filtros={listarFiltrarPor()}
              filtrosSelecionados={filtroSelecionado}
              onClickFiltro={handleClickFiltroSelecionado}
              onClickAtivarAgora={handleClickAtivarAgora}
            />
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          mt={2}
          p={2}
          spacing={2}
          className={classes.itemfiltroData}
        >
          <Grid item xs={3} className={classes.itemfiltroDataInput}>
            <InputDataBranco
              type="text"
              id="mesInicio"
              name="mesInicio"
              minDate={minDataInicio}
              maxDate={maxData}
              customValue={dataInicio}
              onChange={(valor) => handleDataInicio(valor)}
              format={"dd/MM/yyyy"}
              views={["date"]}
              renderIconShowHide={false}
            />
          </Grid>

          <Grid item xs={3} className={classes.itemfiltroDataInput}>
            <InputDataBranco
              type="text"
              id="mesFim"
              name="mesFim"
              customValue={dataFim}
              minDate={dataInicio}
              maxDate={maxData}
              onChange={(valor) => handleDatafim(valor)}
              format={"dd/MM/yyyy"}
              views={["date"]}
              renderIconShowHide={false}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} p={2} spacing={3}>
          <Grid item xs={12}>
            {atual?.length > 0 ? (
              <DataTable
                title={`Histórico de Importação - ${atual[0].nomeImportacao}`}
                subHeader
                subHeaderAlign="right"
                subHeaderComponent={
                  <HighlightOffIcon
                    className={classes.icon}
                    onClick={() => onClickReturn()}
                  />
                }
                columns={colunas}
                data={atual}
                theme="DeltaEnergia"
                customStyles={customStyles}
                conditionalRowStyles={conditionalRowStyles}
                noDataComponent="Nenhum registro encontrado"
              />
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </Loader>
    </>
  );
};

export default ProjecaoInsumoHistoricoImportacoes;
