import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useStyles } from "./style";

const GraficoConsumoEconomiaBarra = ({
  dados,
  serie1,
  serie2,
  serie3,
  categories,
  typeCategories
}) => {
  const classes = useStyles();
  const [grafico, setGrafico] = useState(<div />);

  const dadosGrafico = useMemo(
    () => ({
      series: [
        {
          name: "Consumo real",
          data: serie1,
          type: "bar"
        },
        {
          name: "Consumo projetado",
          data: serie2,
          type: "bar"
        }
      ],
      options: {
        colors: ["#332053", "rgba(51, 32, 83, 0.46)"],
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: true,
          fontSize: "14px",
          fontWeight: 700,
          labels: {
            colors: ["#332053"],
            useSeriesColors: false
          },
          markers: {
            radius: 12,
            fillColors: ["#332053", "rgba(51, 32, 83, 0.46)"]
            // eslint-disable-next-line max-len
            // fillColors: ["linear-gradient(357.04deg, rgba(247, 34, 181, 0.70) 2.11%, rgba(253, 149, 7, 0.64) 75.57%)", "linear-gradient(357.04deg, rgba(247, 34, 181, 0.45) 2.11%, rgba(253, 149, 7, 0.45) 75.57%)"],
          }
        },
        stroke: {
          show: false,
          colors: undefined,
          width: 2,
          dashArray: 0
        },
        fill: {
          type: ["gradient", "gradient"],
          gradient: {
            gradientToColors: ["#332053", "rgba(51, 32, 83, 0.46)"],
            type: "vertical",
            shadeIntensity: 0,
            inverseColors: false,
            opacityFrom: 0.8,
            opacityTo: 0.4,
            stops: [20, 100, 100, 100]
          }
        },
        markers: {
          size: 0,
          colors: ["#332053", "rgba(51, 32, 83, 0.46)"]
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0
              },
              plotOptions: {
                bar: {
                  horizontal: false
                  // borderRadius: 16
                }
              }
            }
          }
        ],
        plotOptions: {
          bar: {
            horizontal: false
            // borderRadius: 16
          }
        },
        xaxis: {
          type: typeCategories,
          categories,
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            show: false
          },
          labels: {
            show: true,
            style: {
              colors: "#4F4F4F",
              fontWeight: "700",
              fontSize: "12px"
            },
            datetimeUTC: true,
            rotate: 0
          },
          position: "bottom",
          tooltip: {
            enabled: false
          }
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          forceNiceScale: true,
          seriesName: undefined,
          labels: {
            show: true,
            minWidth: 0,
            maxWidth: 160,
            offsetX: 0,
            offsetY: 0,
            rotate: 0,
            style: {
              colors: "#4F4F4F",
              fontWeight: "700",
              fontSize: "12px"
            },
            formatter(value) {
              return `
                R$ ${Math.round(value)}
              `;
            }
          }
        },
        tooltip: {
          enabled: true,
          theme: false,
          fillSeriesColor: false,
          custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            const consumoReal = serie3[dataPointIndex]
              ? serie3[dataPointIndex]
              : "";
            const date = w?.globals?.labels[dataPointIndex];
            const valorConvertido = series[seriesIndex][dataPointIndex];
            const valor =
              valorConvertido &&
              valorConvertido.toFixed(2).toString().replace(".", ",");
            const valorReal =
              consumoReal &&
              consumoReal.toFixed(2).toString().replace(".", ",");
            const nameSeries = w.globals.initialSeries[seriesIndex];
            return `
               <div class=${classes.toolTip}> 
                <div class=${classes.toolTipText}>
                  ${date} - R$ ${valor}
                  <div>${nameSeries?.name} - ${valorReal}</div>
                </div>
               </div>
            `;
          }
        }
      }
    }),
    [dados]
  );

  const RenderizarGrafico = useCallback(() => {
    const graficoRender = (
      <div className="mixed-chart">
        <ReactApexChart
          options={dadosGrafico?.options}
          series={dadosGrafico?.series}
          type="bar"
          height="350"
          width="100%"
        />
      </div>
    );

    setGrafico(graficoRender);
  }, [setGrafico, dados]);

  useEffect(() => RenderizarGrafico(), [RenderizarGrafico]);

  return (
    <Grid item xs={12} md={12}>
      {grafico}
    </Grid>
  );
};

GraficoConsumoEconomiaBarra.propTypes = {
  dados: PropTypes.oneOfType([PropTypes.array]),
  serie1: PropTypes.oneOfType([PropTypes.array]),
  serie2: PropTypes.oneOfType([PropTypes.array]),
  serie3: PropTypes.oneOfType([PropTypes.array]),
  categories: PropTypes.oneOfType([PropTypes.array]),
  typeCategories: PropTypes.string
};

GraficoConsumoEconomiaBarra.defaultProps = {
  dados: [],
  serie1: [],
  serie2: [],
  serie3: [],
  categories: [],
  typeCategories: ""
};

export default GraficoConsumoEconomiaBarra;
