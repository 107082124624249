import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import { useForm } from "react-hook-form";
import { Card, Grid, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import InputData from "componentes/inputTextoData";
import { Base } from "componentes/cores";
import Loader from "componentes/loader";
import UploadDropArea from "componentes/upload";
import Botao from "componentes/botao";
import ValidacaoDominios from "componentes/validacaoDominios";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { useSelector } from "react-redux";
import { 
  ImportSPARTA, 
  GetLatestImportsByIdDistribuidora, 
  possuiImportsDeUmAnoTarifario, 
  getHistImpByChaveNatural, 
  getProjecaoInsumo } from "../../servicos/spartaServico";
import { geraURLUpload } from "../../servicos/awsUtils";
import { listarDistribuidoras } from "../../servicos/parametrizacaoDistribuidoras";
import ResultadoOperacao from 'componentes/resultadoOperacao';
import { useStyles } from "./style";
import { useHistory } from "react-router-dom";
import RotasDTO from "../../rotas/rotasUrlDto";
import CloseIcon from "@mui/icons-material/Close";
import { importPing } from "../../utils/import-ping"
import AWSS3UploadAsh from 'aws-s3-upload-ash'

const SpartaImportar = () => {
  const classes = useStyles();
  const history = useHistory();
  const usuarioGlobal = useSelector((state) => state.usuario);
  const msgPadraoUpload = "Solte o arquivo para anexá-lo ou procurar";

  const { handleSubmit } = useForm({
    reValidateMode: "onSubmit",
  });

  const [file, setFile] = useState(false);
  const [fileName, setFileName] = useState(msgPadraoUpload);
  const [carregandoArquivo, setCarregandoArquivo] = useState(false);
  const [progressMesage, setProgressMessage] = useState("");

  const [resultadoOperacao, setResultadoOperacao] = useState(false);
  const [resultadoOperacaoCabecalho, setResultadoOperacaoCabecalho] = useState("");
  const [resultadoOperacaoObj, setResultadoOperacaoObj] = useState({
    "mensagens": []
  });

  const [distribuidora, setDistribuidora] = useState({});
  const [distribuidoraSPARTAS, setDistribuidoraSPARTAS] = useState([]);
  const [ultimaSPARTA, setUltimaSPARTA] = useState({});
  const [atualSPARTA, setAtualSPARTA] = useState({});
  const [existSPARTA, setExistSPARTA] = useState(false);
  const [anoTarifario, setAnoTarifario] = useState(null);

  const [validacaoDominio, setValidacaoDominio] = useState(false);
  const [validacaoDominioCabecalho, setValidacaoDominioCabecalho] = useState("");
  const [validacaoDominiosObj, setValidacaoDominiosObj] = useState({
    "contexto": "",
    "entidades": []
  });

  const [distribuidoras, setDistribuidoras] = useState([]);
  const [carregandoDistribuidoras, setCarregandoDistribuidoras] = useState(false);

  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const S3CustomClient = new AWSS3UploadAsh();


  const obterDistruidoras = useCallback(async () => {
    try {
      setCarregandoDistribuidoras(true);
      const lista = await listarDistribuidoras(true);
      if (lista.status === 200 && lista.data) {
        setDistribuidoras(lista?.data);
      }
      setCarregandoDistribuidoras(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoDistribuidoras(false);
    }
  }, []);

  useEffect(() => {
    if (!distribuidoras.length) obterDistruidoras();
  }, [!distribuidoras.length, obterDistruidoras]);


  const getListImportsByIdDistribuidora = async (id) => {
    const retorno = await GetLatestImportsByIdDistribuidora(id);

    if (retorno.data && retorno.data.length > 0) {
      setDistribuidoraSPARTAS(retorno.data);
      setUltimaSPARTA(retorno.data[0]);
    } else {
      setDistribuidoraSPARTAS([]);
      setUltimaSPARTA({});
    }

  }

  const handleChangeDistribuidora = async (event) => {
    setDistribuidora(
      {
        id: event?.target?.value,
        titulo: event?.currentTarget?.dataset?.titulo,
        nomefantasia: event?.currentTarget?.dataset?.nomefantasia,
        razaosocial: event?.currentTarget?.dataset?.razaosocial,
        diareajustetarifario: event?.currentTarget?.dataset?.diareajustetarifario,
        mesreajustetarifario: event?.currentTarget?.dataset?.mesreajustetarifario,
      }
    );
    await getListImportsByIdDistribuidora(event.target.value);
  }

  const handleChangeAnoTarifario = (data) => {
    setAnoTarifario(data);
  }

  const [vigenciaCorrecao, setVigenciaCorrecao] = useState(null);
  const handleChangeVigenciaCorrecao = (data) => {
    setVigenciaCorrecao(data);
  }

  const [acaoID, setAcaoID] = useState(null);
  const handleChangeAcao = (event) => {
    setAcaoID(event.target.value);
  }

  useEffect(() => {
    setExistSPARTA(false);
  }, [ultimaSPARTA, anoTarifario]);

  const processarArquivo = (arquivo) => {
    if (arquivo != null && arquivo[0] != null) {
      setFile(arquivo[0]);
      setFileName(arquivo[0].file.name);
    }
  };

  const onAddUpload = (arquivo) => {
    processarArquivo(arquivo)
  };

  const onCancelar = () => {
    setFile(false);
    setFileName(msgPadraoUpload);
    setCarregandoArquivo(false);
    setValidacaoDominio(false);
    setExistSPARTA(false);
    setUltimaSPARTA({});
    setAtualSPARTA({});
    setDistribuidoraSPARTAS([]);
    setDistribuidora({})
    setAnoTarifario(null);
    setVigenciaCorrecao(null);
    setAcaoID(null);
    setResultadoOperacao(false);
    setResultadoOperacaoCabecalho("");
    setResultadoOperacaoObj({
      "mensagens": []
    });
  }

  const onCancelarValidacaoDominios = () => {
    setFile(false);
    setFileName(msgPadraoUpload);
    setCarregandoArquivo(false);
    setValidacaoDominio(false);
    setExistSPARTA(false);
    setUltimaSPARTA({});
    setAtualSPARTA({});
    setDistribuidoraSPARTAS([]);
    setDistribuidora({})
    setAnoTarifario(null);
    setVigenciaCorrecao(null);
    setAcaoID(null);
    setResultadoOperacao(false);
    setResultadoOperacaoCabecalho("");
    setResultadoOperacaoObj({
      "mensagens": []
    });
  }

  const importarSPARTA = async (data) => {
    if (data && data.totalDesmarcado === 0 && data.responseInsert.totalInserido === data.responseInsert.totalValores) {
      if (file && data) {
        setCarregandoArquivo(true);
        setValidacaoDominio(false);
        setResultadoOperacao(false);
        setProgressMessage("Importando o arquivo... Por favor, aguarde.");
        setExistSPARTA(false);
        try {
          let novaVigencia = null;
          if (vigenciaCorrecao != null) {
            novaVigencia = vigenciaCorrecao.toISOString();
          }

          const response = await ImportSPARTA(file, distribuidora.id, false, anoTarifario.getFullYear(), novaVigencia, usuarioGlobal?.usuario?.id, usuarioGlobal?.usuario?.usuarioNome);

          if (response?.status === 200 && response?.data) {
            onCancelar();
            let dadosRetorno = response?.data;

            let mensagemRetorno = ["Processo de Importação Finalizado com Sucesso."];

            if (dadosRetorno.length !== null && dadosRetorno.length !== undefined && dadosRetorno.length > 1) {

              let valores = dadosRetorno?.[1];

              if (valores.length !== null && valores.length !== undefined && valores.length > 0) {
                for (var i = 0; i < valores?.length; i++) {
                  mensagemRetorno.push(valores[i].Mensagem);
                }
              }
            }
            setResultadoOperacaoCabecalho(`Sparta - Importação do arquivo ${fileName}`)
            setResultadoOperacaoObj({ "mensagens": mensagemRetorno });
          }
          else if (response?.status === 202 && response?.data) {
            if (
              response?.data.typeError === "ERRO-VALIDACAO-ESTRUTURA"
              || response?.data.typeError === "ERRO-VALIDACAO-TARIFA"
              || response?.data.typeError === "ERRO-VALIDACAO-MERCADO-FATURADO"
              || response?.data.typeError === "ERRO-VALIDACAO-CUSTOS"
              || response?.data.typeError === "ERRO-VALIDACAO-PESO-TARIFARIO"
              || response?.data.typeError === "ERRO-VALIDACAO-CONTRATO-LEILAO"
              || response?.data.typeError === "ERRO-LEITURA-ITENS"
              || response?.data.typeError === "ERRO-VALIDACAO-CONTRATO-LEILAO"
              || response?.data.typeError !== "ERRO-VALIDACAO-DOMINIO"
            ) {
              setAnoTarifario(null);
              setDistribuidora({});
              setFile(false);
              setFileName(msgPadraoUpload);
              setCarregandoArquivo(false);
              setValidacaoDominio(false);

              setResultadoOperacao(true);
              let dadosRetorno = response?.data.value;
              setResultadoOperacaoCabecalho(`Sparta - Falhas da Importação`)
              setResultadoOperacaoObj({ "mensagens": dadosRetorno.map((item) => { return item.mensagem }) });
            }
          }
        } catch (error) {
          setFile(false);
          setFileName(msgPadraoUpload);
          setCarregandoArquivo(false);
          setValidacaoDominio(false);

          setHasError(true);
          setErrorMessage(`Erro ao importar arquivo - ${error}`);
          setResultadoOperacao(true);
          setResultadoOperacaoCabecalho(`Sparta - Importação do arquivo ${fileName}`)
          setResultadoOperacaoObj({ "mensagens": [error.message ?? "Ocorreu um erro não previsto"] });
        }
      } else {
        store.dispatch(
          alertaExibir({
            tipo: "danger",
            mensagem: "É necessário informar o arquivo!"
          })
        );
      }
    } else {
      if (data && data.responseInsert && data.responseInsert.totalInserido > 0) {
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: data.responseInsert.totalInserido === 1 ? "1 valor registrado com sucesso!" : `${data.responseInsert.totalInserido} valores registrados com sucesso!`
          })
        );
      }
      setCarregandoArquivo(false);
      setValidacaoDominio(false);
    }
  }

  const realizarEnvioSparta = () => {
    if (distribuidoraSPARTAS?.length === 0) {
      importarSPARTAComValidacao();
      return;
    }

    let findSPARTA = false;

    distribuidoraSPARTAS.forEach(sparta => {
      if (existSPARTA) {
        findSPARTA = true;

        setAtualSPARTA(sparta);

        if (acaoID == null || acaoID == undefined) {
          store.dispatch(
            alertaExibir({
              tipo: "danger",
              mensagem: "É necessário informar o que quer fazer!"
            })
          );

          return;
        }

        if (acaoID !== "2") {
          importarSPARTAComValidacao();
          return;
        }

        if (vigenciaCorrecao != null) {
          importarSPARTAComValidacao();
          return;
        }

        store.dispatch(
          alertaExibir({
            tipo: "danger",
            mensagem: "É necessário informar a nova vigência!"
          })
        );
      }
    });

    if (!findSPARTA) {
      importarSPARTAComValidacao();
    }
  }

  const validarSPARTASDoAnoTarifario = async () => {
    if (existSPARTA) {
      realizarEnvioSparta();
      return;
    }

    if (ultimaSPARTA.anoTarifario === anoTarifario.getFullYear()) {
      setExistSPARTA(true);
      return;
    }

    if (!existSPARTA) {
      const { data } = await possuiImportsDeUmAnoTarifario(distribuidora.id, anoTarifario.getFullYear());

      if (data === true) {
        setExistSPARTA(true);
        return;
      }
    }

    realizarEnvioSparta();
  }

  const importarSPARTAComValidacao = async () => {
    if (file) {
      setCarregandoArquivo(true);
      setProgressMessage("Importando o arquivo... Por favor, aguarde.");
      setExistSPARTA(false);
      try {
        let novaVigencia = null;
        if (vigenciaCorrecao != null) {
          novaVigencia = vigenciaCorrecao.toISOString();
        }

        console.log('file.file', file.file)
        const keyOnS3 = file.file.name;
        const urlPreSigned = await geraURLUpload(keyOnS3)
        const uploadS3Response = await S3CustomClient.uploadFile(file.file, file.file.type, urlPreSigned.data);
        
        console.log('urlPreSigned', urlPreSigned.data)
        console.log('uploadS3Response', uploadS3Response)
        
        // alert("upload complete to s3")
        // return

        const response = await ImportSPARTA(
          distribuidora.id,
          true,
          anoTarifario.getFullYear(),
          novaVigencia,
          usuarioGlobal?.usuario?.id,
          usuarioGlobal?.usuario?.usuarioNome,
          keyOnS3
        );

        if (response?.status === 200 && response?.data) {
          setProgressMessage("Processando a importaçäo... Por favor, aguarde.");
          const datetimeImportStarted = response.data.DataHoraRequisicao
          const urlParams = new URLSearchParams(window.location.search);
          let idInsumo = urlParams.get('id')
          let codigoInsumo = urlParams.get('codigo')
          await importPing(idInsumo, datetimeImportStarted, usuarioGlobal?.usuario.id, codigoInsumo, (status, message) => {
            setCarregandoArquivo(false);
            setResultadoOperacao(true);
            setResultadoOperacaoCabecalho(`Sparta - Importação do arquivo ${fileName}`)
            let mensagemRetorno = ["Processo de Importação Finalizado.", status, message];
            setResultadoOperacaoObj({ "mensagens": mensagemRetorno });
          })
        }
        else if (response?.status === 202 && response?.data && response?.data.hasOwnProperty('typeError')) {
          if (
            response?.data.typeError === "ERRO-VALIDACAO-ESTRUTURA"
            || response?.data.typeError === "ERRO-VALIDACAO-TARIFA"
            || response?.data.typeError === "ERRO-VALIDACAO-MERCADO-FATURADO"
            || response?.data.typeError === "ERRO-VALIDACAO-CUSTOS"
            || response?.data.typeError === "ERRO-VALIDACAO-PESO-TARIFARIO"
            || response?.data.typeError === "ERRO-VALIDACAO-CONTRATO-LEILAO"
            || response?.data.typeError === "ERRO-LEITURA-ITENS"
          ) {
            setFile(false);
            setAnoTarifario(null);
            setDistribuidora({});
            setFileName(msgPadraoUpload);
            setCarregandoArquivo(false);
            setValidacaoDominio(false);

            setResultadoOperacao(true);
            let mensagens = response?.data.value;

            let mensagemRetorno = [];
            if (mensagens.length !== null && mensagens.length !== undefined && mensagens.length > 0) {
              for (var i = 0; i < mensagens?.length; i++) {
                mensagemRetorno.push(mensagens[i].mensagem);
              }
            }
            setResultadoOperacaoCabecalho(`Sparta - Falhas da Importação`)
            setResultadoOperacaoObj({ "mensagens": mensagemRetorno });
          }
          else if (response?.data.typeError === "ERRO-VALIDACAO-DOMINIO") {
            setValidacaoDominio(true);
            setValidacaoDominioCabecalho(`Importação do arquivo ${fileName}`)
            setValidacaoDominiosObj(response?.data.value);
          }
        }
        else if (response?.status === 202 && response?.data && !response?.data.hasOwnProperty('typeError')) {
          setFile(false);
          setAnoTarifario(null);
          setDistribuidora({});
          setFileName(msgPadraoUpload);
          setCarregandoArquivo(false);
          setValidacaoDominio(false);

          setResultadoOperacao(true);

          let mensagemRetorno = [response?.data];
          setResultadoOperacaoCabecalho(`Sparta - Falhas da Importação`)
          setResultadoOperacaoObj({ "mensagens": mensagemRetorno });

        }
      } catch (error) {
        setFile(false);
        setFileName(msgPadraoUpload);
        setCarregandoArquivo(false);
        setValidacaoDominio(false);
        setResultadoOperacao(true);
        setResultadoOperacaoCabecalho(`Sparta - Falhas da Importação`)
        setResultadoOperacaoObj({ "mensagens": [`Ocorreu um erro não previsto: ${error.message}`] });
      }
    } else {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: "É necessário informar o arquivo!"
        })
      );
    }
  }

  const validarEnvioSPARTA = async () => {
    if (!file) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: "É necessário informar o arquivo!"
        })
      );

      return;
    }

    if (!(distribuidora && distribuidora.id)) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: "É necessário informar a Distribuidora!"
        })
      );

      return;
    }

    if (!anoTarifario) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: "É necessário informar o Ano Tarifário!"
        })
      );

      return;
    }

    validarSPARTASDoAnoTarifario();
  }

  const onFechar = () => {
    history.push(`${RotasDTO.ProjecaoInsumoListar}`);
  }

  return <>
    <form className={classes.container} onSubmit={handleSubmit(validarEnvioSPARTA)}>
      <ResultadoOperacao
        item={resultadoOperacaoObj}
        cabecalhoResultado={resultadoOperacaoCabecalho}
        onClose={() => onCancelar()}
      />
      {resultadoOperacao === false && validacaoDominio == true ?
        <ValidacaoDominios
          item={validacaoDominiosObj}
          cabecalhoValidacao={validacaoDominioCabecalho}
          onConfirmar={(data) => importarSPARTA(data)}
          onCancelar={() => onCancelarValidacaoDominios()}
        />
        : ""
      }
      {resultadoOperacao === false && validacaoDominio === false ?
        <Card className={classes.container}>
          <Grid container className={classes.containerTitulo}>
            <Grid item xs={11} className="font-weight-bold">
              Importação de Sparta
            </Grid>
            <Grid item xs={1} >
              <CloseIcon onClick={onFechar} className={classes.iconcustom} />
            </Grid>
          </Grid>
          {existSPARTA === true ?
            <div>
              <Grid container spacing={4} p={2} className={classes.container}>
                <Grid item sm={12}>
                  <p>
                    {`Já existe um arquivo SPARTA / ${distribuidora?.titulo} para o ano de ${anoTarifario.getFullYear()}.`}
                    <br />
                    Você pode substituir os dados para a vigência atual ou criar uma nova vigência.
                  </p>
                </Grid>
              </Grid>

              <Grid container spacing={4} p={2} className={classes.container} alignItems="center">
                <Grid item sm={4}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="vigencias">O que quer fazer?</InputLabel>
                    <Select
                      variant="outlined"
                      labelId
                      id="vigencias"
                      value={acaoID ?? ""}
                      onChange={handleChangeAcao}
                      label="O que quer fazer?"
                      fullWidth>
                      <MenuItem value="1">Substituir vigência</MenuItem>
                      <MenuItem value="2">Nova vigência</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {acaoID === "2" ?
                  <Grid item sm={5}>
                    <InputData
                      type="text"
                      label="Vigência da Correção"
                      customValue={vigenciaCorrecao}
                      onChange={(data) => handleChangeVigenciaCorrecao(data)}
                      minDate={moment(atualSPARTA.inicioVigencia).add(1, "days").toDate()}
                      maxDate={moment(atualSPARTA.fimVigencia).toDate()}
                      renderIconShowHide={false}
                    />
                  </Grid>
                  : ""}
                {carregandoArquivo === false ?
                  <Grid container spacing={4} p={2} className={classes.container} justifyContent="center">
                    <Grid item sm={3}>
                      <Botao
                        type="button"
                        label="Cancelar"
                        onClick={onCancelar}
                        color={Base.GreyDim}
                        className={classes.button}
                      />
                    </Grid>
                    <Grid item sm={3}>
                      <Botao
                        type="submit"
                        label="Importar"
                        color={Base.Mango}
                        className={classes.button}
                      />
                    </Grid>
                  </Grid>
                  : ""
                }
              </Grid>
            </div>
            :
            <div>
              <Grid container spacing={4} p={2} className={classes.container}>
                {carregandoArquivo === false ?
                  <Grid item sm={12}>
                    <Grid item sm={12} container spacing={4} p={2} className={classes.container} alignItems="center">
                      <Grid item sm={5}>
                        <Loader loading={carregandoDistribuidoras}>
                          <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="distribuidoras">Distribuidoras</InputLabel>
                            <Select
                              variant="outlined"
                              labelId="distribuidoraId"
                              id="distribuidoras"
                              value={distribuidora.id}
                              onChange={handleChangeDistribuidora}
                              label="Distribuidoras"
                              fullWidth>

                              {distribuidoras?.map((item) => (
                                <MenuItem
                                  key={item.idDistribuidora}
                                  value={item.idDistribuidora}
                                  data-titulo={item.titulo}
                                  data-diareajustetarifario={item.diaReajusteTarifario}
                                  data-mesreajustetarifario={item.mesReajusteTarifario}
                                  data-nomefantasia={item.nomeFantasia}
                                  data-razaosocial={item.razaoSocial}
                                >
                                  {item.titulo}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Loader>
                      </Grid>
                      <Grid item sm={3}>
                        <InputData
                          type="text"
                          label="Ano Tarifário"
                          customValue={anoTarifario}
                          format="yyyy"
                          onChange={(data) => handleChangeAnoTarifario(data)}
                          minDate={moment().subtract(8, "years").toDate()}
                          views={["year"]}
                          renderIconShowHide={false}
                        />
                      </Grid>
                    </Grid>
                    <Grid item sm={12} className={classes.spartainfo}>
                      {distribuidora && distribuidora?.diareajustetarifario ?
                        <div>
                          {`Aniversário Tarifário: ${distribuidora?.diareajustetarifario}/${distribuidora?.mesreajustetarifario}`}
                        </div>
                        : ""
                      }

                      {ultimaSPARTA && ultimaSPARTA?.anoTarifario ?
                        <div>
                          {`Última SPARTA importada: ${ultimaSPARTA.anoTarifario}`}
                        </div>
                        : ""
                      }

                    </Grid>
                    <Grid item sm={12} className={classes.spartaDropFile}>
                      <UploadDropArea
                        onAdd={onAddUpload}
                        dropAreaText={fileName}
                        showLoader={false}
                        acceptedFiles={[
                          ".xlsm,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                        ]}
                      />
                    </Grid>
                  </Grid>
                  :
                  <Grid container spacing={4} p={2} className={classes.container} style={{ marginBottom: "20px" }}>
                    <Grid item sm={12} style={{ textAlign: "center" }}>
                      {progressMesage}
                    </Grid>
                    <Grid item sm={12}>
                      <Loader loading={carregandoArquivo} />
                    </Grid>
                  </Grid>
                }
              </Grid>
              {carregandoArquivo === false ?
                <Grid container spacing={4} p={2} className={classes.container} justifyContent="center">
                  <Grid item sm={3}>
                    <Botao
                      type="button"
                      label="Cancelar"
                      onClick={onCancelar}
                      color={Base.GreyDim}
                      className={classes.button}
                    />
                  </Grid>
                  <Grid item sm={3}>
                    <Botao
                      type="submit"
                      label="Importar"
                      color={Base.Mango}
                      className={classes.button}
                    />
                  </Grid>
                </Grid>
                : ""
              }
            </div>
          }
        </Card>
        : ""
      }
    </form>
  </>;
}

export default SpartaImportar;
