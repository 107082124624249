import React from "react";
import PropTypes from "prop-types";
import { Grid, Divider } from '@mui/material';
import { useStyles, Alinhamento, BandeiraWrapper, BandeiraMes, BandeiraCor, BandeiraValor } from './style';

const Bandeira = ({ valorEstimados, valoresDefinitivos, response }) => {
  const classes = useStyles();

  const handleColor = (name) => {
    const color = name.replace(/\s/g, '').toLowerCase();
    let bandeira = "";

    if (color === "verde") {
      bandeira = "green"
    } if (color === "amarela") {
      bandeira = "yellow"
    } if (color === "vermelha1") {
      bandeira = "#ff0000"
    } if (color === "vermelha2") {
      bandeira = "#b30000"
    }

    return bandeira
  }

  return (
    <Grid container spacing={4} className={classes.container}>
      <Grid item sm={12}>
        <Alinhamento>
          {valoresDefinitivos.map((item) => (
            <BandeiraWrapper key={item.id}>
              <BandeiraMes
                className={item.nomeBandeira === "VERMELHA 2" ? classes.mesCustom : classes.mes}
              >
                {window.moment(item.dtReferencia, 'DD-MM-YYYY').format('MMM')}
              </BandeiraMes>
              <BandeiraCor
                className={item.nomeBandeira === "VERMELHA 2" ? classes.bandeiraCustom : classes.bandeira}
                style={{ background: handleColor(item.nomeBandeira) }}
              />
              <BandeiraValor className={classes.valor}>
                {item.valorTarifa?.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                  minimumFractionDigits: 3
                })}
              </BandeiraValor>
            </BandeiraWrapper>
          ))}
          {response === true ?
            <Divider
              orientation="vertical"
              flexItem
              data-df={valoresDefinitivos.length > 0 ? "Definitivo" : ""}
              data-et={valorEstimados.length > 0 ? "Estimado" : ""}
            />
            : ""
          }
          {valorEstimados.map((item) => (
            <BandeiraWrapper key={item.id}>
              <BandeiraMes
                className={item.nomeBandeira === "VERMELHA 2" ? classes.mesCustom : classes.mes}
              >
                {window.moment(item.dtReferencia, 'DD-MM-YYYY').format('MMM')}
              </BandeiraMes>
              <BandeiraCor
                className={item.nomeBandeira === "VERMELHA 2" ? classes.bandeiraCustom : classes.bandeira}
                style={{ background: handleColor(item.nomeBandeira) }}
              />
              <BandeiraValor
                className={classes.valor}
              >
                {item?.valorTarifa?.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                  minimumFractionDigits: 3
                })}
              </BandeiraValor>
            </BandeiraWrapper>
          ))}
        </Alinhamento>
      </Grid>
    </Grid>
  )
};

Bandeira.propTypes = {
  valorEstimados: PropTypes.array,
  valoresDefinitivos: PropTypes.array,
  response: PropTypes.bool
};

Bandeira.defaultProps = {
  valorEstimados: [],
  valoresDefinitivos: [],
  response: false
};

export default Bandeira;
