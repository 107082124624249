import React, { useMemo, useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Loader } from "../../../componentes";
import { RotasDTO } from "../../../global/rotas/rotasUrlDto";
import AssistenteHorizontal from "../../../componentes/assistente";
import EnvioDocumentos from "./Etapas/EnvioDocumentos";
import AnaliseSeguranca from "./Etapas/AnaliseSeguranca";
import InstalacaoMedidor from "./Etapas/InstalacaoMedidor";
import OnBoardingHelper from "./helper";
import UnidadeNaoSelecionada from "../../../componentes/unidadeNaoSelecionada";
import ProcessoMigracao from "./Etapas/ProcessoMigracao";
import BoasVindas from "./Etapas/BoasVindas";
import UnidadeSemDemanda from "./Componentes/unidadeSemDemanda";

const WorkFlowGD = () => {
  const refAssistente = useRef(null);
  const [loading, setLoading] = useState(false);
  const [indexWizard, setIndexWizard] = useState(-1);
  const [semDemanda, setSemDamanda] = useState(false);
  const [demanda, setDemanda] = useState([]);
  const usuario = useSelector((state) => state.usuario);
  const { clienteSelecionado, unidadeSelecionada } = usuario;

  const steps = [
    "Envio de documentos",
    "Análise de segurança",
    "Processo de migração",
    "Instalação do Medidor",
    "Boas-vindas"
  ];

  const obterPorFiltroLateral = async () => {
    const {
      data,
      mensagem,
      sucesso
    } = await OnBoardingHelper.obterProtocoloDemanda(
      unidadeSelecionada,
      setSemDamanda,
      setLoading
    );

    if (!sucesso) {
      OnBoardingHelper.exibirErro(mensagem);
      setLoading(false);
      return;
    }

    setDemanda(data);
  };

  // const tramitar = async (id, proximaEtapa) => {
  //   const { mensagem, sucesso } = await OnBoardingHelper.TramitarDemanda(
  //     id,
  //     proximaEtapa
  //   );

  //   if (!sucesso) {
  //     OnBoardingHelper.exibirErro(mensagem);
  //     setLoading(false);
  //     return;
  //   }

  //   obterPorFiltroLateral();
  // };

  useEffect(() => {
    if (!unidadeSelecionada) {
      setIndexWizard(-1);
      setDemanda(null);
      return;
    }

    obterPorFiltroLateral();
  }, [unidadeSelecionada]);

  const history = useHistory();

  const agendarAtendimento = async () => {
    const renderMenu = {
      menu: true
    };
    history.push({ pathname: RotasDTO.Ajuda, state: renderMenu });
  };

  const TelaPassoAtual = useMemo(() => {
    const passo = demanda?.operacaoPassoOnboardingId;

    switch (true) {
      case passo === 1 || passo === 2 || passo === 15:
        setIndexWizard(0);
        return (
          <EnvioDocumentos
            clienteId={clienteSelecionado}
            onProximaEtapa={() => obterPorFiltroLateral()}
            pf={demanda?.pessoaFisicaJuridica === "PF"}
            showButton
            unidadeId={unidadeSelecionada}
          />
        );
      case passo === 3 ||
        passo === 4 ||
        passo === 16 ||
        passo === 17 ||
        passo === 18:
        setIndexWizard(1);
        return <AnaliseSeguranca showButton={false} />;
      case passo === 5 || passo === 6:
        setIndexWizard(2);
        return (
          <ProcessoMigracao
            showButton={false}
            agendar={() => agendarAtendimento()}
          />
        );
      case passo >= 7 && passo <= 12:
        setIndexWizard(3);
        return (
          <InstalacaoMedidor
            agendar={() => agendarAtendimento()}
            showButton={false}
          />
        );
      case passo === 13:
        setIndexWizard(4);
        return <BoasVindas showButton={false} />;

      default:
        setIndexWizard(-1);
        return semDemanda ? <UnidadeSemDemanda /> : <UnidadeNaoSelecionada />;
    }
  }, [indexWizard, demanda, semDemanda]);

  return (
    <>
      <AssistenteHorizontal
        steps={steps}
        ref={refAssistente}
        onChangeStep={(passo) => setIndexWizard(passo)}
        passoInicialFluxo={indexWizard}
      />
      <Loader loading={loading}>
        {unidadeSelecionada === "" ? <UnidadeNaoSelecionada /> : TelaPassoAtual}
      </Loader>
    </>
  );
};

export default WorkFlowGD;
