import React, { useState } from "react";
import { Box, Card, Grid } from "@mui/material";
import { Download } from "@mui/icons-material";
import { saveAs } from "file-saver";
import { Loader } from "../../../componentes";
import MemberGetMemberCupom from "./helper";

const DownloadCupons = () => {
  const [loading, setLoading] = useState(false);
  const exportarExcelCuponsAtivos = async () => {
    const {
      data,
      sucesso,
      mensagem
    } = await MemberGetMemberCupom.exportarExcelCuponsAtivos(setLoading);

    if (!sucesso) {
      setLoading(false);
      MemberGetMemberCupom.exibirErro(mensagem);
    }

    const blob = new Blob([data]);
    saveAs(blob, "relaçãoCupom.xlsx");
  };
  return (
    <>
      <Loader loading={loading}>
        <Box component={Card}>
          <Grid container spacing={1} py={3} px={2}>
            <Grid item xs={12}>
              <Box
                component="div"
                fontSize="18px"
                fontWeight="700"
                mb={2}
                color="#332053"
              >
                Faça download dos cupons ativos
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <Box
                component="button"
                bgcolor="#FA3EB1"
                color="#fff"
                textAlign="center"
                fontSize={{ xs: "15px", sm: "16px" }}
                fontWeight="700"
                borderRadius="24px"
                py={1}
                px={6}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  exportarExcelCuponsAtivos();
                }}
                id="downloadCupons"
                border="none"
              >
                <Download
                  sx={{ width: "20px", height: "20px" }}
                  htmlColor="#fff"
                />
                <Box ml="15px" component="span">
                  Gerar um Arquivo
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Loader>
    </>
  );
};

export default DownloadCupons;
