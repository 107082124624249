export default class SerasaDto {
  constructor(
    id,
    nomeUnidade,
    cnpj,
    score,
    probabilidadeInadimplencia,
    dataConsultaSerasa,
    status,
    protocoloDemanda,
    selecionado,
    produto
  ) {
    this.id = id;
    this.nomeUnidade = nomeUnidade;
    this.cnpj = cnpj;
    this.score = score;
    this.probabilidadeInadimplencia = probabilidadeInadimplencia;
    this.dataConsultaSerasa = dataConsultaSerasa;
    this.status = status;
    this.protocoloDemanda = protocoloDemanda;
    this.checked = selecionado;
    this.produto = produto;
  }
}
