import React from "react";
import PropTypes from "prop-types";
import { generate } from "shortid";

import { Grid, Button } from "@mui/material";

import { HtmlTooltip } from "paginas/Graficos/ConsumoAtual/style";
import MaterialSwitch from "componentes/switch";

import enumeradorNotificacao from "../enumeradores/enumeradorNotificacao";

const NofificacaoPlataforma = ({ onClick, parametros }) => {

  const onClickStatus = () => onClick(enumeradorNotificacao.Plataforma, parametros.id, !parametros.notificacaoPelaPlataforma);

  return (
    <Grid container spacing={0}>
        <Button key={generate()} onClick={onClickStatus}>
          <HtmlTooltip title="Status">
            <div>
              <MaterialSwitch checked={parametros.notificacaoPelaPlataforma} />
            </div>
          </HtmlTooltip>
        </Button>     
    </Grid>
  );
};

NofificacaoPlataforma.propTypes = {
  onClick: PropTypes.oneOfType([PropTypes.func]).isRequired,
  parametros: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired
};

export default NofificacaoPlataforma;