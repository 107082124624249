import { obterDemanda } from "../../../servicos/workflowServico";
import RetornoEndpointDto from "../../../global/dto/retornoEndpointDto";
import { store } from "../../../global/redux";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";
import {
  obterDadosParaTermoAdesao,
  obterProtocoloDemandaGDPorFiltroLateral,
  tramitarDemandaAtend
} from "../../../servicos/demandaAtendServico";

export default class OnBoardingHelper {
  static async ObterDadosDemanda(protocoloDemanda) {
    try {
      const response = await obterDemanda(protocoloDemanda);

      return RetornoEndpointDto.Sucesso(
        "Dados da demanda obtidos com sucesso",
        response.data
      );
    } catch (ex) {
      return RetornoEndpointDto.Erro(
        ex?.response?.data?.message ?? "Não foi possivel consultar a demanda!"
      );
    }
  }

  static async ObterDadosParaTermoAdesao(protocoloDemanda) {
    try {
      const resultado = await obterDadosParaTermoAdesao(protocoloDemanda);

      if (!resultado?.data?.validationResult?.isValid) {
        return RetornoEndpointDto.Erro("Dados incompletos para gerar o termo");
      }

      return RetornoEndpointDto.Sucesso(
        "Dados para termo obtido com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu um erro ao obter os dados do termo"
      );
    }
  }

  static async TramitarDemanda(id, proximaEtapa) {
    try {
      const resultado = await tramitarDemandaAtend(id, proximaEtapa);

      if (!resultado?.data?.validationResult?.isValid) {
        return RetornoEndpointDto.Erro(
          "Não foi possível avançar para a próxima etapa"
        );
      }

      return RetornoEndpointDto.Sucesso(
        "Processado com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Sucesso("Processado com sucesso");
      // return RetornoEndpointDto.Erro(
      //   "Não foi possível avançar para a próxima etapa"
      // );
    }
  }

  static async obterProtocoloDemanda(unidadeId, setSemDemanda, setLoading) {
    try {
      setLoading(true);
      const resposta = await obterProtocoloDemandaGDPorFiltroLateral(unidadeId);

      if (resposta.status === 204) {
        setSemDemanda(true);
        return RetornoEndpointDto.Sucesso("Passo do fluxo não encontrado!");
      }

      return RetornoEndpointDto.Sucesso(
        "Passo do fluxo obtido com sucesso!",
        resposta.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu um erro ao obter o passo do fluxo!"
      );
    } finally {
      setLoading(false);
    }
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }

  static exibirAlerta(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "warning",
        mensagem
      })
    );
  }

  static exibirSucesso(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem
      })
    );
  }
}
