import RetornoEndpointDto from "../../../global/dto/retornoEndpointDto";
import { store } from "../../../global/redux";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";
import {
  obterTabelaPrinad,
  obterProbilidadeInadimplencias,
  inserirProbalidadeInadimplencias
} from "../../../servicos/serasaServico";

export default class ScoreSerasaGDHelper {
  static async obterTabelaPrinad(setCarregandoSerasa) {
    try {
      setCarregandoSerasa(true);
      const resultado = await obterTabelaPrinad();

      return RetornoEndpointDto.Sucesso(
        "Prinad serasa encontrada com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Erro ao encontrar prinad serasa!");
    } finally {
      setCarregandoSerasa(false);
    }
  }

  static async obterProbilidadeInadimplências(setCarregandoSerasa) {
    try {
      setCarregandoSerasa(true);
      const resultado = await obterProbilidadeInadimplencias();

      return RetornoEndpointDto.Sucesso(
        "Probalidade de inadimplências serasa encontrada com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Erro ao encontrar probalidade de inadimplências serasa!"
      );
    } finally {
      setCarregandoSerasa(false);
    }
  }

  static async inserirProbalidadeInadimplencias(body, setCarregandoSerasa) {
    try {
      setCarregandoSerasa(true);
      const resultado = await inserirProbalidadeInadimplencias(body);

      return RetornoEndpointDto.Sucesso(
        "Probalidade de inadimplências serasa inserida com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Erro ao inserir probalidade de inadimplências serasa!"
      );
    } finally {
      setCarregandoSerasa(false);
    }
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }

  static exibirSucesso(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem
      })
    );
  }
}
