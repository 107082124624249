/* eslint-disable import/no-unresolved */
/* eslint-disable eqeqeq */
import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Grid } from "@mui/material";
import { useForm, useWatch } from "react-hook-form";
import DataTable from "react-data-table-component";
import Coluna from "componentes/tabelaPaginada/colunas/coluna";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import ColunaComponentePersonalizado from "componentes/tabelaPaginada/colunas/colunaComponentePersonalizado";
import Loader from "componentes/loader";
import MaterialInputTexto from "componentes/inputTexto/materialInput";
import RotasDTO from "../../rotas/rotasUrlDto";
import { useStyles, customStyles, conditionalRowStyles } from "./style";
import { obterProjecaoInsumosImportacoes } from "../../servicos/projecaoInsumos";
import enumeradorBotao from "./enumeradores/enumeradorBotao";
import ColunaMultiplosBotoes from "./colunaCustomizada";
import { HubConnectionBuilder, HttpTransportType } from '@microsoft/signalr';

const ProjecaoInsumoListar = () => {
  const history = useHistory();
  const classes = useStyles();
  const [connection, setConnection] = useState(null);

  const [atual, setAtual] = useState();
  const [atualFiltrado, setAtualFiltrado] = useState([]);
  const [carregandoDados, setCarregandoDados] = useState(false);
  
  
  const { register, control } = useForm({
    reValidateMode: "onSubmit"
  });

  const textoBusca = useWatch({
    control,
    name: "textoBusca",
    defaultValue: ""
  });

  const stringBusca = useMemo(() => {
    return textoBusca;
  }, [textoBusca]);

  const obterInsumos = async () => {
    setCarregandoDados(true);
    try {
      const lista = await obterProjecaoInsumosImportacoes();
      if (lista?.data !== null) {
        const response = lista?.data;
        const organizado = [];
        if (response) {
          response.map((item) => organizado.push({
            ...item,
            id: item.idProjecaoInsumo,
            dataUltimaImport: item.dataUltimaImportacao != null ? window.moment(item.dataUltimaImportacao).format("DD-MM-YYYY HH:mm:ss") : ""
          }));
        }
        setAtual(organizado);
        setAtualFiltrado(organizado);
      }
      setCarregandoDados(false);
    } catch (error) {
      setCarregandoDados(false);
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ?? error?.message ?? "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };


  function filterByNome(obj) {
    if (obj.nome != null && obj.nome.toUpperCase().includes(textoBusca.toUpperCase())) {
      return true;
    }
    return false;

  }

  const handleFiltrarInsumos = () => {
    let dadosFiltrados = [];

    if (atual != null && atual.length > 0) {
      dadosFiltrados = atual;

      if (stringBusca != undefined && stringBusca.length > 0) {
        dadosFiltrados = dadosFiltrados.filter(filterByNome);
      }
    }
    setAtualFiltrado(dadosFiltrados);
  }

  useEffect(() => {
    setTimeout(() => {
      handleFiltrarInsumos();
    }, 100);
  }, [stringBusca]);

  const onClickImportar = (id, codigo) => {
    const rota = atual.filter((i) => i.id == id)[0]?.comandoImportar
    if (rota != null && rota != undefined && rota != "") {
      history.push(`${RotasDTO.ProjecaoInsumoListar}${rota}?id=${id}&codigo=${codigo}`);
    } else {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Tela para importação não configurada"
        })
      );
    }
  };

  const onClickVisualizar = (id) => {
    const rota = atual.filter((i) => i.id == id)[0]?.comandoVisualizar

    if (rota != null && rota != undefined && rota != "") {
      history.push(`${RotasDTO.ProjecaoInsumoListar}${rota}`);
    } else {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Tela para visualização não configurada"
        })
      );
    }
  };

  const onClickVisualizarHistorico = (idProjecaoInsumo) => {
    if (idProjecaoInsumo != null && idProjecaoInsumo != undefined && idProjecaoInsumo != 0) {
      const dataUltimaImportacao = atual.filter((i) => i.id == idProjecaoInsumo)[0]?.dataUltimaImportacao

      if (dataUltimaImportacao != null && dataUltimaImportacao != undefined && dataUltimaImportacao != "") {
        history.push(`${RotasDTO.ProjecaoInsumoListar}/historico/${idProjecaoInsumo}`);
      } else {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "Histórico não encontrado"
          })
        );
      }
    }
  };

  const onClickBotao = (tipo, parametros) => {
    switch (tipo) {
      case enumeradorBotao.Importar:
        onClickImportar(parametros.id, parametros.codigo);
        break;
      case enumeradorBotao.Visualizar:
        onClickVisualizar(parametros.id);
        break;
      case enumeradorBotao.Historico:
        onClickVisualizarHistorico(parametros.id);
        break;
      default:
        break;
    }
  };

  const colunas = [
    new Coluna("nome", "Nome", true, () => {}, false, "400px", "400px"),
    new Coluna("dataUltimaImport", "Última Execução", false, "250px", "250px"),
    new Coluna("usuarioUltimaImportacao", "Usuário", true),
    new ColunaComponentePersonalizado(
      "opcoes",
      "Ações",
      ColunaMultiplosBotoes,
      onClickBotao,
      false,
      false,
      "190px"
    )
  ];

  useEffect(() => {
    const abortController = new AbortController();
    obterInsumos(abortController);
    return () => {
      abortController.abort();
    }
  }, []);

  return (
    <>
      <Loader loading={carregandoDados} className="w-auto">
        <Grid container spacing={3}>
          <Grid item xs={5} xl={7}>
            <MaterialInputTexto
              type="text"
              id="textoBusca"
              name="textoBusca"
              label="Buscar"
              renderIconShowHide
              searchAdornment
              defaultValue={textoBusca ?? ""}
              ref={register}
              className={classes.search}
              permiteValorBranco
            />
          </Grid>
        </Grid>
        <Grid container mt={6}>
          {atualFiltrado?.length > 0 ?
            <DataTable
              columns={colunas}
              data={atualFiltrado}
              theme="DeltaEnergia"
              customStyles={customStyles}
              conditionalRowStyles={conditionalRowStyles}
              noDataComponent="Nenhum registro encontrado"
              noHeader
            />
            :
            ""
          }
        </Grid>
      </Loader>
    </>
  )
}

export default ProjecaoInsumoListar;