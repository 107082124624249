import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import { Grid, Menu } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

import BotaoModalFiltro from "../botaoModalFiltro";
import BotaoFiltro from "./componente/botaoFiltro";
import BotaoOrdenador from "../botaoOrdenador";

import { useStyles } from "./style";

const FiltroOrdenacao = ({
  anchorEl,
  open,
  onClose,
  ordenacao,
  ordemSelecionada,
  filtros,
  filtroProduto,
  filtroTipoEnergia,
  filtroSubmercado,
  filtroStatus,
  filtrosSelecionados,
  filtroProdutoSelecionados,
  filtroTipoEnergiaSelecionados,
  filtroSubmercadoSelecionados,
  filtroStatusSelecionados,
  ordemColunaSelecionada,
  onClickOrdenacao,
  onClickFiltro,
  onClickFiltroProduto,
  onClickFiltroTipoDeEnergia,
  onClickFiltroSubmercado,
  onClickFiltroStatus,
  onClickSortOrder,
  onClickAtivarAgora,
  tipos,
  onClickTipo,
  tiposSelecionados,
  ordenador,
  ordenadorUm,
  ordenadorDois,
  ordenadorTres,
  ordenadorQuatro,
  ordenadorCinco,
  ordenadorSeis,
  ordenadorSete,
  ordenacaoColuna,
  larguraBotao,
  filtroPagamento,
  filtroPagamentoSelecionados,
  onClickFiltroPagamento,
  filtroTipoCliente,
  filtrosTipoClienteSelecionado,
  onClickFitroTipoCliente,

  ordenadorOito,
  filtroEmpresas,
  filtroSelecionadosEmpresa,
  onClickFiltroEmpresa,

  ordenadorNove,
  filtroTipoTC,
  filtrosTipoTCSelecionado,
  onClickFitroTipoTC,

  ordenadorDez,
  filtroTipoMedidores,
  filtrosTipoMedidoresSelecionado,
  onClickFitroTipoMedidores
}) => {
  const classes = useStyles();
  const botaoFiltroRef = useRef();
  const [contador, setContador] = useState(0);
  const [botaoOrdenado, setBotaoOrdenado] = useState(false);
  const [valorSelecionado, setValorSelecionado] = useState(null);

  const handleOnClickOrdenacao = (event) => {
    onClickOrdenacao(event.target.value);
    botaoFiltroRef.current.focus();
  };

  const handleOnClickFiltro = (event) => {
    onClickFiltro(event.target.value);
    botaoFiltroRef.current.focus();
  };

  const handleOnClickFiltroProduto = (event) => {
    onClickFiltroProduto(event.target.value);
    botaoFiltroRef.current.focus();
  };

  const handleOnClickFiltroPagamento = (event) => {
    onClickFiltroPagamento(event.target.value);
    botaoFiltroRef.current.focus();
  };

  const handleOnClickFiltroEmpresas = (event) => {
    onClickFiltroEmpresa(event.target.value);
    botaoFiltroRef.current.focus();
  };

  const handleOnClickFiltroTipoDeEnergia = (event) => {
    onClickFiltroTipoDeEnergia(event.target.value);
    botaoFiltroRef.current.focus();
  };
  const handleOnClickFiltroSubmercado = (event) => {
    onClickFiltroSubmercado(event.target.value);
    botaoFiltroRef.current.focus();
  };
  const handleOnClickFiltroStatus = (event) => {
    onClickFiltroStatus(event.target.value);
    botaoFiltroRef.current.focus();
  };

  const handleOnClickFiltroTipoCliente = (event) => {
    onClickFitroTipoCliente(event.target.value);
    botaoFiltroRef.current.focus();
  };

  const handleOnClickFiltroTipoTC = (event) => {
    onClickFitroTipoTC(event.target.value);
    botaoFiltroRef.current.focus();
  };

  const handleOnClickFiltroTipoMedidores = (event) => {
    onClickFitroTipoMedidores(event.target.value);
    botaoFiltroRef.current.focus();
  };

  useEffect(() => {
    // Botão habilitado e ordernar DESC
    setBotaoOrdenado(contador === 2);
  }, [contador, valorSelecionado]);

  const handleOnClickSortOrder = (event) => {
    const idItemSelecionado = event.target.value;
    let contadorTemporario = 0;

    // Habilita botão e ordernar ASC
    if (contador === 0) {
      contadorTemporario = 1;
    } else if (idItemSelecionado !== valorSelecionado) {
      contadorTemporario = 1;
    } else if (botaoOrdenado) {
      contadorTemporario = 0;
    } else {
      contadorTemporario = 2;
    }

    const valorOrdenacao =
      contadorTemporario !== 0
        ? // eslint-disable-next-line no-use-before-define
          obterValorOrdenacao(idItemSelecionado, contadorTemporario === 2)
        : "";

    setContador(contadorTemporario);
    setValorSelecionado(idItemSelecionado);
    onClickSortOrder(valorOrdenacao, idItemSelecionado);

    botaoFiltroRef.current.focus();
  };

  const handleOnClickTipo = (event) => {
    onClickTipo(event.target.value);
    botaoFiltroRef.current.focus();
  };

  const handleOnClickAtivarAgora = () => {
    onClickAtivarAgora();
  };

  const itemSelecionado = useCallback(
    (item) => {
      return String(item) === String(ordemSelecionada);
    },
    [ordemSelecionada]
  );

  const colunaSelecionada = useCallback(
    (item) => {
      return (
        String(item) === String(ordemColunaSelecionada) && (
          <ArrowUpwardIcon
            className={botaoOrdenado ? classes.asc : classes.desc}
          />
        )
      );
    },
    [ordemColunaSelecionada, botaoOrdenado]
  );

  const obterValorOrdenacao = (item, eDesc) => {
    // eslint-disable-next-line no-shadow
    const ordenacao = ordenacaoColuna.find(
      (item2) => String(item2.key) === String(item)
    );
    if (ordenacao === null || !ordenacao) return "";
    return String(item) === String(ordemColunaSelecionada)
      ? eDesc
        ? ordenacao.desc
        : ordenacao.asc
      : ordenacao.asc;
  };

  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(open)}
      onClose={onClose}
      anchorOrigin={{ vertical: 55, horizontal: 384.5 }}
      classes={{ paper: classes.menu }}
    >
      <Grid container spacing={0} className={classes.filterContainer}>
        {ordenacaoColuna?.length ? (
          <>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={classes.containerTitulo}
            >
              {ordenador}
            </Grid>
            <Grid
              container
              spacing={1}
              className={`${classes.filterContainer} ${classes.itemContainer}`}
            >
              {ordenacaoColuna.map((ordemColuna, index) => {
                return (
                  <Grid
                    key={index}
                    item
                    lg={larguraBotao ?? 4}
                    md={3}
                    sm={3}
                    xs={3}
                  >
                    <BotaoOrdenador
                      id={ordemColuna.key}
                      label={ordemColuna.label}
                      value={ordemColuna.key}
                      onClick={(event) => handleOnClickSortOrder(event)}
                      stateless={false}
                      style={{ fontSize: "12px" }}
                      selected={colunaSelecionada(ordemColuna.key)}
                      icon={colunaSelecionada(ordemColuna.key)}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </>
        ) : null}
        {ordenacao?.length ? (
          <>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={classes.containerTitulo}
            >
              {ordenadorUm}
            </Grid>
            <Grid
              container
              spacing={1}
              className={`${classes.filterContainer} ${classes.itemContainer}`}
            >
              {ordenacao.map((ordem, index) => {
                return (
                  <Grid
                    key={index}
                    item
                    lg={larguraBotao ?? 4}
                    md={3}
                    sm={3}
                    xs={3}
                  >
                    <BotaoModalFiltro
                      id={ordem.key}
                      label={ordem.label}
                      value={ordem.key}
                      onClick={(o) => handleOnClickOrdenacao(o)}
                      stateless={false}
                      style={{ fontSize: "12px" }}
                      selected={itemSelecionado(ordem.key)}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </>
        ) : null}

        {filtroTipoCliente?.length ? (
          <>
            <Grid
              className={classes.filterContainer}
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              {ordenadorTres}
            </Grid>
            <Grid container spacing={1}>
              <Grid container spacing={1} className="mr-1 ml-1 mt-1">
                {filtroTipoCliente.map((cliente, index) => {
                  return (
                    <Grid
                      key={index}
                      item
                      lg={larguraBotao ?? 4}
                      md={3}
                      sm={3}
                      xs={3}
                    >
                      <BotaoModalFiltro
                        id={String(cliente.key)}
                        label={cliente.label}
                        value={String(cliente.key)}
                        onClick={(f) => handleOnClickFiltroTipoCliente(f)}
                        stateless={false}
                        style={{ fontSize: "12px" }}
                        selected={
                          String(filtrosTipoClienteSelecionado) ===
                          String(cliente.key)
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
              <Grid container spacing={1} className="mr-1 ml-1 mt-1 mb-2">
                {tipos?.map((tipo, index) => {
                  return (
                    <Grid
                      key={index}
                      item
                      lg={larguraBotao ?? 4}
                      md={3}
                      sm={3}
                      xs={3}
                    >
                      <BotaoOrdenador
                        id={String(tipo.key)}
                        label={tipo.label}
                        value={String(tipo.key)}
                        onClick={(f) => handleOnClickTipo(f)}
                        stateless={false}
                        style={{ fontSize: "12px" }}
                        selected={
                          String(tiposSelecionados) === String(tipo.key)
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </>
        ) : null}

        {filtroTipoTC?.length ? (
          <>
            <Grid
              className={classes.filterContainer}
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              {ordenadorNove}
            </Grid>
            <Grid container spacing={1}>
              <Grid container spacing={1} className="mr-1 ml-1 mt-1">
                {filtroTipoTC.map((tipoTc, index) => {
                  return (
                    <Grid
                      key={index}
                      item
                      lg={larguraBotao ?? 4}
                      md={3}
                      sm={3}
                      xs={3}
                    >
                      <BotaoModalFiltro
                        id={String(tipoTc.key)}
                        label={tipoTc.label}
                        value={String(tipoTc.key)}
                        onClick={(f) => handleOnClickFiltroTipoTC(f)}
                        stateless={false}
                        style={{ fontSize: "12px" }}
                        selected={
                          String(filtrosTipoTCSelecionado) ===
                          String(tipoTc.key)
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
              <Grid container spacing={1} className="mr-1 ml-1 mt-1 mb-2">
                {tipos?.map((tipo, index) => {
                  return (
                    <Grid
                      key={index}
                      item
                      lg={larguraBotao ?? 4}
                      md={3}
                      sm={3}
                      xs={3}
                    >
                      <BotaoOrdenador
                        id={String(tipo.key)}
                        label={tipo.label}
                        value={String(tipo.key)}
                        onClick={(f) => handleOnClickTipo(f)}
                        stateless={false}
                        style={{ fontSize: "12px" }}
                        selected={
                          String(tiposSelecionados) === String(tipo.key)
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </>
        ) : null}

        {filtroTipoMedidores?.length ? (
          <>
            <Grid
              className={classes.filterContainer}
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              {ordenadorDez}
            </Grid>
            <Grid container spacing={1}>
              <Grid container spacing={1} className="mr-1 ml-1 mt-1">
                {filtroTipoMedidores.map((tipoMedidores, index) => {
                  return (
                    <Grid
                      key={index}
                      item
                      lg={larguraBotao ?? 4}
                      md={3}
                      sm={3}
                      xs={3}
                    >
                      <BotaoModalFiltro
                        id={String(tipoMedidores.key)}
                        label={tipoMedidores.label}
                        value={String(tipoMedidores.key)}
                        onClick={(f) => handleOnClickFiltroTipoMedidores(f)}
                        stateless={false}
                        style={{ fontSize: "12px" }}
                        selected={
                          String(filtrosTipoMedidoresSelecionado) ===
                          String(tipoMedidores.key)
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
              <Grid container spacing={1} className="mr-1 ml-1 mt-1 mb-2">
                {tipos?.map((tipo, index) => {
                  return (
                    <Grid
                      key={index}
                      item
                      lg={larguraBotao ?? 4}
                      md={3}
                      sm={3}
                      xs={3}
                    >
                      <BotaoOrdenador
                        id={String(tipo.key)}
                        label={tipo.label}
                        value={String(tipo.key)}
                        onClick={(f) => handleOnClickTipo(f)}
                        stateless={false}
                        style={{ fontSize: "12px" }}
                        selected={
                          String(tiposSelecionados) === String(tipo.key)
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </>
        ) : null}

        {filtros?.length ? (
          <>
            <Grid
              className={classes.filterContainer}
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              {ordenadorDois}
            </Grid>
            <Grid container spacing={1}>
              <Grid container spacing={1} className="mr-1 ml-1 mt-1">
                {filtros.map((filtro, index) => {
                  return (
                    <Grid
                      key={index}
                      item
                      lg={larguraBotao ?? 4}
                      md={3}
                      sm={3}
                      xs={3}
                    >
                      <BotaoModalFiltro
                        id={String(filtro.key)}
                        label={filtro.label}
                        value={String(filtro.key)}
                        onClick={(f) => handleOnClickFiltro(f)}
                        stateless={false}
                        style={{ fontSize: "12px" }}
                        selected={
                          String(filtrosSelecionados) === String(filtro.key)
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
              <Grid container spacing={1} className="mr-1 ml-1 mt-1 mb-2">
                {tipos?.map((tipo, index) => {
                  return (
                    <Grid
                      key={index}
                      item
                      lg={larguraBotao ?? 4}
                      md={3}
                      sm={3}
                      xs={3}
                    >
                      <BotaoOrdenador
                        id={String(tipo.key)}
                        label={tipo.label}
                        value={String(tipo.key)}
                        onClick={(f) => handleOnClickTipo(f)}
                        stateless={false}
                        style={{ fontSize: "12px" }}
                        selected={
                          String(tiposSelecionados) === String(tipo.key)
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </>
        ) : null}

        {filtroProduto?.length ? (
          <>
            <Grid
              className={classes.filterContainer}
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              {ordenadorTres}
            </Grid>
            <Grid container spacing={1}>
              <Grid container spacing={1} className="mr-1 ml-1 mt-1">
                {filtroProduto.map((itemProduto, index) => {
                  return (
                    <Grid
                      key={index}
                      item
                      lg={larguraBotao ?? 4}
                      md={3}
                      sm={3}
                      xs={3}
                    >
                      <BotaoModalFiltro
                        id={String(itemProduto.key)}
                        label={itemProduto.label}
                        value={String(itemProduto.key)}
                        onClick={(f) => handleOnClickFiltroProduto(f)}
                        stateless={false}
                        style={{ fontSize: "12px" }}
                        selected={
                          String(filtroProdutoSelecionados) ===
                          String(itemProduto.key)
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
              <Grid container spacing={1} className="mr-1 ml-1 mt-1 mb-2">
                {tipos?.map((tipo, index) => {
                  return (
                    <Grid
                      key={index}
                      item
                      lg={larguraBotao ?? 4}
                      md={3}
                      sm={3}
                      xs={3}
                    >
                      <BotaoOrdenador
                        id={String(tipo.key)}
                        label={tipo.label}
                        value={String(tipo.key)}
                        onClick={(f) => handleOnClickTipo(f)}
                        stateless={false}
                        style={{ fontSize: "12px" }}
                        selected={
                          String(tiposSelecionados) === String(tipo.key)
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </>
        ) : null}

        {filtroTipoEnergia?.length ? (
          <>
            <Grid
              className={classes.filterContainer}
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              {ordenadorQuatro}
            </Grid>
            <Grid container spacing={1}>
              <Grid container spacing={1} className="mr-1 ml-1 mt-1">
                {filtroTipoEnergia.map((itemTipoEnergia, index) => {
                  return (
                    <Grid
                      key={index}
                      item
                      lg={larguraBotao ?? 4}
                      md={3}
                      sm={3}
                      xs={3}
                    >
                      <BotaoModalFiltro
                        id={String(itemTipoEnergia.key)}
                        label={itemTipoEnergia.label}
                        value={String(itemTipoEnergia.key)}
                        onClick={(f) => handleOnClickFiltroTipoDeEnergia(f)}
                        stateless={false}
                        style={{ fontSize: "12px" }}
                        selected={
                          String(filtroTipoEnergiaSelecionados) ===
                          String(itemTipoEnergia.key)
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
              <Grid container spacing={1} className="mr-1 ml-1 mt-1 mb-2">
                {tipos?.map((tipo, index) => {
                  return (
                    <Grid
                      key={index}
                      item
                      lg={larguraBotao ?? 4}
                      md={3}
                      sm={3}
                      xs={3}
                    >
                      <BotaoOrdenador
                        id={String(tipo.key)}
                        label={tipo.label}
                        value={String(tipo.key)}
                        onClick={(f) => handleOnClickTipo(f)}
                        stateless={false}
                        style={{ fontSize: "12px" }}
                        selected={
                          String(tiposSelecionados) === String(tipo.key)
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </>
        ) : null}

        {filtroSubmercado?.length ? (
          <>
            <Grid
              className={classes.filterContainer}
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              {ordenadorCinco}
            </Grid>
            <Grid container spacing={1}>
              <Grid container spacing={1} className="mr-1 ml-1 mt-1">
                {filtroSubmercado.map((itemSubmercado, index) => {
                  return (
                    <Grid
                      key={index}
                      item
                      lg={larguraBotao ?? 4}
                      md={3}
                      sm={3}
                      xs={3}
                    >
                      <BotaoModalFiltro
                        id={String(itemSubmercado.key)}
                        label={itemSubmercado.label}
                        value={String(itemSubmercado.key)}
                        onClick={(f) => handleOnClickFiltroSubmercado(f)}
                        stateless={false}
                        style={{ fontSize: "12px" }}
                        selected={
                          String(filtroSubmercadoSelecionados) ===
                          String(itemSubmercado.key)
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
              <Grid container spacing={1} className="mr-1 ml-1 mt-1 mb-2">
                {tipos?.map((tipo, index) => {
                  return (
                    <Grid
                      key={index}
                      item
                      lg={larguraBotao ?? 4}
                      md={3}
                      sm={3}
                      xs={3}
                    >
                      <BotaoOrdenador
                        id={String(tipo.key)}
                        label={tipo.label}
                        value={String(tipo.key)}
                        onClick={(f) => handleOnClickTipo(f)}
                        stateless={false}
                        style={{ fontSize: "12px" }}
                        selected={
                          String(tiposSelecionados) === String(tipo.key)
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </>
        ) : null}

        {filtroStatus?.length ? (
          <>
            <Grid
              className={classes.filterContainer}
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              {ordenadorSeis}
            </Grid>
            <Grid container spacing={1}>
              <Grid container spacing={1} className="mr-1 ml-1 mt-1">
                {filtroStatus.map((itemStatus, index) => {
                  return (
                    <Grid
                      key={index}
                      item
                      lg={larguraBotao ?? 4}
                      md={3}
                      sm={3}
                      xs={3}
                    >
                      <BotaoModalFiltro
                        id={String(itemStatus.key)}
                        label={itemStatus.label}
                        value={String(itemStatus.key)}
                        onClick={(f) => handleOnClickFiltroStatus(f)}
                        stateless={false}
                        style={{ fontSize: "12px" }}
                        selected={
                          String(filtroStatusSelecionados) ===
                          String(itemStatus.key)
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
              <Grid container spacing={1} className="mr-1 ml-1 mt-1 mb-2">
                {tipos?.map((tipo, index) => {
                  return (
                    <Grid
                      key={index}
                      item
                      lg={larguraBotao ?? 4}
                      md={3}
                      sm={3}
                      xs={3}
                    >
                      <BotaoOrdenador
                        id={String(tipo.key)}
                        label={tipo.label}
                        value={String(tipo.key)}
                        onClick={(f) => handleOnClickTipo(f)}
                        stateless={false}
                        style={{ fontSize: "12px" }}
                        selected={
                          String(tiposSelecionados) === String(tipo.key)
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </>
        ) : null}

        {filtroPagamento?.length ? (
          <>
            <Grid
              className={classes.filterContainer}
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              {ordenadorSete}
            </Grid>
            <Grid container spacing={1}>
              <Grid container spacing={1} className="mr-1 ml-1 mt-1">
                {filtroPagamento.map((itemPagamento, index) => {
                  return (
                    <Grid
                      key={index}
                      item
                      lg={larguraBotao ?? 4}
                      md={3}
                      sm={3}
                      xs={3}
                    >
                      <BotaoModalFiltro
                        id={String(itemPagamento.key)}
                        label={itemPagamento.label}
                        value={String(itemPagamento.key)}
                        onClick={(f) => handleOnClickFiltroPagamento(f)}
                        stateless={false}
                        style={{ fontSize: "12px" }}
                        selected={
                          String(filtroPagamentoSelecionados) ===
                          String(itemPagamento.key)
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
              <Grid container spacing={1} className="mr-1 ml-1 mt-1 mb-2">
                {tipos?.map((tipo, index) => {
                  return (
                    <Grid
                      key={index}
                      item
                      lg={larguraBotao ?? 4}
                      md={3}
                      sm={3}
                      xs={3}
                    >
                      <BotaoOrdenador
                        id={String(tipo.key)}
                        label={tipo.label}
                        value={String(tipo.key)}
                        onClick={(f) => handleOnClickTipo(f)}
                        stateless={false}
                        style={{ fontSize: "12px" }}
                        selected={
                          String(tiposSelecionados) === String(tipo.key)
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </>
        ) : null}

        {filtroEmpresas?.length ? (
          <>
            <Grid
              className={classes.filterContainer}
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              {ordenadorOito}
            </Grid>
            <Grid container spacing={1}>
              <Grid container spacing={1} className="mr-1 ml-1 mt-1">
                {filtroEmpresas.map((itemEmpresa, index) => {
                  return (
                    <Grid
                      key={index}
                      item
                      lg={larguraBotao ?? 4}
                      md={3}
                      sm={3}
                      xs={3}
                    >
                      <BotaoModalFiltro
                        id={String(itemEmpresa.key)}
                        label={itemEmpresa.label}
                        value={String(itemEmpresa.key)}
                        onClick={(f) => handleOnClickFiltroEmpresas(f)}
                        stateless={false}
                        style={{ fontSize: "12px" }}
                        selected={
                          String(filtroSelecionadosEmpresa) ===
                          String(itemEmpresa.key)
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
              <Grid container spacing={1} className="mr-1 ml-1 mt-1 mb-2">
                {tipos?.map((tipo, index) => {
                  return (
                    <Grid
                      key={index}
                      item
                      lg={larguraBotao ?? 4}
                      md={3}
                      sm={3}
                      xs={3}
                    >
                      <BotaoOrdenador
                        id={String(tipo.key)}
                        label={tipo.label}
                        value={String(tipo.key)}
                        onClick={(f) => handleOnClickTipo(f)}
                        stateless={false}
                        style={{ fontSize: "12px" }}
                        selected={
                          String(tiposSelecionados) === String(tipo.key)
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </>
        ) : null}

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <BotaoFiltro
            label="Ativar agora"
            icon={<ArrowForwardIcon />}
            onClick={() => handleOnClickAtivarAgora()}
            ref={botaoFiltroRef}
          />
        </Grid>
      </Grid>
    </Menu>
  );
};

FiltroOrdenacao.propTypes = {
  anchorEl: PropTypes.oneOfType([PropTypes.any]),
  open: PropTypes.oneOfType([PropTypes.any]),
  onClose: PropTypes.oneOfType([PropTypes.func]),
  ordenacao: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  ordemSelecionada: PropTypes.oneOfType([PropTypes.any]),
  ordemColunaSelecionada: PropTypes.oneOfType([PropTypes.any]),
  filtros: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  filtroProduto: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  filtroTipoEnergia: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  filtroSubmercado: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  filtroStatus: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  filtrosSelecionados: PropTypes.oneOfType([PropTypes.any]),
  filtroProdutoSelecionados: PropTypes.oneOfType([PropTypes.any]),
  filtroTipoEnergiaSelecionados: PropTypes.oneOfType([PropTypes.any]),
  filtroSubmercadoSelecionados: PropTypes.oneOfType([PropTypes.any]),
  filtroStatusSelecionados: PropTypes.oneOfType([PropTypes.any]),
  onClickOrdenacao: PropTypes.oneOfType([PropTypes.func]),
  onClickFiltro: PropTypes.oneOfType([PropTypes.func]),
  onClickFiltroProduto: PropTypes.oneOfType([PropTypes.func]),
  onClickFiltroTipoDeEnergia: PropTypes.oneOfType([PropTypes.func]),
  onClickFiltroSubmercado: PropTypes.oneOfType([PropTypes.func]),
  onClickFiltroStatus: PropTypes.oneOfType([PropTypes.func]),
  onClickSortOrder: PropTypes.oneOfType([PropTypes.func]),
  onClickAtivarAgora: PropTypes.oneOfType([PropTypes.func]),
  tipos: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onClickTipo: PropTypes.oneOfType([PropTypes.func]),
  tiposSelecionados: PropTypes.oneOfType([PropTypes.any]),
  ordenador: PropTypes.oneOfType([PropTypes.any]),
  ordenadorUm: PropTypes.oneOfType([PropTypes.any]),
  ordenadorDois: PropTypes.oneOfType([PropTypes.any]),
  ordenadorTres: PropTypes.oneOfType([PropTypes.any]),
  ordenadorQuatro: PropTypes.oneOfType([PropTypes.any]),
  ordenadorCinco: PropTypes.oneOfType([PropTypes.any]),
  ordenadorSeis: PropTypes.oneOfType([PropTypes.any]),
  ordenadorSete: PropTypes.oneOfType([PropTypes.any]),
  ordenacaoColuna: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  larguraBotao: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),

  filtroPagamento: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  filtroPagamentoSelecionados: PropTypes.oneOfType([PropTypes.any]),
  onClickFiltroPagamento: PropTypes.oneOfType([PropTypes.func]),

  filtroTipoCliente: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  filtrosTipoClienteSelecionado: PropTypes.oneOfType([PropTypes.any]),
  onClickFitroTipoCliente: PropTypes.oneOfType([PropTypes.func]),

  ordenadorOito: PropTypes.oneOfType([PropTypes.any]),
  filtroEmpresas: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  filtroSelecionadosEmpresa: PropTypes.oneOfType([PropTypes.any]),
  onClickFiltroEmpresa: PropTypes.oneOfType([PropTypes.func]),

  ordenadorNove: PropTypes.oneOfType([PropTypes.any]),
  filtroTipoTC: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  filtrosTipoTCSelecionado: PropTypes.oneOfType([PropTypes.any]),
  onClickFitroTipoTC: PropTypes.oneOfType([PropTypes.func]),

  ordenadorDez: PropTypes.oneOfType([PropTypes.any]),
  filtroTipoMedidores: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  filtrosTipoMedidoresSelecionado: PropTypes.oneOfType([PropTypes.any]),
  onClickFitroTipoMedidores: PropTypes.oneOfType([PropTypes.func])
};

FiltroOrdenacao.defaultProps = {
  anchorEl: {},
  open: false,
  onClose: () => {},
  ordenacao: [],
  ordemSelecionada: "",
  ordemColunaSelecionada: "",
  filtros: [],
  filtroProduto: [],
  filtroTipoEnergia: [],
  filtroSubmercado: [],
  filtroStatus: [],
  filtrosSelecionados: "",
  filtroProdutoSelecionados: [],
  filtroTipoEnergiaSelecionados: [],
  filtroSubmercadoSelecionados: [],
  filtroStatusSelecionados: [],
  onClickOrdenacao: () => {},
  onClickFiltro: () => {},
  onClickFiltroProduto: () => {},
  onClickFiltroTipoDeEnergia: () => {},
  onClickFiltroSubmercado: () => {},
  onClickFiltroStatus: () => {},
  onClickSortOrder: () => {},
  onClickAtivarAgora: () => {},
  tipos: [],
  onClickTipo: () => {},
  tiposSelecionados: "",
  ordenador: "",
  ordenadorUm: "",
  ordenadorDois: "",
  ordenadorTres: "",
  ordenadorQuatro: "",
  ordenadorCinco: "",
  ordenadorSeis: "",
  ordenadorSete: "",
  ordenacaoColuna: [],
  larguraBotao: false,
  filtroPagamento: [],
  filtroPagamentoSelecionados: [],
  onClickFiltroPagamento: () => {},
  filtroTipoCliente: [],
  filtrosTipoClienteSelecionado: [],
  onClickFitroTipoCliente: () => {},

  ordenadorOito: "",
  filtroEmpresas: [],
  filtroSelecionadosEmpresa: "",
  onClickFiltroEmpresa: () => {},

  ordenadorNove: "",
  filtroTipoTC: [],
  filtrosTipoTCSelecionado: "",
  onClickFitroTipoTC: () => {},

  ordenadorDez: "",
  filtroTipoMedidores: [],
  filtrosTipoMedidoresSelecionado: "",
  onClickFitroTipoMedidores: () => {}
};

export default FiltroOrdenacao;
