import { makeStyles } from '@mui/styles';
import { Base } from 'componentes/cores';

export const useStyles = makeStyles({
  container: {
    margin: "0",
    width: "100%",
    "& .MuiGrid-root": {
      color: `${Base.BlackBastille} !important`
    },
    "& .MuiInputBase-root": {
      height: "46px"
    },
    "& .MuiFormLabel-root": {
      lineHeight: "1"
    },
    "& .MuiFormControl-root": {
      width: "100%"
    }
  },
  button: {
    fontSize: '18px',
  },
});