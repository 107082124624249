import React from "react";
import PropTypes from "prop-types";
import { generate } from "shortid";

import { Grid, Button } from "@mui/material";
import { EditOutlined } from "@mui/icons-material";

import { HtmlTooltip } from "../../../../Graficos/ConsumoAtual/style";

import { useStyles } from "./style";

const ColunaMultiplosBotoes = ({ onClick, parametros }) => {
  const classes = useStyles();

  const onClickEdicao = () => onClick(1, parametros);



  return (
    <>
      <Grid container spacing={0} className={`${classes.fundoCelula}`}>
        <Grid item xs={4} className={`${classes.fundoBotoes}`}>
          <Button
            key={generate()}
            onClick={onClickEdicao}
            className={classes.botao}
          >
            <HtmlTooltip title="Editar">
              <EditOutlined htmlColor="#FA3EB1" />
            </HtmlTooltip>
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

ColunaMultiplosBotoes.propTypes = {
  onClick: PropTypes.oneOfType([PropTypes.func]).isRequired,
  parametros: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired
};

export default ColunaMultiplosBotoes;
