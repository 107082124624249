import React, { useState, useEffect } from "react";

// Componentes
import { Grid } from "@mui/material";

// Componentes
import getDomain from "global/getDomain";
import Loader from "../../../../componentes/loader";

// Ícones
import IconeConsolidado from "../../../../assets/icones/medidores/consolidado.png";
import IconeFaltantes from "../../../../assets/icones/medidores/faltantes.png";
import IconeTotalHoras from "../../../../assets/icones/medidores/total-horas.png";

import IconeConsolidadoLuz from "../../../../assets/icones/medidores/consolidado-luz.png";
import IconeFaltantesLuz from "../../../../assets/icones/medidores/faltantes-luz.png";
import IconeTotalHorasLuz from "../../../../assets/icones/medidores/total-horas-luz.png";


// Styles
import { Container } from "../../Dashboard/style";
import { useStyles, Icone } from "./style";

const MedidorHora = (props) => {
  const classes = useStyles();
  const [horasConsolidadas, setHorasConsolidadas] = useState();
  const [horasFaltantes, setHorasFaltantes] = useState();
  const [totalHorasMes, setTotalHorasMes] = useState();
  const [carregandoMedidores, setCarregandoMedidores] = useState(false);
  const values = props;

  useEffect(() => {
    setCarregandoMedidores(true);
    setHorasConsolidadas(values?.medidorSelecionado?.contagemHorasCompletas);
    setHorasFaltantes(values?.medidorSelecionado?.contagemHorasIncompletas);
    setTotalHorasMes(values?.medidorSelecionado?.totalHorasMes);
    setCarregandoMedidores(false);
  }, [values?.medidorSelecionado]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Container className={classes.card}>
          <Loader loading={carregandoMedidores} className="w-auto">
            <Grid
              container
              justifyContent="center"
              alignContent="center"
              alignItems="center"
              className={classes.topo}
            >
              <Grid item xs={3} className={classes.iconeWrapper}>
                <Icone src={getDomain() === "luz" ? IconeConsolidadoLuz : IconeConsolidado} />
              </Grid>
              <Grid item xs={9} alignContent="center" alignItems="center">
                <Grid item className={classes.dados}>
                  {horasConsolidadas ?? 0}
                  <span>h</span>
                </Grid>
                <Grid item className={classes.info}>
                  Horas consolidadas
                </Grid>
              </Grid>
            </Grid>
          </Loader>
        </Container>
      </Grid>

      <Grid item xs={12}>
        <Container className={classes.card}>
          <Loader loading={carregandoMedidores} className="w-auto">
            <Grid
              container
              justifyContent="center"
              alignContent="center"
              alignItems="center"
              className={classes.topo}
            >
              <Grid item xs={3} className={classes.iconeWrapper}>
                <Icone src={getDomain() === "luz" ? IconeFaltantesLuz : IconeFaltantes} />
              </Grid>
              <Grid item xs={9} alignContent="center" alignItems="center">
                <Grid item className={classes.dados}>
                  {horasFaltantes ?? 0}
                  <span>h</span>
                </Grid>
                <Grid item className={classes.info}>
                  Horas Faltantes
                </Grid>
              </Grid>
            </Grid>
          </Loader>
        </Container>
      </Grid>

      <Grid item xs={12}>
        <Container className={`${classes.card} ${classes.trace}`}>
          <Loader loading={carregandoMedidores} className="w-auto">
            <Grid
              item
              container
              justifyContent="center"
              alignContent="center"
              alignItems="center"
              className={classes.topo}
            >
              <Grid item xs={3} className={classes.iconeWrapper}>
                <Icone src={getDomain() === "luz" ? IconeTotalHorasLuz : IconeTotalHoras} />
              </Grid>
              <Grid item xs={9} alignContent="center" alignItems="center">
                <Grid item className={classes.dados}>
                  {totalHorasMes ?? 0}
                  <span>h</span>
                </Grid>
                <Grid item className={classes.info}>
                  Total de horas no mês
                </Grid>
              </Grid>
            </Grid>
          </Loader>
        </Container>
      </Grid>
    </Grid>
  );
};

export default MedidorHora;
