import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import { Box, Grid } from "@mui/material";

import { useSelector } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { store } from "global/redux";
import { usina } from "global/redux/modulos/menu/actions";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { salvarMenuSelecionadoColecao } from "../../../../global/redux/modulos/rotas/actions";
import { RotasDTO } from "../../../../global/rotas/rotasUrlDto";

import btnSaibaMais from "./imagens/botao-saiba-mais.svg";

// Styles
import {
  Economia,
  TextoEconomia,
  Consumo,
  TextoConsumo,
  MedicaoConsolidada,
  TextoMedicao,
  InformacoesCadastro,
  TextoInformacoes,
  LinkedIn,
  Site,
  EconomiaMobile,
  FinanceiroMobile,
  ConsumoMobile,
  RelatoriosMobile,
  TextoMobile,
  useStyles
} from "./style";

const Home = () => {
  const usuario = useSelector((state) => state.usuario);
  const { menu } = usuario;
  const history = useHistory();
  const classes = useStyles();
  const [usinaAtiva, setUsinaAtiva] = useState("UFVTresLagoas");
  const [dadosUsinas, setDadosUsina] = useState([]);
  const [mapaPrincipal, setMapaPrincipal] = useState();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("lg"));

  const salvarMenuColecao = (rota, colecao) => {
    if (rota && colecao?.length) {
      const segmento = colecao.find((itemColecao) =>
        itemColecao.functionality.funcionalidadeColecao.find(
          (funcionalidade) => funcionalidade.caminho === rota
        )
      );

      if (segmento?.functionality?.funcionalidadeColecao?.length) {
        store.dispatch(
          salvarMenuSelecionadoColecao([
            ...segmento?.functionality?.funcionalidadeColecao
          ])
        );
      }
    }
  };

  useEffect(() => {
    store.dispatch(
      usina({
        id: null,
        rotulo: null
      })
    );
    fetchUsinas();
    fetchMapaPrincipal();
  }, []);

  const fetchUsinas = async () => {
    try {
      fetch(
        "https://apiapp.sualuz.com.br/wpusinas/index.php/wp-json/wp/v2/pages?per_page=99&orderby=id&order=asc",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setDadosUsina(data);
        });
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  const fetchMapaPrincipal = async () => {
    try {
      fetch(
        "https://painelusinas.deltaenergia.com.br/index.php/wp-json/wp/v2/media?parent=1",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setMapaPrincipal(data[0]?.source_url);
        });
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  return (
    <>
      {usuario?.usuario?.role === "Perfil Gráficos Delta" ||
      usuario?.usuario?.role === "Perfil Gráficos Delta Cliente" ? (
        <div className={classes.bgMapa}>
          <div className="row">
            <div className="col-md-1">
              {dadosUsinas?.length > 0 &&
                dadosUsinas?.slice(0, 10)?.map((item) => {
                  return (
                    item?.acf?.ativar_usina && (
                      <>
                        <Box
                          component="div"
                          onClick={() => {
                            setUsinaAtiva(item?.slug);
                            setMapaPrincipal(item?.acf?.imagem_mapa);
                          }}
                          className={`mt-3 ${classes.boxUsina} ${
                            usinaAtiva === item?.slug
                              ? `${classes.bordaAmarela} ${classes.usinaAberta}`
                              : classes.usinaFechada
                          }`}
                        >
                          <div className={classes.nomeUsina}>
                            {item?.title?.rendered}
                            <div
                              className={`float-right ${classes.cursorPointer}`}
                            >
                              {usinaAtiva === item?.slug ? (
                                <RemoveIcon />
                              ) : (
                                <AddIcon />
                              )}
                            </div>
                          </div>
                          <div
                            className={`mt-3 ${
                              usinaAtiva === item?.slug ? "d-block" : "d-none"
                            }`}
                          >
                            <div className={classes.dadosUsinas}>
                              <div className="row">
                                <div className="col-6 mt-2">Potência</div>
                                <div className="col-6 mt-2">
                                  {item?.acf?.potencia}
                                </div>
                                <div className="col-6 mt-2">
                                  Cronograma de evolução
                                </div>
                                <div className="col-6 mt-2">
                                  {item?.acf?.cronograma_de_evolucao}
                                </div>
                                <div className="col-6 mt-2">Data de Início</div>
                                <div className="col-6 mt-2">
                                  {item?.acf?.data_de_inicio}
                                </div>
                                <div className="col-6 mt-2">
                                  Data de Término
                                </div>
                                <div className="col-6 mt-2">
                                  {item?.acf?.data_do_termino}
                                </div>
                                <div className="col-6 mt-2">
                                  Volume de Energia
                                </div>
                                <div className="col-6 mt-2">
                                  {item?.acf?.volume_de_energia}
                                </div>

                                <Link
                                  className="w-100 text-center mt-2"
                                  onClick={() =>
                                    store.dispatch(
                                      usina({
                                        id: item?.id,
                                        rotulo: item?.title?.rendered
                                      })
                                    )
                                  }
                                  to="/investimentos/rota-usinas"
                                >
                                  <img src={btnSaibaMais} alt="Saiba mais" />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </Box>
                      </>
                    )
                  );
                })}
            </div>

            <div className="col-10 text-center d-md-block d-none">
              {mapaPrincipal && (
                <img
                  src={mapaPrincipal}
                  className={classes.mapaHome}
                  alt="Mapa"
                />
              )}
            </div>

            <div className={`col-1 ${classes.margin50Menos}`}>
              {dadosUsinas?.length > 0 &&
                dadosUsinas?.slice(10)?.map((item) => {
                  return (
                    item?.acf?.ativar_usina && (
                      <>
                        <Box
                          component="div"
                          onClick={() => {
                            setUsinaAtiva(item?.slug);
                            setMapaPrincipal(item?.acf?.imagem_mapa);
                          }}
                          className={`mt-3 ${classes.boxUsina} ${
                            usinaAtiva === item?.slug
                              ? `${classes.bordaAmarela} ${classes.usinaAberta}`
                              : classes.usinaFechada
                          }`}
                        >
                          <div className={classes.nomeUsina}>
                            {item?.title?.rendered}
                            <div
                              className={`float-right ${classes.cursorPointer}`}
                            >
                              {usinaAtiva === item?.slug ? (
                                <RemoveIcon />
                              ) : (
                                <AddIcon />
                              )}
                            </div>
                          </div>
                          <div
                            className={`mt-3 ${
                              usinaAtiva === item?.slug ? "d-block" : "d-none"
                            }`}
                          >
                            <div className={classes.dadosUsinas}>
                              <div className="row">
                                <div className="col-6 mt-2">Potência</div>
                                <div className="col-6 mt-2">
                                  {item?.acf?.potencia}
                                </div>
                                <div className="col-6 mt-2">
                                  Cronograma de evolução
                                </div>
                                <div className="col-6 mt-2">
                                  {item?.acf?.cronograma_de_evolucao}
                                </div>
                                <div className="col-6 mt-2">Data de Início</div>
                                <div className="col-6 mt-2">
                                  {item?.acf?.data_de_inicio}
                                </div>
                                <div className="col-6 mt-2">
                                  Data de Término
                                </div>
                                <div className="col-6 mt-2">
                                  {item?.acf?.data_do_termino}
                                </div>
                                <div className="col-6 mt-2">
                                  Volume de Energia
                                </div>
                                <div className="col-6 mt-2">
                                  {item?.acf?.volume_de_energia}
                                </div>

                                <Link
                                  className="w-100 text-center mt-2"
                                  onClick={() =>
                                    store.dispatch(
                                      usina({
                                        id: item?.id,
                                        rotulo: item?.title?.rendered
                                      })
                                    )
                                  }
                                  to="/investimentos/rota-usinas"
                                >
                                  <img src={btnSaibaMais} alt="Saiba mais" />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </Box>
                      </>
                    )
                  );
                })}
            </div>
          </div>
        </div>
      ) : !mobile ? (
        <Grid container>
          <Grid md={8} xs={12} item>
            <Economia
              onClick={() => salvarMenuColecao("Economia", menu)}
              to={RotasDTO.Economia}
            >
              <TextoEconomia>Economia</TextoEconomia>
            </Economia>
            <Consumo
              onClick={() => salvarMenuColecao("DashboardConsumo", menu)}
              to={RotasDTO.DashboardConsumo}
            >
              <TextoConsumo>Consumo</TextoConsumo>
            </Consumo>
          </Grid>

          <Grid item md={4} xs={12}>
            <MedicaoConsolidada
              onClick={() => salvarMenuColecao("MedicaoConsolidada", menu)}
              to={RotasDTO.MedicaoConsolidada}
            >
              <TextoMedicao>Medição consolidada</TextoMedicao>
            </MedicaoConsolidada>
            <Grid container>
              <Grid xs={9} item>
                <InformacoesCadastro
                  onClick={() => salvarMenuColecao("Clientes", menu)}
                  to={RotasDTO.Clientes}
                >
                  <TextoInformacoes>
                    <PersonOutlineOutlinedIcon className="d-block mx-auto" />
                    Informações de cadastro
                  </TextoInformacoes>
                </InformacoesCadastro>
              </Grid>
              <Grid item xs={3}>
                <LinkedIn
                  target="_blank"
                  href="https://www.linkedin.com/company/delta-energia/"
                  className={classes.iconesDashboard}
                />
                <Site
                  target="_blank"
                  href="http://www.deltaenergia.com.br/"
                  className={classes.iconesDashboard}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container>
          <Grid xs={12} item>
            <EconomiaMobile
              onClick={() => history.push(`${RotasDTO.Economia}/mobile`)}
            >
              <TextoMobile>Economia</TextoMobile>
            </EconomiaMobile>

            <FinanceiroMobile
              onClick={() => history.push(`${RotasDTO.Faturas}/mobile`)}
            >
              <TextoMobile>Financeiro</TextoMobile>
            </FinanceiroMobile>

            <ConsumoMobile
              onClick={() =>
                history.push(`${RotasDTO.DashboardConsumo}/mobile`)
              }
            >
              <TextoMobile>Consumo</TextoMobile>
            </ConsumoMobile>

            <RelatoriosMobile
              onClick={() =>
                history.push(`${RotasDTO.RelatoriosDashboard}/mobile`)
              }
            >
              <TextoMobile>Relatórios</TextoMobile>
            </RelatoriosMobile>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Home;
