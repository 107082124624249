import { createTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const theme = createTheme();

export const useStyles = makeStyles(() => ({
  root: {
    "& .MuiInputBase-input.Mui-disabled": {
      opacity: 0.5,
      borderBottom: "1px solid #e4d9d9"
    },
    "& .MuiInputBase-input": {
      borderBottom: "1px solid black",
      padding: 0,
      textAlign: "center"
    },
    " & .MuiOutlinedInput-notchedOutline": {
      border: "none"
    },
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none",
      margin: 80
    },
    "& [type=number]": {
      WebkitAppearance: "none"
    },
    "& .MuiFormLabel-root": {
      [theme.breakpoints.down("md")]: {
        fontSize: ".9rem"
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: ".8rem"
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "1rem"
      }
    }
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  }
}));
