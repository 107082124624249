import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import getDomain from "global/getDomain";
import { useStyles } from "./style";
import DeltaGif from "../../assets/cotacoes/loading-delta.gif";
import LuzGif from "../../assets/cotacoes/loading-luz.gif";
import ThymosGif from "../../assets/cotacoes/loading-thymos.gif";

// eslint-disable-next-line no-unused-vars
const CustomLoader = ({ className, msgLoading, children }) => {
  const loader = useSelector((state) => state.loader);
  const classes = useStyles();

  return (
    <>
      {children}
      {loader?.loading ? (
        <div className={classes.overlay}>
          <div className={classes.containerLoader}>
            <img
              alt="imgLoader"
              src={
                getDomain() === "luz"
                  ? LuzGif
                  : getDomain() === "delta"
                  ? DeltaGif
                  : ThymosGif
              }
              className={classes.imagemLoader}
            />
            <h4 className={classes.msgLoading}>{msgLoading}</h4>
          </div>
        </div>
      ) : null}
    </>
  );
};

CustomLoader.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types, react/require-default-props
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  msgLoading: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  loading: PropTypes.bool,
  // eslint-disable-next-line react/no-unused-prop-types
  dark: PropTypes.bool
};

CustomLoader.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  children: () => {},
  className: "",
  msgLoading: "Carregando...",
  loading: false,
  dark: true
};

export default CustomLoader;
