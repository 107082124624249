import RetornoEndpointDto from "global/dto/retornoEndpointDto";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { assinarDocumento } from "servicos/demoService";

export default class ContratoHelper {
  static async assinarContrato(dto) {
    try {
      const result = await assinarDocumento(dto);

      return RetornoEndpointDto.Sucesso(
        "Contrato assinado no click sign!",
        result.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu uma falha ao assinar o contrato!"
      );
    }
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }
}
