import { store } from "../../../global/redux";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";
import RetornoEndpointDto from "../../../global/dto/retornoEndpointDto";
import { obterClienteCupomIndicadorPorCliente } from "../../../servicos/memberGetMemberServico";

export default class MemberGetMemberFatura {
  static async obterClienteCupomIndicador(clienteId, setCarregandoFatura) {
    try {
      setCarregandoFatura(true);
      const resultado = await obterClienteCupomIndicadorPorCliente(clienteId);

      return RetornoEndpointDto.Sucesso(
        "Cupom encontrado com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Erro ao encontrar cupom!");
    } finally {
      setCarregandoFatura(false);
    }
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }

  static exibirSucesso(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem
      })
    );
  }
}
