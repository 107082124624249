import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import MaterialInputTexto from "componentes/inputTexto/materialInput";
import { Botao } from "componentes";
import BotaoOutLine from "componentes/botaoOutline";

import { useStyles } from "./style";

const FormularioMotivo = (props) => {
  const { onCancelar, onSalvar } = props;
  const classes = useStyles();
  const [motivo, setMotivo] = useState();
  const [desabilitarSalvar, setDesabilitarSalvar] = useState(true);

  const validarBotao = () => {
    if (!motivo) {
      setDesabilitarSalvar(true);
      return;
    }
    setDesabilitarSalvar(false);
  };

  useEffect(() => {
    validarBotao();
  }, [motivo]);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} className="font-weight-bold" mt={2} pb={1}>
          <MaterialInputTexto
            type="text"
            id="motivo"
            name="motivo"
            label="Novo motivo de cancelamento"
            renderIconShowHide={false}
            defaultValue={motivo}
            onInput={(e) => setMotivo(e.target.value)}
          />
        </Grid>

        <Grid item xs={6}>
          <BotaoOutLine
            onClick={() => onCancelar()}
            label="Cancelar"
            className={classes.botaoTransparente}
          />
        </Grid>
        <Grid item xs={6}>
          <Botao
            onClick={() =>
              onSalvar(
                {
                  motivo
                },
                setDesabilitarSalvar
              )
            }
            label="Salvar"
            className={classes.botao}
            disabled={desabilitarSalvar}
          />
        </Grid>
      </Grid>
    </>
  );
};

FormularioMotivo.propTypes = {
  onCancelar: PropTypes.func,
  onSalvar: PropTypes.func
};

FormularioMotivo.defaultProps = {
  onCancelar: () => {},
  onSalvar: () => {}
};

export default FormularioMotivo;
