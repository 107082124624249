import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: "transparent",
    color: "#332053",
    marginTop: "3px",
    padding: "8px",
    textAlign: "center"
  }
}));
