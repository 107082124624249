import React, { useEffect } from "react";
import getDomain from "global/getDomain";

// Componentes
import ThemeHome from "themes/paginas";

// Redux
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

// Styles
import { Mensagem } from "./style";

const Home = () => {
  const history = useHistory();
  const usuario = useSelector((state) => state.usuario);

  useEffect(() => {
    if (!usuario?.token) window.location.href = "/login";
  }, [usuario, history]);

  return getDomain() === "default" ? (
    <Mensagem>Cliente não definido!</Mensagem>
  ) : (
    <ThemeHome />
  );
};

export default Home;
