import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { useStyles, customStyles, conditionalRowStyles } from "./style";
import { EditOutlined } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import DataTable from "react-data-table-component";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Coluna from "componentes/tabelaPaginada/colunas/coluna";
import ColunaBotao from "componentes/tabelaPaginada/colunas/colunaBotao";
import ModalConfirmacao from "componentes/modalConfirmacao";
import {getVigencias, excluirVigencia} from "../../../../../servicos/premissasBrasil";
import RotasDTO from "../../../../../rotas/rotasUrlDto";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { store } from "global/redux";
import moment from "moment";
import { Base } from "componentes/cores";

const VigenciasProinfa = () => {
  const history = useHistory();
  const classes = useStyles();
  const [vigencia, setVigencia] = useState("VigenciaAtual");
  const [vigencias, setVigencias] = useState([]);
  const [modalExclusao, setModalExclusao] = useState(false);
  const [id, setId] = useState();

  const obterVigencias = async () => 
  {
    try
    {
      const response = await getVigencias();

      if(response.status === 200)
      {
          const addid = response.data.map(g=> {
            return {...g, id: `${g.inicioVigencia}|${g.codigoFonteEnergia}` }
          })
          setVigencias(addid);
      }
      else if(response.status === 202)
      {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: `Falha: ${response.data}`
          })
        );
      }
    }
    catch (error)
    {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem:`Erro não previsto ao obter vigências: ${error}`
        })
      );

    }
  }

  const colunas = [
    new Coluna("nomeFonteEnergia", "Fonte de Energia"),
    {...new Coluna("inicioVigencia", "Início Vigência"),format:(row)=>moment(row.inicioVigencia).format("DD/MM/yyyy")},
    {...new Coluna("finalVigencia", "Fim Vigência"),format:(row)=>moment(row.finalVigencia).format("DD/MM/yyyy")},
    new Coluna("tituloSerieHistorica", "Série Histórica")
  ];

  const onClickNova = () => {
    history.push(`${RotasDTO.ProinfaVigencias}/cadastro/incluir/${vigencia}`);
  }

  const onClickEditar = () => {
    history.push(`${RotasDTO.ProinfaVigencias}/cadastro/editar/${vigencia}`);
  };

  const onConfirmarExclusao = async () => {
    try {
      var dados = id.split("|");
      var iniVigencia = dados[0];
      var fonteenergia = dados[1];

      const response = await excluirVigencia(iniVigencia,fonteenergia);
      setModalExclusao(false);

     if(response.status === 200)
      {
        obterVigencias();
        setId();
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: `${response.data}`
          })
        );
      }
      else if(response.status === 202)
      {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: `Falha: ${response.data}`
          })
        );
      }

    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem:`onConfirmarExclusao: Erro não previsto: ${error}`
        })
      );
    }
  }

  const onClickExcluir = async (item) => {
    setId(item);
    setModalExclusao(true);
  };

    const colunasComExclusão = [
      ...colunas,
      new ColunaBotao(
        "excluir",
        "",
        onClickExcluir,
        <DeleteIcon  style={{color: Base.OffWhite}}/>,
        false,
        "70px",
        "70px",
        true
      )
  ]

  useEffect (() => {
    if(vigencias.length === 0) obterVigencias();
  },[vigencias.length])

  return <>
    <Grid container>
      <Grid item sm={12} className="mt-5">
      <ModalConfirmacao
          item={modalExclusao}
          onConfirmar={() => onConfirmarExclusao()}
          mensagem="Tem certeza que deseja excluir essa vigência?"
          onCancelar={() => setModalExclusao(false)}
        />

        <Grid item sm={4}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="perid">Vigência</InputLabel>
            <Select
              variant="outlined"
              labelId="perid"
              id="perid1"
              value={vigencia ?? ""}
              onChange={(e) => { setVigencia(e.target.value) }}
              label="Operação"
              fullWidth>
              {[{key: "VigenciaHistorica", name: "Histórica"}, 
              {key: "VigenciaAtual", name: "Atual"}, 
              {key: "VigenciaFutura", name: "Futura"}].map((item) => (
                <MenuItem value={item.key}>{item.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <div style={{ display: "flex", height: "100%", width: "100%" }}>
          <div style={{ flexGrow: 1 }}>
            {vigencias?.length > 0 ?
              <DataTable
                noHeader
                subHeader
                subHeaderAlign='right'
                subHeaderComponent={
                <>
                <AddCircleIcon style={{marginRight:"8px"}} className={classes.icon} onClick={() => onClickNova()} />
                {vigencia!="VigenciaHistorica" ? <EditOutlined className={classes.icon} onClick={() => onClickEditar()} /> : ""}
                </>
                }
                columns={colunasComExclusão}
                data={vigencias.filter((item)=>{return item.tipoVigencia == vigencia})}
                theme="DeltaEnergia"
                customStyles={customStyles}
                conditionalRowStyles={conditionalRowStyles}
                noDataComponent="Nenhum registro encontrado"
              />
              :
              <Grid container spacing={2}>
                <Grid item>
                  <AddCircleIcon className={classes.icon} onClick={() => onClickNova()} />
                </Grid>
                <Grid item sm={12} className={classes.customTextoNoData}>
                  Nenhum registro encontrado
                </Grid>
              </Grid>
            }
          </div>
        </div>
      </Grid>
    </Grid>
  </>;
}

export default VigenciasProinfa;