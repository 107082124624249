import { createTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Base } from "../../../../../componentes/cores";
import theme from "../../../../../themes";

export const useStyles = makeStyles(() => ({
  root: {
    "& label.Mui-focused": {
      color: `${Base.GreyDim}`
    },
    "& .MuiIconButton-root": {
      color: "white !important"
    }
  },
  container: {
    "& .MuiFormControl-marginNormal": {
      margin: "0",
      width: "100%",
      paddingLeft: 12
    },
    "& .MuiIconButton-root": {
      color: `${theme.color.secondaryBorderColor} !important`
    },
    "& .MuiInputBase-input": {
      color: `${theme.color.secondaryBorderColor} !important`
    }
  }
}));

export const materialTheme = createTheme({
  palette: {
    primary: {
      main: theme.color.primary,
      contrastText: Base.White,
    },
  },
});
