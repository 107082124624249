import React from "react";
import PropTypes from "prop-types";

// Componentes
import { Grid, Box } from "@mui/material";
import IconeLampada from "assets/home/icone-lampada.png";

// Styles
import { useStyles, IconeSemDados } from "./style";

const SemDados = ({ texto }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} p={2}>
      <Box className={classes.semDados}>
        <IconeSemDados src={IconeLampada} />
        <h4>{texto}</h4>
      </Box>
    </Grid>
  );
};

SemDados.propTypes = {
  texto: PropTypes.string
};

SemDados.defaultProps = {
  texto: ""
};

export default SemDados;
