import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

// Icones
import { BsPerson, BsLock } from "react-icons/bs";

// Componentes
import InputTexto from "../../componentes/inputTexto";

const Campos = ({
  register,
  errors,
  valores,
  travarAcesso,
  enviarEmail,
  acesso
}) => {
  const [type, setType] = useState("password");
  const onShowClick = () => {
    setType(type === "text" ? "password" : "text");
  };

  const placeholder = enviarEmail ? "E-mail" : "E-mail, CPF ou username";

  return (
    <>
      <InputTexto
        id="acesso"
        name="acesso"
        placeholder={`${placeholder}`}
        icon={<BsPerson />}
        ref={register({
          required: "Campo acesso é obrigatório!"
        })}
        errors={errors}
        defaultValue={valores?.acesso ?? ""}
        disabled={travarAcesso}
      />
      <Box mt={errors?.acesso?.message ? 0 : 2}>
        {errors?.acesso?.message ?? ""}
      </Box>

      {!enviarEmail ? (
        <>
          <InputTexto
            type={type}
            id="senha"
            name="senha"
            placeholder="Senha"
            icon={<BsLock />}
            ref={register({
              required: "Campo senha é obrigatório!"
            })}
            errors={errors}
            defaultValue={valores?.senha ?? ""}
            onShow={() => onShowClick()}
          />

          <Box mt={errors?.senha?.message ? 0 : 1}>
            {errors?.senha?.message ?? ""}
          </Box>
        </>
      ) : null}

      {acesso === "registro" && (
        <>
          <InputTexto
            type={type}
            id="confirmarSenha"
            name="confirmarSenha"
            placeholder="Confirmar senha"
            icon={<BsLock />}
            ref={register({
              required: "Campo confirmar senha é obrigatório!"
            })}
            errors={errors}
            defaultValue={valores?.confirmarSenha ?? ""}
            onShow={() => onShowClick()}
          />

          <Box mt={errors?.confirmarSenha?.message ? 0 : 1}>
            {errors?.confirmarSenha?.message ?? ""}
          </Box>
        </>
      )}
    </>
  );
};

Campos.propTypes = {
  register: PropTypes.oneOfType([PropTypes.any]).isRequired,
  errors: PropTypes.oneOfType([PropTypes.any]).isRequired,
  valores: PropTypes.oneOfType([PropTypes.any]),
  travarAcesso: PropTypes.bool,
  enviarEmail: PropTypes.bool,
  acesso: PropTypes.string
};

Campos.defaultProps = {
  valores: {},
  travarAcesso: false,
  enviarEmail: false,
  acesso: ""
};

export default Campos;
