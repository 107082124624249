import {
  SalvarPreFaturamento,
  EnviarParaFaturamento,
  ObterPreFaturamentoPorId,
  Recalcular
} from "servicos/preFaturamentoServico";
import RetornoEndpointDto from "../../../../global/dto/retornoEndpointDto";
import { store } from "../../../../global/redux";
import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";
import { executarCalculoCustoPorUnidade } from "../../../../servicos/calculoCustosServico";

export default class preFaturamentoHelper {

  static async enviarParaFaturamento(id) {
    try {
      const resultado = await EnviarParaFaturamento(id);

      if (resultado.status !== 204)
        return RetornoEndpointDto.Erro(
          resultado.data.message
        );

      return RetornoEndpointDto.Sucesso("Enviado para faturamento com sucesso!");
    } catch (ex) {
      return RetornoEndpointDto.Erro(
        "Ocorreu uma falha ao enviar para faturamento!"
      );
    }
  }

  static async salvarPreFaturamento(dados) {
    try {
      const resultado = await SalvarPreFaturamento(dados);

      if (resultado.status !== 204)
        return RetornoEndpointDto.Erro(
          resultado.data.message
        );

      return RetornoEndpointDto.Sucesso("Pré faturamento salvo com sucesso!");
    } catch (ex) {
      return RetornoEndpointDto.Erro(
        "Ocorreu uma falha ao salvar o pré faturamento!"
      );
    }
  }

  static async obterPreFaturamento(id) {
    try {
      const resultado = await ObterPreFaturamentoPorId(id);

      return RetornoEndpointDto.Sucesso(
        "Pré faturamento obtido com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu um erro ao obter o pré faturamento"
      );
    }
  }

  static async recalcularPreFaturamento(
    unidadeId,
    mesReferencia,
    anoReferencia,
    dados
  ) {

    try {
      const resultado = await Recalcular(
        unidadeId,
        mesReferencia,
        anoReferencia,
        dados
      );

      return RetornoEndpointDto.Sucesso(
        "Pré faturamento recalculado com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu um erro ao obter o pré faturamento recalculado"
      );
    }
  }

  static async executarCalculoCustoPorUnidade(unidadeId, mes, ano) {
    try {

      const resultado = await executarCalculoCustoPorUnidade(unidadeId, mes, ano);
      
      return RetornoEndpointDto.Sucesso(
        "Calculo custos executado com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu um erro ao executar calculo custos"
      );
    }
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }

  static exibirSucesso(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem
      })
    );
  }
}
