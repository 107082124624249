import RetornoEndpointDto from "../../../global/dto/retornoEndpointDto";
import { store } from "../../../global/redux";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";
import { listarOperacaoControlePJPaginado } from "../../../servicos/listagemOperacaoControleServico";

export default class ListagemOperacoesHelper {
  static async listarOperacaoControlePaginado(filtro) {
    try {
      const resultado = await listarOperacaoControlePJPaginado(filtro);

      if (resultado.status === 204)
        return RetornoEndpointDto.Sucesso(
          "Lista de Operação Controle não encontrada!"
        );

      return RetornoEndpointDto.Sucesso(
        "Listagem de Operação Controle obtidas com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        error?.response?.data?.message ??
          "Ocorreu um erro ao obter a lista de Operação Controle!"
      );
    }
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }

  static exibirSucesso(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem
      })
    );
  }
}
