import { saveAs } from "file-saver";
import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";
import { downloadFileAWS } from "./utils";

const api = () => obterApi(MICROSERVICO.MANAGEMENT);

export const adicionarDocumentoCliente = async (body) => {
  return api().post("v1/ClienteDocumento/URLPreAssinada", body);
};

export const obterDocumentoClientePorId = async (id) => {
  return api().get(`v1/ClienteDocumento/${id}`);
};

export const obterDocumentosClientePorClienteId = async (clienteId) => {
  return api().get(`v1/ClienteDocumento/${clienteId}/todos`);
};

export const removerDocumentoCliente = async (id) => {
  return api().delete(`v1/ClienteDocumento/${id}`);
};

export const downloadDocumentoCliente = async (id, nomeArquivo) => {
  const { data } = await api().get(
    `v1/ClienteDocumento/DownloadDocumento/${id}`,
    {
      responseType: "blob"
    }
  );

  const blob = new Blob([data]);

  saveAs(blob, nomeArquivo);
};

export const obterUrlDocumentoCliente = async (id, nomeArquivo) => {
  const { data } = await api().get(
    `v1/ClienteDocumento/DownloadDocumentoURLPreAssinada/${id}`
  );

  downloadFileAWS(data, nomeArquivo);
};

export const downloadDocumentoClienteImagem = async (
  id,
  nomeArquivo,
  extensao
) => {
  const { data } = await api().get(
    `v1/ClienteDocumento/DownloadDocumento/${id}`,
    {
      responseType: "blob"
    }
  );

  const blob = new Blob([data], { type: extensao });

  saveAs(blob, nomeArquivo);
};

export const listarClienteDocumentoPorUnidadeId = async (id) => {
  return api().get(`v1/ClienteDocumento/ObterPorUnidadeId/${id}`);
};

export const listarDocumentosParaAprovacaoPorOperacaoControleId = async (
  operacaoControleId
) => {
  return api().get(
    `v1/ClienteDocumento/ObterDocumentosParaAprovacaoPorOPCId/${operacaoControleId}`
  );
};

export const validarUrlDocumento = async (body) => {
  return api().post("v1/ClienteDocumento/ValidarUrlDocumentoAsync", body);
};

export const obterProcuracao = async (idDistribuidora) => {
  return api().get(
    `v1/ClienteContrato/DownloadProcuracaoPorDistribuidoraIdEListaUnidadeZip/${idDistribuidora}`
  );
};

export const obterValidarUrlDocumento = async (guid) => {
  return api().get(`/v1/ClienteDocumento/ObterValidarUrlDocumento/${guid}`);
};
