import MICROSERVICO from "global/enumeradores/microServico";
import obterApi from "servicos/api";

const api = () => obterApi(MICROSERVICO.PROJECAOTARIFARIA);

export const uploadContratroProinfa = async (arquivo, anoReferencia, dataReferencia, custoAdicional, validarDominio, idUsuario, nomeUsuario) => {
  const data = new FormData();
  data.append("file", arquivo);

  const metodo = "post";
  const url = `v1/ImportarContratosProInfa/importar?AnoReferencia=${anoReferencia}&DataReferencia=${dataReferencia}&CustoAdicional=${custoAdicional}&validarDominio=${validarDominio}&idUsuario=${idUsuario}&nomeUsuario=${nomeUsuario}`;
  return api()[metodo](url, data);
}

export const valoresContratroProinfa = async (anoReferencia) => {
  return api().get(`v1/ImportarContratosProInfa/getall?anoreferencia=${anoReferencia}`);
}

export const valoresContratroProinfaAtualizados = async (anoReferencia) => {
  return api().get(`v1/ImportarContratosProInfa/ObterContratosAtualizados?anoreferencia=${anoReferencia}`);
}

export const importarBaseRateioProinfa = (arquivo, referencia, idUsuario, nomeUsuario) => {
  const data = new FormData();
  data.append("file", arquivo);

  const metodo = "post";
  const url = `v1/ImportBaseRateioProinfa/importa?anoreferencia=${referencia}&idUsuario=${idUsuario}&nomeUsuario=${nomeUsuario}`;
  return api()[metodo](url, data);
}

export const obterValoresBaseRateioProinfa = (ano) => {
  return api().get(`v1/ImportBaseRateioProinfa/visualiza?anoreferencia=${ano}`);
}

export const importarBaseRateioEER = (arquivo, idUsuario, nomeUsuario) => {
  const data = new FormData();
  data.append("file", arquivo);

  const metodo = "post";
  const url = `v1/ImportBaseRateioEER/importa?idUsuario=${idUsuario}&nomeUsuario=${nomeUsuario}`;
  return api()[metodo](url, data);
}

export const obterValoresBaseRateioEER = () => {
  return api().get("v1/ImportBaseRateioEER/visualiza");
}

export const importarBaseRateioESS= (arquivo, idUsuario, nomeUsuario) => {
  const data = new FormData();
  data.append("file", arquivo);

  const metodo = "post";
  const url = `v1/ImportBaseRateioESS/importa?idUsuario=${idUsuario}&nomeUsuario=${nomeUsuario}`;
  return api()[metodo](url, data);
}

export const obterValoresBaseRateioESS = () => {
  return api().get("v1/ImportBaseRateioESS/visualiza");
}


export const uploadContratroEER = async (arquivo, dataReferencia, idUsuario, nomeUsuario) => {
  const data = new FormData();
  data.append("file", arquivo);

  const metodo = "post";
  const url = `v1/ImportarContratoEER/importar?DataPreco=${dataReferencia}&idUsuario=${idUsuario}&NomeUsuario=${nomeUsuario}`;
  return api()[metodo](url, data);
}

export const valoresContratoEER = async () => {
  return api().get("v1/ImportarContratoEER/visualizar");
}

export const importarContratosLeilaoCCEE = async (idUsuario, nomeUsuario, keyFileS3) => {
  const metodo = "post";
  const url = `v1/ContratosCCEE/importar?idUsuario=${idUsuario}&NomeUsuario=${nomeUsuario}&keyFileS3=${keyFileS3}`;
  return obterApi(MICROSERVICO.IMPORTACAOCCEE)[metodo](url);
}

export const valoresContratosLeilaoCCEE = async (id, ano) => {
  return api().get(`v1/ContratosCCEE/getcontratobyiddistribuidora?Ano=${ano}&IdDistribuidora=${id}`);
}

export const valoresContratosLeilaoSparta = async (id, ano) => {
  return api().get(`v1/ImportSPARTA/visualiza?IdDistribuidora=${id}&AnoTarifario=${ano}`);
}

export const valoresGuiaRecortes = async (id, ano) => {
  return api().get(`v1/ImportSPARTA/obterguiarecortes?IdDistribuidora=${id}&AnoTarifario=${ano}`);
}

export const getInfoAtualizar = async () => {
  return api().get(`v1/ImportarContratosProInfa/get-info-atualizar`);
}

export const getVigencias = async () => {
  return api().get(`v1/ImportarContratosProInfa/get-vigencias`);
}

export const adicionarVigencias = async (data) => {
  return api().post(`v1/ImportarContratosProInfa/add-vigencias`, data);
}

export const excluirVigencia = async (inivigencia, codfonte) => {
  return api().delete(`v1/ImportarContratosProInfa/delete-vigencia?iniciovigencia=${inivigencia}&codfonteenergia=${codfonte}`);
}

export const atualizarVigencias = async (data) => {
  return api().put(`v1/ImportarContratosProInfa/update-vigencias`, data);
}
