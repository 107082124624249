import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { useHistory } from "react-router-dom";
import { useForm, useWatch } from 'react-hook-form';
import shortid from "shortid";
import { Grid, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import { Base } from 'componentes/cores';
import { useStyles } from "./style";
import RotasDTO from '../../../rotas/rotasUrlDto';
import Loader from 'componentes/loader';
import MaterialInputTexto from 'componentes/inputTexto/materialInput';
import BotaoFiltroOrdenacao from 'componentes/botaoFiltroOrdenar';
import ItemListaExpansivel from 'componentes/itemListaExpansivel';
import { obterRecortesPorInsumo, listarFiltrarRecortesPor, obterInsumosComRecorte } from '../../../servicos/projecaoInsumos';


const ListaRecortes = () => {
  const classes = useStyles();
  const history = useHistory();

  const { register, control } = useForm({
    reValidateMode: "onSubmit"
  });

  const onClickEditar = (id) => {
    history.push(`${RotasDTO.ProjecaoInsumosRecortesAlterar}/${encodeURIComponent(id)}`);
  }

  const [recortes, setRecortes] = useState([]);
  const [idInsumoProjecao, setIdInsumoProjecao] = useState(null);
  const [listaInsumosProjecao, setListaInsumosProjecao] = useState([]);
  const [listaRecortes, setListaRecortes] = useState([]);
  const [carregandoDados, setCarregandoDados] = useState(false);
  const [filtroSelecionado, setFiltroSelecionado] = useState(null);

  const textoBusca = useWatch({
    control,
    name: "textoBusca",
    defaultValue: ""
  });

  const stringBusca = useMemo(() => {
    return textoBusca;
  }, [textoBusca]);

  const obterRecortes = useCallback(async (id) => {
    try {
      setCarregandoDados(true);
      const lista = await obterRecortesPorInsumo(id);
      if (lista.status === 200 && lista.data) {
        setRecortes(lista.data);
        setListaRecortes(lista.data);
      }
      setCarregandoDados(false);

    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      console.info(error);
      setCarregandoDados(false);
    }
  }, []);

  function filterByGuia(obj) {
    if (obj.guia != null && obj.guia.toUpperCase().includes(textoBusca.toUpperCase())) {
      return true;
    } else {
      return false;
    }
  }
  function filterByNome(obj) {
    if (obj.nome != null && obj.nome.toUpperCase().includes(textoBusca.toUpperCase())) {
      return true;
    } else {
      return false;
    }
  }

  const handleProjecaoInsumos = async () => {
    try {
      const response = await obterInsumosComRecorte();
      if (response?.status === 200 && response?.data) {
        setListaInsumosProjecao(response?.data);
      }
    } catch (error) {
      console.info(error);
    }
  }

  const handleFiltrarRecortes = () => {

    let dadosFiltrados = [];

    if (listaRecortes != null && listaRecortes.length > 0) {

      dadosFiltrados = listaRecortes;

      if (stringBusca != undefined && stringBusca.length > 0) {
        if ((filtroSelecionado == null || filtroSelecionado == undefined)) {
          dadosFiltrados = dadosFiltrados.filter(filterByNome);
        }
      }
      if (filtroSelecionado == 0) {
        let recortesGuia = dadosFiltrados?.filter(filterByGuia);
        dadosFiltrados = recortesGuia;
      }
      setRecortes(dadosFiltrados);

    }
  }

  const handleClickFiltroSelecionado = (event) => {
    const val = filtroSelecionado === event ? null : event;
    setFiltroSelecionado(val);
  };

  const handleClickAtivarAgora = async () => {
    handleFiltrarRecortes();
  };

  useEffect(() => {
    if (idInsumoProjecao) obterRecortes(idInsumoProjecao);
  }, [idInsumoProjecao, obterRecortes]);

  useEffect(() => {
    setTimeout(() => {
      handleFiltrarRecortes();
    }, 100);
  }, [stringBusca]);


  useEffect(() => {
    if (!listaInsumosProjecao.length) handleProjecaoInsumos();
  }, []);

  return (
    <Loader loading={carregandoDados} className="w-auto">
      <Grid container p={2} spacing={3} alignItems="center" className={classes.container}>
        <Grid item sm={4} className={classes.customSelect}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="contrato-label">Insumo</InputLabel>
            <Select
              variant="outlined"
              labelId="contrato-label"
              id="contrato"
              value={idInsumoProjecao ?? ""}
              onChange={e => setIdInsumoProjecao(e.target.value)}
              label="Insumo"
              fullWidth>
              {listaInsumosProjecao?.map((item) => (
                <MenuItem value={item.idProjecaoInsumo}>{item.nome}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {recortes.length > 0 ? <>
          <Grid item xs={9}>
            <MaterialInputTexto
              type="text"
              id="textoBusca"
              name="textoBusca"
              label="Buscar"
              renderIconShowHide
              searchAdornment
              defaultValue={textoBusca ?? ""}
              ref={register}
              className={classes.search}
              permiteValorBranco
            />
          </Grid>
          <Grid item xs={3} className={classes.itemFiltro}>
            <BotaoFiltroOrdenacao
              type="button"
              color={Base.OffWhite}
              background="transparent"
              label="Filtrar / Ordenar"
              icon={<TuneIcon />}
              className={classes.buttonFiltro}
              ordenadorUm="Ordenador"
              ordenadorDois="Filtrar"
              ordemSelecionada={""}
              filtros={listarFiltrarRecortesPor()}
              filtrosSelecionados={filtroSelecionado}
              onClickFiltro={handleClickFiltroSelecionado}
              onClickAtivarAgora={handleClickAtivarAgora}
            />
          </Grid>
        </> : <></>}

        {recortes.length > 0 && recortes.map((recorte) => {
          return (
            <ItemListaExpansivel
              key={shortid.generate()}
              label={recorte?.nome}
              color={Base.White}
              edicao={!!recorte?.codigo}
              onClickEditar={() => onClickEditar(recorte?.codigo)}
            >
              <div>
                <Grid spacing={1}>
                  <Grid container spacing={2}>
                    <Grid item sm={4}>
                      <strong>Guia: </strong>
                    </Grid>
                    <Grid item sm={8}>
                      {recorte?.guia}
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </ItemListaExpansivel>
          )
        })}

      </Grid>
    </Loader>
  );
}

export default ListaRecortes;