import styled from "styled-components";
import { Badge } from "@mui/material";
import { makeStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import theme from "themes";
import { createTheme } from "@mui/material/styles";
import { Base } from "../cores";

const themes = createTheme();

export const useStyles = makeStyles(() => ({
  appBar: {
    backgroundColor: `${theme.color.bgTheme} !important`,

    paddingLeft: (props) => props.cabecalho,

    width: "100%",
    padding: themes.spacing(2),
    "&.invisible": {
      position: "relative",
      opacity: 0,
      margin: "0 0 24px 0",
      padding: "16px",
      width: "100%"
    }
  },
  toolBar: {
    height: "100%",
    paddingRight: "40px",
    position: "relative"
  },
  menuButton: {
    marginRight: themes.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  mobileAppBar: {
    "&.invisible": {
      display: "none"
    },
    backgroundColor: Base.MetallicGold
  },
  mobileToolbar: {
    backgroundColor: theme.color.primary
  },
  sliderBox: {
    width: "100%",
    padding: 0,
    "& .MuiGrid-container": {
      overflow: "auto",
      whiteSpace: "nowrap",
      display: "block",
      margin: 0,
      bottom: 0,
      padding: themes.spacing(0, 2),
      "& .MuiGrid-item": {
        display: "inline-block",
        margin: 0,
        padding: themes.spacing(1, 1, 1, 1),
        // eslint-disable-next-line quotes
        '& [ativa="1"]': {
          borderColor: "#fff"
        },
        "&:first-child": {
          paddingLeft: 0
        },
        "&:last-child": {
          paddingRight: 0
        }
      }
    }
  },
  menuItem: {
    color: "#212529"
  },
  bordaBranca: {
    width: "99%",
    paddingBottom: 25,
    margin: "0 auto",
    backgroundColor: theme.color.bgTheme,
    borderBottomColor: Base.OffWhiteOpacity,
    borderBottomStyle: "solid",
    borderBottomWidth: (props) => (props.hideHeader ? "0" : "1px"),
    boxShadow: (props) => props.hideHeader && "none"
  },
  areaSetaNavegacao: {
    position: "absolute",
    left: 0
  },
  setaNavegacao: {
    color: theme.color.setaMenu
  }
}));

export const ToggleBadge = withStyles(() => ({
  badge: {
    backgroundColor: Base.OffWhite,
    border: `1px solid ${theme.appBar.backgroundColor}`,
    borderRadius: "50%",
    cursor: "pointer",
    color: theme.appBar.backgroundColor,
    maxWidth: "20px"
  }
}))(Badge);

export const Titulo = styled.span`
  font-family: "Plau";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 120%;
  background: ${theme.appBar.TextColorLuz};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;
