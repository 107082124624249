import AppBar from "@mui/material/AppBar";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import Botao from "../botao";

import { Base } from "../cores";
import { useStyles } from "./style";

const ResultadoOperacao = ({ item, cabecalhoResultado, onClose }) => {
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const [value, setValue] = React.useState(0);
  const [aberto, setAberto] = useState(false);
  const [objResultadoOperacao, setObjResultadoOperacao] = useState({
    mensagens: []
  });

  useEffect(() => {
    setObjResultadoOperacao(item);
    setAberto(
      item ? (item.mensagens ? item.mensagens.length > 0 : false) : false
    );
  }, [item?.mensagens?.length]);

  return (
    <div className={classes.root} style={!aberto ? { display: "none" } : {}}>
      <div className={classes.titleValidation}>
        <h6>
          <b>{cabecalhoResultado}</b>
        </h6>
        <h6>
          <b>A Operação efetuada apresentou o seguinte resultado:</b>
        </h6>
      </div>

      <AppBar position="static" color="default">
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Grid
            container
            spacing={5}
            className={classes.container}
            justifyContent="left"
          >
            {objResultadoOperacao !== null &&
              objResultadoOperacao.mensagens?.length > 0 &&
              objResultadoOperacao.mensagens.map((mensagem, index) => {
                return (
                  <Grid key={`${index}${mensagem}`} item sm={12}>
                    <span>{mensagem}</span>
                    <br />
                  </Grid>
                );
              })}
          </Grid>
        </Tabs>
      </AppBar>

      <Grid item sm={12}>
        <Grid
          container
          spacing={5}
          className={classes.container}
          justifyContent="center"
        >
          <Grid item sm={3}>
            <Botao
              type="button"
              color={Base.Mango}
              label="Ok"
              onClick={onClose}
              className={classes.buttonCustom}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

ResultadoOperacao.propTypes = {
  item: PropTypes.oneOfType([PropTypes.any]),
  cabecalhoResultado: PropTypes.oneOfType([PropTypes.string]),
  onClose: PropTypes.oneOfType([PropTypes.func])
};

ResultadoOperacao.defaultProps = {
  item: () => {},
  cabecalhoResultado: "",
  onClose: () => {}
};

export default ResultadoOperacao;
