import React from "react";
import { Celula } from "./style";

export default class ColunaDocumentos {
  constructor(label, nome, minWidth = null, maxWidth = null) {
    this.name = label;

    const verificarStatus = (status) => {
      if (status === "S") return <Celula cor="#2ABF6F">Aprovado</Celula>;

      if (!status) return <Celula cor="#F4A330">Pendente</Celula>;

      if (status === "N") return <Celula cor="#BF2A2A">Reprovado</Celula>;

      return <Celula cor="#FFFFFF">--</Celula>;
    };

    this.selector = label;
    this.cell = (row) => verificarStatus(row.status);
    this.maxWidth = maxWidth;
    this.minWidth = minWidth;
  }
}
