import React, { useEffect, useMemo, useState } from "react";

// FormHooks
import { useForm, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// Componentes
import { Box, Card, Grid } from "@mui/material";
import { useHistory } from "react-router-dom";
import Alerta from "../../../componentes/alerta";
import Botao from "../../../componentes/botao";
import Loader from "../../../componentes/loader";

// Styles
import Campos from "./campos";
import { useStyles } from "./style";

// Serviços
import { atualizarSenha } from "../../../servicos/loginServico";

// Redux
import { getTipoPerfil } from "../../../global/getTipoPerfil";
import { store } from "../../../global/redux";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";
import {
  desabilitarFiltroLateral,
  selecionarCliente,
  selecionarEmpresa,
  selecionarUnidade
} from "../../../global/redux/modulos/usuario/actions";

const AlterarSenha = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const classes = useStyles();
  const history = useHistory();
  const usuario = useSelector((state) => state.usuario);
  const { tipo } = usuario?.usuario;

  const { register, handleSubmit, control } = useForm();
  const newPassword = useWatch({
    control,
    name: "NewPassword"
  });
  const oldPassword = useWatch({
    control,
    name: "OldPassword"
  });

  useEffect(() => {
    if (getTipoPerfil(tipo)) {
      store.dispatch(selecionarEmpresa());
      store.dispatch(selecionarCliente());
      store.dispatch(selecionarUnidade());
      store.dispatch(desabilitarFiltroLateral(true));
      store.dispatch(desabilitarFiltroLateral(false));
    }
  }, []);

  const [mensagemAlerta, setMensagemAlerta] = useState("");
  const [carregandoAlterarSenha, setCarregandoAlterarSenha] = useState(false);
  const [erros] = useState({});
  const [statusMedidor, setStatusMedidor] = useState({
    senhaAntiga: "",
    senhaNova: ""
  });
  const [enabled, setEnabled] = useState(true);

  const enviarFormulario = async (dados) => {
    // eslint-disable-next-line no-use-before-define
    if (forcaSenhaNova < 3) {
      setMensagemAlerta({
        tipo: "warning",
        mensagem: "É necessário cadastrar uma senha forte para avançar!"
      });
      setCarregandoAlterarSenha(false);
      return;
    }

    try {
      dados.Id = usuario?.usuario?.id ?? 0;
      dados.Active = true;
      const atualizacao = await atualizarSenha(dados);

      if (atualizacao?.status === 200) {
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem:
              "Senha atualizada com sucesso, use-a em seu próximo login!"
          })
        );

        setTimeout(() => {
          history.push("/");
        }, 2000);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
    setCarregandoAlterarSenha(false);
  };

  const aoEnviarFormulario = (dados) => {
    setCarregandoAlterarSenha(true);
    enviarFormulario(dados);
  };

  const forcaSenhaAntiga = useMemo(() => {
    if (!oldPassword) return -1;

    const validacoes = [
      oldPassword.match(/([A-Z]{1})/) ? 1 : 0,
      oldPassword.match(/([a-z]{1})/) ? 1 : 0,
      oldPassword.match(/([0-9]{1})/) ? 1 : 0,
      oldPassword.match(/([!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]{1})/) ? 1 : 0,
      oldPassword.length > 7 ? 1 : 0
    ];

    return validacoes.reduce((soma, valor) => soma + valor, -1) ?? -1;
  }, [oldPassword]);

  const forcaSenhaNova = useMemo(() => {
    if (!newPassword) return -1;

    const validacoes = [
      newPassword.match(/([A-Z]{1})/) ? 1 : 0,
      newPassword.match(/([a-z]{1})/) ? 1 : 0,
      newPassword.match(/([0-9]{1})/) ? 1 : 0,
      newPassword.match(/([!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]{1})/) ? 1 : 0,
      newPassword.length > 7 ? 1 : 0
    ];

    return validacoes.reduce((soma, valor) => soma + valor, -1) ?? -1;
  }, [newPassword]);

  useEffect(() => {
    if (forcaSenhaAntiga < 0 || forcaSenhaAntiga > 3) {
      setStatusMedidor((prevState) => ({
        ...prevState,
        senhaAntiga: ""
      }));
    } else {
      setStatusMedidor((prevState) => ({
        ...prevState,
        senhaAntiga:
          "Utilize oito ou mais caracteres com uma combinação de letras maiúsculas e minúsculas, números e símbolos."
      }));
    }
  }, [forcaSenhaAntiga]);

  useEffect(() => {
    if (forcaSenhaNova < 0 || forcaSenhaNova > 3) {
      setStatusMedidor((prevState) => ({
        ...prevState,
        senhaNova: ""
      }));
    } else {
      setStatusMedidor((prevState) => ({
        ...prevState,
        senhaNova:
          "Utilize oito ou mais caracteres com uma combinação de letras maiúsculas e minúsculas, números e símbolos."
      }));
    }
  }, [forcaSenhaNova]);

  const onChange = (value) => {
    if (value) {
      setEnabled(false);
    }
  };

  return (
    <form
      className="needs-validation"
      onSubmit={handleSubmit(aoEnviarFormulario)}
    >
      <Card>
        <Grid
          container
          p={2}
          spacing={mobile ? 2 : 4}
          className={classes.container}
        >
          <Grid item xs={12} className="font-weight-bold">
            <Box component="div" color="#332053">
              Alteração de senha do usuário
            </Box>
          </Grid>
          <Campos
            register={register}
            onChange={(value) => onChange(value)}
            errors={erros}
            forcaSenhaAntiga={forcaSenhaAntiga}
            forcaSenhaNova={forcaSenhaNova}
            statusMedidor={statusMedidor}
          />
          <Grid
            component={Box}
            item
            md={5}
            lg={5}
            display={{ xs: "none", md: "block" }}
          />
          <Grid item xs={12} sm={5}>
            <Loader loading={carregandoAlterarSenha}>
              <Botao
                type="submit"
                label="Alterar senha"
                className={classes.button}
                disabled={enabled}
              />
            </Loader>
          </Grid>
        </Grid>

        {mensagemAlerta && (
          <Alerta {...mensagemAlerta} onClose={() => setMensagemAlerta("")} />
        )}
      </Card>
    </form>
  );
};

export default AlterarSenha;
