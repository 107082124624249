import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

// Componentes
import TreeViewFuncionalidades from "../../../../../componentes/treeView";
import Loader from "../../../../../componentes/loader";
import ModalConfirmacao from "../../../../../componentes/modalConfirmacao";

// Redux
import { store } from "../../../../../global/redux";
import { alertaExibir } from "../../../../../global/redux/modulos/alertas/actions";

// Serviços
import {
  salvarFuncionalidade,
  deletarFuncionalidade,
  listarFuncionalidades,
  ordernaFuncionalidades
} from "../../../../../servicos/funcionalidadesServico";

const Funcionalidades = ({ onBuscar, onEditar, nodeEdicao, checked }) => {
  const [carregandoFuncionalidades, setCarregandoFuncionalidades] = useState(
    false
  );
  const [funcionalidades, setFuncionalidades] = useState([]);

  const obterFuncionalidades = async () => {
    try {
      setCarregandoFuncionalidades(true);
      const lista = await listarFuncionalidades();
      if (lista?.status === 200 && lista?.data?.funcionalidadeColecao) {
        setFuncionalidades(lista?.data?.funcionalidadeColecao ?? []);
      }
      setCarregandoFuncionalidades(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoFuncionalidades(false);
    }
  };

  useEffect(() => {
    obterFuncionalidades();
  }, []);

  const filtrarDados = useCallback(() => {
    return funcionalidades.filter((funcionalidade) => {
      if (onBuscar && onBuscar.length > 2) {
        const regex = RegExp(`^${onBuscar.toLowerCase()}.*$`);
        let filtro = funcionalidade.nome.toLowerCase().match(regex);
        if (filtro) return true;

        filtro = funcionalidade.funcionalidadeColecao.filter((filho) =>
          filho.nome.toLowerCase().match(regex)
        ).length;
        if (filtro) return true;

        filtro = funcionalidade.funcionalidadeColecao.filter((filho) => {
          filtro = filho.funcionalidadeColecao.filter((neto) =>
            neto.nome.toLowerCase().match(regex)
          ).length;
          if (filtro) return true;
          return false;
        }).length;
        if (filtro) return true;

        return false;
      }
      return true;
    });
  }, [funcionalidades, onBuscar]);

  const nodesExpanded = useMemo(() => {
    if (onBuscar && onBuscar?.length > 2) {
      const itens = [];
      filtrarDados().forEach((funcionalidade) => {
        if (!itens.includes(funcionalidade.id)) {
          itens.push(String(funcionalidade.id));
        }
        if (funcionalidade?.funcionalidadeColecao?.length) {
          funcionalidade.funcionalidadeColecao.forEach((filho) => {
            if (!itens.includes(filho.id)) {
              itens.push(String(filho.id));
            }
          });
        }
      });
      return itens;
    }
    return [];
  }, [onBuscar, filtrarDados]);

  const onSalvar = async (dados) => {
    try {
      setCarregandoFuncionalidades(true);
      let listaOrdenacao = [];

      if (dados?.idFuncionalidadePai) {
        if (String(dados?.idTipoFuncionalidade) === "4") {
          filtrarDados().forEach((item) => {
            if (
              String(item?.id) === String(dados?.idFuncionalidadePai) &&
              item?.funcionalidadeColecao?.length
            ) {
              listaOrdenacao = item.funcionalidadeColecao.map(
                (filho, ordem) => {
                  return { id: filho.id, ordenacao: ordem + 2 };
                }
              );
            }
          });
        }

        if (String(dados.idTipoFuncionalidade) === "5") {
          filtrarDados().forEach((item) => {
            item.funcionalidadeColecao.forEach((filho) => {
              if (
                String(filho?.id) === String(dados?.idFuncionalidadePai) &&
                filho?.funcionalidadeColecao?.length
              ) {
                listaOrdenacao = filho.funcionalidadeColecao.map(
                  (neto, ordem) => {
                    return { id: neto.id, ordenacao: ordem + 2 };
                  }
                );
              }
            });
          });
        }
      }

      const salvou = await salvarFuncionalidade(dados.id, {
        ...dados,
        ativo: true,
        ordenacao: 1
      });
      if (salvou) {
        onEditar();

        if (listaOrdenacao?.length) {
          const ordenou = await ordernaFuncionalidades(listaOrdenacao);
          if (!ordenou) {
            store.dispatch(
              alertaExibir({
                tipo: "warning",
                mensagem: "Erro ao ordenar as funcionalidades!"
              })
            );
          }
        }

        obterFuncionalidades();
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: "A funcionalidade foi salva com sucesso!"
          })
        );
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoFuncionalidades(false);
    }
  };

  const [modalExclusao, setModalExclusao] = useState(false);

  const deletar = async (item) => {
    try {
      setModalExclusao(false);
      setCarregandoFuncionalidades(true);
      const deletou = await deletarFuncionalidade(item);
      if (deletou) {
        obterFuncionalidades();
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: `A funcionalidade foi ${
              item.ativo ? "inativada" : "ativada"
            } com sucesso!`
          })
        );
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoFuncionalidades(false);
    }
  };

  const onDeletar = (item) => {
    setModalExclusao(item);
  };

  const onConfirmarExclusao = (item) => {
    try {
      deletar(item);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  return (
    <Loader loading={carregandoFuncionalidades}>
      <ModalConfirmacao
        item={modalExclusao}
        onConfirmar={(item) => onConfirmarExclusao(item)}
        mensagem={`Tem certeza que deseja ${
          modalExclusao.ativo ? "inativar" : "ativar"
        } essa Funcionalidade?`}
        onCancelar={() => setModalExclusao(false)}
      />
      <TreeViewFuncionalidades
        dataSource={filtrarDados()}
        editable
        onEditar={(node) => onEditar(node)}
        nodeEdicao={nodeEdicao}
        onSalvar={(dados) => onSalvar(dados)}
        deletable
        onDeletar={(node) => onDeletar(node)}
        draggable
        nodesExpanded={onBuscar ? nodesExpanded : []}
        onRecarregar={(recarregar) =>
          recarregar ? obterFuncionalidades() : false
        }
        checked={checked}
      />
    </Loader>
  );
};

Funcionalidades.propTypes = {
  onBuscar: PropTypes.string,
  onEditar: PropTypes.oneOfType([PropTypes.func]),
  nodeEdicao: PropTypes.oneOfType([PropTypes.object]),
  checked: PropTypes.bool
};

Funcionalidades.defaultProps = {
  onBuscar: "",
  onEditar: () => {},
  nodeEdicao: {},
  checked: false
};

export default Funcionalidades;
