import { saveAs } from "file-saver";
import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const api = () => obterApi(MICROSERVICO.MEASUREMENT);

// Listar Cotações Paginadas
export const ListarMedicoes = async (medidor, unidadeId, data) => {
  const medidorQueryString = new URLSearchParams(medidor).toString();

  return api().get(
    `v1/medicao/unidade/${unidadeId}/data/${data}?${medidorQueryString}`
  );
};

export const DownloadExcelMedicao = async (
  unidadeId,
  dataReferencia,
  codigoMedicor
) => {
  const params = {};
  params.UnidadeId = unidadeId;
  params.DataReferencia = dataReferencia;

  if (codigoMedicor !== "Ver todos") params.CodigoMedidorCCEE = codigoMedicor;

  const { data } = await api().get(
    `v1/medicao/gerarexcel?${new URLSearchParams(params).toString()}`,
    {
      responseType: "blob"
    }
  );

  const blob = new Blob([data]);

  // eslint-disable-next-line prefer-template
  saveAs(blob, "DadosDeMedicao_" + dataReferencia + ".xlsx");
};
