import React from "react";
import InputTexto from "./inputTexto";

export default class ColunaCampoTextoSemFidelidade {
  constructor(
    field,
    headerName,
    sortable = false,
    onChangeSelecionarDescontoSemFidelidade,
    maxWidth = null,
    minWidth = null,
    adicionalDescontoSemFidelidade = null,
    idCampoDescontoSemFidelidade = null,
    selecionadoId = null,
    enabled = false
  ) {
    this.selector = field;
    this.name = headerName;
    this.sortable = sortable;
    this.maxWidth = maxWidth;
    this.minWidth = minWidth;
    this.adicionalDescontoSemFidelidade = adicionalDescontoSemFidelidade;
    this.idCampoDescontoSemFidelidade = idCampoDescontoSemFidelidade;
    this.selecionadoId = selecionadoId;
    this.enabled = enabled;
    this.cell = (params) => {
      return (
        <InputTexto
          type="text"
          id={params.id}
          name="adicionalDescontoSemFidelidade"
          permiteValorBranco
          onBlur={(event) =>
            onChangeSelecionarDescontoSemFidelidade(event.target.value, params)
          }
          defaultValue={
            params.id === idCampoDescontoSemFidelidade
              ? adicionalDescontoSemFidelidade
              : params?.adicionalDescontoSemFidelidade
          }
          renderIconShowHide={false}
          disabled={!(params.id === selecionadoId && enabled)}
        />
      );
    };
  }
}
