import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { useStyles } from "./style";

const enumeradorStatus = {
    PENDENTE: "Pendente",
    FALHA: "FALHA",
    SUCESSO: "SUCESSO"
};

const ColunaStatus = ({ parametros }) => {
  const classes = useStyles();
  
  return (
    <Grid container spacing={0} className={classes.fundoBotoes}>
        <Grid
            item
            xs={1}
            className={
                parametros.Status === enumeradorStatus.PENDENTE ? classes.fundoPendente : 
                parametros.Status === enumeradorStatus.FALHA ? classes.fundoFalha : 
                parametros.Status === enumeradorStatus.SUCESSO ? classes.fundoSucesso : ""
            }
        />
        <Grid item xs={11} className={classes.textoStatus}>
            {parametros.Status.toUpperCase()}        
        </Grid>
    </Grid>
  );
};

ColunaStatus.propTypes = {
  onClick: PropTypes.oneOfType([PropTypes.func]).isRequired,
  parametros: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired
};

export default ColunaStatus;
