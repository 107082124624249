export default class ListagemBoletasDto {
  constructor(
    id,
    distribuidoraNome,
    icms,
    dataIniciaVigencia,
    dataFimVigencia,
    atualizacao,
    ativo
  ) {
    this.id = id;
    this.distribuidoraNome = distribuidoraNome;
    this.icms = icms;
    this.dataIniciaVigencia = dataIniciaVigencia;
    this.dataFimVigencia = dataFimVigencia;
    this.atualizacao = atualizacao;
    this.ativo = ativo;
  }
}
