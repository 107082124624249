import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const api = () => obterApi(MICROSERVICO.MEASUREMENT);

const montarRequestModel = (dados, usuario) => {
  const medidorDto = {
    CodigoMedidorCCEE: dados.CodMedidorCCEE,
    UnidadeConsumidoraId: usuario.unidadeSelecionada,
    MedidorConsumo: "S",
    MedidorGeracao: "N",
    PorcentagemPerdaCompartilhadaGeracao: "0.0",
    DescricaoMedidorCCEE: dados.DescricaoMedidorCCEE,
    CodigoPontoMedicaoCCEE: "0",
    PorcentagemPerdaCompartilhadaConsumo: " 0.0",
    CodigoPerfilSCDE: dados.CodPerfilSCDE,
    Situacao: "0",
    DataReferencia: null
  };

  if (dados.swPerdaCompartilhada) {
    medidorDto.PorcentagemPerdaCompartilhadaConsumo = dados.PerdaCompartilhada;
  }

  if (dados.swMedidorAtivo) {
    medidorDto.Situacao = "AT";
  } else {
    medidorDto.Situacao = "IN";
  }

  return medidorDto;
};

// Salvar Medidores
export const salvarMedidor = async (dados, usuario) => {
  const medidorDto = montarRequestModel(dados, usuario);

  let metodo = "post";
  let url = "v1/medidores";

  if (dados.id !== undefined && dados.id > 0) {
    metodo = "put";
    url = `${url}/${dados.id}`;
  }

  return api()[metodo](url, medidorDto);
};

// Listar Medidores
export const listarMedidores = async ({
  ordemSelecionada,
  filtroSelecionado,
  paginaAtual = 0,
  unidadeConsumidoraId,
  searchString,
  clienteId,
  empresaId
}) => {
  const parametros = {
    unidadeId: unidadeConsumidoraId,
    ordernar: ordemSelecionada,
    pagina: paginaAtual,
    tamanhoPaginacao: 20,
    searchString,
    filtro: filtroSelecionado,
    clienteId,
    empresaId
  };

  return api().get(
    `/v1/medidores?${new URLSearchParams(parametros).toString()}`
  );
};

// Deletar Medidor
export const deletarMedidor = async (id, ativo) => {
  let hoje = new Date();
  const dd = String(hoje.getDate()).padStart(2, "0");
  const mm = String(hoje.getMonth() + 1).padStart(2, "0");
  const yyyy = hoje.getFullYear();
  hoje = `${yyyy}-${mm}-${dd}`;

  const situacao = ativo ? "AT" : "IN";

  return api().patch(
    `v1/medidores/${id}/situacao/${situacao}?dataReferencia=${hoje}`
  );
};

// Busca Por Id
export const buscarPorId = async (id) => {
  return api().get(`v1/medidores/${id}`);
};

// Busca Por Unidade
export const buscarPorUnidade = async (unidadeId) => {
  return api().get(`v1/medidores/obterporunidade/${unidadeId}`);
};

export const listarOrganizarPor = () => {
  const ordernacoes = [
    {
      key: "ALFABETICA_ASC",
      label: "Alfabética"
    },
    {
      key: "MAIS_RECENTES_DESC",
      label: "Recentes"
    }
  ];

  return ordernacoes;
};

export const listarFiltrarPor = () => {
  const filtros = [
    {
      key: "AT",
      label: "Ativo"
    },
    {
      key: "IN",
      label: "Inativo"
    }
  ];

  return filtros;
};

export const buscarTiposMedidor = async () => {
  return api().get("v1/medidores/tiposMedidor");
};

