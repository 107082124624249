/* eslint-disable import/no-unresolved */
/* eslint-disable no-return-assign */
/* eslint-disable radix */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useCallback } from "react";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "componentes/tabPanel";
import { Base } from "componentes/cores";
import Botao from "componentes/botao";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { useParams } from "react-router-dom";
import { store } from "global/redux";
import { useForm } from "react-hook-form";
import MaterialSwitch from "componentes/switch/index";
import { Grid, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import MaterialInputTexto from "componentes/inputTexto/materialInput";
import MaterialInputMascara from "componentes/inputTextoMascara";
import BotaoRetornarListagem from "componentes/botaoRetornarListagem";
import { add, edit, getAllDistribuidoras, getAnosDistribuidora, getConfigDistribuidora, getDistribuidora } from "../../../servicos/mercadoFaturado";
import { useStyles } from "./style";
import { listarSerieHistoricaMercadoFaturado } from "../../../servicos/seriesHistoricas";
import RotasDTO from "../../../rotas/rotasUrlDto";

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,
    };
}

const DistribuidoraConfiguracao = () => {
    const classes = useStyles();
    const theme = useTheme();
    const { id } = useParams();

    const [seriesHistoricas, setSeriesHistoricas] = useState([]);

    const [distribuidoras, setDistribuidoras] = useState([]);
    const [distribuidora, setDistribuidora] = useState("");

    const [anosDistribuidoras, setAnosDistribuidoras] = useState([]);
    const [anoDistribuidoraTUSD, setAnoDistribuidoraTUSD] = useState([]);
    const [anoDistribuidoraTE, setAnoDistribuidoraTE] = useState([]);

    const [igualar, setIgualar] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isChange, setIsChange] = useState(false);

    const [value, setValue] = React.useState(0);


    const [pesoMigracaoTUSD, setPesoMigracaoTUSD] = useState("0");
    const [pesoHistoricoTUSD, setPesoHistoricoTUSD] = useState("0");
    const [pesoOutrosTUSD, setPesoOutrosTUSD] = useState("0");
    const [totalTUSD, setTotalTUSD] = useState();
    const [seriesTUSD, setSeriesTUSD] = useState([]);

    const [pesoMigracaoTE, setPesoMigracaoTE] = useState("0");
    const [pesoHistoricoTE, setPesoHistoricoTE] = useState("0");
    const [pesoOutrosTE, setPesoOutrosTE] = useState("0");
    const [totalTE, setTotalTE] = useState();
    const [seriesTE, setSeriesTE] = useState([]);

    const { register, handleSubmit } = useForm({
        reValidateMode: "onSubmit",
    });

    const obterSeriesHistoricas = useCallback(async () => {
        try {
            const data = {
                unidade: 1,
                periodicidade: "A"
            }

            const response = await listarSerieHistoricaMercadoFaturado(data);
            if (response?.status === 200 && response?.data) {
                setSeriesHistoricas(response?.data);
            }
        } catch (error) {
            console.info(error);
        }
    }, []);

    useEffect(() => {
        if (!seriesHistoricas.length) obterSeriesHistoricas();
    }, [!seriesHistoricas.length, obterSeriesHistoricas]);


    const obterDistribuidoras = useCallback(async () => {
        try {
            const response = await getAllDistribuidoras();
            const responseExisting = await getDistribuidora();
            if (response?.status === 200 && response?.data && responseExisting?.status === 200 && responseExisting?.data) {
                const filteredData = response.data.filter((item) => {
                    let result = true
                    responseExisting.data.forEach((exist) => {
                        if (item.idDistribuidora === exist.idDistribuidora 
                            && String(item.idDistribuidora) !== String(id)) {
                            result = false;
                        }
                    })
                    return result;
                })
                setDistribuidoras(filteredData);
            }
            else if(response?.status === 200 && response?.data)
            {
                setDistribuidoras(response.data);
            }
        } catch (error) {
            console.info(error);
        }
    });

    useEffect(() => {
        if (!distribuidoras.length) obterDistribuidoras();
    }, []);


    const obterAnosDistribuidora = useCallback(async (iddistribuidora) => {
        try {
            
            const response = await getAnosDistribuidora(iddistribuidora);

            if (response?.status === 200 && response?.data) {
                setAnosDistribuidoras(response.data);
            }
        } catch (error) {
            console.info("ObterAnosDistribuidora: " + error);
        }
    });

    const addSerieTUSD = (newValue) => setSeriesTUSD((state) => [...state, newValue]);
    const addSerieTE = (newValue) => setSeriesTE((state) => [...state, newValue]);

    const obterConfiguracao = useCallback(async (iddistribuidora) => {
        try {
            const config = await getConfigDistribuidora(iddistribuidora);
            if (config?.status === 200 && config?.data) {
                setSeriesTUSD([]);
                setSeriesTE([]);
                setIsEdit(true);
                setDistribuidora(iddistribuidora);
                const configuracaoTUSD = config.data[0].configuracao;
                const configuracaoTE = config.data[1].configuracao;
                const anosTUSD = config.data[0].anosativos;
                const anosTE = config.data[1].anosativos;
                const pesosTUSD = config.data[0].pesossh;
                const pesosTE = config.data[1].pesossh;
                const setAnosTUSD = [];
                const setAnosTE = [];
                setPesoMigracaoTUSD((configuracaoTUSD.pesomigracao * 100).toFixed(2));
                setPesoHistoricoTUSD((configuracaoTUSD.pesohistorico * 100).toFixed(2));
                setPesoOutrosTUSD((configuracaoTUSD.pesooutros * 100).toFixed(2));
                setPesoMigracaoTE((configuracaoTE.pesomigracao * 100).toFixed(2));
                setPesoHistoricoTE((configuracaoTE.pesohistorico * 100).toFixed(2));
                setPesoOutrosTE((configuracaoTE.pesooutros * 100).toFixed(2));
                setDistribuidora(iddistribuidora);
                obterAnosDistribuidora(iddistribuidora);
                anosTUSD.forEach((item) => { setAnosTUSD.push(item.ano) });
                anosTE.forEach((item) => { setAnosTE.push(item.ano) });
                setAnoDistribuidoraTUSD(setAnosTUSD);
                setAnoDistribuidoraTE(setAnosTE);

                if (pesosTUSD.length > 0) {
                    pesosTUSD.forEach((data) => {
                        addSerieTUSD({
                            id_serie: data.idserieidhistorica,
                            id: Math.floor(Math.random() * 6) + 1,
                            codigo: data.seriehistorica.codigo,
                            apelido: data.seriehistorica.nome,
                            fonte: data.seriehistorica.fonte,
                            periodicidade: data.seriehistorica.periodicidade.tipo,
                            categoria: "",
                            peso: (data.peso * 100).toFixed(2)
                        });
                    });
                }

                if (pesosTE.length > 0) {
                    pesosTE.forEach((data) => {
                        addSerieTE({
                            id_serie: data.idserieidhistorica,
                            id: Math.floor(Math.random() * 6) + 1,
                            codigo: data.seriehistorica.codigo,
                            apelido: data.seriehistorica.nome,
                            fonte: data.seriehistorica.fonte,
                            periodicidade: data.seriehistorica.periodicidade.tipo,
                            categoria: "",
                            peso: (data.peso * 100).toFixed(2)
                        });
                    });
                }
            }
        } catch (error) {
            console.info(error);
        }
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const onChangeIgualar = (valor) => {
        if (valor) {
            handleChangeIndex(0);
        }
        setIgualar(valor);
    };

    const handlePesoMigracaoTUSD = (event) => {
        setPesoMigracaoTUSD(event.target.value.replace("%", ""));
        setIsChange(true);
    }

    const handlePesoHistoricoTUSD = (event) => {
        setPesoHistoricoTUSD(event.target.value.replace("%", ""));
        setIsChange(true);
    }

    const handlePesoOutrosTUSD = (event) => {
        setPesoOutrosTUSD(event.target.value.replace("%", ""));
        setIsChange(true);
    }

    const handleChangeDistribuidora = async (event) => {
        await obterAnosDistribuidora(event.target.value);
        setDistribuidora(event.target.value);
    };

    const handleChangeAnoDistribuidoraTUSD = (event) => {
        setAnoDistribuidoraTUSD(event.target.value);
    };

    const handleChangeAnoDistribuidoraTE = (event) => {
        setAnoDistribuidoraTE(event.target.value);
    };

    const changeSelectTUSD = (event) => {
        setSeriesTUSD(
            seriesTUSD.map((item) => {
                if (parseInt(item.id_serie) === parseInt(event.currentTarget.dataset.id)) {
                    return { ...item, codigo: event.target.value, apelido: event.currentTarget.dataset.apelido, fonte: event.currentTarget.dataset.fonte, periodicidade: event.currentTarget.dataset.periodicidade, categoria: event.currentTarget.dataset.categoria };
                }
                return item;
            })
        );
        setIsChange(true);
    };

    const changeInputTUSD = (event) => {
        setSeriesTUSD(
            seriesTUSD.map((item) => {
                if (parseInt(item.id) === parseInt(event.target.id)) {
                    return { ...item, peso: event.target.value.replace("%", "") };
                }
                return item;

            })
        );
        setIsChange(true);
    };

    const handleTotalTUSD = useCallback(() => {
        let sum = 0;
        seriesTUSD.map((item) => sum += parseFloat(recuperarPontoDecimal(item.peso)));
        const values = [recuperarPontoDecimal(pesoHistoricoTUSD), recuperarPontoDecimal(pesoMigracaoTUSD), 
            recuperarPontoDecimal(pesoOutrosTUSD)];
        let sum2 = 0;
        values.map((item) => sum2 += parseFloat(item));
        setTotalTUSD((sum + sum2).toFixed(2));

    }, [seriesTUSD, pesoHistoricoTUSD, pesoMigracaoTUSD, pesoOutrosTUSD]);

    useEffect(() => { handleTotalTUSD() }, [handleTotalTUSD]);



    const handlePesoMigracaoTE = (event) => {
        setPesoMigracaoTE(event.target.value.replace("%", ""));
        setIsChange(true);
    }

    const handlePesoHistoricoTE = (event) => {
        setPesoHistoricoTE(event.target.value.replace("%", ""));
        setIsChange(true);
    }

    const handlePesoOutrosTE = (event) => {
        setPesoOutrosTE(event.target.value.replace("%", ""));
        setIsChange(true);
    }

    const changeSelectTE = (event) => {
        setSeriesTE(
            seriesTE.map((item) => {
                if (parseInt(item.id_serie) === parseInt(event.currentTarget.dataset.id)) {
                    return { ...item, id: event.currentTarget.dataset.id, codigo: event.target.value, apelido: event.currentTarget.dataset.apelido, fonte: event.currentTarget.dataset.fonte, periodicidade: event.currentTarget.dataset.periodicidade, categoria: event.currentTarget.dataset.categoria };
                }
                return item;
            })
        );
        setIsChange(true);
    };

    const changeInputTE = (event) => {
        setSeriesTE(
            seriesTE.map((item) => {
                if (parseInt(item.id) === parseInt(event.target.id)) {
                    return { ...item, peso: event.target.value.replace("%", "") };
                }
                return item;

            })
        );
        setIsChange(true);
    };

    const handleTotalTE = useCallback(() => {
        let sum = 0.0;
        seriesTE.map((item) => sum += parseFloat(recuperarPontoDecimal(item.peso)));
        const values = [recuperarPontoDecimal(pesoHistoricoTE), recuperarPontoDecimal(pesoMigracaoTE), 
            recuperarPontoDecimal(pesoOutrosTE)];
        let sum2 = 0.0;
        values.map((item) => sum2 += parseFloat(item));
        setTotalTE((sum + sum2).toFixed(2));

    }, [seriesTE, pesoHistoricoTE, pesoMigracaoTE, pesoOutrosTE]);

    useEffect(() => {
        if (id) obterConfiguracao(id);
    }, [id, obterConfiguracao]);

    useEffect(() => { handleTotalTE() }, [handleTotalTE]);

    const removeSeriesTUSD = (valueRemove) => {
        const newListSeriesTUSD = seriesTUSD?.filter(item => item !== valueRemove)
        setSeriesTUSD(newListSeriesTUSD);
    }

    const removeSeriesTE = (valueRemove) => {
        const newListSeriesTE = seriesTE?.filter(item => item !== valueRemove)
        setSeriesTE(newListSeriesTE);
    }

    const enviarFormulario = async () => {
        try {
            const obj = {
                "TUSD": {
                    "configuracao": {
                        "id": distribuidora,
                        "grupomercado": "TUSD",
                        "pesomigracao": recuperaValorFloat(pesoMigracaoTUSD),
                        "pesohistorico": recuperaValorFloat(pesoHistoricoTUSD),
                        "pesooutros": recuperaValorFloat(pesoOutrosTUSD)
                    },
                    "anosativos": anoDistribuidoraTUSD.map(item => (
                        {
                            "ano": item
                        }
                    )),
                    "pesossh": seriesTUSD.map(item => (
                        {
                            "seriehistorica": {
                                "idserieidhistorica": item.id,
                                "codigo": item.codigo,
                                "nome": item.apelido,
                                "fonte": item.fonte,
                                "periodicidade": {
                                    "tipo": item.periodicidade
                                },
                                "categoria": {
                                    "tipo": item.categoria
                                }
                            },
                            "peso": recuperaValorFloat(item.peso)
                        }
                    ))
                },
                "TE": {
                    "configuracao": {
                        "id": distribuidora,
                        "grupomercado": "TE",
                        "pesomigracao": !igualar ? recuperaValorFloat(pesoMigracaoTE) : recuperaValorFloat(pesoMigracaoTUSD),
                        "pesohistorico": !igualar ? recuperaValorFloat(pesoHistoricoTE) : recuperaValorFloat(pesoHistoricoTUSD),
                        "pesooutros": !igualar ? recuperaValorFloat(pesoOutrosTE) : recuperaValorFloat(pesoOutrosTUSD)
                    },
                    "anosativos": !igualar ? anoDistribuidoraTE.map(item => (
                        {
                            "ano": item
                        }
                    )) : anoDistribuidoraTUSD.map(item => (
                        {
                            "ano": item
                        }
                    )),
                    "pesossh": !igualar ? seriesTE.map(item => (
                        {
                            "seriehistorica": {
                                "idserieidhistorica": item.id,
                                "codigo": item.codigo,
                                "nome": item.apelido,
                                "fonte": item.fonte,
                                "periodicidade": {
                                    "tipo": item.periodicidade
                                },
                                "categoria": {
                                    "tipo": item.categoria
                                }
                            },
                            "peso": recuperaValorFloat(item.peso)
                        }
                    )) : seriesTUSD.map(item => (
                        {
                            "seriehistorica": {
                                "idserieidhistorica": item.id,
                                "codigo": item.codigo,
                                "nome": item.apelido,
                                "fonte": item.fonte,
                                "periodicidade": {
                                    "tipo": item.periodicidade
                                },
                                "categoria": {
                                    "tipo": item.categoria
                                }
                            },
                            "peso": recuperaValorFloat(item.peso)
                        }
                    ))
                }
            }
            let salvou = false;

            if (!isEdit) {
                salvou = await add(obj);
                setIsEdit(true);
                obterConfiguracao(distribuidora);
            } else {
                salvou = await edit(obj);
                obterConfiguracao(id);
            }

            if (salvou) {
                store.dispatch(
                    alertaExibir({
                        tipo: "success",
                        mensagem: "Configuração de Série Histórica realizada com sucesso!"
                    })
                );
            }

        } catch (error) {
            console.info(error)
        }
    }

    const recuperarPontoDecimal = ((sequencia) => {
        return String(sequencia).replace(",",".");
    });

    const recuperaValorFloat = ((sequencia) => {
        return (parseFloat(String(sequencia).replace(",",".")) / 100).toFixed(4);
    });

    const aoEnviarFormulario = (dados) => {
        if (totalTUSD != 100.00 || (totalTE != 100.00 && !igualar)) {
            store.dispatch(
                alertaExibir({
                    tipo: "warning",
                    mensagem: "O total dos pesos está ultrapassando 100% nas guias TUSD e/ou TE"
                })
            );
        } else {
            alertaExibir({
                tipo: "success",
                mensagem: "Sucesso"
            })
            enviarFormulario(dados);
        }
    }

    const cancelar = () => {

        setIsChange(false);

        setSeriesTUSD([]);
        setPesoMigracaoTUSD("0");
        setPesoHistoricoTUSD("0");
        setPesoOutrosTUSD("0");

        setSeriesTE([]);
        setPesoMigracaoTE("0");
        setPesoHistoricoTE("0");
        setPesoOutrosTE("0");
    }

    return (
        <form className="needs-validation" onSubmit={handleSubmit(aoEnviarFormulario)}>
            <div className={classes.root}>

                <AppBar position="static" color="default">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                    >
                        <Tab label="TUSD" {...a11yProps(0)} />
                        <Tab label="TE" {...a11yProps(1)} disabled={igualar} />
                    </Tabs>
                </AppBar>
                <SwipeableViews
                    axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                >
                    <TabPanel value={value} index={0} dir={theme.direction}>
                        <Grid container p={1} spacing={2} className={classes.container} justifyContent="center">
                            <Grid item sm={1}>
                                <BotaoRetornarListagem
                                    justify="flex-end"
                                    urlListagem={RotasDTO.DistribuidoraMercadoFaturado}
                                />
                            </Grid>
                            <Grid item sm={3} className={classes.alignItems}>
                                <Grid className={classes.formSpaceBetweenTop}>
                                    <b className={classes.marginLabel}>Distribuidora</b>
                                    <div style={{ paddingTop: "15px" }}>
                                        <FormControl variant="outlined" className={classes.formControl}>
                                            <InputLabel id="perid">Distribuidora</InputLabel>
                                            <Select
                                                variant="outlined"
                                                labelId="distr"
                                                id="distr"
                                                value={distribuidora ?? ""}
                                                onChange={handleChangeDistribuidora}
                                                label="Distribuidora"
                                                fullWidth
                                                readOnly={id ? Boolean(true) : Boolean(false)}>   
                                                {distribuidoras.map((dist) => (
                                                    <MenuItem key={dist.idDistribuidora.toString()} value={dist.idDistribuidora}>{dist.titulo}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </Grid>
                                <Grid className={classes.formSpaceBetweenBottom}>
                                    <b className={classes.marginLabel}>Período</b>
                                    <div style={{ paddingTop: "15px" }}>
                                        <FormControl variant="outlined" className={classes.formControl}>
                                            <InputLabel id="perid">Período</InputLabel>
                                            <Select
                                                variant="outlined"
                                                labelId="perid"
                                                id="perid1"
                                                value={anoDistribuidoraTUSD ?? ""}
                                                onChange={handleChangeAnoDistribuidoraTUSD}
                                                label="Anos"
                                                fullWidth
                                                multiple>
                                                {anosDistribuidoras.map((dist) => (
                                                    <MenuItem key={dist.ano} value={dist.ano}>{dist.ano}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid item sm={6}>
                                <Grid container p={1} spacing={2} className={classes.container}>
                                    <Grid item sm={8} justifyContent="flex-start">
                                        <b>Série Histórica TUSD</b>
                                    </Grid>
                                    <Grid item sm={3} justifyContent="flex-start">
                                        <b>Peso</b>
                                    </Grid>
                                </Grid>
                                {seriesTUSD?.map(serie => (
                                    <Grid container p={1} spacing={2} className={classes.container}>
                                        <Grid item sm={8}>
                                            <FormControl variant="outlined" className={classes.formControl}>
                                                <InputLabel id="seriesHistoricas-label">Nome da Série Histórica</InputLabel>
                                                <Select
                                                    variant="outlined"
                                                    labelId="seriesHistoricas-label"
                                                    label="Série Histórica TUSD"
                                                    onChange={changeSelectTUSD}
                                                    value={serie.codigo}
                                                    fullWidth>
                                                    {seriesHistoricas.map((item) => (
                                                        <MenuItem
                                                            key={item.codigo}
                                                            data-id={serie.id_serie}
                                                            data-apelido={item.apelido}
                                                            data-fonte={item.fonte}
                                                            data-periodicidade={item.periodicidade === "M" ? "Mensal" : "Anual"}
                                                            data-categoria={item.idCategoria}
                                                            value={item.codigo}
                                                        >
                                                            {item.codigo}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item sm={3}>
                                            <MaterialInputMascara
                                                type="text"
                                                sufixo="%"
                                                id={serie.id}
                                                name="teste"
                                                defaultValue={serie.peso}
                                                onBlur={changeInputTUSD}
                                                renderIconShowHide={false}
                                                ref={register}
                                            />
                                        </Grid>
                                        <Grid item sm={1}>
                                            <button
                                                type="button"
                                                className={classes.botao}
                                                onClick={() => {
                                                    removeSeriesTUSD(serie);
                                                }}
                                            >
                                                X
                                            </button>
                                        </Grid>
                                    </Grid>
                                ))}
                                <br />
                                <Grid container p={1} spacing={2} className={classes.container}>
                                    <Grid item sm={8}>
                                        <MaterialInputTexto
                                            type="text"
                                            name="migracaoTUSD"
                                            defaultValue="Migração"
                                            renderIconShowHide={false}
                                            disabled
                                            className={classes.inputText}
                                        />
                                    </Grid>
                                    <Grid item sm={3}>
                                        <MaterialInputMascara
                                            type="text"
                                            sufixo="%"
                                            id="pesoMigracaoTUSD"
                                            name="pesoMigracaoTUSD"
                                            defaultValue={pesoMigracaoTUSD}
                                            onBlur={handlePesoMigracaoTUSD}
                                            renderIconShowHide={false}
                                            ref={register}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container p={1} spacing={2} className={classes.container}>
                                    <Grid item sm={8}>
                                        <MaterialInputTexto
                                            type="text"
                                            name="historicoTUSD"
                                            defaultValue="Histórico"
                                            renderIconShowHide={false}
                                            disabled
                                            className={classes.inputText}
                                        />
                                    </Grid>
                                    <Grid item sm={3}>
                                        <MaterialInputMascara
                                            type="text"
                                            sufixo="%"
                                            id="pesoHistoricoTUSD"
                                            name="pesoHistoricoTUSD"
                                            defaultValue={pesoHistoricoTUSD}
                                            onBlur={handlePesoHistoricoTUSD}
                                            renderIconShowHide={false}
                                            ref={register}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container p={1} spacing={2} className={classes.container}>
                                    <Grid item sm={8}>
                                        <MaterialInputTexto
                                            type="text"
                                            name="outrosTUSD"
                                            defaultValue="Outros"
                                            renderIconShowHide={false}
                                            disabled
                                            className={classes.inputText}
                                        />
                                    </Grid>
                                    <Grid item sm={3}>
                                        <MaterialInputMascara
                                            type="text"
                                            sufixo="%"
                                            id="pesoOutrosTUSD"
                                            name="pesoOutrosTUSD"
                                            defaultValue={pesoOutrosTUSD}
                                            onBlur={handlePesoOutrosTUSD}
                                            renderIconShowHide={false}
                                            ref={register}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container p={1} spacing={2} className={classes.container}>
                                    <Grid item sm={8} className={classes.total}>
                                        Total:
                                    </Grid>
                                    <Grid item sm={3} className="text-center">
                                        <b>{totalTUSD ? `${totalTUSD}%` : "%"}</b>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sm={2}>
                                <Grid container p={1} spacing={2} className={classes.container}>
                                    <Grid item sm={12}>
                                        <button
                                            type="button"
                                            className={classes.botao}
                                            onClick={() => {
                                                addSerieTUSD({
                                                    id_serie: Math.floor(Math.random() * 6) + 1,
                                                    id: Math.floor(Math.random() * 6) + 1,
                                                    codigo: "",
                                                    apelido: "",
                                                    fonte: "",
                                                    periodicidade: "",
                                                    categoria: "",
                                                    peso: "0"
                                                });
                                            }}
                                        >
                                            + Série Histórica
                                        </button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={1} dir={theme.direction}>
                        <Grid container p={1} spacing={2} className={classes.container} justifyContent="center">
                            <Grid item sm={1}>
                                <BotaoRetornarListagem
                                    justify="flex-end"
                                    urlListagem={RotasDTO.DistribuidoraMercadoFaturado}
                                />
                            </Grid>
                            <Grid item sm={3}>
                                <Grid className={classes.formSpaceBetweenBottom}>
                                    <b className={classes.marginLabel}>Período</b>
                                    <div style={{ paddingTop: "15px" }}>
                                        <FormControl variant="outlined" className={classes.formControl}>
                                            <InputLabel id="perid">Período</InputLabel>
                                            <Select
                                                variant="outlined"
                                                labelId="perid"
                                                id="perid1"
                                                value={anoDistribuidoraTE ?? ""}
                                                onChange={handleChangeAnoDistribuidoraTE}
                                                label="Anos"
                                                fullWidth
                                                multiple>
                                                {anosDistribuidoras.map((dist) => (
                                                    <MenuItem key={dist.ano} value={dist.ano}>{dist.ano}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid item sm={6}>
                                <Grid container p={1} spacing={2} className={classes.container}>
                                    <Grid item sm={8} justifyContent="flex-start">
                                        <b>Série Histórica TE</b>
                                    </Grid>
                                    <Grid item sm={3} justifyContent="flex-start">
                                        <b>Peso</b>
                                    </Grid>
                                </Grid>
                                {seriesTE?.map(serie => (
                                    <Grid container p={1} spacing={2} className={classes.container}>
                                        <Grid item sm={8}>
                                            <FormControl variant="outlined" className={classes.formControl}>
                                                <InputLabel id="seriesHistoricas-label">Nome da Série Histórica</InputLabel>
                                                <Select
                                                    variant="outlined"
                                                    labelId="seriesHistoricas-label"
                                                    label="Série Histórica"
                                                    onChange={changeSelectTE}
                                                    value={serie.codigo}
                                                    fullWidth>
                                                    {seriesHistoricas.map((item) => (
                                                        <MenuItem
                                                            key={item.codigo}
                                                            data-id={serie.id_serie}
                                                            data-apelido={item.apelido}
                                                            data-fonte={item.fonte}
                                                            data-periodicidade={item.periodicidade === "M" ? "Mensal" : "Anual"}
                                                            data-categoria={item.idCategoria}
                                                            value={item.codigo}
                                                        >
                                                            {item.codigo}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item sm={3}>
                                            <MaterialInputMascara
                                                type="text"
                                                sufixo="%"
                                                id={serie.id}
                                                name="teste"
                                                defaultValue={serie.peso}
                                                onBlur={changeInputTE}
                                                renderIconShowHide={false}
                                                ref={register}
                                            />
                                        </Grid>
                                        <Grid item sm={1}>
                                            <button
                                                type="button"
                                                className={classes.botao}
                                                onClick={() => {
                                                    removeSeriesTE(serie);
                                                }}
                                            >
                                                X
                                            </button>
                                        </Grid>
                                    </Grid>
                                ))}
                                <br />
                                <Grid container p={1} spacing={2} className={classes.container}>
                                    <Grid item sm={8}>
                                        <MaterialInputTexto
                                            type="text"
                                            name="migracaoTE"
                                            defaultValue="Migração"
                                            renderIconShowHide={false}
                                            disabled
                                            className={classes.inputText}
                                        />
                                    </Grid>
                                    <Grid item sm={3}>
                                        <MaterialInputMascara
                                            type="text"
                                            sufixo="%"
                                            id="pesoMigracaoTE"
                                            name="pesoMigracaoTE"
                                            defaultValue={pesoMigracaoTE}
                                            onBlur={handlePesoMigracaoTE}
                                            renderIconShowHide={false}
                                            ref={register}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container p={1} spacing={2} className={classes.container}>
                                    <Grid item sm={8}>
                                        <MaterialInputTexto
                                            type="text"
                                            name="historicoTE"
                                            defaultValue="Histórico"
                                            renderIconShowHide={false}
                                            disabled
                                            className={classes.inputText}
                                        />
                                    </Grid>
                                    <Grid item sm={3}>
                                        <MaterialInputMascara
                                            type="text"
                                            sufixo="%"
                                            id="pesoHistoricoTE"
                                            name="pesoHistoricoTE"
                                            defaultValue={pesoHistoricoTE}
                                            onBlur={handlePesoHistoricoTE}
                                            renderIconShowHide={false}
                                            ref={register}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container p={1} spacing={2} className={classes.container}>
                                    <Grid item sm={8}>
                                        <MaterialInputTexto
                                            type="text"
                                            name="outrosTE"
                                            defaultValue="Outros"
                                            renderIconShowHide={false}
                                            disabled
                                            className={classes.inputText}
                                        />
                                    </Grid>
                                    <Grid item sm={3}>
                                        <MaterialInputMascara
                                            type="text"
                                            sufixo="%"
                                            id="pesoOutrosTE"
                                            name="pesoOutrosTE"
                                            defaultValue={pesoOutrosTE}
                                            onBlur={handlePesoOutrosTE}
                                            renderIconShowHide={false}
                                            ref={register}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container p={1} spacing={2} className={classes.container}>
                                    <Grid item sm={8} className={classes.total}>
                                        Total:
                                    </Grid>
                                    <Grid item sm={3} className="text-center">
                                        <b>{totalTE ? `${totalTE}%` : "%"}</b>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sm={2}>
                                <Grid container p={1} spacing={2} className={classes.container}>
                                    <Grid item sm={12}>
                                        <button
                                            type="button"
                                            className={classes.botao}
                                            onClick={() => {
                                                addSerieTE({
                                                    id_serie: Math.floor(Math.random() * 6) + 1,
                                                    id: Math.floor(Math.random() * 6) + 1,
                                                    codigo: "",
                                                    apelido: "",
                                                    fonte: "",
                                                    periodicidade: "",
                                                    categoria: "",
                                                    peso: "0"
                                                });
                                            }}
                                        >
                                            + Série Histórica
                                        </button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </TabPanel>
                </SwipeableViews>
                <Grid container className={classes.container} justifyContent="center" sm={12}>
                    <Grid item sm={6} class={classes.footerCheckbox}>
                        <MaterialSwitch
                            label="Igualar TUSD e TE?"
                            labelPlacement="top"
                            id="igualar"
                            name="igualar"
                            ref={register()}
                            checked={igualar}
                            onChange={(valor) => onChangeIgualar(valor)}
                        />
                    </Grid>
                    <Grid item sm={6} class={classes.footerButtons}>
                        <Grid container p={1} spacing={2} className={classes.container} justifyContent="flex-end">
                            <Grid item sm={3} style={!isChange ? { display: "none" } : {}}>
                                <Botao type="button" color={Base.Mango} label="Cancelar" onClick={cancelar} className={classes.buttonCustom} />
                            </Grid>
                            <Grid item sm={3}>
                                <Botao type="submit" color={Base.Mango} label="Salvar" className={classes.button} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </form>
    );
}

export default DistribuidoraConfiguracao;