import React, { useState, useEffect, useRef } from "react";
import FileSaver from "file-saver";

// Icones
import TuneIcon from "@mui/icons-material/Tune";

// Form Hooks
import { useForm, useWatch } from "react-hook-form";

// Redux
import { useSelector } from "react-redux";

// Componentes
import { Grid, Button, Box, Chip, Typography } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import ColunaComponentePersonalizado from "componentes/tabelaPaginada/colunas/colunaComponentePersonalizado";
import theme from "themes";
import FiltroAdicionalTabela from "paginas/Cadastros/Agente/filtroAdicionalTabela";
import MaterialInputBusca from "componentes/inputBusca";
import { b64toBlob } from "global/utils/blobUtil";
import Loader from "../../../componentes/loader";
import MaterialInputTexto from "../../../componentes/inputTexto/materialInput";
import ModalLuz from "../../../componentes/modalLuz";
import Modal from "../../../componentes/modal";
import Botao from "../../../componentes/botao";
import BotaoFiltroOrdenacao from "../../../componentes/botaoFiltroOrdenar";
import TabelaPaginada from "../../../componentes/tabelaPaginada";
import Coluna from "../../../componentes/tabelaPaginada/colunas/coluna";
import UploadCustomizado from "../../../componentes/uploadCustomizado";
import { ReactComponent as Upload } from "../../../assets/cotacoes/luz/upload.svg";
import ResultadoPaginadoDto from "../../../componentes/tabelaPaginada/resultadoPaginadoDto";
import enumerador from "./enumerador";
import ColunaMultiplosBotoes from "./colunaCustomizada";
import SerasaDto from "./serasaDto";

// Serviços
import {
  listarRegistros,
  dadosProtocoloDemanda,
  statusProtocoloDemanda,
  downloadArquivo,
  listarOrganizarPor,
  atualizarStatusAprovado,
  uploadArquivo,
  downloadSerasa
} from "../../../servicos/serasaServico";

// Redux
import { store } from "../../../global/redux";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";
import { desabilitarUnidade } from "../../../global/redux/modulos/usuario/actions";

// Styles
import { useStyles, chipStyles, BotaoDownload } from "./style";

import { usuarioPossuiFuncionalidade } from "../../../servicos/funcionalidadesServico";
import { RotasDTO } from "../../../global/rotas/rotasUrlDto";
import { InterfaceDTO } from "../../../global/dto/interfacesDto";

const ListaSerasa = () => {
  const classes = useStyles();
  const classesChip = chipStyles();
  const refTabela = useRef(null);

  const usuario = useSelector((state) => state.usuario);
  const { empresaSelecionada } = usuario;
  const { clienteSelecionado } = usuario;

  const usuarioGlobal = useSelector((state) => state.usuario);
  const { menu } = usuarioGlobal;
  const rotas = useSelector((state) => state.rotas);
  const [mostrarBotaoAprovar, setMostrarBotaoAprovar] = useState(false);

  useEffect(() => {
    store.dispatch(desabilitarUnidade(true));

    return () => store.dispatch(desabilitarUnidade(false));
  }, [desabilitarUnidade]);

  // eslint-disable-next-line no-unused-vars
  const [carregandoUnidades, setCarregandoUnidades] = useState(false);
  const [carregandoProtocoloDemanda, setCarregandoProtocoloDemanda] = useState(
    false
  );
  const [itemEscolhido, setItemEscolhido] = useState([]);
  const [resultadoProtocoloDemanda, setResultadoProtocoloDemanda] = useState(
    []
  );
  const [ordemColunaSelecionada, setOrdemColunaSelecionada] = useState();
  const [valorOrdenacao, setValorOrdenacao] = useState(4);
  const [filtroAdicionalTabela, setFiltroAdicionalTabela] = useState(
    new FiltroAdicionalTabela()
  );
  const [modalAcao, setModalAcao] = useState(false);
  const [modalAprovar, setModalAprovar] = useState(false);
  const [carregandoAprovacao, setCarregandoAprovacao] = useState(false);
  const [motivo, setMotivo] = useState("");
  const [carregandoArquivo, setCarregandoArquivo] = useState(false);
  const [arquivo, setArquivo] = useState("");
  const [arquivoDownload, setArquivoDownload] = useState("");

  const onAddUpload = async (valor) => {
    setCarregandoArquivo(true);
    const file = valor[0]?.file;
    setArquivo(file);
    setCarregandoArquivo(false);
  };

  const onDropRejected = (valor) => {
    const arquivo = valor[0];
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem: `Tipo do arquivo ${arquivo.name} não é permitido!`
      })
    );
  };

  const converterArquivoSerasa = async () => {
    setCarregandoProtocoloDemanda(true);
    try {
      const base64 = b64toBlob(arquivoDownload?.base64);
      const blob = new Blob([base64]);
      FileSaver.saveAs(blob, arquivoDownload?.nomeArquivo);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: "Erro ao carregar download, tente novamente."
        })
      );
    } finally {
      setCarregandoProtocoloDemanda(false);
    }
  };

  const downloadArquivoSerasa = async (protocolo) => {
    setCarregandoProtocoloDemanda(true);
    setArquivoDownload("");

    downloadSerasa(protocolo?.cnpj)
      .then((resp) => {
        setArquivoDownload(resp.data);
      })
      .catch(() => {});
  };

  const obterProtocoloDemanda = async (protocoloDemanda) => {
    setCarregandoProtocoloDemanda(true);

    try {
      const resultado = await dadosProtocoloDemanda(protocoloDemanda);

      if (resultado?.status === 200) {
        setCarregandoProtocoloDemanda(false);
        setResultadoProtocoloDemanda(resultado?.data);
        downloadArquivoSerasa(resultado?.data);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: "Erro ao carregar dados, tente novamente."
        })
      );
    }
    setCarregandoProtocoloDemanda(false);
  };

  const onClickEditar = (id) => {
    setItemEscolhido(id);
    obterProtocoloDemanda(id?.protocoloDemanda);
    setModalAcao(true);
  };

  function formatarValor(valor) {
    return valor
      ? valor.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
          minimumFractionDigits: 2
        })
      : valor;
  }

  const gerarArquivo = async () => {
    try {
      const protocolo = resultadoProtocoloDemanda.protocoloDemanda;
      await downloadArquivo(protocolo);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: "Erro ao carregar download, tente novamente."
        })
      );
    }
  };

  const atualizarStatus = async (status) => {
    setCarregandoProtocoloDemanda(true);
    try {
      const resultado = await statusProtocoloDemanda(
        resultadoProtocoloDemanda.protocoloDemanda,
        status
      );
      if (resultado?.status === 200) {
        setModalAcao(false);
        setCarregandoProtocoloDemanda(false);
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: "Status atualizado com sucesso."
          })
        );
        refTabela.current.obterDadosPaginados();
      } else {
        store.dispatch(
          alertaExibir({
            tipo: "danger",
            mensagem: "Erro ao atualizar status."
          })
        );
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: "Erro ao carregar dados, tente novamente."
        })
      );
    }
    setCarregandoProtocoloDemanda(false);
  };

  const atualizarStatusAprovar = async () => {
    setCarregandoAprovacao(true);
    try {
      const params = {
        cnpj: resultadoProtocoloDemanda?.cnpj,
        statusId: 2,
        motivo,
        usuarioIdEdicao: resultadoProtocoloDemanda?.id
      };
      const paramsArquivo = {
        cnpj: resultadoProtocoloDemanda?.cnpj,
        arquivo
      };
      let resultado = null;
      if (motivo) {
        resultado = await atualizarStatusAprovado(params);
      }
      const resultadoArquivo = await uploadArquivo(paramsArquivo);
      if (resultado?.status === 200 || resultadoArquivo?.status === 200) {
        setModalAcao(false);
        setModalAprovar(false);
        setCarregandoAprovacao(false);
        setMotivo("");
        setArquivo("");
        setArquivoDownload("");
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: "Status atualizado com sucesso."
          })
        );
        refTabela.current.obterDadosPaginados();
      } else {
        store.dispatch(
          alertaExibir({
            tipo: "danger",
            mensagem: "Erro ao atualizar status."
          })
        );
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: "Erro ao carregar dados, tente novamente."
        })
      );
    }
    setCarregandoAprovacao(false);
  };

  const montarModalAprovar = () => {
    return (
      <Loader loading={carregandoAprovacao} className="w-auto">
        <Grid container spacing={2}>
          <Grid item xs={12} className={classes.aprovarTitulo}>
            <b>Motivo aprovação serasa</b>
          </Grid>
          <Grid item xs={12}>
            <MaterialInputTexto
              type="text"
              id="motivo"
              name="motivo"
              label="Digite aqui"
              renderIconShowHide={false}
              defaultValue={motivo}
              onInput={(e) => setMotivo(e.target.value)}
              permiteValorBranco
            />
          </Grid>
          <Grid item xs={12} pb={1}>
            <Typography color="#191C22" pb={2} textAlign="center">
              Upload arquivo Comprovação Serasa
            </Typography>
            <Box
              bgcolor="rgba(250, 62, 177, 0.08)"
              border="2px dashed #FA3EB1"
              borderRadius="8px"
            >
              <UploadCustomizado
                dropAreaText="Anexar arquivo"
                loader={carregandoArquivo}
                onAdd={onAddUpload}
                progressValue={false}
                showLoader={false}
                onDropRejected={onDropRejected}
                acceptedFiles={["image/*", "application/pdf"]}
                icon={Upload}
              />
            </Box>

            {arquivo && (
              <Box pt={1}>
                <Chip
                  classes={{ root: classesChip.previewChip }}
                  label={arquivo?.name}
                  onDelete={() => setArquivo("")}
                  variant="outlined"
                />
              </Box>
            )}
          </Grid>
          <Grid item xs={12}>
            <Botao
              label="Enviar"
              disabled={!arquivo}
              onClick={() => atualizarStatusAprovar()}
            />
          </Grid>
        </Grid>
      </Loader>
    );
  };

  const montarModal = () => {
    return (
      <Loader loading={carregandoProtocoloDemanda} className="w-auto">
        <Grid container>
          <Grid
            xs={12}
            md={arquivoDownload ? 6 : 12}
            lg={arquivoDownload ? 7 : 12}
            sx={{
              display: "flex",
              justifyContent: {
                xs: "center",
                lg: arquivoDownload ? "flex-end" : "center"
              }
            }}
          >
            <Box className={classes.dataConsultaSerasa}>
              <b>Data de consulta:</b>
              {itemEscolhido?.dataConsultaSerasa}
            </Box>
          </Grid>
          {arquivoDownload && (
            <Grid
              xs={12}
              md={6}
              lg={5}
              sx={{
                display: "flex",
                justifyContent: { xs: "center", lg: "flex-end" },
                paddingRight: { xs: 0, lg: "24px" }
              }}
            >
              <BotaoDownload onClick={() => converterArquivoSerasa()}>
                Download arquivo Comprovação Serasa <DownloadIcon />
              </BotaoDownload>
            </Grid>
          )}
        </Grid>
        <Grid container>
          <Grid xs={5} className={classes.gridPaddingTopo}>
            <div>
              <b>Validação do crédito: </b>
              {itemEscolhido?.nomeUnidade}
            </div>
          </Grid>
          <Grid xs={3} className={classes.gridPaddingTopo}>
            <div>
              <b>CNPJ: </b>
              {itemEscolhido?.cnpj}
            </div>
          </Grid>
          <Grid xs={2} className={classes.gridPaddingTopo}>
            <div>
              <b>Score: </b>
              {itemEscolhido?.score}
            </div>
          </Grid>
          <Grid xs={2} className={classes.gridPaddingTopo}>
            <div>
              <b>Probab Inad.: </b>
              {itemEscolhido?.probabilidadeInadimplencia}
            </div>
          </Grid>

          <Grid xs={6} className={classes.gridPadding}>
            <div>
              <b>Refin</b>
              {` - ${resultadoProtocoloDemanda?.refin} - `}
              {resultadoProtocoloDemanda?.refiValor &&
                formatarValor(resultadoProtocoloDemanda?.refiValor)}
            </div>
            <div>
              <b>Pefin</b>
              {`- ${resultadoProtocoloDemanda?.pefin} -`}
              {resultadoProtocoloDemanda?.pefinValor &&
                formatarValor(resultadoProtocoloDemanda?.pefinValor)}
            </div>
            <div>
              <b>Dívidas Vencidas</b>
              {`${resultadoProtocoloDemanda?.dividasVencidas} -`}
              {resultadoProtocoloDemanda?.dividasVencidasValor &&
                formatarValor(resultadoProtocoloDemanda?.dividasVencidasValor)}
            </div>
            <div>
              <b>Falência/Rec. Judicial</b>
              {`-
              ${resultadoProtocoloDemanda?.falenciaRecJudicial} -`}
              {resultadoProtocoloDemanda?.falenciaRecJudicialValor &&
                formatarValor(
                  resultadoProtocoloDemanda?.falenciaRecJudicialValor
                )}
            </div>
          </Grid>
          <Grid xs={6} className={classes.gridPadding}>
            <div>
              <b>Protestos</b>
              {`- ${resultadoProtocoloDemanda?.protestos} - `}
              {resultadoProtocoloDemanda?.protestosValor &&
                formatarValor(resultadoProtocoloDemanda?.protestosValor)}
            </div>
            <div>
              <b>Cheques</b>
              {`- ${resultadoProtocoloDemanda?.cheques} - `}
              {formatarValor(resultadoProtocoloDemanda?.chequesValor)}
            </div>
            <div>
              <b>Recheques</b>
              {` - ${resultadoProtocoloDemanda?.recheque} - `}
              {resultadoProtocoloDemanda?.rechequeValor &&
                formatarValor(resultadoProtocoloDemanda?.rechequeValor)}
            </div>
            <div>
              <b>Ações Judiciais</b>
              {` -
              ${resultadoProtocoloDemanda?.acoesJudiciais} - `}
              {resultadoProtocoloDemanda?.acoesJudiciaisValor &&
                formatarValor(resultadoProtocoloDemanda?.acoesJudiciaisValor)}
            </div>
          </Grid>

          {(itemEscolhido?.status === "Pendente" ||
            itemEscolhido?.status === "Reprovado") && (
            <>
              <Grid xs={4} className={classes.btnAcoes}>
                <Button
                  onClick={() => gerarArquivo()}
                  className={classes.btnDownload}
                >
                  <div>Download Balanço DRE</div>
                </Button>
              </Grid>
              <Grid xs={4} className={classes.btnAcoes}>
                <Button
                  onClick={() => atualizarStatus(false)}
                  className={classes.btnRejeitado}
                >
                  <div>Rejeitar</div>
                </Button>
              </Grid>
              <Grid
                xs={4}
                className={`${
                  mostrarBotaoAprovar ? `${classes.btnAcoes}` : "d-none"
                }`}
              >
                <Button
                  onClick={() => setModalAprovar(true)}
                  className={classes.btnValidado}
                >
                  <div>Aprovar</div>
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Loader>
    );
  };

  const onClickBotao = (tipo, parametros) => {
    switch (tipo) {
      case enumerador.Edicao:
        onClickEditar(parametros);
        break;
      default:
        break;
    }
  };

  const colunas = [
    new Coluna("nomeUnidade", "Nome Fantasia"),
    new Coluna("cnpj", "CNPJ"),
    new Coluna("score", "Score"),
    new Coluna("probabilidadeInadimplencia", "Prob. de Inad"),
    new Coluna("dataConsultaSerasa", "Data de consulta"),
    new Coluna("produto", "Produto"),
    new Coluna("status", "Status"),
    new ColunaComponentePersonalizado(
      "opcoes",
      "Validação",
      ColunaMultiplosBotoes,
      onClickBotao
    )
  ];

  useEffect(() => {
    const possuiFuncionalidadeVerBotaoAprovar = usuarioPossuiFuncionalidade(
      menu,
      rotas.rotaAtual.caminho,
      RotasDTO.Serasa,
      InterfaceDTO.PermissaoVerAprovarSerasa
    );
    setMostrarBotaoAprovar(possuiFuncionalidadeVerBotaoAprovar);
  }, [modalAcao]);

  const handleProduto = (produtoId) => {
    switch (produtoId) {
      case 1:
        return "ACL";
      case 4:
        return "GD";
      default:
        return "";
    }
  };

  const obterSerasa = async (parametros) => {
    const resultado = await listarRegistros(parametros);
    if (!resultado.status === 200) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: resultado.mensagem
        })
      );
      return new ResultadoPaginadoDto([], 1, 0, 0);
    }

    if (resultado?.length === 0 ?? true)
      return new ResultadoPaginadoDto([], 1, 0, 0);

    const resLinhas = await resultado.data?.serasaExperianLista?.map((res) => {
      const produto = handleProduto(res.produtoId);
      return new SerasaDto(
        res.id,
        res.nomeFantasia === "" ? "-" : res.nomeFantasia,
        res.cnpj === "" ? "-" : res.cnpjFormatado,
        res.score === null ? "-" : res.score,
        res.probabilidadeInadimplencia === null
          ? "-"
          : // eslint-disable-next-line prefer-template
            res.probabilidadeInadimplencia + "%",
        res.dataConsultaSerasa === ""
          ? "-"
          : window
              .moment(res?.dataConsultaSerasa)
              .format("DD/MM/yyyy - H:mm:ss"),
        res?.statusNome === "" ? "-" : res?.statusNome,
        res.protocoloDemanda === "" ? "-" : res.protocoloDemanda,
        false,
        produto
      );
    });

    return new ResultadoPaginadoDto(
      resLinhas,
      resultado.data.paginaAtual,
      resultado.data.totalItens,
      resultado.data.totalPaginas
    );
  };

  const onChangeFiltrosTabela = async (parametros) => {
    return obterSerasa({
      tamanhoPagina: parametros.totalPagina,
      pagina: parametros.pagina,
      textoPesquisa: parametros.pesquisar,
      ordenacao: valorOrdenacao,
      empresaId: empresaSelecionada
    });
  };

  const { register, control } = useForm({
    reValidateMode: "onSubmit"
  });

  const textoBusca = useWatch({
    control,
    name: "textoBusca",
    defaultValue: ""
  });

  const [ordemSelecionada, setOrdemSelecionada] = useState("");

  const handleClickSortOrderSelecionado = (valor, idCampoSelecionado) => {
    const val =
      ordemColunaSelecionada === idCampoSelecionado && valor === ""
        ? ""
        : idCampoSelecionado;
    setOrdemColunaSelecionada(val);
    setValorOrdenacao(valor);
  };

  const handleClickAtivarFiltro = () => {
    setFiltroAdicionalTabela(
      new FiltroAdicionalTabela(valorOrdenacao, empresaSelecionada)
    );
  };

  const handleAlterarFiltroLateral = () => {
    setFiltroAdicionalTabela(
      new FiltroAdicionalTabela(valorOrdenacao, empresaSelecionada)
    );
  };

  useEffect(() => {
    handleAlterarFiltroLateral();
  }, [empresaSelecionada, clienteSelecionado]);

  const handleClickOrdemSelecionada = (event) => {
    const val = ordemSelecionada === event ? "" : event;
    setOrdemSelecionada(val);
  };

  return (
    <Loader loading={carregandoUnidades} className="w-auto">
      <ModalLuz
        item={modalAprovar}
        mensagem={montarModalAprovar()}
        onCancelar={() => {
          setMotivo("");
          setArquivo("");
          setArquivoDownload("");
          setModalAprovar(false);
        }}
        footer={false}
      />
      <Modal
        item={modalAcao}
        mensagem={montarModal()}
        onCancelar={() => setModalAcao(false)}
      />
      <Grid
        container
        spacing={3}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={5}>
          <MaterialInputBusca
            type="text"
            id="textoBusca"
            name="textoBusca"
            label="Buscar"
            renderIconShowHide
            searchAdornment
            defaultValue={textoBusca ?? ""}
            ref={register}
            permiteValorBranco
          />
        </Grid>
        <Grid item xs={3} className={classes.itemFiltro}>
          <BotaoFiltroOrdenacao
            type="button"
            color={theme.color.secondaryBorderColor}
            background="transparent"
            label="Filtrar / Ordernar"
            icon={<TuneIcon />}
            className={classes.buttonFiltro}
            ordenacaoColuna={listarOrganizarPor()}
            ordemColunaSelecionada={ordemColunaSelecionada}
            ordemSelecionada={ordemSelecionada}
            onClickSortOrder={handleClickSortOrderSelecionado}
            onClickOrdenacao={handleClickOrdemSelecionada}
            onClickAtivarAgora={handleClickAtivarFiltro}
          />
        </Grid>

        <Grid item xs={12} className="mt-4">
          <TabelaPaginada
            ref={refTabela}
            onChangeFiltrosTabela={onChangeFiltrosTabela}
            colunas={colunas}
            pesquisar={textoBusca}
            filtrosAdicionais={filtroAdicionalTabela}
            paginationPerPage={10}
            paginaAtual={1}
            qtdLinhas={3}
            noHeader
          />
        </Grid>
      </Grid>
    </Loader>
  );
};

export default ListaSerasa;
