import { makeStyles } from "@mui/styles";
import theme from "themes";
import { Base } from "../../../../componentes/cores";

export const useStyles = makeStyles({
  container: {
    margin: "0 0 8px",
    width: "100%",
    "& .MuiInputBase-root": {
      height: "46px"
    },
    "& .MuiFormLabel-root": {
      lineHeight: "1"
    }
  },
  search: {
    "& label.Mui-focused": {
      color: `${theme.color.secondaryBorderColor} !important`
    },
    "& .MuiInput-underline:after": {
      borderColor: `${theme.color.secondaryBorderColor} !important`
    },
    "& .MuiFormLabel-root": {
      color: `${theme.color.secondaryBorderColor} !important`
    },
    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-input": {
        color: `${theme.color.secondaryBorderColor} !important`
      },
      "& .MuiInputAdornment-root": {
        color: `${theme.color.secondaryBorderColor} !important`
      },
      "& fieldset": {
        borderColor: `${theme.color.secondaryBorderColor} !important`
      },
      "&:hover fieldset": {
        borderColor: `${theme.color.secondaryBorderColor} !important`
      },
      "&.Mui-focused fieldset": {
        borderColor: `${theme.color.secondaryBorderColor} !important`
      }
    }
  },
  filter: {
    color: `${theme.color.secondaryBorderColor} !important`,
    display: "inline",
    fontSize: "18px",
    margin: 0,
    padding: 0,
    "& li": {
      display: "inline-block",
      marginLeft: "45px",
      position: "relative",
      "&:nth-child(1) .icon": {
        backgroundColor: Base.GreenFern
      },
      "&:nth-child(2) .icon": {
        backgroundColor: Base.BlueViolet
      },
      "&:nth-child(3) .icon": {
        backgroundColor: Base.BlueDodger
      }
    },
    "& .icon": {
      display: "inline-block",
      height: "20px",
      left: "-30px",
      position: "absolute",
      width: "20px"
    },
    "& .texto": { marginTop: "-2px" }
  },
  button: {
    fontSize: "18px"
  },
  cardCadastro: {
    backgroundColor: theme.color.bgColorCardCadastro
  }
});
