import { makeStyles } from '@mui/styles';
import { Base } from 'componentes/cores';
import { createTheme } from "@mui/material/styles";

const theme = createTheme();

export const useStyles = makeStyles(() => ({
  container: {
    margin: 0,
    width: '100%',
    "& .MuiGrid-root": {
      color: `${Base.BlackBastille} !important`
    },
    "& .MuiDropzoneArea-text": {
      color: `${Base.BlackBastille} !important`
    },
    "& .MuiInputBase-root": {
      height: "46px"
    },
    "& .MuiFormLabel-root": {
      lineHeight: "1"
    },
    '& .MuiGrid-container': {
      alignItems: "center"
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100% !important",
  },
  button: {
    fontSize: '18px',
  },
  botao: {
    background: 'none',
    color: 'inherit',
    border: 'none',
    padding: '0',
    font: 'inherit',
    cursor: 'pointer',
    outline: 'inherit',
  },
  checkbox: {
    "&.Mui-checked": {
      color: Base.GreyHit
    }
  },
}));

