import { makeStyles } from "@mui/styles";
import theme from "themes";
import { Base } from "../../../../componentes/cores";

export const useStyles = makeStyles({
  container: {
    margin: "0",
    width: "100%",
    "& .MuiInputBase-root": {
      height: "46px"
    },
    "& .MuiFormLabel-root": {
      lineHeight: "1"
    }
  },
  button: {
    fontSize: "18px"
  },
  inputTelefone: {
    maxWidth: "70%",
    width: "100%",
    "&.Mui-error": {
      borderColor: Base.RedError,
      color: Base.RedError
    }
  },
  containerTelefone: {
    "& .MuiInputBase-input": {
      height: "7px"
    }
  },
  containerSalvar: {
    margin: "0 0 24px 16px"
  },
  containerAtivo: {
    marginLeft: "24px"
  },
  divider: {
    margin: "16px 18px 16px"
  },
  cardCadastro: {
    backgroundColor: theme.color.bgColorCardCadastro
  }
});
