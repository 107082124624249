import { makeStyles } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import theme from "../../../../../themes";

const themes = createTheme();

export const useStyles = makeStyles(() => ({
  cardUpload: {
    backgroundColor: `${theme.color.novoListItemBackgroundHover}`,
    border: "none",
    borderRadius: 10,
    borderWidth: 1,
    padding: "25px",
    marginBottom: "13px",
    boxShadow: themes.shadows[1],
    display: "flex",
    "& .MuiDropzoneArea-root": {
      backgroundColor: "#FFF0F9",
      minHeight: "74px",
      border: `4px dashed ${theme.color.bordaUpload}`
    },
    "& .MuiDropzoneArea-icon": {
      color: theme.color.bordaUpload
    },
    "& .MuiDropzoneArea-textContainer": {
      display: "flex",
      flexDirection: "column-reverse",

      "& .MuiDropzoneArea-icon": {
        marginTop: "10px",
        marginBottom: 0,
        width: "58px",
        marginRight: 0,
        alignSelf: "center",
        height: "auto"
      },
      "& .MuiDropzoneArea-text": {
        fontFamily: theme.fontTahoma.family,
        margin: 0,
        fontSize: "16px",
        padding: "0 50px 20px 50px"
      }
    }
  },
  cardUploadDisabled: {
    // temporário remover depois
    backgroundColor: `${theme.color.novoListItemBackgroundHover}`,
    border: "none",
    borderRadius: 10,
    borderWidth: 1,
    padding: "25px",
    marginBottom: "13px",
    boxShadow: themes.shadows[1],
    "& .MuiDropzoneArea-root": {
      opacity: 0.5,
      minHeight: "auto"
    },
    "& .MuiDropzoneArea-icon": {
      opacity: 0.5
    },
    "& .MuiDropzoneArea-textContainer": {
      display: "flex",
      flexDirection: "column-reverse",

      "& .MuiDropzoneArea-icon": {
        marginTop: "10px",
        marginBottom: 0,
        width: "58px",
        marginRight: 0,
        alignSelf: "center",
        height: "auto"
      },
      "& .MuiDropzoneArea-text": {
        fontFamily: theme.fontTahoma.family,
        margin: 0,
        fontSize: "16px",
        padding: "0 50px 20px 50px"
      }
    }
  },
  tituloCard: {
    fontWeight: 700,
    fontFamily: theme.font.family,
    fontSize: "20px",
    color: theme.color.tituloColorCardRepresetante
  },
  subTituloCard: {
    paddingTop: "15px",
    paddingBottom: "15px",
    fontFamily: theme.fontTahoma.family,
    fontSize: "16px"
  },
  htmlPasso: {
    paddingTop: "10px",
    paddingBottom: "20px",
    fontFamily: theme.fontTahoma.family,
    fontSize: "16px",
    "& b": {
      color: theme.color.primary,
      fontSize: "20px"
    },
    "& li": {
      fontFamily: theme.fontTahoma.family,
      fontSize: "16px"
    }
  }
}));
