import { makeStyles } from '@mui/styles';
import styled from "styled-components";
import { Base } from "../../../../../../componentes/cores";
import theme from 'themes';

export const useStyles = makeStyles({
    fundoCelula: {
      backgroundColor: "transparent",
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    fundoBotoes: {
      backgroundColor: theme.color.thirdBackgroud,
      padding: 10
    },
    fundoPendente: {
      backgroundColor: "#daa332"
    },
    fundoFalha: {
      backgroundColor: "#e14159"
    },
    fundoSucesso: {
      backgroundColor: "#04c1b0"
    },
    textoStatus: {
      paddingLeft: 15
    },
    textoData: {
      textAlign: "right"
    },
    botaoCentral: {
      borderLeft: `3px ${Base.BlackRussian} solid`,
      justifyContent: "center",
      justifyItems: "center",
      alignContent: "center",
      alignItems: "center",
      textAlign: "center"
    },
    botao: {
      width: "100%",
      height: "100%",
      justifyContent: "center",
      justifyItems: "center",
      alignContent: "center",
      alignItems: "center"
    },
    inativo: {
      opacity: 0.5
    },
    ativo: {
      opacity: 1
    }
  });
  
  export const Icone = styled.img`
    margin-left: 2px;
    max-width: 18px;
    object-fit: cover;
  `;
  