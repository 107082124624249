import React from "react";
import PropTypes from "prop-types";
import { generate } from "shortid";

import { Grid, Button } from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";

import { HtmlTooltip } from "paginas/Graficos/ConsumoAtual/style";
import { useStyles } from "./style";

const ColunaMultiplosBotoes = ({ onClick, parametros }) => {
  const classes = useStyles();

  const onClickDetalhar = () => onClick(parametros.unidadeId, parametros.mes, parametros.ano);

  return (
    <Grid container spacing={0} className={classes.fundoCelula}>

      <Grid item xs={6}>
        <Button
          key={generate()}
          onClick={onClickDetalhar}
          className={`text-white ${classes.botao}`}
        >
          <HtmlTooltip title="Editar">
            <SearchOutlined />
          </HtmlTooltip>
        </Button>
      </Grid>
    </Grid>
  );
};

ColunaMultiplosBotoes.propTypes = {
  onClick: PropTypes.oneOfType([PropTypes.func]).isRequired,
  parametros: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired
};

export default ColunaMultiplosBotoes;