import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  container: {
    margin: "0",
    width: "100%",
    "& .MuiInputBase-root": {
      height: "46px"
    },
    "& .MuiFormLabel-root": {
      lineHeight: "1"
    }
  },
  button: {
    fontSize: "18px"
  },
  trace: {
    width: "112px",
    height: "47px",
    borderBottom: "1px solid black",
    position: "absolute"
  },
  containerSalvar: {
    margin: "23px 0 32px 16px"
  },
  divider: {
    margin: "16px 18px 7.6px !important"
  }
});
