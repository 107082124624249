import React, { useCallback, useEffect, useMemo, useState } from "react";

// ApexChart
import ReactApexChart from "react-apexcharts";

// Redux
import { useSelector } from "react-redux";

// Componentes
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import moment from "moment";
import theme from "themes";
import { Base } from "../../../componentes/cores";
import InputData from "../../../componentes/inputTextoData";
import Loader from "../../../componentes/loader";

// Styles
import { Container } from "../Dashboard/style";
import { useStyles } from "./style";

// Serviços
import { consumoDiario } from "../../../servicos/graficosServico";

const ConsumoDiario = (props) => {
  const classes = useStyles();
  const usuario = useSelector((state) => state.usuario);
  const { unidadeSelecionada } = usuario;

  const [diaMesInicio, setDiaMesInicio] = useState();
  const [diaMesFim, setDiaMesFim] = useState();

  const [dadosDosDias, setDadosDosDias] = useState([]);
  const [labelsDias, setLabelsDias] = useState([]);
  const [media, setMedia] = useState([]);
  const [checarDados, setChecarDados] = useState();
  const [maiorValor, setMaiorValor] = useState();

  const definirMesInicial = () => {
    const now = window.moment();
    const day = now.date();
    // eslint-disable-next-line no-unused-vars
    let date = now;
    // eslint-disable-next-line no-unused-vars
    date =
      day === 1 || day === 2
        ? window.moment().subtract(1, "month").toDate()
        : date;

    setDiaMesInicio(window.moment().subtract(15, "days"));
    setDiaMesFim(window.moment());
  };

  const [pdf, setPDF] = React.useState(props);

  useEffect(() => {
    definirMesInicial();
  }, []);

  useEffect(() => {
    if (dadosDosDias.length) {
      const dadosOrdenados = dadosDosDias.filter((item) => item);
      const maxValor = dadosOrdenados.length > 0 && Math.max(...dadosOrdenados);
      if (dadosOrdenados) setMaiorValor(maxValor);
    }
  }, [dadosDosDias]);

  useEffect(() => {
    setPDF(props);
  }, [props]);

  const dados = useMemo(
    () => ({
      series: [
        {
          color: pdf.status ? "#f76c1d" : Base.YellowMustard,
          data: dadosDosDias,
          name: "Consumo",
          type: "area"
        },
        {
          color: pdf.status ? "black" : "#FF6400",
          data: media,
          name: "Média Diária",
          type: "line"
        }
      ],
      options: {
        chart: {
          fontFamily: theme.font.family,
          height: pdf.status ? 250 : 350,
          offsetX: 0,
          offsetY: 0,
          toolbar: {
            show: false
          },
          type: "line",
          width: "100%"
        },
        dataLabels: {
          background: {
            enabled: false
          },
          dropShadow: {
            enabled: true,
            blur: 0,
            opacity: 0.2
          },
          enabled: true,
          enabledOnSeries: [1],
          formatter(value, { dataPointIndex }) {
            if (dataPointIndex === 0)
              return `${value?.toFixed(1).toString().replace(".", ",")} KWh`;
            return "";
          },
          offsetY: -5,
          style: {
            color: theme.color.gradientInicial,
            fontSize: "16px"
          },
          textAnchor: "start"
        },
        legend: {
          show: false
        },
        stroke: {
          colors: pdf.status
            ? ["#F76C1D", "#F76C1D"]
            : [theme.color.gradientFinal, theme.color.gradientInicial],
          curve: "smooth",
          width: pdf.status ? 5 : 2
        },
        fill: {
          type: ["gradient", "solid"],
          gradient: {
            gradientToColors: [
              theme.color.gradientInicial,
              theme.color.gradientFinal
            ],
            type: "vertical",
            shadeIntensity: 0,
            inverseColors: false,
            opacityFrom: 0.8,
            opacityTo: 0.8,
            stops: [20, 100, 100, 100]
          }
        },
        labels: labelsDias,
        markers: {
          size: 0
        },
        grid: {
          borderColor: pdf.status ? "#000000" : theme.color.gridColorGrafico,
          xaxis: {
            lines: {
              show: true
            },
            labels: {
              show: false
            }
          },
          yaxis: {
            lines: {
              show: false
            },
            labels: {
              show: false
            }
          },
          padding: {
            right: 35
          }
        },
        xaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            show: false
          },
          labels: {
            style: {
              colors: pdf.status ? "black" : theme.color.labelColorGrafico,
              fontSize: pdf.status ? "18px" : "11px"
            }
          },
          position: "top",
          tooltip: {
            enabled: false
          }
        },
        yaxis: {
          labels: {
            show: false
          },
          min: 0,
          max: maiorValor * 1.025
        },
        tooltip: {
          // eslint-disable-next-line object-shorthand
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            const valorConvertido = series[seriesIndex][dataPointIndex];
            const valor =
              valorConvertido &&
              valorConvertido.toFixed(3).toString().replace(".", ",");
            return `
            <div class=${classes.arrowBox}> 
              <center class=${classes.arrowBoxTitle}>${w.globals.seriesNames[seriesIndex]}</center>
              <span><b>${valor}kWh</b></span>
            </div>
          `;
          }
        },
        noData: {
          text: "",
          style: {
            color: `${Base.OffWhite} !important`,
            fontSize: "18px",
            maxWidth: "80%"
          }
        }
      }
    }),
    [dadosDosDias, media, labelsDias, maiorValor, pdf]
  );

  const [carregandoDados, setCarregandoDados] = useState(false);

  const obterDados = useCallback(async () => {
    try {
      setCarregandoDados(true);
      if (diaMesInicio && diaMesFim && unidadeSelecionada) {
        const retorno = await consumoDiario(
          window.moment(diaMesInicio).format("YYYY-MM-DD"),
          window.moment(diaMesFim).format("YYYY-MM-DD"),
          unidadeSelecionada
        );

        if (retorno?.data) {
          setDadosDosDias(retorno?.data?.resultados ?? []);
          setLabelsDias(retorno?.data?.labels ?? []);

          if (retorno?.data?.labels) {
            const medias = [];
            retorno.data.labels.forEach(() => {
              medias.push(retorno?.data?.media);
            });
            setMedia(medias);
          }
        }
      }
      setCarregandoDados(false);
    } catch (error) {
      setCarregandoDados(false);
    }
  }, [diaMesInicio, diaMesFim, unidadeSelecionada]);

  useEffect(() => {
    obterDados();
  }, [obterDados]);

  const retornaSomaDiasMes = (valor) => {
    return window.moment(valor).add(1, "months").subtract(1, "days");
  };

  const onChangeDiaMesInicio = (valor) => {
    setDiaMesInicio(valor);
    if (retornaSomaDiasMes(valor) > window.moment().endOf("month"))
      setDiaMesFim(window.moment().endOf("month"));
    else setDiaMesFim(retornaSomaDiasMes(valor));
  };

  const onChangeDiaMesFim = (valor) => {
    if (window.moment(valor) > retornaSomaDiasMes(diaMesInicio))
      setDiaMesFim(retornaSomaDiasMes(diaMesInicio));
    else setDiaMesFim(valor);
  };

  useEffect(() => {
    const valorInicial = 0;
    const soma = dadosDosDias.reduce((acumulador, valorAtual) => {
      return acumulador + valorAtual;
    }, valorInicial);
    setChecarDados(soma);
  }, [dadosDosDias]);

  return (
    <Container
      height="100%"
      width="100%"
      style={{ backgroundColor: pdf.status ? "#fbfbfb" : "" }}
    >
      <Grid
        container
        justifyContent="space-between"
        className="m-0 w-100"
        spacing={2}
      >
        <Grid
          item
          className={classes.titulo}
          style={{ color: pdf.status === true ? "black" : "" }}
        >
          <div style={{ fontSize: pdf.status === true ? "22px" : "" }}>
            Consumo diário
          </div>
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item>
              {!pdf.status ? (
                <InputData
                  type="text"
                  id="diaMesInicio"
                  name="diaMesInicio"
                  minDate={window.moment().subtract(10, "year").toDate()}
                  maxDate={window.moment().endOf("month").toDate()}
                  customValue={diaMesInicio}
                  onChange={(valor) => onChangeDiaMesInicio(valor)}
                  renderIconShowHide={false}
                  className={classes}
                />
              ) : (
                <div style={{ color: "black" }}>
                  {moment(diaMesInicio).format("DD/MM/YYYY")}
                </div>
              )}
            </Grid>
            <Grid item>
              {!pdf.status ? (
                <InputData
                  type="text"
                  id="diaMesFim"
                  name="diaMesFim"
                  customValue={diaMesFim}
                  minDate={new Date(diaMesInicio)}
                  maxDate={window.moment().endOf("month").toDate()}
                  onChange={(valor) => onChangeDiaMesFim(valor)}
                  renderIconShowHide={false}
                  className={classes}
                />
              ) : (
                <div style={{ color: "black", marginLeft: -13 }}>
                  {`-${moment(diaMesFim).format("DD/MM/YYYY")}`}
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={0} className={classes.itemGrafico}>
        <Grid item xs={12}>
          <Loader loading={carregandoDados}>
            {dados?.options &&
            dados?.series &&
            dadosDosDias?.length &&
            checarDados > 0 ? (
              <ReactApexChart
                options={dados?.options}
                series={dados?.series}
                type="line"
                height={350}
                width="100%"
              />
            ) : (
              <div className={classes.noData}>
                <div>
                  Não foi possível carregar as informações de consumo, tente
                  novamente em algumas horas!
                </div>
              </div>
            )}
          </Loader>
        </Grid>
      </Grid>

      {pdf.status ? (
        <Grid container spacing={0} className={classes.itemGrafico}>
          <Grid item xs={3}>
            <TableContainer>
              <Table
                className={classes.table}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableColumnDia}>
                      Dia
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.tableColumnConsumo}
                    >
                      Cons. (MWh)
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dadosDosDias.map((row, index) =>
                    row !== 0 && index <= 7 && typeof row !== "object" ? (
                      <TableRow key={row}>
                        <TableCell
                          className={classes.tableColumnDia}
                          component="th"
                          scope="row"
                        >
                          {labelsDias[index]}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={classes.tableColumnConsumo}
                        >
                          {row.toLocaleString("pt-BR")}
                        </TableCell>
                      </TableRow>
                    ) : (
                      ""
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={3}>
            <TableContainer>
              <Table
                className={classes.table}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableColumnDia}>
                      Dia
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.tableColumnConsumo}
                    >
                      Cons. (MWh)
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dadosDosDias.map((row, index) =>
                    index > 7 &&
                    index <= 15 &&
                    row !== 0 &&
                    typeof row !== "object" ? (
                      <TableRow key={row}>
                        <TableCell
                          className={classes.tableColumnDia}
                          component="th"
                          scope="row"
                        >
                          {labelsDias[index]}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={classes.tableColumnConsumo}
                        >
                          {row.toLocaleString("pt-BR")}
                        </TableCell>
                      </TableRow>
                    ) : (
                      ""
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={3}>
            <TableContainer>
              <Table
                className={classes.table}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableColumnDia}>
                      Dia
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.tableColumnConsumo}
                    >
                      Cons. (MWh)
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dadosDosDias.map((row, index) =>
                    index >= 16 &&
                    index <= 23 &&
                    row !== 0 &&
                    typeof row !== "object" ? (
                      <TableRow key={row}>
                        <TableCell
                          className={classes.tableColumnDia}
                          component="th"
                          scope="row"
                        >
                          {labelsDias[index]}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={classes.tableColumnConsumo}
                        >
                          {row.toLocaleString("pt-BR")}
                        </TableCell>
                      </TableRow>
                    ) : (
                      ""
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={3}>
            <TableContainer>
              <Table
                className={classes.table}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableColumnDia}>
                      Dia
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.tableColumnConsumo}
                    >
                      Cons. (MWh)
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dadosDosDias.map((row, index) =>
                    index >= 24 &&
                    index <= 31 &&
                    row !== 0 &&
                    typeof row !== "object" ? (
                      <TableRow key={row}>
                        <TableCell
                          className={classes.tableColumnDia}
                          component="th"
                          scope="row"
                        >
                          {labelsDias[index]}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={classes.tableColumnConsumo}
                        >
                          {row.toLocaleString("pt-BR")}
                        </TableCell>
                      </TableRow>
                    ) : (
                      ""
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
    </Container>
  );
};

export default ConsumoDiario;
