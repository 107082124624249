import { makeStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import Tooltip from "@mui/material/Tooltip";
import { Base } from 'componentes/cores';
import styled from "styled-components";
import { createTheme } from "@mui/material/styles";

const theme = createTheme();

export const useStyles = makeStyles(() => ({
  container: {
    margin: "0 0 16px 0",
    width: '100%',
    "& .MuiGrid-root": {
      color: `${Base.BlackBastille} !important`
    },
    "& .MuiDropzoneArea-text": {
      color: `${Base.BlackBastille} !important`
    },
    "& .MuiInputBase-root": {
      height: "46px"
    },
    "& .MuiFormLabel-root": {
      lineHeight: "1"
    },
  },
  containerTitulo: {
    padding: '18px !important'
  },
  titulo: {
    fontSize: '18px',
    color: '#FFF !important'
  },
  containerData: {
    padding: '32px !important',
    fontWeight: "700"
  },
  icon: {
    cursor: "pointer",
    float: "right",
    margin: "5px",
    color: '#FFF !important',
   
  },
  button: {
    fontSize: '18px',
  },
  customButton: {
    fontSize: '18px',
    backgroundColor: "transparent !important"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
  },
  fileUpload: {
    "& .MuiDropzoneArea-root": {
      minHeight: "150px",
      borderWidth: "2px"
    },
    "& .MuiDropzoneArea-text": {
      fontSize: "16px"
    }
  },
  selectOfficer: {
    filter: "invert(1)",
    marginBottom: "0px",
  },
  customSelect: {
    "& .MuiFormControl-root": {
      borderRadius: "4px",
      color: "gray",
      border: "1px solid gray",
      marginRight: "30px"
    },

    "& .MuiOutlinedInput-inputAdornedEnd": {
      color: "gray",
    }
  },
  customTabs: {
    "& .MuiTab-textColorPrimary.Mui-selected": {
      color: "gray"
    }
  }
}));

export const HtmlTooltip = withStyles(() => ({
  tooltip: {
    fontSize: "14px",
    width: "100%",
    textAlign: "center",
    alignItems: "center",
    padding: "8px",
    borderImageSlice: 1,
    backgroundImage:
      "linear-gradient(to bottom, #647480, #647480), linear-gradient(to bottom, #374148, #647480 87%)",
    borderImageSource: "linear-gradient(to bottom, #374148, #647480 87%)",
    borderRadius: "5px"
  }
}))(Tooltip);


export const Tabela = styled.table`
  width: 100%;
`;

Tabela.Thead = styled.thead`
  background: #DCDCDC;
`;

Tabela.Tr = styled.tr``;

Tabela.Th = styled.th`
  padding: 4px;
  border: 4px solid white;
  text-align: center;
`;

Tabela.Tbody = styled.tbody`
  background: #F0F0F0;
`;

Tabela.Td = styled.td`
  padding: 4px;
  border: 4px solid white;
  text-align: center;
`;

Tabela.Tfoot = styled.tfoot``;