/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { AppBar, Tabs, Tab, Card, Grid } from "@mui/material";
import SwipeableViews from 'react-swipeable-views';
import TabPanel from 'componentes/tabPanel';
import { useStyles, Tabela, TabelaScroll } from './style';
import { obterValoresProret } from '../../../../servicos/percentuaisRegulatorios';
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import CloseIcon from "@mui/icons-material/Close";
import RotasDTO  from "../../../../rotas/rotasUrlDto";  
import { useHistory } from "react-router-dom";

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const ProretConsultar = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => setValue(newValue);
  const handleChangeIndex = (index) => setValue(index);

  const [valores, setValores] = useState([]);
  const [proret, setProret] = useState([0]);
  const [proretEncargos, setProretEncargos] = useState([0]);
  const [audiencia, setAudiencia] = useState([0]);
  const [audienciaEncargos, setAudienciaEncargos] = useState([0]);

  const onFechar = () => {
    history.push(`${RotasDTO.ProjecaoInsumoListar}`);
  }
   
  const handleObterValoresProret = async () => {
    try {
      const response = await obterValoresProret();

      if (response.status === 200 && response.data) {
        setValores([1]);
        const { data } = response;
        return data?.map((item) =>
          item.TipoPercentual === "PRORET" ? setProret(item) :
            item.TipoPercentual === "PRORET ENCARGOS SETORIAIS" ? setProretEncargos(item) :
              item.TipoPercentual === "AUDIÊNCIA PÚBLICA" ? setAudiencia(item) :
                item.TipoPercentual === "AUDIÊNCIA PÚBLICA ENCARGOS SETORIAIS" ? setAudienciaEncargos(item) : ""
        );
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  }

  useEffect(() => {
    if (!valores.length) handleObterValoresProret();
  }, []);

  return (
    <> 
    <Grid container className={classes.containerTitulo}>
          <Grid item xs={11} className={classes.titulo}>
            Percentuais Regulatórios de RI
          </Grid>
          <Grid item xs={1} >             
            <CloseIcon onClick={onFechar} className={classes.icon}/>
          </Grid>   
        </Grid>
    <Card>   
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{ style: { background: '#DCDCDC' } }}
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="PRORET" {...a11yProps(0)} />
          <Tab label="PRORET ENCARGOS SETORIAIS" {...a11yProps(1)} />
          <Tab label="AUDIÊNCIA PÚBLICA" {...a11yProps(2)} />
          <Tab label="AUDIÊNCIA PÚBLICA ENCARGOS SETORIAIS" {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <Grid container spacing={4} className={classes.container}>
            <Grid item sm={12}>
              {valores.length > 0 &&
                <TabelaScroll>
                  <div id="scroll">
                    <Tabela>
                      <Tabela.Thead>
                        <Tabela.Tr>
                          <Tabela.Th>Distribuidora</Tabela.Th>
                          <Tabela.Th>Residencial</Tabela.Th>
                          <Tabela.Th>Industrial</Tabela.Th>
                          <Tabela.Th>Comercial</Tabela.Th>
                          <Tabela.Th>Rural</Tabela.Th>
                          <Tabela.Th>Poder Público</Tabela.Th>
                          <Tabela.Th>Iluminação Pública</Tabela.Th>
                          <Tabela.Th>Serviço Público</Tabela.Th>
                        </Tabela.Tr>
                      </Tabela.Thead>
                      <Tabela.Tbody>
                        {proret?.Distribuidoras?.map((item) => (
                          <Tabela.Tr>
                            <Tabela.Td>{item.Distribuidora}</Tabela.Td>
                            {item.ValoresPorClasseDeConsumo.map((consumo) => (
                              consumo.ClasseDeConsumo === "Residencial" ? <Tabela.Td>{consumo.Valor.toFixed(10).replace(".", ",")}</Tabela.Td> : ""
                            ))}
                            {item.ValoresPorClasseDeConsumo.map((consumo) => (
                              consumo.ClasseDeConsumo === "Industrial" ? <Tabela.Td>{consumo.Valor.toFixed(10).replace(".", ",")}</Tabela.Td> : ""
                            ))}
                            {item.ValoresPorClasseDeConsumo.map((consumo) => (
                              consumo.ClasseDeConsumo === "Comercial" ? <Tabela.Td>{consumo.Valor.toFixed(10).replace(".", ",")}</Tabela.Td> : ""
                            ))}
                            {item.ValoresPorClasseDeConsumo.map((consumo) => (
                              consumo.ClasseDeConsumo === "Rural" ? <Tabela.Td>{consumo.Valor.toFixed(10).replace(".", ",")}</Tabela.Td> : ""
                            ))}
                            {item.ValoresPorClasseDeConsumo.map((consumo) => (
                              consumo.ClasseDeConsumo === "Poder Público" ? <Tabela.Td>{consumo.Valor.toFixed(10).replace(".", ",")}</Tabela.Td> : ""
                            ))}
                            {item.ValoresPorClasseDeConsumo.map((consumo) => (
                              consumo.ClasseDeConsumo === "Iluminação Pública" ? <Tabela.Td>{consumo.Valor.toFixed(10).replace(".", ",")}</Tabela.Td> : ""
                            ))}
                            {item.ValoresPorClasseDeConsumo.map((consumo) => (
                              consumo.ClasseDeConsumo === "Serviço Público" ? <Tabela.Td>{consumo.Valor.toFixed(10).replace(".", ",")}</Tabela.Td> : ""
                            ))}
                          </Tabela.Tr>
                        ))}
                      </Tabela.Tbody>
                    </Tabela>
                  </div>
                </TabelaScroll>
              }
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          {valores.length > 0 &&
            <TabelaScroll>
              <div id="scroll">
                <Tabela>
                  <Tabela.Thead>
                    <Tabela.Tr>
                      <Tabela.Th>Distribuidora</Tabela.Th>
                      <Tabela.Th>Residencial</Tabela.Th>
                      <Tabela.Th>Industrial</Tabela.Th>
                      <Tabela.Th>Comercial</Tabela.Th>
                      <Tabela.Th>Rural</Tabela.Th>
                      <Tabela.Th>Poder Público</Tabela.Th>
                      <Tabela.Th>Iluminação Pública</Tabela.Th>
                      <Tabela.Th>Serviço Público</Tabela.Th>
                    </Tabela.Tr>
                  </Tabela.Thead>
                  <Tabela.Tbody>
                    {proretEncargos?.Distribuidoras?.map((item) => (
                      <Tabela.Tr>
                       <Tabela.Td>{item.Distribuidora}</Tabela.Td>
                        {item.ValoresPorClasseDeConsumo.map((consumo) => (
                          consumo.ClasseDeConsumo === "Residencial" ? <Tabela.Td>{consumo.Valor.toFixed(10).replace(".", ",")}</Tabela.Td> : ""
                        ))}
                        {item.ValoresPorClasseDeConsumo.map((consumo) => (
                          consumo.ClasseDeConsumo === "Industrial" ? <Tabela.Td>{consumo.Valor.toFixed(10).replace(".", ",")}</Tabela.Td> : ""
                        ))}
                        {item.ValoresPorClasseDeConsumo.map((consumo) => (
                          consumo.ClasseDeConsumo === "Comercial" ? <Tabela.Td>{consumo.Valor.toFixed(10).replace(".", ",")}</Tabela.Td> : ""
                        ))}
                        {item.ValoresPorClasseDeConsumo.map((consumo) => (
                          consumo.ClasseDeConsumo === "Rural" ? <Tabela.Td>{consumo.Valor.toFixed(10).replace(".", ",")}</Tabela.Td> : ""
                        ))}
                        {item.ValoresPorClasseDeConsumo.map((consumo) => (
                          consumo.ClasseDeConsumo === "Poder Público" ? <Tabela.Td>{consumo.Valor.toFixed(10).replace(".", ",")}</Tabela.Td> : ""
                        ))}
                        {item.ValoresPorClasseDeConsumo.map((consumo) => (
                          consumo.ClasseDeConsumo === "Iluminação Pública" ? <Tabela.Td>{consumo.Valor.toFixed(10).replace(".", ",")}</Tabela.Td> : ""
                        ))}
                        {item.ValoresPorClasseDeConsumo.map((consumo) => (
                          consumo.ClasseDeConsumo === "Serviço Público" ? <Tabela.Td>{consumo.Valor.toFixed(10).replace(".", ",")}</Tabela.Td> : ""
                        ))}
                      </Tabela.Tr>
                    ))}
                  </Tabela.Tbody>
                </Tabela>
              </div>
            </TabelaScroll>
          }
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <Grid container spacing={4} className={classes.container}>
            <Grid item sm={12}>
              {valores.length > 0 &&
                <TabelaScroll>
                  <div id="scroll">
                    <Tabela>
                      <Tabela.Thead>
                        <Tabela.Tr>
                          <Tabela.Th>Distribuidora</Tabela.Th>
                          <Tabela.Th>Residencial</Tabela.Th>
                          <Tabela.Th>Industrial</Tabela.Th>
                          <Tabela.Th>Comercial</Tabela.Th>
                          <Tabela.Th>Rural</Tabela.Th>
                          <Tabela.Th>Poder Público</Tabela.Th>
                          <Tabela.Th>Iluminação Pública</Tabela.Th>
                          <Tabela.Th>Serviço Público</Tabela.Th>
                        </Tabela.Tr>
                      </Tabela.Thead>
                      <Tabela.Tbody>
                        {audiencia?.Distribuidoras?.map((item) => (
                            <Tabela.Tr>
                              <Tabela.Td>{item.Distribuidora}</Tabela.Td>
                              {item.ValoresPorClasseDeConsumo.map((consumo) => (
                                consumo.ClasseDeConsumo === "Residencial" ? <Tabela.Td>{consumo.Valor.toFixed(10).replace(".", ",")}</Tabela.Td> : ""
                              ))}
                              {item.ValoresPorClasseDeConsumo.map((consumo) => (
                                consumo.ClasseDeConsumo === "Industrial" ? <Tabela.Td>{consumo.Valor.toFixed(10).replace(".", ",")}</Tabela.Td> : ""
                              ))}
                              {item.ValoresPorClasseDeConsumo.map((consumo) => (
                                consumo.ClasseDeConsumo === "Comercial" ? <Tabela.Td>{consumo.Valor.toFixed(10).replace(".", ",")}</Tabela.Td> : ""
                              ))}
                              {item.ValoresPorClasseDeConsumo.map((consumo) => (
                                consumo.ClasseDeConsumo === "Rural" ? <Tabela.Td>{consumo.Valor.toFixed(10).replace(".", ",")}</Tabela.Td> : ""
                              ))}
                              {item.ValoresPorClasseDeConsumo.map((consumo) => (
                                consumo.ClasseDeConsumo === "Poder Público" ? <Tabela.Td>{consumo.Valor.toFixed(10).replace(".", ",")}</Tabela.Td> : ""
                              ))}
                              {item.ValoresPorClasseDeConsumo.map((consumo) => (
                                consumo.ClasseDeConsumo === "Iluminação Pública" ? <Tabela.Td>{consumo.Valor.toFixed(10).replace(".", ",")}</Tabela.Td> : ""
                              ))}
                              {item.ValoresPorClasseDeConsumo.map((consumo) => (
                                consumo.ClasseDeConsumo === "Serviço Público" ? <Tabela.Td>{consumo.Valor.toFixed(10).replace(".", ",")}</Tabela.Td> : ""
                              ))}
                            </Tabela.Tr>
                        ))}
                      </Tabela.Tbody>
                    </Tabela>
                  </div>
                </TabelaScroll>
              }
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          {valores.length > 0 &&
            <TabelaScroll>
              <div id="scroll">
                <Tabela>
                  <Tabela.Thead>
                    <Tabela.Tr>
                      <Tabela.Th>Distribuidora</Tabela.Th>
                      <Tabela.Th>Residencial</Tabela.Th>
                      <Tabela.Th>Industrial</Tabela.Th>
                      <Tabela.Th>Comercial</Tabela.Th>
                      <Tabela.Th>Rural</Tabela.Th>
                      <Tabela.Th>Poder Público</Tabela.Th>
                      <Tabela.Th>Iluminação Pública</Tabela.Th>
                      <Tabela.Th>Serviço Público</Tabela.Th>
                    </Tabela.Tr>
                  </Tabela.Thead>
                  <Tabela.Tbody>
                    {audienciaEncargos?.Distribuidoras?.map((item) => (
                      <Tabela.Tr>
                        <Tabela.Td>{item.Distribuidora}</Tabela.Td>
                        {item.ValoresPorClasseDeConsumo.map((consumo) => (
                          consumo.ClasseDeConsumo === "Residencial" ? <Tabela.Td>{consumo.Valor.toFixed(10).replace(".", ",")}</Tabela.Td> : ""
                        ))}
                        {item.ValoresPorClasseDeConsumo.map((consumo) => (
                          consumo.ClasseDeConsumo === "Industrial" ? <Tabela.Td>{consumo.Valor.toFixed(10).replace(".", ",")}</Tabela.Td> : ""
                        ))}
                        {item.ValoresPorClasseDeConsumo.map((consumo) => (
                          consumo.ClasseDeConsumo === "Comercial" ? <Tabela.Td>{consumo.Valor.toFixed(10).replace(".", ",")}</Tabela.Td> : ""
                        ))}
                        {item.ValoresPorClasseDeConsumo.map((consumo) => (
                          consumo.ClasseDeConsumo === "Rural" ? <Tabela.Td>{consumo.Valor.toFixed(10).replace(".", ",")}</Tabela.Td> : ""
                        ))}
                        {item.ValoresPorClasseDeConsumo.map((consumo) => (
                          consumo.ClasseDeConsumo === "Poder Público" ? <Tabela.Td>{consumo.Valor.toFixed(10).replace(".", ",")}</Tabela.Td> : ""
                        ))}
                        {item.ValoresPorClasseDeConsumo.map((consumo) => (
                          consumo.ClasseDeConsumo === "Iluminação Pública" ? <Tabela.Td>{consumo.Valor.toFixed(10).replace(".", ",")}</Tabela.Td> : ""
                        ))}
                        {item.ValoresPorClasseDeConsumo.map((consumo) => (
                          consumo.ClasseDeConsumo === "Serviço Público" ? <Tabela.Td>{consumo.Valor.toFixed(10).replace(".", ",")}</Tabela.Td> : ""
                        ))}
                      </Tabela.Tr>
                    ))}
                  </Tabela.Tbody>
                </Tabela>
              </div>
            </TabelaScroll>
          }
        </TabPanel>
      </SwipeableViews>     
    </Card>
    </>
  )
}

export default ProretConsultar;