import { createTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import styled from "styled-components";
import theme from "themes";

import calendario from "../Assets/calendario.png";
import chat from "../Assets/chat.png";
import dashboard from "../Assets/dashboard.png";
import delta from "../Assets/delta.png";
import linkedin from "../Assets/linkedin.png";
import listaCotacao from "../Assets/listaCotacao.png";
import novaCotacao from "../Assets/novaCotacao.png";

const th = createTheme();

export const useStyles = makeStyles(() => ({
  icon: {
    display: "block !important",
    fontSize: "48px !important",
    marginTop: 20
  },
  divDashboard: {
    marginBottom: "46px",
    [th.breakpoints.down("xl")]: {
      marginBottom: "37px"
    },
    [th.breakpoints.down("lg")]: {
      marginBottom: "30px"
    }
  },
  divChat: {
    marginBottom: "37px !important"
  }
}));

export const NovaCotacao = styled(Link)`
  background: url(${novaCotacao}) left top no-repeat;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  height: 435px;
  text-decoration: none;
  width: 644px;
  &:hover {
    text-decoration: none;
  }
  @media (max-width: 1366px) {
    height: 358.5px;
    width: 552.23px;
  }
  @media (max-width: 1024px) {
    height: 264.5px;
    width: 411.43px;
  }
`;

export const TextoNovaCotacao = styled.div`
  color: ${theme.color.primaryBackgroud};
  font-size: 32px;
  font-weight: bold;
  margin: 40px 0 0 40px;
`;

export const MinhasCotacoes = styled(Link)`
  background: url(${listaCotacao}) left top no-repeat;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  height: 390px;
  text-decoration: none;
  width: 644px;
  &:hover {
    text-decoration: none;
  }
  @media (max-width: 1366px) {
    height: 358.5px;
    width: 552.23px;
  }
  @media (max-width: 1024px) {
    height: 264.5px;
    width: 411.43px;
  }
`;

export const TextoMinhasCotacoes = styled.div`
  color: ${theme.color.primaryBackgroud};
  font-size: 32px;
  font-weight: bold;
  margin: 40px 0 0 40px;
`;

export const Chat = styled(Link)`
  background: url(${chat}) left top no-repeat;
  background-size: contain;
  cursor: pointer;
  display: block;
  height: 444px;
  text-decoration: none;
  width: 456px;
  &:hover {
    text-decoration: none;
  }
  @media (max-width: 1366px) {
    height: 381px;
    width: 391.02px;
  }
  @media (max-width: 1024px) {
    height: 272px;
    width: 278.57px;
  }
`;

export const Calendario = styled(Link)`
  background: url(${calendario}) left top no-repeat;
  background-size: contain;
  display: block;
  height: 124px;
  width: 456px;
  @media (max-width: 1366px) {
    height: 106px;
    width: 391.02px;
  }
  @media (max-width: 1024px) {
    height: 76px;
    width: 278.57px;
  }
`;

export const Dashboard = styled(Link)`
  background: url(${dashboard}) left top no-repeat;
  background-size: contain;
  display: block;
  height: 124px;
  width: 456px;
  @media (max-width: 1366px) {
    height: 106px;
    width: 391.02px;
  }
  @media (max-width: 1024px) {
    height: 76px;
    width: 278.57px;
  }
`;

export const LinkedIn = styled.a`
  background: url(${linkedin}) left top no-repeat;
  background-size: contain;
  display: block;
  height: 72px;
  width: 214px;
  @media (max-width: 1366px) {
    height: 62px;
    width: 182.64px;
  }
  @media (max-width: 1024px) {
    height: 44px;
    width: 130.42px;
  }
`;

export const Site = styled.a`
  background: url(${delta}) left top no-repeat;
  background-size: contain;
  display: block;
  height: 72px;
  width: 214px;
  @media (max-width: 1366px) {
    height: 62px;
    width: 182.64px;
  }
  @media (max-width: 1024px) {
    height: 44px;
    width: 130.42px;
  }
`;
