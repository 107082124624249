export default class FiltroAdicionalTabela {
  constructor(
    distribuidoraId,
    textoBusca,
    dataEnvioMigracao,
    diasPedidoMigracao,
    dataPrevistaMigracao,
    unidades,
    unidadesSelecionadas
  ) {
    this.distribuidoraId = distribuidoraId;
    this.textoBusca = textoBusca;
    this.dataEnvioMigracao = dataEnvioMigracao;
    this.diasPedidoMigracao = diasPedidoMigracao;
    this.dataPrevistaMigracao = dataPrevistaMigracao;
    this.unidades = unidades;
    this.unidadesSelecionadas = unidadesSelecionadas;
  }
}
