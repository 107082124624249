export default class LeadUnidadeConsumidoraDto {
    constructor(id, nomeUnidade, cnpjCpf, dataCadastro, origem, assinado, status, selecionado) {
      this.id = id;
      this.nomeUnidade = nomeUnidade;
      this.cnpjCpf = cnpjCpf;
      this.dataCadastro = dataCadastro;
      this.origem = origem;
      this.assinado = assinado;
      this.status = status;
      this.checked = selecionado;
    }
  }
  
