/* eslint-disable react/jsx-one-expression-per-line */
// Proptypes
import PropTypes from "prop-types";

// Componentes
import { Box, Button, Card, Grid } from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

// Hooks
import React, { useMemo } from "react";
import { useStyles } from "./style";

// DTOs
import PlanoConfiguracaoDto from "../../Dtos/planoConfiguracaoDto";
import PlanoSimuladoDto from "../../Dtos/planoSimuladoDto";

import IconeBronzeDelta from "../../assets/delta/bronze.png";
import IconeOuroDelta from "../../assets/delta/ouro.png";
import IconePrataDelta from "../../assets/delta/prata.png";

import IconeBronzeLuz from "../../assets/luz/bronze.png";
import IconeOuroLuz from "../../assets/luz/ouro.png";
import IconePrataLuz from "../../assets/luz/prata.png";

import IconeBronzeThymos from "../../assets/thymos/bronze.png";
import IconeOuroThymos from "../../assets/thymos/ouro.png";
import IconePrataThymos from "../../assets/thymos/prata.png";

const CardPlano = ({
  planoConfiguracao,
  dadosCards,
  onEscolherPlano,
  ehEdicao,
  cotacaoFechada,
  dominio,
  // eslint-disable-next-line react/prop-types
  print
}) => {
  // const { icone: Icone } = planoConfiguracao;

  const [open, setOpen] = React.useState(false);
  const [planoEscolhido, setPlano] = React.useState("");

  const classes = useStyles({
    planoCor: planoConfiguracao?.corCard,
    corTexto: planoConfiguracao?.corTexto,
    opacity: planoConfiguracao?.opacity,
    cotacaoFechada,
    fundoColor: planoConfiguracao?.fundoColor
  });

  // Funcao de aplicacao de mascara
  const aplicarMascara = (valor) => {
    try {
      return valor.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
        maximumFractionDigits: 0,
        minimumFractionDigits: 0
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(
        "Quebrou a aplicação de mascara monetaria toLocaleString",
        error
      );
      return `R$ ${valor.toFixed(0)}`;
    }
  };

  // UseMemo para formatar a mascara das labels
  // eslint-disable-next-line no-unused-vars
  const economiaMensal = useMemo(() => {
    if (!dadosCards) return "";

    return aplicarMascara(Math.round(dadosCards.economiaMensal));
  }, dadosCards);

  // eslint-disable-next-line no-unused-vars
  const economiaAnual = useMemo(() => {
    if (!dadosCards) return "";

    return aplicarMascara(Math.round(dadosCards.economiaAnual));
  }, dadosCards);

  const economiaTotal = useMemo(() => {
    if (!dadosCards) return "";

    return aplicarMascara(Math.round(dadosCards.economiaTotal));
  }, dadosCards);

  const reducaoCustos = useMemo(() => {
    if (!dadosCards) return "";

    const porcentagemString = dadosCards.reducaoCustos
      .toFixed(2)
      .toString()
      .replace(".", ",")
      .toLocaleString("pt-br");

    return `${porcentagemString}%`;
  }, dadosCards);

  const onClickEscolher = (e) => {
    setPlano(e);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const icones = {
    "delta/bronze": IconeBronzeDelta,
    "delta/prata": IconePrataDelta,
    "delta/ouro": IconeOuroDelta,

    "luz/bronze": IconeBronzeLuz,
    "luz/prata": IconePrataLuz,
    "luz/ouro": IconeOuroLuz,

    "thymos/bronze": IconeBronzeThymos,
    "thymos/prata": IconePrataThymos,
    "thymos/ouro": IconeOuroThymos
  };

  const iconeNome = `${dominio}/${planoConfiguracao?.icone}`;

  const Icone = () => {
    const height = print ? 82 : 52;
    const width = print ? 84 : 54;

    return (
      <img src={icones[iconeNome]} height={height} width={width} alt="icones" />
    );
  };

  return (
    <Grid container className={classes.itemPlano}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Plano selecionado</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            O Plano escolhido foi o {planoEscolhido}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className={classes.btnLabel} onClick={() => setOpen(false)}>
            {" "}
            Voltar{" "}
          </Button>
          <Button
            className={classes.btnLabel}
            onClick={() => onEscolherPlano(dadosCards)}
            autoFocus
          >
            {" "}
            Ok{" "}
          </Button>
        </DialogActions>
      </Dialog>

      <Grid item xs={12}>
        <Card>
          <Grid
            container
            onClick={() =>
              !ehEdicao &&
              !cotacaoFechada &&
              onClickEscolher(planoConfiguracao.nomePlano)
            }
          >
            <Grid item xs={12}>
              <Card>
                <Grid container>
                  <Box
                    component={Grid}
                    sx={{
                      background: planoConfiguracao?.corCard,
                      opacity: planoConfiguracao?.opacity
                    }}
                    item
                    xl={2}
                    lg={4}
                    md={4}
                    sm={3}
                    xs={2}
                    className={classes.containerColorido}
                  >
                    {iconeNome ? <Icone /> : null}
                  </Box>
                  <Grid item xl={10} lg={8} md={8} sm={9} xs={10}>
                    <Grid container className={classes.containerPaddingInterno}>
                      <Grid item xs={12}>
                        <span className={classes.porcentagemReducao}>
                          {reducaoCustos}
                        </span>
                      </Grid>
                      <Grid item xs={12}>
                        <span className={classes.planoNome}>
                          Redução de custos
                        </span>
                      </Grid>
                      <Grid item xs={12}>
                        <span className={classes.labelInformacoesEconomia}>
                          Economia total: {economiaTotal}
                        </span>
                      </Grid>
                      <Grid item xs={12}>
                        <span className={classes.labelInformacoes}>
                          Período {dadosCards.periodo} Anos
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

CardPlano.propTypes = {
  planoConfiguracao: PropTypes.oneOfType([
    PropTypes.instanceOf(new PlanoConfiguracaoDto())
  ]).isRequired,
  dadosCards: PropTypes.oneOfType([
    PropTypes.instanceOf(new PlanoSimuladoDto())
  ]).isRequired,
  onEscolherPlano: PropTypes.oneOfType([PropTypes.func]).isRequired,
  ehEdicao: PropTypes.bool.isRequired,
  cotacaoFechada: PropTypes.bool.isRequired,
  dominio: PropTypes.string.isRequired
};

export default CardPlano;
