import MICROSERVICO from "global/enumeradores/microServico";
import obterApi from "servicos/api";

const api = () => obterApi(MICROSERVICO.PROJECAOTARIFARIA);

export const GetLatestImportsByIdDistribuidora = async (IdDistribuidora) => {
	return api().get(`v1/ImportSPARTA/getLatestImportsByIdDistribuidora?IdDistribuidora=${IdDistribuidora}`);
}
  
export const possuiImportsDeUmAnoTarifario = async (IdDistribuidora, anoTarifario) => {
	const queryParams = `idDistribuidora=${IdDistribuidora}&anoTarifario=${anoTarifario}`
	return api().get(`v1/ImportSPARTA/possuiImportsDeUmAnoTarifario?${queryParams}`);
}

export const ImportSPARTA = async (idDistribuidora, validarDominio, anoTarifario, novaVigencia, idUsuario, nomeUsuario, keyFileS3) => {
	let url = "";
	const metodo = "post";
  
	if (novaVigencia != null) {
	  url = `v1/ImportSPARTA/import?idDistribuidora=${idDistribuidora}&validarDominio=${validarDominio}&anoTarifario=${anoTarifario}&novaVigencia=${novaVigencia}&idUsuario=${idUsuario}&nomeUsuario=${nomeUsuario}&keyFileS3=${keyFileS3}`;
	} else {
	  url = `v1/ImportSPARTA/import?idDistribuidora=${idDistribuidora}&validarDominio=${validarDominio}&anoTarifario=${anoTarifario}&idUsuario=${idUsuario}&nomeUsuario=${nomeUsuario}&keyFileS3=${keyFileS3}`;
	}
  
	return api()[metodo](url);
  };

export const listarDistribuidoras = async () => {
	return api().get("v1/ParametroDistribuidora/getalljoin?StatusProjecaoTarifaria=true");
};

export const getHistImpByChaveNatural = async (idProjecaoInsumo, idUsuario, momentoSolicitacao) => {
	return api().get(`v1/ProjecaoInsumo/getHistImpByChaveNatural?idProjecaoInsumo=${idProjecaoInsumo}&idUsuario=${idUsuario}&momentoSolicitacao=${momentoSolicitacao}`);
}

export const getProjecaoInsumo = async (codigoProjecaoInsumo) => {
	return api().get(`v1/ProjecaoInsumo/getProjecaoInsumo?Codigo=${codigoProjecaoInsumo}`);
}