export default class FiltroAdicionalTabela {
  constructor(
    ordenacao,
    filtro,
    tipoEmpresa,
    empresaSelecionada,
    produto,    
  ) {
    this.ordenacao = ordenacao;
    this.filtro = filtro;
    this.tipoEmpresa = tipoEmpresa;
    this.empresaSelecionada = empresaSelecionada;
    this.produto = produto;    
  }
}
