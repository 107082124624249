import React from "react";
import PropTypes from "prop-types";
import FotoFatura from "assets/camera/foto-fatura.png";
import IconeIlumidado from "assets/camera/iluminado.svg";
import IconeCelular from "assets/camera/icone-celular.svg";
import IconeQuadro from "assets/camera/icone-quadro.svg";
import { Tutorial, Img, BoxPasso, ModalContent, Passo, Icone } from "./styles";

const Passos = ({ fechar, onConfirmar }) => {
  const goCamera = () => {
    onConfirmar();
  };

  return (
    <ModalContent>
      <Tutorial>
        <Img src={FotoFatura} />
        <BoxPasso>
          <h1>
            Passo a passo para <br /> uma boa foto:
          </h1>
          <Passo>
            <Icone className="mr-2" src={IconeIlumidado} />
            <p>Verifique se o lugar está bem iluminado</p>
          </Passo>
          <Passo>
            <Icone className="mr-2" src={IconeCelular} />
            <p>
              Posicione o celular e a fatura na vertical, igual a foto acima
            </p>
          </Passo>
          <Passo className="ml">
            <Icone className="mr-1" src={IconeQuadro} />
            <p>Enquadre o arquivo entre as bordas destacadas</p>
          </Passo>
          <button
            type="button"
            className="transparent"
            onClick={() => fechar()}
          >
            Voltar
          </button>
          <button type="button" onClick={goCamera} className="mt-2">
            Vamos lá!
          </button>
        </BoxPasso>
      </Tutorial>
    </ModalContent>
  );
};

Passos.propTypes = {
  fechar: PropTypes.func,
  onConfirmar: PropTypes.func
};

Passos.defaultProps = {
  fechar: () => {},
  onConfirmar: () => {}
};

export default Passos;
