import TIPOPERFIL from "./enumeradores/tipoPerfil";

export const getTipoPerfil = (tipo) => {
  switch (tipo) {
    case TIPOPERFIL.CLIENTE:
    case TIPOPERFIL.PROSPECT:
      return false;
    case TIPOPERFIL.COLABORADOR:
      return true;
    default:
      return false;
  }
}