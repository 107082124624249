import MICROSERVICO from "global/enumeradores/microServico";
import obterApi from "servicos/api";

const api = () => obterApi(MICROSERVICO.PROJECAOTARIFARIA);

export const importTarifaReferencia = async (arquivo, idUsuario, nomeUsuario) => {
    const data = new FormData();
    data.append("file", arquivo.file);

    const url = `v1/ProjExcTarifaReferencia/importa?idUsuario=${idUsuario}&NomeUsuario=${nomeUsuario}`;

    return api().post(url, data)
}

export const dadosTarifaReferencia = async (ano) => {
    const url = `v1/ProjExcTarifaReferencia/visualizartarifasreferencias/${ano}`;

    return api().get(url)
}