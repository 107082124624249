export default class FiltroDto {
  constructor(
    tamanhoPaginacao,
    pagina,
    pesquisar,
    ordernar,
    data,
    unidadeSelecionada,
    clienteSelecionado,
    empresaSelecionada
  ) {
    this.tamanhoPaginacao = tamanhoPaginacao;
    this.pagina = pagina;
    this.buscar = pesquisar;
    this.ordenar = ordernar;
    this.data = data;
    this.unidadeId = unidadeSelecionada;
    this.clienteId = clienteSelecionado;
    this.empresaId = empresaSelecionada;
  }
}
