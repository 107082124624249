import React, { useState } from "react";

import { Grid } from "@mui/material";
import { Botao } from "componentes";
import InputDataWhite from "componentes/inputTextoDataBranco";
import { saveAs } from "file-saver";
import Helper from "./helper";

import { useStyles } from "./style";

const DownloadMetricas = () => {
  const classes = useStyles();
  const [dataInicialPesquisa, setDataInicialPesquisa] = useState(null);
  const [dataFinalPesquisa, setDataFinalPesquisa] = useState(null);

  const onClickDownloadsEVisualizacoes = async () => {
    const dataInicio = dataInicialPesquisa
      ? window.moment(dataInicialPesquisa).format("YYYY-MM-DD")
      : window.moment().subtract(6, "months").format("YYYY-MM-DD");
    const dataFim = dataFinalPesquisa
      ? window.moment(dataFinalPesquisa).format("YYYY-MM-DD")
      : window.moment().format("YYYY-MM-DD");
    const resultado = await Helper.ExportarPlanilha(dataInicio, dataFim);

    if (!resultado.sucesso) {
      Helper.exibirErro(resultado.mensagem);
    } else if (resultado.data.size === 0 || !resultado.data) {
      Helper.exibirAlerta(
        "Não foram encontrados registros para os parâmetros informados."
      );
    } else {
      const blob = new Blob([resultado.data]);

      saveAs(blob, "MétricasRelatório.xlsx");
      Helper.exibirSucesso(resultado.mensagem);
    }
  };

  return (
    <Grid container p={2} spacing={2}>
      <Grid item xs={12} lg={4}>
        <Botao
          type="submit"
          label="Downloads e Visualizações"
          onClick={onClickDownloadsEVisualizacoes}
          className={classes.button}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <InputDataWhite
          styleForm={false}
          type="text"
          id="DataInicial"
          label="Data Inicial"
          format="dd-MM-yyyy"
          customValue={dataInicialPesquisa}
          onChange={(data) => setDataInicialPesquisa(data)}
          minDate={window.moment().subtract(200, "years").toDate()}
          className={{ container: classes.inputData }}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <InputDataWhite
          styleForm={false}
          type="text"
          id="DataFinal"
          label="Data Final"
          format="dd-MM-yyyy"
          customValue={dataFinalPesquisa}
          onChange={(data) => setDataFinalPesquisa(data)}
          maxDate={window.moment().add(200, "years").toDate()}
          minDate={window.moment(dataInicialPesquisa).toDate()}
          className={{ container: classes.inputData }}
        />
      </Grid>
    </Grid>
  );
};

export default DownloadMetricas;
