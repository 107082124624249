import MICROSERVICO from "global/enumeradores/microServico";
import obterApi from "servicos/api";

const api = () => obterApi(MICROSERVICO.PROJECAOTARIFARIA);

export const uploadESSMinimo = async (arquivo, idUsuario, nomeUsuario) => {
    const data = new FormData();
    data.append("file", arquivo.file);
  
    const metodo = "post";
    const url = `v1/ImportGeracaoTermica/importar/ess/minimo?idUsuario=${idUsuario}&nomeUsuario=${nomeUsuario}`;
    return api()[metodo](url, data);
}

export const getESSMinimo = async () => {
    const metodo = "get";
    const url = "v1/ImportGeracaoTermica/visualizar/ess/minimo";
    return api()[metodo](url);
}
