export default class Coluna {
  constructor(
    field,
    headerName,
    sortable = false,
    maxWidth = null,
    minWidth = null
  ) {
    this.selector = field;
    this.name = headerName;
    this.sortable = sortable;
    this.maxWidth = maxWidth;
    this.minWidth = minWidth;
    this.conditionalCellStyles = [
      {
        when: (row) => typeof row.ativo !== "undefined" && !row.ativo,
        style: {
          "& div": {
            opacity: 0.3
          }
        }
      }
    ];
  }
}
