import moment from "moment";
import React, { useState, useCallback, useEffect } from "react";
import {
  Grid,
  Card,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Typography
} from "@mui/material";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { useStyles, Tabela } from "./style";
import { getCDEPagamentoMensal } from "../../../../servicos/cdeServico";
import { getAllDistribuidoras } from "../../../../servicos/projecaoTarifaria";
import InputDataBranco from "componentes/inputTextoDataBranco";
import CloseIcon from "@mui/icons-material/Close";
import RotasDTO from "../../../../rotas/rotasUrlDto";
import { useHistory } from "react-router-dom";
import { Base } from "componentes/cores";

const CDEPagamentoMensalVisualizar = () => {
  const classes = useStyles();
  const [distribuidoras, setDistribuidoras] = useState([]);
  const history = useHistory();

  const [distribuidora, setDistribuidora] = useState("");
  const [dataInicial, setDataInicial] = useState(
    moment().subtract(12, "month").toDate()
  );
  const [dataFinal, setDataFinal] = useState();

  const [valores, setValores] = useState([]);

  const obterDistribuidoras = useCallback(async () => {
    try {
      const response = await getAllDistribuidoras();
      if (response?.status === 200 && response?.data) {
        setDistribuidoras(response?.data);
      }
    } catch (error) {
      console.info(error);
    }
  });

  useEffect(() => {
    if (!distribuidoras.length) obterDistribuidoras();
  }, []);

  const handleChangeDistribuidora = async (event) => {
    setDistribuidora(event.target.value);
  };

  const obterValores = async () => {
    try {
      let paramdataini = "";
      if (dataInicial !== null && dataInicial !== undefined)
        paramdataini = moment(dataInicial).format("YYYY-MM-DD");

      let paramdatafim = "";
      if (dataFinal !== null && dataFinal !== undefined)
        paramdatafim = moment(dataFinal).format("YYYY-MM-DD");

      const response = await getCDEPagamentoMensal(
        distribuidora,
        paramdataini,
        paramdatafim
      );

      if (response.status === 200 && response.data) {
        setValores(response?.data);
      } else if (response.status === 202) {
        const message =
          "Não foram encontrados dados de Pagamento CDE Mensal para a distribuidora e datas informadas";
        setValores([]);
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: message
          })
        );
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem:
            error?.response?.data?.message ??
            `Erro não previsto ao obter dados de Pagamento CDE Mensal: ${error}`
        })
      );
    }
  };

  useEffect(() => {
    if (distribuidora) obterValores();
  }, [distribuidora, dataInicial, dataFinal]);

  const onFechar = (id) => {
    history.push(`${RotasDTO.ProjecaoInsumoListar}`);
  };

  return (
    <>
      <Grid container sm={12} className={classes.containerTitulo}>
        <Grid item xs={11} className={classes.titulo}>
          <Typography style={{ fontSize: "20pt" }}>
            Visualização de Pagamento CDE Mensal
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <CloseIcon onClick={onFechar} className={classes.icon} />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={7}
        className={classes.container}
        justifyContent="flex-end"
        alignItems="center"
        style={{ marginBottom: "-45px", marginLeft: "-10px" }}
      >
        <Grid item sm={3}></Grid>
        <Grid item sm={2}>
          <Typography style={{ color: Base.OffWhite }}>Data Inicial</Typography>
        </Grid>
        <Grid item sm={2}>
          <Typography style={{ color: Base.OffWhite }}>Data Final</Typography>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={7}
        className={classes.container}
        justifyContent="flex-end"
        alignItems="center"
      >
        <Grid item sm={3} className={classes.customSelect}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="perid">Distribuidora</InputLabel>
            <Select
              labelId="distr"
              id="distr"
              value={distribuidora ?? ""}
              onChange={handleChangeDistribuidora}
              label="Distribuidora"
              fullWidth
            >
              {distribuidoras.map((dist) => (
                <MenuItem value={dist.idDistribuidora}>{dist.titulo}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={2} className={classes.customSelect}>
          <InputDataBranco
            type="text"
            id="inicioSuprimento"
            name="inicioSuprimento"
            customValue={dataInicial}
            onChange={(data) => setDataInicial(data)}
            maxDate={moment("2090-12-31").toDate()}
            minDate={moment("1950-12-31").toDate()}
            format={"dd/MM/yyyy"}
            renderIconShowHide={false}
          />
        </Grid>
        <Grid item sm={2} className={classes.customSelect}>
          <InputDataBranco
            type="text"
            id="inicioSuprimento"
            name="inicioSuprimento"
            customValue={dataFinal}
            onChange={(data) => setDataFinal(data)}
            maxDate={moment("2090-12-31").toDate()}
            minDate={moment("1950-12-31").toDate()}
            format={"dd/MM/yyyy"}
            renderIconShowHide={false}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={4}
        p={2}
        className={classes.container}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item sm={8}>
          <Card className={classes.container}>
            <Grid
              container
              className={classes.container}
              justifyContent="center"
              alignItems="center"
            >
              {valores.length > 0 ? (
                <Grid
                  container
                  sm={10}
                  justifyContent="center"
                  alignItems="center"
                >
                  <div className={classes.customPosition}>
                    <Tabela>
                      <Tabela.Thead>
                        <Tabela.Tr>
                          <Tabela.Th colSpan="12">
                            Pagamento CDE Mensal
                          </Tabela.Th>
                        </Tabela.Tr>
                        <Tabela.Tr>
                          <Tabela.Th>MÊS</Tabela.Th>
                          <Tabela.Th>VALOR</Tabela.Th>
                        </Tabela.Tr>
                      </Tabela.Thead>
                      <Tabela.Tbody>
                        {valores?.map((item) => (
                          <Tabela.Tr>
                            <Tabela.Td>
                              {moment(item.mesPagamento).format("DD/MM/YYYY")}
                            </Tabela.Td>
                            <Tabela.Td>{`R$ ${item.valor.toLocaleString(
                              "pt-BR",
                              {
                                currency: "BRL",
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              }
                            )}`}</Tabela.Td>
                          </Tabela.Tr>
                        ))}
                      </Tabela.Tbody>
                    </Tabela>
                  </div>
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default CDEPagamentoMensalVisualizar;
