import PropTypes from "prop-types";
import React from "react";

// Componentes
import Componente from "../../componentes/conteudo/componente";

const RotaNaoAutenticada = ({ rota }) => {

  if (!rota.autenticada) return <Componente rota={rota} />;
  // return <Redirect to="/login" />;
  window.location.href = "/login" 

};

RotaNaoAutenticada.propTypes = {
  rota: PropTypes.oneOfType([PropTypes.any]).isRequired
};

export default RotaNaoAutenticada;
