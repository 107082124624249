import RetornoEndpointDto from "global/dto/retornoEndpointDto";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import {
  adicionarDocumentoCliente,
  downloadDocumentoCliente,
  obterDocumentosClientePorClienteId,
  obterValidarUrlDocumento,
  removerDocumentoCliente,
  validarUrlDocumento
} from "servicos/clienteDocumento";

export default class EnvioDocumentosHelper {
  static async salvarArquivo(body, setLoadingGeral) {
    try {
      setLoadingGeral(true);

      const response = await adicionarDocumentoCliente(body);

      return RetornoEndpointDto.Sucesso(
        "Arquivo salvo com sucesso!",
        response.data
      );
    } catch (ex) {
      const errors = ex?.response?.data?.errors?.join("\n");
      return RetornoEndpointDto.Erro(
        errors ?? "Não foi possivel salvar os arquivo do Workflow!"
      );
    } finally {
      setLoadingGeral(false);
    }
  }

  static async deleteArquivo(id) {
    try {
      const response = await removerDocumentoCliente(id);

      return RetornoEndpointDto.Sucesso(
        "Arquivo removido com sucesso!",
        response?.data
      );
    } catch (ex) {
      return RetornoEndpointDto.Erro(
        ex?.response?.data?.message ?? "Não foi possivel remover arquivo"
      );
    }
  }

  static async baixarArquivosDemanda(id) {
    try {
      const response = await downloadDocumentoCliente(id);

      return response?.data;
    } catch (ex) {
      return RetornoEndpointDto.Erro(
        ex?.response?.data?.message ?? "Não foi possivel baixar arquivo"
      );
    }
  }

  static async listarDocumentosCliente(clienteId, setLoadingGeral) {
    try {
      setLoadingGeral(true);
      const response = await obterDocumentosClientePorClienteId(clienteId);

      return RetornoEndpointDto.Sucesso(
        "Documentos obtidos com sucesso!",
        response?.data
      );
    } catch (ex) {
      return RetornoEndpointDto.Erro(
        ex?.response?.data?.message ?? "Não foi possivel listar documentos"
      );
    } finally {
      setLoadingGeral(false);
    }
  }

  static async validarUrlDocumento(body, setLoadingGeral) {
    try {
      setLoadingGeral(true);

      const response = await validarUrlDocumento(body);

      const responseDoc = await new Promise((resolve) => {
        const intervalId = setInterval(async () => {
          try {
            const res = await obterValidarUrlDocumento(response?.data?.guid);
            if (res.status !== 202) {
              clearInterval(intervalId);
              resolve(res);
            }
          } catch (error) {
            clearInterval(intervalId);
            resolve(error);
          }
        }, 5000);
      });

      if (responseDoc?.status === 200) {
        return RetornoEndpointDto.Sucesso(
          "Arquivo validado com sucesso!",
          responseDoc?.data
        );
      }
    } catch (ex) {
      const errors = ex?.response?.data?.errors?.join("\n");
      return RetornoEndpointDto.Erro(
        errors ?? "Não foi possivel validar os arquivo do Workflow!"
      );
    } finally {
      setLoadingGeral(false);
    }
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }

  static exibirSucesso(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem
      })
    );
  }
}
