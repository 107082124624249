import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { useStyles } from "./style";
import ValorWACCAtual from "../vigenciaAtual";
import ValorWACCHistorica from "../vigenciaHistorica";
import ValorWACCFutura from "../vigenciaFutura";

const ValoresWACC = () => {
  const classes = useStyles();

  const [vigencia, setVigencia] = useState("Atual");

  return <>
    <Grid container>
      <Grid item sm={12} className="mt-5">
        <Grid item sm={4}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="perid">Vigência</InputLabel>
            <Select
              variant="outlined"
              labelId="perid"
              id="perid1"
              value={vigencia ?? ""}
              onChange={(e) => { setVigencia(e.target.value) }}
              label="Operação"
              fullWidth>
              {["Histórica", "Atual", "Futura"].map((prdcdd) => (
                <MenuItem value={prdcdd}>{prdcdd}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {vigencia === "Histórica" ? <ValorWACCHistorica /> : (vigencia === "Atual" ? <ValorWACCAtual /> : (vigencia === "Futura" ? <ValorWACCFutura /> : <></>))}
      </Grid>
    </Grid>
  </>;
}

export default ValoresWACC;