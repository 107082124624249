import RetornoEndpointDto from "../../../global/dto/retornoEndpointDto";
import { ListarPaginado } from "../../../servicos/grupoAssociativoServico";

export default class GrupoAssociativoHelper {
  static async listarGrupos(filtro) {
    try {
      const resultado = await ListarPaginado(filtro);

      return RetornoEndpointDto.Sucesso(
        "Grupos obtidos com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu um erro ao obter a lista de grupos"
      );
    }
  }
}
