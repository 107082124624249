import { makeStyles } from "@mui/styles";
import { Base } from "../../../../componentes/cores";
import theme from "../../../../themes";

export const useStyles = makeStyles(() => ({
  root: {
    "& label.Mui-focused": {
      color: `${Base.GreyDim} !important`
    }
  },
  container: {
    "& .MuiButtonBase-root": {
      color: theme.color.secondary
    },
    "& .MuiFormControl-root": {
      margin: "0"
    },
    "& .MuiInputBase-root": {
      fontSize: "20px",
      fontWeight: 700,
      paddingLeft: 0
    },
    "& .MuiInputBase-root input": {
      color: theme.color.secondary,
      "-webkit-text-fill-color": theme.color.secondary,
      textTransform: "uppercase"
    },
    "& .MuiInputBase-root fieldset": {
      border: "none"
    }
  },
  button: {
    fontSize: "18px"
  }
}));
