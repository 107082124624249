import styled from "styled-components/macro";
import "./slick.css";

export const Wrapper = styled.div`
  .slick-dots {
    bottom: 4rem;
    li {
      width: 1.5rem;
      height: 1.5rem;

      &.slick-active {
        button {
          background-color: #fff;
        }
      }

      button {
        width: 1.5rem;
        height: 1.5rem;
        border: 0.2rem solid #fff;
        border-radius: 100%;

        &:hover,
        &:focus {
          &:before {
            opacity: 0;
          }
        }

        &:before {
          opacity: 0;
        }
      }
    }
  }

  .dots-position-left {
    .slick-dots {
      width: max-content;
      left: 20%;
    }
  }

  @media screen and (min-width: 1920px) {
    .dots-position-left {
      .slick-dots {
        width: max-content;
        left: 25%;
      }
    }
  }
`;