/* eslint-disable import/no-unresolved */
import React, { useState, useCallback, useEffect } from "react";
import { Grid, Card, InputLabel, Select, MenuItem, FormControl } from "@mui/material";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { useStyles, Tabela } from "./style";
import { getDistribuidoras, getCotasAngra } from "../../../servicos/cotaAngra";
import CloseIcon from "@mui/icons-material/Close";
import RotasDTO  from "../../../rotas/rotasUrlDto";  
import { useHistory } from "react-router-dom";

const CotaAngraVisualizar = () => {
  const classes = useStyles();
  const history = useHistory();

  const onFechar = (id) => {
     history.push(`${RotasDTO.ProjecaoInsumoListar}`);
   }
  const [distribuidoras, setDistribuidoras] = useState([]);
  const [distribuidora, setDistribuidora] = useState("");

  const [valores, setValores] = useState([]);

  const obterDistribuidoras = useCallback(async () => {
    try {
      const response = await getDistribuidoras();
      if (response?.status === 200 && response?.data) {
        setDistribuidoras(response?.data);
      }
    } catch (error) {
      console.info(error);
    }
  });

  useEffect(() => {
    if (!distribuidoras.length) obterDistribuidoras();
  }, []);

  const handleChangeDistribuidora = async (event) => {
    setDistribuidora(event.target.value);
  };

  const obterValores = useCallback(async (idDistribuidora) => {
    try {
      const response = await getCotasAngra(idDistribuidora);
      if (response.status === 200 && response.data) {
        setValores(response?.data);
      } else {
        setValores([]);
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "Não há dados para impressão com os parâmetros informados."
          })
        );
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  }, []);

  useEffect(() => { if (distribuidora) obterValores(distribuidora) }, [distribuidora, obterValores]);

  return <>
   <Grid container className={classes.containerTitulo}>
        <Grid item xs={11} className={classes.titulo}>
          Cotas Angra
        </Grid>
        <Grid item xs={1} >             
          <CloseIcon onClick={onFechar} className={classes.icon}/>
        </Grid>   
      </Grid>
    <Grid container spacing={4} p={2} className={classes.container} justifyContent="flex-end" alignItems="center">
      <Grid item sm={3} className={classes.customSelect}>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="perid">Distribuidora</InputLabel>
          <Select
            labelId="distr"
            id="distr"
            value={distribuidora ?? ""}
            onChange={handleChangeDistribuidora}
            label="Distribuidora"
            fullWidth>
            {distribuidoras.map((dist) => (
              <MenuItem key={dist.idDistribuidora.toString()} value={dist.idDistribuidora}>{dist.titulo}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
    <Grid container spacing={4} p={2} className={classes.container}>
      <Grid item sm={12}>
        <Card className={classes.container}>
          <Grid container spacing={4} p={2} className={classes.container}>
            {valores.length > 0 ?
              <Grid item sm={12}>
                <div className={classes.customPosition}>
                  <Tabela>
                    <Tabela.Thead>
                      <Tabela.Tr>
                        <Tabela.Th colSpan="5">COTAS ANGRA</Tabela.Th>
                      </Tabela.Tr>
                      <Tabela.Tr>
                        <Tabela.Th>ANO</Tabela.Th>
                        <Tabela.Th>COTA</Tabela.Th>
                        <Tabela.Th>MONTANTE</Tabela.Th>
                      </Tabela.Tr>
                    </Tabela.Thead>
                    <Tabela.Tbody>
                      {valores?.map((item) => (
                        <Tabela.Tr>
                          <Tabela.Td>{item.ano}</Tabela.Td>
                          <Tabela.Td>{`${(item.cotaParte * 100).toFixed(2)}%`}</Tabela.Td>
                          <Tabela.Td>{`${item.montante} MWh`}</Tabela.Td>
                        </Tabela.Tr>
                      ))}
                    </Tabela.Tbody>
                  </Tabela>
                </div>
              </Grid>
              : ""}
          </Grid>
        </Card>
      </Grid>
    </Grid>
  </>;
}

export default CotaAngraVisualizar;
