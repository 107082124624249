import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import shortid from "shortid";

// Icones
import TuneIcon from "@mui/icons-material/Tune";

// Form Hooks
import { useForm, useWatch } from "react-hook-form";

// Redux
import { useSelector } from "react-redux";

// Componentes
import { Grid } from "@mui/material";
import MaterialInputBusca from "componentes/inputBusca";
import TabelaSemDados from "componentes/tabelaPaginada/tabelaSemDados";
import { listarFiltrarClientePor } from "servicos/clientesServico";
import theme from "themes";
import { Botao, SelectArredondado } from "../../../../componentes";
import BotaoCadastro from "../../../../componentes/botaoCadastro";
import BotaoFiltroOrdenacao from "../../../../componentes/botaoFiltroOrdenar";
import { Base } from "../../../../componentes/cores";
import ItemListaExpansivel from "../../../../componentes/itemListaExpansivel";
import Loader from "../../../../componentes/loader";
import ModalConfirmacao from "../../../../componentes/modalConfirmacao";
import Paginacao from "../../../../componentes/paginacao";

// Serviços
import {
  deletarUnidade,
  listarFiltrarPor,
  listarOrganizarPor,
  listarUnidadesConsumidoras
} from "../../../../servicos/unidadesServico";

// Rotas
import { RotasDTO } from "../../../../global/rotas/rotasUrlDto";

// Redux
import { store } from "../../../../global/redux";
import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";
import {
  desabilitarUnidade,
  selecionarCliente,
  selecionarUnidade
} from "../../../../global/redux/modulos/usuario/actions";

// Styles
import { useStyles } from "./style";

// Útils
import { formataCnpj } from "../../../../servicos/utils";
import { cpfCnpjValido } from "../../../../global/utils/formatCpfCnpj";

const UnidadesListar = () => {
  const classes = useStyles();
  const history = useHistory();

  const usuario = useSelector((state) => state.usuario);
  const { empresaSelecionada } = usuario;
  const { clienteSelecionado } = usuario;

  useEffect(() => {
    setCarregamentoInicial(true);
    store.dispatch(desabilitarUnidade(true));

    return () => store.dispatch(desabilitarUnidade(false));
  }, [desabilitarUnidade]);

  const [paginaAtual, setPaginaAtual] = useState();
  const [totalPaginas, setTotalPaginas] = useState();
  const [carregandoUnidades, setCarregandoUnidades] = useState(false);
  const [listaUnidades, setListaUnidades] = useState([]);
  const [carregamentoInicial, setCarregamentoInicial] = useState(false);
  const [campoPesquisa, setCampoPesquisa] = useState("");
  const montouComponente = useRef(false);

  const { register, control, setValue } = useForm({
    reValidateMode: "onSubmit"
  });

  const textoBusca = useWatch({
    control,
    name: "textoBusca",
    defaultValue: history?.location?.state?.dadosUnidade?.id?.toString() || ""
  });

  const textoPesquisa = useMemo(() => {
    return textoBusca?.trim() || "";
  }, [textoBusca]);

  const handleListarUnidades = async (
    ordernacao,
    filtro,
    atual,
    tipoCliente,
    campoFiltro = "menu"
  ) => {
    try {
      setCarregandoUnidades(true);
      const buscaPorId = history?.location?.state?.dadosUnidade?.id?.toString()
        ? "BuscaPorId"
        : "";

      const clienteId = campoFiltro === "search" ? "" : clienteSelecionado;

      const lista = await listarUnidadesConsumidoras(
        ordernacao,
        filtro,
        atual,
        cpfCnpjValido(textoPesquisa),
        clienteId,
        tipoCliente,
        empresaSelecionada,
        campoPesquisa || buscaPorId || ""
      );
      if (lista?.status === 200) {
        if (lista?.data?.unidadesConsumidoras) {
          setListaUnidades(lista?.data?.unidadesConsumidoras);
          setPaginaAtual(lista?.data?.paginaAtual);
          setTotalPaginas(lista?.data?.totalPaginas);
        } else {
          setListaUnidades([]);
          setPaginaAtual();
          setTotalPaginas();
        }
      }
      if (lista?.status === 204) {
        setListaUnidades([]);
        setPaginaAtual();
        setTotalPaginas();
      }
      setCarregandoUnidades(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoUnidades(false);
    } finally {
      history.push({
        pathname: RotasDTO.UnidadesConsumidoras,
        state: { dadosUnidade: null }
      });
    }
  };

  const onClickNovaUnidade = () => {
    history.push(`${RotasDTO.UnidadesConsumidoras}/cadastro`);
  };

  const onClickEditar = (id) => {
    history.push(`${RotasDTO.UnidadesConsumidoras}/cadastro/${id}`);
  };

  const [modalExclusao, setModalExclusao] = useState(false);

  const deletar = async (item) => {
    try {
      setModalExclusao(false);
      setCarregandoUnidades(true);
      const deletou = await deletarUnidade(
        item?.id,
        !(modalExclusao?.situacao?.toUpperCase() === "AT")
      );
      if (deletou) {
        handleListarUnidades("", "", 1, "");
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: `A unidade foi ${
              !(modalExclusao?.situacao?.toUpperCase() === "AT")
                ? "ativada"
                : "inativada"
            } com sucesso!`
          })
        );
      }
      setCarregandoUnidades(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoUnidades(false);
    }
  };

  const onClickExcluir = (item) => {
    setModalExclusao(item);
  };

  const onConfirmarExclusao = (item) => {
    try {
      deletar(item);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  const [ordemSelecionada, setOrdemSelecionada] = useState("");
  const [filtroSelecionado, setFiltroSelecionado] = useState("");
  const [
    filtroTipoClienteSelecionado,
    setFiltroTipoClienteSelecionado
  ] = useState("");

  const handleClickOrdemSelecionada = (event) => {
    const val = ordemSelecionada === event ? "" : event;
    setOrdemSelecionada(val);
  };

  const handleClickFiltroSelecionado = (event) => {
    const val = filtroSelecionado === event ? "" : event;
    setFiltroSelecionado(val);
  };

  const handleClickFiltroTipoClienteSelecionado = (event) => {
    const val = filtroTipoClienteSelecionado === event ? "" : event;
    setFiltroTipoClienteSelecionado(val);
  };

  const handleClickAtivarAgora = async () => {
    const filtro = [];
    if (filtroSelecionado) filtro.push(filtroSelecionado);
    handleListarUnidades(
      ordemSelecionada,
      filtro,
      paginaAtual,
      filtroTipoClienteSelecionado
    );
  };

  const proximaPagina = "Go to next page";
  const paginaAnterior = "Go to previous page";

  const onChangePaginacao = (item) => {
    if (item.innerText !== "") {
      const pagina = parseInt(item.innerText, 10);
      handleListarUnidades(
        ordemSelecionada,
        filtroSelecionado,
        pagina,
        filtroTipoClienteSelecionado
      );
    }

    if (item.innerText === "") {
      if (item.ariaLabel === proximaPagina) {
        handleListarUnidades(
          ordemSelecionada,
          filtroSelecionado,
          paginaAtual + 1,
          filtroTipoClienteSelecionado
        );
      }

      if (item.ariaLabel === paginaAnterior) {
        handleListarUnidades(
          ordemSelecionada,
          filtroSelecionado,
          paginaAtual - 1,
          filtroTipoClienteSelecionado
        );
      }
    }
  };

  useEffect(() => {
    setCarregamentoInicial(true);
    handleListarUnidades(
      ordemSelecionada,
      filtroSelecionado,
      1,
      filtroTipoClienteSelecionado
    );
  }, []);

  useEffect(() => {
    if (montouComponente.current) {
      setCarregamentoInicial(true);
      if (carregamentoInicial && empresaSelecionada) {
        setValue("textoBusca", null);
        setCampoPesquisa("");
        handleListarUnidades(
          ordemSelecionada,
          filtroSelecionado,
          1,
          filtroTipoClienteSelecionado
        );
      }
    } else montouComponente.current = true;
  }, [empresaSelecionada, clienteSelecionado]);

  const onClickSearch = () => {
    store.dispatch(selecionarCliente(""));
    store.dispatch(selecionarUnidade(""));

    handleListarUnidades(
      ordemSelecionada,
      filtroSelecionado,
      1,
      filtroTipoClienteSelecionado,
      "search"
    );
  };

  useEffect(() => {
    if (campoPesquisa === "Todos" && carregamentoInicial) {
      const debounce = setTimeout(() => {
        setValue("textoBusca", null);
        handleListarUnidades(
          ordemSelecionada,
          filtroSelecionado,
          1,
          filtroTipoClienteSelecionado
        );
      }, 1000);
      return () => clearTimeout(debounce);
    }
  }, [campoPesquisa]);

  return (
    <Loader loading={carregandoUnidades} className="w-auto">
      <ModalConfirmacao
        item={modalExclusao}
        onConfirmar={(item) => onConfirmarExclusao(item)}
        mensagem={`Tem certeza que deseja ${
          modalExclusao?.situacao?.toUpperCase() === "AT"
            ? "inativar"
            : "ativar"
        } essa Unidade?`}
        onCancelar={() => setModalExclusao(false)}
      />
      <Grid
        container
        p={2}
        spacing={3}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <SelectArredondado
            select
            id="CampoPesquisa"
            name="CampoPesquisa"
            label="Filtrar por campo"
            placeholder="Filtrar por campo"
            valueKey="valor"
            valueName="nome"
            className={classes.select}
            dataSource={[
              { nome: "Todos", valor: "Todos" },
              { nome: "Nome Unidade", valor: "NomeUnidade" },
              { nome: "CPF/CNPJ", valor: "CnpjCpf" },
              { nome: "Número de Instalação", valor: "NumeroInstalacao" }
            ]}
            value={campoPesquisa}
            onChange={(e) => {
              setCampoPesquisa(e.target.value);
            }}
            permiteValorBranco
            styleForm={false}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <MaterialInputBusca
            type="text"
            id="textoBusca"
            name="textoBusca"
            label="Buscar"
            renderIconShowHide
            searchAdornment
            defaultValue={textoBusca ?? ""}
            ref={register}
            permiteValorBranco
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={2}>
          <Botao
            type="submit"
            onClick={onClickSearch}
            label="Buscar"
            className={classes.button}
            disabled={!textoPesquisa}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={3}
          display="flex"
          justifyContent={{ sm: "flex-end" }}
        >
          <BotaoFiltroOrdenacao
            type="button"
            color={theme.color.secondaryBorderColor}
            background="transparent"
            label="Filtrar / Ordernar"
            icon={<TuneIcon />}
            className={classes.buttonFiltro}
            ordenacao={listarOrganizarPor()}
            ordenadorUm="Ordenador"
            ordenadorDois="Filtrar"
            ordenadorTres="Tipo Cliente"
            ordemSelecionada={ordemSelecionada}
            filtros={listarFiltrarPor()}
            filtrosSelecionados={filtroSelecionado}
            onClickOrdenacao={handleClickOrdemSelecionada}
            onClickFiltro={handleClickFiltroSelecionado}
            onClickAtivarAgora={handleClickAtivarAgora}
            filtroTipoCliente={listarFiltrarClientePor()}
            filtrosTipoClienteSelecionado={filtroTipoClienteSelecionado}
            onClickFitroTipoCliente={handleClickFiltroTipoClienteSelecionado}
          />
        </Grid>
        <Grid item xs={12}>
          <BotaoCadastro
            label="Nova unidade consumidora"
            color={theme.color.buttonColor}
            onClick={onClickNovaUnidade}
          />
        </Grid>
        {listaUnidades && listaUnidades.length > 0 ? (
          <>
            {listaUnidades.map((unidade) => {
              return (
                <ItemListaExpansivel
                  key={shortid.generate()}
                  label={unidade?.nomeUnidade ?? unidade?.nomeRazaoSocial}
                  color={Base.White}
                  edicao={!!unidade?.id}
                  exclusao={!!unidade?.id}
                  onClickEditar={() => onClickEditar(unidade?.id)}
                  onClickExcluir={() => onClickExcluir(unidade ?? false)}
                  ativo={unidade?.situacao?.toUpperCase() === "AT"}
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                >
                  <div>
                    <strong>CNPJ/CPF: </strong>
                    {formataCnpj(unidade?.cnpjCpf) ?? ""}
                  </div>
                  <div>
                    <strong>Distribuidora: </strong>
                    {unidade.nomeDistribuidora}
                  </div>
                </ItemListaExpansivel>
              );
            })}
            <Grid
              container
              spacing={3}
              direction="row"
              justifyContent="center"
              alignItems="center"
              mt={2}
            >
              <Grid item>
                <Paginacao
                  paginaAtual={paginaAtual}
                  totalPaginas={totalPaginas}
                  onChangePagina={onChangePaginacao}
                />
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid
            container
            p={2}
            spacing={3}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <TabelaSemDados className={classes.container} />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Loader>
  );
};

export default UnidadesListar;
