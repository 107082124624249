import { makeStyles } from "@mui/styles";
import themes from "themes";
import { Base } from "componentes/cores";

export const useStyles = makeStyles(() => ({
  container: {
    color: `${themes.color.secondaryText} !important`
  },
  button: {
    cursor: "default",
    alignItems: "center",
    backgroundColor: "transparent",
    borderRadius: themes.borda.input,
    borderColor: Base.GreyDim,
    borderWidth: "5px",
    borderStyle: "dashed",
    color: `${themes.color.secondaryText} !important`,
    height: "54px",
    paddingLeft: "0px",
    width: "100%",
    textAlign: "center",
    minHeight: "117px",
    marginBottom: "20px",
    "& span": {
      color: "#646464",
      fontSize: "18px"
    }
  },
  icone: {
    color: `${themes.color.labelBotaoInicial} !important`,
    marginRight: "20px",
    width: "51px !important",
    height: "51px !important"
  }
}));
