import React from "react";
import PropTypes from "prop-types";

// Componentes
import { Grid, Card, Box, Typography } from "@mui/material";
import Unidade from "assets/home/unidade.png";
import Loader from "componentes/loader";
import formatarValor from "global/utils/formatarValor";
import SemDados from "../SemDados";

// Styles
import { useStyles, Chip, StatusUnidade, ImagemUnidade } from "./style";

const Medidor = ({
  unidadeConsumidora,
  carregandoMedidor,
  medidores,
  status
}) => {
  const classes = useStyles();

  return (
    <Loader loading={carregandoMedidor}>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: { xs: "space-between" },
            alignItems: { xs: "center" },
            background: "#FBFBFD"
          }}
          p={2}
        >
          <h3 className={classes.titulo}>Medidor</h3>

          {medidores?.length > 0 && (
            <Chip cor={status ? "#2abf6f" : "#BF2A2A"}>
              {status ? "Ativo" : "Inativo"}
            </Chip>
          )}
        </Grid>
        {medidores?.length > 0 ? (
          <Grid item xs={12} p={2}>
            <Box pb={1}>
              <h3 className={classes.subtitulo}>Unidades consumidoras</h3>
              <Typography>{unidadeConsumidora?.endereco || ""}</Typography>
            </Box>
            <Card className={classes.card}>
              <Grid container p={1}>
                <Grid item xs={5} sm={5} lg={5} xl={4}>
                  <strong className={classes.texto}>Nome do medidor</strong>
                  <p className={classes.textoCinza}>
                    {medidores[0]?.codigoMedidor || ""}
                  </p>
                  <strong className={classes.texto}>Modelo</strong>
                  <p className={classes.textoCinza}>Medidor Inteligente LUZ</p>
                  <strong className={classes.texto}>Tarifa de energia</strong>
                  <p className={classes.textoCinza}>
                    {formatarValor(unidadeConsumidora?.somaTarifaCheiaBandeira)}
                    /kWh
                  </p>
                </Grid>
                <Grid
                  item
                  xs={7}
                  sm={7}
                  lg={7}
                  xl={6}
                  sx={{ position: "relative", textAlign: "center" }}
                >
                  <ImagemUnidade src={Unidade} alt="imagem da unidade" />
                  <StatusUnidade cor={status ? "#2abf6f" : "#BF2A2A"}>
                    {status ? "Online" : "Off-line"}
                  </StatusUnidade>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        ) : (
          <SemDados texto="Você está sem acesso ao medidor no momento! Entre em contato com nosso atendimento para verificar a disponibilidade." />
        )}
      </Grid>
    </Loader>
  );
};

Medidor.propTypes = {
  unidadeConsumidora: PropTypes.shape({
    unidadeConsumidoraId: PropTypes.number,
    diaFaturamentoDistribuidora: PropTypes.number,
    somaTarifaCheiaBandeira: PropTypes.number,
    numeroInstalacao: PropTypes.string,
    endereco: PropTypes.string
  }).isRequired,
  carregandoMedidor: PropTypes.bool,
  medidores: PropTypes.arrayOf(
    PropTypes.shape({ codigoMedidor: PropTypes.string })
  ).isRequired,
  status: PropTypes.bool
};

Medidor.defaultProps = {
  carregandoMedidor: false,
  status: false
};

export default Medidor;
