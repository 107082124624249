import styled from "styled-components";
import theme from "../../themes";

export const BotaoVoltar = styled.button`
  background-color: transparent;
  color: ${theme.color.primary};
  border: none;
  font-size: 18px;
  width: fit-content;
  margin-bottom: 1px;
  display: flex;
  align-items: center;
  svg {
    color: ${theme.color.secondary};
  }
`;
