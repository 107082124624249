import { makeStyles } from "@mui/styles";
import { Base } from "componentes/cores";
import theme from "themes";

export const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: `${Base.WhiteLuz} !important`,
    width: "1020px",
    height: "746px",
    position: "relative",
    padding: (props) =>
      props.bordaConteudo ? "16px 48px !important" : "16px 0",
    "& h1": {
      color: theme.color.primary,
      fontSize: "43px",
      fontFamily: `${theme.font.family}  !important`,
      fontWeight: 700,
      margin: 0,
      "& span": {
        color: theme.color.secondary
      }
    },
    "& h2": {
      color: theme.color.primary,
      fontSize: "40px",
      fontFamily: `${theme.font.family}  !important`,
      fontWeight: 700,
      margin: 0
    },
    "& h4": {
      color: theme.color.primary,
      fontSize: "24px",
      fontFamily: `${theme.font.family}  !important`,
      fontWeight: 700,
      margin: 0,
      "& span": {
        color: theme.color.secondary
      }
    }
  },
  box: {
    display: "flex",
    width: "100%",
    height: "100%"
  },
  iconeLista: {
    position: "absolute",
    left: 0,
    top: 30,
    width: "14px"
  },
  iconeSol: {
    position: "absolute",
    right: 0,
    top: 0,
    width: "80px"
  },
  iconeLuz: {
    position: "absolute",
    right: 20,
    bottom: 20,
    width: "60px"
  },
  conteudo: {
    height: "84%",
    border: (props) =>
      props.bordaConteudo
        ? `1px solid  ${Base.OrangeBlaze} !important`
        : "none",
    borderRadius: "34px !important",
    padding: (props) =>
      props.bordaConteudo ? "16px !important" : "0 !important",
    marginTop: "24px"
  },
  tituloConteudo: {
    color: theme.color.primary,
    fontSize: "24px",
    fontWeight: 700
  }
}));
