import React from "react";
import "date-fns";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import prbrLocale from "date-fns/locale/pt-BR";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ptBR } from "@mui/x-date-pickers/locales";

// style
import { ThemeProvider } from "@mui/material/styles";
import { materialTheme, useStyles } from "./style";

const InputData = React.forwardRef((props, ref) => {
  const {
    label,
    id,
    name,
    format,
    defaultValue,
    errors,
    onChange,
    customValue,
    className,
    minDate,
    maxDate,
    views,
    disabled,
    onBlur,
    openTo,
    allowClear,
    onAccept
  } = props;

  const classes = useStyles();

  return (
    <Grid container className={`${className.container} ${classes.container}`}>
      <LocalizationProvider
        adapterLocale={prbrLocale}
        dateAdapter={AdapterDateFns}
        localeText={
          ptBR.components.MuiLocalizationProvider.defaultProps.localeText
        }
      >
        <ThemeProvider theme={materialTheme}>
          <DatePicker
            name={name}
            minDate={minDate}
            maxDate={maxDate}
            id={String(id)}
            format={format}
            label={label}
            defaultValue={defaultValue}
            value={
              // eslint-disable-next-line valid-typeof
              typeof customValue === "Date" || !customValue
                ? customValue
                : new Date(customValue)
            }
            inputRef={ref}
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled}
            views={views}
            openTo={openTo}
            onAccept={onAccept}
            slotProps={{
              actionBar: {
                actions: ["cancel", "accept", allowClear && "clear"]
              },
              textField: {
                name,
                error: Boolean(errors[name]?.message),
                helperText: errors[name]?.message ?? ""
              }
            }}
            className={classes.datepicker}
          />
        </ThemeProvider>
      </LocalizationProvider>
    </Grid>
  );
});

InputData.propTypes = {
  label: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  format: PropTypes.string,
  defaultValue: PropTypes.string,
  errors: PropTypes.oneOfType([PropTypes.object]),
  className: PropTypes.oneOfType([PropTypes.object]),
  onChange: PropTypes.oneOfType([PropTypes.func]),
  customValue: PropTypes.oneOfType([PropTypes.any]),
  minDate: PropTypes.oneOfType([PropTypes.any]),
  maxDate: PropTypes.oneOfType([PropTypes.any]),
  views: PropTypes.oneOfType([PropTypes.any]),
  openTo: PropTypes.string,
  disabled: PropTypes.bool,
  onBlur: PropTypes.oneOfType([PropTypes.func]),
  allowClear: PropTypes.bool,
  onAccept: PropTypes.func
};

InputData.defaultProps = {
  label: "",
  id: "",
  format: "dd/MM/yyyy",
  defaultValue: "",
  errors: {},
  className: {},
  onChange: () => {},
  customValue: "",
  minDate: new Date(new Date().setFullYear(new Date().getFullYear() - 2)),
  maxDate: new Date("2090-12-31"),
  views: ["year", "month", "day"],
  openTo: "day",
  disabled: false,
  onBlur: () => {},
  onAccept: () => {},
  allowClear: true
};

export default InputData;
