import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const api = () => obterApi(MICROSERVICO.MEASUREMENT);

// Bsucar comparacaoProjecao boleta
export const BuscarComparacaoProjecaoBoleta = async (
  tipoProjecao,
  unidadeConsumidoraId,
  mes,
  ano
) => {
  return api().get(
    `v1/comparacaoprojecaoboleta/${tipoProjecao}/${unidadeConsumidoraId}/${mes}/${ano}`
  );
};

export const BuscarComparacaoProjecaoBoletaOuConsumoProjecao = async (
  tipoProjecao,
  unidadeConsumidoraId,
  mes,
  ano
) => {
  return api().get(
    `v1/comparacaoprojecaoboleta/ComparacaoOuProjecao/${tipoProjecao}/${unidadeConsumidoraId}/${mes}/${ano}`
  );
};
