/* eslint-disable react/prop-types */
import React, { useState } from "react";

import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

import { Menu, MenuItem } from "@mui/material";
import { store } from "../../../../global/redux";

import { useStyles, Wrapper, BadgeNotificacoes } from "./style";
import ListaNotificacoes from "./listaNotificacoes";

import { zerarQuantidadeNotificacoes } from "../../../../global/redux/modulos/notificacoes/actions";

const Notificacoes = React.forwardRef((props, ref) => {
  const classes = useStyles();

  const [notificacaoAberto, setNotificacaoAberto] = useState(null);

  const onClickAbrirNotificacoes = (event) => {
    if (props.notificacoes.quantidadeNotificacoes > 0) {
      store.dispatch(zerarQuantidadeNotificacoes());
      props.atualizaNotificacoesVisualizadas();
    }

    setNotificacaoAberto(event.currentTarget);
  };

  const onClickFecharNotificacoes = () => {
    setNotificacaoAberto(null);
  };

  return (
    <Wrapper className={classes.wrapper} {...props}>
      <BadgeNotificacoes
        badgeContent={props.notificacoes.quantidadeNotificacoes}
        className={classes.notificacaoSino}
        overlap="circular"
        color="default"
        onClick={onClickAbrirNotificacoes}
        invisible={props.notificacoes.quantidadeNotificacoes === 0}
      >
        <NotificationsNoneIcon
          fontSize="large"
          className={classes.icon}
          ref={ref}
        />
      </BadgeNotificacoes>
      {notificacaoAberto && <PlayArrowIcon className={classes.anchorMenu} />}
      <Menu
        id="notificacoesMenu"
        anchorEl={notificacaoAberto}
        keepMounted
        elevation={0}
        open={Boolean(notificacaoAberto)}
        onClose={onClickFecharNotificacoes}
        anchorOrigin={{ vertical: 65, horizontal: 85 }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        MenuListProps={{ className: classes.menu }}
      >
        <MenuItem className={classes.notificacaoMenu}>
          Alertas recentes
        </MenuItem>
        <ListaNotificacoes lista={props.notificacoes.listaNotificacoes} />
      </Menu>
    </Wrapper>
  );
});

export default Notificacoes;
