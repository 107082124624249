import React from "react";
import withStyles from "@mui/styles/withStyles";

import { createTheme } from "@mui/material/styles";

import ClientTheme from "themes";

import { Checkbox } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

const theme = createTheme();

const IOSStyledCheckBox = withStyles(() => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: 0
  },
  checkBoxBase: {
    "&:checked": {
      color: "black"
    }
  },
  iconeSelecionado: {
    fontWeight: "bold",
    color: "black",
    backgroundColor: `${ClientTheme.color.checkButonColor}`
  },
  iconeSemSelecao: {
    color: ClientTheme.color.bordaCheckbox,
    backgroundColor: ClientTheme.color.fundoCheckbox,
    borderRadius: "2px"
  },
  thumb: {
    width: 24,
    height: 24
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"])
  },
  checked: {},
  focusVisible: {}
}))(({ classes, ...props }) => {
  return (
    <Checkbox
      color="default"
      checkedIcon={<CheckIcon className={classes.iconeSelecionado} />}
      icon={<CheckBoxOutlineBlankIcon className={classes.iconeSemSelecao} />}
      inputRef={props.reference}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
});

export default IOSStyledCheckBox;
