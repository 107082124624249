import React, { useState } from 'react';
import { Card, Grid } from "@mui/material";
import { useForm } from 'react-hook-form';
import { useStyles } from './style';
import UploadDropArea from 'componentes/upload';
import Botao from 'componentes/botao';
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { uploadESSANEEL } from "../../../servicos/essaneel";
import ResultadoOperacao from 'componentes/resultadoOperacao';
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import RotasDTO  from "../../../rotas/rotasUrlDto";  
import { useHistory } from "react-router-dom";

const ESSANEEL = () => {
  const classes = useStyles();
  const usuarioGlobal = useSelector((state) => state.usuario);
  const msgPadraoUpload = "Solte o arquivo para anexá-lo ou procurar";
  const history = useHistory();

  const onFechar = (id) => {
    history.push(`${RotasDTO.ProjecaoInsumoListar}`);
  }
  

  const { handleSubmit } = useForm({
    reValidateMode: 'onSubmit',
  });
  const tituloCabecalhoRetorno = "ESS ANEEL Divulgado - Importação do arquivo";
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(msgPadraoUpload);
  const [carregandoArquivo, setCarregandoArquivo] = useState(false);
  const [progressMesage, setProgressMessage] = useState("");
  const [resultadoOperacao, setResultadoOperacao] = useState(false);
  const [resultadoOperacaoCabecalho, setResultadoOperacaoCabecalho] = useState("");
  const [resultadoOperacaoObj, setResultadoOperacaoObj] = useState({
    "mensagens": []
  });

  const processarArquivo = (arquivo) => {
    if (arquivo != null && arquivo[0] != null) {
      setFile(arquivo[0]);
      setFileName(arquivo[0].file.name);
    }
  };

  const onAddUpload = (arquivo) => {
    processarArquivo(arquivo)
  };

  const onCancelar = () => {
    setFile(false);
    setFileName(msgPadraoUpload);
    setCarregandoArquivo(false);
    setResultadoOperacao(false);
    setResultadoOperacaoCabecalho("");
    setResultadoOperacaoObj({
      "mensagens": []
    });
  }

  const EnviarForm = async () => {
    try {
        setCarregandoArquivo(true);
        setResultadoOperacao(false);
        setProgressMessage("Importando o arquivo... Por favor, aguarde.");
        const upload = await uploadESSANEEL(file, usuarioGlobal?.usuario?.id, usuarioGlobal?.usuario?.usuarioNome);
        if ((upload.status === 200 ||upload.status === 202) && upload?.data) {
          setFile(false);
          setFileName(msgPadraoUpload);
          setCarregandoArquivo(false);
          setResultadoOperacao(true); 
          let dadosRetorno = upload?.data;          
          let mensagemRetorno = ["Processo de Importação finalizado com sucesso"];

          if (dadosRetorno.length !==null && dadosRetorno.length !== undefined && dadosRetorno.length > 0){
            
            for (var i in dadosRetorno) {
              if  (dadosRetorno[i].Mensagem ){
                mensagemRetorno.push(dadosRetorno[i].Mensagem); 
              }      
            }
                    
          }     
          setResultadoOperacaoCabecalho(`${tituloCabecalhoRetorno} ${file?.file?.name}`)
          setResultadoOperacaoObj({"mensagens": mensagemRetorno });                       
        }    
    } catch (erro) {
      setFile(false);
      setFileName(msgPadraoUpload);
      setCarregandoArquivo(false);
      setResultadoOperacao(true);
      setResultadoOperacaoCabecalho(`${tituloCabecalhoRetorno} ${file?.file?.name}`)
      setResultadoOperacaoObj({"mensagens": [erro.message?? "Ocorreu um erro desconhecido"]});
      console.info(erro);
    }
  }

  const aoEnviarForm = () => {

    if (!file) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Por favor, selecione o arquivo desejado."
        })
      )
    } else {
      EnviarForm();
    }
  }

  return (
    <form className="needs-validation" onSubmit={handleSubmit(aoEnviarForm)}>
      <ResultadoOperacao
        item={resultadoOperacaoObj}
        cabecalhoResultado={resultadoOperacaoCabecalho}
        onClose={() => onCancelar()}
      />
      {resultadoOperacao === false ?
        <Card className={classes.container}>
          <Grid container className={classes.containerTitulo}>
            <Grid item xs={11} className="font-weight-bold">
               Importação do arquivo ESS ANEEL Divulgado
            </Grid>
            <Grid item xs={1} >             
              <CloseIcon onClick={onFechar} className={classes.icon}/>
            </Grid>   
          </Grid>
          {carregandoArquivo === false ?
            <Card className={classes.container}>
              <Grid container spacing={4} p={2} className={classes.container}>
                <Grid item sm={12} className={classes.fileUpload}>
                  <UploadDropArea
                    onAdd={onAddUpload}
                    dropAreaText={fileName}
                    showLoader={false}
                    acceptedFiles={[
                      '.xlsm,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
                    ]}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4} p={2} className={classes.container} justifyContent="space-between">
                <Grid item sm={3}>
                  <Botao
                    type="button"
                    label="Cancelar"
                    onClick={onCancelar}
                    className={classes.customButton}
                  />
                </Grid>
                <Grid item sm={3}>
                  <Botao
                    type="submit"
                    label="Importar"
                    className={classes.button}
                  />
                </Grid>
              </Grid>
            </Card>
            :
            <Card className={classes.container}>
              <Grid container spacing={4} p={2} className={classes.container}>
                <Grid item sm={12} className={classes.fileUpload}>
                  <Grid item sm={12} style={{ textAlign: "center" }}>
                    {progressMesage}
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          }
        </Card>
      : ""
      }
    </form>
  );
}

export default ESSANEEL;