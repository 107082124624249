import React, { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Card, Grid, Link } from "@mui/material";
import { KeyboardBackspace } from "@mui/icons-material";
import { useStyles } from "./style";

import CardsPlanos from "../../../componentes/CardsPlanos";
import {
  formataMoedaReais,
  formataPercentual
} from "../../../../../servicos/utils";

const MensagemMulta = ({ setPassoAtual, dadosCotacao }) => {
  const classes = useStyles();
  const [exibirPlanos, setExibirPlanos] = useState(false);
  const [dadosPlanosGerados, setDadosPlanosGerados] = useState([]);

  const preventDefault = (event) => {
    event.preventDefault();
    setPassoAtual("cotacaoInicialACL");
  };

  const consultaSerasa = useMemo(() => {
    switch (dadosCotacao.consulta?.nomeStatusSerasa?.toLowerCase()) {
      case "reprovado":
      case "pendente":
      case null:
        return true;
      default:
        return false;
    }
  }, [dadosCotacao]);

  const normalizaEconomiaPeriodo = (meses, periodo) => {
    const periodoMeses = periodo?.output?.find(
      (item) => item?.quantidadeMeses === meses
    )?.outputCalculeira;

    return {
      inicio: periodoMeses?.economia?.[0].mesTimeline?.mes,
      fim: periodoMeses?.economia?.at(-1)?.mesTimeline?.mes
    };
  };

  const normalizaPlanos = (dados, dadosCalculeira) => {
    const arrayPLanos = [];

    arrayPLanos.push(
      {
        nomePlano: "DESCONTOS 3 ANOS",
        anos: 3,
        periodoMeses: 36,
        economiaAnual: dados?.economia3Anos_reais,
        economiaMensal: dados?.economiaMensal3Anos_reais,
        economiaTotal: dados?.economia3Anos_reais ?? "",
        flexibilidade: 0,
        sustentabilidade: 0,
        percentualDesconto: dados?.economia3Anos_percent * 100 ?? "",
        cotacaoSelecionada: false,
        tipoPlanoCotacaoId: 12,
        cotacaoId: 0,
        matrizDescontoId: 0,
        ativo: true,
        periodoEconomia: normalizaEconomiaPeriodo(36, dadosCalculeira)
      },
      {
        nomePlano: "DESCONTOS 4 ANOS",
        anos: 4,
        periodoMeses: 48,
        economiaAnual: dados?.economia4Anos_reais,
        economiaMensal: dados?.economiaMensal4Anos_reais,
        economiaTotal: dados?.economia4Anos_reais ?? "",
        flexibilidade: 0,
        sustentabilidade: 0,
        percentualDesconto: dados?.economia4Anos_percent * 100 ?? "",
        cotacaoSelecionada: false,
        tipoPlanoCotacaoId: 15,
        cotacaoId: 0,
        matrizDescontoId: 0,
        ativo: true,
        periodoEconomia: normalizaEconomiaPeriodo(48, dadosCalculeira)
      },
      {
        nomePlano: "DESCONTOS 5 ANOS",
        anos: 5,
        periodoMeses: 60,
        economiaAnual: dados?.economia5Anos_reais,
        economiaMensal: dados?.economiaMensal5Anos_reais,
        economiaTotal: dados?.economia5Anos_reais ?? "",
        flexibilidade: 0,
        sustentabilidade: 0,
        percentualDesconto: dados?.economia5Anos_percent * 100 ?? "",
        cotacaoSelecionada: false,
        tipoPlanoCotacaoId: 13,
        cotacaoId: 0,
        matrizDescontoId: 0,
        ativo: true,
        periodoEconomia: normalizaEconomiaPeriodo(60, dadosCalculeira)
      }
    );

    return arrayPLanos || [];
  };

  useEffect(() => {
    const dados = normalizaPlanos(
      dadosCotacao?.economiaConsolidada,
      dadosCotacao
    );

    setDadosPlanosGerados(dados);
  }, [dadosCotacao]);

  return (
    <Card className={classes.cardCadastro}>
      <Box p={4}>
        <Box
          component="div"
          fontSize="45px"
          fontWeight="700"
          mb={3}
          className={classes.text}
        >
          Agradecemos seu interesse na LUZ!
        </Box>
        <Box
          component="div"
          color="#4F4F4F"
          fontSize="18px"
          fontWeight="700"
          mb={3}
        >
          Infelizmente neste momento conseguiremos lhe entregar nossos
          benefícios. Em breve nosso time comercial entrará em contato para
          prosseguir com a simulação.
        </Box>

        {!dadosCotacao?.consulta?.cnae && consultaSerasa && (
          <>
            <Box
              mb={3}
              display="flex"
              alignContent="center"
              justifyContent="space-between"
            >
              <Box
                component="div"
                color="#4F4F4F"
                fontSize="18px"
                fontWeight="700"
              >
                Proposta sujeita à análise de crédito
                <Box component="span" className={classes.text} ml={1}>
                  {`score: ${dadosCotacao?.consulta?.score || 0}`}
                </Box>
              </Box>
              <Box
                color="#4F4F4F"
                fontSize="18px"
                fontWeight="700"
                component="div"
                textAlign="end"
                sx={{ cursor: "pointer" }}
                onClick={() => setExibirPlanos(!exibirPlanos)}
              >
                <Box component="span" borderBottom="3px solid #4F4F4F">
                  {exibirPlanos ? "Esconder Planos" : "Exibir Planos"}
                </Box>
              </Box>
            </Box>
            <Grid container spacing={2}>
              {exibirPlanos && (
                <>
                  {dadosPlanosGerados &&
                    dadosPlanosGerados.map((item) => (
                      <Grid item xs={12} lg={4} xl={4}>
                        <CardsPlanos
                          onChecked={() => {}}
                          titulo={item.nomePlano}
                          porcentagem={formataPercentual(
                            item?.percentualDesconto
                          )}
                          anual={formataMoedaReais(item.economiaAnual)}
                          mensal={item?.economialAnual / item?.anos}
                          rodape={formataMoedaReais(item.economiaTotal)}
                          nomeCard={item.nomePlano}
                          checked={false}
                          economiaMensal={formataMoedaReais(
                            item?.economiaMensal
                          )}
                          economiaAnual={formataMoedaReais(
                            item?.economiaAnual / item?.anos
                          )}
                          funcionalidade="serasa"
                          periodoEconomia={`Inicio: ${window
                            .moment(item?.periodoEconomia?.inicio)
                            .format("DD/MM/YYYY")} - Fim: ${window
                            .moment(item?.periodoEconomia?.fim)
                            .format("DD/MM/YYYY")}`}
                        />
                      </Grid>
                    ))}
                </>
              )}
            </Grid>
          </>
        )}

        {dadosCotacao?.consulta?.cnae && (
          <Box
            component="span"
            className={classes.text}
            fontSize="18px"
            fontWeight="700"
          >
            Cnae não atendido
          </Box>
        )}

        <Box display="flex" alignContent="center" mt={4} mb={12}>
          <Box mr={1}>
            <KeyboardBackspace cursor="pointer" htmlColor="#FA3EB1" />
          </Box>
          <Box
            style={{ color: "#FA3EB1", cursor: "pointer" }}
            component={Link}
            fontSize="16px"
            fontWeight="700"
            underline="always"
            onClick={preventDefault}
          >
            Voltar para o inicio
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

MensagemMulta.propTypes = {
  setPassoAtual: PropTypes.oneOfType([PropTypes.func]),
  dadosCotacao: PropTypes.oneOfType([PropTypes.object])
};
MensagemMulta.defaultProps = {
  setPassoAtual: () => {},
  dadosCotacao: {}
};

export default MensagemMulta;
