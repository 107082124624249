/* eslint-disable no-return-assign */
/* eslint-disable radix */
import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { Grid, Card, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useStyles } from './style';
import { Base } from 'componentes/cores';
import { listarSerieHistorica } from '../../../servicos/seriesHistoricas';
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import Loader from 'componentes/loader';
import MaterialInputTexto from 'componentes/inputTexto/materialInput';
import MaterialInputMascara from 'componentes/inputTextoMascara';
import Botao from 'componentes/botao';
import RotasDTO from '../../../rotas/rotasUrlDto';
import { getDistribuidora, getAnosAtivos, add, edit, excluir } from '../../../servicos/mercadoFaturado';

const ConfiguracaoDistribuidoras = () => {
  const { id } = useParams();
  const history = useHistory();
  const classes = useStyles();

  const { register, handleSubmit } = useForm({
    reValidateMode: 'onSubmit',
  });

  const [isEdit, setIsEdit] = useState(false);
  const [isChange, setIsChange] = useState(false);
  const [seriesHistoricas, setSeriesHistoricas] = useState([]);
  const [nomeDistribuidora, setNomeDistribuidora] = useState("");
  const [periodos, setPeriodos] = useState([]);
  const [anosAtivos, setAnosAtivos] = useState([]);
  const [pesoMigracao, setPesoMigracao] = useState(0);
  const [pesoHistorico, setPesoHistorico] = useState(0);
  const [pesoOutros, setPesoOutros] = useState(0);
  const [total, setTotal] = useState("0");
  const [series, setSeries] = useState([]);

  const addSerie = (newValue) => setSeries((state) => [...state, newValue]);
  const changeSelect = (event) => {
    setSeries(
      series.map((item) => {
        if (parseInt(item.id_serie) === parseInt(event?.currentTarget?.dataset?.id)) {
          return { ...item, codigo: event.target.value, apelido: event.currentTarget.dataset.apelido, fonte: event.currentTarget.dataset.fonte, periodicidade: event.currentTarget.dataset.periodicidade, categoria: event.currentTarget.dataset.categoria };
        }
        return item;
      })
    );
    setIsChange(true);
  };
  const changeInput = (event) => {
    setSeries(
      series.map((item) => {
        if (parseInt(item?.id) === parseInt(event?.target?.id)) {
          return { ...item, peso: event.target.value.replace("%", "") };
        }
        return item;

      })
    );
    setIsChange(true);
  };

  const preencherMercado = (nome, pesomigracao, pesohistorico, pesooutros, anos, pesossh) => {

    setSeries([]);
    setAnosAtivos([]);
    setIsChange(false);
    setNomeDistribuidora(nome);
    setAnosAtivos(anos);

    if (periodos.length === 0) {
      if (anos.length > 0) {
        const lista = [];
        anos.forEach(item => {
          lista.push(item.ano);
        });
        setAnosAtivos(lista);
      }
    }

    if (series.length === 0) {
      if (pesossh.length > 0) {
        setPesoMigracao(pesomigracao);
        setPesoHistorico(pesohistorico);
        setPesoOutros(pesooutros);
        pesossh.forEach(item => {
          addSerie({
            id_serie: Math.floor(Math.random() * 100) + 1,
            id: Math.floor(Math.random() * 100) + 1,
            codigo: item.seriehistorica.codigo,
            apelido: item.seriehistorica.nome,
            fonte: item.seriehistorica.fonte,
            periodicidade: item.seriehistorica.periodicidade.tipo,
            categoria: item.seriehistorica.categoria.tipo,
            peso: item.peso
          });
        });
      } else {
        setPesoMigracao("0");
        setPesoHistorico("0");
        setPesoOutros("0");
        addSerie({
          id_serie: Math.floor(Math.random() * 6) + 1,
          id: Math.floor(Math.random() * 6) + 1,
          codigo: "",
          apelido: "",
          fonte: "",
          periodicidade: "",
          categoria: "",
          peso: 0
        });
      }
    }

  }

  const obterPeriodos = useCallback(async (param) => {
    try {
      if (param !== "" && param !== undefined && param !== null) {
        const response = await getAnosAtivos(param);
        if (response?.status === 200 && response?.data) {
          setPeriodos(response.data);
        }
      }
    } catch (error) {
      console.info(error);
    }
  }, []);

  useEffect(() => {
    if (id) obterPeriodos(id);
  }, [id, obterPeriodos]);

  const obterDistruidora = useCallback(async (param) => {
    try {
      if (param !== "" && param !== undefined && param !== null) {
        const response = await getDistribuidora(id);
        if (response?.data) {
          if (response?.status === 200) {
            setIsEdit(true);
          } else {
            setIsEdit(false);
          }
          preencherMercado(response.data.distribuidora.nome,
            response.data.distribuidora.pesomigracao,
            response.data.distribuidora.pesohistorico,
            response.data.distribuidora.pesooutros,
            response.data.anosativos,
            response.data.pesossh);
        }
      }
    } catch (error) {
      console.info(error);
    }
  }, []);

  useEffect(() => {
    if (id) obterDistruidora(id);
  }, [id, obterDistruidora]);

  const obterSeriesHistoricas = useCallback(async () => {
    try {
      const response = await listarSerieHistorica();
      if (response?.status === 200 && response?.data) {
        setSeriesHistoricas(response?.data);
      }
    } catch (error) {
      console.info(error);
    }
  }, []);
  useEffect(() => {
    if (!seriesHistoricas.length) obterSeriesHistoricas();
  }, [!seriesHistoricas.length, obterSeriesHistoricas]);

  const handleTotal = useCallback(() => {
    let sum = 0;
    series.map((item) => sum += parseInt(item.peso))
    const values = [pesoHistorico, pesoMigracao, pesoOutros];
    let sum2 = 0;
    values.map((item) => sum2 += parseInt(item))
    setTotal(sum += sum2);

  }, [series, pesoHistorico, pesoMigracao, pesoOutros]);
  useEffect(() => { handleTotal() }, [handleTotal]);

  const enviarFormulario = async () => {
    try {
      const obj = {
        "distribuidora": {
          "id": id,
          "nome": nomeDistribuidora,
          "pesomigracao": pesoMigracao,
          "pesohistorico": pesoHistorico,
          "pesooutros": pesoOutros
        },
        "anosativos":
          anosAtivos.map(item => (
            {
              "ano": item
            }
          )),
        "pesossh":
          series.map(item => (
            {
              "seriehistorica": {
                "codigo": item.codigo,
                "nome": item.apelido,
                "fonte": item.fonte,
                "periodicidade": {
                  "tipo": item.periodicidade
                },
                "categoria": {
                  "tipo": item.categoria
                }
              },
              "peso": parseInt(item.peso)
            }
          ))
      };

      let salvou = false;
      if (!isEdit) {
        salvou = await add(obj);
      } else {
        salvou = await edit(obj);
      }

      if (salvou) {
        if (salvou?.status === 200 || salvou?.status === 204) {
          store.dispatch(
            alertaExibir({
              tipo: "success",
              mensagem: "Configuração de Série Histórica realizada com sucesso!"
            })
          );
          setTimeout(() => {
            history.push(RotasDTO.Distribuidoras);
          }, 2000);
        }
      }

    } catch (error) {
      console.info(error)
    }
  }

  const aoEnviarFormulario = (dados) => {
    if (total !== 100) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "O valor total deve ser igual a 100%"
        })
      );
    } else if (anosAtivos.length === 0) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "É necessário informar um período"
        })
      );
    } else {
      enviarFormulario(dados);
    }
  }

  const handleChangeAnosAtivos = (event) => {
    setAnosAtivos(event.target.value);
    setIsChange(true);
  }

  const handlePesoMigracao = (event) => {
    setPesoMigracao(event.target.value.replace("%", ""));
    setIsChange(true);
  }

  const handlePesoHistorico = (event) => {
    setPesoHistorico(event.target.value.replace("%", ""));
    setIsChange(true);
  }

  const handlePesoOutros = (event) => {
    setPesoOutros(event.target.value.replace("%", ""));
    setIsChange(true);
  }

  const cancelar = () => {
    setPesoMigracao("0");
    setPesoHistorico("0");
    setPesoOutros("0");
    obterDistruidora(id);
  }

  const deletarDistruidora = useCallback(async (param) => {
    try {
      const response = await excluir(id);
      if (response?.status === 204) {
        obterDistruidora(param);
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: "Configuração de Série Histórica excluida com sucesso!"
          })
        );
        setTimeout(() => {
          history.push(RotasDTO.Distribuidoras);
        }, 2000);
      }
    } catch (error) {
      console.info(error);
    }
  }, []);

  const deletar = () => {
    deletarDistruidora(id);
  }

  return (
    <form className="needs-validation" onSubmit={handleSubmit(aoEnviarFormulario)}>
      <Card className={classes.container}>
        <Grid container p={2} spacing={2} className={classes.container} justifyContent="center">
          <Grid item sm={10}>
            <Grid container p={2} spacing={2} className={classes.container}>
              <Grid item sm={11}>
                <b>Período Ativos</b>
              </Grid>
              <Grid item sm={11}>
                <Loader loading={periodos.length === 0}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="periodos-label">Período</InputLabel>
                    <Select
                      variant="outlined"
                      labelId="periodos-label"
                      id="periodos"
                      value={anosAtivos}
                      onChange={handleChangeAnosAtivos}
                      multiple
                      label="Períodos"
                      fullWidth>
                      {periodos.map((item) => (
                        <MenuItem key={item.ano} value={item.ano}>{item.ano}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Loader>
              </Grid>
            </Grid>
            <Grid container p={2} spacing={2} className={classes.container}>
              <Grid item sm={8} justifyContent="flex-start">
                <b>Série Histórica</b>
              </Grid>
              <Grid item sm={3} justifyContent="flex-start">
                <b>Peso</b>
              </Grid>
            </Grid>
            {series?.map(serie => (
              <Grid container p={2} spacing={2} className={classes.container}>
                <Grid item sm={8}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="seriesHistoricas-label">Nome da Série Histórica</InputLabel>
                    <Select
                      variant="outlined"
                      labelId="seriesHistoricas-label"
                      label="Série Histórica"
                      onChange={changeSelect}
                      value={serie.codigo}
                      fullWidth>
                      {seriesHistoricas.map((item) => (
                        <MenuItem
                          key={item.codigo}
                          data-id={serie?.id_serie}
                          data-apelido={item.apelido}
                          data-fonte={item.fonte}
                          data-periodicidade={item.periodicidade === "M" ? "Mensal" : "Anual"}
                          data-categoria={item.idCategoria}
                          value={item.codigo}
                        >
                          {item.codigo}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={3}>
                  <MaterialInputMascara
                    type="text"
                    sufixo="%"
                    id={serie?.id}
                    name="teste"
                    defaultValue={serie.peso}
                    onBlur={changeInput}
                    renderIconShowHide={false}
                    ref={register}
                  />
                </Grid>
              </Grid>
            ))}
            <br />
            <Grid container p={2} spacing={2} className={classes.container}>
              <Grid item sm={8}>
                <MaterialInputTexto
                  type="text"
                  name="migracao"
                  defaultValue="Migração"
                  renderIconShowHide={false}
                  disabled
                  className={classes.inputText}
                />
              </Grid>
              <Grid item sm={3}>
                <MaterialInputMascara
                  type="text"
                  sufixo="%"
                  id="pesoMigracao"
                  name="pesoMigracao"
                  defaultValue={pesoMigracao}
                  onBlur={handlePesoMigracao}
                  renderIconShowHide={false}
                  ref={register}
                />
              </Grid>
            </Grid>
            <Grid container p={2} spacing={2} className={classes.container}>
              <Grid item sm={8}>
                <MaterialInputTexto
                  type="text"
                  name="historico"
                  defaultValue="Histórico"
                  renderIconShowHide={false}
                  disabled
                  className={classes.inputText}
                />
              </Grid>
              <Grid item sm={3}>
                <MaterialInputMascara
                  type="text"
                  sufixo="%"
                  id="pesoHistorico"
                  name="pesoHistorico"
                  defaultValue={pesoHistorico}
                  onBlur={handlePesoHistorico}
                  renderIconShowHide={false}
                  ref={register}
                />
              </Grid>
            </Grid>
            <Grid container p={2} spacing={2} className={classes.container}>
              <Grid item sm={8}>
                <MaterialInputTexto
                  type="text"
                  name="outros"
                  defaultValue="Outros"
                  renderIconShowHide={false}
                  disabled
                  className={classes.inputText}
                />
              </Grid>
              <Grid item sm={3}>
                <MaterialInputMascara
                  type="text"
                  sufixo="%"
                  id="pesoOutros"
                  name="pesoOutros"
                  defaultValue={pesoOutros}
                  onBlur={handlePesoOutros}
                  renderIconShowHide={false}
                  ref={register}
                />
              </Grid>
            </Grid>
            <Grid container p={2} spacing={2} className={classes.container}>
              <Grid item sm={8} className={classes.total}>
                Total:
              </Grid>
              <Grid item sm={3} className="text-center">
                <b>{total ? `${total}%` : "%"}</b>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={2}>
            <Grid container p={2} spacing={2} className={classes.container}>
              <Grid item sm={12}>
                <button
                  type="button"
                  className={classes.botao}
                  onClick={() => {
                    addSerie({
                      id_serie: Math.floor(Math.random() * 6) + 1,
                      id: Math.floor(Math.random() * 6) + 1,
                      codigo: "",
                      apelido: "",
                      fonte: "",
                      periodicidade: "",
                      categoria: "",
                      peso: 0
                    });
                  }}
                >
                  + Série Histórica
                </button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} style={isEdit ? { display: 'none' } : {}}>
            <Grid container p={2} spacing={2} className={classes.container} justifyContent="flex-end">
              <Grid item sm={3} style={!isChange ? { display: 'none' } : {}}>
                <Botao type="button" color={Base.Mango} label="Cancelar" onClick={cancelar} className={classes.buttonCustom} />
              </Grid>
              <Grid item sm={3}>
                <Botao type="submit" color={Base.Mango} label="Salvar" className={classes.button} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} style={!isEdit ? { display: 'none' } : {}}>
            <Grid container p={2} spacing={2} className={classes.container} justifyContent="flex-end">
              <Grid item sm={3} style={!isChange ? { display: 'none' } : {}}>
                <Botao type="button" color={Base.Mango} label="Cancelar" onClick={cancelar} className={classes.buttonCustom} />
              </Grid>
              <Grid item sm={3}>
                <Botao type="button" color={Base.Mango} label="Excluir" onClick={deletar} className={classes.buttonCustom} />
              </Grid>
              <Grid item sm={3}>
                <Botao type="submit" color={Base.Mango} label="Salvar" className={classes.button} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </form>
  );
}

export default ConfiguracaoDistribuidoras;