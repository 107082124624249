import MICROSERVICO from "../global/enumeradores/microServico";

import obterApi from "./api";

const api = () => obterApi(MICROSERVICO.MEMBERGETMEMBER);

export const obterBeneficioDistribuidora = async (filter) => {
  const filtroQueryString = new URLSearchParams(filter).toString();
  return api().get(
    `/v1/MemberGetMember/ObterBeneficioDistribuidora?${filtroQueryString}`
  );
};

export const obterBeneficioFatura = async (filter) => {
  const filtroQueryString = new URLSearchParams(filter).toString();
  return api().get(
    `/v1/MemberGetMember/ObterBeneficioFatura?${filtroQueryString}`
  );
};

export const criarElasticidadeBeneficio = async (body) => {
  return api().post("/v1/MemberGetMember/ElasticidadeBeneficio", body);
};

export const salvarBeneficioFatura = async (id, body) => {
  let metodo = "post";
  let url = "/v1/MemberGetMember/BeneficioFatura";
  if (id > 0) {
    metodo = "put";
    url = `${url}?idBeneficioFatura=${id}`;
  }
  return api()[metodo](url, body);
};

export const atualizaStatusBeneficioFatura = async (params) => {
  const paramsQueryString = new URLSearchParams(params).toString();

  return api().patch(
    `/v1/MemberGetMember/AtualizaStatusBeneficioFatura?${paramsQueryString}`
  );
};

export const salvarDistribuidoraFatura = async (id, body) => {
  let metodo = "post";
  let url = "/v1/MemberGetMember/BeneficioDistribuidora";
  if (id > 0) {
    metodo = "put";
    url = `${url}?idBeneficioDistribuidora=${id}`;
  }
  return api()[metodo](url, body);
};

export const atualizaStatusDistribuidoraFatura = async (params) => {
  const paramsQueryString = new URLSearchParams(params).toString();

  return api().patch(
    `/v1/MemberGetMember/AtualizaStatusBeneficioDistribuidora?${paramsQueryString}`
  );
};

export const obterBeneficioPrecoFixo = async (filter) => {
  const filtroQueryString = new URLSearchParams(filter).toString();
  return api().get(
    `/v1/MemberGetMember/ObterBeneficioPrecoFixo?${filtroQueryString}`
  );
};

export const salvarPrecoFixoFatura = async (id, body) => {
  let metodo = "post";
  let url = "/v1/MemberGetMember/BeneficioPrecoFixo";
  if (id > 0) {
    metodo = "put";
    url = `${url}?idBeneficioPrecoFixo=${id}`;
  }
  return api()[metodo](url, body);
};

export const atualizaStatusPrecoFixoFatura = async (params) => {
  const paramsQueryString = new URLSearchParams(params).toString();

  return api().patch(
    `/v1/MemberGetMember/AtualizaStatusBeneficioPrecoFixo?${paramsQueryString}`
  );
};

export const obterClienteCupomIndicadorPorCodigo = async (params) => {
  const filtroQueryString = new URLSearchParams(params).toString();
  return api().get(
    `/v1/MemberGetMember/ClienteCupomIndicadorPorCodigo?${filtroQueryString}`
  );
};

export const obterClienteCupomIndicadorPorCliente = async (clienteId) => {
  return api().get(
    `/v1/MemberGetMember/ObterClienteCupomIndicadorClienteCupomIndicadorElasticidade?clienteId=${clienteId}`
  );
};

export const obterCreditoIndicadorPorCliente = async (clienteId) => {
  return api().get(
    `/v1/MemberGetMember/ObterControleCreditoIndicado?ClienteId=${clienteId}`
  );
};

export const obterPermissaoPorCliente = async (clienteId) => {
  return api().get(
    `/v1/MemberGetMember/ClientePermissaoProduto?clienteId=${clienteId}`
  );
};

export const exportarExcelCuponsAtivos = async () => {
  return api().get("v1/MemberGetMember/ExportarExcelCuponsAtivos", {
    responseType: "blob"
  });
};
