const distribuidoEnum = {
  ems: 1261
};

export const tipoProdutoDescricao = (tipo) => {
  if (!tipo) return "";
  switch (Number(tipo)) {
    case distribuidoEnum.ems:
      return "Energia Injetada";
    default:
      return "GD";
  }
};

export const tipoProdutoId = (tipo) => {
  if (!tipo) return "";
  switch (Number(tipo)) {
    case distribuidoEnum.ems:
      return 5;
    default:
      return 4;
  }
};

export const tipoProdutoLabel = (tipo) => {
  if (!tipo) return "";
  switch (Number(tipo)) {
    case distribuidoEnum.ems:
      return "FIDELIDADE";
    default:
      return "DESCONTO";
  }
};

export const tipoProduto = (tipo) => {
  if (!tipo) return "";
  switch (Number(tipo)) {
    case distribuidoEnum.ems:
      return true;
    default:
      return false;
  }
};

export const cardDescricao = (distribuidoraId) => {
  switch (Number(distribuidoraId)) {
    case distribuidoEnum.ems:
      return "na energia consumida*";
    default:
      return "na fatura de energia, todo mês";
  }
};

export const tipoUrl = (distribuidoraId, pessoaFisicaJuridica = "") => {
  switch (Number(distribuidoraId)) {
    case distribuidoEnum.ems:
      return `/v1/ContratoAtend/docusign/termo-adesao-gd-ems-site${pessoaFisicaJuridica}`;
    default:
      return `/v1/SimuladorSite/docusign/termo-adesao-gd${pessoaFisicaJuridica}`;
  }
};
