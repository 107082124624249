export default class AgenteListaDto {
  constructor(id, cliente, sigla, perfil, ativo, perfilAgenteId) {
    this.id = id;
    this.cliente = cliente;
    this.sigla = sigla;
    this.perfil = perfil;
    this.ativo = ativo;
    this.perfilAgenteId = perfilAgenteId
  }
}
