import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Card, Grid } from '@mui/material';
import { useStyles } from './style';
// import BotaoRetornarListagem from 'componentes/botaoRetornarListagem';
import UploadDropArea from 'componentes/upload';
import Botao from 'componentes/botao';
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { importarContratosLeilaoCCEE } from '../../../../../servicos/premissasBrasil';
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import RotasDTO from "../../../../../rotas/rotasUrlDto";
import { useHistory } from "react-router-dom";
import { Loader } from 'componentes';
import ResultadoOperacao from 'componentes/resultadoOperacao';
import { importPing } from "../../../../../utils/import-ping"
import { geraURLUpload } from "../../../../../servicos/awsUtils";
import AWSS3UploadAsh from 'aws-s3-upload-ash';

const S3CustomClient = new AWSS3UploadAsh();

const LeilaoCCEEImportar = () => {
  const classes = useStyles();
  const history = useHistory();
  const usuarioGlobal = useSelector((state) => state.usuario);
  const { handleSubmit } = useForm({
    reValidateMode: 'onSubmit',
  });

  const msgPadraoUpload = "Solte o arquivo para anexá-lo ou procurar";
  const [file, setFile] = useState(false);
  const [fileName, setFileName] = useState(msgPadraoUpload);
  const [importando, setImportando] = useState(false);
  const [carregandoArquivo, setCarregandoArquivo] = useState(false);
  const [progressMesage, setProgressMessage] = useState("");
  const [resultadoOperacao, setResultadoOperacao] = useState(false);
  const [resultadoOperacaoCabecalho, setResultadoOperacaoCabecalho] = useState("");
  const [resultadoOperacaoObj, setResultadoOperacaoObj] = useState({
    "mensagens": []
  });

  const processarArquivo = (arquivo) => {
    if (arquivo != null && arquivo[0] != null) {
      setFile(arquivo[0].file);
      setFileName(arquivo[0].file.name);
    }
  };

  const onAddUpload = (arquivo) => {
    processarArquivo(arquivo);
  };

  const onCancelar = () => {
    setFile(false);
    setFileName(msgPadraoUpload);
    setCarregandoArquivo(false);
    setResultadoOperacao(false);
    setResultadoOperacaoCabecalho("");
    setResultadoOperacaoObj({
      "mensagens": []
    });    
  }

  const obterMensagemDeErro = (error) => {
    if (!error?.message || error?.message?.toLowerCase()?.includes('network error')) {
      return "Ocorreu um erro não previsto.";
    }

    const HTTP_GATEWAY_TIMEOUT_STATUS_CODE = 504;
    const TIMEOUT_DEFAULT_MESSAGE = "The upstream server is timing out";

    if (error?.response?.status === HTTP_GATEWAY_TIMEOUT_STATUS_CODE || error?.response?.message === TIMEOUT_DEFAULT_MESSAGE) {
      return "A importação ultrapassou o limite de tempo estipulado pelo servidor, ocasionando o cancelamento da solicitação.";
    }
    
    return error.message;
  }

  const enviarArquivo = async () => {
    try {
      if (!file) {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "Por favor, selecione o arquivo desejado."
          })
        )
      } 
      else {
        setCarregandoArquivo(true);
        setResultadoOperacao(false);
        setProgressMessage("Importando o arquivo...Esta importação pode levar vários minutos. Por favor, aguarde.");      
        
        console.log('file.file', file)
        const keyOnS3 = file.name;
        const urlPreSigned = await geraURLUpload(keyOnS3)
        const uploadS3Response = await S3CustomClient.uploadFile(file, file.type, urlPreSigned.data);

        console.log('urlPreSigned', urlPreSigned.data)
        console.log('uploadS3Response', uploadS3Response)

        const response = await importarContratosLeilaoCCEE(usuarioGlobal.usuario.id, usuarioGlobal.usuario.usuarioNome, keyOnS3);
        if (response.status === 200 && response.data) {
          const datetimeImportStarted = response.data.DataHoraRequisicao
          const urlParams = new URLSearchParams(window.location.search);
          let idInsumo = urlParams.get('id')
          let codigoInsumo = urlParams.get('codigo')
          await importPing(idInsumo, datetimeImportStarted, usuarioGlobal?.usuario.id, codigoInsumo, (status, message) => {
            let mensagemRetorno = ["Processo de Importação Finalizado.", status, message];
            setFile(false);
            setFileName(msgPadraoUpload);
            setCarregandoArquivo(false);
            setResultadoOperacao(true);
            setResultadoOperacaoObj({ "mensagens": [mensagemRetorno] });
            setResultadoOperacaoCabecalho(`Receita Adicional Bandeira - Importação do arquivo ${file?.name}`)
          })      
        } 
        else if (response?.status === 202 && response?.data) 
        {
          setCarregandoArquivo(false);
          setResultadoOperacao(true); 
          let dadosRetorno = response?.data;
          setResultadoOperacaoCabecalho(`Importação Contratos CCEE - Falhas da Importação`)
          setResultadoOperacaoObj({"mensagens": dadosRetorno.map((item)=> {return item.Mensagem}) });      
        }
      }
    } catch (erro) {
      setFile(false);
      setFileName(msgPadraoUpload);
      setCarregandoArquivo(false);
      setResultadoOperacao(true);
      setResultadoOperacaoCabecalho(`Contratos CCEE - Importação do arquivo ${file?.file?.name ?? fileName}`)

      const mensagemDeErro = obterMensagemDeErro(erro);

      setResultadoOperacaoObj({ "mensagens": [mensagemDeErro] });
      console.info(erro);
    }
  }

  const aoEnviarArquivo = async () => {
      enviarArquivo();
  };

  const onFechar = () => {
    history.push(`${RotasDTO.ProjecaoInsumoListar}`);
  }

  return (
    <form className="needs-validation" onSubmit={handleSubmit(aoEnviarArquivo)}>
      <ResultadoOperacao
        item={resultadoOperacaoObj}
        cabecalhoResultado={resultadoOperacaoCabecalho}
        onClose={() => onFechar()}
      />
      {resultadoOperacao === false ?
      <Card>
        <Grid container className={classes.container} justifyContent="flex-end">
          <Grid container className={classes.containerTitulo}>
            <Grid item xs={11} className="font-weight-bold">
              Importação de Planilha de Leilão CCEE
            </Grid>
            <Grid item xs={1}>
              <CloseIcon onClick={onFechar} className={classes.icon} />
            </Grid>
          </Grid>
        </Grid>
        {carregandoArquivo === false ?
        <Card className={classes.container}>
          <Grid container spacing={4} p={2} className={classes.container}>
            <Grid item sm={12}>
              <UploadDropArea
                onAdd={onAddUpload}
                dropAreaText={fileName}
                showLoader={false}
                acceptedFiles={[
                  '.xlsm,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
                ]}
              />
            </Grid>
          </Grid>
          <Grid container spacing={4} p={2} className={classes.container} justifyContent="space-between">
            <Grid item sm={3}>
              <Botao
                type="submit"
                label="Salvar"
                className={classes.button}
              />
            </Grid>
          </Grid>
        </Card>
            :
            <Card className={classes.container}>
              <Grid container spacing={4} p={2} className={classes.container}>
                <Grid item sm={12} className={classes.fileUpload}>
                  <Grid item sm={12} style={{ textAlign: "center" }}>
                    {progressMesage}
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          }

      </Card>
      : ""
    }      
    </form>
  );
}

export default LeilaoCCEEImportar;