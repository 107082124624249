import { makeStyles } from "@mui/styles";
import { Base } from "componentes/cores";
import theme from "themes";

export const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: Base.White,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& p": {
      color: theme.color.primary,
      fontSize: "11px",
      fontFamily: `${theme.font.family}  !important`,
      fontWeight: 700,
      margin: "10px 0"
    }
  },
  logoDelta: {
    width: "50%",
    padding: "20px"
  },
  imagemGrupo: {
    maxWidth: "80%",
    height: "auto"
  },
  logos: {
    display: "flex"
  },
  logoBox: {
    width: "25%"
  },
  luzLogo: {
    maxWidth: "100%"
  }
}));
