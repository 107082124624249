import { makeStyles } from "@mui/styles";
import styled from "styled-components";
import theme from "themes";
import { createTheme } from "@mui/material/styles";

export const useStyles = makeStyles({
  button: {
    color: theme.color.primaryBackgroud
  },
  card: {
    marginTop: "10px"
  },
  inputRadioLabel: {
    color: `${theme.color.radioButton} !important`
  },
  hr: {
    borderTop: "1px solid black",
    width: "100%",
    marginTop: 30,
    marginBottom: 0,
    marginLeft: 30
  }
});

export const Tabela = styled.table`
  width: 100%;
`;

Tabela.Tr = styled.tr``;
Tabela.Th = styled.th``;
Tabela.Td = styled.td``;

export const th = createTheme();

export const chipStyles = makeStyles({
  previewChip: {
    minWidth: 160,
    maxWidth: 210,
    [th.breakpoints.down("md")]: {
      minWidth: 150,
      maxWidth: 150
    },
    [th.breakpoints.down("lg")]: {
      minWidth: 100,
      maxWidth: 100
    },
    [th.breakpoints.down("xl")]: {
      minWidth: 150,
      maxWidth: 150
    },
    [th.breakpoints.down("xl")]: {
      minWidth: 150,
      maxWidth: 150
    },
    "& .MuiChip-deleteIcon": {
      display: (props) => (props.iconeDesabled ? "none !important" : "block")
    },
    margin: "4px",
    color: "#fff !important",
    fontSize: "14px !important",
    borderRadius: "5px !important",
    backgroundColor: "rgba(0, 0, 0, 0.7) !important"
  }
});
