/* eslint-disable import/no-unresolved */
/* eslint-disable eqeqeq */
import MICROSERVICO from "global/enumeradores/microServico";
import obterApi from "servicos/api";

const api = () => obterApi(MICROSERVICO.PROJECAOTARIFARIA);

export const buscarUnidades = async () => {
  return api().get("v1/UnidadeSerieHistorica");
}

export const buscarUnidadesPorId = async (id) => {
  return api().get(`v1/UnidadeSerieHistorica/${id}`);
}

export const buscarCategorias = async () => {
  return api().get("v1/CategoriaSerieHistorica");
}

export const listarSerieHistorica = async () => {
  return api().get("v1/SerieHistorica");
}

export const listarSerieHistoricaMercadoFaturado = async (data) => {
  return api().get(`v1/SerieHistorica?Ativo=true&Unidade=${data.unidade}&Periodicidade=${data.periodicidade}`);
}

export const buscarPorId = async (id) => {
  return api().get(`v1/SerieHistorica/${id}`);
}

export const buscarPorFiltro = async (Ativo, Categoria, Unidade, Periodicidade) => {
  let url = "v1/SerieHistorica";

  if (Ativo != null && Ativo != undefined) {
    url += `?Ativo=${Ativo}`
  }

  if (Categoria != null && Categoria != undefined && Categoria.trim() !== "") {
    if (url.includes("?")) {
      url += `&Categoria=${Categoria}`
    }
    else {
      url += `?Categoria=${Categoria}`
    }
  }

  if (Unidade != null && Unidade != undefined && Unidade.trim() !== "") {
    if (url.includes("?")) {
      url += `&Unidade=${Unidade}`
    }
    else {
      url += `?Unidade=${Unidade}`
    }
  }

  if (Periodicidade != null && Periodicidade != undefined && Periodicidade.trim() !== "") {
    if (url.includes("?")) {
      url += `&Periodicidade=${Periodicidade}`
    }
    else {
      url += `?Periodicidade=${Periodicidade}`
    }
  }

  return api().get(url);
}

export const salvarSerieHistorica = async (id, data) => {
  let metodo = "post";
  let url = "v1/SerieHistorica/";
  if (id.length > 0) {
    metodo = "put";
    url = `${url}`;
  }
  return api()[metodo](url, data);
}

export const atualizarSerieHistorica = async (data) => {
  return api().put(`v1/SerieHistorica`, data);
}

export const obterValoresSerieHistorica = async (codigo, dataInicial, dataFinal, idCenario) => {
  return api().get(`v1/SerieHistoricaValor/${codigo}/${dataInicial}/${dataFinal}/${idCenario}`);
}

export const listarOrganizarPor = () => {
  const ordernacoes = [
    {
      key: "1",
      label: "Alfabética"
    },
    {
      key: "2",
      label: "Recentes"
    }
  ];

  return ordernacoes;
};

export const listarFiltrarPor = () => {
  const filtros = [
    {
      key: 1,
      label: "Ativo"
    },
    {
      key: 0,
      label: "Inativo"
    }
  ];

  return filtros;
};

export const listarTipoPor = () => {
  const filtros = [
    {
      key: "1",
      label: "Categoria"
    },
    {
      key: "2",
      label: "Periodicidade"
    },
    {
      key: "3",
      label: "Unidade"
    }
  ];

  return filtros;
};


export const obterCenarios = async () => {
  return api().get("v1/Cenario");
}

export const obterSeriesHistoricasCenario = async (idCenario) => {
  return api().get(`v1/SerieHistorica/GetSerieByCenario/${idCenario}`);
}