import MICROSERVICO from "global/enumeradores/microServico";
import obterApi from "servicos/api";

const api = () => obterApi(MICROSERVICO.PROJECAOTARIFARIA);

export const geraURLUpload = async (filenameWithExtesion) => {
	return api().get(`v1/AWSUtils/GeraURLUpload/${encodeURIComponent(filenameWithExtesion)}`);
}


export const uploadFile = async (file, contentType, presignedURL) => {
	try {
        const payload = {
          method: "PUT",
          headers: {
            'Content-Type': contentType
          },
          body: file
        }
        const res = await fetch(presignedURL, payload)
        return Promise.resolve({
          status: 200,
          body: "Upload complete"
        });
      } catch (err) {
        return Promise.resolve({
          status: 400,
          body: "Upload failed"
        });
      }
}