import {
  buscarPorCnpjCotacao,
  salvarClienteCotacao,
  uploadFatura
} from "servicos/cotacaoInicialServico";
import { criarDocumentoClickSign } from "servicos/demoService";
import RetornoEndpointDto from "../../../../global/dto/retornoEndpointDto";
import { store } from "../../../../global/redux";
import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";
import { buscarDadosCadastraisPorCnpj } from "../../../../servicos/dadosCadastraisCnpjServico";

export default class ContatoCotacaoHelper {
  static async obterDadosEmpresaReceitaCNPJ(CNPJ) {
    try {
      const resultado = await buscarDadosCadastraisPorCnpj(CNPJ);

      if (!resultado.data || !resultado.data.data)
        return RetornoEndpointDto.Erro(
          "Ocorreu uma falha ao obter os dados da empresa!"
        );

      return RetornoEndpointDto.Sucesso(
        "Dados obtidos com sucesso!",
        resultado.data.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu uma falha ao obter os dados da empresa!"
      );
    }
  }

  static async salvarClientes(salvarCliente) {
    try {
      const result = await salvarClienteCotacao(salvarCliente.id, salvarCliente);

      return RetornoEndpointDto.Sucesso(
        "Cliente salvo com sucesso!",
        result?.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu uma falha ao salvar os dados da empresa!"
      );
    }
  }

  static async salvarArquivos(arquivos, idCliente) {
    try {
      const result = await uploadFatura(idCliente, arquivos);

      return RetornoEndpointDto.Sucesso(
        "Cliente salvo com sucesso!",
        result.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu uma falha ao salvar os arquivos fatura!"
      );
    }
  }

  static async criarDocumento(dadosCriarDocumento) {
    try {
      const resultado = await criarDocumentoClickSign(dadosCriarDocumento);

      return RetornoEndpointDto.Sucesso(
        "Cliente salvo com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu uma falha ao criar o documento no clicksign!"
      );
    }
  }

  static async obterClientesPorCnpj(cnpj) {
    try {
      const resultado = await buscarPorCnpjCotacao(cnpj);

      if (!resultado || resultado.status === "204")
        return RetornoEndpointDto.Erro(
          "Ocorreu uma falha ao obter os dados da empresa!"
        );

      return RetornoEndpointDto.Sucesso(
        "Dados obtidos com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Ocorreu uma falha ao obter o cliente!");
    }
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }

  static exibirSucesso(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem
      })
    );
  }
}
