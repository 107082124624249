/* eslint-disable radix */
/* eslint-disable no-use-before-define */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useCallback, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Grid, Card, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import BotaoRetornarListagem from "componentes/botaoRetornarListagem";
import MaterialInputTexto from "componentes/inputTexto/materialInput";
import MaterialInputMascara from "componentes/inputTextoMascara";
import Loader from "componentes/loader";
import Botao from "componentes/botao";
import { buscarPorId } from "servicos/distribuidorasServico";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import RotasDTO from "../../../rotas/rotasUrlDto";
import { obterRegioes, obterCapital, obterContrato, obterParametrizacaoDistribuidoraPorId, salvarParametrizacaoDistribuidora, buscarPorIdDistribuidora, salvarApelidoDistribuidora } from "../../../servicos/parametrizacaoDistribuidoras";
import { listarSerieHistorica } from "../../../servicos/seriesHistoricas";
import { useStyles } from "./style";

const ParametrizacaoDistribuidoras = () => {
  const { id } = useParams();
  const history = useHistory();
  const classes = useStyles();

  const { errors, register, handleSubmit } = useForm({
    reValidateMode: "onSubmit",
  });

  const [distribuidora, setDistribuidora] = useState();
  const [apelidosDistribuidora, setApelidosDistribuidora] = useState([]);
  const [regioes, setRegioes] = useState([]);
  const [capitais, setCapitais] = useState([]);
  const [contratos, setContratos] = useState([]);
  const [seriesHistoricas, setSeriesHistoricas] = useState([]);
  const [distribuidoraID, setDistribuidoraID] = useState();
  const [idDistribuidoraDelta, setIdDistribuidoraDelta] = useState();
  const [idRegiao, setIdRegiao] = useState();
  const [idSubsistema, setIdSubsistema] = useState();
  const [idCapital, setIdCapital] = useState();
  const [idContrato, setIdContrato] = useState();
  const [idSerieHistorica, setIdSerieHistorica] = useState();
  const [anoRevisaoTarifaria, setAnoRevisaoTarifaria] = useState();
  const [periodoRevisaoTarifaria, setPeriodoRevisaoTarifaria] = useState();
  const [energiaSuprimento, setEnergiaSuprimento] = useState();
  const [categoriaDistribuicao, setCategoriaDistribuicao] = useState();
  const [statusProjecaoTarifaria, setStatusProjecaoTarifaria] = useState(true);
  const [statusDistribuidora, setStatusDistribuidora] = useState(true);
  const [OR_UD_ER, setOR_UD_ER] = useState();
  const [pesoVMAT, setPesoVMAT] = useState();
  const [pesoVMBT, setPesoVMBT] = useState();
  const [pesoVMMT, setPesoVMMT] = useState();
  const [vmco, setVmco] = useState();
  const [vmrc, setVmrc] = useState();
  const [vmqrr, setVmqrr] = useState();
  const [vmcaimi, setVmcaimi] = useState();
  const [titulo, setTitulo] = useState();

  const handleObterDistribuidora = useCallback(async (idDistribuidora) => {
    try {
      const response = await buscarPorId(idDistribuidora);
      if (response.status === 200 && response.data) {
        
        setDistribuidora(response.data);
      }
    } catch (error) {
      console.info(error);
    }
  }, []);

  useEffect(() => {
    if (idDistribuidoraDelta) handleObterDistribuidora(idDistribuidoraDelta);
  }, [idDistribuidoraDelta, handleObterDistribuidora]);

  const handleObterApelidos = useCallback(async () => {
    try {
      const response = await buscarPorIdDistribuidora(id);
      if (response.status === 200 && response.data) {
        setApelidosDistribuidora(response?.data?.apelidos);
      }
    } catch (error) {
      if (error.message.indexOf("status code 404") > -1) {
        setApelidosDistribuidora([]);
      }
      else {
        console.info(error);
      }
    }
  }, []);

  const handleObterRegioes = async () => {
    try {
      const response = await obterRegioes();
      if (response?.status === 200 && response?.data) {
        setRegioes(response?.data);
      }
    } catch (error) {
      console.info(error);
    }
  }

  const handleObterCapital = async () => {
    try {
      const response = await obterCapital();
      if (response?.status === 200 && response?.data) {
        setCapitais(response?.data);
      }
    } catch (error) {
      console.info(error);
    }
  }

  const handleObterContrato = async () => {
    try {
      const response = await obterContrato();
      if (response?.status === 200 && response?.data) {
        setContratos(response?.data);
      }
    } catch (error) {
      console.info(error);
    }
  }

  const handleObterSeriesHistoricas = useCallback(async () => {
    try {
      const response = await listarSerieHistorica();
      if (response?.status === 200 && response?.data) {
        setSeriesHistoricas(response?.data.filter(filterSeriesHistoricasByInflacao).filter(filterSeriesHistoricasByAtivas));
      }
    } catch (error) {
      console.info(error);
    }
  }, []);


  function filterSeriesHistoricasByInflacao(obj) {
    if ("idCategoria" in obj && obj.idCategoria !== undefined && obj.idCategoria !== null && obj.idCategoria === 1) {
      return true;
    }
    return false;

  }
  function filterSeriesHistoricasByAtivas(obj) {
    if ("ativo" in obj && obj.ativo !== undefined && obj.ativo !== null && obj.ativo === 1) {
      return true;
    }
    return false;

  }

  useEffect(() => {
    handleObterRegioes();
    handleObterCapital();
    handleObterContrato();
    handleObterSeriesHistoricas();
  }, []);

  const handleChangeApelido = (event) => {
    const novoApelido = event.target.value.trim();
    let newListApelidos = apelidosDistribuidora;
    newListApelidos[event.target.id] = novoApelido;
    newListApelidos = [...new Set(newListApelidos)];
    setApelidosDistribuidora(newListApelidos.filter(item => item !== " "));
  };

  const addApelido = () => {
    if (apelidosDistribuidora === []) {
      setApelidosDistribuidora([" "])
    }
    else {
      setApelidosDistribuidora((state) => [...state, " "]);
    }
  }

  const removeApelido = (valueRemove) => {
    const newListApelidos = apelidosDistribuidora?.filter(item => item !== valueRemove)
    setApelidosDistribuidora(newListApelidos);
  }

  function formatarValorSalvar(dado) {
    const formatado = dado
        ? dado.replace(" MWh", "")
            ?.replace(" kW", "")
            ?.replace("R$ ", "")
            ?.replace("%", "")
            ?.replaceAll(".", "")
            ?.replace(",", ".")
            ?.trim()
        : dado;

    return formatado;
  }

  const obterParametrizacao = useCallback(async (distribuidoraItem) => {
    try {
      const response = await obterParametrizacaoDistribuidoraPorId(distribuidoraItem);

      if (response?.status === 200 && response?.data) {
        setDistribuidoraID(response?.data?.idDistribuidora)
        setIdRegiao(response?.data?.regiao);
        setIdSubsistema(response?.data?.subsistema);
        setIdCapital(response?.data?.capital);
        setIdContrato(response?.data?.tipoContrato);
        setIdSerieHistorica(response?.data?.inflacaoFatorX);
        setAnoRevisaoTarifaria(response?.data?.anoRevisaoTarifariaBase);
        setPeriodoRevisaoTarifaria(response?.data?.periodoRevisaoTarifaria);
        setEnergiaSuprimento(response?.data?.mercadoSuprimento);
        setCategoriaDistribuicao(response?.data?.categoriaDistribuicao);
        setStatusProjecaoTarifaria(response?.data?.statusProjecaoTarifaria);
        setStatusDistribuidora(response?.data?.statusDistribuidora);
        setIdDistribuidoraDelta(response?.data?.idDeltaDigital);
        setOR_UD_ER(response?.data?.or_ud_er);
        setPesoVMAT((response?.data?.pesoVMAT * 100).toFixed(4));
        setPesoVMBT((response?.data?.pesoVMBT * 100).toFixed(4));
        setPesoVMMT((response?.data?.pesoVMMT * 100).toFixed(4));
        setVmco((response?.data?.vmco * 100).toFixed(4));
        setVmrc((response?.data?.vmrc * 100).toFixed(4));
        setVmqrr((response?.data?.vmqrr * 100).toFixed(4));
        setVmcaimi((response?.data?.vmcaimi * 100).toFixed(4));
        setTitulo(response?.data?.titulo);
      }
    } catch (error) {
      setDistribuidoraID(null);
    }
  }, []);

  useEffect(() => {
    if (id) obterParametrizacao(id);
  }, [id, obterParametrizacao]);

  useEffect(() => {
    if (id) handleObterApelidos(id);
  }, [id, handleObterApelidos]);

  const enviarFormulario = async () => {
    try {
      const parametroSalvar = {
        idDistribuidora: distribuidoraID,
        idDeltaDigital: idDistribuidoraDelta,
        regiao: idRegiao,
        subsistema: idSubsistema,
        capital: idCapital,
        tipoContrato: idContrato,
        inflacaoFatorX: idSerieHistorica,
        AnoRevisaoTarifariaBase: (anoRevisaoTarifaria != null && anoRevisaoTarifaria != "" && anoRevisaoTarifaria != undefined ? anoRevisaoTarifaria : 0),
        PeriodoRevisaoTarifaria: (periodoRevisaoTarifaria != null && periodoRevisaoTarifaria != "" && periodoRevisaoTarifaria != undefined ? periodoRevisaoTarifaria : 0),
        CNPJ: distribuidora?.cnpj,
        nomeFantasia: distribuidora?.nomeFantasia,
        IdANEEL: distribuidora?.idAneel,
        MercadoSuprimento: energiaSuprimento,
        CategoriaDistribuicao: categoriaDistribuicao,
        StatusProjecaoTarifaria: statusProjecaoTarifaria,
        StatusDistribuidora: statusDistribuidora,
        or_ud_er: parseFloat(OR_UD_ER),
        pesoVMAT: (pesoVMAT / 100),
        pesoVMBT: (pesoVMBT / 100),
        pesoVMMT: (pesoVMMT / 100),
        vmco: (vmco / 100),
        vmrc: (vmrc / 100),
        vmqrr: (vmqrr / 100),
        vmcaimi: (vmcaimi / 100),
        titulo: titulo
      }
      
      const salvou = await salvarParametrizacaoDistribuidora(distribuidoraID ?? 0, parametroSalvar);
      if (salvou?.status === 200 || salvou?.status === 204) {

        if (distribuidoraID === null || distribuidoraID === undefined) {
          setDistribuidoraID(salvou?.data?.idDistribuidora)
        }

        if (distribuidoraID > 0 && apelidosDistribuidora !== undefined && apelidosDistribuidora != [" "]) {
          const mensagemErro = "Parâmetros da Distribuidora Atualizados, porém,";
          try {
            const ApelidosSalvar = { "IdDistribuidora": distribuidoraID, "apelidos": apelidosDistribuidora };
            const salvouApelidos = await salvarApelidoDistribuidora(ApelidosSalvar);
            if (salvouApelidos?.status !== 200 && salvouApelidos?.status !== 204) {
              store.dispatch(
                alertaExibir({
                  tipo: "warning",
                  mensagem: `${mensagemErro} não foi possível salvar os Apelidos da Distribuidora ${salvouApelidos?.status}`
                })
              );
            }
          }
          catch (error) {
            throw new Error(`${mensagemErro} ocorreu erro ao salvar os Apelidos da Distribuidora ${error.message}`);
          }
        }
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: "Parâmetros da Distribuidora Atualizados com sucesso"
          })
        );

      }
      else {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "Não foi possível salvar os Parâmetros da Distribuidora"
          })
        );
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: error
        })
      );
      console.info(error);
    }
  }

  const aoEnviarFormulario = (dados) => {
    if (apelidosDistribuidora !== undefined) {
      const newListApelidos = apelidosDistribuidora?.filter(item => item !== " ");
      setApelidosDistribuidora(newListApelidos);
    }
    if (anoRevisaoTarifaria != null && anoRevisaoTarifaria != undefined && anoRevisaoTarifaria != "" &&
      (anoRevisaoTarifaria < (new Date().getFullYear() - 100) || anoRevisaoTarifaria > (new Date().getFullYear() + 100))) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Ano da Revisão Tarifária inválido"
        })
      );
    }
    else if (periodoRevisaoTarifaria != null && periodoRevisaoTarifaria != undefined && periodoRevisaoTarifaria != "" &&
      (periodoRevisaoTarifaria < 0 || periodoRevisaoTarifaria > 1000)) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Período da Revisão Tarifária inválido"
        })
      );
    }
    else if (idRegiao === undefined || idSubsistema === undefined || idCapital === undefined || idContrato === undefined || idSerieHistorica === undefined) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Todos os Parâmetros da Distribuidora precisam ser preenchidos"
        })
      );
    } else if((+pesoVMMT + +pesoVMAT + +pesoVMBT) !== 100) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Pesos da Variação de Mercado por Faixa de Tensão precisam somar 100%"
        })
      );
    }
    else {
      enviarFormulario(dados);
    }
  }

  return (
    <form className="needs-validation" onSubmit={handleSubmit(aoEnviarFormulario)}>
      <Card className={classes.container}>
        <Grid container p={2} spacing={2} className={classes.container}>
          <Grid item xs={9}> </Grid>
          <Grid item xs={3}>
            <Botao
              type="submit"
              label="Projeção de Mercado"
              className={classes.button}
              onClick={() => history.push(`${RotasDTO.DistribuidorasLista}/configuracao/${distribuidora.id}`)}
            />
          </Grid>
        </Grid>
        <Grid container px={4} py={2} spacing={4} className={classes.container} justifyContent="flex-end">
          <Grid item sm={4}>
            <Loader loading="">
              <FormControl variant="outlined" className={classes.formControl}>
                <MaterialInputTexto
                  type="text"
                  id="nomeFantasia"
                  name="nomeFantasia"
                  label="Nome Fantasia"
                  renderIconShowHide={false}
                  defaultValue={distribuidora?.nomeFantasia !== "" ? distribuidora?.nomeFantasia : ""}
                  disabled="disabled"
                />
              </FormControl>
            </Loader>
          </Grid>
          <Grid item sm={7}>
            <Loader loading="">
              <FormControl variant="outlined" className={classes.formControl}>
                <MaterialInputTexto
                  type="text"
                  id="razaoSocial"
                  name="razaoSocial"
                  label="Razão Social"
                  renderIconShowHide={false}
                  defaultValue={distribuidora?.razaoSocial !== "" ? distribuidora?.razaoSocial : ""}
                  disabled="disabled"
                />
              </FormControl>
            </Loader>
          </Grid>
          <Grid item sm={1}>
            <BotaoRetornarListagem
              justify="flex-end"
              urlListagem={RotasDTO.DistribuidorasLista}
            />
          </Grid>
        </Grid>
        <Grid container p={2} spacing={3}>
          <Grid item xs={8}>
            <Grid container p={2} spacing={2} className={classes.container}>
            <Grid item sm={6}>
                <Loader loading="">
                  <FormControl variant="outlined" className={classes.formControl}>
                    <MaterialInputTexto
                      type="text"
                      id="titulo"
                      name="titulo"
                      label="Título"
                      defaultValue={titulo ?? ""}
                      onBlur={e => setTitulo(e.target.value)}
                      variant="outlined"
                    />


                  </FormControl>
                </Loader>
              </Grid>
              <Grid item sm={6}>
                <Loader loading="">
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="capital-label">Capital</InputLabel>
                    <Select
                      variant="outlined"
                      labelId="capital-label"
                      id="capital"
                      value={idCapital ?? ""}
                      onChange={e => setIdCapital(e.target.value)}
                      label="Capital"
                      fullWidth>
                      {capitais.map((item) => (
                        <MenuItem value={item.capital}>{item.capital}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Loader>
              </Grid>
            </Grid>

            <Grid container p={2} spacing={2} className={classes.container}>
              <Grid item sm={6}>
                <Loader loading="">
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="regiao-label">Região</InputLabel>
                    <Select
                      variant="outlined"
                      labelId="regiao-label"
                      id="regiao"
                      value={idRegiao ?? ""}
                      onChange={e => setIdRegiao(e.target.value)}
                      label="Região"
                      fullWidth>
                      {regioes.map((item) => (
                        <MenuItem value={item.regiao}>{item.regiao}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Loader>
              </Grid>
              <Grid item sm={6}>
                <Loader loading="">
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="subsistema-label">Subsistema</InputLabel>
                    <Select
                      variant="outlined"
                      labelId="subsistema-label"
                      id="subsistema"
                      value={idSubsistema ?? ""}
                      onChange={e => setIdSubsistema(e.target.value)}
                      label="Subsistema"
                      fullWidth>
                      {regioes.map((item) => (
                        <MenuItem value={item.regiao}>{item.regiao}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Loader>
              </Grid>
            </Grid>

            <Grid container p={2} spacing={2} className={classes.container}>
              <Grid item sm={6}>
                <Loader loading="">
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="contrato-label">Tipo de Contrato</InputLabel>
                    <Select
                      variant="outlined"
                      labelId="contrato-label"
                      id="contrato"
                      value={idContrato ?? ""}
                      onChange={e => setIdContrato(e.target.value)}
                      label="Tipo de Contrato"
                      fullWidth>
                      {contratos.map((item) => (
                        <MenuItem value={item.tipoContrato}>{item.tipoContrato}</MenuItem>
                      ))}
                    </Select>

                  </FormControl>
                </Loader>
              </Grid>
              <Grid item sm={6}>
                <Loader loading="">
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="inflacao-label">Inflação no Fator x</InputLabel>
                    <Select
                      variant="outlined"
                      labelId="inflacao-label"
                      id="inflacao"
                      value={idSerieHistorica ?? ""}
                      onChange={e => setIdSerieHistorica(e.target.value)}
                      label="Inflação no Fator x"
                      fullWidth>
                      {seriesHistoricas.map((item) => (
                        <MenuItem value={item.idSerieHistorica}>{item.codigo}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Loader>
              </Grid>
            </Grid>
            <Grid container p={2} spacing={2} className={classes.container}>
              <Grid item sm={6}>
                <Loader loading="">
                  <FormControl variant="outlined" className={classes.formControl}>
                    <MaterialInputTexto
                      type="number"
                      id="anoRevisaoTarifaria"
                      name="anoRevisaoTarifaria"
                      value={anoRevisaoTarifaria ?? ""}
                      label="Ano da Revisão Tarifária"
                      renderIconShowHide={false}
                      onBlur={e => setAnoRevisaoTarifaria(e.target.value)}
                      defaultValue={anoRevisaoTarifaria !== "" ? anoRevisaoTarifaria : ""}
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 4)
                      }}
                    />
                  </FormControl>
                </Loader>
              </Grid>
              <Grid item sm={6}>
                <Loader loading="">
                  <FormControl variant="outlined" className={classes.formControl}>
                    <MaterialInputTexto
                      type="number"
                      id="periodoRevisaoTarifaria"
                      name="periodoRevisaoTarifaria"
                      value={periodoRevisaoTarifaria ?? ""}
                      label="Periodo da Revisão Tarifária"
                      renderIconShowHide={false}
                      onBlur={e => setPeriodoRevisaoTarifaria(e.target.value)}
                      defaultValue={periodoRevisaoTarifaria !== "" ? periodoRevisaoTarifaria : ""}
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2)
                      }}
                    />
                  </FormControl>
                </Loader>
              </Grid>
            </Grid>
            <Grid container p={2} spacing={2} className={classes.container}>
              <Grid item sm={6}>
                <Loader loading="">
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="energia-suprimento-label">Energia Suprimento</InputLabel>
                    <Select
                      variant="outlined"
                      labelId="energia-suprimento"
                      id="energiaSuprimento"
                      value={energiaSuprimento ?? ""}
                      onChange={e => setEnergiaSuprimento(e.target.value)}
                      label="Energia Suprimento"
                      fullWidth>
                      {["TUSD", "TE"].map((item) => (
                        <MenuItem value={item}>{item}</MenuItem>
                      ))}

                    </Select>

                  </FormControl>
                </Loader>
              </Grid>
              <Grid item sm={6}>
                <Loader loading="">
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="categoria-distribuicao">Categoria Distribuição</InputLabel>
                    <Select
                      variant="outlined"
                      labelId="categoria-distribuicao-label"
                      id="categoriaDistribuicao"
                      value={categoriaDistribuicao ?? ""}
                      onChange={e => setCategoriaDistribuicao(e.target.value)}
                      label="Categoria Distribuição"
                      fullWidth>
                      {["Concessionária", "Permissionária"].map((item) => (
                        <MenuItem value={item}>{item}</MenuItem>
                      ))}
                    </Select>

                  </FormControl>
                </Loader>
              </Grid>
            </Grid>
            <Grid container p={2} spacing={2} className={classes.container}>
              <Grid item sm={6}>
                <Loader loading="">
                  <FormControl variant="outlined" className={classes.formControl}>
                    <MaterialInputMascara
                      type="text"
                      id="OR_UD_ER-input"
                      name="OR_UD_ER-input"
                      prefixo="R$ "
                      label="OR + UD + ER"
                      renderIconShowHide={false}
                      onBlur={e => setOR_UD_ER(formatarValorSalvar(e.target.value))}
                      defaultValue={OR_UD_ER !== "" ? OR_UD_ER : ""}
                    />
                  </FormControl>
                </Loader>
              </Grid>
              <Grid item sm={6}>
                <Loader loading="">
                  <FormControl variant="outlined" className={classes.formControl}>
                    <MaterialInputMascara
                      type="text"
                      id="pesoVMAT"
                      name="pesoVMAT"
                      sufixo="%"
                      label="Peso Variação Mercado AT"
                      renderIconShowHide={false}
                      onBlur={e => setPesoVMAT(formatarValorSalvar(e.target.value))}
                      defaultValue={pesoVMAT !== "" ? pesoVMAT : ""}
                    />

                  </FormControl>
                </Loader>
              </Grid>
            </Grid>
            <Grid container p={2} spacing={2} className={classes.container}>
              <Grid item sm={6}>
                <Loader loading="">
                  <FormControl variant="outlined" className={classes.formControl}>
                    <MaterialInputMascara
                      type="text"
                      id="pesoVMMT"
                      name="pesoVMMT"
                      sufixo="%"
                      label="Peso Variação Mercado MT"
                      renderIconShowHide={false}
                      onBlur={e => setPesoVMMT(formatarValorSalvar(e.target.value))}
                      defaultValue={pesoVMMT !== "" ? pesoVMMT : ""}
                    />
                  </FormControl>
                </Loader>
              </Grid>
              <Grid item sm={6}>
                <Loader loading="">
                  <FormControl variant="outlined" className={classes.formControl}>
                    <MaterialInputMascara
                      type="text"
                      id="pesoVMBT"
                      name="pesoVMBT"
                      sufixo="% "
                      label="Peso Variação Mercado BT"
                      renderIconShowHide={false}
                      onBlur={e => setPesoVMBT(formatarValorSalvar(e.target.value))}
                      defaultValue={pesoVMBT !== "" ? pesoVMBT : ""}
                    />

                  </FormControl>
                </Loader>
              </Grid>
              <Grid item sm={6}>
                <Loader loading="">
                  <FormControl variant="outlined" className={classes.formControl}>
                    <MaterialInputMascara
                      type="text"
                      id="vmco"
                      name="vmco"
                      sufixo="% "
                      label="Variação Média CO"
                      renderIconShowHide={false}
                      onBlur={e => setVmco(formatarValorSalvar(e.target.value))}
                      defaultValue={vmco !== "" ? vmco : ""}
                    />
                  </FormControl>
                </Loader>
              </Grid>
              <Grid item sm={6}>
                <Loader loading="">
                  <FormControl variant="outlined" className={classes.formControl}>
                    <MaterialInputMascara
                      type="text"
                      id="vmrc"
                      name="vmrc"
                      sufixo="% "
                      label="Variação Média RC"
                      renderIconShowHide={false}
                      onBlur={e => setVmrc(formatarValorSalvar(e.target.value))}
                      defaultValue={vmrc !== "" ? vmrc : ""}
                    />

                  </FormControl>
                </Loader>
              </Grid>
              <Grid item sm={6}>
                <Loader loading="">
                  <FormControl variant="outlined" className={classes.formControl}>
                    <MaterialInputMascara
                      type="text"
                      id="vmqrr"
                      name="vmqrr"
                      sufixo="% "
                      label="Variação Média QRR"
                      renderIconShowHide={false}
                      onBlur={e => setVmqrr(formatarValorSalvar(e.target.value))}
                      defaultValue={vmqrr !== "" ? vmqrr : ""}
                    />

                  </FormControl>
                </Loader>
              </Grid>
              <Grid item sm={6}>
                <Loader loading="">
                  <FormControl variant="outlined" className={classes.formControl}>
                    <MaterialInputMascara
                      type="text"
                      id="vmcaimi"
                      name="vmcaimi"
                      sufixo="% "
                      label="Variação Média CAIMI"
                      renderIconShowHide={false}
                      onBlur={e => setVmcaimi(formatarValorSalvar(e.target.value))}
                      defaultValue={vmcaimi !== "" ? vmcaimi : ""}
                    />
                  </FormControl>
                </Loader>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={4}>
            <Grid container p={2} spacing={2} className={classes.container}>
              <Grid item sm={6} justifyContent="flex-start">
                <b>Apelidos</b>
              </Grid>
              <Grid item sm={6} justifyContent="flex-start">
                <Grid container p={2} spacing={2} className={classes.container}>
                  <Grid item sm={12}>
                    <button
                      type="button"
                      className={classes.botao}
                      onClick={() => {
                        addApelido();
                      }}
                    >
                      + Apelido
                    </button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container p={2} spacing={2} className={classes.container} justifyContent="right">
              {apelidosDistribuidora?.map((apelido, i) => (
                <Grid container p={2} spacing={2} className={classes.container}>
                  <Grid item sm={10}>
                    <MaterialInputTexto
                      type="text"
                      id={i}
                      name="apelido"
                      value="apelido"
                      renderIconShowHide={false}
                      defaultValue={apelido ?? ""}
                      onBlur={(event) => handleChangeApelido(event)}
                      ref={register({
                        required: "",
                      })}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item sm={2}>
                    <button
                      type="button"
                      className={classes.botao}
                      onClick={() => {
                        removeApelido(apelido);
                      }}
                    >
                      X
                    </button>
                  </Grid>
                </Grid>
              ))}
              <br />
            </Grid>
          </Grid>

        </Grid>
        <Grid container p={3} spacing={3} className={classes.container}>
          <Grid item sm={4}>
            <Botao type="submit" label="Salvar" className={classes.button} />
          </Grid>
        </Grid>
      </Card>
    </form>
  );
}

export default ParametrizacaoDistribuidoras;