import styled from "styled-components";
import { makeStyles , withStyles } from "@mui/styles";
import theme from "themes";

import Tooltip from "@mui/material/Tooltip";

export const useStyles = makeStyles({
  boxItem: {
    alignItems: "center",
    backgroundColor: theme.color.bgItemExpansivel,
    color: theme.color.btFOBorderColor,
    display: "flex",
    fontSize: "12px",
    marginRight: "2px",
    marginBottom: 10,
    // opacity: (props) => (props.ativo ? 1 : 0.5),
    padding: "5px 5px 5px 0;"
  },
  inativo: {
    opacity: 0.5
  },
  ativo: {
    opacity: 1
  },
  label: {
    fontSize: "14px",
  },
  gridItem: {
    flexBasis: "100%",
    fontSize: "2.1875rem",
    margin: 0,
    width: "100%",
    "& .MuiIconButton-root": {
      color: theme.color.buttonColor
    },
    height: "100%"
  },
  treeItem: {
    backgroundColor: theme.color.bgItemExpansivel,
    color: theme.color.secondaryText
  },
  expandItem: {
    backgroundColor: theme.color.bgItemExpansivel,
    fontSize: "18px",
    height: "auto",
    marginLeft: "56px",
    marginTop: 2,
    minWidth: "calc(100% - 56px)",
    padding: "15px 20px",
    textAlign: "center"
  },

  center: {
    justifyContent: "center"
  }
});

export const Icone = styled.img`
  margin-left: 2px;
  max-width: 18px;
  object-fit: cover;
`;

export const HtmlTooltip = withStyles(() => ({
  tooltip: {
    fontSize: "14px",
    width: "100%",
    textAlign: "center",
    alignItems: "center",
    padding: "8px",
    borderImageSlice: 1,
    backgroundImage:
      "linear-gradient(to bottom, #647480, #647480), linear-gradient(to bottom, #374148, #647480 87%)",
    borderImageSource: "linear-gradient(to bottom, #374148, #647480 87%)",
    borderRadius: "5px"
  }
}))(Tooltip);