/* eslint-disable no-unused-vars */
import { Box, Card, Grid, Chip, Button } from "@mui/material";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { montaAtualizaLog } from "servicos/utilsLogs";
import shortid from "shortid";
import { InfoOutlined } from "@mui/icons-material";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import Dynamsoft from "global/utils/dynamsoft";
import { ReactComponent as Upload } from "../../../../assets/cotacoes/luz/upload.svg";
import {
  InputRadioGroup,
  InputTextoCEP,
  Loader,
  SelectArredondado,
  SelectAutocomplete
} from "../../../../componentes";
import ModalMensagemErro from "../../../../componentes/ModalMensagemErro";
import Botao from "../../../../componentes/botao";
import MaterialInputTexto from "../../../../componentes/inputTexto/materialInput";
import MaterialInputTextoMoeda from "../../../../componentes/inputTexto/materialInputMoeda";
import MaterialInputTelefone from "../../../../componentes/inputTexto/materialInputTelefone";
import InputData from "../../../../componentes/inputTextoData";
import MaterialInputMascara from "../../../../componentes/inputTextoMascara";
import ModalConfirmacao from "../../../../componentes/modalConfirmacao";
import ModalLuz from "../../../../componentes/modalLuz";
import UploadCustomizado from "../../../../componentes/uploadCustomizado";
import { unidadesFederativas } from "../../../../global/constantes";
import { InterfaceDTO } from "../../../../global/dto/interfacesDto";
import { store } from "../../../../global/redux";
import {
  desabilitarCliente,
  desabilitarEmpresa,
  desabilitarUnidade
} from "../../../../global/redux/modulos/usuario/actions";
import { RotasDTO } from "../../../../global/rotas/rotasUrlDto";
import { validarCpfCnpj } from "../../../../global/utils/formatCpfCnpj";
import { usuarioPossuiFuncionalidade } from "../../../../servicos/funcionalidadesServico";
import {
  formatarBrasileira,
  removeMaskCep,
  removeMaskCpfCnpj,
  removeMaskTelefone,
  removerAcentos,
  validacaoTrimObject
} from "../../../../servicos/utils";
import statusCotacao from "../../constantes/constanteStatusCotacao";
import CotacaoACLHelper from "./helper";
import { useStyles } from "./style";
import CheckInputs from "../../../../componentes/checkInputs";
import ModalCamera from "../../../../componentes/modalCamera";
import Passos from "../../../../componentes/modalCamera/Passos";

const CotacaoACL = ({ setPassoAtual, setDadosCotacao }) => {
  const { register, handleSubmit, errors } = useForm({
    reValidateMode: "onSubmit"
  });

  const history = useHistory();
  const classes = useStyles();
  const usuario = useSelector((state) => state.usuario);
  const rotas = useSelector((state) => state.rotas);
  const { menu } = usuario;
  const { empresaSelecionada } = usuario;

  const [loading, setLoading] = useState(false);
  const [carregandoOfficer, setCarregandoOfficer] = useState(false);
  const [colaboradorId, setColaboradorId] = useState("");
  const [listaOfficer, setListaOfficer] = useState([]);
  const [error, setError] = useState(false);
  const [carregandoArquivo, setCarregandoArquivo] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [progress, setProgress] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [showLoader, setShowLoader] = useState(false);
  const [cnpjCliente, setCnpjCliente] = useState(null);
  const [nomeEmpresa, setNomeEmpresa] = useState("");
  const [cep, setCep] = useState("");
  const [carregandoDistribuidora, setCarregandoDistribuidora] = useState(false);
  const [listaDistribuidora, setListaDistribuidora] = useState([]);
  const [distribuidoraId, setDistribuidoraId] = useState("");
  const [carregandoSubMercado, setCarregandoSubMercado] = useState(false);
  const [subMercadoId, setSubMercadoId] = useState("");
  const [listaSubmercados, setListaSubmercado] = useState([]);
  const [carregandoTipoEnergia, setCarregandoTipoEnergia] = useState(false);
  const [tipoEnergiaId, setTipoEnergiaId] = useState("");
  const [listaTipoEnergia, setListaTipoEnergia] = useState([]);
  const [consumoForaPonta, setConsumoForaPonta] = useState("");
  const [consumoPonta, setConsumoPonta] = useState("");
  const [demandaForaPonta, setDemandaForaPonta] = useState("");
  const [demandaPonta, setDemandaPonta] = useState("");
  const [taxaIluminacao, setTaxaIluminacao] = useState(null);
  const [icms, setIcms] = useState(null);
  const [pis, setPis] = useState(2.5);
  const [cofins, setCofins] = useState(3);
  const [tradicionalVarejista, setTradicionalVarejista] = useState("VR");
  const [consumidorLivre, setConsumidorLivre] = useState("N");
  const [temGerador, setTemGerador] = useState("N");
  const [dataMigracao, setDataMigracao] = useState(null);
  const [modalidade, setModalidade] = useState("VE");
  const [aumentarDemanda, setAumentarDemanda] = useState(false);
  const [listaNivelTensao, setListaNivelTensao] = useState([]);
  const [carregandoNivelTensao, setCarregandoNivelTensao] = useState(false);
  const [nivelTensaoId, setNivelTensaoId] = useState(null);
  const [unidadeCnpj, setUnidadeCnpj] = useState("N");
  const [opcoesConsumidorLivre, setOpcoesConsumidorLivre] = useState(null);
  const [opcoesUnidadeCnpj, setOpcoesUnidadeCnpj] = useState(null);
  const [opcoesTemGerador, setOpcoesTemGerador] = useState(null);
  const [
    modalCotacaoAbertaMesmoCNPJ,
    setModalCotacaoAbertaMesmoCNPJ
  ] = useState(false);
  const [cotacaoIdMesmoCNPJ, setCotacaoIdMesmoCNPJ] = useState(0);
  const [carregandoNomeFantasia, setCarregandoNomeFantasia] = useState(false);
  const [mensagemModal, setMensagemModal] = useState("");
  const [modalAcao, setModalAcao] = useState(false);
  const [energiaGerada, setEnergiaGerada] = useState("");
  const [energiaPrecoGerada, setEnergiaPrecoGerada] = useState("");
  const [fornecedorCnpj, setFornecedorCnpj] = useState(null);
  const { id } = useParams();
  // eslint-disable-next-line no-unused-vars
  const [status, setStatus] = useState("AT");
  const [faturaDigitalizada, setFaturaDigitalizada] = useState([]);
  const [quandoMigrou, setQuandoMigrou] = useState(null);
  const [distribuidoraUf, setDistribuidoraUf] = useState("");
  const [enabledUf, setEnabledUF] = useState(false);
  const [opcoesTemComissao, setOpcoesTemComissao] = useState(null);
  const [temComissao, setTemComissao] = useState("S");
  const [salesForcesId, setSalesForcesId] = useState(null);
  const [cupom, setCupom] = useState(null);
  const [fileNameLead, setFileName] = useState("");
  const [externoIdFatura, setExternoIdFatura] = useState(null);
  const [email, setEmail] = useState(null);
  const [telefone, setTelefone] = useState(null);
  const [exibirModalMensagem, setExibirModalMensagem] = useState(false);
  const [cotacaoId, setCotacaoId] = useState(null);
  const [numeroInstalacao, setNumeroInstalacao] = useState(null);
  const [dadosCotacaoId, setDadosCotacaoId] = useState(null);
  const [envelopeId, setEnvelopeId] = useState(null);
  const [consumoHistoricoAcl, setConsumoHistoricoAcl] = useState(null);
  const [custoTotal, setCustoTotal] = useState(null);
  const [isErro, setIsErro] = useState(null);
  const [consumoMedioAcl, setConsumoMedioAcl] = useState(null);
  const [statusFatura, setStatusFatura] = useState(null);
  const [usuarioCupom, setUsuarioCupom] = useState(null);
  const [carregandoCupom, setCarregandoCupom] = useState(false);
  const [mensagemModalCupom, setMensagemModalCupom] = useState("");
  const [modalAcaoCupom, setModalAcaoCupom] = useState(false);
  const [mensagemModalConsumidor, setMensagemModalConsumidor] = useState("");
  const [modalAcaoConsumidor, setModalAcaoConsumidor] = useState(false);
  const [checked, setChecked] = useState(false);
  const [cidade, setCidade] = useState(false);
  const [consumoHistorico, setConsumoHistorico] = useState(false);
  const [garantia, setGarantia] = useState(null);
  const [valorGarantia, setValorGarantia] = useState(null);
  const [mesGarantia, setMesGarantia] = useState(null);
  const [dropTextoFoto] = useState("Envie uma foto da sua fatura");
  const [modalCamera, setModalCamera] = useState(false);
  const [passos, setPassos] = useState(false);
  const [carregouScript, setCarregouScript] = useState(false);
  const [statusVigenciaFechado, setStatusVigenciaFechado] = useState(false);
  const [dadosCotacaoAcl, setDadosCotacaoAcl] = useState({});

  const permissaoAlterarFiltrarOfficer = usuarioPossuiFuncionalidade(
    menu,
    rotas.rotaAtual.caminho,
    RotasDTO.CotacaoACL,
    InterfaceDTO.PodeAlterarFiltrarOfficer
  );

  const editarInputs = usuarioPossuiFuncionalidade(
    menu,
    rotas.rotaAtual.caminho,
    RotasDTO.CotacaoACL,
    InterfaceDTO.PermissaoEditarCotacaoACL
  );

  const podeEditarNumeroInstalacao = usuarioPossuiFuncionalidade(
    menu,
    rotas.rotaAtual.caminho,
    RotasDTO.CotacaoACL,
    InterfaceDTO.PodeEditarNumeroInstalacaoACL
  );

  const habiltarEditar = useMemo(() => {
    return podeEditarNumeroInstalacao && checked;
  }, [podeEditarNumeroInstalacao, checked]);

  useEffect(() => {
    if (empresaSelecionada) setColaboradorId(usuario?.usuario?.colaboradorId);
  }, [empresaSelecionada]);

  useEffect(() => {
    store.dispatch(desabilitarCliente(true));
    store.dispatch(desabilitarUnidade(true));

    return () => {
      store.dispatch(desabilitarEmpresa(false));
      store.dispatch(desabilitarCliente(false));
      store.dispatch(desabilitarUnidade(false));
    };
  }, []);

  useEffect(() => {
    if (temGerador === "N") {
      setEnergiaGerada("");
      setEnergiaPrecoGerada("");
    }
  }, [temGerador]);

  useEffect(() => {
    const subMercadoIdSelecionado = unidadesFederativas.find(
      (item) => item.UF === distribuidoraUf
    )?.regiao;
    setSubMercadoId(subMercadoIdSelecionado);
  }, [unidadesFederativas, distribuidoraUf]);

  const dataMigracaoPrevisao = useMemo(() => {
    let dataDefault = "";

    if (dataMigracao) {
      const diaAtual = window.moment(dataMigracao).format("DD");

      if (diaAtual === "01") {
        dataDefault = window.moment(dataMigracao).format();
      } else {
        dataDefault = window
          .moment(dataMigracao)
          .add(1, "months")
          .format("YYYY-MM-01THH:mm:ssZ");
      }
      return dataDefault;
    }

    const diaAtual = window.moment().format("DD");
    if (diaAtual === "01") {
      dataDefault = window.moment(1, "DD").add(6, "months").format();
    } else {
      dataDefault = window.moment(1, "DD").add(7, "months").format();
    }

    return dataDefault;
  }, [dataMigracao]);

  const dataMigracaoCalculeira = useMemo(() => {
    let dataCalculeira = "";

    if (dataMigracao) {
      dataCalculeira = dataMigracaoPrevisao;
    } else if (
      window.moment(dataMigracaoPrevisao).format("YYYY-MM-DD") <=
      window.moment("2024-07-01").format("YYYY-MM-DD")
    ) {
      dataCalculeira = window.moment("2024-07-01").format("YYYY-MM-DD");
    } else {
      dataCalculeira = dataMigracaoPrevisao;
    }

    return dataCalculeira;
  }, [dataMigracaoPrevisao]);

  const validarDataMigracao = (value) => {
    const ontem = window.moment().subtract(1, "day").format("DD/MM/YYYY");
    if (value === ontem) {
      return false;
    }
    return true;
  };

  const validarDataMigrou = (value) => {
    const amanha = window.moment().add(1, "day").format("DD/MM/YYYY");
    if (value === amanha) {
      return false;
    }
    return true;
  };

  const isEdicao = useMemo(() => {
    return id && status !== statusCotacao.Expirada;
  }, [id, status]);

  const edicaoAberta = useMemo(() => {
    return id && status === statusCotacao.Aberta;
  });

  const obterOpcoesSimNao = useCallback(() => {
    setOpcoesConsumidorLivre(CotacaoACLHelper.obterOpcoesSimNao());
    setOpcoesUnidadeCnpj(CotacaoACLHelper.obterOpcoesSimNao());
    setOpcoesTemGerador(CotacaoACLHelper.obterOpcoesSimNao());
    setOpcoesTemComissao(CotacaoACLHelper.obterOpcoesSimNao());
  }, [
    setOpcoesConsumidorLivre,
    setOpcoesUnidadeCnpj,
    setOpcoesTemGerador,
    setOpcoesTemComissao
  ]);

  useEffect(() => {
    obterOpcoesSimNao();
  }, [obterOpcoesSimNao]);

  const listaOpcoesTemGerador = useMemo(() => {
    if (
      !opcoesTemGerador ||
      opcoesTemGerador == null ||
      opcoesTemGerador.length === 0
    )
      return null;

    return opcoesTemGerador.map((x) => {
      x.checked = String(x.valor) === String(temGerador);

      return x;
    });
  }, [opcoesTemGerador, temGerador]);

  const listaOpcoesUnidadeCnpj = useMemo(() => {
    if (
      !opcoesUnidadeCnpj ||
      opcoesUnidadeCnpj == null ||
      opcoesUnidadeCnpj.length === 0
    )
      return null;

    return opcoesUnidadeCnpj.map((x) => {
      x.checked = String(x.valor) === String(unidadeCnpj);

      return x;
    });
  }, [opcoesUnidadeCnpj, unidadeCnpj]);

  const listaOpcoesConsumidorLivre = useMemo(() => {
    if (
      !opcoesConsumidorLivre ||
      opcoesConsumidorLivre == null ||
      opcoesConsumidorLivre.length === 0
    )
      return null;

    return opcoesConsumidorLivre.map((x) => {
      x.checked = String(x.valor) === String(consumidorLivre);

      return x;
    });
  }, [opcoesConsumidorLivre, consumidorLivre]);

  const listaOpcoesTemComissao = useMemo(() => {
    if (
      !opcoesTemComissao ||
      opcoesTemComissao == null ||
      opcoesTemComissao.length === 0
    )
      return null;

    return opcoesTemComissao.map((x) => {
      x.checked = String(x.valor) === String(temComissao);

      return x;
    });
  }, [opcoesTemComissao, temComissao]);

  useEffect(() => {
    if (demandaPonta > 0 && demandaForaPonta > 0) {
      setModalidade("AZ");
    } else if (demandaForaPonta > 0) {
      setModalidade("VE");
    } else {
      setModalidade("VE");
    }

    if (demandaPonta < 500 && demandaPonta > 0) {
      if (demandaForaPonta >= 500) {
        setAumentarDemanda(false);
      } else {
        setAumentarDemanda(true);
      }
    } else if (demandaForaPonta < 500 && demandaForaPonta > 0) {
      if (demandaPonta >= 500) {
        setAumentarDemanda(false);
      } else {
        setAumentarDemanda(true);
      }
    }

    if (dataMigracao && (demandaForaPonta < 500 || demandaPonta < 500)) {
      const date = moment(dataMigracao).isAfter("2023/12/31");
      if (date && tradicionalVarejista === "VR") {
        setAumentarDemanda(false);
      } else {
        setAumentarDemanda(true);
      }
    }

    if (dataMigracao === "" && demandaForaPonta === "" && demandaPonta === "") {
      setAumentarDemanda(false);
    } else if (demandaForaPonta >= 500 || demandaPonta >= 500) {
      setAumentarDemanda(false);
    } else if (!dataMigracao && !demandaForaPonta && !demandaPonta) {
      setAumentarDemanda(false);
    } else if (dataMigracao && demandaForaPonta === "" && demandaPonta === "") {
      setAumentarDemanda(false);
    } else if (demandaForaPonta === undefined && demandaPonta === "") {
      setAumentarDemanda(false);
    } else if (demandaPonta === undefined && demandaForaPonta === "") {
      setAumentarDemanda(false);
    }
  }, [dataMigracao, demandaForaPonta, demandaPonta, tradicionalVarejista]);

  const obterOfficerArvore = async (empresas, colaborador) => {
    setCarregandoOfficer(true);
    const {
      data,
      sucesso,
      mensagem
    } = await CotacaoACLHelper.obterOfficerArvore(
      empresas,
      setCarregandoOfficer,
      setError
    );
    if (!sucesso) {
      CotacaoACLHelper.exibirErro(mensagem);
      return;
    }

    if (!permissaoAlterarFiltrarOfficer) {
      const dados = data.filter((d) => d.id === colaborador);
      setListaOfficer(dados);
      return;
    }

    setListaOfficer(data);
  };

  useEffect(() => {
    if (empresaSelecionada && colaboradorId) {
      obterOfficerArvore(empresaSelecionada, colaboradorId);
    }
  }, [empresaSelecionada, colaboradorId]);

  useEffect(() => {
    const { ConsumoForaPonta, ConsumoPonta, DemandaForaPonta, DemandaPonta } =
      history?.location?.state || {};

    setConsumoForaPonta(ConsumoForaPonta ?? "");
    setConsumoPonta(ConsumoPonta ?? "");
    setDemandaForaPonta(DemandaForaPonta ?? "");
    setDemandaPonta(DemandaPonta ?? "");
  }, [history]);

  const formataValor = (valor) => {
    if (typeof valor === "number") return valor;
    if (!valor) return "";

    return valor
      ? parseFloat(
          String(valor)
            .trim()
            .replace(",00", "")
            .replace("R$", "")
            .replace("R$/MWh", "")
            .replace(" kW", "")
            .replace(" MWh", "")
            .replace(" MWm", "")
            .replace(" ", "")
            .replaceAll(".", "")
            .replace(",", ".")
        )
      : valor;
  };

  const formataValorDemandas = (valor) => {
    if (typeof valor === "number") return valor;
    if (!valor) return "";

    return valor
      .replace("R$ ", "")
      .replace(" kW", "")
      .replace(" MWh", "")
      .replace(" MWm", "")
      .replace(".", "")
      .replace(",", ".")
      .replace(" ", "");
  };

  const handleModalidade = (cor) => {
    switch (cor) {
      case "AZ":
        return "Azul";
      case "VE":
        return "Verde";
      default:
        return cor;
    }
  };

  const handleDemandaForaPonta = (tipo, aumentar) => {
    let valorForaPonta = 0;
    if (tipo === "VR" && aumentar === "S") {
      valorForaPonta = 500;
    } else if (tipo === "VR" && aumentar === "N") {
      valorForaPonta = demandaForaPonta;
    } else if (tipo === "TR" && demandaForaPonta < 500 && aumentar === "S") {
      valorForaPonta = 500;
    } else if (tipo === "TR" && demandaForaPonta < 500 && aumentar === "N") {
      valorForaPonta = demandaForaPonta;
    } else if (tipo === "TR" && demandaForaPonta >= 500) {
      valorForaPonta = demandaForaPonta;
    }

    return formataValor(valorForaPonta);
  };

  const obterColaboradorId = async (colaboradorSelecionado) => {
    if (!colaboradorSelecionado) return;
    const {
      data,
      sucesso,
      mensagem
    } = await CotacaoACLHelper.obterColaboradorId(colaboradorSelecionado);
    if (!sucesso) {
      if (mensagem === "403") {
        return;
      }
      CotacaoACLHelper.exibirErro(mensagem);
      return;
    }

    setCupom(data?.cupom);
  };

  useEffect(() => {
    if (colaboradorId) {
      obterColaboradorId(colaboradorId);
    }
  }, [colaboradorId]);

  const handlePassos = (serasa, dados, ModelProposta, dadosDistribuidora) => {
    if (
      dados?.consulta?.cnae ||
      serasa === "reprovado" ||
      serasa === "pendente"
    ) {
      setPassoAtual("mensagemSerasa");
      setDadosCotacao(dados);
    } else if (!dadosDistribuidora?.mlApp || serasa === "negativo") {
      setPassoAtual("mensagemDistribuidora");
    } else {
      const url = cotacaoId ? `//planos/${cotacaoId}` : "//planos";
      history.push({
        pathname: `${RotasDTO.CotacaoACL}${url}`,
        state: validacaoTrimObject(ModelProposta)
      });
    }
  };

  const atualizarExternoIdDeltaProcessamento = async (
    salesForcesIdFatura,
    serasa,
    dados,
    ModelProposta,
    dadosDistribuidora,
    nomeArquivo
  ) => {
    const model = {
      externoIdOrigem: externoIdFatura,
      externoIdDestino: salesForcesIdFatura,
      nomeArquivo
    };

    const {
      sucesso,
      mensagem
    } = await CotacaoACLHelper.atualizarExternoIdDeltaProcessamento(
      model,
      setLoading
    );
    if (!sucesso) {
      CotacaoACLHelper.exibirErro(mensagem);
      setLoading(false);
      setError(true);
      return;
    }

    ModelProposta.salesForcesId = salesForcesIdFatura ?? salesForcesId;

    handlePassos(serasa, dados, ModelProposta, dadosDistribuidora);
  };

  const criarLeadSalesForceServico = async (
    dados,
    serasa,
    ModelProposta,
    dadosDistribuidora,
    dadosForm,
    consumidor
  ) => {
    const consumoPontaMedia = formataValor(dadosForm?.consumoPonta);
    const consumoForaPontaMedia = formataValor(dadosForm?.consumoForaPonta);

    const consumoMedio = consumoPontaMedia + consumoForaPontaMedia;

    const distribuidoraDescricao = listaDistribuidora?.find(
      (item) => item?.id === distribuidoraId
    )?.nomeFantasia;

    const model = {
      pessoaFisicaJuridica: "PJ",
      nome: nomeEmpresa ?? "",
      email: dadosForm?.email ?? "",
      telefone: removeMaskTelefone(dadosForm?.telefone) ?? "",
      cep: removeMaskCep(cep) ?? "",
      valorDaConta: custoTotal ?? 0,
      multaDistribuidora: false,
      preLeadContratado: false,
      erroLeituraFatura: isErro ?? false,
      consumoAbaixoRange: false,
      distribuidoraNaoAtendida: !dadosDistribuidora?.mlApp,
      contratoGerado: !!envelopeId,
      distribuidora: distribuidoraDescricao ?? "",
      origemDaInformacao: "AreaLogada",
      dataDaCriacaoNoSalesforce: window.moment().format(),
      cupom: usuarioCupom ?? cupom,
      produto: "ACL",
      erroGenericoSimulacao: error,
      observacoes: "",
      cpfCnpj: cnpjCliente ?? "",
      numeroInstalacao: dadosForm?.numeroInstalacao ?? "",
      nomeFantasia: nomeEmpresa ?? "",
      dataSimulacao: window.moment().format(),
      consumoUtilizado: consumoMedioAcl ? consumoMedioAcl / 1000 : 0,
      consumoMensal: consumoMedio ?? 0,
      proprietario: colaboradorId ?? 0,
      empresaDoGrupo: process.env.REACT_APP_EMPRESA_GRUPO_API_URL,
      faturaProcessada: statusFatura === "Processado com Sucesso"
    };

    if (Object.keys(dados).length > 0) {
      model.scoreSerasa = dados?.consulta?.score ?? 0;
      model.descontoOfertado3Anos =
        parseFloat(
          (dados?.economiaConsolidada?.economia3Anos_percent * 100)?.toFixed(2)
        ) ?? 0;
      model.descontoOfertado4Anos =
        parseFloat(
          (dados?.economiaConsolidada?.economia4Anos_percent * 100)?.toFixed(2)
        ) ?? 0;
      model.descontoOfertado5Anos =
        parseFloat(
          (dados?.economiaConsolidada?.economia5Anos_percent * 100)?.toFixed(2)
        ) ?? 0;
      model.reducaoCusto5Anos =
        dados?.economiaConsolidada?.economiaMensal5Anos_reais ?? 0;
      model.reducaoCusto3Anos =
        dados?.economiaConsolidada?.economiaMensal3Anos_reais ?? 0;
      model.descontoInicial =
        parseFloat(
          (dados?.economiaConsolidada?.economia5Anos_percent * 100)?.toFixed(2)
        ) ?? 0;
      model.reducaoDeCusto =
        dados?.economiaConsolidada?.economiaMensal4Anos_reais ?? 0;
    }

    if (fileNameLead) {
      model.nomeDoAnexoComExtensao = fileNameLead;
      model.guidArquivoDaDigitalizacao = externoIdFatura;
    }

    if (!cotacaoId) {
      model.consumoHistorico = consumoHistoricoAcl
        ? consumoHistoricoAcl / 1000
        : 0;
    }

    const {
      data,
      sucesso,
      mensagem
    } = await CotacaoACLHelper.criarLeadSalesForceServico(
      validacaoTrimObject(model),
      setLoading,
      salesForcesId
    );

    if (!sucesso) {
      CotacaoACLHelper.exibirErro(mensagem);
      setLoading(false);
      setError(true);
      return;
    }

    if (consumidor === "consumidorLivre") {
      setModalAcaoConsumidor(true);
      setMensagemModalConsumidor(
        "Em breve nosso time comercial entrará em contato para prosseguir com a simulação."
      );
      return;
    }

    setSalesForcesId(data?.data?.dados?.idDoPreLeadNoSalesforce);

    if (externoIdFatura) {
      atualizarExternoIdDeltaProcessamento(
        data?.data?.dados?.idDoPreLeadNoSalesforce ?? salesForcesId,
        serasa,
        dados,
        ModelProposta,
        dadosDistribuidora,
        fileNameLead
      );

      return;
    }

    ModelProposta.salesForcesId =
      data?.data?.dados?.idDoPreLeadNoSalesforce ?? salesForcesId;

    handlePassos(serasa, dados, ModelProposta, dadosDistribuidora);
  };

  const atualizarCotacaoSituacaoVigencia = async (
    dadosCalculeira,
    serasa,
    ModelProposta,
    dadosDistribuidora,
    dados
  ) => {
    const {
      sucesso,
      mensagem
    } = await CotacaoACLHelper.atualizarCotacaoSituacaoVigencia(
      cotacaoId,
      setLoading
    );

    if (!sucesso) {
      CotacaoACLHelper.exibirErro(mensagem);
      setLoading(false);
      setError(true);
      return;
    }

    criarLeadSalesForceServico(
      dadosCalculeira,
      serasa,
      ModelProposta,
      dadosDistribuidora,
      dados,
      "consumidorNao"
    );
  };

  const normalizaEconomiaPeriodo = (meses, periodo) => {
    const periodoMeses = periodo?.output?.find(
      (item) => item?.quantidadeMeses === meses
    )?.outputCalculeira;

    return {
      inicio: periodoMeses?.economia?.[0].mesTimeline?.mes,
      fim: periodoMeses?.economia?.at(-1)?.mesTimeline?.mes
    };
  };

  const normalizaPlanos = (dadosCalculeira, calculeira) => {
    const arrayPLanos = [];

    arrayPLanos.push(
      {
        periodoMeses: 36,
        economiaAnual: dadosCalculeira?.economia3Anos_reais,
        economiaMensal: dadosCalculeira?.economiaMensal3Anos_reais,
        economiaTotal: (dadosCalculeira?.economia3Anos_reais * 36) / 12,
        flexibilidade: 0,
        sustentabilidade: 0,
        percentualDesconto: dadosCalculeira?.economia3Anos_percent * 100 ?? "",
        cotacaoSelecionada: false,
        tipoPlanoCotacaoId: 12,
        cotacaoId: Number(cotacaoId) ?? 0,
        matrizDescontoId: 0,
        ativo: true,
        dataInicioSuprimento: normalizaEconomiaPeriodo(36, calculeira)?.inicio,
        dataFimSuprimento: normalizaEconomiaPeriodo(36, calculeira)?.fim
      },
      {
        periodoMeses: 48,
        economiaAnual: dadosCalculeira?.economia4Anos_reais,
        economiaMensal: dadosCalculeira?.economiaMensal4Anos_reais,
        economiaTotal: (dadosCalculeira?.economia4Anos_reais * 48) / 12,
        flexibilidade: 0,
        sustentabilidade: 0,
        percentualDesconto: dadosCalculeira?.economia4Anos_percent * 100 ?? "",
        cotacaoSelecionada: false,
        tipoPlanoCotacaoId: 15,
        cotacaoId: Number(cotacaoId) ?? 0,
        matrizDescontoId: 0,
        ativo: true,
        dataInicioSuprimento: normalizaEconomiaPeriodo(48, calculeira)?.inicio,
        dataFimSuprimento: normalizaEconomiaPeriodo(48, calculeira)?.fim
      },
      {
        periodoMeses: 60,
        economiaAnual: dadosCalculeira?.economia5Anos_reais,
        economiaMensal: dadosCalculeira?.economiaMensal5Anos_reais,
        economiaTotal: (dadosCalculeira?.economia5Anos_reais * 60) / 12,
        flexibilidade: 0,
        sustentabilidade: 0,
        percentualDesconto: dadosCalculeira?.economia5Anos_percent * 100 ?? "",
        cotacaoSelecionada: false,
        tipoPlanoCotacaoId: 13,
        cotacaoId: Number(cotacaoId) ?? 0,
        matrizDescontoId: 0,
        ativo: true,
        dataInicioSuprimento: normalizaEconomiaPeriodo(60, calculeira)?.inicio,
        dataFimSuprimento: normalizaEconomiaPeriodo(60, calculeira)?.fim
      }
    );

    return arrayPLanos || [];
  };

  const criarPlanosCotacaoInicial = async (
    dadosCalculeira,
    serasa,
    ModelProposta,
    dadosDistribuidora,
    dados
  ) => {
    const planos = {
      cotacaoInicialPlanosGerados: normalizaPlanos(
        dadosCalculeira?.economiaConsolidada,
        dadosCalculeira
      )
    };
    const {
      sucesso,
      mensagem
    } = await CotacaoACLHelper.criarPlanosCotacaoInicial(planos, setLoading);

    if (!sucesso) {
      CotacaoACLHelper.exibirErro(mensagem);
      setLoading(false);
      setError(true);
      return;
    }

    atualizarCotacaoSituacaoVigencia(
      dadosCalculeira,
      serasa,
      ModelProposta,
      dadosDistribuidora,
      dados
    );
  };

  const formatarDadosCotacao = (dados, dadosDistribuidora) => {
    const desconto =
      listaTipoEnergia.find((item) => item.id === tipoEnergiaId)
        ?.percentualDesconto / 100;

    const nivelTensao = listaNivelTensao?.find(
      (item) => item.id === Number(nivelTensaoId)
    )?.descricao;

    const consumoPontaMedia = formataValor(dados?.consumoPonta);
    const consumoForaPontaMedia = formataValor(dados?.consumoForaPonta);

    const consumoMedio = consumoPontaMedia + consumoForaPontaMedia;

    return {
      parametrosFront: {
        distribuidoraId: distribuidoraId ?? 0,
        subgrupo: nivelTensao ?? "",
        dataMigracao:
          window.moment(dataMigracaoCalculeira).format("YYYY-MM-DD") ?? "",
        submercadoId: subMercadoId ?? 0,
        indiceReauste: "ipca",
        consumoMedio: consumoMedio ?? 0,
        consumoPonta: formataValor(dados?.consumoPonta) ?? 0,
        consumoForaPonta: formataValor(dados?.consumoForaPonta) ?? 0,
        demandaPonta: demandaPonta ? formataValor(demandaPonta) : 0,
        demandaForaPontaCativo: formataValor(demandaForaPonta) ?? 0,
        tipo: tradicionalVarejista ?? "",
        uf: distribuidoraUf ?? "",
        comissao: temComissao === "S",
        cnpj: cnpjCliente ?? "",
        produtoId: 1,
        nomeCliente: nomeEmpresa || "",
        colaboradorId: colaboradorId ?? 0,
        empresaId: empresaSelecionada
      },
      cceeInput: {
        indiceVarejista: tradicionalVarejista === "VR" ? 1 : 0,
        indiceCovid:
          (consumidorLivre === "S" &&
            moment(quandoMigrou).isSameOrAfter("2020/04/01")) ||
          (consumidorLivre === "N" &&
            moment(dataMigracaoCalculeira).isSameOrAfter("2020/04/01"))
            ? 1
            : 0
      },
      geradorInput: {
        precoEnergia_Reais:
          temGerador === "S" ? formataValor(energiaPrecoGerada) : 0,
        indiceEnergia: 0,
        energiaGeradaMWh: temGerador === "S" ? formataValor(energiaGerada) : 0,
        indiceEnergiaGerada: 0
      },
      demandaInput: {
        demandaPonta_kW: demandaPonta ? formataValor(demandaPonta) : 0,
        demandaForaPonta_kW: handleDemandaForaPonta(
          tradicionalVarejista,
          unidadeCnpj
        ),
        demandaForaPontaCativo_kW: formataValor(demandaForaPonta),
        demandaRegistradaPonta_kW: 0,
        demandaRegistradaForaPonta_kW: 0,
        demandaReativaPonta_kW: 0,
        demandaReativaForaPonta_kW: 0,
        ultrapassagemPonta_reais: 0,
        ultrapassagemForaPonta_reais: 0
      },
      descontoInput: {
        desconto_percent: desconto ?? 0
      },
      modalidadeInput: {
        modalidade: handleModalidade(modalidade)
      },
      consumoInput: {
        consumoReativoPonta_MWh: 0,
        consumoReativoForaPonta_MWh: 0,
        reativoPonta_reais: 0,
        reativoForaPonta_reais: 0,
        indiceReativoPonta: 0,
        indiceReativoForaPonta: 0,
        consumoFaturado_MWh: 0
      },
      distribuidoraInput: {
        indiceRegistrada: 0,
        indiceDistribuidoraDesconto: desconto === 0.5 || desconto === 1 ? 1 : 0,
        indiceSubvencaoPisCofins:
          dadosDistribuidora?.pisCofinsSobreDesconto === "AT" ? 1 : 0,
        indiceSubvencaoIcms: dadosDistribuidora?.icmsDesconto === "AT" ? 1 : 0
      },
      iluminacaoPublicaInput: {
        iluminacao_Reais: formataValor(taxaIluminacao) ?? 0
      },
      impostoInput: {
        pis_percent: pis ? formataValor(pis / 100) : 0,
        cofins_percent: cofins ? formataValor(cofins / 100) : 0,
        icms_percent: icms ? formataValor(icms / 100) : 0,
        indicePisCofinsSobreIcms:
          dadosDistribuidora?.pisCofinsIcms === "AT" ? 1 : 0,
        indiceNaoUtilizadaIcms:
          dadosDistribuidora?.naoUtilizadaIcms === "AT" ? 1 : 0
      }
    };
  };

  const formatarDadosProposta = (data, dadosCotacao, dados, cnpjFormatado) => {
    const consumoPontaMedia = formataValor(dados?.consumoPonta);
    const consumoForaPontaMedia = formataValor(dados?.consumoForaPonta);

    const consumoMensal = consumoPontaMedia + consumoForaPontaMedia;

    const distribuidoraDescricao = listaDistribuidora?.find(
      (item) => item?.id === distribuidoraId
    )?.nomeFantasia;

    const submercadoDescricao = listaSubmercados?.find(
      (item) => Number(item?.id) === Number(subMercadoId)
    )?.descricao;

    return {
      nomeRazaoSocial: nomeEmpresa ?? "",
      nomeCliente: nomeEmpresa ?? "",
      nomeDistribuidora: distribuidoraDescricao ?? "",
      clienteCnpj: cnpjCliente ?? "",
      clienteEmail: dados?.email,
      clienteTelefone: removeMaskTelefone(dados?.telefone),
      demanda: dadosCotacao?.parametrosFront?.demandaForaPontaCativo ?? 0,
      consumoMedio: dadosCotacao?.parametrosFront?.consumoMedio ?? 0,
      colaboradorId: colaboradorId ?? 0,
      economiaConsolidada: data?.economiaConsolidada ?? [],
      cep: cep ?? "",
      consumidorLivre: consumidorLivre ?? "",
      classeTarifariaId: modalidade === "AZ" ? 1 : 2,
      nivelTensaoId: nivelTensaoId ?? 0,
      subMercadoId: subMercadoId ?? 0,
      dataMigracaoPrevisao: dataMigracaoCalculeira ?? "",
      demandaContratadaPonta: dadosCotacao?.parametrosFront?.demandaPonta,
      demandaContratadaForaPonta:
        dadosCotacao?.parametrosFront?.demandaForaPontaCativo,
      icms: dadosCotacao?.impostoInput?.icms_percent * 100,
      cosip: dadosCotacao?.iluminacaoPublicaInput?.iluminacao_Reais ?? 0,
      distribuidoraId: distribuidoraId ?? 0,
      numeroInstalacao: dados?.numeroInstalacao ?? "",
      fornecedorCnpj: cnpjFormatado ?? "",
      tradicionalVarejista: tradicionalVarejista ?? "",
      modalidade: modalidade ?? "",
      tipoEnergia: tipoEnergiaId ?? 0,
      pis: pis ?? 0,
      cofins: cofins ?? 0,
      temComissao: temComissao === "S",
      temGerador: temGerador === "S",
      energiaGerada: energiaGerada ? formataValor(energiaGerada) : null,
      precoGerador: energiaPrecoGerada
        ? formataValor(energiaPrecoGerada)
        : null,
      indiceVarejista: dadosCotacao?.cceeInput?.indiceVarejista,
      indiceCovid: dadosCotacao?.cceeInput?.indiceCovid,
      indiceNaoUtilizadaIcms:
        dadosCotacao?.impostoInput?.indiceNaoUtilizadaIcms,
      indicePisCofinsSobreIcms:
        dadosCotacao?.impostoInput?.indicePisCofinsSobreIcms,
      indiceDistribuidoraDesconto:
        dadosCotacao?.distribuidoraInput?.indiceDistribuidoraDesconto,
      indiceRegistrada: dadosCotacao?.distribuidoraInput?.indiceRegistrada,
      indiceSubvencaoIcms:
        dadosCotacao?.distribuidoraInput?.indiceSubvencaoIcms,
      indiceSubvencaoPisCofins:
        dadosCotacao?.distribuidoraInput?.indiceSubvencaoPisCofins,
      indiceReajuste: dadosCotacao?.parametrosFront?.indiceReauste,
      indiceEnergiaGerada: dadosCotacao?.geradorInput?.indiceEnergiaGerada,
      quandoMigrou: quandoMigrou ?? null,
      nivelTensao: dadosCotacao?.parametrosFront?.subgrupo,
      submercado: dadosCotacao?.parametrosFront?.submercadoId,
      salesForcesId: salesForcesId ?? "",
      fileNameLead: fileNameLead ?? "",
      externoIdFatura: externoIdFatura ?? "",
      consumoMensal: formataValor(consumoMensal) ?? 0,
      score: data?.consulta?.score ?? 0,
      consumoForaPonta: dadosCotacao?.parametrosFront?.consumoForaPonta ?? 0,
      consumoPonta: dadosCotacao?.parametrosFront?.consumoPonta ?? 0,
      envelopeId: envelopeId ?? null,
      consumoHistorico: consumoHistoricoAcl ? consumoHistoricoAcl / 1000 : 0,
      valorMedioConta: custoTotal ?? 0,
      isErro: isErro ?? false,
      consumoMedioAcl: consumoMedioAcl ? consumoMedioAcl / 1000 : 0,
      faturaProcessada: statusFatura === "Processado com Sucesso",
      usuarioCupom: usuarioCupom ?? null,
      dadosCalculeira: data || [],
      faturaHistoricos:
        faturaDigitalizada?.resultObject?.[0]?.faturaHistoricos || null,
      cidade,
      uf: distribuidoraUf,
      garantia: garantia || "Não se aplica",
      valorGarantia: valorGarantia || 0,
      mesGarantia: mesGarantia || "",
      submercadoDescricao,
      dadosCotacao: dadosCotacaoAcl || {}
    };
  };

  const executaCalculeira = async (
    dados,
    dadosDistribuidora,
    cnpjFormatado
  ) => {
    if (!dadosDistribuidora?.mlApp) {
      criarLeadSalesForceServico(
        {},
        {},
        {},
        dadosDistribuidora,
        dados,
        "consumidorNao"
      );
      return;
    }

    if (consumidorLivre === "S" && !editarInputs) {
      criarLeadSalesForceServico(
        {},
        {},
        {},
        dadosDistribuidora,
        dados,
        "consumidorLivre"
      );
      return;
    }

    const dadosCotacao = formatarDadosCotacao(dados, dadosDistribuidora);
    const {
      data,
      sucesso,
      mensagem
    } = await CotacaoACLHelper.executaCalculeira(
      validacaoTrimObject(dadosCotacao),
      isEdicao,
      id,
      setLoading,
      setError
    );

    if (!sucesso) {
      CotacaoACLHelper.exibirErro(mensagem);
      setLoading(false);
      return;
    }

    const ModelProposta = formatarDadosProposta(
      data,
      dadosCotacao,
      dados,
      cnpjFormatado
    );

    const serasa = data?.consulta?.nomeStatusSerasa?.toLowerCase();

    if (cotacaoId) {
      criarPlanosCotacaoInicial(
        data,
        serasa,
        ModelProposta,
        dadosDistribuidora,
        dados
      );
    } else {
      criarLeadSalesForceServico(
        data,
        serasa,
        ModelProposta,
        dadosDistribuidora,
        dados,
        "consumidorNao"
      );
    }
  };

  const obterDistribuidoraCnpjOuNome = async (
    cnpjFormatado,
    destino,
    dados
  ) => {
    if (!cnpjFormatado) return;
    const {
      data,
      sucesso,
      mensagem
    } = await CotacaoACLHelper.obterDistribuidoraCnpjOuNome(
      cnpjFormatado,
      setError
    );

    if (!sucesso) {
      CotacaoACLHelper.exibirErro(mensagem);
      setError(true);
      return;
    }

    if (destino === "cep") {
      setDistribuidoraId(data?.id);
    } else {
      executaCalculeira(dados, data, cnpjFormatado);
    }
  };

  const obterCepCobertura = async (cepCobertura, editar) => {
    const {
      data,
      sucesso,
      mensagem
    } = await CotacaoACLHelper.obterCepCobertura(
      cepCobertura,
      setCarregandoDistribuidora,
      setError
    );
    if (!sucesso) {
      if (mensagem === "204") {
        CotacaoACLHelper.exibirNaoEncontrado("Cep não encontrado.");
        setEnabledUF(false);
        setDistribuidoraUf(null);
        setDistribuidoraId(null);
        return;
      }
      CotacaoACLHelper.exibirErro(mensagem);
      setError(true);
      setCarregandoDistribuidora(false);
    }

    if (data?.idDistribuidora === 0) {
      obterDistribuidoraCnpjOuNome(fornecedorCnpj, "cep");
      setEnabledUF(!!data?.uf);
      setDistribuidoraUf(data?.uf);
      setCidade(data?.cidade);
    } else {
      if (editar !== "editar") {
        setDistribuidoraId(data?.idDistribuidora);
      }
      setDistribuidoraUf(data?.uf);
      setCidade(data?.cidade);
      setEnabledUF(true);
    }
  };

  const onBlurCep = async (valorCep) => {
    if (valorCep.length > 7) {
      setCep(removeMaskCep(valorCep));
      obterCepCobertura(removeMaskCep(valorCep));
    }
  };

  const digitalizacaoFatura = async (
    file,
    externoId,
    endpoint,
    fileName,
    colaborador
  ) => {
    if (!empresaSelecionada) {
      setModalAcao(true);
      setMensagemModal("Selecione uma empresa!");
      return;
    }

    setFileName(fileName);
    const {
      data,
      sucesso,
      mensagem
    } = await CotacaoACLHelper.digitalizacaoFatura(
      file,
      fileName,
      externoId,
      colaborador,
      empresaSelecionada,
      distribuidoraId,
      endpoint,
      setCarregandoArquivo,
      setError
    );
    if (!sucesso) {
      setModalAcao(true);
      setMensagemModal(mensagem);
      setCarregandoArquivo(false);
      setFileName("");
      setExternoIdFatura(null);
    }

    setFaturaDigitalizada(data);

    const nivelTensaoFaturaId = listaNivelTensao?.find(
      (item) => item?.descricao === data?.resultObject[0]?.subGrupo
    )?.id;

    const taxaIluminacaoFatura = data?.resultObject[0]?.iluminacaoPublica
      ? data?.resultObject[0]?.iluminacaoPublica?.toString()
      : "0";

    setCep(data?.resultObject[0]?.cepEnderecoCliente);
    setCnpjCliente(data?.resultObject[0]?.cpfCnpjCliente);
    setNomeEmpresa(data?.resultObject[0]?.cliente?.nome);
    setFornecedorCnpj(data?.resultObject[0]?.fornecedorCnpj);
    setConsumoPonta((data?.resultObject?.[0]?.consumoPonta / 1000)?.toFixed(2));
    setConsumoForaPonta(
      (data?.resultObject?.[0]?.consumoForaPonta / 1000)?.toFixed(2)
    );
    setDemandaForaPonta(data?.resultObject?.[0]?.demandaForaPonta);
    setDemandaPonta(data?.resultObject?.[0]?.demandaPonta);
    setNivelTensaoId(nivelTensaoFaturaId);
    setIcms(Number(data?.resultObject?.[0]?.tributosICMSAliquota)?.toFixed(2));
    setTaxaIluminacao(formatarBrasileira(taxaIluminacaoFatura));
    obterCepCobertura(data?.resultObject[0]?.cepEnderecoCliente);

    setExternoIdFatura(data?.resultObject[0]?.externoId);
    setConsumoHistoricoAcl(data?.resultObject[0]?.consumoHistoricoAcl);
    setCustoTotal(data?.resultObject[0]?.custoTotal);
    setIsErro(data?.resultObject[0]?.isErro);
    setConsumoMedioAcl(data?.resultObject[0]?.consumoMedioAcl);
    setStatusFatura(data?.resultObject[0]?.status?.nome);
    setNumeroInstalacao(data?.resultObject[0]?.numeroInstalacao);
    setConsumoHistorico(data?.resultObject[0]?.flagConsumoHistorico);
  };

  const onAddUpload = async (valor) => {
    const fileName = removerAcentos(valor[0]?.file?.name);
    const base64 = valor[0].data.split(",")[1];
    const extensao = valor[0].data.split(";")[0];
    let endpoint = "";

    if (extensao === "data:application/pdf") endpoint = "importar-fourvision";
    else if (extensao === "data:image/png" || extensao === "data:image/jpeg")
      endpoint = "importar-earq";

    const externoId = shortid.generate();
    const colaborador = colaboradorId ? Number(colaboradorId) : null;

    digitalizacaoFatura(base64, externoId, endpoint, fileName, colaborador);
  };

  const onDropRejected = (valor) => {
    const arquivo = valor[0];
    setModalAcao(true);
    setMensagemModal(`Tipo do arquivo ${arquivo.name} não é permitido!`);
  };

  const buscarDadosCadastraisPorCnpj = useCallback(async (cnpj) => {
    const {
      data,
      sucesso,
      mensagem
    } = await CotacaoACLHelper.buscarDadosCadastraisPorCnpj(
      setCarregandoNomeFantasia,
      cnpj
    );

    if (!sucesso) {
      CotacaoACLHelper.exibirErro(mensagem);
      setCarregandoNomeFantasia(false);
      return;
    }
    setNomeEmpresa(data.data.nomeFantasia || data.data.razaoSocial);
  }, []);

  const verificarCnpj = (valor) => {
    const cnpjFormatado =
      valor?.target?.value?.replace(/[^a-zA-Z0-9]/g, "") ?? valor;
    setCnpjCliente(cnpjFormatado);
    if (cnpjFormatado.length === 14) {
      buscarDadosCadastraisPorCnpj(cnpjFormatado);
    }
  };

  const obterNivelTensao = useCallback(async (descricaoNivel) => {
    const { data, sucesso, mensagem } = await CotacaoACLHelper.obterNivelTensao(
      setCarregandoNivelTensao,
      setError
    );

    if (!sucesso) {
      CotacaoACLHelper.exibirErro(mensagem);
      setCarregandoNivelTensao(false);
      return;
    }

    const nivelTensao = data?.find((item) => item.descricao === descricaoNivel)
      ?.id;

    setNivelTensaoId(nivelTensao);

    setListaNivelTensao(data);
  }, []);

  const obterDistribuidora = useCallback(async () => {
    const {
      data,
      sucesso,
      mensagem
    } = await CotacaoACLHelper.obterDistribuidora(
      setCarregandoDistribuidora,
      setError
    );

    if (!sucesso) {
      CotacaoACLHelper.exibirErro(mensagem);
      setCarregandoDistribuidora(false);
      return;
    }
    setListaDistribuidora(data);
  }, []);

  const obterSubmercados = useCallback(async () => {
    const { data, sucesso, mensagem } = await CotacaoACLHelper.obterSubmercados(
      setCarregandoSubMercado,
      setError
    );

    if (!sucesso) {
      CotacaoACLHelper.exibirErro(mensagem);
      setCarregandoSubMercado(false);
      return;
    }
    setListaSubmercado(data);
  }, []);

  const obterTipoEnergia = useCallback(async () => {
    const { data, sucesso, mensagem } = await CotacaoACLHelper.obterTipoEnergia(
      setCarregandoTipoEnergia,
      setError
    );

    if (!sucesso) {
      CotacaoACLHelper.exibirErro(mensagem);
      setCarregandoTipoEnergia(false);
      return;
    }

    const listaEnergia = data?.filter((item) => {
      return (
        item?.descricao === "Incentivada 50%" ||
        item?.descricao === "Convencional"
      );
    });

    setListaTipoEnergia(listaEnergia);

    if (!editarInputs) {
      setTipoEnergiaId(2);
    }
  }, []);

  useEffect(() => {
    obterDistribuidora();
    obterSubmercados();
    obterTipoEnergia();
    obterNivelTensao();
  }, []);

  const obterCotacao = async (dados) => {
    const distribuidoraCnpj = listaDistribuidora?.find(
      (item) => item.id === distribuidoraId
    )?.cnpj;

    if (!dados?.numeroInstalacao || !cnpjCliente || !distribuidoraId) {
      obterDistribuidoraCnpjOuNome(distribuidoraCnpj, "form", dados);
      return;
    }
    const model = {
      numeroInstalacao: dados?.numeroInstalacao || "",
      cpfCnpj: removeMaskCpfCnpj(cnpjCliente),
      distribuidoraId
    };

    if (dadosCotacaoId) {
      obterDistribuidoraCnpjOuNome(distribuidoraCnpj, "form", dados);
      return;
    }

    const { data, sucesso, mensagem } = await CotacaoACLHelper.obterCotacao(
      model,
      setLoading
    );

    if (!sucesso) {
      if (mensagem === "CotacaoEncontrada") {
        obterDistribuidoraCnpjOuNome(distribuidoraCnpj, "form", dados);
      }
    } else {
      setExibirModalMensagem(true);
      setCotacaoId(data);
    }
  };

  const aoEnviarFormulario = (dados) => {
    if (isEdicao && !edicaoAberta) return;

    if (usuario.empresaSelecionada === "") {
      setModalAcao(true);
      setMensagemModal("É necessário selecionar uma Empresa");
      return;
    }

    obterCotacao(dados);
  };

  const onConfirmarTelaEdicao = () => {
    history.push(`${RotasDTO.CotacaoIncial}/${cotacaoIdMesmoCNPJ}`);
    setModalCotacaoAbertaMesmoCNPJ(false);
  };

  const obterCotacaoPorId = async (cotacaoInicialId) => {
    const {
      data,
      sucesso,
      mensagem
    } = await CotacaoACLHelper.obterCotacaoPorId(
      cotacaoInicialId,
      setLoading,
      setError
    );

    if (!sucesso) {
      CotacaoACLHelper.exibirErro(mensagem);
      setLoading(false);
      return;
    }
    const cosip = data?.cosip ? data?.cosip?.toFixed(2) : "0";
    const icmsEdit = data?.icms?.toFixed(2);

    setDadosCotacaoId(data?.id);
    setCep(data?.cep);
    setCnpjCliente(data?.cnpj);
    setNomeEmpresa(data.nomeFantasia);
    setConsumoPonta(data?.consumoPonta?.toFixed(2));
    setConsumoForaPonta(data?.consumoForaPonta?.toFixed(2));
    setDemandaForaPonta(data?.demandaContratada);
    setDemandaPonta(data?.demandaPonta);
    setIcms(Number(data?.icms)?.toFixed(2));
    setTaxaIluminacao(formatarBrasileira(cosip));
    setDataMigracao(data?.dataMigracao);
    setTradicionalVarejista(data?.tradicionalVarejista);
    setConsumidorLivre(data?.consumidorLivre ? "S" : "N");
    setDistribuidoraId(data?.distribuidoraId);
    setColaboradorId(data?.colaboradorId);
    await obterNivelTensao(data?.nivelTensao);
    setTipoEnergiaId(data?.tipoEnergia);
    setIcms(icmsEdit);
    setPis(data?.pis);
    setCofins(data?.cofins);
    setEmail(data?.email);
    setTelefone(data?.telefone);
    setQuandoMigrou(data?.quandoMigrou);
    setTemGerador(data?.temGerador ? "S" : "N");
    setTemComissao(data?.comissao ? "S" : "N");
    setEnergiaGerada(data?.energiaGerada);
    setEnergiaPrecoGerada(data?.precoGerador);
    setNumeroInstalacao(data?.numeroInstalacao);
    setSubMercadoId(data?.submercadoId);
    setSalesForcesId(data?.preLead);
    setEnvelopeId(data?.envelopeID);
    setCustoTotal(data?.valorMedioConta);
    setStatusFatura(
      data?.faturaProcessada === "S" ? "Processado com Sucesso" : null
    );

    obterCepCobertura(data?.cep, "editar");
    setGarantia(data?.garantia);
    setValorGarantia(data?.valorGarantia);
    setMesGarantia(data?.mesesGarantia);
    setStatusVigenciaFechado(data?.status === "FE");
    setDadosCotacaoAcl(data);
  };

  useEffect(() => {
    if (!id) return;
    setCotacaoId(id);
    obterCotacaoPorId(id);
  }, [id]);

  const handleEditarCotacaoGd = () => {
    if (cotacaoId) history.push(`${RotasDTO.CotacaoACL}/${cotacaoId}`);
    obterCotacaoPorId(cotacaoId);
    setExibirModalMensagem(false);
  };

  const handleModalValidacao = () => {
    return (
      <Box fontSize="24px" fontWeight="700">
        Cotação já cadastrada. Deseja editar?
      </Box>
    );
  };

  const obterPorCupom = async (cupomUser) => {
    const { sucesso, mensagem } = await CotacaoACLHelper.obterPorCupom(
      cupomUser?.trim(),
      setCarregandoCupom
    );

    if (!sucesso) {
      if (mensagem === "204") {
        setModalAcaoCupom(true);
        setMensagemModalCupom("Cupom nâo encontrado, tente outro!");
      }
      setCarregandoCupom(false);
    }
  };

  const handleFileSelect = (file) => {
    setCarregouScript(true);
    if (file) {
      return new Promise(() => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          const arquivos = [{ data: reader.result.toString(), file }];
          onAddUpload(arquivos);
        };
      });
    }
  };

  useEffect(() => {
    if (!usuarioCupom) return;
    obterPorCupom(usuarioCupom);
  }, [usuarioCupom]);

  const handleMensagemModalCupom = (mensagem) => {
    return (
      <Box component="div" fontWeight="700" textAlign="center">
        {mensagem}
      </Box>
    );
  };

  const handleMensagemModalConsumidor = (mensagem) => {
    return (
      <Box component="div" fontWeight="700" textAlign="center" fontSize="16px">
        {mensagem}
      </Box>
    );
  };

  const handleMensagemModal = (mensagem) => {
    return (
      <Box component="div" fontWeight="700" textAlign="center">
        {mensagem}
      </Box>
    );
  };

  const renderPassoPlanos = () => {
    const model = {
      nomeRazaoSocial: dadosCotacaoAcl?.nomeFantasia,
      clienteCnpj: dadosCotacaoAcl?.cnpj,
      clienteEmail: dadosCotacaoAcl?.email,
      clienteTelefone: dadosCotacaoAcl?.telefone,
      ...dadosCotacaoAcl
    };

    const url = `//planos/${cotacaoId}`;
    history.push({
      pathname: `${RotasDTO.CotacaoACL}${url}`,
      state: validacaoTrimObject(model)
    });
  };

  return (
    <>
      <ModalMensagemErro
        item={modalAcaoCupom}
        titulo=""
        mensagem={handleMensagemModalCupom(mensagemModalCupom)}
        onCancelar={() => {
          setModalAcaoCupom(false);
          setMensagemModalCupom("");
          setUsuarioCupom(null);
        }}
        onConfirmar={() => {}}
        texto=""
        footer={false}
      />
      <ModalLuz
        item={exibirModalMensagem}
        mensagem={handleModalValidacao()}
        onCancelar={() => {
          setExibirModalMensagem(false);
        }}
        onConfirmar={() => {
          setExternoIdFatura(null);
          handleEditarCotacaoGd();
        }}
      />
      <ModalMensagemErro
        item={modalAcao}
        titulo="Algo deu errado"
        mensagem={handleMensagemModal(mensagemModal)}
        onCancelar={() => {
          setModalAcao(false);
          setMensagemModal("");
        }}
        onConfirmar={() => {}}
        texto="Tentar novamente"
      />
      <ModalConfirmacao
        item={modalCotacaoAbertaMesmoCNPJ}
        onConfirmar={() => onConfirmarTelaEdicao()}
        mensagem="Já existe uma cotação aberta para este CNPJ. Deseja editar?"
        onCancelar={() => {
          setModalCotacaoAbertaMesmoCNPJ(false);
          setCotacaoIdMesmoCNPJ(0);
          setCnpjCliente(null);
        }}
      />

      {passos && (
        <Passos
          fechar={() => setPassos(false)}
          onConfirmar={() => {
            setPassos(false);
            setModalCamera(true);
          }}
        />
      )}
      {modalCamera && (
        <ModalCamera
          onVoltar={() => {
            setPassos(true);
            setModalCamera(false);
            setCarregouScript(true);
          }}
          fechar={() => {
            setModalCamera(false);
            setCarregouScript(true);
          }}
          onCapturar={(imagem) => handleFileSelect(imagem)}
          carregouScript={carregouScript}
        />
      )}

      <ModalMensagemErro
        item={modalAcaoConsumidor}
        titulo=""
        mensagem={handleMensagemModalConsumidor(mensagemModalConsumidor)}
        onCancelar={() => {
          setModalAcaoConsumidor(false);
          setMensagemModalConsumidor("");
        }}
        onConfirmar={() => {}}
        texto=""
        footer={false}
      />
      <Loader loading={loading}>
        <form
          id="hook-form"
          className="needs-validation"
          onSubmit={handleSubmit(aoEnviarFormulario)}
        >
          <Card className={classes.cardCadastro}>
            <Box
              component={Grid}
              container
              spacing={2}
              py={6}
              px={4}
              className={classes.container}
            >
              <Box component={Grid} item xs={12} lg={6}>
                <Box component={Grid} container spacing={2}>
                  <Grid item xs={12} className="font-weight-bold">
                    Informe os dados de energia
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Loader loading={carregandoOfficer}>
                      <SelectArredondado
                        id="colaboradorId"
                        name="colaboradorId"
                        valueKey="id"
                        valueName="nomeColaborador"
                        dataSource={listaOfficer}
                        label="Officer"
                        value={colaboradorId}
                        onChange={(e) => setColaboradorId(e.target.value)}
                        placeholder="Officer"
                        styleForm={false}
                        ref={register(
                          { name: "colaboradorId" },
                          {
                            required: !colaboradorId
                              ? "O campo Officer é obrigatório!"
                              : false
                          }
                        )}
                        errors={errors}
                        disabled={statusVigenciaFechado}
                      />
                    </Loader>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Loader loading={carregandoCupom}>
                      <MaterialInputTexto
                        type="text"
                        id="cupom"
                        name="cupom"
                        label="Cupom (opcional)"
                        defaultValue={usuarioCupom ?? ""}
                        permiteValorBranco
                        renderIconShowHide={false}
                        onBlur={(e) => {
                          setUsuarioCupom(e.target.value);
                        }}
                        ref={register({
                          maxLength: {
                            value: 200,
                            message: "Quantidade máxima de 200 caracteres!"
                          }
                        })}
                        errors={errors}
                        allowClear
                        disabled={statusVigenciaFechado}
                      />
                    </Loader>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box
                      bgcolor="rgba(250, 62, 177, 0.08)"
                      border="2px dashed #FA3EB1"
                      borderRadius="8px"
                    >
                      <UploadCustomizado
                        dropAreaText="Anexar fatura"
                        loader={carregandoArquivo}
                        onAdd={onAddUpload}
                        progressValue={progress}
                        showLoader={showLoader}
                        onDropRejected={onDropRejected}
                        acceptedFiles={["image/*", "application/pdf"]}
                        icon={Upload}
                        disabled={statusVigenciaFechado}
                      />
                    </Box>
                  </Grid>
                  <Grid item sm={6} xs={12} sx={{ display: { md: "none" } }}>
                    <Loader loading={carregandoArquivo}>
                      <Box
                        bgcolor="rgba(250, 62, 177, 0.08)"
                        border="2px dashed #FA3EB1"
                        borderRadius="8px"
                      >
                        <Button
                          type="button"
                          className={classes.botaoCamera}
                          onClick={() => {
                            if (!empresaSelecionada) {
                              setModalAcao(true);
                              setMensagemModal("Selecione uma empresa!");
                              return;
                            }
                            setPassos(true);
                          }}
                        >
                          <CameraAltOutlinedIcon />
                          {dropTextoFoto}
                        </Button>
                      </Box>
                    </Loader>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12} />
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    {podeEditarNumeroInstalacao && (
                      <Box color="rgba(0, 0, 0, 0.6)">
                        <CheckInputs
                          label="Habilitar a edição"
                          setChecked={setChecked}
                          checked={checked}
                          width={15}
                          height={15}
                          disabled={statusVigenciaFechado}
                        />
                      </Box>
                    )}
                    <MaterialInputTexto
                      type="text"
                      id="numeroInstalacao"
                      name="numeroInstalacao"
                      label="Número Instalação"
                      permiteValorBranco
                      renderIconShowHide={false}
                      defaultValue={numeroInstalacao ?? ""}
                      ref={register}
                      className={!habiltarEditar ? classes.desabledInput : ""}
                      readOnly={!habiltarEditar}
                      disabled={statusVigenciaFechado}
                    />
                  </Grid>

                  <Grid xs={6} />

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <MaterialInputMascara
                      type="text"
                      id="cnpj"
                      name="cnpj"
                      label="CNPJ"
                      mask="##.###.###/####-##"
                      renderIconShowHide={false}
                      defaultValue={cnpjCliente ?? ""}
                      onBlur={(valor) => verificarCnpj(valor)}
                      ref={register({
                        required: "Campo CNPJ é obrigatório!",
                        maxLength: {
                          value: 18,
                          message: "Quantidade máxima de 18 caracteres!"
                        },
                        minLength: {
                          value: 18,
                          message: "Quantidade mínima de 18 caracteres!"
                        },
                        validate: (value) =>
                          validarCpfCnpj(value) || "CNPJ inválido!"
                      })}
                      errors={errors}
                      disabled={statusVigenciaFechado}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Loader loading={carregandoNomeFantasia}>
                      <MaterialInputTexto
                        type="text"
                        id="nomeFantasia"
                        name="nomeFantasia"
                        label="Nome fantasia"
                        defaultValue={nomeEmpresa ?? ""}
                        permiteValorBranco
                        renderIconShowHide={false}
                        onBlur={(e) => {
                          setNomeEmpresa(e.target.value);
                        }}
                        ref={register({
                          required: "Campo Nome Fantasia é obrigatório!",
                          maxLength: {
                            value: 200,
                            message: "Quantidade máxima de 200 caracteres!"
                          }
                        })}
                        errors={errors}
                        allowClear
                        disabled={statusVigenciaFechado}
                      />
                    </Loader>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <MaterialInputTexto
                      type="text"
                      id="email"
                      name="email"
                      label="E-mail"
                      defaultValue={email ?? ""}
                      permiteValorBranco
                      renderIconShowHide={false}
                      ref={register({
                        required: "O campo E-mail é obrigatório!",
                        pattern: {
                          value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: "Campo E-mail inválido!"
                        },
                        maxLength: {
                          value: 100,
                          message: "Quantidade máxima de 100 caracteres!"
                        }
                      })}
                      errors={errors}
                      disabled={statusVigenciaFechado}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <MaterialInputTelefone
                      type="text"
                      id="telefone"
                      name="telefone"
                      label="Telefone"
                      defaultValue={telefone ?? ""}
                      permiteValorBranco
                      renderIconShowHide={false}
                      ref={register({
                        required: "O campo Telefone é obrigatório!",
                        minLength: {
                          value: 18,
                          message: "Quantidade mínima de 12 caracteres!"
                        }
                      })}
                      errors={errors}
                      disabled={statusVigenciaFechado}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <InputTextoCEP
                      id="Cep"
                      name="Cep"
                      label="CEP"
                      carregandoEndereco={carregandoDistribuidora}
                      onBlurCep={onBlurCep}
                      defaultValue={cep ?? ""}
                      register={register}
                      errors={errors}
                      disabled={statusVigenciaFechado}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Loader loading={carregandoDistribuidora}>
                      <SelectAutocomplete
                        disableClearable
                        disableOpenOnFocus
                        id="distribuidoraId"
                        name="distribuidoraId"
                        options={listaDistribuidora}
                        label="Distribuidora"
                        getOptionLabel={(option) =>
                          option?.nomeFantasia ?? distribuidoraId
                        }
                        onChange={(e, item) => {
                          setDistribuidoraId(item?.id);
                        }}
                        value={distribuidoraId}
                        ref={register(
                          { name: "distribuidoraId" },
                          {
                            required: !distribuidoraId
                              ? "O campo de distribuidora obrigatório"
                              : false
                          }
                        )}
                        error={errors}
                        allowClear
                        valueName="nomeFantasia"
                        disabled={statusVigenciaFechado}
                      />
                    </Loader>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Loader loading={carregandoSubMercado}>
                      <SelectAutocomplete
                        disableClearable
                        disableOpenOnFocus
                        id="subMercadoId"
                        name="subMercadoId"
                        options={listaSubmercados}
                        label="SubMercado"
                        getOptionLabel={(option) =>
                          option?.descricao ?? subMercadoId
                        }
                        onChange={(e, item) => {
                          setSubMercadoId(item?.id);
                        }}
                        value={subMercadoId}
                        ref={register(
                          { name: "subMercadoId" },
                          {
                            required: !subMercadoId
                              ? "O campo de SubMercado obrigatório"
                              : false
                          }
                        )}
                        error={errors}
                        allowClear
                        valueName="descricao"
                        disabled={statusVigenciaFechado}
                      />
                    </Loader>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Loader loading={carregandoTipoEnergia}>
                      <SelectAutocomplete
                        disableClearable
                        disableOpenOnFocus
                        id="tipoEnergiaId"
                        name="tipoEnergiaId"
                        options={listaTipoEnergia}
                        label="Tipo Energia"
                        getOptionLabel={(option) =>
                          option?.descricao ?? tipoEnergiaId
                        }
                        onChange={(e, item) => {
                          setTipoEnergiaId(item?.id);
                        }}
                        value={tipoEnergiaId}
                        ref={register(
                          { name: "tipoEnergiaId" },
                          {
                            required: !tipoEnergiaId
                              ? "O campo de tipo de energia obrigatório"
                              : false
                          }
                        )}
                        error={errors}
                        allowClear
                        valueName="descricao"
                        disabled={statusVigenciaFechado}
                      />
                    </Loader>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Loader loading={carregandoNivelTensao}>
                      <SelectArredondado
                        id="nivelTensao"
                        name="nivelTensao"
                        valueKey="id"
                        marginBottom="0"
                        valueName="descricao"
                        dataSource={listaNivelTensao}
                        label="Nível de tensão"
                        value={nivelTensaoId}
                        onChange={(e) => setNivelTensaoId(e.target.value)}
                        placeholder="Nível de tensão"
                        errors={errors}
                        ref={register(
                          { name: "nivelTensao" },
                          {
                            required: !nivelTensaoId
                              ? "O campo Nível de tensão é obrigatório!"
                              : false
                          }
                        )}
                        disabled={statusVigenciaFechado}
                      />
                    </Loader>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <SelectArredondado
                      id="distribuidoraUf"
                      name="distribuidoraUf"
                      valueKey="UF"
                      valueName="UF"
                      dataSource={unidadesFederativas}
                      label="UF"
                      value={distribuidoraUf ?? ""}
                      onChange={(e) => setDistribuidoraUf(e.target.value)}
                      placeholder="UF"
                      allowClear={!distribuidoraUf}
                      ref={register(
                        { name: "distribuidoraUf" },
                        {
                          required: !distribuidoraUf
                            ? "Campo UF é obrigatório!"
                            : false
                        }
                      )}
                      errors={errors}
                      disabled={enabledUf || statusVigenciaFechado}
                    />
                  </Grid>

                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box component="div" py={3}>
                      Dados consumo:
                    </Box>
                  </Grid>

                  {consumoHistorico && (
                    <Grid item xs={12}>
                      <Box display="flex" alignItems="center">
                        <InfoOutlined
                          htmlColor="rgb(250, 62, 177)"
                          fontSize="small"
                        />
                        <Box component="span" ml="5px">
                          Consumo histórico utilizado
                        </Box>
                      </Box>
                    </Grid>
                  )}

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <MaterialInputMascara
                      type="text"
                      id="consumoForaPonta"
                      name="consumoForaPonta"
                      label="Consumo Fora Ponta (MWh)"
                      sufixo=" MWh"
                      prefixo=""
                      renderIconShowHide={false}
                      defaultValue={consumoForaPonta}
                      ref={register({
                        required: "Campo Consumo Fora Ponta é obrigatório!",
                        maxLength: {
                          value: 18,
                          message: "Quantidade máxima de 18 caracteres!"
                        },
                        minLength: {
                          value: 1,
                          message: "Quantidade mínima de 1 caracteres!"
                        }
                      })}
                      errors={errors}
                      disabled={statusVigenciaFechado}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <MaterialInputMascara
                      type="text"
                      id="consumoPonta"
                      name="consumoPonta"
                      label="Consumo Ponta (MWh)"
                      sufixo=" MWh"
                      prefixo=""
                      renderIconShowHide={false}
                      defaultValue={consumoPonta}
                      ref={register({
                        required: "Campo Consumo Ponta é obrigatório!",
                        maxLength: {
                          value: 18,
                          message: "Quantidade máxima de 18 caracteres!"
                        },
                        minLength: {
                          value: 1,
                          message: "Quantidade mínima de 1 caracteres!"
                        }
                      })}
                      errors={errors}
                      disabled={statusVigenciaFechado}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <MaterialInputMascara
                      type="text"
                      id="demandaContratadaKW"
                      name="demandaContratadaKW"
                      label="Demanda contratatada fora ponta (kW)"
                      sufixo=" kW"
                      defaultValue={demandaForaPonta}
                      renderIconShowHide={false}
                      onBlur={(e) =>
                        setDemandaForaPonta(
                          formataValorDemandas(e?.target?.value)
                        )
                      }
                      ref={register({
                        required:
                          "Campo demanda contratatada Fora Ponta (kW) é obrigatório!",
                        maxLength: {
                          value: 18,
                          message: "Quantidade máxima de 18 caracteres!"
                        },
                        minLength: {
                          value: 1,
                          message: "Quantidade mínima de 1 caracteres!"
                        }
                      })}
                      errors={errors}
                      disabled={statusVigenciaFechado}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <MaterialInputMascara
                      type="text"
                      id="demandaPonta"
                      name="demandaPonta"
                      label="Demanda contratada ponta (kW)"
                      sufixo=" kW"
                      defaultValue={demandaPonta}
                      renderIconShowHide={false}
                      onBlur={(e) =>
                        setDemandaPonta(formataValorDemandas(e?.target?.value))
                      }
                      errors={errors}
                      disabled={statusVigenciaFechado}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <MaterialInputTextoMoeda
                      type="text"
                      id="taxaIluminacao"
                      name="taxaIluminacao"
                      label="Iluminação Pública"
                      defaultValue={taxaIluminacao ?? ""}
                      renderIconShowHide={false}
                      onInput={(e) => {
                        setTaxaIluminacao(formatarBrasileira(e.target.value));
                      }}
                      ref={register({
                        required: !taxaIluminacao
                          ? "Valor da taxa de iluminação pública é obrigatório!"
                          : false
                      })}
                      errors={errors}
                      disabled={statusVigenciaFechado}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <MaterialInputMascara
                      type="text"
                      id="icms"
                      name="icms"
                      label="Icms (%)"
                      sufixo=" %"
                      defaultValue={icms ?? ""}
                      renderIconShowHide={false}
                      onBlur={(e) => setIcms(formataValor(e?.target?.value))}
                      ref={register({
                        required: "Campo Icms é obrigatório!"
                      })}
                      errors={errors}
                      disabled={statusVigenciaFechado}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <MaterialInputMascara
                      type="text"
                      id="pis"
                      name="pis"
                      label="Pis (%)"
                      sufixo=" %"
                      defaultValue={pis}
                      renderIconShowHide={false}
                      disabled
                      onBlur={(e) => setPis(formataValor(e?.target?.value))}
                      ref={register({
                        required: "Campo Pis é obrigatório!"
                      })}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <MaterialInputMascara
                      type="text"
                      id="cofins"
                      name="cofins"
                      label="Cofins (%)"
                      sufixo=" %"
                      defaultValue={cofins}
                      renderIconShowHide={false}
                      disabled
                      onBlur={(e) => setCofins(formataValor(e?.target?.value))}
                      ref={register({
                        required: "Campo Cofins é obrigatório!"
                      })}
                      errors={errors}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <InputRadioGroup
                      type="text"
                      id="tradicionalVarejista"
                      name="tradicionalVarejista"
                      label="Tipo de mercado"
                      renderIconShowHide={false}
                      customValue={tradicionalVarejista}
                      onChange={(e) =>
                        setTradicionalVarejista(e?.target?.value)
                      }
                      defaultValue={tradicionalVarejista}
                      ref={register({
                        required: "Campo Consumidor Livre é obrigatório!"
                      })}
                      errors={errors}
                      className={classes.inputRadio}
                      classNameLabel={classes.inputRadioLabel}
                      itens={CotacaoACLHelper.obterTradicionalVarejista()}
                      disabled={statusVigenciaFechado || !editarInputs}
                    />
                  </Grid>

                  {editarInputs && (
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <InputRadioGroup
                        type="text"
                        id="temComissao"
                        name="temComissao"
                        label="Têm comissão?"
                        renderIconShowHide={false}
                        customValue={temComissao}
                        onChange={(e) => setTemComissao(e?.target?.value)}
                        defaultValue={temGerador}
                        ref={register({
                          required: "Campo comissão é obrigatório!"
                        })}
                        errors={errors}
                        className={classes.inputRadio}
                        classNameLabel={classes.inputRadioLabel}
                        itens={listaOpcoesTemComissao}
                        disabled={statusVigenciaFechado}
                      />
                    </Grid>
                  )}

                  {editarInputs && (
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <InputRadioGroup
                        type="text"
                        id="temGerador"
                        name="temGerador"
                        label="Têm gerador?"
                        renderIconShowHide={false}
                        customValue={temGerador}
                        onChange={(e) => setTemGerador(e?.target?.value)}
                        defaultValue={temGerador}
                        ref={register({
                          required: "Campo Gerador é obrigatório!"
                        })}
                        errors={errors}
                        className={classes.inputRadio}
                        classNameLabel={classes.inputRadioLabel}
                        itens={listaOpcoesTemGerador}
                        disabled={statusVigenciaFechado || !editarInputs}
                      />
                    </Grid>
                  )}

                  {editarInputs && (
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Box component={Grid} container spacing={2}>
                        <Grid item xs={12} sm={12} md={6}>
                          <MaterialInputMascara
                            type="text"
                            id="energiaGerada"
                            name="energiaGerada"
                            label="Energia Gerada (MWh)"
                            sufixo=" MWh"
                            defaultValue={energiaGerada}
                            renderIconShowHide={false}
                            limparValor={temGerador === "N"}
                            onInputChange={(valor) =>
                              setEnergiaGerada(formataValor(valor))
                            }
                            ref={register({
                              required:
                                temGerador === "S"
                                  ? "Campo Energia Gerada é obrigatório!"
                                  : false
                            })}
                            errors={errors}
                            disabled={
                              temGerador !== "S" || statusVigenciaFechado
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <MaterialInputMascara
                            type="text"
                            id="precoGerador"
                            name="precoGerador"
                            label="Preço Gerador (R$/MWh)"
                            sufixo=" R$/MWh"
                            defaultValue={energiaPrecoGerada}
                            limparValor={temGerador === "N"}
                            renderIconShowHide={false}
                            onInputChange={(valor) =>
                              setEnergiaPrecoGerada(formataValor(valor))
                            }
                            ref={register({
                              required:
                                temGerador === "S"
                                  ? "Campo Preço Gerador é obrigatório!"
                                  : false
                            })}
                            errors={errors}
                            disabled={
                              temGerador !== "S" || statusVigenciaFechado
                            }
                          />
                        </Grid>
                      </Box>
                    </Grid>
                  )}

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <InputRadioGroup
                      type="text"
                      id="consumidorLivre"
                      name="consumidorLivre"
                      label="O consumidor é livre?"
                      renderIconShowHide={false}
                      customValue={consumidorLivre}
                      onChange={(e) => setConsumidorLivre(e?.target?.value)}
                      defaultValue={consumidorLivre}
                      ref={register({
                        required: "Campo Consumidor Livre é obrigatório!"
                      })}
                      errors={errors}
                      className={classes.inputRadio}
                      classNameLabel={classes.inputRadioLabel}
                      itens={listaOpcoesConsumidorLivre}
                      disabled={statusVigenciaFechado || !editarInputs}
                    />
                  </Grid>
                  {editarInputs && (
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      {consumidorLivre === "S" ? (
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <InputData
                              type="text"
                              id="dataMigracao"
                              name="dataMigracao"
                              format="dd/MM/yyyy"
                              label="Data início contrato"
                              minDate={window.moment().endOf("day").toDate()}
                              customValue={dataMigracao}
                              onChange={(value) => setDataMigracao(value)}
                              renderIconShowHide
                              errors={errors}
                              className={classes}
                              InputProps={{ readOnly: false }}
                              variant="standard"
                              autoOk
                              ref={register({
                                validate: (data) =>
                                  validarDataMigracao(data) ||
                                  "Data de migração deve ser igual ou maior que hoje!"
                              })}
                              disabled={statusVigenciaFechado}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <InputData
                              type="text"
                              id="quandoMigrou"
                              name="quandoMigrou"
                              format="dd/MM/yyyy"
                              label="Quando Migrou?"
                              minDate={window
                                .moment()
                                .subtract(90, "year")
                                .toDate()}
                              maxDate={window.moment().endOf("day").toDate()}
                              customValue={quandoMigrou}
                              onChange={(value) => setQuandoMigrou(value)}
                              renderIconShowHide
                              errors={errors}
                              className={classes}
                              InputProps={{ readOnly: false }}
                              variant="standard"
                              autoOk
                              ref={register({
                                required:
                                  consumidorLivre === "S"
                                    ? "Campo Quando Migrou é obrigatório!"
                                    : false,
                                validate: (data) =>
                                  validarDataMigrou(data) ||
                                  "Data de migração deve ser igual ou menor que hoje!"
                              })}
                              disabled={statusVigenciaFechado}
                            />
                          </Grid>
                        </Grid>
                      ) : (
                        <InputData
                          type="text"
                          id="dataMigracao"
                          name="dataMigracao"
                          format="dd/MM/yyyy"
                          label="Data prevista de migração"
                          minDate={window.moment().endOf("day").toDate()}
                          customValue={dataMigracao}
                          onChange={(value) => setDataMigracao(value)}
                          renderIconShowHide
                          errors={errors}
                          className={classes}
                          InputProps={{ readOnly: false }}
                          variant="standard"
                          autoOk
                          ref={register({
                            validate: (data) =>
                              validarDataMigracao(data) ||
                              "Data de migração deve ser igual ou maior que hoje!"
                          })}
                          disabled={statusVigenciaFechado}
                        />
                      )}
                    </Grid>
                  )}
                  {editarInputs && aumentarDemanda && (
                    <>
                      <Grid item lg={6} md={6} sm={6} xs={12} />
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <InputRadioGroup
                          type="text"
                          id="unidadeCnpj"
                          name="unidadeCnpj"
                          label="Deseja aumentar a demanda?"
                          renderIconShowHide={false}
                          customValue={unidadeCnpj}
                          onChange={(e) => setUnidadeCnpj(e?.target?.value)}
                          defaultValue={unidadeCnpj}
                          errors={errors}
                          className={classes.inputRadio}
                          classNameLabel={classes.inputRadioLabel}
                          itens={listaOpcoesUnidadeCnpj}
                          disabled={statusVigenciaFechado}
                        />
                      </Grid>
                    </>
                  )}
                </Box>
              </Box>
              <Box component={Grid} item xs={12} lg={6} />
            </Box>
          </Card>
          <Box component={Grid} container spacing={4} pl={1} pt={3}>
            <Grid item xs={12} lg={3} mt={2}>
              {statusVigenciaFechado ? (
                <Botao
                  onClick={renderPassoPlanos}
                  label="Próximos"
                  className={classes.button}
                />
              ) : (
                <Botao
                  type="submit"
                  label="Ver os melhores planos"
                  className={classes.button}
                />
              )}
            </Grid>
          </Box>
        </form>
      </Loader>
      <Dynamsoft />
    </>
  );
};

CotacaoACL.propTypes = {
  setPassoAtual: PropTypes.oneOfType([PropTypes.func]),
  setDadosCotacao: PropTypes.oneOfType([PropTypes.func])
};
CotacaoACL.defaultProps = {
  setPassoAtual: () => {},
  setDadosCotacao: () => {}
};

export default CotacaoACL;
