import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { Grid } from "@mui/material";
import { EditOutlined } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import DataTable from "react-data-table-component";
import Coluna from "componentes/tabelaPaginada/colunas/coluna";
import ColunaBotao from "componentes/tabelaPaginada/colunas/colunaBotao";
import ModalConfirmacao from "componentes/modalConfirmacao";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { useStyles, customStyles, conditionalRowStyles } from "../vigenciaAtual/style";
import RotasDTO from "../../../rotas/rotasUrlDto";
import { buscarVigenciasWACC, deleteWacc } from "../../../servicos/wacc";
import { Base } from "componentes/cores";

const ValorWACCFutura = () => {
  const history = useHistory();
  const classes = useStyles();

  const [data] = useState(moment().format("YYYY-MM-DD"));
  const [futuras, setFuturas] = useState([]);
  const [modalExclusao, setModalExclusao] = useState(false);

  const obterBandeiras = async (abortController) => {
    try {
      const response = await buscarVigenciasWACC(data, {
        signal: abortController.signal
      });

      if (response?.status === 200 && response?.data && response?.data?.vigenciaFutura.length > 0) {
        const { vigenciaFutura } = response?.data;
        const resp = [];
        vigenciaFutura.map((item) => (
          resp.push({
            ...item,
            dataInicial: window.moment(item.inicioVigencia).format("DD-MM-YYYY"),
            dataFinal: window.moment(item.finalVigencia).format("DD-MM-YYYY"),
            valor: item.valorWACC.toLocaleString("pt-BR", { style: "percent",  minimumFractionDigits: 2 }),
          })
        ));
        return setFuturas(resp);
      }
    } catch (error) {
      console.info(error);
    }
  };

  const onClickEditar = () => {
    history.push(`${RotasDTO.ValoresWACCCadastro}/agendada`);
  };

  const excluir = async () => {
    try {
      const response = await deleteWacc(futuras[0].inicioVigencia);

      if(response.status === 200 && response.data.includes("sucesso"))
      {
        setFuturas([]);
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: `${response.data}`
          })
        );
      }
      else if(response.status === 202)
      {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: `Falha: ${response.data}`
          })
        );
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: `excluir: Erro não previsto: ${error}`
        })
      );
    }
  }

  const onConfirmarExclusao = () => {
    excluir();
    setModalExclusao(false);
  }

  const onClickExcluir = () => {
    setModalExclusao(true);
  };

  const colunas = [
    new Coluna("dataInicial", "Data"),
    new Coluna("valor", "Valor"),
  ];

  const colunasComEdição = [
    ...colunas,
    new ColunaBotao(
      "editar",
      "",
      onClickEditar,
      <EditOutlined  style={{color: Base.OffWhite}}/>,
      false,
      "70px",
      "70px",
      true
    )];

  const colunasComExclusão = [
    ...colunasComEdição,
    new ColunaBotao(
      "excluir",
      "",
      onClickExcluir,
      <DeleteIcon  style={{color: Base.OffWhite}}/>,
      false,
      "70px",
      "70px",
      true
    )
  ]

  useEffect(() => {
    const abortController = new AbortController();
    obterBandeiras(abortController);
    return () => {
      abortController.abort();
    }
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={12} className="mt-5">
          <ModalConfirmacao
            item={modalExclusao}
            onConfirmar={(item) => onConfirmarExclusao(item)}
            mensagem="Tem certeza que deseja excluir essa vigência?"
            onCancelar={() => setModalExclusao(false)}
          />

          <div style={{ display: "flex", height: "100%", width: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              <DataTable
                noHeader
                title="Vigência Futura"
                noDataComponent="Nenhum registro encontrado"
                columns={colunasComExclusão}
                data={futuras}
                theme="DeltaEnergia"
                customStyles={customStyles}
                conditionalRowStyles={conditionalRowStyles}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default ValorWACCFutura;