import React from "react";
import PropTypes from "prop-types";
import { Grid, Box } from "@mui/material";
import { Botao } from "../../../../../componentes";
import { useStyles } from "./style";

const EsqueletoEtapa = ({
  children,
  prazo,
  titulo,
  subtitulo,
  proximoDisabled,
  onClickProximo,
  shadowHeader,
  shadowFooter,
  disabledButton,
  showButton
}) => {
  const classes = useStyles({ shadowHeader, shadowFooter });

  return (
    <>
      <div className={classes.imagemFundo}>
        <Grid container className={classes.header}>
          <Grid item xs={12}>
            <Grid container>
              <Grid
                item
                xl={10}
                lg={8}
                md={8}
                sm={8}
                xs={12}
                className={classes.containerTitulo}
              >
                <h2 className={classes.titulo}>{titulo}</h2>
              </Grid>
              <Box
                component={Grid}
                item
                xl={2}
                lg={4}
                md={4}
                sm={4}
                xs={12}
                className={classes.prazoTexto}
                textAlign={{ md: "start", lg: "end" }}
                display={{ xs: "none", sm: "flex" }}
              >
                {prazo}
              </Box>
              <Grid
                item
                xs={12}
                className={classes.containerTitulo}
              >
                <h5 className={classes.subtitulo}>{subtitulo}</h5>
              </Grid>
              <Box
                component={Grid}
                item
                xs={12}
                className={classes.prazoTexto}
                textAlign={{ md: "start", lg: "end" }}
                display={{ xs: "block", sm: "none" }}
              >
                {prazo}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.content}>
          {children}
        </Grid>
      </div>
      <Box component={Grid} container spacing={2} pt={2}>
        {showButton && (
          <>
            <Grid item xs={12} md={4} />
            <Grid item xs={12} md={4} />
            <Grid item xs={12} md={4}>
              <Botao
                type="submit"
                label="Enviar documentos"
                disabled={proximoDisabled}
                onClick={disabledButton ? null : () => onClickProximo()}
                style={{ opacity: disabledButton ? 0.5 : "" }}
              />
            </Grid>
          </>
        )}
      </Box>
    </>
  );
};

EsqueletoEtapa.propTypes = {
  children: PropTypes.node.isRequired,
  prazo: PropTypes.node,
  titulo: PropTypes.string.isRequired,
  subtitulo: PropTypes.node.isRequired,
  proximoDisabled: PropTypes.bool,
  onClickProximo: PropTypes.func,
  shadowHeader: PropTypes.bool,
  shadowFooter: PropTypes.bool,
  disabledButton: PropTypes.bool,
  showButton: PropTypes.bool
};

EsqueletoEtapa.defaultProps = {
  prazo: "",
  proximoDisabled: false,
  onClickProximo: () => {},
  shadowHeader: false,
  shadowFooter: false,
  disabledButton: false,
  showButton: false
};

export default EsqueletoEtapa;
