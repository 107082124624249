import { Base } from "../../componentes/cores";
import PlauRegular from "../../assets/fontes/Plau/Plau-Regular.woff";
import PlauRegularBold from "../../assets/fontes/Plau/Plau-Bold.woff";
import FundoPdf from "./assets/FundoPdf.png";
import LogoPdf from "./assets/logo-200x135.png";
import Tahoma from "../../assets/fontes/Tahoma.ttf";
import { FontPlauFileContent } from "../../assets/fontes/FontPlauFileContent";
import { FontPlauBoldFileContent } from "../../assets/fontes/FontPlauBoldFileContent";

const theme = {
  appBar: {
    backgroundColor: Base.WhiteLuz,
    TextColor: Base.GreyTextLuz,
    menuitemcolor: Base.Purpleishpink,
    TextColorLuz: Base.GradientTextLuz
  },
  selectArredondado: {
    borderColor: Base.WhiteLuz
  },
  borda: {
    input: "8px"
  },
  texto: {
    loginBemVindo: "Acesse sua conta",
    site: "www.sualuz.com.br",
    empresa: "Luz Investimentos e Participacoes S.a",
    enderecoRua: "Av. Pres. Juscelino Kubitschek",
    enderecoNumero: "1327",
    enderecoComplemento: "7º andar",
    enderecoBairro: "Vila Nova Conceiçao",
    enderecoCep: "04543-011",
    enderecoCidade: "Sao Paulo",
    enderecoEstado: "SP"
  },
  font: {
    family: "Plau",
    srcFont: PlauRegular,
    srcFontBold: PlauRegularBold
  },
  fontTahoma: {
    family: "Tahoma !important",
    srcFont: Tahoma
  },
  pdf: {
    footer: FundoPdf,
    logo: LogoPdf,
    drawColorR: 51,
    drawColorG: 32,
    drawColorB: 83,
    fontFooterColor: Base.White,
    fontJsPDF: FontPlauFileContent,
    fontStyleJsPDF: "Plau-Regular",
    fontNameJsPDF: "Plau-Regular-normal.ttf",
    fontJsPDFBold: FontPlauBoldFileContent,
    fontStyleJsPDFBold: "Plau-Bold",
    fontNameJsPDFBold: "Plau-Bold-normal.ttf"
  },
  color: {
    primary: Base.PrimaryLuzColor,
    primaryOpacity: Base.Purpleishpink,
    primaryText: Base.GreyTextLuz,
    primaryBackgroud: Base.Purpleishpink,
    secondaryBackgroud: Base.WhiteLuz,
    secondary: Base.Purpleishpink,
    secondaryText: Base.GreyTextLuz,
    secondaryBorderColor: Base.PrimaryLuzColor,
    thirdText: Base.GreyTextLuz,
    thirdBackgroud: Base.OffWhite,
    fundoBotoes: Base.White,
    fundoBotoesTexto: Base.GreyTextLuz,
    fourthBackgroud: Base.OffWhite,
    InLoginText: Base.White,
    InBorderText: Base.GreyPaynes,
    BtLoginText: Base.White,
    BtLoginBG: Base.Purpleishpink,
    buttonColor: Base.GreyDim,
    botaoCadastroBorderColor: Base.PrimaryLuzColor,
    invert: "invert(0)",

    // Botão Ordenador
    btOrdBGColor: Base.ButtonLuz,
    btSelBGColor: Base.PrimaryLuzColor,
    btOrdBorderColor: Base.PrimaryLuzColor,
    btTextColor: Base.Purpleishpink,
    btSelTextColor: Base.White,
    botaoTextColor: Base.OffWhite,

    // botaoFiltroOrdenar
    btFOBorderColor: Base.BlackLuz,
    btFOBgColor: Base.White,
    btFOTextColor: Base.BlackLuz,

    // Botão Salvar
    btSalvarBG: Base.PrimaryDeltaColor,
    boBorderColor: Base.Purpleishpink,
    boTextColor: Base.GreyTextLuz,
    boBackgroudSelColor: Base.OffWhite,
    boBorderSelColor: Base.GrPurpleishpinkeyPaynes,
    boTexSelColor: Base.GreyTextLuz,

    // Listagem
    treeItemArrowColor: Base.GreyDim,

    // Menu
    listItemBackgroundHover: Base.Purpleishpink,
    btMenuTextColor: Base.White,
    anchorMenuColor: Base.PrimaryLuzColor,
    menuAvatarColor: Base.OffWhite,
    menuItemAvatarBackground: Base.WhiteGhost,
    menuItemAvatarFont: Base.BlackRussian,
    menuItemAvatarFontHover: Base.BlackRussian,
    menuAvatarFooterBackground: Base.PrimaryLuzColor,
    novoItemBaseColor: Base.AuroraMenuBG,
    novoItemTextColor: Base.Aurora,
    novoBtMenuTextColor: Base.Aurora,
    novoListItemBackgroundHover: Base.NovoBGMenuHuver,

    checkButonColor: Base.Purpleishpink,
    footerColor: Base.WhiteGhost,
    gridText: Base.PrimaryLuzColor,
    gridComponenetColor: Base.Grey,

    menuItemtextEnable: Base.PrimaryLuzColor,
    menuItemTextOpacity: Base.MenuItemTextOpacityLuz,

    // Paginação
    bgPaginacao: Base.PrimaryOpacityLuz,
    numeroPagina: Base.BlackRussian,

    // Paginação:hover
    bgPaginacaoHover: Base.BlackRussian,
    numeroPaginaHover: Base.OffWhite,

    // Paginacao:active
    bgPaginacaoAtiva: "#332053db",

    // InputBuscar
    inputBuscar: Base.PrimaryLuzColor,

    // Workflow
    rootBgColor: Base.White,
    rootVerticalBgColor: Base.OffWhite,
    rootColor: Base.Preto,
    breadcrumbBgColor: Base.White,
    breadcrumbVerticalBgColor: Base.OffWhite,
    activeLabelColor: Base.White,
    completeLabelColor: Base.White,
    breadcrumbActiveBgColor: Base.PrimaryLuzColor,
    breadcrumbCompleteBgColor: Base.PrimaryLuzColor,
    breadcrumbVerticalActiveBgColor: `linear-Gradient(to right, ${Base.PinkLuz}, ${Base.OrangeLuz})`,
    breadcrumbVerticalCompleteBgColor: `linear-Gradient(to right, ${Base.PinkLuz}, ${Base.OrangeLuz})`,
    label: Base.PrimaryLuzColor,
    labelVertical: Base.Preto,
    tituloColorCardRepresetante: Base.PrimaryLuzColor,
    borderColorCardrepresetante: Base.BlackRussian,

    // BotaoOutlined
    labelColor: Base.Preto,
    borderColor: Base.Preto,
    hoverBorderColor: Base.PrimaryLuzColor,
    hoverBgColor: Base.PrimaryLuzColor,
    hoverLabelColor: Base.White,

    radioButton: Base.BlackOpacity60,
    bgColorCard: "rgba(238,240,249)",
    borderCard: Base.White,
    bgColorCardCadastro: Base.White,

    bgColorSecondCard: Base.BlueZodiac,
    bgColorThirdCard: Base.BlueZodiac,

    radioButtonDefault: Base.Aurora,

    // Container Gráficos
    consumoAtualInferior: Base.CustomGraLuzTwo,
    cardColorGraficos: Base.White,
    labelColorGrafico: Base.GreyTextLuz,
    labelColorSelect: Base.OffWhite,
    gradientInicial: Base.PinkLuz,
    gradientFinal: Base.OrangeLuz,
    gradientGraficoHoraInicial: Base.PinkLuz,
    gradientGraficoHoraFinal: Base.OrangeLuz,
    gridColorGrafico: Base.GreyTextLuz,
    serieMensalUm: Base.PrimaryLuzColor,
    serieMensalDois: Base.YellowMustard,
    serieMensalTres: Base.PinkLuz,
    serieMensalQuatro: Base.GreyTextLuz,
    serieInferiorAtual: Base.Aurora,
    montanteSuperior: Base.Lume,
    montanteInferior: Base.Cosmo,
    montante: Base.Aurora,

    // Select Arredondado
    selectArredondadoColor: Base.GreyTextLuz,

    // Switch
    switchBgColor: Base.White,
    switchBgColorDisabled: Base.GreyWater,

    // InputDate
    inputDateColor: Base.PrimaryLuzColor,

    // TreeView
    treeViewBackground1: Base.OffWhite,
    treeViewBackground2: Base.OffWhite,
    treeViewBackground3: Base.GreyTextLuz,
    treeViewBackground4: Base.GreyTextLuz,

    // BarraSuperior
    textoBarra: Base.BlackRussian,

    // Cotações
    bgCardInstrucoes: Base.OffWhite,
    bgCardCotacoes: Base.WhiteLuz,

    // Listar Medição Legendas
    legendaMedicao1: Base.Cimo,
    legendaMedicao2: Base.Lava,
    legendaMedicao3: Base.Turvo,

    // BG Medidor
    bgMedidor: Base.White,

    // Led Medidor
    ledVerde: Base.LedVerdeLuz,
    ledVermelho: Base.Lava,

    // Trace Medidor
    traceMedidorConsumo: Base.OrangeLuz,
    traceMedidorHoras: Base.PinkLuz,

    // Grafico EconomiaPdf
    Cativo: Base.CativoLuz,
    Livre: Base.LivreLuz,

    // PlanosCotações
    backgroundPlanoBronze: `linear-Gradient(to right, ${Base.PinkLuz}, ${Base.OrangeLuz})`,
    backgroundPlanoPrata: `linear-Gradient(to right, ${Base.PinkLuz}, ${Base.OrangeLuz})`,
    backgroundPlanoOuro: `linear-Gradient(to right, ${Base.PinkLuz}, ${Base.OrangeLuz})`,
    opacityPlanoBronze: 0.6,
    opacityPlanoPrata: 0.8,
    opacityPlanoOuro: 1,
    opacityGrafico: 0.8,
    planoColor: Base.White,
    fundoBronze: Base.OffWhite,
    fundoPrata: Base.OffWhite,
    fundoOuro: Base.OffWhite,

    // Botão Filtrar Ordenador
    bgBotaoInicial: Base.OffWhite,
    bgBotaoSelecionado: Base.PrimaryLuzColor,
    labelBotaoInicial: Base.BlackRussian,
    labelBotaoSelecionado: Base.White,

    // Hover Esqueci Senha
    hoverEsqueciSenha: Base.OffWhite,

    // Background content
    bgTheme: Base.WhiteBgTheme,

    // Background tabelas
    bgTabela: Base.White,

    // Background item expansivel
    bgItemExpansivel: Base.White,

    // Background item expansivel
    bgCardEconomia: Base.PrimaryLuzColor,

    // Background faixa medidores
    bgFaixaMedidores: Base.WhiteBgTheme,

    // Background checkbox
    fundoCheckbox: Base.White,

    // Borda Checkbox
    bordaCheckbox: Base.PrimaryLuzColor,

    // Setas menu superior
    setaMenu: Base.PrimaryLuzColor,

    // Upload
    dropZoneLabel: Base.Preto,

    // Background Tooltip
    bgToolTip: Base.PrimaryLuzColor,
    textoTooltip: Base.White,

    // Financeiro status
    bgPendente: Base.Aurora,
    bgPago: Base.PrimaryLuzColor,
    bgPagoParcial: Base.GreenFrag,
    bgAtrasado: Base.OrangeLuz,
    bgVencido: Base.RedAmaranth,

    donutEnergiaCativo: Base.CativoEnergiaLuz,
    donutDistribuidoraCativo: Base.CativoDistribuidoraLuz,
    donutImpostoCativo: Base.CativoOutrosLuz,
    donutOutrosCativo: Base.CativoOutrosLuz,

    donutEnergiaLivre: Base.LivreEnergiaLuz,
    donutDistribuidoraLivre: Base.LivreDistribuidoraLuz,
    donutImpostoLivre: Base.LivreImpostoLuz,
    donutOutrosLivre: Base.LivreOutrosLuz,

    custoCativo: Base.OrangeLuz,
    custoLivre: Base.PinkLuz,

    // Notificação
    notificacaoSino: Base.PrimaryLuzColor,
    notificacaoLink: Base.PrimaryLuzColor,
    qtdNotificacao: Base.PinkLuz,

    // mudar senha
    IconSenha: Base.ButtonLuz,

    gradientTextLuz: Base.GradientTextLuz,

    // Faturas
    valorCorFatura: Base.PrimaryLuzColor,
    botaoDownloadFatura: Base.Purpleishpink,

    // Uploader
    backgroundUpload: Base.BackgroundUploadLuz,
    bordaUpload: Base.PinkLuz
  }
};

export default theme;
