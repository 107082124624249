import { makeStyles } from "@mui/styles";
import theme from "themes";

export const useStyles = makeStyles(() => ({
  card: {
    minHeight: "200px",
    border: "1px solid #FF9321 !important",
    borderRadius: "32px !important",
    padding: "18px !important",
    display: "flex",
    marginBottom: "40px",
    "& h2": {
      color: "#FF9321",
      fontWeight: 700,
      fontSize: "28px",
      marginBottom: "20px"
    },
    "& h3": {
      color: "#FF9321",
      fontWeight: 700,
      fontSize: "24px",
      marginBottom: 0,
      "& span": {
        color: theme.color.secondary
      }
    },
    "& h4": {
      color: theme.color.primary,
      fontWeight: 700,
      fontSize: "20px",
      marginBottom: 0,
      "& span": {
        color: theme.color.secondary
      }
    }
  },
  imagem: {
    textAlign: "center",
    maxWidth: "130px",
    marginRight: "10px",
    "& p": {
      color: theme.color.primary,
      fontSize: "12px",
      lineHeight: "13px",
      marginBottom: 0
    }
  },
  icone: {
    maxWidth: "100%",
    height: "auto"
  }
}));
