import { adaptV4Theme } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { createTheme } from "react-data-table-component";
import withStyles from '@mui/styles/withStyles';

import MuiAccordion from '@mui/material/Accordion';
import { Base } from "componentes/cores";
import theme from 'themes';

export const Accordion = withStyles({
  root: {
    background: "transparent",
    color: "#eef0f9",
    border: 'none',
    boxShadow: 'none',
    fontSize: "22px",
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

export const useStyles = makeStyles({
  customTexto: {
    color: `${Base.White} !important`,
    fontWeight: "400",
    fontSize: "22px"
  },
  customTextoNoData: {
    color: `${Base.White} !important`,
    textAlign: "center"
  },
  noData: {
    color: Base.white
  },
  icon: {
    color: `${Base.White} !important`,

    "& :hover": {
      cursor: "pointer",
    },
  },
  Container: {
    background: theme.color.thirdBackgroud,
    height: "300px",
    marginTop: "2em",
    marginBottom: "2em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  container: {
    margin: "0 0 24px",
    width: "100%"
  },
  search: {
    "& label.Mui-focused": {
      color: `${Base.OffWhite} !important`
    },
    "& .MuiInput-underline:after": {
      borderColor: Base.OffWhite
    },
    "& .MuiFormLabel-root": {
      color: `${Base.OffWhite} !important`
    },
    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-input": {
        color: `${Base.OffWhite} !important`
      },
      "& .MuiInputAdornment-root": {
        color: `${Base.OffWhite} !important`
      },
      "& fieldset": {
        borderColor: Base.OffWhite
      },
      "&:hover fieldset": {
        borderColor: Base.OffWhite
      },
      "&.Mui-focused fieldset": {
        borderColor: Base.OffWhite
      }
    },
    accordion: {
      background: "transparent"
    }
  },
  itemFiltro: {
    display: "flex",
    justifyContent: "flex-end"
  },
  buttonFiltro: {
    fontSize: "18px",
    maxWidth: "500px"
  }
});

export const conditionalRowStyles = [
  {
    when: (row) => !row.ativo,
    style: {
      "& div": {}
    }
  }
];

export const customStyles = {
  table: {
    style: {
      background: "transparent",
    }
  },
  headRow: {
    style: {
      background: "transparent",
    }
  },
  headCells: {
    style: {
      background: "#2a2b32",
      marginLeft: "3px",
      fontSize: "16px",
      color: `${Base.OffWhite} !important`,
      "& div": {
        "&:hover": {
          color: `${Base.OffWhite} !important`
        }
      }
    }
  },
  rows: {
    style: {
      background: "transparent",
      marginBottom: "1px",
      marginTop: "1px",
      color: Base.White
    }
  },
  Pagination: {
    styles: {
      color: "#eef0f9"
    }
  },
  cells: {
    style: {
      marginLeft: "3px",
      background: "#32333b",
      fontSize: "16px",
      color:Base.default
    }
  }
};

createTheme(adaptV4Theme("DeltaEnergia", {
  background: {
    default: "transparent"
  },
  text: {
    primary: "#eef0f9",
    secondary: "#eef0f9"
  },
  action: {
    hover: "#eef0f9"
  }
}));
