/* eslint-disable jsx-a11y/alt-text */
import PropTypes from "prop-types";
import React from "react";

import "./imagePreview.css";

export const ImagePreview = ({ dataUri, isFullscreen }) => {
  const classNameFullscreen = isFullscreen
    ? "demo-image-preview-fullscreen"
    : "";

  return (
    <div className={`demo-image-preview ${classNameFullscreen}`}>
      <img src={dataUri} />
    </div>
  );
};

ImagePreview.propTypes = {
  // eslint-disable-next-line react/require-default-props
  dataUri: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  isFullscreen: PropTypes.bool
};

export default ImagePreview;
