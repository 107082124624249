import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Grid } from "@mui/material";
import { Botao } from "componentes";
import formatarValor from "global/utils/formatarValor";

import { useStyles } from "./style";

const VisualizarCancelamento = (props) => {
  const { dados, onSolicitarCancelamento, exibirBotao } = props;
  const classes = useStyles();

  return (
    <>
      <Grid container>
        {dados && (
          <Grid item xs={12} mb={2}>
            <div className={classes.lista}>
              <div className={classes.dados}>
                {dados?.numeroInstalacao && <p>UC {dados?.numeroInstalacao}</p>}
                {dados?.boletaGDprazoPlanoDescricao && (
                  <p>
                    Plano{" "}
                    {dados?.boletaGDprazoPlanoDescricao === "Sem Fidelidade"
                      ? dados?.boletaGDprazoPlanoDescricao
                      : `Fidelidade ${dados?.boletaGDprazoPlanoDescricao}`}
                  </p>
                )}
                <p>
                  Data de início do contrato:{" "}
                  {moment(dados?.dataInicioContrato).format("DD/MM/YYYY")}
                </p>
              </div>
              <div className={classes.listaItem}>
                <strong>Média mensal</strong>
                <span>{formatarValor(dados?.mediaMensalReais)}</span>
              </div>
              <div className={classes.listaItem}>
                <strong>Tempo contratado</strong>
                <span>{dados?.boletaGDprazoPlanoDescricao?.toLowerCase()}</span>
              </div>
              <div className={classes.listaItem}>
                <strong>Meses utilizados</strong>
                {dados?.mesesUtilizados >= 0 && (
                  <span>{dados?.mesesUtilizados} meses</span>
                )}
              </div>
              <div className={classes.listaItem}>
                <strong>Meses restante de contrato</strong>
                {dados?.mesesRestantes >= 0 && (
                  <span>{dados?.mesesRestantes} meses</span>
                )}
              </div>
              <div className={classes.listaItem}>
                <strong>Média de meses remanescentes</strong>
                <span>{formatarValor(dados?.valorRemanescenteMedioReais)}</span>
              </div>
              <div className={classes.listaItem}>
                <strong>Pagar medidor?</strong>
                <span>{dados?.pagarMedidor ? "Sim" : "Não"}</span>
              </div>
              <div className={classes.listaItem}>
                <strong>Total de multa (Medidor + Fidelidade)</strong>
                <span>{formatarValor(dados?.valorMultaReais)}</span>
              </div>
            </div>
          </Grid>
        )}
        {exibirBotao && (
          <Grid item xs={12}>
            <Botao
              onClick={() => onSolicitarCancelamento()}
              label="Solicitar cancelamento"
              className={classes.botao}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

VisualizarCancelamento.propTypes = {
  dados: PropTypes.oneOfType([PropTypes.any]),
  onSolicitarCancelamento: PropTypes.func,
  exibirBotao: PropTypes.bool
};

VisualizarCancelamento.defaultProps = {
  dados: null,
  onSolicitarCancelamento: () => {},
  exibirBotao: false
};

export default VisualizarCancelamento;
