import React, { useState, useEffect} from "react";
import { Grid, FormControl, Typography } from "@mui/material";
import Loader from "componentes/loader";
import DataTable from "react-data-table-component";
import Coluna from "componentes/tabelaPaginada/colunas/coluna";
import ColunaData from "../colunasPersonalizadas/ColunaData";
import ColunaComponentePersonalizado from "componentes/tabelaPaginada/colunas/colunaComponentePersonalizado";
import MaterialInputTexto from "componentes/inputTexto/materialInput";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { useParams } from "react-router-dom";
import { getMemoriaCalculoDetails } from "submodules/DeltaTarifa/servicos/memoriaCalculo";
import BotaoRetornarListagem from "componentes/botaoRetornarListagem";
import { useStyles, customStyles } from "./style";
import moment from "moment"
import { Base } from "componentes/cores";

const MemoriaCalculo = () => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const {id, etapa, titulo} = useParams();

    const [tituloProjecao, setTituloProjecao] = useState("")
    const [cenario, setCenario] = useState("")
    const [dataProjecao, setDataProjecao] = useState("")
    const [distribuidora, setDistribuidora] = useState("")

    const [detalhesTabela, setDetalhesTabela] = useState(null);
    const [msgAguarde, setMsgAguarde] = useState(<Typography 
        style={{color: Base.OffWhite, paddingTop:"100px", paddingLeft:"42%"}}>Aguarde a carga dos dados...</Typography>)

    let colunasDetalhes = [
        {...new Coluna("itemProjecao", "Item de Projeção", false, "180px"), wrap: true},
        new Coluna("anoProjecao", "Projeção", false, "20px"),
        {...new Coluna("insumo", "Insumo", false, "460px"), wrap: true},
        {...new Coluna("dado", "Dado", false, "350px"), wrap: true},
        titulo === 'CDE' ? new Coluna("nivelTensao", "Nível de Tensão", false, "180px") : new ColunaComponentePersonalizado(
            "data",
            "Data",
            ColunaData,
            null,
            false,
            false,
            "150px",
        ),
        new Coluna("valor", "Valor", false, "180px"),
        new Coluna("unidade", "Unidade", false, "30px"),
    ].filter(Boolean); // Remove valores nulos

    const listarDetalhes = async () => {
        setIsLoading(true);
        const response = await getMemoriaCalculoDetails(id, etapa);

        if (!response || response.status !== 200) {
            store.dispatch(
                alertaExibir({
                    tipo: "warning",
                    mensagem:
                        `Sem registros de memória de cálculo para a etapa ${etapa}.`
                })
            );
            setIsLoading(false);
            setMsgAguarde();
            return;
        }

        setTituloProjecao(response.data.titulo);
        setCenario(response.data.cenario);
        setDataProjecao(moment(response.data.realizadoEm).format("LLL"));
        setDistribuidora(response.data.distribuidora)

        setDetalhesTabela(response.data.detalhes.map((detalhe) => {
            detalhe.unidade.includes("R$" || "US$") 
                ? detalhe.valor = detalhe.valor.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2}) 
                : detalhe.unidade.includes("%") 
                    ? detalhe.valor = (detalhe.valor * 100).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2}) 
                    : "";
            detalhe.anoProjecao == 0 ? detalhe.anoProjecao = null : detalhe.anoProjecao;

            return detalhe;
        }));

        setIsLoading(false);
        setMsgAguarde();
    }

    useEffect(() => {
        listarDetalhes();
    }, []);

    return (
        <Grid container style={{ paddingLeft: "1.5em", paddingRight: "1.5em" }}>
            <Grid item xs={11} style={{ marginBottom: 20 }}>
                <h4 style={{color: "white"}}>{tituloProjecao}</h4>
                <h5 style={{color: "white"}}>Memória de Cálculo - {titulo}</h5>
            </Grid>
            <Grid item xs={1} className={classes.backButton}>
                <BotaoRetornarListagem voltarPagina />
            </Grid>
            <Grid container spacing={3} alignItems="center">
                <Grid item xs={4}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <MaterialInputTexto
                            fullWidth
                            type="text"
                            id="distribuidora"
                            name="distribuidora"
                            label="Distribuidora"
                            renderIconShowHide={false}
                            defaultValue={distribuidora}
                            disabled
                        />

                    </FormControl>
                </Grid>

                <Grid item xs={4}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <MaterialInputTexto
                            fullWidth
                            type="text"
                            id="cenario"
                            name="cenario"
                            label="Cenario"
                            disabled
                            renderIconShowHide={false}
                            defaultValue={cenario}
                        />

                    </FormControl>
                </Grid>

                <Grid item xs={4}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <MaterialInputTexto
                            fullWidth
                            type="text"
                            id="data"
                            name="data"
                            label="Realizada em"
                            renderIconShowHide={false}
                            defaultValue={dataProjecao}
                        />
                    </FormControl>
                </Grid>
            </Grid>

            <Grid container item xs={12} spacing={3} justifyContent="center" className="w-100">
                <Loader loading={isLoading} className="w-100">
                    {msgAguarde}
                </Loader>
            </Grid>   

            {detalhesTabela != null ?
            <Grid container spacing={3} alignItems="center">
                <Grid item xs={12}>
                        <DataTable
                            subHeader
                            subHeaderAlign='right'
                            columns={colunasDetalhes}
                            data={detalhesTabela}
                            theme="DeltaEnergia"
                            customStyles={customStyles}
                            noDataComponent="Nenhum registro encontrado"
                            noHeader
                        />
                </Grid>
            </Grid>
            :
            ""
            }

        </Grid>
    );
}

export default MemoriaCalculo;