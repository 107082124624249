import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import moment from "moment";
import { Grid, Card, TextField, Typography, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import BotaoRetornarListagem from "componentes/botaoRetornarListagem";
import InputData from "componentes/inputTextoData";
import Botao from "componentes/botao";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { useStyles } from "./style";
import {adicionarVigencias, getVigencias, atualizarVigencias} from "../../../../../servicos/premissasBrasil";
import RotasDTO from "../../../../../rotas/rotasUrlDto";

const CadastroVigenciaProinfa = () => {
  const { operacao, tipovigencia } = useParams();
  const history = useHistory();
  const classes = useStyles();

  const { errors, handleSubmit } = useForm({
    reValidateMode: "onSubmit",
  });

  const [data] = useState(moment().format())

  const [dataMinima, setDataMinima] = useState(moment([2000]));
  const [dataMaxima] = useState();
  const [fontesEnergia, setFontesEnergia] = useState([]);
  const [vigencias, setVigencias] = useState([]); 
  const [series, setSeries] = useState([]);
  const [seriesFontes, setSeriesFontes] = useState([]);
  const [iniciosVigencias, setIniciosVigencias] = useState([]);
  
  const obterVigencias = async () => 
  {
    try
    {
      const response = await getVigencias();

      if(response.status === 200)
      {
          const filtered = response.data.filter(g=> {
            return (g.tipoVigencia == tipovigencia)
          })

          const objseries = [];
          const objinicios = [];
          for(const ob of filtered)
          {
            objseries.push({
              "fonte" : ob.codigoFonteEnergia,
              "serie" : ob.tituloSerieHistorica
            });

            objinicios.push({
              "fonte" : ob.codigoFonteEnergia,
              "inicio" : ob.inicioVigencia
            });

          };
          setSeriesFontes(objseries);
          setIniciosVigencias(objinicios);
      }
      else if(response.status === 202)
      {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: `Falha: ${response.data}`
          })
        );
      }
    }
    catch (error)
    {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem:`Erro não previsto ao obter vigências: ${error}`
        })
      );

    }
  }
   
  const getObjSerieHistorica = (valor => {
    return series.find(item => item.tituloSerieHistorica === valor);
  })

  const getSerieByFonte = (fonte) => {

    if(seriesFontes.length > 0)
    {
      const filtered = seriesFontes.filter(o=>{return o.fonte == fonte});
      if(filtered.length > 0)
        return filtered[0].serie;
      else
        return "";
      
    }
    else return "";
  }

  const getInicioByFonte = (fonte) => {

    if(iniciosVigencias.length > 0)
    {
      const filtered = iniciosVigencias.filter(o=>{return o.fonte == fonte});
      if(filtered.length > 0)
        return filtered[0].inicio;
      else
        return "";
    }
    else return "";
  }

  const onChangeSerieHistorica = async (item,valor) => {
    const serie = getObjSerieHistorica(valor);
    for(const obj of vigencias)
    {
      if(obj.codigoFonteEnergia === item)
      {
        obj.tituloSerieHistorica = serie.tituloSerieHistorica;
        obj.codigoSerieHistoricaMes = serie.codigoSerieHistoricaMes;
        obj.codigoSerieHistoricaAno = serie.codigoSerieHistoricaAno;
        break;
      }
    }
    
    const objSeries = [...seriesFontes];
    
    for(const obj of objSeries)
    {
      if(obj.fonte == item)
      {
        obj.serie = serie.tituloSerieHistorica;
        break;
      }
    }
    setSeriesFontes(objSeries);

  }

  const onChangeInicioVigencia = async (item,valor) => {

    for(const obj of vigencias)
    {
      if(obj.codigoFonteEnergia === item)
      {
        
        obj.inicioVigencia = valor;

        break;
      }
    }

    const objInicios = [...iniciosVigencias];
    
    for(const obj of objInicios)
    {
      if(obj.fonte == item)
      {
        obj.inicio = valor;
        break;
      }
    }
    setIniciosVigencias(objInicios);

  }

  useEffect(() => {
      
    if(fontesEnergia.length == 0 && vigencias.length == 0 && series.length == 0)
    {
        const objFontesEnergia = [
        {
          "Nome": "Biomassa",
          "Codigo": "BIOMASSA"
        },
        {
          "Nome": "Eólica",
          "Codigo": "EOLICA"
        },
        {
          "Nome": "Pch",
          "Codigo": "PCH"
        }
      ];

      setFontesEnergia(objFontesEnergia);

      const objVigencia = [
      {
        "inicioVigencia": "",
        "finalVigencia": "2022-05-13T00:00:00Z",
        "codigoFonteEnergia": "BIOMASSA",
        "tituloSerieHistorica": "",
        "codigoSerieHistoricaMes": "",
        "codigoSerieHistoricaAno": "",
        "tipoVigencia": "",
        "nomeFonteEnergia": "Biomassa"
      },
      {
        "inicioVigencia": "",
        "finalVigencia": "2022-05-13T00:00:00Z",
        "codigoFonteEnergia": "EOLICA",
        "tituloSerieHistorica": "",
        "codigoSerieHistoricaMes": "",
        "codigoSerieHistoricaAno": "",
        "tipoVigencia": "",
        "nomeFonteEnergia": "Eólica"
      },
      {
        "inicioVigencia": "",
        "finalVigencia": "2022-05-13T00:00:00Z",
        "codigoFonteEnergia": "PCH",
        "tituloSerieHistorica": "",
        "codigoSerieHistoricaMes": "",
        "codigoSerieHistoricaAno": "",
        "tipoVigencia": "",
        "nomeFonteEnergia": "Pch"
      }
      ];
      
      setVigencias(objVigencia);
      
      if(operacao === "editar" && vigencias.length == 0)
      {
        obterVigencias();
      }
      else if(operacao === "incluir")
      {
        const objseries = [];
        const objinicios = [];
        for(const ob of objFontesEnergia)
        {
          objseries.push({
            "fonte" : ob.Codigo,
            "serie" : ""
          });

          objinicios.push({
            "fonte" : ob.Codigo,
            "inicio" : ""
          });

        };
        setSeriesFontes(objseries);
        setIniciosVigencias(objinicios);
      }

      const objSeries = [
        {
          "tituloSerieHistorica": "IGP-M",
          "codigoSerieHistoricaMes": "IGP-M MÊS",
          "codigoSerieHistoricaAno": "IGP-M ANO"
        },
        {
          "tituloSerieHistorica": "IPCA",
          "codigoSerieHistoricaMes": "IPCA MÊS",
          "codigoSerieHistoricaAno": "IPCA ANO"
        }
      ];

      setSeries(objSeries);
    }

  }, [fontesEnergia.length, vigencias.length, series.length]);


  const incluirVigencias = async () => {
    try 
    {
      const msgvalida = "Preencha ao menos uma linha de vigência com a série e o início da vigência.";

      const valida = vigencias.find(y=>y.inicioVigencia !== "" && y.tituloSerieHistorica !== "");

      if(valida === undefined)
      {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: msgvalida
          })
        );
        return;
      }
      
      const dados = vigencias.filter(y=> {return (y.inicioVigencia !== "" && y.tituloSerieHistorica !== "")});

      const response = await adicionarVigencias(dados);

      if (response?.status === 200 && response.data) {
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: response.data
          })
        );

        setTimeout(() => {
          history.push(RotasDTO.ProinfaVigencias);
        }, 2000);
      }
      else if(response.status == 202)
      {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: response.data
          })
        );
      }
    } 
    catch (error) {
        store.dispatch(
          alertaExibir({
            tipo: "danger",
            mensagem:
              error?.response?.data ??
              "Erro não previsto ao incluir vigências, entre em contato com o suporte!"
          }));
      }
  }

  const editarVigencias = async () => {
    try 
    {
      const msgvalida = "Edite ao menos uma série histórica";

      const valida = vigencias.find(y=>y.tituloSerieHistorica !== "");

      if(valida === undefined)
      {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: msgvalida
          })
        );
        return;
      }
      
      const dados = vigencias.filter(y=> {return (y.tituloSerieHistorica !== "")});

      for(const obj of dados)
      {
        obj.inicioVigencia = getInicioByFonte(obj.codigoFonteEnergia);
      }

      const response = await atualizarVigencias(dados);

      if (response?.status === 200 && response.data) {
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: response.data
          })
        );

        setTimeout(() => {
          history.push(RotasDTO.ProinfaVigencias);
        }, 2000);
      }
      else if(response.status == 202)
      {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: response.data
          })
        );
      }
    } 
    catch (error) {
      console.dir(error);
        store.dispatch(
          alertaExibir({
            tipo: "danger",
            mensagem:
              error.message??
              "Erro não previsto, entre em contato com o suporte!"
          }));
      }
  }

  const aoEnviarFormulario = (dados) => {
    if(operacao == "incluir")
    {
      incluirVigencias();
    }
    else if(operacao == "editar")
    {
      editarVigencias();
    }

  }

  return (
    <form className="needs-validation" onSubmit={handleSubmit(aoEnviarFormulario)}>
      <Card className={classes.container}>
        <Grid container p={2} spacing={4} justifyContent="flex-end" className={classes.container}>
          <Grid item sm={11} style={{fontSize:"20pt"}}>
            Vigências de Indicadores para Atualização de Contratos PROINFA
          </Grid>
          <Grid item sm={1}>
            <BotaoRetornarListagem
              justify="flex-end"
              urlListagem={RotasDTO.ProinfaVigencias}
            />
          </Grid>
        </Grid>

        {
          fontesEnergia.map((item) => (

        <Grid container spacing={1} alignItems="center" className={classes.container}>
          <Grid item sm = {2} style={{paddingLeft:"10px"}}>
            <Typography style={{fontWeight:"bold", fontSize:"15pt", paddingLeft:"40px"}}>{item.Nome}</Typography>
          </Grid>
          <Grid item sm={2}>
          <Typography>Série Histórica:</Typography>
          </Grid>
          <Grid item sm={2}  style={{ marginLeft: "-75px", marginRight: "16px" }}>
              <Select
                id={`serie${item.Codigo}`}
                variant="outlined"
                value={getSerieByFonte(item.Codigo)}
                onChange={(e) => { onChangeSerieHistorica(item.Codigo, e.target.value) }}
                fullWidth
              >
                {series.map((item) => (
                  <MenuItem value={item.tituloSerieHistorica}>{item.tituloSerieHistorica}</MenuItem>
                ))}
              </Select>
          </Grid>
          <Grid item sm={2}>
          <Typography>Início da Vigência:</Typography>
          </Grid>
          <Grid item sm={3} style={{ marginLeft: "5px", marginRight: "16px" }}>
            <InputData
              type="text"
              id={`dataVigencia${item.Codigo}`}
              name="dataVigencia"
              disabled = {operacao == "editar"}
              minDate={dataMinima}
              maxDate={dataMaxima}
              customValue={getInicioByFonte(item.Codigo)}
              onChange={(dt) => onChangeInicioVigencia(item.Codigo, dt)}
              renderIconShowHide={false}
              errors={errors}
            />
          </Grid>
          <Grid item sm={6}></Grid>
        </Grid>

        ))}

        <Grid container p={2} spacing={4} className={classes.container}>
          <Grid item sm={4}>
            <Botao type="submit" label="Salvar" className={classes.button} />
          </Grid>
        </Grid>
      </Card>
    </form>
  );
};

export default CadastroVigenciaProinfa;