import React from "react";
import PropTypes from "prop-types";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Grid } from "@mui/material";
import IOSStyledCheckBox from "./IOSStyledCheckBox";

const IOSStyleCheckBox = IOSStyledCheckBox;

const MaterialCheckBox = React.forwardRef((props, ref) => {
  const {
    label,
    labelPlacement,
    id,
    name,
    checked,
    disabled,
    onChange,
    exibirLabels
  } = props;

  const handleChange = (event) => {
    onChange(event.target.checked);
  };

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Grid
            component="label"
            container
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
            className="mt-1 mb-0 text-left"
          >
            <Grid item>
              <IOSStyleCheckBox
                checked={checked}
                onChange={handleChange}
                disabled={disabled}
                name={name}
                id={id}
                reference={ref}
              />
            </Grid>
            {exibirLabels && <Grid item>{checked ? "Sim" : "Não"}</Grid>}
          </Grid>
        }
        className="m-0 align-items-start"
        label={label}
        labelPlacement={labelPlacement}
      />
    </FormGroup>
  );
});

MaterialCheckBox.propTypes = {
  label: PropTypes.string,
  labelPlacement: PropTypes.oneOfType([PropTypes.any]),
  id: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.oneOfType([PropTypes.any]),
  onChange: PropTypes.oneOfType([PropTypes.func]),
  exibirLabels: PropTypes.bool,
  disabled: PropTypes.bool
};

MaterialCheckBox.defaultProps = {
  label: "",
  labelPlacement: "top",
  id: "",
  name: "",
  checked: false,
  onChange: () => {},
  exibirLabels: false,
  disabled: false
};

export default MaterialCheckBox;
