import React from "react";
import PropTypes from "prop-types";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const SelectAutocomplete = React.forwardRef((props, ref) => {
  const {
    id,
    label,
    options,
    getOptionLabel,
    onChange,
    onInputChange,
    onChangeSelect,
    value,
    disabled,
    defaultValue,
    error,
    className,
    valueName,
    name
  } = props;

  return (
    <>
      <Autocomplete
        id={id}
        options={options}
        getOptionLabel={getOptionLabel}
        onInputChange={onInputChange}
        selectOnFocus
        autoHighlight
        autoComplete
        autoSelect
        disabled={disabled}
        value={
          value && options?.length > 0
            ? options.find((x) => x.id === value)
            : null
        }
        defaultValue={
          defaultValue && options?.length > 0
            ? options.find((x) => x.id === defaultValue)
            : null
        }
        noOptionsText="Nenhum registro encontrado"
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            name={name}
            onChange={onChangeSelect}
            className={className ?? ""}
            label={label}
            variant="outlined"
            error={Boolean(error[name]?.message)}
            helperText={error[name]?.message ?? ""}
            inputRef={ref}
          />
        )}
        renderOption={(prop, option) => {
          return (
            <li {...prop} key={option.id}>
              {option[valueName]}
            </li>
          );
        }}
      />
      {/* {error[id] ? (
        <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">
          {`Campo ${label} é obrigatório!`}
        </p>
      ) : null} */}
    </>
  );
});

SelectAutocomplete.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  getOptionLabel: PropTypes.oneOfType([PropTypes.any]),
  value: PropTypes.oneOfType([PropTypes.any]),
  onInputChange: PropTypes.oneOfType([PropTypes.func]),
  onChange: PropTypes.oneOfType([PropTypes.func]),
  onChangeSelect: PropTypes.oneOfType([PropTypes.func]),
  label: PropTypes.string,
  maxHeight: PropTypes.string,
  maxWidth: PropTypes.string,
  marginBottom: PropTypes.string,
  inputValue: PropTypes.string,
  options: PropTypes.oneOfType([PropTypes.any]),
  error: PropTypes.oneOfType([PropTypes.any]),
  defaultValue: PropTypes.oneOfType([PropTypes.any]),
  disabled: PropTypes.bool,
  valueName: PropTypes.string,
  className: PropTypes.string
};

SelectAutocomplete.defaultProps = {
  id: "",
  getOptionLabel: "",
  value: "",
  onChange: () => {},
  onInputChange: () => {},
  onChangeSelect: () => {},
  label: "",
  maxHeight: "auto",
  maxWidth: "auto",
  marginBottom: "10px",
  inputValue: "",
  options: "",
  error: false,
  defaultValue: null,
  disabled: false,
  valueName: "label",
  className: ""
};

export default SelectAutocomplete;
