import { makeStyles } from "@mui/styles";
import theme from "../../../../themes";

export const useStyles = makeStyles(() => ({
  adicionar: {
    borderColor: theme.color.primary
  },
  container: {
    width: "100%",
    margin: 0,
    paddingBottom: "15px",
    "& fieldset": {
      borderColor: `${theme.color.selectArredondadoColor} !important`
    },
    "& label, & button, & input": {
      color: `${theme.color.selectArredondadoColor} !important`
    }
  }
}));
