import { saveAs } from "file-saver";

import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const api = () => obterApi(MICROSERVICO.DGINTEGRACAO);

export const getDadosFaturas = (unidadeId, dataInicio, dataFim) => {
  return api().get(
    `v1/Faturas/dados-fatura?unidadeConsumidoraId=${unidadeId}&dataInicio=${dataInicio}&dataFim=${dataFim}`
  );
};

export const getConsumoProjecao = (
  unidadeId,
  ept,
  eptProjetado,
  cip,
  bandeira,
  tarifa
) => {
  return api().get(
    `v1/Faturas/consumo-projecao?UnidadeConsumidoraId=${unidadeId}&Ept=${ept}&EptProjetado=${eptProjetado}&CIP=${cip}&BandeiraTarifarira=${bandeira}&Tarifa=${tarifa}`
  );
};

export const downloadFatura = async (params, nomeUnidade) => {
  const { data } = await api().get(
    `v1/Faturas/download?idArquivoFatura=${params.idArquivoFaturaLUZ}`,
    {
      responseType: "blob"
    }
  );
  const blob = new Blob([data]);
  saveAs(blob, `${nomeUnidade}_${params.mesReferencia}.pdf`);
};

export const getFaturasVisaoGeral = (unidades, filtro, statusFatura) => {
  const filtroQueryString = new URLSearchParams(filtro).toString();

  return api().get(
    `v1/Faturas/filtro?${unidades}&${filtroQueryString}&${statusFatura}`
  );
};

// Listar
export const enviarFaturasPorEmail = async (params) => {
  return api().post("v1/Faturas/reenvio-manual", params);
};
